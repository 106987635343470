<div class="popup-sidebar-wrapper accordion-drawer">
    <mat-drawer-container class="side-panel-wrappers ticket-assignment business-report" autosize end>
      <mat-drawer #drawer class="ticket-sidenav" mode="over" position="end">
        <!-- Dynamic Main Heading Section -->
        <div class="main-heading-block">
          <h4><span>{{ guideTitle }}</span></h4> <!-- Dynamically changing title -->
        </div>
        <div class="border-box">
          <div class="btn-block header">
            <ul class="padding-0 margin-0 border-box-list">
              <li>
                <button type="button" class="btn skip-btn" (click)="closeSideBar()" title="Close">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>
              </li>
            </ul>
          </div>
          <!-- Video Guide UI -->
          <ng-container *ngIf="guideType === 'video' && containerData.length">
            <mat-accordion class="video-guide-accordion">
              <mat-expansion-panel *ngFor="let panel of containerData" [expanded]="panel.expanded">
                <mat-expansion-panel-header>
                  <mat-panel-title class="mat-panel-title">
                    {{ panel.title }} <!-- Dynamic title for each video panel -->
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <!-- Video guide content -->
                <div class="video-container">
                  <iframe width="100%" [src]="panel.videoUrl" frameborder="0" allowfullscreen></iframe>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </ng-container>
          <!-- Help Guide UI -->
          <ng-container *ngIf="guideType === 'help'">
            <div class="help-guide-content">
                <ul class="notification e-learning" *ngIf="eLearnData?.length > 0">
                    <li *ngFor="let e of eLearnData" (click)="eLearnPortal(e)">
                        <p [class.notification-group]="e.items.length > 0">{{e.title}}</p>
                        <ul *ngIf="e.items.length > 0" class="list-inline">
                            <li *ngFor="let m of e.items" (click)="eLearnPortal(m)">
                                <p>{{m.title}}</p>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
          </ng-container>
        </div>
      </mat-drawer>
    </mat-drawer-container>
  </div>