import { Component, OnInit , ViewChild,Inject,ElementRef,AfterViewInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { globalConstants } from '../../environments/globalConstants';
import { GlobalService } from '../../environments/global.service';
import { globalFunctions } from '../../environments/globalFunctions';
import {Http, Headers,RequestOptions, Response} from '@angular/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import { MatSliderChange } from '@angular/material/slider';
// import { AngularCropperjsComponent } from 'angular-cropperjs';
declare var jQuery:any;
declare var $ :any;
declare var Croppie: any;
@Component({
  selector: 'app-imagecroppie',
  templateUrl: './imagecroppie.component.html',
  styleUrls: ['./imagecroppie.component.scss']
})
export class ImagecroppieComponent implements AfterViewInit,OnInit {
  public imageUrl:any;
  public cropper:any;
  public zoomLevel:any;
  public oldratio:any;
  public ratio:any;
  public maxRatio = 1;
  public minRatio = 0;
  public eqValue:any;
  // public sliderValue=20;
  // @ViewChild('angularCropper') public angularCropper: AngularCropperjsComponent;
  constructor(private el:ElementRef,@Inject(MAT_DIALOG_DATA) public data: any,private http: Http, public dialogRef: MatDialogRef<ImagecroppieComponent>, private formBuilder: FormBuilder, private globalService: GlobalService) {
   
      // this.imageUrl=data.imgfile;
      this.zoomLevel = 0;
      this.eqValue = (this.maxRatio-this.minRatio)/100;
    var reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this);
    //reader.readAsDataURL(data.imgfile);
    reader.readAsDataURL(data.imgfile);
    this.globalService.diaLogObj(this.dialogRef);
   }

  ngAfterViewInit() {

  }
  ngOnInit() {  }

  _handleReaderLoaded(readerEvt) {
    const self = this;
    const el = document.getElementById('croppie');
    self.cropper = new Croppie(el, {
        viewport: { width: 100, height: 100 },
        boundary: { width: 300, height: 300 },
        showZoomer: true,
        enableResize: true,
    });
    self.cropper.bind({
        url: readerEvt.target.result,
    });
  }

  getCropData() {
    const pageContext = this;
    this.cropper.result('base64').then(function(base64Data) {
      pageContext.cropper.destroy();
      pageContext.dialogRef.close({ data: base64Data });
    });
  }

  clearCropper() {
    this.cropper.destroy();
    this.dialogRef.close({ data: null });
  }



}
