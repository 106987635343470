<!--
  @author Shashank Thakur
  @trackID CAR-1412
  @updated 20-03-2020
-->
<div class="panel panel-primary">
  <div class="panel-heading padding-left-15">
    <h3 class="panel-title">Service Request Log Detail</h3>
    <button mat-mini-fab class="custom-btn-back" (click)="gs.goBack();">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <div class="panel-body bgcolor-lightgrey">
    <div class="jumbotron padding-25 car-view-str">
      <div class="row">
        <div class="col-sm-6 col-md-6">
          <h3 class="margin-0">Service Request Information</h3>
        </div>
        
      </div>
      <hr>

      <form id="viewServiceRequestForm" class="form-horizontal" role="form">
        <!-- Replaced div grid structure with table -->
        
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Request ID:</label></div>
              <div class="col-sm-6">{{logDetail.request_id}}</div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Shop Name :</label></div>
              <div class="col-sm-6 break-word">
                {{logDetail.service_facility_name || logDetail.company_name}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Vehicle :</label></div>
              <div class="col-sm-6" style="word-break: break-word;">
                <span *ngIf="vehicle_pic ==null || vehicle_pic ==undefined || vehicle_pic.length == 0">
                  <span *ngIf="logDetail.year !=undefined && logDetail.make !=undefined && logDetail.model !=undefined" [innerHTML]="vehicleName ? vehicleName:'N/A'">
                    
                  </span>
                </span>
                <div *ngIf="vehicle_pic !=null && vehicle_pic !=undefined && vehicle_pic.length != 0">
                <span class="tooltip-status" (click)="imageToolDialog($event,0,1,[{'vehicle_pic':vehicle_pic}]);">{{logDetail.year}}/{{logDetail.make}}/{{logDetail.model}}</span>
                </div>
              
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>License Plate :</label></div>
              <div class="col-sm-6 break-word text-uppercase">
                {{logDetail.license_plate || 'N/A'}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Title :</label></div>
              <div class="col-sm-6">
                {{logDetail.title || 'N/A'}}
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>VIN Number :</label></div>
              <div class="col-sm-6 col-sm-6 break-all text-uppercase">
                <span *ngIf="vin_photo ==null || vin_photo ==undefined || vin_photo.length == 0">
                  <span *ngIf="logDetail.vin">
                    {{logDetail.vin | slice:0:-8}}<span style="color:#eb1e4c">{{logDetail.vin.substr(logDetail.vin.length - 8)}}</span>
                  </span>
                  <span *ngIf="!logDetail.vin">
                    N/A
                  </span>
                </span>
                <div *ngIf="vin_photo !=null && vin_photo !=undefined && vin_photo.length != 0">
                    <span class="text-uppercase tooltip-status" (click)="imageToolDialog($event,0,5,vin_photo);" tooltipPlacement="left"  *ngIf="logDetail.vin!=null" style="display: inline-block;">
                      <span *ngIf="logDetail.vin">
                        {{logDetail.vin | slice:0:-8}}<span style="color:#eb1e4c">{{logDetail.vin.substr(logDetail.vin.length - 8)}}</span>
                      </span>
                    </span>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Customer Name :</label></div>
              <div class="col-sm-6">
                {{logDetail.first_name}} {{logDetail.last_name}} 
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Requested Date :</label></div>
              <div class="col-sm-6 break-word">
                {{formatDate(logDetail.requested_delivery_date)}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Mobile Number :</label></div>
              <div class="col-sm-6" >
                <a class="text-black" *ngIf="isAllowed('views','view_customer_info')" href="tel:{{logDetail.mobile_phone}}">{{logDetail.mobile_phone}}</a>
                <span *ngIf="isAllowed('views','view_customer_info')==false">-</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Scheduled Date :</label></div>
              <div class="col-sm-6" *ngIf="logDetail.schedule_service_date!='0000-00-00 00:00:00'">
                {{formatDate(logDetail.schedule_service_date)}}
              </div>
              <div class="col-sm-6" *ngIf="logDetail.schedule_service_date=='0000-00-00 00:00:00'">
                 N/A
                </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Present Mileage (Miles in) :</label></div>
              <div class="col-sm-6">
                {{logDetail.present_mileage}}
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Estimated Date :</label></div>
              <div class="col-sm-6" *ngIf="logDetail.estimated_delivery_date!='0000-00-00 00:00:00'">
                {{formatDate(logDetail.estimated_delivery_date)}}
              </div>
              <div class="col-sm-6" *ngIf="logDetail.estimated_delivery_date=='0000-00-00 00:00:00'">
                  N/A
                </div>
            </div>
          </div>
        </div>
        <div class="row">
            <div class="col-sm-6 margin-bottom-10">
                <div class="row">
                  <div class="col-sm-6"><label>Present Mileage (Miles out) :</label></div>
                  <div class="col-sm-6" [innerHTML]="logDetail.present_mileage_out != '0' ? logDetail.present_mileage_out : '-'"> </div>
                  <div class="col-sm-6" *ngIf="!logDetail.present_mileage_out" [innerHTML]="'N/A'"> </div>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-sm-12 margin-bottom-10">
            <div class="row">
              <div class="col-sm-3"><label>Notes :</label></div>
              <div class="col-sm-9 break-all" [innerHtml]="getNltoBr(logDetail.notes)">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 margin-bottom-10 hide">
            <div class="row">
              <div class="col-sm-3"><label>Download Report :</label></div>
              <div class="col-sm-9">
                <span id="downloadReportUPSP" *ngIf="reportDownloadType==1" >
                  <a class="text-black" href="javascript:void(0)" (click)="invokeDownload(reportDownloadLink,'CARInspectionReport')">(USPS-PMI-PS-Form)<i class="fa fa-download fa-fw "></i></a>
                </span>
                <span id="downloadReportACDELCO" *ngIf="reportDownloadType==2">
                  <a class="text-black" href="javascript:void(0)" (click)="invokeDownload(reportDownloadLink,'CARInspectionReport')">(CAR Inspection Report)<i class="fa fa-download fa-fw "></i></a>
                </span>
                <span id="noReportPerformed" *ngIf="reportDownloadType==0">
                  No Report Performed
                </span>
              </div>
            </div>
          </div>
        </div>
       <div class="row">
        <div class="col-sm-6  margin-bottom-5">
          <div class="row">
              <div class="col-sm-6"><label class="control-label">Status :</label></div>
              <div class="col-sm-6">
                  <mat-chip-list class="mat-badge">
                      <mat-chip [color]="getStatus() == 'Closed' ? 'warn' : getStatus() == 'Created' ? 'accent' : 'primary'" selected="true">
                       <span > {{getStatus()}}</span>
                      </mat-chip>
                  </mat-chip-list>
              </div>
          </div>
         </div>
         
        </div>
        <div class="row" *ngIf="(logDetail.request_documents!=null && logDetail.request_documents.length > 0) || (logDetail.request_documents!=null && logDetail.request_images.length > 0)">
          <div class="col-sm-12 upload_documents_section margin-bottom-15">
            <h4 class="document__title" (click)="toggleShowDiv()"><strong>Documents &amp; Images</strong>
            </h4>
            <div class="col-sm-12 show-documents" [@slideInOut]="animationState">
              <div class="col-sm-6" *ngIf="logDetail.request_documents!=null && logDetail.request_documents.length > 0">
                <div class="row">
                  <div class="col-sm-12 margin-bottom-5"><label>Document(s)</label></div>
                  <div class="col-sm-12 text-danger">

                      <a *ngFor="let doc of logDetail.request_documents" style="display:inline-block;margin:0px 5px 5px 0px;position:relative;" href="javascript:void(0)" (click)="invokeDownload(bucket_url+'Service-Request-Documents/'+doc.document_name,'ServiceRequestDocument',doc.document_name)" class="thumbnail">
                        <span class="fa far fa-file-pdf fa-4x" *ngIf="getFileIcons(doc.document_name)==3"></span>
                        <span class="fa far fa-file-word fa-4x" *ngIf="getFileIcons(doc.document_name)==4"></span>
                      </a>

                  </div>
                </div>
              </div>
              <div class="col-sm-6" *ngIf="logDetail.request_documents.length == 0">
                <div class="col-sm-12 margin-bottom-5"><label> </label></div>
                <div class="col-sm-12 text-danger"> No documents uploaded.</div>
             </div>
              <div class="col-sm-6" *ngIf="logDetail.request_images!=null && logDetail.request_images.length > 0">
                <div class="row">
                  <div class="col-sm-12 margin-bottom-5"><label>Image(s)</label></div>
                  <div class="col-sm-12">
                    <ul class="list-inline margin-bottom-0">
                      <li class="relative view-images" *ngFor="let image of logDetail.request_images;let i=index;">
                        <a (click)="imageToolDialog($event,i,8,logDetail.request_images)" class="thumbnail" href="javascript:void(0);">
                          <img style="width:100px;height:75px;" src="{{bucket_url}}Service-Request-Pictures/thumb/{{image.image_name}}">
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-sm-6" *ngIf="logDetail.request_images.length == 0">
                <div class="col-sm-12 margin-bottom-5"><label> </label></div>
                <div class="col-sm-12 text-danger"> No images uploaded.</div>     </div>
            </div>
          </div>
        </div>
        <!-- END -->
      </form>
    </div>
    <mat-accordion class="new-request-expension view-request show-price margin-bottom-10 display-full" displayMode="flat" hideToggle="false" >
      <mat-expansion-panel [expanded]="a==0" hideToggle="true"  *ngFor="let service_request of logDetail.service_request_list;let a=index" id="expansion-{{a+1}}" >
        <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="expansionHead-{{a+1}}" (click)="expansionOpen(a+1);" [attr.data-state]="a==0" >
          {{a+1}}. {{ service_request.name }}
        </mat-expansion-panel-header>
        <ul class="service-list-items">
          <li class="topPad" *ngFor="let servicerequestChild of service_request.children;let i = index">
            <p>{{i+1}}) {{servicerequestChild.name}}</p>
            <ul class="service-list-sub-items no-style" >
              <li >
                <div class="row">

                  <div *ngFor="let recommendation of servicerequestChild.recommendations;let j = index">
                    <div *ngIf="recommendation.service_request_list_id!='158'">
                      <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.parts.length > 0">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Part(s):</label>
                          <div class="col-sm-9 col-lg-10 text-justify">
                            <div class="addedPartsItems">
                              <div class="partRow row" [class.text-orange]="part.partstech_cart_item==1 || part.partstech_order_item==1" style="padding-top:3px;" *ngFor="let part of recommendation.parts;let k = index">
                                <div class="col-sm-8 pname break-all"><span *ngIf="part.part_number!=''">{{part.part_number}} </span><span *ngIf="isAllowed('views','display_part_cost') || (part.partstech_cart_item==1 || part.partstech_order_item==1)">(${{part.cost}}) -</span> {{part.name}}<span *ngIf="part.description!=''"> - {{part.description}}</span></div>
                                <div class="col-sm-2 pprice "><span *ngIf="showCostPrice || (part.partstech_cart_item==1 || part.partstech_order_item==1)">$ {{ parseFloats(part.part_price)}}</span></div>
                                <div class="col-sm-2 pquantity" [class.text-red]="isInventory && part.out_of_stock=='1'">
                                  <i *ngIf="isInventory" class="fa fa-info-circle" title="{{ stockInHand(part) }} "></i> {{part.quantity}}
                                  <!-- <button type="button" class="btn btn-xs btn-link text-red" (click)="addStockOrder(recommendation,part)"><i *ngIf="isInventory && part.out_of_stock=='1'" class="fa fa-cart-plus fa-lg" title="Add to stock order"></i></button> -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.generic_jobs.length > 0">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Generic Job(s):</label>
                          <div class="col-sm-9 col-lg-10 text-justify">
                            <div class="addedGenericJobs">
                              <div class="partRow row" style="padding-top:3px;" *ngFor="let gjob of recommendation.generic_jobs;let l = index">
                                <div class="col-sm-8 pname">{{gjob.name}}</div>
                                <div class="col-sm-2 pprice" *ngIf="showCostPrice">$ {{ parseFloats(gjob.price) }}</div>
                                <div class="col-sm-2 ppqty">{{gjob.quantity}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.computer_codes.length > 0">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Computer Codes(s):</label>
                          <div class="col-sm-9 col-lg-10 text-justify">
                            <div class="addedGenericJobs">
                              <div class="partRow row" style="padding-top:3px;" *ngFor="let cjob of recommendation.computer_codes;let l = index">
                                <div class="col-sm-12 pname break-all">{{cjob.trouble_code}} {{cjob.fault_location}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.nexpart_labor_time > 0">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Nexpart Labor Time:</label>
                          <div class="col-sm-9 col-lg-10 text-justify break-all">
                            <div class="addedComputerCodes">{{recommendation.nexpart_labor_time}} hour(s) </div>
                          </div>
                        </div>
                      </div>


                      <div class="col-sm-12 margin-bottom-15">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Estimated Time:</label>
                          <div class="col-sm-9 col-lg-10 text-justify break-all">
                            <div class="addedComputerCodes">{{recommendation.estimated_time}} hour(s) </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-12 margin-bottom-15" *ngIf="showCostPrice">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Estimated Labor:</label>
                          <div class="col-sm-9 col-lg-10 text-justify break-all">
                            <div class="addedComputerCodes">$ {{ parseFloats(recommendation.estimated_labor)}}</div>
                          </div>
                        </div>
                      </div>


                      <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.service_request_list_id!=700">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Description of Work:</label>
                          <div class="col-sm-9 col-lg-10 text-justify break-all" >
                            <p [innerHtml]="getNltoBr(recommendation.recommendation)"></p>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.service_request_list_id!=700">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Notes:</label>
                          <div class="col-sm-9 col-lg-10 text-justify break-all">
                            <p [innerHtml]="getNltoBr(recommendation.notes)"></p>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 margin-bottom-15 already-uploaded-images" *ngIf="recommendation.service_request_list_id!=700">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Uploaded Image(s):</label>
                          <div class="col-sm-9 col-lg-10 text-justify">
                            <ul class="list-inline margin-bottom-0" *ngIf="recommendation.uploaded_images.length > 0">
                              <li  class="relative view-images" *ngFor="let image of recommendation.uploaded_images;let i=index;">
                                <a (click)="imageToolDialog($event,i,4,recommendation.uploaded_images)" class="thumbnail" href="javascript:void(0);">
                                  <img class="show-image" src="{{bucket_url}}recommendation-pictures/thumb/{{image.filename}}">
                                </a>
                              </li>
                            </ul>
                            <span class="text-danger" *ngIf="recommendation.uploaded_images.length == 0">
                              <span>No images uploaded.</span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 margin-bottom-15 already-uploaded-videos" *ngIf="recommendation.service_request_list_id!=700">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Uploaded Video(s):</label>
                            <div class="col-sm-9 col-lg-10 text-justify" *ngIf="recommendation.uploaded_videos.length > 0">
                              <ul id="vehicle_video-ul" class="list-inline margin-bottom-0">
                                <li class="video-upload relative" *ngFor="let video of recommendation.uploaded_videos;let i=index">
                                  <a class="thumbnail margin-bottom-0" href="javascript:void(0)" (click)="videoShow(video.filename)">
                                    <span class="relative video-bg">
                                      <mat-icon class="position-center play-video">play_circle_filled</mat-icon>
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          <div class="col-sm-9 col-lg-10 text-justify" *ngIf="recommendation.uploaded_videos.length == 0">
                            <span class="text-danger">No videos uploaded.
                              <span></span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-12 notranslate" *ngIf="recommendation.service_request_list_id!=700">
                        

                        <div class="display-full text-right">
                          <mat-button-toggle value="Accepted" class="toogle-btn btn-success" *ngIf="recommendation.approval_status=='1'" >Accepted</mat-button-toggle>
                          <mat-button-toggle value="Rejected" class="toogle-btn btn-danger" *ngIf="recommendation.approval_status=='2'" >Rejected</mat-button-toggle>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li  *ngFor="let secondChild of servicerequestChild.children;let j = index">
                <p>{{secondChild.name}}</p>

                <p class="service-list-sub-items" *ngIf="secondChild.input == 'imageupload'">
                  <span style="padding-left: 58px;" class="relative view-images">
                    <a (click)="imageToolDialog($event,0,9,[secondChild])" class="thumbnail" href="javascript:void(0);">
                        <img src="{{bucket_url}}service-pictures/thumb/{{secondChild.value}}"/>
                    </a>
                  </span>
                </p>

                <p class="service-list-sub-items" *ngIf="secondChild.input == 'textarea'">
                  <span style="padding-left: 58px;word-wrap: break-word ; display:block" [innerHtml]="getNltoBr(secondChild.value)">
                  </span>
                </p>
                <div class="row">

                  <div *ngFor="let recommendation of secondChild.recommendations;let j = index">
                    <div *ngIf="recommendation.service_request_list_id!='158'">
                    <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.parts.length > 0">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Part(s):</label>
                        <div class="col-sm-9  col-lg-10 text-justify">
                          <div class="addedPartsItems">
                            <div class="partRow row" [class.text-orange]="part.partstech_cart_item==1 || part.partstech_order_item==1" style="padding-top:3px;" *ngFor="let part of recommendation.parts;let k = index">
                              <div class="col-sm-8 pname break-all"><span *ngIf="part.part_number!=''">{{part.part_number}} </span><span *ngIf="isAllowed('views','display_part_cost') || (part.partstech_cart_item==1 || part.partstech_order_item==1)">(${{part.cost}}) -</span> {{part.name}}<span *ngIf="part.description!=''"> - {{part.description}}</span></div>
                              <div class="col-sm-2 pprice"><span *ngIf="showCostPrice || (part.partstech_cart_item==1 || part.partstech_order_item==1)">$ {{parseFloats(part.part_price)}}</span></div>
                              <div class="col-sm-2 pquantity" [class.text-red]="isInventory && part.out_of_stock=='1'">
                                <i *ngIf="isInventory" class="fa fa-info-circle" title="{{ stockInHand(part)}} "></i> {{part.quantity}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.generic_jobs.length > 0">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Generic Job(s):</label>
                        <div class="col-sm-9 col-lg-10 text-justify">
                          <div class="addedGenericJobs">
                            <div class="partRow row" style="padding-top:3px;" *ngFor="let gjob of recommendation.generic_jobs;let l = index">
                              <div class="col-sm-8 pname">{{gjob.name}}</div>
                              <div class="col-sm-2 pprice" *ngIf="showCostPrice">$ {{ parseFloats(gjob.price)}}</div>
                              <div class="col-sm-2 ppqty">{{gjob.quantity}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.computer_codes.length > 0">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Computer Codes(s):</label>
                        <div class="col-sm-9 col-lg-10 text-justify">
                          <div class="addedGenericJobs">
                            <div class="partRow row" style="padding-top:3px;" *ngFor="let cjob of recommendation.computer_codes;let l = index">
                              <div class="col-sm-12 pname break-all">{{cjob.trouble_code}} {{cjob.fault_location}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                      <div class="col-sm-12 margin-bottom-15"  *ngIf="recommendation.nexpart_labor_time > 0" >
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Nexpart Labor Time:</label>
                          <div class="col-sm-9 col-lg-10 text-justify break-all">
                            <div class="addedComputerCodes">{{recommendation.nexpart_labor_time}} hour(s) </div>
                          </div>
                        </div>
                      </div>

                    <div class="col-sm-12 margin-bottom-15">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Estimated Time:</label>
                        <div class="col-sm-9 col-lg-10 text-justify break-all">
                          <div class="addedComputerCodes">{{recommendation.estimated_time}} hour(s) </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-12 margin-bottom-15" *ngIf="showCostPrice">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Estimated Labor:</label>
                        <div class="col-sm-9 col-lg-10 text-justify break-all">
                          <div class="addedComputerCodes">$ {{ parseFloats(recommendation.estimated_labor)}}</div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="col-sm-12 margin-bottom-15">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Description of Work:</label>
                        <div class="col-sm-9 col-lg-10 text-justify break-all">
                          <p [innerHtml]="getNltoBr(recommendation.recommendation)"></p>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 margin-bottom-15">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Notes:</label>
                        <div class="col-sm-9 col-lg-10 text-justify break-all">
                          <p [innerHtml]="getNltoBr(recommendation.notes)"></p>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 margin-bottom-15 already-uploaded-images">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Uploaded Image(s):</label>
                        <div class="col-sm-9 col-lg-10 text-justify">
                          <ul class="list-inline margin-bottom-0" *ngIf="recommendation.uploaded_images.length > 0">
                            <li  class="relative view-images" *ngFor="let image of recommendation.uploaded_images;let i=index;">
                              <a (click)="imageToolDialog($event,i,4,recommendation.uploaded_images)" class="thumbnail" href="javascript:void(0);">
                                <img class="show-image" src="{{bucket_url}}recommendation-pictures/thumb/{{image.filename}}">
                              </a>
                            </li>
                          </ul>
                          <span class="text-danger" *ngIf="recommendation.uploaded_images.length == 0">
                            <span>No images uploaded.</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 margin-bottom-15 already-uploaded-videos">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Uploaded Video(s):</label>
                          <div class="col-sm-9 col-lg-10 text-justify" *ngIf="recommendation.uploaded_videos.length > 0">
                            <ul id="vehicle_video-ul" class="list-inline margin-bottom-0">
                              <li class="video-upload relative" *ngFor="let video of recommendation.uploaded_videos;let i=index">
                                <a class="thumbnail margin-bottom-0" href="javascript:void(0)" (click)="videoShow(video.filename)">
                                  <span class="relative video-bg">
                                    <mat-icon class="position-center play-video">play_circle_filled</mat-icon>
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        <div class="col-sm-9  col-lg-10 text-justify" *ngIf="recommendation.uploaded_videos.length == 0">
                          <span class="text-danger">No videos uploaded.
                            <span></span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-12 notranslate">

                      <div class="display-full text-right" >
                        <mat-button-toggle value="Accepted" class="toogle-btn btn-success" *ngIf="recommendation.approval_status=='1'">Accepted</mat-button-toggle>
                        <mat-button-toggle value="Rejected" class="toogle-btn btn-danger" *ngIf="recommendation.approval_status=='2'">Rejected</mat-button-toggle>
                      </div>
                    </div>
                    </div>
                  </div>

                </div>
              </li>
            </ul>
          </li>
        </ul>
        <!-- <div class="panel-price" *ngIf="ConvertToNumber(logDetail.request_status) >=3 && ConvertToNumber(logDetail.request_status) <9">
          <div class="panel-footer rejectedJobs col-sm-6" role="tab">
            <h4 class="text-right" style="margin:0;"><span>Rejected Job(s) Cost&nbsp;:&nbsp;$</span>
              <span class="total-job-price-63 notranslate">
                {{ this.ObjectJson[service_request.id]["RejectedValue"] }}
              </span>
            </h4>
          </div>
          <div class="panel-footer acceptedJobs col-sm-6" role="tab">
            <h4 class="text-right" style="margin:0;"><span>Total Price&nbsp;:&nbsp;$</span>
              <span class="total-job-price-63 notranslate">
                {{this.ObjectJson[service_request.id]["AcceptedValue"] }}
              </span>
            </h4>
          </div>
        </div> -->
      </mat-expansion-panel>
    </mat-accordion>
    <div class="disclaimer-and-totals-container notranslate">
      <!-- <h4 class="text-right"><strong>Sub Total : </strong>$<span class="grand-total"> {{subTotal}}</span></h4> -->
        <h4 class="text-right"><strong>Grand Total : </strong>$ <span class="grand-total-with-tax">{{ logDetail.grand_total }}</span></h4>
        <!-- <h4 class="text-right"><strong>Total Paid : </strong>$ <span id="totalPaid">{{serviceInfo.value.total_paid == null ? "0.00" : serviceInfo.value.total_paid}}</span></h4>
        <h4 class="text-right"><strong>Balance : </strong>$<span id="balance"> {{ balance }}</span></h4> -->
       <div>
        
        
      </div>
    </div>
  </div>
</div>
