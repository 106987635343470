/**
 * @trackID <:CAR-1419>
 * @updated 20-03-2020
 * @updatedBy Shashank Thakur
 */
import { Component,Input, OnInit, Inject, Optional, OnDestroy} from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS, } from '@angular/material/core';
import { MatTab} from '@angular/material/tabs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {Location, LocationStrategy, PathLocationStrategy, DatePipe} from '@angular/common';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';

export class AppDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
   if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
     const str = value.split('/');
     const year = Number(str[2]);
     const month = Number(str[1]) - 1;
     const date = Number(str[0]);
     return new Date(year, month, date);
   }
   const timestamp = typeof value === 'number' ? value : Date.parse(value);
   return isNaN(timestamp) ? null : new Date(timestamp);
 }

  format(date: Date, displayFormat: Object): string {
      if (displayFormat == "input") {
          let day = date.getDate();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();
          return this._to2digit(month)+ '/' + this._to2digit(day)  + '/' + year;
      } else {
          return date.toDateString();
      }
  }

  private _to2digit(n: number) {
      return ('00' + n).slice(-2);
  }
}

export const APP_DATE_FORMATS =
{
  parse: {
      dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
  },
  display: {
      // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
      dateInput: 'input',
      monthYearLabel: { month: 'short', year: 'numeric', day: 'numeric' },
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'},
  }
}
declare var $ :any;
@Component({
  selector: 'app-service-request-logs',
  templateUrl: './service-request-logs.component.html',
  styleUrls: ['./service-request-logs.component.scss'],
  providers: [MatTab, DatePipe,
    {
        provide: DateAdapter, useClass: AppDateAdapter
    },
    {
        provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
 ]
})
export class ServiceRequestLogsComponent implements OnInit,OnDestroy {
  public openSearch = false;
  public userData;
  public request_id: any = '';
  public technicians = [{id:'',text:"Select Employee"}];
  public content = '';
  public filterAction = [{id:'',name:"Select Action"},{ id: "underProcess", name: "Under Process" }, { id: "assignment", name: "Assignment/Remove" }, { id: "employee", name: "Employee Action" }, { id: "recommendation", name: "Recommendation" }, { id: "recomToCustomer", name: "Update Recommendation to Customer" }, { id: "custApproval", name: "Customer Approval" }, { id: "workProgress", name: "Work in Progress" }, { id: "payment", name: "Payment" }, { id: "editMode", name: "Request Edit Mode" }, { id: "completion", name: "Request Complition" }, {id:"markAsPaid",name:"Mark as Paid"}, { id: "bwtDate", name: "By Date" }, { id: 'reopenTicket', name: 'Re-Open'}];
  public fillActionValue = '';
  public filterTech = '';
  public logs = [];
  public docImgPath = globalConstants.S3_BUCKET_URL + globalConstants.EMP_DOCUMENT + globalConstants.IMG_THUMB;
  public fromDate='';
  public toDate='';
  public roles=globalConstants.ROLES;
  public isEmployee=false;
  public request_id_logs:any;
  public isMatDialogOn = false;
  public subscriptionDataForAPI: Subscription = new Subscription();

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,public globalService: GlobalService, public router: Router, private activatedRoute: ActivatedRoute,@Optional() public dialogRef: MatDialogRef<ServiceRequestLogsComponent>) {
    this.userData = this.globalService.getCurrentUser();
    this.content = 'user_id=' + this.userData.id + '&user_type=' + this.userData.user_type;
    if (this.userData.role_id == 4) {
      this.router.navigate(['shops/action/forbidden']);
    }
    setTimeout(()=>{
      let isEnable=this.globalService.getAcl('service_request','views');
      if(!isEnable){
       this.router.navigate(['shops/action/forbidden']);
      }
    },globalConstants.RELOAD_WAIT)
    this.globalService.setMetaData("SHOPS","SERVICE_REQUEST_LOGS")
  }

  ngOnInit() {
    this.getParams();
    // console.log('log'+ this.isServiceRequestLogs);
    this.isEmployee=this.globalService.isSubscribe('employee');
    this.content = this.content + '&request_id=' + this.request_id;
    let data = this.content;
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_SERVICE_REQUEST_TRACKERS, data).subscribe((r:any) => {
      if (r.result == 1 && r.data.length > 0) {
        for(var i in r.data){
          r.data[i].text=r.data[i].first_name+' '+r.data[i].last_name;
          r.data[i].id=r.data[i].user_id;
          this.technicians.push(r.data[i]);
        }
        // this.technicians = r.data;
      }
    });
    this.search();
  }
 
  getParams() {
    if(this.data){ //component called from popup
      this.isMatDialogOn = true;
      this.request_id = this.data.request_id;
      if(this.data.user_id){
        this.filterTech = this.data.user_id;
        this.openSearch = true;
      }
    }else{
      this.activatedRoute.params.subscribe((params: Params) => {
        this.request_id = params["id"];
      });
    }
  }
  search() {
    let data = this.content + "&filterAction=" + this.fillActionValue + "&technician=" + this.filterTech;
    if(this.fromDate!=''){
      data =data+'&from='+ this.dateFormat(this.fromDate);
    }
    if(this.toDate!=''){
      data =data+'&to='+ this.dateFormat(this.toDate);
    }
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_SERVICE_REQUEST_LOGS, data).subscribe((r:any) => {
      if (r.result == 1) {
        this.logs = r.data;
      }else{
        // this.globalService.snackbar("error", r.message);
        this.logs = [];
      }
    });
  }
  clearSearch() {
    this.fillActionValue = '';
    this.filterTech = '';
    this.fromDate='';
    this.toDate='';
    this.search();
  }
  isAnyOptionActive(button) {
    if (this.fillActionValue != '' || this.filterTech != '') {
      return true;
    } else {
      return false;
    }
  }
  isProfilePic(src) {
    if (src == null || src == '') {
      return false;
    } else {
      return true;
    }
  }
  isProfilePlaceholder(src) {
    if (src == null || src == '') {
      return true;
    } else {
      return false;
    }
  }
  setTouchViewValue() {
    return true;
  }
  dateChange(event,type){
    if(type==1){
      this.toDate=this.fromDate;
    }
  }
  dateFormat(dateValue) {
    return ((new Date(dateValue).getMonth()+1) < 10 ? '0' : '')+(new Date(dateValue).getMonth()+1) + "/" + (new Date(dateValue).getDate() < 10 ? '0' : '')  + new Date(dateValue).getDate() + "/" + new Date(dateValue).getFullYear();
 }
 getRoles(id){
  return (typeof this.roles[id] != 'undefined' ? this.roles[id] :'');
 }
 viewDetail(log){
   if(this.isMatDialogOn){
      let logWindow = window.open("shops/service-request/logs-detail")
      logWindow.localStorage.setItem('logView',log.old_record_value)
   }else{
      this.globalService.getSetItems('logView',log.old_record_value);
      this.router.navigate(['shops/service-request/logs-detail']);
   }
 }
 formatDate(data){
  return this.globalService.getFullFormateDate(data,'G','H');
 }

 ngOnDestroy(){
  this.subscriptionDataForAPI.unsubscribe();
}  
}
