import { Component, OnInit,Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
@Component({
  selector: 'app-notifyjob',
  templateUrl: './notifyjob.component.html',
  styleUrls: ['./notifyjob.component.scss']
})
export class NotifyjobComponent implements OnInit {
  public userData;
  public param;
  public taskTrack=false;
  public userTaskTrack:any=0;
  constructor(public dialogRef: MatDialogRef<NotifyjobComponent>,@Inject(MAT_DIALOG_DATA) public data: any, public globalService: GlobalService) {
    this.param = data;
  }

  ngOnInit() {
    this.userData=this.globalService.getCurrentUser();
    this.taskTrack=this.param.taskTrack ;
    this.userTaskTrack =this.param.userTaskTrack;
    this.globalService.diaLogObj(this.dialogRef);
  }
  jobDone(type){
    this.dialogRef.close(type);
  }
}
