import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { globalFunctions } from '../../../../environments/globalFunctions';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-vehicle',
  templateUrl: './add-vehicle.component.html',
  styleUrls: ['./add-vehicle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddVehicleComponent implements OnInit {

  public newVehicle: FormGroup
  public carMakeList;
  public yearsList
  public modelList
  public newMake = false
  public newYear= false
  public newModel = false
  public disabledYear = true
  public disabledModel = true;
  public param:any=null;
  public user:any=null;
  public selectedYear : any = '';
  public selectedModel: any = '';
  constructor( @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AddVehicleComponent>,private frmbuild: FormBuilder, public gs: GlobalService) { 
    this.param=data
    this.user=this.gs.getCurrentUser()
    this.gs.diaLogObj(this.dialogRef);
    if(!(typeof this.param.qq!='undefined' && this.param.customer_id!='' && this.param.qq && this.param.customer_id)){
      this.dialogRef.close(0)
      this.gs.snackbar("error", 'Please select cutomer.');
    }
  }

  ngOnInit() {

      this.newVehicle = this.frmbuild.group({
        year: ['', [Validators.minLength(4), Validators.pattern('^[12][0-9]{3}$')]],
        make: [''],
        model: [''],
        sel_make: [''],
        sel_year: [''],
        sel_model: [''],
        is_other_make:[1],
        user_id:this.user.id,      
        customer_id:this.param.customer_id
      })
      this.gs.callAPI(globalConstants.API_GET_CAR_MAKES, "opr=1").subscribe((data:any) => {
        this.carMakeList = globalConstants.carMakeDDLPlaceHolder.concat(data.data);
        /* FOR SEARCH BOX DROPDOWN */
        var makelist = [];
        for (var i in this.carMakeList) {
          this.carMakeList[i].id = this.carMakeList[i].make;
          this.carMakeList[i].text = this.carMakeList[i].make;
          makelist.push(this.carMakeList[i]);
        }
        this.carMakeList = makelist;
      }, (error) => { })
  }
  /** make change */
  getOptionMakes(opt) {
    console.log("this is opt",opt);
    if(opt !=='Select Make'){
      this.newVehicle.controls.sel_make.setValue(opt);
      this.newVehicle.controls.make.setValue(opt);
      this.newVehicle.controls.sel_year.setValue('');
      this.newVehicle.controls.year.setValue('');
      this.newVehicle.controls.sel_model.setValue('');
      this.newVehicle.controls.model.setValue('');
      this.selectedYear = 'Select Year';
      this.selectedModel = 'Select Model';
      this.getYears(opt);
    }else{
    this.newVehicle.controls.sel_make.setValue('');
    this.newVehicle.controls.make.setValue('');
    this.newVehicle.controls.sel_year.setValue('');
    this.newVehicle.controls.year.setValue('');
    this.newVehicle.controls.sel_model.setValue('');
    this.newVehicle.controls.model.setValue('');
    this.disabledYear = true
    this.disabledModel = true;
    this.selectedYear = 'Select Year';
    this.selectedModel = 'Select Model';
    }
  }
  /** get list of Years */
  getYears(opt) {
    this.gs.callAPI(globalConstants.API_GET_YEAR_LIST, "opr=1&make=" + opt).subscribe((data:any) => {
      if (data.result == "1") {
        this.disabledYear = false
        var yearsList = [];
        if (data.data.length != undefined) {
          this.yearsList = [];
          this.yearsList = globalConstants.carYearDDLPlaceHolder.concat(data.data);
          /* FOR SEARCH BOX DROPDOWN SELECT */
          for (var i in this.yearsList) {
            this.yearsList[i].id = this.yearsList[i].year;
            this.yearsList[i].text = this.yearsList[i].year;
          }
        } else {
          this.yearsList = [];
          this.yearsList[0] = data.data
        }
      } else {
      }
    }, (error) => { });
  }
  /** year change */
  getOptionYears(opt) {
    if(opt !== 'Select Year'){
    this.newVehicle.controls.sel_model.setValue('');
    this.newVehicle.controls.model.setValue('');
    this.newVehicle.controls.sel_year.setValue(opt);
    this.newVehicle.controls.year.setValue(opt);
    this.selectedModel = 'Select Model';
    this.disabledModel = true;
    this.getModelList(opt);
    }else{
      this.newVehicle.controls.sel_model.setValue('');
      this.newVehicle.controls.model.setValue('');
      this.newVehicle.controls.sel_year.setValue('');
      this.newVehicle.controls.year.setValue('');
      this.selectedModel = 'Select Model';
      this.disabledModel = true;
    }
  }
  /** get model lists */
  getModelList(opt) {
    this.gs.callAPI(globalConstants.API_GET_MODELS_LIST_URL, "opr=1&make=" + this.newVehicle.value.make + "&year=" + opt).subscribe((data:any) => {
      if (data.result == "1") {
        this.disabledModel = false;
        var modelsList = [];
        if (data.data.length == undefined) {
          this.modelList = [];
          this.modelList[0] = globalConstants.carModelDDLPlaceholder.concat(data.data);

          this.modelList.id = this.modelList.model + (this.modelList.trim ? ' ' + this.modelList.trim : '') + (this.modelList.body_style ? ' ' + this.modelList.body_style : '')
          this.modelList.text = this.modelList.model + (this.modelList.trim ? ' ' + this.modelList.trim : '') + (this.modelList.body_style ? ' ' + this.modelList.body_style : '')

        } else {
          this.modelList = [];
          this.modelList = globalConstants.carModelDDLPlaceholder.concat(data.data);
          for (var i in this.modelList) {
            this.modelList[i].id = this.modelList[i].model + (this.modelList[i].trim ? ' ' + this.modelList[i].trim : '') + (this.modelList[i].body_style ? ' ' + this.modelList[i].body_style : '')
            this.modelList[i].text = this.modelList[i].model + (this.modelList[i].trim ? ' ' + this.modelList[i].trim : '') + (this.modelList[i].body_style ? ' ' + this.modelList[i].body_style : '')
          }
        }
      } else {

      }
    }, (error) => { })
  }
  /** get and set model */
  getOptionModels(opt) {
    if (opt !== 'Select Model') {
    this.newVehicle.controls.sel_model.setValue(opt);
    this.newVehicle.controls.model.setValue(opt);
    }else{
      this.newVehicle.controls.sel_model.setValue('');
      this.newVehicle.controls.model.setValue('');
    }
  }
  /** only numeric */
  onlyNumericKey(event) {
    return globalFunctions.onlyDecimalNumberKey(event);
  }
  /** submit vehicle data */
  addNewVehicle() {
    if(this.newMake || this.newYear || this.newModel){
      this.newVehicle.controls.is_other_make.setValue(1)
    }else{
      this.newVehicle.controls.is_other_make.setValue(1)
    }

    if(this.newVehicle.valid){
    let data=this.newVehicle.value
    this.gs.callAPI(globalConstants.API_QQ_ADD_VEHICLE,data).subscribe((r:any)=>{
      if(r.result==1){
        this.gs.snackbar("success", r.message);
        this.dialogRef["car"]=r.data
        this.dialogRef.close(1)
      }else{
        this.gs.snackbar("error", r.message);
      }
    },(error)=>{})
  }
  }
  /**
   *  if another make
   *  change select to input
   *  and id existing make
   *  change input to select
   */
  anotherMake(cond){
    this.newMake=cond
    this.newYear=cond
    this.newModel=cond
  }
  /**
   *  if another year
   *  change select to input
   *  and id existing year
   *  change input to select
   */
  anotherYear(cond){
    this.newYear=cond
    this.newModel=cond
  }
  /**
   *  if another model
   *  change select to input
   *  and id existing model
   *  change input to select
   */
  anotherModel(cond){
    this.newModel=cond
  }

}
