import { Component, OnInit, ViewChild, Pipe, Inject } from '@angular/core';
import { globalConstants } from "../../../../environments/globalConstants";
import { GlobalService } from "../../../../environments/global.service";
import { globalFunctions } from "../../../../environments/globalFunctions";
import { Router, ActivatedRoute, ParamMap, NavigationEnd, Params } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { MatTableDataSource } from '@angular/material/table';
const SHOP_PART_LIST = 'shop-part-logs';


@Component({
  selector: 'app-partlog',
  templateUrl: './partlog.component.html',
  styleUrls: ['./partlog.component.scss']
})
export class PartlogComponent implements OnInit {
  public part_id = 0;
  displayedColumns = ['date_formate', 'name', 'designation', 'remark', 'Action'];
  dataSource: MatTableDataSource<PartLogData> = new MatTableDataSource();
  public options = {
    user_type: "User",
    user_id: "",
    search_by: "",
    fromDate: "",
    toDate: "",
    search_keyword: "",
    start: 0,
    limit: 10,
    part_id: 0
  }
  public currentUser;
  public fromDate = '';
  public toDate = '';
  public today = new Date();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public selectlists =[];
  public record :any =[];
  public recordsTotal: any =[];
  constructor(private activatedRoute: ActivatedRoute, public globalService: GlobalService, private router: Router) {
    this.currentUser = this.globalService.getCurrentUser();
    this.options.user_id = this.currentUser.id;
    this.globalService.setMetaData("SHOPS","PARTS_LOGS")
  }

  ngOnInit() {
    setTimeout(()=>{
    let isEnable = this.globalService.getAcl('part', 'views');
    if (!(isEnable)) {
      this.router.navigate(['shops/action/forbidden']);
    }
  },globalConstants.RELOAD_WAIT)
    this.getParams();
    this.options.part_id = this.part_id;
    this.setDataTable();
    this.selectlists=[
      {id:'',text:'--Search By--'},
      {id:1,text:'Create'},
      {id:2,text:'Update'},
      {id:3,text:'Enable'},
      {id:4,text:'Deleted'},
      {id:'bwtDate',text:'Date'},
    ];
  }
  setDataTable() {
    if (this.fromDate != '') {
      this.options.fromDate = this.dateFormat(this.fromDate);
    }
    if (this.toDate != '') {
      this.options.toDate = this.dateFormat(this.toDate);
    }
    this.record=[];
    var content = globalFunctions.stringifyFormData(this.options, null);
    var callApi = this.globalService.callAPI(globalConstants.API_PART_LOGS, content);
    callApi.subscribe((data:any) => {
      if (data.data != undefined) {
        let moreRows = 'false';
        let firstList = 'false';
        this.globalService.localGetterSetter("totalRecords", data.recordsTotal, SHOP_PART_LIST);
        if (data.more_rows == "true") {
          moreRows = "true";
        }
        this.globalService.localGetterSetter("more_rows", moreRows, SHOP_PART_LIST);
        if (data.start >= 0 && data.start <= 5) {
          firstList = "true";
        }
        this.globalService.localGetterSetter("first_list", firstList, SHOP_PART_LIST);

        this.recordsTotal = data.data.length;

        if (data.data.length == undefined) {
          this.AddRecord(data.data);
          this.dataSource = new MatTableDataSource(this.record);
          this.dataSource.sort = this.sort;
          this.globalService.localGetterSetter("current-visible", '1', SHOP_PART_LIST);
        } else {
          this.globalService.localGetterSetter("current-visible", this.recordsTotal, SHOP_PART_LIST);
          for (var i = 0; i < data.data.length; i++) {
            this.AddRecord(data.data[i]);
          }
          this.dataSource = new MatTableDataSource(this.record);
          this.dataSource.sort = this.sort;
        }
        this.globalService.localGetterSetter(null, this.options, SHOP_PART_LIST);
      } else {
        this.dataSource = new MatTableDataSource(this.record);
        this.globalService.localGetterSetter("totalRecords", '0', SHOP_PART_LIST);
      }
    })
  }

  AddRecord(record){
    var data = this.createRecord(record);
    this.record.push(data);
  }

  private createRecord(record) {
    return {
      name: record.first_name + ' ' + record.last_name,
      date: record.add_date,
      date_formate: this.globalService.getFullFormateDate(record.add_date,'G','H'),
      designation: record.designation,
      remark: record.remarks,
      id: record.id,
      actions:record.module_action
    };
  } 
  getParams() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.part_id = params["id"];
    });
  }
  getTotalRecords() {
    return parseInt(localStorage.getItem(SHOP_PART_LIST + 'totalRecords'));
  }
  firstList() {
    return this.globalService.firstList(SHOP_PART_LIST, this.options);
  }

  lastList() {
    return this.globalService.lastList(SHOP_PART_LIST);
  }

  getUpperLimit(currentLastLimit, total) {
    if (currentLastLimit > total) {
      return total
    } else {
      return currentLastLimit
    }
  }
  search(event) {
    this.options.start = 0;
    this.setDataTable();
  }
  reinitializeOptions() {
    this.globalService.localGetterSetter(this.options, this.options, SHOP_PART_LIST);
    this.options.user_id = this.currentUser.id;
    this.options.user_type = this.currentUser.user_type;
  }
  clearSearch() {
    this.globalService.localGetterSetter(null, this.options, SHOP_PART_LIST);
    this.reinitializeOptions();
    this.options.part_id = this.part_id;
    this.fromDate = '';
    this.toDate = '';
    this.setDataTable();
  }
  checkFormEmpty(button) {
    if (this.options.search_keyword == "" && this.options.search_by == "" && this.options.fromDate == "" && this.options.toDate == "") {
      if (button) { button.color = "primary" }
      return false;
    }
    else {
      if (button) { button.color = "accent" }
      return true;
    }
  }
  scrolling() {
    this.globalService.setScrolling('.car-datatable', 1000, -20);
  }
  setTouchViewValue() {
    return true;
  }
  paginateByLimit(event) {
    this.globalService.setScrolling('body', 600, 0);
    this.options.limit = event.pageSize;
    this.options.start = 0;
    this.scrolling();
    this.setDataTable();
  }
  paginate(event, type) {
    this.scrolling();
    if (type == "next") {
      this.options.start = this.options.start + this.options.limit;

    } else {
      this.options.start = this.options.start - this.options.limit;

    }
    this.setDataTable();
  }
  viewDetail(id) {
    // console.log('go detail', id);
    this.router.navigate(['shops/parts/log/detail', id]);
  }
  dateChange(event, type) {
    if (type == 1) {
      this.toDate = this.fromDate;
    }
  }
  dateFormat(dateValue) {
    return ((new Date(dateValue).getMonth() + 1) < 10 ? '0' : '') + (new Date(dateValue).getMonth() + 1) + "/" + (new Date(dateValue).getDate() < 10 ? '0' : '') + new Date(dateValue).getDate() + "/" + new Date(dateValue).getFullYear();
  }
}


export interface PartLogData {
  name: any;
  date: any;
  designation: any;
  remark: any;
  id: any;
  actions:any;
}

