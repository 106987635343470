import { Component, OnInit, ViewChild, Output, EventEmitter, AfterViewInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDrawer } from '@angular/material/sidenav';
import { GlobalService } from '../../../../environments/global.service';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
})

export class SearchFilterComponent implements OnInit, AfterViewInit {
  @ViewChild('searchFilterDrawer') searchFilterDrawer: MatDrawer;
  @Input() data;
  @Input() type;
  @Output() closeTab = new EventEmitter<any>();
  public today = new Date();
  public fillActionValue='';
  public addCustomerSearchSideBar = false;
  public addEmployeeSearchSideBar = false;
  public filterOption=[{id:1,text:'Today',check:false},{id:2,text:'Week',check:false},{id:3,text:'Month',check:false},{id:4,text:'Year',check:false},{id:5,text:'Date Range',check:false}];
  searchForm: FormGroup;
  public submited = false;
  public fromDate = '';
  public toDate = '';
  public fromCal;
  public toCal;
  public fromDateValid = false;
  public toDateValid = false;
  public customerList = [];
  public employeeList = [];
  public mask = {
    guide: true,
    showMask : false,
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/',/\d/, /\d/,/\d/, /\d/]
  };
  constructor(public globalService: GlobalService, private fb: FormBuilder) { }
  ngAfterViewInit(): void {
    this.searchFilterDrawer.toggle();
  }

  ngOnInit() {
    if(this.type === 'advance-report'){
      this.customerList = JSON.parse(localStorage.getItem("advance-report-selected-customer"))|| [];
      this.employeeList = JSON.parse(localStorage.getItem("advance-report-selected-employee"))|| [];
    }else if(this.type === 'business-metrics'){
      this.customerList = JSON.parse(localStorage.getItem("business-selected-customer"))|| [];
      this.employeeList = JSON.parse(localStorage.getItem("business-selected-employee"))|| [];
    }
    this.searchForm = this.fb.group({
      rangeSelected: [0],
      starting_date: [''],
      ending_date: ['']
    });
    this.changeRangeSelected(parseInt(this.data.select_sales));
    if(parseInt(this.data.select_sales) === 5){
      this.addValidation();
      this.fromDate = this.data.starting_date;
      this.toDate = this.data.ending_date;
      this.fromCal = new Date(this.data.starting_date);
      this.toCal = new Date(this.data.ending_date);
      this.searchForm.patchValue({
        starting_date: new Date(this.data.starting_date),
        ending_date: new Date(this.data.ending_date)
      });
    }
  }

  setTouchViewValue() {
    return true;
  }

  addValidation(){
    this.searchForm.controls['starting_date'].setValidators([Validators.required]);
    this.searchForm.controls['starting_date'].updateValueAndValidity();

    this.searchForm.controls['ending_date'].setValidators([Validators.required]);
    this.searchForm.controls['ending_date'].updateValueAndValidity();
  }

  removeValidation(){
    this.searchForm.controls['starting_date'].clearValidators();
    this.searchForm.controls['starting_date'].updateValueAndValidity();

    this.searchForm.controls['ending_date'].clearValidators();
    this.searchForm.controls['ending_date'].updateValueAndValidity();
  }

  changeRangeSelected(id:number){
    this.filterOption.forEach(e=>{
      if(id === e.id){
        e.check = e.check?false:true;
      }else{
        e.check = false;
      }
    });
    if(this.filterOption[id-1].check){
      this.searchForm.patchValue({
        rangeSelected: id
      });
    }else{
      this.searchForm.patchValue({
        rangeSelected: 0
      });
    }
    if(id==0 || id==1 || id==2 || id==3 || id==4){
      this.submited = false;
      this.fillActionValue='';
      this.removeValidation();
    }else if(id==5){
      this.fillActionValue='bwtDate';
      this.addValidation();
    }
  }

  dateChange(event,type){
    let current = new Date();
    if(type === 1){
      if(this.globalService.isValidDate(new Date(this.fromDate))){
        let date = new Date(this.fromDate);
        if(current > date){
          this.fromDate = this.formatDate(date);
          this.fromCal = new Date(this.fromDate);
          this.fromDateValid = false;
        }else{
          this.fromDate = this.formatDate(current);
          this.fromCal = current;
          this.fromDateValid = false;
        }

        //add 30 days in end date
        if(this.toDate === '' || new Date(this.toDate) < date){
          let newDate = date;
          newDate.setDate(newDate.getDate() + 30);
          if(current > newDate){
            this.toDate = this.formatDate(newDate);
            this.toCal = newDate;
            this.toDateValid = false;
          }else{
            this.toDate = this.formatDate(current);
            this.toCal = current;
            this.toDateValid = false;
          }
        }else if(new Date(this.toDate) > date){
          this.toDateValid = false;
          this.toCal = new Date(this.toDate);
        }
      }else{
        this.fromDateValid = true;
        this.submited = true;
        return
      }
    }else if(type === 2){
      if(this.globalService.isValidDate(new Date(this.toDate)) && (this.fromDate === '' || new Date(this.fromDate) <= new Date(this.toDate))){
        let date = new Date(this.toDate);
        if(current > date){
          this.toDate = this.formatDate(date);
          this.toCal = date;
          this.toDateValid = false;
        }else{
          this.toDate = this.formatDate(current);
          this.toCal = current;
          this.toDateValid = false;
        }
      }else{
        this.toDateValid = true;
        this.submited = true;
        return
      }  
    }
  }

  updateChange(event,type){
    let date = new Date(event.target.value);
    if(type === 1){
      this.fromDate = this.formatDate(date);
      this.fromCal = date;
      this.fromDateValid = false;
    }else if(type === 2){
      this.toDate = this.formatDate(date);
      this.toCal = date;
      this.toDateValid = false;
    }
    this.dateChange(event,type);
  }

  makeDate(date){
    if(date !== ''){
      return new Date(date)
    }else{
      return '';
    }
  }

  formatDate(date) {
    if (!new Date(date) || date == null || date == "0000-00-00 00:00:00") {return "-"}
    return (new Date(date).getMonth() + 1).toString().padStart(2, "0") + "/" + new Date(date).getDate().toString().padStart(2, "0") + "/" + new Date(date).getFullYear()
  }
  
  clearSearch() {
    this.searchForm.patchValue({
      rangeSelected: 3,
      starting_date: '',
      ending_date: ''
    });
    this.fromDate = '';
    this.toDate = '';
    this.fromCal = '';
    this.toCal = '';
    this.fillActionValue='';
    this.customerList = [];
    this.employeeList = [];
    this.submited = false;
    this.fromDateValid = false;
    this.toDateValid = false;
    if(this.type === 'advance-report'){
      localStorage.setItem("advance-report-selected-customer",JSON.stringify(this.customerList));
      localStorage.setItem("advance-report-selected-employee",JSON.stringify(this.employeeList));
    }else if(this.type === 'business-metrics'){
      localStorage.setItem("business-selected-customer",JSON.stringify(this.customerList));
      localStorage.setItem("business-selected-employee",JSON.stringify(this.employeeList)); 
    }
    this.CustomerOutput(4);
    this.EmployeeOutput(4);
    if(this.filterOption[2].check){
      this.changeRangeSelected(3);
    }
    this.changeRangeSelected(3);
  }

  CustomerOutput(event: any){
    if(event === 2){
      this.addCustomerSearchSideBar = true;
    }else{
      const output = {
        changes : event,
        select_sales: this.searchForm.controls['rangeSelected'].value
      }
      this.closeTab.emit(output);  
    }
  }

  EmployeeOutput(event: any){
    if(event === 2){
      this.addEmployeeSearchSideBar = true;
    }else{
      const output = {
        changes : event,
        select_sales: this.searchForm.controls['rangeSelected'].value
      }
      this.closeTab.emit(output);  
    }
  }

  searchCustomerOutput(event:any){
    this.addCustomerSearchSideBar = false;
    if(event.changed){
      if(this.type === 'advance-report'){
        this.customerList = JSON.parse(localStorage.getItem("advance-report-selected-customer"))|| [];
      }else if(this.type === 'business-metrics'){
        this.customerList = JSON.parse(localStorage.getItem("business-selected-customer"))|| [];
      }
      this.CustomerOutput(3);
    }
  }

  searchEmployeeOutput(event:any){
    this.addEmployeeSearchSideBar = false;
    if(event.changed){
      if(this.type === 'advance-report'){
        this.employeeList = JSON.parse(localStorage.getItem("advance-report-selected-employee"))|| [];
      }else if(this.type === 'business-metrics'){
        this.employeeList = JSON.parse(localStorage.getItem("business-selected-employee"))|| [];
      }
      this.EmployeeOutput(3);
    }
  }

  closeSideBar(isChanged: number = 0) {
    let output;
    if(isChanged === 1){
      this.submited = true;
      this.searchForm.patchValue({
        starting_date: this.fromDate,
        ending_date: this.toDate
      });
      if(!this.searchForm.valid || this.searchForm.controls['rangeSelected'].value === 0){
        return;
      }
      if(this.fromDateValid || this.toDateValid){
        return;
      }
      output = {
        changes : isChanged,
        select_sales: this.searchForm.controls['rangeSelected'].value,
        starting_date: this.formatDate(this.searchForm.controls['starting_date'].value),
        ending_date: this.formatDate(this.searchForm.controls['ending_date'].value)
      }
    }else{
      output = {
        changes : isChanged,
      }
    }
    setTimeout(() => {
      this.closeTab.emit(output);
    }, 1000);
    this.searchFilterDrawer.toggle();
  }
}


