import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { GoogleChartInterface, GoogleChartType } from 'ng2-google-charts';
import { GlobalService } from 'src/environments/global.service';
import { MatTableDataSource } from '@angular/material/table';
import { globalConstants } from 'src/environments/globalConstants';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { globalFunctions } from 'src/environments/globalFunctions';
import { Router } from '@angular/router';
const INVENTORY_LIST = 'shop-inventoryStatsList-';

@Component({
  selector: 'app-inventory-stats',
  templateUrl: './inventory-stats.component.html',
  styleUrls: ['./inventory-stats.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InventoryStatsComponent implements OnInit {
  public inventoryGraph: GoogleChartInterface;
  public options: any = {
    user_type: "User",
    user_id: "",
    search_by: '',
    is_export: '0',
    inventoryDateRange: "",
    start: 0,
    limit: 10,
    part_tags:""
  };
  public displayedColumns = ['name', 'part_number', 'manufacturer', 'price', 'quantity', 'Picture'];
  public dataSource: MatTableDataSource<InventoryData> = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  public currentUser: any;
  public record: any;
  public recordsTotal: any;
  public showCostPrice = false;
  public partPicture = globalConstants.S3_BUCKET_URL + globalConstants.PART_PICTURE + globalConstants.IMG_THUMB;
  public searchByList = [
    { id: "", text: "Search By" },
    { id: "1", text: "Current Month" },
    { id: "2", text: "Last Month" },
    { id: "3", text: "Date Range" },
  ];
  public dRangePickerConfig: any = {  // date range picker config
    isDisabled: true
  }; 
  public dateRangeOptions: any = '';
  public searchPanelOpenStatus: boolean = false;
  public showChart: boolean= false; 
  public allTags=[];

  constructor(
    private globalService: GlobalService,
    public router: Router
  ) { 
    setTimeout(()=>{
      let isEnable=this.globalService.getAcl('sales_reports','views');
      if(!(isEnable)){
          this.router.navigate(['shops/action/forbidden']);
      }
    },globalConstants.RELOAD_WAIT)
  }

  ngOnInit(): void {
    this.globalService.setMetaData("SHOPS","INVENTORY");
    this.showCostPrice = this.globalService.getAcl('display_part_cost', 'views');
    this.currentUser = JSON.parse(localStorage.getItem("loggedInUser"));
    this.globalService.ShowDateRange(this.dRangePickerConfig);
    this.reinitializeOptions();
    this.getAllTags();
    if(localStorage.getItem(INVENTORY_LIST+"search_by")!="") {
      this.searchPanelOpenStatus = true;
  } else {
      this.searchPanelOpenStatus = false;
  }
    this.setDataTable();
  }

  reinitializeOptions() {
    this.options=this.globalService.localGetterSetter(this.options,this.options,INVENTORY_LIST);
    this.options.user_type= "User";
    this.options.user_id= this.currentUser.id;
}
  getInventoryReportData(inventoryParts, durationGrandTotal) {
    let inventoryList = [];
    inventoryList.push(['Type', "Parts Sales"]);
    if (inventoryParts.length >0) {
      inventoryParts.forEach(e => {
        inventoryList.push([e.name, parseFloat(e.total_part_price)])
      });
    } else {
      inventoryList.push(['Parts data not found', 0])
    }
    this.showChart = false;
    setTimeout(() => {
    this.inventoryGraph = {
      chartType: GoogleChartType.ColumnChart,
      dataTable: inventoryList,
      formatters: [
        {
          columns: [1],
          type: 'NumberFormat',
          options: {
            fractionDigits: 2
          }
        }
      ],
      options: {
        title: 'Top Parts Sold',
        width: '100%',
        height: 350,
        bar: { groupWidth: "35%" },
        animation: {
          duration: 1000,
          easing: 'out',
          startup: true
        },
        vAxis: {
          title: 'Total Retail Price',
          titleTextStyle: { color: '#000' }
        },
        hAxis: {
          title: `Total Parts Sale : $ ${durationGrandTotal == ''? '0':durationGrandTotal.toFixed(2)}`, 
          titleTextStyle: { color: '#000', italic: false, bold: true,}
        }
      }
    };
    this.showChart = true;
  }, 100);
    
  }

  getTotalRecords() {
    return parseInt(localStorage.getItem(INVENTORY_LIST + 'totalRecords'))
  }

  currentDate() {
    return (new Date().getMonth() + 1) + "/" + new Date().getDate() + "/" + new Date().getFullYear()
  }

  paginate(event, type) {
    this.scrolling();
    if (type == "next") {
      this.options.start = this.options.start + this.options.limit;
    } else {
      this.options.start = this.options.start - this.options.limit;
    }
    this.setDataTable();
  }

  firstList() {
    return this.globalService.firstList(INVENTORY_LIST, this.options);
  }

  lastList() {
    return this.globalService.lastList(INVENTORY_LIST);
  }

  getUpperLimit(currentLastLimit, total) {
    if (currentLastLimit > total) {
      return total;
    } else {
      return currentLastLimit;
    }
  }

  setDataTable() {
    this.record = [];
    var callApi = this.globalService.callAPI(globalConstants.API_GET_INVENTORY_STATS, this.options);
    callApi.subscribe((data: any) => {
      if (data.data != undefined) {
        this.getInventoryReportData(data.data, data.duration_grand_total);
        let moreRows = 'false';
        let firstList = 'false';
        this.globalService.localGetterSetter("totalRecords", data.recordsTotal, INVENTORY_LIST);
        if (data.more_rows == "true") {
          moreRows = "true";
        }
        this.globalService.localGetterSetter("more_rows", moreRows, INVENTORY_LIST);
        if (data.start >= 0 && data.start <= 5) {
          firstList = "true";
        }
        this.globalService.localGetterSetter("first_list", firstList, INVENTORY_LIST);
        this.recordsTotal = data.data.length;
        if (data.data.length == undefined) {
          this.dataSource.data = data.data;
          this.AddRecord(data.data)
          this.globalService.localGetterSetter("current-visible", '1', INVENTORY_LIST);
          this.dataSource = new MatTableDataSource(this.record);
          this.dataSource.sort = this.sort;
        } else {
          this.globalService.localGetterSetter("current-visible", this.recordsTotal, INVENTORY_LIST);
          for (var i = 0; i < data.data.length; i++) {
            this.AddRecord(data.data[i]);
          }
          this.dataSource = new MatTableDataSource(this.record);
          this.dataSource.sort = this.sort;
        }
        this.globalService.localGetterSetter(null, this.options, INVENTORY_LIST);
      } else {
        this.getInventoryReportData([],'');
        this.dataSource = new MatTableDataSource(this.record);
        this.globalService.localGetterSetter("totalRecords", '0', INVENTORY_LIST);
      }
    })
  }

  AddRecord(record) {
    var data = this.createRecord(record);
    this.record.push(data);
  }

  private createRecord(record) {
    return {
      name: record.name,
      part_number: (record.part_number) ? (record.part_number) : '-',
      manufacturer: (record.manufacturer) ? (record.manufacturer) : '-',
      price: parseFloat(record.total_part_price).toFixed(2),
      picture: record.part_picture,
      quantity: record.total_part_quantity,
    };
  }

  scrolling() {
    this.globalService.setScrolling('.car-datatable', 1000, -20);
  }

  paginateByLimit(event) {
    this.globalService.setScrolling('body', 600, 0);
    this.options.limit = event.pageSize;
    this.options.start = 0;
    this.scrolling();
    this.setDataTable();
  }

  isLowStock(val, quantity) {
    let lowStockQty = parseFloat(val);
    let qty = parseFloat(quantity);
    return (qty <= lowStockQty ? true : false);
  }

  showWholeNumber(val) {
    return globalFunctions.hasDecimal(val, true);
  }

  checkFormEmpty(button){
    if (this.options.part_tags=="" && this.options.search_by == '') {
      if (button) { button.color = "primary" }
      return false;
    } else {
      if (button) { button.color = "accent" }
      return true;
    }
  }

  clearSearch(){
    this.options.start = 0;
    this.options.search_by = '';
    this.options.is_export = "0";
    this.dateRangeOptions = '';
    this.options.inventoryDateRange = '';
    this.dRangePickerConfig.isDisabled = true;
    this.globalService.ShowDateRange(this.dRangePickerConfig);
    this.options.part_tags = '';
    this.setDataTable();
  }

  onOptionChange(event: any) {
    if (event == 3) {
      this.dRangePickerConfig.isDisabled = false;
      this.dateRangeOptions = null;
      this.globalService.ShowDateRange(this.dRangePickerConfig);
    } else {
      this.dRangePickerConfig.isDisabled = true;
      this.dateRangeOptions = '';
      this.globalService.ShowDateRange(this.dRangePickerConfig);
    }
  }

  handleSearchInventoryStats(event){
    this.options.start = 0;
    this.options.inventoryDateRange = this.dateRangeOptions;
     if(this.dateRangeOptions !== null){
        this.setDataTable();
     } else{
        this.globalService.snackbar('error',"Please select or enter a valid date range");
     }
  }

  onDateChange(event: any) {
    if (event == null) {
      this.dateRangeOptions = null;
    } else {
      var stringDateFormat = [
        event.start.toISOString(),
        event.end.toISOString()
      ]
      this.dateRangeOptions = stringDateFormat;
    }
  }
  getAllTags(){
    let data={user_id: this.currentUser.id};
    this.globalService.callAPI(globalConstants.API_LIST_PART_TAGS,data).subscribe((res:any)=>{
        if (res.result == "1") {
            this.allTags=res.data;
        }
    },(error)=>{})
  }

  downloadInventoryStatsCSV(){
    this.options.is_export = "1";
    var callApi = this.globalService.callAPI(globalConstants.API_GET_INVENTORY_STATS, this.options);
    callApi.subscribe((reportData: any) => {
        this.options.is_export = '0';
        window.location.href = globalConstants.API_SHOP_DOWNLOAD_SERVICE_REQUEST_LIST + "?t=5&p=" + reportData.csv_url;
    })
  }
}

export interface InventoryData {
  name: string;
  part_number: string;
  manufacturer: string;
  price: number;
  picture: string;
  quantity: number;
}
