import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalService } from '../../../../environments/global.service';
import { globalFunctions } from '../../../../environments/globalFunctions';
import { globalConstants } from '../../../../environments/globalConstants';
 /**
   * @trackID <:CAR-1413> Reset Customer Password
   * @created 2020-02-18
   * @createdBY Vikas Kumar
   * @return
   */
@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    public currentCustomer;
    public isCollapsed: boolean = true;
    public checkForPwdForm: FormGroup;
    public submitted = false;
    public param: any = {}

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ResetPasswordComponent>, private formBuilder: FormBuilder, private gs: GlobalService, private router: Router) {
        this.currentCustomer = this.gs.getAdmin()
        if (this.currentCustomer && typeof this.currentCustomer.id != 'undefined') {
            this.gs.diaLogObj(this.dialogRef);
        } else {
            this.router.navigate(["/admin"]);
        }
        this.param = data
    }

    ngOnInit() {
        this.initilizeCheckPasswordForm();
    }

    initilizeCheckPasswordForm() {
        this.checkForPwdForm = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirm_password: ['', [Validators.required, Validators.minLength(6)]],
            customer_id: this.param.customer_id,
            send_email: 1,
            user_type: this.currentCustomer.user_type,
            user_id: this.currentCustomer.id,
        })
    }

    checkIfValid() {
        let pass = this.checkForPwdForm.value.password
        let confPass = this.checkForPwdForm.value.confirm_password
        if (!(confPass === pass)) {
            this.checkForPwdForm.controls.confirm_password.setErrors({ mismatch: true })
        }
        if (this.checkForPwdForm.valid) {
            this.submitted = false;
        }
    }

    cancelAuthentication() {
        this.dialogRef.close();
    }

    validatePassword() {
        let pass = this.checkForPwdForm.value.password
        let confPass = this.checkForPwdForm.value.confirm_password
        if (!(confPass === pass)) {
            this.checkForPwdForm.controls.confirm_password.setErrors({ mismatch: true })
        }
        if (this.checkForPwdForm.valid) {
            let formdata = this.checkForPwdForm.value;
            formdata.password = formdata.password.replaceAll('+','%2B');
            formdata.confirm_password = formdata.confirm_password.replaceAll('+','%2B');
            var stringifiedFormData = globalFunctions.stringifyFormData(formdata, null);
            this.gs.callAPI(globalConstants.API_MASTER_CUSTOMER_PASSWORD_CHANGE, stringifiedFormData).subscribe((data:any) => {
                var message = data.message;
                if (data.result == "1") {
                    this.gs.snackbar("success", message);
                    this.dialogRef.close(true);
                } else {
                    this.gs.snackbar("error", message);
                }
            });
        } else {
            this.submitted = true;
        }
    }

}
