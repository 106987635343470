import {Component, OnInit, OnDestroy} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {globalFunctions} from "../../../../environments/globalFunctions";
import {globalConstants} from "../../../../environments/globalConstants";
import {GlobalService} from "../../../../environments/global.service";
import {Subscription} from 'rxjs';
import {trigger,state,style,animate,transition} from '@angular/animations';
import {AppComponent} from "../../../app.component";
import {MatDialog } from '@angular/material/dialog';
import {UsercontactsComponent} from '../../../usercontacts/usercontacts.component';

@Component({
  selector: 'app-editshopcustomers',
  templateUrl: './editshopcustomers.component.html',
  styleUrls: ['./editshopcustomers.component.scss'],
   animations: [
    trigger('popOverState', [
      state('show', style({
        opacity:1,
        transform:'translateY(0px)',
        'z-index':'0'
      })),
      state('hide',   style({
        opacity:0,
        height:'0px',
        'z-index':'-1',
        'display':'none'
      })),
      transition('show => hide', animate('0.2s 100ms ease-out')),
      transition('hide => show', animate('0.2s 100ms ease-out'))
    ])
  ]
})
export class EditshopcustomersComponent implements OnInit, OnDestroy {
    public customerEdit: FormGroup; 
    public currentCustomer;
    public selectedCountry;
    public selectedState;
    public selectedCity;
    public countries:any =[];
    public states: any = [];
    public cities: any = [];
    public checkedBox: boolean = false;
    public phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    public currentUserInfo;
    public getCustomerTypes;
    public checkUserEmail: boolean = false;
    public idFromUrl;
    public show = false;
    public taxExemptedCheck:boolean;
    public requireApprovalCheck:boolean;
    public requireApprovalAmount : boolean = false;
    public disableStates;
    public disableCities;
    public customerTypesOptions;
    public defaultCustomerType;
    public modulePermission:any=0;
    public timezoneData=[];
    public zipLabel = 'Zip Code';
    public isSubletWorkCheck: boolean = false;
    public subscriptionDataForAPI: Subscription = new Subscription();
    constructor(
        public app:AppComponent,
        private activatedRoute:ActivatedRoute,
        private formBuilder: FormBuilder,
        private router: Router, 
        public globalService: GlobalService, 
        public dialog: MatDialog) {
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
        setTimeout(()=>{
        this.modulePermission=this.globalService.isAllowed(this.app,'customer','edits');
        if(!(this.modulePermission)){
            this.router.navigate(['shops/action/forbidden']);
        }
    },globalConstants.RELOAD_WAIT)
        var userInfo = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&shop_id=" + this.globalService.getCurrentUser()["shop_id"];
        this.subscriptionDataForAPI =  this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_TYPES, userInfo).subscribe((data:any) => {
            this.getCustomerTypes = data.data;
            var customerTypesList=[];
            for(var i in this.getCustomerTypes){
                this.getCustomerTypes[i].id=this.getCustomerTypes[i].id;
                this.getCustomerTypes[i].text=this.getCustomerTypes[i].type;                                        
                customerTypesList.push(this.getCustomerTypes[i]);
            }
            this.getCustomerTypes = customerTypesList;
        });
    }

    ngOnInit() {
        this.globalService.setMetaData("SHOPS","EDIT_CUSTOMERS");
        this.initializeForBuilders();
        this.getCurrentCustomerDetails();
    }

    setParams(){
        this.activatedRoute.params.subscribe((params: Params) => {
          this.idFromUrl = params["customer_id"];
        });
    }

    initializeForBuilders() {
        this.setParams();
        this.customerEdit = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(globalConstants.EMAIL_REGEX)]],
            first_name: ['', [Validators.required,Validators.pattern(globalConstants.NAME_REGEX)]],
            middle_name: ['',Validators.pattern(globalConstants.NAME_REGEX)],
            last_name: ['', [Validators.required,Validators.pattern(globalConstants.NAME_REGEX)]],
            address_one: [''],
            address_two: [''],
            city: ['',Validators.required],
            state: ['',Validators.required],
            frm_state: [''],
            country: [''],
            frm_country: [''],
            zipcode: ['',[Validators.pattern("^[0-9]+$")]],
            home_phone: [''],
            business_phone: [''],
            mobile_phone: ['', [Validators.required, Validators.minLength(10), this.globalService.mobileNumberValidation]],
            business_name: ['',[Validators.pattern(globalConstants.NAME_REGEX)]],
            customer_type: [''],
            tax_exempted: [''],
            user_type:this.globalService.getCurrentUser()["user_type"],
            user_id:this.globalService.getCurrentUser()["id"],
            customer_id:this.idFromUrl,
            shop_id:this.globalService.getCurrentUser()["shop_id"],
            business_name_present: [false],
            require_approval: [''],
            approval_amount: ['',Validators.pattern(/^-?\d+(?:\.\d+)?$/)],
            timezone:[''],
            is_sublet: [''],
            address_is_required:[false]
        });
    }


    handleNoAddressChange(obj){
            let country = this.customerEdit.value.country;
            let zRegex = this.countries.find(res => (res.country_id == country || res.country_name == country));
            if(obj.checked){                 
                this.customerEdit.controls.address_one.setValidators([]);
                this.customerEdit.controls.zipcode.setValidators([Validators.pattern(zRegex.zipcode_regex)]);
                this.customerEdit.controls.state.setValidators([]);
                this.customerEdit.controls.city.setValidators([]);           
            }else{
                this.customerEdit.controls.address_one.setValidators([Validators.required]);  
                this.customerEdit.controls.zipcode.setValidators([Validators.required,Validators.pattern(zRegex.zipcode_regex)]);
                this.customerEdit.controls.state.setValidators([Validators.required]); 
                this.customerEdit.controls.city.setValidators([Validators.required]); 
            }  
            this.customerEdit.controls.address_one.updateValueAndValidity(); 
            this.customerEdit.controls.zipcode.updateValueAndValidity(); 
            this.customerEdit.controls.state.updateValueAndValidity(); 
            this.customerEdit.controls.city.updateValueAndValidity();                 
    }

    updateZipCodeValidation(){ 
             let country_name = this.customerEdit.value.country ;
            if(country_name && Array.isArray(this.countries)){                 
                let zRegex = this.countries.find(res=>(res.country_id==country_name || res.country_name==country_name));
                 if(zRegex && 'zipcode_regex' in zRegex){
                     this.zipLabel = zRegex.zipcode_label;
                     if(!this.customerEdit.get("address_is_required").value){
                         this.customerEdit.controls.zipcode.setValidators([Validators.required,Validators.pattern(zRegex.zipcode_regex)]);
                     }else{
                         this.customerEdit.controls.zipcode.setValidators([Validators.pattern(zRegex.zipcode_regex)]);
                     }
                     this.customerEdit.controls.zipcode.updateValueAndValidity();
                 }          
             }                
    }
 
    
    /* FOR CUSTOMER TYPES */
    getSelectedOptionCustomerType(opt) {
        this.customerEdit.controls.customer_type.setValue(opt);
    }
    
    /* ---- */
    editCustomerData() {
        this.customerTypeValidation();
        if(this.customerEdit.valid) {
              if(!this.customerEdit.value.timezone || this.customerEdit.value.timezone == null || typeof(this.customerEdit.value.timezone) == 'undefined'){
                     this.customerEdit.patchValue({timezone : globalConstants.DEFAULT_TIME_ZONE});
              }
            var data = new FormData();
            for(let i in this.customerEdit.value){
               data.append(i, this.customerEdit.value[i]);
            }
            this.globalService.formData(globalConstants.API_EDIT_CUSTOMER_URL, data).subscribe((data:any) => {
              if(data.code == "510"){
                this.globalService.snackbar('success', data.message);
                this.router.navigate(["/shops/list-customers"]);
              }else{
                this.globalService.snackbar('error', data.message);
              }
            });
        } else {
          this.mandatoryMsg();
        }
    }

    mandatoryMsg() {
        this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
    onlyNumericKey(event) {
        return globalFunctions.onlyDecimalNumberKey(event);
    }
    showTaxExempted(event) {
        if (event.checked) {
            this.customerEdit.controls.tax_exempted.setValue(1);
        } else {
            this.customerEdit.controls.tax_exempted.setValue(0);
        }
    }
    
    getCurrentCustomerDetails() {
        this.setParams();
        var customerInfo = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&customer_id=" + this.idFromUrl;
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_DETAILS, customerInfo).subscribe((data:any) => {
        var response = data.data;
        if(response.mapping_status === "0"){
            this.router.navigate(["/shops/list-customers"])
        }
        this.customerEdit.controls.email.setValue(response.email);
        this.customerEdit.controls.first_name.setValue(response.first_name);
        this.customerEdit.controls.middle_name.setValue(response.middle_name);
        this.customerEdit.controls.last_name.setValue(response.last_name);
        this.customerEdit.controls.business_name.setValue(response.business_name);
        this.customerEdit.controls.address_one.setValue(response.address1);
        this.customerEdit.controls.address_two.setValue(response.address2);
        this.customerEdit.controls.city.setValue(response.city);
        this.customerEdit.controls.state.setValue(response.state);
        this.customerEdit.controls.country.setValue(response.country);  
        this.getCountries();  
        this.selectedCountry = response.country;
        this.selectedState = response.state;
        this.selectedCity = response.city;
        this.customerEdit.controls.zipcode.setValue(response.zip);
        this.customerEdit.controls.home_phone.setValue(response.home_phone);
        this.customerEdit.controls.business_phone.setValue(response.business_phone);
        this.customerEdit.controls.business_name_present.setValue(response.business_name_present);
        this.customerEdit.controls.mobile_phone.setValue(response.mobile_phone);
        this.customerEdit.controls.customer_type.setValue(response.customer_type);
        this.defaultCustomerType = response.customer_type;
        this.customerEdit.controls.approval_amount.setValue(response.approval_amount);
        this.customerEdit.controls.is_sublet.setValue(response.is_sublet);
        this.isSubletWorkCheck = response.is_sublet=='1'?true:false;
        if (response.tax_exempted == 1) {
            this.taxExemptedCheck = true;
            this.customerEdit.controls.tax_exempted.setValue(1);
        } else {
            this.taxExemptedCheck = false;
            this.customerEdit.controls.tax_exempted.setValue(0);
        }
        if (response.require_approval == '1') {
            this.requireApprovalCheck = true;
            this.requireApprovalAmount = true;
            this.customerEdit.controls.require_approval.setValue(1);
        } else {
            this.requireApprovalCheck = false;
            this.requireApprovalAmount = false;
            this.customerEdit.controls.require_approval.setValue(0);
        }
    
        /* SET COUNTRY ID TO GET STATE */
        if(response.country != "") {
            this.selectedCountry = response.country_id; 
        }
        this.customerEdit.controls.frm_country.setValue(this.selectedCountry);
        this.getStates(this.selectedCountry);
        this.customerEdit.controls.timezone.setValue(response.time_zone);
        this.getTimezone(this.selectedCountry);
        /* SET STATE ID TO GET CITY NAME */
        if(response.state != "") {
            this.selectedState = response.state_id;
        } else {
            this.selectedState = '';
        }
        this.customerEdit.controls.frm_state.setValue(this.selectedState);
        this.getCities(this.selectedState);
        if(response.business_name != null && response.business_name != " " && response.business_name != ""){
          this.checkedBox = true;
          this.show = true;
          this.customerEdit.get('business_name').setValidators([Validators.required,Validators.pattern(globalConstants.NAME_REGEX)]);
              this.customerEdit.get('business_name').updateValueAndValidity();
        } else {
          this.checkedBox = false;
          this.show = false;
          this.customerEdit.get('business_name').setValidators([]);
          this.customerEdit.get('business_name').updateValueAndValidity();
        }
    })
  }

     /* FOR COUNTRY/STATES/CITY SELECT */
     handleCountryDropdownChange(countryId) {
        this.getStates(countryId);
        let countryData = this.countries.find(countryInfo => countryId == countryInfo.id);
        this.customerEdit.controls.country.setValue(countryData.country_name);
        this.updateZipCodeValidation();
        this.customerEdit.controls.state.setValue('');
        this.customerEdit.controls.city.setValue('');
        this.customerEdit.controls.timezone.setValue('');
        this.selectedState = '';
        this.selectedCity = '';
        this.getTimezone(countryId);
        this.customerEdit.get('timezone').setValidators([Validators.required]);
        this.customerEdit.get('timezone').updateValueAndValidity();
    }
    
    handleStateDropdownChange(stateId) {
        if (stateId !== '') {
            this.getCities(stateId);
            let stateData = this.states.find(stateInfo => stateId == stateInfo.id);
            this.customerEdit.controls.state.setValue(stateData.state_name);
            this.customerEdit.controls.city.setValue('');
            this.selectedCity = 'Select City';
        } else {
            this.customerEdit.controls.state.setValue("");
            this.customerEdit.controls.city.setValue('');
            this.selectedCity = 'Select City';
        }
    }

    handleCityDropdownChange(cityName) {
        if (cityName !== 'Select City') {
            this.customerEdit.controls.city.setValue(cityName);
        } else {
            this.customerEdit.controls.city.setValue('');
        }
    }

    getCountries() {
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_COUNTRIES_LIST, "opr=1").subscribe((data: any) => {
            if (data.result == "1") {
                this.countries = data.data;
                for (var i in this.countries) {
                    this.countries[i].id = this.countries[i].country_id;
                    this.countries[i].text = this.countries[i].country_name;
                }
            } else {
                this.countries = [];
            }
            let noAddressStatus = JSON.parse(localStorage.getItem("shop-details")).no_customer_address== 1? true:false;
            this.customerEdit.controls.address_is_required.setValue(noAddressStatus);
            if(noAddressStatus){
                this.handleNoAddressChange({checked:true});
            }else{
                this.handleNoAddressChange({checked:false});
            }
            this.updateZipCodeValidation();
        });
    }
    getStates(countryID) {
        if (typeof(countryID) == 'undefined' || countryID == "") {
            this.disableStates = true;
            this.disableCities = true;
            this.cities=[];
        } else {
            this.disableCities = true;
            this.cities=[
                { id: "", text: "Select City" }
            ];
            let stateDDLPlaceholder = [
                { 
                    id: "",
                    state_id: "",
                    state_name: "Select State",
                    country_id: "",
                    latitude: "",
                    longitude: "",
                    tax_rate: "",
                    state_code: ""
                 }
            ];
            this.states = [];
            this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_STATES_URL, "opr=1&country_id=" + countryID).subscribe((data:any) => {
                var responseData = data;
                this.disableStates = false;
                if (responseData.result == "1") {
                    this.states  = stateDDLPlaceholder.concat(responseData.data);
                    for(var i in this.states){
                        this.states[i].id=this.states[i].state_id;
                        this.states[i].text=this.states[i].state_name;  
                   }
                } else {
                    this.states = [];
                    this.cities = [];
                    this.disableStates = false;
                    this.selectedState = [{ id: "", text: "No state found" }];
                    this.disableCities = true;
                }
            });
        }
      }

  /* GET CITIES */
  getCities(stateId) {
    if (typeof(stateId) == 'undefined' || stateId == "") {
        this.cities = [];
        this.disableCities = true;
    } else {
        this.cities=[];
        let cityDDLPlaceholder=[{
            id: "",
            city_id: "",
            city_name: "Select City",
            state_id: "",
            country_id: "",
            latitude: "",
            longitude: ""
            }];
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_CITIES_URL, "opr=1&state_id=" + stateId)
            .subscribe(data => {
            var responseData = data;
            if (responseData.result == "1") {
                this.disableCities = false;
                this.cities = cityDDLPlaceholder.concat(responseData.data);
                    for(var i in this.cities){
                        this.cities[i].id=this.cities[i].city_name;
                        this.cities[i].text=this.cities[i].city_name;          
                    }
            } else {
                this.cities = [{id: "", text: "No city found"}];
                this.disableCities = false;
            }
        })
    }
}

    /* Home Phone Validity Check */
    checkHomePhoneValidity() {
        Window["errorObj"] = this.customerEdit
        let home_phone = this.customerEdit.value.home_phone;
         if(home_phone !="") {
            let phoneResult = this.customerEdit.value.home_phone.replace(/\D+/g, "").length >= 10 ? false : {minLength: true};
            if(phoneResult == false) {
                this.customerEdit.controls.home_phone.setErrors(null);
            } else {
                this.customerEdit.controls.home_phone.setErrors({"phoneError": true});
            }
        } else {
            this.customerEdit.controls.home_phone.setErrors(null);
        }
    }

    /* Business Phone Validity Check */
    checkBusinessPhoneValidity() {
         Window["errorObj"] = this.customerEdit;
        let home_phone = this.customerEdit.value.business_phone;
         if(home_phone !="") {
            let phoneResult = this.customerEdit.value.business_phone.replace(/\D+/g, "").length >= 10 ? false : {minLength: true};
            if(phoneResult == false) {
                this.customerEdit.controls.business_phone.setErrors(null);
            } else {
                this.customerEdit.controls.business_phone.setErrors({"phoneError": true});
            }
        } else {
            this.customerEdit.controls.business_phone.setErrors(null);
        }
    }

    /* animation */
    get showBusinessName() {
      return this.show ? 'show' : 'hide'
    }
    
    businessNameToggle() {
        if(this.show){
            this.show = false;
            this.customerEdit.get('business_name').setValidators([]);
              this.customerEdit.get('business_name').updateValueAndValidity();
            this.customerEdit.controls.business_name.setValue("");
        }else{
            this.show = true;
            this.customerEdit.get('business_name').setValidators([Validators.required,Validators.pattern(globalConstants.NAME_REGEX)]);
            this.customerEdit.get('business_name').updateValueAndValidity();
        }
    }
    
    editContacts(){
        let dialogRef = this.dialog.open(UsercontactsComponent, {
            panelClass: 'car-dialog-form',
            width: "800px",
            data: {user_id:this.globalService.getCurrentUser()["id"],user_type:this.globalService.getCurrentUser()["user_type"],customer_id:this.idFromUrl}
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }
  
    showRequireApproval(event) {
        if (event.checked) {
            this.requireApprovalAmount = true;
            this.customerEdit.controls.require_approval.setValue(1);
        } else {
            this.requireApprovalAmount = false;
            this.customerEdit.controls.require_approval.setValue(0);
        }
    }
    
    /* animation */
    get checkApprovalAmount() {
        return this.requireApprovalAmount ? 'show' : 'hide'
    }
    
    onlyDecimalNumberKey(event) {
        let charCode = (event.which) ? event.which : event.keyCode;
        if (charCode != 46 && charCode > 31
            && (charCode < 48 || charCode > 57))
            return false;
        return true;
    }

    disableKey(event) {
        if (event.ctrlKey==true && (event.which == '67' || event.which == '86' ||  String.fromCharCode(event.which).toLowerCase() == 's')) {
            event.preventDefault();
        }
    }

    isSubletWork(event) {
        if (event.checked) {
            this.isSubletWorkCheck = true;
            this.customerEdit.controls.is_sublet.setValue(1);
        } else {
            this.isSubletWorkCheck = false;
            this.customerEdit.controls.is_sublet.setValue(0);
        }
    }
    
    /* Customer Type Validation */
    customerTypeValidation() {
        let cType = this.customerEdit.value.customer_type;
        if(cType !="") {
            let cTypeValue = this.customerEdit.value.customer_type.length >= 1 ? false : {required: true};
            if(cTypeValue == false) {
                this.customerEdit.controls.customer_type.setErrors(null);
            } else {
                this.customerEdit.controls.customer_type.setErrors({"required": true});
            }
        } else {
            this.customerEdit.controls.customer_type.setErrors({"required": true});
        }
    }
        /** customer timezone start */
        getSelectedOptionTimezone(event){
            if(event !==''){
            this.customerEdit.controls.timezone.setValue(event);
            }else{
                this.customerEdit.controls.timezone.setValue(''); 
            }
        }
        /** customer timezone end */
        /** get all timezone start */
        getTimezone(Id){
            let timeZoneDDLPlaceHolder =[{
                text: "Select Time Zone", 
                id: ""
            }];
            let data={country_id:Id ,user_type: this.globalService.getCurrentUser()["user_type"],user_id: this.globalService.getCurrentUser()["id"]};
            this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_TIMEZONE,data).subscribe((zones:any)=>{
                if(zones.result=='1'){
                    this.timezoneData=timeZoneDDLPlaceHolder.concat(zones.data);
                }else{
                    this.timezoneData=[];
                }
            });
        }

        setCountryStateCityViaZipCode(){
            let payload = {
                 user_id: this.currentCustomer.id,
                 user_type: "User",
                 zip_code: this.customerEdit.controls.zipcode.value
            }
            this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_STATE_CITY_VIA_ZIPCODE,payload).subscribe((response:any)=>{
                if(response.result == 1){
                    if(response.data.country_info.country_id !== 0 
                        && response.data.state_info.state_id !== 0 
                        &&  response.data.city_info.city_id !== 0){
                        this.selectedCountry = response.data.country_info.country_code;
                        let oldNewCustomerCountry = this.customerEdit.value.country;
                        this.customerEdit.controls.country.setValue(response.data.country_info.country_name);
                        this.getStates(response.data.country_info.country_code);
                        this.selectedState = response.data.state_info.state_id;
                        this.customerEdit.controls.state.setValue(response.data.state_info.state_name);
                        this.getCities(response.data.state_info.state_id)
                        this.selectedCity = response.data.city_info.city_name;
                        this.customerEdit.controls.city.setValue(this.selectedCity);
                        if(oldNewCustomerCountry !== this.selectedCountry){
                            this.customerEdit.controls.timezone.setValue('');
                            this.getTimezone(response.data.country_info.country_code);
                            this.customerEdit.get('timezone').setValidators([Validators.required]);
                            this.customerEdit.get('timezone').updateValueAndValidity();
                        }
                    }
                }
          })
        }

        convertIntoDecimal(event,frmControlName,beforeDecimalDigit) {
            let fieldValue = this.customerEdit.get(frmControlName).value;
            let result= this.globalService.processDecimalValue(fieldValue,beforeDecimalDigit);
            this.customerEdit.get(frmControlName).setValue(result);
            return globalFunctions.validateNumbersWithDecimal(event,3);
        }

        ngOnDestroy(){
            this.subscriptionDataForAPI.unsubscribe();
        }  
}
