import { HelperService } from './../../../helper.service';
import { GlobalService } from 'src/environments/global.service';
import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  HostListener,
  ViewEncapsulation
} from '@angular/core';
import { Observable,Subscription } from 'rxjs';
import { IChat, IConversation } from 'src/app/interfaces';
import { Socket } from 'ngx-socket-io';
import * as moment from 'moment-timezone';
import { globalConstants } from 'src/environments/globalConstants';
window.moment = moment;
@Component({
  selector: 'app-chat-area',
  templateUrl: './chat-area.component.html',
  styleUrls: ['./chat-area.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '(document:storage)': 'onStorageChange($event)'
  },
})
export class ChatAreaComponent implements OnInit {
  @Input() onMobileScreen;
  @Input() conversation=new Observable();
  @Input() closeModalListener = new Observable();
  @Output() BackToContact=new EventEmitter<any>();
  @Output() refeshConversations=new EventEmitter()
  @Output() SelectContactForInfo=new EventEmitter();
  @Output() updateBadge=new EventEmitter();
  public conversationInfo: IConversation|null=null;
  public lastTimeStamp='';
  public chats: IChat[]=[];
  public query: string='';
  public socketId: string='';
  public socketConnectionStatus: boolean=false;
  public shopDetails;
  public showMoreButton: boolean=false;
  public loggedInUser;
  public isCustomerGloballyUnsubscribed: boolean = false;
  public isCustomerLocallyBlacklisted : boolean = false
  public isTwoWaySMSEnabled: boolean = true;
  public isOneWaySMSEnabled: boolean = true;
  public isSMSProviderSetupEnabled: boolean = false;
  public gc = globalConstants;
  public chatInfoSubscription: Subscription = new Subscription();
  public getMessageSubscription;
  public getMessageSubscription1;
  public previousChatRequestQuery = {
    limit: 30,
    page: 0,
    opr: 1,
    query: '',
    lastTimeStamp: null
  };
  public isIPhone: boolean = false;
  public modalStatus:any ;
  public cycleStatusActive: boolean = false;
  public displayErrorMsg  : any = 0;

  constructor(
    private socket: Socket,
    public globalService: GlobalService,
    public helperService: HelperService
  ) {
    this.socket.on('updateChat', (msg) => {
      if (this.conversationInfo && !this.cycleStatusActive)  {
        this.previousChatRequestQuery.opr = 1;
        this.startGetAndReadProcess();
      }
      this.refeshConversations.emit();
    });
    this.chatInfoSubscription =   this.helperService.getSocketConnectionStatus.subscribe((status) => {
      this.socketConnectionStatus=status;
      if(status&&this.conversationInfo) {
        this.startGetAndReadProcess();
      }
    });
  }

  ngOnInit(): void {
    this.isIPhone = this.globalService.detectMobileScreen() && /iPhone/i.test(navigator.userAgent);

    this.chatInfoSubscription = this.helperService.modalViewStatus.subscribe(
      (modalState: string) => {
        this.modalStatus = modalState;
        if (modalState === 'active') {
          this.setUnreadMessagesAsRead();
          setTimeout(() => {
            const element = document.getElementById('chat-area-messages');
            if (element) {
              document
                .getElementById('chat-area-messages')
                .scrollIntoView({ block: 'end' });
            }
          }, 500);
        }
      }
    );

    this.chatInfoSubscription =  this.conversation.subscribe(async (response: IConversation) => {
      if (response && this.modalStatus === 'active') {
        this.showMoreButton = false;
        this.chats = [];
        this.conversationInfo = response;
        this.startGetAndReadProcess();
        this.previousChatRequestQuery.page = 0;
        this.previousChatRequestQuery.query = '';
        this.previousChatRequestQuery.lastTimeStamp = '';
        this.previousChatRequestQuery.opr = 1;
      }
    });
    this.chatInfoSubscription =  this.closeModalListener.subscribe((closeRequest) => {
      if (closeRequest === 'destroy') {
        this.chatInfoSubscription.unsubscribe();
        this.conversationInfo=null;
      }
    });
  }

  sendMessage=async () => {
    this.previousChatRequestQuery.opr=1;
    if(this.previousChatRequestQuery.query.trim() !== '') {
      const userId=this.loggedInUser.id;
      const shopId=this.loggedInUser.shop_id;
      const customerId=this.conversationInfo!.customer_id;
      const socketPayload={
        message: this.previousChatRequestQuery.query,
        userId: userId,
        shopId: shopId,
        customerId: customerId,
        conversationId: this.conversationInfo.conversation_id,
        lastTimeStamp: this.previousChatRequestQuery.lastTimeStamp,
        opr: this.previousChatRequestQuery.opr
      };
      this.socket.emit('addMessageToDB',socketPayload);
      this.previousChatRequestQuery.query='';
      this.previousChatRequestQuery.opr=1;
    }
  };

  startGetAndReadProcess = async () => {
    this.cycleStatusActive = true;
    this.loggedInUser = await JSON.parse(localStorage.getItem('loggedInUser'));
    this.shopDetails = await this.globalService.getShopDetails();
    this.globalService
      .callAPI(globalConstants.CHAT_API_GET_PREVIOUS_MESSAGES_URL, {
        customerId: this.conversationInfo.customer_id,
        shopId: this.loggedInUser.shop_id,
        page: this.previousChatRequestQuery.page,
        limit: this.previousChatRequestQuery.limit,
        // query: this.previousChatRequestQuery.query, // TBD later
        lastTimeStamp: this.previousChatRequestQuery.lastTimeStamp,
        opr: this.previousChatRequestQuery.opr,
      })
      .toPromise()
      .then((getMessages) => {
        this.isCustomerGloballyUnsubscribed =
          getMessages.data.isCustomerGloballyUnsubscribed;
        this.isCustomerLocallyBlacklisted =
          getMessages.data.isCustomerLocallyBlacklisted;
        this.isTwoWaySMSEnabled = getMessages.data.isTwoWaySMSEnabled;
        this.isOneWaySMSEnabled = getMessages.data.isOneWaySMSEnabled;
        this.isSMSProviderSetupEnabled =
          getMessages.data.isSMSProviderSetupEnabled;
          this.handleWaringMsgOnTextBox();
        if (getMessages.data.previousMessages.length) {
          this.chats = [...this.chats, ...getMessages.data.previousMessages];
          if (this.conversationInfo['conversation_id'] === undefined) {
            // assign converstion id to active chat if conversation does't exists
            this.conversationInfo['conversation_id'] =
              getMessages.data.previousMessages[0].chat_id;
          }
          if (
            this.previousChatRequestQuery.opr === 1 &&
            !this.previousChatRequestQuery.lastTimeStamp
          ) {
            this.showMoreButton = getMessages.data.showMoreButton;
          }
        }
      })
      .then(() => {
        let last=this.chats[this.chats.length-1];
        this.previousChatRequestQuery.lastTimeStamp=last?.add_date;
        this.setUnreadMessagesAsRead();
      }).then(() => {
        setTimeout(() => {
          const element = document.getElementById('chat-area-messages');
          if (element) {
            document
              .getElementById('chat-area-messages')
              .scrollIntoView({ block: 'end' });
          }
        }, 500);
      })
  };

  setUnreadMessagesAsRead = async () => {
    if (this.modalStatus === 'active') {
      /**
        * read should be on call in mobile view only when chat area is active
        */
      if (this.onMobileScreen && this.conversationInfo) {
        const getUnreadChats = this.chats
          .filter((chat) => chat.read_status === 0)
          .map((chat) => chat.id);
        if (getUnreadChats.length) {
          const shopInfo = await localStorage.getItem('shop-details');
          this.globalService
            .callAPI(globalConstants.CHAT_API_GET_READ_MESSAGES_URL, {
              chatIds: getUnreadChats,
              shopId: JSON.parse(shopInfo).id,
              conversationId: this.conversationInfo.conversation_id,
            })
            .toPromise().then(() => {
              this.updateBadge.emit(this.conversationInfo!.conversation_id);
              this.chats = this.chats.map((chat) => {
                if (getUnreadChats.includes(chat.id)) {
                  chat.read_status = 1;
                }
                return chat;
              });
            });
        }
      } else {
        const getUnreadChats = this.chats
          .filter((chat) => chat.read_status === 0)
          .map((chat) => chat.id);
        if (getUnreadChats.length) {
          const shopInfo = await localStorage.getItem('shop-details');
          this.globalService
            .callAPI(globalConstants.CHAT_API_GET_READ_MESSAGES_URL, {
              chatIds: getUnreadChats,
              shopId: JSON.parse(shopInfo).id,
              conversationId: this.conversationInfo.conversation_id,
            })
            .toPromise().then(() => {
              this.updateBadge.emit(this.conversationInfo!.conversation_id);
              this.chats = this.chats.map((chat) => {
                if (getUnreadChats.includes(chat.id)) {
                  chat.read_status = 1;
                }
                return chat;
              });
            });
        }
      }
      this.cycleStatusActive = false;
    }else{
      this.cycleStatusActive = false;
    }
  };


  handleSelectContactInfo() {
    this.SelectContactForInfo.emit();
  }

  handleBackFormChatArea() {
    /**
     * When chat area is closed and customer list is active then chat are should be null
     */
    if (this.onMobileScreen) {
      this.chatInfoSubscription.unsubscribe();
      this.conversationInfo = null;
    }
    this.BackToContact.emit();
  }

  getTimeInString = (time: moment.MomentInput) => {
    return this.helperService.convertTimeInString(this.shopDetails,time);
  }

  paginate = async (opr: string) => {
    if(this.socketConnectionStatus) {
      if(opr === '2') {
        this.previousChatRequestQuery = {
          ...this.previousChatRequestQuery,
          opr: 2,
          page: this.previousChatRequestQuery.page+1
        };
        this.chatInfoSubscription = this.globalService.callAPI(globalConstants.CHAT_API_GET_PREVIOUS_MESSAGES_URL, {
          customerId: this.conversationInfo.customer_id,
          shopId: this.loggedInUser.shop_id,
          page: this.previousChatRequestQuery.page,
          limit: this.previousChatRequestQuery.limit,
          query: this.previousChatRequestQuery.query,
          lastTimeStamp: this.previousChatRequestQuery.lastTimeStamp,
          opr: this.previousChatRequestQuery.opr.toString()
        })
          .subscribe((response) => {
            this.chats = [...response.data.previousMessages, ...this.chats];
            this.showMoreButton = response.data.showMoreButton;
            this.setUnreadMessagesAsRead();
          });
      }
    }
  };

  getCustomerInitials() {
    const initials=this.conversationInfo.first_name[0].toUpperCase()+this.conversationInfo.last_name[0].toUpperCase();
    return initials.toString();
  }

  getUserInitials(chat:IChat) {
    const initials=chat.user_details.first_name[0]+chat.user_details.last_name[0];
    return initials.toUpperCase();
  }
  onKeydown(event) {
    event.preventDefault();
  }
  onMobileNumberClick(event: Event) {
    event.stopPropagation();
  }

  identify(index, item) {
    return item.id;
  }

  handleWaringMsgOnTextBox() {
    this.displayErrorMsg = 0;
    if (this.isTwoWaySMSEnabled == false && this.conversationInfo && this.displayErrorMsg == 0) {
      this.displayErrorMsg = 1;
    } else if (this.isSMSProviderSetupEnabled == false && this.conversationInfo && this.displayErrorMsg == 0) {
      this.displayErrorMsg = 2;
    } else if (this.isCustomerGloballyUnsubscribed == true && this.conversationInfo && this.displayErrorMsg == 0) {
      this.displayErrorMsg = 3;
    } else if (this.isCustomerLocallyBlacklisted == true && this.conversationInfo && this.displayErrorMsg == 0) {
      this.displayErrorMsg = 4;
    }
  }

  ngOnDestroy() {
    this.chatInfoSubscription.unsubscribe();
  }
}
