<mat-form-field class="date-picker-field car-input btn-block" floatPlaceholder="never" appearance="fill">
  <mat-label>Enter a date range</mat-label>
  
  <!-- Date range picker -->
  <mat-date-range-input [formGroup]="range" [rangePicker]="picker" class="placehoderStyle" [disabled]="isDisabled">
    <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="handleDateRangeChange()">
    <input matEndDate formControlName="end" placeholder="End date" (dateChange)="handleDateRangeChange()">
  </mat-date-range-input>
  
  <!-- Date picker toggle -->
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker [color]="color"></mat-date-range-picker>

  <!-- Error messages for invalid start and end dates -->
  <mat-error *ngIf="isInvalidDate">
    Invalid date range
  </mat-error>
</mat-form-field>
