 <div mat-dialog-title class="relative limit-width">
  <span *ngIf="formType == 'title'">Edit Request Title</span>
  <span *ngIf="formType == 'appt_date'">Edit Appointment Date</span>
  <span *ngIf="formType == 'scheduled_date'">Edit Scheduled Date</span>
  <span *ngIf="formType == 'start_date'">Edit Start Date</span>
  <span *ngIf="formType == 'end_date'">Edit End Date</span>
  <span *ngIf="formType == 'est_hours'">Est. Service Time</span>
  <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
    <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>
<form class="form-horizontal form-control-normal" (ngSubmit)="editSRdetails()" autocomplete="off" [formGroup]="SRForm">

  <mat-dialog-content class="ticket_dialog_content">

    <div class="display-full margin-bottom-" *ngIf="formType == 'title'">
      <div class="col-md-6">
      <label>Request Title:</label>
      <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill" >
        <input matInput placeholder="Request Title"  title="Request Title"
          class="form-control input-lg custom-form-input" maxlength="255" formControlName="event_request_title" required />
      </mat-form-field>
      </div>
      </div>

      <div class="display-full margin-bottom-" *ngIf="formType == 'est_hours'">
        <div class="col-md-6">
        <label>Est. Service hours:</label>
        <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill" >
          <input matInput placeholder="Est. Service hours" title="Est. Service hours"
            class="form-control input-lg custom-form-input" (keypress)="onlyNumericKey($event)" maxlength="20" formControlName="event_est_hours" />
        </mat-form-field>
        </div>
        </div>

        
      <div class="display-full margin-bottom-5 date-edit-wrapper" *ngIf="formType == 'appt_date'">
        <div class="col-md-6">
          <label> Appointment Date: </label>
          <div class="vlio-datepicker relative">
            <dp-date-picker mode="daytime" [config]="datePickerConfig" theme="dp-material dp-main" class="btn-block"
            (onChange)="checkIfValid();" formControlName="event_appt_date" #dayPicker></dp-date-picker>
            <button type="button" mat-icon-button (click)="dayPicker.api.open();">
              <mat-icon>date_range</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="display-full margin-bottom-5" *ngIf="formType == 'scheduled_date'">
        <div class="col-md-6">
          <label> Schedule Date: </label>
          <div class="vlio-datepicker relative">
            <dp-date-picker mode="daytime" [config]="dateSPickerConfig" theme="dp-material dp-main" class="btn-block"
            (onChange)="checkIfValid();" formControlName="event_scheduled_date" #dayPicker></dp-date-picker>
            <button type="button" mat-icon-button (click)="dayPicker.api.open();">
              <mat-icon>date_range</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="display-full margin-bottom-5" *ngIf="formType == 'start_date'">
        <div class="col-md-6">
          <label> Start Date: </label>
          <div class="vlio-datepicker relative">
            <dp-date-picker mode="daytime" [config]="datePickerConfig" theme="dp-material dp-main" class="btn-block"
            (onChange)="checkIfValid();" formControlName="event_start_date" #dayPicker></dp-date-picker>
            <button type="button" mat-icon-button (click)="dayPicker.api.open();">
              <mat-icon>date_range</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="display-full margin-bottom-5" *ngIf="formType == 'end_date'">
        <div class="col-md-6">
          <label> End Date: </label>
          <div class="vlio-datepicker relative">
            <dp-date-picker mode="daytime" [config]="datePickerConfig" theme="dp-material dp-main" class="btn-block"
            (onChange)="checkIfValid();" formControlName="event_end_date" #dayPicker></dp-date-picker>
            <button type="button" mat-icon-button (click)="dayPicker.api.open();">
              <mat-icon>date_range</mat-icon>
            </button>
          </div>
        </div>
      </div>

  </mat-dialog-content>

  <mat-dialog-actions class="inquiry-action relative">
    <div class="flexBtns">
      <button type="submit" mat-raised-button color="accent" title="Save" [disabled]="submitDisabled"> Save and Continue</button>
      <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
    </div>
  </mat-dialog-actions>
</form>