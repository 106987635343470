<div mat-dialog-title class="relative" >
    USER AUTHENTICATION
<!--    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
        <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>-->
</div>
<!-- BODY -->
<mat-dialog-content>
    <form class="form-horizontal form-control-normal" autocomplete="off" [formGroup]="checkForPwdForm" (ngSubmit)="validatePassword()">
        <div class="display-full margin-bottom-5">
            <div class="col-sm-12">
                <label *ngIf="!deleteNote && (!changePassword)" for="password">Please enter your user password for authentication to reopen a ticket.</label>
                <label *ngIf="deleteNote && (!changePassword)" for="password">Please enter your user password for authentication to delete a ticket.</label>
                <label *ngIf="changePassword" for="password">Please enter your user password for authentication to change customer password.</label>
                <mat-form-field class="car-input btn-block" floatPlaceholder="never"  hideRequiredMarker="false">
                    <input  matInput placeholder="Password" formControlName="password"  class="form-control input-lg custom-form-input" (keyup)="checkIfValid()"  [type]="hide ? 'text' : 'password'"/>
                    <mat-error *ngIf="checkForPwdForm.controls.password.hasError('required')">
                        Password is required
                    </mat-error>
                    <mat-error *ngIf="checkForPwdForm.controls.password.hasError('minlength')">
                        The password must be minimum 6 characters
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="display-full margin-bottom-5">
          <div class="col-sm-12 text-right">
            <button type="submit" mat-raised-button  color="accent" title="Submit" [disabled]="submitted">
                SUBMIT
            </button>
            <button mat-raised-button class="font-bold" title="Cancel" (click)="cancelAuthentication()">CANCEL</button>
          </div>
      </div>
    </form>
</mat-dialog-content>

