import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { globalFunctions } from '../../../../environments/globalFunctions';
  /**
   * @trackID <:CAR-1416> Edit Customer Profile Change Log View
   * @created 2020-02-18
   * @createdBY Vikas Kumar
   * @return
   */
@Component({
  selector: 'app-profilelogdiff',
  templateUrl: './profilelogdiff.component.html',
  styleUrls: ['./profilelogdiff.component.scss']
})
export class ProfilelogdiffComponent implements OnInit {

  public param:any={}
  public oldData:any=null
  public newData:any=null
  public reqOrigin=globalFunctions.requestOrigin()
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public gs: GlobalService) {
    this.param=data
  }

  ngOnInit() {
    this.oldData=JSON.parse(this.param.old_record_value)
    this.newData=JSON.parse(this.param.new_record_value)
  }
  originType(t){
    let f='-'
    this.reqOrigin.forEach((i)=>{
      if(i.value==t){
        f=i.label
        return true;
      }
    })
    return f
  }

}
