<!--
    @author Shashank Thakur
    @trackID CAR-1412
    @updated 18-03-2020
-->
<ng-container *ngIf="popupData && popupData.isPopup">
    <div mat-dialog-title class="relative">
        Add New {{ currentUserInfo.profile_type == '2' ? 'Contact' : 'Customer' }}
        <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
          <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <ng-container *ngTemplateOutlet="add_customer_form_template"></ng-container>
    </mat-dialog-content>
    <mat-dialog-actions class="inquiry-action relative">
        <div class="text-right">
            <button mat-raised-button color="accent" (click)="addCustomer()" class="font-bold">Submit</button>
            <button mat-raised-button color="accent" (click)="addCustomer(2)" class="font-bold">Submit & add vehicle</button>
            <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
        </div>
    </mat-dialog-actions>
</ng-container>

<ng-container *ngIf="!popupData || !popupData.isPopup">
    <div id="mainCompotents">
    <div class="panel panel-primary" id="dashboardQuickLinks">
        <div class="panel-heading padding-left-15">
            <h3 class="panel-title">Add New {{ currentUserInfo.profile_type == '2' ? 'Contact' : 'Customer' }}</h3>
        </div>
        <div class="panel-body padding-0" style="border:none;">
            <!-- Login page start -->
            <div class="bgcolor-lightgrey form-content addNew-customer">
                <div class="container-fluid">
                    <!--
                        @author Shashank Thakur
                        @trackID CAR-1412
                        @updated 18-03-2020
                    -->
                    <ng-container *ngTemplateOutlet="add_customer_form_template"></ng-container>
                </div>
            </div>
        </div>
    </div>
    </div>
</ng-container>

<!--
    @author Shashank Thakur
    @trackID CAR-1412
    @updated 18-03-2020
    @see A template is created to share the form fields between page UI and popup UI. This template is then called in ng-container
-->
<ng-template #add_customer_form_template>
    <form [ngClass]="{'form-inline row padding-bottom-15':(!popupData || !popupData.isPopup), 'form-horizontal form-control-normal': (popupData && popupData.isPopup)}" role="form" [formGroup]="addNewCustomer" novalidate (ngSubmit)="addCustomer()" id="add-customer-form">
        <div class=" col-xs-12 form-block">
            <h2 class="fontcolor-blue sub-heading">LOGIN INFORMATION</h2>
            <div class="row">
                <div [ngClass]="{'form-group':(!popupData || !popupData.isPopup), 'col-sm-12 col-md-6': true }" >
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input  matInput placeholder="Email Address(Optional)" formControlName="email" maxlength="200" class="form-control input-lg custom-form-input"  (blur)="checkEmail()" />
                        <mat-error *ngIf="addNewCustomer.controls.email.hasError('pattern')">
                            Please enter a valid Email Address
                        </mat-error>
                        <mat-error *ngIf="addNewCustomer.controls.email.hasError('emailAlreadyExit')">
                            Email Address<strong> already exists. </strong> <a href="javascript:void(0)" (click)="customerInformationPage(getEmailCheck,'email')" target="_self">Click Here.</a>
                        </mat-error>
                    </mat-form-field>
                    </div>
                <!-- mobile phone column start -->
                <div [ngClass]="{'form-group':(!popupData || !popupData.isPopup), 'col-sm-12 col-md-6': true }">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input  matInput placeholder="Mobile Phone*" title="Mobile Phone" (blur)="checkMobilePhone()" formControlName="mobile_phone"  class="form-control input-lg custom-form-input" required maxlength="15" [textMask]="{mask: phoneMask}" />
                        <mat-error *ngIf="addNewCustomer.controls.mobile_phone.hasError('required')">
                            Mobile Phone is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="addNewCustomer.controls.mobile_phone.hasError('minLength') && !addNewCustomer.controls.mobile_phone.hasError('required')">
                            Invalid Mobile Phone
                        </mat-error>
                        <mat-error *ngIf="addNewCustomer.controls.mobile_phone.hasError('contactAlreadyExit')">
                            Mobile Phone <strong>already exists. </strong> <a href="javascript:void(0)" (click)="customerInformationPage(getMobileCheck,'mobile')" target="_self">Click Here.</a>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class=" col-xs-12 form-block">
            <h2 class="fontcolor-blue sub-heading">PERSONAL INFORMATION</h2>
            <div class="row">
                <div [ngClass]="{'form-group':(!popupData || !popupData.isPopup), 'col-sm-12 margin-bottom-5': true }" class="">
                    <mat-checkbox [value]="" (click)="businessNameToggle()">Add {{globalService.getBORDName()}}</mat-checkbox>
                </div>
            </div>
            <div class="row">
                <div  [ngClass]="{'form-group':(!popupData || !popupData.isPopup), 'col-sm-12 col-md-4': true }">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                        <input matInput placeholder="First Name*" title="First Name" formControlName="first_name" maxlength="200" class="form-control input-lg custom-form-input" required >
                        <mat-error *ngIf="addNewCustomer.controls.first_name.hasError('required')">
                            First Name is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="addNewCustomer.controls.first_name.hasError('pattern')">
                            Must not contain any special characters.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div [ngClass]="{'form-group':(!popupData || !popupData.isPopup), 'col-sm-12 col-md-4': true }">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input  matInput placeholder="Middle Name" title="Middle Name" formControlName="middle_name" maxlength="200"  class="form-control input-lg custom-form-input">
                        <mat-error *ngIf="addNewCustomer.controls.middle_name.hasError('pattern')">
                            Must not contain any special characters.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div [ngClass]="{'form-group':(!popupData || !popupData.isPopup), 'col-sm-12 col-md-4': true }">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" hideRequiredMarker="false" appearance="fill" appearance="fill">
                        <input  matInput placeholder="Last Name*" title="Last Name" formControlName="last_name" maxlength="200"  class="form-control input-lg custom-form-input" required >
                        <mat-error *ngIf="addNewCustomer.controls.last_name.hasError('required')">
                            Last Name is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="addNewCustomer.controls.last_name.hasError('pattern')">
                            Must not contain any special characters.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div [ngClass]="{'form-group':(!popupData || !popupData.isPopup), 'col-sm-12 col-md-4': true }" [@popOverState]="showBusinessName">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input  matInput placeholder="{{globalService.getBORDName()}}" title="{{globalService.getBORDName()}}" formControlName="business_name" maxlength="200"  class="form-control input-lg custom-form-input">
                        <mat-error *ngIf="addNewCustomer.controls.business_name.hasError('pattern')">
                            Must not contain any special characters.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class=" col-xs-12 form-block">
            <h2 class="fontcolor-blue sub-heading">CONTACT INFORMATION</h2>
            <div class="row contact-info">
                <div class="form-group col-sm-12 col-md-6 margin-bottom-0">
                  <mat-checkbox class="btn-block" labelPosition="after" formControlName="address_is_required">
                    No Address 
                  </mat-checkbox>
                </div>
            </div>  
            <div class="row">               
                <div [ngClass]="{'form-group':(!popupData || !popupData.isPopup), 'col-sm-12 col-md-6': true }">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input  matInput placeholder="Address Line 1*" title="Address 1" formControlName="address_one" maxlength="200" class="form-control input-lg custom-form-input">
                        <mat-error *ngIf="addNewCustomer.controls.address_one.hasError('required')">
                            Address Line 1 is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div [ngClass]="{'form-group':(!popupData || !popupData.isPopup), 'col-sm-12 col-md-6': true }">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input  matInput placeholder="Address Line 2" title="Address 2" formControlName="address_two" maxlength="200" class="form-control input-lg custom-form-input">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div [ngClass]="{'form-group':(!popupData || !popupData.isPopup), 'col-sm-12 col-md-6': true }">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input  matInput placeholder="{{zipLabel}}*"
                         title="{{zipLabel}}"  #zipcode (input)="zipcode.value = zipcode.value.toUpperCase()"
                          formControlName="zipcode" class="form-control input-lg custom-form-input"
                           required maxlength="15"  
                           (blur)="setCountryStateCityViaZipCode()"
                           />
                        <mat-error *ngIf="addNewCustomer.controls.zipcode.hasError('required')">
                            {{zipLabel}} is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="addNewCustomer.controls.zipcode.hasError('pattern')">
                            Please enter valid {{zipLabel.toLowerCase()}}
                        </mat-error>
                        </mat-form-field>
                </div>
                <div [ngClass]="{'form-group':(!popupData || !popupData.isPopup), 'col-sm-12 col-md-6 uc-ngx-select': true }">
                    <ngx-select class="display-full margin-bottom-20" 
                        [items]="countries" 
                        [(ngModel)]="selectedCountry" 
                        [ngModelOptions]="{standalone: true}" 
                        (select)="handleCountryDropdownChange($event)"
                        placeholder="Select Country">
                    </ngx-select>
                </div>                
            </div>

            <div class="row">
                <div [ngClass]="{'form-group':(!popupData || !popupData.isPopup), 'col-sm-12 col-md-6 uc-ngx-select': true }">
                    <ngx-select class="display-full margin-bottom-20" 
                        [items]="states" 
                        [(ngModel)]="selectedState"
                        [ngModelOptions]="{standalone: true}"
                        (select)="handleStateDropdownChange($event)"
                        [disabled]="disableStates"
                        placeholder="Select State">
                    </ngx-select>
                    <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                    <mat-error *ngIf="addNewCustomer.controls.state.hasError('required') && addNewCustomer.get('state').touched">
                        State is <strong>required</strong>
                      </mat-error>
                    </div>
                </div>
                <div [ngClass]="{'form-group':(!popupData || !popupData.isPopup), 'col-sm-12 col-md-6 uc-ngx-select': true }">
                    <ngx-select class="display-full margin-bottom-20" 
                         [items]="cities" 
                         [(ngModel)]="selectedCity"
                         [ngModelOptions]="{standalone: true}" 
                        (select)="handleCityDropdownChange($event)"
                        [disabled]="disableCities"
                        placeholder="Select City">
                    </ngx-select>
                    <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                      <mat-error *ngIf="addNewCustomer.controls.city.hasError('required') && addNewCustomer.get('city').touched">
                          City is <strong>required</strong>
                       </mat-error>
                    </div>
                </div>
              
            </div>

            <div class="row">
                <div [ngClass]="{'form-group':(!popupData || !popupData.isPopup), 'col-sm-12 col-md-6': true }">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input  matInput placeholder="Home Phone" title="Home Phone" formControlName="home_phone"  class="form-control input-lg custom-form-input"  maxlength="15" [textMask]="{mask: phoneMask}" (keyup)="checkHomePhoneValidity()"/>
                        <mat-error *ngIf="addNewCustomer.controls.home_phone.hasError('phoneError')">
                            Invalid Home Phone number
                        </mat-error>
                    </mat-form-field>
                </div>
                <div [ngClass]="{'form-group':(!popupData || !popupData.isPopup), 'col-sm-12 col-md-6': true }">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input  matInput placeholder="Business Phone" title="Business Phone" formControlName="business_phone"  class="form-control input-lg custom-form-input"  maxlength="15" [textMask]="{mask: phoneMask}" (keyup)="checkBusinessPhoneValidity()"/>
                        <mat-error *ngIf="addNewCustomer.controls.business_phone.hasError('phoneError')">
                            Invalid Business Phone number
                        </mat-error>
                    </mat-form-field>
                </div>
               
            </div>

            <div class="row">
                <div [ngClass]="{'form-group':(!popupData || !popupData.isPopup), 'col-sm-12 col-md-6 uc-ngx-select': true }">
                    <ngx-select class="display-full margin-bottom-20" 
                        formControlname="customer_type"
                        [items]="getCustomerTypes" 
                        (select)="getSelectedOptionCustomerType($event)" 
                        [(ngModel)]="defaultCustomerType"
                        [ngModelOptions]="{standalone: true}"
                        placeholder="Select Customer Type">
                    </ngx-select>
                    <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                        <mat-error *ngIf="addNewCustomer.controls.customer_type.hasError('required')">
                            Customer Type is <strong>required</strong>
                        </mat-error>
                    </div>
                </div>
                <div [ngClass]="{'form-group':(!popupData || !popupData.isPopup), 'col-sm-12 col-md-6 uc-ngx-select': true }">
                    <ngx-select class="display-full margin-bottom-20" 
                    [items]="timezoneData" 
                    formControlName="timezone"
                    (select)="getSelectedOptionTimezone($event)"
                    placeholder="Select Timezone">
                </ngx-select>
                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                   <mat-error *ngIf="addNewCustomer.controls.timezone.hasError('required')">
                        Timezone is <strong>required</strong>
                   </mat-error>
                 </div>
                </div>
            </div>

            <div class="row">
                <div [ngClass]="{'form-group':(!popupData || !popupData.isPopup), 'col-sm-12 col-md-6': true }">
                    <mat-checkbox class="btn-block" labelPosition="after" (change)="showTaxExempted($event)" formControlName="tax_exempted" >
                        Tax Exempted
                    </mat-checkbox>
                </div>
            </div>
            
            <div class="row">
                <div [ngClass]="{'form-group':(!popupData || !popupData.isPopup), 'col-sm-12 col-md-6': true }">
                    <mat-checkbox class="btn-block margin-bottom-5" labelPosition="after" (change)="showRequireApproval($event)" formControlName="require_approval" [(ngModel)]="requireApprovalCheck">
                        Require Approval
                    </mat-checkbox>
                </div>
            </div>
            <div class="row" [@popOverState]="checkApprovalAmount">
                <div [ngClass]="{'form-group':(!popupData || !popupData.isPopup), 'col-sm-12 col-md-4': true }">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input type="text" matInput placeholder="Amount" title="Amount" formControlName="approval_amount"  class="form-control input-lg custom-form-input" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event);validateNumbersWithDecimal($event,3)" maxlength="12">
                        <mat-error *ngIf="addNewCustomer.controls.approval_amount.hasError('pattern')">
                            Must be a number with upto 2 decimal places.
                          </mat-error>
                    </mat-form-field>
                </div>  
                <p class="help-block col-sm-12"><strong>Note: </strong>If the Grand Total is exceeded by this amount, shop needs approval.</p>
            </div>  
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <mat-checkbox class="btn-block" labelPosition="after" (change)="isSubletWork($event)" [(ngModel)]="isSubletWorkCheck" [ngModelOptions]="{standalone: true}">
                        Sublet Work
                    </mat-checkbox>
                </div>
            </div>
            <div class="row">
                <p class="help-block col-sm-12"><strong>Note: </strong>Use shop account as customer for sublet work</p>
            </div>            
        </div>
        <div class="row margin-0">
            <div class=" col-xs-12 col-md-3 form-block submit-block" *ngIf="(!popupData || !popupData.isPopup)">
                <button type="submit" mat-raised-button class="car-btn btn-block text-uppercase  btn-lg font-bold fontcolor-white" color="accent" title="Submit" style="max-width: 300px;">Submit</button>
            </div>
            <div class=" col-xs-12 col-md-5 col-lg-4 form-block submit-block" *ngIf="(!popupData || !popupData.isPopup)">
                <button type="button" mat-raised-button (click)="addCustomer(2)" class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Submit & add vehicle" style="max-width: 300px;">Submit & add vehicle</button>
            </div>
        </div>
    </form>
</ng-template>