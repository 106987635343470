<div class="panel panel-primary margin-0">
    <div class="panel-heading padding-left-30">
    <h3 class="panel-title">Payment Status</h3>
    </div>
    <div class="panel-body bgcolor-lightgrey">
         <div class="payment-response fail"  style="height: auto;">
            <div>
                <div class="payment-content" style="max-width: 100%;">
                    <span>
                        <i class="fa fa-times-circle" aria-hidden="true"></i>
                    </span>
                    <h2 class="title">Failure!</h2>
                    <h4 class="msg">{{subscriptionMessage}}</h4>
                </div>
            </div>
        </div>
    </div>
    
</div>