import { Component, OnInit, ViewChild, OnDestroy  } from '@angular/core';
import { ViktableComponent, VikSource } from '../../../admin/viktable/viktable.component';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
const UPLOAD_LIST = "queue-order-detail-";
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-quedetail',
  templateUrl: './quedetail.component.html',
  styleUrls: ['./quedetail.component.scss']
})
export class QuedetailComponent extends ViktableComponent implements OnInit,OnDestroy {

  public tablesData = this;
  displayedColumns = ['Name', 'Quantity','in Stock','add_date', 'Actions'];
  public status = ['Pending', 'Processing', 'Finished'];
  dataSource: any;
  url = "";
  public options = {
    user_type: "User",
    user_id: "",
    search_keyword: "",
    que_id: "",
    start: 0,
    limit: 10
  }
  public user: any = {};
  public orderQueId: any = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public subscriptionDataForAPI: Subscription = new Subscription();

  constructor(public gs: GlobalService, public router: Router, private activatedRoute: ActivatedRoute) {
    super(globalConstants.API_STOCK_ORDER_QUEUE_DETAIL, gs);
    this.user = this.gs.getCurrentUser();
    if (this.user) {
      this.returnData = 'queDetail';
      this.sessionKey = UPLOAD_LIST;
      this.url = globalConstants.API_STOCK_ORDER_QUEUE_DETAIL;
      this.gs.setMetaData("SHOPS", "QUEUE_ORDER_DETAIL")
    } else {
      this.router.navigate(['shops']);
    }
  }

  ngOnInit() {
    this.getParam();
    setTimeout(() => {
      let isEnable = this.gs.getAcl('stock_order', 'addNew');
      if (!(isEnable)) {
        this.router.navigate(['shops/action/forbidden']);
      }
    }, globalConstants.RELOAD_WAIT);
    this.initOptions();
  }

  ngAfterViewInit(){
    this.setDataTable();
  }
  getParam() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.orderQueId = params["id"];
    });
  }
  /** init options */
  initOptions() {
    this.options = this.gs.localGetterSetter(this.options, this.options, UPLOAD_LIST);
    this.options.que_id = this.orderQueId;
    this.options.user_type = this.user.user_type;
    this.options.user_id = this.user.id;
  }
  /** set data source of order que detail list */
  setDataTable() {
    this.dataSource = new VikSource(this.tablesData, this.sort, this.paginator);
    this.dataSource.getSortedData = this.sortedData;
  }
  /** create record, that show on order queue list */
  createRecordrecord(record) {
    return {
      que_id: record.que_id,
      part_id: record.part_id,
      name: record.name,
      quantity: record.quantity,
      inStock:record.stock_quantity,
      add_date:this.gs.getFullFormateDate(record.add_date,'G','B')
    };
  }
  /** sort order que list */
  sortedData(): any[] {
    let th: any = this;
    const data = th._exampleDatabase.data.slice();
    if (!th._sort.active || th._sort.direction == '') { return data; }
    return data.sort((a, b) => {
      let propertyA;
      let propertyB;
      switch (th._sort.active) {
        case 'Name': propertyA = a.name; propertyB = b.name; break;
        case 'Create At': propertyA = a.added_on; propertyB = b.added_on; break;
        default: return 0;
      }
      let valueA = isNaN(propertyA) ? propertyA.toUpperCase() : propertyA;
      let valueB = isNaN(propertyB) ? propertyB.toUpperCase() : propertyB;

      return (valueA < valueB ? -1 : 1) * (th._sort.direction == 'asc' ? 1 : -1);
    });
  }
  /** return que status */
  showStatus(s) {
    return (typeof this.status[s] == 'undefined' ? 'N/A' : this.status[s]);
  }
  preppareStock() {
    this.router.navigate(['shops/stock-order/' + this.orderQueId]);
  }
  /** delete part from stock order queue */
  deletePart(part) {
    this.gs.confirmDialog("yes", "Are you sure you want to delete this part from queue?").subscribe((response) => {
      if (response != undefined && response.toString() == "yes") {
        let data = { user_id: this.user.id, que_id: part.que_id,part_id: part.part_id };
       this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_DELETE_PART_FROM_ORDER_QUEUE, data).subscribe((res:any) => {
          if (res.result == 1) {
            this.gs.snackbar("success", res.message);
            this.setDataTable();
          } else {
            this.gs.snackbar("error", res.message);
          }
        }, (error) => { });
      }
    });
  }

  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
  }  
}
