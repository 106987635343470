<!-- header -->
<div mat-dialog-title class="relative job-paste-confirmation-title">
    Paste Job Confirmation
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
        <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
</div>
<!-- BODY -->
<mat-dialog-content class="job-paste-confirmation"> 
    <div class="row job-details-row">
        <div class="form-group col-sm-12">
            <label class="margin-bottom-10"><span class="info-text">Please select at least one job in which you want to paste the job data.</span></label>
            <label class="material-radio">
                <input type="radio" name="supply_charges_disp_type"  (click)="handleSelectJob('targetJob')" value="1"/>
                <div class="margin-left-10">
                    <span>{{JobOneNameActive.level1JobName}} -> {{JobOneNameActive.level2JobName}}  <span *ngIf="JobOneNameActive.level3JobName !== null"> -> {{JobOneNameActive.level3JobName}}</span></span> <span class="text-accent">&nbsp;(Active Job)</span>
                </div>
            </label>
            <label class="material-radio">
                <input type="radio" name="supply_charges_disp_type"  (click)="handleSelectJob('pasteJob')" value="2"/>
                <div class="margin-left-10">
                    <span>{{jobTwoName.l1}} -> {{jobTwoName.l2}}<span *ngIf="jobTwoName.l3 !== ''"> -> {{jobTwoName.l3}}</span> </span>
                </div>
            </label>
              <div *ngIf="showErrMsg" class="alert-text text-danger">Please select at least one job to paste the data</div>
            <label class="margin-top-5 note-desc"><span class="font-bold">Note:</span><span> Job category will be added to SR automatically if it doesn't exist in SR.</span></label>
        </div>
       
    </div>
</mat-dialog-content>
<!-- footer -->
<mat-dialog-actions align="end">
    <div class="display-full">
        <button type="button" mat-raised-button class="mat-raised-button margin-right-10" color="accent" (click)="submittedJob()">
            Paste Job
        </button>
        <button mat-raised-button mat-dialog-close class="font-bold" title="Close" id="close">Close</button>
    </div>
</mat-dialog-actions>