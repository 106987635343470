<div class="row chat-info-area">
    <div class="col-sm-12 header-wrap" *ngIf="conversationInfo!=null">
        <div class="back-section">
            <div class="back-icon" *ngIf="onMobileScreen"><mat-icon (click)="onBackToChatArea()">arrow_back</mat-icon>
            </div>
            <div class="info-section">
                <figure class="margin-right-10">
                    <div class="img-xs">
                        <p>{{getCustomerInitials(conversationInfo)}}</p>
                    </div>
                </figure>
                <div class="contact-chatDetails-section flex">
                    <div class="contact-chatDetails">
                        <p class="text-bold margin-0">{{conversationInfo.first_name}} {{conversationInfo.last_name}}</p>
                        <p class="margin-0"><a class="text-black cursor-pointer" href="tel:{{conversationInfo.mobile_phone}}" [innerHTML]="conversationInfo.mobile_phone"></a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row contact-heading-section" *ngIf="!onMobileScreen">
        <div class="col-sm-12 info-heading">
            <h3 class="">Contact Info</h3>
        </div>
    </div>
    <div class="row vehicle-accessed-section" *ngIf="conversationInfo!=null">
        <mat-accordion class="col-sm-12 padding-10 padding-sm-bottom-0 contact-info-accordion">
            <mat-expansion-panel class="mat-expansion-panel personal-info-panel">
                <mat-expansion-panel-header class="mat-expansion-panel-header">
                    <h3 class="margin-0">Personal Info</h3>
                </mat-expansion-panel-header>
                <div class="row contactInfo-section">
                    <div class="col-sm-12">
                        <div class="row margin-0">
                            <div class="col-xs-3 col-md-4 col-lg-3 padding-left-0">Mobile:</div>
                            <div class="col-xs-9 col-md-8 col-lg-9 padding-left-10 mob-no"> <a class="text-black cursor-pointer" href="tel:{{conversationInfo.mobile_phone}}" [innerHTML]="conversationInfo.mobile_phone"></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 padding-top-15 padding-bottom-15">
                        <div class="row margin-0">
                            <div class="col-xs-3 col-md-4 col-lg-3 padding-left-0">Email:</div>
                            <div class="col-xs-9 col-md-8 col-lg-9 padding-left-10 email-id"><a class="text-black cursor-pointer" href="mailto:{{conversationInfo.email}}" [innerHTML]="conversationInfo.email"></a></div>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="row">
                            <div class="col-xs-3 col-md-4 col-lg-3">Address: </div>
                            <div class="col-xs-9 col-md-8 col-lg-9 padding-left-0">
                                <div class="row margin-0 address-row">
                                    <div class="col-sm-12 padding-left-0 break-all" *ngIf="conversationInfo.address1"> {{ conversationInfo.address1}}</div>
                                    <div class="col-sm-12 padding-left-0 break-all" *ngIf="conversationInfo.address2">{{ conversationInfo.address2 }}</div>
                                    <div class="col-sm-12 padding-left-0 break-all" *ngIf="conversationInfo.city">  {{ conversationInfo.city}}
                                    </div>
                                    <div class="col-sm-12 padding-left-0 break-all">
                                        <span *ngIf="conversationInfo.state">{{ conversationInfo.state }}</span>
                                        <span *ngIf="conversationInfo.country">,{{ conversationInfo.country}}</span>
                                        <span *ngIf="conversationInfo.zip">,{{ conversationInfo.zip }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel class="mat-expansion-panel vehicle-expansion">
                <mat-expansion-panel-header class="mat-expansion-panel-header">
                    <h3 class="margin-0">Vehicle Accessed Recently</h3>
                </mat-expansion-panel-header>

                <div class="row col-row">
                    <div class="col-xs-12 col-md-12 col-sm-12 no-result-found" *ngIf="!vehicleHistory.length">
                        <p>No Results Found</p>
                    </div>
                    <div class="col-xs-12 col-md-12 col-sm-12 vehicle-col" *ngIf="vehicleHistory.length">
                        <div class="vehicle-section" *ngFor="let vehicle of vehicleHistory">
                            <figure class="margin-right-10">
                                <div class="img-wrap">
                                    <img class="img-xs" src="{{imgPath}}default-car.png" *ngIf="vehicle.vehicle_pic == ''">
                                    <img class="img-xs" src="{{imgPath}}{{vehicle.vehicle_pic}}" *ngIf="vehicle.vehicle_pic != ''">
                                </div>
                            </figure>
                            <div class="vehicle-details-section flex">
                                <div class="vehicle-details">
                                    <p class="text-bold margin-0 break-all">{{globalService.getGlobalVehicleName(vehicle) || 'N/A'}}</p>
                                    <p class="margin-0 text-uppercase"><span>VIN : </span> {{vehicle.vin || "N/A"}}</p>
                                    <p class="margin-0 text-uppercase"><span>Plate : </span> {{vehicle.license_plate || "N/A"}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" [hidden]="!showMoreVehicle">
                    <div class="col-sm-12">
                        <p class="text-right load-more">
                            <button class="btn btn-gray btn-block no-outline" [disabled]="!connectionActive" (click)="paginateVehicle()">Load More...</button>
                        </p>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel class="mat-expansion-panel sr-accessed-expansion" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <h3 class="margin-0">SR Accessed Recently</h3>
                </mat-expansion-panel-header>
                <mat-tab-group class="status-toggle" animationDuration="0ms">
                    <mat-tab label="Active" class="tab-label">
                        <div class="row padding-top-5 margin-0 sr-row">

                            <div class="col-sm-12 padding-0 no-result-found" *ngIf="SRHistory.active.length === 0">
                                <p>No Results Found</p>
                            </div>

                            <div class="col-sm-12 padding-0 sr-col" *ngIf="SRHistory.active.length > 0">
                                <div class="sr-details-section" *ngFor="let activesr of SRHistory.active">
                                    <div class="row margin-0">
                                        <div class="col-xs-9 col-sm-9 col-md-9 padding-left-0">
                                            <mat-chip-list class="mat-badge">
                                                <mat-chip
                                                    class="mat-chip-selected margin-0 {{ getStatusColor(activesr) }}"
                                                    selected="true">
                                                    <span class="font-bold">{{statuses[activesr.status]}}</span>
                                                    <span class="font-bold"></span>
                                                </mat-chip>
                                            </mat-chip-list>
                                        </div>
                                        <div class="col-xs-3 col-sm-3 col-md-3 check-section">
                                            <span>
                                                <!-- <i class="fa fa-info-circle padding-right-5"></i> -->
                                                <i *ngIf="activesr.on_the_lot === 1" class="fa fa-check-circle" title="On The Lot"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row margin-0 padding-top-10 padding-bottom-5">
                                        <div class="col-sm-12 padding-left-0 sr-id">
                                            <span class="cursor-pointer" (click)="navigateToSRView(activesr.id)">{{activesr.request_id}}</span>
                                        </div>
                                    </div>
                                    <div class="row margin-0 ">
                                        <div class="col-xs-1 col-sm-1 padding-0"><span><i class="fa fa-car"></i></span>
                                        </div>
                                        <div class="col-xs-11 col-sm-11 padding-left-5">
                                            <span>{{globalService.getGlobalVehicleName(activesr)||'N/A'}}</span>
                                        </div>
                                    </div>
                                    <div class="row margin-0">
                                        <div class="col-xs-1 col-sm-1 padding-0"><span><i
                                                    class="fa fa-credit-card"></i></span>
                                        </div>
                                        <div class="col-xs-11 col-sm-11 padding-left-5">
                                            <span class="text-uppercase">{{activesr.license_plate || 'N/A' }}</span> 
                                            <span class="hide"> (color : {{activesr.color ||"N/A"}})</span> 
                                        </div>
                                    </div>
                                    <div class="row margin-0">
                                        <div class="col-xs-1 col-sm-1 padding-0"><span><i
                                                    class="fa fa-calendar"></i></span>
                                        </div>
                                        <div class="col-xs-11 col-sm-11 padding-left-5">
                                            <span>{{globalService.getFullFormateDate(activesr.requested_delivery_date,'G','C')
                                                }}</span>
                                        </div>
                                    </div>
                                    <div class="row margin-0 padding-bottom-10">
                                        <div class="col-xs-1 col-sm-1 padding-0"><span><i
                                                    class='fa fa-pencil'></i></span></div>
                                        <div class="col-xs-11 col-sm-11 padding-left-5">{{activesr.title || 'N/A'}}</div>
                                    </div>
                                    <div class="row margin-0 total-section text-center">
                                        <div
                                            class="col-xs-9 col-sm-9 padding-left-0 padding-right-0 accept-reject-section">
                                            <div class="row margin-0">
                                                <div class="col-xs-6 col-sm-6 padding-left-0 acceptJobs">
                                                    <div> <span class="text-success">
                                                        <i class="fa fa-check-circle"></i></span>&nbsp; 
                                                    </div>
                                                    <div><span><i class="fa fa-dollar"></i> 0.00</span><span> (0)</span></div>

                                                </div>
                                                <div class="col-xs-6 col-sm-6 padding-left-0 rejectJobs"
                                                    *ngIf="activesr.status !== 8">
                                                    <div><span class="text-danger"><i class="fa fa-ban"></i></span></div>
                                                    <div><span><i class="fa fa-dollar"></i> 0.00</span><span> (0)</span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-3 col-sm-3 font-bold padding-0 paid-unpaid-section">
                                            <span class="text-success" *ngIf="activesr.status === 8">
                                                <i class="fa fa-dollar"></i> Paid
                                            </span>
                                            <div class="text-error" *ngIf="activesr.status !== 8">
                                                <span><i class="fa fa-dollar"></i></span> <span>{{activesr.grand_total | number: '1.2-2'}}
                                            </span></div>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                            <div class="col-sm-12 padding-0 lode-section">
                                <div class="row margin-0 ">
                                    <div class="col-sm-12 padding-0">
                                        <p class="text-right load-more">
                                            <button *ngIf="showMoreActiveSR" [disabled]="!connectionActive" (click)="paginateActiveSr()"
                                                class="btn btn-gray btn-block no-outline">Load
                                                More...</button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>

                    <mat-tab label="Completed" class="tab-label">
                        <div class="row padding-top-5 margin-0 sr-row">

                            <div class="col-sm-12 padding-0 no-result-found" *ngIf="SRHistory.completed.length === 0">
                                <p>No Results Found</p>
                            </div>

                            <div class="col-sm-12 padding-0 sr-col" *ngIf="SRHistory.completed.length > 0">
                                <div class="sr-details-section" *ngFor="let completedSR of SRHistory.completed">
                                    <div class="row margin-0">
                                        <div class="col-xs-9 col-sm-9 col-md-9 padding-left-0">
                                            <mat-chip-list class="mat-badge">
                                                <mat-chip
                                                    class="mat-chip-selected margin-0 {{ getStatusColor(completedSR) }}"
                                                    selected="true">
                                                    <span class="font-bold">{{statuses[completedSR.status]}}</span>
                                                    <span class="font-bold"></span>
                                                </mat-chip>
                                            </mat-chip-list>
                                        </div>
                                        <div class="col-xs-3 col-sm-3 col-md-3 check-section">
                                            <span *ngIf="completedSR.on_the_lot === 1">
                                                <i class="fa fa-info-circle padding-right-5"></i>
                                                <i class="fa fa-check-circle"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row margin-0 padding-top-10 padding-bottom-5">
                                        <div class="col-sm-12 padding-left-0 sr-id">
                                            <span class="cursor-pointer" (click)="navigateToSRView(completedSR.id)">{{completedSR.request_id}}</span>
                                        </div>
                                    </div>
                                    <div class="row margin-0 ">
                                        <div class="col-xs-1 col-sm-1 padding-0"><span><i class="fa fa-car"></i></span>
                                        </div>
                                        <div class="col-xs-11 col-sm-11 padding-left-5">
                                            <span>{{globalService.getGlobalVehicleName(completedSR) || 'N/A'}}</span>

                                        </div>
                                    </div>
                                    <div class="row margin-0">
                                        <div class="col-xs-1 col-sm-1 padding-0"><span><i
                                                    class="fa fa-credit-card"></i></span>
                                        </div>
                                        <div class="col-xs-11 col-sm-11 padding-left-5">
                                            <span class="text-uppercase">{{completedSR.license_plate || 'N/A'}}</span>
                                            <span class="hide"> (color :{{completedSR.color || 'N/A'}})</span>
                                        </div>
                                    </div>
                                    <div class="row margin-0">
                                        <div class="col-xs-1 col-sm-1 padding-0"><span><i
                                                    class="fa fa-calendar"></i></span>
                                        </div>
                                        <div class="col-xs-11 col-sm-11 padding-left-5">
                                            <span>{{globalService.getFullFormateDate(completedSR.sr_modify_date,'G','C')}}</span>
                                        </div>
                                    </div>
                                    <div class="row margin-0 padding-bottom-10">
                                        <div class="col-xs-1 col-sm-1 padding-0"><span>
                                                <i class='fa fa-pencil'></i></span>
                                        </div>
                                        <div class="col-xs-11 col-sm-11 padding-left-5">{{completedSR.title || 'N/A'}}</div>
                                    </div>
                                    <div class="row margin-0 total-section text-center">
                                        <div
                                            class="col-xs-9 col-sm-9 padding-left-0 padding-right-0 accept-reject-section">
                                            <div class="row margin-0">
                                                <div class="col-xs-6 col-sm-6 padding-left-0 acceptJobs">
                                                    <div><span class="text-success">
                                                        <i class="fa fa-check-circle"></i>
                                                    </span></div>
                                                    <div><span><i class="fa fa-dollar"></i> 0.00</span>
                                                        <span> (0)</span>
                                                    </div>
                                                </div>
                                                <div class="col-xs-6 col-sm-6 padding-left-0 rejectJobs">
                                                    <div><span class="text-danger">
                                                        <i class="fa fa-ban"></i></span></div>
                                                    <div><span><i class="fa fa-dollar"></i></span>
                                                        0.00<span>(0)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-3 col-sm-3 font-bold padding-0 paid-unpaid-section">
                                            <span class="text-success" *ngIf="completedSR.status === 8">
                                                <i class="fa fa-dollar"></i> Paid
                                            </span>
                                            <div class="text-error" *ngIf="completedSR.status !== 8">
                                                <span><i class="fa fa-dollar"></i></span> <span>{{completedSR.grand_total | number:'1.2-2'}}
                                            </span></div>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                            <div class="col-sm-12 padding-0 lode-section">
                                 <div class="row margin-0">
                                    <div class="col-sm-12 padding-0">
                                        <p class="text-right load-more">
                                            <button *ngIf="showMoreCompletedSR" [disabled]="!connectionActive" (click)="paginateCompletedSr()"
                                                class="btn btn-gray btn-block no-outline">Load
                                                More...</button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </mat-expansion-panel>
        </mat-accordion>

    </div>