/**
 * @trackID <:CAR-1419>, <:CAR-1496>
 * @updated 19-03-2020
 * @updatedBy Shashank Thakur
 */
import {Component,  ViewChild, ViewEncapsulation,ChangeDetectorRef, Inject, OnDestroy} from '@angular/core';
import {Router, ActivatedRoute,Params} from '@angular/router';
import {Location,  DatePipe} from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import { MatTab } from '@angular/material/tabs'
import {DataSource} from '@angular/cdk/collections';
import {URLSearchParams} from '@angular/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {globalFunctions} from "../../../../environments/globalFunctions";
import {globalConstants} from "../../../../environments/globalConstants";
import {GlobalService} from "../../../../environments/global.service";
import {Observable} from 'rxjs/Observable';
import {Title} from '@angular/platform-browser';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AppComponent} from '../../../app.component';
import {PaymentDialogComponent} from '../payment-dialog/payment-dialog.component';
import {AddMileageDialogComponent} from '../add-mileage-dialog/add-mileage-dialog.component';
import {TechassignmentComponent} from '../techassignment/techassignment.component';
import { MessageaddComponent } from '../../shopservicecomplaints/messageadd/messageadd.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import {​ ServiceHistoryComponent }​ from '../../../shop/shopservicerequest/service-history/service-history.component';
import { DetailTabComponent } from '../detail-tab/detail-tab.component';
const SERVICE_LIST='shop-service';
import {Subscription} from 'rxjs';


export class AppDateAdapter extends NativeDateAdapter {
   parse(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

   format(date: Date, displayFormat: Object): string {
       if (displayFormat == "input") {
           let day = date.getDate();
           let month = date.getMonth() + 1;
           let year = date.getFullYear();
           return this._to2digit(month)+ '/' + this._to2digit(day)  + '/' + year;
       } else {
           return date.toDateString();
       }
   }

   private _to2digit(n: number) {
       return ('00' + n).slice(-2);
   }
}

export const APP_DATE_FORMATS =
{
   parse: {
       dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
   },
   display: {
       // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
       dateInput: 'input',
       monthYearLabel: { month: 'short', year: 'numeric', day: 'numeric' },
       dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
       monthYearA11yLabel: {year: 'numeric', month: 'long'},
   }
}

@Component({
    selector: 'app-listshopservice',
    templateUrl: './listshopservice.component.html',
    styleUrls: ['./listshopservice.component.scss'],
    animations: [
        trigger('flyInOut', [
            state('in', style({
                transform: 'translateX(0)'
            })),
            transition('void => *', [
                style({transform: 'translateX(500%)'}),
                animate('0.5s ease-in')
            ])
        ])
    ],
    encapsulation: ViewEncapsulation.None,
    providers: [MatTab, DatePipe,
       {
           provide: DateAdapter, useClass: AppDateAdapter
       },
       {
           provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
       }
    ]
})
export class ListshopserviceComponent  {

    public owlOptions: any = {
        items: 4, 
        dots: true,
        loop:true,
        nav: false,
        center: true,
        // margin:10,
        animateOut: 'slideInLeft',
        animateIn: 'slideOutRight',
        // navText: [
        //   "<i class='fa fa-chevron-left fa-lg'></i>",
        //   "<i class='fa fa-chevron-right fa-lg'></i>"
        // ],
        responsive: {
          0: {
            items:1.3
          },
          480: {
            items:1.3
          },
          620: {
            items:2.1
          },
          768: {
            items:3.2
          },
          1200: {
            items:4
          }
        }
      };
    public displayedColumns:any=[];
    public defaultColumns:any = '';
    dataSource: serviceRequestGridDataSource | null;
    public currentCustomer;
    public schedule_service_option = '';
    public requested_delivery_option : any='';
    public estimated_delivery_option = '';
    public more_rows;
    public request_id:string;
    public isLegendHidden: boolean = true;
    public options = {
        user_id: "",
        user_type: "User",
        status: '',
        search_by: "",
        search_keyword: "",
        start: 0,
        limit: 10,
        schedule_service_date: "",
        requested_delivery_date: "",
        estimated_delivery_date: "",
        customer_id: "",
        employee_id:"",
        unassigned_sr:'',
        jobStatus:'',
        on_the_lot : '0',
        wait_for_part:'0',
        account_verify:'0',
        serv_notes_unread:'0',
        isCompletedIncluded:'0',
        sort_column: '',
        sort_direction: '',
        empFilterStatus:'',
        empFilterKeyword:'',
        is_export:0
    }
    public statuses = ['Appointments', 'Pending', 'Processing', 'Approval Awaited', '', 'Service Completed', 'Completed', 'Approval Completed', 'Completed And Paid', 'Unread Rating', 'Non-CAR','','Work In Progress'];
    public modulePermission:any={};
    public modulesName='service_request';
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    public gSerice=null;
    public openSearch: boolean = false;
    public skipTicketAssign=false;
    public jobFilter=[{id:"",name:"Jobs Status"},{id:"process",name:"Process"},{id:"completed",name:"Completed"}];
    public empFilter=[{id:"",name:"Employee Action"},{id:"1",name:"Assigned to"},{id:"2",name:"Created by"}];
    public fromCalender='';
    public searchByStatus = [];
    public isEmployee=false;
    public isShop=false;
    public searchByArray = [];
    public isReportGenerate=false;
    public isFleet:any=false;
    public accountVerifyDeterminate=false
    public waitForPart=false
    public onTheLot=false
    public checkValues = [false, null,true];
    public lotIndex=0
    public waitingIndex=0
    public accVerify=0
    public sTimeSlots:Array<any> = globalConstants.TIME_SLOTS;
    public eTimeSlots:Array<any> = globalConstants.TIME_SLOTS;
    public isServiceNote=false
    public additionalColumn = [
        { 'label': 'Req. Id','default':true, 'pos': 0, 'value': 'request_id' },
        { 'label': 'Title','default':true, 'pos': 1, 'value': 'title','before':'business_name','after':'request_id' },
        { 'label': 'Account','default':true, 'pos': 2, 'value': 'business_name','before':'vehicle','after':'title' },
        { 'label': 'Vehicle','default':true, 'pos': 3, 'value': 'vehicle','before':'appointment_date','after':'business_name' },
        { 'label': 'App. Date','default':true, 'pos': 4, 'value': 'appointment_date','before':'service_date','after':'vehicle' },
        { 'label': 'Schedule Date','default':true, 'pos': 5, 'value': 'service_date','before':'estimated_delivery_date','after':'appointment_date' },
        { 'label': 'Est. Delivery Date','default':true, 'pos': 6, 'value': 'estimated_delivery_date','before':'unit_number','after':'service_date' },
        { 'label': 'Unit Number','default':false, 'pos': 7, 'value': 'unit_number','before':'on_the_lot','after':'estimated_delivery_date'  },
        { 'label': 'On The Lot','default':false, 'pos': 8, 'value': 'on_the_lot','before':'license_plate','after':'unit_number'  },
        { 'label': 'License Plate','default':false, 'pos': 9, 'value': 'license_plate','before':'vin','after':'on_the_lot'  },
        { 'label': 'VIN','default':false, 'pos': 10, 'value': 'vin','before':'status','after':'license_plate'  },
    ];
    isCompletedIncluded:any;
    public sortDirections = {
        'asc':'asc',
        'desc':'desc'
    };
    gc:any = globalConstants;
    public techData:Object = {};
    public allList  = [];
    public subscriptionDataForAPI: Subscription = new Subscription();
    public techniciansList: any = [];
    public unassignedSRStatus :boolean = false;
    public dRangePickerConfig: any = {  // date range picker config
        isDisabled: false
    }; 
    constructor(
        private location: Location,
        public cdr: ChangeDetectorRef, 
        private app: AppComponent, 
        private activatedRoute: ActivatedRoute, 
        public router: Router,
        public titleService: Title, 
        public globalservice: GlobalService, 
        public dialog: MatDialog) 
        {
        this.globalservice.setMetaData("SHOPS","LIST_SERVICE_REQUEST")
        this.globalservice.ShowDateRange(this.dRangePickerConfig);
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
        this.options.user_id = this.currentCustomer.id;
        this.isShop = [globalConstants.ROLE_ID_SHOP_OWNER,globalConstants.ROLE_ID_MANAGER,globalConstants.ROLE_ID_ADVISOR,globalConstants.ROLE_ID_TECHNICIAN].includes(Number(this.currentCustomer.role_id)) ? true : false;
        this.gSerice=globalservice;
        this.cdr.markForCheck();
        // this.reinitializeOptions();
        this.isFleet=this.globalservice.isFleet();
        if(localStorage.getItem("openFilter") == "1") {
            this.openSearch = true;
        } else {
            this.openSearch = false;
        }
        /* SEARCH BY STATUS ARRAY */
        this.searchByStatus = [
            { id: "", text: "Select Status"},
            { id: "11", text: "Appointments"},
            { id: "1", text: "Pending"},
            { id: "2", text: "Processing"},
            { id: "3", text: "Approval Awaited"},
            { id: "7", text: "Approval Completed"},
            { id: "12", text: "Work In Progress"},
            { id: "6", text: "Completed"},
            { id: "8", text: "Completed And Paid"},
            { id: "9", text: "Unread Ratings"},
            { id: 'feedback', text: "Ratings"},
            { id: '-1', text: "Deleted"},
            
        ];
        /* SEARCH BY ARRAY */
        this.searchByArray = [
            { id: "7", text: this.globalservice.getBORDName()},
            { id: "6", text: (this.isFleet ? "Contact" : "Customer")+" Email"},
            { id: "4", text: (this.isFleet ? "Contact" : "Customer")+" Name"},
            { id: "11", text: "Account"},
            { id: "9", text: "License Plate"},
            { id: "1", text: "Parts"},
            { id: "3", text: "Req. Id"},
            { id: "2", text: "Title"},
            { id: "5", text: "Vehicle"},
            { id: "8", text: "Vin"},
            { id: "12", text: "Unit Number"},
            { id: "13", text: "Created By"},
            { id: "15", text: "Generic Job"}
        ];
        this.isReportGenerate=this.globalservice.isUserReportOn();
        // if(this.isFleet){
        //     this.displayedColumns = ['request_id', 'title', 'customer_name','unit_number', 'vehicle', 'appointment_date', 'service_date', 'status', 'actions'];
        // }else{
            // this.displayedColumns = ['request_id', 'title', 'business_name', 'vehicle', 'appointment_date', 'service_date', 'estimated_delivery_date', 'status', 'actions'];
        // }
        
        
        this.displayedColumns.push('status')
        this.displayedColumns.push('actions')
        if(this.options.on_the_lot=='1'){ 
            this.onTheLot=true
        }else if(this.options.on_the_lot=='2'){
            this.onTheLot=null
        }
        if(this.options.account_verify=='1'){
            this.accountVerifyDeterminate=true
        }else if(this.options.account_verify=='2'){
            this.accountVerifyDeterminate=null
        }
        if(this.options.wait_for_part=='1'){
            this.waitForPart=true
        }else if(this.options.wait_for_part=='2'){
            this.waitForPart=null
        }
    }
    
    isWorkflowIconHovered: boolean = false;

    onHover(isWorkflowIconHovered: boolean): void {
        this.isWorkflowIconHovered = isWorkflowIconHovered;
    }

    
  toggleLegends() {
    this.isLegendHidden = !this.isLegendHidden;
  }

    navigateToJobBoard(route: string): void {
        this.router.navigate([route]);
    }

    downloadSRListCSV(){
        this.options.is_export = 1;
        var content = globalFunctions.stringifyFormData(this.options, null);
        var callApi = this.globalservice.callAPI(globalConstants.API_GET_SERVICE_REQUESTS_URL, content);
        this.subscriptionDataForAPI = callApi.subscribe((data: any) => {
            var reportData = data;
            window.location.href = globalConstants.API_SHOP_DOWNLOAD_SERVICE_REQUEST_LIST + "?t=1&p=" + reportData.csv_url;
            // this.report_csv_url=reportdata.csv_url;
        });
    }
    getStatus(statusId) {
        return this.statuses[statusId] || 'Deleted'; // for record.status == -1
    }
    setTouchViewValue() {
        return this.app.touchViewState;
    }
    getParams() {
        this.activatedRoute.params.subscribe((params: Params) => {
          this.fromCalender = params["title"];
        });
      }
    paymentDialog(row:any): void {
        let accessLimit=this.globalservice.isAllowed(this.app,'payment');
        this.globalservice.setAcl(this.app.roles);
        let dialogRef = this.dialog.open(PaymentDialogComponent, {
            panelClass: ['payment-dialog'],
            data: {request_id: row.id,obj:this,accessLimit:accessLimit,fullRequestId: row.request_id}
        });

        dialogRef.afterClosed().subscribe(result => {
            if(typeof dialogRef['addAmount']!='undefined' && dialogRef['addAmount']==true) {
                this.setDataTable();
            }
        });
    }
    /* Add mileage if it is empty in case of under process and fill recomendation */
    addMileageDialog(row,distanceCovered,actionType): void {
        let dialogRef = this.dialog.open(AddMileageDialogComponent, {
            panelClass: ['payment-dialog'],
            data: {request_id:row.id, distance_covered:distanceCovered, action_type:actionType}
        });

        dialogRef.afterClosed().subscribe(result => {
            if(typeof dialogRef['actionType']!='undefined' && dialogRef['actionType']==2 ){
                this.reportIsOn(row,"/shops/service-inspection-report/" + row.id);
            }
            if(result == 1) {
                this.setDataTable();
            }
            if(typeof dialogRef['addMileage']!='undefined' && dialogRef['addMileage']==true && this.skipTicketAssign ){
                this.ticketAssignment(row.id,row);
            }
        });
    }

    showFeedbackView(rid) {
        this.gSerice.localGetterSetter(this.options,null,SERVICE_LIST);
        this.router.navigate(['shops/customer-feedback/' + rid]);
    }
    formatDate(date) {
        if (!new Date(date) || date == null || date == "0000-00-00 00:00:00") {return "-"}
        return (new Date(date).getMonth() + 1) + "/" + new Date(date).getDate() + "/" + new Date(date).getFullYear()
    }
    ngOnInit() {
        this.getTicketAssignSetting();
        this.reinitializeOptions();
        this.getTechnicianData();
        setTimeout(() => {
            this.isEmployee=this.globalservice.isSubscribe('employee')
            this.isServiceNote= this.globalservice.isSubscribe('service_notes')
            this.modulePermission = this.globalservice.isAllowed(this.app, this.modulesName);
            if (!(this.modulePermission && (this.modulePermission['enable'] == 1))) {
                this.router.navigate(['shops/action/forbidden']);
            }
            // added two new filters "assigned to" and "created by"
            if(this.isEmployee){
                this.searchByArray.push({ id: "14", text: "Assigned To"})
            }
            if(this.isServiceNote){
                this.searchByArray.push({ id: "10", text: "Service Notes"})
            }
        }, globalConstants.RELOAD_WAIT)
        this.getParams();
        if((this.fromCalender !='') && (typeof this.fromCalender != 'undefined')){
            if(this.fromCalender=='on-the-lot'){
                // this.globalservice.localGetterSetter(null,this.options,SERVICE_LIST);
                // this.reinitializeOptions();
                // this.options.on_the_lot="1";
            }else{
                this.options.search_keyword=this.fromCalender;
                this.options.search_by="3";
            }
        }
        
        this.quickOntheLot();
        this.setUnassignSR();
        if(this.isAnyOptionActive()){
            this.openSearch = true;
            if(this.options.isCompletedIncluded == '1'){
                this.isCompletedIncluded = true;
            }else{
                this.isCompletedIncluded = false;
            }
        }
        let compIncludedTemp = this.globalservice.getSetItems("isCompletedIncluded");
        this.options.isCompletedIncluded = compIncludedTemp == '1' ? '1' : '0';
        if(this.options.isCompletedIncluded == '1'){
            this.isCompletedIncluded = true;
        }else{
            this.isCompletedIncluded = false;
        }
        this.cdr.detectChanges();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.setDataTable();
            this.getUserDetails();
          },0)
      }

    isAllowed(action,module?:any){
        if(typeof module!='undefined'){
            return this.globalservice.isAllowed(this.app,module,action);
        }
        return this.globalservice.isAllowed(this.app,this.modulesName,action);
    }

    dateFormatWithoutTime(dateValue) {
       return ((new Date(dateValue).getMonth()+1) < 10 ? '0' : '')+(new Date(dateValue).getMonth()+1) + "/" + (new Date(dateValue).getDate() < 10 ? '0' : '')  + new Date(dateValue).getDate() + "/" + new Date(dateValue).getFullYear();
    }
   /**
     * @updatedBy Vikas Kumar
     * @trackID <:CAR-1533>
     * @updated 28-04-2020
     */
    public updateOnTheLotStatus(value: any,filter?:any) {
        this.lotIndex+=1;        
        this.onTheLot=this.checkValues[this.lotIndex % this.checkValues.length];
        if(filter==1){
            this.search(value)
        }
    }

    onDateChange(event: any) {
        if (event == null) {
          this.requested_delivery_option = null;
        } else {
          var stringDateFormat = [
            event.start.toISOString(),
            event.end.toISOString()
          ]
          this.requested_delivery_option = stringDateFormat;
        }
    }

    /**
     * @updatedBy Shashank Thakur
     * @trackID <:CAR-1491>, <:CAR-1512>
     * @updated 20-03-2020, 27-03-2020
     * @see Function updated to remove and add actions column as per deleted status
     * @see Function updated to change and show note related to completed service requests.
     */
    search(event) {
        if(this.options.search_by){
            this.options.customer_id=''
           localStorage.setItem("shop-servicecustomer_id", '');
        }
        if (this.schedule_service_option != "" && this.schedule_service_option != null) {
                //let schedule_date = new Date(this.schedule_service_option).getMonth() + 1 + "/" + new Date(this.schedule_service_option).getDate() + "/" + new Date(this.schedule_service_option).getFullYear();
            let schedule_date = this.dateFormatWithoutTime(this.schedule_service_option);
            this.options.schedule_service_date = schedule_date;
        } else {
            this.options.schedule_service_date = "";
        }
        if (this.requested_delivery_option != "" && this.requested_delivery_option != null) {
            //let request_delivary = (new Date(this.requested_delivery_option).getMonth() + 1) + "/" + new Date(this.requested_delivery_option).getDate() + "/" + new Date(this.requested_delivery_option).getFullYear();
            let request_delivary = this.requested_delivery_option;
            this.options.requested_delivery_date = request_delivary;
        } else {
            this.options.requested_delivery_date = "";
        }
        if (this.estimated_delivery_option != "" && this.estimated_delivery_option != null) {
            //let estimated_delivary = (new Date(this.estimated_delivery_option).getMonth() + 1) + "/" + new Date(this.estimated_delivery_option).getDate() + "/" + new Date(this.estimated_delivery_option).getFullYear();
            let estimated_delivary = this.dateFormatWithoutTime(this.estimated_delivery_option);
            this.options.estimated_delivery_date = estimated_delivary;
        } else {
            this.options.estimated_delivery_date = "";
        }
        // Code to hide or add actions column to the columns to be shown on the basis of deleted status
        if(this.options.status == '-1'){
            if(this.displayedColumns.indexOf('actions') !== -1){
                this.displayedColumns.pop(this.displayedColumns.indexOf('actions'));
            }
        }
        else{
            if(this.displayedColumns.indexOf('actions') == -1){
                this.displayedColumns.push('actions');
            }
        }
        // Code to change note text related to inclusion and exclusion of completed service requests in search results
        // if(this.options.status == '6' || this.options.status == '8'){
        //     this.isCompletedIncluded = true;
        // }
        // else{
        //     this.isCompletedIncluded = false;
        // }
        this.options.start = 0;
        this.options.is_export= 0;
        if(this.requested_delivery_option !== null){
            this.setDataTable();
         } else{
            this.globalservice.snackbar('error',"Please select or enter a valid date range");
         }
        // this.setDataTable();
    }
    
    /**
     * @updatedBy Shashank Thakur
     * @trackID <:CAR-1491>, <:CAR-1512>
     * @updated 20-03-2020, 27-03-2020
     * @see Function updated to remove and add actions column as per deleted status
     * @see Function to set the note text according to the inclusion of completed service requests 
     * in list.
     */
    clearSearch() {
        if(this.displayedColumns.indexOf('actions') == -1){
            this.displayedColumns.push('actions');
        }
        this.gSerice.localGetterSetter(null,this.options,SERVICE_LIST);
        this.openSearch = false;
        this.schedule_service_option="";
        this.requested_delivery_option="";
        this.estimated_delivery_option="";
        this.dRangePickerConfig.isDisabled = false;
        this.globalservice.ShowDateRange(this.dRangePickerConfig);
        localStorage.setItem("shop-servicecustomer_id", '');
        localStorage.setItem("openFilter",'');
        localStorage.setItem(SERVICE_LIST+"on_the_lot",'');
        localStorage.setItem(SERVICE_LIST+"unassigned_sr",'');
        this.accountVerifyDeterminate=false;
        this.waitForPart=false
        this.onTheLot=false
        this.unassignedSRStatus = false;
        this.accVerify=0; 
        this.waitingIndex=0;
        this.lotIndex=0;
        this.options = {
            user_id: this.currentCustomer.id,
            user_type: this.currentCustomer.user_type,
            status: '',
            search_by: "",
            search_keyword: "",
            start: 0,
            limit: 10,
            schedule_service_date: "",
            requested_delivery_date: "",
            estimated_delivery_date: "",
            customer_id: "",
            employee_id:'',
            unassigned_sr:'',
            jobStatus:'',
            on_the_lot : '0',
            wait_for_part:'0',
            account_verify:'0',
            serv_notes_unread:'0',
            isCompletedIncluded:(this.isCompletedIncluded ? '1' : '0'),
            sort_column: '',
            sort_direction: '',
            empFilterStatus:'',
            empFilterKeyword:'',
            is_export:0
        }
        
           
        
        // this.isCompletedIncluded = false;
        this.clearSort();
        this.setDataTable();
    }

    viewPart(getId) {
        this.gSerice.localGetterSetter(this.options,null,SERVICE_LIST);
        this.router.navigate(['/customer/view-request/', getId]);
    }

    scrolling() {
        this.globalservice.setScrolling('.car-datatable', 1000, -20);
    }

    viewCarHistory(customer_id,vehicle_id) {
        localStorage.setItem("carDetailsCustomerID", customer_id);
        localStorage.setItem("carDetailsVehicleID", vehicle_id);
        this.gSerice.localGetterSetter(this.options,null,SERVICE_LIST);
        this.router.navigate(['shops/service-history']);
    }

    paginateByLimit(event){
        this.globalservice.setScrolling('body', 600, 0);
        this.options.limit = event.pageSize;
        this.options.start = 0;
        this.scrolling();
        this.setDataTable();
    }

    reinitializeOptions() {
        this.gSerice.localGetterSetter(this.options,this.options,SERVICE_LIST);
        this.options.user_id= this.currentCustomer.id;
        this.options.user_type= "User";
        this.options.on_the_lot = (localStorage.getItem(SERVICE_LIST+"on_the_lot") ? localStorage.getItem(SERVICE_LIST+"on_the_lot") : '0');
    }

    getTotalRecords() {
        let t=parseInt(localStorage.getItem(SERVICE_LIST+'totalRecords'));
        return (isNaN(t) ? 0 : t );
    }

    paginate(event,type){
        this.scrolling();
        if(type=="next") {
          this.options.start = this.options.start + this.options.limit;

        } else {
          this.options.start = this.options.start - this.options.limit;

        }
        this.setDataTable();
    }

    firstList() {
        return this.globalservice.firstList(SERVICE_LIST,this.options);
    }
    lastList() {
        return this.globalservice.lastList(SERVICE_LIST);
    }
    getUpperLimit(currentLastLimit, total) {
        if (currentLastLimit > total) {
            return total
        } else {
            return currentLastLimit
        }
    }

    viewServiceRequest(id,row?:any) {
        this.reportIsOn(row,"/shops/view-service-request/" + id);
    }
    
    fillRecommendation(id, present_mileage, distance_covered,row?:any) {
        this.gSerice.localGetterSetter(this.options,null,SERVICE_LIST);
        if((typeof(present_mileage) == 'undefined' || present_mileage == "") && row.is_walkin_customer_vehicle == '0'){
            this.addMileageDialog(row,distance_covered,'2');
        } else {
            this.reportIsOn(row,"/shops/service-inspection-report/" + id);
        }
    }
    
    openServiceHistoryPopup(serviceInfo){​
        let info:any = {
          id:'service-history-'+serviceInfo.car_id,
          name: 'Service History View',
          module: 'Service Request'
        }
        let data:any = {​ isPopup: true, carId:serviceInfo.car_id, serviceInfo:serviceInfo, id: 'service-history-'+serviceInfo.car_id };
        this.gSerice.openMinimizablePopup(ServiceHistoryComponent, info, data);
    }​

    /***Check service request*****/
    isAnyOptionActive(button?:any) {
        if(this.schedule_service_option != '' ||  this.requested_delivery_option != '' || this.estimated_delivery_option !=''){
            return true;
        }
        if(this.onTheLot || this.onTheLot===null || this.waitForPart || this.waitForPart==null || this.accountVerifyDeterminate || this.accountVerifyDeterminate==null || this.unassignedSRStatus){
            return true
        }
        if (this.options.search_by=="" && this.options.jobStatus=="" && this.options.search_keyword == "" && this.options.status == "" && this.options.estimated_delivery_date == "" && this.options.requested_delivery_date == "" && this.options.schedule_service_date == "" && this.options.employee_id == '') {
            if (button) {button.color = "primary"}
            return false;
        }
        else {
            if (button) {button.color = "accent"}
            return true;
        }
    }
    editRequest(rid,cusid) {
        this.gSerice.localGetterSetter(this.options,null,SERVICE_LIST);
        this.router.navigateByUrl('shops/edit-service-request/'+ rid +"/"+cusid);
    }

    underProcess(id, present_mileage, distance_covered,row?:any){
        if(present_mileage == undefined || present_mileage == ""){
            this.addMileageDialog(row,distance_covered,'1');
        } else {
            let content='id='+id+"&user_id="+this.currentCustomer.id+"&user_type="+this.currentCustomer.user_type;
            this.gSerice.callAPI(globalConstants.API_UPDATE_SERVICE_REQUEST_STATUS_URL, content).subscribe((response) => {
                if (response.result == "1") {
                    this.gSerice.snackbar("success", response.message);
                    if(this.skipTicketAssign ){
                      this.ticketAssignment(id,);
                    }
                    this.search(null);
                } else {
                    this.gSerice.snackbar("error", response.message);
                }
            });
        }
    }

    setDataTable(){
        this.options.on_the_lot = (this.onTheLot==false ? '0' :(this.onTheLot==null ? '2' :'1'))
        this.options.wait_for_part=(this.waitForPart==false ? '0' :(this.waitForPart==null ? '2' :'1'))
        this.options.account_verify=(this.accountVerifyDeterminate==false ? '0' :(this.accountVerifyDeterminate==null ? '2' :'1'))

        //check for sort order
        if(localStorage.getItem(SERVICE_LIST+"sort_column") != ''){
            const sortDirection = localStorage.getItem(SERVICE_LIST+"sort_direction");
            if(sortDirection != ''){
                this.sort.active = localStorage.getItem(SERVICE_LIST+"sort_column");
                this.sort.direction = this.sortDirections.hasOwnProperty(sortDirection) ? this.sortDirections[sortDirection] : '';
            }else {
                this.clearSort();
            }
        }
        //check for sort order
        if(this.options.status == '6'){
            this.isCompletedIncluded = true;
            this.options.isCompletedIncluded = '1';
        }
        this.dataSource = new serviceRequestGridDataSource(new serviceRequestGridDatabase(this.options,this.globalservice,this.statuses),this.sort, this.paginator);
    }
    generateInvoice(rid, total, taxRate, isTaxIncluded,requestID) {
        //var conf = "Are you sure you want to mark this request as complete and generate its invoice?";
        var conf = "Are you sure you want to print invoice?";
        this.globalservice.confirmDialog('GenerateInvoice', conf).subscribe((r) => {
            let res:any=r;
        if(res=='GenerateInvoice'){
            var url=globalConstants.API_GENERATE_PAY_INVOICE_URL+'?';
            var url=globalConstants.API_GENERATE_INVOICE_URL;
            let userId=this.globalservice.getCurrentUser()["id"];
            let params = new URLSearchParams();
            params.set('service_request_id', rid);
            params.set('user_type', "User");
            params.set('invoke_download', '1');
            params.set('user_id', userId);
            params.set('is_tax_included', isTaxIncluded);
            params.set('tax_rate', taxRate);
            params.set('total', total);            
            url += '?'+params.toString();
            let FileName = requestID+'.pdf';
            this.globalservice.downloadAsset(url,FileName);          
          } else {
            return false;
          }
        });

    }
    markAsPaid(r_id) {
        let data = { user_id: this.globalservice.getCurrentUser()['id'], request_id: r_id };
        let params = globalFunctions.stringifyFormData(data, this.globalservice.getCurrentUser()["user_type"]);
        this.globalservice.confirmDialog('MarkAsPaid', 'Are you sure you want to mark this request as paid?').subscribe((r) => {
            let res:any=r;
          if(res=='MarkAsPaid'){
            this.globalservice.callAPI(globalConstants.API_UPDATE_SERVICE_REQUEST_STATUS_AS_PAID_URL, params).subscribe((response:any) => {
                if (response.result == 1) {
                    this.globalservice.snackbar("success", response.message);
                    this.setDataTable();
                } else {
                    this.globalservice.snackbar("error", response.message);
                }
            });
         }
        });
    }
    /* STATUS COLOR */
    getRowStatusColor(status) {
        if(status != -1){
            var statusArr = ['active-cell', 'warning-cell', 'info-cell', 'awaited-cell', 'customer-appt', 'success-cell', 'success-completed', 'success-cell', 'success-paid', "", "", "", 'work-progress-cell'];
            return statusArr[status];
        }
        else{
            return 'deleted-cell';
        }
    }

    detailTab(row:any,tabIndex) {
        let dialogRef = this.dialog.open(DetailTabComponent, {
          panelClass: 'car-dialog-form',
          width: '800px',
          data: { requestId: row.id, customer_id: row.customer_id, tab: tabIndex ,car_id : row.car_id }
        });
    }
    
    isVisible(row){
        if(row.check_action_status == 6 || row.check_action_status == 8){
            return false;
        }
        return ( this.currentCustomer.role_id != 4 ? true : false );
    }
    getTicketAssignSetting(){
        let data="user_id="+this.currentCustomer.id+"&user_type="+this.currentCustomer.user_type;
        this.globalservice.callAPI(globalConstants.API_SKIP_FOREVER,data).subscribe((skipTicket:any)=>{
            if(skipTicket.result=="1"){
                if(typeof skipTicket.data.skip_forever !='undefined' && skipTicket.data.skip_forever=='1'){
                    this.skipTicketAssign=false;
                }else{
                    this.skipTicketAssign=true;
                }
            }else{
                this.skipTicketAssign=true;
            }
            
        })
    }
    ticketAssignment(id,SRDetail:Object ={}){
        if(this.isEmployee==false){
            return false;
        }
        this.techData= {request_id: id,data:SRDetail};
    }
    techAssignmentOut($event:any){
        if($event==true){
            setTimeout(() => this.getTicketAssignSetting(),1000);
        }
        this.techData = {};
    }
    isViewAssignee(row){
        return ( this.currentCustomer.role_id != 4 ? true : false );
    }
    viewAssignee(id){
        if(this.isEmployee==false){
            return false;
        }
        let dialogRef = this.dialog.open(TechassignmentComponent, {
            panelClass: ['car-dialog-form'],
            width: "900px",
            data: {request_id:id,techStatus:true}
        });
    }

    
    viewLogs(id){
        this.router.navigate(['shops/service-request/logs',id]);
    }
    calenderView(){
        this.router.navigate(['shops/calendar']);
    }
    newCalenderView(){
        this.router.navigate(['shops/service-calendar']);
    }
    quickOntheLot() {
        let v = this.globalservice.getSetItems('on-the-lot');
        if (v == '1') {
            this.globalservice.localGetterSetter(null, this.options, SERVICE_LIST);
            this.reinitializeOptions();
            this.isCompletedIncluded = this.globalservice.getSetItems("isCompletedIncluded");
            this.options.isCompletedIncluded = this.isCompletedIncluded == '1' ? '1' : '0';
            this.options.on_the_lot = "1";
            this.onTheLot=true
            this.globalservice.getSetItems('on-the-lot', '0');
        }
    }

    setUnassignSR(){
        let checkboxValue = this.globalservice.getSetItems('unassigned_sr');
        if (checkboxValue == '1') {
            this.globalservice.localGetterSetter(null, this.options, SERVICE_LIST);
            this.reinitializeOptions();
            this.options.unassigned_sr = "1";
            this.unassignedSRStatus=true;
            this.globalservice.getSetItems('unassigned_sr', '0');
        }
    }
    autoAssign(rId, url) {
        let data = { assign_totech: 1, emp_id: this.currentCustomer.id, request_id: rId, user_id: this.currentCustomer.id, user_type: this.currentCustomer.user_type };
        this.globalservice.callAPI(globalConstants.API_ASSIGN_SERVICE_REQUEST, data).subscribe((tech:any) => {
            if (tech.result == 1) {
                this.redirectUrl(url);
            } else if(tech.code=='400'){
                this.redirectUrl(url);
            }
        }, (error) => { })
    }
    redirectUrl(url) {
        this.gSerice.localGetterSetter(this.options, null, SERVICE_LIST);
        this.router.navigate([url]);
    }
    /**  is_assign==0 && self_assign==1 then popup to self assign
         is_assign ==0 && self assign ==0 auto assign and redirect to view or fill
         if is assign ==1 then redirect to view or fill or request is completed 
         */
    reportIsOn(row, url) {
        if(row.check_action_status==6 ||row.check_action_status==8){
            this.redirectUrl(url);
         }else if (!this.isReportGenerate) {
            this.redirectUrl(url);
        } else if (typeof row.is_assign != 'undefined' && row.is_assign == 1) {
            this.redirectUrl(url);
        } else if (typeof row.is_assign != 'undefined' && row.is_assign == 0 && row.is_assign == 0) {
            this.redirectUrl(url);
            ///this.autoAssign(row.id, url);
        }
    }
    menuEnable(row){
        /*if (!this.isReportGenerate) {
           return true; 
        }else if(this.isReportGenerate && row.is_assign==1){
           return true; 
        }else{
           return false; 
        }*/
        return true;
    }
    waitingForPart(value: any) {
        this.waitingIndex+=1;        
        this.waitForPart=this.checkValues[this.waitingIndex % this.checkValues.length];
    }
    accountVerify() {
        this.accVerify+=1;        
        this.accountVerifyDeterminate=this.checkValues[this.accVerify % this.checkValues.length];   
    }
    /** open service request notes list */
    srnoteList(row) {
       this.globalservice.srnoteListId(row.id);        
    }
    serviceNoteUnreadStatus(event) {
        let checked = '0';
        if (event.checked === true) {
            checked = '1';
        }
        this.options.serv_notes_unread=checked
    }
    ngOnDestroy(){
        this.globalservice.srnoteListId(false);
    }
    addMessage(row){
        let param={request_id:row.id}
        let dialogRef = this.dialog.open(MessageaddComponent, {
            panelClass: ['car-dialog-form'],
            data: param
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }
    
    /**
     * @author Shashank Thakur
     * @trackID <:CAR-1492>, <:CAR-1420>
     * @created 19-03-2020
     * @see Function to reopen a service request.
     */
    reOpen(row:any){
        let continueReOpen = () => {
            let data:any = {
                user_id:this.currentCustomer.id,
                user_type:this.currentCustomer.user_type,
                request_id:row.id
            };
            this.globalservice.callAPI(globalConstants.API_MASTER_REVERT_SERVICE_TICKET_URL, data)
            .subscribe((data:any) => {
            var message = data.message;
            if (data.result == "1") {
              this.globalservice.snackbar('success', message);
              this.setDataTable();
            } else {
              this.globalservice.snackbar("error", message);
            }
            }, (error) => { });
        }
        let conf:string = "Are you sure you want to re-open this service request?";
        this.globalservice.confirmDialog('ConfirmReopen', conf).subscribe((r) => {
            let res:any=r;
            if(res=='ConfirmReopen'){
                // execute delete code
                continueReOpen();
            } else {
                return false;
            }
        });
    }
    
    /**
     * @author Shashank Thakur
     * @trackID <:CAR-1492>, <:CAR-1420>
     * @created 20-03-2020
     * @see Function to soft delete a service request.
     */
    softDelete(row:any){
        let continueDelete = () => {
            let data:any = {
                user_id:this.currentCustomer.id,
                user_type:this.currentCustomer.user_type,
                request_id:row.id
            };
            this.globalservice.callAPI(globalConstants.API_MASTER_DELETE_SERVICE_TICKET, data)
            .subscribe((data:any) => {
            var message = data.message;
            if (data.result == "1") {
              this.globalservice.snackbar('success', message);
              this.setDataTable();
            } else {
              this.globalservice.snackbar("error", message);
            }
            }, (error) => { });
        }
        
        let dialogRef = this.dialog.open(UserAuthConfirm, {
            panelClass: 'car-dialog-form',
            width: "800px"
        });
        dialogRef.afterClosed().subscribe(
            (result) => { 
                if(result && result.passwordOK){
                    continueDelete();
                }
            },
            (err)=>{ }
        );
    }

   /**
     * @trackID <:CAR-1388> Edit Customer
     * @created 2020-02-17
     * @createdBY Vikas Kumar
     * @param event, column data 
     * @return
     */
    addColumn(event, type) {
        if (event.checked === true && type.value) {
            let pre = this.displayedColumns.indexOf(type.before)
            let after = this.displayedColumns.indexOf(type.after)
            if (after != -1) {
                this.displayedColumns.splice(after + 1, 0, type.value);
            } else if (pre != -1) {
                this.displayedColumns.splice(pre, 0, type.value);
            } else {
                this.findPosition(type)
            }
        } else if (type && type.value) {
            let ind = this.displayedColumns.indexOf(type.value)
            if (ind != -1) {
                this.displayedColumns.splice(ind, 1);
            }
        }
    }
 /**
   * @trackID <:CAR-1388> Edit Customer
   * @created 2020-02-17
   * @createdBY Vikas Kumar
   * @param column data 
   * @return
   */
    findPosition(type) {
        let cc = this.additionalColumn;
        let pos = type.pos
        let f = 0
        let added: any = true
        let found = 0
        cc.forEach((e, i) => {
            if (type.value == e.value) {
                if ((i - 2) >= 0 && typeof cc[i - 1] != 'undefined') {
                    f = (i - 1)
                    added = this.preNext(cc[i - 1], type);
                    if (added) {
                        found = 1
                    }
                }
            }
        })
        if (added === false || found == 0) {
            for (var i = f; i >= 0; i--) {
                added = this.preNext(cc[i], type);
                if (added === true) {
                    found = 1
                    break;
                }
            }
        }
        if (added === false || found == 0) {
            this.displayedColumns.splice(0, 0, type.value);
        }
    }
  /**
    * @trackID <:CAR-1388> Edit Customer
    * @created 2020-02-17
    * @createdBY Vikas Kumar
    * @param column data, column data
    * @return boolean
    */
    preNext(type, c) {
        let pre = this.displayedColumns.indexOf(type.before);
        let after = this.displayedColumns.indexOf(type.after);
        if (after != -1) {
            this.displayedColumns.splice(after + 1, 0, c.value);
            return true
        } else if (pre != -1) {
            this.displayedColumns.splice(pre, 0, c.value);
            return true
        } else if (type.pos == 0) {
            let ind = this.displayedColumns.indexOf(type.value)
            if (ind == 0) {
                this.displayedColumns.splice(1, 0, c.value);
                return true
            }
        }
        return false
    }
    /**
     * @author Shashank Thakur
     * @trackID <:CAR-1512>
     * @created 27-03-2020
     * @see Function to toggle the list of service requests between completed included and excluded.
     */
    toggleCompletedRequests(ev:any){
        this.isCompletedIncluded = !this.isCompletedIncluded;
        if(this.isCompletedIncluded){
            // code to refresh list with completed requests
            this.options.isCompletedIncluded = '1';
            this.search(ev);
        }
        else{
            // code to refresh list without completed requests
            this.options.isCompletedIncluded = '0';
            this.search(ev);
        }
        localStorage.setItem("isCompletedIncluded",JSON.stringify(this.isCompletedIncluded));
    }
    /**
   * @author Vikas Kumar
   * @trackID <:CAR-1388>
   * @created 20-04-2020
   */
    customCol(evt){
        evt.stopPropagation()
    }
 /**
   * @author Vikas Kumar custom Column
   * @trackID <:CAR-1388>
   * @created 21-04-2020
   */
    getUserDetails(){
        var shopContent = "user_id=" + this.globalservice.getCurrentUser()["id"] + "&user_type=" + this.globalservice.getCurrentUser()["user_type"];
        this.globalservice.callAPI(globalConstants.API_GET_USER_DETAIL_URL, shopContent).subscribe((data:any) => {
            if(data.result=="1"){
                let col=data.data.service_list_items;
                if(col && col.length>0){
                    this.defaultColumns = col;
                    let custCol=col.split(',')
                    this.displayedColumns = custCol
                    for(var i in this.additionalColumn){
                        this.additionalColumn[i].default=false
                    }
                    for(var i in custCol){
                        for(var j in this.additionalColumn){
                            if(this.additionalColumn[j].value==custCol[i]){
                                this.additionalColumn[j].default=true
                            }
                        }
                    }
                }else{
                    this.defaultCol()
                }
                this.addFixCol()
            }
        },(error)=>{})
    }
 /**
   * @author Vikas Kumar custom Column
   * @trackID <:CAR-1388>
   * @created 21-04-2020
   */
    defaultCol(){
        for(var i in this.additionalColumn){
            if(this.additionalColumn[i].default){
                this.displayedColumns.push(this.additionalColumn[i].value)
            }
        }
        this.addFixCol()
    }
 /**
   * @author Vikas Kumar custom Column
   * @trackID <:CAR-1388>
   * @created 21-04-2020
   */
    addFixCol() {
        let ind = this.displayedColumns.indexOf('status')
        if (ind != -1) {
            this.displayedColumns.splice(ind, 1);
        }
        let ind1 = this.displayedColumns.indexOf('action')
        if (ind != -1) {
            this.displayedColumns.splice(ind, 1);
        }
        this.displayedColumns.push('status')
        this.displayedColumns.push('actions')
    }
   /**
   * @author Vikas Kumar
   * @trackID <:CAR-1533>
   * @created 28-04-2020
   */
  public setOnTheLotStatus(value,obj) {
    let checked : number = 0;
    if(value.checked === true) {
      checked = 1;
    }
    let self = this;
    let data="request_id="+obj.id+"&user_id="+this.globalservice.getCurrentUser()["id"]+"&on_the_lot="+checked+ "&user_type=User"
    this.globalservice.callAPI(globalConstants.API_UPDATE_ON_THE_LOT_STATUS,data).subscribe((res:any)=>{
        if(res.result=="1"){
            this.globalservice.snackbar("success", res.message);
        }else{
            this.globalservice.snackbar("error", res.message);
        }
    },(error)=>{ })
  }

  /**
   * @author Nitin Raj
   * @trackID <:CAR-1548>
   * @created 04-05-2020
   */
  public customColumnsMenuIsClosed(){
    let listColumns = this.displayedColumns.slice(0, this.displayedColumns.length-2).join(',');
    if(this.defaultColumns != listColumns){
        let data="user_id="+this.globalservice.getCurrentUser()["id"]+"&service_list_items="+listColumns+ "&user_type=User"
        this.globalservice.callAPI(globalConstants.API_SAVE_CUSTOM_COLUMNS,data).subscribe((res:any)=>{ 
            if(res.result == "1"){
                this.defaultColumns = listColumns;
            }
        },(error)=>{ })
    }
  }

  /**
   * @author Nitin Raj
   * @created 04-05-2020
   * @see check for change in sort params and save state
   */
  public changeSortParams(sorter){
    this.options.sort_column = sorter.active;
    this.options.sort_direction = sorter.direction;
    this.gSerice.localGetterSetter(this.options,null,SERVICE_LIST);
  }

  /**
   * @author Nitin Raj
   * @created 04-05-2020
   * @see clear existing sort state
   */
  clearSort() {
    this.sort.sort({id: '', start: 'asc', disableClear: false});
  }

  pauseEmpTimer(requestId){
    let data = 'user_id=' + this.currentCustomer.id + '&user_type=' + this.currentCustomer.user_type + '&request_id=' + requestId + '&tracker=' + btoa('2');
    this.globalservice.callAPI(globalConstants.API_TRACK_SERVICE_REQUEST,data).subscribe((track:any)=>{
      if(track.result==1){
        this.globalservice.snackbar("success", track.message);
        this.setDataTable();
      }else{
        this.globalservice.snackbar("error", track.message);
      }
    })
  }

  handleLegends(option){
        this.options.status = option;
        this.options.start = 0;
        this.openSearch = true;
        this.setDataTable();
  }

  getTechnicianData() {
    let option = {
      user_id: this.currentCustomer.id,
      user_type: 'User'
    };
    this.globalservice.callAPI(globalConstants.API_GET_TECHNICIAN, option).subscribe((res:any) => {
      if (res.result == 1) {
        var tempTechnicianData =[{
          id: '', first_name: 'Select ', middle_name: '', last_name: 'Employee', role_name: ''
        }];
        this.techniciansList = tempTechnicianData.concat(res.allemployes);
      }
    })
  }

  handleTechnician(empId){
       this.options.employee_id = empId;
  }

  handleUnassignedSRStatus(event){
    if (event.checked === true) {
        this.unassignedSRStatus = true;
        this.options.unassigned_sr='1';
    }else{
        this.unassignedSRStatus = false;
        this.options.unassigned_sr='0';
    }
  }
  
}

/**
 * @author Shashank Thakur
 * @trackID <:CAR-1491>
 * @created 20-03-2020
 * @see Component to show authentication popup on deleting a service request.
 */
@Component({
    template: `<div mat-dialog-title class="relative">{{popUpTitle}}<button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
    <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button></div><mat-dialog-content><div class="center-block" style="max-width: 600px;">
    <form [formGroup]="newPassword" class="form-horizontal form-control-normal" id="auth_form" (ngSubmit)="authorize()"><p [innerHTML]="note"></p>
    <div class="display-full margin-bottom-5">
    <label class="col-sm-3 control-label"><strong>Password</strong></label>
    <div class="col-sm-9"><mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
    <input matInput type="password" placeholder=" Password" formControlName="password" class="form-control input-lg custom-form-input btn-block" required/></mat-form-field>    </div>  </div>
    </form></div></mat-dialog-content>
    <div class="row"><div class="col-sm-12 text-right margin-bottom-5 flexBtns">
    <button mat-raised-button type="submit" form="auth_form" class="font-bold margin-right-10" color="accent">Submit</button>
    <button mat-raised-button mat-dialog-close class="font-bold">Close</button></div></div>`
})
export class UserAuthConfirm {
    public popUpTitle='USER AUTHENTICATION';
    public note='Please enter your login password for authentication to <b>delete the service request.</b>';
    newPassword:FormGroup;
    public subscriptionDataForAPI: Subscription = new Subscription();

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<UserAuthConfirm>, private formBuilder: FormBuilder, private gs: GlobalService) {
      this.newPassword = this.formBuilder.group({
        password:[''],
        user_type: this.gs.getCurrentUser()["user_type"],
        user_id: this.gs.getCurrentUser()["id"],
        dataType:'authorize'
      });
      this.gs.diaLogObj(this.dialogRef);
    }
    authorize(){
        let formdata = this.newPassword.value;
        formdata.password = formdata.password.replaceAll('+','%2B');
        let stringifiedFormData = globalFunctions.stringifyFormData(formdata, null);
        this.subscriptionDataForAPI =  this.gs.callAPI(globalConstants.API_PASSWORD_AUTHENTICATION_URL,stringifiedFormData).subscribe((res:any)=>{
            let message:string = res.message;
            if(res.result=='1'){
                this.dialogRef.close({passwordOK: true});
            }else{
                this.gs.snackbar("error", message);
            }
        },
        (err)=>{
        });
    }

  
   
    
  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
}  
}

export interface UserData {
    vehicle: string;
    customer_name: string;
    estimated_delivery_date: string;
    requested_delivery_date:string,
    appointment_date: string;
    service_date: string;
    request_id: string;
    status: any;
    check_action_status:any;
    title: string;
    id: any,
    customer_id:string,
    feedback_read_status: string,
    grand_total:any,
    is_tax_included:any,
    tax_rate:any,
    read_status:any,
    present_mileage:any,
    distance_covered:any,
    appt:any,
    is_assign:any,
    self_assign:any,
    unit_number:any,
    business_name:any,
    license_plate:string,
    vin:string,
    work_in_progress:string,
    seconds_worked:any,
    emp_work_timers:any,
    on_the_lot:any,
    selfCheckInStatus: any,
}

/** An example database that the data source uses to retrieve data for the table. */
export class serviceRequestGridDatabase {
    public total = 100;
    public options = {
        user_id: "",
        user_type: "User",
        status: "",
        search_keyword: "",
        start: 0,
        limit: 10,
        schedule_service_date: "",
        requested_delivery_date: "",
        estimated_delivery_date: "",
        feedback_read_status: "",
        on_the_lot : '0',
    }

    dataChange: BehaviorSubject<UserData[]> = new BehaviorSubject<UserData[]>([]);
    public recordsTotal = "";
    get data(): UserData[] {return this.dataChange.value;}
    getTotalRecords() {return this.recordsTotal}
    private user=this.globalService.getCurrentUser();
    constructor(options,private globalService:GlobalService,public statuses) {
        this.options = options;

        var content = globalFunctions.stringifyFormData(this.options, null)
        var callApi = this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUESTS_URL, content);
        callApi.subscribe((data:any) => {
            if (data.data != undefined) {
                let moreRows='false';
                let firstList='false';
                this.globalService.localGetterSetter("totalRecords",data.recordsTotal,SERVICE_LIST);
                if(data.more_rows == "true") {
                    moreRows="true";
                }
                this.globalService.localGetterSetter("more_rows",moreRows,SERVICE_LIST);
                if (data.start >= 0 && data.start <= 5) {
                    firstList="true";
                }
                this.globalService.localGetterSetter("first_list",firstList,SERVICE_LIST);

                this.recordsTotal = data.data.length;

                if (data.data.length == undefined) {
                    this.AddRecord(data.data)
                } else {
                    for (var i = 0; i < data.data.length; i++) {
                        this.AddRecord(data.data[i])
                    }
                }
                this.globalService.localGetterSetter(null,this.options,SERVICE_LIST);
            } else {
                this.globalService.localGetterSetter("totalRecords",'0',SERVICE_LIST);
            }
        },(error)=>{})
    }
    AddRecord(record) {
        const copiedData = this.data.slice();
        copiedData.push(this.createRecord(record));
        this.dataChange.next(copiedData);
    }
    private createRecord(record) {
        let DateFormat=this.globalService.DateFormat();
        let role=this.user.role_id;
        return {
            vehicle: this.globalService.getGlobalVehicleName(record)||"-",
            customer_name: record.first_name + " " + record.last_name,
            estimated_delivery_date: this.globalService.getFullFormateDate(record.estimated_delivery_date,DateFormat,'H'),
            service_date: this.globalService.getFullFormateDate(record.schedule_service_date,DateFormat,'H'),
            appointment_date: record.add_date,
            request_id: record.request_id,
            status:this.statuses[record.status] || 'Deleted', // for record.status == -1
            check_action_status:record.status,
            title: record.title,
            //      car: (record.status == '9' || record.status == '10') ? 'No' : 'Yes',
            id: record.id,
            requested_delivery_date:this.globalService.getFullFormateDate(record.requested_delivery_date,DateFormat,'H'),
            customer_id: record.customer_id,
            car_id: record.car_id,
            feedback_read_status: record.feedback_read_status,
            grand_total:record.grand_total,
            is_tax_included:record.is_tax_included,
            tax_rate:record.tax_rate,
            read_status: record.read_status,
            present_mileage: record.present_mileage,
            distance_covered: record.distance_covered,
            appt : record.is_appointment,
            on_the_lot : record.on_the_lot,
            is_assign: (role==1 ? 1 : record.is_assign),
            self_assign:(role==1 ? 1 : record.self_assign),
            unit_number:(record.unit_number ? record.unit_number : '-'),
            business_name:record.business_name, 
            license_plate:record.license_plate,
            vin:record.vin,
            make:record.make,
            model:record.model,
            year:record.year,
            work_in_progress:record.work_in_progress,
            seconds_worked:record.seconds_worked,
            emp_work_timers:record.emp_under_work_timers,
            selfCheckInStatus: record.self_checkin_status
        };
    }
}


export class serviceRequestGridDataSource extends DataSource<any> {

    constructor(private _exampleDatabase: serviceRequestGridDatabase, private _sort: MatSort, public  _paginator: MatPaginator) {
        super();
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<UserData[]> {
        const displayDataChanges = [
            this._exampleDatabase.dataChange,
            this._paginator.page,
            this._sort.sortChange,
        ];

        return Observable.merge(...displayDataChanges).map(() => {
            let data = this._exampleDatabase.data.slice();

            // sort if needed
            if (this._sort.active && this._sort.direction !== '') {
                const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
                return this.getSortedData().splice(startIndex, this._paginator.pageSize);
            }
            const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            return data.splice(startIndex, this._paginator.pageSize);
        });
    }

    getSortedData(): UserData[] {
        const data = this._exampleDatabase.data.slice();
        if (!this._sort.active || this._sort.direction == '') {return data;}

        return data.sort((a, b) => {
            let propertyA;
            let propertyB;

            switch (this._sort.active) {
                case 'title': propertyA=a.title;propertyB=b.title;break;
                case 'customer_name': propertyA=a.customer_name;propertyB=b.customer_name;break;
                case 'business_name': propertyA=a.customer_name;propertyB=b.customer_name;break;
                case 'vehicle': propertyA=a.vehicle;propertyB=b.vehicle;break;
                case 'request_id': propertyA=a.request_id;propertyB=b.request_id;break;
                case 'appointment_date': propertyA=new Date(a.requested_delivery_date);propertyB=new Date(b.requested_delivery_date);break;
                case 'service_date': propertyA=new Date(a.service_date);propertyB=new Date(b.service_date);break;
                case 'estimated_delivery_date': propertyA=new Date(a.estimated_delivery_date);propertyB=new Date(b.estimated_delivery_date);break;
                case 'status': 
                    propertyA=this.getSortStatus(a.check_action_status,a.appt);
                    propertyB=this.getSortStatus(b.check_action_status,b.appt);
                    break;
                case 'unit_number': propertyA=a.unit_number;propertyB=b.unit_number;break;
                case 'license_plate': propertyA=a.license_plate;propertyB=b.license_plate;break;
                case 'vin': propertyA=a.vin;propertyB=b.vin;break;
                case 'on_the_lot': propertyA=a.on_the_lot;propertyB=b.on_the_lot;break;
                default: return 0;
            }
            if(propertyA=='Invalid Date' || propertyB=='Invalid Date'){
                let date=-1;
                if(propertyB=='Invalid Date'){
                    date=1;
                }
                return (date * (this._sort.direction == 'asc' ? 1 : -1));
            }
            
            let valueA = isNaN(propertyA) ? propertyA.toUpperCase() : propertyA;
            let valueB = isNaN(propertyB) ? propertyB.toUpperCase() : propertyB;

            let baseCmp = valueA < valueB ? -1 : 1;
            if(this._sort.active == 'unit_number'){
                if(valueA == '-' || valueB == '-'){
                    if(this._sort.direction == 'asc'){
                        baseCmp = valueA > valueB ? -1 : 1;
                    }
                }
            }
            return baseCmp * (this._sort.direction == 'asc' ? 1 : -1);
        });
    }

    getSortStatus(status,appt){
        let sortStatusMap = {1:1,2:2,3:3,7:4,12:5,6:6,8:7}
        if(appt == 1 && status == 1) return -1;
        if(appt == 1 && status == 2) return 0;
        return sortStatusMap[status] ? sortStatusMap[status] : 0;
    }

    disconnect() {
        //
    }

}