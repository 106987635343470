import { Component, OnInit, ViewChild, Pipe, Inject, ChangeDetectorRef, ViewEncapsulation, Optional, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup, FormControl, FormBuilder, Validators, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { globalFunctions } from "../../../../environments/globalFunctions";
import { globalConstants } from "../../../../environments/globalConstants";
import { GlobalService } from "../../../../environments/global.service";
import { INgxSelectOption } from 'ngx-select-ex';
import { map, switchMap } from 'rxjs/operators';
import { Observable, pipe } from 'rxjs';
import {Subscription} from 'rxjs';
// import { CustomLoader } from '../../../customloader';

@Component({
  selector: 'app-edit-nexpart-vendor',
  templateUrl: './edit-nexpart-vendor.component.html',
  styleUrls: ['./edit-nexpart-vendor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditNexpartVendorComponent implements OnInit, OnDestroy {

  public isPopup:boolean;
  public currentUser:any;
  public currentUserName:string;
  public editVendorForm:FormGroup;
  public vendorId:any;
  public vendorDetails:any;
  public passwordVisibility:boolean;
  public logoImage:string;
  public logoBaseUrl:string = globalConstants.S3_BUCKET_URL+globalConstants.NEXPART_LOGO;
  public isLogoUploaded:boolean;
  public activeSubmitAndStay:boolean = false;
  public FORMAT_SUPPORTED_FOR_IMAGE = globalConstants.FORMAT_SUPPORTED_FOR_IMAGE;
  public MAX_ALLOWED_IMAGE_SIZE = globalConstants.MAX_ALLOWED_IMAGE_SIZE;
  public MAX_ALLOWED_IMAGE_DISPLAYED_TEXT = globalConstants.MAX_ALLOWED_IMAGE_DISPLAYED_TEXT;
  public subscriptionDataForAPI: Subscription = new Subscription();

  constructor(private formBuilder: FormBuilder, private router: Router, public globalService: GlobalService, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, @Optional() public dialogRef: MatDialogRef<EditNexpartVendorComponent>,
  //  private customLoader:CustomLoader,
    private activatedRoute: ActivatedRoute) {
    if(data && data.isPopup){
      this.isPopup = true;
      this.vendorId = data.vendorId;
    }
    if (localStorage.getItem("loggedInUser")) {
      this.currentUser = JSON.parse(localStorage.getItem("loggedInUser"));
      this.currentUserName = this.currentUser.first_name + this.currentUser.last_Name;
    } else {
      router.navigate(["/shops"])
    }
    setTimeout(()=>{
      let inSubscription:boolean=true;
      // inSubscription = this.globalService.isSubscribe(globalConstants.SUBSCRIPTION_DISCOUNTS_FEATURE);
      let isEnable=this.globalService.getAcl('nexpart_creds','edits');
      if(!(isEnable) || !(inSubscription)){
        this.router.navigate(['shops/action/forbidden']);
      }
     },globalConstants.RELOAD_WAIT);
  }
  
  ngOnInit() {
    this.globalService.setMetaData("SHOPS", "EDIT_NEXPART_VENDOR");
    this.getParams();
    this.initializeAddDiscountForm();
    this.initializeFormDetails();
  }
  
  getParams() {
    if(this.data && this.data.isPopup && this.data.vendorId){
      this.vendorId = this.data.vendorId;
    }
    else{
      this.activatedRoute.params.subscribe((params: Params) => {
        this.vendorId = params["id"];
      });
    }
  }
  
  initializeFormDetails(){
    let data = { user_id: this.currentUser.id, user_type: this.currentUser.user_type, vendor_id: this.vendorId };
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_NEXLINK_VENDORS_URL, data).subscribe((r:any) => {
      if (r.result == 1) {
        let th = this;
        this.vendorDetails = r.data[0];
        setTimeout(()=>{
          this.editVendorForm!.get('name').setValue(this.vendorDetails.name);
          this.editVendorForm.get('username').setValue(this.vendorDetails.username);
          this.editVendorForm.get('password').setValue(this.vendorDetails.password);
          this.editVendorForm.get('logo').setValue(this.vendorDetails.vendor_logo);
          this.logoImage = this.vendorDetails.vendor_logo;
        }, 300);
      }else{
        let message = r.message;
        this.router.navigate(['shops/nexpart/vendors-list']);
        this.globalService.snackbar('error', message);
      }
    }, (error:any) => { })
  }

  initializeAddDiscountForm() {
    this.editVendorForm = this.formBuilder.group({
      name: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', [Validators.required]],
      logo: ['']
    });
  }

  isNotNumber(num:any){
    return isNaN(num);
  }

  onlyDecimalNumberKey(event:any) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
  }

  onlyNumberKey(event:any) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) return false;
    return true;
  }

  disableKey(event:any) {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey == true && (event.which == '67' || event.which == '86' || String.fromCharCode(event.which).toLowerCase() == 's')) {
      console.log('thou. shalt. not. PASTE!');
      event.preventDefault();
    }
  }


  editVendor(opr:any) {
    Object.keys(this.editVendorForm.controls).map((controlName:any) => {
      this.editVendorForm.get(controlName).markAsTouched({ onlySelf: true });
    });
    
    if(this.editVendorForm.valid) {
        var data = new FormData();
        let formData = this.editVendorForm.value; 
        data.append('user_type',this.currentUser.user_type);
        data.append('user_id',this.currentUser.id);
        data.append('vendor_id',this.vendorId);
        for(let x in formData){
          data.append(x, formData[x]);
        }
        // data.append('username', formData.username);
        // data.append('password', formData.password);
        this.globalService.formData(globalConstants.API_EDIT_NEXLINK_VENDOR_URL,data).subscribe((data:any)=> {
            let message = data.message;
            if (data.result == "1") {
              if(opr == 1){
                if(this.isPopup){
                  this.dialogRef.close(1);
                }
                else{
                  this.router.navigate(["/shops/nexpart/vendors-list"]);
                }
              }
              else{
                this.editVendorForm.get('logo').setValue(data.data.vendor_logo);
                this.activeSubmitAndStay = true;
                // this.editVendorForm.reset();
              }
              this.globalService.snackbar('success', message);
            } else {
              this.globalService.snackbar('error', message);
            }
        });
    } else {
        this.mandatoryMsg();
    }
  }

  mandatoryMsg() {
    this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
  }
  
  expandAccordion(accordion:boolean){
    accordion = !accordion;
  }
 
  getSelectedCustomers(ev:any){
    console.log('selected customers = ', ev);
    // console.log('selected customers add = ', this.editVendorForm.controls['customers'].value);
    // this.filteredCustomerData = this.customerData.slice(0,100).filter((customer)=>{
    //   return this.editVendorForm.get('customers').value.indexOf(customer.id) == -1;
    // });
  }
  
  changeListener(event:any, rendering_tag_id:any) {
    let ele = <HTMLInputElement>document.getElementById('add' + rendering_tag_id);
    if (event.target.files && event.target.files[0]) {
      let cFiles = { src: <ArrayBuffer | string>'', type: 0, name: '' };
      let file = event.target.files[0];
      let valid=true;
      valid=this.globalService.validateFiles('image',event.target.files);
      if(valid==false){
        return false;
      }
      if (valid) {
        if (file.size) {
          let data = new FormData();
          let url = globalConstants.API_NEW_SERVICE_REQUEST_FILE;
          data.append('user_id', this.currentUser.id);
          data.append('user_type', this.currentUser.user_type);
          data.append('image', file);
          let FileType = this.globalService.getFileIcon(file);
          cFiles.type = FileType;
          let reader = new FileReader();
          reader.onload = (e) => {
            cFiles.src = e.target['result'];
          }
          reader.readAsDataURL(event.target.files[0]);

          this.globalService.formData(url, data).subscribe((response:any) => {
            let resData = response;
            if (resData.result == 1) {
              this.checkFileUploadLimit(cFiles,resData,rendering_tag_id);
              event.target.value = "";
            } else {
              if (ele) {
                  ele.value = '';
              }
              this.globalService.snackbar("error", resData.message);
            }
          })
        } else {
          if (ele) {
              ele.value = '';
          }
          this.globalService.snackbar("error", 'Please upload a valid file.');
        }
      } else {
        if (ele) {
            ele.value = '';
        }
        this.globalService.snackbar("error", 'Please upload a valid file.');
      }
    }
  }
  
  deleteImg(type:any){
    let ele=<HTMLInputElement>document.getElementById('add'+type);
    if(ele){
      ele.value='';
    }
    this.logoImage = '';
    this.isLogoUploaded = false;
    this.editVendorForm.controls.logo.setValue('');
  }
  
  checkFileUploadLimit(cFiles,resData,rendering_tag_id){
    // let multi=false;
    cFiles.name = resData.data.name;
    // if(!multi){
    //   this.vehicle_pics=[];    
    // }
    // this.vehicle_pics.push(cFiles);
    this.isLogoUploaded = true;
    this.logoImage = cFiles.src;
    this.editVendorForm.controls.logo.setValue(cFiles.name);
  }

  handleClose(){
    if(this.activeSubmitAndStay){
      this.dialogRef.close(1);
    }else{
      this.dialogRef.close();
    }
  }

  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
  }  
}
