<div class="panel panel-primary">
  <div *ngIf="isMatDialogOn" mat-dialog-title class="relative">
    Service Request Logs
    <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
      <mat-icon class="close-icon-lg">close</mat-icon>
    </button>
  </div>
  <div *ngIf="!isMatDialogOn" class="panel-heading padding-left-15">
    <h3 class="panel-title">Service Request Logs</h3>
    <button mat-mini-fab class="custom-btn-back" title="Back" (click)="globalService.goBack();">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <div class="panel-body bgcolor-lightgrey logs-detail">
    <!-- logs filter start -->
    <div class="logs-filter">
      <div class="display-full relative">
        <mat-expansion-panel hideToggle="false" [expanded]="openSearch" class="search-filter search-employee relative mat-elevation-z1">
          <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px">
            <strong>
              <i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
          </mat-expansion-panel-header>
          <form id="advanceSearchForm" class="collapse in" aria-expanded="true">
            <div class="panel-body">
              <div class="col-sm-3 uc-ngx-select">
                <ngx-select class="display-full margin-bottom-20" [items]="filterAction" [(ngModel)]="fillActionValue" [ngModelOptions]="{standalone: true}"
                  placeholder="Select Action" optionTextField="name" [noAutoComplete]="true">
                </ngx-select>
              </div>
              <div class="col-sm-3 uc-ngx-select" *ngIf="isEmployee">
                  <ngx-select class="display-full margin-bottom-20" [items]="technicians" [(ngModel)]="filterTech" [ngModelOptions]="{standalone: true}"
                  placeholder="Select Employee" [noAutoComplete]="true">
                </ngx-select>
              </div>
              <div class="col-sm-3" *ngIf="fillActionValue=='bwtDate'">
                  <mat-form-field class="car-input btn-block car-datePicker"  floatPlaceholder="never">
                      <input (dateChange)="dateChange($event,1)" matInput class="form-control input-lg custom-form-input"  [(ngModel)]="fromDate" [ngModelOptions]="{standalone: true}" placeholder="Log From Date" [matDatepicker]="filerPicker1" onkeydown="return false;">
                             <mat-datepicker-toggle matSuffix [for]="filerPicker1" ></mat-datepicker-toggle>
                      <mat-datepicker #filerPicker1 [touchUi]="setTouchViewValue()"></mat-datepicker>
                  </mat-form-field>
              </div>
              <div class="col-sm-3" *ngIf="fillActionValue=='bwtDate'">
                  <mat-form-field class="car-input btn-block car-datePicker"  floatPlaceholder="never">
                      <input (change)="dateChange($event,2)" matInput class="form-control input-lg custom-form-input"  [(ngModel)]="toDate" [min]="fromDate" [ngModelOptions]="{standalone: true}" placeholder="Log to Date" [matDatepicker]="filerPicker2" onkeydown="return false;">
                             <mat-datepicker-toggle matSuffix [for]="filerPicker2" ></mat-datepicker-toggle>
                      <mat-datepicker #filerPicker2 [touchUi]="setTouchViewValue()"></mat-datepicker>
                  </mat-form-field>
              </div>
            </div>
            <mat-action-row class="search-filter-footer">
              <button mat-raised-button color="accent" type="submit" title="Search" (click)="search()">
                Search
              </button>
            </mat-action-row>
          </form>
        </mat-expansion-panel>
        <button mat-button type="button" title="Clear Filter" #clearBtn class="clear-btn" [class.active-search]="isAnyOptionActive(clearBtn)" (click)="clearSearch()">
          Clear Filter
        </button>
      </div>
    </div>
    <!-- logs filter start -->
    <!-- repeat user logs start -->
    <div class="display-full user" *ngFor="let log of logs">
      <img class="user-icon user-icon--circle" *ngIf="isProfilePic(log.photo)" src="{{docImgPath}}{{log.photo}}"  alt="Profile image">
      <img class="user-icon user-icon--circle" *ngIf="isProfilePlaceholder(log.photo)" src="assets/img/default-avatar.png" alt="no image">
      <div class="user-detail col-sm-12 user-detail--border-bottom">         
        <div class="row">
          <div class="col-sm-8">
            <h4 class="margin-0" *ngIf="log.user_type=='Customer'">{{log.fname}} {{log.lname}}</h4>
            <h4 class="margin-0" *ngIf="log.user_type!='Customer'">{{log.first_name}} {{log.last_name}} <small *ngIf="log.designation!=''"><i>({{log.designation}})</i></small></h4>
            <small>{{formatDate(log.add_date)}}</small> 
          </div>
          <div class="col-sm-4 padding-0 text-right">
            <button mat-raised-button type="button" color="accent" *ngIf="(log.module_action=='ServiceRequestReOpen' || log.module_action =='ServiceRequestUpdateRecommendationToCustomer' || log.module_action =='ServiceRequestRecommendations') && log.old_record_value!=''" (click)="viewDetail(log)">View Details</button>
          </div>
        </div>      
      </div>      
      <div class="user-content">
        <em><span [innerHTML]="log.remarks"></span> <span *ngIf="log.assignee_first_name!=''"> - <small>{{log.assignee_first_name}} {{log.assignee_last_name}} <i>({{getRoles(log.assignee_designation)}})</i></small></span></em>
      </div>
    </div>
    <!-- repeat user logs end -->

    <div class="display-full user" *ngIf="logs.length==0">
        <h4 class="margin-0 text-center">No Records Available</h4>
    </div>
  </div>
</div>