<div class="panel panel-primary" id="mainCompotents">
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">{{ currentCustomer.profile_type == '2' ? 'CONTACT' : 'CUSTOMER' }} LIST</h3>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <div class="display-full relative">
            <mat-expansion-panel hideToggle="false" class="search-filter relative" [expanded]="openShopCustomersSearch">
                <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px">
                    <strong><i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
                </mat-expansion-panel-header>
                <form id="advanceSearchForm" class="collapse in" aria-expanded="true">
                    <div class="panel-body padding-top-5">
                        <div class="row">
                            <div class="col-sm-3">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <input  matInput placeholder="Search Keyword" title="Search Keyword" [(ngModel)]="options.search_keyword" [ngModelOptions]="{standalone: true}" class="form-control input-lg custom-form-input"/>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <mat-action-row class="search-filter-footer">
                      <button mat-raised-button color="accent" type="submit" (click)="search($event)" title="Search">
                        Search
                      </button>
                    </mat-action-row>
                </form>
            </mat-expansion-panel>
            <button mat-button class="clear-btn" title="Clear Filter" #clearBtn (click)="clearSearch()" [class.active-search]="checkFormEmpty(clearBtn)">
                Clear Filter
            </button>
        </div>

        <!-- grid and list view icon -->
        <div class="grid-list-view-icon hide">
            <ul class="list-inline d-flex flex-row mx-5">
                <li class="list-inline-item one">
                    <div class="action-icon-color active">
                        <i class="fa fa-list" aria-hidden="true"></i>
                    </div>
                </li>
                <li class="list-inline-item">
                    <div class="action-icon-color">
                        <i class="fa fa-th" aria-hidden="true"></i>
                    </div>
                </li>
            </ul>
        </div>
        <div class="car-datatable mat-elevation-z1 uc-datatable ">
            <div class="table-responsive relative">
                <form [formGroup]="listCar" class="form-inline signup-form" method="POST" enctype="multipart/form-data" autocomplete="off">
                    <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg uc-table-responsive">
                        <!-- Color Column -->
                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="name"> Name </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="name" data-val="Name" title="{{row.name}}"> {{row.name}}  <mat-chip-list class="mat-badge" *ngIf="row.is_sublet=='1'" >
                                <mat-chip color="primary" selected="true">Sublet</mat-chip>
                            </mat-chip-list>
                            </mat-cell>
                        </ng-container>

                         <!-- Color Column -->
                         <ng-container matColumnDef="businessName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="businessName"> Business Name </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="businessName" data-val="Business Name" 
                            title='{{row.business_name != "" ? row.business_name : "-"}}'> {{row.business_name != "" ? row.business_name : '-'}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="deptName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="businessName"> Department Name </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="businessName" data-val="Department Name" 
                            title='{{row.business_name != "" ? row.business_name : "-"}}'> {{row.business_name != "" ? row.business_name : '-'}} </mat-cell>
                        </ng-container>
                        
                        <!-- Color Column -->
                        <ng-container matColumnDef="email">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Email </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="auto" data-val="Email" title="{{(row.email)}}"><a class="text-black" href="mailto:{{row.email}}"> {{(row.email)}} </a></mat-cell>
                        </ng-container>

                        <!-- Color Colu`mn -->
                        <ng-container matColumnDef="address">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Address </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="auto" data-val="Address" title='{{row.address !="" ? row.address : "-"}}'>{{row.address !="" ? row.address : "-"}}</mat-cell>
                        </ng-container>

                        <!-- Color Column -->
                        <ng-container matColumnDef="city">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> City </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="auto" data-val="City" title='{{row.city !="" ? row.city : "-"}}'>{{row.city !="" ? row.city : "-"}}</mat-cell>
                        </ng-container>

                        <!-- Color Column -->
                        <ng-container matColumnDef="state">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> State </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="auto" data-val="State" title='{{row.state !="" ? row.state : "-"}}'> {{row.state !="" ? row.state : "-"}} </mat-cell>
                        </ng-container>

                        <!-- Color Column -->
                        <ng-container matColumnDef="mobile">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="phone"> Mobile No. </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="phone" data-val="Mobile No." 
                            title='{{row.mobile !="" ? row.mobile : "-"}}'><a class="text-black" href="tel:{{row.mobile}}"> {{row.mobile !="" ? row.mobile : "-"}}</a> </mat-cell>
                        </ng-container>

                        <!-- Color Column -->
                        <ng-container matColumnDef="Actions">
                            <mat-header-cell *matHeaderCellDef  data-role="button"> Actions </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="button">
                                <button mat-button class="list-action-btn" [matMenuTriggerFor]="menu" disableRipple >
                                    Action<mat-icon>arrow_drop_down</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu" class="car-icon-menu disable-no-bg" [overlapTrigger]="false">
                                    <button type="button" title="{{ row.email_activation_status == '1' ? 'Edit': 'Email verification is pending.' }}" class="mat-menu-item" *ngIf="isAllowed('edits') && row.mapping_status == '1'" [disabled]="row.email_activation_status == '0'" (click)="editCustomer(row.customer_id,row.is_walkin_customer)"><i class="fa fa-edit"></i>Edit</button>
                                    <a class="mat-menu-item" *ngIf="row.mapping_status == 0" (click)="getCustomerAccess(row)" title="View"><i class="fa fa-list-alt"></i>Get Full Access</a>
                                    <a class="mat-menu-item" *ngIf="isAllowed('views')" (click)="viewCustomer(row.customer_id)" title="View"><i class="fa fa-list-alt"></i>View</a>
                                    <a class="mat-menu-item" (click) = "addCar(row.sendParams)" title="Add Car"><i class="fa fa-car"></i>Add Car</a>
                                    <a class="mat-menu-item" (click) = "listCars(row.customer_id)" title="View Cars"><i class="fa fa-list-alt"></i>View Cars</a>
                                    <a class="mat-menu-item" *ngIf="isAllowed('addNew','service_request')" (click) = "newServiceRequestForCustomer(row.id, row.customer_country)" title="New Service Request"><i class="fa fa-wrench"></i>New Service Request</a>
                                    <a class="mat-menu-item" *ngIf="isAllowed('views','service_request')" (click) = "viewAllServiceRequests(row.name,row.customer_id)" title="View All Requests"><i class="fa fa-list-alt"></i> View All Requests</a>
                                    <a class="mat-menu-item" *ngIf="isAllowed('edits','customer_password')" (click) = "sendCustomerPassword(row.customer_id,row.is_walkin_customer)" title="Send Password"><mat-icon>security</mat-icon> Send Password</a>
                                    <button type="button" *ngIf="profileType==2 && row.is_preferred==0" title="Mark as Preferred" class="mat-menu-item" (click)="markAsPerfrred(row)"><i class="fa fa-heart"></i>Mark as Preferred</button>
                                    <button type="button" *ngIf="profileType==2 && row.is_preferred==1" title="Delete From Preferred" class="mat-menu-item" (click)="markAsPerfrred(row)"><i class="fa far fa-heart"></i>Delete from Preferred</button>
                                </mat-menu>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                </form>

                <div class="padding-15 text-center"
                    [style.display]="getTotalRecords() < 1 ? '' : 'none'">
                    No Records Available
                </div>
            </div>
            <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
                <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="options.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event)" >
                </mat-paginator>

                <div class="mat-paginator table-paging">
                    <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
                    <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                            <mat-icon>navigate_before</mat-icon>
                    </button>
                    <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                            <mat-icon>navigate_next</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div> 
<!-- Customer Grid View START -->
<div class="row hide">
    <div class="col-md-4 col-sm-6 col-xs-12">
        <div class="flip-card">
            <div class="flip-main">
                <div class="recto">
                    <div class="x-card">
                        <div class="x-card-body text-center pb-2">
                            <img class="img-sq-100"src="assets/img/people-10.jpg" alt="">
                            <div class="">
                                <h4 class="x-card-title">Michael Scott Jones</h4>
                                <p class="x-card-text mb-1">
                                    <label>Address:</label>
                                    <span>426 Vinewood Road</span>
                                </p>
                                <span class="mr-1">
                                    <label>City:</label>
                                    <span>Calymont</span>
                                </span>
                                <span class="">
                                    <label>Country:</label>
                                    <span>USA</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="verso rounded">
                    <div class="x-card">
                        <div class="x-card-body text-center">
                            <h4 class="x-card-title"><span>Michael Scott Jones</span></h4>
                            <div class="padding-top">
                                <p class="x-card-text mb-2"><a href="#" class="text-black">michaeljones@gmail.com</a> </p>
                                <p class="mb-2"><a class="text-black" href="#"> 889-187-7987</a></p>
                            </div>
                            <ul class="list-inline d-flex flex-row mx-5">
                                <li class="list-inline-item">
                                    <a class="action-icon-color" title="Edit">
                                        <i class="fa fa-edit"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="action-icon-color" title="View">
                                        <i class="fa fa-list-alt"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="action-icon-color" title="Add Car" >
                                        <i class="fa fa-car"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="action-icon-color" title="View Car">
                                        <i class="fa fa-list-alt"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="action-icon-color" title="New Service Request">
                                        <i class="fa fa-wrench"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="action-icon-color" title="View All Requests">
                                        <i class="fa fa-list-alt"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item margin-top-10">
                                    <a class="action-icon-color" title="View All Requests">
                                        <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true">security</mat-icon>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-sm-6 col-xs-12">
        <div class="flip-card">
            <div class="flip-main">
                <div class="recto">
                    <div class="x-card">
                        <div class="x-card-body text-center pb-2">
                            <img class="img-sq-100"src="assets/img/people-10.jpg" alt="">
                            <div class="">
                                <h4 class="x-card-title">Michael Scott Jones</h4>
                                <p class="x-card-text mb-1">
                                    <label>Address:</label>
                                    <span>426 Vinewood Road</span>
                                </p>
                                <span class="mr-1">
                                    <label>City:</label>
                                    <span>Alston</span>
                                </span>
                                <span class="">
                                    <label>Country:</label>
                                    <span>Georgia</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="verso rounded">
                    <div class="x-card">
                        <div class="x-card-body text-center">
                            <h4 class="x-card-title"><span>Michael Scott Jones</span></h4>
                            <div class="padding-top">
                                <p class="x-card-text mb-2"><a href="#" class="text-black">michaeljones@gmail.com</a> </p>
                                <p class="mb-2"><a class="text-black" href="#"> 889-187-7987</a></p>
                            </div>
                            <ul class="list-inline d-flex flex-row mx-5">
                                <li class="list-inline-item">
                                    <a class="action-icon-color" title="Edit">
                                        <i class="fa fa-edit"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="action-icon-color" title="View">
                                        <i class="fa fa-list-alt"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="action-icon-color" title="Add Car" >
                                        <i class="fa fa-car"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="action-icon-color" title="View Car">
                                        <i class="fa fa-list-alt"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="action-icon-color" title="New Service Request">
                                        <i class="fa fa-wrench"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="action-icon-color" title="View All Requests">
                                        <i class="fa fa-list-alt"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item margin-top-10">
                                    <a class="action-icon-color" title="View All Requests">
                                        <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true">security</mat-icon>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-sm-6 col-xs-12">
        <div class="flip-card">
            <div class="flip-main">
                <div class="recto">
                    <div class="x-card">
                        <div class="x-card-body text-center pb-2">
                            <img class="img-sq-100"src="assets/img/default-avatar.png" alt="">
                            <div class="">
                                <h4 class="x-card-title">Michael Scott Jones</h4>
                                <p class="x-card-text mb-1">
                                    <label>Address:</label>
                                    <span>426 Vinewood Road</span>
                                </p>
                                <span class="mr-1">
                                    <label>City:</label>
                                    <span>Alston</span>
                                </span>
                                <span class="">
                                    <label>Country:</label>
                                    <span>Georgia</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="verso rounded">
                    <div class="x-card">
                        <div class="x-card-body text-center">
                            <h4 class="x-card-title"><span>Michael Scott Jones</span></h4>
                            <div class="padding-top">
                                <p class="x-card-text mb-2"><a href="#" class="text-black">michaeljones@gmail.com</a> </p>
                                <p class="mb-2"><a class="text-black" href="#"> 889-187-7987</a></p>
                            </div>
                            <ul class="list-inline d-flex flex-row mx-5">
                                <li class="list-inline-item">
                                    <a class="action-icon-color" title="Edit">
                                        <i class="fa fa-edit"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="action-icon-color" title="View">
                                        <i class="fa fa-list-alt"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="action-icon-color" title="Add Car" >
                                        <i class="fa fa-car"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="action-icon-color" title="View Car">
                                        <i class="fa fa-list-alt"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="action-icon-color" title="New Service Request">
                                        <i class="fa fa-wrench"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="action-icon-color" title="View All Requests">
                                        <i class="fa fa-list-alt"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item margin-top-10">
                                    <a class="action-icon-color" title="View All Requests">
                                        <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true">security</mat-icon>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Customer Grid View END-->