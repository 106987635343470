import {Component, OnInit, ViewChild, ViewEncapsulation,OnDestroy} from '@angular/core';
import {AbstractControl, FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
import {Http, Headers, Response} from '@angular/http';

import {globalFunctions} from "../../../environments/globalFunctions";
import {globalConstants} from "../../../environments/globalConstants";
import {GlobalService} from "../../../environments/global.service";

import {Title} from '@angular/platform-browser';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {DataSource, SelectionModel} from '@angular/cdk/collections';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import {AppComponent} from "../../app.component"
import {Subscription} from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
/**
 * @author Shashank Thakur
 * @trackID <:CAR-1412>
 * @updated 18-03-2020
 */
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddnewcustomerComponent } from '../shopcustomers/addnewcustomer/addnewcustomer.component';
import {​ ServiceHistoryComponent }​ from '../../shop/shopservicerequest/service-history/service-history.component';
import { DetailTabComponent } from '../shopservicerequest/detail-tab/detail-tab.component';

const REQUEST_LIST = "easy-checkin-";
@Component({
  selector: 'app-easycheckin',
  templateUrl: './easycheckin.component.html',
  styleUrls: ['./easycheckin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EasycheckinComponent implements OnInit, OnDestroy {
  selectlists : any[];
  public resultData1 = localStorage.getItem("easyCheckinData");
  public shop: FormGroup;
  public licensePlate = false;
  public more_rows;
  public search_keyword_valid:boolean = false;
  public search_keyword2_valid:boolean = false;
  public options = {
    user_type: "User",
    user_id: "",
    search_keyword2: "",
    search_keyword: "",
    search_by: "",
    start: 0,
    limit: 10,
  }
  public checkinFilters;
  public displayedColumns;
  public displayResults;
  public disableEdit: boolean = true;
  // displayedColumns = ['Name', 'Email', 'Address', 'City', 'State', 'Country', 'Mobile Number',  'Actions'];
  dataSource: MatTableDataSource<easyCheckData> = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public openSearch: boolean = false;
  public checkIn=false;
  public viewCustomerInfo=false;
  gc:any = globalConstants;
  public subscriptionDataForAPI: Subscription = new Subscription();
  public recordsTotal : any;
  public record :any =[];
  constructor(public app:AppComponent,private formBuilder: FormBuilder, public titleService: Title, private globalService: GlobalService, private route: ActivatedRoute, private http: Http, private router: Router, public dialog: MatDialog) {
    this.options =  JSON.parse(localStorage.getItem("easyCheckinData"));
    // this.displayedColumns = ['Name', 'Email', 'Address', 'City', 'State', 'Country', 'Mobile Number',,'Car-Name', 'Maker', 'Model', 'Year', 'Color', 'License-Plate',  'Actions'];

    this.selectlists = [
      {
        id : 1,
        name: "Last Name",
      },
      {
        id : 2,
        name: "License Plate",
      },
      {
        id : 3,
        name: "Phone Number(Last 4 digits)",
      },
      {
        id : 4,
        name: "Business Name",
      },
      {
        id : 5,
        name: "CAR Customer and License Plate",
      },
      {
        id: 6,
        name: "Unit Number",
      },
      {
        id: 7,
        name: "Vin",
      }]
      if (this.globalService.isFleet()) {
        this.selectlists = [
          {
            id: 2,
            name: "License Plate",
          },
          {
            id: 4,
            name: "Department Name",
          },
          {
            id: 6,
            name: "Unit Number",
          },
          {
            id: 7,
            name: "Vin",
          }
        ]
      }
      if(localStorage.getItem("openFilter") == "1") {
        this.openSearch = true;
      } else {
        this.openSearch = false;
      }

      
      this.globalService.setMetaData("SHOPS","EASY_CHECK_IN")
    }

    ngOnInit() {
      
      this.viewCustomerInfo=this.globalService.isAllowed(this.app,'view_customer_info','views');
    setTimeout(()=>{
      this.checkIn=this.globalService.isAllowed(this.app,'check_in','addNew');
      if(!(this.checkIn)){
        this.router.navigate(['shops/action/forbidden']);
      }
      if(this.viewCustomerInfo==false){
        this.selectlists = [{id : 2,name: "License Plate"}]
      }
    },globalConstants.RELOAD_WAIT);
      
      this.setDisplayedColumns();
      this.reinitializeOptions();
      this.options.user_id = this.globalService.getCurrentUser()["id"];
      this.getDataTable();
    }

    ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
    }

    setDisplayedColumns(){
      if(this.options.search_by == "2" || this.options.search_by == "6" || this.options.search_by == "7") {
        //lookup for vehicle attribute licensee plate, unit number, vin
       this.displayResults = this.options.search_by;
       this.displayedColumns = ['name', 'mobile', 'vehicle','unit_number','vin','car_license_plate', 'state', 'Actions'];
     } else {
        //lookup for customer attributes 
       this.displayResults = this.options.search_by;
       this.displayedColumns = ['name', 'email', 'address', 'city', 'state', 'country', 'mobile',  'Actions'];
     }
    }
    search(event) {
      this.setDisplayedColumns();
      this.search_keyword_valid = false;
      this.search_keyword2_valid = false;

      if(this.options.search_keyword == ''){
        this.search_keyword_valid = true;
      }
      if(this.options.search_by == '5'){
        if(this.options.search_keyword2 == ''){
          this.search_keyword2_valid = true;
        }
      }
      if(this.search_keyword_valid == true || this.search_keyword2_valid == true){
        return false;
      }

      this.options = {
        user_type: "User",
        user_id: this.globalService.getCurrentUser()["id"],
        search_keyword2: this.options.search_keyword2,
        start: 0,
        limit: 10,
        search_keyword: this.options.search_keyword,
        search_by: this.options.search_by,
      }

      localStorage.setItem("easyCheckinData", JSON.stringify(this.options));
      this.getDataTable();
    }

    sideNavOpen() {
      console.log("nav toggle");
    }

    checkFormEmpty(button) {
      if (this.options.search_keyword == "") {
        if (button) {button.color = "primary"}
        return false
      }
      else {
        if (button) {button.color = "accent"}
        return true;
      }
    }

    onResize(event) {
      event.target.innerWidth;
    }

    editCustomer(id) {
      this.router.navigate(['shops/edit-customer/', id]);
    }

    addCar(id,country_id) {
      localStorage.setItem("shopCustomerID", id+","+country_id);
      this.router.navigate(['shops/add-car']);
    }

    listCars(id) {
      localStorage.setItem("listCustomerID", id);
      this.router.navigate(['shops/list-cars']);
    }

    viewAllServiceRequests(search_keyword, customer_id) {
      localStorage.setItem("shop-servicesearch_keyword", search_keyword);
      localStorage.setItem("shop-servicecustomer_id", customer_id);
      localStorage.setItem("openFilter", "1");
      this.router.navigate(['shops/list-service-requests']);
    }
    /* EASY CHECKIN SEARCH BY LICENSE PLATE */
    viewCarHistory(customer_id, vehicle_id) {
      localStorage.setItem("carDetailsCustomerID", customer_id);
      localStorage.setItem("carDetailsVehicleID", vehicle_id);
      this.router.navigate(['shops/service-history']);
    }
    
    openServiceHistoryPopup(serviceInfo){​
      // Material Popup Dialog
      
      // let dialogRef = this.dialog.open(ServiceHistoryComponent, {​
      //   panelClass: 'car-dialog-form',
      //   width:'95%',
      //   data: {​ isPopup: true, car_id:this.serviceDetail.car_id, service_data: this.serviceDetail }​
      // }​);
      // dialogRef.afterClosed().subscribe(result => {​
      //   console.log('history popup closed = ', result);
      // }​);
      let info:any = {
        id:'service-history-'+serviceInfo.car_id,
        name: 'Service History View',
        module: 'Service Request'
      }
      let data:any = {​ isPopup: true, carId:serviceInfo.car_id, serviceInfo:serviceInfo, id: 'service-history-'+serviceInfo.car_id };
      this.globalService.openMinimizablePopup(ServiceHistoryComponent, info, data);
    }​

    viewCar(customer_id, vehicle_id) {
      localStorage.setItem("carDetailsCustomerID", customer_id);
      localStorage.setItem("carDetailsVehicleID", vehicle_id);
      this.router.navigate(['shops/view-car']);
    }

    editCar(customer_id, vehicle_id, country_name) {
      localStorage.setItem("carDetailsCustomerID", customer_id);
      localStorage.setItem("carDetailsVehicleID", vehicle_id);
      localStorage.setItem("carCustomerCountryName", country_name);
      this.router.navigate(['shops/edit-car']);
    }

    deleteCar(car_id, customer_id) {
      this.globalService.confirmDialog(car_id, 'Are you sure you want to delete this car?').subscribe((res) => {
        if (res != null) {
         this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_DELETE_CUSTOMER_VEHICLE_URL, "customer_id=" + customer_id + "&vehicle_id=" + car_id).subscribe((data:any) => {
            var message = data.message;
            if (data.result == "1") {
              //this.globalService.onDeleteCheck(SHOP_COMPUTERCODE,this.options)
              this.reinitializeOptions();
              this.getDataTable();
              this.globalService.snackbar('success', message);
            } else {
              this.globalService.snackbar("error", message);
            }
          });
        }
      });
    }

    carNewRequest(carId,customer_id,vehicleVin) {
      // if(vehicleVin !="") {
      //   this.router.navigate(['shops/new-service-request/'+customer_id+'/'+carId]);
      // } else {
      //   this.globalService.confirmDialog('confirm' ,'Request can only be created for the vehicle with VIN number.');
      // }
      this.router.navigate(['shops/new-service-request/'+customer_id+'/'+carId]);
    }

    /* */
    makeNewRequest(customer_id) {
      this.router.navigate(['shops/new-service-request/'+customer_id]);
    }

    getCurrentRoute() {
      return this.router.url
    }

    scrolling() {
      this.globalService.setScrolling('.car-datatable', 1000, -20);
    }

    paginateByLimit(event) {
      this.globalService.setScrolling('body', 600, 0);
        this.options.limit = event.pageSize;
        this.options.start = 0;
        this.scrolling();
        this.getDataTable();
    }

    reinitializeOptions() {
      this.options = {
        user_type: "User",
        user_id: this.globalService.getCurrentUser()["id"],
        search_keyword2: this.options.search_keyword2,
        start: 0,
        limit: 10,
        search_keyword: this.options.search_keyword,
        search_by: this.options.search_by
      }
      localStorage.setItem("easyCheckinData", JSON.stringify(this.options));
    }

    getTotalRecords() {
      return parseInt(localStorage.getItem(REQUEST_LIST + 'totalRecords'));
    }

    paginate(event, type) {
      this.scrolling();
      if (type == "next") {
        this.options.start = this.options.start + this.options.limit;
      } else {
        this.options.start = this.options.start - this.options.limit;
      }
      this.getDataTable();
    }

    firstList() {
      return this.globalService.firstList(REQUEST_LIST, this.options);
    }

    lastList() {
      return this.globalService.lastList(REQUEST_LIST);
    }

    getUpperLimit(currentLastLimit, total) {
      if (currentLastLimit > total) {
        return total
      } else {
        return currentLastLimit
      }
    }

    onSelectListChange(list){
      if(list == 5){
        this.licensePlate = true;
      }else{
        this.licensePlate = false;
      }
    }
    getDataTable(){
      this.record = [];
      var content = globalFunctions.stringifyFormData(this.options, null);
      var callApi = this.globalService.callAPI(globalConstants.API_GET_EASY_CHECKIN_CUSTOMERS_LIST_URL, content);
     this.subscriptionDataForAPI =  callApi.subscribe((data:any) => {
        if (data.data != undefined) {
          let moreRows = 'false';
          let firstList = 'false';
          this.globalService.localGetterSetter("totalRecords", data.recordsTotal, REQUEST_LIST);

          if(data.more_rows == "true") {
            moreRows = "true";
          }

          this.globalService.localGetterSetter("more_rows", moreRows, REQUEST_LIST);
          if (data.start >= 0 && data.start <= 5) {
            firstList = "true";
          }

          this.globalService.localGetterSetter("first_list", firstList, REQUEST_LIST);
          if(data.data.length!=undefined){
            this.globalService.localGetterSetter("current-visible",this.recordsTotal,REQUEST_LIST);
            this.recordsTotal = data.data.length;
            for (var i = 0; i < data.data.length; i++) {
              this.AddRecord(data.data[i])
            }
            this.dataSource = new MatTableDataSource(this.record)
            this.dataSource.sort = this.sort;
          } else {
            this.recordsTotal = "1";
            this.AddRecord(data.data);
            this.dataSource = new MatTableDataSource(this.record)
            this.dataSource.sort = this.sort;
            this.globalService.localGetterSetter("current-visible",'1',REQUEST_LIST);
          }
        } else {
          this.dataSource = new MatTableDataSource(this.record)
          this.globalService.localGetterSetter("totalRecords", '0', REQUEST_LIST);
        }
      })
    }

    AddRecord(record){
      var data = this.createRecord(record);
      this.record.push(data);
    }

    private createRecord(record) {
      if(this.viewCustomerInfo){
        return {
          name: record.first_name + " " + record.last_name,
          email: record.email,
          address: record.address1,
          city: record.city,
          state: record.state,
          country: record.country,
          country_id : record.country_id,
          mobile: record.mobile_phone,
          status: record.status,
          shop_id: record.cust_id,
          car_id: record.car_id,
          vin: record.vin,
          customer_id: record.cust_id,
          vehicle: this.globalService.getGlobalVehicleName(record),
          car_color: record.color,
          unit_number: record.unit_number,
          car_license_plate: record.license_plate,
          is_sublet: record.is_sublet
        };
      }else{
        return {
          name: record.first_name + " " + record.last_name,
          email: '-',
          address: '-',
          city: '-',
          state: '-',
          country: '-',
          country_id : record.country_id,
          mobile: '-',
          status: record.status,
          shop_id: record.cust_id,
          car_id: record.car_id,
          vin: record.vin,
          customer_id: record.cust_id,
          vehicle: this.globalService.getGlobalVehicleName(record),
          car_color: record.color,
          unit_number: record.unit_number,
          car_license_plate: record.license_plate,
          is_sublet: record.is_sublet
        };
      }
    }
    isAllowed(action,module?:any){
      if(typeof module!='undefined'){
          return this.globalService.isAllowed(this.app,module,action);
      }
      return false;
    }
    showCarDetail(){
      let type=this.displayResults;
      if(type=='2' || type=='6' || type=='7'){
        return true;
      }
      return false;
    }
  
  /**
   * @author Shashank Thakur
   * @trackID <:CAR-1412>
   * @created 18-03-2020
   * @see Function to open add new customer in popup.
  */
  openAddNewCustomerPopup(ev:any){
    let dialogRef = this.dialog.open(AddnewcustomerComponent, {
      panelClass: 'car-dialog-form',
      width: '100%',
      data: { isPopup: true }
    });
    dialogRef.afterClosed().subscribe((r: any) => {
      
    });
  }

  handleOpenViewCarAndCustomerPopUp(data, tabInfo) {
    let dialogRef = this.dialog.open(DetailTabComponent, {
      panelClass: 'car-dialog-form',
      width: "800px",
      data: { requestId: '', customer_id: data.customer_id, tab: tabInfo, car_id: data.car_id }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
}  
}

  export interface easyCheckData {
    name: any;
    email: any;
    address: any;
    city: any;
    state: any;
    country: any;
    country_id: any;
    mobile: any;
    status: any;
    shop_id: any;
    car_id: any;
    vin: any;
    customer_id: any;
    car_name: any;
    car_make: any;
    car_model: any;
    car_year: any;
    car_color: any;
    vehicle:any;
    car_license_plate: any;
    is_sublet: any;
  }


