<div class="panel panel-primary" id="mainCompotents">
  <div class="panel-heading padding-left-30">
    <h3 class="panel-title">Order Queue Details</h3>
    <button mat-mini-fab class="custom-btn-back" (click)="gs.goBack();">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <div class="panel-body bgcolor-lightgrey" *ngIf="detailData">
      <div class="display-full relative">
      <button class="btn mat-raised-button mat-accent pull-right" (click)="preppareStock()" title="Order Now">Order Now</button>
      </div>
    <div class="display-full relative">
        
      <div class="table-responsive">
        <table class="table table-striped table-hover table-bordered ">
          <tbody>
            <tr>
              <td><strong>Queue Name</strong> </td>
              <td>{{detailData.name}} </td>
            </tr>
            <tr>
                <td><strong>Created By</strong> </td>
                <td>{{detailData.first_name+' '+detailData.last_name}} </td>
              </tr>
            <tr>
                <td><strong>Status </strong></td>
                <td>{{showStatus(detailData.status)}} </td>
            </tr>
            <tr>
                <td><strong>Created on</strong> </td>
                <td>{{gs.getFullFormateDate(detailData.add_date,'G','B')}} </td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="panel-body bgcolor-lightgrey">
        
    <div class="display-full relative">
        <div class="car-datatable mat-elevation-z1">
            <div class="table-responsive relative">
                <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg uc-table-responsive">

                    <ng-container matColumnDef="Name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Name </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Name"> {{row.name}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Quantity">
                        <mat-header-cell *matHeaderCellDef data-role="auto"> Quantity </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Quantity"> {{row.quantity }} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="in Stock">
                            <mat-header-cell *matHeaderCellDef data-role="auto"> In Stock </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="auto" data-val="In Stock"> {{row.inStock }} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="add_date">
                            <mat-header-cell *matHeaderCellDef data-role="auto"> Added on </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="auto" data-val="Added on"> {{row.add_date }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Actions">
                        <mat-header-cell *matHeaderCellDef data-role="button"> Actions </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="button">
                           <button  (click)="deletePart(row)" title="Delete Part from Queue" class="btn-view mat-raised-button mat-accent" type="button">Delete</button>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
                <div class="padding-15 text-center" [style.display]="getTotalRecords() < 1 ? '' : 'none'">
                     No Records Available
            </div>
        </div>
        <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
            <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="options.limit" [pageSizeOptions]="[5, 10,25, 100]" (page)="paginateByLimit($event)" >
            </mat-paginator>

            <div class="mat-paginator table-paging">
                <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
                <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                        <mat-icon>navigate_before</mat-icon>
                </button>
                <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                        <mat-icon>navigate_next</mat-icon>
                </button>
            </div>
        </div>
    </div>
    </div>
  </div>
</div>