<h2 mat-dialog-title>Vehicle Inspection Report</h2>
<mat-dialog-content>
    <form [formGroup]="report" (ngSubmit)="saveReport()" id="ACDelcoReportForm" class="form-inline col-xs-12" autocomplete="off" style="padding:0;">
        <div class="row checkpoint-section" style="margin:0;">
            <div class="col-sm-12 col-md-4 col-lg-4 form-block text-center color-indicator" style="padding:0px;">
                <p style="background-color:#86c6fe;" class="col-sm-6 col-lg-12"><i class="fa fa-circle fa-fw fa-lg" style="color:green;"></i> Checked And OK</p>
                <label class="col-sm-6 col-lg-12">
                    <mat-checkbox [checked]="isChecked('all_green')" (change)="checkAll($event,'all_green')" class="mat-success check-green">All Green</mat-checkbox>
                </label>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 form-block text-center color-indicator" style="padding:0px;">
                <p style="background-color:#86c6fe;" class="col-sm-6 col-lg-12"><i class="fa fa-circle fa-fw fa-lg" style="color:yellow;"></i> May Require Attention Soon</p>
                <label class="col-sm-6 col-lg-12">
                    <mat-checkbox [checked]="isChecked('all_yellow')" (change)="checkAll($event,'all_yellow')" class="mat-yellow check-yellow">All Yellow</mat-checkbox>
                </label>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 form-block text-center color-indicator" style="padding:0px;">
                <p style="background-color:#86c6fe;" class="col-sm-6 col-lg-12"><i class="fa fa-circle fa-fw fa-lg" style="color:red;"></i> Requires Immediate Attention</p>
                <label class="col-sm-6 col-lg-12">
                    <mat-checkbox color="warn" class="check-red" [checked]="isChecked('all_red')" (change)="checkAll($event,'all_red')">All Red</mat-checkbox>
                </label>
            </div>
        </div>

        <!-- Content of the form -->
        <div class="row" style="margin:15px 0 0 0;">
            <div class="main-engine" style="flex-wrap:wrap;">
                <div class="col-md-6 col-sm-12 col-xs-12 col-content">
                    <div class="text-center col-header">INTERIOR</div>
                    <div class="row">
                        <div class="col-sm-12 col-md-4 text-left">
                            <mat-radio-group style="white-space:nowrap;" class="radio-checkbox vk-report-group" formControlName='interior_ac'>
                                <mat-radio-button name="interior_chk_ac" value="1" class="check-green"></mat-radio-button>
                                <mat-radio-button name="interior_chk_ac" value="2" class="check-yellow"></mat-radio-button>
                                <mat-radio-button name="interior_chk_ac" value="3" color="warn" class="check-red"></mat-radio-button>
                            </mat-radio-group>
                            <span class="margin-0">AC</span>
                        </div>
                        <div class="col-sm-12 col-md-4 text-left">
                            <mat-radio-group style="white-space:nowrap;" class="radio-checkbox vk-report-group" formControlName="interior_lighting">
                                <mat-radio-button name="interior_chk_lighting" value="1" class="check-green"></mat-radio-button>
                                <mat-radio-button name="interior_chk_lighting" value="2" class="check-yellow"></mat-radio-button>
                                <mat-radio-button name="interior_chk_lighting" value="3" color="warn" class="check-red"></mat-radio-button>
                            </mat-radio-group>
                            <span class="margin-0">Lighting</span>
                        </div>
                        <div class="col-sm-12 col-md-4 text-left">
                            <mat-radio-group style="white-space:nowrap;" class="radio-checkbox vk-report-group" formControlName="interior_heating">
                                <mat-radio-button name="interior_chk_heating" value="1" class="check-green"></mat-radio-button>
                                <mat-radio-button name="interior_chk_heating" value="2" class="check-yellow"></mat-radio-button>
                                <mat-radio-button name="interior_chk_heating" value="3" color="warn" class="check-red"></mat-radio-button>
                            </mat-radio-group>
                            <span class="margin-0">Heating</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-xs-12 col-content">
                  <div class="text-center col-header">EXTERIOR</div>
                    <div class="row">
                        <div class="col-sm-12 col-md-4 text-left">
                            <mat-radio-group class="radio-checkbox" formControlName="exterior_headlights">
                                <mat-radio-button name="exterior_chk_headlights" value="1" class="check-green"></mat-radio-button>
                                <mat-radio-button name="exterior_chk_headlights" value="2" class="check-yellow"></mat-radio-button>
                                <mat-radio-button name="exterior_chk_headlights" value="3" color="warn" class="check-red"></mat-radio-button>
                            </mat-radio-group>
                            <span class="margin-0">Head Lights</span>
                        </div>
                        <div class="col-sm-12 col-md-4 text-left">
                            <mat-radio-group class="radio-checkbox" formControlName="exterior_brakelights">
                                <mat-radio-button name="exterior_chk_brakelights" value="1" class="check-green"></mat-radio-button>
                                <mat-radio-button name="exterior_chk_brakelights" value="2" class="check-yellow"></mat-radio-button>
                                <mat-radio-button name="exterior_chk_brakelights" value="3" color="warn" class="check-red"></mat-radio-button>
                            </mat-radio-group>
                            <span class="margin-0">Brake Lights</span>
                        </div>
                        <div class="col-sm-12 col-md-4 text-left">
                            <mat-radio-group class="radio-checkbox" formControlName="exterior_turnsignals">
                                <mat-radio-button name="exterior_chk_turnsignals" value="1" class="check-green"></mat-radio-button>
                                <mat-radio-button name="exterior_chk_turnsignals" value="2" class="check-yellow"></mat-radio-button>
                                <mat-radio-button name="exterior_chk_turnsignals" value="3" color="warn" class="check-red"></mat-radio-button>
                            </mat-radio-group>
                            <span class="margin-0">Turn Signals</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin:15px 0 0 0;">
            <div class="main-engine" style="flex-wrap:wrap;">
                <div class="col-md-3 col-sm-12 col-xs-12 col-content">
                    <div class="text-center col-header">WIPER BLADES</div>
                    <div class="row text-center margin-bottom-30">

                        <img src="assets/img/wiperblades.png" style="">
                    </div>
                    <div class="row">
                        <div class="col-xs-6 text-center">
                            <mat-radio-group class="radio-checkbox vk-report-group" formControlName='wiperblades_lf'>
                                <p><mat-radio-button name="wiperblades_chk_lf" value="1"  class="check-green">&nbsp;</mat-radio-button></p>
                                <p class="relative">
                                    <span class="left-lf">LF</span>
                                <mat-radio-button name="wiperblades_chk_lf" value="2"  class="check-yellow">&nbsp;</mat-radio-button>
                                </p>
                                <p>
                                <mat-radio-button name="wiperblades_chk_lf" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                                </p>
                            </mat-radio-group>
                        </div>
                        <div class="col-xs-6 text-center">
                            <mat-radio-group class="radio-checkbox vk-report-group" formControlName='wiperblades_rf'>
                                <p><mat-radio-button name="wiperblades_chk_rf" value="1" class="check-green">&nbsp;</mat-radio-button></p>
                                <p class="relative">
                                <mat-radio-button name="wiperblades_chk_rf" value="2" class="check-yellow">&nbsp;</mat-radio-button>
                                <span class="right-rf">RF</span></p>
                                <p><mat-radio-button name="wiperblades_chk_rf" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button></p>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="padding-top-15">
                        <p>
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='wiperblades_rear'>
                            <mat-radio-button name="wiperblades_chk_rear" value="1" class="check-green">&nbsp;</mat-radio-button>
                            <mat-radio-button name="wiperblades_chk_rear" value="2" class="check-yellow">&nbsp;</mat-radio-button>
                            <mat-radio-button name="wiperblades_chk_rear" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                        </mat-radio-group>
                        <strong>Rear</strong> (if applicable)
                        </p>
                        <p>
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='wiperblades_windshield_condition'>
                            <mat-radio-button name="wiperblades_chk_windshield_cond" value="1" class="check-green">&nbsp;</mat-radio-button>
                            <mat-radio-button name="wiperblades_chk_windshield_cond" value="2" class="check-yellow">&nbsp;</mat-radio-button>
                            <mat-radio-button name="wiperblades_chk_windshield_cond" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                        </mat-radio-group>
                        Windshield condition
                        </p>
                    </div>
                    <div class="padding-top-15">
                        <p>
                        <mat-checkbox color="warn" Name="wiperblades_chk_cracks" formControlName="wiperblades_cracks" value="1" labelPosition="before">Cracks</mat-checkbox>
                        <mat-checkbox color="warn" Name="wiperblades_chk_cracks" formControlName="wiperblades_chips" value="1" labelPosition="before" class="margin-left-30">Chips</mat-checkbox>
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 col-content">
                    <div class="text-center col-header">CHECK TIRES AND TREAD DEPTH</div>
                    <div class="row text-center">(Check body condition)</div>
                    <div class="row">
                        
                        <div class="col-sm-3" style="padding-right:0;padding-left:10px;">
                        <div class="alignment-wrap">
                                <p class="text-center"><strong>Left Front</strong></p>
                                <p>PSI @: <input type="text" class="form-control tires-textbox tires_lf_psi_at" formControlName="tires_lf_psi_at" maxlength="6"></p>
                                <p>Set to: <input type="text" class="form-control tires-textbox tires_lf_psi_setto" formControlName="tires_lf_psi_setto" maxlength="6">PSI</p>
                            </div>
                            <div class="alignment-wrap">
                                <p class="text-center"><strong>Left Rear</strong></p>
                                <p>PSI @: <input type="text" class="form-control tires-textbox tires_lr_psi_at" formControlName="tires_lr_psi_at" maxlength="6"></p>
                                <p>Set to: <input type="text" class="form-control tires-textbox tires_lr_psi_setto" formControlName="tires_lr_psi_setto" maxlength="6">PSI</p>
                            </div>
                        </div>
                        <div class="col-sm-6 text-center" style="padding:0px;">
                            <div style="padding:8px 12px; border:2px solid #555555;">
                                <img src="assets/img/car-body.png" style="width:100%;">
                            </div>
                            <p>(Check lamps)</p>
                        </div>
                        <div class="col-sm-3" style="padding-right:0;padding-left:10px;">
                            <div class="alignment-wrap">
                                <p class="text-center"><strong>Right Front</strong></p>
                                <p>PSI @: <input type="text" class="form-control tires-textbox tires_rf_psi_at" formControlName="tires_rf_psi_at" maxlength="6"></p>
                                <p>Set to: <input type="text" class="form-control tires-textbox tires_rf_psi_setto" formControlName="tires_rf_psi_setto" maxlength="6">PSI</p>
                            </div>
                            <div class="alignment-wrap">
                                <p class="text-center"><strong>Right Rear</strong></p>
                                <p>PSI @: <input type="text" class="form-control tires-textbox tires_rr_psi_at" formControlName="tires_rr_psi_at" maxlength="6"></p>
                                <p>Set to: <input type="text" class="form-control tires-textbox tires_rr_psi_setto" formControlName="tires_rr_psi_setto" maxlength="6">PSI</p>
                            </div>
                        </div>
                    </div>
                    <div class="row text-center padding-top-10">
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='tires_pressure'>
                            <mat-radio-button name="tires_chk_pressure" value="1" class="check-green">&nbsp;</mat-radio-button>
                            <mat-radio-button name="tires_chk_pressure" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                        </mat-radio-group>
                        Tire Pressure Monitoring System
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <div style="margin:15px 0 0;"><strong>Rotation</strong></div>
                            <p style="margin:0;" class="tire-check-label">
                                <label>
                                    <mat-checkbox Name="tires_chk_rotation" formControlName="tires_rotation1" value="1" >Needed</mat-checkbox>
                                </label>
                            </p>
                            <p class="tire-check-label"><label><mat-checkbox Name="tires_chk_rotation" formControlName="tires_rotation2" value="2" >performed</mat-checkbox></label></p>
                        </div>
                        <div class="col-sm-4">
                            <p style="margin:15px 0 0;"><strong>Alignment</strong></p>
                            <p style="margin:0;" class="tire-check-label"><label><mat-checkbox Name="tires_chk_alignment" formControlName="tires_alignment1" value="1">Needed</mat-checkbox></label></p>
                            <p class="tire-check-label"><mat-checkbox Name="tires_chk_alignment" formControlName="tires_alignment2" value="2">Performed</mat-checkbox></p>
                        </div>
                        <div class="col-sm-4">
                            <p style="margin:15px 0 0;"><strong>Balance</strong></p>
                            <p style="margin:0;" class="tire-check-label"><label><mat-checkbox Name="tires_chk_balance" formControlName="tires_balance1" value="1">Needed</mat-checkbox></label></p>
                            <p class="tire-check-label"><mat-checkbox Name="tires_chk_balance" formControlName="tires_balance2" value="2">Performed</mat-checkbox></p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4 col-xs-3 wear-pattern-wrap">
                            <mat-checkbox Name="tires_chk_wearpattern_left"  formControlName="wear_pattern_damage_left1"  value="1" labelPosition="before">LF</mat-checkbox>
                            <mat-checkbox Name="tires_chk_wearpattern_left"  formControlName="wear_pattern_damage_left2" value="2" labelPosition="before" class="margin-left-10">LR</mat-checkbox>
                        </div>
                        <div class="col-sm-4 col-xs-5 wear-pattern-wrap" style="padding:0;">
                            <p style="margin:0;">Wear Pattern/Damage</p>
                        </div>
                        <div class="col-sm-4 col-xs-3 wear-pattern-wrap">
                            <p style="margin:0;">
                            <mat-checkbox Name="tires_chk_wearpattern_right" formControlName="wear_pattern_damage_right1" value="1" labelPosition="before">RF</mat-checkbox>
                            <mat-checkbox Name="tires_chk_wearpattern_right" formControlName="wear_pattern_damage_right2" value="2" labelPosition="before" class="margin-left-10">RR</mat-checkbox>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12 col-content">
                   <div class=" text-center col-header">CHECK BATTERY</div>
                    <div class="text-center margin-bottom-30 col-sm-6 col-md-12">
                        <img src="assets/img/battery.png" style="width:100%; max-width:170px;" class="">
                    </div>
                    <div class="margin-top-30 col-sm-6 col-md-12">
                    <p>
                    <mat-radio-group class="radio-checkbox vk-report-group" formControlName='battery_charge'>
                        <mat-radio-button name="battery_chk_charge" value="1" class="check-green">&nbsp;</mat-radio-button>
                        <mat-radio-button name="battery_chk_charge" value="2" class="check-yellow">&nbsp;</mat-radio-button>
                        <mat-radio-button name="battery_chk_charge" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                    </mat-radio-group> Battery charge
                    </p>
                    

                    <p>
                    <mat-radio-group class="radio-checkbox vk-report-group" formControlName='battery_condition'>
                        <mat-radio-button name="battery_chk_condition" value="1" class="check-green">&nbsp;</mat-radio-button>
                        <mat-radio-button name="battery_chk_condition" value="2" class="check-yellow">&nbsp;</mat-radio-button>
                        <mat-radio-button name="battery_chk_condition" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                    </mat-radio-group>Battery condition

                    </p>
                    
                    <p>
                    <mat-radio-group class="radio-checkbox vk-report-group" formControlName='battery_cables'>
                        <mat-radio-button name="battery_chk_cables" value="1" class="check-green">&nbsp;</mat-radio-button>
                        <mat-radio-button name="battery_chk_cables" value="2" class="check-yellow">&nbsp;</mat-radio-button>
                        <mat-radio-button name="battery_chk_cables" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                    </mat-radio-group>Battery cables and connections

                    </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" style="margin:15px 0 0 0;">
            <div class="main-engine" style="flex-wrap:wrap;">
                <div class="col-md-6 col-sm-12 col-xs-12 col-content hidden-xs">
                    <div class="text-center col-header margin-bottom-5">CHECK FLUID LEVELS</div>
                    <div class="row" style="border-bottom:1px solid #888888;padding-top:8px;">
                        <div class="col-sm-1"><p style="margin-bottom:8px;"><strong>OK</strong></p></div>
                        <div class="col-sm-5"><p style="margin-bottom:8px;"><strong>FILLED</strong></p></div>
                        <div class="col-sm-6 text-center"><p style="margin-bottom:8px;"><strong>REQUIRES ATTENTION</strong></p></div>
                    </div>
                    <div class="row" style="border-bottom:1px solid #888888;padding-top:8px;">
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='fluids_engine_oil'>
                            <div class="col-sm-1">
                                <mat-radio-button name="fluids_chk_engineoil" value="1" class="check-green">&nbsp;</mat-radio-button>
                            </div>
                            <div class="col-sm-9">
                                <p style="margin-bottom:8px;"><mat-radio-button name="fluids_chk_engineoil" value="2" class="check-yellow">Engine oil</mat-radio-button></p>
                            </div>
                            <div class="col-sm-2">
                                <mat-radio-button name="fluids_chk_engineoil" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                            </div>
                        </mat-radio-group>
                    </div>
                    <div class="row" style="border-bottom:1px solid #888888;padding-top:8px;">
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='fluids_brake_fluid_reservoir'>
                            <div class="col-sm-1">
                                <mat-radio-button name="fluids_chk_brakereservoir" value="1" class="check-green">&nbsp;</mat-radio-button>
                            </div>
                            <div class="col-sm-9">
                                <p style="margin-bottom:8px;"><mat-radio-button name="fluids_chk_brakereservoir" value="2" class="check-yellow">Brake fluid reservoir</mat-radio-button></p>
                            </div>
                            <div class="col-sm-2">
                                <mat-radio-button name="fluids_chk_brakereservoir" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                            </div>
                        </mat-radio-group>
                    </div>
                    <div class="row" style="border-bottom:1px solid #888888;padding-top:8px;">
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='fluids_transmission'>
                            <div class="col-sm-1">
                                <mat-radio-button name="fluids_chk_transmission" value="1" class="check-green">&nbsp;</mat-radio-button>
                            </div>
                            <div class="col-sm-9">
                                <p style="margin-bottom:8px;"><mat-radio-button name="fluids_chk_transmission" value="2" class="check-yellow">Transmission (if equipped /wdipstick)</mat-radio-button></p>
                            </div>
                            <div class="col-sm-2">
                                <mat-radio-button name="fluids_chk_transmission" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                            </div>
                        </mat-radio-group>
                    </div>
                    <div class="row" style="border-bottom:1px solid #888888;padding-top:8px;">
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='fluids_coolant_recovery'>
                            <div class="col-sm-1">
                                <mat-radio-button name="fluids_chk_coolantrecovery" value="1" class="check-green">&nbsp;</mat-radio-button>
                            </div>
                            <div class="col-sm-9">
                                <p style="margin-bottom:8px;"><mat-radio-button name="fluids_chk_coolantrecovery" value="2" class="check-yellow">Coolant recovery reservoir</mat-radio-button></p>
                            </div>
                            <div class="col-sm-2">
                                <mat-radio-button name="fluids_chk_coolantrecovery" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                            </div>
                        </mat-radio-group>
                    </div>
                    <div class="row" style="border-bottom:1px solid #888888;padding-top:8px;">
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='fluids_power_steering'>
                            <div class="col-sm-1">
                                <mat-radio-button name="fluids_chk_powersteering" value="1" class="check-green">&nbsp;</mat-radio-button>
                            </div>
                            <div class="col-sm-9">
                                <p style="margin-bottom:8px;"><mat-radio-button name="fluids_chk_powersteering" value="2" class="check-yellow">Power steering</mat-radio-button></p>
                            </div>
                            <div class="col-sm-2">
                                <mat-radio-button name="fluids_chk_powersteering" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                            </div>
                        </mat-radio-group>
                    </div>
                    <div class="row" style="border-bottom:1px solid #888888;padding-top:8px;">
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='fluids_windshield_washer'>
                            <div class="col-sm-1">
                                <mat-radio-button name="fluids_chk_windshieldwasher" value="1" class="check-green">&nbsp;</mat-radio-button>
                            </div>
                            <div class="col-sm-9">
                                <p style="margin-bottom:8px;"><mat-radio-button name="fluids_chk_windshieldwasher" value="2" class="check-yellow">Windshield washer</mat-radio-button></p>
                            </div>
                            <div class="col-sm-2">
                                <mat-radio-button name="fluids_chk_windshieldwasher" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                            </div>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 col-content hidden-sm hidden-md hidden-lg">
                    <div class="text-center col-header margin-bottom-5">CHECK FLUID LEVELS</div>
                     <div class="row" style="border-bottom:1px solid #888888;padding-top:8px;">
                     <div class="col-sm-12"><p style="margin-bottom:8px;"><strong>Engine oil</strong></p></div>
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='fluids_engine_oil'>
                            <div class="col-sm-1">
                                <p style="margin-bottom:8px;"><mat-radio-button name="fluids_chk_engineoil" value="1" class="check-green">OK</mat-radio-button></p>
                            </div>
                            <div class="col-sm-9">
                                <p style="margin-bottom:8px;"><mat-radio-button name="fluids_chk_engineoil" value="2" class="check-yellow">FILLED</mat-radio-button></p>
                            </div>
                            <div class="col-sm-2">
                                <p style="margin-bottom:8px;"><mat-radio-button name="fluids_chk_engineoil" value="3" color="warn" class="check-red">REQUIRES ATTENTION</mat-radio-button></p>
                            </div>
                        </mat-radio-group>
                    </div>
                     <div class="row" style="border-bottom:1px solid #888888;padding-top:8px;">
                     <div class="col-sm-12"><p style="margin-bottom:8px;"><strong>Brake fluid reservoir</strong></p></div>
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='fluids_brake_fluid_reservoir'>
                            <div class="col-sm-1">
                                <p style="margin-bottom:8px;"><mat-radio-button name="fluids_chk_brakereservoir" value="1" class="check-green">OK</mat-radio-button></p>
                            </div>
                            <div class="col-sm-9">
                                <p style="margin-bottom:8px;"><mat-radio-button name="fluids_chk_brakereservoir" value="2" class="check-yellow">FILLED</mat-radio-button></p>
                            </div>
                            <div class="col-sm-2">
                                <p style="margin-bottom:8px;"><mat-radio-button name="fluids_chk_brakereservoir" value="3" color="warn" class="check-red">REQUIRES ATTENTION</mat-radio-button></p>
                            </div>
                        </mat-radio-group>
                    </div>
                     <div class="row" style="border-bottom:1px solid #888888;padding-top:8px;">
                     <div class="col-sm-12"><p style="margin-bottom:8px;"><strong>Transmission (if equipped /wdipstick)</strong></p></div>
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='fluids_transmission'>
                            <div class="col-sm-1">
                                <p style="margin-bottom:8px;"><mat-radio-button name="fluids_chk_transmission" value="1" class="check-green">OK</mat-radio-button></p>
                            </div>
                            <div class="col-sm-9">
                                <p style="margin-bottom:8px;"><mat-radio-button name="fluids_chk_transmission" value="2" class="check-yellow">FILLED</mat-radio-button></p>
                            </div>
                            <div class="col-sm-2">
                                <p style="margin-bottom:8px;"></p><mat-radio-button name="fluids_chk_transmission" value="3" color="warn" class="check-red">REQUIRES ATTENTION</mat-radio-button>
                            </div>
                        </mat-radio-group>
                    </div>
                     <div class="row" style="border-bottom:1px solid #888888;padding-top:8px;">
                     <div class="col-sm-12"><p style="margin-bottom:8px;"><strong>Coolant recovery reservoir</strong></p></div>
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='fluids_coolant_recovery'>
                            <div class="col-sm-1">
                                <p style="margin-bottom:8px;"><mat-radio-button name="fluids_chk_coolantrecovery" value="1" class="check-green">OK</mat-radio-button></p>
                            </div>
                            <div class="col-sm-9">
                                <p style="margin-bottom:8px;"><mat-radio-button name="fluids_chk_coolantrecovery" value="2" class="check-yellow">FILLED</mat-radio-button></p>
                            </div>
                            <div class="col-sm-2">
                                <p style="margin-bottom:8px;"><mat-radio-button name="fluids_chk_coolantrecovery" value="3" color="warn" class="check-red">REQUIRES ATTENTION</mat-radio-button></p>
                            </div>
                        </mat-radio-group>
                    </div>
                    <div class="row" style="border-bottom:1px solid #888888;padding-top:8px;">
                    <div class="col-sm-12"><p style="margin-bottom:8px;"><strong>Power steering</strong></p></div>
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='fluids_power_steering'>
                            <div class="col-sm-1">
                                <p style="margin-bottom:8px;"><mat-radio-button name="fluids_chk_powersteering" value="1" class="check-green">OK</mat-radio-button></p>
                            </div>
                            <div class="col-sm-9">
                                <p style="margin-bottom:8px;"><mat-radio-button name="fluids_chk_powersteering" value="2" class="check-yellow">FILLED</mat-radio-button></p>
                            </div>
                            <div class="col-sm-2">
                                <p style="margin-bottom:8px;"><mat-radio-button name="fluids_chk_powersteering" value="3" color="warn" class="check-red">REQUIRES ATTENTION</mat-radio-button></p>
                            </div>
                        </mat-radio-group>
                     </div>
                     <div class="row" style="border-bottom:1px solid #888888;padding-top:8px;">
                        <div class="col-sm-12"><p style="margin-bottom:8px;"><strong>Windshield washer</strong></p></div>
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='fluids_windshield_washer'>
                            <div class="col-sm-1">
                                <p style="margin-bottom:8px;"><mat-radio-button name="fluids_chk_windshieldwasher" value="1" class="check-green">OK</mat-radio-button></p>
                            </div>
                            <div class="col-sm-9">
                                <p style="margin-bottom:8px;"><mat-radio-button name="fluids_chk_windshieldwasher" value="2" class="check-yellow">FILLED</mat-radio-button></p>
                            </div>
                            <div class="col-sm-2">
                                <p style="margin-bottom:8px;"><mat-radio-button name="fluids_chk_windshieldwasher" value="3" color="warn" class="check-red">REQUIRES ATTENTION</mat-radio-button></p>
                            </div>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 col-content">
                    <div class="text-center col-header margin-bottom-15">CHECK BRAKES</div>
                    <div class="text-center margin-bottom-30 col-sm-6 col-md-12">
                        <img src="assets/img/brakes.png" style="width:100%; max-width:170px;" class="">
                    </div>
                    <div class="row" style="margin:0;">
                        <div class="col-sm-6">
                            <p>
                            <mat-radio-group class="radio-checkbox vk-report-group" formControlName='brakes_noise'>
                                <mat-radio-button name="brakes_chk_noise" value="1" class="check-green">&nbsp;</mat-radio-button>
                                <mat-radio-button name="brakes_chk_noise" value="2" class="check-yellow">&nbsp;</mat-radio-button>
                                <mat-radio-button name="brakes_chk_noise" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                            </mat-radio-group>Brake noise
                            </p>
                            <p>
                            <mat-radio-group class="radio-checkbox vk-report-group" formControlName='brakes_excessive_dust'>
                                <mat-radio-button name="brakes_chk_excessivedust" value="1" class="check-green">&nbsp;</mat-radio-button>
                                <mat-radio-button name="brakes_chk_excessivedust" value="2" class="check-yellow">&nbsp;</mat-radio-button>
                                <mat-radio-button name="brakes_chk_excessivedust" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                            </mat-radio-group>Excessive dust
                            </p>
                            <p>
                            <mat-radio-group class="radio-checkbox vk-report-group" formControlName='brakes_front_linings'>
                                <mat-radio-button name="brakes_chk_frontlinings" value="1" class="check-green">&nbsp;</mat-radio-button>
                                <mat-radio-button name="brakes_chk_frontlinings" value="2" class="check-yellow">&nbsp;</mat-radio-button>
                                <mat-radio-button name="brakes_chk_frontlinings" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                            </mat-radio-group>Front linings
                            </p>
                          
                        </div>
                        <div class="col-sm-6 text-left" style="padding-right:0;">
                            <p>
                            <mat-radio-group class="radio-checkbox vk-report-group" formControlName='brakes_hoses'>
                                <mat-radio-button name="brakes_chk_hoses" value="1" class="check-green">&nbsp;</mat-radio-button>
                                <mat-radio-button name="brakes_chk_hoses" value="2" class="check-yellow">&nbsp;</mat-radio-button>
                                <mat-radio-button name="brakes_chk_hoses" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                            </mat-radio-group>Brake hoses
                            </p>
                            <p>
                            <mat-radio-group class="radio-checkbox vk-report-group" formControlName='brakes_steel_line_condition'>
                                <mat-radio-button name="brakes_chk_steelline" value="1" class="check-green">&nbsp;</mat-radio-button>
                                <mat-radio-button name="brakes_chk_steelline" value="2" class="check-yellow">&nbsp;</mat-radio-button>
                                <mat-radio-button name="brakes_chk_steelline" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                            </mat-radio-group>Steel line condition
                            </p>
                            <p>
                                <mat-radio-group class="radio-checkbox vk-report-group" formControlName='brakes_rear_linings'>
                                    <mat-radio-button name="brakes_chk_rearlinings" value="1" class="check-green">&nbsp;</mat-radio-button>
                                    <mat-radio-button name="brakes_chk_rearlinings" value="2" class="check-yellow">&nbsp;</mat-radio-button>
                                    <mat-radio-button name="brakes_chk_rearlinings" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                                </mat-radio-group>Rear linings
                            </p>
                        </div>
                    </div>
                    <div class="row" style="padding-top:10px;border-top:1px solid #888888;">
                        <div class="col-sm-12">
                            <p style="margin:0;padding:0 15px;">
                            <mat-radio-group class="radio-checkbox vk-report-group" formControlName='brakes_parking'>
                                <mat-radio-button name="brakes_chk_parking" value="1" class="check-green">&nbsp;</mat-radio-button>
                                <mat-radio-button name="brakes_chk_parking" value="2" class="check-yellow">&nbsp;</mat-radio-button>
                                <mat-radio-button name="brakes_chk_parking" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                            </mat-radio-group>Parking brake
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" style="margin:15px 0 0 0;">
            <div class="col-sm-12 text-center col-header col-header-2">ADDITIONAL CHECKS</div>
            <div class="col-sm-12" style="border:1px solid #888888;">
                <div class="row" style="border-bottom:1px solid #888888;border-top:0px;padding-top:8px;">
                    <div class="col-sm-12"><p style="margin-bottom:8px;"><strong>Inspect for visible leaks:</strong></p></div>
                </div>
                <div class="row" style="border-bottom:1px solid #888888;padding-top:8px;">
                    <div class="col-sm-12">
                        <p style="margin-bottom:8px;">
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='visible_leaks_fuel_system'>
                            <mat-radio-button name="visibleleaks_chk_fuelsystem" value="1" class="check-green">&nbsp;</mat-radio-button>
                            <mat-radio-button name="visibleleaks_chk_fuelsystem" value="2" class="check-yellow">&nbsp;</mat-radio-button>
                            <mat-radio-button name="visibleleaks_chk_fuelsystem" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                        </mat-radio-group>Fuel system (also including gas cap seating)
                        </p>
                    </div>
                    <div class="col-sm-12">
                        <p style="margin-bottom:8px;">
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='visible_leaks_engine_transmission'>
                            <mat-radio-button name="visibleleaks_chk_enginetrans" value="1" class="check-green">&nbsp;</mat-radio-button>
                            <mat-radio-button name="visibleleaks_chk_enginetrans" value="2" class="check-yellow">&nbsp;</mat-radio-button>
                            <mat-radio-button name="visibleleaks_chk_enginetrans" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                        </mat-radio-group>Engine, transmission, drive axle, transfer case
                        </p>
                    </div>
                    <div class="col-sm-12">
                        <p style="margin-bottom:8px;">
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='visible_leaks_engine_cooling_system'>
                            <mat-radio-button name="visibleleaks_chk_enginecooling" value="1" class="check-green">&nbsp;</mat-radio-button>
                            <mat-radio-button name="visibleleaks_chk_enginecooling" value="2" class="check-yellow">&nbsp;</mat-radio-button>
                            <mat-radio-button name="visibleleaks_chk_enginecooling" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                        </mat-radio-group>Engine cooling system
                        </p>
                    </div>
                    <div class="col-sm-12">
                        <p style="margin-bottom:8px;">
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='visible_leaks_shocks_struts'>
                            <mat-radio-button name="visibleleaks_chk_shocksstruts" value="1" class="check-green">&nbsp;</mat-radio-button>
                            <mat-radio-button name="visibleleaks_chk_shocksstruts" value="2" class="check-yellow">&nbsp;</mat-radio-button>
                            <mat-radio-button name="visibleleaks_chk_shocksstruts" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                        </mat-radio-group>Shocks and struts - also check operation
                        </p>
                    </div>
                </div>
                <div class="row" style="border-bottom:1px solid #888888;padding-top:8px;">
                    <div class="col-sm-12"><p style="margin-bottom:8px;"><strong>Inspect visual condition:</strong></p></div>
                </div>
                <div class="row" style="border-bottom:1px solid #888888;padding-top:8px;">
                    <div class="col-sm-12">
                        <p style="margin-bottom:8px;">
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='visual_condition_belts'>
                            <mat-radio-button name="visual_chk_belts" value="1" class="check-green">&nbsp;</mat-radio-button>
                            <mat-radio-button name="visual_chk_belts" value="2" class="check-yellow">&nbsp;</mat-radio-button>
                            <mat-radio-button name="visual_chk_belts" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                        </mat-radio-group>Belts: engine, accessory, serpentine, and/or V-drive
                        </p>
                    </div>
                    <div class="col-sm-12">
                        <p style="margin-bottom:8px;">
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='visual_condition_hoses'>
                            <mat-radio-button name="visual_chk_hoses" value="1" class="check-green">&nbsp;</mat-radio-button>
                            <mat-radio-button name="visual_chk_hoses" value="2" class="check-yellow">&nbsp;</mat-radio-button>
                            <mat-radio-button name="visual_chk_hoses" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                        </mat-radio-group>Hoses: engine, power steering and HVAC
                        </p>
                    </div>
                    <div class="col-sm-12">
                        <p style="margin-bottom:8px;">
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='visual_condition_air_filters'>
                            <mat-radio-button name="visual_chk_airfilters" value="1" class="check-green">&nbsp;</mat-radio-button>
                            <mat-radio-button name="visual_chk_airfilters" value="2" class="check-yellow">&nbsp;</mat-radio-button>
                            <mat-radio-button name="visual_chk_airfilters" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                        </mat-radio-group>Engine air filter and cabin air filters
                        </p>
                    </div>
                    <div class="col-sm-12">
                        <p style="margin-bottom:8px;">
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='visual_condition_steering'>
                            <mat-radio-button name="visual_chk_steering" value="1" class="check-green">&nbsp;</mat-radio-button>
                            <mat-radio-button name="visual_chk_steering" value="2" class="check-yellow">&nbsp;</mat-radio-button>
                            <mat-radio-button name="visual_chk_steering" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                        </mat-radio-group>Steering components and steering linkage
                        </p>
                    </div>
                    <div class="col-sm-12">
                        <p style="margin-bottom:8px;">
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='visual_condition_cvdrive'>
                            <mat-radio-button name="visual_chk_cvdrive" value="1" class="check-green">&nbsp;</mat-radio-button>
                            <mat-radio-button name="visual_chk_cvdrive" value="2" class="check-yellow">&nbsp;</mat-radio-button>
                            <mat-radio-button name="visual_chk_cvdrive" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                        </mat-radio-group>CV drive axle boots or driveshafts and U-joints
                        </p>
                    </div>
                    <div class="col-sm-12">
                        <p style="margin-bottom:8px;">
                        <mat-radio-group class="radio-checkbox vk-report-group" formControlName='visual_condition_exhaust'>
                            <mat-radio-button name="visual_chk_exhaust" value="1" class="check-green">&nbsp;</mat-radio-button>
                            <mat-radio-button name="visual_chk_exhaust" value="2" class="check-yellow">&nbsp;</mat-radio-button>
                            <mat-radio-button name="visual_chk_exhaust" value="3" color="warn" class="check-red">&nbsp;</mat-radio-button>
                        </mat-radio-group>Exhaust system components
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action">
    <div class="flexBtns2">
        <button mat-raised-button mat-dialog-close>Close</button>
        <!-- Can optionally provide a result for the closing dialog. -->
        <button mat-raised-button  (click)="saveReport()" color="accent">Save Changes</button>
    </div>
</mat-dialog-actions>
