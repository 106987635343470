<!-- header -->
<div mat-dialog-title class="relative">
    Nexpart Labor Settings
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
        <mat-icon class="notranslate close-icon-lg" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
</div>
<!-- BODY -->
<mat-dialog-content class="nextPart-setting-dialog"> 
    <div class="row">
        <div class="form-group col-sm-12">
             <div>
                <mat-checkbox [(ngModel)]="chkDisplayLaborTextInDescription" [ngModelOptions]="{standalone: true}" (change)="handleLaborTextAndDesc()">Pull labor notes into "Description of Work" in job recommendation</mat-checkbox>
             </div>
             <div class="space-notes-display">
                 <span>
                     <mat-checkbox [(ngModel)]="chkLaborTitleInDescription" [ngModelOptions]="{standalone: true}" (change)="handleLaborTitleAndNotes()">Display Title</mat-checkbox><br>
                     <mat-checkbox [(ngModel)]="chkLaborNotesInDescription" [ngModelOptions]="{standalone: true}" (change)="handleLaborTitleAndNotes()">Display Notes</mat-checkbox><br>
                     <mat-checkbox [(ngModel)]="chkLaborNotesPrefixInDescription" [ngModelOptions]="{standalone: true}" (change)="handleLaborTitleAndNotes()">Include "[NXT]:" Prefix</mat-checkbox>
                     <mat-error class="nextPart-error" *ngIf="showErrMsgForChkDisplayLaborText">Please select at least one option from Display Title or Display Notes.</mat-error>
                </span>
             </div>
        </div>
    </div>
</mat-dialog-content>
<!-- footer -->
<mat-dialog-actions align="end">
    <div class="display-full margin-bottom-5">
        <div class="col-sm-12 text-right">
            <button type="button" mat-raised-button class="mat-raised-button margin-right-10" color="accent" title="OK" (click)="saveNexpartSetting()">
                Save
            </button>
            <button mat-raised-button mat-dialog-close class="font-bold" title="Close" id="close">Close</button>
        </div>
    </div>
</mat-dialog-actions>