<div class="panel panel-primary" id="mainCompotents">
    <div class="panel-heading padding-left-30">
        <h3 class="panel-title">Subscribed Customers List</h3>
    </div>
    <div class="panel-body bgcolor-lightgrey">

        <div class="car-datatable uc-datatable mat-elevation-z1">
            <div class="table-responsive relative">
                <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg uc-table-responsive">

                    <!-- Color Column -->
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="name"> Name </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="name" data-val="Name"> {{row.name}} </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="email">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Email </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Email"> <a class="text-black" href="mailto:{{row.mobile}}">{{(row.email)}}</a> </mat-cell>
                    </ng-container>

                    <!-- Color Colu`mn -->
                    <ng-container matColumnDef="subscription_type">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Subscription Type </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Subscription Type">{{row.subscription_type}}</mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="duration">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Duration (months) </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Duration (months)">{{ row.duration }}</mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="amount">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="price"> Amount </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="price" data-val="Amount"> {{row.amount}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="subscribed_on">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="date"> Subscribed On </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="date" data-val="Subscribed On"> {{row.subscribed_on}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="expiry_on">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="date"> Expiry On </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="date" data-val="Expiry On"> {{row.expiry_on}} </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
                <div class="padding-15 text-center"
                     [style.display]="getTotalRecords() < 1 ? '' : 'none'">
                     No Records Available
            </div>
        </div>
        <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
            <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="options.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event)" >
            </mat-paginator>

            <div class="mat-paginator table-paging">
                <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
                <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                        <mat-icon>navigate_before</mat-icon>
                </button>
                <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                        <mat-icon>navigate_next</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
</div>
