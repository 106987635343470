<form class="form-horizontal form-control-normal milesOut-form" autocomplete="off" [formGroup]="milesForm">
  <div mat-dialog-title class="relative header">
    <h4 class="title margin-0">Miles Out <span class="sr-id"> - #{{miles.option.fullRequestId}}</span></h4>
    <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
      <mat-icon class="close-icon-lg">close</mat-icon>
    </button>
  </div>
  <mat-dialog-content class="milesout-content">
    <div class="content-section">
      <div class="message-section" *ngIf="!miles.returnData">
        <div [innerHTML]="confTxt" class="hide"></div>
        <div [innerHTML]="note" class="hide"></div>
      </div>
      <div class="message-section" *ngIf="miles.returnData">
        <div [innerHTML]="miles.note"></div>
      </div>
      <div class="note-section row">
        <p><strong>Note:</strong> Below actions will complete the service request, generate the invoice and set a reminder for next service.</p>
      </div>
      <div class="row equal-height">
        <div class="col-sm-6 padding-sm-0">
          <div class="action-section">
            <div class="section-title">Completing This Action:</div>
            <ol class="action-list">
              <li>This service request will be completed.</li>
              <li>All timers will be stopped.</li>
              <li>The invoice will be generated.</li>
            </ol>
            <label class="control-label"><strong>Miles Out</strong></label>
            <mat-form-field class="btn-block car-input miles-out-input" floatPlaceholder="never" appearance="fill">
              <input matInput type="text" maxlength='10' placeholder="Enter miles out" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)" class="form-control input-lg custom-form-input btn-block" formControlName="miles_out" />
              <mat-error *ngIf="milesForm.controls.miles_out.hasError('required')">
                This field cannot be empty
              </mat-error>
            </mat-form-field>
            <div class="help-block margin-top-0">Vehicle’s current mileage is: {{carDetail.distance_covered}}</div>
          </div>
        </div>
        <div class="col-sm-6 padding-sm-0" *ngIf="(miles.isWalkinCustomer == '0' && miles.isWalkinVehicle == '0') && miles.permissionObj.dfsReminders == 1">
          <div class="next-service-section" *ngIf="!showExistingReminderSection">
            <div class="reminder-banner-wrap" *ngIf="showExistingReminderBanner">
              <div class="reminder-banner" (click)="handleToSwitchRemindersScreen(1)">
                <i class="fa-solid fa-arrow-left"></i> Existing Reminder
              </div>
            </div>
            <div class="section-title">Next Service Reminder:</div>
            <ol class="action-list">
              <li>Enter 'Miles' when the vehicle's next service is scheduled.</li>
              <li>Enter 'Date' when the vehicle's next service is scheduled.</li>
            </ol>
            <label class="control-label"><strong>Schedule by Miles
              </strong></label>
            <mat-form-field class="btn-block car-input miles-input" floatPlaceholder="never" appearance="fill">
              <input matInput [readonly]="!permissionObj.dfsPermission" maxlength='10' placeholder="Enter schedule by miles"
                class="form-control input-lg custom-form-input btn-block" formControlName="schedule_by_miles"
                (keypress)="validateNumbersWithDecimal($event)" (paste)="validateFieldOnPaste('schedule_by_miles')"
                [ngClass]="!permissionObj.dfsPermission ? 'disabled':''"
                />
                <mat-error *ngIf="milesForm.get('schedule_by_miles').status == 'INVALID'">
                  Please enter only numbers or upto one decimal place
              </mat-error>
            </mat-form-field>
            <div class="help-block miles-input-helpText">Default miles for reminder: {{miles.existingReminderObj.standardMilage || 'N/A'}}, Vehicle mileage:
              {{carDetail.distance_covered||'N/A'}}</div>
            <label class="control-label"><strong>Schedule by Date
              </strong></label>
            <mat-form-field class="btn-block car-input car-datePicker" floatPlaceholder="never" appearance="fill">
              <input [disabled]="!permissionObj.dfsPermission" matInput maxlength='14' placeholder="Enter schedule by date"
                class="form-control input-lg custom-form-input btn-block" [matDatepicker]="filerPicker"
                [(ngModel)]="scheduleByDate" [min]="minDate" [ngModelOptions]="{standalone: true}" onkeydown="return false;"
                [ngClass]="!permissionObj.dfsPermission ? 'disabled':''"
                >
              <mat-datepicker-toggle matSuffix [for]="filerPicker"></mat-datepicker-toggle>
              <mat-datepicker #filerPicker [touchUi]="true"></mat-datepicker>
            </mat-form-field>
            <div class="help-block">Default days for reminder: {{miles.existingReminderObj.standardDays || 'N/A'}}</div>
            <mat-checkbox *ngIf="permissionObj.dfsPermission" (change)="handleDoNotSetReminder($event.checked)">Do not set reminder</mat-checkbox>
          </div>
          <div class="next-service-section" *ngIf="showExistingReminderSection">
            <div class="section-title">Next Service Reminder:</div>
            <div class="existing-reminder-details">
              <p class="existing-reminder-helpText">A reminder already exists on this vehicle. Below are the details:</p>
              <p><strong>Schedule by Miles:</strong> {{miles.existingReminderObj.existingReminder.schedule_by_miles || 'N/A'}}</p>
              <p><strong>Schedule by Date:</strong> {{convertDateFormat(miles.existingReminderObj.existingReminder.schedule_by_date || 'N/A')}}
              </p>
        
              <div class="radio-group-wrap">
                <mat-radio-group aria-label="Select reminder option">
                  <mat-radio-button class="mat-radio-button" value="existing" checked>
                    Keep the existing reminder
                  </mat-radio-button>
                  <mat-radio-button class="mat-radio-button" value="new" (change)="handleToSwitchRemindersScreen(2)">
                    Set a new reminder
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 padding-sm-0" *ngIf="(miles.isWalkinCustomer == '1' || miles.isWalkinVehicle == '1') && miles.permissionObj.dfsReminders == 1">
          <div class="next-service-section walkin">
            <div class="section-title">Next Service Reminder:</div>
            <div class="reminder-message">
              <p>Reminders are not applicable to walkin profile.</p>
              <p>Please change customer/vehicle profile in order to set next service reminder.</p>
            </div>
          </div>
        </div>
        <div class="col-sm-6 padding-0" *ngIf="miles.permissionObj.dfsReminders == 0">
          <div class="next-service-section next-service-alert-section">
            <div class="section-title">Next Service Reminder:</div>
            <app-alert-warning [addonPayloadValue]="gc.ADD_ONS_OBJ.dfsReminders"></app-alert-warning>
          </div>
        </div>
      </div>
      <div class="row checkbox-row" *ngIf="(miles.isWalkinCustomer == '0' || miles.isWalkinVehicle == '0')">
        <div class="col-sm-6 padding-sm-0">
          <div class="checkbox-section">
            <div class="checkbox-wrap" *ngIf="miles.permissionObj.privateReview == 1">
              <mat-checkbox class="mat-checkbox" (change)="handleDoNotSetReviewURl($event.checked)" [checked]="milesForm.value.review_url_chk=='1'" [disabled]="!permissionObj.pvtReviewPermission">Review Url</mat-checkbox>
              &nbsp;<i class="fa fa-pencil text-red" (click)="openReviewAndPaymentDialog(0)" *ngIf="permissionObj.pvtReviewPermission"></i>
            </div>
            <div class="checkbox-wrap" *ngIf="miles.permissionObj.externalPayment == 1">
              <mat-checkbox class="mat-checkbox" (change)="handleDoNotSetPaymentURl($event.checked)" [checked]="milesForm.value.payment_url_chk=='1'" [disabled]="!permissionObj.extPaymentPermission">Payment Url</mat-checkbox>
              &nbsp;<i class="fa fa-pencil text-red" *ngIf="permissionObj.extPaymentPermission" (click)="openReviewAndPaymentDialog(1)"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="row diagnostic-charges-section" *ngIf="showDiagnosticCheckbox">
        <div class="col-sm-12 diagnostic-section">
          <mat-checkbox (change)="diagnosticForm($event)">Diagnostic Charges</mat-checkbox>
        </div>
      </div>
      <div class="row vehicle-evaluation-section" *ngIf="milesForm.value.is_diagnostic==1">
        <div class="col-sm-12 padding-sm-0">
          <label class="sub-title"><strong>Vehicle Evaluation:</strong></label>
          <div class="genericJob-section">
            <div class="form-group row">
              <label class="col-sm-3 control-label">Generic Job(s)</label>
              <div class="col-sm-9">
                <div class="input-group diagnostic-form">
                  <input class="car-input" matInput ngui-auto-complete (valueChanged)="getSelectedOptionForGj($event)" [source]="observableSourceforGJ" [list-formatter]="myListFormatter" value-property-name="label" placeholder="Enter Generic Job" (click)="setReturnFalse()" id="new-gj-ac" class="form-control" />
                  <button type="button" color="accent" class="font-bold mat-raised-button mat-accent add-btn" (click)="addNewGJ($event)">Add</button>
                </div>
                <div class="help-block">Click "Add" after selecting a generic job.</div>
                <mat-form-field class="car-input hide">
                  <input type="text" placeholder="Quantity" aria-label="Quantity" id="new-gj-qty" class="form-control input-lg custom-form-input btn-block" matInput maxlength="5" (keypress)="onlyNumericKey($event)" />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="generic-job-details" *ngFor="let job of viewingJSON; let a = index">
            <div class="row">
             <div class="col-sm-3"></div>
             <div class="col-sm-9">
              <div class="row job-details-row">
                <div class="col-sm-6 job-name margin-sm-bottom-10">{{viewingJSON[a].name}}</div>
                <div class="col-sm-6 job-details">
                  <div class="row margin-sm-top-5">
                    <div class="col-xs-6 col-sm-5 pprice"><span *ngIf="showCostPrice">${{viewingJSON[a].price}}</span></div>
                    <div class="col-xs-3 col-sm-3 pquantity text-right">{{viewingJSON[a].quantity}}</div>
                    <div class="col-xs-3 col-sm-4 text-right">
                      <button type="button" title="Delete" class="btn btn-xs btn-link text-red padding-right-0" (click)="removeGJ(a)">
                        <i class="fa fa-trash fa-lg"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
             </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 control-label"><strong>Estimated Time</strong></label>
            <div class="col-sm-9">
              <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                <input matInput type="text" maxlength='14' (change)="addTime($event)" placeholder="Enter estimated time" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)" class="form-control input-lg custom-form-input btn-block" formControlName="labor_time" />
              </mat-form-field>
            </div>
          </div>
          <div class="form-group row" [class.hide]="!showCostPrice">
            <label class="col-sm-3 control-label"><strong>Labor Price</strong></label>
            <div class="col-sm-9">
              <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                <input matInput type="text" maxlength='14' (change)="addLabor($event)" placeholder="Enter labor price" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)" class="form-control input-lg custom-form-input btn-block" formControlName="labor_charge" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row vehicle-evaluation-section" *ngIf="milesForm.value.is_diagnostic==1">
        <div class="col-sm-12 padding-sm-0">
             <label class="sub-title"><strong>Vehicle Evaluation:</strong></label>
            <div class="genericJob-section">
              <label class="control-label">Generic Job(s)</label>
              <div class="input-group diagnostic-form">
                <input class="car-input" matInput ngui-auto-complete (valueChanged)="getSelectedOptionForGj($event)" [source]="observableSourceforGJ" [list-formatter]="myListFormatter" value-property-name="label" placeholder="Enter Generic Job" (click)="setReturnFalse()" id="new-gj-ac" class="form-control" />
                <button type="button" color="accent" class="font-bold mat-raised-button mat-accent add-btn" (click)="addNewGJ($event)">Add</button>
              </div>
              <div class="help-block">Click "Add" after selecting a generic job.</div>
              <mat-form-field class="car-input hide">
                <input type="text" placeholder="Quantity" aria-label="Quantity" id="new-gj-qty" class="form-control input-lg custom-form-input btn-block" matInput maxlength="5" (keypress)="onlyNumericKey($event)" />
              </mat-form-field>
            </div>
            <div class="generic-job-details" *ngFor="let job of viewingJSON; let a = index">
              <div class="job-name">{{viewingJSON[a].name}}</div>
              <div class="job-details">
                <span class="job-price" *ngIf="showCostPrice">${{viewingJSON[a].price}}</span>
                <span class="job-quantity">{{viewingJSON[a].quantity}}</span>
                <button type="button" title="Delete" class="btn btn-xs btn-link text-red" (click)="removeGJ(a)">
                  <i class="fa fa-trash fa-lg"></i>
                </button>
              </div>
            </div>
            <label class="control-label"><strong>Estimated Time</strong></label>
            <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
              <input matInput type="text" maxlength='14' (change)="addTime($event)" placeholder="Enter estimated time" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)" class="form-control input-lg custom-form-input btn-block" formControlName="labor_time" />
            </mat-form-field>
            <label class="control-label" [class.hide]="!showCostPrice"><strong>Labor Price</strong></label>
            <mat-form-field class="btn-block car-input" [class.hide]="!showCostPrice">
              <input matInput type="text" maxlength='14' (change)="addLabor($event)" placeholder="" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)" class="form-control input-lg custom-form-input btn-block" formControlName="labor_charge" />
            </mat-form-field>
        </div>
      </div> -->
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="inquiry-action">
    <div class="flexBtns2">
      <button mat-raised-button color="accent" (click)="submit()" class="font-bold">Submit</button>
      <button mat-raised-button mat-dialog-close class="font-bold border">Close</button>
    </div>
  </mat-dialog-actions>
</form>