<div class="panel panel-primary view-all-vehicle" id="mainCompotents">
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">Last Service list</h3>
        <button mat-mini-fab class="custom-title-btn export-btn" title="Export" (click)="downloadVehiclesCSV()">
            <i class="fa fa-solid fa-file-export" aria-hidden="true"></i>
            <span class="hidden-xs"> Export</span>
        </button>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <div class="display-full relative">
            <mat-expansion-panel hideToggle="false" class="search-filter relative" [expanded]="searchPanelOpenStatus">
                <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px">
                    <strong><i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
                </mat-expansion-panel-header>
                <form id="advanceSearchForm" class="collapse in" aria-expanded="true">
                    <div class="panel-body">
                        <div class="row nr-col-width">
                            <div class="col-sm-3">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <mat-select class="form-control input-lg custom-form-input"
                                        options="searchKeyWordList" [(ngModel)]="options.search_by"
                                        [ngModelOptions]="{standalone: true}" placeholder="Search By">
                                        <mat-option *ngFor="let opt of searchKeyWordList"
                                            [value]="opt.id">{{opt.text}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-3">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <input matInput placeholder="Search Keyword" title="Search Keyword"
                                        [(ngModel)]="options.search_keyword" [ngModelOptions]="{standalone: true}"
                                        class="form-control input-lg custom-form-input" />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-3">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <mat-select class="form-control input-lg custom-form-input"
                                        options="searchByDaysList" (valueChange)="onChangeDays($event)"
                                        [(ngModel)]="options.lastServiceDays" [ngModelOptions]="{standalone: true}"
                                        placeholder="Search By Days">
                                        <mat-option *ngFor="let opt of searchByDaysList"
                                            [value]="opt.id">{{opt.text}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-3 dateRangeStyle details-input">
                                <app-date-range-picker class="date-time-section"
                                    (SelectTime)="onDateChange($event)"></app-date-range-picker>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3">
                                <mat-checkbox class="example-margin" [(ngModel)]="options.isRejectJobs"
                                    [ngModelOptions]="{standalone: true}">Include Rejected Jobs</mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <mat-action-row class="search-filter-footer">
                        <button mat-raised-button color="accent" type="submit" title="Search" (click)="search($event)">
                            Search
                        </button>
                    </mat-action-row>
                </form>
            </mat-expansion-panel>
            <button mat-button class="clear-btn" title="Clear Filter" #clearBtn [class.active-search]="checkFormEmpty(clearBtn)"
                (click)="clearSearch()">
                Clear Filter
            </button>
        </div>

        <div class="car-datatable mat-elevation-z1 uc-datatable ">
            <div class="table-responsive relative">
                <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg uc-table-responsive">
                    <ng-container matColumnDef="vehicle">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Vehicle</mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Vehicle">{{row.vehicle}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="VIN">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">VIN</mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="VIN">{{row.VIN}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="licensePlate">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">License
                            Plate</mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto"
                            data-val="License Plate">{{row.licensePlate}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="customer">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Account</mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Account">
                            <i class="fa fa-info-circle pointer text-red" (click)="openViewCarPopUp(row,2)"></i>
                            {{row.customer}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="mobile">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Mobile</mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Mobile">
                            <a class="text-black" href="tel:{{row.email}}"> {{row.mobile}}</a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Email</mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Email">
                            <a class="text-black" href="mailto:{{row.email}}"> {{row.email}} </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="YTD">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">$ YTD </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="$ YTD">$ {{row.YTD | number : '1.2-2'}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="YYYY">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">$ {{previousYear}}
                            </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" attr.data-val="$ {{previousYear}}">$ {{(row.YYYY | number : '1.2-2') || '-'}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="lastServiceDate">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Last Service
                            Date</mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Last SR Date">
                            {{row.lastServiceDate}}<br><span class="expire-badge" title="{{showDays(row.lastServiceDays,true)}}">{{showDays(row.lastServiceDays,false)}}</span>
                        </mat-cell>
                        <mat-chip color="primary" selected="true">Sublet</mat-chip>
                    </ng-container>

                    <ng-container matColumnDef="serviceTitle">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">View Last SR
                            </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="View Last SR">
                            <a class="text-black pointer" (click)="goToSR(row.requestId)"> {{row.serviceTitle}} </a>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Actions">
                        <mat-header-cell *matHeaderCellDef data-role="button">Action</mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="button">
                            <button mat-button class="list-action-btn" [matMenuTriggerFor]="menu" disableRipple>
                                Action<mat-icon>arrow_drop_down</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu" class="car-icon-menu disable-no-bg" [overlapTrigger]="false">
                                <a class="mat-menu-item" (click)="openViewCarPopUp(row,1)"
                                    title="View Car"><mat-icon>remove_red_eye</mat-icon>View Car</a>
                                <a class="mat-menu-item" (click)="openEditCarPopUp(row.carId)" title="Edit Car"><i
                                        class="fa fa-pencil"></i>Edit Car</a>
                                <a class="mat-menu-item" (click)="openServiceHistoryPopup(row.carId)" title="View Service History">
                                    <img [src]="gc.SERVICE_HISTORY_ICON" style="width:16px; margin-right:5px;" />View
                                    History</a>
                            </mat-menu>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
                <div class="padding-15 text-center" [style.display]="getTotalRecords() < 1 ? '' : 'none'">
                    No Records Available
                </div>
                <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
                    <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0"
                        [pageSize]="options.limit" [pageSizeOptions]="[5, 10,25, 100]" (page)="paginateByLimit($event)">
                    </mat-paginator>

                    <div class="mat-paginator table-paging">
                        <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} -
                            {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}
                        </div>
                        <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                            <mat-icon>navigate_before</mat-icon>
                        </button>
                        <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                            <mat-icon>navigate_next</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>