import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog'
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
import { CustomdatatableComponent,CustomdatatableSource } from '../../../customdatatable/customdatatable.component';
import { Router } from '@angular/router';
import { AddDiscountComponent } from "../add-discount/add-discount.component";
import { EditDiscountComponent } from "../edit-discount/edit-discount.component";
const SHOP_DISCOUNTS_LIST = "shop-discounts-";
import {Subscription} from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-list-discounts',
  templateUrl: './list-discounts.component.html',
  styleUrls: ['./list-discounts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListDiscountsComponent extends CustomdatatableComponent implements OnInit,OnDestroy  {

  public currentUser: any = {};
  public openStateSearch = false;
  public displayedColumns = ['name', 'code',  'added_by', 'type', 'value', 'start_date', 'end_date', 'status', 'add_date', 'Actions'];
  public tablesData=this;
  public url="";
  public options = {
    user_type: "User",
    user_id: "",
    search_by: "",
    search_keyword:  "",
    start: 0 ,
    limit: 10 ,
  }
  public searchByArray=[{id:1,text:'Name'},{id:2,text:'Code'}];
  public isEditEnabled:boolean;
  public isDelEnabled:boolean;
  public isAddEnabled:boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public subscriptionDataForAPI: Subscription = new Subscription();
  dataSource: MatTableDataSource<discountListData> = new MatTableDataSource();
  public record :any =[];

  constructor(public gs: GlobalService,public router: Router, public dialog: MatDialog) {
    super(globalConstants.API_LIST_SHOP_DISCOUNTS_URL, gs);
    this.currentUser = this.gs.getCurrentUser();
    if (this.currentUser) {
      this.sessionKey = SHOP_DISCOUNTS_LIST;
      this.url = globalConstants.API_LIST_SHOP_DISCOUNTS_URL;
    } else {
      this.router.navigate(["/shops"]);
    }
    setTimeout(()=>{
     this.isEditEnabled=this.gs.getAcl('discounts','edits');
     this.isDelEnabled=this.gs.getAcl('discounts','deletes');
     this.isAddEnabled=this.gs.getAcl('discounts','addNew');
     let inSubscription:boolean=true;
      // inSubscription = this.gs.isSubscribe(globalConstants.SUBSCRIPTION_DISCOUNTS_FEATURE);
     let isEnable=this.gs.getAcl('discounts','views');
      if(!(isEnable) || !(inSubscription)){
        this.router.navigate(['shops/action/forbidden']);
      }
    },globalConstants.RELOAD_WAIT);
  }

  ngOnInit() {
    this.reinitializeOptions();
    if(this.checkFormEmpty(null)){
      this.openStateSearch = true;
    }else{
      this.openStateSearch = false;
    }
    this.isReady = true;
    this.gs.setMetaData("SHOPS", "SHOP_DISCOUNTS_LIST")
    this.setDataTable()
  }

  addDiscount(){
    this.router.navigate(["/shops/discounts/add"]);
    // let dialogRef = this.dialog.open(AddDiscountComponent, {
    //   panelClass: 'car-dialog-form',
    //   data: { isPopup: true }
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('add coupon closed = ', result);
    // });
  }

  editDiscount(row){
    this.router.navigate(["/shops/discounts/edit/"+row.id]);
    // let dialogRef = this.dialog.open(EditDiscountComponent, {
    //   panelClass: 'car-dialog-form',
    //   data: { isPopup: true, couponId:row.id }
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('edit coupon closed = ', result);
    // });
  }

  deleteDiscount(row){
    this.gs.confirmDialog("yes", "Are you sure you want to remove this coupon?").subscribe((r:any) => {
      if (r != undefined && r == "yes") {
        let data = { user_id: this.currentUser.id, user_type: this.currentUser.user_type, discount_id: row.id };
        this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_DELETE_SHOP_DISCOUNT_URL, data).subscribe((r:any) => {
          let message = r.message;
          if (r.result == 1) {
            this.gs.snackbar('success', message);
            this.setDataTable();
          }else{
            this.gs.snackbar('error', message);
          }
        }, (error) => { });
      }
    })
  }

  search(event) {
    this.options.start = 0;
    this.setDataTable();
  }
  
  clearSearch() {
    this.openStateSearch = false;
    this.gs.localGetterSetter("", this.options, SHOP_DISCOUNTS_LIST);
    this.options.search_keyword= "";
    this.reinitializeOptions();
    this.setDataTable();
  }

  reinitializeOptions() {
    this.options = this.gs.localGetterSetter(this.options, this.options, SHOP_DISCOUNTS_LIST);
    this.options.user_type = this.currentUser.user_type;
    this.options.user_id = this.currentUser.id;
  }

  dateFormatWithoutTime(dateValue) {
    return ((new Date(dateValue).getMonth()+1) < 10 ? '0' : '')+(new Date(dateValue).getMonth()+1)+'/'+(new Date(dateValue).getDate() < 10 ? '0' : '')  + new Date(dateValue).getDate()+'/'+(new Date(dateValue).getFullYear());
  }

  createRecordrecord(record) {
    return {
      name: record.name,
      code: record.code,
      id: record.id,
      added_by: record.first_name+' '+record.middle_name+' '+record.last_name,
      type: record.type == 1 ? 'Amount' : 'Percenatage',
      value: record.type == 1 ? '$'+record.abs_value : record.percent_value+'%',
      start_date: record.start_date ? this.dateFormatWithoutTime(record.start_date) : '-',
      end_date: record.end_date ? this.dateFormatWithoutTime(record.end_date) : '-',
      status:record.status == 1 ? 'Active' : 'Inactive',
      add_date: this.gs.getFullFormateDate(record.add_date,'G',null)
    };
  }

  checkFormEmpty(button) {
    if (this.options.search_keyword == "") {
        if (button) {button.color = "primary"}
        return false
    }else {
        if (button) {button.color = "accent"}
        return true;
    }
  }

  setDataTable() {
    // this.dataSource = new CustomdatatableSource(this.tablesData, this.sort, this.paginator);
    // this.dataSource.getSortedData=this.sortedData;
    this.record = [];
    var content = this.options;
    this.isGetData=false
    this.callApi = this.gs.callAPI(this.url, content);
    this.callApi.subscribe((data) => {
      this.apiCaller.next(data);
      this.isGetData=true;
      let responseData = data.data
      if (responseData != undefined) {
        let moreRows = 'false';
        let firstList = 'false';
        this.gs.localGetterSetter("totalRecords", data.recordsTotal, this.sessionKey);
        if (data.more_rows == "true") {
          moreRows = "true";
        }
        this.gs.localGetterSetter("more_rows", moreRows, this.sessionKey);
        if (data.start >= 0 && data.start <= 5) {
          firstList = "true";
        }
        this.gs.localGetterSetter("first_list", firstList, this.sessionKey);
        if (responseData.length != undefined) {
          this.recordsTotal = responseData.length;
          for (var i = 0; i < responseData.length; i++) {
            this.AddRecord(responseData[i])
          }
          this.dataSource = new MatTableDataSource(this.record)
          this.dataSource.sort = this.sort;
        } else {
          this.recordsTotal = "1";
          this.AddRecord(responseData)
          this.dataSource = new MatTableDataSource(this.record)
          this.dataSource.sort = this.sort;
        }
        this.gs.localGetterSetter(null, this.options, this.sessionKey);
      } else {
        this.dataSource = new MatTableDataSource(this.record)
        this.gs.localGetterSetter("totalRecords", '0', this.sessionKey);
      }
    },(error)=>{
      this.gs.localGetterSetter("totalRecords", '0', this.sessionKey);
    })
  }

  AddRecord(record){
    var data = this.createRecordrecord(record);
      this.record.push(data);
  }
  // sortedData(): any[] {
  //  let th:any=this;
  //  const data = th._exampleDatabase.data.slice();
  //   if (!th._sort.active || th._sort.direction == '') { return data; }

  //   return data.sort((a, b) => {
  //     let propertyA;
  //     let propertyB;
  //     switch (th._sort.active) {
  //       case 'Name': propertyA = a.name; propertyB = b.name; break;
  //       case 'Code': propertyA = a.code; propertyB = b.code; break;
  //       case 'Added By': propertyA = a.added_by; propertyB = b.added_by; break;
  //       case 'Type': propertyA = a.type; propertyB = b.type; break;
  //       case 'Value': propertyA = a.value; propertyB = b.value; break;
  //       case 'Start Date': propertyA = a.start_date; propertyB = b.start_date; break;
  //       case 'End Date': propertyA = a.end_date; propertyB = b.end_date; break;
  //       case 'Status': propertyA = a.status; propertyB = b.status; break;
  //       case 'Added On': propertyA = a.add_date; propertyB = b.add_date; break;
  //       default: return 0;
  //     }
  //     let valueA = isNaN(propertyA) ? propertyA.toUpperCase() : propertyA;
  //     let valueB = isNaN(propertyB) ? propertyB.toUpperCase() : propertyB;

  //     return (valueA < valueB ? -1 : 1) * (th._sort.direction == 'asc' ? 1 : -1);
  //   });
  // }
  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
  }
}

export interface discountListData {
  name: any;
  code: any;
  added_by:any;
  type: any;
  new_record_value: any;
  value:any;
  start_date:any;
  end_date:any;
  status:any;
  add_date:any;
}