import { Component, OnInit, Inject, ChangeDetectorRef, Optional } from '@angular/core';
import { GlobalService } from '../../../../environments/global.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { globalConstants } from '../../../../environments/globalConstants';
import { IDatePickerConfig } from 'ng2-date-picker';
import {DatePipe} from '@angular/common';
import * as moment from 'moment';
@Component({
  selector: 'app-addleave',
  templateUrl: './addleave.component.html',
  styleUrls: ['./addleave.component.scss'],
  providers: [DatePipe]
})
export class AddleaveComponent implements OnInit {

  public leave    : FormGroup; 
  public user     : any = {};
  public employes       = [];
  public leaveTypes     = [];
  public param:any      = null;
  public assignees:any  = [];
  public isEmployee     = false;
  public datePickerConfig: IDatePickerConfig = {
    format: "MM/DD/YYYY",    
    drops:'down',
    disableKeypress:true
  };
  public timeSlots:any = globalConstants.TIME_SLOTS; 
  public error:any     = {tError:false};
  public docType       = globalConstants.FORMAT_FOR_DOC + ', pdf, ' + globalConstants.FORMAT_SUPPORTED_FOR_IMAGE;
  public imgSize       = globalConstants.MAX_ALLOWED_IMAGE_DISPLAYED_TEXT;
  public docs          = [];
  constructor(private ref:ChangeDetectorRef, private datePipe: DatePipe,
    public dialogRef: MatDialogRef<AddleaveComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private gs: GlobalService) {
    this.gs.diaLogObj(this.dialogRef);
    this.user     = this.gs.getCurrentUser();
    this.param    = this.data;
  }

  ngOnInit() {   
    this.leave  = this.formBuilder.group({      
      title            : ['',[Validators.required]],    
      start_date       : ['',[Validators.required]],    
      end_date         : ['',[Validators.required]],    
      emp_id           : ['',[Validators.required]],    
      leave_type       : ['',[Validators.required]],    
      leave_duration   : ['',[Validators.required]],    
      user_id          : this.user.id,
      user_type        : this.user.user_type,
      leave_status     : ['2'],
      leave_start_time : [''],    
      leave_end_time   : [''], 
      leave_notes      : [''],
      attachments      : ['']      
    },{validator: this.customDateValidate('start_date', 'end_date')});
    this.getEmployee();
    this.getLeaveType();  

    this.leave.controls.leave_duration.valueChanges.subscribe(res=>{
     
        if(res=='f'){
          this.leave.controls.leave_start_time.clearValidators();
          this.leave.controls.leave_end_time.clearValidators();
          this.leave.controls.leave_start_time.setValue("");
          this.leave.controls.leave_end_time.setValue("");
        }
        if(res=='h'){
          this.leave.controls.leave_start_time.setValidators([Validators.required]);
          this.leave.controls.leave_end_time.setValidators([Validators.required]);
        }
        this.leave.controls.leave_start_time.updateValueAndValidity();
        this.leave.controls.leave_end_time.updateValueAndValidity();
    });

    if(this.param && 'startDate' in this.param && this.param.startDate){
      let startDate = this.datePipe.transform(moment(this.param.startDate).toISOString(), 'MM/dd/yyyy hh:mm a');
      this.leave.controls.start_date.setValue(this.datePipe.transform(startDate, 'MM/dd/yyyy'));
    }
    if(this.param && 'endDate' in this.param && this.param.endDate){
      let endDate = this.datePipe.transform(moment(this.param.endDate).toISOString(), 'MM/dd/yyyy hh:mm a');
      this.leave.controls.end_date.setValue(this.datePipe.transform(endDate, 'MM/dd/yyyy'));
    }

    this.leave.get("start_date").valueChanges.subscribe(x => {   
        this.leave.controls.end_date.setValue(x);
    });

  }

  customDateValidate(from: string, to: string) {
    return (group: FormGroup): {[key: string]: any} => {
      let f = group.controls[from];
      let t = group.controls[to];
      if (f.value &&  t.value && f.value > t.value) {
        return {
          dates: "Start Date should be less than End Date"
        };
      }
      return {};
    }
  }


  onSubmit() {   

    this.error.tError = false;
    if(moment(new Date(this.leave.controls['start_date'].value)).format('MM/DD/YYYY')==moment(new Date(this.leave.controls['end_date'].value)).format('MM/DD/YYYY') && this.leave.controls['leave_start_time'].value > this.leave.controls['leave_end_time'].value){
      this.error.tError = true;
    }

    if (this.leave.valid && this.error.tError==false) {   
      let attachments=[];
      if(this.docs.length){
        for(var i in this.docs){
          attachments.push({name:this.docs[i].name,fileType:this.docs[i].type});
        }
      }
      this.leave.controls.attachments.setValue(JSON.stringify(attachments));   
       let data= Object.assign({},this.leave.value);
       if(data.leave_duration=='h'){
        data['leave_start_time'] = moment(data.start_date+" "+data.leave_start_time).format('MM/DD/YYYY hh:mm A Z');
        data['leave_end_time']   = moment(data.end_date+" "+data.leave_end_time).format('MM/DD/YYYY hh:mm A Z');
       }        
       this.gs.formData(globalConstants.API_ADD_LEAVE_APPLICATION,  this.gs.formsData(data)).subscribe((r) => {
        if (r.result == 1) {
          this.dialogRef.close(r);
          this.gs.snackbar("success", r.message);
        } else {
          this.gs.snackbar("error", r.message);
        }
      }, (error) => { })
    } else {
      this.gs.validateAllFormFields(this.leave);
      this.gs.snackbar("error", globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
  }
 

  getEmployee() {
    if(this.gs.getAcl('apply_employee_leaves','addNew')){
      this.employes.push({id: this.user.id, text:'Self' });
      let data = { user_id: this.user.id, user_type: this.user.user_type, opr: 1,pr:1 };
      this.gs.callAPI(globalConstants.API_GET_TECHNICIAN, data).subscribe((r:any) => {
        if (r.result == 1) {
            r.data.map((data)=>{
              this.employes.push({id: data.id, text: data.first_name+' ' +data.middle_name+' '+data.last_name });
            });
        }
      }, (error) => { })
    }else{
      this.employes.push({id: this.user.id, text:'Self' });
    }
  }

  getLeaveType(){   
    let data = { user_id: this.user.id, user_type: this.user.user_type };
    this.gs.callAPI(globalConstants.API_GET_LEAVE_TYPE, data).subscribe((r:any) => {
      if (r.result == 1) {
          r.data.map((data)=>{
            this.leaveTypes.push({id: data.leave_type_id, text: data.leave_name });
          });
      }
    }, (error) => { })
  }

    
  uploadPicture(event) {
    console.log('event',event);
    let type  = 'doc';
    let valid = this.gs.validateFiles(type, event.target.files);
    if (valid == false) {
      this.removeFileValues();
      return false;
    }
    console.log('valid',valid);
    let data     = new FormData();
    let url      = globalConstants.API_NEW_SERVICE_REQUEST_FILE;
    let file     = event.target.files[0];
    let fileType = this.gs.getFileIcon(file);
    let reader   = new FileReader();
    data.append(type, file);
    data.append('user_id', this.user.id);
    data.append('user_type', this.user.user_type);
    this.gs.formData(url, data).subscribe((resData) => {
      if (resData.result == 1) {        
          if (fileType == 1) {
            reader.onload = (e) => {
              resData.data.type = fileType;
              resData.data.src  = e.target['result'];
              this.docs.push(resData.data);
              this.ref.detectChanges();
            }
            reader.readAsDataURL(file);
          } else {
            resData.data.type = fileType;
            this.docs.push(resData.data);
          }
      } else {
        this.gs.snackbar("error", resData.message);
      }
    });
  }

  removeFileValues() {
    let file: any = document.querySelectorAll('input[type="file"]');
    if (typeof file.length != 'undefined') {
      file.length && file.forEach(function (x) {
        x.value = '';
      })
    }
  }

  delete(index?:any){   
    this.docs.splice(index,1);
    this.removeFileValues();
  }

  
  

}
