import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/base/services';
@Component({
  selector: 'app-access-limit',
  templateUrl: './access-limit.component.html',
  styleUrls: ['./access-limit.component.scss']
})
export class AccessLimitComponent implements OnInit {

  constructor(
    public loaderService:LoaderService
  ) { }

  ngOnInit() {
     this.loaderService.hide();
  }

}
