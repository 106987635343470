import { Component, OnInit, ViewChild, Pipe, Inject, ChangeDetectorRef, ViewEncapsulation, Optional, ContentChild, ElementRef, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup, FormControl, FormBuilder, Validators, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import {Http, Headers, Response, ResponseContentType} from '@angular/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {MatDatepickerInputEvent } from '@angular/material/datepicker';
import { globalFunctions } from "../../../../environments/globalFunctions";
import { globalConstants } from "../../../../environments/globalConstants";
import { GlobalService } from "../../../../environments/global.service";
import { INgxSelectOption } from 'ngx-select-ex';
import { map, switchMap } from 'rxjs/operators';
import { Observable, pipe,Subscription } from 'rxjs';
// import { CustomLoader } from '../../../customloader';
import { DetailTabComponent } from '../detail-tab/detail-tab.component';
import { ImagedialogComponent } from '../../../assetsComponent/imagedialog/imagedialog.component';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { ImagetooldialogComponent } from '../../../imagetooldialog/imagetooldialog.component';
import { VideoShowComponent } from '../../../customer/my-vehicles/video-show/video-show.component';
import saveAs  from 'file-saver';
// import { AppComponent } from '../../../app.component';
declare var jQuery:any;
declare var $ :any;

@Component({
  selector: 'app-service-history',
  templateUrl: './service-history.component.html',
  styleUrls: ['./service-history.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServiceHistoryComponent implements OnInit,OnDestroy  {
  
  @ViewChild(ModalDirective) serviceHistoryModal: ModalDirective;
  @ViewChild('scrollLoader', {read: ElementRef}) victim: ElementRef;
  
  vehiclesListIO:IntersectionObserver;
  public isPopup:boolean;
  isCategoryView:boolean;
  isCategoryViewModel:boolean;
  categoryBtnText:string = 'Switch to Category View';
  isAdvancedSearch:boolean;
  isAdvancedSearchModel:boolean;
  disableModeSwitch:boolean;
  searchBtnText:string = 'Switch to Advanced Mode';
  serviceRequestId:any;
  customer_id:any;
  public serviceInfo:any;
  vehicle_pic:any;
  vin_photo:any;
  vehicleName:any;
  public carId:any;
  backupCarId:any;
  globalConstants=globalConstants;
  openSearch: boolean;
  openAdvancedSearch: boolean;
  openVehicleList: boolean;
  historyDetailsPanel: boolean;
  carMakeList;
  carModels;;
  years;
  vehiclesList:Array<any>;
  selectedCar:any;
  imgPath = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_PICTURES + globalConstants.IMG_THUMB;
  historyListing:any;
  vehicleListing:any;
  toStartDate:Date;
  advancedOptions = {
    user_id: "",
    user_type: "User",
    make:'',
    year:'',
    model:'',
    license_plate:'',
    vin:'',
    part_number: '',
    description_of_work: '',
    notes: '',
    start: 0,
    limit: 10,
    searched: false,
    job_ids:'',
  }
  options = {
    user_id: "",
    user_type: "User",
    status: '',
    search_by: "",
    search_keyword: "",
    start: 0,
    limit: 10,
    schedule_service_date: "",
    requested_delivery_date: "",
    estimated_delivery_date: "",
    customer_id: "",
    jobStatus:'',
    on_the_lot : '0',
    wait_for_part:'0',
    account_verify:'0',
    serv_notes_unread:'0',
    sort_column: '',
    sort_direction: '',
    from_date: '',
    to_date: '',
    searched: false
  };
  fromDateModel:any;
  toDateModel:any;
  searchByArray = [];
  currentCustomer:any;
  showMinButton:boolean;
  id:string;
  searchResults:Array<any> = [];
  public minidragIcon='car2.jpg';
  minidragTitle:string = 'Service history';
  vehicleIds: Array<string> = [];
  /**
   * service details / results
   */
  partsOptions = [];
  genericOptions = [];
  //  public disclaimer = changeDisclaimer | null
  public ObjectJson = {};
  public headingColorFlag={};
  public totalReturnedValue;
  public finalShowAbleAmount;
  public balance;
  public totalAcceptedCalculation = []
  public rejectedJobCalculation = [];
  public grandTotalAcceptedArray = [];
  public grandTotalRejectedArray = [];
  public nonTaxableValue = [];
  public UnTaxedAcceptedValue = 0;
  public totalAcceptedValue ="";
  public totalRejectedValue ="";
  public conservingStatus = [];
  public statusAcceptText:any;
  public statusRejectText:any;
  public dataSources = {data: []};
  public reportDownloadLink='';
  public reportDownloadType=0;
  public subTotal;
  public totalTax;
  public recIds = []
  public jobStatusObj = {
    l1Id:"",
    l2Id:"",
    l3Id:"",
    recommendationId:"",
    status:""
  };
  public applyChanges=false;
  public jobStatuses = [];
  public showPartsTechButton=false;
  public partstechData=[];
  public partstechOrder=[];
  public editMode='0';
  public access_timestamp='';
  public showDownload=false;
  public initFormValue=true;

  public currentUserID;
  public userType;
  public requestCustomerDetails;
  public requestCustomerID;
  public getRequireApproval;
  //public authorizeBy;
  //public contactPerson;
  public contactPhoneNumber;
  //public dateTimeOfCall;
  public otherMeans;
  public getApprovalAmount;
  public requestCreatedDate;
  public viewCustomerInfo=false;
  public isFillRecommendation=false;
  public isDisclaimers=false;
  public isAcceptRejectJob=false;
  public taskTrack=false;
  public userTaskTrack:any=0;
  public statuses = ['Appointment', 'Pending', 'Processing', 'Waiting for Approval', '', 'Service Completed', 'Completed', 'Approval Completed', 'Completed And Paid', 'Unread Rating', 'Non-CAR','','Work in progress'];
  public skipTicketAssign=false;
  public techinianStatusDone=false;

  public getRejetedJob=true;

  public requestApprovalDetails: any = [];
  public authorizeBy;
  public contactPerson;
  public contactPersonPhone;
  public dateTimeOfCall;
  public otherMeansOfContact;
  public dateTimeOfCallValue;

  public vinTimeStamp = new Date().getTime();
  public vehicleTimeStamp = new Date().getTime();
  public isSubscribed=true;
  public isEmployee=false;
  animationState = 'out';
  animationStateApprovalData = 'out';
  public isInventory=false;
  public recomLock:any={is_locked:0,locker_id:"",locker_name:""};
  public showCostPrice=false;
  public isLaborTax=false;
  public isServiceNote=false
  public serviceDetail:any=null;
  public qqDetail:any;
  public levelPriceObj:any={};
  public hidePrices=true;
  couponRedeemConditions:Array<boolean> = [];
  public areAllAccordiansOpened:boolean = false;
  setFocus:boolean;
  result :any;
  public isFirstAccordianOpened:boolean = true;
  public subscriptionDataForAPI: Subscription = new Subscription();
  public autoSearchSelectedItem : any ;
  public showJobs : boolean = false;
  constructor(private formBuilder: FormBuilder, private router: Router, public globalService: GlobalService, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, @Optional() public dialogRef: MatDialogRef<ServiceHistoryComponent>,
  //  private customLoader:CustomLoader,
    public dialog: MatDialog, private activatedRoute: ActivatedRoute, public bsModalRef:BsModalRef, public cdr: ChangeDetectorRef, private http:Http) {
    // if(data && data.isPopup){
    //   this.isPopup = true;
    //   this.carId = data.car_id;
    //   this.serviceInfo = data.service_data;
    // }
    this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
    /* SEARCH BY ARRAY */
    this.searchByArray = [
      { id: "1", text: "Request ID"},
      { id: "2", text: "Category Name/Job"},
      { id: "3", text: "Part Number"},
      { id: "4", text: "Rejected Job"},
    ];
    
    this.showCostPrice=this.globalService.getAcl('display_part_cost','views');
  }

  ngOnInit(){
    this.setParams();
    setTimeout(()=>{
      if(!this.backupCarId){
        this.backupCarId = this.carId;
      }
      this.isAdvancedSearchModel = this.isAdvancedSearch;
      if(this.isAdvancedSearch){
        this.searchBtnText = 'Switch to Normal Mode';
        this.openAdvancedSearch = true;
        this.openVehicleList = true;
      }
      else{
        this.searchBtnText = 'Switch to Advanced Mode';
        this.openAdvancedSearch = false;
        this.openVehicleList = false;
      }
      this.isCategoryViewModel = this.isCategoryView;
      if(this.options.from_date){
        this.fromDateModel = new Date(this.options.from_date);
      }
      if(this.options.to_date){
        this.toDateModel = new Date(this.options.to_date);
      }
      this.getCarMakes();
      if(this.isAdvancedSearch && this.advancedOptions.make != null && this.advancedOptions.make != ''){
        this.getYears(this.advancedOptions.make);
      }
      if(this.isAdvancedSearch && this.advancedOptions.year != null && this.advancedOptions.year != ''){
        this.getModelList(this.advancedOptions.year);
      }
      if((this.isAdvancedSearch && this.vehiclesList && this.vehiclesList.length > 0 && this.selectedCar) || (!this.isAdvancedSearch && this.backupCarId)){
        this.initializePopupData();
      }
    }, 500);
  }
  
  initializePopupData(){
    // this.globalService.setBootstrapModalDirective(this.id, this.serviceHistoryModal);
    // this.getStaticResults();
    this.getHistoryDetails();
  }
  
  setParams() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.carId = params["car_id"];
      // this.getStaticResults();
      // this.getCarMakes();
      // this.getHistoryDetails();
    });
  }
  
  getHistoryDetails(){
    let content = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&vehicle_id=" + this.backupCarId;
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_VEHICLE_OWNER_DETAILS_URL, content).subscribe((response:any) => {
      if(response.result == 1){
        this.serviceInfo = response.data;
      }
      else{
        this.serviceInfo = undefined;
      }
    });
    this.getHistoryListings();
  }
  
  getHistoryListings(isLoadMore?:boolean){
    let body = {
      user_id: this.globalService.getCurrentUser()["id"],
      user_type: this.globalService.getCurrentUser()["user_type"],
      vehicle_id: this.backupCarId || this.carId,
      vehicle_ids: this.vehicleIds,
      start: this.options.start,
      limit: this.options.limit,
      search_by:this.options.search_by,
      keyword: this.options.search_keyword,
      from_date: this.options.from_date,
      to_date: this.options.to_date,
      type: (this.isCategoryView)?'1':'2',
      part_number: this.advancedOptions.part_number,
      description_of_work: this.advancedOptions.description_of_work,
      notes: this.advancedOptions.notes,
      job_ids: this.advancedOptions.job_ids
    } 
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_VEHICLE_SERVICE_HISTORY_URL, body).subscribe((response:any) => {
      // console.log('history details listing = ', response);
      this.historyListing = response;
      if(!isLoadMore){
        if(response.result == 1){
          this.searchResults = response.data;
          if(this.isCategoryView){
            this.searchResults.forEach((sr)=>{
              sr.category_name = sr.level1_name + ' > ' + sr.level2_name + ((sr.level3_name) ? (' > '+sr.level3_name) : '');
            });
          }
          else{
            // Date View
            this.searchResults.forEach((sr)=>{
              sr.sub_list.data.forEach((job)=>{
                job.category_name = job.level1_name + ' > ' + job.level2_name + ((job.level3_name) ? (' > '+job.level3_name) : '');
              });
            });
          }
        }
        else{
          this.searchResults = [];
          this.globalService.snackbar('error', response.message);
        }
      }
      else{
        if(response.result == 1){
          let tmpResults = response.data;
          if(this.isCategoryView){
            tmpResults.forEach((sr)=>{
              sr.category_name = sr.level1_name + ' > ' + sr.level2_name + ((sr.level3_name) ? (' > '+sr.level3_name) : '');
            });
          }
          else{
            // Date View
            tmpResults.forEach((sr)=>{
              sr.sub_list.data.forEach((job)=>{
                job.category_name = job.level1_name + ' > ' + job.level2_name + ((job.level3_name) ? (' > '+job.level3_name) : '');
              });
            });
          }
          this.searchResults = this.searchResults.concat(tmpResults);
        }
        else{
          this.globalService.snackbar('error', response.message);
        }
      }
    });
  }
  
  detailTab(type) {
    let dialogRef = this.dialog.open(DetailTabComponent, {
      panelClass: 'car-dialog-form',
      width: '800px',
      data: { requestId: this.serviceRequestId, customer_id: this.customer_id, tab: type ,car_id : this.backupCarId }
    });
  }
  
  chkDate(date){
    if (date!="0000-00-00 00:00:00") {
      let currentDate:Date = new Date();
      if(new Date(date)<new Date(currentDate)) {
        return true
      } else {
        return false;
      }
    }
  }
  
  openImageDialog(catType){
    let imgfile;
    if(catType == 1){
      imgfile= this.vehicle_pic;
    }else{
      imgfile= this.vin_photo;
    }
    let dialogRef = this.dialog.open(ImagedialogComponent, {
      panelClass: ['image-dialog'],
      data: {img:imgfile, category:catType}
    });
    imgfile="";
  }
  
  copyText() {
    let text:any = this.serviceInfo.vin;
    this.globalService.txtCopy(text);
  }
  
  isAllowed(action,module?:any){
    // if(typeof module!='undefined'){
    //   return this.globalService.isAllowed(this.app,module,action);            
    // }
    return true;
  }
  
  /**
   * @updatedBy Shashank Thakur
   * @trackID <:CAR-1491>, <:CAR-1512>
   * @updated 20-03-2020, 27-03-2020
   * @see Function updated to remove and add actions column as per deleted status
   * @see Function updated to change and show note related to completed service requests.
   */
  search(event?:any, isAdvanced?:boolean) {
    if(!isAdvanced){
      if(this.options.search_by){
        // this.options.customer_id=''
        // localStorage.setItem("customer_id", '');
      }
      if (this.fromDateModel) {
        let from_date = this.dateFormatWithoutTime(this.fromDateModel);
        this.options.from_date = from_date;
      } else {
        this.options.from_date = "";
      }
      if (this.toDateModel) {
        let to_date = this.dateFormatWithoutTime(this.toDateModel);
        this.options.to_date = to_date;
      } else {
        this.options.to_date = "";
      }
      this.options.start = 0;
      this.options.searched = true;
      // this.globalService.updateBootstrapModalInfo(this.id, {options: this.options, advancedOptions: this.advancedOptions, isAdvancedSearch: this.isAdvancedSearch, openAdvancedSearch: this.openAdvancedSearch, isCategoryView:this.isCategoryView, selectedCar:this.selectedCar, vehiclesList:this.vehiclesList, backupCarId: this.backupCarId});
      this.getHistoryListings();
      // this.setDataTable();
    }
    else{
      this.advancedOptions.start = 0;
      // this.globalService.updateBootstrapModalInfo(this.id, {options: this.options, advancedOptions: this.advancedOptions, isAdvancedSearch: this.isAdvancedSearch, openAdvancedSearch: this.openAdvancedSearch, isCategoryView:this.isCategoryView, selectedCar:this.selectedCar, vehiclesList:this.vehiclesList, backupCarId: this.backupCarId});
      // this.getStaticVehicles();
      this.vehiclesList = [];
      this.changeCar();
      if((this.advancedOptions.make !== '' && this.advancedOptions.make !== null) || (this.advancedOptions.year !== '' && this.advancedOptions.year !== null) || (this.advancedOptions.model !== '' && this.advancedOptions.model !== null)
      || this.advancedOptions.license_plate !== '' || this.advancedOptions.vin !== '' || this.advancedOptions.part_number !== ''
      || this.advancedOptions.description_of_work !== '' || this.advancedOptions.notes !== '' || this.advancedOptions.job_ids !== ''){
        this.advancedOptions.searched = true;
        console.log(this.advancedOptions);
        this.getVehiclesList();
      }else{
        this.globalService.snackbar('error', 'Please fill atleast one field.');
        this.advancedOptions.searched = false;
      }

      // set intersection observer for vehicles list
      // this.setVehiclesListAutoLoadMore();
    }
    this.isFirstAccordianOpened = true;
    this.areAllAccordiansOpened = false;
  }
  
  setVehiclesListAutoLoadMore(){
    setTimeout(()=>{
      let el:any = document.getElementById('vehicles-list');
      this.vehiclesListIO = new IntersectionObserver((entries)=>{
        entries.forEach((entry: IntersectionObserverEntry|any) => {
          if(entry.intersectionRatio >= 0.3){
            // load more
            this.loadMoreVehicles();
          } 
        });
      },{threshold: 0.7});
      this.vehiclesListIO.observe(this.victim.nativeElement);
    }, 200);
  }
  
  setToStartDate(ev:MatDatepickerInputEvent<Date>){
    // console.log('ev = ', ev);
    this.toStartDate = ev.value;
  }
  
  /**
   * @updatedBy Shashank Thakur
   * @trackID <:CAR-1491>, <:CAR-1512>
   * @updated 20-03-2020, 27-03-2020
   * @see Function updated to remove and add actions column as per deleted status
   * @see Function to set the note text according to the inclusion of completed service requests 
   * in list.
   */
  clearSearch() {
    // this.openSearch = false;
    // this.options.from_date="";
    // this.options.to_date="";
    
    localStorage.setItem("customer_id", '');
    localStorage.setItem("openFilter",'');
    this.options = {
      user_id: this.currentCustomer.id,
      user_type: this.currentCustomer.user_type,
      status: '',
      search_by: "",
      search_keyword: "",
      start: 0,
      limit: 10,
      schedule_service_date: "",
      requested_delivery_date: "",
      estimated_delivery_date: "",
      customer_id: "",
      jobStatus:'',
      on_the_lot : '0',
      wait_for_part:'0',
      account_verify:'0',
      serv_notes_unread:'0',
      sort_column: '',
      sort_direction: '', 
      from_date: '',
      to_date: '',
      searched: false
    };
    this.fromDateModel = '';
    this.toDateModel = '';
    // this.globalService.updateBootstrapModalInfo(this.id, {options: this.options, advancedOptions: this.advancedOptions, isAdvancedSearch: this.isAdvancedSearch, openAdvancedSearch: this.openAdvancedSearch, isCategoryView:this.isCategoryView, selectedCar:this.selectedCar, vehiclesList:this.vehiclesList, backupCarId: this.backupCarId});
    this.getHistoryListings();
  }
  
  /**
   * @updatedBy Shashank Thakur
   * @trackID
   * @updated 21-10-2020
   * @see Function updated to remove and add actions column as per deleted status
   * @see Function to set the note text according to the inclusion of completed service requests 
   * in list.
   */
  clearAdvancedSearch() {
    // this.openAdvancedSearch = false;
    this.advancedOptions = {
      user_id: this.currentCustomer.id,
      user_type: this.currentCustomer.user_type,
      make: '',
      year: '',
      model: '',
      license_plate: '',
      vin: '',
      part_number: '',
      description_of_work: '',
      notes: '',
      start: 0,
      limit: 10,
      searched: false,
      job_ids:''
    }
    this.years = [
      {  
        year: "",
        id: "",
        text: "Select Year",
      }
    ]; //Clear Year in advance search
    this.carModels = [
      {
        body_style: "",
        id: "",
        make: "",
        model: "",
        sub_model: "",
        text: "Select Model",
        trim: "",
        year: "",
      }
  ];
     //Clear Cars Models in Advance Search
    // this.globalService.updateBootstrapModalInfo(this.id, {options: this.options, advancedOptions: this.advancedOptions, isAdvancedSearch: this.isAdvancedSearch, openAdvancedSearch: this.openAdvancedSearch, isCategoryView:this.isCategoryView, selectedCar:this.selectedCar, vehiclesList:this.vehiclesList, backupCarId: this.backupCarId});
    this.changeCar();
    this.removeSelectedJob();
    this.vehiclesList = [];
    this.clearSearch();
  }
  
  dateFormatWithoutTime(dateValue) {
    return ((new Date(dateValue).getMonth()+1) < 10 ? '0' : '')+(new Date(dateValue).getMonth()+1) + "/" + (new Date(dateValue).getDate() < 10 ? '0' : '')  + new Date(dateValue).getDate() + "/" + new Date(dateValue).getFullYear();
  }
  
  /***Check service request*****/
  isAnyOptionActive(button?:any, isAdvanced?:any) {
    if(!isAdvanced){
      if(this.options.from_date != '' ||  this.options.to_date != ''){
        return true;
      }
      if (this.options.search_by=="" && this.options.jobStatus=="" && this.options.search_keyword == "" && this.options.status == "" && this.options.estimated_delivery_date == "" && this.options.requested_delivery_date == "" && this.options.schedule_service_date == "" ) {
        // console.log("IF", this.options);
        if (button) {button.color = "primary"}
          return false;
      }
      else {
        // console.log("ELSE", this.options);
        if (button) {button.color = "accent"}
          return true;
      }
    }
  }
  
  isAnyOptionActiveAdvanced(button?:any) {
    if (this.advancedOptions.license_plate == "" && this.advancedOptions.vin =="" && this.advancedOptions.make == "" && this.advancedOptions.year == "" && this.advancedOptions.model == "" ) {
      // console.log("IF", this.options);
      if (button) {button.color = "primary"}
      return false;
    }
    else {
      // console.log("ELSE", this.options);
      if (button) {button.color = "accent"}
      return true;
    }
  }
  
  toggleState(type){
    if(type==1){
      this.serviceHistoryModal.show();
    }else if(type==3){
      this.bsModalRef.hide();
      // reset data of modal to default if required
      this.globalService.clearBootstrapModal(this.id);
    }else{
      // get directive reference and call hide function();
      // this.globalService.getBootstrapModalDirective(this.id).hide();
      // save current state before minimizing - 
      this.globalService.updateBootstrapModalInfo(this.id, {options: this.options, advancedOptions: this.advancedOptions, isAdvancedSearch: this.isAdvancedSearch, openAdvancedSearch: this.openAdvancedSearch,openVehicleList: this.openVehicleList, isCategoryView: this.isCategoryView, selectedCar:this.selectedCar, vehiclesList:this.vehiclesList, backupCarId: this.backupCarId, serviceInfo: this.serviceInfo, searchResults: this.searchResults});
      this.bsModalRef.hide();
    }
  }
  
  isUserLoggedIn() {
    return (localStorage.getItem("loggedInUser") == null) ? false : true
  }
  
  private getStaticResults(){
    this.searchResults = [];
  }
    
  expandAll(idKeyword:string, parent:any=''){
    if(!parent) {
      this.areAllAccordiansOpened = true;
    }
  }
  
  collapseAll(idKeyword:string, parent:any=''){
    if(!parent) {
      this.areAllAccordiansOpened = false;
      this.isFirstAccordianOpened = false;   
    }
  }
  
  /** copy recommendation data from jobs */
  copyJob(e, data,SRData){
    e.preventDefault();
    let jobId='';
    let name='';
    // let v:any=data;
    let l1 = data.level1_name;
    let l2:any="",l3="";
    if(data.level3_name){
      // v=jl3;
      l2=data.level2_name;
      l3=data.level3_name;
      name=l1+' / '+data.level2_name+' / '+data.level3_name;
      jobId=data.recommendations[0].service_request_list_id;
    }else{
      name=data.level1_name +' / '+data.level2_name;
      l2=data.level2_name;
      jobId=data.recommendations[0].service_request_list_id;
    }
    let vehicleInfo = {
      year: SRData.year,
      make: SRData.make,
      model: SRData.model,
      sub_model: SRData.sub_model  
    };
    let cbData={id:jobId,name:name,l1:l1,l2:l2,l3:l3,recomm:data.recommendations,vehicleInfo};
    this.globalService.copyJob('set',cbData);
  }
  
  isCopyEnable(obj){
    if(typeof obj.children =='undefined'){
      return true;
    }else if(typeof obj.children !='undefined' && obj.children.length==0){
      return true;
    }
    return false;
  }
  
  parseFloats(value){
    let val=parseFloat(value);
    if(isNaN(val)){
      return '0.00';
    }
    return val.toFixed(2);
  }
  
  stockInHand(part){
    let q=globalFunctions.hasDecimal(part.stock_quantity,true)+' remaining in stock';
    let ouq=globalFunctions.hasDecimal(part.quantity_overused,true);
    if(parseFloat(ouq)){
      q+=', '+ouq+' short';
    }
    return q;
  }
  
  getNltoBr(val){
    if(val && val.length>0){
      return val.toString().replace(/\r\n/g, "<br />").replace(/\n/g, "<br />");
    }
    return '-';
  }
  
  imageToolDialog(current,i,type,imgaar): void {
    let dialogRef = this.dialog.open(ImagetooldialogComponent, {
        panelClass: ['imageToolDialog'],
        width: "100%",
        data: {event: current,index:i,imgType:type,imgArray:imgaar,customerId:this.globalService.getCurrentUser()["id"]}
    });

    dialogRef.afterClosed().subscribe(result => {
        //console.log('The dialog was closed');
        if(type == 1){
          this.vehicleTimeStamp = new Date().getTime();
        }else if(type == 5){
          this.vinTimeStamp = new Date().getTime();
        }
    });
  }
  
  videoShow(val): void {
    let dialogRef = this.dialog.open(VideoShowComponent, {
        panelClass: 'car-dialog-form',
        width: "800px",
        data: {name: val, url: globalConstants.S3_BUCKET_URL + 'recommendation-videos/'}
    });
  
    dialogRef.afterClosed().subscribe(result => {
  
    });
  }
    
  toNumber(value){
    return parseInt(value)
  }
  
  ConvertToNumber(str){ return parseInt(str) }
  
  loadMoreSearches(){
    this.options.start += this.options.limit;
    this.getHistoryListings(true);
  }
  
  loadMoreRequests(root:any, categoryData:any){
    let body:any = {
      user_id: this.globalService.getCurrentUser()["id"],
      user_type: this.globalService.getCurrentUser()["user_type"],
      vehicle_id: this.backupCarId || this.carId,
      start: root.sub_list.start,
      limit: root.sub_list.limit,
      search_by:this.options.search_by,
      keyword: this.options.search_keyword,
      from_date: this.options.from_date,
      to_date: this.options.to_date,
      type: (this.isCategoryView)?'1':'2'
    } 
    if(this.isCategoryView){
      body.level1_id = categoryData.l1;
      body.level2_id = categoryData.l2;
      body.level3_id = categoryData.l3;
    }
    else{
      body.request_id = categoryData.request_id;
    }
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_VEHICLE_SERVICE_HISTORY_SUB_LIST_URL, body).subscribe((response:any) => {
      root.sub_list.more_rows = response.more_rows;
      root.sub_list.start = response.start;
      if(response.result == 1){
        let tmpData:Array<any> = response.data;
        if(this.isCategoryView){
          tmpData.forEach((sr)=>{
            sr.category_name = sr.level1_name + ' > ' + sr.level2_name + ((sr.level3_name) ? (' > '+sr.level3_name) : '');
          });
        }
        else{
          // Date View
          tmpData.forEach((sr)=>{
            sr.category_name = sr.level1_name + ' > ' + sr.level2_name + ((sr.level3_name) ? (' > '+sr.level3_name) : '');
          });
        }
        root.sub_list.data = root.sub_list.data.concat(tmpData);
      }
      else{
        this.globalService.snackbar('error', response.message);
      }
    });
  }
  
  toggleSearchMode(){
    this.isAdvancedSearch = !this.isAdvancedSearch;
    this.isAdvancedSearchModel = !this.isAdvancedSearchModel;
    if(this.isAdvancedSearch){
      this.searchBtnText = 'Switch to Normal Mode';
      this.openAdvancedSearch = true;
      this.openVehicleList = true;
      this.backupCarId = '';
    }
    else{
      this.searchBtnText = 'Switch to Advanced Mode';
      this.openAdvancedSearch = false;
      this.openVehicleList = false;
      this.backupCarId = this.carId;
    }
    if(this.searchResults && this.searchResults.length == 0 && this.backupCarId){
      this.initializePopupData();
    }
  }
  
  toggleResultsView(){
    this.isCategoryView = !this.isCategoryView;
    this.isCategoryViewModel = !this.isCategoryViewModel;
    if(this.isCategoryView){
      this.categoryBtnText = 'Switch to Date View';
    }
    else{
      this.categoryBtnText = 'Switch to Category View';
    }
    this.options.start = 0;
    // this.globalService.updateBootstrapModalInfo(this.id, {options: this.options, advancedOptions: this.advancedOptions, isAdvancedSearch: this.isAdvancedSearch, openAdvancedSearch: this.openAdvancedSearch, isCategoryView:this.isCategoryView, selectedCar:this.selectedCar, vehiclesList:this.vehiclesList, backupCarId: this.backupCarId});
    this.getHistoryListings();
  }
  
  /* CHANGE MAKE */
  getSelectedOptionMakes(opt){
    this.getYears(opt);
  }

  /* CHANGE YEARS */
  getSelectedOptionYears(opt){
    this.getModelList(opt);
  }

  /* CHANGE MODELS */
  getSelectedOptionModels(opt){
    
  }
  
  getCarMakes() {
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_CAR_MAKES, "opr=2").subscribe((data:any) => {
      if(data.result=="1"){
        this.carMakeList = data.data;
        /* FOR SEARCH BOX DROPDOWN */
        var makelist=[
           {
            make: "", 
            id: "",
            text: "Select Make"
           }
        ];
        for(var i in this.carMakeList){
            this.carMakeList[i].id=this.carMakeList[i].make;
            this.carMakeList[i].text=this.carMakeList[i].make;
            makelist.push(this.carMakeList[i]);
        }
        this.carMakeList=makelist;
      }
    });
  }
  
  getModelList(event) {
    if (event == undefined) {
      //this.car.controls.model.disable();
      this.carModels = [];
      // this.disabledModel = true;
    } else {
      this.advancedOptions.model = ' ';
      // this.car.controls.model.enable();
      this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_MODELS_LIST_URL, "opr=2&make=" + this.advancedOptions.make + "&year=" + event).subscribe((data:any) => {
        if (data.result == "1") {
          // this.disabledModel = false;
          var modelsList=[];
          if (data.data.length != undefined) {
              this.carModels = data.data;
              /* FOR SEARCH BOX DROPDOWN SELECT */
              for(var i in this.carModels){
                  this.carModels[i].id=this.carModels[i].model+(this.carModels[i].trim ? ' '+this.carModels[i].trim : '')+(this.carModels[i].body_style ? ' '+this.carModels[i].body_style : '')
                  this.carModels[i].text=this.carModels[i].model+(this.carModels[i].trim ? ' '+this.carModels[i].trim : '')+(this.carModels[i].body_style ? ' '+this.carModels[i].body_style : '')
                  modelsList.push(this.carModels[i]);
                  //this.statesArray[this.years[i].state_id]=(this.years[i].state_name);
              }
              this.carModels=modelsList;
              /* END FOR V2 SELECT */
          } else {
              this.carModels[0] = data.data;
              /* FOR SEARCH BOX DROPDOWN SELECT */
              this.carModels.id=this.carModels.model+(this.carModels.trim ? ' '+this.carModels.trim : '')+(this.carModels.body_style ? ' '+this.carModels.body_style : '')
              this.carModels.text=this.carModels.model+(this.carModels.trim ? ' '+this.carModels.trim : '')+(this.carModels.body_style ? ' '+this.carModels.body_style : '')          
              modelsList.push(this.carModels);
              //this.statesArray[this.states.state_id]=this.states.state_name;
              this.carModels=modelsList;
              /* END FOR V2 SELECT */
          }
        } else {
            // this.globalService.snackbar("error", data.message)
        }
      });
    }
  }
  
  getYears(event) {
    //this.car.controls.model.disable();
    this.advancedOptions.year = ' ';
    this.carModels = [];
    if (event == undefined || event == "") {
      // this.car.controls.year.disable();
      this.years = [];
      this.carModels = [];
      // this.disabledYear = true;
      // this.disabledModel = true;
    } else {
      //this.car.controls.year.enable();
      this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_YEAR_LIST, "opr=2&make=" + event).subscribe((data:any) => {
        if (data.result == "1") {
          // this.disabledYear = false;
          var yearsList=[];
          if (data.data.length == undefined) {
              //this.years = [];
              this.years[0] = data.data;
              /* FOR SEARCH BOX DROPDOWN SELECT */
              this.years.id=this.years.year;
              this.years.text=this.years.year;          
              yearsList.push(this.years);
              //this.statesArray[this.states.state_id]=this.states.state_name;
              this.years=yearsList;
              /* END FOR V2 SELECT */
          } else {
              this.years = data.data;
              /* FOR SEARCH BOX DROPDOWN SELECT */
              for(var i in this.years){
                  this.years[i].id=this.years[i].year;
                  this.years[i].text=this.years[i].year;
                  yearsList.push(this.years[i]);
                  //this.statesArray[this.years[i].state_id]=(this.years[i].state_name);
              }
              this.years=yearsList;
              /* END FOR V2 SELECT */
          }
        } else {
          //this.car.controls.year.disable();
          // this.disabledYear = true;
        }
      });
    }
  }
  
  changeCar(){
    this.selectedCar=null;
    this.backupCarId = '';
    // this.globalService.updateBootstrapModalInfo(this.id, {options: this.options, advancedOptions: this.advancedOptions, isAdvancedSearch: this.isAdvancedSearch, openAdvancedSearch: this.openAdvancedSearch, isCategoryView:this.isCategoryView, selectedCar:this.selectedCar, vehiclesList:this.vehiclesList, backupCarId: this.backupCarId});
    if(this.vehiclesListIO && this.victim){
      this.vehiclesListIO.observe(this.victim.nativeElement);
    }
  }

  //Issue: Car-370 -> Clear Search when all vehicles are selected
  viewAllCar(){
    this.selectedCar=null;
    this.backupCarId = '';
    // this.globalService.updateBootstrapModalInfo(this.id, {options: this.options, advancedOptions: this.advancedOptions, isAdvancedSearch: this.isAdvancedSearch, openAdvancedSearch: this.openAdvancedSearch, isCategoryView:this.isCategoryView, selectedCar:this.selectedCar, vehiclesList:this.vehiclesList, backupCarId: this.backupCarId});
    if(this.vehiclesListIO && this.victim){
      this.vehiclesListIO.observe(this.victim.nativeElement);
    }
    this.openSearch = false;
    this.clearSearch();
  }
  
  selectVehicle(car){
    this.selectedCar=car;
    this.backupCarId = car.id;
    this.clearSearch();
    // this.globalService.updateBootstrapModalInfo(this.id, {options: this.options, advancedOptions: this.advancedOptions, isAdvancedSearch: this.isAdvancedSearch, openAdvancedSearch: this.openAdvancedSearch, isCategoryView:this.isCategoryView, selectedCar:this.selectedCar, vehiclesList:this.vehiclesList, backupCarId: this.backupCarId});
    this.initializePopupData();
    if(this.vehiclesListIO && this.victim){
      this.vehiclesListIO.unobserve(this.victim.nativeElement);
    }
  }
  
  getVehiclesList(isLoadMore?:boolean){
    let body:any = {
      user_id: this.globalService.getCurrentUser()["id"],
      user_type: this.globalService.getCurrentUser()["user_type"],
      start: this.advancedOptions.start,
      limit: this.advancedOptions.limit,
      year: (this.advancedOptions.year == null) ? '' : this.advancedOptions.year,
      make: (this.advancedOptions.make == null) ? '' : this.advancedOptions.make,
      model: (this.advancedOptions.model == null) ? '' : this.advancedOptions.model,
      license_plate: this.advancedOptions.license_plate,
      vin: this.advancedOptions.vin,
      part_number: this.advancedOptions.part_number,
      description_of_work: this.advancedOptions.description_of_work,
      notes: this.advancedOptions.notes,
      job_ids: this.advancedOptions.job_ids
    } 
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_VEHICLE_SEARCH_URL, body).subscribe((response:any) => {
      this.vehicleListing = response;
      if(response.result == 1){
        if(!isLoadMore){
          this.vehiclesList = response.data;
        }
        else{
          let tmp:Array<any> = response.data;
          this.vehiclesList = this.vehiclesList.concat(tmp);
        }
        //Issue: Car-370 -> calling history listing function without vehicle id (Line: 983-991)
        this.vehicleIds = [];
        this.vehiclesList.forEach(e=>{
          this.vehicleIds.push(e.id);
        });
        if(this.vehicleIds.length > 0){
          this.getHistoryListings();
          this.openVehicleList = false; //collapse vehicle list  
        }
        this.formatVehicleName(this.vehiclesList);
      }
      else{
        this.vehiclesList = [];
        this.searchResults = [];
        this.vehicleIds = [];
        this.advancedOptions.searched = false;
        this.globalService.snackbar('error', response.message);
      }
      // this.globalService.updateBootstrapModalInfo(this.id, {options: this.options, advancedOptions: this.advancedOptions, isAdvancedSearch: this.isAdvancedSearch, openAdvancedSearch: this.openAdvancedSearch, isCategoryView:this.isCategoryView, selectedCar:this.selectedCar, vehiclesList:this.vehiclesList, backupCarId: this.backupCarId});
    });
  }
  
  loadMoreVehicles(){
    this.advancedOptions.start += this.advancedOptions.limit;
    this.getVehiclesList(true);
  }
  
  getFormattedDate(date:string){
    return this.globalService.getFullFormateDate(date,'G','B')
  }
  
  formatVehicleName(v:Array<any>){
    v.forEach((obj: any) => {
      let n = obj.make;
      if (obj.year && obj.year!='0') {
        n = (n? n + ' / ':'') + obj.year
      }
      if (obj.model) {
        n = (n? n + ' / ':'') + obj.model
      }
      obj.car_name = (n ? n : 'N/A')
    })
    if(v.length==1){
      // this.selectedCar = (v[0]);
      this.selectVehicle((v[0]));
    }
  }
  
  // switchChange(event) {
  //   if (event.checked) {
  //     this.isAdvancedSearch=true;
  //     console.log('true');
  //   } else {
  //     this.isAdvancedSearch=false;
  //     console.log('false');
  //   }
  // }
  
  private getStaticVehicles(){
    this.vehiclesList = [];
  }
  
  printInvoice(){
  
    let url = globalConstants.API_CREATE_ESTIMATE_URL;
    let data={
      "service_request_id":this.serviceInfo.controls.id.value,
      "user_type":this.globalService.getCurrentUser()["user_type"],
      "customer_id":this.serviceInfo.controls.customer_id.value,
      "user_id":this.globalService.getCurrentUser()["id"],
      "car_id":this.serviceInfo.controls.car_id.value,
      "invoke_download":1
    };
    let skipFirst:boolean = true;
    for(let d in data){
      //console.log(d,data[d]);
      if(skipFirst == true){
        url += "?"+d+"="+data[d];
        skipFirst = false;
      }else{
        url += "&"+d+"="+data[d];
      }
    }
    let serviceRequestId = this.serviceInfo.request_id;
    this.globalService.downloadFileWithGet(url,serviceRequestId);
  }
  
  printTechSheet(){
    let url = globalConstants.API_TECHNICIAN_SHEET_URL;
    let data={
      "service_request_id":this.serviceInfo.controls.id.value,
      "user_type":this.globalService.getCurrentUser()["user_type"],
      "customer_id":this.serviceInfo.controls.customer_id.value,
      "user_id":this.globalService.getCurrentUser()["id"],
      "car_id":this.serviceInfo.controls.car_id.value,
      "invoke_download":1
    };
    let skipFirst:boolean = true;
    for(let d in data){
      //console.log(d,data[d]);
      if(skipFirst == true){
        url += "?"+d+"="+data[d];
        skipFirst = false;
      }else{
        url += "&"+d+"="+data[d];
      }
    }
    let serviceRequestId = this.serviceInfo.request_id;
    this.globalService.downloadFileWithGet(url,serviceRequestId);
  }
  
  expandInnerPanels = (parentIndex:number) =>{
    const children:HTMLCollection = $(`#expansionHead-parent-${parentIndex}`)[0]!.children;
    for(let i=0;i<children.length;i++){
      for (let j=0;j<children[i].children.length;j++){
        const isAlreadyExpanded:boolean = children[i].classList.contains('mat-expanded')
        let innerId = children[i].children[j].id;
        setTimeout(() => {
         if(!isAlreadyExpanded ) {
          $(`#${innerId}`).click();
          document.getElementById(innerId).scrollIntoView();
         } 
        }, (200));
      }
    }
  }

  collapseInnerPanels = (parentIndex:number) =>{
    const children:HTMLCollection = $(`#expansionHead-parent-${parentIndex}`)[0]!.children;
    for(let i=0;i<children.length;i++){
      for (let j=0;j<children[i].children.length;j++){
        const isAlreadyExpanded:boolean = children[i].classList.contains('mat-expanded');
        let innerId = children[i].children[j].id;
        setTimeout(() => {
          if(isAlreadyExpanded ) {
            $(`#${innerId}`).click();
         } 
        }, (200));
      }
    }
  }

  scollToView(index:number){
    setTimeout(() => {
      document.getElementById(`expansion-history-${index}`).scrollIntoView({
        block:'nearest',
        inline:'nearest',
        behavior: 'smooth'
      })
    }, (200));
  }

  selectedJobCategoryOption(jobValue) {
    if (typeof jobValue == 'object') {
      if (jobValue.level3Id == '') {
        this.advancedOptions.job_ids = jobValue.level2Id;
      } else {
        this.advancedOptions.job_ids = jobValue.level3Id;
      }
      this.autoSearchSelectedItem = jobValue.value;
      this.showJobs = true;
    }
    this.clearSearchForAutocomplate();
  }

  clearSearchForAutocomplate() {
    let txt = <HTMLInputElement>document.getElementById('searchAddJobAattribute');
    txt.value = '';
  }

  removeSelectedJob(){
    this.autoSearchSelectedItem = '';
    this.showJobs= false;
    this.advancedOptions.job_ids = '';
  }

  carAttribute() {
    return false;
  }
  autocompleListFormatter(opt) {
    return opt.value;
  }

  jobCategoryList = (keyword: any): Observable<any[]> => {
    let url: string = globalConstants.API_search_service_requests_list + '?keyword=' + keyword;
    if (typeof keyword != 'undefined' && keyword.length >= 3) {
      let f = [];
      return this.globalService.getAPI(url).map(res => {
        let json = res;
        this.globalService.authText(json);
        if (json && json != null && typeof json.data != 'undefined') {
          json.data.forEach(function (x) {
            f.push(x);
          });
        }
        return f;
      });
    } else {
      return Observable.of([]);
    }
  }

  getVehicleName(vehicleInfo:any){
    return this.globalService.getGlobalVehicleName(vehicleInfo);
  }
  
  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
  } 
  
  convertNltoBr(text){
    return this.globalService.globalNltoBr(text);
  }

}
