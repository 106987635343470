import {Component, ElementRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {DataSource, SelectionModel} from '@angular/cdk/collections';
import {Http, Headers, Response} from '@angular/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {globalFunctions} from "../../../../environments/globalFunctions"
import {globalConstants} from "../../../../environments/globalConstants"
import {GlobalService} from "../../../../environments/global.service"
import {HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Title} from '@angular/platform-browser';
import {lang} from "../../../../environments/lang-en"
import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';
import {AppComponent} from '../../../app.component';
import { MatTableDataSource } from '@angular/material/table';
const SERVICE_COMPLAIN='shop-service-complain-';
@Component({
  selector: 'app-shoplistservicecomplaints',
  templateUrl: './shoplistservicecomplaints.component.html',
  styleUrls: ['./shoplistservicecomplaints.component.scss'],
  animations: [
        trigger('flyInOut', [
            state('in', style({
                transform: 'translateX(0)'
            })),
            transition('void => *', [
                style({transform: 'translateX(500%)'}),
                animate('0.5s ease-in')
            ])
          ])
    ],
  encapsulation: ViewEncapsulation.None
})
export class ShoplistservicecomplaintsComponent {
  displayedColumns = ['complaint_id', 'request_id', 'title', 'vehicle', 'complaint_date', 'status', 'actions'];
  dataSource: MatTableDataSource<ServiceComplainData> = new MatTableDataSource();
  public currentCustomer;
  public schedule_service_option
  public requested_delivery_option
  public estimated_delivery_option
  public openSearch;
  public more_rows;
  public options = {
    user_id: "",
    user_type: "User",
    complaint_status: '',
    search_keyword: "",
    start: 0 ,
    limit: 10 ,
  }
  public statuses = ['', 'Created', 'Resolved', 'Closed', 'Unread'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public searchByStatus = [];
  public recordsTotal: any;
  public record:any = [];
  constructor(private location:Location,private app: AppComponent, public router:Router,private http:Http, public titleService: Title,public globalservice:GlobalService){
    this.globalservice.setMetaData("SHOPS","MANAGE_COMPLAINT")
    this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
    this.options.user_id = this.currentCustomer.id;
    this.reinitializeOptions();
    if(localStorage.getItem("openFilter") == "1") {
            this.openSearch = true;
    } else {
        this.openSearch = false;
    }
    /* SEARCH BY ARRAY */
    this.searchByStatus = [
        { id: "1", text: "Created"},
        { id: "2", text: "Resolved"},
        { id: "3", text: "Closed"},
        { id: "4", text: "Unread"}
    ];
  }
  getStatus(statusId) {
    return this.statuses[statusId]
  }
  setTouchViewValue(){
      return this.app.touchViewState;
  }
  formatDate(date) {
    if (!new Date(date) || date == null || date == "0000-00-00 00:00:00") { return "-" }
    return (new Date(date).getMonth()+1) + "/" + new Date(date).getDate() + "/" + new Date(date).getFullYear()
  }
  ngOnInit() {
    setTimeout(()=>{
    let isEnable=this.globalservice.getAcl('complaints','views');
    if(!(isEnable)){
        this.router.navigate(['shops/action/forbidden']);
    }
  },globalConstants.RELOAD_WAIT)
    //this.titleService.setTitle(lang.TITLE_CUSTOMER_LIST_REQUEST);
    this.setDataTable();
    // this.clearSearch();
  }
  search(event) {
    this.options.start = 0;
    this.setDataTable();
  }
  clearSearch() {
    this.globalservice.localGetterSetter(null,this.options,SERVICE_COMPLAIN);
    this.openSearch = false;
    localStorage.setItem("openFilter",'');
    this.reinitializeOptions();
    this.setDataTable();
  }
  viewPart(getId){
    this.globalservice.localGetterSetter(this.options,null,SERVICE_COMPLAIN);
      this.router.navigate(['/customer/view-request/', getId]);
  }
  scrolling(){
      this.globalservice.setScrolling('.car-datatable', 1000, -20);
}
  paginateByLimit(event){
    this.globalservice.setScrolling('body', 600, 0);
    this.options.limit = event.pageSize;
    this.options.start = 0;
    this.scrolling();
    // this.clearSearch();
    this.setDataTable();
  }
  reinitializeOptions (){
    this.options =this.globalservice.localGetterSetter(this.options,this.options,SERVICE_COMPLAIN);
    this.options.user_id= this.currentCustomer.id;
    this.options.user_type= "User";
  }
  getTotalRecords(){
    return parseInt(localStorage.getItem(SERVICE_COMPLAIN+'totalRecords'));
  }
  paginate(event,type){
    this.scrolling();
    if(type=="next") {
      this.options.start = this.options.start + this.options.limit;

    } else {
      this.options.start = this.options.start - this.options.limit;

    }
    this.setDataTable();
  }
  firstList() {
    return this.globalservice.firstList(SERVICE_COMPLAIN,this.options);
  }
  lastList(){
    return this.globalservice.lastList(SERVICE_COMPLAIN);
  }
  getUpperLimit(currentLastLimit,total) {
    if(currentLastLimit > total) {
      return total
    } else {
      return currentLastLimit
    }
  }

  viewComplaintDetails(row) {
    this.globalservice.localGetterSetter(this.options,null,SERVICE_COMPLAIN);
    localStorage.setItem("shopComplaintID",row.complaint_id);
    this.globalservice.getSetItems("shopComplaintReadStatus",row.read_status);
    this.router.navigate(['shops/view-service-complaint']);
   }

   /***Check service request*****/
  isAnyOptionActive(button) {
      if (this.options.search_keyword == "" && this.options.complaint_status == "") {
          if (button) {button.color = "primary"}
          return false;
      }
      else {
          if (button) {button.color = "accent"}
          return true;
      }
  }
  setDataTable(){
    this.record = [];
    var content = globalFunctions.stringifyFormData(this.options, null);
    var callApi = this.globalservice.callAPI(globalConstants.API_GET_COMPLAINTS_URL, content);
    callApi.subscribe((data:any)=>{
      if(data.data!=undefined) {
        let moreRows='false';
        let firstList='false';
        this.globalservice.localGetterSetter("totalRecords",data.recordsTotal,SERVICE_COMPLAIN);
        if(data.more_rows == "true") {
            moreRows="true";
        }
        this.globalservice.localGetterSetter("more_rows",moreRows,SERVICE_COMPLAIN);

        if(data.start >= 0 && data.start <= 5) {
          firstList="true";
        }
        this.globalservice.localGetterSetter("first_list",firstList,SERVICE_COMPLAIN);

        this.recordsTotal = data.data.length;

        if(data.data.length==undefined){
          this.AddRecord(data.data);
          this.dataSource = new MatTableDataSource(this.record);
          this.dataSource.sort = this.sort;
        } else {
          for(var i=0;i<data.data.length;i++){
            this.AddRecord(data.data[i]);
          }
          this.dataSource = new MatTableDataSource(this.record);
          this.dataSource.sort = this.sort;
        }
        this.globalservice.localGetterSetter(null,this.options,SERVICE_COMPLAIN);
      }else {
        this.dataSource = new MatTableDataSource(this.record);
          this.globalservice.localGetterSetter("totalRecords",'0',SERVICE_COMPLAIN);
      }
    })
  }

  AddRecord(record) {
    var data = this.createRecord(record);
    this.record.push(data);
}

private createRecord(record) {
  let vehicleName="";
  if (record.year) {
    vehicleName= record.year ;
  }
  if(record.make != ""){
    vehicleName+= (vehicleName ? " " : "" ) + record.make;
  }
  if(record.model != ""){
    vehicleName+= (vehicleName ? " " : "" ) + record.model
  }
  return {
      complaint_id: record.id,
      request_id: record.request_id,
      title: record.title,
      vehicle: vehicleName,
      complaintt_date: this.globalservice.getFullFormateDate(record.add_date,'G','H'),
      status:this.statuses[record.status],
      check_status:record.status,
      ticket_no:record.ticket_no,
      read_status:record.read_status,
  };
}
    /* STATUS COLOR */
    getRowStatusColor(status) {
        var statusArr = ['', 'warning-cell', 'info-cell', 'success-cell'];
        return statusArr[status];
    }
}

export interface ServiceComplainData {
    complaint_id: string,
    request_id: string,
    title: string;
    vehicle: string;
    complaintt_date: string;
    status: string;
    check_status: any;
    ticket_no: any;
    read_status:any;
}


