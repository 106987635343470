<div id="mainCompotents">
  <div class="main-content">
    <div class="shopaddnewpart">
      <div class="panel panel-primary smartBatch">
        <div class="panel-heading padding-left-15">
          <h3 class="panel-title">{{heading}} Smart Batch</h3>
          <button mat-mini-fab class="custom-btn-back" (click)="gs.goBack();" *ngIf="batchId">
            <mat-icon>arrow_back</mat-icon>
          </button>
        </div>
        <div class="panel-body bgcolor-lightgrey editSmart-batch">
          <!-- Add New Smart Batch start -->
          <div id="addNewPart" class="row bgcolor-lightgrey form-content">
            <div class="container-fluid">
              <form [formGroup]="addBatches" novalidate (ngSubmit)="AddBatch(1)" id="addPartForm"
                role="form" method="POST" enctype="multipart/form-data">
                <div class="col-xs-12 form-block">
                  <div class="row">
                    <div class="form-group col-md-6 col-sm-12">
                      <label for="group_name">Part Type</label>
                      <div class="relative">
                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                          <input matInput placeholder="Part Type" title="Part Type" formControlName="group_name" maxlength="200"
                            value="" class=" input-lg custom-form-input"  required />
                          <mat-error *ngIf="addBatches.controls.group_name.hasError('required')">
                            This field cannot be empty
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-6 col-sm-12">
                      <label for="batch_number">Smart Batch Number</label>
                      <div class="relative">
                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                          <input matInput placeholder="Smart Batch Number" title="Smart Batch Number"
                            formControlName="batch_number" value="" class=" input-lg custom-form-input" maxlength="200"
                             required />
                          <mat-error *ngIf="addBatches.controls.batch_number.hasError('required')">
                            This field cannot be empty
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 form-group submit-block">
                  <button type="button" mat-raised-button
                    class="car-btn text-uppercase font-light fontcolor-white" id="saveClose"
                    style="max-width: 300px;" color="accent" title="Submit and Close" 
                    (click)="AddBatch(1)">Submit and Close</button>
                  <button type="button" mat-raised-button
                    class="car-btn text-uppercase  font-light fontcolor-white" id="saveAddPartClose"
                    style="max-width: 350px;" color="accent" title="Submit and Add Parts to Batch" *ngIf="!batchId"
                    (click)="AddBatch(2)">Submit and Add Parts to Batch</button>
                </div>
              </form>
            </div>
          </div>
          <!-- Add New Smart Batch end -->
        </div>
        <div class="panel panel-primary relative margin-15 partList" [class.hide]="!showPartList">
            <div class="panel-heading padding-left-15">
                <h3 class="panel-title">PARTS LIST</h3>
                <button type="button" mat-mini-fab *ngIf="batchId" class="custom-btn-back list-smart-batch-button btn-right" title="Add Parts To Batch"  (click)="AssignBatch();">
                    <i class="fa fa-fw fa-plus margin-right-5"></i><span class="hidden-xs">Add Parts To Batch</span>
                </button>
            </div>
          <div class="panel-body bgcolor-lightgrey">

              <div class="car-datatable mat-elevation-z1">

                <div class="table-responsive relative">
                  <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg">

                    <ng-container matColumnDef="Name">
                      <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Name </mat-header-cell>
                      <mat-cell *matCellDef="let row" data-role="auto"> {{row.name}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="part_number">
                      <mat-header-cell *matHeaderCellDef data-role="auto"> Part Number </mat-header-cell>
                      <mat-cell *matCellDef="let row" data-role="auto"> {{row.part_number }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Price">
                      <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Price </mat-header-cell>
                      <mat-cell *matCellDef="let row" data-role="auto"> {{row.price }} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Quantity">
                      <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Quantity </mat-header-cell>
                      <mat-cell *matCellDef="let row" data-role="auto">
                        <strong [class.text-red]="row.is_low_stock">{{showWholeNumber(row.quantity)}}&nbsp;({{showWholeNumber(row.low_stock_value)}})</strong>
                        <button (click)="addStockOrder(row)" *ngIf="addTostockOrder && row.is_low_stock" class="btn-link text-red" title="Add to stock order"><i class="fa fa-cart-plus fa-lg"></i></button>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Actions">
                      <mat-header-cell *matHeaderCellDef data-role="button"> Actions </mat-header-cell>
                      <mat-cell *matCellDef="let row" data-role="button">
                          <button *ngIf="isAllowed('edits','part') || isAllowed('views','part')" mat-button class="list-action-btn" [matMenuTriggerFor]="menu" disableRipple >
                              Action<mat-icon>arrow_drop_down</mat-icon>
                          </button>
                          <mat-menu #menu="matMenu" class="car-icon-menu" [overlapTrigger]="false">
                              <a class="mat-menu-item" *ngIf="isAllowed('edits','part') && row.delete_status=='0'" (click)="editPart(row.part_id)"><i class="fa fa-edit"></i>Edit</a>
                              <a class="mat-menu-item" *ngIf="isAllowed('views','part') && row.delete_status=='0'" (click)="viewLog(row.part_id)"><i class="fa fa-eye"></i>Logs</a>
                              <a class="mat-menu-item" *ngIf="isAllowed('views','part') && row.delete_status=='0'" (click)="removeBatch(row)"><i class="fa fa-trash"></i>Remove Part from Batch</a>
                          </mat-menu>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  </mat-table>
                  <div class="padding-15 text-center" [style.display]="getTotalRecords() < 1 ? '' : 'none'">
                    No Records Available
                  </div>
                </div>
                <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
                  <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0"
                    [pageSize]="options.limit" [pageSizeOptions]="[5, 10,25, 100]" (page)="paginateByLimit($event)">
                  </mat-paginator>

                  <div class="mat-paginator table-paging">
                    <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} -
                      {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
                    <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                      <mat-icon>navigate_before</mat-icon>
                    </button>
                    <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                      <mat-icon>navigate_next</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
         
        </div>
      </div>
    </div>
  </div>
</div>