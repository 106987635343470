import { Component, OnInit, ViewChild, Output, Input, EventEmitter, AfterViewInit } from '@angular/core';
import { GlobalService } from '../../../../environments/global.service';
import { MatDrawer } from '@angular/material/sidenav';
import { globalConstants } from '../../../../environments/globalConstants';
import { globalFunctions } from '../../../../environments/globalFunctions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-excluded-vehicles',
  templateUrl: './add-excluded-vehicles.component.html',
  styleUrls: ['./add-excluded-vehicles.component.scss'],
})

export class AddExcludedVehiclesComponent implements OnInit, AfterViewInit {
  @ViewChild('excludedVehicesDrawer') excludedVehicesDrawer: MatDrawer;
  @Input() excludeVehicleList;
  @Output() closeTab = new EventEmitter<any>();

  advancedOptions = {
    user_id: "",
    user_type: "User",
    license_plate:'',
    vin:'',
    customer_name: '',
    customer_email:'',
    customer_mobile:'',
    exculde_selected_vehicle:'',
    start: 0,
    limit: 10,
  }
  public recordsTotal = 0
  public allVehicleList = [];
  public selectedVehicle = [];
  public isAnySelected = false;
  public selectable : any = false
  searchForm: FormGroup;
  constructor(private globalService: GlobalService, private fb: FormBuilder) {
    this.advancedOptions.user_id = JSON.parse(localStorage.getItem("loggedInUser")).id;
  }

  ngOnInit() {
    this.searchForm = this.fb.group({
      license_plate:[''],
      vin:[''],
      customer_name: [''],
      customer_email:[''],
      customer_mobile:[''],
    });
    this.getSearchedVehicle();
  }

  ngAfterViewInit() {
    this.excludedVehicesDrawer.toggle();
  }

  setTouchViewValue() {
    return true;
  }

  updateSelection(index: number){
    this.allVehicleList[index].status = this.allVehicleList[index].status?false:true;
    if(this.allVehicleList[index].status){
      this.selectedVehicle.push(this.allVehicleList[index]);
    }else{
      const i = this.selectedVehicle.map(function(e) { return e.Vehicle_id; }).indexOf(this.allVehicleList[index].Vehicle_id);
      this.selectedVehicle.splice(i, 1);
    }
    if(this.selectedVehicle.filter(e => e.status).length === 0){
      this.isAnySelected = false;
    }else{
      this.isAnySelected = true;
    }
  }

  removeSelected(i){
    const index = this.allVehicleList.map(function(e) { return e.Vehicle_id; }).indexOf(this.selectedVehicle[i].Vehicle_id);
    if(index !== -1){
      this.updateSelection(index);
    }else{
      this.selectedVehicle.splice(i, 1);
    }
  }

  search(){
    if(this.searchForm.controls['license_plate'].value === '' && this.searchForm.controls['vin'].value === '' && this.searchForm.controls['customer_name'].value === '' && this.searchForm.controls['customer_email'].value === '' && this.searchForm.controls['customer_mobile'].value === ''){
      this.globalService.snackbar("error", 'Atleast one of the option from above is required.');
      return;
    }
    this.advancedOptions.start = 0;
    this.getSearchedVehicle();
  }

  getSearchedVehicle(){
    this.advancedOptions.license_plate = this.searchForm.controls['license_plate'].value;
    this.advancedOptions.vin = this.searchForm.controls['vin'].value;
    this.advancedOptions.customer_name = this.searchForm.controls['customer_name'].value;
    this.advancedOptions.customer_email = this.searchForm.controls['customer_email'].value;
    this.advancedOptions.customer_mobile = this.searchForm.controls['customer_mobile'].value;
    if(this.excludeVehicleList.length > 0 && Array.isArray(this.excludeVehicleList)){
      this.advancedOptions.exculde_selected_vehicle =   this.excludeVehicleList.map(itm => itm.vehicle_id).join(','); 
    }
    var content = globalFunctions.stringifyFormData(this.advancedOptions, null);
    this.globalService.callAPI(globalConstants.API_ADVANCED_VEHICLE_SEARCH, content).subscribe((response:any) => {
      if (response.result == 1) {
        this.allVehicleList= response.data;
        this.recordsTotal = response.recordsTotal;
        this.allVehicleList.forEach(e=>{
          if(this.selectedVehicle.some(f => e.Vehicle_id === f.Vehicle_id)){
            e.status = true;
          }else{
            e.status = false;
          }
        });
      } else {
        this.allVehicleList= [];
        this.recordsTotal = 0;
        this.globalService.snackbar("error", response.message);
      };
    });
  }

  paginate(event,type){
    if(type=="next") {
      this.advancedOptions.start = this.advancedOptions.start + this.advancedOptions.limit;
    } else {
      this.advancedOptions.start = this.advancedOptions.start - this.advancedOptions.limit;
    }
    this.getSearchedVehicle(); 
  }

  paginateByLimit(event){
    this.advancedOptions.limit = event.pageSize;
    this.advancedOptions.start = 0;
    this.getSearchedVehicle();
  }

  firstList() {
    if(this.advancedOptions.start == 0){
      return true;      
    }
    return false;
  }
  lastList() {
    if((this.advancedOptions.start + this.advancedOptions.limit) >= this.recordsTotal ){
      return true;
    }
      return false;
  }
  getUpperLimit(currentLastLimit, total) {
      if (currentLastLimit > total) {
          return total
      } else {
          return currentLastLimit
      }
  }

  clearVehileSelection(){
    this.searchForm.patchValue({
      license_plate: '',
      vin: '',
      customer_name: '',
      customer_email: '',
      customer_mobile : ''
    });
    this.allVehicleList.forEach(e=>{
      e.status = false;
    });
    this.advancedOptions.license_plate = '';
    this.advancedOptions.vin = '';
    this.advancedOptions.customer_name = '';
    this.advancedOptions.customer_email = '';
    this.advancedOptions.customer_mobile = '';
    this.advancedOptions.start = 0;
    this.selectedVehicle = [];
    this.getSearchedVehicle();
  }

  closeSideBar(isChanged: boolean = false) {
    if(isChanged){
      this.selectedVehicle.filter(e=> e.status).forEach(e=>{
        this.excludeVehicleList.push(e);
      })
    }
    this.excludedVehicesDrawer.toggle();
    const output = {
      'changed': isChanged,
      'selectedVehicles': this.excludeVehicleList
    }
    setTimeout(() => {
      this.closeTab.emit(output);
    }, 1000);
  }
}


