
<div class="login-container container-fluid padding-left-0 padding-right-0" id="mainContainerAdmin" >
  <div id="loginContentAdmin" class="error-page">
    <div class="well login-panel">
      <mat-card class="card-box mat-card">
        <div class="form-group row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <section class="text-center" id="uc-page-error">
              <div class="page-error-inner">
                <h1 class="uc-main-title">Oop's<br>Some Error Occurred</h1>
                <p>Our Technical Team has been notified </p>
                <!-- <a class="btn uc-btn-default uc-btn-success" href="/">GO BACK TO THE HOMEPAGE</a> -->
                <button class="btn uc-btn-default" (click)="goBack()">Home</button>
                <button class="btn uc-btn-default" (click)="clearCache()">Clear Cache</button>
              </div>
            </section>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
