import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { FormGroup,FormBuilder } from '@angular/forms';
import {globalFunctions} from "../../../../environments/globalFunctions";

@Component({
  selector: 'app-user-authentication-dialog',
  templateUrl: './user-authentication-dialog.component.html',
  styleUrls: ['./user-authentication-dialog.component.scss']
})
export class UserAuthenticationDialogComponent implements OnInit {
  public popUpTitle='USER AUTHENTICATION';
  public note='Please enter your login password for authentication to <b>delete the service request.</b>';
  newPassword:FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalService: GlobalService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<UserAuthenticationDialogComponent>,
    private formBuilder: FormBuilder
    ) { }

  ngOnInit(): void {
    this.newPassword = this.formBuilder.group({
      password:[''],
      user_type: this.globalService.getCurrentUser()["user_type"],
      user_id: this.globalService.getCurrentUser()["id"],
      dataType:'authorize'
    });
    this.globalService.diaLogObj(this.dialogRef);
  }

  authorize(){
    let formdata = this.newPassword.value;
    formdata.password = formdata.password.replaceAll('+','%2B');
    let stringifiedFormData = globalFunctions.stringifyFormData(formdata, null);
    this.globalService.callAPI(globalConstants.API_PASSWORD_AUTHENTICATION_URL,stringifiedFormData).subscribe((res:any)=>{
        let message:string = res.message;
        if(res.result=='1'){
            this.dialogRef.close({passwordOK: true});
        }else{
            this.globalService.snackbar("error", message);
        }
    },
    (err)=>{
    });
}
}
