import { Component, OnInit, Inject, ViewChild,OnDestroy, ViewEncapsulation} from '@angular/core';
import { AbstractControl,FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap, NavigationEnd, Params  } from '@angular/router';
import { Http,Headers, Response} from '@angular/http';
import { globalFunctions } from "../../../environments/globalFunctions"
import { globalConstants } from "../../../environments/globalConstants"
import {GlobalService} from "../../../environments/global.service"
import { lang } from "../../../environments/lang-en"
import { Title } from '@angular/platform-browser';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {AppComponent} from "../../app.component";
import {Subscription} from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-shop-subscription',
    templateUrl: './shop-subscription.component.html',
    styleUrls: ['./shop-subscription.component.scss'],
    encapsulation:ViewEncapsulation.None
})
export class ShopSubscriptionComponent implements OnInit,OnDestroy {

    public subscriptionPlans: any;
    public subscription_id;
    public shop_id;
    public currentCustomer;
    public currentCustomerName;
    public shopPayment:FormGroup;
    public subscription_type;
    public paymentSuccess;
    public profileType:any=false;
    public fleetComments:any='';
    public shopDetails: any={};
    public subscriptionDataForAPI: Subscription = new Subscription();
    public plansList: any =  [];
    // Define Owl Carousel options
    public customOptions: OwlOptions = {
        loop: false,
        margin: 10,
        nav: true,
        startPosition: 1,
        responsive: {
        0: {
            items: 1,
        },
        600: {
            items: 2,
        },
        },
    };
    constructor(public app: AppComponent, private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private route: ActivatedRoute, public titleService: Title, private http: Http, private router: Router, private globalService: GlobalService,  public dialog: MatDialog) {
        if(localStorage.getItem("loggedInUser")) {
            this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
            this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
            this.shopPayment = this.formBuilder.group({
                checkBoxValue: [""],
                yearly_subscription: [""],
                mailchimp_subscription: [""],
            });
        } else {
          this.router.navigate(["/shops"]);
        }
        this.globalService.setMetaData("SHOPS","SUBSCRIPTION");
    }

    ngOnInit() {
        this.profileType=this.globalService.isFleet();
        let payload = {
            user_type: "User",
            opr: 1
          };
          this.globalService.callAPI(globalConstants.API_GET_SHOPS_SUBSCRIPTION_PLANS, payload).subscribe((data: any) => {
            if (data.result == 1) {
                this.subscriptionPlans = data.data;
                if (this.subscriptionPlans.length > 0) {
                    for (let i = 0; i < this.subscriptionPlans.length; i++) {
                        this.subscriptionPlans[i].checked = false;
                        this.subscriptionPlans[i].featuresIncluded = JSON.parse(this.subscriptionPlans[i].features_included);
                        this.subscriptionPlans[i].extrasInfo = JSON.parse(this.subscriptionPlans[i].extras);
                    }

                    var sourcePlanList: any = JSON.parse(JSON.stringify(this.subscriptionPlans));
                    var valuePlanInfo :any = sourcePlanList.filter(function (plan) {
                        let planInfo = (plan.plan_type == 1 && plan.plan_type_group == 1);
                        return planInfo;
                    });
                    var standardPlanInfo :any = sourcePlanList.filter(function (plan) {
                        let planInfo = (plan.plan_type == 1 && plan.plan_type_group == 2);
                        return planInfo;
                    });
                    this.plansList.push(valuePlanInfo[0]);
                    this.plansList.push(standardPlanInfo[0]);
                }
            } else {
                this.globalService.snackbar("error", data.message);
            }
        });
        this.getDetails();
    }

    //code to switching between monthly & yearly plan data
    handlePlanSwitch(event, sub, index) {
        var sourcePlanList :any = JSON.parse(JSON.stringify(this.subscriptionPlans));
        if (index == 0) {
          var planType = event.checked ? 2 : 1;
          var valuePlanInfo: any = sourcePlanList.filter(function (plan) {
            let planInfo = (plan.plan_type == planType && plan.plan_type_group == 1);
            return planInfo;
          });
          this.updatePlanDetails(index, valuePlanInfo[0]);
        } else {
          var planType = event.checked ? 2 : 1;
          var standardPlanInfo: any = sourcePlanList.filter(function (plan) {
            let planInfo = (plan.plan_type == planType && plan.plan_type_group == 2);
            return planInfo;
          });
          this.updatePlanDetails(index, standardPlanInfo[0]);
        }
    }
    updatePlanDetails(index,subscriptionData){
        Object.assign(this.plansList[index],subscriptionData); 
    }

    getPaymentGateway(response, sub,index) {        

       let plan;
       if (this.subscriptionPlans.length) {
        this.subscriptionPlans.forEach((p, i) => {
            
            if (sub.id == p.group_id || sub.id == p.id) {

                if (sub.yearly_subscription && sub.checked && p.mailchimp == '1' && p.duration == '12') {
                    plan= p;
                } else if (sub.id == p.group_id && sub.checked && p.mailchimp == '1' && p.duration == '1') {
                    plan= p;
                } else if (sub.id == p.group_id && sub.yearly_subscription && p.mailchimp == '0' && p.duration == '12') {
                    plan= p;
                }else if (sub.id == p.id && (!sub.yearly_subscription) && (!sub.checked)) {
                    plan= p;
                    return true;
                }
            }
        })
    }
        localStorage.setItem("subscription_id",plan.id);
        
        let dialogRef;
        dialogRef = this.dialog.open(addCCFORM, {
            disableClose:true,
            panelClass: 'car-dialog-form',
            data:{plan_id:plan.bt_plan_id,plan_name:plan.subscription_name,plan_amount:plan.amount,mail_chimp:plan.mailchimp,subscriptionType:plan.subscription_type,subscriptionId:plan.id}
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result.data == 1){
                this.paymentSuccess = true;
                this.app.getCurrentShopSubscription();
                this.router.navigate(["shops/payment-success"]);
            }
            if(result.data == 0){
                this.paymentSuccess = false;
                //this.router.navigate(["shops/payment-fail"]);
                this.globalService.snackbar('error', localStorage.getItem("shopPaySuccessMsg"));
            }
        });
    }

    /** send message to admin */
    fleetComment(){
        let data={user_id:this.currentCustomer.id,user_type:this.currentCustomer.user_type,comment:this.fleetComments};
        let content=this.globalService.formsData(data);
        this.globalService.formData(globalConstants.API_FLEET_REQUEST_MAIL,content).subscribe((r:any)=>{
            if(r.result==1){
                this.globalService.snackbar('success', r.message);
                this.fleetComments='';
                this.getDetails();
            }else{
                this.globalService.snackbar('error', r.message);
            }
        },(error)=>{})
    }
    /** get profile shop/fleet detail */
    getDetails(){
        let data={user_id:this.currentCustomer.id,user_type:this.currentCustomer.user_type,shop_id:this.currentCustomer.shop_id};
        this.subscriptionDataForAPI =  this.globalService.callAPI(globalConstants.API_GET_SHOP_DETAILS_ON_DASHBOARD, data).subscribe((res:any) => {
             if(res.result == "1"){
              this.shopDetails = res.data;
             }
        },(error)=>{})
    }
    /** show tawk.to chat*/
    showChat() {
        globalFunctions.supportChat();
    }

    ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
    }
    
}

/************
    Add CC FORM  Dialog 
***************/
@Component({
  templateUrl: 'credit-card-form.html',
})
export class addCCFORM implements  OnDestroy {
    public ccForm: FormGroup;
    public ccFormExisting: FormGroup;
    public ccMask = [/[1-9]/, /\d/,/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/,'-', /\d/, /\d/, /\d/, /\d/,];
    public currentCustomer;
    public currentCustomerName;
    public first_name;
    public last_name;
    public email;
    public address1;
    public city;
    public state;
    public zip;
    public country;
    public cell_phone1;
    public user_type;
    public user_id;
    public planName;
    public planAmount;
    public shop_id;
    public paymentSuccess;
    public subscriptionMessage;
    
    public isSubscribeld;
    public displayForm;
    public cardHolderName;
    public CardType;
    public ccMaskedNumber;
    public ccExpiryDay;
    public ccInfoResult;
    public defaultCheck;
    public displayNewForm;
    public paymentToken;
    public btCustomerID;
    public plan_id;
    public mailChimp;
    public subscriptionID;
    public subscriptionType;
    public btSubscriptionID;
    public coupon_code='';
    public showCouponCode=true;
    public subscriptionDataForAPI: Subscription = new Subscription();
    constructor(private activatedRoute: ActivatedRoute, private router: Router, private http: Http,public dialogRef: MatDialogRef<addCCFORM>, @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private globalService: GlobalService) {
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
        this.currentCustomerName = this.currentCustomer.first_name+" "+this.currentCustomer.last_name;
        /* USER LOGIN DETAILS */
        this.plan_id        = data.plan_id;
        this.mailChimp      = data.mail_chimp;
        this.subscriptionID = data.subscriptionId;
        this.subscriptionType = data.subscriptionType;
        this.first_name     = this.currentCustomer.first_name;
        this.last_name      = this.currentCustomer.last_name;
        this.email          = this.currentCustomer.email;
        this.address1       = this.currentCustomer.address1;
        this.city           = this.currentCustomer.city;
        this.state          = this.currentCustomer.state;
        this.country        = this.currentCustomer.country;
        this.zip            = this.currentCustomer.zip;
        this.cell_phone1    = this.currentCustomer.cell_phone1;
        this.user_type      = this.currentCustomer.user_type;
        this.user_id        = this.currentCustomer.id;
        this.shop_id        = this.currentCustomer.shop_id;
        this.isSubscribeld  = this.currentCustomer.is_subscribed;
        this.globalService.diaLogObj(this.dialogRef);
        if(this.subscriptionType=="10"){
            this.showCouponCode=false;
        }
        if(this.isSubscribeld == 'true'){
            // console.log("1");
            this.defaultCheck = true;
            var resquestdata = "user_id=" + this.globalService.getCurrentUser().id+ "&shop_id=" + this.globalService.getCurrentUser().shop_id+ "&user_type=" + this.globalService.getCurrentUser().user_type;
            this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_BT_CC_INFO_URL, resquestdata).subscribe((data:any) => {
                if (data.result == '1') {
                    // console.log("2");
                    this.ccInfoResult = data.data;
                    if(data.data != null){
                        this.cardHolderName = this.ccInfoResult.cardholderName;
                        this.CardType       = this.ccInfoResult.cardType;
                        this.ccMaskedNumber = this.ccInfoResult.maskedNumber;
                        this.ccExpiryDay    = this.ccInfoResult.expirationDate;
                        this.paymentToken   = this.ccInfoResult.payment_token;
                        this.btCustomerID   = this.ccInfoResult.bt_customer_id;
                        this.btSubscriptionID = this.currentCustomer.subscription_data.bt_subscription_id;
                        this.displayForm = false;
                    this.formInitWithExsitingCC();
                    } else {
                        this.displayForm = true;
                        this.formInitWithNewCC();
                        this.cardHolderName = '';
                    }
                } else {
                    this.displayForm = true;
                    this.formInitWithNewCC();
                    this.cardHolderName = '';
                }
            });
        } else {
            this.displayForm = true;
            this.defaultCheck = false;
            this.formInitWithNewCC();
        }
        
        /* END */
        this.planName = data.plan_name;
        this.planAmount = parseFloat(data.plan_amount).toFixed(2);
    }
    
    formInitWithNewCC(){
        this.ccForm = this.formBuilder.group({
            plan_id: [this.plan_id],
            cc_name: ['', [Validators.required, Validators.pattern(globalConstants.NAME_REGEX)]],
            cc_number: ['',[Validators.required, Validators.pattern(globalConstants.DIGIT_ONLY_REGEX)]],
            cvv: ['', [Validators.required, Validators.pattern(globalConstants.DIGIT_ONLY_REGEX)]],
            exp_month: ['', [Validators.required, Validators.pattern(globalConstants.DIGIT_ONLY_REGEX)]],
            exp_year: ['', [Validators.required, Validators.pattern(globalConstants.DIGIT_ONLY_REGEX)]],
            first_name: [this.first_name],
            last_name: [this.last_name],
            email: [this.email],
            address1: [this.address1],
            city: [this.city],
            state: [this.state],
            country: [this.country],
            zip: [this.zip],
            cell_phone1: [this.cell_phone1],
            user_type: [this.user_type],
            user_id: [this.user_id],
            shop_id: [this.shop_id],
            mail_chimp: [this.mailChimp],
            subscription_id: [this.subscriptionID],
            subscription_type: [this.subscriptionType],
            opr:2
        });
    }
    
    formInitWithExsitingCC(){
        this.ccFormExisting = this.formBuilder.group({
            plan_id: [this.plan_id],
            cc_name: [this.currentCustomerName, [Validators.required, Validators.pattern(globalConstants.NAME_REGEX)]],
            cc_number: ['',[Validators.required, Validators.pattern(globalConstants.DIGIT_ONLY_REGEX)]],
            cvv: ['', [Validators.required, Validators.pattern(globalConstants.DIGIT_ONLY_REGEX)]],
            exp_month: ['', [Validators.required, Validators.pattern(globalConstants.DIGIT_ONLY_REGEX)]],
            exp_year: ['', [Validators.required, Validators.pattern(globalConstants.DIGIT_ONLY_REGEX)]],
            first_name: [this.first_name],
            last_name: [this.last_name],
            email: [this.email],
            address1: [this.address1],
            city: [this.city],
            state: [this.state],
            country: [this.country],
            zip: [this.zip],
            cell_phone1: [this.cell_phone1],
            user_type: [this.user_type],
            user_id: [this.user_id],
            shop_id: [this.shop_id],
            mail_chimp: [this.mailChimp],
            subscription_id: [this.subscriptionID],
            subscription_type: [this.subscriptionType],
            payment_token: [this.paymentToken],
            bt_customer_id: [this.btCustomerID],
            bt_subscription_id: [this.btSubscriptionID],
            opr: 2
        });
    }
    
    changePaymetForm(event) {
        if(event.value == '1') {
            this.displayNewForm = false;
        }
        if(event.value == '2') {
            this.displayNewForm = true;
        }
    } 
    
    
    onlyDecimalNumberKey(event) {
        let charCode = (event.which) ? event.which : event.keyCode;
        if (charCode < 48 || charCode > 57)
            return false;
        return true;
    }

    disableKey(event) {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if (event.ctrlKey==true && (event.which == '67' || event.which == '86' ||  String.fromCharCode(event.which).toLowerCase() == 's')) {
            console.log('thou. shalt. not. PASTE!');
            event.preventDefault();
        }
    }
    
//     getCardType(event) //code cleanup after revisit
//     {
//         let ele:any = <HTMLInputElement>document.getElementById('cardnumber');
//         let number:any=ele.value;
//         let cardtype='';
//         //console.log("Number",number,ele);
//         // visa
//         var re = new RegExp("^4");
//         if (number.match(re) != null)
//             //console.log("VISA");
//             cardtype= '<img src="../../../assets/img/payment.png"/ style="margin-left: -2px;">';

//         // Mastercard 
//         // Updated for Mastercard 2017 BINs expansion
//         /*if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) 
//             cardtype= "Mastercard";*/
            
//         var re = new RegExp("^5");
//         if (number.match(re) != null)
//             //console.log("VISA");
// //            cardtype= "Mastercard";
//             cardtype= '<img src="../../../assets/img/payment.png"/ style="margin-left: -63px;">';
            
//         // AMEX
//         re = new RegExp("^3[47]");
//         if (number.match(re) != null)
// //            console.log("AMEX");
//             cardtype= '<img src="../../../assets/img/payment.png"/ style="margin-left: -368px;">';
            

//         // Discover
//         re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
//         if (number.match(re) != null)
// //            cardtype= "Discover";
//             cardtype= '<img src="../../../assets/img/payment.png"/ style="margin-left: -245px;">';

//         // Diners
//         re = new RegExp("^36");
//         if (number.match(re) != null)
// //            cardtype= "Diners";
//             cardtype= '<img src="../../../assets/img/payment.png"/ style="margin-left: -185px;">';

//         // Diners - Carte Blanche
//         re = new RegExp("^30[0-5]");
//         if (number.match(re) != null)
// //            cardtype= "Diners - Carte Blanche";
//             cardtype= '<img src="../../../assets/img/payment.png"/ style="margin-left: -185px;">';

//         // JCB
//         re = new RegExp("^35(2[89]|[3-8][0-9])");
//         if (number.match(re) != null)
// //            cardtype= "JCB";
//             cardtype= '<img src="../../../assets/img/payment.png"/ style="margin-left: -306px">';

//         // Visa Electron
//         re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
//         if (number.match(re) != null)
// //            cardtype= "Visa Electron";
//             cardtype= '<img src="../../../assets/img/payment.png"/ style="margin-left: -124px;">';
            
//         let cardEle:any = <HTMLElement>document.getElementById('cardIcon');
//         cardEle.innerHTML=cardtype;
//         //console.log("Number",cardtype,cardEle);
//         return "";
//     }
    handleCardTypeImg(){ 
        let cardEle:any = <HTMLElement>document.getElementById('cardIcon');
        var cardImage = this.globalService.getCardTypeImage(this.ccFormExisting.value.cc_number);
        cardEle.innerHTML= cardImage;
       }

    
    /* Pay Now From CC Pop Up FIRST TIME PAYMENT */
    
    payNow(){ 
        if (this.ccForm.valid) {
            var data = new FormData();
            for (let i in this.ccForm.value) {
                data.append(i, this.ccForm.value[i]);
            }
            if(this.coupon_code){
                data.append('coupon_code',this.coupon_code);
            }
            this.globalService.formData(globalConstants.API_ADD_BT_SUBSCRIPTION_URL,data).subscribe((data:any)=> {
                localStorage.setItem("shopPaySuccessMsg", data.message);
                if (data.result == "1") {
                    this.paymentSuccess = true;
                    data["data"]["timeStamp"] = JSON.parse(localStorage.getItem("loggedInUser"))["timeStamp"]
                    localStorage.setItem("loggedInUser",JSON.stringify(data["data"]));
                    return this.dialogRef.close({ data: 1 });
                } else {
                    this.paymentSuccess = false;
                    this.globalService.snackbar('error', data.message);
                }
            });
        } else {
            this.globalService.snackbar("error", globalConstants.FORM_SUBMISSION_VALIDATION_WARNING)
        }
    } 
    
    /* PAY NOW WITH NEW CREDIT CARD IF PAYMENT IS DONE PREVIOUSLY */
    
    payNowWithNewCC(){ 
        if (this.ccFormExisting.valid) {
            var data = new FormData();
            for (let i in this.ccFormExisting.value) {
                data.append(i, this.ccFormExisting.value[i]);
            }
            if(this.coupon_code){
                data.append('coupon_code',this.coupon_code);
            }
           
            this.globalService.formData(globalConstants.API_SHOP_SUBSCRIPTION_WITH_NEW_CC,data).subscribe((data:any)=> {
                
                localStorage.setItem("shopPaySuccessMsg", data.message);
                if (data.result == "1") {
                    this.paymentSuccess = true;
                    data["data"]["timeStamp"] = JSON.parse(localStorage.getItem("loggedInUser"))["timeStamp"]
                    localStorage.setItem("loggedInUser",JSON.stringify(data["data"]));
                    let params = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&shop_id=" + this.globalService.getCurrentUser()["shop_id"];
                    this.globalService.callAPI(globalConstants.API_GET_SHOP_DETAILS_ON_DASHBOARD, params).subscribe((res) => {
                        if(res.result == "1"){
                            this.globalService.setShopDetails(res.data);
                            return this.dialogRef.close({ data: 1 });
                        }else{
                            return this.dialogRef.close({ data: 1 });
                        }
                    });
                } else {
                    this.paymentSuccess = false;
                    this.globalService.snackbar('error', data.message);
                }
            });
        } else {            
            this.globalService.snackbar("error", globalConstants.FORM_SUBMISSION_VALIDATION_WARNING)
        }
    } 
    
    payNowWithExistingCC(){
        let previousInfo = {
            plan_id: this.plan_id,
            cc_name: this.currentCustomerName,
            first_name:this.first_name,
            last_name: this.last_name,
            email: this.email,
            address1:this.address1,
            city: this.city,
            state:this.state,
            country: this.country,
            zip: this.zip,
            cell_phone1: this.cell_phone1,
            user_type: this.user_type,
            user_id: this.user_id,
            shop_id: this.shop_id,
            mail_chimp: this.mailChimp,
            subscription_id: this.subscriptionID,
            subscription_type:this.subscriptionType,
            payment_token: this.paymentToken,
            bt_customer_id: [this.btCustomerID],
            bt_subscription_id: [this.btSubscriptionID],
            coupon_code : this.coupon_code
        };
       
        this.subscriptionDataForAPI =  this.globalService.callAPI(globalConstants.API_SHOP_PAY_WITH_EXISTING_CC,previousInfo).subscribe((data:any)=> {
            
            localStorage.setItem("shopPaySuccessMsg", data.message);
            if (data.result == "1") {
                this.paymentSuccess = true;
                data["data"]["timeStamp"] = JSON.parse(localStorage.getItem("loggedInUser"))["timeStamp"]
                localStorage.setItem("loggedInUser",JSON.stringify(data["data"]));
                let params = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&shop_id=" + this.globalService.getCurrentUser()["shop_id"];
                this.globalService.callAPI(globalConstants.API_GET_SHOP_DETAILS_ON_DASHBOARD, params).subscribe((res) => {
                    if(res.result == "1"){
                        this.globalService.setShopDetails(res.data);
                        return this.dialogRef.close({ data: 1 });
                    }else{
                        return this.dialogRef.close({ data: 1 });
                    }
                });               
            } else {
                 this.paymentSuccess = false;
                this.globalService.snackbar('error', data.message);
            }
        });
    }
     
    close(){
         return this.dialogRef.close({ data: 3 });
    }

    validateFieldOnPasteINCcFormExisting(controlName: string) {
        const control = this.ccFormExisting.get(controlName);
        control.markAsTouched();
    }

    validateFieldOnPasteINCcForm(controlName: string) {
        const control = this.ccForm.get(controlName);
        control.markAsTouched();
    }

    ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
    }  
}
