import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { Http } from '@angular/http';
import { globalFunctions } from '../../../../environments/globalFunctions';
import { globalConstants } from '../../../../environments/globalConstants';

@Component({
  selector: 'app-job-approval-dialog',
  templateUrl: './job-approval-dialog.component.html',
  styleUrls: ['./job-approval-dialog.component.scss']
})
export class JobApprovalDialogComponent implements OnInit {

  public jobApprovalForm:FormGroup;
  public serviceList:Array<any>;
  public recomStates : any = {};
  public access_timestamp = '';
  private areAllJobsRejected:any;
  public getRequireApproval:any;
  public getApprovalAmount:any;
  public finalShowAbleAmount:any = 0;

  public subTotal:any = 0;
  public grandTotal:any = 0;
  public discount:any =0;
  public taxRate:any =0;
  public taxAmount:any = 0;
  public supplyCharges:any = 0;
  public disableAcceptAndRejectButton: boolean =false


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private formBuilder: FormBuilder, public dialogRef: MatDialogRef<JobApprovalDialogComponent>, private gs: GlobalService,public http:Http) { }

  ngOnInit() {
    let user = this.gs.getCurrentUser();
    if (!user) {
      this.dialogRef.close(false);
    }
    this.gs.diaLogObj(this.dialogRef);
    this.access_timestamp = this.data.access_timestamp;
    let fData = new FormData();
    fData.append('user_id',user.id);
    fData.append('user_type',user.user_type);
    fData.append('request_id',this.data.requestId);
    this.gs.formData(globalConstants.API_GET_SERVICE_REQUEST_JOBS, fData).subscribe((response:any) => {
      if(response.result == "1") {
        this.serviceList = response.data;
        this.subTotal = parseFloat(response.sub_total);
        this.grandTotal = parseFloat(response.grand_total);
        this.discount = this.toFormattedDecimal(response.discount.total_discounted_amount,2);
        this.finalShowAbleAmount = parseFloat(response.grand_total) - this.discount;
        if(this.finalShowAbleAmount < 0){
          this.finalShowAbleAmount = 0;
        }
        this.taxRate = response.tax_rate;
        this.taxAmount = parseFloat(response.tax_amount);
        this.supplyCharges = parseFloat(response.supplies_charges);
        if(this.serviceList.length >0){
          this.serviceList.forEach(ele => {
            this.recomStates[ele.id] = {
              'approval_status':ele.approval_status,
              'job_total':ele.job_total,
              'old_approval_status':ele.old_approval_status,
              'acp_text':ele.approval_status == 1 ? 'Undo Accept' : 'Accept',
              'rej_text':ele.approval_status == 2 ? 'Undo Reject' : 'Reject'
            };
          });
        }else{
          this.disableAcceptAndRejectButton = true;
        }
      } else{
          this.disableAcceptAndRejectButton = true;
      }
    });
    var customerOprs = "user_id="+user.id+"&user_type="+user.user_type+"&customer_id="+ this.data.customer_id;
    this.gs.callAPI(globalConstants.API_GET_CUSTOMER_DETAILS, customerOprs).subscribe((response:any) => {
        if (response.result == '1') {
            this.getRequireApproval = response.data.require_approval;
            this.getApprovalAmount = response.data.approval_amount;
        }
    });
    this.jobApprovalForm = this.formBuilder.group({
      request_id: [this.data.requestId]
    });
  }

  toggleJobApproval(recomId,level1Id,type){
    const currentStatus = this.recomStates[recomId].approval_status;
    let newStatus = 0;
    if(type == 'accept'){
      if(currentStatus == 0 || currentStatus == 2){
        newStatus = 1;
        //if(currentStatus == 2) this.finalShowAbleAmount += parseFloat(this.recomStates[recomId].job_total);
      }else{
        newStatus = 0;
      }
    }else{
      newStatus = (currentStatus == 0 || currentStatus == 1) ? 2 : 0;
      if(currentStatus == 0 || currentStatus == 1){
        // newStatus = 2; if(currentStatus == 1) // this.finalShowAbleAmount -= parseFloat(this.recomStates[recomId].job_total);
      }else{
        // newStatus = 0; if(currentStatus == 2) // this.finalShowAbleAmount += parseFloat(this.recomStates[recomId].job_total);
      }
    }
    this.recomStates[recomId].approval_status = newStatus;
    this.updateRecomButtonText(recomId,newStatus);
    let content = 'access_timestamp=' + this.access_timestamp + "&rec_id=" + recomId + "&status="+newStatus+"&level1Id="+level1Id;
      this.gs.callAPI(globalConstants.API_UPDATE_RECOMMENDATION_STATUS_URL, content).subscribe((data:any)=>{
        if(data.result == "1"){
          this.access_timestamp = data.access_timestamp;
          this.subTotal = parseFloat(data.sub_total);
          this.finalShowAbleAmount = data.grand_total - this.discount;
          if(this.finalShowAbleAmount < 0){
            this.finalShowAbleAmount = 0;
          }
          this.taxAmount     = parseFloat(data.tax_total);
          this.supplyCharges = parseFloat(data.supplies_charges);
          this.gs.snackbar("success", data.message);
        }else{
          this.gs.snackbar("error", data.message);
        }
      });
  }

  updateRecomButtonText(recomId,newStatus){
    this.recomStates[recomId].acp_text = (newStatus == 1) ? 'Undo Accept' : 'Accept'
    this.recomStates[recomId].rej_text = (newStatus == 2) ? 'Undo Reject' : 'Reject'
  }

  applyChanges() {
    if (this.canApplyChanges()) {
      this.checkRejectedJobs();
      let r=(parseFloat(this.finalShowAbleAmount) > parseFloat( this.getApprovalAmount));
      if(this.getRequireApproval == '1' && r) {
        // If require approval TRUE
        this.dialogRef.close({
          action:'requireApproval',
          data:{
            access_timestamp:this.access_timestamp,
            areAllJobsRejected:this.areAllJobsRejected
          }
        });
      } else {
        // If require approval FALSE
        let content='access_timestamp='+this.access_timestamp+"&request_id=" + this.data.requestId;
        content += this.data.skip_reassign ? "&skip_reassign=" + this.data.skip_reassign : '';
        this.gs.callAPI(globalConstants.API_UPDATE_NOTIFY_TO_ADMIN_URL,content ).subscribe((response:any) => {
          if (response.result == "1") {
            this.access_timestamp = response.access_timestamp;
            this.gs.snackbar("success", response.message);
            this.dialogRef.close({action:'goToCompletion',data:{access_timestamp:this.access_timestamp}});
          } else {
            this.gs.snackbar("error", response.message);
          }
        });
      }
    } else {
      this.gs.snackbar("error", 'Please provide the approval for every job otherwise reject them.');
    }
  }

  canApplyChanges(){
    let res = true;
    for(let rec in this.recomStates){
      if(this.recomStates[rec].approval_status == 0){
        res = false;
      }
    }
    return res;
  }

  checkRejectedJobs(){
    this.areAllJobsRejected = true;
    for(let rec in this.recomStates){
      if(this.recomStates[rec].approval_status == 1){
        this.areAllJobsRejected = false;
      }
    }
  }

  closeDialog(){
    this.dialogRef.close({action:'close',data:{access_timestamp:this.access_timestamp}})
  }

  /** Accept all current jobs */
  acceptAll(){
    const recIds = Object.keys(this.recomStates);
      // API_update_all_recommendation_statuses.php
    let content = 'access_timestamp=' + this.access_timestamp + "&rec_ids=" + recIds + "&status=1&from_v2=1";
      this.gs.callAPI(globalConstants.API_UPDATE_ALL_RECOMMENDATION_STATUES, content).subscribe((data:any)=>{
        if(data.result==1){
          this.gs.snackbar("success", data.message);
          this.access_timestamp = data.access_timestamp;
          
          // this.finalShowAbleAmount = 0;
          this.subTotal = parseFloat(data.sub_total);
          this.finalShowAbleAmount = data.grand_total - this.discount;
          if(this.finalShowAbleAmount < 0){
            this.finalShowAbleAmount = 0;
          }
          this.taxAmount = parseFloat(data.tax_total);
          this.supplyCharges = parseFloat(data.supplies_charges);
          
          for(let rec in this.recomStates){
            this.recomStates[rec].approval_status = 1;
            this.updateRecomButtonText(rec,1);
            //this.finalShowAbleAmount += this.recomStates[rec].job_total;
          }
        }else{
          this.gs.snackbar("error", data.message);
        }
      });
  }

  /** Reject all current jobs */
  rejectAll(){
    const recIds = Object.keys(this.recomStates);
    let content ='access_timestamp=' + this.access_timestamp + "&rec_ids=" + recIds + "&status=2&from_v2=1";
    this.gs.callAPI(globalConstants.API_UPDATE_ALL_RECOMMENDATION_STATUES, content).subscribe((data:any)=>{
      if(data.result==1){
        this.gs.snackbar("success", data.message);
        this.access_timestamp = data.access_timestamp;
        this.subTotal = parseFloat(data.sub_total);
        this.finalShowAbleAmount = data.grand_total - this.discount;
        if(this.finalShowAbleAmount < 0){
          this.finalShowAbleAmount = 0;
        }
        this.taxAmount     = parseFloat(data.tax_total);
        this.supplyCharges = parseFloat(data.supplies_charges);
        //this.finalShowAbleAmount = 0;
        for(let rec in this.recomStates){
          this.recomStates[rec].approval_status = 2;
          this.updateRecomButtonText(rec,2);
        }
      }else{
        this.gs.snackbar("error", data.message);
      }
    });
  }

  /** apply previous status if any on current jobs */
  applyPrevious() {
    let data = { user_id: this.gs.getCurrentUser()["id"], user_type: this.gs.getCurrentUser()["user_type"], request_id: this.data.requestId, access_timestamp: this.access_timestamp };
    this.gs.callAPI(globalConstants.API_USE_PREVIOUS_STATUS, data).subscribe((r:any) => {
      if (r.result == 1) {
        this.gs.snackbar("success", r.message);
        this.access_timestamp = r.access_timestamp;
        this.subTotal = parseFloat(r.sub_total);
        this.finalShowAbleAmount = r.grand_total - this.discount;
        if(this.finalShowAbleAmount < 0){
          this.finalShowAbleAmount = 0;
        }
        this.taxAmount = parseFloat(r.tax_total);
        this.supplyCharges = parseFloat(r.supplies_charges);
        for(let rec in this.recomStates){
          this.recomStates[rec].approval_status = this.recomStates[rec].old_approval_status;
          this.updateRecomButtonText(rec,this.recomStates[rec].old_approval_status);
        }
      } else {
        this.gs.snackbar("error", r.message);
      }
    }, (error) => { })
  }

  /** check for use previous availability */
  canUsePrevious(){
    let res = false;
    for(let rec in this.recomStates){
      if(this.recomStates[rec].old_approval_status != 0){
        res = true;break;
      }
    }
    return res;
  }

  toFormattedDecimal(value,prec){
    return parseFloat(value).toFixed(prec);
  }
}