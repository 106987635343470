import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
    public id: any;
    public message: string;
    public labelNo: string;
    public labelYes: string;
    public labelRm: string;
    public data:any;
    public serviceDetail:any;
    public markAsComplete:boolean = false;
    public downloadInvoice:boolean = false;
    public bulkOprConsent:boolean = false;  //used in part list bulk operation
    public displayErrMsg : boolean = false;

    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {
        this.labelNo = 'No';
        this.labelYes = 'Yes';
        this.labelRm = 'Rm';
    }
    
    ngOnInit() {
        if(this.data && this.id == 'open_pay'){
            // console.log('data = ', this.data);
            // this.serviceDetail = this.data.serviceDetail.value;
        }
    }
    
   selectNo(){
    this.dialogRef.close('No');
   }

    handleBulkOprConsent() {
        if (!this.bulkOprConsent) {
            this.displayErrMsg = true;
        } else {
            this.displayErrMsg = false;
        }
    }

    processPartsBulkOperation() {
        if (this.bulkOprConsent) {
            this.dialogRef.close({
                id: this.id, bulkOprConsent: this.bulkOprConsent
            })
        } else {
            this.displayErrMsg = true;
        }
    }
}
