import { Component, OnInit, ViewEncapsulation, Inject,AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { globalFunctions } from '../../../../environments/globalFunctions';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';


@Component({
  selector: 'app-usps-vehicle-inspection-report',
  templateUrl: './usps-vehicle-inspection-report.component.html',
  styleUrls: ['./usps-vehicle-inspection-report.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UspsVehicleInspectionReportComponent implements OnInit {
  public uspsReport;
  public reportData;
  public service_request_id;
  public carRequestId: any;
  public reqPath=location.origin+'/shops/view-service-request/';
  constructor(
    public dialogRef: MatDialogRef<UspsVehicleInspectionReportComponent>,
   @Inject(MAT_DIALOG_DATA) public data: any, 
    private formBuilder: FormBuilder, 
    private globalService: GlobalService
  ) { 
    this.reportData = data.report_data;
    this.service_request_id=data.service_request_id;
    this.carRequestId = data.fullRequestId;
    this.globalService.diaLogObj(this.dialogRef);
    this.initForm();
    this.setReportData();
  }

  ngOnInit(): void {
  }

  setReportData() {
    if (this.reportData) {
      for (var i in this.reportData) {
        try {
          this.uspsReport.controls[i].setValue(this.reportData[i]);
        } catch (e) { }
      }
    }else{
      this.uspsReport.controls.service_request_id.setValue(this.service_request_id);
    }

  }
  saveUsps() {
    let url = globalConstants.API_FILL_INSPECTION_REPORT_USPS_URL;
    let data = globalFunctions.stringifyFormData(this.uspsReport.value, this.globalService.getCurrentUser()["user_type"]);
    data = data + "&user_id=" + this.globalService.getCurrentUser()["id"];
    this.globalService.callAPI(url, data).subscribe((result:any) => {
      if (result.result == '1') {
        this.dialogRef.close({
          status:"success",
          access_timestamp:result.access_timestamp
        });
        this.globalService.snackbar('success', result.message);
      } else {
        this.globalService.snackbar("error", result.message);
      }
    });
    // this.dialogRef.close();
  }
  initForm() {
    this.uspsReport = this.formBuilder.group({
      vehicle_number: [''],
      assigned_location: [''],
      due_date: [''],
      type_pmi: [''],
      cab_area_A_1: [''],
      cab_area_B_1: [''],
      cab_area_A_2: [''],
      cab_area_B_2: [''],
      cab_area_A_3: [''],
      cab_area_B_3: [''],
      cab_area_A_4: [''],
      cab_area_B_4: [''],
      cab_area_A_5: [''],
      cab_area_B_5: [''],
      cab_area_A_6: [''],
      cab_area_B_6: [''],
      cab_area_A_7: [''],
      cab_area_B_7: [''],
      cab_area_A_8: [''],
      cab_area_B_8: [''],
      cab_area_A_9: [''],
      cab_area_B_9: [''],
      cab_area_A_10: [''],
      cab_area_B_10: [''],
      cab_area_textval_10: [''],
      cab_area_A_11: [''],
      cab_area_B_11: [''],
      cab_area_A_12: [''],
      cab_area_B_12: [''],
      cab_area_A_13: [''],
      cab_area_B_13: [''],
      cab_area_A_14: [''],
      cab_area_B_14: [''],
      cab_area_A_15: [''],
      cab_area_B_15: [''],
      cab_area_A_16: [''],
      cab_area_B_16: [''],
      cab_area_A_17: [''],
      cab_area_B_17: [''],
      cab_area_A_18: [''],
      cab_area_B_18: [''],
      cab_area_A_19: [''],
      cab_area_B_19: [''],
      cab_area_A_20: [''],
      cab_area_B_20: [''],
      cab_area_A_21: [''],
      cab_area_B_21: [''],
      cab_area_A_22: [''],
      cab_area_B_22: [''],
      cab_area_textboxval_22: [''],
      circle_inspection_A_1: [''],
      circle_inspection_B_1: [''],
      circle_inspection_A_2: [''],
      circle_inspection_B_2: [''],
      circle_inspection_A_3: [''],
      circle_inspection_B_3: [''],
      circle_inspection_textval_3_lf: [''],
      circle_inspection_textval_3_rf: [''],
      circle_inspection_textval_3_lr: [''],
      circle_inspection_textval_3_rr: [''],
      circle_inspection_A_4: [''],
      circle_inspection_B_4: [''],
      circle_inspection_A_5: [''],
      circle_inspection_B_5: [''],
      circle_inspection_A_6: [''],
      circle_inspection_B_6: [''],
      circle_inspection_A_7: [''],
      circle_inspection_B_7: [''],
      circle_inspection_A_8: [''],
      circle_inspection_B_8: [''],
      circle_inspection_A_9: [''],
      circle_inspection_B_9: [''],
      circle_inspection_A_10: [''],
      circle_inspection_B_10: [''],
      circle_inspection_A_11: [''],
      circle_inspection_B_11: [''],
      circle_inspection_A_12: [''],
      circle_inspection_B_12: [''],
      circle_inspection_textboxval_12: [''],
      circle_inspection_A_13: [''],
      circle_inspection_B_13: [''],
      circle_inspection_textboxval_13: [''],
      underneath_inspect_lube_A_1: [''],
      underneath_inspect_lube_B_1: [''],
      underneath_inspect_lube_A_2: [''],
      underneath_inspect_lube_B_2: [''],
      underneath_inspect_lube_A_3: [''],
      underneath_inspect_lube_B_3: [''],
      underneath_inspect_lube_A_4: [''],
      underneath_inspect_lube_B_4: [''],
      underneath_inspect_lube_textval_4_lf: [''],
      underneath_inspect_lube_textval_4_rf: [''],
      underneath_inspect_lube_textval_4_lr: [''],
      underneath_inspect_lube_textval_4_rr: [''],
      underneath_inspect_lube_A_5: [''],
      underneath_inspect_lube_B_5: [''],
      underneath_inspect_lube_A_6: [''],
      underneath_inspect_lube_B_6: [''],
      underneath_inspect_lube_A_7: [''],
      underneath_inspect_lube_B_7: [''],
      underneath_inspect_lube_A_8: [''],
      underneath_inspect_lube_B_8: [''],
      underneath_inspect_lube_A_9: [''],
      underneath_inspect_lube_B_9: [''],
      underneath_inspect_lube_A_10: [''],
      underneath_inspect_lube_B_10: [''],
      underneath_inspect_lube_A_11: [''],
      underneath_inspect_lube_B_11: [''],
      underneath_inspect_lube_A_12: [''],
      underneath_inspect_lube_B_12: [''],
      underneath_inspect_lube_A_13: [''],
      underneath_inspect_lube_B_13: [''],
      underneath_inspect_lube_textboxval_13: [''],
      underneath_inspect_lube_A_14: [''],
      underneath_inspect_lube_B_14: [''],
      underneath_inspect_lube_textboxval_14: [''],
      underneath_inspect_lube_A_15: [''],
      underneath_inspect_lube_B_15: [''],
      underneath_inspect_lube_textboxval_15: [''],
      engine_compartment_area_A_1: [''],
      engine_compartment_area_B_1: [''],
      engine_compartment_area_A_2: [''],
      engine_compartment_area_B_2: [''],
      engine_compartment_area_textval_2: [''],
      engine_compartment_area_A_3: [''],
      engine_compartment_area_B_3: [''],
      engine_compartment_area_A_4: [''],
      engine_compartment_area_B_4: [''],
      engine_compartment_area_A_5: [''],
      engine_compartment_area_B_5: [''],
      engine_compartment_area_A_6: [''],
      engine_compartment_area_B_6: [''],
      engine_compartment_area_A_7: [''],
      engine_compartment_area_B_7: [''],
      engine_compartment_area_A_8: [''],
      engine_compartment_area_B_8: [''],
      engine_compartment_area_A_9: [''],
      engine_compartment_area_B_9: [''],
      engine_compartment_area_A_10: [''],
      engine_compartment_area_B_10: [''],
      engine_compartment_area_A_11: [''],
      engine_compartment_area_B_11: [''],
      engine_compartment_area_A_12: [''],
      engine_compartment_area_B_12: [''],
      engine_compartment_area_A_13: [''],
      engine_compartment_area_B_13: [''],
      engine_compartment_area_textval_13_volts: [''],
      engine_compartment_area_A_14: [''],
      engine_compartment_area_B_14: [''],
      engine_compartment_area_textval_14_amps: [''],
      engine_compartment_area_A_15: [''],
      engine_compartment_area_B_15: [''],
      engine_compartment_area_textval_15_volts: [''],
      engine_compartment_area_textval_15_amps: [''],
      engine_compartment_area_A_16: [''],
      engine_compartment_area_B_16: [''],
      engine_compartment_area_textval_16_volts: [''],
      engine_compartment_area_A_17: [''],
      engine_compartment_area_B_17: [''],
      engine_compartment_area_textval_17_hc_idle: [''],
      engine_compartment_area_textval_17_co_idle: [''],
      engine_compartment_area_textval_17_hc_rpm: [''],
      engine_compartment_area_textval_17_co_rpm: [''],
      engine_compartment_area_textval_17_o2_idle: [''],
      engine_compartment_area_textval_17_co2_idle: [''],
      engine_compartment_area_textval_17_o2_rpm: [''],
      engine_compartment_area_textval_17_co2_rpm: [''],
      engine_compartment_area_A_18: [''],
      engine_compartment_area_B_18: [''],
      engine_compartment_area_A_19: [''],
      engine_compartment_area_B_19: [''],
      cng_vehicles_A_1: [''],
      cng_vehicles_B_1: [''],
      cng_vehicles_A_2: [''],
      cng_vehicles_B_2: [''],
      engine_compartment_A_1: [''],
      engine_compartment_B_1: [''],
      engine_compartment_A_2: [''],
      engine_compartment_B_2: [''],
      engine_compartment_A_3: [''],
      engine_compartment_B_3: [''],
      engine_compartment_textval_3_hc_idle: [''],
      engine_compartment_textval_3_co_idle: [''],
      engine_compartment_textval_3_hc_rpm: [''],
      engine_compartment_textval_3_co_rpm: [''],
      engine_compartment_textval_3_o2_idle: [''],
      engine_compartment_textval_3_co2_idle: [''],
      engine_compartment_textval_3_o2_rpm: [''],
      engine_compartment_textval_3_co2_rpm: [''],
      underneath_inspection_A_1: [''],
      underneath_inspection_B_1: [''],
      underneath_inspection_A_2: [''],
      underneath_inspection_B_2: [''],
      underneath_inspection_A_3: [''],
      underneath_inspection_B_3: [''],
      underneath_inspection_A_4: [''],
      underneath_inspection_B_4: [''],
      underneath_inspection_textboxval_4: [''],
      underneath_inspection_A_5: [''],
      underneath_inspection_B_5: [''],
      underneath_inspection_textboxval_5: [''],
      completed_by: [''],
      order_date: [''],
      workorder_number: [''],
      odometer: [''],
      service_request_id: [''],
      inspectionReportType: [1]
    });
  }

}
