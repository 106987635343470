<div class="panel panel-primary" id="mainCompotents">
  <div class="panel-heading padding-left-15">
    <h3 class="panel-title">PARTS Import Details</h3>
    <button mat-mini-fab class="custom-btn-back" (click)="gs.goBack();">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <div class="panel-body bgcolor-lightgrey" *ngIf="detailData">
    <div class="display-full relative">
        <strong>Status of your uploaded excel file will be shown in File Upload Listing:</strong>
        <ul>
          <li>Pending - Waiting records to be imported to temporary table</li>
          <li>Uploaded for Import - Records import to temporary table is in progress</li>
          <li>Processing - Records import to parts table is in progress</li>
          <li>Finished - Finish importing records to temporary table</li>
        </ul>
      <div class="table-responsive">
        <table class="table table-striped table-hover table-bordered ">
          <tbody>
            <tr>
              <td><strong>File Name</strong> </td>
              <td><a href="javascript:;" (click)="downLoadCsv(detailData.file_name)">{{detailData.file_name}}</a> </td>
            </tr>
            <tr>
                <td><strong>Status </strong></td>
                <td>{{showStatus(detailData.status)}} </td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="panel-body bgcolor-lightgrey">
      <strong> Status of your uploaded parts will be shown in Bulk Upload Listing:</strong>
      <ul>
        <li>Queued : Your uploaded parts are queued for import to the system.</li>
        <li>Failed to import : Part failed to be imported. Please view the part upload to see the error message.</li>
        <li>Successful Import : Part is successfully imported. Please check in part listing.</li>
      </ul>
    <div class="display-full relative">
      <div class="car-datatable uc-datatable mat-elevation-z1">

        <div class="table-responsive relative">
          <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg uc-table-responsive">

            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Name </mat-header-cell>
              <mat-cell *matCellDef="let row" data-role="auto" data-val="Name"> {{row.name}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="part_number">
              <mat-header-cell *matHeaderCellDef data-role="auto"> Part Number </mat-header-cell>
              <mat-cell *matCellDef="let row" data-role="auto" data-val="Part Number"> {{row.part_number }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Status </mat-header-cell>
              <mat-cell *matCellDef="let row" data-role="auto" data-val="Status"> {{row.status }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Actions">
              <mat-header-cell *matHeaderCellDef data-role="button"> Actions </mat-header-cell>
              <mat-cell *matCellDef="let row" data-role="button" data-val="Actions">
                <a href="javascript:;" (click)="viewImportDetail(row)" title="View">
                  <i class="fa fa-eye" ></i>
                </a>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
          <div class="padding-15 text-center" [style.display]="getTotalRecords() < 1 ? '' : 'none'">
            No Records Available
          </div>
        </div>
        <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
          <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="options.limit"
            [pageSizeOptions]="[5, 10,25, 100]" (page)="paginateByLimit($event)">
          </mat-paginator>

          <div class="mat-paginator table-paging">
            <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
            <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
              <mat-icon>navigate_before</mat-icon>
            </button>
            <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
              <mat-icon>navigate_next</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>