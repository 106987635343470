<!--edit disclaimer start-->
<div class="car-panel" id="mainCompotents">
    <div class="panel panel-primary" id="editDisclaimerPanel">
        <div class="panel-heading padding-left-15">
            <h3 class="panel-title">
              Edit Disclaimer
            </h3>
            <button mat-mini-fab class="custom-btn-back" title="Back" (click)="globalService.goBack();">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
        <div class="panel-body bgcolor-lightgrey edit-disclaimer" >
            <!-- Add disclaimer page start -->
            <div id="editdisclaimerContent" class="row bgcolor-lightgrey form-content">
                <div class="container-fluid">
                    <form [formGroup]="shopEditDisclaimer" (ngSubmit)="editdisclaimer()" class="form-inline col-xs-12 col-sm-12 col-md-12 col-lg-12" role="form" method="POST" enctype="multipart/form-data" autocomplete="off">
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block">
                          <div class="row">
                              <div class="form-group col-sm-12 col-md-6" *ngIf="currentDisclaimerDetails">
                                    <label for="title">Title</label>
                                  <div class='relative'>
                                      <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                          <input matInput placeholder="Title*" title="Title" maxlength="200" formControlName="title"  class="form-control input-lg custom-form-input"  [(ngModel)]="currentDisclaimerDetails.title" />
                                          <mat-error *ngIf="shopEditDisclaimer.controls.title.hasError('required')">
                                              This field cannot be empty
                                          </mat-error>
                                      </mat-form-field>
                                  </div>
                              </div>
                          </div>
                          <div class="row">
                              <div class="form-group col-sm-12 details-input"  *ngIf="currentDisclaimerDetails">
                                  <label for="description">Description</label>
                                  <div class='relative'>
                                      <mat-form-field class="btn-block car-input notes-input" floatPlaceholder="never" appearance="fill">
                                          <textarea matInput placeholder="Description*" title="Description" rows="5" maxlength="1000" formControlName="description"   rows="5"  [(ngModel)]="currentDisclaimerDetails.description"></textarea>
                                          <mat-error *ngIf="shopEditDisclaimer.controls.description.hasError('required')">
                                              This field cannot be empty
                                          </mat-error>
                                      </mat-form-field>
                                  </div>
                              </div>
                          </div>
                          <div class="row">
                              <div class="form-group col-sm-12">
                                  <div class='relative'>
                                      <mat-checkbox class="btn-block" labelPosition="after" (change)="showOptions($event)" formControlName="is_default" [checked]="disclaimerCheck">
                                          Set as Default Disclaimer.
                                      </mat-checkbox>
                                  </div>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block submit-block">
                                  <button type="submit" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-light fontcolor-white" color="accent" title="Submit" style="max-width: 300px;">
                                      SUBMIT
                                  </button>
                              </div>
                          </div>
                      </div>
                    </form>
                </div>
            </div>
        </div>
    </div>   
</div>
<!--edit disclaimer end -->
