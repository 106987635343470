<div class="panel panel-primary" id="mainCompotents">
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">VENDORS LIST</h3>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <div class="display-full relative">
            <mat-expansion-panel hideToggle="false" class="search-filter relative" [expanded]="openShopCustomersSearch">
                <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px">
                    <strong><i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
                </mat-expansion-panel-header>
                <form id="advanceSearchForm" class="collapse in" aria-expanded="true">

                    <div class="panel-body">
                        <div class="col-sm-3">
                            <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                <input  matInput placeholder="Search Keyword" title="Search Keyword" [(ngModel)]="options.search_keyword" [ngModelOptions]="{standalone: true}" class="form-control input-lg custom-form-input"/>
                            </mat-form-field>
                        </div>
                    </div>
                    <mat-action-row class="search-filter-footer">
                      <button mat-raised-button color="accent" type="submit" (click)="search($event)" title="Search">
                        Search
                      </button>
                    </mat-action-row>
                </form>
            </mat-expansion-panel>
            <button mat-button class="clear-btn" title="Clear Filter" #clearBtn (click)="clearSearch()" [class.active-search]="checkFormEmpty(clearBtn)">
                Clear Filter
            </button>
        </div>
        
        <div class="car-datatable uc-datatable mat-elevation-z1">
            <div class="table-responsive relative">
                <form [formGroup]="listCar" class="form-inline signup-form" method="POST" enctype="multipart/form-data" autocomplete="off">
                    <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg uc-table-responsive">
                        <!-- Color Column -->
                        <ng-container matColumnDef="vendor_name">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="name"> Name </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="name" data-val="Name"> {{row.vendor_name}}</mat-cell>
                        </ng-container>

                        <!-- Color Column -->
                        <ng-container matColumnDef="vendor_link">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Link </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="auto" class="link" data-val="Link"> <a href="{{row.vendor_link}}" target="_blank"> {{(row.vendor_link)}}</a> </mat-cell>
                        </ng-container>
                        
                        <ng-container matColumnDef="add_date">
                            <mat-header-cell *matHeaderCellDef mat-sort-header  data-role="date"> Added on </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="date" data-val="Added on"> {{row.add_date}} </mat-cell>
                        </ng-container>
                        <!-- Color Column -->
                        <ng-container matColumnDef="Actions">
                            <mat-header-cell *matHeaderCellDef  data-role="button"> Actions </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="button">
                                <button mat-button *ngIf="isAllowed('edits') || isAllowed('deletes')" class="list-action-btn" [matMenuTriggerFor]="menu" disableRipple >
                                    Action<mat-icon>arrow_drop_down</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu" class="car-icon-menu" [overlapTrigger]="false">
                                    <a class="mat-menu-item" *ngIf="isAllowed('edits')" (click)="editVendor(row.id)"><i class="fa fa-edit"></i>Edit</a>
                                    <a class="mat-menu-item" *ngIf="isAllowed('deletes')" (click) = "deleteVendor(row.id)"><i class="fa fa-trash"></i>Delete</a>
                                   </mat-menu>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;" [class.success-completed]="row.left_menu_status==1"></mat-row>
                    </mat-table>
                </form>

                <div class="padding-15 text-center"
                     [style.display]="getTotalRecords() < 1 ? '' : 'none'">
                     No Records Available
            </div>
        </div>
        <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
             <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="options.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event)" >
            </mat-paginator>

            <div class="mat-paginator table-paging">
                <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
                <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                        <mat-icon>navigate_before</mat-icon>
                </button>
                <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                        <mat-icon>navigate_next</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
</div>
