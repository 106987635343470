import {Component, ElementRef, ViewChild, ViewEncapsulation, OnDestroy} from '@angular/core';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {DataSource, SelectionModel} from '@angular/cdk/collections';
import {Http, Headers, Response} from '@angular/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {globalFunctions} from "../../../../environments/globalFunctions"
import {globalConstants} from "../../../../environments/globalConstants"
import {GlobalService} from "../../../../environments/global.service"
import {HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Title} from '@angular/platform-browser';
import {lang} from "../../../../environments/lang-en"
import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import {AppComponent} from '../../../app.component';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import * as moment from 'moment'
const REQUEST_LIST = "stock-order-";
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-list-stock-orders',
  templateUrl: './list-stock-orders.component.html',
  styleUrls: ['./list-stock-orders.component.scss']
})
export class ListStockOrdersComponent implements OnDestroy {
  displayedColumns = ['id','add_date','que_name','actions'];
  public currentUser;
  public schedule_service_option
  public requested_delivery_option
  public estimated_delivery_option
  public more_rows;
  public isSubscribed=true;
  public options = {
    user_id: "",
    user_type:"User",
    status: '',
    search_keyword: "",
    start: 0,
    limit: 10,
    orderDate: "",
    search_date: ""
  }

  public subscriptionDataForAPI: Subscription = new Subscription();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public openCustomerSearch: boolean = false;
  dataSource: MatTableDataSource<StockOrderData> = new MatTableDataSource();
  public record :any =[];
  public recordsTotal: any =[];
  constructor(private location:Location,private app: AppComponent, public router:Router,private http:Http, public titleService: Title,public globalservice:GlobalService, public dialog: MatDialog){
    if (localStorage.getItem("loggedInUser")) {
      this.currentUser = JSON.parse(localStorage.getItem("loggedInUser"));
      this.options.user_id = this.currentUser.id;
      this.reinitializeOptions();
    } else {
      this.router.navigate(["/shops"]);
    }
    this.globalservice.setMetaData("SHOPS","STOCK_ORDERES_LISTING");
    this.isSubscribed=this.globalservice.isPartstechAllowed();
    setTimeout(()=>{
    let isEnable=this.globalservice.getAcl('stock_order','views');
    if(!(isEnable)){
        this.router.navigate(['shops/action/forbidden']);
    }
  },globalConstants.RELOAD_WAIT)
  }

  AddNew(){
    this.router.navigateByUrl("/shops/stock-order")
  }

  ViewStockOrder(id){
    this.router.navigateByUrl("/shops/view-stock-orders/"+id)
  }

  ngOnInit() {
    this.reinitializeOptions();
    this.setDataTable();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  search(event) {
    this.options.start = 0;
    if(this.options.orderDate){
      let d = new Date(this.options.orderDate);
      let m=(d.getMonth() + 1).toString();
      let md=(d.getDate()).toString();
      this.options.search_date = d.getFullYear()+'-' +('00'+m).slice(m.length) + '-' + ('00'+md).slice(md.length);
    }else{
      this.options.search_date ='';
    }

    this.setDataTable();
  }

  scrolling(){
    this.globalservice.setScrolling('.car-datatable', 1000, -20);
  }

  paginateByLimit(event){
    this.globalservice.setScrolling('body', 600, 0);
    this.options.limit = event.pageSize;
    this.options.start = 0;
    this.scrolling();
    this.setDataTable();
  }

  clearSearch() {
    this.openCustomerSearch = false;
    this.globalservice.localGetterSetter(null, this.options, REQUEST_LIST);
    this.reinitializeOptions();
    this.setDataTable();
  }

  reinitializeOptions() {
    this.options = this.globalservice.localGetterSetter(this.options, this.options, REQUEST_LIST);
    this.options.user_type = "User"
    this.schedule_service_option = ""
    this.requested_delivery_option = ""
    this.estimated_delivery_option = "";
    this.currentUser = JSON.parse(localStorage.getItem("loggedInUser"));
    this.options.user_id = this.currentUser.id;
  }

  getTotalRecords() {
    return parseInt(localStorage.getItem(REQUEST_LIST + 'totalRecords'));
  }

  paginate(event, type) {
    this.scrolling();
    if (type == "next") {
      this.options.start = this.options.start + this.options.limit;

    } else {
      this.options.start = this.options.start - this.options.limit;
    }
    this.setDataTable();
  }

  firstList() {
    return this.globalservice.firstList(REQUEST_LIST, this.options);
  }

  lastList() {
    return this.globalservice.lastList(REQUEST_LIST);
  }

  getUpperLimit(currentLastLimit, total) {
    if (currentLastLimit > total) {
      return total
    } else {
      return currentLastLimit
    }
  }

  viewServiceRequest(id) {
    this.globalservice.localGetterSetter(this.options,null,REQUEST_LIST);
    this.router.navigate(["/customer/view-service-request/"+id]);
  }

  setDataTable(){
    this.record = [];
    var content = globalFunctions.stringifyFormData(this.options, null);
    var callApi = this.globalservice.callAPI(globalConstants.API_GET_STOCK_ORDERS_LIST, content);
    this.subscriptionDataForAPI = callApi.subscribe((data:any)=>{
      if (data.data != undefined) {
        let moreRows = 'false';
        let firstList = 'false';
        this.globalservice.localGetterSetter("totalRecords", data.recordsTotal, REQUEST_LIST);
        if (data.more_rows == "true") {
          moreRows = "true";
        }
        this.globalservice.localGetterSetter("more_rows", moreRows, REQUEST_LIST);
        if (data.start >= 0 && data.start <= 5) {
          firstList = "true";
        }
        this.globalservice.localGetterSetter("first_list", firstList, REQUEST_LIST);

        if (data.data.length != undefined) {
          this.globalservice.localGetterSetter("current-visible",this.recordsTotal,REQUEST_LIST);
          this.recordsTotal = data.data.length;
          for (var i = 0; i < data.data.length; i++) {
            this.AddRecord(data.data[i])
          }
          this.dataSource = new MatTableDataSource(this.record)
          this.dataSource.sort = this.sort;
        } else {
          this.recordsTotal = "1";
          this.AddRecord(data.data);
          this.dataSource = new MatTableDataSource(this.record)
          this.dataSource.sort = this.sort;
          this.globalservice.localGetterSetter("current-visible",'1',REQUEST_LIST);
        }
      } else {
        this.dataSource = new MatTableDataSource(this.record);
        this.globalservice.localGetterSetter("totalRecords", '0', REQUEST_LIST);
      }
    })
  }

  AddRecord(record){
    var data = this.createRecordrecord(record);
    this.record.push(data);
  }

  createRecordrecord(record) {
    return {
      id:record.id,
      user_id:record.user_id,
      shop_id:record.shop_id,
      session_id:record.session_id,
      order_completed:record.order_completed,
      add_date:this.globalservice.getFullFormateDate(record.add_date,"G","B"),
      modify_date:this.globalservice.getFullFormateDate(record.modify_date,"G","B"),
      que_name: record.que_name
    };
  }

  isAllowed(action){
    return this.globalservice.getAcl('stock_order',action);
  }
  gotoShopSubscription() {
    this.router.navigate(['/shops/subscription'])
  }
  QueOrder(){
    this.router.navigate(['/shops/queue/order']);
  }
  checkFormEmpty(button) {
    if ( this.options.search_keyword == ""  && this.options.orderDate == "") {
        if (button) {button.color = "primary"}
        return false
    }
    else {
        if (button) {button.color = "accent"}
        return true;
    }
}

ngOnDestroy(){
  this.subscriptionDataForAPI.unsubscribe();
}
}

export interface StockOrderData {
  id:any,
  user_id:any,
  shop_id:any,
  session_id:any,
  order_completed:any,
  add_date:any,
  modify_date:any,
  que_name:any,
}


 