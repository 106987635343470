import { Injectable } from '@angular/core';
import {Http, Headers,URLSearchParams, Response,BaseRequestOptions,RequestOptions,RequestOptionsArgs} from '@angular/http';

import { Observable } from 'rxjs/Observable';
import {globalConstants} from "../environments/globalConstants";
import {CanDeactivate,ActivatedRouteSnapshot,RouterStateSnapshot, Router} from '@angular/router';
import {ViewServicerequestComponent} from './shop/shopservicerequest/view-servicerequest/view-servicerequest.component';
import {GlobalService} from "../environments/global.service"
import { FillRecommendationComponent } from './shop/shopservicerequest/fill-recommendation/fill-recommendation.component';
@Injectable()
export class CarheadService extends BaseRequestOptions {
  constructor( ) {
    super();
  }
  merge(options?: RequestOptionsArgs): RequestOptions {
    const newOptions = super.merge(options);
    let params: URLSearchParams = new URLSearchParams();
    var inURL = window.location.href.split('/');
    let jwtConfig:any='';
    if(typeof inURL[3]!='undefined' && inURL[3]=='shops'){
      jwtConfig =localStorage.getItem(globalConstants.SHOP_JWT_KEY);
    }else if(typeof inURL[3]!='undefined' && inURL[3]=='customer'){
      jwtConfig =localStorage.getItem(globalConstants.CUSTOMER_JWT_KEY);
    }else if(typeof inURL[3]!='undefined' && inURL[3]=='admin'){
      jwtConfig =localStorage.getItem(globalConstants.ADMIN_JWT_KEY);
    }
    console.log("this is jwt cashhead-------------")
    if(jwtConfig){
      jwtConfig=JSON.parse(jwtConfig);
      for(let i in jwtConfig){
        params.set(i, jwtConfig[i]);
      }
    }

    newOptions.params=params;
    return newOptions;
  }
}
@Injectable()
export class CanComponentDeactivate implements CanDeactivate<ViewServicerequestComponent>{
  public selectedRoutes:any = {};
  constructor(private gs:GlobalService,private router:Router) {
    router.config.forEach(route=>{
      if(route.component == ViewServicerequestComponent){
        this.selectedRoutes.viewRoutePath = this.gs.reConstructPath(route.path);
      }else if(route.component == FillRecommendationComponent){
        this.selectedRoutes.fillRoutePath = this.gs.reConstructPath(route.path);
      }else if(Object.keys(this.selectedRoutes).length == 2){
        return;
      }
    });
    //console.log(this.selectedRoutes);
  }
  canDeactivate(component:any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot): Observable<boolean>|Promise<boolean>|boolean {
      let user=this.gs.getCurrentUser();
      let checkByPass = !this.gs.checkToBypassPopup(currentState.url,nextState.url,this.selectedRoutes);
      if(user && checkByPass){
        return component.checkDeActive();
      }else{
        return true;
      }
  }
}