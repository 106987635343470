<div mat-dialog-title class="relative edit-dfs-title">
    Edit DFS Reminder
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
      <mat-icon  class="close-icon-lg">close</mat-icon>
    </button>
</div>
<mat-dialog-content class="edit-dfs-content">
  <form class="form-horizontal form-control-normal" autocomplete="off" [formGroup]="editDfsForm" >
        <label class="control-label"><strong>Schedule by Miles
            </strong></label>
        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
            <input matInput maxlength='10' placeholder="Enter schedule by miles"
                class="form-control input-lg custom-form-input btn-block" formControlName="schedule_by_miles"
                (keypress)="validateNumbersWithDecimal($event)" (paste)="validateFieldOnPaste('schedule_by_miles')" />
            <mat-error *ngIf="editDfsForm.get('schedule_by_miles').status == 'INVALID'">
                Please enter only numbers or upto one decimal place
            </mat-error>
        </mat-form-field>
        <label class="control-label"><strong>Schedule by Date
            </strong></label>
        <mat-form-field class="btn-block car-input car-datePicker" floatPlaceholder="never" appearance="fill">
            <input matInput maxlength='14' placeholder="Enter schedule by date"
            class="form-control input-lg custom-form-input btn-block" [matDatepicker]="filerPicker"
            [(ngModel)]="scheduleByDate" [min]="minDate" [ngModelOptions]="{standalone: true}" onkeydown="return false;">
          <mat-datepicker-toggle matSuffix [for]="filerPicker"></mat-datepicker-toggle>
          <mat-datepicker #filerPicker [touchUi]="true"></mat-datepicker>
        
        </mat-form-field>
      </form>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative edit-dfs-action">
    <div>
      <button mat-accent mat-raised-button class="font-bold mat-accent" (click)="handleUpdateReminder()">Update</button>
    </div>
  </mat-dialog-actions>