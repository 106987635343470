import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {globalConstants} from '../../../../environments/globalConstants';
import {GlobalService} from '../../../../environments/global.service';
import {globalFunctions} from '../../../../environments/globalFunctions';
import {Router, ActivatedRoute, Params, NavigationEnd} from '@angular/router';
import {FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ImagetooldialogComponent} from '../../../imagetooldialog/imagetooldialog.component';
import {VideoShowComponent} from '../../my-vehicles/video-show/video-show.component';
declare var jQuery:any;
declare var $ :any;
@Component({
    selector: 'app-service-history-view',
    templateUrl: './service-history-view.component.html',
    styleUrls: ['./service-history-view.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ServiceHistoryViewComponent implements OnInit {
    public serviceRequestId: number;
    public serviceInfo: any;
    public serviceRequestList: any;
    public showServiceDetail: boolean = false;
    partsOptions = [];
    genericOptions = [];
    public ObjectJson = {}
    public totalReturnedValue;
    public finalShowAbleAmount;
    public totalAcceptedCalculation = []
    public rejectedJobCalculation = [];
    public grandTotalAcceptedArray = [];
    public grandTotalRejectedArray = [];
    public nonTaxableValue = [];
    public UnTaxedAcceptedValue = 0;
    public totalAcceptedValue = "";
    public totalRejectedValue = "";
    public conservingStatus = [];
    public statusAcceptText: any;
    public statusRejectText: any;
    public vehicle_pic: any;
    public vin_photo: any;
    public dataSources = {data: []};
    public reportDownloadLink = '';
    public reportDownloadType = 0;
    public jobStatusObj = {
        l1Id: "",
        l2Id: "",
        l3Id: "",
        recommendationId: "",
        status: ""
    };
    public globalConstants = globalConstants;
    public recoImagePath=globalConstants.S3_BUCKET_URL+globalConstants.RECOMMEDATION_IMAGE+globalConstants.IMG_THUMB;
    public jobStatuses = [];
    public vehicleName="";
    constructor(private formBuilder: FormBuilder, public dialog: MatDialog, public globalService: GlobalService, public router: Router, private activatedRoute: ActivatedRoute) {
        this.globalService.setMetaData("CUSTOMER","SERVICE_REQUEST_DETAIL")
    }
    toNumber(str) {
        return parseInt(str)
    }
    show(val) {
        // console.log(val)
    }
    ngOnInit() {
        this.serviceInfo = this.formBuilder.group({
            add_date: [],
            address1: [],
            address2: [],
            balance_amount: [],
            business_name: [],
            business_phone: [],
            car_id: [],
            city: [],
            color: [],
            comments: [],
            company_name: [],
            completion_date: [],
            confirmation_token: [],
            country: [],
            customer_id: [],
            customer_type: [],
            disclaimer: [],
            disclaimer_id: [],
            email: [],
            estimated_delivery_date: [],
            feedback_code: [],
            first_name: [],
            grand_total: [],
            has_app_downloaded: [],
            home_phone: [],
            id: [],
            inspection_reports: [],
            insurance_expiration_date: [],
            is_DIY: [],
            is_paid: [],
            is_supplies_charges_included: [],
            is_tax_included: [],
            is_usps_vehicle: [],
            labor_amount: [],
            labor_rate: [],
            last_name: [],
            license_plate: [],
            mail_sent: [],
            make: [],
            middle_name: [],
            mobile_phone: [],
            model: [],
            modify_date: [],
            no_report_performed: [],
            non_taxable_amount: [],
            notes: [],
            notify_accept_reject_jobs_email: [],
            notify_accept_reject_jobs_push: [],
            notify_create_service_facility_car_email: [],
            notify_create_service_facility_car_push: [],
            notify_expiration_dates_email: [],
            notify_expiration_dates_push: [],
            notify_expiration_dates_web: [],
            notify_feedback_email: [],
            notify_feedback_push: [],
            notify_make_changes_car_facility_service_request_email: [],
            notify_make_changes_car_facility_service_request_push: [],
            notify_send_updated_info_to_customer_email: [],
            notify_send_updated_info_to_customer_push: [],
            notify_service_completion_email: [],
            notify_service_completion_push: [],
            notify_to_admin: [],
            notify_to_customer: [],
            parts_amount: [],
            password: [],
            payment_date: [],
            payment_reminder_date: [],
            payment_reminder_status: [],
            present_mileage: [],
            read_status: [],
            recommendation_saved: [],
            registration_expiration_date: [],
            request_documents: [],
            request_id: [],
            request_images: [],
            request_status: [],
            requested_delivery_date: [],
            reset_pwd_token: [],
            schedule_service_date: [],
            service_facility: [],
            service_request_list: [],
            shop_id: [],
            state: [],
            status: [],
            supplies_charges: [],
            suppliescharges: [],
            tax_amount: [],
            tax_exempt_number: [],
            tax_exempted: [],
            tax_rate: [],
            technician: [],
            title: [],
            total_amount: [],
            total_paid: [],
            vin: [],
            year: [],
            zip: [],
            service_facility_name: [],
            request_notes: []
        });
        this.setParams();
        let content = "customer_id=" + this.globalService.getCurrentCustomer()["id"] + "&service_request_id=" + this.serviceRequestId;
        this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUESTS_HISTROY_VIEW_URL, content).subscribe((response:any) => {
            if (response.result == 1) {
                // this.serviceInfo = response.data;
                this.serviceRequestList = response.data.service_request_list;
                var data = response.data;
                this.serviceInfo.controls.add_date.setValue(this.globalService.getFullFormateDate(data.add_date,'G','H'))
                this.serviceInfo.controls.address1.setValue(data.address1)
                this.serviceInfo.controls.address2.setValue(data.address2)
                this.serviceInfo.controls.balance_amount.setValue(data.balance_amount)
                this.serviceInfo.controls.business_name.setValue(data.business_name)
                this.serviceInfo.controls.business_phone.setValue(data.business_phone)
                this.serviceInfo.controls.car_id.setValue(data.car_id)
                this.serviceInfo.controls.city.setValue(data.city)
                this.serviceInfo.controls.color.setValue(data.color)
                this.serviceInfo.controls.comments.setValue(data.comments)
                this.serviceInfo.controls.company_name.setValue(data.company_name)
                this.serviceInfo.controls.completion_date.setValue(this.globalService.getFullFormateDate(data.completion_date,'G','H'))
                this.serviceInfo.controls.confirmation_token.setValue(data.confirmation_token)
                this.serviceInfo.controls.country.setValue(data.country)
                this.serviceInfo.controls.customer_id.setValue(data.customer_id)
                this.serviceInfo.controls.customer_type.setValue(data.customer_type);
                this.serviceInfo.controls.disclaimer.setValue(data.disclaimer)
                this.serviceInfo.controls.disclaimer_id.setValue(data.disclaimer_id)
                this.serviceInfo.controls.email.setValue(data.email)
                this.serviceInfo.controls.estimated_delivery_date.setValue(this.globalService.getFullFormateDate(data.estimated_delivery_date,'G','B'));
                this.serviceInfo.controls.feedback_code.setValue(data.feedback_code)
                this.serviceInfo.controls.first_name.setValue(data.first_name)
                this.serviceInfo.controls.grand_total.setValue(data.grand_total)
                this.serviceInfo.controls.has_app_downloaded.setValue(data.has_app_downloaded)
                this.serviceInfo.controls.home_phone.setValue(data.home_phone)
                this.serviceInfo.controls.id.setValue(data.id)
                this.serviceInfo.controls.inspection_reports.setValue(data.inspection_reports)
                this.serviceInfo.controls.insurance_expiration_date.setValue(this.globalService.getFullFormateDate(data.insurance_expiration_date,'GD','H'))
                this.serviceInfo.controls.is_DIY.setValue(data.is_DIY)
                this.serviceInfo.controls.is_paid.setValue(data.is_paid)
                this.serviceInfo.controls.is_supplies_charges_included.setValue(data.is_supplies_charges_included)
                this.serviceInfo.controls.is_tax_included.setValue(data.is_tax_included)
                this.serviceInfo.controls.is_usps_vehicle.setValue(data.is_usps_vehicle)
                this.serviceInfo.controls.labor_amount.setValue(data.labor_amount)
                this.serviceInfo.controls.labor_rate.setValue(data.labor_rate)
                this.serviceInfo.controls.last_name.setValue(data.last_name)
                this.serviceInfo.controls.license_plate.setValue(data.license_plate)
                this.serviceInfo.controls.mail_sent.setValue(data.mail_sent)
                this.serviceInfo.controls.make.setValue(data.make)
                this.serviceInfo.controls.middle_name.setValue(data.middle_name)
                this.serviceInfo.controls.mobile_phone.setValue(data.mobile_phone)
                this.serviceInfo.controls.model.setValue(data.model)
                this.serviceInfo.controls.modify_date.setValue(this.globalService.getFullFormateDate(data.modify_date,'G','H'))
                this.serviceInfo.controls.no_report_performed.setValue(data.no_report_performed)
                this.serviceInfo.controls.non_taxable_amount.setValue(data.non_taxable_amount)
                this.serviceInfo.controls.notes.setValue(data.notes)
                this.serviceInfo.controls.notify_accept_reject_jobs_email.setValue(data.notify_accept_reject_jobs_email)
                this.serviceInfo.controls.notify_accept_reject_jobs_push.setValue(data.notify_accept_reject_jobs_push)
                this.serviceInfo.controls.notify_create_service_facility_car_email.setValue(data.notify_create_service_facility_car_email)
                this.serviceInfo.controls.notify_create_service_facility_car_push.setValue(data.notify_create_service_facility_car_push)
                this.serviceInfo.controls.notify_expiration_dates_email.setValue(data.notify_expiration_dates_email)
                this.serviceInfo.controls.notify_expiration_dates_push.setValue(data.notify_expiration_dates_push)
                this.serviceInfo.controls.notify_expiration_dates_web.setValue(data.notify_expiration_dates_web)
                this.serviceInfo.controls.notify_feedback_email.setValue(data.notify_feedback_email)
                this.serviceInfo.controls.notify_feedback_push.setValue(data.notify_feedback_push)
                this.serviceInfo.controls.notify_make_changes_car_facility_service_request_email.setValue(data.notify_make_changes_car_facility_service_request_email)
                this.serviceInfo.controls.notify_make_changes_car_facility_service_request_push.setValue(data.notify_make_changes_car_facility_service_request_push)
                this.serviceInfo.controls.notify_send_updated_info_to_customer_email.setValue(data.notify_send_updated_info_to_customer_email)
                this.serviceInfo.controls.notify_send_updated_info_to_customer_push.setValue(data.notify_send_updated_info_to_customer_push)
                this.serviceInfo.controls.notify_service_completion_email.setValue(data.notify_service_completion_email)
                this.serviceInfo.controls.notify_service_completion_push.setValue(data.notify_service_completion_push)
                this.serviceInfo.controls.notify_to_admin.setValue(data.notify_to_admin)
                this.serviceInfo.controls.notify_to_customer.setValue(data.notify_to_customer)
                this.serviceInfo.controls.parts_amount.setValue(data.parts_amount)
                this.serviceInfo.controls.password.setValue(data.password)
                this.serviceInfo.controls.payment_date.setValue(this.globalService.getFullFormateDate(data.payment_date,'G','H'))
                this.serviceInfo.controls.payment_reminder_date.setValue(this.globalService.getFullFormateDate(data.payment_reminder_date,'G','H'))
                this.serviceInfo.controls.payment_reminder_status.setValue(data.payment_reminder_status)
                this.serviceInfo.controls.present_mileage.setValue(data.present_mileage)
                this.serviceInfo.controls.read_status.setValue(data.read_status)
                this.serviceInfo.controls.recommendation_saved.setValue(data.recommendation_saved)
                this.serviceInfo.controls.registration_expiration_date.setValue(this.globalService.getFullFormateDate(data.payment_reminder_date,'GD','H'))
                this.serviceInfo.controls.request_documents.setValue(data.request_documents)
                this.serviceInfo.controls.request_id.setValue(data.request_id);
                this.serviceInfo.controls.request_notes.setValue(data.request_notes)
                this.serviceInfo.controls.request_images.setValue(data.request_images)
                this.serviceInfo.controls.request_status.setValue(parseInt(data.request_status));
                this.serviceInfo.controls.requested_delivery_date.setValue(this.globalService.getFullFormateDate(data.requested_delivery_date,'G','B'));
                this.serviceInfo.controls.reset_pwd_token.setValue(data.reset_pwd_token)
                this.serviceInfo.controls.schedule_service_date.setValue(this.globalService.getFullFormateDate(data.schedule_service_date,'G','B'));
                this.serviceInfo.controls.service_facility.setValue(data.service_facility)
                this.serviceInfo.controls.service_request_list.setValue(data.service_request_list)
                this.serviceInfo.controls.shop_id.setValue(data.shop_id)
                this.serviceInfo.controls.state.setValue(data.state)
                this.serviceInfo.controls.status.setValue(data.status)
                this.serviceInfo.controls.supplies_charges.setValue(data.supplies_charges)
                this.serviceInfo.controls.suppliescharges.setValue(data.suppliescharges)
                this.serviceInfo.controls.tax_amount.setValue(data.tax_amount)
                this.serviceInfo.controls.tax_exempt_number.setValue(data.tax_exempt_number)
                this.serviceInfo.controls.tax_exempted.setValue(data.tax_exempted)
                this.serviceInfo.controls.tax_rate.setValue(data.tax_rate)
                this.serviceInfo.controls.technician.setValue(data.technician)
                this.serviceInfo.controls.title.setValue(data.title)
                this.serviceInfo.controls.total_amount.setValue(data.total_amount)
                this.serviceInfo.controls.total_paid.setValue(data.total_paid == null ? 0.00 : data.total_paid)
                this.vehicle_pic = data.vehicle_pic
                this.vin_photo = data.vin_photo
                this.serviceInfo.controls.vin.setValue(data.vin)
                this.serviceInfo.controls.year.setValue(data.year)
                this.serviceInfo.controls.zip.setValue(data.zip);
                this.serviceInfo.controls.service_facility_name.setValue(data.service_facility_name);
                this.showServiceDetail = true;
                this.downloadUspsReport();
                if (data.year =="") {
                    this.vehicleName= "";
                  } else {
                    this.vehicleName= data.year ;
                  }
                  if(data.make != ""){
                    this.vehicleName += (this.vehicleName ? "/" : "" ) + data.make;
                  }
                  if(data.model != ""){
                    this.vehicleName += (this.vehicleName ? "/" : "" ) + data.model
                  }
                  this.vehicleName = (this.vehicleName ? this.vehicleName :"N/A");
                // for(var i = 0;i<this.serviceInfo.value.service_request_list.length;i++) {
                //   this.totalReturnedValue = this.getTotalPrice(this.serviceInfo.value.service_request_list[i]);
                //   var returingObj = this.getAcceptAndRejectCalculationForPage(this.serviceInfo.value.service_request_list[i],null)
                //   this.ObjectJson[this.serviceInfo.value.service_request_list[i]["id"]]=
                //   (
                //     {
                //       AcceptedValue:returingObj[0].toFixed(2),
                //       RejectedValue:returingObj[1].toFixed(2)
                //     }
                //   )
                // }
                // this.calculatefinalAmount (this.serviceInfo.value.tax_rate,this.serviceInfo.value.supplies_charges);
            } else {
                this.showServiceDetail = false;
                this.globalService.snackbar("error", response.message);
            }
            // console.log(this.serviceInfo);
        });
    }
    setParams() {
        this.activatedRoute.params.subscribe((params: Params) => {
            this.serviceRequestId = params["serviceId"];
        });
    }
    addArray(array) {
        console.log(array);
        var sum = 0;
        /*for(var i=0;i<array.length;i++) {
          sum = sum + array[i]
        }*/
        return sum
    }
    ConvertToNumber(str) {return parseInt(str)}
    fillRecommendation(id) {

        this.router.navigate(["/shops/service-inspection-report/" + id]);
    }
    getBalance(gTotal, bal) {
        if (bal != null) {
            return (parseFloat(gTotal) - parseFloat(bal));
        }
        return gTotal;
    }
    generateInvoice(rid, total, taxRate, isTaxIncluded) {
        var conf = confirm("Are you sure you want to mark this request as complete and generate its invoice?");

        if (conf == true) {
            window.open('generate-invoice.php?r=' + rid + '&ti=' + isTaxIncluded + '&tr=' + taxRate + '&to=' + total, '_blank', 'titlebar=no,toolbar=no,resizeable=no,location=no,height=600,width=800,scrollbars=yes');
        } else {
            return false;
        }
    }
    getNltoBr(val){
      if(val && val.length>0){
        return val.toString().replace(/\r\n/g, "<br />").replace(/\n/g, "<br />");
      }
      return '-';
    }
    expansionOpen(ele) {
        // console.log('scroll animation');
        var target= '#expansion-'+ ele;
        var eleState = $(target).data('state');
        $(".new-request-expension .mat-expansion-panel").data("state",false);
        if(!eleState){
          setTimeout(() => {
             let element = document.getElementById('expansion-' + ele);
             var target= '#expansion-'+ ele;
             setTimeout(() => {
               var body = $("html, body");
               body.animate({
                      scrollTop: jQuery(target).offset().top - 90
                }, 500);
             }, 500);
             $(target).data('state',true);
          }, 200);
        }
     }
    finalUpdateToNotifyAdmin(request_id) {
        this.globalService.callAPI(globalConstants.API_UPDATE_NOTIFY_TO_ADMIN_URL, "request_id=" + request_id).subscribe((response:any) => {
            // console.log(response);
            if (response.result == "1") {
                this.globalService.snackbar("success", response.message)
            } else {
                this.globalService.snackbar("error", response.message)
            }
        })
    }
    chkDate(date) {
        if (date != "0000-00-00 00:00:00") {
            var currentDate = new Date();
            if (new Date(date) < new Date(currentDate)) {
                return true
            } else {
                return false;
            }
        }
    }
    getTotalPrice(object) {
        var totalPriceForGj = 0, totalPriceForParts = 0, rejectedJobCost = 0, nonTaxableAmount = 0;
        var labourCost = 0;
        for (var i = 0; i < object.children.length; i++) {
            if (object.children[i].children != undefined && object.children[i].children.length > 0) {

                // do the level 2 jobs
                this.callToThirdLevelCalculations(object.children[i].children);

            } else {
                if (object.children[i].recommendations != undefined && object.children[i].recommendations.length > 0) {

                    for (var j = 0; j < object.children[i].recommendations.length; j++) {
                        labourCost = 0;
                        var scopedNonTaxedValue = 0;
                        labourCost = labourCost + parseFloat(object.children[i].recommendations[j].estimated_labor);

                        // calculate price for parts
                        for (var k = 0; k < object.children[i].recommendations[j].parts.length; k++) {
                            if (object.children[i].recommendations[j].parts.length > 0) {
                                totalPriceForParts = parseFloat(totalPriceForParts.toString()) + (object.children[i].recommendations[j].parts[k].part_price * object.children[i].recommendations[j].parts[k].quantity);
                                if (object.children[i].recommendations[j].parts[k].non_taxable == "1" && object.children[i].recommendations[j].approval_status != "2") {
                                    scopedNonTaxedValue = scopedNonTaxedValue + (object.children[i].recommendations[j].parts[k].part_price * object.children[i].recommendations[j].parts[k].quantity);

                                }
                            }
                        }

                        // calculate cost for generic jobs
                        for (var k = 0; k < object.children[i].recommendations[j].generic_jobs.length; k++) {
                            totalPriceForGj = totalPriceForGj + (object.children[i].recommendations[j].generic_jobs[k].price * object.children[i].recommendations[j].generic_jobs[k].quantity);
                            if (object.children[i].recommendations[j].generic_jobs[k].non_taxable == "1" && object.children[i].recommendations[j].approval_status != "2") {
                                scopedNonTaxedValue = scopedNonTaxedValue + (object.children[i].recommendations[j].generic_jobs[k].price * object.children[i].recommendations[j].generic_jobs[k].quantity);
                            }
                        }
                        this.nonTaxableValue.push(scopedNonTaxedValue);
                        // console.log("this.nonTaxableValue",  this.nonTaxableValue)
                        var totalAmount = parseFloat(totalPriceForParts.toString()) + parseFloat(totalPriceForGj.toString()) + parseFloat(labourCost.toString());
                        totalAmount = parseFloat(totalAmount.toString());
                        if (object.children[i].recommendations[j].approval_status == "2") {
                            this.rejectedJobCalculation.push(totalAmount);
                            this.nonTaxableValue.splice(this.nonTaxableValue.length - 1, 1)
                        } else {
                            this.totalAcceptedCalculation.push(totalAmount);
                        }
                        //console.log("totalAmount",totalAmount)
                        totalAmount = 0;
                        totalPriceForParts = 0;
                        totalPriceForGj = 0;
                    }
                    // console.log("accepted",)
                }
            }
        }

        return [this.totalAcceptedCalculation, this.rejectedJobCalculation]
    }
    calculatefinalAmount(tax_rate, supplies_charges) {
        var acceptedValue = this.addArray(this.totalAcceptedCalculation);
        var totalNonTaxedValue = this.addArray(this.nonTaxableValue);
        var taxableValue = acceptedValue - totalNonTaxedValue;
        var finalValue;
        var totalCalculatedAmount

        if (this.serviceInfo.value.request_status == "6" || this.serviceInfo.value.request_status == "8") {
            finalValue = this.serviceInfo.value.total_amount
        } else if (this.serviceInfo.value.request_status == "3" || this.serviceInfo.value.request_status == "4" || this.serviceInfo.value.request_status == "5" || this.serviceInfo.value.request_status == "7" || this.serviceInfo.value.request_status == "12") {
            if (this.serviceInfo.value.is_tax_included == "1") {
                totalCalculatedAmount = acceptedValue + (taxableValue * (parseFloat(tax_rate) / 100))
            } else {
                totalCalculatedAmount = acceptedValue;
            }

            if (this.serviceInfo.value.is_supplies_charges_included == "1") {
                finalValue = totalCalculatedAmount + parseFloat(supplies_charges);
            } else {
                finalValue = totalCalculatedAmount
            }
        } else {
            finalValue = this.serviceInfo.value.total_amount
        }
        if (finalValue != null) {
            this.finalShowAbleAmount = parseFloat(finalValue).toFixed(2)
        }
    }
    callToThirdLevelCalculations(secondLevelChildren) {

        var totalPriceForGj = 0, totalPriceForParts = 0, rejectedJobCost = 0;
        var labourCost = 0;

        for (var i = 0; i < secondLevelChildren.length; i++) {
            if (secondLevelChildren[i].recommendations.length > 0) {
                for (var j = 0; j < secondLevelChildren[i].recommendations.length; j++) {
                    var tobeDeducted = 0;
                    var scopedNonTaxedValue = 0;
                    totalPriceForGj = 0;
                    totalPriceForParts = 0;
                    rejectedJobCost = 0;

                    for (var k = 0; k < secondLevelChildren[i].recommendations[j].parts.length; k++) {
                        totalPriceForParts = totalPriceForParts + (secondLevelChildren[i].recommendations[j].parts[k].part_price * secondLevelChildren[i].recommendations[j].parts[k].quantity);
                        if (secondLevelChildren[i].recommendations[j].parts[k].non_taxable == "1" && secondLevelChildren[i].recommendations[j].approval_status != "2") {
                            scopedNonTaxedValue = scopedNonTaxedValue + (secondLevelChildren[i].recommendations[j].parts[k].part_price * secondLevelChildren[i].recommendations[j].parts[k].quantity)
                        }
                    }

                    for (var k = 0; k < secondLevelChildren[i].recommendations[j].generic_jobs.length; k++) {
                        totalPriceForGj = totalPriceForGj + (secondLevelChildren[i].recommendations[j].generic_jobs[k].price * secondLevelChildren[i].recommendations[j].generic_jobs[k].quantity);
                        if (secondLevelChildren[i].recommendations[j].generic_jobs[k].non_taxable == "1" && secondLevelChildren[i].recommendations[j].approval_status != "2") {
                            scopedNonTaxedValue = scopedNonTaxedValue + (secondLevelChildren[i].recommendations[j].generic_jobs[k].price * secondLevelChildren[i].recommendations[j].generic_jobs[k].quantity);
                        }
                    }

                    this.nonTaxableValue.push(scopedNonTaxedValue);
                    labourCost = labourCost + parseFloat(secondLevelChildren[i].recommendations[j].estimated_labor);

                    var totalAmount = parseFloat(totalPriceForParts.toString()) + parseFloat(totalPriceForGj.toString()) + parseFloat(labourCost.toString()) - parseInt(tobeDeducted.toString());
                    totalAmount = parseFloat(totalAmount.toString());

                    if (secondLevelChildren[i].recommendations[j].approval_status == "2") {
                        this.nonTaxableValue.splice(this.nonTaxableValue.length - 1, 1)
                        this.rejectedJobCalculation.push(totalAmount)
                    } else {
                        this.totalAcceptedCalculation.push(totalAmount);
                    }
                    tobeDeducted = 0;
                    totalAmount = 0;
                    totalPriceForParts = 0;
                    totalPriceForGj = 0;
                    labourCost = 0
                }
            } else {
            }
        }
    }
    getAcceptAndRejectCalculationForPage(serviceRequest, returningStatus) {
      console.log(this.serviceInfo.value.request_status)
      if(this.serviceInfo.value.request_status!='9' && this.serviceInfo.value.request_status!='10') {

        var total = 0;
        var rejected = 0;
        var approved = 0;
        var finalCalculation = 0;
        serviceRequest.children.forEach(l2child => {

          if (l2child.recommendations.length > 0) {
            total = 0;
            var l2childRecommendation = l2child.recommendations[0];
            var approval_status = l2childRecommendation.approval_status;

            // parts calculation for l2

            if (l2childRecommendation.parts.length > 0) {
              l2childRecommendation.parts.forEach((part) => {
                total = total + (parseFloat(part.part_price) * parseFloat(part.quantity))
              })
            }

            // GJ calculation for l2

            if (l2childRecommendation.generic_jobs.length > 0) {
              l2childRecommendation.generic_jobs.forEach(gj => {
                total = total + (parseFloat(gj.price) * parseFloat(gj.quantity))
              });
            }
            total = total + parseFloat(l2childRecommendation.estimated_labor);
            finalCalculation = finalCalculation + total
            if (approval_status == "2") {
              rejected = rejected + total;
            } else {
              approved = approved + total;
            }
            // this.ObjectJson.push({[serviceRequest.id]:{[l2child.id]:{"total":total,"status":approval_status}}})

          }
          else {
            // console.log(l2child.children)
            if (l2child.children.length > 0) {
            l2child.children.forEach(l3child => {

              // console.log("l3.id",l3child.id)
              total = 0;
              var l3childRecommendation = l3child.recommendations[0];
              var approval_status = l3childRecommendation.approval_status;

              if (l3childRecommendation.parts.length > 0) {
                l3childRecommendation.parts.forEach((part) => {
                  total = total + (parseFloat(part.part_price) * parseFloat(part.quantity))
                })
              }
              if (l3childRecommendation.generic_jobs.length > 0) {
                l3childRecommendation.generic_jobs.forEach(gj => {
                  total = total + (parseFloat(gj.price) * parseFloat(gj.quantity))
                });
              }
              total = total + parseFloat(l3childRecommendation.estimated_labor);

              finalCalculation = finalCalculation + total
              if (approval_status == "2") {
                rejected = rejected + total;
              } else {
                approved = approved + total;
              }
            });
          }
        }
      });

      if (returningStatus == null) {
        return [approved, rejected]
      }
      // return returningStatus == "accept" ? approved : rejected;
      }
    }
    downloadUspsReport() {
        let downloadUSPSReport_URL = '';
        let reportData;
        let qryStringUrl = "";
        let data = this.serviceInfo.value.inspection_reports;
        if (data.length) {
            reportData = data[0].acdelco_report_data;
            if (reportData) {
                downloadUSPSReport_URL = globalConstants.API_DOWNLOAD_INSPECTION_REPORT_ACDELCO_URL;
                qryStringUrl += "?report_type=2";
                qryStringUrl += "&acdelco_report_id=" + reportData.id;
                this.reportDownloadType = 2;
            } else {
                downloadUSPSReport_URL = globalConstants.API_DOWNLOAD_INSPECTION_REPORT_USPS_URL;
                reportData = data[0].usps_report_data;
                qryStringUrl += "?report_type=1";
                qryStringUrl += "&usps_report_id=" + reportData.id;
                this.reportDownloadType = 1;
            }

            this.reportDownloadLink = downloadUSPSReport_URL + qryStringUrl;
        } else {
            this.reportDownloadLink = '';
        }

    }
    /* image rotation dialog  */
    imageToolDialog(current, i, type, imgaar): void {
        let dialogRef = this.dialog.open(ImagetooldialogComponent, {
            panelClass: ['imageToolDialog'],
            width: "100%",
            data: {event: current, index: i, imgType: type, imgArray: imgaar, customerId: this.globalService.getCurrentCustomer()["id"]}
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }
    getInvoicePdf() {
        return globalConstants.root_url + 'service-invoices/' + this.serviceInfo.value.request_id + '.pdf';
    }
    getDateVerify(d){
        if(d=='0000-00-00 00:00:00' || d==null || d==false || typeof d=='undefined'){
            return false;
        }
        return true;
    }
    /*******video dialog******/
 videoShow(val): void {
    let dialogRef = this.dialog.open(VideoShowComponent, {
        panelClass: 'car-dialog-form',
        width: "800px",
        data: {name: val, url: globalConstants.S3_BUCKET_URL + 'recommendation-videos/'}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
  parseFloats(value){
    var val=parseFloat(value);
    if(isNaN(val)){
      return '0.00';
    }
    return val.toFixed(2);
  }

  getFileIcons(str){
    let ext=str.substr(str.lastIndexOf(".")+1);
    let type=0;
    if(ext!=''){
      type= this.globalService.getFileIcon({type:ext.toLowerCase()});
    }
    return type;
   }
   invokeDownload(url,type,fileName=''){
    let localFileName = 'DownloadedFile',localNameExt,localName;
    if(type == 'ServiceRequestDocument'){
      localName = fileName.split('.');
      localNameExt = localName[localName.length-1];
      localFileName = 'ServiceRequestDocument.'+localNameExt;
    }else if(type == 'CARInspectionReport'){
      localFileName = 'CARInspectionReport.pdf';
    }
    //console.log(localFileName);
    this.globalService.downloadAsset(url,localFileName);
  }
}
