<div mat-dialog-title class="relative">
  Part Suggestion for {{title}}
  <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
    <mat-icon class="close-icon-lg">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="center-block" style="max-width: 600px;">
    <div class="display-full margin-bottom-5">
        <div class="col-md-12 col-sm-12 pricing-method">
                   
            <div class="table-responsive relative ">
              <table class="table table-striped margin-bottom-10 ">
                <thead class="table-heading">
                  <tr>
                    <th>Select</th>
                    <th>Part Number</th>
                    <th>Part Name</th>
                    <th>QTY Required</th>
                    <th>QTY Available</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let r of parts;let i=index">
                    <td><input type="radio" name="part" value="{{i}}" /></td>
                    <td>{{r.part_number}}</td>
                    <td>{{r.name}}</td>
                    <td>{{param.for.part_qty}}</td>
                    <td>{{r.stock_quantity}}</td>
                  </tr>
                  <tr *ngIf="parts.length==0" class="tbl-center">
                      <td colspan="5">No Records Available</td>
                  </tr>
                </tbody>
              </table>
            </div>
         
        </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
    <button mat-raised-button color="accent" (click)="submit()" class="font-bold">Submit</button>
    <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
</mat-dialog-actions>