import { OnInit, Component, ChangeDetectionStrategy,ChangeDetectorRef , ViewChild, TemplateRef ,OnDestroy } from '@angular/core';
import { CalendarEvent, CalendarEventAction, CalendarMonthViewDay,CalendarEventTimesChangedEvent } from 'angular-calendar';
import { startOfDay,subWeeks,addWeeks,endOfWeek,subMonths,startOfWeek,startOfMonth, endOfDay, subDays, addDays, endOfMonth, isSameDay,addMonths, isSameMonth,addMinutes, addHours } from 'date-fns';
import { Subject } from 'rxjs/Subject';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
import {globalConstants} from "../../environments/globalConstants";
import {GlobalService} from "../../environments/global.service";
import { TechassignmentComponent } from '../shop/shopservicerequest/techassignment/techassignment.component';
import { MatDialog } from '@angular/material/dialog';
import {Subscription} from 'rxjs';

const colors: any = { 
  0 : { //active-cell
    secondary: '#f5f5f5',
    primary: '#ababab'
  },
  1 : { //warning-cell
    secondary: '#fcf8e3',
    primary: '#efd8a2'
  },
  2 : { //info-cell
    secondary: '#d9edf7',
    primary: '#1E88E5'
  },
  3:{ //success-cell
    secondary: '#c6f792',
    primary: ' #a1de61'
  },
  4 : { //info-cell
    secondary: '#d9edf7',
    primary: '#1E88E5'
  },
  5:{ //success-cell
    secondary: '#c6f792',
    primary: '#a1de61'
  },
  6:{ //success-completed
    secondary:'#aaeeaa',
    primary: '#689F38'
  },
  7:{ //success-cell
    secondary: '#c6f792',
    primary: '#a1de61'
  },
  8 : { //success-paid
    secondary:'#B2DFDB',
    primary: '#009688'
  },
  12:{ //work-progress-cell
    secondary: '#c2f7c2',
    primary: '#b4eab4'
  },
  default:{
    secondary:'',
    primary:''
  }
}


type CalendarPeriod = 'day' | 'week' | 'month';

function addPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
  return {
    day: addDays,
    week: addWeeks,
    month: addMonths
  }[period](date, amount);
}

function subPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
  return {
    day: subDays,
    week: subWeeks,
    month: subMonths
  }[period](date, amount);
}

function startOfPeriod(period: CalendarPeriod, date: Date): Date {
  return {
    day: startOfDay,
    week: startOfWeek,
    month: startOfMonth
  }[period](date);
}

@Component({
  selector: 'app-calendarview',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendarview.component.html',
  styleUrls: ['./calendarview.component.scss']
})

export class CalendarviewComponent implements OnInit {

  @ViewChild('modalContent') modalContent: TemplateRef<any>;
  refresh: Subject<any> = new Subject();
  viewDate: any = new Date();
  public view: CalendarPeriod = 'month';
  maxDate: Date = addMonths(new Date(), 2);
  activeDayIsOpen: boolean = false;
  public data;
  public eventTitleTemplate :any =""
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  events: Array<CalendarEvent<any>>;

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-eye"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Views', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-ticket"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Ticket', event);
      }
    }
  ];
  public currentUser:any={};
  public serviceRequest=[];
  public currentCalMonth='';
  public optionsStatus='';
  public nextBtnDisabled=false;
  public isEmployee=false
  public subscriptionDataForAPI: Subscription = new Subscription();
  constructor(private router: Router,public gs :GlobalService,public cdr: ChangeDetectorRef, public dialog: MatDialog) {
    this.currentUser = this.gs.getCurrentUser();
  }

  ngOnInit() {
    setTimeout(()=>{
      let modulePermission=this.gs.getAcl('service_request','enable');
      this.isEmployee=this.gs.isSubscribe('employee')
      if(!(modulePermission)){
        this.router.navigate(['shops/action/forbidden']);
      }
    }, globalConstants.RELOAD_WAIT)
    this.dateOrViewChanged();
    this.getCalenderData();
    this.gs.setMetaData("SHOPS","CALENDER_VIEW")
  }
  getCalenderData(){
    this.serviceRequest=[];
    let content='user_id='+this.currentUser.id+'&user_type='+this.currentUser.user_type+'&date='+this.currentCalMonth+'&status='+this.optionsStatus;
    this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_CALENDAR_SERVICE_REQUEST, content).subscribe((cal:any)=>{
      if(cal.result==1){
        // console.log(cal.data)
        cal.data.sort((a, b) => {

          return (parseInt(a.status)-parseInt(b.status));
      });

        for(var i in cal.data){
          let lastDate:any=addDays(this.gs.getFullFormateDate(cal.data[i].completion_date,"G",'C'),0);
          let appDate:any=this.gs.getFullFormateDate(cal.data[i].requested_delivery_date,'G','C');
          let customer=cal.data[i].first_name+ " "+cal.data[i].last_name;
          let vehicle=(cal.data[i].year ? cal.data[i].year+" ":"") + (cal.data[i].make ? cal.data[i].make+" ":"") + cal.data[i].model+(typeof cal.data[i].sub_model!='undefined' && cal.data[i].sub_model ? '('+cal.data[i].sub_model+')':'');
          let startDate=startOfDay(appDate);
          if(startDate >= lastDate){
            lastDate=addMinutes(appDate,30);
          }
          let tmp={
            start:subDays(appDate,0),
            end: lastDate,
            title: '#'+cal.data[i].request_id + '||'+cal.data[i].title + '|| ' +vehicle+ '| ' + customer + '| ' +appDate,
            id: cal.data[i].request_id,
            view: cal.data[i].id,
            actions: this.actions,color: ( typeof colors[cal.data[i].status] !='undefined' ? colors[cal.data[i].status] : colors.default )
          };
          this.serviceRequest.push(tmp);
        }
        
      }
      this.events =this.serviceRequest;
      this.cdr.markForCheck();
    });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent('Dropped or resized', event);
    this.refresh.next();
  }

  handleEvent(action: string, event: any): void {
    // console.log(action,event);
    if(action=='Views'){
      this.router.navigate(['/shops/view-service-request/', event.view ]);
    }else if(action=='Ticket'){
      this.ticketAssignment(event.view)
    }else{
      this.router.navigate(['/shops/list-service-requests/', event.id ]);
    }
  }

  addEvent(): void {
    this.events.push({
      title: 'New event',
      start: startOfDay(new Date()),
      end: endOfDay(new Date()),
      color: colors.red,
      draggable: false,
      resizable: {
        beforeStart: true,
        afterEnd: true
      }
    });
    this.refresh.next();
  }
  listView(){
    this.router.navigate(['shops/list-service-requests']);
  }
  previousMonth(event){
    this.currentCalMonth=this.formatDate(this.viewDate);
    this.dateOrViewChanged();
    this.getCalenderData();
  }
  formatDate(date) {
    let d=new Date(date);
    if (!d || date == null || date == "0000-00-00 00:00:00") {return ""}
    return (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear();
  }
  dateOrViewChanged(): void {
    this.nextBtnDisabled =false;
    //  !this.dateIsValid(
    //   startOfPeriod(this.view, addPeriod(this.view, this.viewDate, 2))
    // );
  //  if (this.viewDate > this.maxDate) {
  //    this.viewDate = this.maxDate;
  //    this.dateOrViewChanged();    
  //   }
  }
  changeView(view: CalendarPeriod): void {
    this.view = view;
    this.dateOrViewChanged();
  }
  dateIsValid(date: Date): boolean {
    return date <= this.maxDate;
  }
  closeDayEvent(){
    this.activeDayIsOpen = false;
  }
 /**
   * @createdBy Vikas Kumar
   * @trackID <:CAR-1396>
   * @created 22-04-2020
   * @see Function assign task
   */
  ticketAssignment(id){
    if(this.isEmployee==false){
        return false;
    }
    let dialogRef = this.dialog.open(TechassignmentComponent, {
        panelClass: ['car-dialog-form', 'ticket-dialog'],
        data: {request_id: id}
    });
  }

  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
  }  
  
}
