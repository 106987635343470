import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { globalFunctions } from '../../../../environments/globalFunctions';
import {Http, Headers, Response} from '@angular/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
import {trigger,state,style,animate,transition} from '@angular/animations';

@Component({
  selector: 'app-authenticatepassworddialog',
  templateUrl: './authenticatepassworddialog.component.html',
  styleUrls: ['./authenticatepassworddialog.component.scss'],
  animations: [
    trigger('popOverState', [
      state('show', style({
        opacity:1,
        height:'69px',
        transform:'translateY(0px)',
        'z-index':'0'
      })),
      state('hide',   style({
        opacity:0,
        height:'0px',
        transform:'translateY(-69px)',
        'z-index':'-1'
      })),
      transition('show => hide', animate('100ms ease-out')),
      transition('hide => show', animate('0.2s 100ms ease-out'))
    ])
  ]
})
export class AuthenticatepassworddialogComponent {
    public currentCustomer;
    public currentCustomerName;
    public isCollapsed:boolean = true;
    public reopenTicketForm:FormGroup;
    public checkForPwdForm:FormGroup;
    public submitted=false;
    public checkForPassword:boolean = false;
    public hide;
    show = false;
    public deleteNote=false;
    public hideActions = false;
    public changePassword=false
    constructor( @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AuthenticatepassworddialogComponent>, private formBuilder: FormBuilder, private globalService: GlobalService, private http: Http, private router: Router) { 
        if(localStorage.getItem("loggedInAdmin")) {
            this.currentCustomer        = JSON.parse(localStorage.getItem("loggedInAdmin"));
            this.currentCustomerName    = this.currentCustomer.first_name + this.currentCustomer.last_Name;
            this.initilizeCheckPasswordForm();
        } else {
            this.router.navigate(["/admin"]);
        }
        this.deleteNote=(typeof data.delete=='undefined' ? false : data.delete);
        this.changePassword=(typeof data.changePassword=='undefined' ? false : data.changePassword);
        this.globalService.diaLogObj(this.dialogRef);
    }

    ngOnInit() {
    }
    
    initilizeCheckPasswordForm() { 
        this.checkForPwdForm = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(6)]],
            user_type: this.currentCustomer.user_type,
            user_id: this.currentCustomer.id,
        })
    }
    
    checkIfValid() {
        if (this.checkForPwdForm.valid ) {
            this.submitted = false;
        }
    }
    
    cancelAuthentication() {
        this.checkForPassword = false;
        if(this.changePassword==false){
            console.log('gigigi')
            this.router.navigate(["/admin/dashboard"]);
        }
        this.dialogRef.close();
    }
    
    validatePassword() {
        if(this.checkForPwdForm.valid) {
            let formdata = this.checkForPwdForm.value;
            formdata.password = formdata.password.replaceAll('+','%2B');
            var stringifiedFormData = globalFunctions.stringifyFormData(formdata, null);
            this.globalService.callAPI(globalConstants.API_PASSWORD_AUTHENTICATION_URL,stringifiedFormData).subscribe((data:any)=> {
                var message = data.message;
                if (data.result == "1") {
                    this.checkForPassword = true;
                    this.dialogRef.close(this.checkForPassword);
                    //this.router.navigate(["/admin/shop/reopen-ticket"]);
                } else {
                    this.globalService.snackbar("error", message);
                }
            });
        } else{
            this.submitted = true;
        }
    }
}
