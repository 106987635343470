<div class="alert alert-dismissible error-message display-full" role="alert" style="position: static;" *ngIf="type == 'error'">
    <button class="close close-message" (click)="snackbar.dismiss()">&times;</button>
    <div class="col-xs-12 msg-content">
        <i class="fa fa-times-circle fa-2x"></i>
        <strong class="msg-type">ERROR!</strong>
        {{ message }}
        <button type="button" *ngIf="saveButton" class="btn btn-sm btn-success addNewPart" (click)="doAction()">Save Now</button>
        <button type="button" *ngIf="refreshButton" class="btn btn-sm btn-success addNewPart" (click)="doAction()">Refresh</button>
    </div>
</div>
<div class="alert alert-dismissible success-message display-full" style="position: static;" *ngIf="type == 'success'">
    <button class="close close-message" (click)="snackbar.dismiss()">&times;</button>
    <div class="col-xs-12 msg-content">
        <i class="fa fa-check-circle fa-2x"></i>
        <strong class="msg-type">SUCCESS!</strong>
        {{ message }}
        <button type="button" *ngIf="saveButton" class="btn btn-sm btn-success addNewPart" (click)="doAction()">Save Now</button>
    </div>
</div>