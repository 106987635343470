import {Component, ViewChild, ChangeDetectorRef, HostListener, OnInit, ViewEncapsulation,ChangeDetectionStrategy} from '@angular/core';
import {Router, ActivatedRoute, ParamMap, NavigationEnd, RoutesRecognized} from '@angular/router';
import {Title} from '@angular/platform-browser';
// import {MatSidenav} from '@angular/material';
// import {NgProgress, NgProgressModule, NgProgressBrowserXhr} from 'ngx-progressbar';
import {Location, LocationStrategy, PathLocationStrategy,} from '@angular/common';
import {GlobalService} from "../../environments/global.service"
import {GoogleAdsComponent} from "../../app/google-ads/google-ads.component"
import {DragulaService} from 'ng2-dragula';
import {globalConstants} from '../../environments/globalConstants'
import {globalFunctions} from "../../environments/globalFunctions"
import {ErrorHandler, Injectable, Injector } from '@angular/core';
import { AppComponent } from '../app.component';
@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.scss']
})
export class PagenotfoundComponent implements OnInit {
public whereToGo;
  constructor(public router:Router,public globalService:GlobalService,public app:AppComponent,public location:Location) {
    // console.log(router);
    this.app.showNoNLoggedInRoute=true;
    var getPreviousURL= document.referrer.split("/");
    var whoWasThere = getPreviousURL[3];
    var whereToGo;
    // this.location.go("/page-not-found");

    switch(whoWasThere){
      case "customer": this.whereToGo="/customer/dashboard";break;
      case "shops": this.whereToGo="/shops/dashboard";break;
      case "admin": this.whereToGo="/admin/dashboard";break;
    }
  }

  goBack(){
    var currentURL = window.location.href;
    var isCustomerCameTo404 =  currentURL.indexOf("/customer");
    var isShopCameTo404 =  currentURL.indexOf("/shops");
    var isAdminCameTo404 =  currentURL.indexOf("/admin");

    if (isCustomerCameTo404 != -1) {
      this.router.navigateByUrl("/customer")
    } else if(isShopCameTo404 != -1) {
      this.router.navigateByUrl("/shops")
    } else if(isAdminCameTo404 != -1) {
      this.router.navigateByUrl("/admin")
    } else {
      if(this.app.isAnyOneLoggedin()) {
        parent.history.go(-2)
      } else {
        this.router.navigateByUrl("/customer")
      }
    }
  }
  ngOnInit() {
  }
}
