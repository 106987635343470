<div mat-dialog-title class="relative">
  <span *ngIf="!isEdit">Adjust Schedule</span>
  <span *ngIf="isEdit"> Edit Schedule</span>
  <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
    <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>

<mat-dialog-content class="ticket_dialog_content overflow-data">
  <div class="center-block search-employee" style="max-width: 650px;">
    <form class="form-horizontal form-control-normal" autocomplete="off" (ngSubmit)="onSubmit()" [formGroup]="tech">
      <div class="vehicle-detail" *ngIf="(selectSR | json) != ({} | json)">
        <div class="row">
          <div class="col-sm-12">
            <h4>Service Request Information</h4>
          </div>
          <div class="col-sm-12"><label>SR ID : </label> {{selectSR.request_id}}</div>
          <div class="col-sm-12" *ngIf="selectSR.requested_delivery_date && selectSR.requested_delivery_date!='-'">
            <label>Appointment Date : {{ convertISODate(selectSR.requested_delivery_date) | date:'M/d/yy h:mm a'}} </label>
          </div>
        </div>
        <div class="break row"></div>
        <div class="row">
          <div class="col-sm-12">
            <h4>Vehicle Information</h4>
          </div>
          <div class="col-sm-12" *ngIf="selectSR.year !=undefined && selectSR.make !=undefined &&
          selectSR.model !=undefined"><label>Year/Make/Model : </label>
            {{selectSR.year+'/'+selectSR.make+'/'+selectSR.model}} &nbsp;&nbsp; <ng-container *ngIf="selectSR.state">
              <label>State : </label> {{selectSR.state}}
            </ng-container>
          </div>
          <div class="col-sm-12"><label>License Plate : </label> {{selectSR.license_plate}}</div>
          <div class="col-sm-12" *ngIf="selectSR.vin"><label>VIN : </label> {{selectSR.vin}}</div>
        </div>
        <div class="break row"></div>
        <div class="row">
          <div class="padd"><label>Estimated Service Time : </label> {{estimated_hours ? estimated_hours+' hour' : '-'}}
          </div>
        </div>
      </div>

      <div class="display-full margin-top as-date-section">
        <div class="col-md-3 mat-radio-label-content">Start Date:</div>
        <div class="col-md-5 date-input">
          <div class="vlio-datepicker relative">
            <dp-date-picker mode="day" [config]="datePickerConfig" theme="dp-material dp-main" class="btn-block"
              placeholder="Select Start date" formControlName="event_start_date"
              (ngModelChange)="startDateHandler($event)" #dayPicker></dp-date-picker>
            <button type="button" mat-icon-button (click)="dayPicker.api.open();">
              <mat-icon>date_range</mat-icon>
            </button>
          </div>
          <div class="errors"
            *ngIf="tech.controls['event_start_date'].invalid && (tech.controls['event_start_date'].dirty || tech.controls['event_start_date'].touched)">
            <mat-error
              [hidden]="!((tech.controls['event_start_date'].dirty) &&  tech.controls['event_start_date'].errors?.required)">
              Start Date is <strong>required</strong>
            </mat-error>
          </div>
        </div>
        <div class="col-md-4 time-input">
          <ngx-select class="display-full margin-bottom-15" [noAutoComplete]="true"  formControlName="event_start_time" placeholder="Start Time"
            [items]="sTimeSlots" (select)="startTimeHandler($event)">
          </ngx-select>
          <div
            *ngIf="tech.controls['event_start_time'].invalid && (tech.controls['event_start_time'].dirty || tech.controls['event_start_time'].touched)">
            <mat-error
              [hidden]="!((tech.controls['event_start_time'].dirty) &&  tech.controls['event_start_time'].errors?.required)">
              Start Time is <strong>required</strong>
            </mat-error>
          </div>
        </div>
      </div>
      <div class="display-full as-date-section">
        <div class="col-md-3 mat-radio-label-content">End Date:</div>
        <div class="col-md-5 date-input">
          <div class="vlio-datepicker relative">
            <dp-date-picker mode="day" [config]="datePickerConfig" theme="dp-material dp-main" class="btn-block"
              placeholder="Select End date"  formControlName="event_end_date"
              #dayPickerTwo>
            </dp-date-picker>
            <button type="button" mat-icon-button (click)="dayPickerTwo.api.open();">
              <mat-icon>date_range</mat-icon>
            </button>
          </div>
          <div
            *ngIf="tech.controls['event_end_date'].invalid && (tech.controls['event_end_date'].dirty || tech.controls['event_end_date'].touched)">
            <mat-error
              [hidden]="!((tech.controls['event_end_date'].dirty) &&  tech.controls['event_end_date'].errors?.required)">
              End Date is <strong>required</strong>
            </mat-error>
          </div>
          <div class="f-error">
            <mat-error *ngIf="error.dError">End Date can't before start date.</mat-error>
          </div>
        </div>
        <div class="col-md-4 time-input">
          <ngx-select class="display-full margin-bottom-15" [noAutoComplete]="true" formControlName="event_end_time" placeholder="End Time"
            [items]="eTimeSlots">
          </ngx-select>
          <div
            *ngIf="tech.controls['event_end_time'].invalid && (tech.controls['event_end_time'].dirty || tech.controls['event_end_time'].touched)">
            <mat-error
              [hidden]="!((tech.controls['event_end_time'].dirty) &&  tech.controls['event_end_time'].errors?.required)">
              End Time is <strong>required</strong>
            </mat-error>
          </div>
          <div class="f-error">
            <mat-error *ngIf="error.tError">End time can't before start time.</mat-error>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="inquiry-action relative">
  <div class="flexBtns">
    <button mat-raised-button color="accent" class="font-bold" (click)="onSubmit()" title="Submit">Submit</button>
    <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
  </div>
</mat-dialog-actions>