import { Component, OnInit, ViewChild,OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap,NavigationEnd, Params  } from '@angular/router';
import { Http,Headers, Response} from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { globalFunctions } from "../../../../environments/globalFunctions";
import { globalConstants } from "../../../../environments/globalConstants";
import {GlobalService} from "../../../../environments/global.service";
import { lang } from "../../../../environments/lang-en";
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TextMaskModule } from 'angular2-text-mask';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppComponent } from '../../../app.component';

@Component({
  selector: 'app-editshopvendor',
  templateUrl: './editshopvendor.component.html',
  styleUrls: ['./editshopvendor.component.scss']
})

export class EditshopvendorComponent implements OnDestroy {

    public currentCustomer;
    public currentCustomerName;
    public isCollapsed:boolean = true;
    public editVendorForm:FormGroup;
    public idFromUrl;
    public currentVendorDetails;
    public vendorName;
    public vendorLink;
    public leftMenuStatus:boolean;
    public subscriptionDataForAPI: Subscription = new Subscription();

    constructor(private app:AppComponent,private activatedRoute:ActivatedRoute,private formBuilder: FormBuilder,private route: ActivatedRoute,public titleService:Title,private http: Http,private router: Router,public snackBar: MatSnackBar, public globalService: GlobalService){
        if(localStorage.getItem("loggedInUser")) {
            this.setParams();
            this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
            this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
            this.editVendorForm = this.formBuilder.group({
                vendor_name: ['', [Validators.required]],
                //vendor_link: ['', [Validators.required, Validators.pattern("https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,}")]],
                vendor_link: ['', [Validators.required,Validators.pattern(globalConstants.WEB_URL_REGEX)]],
                user_type: this.currentCustomer.user_type,
                user_id: this.currentCustomer.id,
                shop_id: this.currentCustomer.shop_id,
                vendor_id: this.idFromUrl,
                left_menu_status: [''],
            })
        } else {
            this.router.navigate(["/shops"]);
        }
        setTimeout(()=>{
        let isEnable=this.globalService.getAcl('vendors','edits');
        if(!(isEnable)){
            this.router.navigate(['shops/action/forbidden']);
        }
    },globalConstants.RELOAD_WAIT)
    this.globalService.setMetaData("SHOPS","EDIT_VENDOR")
    }

    setParams(){
      this.activatedRoute.params.subscribe((params: Params) => {
          this.idFromUrl = params["vendor_id"];
       });
    }

    ngOnInit(){
        this.setParams();

        var shopContent = "user_id="+this.currentCustomer.id+"&user_type="+this.currentCustomer.user_type+"&vendor_id="+ this.idFromUrl;
         this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_VENDORS_LIST_URL,shopContent).subscribe((data:any) => {
            this.currentVendorDetails = data.data;
            this.vendorName = this.currentVendorDetails.vendor_name;
            this.vendorLink = this.currentVendorDetails.link;
            if (this.currentVendorDetails.menu_status == "1") {
                this.leftMenuStatus = true;
                this.editVendorForm.controls.left_menu_status.setValue(1);
            } else {
                this.leftMenuStatus = false;
                this.editVendorForm.controls.left_menu_status.setValue(0);
            }
        });
    }


    editVendor() {
        if(this.editVendorForm.valid) {
            var stringifiedData =this.globalService.formsData(this.editVendorForm.value);
            this.globalService.formData(globalConstants.API_EDIT_VENDOR_URL,stringifiedData).subscribe((data:any)=> {
                var message = data.message;
                if (data.result == "1") {
                    this.router.navigate(['shops/list-vendors']);
                    this.globalService.snackbar('success', message);
                    this.app.editVendor(this.editVendorForm.value);
                    if(data.data.menu_status == '1') {
                        this.app.addNewItemToMenuItemResources(data.data)
                    } else {
                        this.app.deleteVendor(data.data.id)
                    }
                } else {
                    this.globalService.snackbar("error", message);
                }
            });
        } else {
            this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
        }
    }
    checkLeftMenuStatus(event) {
        if (event.checked) {
            this.editVendorForm.controls.left_menu_status.setValue(1);
        } else {
            this.editVendorForm.controls.left_menu_status.setValue(0);
        }
    }

    
  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
  } 
}
