import { Component, OnInit, ViewChild, Input, SimpleChange, OnChanges } from '@angular/core';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {MatDatepicker} from '@angular/material/datepicker';
import {MatPaginator} from '@angular/material/paginator';
import { MatSort} from '@angular/material/sort';
import {DataSource, SelectionModel} from '@angular/cdk/collections';
import {Http, Headers, Response} from '@angular/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {globalFunctions} from "../../../../environments/globalFunctions"
import {globalConstants} from "../../../../environments/globalConstants"
import {GlobalService} from "../../../../environments/global.service"
import {Observable} from 'rxjs/Observable';
import {Title} from '@angular/platform-browser';
import {TechassignmentComponent} from '../../shopservicerequest/techassignment/techassignment.component';
import {ServiceRequestLogsComponent} from '../../shopservicerequest/service-request-logs/service-request-logs.component';
import { trigger,state,style,animate,transition } from '@angular/animations';
import {MatDialog} from '@angular/material/dialog';
import {AppComponent} from '../../../app.component';
import { MatTableDataSource } from '@angular/material/table';
const SERVICE_LIST='shop-service';
const TABLE_TYPE_CREATED = 1;
const TABLE_TYPE_ASSIGNED = 2;
@Component({
  selector: 'app-empreportdatatableview',
  templateUrl: './empreportdatatableview.component.html',
  styleUrls: ['./empreportdatatableview.component.scss']
})
export class EmpreportdatatableviewComponent implements OnChanges,OnInit {
  @Input() empId: string;
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() dateCriteria: string;
  displColsCreated = ['request_id', 'title', 'customer_name', 'vehicle_name',   'status','action'];
  displColsAssigned = ['request_id', 'title', 'customer_name', 'vehicle_name', 'hours_logged', 'hours_billed', 'status','action'];
  dataSourceCreated: MatTableDataSource<employeeReportData> = new MatTableDataSource();
  dataSourceAssigned: MatTableDataSource<employeeReportData> = new MatTableDataSource();
  public more_rows;
  public request_id: string;
  public currentCustomer;
  public optionsCreated = {
      user_id: "",
      user_type: "User",
      start: 0,
      limit: 10,
      date_criteria: '1',
      start_date: '01/15/2019',
      end_date: '01/15/2019',
      emp_id: ""
  }
  public optionsAssigned = {
      user_id: "",
      user_type: "User",
      start: 0,
      limit: 10,
      date_criteria: '1',
      start_date: '01/15/2019',
      end_date: '01/15/2019',
      emp_id: ""
  }
  public statuses = ['Appointment', 'Pending', 'Processing', 'Waiting for Approval', '', 'Service Completed', 'Completed', 'Approval Completed', 'Completed And Paid', "", "Non-CAR", "", "Work In Progress"];
  private inputToOptionMap = {
    'empId' : 'emp_id',
    'startDate' : 'start_date',
    'endDate' : 'end_date',
    'dateCriteria' : 'date_criteria'
  }
  public isReportGenerate=false;
  public isSrEnable = false;
  private reloadOnInputChange = false;
  private SHOP_EMPLOYEE_LOG_CREATED:string;
  private SHOP_EMPLOYEE_LOG_ASSIGNED:string;
  @ViewChild('paginatorCreated') paginatorCreated: MatPaginator;
  @ViewChild('tableCreated', { read: MatSort }) sortCreated: MatSort;
  @ViewChild('paginatorAssigned') paginatorAssigned: MatPaginator;
  @ViewChild('tableAssigned', { read: MatSort }) sortAssigned: MatSort;
  public recordsTotal: any;
  public record: any[];
  public recordCreate: any[];
  public recordAssign: any[];
  
  constructor(private app: AppComponent, public router: Router, private http: Http, public globalService: GlobalService, public dialog: MatDialog) {
    this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
    this.reinitializeOptions();
    this.isReportGenerate=this.globalService.isUserReportOn();
    setTimeout(()=>{
        this.isSrEnable = this.globalService.getAcl('service_request','views');
        // console.log('is sr enable',this.isSrEnable);
    },globalConstants.RELOAD_WAIT)
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    let empIdChanged = false;
    for (let propName in changes) {
      let changedProp = changes[propName];
      this.optionsCreated[this.inputToOptionMap[propName]] = changedProp.currentValue;
      this.optionsAssigned[this.inputToOptionMap[propName]] = changedProp.currentValue;
      if(propName == 'empId') empIdChanged = true;
    }
    if(empIdChanged){
      this.SHOP_EMPLOYEE_LOG_CREATED = 'shop-employeelogs-created-'+this.empId+'-';
      this.SHOP_EMPLOYEE_LOG_ASSIGNED = 'shop-employeelogs-assigned-'+this.empId+'-';
    }
  }

  // clearCurrentDataTableKeys(){
  //   for(let val of ['totalRecords','more_rows','first_list']){
  //     localStorage.removeItem(SHOP_EMPLOYEE_LOG_CREATED+val)
  //     localStorage.removeItem(SHOP_EMPLOYEE_LOG_ASSIGNED+val)
  //   }
  //   for(let key in this.optionsCreated){
  //     localStorage.removeItem(SHOP_EMPLOYEE_LOG_CREATED+key);
  //     localStorage.removeItem(SHOP_EMPLOYEE_LOG_ASSIGNED+key);
  //   }
  // }

  ngOnInit() {
    this.reloadOnInputChange = true;
    this.globalService.localGetterSetter("totalRecords", 0, this.SHOP_EMPLOYEE_LOG_CREATED);
    this.globalService.localGetterSetter("totalRecords", 0, this.SHOP_EMPLOYEE_LOG_ASSIGNED);
  }

  reinitializeOptions(tableType = null) {
    if(tableType == TABLE_TYPE_CREATED){
      this.globalService.localGetterSetter(this.optionsCreated, this.optionsCreated, this.SHOP_EMPLOYEE_LOG_CREATED);
      this.optionsCreated.user_id = this.currentCustomer.id;
      this.optionsCreated.date_criteria = '1';
      this.optionsCreated.start_date = this.currentDate();
      this.optionsCreated.end_date = this.currentDate();
      this.optionsCreated.limit = 10;
      this.optionsCreated.start = 0; 
      this.optionsCreated.emp_id = this.empId;
    }else if(tableType == TABLE_TYPE_ASSIGNED){
      this.globalService.localGetterSetter(this.optionsAssigned, this.optionsAssigned, this.SHOP_EMPLOYEE_LOG_ASSIGNED);
      this.optionsAssigned.user_id = this.currentCustomer.id;
      this.optionsAssigned.date_criteria = '1';
      this.optionsAssigned.start_date = this.currentDate();
      this.optionsAssigned.end_date = this.currentDate();
      this.optionsAssigned.limit = 10;
      this.optionsAssigned.start = 0;
      this.optionsAssigned.emp_id = this.empId;
    }else{
      this.globalService.localGetterSetter(this.optionsCreated, this.optionsCreated, this.SHOP_EMPLOYEE_LOG_CREATED);
      this.optionsCreated.user_id = this.currentCustomer.id;
      this.optionsCreated.date_criteria = '1';
      this.optionsCreated.start_date = this.currentDate();
      this.optionsCreated.end_date = this.currentDate();
      this.optionsCreated.limit = 10;
      this.optionsCreated.start = 0; 
      this.optionsCreated.emp_id = this.empId;
      this.globalService.localGetterSetter(this.optionsAssigned, this.optionsAssigned, this.SHOP_EMPLOYEE_LOG_ASSIGNED);
      this.optionsAssigned.user_id = this.currentCustomer.id;
      this.optionsAssigned.date_criteria = '1';
      this.optionsAssigned.start_date = this.currentDate();
      this.optionsAssigned.end_date = this.currentDate();
      this.optionsAssigned.limit = 10;
      this.optionsAssigned.start = 0;
      this.optionsAssigned.emp_id = this.empId;
    }
  }

  currentDate() {
    return (new Date().getMonth() + 1) + "/" + new Date().getDate() + "/" + new Date().getFullYear()
  }

  formatDate(date) {
    if (!new Date(date) || date == null || date == "0000-00-00 00:00:00") {
        return "-"
    }
    return (new Date(date).getMonth() + 1) + "/" + new Date(date).getDate() + "/" + new Date(date).getFullYear()
  }

  autoAssign(rId:any, url:any) {
    let data = { assign_totech: 1, emp_id: this.currentCustomer.id, request_id: rId, user_id: this.currentCustomer.id, user_type: this.currentCustomer.user_type };
    this.globalService.callAPI(globalConstants.API_ASSIGN_SERVICE_REQUEST, data).subscribe((tech:any) => {
      if (tech.result == 1) {
          this.redirectUrl(url);
      } else if(tech.code=='400'){
          this.redirectUrl(url);
      }
    }, (error:any) => { })
  }

  redirectUrl(url:any) {
    window.open(url);
  }

  reportIsOn(row:any, url:any) {
    if (!this.isReportGenerate) {
      this.redirectUrl(url);
    } else if (typeof row.is_assign != 'undefined' && row.is_assign == 1) {
      this.redirectUrl(url);
    } else if (typeof row.is_assign != 'undefined' && row.is_assign == 0 && row.is_assign == 0) {
      //this.autoAssign(row.id, url);
      this.redirectUrl(url);
    }
  }

  viewServiceRequest(id,row?:any) {
    this.reportIsOn(row,"/shops/view-service-request/" + id);
  }

  viewServiceLogs(requestId,requestNumber,fromAssigned?){
    let dialogParams = {request_id:requestId, obj:this};
    if(fromAssigned) dialogParams['user_id'] = this.empId
    let dialogRef = this.dialog.open(ServiceRequestLogsComponent, {
      panelClass: ['service-logs-dialog'],
      // position: {top: '50px'},
      width: "1100px",
      height: "550px",
      data: dialogParams
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

  scrolling() {
    this.globalService.setScrolling('.car-datatable', 1000, -20);
  }

  paginateByLimit(event:any,tableType:any) {  
    this.globalService.setScrolling('body', 600, 0);
    if(tableType == TABLE_TYPE_CREATED){
      this.optionsCreated.limit = event.pageSize;
      this.optionsCreated.start = 0;
    }else{
      this.optionsAssigned.limit = event.pageSize;
      this.optionsAssigned.start = 0;
    }
    this.setDataTable(tableType);
  }

  getTotalRecords(tableType) {
    let selectedTable = tableType == TABLE_TYPE_CREATED ? this.SHOP_EMPLOYEE_LOG_CREATED : this.SHOP_EMPLOYEE_LOG_ASSIGNED;
    return parseInt(localStorage.getItem(selectedTable + 'totalRecords'))
  }

  paginate(event, type, tableType) {
    this.scrolling();
    if(tableType == TABLE_TYPE_CREATED){
      this.optionsCreated.start = (type == "next") ? this.optionsCreated.start + this.optionsCreated.limit : this.optionsCreated.start - this.optionsCreated.limit;
    }else{
      this.optionsAssigned.start = (type == "next") ? this.optionsAssigned.start + this.optionsAssigned.limit : this.optionsAssigned.start - this.optionsAssigned.limit;
    }
    this.setDataTable(tableType);
  }

  firstList(tableType) {
    return tableType == TABLE_TYPE_CREATED ? this.globalService.firstList(this.SHOP_EMPLOYEE_LOG_CREATED, this.optionsCreated) : this.globalService.firstList(this.SHOP_EMPLOYEE_LOG_ASSIGNED, this.optionsAssigned);
  }

  lastList(tableType) {
    return tableType == TABLE_TYPE_CREATED ? this.globalService.lastList(this.SHOP_EMPLOYEE_LOG_CREATED) : this.globalService.lastList(this.SHOP_EMPLOYEE_LOG_ASSIGNED);
  }
  
  getUpperLimit(currentLastLimit, total) {
    if (currentLastLimit > total) {
        return total
    } else {
        return currentLastLimit
    }
  }

  /* LOAD DATA IN TABLES */
  setDataTable(tableType = null) {
    if(tableType == TABLE_TYPE_CREATED){
        this.callApiForDataSourceCreate();
    }else if(tableType == TABLE_TYPE_ASSIGNED){
      this.callApiForDataAssigned();
    }else{
      this.callApiForDataSourceCreate();
      this.callApiForDataAssigned();
    }
  }


  callApiForDataSourceCreate(){
    this.recordCreate = [];
    let API_URL ='';
    if(TABLE_TYPE_CREATED){
      API_URL = globalConstants.API_GET_EMPLOYEES_REPORTS_CREATED_TICKETS_URL;
    }else{
      API_URL = globalConstants.API_GET_EMPLOYEES_REPORTS_ASSIGNED_TICKETS_URL;
    }
    let content = globalFunctions.stringifyFormData(this.optionsCreated, null);
    let callApi = this.globalService.callAPI(API_URL, content);
    callApi.subscribe((data:any) => {
        if (data.data != undefined) {
            let moreRows = 'false';
            let firstList = 'false';
            // console.log(this.options.emp_id,this.localStorageKey);
            this.globalService.localGetterSetter("totalRecords", data.recordsTotal, this.SHOP_EMPLOYEE_LOG_CREATED);
            if (data.more_rows == "true") {
                moreRows = "true";
            }
            this.globalService.localGetterSetter("more_rows", moreRows, this.SHOP_EMPLOYEE_LOG_CREATED);
            if (data.start >= 0 && data.start <= 5) {
                firstList = "true";
            }
            this.globalService.localGetterSetter("first_list", firstList, this.SHOP_EMPLOYEE_LOG_CREATED);

            this.recordsTotal = data.data.length;

            if (data.data.length == undefined) {
                this.AddRecordForCreate(data.data);
                this.dataSourceCreated = new MatTableDataSource(this.recordCreate)
                this.dataSourceCreated.sort = this.sortCreated;
            } else {
                for (var i = 0; i < data.data.length; i++) {
                    this.AddRecordForCreate(data.data[i]);
                }
                this.dataSourceCreated = new MatTableDataSource(this.recordCreate)
                this.dataSourceCreated.sort = this.sortCreated;
            }
            this.globalService.localGetterSetter(null, this.optionsCreated, this.SHOP_EMPLOYEE_LOG_CREATED);
        } else {
            this.globalService.localGetterSetter("totalRecords", '0',this.SHOP_EMPLOYEE_LOG_CREATED);
        }
    })
  }

  AddRecordForCreate(record){
    var data = this.createRecord(record);
      this.recordCreate.push(data);
  }

 callApiForDataAssigned(){
  this.recordAssign = [];
  let API_URL ='';
  if(TABLE_TYPE_CREATED){
    API_URL = globalConstants.API_GET_EMPLOYEES_REPORTS_CREATED_TICKETS_URL;
  }else{
    API_URL = globalConstants.API_GET_EMPLOYEES_REPORTS_ASSIGNED_TICKETS_URL;
  }
  let content = globalFunctions.stringifyFormData(this.optionsAssigned, null);
  let callApi = this.globalService.callAPI(API_URL, content);
  callApi.subscribe((data:any) => {
      if (data.data != undefined) {
          let moreRows = 'false';
          let firstList = 'false';
          // console.log(this.options.emp_id,this.localStorageKey);
          this.globalService.localGetterSetter("totalRecords", data.recordsTotal, this.SHOP_EMPLOYEE_LOG_ASSIGNED);
          if (data.more_rows == "true") {
              moreRows = "true";
          }
          this.globalService.localGetterSetter("more_rows", moreRows, this.SHOP_EMPLOYEE_LOG_ASSIGNED);
          if (data.start >= 0 && data.start <= 5) {
              firstList = "true";
          }
          this.globalService.localGetterSetter("first_list", firstList, this.SHOP_EMPLOYEE_LOG_ASSIGNED);

          this.recordsTotal = data.data.length;

          if (data.data.length == undefined) {
              this.AddRecordForAssign(data.data);
              this.dataSourceAssigned = new MatTableDataSource(this.recordAssign)
              this.dataSourceAssigned.sort = this.sortAssigned;
          } else {
              for (var i = 0; i < data.data.length; i++) {
                  this.AddRecordForAssign(data.data[i]);
              }
              this.dataSourceAssigned = new MatTableDataSource(this.recordAssign)
              this.dataSourceAssigned.sort = this.sortAssigned;
          }
          this.globalService.localGetterSetter(null, this.optionsAssigned, this.SHOP_EMPLOYEE_LOG_ASSIGNED);
      } else {
          this.globalService.localGetterSetter("totalRecords", '0', this.SHOP_EMPLOYEE_LOG_ASSIGNED);
      }
    })
  }

  AddRecordForAssign(record){
    var data = this.createRecord(record);
      this.recordAssign.push(data);
  }



  private createRecord(record) {
    let baseRecord = {
        customer_name: record.customer_name,
        vehicle_name: record.vehicle_name, 
        request_id: record.request_id,
        status: this.statuses[record.status],
        title: record.title,
        id: record.id,
        customer_id: record.customer_id,
        hours_logged: '0.0',
        hours_billed: '0.0'
    };
    if(TABLE_TYPE_ASSIGNED){
        baseRecord.hours_logged = record.hours_logged;
        baseRecord.hours_billed = record.hours_billed;
    }
    return baseRecord;
}
  /* STATUS COLOR */
  getRowStatusColor(status) {
    var statusArr = ['active-cell', 'warning-cell', 'info-cell', 'success-cell', 'info-cell', 'success-cell', 'success-completed', 'success-cell', 'success-paid', "", "", "", "work-progress-cell"];
    return statusArr[status];
  }
}

export interface employeeReportData {
  customer_name: string;
  vehicle_name: string; 
  request_id: string;
  status: string;
  title: string;
  id: string,
  customer_id: string,
  hours_logged?: string;
  hours_billed?: string;
}