<!-- Fill-recommendation Jobs Start -->
<mat-accordion class="new-request-expension show-price margin-bottom-10 margin-top-10 display-full" displayMode="flat"
  hideToggle="false">
  <mat-expansion-panel [expanded]="i==0" hideToggle="true" class="new-request-expension relative"
    *ngFor="let job of allJobs;let i = index" id="qqexpansion-{{i+1}}" style="border-radius:15px 15px;">
    <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="qqexpansionHead-{{i+1}}"
      [attr.data-state]="i==0">
      <span class="margin-right-5">{{ i+1 }}.</span><b>{{ job.name }}</b>
    </mat-expansion-panel-header>
    <ul class="service-list-items">
      <li *ngFor="let subChild of job.children;let j = index"><p>{{subChild.name}}</p>
        <!-- level 2-->
        <div *ngIf="(subChild.children && subChild.children.length==0)" class="padding-left-15">
          <ng-container *ngTemplateOutlet="fillrecomtmp;context:{'jobs':subChild,'grandparent':job}">
          </ng-container>
        </div>
        <!-- level 2-->
        <!-- level 3-->
        <div *ngIf="subChild.children && subChild.children.length" class="padding-left-15" >
          <div *ngFor="let subChild3 of subChild.children;let k=index">
            <p>{{subChild3.name}}</p>
            <ng-container *ngTemplateOutlet="fillrecomtmp;context:{'jobs':subChild3,'parent':subChild,'grandparent':job}">
            </ng-container>
          </div>
        </div>
        <!-- level 3-->
      </li>
    </ul>
  </mat-expansion-panel>
</mat-accordion>

<div class="row sticky-section">
  <div class="col-sm-12 col-lg-4 col-md-4 col-xs-12 paymnetTotal-info">

    <h4 class="text-right relative payment-info-wrap">
      <span class="payment-info">
        <strong>Total : </strong>$ <span> {{grand_total}}</span>
      </span>
      <span class="payment-info paid">
        <strong>Paid : </strong>$ <span> {{total_paid}}</span>
      </span>
      <i class="tax-note">
        Taxes and supplies are not included
      </i>
      </h4>
  </div>
  <!-- Save Button -->
<div class="btn-wrap col-lg-8 col-xs-12 col-md-8 qq-creation-btn">
  <button type="button" (click)="saveRecommendation(4)" class="font-bold mat-elevation-z0 mat-raised-button mat-success" *ngIf="isAllowed('payment','views')" title="Payments" >Payments</button>
  <button type="button" (click)="saveRecommendation(1)" class="font-bold  mat-elevation-z0 mat-raised-button mat-accent" title="Save" style="margin-right: 1px;" >Save</button>
  <button type="button" (click)="saveRecommendation(2)" class="font-bold mat-elevation-z0 mat-raised-button mat-accent" title="Save & Close" style="margin-right: 1px;" >Save & Close</button>
   <button type="button" (click)="sendToCustomer(3)" class="font-bold mat-elevation-z0 mat-raised-button mat-accent" title="Update Customer" >Update Customer</button>
</div>
<!-- Save Button -->
</div>

<ng-template #fillrecomtmp let-jobs="jobs" let-parent="parent" let-grandparent="grandparent" >
  <div class="row margin-bottom-0 qq-addJob" *ngIf="jobs.id!='158'">
    
    <div class="row margin-bottom-0">
      <div class="col-sm-12">
        <div class="form-group genericJob-scrtion">
          <label class="col-sm-3 col-lg-2 text-right control-label">Generic Job(s):</label>
          <div class="col-sm-9">
            <div class="form-inline relative quick-service-section">
              <div class="complete-wrap mat-form-field">
                  <input matInput ngui-auto-complete (valueChanged) = "getSelectedGenericJob($event,jobs)"
                  [source]="observableGenericJob" value-property-name="label" [list-formatter]="myListFormatter"
                  placeholder="Enter Generic Job" (click)="setReturnFalse()"
                  id="new-gj-{{jobs.id}}-ac" title="Generic Job" class="form-control"/>
              </div>

              <mat-form-field class="quantity-input car-input complete-wrap" floatPlaceholder="never" appearance="fill">
                <input type="text" placeholder="Quantity" aria-label="Quantity" class="form-control input-lg  btn-block"
                  matInput maxlength="5" title="Quantity"  id="new-gj-{{jobs.id}}-qty" />
              </mat-form-field>
              <button type="button" (click)="addNewGJ(jobs)" title="Add Generic Job" class="btn addGenJob btn-default">Add</button>
            </div>
          
            <div class="partRow selPart-1">
              <div class="display-full row margin-top-5" *ngFor="let GJ of selectedGenericJob[jobs.id];let i=index">
                <div class="col-md-5 col-lg-7 pname break-all">{{GJ.label}}</div>
                <div class="col-md-7 col-lg-5">
                  <div class="row">
                    <div class="col-sm-5 pprice" *ngIf="showCostPrice">${{GJ.price}}</div>
                    <div class="col-sm-3 pquantity">{{GJ.quantity}} </div>
                    <div class="col-sm-4 text-right">
                      <button type="button" title="Delete" class="btn-xs btn-link text-red" (click)="removeGJ(i,jobs.id)"><i class="fa fa-trash fa-lg"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row margin-bottom-0">
      <div class="col-sm-12">
        <div class="form-group parts-section">
          <label class="col-sm-3 col-lg-2 text-right control-label">Part(s):</label>
          <div class="col-sm-9">
            <div class="form-inline relative quick-service-section">
              <div class="complete-wrap mat-form-field">
                <input matInput ngui-auto-complete [source]="observableSource"
                  (valueChanged)="getSelectedOption($event,jobs)" [list-formatter]="autoCompleListParts"
                  placeholder="Enter Part Name" title="Part Name" (click)="setReturnFalse()" id="new-part-{{jobs.id}}-ac"
                  class="form-control" />
              </div>
              <mat-form-field [class.hide]="!showCostPrice" class="car-input complete-wrap" floatPlaceholder="never" appearance="fill" style="width: 96px">
                <input type="text" placeholder="Price" id="new-part-{{jobs.id}}-price" aria-label="price"
                  class="form-control input-lg  btn-block" title="Price" matInput (keypress)="onlyNumericKey($event)" />
              </mat-form-field>
              <mat-form-field class="car-input complete-wrap" floatPlaceholder="never" appearance="fill" style="width: 100px">
                <input type="text" id="new-part-{{jobs.id}}-qty" placeholder="Quantity" aria-label="Quantity"
                  class="form-control input-lg  btn-block" title="Quantity" (keypress)="onlyNumericKey($event)" matInput maxlength="5" />
              </mat-form-field>
                <button type="button" id="new-part-{{jobs.id}}-add-btn" title="Add Part"  (click)="addNewPart($event,jobs)" class="btn btn-default btnPartsAdd">Add</button>
                <button type="button" *ngIf="isAllowed('part','addNew')" class="btn btn-success addNewPart" id="new-part-{{jobs.id}}-add-new-btn"  (click)="addNewPartDialog(jobs,parent,grandparent)"><i class="fa fa-fw fa-plus"></i>Add New</button>
                <button type="button" id="update-part-{{jobs.id}}-btn" title="Add Part"  (click)="updatePart($event,jobs)" class="btn btn-default " style="display: none;">Update</button>
                <button type="button" id="edit-part-{{jobs.id}}-btn" title="Add Part"  (click)="updateEditPart($event,jobs)" class="btn btn-default " style="display: none;">Edit</button>
                <button *ngIf="showPartsTechButon" type="button" class="btn btn-gray partsTechbtn" id="new-part-{{jobs.id}}-partech-new-btn" (click)="partech(jobs,parent,grandparent)" ><img style="width:140px;" src="assets/img/PartsTechLogo.png" /></button>
            </div>
         
            <div class="partRow  selPart-1">
              <div class="display-full row margin-top-5" [class.text-orange]="part.partstech_cart_item==1" *ngFor="let part of selectedParts[jobs.id];let i=index">
                <div class="col-md-5 col-lg-7 pname break-all">{{part.label}}</div>
                <div class="col-md-7 col-lg-5">
                  <div class="row">
                    <div class="col-sm-4 pprice">
                      <span [tooltip]="myTooltip1"  *ngIf="showCostPrice">${{part.price}}</span>
                      
                      
                      <tooltip-content #myTooltip1 class="" >
                        {{gnTipId(part)}}
                     </tooltip-content>
                      </div>
                    <div class="col-sm-4 pquantity">{{part.quantity}} <i class="fa fa-info-circle text-red" title="{{getInfoTxt()}}"></i></div>
                    <div class="col-sm-4 text-right">
                      <button (click)="cancelEditPart(i,part,jobs.id)" type="button" title="Cancel Edit" id="cancel-{{jobs.id}}-part-{{(part.part_id ? part.part_id:part.id)}}" class="cancelqq-{{jobs.id}}-part btn-xs btn-link text-red" style="display: none;"><i class="fa fa-times fa-lg"></i></button>
                      <button (click)="editPart(i,part,jobs.id)" type="button" title="Edit" id="edit-{{jobs.id}}-part-{{(part.part_id ? part.part_id:part.id)}}" class="editqq-{{jobs.id}}-part btn-xs btn-link text-red"><i class="fa fa-pencil fa-lg"></i></button>
                      <button (click)="deleteParts(i,jobs.id)" type="button" title="Delete" class="delqq-{{jobs.id}}-part btn-xs btn-link text-red"><i class="fa fa-trash fa-lg"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row margin-bottom-0">
      <div class="col-sm-12">
        <div class="form-group">
          <label class="col-sm-3 col-lg-2 text-right control-label">Time:</label>
          <div class="col-sm-9">
            <div class="form-inline relative">
              <div class="complete-wrap mat-form-field">
                <input matInput class="form-control" maxlength="5" title="Time" (keypress)="onlyNumericKey($event)" [(ngModel)]="times[jobs.id]" (change)="timeChange(jobs)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row margin-bottom-0" [class.hide]="!showCostPrice">
      <div class="col-sm-12">
        <div class="form-group">
          <label class="col-sm-3 col-lg-2 text-right control-label">Labor:</label>
          <div class="col-sm-9">
            <div class="form-inline relative">
              <div class="complete-wrap mat-form-field">
                <input matInput class="form-control" title="Labor" (keypress)="onlyNumericKey($event)" [(ngModel)]="labors[jobs.id]" (change)="laborChange(jobs)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row margin-bottom-0">
      <div class="col-sm-12">
        <div class="form-group">
          <label class="col-sm-3 col-lg-2 text-right control-label">Notes:</label>
          <div class="col-sm-9 details-input">
            <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
              <textarea matInput placeholder="Notes" title="Notes" [(ngModel)]="notes[jobs.id]"  rows="5"></textarea>
            </mat-form-field>

          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<!-- Fill-recommendation Jobs End -->