<ng-container *ngIf="!isPopup">
  <div id="mainCompotents">
    <div class="main-content" id="dashboard-content">
      <div class="shopeditdiscount">
        <div class="panel panel-primary" id="shopeditdiscountPanel">
          <div class="panel-heading padding-left-15">
            <h3 class="panel-title">Edit Coupon Code</h3>
            <button mat-mini-fab class="custom-btn-back" title="Back" (click)="globalService.goBack();">
                <mat-icon>arrow_back</mat-icon>
            </button>
          </div>
          <div class="panel-body bgcolor-lightgrey">
            <!-- Add New discount page start -->
            <div id="editdiscount" class="row bgcolor-lightgrey form-content">
              <div class="container-fluid">
                <ng-container *ngTemplateOutlet="editCouponFormTemplate"></ng-container>
              </div>
            </div>
            <!-- Edit discount end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- Popup view for this component -->
<ng-container *ngIf="isPopup">
  <div mat-dialog-title class="relative">
    Edit Coupon Code
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
      <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="center-block" style="max-width: 800px;">
      <div id="addNewDiscount" class="row bgcolor-lightgrey form-content">
        <div class="container-fluid">
          <ng-container *ngTemplateOutlet="editCouponFormTemplate"></ng-container>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="inquiry-action relative">
    <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
    <button type="button" mat-raised-button class="font-bold" color="accent" title="Submit & Close" style="max-width: 300px;" [disabled]="submitted && rulesSubmitted" (click)="editDiscountCoupon(1)">
      Submit &amp; Close
    </button>
    <button type="button" mat-raised-button class="font-bold" color="accent" title="Submit & Add Another" style="max-width: 300px;" [disabled]="submitted && rulesSubmitted" (click)="editDiscountCoupon(2)">
      Submit &amp; Stay
    </button>
  </mat-dialog-actions>
</ng-container>

<ng-template #editCouponFormTemplate>
  <form [formGroup]="shopEditDiscount" novalidate id="editDiscountForm" class="form-inline col-sm-12" role="form" method="POST" enctype="multipart/form-data" (ngSubmit)="editDiscountCoupon(1)">
    <mat-accordion *ngIf="conditionTypes && conditionTypes.length > 0" class="new-request-expension view-request form-parts margin-bottom-10 display-full" displayMode="flat" hideToggle="false" >
      <mat-expansion-panel [expanded]="rulesAccordion" hideToggle="true" id="expansion-rules" >
        <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="expansionHead-rules" (click)="expandAccordion(rulesAccordion);" >
          Rules
        </mat-expansion-panel-header>
        <div class="row">
          <div class="form-group col-sm-12 col-md-12">
            <app-discount-rules #editDiscountRules [rule]="rule" [condition-types]="conditionTypes" [submitted]="rulesSubmitted" (onValid)="areRulesValid($event)" (onRuleRemoved)="updateSubmitted($event)"></app-discount-rules>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion *ngIf="conditionTypes && conditionTypes.length > 0" class="new-request-expension view-request form-parts margin-bottom-10 display-full" displayMode="flat" hideToggle="false" >
      <mat-expansion-panel [expanded]="codeDetailsAccordion" hideToggle="true" id="expansion-rules" >
        <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="expansionHead-rules" (click)="expandAccordion(codeDetailsAccordion);" >
          Coupon Code Details
        </mat-expansion-panel-header>
        <div class="col-xs-12 form-block">
          <div class="row">
            <div class="form-group details-input col-md-6 col-sm-12">
              <label for="discount_name">Name<span class="asterisk">*</span></label>
              <div class="relative">
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Coupon Name" title="Name" formControlName="discount_name"
                    value="" class=" input-lg custom-form-input" (keydown)="checkIfValid()" required />
                  <mat-error *ngIf="shopEditDiscount.controls.discount_name.hasError('required')">
                    This field cannot be empty
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="form-group col-md-6 col-sm-12">
              <label for="discount_code">Code</label>
              <div class="relative">
                <label for="discount_code">{{ shopEditDiscount.controls.discount_code.value }}</label>
                <mat-form-field class="hide btn-block car-input" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Coupon Code" title="Name" formControlName="discount_code"
                    value="" class=" input-lg custom-form-input" (keydown)="checkIfValid()" />
                  <mat-error *ngIf="shopEditDiscount.controls.discount_code.hasError('required')">
                    This field cannot be empty
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group details-input col-sm-12 col-md-6">
              <label for="start_date">Start Date</label>
              <mat-form-field class="car-input btn-block car-datePicker" floatPlaceholder="never" appearance="fill">
                <input matInput class="form-control input-lg custom-form-input" formControlName="startDateObj" placeholder="Choose a date"
                (dateChange)="handleStartDateChange();checkIfValid();" [matDatepicker]="picker1" (focus)="picker1.open()" maxlength="25"
                  onkeydown="return false;">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <mat-error *ngIf="shopEditDiscount.controls.startDateObj.hasError('required')">
                  This field cannot be empty
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group details-input col-sm-12 col-md-6">
              <label for="end_date">End Date</label>
              <mat-form-field class="car-input btn-block car-datePicker" floatPlaceholder="never" appearance="fill">
                <input matInput class="form-control input-lg custom-form-input" formControlName="endDateObj" placeholder="Choose a date"
                (dateChange)="checkIfValid();" [min]="minEndDate" [matDatepicker]="picker2" (focus)="picker2.open()" maxlength="25"
                  onkeydown="return false;">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <mat-error *ngIf="shopEditDiscount.controls.endDateObj.hasError('required')">
                  This field cannot be empty
                </mat-error>
              </mat-form-field>
              <mat-checkbox class="no-end-date" (change)="checkIfValid()" formControlName="no_end_date" value="1">No End Date</mat-checkbox>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion *ngIf="conditionTypes && conditionTypes.length > 0" class="new-request-expension view-request form-parts margin-bottom-10 display-full" displayMode="flat" hideToggle="false" >
      <mat-expansion-panel [expanded]="actionAccordion" hideToggle="true" id="expansion-rules" >
        <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="expansionHead-rules" (click)="expandAccordion(actionAccordion);" >
          Action
        </mat-expansion-panel-header>
        <div class="col-xs-12 form-block action-form-block">
          <div class="row">
            <div class="form-group col-md-6 col-sm-6">
              <label for="type">Type</label>
              <div class="relative">
                <label for="type">{{ (shopEditDiscount.controls.type.value == 1 ? 'Fixed Amount' : 'Percentage') }}</label>
              </div>
              <mat-select class="hide form-control input-lg custom-form-input" formControlName="type" placeholder="Type" (change)="toggleTypeValidation();checkIfValid();">
                <mat-option value="1">Fixed Amount</mat-option>
                <mat-option value="2">Percentage</mat-option>
              </mat-select>
            </div>
            <div class="form-group details-input col-sm-6">
              <label for="type">Value<span class="asterisk">*</span></label>
              <div class="relative">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Value" title="Value" formControlName="type_value" value=""
                    class=" input-lg custom-form-input" maxlength="8"
                    (keydown)="disableKey($event);checkIfValid()" (keypress)="onlyDecimalNumberKey($event)"
                    required />
                  <mat-error *ngIf="shopEditDiscount.controls.type_value.hasError('required') ">
                    This field cannot be empty
                  </mat-error>
                  <mat-error *ngIf="shopEditDiscount.controls.type_value.invalid  && shopEditDiscount.value.type_value!='' && isNotNumber(shopEditDiscount.value.type_value)">
                    Type value is required (must be a number with 2 decimal places).
                  </mat-error>
                  <mat-error *ngIf="shopEditDiscount.controls.type_value.invalid  && shopEditDiscount.value.type_value!='' && shopEditDiscount.value.type_value < 1">
                    Value should be greater than 0.
                  </mat-error>
                  <mat-error *ngIf="shopEditDiscount.controls.type_value.invalid  && shopEditDiscount.value.type_value!='' && shopEditDiscount.value.type_value > 100 && discount_type == 2">
                    Percentage Value should not exceed 100.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="relative" [class.hide]="shopEditDiscount.controls['type'].value == 1">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Maximum Redemption Limit" title="Maximum Redemption Limit" formControlName="max_redemption_limit" value=""
                    class="input-lg custom-form-input" maxlength="8"
                    (keydown)="disableKey($event);checkIfValid()" (keypress)="onlyDecimalNumberKey($event)"
                    required />
                  <mat-error *ngIf="shopEditDiscount.controls.max_redemption_limit.hasError('required') ">
                    This field cannot be empty
                  </mat-error>
                  <mat-error *ngIf="shopEditDiscount.controls.max_redemption_limit.invalid  && shopEditDiscount.value.max_redemption_limit!='' && isNotNumber(shopEditDiscount.value.max_redemption_limit)">
                    Maximum redemption limit is required (must be a number with 2 decimal places).
                  </mat-error>
                  <mat-error *ngIf="shopEditDiscount.controls.max_redemption_limit.invalid  && shopEditDiscount.value.max_redemption_limit!='' && shopEditDiscount.value.max_redemption_limit < 1">
                    Value should be greater than 0.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6 col-sm-6">
              <label for="multiple_usage">Usage Type</label>
              <div class="relative">
                <label for="multiple_usage">{{ (shopEditDiscount.controls.multiple_usage.value == 1 ? 'One Time' : 'Multiple Times') }}</label>
              </div>
              <mat-select class="hide form-control input-lg custom-form-input" formControlName="multiple_usage" placeholder="Type" (change)="toggleUsageValidation();checkIfValid();">
                <mat-option value="1">One Time</mat-option>
                <mat-option value="2">Multiple Times</mat-option>
              </mat-select>
            </div>
          </div>
          <div class="row" [class.hide]="shopEditDiscount.controls['multiple_usage'].value != 2">
            <div class="form-group col-md-6 col-sm-12 center details-input">
              <label for="usage_limit">Total no. of usages</label>
              <div class="relative">
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Total no. of usages" title="Total no. of usages"
                    formControlName="usage_limit" class=" input-lg custom-form-input"
                    (keydown)="disableKey($event);checkIfValid();" (keypress)="onlyNumberKey($event);" />
                  <mat-error *ngIf="shopEditDiscount.controls.usage_limit.hasError('required')">
                    This field cannot be empty
                  </mat-error>
                  <mat-error *ngIf="shopEditDiscount.controls.usage_limit.invalid  && shopEditDiscount.value.usage_limit!='' && shopEditDiscount.value.usage_limit < 1">
                    Value should be greater than 0.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="form-group col-md-6 col-sm-12 details-input">
              <label for="usage_per_customer_limit">Total no. of usages per person</label>
              <div class="relative">
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Total no. of usages per person" title="Total no. of usages per person" formControlName="usage_per_customer_limit"
                    class=" input-lg custom-form-input" (keydown)="disableKey($event);checkIfValid();"
                    (keypress)="onlyNumberKey($event);" />
                  <mat-error *ngIf="shopEditDiscount.controls.usage_per_customer_limit.hasError('required')">
                    This field cannot be empty
                  </mat-error>
                  <mat-error *ngIf="shopEditDiscount.controls.usage_per_customer_limit.invalid  && shopEditDiscount.value.usage_per_customer_limit!='' && shopEditDiscount.value.usage_per_customer_limit < 1">
                    Value should be greater than 0.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6 col-sm-6">
              <label for="discountStatus">Status</label>
              <mat-select class="form-control input-lg custom-form-input"
                [(value)]="discountStatus" placeholder="Status">
                <mat-option *ngFor="let opt of statusList" [value]="opt.id">{{opt.text}}</mat-option>
              </mat-select>
            </div>
            <div class="form-group col-md-6 col-sm-12">
              <label for="is_combinational">Can be used with other coupon(s)</label>
              <mat-select class="form-control input-lg custom-form-input" placeholder="Can be used with other coupon(s)" formControlName="is_combinational">
                <mat-option [value]="'0'">No</mat-option>
                <mat-option [value]="'1'">Yes</mat-option>
              </mat-select>
            </div>
          </div>
          <div class="row" *ngIf="customerData && customerData.length > 0">
            <div class="form-group details-input col-md-6 col-sm-12 uc-ngx-select">
              <label for="customers">Customer(s)</label>
              <ngx-select class="display-full mb-50 select-customer-dropdown" [items]="customerData" formControlName="customers" placeholder="Select Customer(s)" optionValueField="id" optionTextField="label" [multiple]="true" [autoClearSearch]="true">
                <!-- <ng-template ngx-select-option ngx-select-option-selected let-option>
                  ({{option.email}})
                </ng-template> -->
              </ngx-select>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="row" *ngIf="!isPopup">
      <div class="col-xs-12 form-block submit-block">
        <button type="submit" mat-raised-button
          class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent"
          title="Submit & Close" style="max-width: 300px;" [disabled]="submitted && rulesSubmitted">
          SUBMIT &amp; CLOSE
        </button>
        <button type="button" mat-raised-button
          class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent"
          title="Submit & Add Another" style="max-width: 300px;" [disabled]="submitted && rulesSubmitted"
          (click)="editDiscountCoupon(2)">
          SUBMIT &amp; STAY
        </button>
      </div>
    </div>
  </form>
</ng-template>