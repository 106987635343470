<div class="panel panel-primary" id="dashboardQuickLinks">
    <div class="panel-heading padding-left-30">
        <h3 class="panel-title">
            EDIT CAR
        </h3>
        <button mat-mini-fab class="custom-btn-back" title="Back" (click)="globalService.goBack();">
            <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
    <div class="panel-body bgcolor-lightgrey" >
        <!-- Login page start -->
        <div  class="row bgcolor-lightgrey form-content">
            <div class="container-fluid">
                <form [formGroup]="car" (ngSubmit)="editCar()" class="form-inline col-xs-12 padding-bottom-15" method="POST" enctype="multipart/form-data" autocomplete="off">
                    <div class="col-xs-12 form-block" *ngIf="showSubscriptionMessage">
                        <div class="btn-lg alert-danger disabled">You are exceeding the maximum amount of vehicles allowed for your account.  Please upgrade your subcription to add more vehicles. <br><a class="btn btn-success" href="javascript:void(0);" role="button">Subscribe Now</a></div>
                    </div>
                    <div class="col-xs-12 form-block">

                        <div class="row">
                            <div class="form-group col-sm-12 col-md-6">
                                <label for="make">Make*</label>
                                <div class='relative uc-ngx-select' *ngIf="!EnterNewMake">
                                    <ngx-select class="display-full margin-bottom-20" 
                                        formControlname="make"
                                        [(ngModel)]="selectedMake" 
                                        [ngModelOptions]="{standalone: true}" 
                                        [items]="carMakeList" 
                                        (select)="getSelectedOptionMakes($event)"
                                        placeholder="Select Make">
                                    </ngx-select>
                                    <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                                        <mat-error *ngIf="car.controls.sel_make.hasError('required')">
                                            This field cannot be empty
                                        </mat-error>
                                    </div>
                                    <a href="javascript:void(0);" color="accent" class="others-link make-link" (click)="enterNewMake()" *ngIf="EnterNewMakeButton">Another Make</a>
                                </div>
                                <div class='relative' *ngIf="EnterNewMake">
                                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" hideRequiredMarker="true" *ngIf="EnterNewMake">
                                        <input matInput placeholder="Make" title="Make" formControlName="make"  class="form-control input-lg custom-form-input" [(ngModel)]="selectedMake" />
                                        <mat-error *ngIf="car.controls.make.hasError('required') && EnterNewMake">
                                            This field cannot be empty
                                        </mat-error>
                                    </mat-form-field>
                                    <a href="javascript:void(0);" class="others-link make-link" (click)="useExitingMake()" *ngIf="UseExistingMakeButton">Existing Make</a>
                                </div>
                                
                                
                            </div>

                            <div class="form-group col-sm-12 col-md-6" *ngIf="years">
                                <label for="year">Year*</label>
                                <div class='relative uc-ngx-select' *ngIf="!EnterNewYear">
                                    <ngx-select class="display-full margin-bottom-20" 
                                        formControlname="year"  
                                        [(ngModel)]="selectedYear" 
                                        [ngModelOptions]="{standalone: true}" 
                                        [items]="years" 
                                        (select)="getSelectedOptionYears($event)"
                                        [disabled]="disabledYear"
                                        placeholder="Select Year">
                                    </ngx-select>
                                    <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                                        <mat-error *ngIf="car.controls.sel_year.hasError('required') && !EnterNewYear">
                                            This field cannot be empty
                                        </mat-error>
                                    </div>
                                    <a href="javascript:void(0);" class="others-link year-link" (click)="enterNewYear()" *ngIf="EnterNewYearButton">Another Year</a>
                                </div>
                                <div class='relative' *ngIf="EnterNewYear">
                                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" *ngIf="EnterNewYear">
                                        <input matInput placeholder="Year" title="Year" formControlName="year" class="form-control input-lg custom-form-input" maxlength="4" requried (keyup)="checkVinValidity();autoFillProductionDate()" [(ngModel)]="selectedYear"/>
                                        <mat-error *ngIf="car.controls.year.hasError('required')">
                                            This field cannot be empty
                                        </mat-error>
                                    </mat-form-field>
                                    <a href="javascript:void(0);" class="others-link year-link" (click)="useExitingYear()" *ngIf="UseExistingYearButton">Existing Year</a>
                                </div>
                                    
                                    

                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-sm-12 col-md-6" *ngIf="carModels">
                                <label for="model">Model*</label>
                                <div class='relative uc-ngx-select' *ngIf="!EnterNewModel">
                                    <ngx-select class="display-full margin-bottom-20" 
                                        formControlname="model"  
                                        [(ngModel)]="selectedModel" 
                                        [ngModelOptions]="{standalone: true}" 
                                        [items]="carModels" 
                                        (select)="getSelectedOptionModels($event)"
                                        [disabled]="disabledModel"
                                        placeholder="Select Model">
                                    </ngx-select>
                                    <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                                        <mat-error *ngIf="car.controls.sel_model.hasError('required') && !EnterNewModel">
                                            This field cannot be empty
                                        </mat-error>
                                    </div>
                                    <a href="javascript:void(0);" class="others-link model-link" (click)="enterNewModel()" *ngIf="EnterNewModelButton">Another Model</a>
                                </div>
                                <div class='relative'>
                                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" *ngIf="EnterNewModel">
                                        <input matInput placeholder="Model*" title="Model" formControlName="model"  class="form-control input-lg custom-form-input" [(ngModel)]="selectedModel" />
                                        <mat-error *ngIf="car.controls.model.hasError('required')">
                                            This field cannot be empty
                                        </mat-error>
                                    </mat-form-field>
                                    <a href="javascript:void(0);" class="others-link model-link" (click)="useExitingModel()" *ngIf="UseExistingModelButton">Existing Model</a>
                                </div>

                            </div>
                            <div class="form-group col-sm-12 col-md-6"  *ngIf="vehicleDetails">
                                <label for="subModel">Engine Size</label>
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                                    <input  matInput placeholder="Engine Size" title="Engine Size" formControlName="sub_model"  class="form-control input-lg custom-form-input" maxlength="255" [(ngModel)] ="engineSize"/>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-sm-12 col-md-6" *ngIf="vehicleDetails">
                                <label for="vin">Vin</label>
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                                    <input matInput placeholder="Vin" title="Vin" formControlName="vin"  class="form-control input-lg custom-form-input text-uppercase" maxlength="17" [(ngModel)] ="vehicleDetails.vin" (keyup)="checkIfValid();checkVinValidity()"/>
                                    <mat-error *ngIf="car.controls.vin.hasError('patern') ">
                                        Invalid Vin Number
                                    </mat-error>

                                    <mat-error *ngIf="car.controls.vin.hasError('YearError')">
                                        Vin Issued After 1981 should have maximum 17 characters
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-sm-12 col-md-6" *ngIf="vehicleDetails">
                                <label for="">Production Date (<span>First select year, then month.</span>)
                                    <span class="question-mark" matTooltipPosition="above" matTooltipClass="red-tooltip" matTooltipHideDelay="3000"
                                          matTooltip="Production dates are usually referred to by the month and year the vehicle was built. To learn more about production dates and where to find them. Click Here">?</span>
                                </label>
                                <div class="vlio-datepicker relative">
                                    <dp-date-picker mode="month" (open)="goTo($event,dayPicker)" (onChange)="goTo($event,dayPicker)" theme="dp-material dp-main" [config]="datePickerConfig" class="btn-block" placeholder="Production Date" formControlName="production_date" #dayPicker></dp-date-picker>
                                    <button type="button" mat-icon-button (click)="dayPicker.api.open();">
                                            <mat-icon>date_range</mat-icon>
                                    </button>
                                </div>
                                <mat-error *ngIf="car.controls.production_date.hasError('required')">
                                    Invalid Production Date
                                </mat-error>
                            </div>
                            <!--                <div *ngIf="!car.controls.production_date.valid && !car.controls.production_date.value==''">
                                                <p>Invalid Production Date</p>
                                            </div>-->
                        </div>

            <div class="row">
                <div class="form-group col-sm-12 col-md-6" *ngIf="vehicleDetails">
                    <label for="distance">Mileage</label>
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                        <input matInput placeholder="Mileage" title="Mileage" formControlName="distance_covered"  class="form-control input-lg custom-form-input" maxlength="25" [(ngModel)] ="vehicleDetails.distance_covered" (keypress)="onlyNumericKey($event)" />
                    </mat-form-field>
                </div>
                <div class="form-group col-sm-12 col-md-3" *ngIf="vehicleDetails">
                    <label for="licensePlate">License Plate*</label>
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                        <input matInput placeholder="License Plate" title="License Plate" formControlName="license_plate" class="form-control input-lg custom-form-input" maxlength="100" [(ngModel)] ="vehicleDetails.license_plate" style="text-transform:uppercase;"/>
                        <mat-error *ngIf="car.controls.license_plate.hasError('required')">
                            License plate can't be empty
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group col-sm-12 col-md-3 uc-ngx-select" *ngIf="vehicleDetails">
                    <label for="">State*</label>
                    <ngx-select class="display-full margin-bottom-20" 
                        formControlname="State"   
                        [(ngModel)]="carState" 
                        [ngModelOptions]="{standalone: true}" 
                        [items]="states"
                        (select)="getSelectedOptionState($event)"
                        placeholder="Select State">
                    </ngx-select>
                    <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                        <mat-error *ngIf="car.controls.State.hasError('required')">
                            This field cannot be empty
                        </mat-error>
                    </div>
                    
                </div>
            </div>

        <div class="row">
            <div class="form-group col-sm-12 col-md-6" *ngIf="vehicleDetails">
                <label for="color">Color*</label>
                <div class='relative uc-ngx-select' *ngIf="!addNewColor">
                    <ngx-select class="display-full margin-bottom-20" 
                        formControlname="color" 
                        [(ngModel)]="vehicleColor" 
                        [ngModelOptions]="{standalone: true}" 
                        [items]="carColors" 
                        (select)="getSelectedColor($event)"
                        placeholder="Choose color">
                    </ngx-select>
                    <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper" *ngIf="!addNewColor">
                        <mat-error *ngIf="car.controls.color.hasError('required') && !addNewColor">
                            This field cannot be empty
                        </mat-error>
                    </div>
                    <a href="javascript:void(0);" class="others-link" *ngIf="!addNewColor" (click)="enableAddNewColor()">Other Color</a>
                </div>
                <div class='relative' *ngIf="addNewColor">
                    <mat-form-field class="btn-block car-input mat-form-field-type-mat-select" floatPlaceholder="never" *ngIf="addNewColor">
                        <input matInput type="text" placeholder="choose color" class="form-control input-lg custom-form-input" formControlName="color" [value]="vehicleDetails.color">
                        <mat-error *ngIf="car.controls.color.hasError('required') && addNewColor">
                            This field cannot be empty
                        </mat-error>
                    </mat-form-field>
                    <a href="javascript:void(0);" class="others-link make-link" *ngIf="addNewColor" (click)="disableAddNewColor()">Use Existing Color</a>
                    
                </div>
                
                
                    
            </div>
            <div class="form-group col-sm-12 col-md-6" *ngIf="vehicleDetails">
                <label for="unitNumber">Unit Number</label>
                <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                    <input  matInput placeholder="Unit Number" title="Unit Number" formControlName="unit_number" class="form-control input-lg custom-form-input" maxlength="100" [ngModel] ="vehicleDetails.unit_number"/>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-12 col-md-6" *ngIf="vehicleDetails">
                <label for="driver">Driver</label>
                <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                    <input  matInput placeholder="Driver" title="Driver" formControlName="driver" class="form-control input-lg custom-form-input" maxlength="255" [ngModel] ="vehicleDetails.driver" />
                </mat-form-field>
            </div>
            <div class="form-group col-sm-12 col-md-6" *ngIf="vehicleDetails">
                <label for="driverPhoneNumber">Driver Phone Number</label>
                <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                    <input  matInput placeholder="Driver Phone Number" title="Driver Phone Number" formControlName="driver_phone_number" class="form-control input-lg custom-form-input" [ngModel] ="vehicleDetails.driver_phone_number" [textMask]="{mask: phoneMask}" (keyup)="checkIfValid();checkHomePhoneValidity()" />
                            <mat-error *ngIf="car.controls.driver_phone_number.hasError('phoneError')">
                        Invalid driver phone number
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-12 col-md-6" *ngIf="vehicleDetails">
                <label for="registrationExpirationDate">Registration Expiration Date</label>
                <mat-form-field class="car-input btn-block car-datePicker"  floatPlaceholder="never">
                    <input matInput class="form-control input-lg custom-form-input" formControlName="registration_expiration_date_frm" placeholder="Choose a date" [matDatepicker]="picker2" onkeydown="return false;" />
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2 [touchUi]="setTouchViewValue()"></mat-datepicker>
                    <input type="hidden" formControlName="registration_expiration_date" />
                </mat-form-field>
            </div>
            <div class="form-group col-sm-12 col-md-6"  *ngIf="vehicleDetails">
                <label for="insuranceExpirationDate">Insurance Expiration Date</label>
                <!--<div class="input-group date col-sm-12" id="insuranceExpirationDateContainer">-->
                <mat-form-field class="car-input btn-block car-datePicker"  floatPlaceholder="never">
                    <input matInput class="form-control input-lg custom-form-input" formControlName="insurance_expiration_date_frm" placeholder="Choose a date" [matDatepicker]="picker1" onkeydown="return false;"/>
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1 [touchUi]="setTouchViewValue()"></mat-datepicker>
                    <input type="hidden" formControlName="insurance_expiration_date" />
                </mat-form-field>

            </div>
        </div>
        <div class="row">
                <div class="form-group col-sm-12 col-md-6">
                    <label for="inspection_expiration_date">Inspection Expiration Date</label>
                    <mat-form-field class="car-input btn-block car-datePicker" floatPlaceholder="never">
                        <input matInput placeholder="Inspection Expiration Date" title="Inspection Expiration Date" formControlName="inspection_expiration_date_frm" [matDatepicker]="picker3" (focus)="picker3.open()" maxlength="25" class="form-control input-lg custom-form-input text-uppercase" (keyup)="checkIfValid()"  />
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3 [touchUi]="setTouchViewValue()"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="form-group col-sm-12 col-md-6">
                    <label for="transmission">Transmission</label>
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                        <input matInput placeholder="Transmission" title="Transmission" formControlName="transmission" class="form-control input-lg custom-form-input" (keyup)="checkIfValid()"  />
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-12 col-md-6">
                    <label for="brake">Brake</label>
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                        <input matInput placeholder="Brake" title="Brake" formControlName="brake" class="form-control input-lg custom-form-input" (keyup)="checkIfValid()"  />
                    </mat-form-field>
                </div>
                <div class="form-group col-sm-12 col-md-6">
                    <label for="driveline">Driveline</label>
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                        <input matInput placeholder="Driveline" title="Driveline" formControlName="driveline" class="form-control input-lg custom-form-input" (keyup)="checkIfValid()"  />
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-12 col-md-6">
                    <label for="gvwr">GVWR</label>
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                        <input matInput placeholder="GVWR" title="GVWR" formControlName="gvwr" class="form-control input-lg custom-form-input" (keyup)="checkIfValid()"  />
                    </mat-form-field>
                </div>
                <div class="form-group col-sm-12 col-md-6">
                    <label for="wheel_base">Wheel Base</label>
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                        <input matInput placeholder="Wheel Base" title="Wheel Base" formControlName="wheel_base" class="form-control input-lg custom-form-input" (keyup)="checkIfValid()"  />
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-12 col-md-6">
                    <label for="tire_size">Tire Size</label>
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                        <input matInput placeholder="Tire Size" title="Tire Size" formControlName="tire_size" class="form-control input-lg custom-form-input " (keyup)="checkIfValid()"  />
                    </mat-form-field>
                </div>
                <div class="form-group col-sm-12 col-md-6">
                    <label for="wheel_lock_location">Wheel Lock Location</label>
                    <div class='relative uc-ngx-select' *ngIf="antWheellocklocation">
                        <ngx-select class="display-full margin-bottom-20" formControlName="wheel_lock_location" [items]="wheelLock"
                            (select)="getLockLocation($event)" placeholder="Select Lock Location">
                        </ngx-select>
                        <a href="javascript:void(0);" color="accent" class="others-link" (click)="antWheellocklocation=(!antWheellocklocation)">Other Location</a>
                    </div>
                    <div class='relative' *ngIf="!antWheellocklocation">
                        <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                            <input matInput placeholder="Wheel Lock Location" title="Wheel Lock Location" formControlName="wheel_lock_location" class="form-control input-lg custom-form-input"
                                (keyup)="checkIfValid()" />
                        </mat-form-field>
                        <a href="javascript:void(0);" color="accent" class="others-link" (click)="antWheellocklocation=(!antWheellocklocation)">Other Location</a>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="vehicleDetails">
                    <div class="form-group col-sm-12 col-md-6">
                        <mat-checkbox class="btn-block" labelPosition="after" (change)="californiaEmmissions($event)" formControlName="california_emmissions" >California Emissions</mat-checkbox>
                    </div>
            </div>
            <div class="row" *ngIf="isCustFieldAllowed()">
                <div class="col-sm-12"> 
                    <h2 class="fontcolor-blue sub-heading more-fields-heading">                        
                         More Fields                        
                         <button type="button" mat-raised-button class="car-btn font-bold fontcolor-white" color="accent" (click)="fieldList()" title="Custom Fields">Custom Fields</button></h2>
                </div>
            </div>
            <div class="row" *ngIf="isCustFieldAllowed() && customFieldList.length">
                <div class="form-group col-sm-12 col-md-6" *ngFor="let field of customFieldList;let i=index">
                    <label for="tire_size" *ngIf="field.type!='checkbox'">{{field.label}}<span *ngIf="field.is_mandatory==1">*</span></label>
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" *ngIf="field.type=='textbox'">
                        <input matInput placeholder="{{field.label}}" title="{{field.label}}" [(ngModel)]="customFieldList[i].value" [ngModelOptions]="{standalone: true}" class="form-control input-lg custom-form-input " (keyup)="checkIfValid()"  />
                    </mat-form-field>
                    <div class='relative' *ngIf="field.type=='radio'">
                        <mat-radio-group class="example-radio-group" [(ngModel)]="customFieldList[i].value"
                            [ngModelOptions]="{standalone: true}">
                            <mat-radio-button class="example-radio-button  margin-right-10" *ngFor="let opt of field.field_options" [value]="opt.id">
                                {{opt.value}} </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class='relative' *ngIf="field.type=='checkbox'">
                        <mat-checkbox class="btn-block margin-top-25" [(ngModel)]="customFieldList[i].value"
                            [ngModelOptions]="{standalone: true}" labelPosition="after">
                            {{field.label}}
                        </mat-checkbox>
                    </div>
                    <div class='relative uc-ngx-select' *ngIf="field.type=='dropdown'">
                        <ngx-select class="display-full margin-bottom-20" [items]="field.field_options"
                            [(ngModel)]="customFieldList[i].value" [ngModelOptions]="{standalone: true}" optionTextField="value"
                            optionValueField="id" (select)="chooseCustmField($event,i)" placeholder="Select {{field.label}}">
                        </ngx-select>
                    </div>
                </div>
            </div>
        <div class="row margin-top-25">
            <div class="form-group col-sm-12 col-md-6">
                <label for="vehicleRegPic">Vehicle Registration Photo</label>
                <div class="upload-image" id="upload-img">
                    <mat-toolbar class="mat-elevation-z2 margin-bottom-5 upload-btn relative">
                        <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                        Drag File Here
                        <input type="file" id="vehicle_registered_pic" (change)="changeListner($event,'vehicle_registered_pic-ul')" formControlname="vehicle_registered_pic"/>
                        <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                    </mat-toolbar>
                    <div class="margin-bottom-5">
                        <p class="help-block" style="margin:0px;">Formats supported : {{FORMAT_SUPPORTED_FOR_IMAGE}}</p>
                        <p class="help-block" style="margin:0px;">Maximum size : {{MAX_ALLOWED_IMAGE_DISPLAYED_TEXT}}</p>
                    </div>
                    <ul id="vehicle_registered_pic-ul" class="list-inline margin-bottom-0 margin-top-5">
                        <li class="relative view-images" *ngFor="let item of vehicleRegisteredPic;let i=index">
                            <a href="javascript:void(0);" class="thumbnail" (click)="imageToolDialog($event,i,3,vehicleRegisteredPic);$event.stopPropagation();">
                                <img class="show-image" *ngIf="item.vehicleRegistered_pic!=''" src="{{item.vehicleRegistered_pic}}">
                                <i class="fa fa-trash remove-img" (click)="imgDelete(item.id,'vehicle_registered_pic',i);$event.stopPropagation();"></i>
                                <img class="show-image" *ngIf="item.vehicleRegistered_pic==''" src="{{registeredPicture}}{{item.vehicle_registered_pic}}">
                                <div class="image-loader" *ngIf="item.loader">
                                    <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- <input type="file" id="vehicleRegPic" name="vehicle_registered_pic" placeholder="Vehicle Registered Pic"  (change)="getFile($event,'vehicle_registered_pic')">
                <p class="help-block" style="margin:0px;">Formats supported : png, jpeg, jpg</p>
                <p class="help-block" style="margin:0px;">Maximum size : 10MB</p> -->
            </div>
            <div class="form-group col-sm-12 col-md-6">
                <label for="vehicleInsPic">Vehicle Insurance Card Photo</label>
                <div class="upload-image" id="upload-img">
                    <mat-toolbar class="mat-elevation-z2 margin-bottom-5 upload-btn relative">
                        <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                        Drag File Here
                        <input type="file" id="vehicle_insured_pic" (change)="changeListner($event,'vehicle_insured_pic-ul')" formControlname="vehicle_insured_pic"/>
                        <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                    </mat-toolbar>
                    <div class="margin-bottom-5">
                        <p class="help-block" style="margin:0px;">Formats supported : {{FORMAT_SUPPORTED_FOR_IMAGE}}</p>
                        <p class="help-block" style="margin:0px;">Maximum size : {{MAX_ALLOWED_IMAGE_DISPLAYED_TEXT}}</p>
                    </div>
                    <ul id="vehicle_insured_pic-ul" class="list-inline margin-bottom-0 margin-top-5">
                        <li class="relative view-images" *ngFor="let item of vehicleInsuredPic;let i=index">
                            <a href="javascript:void(0);" class="thumbnail" (click)="imageToolDialog($event,i,2,vehicleInsuredPic);$event.stopPropagation();">
                                <img class="show-image" *ngIf="item.vehicleInsured_pic!=''" src="{{item.vehicleInsured_pic}}">
                                <i class="fa fa-trash remove-img" (click)="imgDelete(item.id,'vehicle_insured_pic',i);$event.stopPropagation();"></i>
                                <img class="show-image" *ngIf="item.vehicleInsured_pic==''" src="{{insurance}}{{item.vehicle_insured_pic}}">
                                <div class="image-loader" *ngIf="item.loader">
                                    <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- <input type="file" id="vehicleInsPic" name="vehicle_insured_pic" placeholder="Vehicle Insured Pic"  (change)="getFile($event,'vehicle_insured_pic')">
                <p class="help-block" style="margin:0px;">Formats supported : png, jpeg, jpg</p>
                <p class="help-block" style="margin:0px;">Maximum size : 10MB</p> -->
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-12 col-md-6">
                <label for="vehiclePic">Vehicle Photo</label>
                <div class="upload-image" id="upload-img">
                    <mat-toolbar class="mat-elevation-z2 margin-bottom-5 upload-btn relative">
                        <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                        Drag File Here
                        <input type="file" accept="image/*" id="vehicle_pic" (change)="changeListner($event,'vehicle_pic-ul')" formControlname="vehicle_pic"/>
                        <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                    </mat-toolbar>
                    <div class="margin-bottom-5">
                        <p class="help-block" style="margin:0px;">Formats supported : {{FORMAT_SUPPORTED_FOR_IMAGE}}</p>
                        <p class="help-block" style="margin:0px;">Maximum size : {{MAX_ALLOWED_IMAGE_DISPLAYED_TEXT}}</p>
                    </div>
                    <ul id="vehicle_pic-ul" class="list-inline margin-bottom-0 margin-top-5">
                        <li class="relative view-images" *ngFor="let item of vPic;let i=index">
                            <a href="javascript:void(0);" class="thumbnail" (click)="imageToolDialog($event,i,1,vPic);$event.stopPropagation();">
                                <img class="show-image" *ngIf="item.vehiclePic!=''" src="{{item.vehiclePic}}" />
                                <i class="fa fa-trash remove-img" (click)="imgDelete(item.id,'vehicle_pic',i);$event.stopPropagation();"></i>
                                <img class="show-image" *ngIf="item.vehiclePic==''" src="{{imgPath}}{{item.vehicle_pic}}" />
                                <div class="image-loader" *ngIf="item.loader">
                                    <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- <input type="file" #fileInput formControlname="vehicle_pic" placeholder="Vehicle Pic" (change)="getFile($event,'vehicle_pic')"> -->
                <!-- <p class="help-block" style="margin:0px;">Formats supported : png, jpeg, jpg</p>
                <p class="help-block" style="margin:0px;">Maximum size : 10MB</p> -->
            </div>
            <div class="form-group col-sm-12 col-md-6">
                <label for="vehicleVideo">Vehicle Video</label>
                <div class="upload-image" id="upload-img">
                    <mat-toolbar class="mat-elevation-z2 margin-bottom-5 upload-btn relative">
                        <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                        Drag File Here
                        <input type="file" id="vehicle_video" (change)="changeListner($event,'vehicle_video-ul')" formControlname="vehicle_video"/>
                        <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                    </mat-toolbar>
                    <div class="margin-bottom-5">
                        <p class="help-block" style="margin:0px;">Formats supported : {{FORMAT_SUPPORTED_FOR_VIDEO}}</p>
                        <p class="help-block" style="margin:0px;">Maximum size : {{MAX_ALLOWED_VIDEO_DISPLAYED_TEXT}}</p>
                    </div>
                    <ul id="vehicle_video-ul" class="list-inline margin-bottom-0 margin-top-5">
                        <li class="video-upload relative" *ngFor="let video of videoList;let i=index">
                            <a class="thumbnail margin-bottom-0" href="javascript:void(0)" (click)="videoShow(video.vehicle_video)">
                                <span class="relative video-bg">
                                    <mat-icon class="position-center play-video">play_circle_filled</mat-icon>
                                    <div class="image-loader" *ngIf="video.loader">
                                        <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                                    </div>
                                </span>
                            </a>
                            <i class="fa fa-trash remove-img" (click)="imgDelete(video.id,'vehicle_video',i)"></i>
                        </li>
                    </ul>
                </div>
                <!--                <p class="help-block" style="margin:0px;">Formats supported : mp4, mov</p>
                                <p class="help-block" style="margin:0px;">Maximum size : 50MB</p>-->
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-12 col-md-6">
                <label for="vehicleVinPic">Vehicle Vin Photo</label>
                <div class="upload-image" id="upload-img">
                    <mat-toolbar class="mat-elevation-z2 margin-bottom-5 upload-btn relative">
                        <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                        Drag File Here
                        <input type="file" id="vin_photo" (change)="changeListner($event,'vin_photo-ul')" formControlname="vin_photo"/>
                        <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                    </mat-toolbar>
                    <div class="margin-bottom-5">
                        <p class="help-block" style="margin:0px;">Formats supported : {{FORMAT_SUPPORTED_FOR_IMAGE}}</p>
                        <p class="help-block" style="margin:0px;">Maximum size : {{MAX_ALLOWED_IMAGE_DISPLAYED_TEXT}}</p>
                    </div>
                    <ul id="vin_photo-ul" class="list-inline margin-bottom-0 margin-top-5">
                        <li class="relative view-images" *ngIf="vinPhoto!=''">
                            <a href="javascript:void(0);" class="thumbnail" (click)="imageToolDialog($event,0,5,vinPhoto);$event.stopPropagation();">
                                <img class="show-image" *ngIf="vinPhotoSet==''" src="{{vinPicture}}{{vinPhoto}}">
                                <i class="fa fa-trash remove-img" (click)="imgDelete(0,'vinPhoto', 0);$event.stopPropagation();"></i>
                                <img class="show-image" *ngIf="vinPhotoSet!=''" src="{{vinPhotoSet}}">
                                <div class="image-loader" *ngIf="vinPhotoSetLoader">
                                    <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="row" *ngIf="uspsVehicle!='0' && uspsVehicle!=''">
                    <div class="form-group col-sm-12 col-md-12">
                        <strong>This is a USPS Vehicle.</strong>
                    </div>
                </div>
                
            </div>
            <div class="form-group col-sm-12 col-md-6">
                <label for="vehicleRegPic">Other Labels Photo</label>
                <div class="upload-image" id="upload-img">
                    <mat-toolbar class="mat-elevation-z2 margin-bottom-5 upload-btn relative">
                        <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                        Drag File Here
                        <input type="file" id="other_labels_temp" (change)="changeListner($event,'other_labels_temp-ul')" formControlname="other_labels_temp"/>
                        <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                    </mat-toolbar>
                    <div class="margin-bottom-5">
                        <p class="help-block" style="margin:0px;">Formats supported : {{FORMAT_SUPPORTED_FOR_IMAGE}}</p>
                        <p class="help-block" style="margin:0px;">Maximum size : {{MAX_ALLOWED_IMAGE_DISPLAYED_TEXT}}</p>
                    </div>
                    <ul id="other_labels_temp-ul" class="list-inline margin-bottom-0 margin-top-5">
                        <li class="relative view-images" style="vertical-align: top;" style="vertical-align: top;" *ngFor="let item of otherLabelsTemp;let i=index">
                            <a href="javascript:void(0);" class="thumbnail">
                                <img class="show-image" *ngIf="item.label_pic!=''" src="{{VEHICLE_LABEL_PIC}}{{item.label_pic}}"><i class="fa fa-trash remove-img" (click)="imgDelete(item.id,'other_labels_temp',i)"></i>
                                <img class="show-image" *ngIf="item.other_labels_temp!=''" src="{{item.other_labels_temp}}">
                                <div class="image-loader" *ngIf="item.loader">
                                    <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                
            </div>
        </div>
    </div>
    <div class="col-xs-12 col-md-6 col-lg-3 form-block submit-block">
        <button type="submit" mat-raised-button class="car-btn btn-block text-uppercase  btn-lg font-bold fontcolor-white" color="accent" title="Submit">SUBMIT</button>
    </div>
</form>
</div>
</div>
</div>
</div>
<!-- dynamic content -->
