<!-- header -->
<div mat-dialog-title class="relative full-profile-access-title">
    Get Full Profile Access
    <button mat-mini-fab  class="close-modal mat-elevation-z0"  (click)="closeConfirmationPopUp()">
        <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
</div>
<!-- BODY -->
<mat-dialog-content class="nextPart-setting-dialog full-profile-access">
    <div class="row margin-0">
        <label class="col-xs-3 col-sm-2">Name</label>
        <div class="col-xs-9 col-sm-10">{{customerData.name}}</div>
    </div>
    <div class="row margin-0">
        <label class="col-xs-3 col-sm-2">Email</label>
        <div class="col-xs-9 col-sm-10">{{customerData.email}}</div>
    </div>
    <div class="row select-text">
        <div class="col-md-12" *ngIf="verifiedStatus == 0 || verifiedStatus == 3 || verifiedStatus == 1">Select any one option from below to get full profile access:</div>
    </div>
    <div *ngIf="verifiedStatus == 0 || verifiedStatus == 3 || verifiedStatus == 1">
        <div class="row margin-left-0 margin-right-0">
            <div class="col-md-12">
                <label class="material-radio">
                    <input type="radio" name="access_user_profile" value="phone"
                    (change)="showVerificationOption('phoneNumber')" />
                    <div><span class="label-text">Enter customer's registered mobile number</span></div>
                </label>
                
                <div *ngIf="showVerificationOptions == 1">
                    <div class="row margin-0 form-row">
                        <form  role="form" [formGroup]="mobileNumberForm" novalidate
                            (ngSubmit)="validateUser('mobileNo')" id="add-customer-form">
                            <div class="col-sm-4 form-label">Enter mobile Number:</div>
                            <div class="col-sm-6 text-field" *ngIf="verifiedStatus == 0 || verifiedStatus == 3">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <input matInput placeholder="Mobile Phone*" title="Mobile Phone"
                                        formControlName="mobile_phone" class="form-control custom-form-input" required
                                        maxlength="15" [textMask]="{mask: phoneMask}"  />
                                    <mat-error *ngIf="mobileNumberForm.controls.mobile_phone.hasError('required')">
                                        Mobile Phone is <strong>required</strong>
                                    </mat-error>
                                    <mat-error
                                        *ngIf="mobileNumberForm.controls.mobile_phone.hasError('minLength') && !mobileNumberForm.controls.mobile_phone.hasError('required')">
                                        Invalid Mobile Number
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-2" *ngIf="verifiedStatus == 0 || verifiedStatus == 3">
                                <button type="submit" mat-raised-button class="mat-raised-button" color="accent"
                                    >Validate</button>
                            </div>
                            <div class="row margin-0 verified-row">
                                <div class="col-sm-4 font-bold" *ngIf="verifiedStatus == 1">
                                    {{verifiedMobileNo}}
                                </div>
                                <div class="col-sm-8 font-bold text-success" *ngIf="verifiedStatus == 1">
                                    Verified
                                </div>
                            </div>
                        </form>
                        
                        
                    </div>
                    <div class="row margin-0 message-row"  *ngIf="verifiedStatus == 3">
                       <div class="col-sm-12 error-text">
                        <span>Phone number doesn't not match with customer registered phone number.</span><br>
                            <span>Enter phone number registered in customer profile in order to get full profile access.
                        </span>
                       </div>
                    </div>
                    <div class="row margin-0 message-row"  *ngIf="verifiedStatus == 1">
                        <div class="col-sm-12 success-text">
                            <span>Customer's registered phone number has been verified and now you can access the full profile.
                            </span>
                        </div>
                    </div>
                    <div class="row margin-0 message-row">
                       <div class="col-sm-12">
                        <span><b>Note:</b> If the phone number matches with the costumer's registered phone number, you would get the full profile access.</span>
                       </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="row margin-left-0 margin-right-0 email-row">
            <div class="col-md-12">
                <label class="material-radio">
                    <input type="radio" name="access_user_profile" value="email"
                    (change)="showVerificationOption('email')" />
                    <div><span class="label-text">Send profile access request to customer to their registered email address</span></div>
                </label>
                <div class="row margin-0" *ngIf="showVerificationOptions == 2">
                    <div class="col-sm-12 message-row ">
                        <span> Customer will receive an email at <span class="font-bold">{{customerData.email}}</span> where they have to
                            "Accept" the request in order to get you the full profile access.
                        </span>
                    </div>
                    <div class="col-sm-12">
                        <div class="margin-top-10">
                            <button mat-raised-button class="mat-raised-button margin-bottom-5 margin-top-5"
                                color="accent" (click)="validateUser('emailId')">Send Request Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row margin-0 email-message" *ngIf="verifiedStatus == 2">
        <div class="col-sm-12 email-success">
            An email has been send to {{customerData.name}} at his/her registered email address
        <b>({{customerData.email}})</b>. You will get the full profile access as soon the
        request is approved.
        </div>
        
    </div>
</mat-dialog-content>
<!-- footer -->
<mat-dialog-actions align="end">
    <div class="display-full">
        <button mat-raised-button mat-dialog-close class="font-bold" title="Close"
            (click)="closeConfirmationPopUp()">Close</button>
    </div>
</mat-dialog-actions>