import {Component, OnInit, ViewChild, Pipe, Inject, ElementRef, ViewEncapsulation,OnDestroy} from '@angular/core';
import {AbstractControl, FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
import {Http, Headers, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../../environments/globalFunctions";
import {globalConstants} from "../../../../environments/globalConstants";
import {GlobalService} from "../../../../environments/global.service";
import {lang} from "../../../../environments/lang-en";
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';
import {AppComponent} from '../../../app.component'

// import {MatPaginator, MatSort, MatDatepicker, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {DataSource, SelectionModel} from '@angular/cdk/collections';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {ScrollToService} from 'ng2-scroll-to-el';
import { MatTableDataSource } from '@angular/material/table';

import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
const SHOP_CUSTOMER_LIST='shop-customer-';
import { FullProfileAccessComponent } from '../full-profile-access/full-profile-access.component';

@Component({
  selector: 'app-listshopcustomers',
  templateUrl: './listshopcustomers.component.html',
  styleUrls: ['./listshopcustomers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListshopcustomersComponent implements OnInit,OnDestroy {
    public submitted = false;
    public currentCustomer;
    public currentCustomerName;
    public currentCustomerVehicles;
    public isCollapsed: boolean = true;
    public openShopCustomersSearch: boolean = true;
    public currentShopDetails;
    public listShops;
    public listParts = [];
    public UnapprovedPartsList = [];
    public result: any;
    public idFromUrl;
    public listCar: FormGroup;
    displayedColumns = ['name', 'businessName', 'email', 'address', 'city', 'state', 'mobile', 'Actions'];
    dataSource: MatTableDataSource<shopCustomerData> = new MatTableDataSource();

    public more_rows;
    public options = {
        user_type: "User",
        user_id: "",
        search_keyword: "",
        start: 0 ,
        limit: 10 ,
    }
    public modulesName='customer';
    public modulePermission:any={};
    public viewCustomerInfo=false;
    public profileType:any=1;
    public listingView=false;
    public record :any =[];
    public recordsTotal :any ;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    public subscriptionDataForAPI: Subscription = new Subscription();
    
    constructor(
        public app:AppComponent,
        private formBuilder: FormBuilder, 
        public titleService: Title, 
        private globalService: GlobalService, 
        private route: ActivatedRoute, 
        private router: Router, 
        public dialog: MatDialog,
        private scrollService: ScrollToService) {
      this.globalService.setMetaData("SHOPS","LIST_CUSTOMERS");
      localStorage.removeItem('licensePlateData');
      this.app.showCommonHeader=true;
      if (this.globalService.isFleet()) {
        this.displayedColumns = ['name', 'deptName', 'email', 'address', 'city', 'state', 'mobile', 'Actions'];
      }
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
        this.options.user_id = this.currentCustomer.id;
        this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
        this.reinitializeOptions();
        if(localStorage.getItem(SHOP_CUSTOMER_LIST+"search_keyword")!="") {
            this.openShopCustomersSearch = true;
        } else {
            this.openShopCustomersSearch = false;
        }
        this.listCar = this.formBuilder.group({
            customer_id: [''],
        });
    }

    newServiceRequestForCustomer(customer_id, customer_country) {
      localStorage.setItem("customer_country", customer_country);
      localStorage.setItem("VinValues", '');
      this.globalService.localGetterSetter(this.options,null,SHOP_CUSTOMER_LIST);
      this.router.navigate(['shops/new-service-request/'+customer_id]);
    }
    ngOnInit() {
        if(this.currentCustomer && typeof this.currentCustomer.profile_type!='undefined'){
            this.profileType=this.currentCustomer.profile_type;
        }
        setTimeout(()=>{
            this.modulePermission=this.globalService.isAllowed(this.app,this.modulesName);
            this.viewCustomerInfo=this.globalService.isAllowed(this.app,'view_customer_info','views');
            if(!(this.modulePermission && (this.modulePermission['enable']==1 ))){
                this.router.navigate(['shops/action/forbidden']);
            }
            this.setDataTable();
        },globalConstants.RELOAD_WAIT)
    }

        /** define the paginator in after init the page  */
   ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
   }
    search(event) {
        this.options.start = 0;
        this.setDataTable();
    }

    clearSearch() {
        this.openShopCustomersSearch=false;
        this.globalService.localGetterSetter(null,this.options,SHOP_CUSTOMER_LIST);
        this.reinitializeOptions();
        this.setDataTable();
    }

    checkFormEmpty(button) {
        if (this.options.search_keyword == "") {
            if (button) {button.color = "primary"}
            return false
        }
        else {
            if (button) {button.color = "accent"}
            return true;
        }
    }

    onResize(event) {
        event.target.innerWidth;
    }

    editCustomer(id,isWalkinCustomerVehicle) {
        if(isWalkinCustomerVehicle == '1'){
            this.globalService.handleWalkinProfileEditDelete(isWalkinCustomerVehicle);
            return false;
          }
        this.globalService.localGetterSetter(this.options,null,SHOP_CUSTOMER_LIST);
        this.router.navigate(['shops/edit-customer/', id]);
    }
    
    viewCustomer(id) {
        this.globalService.localGetterSetter(this.options,null,SHOP_CUSTOMER_LIST);
        this.router.navigate(['shops/view-customer/', id]);
    }
    
    addCar(id) {
        localStorage.setItem("shopCustomerID", id);
        localStorage.setItem("VinValues", "");
        localStorage.setItem("VinNumber", "");
        this.globalService.localGetterSetter(this.options,null,SHOP_CUSTOMER_LIST);
        this.router.navigate(['shops/add-car']);
    }

    listCars(id) {
        localStorage.setItem("listCustomerID", id);
        this.globalService.localGetterSetter(this.options,null,SHOP_CUSTOMER_LIST);
        this.router.navigate(['shops/list-cars']);
    }

    viewAllServiceRequests(search_keyword, customer_id) {
        localStorage.setItem("shop-servicesearch_keyword", search_keyword);
        localStorage.setItem("shop-servicecustomer_id", customer_id);
        localStorage.setItem("openFilter", "1");
        this.globalService.localGetterSetter(this.options,null,SHOP_CUSTOMER_LIST);
        this.router.navigate(['shops/list-service-requests']);
    }

    getLoggedInUserDetail() {
      return JSON.parse(localStorage.getItem("loggedInUser")).first_name
    }
    getCurrentRoute() {
        return this.router.url
    }

    scrolling() {
        this.scrollService.scrollTo('.car-datatable', 1000, -20);
    }

    paginateByLimit(event) {
        this.scrolling();
        this.options.limit = event.pageSize;
        this.options.start = 0;
        this.setDataTable();
    }

    reinitializeOptions() {
        this.options=this.globalService.localGetterSetter(this.options,this.options,SHOP_CUSTOMER_LIST);
        this.options.user_type= "User";
        this.options.user_id= this.currentCustomer.id;
    }

    getTotalRecords(){
        return parseInt(localStorage.getItem(SHOP_CUSTOMER_LIST+'totalRecords'));
    }

    paginate(event,type){
      this.scrolling();
      if(type=="next") {
        this.options.start = this.options.start + this.options.limit;

      } else {
        this.options.start = this.options.start - this.options.limit;

      }
      this.setDataTable();
    }

    firstList() {
        return this.globalService.firstList(SHOP_CUSTOMER_LIST,this.options);
    }

    lastList() {
        return this.globalService.lastList(SHOP_CUSTOMER_LIST);
    }

    getUpperLimit(currentLastLimit, total) {
        if (currentLastLimit > total) {
            return total
        } else {
            return currentLastLimit
        }
    }
    setDataTable(){
        this.record=[];
        let formdata = new FormData();
        formdata.append("limit", (this.options.limit).toString());
        formdata.append("search_keyword", this.options.search_keyword);
        formdata.append("start", (this.options.start).toString());
        formdata.append("user_id", this.options.user_id);
        formdata.append("user_type", "User");
        var callApi = this.globalService.formData(globalConstants.API_GET_CUSTOMERS_LIST_URL, formdata);
        callApi.subscribe((data:any) => {
            if(data && typeof data!='undefined' && data.listing_view=='2'){
                this.listingView=true
            }
            if (data.data != undefined) {
                let moreRows='false';
                let firstList='false';
                this.globalService.localGetterSetter("totalRecords",data.recordsTotal,SHOP_CUSTOMER_LIST);
                if(data.more_rows == "true") {
                    moreRows="true";
                }
                this.globalService.localGetterSetter("more_rows",moreRows,SHOP_CUSTOMER_LIST);
                if (data.start >= 0 && data.start <= 5) {
                    firstList="true";
                }
                this.globalService.localGetterSetter("first_list",firstList,SHOP_CUSTOMER_LIST);
                if (data.data.length != undefined) {
                    this.recordsTotal = data.data.length;
                    for (var i = 0; i < data.data.length; i++) {
                        this.AddRecord(data.data[i])
                    }
                    this.dataSource = new MatTableDataSource(this.record)
                    this.dataSource.sort = this.sort;
                    this.globalService.localGetterSetter("current-visible",this.recordsTotal,SHOP_CUSTOMER_LIST);
                } else {
                    this.globalService.localGetterSetter("current-visible",'1',SHOP_CUSTOMER_LIST)
                    this.recordsTotal = "1";
                    this.AddRecord(data.data)
                    this.dataSource = new MatTableDataSource(this.record)
                    this.dataSource.sort = this.sort;
                }
                this.globalService.localGetterSetter(null,this.options,SHOP_CUSTOMER_LIST);
            } else {
                this.dataSource = new MatTableDataSource(this.record);
                this.globalService.localGetterSetter("totalRecords",'0',SHOP_CUSTOMER_LIST);
            }
        },(error)=>{})
    }

    /** its user add the record via using create r */
    AddRecord(record) {
        var data = this.createRecord(record);
        this.record.push(data);
    }

    private createRecord(record) {
        if(this.viewCustomerInfo){
            return {
                name: record.first_name + " " + record.last_name,
                business_name : record.business_name,
                email: record.email,
                address: record.address1,
                city: record.city,
                state: record.state,
                country: record.country,
                mobile: record.mobile_phone,
                status: record.status,
                customer_id: record.id,
                id:record.id,
                sendParams: record.id+','+record.country_id+','+record.state,
                customer_country: record.country,
                email_activation_status: record.email_activation_status,
                mapping_status: record.mapping_status,
                is_preferred:record.is_preferred_customer,
                is_sublet: record.is_sublet,
                is_walkin_customer:record.is_walkin_customer
            };
        }else{
            return {
                name: record.first_name + " " + record.last_name,
                business_name:'-',
                email: '-',
                address: '-',
                city: '-',
                state: '-',
                country: '-',
                mobile: '-',
                status: record.status,
                customer_id: record.id,
                id:record.id,
                sendParams: record.id+','+record.country_id,
                customer_country: '-',
                email_activation_status: record.email_activation_status,
                mapping_status: record.mapping_status,
                is_preferred:record.is_preferred_customer,
                is_sublet: record.is_sublet
            };
        }

    }
    /* SEND CUSTOMER PASSWORD */
    sendCustomerPassword(id,isWalkinCustomerVehicle) {
        if(isWalkinCustomerVehicle == '1'){
            this.globalService.handleWalkinProfileEditDelete(isWalkinCustomerVehicle);
            return false;
          }
        this.globalService.confirmDialog(id, 'Are you sure you want to reset password?').subscribe((res) => {
            if (res != null) {
                var stringifiedFormData = "user_type=" + this.currentCustomer.user_type + "&user_id=" + this.currentCustomer.id + "&customer_id=" + id;
                this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_SEND_CUSTOMER_PASSWORD_URL, stringifiedFormData).subscribe((data:any) => {
                    var message = data.message;
                    if (data.result == "1") {
                        this.globalService.snackbar('success', message);
                    } else {
                        this.globalService.snackbar("error", message);
                    }
                });
            }
        });
        
    }
    /* END */
    isAllowed(action,module?:any){
        if(typeof module!='undefined'){
            return this.globalService.isAllowed(this.app,module,action);            
        }
        return this.globalService.isAllowed(this.app,this.modulesName,action);
    }
    /** add/remove customer from preferred list */
    markAsPerfrred(row) {
        this.globalService.confirmDialog("yes", "Are you sure?").subscribe((res) => {
            let r: any = res;
            if (r == "yes") {
                let data = { user_id: this.currentCustomer.id, user_type: this.currentCustomer.user_type, customer_id: row.customer_id, opr: row.is_preferred };
                this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_PREFERRED_CUSTOMERS, data).subscribe((r:any) => {
                    if (r.result == 1) {
                        this.globalService.snackbar('success', r.message);
                        this.setDataTable();
                    } else {
                        this.globalService.snackbar("error", r.message);
                    }
                }, (error) => { });
            }
        });
    }

    getCustomerAccess(customerData:any){
        let dialogRef = this.dialog.open(FullProfileAccessComponent, {
          panelClass: ['car-dialog-form'],
          width: '800px',
          data: { customerInfo:customerData }
        });
        dialogRef.afterClosed().subscribe(result => {
            if(result){
                this.setDataTable();
            }
        })
    }

    ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
    }  
    
}

export interface shopCustomerData {
    name: any;
    business_name:any;
    email: any;
    address: any;
    city: any;
    state: any;
    country: any;
    mobile: any;
    status: any;
    customer_id: any;
    id: any;
    sendParams: any;
    customer_country: any;
    email_activation_status: any;
    mapping_status: any;
    is_sublet: any;
}

export class addCarDialog {
    public customer_id;
    constructor(public dialogRef: MatDialogRef<addCarDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private router: Router, private globalService: GlobalService) {
    }
    addCar() {
        localStorage.setItem("shopCustomerID", this.customer_id);
        this.router.navigate(['shops/add-car']);
        this.dialogRef.close();
    }
}