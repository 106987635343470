import { Injectable } from '@angular/core';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import 'rxjs/add/operator/take';
import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import { Router } from '@angular/router';
import { GlobalService } from '../environments/global.service';
import { globalConstants } from '../environments/globalConstants';

@Injectable()
export class MessagingService {
  public  messaging :any = getMessaging();
  public  currentMessage = new BehaviorSubject(null);
  public  currentToken :any = null;
  constructor(
     public router: Router,
     private gs:GlobalService
     ) {
   }

  checkDevice() {
    if (/iPad|iPhone/.test(navigator.platform)) {
      return true;
    } else {
      return false;
    }
  }
  checkNotificationPermission() {
    // if (Notification.permission === 'granted') {
    //   return true;
    // } else {
    //   return false;
    // }
    return  Notification.permission
  }
  updateToken(token) {
    if (this.gs.getCurrentUser()) {
      let data = { device_token: token };
      this.gs.callAPI(globalConstants.API_NOTIFICATION_TOKEN, data).subscribe((r) => { }, (error) => { });
    }
    this.currentToken = token;
  }

  getPermission() {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        this.getFCMToken();
        this.receiveMessage();
      } else {
      }
    });
  }

  getFCMToken() {
    const messaging = getMessaging();
    getToken(this.messaging, { vapidKey: globalConstants.vapidKey }).then(
      (currentToken) => {
        if (currentToken) {
          this.updateToken(currentToken);
          this.receiveMessage();
        } else {}
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      })
  }

  receiveMessage() {
    let isIPhoneDevice = this.checkDevice();
    if (isIPhoneDevice) {
    } else {
      const messaging = getMessaging();
      onMessage(this.messaging, (payload) => {
        let url = "../assets/sounds/Notification.mp3";
        var audio =new Audio(url);
        audio.play();
        this.showNotification(payload.notification, payload.data);
        this.currentMessage.next(payload);
      });
    }
  }

  showNotification(n,data){
    let title=n.title;
    let r=this.router;
    var options = {
        body: n.body,
        icon: n.icon,
        data: data
      };
      var nobj = new Notification(title, options);
      nobj.onclick=function(event){
        let e:any=event.currentTarget;
        parent.focus();
        window.focus();
        this.close();
        if(e && typeof e.data!= 'undefined'){
          r.navigateByUrl(e.data.redirect_url);
        }
        setTimeout(nobj.close.bind(nobj), 5000);
      }
      getToken(this.messaging, {vapidKey: globalConstants.vapidKey}).then(
        (refreshToken) => {
          if (refreshToken) {
            this.updateToken(refreshToken);
          }
        })   
  }
}


// old code start for web-notification(FCM) 
// import { Injectable } from '@angular/core';
// import { AngularFireDatabase } from 'angularfire2/database';
// import { AngularFireAuth } from 'angularfire2/auth';
// import * as firebase from 'firebase';

// import 'rxjs/add/operator/take';
// import { BehaviorSubject } from 'rxjs/BehaviorSubject'
// import { Router } from '@angular/router';
// import { GlobalService } from '../environments/global.service';
// import { globalConstants } from '../environments/globalConstants';

// @Injectable()
// export class MessagingService {

//   messaging = firebase.messaging()
//   currentMessage = new BehaviorSubject(null)
//   currentToken = '';
//   constructor(private db: AngularFireDatabase, public router: Router,private gs:GlobalService) { }
//   updateToken(token) {
//     if(this.gs.getCurrentUser()){
//       let data = { device_token: token };
//       this.gs.callAPI(globalConstants.API_NOTIFICATION_TOKEN, data).subscribe((r) => { }, (error) => { });
//     }
//     this.currentToken = token;
//   }

//   getPermission() {
//     this.messaging.requestPermission()
//       .then(() =>{
//         return this.messaging.getToken()
//       })
//       .then(token => {
//         this.updateToken(token)
//       })
//       .catch((err) => {
//         console.log('Unable to get permission to notify.', err);
//       });
//   }

//   receiveMessage() {
//     this.messaging.onMessage((payload: any) => {
//       this.showNotification(payload.notification, payload.data)
//       this.currentMessage.next(payload)
//     });
//   }
//   showNotification(n,data){
//     let title=n.title;
//     let r=this.router;
//     var options = {
//         body: n.body,
//         icon: n.icon,
//         data: data
//       };
//       var nobj = new Notification(title, options);
//       nobj.onclick=function(event){
//         let e:any=event.currentTarget;
//         parent.focus();
//         window.focus();
//         this.close();
//         if(e && typeof e.data!= 'undefined'){
//           r.navigateByUrl(e.data.redirect_url);
//         }
//         setTimeout(nobj.close.bind(nobj), 5000);
//       }
//       this.messaging.onTokenRefresh(()=>{
//         this.messaging.getToken().then(function(token) {
//           this.updateToken(token);
//         }).catch(function(err) { });
//       })      
//   }

// }

// old code end for web-notification(FCM)