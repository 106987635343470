<div id="mainCompotents">
<div class="panel panel-primary" id="dashboardQuickLinks">
  <div class="panel-heading padding-left-30">
    <h3 class="panel-title">Edit Profile Details</h3>
  </div>
  <div class="panel-body bgcolor-lightgrey padding-0" style="border:none;">

    <mat-tab-group #tabNavbar class="account-tabs" indexToSelect="1" [selectedIndex]="selectedIndex" backgroundColor="primary"  (selectedIndexChange)="selectedIndexChange($event)">

      <mat-tab label="Edit Profile" data-id="ep" id='1'>
        <div class="bgcolor-lightgrey form-content">
          <div class="container-fluid">
            <form  class="form-inline col-xs-12 padding-bottom-15" role="form" [formGroup]="customerEdit" novalidate (ngSubmit)="editCustomerData(0)">
              <div class=" col-xs-12 form-block">
                <div class="fontcolor-blue sub-heading">
                    <div class="row">
                        <div class="col-sm-5 col-lg-7">
                            <h2 class="margin-0">LOGIN INFORMATION</h2>
                        </div>
                        <div class="col-sm-7 col-lg-5 text-right">
                            <a mat-raised-button class="mat-success nofication-btn" [routerLink]="['/customer/notifications']">
                              <mat-icon>notifications</mat-icon>
                              <span>Notification <span class="hidden-xs">Preferences</span></span>
                            </a>
                            <a mat-raised-button class="mat-success nofication-btn" (click)="contacts()" href="javascript:void(0);">
                              <i class="fa fa-vcard fa-lg"></i>
                              <span>Contacts</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="row">
                  <div class="form-group col-sm-12 col-md-4">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never"  hideRequiredMarker="false">
                      <input  matInput placeholder="Enter Email" formControlName="email"  class="form-control input-lg custom-form-input" required/>
                      <mat-error *ngIf="customerEdit.controls.email.hasError('pattern')">
                        Please enter a valid email address
                      </mat-error>
                      <mat-error *ngIf="customerEdit.controls.email.hasError('required')">
                        Email is <strong>required</strong>
                      </mat-error>
                    </mat-form-field>
                    <!--<input type="text" class="form-control input-lg custom-form-input" id="email" name="email" placeholder="Email*" value="nitin.teche@gmail.com" disabled="">-->
                  </div>
                </div>
              </div>
              <div class=" col-xs-12 form-block">
                <h2 class="fontcolor-blue sub-heading">PERSONAL INFORMATION</h2>
                <div class="row">
                  <div class="form-group col-sm-12 margin-bottom-5">
                    <mat-checkbox [checked]="checkedBox" [value]="" (click)="businessNameToggle()">Add Business Name</mat-checkbox>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-sm-12 col-md-4">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" hideRequiredMarker="false">
                      <input matInput placeholder="First Name" title="First Name" formControlName="first_name"  class="form-control input-lg custom-form-input" (keyup)="checkIfValid()">
                      <mat-error *ngIf="customerEdit.controls.first_name.hasError('required')">
                        First Name is <strong>required</strong>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <input  matInput placeholder="Middle Name" title="Middle Name" formControlName="middle_name"  class="form-control input-lg custom-form-input">
                    </mat-form-field>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" hideRequiredMarker="false">
                      <input  matInput placeholder="Last Name" title="Last Name" formControlName="last_name"  class="form-control input-lg custom-form-input" (keyup)="checkIfValid()">
                      <mat-error *ngIf="customerEdit.controls.last_name.hasError('required')">
                        Last Name is <strong>required</strong>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group col-sm-12 col-md-4" id="businessNameContainer" [@popOverState]="showBusinessName">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <input  matInput placeholder="Business Name" title="Business Name" formControlName="business_name"  class="form-control input-lg custom-form-input">
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class=" col-xs-12 form-block">
                <h2 class="fontcolor-blue sub-heading">CONTACT INFORMATION</h2>
                <div class="row">
                  <div class="form-group col-sm-12 col-md-6">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <input  matInput placeholder="Address 1" title="Address 1" formControlName="address_one"  class="form-control input-lg custom-form-input">
                    </mat-form-field>
                  </div>
                  <div class="form-group col-sm-12 col-md-6">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <input  matInput placeholder="Address 2" title="Address 2" formControlName="address_two"  class="form-control input-lg custom-form-input">
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                    <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
                        <ngx-select class="display-full margin-bottom-20" 
                            [items]="countries" 
                            [(ngModel)]="selectedCountry" 
                            [ngModelOptions]="{standalone: true}"
                            (select)="getSelectedOptionCountry($event)"
                            placeholder="Select Country">
                        </ngx-select>
                        
                    </div>
                    <div class="form-group col-sm-12 col-md-6">
                      <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                        <input  matInput placeholder="{{zipLabel}}*" title="{{zipLabel}}"  #zipcode (input)="zipcode.value = zipcode.value.toUpperCase()" formControlName="zipcode" maxlength="15" class="form-control input-lg custom-form-input"  (keyup)="checkIfValid()" />
                        <mat-error *ngIf="customerEdit.controls.zipcode.hasError('pattern')">
                          Please enter valid {{zipLabel.toLowerCase()}}
                        </mat-error>
                        <mat-error *ngIf="customerEdit.controls.zipcode.hasError('maxlength')">
                          Invalid {{zipLabel.toLowerCase()}}
                        </mat-error>
                      </mat-form-field>
                  </div>
                   
                </div>

                <div class="row">
                  <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
                    <ngx-select class="display-full margin-bottom-20" 
                        [items]="states" 
                        [(ngModel)]="selectedState" 
                        [ngModelOptions]="{standalone: true}" 
                        (select)="getSelectedOptionState($event)"
                        [disabled]="disableStates"
                        placeholder="Select State">
                    </ngx-select>
                    
                </div>
                    <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
                        <ngx-select class="display-full margin-bottom-20" 
                            [items]="vcities" 
                            [(ngModel)]="selectedCity" 
                            [ngModelOptions]="{standalone: true}" 
                            (select)="getSelectedOptionCity($event)"
                            [disabled]="disableCities"
                            placeholder="Select City">
                        </ngx-select>
                        
                    </div>                                
                   
                </div>
                <div class="row">
                  <div class="form-group col-sm-12 col-md-4">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <input  matInput (keyup)="phoneValidation(customerEdit,'home_phone')" placeholder="Home Phone" title="Home Phone" formControlName="home_phone"  class="form-control input-lg custom-form-input"  maxlength="15" [textMask]="{mask: phoneMask}" (keyup)="checkIfValid();checkHomePhoneValidity()"/>
                      <mat-error *ngIf="customerEdit.controls.home_phone.hasError('phoneError')">
                        Invalid home phone number
                      </mat-error>
                      <mat-error *ngIf="customerEdit.controls.home_phone.hasError('home_phone')">
                        Invalid phone number
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <input  matInput placeholder="Business Phone" title="Business Phone" formControlName="business_phone"  class="form-control input-lg custom-form-input" maxlength="15" [textMask]="{mask: phoneMask}" (keyup)="checkIfValid();checkBusinessPhoneValidity()"/>
                      <mat-error *ngIf="customerEdit.controls.business_phone.hasError('phoneError')">
                        Invalid business phone number
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <input  matInput placeholder="Mobile Phone" title="Mobile Phone" formControlName="mobile_phone"  class="form-control input-lg custom-form-input" maxlength="15" [textMask]="{mask: phoneMask}" (keyup)="checkIfValid()"/>
                      <mat-error *ngIf="customerEdit.controls.mobile_phone.hasError('required')">
                        Mobile Number is <strong>required</strong>
                      </mat-error>
                      <mat-error *ngIf="customerEdit.controls.mobile_phone.hasError('minLength') && !customerEdit.controls.mobile_phone.hasError('required')">
                        Invalid Mobile Number
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row search-employee">
                    <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
                        <ngx-select class="display-full margin-bottom-20" 
                        [items]="timezoneData" 
                        formControlName="timezone"
                        (select)="getSelectedOptionTimezone($event)"
                        placeholder="Select Timezone">
                    </ngx-select>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12 col-md-6">
                        <mat-checkbox class="btn-block" labelPosition="after" (change)="showRequireApproval($event)" formControlName="require_approval" [checked]="requireApprovalCheck">
                            Require Approval
                        </mat-checkbox>
                    </div>
                </div>
                
                <div class="row">
                    <div class="form-group col-sm-12 col-md-4" [@popOverState]="checkApprovalAmount">
                        <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                           <input type="number" matInput placeholder="Amount" title="Amount" formControlName="approval_amount"  class="form-control input-lg custom-form-input" (keydown)="disableKey($event);checkIfValid()" (keypress)="onlyDecimalNumberKey($event)">
                       </mat-form-field>
                    </div>  
                    <p class="help-block col-sm-12" style="margin-top: -15px;"><strong>Note: </strong>If the Grand Total is exceeded by this amount, shop needs approval.</p>
                </div>
                
              </div>

              <div class=" col-xs-12 form-block submit-block">
                <button type="button" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Save and stay"  style="max-width: 300px;" (click)="editCustomerData(0)" [disabled]="submitted">SAVE AND STAY</button>
                <button type="button" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Save and close" style="max-width: 300px;" (click)="editCustomerData(1)" [disabled]="submitted">SAVE AND CLOSE</button>
              </div>
            </form>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Preferred Service Center" data-id="psc">
        <div class="bgcolor-lightgrey form-content">
          <div class="container-fluid">
            <form id="editPrefServiceCenterDetailsForm" class="form-inline  col-xs-12 edit-roadside-assistance-details-form" role="form"  [formGroup]="customerServiceCenter" novalidate (ngSubmit)="update_customer_service_center_detail(0)">
              <div class=" col-xs-12 form-block">
                <h2 class="fontcolor-blue sub-heading">PERSONAL INFORMATION</h2>
                <div class="row">
                  <div class="form-group col-sm-12 col-md-4">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <input  matInput placeholder="Business Name" title="Business Name" formControlName="business_name" class="form-control input-lg custom-form-input" />
                    </mat-form-field>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <input  matInput placeholder="Contact Name" title="Contact Name" formControlName="contact_name" class="form-control input-lg custom-form-input" />
                    </mat-form-field>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <mat-form-field class="car-input btn-block" hideRequiredMarker="false" floatPlaceholder="never">
                      <input  matInput placeholder="Email" title="Email" formControlName="email" class="form-control input-lg custom-form-input"  />
                      <mat-error *ngIf="customerServiceCenter.controls.email.hasError('pattern')">
                        Please enter a valid email address
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class=" col-xs-12 form-block">
                <h2 class="fontcolor-blue sub-heading">CONTACT INFORMATION</h2>
                <div class="row">
                    <div class="form-group col-sm-12 col-md-6">
                        <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                            <input matInput placeholder="Address 1" title="Address 1" formControlName="address_one" class="form-control input-lg custom-form-input"  maxlength="255"/>
                        </mat-form-field>
                    </div>
                    
                    <div class="form-group col-sm-12 col-md-6">
                        <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                            <input matInput placeholder="Address 2" title="Address 2" formControlName="address_two" class="form-control input-lg custom-form-input" maxlength="255" />
                        </mat-form-field>
                    </div>
                    
                    <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
                        <ngx-select class="display-full margin-bottom-20" 
                            [items]="countries" 
                            [(ngModel)]="defaultCountry" 
                            [ngModelOptions]="{standalone: true}"
                            (select)="getSelectedOptionPrefCountry($event)"
                            placeholder="Select Country">
                        </ngx-select>
                       
                    </div>
                    <div class="form-group col-sm-12 col-md-6">
                      <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                        <input  matInput placeholder="{{sZipLabel}}*" title="{{sZipLabel}}"  #szipcode (input)="szipcode.value = szipcode.value.toUpperCase()"  formControlName="zipcode" class="form-control input-lg custom-form-input" maxlength="15"  />
                      </mat-form-field>
                      <mat-error *ngIf="customerServiceCenter.controls.zipcode.hasError('pattern')">
                        Please enter valid {{sZipLabel.toLowerCase()}}
                    </mat-error>
                    </div>
                    
                    <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
                        <ngx-select class="display-full margin-bottom-20" 
                            [items]="preferedStates" 
                            [(ngModel)]="preferedCustomerStateValue" 
                            [ngModelOptions]="{standalone: true}" 
                            (select)="getSelectedOptionPrefState($event,preferedCustomerStateValue)"
                            [disabled]="disablePrefStates"
                            placeholder="Select State">
                        </ngx-select>
                       
                    </div>


                    <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
                          <ngx-select class="display-full margin-bottom-20" 
                            [items]="prefCities" 
                            [(ngModel)]="preferedCustomerCityValue" 
                            [ngModelOptions]="{standalone: true}" 
                            [defaultValue]="cityBlank"
                            (select)="getSelectedOptionPrefCity($event,preferedCustomerCityValue)"
                            [disabled]="disablePrefCities"
                            placeholder="Select City">
                        </ngx-select>
                        
                    </div>
                    
                
                  <div class="form-group col-sm-12 col-md-6">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <input  matInput placeholder="Phone" title="Phone" formControlName="mobile_phone" class="form-control input-lg custom-form-input"  [textMask]="{mask: phoneMask}"  (keyup)="preferedServicesPhoneValidity()"/>
                      <mat-error *ngIf="customerServiceCenter.controls.mobile_phone.hasError('phoneError')">
                        Invalid home phone number
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group col-sm-12 col-md-6">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <input  matInput placeholder="Website URL" title="Website URL" formControlName="website_url" class="form-control input-lg custom-form-input" />
                    </mat-form-field>
                    <span style="position: absolute;bottom: 0;left: 16px;font-size: 14px;">Ex: http://www.example.com</span>
                  </div>
                </div>
              </div>
              <div class=" col-xs-12 form-block submit-block">
                <button type="button" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Save and stay"  style="max-width: 300px;" (click)="update_customer_service_center_detail(0)">SAVE AND STAY</button>
                <button type="button" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Save and close" style="max-width: 300px;" (click)="update_customer_service_center_detail(1)">SAVE AND CLOSE</button>
              </div>
            </form>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Roadside Assistance" data-id="ra">
        <div class="bgcolor-lightgrey form-content">
          <div class="container-fluid">
            <form id="editRoadsideAssistanceDetailsForm" class="form-inline  col-xs-12 edit-roadside-assistance-details-form"  [formGroup]="customerRoadsideAssistance" novalidate (ngSubmit)="update_customer_roadside_assistance_details(0)">
              <div class=" col-xs-12 form-block">
                <h2 class="fontcolor-blue sub-heading">PERSONAL INFORMATION</h2>
                <div class="row">
                  <div class="form-group col-sm-12 col-md-4">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <input  matInput placeholder="Business Name" title="Business Name" formControlName="business_name" class="form-control input-lg custom-form-input" />
                    </mat-form-field>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <input  matInput placeholder="Contact Name" title="Contact Name" formControlName="contact_name" class="form-control input-lg custom-form-input" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class=" col-xs-12 form-block">
                <h2 class="fontcolor-blue sub-heading">CONTACT INFORMATION</h2>
                <div class="row">
                  <div class="form-group col-sm-12 col-md-4">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <input  matInput placeholder="Phone" title="Phone" formControlName="mobile_phone" class="form-control input-lg custom-form-input" maxlength="15" [textMask]="{mask: phoneMask}" (keyup)="roadSideAssistancePhoneValidity()"/>
                      <mat-error *ngIf="customerRoadsideAssistance.controls.mobile_phone.hasError('phoneError')">
                        Invalid phone number
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group col-sm-12">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <textarea matInput placeholder="Notes" title="Notes" formControlName="notes" rows="5"></textarea>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class=" col-xs-12 form-block submit-block">
                <button type="button" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Save and stay"  style="max-width: 300px;" (click)="update_customer_roadside_assistance_details(0)">SAVE AND STAY</button>
                <button type="button" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Save and close" style="max-width: 300px;" (click)="update_customer_roadside_assistance_details(1)">SAVE AND CLOSE</button>
              </div>
            </form>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Insurance Company" data-id="ic">
        <div class="bgcolor-lightgrey form-content">
          <div class="container-fluid">
            <form id="editInsuranceCompanyDetailsForm" class="form-inline  col-xs-12 edit-roadside-assistance-details-form"  [formGroup]="customerInsurance" novalidate (ngSubmit)="update_customer_insurance_company_details(0)">
              <div class=" col-xs-12 form-block">
                <h2 class="fontcolor-blue sub-heading">PERSONAL INFORMATION</h2>
                <div class="row">
                  <div class="form-group col-sm-12 col-md-4">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <input  matInput placeholder="Business Name" title="Business Name" formControlName="business_name" class="form-control input-lg custom-form-input" />
                    </mat-form-field>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <input  matInput placeholder="Contact Name" title="Contact Name" formControlName="contact_name" class="form-control input-lg custom-form-input" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class=" col-xs-12 form-block">
                <h2 class="fontcolor-blue sub-heading">CONTACT INFORMATION</h2>
                <div class="row">
                  <div class="form-group col-sm-12 col-md-4">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <input  matInput placeholder="Local Office Phone Number" title="Local Office Phone Number" formControlName="local_office_phone" class="form-control input-lg custom-form-input" maxlength="15" [textMask]="{mask: phoneMask}" (keyup)="localOfficePhoneValidity()" />
                      <mat-error *ngIf="customerInsurance.controls.local_office_phone.hasError('phoneError')">
                        Invalid phone number
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <input  matInput placeholder="Emergency Phone Number" title="Emergency Phone Number" formControlName="emergency_phone" class="form-control input-lg custom-form-input" maxlength="15" [textMask]="{mask: phoneMask}" (keyup)="emergencyPhoneValidity()" />
                      <mat-error *ngIf="customerInsurance.controls.emergency_phone.hasError('phoneError')">
                        Invalid phone number
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group col-sm-12">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <textarea matInput placeholder="Notes" title="Notes" formControlName="notes" rows="4"></textarea>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class=" col-xs-12 form-block submit-block">
                <button type="button" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Save and stay"  style="max-width: 300px;"  (click)="update_customer_insurance_company_details(0)">SAVE AND STAY</button>
                <button type="button" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Save and close" style="max-width: 300px;"  (click)="update_customer_insurance_company_details(1)">SAVE AND CLOSE</button>
              </div>
            </form>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Warranty Company" data-id="wc">
        <div class="bgcolor-lightgrey form-content">
          <div class="container-fluid">
            <form id="editWarrantyCompanyDetailsForm" class="form-inline  col-xs-12 edit-roadside-assistance-details-form"  [formGroup]="customerWarranty" novalidate>
              <div class=" col-xs-12 form-block">
                <h2 class="fontcolor-blue sub-heading">PERSONAL INFORMATION</h2>
                <div class="row">
                  <div class="form-group col-sm-12 col-md-4">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <input  matInput placeholder="Business Name" title="Business Name" formControlName="business_name" class="form-control input-lg custom-form-input" />
                    </mat-form-field>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <input  matInput placeholder="Contact Name" title="Contact Name" formControlName="contact_name" class="form-control input-lg custom-form-input" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class=" col-xs-12 form-block">
                <h2 class="fontcolor-blue sub-heading">CONTACT INFORMATION</h2>
                <div class="row">
                  <div class="form-group col-sm-12 col-md-4">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <input  matInput placeholder="Phone Number" title="Phone Number" formControlName="mobile_phone" class="form-control input-lg custom-form-input" maxlength="15" [textMask]="{mask: phoneMask}" (keyup)="warrantyPhoneValidity()"/>
                      <mat-error *ngIf="customerWarranty.controls.mobile_phone.hasError('phoneError')">
                        Invalid phone number
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <input  matInput placeholder="Policy Number" title="Policy Number" formControlName="policy_number" class="form-control input-lg custom-form-input" maxlength="20" />
                    </mat-form-field>
                  </div>
                  <div class="form-group col-sm-12 col-md-4">
                    <mat-form-field class="car-input btn-block  car-datePicker"  floatPlaceholder="never">
                      <input matInput class="form-control input-lg custom-form-input" placeholder="Expiration Date" formControlName="expiration_date_frm" [matDatepicker]="expiration_date" onkeydown="return false;">
                      <mat-datepicker-toggle matSuffix [for]="expiration_date"></mat-datepicker-toggle>
                      <mat-datepicker #expiration_date [touchUi]="setTouchViewValue()"></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="form-group col-sm-12">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <textarea matInput placeholder="Notes" title="Notes" formControlName="notes" rows="5"></textarea>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class=" col-xs-12 form-block submit-block">
                <button type="button" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Save and Stay"  style="max-width: 300px;" (click)="update_customer_warranty_company_details(0)">SAVE AND STAY</button>
                <button type="button" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Save and Close" style="max-width: 300px;" (click)="update_customer_warranty_company_details(1)">SAVE AND CLOSE</button>
              </div>
            </form>
          </div>
        </div>
      </mat-tab>

    </mat-tab-group>
  </div>
</div>
</div>