<div mat-dialog-title class="relative payment-title">
      <h4 class="margin-0">Payments
   <span class="sr-id-subHeading hidden-xs" *ngIf="fullRequestId"> - #{{fullRequestId}}</span></h4>
  <div class="payment-on-the-lot">
      <mat-checkbox (change)="updateOnTheLotStatus($event)" [checked]="onTheLot">On The Lot</mat-checkbox>
  </div>
  <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0" (click)="closePopup()">
    <mat-icon class="close-icon-lg">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="payment-dialog-box">
  <div class="center-block" *ngIf="isAllowed('addNew')">
    <div class="srId-show visible-xs" *ngIf="fullRequestId">Request Id - #{{fullRequestId}}</div>
    <form class="form-horizontal form-control-normal" autocomplete="off" [formGroup]="addPayment" *ngIf="dataSources.request_status!='8'">
      <div class="row margin-bottom-5">
        <label class="col-sm-3 control-label"><strong>Payment Method</strong></label>
        <div class="col-sm-9 uc-ngx-select">
            <ngx-select class="display-full margin-bottom-20" [items]="paymentMethod" formControlName="payment_method"
            placeholder="Select Payment Method" [noAutoComplete]="true" (select)="changeMethod($event)">
          </ngx-select>
        </div>
      </div>
      <div class="row margin-bottom-5">
        <label class="col-sm-3 control-label"><strong>Payment Amount</strong></label>
        <div class="col-sm-9">
          <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
            <input matInput type="text" maxlength='14' id="paymentAmountPopup" placeholder="{{dataSources.balance}}" (keyup)="paymentWithOrWithoutCCfee($event,'payment_amount')" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)"  formControlName="payment_amount" (blur)="paymentOnfocuesOut($event,'payment_amount')" class="form-control input-lg custom-form-input btn-block"  required/>
            <mat-error *ngIf="addPayment.controls.payment_amount.hasError('required')">
              Amount is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row margin-bottom-5" *ngIf="creaditCard && isCCFeeIncluded">
        <label class="col-sm-3 control-label"><strong>CC Fee <span *ngIf="addPayment.controls.cc_fee_type.value == '2'">({{addPayment.controls.cc_fee_percentage.value}}%)</span></strong></label>
        <div class="col-sm-9">
          <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
            <input matInput type="text" class="form-control input-lg custom-form-input btn-block" [value]="floatUpdate(ccFee)" disabled="true"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row margin-bottom-5" *ngIf="creaditCard && isCCFeeIncluded">
        <label class="col-sm-3 control-label"><strong>Payment w/ CC Fee</strong></label>
        <div class="col-sm-9">
          <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
            <input matInput type="text" maxlength='14' id="paymentAmountPopupWithCCFee" [(ngModel)]="netPayable"  [ngModelOptions]="{standalone: true}" (keyup)="paymentWithOrWithoutCCfee($event,'payment_with_ccFee')"  (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)"  (blur)="paymentOnfocuesOut($event,'payment_with_ccFee')" class="form-control input-lg custom-form-input"/>
          </mat-form-field>
        </div>
      </div>
      <div class="row margin-bottom-5">
        <label class="col-sm-3 control-label"><strong>Payment Date</strong></label>
        <div class="col-sm-9">
          <mat-form-field class="car-input btn-block car-datePicker" floatPlaceholder="never" appearance="fill">
            <input matInput readonly class="form-control input-lg custom-form-input" formControlName="paymentDate" placeholder="{{maxDates}}" [max]="maxDate"
            [matDatepicker]="picker1" (focus)="picker1.open()" />
            <mat-datepicker-toggle matSuffix class="payment-date" [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker readonly #picker1></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row margin-bottom-5" *ngIf="checkMethod">
        <label class="col-sm-3 control-label"><strong>Check Number</strong></label>
        <div class="col-sm-9">
          <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
            <input matInput type="text" placeholder="Check Number" formControlName="cheque_number" class="form-control input-lg custom-form-input btn-block"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row margin-bottom-5" *ngIf="checkMethod">
        <label class="col-sm-3 control-label"><strong>Bank Name</strong></label>
        <div class="col-sm-9">
          <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
            <input matInput type="text" placeholder="Bank Name" formControlName="bank_name" class="form-control input-lg custom-form-input btn-block"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row margin-bottom-5" *ngIf="creaditCard">
        <label class="col-sm-3 control-label"><strong>CC Last 4 Digits</strong></label>
        <div class="col-sm-9">
          <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
            <input matInput type="text" (keyup)="vkFloat('cc_number')" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)" maxlength='4' placeholder="Last 4 Digits Only" formControlName="cc_number" class="form-control input-lg custom-form-input btn-block"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row margin-bottom-5">
        <label class="col-sm-3 control-label"><strong>Notes</strong></label>
        <div class="col-sm-9 details-input">
          <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
            <textarea matInput placeholder="Notes" formControlName="payment_notes" rows="3" #notes maxlength="500"></textarea>
            <mat-hint align="end">{{notes.value?.length || 0}}/500 Characters Max</mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="row margin-bottom-5">
        <label class="col-sm-3 control-label"><strong>&nbsp;</strong></label>
        <div class="col-sm-9">
          <button mat-raised-button (click)="addPayments()" class="font-bold" color="accent">Add Payment</button>
          <!-- <button mat-raised-button *ngIf="isMarkAsPaid" [disabled]="dataSources.mark_as_paid==0" (click)="markAsPaid(data.request_id)" class="font-bold hide" color="accent">Mark as Paid</button> -->
        </div>
      </div>
    </form>
  </div>
  <div class="display-full margin-bottom-30" *ngIf="isAllowed('views')">
    <h4 class="margin-bottom-30">Previous Payments for this Ticket:</h4>
    <div class="table-responsive relative">
      <table class="table table-striped table-hover table-condensed sortable" id="paymentsList">
        <thead>
          <tr>
            <th class="">#</th>
            <th class="">Payment Method</th>
            <th class="">Date</th>
            <th class="">Amount</th>
            <th class="">CC</th>
            <th class="">Check No.</th>
            <th class="">Bank Name</th>
            <th class="">CC Fee</th>
            <th class="">Total</th>
            <th width="15%" class="">Notes</th>
            <th class="sorttable_nosort" *ngIf="dataSources.request_status!='8' && isAllowed('deletes')">Actions</th>
          </tr>
        </thead>
        <tbody id="paymentsListView">
          <tr *ngFor="let payment of dataSources.data" [class.deletedPay]="payment.delete_status=='1'">
            <td class="">{{payment.payment_id}}</td>
            <td class="">{{getPayMethod(payment.payment_method)}}</td>
            <td class="">{{payment.payment_date}}</td>
            <td class="">{{payment.payment_amount}}</td>
            <td class="">{{payment.credit_card_number}}</td>
            <td class="">{{payment.cheque_number}}</td>
            <td class="">{{payment.bank_name}}</td>
            <td class="">{{floatUpdate(payment.cc_fee)  || 0}}</td>
            <td class="">{{getTotal(payment.payment_amount,payment.cc_fee)}}</td>
            <td width="15%" class="">{{payment.notes}}</td>
            <td class="" *ngIf="isAllowed('deletes')">
              <input (click)="deletePayment(data.request_id,payment.payment_id)" [disabled]="payment.delete_status=='1'" type="button" value="Delete" class="btn btn-xs btn-red-theme deletePayment">
            </td>
          </tr>
          <tr *ngIf="dataSources.data.length==0">
            <td colspan="9" class="norecords-row">No Records Found.</td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>
    <div class="row mrg-top-10">
      <div class="col-sm-4 col-xs-5 pad-top-6">Total Paid:</div>
      <div class="col-sm-8 col-xs-5 pad-top-6" id="payTotalPaid">${{dataSources.total_paid}}</div>
    </div>
    <div class="row mrg-top-10" *ngIf="dataSources.total_cc_fee > 0">
      <div class="col-sm-4 col-xs-5 pad-top-6">Total CC Fee Charge:</div>
      <div class="col-sm-8 col-xs-5 pad-top-6" id="payTotalPaid">${{dataSources.total_cc_fee}}</div>
    </div>
    <!-- <div class="row mrg-top-10 discount-row">
      <div class="col-sm-4 col-xs-5 pad-top-6">Discount:</div>
      <div class="col-sm-8 col-xs-5 pad-top-6" id="totalDiscount">
        ${{(dataSources.discounts.total_discounted_amount | number:'1.2-2') || '0.00'}}
        <ng-container *ngIf="dataSources.discounts.discounts_list && dataSources.discounts.discounts_list.applicable.length > 0">
          <p class="help-block" *ngFor="let coupon of dataSources.discounts.discounts_list.applicable">{{ coupon.discount_code }}</p>
        </ng-container>
        <ng-container *ngIf="dataSources.discounts.discounts_list && dataSources.discounts.discounts_list.inapplicable.length > 0">
          <h5>Inapplicable Coupons</h5>
          <p class="help-block" *ngFor="let coupon of dataSources.discounts.discounts_list.inapplicable">{{ coupon.discount_code }}</p>
        </ng-container>
      </div>
    </div> -->
    <div class="row mrg-top-10">
      <div class="col-sm-4 col-xs-5 pad-top-6">Payment Balance:</div>
      <div class="col-sm-8 col-xs-5 pad-top-6" id="payBalance">${{dataSources.balance}}</div>
    </div>
    <div id="paymentReminderSection" *ngIf="dataSources.balance != null && toNummber(dataSources.balance) > 0">
      <div class="row mrg-top-10">
        <div class="col-sm-4 col-xs-5 pad-top-6">Previous Reminder Date:</div>
        <!-- <div class="col-sm-3 col-xs-5 pad-top-6" id="previousReminderDate">{{dataSources.previous_reminder_date}}</div> -->
        <div class="col-sm-4 col-xs-5 col-xs-offset-5 col-sm-offset-4">
          <button type="button" (click)="showReminderForm()" mat-raised-button class="font-bold" color="accent">Set New Reminder</button>
        </div>
      </div>
      <div class="row mrg-top-10 " id="setReminderSection" *ngIf="newReminderShow">
        <div class="col-sm-4 pad-top-6">Reminder Date:</div>
        <div class="col-sm-3 col-xs-7 pad-top-6">
          <mat-form-field [formGroup]="addReminder" class="car-input btn-block car-datePicker" floatPlaceholder="never" appearance="fill">
            <input matInput class="form-control input-lg custom-form-input" formControlName="payment_reminder_date" placeholder="Reminder Date"
            [matDatepicker]="picker2" (focus)="picker2.open()" />
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-sm-3 col-xs-5 pad-top-6"><button (click)="setReminder()" type="button" mat-raised-button class="font-bold" color="accent" id="savePaymentReminder">Save</button></div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
  <button mat-raised-button *ngIf="requestStatus == 6 || requestStatus == 8" (click)="getInvoicePdf()" class="font-bold" color="accent" title="Download Invoice">Download Invoice</button>
  <button mat-raised-button mat-dialog-close class="font-bold" (click)="closePopup()">Close</button>
</mat-dialog-actions>
