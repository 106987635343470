import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { globalConstants } from '../../../../environments/globalConstants';
import { globalFunctions } from '../../../../environments/globalFunctions';

@Component({
  selector: 'app-extrafields',
  templateUrl: './extrafields.component.html',
  styleUrls: ['./extrafields.component.scss']
})
export class ExtrafieldsComponent implements OnInit {
  public user: any = null
  public param: any = {}
  public customForm: FormGroup
  public fieldType = globalFunctions.customFieldType()
  public isMadatory = 0
  public fieldDefaultType:any = ''
  public fieldOptions = [{ value: '' }, { value: '' }]
  public isEdit=false

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public gs: GlobalService, public dialogRef: MatDialogRef<ExtrafieldsComponent>, public dialog: MatDialog, private form: FormBuilder) {
    this.gs.diaLogObj(this.dialogRef);
    this.user = this.gs.getCurrentUser()
    this.param = data
  }

  ngOnInit() {
    let shopPer = this.gs.getAcl('custom_vehicle_fields', 'addNew')
    if (!shopPer) {
      this.dialogRef.close()
    }
    this.customForm = this.form.group({
      label: ['', [Validators.required]],
      is_mandatory: [''],
      type: [''],
      field_options: [''],
      is_default: [''],
      field_id: [''],
      user_id: this.user.id,
      user_type: this.user.user_type,
    })
    if (this.param && typeof this.param.id != 'undefined') {
      this.isEdit=true
      this.getDetail()
    } else {
      this.fieldDefaultType = 'textbox'
      this.customForm.controls.type.setValue(this.fieldDefaultType)
    }
  }
  isMadatChange($e) {
    if ($e && $e.value) {
      this.customForm.controls.is_mandatory.setValue($e.value)
    }
  }
  getOptionType(e) {
    if (e) {
      this.customForm.controls.type.setValue(e)
    }
  }
  addNewField() {
    if (this.customForm.value.type == 'radio' || this.customForm.value.type == 'dropdown') {
      this.customForm.controls.field_options.setValue(JSON.stringify(this.fieldOptions))
    } else {
      this.customForm.controls.field_options.setValue('')
    }
    let url = globalConstants.API_CUSTOM_VEHICLE_FIELD
    if (this.param && typeof this.param.id != 'undefined') {
      url = globalConstants.API_EDIT_CUSTOM_FIELD
    }

    let content = this.gs.formsData(this.customForm.value)
    if (this.customForm.valid) {
      this.gs.formData(url, content).subscribe((r) => {
        if (r.result == 1) {
          this.gs.snackbar('success', r.message);
          this.dialogRef.close(r.data)
        } else {
          this.gs.snackbar('error', r.message);
        }
      }, (error) => { })
    } else {
      this.gs.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
  }

  addOption() {
    this.fieldOptions.push({ value: '' })
  }

  removeOpt(i) {
    this.fieldOptions.splice(i, 1)
  }
  getDetail() {
    let data = { field_id: this.param.id, user_id: this.user.id, user_type: this.user.user_type }
    this.gs.callAPI(globalConstants.API_GET_CUSTOM_FIELD_LIST, data).subscribe((r:any) => {
      if (r.result == 1) {
        let field = r.data
        this.fieldOptions = field.field_options
        this.customForm.controls.type.setValue(field.type)
        this.customForm.controls.field_id.setValue(this.param.id)
        this.fieldDefaultType = field.type
        this.customForm.controls.label.setValue(field.label)
        this.customForm.controls.is_mandatory.setValue(field.is_mandatory)
        this.isMadatory = field.is_mandatory
      }
    }, (error) => { })
  }

}
