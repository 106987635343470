import { Component, OnInit, ViewChild} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap,NavigationEnd, Params  } from '@angular/router';
import { Http,Headers, Response} from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { globalFunctions } from "../../../environments/globalFunctions"
import { globalConstants } from "../../../environments/globalConstants"
import {GlobalService} from "../../../environments/global.service"
import { lang } from "../../../environments/lang-en"
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TextMaskModule } from 'angular2-text-mask';

@Component({
  selector: 'app-shopsubscriptionlogs',
  templateUrl: './shopsubscriptionlogs.component.html',
  styleUrls: ['./shopsubscriptionlogs.component.scss']
})
export class ShopsubscriptionlogsComponent implements OnInit {

  public currentCustomer;
  public getShopName;
  public currentCustomerName;
  public currentCustomerVehicles;
  public isCollapsed:boolean = true;
  public idFromUrl;
  public showSubscriptionMessage = false;
  public base64value="";
  public selectedKey="";
  public states:any;
  public cities:any;
  public editComputer:FormGroup;
  public currentComputerDetails;
  public getSubscriptionLogs: any = [];
  public getCompanyName;
  public logDataLength;
  public DisplayDuration;
  public result: any;
  public logStatuses: any = ['danger', 'success success-completed'];
  
  constructor(private activatedRoute:ActivatedRoute,private formBuilder: FormBuilder,private route: ActivatedRoute,public titleService:Title,private http: Http,private router: Router,public globalService: GlobalService) {
    //console.log(router.url)
    
    if(localStorage.getItem("loggedInAdmin")) {
        this.setParams();
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInAdmin"));
        this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
        /* Shop Name */
        var oprListShops = "user_id=" + this.currentCustomer.id +"&user_type=" + this.currentCustomer.user_type+ "&shop_id=" + this.idFromUrl;
        this.globalService.callAPI(globalConstants.API_GET_SHOP_DETAIL_URL, oprListShops).subscribe((data:any) => {
            this.getShopName = data.data;
            this.getCompanyName = this.getShopName.company_name;
        })   

        //var opr = "user_id=" + this.currentCustomer.id + "&user_type=" + this.currentCustomer.user_type+ "&shop_id=" + this.idFromUrl;
        this.globalService.callAPI(globalConstants.API_GET_SHOP_SUBSCRIPTION_LOGS_URL, oprListShops).subscribe((data:any) => {
            //this.getSubscriptionLogs = data.data;
            this.logDataLength = data.recordsTotal;
            if (data.result == "1") { 
                if(data.data.length == undefined){
                    this.getSubscriptionLogs[0] = data.data;
                } else {
                    this.getSubscriptionLogs = data.data;
                }
                for(var i=0;i<this.getSubscriptionLogs.length;i++) {
                    this.getSubscriptionLogs[i].add_date = this.globalService.getFullFormateDate(this.getSubscriptionLogs[i].add_date,'GD','H'),
                    this.getSubscriptionLogs[i].expiry_date = this.globalService.getFullFormateDate(this.getSubscriptionLogs[i].expiry_date,'GD','H')
                }
            }
        })        
       
    } else {
      this.router.navigate(["/admin"]);
    }
  }
  
  setParams(){
    this.activatedRoute.params.subscribe((params: Params) => {
        this.idFromUrl =atob(params["shop_id"]);
     });
  }
  
    ngAfterViewInit(){
        /*
        this.setParams();
        var oprListShops = "user_id=" + this.currentCustomer.id +"&user_type=" + this.currentCustomer.user_type+ "&shop_id=" + this.idFromUrl;
        this.globalService.callAPI(globalConstants.API_GET_SHOP_SUBSCRIPTION_LOGS_URL, oprListShops).subscribe((data) => {
            this.getSubscriptionLogs = data.data;
            console.log("SUBS LOG", this.getSubscriptionLogs)
            if(this.getSubscriptionLogs != "undefined" || this.getSubscriptionLogs != undefined) { 
                this.logDataLength = false;
            }
        })

        */
    }
    
    sideNavOpen(){

    }
    onResize(event) {
      event.target.innerWidth;
    }
    ngOnInit() {
        this.globalService.setMetaData("ADMIN","SHOP_SUBSCRIPTION_LOG")
    }
    getCurrentRoute() {
      return this.router.url
    }
    
    convertStaticNameToDynamic(id) {
        if(id == 1)  {
          return "CAR Beta";
        } else if(id == 2) { 
          return "CAR Basic";
        } else if(id == 3) { 
          return "CAR Premium";
        } else if(id == 4) { 
          return "CAR Basic with Mailchimp";
        } else if(id == 5) { 
          return "CAR Beta with Mailchimp";
        }
    }
    
    getDuration(id){ 
        if(id==1) { 
            this.DisplayDuration = id+" month";
            return this.DisplayDuration;
        } else if(id > 1 && id == 12) { 
            this.DisplayDuration = id+" months";
            return this.DisplayDuration;
        } else if(id > 12) {
            this.DisplayDuration = (id/12)+" years";
            return this.DisplayDuration;
        }
    }
    
    extendSubscription() {
        this.setParams();
        //this.globalService.localGetterSetter(this.options,null,SHOP_LIST);
        var stringifiedFormData = "user_type=" + this.currentCustomer.user_type + "&user_id=" + this.currentCustomer.id + "&shop_id=" + this.idFromUrl;
        /*if (ele.source.checked) {
            var alertMessage = 'Do you want to enable this shop ?'
        } else {
            var alertMessage = 'Do you want to disable this shop ?'
        }*/
        var alertMessage = 'Are you sure you want to continue?'
        this.globalService.confirmDialog(this.idFromUrl, alertMessage).subscribe((res) => {
            this.result = res;
            if (this.result != null) {
                this.globalService.callAPI(globalConstants.API_EXTEND_SHOP_SUBSCRIPTION_URL, stringifiedFormData).subscribe((data:any) => {
                    var message = data.message;
                    if (data.result == "1") {
                        this.globalService.snackbar('success', message);
                    } else {
                        this.globalService.snackbar('error', message);
                    }
                });
            }
        });
    }
    
    /* GET STATUS */
    getStatus(statusId) {
        return this.logStatuses[statusId]
    }
}
