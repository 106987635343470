import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem,CdkDrag } from '@angular/cdk/drag-drop';
import { BottomSheetHelperComponent } from '../../../base/helpers/bottom-sheet-helper/bottom-sheet-helper.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AppComponent } from '../../../app.component';
import { GlobalService } from 'src/environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { VehicleInspectionReportComponent } from '../../shopservicerequest/vehicle-inspection-report/vehicle-inspection-report.component';
import { UspsVehicleInspectionReportComponent } from '../../shopservicerequest/usps-vehicle-inspection-report/usps-vehicle-inspection-report.component';
import { JobApprovalDialogComponent } from '../../shopservicerequest/job-approval-dialog/job-approval-dialog.component';
import { RequireApprovalDialogComponent } from '../../shopservicerequest/require-approval-dialog/require-approval-dialog.component';
import { MilesoutComponent } from '../../shopservicerequest/milesout/milesout.component';
import { PaymentDialogComponent } from '../../shopservicerequest/payment-dialog/payment-dialog.component';
import { AddMileageDialogComponent } from '../../shopservicerequest/add-mileage-dialog/add-mileage-dialog.component';

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss']
})
export class WorkflowComponent implements OnInit {
  @ViewChild(TemplateRef) template: TemplateRef<any>;
  public srStatusOptionsList: any = [
    { id: "", text: "Select Status" },
    { id: "11", text: "Appointments" },
    { id: "1", text: "Pending" },
    { id: "2", text: "In Progress" },
    { id: "3", text: "Approval Awaited" },
    { id: "12", text: "Work In Progress" },
    { id: "6", text: "Completed" },
    { id: "8", text: "Completed And Paid" },
  ];

  public jobStatusOptionsList: any = [
    { id: "", text: "Job Status" },
    { id: "1", text: "Not Started" },
    { id: "2", text: "In Progress" },
    { id: "3", text: "Completed" }
  ];

  public quickActionsOptionsList: any = [
    { id: "", text: "Quick Actions" },
    { id: '0', text: "Assgined To Me" },
    { id: "1", text: "Today" },
    { id: "2", text: "Week" },
    { id: "3", text: "Month" },
    { id: "4", text: "Date Range" }
  ];

 public FlagOptionList :any = [
      {id:"", text: "Select Flag"},
      {id:"gray", text: "Gray"},
      {id:"blue", text: "Blue"},
      {id:"teal", text: "Teal"},
      {id:"green", text: "Green"},
      {id:"yellow", text: "Yellow"},
      {id:"red", text: "Red"},
      {id:"purple", text: "Purple"}
  ]
  public options: any = {
    user_type: "User",
    user_id: "",
    employee_id: "",
    status: '',
    duration: "",
    search_keyword: "",
    job_status: "",
    label_id:"",
    flag_value:""
  };
  public serviceRequestCount: any = {
    "pendingCount": 0,
    "inProgressCount": 0,
    "approveWaitCount": 0,
    "workInProgressCount": 0,
    "invoiceCount": 0,
    "paidCount": 0
  };
  public modulesName = 'service_request';
  public modulePermission: any = {};
  public apptAndPendingList: any = [];
  public inProgressList: any = [];
  public approvalAwaitedList: any = [];
  public workInProgressList: any = [];
  public OnTheLotList: any = [];
  public invoicedList: any = [];
  public paidList: any = [];
  public currentUser: any;
  public moreFilter: boolean = false;
  public enableDateRange: boolean = false;
  public showClearFilterCss: boolean = false;
  public disableDargAndDrop: boolean;
  public techniciansList: any = [];
  public quickActionData: any; //re-visit
  public serviceRequestLabelsOptionsList : any = [];
  public serviceRequestInfo :any;
  public techinianWorkingStatus:boolean = true;
  public targetColumnName : any;
  public SRInfo : any;
  public latestSRData : any;
  public VehicleInspectionReport : any;
  public confirmationTitle: string;
  public disableApptAndPendingCol :any = false
  public disableInProgressCol :any = false;
  public disableApprovalAwaitedCol :any = false;
  public disableWorkInProgressCol :any = false;
  public disableInvoicedCol :any = false;
  public disablePaidedCol :any = false;
  public permissionValidatonMsg : any = "You are not allowed to perform this Action";
  public isLoaderShowing :any = true;
  public sourescColumnName: string;
  public someChangesVerbiageTxt : string = "Some changes have occurred in this Service Ticket. Please refresh the page and review changes.<br>Do you want to reload the page now?";
  public convertListStatus :any;
  public pendingAndApptListBeforeToggle:any;
  public apptAndPendingTitleOfColumn :string = "Appt/Pending";
  constructor(
    private bottomSheet: MatBottomSheet,
    private app: AppComponent,
    public globalservice: GlobalService,
    public router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.currentUser = this.globalservice.getCurrentUser();
    this.disableDargAndDrop = this.globalservice.detectMobileScreen();
    this.globalservice.setMetaData("SHOPS","JOBBOARD");
    setTimeout(() => {
      this.modulePermission = this.globalservice.isAllowed(this.app, this.modulesName);
      if (!(this.modulePermission && (this.modulePermission['enable'] == 1))) {
        this.router.navigate(['shops/action/forbidden']);
      }
    }, globalConstants.RELOAD_WAIT);
    let empId = this.globalservice.getSetItems('jobBoardFilterEmpId');
    if(empId !== '' && empId !== null){
         this.options.employee_id = empId;
         this.moreFilter = true;
         this.search();
    }
    this.getServiceRequestData();
    this.getTechnicianData();
    this.getSRLabelsData();
  }

  navigatetoListPage() {
    // Use the Router to navigate to the desired route
    this.router.navigate(['/shops/list-service-requests']);
  }

  getServiceRequestData() {
    this.options.user_id = this.currentUser.id;
    this.options.isLaderShow = this.isLoaderShowing;
    this.globalservice.callAPI(globalConstants.API_GET_SERVICE_REQUEST_JOB_BOARD, this.options).subscribe((res: any) => {
      if (res.result == 1) {
        this.apptAndPendingList = res.data.appointment_pending;
        this.inProgressList = res.data.in_progress;
        this.approvalAwaitedList = res.data.approval_awaited;
        this.workInProgressList = res.data.work_in_progress;
        this.invoicedList = res.data.invoiced;
        res.data.paid.forEach(element => {
             element["disable"] = true;
        });
        this.paidList = res.data.paid;
        this.serviceRequestCount = {
          "pendingCount": this.apptAndPendingList.length,
          "inProgressCount": this.inProgressList.length,
          "approveWaitCount": this.approvalAwaitedList.length,
          "workInProgressCount": this.workInProgressList.length,
          "invoiceCount": this.invoicedList.length,
          "paidCount": this.paidList.length
        };
      }
      this.handleColorOfClearButton();
    })
  }

  getTechnicianData() {
    let option = {
      user_id: this.currentUser.id,
      user_type: 'User'
    };
    this.globalservice.callAPI(globalConstants.API_GET_TECHNICIAN, option).subscribe((res:any) => {
      if (res.result == 1) {
        var tempTechnicianData =[{
          id: '', first_name: 'Select ', middle_name: '', last_name: 'Employee', role_name: ''
        }];
        this.techniciansList = tempTechnicianData.concat(res.allemployes);
      }
    })
  }

  getSRLabelsData(){
    let options = {
      user_id: this.currentUser.id,
      user_type: 'User'
    }
    this.globalservice.callAPI(globalConstants.API_GET_SERVICE_REQUEST_LABELS_MASTER,options).subscribe((res:any) => {
      if (res.result == 1) {
        let tempSRLabels = [
          {id: '', shop_id: '', label_name: 'Select Label', add_date: '', modify_date: ''},
          {id: 'OTL', shop_id: '', label_name: 'On The Lot', add_date: '', modify_date: ''},
          {id: 'WAITING_FOR_PARTS', shop_id: '', label_name: 'Waiting For Parts', add_date: '', modify_date: ''},
          {id: 'ACCOUNTANT_VERIFIED', shop_id: '', label_name: 'Accountant Verified', add_date: '', modify_date: ''}
        ];  //Revisit add this tempSRLabels in global constant file
        this.serviceRequestLabelsOptionsList = tempSRLabels.concat(res.data);
        this.serviceRequestLabelsOptionsList = this.globalservice.sortLabelsListInAlphabeticallyOrder(this.serviceRequestLabelsOptionsList);
      }
    })
}

  showMoreFilter() {
    this.moreFilter = !this.moreFilter;
  }

  handleQuickAction(event) {
    if (event == 0) {
      this.options.employee_id = this.currentUser.id;
      this.options.duration = '';
      this.enableDateRange = false;
      this.options.requested_date_range = '';
      this.globalservice.ShowDateRange(this.enableDateRange);
    } else if (event == 4) {
      this.enableDateRange = true;
      this.options.duration = event;
      this.globalservice.ShowDateRange(this.enableDateRange);
    } else {
      this.options.duration = event;
      this.enableDateRange = false;
      this.options.requested_date_range = '';
      this.globalservice.ShowDateRange(this.enableDateRange);
    }
  }

  search() {
    this.showClearFilterCss = true;
    this.isLoaderShowing = true;
    this.getServiceRequestData();
  }

  clearFilter() {
    this.options = {
      user_type: "User",
      user_id: this.currentUser.id,
      employee_id: "",
      status: '',
      duration: "",
      search_keyword: "",
      job_status: "",
      requested_date_range: "",
      label_id:"",
      flag_value:""
    };
    this.showClearFilterCss = false;
    this.quickActionData = '';
    this.enableDateRange = false;
    this.globalservice.ShowDateRange(this.enableDateRange);
    this.isLoaderShowing = true;
    this.getServiceRequestData();
  }

  handleColorOfClearButton(){
      if(this.options.employee_id == '' 
         && this.options.status == '' 
         && this.options.duration == ''
         && this.options.search_keyword == '' 
         && this.options.job_status == ''
         && this.options.label_id == ''
         &&  this.options.flag_value == ''
         ){
          this.showClearFilterCss = false;
         }else{
          this.showClearFilterCss = true;
         }
  }

  handleTechician(techniciaID: any) {
    if (techniciaID != this.currentUser.id) {
      if (this.options.duration == '') {
        this.quickActionData = '';
      }
    }
  }

  handleDateRange(event) {
    var stringDateFormate = [
      event.start.toISOString(),
      event.end.toISOString()
    ]
    this.options.requested_date_range = stringDateFormate;
  }


  drop(event: CdkDragDrop<string[]>) { //re-visit
    this.sourescColumnName = event.previousContainer.id;
    this.targetColumnName = event.container.id;
    this.SRInfo = event.previousContainer.data[event.previousIndex];
    this.BackToNormalColorOfAllColumn();
    if (event.previousContainer === event.container) {
      // console.log("this is not drop the data i.e crad is back to moves perviours container");
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // console.log("this is drop the data")
      if (this.targetColumnName == 'inProgressColumn') {
        this.moveCardToInProgress(event)
      } else if (this.targetColumnName == 'approvalAwaitedColumn') {
        this.moveCardtoApprovalAwaited(event);
      } else if (this.targetColumnName == 'workInProgressColumn') {
        this.moveCardToWorkInProgress(event);
      }  else if (this.targetColumnName == 'invoicedColumn') {
        this.moveCardtoInvoiced(event);
      }  else if (this.targetColumnName == 'paidColumn') {
        this.moveCardtoPaid(event);
      } 

    }
  }

  reDrawJobBoard(event:any){
      this.isLoaderShowing = false;
      this.lockUnlockServiceRequest(this.SRInfo,0);
      this.getServiceRequestData();
  }
  
  moveCardToInProgress(cardData:any){
    if (this.isAllowed('addNew', 'mark_request_under_pr')) {
      this.getUpdatedDataofSR(cardData)
    } else {
      this.globalservice.snackbar("error", this.permissionValidatonMsg);
      this.reDrawJobBoard(cardData);
    }
  }

  moveCardtoApprovalAwaited(cardData: any) {
      this.getUpdatedDataofSR(cardData);
  } 

  moveCardToWorkInProgress(cardData:any) {
    this.getUpdatedDataofSR(cardData); 
  }


  moveCardtoInvoiced(cardData: any) {
    if (this.isAllowed('views', 'mark_service_complete')) {
      this.getUpdatedDataofSR(cardData);
    } else {
      this.globalservice.snackbar("error", this.permissionValidatonMsg);
      this.reDrawJobBoard(cardData);
    }
  }

  moveCardtoPaid(cardData: any) {
    if (this.isAllowed('views', 'mark_service_complete')) {
      this.getUpdatedDataofSR(cardData);
    } else {
      this.globalservice.snackbar("error", this.permissionValidatonMsg);
      this.reDrawJobBoard(cardData);
    }
  }


  markSRInUnderProgress(cardData){
    let content = 'id=' + this.SRInfo.id + "&user_id=" + this.currentUser.id + "&user_type=" + this.currentUser.user_type;
    this.globalservice.callAPI(globalConstants.API_UPDATE_SERVICE_REQUEST_STATUS_URL, content).subscribe((response: any) => {
      if (response.result == "1") {
        this.globalservice.snackbar("success", response.message);
        this.reDrawJobBoard(cardData);
      } else {
        this.globalservice.snackbar("error", response.message);
      }
    });
  }

  getUpdatedDataofSR(cardData: any) {
    let content = "user_id=" + this.currentUser.id + "&user_type=" + this.currentUser.user_type + "&service_request_id=" + this.SRInfo.id;
    this.globalservice.callAPI(globalConstants.API_GET_SERVICE_REQUEST_VIEW_URL, content).subscribe((response: any) => {
      if (response.result == 1) {
       if(this.SRInfo.access_timestamp > response.data.modify_date){
          this.latestSRData = response.data;
          this.VehicleInspectionReport = response.data.inspection_reports;
         if (this.latestSRData.is_recom_locked == 1 && this.latestSRData.recom_locker_id != this.currentUser.id) {
           let conf = this.latestSRData.recom_locker_name + " is working and filling up the recommendation on this ticket ("+this.latestSRData.request_id+"), Do you want to <b>Release</b> it?";
           this.globalservice.confirmDialog("yes", conf).subscribe((ress: any) => {
             if (ress == 'yes') {
                    this.releaseLock(cardData);
             }
           })
         } else {
              this.lockUnlockServiceRequest(this.latestSRData,1);
               if (this.sourescColumnName == 'inProgressColumn' || this.sourescColumnName == 'approvalAwaitedColumn' || this.sourescColumnName == 'workInProgressColumn' || this.sourescColumnName == 'invoicedColumn') {
                 this.checkRequestStatusAndTakeAction(cardData);
               } else {
                 this.checkmilageAndmarkSRInUnderProgress(cardData);
             }
         }
        }else{
          let conf = this.someChangesVerbiageTxt;
          this.globalservice.confirmDialog("yes", conf).subscribe((ress:any)=>{
             if(ress == 'yes'){
                this.reDrawJobBoard(cardData);
             }
          })
        }
      }
    })
  }

  releaseLock(cardData:any){
    if(this.SRInfo.id){
      let data={request_id:this.SRInfo.id,lock_status:0,force_unlock:1};
      this.globalservice.callAPI(globalConstants.API_MODIFY_RECOM_LOCK,data).subscribe((res:any)=>{
       if (res.result == 1) {
         this.globalservice.snackbar("success", res.message);
         this.lockUnlockServiceRequest(this.latestSRData,1);
         if (this.sourescColumnName == 'inProgressColumn' || this.sourescColumnName == 'approvalAwaitedColumn' || this.sourescColumnName == 'workInProgressColumn' || this.sourescColumnName == 'invoicedColumn') {
          this.checkRequestStatusAndTakeAction(cardData);
        } else {
          this.checkmilageAndmarkSRInUnderProgress(cardData);
        }
       }else{
        this.globalservice.snackbar("error", res.message);
       }
      },(error)=>{});
     }
  }

  checkRequestStatusAndTakeAction(cardData: any) {
    let requestStatus = this.latestSRData.request_status;
    if (requestStatus == 6) { //request is complete
      this.showAddPaymentPopUp(cardData);
    }
    else if (((this.latestSRData.notify_to_admin == '1' || this.latestSRData.notify_to_customer == '1') && requestStatus == '12') || requestStatus == '7') { // 7(Approval Done),12(Work in Progress)
      this.checkTechnianWorkingStatus(cardData);
    }
    else  //Not Request Completed 6,Not Has been paid 8   
    {
      if (this.sourescColumnName == 'approvalAwaitedColumn') {
        this.showJobApprovalPopup(cardData, {});
      } else if (this.sourescColumnName == 'inProgressColumn') {
        this.checkTechnianWorkingStatus(cardData);
      }
    }
  }

  checkmilageAndmarkSRInUnderProgress(cardData){
    if(this.SRInfo.present_mileage == undefined || this.SRInfo.present_mileage == ""|| this.SRInfo.present_mileage == 0  ){
      this.showAddMileageDialog(this.SRInfo,this.SRInfo.distance_covered,'2',cardData);
    } else {
         this.markSRInUnderProgress(cardData);
    }
  }

  checkTechnianWorkingStatus(cardData:any){
    let data = "user_id=" + this.currentUser.id + "&user_type=" + this.currentUser.user_type + "&request_id=" + this.SRInfo.id;
    this.globalservice.callAPI(globalConstants.API_SERVICE_REQUEST_TRACKERS, data).subscribe((r: any) => {
      if (r.result == 1) {
        this.techinianWorkingStatus = true;
        for (var i in r.data) {
          if (r.data[i].work_status != '3') {
            this.techinianWorkingStatus = false;
          }
        }
        // this.getUpdatedDataofSR(cardData);
         this.showAllTimeStopPopup(cardData);
         
      }else{
         this.techinianWorkingStatus = true;
        //  this.getUpdatedDataofSR(cardData);
         this.showAllTimeStopPopup(cardData);
      }   
    })
  }


  showAllTimeStopPopup(cardData) {
    let payload = {
      requestCreationType: 3,
      force_override: '0',
      request_id: this.SRInfo.id,
      user_id: this.currentUser.id,
      user_type: this.currentUser.user_type
    };
    if (!this.techinianWorkingStatus) {
      this.confirmationTitle = '<center><b>All timers will stop</b></center> <center>Are you sure the technician is done with this service and you want to send updated information to customer?</center> ';
      this.globalservice.confirmDialog("yes", this.confirmationTitle).subscribe((ress) => {
        let r: any = ress;
        if (r == 'yes') {
          payload.force_override = '1';
          if(this.sourescColumnName == 'workInProgressColumn'){
              this.showmilesOutPopup(cardData);
          }else{
            this.handleInpectionReportConfirmationPopup(payload, cardData);
          }
          
        } else {
          this.reDrawJobBoard(cardData); //re-drawJobBoard programmatly
        }
      })
    } else {
      // this.handleInpectionReportConfirmationPopup(payload, cardData);
      if (this.sourescColumnName == 'workInProgressColumn') {
        this.showmilesOutPopup(cardData);
      } else {
        this.handleInpectionReportConfirmationPopup(payload, cardData);
      }
    };
  }

 
  handleInpectionReportConfirmationPopup(payload: any, cardData: any) {
    let noReportPerformed = this.latestSRData.no_report_performed == "1" ? true : false;
    payload = {
      "user_id" : this.currentUser.id,
      "user_type": this.currentUser.user_type,
      "service_request_id" : this.SRInfo.id,
      "force_override": payload.force_override,
      "shop_id": this.currentUser.shop_id,
      "customer_id": this.latestSRData.customer_id,
      "no_report_performed": noReportPerformed,
      "opr": false,
      "discount_id":'',
      "return_queue":"",
      "requestCreationType":2,
      "access_timestamp":this.SRInfo.access_timestamp
    };
    this.globalservice.callAPI(globalConstants.API_MOVE_CARD_ON_JOB_BOARD, payload).subscribe((response: any) => { //add the new api awaited
      if (response.result == 1) {
          //  this.reDrawJobBoard(cardData);
          if (this.targetColumnName == 'workInProgressColumn' || this.targetColumnName == 'invoicedColumn' || this.targetColumnName == 'paidColumn') {
            this.latestSRData.access_timestamp = response.access_timestamp;
            this.showJobApprovalPopup(cardData, payload);
          } else{
              this.reDrawJobBoard(cardData);
          }
      } else {
        if (response.code == '352') {
          let conf = this.someChangesVerbiageTxt;
          this.globalservice.confirmDialog("yes", conf).subscribe((ress:any)=>{
             if(ress == 'yes'){
                this.reDrawJobBoard(cardData);
             }
          })
        }
        else if (response.code == 167 || response.code == 166) {
          let ask = "Inspection report is not performed on this vehicle. Do you want to submit the report now ?";
          this.globalservice.confirmDialog("ask_vehicle_ins", ask).subscribe((insDiagRes) => {
            let insDiagResult: any = insDiagRes;
            switch (insDiagResult) {
              case 'no':
              //  this.reDrawJobBoard(cardData); //thorwing cmpliation error
              case 'close':
                this.reDrawJobBoard(cardData);
                break; //r
              case 'skip':
                if (this.targetColumnName == 'workInProgressColumn' || this.targetColumnName == 'invoicedColumn' || this.targetColumnName == 'paidColumn') {
                  this.showJobApprovalPopup(cardData, payload);
                } //re-vite
                else {
                  payload.no_report_performed = true;
                  this.handleSkipAndSaveRecommendation(cardData, payload);
                }
                break;
              case 'yes':
                if (this.latestSRData.is_usps_vehicle == '1') {
                  this.showUSPSInspectionReportPopup(cardData, payload);
                } else {
                  this.showAcdelcoInspectionReportPopup(cardData, payload);
                }
                break;
            }
          })
        }else{
          this.globalservice.snackbar("error", response.message);
        }
      }
    })
  }

  handleSkipAndSaveRecommendation(cardData:any,payload:any){  
    this.globalservice.callAPI(globalConstants.API_MOVE_CARD_ON_JOB_BOARD, payload).subscribe((response: any) => { //add the new api awaited
      this.reDrawJobBoard(cardData);
    })
  }

  showAcdelcoInspectionReportPopup(cardData: any, payload: any): void {
    let reportData;
    if (this.VehicleInspectionReport.length) {
      reportData = this.VehicleInspectionReport[0].acdelco_report_data;  //where this data is comaing in 
    }
    let dialogRef = this.dialog.open(VehicleInspectionReportComponent, {
      panelClass: ['car-dialog-form', 'inscpection-dialog'],
      data: { service_request_id: this.SRInfo.id, report_data: reportData,disableFormInputControls:0,fullRequestId: this.SRInfo.request_id  }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.status == 'success') {
            this.SRInfo.access_timestamp = result.access_timestamp;
        if (this.targetColumnName == 'approvalAwaitedColumn') {
          payload.no_report_performed = false;
          this.handleSkipAndSaveRecommendation(cardData, payload); //call save recomadation method
        } 
        else if (this.targetColumnName == 'workInProgressColumn' || this.targetColumnName == 'invoicedColumn' || this.targetColumnName == 'paidColumn') { //re-vite
          this.showJobApprovalPopup(cardData, payload);
        }
      } else {
        this.reDrawJobBoard(cardData);
      }
    });
  }

  showUSPSInspectionReportPopup(cardData: any, payload: any) {
    let reportData;
    if (this.VehicleInspectionReport.length) {
      reportData = this.VehicleInspectionReport[0].acdelco_report_data;
    }
    let dialogRef = this.dialog.open(UspsVehicleInspectionReportComponent, {
      panelClass: ['car-dialog-form', 'inscpection-dialog'],
      data: { service_request_id: this.SRInfo.id, report_data: reportData,disableFormInputControls:0,fullRequestId: this.SRInfo.request_id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'success') {
        this.SRInfo.access_timestamp = result.access_timestamp;
        if (this.targetColumnName == 'approvalAwaitedColumn') {
          payload.no_report_performed = false;
          this.handleSkipAndSaveRecommendation(cardData, payload); //call save recomadation method
        } 
        else if (this.targetColumnName == 'workInProgressColumn' || this.targetColumnName == 'invoicedColumn' || this.targetColumnName == 'paidColumn') { //re-vite
          this.showJobApprovalPopup(cardData, payload);
        }
      } else {
        this.reDrawJobBoard(cardData);
      }
    });
  }


  showJobApprovalPopup(cardData: any, payload: any) {
    let approvalDiagParams =
    {
      requestId: this.SRInfo.id,
      car_id: this.SRInfo.car_id,
      customer_id: this.SRInfo.customer_id,
      access_timestamp: this.latestSRData.access_timestamp,
      skip_reassign: 1,
      fullRequestId:this.SRInfo.request_id
    };
    let dialogRef = this.dialog.open(JobApprovalDialogComponent, {
      panelClass: 'car-dialog-form',
      width: '800px',
      data: approvalDiagParams
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res.action == 'requireApproval') {
        if (this.targetColumnName == 'workInProgressColumn' || this.targetColumnName == 'invoicedColumn' || this.targetColumnName == 'paidColumn') {
          this.showCustomerAuthorizationPopUp(cardData, res.data);
        }
      } else if(res.action == 'goToCompletion'){
        if (this.targetColumnName == 'invoicedColumn' || this.targetColumnName == 'paidColumn') {
          this.showmilesOutPopup(cardData);
        }else{
           this.reDrawJobBoard(cardData); 
        }
      } else {
        this.reDrawJobBoard(cardData); //when close the pop-up
      }
    });

  }


  showCustomerAuthorizationPopUp(cardData: any, data) {

    localStorage.setItem("requestCreatedDate", this.globalservice.getFullFormateDate(this.SRInfo.add_date, "G", ""));
    let dialogRef = this.dialog.open(RequireApprovalDialogComponent, {
      panelClass: ['payment-dialog'],
      width: '800px',
      data: { service_request_id: this.SRInfo.id, access_timestamp: data.access_timestamp, user_id: this.currentUser.id, customer_id: this.SRInfo.customer_id, jobStatus: data.areAllJobsRejected, skip_reassign: 1,fullRequestId: this.latestSRData.request_id}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.targetColumnName == 'invoicedColumn' || this.targetColumnName == 'paidColumn') {
          this.showmilesOutPopup(cardData);
        } else {
          this.reDrawJobBoard(cardData);
        }
      } else {
        this.reDrawJobBoard(cardData);
      }
    });
  }

  showmilesOutPopup(cardData: any) {
    let content = "user_id=" + this.globalservice.getCurrentUser()["id"] + "&user_type=" + this.globalservice.getCurrentUser()["user_type"] + "&service_request_id=" + this.SRInfo.id;
    this.globalservice.callAPI(globalConstants.API_GET_SERVICE_REQUEST_VIEW_URL, content).subscribe((response:any) => {
    let options = {
      tax: response.data.tax_rate,
      total: response.data.tax_amount,
      isTax: response.data.is_tax_included,
      fullRequestId: this.latestSRData.request_id
    };
    let dialogRef = this.dialog.open(MilesoutComponent, {
      panelClass: 'car-dialog-form',
      width: '800px',
      data: { returnData: false, requestId: this.SRInfo.id, miles: response.data.present_mileage, option: options, confirmationTitle: this.confirmationTitle, car_id: this.SRInfo.car_id, list: response.data.service_request_list, customer_id: this.SRInfo.customer_id, labor_rate: response.data.labor_rate }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.targetColumnName == 'paidColumn') {
          this.showAddPaymentPopUp(cardData);
        } else {
          this.reDrawJobBoard(cardData);
        }
      } else {
        this.reDrawJobBoard(cardData);
      }
    });
   })
  }
 
  showAddPaymentPopUp(cardData: any) {
    let accessLimit = this.globalservice.getAcl('payment');
    let dialogRef = this.dialog.open(PaymentDialogComponent, {
      panelClass: ['payment-dialog'],
      data: { request_id: this.SRInfo.id, accessLimit: accessLimit,fullRequestId: this.latestSRData.request_id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reDrawJobBoard(cardData);
      } else {
        this.reDrawJobBoard(cardData);
      }
      // localStorage.removeItem('fillRecommendationPaymentDialog-'+this.serviceRequestId);
    });
   }

   showAddMileageDialog(row,distanceCovered,actionType,cardData): void {
    let dialogRef = this.dialog.open(AddMileageDialogComponent, {
        panelClass: ['payment-dialog'],
        data: {request_id:row.id, distance_covered:distanceCovered, action_type:actionType}
    });

    dialogRef.afterClosed().subscribe(result => {
           if(result == 1){
           this.reDrawJobBoard(cardData);
          // this.markSRInUnderProgress(cardData);
           }
          // this.markSRUnderProgress(cardData)
    });
}

reDrawJobBoardFormServiceCard(){
    this.getServiceRequestData();
}

  checkDisableColumns(sourescColumnName: any) {
    if (sourescColumnName == 'appointAndPendingColumn') {
      this.disableApptAndPendingCol = true;
      if (this.isAllowed('addNew', 'mark_request_under_pr')) {
        this.disableInProgressCol = true;
      } else {
        this.disableInProgressCol = false;
      }
      this.disableApprovalAwaitedCol = false;
      this.disableWorkInProgressCol = false;
      this.disableInvoicedCol = false;
      this.disablePaidedCol = false;
    } else if (sourescColumnName == 'inProgressColumn') {
      this.disableApptAndPendingCol = false;
      this.disableInProgressCol = true;
      this.disableApprovalAwaitedCol = true;
      this.disableWorkInProgressCol = true;
      this.disableInvoicedCol = true;
      this.disablePaidedCol = true;
    } else if (sourescColumnName == 'approvalAwaitedColumn') {
      this.disableApptAndPendingCol = false;
      this.disableInProgressCol = false;
      this.disableApprovalAwaitedCol = true;
      this.disableWorkInProgressCol = true;
      if (this.isAllowed('views', 'mark_service_complete')) {
        this.disableInvoicedCol = true;
        this.disablePaidedCol = true;
      } else {
        this.disableInvoicedCol = false;
        this.disablePaidedCol = false;
      }
    } else if (sourescColumnName == 'workInProgressColumn') {
      this.disableApptAndPendingCol = false;
      this.disableInProgressCol = false;
      this.disableApprovalAwaitedCol = false;
      this.disableWorkInProgressCol = true;
      if (this.isAllowed('views', 'mark_service_complete')) {
        this.disableInvoicedCol = true;
        this.disablePaidedCol = true;
      } else {
        this.disableInvoicedCol = false;
        this.disablePaidedCol = false;
      }
    } else if (sourescColumnName == 'invoicedColumn') {
      this.disableApptAndPendingCol = false;
      this.disableInProgressCol = false;
      this.disableApprovalAwaitedCol = false;
      this.disableWorkInProgressCol = false;
      if (this.isAllowed('views', 'mark_service_complete')) {
        this.disableInvoicedCol = true;
        this.disablePaidedCol = true;
      } else {
        this.disableInvoicedCol = false;
        this.disablePaidedCol = false;
      }
    }
  }

BackToNormalColorOfAllColumn(){
  this.disableApptAndPendingCol = false;
  this.disableInProgressCol =false;
  this.disableApprovalAwaitedCol = false;
  this.disableWorkInProgressCol =false;
  this.disableInvoicedCol = false;
  this.disablePaidedCol = false;
}

isAllowed(action,module?:any){
  if(typeof module!='undefined'){
      return this.globalservice.isAllowed(this.app,module,action);
  }
    return this.globalservice.isAllowed(this.app,this.modulesName,action);
 }


 lockUnlockServiceRequest(SRInfo:any,lock:any){
  let data={request_id:SRInfo.id,lock_status:lock};
  this.globalservice.callAPI(globalConstants.API_MODIFY_RECOM_LOCK,data).subscribe((res:any)=>{
   if (res.result != 1) {
     this.globalservice.snackbar("error", res.message);
     return false;
   }else{
      return true;
   }
  },(error)=>{});
 }
  openBottomSheet() {
    return this.bottomSheet.open(BottomSheetHelperComponent);
  }

  sendAndCompleteTheSRButton(event:any){
       this.sourescColumnName = event.sourceColumnName;
       this.targetColumnName = event.targetColumnName;
       this.SRInfo = event.serviceRequestData;
       this.getUpdatedDataofSR({});
  }

  showingPendingList(){
    if (this.convertListStatus) {
      this.pendingAndApptListBeforeToggle = this.apptAndPendingList;
      this.apptAndPendingTitleOfColumn = "Appt";
      this.apptAndPendingList = this.apptAndPendingList.filter(srData => (srData.is_appointment == '1' && (srData.status == '1' || srData.status == '2')));
      this.serviceRequestCount.pendingCount = this.apptAndPendingList.length;
    } else {
      this.apptAndPendingTitleOfColumn = "Appt/Pending";
      this.apptAndPendingList = this.pendingAndApptListBeforeToggle;
      this.serviceRequestCount.pendingCount = this.apptAndPendingList.length;
    }
  }

  openOnTheLotJobBoard(){
     this.router.navigate(['shops/boards/on-the-lot']);
  }

  openMyJobBoard(){
    this.router.navigate(['shops/boards/my-workload']);
  }

  handleDeleteSRCardWithoutDrawBoard(event:any){
    if(event.columnName == 'appointAndPendingColumn'){
      this.apptAndPendingList.splice(this.apptAndPendingList.findIndex(function(i){
        return i.id === event.serviceReqId;
      }), 1);
      this.serviceRequestCount.pendingCount = this.apptAndPendingList.length;
    } else if(event.columnName == 'inProgressColumn'){
      this.inProgressList.splice(this.inProgressList.findIndex(function(i){
        return i.id === event.serviceReqId;
      }), 1);
      this.serviceRequestCount.inProgressCount = this.inProgressList.length;
    } else if(event.columnName == 'approvalAwaitedColumn'){
      this.approvalAwaitedList.splice(this.approvalAwaitedList.findIndex(function(i){
        return i.id === event.serviceReqId;
      }), 1);
      this.serviceRequestCount.approveWaitCount = this.approvalAwaitedList.length;
    }else if(event.columnName == 'workInProgressColumn'){
      this.workInProgressList.splice(this.workInProgressList.findIndex(function(i){
        return i.id === event.serviceReqId;
      }), 1);
      this.serviceRequestCount.workInProgressCount = this.workInProgressList.length;
    }else if(event.columnName == 'invoicedColumn'){
      this.invoicedList.splice(this.invoicedList.findIndex(function(i){
        return i.id === event.serviceReqId;
      }), 1);
      this.serviceRequestCount.invoiceCount = this.invoicedList.length;
    }else if(event.columnName == 'paidColumn'){
      this.paidList.splice(this.paidList.findIndex(function(i){
        return i.id === event.serviceReqId;
      }), 1);
      this.serviceRequestCount.paidCount = this.paidList.length;
    }
  }

  handleReOpenSRWithoutDrawBoard(event:any){
      var SRData = this.paidList.filter(data => data.id == event.serviceReqId);
      if(event.columnName == 'paidColumn'){
        var SRData = this.paidList.filter(data => data.id == event.serviceReqId);
        this.paidList.splice(this.paidList.findIndex(function(i){
          return i.id === event.serviceReqId;
        }), 1);
        this.serviceRequestCount.paidCount = this.paidList.length;
        SRData[0].status = '2';
        SRData[0].access_timestamp = event.access_timestamp;
        this.inProgressList.unshift(SRData[0]);
      } else {
        var SRData = this.invoicedList.filter(data => data.id == event.serviceReqId);
        this.invoicedList.splice(this.invoicedList.findIndex(function(i){
          return i.id === event.serviceReqId;
        }), 1);
        this.serviceRequestCount.invoiceCount = this.invoicedList.length;
        SRData[0].status = '2';
        SRData[0].access_timestamp = event.access_timestamp;
        this.inProgressList.unshift(SRData[0]);
      }
  }

  ngOnDestroy(){
    this.globalservice.getSetItems('jobBoardFilterEmpId','');
  }

}
