<div mat-dialog-title class="relative">
  <span class="title-span">Change Customer & Vehicle</span>
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0" *ngIf="selectedVehicleInfo == null">
      <mat-icon  class="notranslate">close</mat-icon>
    </button>
</div>
<mat-dialog-content>
  <div class="panel panel-primary">
    <div class="panel-heading">
      <h3 class="panel-title">
        Current Customer Information
      </h3>
    </div>
    <div class="panel-body bgcolor-lightgrey customerSelection-form">
          <div class="row margin-0">
          <div class="alert alert-warning">
            <p>You are working on #<label>{{currentCustomerData.carRequestId}}</label> and this SR is created for below customer/vehicle profile.</p>
          </div>
          </div>
          <div class="row margin-bottom-10">
              <div class="col-sm-3"><span class="font-bold">Customer:</span></div>
              <div class="col-sm-9 padding-top-5"><span>{{currentCustomerData.firstName}} {{currentCustomerData.lastName}}</span>
              <span>{{currentCustomerData.isWalkinCustomer == '0'?' / '+currentCustomerData.mobilePhone:''}}</span>
              </div>
          </div>
        <div class="row">
          <div class="col-sm-3"><span class="font-bold">Vehicle:</span></div>
          <div class="col-sm-9 padding-top-5"><span>{{globalService.getGlobalVehicleName(currentCustomerData) || 'N/A'}}</span></div>
        </div>
    </div>
  </div>
  <div class="panel panel-primary customer-selection-panel">
    <div class="panel-heading">
      <h3 class="panel-title">
        Customer Selection
      </h3>
      <div class="btn-wrap">
       <div *ngIf="createCustomerScreenState == 1 || createCustomerScreenState == 2">
          <button mat-button title="Search Customer" [disabled]="createCustomerScreenState == 1"  (click)="handleAddOrSearchCustomerScreen(1)" class="btn-list list-action-btn clear-btn-accent search-btn">
            <span><i class="fa fa-fw fa-search"></i></span><span class="hidden-xs"> Search</span>
          </button>
          <button mat-button title="Add New Customer" [disabled]="createCustomerScreenState == 2 && isAllowed('customer','addNew')"  (click)="handleAddOrSearchCustomerScreen(2)" class="btn-list list-action-btn clear-btn-accent">
            <span><i class="fa fa-fw fa-plus"></i></span><span class="hidden-xs"> Add</span>
          </button>
        </div>

        <button mat-button title="Search Customer" *ngIf="createCustomerScreenState == 3"  (click)="goToCustomerSearchScreen(1)" class="btn-list list-action-btn clear-btn-accent search-btn">
          <span><i class="fa fa-fw fa-search"></i></span><span class="hidden-xs"> Search</span>
        </button>
        <button mat-button title="Add New Customer" *ngIf="createCustomerScreenState == 3"  (click)="goToCustomerSearchScreen(2)" class="btn-list list-action-btn clear-btn-accent">
          <span><i class="fa fa-fw fa-plus"></i></span><span class="hidden-xs"> Add</span>
        </button>
      </div>
    </div>
    <div class="panel-body bgcolor-lightgrey customerSelection-form">
      <div class="row margin-0 search-wrap" *ngIf="createCustomerScreenState == 1">
          <div class="wrap">
            <h4>
              Search Existing Customer:
            </h4>
            <form class="row" [formGroup]="searchCustomerForm" (submit)="search()" novalidate method="POST" enctype="multipart/form-data"
              autocomplete="off">
              <div class="form-group col-sm-6">
                  <label>First/Last/Business Name</label>
                  <mat-form-field class="car-input " floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                    <div class="relative">
                      <input matInput placeholder="First/Last/Business Name" title="First/Last/Business Name"
                        class="form-control input-lg custom-form-input" formControlName="last_name" maxlength="200"/>
                    </div>
                  </mat-form-field>
              </div>
              <div class="form-group col-sm-6">
                  <label>License Plate</label>
                  <mat-form-field class="car-input" floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                    <div class="relative">
                      <input matInput placeholder="License Plate" title="License Plate"
                        class="form-control input-lg custom-form-input" formControlName="plate" maxlength="200"/>
                    </div>
                  </mat-form-field>
              </div>
              <div class="form-group col-sm-6">
                  <label>Mobile Number</label>
                  <mat-form-field class="car-input " floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                    <div class="relative">
                      <input matInput placeholder="Mobile Number" title="Customer Mobile Number"
                        [textMask]="{mask: mobileMask}" 
                        formControlName="mobile" class="form-control input-lg custom-form-input" />
                    </div>
                    <mat-error *ngIf="searchCustomerForm.controls.mobile.hasError('pattern')">
                      Invalid phone number
                    </mat-error>
                  </mat-form-field>
              </div>
                <div class="form-group col-sm-6">
                  <label>Contact Name</label>
                  <mat-form-field class="car-input " floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                    <div class="relative">
                      <input matInput placeholder="Contact Name" title="Contact Name"
                        class="form-control input-lg custom-form-input" formControlName="contact" maxlength="200"/>
                    </div>
                  </mat-form-field>
              </div>
              <div class="col-sm-12 margin-bottom-20" *ngIf="showCustomerSearchError"><span class="mat-error"> Please provide input for at least one field. </span></div>
              <div class="col-xs-12 col-sm-2">
                <div class="">
                  <button type="submit" 
                    class="font-bold submit-btn btn-block mat-elevation-z0 mat-raised-button mat-accent"
                    title="Submit">Submit</button>                 
                </div>
              </div>
            </form>
          </div>
      </div>
      <div class="row margin-0 customer-wrap" *ngIf="createCustomerScreenState == 2"> 
        <div class="wrap">
          <h4>
            Add New Customer:
          </h4>
          <form class="row" (ngSubmit)="addNewCustomer()" [formGroup]="addNewCustomerForm" autocomplete="off">
            <div class="form-group col-sm-6 col-md-6">
              <label>First Name</label>
              <mat-form-field class="car-input " floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                <div class="relative">
                  <input matInput placeholder="First Name*" title="First Name" formControlName="first_name"
                    class="form-control input-lg custom-form-input" maxlength="200"/>
                </div>
                <mat-error *ngIf="addNewCustomerForm.controls.first_name.hasError('required')">
                  First name cannot be empty
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group col-sm-6 col-md-6">
              <label>Last Name</label>
              <mat-form-field class="car-input " floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                <div class="relative">
                  <input matInput placeholder="Last Name*" title="Last Name" formControlName="last_name"
                    class="form-control input-lg custom-form-input" maxlength="200"/>
                </div>
                <mat-error *ngIf="addNewCustomerForm.controls.last_name.hasError('required')">
                  Last name cannot be empty
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group col-sm-6 col-md-6">
              <label>Email Address</label>
              <mat-form-field class="car-input " floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                <div class="relative">
                  <input matInput placeholder="Email Address* (Optional)" title="Email" formControlName="email"
                    class="form-control input-lg custom-form-input" 
                    (blur)="handleChangeEmailAddress()" maxlength="200"/>
                </div>
                <mat-error *ngIf="addNewCustomerForm.controls.email.hasError('pattern')">
                  Please enter a valid email address
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group col-sm-6 col-md-6">
              <label>Mobile Number</label>
              <mat-form-field class="car-input " floatPlaceholder="never" appearance="fill">
                <div class="relative">
                  <input matInput placeholder="Mobile Number*" title="Mobile Number" formControlName="mobile"
                    [textMask]="{mask: mobileMask}" (blur)="handleChangeMobileNumber()" 
                    class="form-control input-lg custom-form-input" />
                </div>
                <mat-error *ngIf="addNewCustomerForm.controls.mobile.hasError('required')">
                  Mobile number cannot be empty
                </mat-error>
                <mat-error *ngIf="addNewCustomerForm.controls.mobile.hasError('pattern')">
                  Please enter a valid mobile number
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-2">
              <div class="">
                <button type="submit" class="font-bold submit-btn btn-block mat-elevation-z0 mat-raised-button mat-accent"
                     title="Submit">Submit</button>                  
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="pointer margin-0" *ngIf="createCustomerScreenState == 3">
        <div class="row info-view-wrap" *ngFor="let customer of customersList">
         <div class="col-sm-9 ">
          <div class="row margin-bottom-10">
            <div class="col-sm-4"><span class="font-bold">Name:</span></div>
            <div class="col-sm-8 break-all"><span> {{customer.first_name}} {{customer.last_name}}</span></div>
          </div>
          <div class="row margin-bottom-10">
            <div class="col-sm-4">
              <span class="font-bold">Email:</span>
            </div>
            <div class="col-sm-8 break-all">
              <span><a class="text-black" href="mailto:{{customer.email}}">{{customer.email}}</a></span>
            </div>
          </div>
          <div class="row" *ngIf="customer.is_walkin_customer == '0'">
            <div class="col-sm-4">
              <span class="font-bold">Mobile Number:</span>
            </div>
            <div class="col-sm-8">
              <span>
                <a class="text-black" href="tel:{{customer.mobile_phone}}">{{customer.mobile_phone !="" ?
                  customer.mobile_phone : "-"}}</a>
              </span>
            </div>
          </div>
         </div>
         <div class="col-sm-3">
          <div class="edit-customer-btn-wrap text-right">
            <button mat-button title="Select Customer" *ngIf="!checkVehicleUpdate" (click)="getCustomerVehicles(customer,true)"
              class="mat-elevation-z0 mat-raised-button mat-accent edit-customer-btn">
              <span class="">
                Select
              </span>
            </button>
          </div>
         </div>   
        </div>
      </div>
    </div>
  </div>
  <div class="" *ngIf="checkVehicleUpdate ">
    <app-customer-vehicle-section *ngIf="checkVehicleUpdate" [importVehicleList]="vehiclesList" [importCustomerData]="customersList" [importVehicleId]="currentCustomerData.vehicleId" [importLicensePlate]="searchCustomerForm.value.plate" [viaSearchVehicles]="isViaVehiclesSearch" (SelectedVehicleInfo)="handleVehicleSection($event)"  (SearchVehicleObj)="handleVehicleSearch($event)"></app-customer-vehicle-section>
      <div class="row submit-section" *ngIf="selectedVehicleInfo !== null">
          <div class="col-sm-6 margin-sm-bottom-20">
            <button class="car-btn btn-block btn-lg font-bold" color="accent"  mat-raised-button (click)="goToCustomerSearchScreen(1)">Startover</button>
          </div>
          <div class="col-sm-6 text-right">
            <button class="car-btn btn-block btn-lg btn-success font-bold"  mat-raised-button *ngIf="vehiclesList.length>0" (click)="handleCustomerVehicleConfirm()">Confirm</button>
          </div>
      </div>
  
  </div>
</mat-dialog-content>