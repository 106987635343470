<div class="panel panel-primary">
    <div class="panel-heading padding-left-30">
        <h3 class="panel-title">RATINGS FOR SERVICE REQUEST <span><u>#{{requestId}}</u></span></h3>
        <button mat-mini-fab class="custom-btn-back" (click)="gs.goBack();">
        <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <div class="col-xs-12" autocomplete="off">
            <div id="customerFeedBack" class="form-inline col-xs-12">
                <div class="col-xs-12 form-block padding-top-25 padding-bottom-25 rating-form">
                    <div class="row" *ngFor="let question of feedbackQuestion">
                        <div class="form-group col-lg-6">
                            <label>{{question.question}}</label>
                            <bar-rating [(rate)]="question.rating" [readOnly]="true" [max]="5" [theme]="'stars'" title="{{questionInfo[question.id].title}}"></bar-rating>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-6">
                            <label for="feedbackComment">Comment :</label>
                            <p>{{questionInfo.feedback_comment}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
