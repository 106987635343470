<div class="panel panel-primary" id="mainCompotents">
  <div class="panel-heading padding-left-15">
    <h3 class="panel-title">STOCK ORDER DETAILS</h3>
    <button mat-mini-fab class="custom-btn-back" title="Back" (click)="globalService.goBack();">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <!---->
  <div class="panel-body bgcolor-lightgrey">
  <div class="jumbotron inspection-report padding-25 car-view-str margin-bottom-15">
    <h3 class="margin-top-0">Stock Order Information</h3>
    <hr>
    <div class="row">
      <div class="col-sm-6 margin-bottom-10">
        <div class="row">
          <div class="col-sm-6"><label>Order ID :</label></div>
          <div class="col-sm-6">
            <span>{{stockOrderDetails.value.id}}</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6 margin-bottom-10">
        <div class="row">
          <div class="col-sm-6"><label>Order Date : </label></div>
          <div class="col-sm-6 break-word">
            <span>{{stockOrderDetails.value.add_date}}</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6 margin-bottom-10">
        <div class="row">
          <div class="col-sm-6"><label>Sub Total :</label></div>
          <div class="col-sm-6">
            <span>${{stockOrderDetails.value.sub_total}}</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6 margin-bottom-10">
        <div class="row">
          <div class="col-sm-6"><label>Tax Charges:</label></div>
          <div class="col-sm-6 break-word">
            <span>${{stockOrderDetails.value.tax_total}}</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6 margin-bottom-10">
        <div class="row">
          <div class="col-sm-6"><label>Delivery Charges :</label></div>
          <div class="col-sm-6 break-word">
          <span>${{stockOrderDetails.value.delievery_total}}</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6 margin-bottom-10">
        <div class="row">
          <div class="col-sm-6"><label>Total Order Amount :</label></div>
          <div class="col-sm-6 break-word">
          <span>${{stockOrderDetails.value.order_total}}</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6 margin-bottom-10" *ngIf="stockOrderDetails.value.que_name">
          <div class="row">
            <div class="col-sm-6"><label>Queue Name :</label></div>
            <div class="col-sm-6 break-word">
            <span>{{stockOrderDetails.value.que_name}}</span>
            </div>
          </div>
        </div>
    </div>
  </div>
  <div class="display-full relative">
      <div class="car-datatable uc-datatable mat-elevation-z1">
          <div class="table-responsive relative">
            <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg uc-table-responsive">
              <ng-container matColumnDef="name">
                  <mat-header-cell  *matHeaderCellDef mat-sort-header data-role="auto">Name</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-role="auto" data-val="Name"> {{row.name}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="partstech_part_number">
                  <mat-header-cell  *matHeaderCellDef mat-sort-header data-role="auto">Number</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-role="auto" data-val="Number"> {{row.partstech_part_number}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="part_quantity">
                  <mat-header-cell  *matHeaderCellDef mat-sort-header data-role="auto">Qty</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-role="auto" data-val="Qty"> {{row.part_quantity}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="part_price">
                  <mat-header-cell  *matHeaderCellDef mat-sort-header data-role="auto">Cost</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-role="auto" data-val="Cost"> {{row.part_price}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="list_price">
                  <mat-header-cell  *matHeaderCellDef mat-sort-header data-role="auto">Price</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-role="auto" data-val="Price"> {{row.list_price}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="part_manufacturer">
                  <mat-header-cell  *matHeaderCellDef mat-sort-header data-role="auto">Manufacturer</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-role="auto" data-val="Manufacturer"> {{row.part_manufacturer}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="distributor_name">
                  <mat-header-cell  *matHeaderCellDef mat-sort-header data-role="auto">Distributor Name</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-role="auto" data-val="Distributor Name"> {{row.distributor_name}} </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <div class="padding-15 text-center"
                         [style.display]="getTotalRecords() < 1 ? '' : 'none'">
                         No Records Available
                </div>
          </div>
          <div class="display-full relative">
                <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="options.limit" [pageSizeOptions]="[5, 10,25, 100]" (page)="paginateByLimit($event)" >
                </mat-paginator>
    
                <div class="mat-paginator table-paging">
                    <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
                    <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                            <mat-icon>navigate_before</mat-icon>
                    </button>
                    <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                            <mat-icon>navigate_next</mat-icon>
                    </button>
                </div>
            </div>
      </div>
  </div>
</div>
</div>
