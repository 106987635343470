import { Component, OnInit, ViewChild, Input,  OnChanges, SimpleChanges } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { GlobalService } from 'src/environments/global.service';
import { globalConstants } from 'src/environments/globalConstants';


@Component({
  selector: 'app-common-sidepanel-drawer',
  templateUrl: './common-sidepanel-drawer.component.html',
  styleUrls: ['./common-sidepanel-drawer.component.scss']
})
export class CommonSidepanelDrawerComponent implements OnInit {
  @ViewChild('drawer') drawer: MatDrawer;
  @Input() guideType: string;
  public panelOpenState = false;
  public guideTitle: string = '';
  public isContainerDataReady: boolean = false;
  public containerData: any[] = [];
  public currentUser: any;
  public subscriptionDataForAPI: Subscription = new Subscription();
  public eLearnData: any;
  constructor(private sanitizer: DomSanitizer,private globalService: GlobalService,public app: AppComponent ) {}
  
  ngOnInit(): void {
    this.currentUser = this.globalService.getCurrentUser();
    this.setContainerData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.guideType && !changes.guideType.isFirstChange()) {
      this.setContainerData(); // Reset the accordion data when guideType changes
    }
  }

  setContainerData(): void {
    this.isContainerDataReady = false;  // Set to false initially
    setTimeout(() => {
      if (this.guideType === 'video') {
        this.guideTitle = 'Video Guide';
        this.containerData = [
          {
            title: "Overview Walkthrough Video",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/ZdqjoF351J0'),
            expanded: true,
          },
          {
            title: "Add New Employee Log In",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/E1ngsfV25U8'),
            expanded: false,
          },
          {
            title: "Adding New Customer",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/v-AfBkGgEtM'),
            expanded: false,
          },
          {
            title: "Approval Stages: Waiting For Approval - Approval Acquired - Work in Progress",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/U0Lb3G33py8'),
            expanded: false,
          },
          {
            title: "Bulk Upload List",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/_7f_llrglug'),
            expanded: false,
          },
          {
            title: "Calendar Overview",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/47CsVwXTcz4'),
            expanded: false,
          },
          {
            title: "Closing Tickets and Payments",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/0mY7ZwzR4es'),
            expanded: false,
          },
          {
            title: "Computer Codes",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/Mo4mLlK2uVM'),
            expanded: false,
          },
          {
            title: "Coupons",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/MJHPrjfagPM'),
            expanded: false,
          },
          {
            title: "Create Service Requests",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/lpZnbTE040Q'),
            expanded: false,
          },
          {
            title: "Customer and Shop Messages and Live Chat",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/oS960cfD2-Y'),
            expanded: false,
          },
          {
            title: "Dashboard Video",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/Y8lTvyE3848'),
            expanded: false,
          },
          {
            title: "Disclaimers",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/QP25XfsnIrg'),
            expanded: false,
          },
          {
            title: "Edit – Remove Job Category",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/t7DGvDBWBug'),
            expanded: false,
          },
          {
            title: "Employee Assignment Tools",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/4eK0-2sktc0'),
            expanded: false,
          },
          {
            title: "Employee Permissions",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/kLcZmrSMpjE'),
            expanded: false,
          },
          {
            title: "Fault codes",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/yPGSuw2yI4A'),
            expanded: false,
          },
          {
            title: "Fill Recommendation",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/9abEN1LA0rc'),
            expanded: false,
          },
          {
            title: "Generic Jobs New and List",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/UOGJD0if0TE'),
            expanded: false,
          },
          {
            title: "Importing Customer Lists",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/fiDsgYVvOKc'),
            expanded: false,
          },
          {
            title: "Internal Notes",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/nXeAgd4ZXq4'),
            expanded: false,
          },
          {
            title: "Job Board",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/fu12cULS6VI'),
            expanded: false,
          },
          {
            title: "Labor Rate",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/VTWNi8ZCjnU'),
            expanded: false,
          },
          {
            title: "Labor Rates",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/CI3WWBbf5Rc'),
            expanded: false,
          },
          {
            title: "MailChimp",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/j1Wdwidq5H4'),
            expanded: false,
          },
          {
            title: "Managing Sublet Services in CAR Software",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/vm0ItZw7VhY'),
            expanded: false,
          },
          {
            title: "My Account Profile and Company profile",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/RoB19fRdMyE'),
            expanded: false,
          },
          {
            title: "New Part Inventory",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/-8DdM214KkY'),
            expanded: false,
          },
          {
            title: "Nexpart Parts Vendor Integration",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/pFW0hc-ocd4'),
            expanded: false,
          },
          {
            title: "On The Lot",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/VaIU5Sv0Afs'),
            expanded: false,
          },
          {
            title: "Parts List",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/lK0KoBFvJ5s'),
            expanded: false,
          },
          {
            title: "Parts Matrix",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/_LspmcKbszU'),
            expanded: false,
          },
          {
            title: "Partstech Integration",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/MKIJpuODdAo'),
            expanded: false,
          },
          {
            title: "Recovering and Reopening Service Requests in CAR",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/EnQwZLMhLsM'),
            expanded: false,
          },
          {
            title: "Return Queue",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/eKDbZxoUnZA'),
            expanded: false,
          },
          {
            title: "Sales Over Due Reports and Employee logs",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/BEl25yxER7Q'),
            expanded: false,
          },
          {
            title: "Smart Batch",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/Jupe1TYBrgo'),
            expanded: false,
          },
          {
            title: "Stock Order",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/MHnYUf-vpUw'),
            expanded: false,
          },
          {
            title: "Upgrade Account",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/sP3ay2mAmoE'),
            expanded: false,
          },
          {
            title: "Vendor and Resources",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/MHAh2v2h17U'),
            expanded: false,
          },
          {
            title: "Vehicle Inspection Expiration List",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/wsUPhfbvnzI'),
            expanded: false,
          },
          {
            title: "View Service Request List",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/TszyjWUboZo'),
            expanded: false,
          },
          {
            title: "View/Search for all Customers",
            videoUrl: this.sanitizeUrl('https://www.youtube.com/embed/ynaK5XazmSg'),
            expanded: false,
          },
        ];        
      } else if (this.guideType === 'help') {
        this.guideTitle = 'Help Guide';
        let data = { opr: 1, user_id: this.currentUser.id, user_type: this.currentUser.user_type };
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_E_LEARNING, data).subscribe((r: any) => {
          if (r.result == '1') {
            this.eLearnData = r.data;
          }
        }, (error: any) => { })
      }
      this.isContainerDataReady = true;  // Data is ready, now render
    }, 50);
  }

   // Method to sanitize the video URL
   sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  // Method to open the drawer
  openDrawer() {
    this.drawer.toggle();
  }

  // Method to close the drawer if needed
  closeSideBar() {
    this.drawer.toggle();
  }
 
  //open learn portal pop-up
  eLearnPortal(e){
    this.app.eLearnPortal(e);
  }
}
