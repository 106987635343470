<div class="panel panel-primary" id="mainCompotents">
    <div class="panel-heading padding-left-30">
        <h3 class="panel-title">EDIT SERVICE FACILITY</h3>
        <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
            <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <div id="signupContent" class="row bgcolor-lightgrey">
            <div class="container-fluid">
                <form class="form-inline col-xs-12 padding-bottom-15" [formGroup]="addServiceFacility" novalidate (ngSubmit)="editServiceFacility()">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block">
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label for="txtShopName">Name</label>
                                <div class='relative'>
                                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                                        <input matInput placeholder="Name" title="Name" class="form-control input-lg custom-form-input" maxlength="255"  formControlName="facility_name" (keyup)="checkFormValid()"/>
                                        <mat-error *ngIf="addServiceFacility.controls.facility_name.hasError('required')">
                                            Name is <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                    <a href="javascript:void(0);" color="accent" class="others-link make-link" (click)="resetShopName()" *ngIf="currentShopID!=0">Reset Name</a>
                                    <!--<input type="text" class="form-control input-lg custom-form-input" id="txtShopName" name="facility_name" placeholder="Name" maxlength="255" data-validation="[NOTEMPTY]">-->
                                </div>
                                
                            </div>
                            <div class="form-group col-lg-6">
                                <label for="txtShopEmail">Email</label>
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                                    <input matInput placeholder="Email" title="Email" class="form-control input-lg custom-form-input" maxlength="255"  formControlName="facility_email" (keyup)="checkFormValid()"/>
                                    <mat-error *ngIf="addServiceFacility.controls.facility_email.hasError('required')">
                                        Email is <strong>required</strong>
                                    </mat-error>
                                    <mat-error *ngIf="addServiceFacility.controls.facility_email.hasError('pattern')">
                                        Please enter a valid email address
                                    </mat-error>
                                </mat-form-field>
                                <!--<input type="email" class="form-control input-lg custom-form-input" id="txtShopEmail" name="facility_email" placeholder="Email" maxlength="255" data-validation="[NOTEMPTY, EMAIL]">-->
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label for="txtShopAddress">Address</label>
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                                    <input matInput placeholder="Address" title="Address" class="form-control input-lg custom-form-input" maxlength="255"  formControlName="facility_address"/>
                                </mat-form-field>
                                <!--<input type="text" class="form-control input-lg custom-form-input" id="txtShopAddress" name="facility_address" placeholder="Address" maxlength="255">-->
                            </div>
                            <div class="form-group col-lg-6 uc-ngx-select">
                                <label for="txtShopCity">Country</label>
                                <ngx-select class="display-full margin-bottom-20" 
                                    [items]="countries" 
                                    [(ngModel)]="defaultCountryVaue" 
                                    [ngModelOptions]="{standalone: true}"
                                    (select)="getSelectedOptionCountry($event)"
                                    [disabled]="disableCountries"
                                    placeholder="Select Country">
                                </ngx-select>
                               
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label for="txtShopZip">{{zipLabel}}</label>
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                                    <input matInput placeholder="{{ zipLabel }}" title="{{ zipLabel }}" #facility_zip (input)="facility_zip.value = facility_zip.value.toUpperCase()" class="form-control input-lg custom-form-input" formControlName="facility_zip" maxlength="15"/>
                                    <mat-error *ngIf="addServiceFacility.controls.facility_zip.hasError('pattern')">
                                        Please enter valid {{zipLabel.toLowerCase()}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-lg-6 uc-ngx-select">
                                <label for="txtShopState">State</label>
                                <ngx-select class="display-full margin-bottom-20" 
                                    [items]="states" 
                                    [(ngModel)]="defaultStateVaue" 
                                    [defaultValue]="stateBlank"
                                    [ngModelOptions]="{standalone: true}" 
                                    (select)="getSelectedOptionState($event)"
                                    [disabled]="disableStates"
                                    placeholder="Select State">
                                </ngx-select>                                
                            </div>                          
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6 uc-ngx-select">
                                <label for="txtShopCity">City</label>
                                <ngx-select class="display-full margin-bottom-20" 
                                    [items]="vcities" 
                                    [(ngModel)]="selectedCity" 
                                    [ngModelOptions]="{standalone: true}" 
                                    [defaultValue]="cityBlank"
                                    (select)="getSelectedOptionCity($event)"
                                    [disabled]="disableCities"
                                    placeholder="Select City">
                                </ngx-select>                               
                            </div>
                            <div class="form-group col-lg-6">
                                <label for="txtShopPhone">Phone Number</label>
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                                    <input matInput placeholder="Phone Number" title="Phone Number" class="form-control input-lg custom-form-input" maxlength="15"  formControlName="facility_phone" maxlength="15" [textMask]="{mask: phoneMask}"  (keyup)="checkFormValid();phoneValidation(addServiceFacility,'facility_phone')"/>
                                    <mat-error *ngIf="addServiceFacility.controls.facility_phone.hasError('required')">
                                        Phone Number is <strong>required</strong>
                                    </mat-error>
                                    <mat-error *ngIf="addServiceFacility.controls.facility_phone.hasError('facility_phone')">
                                        Invalid phone number
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label for="txtShopUrl">Website URL</label>
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                                    <input matInput placeholder="Website URL" title="Website URL" class="form-control input-lg custom-form-input" formControlName="facility_weburl" maxlength="255"/>
                                    <mat-error *ngIf="addServiceFacility.controls.facility_weburl.hasError('pattern')">
                                        Invalid website URL
                                    </mat-error>
                                </mat-form-field>
                                
                                <!--<input type="text" class="form-control input-lg custom-form-input" id="txtShopUrl" name="facility_weburl" placeholder="Website URL" data-validation="[OPTIONAL]" data-validation-regex="/^((https?|ftp)\:\/\/)?([a-z0-9+!*(),;?&amp;=\$_.-]+(\:[a-z0-9+!*(),;?&amp;=\$_.-]+)?@)?([a-z0-9-.]*)\.([a-z]{2,4})(\:[0-9]{2,5})?(\/([a-z0-9+\$_-]\.?)+)*\/?(\?[a-z+&amp;\$_.-][a-z0-9;:@&amp;%=+\/\$_.-]*)?(#[a-z_.-][a-z0-9+\$_.-]*)?$/i" data-validation-regex-message="Invalid url." maxlength="255">-->
                            </div>
                        </div>
                        <div class="row">
                            <div class="checkbox form-group col-lg-6">
                                <mat-checkbox formControlName="facility_isdefault"  labelPosition="after"(change)="setDefaultSevice($event)" [checked]="serviceFacilityCheck">Set as Default Service Facility</mat-checkbox>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block submit-block">
                        <div class="row">
                            <div class="col-xs-12 col-md-6 col-lg-3 form-block submit-block">
                                <button type="submit" mat-raised-button class="car-btn btn-block text-uppercase  btn-lg font-bold fontcolor-white" color="accent" title="Submit" [disabled]="submitted">SUBMIT</button>
                            </div>
                            
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>