<div class="car-panel" id="mainCompotents">

    <div class="panel panel-primary">
        <div class="panel-heading padding-left-15">
            <h3 class="panel-title">
                Manage Connections - MailChimp
            </h3>
        </div>
        <div class="panel-body bgcolor-lightgrey mailChimp">

            <div  class="row bgcolor-lightgrey form-content" *ngIf="isSubscribed== 'false'">
                <div class="container-fluid" style="text-align: center;font-size: 30px;">
                    Please subscribe to use Mailchimp.
                </div>
                <div class="padding-bottom-15 col-xs-12" style="text-align: center;">
                    <button type="button" mat-raised-button class="mat-success font-bold mat-elevation-z1" (click)="gotoShopSubscription()" style="background-color: #f50057;">Subscribe Now</button>
                </div>
            </div>
    
            <div  class="row bgcolor-lightgrey form-content padding-top-45" *ngIf="isSubscribed== 'true' && mailchimpStatus!='1'">
                <div class="container-fluid  padding-bottom-15" style="text-align: center;font-size: 22px; color:#a94442;">
                    Upgrade your plan to add MailChimp Connection.
                </div>
                <div class="padding-bottom-45 col-xs-12" style="text-align: center;padding-top: 10px;">
                    <button type="button" mat-raised-button class="mat-success font-bold mat-elevation-z1" (click)="gotoShopSubscription()" style="background-color: #f50057;">Upgrade Now</button>
                </div>
            </div>
    
            <!-- Add Customer page start -->
            <div  class="row bgcolor-lightgrey form-content" *ngIf="isSubscribed== 'true' && mailchimpStatus=='1'">
                <div class="col-xs-12">
                    <button type="button" mat-raised-button class="mat-success font-bold mat-elevation-z1" (click)="syncShopMailchimpCustomerList()" *ngIf="currentAPIDetails">Sync Customer List</button>
                </div>
                <div class="container-fluid">
                    <form [formGroup]="mailchipConnection" (ngSubmit)="editMailchimp()" id="changePass" class="form-inline display-full padding-bottom-15" enctype="multipart/form-data">
                        <div class="col-xs-12 form-block">
                            <div class="row">
                                <div class="form-group  col-sm-12 col-md-6">
                                    <label for="api_key">API Key</label>
                                    <div class='relative'>
                                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                            <input matInput placeholder="API Key*" title="API Key" formControlName="api_key"  class="form-control input-lg custom-form-input" [(ngModel)]="API_key" />
                                            <mat-error *ngIf="mailchipConnection.controls.api_key.hasError('required')">
                                                This field cannot be empty
                                            </mat-error>
                                        </mat-form-field>
                                        <p class="help-block">Enter API key got from mailchimp.com</p>
                                    </div>
                                </div>
                            </div>
    
                            <div class="row">
                                <div class="form-group  col-sm-12 col-md-6">
                                    <label for="cost">Customer List ID</label>
                                    <div class='relative'>
                                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                            <input matInput placeholder="Customer List ID*" title="Customer List ID" formControlName="customerlist_id"  class="form-control input-lg custom-form-input" [(ngModel)]="customerListID"/>
                                            <mat-error *ngIf="mailchipConnection.controls.customerlist_id.hasError('required')">
                                                This field cannot be empty
                                            </mat-error>
                                        </mat-form-field>
                                        <p class="help-block">Enter Customer List Id got from mailchimp.com</p>
                                    </div>
                                </div>
                            </div>
    
                            <div class="row">
                                <div class="col-xs-12 form-block submit-block">
                                    <button type="submit" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Submit" style="max-width: 300px;">
                                        SUBMIT
                                    </button>
                                </div>
                            </div>
    
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

  
</div>
