<div class="panel panel-primary service-request-view">
  <div class="panel-heading padding-left-15">
    <h3 class="panel-title">Service Request Detail</h3>
    
    <button mat-mini-fab class="custom-btn-back" (click)="goBack();">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <div class="quick-action" *ngIf="serviceDetail">
    <div class="quick-action-icon" (touchstart)="onQuickActionIconClick()"><i class="fa-solid fa-gear"></i></div>
    <app-quickaction [viewServiceInfo]="serviceDetail" [parent]="this" [isAddInPayment]="isAddInPayment" (onTicketAssignment)="onTicketAssignment($event)" (couponAdded)="onCouponAdded($event)" (paymentAdded)="OnPaymentAdded($event)"></app-quickaction>
  </div>
  <div class="panel-body bgcolor-lightgrey viewService-request">
    <div class="jumbotron padding-15 car-view-str">
      <div class="row">
        <div class="col-sm-6">
          <h3 class="font-24 servicce-info-text">
            Service Request Information
          </h3>
        </div>
        <div class="col-sm-6 text-right top-row-checkbox">
          <mat-checkbox (change)="updateOnTheLotStatus($event)" [checked]="serviceInfo.value.on_the_lot" #onTheLotCheckbox>On The Lot</mat-checkbox>&nbsp;&nbsp;
          
          <!-- <mat-checkbox [class.hide-element]="serviceInfo.value.request_status!='6' && serviceInfo.value.request_status!='8'" (change)="watingForPart($event)" [checked]="serviceInfo.value.parts_awaited" #waitingForPartsCheckbox>Waiting For Parts</mat-checkbox> -->
          <mat-checkbox [class.hide-Accountant-verification]="!waitingForPartCheckBoxStatus" (change)="updateWaitingForPartStatus($event)" [checked]="serviceInfo.value.parts_awaited" #waitingForPartsCheckbox>Waiting For Parts</mat-checkbox>
          <mat-checkbox *ngIf="isAllowed('edits','account_verification') && (serviceInfo.value.request_status=='6' || serviceInfo.value.request_status=='8')" (change)="updateAccountVerifyStatus($event)" [checked]="serviceInfo.value.accountant_verification" #accountantVerificationCheckbox>Accountant Verified</mat-checkbox>
      </div>
        <div class="col-sm-12 text-right sr-action-btns">
          <div class="icon-block" *ngIf="taskTrack && (userTaskTrack==0 || userTaskTrack==2)">
            <a href="javascript:;" class="btn btn-text-wrap" [title]="userTaskTrack==0 ? 'Start' : 'Resume'" (click)="taskTracking(1)"  >
              <span *ngIf="userTaskTrack==0"><i class="fa fa-solid fa-play start"></i></span>
              <span *ngIf="userTaskTrack==2"><i class="fa fa-solid fa-play resume"></i></span>
            </a>
            <span>
              <p *ngIf="userTaskTrack==0" class="help-word">Start</p>
              <p *ngIf="userTaskTrack==2" class="help-word">Resume</p>
            </span>
          </div>
          <div class="icon-block" *ngIf="taskTrack && userTaskTrack=='1'">
            <a href="javascript:;" class="btn btn-text-wrap" title="Pause" (click)="taskTracking(2)"  ><i class="fa fa-solid fa-pause"></i></a>
            <p class="help-word">Pause</p>
          </div>
          <div class="icon-block" *ngIf="taskTrack && userTaskTrack!='0' && userTaskTrack!='3' && userTaskTrack!=-1">
            <button type="button" class="btn btn-text-wrap" title="Job Done" (click)="taskTracking(3)"  [disabled]="userTaskTrack=='2'"><i class="fa fa-regular fa-calendar-check"></i></button>
            <p class="help-word">Job Done</p>
          </div>
          <div class="icon-block" *ngIf="(taskTrack==false || taskTrack==true) && userTaskTrack=='3'">
            <a href="javascript:;" class="btn btn-text-wrap success-btn" title="Job Done" ><i class="fa fa-regular fa-calendar-check"></i></a>
            <p class="help-word">Job Done</p>
          </div>
          <div class="icon-block" *ngIf="userTaskTrack==-1 && serviceInfo.value.request_status!='6' && serviceInfo.value.request_status!='8'">
            <a href="javascript:;" class="btn btn-text-wrap" title="Assign Now" (click)="autoAssign()"  >
              <i class="fa-solid fa-play"></i>
            </a>
            <p class="help-word">Start</p>
          </div>
          <div class="icon-block" *ngIf="userTaskTrack==-1 && serviceInfo.value.request_status!='6' && serviceInfo.value.request_status!='8' ">
            <a href="javascript:;" class="btn btn-text-wrap" title="Next Available" (click)="slefTicketAssignment()"  >
              <img src="/assets/img/play-plus-black.svg" >
            </a>
            <p class="help-word">Next Avail</p>
          </div>  
          <div class="icon-block separator" *ngIf="taskTrack && (userTaskTrack == 0 || userTaskTrack == 1 || userTaskTrack == 2 || userTaskTrack == 3 || (userTaskTrack == -1 && serviceInfo.value.request_status != '6' && serviceInfo.value.request_status != '8'))">
            <span class="vertical-separator">|</span>
          </div>
          <div class="icon-block" *ngIf="isAllowed('views','display_part_cost')">
            <a href="javascript:void(0);"  class="btn btn-text-wrap hide-prices-btn" [class.btn-success]="hidePrices" (click)="priceHide()"  title="{{ ((hidePrices) ? 'Hide Cost' : 'Show Cost') }}"><span >
              <i class="fa fa-h-square"></i>
              </span>
            </a>
            <p class="help-word">{{ ((hidePrices) ? 'Hide Cost' : 'Show Cost') }}</p>

          </div>
          <div class="icon-block" *ngIf="recomLock.is_locked=='0' && serviceInfo.value.notify_to_admin != '1' && editMode=='0' && isFillRecommendation && serviceInfo.value.request_status!='10' && (serviceInfo.value.request_status!='6' && serviceInfo.value.request_status!='8') ">
            <a href="javascript:void(0);" class="btn btn-text-wrap " title="Fill Inspection Report & Recommendation"  id="btnFillRecommendation" (click)="gotoFillRecommendationPage();">
              <i class="fa fa-solid fa-file-signature"></i>
            </a>
            <p class="help-word">Recom</p>
          </div>
          <div class="icon-block" *ngIf="serviceInfo.value.request_status=='12' && editMode=='0' && isAllowed('edits','service_request')">
            <a href="javascript:void(0);" class="btn btn-text-wrap" title="Edit SR Recommendation"   (click)="editRequest()" >
              <i class="fa far fa-pen-to-square"></i>
            </a>
            <p class="help-word">Recom</p>
          </div>
          <div class="icon-block" *ngIf="editMode=='1'">
            <a href="javascript:void(0);" class="btn btn-text-wrap disabled" title="In Edit Mode" ><i class="fa far fa-pen-to-square"></i></a>
            <p class="help-word">Edit Mode</p>
          </div>
          <div class="icon-block" *ngIf="serviceInfo.value.request_status=='7'">
            <a href="javascript:void(0);" class="btn btn-text-wrap" title="Work In Progress"  (click)="workInProgress()">
              <i class="fa fa-solid fa-bars-progress"></i>
            </a>
            <p class="help-word">WIP</p>
          </div>
          <div class="icon-block" *ngIf="((( serviceInfo.value.notify_to_admin == '1' || serviceInfo.value.notify_to_customer == '1' ) && serviceInfo.value.request_status=='12') || serviceInfo.value.request_status=='7')  && isAllowed('views','mark_service_complete')">
            <button type="button" class="btn btn-text-wrap" title="Service Completion"  (click)="generateInvoice(serviceInfo.value.request_id,serviceInfo.value.grand_total,serviceInfo.value.tax_rate,serviceInfo.value.is_tax_included)">
              <i class="fa-solid fa-calendar-check"></i>
            </button>
            <p class="help-word">Complete SR</p>
          </div>
          <div class="icon-block" *ngIf="(serviceInfo.value.request_status=='6' || serviceInfo.value.request_status=='8') && showDownload && isAllowed('views','view_customer_info')">  
            <a   class="btn btn-text-wrap" [ngClass]="{'success-btn':serviceInfo.value.request_status=='8'}" title="Download Invoice" id="downloadInvoice"  name="downloadInvoice" (click)="getInvoicePdf()" href="javascript:void(0);" >
              <i class="fa-solid fa-print"></i>
            </a>
            <p class="help-word">Invoice</p>
          </div>
          <div class="icon-block" *ngIf="recomLock.is_locked=='0' && editMode=='1' && isFillRecommendation && serviceInfo.value.request_status!='10'  && (serviceInfo.value.request_status!='6' && serviceInfo.value.request_status!='8' &&  serviceInfo.value.request_status!='12')">  
            <a href="javascript:void(0);" class="btn btn-text-wrap" title="Fill Inspection Report & Recommendation"  id="btnFillRecommendation" (click)="gotoFillRecommendationPage();">
              <i class="fa fa-solid fa-file-signature"></i>
            </a>
            <p class="help-word">Recom</p>
          </div>
          <div class="icon-block separator hidden-xs">  
            <span class="vertical-separator ">|</span>
          </div>
          <div class="icon-block hidden-xs" *ngIf="isAllowed('views','complaints')">  
            <a href="javascript:void(0);"  class="btn btn-text-wrap " title="View {{ currentUser.profile_type == '2' ? 'Contact' : 'Customer' }} Chat" (click)="messages()">
              <span class="message-icon" >
                <i class="fa fa-regular fa-envelope"></i>
              </span>
            </a>
            <p class="help-word">Chat</p>
          </div>
          <div class="icon-block hidden-xs" *ngIf="isServiceNote">
            <a hre="javascript:;" class="btn btn-text-wrap" title="Open Notes"  (click)="srnoteList()"><i class="fa fa-regular fa-note-sticky"></i></a>
            <p class="help-word">Notes</p>
          </div>
          <div class="icon-block hidden-xs">
            <a  href="javascript:void(0);"  class="btn btn-text-wrap" title="View Service History" (click)="openServiceHistoryPopup()" >
                <img class="service-history-img" #historyImg src="../../../../assets/img/service-history.svg" />
            </a>
            <p class="help-word">History
          </div>  




          <button class="mat-icon-button dot-btn hide" mat-icon-button [matMenuTriggerFor]="actionMenu" disableRipple>
             
            <mat-icon class="mat-icon material-icons text-red margin-left-5">more_vert</mat-icon>
          </button>
          <mat-menu #actionMenu="matMenu" class="car-icon-menu disable-no-bg visible-xs" [overlapTrigger]="false" xPosition="before">
            
            <button class="mat-menu-item" disableRipple type="button" title="{{ currentUser.profile_type == '2' ? 'Contact' : 'Customer' }} Chat List" (click)="messages()" *ngIf="isAllowed('views','complaints')"  ><span><i class="fa fa-regular fa-envelope"></i> {{ currentUser.profile_type == '2' ? 'Contact' : 'Customer' }} Chat List</span></button>    
            <button class="mat-menu-item" disableRipple type="button"  title="Open Notes" *ngIf="isServiceNote" (click)="srnoteList()"><span><i class="fa fa-regular fa-note-sticky"></i> Open Notes</span></button>  
            <button class="mat-menu-item" disableRipple type="button"  (click)="openServiceHistoryPopup()" title="View Service History" ><span class="service-history-icon">
              
              <img #historyImg src="../../../../assets/img/service-history.svg"  /> Service History
             </span> </button>  
    
          </mat-menu>
        </div>
      </div>

      <hr class="margin-top-10">

      <form id="viewServiceRequestForm" class="form-horizontal" role="form">
        <!-- Replaced div grid structure with table -->
        <div class="row" *ngIf="recomLock.is_locked"> <div class="col-sm-12 margin-bottom-10">
            <div class="row"><div class="alert alert-danger uc-alert-info"><strong>{{recomLock.locker_name}}</strong> is working and filling up the recommendation on this ticket.<button type="button" class="font-bold mat-elevation-z0 mat-raised-button mat-accent" (click)="releaseLock()">Release</button></div></div>
          </div>
        </div>
        <div class="row" *ngIf="qqDetail">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6 col-md-6 col-xs-6"><label>Quote ID</label></div>
              <div class="col-sm-6 col-md-6 col-xs-6" (click)="quickQuoteView()"  class="tooltip-status quoteId" >#{{qqDetail.quote_id}} ({{changeDateFormat(qqDetail.add_date)}})</div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6 col-md-6 col-xs-6"><label>Created By</label></div>
              <div class="col-sm-6 col-md-6 col-xs-6">{{qqDetail.user_name}}</div>
            </div>
          </div>
        </div>




        

        <div class="row">
          <div class="col-sm-6">
            <div class="row margin-bottom-10">
              <div class="col-sm-6 col-md-6 col-xs-6"><label>Request ID</label></div>
              <div class="col-sm-6 col-md-6 col-xs-6">{{this.serviceInfo.value.request_id}}</div>
            </div>
            <div class="row margin-bottom-10">
              <div class="col-sm-6 col-md-6 col-xs-6"><label>Title</label></div>
              <div class="col-sm-6 col-md-6 col-xs-6 break-all">
                {{this.serviceInfo.value.title || 'N/A'}}
              </div>
            </div>
            <div class="row margin-bottom-10">
              <div class="col-sm-6 col-md-6 col-xs-6"><label>{{ currentCustomer.profile_type == '2' ? 'Contact' : 'Customer' }} Name <button (click)="detailTab(2)" class="btn btn-xs btn-link text-red ng-tns-c13-22" title="{{ currentCustomer.profile_type == '2' ? 'Contact' : 'Customer' }} Detail" type="button"><i class="fa fa-info-circle fa-lg"></i></button></label></div>
              <div class="col-sm-6 col-md-6 col-xs-6 break-word">
                {{this.serviceInfo.value.first_name}} {{this.serviceInfo.value.last_name}}
              </div>
            </div>
            <div class="row margin-bottom-10">
              <div class="col-sm-6 col-md-6 col-xs-6"><label>Mobile Number</label></div>
              <div class="col-sm-6 col-md-6 col-xs-6" >
                <a class="text-black" *ngIf="isAllowed('views','view_customer_info')" href="tel:{{this.serviceInfo.value.mobile_phone}}">{{this.serviceInfo.value.mobile_phone}}</a>
                <span *ngIf="isAllowed('views','view_customer_info')==false">-</span>
              </div>
            </div>
            <div class="row margin-bottom-10">
              <div class="col-sm-6 col-md-6 col-xs-6"><label>
                <span class="tooltip-status" >
                    Vehicle
                </span>
                <button (click)="detailTab(1)" class="btn btn-xs btn-link text-red ng-tns-c13-22" title="Vehicle Details" type="button"><i class="fa fa-info-circle fa-lg"></i></button></label></div>
              <div class="col-sm-6 col-md-6 col-xs-6" style="word-break: break-word;">
                <span *ngIf="vehicle_pic ==null || vehicle_pic ==undefined || vehicle_pic.length == 0">
                  <span *ngIf="serviceInfo.value.year !=undefined && serviceInfo.value.make !=undefined && serviceInfo.value.model !=undefined" [innerHTML]="vehicleName ? vehicleName:'N/A'">

                  </span>
                </span>
                <div *ngIf="vehicle_pic !=null && vehicle_pic !=undefined && vehicle_pic.length != 0">
                <span class="tooltip-status"  [tooltip]="myTooltip"  (click)="imageToolDialog($event,0,1,[{'vehicle_pic':vehicle_pic}]);">{{vehicleName}}</span>
                <tooltip-content #myTooltip class="image-tooltip" placement="bottom">
                    <img src="{{globalConstants.S3_BUCKET_URL}}{{globalConstants.VEHICLE_PICTURES}}{{globalConstants.IMG_ORIGINAL}}{{vehicle_pic}}?{{vehicleTimeStamp}}" class="img-responsive">
                </tooltip-content>
                </div>
                <p id="expMsgs" class="text-danger" style="font-size:11px;margin-top: 5px;margin-bottom: 0;">
                  <span *ngIf="chkDate(serviceInfo.value.insurance_expiration_date)">Vehicle Insurance Expired -{{serviceInfo.value.insurance_expiration_date}}<br></span>
                  <span *ngIf="chkDate(serviceInfo.value.registration_expiration_date)">Vehicle Registration Expired - {{serviceInfo.value.registration_expiration_date}}<br></span>
                </p>

              </div>
            </div>
            <div class="row margin-bottom-10">
              <div class="col-sm-6 col-md-6 col-xs-6"><label>VIN Number <button *ngIf="serviceInfo.value.vin" (click)="copyText()" class="btn btn-xs btn-link text-red ng-tns-c13-22" title="Copy VIN" type="button"><i class="fa fa-copy fa-lg"></i></button></label></div>
              <div class="col-sm-6 col-md-6 col-xs-6 text-uppercase break-all">
                <span *ngIf="vin_photo ==null || vin_photo ==undefined || vin_photo.length == 0">
                  <span *ngIf="serviceInfo.value.vin">
                    {{serviceInfo.value.vin | slice:0:-8}}<span style="color:#eb1e4c">{{serviceInfo.value.vin.substr(serviceInfo.value.vin.length - 8)}}</span>
                  </span>
                  <span *ngIf="!serviceInfo.value.vin">
                    N/A
                  </span>
                  <!-- {{serviceInfo.value.vin ? serviceInfo.value.vin.substr(0, 8) : ''}}<span style="color:#eb1e4c">{{serviceInfo.value.vin ? serviceInfo.value.vin.substr(9) : ''}}</span> -->
                </span>
                <div *ngIf="vin_photo !=null && vin_photo !=undefined && vin_photo.length != 0">
                    <span class="text-uppercase tooltip-status"  [tooltip]="myTooltip1" (click)="imageToolDialog($event,0,5,vin_photo);"   *ngIf="this.serviceInfo.value.vin!=null" style="display: inline-block;">
                      <span *ngIf="serviceInfo.value.vin">
                        {{serviceInfo.value.vin | slice:0:-8}}<span style="color:#eb1e4c">{{serviceInfo.value.vin.substr(serviceInfo.value.vin.length - 8)}}</span>
                      </span>
                    </span>
                    <tooltip-content #myTooltip1 class="image-tooltip" placement="bottom">
                      <img src="{{globalConstants.S3_BUCKET_URL}}{{globalConstants.VEHICLE_VIN_PIC}}{{globalConstants.IMG_ORIGINAL}}{{vin_photo}}?{{vinTimeStamp}}" class="img-responsive">
                    </tooltip-content>
                </div>

              </div>
            </div>
            <div class="row margin-bottom-10">
              <div class="col-sm-6 col-md-6 col-xs-6"><label>License Plate</label></div>
              <div class="col-sm-6 col-md-6 col-xs-6 break-word text-uppercase">
                {{this.serviceInfo.value.license_plate || 'N/A'}}
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row margin-bottom-10">
              <div class="col-sm-6 col-md-6 col-xs-6"><label>Present Mileage (Miles in)</label></div>
              <div class="col-sm-6 col-md-6 col-xs-6 break-word">
                {{this.serviceInfo.value.present_mileage}}
              </div>
            </div>
            <div class="row margin-bottom-10">
              <div class="col-sm-6 col-md-6 col-xs-6"><label>Present Mileage (Miles out)</label></div>
              <div class="col-sm-6 col-md-6 col-xs-6" *ngIf="serviceInfo.value.present_mileage_out" [innerHTML]="this.serviceInfo.value.present_mileage_out != '0' ? this.serviceInfo.value.present_mileage_out : '-'"> </div>
              <div class="col-sm-6 col-md-6 col-xs-6" *ngIf="!serviceInfo.value.present_mileage_out" [innerHTML]="'N/A'"> </div>
            </div>
            <div class="row margin-bottom-10">
              <div class="col-sm-6 col-md-6 col-xs-6"><label>Requested Date</label></div>
              <div class="col-sm-6 col-md-6 col-xs-6 break-word">
                {{this.serviceInfo.value.requested_delivery_date}}
              </div>
            </div>
            <div class="row margin-bottom-10">
              <div class="col-sm-6 col-md-6 col-xs-6"><label>Scheduled Date</label></div>
              <div class="col-sm-6 col-md-6 col-xs-6" *ngIf="this.serviceInfo.value.schedule_service_date!='0000-00-00 00:00:00'">
                {{this.serviceInfo.value.schedule_service_date}}
              </div>
              <div class="col-sm-6" *ngIf="this.serviceInfo.value.schedule_service_date=='0000-00-00 00:00:00'">
                 N/A
                </div>
            </div>
            <div class="row margin-bottom-10" *ngIf="this.SrComplete">
              <div class="col-sm-6 col-md-6 col-xs-6"><label>Completion Date</label></div>
              <div class="col-sm-6 col-md-6 col-xs-6" *ngIf="this.serviceInfo.value.completion_date!='0000-00-00 00:00:00'">
                {{this.serviceInfo.value.completion_date}}
              </div>
              <div class="col-sm-6 col-md-6 col-xs-6" *ngIf="this.serviceInfo.value.completion_date=='0000-00-00 00:00:00'">
                  N/A
                </div>
            </div>
            <div class="row margin-bottom-10" *ngIf="!this.SrComplete">
              <div class="col-sm-6 col-md-6 col-xs-6"><label>Completion Date</label></div>
              <div class="col-sm-6 col-md-6 col-xs-6">
                  N/A
                </div>
            </div>
            <div class="row margin-bottom-10">
              <div class="col-sm-6 col-md-6 col-xs-6"><label>Needed By</label></div>
              <div class="col-sm-6 col-md-6 col-xs-6" *ngIf="this.serviceInfo.value.estimated_delivery_date!='0000-00-00 00:00:00'">
                {{this.serviceInfo.value.estimated_delivery_date}}
              </div>
              <div class="col-sm-6 col-md-6 col-xs-6" *ngIf="this.serviceInfo.value.estimated_delivery_date=='0000-00-00 00:00:00'">
                  N/A
                </div>
            </div>     
            <div class="row margin-bottom-10">
              <div class="col-sm-6 col-md-6 col-xs-6"><label>Status</label></div>
              <div class="col-sm-6 col-md-6 col-xs-6 status-badge">              
                  <mat-chip-list class="mat-badge">
                      <mat-chip class="cursor-pointer mat-chip-selected {{ getStatusColor() }}" selected="true">
                       <span (click)="showAllstatus()" *ngIf="currentCustomer.role_id!=4"> {{getStatus()}}</span>
                       <span *ngIf="currentCustomer.role_id==4"> {{getStatus()}}</span>
                      </mat-chip>
                  </mat-chip-list>
              </div>
          </div>       

          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6 col-md-6 col-xs-6"><label>Download Report</label></div>
              <div class="col-sm-6 col-md-6 col-xs-6">
                <span id="downloadReportUPSP" *ngIf="reportDownloadType==1" >
                  <a class="text-black" href="javascript:void(0)" (click)="invokeDownload(reportDownloadLink,'CARInspectionReport')">(USPS-PMI-PS-Form)<i class="fa fa-download fa-fw "></i></a>
                </span>
                <span id="downloadReportACDELCO" *ngIf="reportDownloadType==2">
                  <a class="text-black" href="javascript:void(0)" (click)="invokeDownload(reportDownloadLink,'CARInspectionReport')">(CAR Inspection Report)<i class="fa fa-download fa-fw "></i></a>
                </span>
                <span id="noReportPerformed" *ngIf="reportDownloadType==0">
                  No Report Performed
                </span>
              </div>
            </div>  
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-12 margin-bottom-10">
            <div class="row">
              <div class="col-xs-12 col-sm-3 col-md-3"><label>Notes</label></div>
              <div class="col-xs-12 col-sm-9 col-md-9 break-all" [innerHtml]="convertNltoBr(serviceInfo.value.notes)">
                <!-- {{this.serviceInfo.value.notes}} -->
              </div>
            </div>
          </div>
        </div>
        <div class="row margin-bottom-10">
          <div class="col-sm-12  margin-bottom-10">
            <div class="row">            
              <div class="col-sm-3"><label for="service_request_label" class="control-label"> Labels</label>&nbsp;
                <button class="btn btn-xs btn-link text-red" title="Edit" type="button">
                  <i class="fa fa-pencil fa-lg" title="Manage Labels" (click)="manageLabels()"></i>
                </button>
              </div>
  
              <div class="form-group details-input col-sm-4  uc-ngx-select margin-0">
                  <div class="relative" *ngIf="showLabelsDropdown">
                    <ngx-select [autoClearSearch]="true" class="display-full" 
                    [defaultValue]="preSelectLabel"
                    [multiple]="true" 
                    [items]="allLabels"
                    (select)="selectedLabel($event)"
                    (remove)="removeLabel($event)"
                    optionTextField="label_name"
                    placeholder="Select Label">
                  </ngx-select>
                  </div>
              </div>
            </div>
          </div>
          
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10" *ngIf="upload_v && upload_v.length > 0">
            <div class="row">
              <div class="col-sm-6 col-md-6 col-xs-6"><label>Walk Around Video</label></div>
              <div class="col-sm-6 col-md-6 col-xs-6">
                <div class="vlio-datepicker relative">
                  <div class="upload-image" id="upload-img">          
                    <ul class="list-inline margin-bottom-0">
                      <li class="video-upload " *ngFor="let image of upload_v;let i=index">
                        <a class="thumbnail margin-bottom-0 relative" href="javascript:void(0)" (click)="videoShowMain(image)">
                          <span class="relative video-bg">
                            <mat-icon class="position-center play-video">play_circle_filled</mat-icon>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        
        <div class="row margin-bottom-15" *ngIf="(businessMetricReport | json) != '{}' && profitPermission && hidePrices"> 
          <div class="col-sm-12 padding-lr-0-mob">
            <div class="alert alert-info uc-alert-info profit-alert" >             
              <p class="break-para">What are your overall service request metrics?</p>
              <button class="font-bold mat-raised-button mat-elevation-z0" style="margin-right:220px;width:auto;">
                SR (Profit) : $ {{ businessMetricReport.sr_profit }}
              </button>
              <button class="font-bold mat-raised-button mat-elevation-z0" style="width: auto;">
                SR (Profit Margin) : {{ businessMetricReport.sr_profit_margin }}%
              </button>
            </div>
          </div>
        </div>


        <div class="row documents-accordion relative" *ngIf="(serviceInfo.value.request_documents!=null && serviceInfo.value.request_documents.length > 0) || (serviceInfo.value.request_images!=null && serviceInfo.value.request_images.length > 0)">
          <mat-accordion class="new-request-expension view-request margin-bottom-10 display-full" displayMode="flat" hideToggle="false" >
              <mat-expansion-panel hideToggle="true" id="expansion-rules" >
                  <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="expansionHead-rules" >
                      <span class="margin-right-5"> </span><b>Documents &amp; Images</b>
                  </mat-expansion-panel-header>
                  <div class="">
                      <div class="col-sm-6" *ngIf="serviceInfo.value.request_documents!=null && serviceInfo.value.request_documents.length > 0">
                          <div class="row">
                              <div class="col-sm-12 margin-bottom-5"><label>Document(s)</label></div>
                              <div class="col-sm-12 text-danger">
      
                                  <a *ngFor="let doc of serviceInfo.value.request_documents" style="display:inline-block;margin:0px 5px 5px 0px;position:relative;" href="javascript:void(0)" (click)="invokeDownload(globalConstants.S3_BUCKET_URL+'Service-Request-Documents/'+doc.document_name,'ServiceRequestDocument',doc.document_name)" class="thumbnail">
                                  <!-- <img style="width:80px;height:75px;" src="assets/img/adobe_pdf-logo.jpg"> -->
                                  <span class="fa far fa-file-pdf fa-4x" *ngIf="getFileIcons(doc.document_name)==3"></span>
                                  <span class="fa far fa-file-word fa-4x" *ngIf="getFileIcons(doc.document_name)==4"></span>
                                  </a>
      
                              </div>
                          </div>
                      </div>
                      <div class="col-sm-6" *ngIf="serviceInfo.value.request_documents.length == 0">
                          <div class="col-sm-12 margin-bottom-5"><label> </label></div>
                          <div class="col-sm-12 text-danger pd-ltm-0"> No documents uploaded.</div>
                      </div>
                      <div class="col-sm-6" *ngIf="serviceInfo.value.request_images!=null && serviceInfo.value.request_images.length > 0">
                          <div class="row">
                              <div class="col-sm-12 margin-bottom-5"><label>Image(s)</label></div>
                              <div class="col-sm-12 image-list-section">
                                  <ul class="list-inline margin-bottom-0">
                                      <li class="relative view-images" *ngFor="let image of serviceInfo.value.request_images;let i=index;">
                                      <a (click)="imageToolDialog($event,i,8,serviceInfo.value.request_images)" class="thumbnail" href="javascript:void(0);">
                                          <img style="width:100px;height:75px;" src="{{globalConstants.S3_BUCKET_URL}}Service-Request-Pictures/thumb/{{image.image_name}}">
                                      </a>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <div class="col-sm-6" *ngIf="serviceInfo.value.request_images.length == 0">
                          <div class="col-sm-12 margin-bottom-5"><label> </label></div>
                          <div class="col-sm-12 text-danger"> No images uploaded.</div>     
                      </div>
                  </div>
              </mat-expansion-panel>
          </mat-accordion>
      </div>
        <!-- DISPLAY REQUIRE APPROVAL -->

        <div class="row documents-accordion relative">
            <mat-accordion class="new-request-expension view-request margin-bottom-10 display-full" displayMode="flat" hideToggle="false" >
                <mat-expansion-panel hideToggle="true" id="expansion-rules" >
                  <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="expansionHead-rules" >
                    <span class="margin-right-5"> </span><b>Customer Approval</b>
                  </mat-expansion-panel-header>
                  <div class="margin-bottom-15"  *ngIf="requestApprovalDetails.length > 0 ">
                    <div class="display-full alert alert-info margin-bottom-0" style="border-top-left-radius: 0px;border-top-right-radius: 0px;" >
                       <strong class="display-full margin-bottom-10">How was authorization obtained?</strong>
                       <ng-template ngFor let-approvalData [ngForOf]="requestApprovalDetails">
                           <div class="auth-list" *ngIf=" (approvalData.authorize_by!='' && approvalData.authorize_by!=null  && approvalData.authorized_by_customer ==0)">
                               <div *ngIf="approvalData.authorize_by!='Other' && approvalData.add_mode != 2 ">
                                   <div class="row margin-bottom-10">
                                       <div class="col-md-4 col-sm-6 col-lg-3"><span>Authorization obtained :</span></div>
                                       <div class="col-md-8 col-sm-6 col-lg-9">{{approvalData.authorize_by || '-'}}</div>
                                   </div>
               
                                   <div class="row margin-bottom-10">
                                       <div class="col-md-4 col-sm-6 col-lg-3"><span>Who was the person?</span></div>
                                       <div class="col-md-8 col-sm-6 col-lg-9">{{approvalData.contact_person || '-'}}</div>
                                   </div>
               
                                   <div class="row margin-bottom-10">
                                       <div class="col-md-4 col-sm-6 col-lg-3"><span>Phone number :</span></div>
                                       <div class="col-md-8 col-sm-6 col-lg-9">{{approvalData.contact_person_phone_number || '-'}}</div>
                                   </div>
               
                                   <div class="row margin-bottom-10">
                                       <div class="col-md-4 col-sm-6 col-lg-3"><span>Date Time of call :</span></div>
                                       <div class="col-md-8 col-sm-6 col-lg-9">{{changeDateFormat(approvalData.date_time_of_call) || '-'}}</div>
                                   </div>
                               </div>
               
                               <div *ngIf="approvalData.authorize_by=='Other'  && approvalData.add_mode != 2 ">
                                   <div class="row margin-bottom-10">
                                       <div class="col-md-4 col-sm-6 col-lg-3"><span>Authorization obtained :</span></div>
                                       <div class="col-md-8 col-sm-6 col-lg-9">{{approvalData.authorize_by || '-'}}</div>
                                   </div>
                                   <div class="row margin-bottom-10">
                                     <div class="col-md-4 col-sm-6 col-lg-3"><span>Who was the person?</span></div>
                                     <div class="col-md-8 col-sm-6 col-lg-9">{{approvalData.contact_person || '-'}}</div>
                                 </div>
                                 <div class="row margin-bottom-10">
                                     <div class="col-md-4 col-sm-6 col-lg-3"><span>Date Time of call :</span></div>
                                     <div class="col-md-8 col-sm-6 col-lg-9">{{changeDateFormat(approvalData.date_time_of_call) || '-'}}</div>
                                 </div>
                                   <div class="row margin-bottom-10">
                                       <div class="col-md-4 col-sm-6 col-lg-3"><label>Description :</label></div>
                                       <div class="col-md-8 col-sm-6 col-lg-9"><span [innerHTML]="approvalData.other_means_of_contacts"></span></div>
                                   </div>
                               </div>
                               <div *ngIf=" approvalData.add_mode == 2 ">
                                   <div class="row margin-bottom-10">
                                       <div class="col-md-4 col-sm-6 col-lg-3"><span>Authorization obtained :</span></div>
                                       <div class="col-md-8 col-sm-6 col-lg-9">{{approvalData.authorize_by || '-'}}</div>
                                   </div>
                                 <div class="row margin-bottom-10">
                                     <div class="col-md-4 col-sm-6 col-lg-3"><span>Date Time :</span></div>
                                     <div class="col-md-8 col-sm-6 col-lg-9">{{changeDateFormat(approvalData.date_time_of_call) || '-'}}</div>
                                 </div>
                                   <div class="row margin-bottom-10">
                                       <div class="col-md-4 col-sm-6 col-lg-3"><label>Description :</label></div>
                                       <div class="col-md-8 col-sm-6 col-lg-9"><span [innerHTML]="approvalData.other_means_of_contacts"></span></div>
                                   </div>
                               </div>
                           </div>
                           <div class="auth-list" *ngIf="approvalData.authorized_by_customer ==1">
                            <div class="row margin-bottom-10">
                              <div class="col-md-4 col-sm-6 col-lg-3"><span>Authorization obtained :</span></div>
                              <div class="col-md-8 col-sm-6 col-lg-9">Customer</div>
                            </div>
                            <div class="row margin-bottom-10">
                              <div class="col-md-4 col-sm-6 col-lg-3"><span>Phone Number Verification :</span></div>
                              <div class="col-md-8 col-sm-6 col-lg-9">{{approvalData.phone_number_verified == 1?'Verified':'Not Verified'}}</div>
                            </div>
                           </div>
                       </ng-template>
                   </div>
               </div>
               <div class="margin-bottom-15 no-approval mat-error"  *ngIf="requestApprovalDetails.length == 0">
                  No Record Found
               </div>
                </mat-expansion-panel>
              </mat-accordion>
        </div>
        <!-- <div class="toggle__title" (click)="toggleShowApprovalData()" *ngIf="requestApprovalDetails.length > 0">
            <strong>Customer Approval</strong>
        </div>
        <div class="margin-bottom-15" [@slideInOut]="animationStateApprovalData" *ngIf="requestApprovalDetails.length > 0 ">
             <div class="display-full alert alert-info margin-bottom-0" style="border-top-left-radius: 0px;border-top-right-radius: 0px;" >
                <strong class="display-full margin-bottom-10">How was authorization obtained?</strong>
                <ng-template ngFor let-approvalData [ngForOf]="requestApprovalDetails">
                    <div class="auth-list" *ngIf=" (approvalData.authorize_by!='' && approvalData.authorize_by!=null && approvalData.authorize_by!='null')">


                        <div *ngIf="approvalData.authorize_by!='Other' && approvalData.add_mode != 2 ">
                            <div class="row margin-bottom-10">
                                <div class="col-md-4 col-sm-6 col-lg-3"><span>Authorization obtained :</span></div>
                                <div class="col-md-8 col-sm-6 col-lg-9">{{approvalData.authorize_by}}</div>
                            </div>

                            <div class="row margin-bottom-10">
                                <div class="col-md-4 col-sm-6 col-lg-3"><span>Who was the person?</span></div>
                                <div class="col-md-8 col-sm-6 col-lg-9">{{approvalData.contact_person}}</div>
                            </div>

                            <div class="row margin-bottom-10">
                                <div class="col-md-4 col-sm-6 col-lg-3"><span>Phone number :</span></div>
                                <div class="col-md-8 col-sm-6 col-lg-9">{{approvalData.contact_person_phone_number}}</div>
                            </div>

                            <div class="row margin-bottom-10">
                                <div class="col-md-4 col-sm-6 col-lg-3"><span>Date Time of call :</span></div>
                                <div class="col-md-8 col-sm-6 col-lg-9">{{changeDateFormat(approvalData.date_time_of_call)}}</div>
                            </div>
                        </div>

                        <div *ngIf="approvalData.authorize_by=='Other'  && approvalData.add_mode != 2 ">
                            <div class="row margin-bottom-10">
                                <div class="col-md-4 col-sm-6 col-lg-3"><span>Authorization obtained :</span></div>
                                <div class="col-md-8 col-sm-6 col-lg-9">{{approvalData.authorize_by}}</div>
                            </div>
                            <div class="row margin-bottom-10">
                              <div class="col-md-4 col-sm-6 col-lg-3"><span>Who was the person?</span></div>
                              <div class="col-md-8 col-sm-6 col-lg-9">{{approvalData.contact_person}}</div>
                          </div>
                          <div class="row margin-bottom-10">
                              <div class="col-md-4 col-sm-6 col-lg-3"><span>Date Time of call :</span></div>
                              <div class="col-md-8 col-sm-6 col-lg-9">{{changeDateFormat(approvalData.date_time_of_call)}}</div>
                          </div>
                            <div class="row margin-bottom-10">
                                <div class="col-md-4 col-sm-6 col-lg-3"><label>Description :</label></div>
                                <div class="col-md-8 col-sm-6 col-lg-9"><span [innerHTML]="approvalData.other_means_of_contacts"></span></div>
                            </div>
                        </div>
                        <div *ngIf=" approvalData.add_mode == 2 ">
                            <div class="row margin-bottom-10">
                                <div class="col-md-4 col-sm-6 col-lg-3"><span>Authorization obtained :</span></div>
                                <div class="col-md-8 col-sm-6 col-lg-9">{{approvalData.authorize_by}}</div>
                            </div>
                          <div class="row margin-bottom-10">
                              <div class="col-md-4 col-sm-6 col-lg-3"><span>Date Time :</span></div>
                              <div class="col-md-8 col-sm-6 col-lg-9">{{changeDateFormat(approvalData.date_time_of_call)}}</div>
                          </div>
                            <div class="row margin-bottom-10">
                                <div class="col-md-4 col-sm-6 col-lg-3"><label>Description :</label></div>
                                <div class="col-md-8 col-sm-6 col-lg-9"><span [innerHTML]="approvalData.other_means_of_contacts"></span></div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div> -->

        <!-- END -->
        <div class="row" >
          <div class="col-sm-12 padding-lr-0-mob">
            <div class="alert alert-info uc-alert-info disabled" *ngIf="toNumber(serviceInfo.value.request_status) == 3">
                Approve the recommendation(s) that need to be done to the service request and reject those which are not needed. Then click the below button to apply the changes and continue further.
                <br>
                <button *ngIf="isAcceptRejectJob"  mat-raised-button class="mat-success font-bold mat-elevation-z1 notify-to-admin" (click)="finalUpdateToNotifyAdmin(serviceInfo.value.request_id)">
                  Apply Changes
                </button>
            </div>
            <div class="alert alert-info uc-alert-info" *ngIf="serviceDetail && ((serviceInfo.value.is_usps_vehicle == '1') || (serviceDetail.usps_vehicle == '1'))">
              <p *ngIf="serviceInfo.value.request_status !='6' && serviceInfo.value.request_status !='8'">Perform Emissions Inspection. Click below button to perform one now?</p>
              <p *ngIf="serviceInfo.value.request_status =='6' || serviceInfo.value.request_status =='8'">Please click this button to view Emission Inspection Report</p>
              <button mat-raised-button class="font-bold mat-elevation-z0" color="accent" (click)="emissionInspection()">
                <span>Emission Inspection</span>
              </button>
            </div>
            <div class="alert alert-info uc-alert-info print-alert" *ngIf="(serviceInfo.value.request_status =='1' || serviceInfo.value.request_status =='2' || serviceInfo.value.request_status =='3' || serviceInfo.value.request_status =='7' || serviceInfo.value.request_status =='12')">
              <p class="break-para" > Estimate and Tech Sheet print can be taken after filling out recommendations.</p>
              <button  [ngClass]="isAllowed('views','invoices') && isAllowed('views','view_customer_info') ? 'print-tech-sheet-btn' : ''" class="font-bold mat-elevation-z0 mat-raised-button mat-accent" (click)="printTechSheet()" [disabled]="serviceInfo.value.recommendation_saved != '1'">
                <span>Print Tech Sheet</span>
              </button>
              <button class="font-bold mat-elevation-z0 mat-raised-button mat-accent" (click)="printInvoice()" [disabled]="serviceInfo.value.recommendation_saved != '1'" *ngIf="isAllowed('views','invoices') && isAllowed('views','view_customer_info')">
                <span>Print Estimate</span>
              </button>
            </div>
            <div class="alert alert-info  uc-alert-info disabled" *ngIf="showPartsTechButton && (partstechData.length > 0 ) && isAllowed('edits','partstech_order')">
              <p>PartsTech parts added to this request and order yet to be placed on PartsTech.</p>
              <button type="button" mat-raised-button class="mat-success font-bold mat-elevation-z1" (click)="partstech()" ><img style="width:140px;" src="assets/img/PartsTechLogo.png" /></button>
            </div>
            <div class="alert alert-info uc-alert-info disabled" *ngIf="(partstechOrder.length > 0 && isAllowed('views','partstech_order'))">
              <p>PartsTech Order(s) associated with this request.</p>
              <button type="button" mat-raised-button class="mat-success font-bold mat-elevation-z1" (click)="showPartstechOrder()" >Placed Order</button>
            </div>
            <div class="alert alert-info uc-alert-info disabled" *ngIf="(nexpartOrder.length > 0 && isAllowed('views','nexpart_order'))">
              <p>Nexpart Order(s) associated with this request.</p>
              <button type="button" mat-raised-button class="mat-success font-bold mat-elevation-z1" (click)="showNexpartOrder()" >Placed Order</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="display-full margin-bottom-15" *ngIf="serviceInfo.value.request_status == '3' && serviceInfo.value.service_request_list.length >0">
      <button type="button" title="Accept All" *ngIf="isAcceptRejectJob" mat-raised-button class="mat-success font-bold mat-elevation-z1" (click)="acceptAll()">Accept All</button>
      <button type="button" title="Reject All" *ngIf="isAcceptRejectJob" mat-raised-button color="warn" class="font-bold mat-elevation-z1" (click)="rejectAll()">Reject All</button>
      <button type="button" title="Use Previous" *ngIf="isAcceptRejectJob && isUsePrevious()" mat-raised-button color="accent" class="font-bold matelevation-z1" (click)="usePrevious()">Use Previous</button>
    </div>
    <div class="display-full margin-bottom-15" *ngIf="serviceInfo.value.service_request_list?.length == 0">
       <p style="text-align: center;">No Job Found</p>
    </div>
   
    <mat-accordion class="new-request-expension view-request show-price margin-bottom-10 display-full" displayMode="flat" hideToggle="false" >
      <div class="documents-accordion relative view-sr-list">
        <div class="job-category-container" *ngFor="let service_request of serviceInfo.value.service_request_list;let a=index">
          <mat-expansion-panel [expanded]="a==0" hideToggle="true" id="expansion-{{a+1}}" >
            <mat-expansion-panel-header [class.mat-bg-green]="headingColorFlag[a]==2" [class.mat-bg-red]="headingColorFlag[a]==3" collapsedHeight="42px" expandedHeight="42px" id="expansionHead-{{a+1}}" (click)="expansionOpen(a+1);" [attr.data-state]="a==0" >
              <div class="job-name text-ellipsis"><span>{{a+1}}.</span> {{ service_request.name }}</div>
              <div class="row hidden-xs subjob-category-wrapper-desktop">
                <div class="col-sm-12" *ngFor="let jobCategories of service_request.subJobCategories; let sn = index">
                  <p class="subjobs"><span>{{ sn+1 }}.</span>&nbsp;<span>{{jobCategories}}</span></p>
                </div>
              </div>
            </mat-expansion-panel-header>
            <ul class="service-list-items">
              <li class="topPad job-item" *ngFor="let servicerequestChild of service_request.children;let i = index">
                <p class="child-job"><span>{{i+1}}. {{servicerequestChild.name}}</span>
                  <span class="btn-copy-paste" *ngIf="serviceInfo.value.recommendation_saved == '1' && isCopyEbnable(servicerequestChild)" (click)="copyJob($event,servicerequestChild,service_request)">
                    <i class="fa fa-copy"></i> <span class="hidden-xs"> Copy Job</span>
                  </span>
                </p>
                <ul class="service-list-sub-items no-style padding-left-0" >
                  <li  *ngIf="serviceInfo.value.recommendation_saved == '1'">
                    <div class="row">

                      <div *ngFor="let recommendation of servicerequestChild.recommendations;let j = index">
                        <div *ngIf="recommendation.service_request_list_id!='158'">
                          <div class="col-sm-12 margin-bottom-10 padding-lr-0-mob" *ngIf="recommendation.parts.length > 0">
                            <div class="form-group">
                              <label class="col-sm-3 col-lg-2 text-right control-label">Part(s):</label>
                              <div class="col-sm-9 col-lg-10 text-justify">
                                <div class="addedPartsItems">
                                  <div class="partRow row" [class.text-orange]="part.partstech_cart_item==1 || part.partstech_order_item==1" [class.text-nexpart-green]="part.nexlink_cart_item==1 || part.nexlink_order_item==1"  *ngFor="let part of recommendation.parts;let k = index">
                                    <div class="col-sm-8 pname break-all margin-sm-bottom-7"
                                    data-bs-toggle="tooltip" data-bs-placement="right" title="{{getPartToolTipTitle(part)}}"
                                    ><span *ngIf="part.part_number!=''">{{part.part_number}} </span><span *ngIf="showCostPrice && hidePrices">(${{part.cost}}) -</span> {{part.name}}<span *ngIf="part.description!=''"> - {{part.description}}</span></div>
                                    <div class="col-xs-6 col-sm-2 pprice "><span *ngIf="showCostPrice">$ {{ parseFloats(part.part_price)}}</span></div>
                                    <div class="col-xs-6 col-sm-2 pquantity" [class.text-red]="isInventory && part.out_of_stock=='1'">
                                      <i *ngIf="isInventory" class="fa fa-info-circle" title="{{ stockInHand(part) }} "></i> {{part.quantity}}
                                      <!-- <button type="button" class="btn btn-xs btn-link text-red" (click)="addStockOrder(recommendation,part)"><i *ngIf="isInventory && part.out_of_stock=='1'" class="fa fa-cart-plus fa-lg" title="Add to stock order"></i></button> -->
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 margin-bottom-10 padding-lr-0-mob" *ngIf="recommendation.generic_jobs.length > 0">
                            <div class="form-group">
                              <label class="col-sm-3 col-lg-2 text-right control-label">Generic Job(s):</label>
                              <div class="col-sm-9 col-lg-10 text-justify">
                                <div class="addedGenericJobs">
                                  <div class="partRow row"  *ngFor="let gjob of recommendation.generic_jobs;let l = index">
                                    <div class="col-sm-8 pname margin-sm-bottom-7">{{gjob.name}}</div>
                                    <div class="col-xs-6 col-sm-2 pprice"><span *ngIf="showCostPrice">$ {{ parseFloats(gjob.price) }}</span></div>
                                    <div class="col-xs-6 col-sm-2 ppqty">{{gjob.quantity}}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 margin-bottom-10 padding-lr-0-mob" *ngIf="recommendation.computer_codes.length > 0">
                            <div class="form-group">
                              <label class="col-sm-3 col-lg-2 text-right control-label">Computer Codes(s):</label>
                              <div class="col-sm-9 col-lg-10 text-justify">
                                <div class="addedGenericJobs">
                                  <div class="partRow row"  *ngFor="let cjob of recommendation.computer_codes;let l = index">
                                    <div class="col-sm-12 pname break-all">{{cjob.trouble_code}} {{cjob.fault_location}}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-12 margin-bottom-10 padding-lr-0-mob" *ngIf="currentUser.subscription_data.nexpart == 1  &&  recommendation.nexpart_labor_time >0">
                            <div class="form-group">
                              <!-- <label class="col-sm-3 col-lg-2 text-right control-label">Nexpart labor time:</label> -->
                              <label class="col-sm-3 col-lg-2  hoursLbr text-right control-label" 
                              data-bs-toggle="tooltip" data-bs-placement="right" title="Nexpart: {{parseFloats(recommendation.nexPartTotalLaborHours)}} Hrs, PartsTech :{{parseFloats(recommendation.partsTechTotalLaborHours)}} Hrs">Nexpart/PartsTech Labor (Hours)</label>
                              <div class="col-sm-9 col-lg-10 text-justify break-all">
                                <div class="addedComputerCodes"> {{ parseFloats(recommendation.nexpart_labor_time)}} hour(s)</div>
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-12 margin-bottom-10 padding-lr-0-mob">
                            <div class="form-group">
                              <label class="col-sm-3 col-lg-2 text-right control-label">Estimated Time:</label>
                              <div class="col-sm-9 col-lg-10 text-justify break-all">
                                <div class="addedComputerCodes">{{recommendation.estimated_time}} hour(s) </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-12 margin-bottom-10 padding-lr-0-mob" *ngIf="showCostPrice">
                            <div class="form-group">
                              <label class="col-sm-3 col-lg-2 text-right control-label">Estimated Labor:</label>
                              <div class="col-sm-9 col-lg-10 text-justify break-all">
                                <div class="addedComputerCodes">$ {{ parseFloats(recommendation.estimated_labor)}}</div>
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-12 margin-bottom-10 padding-lr-0-mob" *ngIf="recommendation.service_request_list_id!=700">
                            <div class="form-group">
                              <label class="col-sm-3 col-lg-2 text-right control-label">Description of Work:</label>
                              <div class="col-sm-9 col-lg-10 text-justify break-all" >
                                <p [innerHtml]="convertNltoBr(recommendation.recommendation)"></p>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 margin-bottom-10 padding-lr-0-mob" *ngIf="recommendation.service_request_list_id!=700">
                            <div class="form-group">
                              <label class="col-sm-3 col-lg-2 text-right control-label">Notes:</label>
                              <div class="col-sm-9 col-lg-10 text-justify break-all">
                                <p [innerHtml]="convertNltoBr(recommendation.notes)"></p>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 margin-bottom-10 already-uploaded-images padding-lr-0-mob" *ngIf="recommendation.service_request_list_id!=700">
                            <div class="form-group">
                              <label class="col-sm-3 col-lg-2 text-right control-label">Uploaded Image(s):</label>
                              <div class="col-sm-9 col-lg-10 text-justify">
                                <ul class="list-inline margin-bottom-0" *ngIf="recommendation.uploaded_images.length > 0">
                                  <li  class="relative view-images" *ngFor="let image of recommendation.uploaded_images;let i=index;">
                                    <a (click)="imageToolDialog($event,i,4,recommendation.uploaded_images)" class="thumbnail" href="javascript:void(0);">
                                      <img class="show-image" src="{{globalConstants.S3_BUCKET_URL}}recommendation-pictures/thumb/{{image.filename}}">
                                    </a>
                                  </li>
                                </ul>
                                <span class="text-danger" *ngIf="recommendation.uploaded_images.length == 0">
                                  <span>No images uploaded.</span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 margin-bottom-10 already-uploaded-videos padding-lr-0-mob" *ngIf="recommendation.service_request_list_id!=700">
                            <div class="form-group">
                              <label class="col-sm-3 col-lg-2 text-right control-label">Uploaded Video(s):</label>
                                <div class="col-sm-9 col-lg-10 text-justify" *ngIf="recommendation.uploaded_videos.length > 0">
                                  <ul id="vehicle_video-ul" class="list-inline margin-bottom-0">
                                    <li class="video-upload relative" *ngFor="let video of recommendation.uploaded_videos;let i=index">
                                      <a class="thumbnail margin-bottom-0" href="javascript:void(0)" (click)="videoShow(video.filename)">
                                        <span class="relative video-bg">
                                          <mat-icon class="position-center play-video">play_circle_filled</mat-icon>
                                        </span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              <div class="col-sm-9 col-lg-10 text-justify" *ngIf="recommendation.uploaded_videos.length == 0">
                                <span class="text-danger">No videos uploaded.
                                  <span></span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="display-full col-sm-12 text-right margin-bottom-10" *ngIf="showCostPrice">
                              Job Total : <span *ngIf="levelPriceObj">$ {{levelPriceObj[recommendation.id]}}</span>
                          </div>
                          <div class="col-xs-12 notranslate margin-bottom" *ngIf="recommendation.service_request_list_id!=700">
                              <div class="previous-job-status" *ngIf="isShowPrevious() && recommendation.old_approval_status!=0">
                                  <div class="alert" [attr.title]="recommendation.old_approval_status=='1' ? 'Previously Accepted' : 'Previously Rejected'" [class.alert-success]="recommendation.old_approval_status=='1'" [class.alert-danger]="recommendation.old_approval_status=='2'" disabled>
                                    {{( recommendation.old_approval_status=='1' ? 'Accepted' : 'Rejected' )}}
                                  </div>
                                </div>
                            <div class="displayfull text-right btn-wrap" *ngIf="serviceInfo.value.request_status==3 && isAcceptRejectJob">
                              <mat-button-toggle value="accept" (change)="updateText($event, recommendation.id, recommendation,service_request,1)" class="toogle-btn btn-success" [checked]="recommendation.approval_status == 0 || recommendation.approval_status == 2">
                                {{recommendation.approval_status == 0 ? 'Accept' : recommendation.approval_status == 1 ? 'Undo Accept' : recommendation.approval_status == 2 ? 'Accept': statusAcceptText}}
                              </mat-button-toggle>
                              <mat-button-toggle value="reject" (change)="updateText($event, recommendation.id, recommendation,service_request,2)" class="toogle-btn btn-danger" [checked]="recommendation.approval_status == 0 || recommendation.approval_status == 1">
                                {{recommendation.approval_status == 0 ? 'Reject' : recommendation.approval_status == 1 ? 'Reject' : recommendation.approval_status == 2 ? 'Undo Reject': statusRejectText}}
                              </mat-button-toggle>
                            </div>
                            <div class="displayfull text-right" *ngIf="serviceInfo.value.request_status!=3">
                              <mat-button-toggle value="Accepted" class="toogle-btn btn-success cursor-default" *ngIf="recommendation.approval_status=='1'" >Accepted</mat-button-toggle>
                              <mat-button-toggle value="Rejected" class="toogle-btn btn-danger cursor-default" *ngIf="recommendation.approval_status=='2'" >Rejected</mat-button-toggle>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li  *ngFor="let secondChild of servicerequestChild.children;let j = index">
                    <p class="child-job">
                      <span><i class='fa fa-arrow-right'></i> {{secondChild.name}}</span>
                      <span class="btn-copy-paste" *ngIf="serviceInfo.value.recommendation_saved == '1' && secondChild.input != 'imageupload'" (click)="copyJob($event,servicerequestChild,service_request,secondChild)">
                        <i class="fa fa-copy"></i> <span class="hidden-xs"> Copy Job</span>
                      </span>
                    </p>

                    <p class="service-list-sub-items" *ngIf="secondChild.input == 'imageupload'">
                      <span class="relative view-images">
                        <a (click)="imageToolDialog($event,0,9,[secondChild])" class="thumbnail" href="javascript:void(0);">
                            <img src="{{globalConstants.S3_BUCKET_URL}}service-pictures/thumb/{{secondChild.value}}"/>
                        </a>
                      </span>
                    </p>

                    <p class="service-list-sub-items" *ngIf="secondChild.input == 'textarea'">
                      <span style="" class="view-sub-items" [innerHtml]="convertNltoBr(secondChild.value)">
                      </span>
                    </p>
                    <div class="row" *ngIf="serviceInfo.value.recommendation_saved == '1'">

                      <div *ngFor="let recommendation of secondChild.recommendations;let j = index">
                        <div *ngIf="recommendation.service_request_list_id!='158'">
                        <div class="col-sm-12 margin-bottom-15 padding-lr-0-mob margin-top-10" *ngIf="recommendation.parts.length > 0">
                          <div class="form-group">
                            <label class="col-sm-3 col-lg-2 text-right control-label">Part(s):</label>
                            <div class="col-sm-9  col-lg-10 text-justify">
                              <div class="addedPartsItems">
                                <div class="partRow row" [class.text-orange]="part.partstech_cart_item==1 || part.partstech_order_item==1" [class.text-nexpart-green]="part.nexlink_cart_item==1 || part.nexlink_order_item==1"  *ngFor="let part of recommendation.parts;let k = index">
                                  <div class="col-sm-8 pname break-all margin-sm-bottom-7"
                                  data-bs-toggle="tooltip" data-bs-placement="right" title="{{getPartToolTipTitle(part)}}"
                                  >
                                    <span *ngIf="part.part_number!=''">{{part.part_number}} </span><span *ngIf="showCostPrice && hidePrices">(${{part.cost}}) -</span> {{part.name}}<span *ngIf="part.description!=''"> - {{part.description}}</span>
                                  </div>
                                  <div class="col-xs-6 col-sm-2 pprice"><span *ngIf="showCostPrice">$ {{parseFloats(part.part_price)}}</span></div>
                                  <div class="col-xs-6 col-sm-2 pquantity" [class.text-red]="isInventory && part.out_of_stock=='1'">
                                    <i *ngIf="isInventory" class="fa fa-info-circle" title="{{ stockInHand(part)}} "></i> {{part.quantity}}
                                    <!-- <button type="button" class="btn btn-xs btn-link text-red" (click)="addStockOrder(recommendation,part)"><i *ngIf="isInventory && part.out_of_stock=='1'" class="fa fa-cart-plus fa-lg" title="Add to stock order"></i></button> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 margin-bottom-15 padding-lr-0-mob" *ngIf="recommendation.generic_jobs.length > 0">
                          <div class="form-group">
                            <label class="col-sm-3 col-lg-2 text-right control-label">Generic Job(s):</label>
                            <div class="col-sm-9 col-lg-10 text-justify">
                              <div class="addedGenericJobs">
                                <div class="partRow row"  *ngFor="let gjob of recommendation.generic_jobs;let l = index">
                                  <div class="col-sm-8 pname margin-sm-bottom-7">{{gjob.name}}</div>
                                  <div class="col-xs-6 col-sm-2 pprice"><span *ngIf="showCostPrice"> $ {{ parseFloats(gjob.price)}}</span></div>
                                  <div class="col-xs-6 col-sm-2 ppqty">{{gjob.quantity}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 margin-bottom-15 padding-lr-0-mob" *ngIf="recommendation.computer_codes.length > 0">
                          <div class="form-group">
                            <label class="col-sm-3 col-lg-2 text-right control-label">Computer Codes(s):</label>
                            <div class="col-sm-9 col-lg-10 text-justify">
                              <div class="addedGenericJobs">
                                <div class="partRow row"  *ngFor="let cjob of recommendation.computer_codes;let l = index">
                                  <div class="col-sm-12 pname break-all">{{cjob.trouble_code}} {{cjob.fault_location}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="col-sm-12 margin-bottom-15 padding-lr-0-mob" *ngIf="currentUser.subscription_data.nexpart == 1 &&  recommendation.nexpart_labor_time >0">
                          <div class="form-group">
                            <label class="col-sm-3 col-lg-2  hoursLbr text-right control-label" 
                              data-bs-toggle="tooltip" data-bs-placement="right" title="Nexpart: {{parseFloats(recommendation.nexPartTotalLaborHours)}} Hrs, PartsTech: {{parseFloats(recommendation.partsTechTotalLaborHours)}} Hrs">Nexpart/PartsTech Labor (Hours): </label>
                            <div class="col-sm-9 col-lg-10 text-justify break-all">
                              <div class="addedComputerCodes"> {{ parseFloats(recommendation.nexpart_labor_time)}} hour(s)</div>
                            </div>
                          </div>
                        </div>
    
                        <div class="col-sm-12 margin-bottom-15 padding-lr-0-mob">
                          <div class="form-group">
                            <label class="col-sm-3 col-lg-2 text-right control-label">Estimated Time:</label>
                            <div class="col-sm-9 col-lg-10 text-justify break-all">
                              <div class="addedComputerCodes">{{recommendation.estimated_time}} hour(s) </div>
                            </div>
                          </div>
                        </div>


                        <div class="col-sm-12 margin-bottom-15 padding-lr-0-mob" *ngIf="showCostPrice">
                          <div class="form-group">
                            <label class="col-sm-3 col-lg-2 text-right control-label">Estimated Labor:</label>
                            <div class="col-sm-9 col-lg-10 text-justify break-all">
                              <div class="addedComputerCodes">$ {{ parseFloats(recommendation.estimated_labor)}}</div>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-12 margin-bottom-15 padding-lr-0-mob">
                          <div class="form-group">
                            <label class="col-sm-3 col-lg-2 text-right control-label">Description of Work:</label>
                            <div class="col-sm-9 col-lg-10 text-justify break-all">
                              <p [innerHtml]="convertNltoBr(recommendation.recommendation)"></p>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 margin-bottom-15 padding-lr-0-mob">
                          <div class="form-group">
                            <label class="col-sm-3 col-lg-2 text-right control-label">Notes:</label>
                            <div class="col-sm-9 col-lg-10 text-justify break-all">
                              <p [innerHtml]="convertNltoBr(recommendation.notes)"></p>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 margin-bottom-15 already-uploaded-images padding-lr-0-mob">
                          <div class="form-group">
                            <label class="col-sm-3 col-lg-2 text-right control-label">Uploaded Image(s):</label>
                            <div class="col-sm-9 col-lg-10 text-justify">
                              <ul class="list-inline margin-bottom-0" *ngIf="recommendation.uploaded_images.length > 0">
                                <li  class="relative view-images" *ngFor="let image of recommendation.uploaded_images;let i=index;">
                                  <a (click)="imageToolDialog($event,i,4,recommendation.uploaded_images)" class="thumbnail" href="javascript:void(0);">
                                    <img class="show-image" src="{{globalConstants.S3_BUCKET_URL}}recommendation-pictures/thumb/{{image.filename}}">
                                  </a>
                                </li>
                              </ul>
                              <span class="text-danger" *ngIf="recommendation.uploaded_images.length == 0">
                                <span>No images uploaded.</span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 margin-bottom-15 already-uploaded-videos padding-lr-0-mob">
                          <div class="form-group">
                            <label class="col-sm-3 col-lg-2 text-right control-label">Uploaded Video(s):</label>
                              <div class="col-sm-9 col-lg-10 text-justify" *ngIf="recommendation.uploaded_videos.length > 0">
                                <ul id="vehicle_video-ul" class="list-inline margin-bottom-0">
                                  <li class="video-upload relative" *ngFor="let video of recommendation.uploaded_videos;let i=index">
                                    <a class="thumbnail margin-bottom-0" href="javascript:void(0)" (click)="videoShow(video.filename)">
                                      <span class="relative video-bg">
                                        <mat-icon class="position-center play-video">play_circle_filled</mat-icon>
                                      </span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            <div class="col-sm-9  col-lg-10 text-justify" *ngIf="recommendation.uploaded_videos.length == 0">
                              <span class="text-danger">No videos uploaded.
                                <span></span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="display-full col-sm-12 text-right margin-bottom-10" *ngIf="showCostPrice">
                            Job Total : <span *ngIf="levelPriceObj">$ {{levelPriceObj[recommendation.id]}}</span>
                        </div>
                        <div class="col-xs-12 notranslate margin-bottom">
                            <div class="previous-job-status" *ngIf="isShowPrevious() && recommendation.old_approval_status!=0">
                                <div class="alert " [attr.title]="recommendation.old_approval_status=='1' ? 'Previously Accepted' : 'Previously Rejected'" [class.alert-success]="recommendation.old_approval_status=='1'" [class.alert-danger]="recommendation.old_approval_status=='2'">
                                  {{( recommendation.old_approval_status=='1' ? 'Accepted' : 'Rejected' )}}
                                </div>
                            </div>
                          <div class="displayfull text-right btn-wrap" *ngIf="serviceInfo.value.request_status==3 && isAcceptRejectJob">
                            <mat-button-toggle value="accept" (change)="updateText($event, recommendation.id, recommendation,service_request,1)" class="toogle-btn btn-success" [checked]="recommendation.approval_status == '0' || recommendation.approval_status == '2'">
                              {{recommendation.approval_status == 0 ? 'Accept' : recommendation.approval_status == '1' ? 'Undo Accept' : recommendation.approval_status == '2' ? 'Accept': statusAcceptText}}
                            </mat-button-toggle>
                            <mat-button-toggle value="reject" (change)="updateText($event, recommendation.id, recommendation,service_request,2)" class="toogle-btn btn-danger" [checked]="recommendation.approval_status == '0' || recommendation.approval_status == '1'">
                              {{recommendation.approval_status == 0 ? 'Reject' : recommendation.approval_status == '1' ? 'Reject' : recommendation.approval_status == '2' ? 'Undo Reject': statusRejectText}}
                            </mat-button-toggle>
                          </div>
                          <div class="displayfull text-right" *ngIf="serviceInfo.value.request_status!=3">
                            <mat-button-toggle value="Accepted" class="toogle-btn btn-success cursor-default" *ngIf="recommendation.approval_status=='1'">Accepted</mat-button-toggle>
                            <mat-button-toggle value="Rejected" class="toogle-btn btn-danger cursor-default" *ngIf="recommendation.approval_status=='2'">Rejected</mat-button-toggle>
                          </div>
                        </div>
                        </div>
                      </div>

                    </div>
                  </li>
                </ul>
              </li>
            </ul>
            <div class="panel-price" *ngIf="((ConvertToNumber(serviceInfo.value.request_status) >=3 && ConvertToNumber(serviceInfo.value.request_status) <9) || ConvertToNumber(serviceInfo.value.request_status) == 12) && showCostPrice">
              <div class="panel-footer rejectedJobs col-sm-6" role="tab">
                <h4 class="text-right" style="margin:0;"><span>Rejected Job(s) Cost&nbsp;:&nbsp;$</span>
                  <span class="total-job-price-63 notranslate">
                    {{ this.ObjectJson[service_request.id]["RejectedValue"] }}
                  </span>
                </h4>
              </div>
              <div class="panel-footer acceptedJobs col-sm-6" role="tab">
                <h4 class="text-right" style="margin:0;"><span>Total Price&nbsp;:&nbsp;$</span>
                  <span class="total-job-price-63 notranslate">
                    {{this.ObjectJson[service_request.id]["AcceptedValue"] }}
                  </span>
                </h4>
              </div>
            </div>
          </mat-expansion-panel>
          <div class="subjob-category-wrapper-mobile">
            <span class="tooltip-status" [tooltip]="myJobTooltip" class="visible-xs fa fa-info-circle"></span>
            <tooltip-content #myJobTooltip class="subjob-tooltip-container" placement="top">
              <div class="row subjob-category-wrapper-mobile">
                <div class="col-sm-12" *ngFor="let jobCategories of service_request.subJobCategories; let sn = index">
                  <p class="subjobs"><span>{{ sn+1 }}.</span>&nbsp;<span>{{jobCategories}}</span></p>
                 
                </div>
              </div>
            </tooltip-content>
          </div>
        </div>  
      </div>
    </mat-accordion>
    <!-- <div class="final-calculation">
      <p><b>Supply Charges</b> : ${{serviceInfo.value.supplies_charges}}</p>
      <p><b>Grand Total (Incl. of all taxes)</b> : ${{ finalShowAbleAmount }}</p>
      <p><b>Total Paid</b> : ${{serviceInfo.value.total_paid == null ? "0.00" : serviceInfo.value.total_paid}}</p>
    </div> -->

    <div class="disclaimer-and-totals-container notranslate">
      <h4 class="text-right" *ngIf="showCostPrice"><strong>Sub Total : </strong>$ <span class="grand-total"> {{subTotal}}</span></h4>
      
      <label class="checkbox text-right" [class.hide]="!(isAllowed('views','add_supplies_invoice') && showCostPrice)" style="font-size:18px;font-weight:bold;" id="supplyChargesRow" onclick="return false;" *ngIf="serviceInfo.value.is_supplies_charges_included  == '1'">
        <input type="checkbox" name="is_supply_charges_included" id="chkSupplyCharges" class="chk-supply-charges hidden" value="1" disabled="" checked="checked">
        Supply Charges<span *ngIf="serviceDetail.supply_charges_disp_type == 2">({{serviceDetail.supply_charge_percentage}}%)</span> : <span class="supply-charges-value">$ {{ parseFloats(serviceInfo.value.supplies_charges)}}</span>
      </label>
      
      <!-- Coupon Redeem UI only if not completed and paid-->
      <!-- show in view mode to everyone else except shop owner -->
      <app-redeem-coupon #viewRequestCoupon *ngIf="serviceDetail && showCostPrice" [is-view-mode]="serviceDetail.request_status == 8" [context]="this" (couponAdded)="onCouponAdded($event)"></app-redeem-coupon>
      
      <label class="checkbox text-right" [class.hide]="!(isAllowed('views','add_tax_invoice') && showCostPrice)" style="font-size:18px;" id="taxRow" onclick="return false;" *ngIf="serviceInfo.value.is_tax_included  == '1'">
        Tax (<span class="tax-rate-value">{{serviceInfo.value.tax_rate}}% : $ {{totalTax}}</span>)
      </label>
      <h4 class="text-right" *ngIf="showCostPrice"><strong>Grand Total : </strong>$ <span class="grand-total-with-tax">{{ finalShowAbleAmount }}</span></h4>

      <ng-container *ngIf="showCostPrice && serviceInfo.value.total_paid != null && serviceInfo.value.total_paid > 0">
        <hr/>
        <h4 class="text-right" *ngIf="showCostPrice"><strong>Total SR Paid : </strong>$ <span id="totalPaid">{{parseFloats(serviceInfo.value.total_paid)}}</span></h4>
        <h4 class="text-right" *ngIf="showCostPrice"><strong>Total CC Fee Paid : </strong>$ <span id="totalPaid">{{parseFloats(serviceInfo.value.total_cc_fee)}}</span></h4>
        <h4 class="text-right" *ngIf="showCostPrice"><strong>Total Net Paid : </strong>$ <span id="totalPaid">{{parseFloats(totalNetPayed(serviceInfo.value.total_paid, serviceInfo.value.total_cc_fee))}}</span></h4>
        <h4 class="text-right" *ngIf="showCostPrice"><strong>Balance : </strong>$ <span id="balance">{{ parseFloats(balance) }}</span></h4>
      </ng-container>
      <!-- <ng-container  *ngIf="netPayable > 0 && balance > 0 && serviceDetail && serviceDetail.status != '6'"> -->
      <ng-container *ngIf="showCostPrice">
        <hr/>
        <span class="checkbox text-right" style="font-size:18px;font-weight:bold;" id="taxRow">
          <mat-checkbox (change)="calculateCCFee($event)" [checked] = "serviceInfo.value.cc_fee_included == '1'">CC Fee <span *ngIf="serviceDetail && serviceDetail.cc_fee_type == '2'">({{serviceDetail.cc_fee}}%)</span> : $ {{parseFloats(totalCCfeeValue)}}</mat-checkbox>
        </span>
        <h4 class="text-right"><strong>Total Payable: </strong>$ <span class="grand-total-with-tax">{{ parseFloats(netPayable) }}</span></h4>        
      </ng-container>
    </div>
    <div class="disclaimer-and-totals-container margin-top-10">
        <div class="btn-lg alert alert-info uc-alert-info disabled margin-bottom-10 " *ngIf="toNumber(serviceInfo.value.request_status) == 3">
            Approve the recommendation(s) that need to be done to the service request and reject those which are not needed. Then click the below button to apply the changes and continue further.<br>
            <button *ngIf="isAcceptRejectJob" mat-raised-button class="mat-success font-bold mat-elevation-z1 notify-to-admin" (click)="finalUpdateToNotifyAdmin(serviceInfo.value.request_id)">Apply Changes</button>
          </div>
    <div class="alert alert-info relative" *ngIf="isAllowed('views','disclaimer')" style="padding:17px 15px;border-radius:5px;" id="disclaimer">
      <span class="" [innerHTML]="serviceInfo.value.disclaimer"></span>
      <div class="btnChangeDisclaimerModalContainer" *ngIf="isAllowed('edits','disclaimer')">
          <button class="btn btn-danger btn-sm" id="btnChangeDisclaimerModal" style="position:absolute;right:0px;top:0px;padding:2px;"  (click)="disclaimerOpen()">
              <i class="fa fa-edit fa-fw fa-lg"></i>
          </button>
      </div>
    </div>
  </div>
    </div>
  </div>
  <!--
Ticket Assignment SidePanel integration
-->
<app-tech-assignment [data]="techData" (closeTab)="techAssignmentOut($event)" *ngIf="(techData | json) != '{}'"></app-tech-assignment>
