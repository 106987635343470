import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';

@Component({
  selector: 'app-subscribepopup',
  templateUrl: './subscribepopup.component.html'
})
export class SubscribepopupComponent implements OnInit {

  public plans = [];
  public plan: any = { amount: "0", duration: "0" };
  private shopDetail: any = {};
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<SubscribepopupComponent>, private globalService: GlobalService) {
    this.globalService.diaLogObj(this.dialogRef);
    this.shopDetail = data;
  }

  ngOnInit() {
    this.globalService.callAPI(globalConstants.API_MASTER_GET_SUBSCRIPTION_PLANS, {}).subscribe((data:any) => {
      if (data.result == "1") {
        this.plans = data.data;
      }
    })
  }

  changePlan(obj) {
    this.plans.forEach((e, v) => {
      if (e.id == obj.value) {
        this.plan = e;
      }
    });
  }
  submitPlan() {
    let cont = { shop_id: this.shopDetail.shop_id, subscription_id: this.plan.id }
    this.globalService.callAPI(globalConstants.API_MASTER_SHOP_SUBSCRIPTION, cont).subscribe((data:any) => {
      if (data.result == "1") {
        this.globalService.snackbar('success', data.message);
        this.dialogRef.close(1);
      } else {
        this.globalService.snackbar('error', data.message);
      }
    })
  }

}
