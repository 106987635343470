import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';

@Component({
  selector: 'app-searchcustomer',
  templateUrl: './searchcustomer.component.html',
  styleUrls: ['./searchcustomer.component.scss']
})
export class SearchcustomerComponent implements OnInit {

  public customerDetails:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public globalService: GlobalService, private router: Router) {
    this.customerDetails = data.customerDetails;
  }

  ngOnInit() {
  }

  viewVehicle() {
    localStorage.setItem("listCustomerID", this.customerDetails.customer_id);
    this.router.navigate(['shops/list-cars']);
  }
}
