
<div mat-dialog-title class="relative">
    <h4 class="margin-top-0">{{ searchBy === 'licence'?'License Plate':'VIN' }} already exist under this profile</h4>
    <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0" id="close">
        <mat-icon class="notranslate close-icon-lg">close</mat-icon>
      </button>
</div>
<mat-dialog-content class="overflow-data">
    <div class="center-block" style="max-width: 600px;">        
        <form class="form-horizontal form-control-normal" *ngIf="customerVehcileInfo">
            <div class="customer-search">
                <div class="row margin-bottom-20 display-full">
                    <div class="col-xs-4">
                        <div class="field-name">
                            VIN
                        </div>
                    </div>
                    <div class="col-xs-8">
                        <div class="field-name">
                            {{customerVehcileInfo.vehicle_info.vin}}
                        </div>
                    </div>
                </div>
                <div class="row margin-bottom-20 display-full">
                    <div class="col-xs-4">
                        <div class="field-name">
                            License Plate
                        </div>
                    </div>
                    <div class="col-xs-8">
                        <div class="field-name" style="text-transform:uppercase;">
                            {{customerVehcileInfo.vehicle_info.license_plate}}
                        </div>
                    </div>
                </div>
                <div class="row margin-bottom-20 display-full">
                    <div class="col-xs-4">
                        <div class="field-name">
                            Customer Name
                        </div>
                    </div>
                    <div class="col-xs-8">
                        <div class="field-name large-txt">
                            {{customerVehcileInfo.vehicle_info.customer_name}}
                        </div>
                    </div>
                </div> 
                <div class="row margin-bottom-20 display-full">
                    <div class="col-xs-12">
                        <strong class="strong">
                            What I can do next?
                        </strong>
                    </div>
                </div>
                <div class="row margin-bottom-20 display-full" *ngIf="status === 1 || status === 2">
                    <div class="col-xs-6 car-xs-width">
                        <div class="field-name">
                            Create Service Request for <span class="large-txt">{{customerVehcileInfo.vehicle_info.customer_name}}</span>? 
                        </div>
                    </div>
                    <div class="col-xs-6 car-xs-width button">
                        <button type="button" [mat-dialog-close]="true" class="close-modal btn fontcolor-white mat-raised-button mat-accent" (click)="createSR()">Create Service Request Now</button>
                    </div>
                </div>
                <div class="row margin-bottom-20 display-full" *ngIf="status === 2">
                    <div class="col-xs-4">
                    </div>
                    <div class="col-xs-4 text-center">
                        OR
                    </div>
                </div>
                <div class="row margin-bottom-20 display-full" *ngIf="status === 2">
                    <div class="col-xs-6 car-xs-width">
                        <div class="field-name">
                            Create Service Request under <span class="large-txt">{{shopCustomerDetails.first_name}} {{shopCustomerDetails.last_name}}</span> that entered your shop
                        </div>
                    </div>
                    <div class="col-xs-6 car-xs-width button">
                        <button type="button" [mat-dialog-close]="true" class="btn fontcolor-white mat-raised-button mat-accent" (click)="createSRForAnotherUser()">Create Service Request Now</button>
                    </div>
                </div>
                <!-- <div class="row margin-bottom-20 display-full" *ngIf="status === 3">
                    <div class="col-xs-4">
                    </div>
                    <div class="col-xs-4 text-center">
                        OR
                    </div>
                </div> -->
                <div class="row margin-bottom-20 display-full" *ngIf="status === 3">
                    <div class="col-xs-5 car-xs-width">
                        <div class="field-name">
                        Working on a sublet work? - Is this a Sublet job from another shop?
                        </div>
                    </div>
                    <div class="col-xs-7 car-xs-width button">
                        <button type="button" [mat-dialog-close]="true" class="close-modal btn fontcolor-white mat-raised-button mat-accent" (click)="createSubletSR()">Create Sublet Service Request</button>
                    </div>
                </div>
            </div>
        </form>
        <div class="customer-search" *ngIf="!customerVehcileInfo">
            <div class="row margin-bottom-20 display-full">
                <div class="col-xs-12 text-center">
                    <div class="field-name">
                        No Record Found
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>