import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { GlobalService } from '../../../../environments/global.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { globalConstants } from '../../../../environments/globalConstants';
import { globalFunctions } from '../../../../environments/globalFunctions';
import { DatePipe } from '@angular/common';
import { AppDateAdapter, APP_DATE_FORMATS } from '../shopeditcar/shopeditcar.component';
import { IDatePickerConfig } from 'ng2-date-picker';
import { ExtrafieldListComponent } from '../extrafield-list/extrafield-list.component';
import { VideoShowComponent } from '../../../customer/my-vehicles/video-show/video-show.component';
import { ImagetooldialogComponent } from '../../../imagetooldialog/imagetooldialog.component';
import { SearchcarComponent } from '../searchcar/searchcar.component';
import { log } from 'console';
@Component({
  selector: 'app-editpopup',
  templateUrl: './editpopup.component.html',
  styleUrls: ['./editpopup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    DatePipe,
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class EditpopupComponent implements OnInit {

  public user: any = null
  public param: any = {}
  public carForm: FormGroup
  public vehicleId
  public carCustomerId
  public vehicleDetails;
  public carMakeList;
  public carModels;;
  public years;
  public states;
  public selectedMake;
  public selectedYear;
  public selectedModel;
  public EnterNewMakeButton = true;
  public EnterNewYearButton = true;
  public EnterNewModelButton = true;
  public UseExistingMakeButton = false;
  public UseExistingYearButton = false;
  public UseExistingModelButton = false;
  public EnterNewMake = false;
  public EnterNewYear = false;
  public EnterNewModel = false;
  public UseExisitngMake = true;
  public UseExistingYear = true;
  public UseExisitngModel = true;
  public disableYears;
  public disableModels;
  public yearsValue;
  public makesValue;
  public modelsValue;
  public disabledYear;
  public disabledModel;
  public validCar = false;
  public wheelLock = []
  public antWheellocklocation = true
  public customFieldList = []
  public preCustomFields = []
  public otherLabelsTemp = []
  public submitted: boolean = false;
  public carColors: any = globalFunctions.carColors()
  public addNewColor = false;
  public vehicleColor;
  public phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public dateMask = [/[1-9]/, /[1-9]/, '/', /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/];
  public touchViewState: boolean;
  public countries: any = [];
  public customerCountryID;
  public shopDetail: any;
  public loginUserDetails: any;
  public gc = globalConstants;
  datePickerConfig: IDatePickerConfig = {
    format: "MM/YYYY",
    closeOnSelect: true
  };
  public addVinConfirmation: any;
  public uspsVehicle;
  public engineSize;
  public FORMAT_SUPPORTED_FOR_IMAGE = globalConstants.FORMAT_SUPPORTED_FOR_IMAGE;
  public FORMAT_SUPPORTED_FOR_VIDEO = globalConstants.FORMAT_SUPPORTED_FOR_VIDEO;
  public MAX_ALLOWED_IMAGE_SIZE = globalConstants.MAX_ALLOWED_IMAGE_SIZE;
  public MAX_ALLOWED_IMAGE_DISPLAYED_TEXT = globalConstants.MAX_ALLOWED_IMAGE_DISPLAYED_TEXT;
  public MAX_ALLOWED_VIDEO_SIZE = globalConstants.MAX_ALLOWED_VIDEO_SIZE;
  public MAX_ALLOWED_VIDEO_DISPLAYED_TEXT = globalConstants.MAX_ALLOWED_VIDEO_DISPLAYED_TEXT;
  public imgPath = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_PICTURES + globalConstants.IMG_THUMB;
    public insurance = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_INSURANCE + globalConstants.IMG_THUMB;
    public registeredPicture = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_REGISTRATION_PIC + globalConstants.IMG_THUMB;
    public vinPicture = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_VIN_PIC + globalConstants.IMG_THUMB;
    public vehVideo = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_VIDEO;
    public VEHICLE_LABEL_PIC= globalConstants.S3_BUCKET_URL +globalConstants.VEHICLE_LABEL_PICTURES + globalConstants.IMG_THUMB
    public vPic = [];
    public vehicleInsuredPic = [];
    public vehicleRegisteredPic = [];
    public vehicleVinPic = [];
    public videoList = [];
    public userCountryID;
    public carState;
    public vinPhoto = '';
    public vinPhotoSet = '';
    public vinPhotoSetLoader = false;
    public vehicleByVin;
    public vehicleByLicence;
    public shopCustomerDetails;
    public labourRatesType:any =[];
    public labourType :any ;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private datePipe: DatePipe, public gs: GlobalService,  public dialogRef: MatDialogRef<EditpopupComponent>, public dialog: MatDialog, private form: FormBuilder) {
    this.gs.diaLogObj(this.dialogRef);
    this.user = this.gs.getCurrentUser()
    this.param = data
    this.wheelLock = globalFunctions.wheelLock()
  }

  ngOnInit() {

    /**
   * Retrieve the logged-in user's details from local storage, construct a payload, and call the API to get shop details. 
   * Assign the returned shop details to the 'shopDetail' property.
   */
    this.loginUserDetails = JSON.parse(localStorage.getItem("loggedInUser"));
    var payload = "user_id=" + this.loginUserDetails.shop_id + "&user_type=" + this.loginUserDetails.user_type + "&shop_id=" + this.loginUserDetails.shop_id;
    this.gs.callAPI(globalConstants.API_SHOP_DETAILS, payload).subscribe((data: any) => {
      this.shopDetail = data.data;
    });

    if (!this.user) {
      this.dialogRef.close();
    }
    this.vehicleId=this.param.car_id;
    this.carCustomerId=this.param.customer_id;
    this.getLabourType();
    this.formInit();
    this.getDetail();
    this.getShopCustomerDetails();

  }

  getLabourType() {
    var currentCustomer: any = JSON.parse(localStorage.getItem("loggedInUser"));
    var stringifiedFormData = "user_type=" + currentCustomer.user_type + "&user_id=" + currentCustomer.id + "&shop_id=" + currentCustomer.shop_id;
    this.gs.callAPI(globalConstants.API_GET_CUSTOMER_TYPES, stringifiedFormData).subscribe(data => {
      this.labourRatesType = data.data;
      this.labourRatesType.unshift({
        id: "0",
        type: "Select Default Labor Rate"
      });
    });
  }

  setOptionOfLabourType(opt) {
    this.carForm.controls.customer_type_id.setValue(opt);
  }

  getDetail() {
    let data = { customer_id: this.param.customer_id, vehicle_id: this.param.car_id, user_id: this.user.id, user_type: this.user.user_type }
    this.gs.callAPI(globalConstants.API_GET_CUSTOMER_VEHICLES_URL, data).subscribe((r:any) => {
      if (r.result == 1) {
        this.vehicleDetails = r.data
        let registrationDateFromDB = this.vehicleDetails.registration_expiration_date;
        if (registrationDateFromDB != '0000-00-00 00:00:00' && registrationDateFromDB != "" && registrationDateFromDB !== null) {
          registrationDateFromDB = this.gs.getFullFormateDate(registrationDateFromDB, 'L', 'B');
          this.carForm.controls.registration_expiration_date_frm.setValue(new Date(registrationDateFromDB));
          this.carForm.controls.registration_expiration_date.setValue(this.vehicleDetails.registration_expiration_date);
        }
        // Insurance date
        let insuranceDateFromDB = this.vehicleDetails.insurance_expiration_date;
        if (insuranceDateFromDB != '0000-00-00 00:00:00' && insuranceDateFromDB != "" && insuranceDateFromDB!== null) {
          insuranceDateFromDB = this.gs.getFullFormateDate(insuranceDateFromDB, 'L', 'B');
          this.carForm.controls.insurance_expiration_date_frm.setValue(new Date(insuranceDateFromDB));
          this.carForm.controls.insurance_expiration_date.setValue(this.vehicleDetails.insurance_expiration_date);
        }
        let inspecDateFromDB = this.vehicleDetails.inspection_expiration_date;
        if (inspecDateFromDB != '0000-00-00 00:00:00' && inspecDateFromDB != "" && inspecDateFromDB!== null) {
          inspecDateFromDB = this.gs.getFullFormateDate(inspecDateFromDB, 'L', 'B');
          this.carForm.controls.inspection_expiration_date_frm.setValue(new Date(inspecDateFromDB));
          this.carForm.controls.inspection_expiration_date.setValue(this.vehicleDetails.inspection_expiration_date);
        }

        this.carForm.controls.driver.setValue(this.vehicleDetails.driver)
        this.carForm.controls.State.setValue(this.vehicleDetails.state)
        if (this.vehicleDetails.production_date) {
          this.carForm.controls.production_date.setValue(this.datePipe.transform(this.vehicleDetails.production_date, 'MM/yyyy'));
        }
        this.carModels = [];
        this.years = [];
        this.vinPhoto = this.vehicleDetails.vin_photo;
        this.selectedMake = this.vehicleDetails.make;
        this.carForm.value.make = this.vehicleDetails.make;
        this.carForm.controls.make.setValue(this.vehicleDetails.make);
        this.carForm.controls.sel_make.setValue(this.vehicleDetails.make);
        //this.getYears(this.vehicleDetails.make);
        this.selectedYear = this.vehicleDetails.year;
        this.carForm.controls.year.setValue(this.vehicleDetails.year);
        this.carForm.controls.sel_year.setValue(this.vehicleDetails.year);
        //this.getModelList(this.selectedYear);
        this.selectedModel = this.vehicleDetails.model;
        this.carForm.controls.model.setValue(this.vehicleDetails.model);
        this.carForm.controls.sel_model.setValue(this.vehicleDetails.model);
        this.carForm.controls.customer_type_id.setValue(this.vehicleDetails.customer_type_id);
        this.labourType = this.vehicleDetails.customer_type_id;
        this.carForm.controls.color.setValue(this.vehicleDetails.color);
        this.carState = this.vehicleDetails.state;
        this.vehicleColor = this.vehicleDetails.color;
        
        let vpics = [];
        if (this.vehicleDetails.vehicle_pic.length) {
          for (var i in this.vehicleDetails.vehicle_pic) {
            this.vehicleDetails.vehicle_pic[i].loader = false;
            this.vehicleDetails.vehicle_pic[i].vehiclePic = '';
            vpics.push(this.vehicleDetails.vehicle_pic[i]);
          }
        }

        if (this.vehicleDetails.vehicle_insured_pic.length) {
          for (var i in this.vehicleDetails.vehicle_insured_pic) {
            this.vehicleDetails.vehicle_insured_pic[i].loader = false;
            this.vehicleDetails.vehicle_insured_pic[i].vehicleInsured_pic = '';
            this.vehicleInsuredPic.push(this.vehicleDetails.vehicle_insured_pic[i]);
          }
        }
        if (this.vehicleDetails.vehicle_registered_pic.length) {
          for (var i in this.vehicleDetails.vehicle_registered_pic) {
            this.vehicleDetails.vehicle_registered_pic[i].loader = false;
            this.vehicleDetails.vehicle_registered_pic[i].vehicleRegistered_pic = '';
            this.vehicleRegisteredPic.push(this.vehicleDetails.vehicle_registered_pic[i]);
          }
        }
        if (this.vehicleDetails.vehicle_video.length) {
          for (var i in this.vehicleDetails.vehicle_video) {
            this.vehicleDetails.vehicle_video[i].loader = false;
            this.vehicleDetails.vehicle_video[i].vehicleVideo = '';
            this.videoList.push(this.vehicleDetails.vehicle_video[i]);
          }
        }
        if (this.vehicleDetails.vehicle_label_pic.length > 0) {
          for (var i in this.vehicleDetails.vehicle_label_pic) {
            this.vehicleDetails.vehicle_label_pic[i].loader = false;
            this.vehicleDetails.vehicle_label_pic[i].other_labels_temp = '';
            this.otherLabelsTemp.push(this.vehicleDetails.vehicle_label_pic[i]);
          }
        }
        if (this.vehicleDetails.california_emissions == 1) {
          this.carForm.controls.california_emmissions.setValue(1);
        }
        this.carForm.controls.transmission.setValue(this.vehicleDetails.transmission);
        this.carForm.controls.brake.setValue(this.vehicleDetails.brake);
        this.carForm.controls.driveline.setValue(this.vehicleDetails.driveline);
        this.carForm.controls.gvwr.setValue(this.vehicleDetails.gvwr);
        this.carForm.controls.wheel_base.setValue(this.vehicleDetails.wheel_base);
        this.carForm.controls.tire_size.setValue(this.vehicleDetails.tire_size);
        this.carForm.controls.wheel_lock_location.setValue(this.vehicleDetails.wheel_lock_location);
        this.checkExistingLoclLocation()
        this.vPic = vpics;
        this.uspsVehicle = this.vehicleDetails.usps_vehicle;
        this.customerCountryID = this.vehicleDetails.country_id;
        this.engineSize = (this.vehicleDetails.sub_model!= 'undefined') ? this.vehicleDetails.sub_model : '';
        var statesOpr = "country_id=" + this.customerCountryID + "&opr=1";
        this.carForm.controls.standard_days.setValue(this.vehicleDetails.standard_days);
        this.carForm.controls.standard_mileage.setValue(this.vehicleDetails.standard_mileage);
        this.gs.callAPI(globalConstants.API_GET_STATES_URL, statesOpr).subscribe((data:any) => {
            this.states = globalConstants.stateDDLPlaceholder.concat(data.data);
            var slist=[];
            for(var i in this.states){
                this.states[i].id=this.states[i].state_name;
                this.states[i].text=this.states[i].state_name;
                slist.push(this.states[i]);
            }
            this.states=slist;
            this.setSelectedOptionState();
        });

        var colorList = [];
        for (var i in this.carColors) {
          this.carColors[i].id = this.carColors[i].id;
          this.carColors[i].text = this.carColors[i].text;
          colorList.push(this.carColors[i]);
          // this.colorsArray[this.carColors[i].id]=(this.carColors[i].text);
        }
        this.carColors = colorList;
        let custFields = []
        if (this.vehicleDetails && typeof this.vehicleDetails.custom_fields != 'undefined' && this.vehicleDetails.custom_fields.length) {
          custFields = this.vehicleDetails.custom_fields
        }
        this.getCustomField(custFields)
      }
      this.checkExistingColor();
      //this.getCarMakes();
      this.getMakeYearModelByVehicle();
 
    }, (error) => { })
  }


  formInit() {
    this.carForm = this.form.group({
      vin: ['',Validators.pattern(globalConstants.NAME_REGEX)],
      production_date: [''],
      year: ['',Validators.pattern('^[12][0-9]{3}$')],
      make: [''],
      model: [''],
      sel_make: [''],
      sel_year: [''],
      sel_model: [''],
      sub_model: [''],
      mileage: [''],
      license_plate: [''],
      color: [''],
      select_color: [''],
      unit_number: [''],
      driver: [''],
      driver_phone_number: [''],
      distance_covered: ['', [Validators.pattern(/^\d+(\.\d)?$/)]],
      vehicle_registered_pic: [''],
      vehicle_insured_pic: [''],
      vehicle_pic: [''],
      vehicle_video: [''],
      registration_expiration_date_frm: [''],
      registration_expiration_date: [''],
      insurance_expiration_date_frm: [''],
      insurance_expiration_date: [''],
      vin_photo: [''],
      State: [''],
      vehicle_id: [this.vehicleId],
      customer_id: [this.carCustomerId],
      old_customer_id: [''],
      is_make: [1],
      is_year: [1],
      is_model: [1],
      is_color: [1],
      is_license: [1],
      inspection_expiration_date_frm: [''],
      inspection_expiration_date: [''],
      california_emmissions: [''],
      transmission: [''],
      brake: [''],
      driveline: [''],
      gvwr: [''],
      wheel_base: [''],
      tire_size: [''],
      wheel_lock_location: [''],
      custom_fields: [''],
      other_labels_temp: [''],
      customer_type_id:[''],
      standard_mileage: ['', [Validators.pattern(/^\d+(\.\d)?$/)]], 
      standard_days:['', [Validators.pattern(/^\d*$/)]]
    })
  }
  changeDateFormat(dateValue) {
    return new Date(dateValue).getFullYear() + '-' + (new Date(dateValue).getMonth() + 1) + '-' + new Date(dateValue).getDate();
  }
  editCar() {

    this.stateValidation();
    if (this.carForm.valid) {
      this.carForm.value.vehicle_id = this.vehicleId;
      if (this.carForm.value.registration_expiration_date_frm != '') {
        let regDate = this.changeDateFormat(this.carForm.value.registration_expiration_date_frm);
        this.carForm.controls.registration_expiration_date.setValue(regDate)
      } else {
        this.carForm.controls.registration_expiration_date.setValue('')
      }

      if (this.carForm.value.insurance_expiration_date_frm != '') {
        let insDate = this.changeDateFormat(this.carForm.value.insurance_expiration_date_frm);
        this.carForm.controls.insurance_expiration_date.setValue(insDate)
      } else {
        this.carForm.controls.insurance_expiration_date.setValue('')
      }
      if (this.carForm.value.inspection_expiration_date_frm != '') {
        let insDate = this.changeDateFormat(this.carForm.value.inspection_expiration_date_frm);
        this.carForm.controls.inspection_expiration_date.setValue(insDate)
      } else {
        this.carForm.controls.inspection_expiration_date.setValue('')
      }
      let custFields = [];
      if (this.customFieldList.length) {
        for (let n in this.customFieldList) {
          let cf = this.customFieldList[n]
          let nid = (typeof cf.vehicle_profile_field_id != 'undefined' ? cf.vehicle_profile_field_id : false);
          let t: any = { id: (nid ? nid : cf.id), value: cf.value }
          custFields.push(t)
        }
        this.carForm.controls.custom_fields.setValue(JSON.stringify(custFields))
      }

      var content = globalFunctions.stringifyFormData(this.carForm.value, null)
      if (this.carForm.value.vin != "" || this.validCar) {
        this.submitCar(content);
      } else {
        this.gs.confirmDialog(1, 'You have not entered the VIN Number. You will not be able to create a service request without it. Do you still want to continue without entering it?').subscribe((res) => {
          this.addVinConfirmation = res;
          if (this.addVinConfirmation == 1) {
            this.submitCar(content);
          }
        });
      }
    } else {
      this.submitted = true;
      this.gs.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
  }
  submitCar(content) {
    this.gs.callAPI(globalConstants.API_EDIT_VEHICLE_URL, content).subscribe((data:any) => {
      var message = data.message;
      if (data.result == "1") {
        this.gs.snackbar('success', message);
      } else {
        this.gs.snackbar('error', message);
        if (data.code == "311" || data.code == "310" ) {
          this.checkVIN();
        }else if (data.code == "312" || data.code == "313" ) {
          this.checkLicence();
        }
      }
    });
  }
  stateValidation() {
    this.checkIfValid();
  }
  checkIfValid() {
    let x = ['sel_make', 'sel_year', 'sel_model', 'license_plate', 'State', 'color'];
    this.validCar = false;
    let lp = this.gs.isFleet()
    if (this.EnterNewMake || this.EnterNewYear || this.EnterNewModel) {
      this.validCar = true;
    }
    for (const key in this.carForm.controls) {
      if (x.indexOf(key) != -1) {
        this.carForm.get(key).clearValidators();
        this.carForm.get(key).setErrors(null);
        this.carForm.get(key).updateValueAndValidity({ onlySelf: false, emitEvent: false });
      }
    }
    if (this.validCar) {
      this.carForm.get('select_color').setValidators(null);
      this.carForm.get('color').setValidators(null);
      this.carForm.get('license_plate').setValidators(null);
      this.carForm.get('State').setValidators(null);
    } else {
      this.carForm.get('color').setValidators([Validators.required]);
      this.carForm.get('select_color').setValidators([Validators.required]);
      this.carForm.get('license_plate').setValidators([Validators.required]);
      this.carForm.get('State').setValidators([Validators.required]);
    }
    if (this.EnterNewMake) {
      this.carForm.get('sel_make').setValidators(null);
    } else {
      (!this.validCar) && this.carForm.get('sel_make').setValidators([Validators.required]);
    }
    if (this.EnterNewYear) {
      this.carForm.get('sel_year').setValidators(null);
    } else {
      (!this.validCar) && this.carForm.get('sel_year').setValidators([Validators.required])
    }
    if (this.EnterNewModel) {
      this.carForm.get('sel_model').setValidators(null);
    } else {
      (!this.validCar) && this.carForm.get('sel_model').setValidators([Validators.required])
    }
    if (lp) {
      this.carForm.get('license_plate').setValidators(null);
    }
    for (const key in this.carForm.controls) {
      if (x.indexOf(key) != -1) {
        this.carForm.get(key).updateValueAndValidity({ onlySelf: false, emitEvent: false });
      }
    }
    this.carForm.controls.is_make.setValue((this.EnterNewMake || this.validCar ? "0" : "1"));
    this.carForm.controls.is_year.setValue((this.EnterNewYear || this.validCar ? "0" : "1"));
    this.carForm.controls.is_model.setValue((this.EnterNewModel || this.validCar ? "0" : "1"));
    this.carForm.controls.is_color.setValue((this.EnterNewMake || this.validCar ? "0" : "1"));
    this.carForm.controls.is_license.setValue((this.EnterNewMake || this.validCar ? "0" : "1"));

    if (this.carForm.value.production_date == "" || typeof this.carForm.value.production_date == "undefined") {
      this.carForm.controls.production_date.setValue("");
    }
    if (this.carForm.valid) {
      this.submitted = false;
    }
  }

  getMakeYearModelByVehicle(){
    this.gs.callAPI(globalConstants.API_GET_YEAR_MODEL_LIST, {vehicle_id: this.param.car_id}).subscribe((data:any) => {
      if (data.result == "1") {  
        this.disabledYear  = false;  
        this.disabledModel = false; 
        if('make' in data.data && Array.isArray(data.data.make)){
          this.years  = [];
          this.carMakeList  = globalConstants.carMakeDDLPlaceHolder.concat(data.data.make);
          for (var i in this.carMakeList) {
            this.carMakeList[i].id   = this.carMakeList[i].make;
            this.carMakeList[i].text = this.carMakeList[i].make;
          }
          setTimeout(() => {
            this.checkExistingMakeYearModel();
          }, 800);         
        }

        if('year' in data.data && Array.isArray(data.data.year)){
          this.years = [];
          this.years = globalConstants.carYearDDLPlaceHolder.concat(data.data.year);
          for (var i in this.years) {
            this.years[i].id = this.years[i].year;
            this.years[i].text = this.years[i].year;
          }
        }

        if('model' in data.data && Array.isArray(data.data.model)){
          this.carModels = [];
          this.carModels = globalConstants.carModelDDLPlaceholder.concat(data.data.model);
          for (var i in this.carModels) {
            this.carModels[i].id = this.carModels[i].model + (this.carModels[i].trim ? ' ' + this.carModels[i].trim : '') + (this.carModels[i].body_style ? ' ' + this.carModels[i].body_style : '')
            this.carModels[i].text = this.carModels[i].model + (this.carModels[i].trim ? ' ' + this.carModels[i].trim : '') + (this.carModels[i].body_style ? ' ' + this.carModels[i].body_style : '')
          }
        }

      } else {
        this.disabledYear  = true;
        this.disabledModel = true; 
      }
    });
  }

  getYears(event) {

    this.carModels = [];
    if (event == undefined || event == "") {

      this.years = [];
      this.carModels = [];
      this.disabledYear = true;
      this.disabledModel = true;
    } else {

      this.gs.callAPI(globalConstants.API_GET_YEAR_LIST, "opr=1&make=" + event).subscribe((data:any) => {
        if (data.result == "1") {
          this.disabledYear = false;
          // var yearsList = [];
          if (data.data.length == undefined) {
            this.years = [];
            this.years[0] = globalConstants.carYearDDLPlaceHolder.concat(data.data);
            /* FOR SEARCH BOX DROPDOWN SELECT */
            this.years.id = this.years.year;
            this.years.text = this.years.year;
            // yearsList.push(this.years);
            // this.years = yearsList;
            /* END FOR V2 SELECT */
          } else {
            this.years = globalConstants.carYearDDLPlaceHolder.concat(data.data);
            /* FOR SEARCH BOX DROPDOWN SELECT */
            for (var i in this.years) {
              this.years[i].id = this.years[i].year;
              this.years[i].text = this.years[i].year;
              // yearsList.push(this.years[i]);
            }
            // this.years = yearsList;
            /* END FOR V2 SELECT */
          }
        } else {
          this.disabledYear = true;
        }
      });
    }
  }

  getModelList(event) {
    if (event == undefined || event == '') {
      this.carModels = [
        {
          body_style: "",
          id: "Select Model",
          make: "",
          model: "Select Model",
          text: "Select Model",
          trim: "",
          year: ""
        }
      ];
      this.disabledModel = true;
    } else {

      this.gs.callAPI(globalConstants.API_GET_MODELS_LIST_URL, "opr=1&make=" + this.selectedMake + "&year=" + event).subscribe((data:any) => {
        if (data.result == "1") {
          this.disabledModel = false;
          // var modelsList = [];
          if (data.data.length != undefined) {
            this.carModels = globalConstants.carModelDDLPlaceholder.concat(data.data);
            /* FOR SEARCH BOX DROPDOWN SELECT */
            for (var i in this.carModels) {
              this.carModels[i].id = this.carModels[i].model + (this.carModels[i].trim ? ' ' + this.carModels[i].trim : '') + (this.carModels[i].body_style ? ' ' + this.carModels[i].body_style : '')
              this.carModels[i].text = this.carModels[i].model + (this.carModels[i].trim ? ' ' + this.carModels[i].trim : '') + (this.carModels[i].body_style ? ' ' + this.carModels[i].body_style : '')
              // modelsList.push(this.carModels[i]);
            }
            // this.carModels = modelsList;
            /* END FOR V2 SELECT */
          } else {
            this.carModels[0] = globalConstants.carModelDDLPlaceholder.concat(data.data);
            /* FOR SEARCH BOX DROPDOWN SELECT */
            this.carModels.id = this.carModels.model + (this.carModels.trim ? ' ' + this.carModels.trim : '') + (this.carModels.body_style ? ' ' + this.carModels.body_style : '')
            this.carModels.text = this.carModels.model + (this.carModels.trim ? ' ' + this.carModels.trim : '') + (this.carModels.body_style ? ' ' + this.carModels.body_style : '')
            // modelsList.push(this.carModels);
            // this.carModels = modelsList;
            /* END FOR V2 SELECT */
          }
        }
      });
    }
  }
  getCarMakes() {
    this.gs.callAPI(globalConstants.API_GET_CAR_MAKES, "opr=1").subscribe((data:any) => {
      if (data.result == "1") {
        this.carMakeList = globalConstants.carMakeDDLPlaceHolder.concat(data.data);
        /* FOR SEARCH BOX DROPDOWN */
        var makelist = [];
        for (var i in this.carMakeList) {
          this.carMakeList[i].id = this.carMakeList[i].make;
          this.carMakeList[i].text = this.carMakeList[i].make;
          makelist.push(this.carMakeList[i]);
        }
        this.carMakeList = makelist;
      }
      this.checkExistingMakeYearModel();
    });
  }
  checkExistingMakeYearModel() {
    this.enterNewMake();
    // Optimize the loop by using find to reduce iteration time
    const existingMake = this.carMakeList.find(make => make.make === this.vehicleDetails.make);
    if (existingMake) {
      this.useExitingMake();
      this.enterNewYear();
  
      const existingYear = this.years.find(year => year.year === this.vehicleDetails.year);
      if (existingYear) {
        this.useExitingYear();
        this.enterNewModel();
  
        const existingModel = this.carModels.find(model => {
          let modelStr = model.model;
          if (model.trim) modelStr += ` ${model.trim}`;
          if (model.body_style) modelStr += ` ${model.body_style}`;
          return modelStr === this.vehicleDetails.model;
        });
  
        if (existingModel) {
          this.useExitingModel();
        }
      }
    }
  }


  checkExistingColor() {
    for (var i = 0; i < this.carColors.length; i++) {
      if (this.carColors[i].id == this.vehicleColor) {
        this.addNewColor = false;
        return;
      } else {
        this.addNewColor = true;
      }
    }
  }
  // Make
  enterNewMake() {
    this.EnterNewMake = true;
    this.EnterNewYear = true;
    this.EnterNewModel = true;
    let oldValueMake = this.selectedMake;
    let oldValueYear = this.selectedYear;
    let oldValueModel = this.selectedModel;
    if (this.EnterNewMakeButton == true) {
      if(oldValueMake == 'Select Make'){
         this.selectedMake = '';
      }
      if(oldValueYear == 'Select Year'){
        this.selectedYear = '';
      }
      if(oldValueModel == 'Select Model'){
          this.selectedModel = '';
      }
      this.EnterNewMakeButton = false;//show Existing Make
      this.UseExistingMakeButton = true;//Hide Another
      this.EnterNewYearButton = false;//Hide Existing Year
      this.UseExistingYearButton = false;//Hide Another
      this.EnterNewModelButton = false;//Hide Existing Model
      this.UseExistingModelButton = false;//Hide Another
    } else {
      this.selectedMake = oldValueMake;
      this.selectedYear = oldValueYear;
      this.selectedModel = oldValueModel;
      this.EnterNewMakeButton = true;//hide Existing Make
      this.UseExistingMakeButton = false;//show Another
      this.EnterNewYearButton = true;//show Existing Year
      this.UseExistingYearButton = false;//Hide Another
      this.EnterNewModelButton = true;//show Existing Model
      this.UseExistingModelButton = false;//Hide Another
    }

    if (this.disabledYear == false || this.disabledModel == false) {
      this.disabledYear = true;
      this.disabledModel = true;
    } else {
      this.disabledYear = false;
      this.disabledModel = false;
    }
    this.checkIfValid();
  }
  useExitingMake() {
    if (this.makesValue != "") {
      this.disabledYear = false;
    }
    if (this.modelsValue != "") {
      this.disabledModel = false;
    }
    this.EnterNewMake = false;
    this.EnterNewYear = false;
    this.EnterNewModel = false;
    this.EnterNewMakeButton = true;//hide Existing Make
    this.UseExistingMakeButton = false;//show Another
    this.EnterNewYearButton = true;//show Existing Year
    this.UseExistingYearButton = false;//Hide Another
    this.EnterNewModelButton = true;//show Existing Model
    this.UseExistingModelButton = false;//Hide Another
    //this.car.controls.year.reset();
    this.checkIfValid();
  }
  enterNewYear() {
    let oldValueYear = this.selectedYear;
    let oldValueModel = this.selectedModel;
    if(oldValueYear == 'Select Year'){
      this.selectedYear = '';
    }else{
      this.selectedYear = oldValueYear;
    }
    if(oldValueModel == 'Select Model'){
        this.selectedModel = '';
    }else{
      this.selectedModel = oldValueModel;
    }
    this.EnterNewYear = true;
    this.EnterNewModel = true
    this.EnterNewYearButton = false;//show Existing Year
    this.UseExistingYearButton = true;//Hide Another
    this.EnterNewModelButton = false;//show Existing Model
    this.UseExistingModelButton = false;//Hide Another
    this.checkIfValid();
  }
  useExitingYear() {
    this.EnterNewYear = false;
    this.EnterNewModel = false
    this.EnterNewYearButton = true;//show Existing Year
    this.UseExistingYearButton = false;//Hide Another
    this.EnterNewModelButton = true;//show Existing Model
    this.UseExistingModelButton = false;//Hide Another
    //this.car.controls.model.reset();
    this.checkIfValid();
  }
  enterNewModel() {
    let oldValueModel = this.selectedModel;
    if (oldValueModel == 'Select Model') {
      this.selectedModel = '';
    } else {
      this.selectedModel = oldValueModel;
    }
    this.EnterNewModel = true
    this.EnterNewModelButton = false;
    this.UseExistingModelButton = true;//Hide Another
    this.checkIfValid();
  }
  useExitingModel() {
    this.EnterNewModel = false
    this.UseExistingModelButton = false;
    this.EnterNewModelButton = true;
    this.checkIfValid();
  }
  /* CHANGE MAKE */
  getSelectedOptionMakes(opt) {
    if (opt !== 'Select Make') {
      this.disabledModel = true;
      this.makesValue = opt;
      this.carForm.controls.sel_make.setValue(opt);
      this.carForm.controls.make.setValue(opt);
      this.carForm.controls.sel_year.setValue('');
      this.carForm.controls.year.setValue('');
      this.carForm.controls.sel_model.setValue('');
      this.carForm.controls.model.setValue('');
      this.selectedYear = 'Select Year';
      this.selectedModel = 'Select Model';
      this.getYears(opt);
      this.getModelList('');
    } else {
      this.disabledModel = true;
      this.disabledYear = true;
      this.makesValue = opt;
      this.carForm.controls.sel_make.setValue('');
      this.carForm.controls.make.setValue('');
      this.carForm.controls.sel_year.setValue('');
      this.carForm.controls.year.setValue('');
      this.carForm.controls.sel_model.setValue('');
      this.carForm.controls.model.setValue('');
      this.selectedYear = 'Select Year';
      this.selectedModel = 'Select Model';
    }

    this.checkIfValid();
  }

  /* CHANGE YEARS */
  getSelectedOptionYears(opt) {
    if (opt !== 'Select Year') {
      this.disabledModel = false;
      this.carForm.controls.sel_model.setValue('');
      this.carForm.controls.model.setValue('');
      this.carForm.controls.sel_year.setValue(opt);
      this.carForm.controls.year.setValue(opt);
      this.selectedModel = 'Select Model';
      this.getModelList(opt);
      let makeDate = '01' + '/' + opt;
      this.carForm.controls.production_date.setValue(makeDate);
      this.checkVinValidity();
    } else {
      this.disabledModel = true;
      this.carForm.controls.sel_model.setValue('');
      this.carForm.controls.model.setValue('');
      this.carForm.controls.sel_year.setValue('');
      this.carForm.controls.year.setValue('');
      this.selectedModel = 'Select Model';
      this.carForm.controls.production_date.setValue('');
    }
    this.checkIfValid();
  }

  /* CHANGE MODELS */
  getSelectedOptionModels(opt) {
    if (opt !== 'Select Model') {
      this.modelsValue = opt;
      this.carForm.controls.sel_model.setValue(opt);
      this.carForm.controls.model.setValue(opt);
    } else {
      this.modelsValue = '';
      this.carForm.controls.sel_model.setValue('');
      this.carForm.controls.model.setValue('');
    }
    // this.globalService.validateState(this.car, ['sel_model']);
    this.checkIfValid();
  }

  /* STATES LIST IN SEARCH BOX DROP DOWN */
  getSelectedColor(opt) {
    if (opt != "") {
      this.carForm.controls.select_color.setValue(opt);
      this.carForm.controls.color.setValue(opt);
    }else{
      this.carForm.controls.select_color.setValue('');
      this.carForm.controls.color.setValue('');
    }
    this.checkIfValid();
  }

  /* STATES LIST IN SEARCH BOX DROP DOWN */
  getSelectedOptionState(stateId) {
    if (stateId !== 'Select State') {
      let stateData = this.states.find(stateInfo => stateId == stateInfo.id);
      this.carForm.controls.State.setValue(stateData.state_name);
      this.checkIfValid();
      this.checkLicence();
    } else {
      this.carForm.controls.State.setValue('');
      this.checkIfValid();
    }
  }
  goTo(obj, picker) {
    if (typeof obj == 'undefined') {
      obj = picker.inputElementValue;
    }
    if (obj != '') {
      picker.api.moveCalendarTo(obj);
    }
  }
  setTouchViewValue() {
    return this.touchViewState;
  }
  getLockLocation(opt) {
    this.checkIfValid();
  }
  californiaEmmissions(t) {
    if (t.checked) {
      this.carForm.controls.california_emmissions.setValue(1);
    } else {
      this.carForm.controls.california_emmissions.setValue(0);
    }
    this.checkIfValid();
  }
  checkExistingLoclLocation() {
    for (var i = 0; i < this.wheelLock.length; i++) {
      if (this.wheelLock[i].id == this.vehicleDetails.wheel_lock_location) {
        this.antWheellocklocation = true;
        return;
      } else {
        this.antWheellocklocation = false;
      }
    }
  }
  isCustFieldAllowed() {
    return this.gs.getAcl('custom_vehicle_fields', 'addNew')
  }
  getCustomField(f) {
    let data = { user_type: this.user.user_type, user_id: this.user.id }
    this.gs.callAPI(globalConstants.API_GET_CUSTOM_VEHICLE_FIELD, data).subscribe((r:any) => {
      if (r.result == 1) {
        this.customFieldList = r.data
        this.assigneValueToCustmFields(f)
      }
    }, (error) => { })
  }
  assigneValueToCustmFields(data) {
    if (data && typeof data != 'undefined' && data.length) {
      this.customFieldList.forEach((e) => {
        for (let i in data) {
          if (e.id == data[i].vehicle_profile_field_id) {
            e.value = data[i].value
            break;
          }
        }

      })
    }
  }
  chooseCustmField(v, i) {
    this.customFieldList[i].value = v
  }
  fieldList() {
    let dialogRef = this.dialog.open(ExtrafieldListComponent, {
      panelClass: ['car-dialog-form', 'extrafields-dialog-form'],
      width: "800px",
      data: {}
    });
    dialogRef.afterClosed().subscribe((r: any) => {
      let custFields = []
      if (this.vehicleDetails && typeof this.vehicleDetails.custom_fields != 'undefined' && this.vehicleDetails.custom_fields.length) {
        custFields = this.vehicleDetails.custom_fields
      }
      this.getCustomField(custFields)
    })
  }
  changeListner(event, rendering_tag_id) {
    var html = "";
    var base64image = "";
    if (event.target.files && event.target.files[0]) {
        let file = event.target.files[0];
        let uploading_key = rendering_tag_id.split("-")[0];
        let ele=<HTMLInputElement>document.getElementById(uploading_key);
        //if (file.type == "image/png" || file.type == "image/jpeg" || file.type == 'video/mp4' || file.type == "video/quicktime") {
            if (uploading_key == "vehicle_video" ? file.size < this.MAX_ALLOWED_VIDEO_SIZE : file.size < this.MAX_ALLOWED_IMAGE_SIZE) {
              var filesAmount = event.target.files.length;
              for (let i = 0; i < filesAmount; i++) {
                var img: any = document.createElement("img");
                img.className = "show-image";
                var reader = new FileReader();
                let base64valuec;
                reader.onload = (e: any) => {
                  img.src = e.target.result;
                  img.className = "show-image";
                  base64valuec = e.target.result;
                  
                    /*if (uploading_key == "vehicle_video") {
                        if (file.type == 'video/mp4' || file.type == "video/quicktime") {
                            return true;
                        } else { 
                            this.gs.snackbar("error", "Please upload only mp4 video.");
                            //let ele=<HTMLInputElement>document.getElementById(rendering_tag_id);
                            //ele.value='';
                            return false;
                        }
                    } else {
                        if(file.type == "image/png" || file.type == "image/jpeg" || file.type == 'image/jpg') {
                            return true;
                        } else { 
                            this.gs.snackbar("error", "Please upload only png jpeg & jpg format of image.");
                            //let ele=<HTMLInputElement>document.getElementById(rendering_tag_id);
                            //ele.value='';
                            return false;
                        }
                    }*/
                    
                    /*
                    if (file.type == 'video/mp4' || file.type == "video/quicktime") {
                        if (uploading_key != "vehicle_video") {
                            this.gs.snackbar("error", "Please upload only mp4 video.");
                            return false;
                        }
                    } */

                  let pic = { id: 0, loader: false, vehiclePic: '', vehicleInsured_pic: '', vehicleRegistered_pic: '', other_labels_temp: '', label_pic: '' };
                  pic.loader = true
                  if (uploading_key == "vehicle_pic") {
                    if (file.type == "image/png" || file.type == "image/jpeg" || file.type == 'image/jpg') {
                      this.uploadImage(globalConstants.API_UPLOAD_VEHICLE_PICTURE_URL, uploading_key, base64valuec);
                    } else {
                      this.gs.snackbar("error", "Please upload only png, jpg & jpeg format of image.");
                      pic.vehiclePic = "";
                      ele.value = '';
                    }
                  }
                  else if (uploading_key == "vehicle_registered_pic") {
                    if (file.type == "image/png" || file.type == "image/jpeg" || file.type == 'image/jpg') {
                      pic.vehicleRegistered_pic = base64valuec;
                      this.vehicleRegisteredPic.push(pic);
                      this.uploadImage(globalConstants.API_UPLOAD_VEHICLE_REG_PICTURE_URL, uploading_key, base64valuec);
                    } else {
                      this.gs.snackbar("error", "Please upload only png, jpg & jpeg format of image.");
                      pic.vehicleRegistered_pic = "";
                      ele.value = '';
                    }
                  }
                  else if (uploading_key == "vehicle_insured_pic") {
                    if (file.type == "image/png" || file.type == "image/jpeg" || file.type == 'image/jpg') {
                      pic.vehicleInsured_pic = base64valuec;
                      this.uploadImage(globalConstants.API_UPLOAD_VEHICLE_INS_PICTURE_URL, uploading_key, base64valuec);
                      this.vehicleInsuredPic.push(pic);
                    } else {
                      this.gs.snackbar("error", "Please upload only png, jpg & jpeg format of image.");
                      pic.vehicleInsured_pic = "";
                      ele.value = '';
                    }
                  }
                  else if (uploading_key == "vehicle_video") {
                    if (file.type == 'video/mp4' || file.type == "video/quicktime") {
                      this.videoList.push(pic);
                      this.uploadImage(globalConstants.API_UPLOAD_VEHICLE_VIDEO_URL, uploading_key, file);
                    } else {
                      this.gs.snackbar("error", "Please upload only mp4 & quicktime format of video.");
                      ele.value = '';
                      return false;
                    }
                  }
                  else if (uploading_key == "vin_photo") {
                    if (file.type == "image/png" || file.type == "image/jpeg" || file.type == 'image/jpg') {
                      this.vinPhotoSetLoader = true;
                      if (this.vinPhoto == '') {
                        this.vinPhoto = 'new Image';
                      }
                      this.vinPhotoSet = base64valuec;
                      this.uploadImage(globalConstants.API_UPLOAD_VEHICLE_VIN_PICTURE_URL, uploading_key, base64valuec);
                    } else {
                      this.gs.snackbar("error", "Please upload only png, jpg & jpeg format of image.");
                      this.vinPhotoSet = "";
                      ele.value = '';
                    }
                  } else if (uploading_key == "other_labels_temp") {
                    if (file.type == "image/png" || file.type == "image/jpeg" || file.type == 'image/jpg') {
                      this.uploadImage(globalConstants.API_ADD_VEHICLE_LABEl_PICTURE, uploading_key, base64valuec);
                    } else {
                      ele.value = '';
                      pic.other_labels_temp = "";
                      this.gs.snackbar("error", "Please upload only png, jpg & jpeg format of image.");
                    }
                  }
                  event.target.value = "";
                }

                reader.readAsDataURL(event.target.files[i]);
              }
            } else {
                ele.value='';
                if(uploading_key == "vehicle_video"){
                    this.gs.snackbar("error", "Video should be less then "+this.MAX_ALLOWED_VIDEO_DISPLAYED_TEXT+".");
                }else{
                    this.gs.snackbar("error", "Image should be less then "+this.MAX_ALLOWED_IMAGE_DISPLAYED_TEXT+".");
                }
            }
        /*} else {
            this.gs.snackbar("error", "Please upload only png & jpeg format of image");
        }*/
    }
}
uploadImage(url, key, base64value) {
    // console.log("console");
    this.gs.imageLoader();
    if (key == "vin_photo") {
        key = "vehicle_vin_pic"
    }else if(key=='other_labels_temp'){
        key='label_pic'
    }

    var data = new FormData();
    data.append('customer_id', this.carCustomerId);
    data.append('vehicle_id', this.vehicleId);
    data.append(key, base64value);
    this.gs.formData(url, data).subscribe((response) => {
        let resData = response;
        if (key == "vehicle_pic") {
            this.vPic.push({
               loader: false,
               id:resData.data.id,
               vehiclePic:'',
               vehicle_pic: resData.data.vehicle_pic
            });
        } else if (key == "vehicle_registered_pic") {
            this.vehicleRegisteredPic[this.vehicleRegisteredPic.length - 1].id = resData.data.id;
            this.vehicleRegisteredPic[this.vehicleRegisteredPic.length - 1].loader = false;
            this.vehicleRegisteredPic[this.vehicleRegisteredPic.length - 1].vehicleRegistered_pic='';
            this.vehicleRegisteredPic[this.vehicleRegisteredPic.length - 1].vehicle_registered_pic=resData.data.vehicle_registered_pic;
        } else if (key == "vehicle_insured_pic") {
            this.vehicleInsuredPic[this.vehicleInsuredPic.length - 1].id = resData.data.id;
            this.vehicleInsuredPic[this.vehicleInsuredPic.length - 1].loader = false;
            this.vehicleInsuredPic[this.vehicleInsuredPic.length - 1].vehicle_insured_pic=resData.data.vehicle_insured_pic;
            this.vehicleInsuredPic[this.vehicleInsuredPic.length - 1].vehicleInsured_pic='';
        } else if (key == 'vehicle_vin_pic') {
            this.vinPhotoSetLoader = false;
            this.vinPhotoSet='';
            this.vinPhoto=resData.data.vehicle_vin_pic;
        } else if (key == "vehicle_video") {
            this.videoList[this.videoList.length - 1].id = resData.data.id;
            this.videoList[this.videoList.length - 1].vehicle_video = resData.data.vehicle_video;
            this.videoList[this.videoList.length - 1].loader = false;
        } else if (key == "label_pic") {
            this.otherLabelsTemp.push({
               id: resData.data.id,
               label_pic: resData.data.label_pic,
               other_labels_temp:'',
               loader : false
            })
        }
    })
}
/** delete image and video */
imgDelete(picId, types, index) {   
    this.gs.imageLoader();
   
    let data = 'vehicle_id=' + this.vehicleId;

    let url = '';

    
    if (types == 'vehicle_pic') {
      this.vPic[index].loader = true;
        this.gs.confirmDialog(picId ,'Are you sure you want to delete this image?').subscribe((res) => {
            if (res != null) {
                url = globalConstants.API_DELETE_VEHICLE_PICTURE_URL;
                data = data + '&vehicle_picture_id=' + picId;
                if (url != '') {
                    this.gs.callAPI(url, data).subscribe((response:any) => {
                      this.vPic[index].loader = false;
                        let result = response;
                        if (types == 'vehicle_pic' && result.result == 1) {
                            this.deleteImage(this.vPic, picId);
                            this.gs.snackbar("success", result.message);
                        }
                    })
                }
            }
        });
    } else if (types == "vehicle_registered_pic") {
        this.gs.confirmDialog(picId ,'Are you sure you want to delete this image?').subscribe((res) => {
           this.vehicleRegisteredPic[index].loader = true;
            if (res != null) {
                url = globalConstants.API_DELETE_VEHICLE_REG_PICTURE_URL;
                data = data + '&vehicle_registered_picture_id=' + picId;
                if (url != '') {
                    this.gs.callAPI(url, data).subscribe((response:any) => {
                       this.vehicleRegisteredPic[index].loader = false;
                        let result = response;
                        if (types == 'vehicle_registered_pic' && result.result == 1) {
                            this.deleteImage(this.vehicleRegisteredPic, picId);
                            this.gs.snackbar("success", result.message);
                        }
                    })
                }
            }
        });
    } else if (types == "vehicle_insured_pic") {
        this.gs.confirmDialog(picId ,'Are you sure you want to delete this image?').subscribe((res) => {
          this.vehicleInsuredPic[index].loader = true;
            if (res != null) {
                url = globalConstants.API_DELETE_VEHICLE_INS_PICTURE_URL;
                data = data + '&vehicle_insured_picture_id=' + picId;
                if (url != '') {
                    this.gs.callAPI(url, data).subscribe((response:any) => {
                        let result = response;
                      this.vehicleInsuredPic[index].loader = false;
                        if (types == 'vehicle_insured_pic' && result.result == 1) {
                            this.deleteImage(this.vehicleInsuredPic, picId);
                            this.gs.snackbar("success", result.message);
                        }
                    })
                }
            }
        });
     } else if (types == "vinPhoto") {
        this.gs.confirmDialog(picId ,'Are you sure you want to delete this image?').subscribe((res) => {
          this.vinPhotoSetLoader = true;
            if (res != null) {
                url = globalConstants.API_DELETE_VEHICLE_VIN_PICTURE_URL;
                data = data + '&customer_id=' + this.carCustomerId;
                if (url != '') {
                    this.gs.callAPI(url, data).subscribe((response:any) => {
                      this.vinPhotoSetLoader = false;
                        let result = response;
                        if (types == 'vinPhoto' && result.result == 1) {
                            this.vinPhoto = ''; this.vinPhotoSet = '';
                            this.gs.snackbar("success", result.message);
                        }
                    })
                }
            }
        });
    } else if (types == "vehicle_video") {
        this.gs.confirmDialog(picId ,'Are you sure you want to delete this video?').subscribe((res) => {
          this.videoList[index].loader = true;
            if (res != null) {
                url = globalConstants.API_DELETE_VEHICLE_VIDEO_URL;
                data = data + '&vehicle_video_id=' + picId;
                if (url != '') {
                    this.gs.callAPI(url, data).subscribe((response:any) => {
                      let result = response;
                      this.videoList[index].loader = false;
                        if (types == 'vehicle_video' && result.result == 1) {
                            this.deleteImage(this.videoList, picId);
                            this.gs.snackbar("success", result.message);
                        }
                    })
                }
            }
        });
    }else if(types == "other_labels_temp"){
        this.gs.confirmDialog(picId, 'Are you sure you want to delete this image?').subscribe((res) => {
            this.otherLabelsTemp[index].loader = true;
            if (res != null) {
                url = globalConstants.API_DELETE_VEHICLE_LABEl_PICTURE;
                data = data + '&label_picture_id=' + picId;
                if (url != '') {
                    this.gs.callAPI(url, data ).subscribe((response:any) => {
                        this.otherLabelsTemp[index].loader = false;
                        let result = response;
                        if (types == 'other_labels_temp' && result.result == 1) {
                            this.deleteImage(this.otherLabelsTemp, picId);
                            this.gs.snackbar("success", result.message);
                        }
                    })
                }
            }
        });
    }
}

/***delete data from array */
deleteImage(vPic, picId) {
    if (vPic.length) {
        for (let i in vPic) {
            if (vPic[i].id == picId) {
                vPic.splice(parseInt(i), 1);
            }
        }
    }
}

/*******video dialog******/
  videoShow(val): void {
    let dialogRef = this.dialog.open(VideoShowComponent, {
      panelClass: 'car-dialog-form',
      width: "800px",
      data: { name: val, url: globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_VIDEO }
    });
  }
  /* image rotation dialog  */
  imageToolDialog(current, i, type, imgaar): void {
    let dialogRef = this.dialog.open(ImagetooldialogComponent, {
      panelClass: ['imageToolDialog'],
      width: "100%",
      data: { event: current, index: i, imgType: type, imgArray: imgaar, customerId: this.user.id, carId: this.vehicleId }
    });
  }
  
  /**
   * Update By - Shashank Thakur
   * modified date - 16-03-2020
   * Removed the (focus) callback to openpicker to fix the on popup open issue.
   * Using tabIndex = -1 we can keep (focus) callback in code by fixing the issue.
   */
  onPickerOpen(picker: any) {
    picker.open();
  }

  /**
   * These are the functions missing in .ts but used in .html file and are copied from
   * shopeditcar.component.ts
   */
  enableAddNewColor() {
    this.addNewColor = true;
  }

  disableAddNewColor() {
    this.addNewColor = false;
  }

  /* Home Phone Validity Check */
  checkHomePhoneValidity() {
    Window["errorObj"] = this.vehicleDetails;
    let home_phone = this.vehicleDetails.driver_phone_number;
    if (home_phone != "") {
      let phoneResult = this.vehicleDetails.driver_phone_number.replace(/\D+/g, "").length >= 10 ? false : { minLength: true };

      if (phoneResult == false) {
        this.carForm.controls.driver_phone_number.setErrors(null);
        this.submitted = false;
      } else {
        this.carForm.controls.driver_phone_number.setErrors({ "phoneError": true });
      }
    } else {
      this.carForm.controls.driver_phone_number.setErrors(null);
      this.submitted = false;
    }
  }

  onlyNumericKey(event) {
    return globalFunctions.onlyDecimalNumberKey(event);
  }

  checkVinValidity() {
    Window["errorObj"] = this.vehicleDetails
    var year_selected = this.vehicleDetails.year;
    var vin_number = this.vehicleDetails.vin;
    if (vin_number != "") {
      if (year_selected > 1981 && !this.EnterNewYear) {
        if (vin_number.length < 17) {
          this.carForm.controls.vin.setErrors({ "YearError": true });
        } else {
          this.carForm.controls.vin.setErrors(null);
        }
      } else {

      }
    }
    /*if (year_selected > 1980 && vin_number.length > 16 && !this.EnterNewYear) {
        this.car.controls.vin.setErrors({"YearError": true});
    } else {
        this.car.controls.vin.setErrors(null);
    }*/
  }

  autoFillProductionDate() {
    if (this.vehicleDetails.year != "") {
      let makeDate = "01" + '/' + this.vehicleDetails.year;
      this.carForm.controls.production_date.setValue(makeDate)
    }
  }

  getShopCustomerDetails() {
    var opr = "user_type=" + this.user.user_type + "&user_id="+this.user.id+"&customer_id=" + this.carCustomerId;
    this.gs.callAPI(globalConstants.API_GET_CUSTOMER_DETAILS, opr).subscribe((data:any) => {
        this.shopCustomerDetails = data.data;
    });
  }  

  checkVIN(){
    let vin    = this.carForm.controls.vin.value;  
    if(vin && this.vehicleDetails && this.vehicleDetails.vin==vin && vin.length >=17){
        let state  = this.carForm.controls.State.value;
        let opr    = "user_type=" + this.user.user_type + "&user_id="+this.user.id+"&vin="+vin+"&state="+state+"&customer_id=" + this.carCustomerId+"&partstech_check=0&vehicle_id="+this.vehicleId;
        this.gs.callAPI(globalConstants.API_CHECK_VEHICLE_INFO, opr).subscribe((data:any) => {
            if(data.result == "1"){
                this.vehicleByVin = data.data;
                if(this.vehicleByVin && 'vehicle_info' in this.vehicleByVin){
                this.searchCarDialog(this.vehicleByVin,'vin');  
                }          
            }else{
                if(data.code == 528){
                    this.gs.confirmDialog("confirm", data.message).subscribe((res) => {
                        if (res != null) {
                            this.carForm.patchValue({vin: ''});
                        }
                    });
                }else{
                    this.gs.snackbar("error", data.message);
                }
            }
        });
    }
    
  }

  checkLicence(){
      let license_plate = this.carForm.controls.license_plate.value;
      if(license_plate){
          let stateName     = this.carForm.controls.State.value;
          let opr           = "user_type=" + this.user.user_type + "&user_id="+this.user.id+"&license_plate="+license_plate +"&state_name="+stateName+"&customer_id=" + this.carCustomerId;  
          let carState = this.states.filter(state => state.state_name ==  stateName);
          if(carState.length > 0) {
              opr = opr+"&state="+carState[0].state_code+"&partstech_check=0&vehicle_id="+this.vehicleId;
          }else{
              opr = opr+"&state=&partstech_check=0&vehicle_id="+this.vehicleId;
          }  
          this.gs.callAPI(globalConstants.API_CHECK_VEHICLE_INFO, opr).subscribe((data:any) => {
              if(data.result == "1"){
                  this.vehicleByLicence = data.data;
                  if('code' in data && data.code =='638' && 'vehicle_info' in data){
                    this.searchCarDialog({vehicle_info:data.vehicle_info},'vin');  
                  }else if(this.vehicleByLicence && 'vehicle_info' in this.vehicleByLicence){
                      this.searchCarDialog(this.vehicleByLicence,'licence');     
                  }                       
              }else{
                  if(data.code == 528){
                      this.gs.confirmDialog("confirm", data.message).subscribe((res) => {
                          if (res != null) {
                              this.carForm.patchValue({license_plate: ''});
                          }
                      });
                  }else{
                      this.gs.snackbar("error", data.message);
                  }
              }
          });
      }       
  }

  searchCarDialog(customerVehcileInfo, searchBy){
      let status = 1; 
      if(this.carCustomerId == customerVehcileInfo.vehicle_info.customer_id){
          status = 1;
      }else if(this.carCustomerId != customerVehcileInfo.vehicle_info.customer_id){
          status = 2;
      }
      if(this.shopCustomerDetails && this.shopCustomerDetails.is_sublet == '1'){
          status = 3;
      }
      const dialogRef = this.dialog.open(SearchcarComponent, {
          panelClass: ['car-dialog-form'],
          data: {
              status:status,
              customerVehcileInfo: customerVehcileInfo,
              shopCustomerDetails: this.shopCustomerDetails,
              currentCustomer    : this.user,
              searchBy: searchBy,
          }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.dialogRef.close(true);
        }         
      });   
  }

  validateNumbersNoDecimal(event) {
    return globalFunctions.validateNumbersNoDecimal(event);
  }

  validateNumbersWithDecimal(event) {
    return globalFunctions.validateNumbersWithDecimal(event,2);
  }
/**
   * validates the form control's value on paste event.
   * ensure that validation messages are displayed if the pasted value is invalid.
   */
validateFieldOnPaste(controlName: string) {
  const control = this.carForm.get(controlName);

  control.markAsTouched();
}


public setSelectedOptionState() {
  let shopState = this.shopDetail.state;
  let StateName = '';
  if(shopState && this.vehicleDetails.state == ''){
       StateName = shopState;
  }
  if(StateName) {
    const carState = this.states.filter(
      (state) => state.state_name == StateName
    );
  if(carState.length >0){
       this.carState = carState[0].state_name;
       this.carForm.patchValue({ State: carState[0].state_name });
   } else {
       this.carState = '';
       this.carForm.controls.State.setValue('');
   }
  }
 
}

convertIntoDecimal(event,frmControlName,beforeDecimalDigit,digitAfterDecimal){
  let fieldValue = this.carForm.get(frmControlName).value
  let result= this.gs.processDecimalValue(fieldValue,beforeDecimalDigit);
  this.carForm.get(frmControlName).setValue(result);
  return globalFunctions.validateNumbersWithDecimal(event,digitAfterDecimal);
}

}
