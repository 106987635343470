import { Component, OnInit, ViewChild,OnDestroy} from '@angular/core';
import {  FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute  } from '@angular/router';
import { Http,Headers, Response} from '@angular/http';
import { globalConstants } from "../../../environments/globalConstants";
import {GlobalService} from "../../../environments/global.service";
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TextMaskModule } from 'angular2-text-mask';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'shop-editdisclaimer',
    templateUrl: 'shopeditdisclaimer.component.html',
    styleUrls: ['shopeditdisclaimer.component.scss']
})
export class ShopeditdisclaimerComponent implements OnInit,OnDestroy {
  public currentCustomer;
  public currentCustomerName;
  public shopEditDisclaimer;
  public disclaimerId;
  public currentDisclaimerDetails;
  public disclaimerCheck:boolean;
  public subscriptionDataForAPI: Subscription = new Subscription();

  constructor(private activatedRoute:ActivatedRoute,private formBuilder: FormBuilder,private route: ActivatedRoute,public titleService:Title,private http: Http,private router: Router,public snackBar: MatSnackBar, public globalService: GlobalService) {
        if(localStorage.getItem("loggedInUser")) {
            this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
            this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
            this.disclaimerId = localStorage.getItem("shopDisclaimerID");
            this.shopEditDisclaimer = this.formBuilder.group({
                title: ['', Validators.required],
                description: ['',Validators.required],
                is_default: [''],
                user_type: this.currentCustomer.user_type,
                user_id: this.currentCustomer.id,
                disclaimer_id: ['']
            });
        } else {
          this.router.navigate(["/shops"]);
        }
        setTimeout(()=> {
        let isEnable=this.globalService.getAcl('disclaimer','edits');
        if(!(isEnable)){
            this.router.navigate(['shops/action/forbidden']);
        }
    },globalConstants.RELOAD_WAIT)
  }
  ngOnInit(){
    this.globalService.setMetaData("SHOPS","EDIT_DISCLAIMER");
   }

   ngAfterViewInit(){
       this.getServiceFacilities();
   }
   
   getServiceFacilities() {

       const shopContent = "user_id="+this.currentCustomer.id+"&user_type="+this.currentCustomer.user_type+"&disclaimer_id="+ this.disclaimerId;
       this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_DISCLAIMERS_LIST_URL,shopContent).subscribe((data:any) => {
           this.currentDisclaimerDetails = data.data;
        //    console.log("Dis", this.currentDisclaimerDetails);
           if (this.currentDisclaimerDetails.is_default == 1) {
                   this.disclaimerCheck = true;
           }else{
               this.disclaimerCheck = false;
           }
           this.shopEditDisclaimer.controls.is_default.setValue(this.currentDisclaimerDetails.is_default);
           this.shopEditDisclaimer.controls.disclaimer_id.setValue(this.currentDisclaimerDetails.id);
        });
    }

   
   showOptions(event){
       if(event.checked) {
           this.shopEditDisclaimer.controls.is_default.setValue(1);
       } else {
           this.shopEditDisclaimer.controls.is_default.setValue(0);
       }
   }
    editdisclaimer() {
        if(this.shopEditDisclaimer.valid) {
            const stringifiedFormData =this.globalService.formsData(this.shopEditDisclaimer.value);
            this.globalService.formData(globalConstants.API_EDIT_DISCLAIMER_URL,stringifiedFormData).subscribe((data:any)=> {
                  const message = data.message;
                  if (data.result == "1") {
                      this.router.navigate(['shops//list-disclaimers']);
                      this.globalService.snackbar('success', message);
                  } else { 
                      this.globalService.snackbar('error', message);
                  }
              });
        } else {
            this.mandatoryMsg();
        }
    }
    
    mandatoryMsg() {
        this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }

    ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
    }  
}
