import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
import { QqpaymentComponent } from '../qqpayment/qqpayment.component';

@Component({
  selector: 'app-qqview',
  templateUrl: './qqview.component.html',
  styleUrls: ['./qqview.component.scss']
})
export class QqviewComponent implements OnInit {

  public user:any=null
  public customer:any=null
  public param:any={}
  public recomendationData:any={}
  public allJobs:any=[]
  public selectedParts: any = {}
  public selectedGenericJob: any = {}
  public times:any={}
  public labors:any={}
  public notes:any={}
  public showCostPrice=false;
  public vehicleName :any ="";
  public editable=true
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public gs:GlobalService, public dialogRef: MatDialogRef<QqviewComponent>, public dialog: MatDialog) { 
    this.gs.diaLogObj(this.dialogRef);
    this.user=this.gs.getCurrentUser()
    this.param=data
  }

  ngOnInit() {
    // let shopPer=this.gs.isSubscribe('quick_quote')
    // let userPer=this.gs.getAcl('quick_quote','views');
    this.editable = this.gs.getAcl('quick_quote', 'edits');
    // if(!(shopPer && userPer)){
    //   this.dialogRef.close()
    // }
    this.showCostPrice=this.gs.getAcl('display_part_cost','views'); 
    this.getQQDetail(this.param)
    // console.log(this.param)
  }
  /** get the all job list to  */
  getQQDetail(param){
    let data={quote_id:param.id,user_id: this.user.id}
    this.gs.callAPI(globalConstants.API_QQ_DETAIL, data).subscribe((r:any) => {
      if(r.result==1){
        this.recomendationData=r.data
        if (this.recomendationData.year =="") {
        this.vehicleName= "";
      } else {
        this.vehicleName=this.recomendationData.year ;
      }
      if(this.recomendationData.make != ""){
        this.vehicleName+= (this.vehicleName ? "/" : "" ) + this.recomendationData.make;
      }
      if(this.recomendationData.model != ""){
        this.vehicleName+= (this.vehicleName ? "/" : "" ) + this.recomendationData.model
    }
        this.allJobs=r.data.service_list
        this.petchAllRecommendation()
      }else{
        this.gs.snackbar("error", r.message);
      }
    }, (error) => { })
  }
  petchAllRecommendation(){
    let data=this.allJobs
    data.forEach((r:any)=>{
       if(r.children && r.children.length){
        for(let i in r.children){
           if(typeof r.children[i].recommendation!='undefined' && r.children[i].recommendation && r.children[i].recommendation.id){
            this.seperateJob(r.children[i].recommendation)
           }else if(typeof r.children[i].children!='undefined' && r.children[i].children.length){
            this.findChild(r.children[i].children)
           }
        }
       }else if(typeof r.recommendation!='undefined' && r.recommendation && r.recommendation.id){
        this.seperateJob(r.recommendation)
       }
    })
  }
  findChild(job){
    job.forEach((r:any)=>{
       if(r.children && r.children.length){
        for(let i in r.children){
           if(typeof r.children[i].recommendation!='undefined' && r.children[i].recommendation && r.children[i].recommendation.id){
            this.seperateJob(r.children[i].recommendation)
           }else if(typeof r.children[i].children!='undefined' && r.children[i].children.length){
            this.findChild(r.children[i].children)
           }
        }
       }else if(typeof r.recommendation!='undefined' && r.recommendation && r.recommendation.id){
        this.seperateJob(r.recommendation)
       }
    })
  }

  seperateJob(job){
    // console.log(job)
    let priceShow=this.showCostPrice
    if(job.parts.length){
      job.parts.forEach((e)=>{
          e.label=e.part_number+(priceShow? ' ($'+e.cost+') ':' ')+e.name
          e.price=e.part_price
      })
    }

    if(job.generic_jobs.length){
      job.generic_jobs.forEach((e)=>{
          e.label=e.name
      })
    }
    if (typeof this.selectedParts[job.service_list_id] == 'undefined') {
      this.selectedParts[job.service_list_id] = []
      this.selectedParts[job.service_list_id] = job.parts
    }
    if (typeof this.selectedGenericJob[job.service_list_id] == 'undefined') {
      this.selectedGenericJob[job.service_list_id] = []
      this.selectedGenericJob[job.service_list_id] = job.generic_jobs
    }
    if (typeof this.labors[job.service_list_id] == 'undefined') {
      this.labors[job.service_list_id] = ''
      this.labors[job.service_list_id] = job.estimated_labor
    }
    if (typeof this.times[job.service_list_id] == 'undefined') {
      this.times[job.service_list_id] = ''
      this.times[job.service_list_id] = job.estimated_time
    }
    if (typeof this.notes[job.service_list_id] == 'undefined') {
      this.notes[job.service_list_id] = ''
      this.notes[job.service_list_id] = job.notes
    }
  }
  editJobs(){
    this.dialogRef.close(2)
  }
  formatDate(addDate){
    return this.gs.getFullFormateDate(addDate,'G','B')
  }

  getNltoBr(val){
    if(val && val.length>0){
      return val.toString().replace(/\r\n/g, "<br />").replace(/\n/g, "<br />");
    }
    return '-';
  }
  payments(){
    let dialogRef: any = this.dialog.open(QqpaymentComponent, {
      panelClass: ['payment-dialog'],
      width: "100%",
      data: this.param
    });
    dialogRef.afterClosed().subscribe(r => {
      if (r == 1) {
        this.getQQDetail(this.param)
      }
    })
  }
  isAllowed(module,action) {
    return this.gs.getAcl(module,action);
  }
  generateQuote(){
    var conf = "Are you sure you want to print this quote ?";
    this.gs.confirmDialog('GenerateQuote', conf).subscribe((r) => {
        let res:any=r;
    if(res=='GenerateQuote'){
        var url=globalConstants.API_QQ_PRINT_ESTIMATE_URL;
        let userId=this.gs.getCurrentUser()["id"];
        let params = new URLSearchParams();
        params.set('quote_id', this.recomendationData.id);
        params.set('user_type', "User");
        params.set('user_id', userId);       
        url += '?'+params.toString();
        let FileName = 'Quote-'+this.recomendationData.id+'.pdf';
        this.gs.downloadAsset(url,FileName);
      } else {
        return false;
      }
    });
  }
}
