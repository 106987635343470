<div mat-dialog-title class="relative">
  <span>
    {{ currentUserInfo.profile_type == '2' ? 'Contact' : 'Customer' }} Chat List
  <span  class="add-message-btn text-red margin-left-10" title="Add {{ currentUserInfo.profile_type == '2' ? 'Contact' : 'Customer' }} Chat" (click)="addMessage()">
    <i class="fa fa-plus-square" >

    </i>
  </span>
  </span>

  <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
    <mat-icon class="notranslate close-icon-lg">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="customer-chatList-content">
  <div class="info-text">This is a message between you and the customer on the CAR App <strong>(Digital Glovebox)</strong>, 
    they also get your message by email as well. If you would like two-way texting upgrade, 
    contact CAR Support -<a href="tel:1 (833) 227-7778" class="font-bold" >1 (833) 227-7778</a> or in Live Support.</div>
    <div class="car-datatable uc-datatable mat-elevation-z1">
        <div class="text-right hidden">
          <mat-form-field floatPlaceholder="never" >
            <input matInput #filter placeholder="Filter users">
          </mat-form-field>
        </div>
        <div class="table-responsive relative">
          <mat-table #table [dataSource]="dataSource" matSort class="uc-table-responsive service-list datatable-loader-bg">
  
            <!-- Color Column -->
            <ng-container matColumnDef="id">
              <mat-header-cell *matHeaderCellDef mat-sort-header data-role="id"> ID </mat-header-cell>
              <mat-cell *matCellDef="let row" data-role="id"  data-val="ID"> {{row.id}} </mat-cell>
            </ng-container>
    
            
            <ng-container matColumnDef="title">
              <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Title </mat-header-cell>
              <mat-cell *matCellDef="let row" data-role="auto" data-val="Title"> {{row.title}} </mat-cell>
            </ng-container>
  
            
  
            <ng-container matColumnDef="added">
              <mat-header-cell *matHeaderCellDef mat-sort-header data-role="date"> Date </mat-header-cell>
              <mat-cell *matCellDef="let row" data-role="date" data-val="Date">{{row.added_at}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Status </mat-header-cell>
              <mat-cell *matCellDef="let row" data-role="auto" data-val="Status"> {{row.status}}</mat-cell>
            </ng-container>
  
  
            <!-- Color Column -->
            <ng-container matColumnDef="Actions">
              <mat-header-cell *matHeaderCellDef data-role="button"> Actions </mat-header-cell>
              <mat-cell *matCellDef="let row" data-role="button">
                <button mat-button class="list-action-btn" color="accent" [matMenuTriggerFor]="menu" disableRipple >
                  Action<mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #menu="matMenu" class="car-icon-menu disable-no-bg" xPosition="before" [overlapTrigger]="false">
                  <button class="mat-menu-item" (click)="viewComplaintDetails(row)"> <mat-icon>remove_red_eye</mat-icon>View</button>
                </mat-menu>
              </mat-cell>
            </ng-container>
  
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="mat-row {{getRowStatusColor(row.check_status)}}"></mat-row>
          </mat-table>
          <div class="padding-15 text-center"
          [style.display]="getTotalRecords() < 1 ? '' : 'none'">
          No Records Available
        </div>
      </div>
      <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
        <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="options.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event)" >
        </mat-paginator>
  
              <div class="mat-paginator table-paging">
                  <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
                  <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                          <mat-icon>navigate_before</mat-icon>
                  </button>
                  <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                          <mat-icon>navigate_next</mat-icon>
                  </button>
              </div>
          </div>
      </div>

</mat-dialog-content>
