import {Component, OnInit, ViewEncapsulation, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {globalConstants} from '../../../../environments/globalConstants';
import {GlobalService} from '../../../../environments/global.service';
import {globalFunctions} from '../../../../environments/globalFunctions';
import {Router, ActivatedRoute, Params, NavigationEnd} from '@angular/router';
import {FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ImagetooldialogComponent } from '../../../imagetooldialog/imagetooldialog.component';
import { ImagedialogComponent } from '../../../assetsComponent/imagedialog/imagedialog.component';
import {VideoShowComponent} from '../../../customer/my-vehicles/video-show/video-show.component';
import {AppComponent} from '../../../app.component';
import { DetailTabComponent } from '../detail-tab/detail-tab.component';
import { Http,Headers,ResponseContentType } from '@angular/http';
import  saveAs  from 'file-saver';
import {Subscription} from 'rxjs';

declare var jQuery:any;
declare var $ :any;
@Component({
  selector: 'app-shop-service-history-view',
  templateUrl: './shop-service-history-view.component.html',
  styleUrls: ['./shop-service-history-view.component.scss']
})
export class ShopServiceHistoryViewComponent implements OnInit, OnDestroy{
    public currentCustomer;
    public serviceRequestId: number;
    public serviceInfo: FormGroup;
    public serviceRequestList: any;
    public showServiceDetail: boolean = false;
    partsOptions = [];
    genericOptions = [];
    public subTotal;
    public totalTax;
    public ObjectJson = {};
    public nonTaxableValue = [];
    public totalReturnedValue;
    public finalShowAbleAmount;
    public rejectedJobCalculation = [];
    public grandTotalAcceptedArray = [];
    public grandTotalRejectedArray = [];
    public totalAcceptedCalculation = [];
    public path=globalConstants.S3_BUCKET_URL+globalConstants.SERVICE_PICTURE+globalConstants.IMG_THUMB;
    public reportDownloadLink='';
    public reportDownloadType=0;
    public vehicle_pic:any;
    public vin_photo:any;
    public globalConstants=globalConstants;
    public currentShopId;
    public reqImagePath=globalConstants.S3_BUCKET_URL+globalConstants.SERVICE_REQUEST_PICTURES+globalConstants.IMG_THUMB;
    public recoImagePath=globalConstants.S3_BUCKET_URL+globalConstants.RECOMMEDATION_IMAGE+globalConstants.IMG_THUMB;
    public reqDocPath=globalConstants.S3_BUCKET_URL+'Service-Request-Documents/';
    public showDownloadOrPrice=false;
    
    public customer_id;
    public currentCustomerDetails;
    public currentUserID;
    public userType;
    public requestCustomerDetails;
    public requestCustomerID;
    public getRequireApproval;
    public authorizeBy;
    public contactPerson;
    public contactPhoneNumber;
    public dateTimeOfCall;
    public otherMeans;
    public vehicleName="";
    public showCostPrice=false;
    public subscriptionDataForAPI: Subscription = new Subscription();

    constructor(private app: AppComponent,public cdr: ChangeDetectorRef,public globalService: GlobalService,public dialog: MatDialog, public router: Router, private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, public http:Http) {
        if (localStorage.getItem("loggedInUser")) {
            this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
            this.currentShopId = parseInt(this.currentCustomer["shop_id"]);
            this.currentUserID = this.currentCustomer.id;
            this.userType = this.currentCustomer.user_type;
        } else {
            this.router.navigate(["/shops"]);
        }
        setTimeout(()=>{
        let isEnable=this.globalService.isAllowed(this.app,'view_service_history','views');
        this.showCostPrice=this.globalService.getAcl('display_part_cost','views');
        if(!isEnable){
          this.router.navigate(['shops/action/forbidden']);
        }
      },globalConstants.RELOAD_WAIT);
      this.globalService.setMetaData("SHOPS","SERVICE_REQUEST_DETAIL")
        this.globalService.hideTooltipImage();
    }
    chkDate(date){
      if (date!="0000-00-00 00:00:00") {
        var currentDate = new Date();
        if(new Date(date)<new Date(currentDate)) {
          return true
        } else {
          return false;
        }
      }
    }
    getNltoBr(val){
      if(val && val.length>0){
        return val.toString().replace(/\r\n/g, "<br />").replace(/\n/g, "<br />");
      }
      return '-';
    }
    ConvertToNumber(str){ return parseInt(str)}

    ngOnInit() {
      this.serviceInfo = this.formBuilder.group({
        add_date:[],
        address1:[],
        address2:[],
        balance_amount:[],
        business_name:[],
        business_phone:[],
        car_id:[],
        city:[],
        color:[],
        comments:[],
        company_name:[],
        completion_date:[],
        confirmation_token:[],
        country:[],
        customer_id:[],
        customer_type:[],
        disclaimer:[],
        disclaimer_id:[],
        email:[],
        estimated_delivery_date:[],
        feedback_code:[],
        first_name:[],
        grand_total:[],
        has_app_downloaded:[],
        home_phone:[],
        id:[],
        inspection_reports:[],
        insurance_expiration_date:[],
        is_DIY:[],
        is_paid:[],
        is_supplies_charges_included:[],
        is_tax_included:[],
        is_usps_vehicle:[],
        labor_amount:[],
        labor_rate:[],
        last_name:[],
        license_plate:[],
        mail_sent:[],
        make:[],
        middle_name:[],
        mobile_phone:[],
        model:[],
        modify_date:[],
        no_report_performed:[],
        non_taxable_amount:[],
        notes:[],
        notify_accept_reject_jobs_email:[],
        notify_accept_reject_jobs_push:[],
        notify_create_service_facility_car_email:[],
        notify_create_service_facility_car_push:[],
        notify_expiration_dates_email:[],
        notify_expiration_dates_push:[],
        notify_expiration_dates_web:[],
        notify_feedback_email:[],
        notify_feedback_push:[],
        notify_make_changes_car_facility_service_request_email:[],
        notify_make_changes_car_facility_service_request_push:[],
        notify_send_updated_info_to_customer_email:[],
        notify_send_updated_info_to_customer_push:[],
        notify_service_completion_email:[],
        notify_service_completion_push:[],
        notify_to_admin:[],
        notify_to_customer:[],
        parts_amount:[],
        password:[],
        payment_date:[],
        payment_reminder_date:[],
        payment_reminder_status:[],
        present_mileage:[],
        read_status:[],
        recommendation_saved:[],
        registration_expiration_date:[],
        request_documents:[],
        request_id:[],
        request_images:[],
        request_status:[],
        requested_delivery_date:[],
        reset_pwd_token:[],
        schedule_service_date:[],
        service_facility:[],
        service_request_list:[],
        shop_id:[],
        state:[],
        status:[],
        supplies_charges:[],
        suppliescharges:[],
        tax_amount:[],
        tax_exempt_number:[],
        tax_exempted:[],
        tax_rate:[],
        technician:[],
        title:[],
        total_amount:[],
        total_paid:[],
        vin:[],
        year:[],
        zip:[],
        service_facility_name:[],
        request_notes:[]
      });
        this.setParams();
        let content = "user_id=" + this.currentCustomer.id + "&service_request_id=" + this.serviceRequestId+ "&user_type=" + this.currentCustomer.user_type;
       this.subscriptionDataForAPI =  this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUESTS_HISTROY_VIEW_URL, content).subscribe((response:any) => {
            if (response.result == 1) {
                // this.serviceInfo = response.data;
                this.serviceRequestList = response.data.service_request_list;
                this.showServiceDetail = true;
                var data = response;
                let shop=this.currentCustomer;
                if(shop.shop_id==data.data.shop_id){
                  this.showDownloadOrPrice=true;
                }
                this.serviceInfo.controls.add_date.setValue(this.globalService.getFullFormateDate(data.data.add_date,'G','B'))
                this.serviceInfo.controls.address1.setValue(data.data.address1)
                this.serviceInfo.controls.address2.setValue(data.data.address2)
                this.serviceInfo.controls.balance_amount.setValue(data.data.balance_amount)
                this.serviceInfo.controls.business_name.setValue(data.data.business_name)
                this.serviceInfo.controls.business_phone.setValue(data.data.business_phone)
                this.serviceInfo.controls.car_id.setValue(data.data.car_id)
                this.serviceInfo.controls.city.setValue(data.data.city)
                this.serviceInfo.controls.color.setValue(data.data.color)
                this.serviceInfo.controls.comments.setValue(data.data.comments)
                this.serviceInfo.controls.company_name.setValue(data.data.company_name)
                this.serviceInfo.controls.completion_date.setValue(this.globalService.getFullFormateDate(data.data.completion_date,'G','B'))
                this.serviceInfo.controls.confirmation_token.setValue(data.data.confirmation_token)
                this.serviceInfo.controls.country.setValue(data.data.country)
                this.serviceInfo.controls.customer_id.setValue(data.data.customer_id)
                this.serviceInfo.controls.customer_type.setValue(data.data.customer_type)
                this.serviceInfo.controls.disclaimer.setValue(data.data.disclaimer)
                this.serviceInfo.controls.disclaimer_id.setValue(data.data.disclaimer_id)
                this.serviceInfo.controls.email.setValue(data.data.email)
                this.serviceInfo.controls.estimated_delivery_date.setValue(this.globalService.getFullFormateDate(data.data.estimated_delivery_date,'G','B'))
                this.serviceInfo.controls.feedback_code.setValue(data.data.feedback_code)
                this.serviceInfo.controls.first_name.setValue(data.data.first_name)
                this.serviceInfo.controls.grand_total.setValue(data.data.grand_total)
                this.serviceInfo.controls.has_app_downloaded.setValue(data.data.has_app_downloaded)
                this.serviceInfo.controls.home_phone.setValue(data.data.home_phone)
                this.serviceInfo.controls.id.setValue(data.data.id)
                this.serviceInfo.controls.inspection_reports.setValue(data.data.inspection_reports)
                this.serviceInfo.controls.insurance_expiration_date.setValue(this.globalService.getFullFormateDate(data.data.insurance_expiration_date,'GD','B'))
                this.serviceInfo.controls.is_DIY.setValue(data.data.is_DIY)
                this.serviceInfo.controls.is_paid.setValue(data.data.is_paid)
                this.serviceInfo.controls.is_supplies_charges_included.setValue(data.data.is_supplies_charges_included)
                this.serviceInfo.controls.is_tax_included.setValue(data.data.is_tax_included)
                this.serviceInfo.controls.is_usps_vehicle.setValue(data.data.is_usps_vehicle)
                this.serviceInfo.controls.labor_amount.setValue(data.data.labor_amount)
                this.serviceInfo.controls.labor_rate.setValue(data.data.labor_rate)
                this.serviceInfo.controls.last_name.setValue(data.data.last_name)
                this.serviceInfo.controls.license_plate.setValue(data.data.license_plate)
                this.serviceInfo.controls.mail_sent.setValue(data.data.mail_sent)
                this.serviceInfo.controls.make.setValue(data.data.make)
                this.serviceInfo.controls.middle_name.setValue(data.data.middle_name)
                this.serviceInfo.controls.mobile_phone.setValue(data.data.mobile_phone)
                this.serviceInfo.controls.model.setValue(data.data.model)
                this.serviceInfo.controls.modify_date.setValue(this.globalService.getFullFormateDate(data.data.modify_date,'G','B'))
                this.serviceInfo.controls.no_report_performed.setValue(data.data.no_report_performed)
                this.serviceInfo.controls.non_taxable_amount.setValue(data.data.non_taxable_amount)
                this.serviceInfo.controls.notes.setValue(data.data.notes)
                this.serviceInfo.controls.notify_accept_reject_jobs_email.setValue(data.data.notify_accept_reject_jobs_email)
                this.serviceInfo.controls.notify_accept_reject_jobs_push.setValue(data.data.notify_accept_reject_jobs_push)
                this.serviceInfo.controls.notify_create_service_facility_car_email.setValue(data.data.notify_create_service_facility_car_email)
                this.serviceInfo.controls.notify_create_service_facility_car_push.setValue(data.data.notify_create_service_facility_car_push)
                this.serviceInfo.controls.notify_expiration_dates_email.setValue(data.data.notify_expiration_dates_email)
                this.serviceInfo.controls.notify_expiration_dates_push.setValue(data.data.notify_expiration_dates_push)
                this.serviceInfo.controls.notify_expiration_dates_web.setValue(data.data.notify_expiration_dates_web)
                this.serviceInfo.controls.notify_feedback_email.setValue(data.data.notify_feedback_email)
                this.serviceInfo.controls.notify_feedback_push.setValue(data.data.notify_feedback_push)
                this.serviceInfo.controls.notify_make_changes_car_facility_service_request_email.setValue(data.data.notify_make_changes_car_facility_service_request_email)
                this.serviceInfo.controls.notify_make_changes_car_facility_service_request_push.setValue(data.data.notify_make_changes_car_facility_service_request_push)
                this.serviceInfo.controls.notify_send_updated_info_to_customer_email.setValue(data.data.notify_send_updated_info_to_customer_email)
                this.serviceInfo.controls.notify_send_updated_info_to_customer_push.setValue(data.data.notify_send_updated_info_to_customer_push)
                this.serviceInfo.controls.notify_service_completion_email.setValue(data.data.notify_service_completion_email)
                this.serviceInfo.controls.notify_service_completion_push.setValue(data.data.notify_service_completion_push)
                this.serviceInfo.controls.notify_to_admin.setValue(data.data.notify_to_admin)
                this.serviceInfo.controls.notify_to_customer.setValue(data.data.notify_to_customer)
                this.serviceInfo.controls.parts_amount.setValue(data.data.parts_amount)
                this.serviceInfo.controls.password.setValue(data.data.password)
                this.serviceInfo.controls.payment_date.setValue(data.data.payment_date)
                this.serviceInfo.controls.payment_reminder_date.setValue(data.data.payment_reminder_date)
                this.serviceInfo.controls.payment_reminder_status.setValue(data.data.payment_reminder_status)
                this.serviceInfo.controls.present_mileage.setValue(data.data.present_mileage)
                this.serviceInfo.controls.read_status.setValue(data.data.read_status)
                this.serviceInfo.controls.recommendation_saved.setValue(data.data.recommendation_saved)
                this.serviceInfo.controls.registration_expiration_date.setValue(this.globalService.getFullFormateDate(data.data.registration_expiration_date,'GD','B'))
                if(data.data.request_images.length > 0) {
                  this.serviceInfo.controls.request_documents.setValue(data.data.request_documents)
                } else {
                  this.serviceInfo.controls.request_documents.setValue(null)
                }
                this.serviceInfo.controls.request_id.setValue(data.data.request_id)
                if(data.data.request_images.length > 0) {
                  this.serviceInfo.controls.request_images.setValue(data.data.request_images)
                } else {
                  this.serviceInfo.controls.request_images.setValue(null)
                }
                this.serviceInfo.controls.request_status.setValue(parseInt(data.data.request_status));
                this.serviceInfo.controls.requested_delivery_date.setValue(this.globalService.getFullFormateDate(data.data.requested_delivery_date,'G','B'))
                this.serviceInfo.controls.reset_pwd_token.setValue(data.data.reset_pwd_token)
                this.serviceInfo.controls.schedule_service_date.setValue(this.globalService.getFullFormateDate(data.data.schedule_service_date,'G','B'))
                this.serviceInfo.controls.service_facility.setValue(data.data.service_facility)
                this.serviceInfo.controls.service_request_list.setValue(data.data.service_request_list)
                this.serviceInfo.controls.shop_id.setValue(parseInt(data.data.shop_id))
                this.serviceInfo.controls.state.setValue(data.data.state)
                this.serviceInfo.controls.status.setValue(data.data.status)
                this.serviceInfo.controls.supplies_charges.setValue(data.data.supplies_charges)
                this.serviceInfo.controls.suppliescharges.setValue(data.data.suppliescharges)
                this.serviceInfo.controls.tax_amount.setValue(data.data.tax_amount)
                this.serviceInfo.controls.tax_exempt_number.setValue(data.data.tax_exempt_number)
                this.serviceInfo.controls.tax_exempted.setValue(data.data.tax_exempted)
                this.serviceInfo.controls.tax_rate.setValue(data.data.tax_rate)
                this.serviceInfo.controls.technician.setValue(data.data.technician)
                this.serviceInfo.controls.title.setValue(data.data.title)
                this.serviceInfo.controls.total_amount.setValue(data.data.total_amount)
                this.serviceInfo.controls.total_paid.setValue(data.data.total_paid)
                this.vehicle_pic=data.data.vehicle_pic
                this.vin_photo=data.data.vin_photo
                this.serviceInfo.controls.vin.setValue(data.data.vin)
                this.serviceInfo.controls.year.setValue(data.data.year)
                this.serviceInfo.controls.zip.setValue(data.data.zip);
                this.serviceInfo.controls.service_facility_name.setValue(data.data.service_facility_name);
                /* GET REQUIRE APPROVAL DETAILS */
                this.customer_id = data.data.customer_id;
                this.authorizeBy = data.data.authorize_by;
                this.contactPerson = data.data.contact_person;
                this.contactPhoneNumber = data.data.contact_person_phone_number;
                this.dateTimeOfCall = this.globalService.getFullFormateDate(data.data.date_time_of_call,'G','H');
                this.otherMeans = data.data.other_means_of_contacts;
                /* END */
                this.downloadUspsReport();
                this.updateLevelPrice();
                this.calculatefinalAmount (this.serviceInfo.value.tax_rate,this.serviceInfo.value.supplies_charges);
                this.subTotal = this.addArray(this.totalAcceptedCalculation).toFixed(2);
                this.totalTax = ((this.subTotal * this.serviceInfo.value.tax_rate)/100).toFixed(2);
                if (data.data.year =="") {
                  this.vehicleName= "";
                } else {
                  this.vehicleName= data.data.year ;
                }
                if(data.data.make != ""){
                  this.vehicleName += (this.vehicleName ? "/" : "" ) + data.data.make;
                }
                if(data.data.model != ""){
                  this.vehicleName += (this.vehicleName ? "/" : "" ) + data.data.model
                }
                this.vehicleName = (this.vehicleName ? this.vehicleName :"N/A");
                
            } else {
                this.showServiceDetail = false;
                this.globalService.snackbar("error", response.message);
            }
            
            /* GET CUSTOMER DETAILS */
//            var customerOprs = "user_id="+this.currentUserID+"&user_type="+this.userType+"&customer_id="+ this.customer_id;
//            this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUEST_VIEW_URL, customerOprs).subscribe((response) => {
//                this.requestCustomerDetails = response.data;
//                this.getRequireApproval = this.requestCustomerDetails.require_approval;
//            });
            /* END */
        });
    }

    setParams() {
        this.activatedRoute.params.subscribe((params: Params) => {
            this.serviceRequestId = params["serviceId"];
        });
    }

    addArray(array) {

        var sum = 0.00;
        if (array != undefined) {
            for (var i = 0; i < array.length; i++) {
                sum = sum + parseFloat(array[i]);
            }
        }
        return sum
    }

    calculatefinalAmount(tax_rate, supplies_charges) {
        var acceptedValue = this.addArray(this.totalAcceptedCalculation);
        var totalNonTaxedValue = this.addArray(this.nonTaxableValue);
        var taxableValue = acceptedValue - totalNonTaxedValue;
        var finalValue;
        var totalCalculatedAmount

        if(this.serviceInfo.value.request_status == "6" || this.serviceInfo.value.request_status == "8") {
          finalValue = this.serviceInfo.value.grand_total;
        } else if(this.serviceInfo.value.request_status == "3" ||  this.serviceInfo.value.request_status == "4" || this.serviceInfo.value.request_status == "5" || this.serviceInfo.value.request_status == "7" || this.serviceInfo.value.request_status == "12" ){
          if(this.serviceInfo.value.is_tax_included  == "1") {
            totalCalculatedAmount = acceptedValue + (taxableValue * (parseFloat(tax_rate)/100))
          } else {
            totalCalculatedAmount = acceptedValue;
          }

          if(this.serviceInfo.value.is_supplies_charges_included == "1") {
            finalValue = totalCalculatedAmount + parseFloat(supplies_charges);
          } else  {
            finalValue = totalCalculatedAmount
          }
        } else {
          finalValue = this.serviceInfo.value.total_amount
        }
        if(finalValue!=null) {
          this.finalShowAbleAmount = parseFloat(finalValue).toFixed(2)
        }
    }
    toNumber(str) { return parseInt(str)}
    fillRecommendation(id) {

        this.router.navigate(["/shops/service-inspection-report/" + id]);
    }

    getBalance(gTotal, bal) {
        if (bal != null) {
            return (parseFloat(gTotal) - parseFloat(bal));
        }
        return gTotal;
    }
    openImageDialog(catType){
        let imgfile;
      if(catType == 1){
        imgfile= this.vehicle_pic;
      }else{
        imgfile= this.vin_photo;
      }
      let dialogRef = this.dialog.open(ImagedialogComponent, {
          panelClass: ['image-dialog'],
          data: {img:imgfile, category:catType}

      });
      imgfile="";
    }
    generateInvoice(rid, total, taxRate, isTaxIncluded) {
        var conf = confirm("Are you sure you want to mark this request as complete and generate its invoice?");

        if (conf == true) {
            window.open('generate-invoice.php?r=' + rid + '&ti=' + isTaxIncluded + '&tr=' + taxRate + '&to=' + total, '_blank', 'titlebar=no,toolbar=no,resizeable=no,location=no,height=600,width=800,scrollbars=yes');
        } else {
            return false;
        }
    }
    getInvoicePdf(){
      let userId=this.globalService.getCurrentUser()["id"];
      let url = globalConstants.API_GENERATE_INVOICE_URL;
      let data={
        "service_request_id":this.serviceRequestId,
        "user_type":"User",
        "invoke_download":1,
        "user_id":userId,
        "is_tax_included":this.serviceInfo.value.is_tax_included,
        "tax_rate":this.serviceInfo.value.tax_rate,
        "total":this.serviceInfo.value.grand_total
      };
      let skipFirst:boolean = true;
      for(let d in data){
        if(skipFirst == true){
          url += "?"+d+"="+data[d];
          skipFirst = false;
        }else{
          url += "&"+d+"="+data[d];
        }
      }
      let serviceRequestId = this.serviceInfo.controls.request_id.value;
      this.globalService.downloadFileWithGet(url,serviceRequestId);
    }
    expansionOpen(ele) {
        var target= '#expansionHead-'+ ele;
        var eleState = $(target).data('state');
        $(".new-request-expension").find(".mat-expansion-panel-header").data("state",false);
        if(!eleState){
          setTimeout(() => {
             let element = document.getElementById('expansion-' + ele);
             var target= '#expansionHead-'+ ele;
             setTimeout(() => {
               var body = $("html, body");
               body.animate({
                      scrollTop: jQuery(target).offset().top - 90
                }, 500);
             }, 500);
             $(target).data('state',true);
          }, 200);
        }
    }
    downloadUspsReport() {
        let downloadUSPSReport_URL = '';
        let reportData;
        let qryStringUrl = "";
        let data=this.serviceInfo.value.inspection_reports;
        if (data.length) {
          reportData = data[0].acdelco_report_data;
          if (reportData) {
            downloadUSPSReport_URL = globalConstants.API_DOWNLOAD_INSPECTION_REPORT_ACDELCO_URL;
            qryStringUrl += "?report_type=2";
                 qryStringUrl += "&acdelco_report_id=" + reportData.id;
                 this.reportDownloadType=2;
          } else {
            downloadUSPSReport_URL = globalConstants.API_DOWNLOAD_INSPECTION_REPORT_USPS_URL;
            reportData = data[0].usps_report_data;
            qryStringUrl += "?report_type=1";
                 qryStringUrl += "&usps_report_id=" + reportData.id;
                 this.reportDownloadType=1;
          }

          this.reportDownloadLink = downloadUSPSReport_URL + qryStringUrl;
        } else {
          this.reportDownloadLink = '';
        }

      }
      /* image rotation dialog  */
     imageToolDialog(current,i,type,imgaar): void {
      let dialogRef = this.dialog.open(ImagetooldialogComponent, {
          panelClass: ['imageToolDialog'],
          width: "100%",
          data: {event: current,index:i,imgType:type,imgArray:imgaar,customerId:this.currentCustomer.id}
      });

      dialogRef.afterClosed().subscribe(result => {
        //   console.log('The dialog was closed');
      });
   }
   /*******video dialog******/
 videoShow(val): void {
    let dialogRef = this.dialog.open(VideoShowComponent, {
        panelClass: 'car-dialog-form',
        width: "800px",
        data: {name: val, url: globalConstants.S3_BUCKET_URL + 'recommendation-videos/'}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
  updateLevelPrice(){
    if(this.serviceInfo.value.request_status!="9" && this.serviceInfo.value.request_status!="10" )  {
      for(var i = 0;i<this.serviceInfo.value.service_request_list.length;i++) {
        this.totalReturnedValue = this.getTotalPrice(this.serviceInfo.value.service_request_list[i]);
        var returingObj = this.getAcceptAndRejectCalculationForPage(this.serviceInfo.value.service_request_list[i],null)
        this.ObjectJson[this.serviceInfo.value.service_request_list[i]["id"]]=
        (
          {
            AcceptedValue:returingObj[0].toFixed(2),
            RejectedValue:returingObj[1].toFixed(2)
          }
        )
      }
    }
  }
  getAcceptAndRejectCalculationForPage(serviceRequest,returningStatus){
    var total = 0;
    var rejected = 0;
    var approved = 0;
    var finalCalculation = 0;
    serviceRequest.children.forEach(l2child => {

      if(l2child.recommendations.length>0) {
        total = 0;
        var l2childRecommendation = l2child.recommendations[0];
        var approval_status = l2childRecommendation.approval_status;

        // parts calculation for l2

        if(l2childRecommendation.parts.length > 0) {
          l2childRecommendation.parts.forEach((part)=>{
            total = total + (parseFloat(part.part_price) * parseFloat(part.quantity))
          })
        }

        // GJ calculation for l2

        if(l2childRecommendation.generic_jobs.length > 0) {
          l2childRecommendation.generic_jobs.forEach(gj => {
            total = total + (parseFloat(gj.price) * parseFloat(gj.quantity))
          });
        }
        total = total + parseFloat(l2childRecommendation.estimated_labor);
        finalCalculation = finalCalculation + total
        if(approval_status == "2") {
          rejected = rejected + total;
        } else {
          approved = approved + total;
        }
        // this.ObjectJson.push({[serviceRequest.id]:{[l2child.id]:{"total":total,"status":approval_status}}})

      }
      else {
        l2child.children.forEach(l3child=>{

          total = 0;
          var l3childRecommendation = l3child.recommendations[0];
          var approval_status = l3childRecommendation.approval_status;

          if(l3childRecommendation.parts.length > 0) {
            l3childRecommendation.parts.forEach((part)=>{
              total = total + (parseFloat(part.part_price) * parseFloat(part.quantity))
            })
          }
          if(l3childRecommendation.generic_jobs.length > 0) {
            l3childRecommendation.generic_jobs.forEach(gj => {
              total = total + (parseFloat(gj.price) * parseFloat(gj.quantity))
            });
          }
          total = total + parseFloat(l3childRecommendation.estimated_labor);

          finalCalculation = finalCalculation + total
          if(approval_status == "2") {
            rejected = rejected + total;
          } else {
            approved = approved + total;
          }
        });
      }
    });

    if(returningStatus == null ){
      return [approved,rejected]
    }
    // return returningStatus == "accept" ? approved : rejected;
  }
  getTotalPrice(object) {
    var totalPriceForGj = 0,totalPriceForParts = 0,rejectedJobCost = 0, nonTaxableAmount=0;
    var labourCost = 0;
    for(var i=0;i<object.children.length;i++) {
      if(object.children[i].children!=undefined && object.children[i].children.length > 0) {

        // do the level 2 jobs
        this.callToThirdLevelCalculations(object.children[i].children);
      } else {
        if(object.children[i].recommendations!=undefined && object.children[i].recommendations.length > 0) {

          for(var j=0;j<object.children[i].recommendations.length;j++) {
            labourCost = 0;
            var scopedNonTaxedValue = 0;
            labourCost = labourCost + parseFloat(object.children[i].recommendations[j].estimated_labor);
            // calculate price for parts
            for(var k=0;k<object.children[i].recommendations[j].parts.length;k++) {
              if(object.children[i].recommendations[j].parts.length > 0) {
                totalPriceForParts = parseFloat(totalPriceForParts.toString()) + (object.children[i].recommendations[j].parts[k].part_price * object.children[i].recommendations[j].parts[k].quantity);
                if(object.children[i].recommendations[j].parts[k].non_taxable == "1" && object.children[i].recommendations[j].approval_status !="2") {
                  scopedNonTaxedValue = scopedNonTaxedValue  + (object.children[i].recommendations[j].parts[k].part_price * object.children[i].recommendations[j].parts[k].quantity);
                }
              }
            }

            // calculate cost for generic jobs
            for(var k=0;k<object.children[i].recommendations[j].generic_jobs.length;k++) {
              totalPriceForGj = totalPriceForGj + (object.children[i].recommendations[j].generic_jobs[k].price * object.children[i].recommendations[j].generic_jobs[k].quantity);
              if(object.children[i].recommendations[j].generic_jobs[k].non_taxable == "1" && object.children[i].recommendations[j].approval_status !="2") {
                scopedNonTaxedValue = scopedNonTaxedValue  + (object.children[i].recommendations[j].generic_jobs[k].price * object.children[i].recommendations[j].generic_jobs[k].quantity);
              }
            }
            this.nonTaxableValue.push(scopedNonTaxedValue);

            var totalAmount = parseFloat(totalPriceForParts.toString()) + parseFloat(totalPriceForGj.toString()) + parseFloat(labourCost.toString());
            totalAmount = parseFloat(totalAmount.toString());
            if(object.children[i].recommendations[j].approval_status == "2") {
              this.rejectedJobCalculation.push(totalAmount);
              this.nonTaxableValue.splice(this.nonTaxableValue.length-1,1)
            } else {
              this.totalAcceptedCalculation.push(totalAmount);
            }

            totalAmount = 0;
            totalPriceForParts = 0;
            totalPriceForGj = 0;
          }

        }
      }
    }

    return [this.totalAcceptedCalculation,this.rejectedJobCalculation]
  }
  callToThirdLevelCalculations(secondLevelChildren) {

          var totalPriceForGj = 0,totalPriceForParts = 0,rejectedJobCost = 0;
          var labourCost = 0;

          for(var i=0;i<secondLevelChildren.length;i++) {
            if(secondLevelChildren[i].recommendations.length > 0) {
              for(var j=0;j<secondLevelChildren[i].recommendations.length;j++) {
                var tobeDeducted = 0;
                var scopedNonTaxedValue = 0;
                totalPriceForGj = 0;
                totalPriceForParts = 0;
                rejectedJobCost = 0;

                for(var k=0;k<secondLevelChildren[i].recommendations[j].parts.length;k++) {
                  totalPriceForParts = totalPriceForParts + (secondLevelChildren[i].recommendations[j].parts[k].part_price * secondLevelChildren[i].recommendations[j].parts[k].quantity);
                  if(secondLevelChildren[i].recommendations[j].parts[k].non_taxable == "1" && secondLevelChildren[i].recommendations[j].approval_status !="2") {
                    scopedNonTaxedValue = scopedNonTaxedValue + (secondLevelChildren[i].recommendations[j].parts[k].part_price * secondLevelChildren[i].recommendations[j].parts[k].quantity)
                  }
                }

                for(var k=0;k<secondLevelChildren[i].recommendations[j].generic_jobs.length;k++) {
                  totalPriceForGj = totalPriceForGj + (secondLevelChildren[i].recommendations[j].generic_jobs[k].price * secondLevelChildren[i].recommendations[j].generic_jobs[k].quantity);
                  if(secondLevelChildren[i].recommendations[j].generic_jobs[k].non_taxable == "1" && secondLevelChildren[i].recommendations[j].approval_status !="2") {
                    scopedNonTaxedValue = scopedNonTaxedValue + (secondLevelChildren[i].recommendations[j].generic_jobs[k].price * secondLevelChildren[i].recommendations[j].generic_jobs[k].quantity);
                  }
                }

                this.nonTaxableValue.push(scopedNonTaxedValue);
                labourCost = labourCost + parseFloat(secondLevelChildren[i].recommendations[j].estimated_labor);

                var totalAmount = parseFloat(totalPriceForParts.toString()) + parseFloat(totalPriceForGj.toString()) + parseFloat(labourCost.toString()) - parseInt(tobeDeducted.toString());
                totalAmount = parseFloat(totalAmount.toString());

                if(secondLevelChildren[i].recommendations[j].approval_status == "2") {
                  this.nonTaxableValue.splice(this.nonTaxableValue.length-1,1)
                  this.rejectedJobCalculation.push(totalAmount)
                } else {
                  this.totalAcceptedCalculation.push(totalAmount);
                }
                tobeDeducted = 0;
                totalAmount = 0;
                totalPriceForParts = 0;
                totalPriceForGj = 0;
                labourCost = 0
              }
            } else {
            }
          }
        }
        parseFloats(value){
          let val=parseFloat(value);
          if(isNaN(val)){
            return '0.00';
          }
          return val.toFixed(2);
        }
        isAllowed(action,module?:any){
          if(typeof module!='undefined'){
              return this.globalService.isAllowed(this.app,module,action);            
          }
          return false;
      }
      copyText() {
        var text = this.serviceInfo.value.vin;
        this.globalService.txtCopy(text);
      }
      detailTab(type) {
        let dialogRef = this.dialog.open(DetailTabComponent, {
          panelClass: 'car-dialog-form',
          width: '800px',
          data: { requestId: this.serviceRequestId, customer_id: this.customer_id, tab: type ,car_id : this.serviceInfo.value.car_id }
        });
      }
      invokeDownload(url,type,fileName=''){
        let localFileName = 'DownloadedFile',localNameExt,localName;
        if(type == 'ServiceRequestDocument'){
          localName = fileName.split('.');
          localNameExt = localName[localName.length-1];
          localFileName = 'ServiceRequestDocument.'+localNameExt;
        }else if(type == 'CARInspectionReport'){
          localFileName = 'CARInspectionReport.pdf';
        }
        //console.log(localFileName);
        this.globalService.downloadAsset(url,localFileName);
      }
      getFileIcons(str){
        let ext=str.substr(str.lastIndexOf(".")+1);
        let type=0;
        if(ext!=''){
          type= this.globalService.getFileIcon({type:ext.toLowerCase()});
        }
        // console.log(str,ext,type);
        return type;
       }

       ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
      }  
      
}
