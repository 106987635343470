import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//shop routes
import {ShoploginComponent} from './shop/shoplogin/shoplogin.component';
import {ShopdashboardComponent} from './shop/shopdashboard/shopdashboard.component';
import {ShopForgotPasswordComponent} from './shop/shopforgotpassword/shopforgotpassword.component';
import {ShopaddnewpartComponent} from './shop/shopaddnewpart/shopaddnewpart.component';
import {ShopaddgenericjobComponent} from './shop/shopaddgenericjob/shopaddgenericjob.component';
import {ShopeditgenericjobComponent} from './shop/shopeditgenericjob/shopeditgenericjob.component';
import {ShopeditpartComponent} from './shop/shopeditpart/shopeditpart.component';
import {ShopadddisclaimerComponent} from './shop/shopadddisclaimer/shopadddisclaimer.component';
import {ShopeditdisclaimerComponent} from './shop/shopeditdisclaimer/shopeditdisclaimer.component';
import {ShopaddcomputercodeComponent} from './shop/shopaddcomputercode/shopaddcomputercode.component';
import {ShopeditcomputercodeComponent} from './shop/shopeditcomputercode/shopeditcomputercode.component';
import {addCCFORM, ShopSubscriptionComponent} from './shop/shop-subscription/shop-subscription.component';
import {FillRecommendationComponent,Autoui, CloseOrRefresh} from './shop/shopservicerequest/fill-recommendation/fill-recommendation.component';
import { SalesreportComponent } from './shop/shopreports/salesreport/salesreport.component';
import { EmployeelogsComponent } from './shop/shopreports/employeelogs/employeelogs.component';
import { OverduereportComponent } from './shop/shopreports/overduereport/overduereport.component';

import {ChangeshopspasswordComponent} from './shop/changeshopspassword/changeshopspassword.component';
import {EditshopprofileComponent} from './shop/editshopprofile/editshopprofile.component';
import {EasycheckinComponent} from './shop/easycheckin/easycheckin.component';
import {EdituserprofileComponent} from './shop/edituserprofile/edituserprofile.component';
import {AddnewcustomerComponent} from './shop/shopcustomers/addnewcustomer/addnewcustomer.component';
import {ListshopcustomersComponent} from './shop/shopcustomers/listshopcustomers/listshopcustomers.component';
import {EditshopcustomersComponent} from './shop/shopcustomers/editshopcustomers/editshopcustomers.component';
import {AddcarComponent} from './shop/shopcar/addcar/addcar.component';
import {ListcustomercarsComponent} from './shop/shopcar/listcustomercars/listcustomercars.component';
import {ListshopserviceComponent, UserAuthConfirm} from './shop/shopservicerequest/listshopservice/listshopservice.component';
import {ShopcardetailsComponent} from './shop/shopcar/shopcardetails/shopcardetails.component';
import {ShopeditcarComponent} from './shop/shopcar/shopeditcar/shopeditcar.component';
import {ShopcarservicehistoryComponent} from './shop/shopcar/shopcarservicehistory/shopcarservicehistory.component';
import {ListshoppartsComponent} from './shop/shopparts/listshopparts/listshopparts.component';
import {ListgenericjobsComponent} from './shop/shopgenericjob/listgenericjobs/listgenericjobs.component';
import {ListshopdisclaimersComponent} from './shop/shopdisclaimers/listshopdisclaimers/listshopdisclaimers.component';
import {ViewServicerequestComponent, changeDisclaimer} from './shop/shopservicerequest/view-servicerequest/view-servicerequest.component';
import {EditServicerequestComponent} from './shop/shopservicerequest/edit-servicerequest/edit-servicerequest.component';
import {ShoplistcomputercodesComponent} from './shop/shopcomputercodes/shoplistcomputercodes/shoplistcomputercodes.component';
import {AddeditlabourratesComponent} from './shop/shoplabourrates/addeditlabourrates/addeditlabourrates.component';
import {AddeditmailchimpComponent} from './shop/shopconnection/addeditmailchimp/addeditmailchimp.component';
import {ShoplistservicecomplaintsComponent} from './shop/shopservicecomplaints/shoplistservicecomplaints/shoplistservicecomplaints.component';
import {ShopviewservicecomplaintComponent} from './shop/shopservicecomplaints/shopviewservicecomplaint/shopviewservicecomplaint.component';
import {ShopviewcoustomerinquiryComponent} from './shop/shopinquiries/shopviewcoustomerinquiry/shopviewcoustomerinquiry.component';
import {ListshopinquiriesComponent} from './shop/shopinquiries/listshopinquiries/listshopinquiries.component';
import {ImportContactsComponent} from './shop/shopcustomers/import-contacts/import-contacts.component';
import {SelfCheckinComponent} from './shop/self-checkin/self-checkin.component';
import { ServiceRequestLogsComponent } from './shop/shopservicerequest/service-request-logs/service-request-logs.component';
// shop payment(2checkout) repsonse
import {PaymentResponseComponent} from './shop/payment-response/payment-response.component';
import {NewServiceRequestComponent, addCarDialog} from './shop/new-service-request/new-service-request.component';
import {ShopCustomerFeedbackComponent} from './shop/shop-customer-feedback/shop-customer-feedback.component';
import { ShopcustomerinformationComponent } from './shop/shopcustomers/shopcustomerinformation/shopcustomerinformation.component';
import { ShopServiceHistoryViewComponent } from './shop/shopservicerequest/shop-service-history-view/shop-service-history-view.component';
import { AddeditpartstechComponent } from './shop/shopconnection/addeditpartstech/addeditpartstech.component';
import { ShopresetpasswordComponent } from './shop/shopresetpassword/shopresetpassword.component';
import { CustomerresetpasswordComponent } from './customer/customerresetpassword/customerresetpassword.component';
import { AddvendorComponent } from './shop/shopvendors/addvendor/addvendor.component';
import { ListshopvendorsComponent } from './shop/shopvendors/listshopvendors/listshopvendors.component';
import { EditshopvendorComponent } from './shop/shopvendors/editshopvendor/editshopvendor.component';
import { PagenotfoundComponent } from "./pagenotfound/pagenotfound.component";
//anil sir
import { AddshopresourceComponent } from './shop/shopresources/addshopresource/addshopresource.component';
import { ListshopresourcesComponent } from './shop/shopresources/listshopresources/listshopresources.component';
import { EditshopresourceComponent } from './shop/shopresources/editshopresource/editshopresource.component';
// apoorv
import { PagesComponent } from './pages/pages.component';
import { ConfirmsignupComponent } from './customer/confirmsignup/confirmsignup.component';
import { ContactComponent } from './contact/contact.component';
import { ShopPaymentSuccessComponent } from './shop/payment-response/shop-payment-success/shop-payment-success.component';
import { ShopPaymentFailureComponent } from './shop/payment-response/shop-payment-failure/shop-payment-failure.component';
import { ErrorOccurredComponent } from './error-occurred/error-occurred.component';
import { StockOrderComponent } from './shop/shopparts/stock-order/stock-order.component';
import { PermissionComponent } from './role/permission/permission.component';
import { ListStockOrdersComponent } from './shop/shopparts/list-stock-orders/list-stock-orders.component';
import { ViewStockOrdersComponent } from './shop/shopparts/view-stock-orders/view-stock-orders.component';
import { AddemployeeComponent } from './shop/employee/addemployee/addemployee.component';
import { EditemployeeComponent } from './shop/employee/editemployee/editemployee.component';
import { ViewcustomerdetailsComponent } from './shop/shopcustomers/viewcustomerdetails/viewcustomerdetails.component';
import { ListemployeeComponent,resetPassword } from './shop/employee/listemployee/listemployee.component';
import { ViewempComponent } from './shop/employee/viewemp/viewemp.component';
import { EmpPermissionComponent } from './shop/employee/emp-permission/emp-permission.component';
import { AccessLimitComponent } from './shop/access-limit/access-limit.component';
import { TechreportComponent } from './shop/employee/techreport/techreport.component';
import { CalendarviewComponent } from './calendarview/calendarview.component';
import { PartlogComponent } from './shop/shopparts/partlog/partlog.component';
import { PartlogdetailComponent } from './shop/shopparts/partlogdetail/partlogdetail.component';
import { RegistrationComponent } from './shop/registration/registration.component';
import { MailverificationComponent } from './shop/mailverification/mailverification.component';
import { NotificationComponent } from './notification/notification.component';
import { NotificationhandelComponent } from './notification/notificationhandel/notificationhandel.component';
import { ImportexportComponent } from './shop/shopparts/importexport/importexport.component';
import { PartuploadlistComponent } from './shop/shopparts/partuploadlist/partuploadlist.component';
import { PartuploaddetailComponent } from './shop/shopparts/partuploaddetail/partuploaddetail.component';
import { PartimportdetailComponent } from './shop/shopparts/partimportdetail/partimportdetail.component';
import { QueorderComponent } from './shop/shopparts/queorder/queorder.component';
import { QuedetailComponent } from './shop/shopparts/quedetail/quedetail.component';
import { ReturnquelistComponent } from './shop/shopparts/returnquelist/returnquelist.component';
import { ReturnviewComponent } from './shop/shopparts/returnview/returnview.component';
import { WAKdetailsComponent } from './wak/wak-details/wak-details.component';
import { LogviewComponent } from './shop/shopservicerequest/logview/logview.component';
import { ShopSmartBatchListComponent } from './shop/smart-batch/smart-batch-list/shop-smart-batch-list.component';
import { PartsmatrixComponent } from './shop/shopparts/partsmatrix/partsmatrix.component';
import { AddSmartBatchComponent } from './shop/shopparts/add-smart-batch/add-smart-batch.component';
import { InternalnotesComponent } from './shop/internalnote/internalnotes/internalnotes.component';
import { ViewnoteComponent } from './shop/internalnote/viewnote/viewnote.component';
import { EditnoteComponent } from './shop/internalnote/editnote/editnote.component';
import { ViewSrnoteComponent } from './shop/srnote/view-srnote/view-srnote.component';
import { QqlistComponent } from './shop/quickquote/qqlist/qqlist.component';
import { InspectionListComponent } from './shop/shopservicerequest/inspection-list/inspection-list.component';
import { VkrouteComponent } from './vkroute/vkroute.component';
import { AddDiscountComponent } from './shop/discounts/add-discount/add-discount.component';
import { ListDiscountsComponent } from './shop/discounts/list-discounts/list-discounts.component';
import { EditDiscountComponent } from './shop/discounts/edit-discount/edit-discount.component';
import{​​ ServiceHistoryComponent }​​ from './shop/shopservicerequest/service-history/service-history.component';
import {​​ ListVendorsComponent }​​ from './shop/nexpart/list-vendors/list-vendors.component';
import { AddNexpartVendorComponent }​​ from './shop/nexpart/add-nexpart-vendor/add-nexpart-vendor.component';
import { EditNexpartVendorComponent }​​ from './shop/nexpart/edit-nexpart-vendor/edit-nexpart-vendor.component';
import {​​ CallbackComponent }​​ from './shop/nexpart/callback/callback.component';
import { CustomerpulllogsComponent } from './admin/customerpulllogs/customerpulllogs.component';
import { ServiceCalendarComponent } from './shop/shopservicerequest/service-calendar/service-calendar.component'; 
import { AttentionServiceRequestsComponent } from './shop/shopservicerequest/attention-service-requests/attention-service-requests.component';
import { AdvancereportComponent } from './shop/shopreports/advancereport/advancereport.component';
import { BusinessmetricsComponent } from './shop/shopreports/businessmetrics/businessmetrics.component';
import { CustomerapprovaldetailsComponent } from './shop/shopcustomers/customerapprovaldetails/customerapprovaldetails.component';
import { TempCustomerApprovalComponent } from './shop/tempCustomerApproval/tempCustomerApproval.component';
import { ListTemplatesComponent } from './shop/sms-templates/list-template/list-templates.component';
import { EditTemplateComponent } from './shop/sms-templates/edit-template/edit-template.component';
import { WorkflowComponent } from './shop/boards/workflow/workflow.component';
import { OnTheLotComponent } from './shop/boards/on-the-lot/on-the-lot.component';
import { MyWorkLoadComponent } from './shop/boards/my-work-load/my-work-load.component';
import {CarheadService,CanComponentDeactivate} from './carhead.service';
import { AuthGuard } from './base/authguard/auth-guard.guard';
import { MobileNoIgnoreListComponent } from './shop/mobile-ignore-list/mobile-no-ignore-list/mobile-no-ignore-list.component';
import { AddMobileNoComponent } from './shop/mobile-ignore-list/add-mobile-no/add-mobile-no.component';
import { EditMobileNoComponent } from './shop/mobile-ignore-list/edit-mobile-no/edit-mobile-no.component';
import { QboConnectComponent } from './shop/qbo-connect/qbo-connect.component';
import { DueForServiceComponent } from './shop/due-for-service/due-for-service.component';
import { ShopVehiclesListComponent } from './shop/shop-vehicles-list/shop-vehicles-list.component';
import { LastServiceVehiclesComponent } from './shop/shopcar/last-service-vehicles/last-service-vehicles.component';
import { ShopDashboardTwoComponent } from './shop/shop-dashboard-two/shop-dashboard-two.component';
import { ShopProfileDetailComponent } from './shop/shop-profile-detail/shop-profile-detail.component';
import { InventoryStatsComponent } from './shop/shopreports/inventory-stats/inventory-stats.component';

const routes: Routes = [
  {path: '', component: ShoploginComponent},
  {path: 'pages', component: PagesComponent},
  {path: 'pages/sreset-password', component: ShopresetpasswordComponent},
  {path: 'pages/slogin', component: ShoploginComponent},
  {path: 'pages/sforgot_password', component: ShopForgotPasswordComponent},
  {path: 'pages/creset-password', component: CustomerresetpasswordComponent},
  {path: 'contact-us', component: ContactComponent},
  {path: 'pcontact', component: ContactComponent},
  {path: 'confirm-signup',component:ConfirmsignupComponent},
  {path: 'confirmsignup',component:ConfirmsignupComponent},
  {path: 'shops-confirm-signup',component:MailverificationComponent},
  {path: 'error',component:ErrorOccurredComponent}, 
  {path: 'admin/logs/customer-pull', component: CustomerpulllogsComponent},

  {path: "shops/register", component: RegistrationComponent},
  {path: "shops/view-stock-orders/:id", component: ViewStockOrdersComponent,canActivate:[AuthGuard] },
  {path: 'shops/list-stock-orders', component: ListStockOrdersComponent,canActivate:[AuthGuard]},
  {path: 'shops/reset-password', component: ShopresetpasswordComponent},
  {path: 'shops/reports-stats-servicerequests', component: SalesreportComponent,canActivate:[AuthGuard]},
  {path: 'shops/reports-stats-servicerequests/business-metrics', component: BusinessmetricsComponent,canActivate:[AuthGuard]},
  {path: 'shops/reports-stats-servicerequests/advance-report', component: AdvancereportComponent,canActivate:[AuthGuard]},
  {path: 'shops/reports-stats-employeelogs', component: EmployeelogsComponent,canActivate:[AuthGuard]},
  {path: 'shops/reports-stats-overdue', component: OverduereportComponent,canActivate:[AuthGuard]},
  {path: 'shops', component: ShoploginComponent},
  {path: 'shops/payment-response', component: PaymentResponseComponent,canActivate:[AuthGuard]},
  {path: 'shops/subscription', component: ShopSubscriptionComponent,canActivate:[AuthGuard]},
  {path: 'shops/forgot_password', component: ShopForgotPasswordComponent},
  {path: 'shops/change-password', component: ChangeshopspasswordComponent,canActivate:[AuthGuard]},
  {path: 'shops/edit-shop', component: EditshopprofileComponent,canActivate:[AuthGuard]},
  {path: 'shops/easy-check-in', component: EasycheckinComponent, canActivate:[AuthGuard]},
  {path: 'shops/edit-profile', component: EdituserprofileComponent, pathMatch: 'full', canActivate:[AuthGuard]},
  {path: 'shops/add-customer', component: AddnewcustomerComponent, canActivate:[AuthGuard]},
  {path: 'shops/list-customers', component: ListshopcustomersComponent,canActivate:[AuthGuard]},
  {path: 'shops/edit-customer/:customer_id', component: EditshopcustomersComponent,canActivate:[AuthGuard]},
  {path: 'shops/upload-contacts', component: ImportContactsComponent,canActivate:[AuthGuard]},
  {path: 'shops/add-car', component: AddcarComponent,canActivate:[AuthGuard]},
  {path: 'shops/list-cars', component: ListcustomercarsComponent,canActivate:[AuthGuard]},
  {path: 'shops/view-car', component: ShopcardetailsComponent,canActivate:[AuthGuard]},
  {path: 'shops/edit-car', component: ShopeditcarComponent,canActivate:[AuthGuard]},
  {path: 'shops/service-history', component: ShopcarservicehistoryComponent,canActivate:[AuthGuard]},
  {path: 'shops/list-service-requests', component: ListshopserviceComponent,canActivate:[AuthGuard]},
  {path: 'shops/list-service-requests/:title', component: ListshopserviceComponent,canActivate:[AuthGuard]},
  {path: 'shops/add-part', component: ShopaddnewpartComponent,canActivate:[AuthGuard]},
  {path: 'shops/list-parts', component: ListshoppartsComponent,canActivate:[AuthGuard]},
  {path: 'shops/list-parts/:notify', component: ListshoppartsComponent,canActivate:[AuthGuard]},
  {path: 'shops/edit-part', component: ShopeditpartComponent,canActivate:[AuthGuard]},
  {path: 'shops/new-generic-job', component: ShopaddgenericjobComponent,canActivate:[AuthGuard]},
  {path: 'shops/list-generic-jobs', component: ListgenericjobsComponent,canActivate:[AuthGuard]},
  {path: 'shops/edit-generic-job', component: ShopeditgenericjobComponent,canActivate:[AuthGuard]},
  {path: 'shops/new-disclaimer', component: ShopadddisclaimerComponent,canActivate:[AuthGuard]},
  {path: 'shops/list-disclaimers', component: ListshopdisclaimersComponent,canActivate:[AuthGuard]},
  {path: 'shops/edit-disclaimer', component: ShopeditdisclaimerComponent,canActivate:[AuthGuard]},
  {path: 'shops/new-computer-code', component: ShopaddcomputercodeComponent,canActivate:[AuthGuard]},
  {path: 'shops/list-computer-code', component: ShoplistcomputercodesComponent,canActivate:[AuthGuard]},
  {path: 'shops/edit-computer-code', component: ShopeditcomputercodeComponent,canActivate:[AuthGuard]},
  {path: 'shops/manage-labor-rates', component: AddeditlabourratesComponent,canActivate:[AuthGuard]},
  {path: 'shops/connections', component: AddeditmailchimpComponent,canActivate:[AuthGuard]},
  {path: 'shops/manage-service-complaints', component: ShoplistservicecomplaintsComponent,canActivate:[AuthGuard]},
  {path: 'shops/view-service-complaint', component: ShopviewservicecomplaintComponent,canActivate:[AuthGuard]},
  {path: 'shops/view-service-complaint/:id', component: ShopviewservicecomplaintComponent,canActivate:[AuthGuard]},
  {path: 'shops/list-shop-inquiries', component: ListshopinquiriesComponent,canActivate:[AuthGuard]},
  {path: 'shops/view-shop-customer-inquiry', component: ShopviewcoustomerinquiryComponent,canActivate:[AuthGuard]},
  {path: 'shops/view-shop-customer-inquiry/:id', component: ShopviewcoustomerinquiryComponent,canActivate:[AuthGuard]},
  {path: 'shops/self-checkin', component: SelfCheckinComponent, canActivate:[AuthGuard]},
  {path: 'shops/dashboard', component: ShopdashboardComponent, canActivate:[AuthGuard]},
  {path: 'shops/service-inspection-report/:id', component: FillRecommendationComponent,canActivate:[AuthGuard],canDeactivate: [CanComponentDeactivate]},
  {path: 'shops/view-service-request/:id', component: ViewServicerequestComponent,canActivate:[AuthGuard],canDeactivate: [CanComponentDeactivate]},
  {path: 'shops/edit-service-request/:id/:cid', component: EditServicerequestComponent,canActivate:[AuthGuard]},
  {path: 'shops/new-service-request/:customer_id/:car_id',component: NewServiceRequestComponent,canActivate:[AuthGuard]},
  {path: 'shops/new-service-request/:customer_id',component: NewServiceRequestComponent,canActivate:[AuthGuard]},
  {path: 'shops/customer-information',component: ShopcustomerinformationComponent,canActivate:[AuthGuard]},
  {path: 'shops/customer-feedback/:request_id', component: ShopCustomerFeedbackComponent,canActivate:[AuthGuard]},
  {path: 'shops/service-history-view/:serviceId', component: ShopServiceHistoryViewComponent,canActivate:[AuthGuard]},
  {path: 'shops/parts-tech', component: AddeditpartstechComponent,canActivate:[AuthGuard]},
  {path: 'shops/add-vendor', component: AddvendorComponent,canActivate:[AuthGuard]},
  {path: 'shops/list-vendors', component: ListshopvendorsComponent,canActivate:[AuthGuard]},
  {path: 'shops/edit-vendor/:vendor_id', component: EditshopvendorComponent,canActivate:[AuthGuard]},
  {path: 'shops/add-resource', component: AddshopresourceComponent,canActivate:[AuthGuard]},
  {path: 'shops/list-resources', component: ListshopresourcesComponent,canActivate:[AuthGuard]},
  {path: 'shops/edit-resource/:resource_id', component: EditshopresourceComponent,canActivate:[AuthGuard]},
  {path: 'shops/payment-success', component: ShopPaymentSuccessComponent,canActivate:[AuthGuard]},
  {path: 'shops/payment-fail', component: ShopPaymentFailureComponent,canActivate:[AuthGuard]},
  {path: 'shops/stock-order', component: StockOrderComponent,canActivate:[AuthGuard]},
  {path: 'shops/stock-order/:id', component: StockOrderComponent,canActivate:[AuthGuard]},
  {path: 'shops/employee/add', component: AddemployeeComponent,canActivate:[AuthGuard]},
  {path: 'shops/employee/edit/:emp_id', component: EditemployeeComponent,canActivate:[AuthGuard]},
  {path: 'shops/employee/list', component: ListemployeeComponent,canActivate:[AuthGuard]},
  {path: 'shops/employee/view/:emp_id', component: ViewempComponent,canActivate:[AuthGuard]},
  {path: 'shops/employee/permissions/:emp_id', component: EmpPermissionComponent,canActivate:[AuthGuard]},
  {path: 'shops/employee/report/:emp_id', component: TechreportComponent,canActivate:[AuthGuard]},
  {path: 'shops/view-customer/:customer_id', component: ViewcustomerdetailsComponent,canActivate:[AuthGuard]},
  {path: 'shops/customer-approval/:customer_id', component: CustomerapprovaldetailsComponent,canActivate:[AuthGuard]},
  {path: 'shops/roles/permissions', component: PermissionComponent,canActivate:[AuthGuard]},
  {path: 'shops/action/forbidden', component: AccessLimitComponent,canActivate:[AuthGuard]},
  {path: 'shops/service-request/logs/:id', component: ServiceRequestLogsComponent,canActivate:[AuthGuard]},
  {path: 'shops/service-request/logs-detail', component: LogviewComponent,canActivate:[AuthGuard]},
  {path: 'shops/calendar', component: CalendarviewComponent,canActivate:[AuthGuard]},
  {path: 'shops/part/logs/:id',component:PartlogComponent,canActivate:[AuthGuard]},
  {path: 'shops/parts/log/detail/:id',component:PartlogdetailComponent,canActivate:[AuthGuard]},
  {path: 'shops/parts/import-export',component:ImportexportComponent,canActivate:[AuthGuard]},
  {path: 'shops/parts/import-list', component: PartuploadlistComponent,canActivate:[AuthGuard]},
  {path: 'shops/parts/import-detail/:id', component: PartuploaddetailComponent,canActivate:[AuthGuard]},
  {path: 'shops/parts/import-part-detail/:id', component: PartimportdetailComponent,canActivate:[AuthGuard]},
  {path: 'shops/notification',component:NotificationComponent,canActivate:[AuthGuard]},
  {path: 'shops/notification/handel',component:NotificationhandelComponent,canActivate:[AuthGuard]},
  {path: 'shops/queue/order',component:QueorderComponent,canActivate:[AuthGuard]},
  {path: 'shops/queue/detail/:id',component:QuedetailComponent,canActivate:[AuthGuard]},
  {path: 'shops/return/queue',component:ReturnquelistComponent,canActivate:[AuthGuard]},
  {path: 'shops/return/view/:id',component:ReturnviewComponent,canActivate:[AuthGuard]},
  {path: 'shops/add-smart-batch',component:AddSmartBatchComponent,canActivate:[AuthGuard]},
  {path: 'shops/cms/:id',component:WAKdetailsComponent,canActivate:[AuthGuard]},
  {path: 'shops/wak/:id',component:WAKdetailsComponent,canActivate:[AuthGuard]},
  {path: 'shops/list-smart-batch',component:ShopSmartBatchListComponent,canActivate:[AuthGuard]},
  {path: 'shops/part-matrix',component:PartsmatrixComponent,canActivate:[AuthGuard]},
  {path: 'shops/edit-smart-batch/:id',component:AddSmartBatchComponent,canActivate:[AuthGuard]},
  {path: 'shops/internalnote/list',component:InternalnotesComponent,canActivate:[AuthGuard]},
  {path: 'shops/internalnote/view/:id',component:ViewnoteComponent,canActivate:[AuthGuard]},
  {path: 'shops/internalnote/edit/:id',component:EditnoteComponent,canActivate:[AuthGuard]},
  {path: 'shops/servicenote/view/:id',component:ViewSrnoteComponent,canActivate:[AuthGuard]},
  {path: 'shops/quick-quotes',component:QqlistComponent,canActivate:[AuthGuard]},
  {path: 'shops/inspection-list',component:InspectionListComponent,canActivate:[AuthGuard]},
  {path: 'shops/notifyredirect', component:VkrouteComponent,canActivate:[AuthGuard]},
  {path: 'shops/discounts', component:ListDiscountsComponent,canActivate:[AuthGuard]},
  {path: 'shops/discounts/add', component:AddDiscountComponent,canActivate:[AuthGuard]},
  {path: 'shops/discounts/edit/:id', component:EditDiscountComponent,canActivate:[AuthGuard]},
  {​​path: 'shops/service-history/:car_id', component:ServiceHistoryComponent,canActivate:[AuthGuard]}​​,
  {​​path: 'shops/nexpart/vendors-list', component:ListVendorsComponent,canActivate:[AuthGuard]}​​,
  {​​path: 'shops/nexpart/add-vendor', component:AddNexpartVendorComponent,canActivate:[AuthGuard]}​​,
  {​​path: 'shops/nexpart/edit-vendor/:id', component:EditNexpartVendorComponent,canActivate:[AuthGuard]}​​,
  {​​path: 'shops/nexpart/callback', component:CallbackComponent,canActivate:[AuthGuard]}​​,
  {path: 'shops/service-calendar', component: ServiceCalendarComponent,canActivate:[AuthGuard]},
  {path: 'shops/attention-service-requests', component: AttentionServiceRequestsComponent,canActivate:[AuthGuard]},
  {path: 'shops-customer-approval/:cid/:sid',component:TempCustomerApprovalComponent,canActivate:[AuthGuard]},
  {path: 'shops/sms-templates', component:ListTemplatesComponent,canActivate:[AuthGuard]},
  {path: 'shops/sms-templates/edit/:id', component:EditTemplateComponent,canActivate:[AuthGuard]},
  {path: 'shops/boards/job-board', component: WorkflowComponent, canActivate:[AuthGuard]},
  {path: 'shops/boards/on-the-lot', component: OnTheLotComponent, canActivate:[AuthGuard]},
  {path: 'shops/boards/my-workload', component:MyWorkLoadComponent, canActivate:[AuthGuard]},
  {path: 'shops/last-service', component:LastServiceVehiclesComponent, canActivate:[AuthGuard]},
  {path: 'shops/mobile-no-ignore-list', component:MobileNoIgnoreListComponent, canActivate:[AuthGuard]},
  {path: 'shops/add-ignore-mobile-no', component:AddMobileNoComponent,canActivate:[AuthGuard]},
  {path: 'shops/edit-ignore-mobile-no/:id', component:EditMobileNoComponent,canActivate:[AuthGuard]},
  {path: 'shops/qbo', component:QboConnectComponent,canActivate:[AuthGuard]},
  {path: 'shops/due-for-service', component: DueForServiceComponent,canActivate:[AuthGuard]},
  {path: 'shops/shop-vehicles-list', component: ShopVehiclesListComponent, canActivate:[AuthGuard]},
  {path: 'shops/dashboard-two', component: ShopDashboardTwoComponent, canActivate:[AuthGuard]},
  {path: 'shops/shop-profile-detail', component:ShopProfileDetailComponent, canActivate:[AuthGuard]},
  {path: 'shops/inventory-stats', component:InventoryStatsComponent, canActivate:[AuthGuard]},
  {path: 'page-not-found', component: PagenotfoundComponent},
  {path: '**', redirectTo: 'page-not-found',},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
