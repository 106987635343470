
<div class="uc-notelist" *ngIf="isInternalNotes && options.request_id" [class.hide]="showList">
  <div id="notepanellist" style="display:none;">
    <div class="panel">
      <div class="panel-heading">
        <h3 class="panel-title">
          Service Notes <em *ngIf="requestID!=''">- #{{requestID}}</em>
          <span type="button" class="btn-icon fa fa-plus-square fa-lg text-red" title="Add Note" (click)="internalNotes();"></span>
        </h3>
        <span type="button" class="btn-icon fa fa-times fa-lg" (click)="closeList()" title="Close" ></span>
      </div>
    </div>
    <div class="uc-list-content">
      <div *ngIf="allList.length == 0" class="no-service-note">
          <p>Service request note data not found. </p>
          <button mat-raised-button color="accent" type="button" title="Add Note" (click)="internalNotes();">
            Add Note
          </button>
      </div>
      <owl-carousel *ngIf="allList" [options]="owlOptions" [items]="allList" [carouselClasses]="['sliding']">
        <div class="item" *ngFor="let row of allList">
            <div class="panel">
              <div class="panel-heading">
                <h3 class="panel-title">
                  <span (click)="viewNote(row)" title="View Note"> {{limitxt(row.name,21)}}</span>
                  <span *ngIf="row.added_by==user.id" class="btn-icon text-red" title="Edit Note" (click)="edit(row)"><i class="fa fa-pencil"></i> </span>
                </h3>
              </div>
              <div class="panel-body" title="View Note" (click)="viewNote(row)">
                <p>{{limitxt(row.description,72)}}</p>
              </div>
            </div>
          </div>
      </owl-carousel>
    </div>
  </div>
  <div class="notelist-collapse" [class.hide]="disableSlide" (click)="listView()">
    <i class="fa fa-chevron-up" [class.fa-chevron-down]="!isSlideOpen" title="Service Notes"></i>
  </div>
</div>