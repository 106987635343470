import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { globalConstants } from "../../../../environments/globalConstants";
import { GlobalService } from "../../../../environments/global.service";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper'
import { globalFunctions } from 'src/environments/globalFunctions';
@Component({
  selector: 'app-cc-authorization',
  templateUrl: './cc-authorization.component.html',
  styleUrls: ['./cc-authorization.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CCAuthorizationComponent implements OnInit,AfterViewInit {

  @ViewChild('stepper') stepper: MatStepper;
  public ccAuthForm         : FormGroup;
  public eulaFormGroup      : FormGroup;
  public shopProfileForm    : FormGroup;
  public currentCustomer    : any = {};
  public shopDetail         : any = {};
  public isEditable         : boolean;
  public isStepOneCompleted : boolean;
  public isShopProfileSetupCompleted : boolean = false
  public isPlanupgrade      : number = 0;
  public subscriptionDetail : any = {};
  public todayDate          : any = new Date();
  public agreementTitle     : any = "CC Authorization";
  public couponCode         : any ;
  public removeCouponCode   : boolean = false;
  public subscriptionPlanList: any;
  public showCrossIcon : any = false;
  public isprofileStep : boolean = false;
  constructor(
    private ref: ChangeDetectorRef,
    public  dialogRef: MatDialogRef<CCAuthorizationComponent>,
    private formBuilder: FormBuilder, 
    public globalService: GlobalService) {
    this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
    this.shopDetail      = JSON.parse(localStorage.getItem("shop-details"));
  }



  ngOnInit() {

    if (this.shopDetail.cc_authorisation == 1 ) {
      this.isStepOneCompleted = true;
      this.agreementTitle = 'End User License Agreement';
    }
     if(this.shopDetail.is_taxes_set == 1){
        this.isShopProfileSetupCompleted = true;
        this.isprofileStep = false;
     }

    if('subscription' in this.shopDetail){
      this.isPlanupgrade      = 1;
      this.subscriptionDetail = this.shopDetail.subscription;
    }  
   
    this.eulaFormGroup = this.formBuilder.group({
      terms         : [false, Validators.required],
      agree         : ['', Validators.required],
      user_name     : ['', Validators.required],
      user_type     : [this.currentCustomer.user_type],
      user_id       : [this.currentCustomer.id],
      plan_upgrade  : [this.isPlanupgrade],
    });

    this.eulaFormGroup.controls['terms'].valueChanges.subscribe(value => {
        if(value){
          this.eulaFormGroup.controls['agree'].setValue(1);
        }else{
          this.eulaFormGroup.controls['agree'].setValue('');
        }
    });

    this.ccAuthForm = this.formBuilder.group({
      cc_name: ['', [Validators.required,Validators.pattern(globalConstants.NAME_REGEX)]],
      cc_number: ['', [Validators.required,Validators.pattern(globalConstants.DIGIT_ONLY_REGEX)]],
      cvv: ['', [Validators.required,Validators.pattern(globalConstants.DIGIT_ONLY_REGEX)]],
      exp_month: ['', [Validators.required, Validators.pattern(globalConstants.DIGIT_ONLY_REGEX)]],
      exp_year: ['', [Validators.required, Validators.pattern(globalConstants.DIGIT_ONLY_REGEX)]],
      first_name: [this.currentCustomer.first_name],
      last_name: [this.currentCustomer.last_name],
      email: [this.currentCustomer.email],
      address1: [this.currentCustomer.address1],
      city: [this.currentCustomer.city],
      state: [this.currentCustomer.state],
      country: [this.currentCustomer.country],
      zip: [this.currentCustomer.zip],
      cell_phone1: [this.currentCustomer.cell_phone1],
      user_type: [this.currentCustomer.user_type],
      user_id: [this.currentCustomer.id],
      shop_id: [this.currentCustomer.shop_id],
      mail_chimp: [0],
      opr: 1
    });
    this.shopProfileForm = this.formBuilder.group({
      tax:['']
    })

    this.getAllSubscriptionPlans();
  }

  onUpdateCCInfo() {
    if (this.ccAuthForm.valid) {
      var data = new FormData();
      for (let i in this.ccAuthForm.value) {
        data.append(i, this.ccAuthForm.value[i]);
      }
      let planSummary = this.globalService.decodedWpiInfo();
      if(planSummary !== null){
        let selectedPlanDetails = this.subscriptionPlanList.find((plan) => plan.bt_plan_id == planSummary.planId);
        data.append('plan_id',selectedPlanDetails.bt_plan_id);
        data.append('subscription_id',selectedPlanDetails.id);
        data.append('subscription_type',selectedPlanDetails.subscription_type);
      }
      if(typeof(this.couponCode) !== 'undefined' && this.couponCode !== ''){
        data.append('coupon_code',this.couponCode);
      }
       this.globalService.formData(globalConstants.API_ADD_BT_SUBSCRIPTION_URL, data).subscribe(data => {
        if (data.result == "1") {
          this.globalService.snackbar('success', data.message);
          data["data"]["timeStamp"] = JSON.parse(localStorage.getItem("loggedInUser"))["timeStamp"]
          localStorage.setItem("loggedInUser",JSON.stringify(data["data"]));
          let params = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&shop_id=" + this.globalService.getCurrentUser()["shop_id"];
          this.globalService.callAPI(globalConstants.API_GET_SHOP_DETAILS_ON_DASHBOARD, params).subscribe((res) => {
            if(res.result == "1"){
              this.shopDetail         = res.data;
              this.subscriptionDetail = this.shopDetail.subscription;
              this.globalService.setShopDetails(this.shopDetail);
              this.globalService.removeWpiInfo();
              this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
              this.agreementTitle = 'End User License Agreement';
              this.isprofileStep = false;
            }else{
              this.globalService.snackbar('error', data.message);
            }
          });          
        } else {
          this.globalService.snackbar('error', data.message);
        }
      });
    } else {
      this.globalService.validateAllFormFields(this.ccAuthForm);
    }
  }

  OnEULAAgggrement() {
    if (this.eulaFormGroup.valid) {
      var data = new FormData();
      for (let i in this.eulaFormGroup.value) {
        data.append(i, this.eulaFormGroup.value[i]);
      }
      this.globalService.formData(globalConstants.API_SHOP_SAVE_EULA_AGREEMENT_URL, data).subscribe(data => {
        if (data.result == "1") {
          this.globalService.snackbar('success', data.message);
          this.agreementTitle = 'Profile Completion';
          // Check the length of steps and set isprofileStep accordingly
          if (this.stepper.steps.length === 2) {
            this.isprofileStep = false;
          } else {
            this.isprofileStep = true;
          }
          this.globalService.removeWpiInfo();
          this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
          this.showCrossIcon = true;
        } else {
          this.globalService.snackbar('error', data.message);
        }
      });
    } else {
      this.globalService.validateAllFormFields(this.eulaFormGroup);
    }
  }

  close() {
    return this.dialogRef.close();
  }

  ngAfterViewInit() {
    
  }

  refresh(): void {
    this.globalService.removeWpiInfo();
    globalFunctions.controlDashboardNavigation();
  }
   // Function to toggle coupon input visibility
   toggleCouponInput() {
    this.removeCouponCode = true;
  }
  skipShopProfileStepInEULA(){
    this.agreementTitle = '';
    this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
    this.isprofileStep = false;
  }

  handleCardTypeImg(){
    let cardEle:any = <HTMLElement>document.getElementById('cardIcon');
    var cardImage = this.globalService.getCardTypeImage(this.ccAuthForm.value.cc_number);
    cardEle.innerHTML= cardImage;
  }
  handleRemoveCoupon(){
     this.removeCouponCode = false;
     this.couponCode = '';
  }

  handleCloseCcAuthDialog(){
    this.dialogRef.close();
    this.refresh();
  }

  getAllSubscriptionPlans() {
    let payload = {
      user_type: "User",
      opr: 1
    }
    this.globalService.callAPI(globalConstants.API_GET_SHOPS_SUBSCRIPTION_PLANS, payload).subscribe((response: any) => {
      if (response.result == 1) {
        this.subscriptionPlanList = response.data;
      }
    })
  }

  handleNxtBillingDtFormat(subscriptionInfo:any){
    return subscriptionInfo.nextBillingDate !=='0'? this.globalService.getFullFormateDate(subscriptionInfo.nextBillingDate, "GD", ""): 'N/A';
   }

   onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57)
        return false;
    return true;
   }

   validateFieldOnPaste(controlName: string) {
    const control = this.ccAuthForm.get(controlName);
    control.markAsTouched();
  }
}


