import {Component, OnInit, ViewChild, Input} from '@angular/core';
import {AbstractControl, FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
import {Http, Headers, Response,RequestOptions} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../../environments/globalFunctions"
import {globalConstants} from "../../../../environments/globalConstants"
import {GlobalService} from "../../../../environments/global.service"
import {lang} from "../../../../environments/lang-en"
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';
import {trigger,state,style,animate,transition} from '@angular/animations';

import {AppComponent} from "../../../app.component"
const EMAIL_REGEX = globalConstants.EMAIL_REGEX;

@Component({
  selector: 'app-edit-service-facility',
  templateUrl: './edit-service-facility.component.html',
  styleUrls: ['./edit-service-facility.component.scss']
})
export class EditServiceFacilityComponent implements OnInit {
    public currentCustomer;
    public shopList: any;
    public submitted: boolean = false;
    public addServiceFacility: FormGroup;
    public states:any = [];
    public cities:any = [];
    public countries: any = [];
    public defaultCountryVaue;
    public defaultStateVaue;
    public splitCountryValues;
    public customerCountryID;
    public customerCountryName;
    public splitStateValues;
    public customerStateID;
    public customerStateName;
    public currentServiceFacility;
    public serviceFacilityID;
    public currentShopID;
    public defaultCompanyName;
    public defaultEmail;
    public serviceFacilityCheck:boolean;
    public phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    
    public vcountries={};
    public vstates=[''];
    public vcities=[''];
    public disableCountries;
    public disableStates;
    public disableCities;
    public stateBlank;
    public cityBlank;
    public newCountryName='';
    public newStateName;
    public newCityName;
    public selectedCountry;
    public selectedState;
    public selectedCity;
    public zipLabel = 'Zip Code';
    constructor(private appComponent: AppComponent, private formBuilder: FormBuilder, private route: ActivatedRoute, public titleService: Title, private http: Http, private router: Router,public globalService: GlobalService) {
      this.globalService.setMetaData("CUSTOMER","EDIT_SERVICE_FACILITY")

        if (localStorage.getItem("loggedInCustomer")) {
            this.currentCustomer = JSON.parse(localStorage.getItem("loggedInCustomer"));
            this.serviceFacilityID = localStorage.getItem("serviceFacilityID");
            this.globalService.callAPI(globalConstants.API_GET_COUNTRIES_LIST,"opr=1").subscribe((data:any) => {
                this.countries = data.data;
                /* FOR V2 SELECT */
                var clist=[];
                for(var i in this.countries){
                    this.countries[i].id=this.countries[i].country_id;
                    this.countries[i].text=this.countries[i].country_name;                                        
                    clist.push(this.countries[i]);
                    this.vcountries[this.countries[i].id]=this.countries[i].country_name;
                }
                this.countries=clist;
                /* END FOR V2 SELECT */
            })
            this.initializeForBuilders();
        } else {
            this.router.navigate(["/customer"]);
        }
    }

    initializeForBuilders() {
        this.addServiceFacility = this.formBuilder.group({
            facility_name: ['', [Validators.required]],
            facility_email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
            facility_address: [''],
            facility_country: [''],
            facility_country_frm: [''],
            facility_state: [''],
            facility_state_frm: [''],
            facility_city: [''],
            facility_zip: [''],
            facility_phone: ['', [Validators.required]],
            facility_weburl: ['', [Validators.pattern("https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,}")]],
            facility_isdefault: [''],
            customer_id: [this.currentCustomer.id],
            facility_id: [this.serviceFacilityID],
        });

        /**
     * update Zip codee validation
     */
        this.addServiceFacility.get("facility_country").valueChanges.subscribe(country_name => {  
        if(country_name && Array.isArray(this.countries)){                 
                let zRegex = this.countries.find(res=>(res.country_id==country_name || res.country_name==country_name));
                if(zRegex && 'zipcode_regex' in zRegex){
                    this.zipLabel = zRegex.zipcode_label;
                    this.addServiceFacility.controls.facility_zip.setValidators([Validators.required, Validators.pattern(zRegex.zipcode_regex)]);
                    this.addServiceFacility.controls.facility_zip.updateValueAndValidity();
                }          
            }                
        });

        this.addServiceFacility.valueChanges.subscribe(selectedValue  => {
            this.checkFormValid();
        });
    }

    ngOnInit() {
        setTimeout(() => {
            let isFleetCustomer = this.globalService.isFleetCustomer()
            if (isFleetCustomer) {
                this.router.navigate(["/customer/dashboard"]);
            }
        }, globalConstants.RELOAD_WAIT);
        this.getCurrentServiceFacility();
    }
    
    /* FOR COUNTRY/STATES/CITY SELECT */
    
    getSelectedOptionCountry(opt) {
        this.disableStates = true;
        this.getStates({value:opt+'|'+this.vcountries[opt]})
        this.newCountryName = this.vcountries[opt];
        this.addServiceFacility.controls.facility_country.setValue(this.newCountryName);
        this.addServiceFacility.controls.facility_state.setValue('');
        this.addServiceFacility.controls.facility_city.setValue('');
        this.disableCities = true;
    }
    
    getSelectedOptionState(opt) {
        this.disableCities = true;
        this.getCities({value:opt+'|'+this.vstates[opt]})
        this.newStateName = this.vstates[opt];
        this.addServiceFacility.controls.facility_state.setValue(this.newStateName);
        this.addServiceFacility.controls.facility_city.setValue('');
    }
    
    getSelectedOptionCity(opt) {
        this.newCityName = opt;
        this.addServiceFacility.controls.facility_city.setValue(opt);
    }
    
    /* GET CURRENT SERVICE FACILITY */

    getCurrentServiceFacility() {
        let oprs = "customer_id="+this.currentCustomer.id+"&facility_id="+this.serviceFacilityID;
        // console.log("Content", shopContent)
        this.globalService.callAPI(globalConstants.API_GET_SERVICE_FACILITY_DETAIL_URL,oprs ).subscribe((data:any) => {
            this.currentServiceFacility = data.data;
            // console.log('Details', this.currentServiceFacility);
            this.addServiceFacility.controls.facility_name.setValue(this.currentServiceFacility.name);
            this.addServiceFacility.controls.facility_email.setValue(this.currentServiceFacility.email);
            this.addServiceFacility.controls.facility_address.setValue(this.currentServiceFacility.address);
            //this.addServiceFacility.controls.facility_country_frm.setValue(this.currentServiceFacility.country);
            //this.addServiceFacility.controls.facility_state_frm.setValue(this.currentServiceFacility.state);
            this.addServiceFacility.controls.facility_city.setValue(this.currentServiceFacility.city);
            this.addServiceFacility.controls.facility_zip.setValue(this.currentServiceFacility.zip);
            this.addServiceFacility.controls.facility_phone.setValue(this.currentServiceFacility.phone_number);
            this.addServiceFacility.controls.facility_weburl.setValue(this.currentServiceFacility.website_url);
            this.currentShopID = this.currentServiceFacility.shop_id;
            this.defaultCompanyName = this.currentServiceFacility.company_name;
            this.defaultEmail = this.currentServiceFacility.email;
            // console.log("kkk", this.currentServiceFacility.city)
            this.selectedCountry = this.currentServiceFacility.country;
            this.selectedState = this.currentServiceFacility.state;
            this.selectedCity = this.currentServiceFacility.city;
            
            if (this.currentServiceFacility.is_default == 1) {
                    this.serviceFacilityCheck = true;
                    this.addServiceFacility.controls.facility_isdefault.setValue(1);
            } else {
                this.serviceFacilityCheck = false;
                this.addServiceFacility.controls.facility_isdefault.setValue(0);
            }
            if(this.currentServiceFacility.shop_id == 0 || this.currentServiceFacility.shop_id == "0") {
                this.addServiceFacility.controls.facility_email.enable();
                this.addServiceFacility.controls.facility_address.enable();
                this.addServiceFacility.controls.facility_country_frm.enable();
                this.addServiceFacility.controls.facility_state_frm.enable();
                this.addServiceFacility.controls.facility_city.enable();
                this.addServiceFacility.controls.facility_zip.enable();
                this.addServiceFacility.controls.facility_phone.enable();
                this.addServiceFacility.controls.facility_weburl.enable();
                this.disableStates = false;
                this.disableCities = false;
                console.log("1");
            } else {
                this.addServiceFacility.controls.facility_email.disable();
                this.addServiceFacility.controls.facility_address.disable();
                this.addServiceFacility.controls.facility_country_frm.disable();
                this.addServiceFacility.controls.facility_state_frm.disable();
                this.addServiceFacility.controls.facility_city.disable();
                this.addServiceFacility.controls.facility_zip.disable();
                this.addServiceFacility.controls.facility_phone.disable();
                this.addServiceFacility.controls.facility_weburl.disable();
                this.disableStates = true;
                this.disableCities = true;
            }

            /* SET COUNTRY ID TO GET STATES */
            if(this.currentServiceFacility.country != "") {
                //this.defaultCountryVaue = this.currentServiceFacility.country_id+"|"+this.currentServiceFacility.country;
                this.defaultCountryVaue = this.currentServiceFacility.country_id;
            } else {
                this.defaultCountryVaue = "USA";
            }
            this.addServiceFacility.controls.facility_country.setValue(this.currentServiceFacility.country);
            var event = {
                value: this.defaultCountryVaue
            }

            this.getStates(event);

            /* SET STATE ID TO GET CITIES */
            if(this.currentServiceFacility.state != "") {
//                this.defaultStateVaue = this.currentServiceFacility.state_id+"|"+this.currentServiceFacility.state;
                  this.defaultStateVaue = this.currentServiceFacility.state_id;
            } else {
                this.defaultStateVaue = "";
            }
            this.addServiceFacility.controls.facility_state.setValue(this.currentServiceFacility.state);

            let cityName = {
                value: this.defaultStateVaue
            }
            this.getCities(cityName);
            this.addServiceFacility.controls.facility_city.setValue(this.currentServiceFacility.city);

            /* END */
        });
    }


    setDefaultSevice(event) {
        if (event.checked) {
            this.addServiceFacility.controls.facility_isdefault.setValue(1);
        } else {
            this.addServiceFacility.controls.facility_isdefault.setValue(0);
        }
    }

    gotoListPage() {
        this.router.navigate(['/customer/list-service-facilities'])
    }

    onlyNumericKey(event) {
        return globalFunctions.onlyDecimalNumberKey(event);
    }

    checkFormValid(){
        if (this.addServiceFacility.valid) {
            this.submitted = false;
        }
    }

    phoneValidation(control,key){
        this.globalService.preferedServicesPhoneValidity(control,key);
        this.checkFormValid();
    }

    resetShopName() {
        this.addServiceFacility.controls.facility_name.setValue(this.defaultCompanyName);
    }


    editServiceFacility() {
        if(this.addServiceFacility.valid) {
            Object.keys(this.addServiceFacility.controls).map((controlName) => {
                this.addServiceFacility.get(controlName).enable();
            });

            /* BLANK COUNTRY CHECK */
            /*if(this.addServiceFacility.value.facility_country_frm == undefined || this.addServiceFacility.value.facility_country_frm == 'undefined') {
                this.addServiceFacility.controls.facility_country.setValue('');
                this.addServiceFacility.controls.facility_state.setValue('');
                this.addServiceFacility.controls.facility_city.setValue('');
            } else {
                this.addServiceFacility.controls.facility_country.setValue(this.customerCountryName);
            }
            if(this.customerStateName == "" || this.customerStateName == undefined || this.customerStateName == 'undefined' || this.addServiceFacility.controls.facility_state_frm == null || this.addServiceFacility.value.facility_state_frm == "") {
                this.addServiceFacility.controls.facility_state.setValue('');
                this.addServiceFacility.controls.facility_city.setValue('');
            } else {
                this.addServiceFacility.controls.facility_state.setValue(this.customerStateName);
            }

            if(this.addServiceFacility.value.facility_city == 'undefined' || this.addServiceFacility.value.facility_city == undefined || this.addServiceFacility.value.facility_city == "null" || this.addServiceFacility.value.facility_city == "" || this.addServiceFacility.controls.facility_city == null) {
                this.addServiceFacility.controls.facility_city.setValue('');
            }*/

            /* END */

            /*this.addServiceFacility.controls.facility_country.setValue(this.customerCountryName);
            this.addServiceFacility.controls.facility_state.setValue(this.customerStateName);*/



            var data = new FormData();
            for(let i in this.addServiceFacility.value){
               data.append(i, this.addServiceFacility.value[i]);
            }

            //var stringifiedFormData = globalFunctions.stringifyFormData(this.addServiceFacility.value, null);
            
            this.globalService.formData(globalConstants.API_EDIT_SERVICE_FACILITY_URL,data).subscribe(data=> {
                var message = data.message;
                if (data.result == "1") {
                    this.router.navigate(["/customer/list-service-facilities"]);
                    this.addServiceFacility.controls.facility_email.disable()
                    this.globalService.snackbar('success', message);
                } else {
                    if(this.currentServiceFacility.shop_id != 0 || this.currentServiceFacility.shop_id != "0") {
                        this.addServiceFacility.controls.facility_email.disable();
                        this.addServiceFacility.controls.facility_address.disable();
                        this.addServiceFacility.controls.facility_country_frm.disable();
                        this.addServiceFacility.controls.facility_state_frm.disable();
                        this.addServiceFacility.controls.facility_city.disable();
                        this.addServiceFacility.controls.facility_zip.disable();
                        this.addServiceFacility.controls.facility_phone.disable();
                        this.addServiceFacility.controls.facility_weburl.disable();
                    }
                    this.globalService.snackbar('error', message);
                }
              });
        } else {
            this.submitted = true;
            this.mandatoryMsg();
        }
    }

    mandatoryMsg() {
        this.globalService.snackbar('error', "Please fill all the mandatory fields.");
    }

    /* GET STATES */
    getStates(event) {
        // console.log("1", event)
        // console.log("2", event.value)
        if (event.value == undefined || event.value == "") {
            this.addServiceFacility.controls.facility_state_frm.disable();
            this.addServiceFacility.controls.facility_city.disable();
            this.addServiceFacility.controls.facility_state_frm.reset();
            this.addServiceFacility.controls.facility_city.reset();
            this.disableStates = true;
            this.disableCities = true;
            this.vstates=[];
            this.vcities=[];
        } else {
            this.vstates=[];
            this.vcities=[];
            this.disableCities = true;
            
            if(this.currentShopID == 0 || this.currentShopID == '0') {
                this.disableCountries = false;
                this.disableStates = false;
                this.disableCities = false;
            } else {
                this.disableCountries = true;
                this.disableStates = true;
                this.disableCities = true;
            }
            /* Split country values */
            // console.log("Country Value", this.addServiceFacility.value.facility_country_frm)
            //if(this.addServiceFacility.controls.facility_country_frm != undefined ||  this.addServiceFacility.value.facility_country_frm != "") {
                // console.log()
                this.splitCountryValues  = event.value.split("|");
                this.customerCountryID   = this.splitCountryValues[0];
                if(this.customerCountryID != "") {
                    this.customerCountryName = this.splitCountryValues[1];
                } else {
                    this.customerCountryName = "";
                }
            //}

            /* END */
            this.states = [];
            this.cities = [];
            
            this.globalService.callAPI(globalConstants.API_GET_STATES_URL, "opr=1&country_id=" + this.customerCountryID ).subscribe((data:any) => {
                var responseData = data;
                if (responseData.result == "1") {
                    //this.disableStates = false;
                    var slist=[];
                    if (responseData.data.length == undefined) {
                        this.states[0] = responseData.data;
                        /* FOR V2 SELECT */
                        this.states.id=this.states.state_id;
                        this.states.text=this.states.state_name;          
                        slist.push(this.states);
                        this.vstates[this.states.state_id]=this.states.state_name;
                        this.states=slist;
                        /* END FOR V2 SELECT */
                    } else {
                        this.states = responseData.data;
                        /* FOR V2 SELECT */
                        for(var i in this.states){
                            this.states[i].id=this.states[i].state_id;
                            this.states[i].text=this.states[i].state_name;
                            slist.push(this.states[i]);
                            this.vstates[this.states[i].state_id]=(this.states[i].state_name);
                        }
                        this.states=slist;
                        /* END FOR V2 SELECT */
                    }
                } else {
                    //
                    this.states = [];
                    this.cities = [];
                    this.addServiceFacility.controls.facility_city.disable();
                     /* FOR V2 SELECT */
                    this.vstates=[];
                    this.disableStates = false;
                    this.vcities=[];
                    this.disableCities = true;
                    /* END FOR V2 SELECT */
                }
            });
        }
    }

   /* GET CITIES */
    getCities(event) {
        // console.log("3", event)
        // console.log("4", event.value)
        if (event.value == undefined || event.value == "" || event.value == null) {
            this.addServiceFacility.controls.facility_city.disable();
            this.cities = [];
            this.disableCities = true;
            this.vcities=[];
        } else {
            if(this.currentShopID == 0 || this.currentShopID == '0') {
                this.addServiceFacility.controls.facility_city.enable();
                this.disableCities = false;
                console.log("5");
            } else {
                this.addServiceFacility.controls.facility_city.disable();
                this.disableCities = true;
                console.log("6");
            }
            /* Split States values */
            this.splitStateValues  = event.value.split("|");
            this.customerStateID   = this.splitStateValues[0];
            if(this.customerStateID != "") {
                this.customerStateName = this.splitStateValues[1];
            } else {
                this.customerStateName = "";
            }
            /* END */
            this.globalService.callAPI(globalConstants.API_GET_CITIES_URL, "opr=1&state_id=" + this.customerStateID ).subscribe((data:any) => {
                var responseData = data;
                if (responseData.result == "1") {
                    //this.disableCities = false;
                    var clist=[];
                    if (responseData.data.length == undefined || responseData.data.length == 'undefined') {
                        this.cities[0] = responseData.data;
                         /* FOR V2 SELECT */
                        this.cities[0].id=this.cities[0].city_name;
                        this.cities[0].text=this.cities[0].city_name;          
                        clist.push(this.cities[0]);
                        this.vcities=clist;
                        /* END FOR V2 SELECT */
                    } else {
                        this.cities = responseData.data;
                        /* FOR V2 SELECT */
                        for(var i in this.cities){
                            this.cities[i].id=this.cities[i].city_name;
                            this.cities[i].text=this.cities[i].city_name;          
                            clist.push(this.cities[i]);
                        }
                        this.vcities=clist;
                        /* END FOR V2 SELECT */
                    }
                } else {
                    this.cities = [];
                    this.addServiceFacility.controls.facility_city.disable();
                    /* FOR V2 SELECT */
                    this.disableCities = false;
                    this.vcities=[];
                    /* END FOR V2 SELECT */
                }
            })

        }
    }

    /* Get Country ID from Name */
    /*
     */
}
