import { Component, OnInit, Inject, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../../environments/global.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShopcarvinscanComponent } from '../shopcar/shopcarvinscan/shopcarvinscan.component';
import { ShopcarLicensePlateComponent } from '../shopcar/shopcarlicenseplate/shopcarlicenseplate.component';
import { globalConstants } from '../../../environments/globalConstants';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AddVehicleForApptComponent } from './add-vehicle-appt/add-vehicle-appt.component';
import { AcquirevehicleApptComponent } from './acquirevehicle-appt/acquirevehicleappt.component';
import { EditcustomerpopupComponent } from '../shopcustomers/editcustomerpopup/editcustomerpopup.component';
import { SearchothercustomerComponent } from '../shopcustomers/searchothercustomer/searchothercustomer.component';
import { SearchcustomerComponent } from '../shopcustomers/searchcustomer/searchcustomer.component';
import { HelperService } from 'src/app/helper.service';

const EMAIL_REGEX = globalConstants.EMAIL_REGEX;
@Component({
  selector: 'app-quickappt',
  templateUrl: './quickappt.component.html',
  styleUrls: ['./quickappt.component.scss']
})
export class QuickapptComponent implements OnInit {

  public user:any=null
  public customer:any=null
  public vechile:any=[]
  public carObj={"vin":"vin","engine_size":"engine_size","car_name":"body","make":"make","model":"model","sub_model":"sub_model","year":"year","wheel_base":"wheel_base","tire_size":"tire_size","driveline":"driveline"};
  public carLObj={"vin":"vin","engine_size":"engine_size","car_name":"body","make":"make","model":"model","sub_model":"sub_model","year":"year","license_plate":"license_plate","wheel_base":"wheel_base","tire_size":"tire_size","driveline":"driveline","State":""}
  public imgPath = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_PICTURES + globalConstants.IMG_THUMB;
  public custSearch:any={email:"",mobile:"",user_id:""}
  public newCust:any={email:"",mobile:"",fname:"",lname:""}
  public mobileMask =  [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public addVehicle=false
  public searchOrNew=true
  public searchOrNewFinish=true
  public newCustomer: FormGroup;  
  public seletedCar:any=null
  public selectCust:any=null;
  public openChatWindow=false
  public isShowVehicleList=false
  public srchForm:FormGroup
  public submit=false
  public param:any={}
  public recommData:any={}
  public isShopDataAssigned=false
  public shopDataNote = 'Your account activation is under process and we are adding all the selected services to your account. It may take 1-2 hours. Once the services are assigned, you will get a notification.';
  public getRandomCustomerEmail;
  public getEmailCheck;
  public getMobileCheck;
  public checkUserEmail: boolean = false;
  public checkUserMobile: boolean = false;
  public isAutoGeneratedEmail: boolean;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public gs:GlobalService, public dialogRef: MatDialogRef<QuickapptComponent>, public dialog: MatDialog, private form: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public popupData: any, private router: Router,public helperService : HelperService) {
    this.gs.diaLogObj(this.dialogRef);
    this.user=this.gs.getCurrentUser()
    this.param = data
    // this.isShopDataAssigned=this.gs.isDefaultDataAssigned();
    this.isShopDataAssigned = true; //To allow shop to create appointment and QQ with out waiting for master data assignment
  }
  
  ngOnInit() {
    
    this.custSearch.user_id=this.user.id
    this.newCustomer = this.form.group({
      first_name: ['',[Validators.required]],
      last_name: ['',[Validators.required]],
      mobile: ['',[Validators.required,Validators.minLength(10),this.gs.mobileNumberValidation]],
      email: ['',[Validators.pattern(EMAIL_REGEX)]],
      mapping_status: ['1'],
      user_id:this.user.id
    })
    
    this.srchForm=this.form.group({
      last_name: [''],
      mobile: [''],
      plate: [''],
      contact: [''],
      user_id:this.user.id
    })
    // if(this.param && typeof this.param.car_id!='undefined'){
    //   let userPer=this.gs.getAcl('quick_quote','edits');
    //   if(!userPer){
    //     this.gs.snackbar("error", "You do not have access rights for this resource.");
    //     this.dialogRef.close()
    //   }else{
    //     // this.getQQDetail(this.param)
    //   }
    // }
    // console.log(this.param);
  }
  /** search customer */
  search() {
    let data=this.gs.formsData(this.srchForm.value)
    if (this.srchForm.valid && (this.srchForm.value.mobile || this.srchForm.value.last_name || this.srchForm.value.plate || this.srchForm.value.contact)) {
      this.submit=true
      this.gs.formData(globalConstants.API_QUICKAPPT_CUSTOMER_SEARCH, data).subscribe((r) => {
        this.submit=false
        if (r.result == 1) {
          this.customer = r.customer;        
          this.formateVehicle()
          this.searchOrNew = false
          this.searchOrNewFinish = false
          this.changeCar()
          if(Array.isArray(this.customer) && this.customer.length ==1){
            this.getVehicleByCustomer(this.customer[0],this.srchForm.value.plate);
          }
        } else {
          this.gs.snackbar("error", r.message);
        }
      }, (error) => { this.submit=false })
    }else if(!this.srchForm.valid){
      this.gs.snackbar("error", 'Please enter valid Last Name or Mobile or Plate or Contact Name');
    }else{
      this.gs.snackbar("error", 'Please enter Last Name or Mobile or Plate or Contact Name');
    }
  }
  

  /* Get Customer Vehicles */
  getVehicleByCustomer(cust,licensePlate=null){
    var custInfo = "user_id=" + this.user["id"] + "&user_type=" + this.user["user_type"] + "&customerId=" + cust.id;
    this.gs.callAPI(globalConstants.API_QUICKAPPT_CUSTOMER_SEARCH, custInfo).subscribe((r:any) => {
      this.selectedCustomer(cust);
      if (r.result == 1) {
        this.customer = r.customer ;
        this.vechile  = r.vehicles ;       
        if (Array.isArray(this.vechile) && this.vechile.length && licensePlate) {     
          this.vechile.forEach((r)=>{
            r.license_plate=(r.license_plate ? r.license_plate.toLowerCase():r.license_plate)
            if(r.license_plate==licensePlate.toLowerCase()){
              this.changeCar()
              this.selectVechile(r)
            }
          })
        }
        this.formateVehicle()
        this.searchOrNew = false
        this.searchOrNewFinish = false
      } else {
        this.gs.snackbar("error", r.message);
      }
    }, (error) => { this.submit=false })
  }

  /** add new customer */
  addNewCustomer(){
    if(this.newCustomer.valid){
      let data=this.gs.formsData(this.newCustomer.value)
      this.gs.formData(globalConstants.API_QUICKAPPT_ADD_CUSTOMER,data).subscribe((r)=>{
        if(r.result==1){
          this.customer=(typeof r.data !='undefined' ? r.data : r.customer);
          this.selectedCustomer(this.customer[0]);
          this.searchOrNew = false;
          if(typeof r.vehicles!='undefined'){
            this.vechile = r.vehicles
            this.formateVehicle()
          }
          this.gs.snackbar("success", r.message);
          this.searchOrNewFinish=false
          this.openChatWindow=false
        }else{
          this.gs.snackbar("error", r.message);
        }
      },(error)=>{ })
    }else{
      this.gs.snackbar("error", globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
  }

  resetSearch(){
   this.vechile     = [];
   this.customer    = []
   this.searchOrNew = true
   this.searchOrNewFinish=true
   this.srchForm.controls.mobile.setValue("");
   this.srchForm.controls.last_name.setValue("");
   this.srchForm.controls.plate.setValue("");
   this.srchForm.controls.contact.setValue("");
   this.submit=false;
   this.selectCust=null;
  }

  /** add new Car */
  addNewCar(){
    this.openAddVehicle()
  }
  
  vinScan() { 
    let dialogRef:any = this.dialog.open(ShopcarvinscanComponent, {
      panelClass: ['car-dialog-form'],
      width: "800px",
      data: { qq:true ,quickapt: true,customer_id:((Array.isArray(this.customer) && this.customer.length) ? this.customer[0].id:"")}
    });
    let c=JSON.stringify(this.carObj)
    let obj:any=JSON.parse(c);
    dialogRef.afterClosed().subscribe(r => {
      if(r==1){
        if(dialogRef.customer && typeof dialogRef.customer.id!="undefined"){
          this.customer = [];
          this.customer.push(dialogRef.customer);
          this.selectedCustomer(dialogRef.customer);
          this.searchOrNew = false
          this.searchOrNewFinish = false;
        }
        // console.log(dialogRef.car)
        if(Array.isArray(dialogRef.vehicles) && dialogRef.vehicles.length){
          this.vechile=dialogRef.vehicles
          this.formateVehicle()
          this.vechile.forEach((r)=>{
            if(r.vin==dialogRef.vin){
              this.changeCar()
              this.selectVechile(r)
            }
          })
        } else {
          obj.vin = dialogRef.vin
          if(dialogRef.car){
            Object.entries(dialogRef.car).forEach((o: any) => {
              for (let i in obj) {
                if (o[0] == obj[i]) {
                  obj[i] = o[1]
                }
              }
            })
            let n = (obj.make ? obj.make + " " : "") + obj.model;
            obj.car_name = (n ? n : 'N/A')
            this.addCarFromScan(obj)
          }
          // this.vechile.push(obj)         
        }
      }else if(r==2 && typeof dialogRef.acquire!="undefined" && dialogRef.acquire){
        this.openAcquiringForm(1,dialogRef.vin,'','')
      } else if(r == 3){
        this.dialogRef.close()
      }
    })
  }

  /** plate scan */
  plateScan() {
    console.log('plateScanC',this.customer)
    console.log('plateScanC',this.selectCust);
    let dialogRef: any = this.dialog.open(ShopcarLicensePlateComponent, {
      panelClass: ['car-dialog-form', 'ticket-dialog'],
      width: "800px",
      data: { qq: true,quickapt: true, customer_id: ((Array.isArray(this.customer) && this.customer.length) ? this.customer[0].id : "") }
    });
    let c = JSON.stringify(this.carLObj)
    let obj: any = JSON.parse(c);
    dialogRef.afterClosed().subscribe(r => {
      if (r == 1) {
        console.log('dialogRef',dialogRef.customer);
        if (dialogRef.customer && typeof dialogRef.customer.id!="undefined") {
          this.customer          = [];
          this.customer.push(dialogRef.customer);
          this.selectedCustomer(dialogRef.customer);
          this.searchOrNewFinish = false;
          this.searchOrNew = false;
          console.log('customer',this.customer);
        }
        if (Array.isArray(dialogRef.vehicles) && dialogRef.vehicles.length) {
          this.vechile = dialogRef.vehicles
          this.formateVehicle()
          dialogRef.plate_no=(dialogRef.plate_no ? dialogRef.plate_no.toLowerCase():dialogRef.plate_no)
          this.vechile.forEach((r)=>{
            r.license_plate=(r.license_plate ? r.license_plate.toLowerCase():r.license_plate)
            if(r.license_plate==dialogRef.plate_no){
              this.changeCar()
              this.selectVechile(r)
            }
          })
        } else {
          Object.entries(dialogRef.car).forEach(([key, val]) => {
            for (let i in obj) {
              if (key == obj[i]) {
                obj[i] = val
              }
            }
          })       

          if(dialogRef.car && 'engine_size' in dialogRef.car){
            obj.sub_model = dialogRef.car.engine_size;
          }

          if(dialogRef.car && 'state_name' in dialogRef.car){
            obj.State = dialogRef.car.state_name;
          }

          let n = (obj.make ? obj.make + " " : "") + obj.model;
          obj.car_name = (n ? n : 'N/A')
          // this.vechile.push(obj)
          this.addCarFromScan(obj)
        }
      }else if(r==2 && typeof dialogRef.acquire!="undefined" && dialogRef.acquire){
        this.openAcquiringForm(2,'',dialogRef.plate_no,dialogRef.state)
      } else if(r == 3){
        this.dialogRef.close()
      }
    })
  }

  /** formate vehicle */
  formateVehicle(){
    let v=this.vechile
    v.forEach((obj: any) => {
      let n = obj.make;
      if (obj.year && obj.year!='0') {
        n = (n? n + ' / ':'') + obj.year
      }
      if (obj.model) {
        n = (n? n + ' / ':'') + obj.model
      }
      obj.car_name = (n ? n : 'N/A')
    })
    if(Array.isArray(v) && v.length==1){
      this.selectVechile(v[0])
    }
  }

  phoneValidation($e,key,search?:any) {
    if(search){
      this.gs.preferedServicesPhoneValidityForCalendar(this.srchForm, key);      
    }else{
      this.gs.preferedServicesPhoneValidityForCalendar(this.newCustomer, key);
      this.newCustomer.valid
    }
  }

  /** open Add Vehicle Form */
  openAddVehicle(){
    let dialogRef: any = this.dialog.open(AddVehicleForApptComponent, {
      panelClass: ['car-dialog-form', 'addvehiclemodal'],
      width: "800px",
      data: { customer_id: (this.selectCust ? this.selectCust.id : "") }
    });
    dialogRef.afterClosed().subscribe(r => {
      if (r == 1) {
        if (dialogRef.car && typeof dialogRef.car.id!="undefined") {
          this.vechile.push( dialogRef.car)
          this.changeCar()
          this.selectVechile(dialogRef.car)
          this.formateVehicle()
        }
      }
    })
  }

  selectVechile(car){
    this.seletedCar = null;
    setTimeout(() => {
      this.seletedCar = car;
    }, 500);    
  }

  selectedCustomer(customer){
    this.selectCust = customer;
  }

  /** add car from VIN or Plate Scan */
  addCarFromScan(obj){
    console.log('newvehcle',obj);
    console.log('addCarFromScanC',this.customer)
    console.log('addCarFromScanC',this.selectCust);
    obj.user_id=this.user.id
    obj.user_type=this.user.user_type
    obj.customer_id=((Array.isArray(this.customer) && this.customer.length) ? this.customer[0].id : "")
    if(obj.customer_id){
      this.gs.callAPI(globalConstants.API_ADD_VEHICLE_URL,obj).subscribe((r:any)=>{
        if(r.result==1){
          this.gs.snackbar("success", r.message);
          this.vechile.push(r.data)
          this.changeCar()
          this.selectVechile(r.data)
          this.formateVehicle()
        }else{
          this.gs.snackbar("error", r.message);
        }
      },(error)=>{})
    }else{
      // this.gs.snackbar("error", "Sele");
    }
  }

  /** acquiring vehicle from other customer*/
  openAcquiringForm(opr,vin?:any,plate?:any,state?:any){
    console.log('openAcquiringFormC',this.customer)
    console.log('openAcquiringFormC',this.selectCust)
    let dialogRef: any = this.dialog.open(AcquirevehicleApptComponent, {
      panelClass: ['car-dialog-form'],
      width: "800px",
      data: { customer_id: ((Array.isArray(this.customer) && this.customer.length) ? this.customer[0].id : ""),opr:opr,vin:vin,plate:plate,state:state }
    });
    dialogRef.afterClosed().subscribe(r => {
      if (r == 1) {
        this.srchForm.controls.mobile.setValue(this.customer[0].mobile_phone)
        this.search()
      }
    })
  }

  changeCar(){
    this.seletedCar=null
  }

  /** confirm before close*/
  qaClose(){
    // this.gs.confirmDialog("yes", "Are you sure you want to close ?").subscribe((r:any) => {
      // if (r != undefined && r == "yes") {
        this.dialogRef.close()
      // }
    // })
  }

  // getQQDetail(param) {
  //   let data = { quote_id: param.id, user_id: this.user.id }
  //   this.gs.callAPI(globalConstants.API_QQ_DETAIL, data).subscribe((r) => {
  //     if (r.result == 1) {
  //       this.recommData = r.data
  //       let customer = {
  //         "first_name": this.recommData.first_name,
  //         "last_name": this.recommData.last_name,
  //         "id": this.recommData.customer_id,
  //         "email":this.recommData.email,
  //         "mobile_phone":this.recommData.mobile_phone
  //       }
  //       this.customer = customer
  //       this.editMode = true
  //       this.getCustomerVehicles()
  //     } else {
  //       this.gs.snackbar("error", r.message);
  //     }
  //   }, (error) => { })
  // }

  getCustomerVehicles() {
    var content = "customer_id=" + this.customer.id + "&from_v2=1";
     this.gs.callAPI(globalConstants.API_GET_CUSTOMER_VEHICLES_URL, content).subscribe((data:any) => {
      if(data.result == 1) {
        this.vechile= data.data
        this.vechile.forEach((e)=>{
          if(e.id==this.param.car_id){
            this.seletedCar=e
          }
        })
        this.formateVehicle()
      }
    },(error)=>{ })
  }

  // enableEdit(obj){
  //   if(obj && obj.data && typeof obj.data.car_id!='undefined'){
  //     this.param=obj.data
  //     // this.getQQDetail(this.param)
  //   }
  // }

  editCustomer(customer){
    let p=this.gs.getAcl('customer','edits');
    if(!p){
      return false
    }
    let dialogRef: any = this.dialog.open(EditcustomerpopupComponent, {
      panelClass: ['car-dialog-form'],
      data: customer 
    });
    dialogRef.afterClosed().subscribe(r => {
      if (r == 1) {
        if(typeof dialogRef.email!='undefined'){
          this.customer.email=dialogRef.email
        }
        if(typeof dialogRef.first_name!='undefined'){
          this.customer.first_name=dialogRef.first_name
        }
        if(typeof dialogRef.last_name!='undefined'){
          this.customer.last_name=dialogRef.last_name
        }
        if(typeof dialogRef.mobile_phone!='undefined'){
          this.customer.mobile_phone=dialogRef.mobile_phone
        }  
      }
    })
  }
  
  checkEmail(){
    let email=this.newCustomer.controls.email;
    let errorEmail=false;
    if( this.newCustomer.value.email =='' || email.hasError('pattern')){
        errorEmail=true;
    }
    var opr = "email=" + this.newCustomer.value.email.replaceAll('+','%2B'); 
    (!errorEmail) && this.gs.callAPI(globalConstants.API_CHECK_CUSTOMER_INFORMATION_URL, opr).subscribe((data) => {
      this.getEmailCheck = data;
        if(this.getEmailCheck.result == "1"){
          this.isAutoGeneratedEmail = this.helperService.isAutoGeneratedEmail(this.newCustomer.value.email);
          this.checkUserEmail = true;
          if(!this.isAutoGeneratedEmail){
            this.openDialog(this.getEmailCheck,'email');
          }
        }else{
            this.checkUserEmail = false;
        }
    })
  }

  checkMobilePhone(){
    let mobile=this.newCustomer.controls.mobile;
    let errorMobile=false;
    if(mobile.hasError('required') || mobile.hasError('pattern')){
        errorMobile=true;
    }
    var opr = "mobile_phone=" + this.newCustomer.value.mobile;
    (!errorMobile) && this.gs.callAPI(globalConstants.API_CHECK_CUSTOMER_INFORMATION_URL, opr).subscribe((data) => {
      this.getMobileCheck = data;
        if(this.getMobileCheck.result == "1"){
          this.checkUserMobile = true;
          this.getMobileCheck.mobile_phone = this.newCustomer.value.mobile;
          this.openDialog(this.getMobileCheck,'mobile');
        }else{
          this.checkUserMobile = false;            
        }
    });
}


  openDialog(getEmailCheck, searchBy) {
    if(getEmailCheck.mapping_status == '1'){
        const dialogRef = this.dialog.open(SearchcustomerComponent, {
            panelClass: ['car-dialog-form'],
            data: {
                customerDetails:getEmailCheck,
            }
        });
        dialogRef.afterClosed().subscribe(res => {
          if(res){
            this.dialogRef.close();
          }
        });
    }else{
        const dialogRef = this.dialog.open(SearchothercustomerComponent, {
            panelClass: ['car-dialog-form'],
            data: {
                customerDetails:getEmailCheck,
                searchBy: searchBy
            }
        });
        dialogRef.afterClosed().subscribe(res => {
          if(res){
            console.log('Matadfad',res);
            this.dialogRef.close();
          }
        });
    }
  } 
  
  customerInformationPage(id,medium = 'email') {
    localStorage.setItem("customer_id",id);
    if(medium === 'email'){
      this.checkEmail();            
    }else if(medium === 'mobile'){
      this.checkMobilePhone();
    }
    // this.dialogRef.close();
    // this.router.navigate(['shops/customer-information']);
  }

  isAllowed(module,action){
    return this.gs.getAcl(module,action);
  }

  supportRequest(){
    
  }
}