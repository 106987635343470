<div mat-dialog-title class="relative">
    Bulk Update
    <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0" title="Close">
        <mat-icon class="close-icon-lg">close</mat-icon>
    </button>
</div>
<form class=" bulk-upload-form" [formGroup]="updatePartInfoForm" novalidate (onSubmit)="handleUpdateBulkParts()">
    <mat-dialog-content class="bulk-upload-content">
        <div class="help-text padding-bottom-20">Please provide value for only those fields which you want to update in bulk for selected parts.
        </div>
        <div class="bulk-upload-wrap">
            <div class="row margin-bottom-5">
                <label class="col-sm-3 control-label"><strong>Part Cost</strong></label>
                <div class="col-sm-9">
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                        <input matInput type="text" placeholder="Part Cost" formControlName="bulk_cost"
                            class="form-control input-lg custom-form-input btn-block" (keydown)="disableKey($event)"
                            (keypress)="onlyDecimalNumberKey($event);convertIntoDecimal($event,'bulk_cost',7)"
                            maxlength="10" />
                        <mat-error *ngIf="updatePartInfoForm.controls.bulk_cost.hasError('pattern')">
                            Must be a number with upto 2 decimal places.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row margin-bottom-5">
                <label class="col-sm-3 control-label"><strong>Retail Price</strong></label>
                <div class="col-sm-9">
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                        <input matInput type="text" placeholder="Retail Price" formControlName="bulk_price"
                            class="form-control input-lg custom-form-input btn-block" (keydown)="disableKey($event)"
                            (keypress)="onlyDecimalNumberKey($event);convertIntoDecimal($event,'bulk_price',7)"
                            maxlength="10" />
                        <mat-error *ngIf="updatePartInfoForm.controls.bulk_price.hasError('pattern')">
                            Must be a number with upto 2 decimal places.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row margin-bottom-5">
                <label class="col-sm-3 control-label"><strong>Part Quantity</strong></label>
                <div class="col-sm-9">
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                        <input matInput type="text" placeholder="Part Quantity" formControlName="bulk_quantity"
                            class="form-control input-lg custom-form-input btn-block" (keydown)="disableKey($event)"
                            (keypress)="onlyDecimalNumberKey($event);convertIntoDecimal($event,'bulk_quantity',4)"
                            maxlength="7" />
                        <mat-error *ngIf="updatePartInfoForm.controls.bulk_quantity.hasError('pattern')">
                            Must be a number with upto 2 decimal places.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row margin-bottom-5">
                <label class="col-sm-3 control-label padding-right-0"><strong>Low Stock Quantity</strong></label>
                <div class="col-sm-9">
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                        <input matInput type="text" placeholder="Low Stock Quantity"
                            formControlName="bulk_low_stock_value"
                            class="form-control input-lg custom-form-input btn-block" (keydown)="disableKey($event)"
                            (keypress)="onlyDecimalNumberKey($event);convertIntoDecimal($event,'bulk_low_stock_value',4)"
                            maxlength="7" />
                        <mat-error *ngIf="updatePartInfoForm.controls.bulk_low_stock_value.hasError('pattern')">
                            Must be a number with upto 2 decimal places.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="disPlayErrMsg" class="validation-error text-center"> Please enter value for at least one field.</div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="inquiry-action relative">
        <div class="flexBtns">
            <button type="submit" (click)="handleUpdateBulkParts()" mat-raised-button
                class="font-bold mat-raised-button" color="accent" title="Submit">Submit</button>
            <button mat-raised-button mat-dialog-close class="font-bold border" title="Close">Close</button>
        </div>
    </mat-dialog-actions>
</form>