import { Component, OnInit, Inject, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDrawer, MatSidenav} from '@angular/material/sidenav';
import { GlobalService } from '../../../../environments/global.service';

import { globalConstants } from '../../../../environments/globalConstants';
@Component({
  selector: 'app-updateworkhours',
  templateUrl: './updateworkhours.component.html',
  styleUrls: ['./updateworkhours.component.scss'],
})

export class UpdateworkhoursComponent implements OnInit {
  @ViewChild('drawer') drawer: MatSidenav;
  @Output() closeTab = new EventEmitter<any>();
  @Input() data; 
  public userData;
  public timeForm: FormGroup;
  public submitted: boolean = false;
  public requestdata: any = [];
  public actionSlots:Array<any> = [];
  public hourSlots:Array<any> = [];
  public minSlots:Array<any> = [];
  public workHour = 0;
  public workMin = 0;
  public prevHour = 0;
  public prevMin = 0;
  constructor(private formBuilder: FormBuilder, public globalService: GlobalService) { 
    this.userData=this.globalService.getCurrentUser();
    this.actionSlots = [{"id":'1',"text":"Add"},{"id":'2',"text":"Subtract"}]
    this.hourSlots =  [{"id":"00","text":"00 Hour"},{"id":"01","text":"01 Hour"}];
    this.minSlots =  globalConstants.MINUTES_SLOTS;
  }

  ngOnInit() {
    this.timeForm = this.formBuilder.group({
      user_type: this.globalService.getCurrentUser()["user_type"],
      user_id: this.globalService.getCurrentUser()["id"],
      adjustment_type      :['',[Validators.required]],
      updatedHours    :['',[Validators.required]],
      updatedMins     :['',Validators.required],
      comment :[''],
    });
    if(this.drawer){
      this.drawer.open();
    }
    this.getRequestDetails();
    this.timeForm.patchValue({
      adjustment_type: '1',
      updatedHours: '00',
      updatedMins: '00'
    });
    let i;
    for (i = 2; i <= 100; i++) {
      const time = i.toString().padStart(2, "0");
      this.hourSlots.push({"id":time,"text":time+" Hours"})
    }
  }

  getRequestDetails(){
    let body:any = {
      user_id: this.globalService.getCurrentUser()["id"],
      user_type: this.globalService.getCurrentUser()["user_type"],
      record_id: this.data
    }
    this.globalService.callAPI(globalConstants.API_GET_ADJUST_HOURS_URL, body).subscribe((data:any) => {
        if (data.result != "1") {
            this.globalService.snackbar("error", data.message);
        } else {
          this.requestdata = data.data;

          this.prevHour = Math.floor(data.data.minutes_worked/60);
          this.prevMin = data.data.minutes_worked%60;

          this.workHour = Math.floor(data.data.adjusted_minutes/60);
          this.workMin = data.data.adjusted_minutes%60;
          if(data.data.adjustment_type === '1' || data.data.adjustment_type === '2'){
            this.timeForm.patchValue({
              adjustment_type: data.data.adjustment_type.toString(),
              updatedHours: this.workHour.toString().padStart(2, "0"),
              updatedMins: this.workMin.toString().padStart(2, "0"),
              comment: data.data.comment
            });
          }
          
        }
    });
  }

  updateUserAdjustHour(){
    if (this.timeForm.valid) {
      const adjustMin = parseInt(this.timeForm.controls.updatedHours.value) * 60 + parseInt(this.timeForm.controls.updatedMins.value);
      if((this.timeForm.controls.adjustment_type.value === '2') && (adjustMin > this.requestdata.minutes_worked)){
        this.globalService.snackbar('error', "Adjust minutes cannot be greater than the minutes worked by user.");
        return;
      }
      let body:any = {
        user_id: this.globalService.getCurrentUser()["id"],
        user_type: this.globalService.getCurrentUser()["user_type"],
        record_id: this.data,
        adjust_type: this.timeForm.controls.adjustment_type.value,
        adjust_min: adjustMin,
        comment: this.timeForm.controls.comment.value
      }
      this.globalService.callAPI(globalConstants.API_UPDATE_USER_ADJUST_HOURS_URL, body).subscribe((data:any) => {
          if (data.result != "1") {
            this.globalService.snackbar("error", data.message);
          } else {
            this.globalService.snackbar("success", data.message);
            this.drawer.close();
            this.closeTab.emit(1);
          }
      });
    }else{
      this.submitted = true;
      this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
  }

  updateValue(){
    if(this.timeForm.controls.adjustment_type.value === '2'){
      this.timeForm.patchValue({
        updatedHours: '00',
      });
    }
  }

  closeSideBar(){
    this.drawer.close();
    this.closeTab.emit(0);
  }
}

