import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { Http } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { globalConstants } from '../../../../environments/globalConstants';

@Component({
  selector: 'app-smart-batch-assign',
  templateUrl: './smart-batch-assign.component.html',
  styleUrls: ['./smart-batch-assign.component.scss']
})
export class SmartBatchAssignComponent implements OnInit {

  public batches:any=null;
  public user:any=null;
  public part:any=null;
  public showCostPrice=false;
  public showingPartErr: any = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<SmartBatchAssignComponent>, private gs: GlobalService,public http:Http) {
    this.batches = data;
  }

  ngOnInit() {
    this.user = this.gs.getCurrentUser();
    if (!this.user) {
      this.dialogRef.close(2);
    }
    this.gs.diaLogObj(this.dialogRef);
    this.showCostPrice=this.gs.getAcl('display_part_cost','views');
  }
  getSelectedOption(opt) {
    if (typeof opt == "object") {
      this.showingPartErr = false;
      this.part = {
        part_id: opt.id,
        batch_id:this.batches.id,
        user_id: this.user.id,
        user_type: this.user.user_type
      };
    }
  }
  observableParts = (keyword: any): Observable<any[]> => {
    let url: string = globalConstants.API_PARTLIST_SMARTBATCH_ASSIGNMENT + "?user_id=" + this.user["id"] + "&query=" + keyword;
    if (keyword) {
      let f = [];
       return this.gs.getAPI(url).map((res:any)=>{
          let json = res;
          let show=this.showCostPrice;
          this.gs.authText(json);
          if (json != null) {
            json.forEach(function (x) {
              x.value = x.label;
              x.name = x.label;
              x.label+=(show?' '+x.price:'');
              x.quantity = 1;
              f.push(x)
            }
            );
          }
          return f
        })
    } else {
      return Observable.of([]);
    }
  }
  myListFormatter(data: any): string {
    return data['label'];
  }
  setReturnFalse() {
    return false
  }
  submit(type){
    if((!this.part) || (typeof this.part.part_id=='undefined')){
       this.showingPartErr = true;
      return false;
    }
    this.gs.callAPI(globalConstants.API_ASSIGN_PART_TO_SMART_BATCH,this.part).subscribe((res:any)=>{
      var message = res.message;
      if(res.result==1){
        this.gs.snackbar('success', message);
        (<HTMLInputElement>document.getElementById("new-part-qty")).value = "";
        this.part = {};
        this.batches.ref.setDataTable();
        if(type==2){
          this.dialogRef.close(0);
        }
      } else {
        this.gs.snackbar('error', message);
      }
    },(error)=>{})
  }

}
