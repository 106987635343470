import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { GlobalService } from "../../../environments/global.service";
import { IConversation, ICustomerChat } from 'src/app/interfaces';
import * as moment from 'moment-timezone';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-customer-live-chat',
  templateUrl: './customer-live-chat.component.html',
  styleUrls: ['./customer-live-chat.component.scss'],
})
export class CustomerLiveChatComponent implements OnInit {
  @ViewChild(ModalDirective) customerLiveChatModal: ModalDirective;
  public isDesktopViewActive = false;
  public showChatContact: any;
  public showChatArea: any;
  public showChatInfo: any;
  public addNoneClass: boolean;
  public refreshConversationInLeft = new EventEmitter();
  public selectedConversation = new EventEmitter();
  public updateBadgeInChatView = new EventEmitter();
  @Output() toggleModalState = new EventEmitter<any>();
  public emitCloseModal=new EventEmitter();
  constructor(
    private globalService: GlobalService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CustomerLiveChatComponent>
  ) {
  }

  id: any;
  ngOnInit(): void {
    localStorage.setItem('chat-modal-state', 'active');
    // window.scrollTo(0, 0);
    if (window.innerWidth > 991) {
      this.isDesktopViewActive = true; // for showing desktop view //
      this.showChatContact = true;
      this.showChatArea = true;
      this.showChatInfo = true;
    } else {
      this.isDesktopViewActive = false; // for showing mobile view //
      this.showChatContact = true;
      this.showChatArea = false;
      this.showChatInfo = false;
    }

    this.setMomentTimeByUserTimeZone();
  }

  async setMomentTimeByUserTimeZone() {
    const user = await this.globalService.getShopDetails();
    moment.tz.setDefault(user.time_zone);
  }

  toggleState(type) {
    switch (type) {
      case 1:
        localStorage.setItem('chat-modal-state', 'active');
        this.customerLiveChatModal.show();
        break;
      case 2:
        this.emitCloseModal.emit('minimise');
        localStorage.setItem('chat-modal-state', 'minimise');
        this.data.toggleModalState('minimise');
        this.addNoneClass = true;
        break;
      case 3:
        this.emitCloseModal.emit('destroy');
        localStorage.setItem('chat-modal-state', 'destroy');
        this.data.toggleModalState('destroy');
        break;
    }
  }

  chatAreaViewInMobile() {
    if (!this.isDesktopViewActive) {
      this.showChatContact = false;
      this.showChatArea = true;
      this.showChatInfo = false;
    }
  }

  contactViewInMobile() {
    if (!this.isDesktopViewActive) {
      this.showChatContact = false;
      this.showChatArea = false;
      this.showChatInfo = true;
    }
  }

  onBackToContact() {
    if (!this.isDesktopViewActive) {
      this.showChatContact = true;
      this.showChatArea = false;
      this.showChatInfo = false;
    }
  }

  onBackToChatArea() {
    this.chatAreaViewInMobile();
  }

  updateSelectedContactInfo = (conversationInfo: IConversation) => {
    if (!this.isDesktopViewActive) {
      this.showChatContact = false;
      this.showChatArea = true;
      this.showChatInfo = false;
    }
    conversationInfo && this.selectedConversation.emit(conversationInfo);
  }

  refeshConversations = (value) => {
    this.refreshConversationInLeft.emit(value);
  }

  updateBadge = (value) => {
    this.updateBadgeInChatView.emit(value);
  }
}
