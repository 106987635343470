import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
// import { MatPaginator, MatSort, MatDatepicker, DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter, MatDialog } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import {  MatSort  } from '@angular/material/sort';
import {  MatDatepicker } from '@angular/material/datepicker';
import { MatDialog} from '@angular/material/dialog'

import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { globalFunctions } from '../../../../environments/globalFunctions';
import { AppComponent } from '../../../app.component';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { TechassignmentComponent } from '../techassignment/techassignment.component';
import { AdjustTechScheduleComponent } from './adjust-tech-schedule/adjust-tech-schedule.component';
const ATTENTION_SR_LIST = 'attention-serice-request';
import * as moment from 'moment';
import { NativeDateAdapter } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';

export class AppDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  format(date: Date, displayFormat: Object): string {
    if (displayFormat == "input") {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      return this._to2digit(month) + '/' + this._to2digit(day) + '/' + year;
    } else {
      return date.toDateString();
    }
  }

  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }
}

export const APP_DATE_FORMATS =
{
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
  },
  display: {
    // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    dateInput: 'input',
    monthYearLabel: { month: 'short', year: 'numeric', day: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
}
@Component({
  selector: 'app-attention-service-requests',
  templateUrl: './attention-service-requests.component.html',
  styleUrls: ['./attention-service-requests.component.scss'],
  providers: [DatePipe]
})

export class AttentionServiceRequestsComponent implements OnInit {

  public displayedColumns: any = ['request_id', 'name','username','start_date', 'end_date', 'conflict_reason', 'requested_delivery_date', 'Actions'];
  public defaultColumns: any = '';
  public currentCustomer;
  public more_rows;
  public request_id: string;
  public empSelected = "";
  public options = {
    user_id: "",
    user_type: "User",
    search_by: "",
    search_keyword: "",
    start: 0,
    limit: 10,
    emp_id: "",
    search_days: "",
    from_date: "",
    to_date: "",
    sort_column: '',
    sort_direction: '',
  }
  public currentUser;
  public statuses = ['Appointment', 'Pending', 'Processing', 'Waiting for Approval', '', 'Service Completed', 'Completed', 'Approval Completed', 'Completed And Paid', 'Unread Rating', 'Non-CAR', '', 'Work In Progress'];
  public modulePermission: any = {};
  public modulesName = 'service_request';
  public filterOption = [{ id: "1", text: 'Today' }, { id: "2", text: 'Week' }, { id: "3", text: 'Date Range' }];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public gSerice = null;
  public openSearch: boolean = false;
  public skipTicketAssign = false;
  public jobFilter = [];
  public empFilter = [];
  public searchByStatus = [];
  public isEmployee = false;
  public isShop = false;
  public searchByArray = [];
  public sortDirections = {
    'asc': 'asc',
    'desc': 'desc'
  };
  filterOptionsSet: Observable<any[]>;
  myControl: FormControl = new FormControl();
  public getEmployee     = [];
  public today           = new Date();
  public techData:any = {};
  dataSource: MatTableDataSource<SRData> = new MatTableDataSource();
  public record :any =[];
  public recordsTotal : any ;
  constructor(
    public cdr: ChangeDetectorRef,
    public activatedRoute: ActivatedRoute,
    private app: AppComponent,
    public dialog: MatDialog,
    public globalService: GlobalService) {
    this.globalService.setMetaData("SHOPS", "LIST_SERVICE_REQUEST");
    this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
    this.options.user_id = this.currentCustomer.id;
    /* SEARCH BY ARRAY */
    this.searchByArray = [
      { id: "1", text: "Req. Id" },
      { id: "2", text: "Title" },
      { id: "6", text: "Customer Email" },
      { id: "3", text: "Customer Name" },
      { id: "4", text: "License Plate" },
      { id: "5", text: "Vehicle" }
    ];

  }

  ngOnInit() {
    let search_keyword = this.globalService.getSetItems('userAlreadyExist');
    this.currentUser = this.globalService.getCurrentUser();
    this.options.user_id = this.currentUser.id;
    this.options.user_type = this.currentUser.user_type;
    this.options.search_keyword = (search_keyword ? search_keyword : '');
    this.options.search_by = (search_keyword ? 'emails' : '0');
    this.activatedRoute.queryParams.subscribe(params => {
      this.request_id = params['request_id'];
    });
    this.setDataTable();
    this.getEmployees();
    this.isEmployee     = this.globalService.isSubscribe('employee');
  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  getEmployees() {
    let content = 'user_id=' + this.currentUser.id + '&user_type=' + this.currentUser.user_type;
    let val_type = 'employee';
    this.globalService.callAPI(globalConstants.API_GET_TECHNICIAN, content).subscribe((data:any) => {
      this.getEmployee = data.allemployes;
      this.filterOptionsSet = this.myControl.valueChanges
        .startWith(null)
        .map(val => val ? this.filter(val, val_type) : this.getEmployee && this.getEmployee.slice());
      if(this.request_id !== ''){
        this.openSearch = true;
        this.options.search_keyword = this.request_id;
        this.options.search_by = "1";
        this.search();  
      }
    });
  }

  filter(val: string, val_type): string[] {
    if (val.length != undefined) {
      var filteredValue = this.getEmployee.filter(option => option.first_name.toLowerCase().indexOf(val.toLowerCase()) === 0);
      return filteredValue
    }
  }

  empDisplayFn(val) {   
    if (val != undefined && val != null && val != '') {
      return val.first_name + ' ' + val.last_name;
    }
  }

  setDataTable() {
    this.record = [];
    let options = Object.assign({},this.options);
    if(options.from_date!=''){
      options.from_date = this.dateFormat(options.from_date);
    }
    if(options.to_date!=''){
      options.to_date = this.dateFormat(options.to_date);
    }
    // this.options = options;
    var content = globalFunctions.stringifyFormData(this.options, null);
    var callApi = this.globalService.callAPI(globalConstants.API_GET_ATTENTIONS_SR_LIST, content);
    callApi.subscribe((data:any) => {
      if (data.data != undefined) {
        let moreRows = 'false';
        let firstList = 'false';
        this.globalService.localGetterSetter("totalRecords", data.recordsTotal, ATTENTION_SR_LIST);
        if (data.more_rows == "true") {
          moreRows = "true";
        }
        this.globalService.localGetterSetter("more_rows", moreRows, ATTENTION_SR_LIST);
        if (data.start >= 0 && data.start <= 5) {
          firstList = "true";
        }
        this.globalService.localGetterSetter("first_list", firstList, ATTENTION_SR_LIST);
        if (data.data.length != undefined) {
          this.recordsTotal = data.data.length;
          for (var i = 0; i < data.data.length; i++) {
            this.AddRecord(data.data[i])
            this.dataSource = new MatTableDataSource(this.record)
            this.dataSource.sort = this.sort;
          }
          this.globalService.localGetterSetter("current-visible", this.recordsTotal, ATTENTION_SR_LIST);
        } else {
          console.log("this is length",data.data)
          this.globalService.localGetterSetter("current-visible", '1', ATTENTION_SR_LIST)
          this.recordsTotal = "1";
          this.AddRecord(data.data)
          console.log("this is recode",this.record)
          this.dataSource = new MatTableDataSource(this.record)
          this.dataSource.sort = this.sort;
        }
        this.globalService.localGetterSetter(null, this.options, ATTENTION_SR_LIST);
      } else {
        this.dataSource = new MatTableDataSource(this.record);
        this.globalService.localGetterSetter("totalRecords", '0', ATTENTION_SR_LIST);
      }
      this.globalService.getSetItems('userAlreadyExist', '');
    });
  }

  AddRecord(record) {
    var data = this.createRecordrecord(record);
    this.record.push(data)
  }

  private createRecordrecord(record) {
    return {
      name: record.first_name + ' ' + record.last_name,
      emp_id: record.user_id,
      id: record.id,
      request_id: record.request_id,
      conflict_reason: record.conflict_reason,
      start_date: record.start_date,
      end_date: record.end_date,
      requested_delivery_date: record.requested_delivery_date,
      reason_type_id: record.reason_type_id,
      username : record.Username,
      license_plate:record.license_plate,
      vin:record.vin,
      make:record.make,
      model:record.model,
      year:record.year,
      state:record.state,
      car_id: record.car_id,
      present_mileage: record.present_mileage,
      user_sr_id:record.user_sr_id,
      conflict_id:record.conflict_id,
      reason_title:record.reason_title,
      reason_description:record.reason_description
    }
  }

  search() {
    this.options.start = 0;
   
    this.setDataTable();
  }

  clearSearch() {
    this.globalService.getSetItems('userAlreadyExist', '');
    this.globalService.localGetterSetter(null, this.options, ATTENTION_SR_LIST);
    this.reInitOptions();
    this.setDataTable();
  }

  dateFormat(dateValue) {
    return ((new Date(dateValue).getMonth()+1) < 10 ? '0' : '')+(new Date(dateValue).getMonth()+1) + "/" + (new Date(dateValue).getDate() < 10 ? '0' : '')  + new Date(dateValue).getDate() + "/" + new Date(dateValue).getFullYear();
  }

  reInitOptions() {
    this.options = this.globalService.localGetterSetter(this.options, this.options, ATTENTION_SR_LIST);
    this.options.user_id = this.currentUser.id;
    this.options.user_type = this.currentUser.user_type;
    this.options.search_keyword = '';
  }

  getTotalRecords() {
    return parseInt(localStorage.getItem(ATTENTION_SR_LIST + 'totalRecords'));
  }

  paginateByLimit(event) {
    this.scrolling();
    this.options.limit = event.pageSize;
    this.options.start = 0;
    this.setDataTable();
  }

  paginate(event, type) {
    this.scrolling();
    if (type == "next") {
      this.options.start = this.options.start + this.options.limit;
    } else {
      this.options.start = this.options.start - this.options.limit;
    }
    this.setDataTable();
  }

  scrolling() {
    this.globalService.setScrolling('.car-datatable', 1000, -20);
  }

  firstList() {
    return this.globalService.firstList(ATTENTION_SR_LIST, this.options);
  }

  lastList() {
    return this.globalService.lastList(ATTENTION_SR_LIST);
  }

  getUpperLimit(currentLastLimit, total) {
    if (currentLastLimit > total) {
      return total
    } else {
      return currentLastLimit
    }
  }

  public changeSortParams(sorter) {
    this.options.sort_column = sorter.active;
    this.options.sort_direction = sorter.direction;
    console.log("this is option",this.options)
    this.globalService.localGetterSetter(this.options, null, ATTENTION_SR_LIST);
  }

  isAllowed(action, module?: any) {
    if (typeof module != 'undefined') {
      return this.globalService.isAllowed(this.app, module, action);
    }
    return this.globalService.isAllowed(this.app, this.modulesName, action);
  }

  removeAssigne(row) {
    this.globalService.confirmDialog("removeAssigne", "Are you sure to want to delete?").subscribe((data) => {
      if (data != undefined && data.toString() == "removeAssigne") {
        let params = 'user_id=' + this.currentUser.id + '&user_type=' + this.currentUser.user_type + '&request_id=' + row.id + "&tech_id=" + row.user_sr_id;;
        this.globalService.callAPI(globalConstants.API_DELETE_SERVICE_REQUEST_ASSIGNEE, params).subscribe((r:any) => {
          if (r.result == 1) {
            this.globalService.snackbar("success", r.message);
            this.delete(row);
          } else {
            this.globalService.snackbar("error", r.message);
          }
        })
      }
    })
  }

  delete(row){
    let params = 'user_id=' + this.currentUser.id + '&user_type=' + this.currentUser.user_type + '&conflict_id=' + row.conflict_id 
    this.globalService.callAPI(globalConstants.API_DELETE_ATTENTION_SR, params).subscribe((r:any) => {
      if (r.result == 1) {     
        this.setDataTable();
      } else {
        this.globalService.snackbar("error", r.message);
      }
    })
  }

  deleteLeave(row) {
    this.globalService.confirmDialog("deleteLeave", "Are you sure to want to delete?").subscribe((data:any) => {
      if (data != undefined && data.toString() == "deleteLeave") {
        let params = 'user_id=' + this.currentUser.id + '&user_type=' + this.currentUser.user_type + '&request_id=' + row.id + "&emp_id=" + row.emp_id+ "&id=" + row.reason_type_id;
        this.globalService.callAPI(globalConstants.API_DELETE_LEAVE, params).subscribe((r:any) => {
          if (r.result == 1) {
            this.globalService.snackbar("success", r.message);
            this.delete(row);
          } else {
            this.globalService.snackbar("error", r.message);
          }
        })
      }
    })
  }

  deleteConflict(row) {
    this.globalService.confirmDialog("deleteConflict", "Are you sure to want to delete?").subscribe((data) => {
      if (data != undefined && data.toString() == "deleteConflict") {
        this.delete(row);
      }
    })
  }

  /*asssignetoOther(row) {
    let dialogRef = this.dialog.open(TechassignmentComponent, {
      panelClass: ['car-dialog-form'],
      width: "650px",
      data: { request_id:row.id,empId:row.emp_id,techId:row.user_sr_id,data:row,fromConflict:true}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof dialogRef["techAssign"] != 'undefined' && dialogRef["techAssign"] == true) {
        this.delete(row);
      }
    });
  }*/


  asssignetoOther(row){   
    if(this.isEmployee==false){
        return false;
    }
    this.techData= {request_id:row.id,empId:row.emp_id,techId:row.user_sr_id,data:row,fromConflict:true};
  }

  techAssignmentOut($event:any){
    if($event==true){   
      this.delete(this.techData.data); 
    }
    this.techData = {};   
  }


  adjustTiming(row) {
    let dialogRef = this.dialog.open(AdjustTechScheduleComponent, {
      panelClass: ['car-dialog-form'],
      width: "650px",
      data: { request_id: row.id,data:row,user_sr_id:row.user_sr_id,emp_id:row.emp_id}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof dialogRef["adjustTime"] != 'undefined' && dialogRef["adjustTime"] == true) {
        this.delete(row);
      }
    });
  }

  convertISODate(dateString){
    return moment(dateString).toISOString();
  }

  SetOptionAsEmployeeId(event){
    this.options.emp_id = (event.option.value["id"]);
  }

}

export interface SRData {
  id: any;
  request_id: any;
  conflict_reason: any;
  start_date: any;
  end_date: any;
  emp_id: any;
  requested_delivery_date: any;
  reason_type_id: any;
  name: string;
  username:string
  license_plate:string;
  vin:string;  
  user_sr_id:any;
  conflict_id:any;

}




