import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { globalFunctions } from '../../../../environments/globalFunctions';
import {Http, Headers, Response} from '@angular/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
import { SearchcarComponent } from '../searchcar/searchcar.component';
import { QQSearchcarComponent } from '../../quickquote/qq-searchcar/qq-searchcar.component';


@Component({
  selector: 'app-shopcarlicenseplate',
  templateUrl: './shopcarlicenseplate.component.html',
  styleUrls: ['./shopcarlicenseplate.component.scss']
})

export class ShopcarLicensePlateComponent {
    public licensePlateForm: FormGroup;
    public licensePlateData;
    public shopCustomerID;
    public customerCountryName;
    public currentCustomer;
    public shopCountryId = 'USA';
    public shopState;
    public states: any = [];
    public defaultState;
    public statesArray=[''];
    public qq=false
    public quickapt= false;
    shopCustomerDetails: any;
    public shopDetail: any;
    constructor( @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ShopcarLicensePlateComponent>,
        public dialog: MatDialog,
        private formBuilder: FormBuilder,
        private globalService: GlobalService,
        private http: Http,
        private router: Router
    ) {


        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
        if(typeof data.quickapt!='undefined' && data.quickapt){
            this.quickapt=true
        }
        if (typeof data.qq != 'undefined' && data.qq) {
            this.qq = true
            this.shopCustomerID=data.customer_id
        } else if(data.send_params) {
            localStorage.setItem("shopCustomerID", data.send_params);
            var splitParamsValues = data.send_params.split(",");
            this.shopCustomerID = splitParamsValues[0];
            this.customerCountryName = splitParamsValues[1];
        }else if(data.customer_id){
            this.shopCustomerID=data.customer_id;
        }
        this.globalService.diaLogObj(this.dialogRef);
       
    }

    ngOnInit() {
        if(this.qq){
            this.licensePlateForm = this.formBuilder.group({
                plate_number: ['', [Validators.required,Validators.pattern(globalConstants.VIN_AND_LICENSE_PLATE_REGEX)]],
                state: [''],
                state_name:""
            });
        }else{
            this.licensePlateForm = this.formBuilder.group({
                plate_number: ['', [Validators.required,Validators.pattern(globalConstants.VIN_AND_LICENSE_PLATE_REGEX)]],
                state: ['', [Validators.required]],
                state_name:""
            });
        }
        this.getShopDetails();
        
        if(this.shopCustomerID && typeof this.shopCustomerID != 'undefined'){
            this.getShopCustomerDetails();   
        }     
    }
    
    
    getLicensePlate() {
        Object.keys(this.licensePlateForm.controls).map((controlName) => {
            this.licensePlateForm.get(controlName).markAsTouched({onlySelf: true});
        });
        
        if (this.licensePlateForm.valid) {
            let sendOprs = (this.shopCustomerID ? "customer_id="+this.shopCustomerID:"")+"&plate="+this.licensePlateForm.value.plate_number.replace(/^\s+|\s+$/gm, '')+"&state="+this.licensePlateForm.value.state+"&user_id="+this.currentCustomer.id;
            if(this.qq){
                let stateName=this.licensePlateForm.value.state_name
                sendOprs = sendOprs+"&state_name="+stateName+"&qq_opr=1"
            }
            this.globalService.callAPI(globalConstants.API_GET_LICENSE_PLATE_DATA_URL, sendOprs).subscribe((data:any) => {
                var message = data.message;
                if(data.result == "1") {
                    if('code' in data && data.code =='638' && 'vehicle_info' in data){
                        this.searchCarDialog({vehicle_info:data.vehicle_info},'vin');  
                    }else if(this.qq){
                        this.dialogRef['plate_no']=this.licensePlateForm.value.plate_number
                        this.dialogRef['car']=data.data
                        this.dialogRef['customer']=""
                        this.dialogRef['vehicles']=""
                        if(typeof data.customer!='undefined' && data.customer){
                           this.dialogRef['customer']=data.customer
                        }
                        if(typeof data.vehicles!='undefined' && data.vehicles){
                           this.dialogRef['vehicles']=data.vehicles
                        }
                        this.dialogRef.close(1);
                    }else{
                        this.licensePlateData = data.data;
                        localStorage.setItem('licensePlateData', JSON.stringify(this.licensePlateData));
                        this.dialogRef.close(this.licensePlateData);
                        this.router.navigate(['/shops/add-car']);
                    }
                } else {
                    if(data.code==517){
                        this.dialogRef['plate_no']=this.licensePlateForm.value.plate_number
                        this.dialogRef['state']=this.licensePlateForm.value.state_name
                        if(this.quickapt){
                            let vehicleInfo = { vehicle_info: data.vehicles }
                            this.searchCarDialog(vehicleInfo,'licence');
                        }else{
                            //this.askForAcquiring(message)
                            let vehicleInfo = { vehicle_info: data.vehicles }
                            this.qqSearchCarDialog(vehicleInfo,'licence');
                        }
                    }else{
                        this.globalService.snackbar("error", message);
                    }
                }
                
            },(error)=>{  });
            
        } 
    }

    getShopCustomerDetails() {
        var opr = "user_type=" + this.currentCustomer.user_type + "&user_id="+this.currentCustomer.id+"&customer_id=" + this.shopCustomerID;
        this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_DETAILS, opr).subscribe((data:any) => {
            this.shopCustomerDetails = data.data;
        });
    } 

    checkLicence(){
        Object.keys(this.licensePlateForm.controls).map((controlName) => {
            this.licensePlateForm.get(controlName).markAsTouched({onlySelf: true});
        });
        
        if (this.licensePlateForm.valid) {
            let license_plate = this.licensePlateForm.value.plate_number.replace(/^\s+|\s+$/gm, '');
            let state         = this.licensePlateForm.controls.state.value;
            let stateName     = this.licensePlateForm.value.state_name;
            let opr           = "user_type=" + this.currentCustomer.user_type + "&user_id="+this.currentCustomer.id+"&license_plate="+license_plate +"&state="+state+"&customer_id=" + this.shopCustomerID+"&state_name="+stateName;
            this.globalService.callAPI(globalConstants.API_CHECK_VEHICLE_INFO, opr).subscribe((data:any) => {
                let message = data.message;
                if(data.result == "1"){
                    if('code' in data && data.code =='638' && 'vehicle_info' in data){
                        console.log('sss',data);
                        this.searchCarDialog({vehicle_info:data.vehicle_info},'vin');  
                    }else if('vehicle_info' in data.data){
                        this.searchCarDialog(data.data,'licence');  
                    }else{
                        this.licensePlateData = data.data;
                        localStorage.setItem('licensePlateData', JSON.stringify(this.licensePlateData));
                        this.dialogRef.close(this.licensePlateData);
                        this.router.navigate(['/shops/add-car']);
                    }                              
                }else{
                    if(data.code == 528){
                        this.globalService.confirmDialog("confirm", data.message).subscribe((res) => {
                            if (res != null) {
                              this.licensePlateForm.patchValue({  plate_number: ''});
                            }
                        });
                    }else if(data.code==517){
                        this.dialogRef['plate_no']=this.licensePlateForm.value.plate_number
                        this.dialogRef['state']=this.licensePlateForm.value.state_name
                        this.askForAcquiring(message)
                    }else{
                        this.globalService.snackbar("error", message);
                    }
                }
            })
        }
    }

    searchCarDialog(customerVehcileInfo, searchBy){
        let status = 1; 
        if(this.shopCustomerID == customerVehcileInfo.vehicle_info.customer_id){
            status = 1;
        }else if(this.shopCustomerID != customerVehcileInfo.vehicle_info.customer_id){
            status = 2;
        }
        if(this.shopCustomerDetails.is_sublet == '1'){
            status = 3;
        }
        const dialogRef = this.dialog.open(SearchcarComponent, {
            panelClass: ['car-dialog-form'],
            data: {
                status:status,
                customerVehcileInfo: customerVehcileInfo,
                shopCustomerDetails: this.shopCustomerDetails,
                currentCustomer: this.currentCustomer,
                searchBy: searchBy,
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if(result.status === 'jump'){
                this.dialogRef.close(3);
            }
        });
    }
    
    cancelLicensePlateModel() {
        Object.keys(this.licensePlateForm.controls).map((controlName) => {
            this.licensePlateForm.get(controlName).reset();
        });
    }

    getStates(){
        let country        = this.shopDetail.country_id;
        let statesOpr: any = "country_id=" + country + "&opr=1";
        let url            = globalConstants.API_GET_STATES_URL;
        if (this.qq) {         
            statesOpr = "country_id=" + country + "&opr=1";
            url       = globalConstants.API_GET_STATES_URL
        }else if(this.shopCustomerID){
            statesOpr = "customer_id=" + this.shopCustomerID + "&opr=1"
             url      = globalConstants.API_GET_STATES_BY_CUSTOMER_ID
        }
        this.globalService.callAPI(url, statesOpr).subscribe((r:any) => {
            if (r.result==1) {
                this.states = r.data;
                var slist = [];
                if (this.qq) {
                    slist.push({"id":'0',"state_code":'Select State',"text":'Select State'})
                }
                for (var i in this.states) {
                    this.states[i].id = this.states[i].state_code;
                    this.states[i].text = this.states[i].state_name;
                    slist.push(this.states[i]);
                    this.statesArray[this.states[i].state_id] = (this.states[i].state_name);
                }
                this.states = slist;
                if (!this.qq) {
                    this.setSelectedOptionState();
                }else{
                    // let StateName  = this.shopDetail.state;
                    let stateName = (this.shopCustomerDetails.state == "" || typeof (this.shopCustomerDetails.state) == 'undefined') ? this.shopDetail.state : this.shopCustomerDetails.state;
                    const carState = this.states.filter(state => state.state_name == stateName);
                    if(carState.length > 0) {
                        this.defaultState = carState[0].state_code;                    
                        this.licensePlateForm.patchValue({state : this.defaultState});
                        this.licensePlateForm.patchValue({state_name : stateName});
                    }  
                }
            }
        }, (error) => { })
    }

     /* STATES LIST IN SEARCH BOX DROP DOWN */
     public getSelectedOptionState(opt) {
         
         if (this.qq) {
            let carState = this.states.filter(state => state.state_code ==  opt);
            if(carState.length > 0) {
                this.defaultState = carState[0].state_code;
                let stateName= carState[0].state_name;
                this.licensePlateForm.patchValue({state : this.defaultState});
                this.licensePlateForm.patchValue({state_name : stateName});
            } else {
                this.licensePlateForm.controls.state.setValue('');
                this.licensePlateForm.controls.state_name.setValue('');
            }
         }else{
            let carState = this.states.filter(state => state.state_code ==  opt);
            if(carState.length > 0) {
                this.defaultState = carState[0].state_code;
                let stateName= carState[0].state_name;
                this.licensePlateForm.patchValue({state : this.defaultState});
                this.licensePlateForm.patchValue({state_name : stateName});
            } else {
                this.licensePlateForm.controls.state.setValue('');
            }
         }
    }

    // set state if customer set state value otherwise set shop profile state value.
    public setSelectedOptionState(){
        let StateName = "";
        var content = "customer_id=" + this.shopCustomerID;
        this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_DETAILS, content).subscribe((data:any) => {
         if (data.result==1) {
            StateName = data.data.state;
            if(localStorage.getItem('shop_state') && StateName==''){  
                StateName = localStorage.getItem('shop_state');
            }
            console.log('sss',StateName);
            if(StateName){                
                const carState = this.states.filter(state => state.state_name == StateName);
                if(carState.length > 0) {
                    this.defaultState = carState[0].state_code;                    
                    this.licensePlateForm.patchValue({state : this.defaultState});
                    this.licensePlateForm.patchValue({state_name : StateName});
                } else {
                    this.defaultState = '';
                    this.licensePlateForm.controls.state.setValue('');
                }
            }
         }
        })
    }
    /**
     * getting confirmation from user 
     * to go for vehicle transfer
     * or Scan new Plate
    */
    askForAcquiring(msg){
        this.globalService.confirmDialog("yes", msg+" If you want to expedite the ownership transfer and can verify customer details, press YES?").subscribe((r:any) => {
            if (r != undefined && r == "yes") {
                this.dialogRef["acquire"]=true;
                this.dialogRef.close(2);
            }else{
                this.dialogRef["acquire"]=false;
            }
        })
    }

    qqSearchCarDialog(customerVehcileInfo, searchBy){
        let status = 1; 
        if(this.shopCustomerID == customerVehcileInfo.vehicle_info.customer_id){
            status = 1;
        }else if(this.shopCustomerID != customerVehcileInfo.vehicle_info.customer_id){
            status = 2;
        }
      
        const dialogRef = this.dialog.open(QQSearchcarComponent, {
            panelClass: ['car-dialog-form'],
            data: {
                status:status,
                customerVehcileInfo: customerVehcileInfo,
                shopCustomerDetails: this.shopCustomerDetails,
                currentCustomer: this.currentCustomer,
                searchBy: searchBy,
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.dialogRef["acquire"]=false;
            if(result && 'data' in result){
                console.log('as111dfa',result)
                this.dialogRef['data'] = result.data;
                this.dialogRef["acquire"]=true;
            } 
            this.dialogRef.close(2);            
        });
    }

    getShopDetails(){
        var payload = "user_id=" + this.currentCustomer.shop_id + "&user_type=" + this.currentCustomer.user_type + "&shop_id=" + this.currentCustomer.shop_id;
       this.globalService.callAPI(globalConstants.API_SHOP_DETAILS, payload).subscribe((data: any) => {
         this.shopDetail = data.data;
         this.getStates();
       })
    }

}
