<div class="container">
  <!-- Start Contact Us form -->
    <div  class="row bgcolor-lightgrey form-content" id="contact-us-form" *ngIf="!thanksMsg">
      <form [formGroup]="contactForm" role="form" (ngSubmit)="contactUsForm()">
        <div class="form-group form-content  col-sm-12">
          <h2  class="text-center fontcolor-blue main-heading">CONTACT US</h2>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 pull-right">
          <address>
            <p><strong>Complete Auto Reports LLC</strong></p>
            <p>701 N Stiles St</p>
            <p>Linden NJ 07036,</p>
            <p>1 833 AUTORPT</p>
            <p>1 833 2886778</p>
            <br>
            <a class="text-red" href="mailto:support@completeautoreports.com">support@completeautoreports.com</a>
          </address>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-9 form-block">
          <div class="row">
            <div class="form-group col-sm-6">
                <div class='relative'>
                  <mat-form-field class="btn-block car-input" floatPlaceholder="never">
                      <input matInput placeholder="First Name*" (keyup)="checkIfValid()" formControlName="first_name"  class="form-control input-lg custom-form-input"  />
                      <mat-error *ngIf="contactForm.controls.first_name.hasError('required')">
                          This field cannot be empty.
                      </mat-error>
                  </mat-form-field>
                </div>
            </div>
            <div class="form-group col-sm-6">
                <div class='relative'>
                  <mat-form-field class="btn-block car-input" floatPlaceholder="never">
                      <input matInput placeholder="Last Name*" (keyup)="checkIfValid()"  formControlName="last_name"  class="form-control input-lg custom-form-input"  />
                      <mat-error *ngIf="contactForm.controls.last_name.hasError('required')">
                          This field cannot be empty.
                      </mat-error>
                  </mat-form-field>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-6">
                <div class='relative'>
                  <mat-form-field class="btn-block car-input" floatPlaceholder="never">
                      <input matInput placeholder="Email*" (keyup)="checkIfValid()" formControlName="email"  class="form-control input-lg custom-form-input"  />
                      <mat-error *ngIf="contactForm.controls.email.hasError('required')">
                          This field cannot be empty.
                      </mat-error>
                      <mat-error *ngIf="contactForm.controls.email.hasError('pattern')">
                          Please enter valid email address.
                      </mat-error>
                  </mat-form-field>
                </div>
            </div>
            <div class="form-group col-sm-6">
                <div class='relative'>
                  <mat-form-field class="btn-block car-input" floatPlaceholder="never">
                      <input matInput placeholder="Mobile Phone" (keyup)="checkIfValid()" formControlName="mobile_phone"  class="form-control input-lg custom-form-input" maxlength="15" [textMask]="{mask: phoneMask}"  />
  
                      <mat-error *ngIf="contactForm.controls.mobile_phone.hasError('minLength')">
                        Invalid Mobile Number
                      </mat-error>
                  </mat-form-field>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                <textarea (keyup)="checkIfValid()" matInput placeholder="Your message here*"  formControlName="message"  rows="5"  class="form-control input-lg custom-form-input"></textarea>
                <mat-error *ngIf="contactForm.controls.message.hasError('required')">
                  This field cannot be empty.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="form-group  col-xs-12 col-sm-12 col-md-12 col-lg-12" style="min-height:78px">
                <re-captcha siteKey="{{site_key}}" (resolved)="handleReCaptchaResponse($event)"  formControlName="g-recaptcha-response"></re-captcha>
            </div>
            <div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="form-block submit-block">
                  <button type="submit" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" [disabled]="submitted" color="accent" title="Submit"  style="max-width: 300px;" >SUBMIT</button>
                </div>
              </div>
          </div>
        </div>
      </form>
    </div>
    <!-- End Contact Us form -->

    <!-- start Contact Us Message -->
    <div class="row bgcolor-lightgrey" id="contactus-thanks-msg" *ngIf="thanksMsg">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 pull-right address-info">
        <address>
          <p><strong>Complete Auto Reports LLC</strong></p>
          <p>701 N Stiles St</p>
          <p>Linden NJ 07036,</p>
          <p>1 833 AUTORPT</p>
          <p>1 833 2886778</p>
          <br>
          <a class="text-red" href="mailto:support@completeautoreports.com">support@completeautoreports.com</a>
        </address>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-9 thanks-msg">
        <h2  class="text-center fontcolor-blue main-heading margin-bottom-45">Thank you for contacting us @ CAR.</h2>
        <br/>
        <h2 class="text-center">
          Should your message require support of follow up, we will get<br> back to you as soon as possible.
        </h2>
        <small>
          In the meantime, feel free to login (<a class="text-red" href="https://app.completeautoreports.com/">https://app.completeautoreports.com/</a>) or learn more about our service (<a target="_blank" class="text-red" href="https://www.completeautoreports.com">https://www.completeautoreports.com</a>).
        </small>
      </div>
    </div>
    <!-- end Contact Us Message -->
</div>
