<div class="panel panel-primary" id="mainCompotents">
    <div class="panel-heading padding-left-30">
        <h3 class="panel-title" [innerHTML]="WAKContent.title" ></h3>
        <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
            <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <div class="row" >

            <div class="col-sm-12"  *ngIf="WAKContent && WAKContent.wak_content" [innerHTML]="htmlProperty()"></div>
        </div>
    </div>
</div>




