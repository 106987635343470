import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';

@Component({
  selector: 'app-searchothercustomer',
  templateUrl: './searchothercustomer.component.html',
  styleUrls: ['./searchothercustomer.component.scss']
})
export class SearchothercustomerComponent implements OnInit {

  public customerDetails:any;
  public validatePhone = false;
  public isValidated = false;
  public mobile_phone = '';
  public alternate_phone = '';
  public newCustomer;
  public phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public submitted = false;
  currentUserInfo: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public globalService: GlobalService, private router: Router, private formBuilder: FormBuilder) {
    this.customerDetails = data.customerDetails;
    if(localStorage.getItem("loggedInUser")) {
      this.currentUserInfo = JSON.parse(localStorage.getItem("loggedInUser"));
    }
  }

  ngOnInit() {
      this.newCustomer = this.formBuilder.group(
      {
        mobile_phone: ['', [Validators.required, Validators.minLength(10), this.globalService.mobileNumberValidation]]
      }
    );
    if(this.data.searchBy == "mobile")
    {
      this.isValidated = false;
      this.validatePhone = true;
      this.newCustomer.patchValue({
        'mobile_phone' : ""
      });
      // this.validate();
    }
    if(this.customerDetails.alternate_mobile_number != ""){
        this.alternate_phone = this.customerDetails.alternate_mobile_number;
        this.newCustomer.patchValue({
          'mobile_phone' : this.customerDetails.alternate_mobile_number
        });
        this.isValidated = true;
        if(this.customerDetails.alternate_mobile_number === this.customerDetails.mobile_phone){
          this.validatePhone = true;
        }else{
          this.validatePhone = false;
        }
    }
  }

  validate(){
    this.submitted = true;
    if (!this.newCustomer.valid) {
      return;
    }
    var opr = "user_type="+this.currentUserInfo.user_type +"&user_id="+this.currentUserInfo.id+"&customer_id="+this.customerDetails.customer_id+"&mobile_number=" + this.newCustomer.value.mobile_phone;
    this.globalService.callAPI(globalConstants.API_UPDATE_ALTERNATE_NUMBER, opr).subscribe((data:any) => {
      this.isValidated = true;
      if(data.data.verify_status.toUpperCase() == "VERIFIED"){
        this.customerDetails.mobile_phone = data.data.mobile_phone;
        this.validatePhone = true;
      }else{
        this.validatePhone = false;
        this.customerDetails.mobile_phone = data.data.mobile_phone;
        this.alternate_phone = data.data.alternate_mobile_number;
      }
    })
  }

  updateAlterNumber(){
    this.isValidated = false;
    this.validatePhone = false;
    this.alternate_phone = '';
  }

  skipValidation(){
    var opr = "user_type="+this.currentUserInfo.user_type +"&user_id="+this.currentUserInfo.id+"&customer_id="+this.customerDetails.customer_id+"&mobile_number=";
    this.globalService.callAPI(globalConstants.API_UPDATE_ALTERNATE_NUMBER, opr).subscribe((data) => {
      this.isValidated = true;
    });
  }

  // sendMail(){
  //   this.router.navigate(['shops/customer-approval/', this.customerDetails.customer_id]);    
  // }

  viewVehicle() {
    localStorage.setItem("listCustomerID", this.customerDetails.customer_id);
    this.router.navigate(['shops/list-cars']);
  }
}
