<div mat-dialog-title class="relative">
  Crop Image
  <button mat-mini-fab class="close-modal mat-elevation-z0" (click)="clearCropper()">
    <mat-icon class="notranslate close-icon-lg">close</mat-icon>
  </button>
</div>
<mat-dialog-content id="croppie" class="div-cropper crop-hieght"></mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
  <button mat-raised-button color="accent" class="font-bold" (click)="getCropData()">Save Logo</button>
  <button mat-raised-button class="font-bold" (click)="clearCropper()">Close</button>
</mat-dialog-actions>
