import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Http, Headers, Response } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { globalFunctions } from "../../environments/globalFunctions"
import { Title } from '@angular/platform-browser';
import { ReCaptchaComponent } from 'angular2-recaptcha';
import { globalConstants } from "../../environments/globalConstants"
import { GlobalService } from "../../environments/global.service";
const EMAIL_REGEX = globalConstants.EMAIL_REGEX;
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @ViewChild(ReCaptchaComponent) captcha: ReCaptchaComponent;
  public site_key = globalConstants.GOOGLE_RECAPTCHA_SITE_KEY;
  public submitted: boolean = false;
  public thanksMsg: boolean = false;
  public contactForm: FormGroup;
  public phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  constructor(private formBuilder: FormBuilder, private globalService: GlobalService, private http: Http, private router: Router) {
    this.contactForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
      mobile_phone: ['', [Validators.minLength(10)]],
      message: ['', Validators.required],
      'g-recaptcha-response': ['']
    });
    console.log(Validators.minLength(10));
  }

  ngOnInit() {
  }
  contactUsForm() {
    if (this.contactForm.valid) {
      this.globalService.callAPI(globalConstants.API_CONTACTUS_URL, this.contactForm.value).subscribe((data:any) => {
        if (data.result == "1") {
          this.globalService.snackbar("success", data.message);
          this.thanksMsg = true;
        } else {
          this.globalService.snackbar("error", data.message);
        }
      });
    } else {
      this.submitted = true;
    }
  }
  mobileNumberValidation() {
    let home_phone = this.contactForm.value.mobile_phone;
    if(home_phone !="") {
        let phoneResult = this.contactForm.value.mobile_phone.replace(/\D+/g, "").length >= 10 ? false : {minLength: true};
        if(phoneResult == false) {
            this.contactForm.controls.mobile_phone.setErrors(null);
            this.submitted = false;
        } else {
            this.contactForm.controls.mobile_phone.setErrors({"minLength": true});
        }
    } else {
        this.contactForm.controls.mobile_phone.setErrors(null);
        this.submitted = false;
    }
  }
  checkIfValid() {
    this.mobileNumberValidation();
    if (this.contactForm.valid) {
      this.submitted = false;
    }else{
      this.submitted = true;
    }
  }

  handleReCaptchaResponse(captchaResponse: string) {
    this.contactForm.controls.g_recaptcha_response.setValue(captchaResponse);
  }
}
