import {Component, ElementRef, ViewChild, ViewEncapsulation, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {DataSource, SelectionModel} from '@angular/cdk/collections';
import {Http, Headers, Response} from '@angular/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {globalFunctions} from "../../../../environments/globalFunctions";
import {globalConstants} from "../../../../environments/globalConstants";
import {GlobalService} from "../../../../environments/global.service";
import {HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Title} from '@angular/platform-browser';
import {lang} from "../../../../environments/lang-en";
import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';
import {AppComponent} from '../../../app.component';
import { MatTableDataSource } from '@angular/material/table';
const CAR_HISTORY = 'shop-car-histor-';
@Component({
  selector: 'app-shopcarservicehistory',
  templateUrl: './shopcarservicehistory.component.html',
  styleUrls: ['./shopcarservicehistory.component.scss'],
  animations: [
        trigger('flyInOut', [
            state('in', style({
                transform: 'translateX(0)'
            })),
            transition('void => *', [
                style({transform: 'translateX(500%)'}),
                animate('0.5s ease-in')
            ])
          ])
    ],
  encapsulation: ViewEncapsulation.None
})
export class ShopcarservicehistoryComponent {
    displayedColumns = ['request_id','title', 'customer_name','vehicle', 'appointment_date', 'service_date', 'estimated_delivery_date', 'car', 'status','actions'];
    dataSource: MatTableDataSource<carServiceHistoryData> = new MatTableDataSource();
    public currentCustomer;
    public schedule_service_option
    public requested_delivery_option
    public estimated_delivery_option
    public more_rows;
    public options = {
        user_id: "",
        user_type: "User",
        car_id: "",
        status: "",
        search_keyword: "",
        start: 0 ,
        limit: 10,
    }

    public statuses = ['Appointment', 'Pending', 'Processing', 'Waiting for Approval', '', '', 'Completed', 'Approval Sent', 'Completed And Paid', 'Created', 'Created','','Work in progress'];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    public record :any =[];
    public recordsTotal :any;

    constructor(private location:Location,private app: AppComponent, public router:Router,private http:Http, public titleService: Title,public globalservice:GlobalService){
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
        this.options.user_id = this.currentCustomer.id;
        this.options.car_id= localStorage.getItem("carDetailsVehicleID");
        setTimeout(()=>{
        let isEnable=this.globalservice.isAllowed(this.app,'view_service_history','views');
        if(!isEnable){
          this.router.navigate(['shops/action/forbidden']);
        }
    },globalConstants.RELOAD_WAIT);
    this.globalservice.setMetaData("SHOPS","SERVICE_REQUESTS_HISTORY")
        this.reinitializeOptions();
    }

    getStatus(statusId) {
        return this.statuses[statusId]
    }

    setTouchViewValue(){
        return this.app.touchViewState;
    }

    formatDate(date) {
        if (!new Date(date) || date == null || date == "0000-00-00 00:00:00") { return "-" }
        return (new Date(date).getMonth()+1) + "/" + new Date(date).getDate() + "/" + new Date(date).getFullYear()
    }

    ngOnInit() {
      this.setDataTable();
    }

    search(event) {
        this.setDataTable();
    }

    clearSearch() {
        this.globalservice.localGetterSetter(null,this.options,CAR_HISTORY);
        this.reinitializeOptions();
        this.setDataTable();
    }

    viewPart(getId){
        this.router.navigate(['/customer/view-request/', getId]);
    }

    scrolling(){
        this.globalservice.setScrolling('.car-datatable', 1000, -20);
    }

     paginateByLimit(event) {
        this.scrolling();
        this.options.limit = event.pageSize;
        this.options.start = 0;
        this.setDataTable();
    }

    reinitializeOptions() {
        this.options=this.globalservice.localGetterSetter(this.options,this.options,CAR_HISTORY);
        this.options.user_type= "User";
        this.options.user_id= this.currentCustomer.id;
        this.options.car_id= localStorage.getItem("carDetailsVehicleID");
    }

     getTotalRecords(){
        return parseInt(localStorage.getItem(CAR_HISTORY+'totalRecords'));
    }

    paginate(event,type){
        this.scrolling();
        if(type=="next") {
            this.options.start = this.options.start + this.options.limit;
        } else {
            this.options.start = this.options.start - this.options.limit;
        }
        this.setDataTable();
    }

    firstList() {
        return this.globalservice.firstList(CAR_HISTORY,this.options);
    }

    lastList() {
        return this.globalservice.lastList(CAR_HISTORY);
    }

    getUpperLimit(currentLastLimit, total) {
        if (currentLastLimit > total) {
            return total
        } else {
            return currentLastLimit
        }
    }

    viewServiceRequest(id) {
        localStorage.setItem("status",this.options.status.toString())
        localStorage.setItem("search_keyword",this.options.search_keyword);
        localStorage.setItem("start",this.options.start.toString())
        localStorage.setItem("limit",this.options.limit.toString())
        this.router.navigate(['shops//service-history']);
    }

    /***Check service request*****/
    isAnyOptionActive(button) {
        if (this.options.search_keyword == "" && this.options.status == "" && this.estimated_delivery_option == "" && this.requested_delivery_option == "" && this.schedule_service_option == "" && this.estimated_delivery_option == "") {
            if (button) {button.color = "primary"}
            return false
        }
        else {
            if (button) {button.color = "accent"}
            return true;
        }
    }

    editRequest(id) {
        this.router.navigate(['customer/edit_request/',id])
    }

    viewHistoryDetail(historyId){
        this.router.navigate(["/shops/service-history-view", historyId]);
    }
    setDataTable(){
        this.record = [];
        var content = globalFunctions.stringifyFormData(this.options, null);
        var callApi = this.globalservice.callAPI(globalConstants.API_GET_SERVICE_REQUESTS_HISTROY_URL, content);
        callApi.subscribe((data:any)=>{
            if (data.data != undefined) {

                let moreRows='false';
                let firstList='false';
                this.globalservice.localGetterSetter("totalRecords",data.recordsTotal,CAR_HISTORY);
                if(data.more_rows == "true") {
                    moreRows="true";
                }
                this.globalservice.localGetterSetter("more_rows",moreRows,CAR_HISTORY);
                if (data.start >= 0 && data.start <= 5) {
                    firstList="true";
                }
                this.globalservice.localGetterSetter("first_list",firstList,CAR_HISTORY);

                if (data.data.length != undefined) {
                    this.recordsTotal = data.data.length;
                    for (var i = 0; i < data.data.length; i++) {
                        this.AddRecord(data.data[i])
                    }
                    this.dataSource = new MatTableDataSource(this.record)
                    this.dataSource.sort = this.sort;
                } else {
                    this.recordsTotal = "1";
                    this.AddRecord(data.data)
                    this.dataSource = new MatTableDataSource(this.record)
                    this.dataSource.sort = this.sort;
                }
                this.globalservice.localGetterSetter(null,this.options,CAR_HISTORY);
            } else {
                this.dataSource = new MatTableDataSource(this.record)
                this.globalservice.localGetterSetter("totalRecords",'0',CAR_HISTORY);
            }
        })
    }

    /** its user add the record via using create r */
   AddRecord(record){
      var data = this.createRecord(record);
      this.record.push(data);
    }

   createRecord(record) {
        return {
            vehicle: (record.make ? record.make + " " :"") + record.model,
            customer_name: record.first_name + " " + record.last_name,
            estimated_delivery_date: this.globalservice.getFullFormateDate( record.estimated_delivery_date,'G','H'),
            service_date:  this.globalservice.getFullFormateDate( record.schedule_service_date,'G','H'),
            appointment_date: this.globalservice.getFullFormateDate( record.add_date,'G','H'),
            request_id: record.request_id,
            requested_delivery_date: this.globalservice.getFullFormateDate( record.requested_delivery_date,'G','H' ) ,
            status: record.status,
            title: record.title,
            car: (record.status == '9') ? 'No' : 'Yes',
            id: record.id
        };
    }
    /* STATUS COLOR */
    getRowStatusColor(status) {
        var statusArr = ['active-cell', 'warning-cell', 'info-cell', 'success-cell', 'info-cell', 'success-cell', 'success-completed', 'success-cell', 'success-paid', '', '', '', 'work-progress-cell'];
        return statusArr[status];
    }
}

export interface carServiceHistoryData {
    vehicle: string;
    customer_name: string;
    estimated_delivery_date: string;
    requested_delivery_date:string;
    appointment_date: string;
    service_date: string;
    request_id: string;
    status: string;
    title: string;
    car: string;
    id: string,
}

