import {Component, OnInit, ViewChild,  ViewEncapsulation,OnDestroy} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Http} from '@angular/http';
import {globalFunctions} from "../../../../environments/globalFunctions";
import {globalConstants} from "../../../../environments/globalConstants";
import {GlobalService} from "../../../../environments/global.service";
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';

// import {MatPaginator, MatSort, MatDatepicker} from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {DataSource, SelectionModel} from '@angular/cdk/collections';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';


import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
const SHOP_DISCLAIMER='shop-disclaimer-';
let updateDefault={id:0,data:[]};
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-listshopdisclaimers',
  templateUrl: './listshopdisclaimers.component.html',
  styleUrls: ['./listshopdisclaimers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListshopdisclaimersComponent implements OnInit, OnDestroy {
    public currentCustomer;
    public currentCustomerName;
    public currentCustomerVehicles;
    public isCollapsed: boolean = true;
    public customer_id;
    public result: any;
    displayedColumns = ['title', 'description', 'added_on', 'Is Default', 'Actions'];
    dataSource: MatTableDataSource<disclaimerData> = new MatTableDataSource();

    public more_rows;
    public options = {
        user_type: "User",
        user_id: "",
        start: 0 ,
        limit: 10 ,
    }

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    public subscriptionDataForAPI: Subscription = new Subscription();
    recordsTotal: any;
    record:any = [];
    constructor(public titleService: Title, public globalService: GlobalService, private route: ActivatedRoute, private http: Http, private router: Router) {
        this.globalService.setMetaData("SHOPS","LIST_DISCLAIMER")
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
        this.options.user_id = this.currentCustomer.id;
        this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
        setTimeout(()=>{
        let isEnable=this.globalService.getAcl('disclaimer','enable');
        if(!(isEnable)){
            this.router.navigate(['shops/action/forbidden']);
        }
    },globalConstants.RELOAD_WAIT)
    }

    ngOnInit() {
        //this.titleService.setTitle(lang.TITLE_ADMIN_SHOP_LIST);
        this.reinitializeOptions();
        this.setDataTable();
    }

    sideNavOpen() {

    }
    onResize(event) {
        event.target.innerWidth;
    }

    editDisclaimer(disclaimer_id) {
        this.globalService.localGetterSetter(this.options,null,SHOP_DISCLAIMER);
        localStorage.setItem("shopDisclaimerID", disclaimer_id);
        this.router.navigate(['shops/edit-disclaimer']);
    }

    deleteDisclaimer(disclaimer_id) {
        this.globalService.localGetterSetter(this.options,null,SHOP_DISCLAIMER);
        var stringifiedFormData = "user_type=" + this.currentCustomer.user_type + "&user_id=" + this.currentCustomer.id + "&disclaimer_id=" + disclaimer_id;
        this.globalService.confirmDialog(disclaimer_id, 'Are you sure you want to delete this disclaimer?').subscribe((res) => {
            this.result = res;
            if (this.result != null) {
                this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_DELETE_DISCLAIMER_URL, stringifiedFormData).subscribe((data:any) => {
                    var message = data.message;
                    if (data.result == "1") {
                        this.globalService.onDeleteCheck(SHOP_DISCLAIMER,this.options);
                        this.reinitializeOptions();
                        this.setDataTable();
                        this.globalService.snackbar('success', message);
                    } else {
                        this.globalService.snackbar("error", message);
                    }
                });
            }
        });
    }

    // ## SET DEFAULT SHOP ##
    setDefaultDisclaimer(disclaimer_id) {
        this.globalService.localGetterSetter(this.options,null,SHOP_DISCLAIMER);
        var stringifiedFormData = "user_type=" + this.currentCustomer.user_type + "&user_id=" + this.currentCustomer.id + "&disclaimer_id=" + disclaimer_id;
        this.subscriptionDataForAPI =  this.globalService.callAPI(globalConstants.API_SET_DEFAULT_DISCLAIMER_URL, stringifiedFormData).subscribe((data:any) => {
                var message = data.message;
                if (data.result == "1") {
                    this.globalService.snackbar('success', message);
                    this.reinitializeOptions();
                    updateDefault.id=disclaimer_id;
                    this.setDataTable();
                } else {
                    this.globalService.snackbar("error", message);
                }
            });
    }

    getCurrentRoute() {
        return this.router.url
    }

    scrolling() {
        this.globalService.setScrolling('.car-datatable', 1000, -20);
    }

    paginateByLimit(event){
        this.globalService.setScrolling('body', 600, 0);
        this.options.limit = event.pageSize;
        this.options.start = 0;
        this.scrolling();
        updateDefault.id=0;
        this.setDataTable();
    }


    reinitializeOptions() {
        this.globalService.localGetterSetter(this.options,this.options,SHOP_DISCLAIMER);
        this.options.user_id= this.currentCustomer.id;
        this.options.user_type= this.currentCustomer.user_type;
        updateDefault.id=0;
    }

    getTotalRecords() {
        return parseInt(localStorage.getItem(SHOP_DISCLAIMER+'totalRecords'))
    }

    formatDate(date) {
        if (!new Date(date) || date == null || date == "0000-00-00 00:00:00") { return "--" }
        return (new Date(date).getMonth()+1) + "/" + new Date(date).getDate() + "/" + new Date(date).getFullYear()
    }

   paginate(event,type){
        this.scrolling();
        if(type=="next") {
          this.options.start = this.options.start + this.options.limit;

        } else {
          this.options.start = this.options.start - this.options.limit;

        }
        updateDefault.id=0;
        this.setDataTable();
    }

    firstList() {
        return this.globalService.firstList(SHOP_DISCLAIMER,this.options);
    }

    lastList(){
        return this.globalService.lastList(SHOP_DISCLAIMER);
    }

    getUpperLimit(currentLastLimit,total) {
        if(currentLastLimit > total) {
          return total
        } else {
          return currentLastLimit
        }
    }
    setDataTable(){
        this.record = [];
        var content = globalFunctions.stringifyFormData(this.options, null);
        if(updateDefault.id==0){
        var callApi = this.globalService.callAPI(globalConstants.API_GET_DISCLAIMERS_LIST_URL, content);
        callApi.subscribe((data:any) => {
            if (data.data != undefined) {
                let moreRows='false';
                let firstList='false';
                this.globalService.localGetterSetter("totalRecords",data.recordsTotal,SHOP_DISCLAIMER);
                if(data.more_rows == "true") {
                    moreRows="true";
                }
                this.globalService.localGetterSetter("more_rows",moreRows,SHOP_DISCLAIMER);
                if (data.start >= 0 && data.start <= 5) {
                    firstList="true";
                }
                this.globalService.localGetterSetter("first_list",firstList,SHOP_DISCLAIMER);
                let all=data;
                if( data.data.length!=undefined) {
                    updateDefault.data=all.data;
                    this.recordsTotal = all.data.length;
                    for (var i = 0; i < all.data.length; i++) {
                        this.AddRecord(all.data[i])
                    }
                    this.dataSource = new MatTableDataSource(this.record)
                    this.dataSource.sort = this.sort;
                    this.globalService.localGetterSetter("current-visible",this.recordsTotal,SHOP_DISCLAIMER);
                } else {
                    this.globalService.localGetterSetter("current-visible",'1',SHOP_DISCLAIMER);
                    this.recordsTotal = "1" ;
                    updateDefault.data=[all.data];
                    this.AddRecord(all.data)
                    this.dataSource = new MatTableDataSource(this.record)
                    this.dataSource.sort = this.sort;
                }
                this.globalService.localGetterSetter(null,this.options,SHOP_DISCLAIMER);
            }else {
                this.globalService.localGetterSetter("totalRecords",'0',SHOP_DISCLAIMER);
            }
        })
     }else{
        let all=updateDefault.data;
        for (var i = 0; i < all.length; i++) {
            if(all[i].id==updateDefault.id){
                    all[i].is_default=1;
            }else{
                all[i].is_default=0;
            }
            this.AddRecord(all[i]);
        }
      }
    }

    AddRecord(record) {
        var data = this.createRecordrecord(record);
        this.record.push(data);
    }

    private createRecordrecord(record) {
        return {
            title: record.title,
            description: record.description,
            added_on: this.globalService.getFullFormateDate(record.add_date,'GD','H'),
            is_default: record.is_default,
            disclaimer_id: record.id
        };
    }
    isAllowed(action){
        return this.globalService.getAcl('disclaimer',action);
    }

    
  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
  }  
}

export interface disclaimerData {
    title: any,
    description: any,
    added_on: any,
    is_default: any,
    disclaimer_id: any
}

