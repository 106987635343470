import { Component, OnInit, Input } from '@angular/core';
import {IDatePickerConfig} from 'ng2-date-picker';
import {DatePipe} from '@angular/common';
import {globalConstants} from "../../../../environments/globalConstants"
import {globalFunctions} from "../../../../environments/globalFunctions"
import { GlobalService } from "../../../../environments/global.service";
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { QuickapptComponent } from './../quickappt.component';
import {NavigationEnd, Router} from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-servicedetail',
  templateUrl: './servicedetail.component.html',
  styleUrls: ['./servicedetail.component.scss'],
  providers: [DatePipe]
})
export class ServicedetailComponent implements OnInit {
  @Input() customer: any;
  @Input() seletedCar: any;
  @Input() apptDetails: any;
  @Input() dialogRef: MatDialogRef<QuickapptComponent>;
  
  public newAppt:FormGroup;
  public currentShop:any;
  public distCovered:any;
  public estimateServiceTime:any = globalConstants.SR_ESTIMATED_HOUR;

  public datePickerConfig: IDatePickerConfig = {
    drops: "down",
    format: "MM/DD/YYYY hh:mm A",
    min: this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm a'),
    closeOnSelect:true,
    disableKeypress: true,
    unSelectOnClick:false
  };

  constructor(public gs: GlobalService, private datePipe: DatePipe, private formBuilder: FormBuilder, public router: Router) {
    this.currentShop = JSON.parse(localStorage.getItem("loggedInUser"));
  }

  ngOnInit() {

    this.initForm();
    this.setTimeToDecimal(this.apptDetails.startTime, this.apptDetails.endTime);
    let vehicleMileage = parseInt(this.seletedCar.distance_covered);
    this.distCovered = !isNaN(vehicleMileage) ? vehicleMileage : 0;
    let startDate = this.datePipe.transform(this.apptDetails.startTime, 'MM/dd/yyyy hh:mm a');
    let endDate = this.datePipe.transform(this.apptDetails.endTime, 'MM/dd/yyyy hh:mm a');
    // console.log(this.apptDetails);
    this.newAppt.controls.user_id.setValue(this.currentShop.id);
    this.newAppt.controls.user_type.setValue(this.currentShop.user_type);
    this.newAppt.controls.customer_id.setValue(this.customer.id);
    this.newAppt.controls.vehicle_id.setValue(this.seletedCar.id);
    this.newAppt.controls.event_start_date.setValue(startDate);
    this.newAppt.controls.event_end_date.setValue(endDate);
    this.newAppt.controls.event_type.setValue(this.apptDetails.eventType);
    this.newAppt.controls.request_date.setValue(startDate);
    this.newAppt.controls.est_hours.setValue(this.estimateServiceTime);     
    // this.router.events.subscribe((event) => { 
    //   if (event instanceof NavigationEnd) {
    //       if(event.url.indexOf('shops/service-inspection-report') == 1){
    //         setTimeout(() => {
    //               location.reload();
    //         }, 1000);
    //       }
    //   }
    // });
    
  }

  getEstServiceTime(){
    this.estimateServiceTime
  }

  initForm(){
    this.newAppt = this.formBuilder.group({
      user_id: [''],
      user_type: [''],
      customer_id: [''],
      vehicle_id: [''],
      event_start_date: [''],
      event_end_date: [''],
      event_type: [''],
      request_title: [''],
      est_hours: [''],
      request_notes: [''],
      request_date: [''],
      distance_covered: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      force_submit:0
    });
  }

  onlyNumericKey(event) {
    return globalFunctions.onlyDecimalNumberKey(event);
  }

  addNewAppt(opr = 0){
    let data = Object.assign({},this.newAppt.value);
    data['request_date'] = moment(data['request_date']).format('MM/DD/YYYY hh:mm A Z');
    let formdata = this.gs.formsData(data);
    this.gs.formData(globalConstants.API_QUICKAPPT_CREATION, formdata).subscribe((r) => {
      if (r.result != "1" && 'statusCode' in r) {
        this.gs.confirmDialog('unpaidBalance', r.message,400).subscribe((r) => {
          let res: any = r;
          if (res == 'unpaidBalance') {
              this.newAppt.controls.force_submit.setValue(2);
              setTimeout(() => {
                  this.addNewAppt(opr);
              }, 500);
          }
        });
      }else if(r.result==1){
        this.gs.snackbar("success", r.message);
        this.dialogRef.close(r.data);
        if(opr == 2){
          this.redirectToFillRecomPage(r.data.request_id,"/shops/service-inspection-report/" + r.data.request_id);
        }
      }else{
        this.gs.snackbar("error", r.message);
      }
    }, (error) => { })
  }

  isAllowed(action,module?:any){
    if(typeof module!='undefined'){
        return this.gs.getAcl(module,action);
    }
    return false;
}

  redirectToFillRecomPage(reqId, url) {
   this.router.navigate([url]);
  }

  setTimeToDecimal(startDate:any, endDate:any){
    let now = this.datePipe.transform(startDate, 'dd/MM/yyyy HH:mm:ss');
    let end = this.datePipe.transform(endDate, 'dd/MM/yyyy HH:mm:ss');

    var ms = moment(end,"DD/MM/YYYY HH:mm:ss").diff(moment(now,"DD/MM/YYYY HH:mm:ss"));
    var d = moment.duration(ms);
    var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");

    var hoursMinutes = s.split(/[.:]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    let CalTime = (hours + minutes / 60);
    CalTime !== 0 ? this.estimateServiceTime = CalTime : this.estimateServiceTime = globalConstants.SR_ESTIMATED_HOUR;
 }


}
