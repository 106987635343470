import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../services';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private count: number = 0;
  private ignoreListString: any = [
    'API_notifications.php',
    'API_search_service_requests_list.php',
    'API_get_parts_data.php',
    'API_get_generic_jobs.php',
    'API_get_computer_codes.php',
    'API_get_part_numbers.php',
    'API_partlist_smartbatch_assignment.php',
    'API_get_partstech_parts_data.php',
    'API_set_sr_flag_on_job_board.php',
    'API_modify_recom_locker.php',
    'update_socket_id'
  ]; // semicolon seperated keys
  constructor(private loaderService: LoaderService) { }
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let showLoaderForRedrawJobBoard :any='';
    let reqBodyInfo :any = request.body;
    const APIModuleName: string =
      request.url.split('/')[request.url.split('/').length - 1];
      if(APIModuleName == 'API_get_service_requests_job_board.php'){
             showLoaderForRedrawJobBoard = reqBodyInfo.lastIndexOf("true");
            if(showLoaderForRedrawJobBoard == -1){
                 return next.handle(request);
            }  
      }
    let updateAPIModuleName: string[] = APIModuleName.split('?');
    if (this.ignoreListString.includes(updateAPIModuleName[0])) {
      return next.handle(request);
    }

    if (this.count === 0) {
      this.loaderService.show();
    }

    this.count++;
    return next.handle(request).pipe(
      finalize(() => {
        this.count--;
        if (this.count === 0) {
            this.loaderService.hide();
        }
      })
    );
  }
}
