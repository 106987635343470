import {Component, ViewChild, ChangeDetectorRef, HostListener, OnInit, ViewEncapsulation,ChangeDetectionStrategy ,Compiler} from '@angular/core';
import {Router, ActivatedRoute, ParamMap, NavigationEnd, RoutesRecognized} from '@angular/router';
import {Title} from '@angular/platform-browser';
// import {MatSidenav} from '@angular/material';
// import {NgProgress, NgProgressModule, NgProgressBrowserXhr} from 'ngx-progressbar';
import {Location, LocationStrategy, PathLocationStrategy,} from '@angular/common';
import {GlobalService} from "../../environments/global.service"
import {GoogleAdsComponent} from "../../app/google-ads/google-ads.component"
import {DragulaService} from 'ng2-dragula';
import {globalConstants} from '../../environments/globalConstants'
import {globalFunctions} from "../../environments/globalFunctions"
import {ErrorHandler, Injectable, Injector } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-error-occurred',
  templateUrl: './error-occurred.component.html',
  styleUrls: ['./error-occurred.component.scss']
})
export class ErrorOccurredComponent implements OnInit {

  constructor(public router:Router,public globalService:GlobalService,public app:AppComponent,public location:Location) {
    //console.log(router);
    this.app.showNoNLoggedInRoute = true;
  }

  goBack(){
    let errorPageUrl = localStorage.getItem("error-page-url");
    let navigateTo = errorPageUrl ? "/"+errorPageUrl : "";
    localStorage.clear() 
    if (navigateTo != "") {
      this.router.navigateByUrl(navigateTo)
    } else {
      if(this.app.isAnyOneLoggedin()) {
        parent.history.go(-2)
      } else {
        this.router.navigateByUrl("/customer")
      }
    }
  }

clearCache(){​​​​​​​
    let errorPageUrl = localStorage.getItem("error-page-url");
    let navigateTo = errorPageUrl ? "/"+errorPageUrl : "";
    localStorage.clear() 
    if (navigateTo != "") {
      this.router.navigateByUrl(navigateTo)
    } else {
      if(this.app.isAnyOneLoggedin()) {
        parent.history.go(-2)
      } else {
        this.router.navigateByUrl("/customer")
      }
    }​​​​​​​
    // this.compiler.clearCache(); 
  }​​​​​​​

  ngOnInit() {}

}
