<div mat-dialog-title class="relative">
    {{editExpenseData !== null? 'Edit':'Add'}} Expense
    <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
      <mat-icon class="close-icon-lg">close</mat-icon>
    </button>
</div>
<form class=" add-expenses-form" [formGroup]="addExpenseForm" novalidate (onSubmit)="handleAddExpense()">
<mat-dialog-content class="add-expenses-content">
    <div class="add-expenses-wrap">
            <div class="row margin-bottom-5">
                <label class="col-sm-2 control-label"><strong>Title<span class="asterisk">*</span></strong></label>
                <div class="col-sm-10">
                  <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput type="text" placeholder="Title" formControlName ="title" class="form-control input-lg custom-form-input btn-block" maxlength="200" required />
                    <mat-error *ngIf="addExpenseForm.controls.title.hasError('required')">
                      Title is <strong>required</strong>.
                  </mat-error>
                  </mat-form-field>
                </div>
            </div>
           
            <div class="row margin-bottom-5 category-input">
                <label class="col-sm-2 control-label"><strong>Category<span class="asterisk">*</span></strong></label>
                <div class="col-sm-10 status-input">
                  <mat-form-field class="btn-block car-input status-select-input" floatPlaceholder="never" appearance="fill">
                    <mat-select class="form-control input-lg custom-form-input"  placeholder="Select Category" formControlName="category" required >
                      <mat-option *ngFor="let category of categoriesList;index as i" [value]="category.id">{{category.category_name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="addExpenseForm.controls.category.hasError('required')">
                      Category is <strong>required</strong>.
                  </mat-error>
                  </mat-form-field>
                </div>
            </div>
            <div class="row margin-bottom-5">
                <label class="col-sm-2 control-label"><strong>Amount<span class="asterisk">*</span></strong></label>
                <div class="col-sm-10">
                  <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput type="text" placeholder="Amount" formControlName="amount" class="form-control input-lg custom-form-input btn-block" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event);convertIntoDecimal($event,'amount',7)" maxlength="10" />
                    <mat-error *ngIf="addExpenseForm.controls.amount.hasError('required')">
                      Amount is <strong>required</strong>.
                  </mat-error>
                  <mat-error *ngIf="addExpenseForm.controls.amount.hasError('pattern')">
                    Must be a number with upto 2 decimal places.
                  </mat-error>
                  </mat-form-field>
                </div>
            </div>
            <div class="row margin-bottom-5 datepicker-section">
                <label class="col-sm-2 control-label"><strong>Expense Date<span class="asterisk">*</span></strong></label>
                <div class="col-sm-10">
                  <div class="vlio-datepicker relative">
                      <dp-date-picker mode="day" placeholder="Select Expense Date" formControlName="expense_date" [config]="datePickerConfig" theme="dp-material dp-main" class="btn-block" (open)="handleExpenseDate($event,dayPicker)" #dayPicker
                      (onChange)="handleExpenseDate($event,dayPicker)"></dp-date-picker>
                      <button type="button" class="calendar-icon" mat-icon-button (click)="dayPicker.api.open();">
                        <mat-icon>date_range</mat-icon>
                      </button>
                  </div>
                  <mat-error  class="dp-error" *ngIf="addExpenseForm.controls.expense_date.hasError('required') && addExpenseForm.get('expense_date').touched">
                    Expense date is <strong>required</strong>.
                </mat-error>
                <div class="dp-error"
                  *ngIf="!(addExpenseForm.controls.expense_date.valid) && (addExpenseForm.value.expense_date!='') && (addExpenseForm.value.expense_date!=undefined)">
                  <p>Invalid date.</p>
                </div>
                <div class="dp-error" *ngIf="errorMsgExpenseDate !== ''">
                  {{errorMsgExpenseDate}}
                </div>
                </div>
            </div>   
            <div class="row margin-bottom-15">
              <div class="col-sm-10 col-sm-offset-2">
                  <mat-checkbox formControlName="copy_expense">Copy month on month</mat-checkbox>
              </div>
            </div>
            <div class="row margin-bottom-15">
                <label class="col-sm-2 control-label"><strong>Description</strong></label>
                <div class="col-sm-10 details-input notes-input">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                      <textarea matInput placeholder="Description" rows="5" formControlName="description" rows="2" maxlength="250"></textarea>
                      <mat-hint align="start">Text limit: Maximum 250 characters.</mat-hint>
                    </mat-form-field>
                </div>    
            </div> 
           
    </div>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
    <div class="flexBtns">
      <button type="submit" (click)="handleAddExpense()" mat-raised-button class="font-bold mat-raised-button" color="accent">Submit</button>
      <button mat-raised-button mat-dialog-close class="font-bold border">Close</button>
    </div>
  </mat-dialog-actions>
</form>