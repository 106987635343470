<form class="form-horizontal" autocomplete="off" novalidate [formGroup]="newComputerCode">
    <div mat-dialog-title class="relative">
        Add New Computer Code
        <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
            <mat-icon class="close-icon-lg">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <div class="display-full margin-bottom-5">
            <label class="col-sm-3 control-label"><strong>Trouble Code</strong></label>
            <div class="col-sm-9">
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput type="text" placeholder="Trouble Code" formControlName="trouble_code" class="form-control input-lg custom-form-input btn-block" required/>
                    <mat-error *ngIf="newComputerCode.controls.trouble_code.hasError('required')">
                        Trouble Code is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="display-full margin-bottom-5">
            <label class="col-sm-3 control-label"><strong>Fault Location</strong></label>
            <div class="col-sm-9 details-input notes-input">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <textarea matInput placeholder="Fault Location" formControlName="fault_location" rows="5" required></textarea>
                    <mat-error *ngIf="newComputerCode.controls.fault_location.hasError('required')">
                        Fault Location is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="display-full margin-bottom-5">
          <div class="col-sm-3">&nbsp;</div>
          <div class="col-sm-9">
            <mat-checkbox name="add_to_job" (change)="toggleQuantityField($event)" [(ngModel)]="newComputerCode.value.add_to_job" [ngModelOptions]="{standalone: true}">Add to job</mat-checkbox>
          </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="inquiry-action relative flexBtn3">
        <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
        <button type="button" mat-raised-button class="font-bold" (click)="addComputerCode(1)" color="accent">Submit and Close</button>
        <button type="button" mat-raised-button class="font-bold" (click)="addComputerCode(2)" color="accent">Submit and Add Another</button>
    </mat-dialog-actions>
</form>
