<form class="form-horizontal form-control-normal" autocomplete="off" [formGroup]="labelForm">
  <div mat-dialog-title class="relative">
    Labels
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
      <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
  </div>
  <mat-dialog-content class="customer-detail-popup">
    <div class="center-block" style="max-width: 600px;" *ngIf="isEdit || isAdd">
      <div class="display-full margin-bottom-5">
        <label class="col-sm-3 control-label">
          <strong>Label Name</strong>
        </label>
        <div class="col-sm-9">
          <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
            <input matInput type="text" placeholder="Label Name" class="form-control input-lg custom-form-input btn-block" formControlName="label_name"
              required/>
            <mat-error *ngIf="labelForm.controls.label_name.hasError('required')">
              This field cannot be empty
            </mat-error>
          </mat-form-field>
        </div>
      </div>

    </div>
    <div class="col-sm-12" *ngIf="isEdit==false && isAdd==false">
      <fieldset *ngIf="allLabels.length > 0">
        <div class="table-responsive relative ">
          <table class="table table-striped  margin-bottom-10 " >
            <thead class="table-heading">
              <tr>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let label of allLabels">
                <td>{{label.label_name}}</td>
                <td>
                  <a href="javascript:;" (click)="editLabel(label)">
                    <i class="fa fa-pencil" *ngIf="label.id !=='OTL' && label.id!== 'WAITING_FOR_PARTS' && label.id !== 'ACCOUNTANT_VERIFIED'"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        </fieldset>
        <div class="table-responsive relative " *ngIf="allLabels.length==0">
          <table class="table table-striped table-bordered  margin-bottom-0 " >
            <tbody>
              <tr>
                <td colspan="2" class="norecords-row">No record found</td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="inquiry-action relative">
    <div class="flexBtns">
      <button mat-raised-button color="accent" *ngIf="isAdd==false && isEdit==false" (click)="addLabel(1)" class="font-bold">Add Label</button>
      <button mat-raised-button color="accent" *ngIf="isAdd" (click)="addLabel(2)" class="font-bold">Submit</button>
      <button mat-raised-button color="accent" *ngIf="isEdit" (click)="submitEdit()" class="font-bold">Update</button>

      <button mat-raised-button color="accent" *ngIf="isEdit || isAdd" (click)="cancelAction()" class="font-bold">Cancel</button>
      <button mat-raised-button mat-dialog-close *ngIf="isAdd==false && isEdit==false" class="font-bold">Close</button>
    </div> 
  </mat-dialog-actions>
</form>