import {Component, ViewChild, ChangeDetectorRef, HostListener, OnInit, ViewEncapsulation,ChangeDetectionStrategy} from '@angular/core';
import {Router, ActivatedRoute, ParamMap, NavigationEnd, RoutesRecognized} from '@angular/router';
import {Title} from '@angular/platform-browser';
// import {MatSidenav} from '@angular/material';
// import {NgProgress, NgProgressModule, NgProgressBrowserXhr} from 'ngx-progressbar';
import {Location, LocationStrategy, PathLocationStrategy,} from '@angular/common';
import {GlobalService} from "../../environments/global.service"
import {GoogleAdsComponent} from "../../app/google-ads/google-ads.component"
import {DragulaService} from 'ng2-dragula';
import {globalConstants} from '../../environments/globalConstants'
import {globalFunctions} from "../../environments/globalFunctions"
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { AppComponent } from "../app.component";

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  constructor(public location:Location,public app:AppComponent,public activatedRoute:ActivatedRoute,public router: Router) {
        var whereToGo = this.activatedRoute.snapshot.queryParams.next;
       
        if(whereToGo == "/customer" || decodeURIComponent(whereToGo) == "/customer") {
          this.app.showCommonHeader = true;
          this.app.showNoNLoggedInRoute = true;
          this.router.navigate(["/pages/clogin"] ,{skipLocationChange:true})
        } else if(whereToGo == "/shops") {
          this.app.getCurrentShopSubscription();
          this.app.showCommonHeader = true;
          // this.app.showNoNLoggedInRoute = true;
          this.router.navigate(["/pages/slogin"],{skipLocationChange:true})
        } else if(whereToGo == "/customer/forgot_password" || decodeURIComponent(whereToGo) == "/customer/forgot_password") {
          this.app.showCommonHeader = true;
          this.app.showNoNLoggedInRoute = true;
          
          this.router.navigate(["/pages/cforgot_password"],{skipLocationChange:true})
        } else if(whereToGo == "/customer/register") {
          this.app.showCommonHeader = true;
          this.app.showNoNLoggedInRoute = true;
          this.router.navigate(["pages/cregister"],{skipLocationChange:true})
        } else if(whereToGo == "/admin") {
          this.app.showCommonHeader = true;
          this.app.showNoNLoggedInRoute = true;
          this.router.navigate(["/pages/alogin"],{skipLocationChange:true})
        } else if(whereToGo == "/shops/forgot_password") {
          this.app.showCommonHeader = true;
          this.app.showNoNLoggedInRoute = true;
          this.router.navigate(["pages/sforgot_password"],{skipLocationChange:true})
        } else if(whereToGo == "/shops/reset-password") {
          this.app.showCommonHeader = true;
          this.app.showNoNLoggedInRoute = true;
          this.location.go("/shops/reset-password");
          this.router.navigate(["pages/sreset-password"],{skipLocationChange:true})
        } else if(whereToGo == "/customer/reset-password") {
          this.app.showCommonHeader = true;
          this.app.showNoNLoggedInRoute = true;
          this.location.go("/customer/reset-password")
          this.router.navigate(["pages/creset-password"],{skipLocationChange:true})
        } else if(whereToGo == "/contact-us") {
          this.app.showCommonHeader = true;
          this.app.showNoNLoggedInRoute = true;
          this.router.navigate(["/pcontact"],{skipLocationChange:true})
        }else if(whereToGo == "/confirm-signup") {
          this.app.showCommonHeader = true;
          this.app.showNoNLoggedInRoute = true;
          this.router.navigate(["/confirmsignup"],{skipLocationChange:true})
        } else {
          this.app.showCommonHeader = true;
          this.app.showNoNLoggedInRoute = true;
          this.router.navigate(["/page-not-found"],{skipLocationChange:true})
          
        }
      }

  ngOnInit() {

  }

}
