<div mat-dialog-title class="relative">
  Add to Return Queue
<button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
  <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
</button>
</div>
<mat-dialog-content>
<div class="row">
  <div class="col-sm-12 form-group" *ngIf="showTxt">{{note}}</div>
  <div class="col-sm-6 form-group" *ngIf="addToreturnQue">
    <button mat-raised-button (click)="returnQ()" class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white"
      title="Add to Return Queue" color="accent">Add to Return Queue </button>
  </div>
  <div class="col-sm-6 form-group">
    <button mat-raised-button (click)="stayStock()" class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white"
      title="Stay in Stock" color="accent">Stay in Stock</button>
  </div>
</div>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
  <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
</mat-dialog-actions>
