<div class="panel panel-primary" id="mainCompotents">
  <div class="panel-heading padding-left-15">
    <h3 class="panel-title">Edit Service Request</h3>
    <button mat-mini-fab class="custom-btn-back" title="Back" (click)="globalService.goBack();">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <div class="panel-body bgcolor-lightgrey">
    <form [formGroup]="newRequest" (ngSubmit)="saveServiceRequest()" class="col-xs-12 signup-form request-creation-form" method="POST" enctype="multipart/form-data" autocomplete="off">
      <div class="row">
        <div class="col-md-12">
          <div class="row er-col-width">
            <div class="col-md-4 textarea-label">Select Vehicle:</div>
            <div class="col-md-8 uc-ngx-select details-input">
              <ngx-select class="display-full margin-bottom-20" [items]="serviceVehicles" formControlName="request_car_id"
                placeholder="Select car" [noAutoComplete]="true" optionTextField="name" (select)="selectedVehicle()">
              </ngx-select>
              <div *ngIf="insuranceExpired || registrationExpired" class="insurance-notes">
                <div *ngIf="insuranceExpired">
                  Vehicle Insurance Expired - {{displayInsuranceDate}}
                </div>
                <div *ngIf="registrationExpired">
                  Vehicle Registration Expired - {{displayRegistrationDate}}
                </div>
              </div>
              <p id="expMsgs" class="text-danger insurance-notes" *ngIf="insuranceDefaultNotes">
                <span *ngIf="chkDate(insExpDate)">Vehicle Insurance Expired -{{insExpDate}}<br></span>
                <span *ngIf="chkDate(regExpDate)">Vehicle Registration Expired - {{regExpDate}}<br></span>
              </p>
            </div>
          </div>
          <div class="row er-col-width ">
            <div class="col-md-4 textarea-label">Request Title:</div>
            <div class="col-md-8 details-input">
              <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                <input  matInput placeholder="Request Title" title="Request Title" class="form-control input-lg custom-form-input" maxlength="255" formControlName="request_title"/>
              </mat-form-field>
            </div>
          </div>

          <div class="row er-col-width">
            <div class="col-md-4 textarea-label">Schedule Date:</div>
            <div class="col-md-8  details-input"> 
              <app-date-and-time-picker *ngIf="scheduleDate" [importTime]="scheduleDate" class="date-time-section" (SelectTime)="onDateChange($event)"></app-date-and-time-picker>
            </div>
           </div>
          <div class="row er-col-width">
            <div class="col-md-4 textarea-label">Present Mileage (Miles):</div>
            <div class="col-md-8 details-input">
              <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                <input matInput placeholder="Present Mileage" placeholder="{{mileage}}" title="Present Mileage" maxlength="10" class="form-control input-lg custom-form-input" formControlName="distance_covered" />
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="row requestNotes-section">
            <div class="col-md-4 textarea-label">Request Notes:</div>
            <div class="col-md-8 details-input">
              <mat-form-field class="car-input btn-block textarea-input" floatPlaceholder="never" appearance="fill">
                <textarea matInput placeholder="Request Notes" title="notes" rows="5" maxlength="1000" formControlName="request_notes"></textarea>
              </mat-form-field>
              <p class="tax-note text-red">Customer can see anything you write here<p></p>
            </div>
          </div>
        </div>
        <div class="form-group col-sm-12" id="documentsAndImagesContainer">
         <div class="row margin-0">
          <h4 class="col-md-12"><strong>Documents &amp; Images</strong></h4>
          <div class="col-md-6">
            <label for="fileDocumentUpload" class=" font-bold">Upload Document</label>
            <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative" >
              <mat-icon class="margin-right-10">cloud_upload</mat-icon>
              Drag File Here
              <input multiple type="file" id="addrequest_document" name="request_document" (change)="changeListner($event,'request_document')" />
              <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
            </mat-toolbar>
            <p class="help-block">Format(s) supported : pdf, doc, docx</p>
            <p class="help-block">Maximum size : 10MB</p>
            <p class="help-block bold-text">E.g. : Pdf reports from your scan tool or other relevant documents.</p>
            
            <ul class="list-inline margin-left-0 upload-image">
              <li *ngFor="let doc of requestDoc;let i=index" class="relative view-images">
                <a href="javascript:void(0);" (click)="invokeDownload(globalConstants.S3_BUCKET_URL+'Service-Request-Documents/'+doc.document_name,'ServiceRequestDocument',doc.document_name)" class="img-set thumbnail relative pdf-img">
                  <!-- <img class="show-image img-responsive" src="assets/img/adobe_pdf-logo.jpg" /> -->
                  <span class="fa far fa-file-pdf fa-4x" *ngIf="getFileIcons(doc.document_name)==3"></span>
                  <span class="fa far fa-file-word fa-4x" *ngIf="getFileIcons(doc.document_name)==4"></span>
                  <div class="image-loader">
                    <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                  </div>
                  <i class="fa fa-trash remove-img" (click)="deleteImgDoc(i,doc.id,'document')"></i>
                </a>
              </li>
            </ul>

          </div>
          <div class="col-md-6">
            <label for="fileImageUpload" class="font-bold">Upload Image</label>
            <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative" >
              <mat-icon class="margin-right-10">cloud_upload</mat-icon>
              Drag File Here
              <input multiple type="file" id="addrequest_image" name="request_image" (change)="changeListner($event,'request_image')" />
              <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
            </mat-toolbar>
            <p class="help-block">Format(s) supported : {{imgTypes}}</p>
            <p class="help-block">Maximum size : {{imgSize}}</p>
            <p class="help-block bold-text">E.g. : Picture of instrument cluster, 4 corners of car, vehicle damage, etc. </p>
            <ul class="list-inline margin-left-0 upload-image">
              <li class="img-set relative view-images no-border" *ngFor="let img of requestImages;let i=index">
                <a (click)="imageToolDialog($event,i,8,requestImages)" class="thumbnail" href="javascript:void(0);$event.stopPropagation();">
                  <img class="show-image img-responsive" src="{{requestImgPath}}{{img.image_name}}" />
                  <div class="image-loader">
                    <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                  </div>
                  <i class="fa fa-trash remove-img" (click)="deleteImgDoc(i,img.id,'image');$event.stopPropagation();"></i>
                </a>
              </li>
            </ul>

          </div>
         </div>
        </div>
      </div>
      <div class="row padding-top-15 padding-bottom-15 editRequest-searchJob">
        <div class="col-sm-12">
            <label >Search in jobs:</label>
        </div>
        <div class="col-sm-12 relative">
            <input ngui-auto-complete  (valueChanged) = "getSelectedOptionForx($event)" (click)="carAttribute()"
            [source]="observableSourceForx" [list-formatter]="autocompleListFormatter"
            value-property-name="value"
            placeholder="Search Attribute"
            id="searchCarAattribute"
            class="form-control search-job-input"/>
        </div>
      </div>
      <mat-accordion class="new-request-expension margin-bottom-10 margin-top-10 display-full" displayMode="flat" hideToggle="false">
        <mat-expansion-panel hideToggle="false" class="new-request-expension" [class.hidden]="servicerequest.is_searchable==1" *ngFor="let servicerequest of serviceRequestList;let i = index" id="expansion-{{i+1}}" (opened)="expansionOpen(i+1);">
          <mat-expansion-panel-header [class.hidden]="servicerequest.is_searchable==1" collapsedHeight="42px" expandedHeight="42px" id="expansionHead-{{i+1}}" (click)="expansionOpen(i+1);" data-state="false">
            <span class="margin-right-5">{{ i+1 }}.</span>{{ servicerequest.name }}
          </mat-expansion-panel-header>

          <div *ngFor="let servicerequestChild of servicerequest.children;let j = index">
            <mat-checkbox class="btn-block" labelPosition="before" id="request-check-{{servicerequest.id}}-{{servicerequestChild.id}}" name="request-check-{{servicerequest.id}}-{{servicerequestChild.id}}"  (change)="showOptions(servicerequestChild , $event, i+1);makeJSON($event,servicerequest,servicerequestChild,null,i+1)">
              {{ j+1 }}) {{servicerequestChild.name}}
            </mat-checkbox>

            <div class="padding-left-25 expansion-subchild" *ngIf="servicerequestChild.children" id="second-level-child-{{servicerequestChild.id }}">
              <div *ngFor="let subchild of servicerequestChild.children;let k = index">
                <label *ngIf="subchild.input == 'radio'" class="material-radio padding-right-10">
                  <input type="radio"  value="1" id="requestRadio-{{servicerequest.id}}-{{servicerequestChild.id}}-{{subchild.id}}-input" name="requestRadio-{{servicerequest.id}}-{{servicerequestChild.id}}" (change)="makeJSON($event,servicerequest,servicerequestChild,subchild,i+1)" />
                  <span>{{ subchild.name }}</span>
                </label>
                <!-- <mat-radio-button *ngIf="subchild.input == 'radio'" name="request-{{i}}-{{j}}" value="1" (change)="makeJSON($event,servicerequest,servicerequestChild,subchild,i+1)">{{ subchild.name }}</mat-radio-button> -->
                <mat-checkbox  id="requestid-{{servicerequest.id}}-{{servicerequestChild.id}}-{{subchild.id}}" name="request-{{servicerequest.id}}-{{servicerequestChild.id}}-{{subchild.id}}" class="btn-block" labelPosition="before" *ngIf="subchild.input == 'checkbox'" (change)="makeJSON($event,servicerequest,servicerequestChild,subchild,i+1)">
                  {{subchild.name}}
                </mat-checkbox>

                <mat-form-field class="car-input btn-block" floatPlaceholder="never" *ngIf="subchild.input == 'textarea'" appearance="fill">
                  <textarea  matInput id="{{servicerequest.id}}-{{servicerequestChild.id}}"  placeholder="{{ subchild.name }}" title="{{ subchild.name }}" rows="4"  (keyup)="makeJSON($event,servicerequest,servicerequestChild,subchild,i+1)" value="{{getTextValue(servicerequest,servicerequestChild,subchild,'text')}}"></textarea>
                </mat-form-field>

                <!-- <input type="file" id="{{subchild.id}}" name="{{ subchild.name }}" *ngIf="subchild.input == 'imageupload'" (change)="makeJSON($event,servicerequest,servicerequestChild,subchild,i+1)"> -->
                <div *ngIf="subchild.input == 'imageupload'">
                <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative" >
                  <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                  Drag File Here
                  <input type="file" id="{{subchild.id}}jobs" name="{{ subchild.name }}" (change)="makeJSON($event,servicerequest,servicerequestChild,subchild,i+1)" />
                  <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                </mat-toolbar>
                <p class="help-block">Format(s) supported : {{imgTypes}}</p>
                <p class="help-block">Maximum size : {{imgSize}}</p>
                <ul class="list-inline margin-left-0 upload-image" *ngIf="subchild.input == 'imageupload'">
                  <li class="relative view-images" *ngIf="getTextValue(servicerequest,servicerequestChild,subchild,'image') && jobImages==''" >
                    <a (click)="imageToolDialog($event,0,9,[{value : getTextValue(servicerequest,servicerequestChild,subchild,'image')}]);$event.stopPropagation();" class="img-set thumbnail" href="javascript:void(0);$event.stopPropagation();">
                      <img class="show-image" src="{{jobImg}}{{getTextValue(servicerequest,servicerequestChild,subchild,'image')}}?r={{random}}" id="other_image" />
                      <i class="fa fa-trash remove-img" (click)="deleteImgDoc($event,subchild.id,'job_image');$event.stopPropagation();"></i>
                    </a>
                  </li>
                  <li class="img-set thumbnail relative" *ngIf="jobImages" >
                    <img class="show-image img-responsive" src="{{jobImages}}" />
                    <i class="fa fa-trash remove-img" (click)="deleteJobImg(subchild);$event.stopPropagation();"></i>
                  </li>
                </ul>
              </div>
              </div>

            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <div class="display-full text-right">
        <button name="new_service_notify" type="submit" class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white notify-btn" color="accent" mat-raised-button title="Notify Customer">
          Notify Customer
        </button>
      </div>
    </form>
  </div>
</div>
