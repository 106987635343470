<div mat-dialog-title class="relative">
  Emission Inspection
  <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
      <mat-icon class="close-icon-lg">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="emission-content">
  <div class="form-horizontal col-xs-12 padding-bottom-25" autocomplete="off">
   <form class="row form-horizontal" *ngIf="status" (ngSubmit)="addInspection()" autocomplete="off" [formGroup]="report" method="POST" enctype="multipart/form-data" autocomplete="off" >
      <div class="form-group">
          <label for="Date" class="col-sm-3 control-label"><strong>Date</strong></label>
          <div class="col-sm-9 details-input">
              <mat-form-field class="car-input btn-block car-datePicker" floatPlaceholder="never" appearance="fill">
                  <input matInput formControlName="performDate" [max]="maxDate" class="form-control input-lg custom-form-input" placeholder="Choose a date" [matDatepicker]="picker1" (focus)="picker1.open()">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
          </div>
      </div>
      <div class="form-group">
          <label for="Photo" class="col-sm-3 control-label"><strong>Photo</strong></label>
          <div class="col-sm-9">
              <div class="upload-image" id="upload-img">
                  <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative">
                      <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                      Drag File Here
                      <input type="file" id="addvehicle_pic"  name="vehicle_pic" (change)="getFiles($event)" formControlName="vehicle_pic"/>
                      <button mat-raised-button color="accent" class="pull-right">Browse</button>
                  </mat-toolbar>
                  <div class="note">
                      <p class="help-block">Format(s) supported : {{emissionImgTypes}}</p>
                      <p class="help-block">Maximum size : {{emissionMaxAllowedSize}}</p>
                  </div>
                  <div class="thumbnail"  *ngIf="carImage!=''">
                         <img src="{{carImage}}"/>
                          <button class="btn btn-danger btn-remove-business-logo" (click)="deletePartPic()" type="button">
                                  <i class="fa fa-trash"></i>
                              </button>
                  </div>
              </div>
          </div>
      </div>
      <div class="form-group">
          <label class="col-sm-3 control-label">&nbsp;</label>
          <div class="col-sm-9 margin-top-15">
              <button type="button" (click)="addInspection()" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white btn-submit" color="accent" title="Submit">Submit</button>
          </div>
      </div>
   </form>
  </div>
  <p class="display-full">Previous emission inspection done for this vehicle:</p>
  <table class="table table-striped table-hover table-condensed sortable" id="paymentsList">
      <thead>
          <tr>
              <th>#</th>
              <th>Inspection Perform Date</th>
              <th>Request ID</th>
              <th>Photo</th>
          </tr>
      </thead>
      <tbody id="paymentsListView">
          <tr *ngFor="let data of dataSources;let i=index">
              <td>{{i+1}}</td>
              <td>{{data.perform_date}}</td>
              <td><a href="javascript:;" (click)="openInNewTab(data.request_id)" > {{data.request_number}}</a></td>
              <td>
                  <a (click)="imageToolDialog($event,0,10,[dataSources[i]])" class="thumbnail-emission" href="javascript:void(0);">
                      <img *ngIf="data.pic!=''" src="{{picPath}}{{data.pic}}" width="100" />
                  </a>
              </td>
          </tr>
          <tr *ngIf="dataSources.length==0"><td colspan="4" class="norecords-row">No Records Found.</td></tr>
      </tbody>
      <tfoot></tfoot></table>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
  <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
</mat-dialog-actions>
