<div class="panel panel-primary margin-0" id="mainCompotents">
    <div class="panel-heading padding-left-15 business-wrapper">
        <div class="row">
            <div class="col-xs-7">
                <h3 class="panel-title">
                    Advanced Report
                </h3>
            </div>
            <div class="col-xs-5">
                <div class="text-right">
                    <button class="settingsIcon text-white fa fa-download" title="Export Report" (click)="exportInPDF()" type="button"></button>
                    <button class="settingsIcon text-white fa fa-times-circle" title="Excluded Vehicle List" type="button"
                        (click)="excludedVehicleSideBar = true">
                    </button>
                    <button [matMenuTriggerFor]="menu" class="settingsIcon text-white" title="Switch Reports"><i class="fa fa-cog"></i></button>
                    <mat-menu #menu="matMenu" class="mat-dropdown-menu business-metrics" [overlapTrigger]="false" xPosition="before">
                        <button mat-menu-item>Advanced Report</button>
                        <button mat-menu-item routerLink="../business-metrics">Business Metrics</button>
                        <button mat-menu-item routerLink="../../reports-stats-servicerequests">Sales Report</button>    
                    </mat-menu>
                    <button class="settingsIcon text-white fa fa-filter" title="Filters" type="button"
                        (click)="searchFilterSideBar = true">
                    </button>
                </div>
            </div>
       </div>
    </div>
    <div class="panel-body white advance-report">
        <div class="bgcolor-white" style="width:100%; padding:0 !important;">
            <table class="table advance-report-table margin-0" *ngIf="reportData.records">
                <thead>
                    <tr class="advance-report-header">
                        <th></th>
                        <th>Units</th>
                        <th>Labor</th>
                        <th>Parts</th>
                        <th>Parts Cost</th>
                        <th>Net Sales </th>
                        <th>Cost </th>
                        <th>
                            <div class="header-part">
                                GPM% 
                               <span class="tooltip-block"> 
                                    <i matTooltip="(Net Sales - Cost / Net Sales) * 100" mat-tooltip-panel-above class="tooltip-status fa fa-question" aria-hidden="true"></i>
                               </span>
                            </div>
                        </th>
                        <th>
                            <div class="header-part">
                                COGS
                                <span class="tooltip-block">
                                    <i matTooltip="Cost/Net Sales" mat-tooltip-panel-above class="tooltip-status fa fa-question" aria-hidden="true"></i>
                                </span>
                            </div>
                        </th>
                        <th>Unit Avg </th>
                    </tr>
                </thead>
                <tbody role="rowgroup" class="accessories-tabel" *ngFor="let record of reportData.records">
                   <!-- Accessories block start -->
                   <tr class="list-row">
                       <th colspan="4" role="row" class="accessories-row" *ngIf="record.parent_id == 0"><b>{{record.name}}</b></th>
                       <th colspan="4" role="row" class="accessories-row" *ngIf="record.parent_id != 0"><b>{{record.parent_name}} >> {{record.name}}</b></th>
                       <td colspan="6" role="row"  class="accessories-row"></td>
                       
                   </tr>
                    <tr role="row" *ngFor="let job of record.jobs">
                        <th class="padding-left-20">{{job.name}}</th>
                        <td>{{job.unit}} </td>
                        <td>{{job.labor | currency:'USD':'symbol-narrow'}}</td>
                        <td>{{job.parts | currency:'USD':'symbol-narrow'}}</td>
                        <td>{{job.part_shop | currency:'USD':'symbol-narrow'}}</td>
                        <td>{{job.net_sales | currency:'USD':'symbol-narrow'}}</td>
                        <td>{{job.cost | currency:'USD':'symbol-narrow'}}</td>
                        <td>{{job.gpm}}</td>
                        <td>{{job.cogs}}</td>
                        <td colspan="2">{{job.unit_avg | currency:'USD':'symbol-narrow'}}</td>
                    </tr>
                    <tr role="row">
                        <th class="padding-left-20"><b>Totals</b></th>
                        <td><b>{{getTotalByUnits(record.jobs,'unit')}} </b></td>
                        <td><b>{{getTotalByField(record.jobs,'labor') | currency:'USD':'symbol-narrow'}}</b></td>
                        <td><b>{{getTotalByField(record.jobs,'parts') | currency:'USD':'symbol-narrow'}}</b></td>
                        <td><b>{{getTotalByField(record.jobs,'part_shop') | currency:'USD':'symbol-narrow'}}</b></td>
                        <td><b>{{getTotalByField(record.jobs,'net_sales') | currency:'USD':'symbol-narrow'}}</b></td>
                        <td><b>{{getTotalByField(record.jobs,'cost') | currency:'USD':'symbol-narrow'}}</b></td>
                        <td><b>{{getAverage(record.jobs,'gpm')}}</b></td>
                        <td><b>{{getAverage(record.jobs,'cogs')}}</b></td>
                        <td colspan="2"><b>{{getAverage(record.jobs,'unit_avg') | currency:'USD':'symbol-narrow'}}</b></td>
                    </tr>
                    <tr class="last-row-jobs"> 
                        <th class="padding-left-20"><b>Jobs: </b></th>
                        <td class="overall-jobs"><b>{{record.jobs.length}}</b></td>
                        <td colspan="2" class="total-text-in-desktop"><b>Average Job Net Sale:</b></td>
                        <td class="overall-jobs"><b>{{totalNetSales(record.jobs) | currency:'USD':'symbol-narrow'}}</b></td>
                        <td></td>
                        <td></td>
                        <td class="total-text-in-desktop"><b>Alt. GPM%:</b></td>
                        <td class="overall-jobs"><b>{{totalGPM(record.jobs)}}</b></td>
                        <td></td>
                    </tr>
                </tbody>
                <!-- Grand total row start -->
                <tbody role="rowgroup" class="accessories-tabel" style="border-top: 2px dotted;" *ngIf="reportData.records.length > 0">
                    <tr *ngIf="reportData.grand_total"> 
                        <th><b>Grand Totals:</b></th>
                        <td><b>{{reportData.grand_total.units}}</b></td>
                        <td><b>{{reportData.grand_total.labor | currency:'USD':'symbol-narrow'}}</b></td>
                        <td><b>{{reportData.grand_total.parts | currency:'USD':'symbol-narrow'}}</b></td>
                        <td><b>{{reportData.grand_total.part_shop | currency:'USD':'symbol-narrow'}}</b></td>
                        <td><b>{{reportData.grand_total.net_sales | currency:'USD':'symbol-narrow'}}</b></td>
                        <td><b>{{reportData.grand_total.cost | currency:'USD':'symbol-narrow'}}</b></td>
                        <td><b>{{reportData.grand_total.gpm}}</b></td>
                        <td><b>{{reportData.grand_total.cogs}}</b></td>
                        <td colspan="2"><b>{{reportData.grand_total.unit_avg | currency:'USD':'symbol-narrow'}}</b></td>
                    </tr>
                    <tr class="last-row-jobs" *ngIf="reportData.grand_total_job"> 
                        <th class=""><b>Grand Total Jobs: </b></th>
                        <td class="overall-jobs"><b>{{reportData.grand_total_job.jobs}}</b></td>
                        <td colspan="2" class="total-text-in-desktop"><b>Average Job Net Sale:</b></td>
                        <td class="overall-jobs"><b>{{reportData.grand_total_job.unit_avg | currency:'USD':'symbol-narrow'}}</b></td>
                        <td></td>
                        <td></td>
                        <td class="total-text-in-desktop"><b>Alt. GPM%:</b></td>
                        <td class="overall-jobs"><b>{{reportData.grand_total_job.alt_gpm}}</b></td>
                        <td></td>
                    </tr>
                 </tbody>
                 <tbody role="rowgroup" class="accessories-tabel" *ngIf="reportData.records.length === 0">
                    <tr class="last-row-jobs">
                        <td class="text-center" colspan="10">No Records Available</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<app-excluded-vehicles [excludedVehicles]="excludedVehicles"  (closeTab)="excludedOut($event)" *ngIf="excludedVehicleSideBar"></app-excluded-vehicles>
<app-add-excluded-vehicles [excludeVehicleList]="excludedVehicles" (closeTab)="addexcludedOut($event)" *ngIf="addExcludedVehicleSideBar"></app-add-excluded-vehicles>
<app-search-filter [data]="options" [type]="'advance-report'" (closeTab)="searchFilterOutput($event)" *ngIf="searchFilterSideBar"></app-search-filter>