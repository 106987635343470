import { Component, OnInit , ViewChild,Inject,ElementRef,AfterViewInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { globalConstants } from '../../environments/globalConstants';
import { GlobalService } from '../../environments/global.service';
import { globalFunctions } from '../../environments/globalFunctions';
import {Http, Headers,RequestOptions, Response} from '@angular/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Router} from '@angular/router';
declare var jQuery:any;
declare var $ :any;
@Component({
  selector: 'app-imagetooldialog',
  templateUrl: './imagetooldialog.component.html',
  styleUrls: ['./imagetooldialog.component.scss']
})
export class ImagetooldialogComponent implements AfterViewInit {
  public imagecanvas:any;
  public tempimg:any;
  public imageData :any;
  public cnvMaxWidth = 10000;
  public cnvMaxHeight = 9000;
  public currangle = 0;
  public index:any;
  public imgType:any;
  public imgURL;
  public imagesArr :any;
  public len :any;
  public init = 0;
  public parentContainer:any;
  public context:CanvasRenderingContext2D;
  public imageCanvasObject:any;
  public globalConstants=globalConstants;
  public archURL='';
  @ViewChild('imageCanvas') imageCanvas: ElementRef;
  constructor( private el:ElementRef,@Inject(MAT_DIALOG_DATA) public data: any, public router: Router,private http: Http, public dialogRef: MatDialogRef<ImagetooldialogComponent>, private formBuilder: FormBuilder, private globalService: GlobalService) {

    this.imageData=data;

    this.imgType=  this.imageData.imgType;
    dialogRef.afterClosed().subscribe(result => {
        this.clearCanvas();
        console.log('clearing canvas');
    });
    this.globalService.diaLogObj(this.dialogRef);
  }
  ngAfterViewInit(){
    this.imageCanvasObject = this.imageCanvas.nativeElement;
    var rotate = 'rotate(' + this.init + 'deg)';
    var canvas: any = this.imageCanvasObject;
     this.context = canvas.getContext("2d");

     this.imageInit();
  }
  ngOnInit() {
  }
  imageInit(){
    this.tempimg = document.createElement('img');
    this.tempimg.crossOrigin = "Anonymous";
    var canvasObject: any = this.imageCanvasObject;
    /**
     * Bind onload event to image that setups the image dimensions
     * and equates them with canvas dimensions,setup css props
     * accordingly for canvas.
     */
     //console.log($('.cdk-overlay-container .mat-dialog-container .mat-dialog-content ').find('#viewLargeImageModal').addClass('uttam'));
      this.index = this.imageData.index;
      this.imgURL;
      this.imagesArr = this.imageData.imgArray;
      var tempImg = this.tempimg;
      var imageCanvasContext = this.context;
      var pageContext = this;
    // var index = this.imageData.index;
    // var imgType=this.imageData.imgType;
    if(this.imageData.ArchUrl){
        this.archURL=globalConstants.DATA_ARCHIVE;
    }
    if(this.imgType == '5' || this.imgType == '7'){
      var tempArr = [];
      tempArr.push(this.imagesArr);
      this.imagesArr = tempArr;
    }
    this.len = this.imagesArr.length;
    // console.log("index",this.index);

    // console.log("array length",this.len);
    tempImg.onload = function() {
        pageContext.imageResize(tempImg);
        canvasObject.width = tempImg.width;
        canvasObject.height = tempImg.height;
        jQuery(canvasObject).css({
            'width': tempImg.width,
            'height': tempImg.height
        });
        jQuery(canvasObject).parents('.image-content').css({
            'text-align': 'center',
            'width':'100%'
        });
        jQuery(canvasObject).parents('#viewLargeImageModal').find(".image-body").css({
            // 'min-height': canvasObject.height + 60
        });
        // console.log(tempImg);
        // console.log(canvasObject);
        // console.log(imageCanvasContext);
        imageCanvasContext.drawImage(tempImg, 0, 0,canvasObject.width,canvasObject.height);
    }
    this.viewLargeImageModal(this);
  }

  viewLargeImageModal(pageObject){
    var tempImg = pageObject.tempimg;
    // console.log('tempImg=>',this.len);
    //console.log(this.imagesArr);
    if (this.len > 0) {
        for (var i = 0; i < this.len; i++) {
            if (this.index == i) {
                if (this.imgType == 1) {
                    this.imgURL =this.globalConstants.S3_BUCKET_URL+this.archURL+this.globalConstants.VEHICLE_PICTURES+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].vehicle_pic + '?' + new Date().getTime();

                } else if (this.imgType == 2) {
                    this.imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_INSURANCE+this.globalConstants.IMG_ORIGINAL + this.imagesArr[i].vehicle_insured_pic + '?' + new Date().getTime();

                } else if (this.imgType == 3) {
                    this.imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_REGISTRATION_PIC+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].vehicle_registered_pic + '?' + new Date().getTime();
                } else if (this.imgType == 5) {
                    this.imgURL = this.globalConstants.S3_BUCKET_URL+this.archURL+this.globalConstants.VEHICLE_VIN_PIC+this.globalConstants.IMG_ORIGINAL+ this.imagesArr + '?' + new Date().getTime();
                } else if (this.imgType == 7) {
                    this.imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_COMPLAINT_PIC+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].complaint_pic + '?' + new Date().getTime();
                } else if (this.imgType == 4) {
                    this.imgURL = this.globalConstants.S3_BUCKET_URL+this.archURL+this.globalConstants.RECOMMEDATION_IMAGE+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].filename + '?' + new Date().getTime();
                } else if (this.imgType == 8) {
                    this.imgURL = this.globalConstants.S3_BUCKET_URL+this.archURL+this.globalConstants.SERVICE_REQUEST_PICTURES+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].image_name + '?' + new Date().getTime();
                } else if (this.imgType == 9) {
                    this.imgURL = this.globalConstants.S3_BUCKET_URL+this.archURL+this.globalConstants.SERVICE_PICTURE+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].value + '?' + new Date().getTime();
                } else if (this.imgType == 10) {
                    this.imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.EMISSION_INSPECTION_PIC+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].pic + '?' + new Date().getTime();
                } else if (this.imgType == 11) {
                    this.imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.EMP_DOCUMENT+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].src + '?' + new Date().getTime();
                } else if (this.imgType == 12) {
                    this.imgURL = this.globalConstants.S3_BUCKET_URL+globalConstants.INTERNAL_NOTE_DOC+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i] + '?' + new Date().getTime();
                } else if (this.imgType == 13) {
                    this.imgURL = this.globalConstants.S3_BUCKET_URL+globalConstants.VEHICLE_LABEL_PICTURES+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].label_pic + '?' + new Date().getTime();
                }else if (this.imgType == 14) {
                    this.imgURL = this.globalConstants.S3_BUCKET_URL+globalConstants.EMP_LEAVE_DOCUMENT+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].filename + '?' + new Date().getTime();
                }
                // console.log('imageUrl',this.imgURL);
                this.http.request(this.imgURL,)
                jQuery('#imageShow').attr('src', this.imgURL);
                tempImg.setAttribute('src', this.imgURL);
                if(this.len == 1){
                    $(".left ").css('color','grey');
                    $(".right").css('color','grey');
                }else if(this.index == 0){
                    $(".left ").css('color','grey');
                    $(".right").css('color','black');
                }else if(this.index == this.len-1){
                    $(".left ").css('color','black');
                    $(".right").css('color','grey');
                }else{
                    $(".left").css('color','black');
                    $(".right").css('color','black');
                }
            }
        }
    }
}
// image resize
imageResize(tempimg){
    if(tempimg.width > this.cnvMaxWidth){
        tempimg.width = tempimg.width-(tempimg.width-this.cnvMaxWidth);
        tempimg.height = tempimg.height-((tempimg.width-this.cnvMaxWidth)/2);
    }if(tempimg.height > this.cnvMaxHeight){
        tempimg.height = tempimg.height-(tempimg.height-this.cnvMaxHeight);
        tempimg.width = tempimg.width-((tempimg.height-this.cnvMaxHeight)/2);
    }
}
// viewPreviousImage
viewPreviousImage() {
    var imgURL;
    var len = this.len;
    var prevIndex = Number(this.index) - 1;
    this.currangle = 0;
    if (len > 0 && prevIndex >= 0) {
        for (var i = 0; i < len; i++) {
            if (prevIndex == i) {
            if (this.imgType == 1) {
                imgURL =this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_PICTURES+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].vehicle_pic + '?' + new Date().getTime();
            } else if (this.imgType == 2) {
                imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_INSURANCE+this.globalConstants.IMG_ORIGINAL + this.imagesArr[i].vehicle_insured_pic + '?' + new Date().getTime();
            } else if (this.imgType == 3) {
                imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_REGISTRATION_PIC+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].vehicle_registered_pic + '?' + new Date().getTime();
            } else if (this.imgType == 5) {
                imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_VIN_PIC+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i] + '?' + new Date().getTime();
            }else if (this.imgType == 7) {
                imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_COMPLAINT_PIC+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].complaint_pic + '?' + new Date().getTime();
            } else if (this.imgType == 4) {
                imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.RECOMMEDATION_IMAGE+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].filename + '?' + new Date().getTime();
            } else if (this.imgType == 8) {
                imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.SERVICE_REQUEST_PICTURES+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].image_name + '?' + new Date().getTime();
            } else if (this.imgType == 9) {
                imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.SERVICE_PICTURE+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].value + '?' + new Date().getTime();
            } else if (this.imgType == 10) {
                imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.EMISSION_INSPECTION_PIC+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].pic + '?' + new Date().getTime();
            } else if (this.imgType == 11) {
                imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.EMP_DOCUMENT+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].src + '?' + new Date().getTime();
            }else if (this.imgType == 13) {
                imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_LABEL_PICTURES+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].label_pic + '?' + new Date().getTime();
            }else if (this.imgType == 14) {
                imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.EMP_LEAVE_DOCUMENT+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].filename + '?' + new Date().getTime();
            }
                $('#imageShow').attr('src', imgURL);
                /**
                 * Clear the canvas and reset the current angle to 0,
                 * afterwards set the previous image.
                 */
                this.clearCanvas();
                this.currangle = 0;
                this.tempimg.setAttribute('src', imgURL);
                this.index = prevIndex;
                if (len > 1) {
                    if (prevIndex <= 0) {
                        $(".left").css('color', 'grey');
                        $(".right").css('color', 'black');
                    } else {
                        $(".left").css('color', 'black');
                        $(".right").css('color', 'black');
                    }
                }
                // break;
            }
        }
    }
}
// viewNextImage
viewNextImage() {
    var imgURL;
    var len = this.len;
    var nextIndex = Number(this.index) + 1;
    this.currangle = 0;
    if (len > 0 && nextIndex <= len) {
        for (var i = 0; i < len; i++) {
            if (nextIndex == i) {
            if (this.imgType == 1) {
                imgURL =this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_PICTURES+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].vehicle_pic + '?' + new Date().getTime();
            } else if (this.imgType == 2) {
                imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_INSURANCE+this.globalConstants.IMG_ORIGINAL + this.imagesArr[i].vehicle_insured_pic + '?' + new Date().getTime();
            } else if (this.imgType == 3) {
                imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_REGISTRATION_PIC+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].vehicle_registered_pic + '?' + new Date().getTime();
            } else if (this.imgType == 5) {
                imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_VIN_PIC+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i] + '?' + new Date().getTime();
            }else if (this.imgType == 7) {
                imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_COMPLAINT_PIC+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].complaint_pic+ '?' + new Date().getTime();
            } else if (this.imgType == 4) {
                imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.RECOMMEDATION_IMAGE+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].filename + '?' + new Date().getTime();
            } else if (this.imgType == 8) {
                imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.SERVICE_REQUEST_PICTURES+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].image_name + '?' + new Date().getTime();
            } else if (this.imgType == 9) {
                imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.SERVICE_PICTURE+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].value + '?' + new Date().getTime();
            } else if (this.imgType == 10) {
                imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.EMISSION_INSPECTION_PIC+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].pic + '?' + new Date().getTime();
            } else if (this.imgType == 11) {
                imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.EMP_DOCUMENT+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].src + '?' + new Date().getTime();
            }else if (this.imgType == 13) {
                imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_LABEL_PICTURES+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].label_pic + '?' + new Date().getTime();
            }else if (this.imgType == 14) {
                imgURL = this.globalConstants.S3_BUCKET_URL+this.globalConstants.EMP_LEAVE_DOCUMENT+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[i].filename + '?' + new Date().getTime();
            }
                $('#imageShow').attr('src', imgURL);
                /**
                 * clear canvas, reset current angle and then
                 * load the next image.
                 */
                this.clearCanvas();
                this.currangle = 0;
                this.tempimg.setAttribute('src', imgURL);
                this.index = nextIndex;
                if (len > 1) {
                    if (nextIndex >= len - 1) {
                        $(".right").css('color', 'grey');
                        $(".left").css('color', 'black');
                    } else {
                        $(".left").css('color', 'black');
                        $(".right").css('color', 'black');
                    }
                }
                // break;
            }
        }
    }
}
  imageRotation(direction) {
    var angle;
    //set the angle of rotation which spans from 0 to 360.
    if (direction == 'left') {
        if (this.currangle == 0) {
            angle = 270;
        } else {
            angle = this.currangle - 90;
        }
        this.init -= 90;
    } else {
        if (this.currangle == 360) {
            angle = 90;
        } else {
            angle = this.currangle + 90;
        }
        this.init += 90;
    }
    //update global variable for angle that is currangle.
    this.currangle = angle;
    var rotate = 'rotate(' + this.init + 'deg)';
    $('#imageShow').parents('.image-body').css({
        '-webkit-transform': rotate,
        '-moz-transform': rotate,
        '-o-transform': rotate,
        '-ms-transform': rotate,
        'transform': rotate,
        'transition':'all 0.7s ease'
        // 'transform-origin':'50% 50%'
    });
    /**
     * adjust the dimensions of canvas aiming for vertical or
     * horizontal layout based on next orientation of image
     * specified by angle of rotation.
     */
    if (angle == 90 || angle == 270) {
        this.imageCanvasObject.width = this.tempimg.height;
        this.imageCanvasObject.height = this.tempimg.width;
    } else {
        this.imageCanvasObject.width = this.tempimg.width;
        this.imageCanvasObject.height = this.tempimg.height;
    }
    $(this.imageCanvasObject).css({
        'width': this.imageCanvasObject.width,
        'height': this.imageCanvasObject.height
    });
    $(this.imageCanvasObject).parents('.modal-dialog').css({
        'text-align': 'center'
    });
    $(this.imageCanvasObject).parents('#viewLargeImageModal').find(".modal-backdrop").css({
        'min-height': this.imageCanvasObject.height + 60
    });
    var cnvcontext = this.imageCanvasObject.getContext('2d');
    /*
     * save the current context so that we can put the original
     * coordinate system back, move image to center of canvas,
     * rotate the image, move image back to horizontal/vertical
     * origin and finally restore context.
     */
    cnvcontext.save();
    cnvcontext.translate(this.imageCanvasObject.width / 2, this.imageCanvasObject.height / 2);
    cnvcontext.rotate(angle * Math.PI / 180);
    //cnvcontext.drawImage(tempimg,-(cnvheight/2),-(cnvwidth/2),cnvheight,cnvwidth);
    if (angle == 90 || angle == 270) {
        cnvcontext.drawImage(this.tempimg, -(this.imageCanvasObject.height / 2), -(this.imageCanvasObject.width / 2), this.imageCanvasObject.height, this.imageCanvasObject.width);
    } else {
        cnvcontext.drawImage(this.tempimg, -(this.imageCanvasObject.width / 2), -(this.imageCanvasObject.height / 2), this.imageCanvasObject.width, this.imageCanvasObject.height);
    }
    cnvcontext.restore();
  }

  clearCanvas(){
      this.imageCanvasObject.getContext('2d').clearRect(0,0,this.imageCanvasObject.width,this.imageCanvasObject.height);
      // $(tempimg).removeAttr('height');
      // $(tempimg).removeAttr('width');
      this.currangle = 0;
      this.init = 0;
      $('#imageShow').parents('.image-body').css({
          '-webkit-transform': '',
          '-moz-transform': '',
          '-o-transform': '',
          '-ms-transform': '',
          'transform': '',
          'transition':'all 0s ease'
      });
  }
   saveRotatedImage() {
    var imgurl;
    if (this.imgType == 1) {
        imgurl =this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_PICTURES+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[this.index].vehicle_pic;
    } else if (this.imgType == 2) {
        imgurl = this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_INSURANCE+this.globalConstants.IMG_ORIGINAL + this.imagesArr[this.index].vehicle_insured_pic;
    } else if (this.imgType == 3) {
        imgurl = this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_REGISTRATION_PIC+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[this.index].vehicle_registered_pic;
    } else if (this.imgType == 5) {
        imgurl = this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_VIN_PIC+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[this.index];
    } else if (this.imgType == 7) {
        imgurl = this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_COMPLAINT_PIC+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[this.index].complaint_pic;
    } else if (this.imgType == 4) {
        imgurl = this.globalConstants.S3_BUCKET_URL+this.globalConstants.RECOMMEDATION_IMAGE+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[this.index].filename;
    } else if (this.imgType == 8) {
        imgurl = this.globalConstants.S3_BUCKET_URL+this.globalConstants.SERVICE_REQUEST_PICTURES+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[this.index].image_name;
    } else if (this.imgType == 9) {
        imgurl = this.globalConstants.S3_BUCKET_URL+this.globalConstants.SERVICE_PICTURE+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[this.index].value;
    } else if (this.imgType == 10) {
        imgurl = this.globalConstants.S3_BUCKET_URL+this.globalConstants.EMISSION_INSPECTION_PIC+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[this.index].pic;
    } else if (this.imgType == 11) {
        imgurl = this.globalConstants.S3_BUCKET_URL+this.globalConstants.EMP_DOCUMENT+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[this.index].src;
    } else if (this.imgType == 13) {
        imgurl = this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_LABEL_PICTURES+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[this.index].label_pic;
    }else if (this.imgType == 14) {
        imgurl = this.globalConstants.S3_BUCKET_URL+this.globalConstants.EMP_LEAVE_DOCUMENT+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[this.index].filename;
    }
    var ext = imgurl.substring(imgurl.lastIndexOf('.') + 1);
    var esrc = encodeURI(imgurl);
    var name = decodeURI(decodeURI(esrc.substring(esrc.lastIndexOf('/') + 1)));
    var type = "image/";
    if (ext === 'jpg' || ext === 'jpeg') {
        type += 'jpeg';
    } else {
        type += 'png';
    }
    // console.log(this.imageCanvasObject);
    var base64data = this.imageCanvasObject.toDataURL(type);
    var inURL = this.router.url.split("/")[1];
    let saveModifiedImageFormData = new FormData();
    saveModifiedImageFormData.append('vehicle_pic',base64data);
    saveModifiedImageFormData.append('vehicle_name',name);
    saveModifiedImageFormData.append('image_type',type);
    saveModifiedImageFormData.append('vehicle_image_category',this.imgType);
    if(inURL == "customer"){
        saveModifiedImageFormData.append('customer_id',this.imageData.customerId);
    }else if(inURL == "shops"){
        saveModifiedImageFormData.append('user_id',this.imageData.customerId);
    }
    saveModifiedImageFormData.append('vehicle_id',this.imageData.carId);
    this.rotatedImageSaver(saveModifiedImageFormData);
}

 downloadImage() {
    var imgurl;
    if (this.imgType == 1) {
        imgurl =this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_PICTURES+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[this.index].vehicle_pic;
    } else if (this.imgType == 2) {
        imgurl = this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_INSURANCE+this.globalConstants.IMG_ORIGINAL + this.imagesArr[this.index].vehicle_insured_pic;
    } else if (this.imgType == 3) {
        imgurl = this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_REGISTRATION_PIC+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[this.index].vehicle_registered_pic;
    } else if (this.imgType == 5) {
        imgurl = this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_VIN_PIC+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[this.index];
    } else if (this.imgType == 7) {
        imgurl = this.globalConstants.S3_BUCKET_URL+this.globalConstants.VEHICLE_COMPLAINT_PIC+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[this.index].complaint_pic;
    } else if (this.imgType == 4) {
        imgurl = this.globalConstants.S3_BUCKET_URL+this.globalConstants.RECOMMEDATION_IMAGE+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[this.index].filename;
    } else if (this.imgType == 8) {
        imgurl = this.globalConstants.S3_BUCKET_URL+this.globalConstants.SERVICE_REQUEST_PICTURES+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[this.index].image_name;
    } else if (this.imgType == 9) {
        imgurl = this.globalConstants.S3_BUCKET_URL+this.globalConstants.SERVICE_PICTURE+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[this.index].value;
    } else if (this.imgType == 10) {
        imgurl = this.globalConstants.S3_BUCKET_URL+this.globalConstants.EMISSION_INSPECTION_PIC+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[this.index].pic;
    } else if (this.imgType == 11) {
        imgurl = this.globalConstants.S3_BUCKET_URL+this.globalConstants.EMP_DOCUMENT+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[this.index].src;
    } else if (this.imgType == 12) {
        imgurl = this.globalConstants.S3_BUCKET_URL+globalConstants.INTERNAL_NOTE_DOC+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[this.index] 
    } else if (this.imgType == 13) {
        imgurl = this.globalConstants.S3_BUCKET_URL+globalConstants.VEHICLE_LABEL_PICTURES+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[this.index].label_pic
    }else if (this.imgType == 14) {
        imgurl = this.globalConstants.S3_BUCKET_URL+globalConstants.EMP_LEAVE_DOCUMENT+this.globalConstants.IMG_ORIGINAL+ this.imagesArr[this.index].filename
    }
    var ext = imgurl.substring(imgurl.lastIndexOf('.') + 1);
    var esrc = encodeURI(imgurl);
    var name = decodeURI(decodeURI(esrc.substring(esrc.lastIndexOf('/') + 1)));
    var type = "image/";
    if (ext === 'jpg' || ext === 'jpeg') {
        ext = 'jpg';
        type += 'jpeg';
    } else {
        type += 'png';
    }
    let imageDownloadURL = this.globalConstants.API_DOWNLOAD_IMAGES_URL + "?vehicle_name=" + name + "&vehicle_image_category=" + this.imgType;
    this.globalService.downloadAsset(imageDownloadURL,'Image.'+ext);
}
  rotatedImageSaver(data){
    this.globalService.callAPIWithJSONHeader(globalConstants.API_SAVE_MODIFIED_IMAGE_URL, data)
        .subscribe((data:any) => {
            if (data.code == "500") {
                this.globalService.snackbar('success', data.message);
                if(this.imgType == '10'){
                    $('.thumbnail-emission').each(function () {
                        var source = $(this).find('img').attr('src');
                        source = source + '?' + new Date().getTime();
                        $(this).find('img').attr('src', source);
                    });
                }else{
                    $('.thumbnail').each(function () {
                        var source = $(this).find('img').attr('src');
                        source = source + '?' + new Date().getTime();
                        $(this).find('img').attr('src', source);
                    });
                }
            } else {
                this.globalService.snackbar('error', data.message);
            }
        });
  }
}
