import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationEnd, Params } from '@angular/router';
import { GlobalService } from "../../../../environments/global.service";
import { globalConstants } from "../../../../environments/globalConstants";
import {Subscription} from 'rxjs'; 

@Component({
  selector: 'app-emp-permission',
  templateUrl: './emp-permission.component.html',
  styleUrls: ['./emp-permission.component.scss']
})
export class EmpPermissionComponent implements OnInit,OnDestroy {
  public roles: any[];
  public currentUser;
  public empId;
  public search: string;
  public showNoResult: boolean = false;
  public subscriptionDataForAPI: Subscription = new Subscription();

  constructor(private route: ActivatedRoute,private router: Router,public gs: GlobalService) { 
    let user = this.gs.getCurrentUser();
    if (user) {
      this.currentUser = user;
    } else {
      this.router.navigate(["/shops/login"]);
    }
    setTimeout(()=>{
    let isEnable=this.gs.getAcl('shop_roles_permission','edits');
    if(!(isEnable)){
        this.router.navigate(['shops/action/forbidden']);
    }
  },globalConstants.RELOAD_WAIT);
  this.gs.setMetaData("SHOPS","MANAGE_EMPLOYEE_PERMISSIONS")
  }

  ngOnInit() {
    this.getParams();
    this.getPermission();
  }
  getPermission() {
    let data = {emp_id:this.empId, user_id: this.currentUser.id, user_type: this.currentUser.user_type };
    this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_SHOP_USER_PERMISSIONS, data).subscribe((r:any) => {
      if (r.result == '1') {
        this.roles = r.data;
        this.roles.forEach(e=>{
          e.items.forEach(element => {
            element.show = true;
          });
        })
      }
    });
  }
  getParams() {
    this.route.params.subscribe((params: Params) => {
      this.empId = params["emp_id"];
    });
  }
  /**
   * on Change Check All Checkbox checked  by (View, Add , Edit ,Delete )
   * @param roleId
   * @param roleIndex
   * @param actionType
   * @param actionTypeStatus
   */
  checkAllAction(roleId, roleIndex, actionType, actionTypeStatus) {
    this.roles[roleIndex].items.forEach(elements => {
      switch (actionType) {
        case 'view':
          elements.permissions.view = (actionTypeStatus == true) ? false : true;
          break;
        case 'add':
          elements.permissions.add = (actionTypeStatus == true) ? false : true;
          break;
        case 'edit':
          elements.permissions.edit = (actionTypeStatus == true) ? false : true;
          break;
        case 'delete':
          elements.permissions.delete = (actionTypeStatus == true) ? false : true;
          break;
      }
    });
    this.validateCheckAll(roleId, roleIndex, actionType, actionTypeStatus, '', '', '1');
  }
  /**
   * To validate permission checkboxes upon changing Permissions checkboxes (View, Add,Edit Delete)
   * @param roleId
   * @param roleIndex
   * @param actionType
   * @param actionTypeStatus
   * @param permissionId
   * @param permissionIndex
   */
  checkPermission(roleId, roleIndex, actionType, actionTypeStatus, permissionId, permissionIndex) {
    switch (actionType) {
      case 'view':
        this.roles[roleIndex].items[permissionIndex].permissions.view = (this.roles[roleIndex].items[permissionIndex].permissions.view) ? false : true;
        this.validateCheckAll(roleId, roleIndex, actionType, this.roles[roleIndex].items[permissionIndex].permissions.view, permissionId, permissionIndex, '2')
        break;
      case 'add':
        this.roles[roleIndex].items[permissionIndex].permissions.add = (this.roles[roleIndex].items[permissionIndex].permissions.add) ? false : true;
        this.validateCheckAll(roleId, roleIndex, actionType, this.roles[roleIndex].items[permissionIndex].permissions.add, permissionId, permissionIndex, '2')
        break;
      case 'edit':
        this.roles[roleIndex].items[permissionIndex].permissions.edit = (this.roles[roleIndex].items[permissionIndex].permissions.edit) ? false : true;
        this.validateCheckAll(roleId, roleIndex, actionType, this.roles[roleIndex].items[permissionIndex].permissions.edit, permissionId, permissionIndex, '2')
        break;
      case 'delete':
        this.roles[roleIndex].items[permissionIndex].permissions.delete = (this.roles[roleIndex].items[permissionIndex].permissions.delete) ? false : true;
        this.validateCheckAll(roleId, roleIndex, actionType, this.roles[roleIndex].items[permissionIndex].permissions.delete, permissionId, permissionIndex, '2')
        break;
    }
  }
  /**
   * To validate 'ALL' checkboxes upon changing 'ALL' and upon changing Permissions checkboxes (View, Add,Edit Delete)
   * @param roleId
   * @param roleIndex
   * @param actionType
   * @param actionTypeStatus
   * @param permissionId
   * @param permissionIndex
   * @param isAllClicked
   */
  validateCheckAll(roleId, roleIndex, actionType, actionTypeStatus, permissionId, permissionIndex, isAllClicked) {
    // console.log(roleId, roleIndex, actionType, actionTypeStatus , permissionId, permissionIndex, isAllClicked);
    if (isAllClicked == 1) {
      var checkedVal = (actionTypeStatus == true) ? false : true;
      this.checkAll(roleIndex, actionType, checkedVal);
    } else {

      switch (actionType) {
        case 'view':
          var filterResult = this.roles[roleIndex].items.filter(function (x) { return x.permissions.view == false });
          break;
        case 'add':
          var filterResult = this.roles[roleIndex].items.filter(function (x) { return x.permissions.add == false });
          break;
        case 'edit':
          var filterResult = this.roles[roleIndex].items.filter(function (x) { return x.permissions.edit == false });
          break;
        case 'delete':
          var filterResult = this.roles[roleIndex].items.filter(function (x) { return x.permissions.delete == false });
          break;
      }

      // console.log(filterResult, filterResult.length);
      if (filterResult.length > 0) {
        this.checkAll(roleIndex, actionType, false);
      } else {
        this.checkAll(roleIndex, actionType, true);
      }
    }
  }
  /**
   * To check/uncheck 'ALL' checkboxes
   * @param roleIndex
   * @param actionType
   * @param checkedValue
   */
  checkAll(roleIndex, actionType, checkedValue) {
    switch (actionType) {
      case 'view':
        this.roles[roleIndex].all.view = checkedValue;
        break;
      case 'add':
        this.roles[roleIndex].all.add = checkedValue;
        break;
      case 'edit':
        this.roles[roleIndex].all.edit = checkedValue;
        break;
      case 'delete':
        this.roles[roleIndex].all.delete = checkedValue;
        break;
    }
  }
  savePermission() {
    let data = {emp_id:this.empId, user_id: this.currentUser.id, user_type: this.currentUser.user_type,permissions:JSON.stringify(this.roles) };
    let formData=new FormData();
    for(var i in data){
      formData.append(i,data[i]);
    }
    this.gs.formData(globalConstants.API_SHOP_USER_PERMISSIONS_SAVE, formData).subscribe((r) => {
      if (r.result == '1') {
        this.gs.snackbar('success',r.message);
      }
    });
  }

  searchPermissions(roleIndex: number){
    let hasVisibleItems = false;
    if(this.search != ''){
      this.roles[roleIndex].items.forEach(e=>{
        if(e.name.toLowerCase().includes(this.search.toLowerCase())){
          e.show = true;
          hasVisibleItems = true;
        }else{
          e.show = false;
        }
      });
    }else{
      this.roles[roleIndex].items.forEach(e=>{
        e.show = true;
        hasVisibleItems = true;
      });
    }
    this.showNoResult = !hasVisibleItems;
  }

  clearPermissions(roleIndex: number){
    if(this.search == ''){
      this.roles[roleIndex].items.forEach(e=>{
        e.show = true;
        this.showNoResult = false;
      });
    }
  }

  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
  }
}
