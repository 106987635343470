import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
@Component({
  selector: 'app-nexpart-labor-notes-setting',
  templateUrl: './nexpart-labor-notes-setting.component.html',
  styleUrls: ['./nexpart-labor-notes-setting.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NexpartLaborNotesSettingComponent implements OnInit {

  public chkDisplayLaborTextInDescription: any = false;
  public chkLaborTitleInDescription: any = false;
  public chkLaborNotesInDescription: any = false;
  public chkLaborNotesPrefixInDescription : any = false;
  public showErrMsgForChkDisplayLaborText: any = false;
  public isNxTPtLaborSettingsValidated: any = true;
  public currentCustomer: any;
  constructor(
    public globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
    var nexPartSetting = "user_id=" + this.currentCustomer.id + "&user_type=" + this.currentCustomer.user_type;
    this.globalService.callAPI(globalConstants.API_NEXPART_LABOR_SETTINGS, nexPartSetting).subscribe((res: any) => {
      if (res.result == "1") {
        this.chkDisplayLaborTextInDescription = res.data.chk_nxt_labor_job_desc == "1" ? true : false;
        this.chkLaborTitleInDescription = res.data.chk_nxt_labor_job_desc_title == "1" ? true : false;
        this.chkLaborNotesInDescription = res.data.chk_nxt_labor_job_desc_notes == "1" ? true : false;
        this.chkLaborNotesPrefixInDescription = res.data.chk_nxt_labor_job_desc_notes_prefix=="1"? true : false; 
      } else {
        this.globalService.snackbar('error', res.message);
      }
    })

  }

  handleLaborTitleAndNotes() {
    this.showErrMsgForChkDisplayLaborText = false;
    if (!this.chkLaborNotesInDescription && !this.chkLaborTitleInDescription && !this.chkLaborNotesPrefixInDescription) {
      this.chkDisplayLaborTextInDescription = false;
    } else {
      this.chkDisplayLaborTextInDescription = true;
    }
  }

  handleLaborTextAndDesc() {
    if (!this.chkDisplayLaborTextInDescription) {
      this.chkLaborNotesInDescription = false;
      this.chkLaborTitleInDescription = false;
      this.chkLaborNotesPrefixInDescription = false;
    }
  }

  saveNexpartSetting() {
    if (this.chkDisplayLaborTextInDescription) {
      if (!this.chkLaborTitleInDescription && !this.chkLaborNotesInDescription) {
        this.showErrMsgForChkDisplayLaborText = true;
        this.isNxTPtLaborSettingsValidated = false;
      } else {
        this.isNxTPtLaborSettingsValidated = true;
      }
    }
    if (this.isNxTPtLaborSettingsValidated) {
      let nexPartLaborStObj: any = {
        user_id: this.currentCustomer.id,
        user_type: this.currentCustomer.user_type
      }
      nexPartLaborStObj.chk_nxt_labor_job_desc = this.chkDisplayLaborTextInDescription ? 1 : 0;
      nexPartLaborStObj.chk_nxt_labor_job_desc_title = this.chkLaborTitleInDescription ? 1 : 0;
      nexPartLaborStObj.chk_nxt_labor_job_desc_notes = this.chkLaborNotesInDescription ? 1 : 0;
      nexPartLaborStObj.chk_nxt_labor_job_desc_notes_prefix = this.chkLaborNotesPrefixInDescription ? 1 : 0;
      this.globalService.callAPI(globalConstants.API_NEXPART_LABOR_SETTINGS, nexPartLaborStObj).subscribe((res: any) => {
        if (res.result == "1") {
          this.globalService.snackbar('success', res.message);
        } else {
          this.globalService.snackbar('error', res.message);
        }
      })
    }
  }
}
