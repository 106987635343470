import { Component, OnInit, ViewChild, Output, Input, EventEmitter, AfterViewInit } from '@angular/core';
import { GlobalService } from '../../../../environments/global.service';
import { MatDrawer } from '@angular/material/sidenav';
import { globalConstants } from '../../../../environments/globalConstants';
import { globalFunctions } from '../../../../environments/globalFunctions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-search-advance-customer',
  templateUrl: './search-advance-customer.component.html',
  styleUrls: ['./search-advance-customer.component.scss'],
})

export class SearchAdvanceCustomerComponent implements OnInit, AfterViewInit {
  @ViewChild('searchCustomerDrawer') searchCustomerDrawer: MatDrawer;
  public customerList = [];
  @Input() type;
  @Output() closeTab = new EventEmitter<any>();

  advancedOptions = {
    user_id: "",
    user_type: "User",
    search_by: "",
    search_keyword: "",
    exculde_selected_customer: "",
    start: 0,
    limit: 10,
  }
  public searchByArray = [];
  public selectedCustomer = [];
  public recordsTotal = 0
  public allCustomerList = [];
  public isAnySelected = false;
  public selectable = false;
  searchForm: FormGroup;
  public submitted = false;
  constructor(private globalService: GlobalService, private fb: FormBuilder) {
    this.advancedOptions.user_id = JSON.parse(localStorage.getItem("loggedInUser")).id;
    this.searchByArray = [
      { id: "0", text: "Search By"},
      { id: "1", text: "Customer Name"},
      { id: "2", text: "Customer Email"},
      { id: "3", text: "Customer Mobile"},
      { id: "4", text: "Business Name"}
    ];
  }

  ngOnInit() {
    if(this.type === 'advance-report'){
      this.customerList = JSON.parse(localStorage.getItem("advance-report-selected-customer"))|| [];
    }else if(this.type === 'business-metrics'){
      this.customerList = JSON.parse(localStorage.getItem("business-selected-customer"))|| [];
    }
    this.searchForm = this.fb.group({
      search_by: ['0',Validators.required],
      search_keyword: ['',Validators.required]
    });
    this.getSearchedCustomer();
  }

  ngAfterViewInit() {
    this.searchCustomerDrawer.toggle();
  }

  setTouchViewValue() {
    return true;
  }

  updateSelection(index: number){
    this.allCustomerList[index].status = this.allCustomerList[index].status?false:true;
    if(this.allCustomerList[index].status){
      this.selectedCustomer.push(this.allCustomerList[index]);
    }else{
      const i = this.selectedCustomer.map(function(e) { return e.id; }).indexOf(this.allCustomerList[index].id);
      this.selectedCustomer.splice(i, 1);
    }
    if(this.selectedCustomer.filter(e => e.status).length === 0){
      this.isAnySelected = false;
    }else{
      this.isAnySelected = true;
    }
  }

  removeSelected(i){
    const index = this.allCustomerList.map(function(e) { return e.id; }).indexOf(this.selectedCustomer[i].id);
    if(index !== -1){
      this.updateSelection(index);
    }else{
      this.selectedCustomer.splice(i, 1);
    }
  }

  search(){
    this.advancedOptions.search_by = this.searchForm.controls['search_by'].value;
    this.advancedOptions.search_keyword = this.searchForm.controls['search_keyword'].value;
    this.submitted = true;
    if(this.searchForm.controls['search_keyword'].value === '' || this.searchForm.controls['search_by'].value == 0){
      return;
    }
    this.advancedOptions.start = 0;
    this.getSearchedCustomer();
  }

  getSearchedCustomer(){
    this.allCustomerList = [];
    if(this.customerList.length > 0 && Array.isArray(this.customerList)){
      this.advancedOptions.exculde_selected_customer =   this.customerList.map(itm => itm.id).join(','); 
    }
    var content = globalFunctions.stringifyFormData(this.advancedOptions, null);
    this.globalService.callAPI(globalConstants.API_ADVANCED_CUSTOMER_SEARCH, content).subscribe((response:any) => {
      if (response.result == 1) {
        this.allCustomerList= response.data;
        this.recordsTotal = response.recordsTotal;
        this.allCustomerList.forEach(e=>{
          if(this.selectedCustomer.some(f => e.id === f.id)){
            e.status = true;
          }else{
            e.status = false;
          }
        });
      } else {
        this.recordsTotal = 0;
        this.globalService.snackbar("error", response.message);
      };
    });
  }

  paginate(event,type){
    if(type=="next") {
      this.advancedOptions.start = this.advancedOptions.start + this.advancedOptions.limit;
    } else {
      this.advancedOptions.start = this.advancedOptions.start - this.advancedOptions.limit;
    }
    this.getSearchedCustomer(); 
  }

  paginateByLimit(event){
    this.advancedOptions.limit = event.pageSize;
    this.advancedOptions.start = 0;
    this.getSearchedCustomer();
  }

  firstList() {
    if(this.advancedOptions.start == 0){
      return true;      
    }
    return false;
  }
  lastList() {
    if((this.advancedOptions.start + this.advancedOptions.limit) >= this.recordsTotal ){
      return true;
    }
      return false;
  }
  getUpperLimit(currentLastLimit, total) {
      if (currentLastLimit > total) {
          return total
      } else {
          return currentLastLimit
      }
  }

  clearVehileSelection(){
    this.searchForm.patchValue({
      search_by: '0',
      search_keyword: ''
    });
    this.allCustomerList.forEach(e=>{
      e.status = false;
    });
    this.selectedCustomer = [];
    this.advancedOptions.start = 0;
    this.advancedOptions.search_by = '';
    this.advancedOptions.search_keyword = '';
    this.submitted = false;
    this.getSearchedCustomer();
  }

  closeSideBar(isChanged: boolean = false) {
    if(isChanged){
      this.selectedCustomer.filter(e=> e.status).forEach(e=>{
        this.customerList.push(e);
      });
      if(this.type === 'advance-report'){
        localStorage.setItem("advance-report-selected-customer",JSON.stringify(this.customerList));
      }else if(this.type === 'business-metrics'){
        localStorage.setItem("business-selected-customer",JSON.stringify(this.customerList));
      }
    }
    this.searchCustomerDrawer.toggle();
    const output = {
      'changed': isChanged
    }
    setTimeout(() => {
      this.closeTab.emit(output);
    }, 1000);
  }
}


