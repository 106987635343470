<div class="panel panel-primary add-employee" id="mainCompotents">
  <div class="panel-heading padding-left-15">
    <h3 class="panel-title">Add New Employee</h3>
  </div>
  <div class="panel-body bgcolor-lightgrey padding-0">

    <!-- Login page start -->
    <div class="bgcolor-lightgrey form-content">
      <div class="container-fluid">
        <form class="form-inline col-xs-12 padding-bottom-15 add-employee-form" role="form" [formGroup]="addNewEmployee" novalidate>
          <div class=" col-xs-12 form-block">
            <h2 class="fontcolor-blue sub-heading">LOGIN INFORMATION</h2>
            <div class="row">
              <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
              <ngx-select class="display-full margin-bottom-20" [items]="employeeTypeList" formControlName="employee_type"
                placeholder="Select Employee Type *" [noAutoComplete]="true" optionTextField="name" (select)="checkIfValid();handleSelfCheckinAutoFill($event)">
              </ngx-select>  
              <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                <mat-error  *ngIf="addNewEmployee.controls.employee_type.hasError('required') && addNewEmployee.get('employee_type').touched">
                  Employee type is
                  <strong>required</strong>.
                </mat-error>
            </div>
              </div>
              <div class="form-group col-sm-12 col-md-6">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                  <input matInput placeholder="Email Address*" formControlName="email" maxlength="200" class="form-control input-lg custom-form-input" (keyup)="checkIfValid()"
                    (blur)="checkEmailAddressExistsOrNot()" />
                  <mat-error *ngIf="addNewEmployee.controls.email.hasError('pattern')">
                    Please enter a valid email address.
                  </mat-error>
                  <mat-error *ngIf="addNewEmployee.controls.email.hasError('required')">
                    Email Address is
                    <strong>required</strong>.
                  </mat-error>
                  <mat-error *ngIf="addNewEmployee.controls.email.hasError('alreadyExit')">
                    Email address 
                    <strong>already exists</strong>.
                    <a href="javascript:void(0)" *ngIf="employeeEmailExistsInfo.is_same" (click)="goToCustomerInfoPage(employeeEmailExistsInfo.email)">Click Here.</a>
                  </mat-error>
                </mat-form-field>
                
              </div>
            </div>
          </div>

          <div class=" col-xs-12 form-block">
            <h2 class="fontcolor-blue sub-heading">PERSONAL INFORMATION</h2>
            <div class="row">
              <div class="form-group col-sm-12 col-md-4">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                  <input matInput placeholder="First Name*" title="First Name" maxlength="200" formControlName="first_name" class="form-control input-lg custom-form-input"
                    (keyup)="checkIfValid()">
                  <mat-error *ngIf="addNewEmployee.controls.first_name.hasError('required')">
                    First name is
                    <strong>required</strong>.
                  </mat-error>
                  <mat-error *ngIf="addNewEmployee.controls.first_name.hasError('pattern')">
                    Must not contain any special characters.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-group col-sm-12 col-md-4">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Middle Name" title="Middle Name" maxlength="200" formControlName="middle_name" class="form-control input-lg custom-form-input">
                  <mat-error *ngIf="addNewEmployee.controls.middle_name.hasError('pattern')">
                    Must not contain any special characters.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-group col-sm-12 col-md-4">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                  <input matInput placeholder="Last Name*" title="Last Name" maxlength="200" formControlName="last_name" class="form-control input-lg custom-form-input"
                    (keyup)="checkIfValid()">
                  <mat-error *ngIf="addNewEmployee.controls.last_name.hasError('required')">
                    Last name 
                    <strong>required</strong>.
                  </mat-error>
                  <mat-error *ngIf="addNewEmployee.controls.last_name.hasError('pattern')">
                    Must not contain any special characters.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class=" col-xs-12 form-block">
            <h2 class="fontcolor-blue sub-heading">CONTACT INFORMATION</h2>
            <div class="row">
              <div class="form-group col-sm-12 col-md-6 margin-top-0">
                <mat-checkbox class="btn-block margin-top-10" labelPosition="after" (change)="handleNoAddressChange($event)" [checked]="addressCheckbox">
                 No Address
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-12 col-md-6">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input matInput [placeholder]="placeHolderObject.addressLine1" title="Address Line 1" maxlength="200" formControlName="address_one" class="form-control input-lg custom-form-input"
                    (keyup)="checkIfValid()">
                  <mat-error *ngIf="addNewEmployee.controls.address_one.hasError('required')">
                    Address line 1 is
                    <strong>required</strong>.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-group col-sm-12 col-md-6">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Address Line 2" title="Address Line 2" maxlength="200" formControlName="address_two" class="form-control input-lg custom-form-input">
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-sm-12 col-md-6">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input matInput [placeholder]="placeHolderObject.zipCode" title="{{zipcodeLabel}}"  #zipcode (input)="zipcode.value = zipcode.value.toUpperCase()" formControlName="zipcode" class="form-control input-lg custom-form-input"
                    maxlength="15" (keyup)="checkIfValid()" (blur)="setCountryStateCityViaZipCode()"/>
                  <mat-error *ngIf="addNewEmployee.controls.zipcode.hasError('required')">
                    {{zipcodeLabel}} is <strong>required</strong>.</mat-error>
                  <mat-error *ngIf="addNewEmployee.controls.zipcode.hasError('pattern')">
                    Please enter a valid {{zipcodeLabel.toLowerCase()}}
                  </mat-error>
                </mat-form-field>
              </div> 
              <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
                <ngx-select class="display-full margin-bottom-20" id="countryId"
                 [items]="countries" 
                 [(ngModel)]="selectedCountry"
                 [ngModelOptions]="{standalone: true}"
                 (select)="handleCountryDropdownChange($event)"
                 placeholder="Select Country">
                </ngx-select>
                <mat-error *ngIf="addNewEmployee.controls.country.hasError('required')">
                  Country is
                  <strong>required</strong>.
                </mat-error>

              </div>            
            </div>
              
            <div class="row">
              <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
                <ngx-select class="display-full margin-bottom-20" id="stateId"
                    [items]="states" 
                    [(ngModel)]="selectedState"
                    [ngModelOptions]="{standalone: true}"
                    (select)="handleStateDropdownChange($event)"
                    [disabled]="disableStates"
                    placeholder="Select State">
                </ngx-select>
                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                  <mat-error *ngIf="addNewEmployee.controls.state.hasError('required') && addNewEmployee.get('state').touched">
                    State is <strong>required</strong>.
                  </mat-error>
                </div>
              </div>
              <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
                <ngx-select class="display-full margin-bottom-20"  id="cityId"
                [items]="cities" 
                (select)="handleCityDropdownChange($event)"
                [(ngModel)]="selectedCity"
                [ngModelOptions]="{standalone: true}"
                placeholder="Select City" 
                [disabled]="disableCities"
                >
                </ngx-select>
                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                  <mat-error *ngIf="addNewEmployee.controls.city.hasError('required') && addNewEmployee.get('city').touched">
                    City is <strong>required</strong>.
                  </mat-error>
                </div>
              </div>              
            </div>

            <div class="row">
              <div class="form-group col-sm-12 col-md-6">
                <div class="row">
                  <div class="col-sm-6">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                      <input matInput [placeholder]="placeHolderObject.mobileNumber" title="Mobile Number" formControlName="mobile_phone" class="form-control input-lg custom-form-input"
                        maxlength="15" [textMask]="{mask: phoneMask}" (keyup)="checkIfValid();checkMobileValid()" />
                      <mat-error *ngIf="addNewEmployee.controls.mobile_phone.hasError('required')">
                        Mobile number is
                        <strong>required</strong>.
                      </mat-error>
                      <mat-error *ngIf="addNewEmployee.controls.mobile_phone.hasError('minLength') && !addNewEmployee.controls.mobile_phone.hasError('required')">
                        Invalid mobile number.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-6">
                    <button type="button" (click)="addContacts()" mat-raised-button class="display-full btn-height-40 emergency-contacts-btn" color="accent">Emergency Contacts</button>
                  </div>
                </div>

              </div>
              <div class="form-group col-sm-12 col-md-6 dob-field-group">
                <div class="vlio-datepicker relative">
                  <dp-date-picker matInput mode="day" placeholder='Date of Birth* (MM/DD/YYYY)' (open)="handleDOB($event,dayPicker)"
                    (onChange)="handleDOB($event,dayPicker)" theme="dp-material dp-main" class="btn-block" [config]="datePickerConfig"
                    formControlName="dob" #dayPicker></dp-date-picker>
                  <button type="button" class="calendar-icon"  mat-icon-button (click)="dayPicker.api.open();">
                    <mat-icon class="mat-icon">date_range</mat-icon>
                  </button>
                </div>
                <div class="dp-error"
                  *ngIf="addNewEmployee.controls.dob.hasError('required') && addNewEmployee.get('dob').touched ">
                  Date of Birth is
                  <strong>required</strong>
                </div>
                <div class="dp-error"
                  *ngIf="!(addNewEmployee.controls.dob.valid) && (addNewEmployee.value.dob!='') && (addNewEmployee.value.dob!=undefined)">
                  <p>Invalid Date of Birth</p>
                </div>
                <div class="dp-error"
                  *ngIf="isFutureDate && (addNewEmployee.controls.dob.valid)">
                  <p>Date of Birth in the future is not allowed.</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-12 col-md-6 weekly-hours">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Hours a Week*" title="Hours a Week" maxlength="6" formControlName="hours_week" class="form-control input-lg custom-form-input"
                    (keyup)="updateWagesValue('hours_week');checkIfValid()" (keydown)="disableKey($event)" (keypress)="allowDecimalNumber($event)"
                  />
                  <mat-error *ngIf="addNewEmployee.controls.hours_week.hasError('required')">
                    Hours a week is
                    <strong>required</strong>.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-group col-sm-12 col-md-6">
                <mat-checkbox class="btn-block margin-top-10" labelPosition="after"
                  (change)="enableReport($event)" formControlName="enable_report">
                  Enable Report Generation
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-12 col-md-6">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Hourly Wages*" title="Hours Wages"  maxlength="7" formControlName="hours_wages" class="form-control input-lg custom-form-input"
                    (keyup)="updateWagesValue('hours_wages');checkIfValid()" (keydown)="disableKey($event)" (keypress)="allowDecimalNumber($event)"
                  />
                  <mat-error *ngIf="addNewEmployee.controls.hours_wages.hasError('required')">
                    Hourly wages is
                    <strong>required</strong>.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-group col-sm-12 col-md-6">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input matInput readonly placeholder="Weekly Wages*" title="Week Wages" formControlName="week_wages" class="form-control input-lg custom-form-input disabled"/>
                  <mat-error *ngIf="addNewEmployee.controls.week_wages.hasError('required')">
                    Weekly wages is
                    <strong>required</strong>.
                  </mat-error>
                </mat-form-field>
              </div>

            </div>
          </div>

            <!-- Start USER TIMELINE Row -->            
            <div class="col-xs-12 form-block">
                <h2 class="fontcolor-blue sub-heading">WORK SCHEDULE</h2>
                <mat-error *ngIf="!addNewEmployee.controls.selectedItems.valid">
                    Please select atleast one weekday.
                </mat-error>
                <mat-radio-group formControlName="job_type" aria-label="Select an option">
                    <mat-radio-button [checked]="addNewEmployee.controls.job_type.value==0" value="0">Full Time</mat-radio-button>
                    <mat-radio-button [checked]="addNewEmployee.controls.job_type.value==1" value="1">Part Time</mat-radio-button>
                </mat-radio-group>               
                
                <div class="row work-schedule-wrapper" formArrayName="weekday_timings" *ngFor="let timing of addNewEmployee.get('weekday_timings')['controls']; let i = index;">
                    <div class="col-sm-12 col-md-12" [formGroupName]="i">
                        <div class="row">
                            <div class="col-sm-2 col-xs-2">
                                <div class="day-fields">
                                    <ng-container [ngSwitch]="i">
                                        <label *ngSwitchCase="0">Monday</label>
                                        <label *ngSwitchCase="1">Tuesday</label>
                                        <label *ngSwitchCase="2">Wednesday</label>
                                        <label *ngSwitchCase="3">Thursday</label>
                                        <label *ngSwitchCase="4">Friday</label>
                                        <label *ngSwitchCase="5">Saturday</label>
                                        <label *ngSwitchCase="6">Sunday</label>
                                    </ng-container>  
                                </div>
                            </div>
                            <div class="col-sm-1 col-xs-1">
                                <div class="row">
                                    <div class="form-group weak-checkbox col-xs-12">
                                        <mat-checkbox class="btn-block padding-top-10"  labelPosition="after" [class.chk_disable]="timing.get('is_disable').value"  formControlName="weekdayid">
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-9 col-xs-12">
                                <div class="row">
                                    <div class="form-group col-sm-5 col-xs-4 uc-ngx-select">
                                        <ngx-select class="display-full"  [items]="timeSlots" formControlName="start_time" placeholder="Start Time" optionTextField="text" >
                                        </ngx-select>                           
                                            <mat-error class="dp-error" *ngIf="timing.get('start_time').hasError('required')">
                                            Start time <strong>required</strong>.
                                            </mat-error>
                                            <mat-error class="dp-error"  *ngIf="timing.errors">
                                            {{ timing.errors?.dates  }}.
                                            </mat-error>
                                    </div>
                                    <div class="form-group col-sm-5 col-xs-4 uc-ngx-select">
                                        <ngx-select class="display-full" [items]="timeSlots" formControlName="close_time"  placeholder="End Time" optionTextField="text" >
                                        </ngx-select>                                         
                                            <mat-error class="dp-error" *ngIf="timing.get('close_time').hasError('required')">
                                            End time <strong>required</strong>.
                                            </mat-error>                            
                                    </div>
                                    <div class="form-group  col-xs-2">
                                      <button type="button" [disabled]="timing.get('weekdayid').value==false || timing.get('start_time').value=='' || timing.get('close_time').value==''" (click)="applyToAll(i)" mat-raised-button color="accent" class="pull-left">Apply to All</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>               
                
            </div>            
            <!-- End USER TIME Row -->

            <!-- USER OVERTIME Row -->              
            <div class="col-xs-12 form-block padding-bottom-20 margin-top-10 overtime-section">
                <h2 class="fontcolor-blue sub-heading">OVERTIME SETTING</h2>                
                  <div class="row">
                    <div class="col-sm-4 col-xs-6 uc-ngx-select">
                      <ngx-select class="display-full" [items]="hourSlots" formControlName="max_hour"  placeholder="Hours" optionTextField="text" >
                      </ngx-select> 
                    </div>
                    <div class="col-sm-4 col-xs-6 uc-ngx-select">
                      <ngx-select class="display-full" [items]="minSlots" formControlName="max_min"  placeholder="Minutes" optionTextField="text" >
                      </ngx-select> 
                    </div>
                </div>
            </div>
            <!-- USER OVERTIME Row -->  
         
            <div class="col-xs-12 form-block">
              <h2 class="fontcolor-blue sub-heading">PHOTO / DOCUMENTS / DRIVING LICENSE</h2>
              <div class="row">                
                <div class="form-group document-section col-sm-12 col-md-6 padding-bottom-20">
                  <!-- Start Employee Upload photo -->
                  <div class="col-sm-12 padding-0">
                    <label for="partPic">Upload Photo</label>
                  </div>
                  <div class="col-sm-12 file-upload-container padding-0">
                    <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative">
                      <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                      Drag File Here
                      <input type="file" id="empPhoto" (change)="uploadPicture($event,'image')" name="emp_pic" accept="image/*" formControlname="emp_photo"
                      />
                      <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                    </mat-toolbar>
                    <input id="empPhotoImagedata" name="emp_pic" value="" type="hidden" formControlName="emp_photo">
                  </div>
                  <div class="col-sm-12 padding-0">
                    <p class="help-block margin-0">Formats supported : {{imgTypes}}</p>
                    <p class="help-block margin-0">Maximum size : {{imgSize}}</p>
                    <ul class="list-inline upload-image margin-bottom-0">
                      <li *ngIf="employeePhoto!=''">
                        <a href="javascript:void(0);" class="img-set thumbnail relative">
                          <img class="show-image" src="{{employeePhoto}}" />
                          <i class="fa fa-trash remove-img" (click)="documentDelete('image')"></i>
                        </a>
                      </li>
                    </ul>

                  </div>
                  <!-- end Employee Upload photo -->
                  <!-- start Employee Driving License -->
                  <div class="col-sm-12 padding-0 margin-top-20">
                    <label for="partPic">Driving License</label>
                  </div>
                  <div class="col-sm-12 file-upload-container padding-0">
                    <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative">
                      <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                      Drag File Here
                      <input type="file" id="drivingLicense" (change)="uploadPicture($event,'doc',2)" />
                      <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                    </mat-toolbar>
                    <input id="drivingLicensedata" name="emp_driving_license" value="" type="hidden" formControlName="driving_license">
                  </div>
                  <div class="col-sm-12 padding-0">
                    <p class="help-block margin-0">Formats supported : {{docType}}</p>
                    <p class="help-block margin-0">Maximum size : {{imgSize}}</p>
                    <ul class="list-inline upload-image margin-bottom-0">
                      <li *ngFor="let license of employeeLicenseDocs;let i=index">
                        <a href="javascript:void(0);" class="img-set thumbnail relative">
                            <img class="show-image" *ngIf="license.type==1" src="{{license.src}}" />
                            <span class="fa far fa-file-pdf fa-4x" *ngIf="license.type==3"></span>
                            <span class="fa far fa-file-word fa-4x" *ngIf="license.type==4"></span>
                          <i class="fa fa-trash remove-img" (click)="documentDelete('doc',i,2)"></i>
                        </a>
                      </li>
                    </ul>

                  </div>
                  <!-- start Employee Driving License -->
                </div>
                <!-- start Employee Driving documents -->
                <div class="form-group col-sm-12 col-md-6 padding-bottom-20 documents-upload">
                  <div class="col-sm-12 padding-0">
                    <label for="partPic">Documents</label>
                  </div>
                  <div class="col-sm-12 file-upload-container padding-0">
                    <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative">
                      <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                      Drag File Here
                      <input type="file" id="empDocuments" (change)="uploadPicture($event,'doc',1)" />
                      <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                    </mat-toolbar>
                    <input id="empDocuments" name="emp_Documents" value="" type="hidden" formControlName="emp_documents">
                  </div>
                  <div class="col-sm-12 padding-0">
                    <p class="help-block margin-0">Formats supported : {{docType}}</p>
                    <p class="help-block margin-0">Maximum size : {{imgSize}}</p>
                    <ul class="list-inline upload-image margin-bottom-0">
                      <li *ngFor="let doc of employeeDocs; let i=index">
                        <a href="javascript:void(0);" class="img-set thumbnail relative">
                          <img class="show-image" *ngIf="doc.type==1" src="{{doc.src}}" />
                          <span class="fa far fa-file-pdf fa-4x" *ngIf="doc.type==3"></span>
                          <span class="fa far fa-file-word fa-4x" *ngIf="doc.type==4"></span>
                          <i class="fa fa-trash remove-img" (click)="documentDelete('doc',i,1)"></i>
                        </a>
                      </li>
                    </ul>

                  </div>
                </div>
                <!-- end Employee Driving documents -->
              </div>
            </div>        

            <div class=" col-xs-12 form-block submit-block">
              <div class="row">
                <div class="col-sm-6 col-lg-4">
                  <button mat-raised-button class="car-btn btn-block text-uppercase  btn-lg font-light fontcolor-white" id="saveClose" color="accent"
                    title="Submit and Close" (click)="addEmployee('submit&Close')" >SUBMIT AND CLOSE</button>
                </div>
                <div class="col-sm-6 col-lg-4 margin-top-btn">
                  <button mat-raised-button class="car-btn btn-block text-uppercase  btn-lg font-light fontcolor-white" id="saveAdd" color="accent"
                    title="Submit and Add Another" (click)="addEmployee('Submit&Add')" >SUBMIT AND ADD ANOTHER</button>
                </div>
              </div>
            </div>

        </form>
      </div>
    </div>
  </div>
</div>