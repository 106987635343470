<div class="panel panel-primary margin-0">
  <div class="panel-heading padding-left-15">
    <h3 class="panel-title">Unauthorized</h3>
  </div>
  <div class="panel-body bgcolor-lightgrey">
    <div class="msg-response msg--fail" style="height: auto;">
      <div>
        <div class="msg-response_content" style="max-width: 100%;">
          <span><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
          <h2 class="title">Unauthorized!</h2>
          <h4 class="msg">You are not authorize to access this resource.</h4>
          <h4 class="msg">Please contact your administrator to resolve this.</h4>
        </div>
      </div>
    </div>
  </div>
</div>