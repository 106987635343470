<div class="panel panel-primary" id="mainCompotents">
  <div class="panel-heading padding-left-15">
    <h3 class="panel-title">Inspection LIST</h3>
  </div>
  <div class="panel-body bgcolor-lightgrey">
    <div class="display-full relative">
      <mat-expansion-panel hideToggle="false"
        class="search-filter search-employee relative mat-elevation-z1" [expanded]="searchPanelOpenStatus">
        <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px" >
          <strong><i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
        </mat-expansion-panel-header>
        <form id="advanceSearchForm" class="collapse in" aria-expanded="true">
          <div class="panel-body">
            <div class="col-sm-12">
              <div class="col-sm-3">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <mat-select class="form-control input-lg custom-form-input" options="searchByArray"
                    [(ngModel)]="options.search_by" [ngModelOptions]="{standalone: true}" placeholder="Search By">
                    <mat-option *ngFor="let opt of searchByArray" [value]="opt.id">{{opt.text}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-sm-3 hide">
                  <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                      <input  matInput placeholder="Search Keyword" title="Search Keyword" [(ngModel)]="options.keyword" [ngModelOptions]="{standalone: true}" class="form-control input-lg custom-form-input"/>
                  </mat-form-field>
              </div>
              <div class="col-sm-3">
                <mat-checkbox class="v-checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="options.registration">Registration
                  Date</mat-checkbox>
              </div>
              <div class="col-sm-3">
                <mat-checkbox class="v-checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="options.insurance">Insurance Date
                </mat-checkbox>
              </div>
              <div class="col-sm-3">
                <mat-checkbox class="v-checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="options.inspection">Inspection Date
                </mat-checkbox>
              </div>
    
            </div>
          </div>
          <mat-action-row class="search-filter-footer">
            <button mat-raised-button color="accent" type="submit" title="Search" (click)="search()">
              Search
            </button>
          </mat-action-row>
        </form>
      </mat-expansion-panel>
      <button mat-button type="button" #clearBtn class="clear-btn" title="Clear Filter" [class.active-search]="isAnyOptionActive()"
        (click)="clearSearch()">
        Clear Filter
      </button>
    </div>
    <div class="car-datatable uc-datatable mat-elevation-z1">
      <div class="table-responsive relative">
        <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg uc-table-responsive ">

          <ng-container matColumnDef="cust_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="id">Customer Name</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="id" data-val="Customer Name"><span (click)="cutomerDetail(row,2)"> {{row.cust_name || '-'}}</span> </mat-cell>
          </ng-container>

          <ng-container matColumnDef="inspection_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Inspection Date</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="Inspection Date">{{ dateProvided(row.inspection_date)}}<span  class="expires-in{{isExpired(row.inspeDays_left)}} expire-badge" *ngIf="row.inspection_date!='-'" title="{{showDays(row.inspeDays_left,true)}}">{{showDays(row.inspeDays_left,false)}}</span></mat-cell>
          </ng-container>
          <ng-container matColumnDef="registration_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Registration Date</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="Registration Date">{{dateProvided(row.registration_date)}}<span  class="expires-in{{isExpired(row.regDays_left)}} expire-badge" *ngIf="row.registration_date!='-'" title="{{showDays(row.regDays_left,true)}}">{{showDays(row.regDays_left,false)}}</span> </mat-cell>
          </ng-container>
          <ng-container matColumnDef="insurance_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Insurance Date</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="Insurance Date">{{dateProvided(row.insurance_date)}}<span class="expires-in{{isExpired(row.insDays_left)}} expire-badge" *ngIf="row.insurance_date!='-'" title="{{showDays(row.insDays_left,true)}}">{{showDays(row.insDays_left,false)}}</span></mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Vehicle</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="Vehicle"> {{row.name || 'N/A'}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="days_left">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="date">Days Left</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="date" data-val="Days Left"> {{row.days_left || '-'}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Actions">
            <mat-header-cell *matHeaderCellDef data-role="button"> Actions </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="button">
              <button mat-button class="list-action-btn" [matMenuTriggerFor]="menu" disableRipple>
                Action<mat-icon>arrow_drop_down</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="car-icon-menu" [overlapTrigger]="false">
                <button class="mat-menu-item" (click)="cutomerDetail(row,1)"><i class="fa fa-eye"></i>Vehicle Detail</button>
                <button class="mat-menu-item" (click)="cutomerDetail(row,2)"><i class="fa fa-eye"></i>Customer Detail</button>
              </mat-menu>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <div class="padding-15 text-center" [style.display]="getTotalRecords() < 1 ? '' : 'none'">No Records Available
        </div>
      </div>
      <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
        <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0"
          [pageSize]="options.limit" [pageSizeOptions]="[5, 10,25, 100]" (page)="paginateByLimit($event)">
        </mat-paginator>

        <div class="mat-paginator table-paging">
          <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} -
            {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
          <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
            <mat-icon>navigate_before</mat-icon>
          </button>
          <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
            <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
