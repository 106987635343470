<div mat-dialog-title class="relative">
  Quick Quote View
  <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
    <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="panel panel-primary ">
    <div class="panel-heading">
      <h3 class="panel-title">Quick Quote Detail</h3>
    </div>

    <div class="panel-body bgcolor-lightgrey">
      <div class="jumbotron padding-25 margin-bottom-0 car-view-str">
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Quote ID:</label></div>
              <div class="col-sm-6">{{recomendationData.id}}</div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
             <div class="row">
              <div class="col-sm-6"><label>Vehicle :</label></div>
              <div class="col-sm-6">
                <span [innerHTML]="vehicleName ? vehicleName:'N/A'"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
           
            <div class="row">
              <div class="col-sm-6"><label>Add Date:</label></div>
              <div class="col-sm-6">{{formatDate(recomendationData.add_date)}}</div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              
            </div>
          </div>
        </div>
        <div class="row">
            <div class="col-sm-12 margin-bottom-10">
              <div class="row">
                <div class="col-sm-3"><label>Notes :</label></div>
                <div class="col-sm-9" >
                    <span [innerHTML]="recomendationData.notes ? getNltoBr(recomendationData.notes):'-'"></span>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <mat-accordion class="new-request-expension show-price margin-bottom-10 margin-top-10 display-full" displayMode="flat"
    hideToggle="false">
    <mat-expansion-panel [expanded]="i==0" hideToggle="true" class="new-request-expension relative"
      *ngFor="let job of allJobs;let i = index" id="qqvexpansion-{{i+1}}" style="border-radius: 0">
      <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="qqvexpansionHead-{{i+1}}"
        [attr.data-state]="i==0">
        <span class="margin-right-5">{{ i+1 }}.</span><b>{{ job.name }}</b>
      </mat-expansion-panel-header>
      <ul class="service-list-items">
        <li *ngFor="let subChild of job.children;let j = index"><p>{{subChild.name}}</p>
          <!-- level 2-->
          <div *ngIf="(subChild.children && subChild.children.length==0)">
            <ng-container *ngTemplateOutlet="viewcusrecomtmp;context:{'jobs':subChild}">
            </ng-container>
          </div>
          <!-- level 2-->
          <!-- level 3-->
          <div *ngIf="subChild.children && subChild.children.length" class="padding-left-15" >
            <div *ngFor="let subChild3 of subChild.children;let k=index">
              <p>{{subChild3.name}}</p>
              <ng-container *ngTemplateOutlet="viewcusrecomtmp;context:{'jobs':subChild3}">
              </ng-container>
            </div>
          </div>
          <!-- level 3-->
        </li>
      </ul>
    </mat-expansion-panel>
  </mat-accordion>
</mat-dialog-content>

<div class="" *ngIf="recomendationData">
  <div class="col-sm-12">
     <h4 class="text-right relative payment-info-wrap margin-0">
      <span class="payment-info">
        <strong>Total : </strong>$ <span> {{recomendationData.grand_total}}</span>
      </span>
       <span class="payment-info">
        <strong>Paid : </strong>$ <span> {{recomendationData.total_paid}}</span>
      </span>
    <i class="tax-note">
      Taxes and supplies are not included
    </i>
    </h4>
    
  </div>
</div>

<ng-template #viewcusrecomtmp let-jobs="jobs">
   <div class="row margin-bottom-0" *ngIf="jobs.id!='158'">
      <div class="row margin-bottom-0" *ngIf="selectedGenericJob && selectedGenericJob[jobs.id] && selectedGenericJob[jobs.id].length">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="col-sm-3 text-right control-label">Generic Job(s):</label>
            <div class="col-sm-9">            
                <div class="display-full row" *ngFor="let GJ of selectedGenericJob[jobs.id];let i=index">
                  <div class="col-md-5 col-lg-7 pname break-all">{{GJ.label}}</div>
                  <div class="col-md-7 col-lg-5">
                    <div class="row">
                      <div class="col-sm-5 pprice" >${{GJ.price}}</div>
                      <div class="col-sm-3 pquantity">{{GJ.quantity}} </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom-0" *ngIf="selectedParts && selectedParts[jobs.id] && selectedParts[jobs.id].length">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="col-sm-3 text-right control-label">Part(s):</label>
            <div class="col-sm-9">           
                <div class="display-full row" *ngFor="let part of selectedParts[jobs.id];let i=index">
                  <div class="col-md-5 col-lg-7 pname break-all">{{part.label}}</div>
                  <div class="col-md-7 col-lg-5">
                    <div class="row">
                      <div class="col-sm-5 pprice" ><span>${{part.price}}</span></div>
                      <div class="col-sm-3 pquantity">{{part.quantity}} </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom-0">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="col-sm-3 text-right control-label">Time:</label>
            <div class="col-sm-9">
              <div class="form-inline relative">
                <div class="complete-wrap mat-form-field">{{times[jobs.id] || '-'}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom-0">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="col-sm-3 text-right control-label">Labor:</label>
            <div class="col-sm-9">
              <div class="form-inline relative">
                <div class="complete-wrap mat-form-field"><span > $ {{ labors[jobs.id] || '0.00'}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom-0">
        <div class="col-sm-12">
          <div class="form-group margin-bottom-0">
            <label class="col-sm-3 text-right control-label margin-bottom-0">Notes:</label>
            <div class="col-sm-9" [innerHTML]="getNltoBr(notes[jobs.id])">
                
            </div>
          </div>
        </div>
      </div>
    </div>
</ng-template>
