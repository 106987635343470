import {Component, OnInit, ViewChild, ViewEncapsulation, OnDestroy} from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd, Params} from '@angular/router';
import {Http, Headers, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../../environments/globalFunctions";
import {globalConstants} from "../../../../environments/globalConstants";
import {GlobalService} from "../../../../environments/global.service";
import {lang} from "../../../../environments/lang-en";
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-shopviewcoustomerinquiry',
    templateUrl: './shopviewcoustomerinquiry.component.html',
    styleUrls: ['./shopviewcoustomerinquiry.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ShopviewcoustomerinquiryComponent implements OnInit,OnDestroy {
    public serviceRequestId: number;
    public shopAddInquiry: FormGroup;
    public serviceInfo: any;
    public serviceInfoComments: any = [];
    public serviceRequestList: any;
    public showServiceDetail: boolean = false;
    public inquiry_id;
    public inquiry_status;
    public servicecommentDetails;
    public serviceInfoStatus;
    public read_status;
    public submitted = false;
    public inquiryReadStatus='';
    myControl: FormControl = new FormControl();
    genericControl: FormControl = new FormControl();
    partsOptions = [];
    genericOptions = [];
    public statuses = ['Open', 'Resolved', 'Unread'];
    public isViewCustomerInfo=false;
    public inqId:any=null;
    public subscriptionDataForAPI: Subscription = new Subscription();

    constructor(private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private route: ActivatedRoute, public titleService: Title, private http: Http, private router: Router, public snackBar: MatSnackBar, public globalService: GlobalService) {
        if (localStorage.getItem("loggedInUser")) {
           this.initialializeAddCommentForm();
           this.getParams();
           if(this.inqId){
            this.inquiry_id =this.inqId ;
           }else{
            this.inquiry_id = localStorage.getItem("shopInquiryID");
           }
            this.inquiryReadStatus=this.globalService.getSetItems("shopInquiryReadStatus")
            this.globalService.setMetaData("SHOPS","INQUIRY_DETAIL")
        } else {
            this.router.navigate(["/shops"]);
        }
        setTimeout(()=>{
        let isEnable=this.globalService.getAcl('inquiries','views');
        this.isViewCustomerInfo=this.globalService.getAcl('view_customer_info','views');
       
        if(!(isEnable)){
            this.router.navigate(['shops/action/forbidden']);
        }
    },globalConstants.RELOAD_WAIT)
    }

    ngOnInit() {
        this.initialializeAddCommentForm();
        let content = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&inquiry_id=" + this.inquiry_id+"&read_status="+this.inquiryReadStatus;
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_INQUIRY_VIEW_URL, content).subscribe((response:any) => {

            if (response.result == 1) {
                this.serviceInfo = response.data;
                this.serviceInfo.add_date=this.globalService.getFullFormateDate(this.serviceInfo.add_date,'G','B');
                this.serviceInfoStatus = response.data.status;
                if (this.serviceInfo.comments) {
                    
                    if (this.serviceInfo.comments && this.serviceInfo.comments.length > 0) {
                        this.serviceInfoComments = this.serviceInfo.comments;
                    }
                    else {
                        this.serviceInfoComments[0] = this.serviceInfo.comments;
                    }
                    for(var i=0;i<this.serviceInfoComments.length;i++) {
                        this.serviceInfoComments[i].add_date = this.globalService.getFullFormateDate(this.serviceInfoComments[i].add_date,'G','H')
                    }
                }
                this.serviceRequestList = response.data.service_request_list;
                this.showServiceDetail = true;
            } else {
                this.showServiceDetail = false;
                this.globalService.snackbar("error", response.message);
            }

        });
    }

    ngAfterViewInit() {
//        let content = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&inquiry_id=" + this.inquiry_id;
//        this.globalService.callAPI(globalConstants.API_GET_INQUIRY_VIEW_URL, content).subscribe((response) => {
//
//            if (response.result == 1) {
//                this.serviceInfo = response.data;
//                this.serviceInfoStatus = response.data.status;
//                if (this.serviceInfo.comments) {
//                    
//                    if (this.serviceInfo.comments && this.serviceInfo.comments.length > 0) {
//                        this.serviceInfoComments = this.serviceInfo.comments;
//                    }
//                    else {
//                        this.serviceInfoComments[0] = this.serviceInfo.comments;
//                    }
//                    
//                }else{
//                    console.log("check", this.serviceInfoComments.length, this.serviceInfoComments);
//                }
//                this.serviceRequestList = response.data.service_request_list;
//                this.showServiceDetail = true;
//            } else {
//                this.showServiceDetail = false;
//                this.globalService.snackbar("error", response.message);
//            }
//
//        });
    }

    initialializeAddCommentForm() {
        this.shopAddInquiry = this.formBuilder.group({
            parent_id: this.inquiry_id,
            inquiry_shop: this.globalService.getCurrentUser()["shop_id"],
            user_type: this.globalService.getCurrentUser()["user_type"],
            user_id: this.globalService.getCurrentUser()["id"],
            inquiry_comment: ['', Validators.required],
            inquiry_resolver: [''],
            is_comment: [true],
        })
    }

    addComment() {
        Object.keys(this.shopAddInquiry.controls).map((controlName) => {
            this.shopAddInquiry.get(controlName).markAsTouched({onlySelf: true});
        });
        
        if (this.shopAddInquiry.valid) {
            this.shopAddInquiry.controls.parent_id.setValue(this.inquiry_id);
            this.shopAddInquiry.controls.inquiry_shop.setValue(this.globalService.getCurrentUser()["shop_id"]);
            this.shopAddInquiry.controls.user_type.setValue(this.globalService.getCurrentUser()["user_type"]);
            this.shopAddInquiry.controls.user_id.setValue(this.globalService.getCurrentUser()["id"]);
            var stringifiedFormData = globalFunctions.stringifyFormData(this.shopAddInquiry.value, null);
            this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_ADD_INQUIRY_URL, stringifiedFormData).subscribe((data:any) => {
                    var message = data.message;
                    if (data.result == "1") {
                        this.shopAddInquiry.controls.inquiry_comment.reset();
                        this.ngOnInit();
                        this.globalService.snackbar('success', message);
                    } else {
                        this.globalService.snackbar('error', message);
                    }
                });
            //  this.router.navigate(["/shops"]);
        } else {
            this.submitted = true;
            this.mandatoryMsg();
        }
    }

    checkIfValid() {
        if (this.shopAddInquiry.valid) {
            this.submitted = false;
        }
    }
    showTaxExempted(event) {
        if (event.checked) {
            this.shopAddInquiry.controls.inquiry_resolver.setValue(true);
        } else {
            this.shopAddInquiry.controls.inquiry_resolver.setValue(false);
        }
    }

    openDialog() {
        this.globalService.confirmDialog("cc_id", 'It\'s a demo popup please ignore this').subscribe((res) => {});
    }

    expansionOpen(ele) {
//        let element = document.getElementById('expansion-' + ele);
//        setTimeout(() => {
//            this.globalService.setScrolling(element, 700, -5);
//        }, 500);
    }

    filter(val: any): any[] {
        if (val.length != undefined) {
            var filteredValue = this.partsOptions.filter(option => option.label.toLowerCase().indexOf(val.toLowerCase()) === 0);
            return filteredValue
        }
    }

    getStatus(statusId) {
        return this.statuses[statusId]
    }

    mandatoryMsg() {
        this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
    getParams() {
        this.route.params.subscribe((params: Params) => {
          this.inqId = params["id"];
        });
    }

    ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
    } 
}
