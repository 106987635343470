<div id="mainCompotents">
<div class="panel panel-primary" id="dashboardQuickLinks">
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">EDIT {{ currentCustomer.profile_type == '2' ? 'CONTACT' : 'CUSTOMER' }} DETAILS</h3>
        <button mat-mini-fab class="custom-btn-back" title="Back" (click)="globalService.goBack();">
            <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
    <div class="panel-body bgcolor-lightgrey padding-0" style="border:none;">

        <!-- Login page start -->
        <div class="bgcolor-lightgrey form-content edit-customer">
            <div class="container-fluid">
                <form  class="form-inline edit-customer-form col-xs-12 padding-bottom-15" role="form" [formGroup]="customerEdit" novalidate (ngSubmit)="editCustomerData()">
                    <div class=" col-xs-12 form-block">
                        <div class="fontcolor-blue sub-heading">
                            <div class="row flex-row">
                                <div class="col-sm-9">
                                    <h2 class="margin-0">LOGIN INFORMATION
                                    <mat-chip-list class="mat-badge" *ngIf="isSubletWorkCheck">
                                        <mat-chip color="primary" selected="true">Sublet Account</mat-chip>
                                    </mat-chip-list>
                                    </h2>
                                </div>
                                <div class="col-sm-3 text-right">
                                    <button mat-raised-button type="button" class=" mat-success" (click)="editContacts()">
                                            <i class="fa fa-vcard fa-lg"></i> <span class="hidden-xs">&nbsp;</span>
                                            <span class="hidden-xs">{{ currentCustomer.profile_type == '2' ? 'Other Contacts' : 'Contacts' }}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-12 col-md-6">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never"  hideRequiredMarker="false" appearance="fill">
                                    <input  matInput placeholder="Email Address *" formControlName="email" class="form-control input-lg custom-form-input" required  maxlength="200"/>
                                    <mat-error *ngIf="customerEdit.controls.email.hasError('pattern')">
                                        Please enter a valid Email address
                                    </mat-error>
                                    <mat-error *ngIf="customerEdit.controls.email.hasError('required')">
                                        Email Address is <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper" *ngIf="checkUserEmail">
                                    <div class="ng-tns-c12-29 ng-trigger ng-trigger-transitionMessages" style="opacity: 1; transform: translateY(0%);">
                                        <p class="mat-error ng-tns-c11-28 large-text" role="alert">
                                            Email Address is <strong>already exist. </strong> <a href="javascript:void(0)" [routerLink]="['/shops/dashboard']" target="_blank">Click Here.</a>
                                        </p>
                                    </div>
                                </div>
                                <!--<input type="text" class="form-control input-lg custom-form-input" id="email" name="email" placeholder="Email*" value="nitin.teche@gmail.com" disabled="">-->
                            </div>
                            <!-- mobile phone column start -->
                            <div class="form-group col-sm-12 col-md-6">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <input  matInput placeholder="Mobile Phone" title="Mobile Phone" formControlName="mobile_phone"  class="form-control input-lg custom-form-input" required maxlength="15" [textMask]="{mask: phoneMask}" />
                                    <mat-error *ngIf="customerEdit.controls.mobile_phone.hasError('required')">
                                        Mobile Phone is <strong>required</strong>
                                    </mat-error>
                                    <mat-error *ngIf="customerEdit.controls.mobile_phone.hasError('minLength') && !customerEdit.controls.mobile_phone.hasError('required')">
                                        Invalid Mobile Phone
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <p class="help-block"><strong>Note </strong>If you are making any change to email, customer will receive an email verification link on this new email. Customer will not be able to access his/her account until they verify the email. Further modifications to customer profile would not be possible until he/she verifies the new email address.</p>
                    </div>
                    <div class=" col-xs-12 form-block">
                        <h2 class="fontcolor-blue sub-heading">PERSONAL INFORMATION</h2>
                        <div class="row">
                            <div class="form-group col-sm-12 margin-bottom-5">
                                <mat-checkbox [checked]="checkedBox" [value]="" (click)="businessNameToggle()">Add {{globalService.getBORDName()}}</mat-checkbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-12 col-md-4">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" hideRequiredMarker="false" appearance="fill"> 
                                    <input matInput placeholder="First Name" title="First Name" formControlName="first_name"  class="form-control input-lg custom-form-input" required  maxlength="100">
                                    <mat-error *ngIf="customerEdit.controls.first_name.hasError('required')">
                                        First Name is <strong>required</strong>
                                    </mat-error>
                                    <mat-error *ngIf="customerEdit.controls.first_name.hasError('pattern')">
                                        Must not contain any special characters.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-sm-12 col-md-4">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <input  matInput placeholder="Middle Name" title="Middle Name" formControlName="middle_name"  class="form-control input-lg custom-form-input" maxlength="100">
                                    <mat-error *ngIf="customerEdit.controls.middle_name.hasError('pattern')">
                                        Must not contain any special characters.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-sm-12 col-md-4">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                                    <input  matInput placeholder="Last Name" title="Last Name" formControlName="last_name"  class="form-control input-lg custom-form-input" required  maxlength="100">
                                    <mat-error *ngIf="customerEdit.controls.last_name.hasError('required')">
                                        Last Name is <strong>required</strong>
                                    </mat-error>
                                    <mat-error *ngIf="customerEdit.controls.last_name.hasError('pattern')">
                                       Must not contain any special characters.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-sm-12 col-md-4" [@popOverState]="showBusinessName">
                                 <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <input  matInput placeholder="{{globalService.getBORDName()}}" title="{{globalService.getBORDName()}}" formControlName="business_name"  class="form-control input-lg custom-form-input" maxlength="200">
                                    <mat-error *ngIf="customerEdit.controls.business_name.hasError('pattern')">
                                        Must not contain any special characters.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class=" col-xs-12 form-block">
                        <h2 class="fontcolor-blue sub-heading">CONTACT INFORMATION</h2>
                        <div class="row">
                            <div class="form-group col-sm-12 col-md-6">
                              <mat-checkbox class="btn-block margin-top-10" labelPosition="after" formControlName="address_is_required">
                                No Address
                              </mat-checkbox>
                            </div>
                        </div> 
                        <div class="row">
                            <div class="form-group col-sm-12 col-md-6">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <input  matInput placeholder="Address Line 1" title="Address 1" formControlName="address_one"  class="form-control input-lg custom-form-input"  maxlength="200">
                                    <mat-error *ngIf="customerEdit.controls.address_one.hasError('required')">
                                        Address Line 1 is <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-sm-12 col-md-6">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <input  matInput placeholder="Address Line 2" title="Address 2" formControlName="address_two"  class="form-control input-lg custom-form-input" maxlength="200">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-sm-12 col-md-6">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <input  matInput placeholder="{{zipLabel}}" title="{{zipLabel}}"
                                      #zipcode (input)="zipcode.value = zipcode.value.toUpperCase()"
                                       formControlName="zipcode" 
                                       class="form-control input-lg custom-form-input" maxlength="15"  
                                       (blur)="setCountryStateCityViaZipCode()"
                                       />
                                    <mat-error *ngIf="customerEdit.controls.zipcode.hasError('required')">
                                        {{zipLabel}} is <strong>required</strong>
                                    </mat-error>
                                    <mat-error *ngIf="customerEdit.controls.zipcode.hasError('pattern')">
                                        Please enter valid {{zipLabel.toLowerCase()}}
                                    </mat-error>
                                    <mat-error *ngIf="customerEdit.controls.zipcode.hasError('maxlength')">
                                        Invalid {{zipLabel.toLowerCase()}}
                                    </mat-error>
                                </mat-form-field>
                            </div>  
                            <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
                                <ngx-select class="display-full margin-bottom-20" 
                                    [items]="countries" 
                                    [(ngModel)]="selectedCountry" 
                                    [ngModelOptions]="{standalone: true}"
                                    (select)="handleCountryDropdownChange($event)"
                                    placeholder="Select Country">
                                </ngx-select>
                            </div>                              
                        </div>

                        <div class="row">
                            <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
                                <ngx-select class="display-full margin-bottom-20" 
                                    [items]="states" 
                                    [(ngModel)]="selectedState" 
                                    [ngModelOptions]="{standalone: true}" 
                                    (select)="handleStateDropdownChange($event)"
                                    [disabled]="disableStates"
                                    placeholder="Select State">
                                </ngx-select>
                                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                                  <mat-error *ngIf="customerEdit.controls.state.hasError('required')">
                                      State is <strong>required</strong>
                                  </mat-error> 
                                </div>
                            </div>
                            <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
                                <ngx-select class="display-full margin-bottom-20" 
                                    [items]="cities" 
                                    [(ngModel)]="selectedCity" 
                                    [ngModelOptions]="{standalone: true}"
                                    (select)="handleCityDropdownChange($event)"
                                    [disabled]="disableCities"
                                    placeholder="Select City">
                                </ngx-select> 
                                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                                    <mat-error *ngIf="customerEdit.controls.city.hasError('required')">
                                        City is <strong>required</strong>
                                    </mat-error>
                                </div>
                            </div>                           
                        </div>
            <div class="row">
                <div class="form-group col-sm-12 col-md-6">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input  matInput placeholder="Home Phone" title="Home Phone" formControlName="home_phone"  class="form-control input-lg custom-form-input"  maxlength="15" [textMask]="{mask: phoneMask}" (keyup)="checkHomePhoneValidity()" />
                        <mat-error *ngIf="customerEdit.controls.home_phone.hasError('phoneError')">
                            Invalid Home Phone
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group col-sm-12 col-md-6">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <input  matInput placeholder="Business Phone" title="Business Phone" formControlName="business_phone"  class="form-control input-lg custom-form-input"  maxlength="15" [textMask]="{mask: phoneMask}" (keyup)="checkBusinessPhoneValidity()" />
                        <mat-error *ngIf="customerEdit.controls.business_phone.hasError('phoneError')">
                            Invalid Business Phone
                        </mat-error>
                    </mat-form-field>
                </div>
               
            </div>

            <div class="row">
                <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
                    <ngx-select class="display-full margin-bottom-20" 
                        formControlname="customer_type"
                        [(ngModel)]="defaultCustomerType"
                        [items]="getCustomerTypes" 
                        (select)="getSelectedOptionCustomerType($event)"
                        [ngModelOptions]="{standalone: true}"
                        placeholder="Select Customer Type">
                    </ngx-select>
                    <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                        <mat-error *ngIf="customerEdit.controls.customer_type.hasError('required')">
                            Customer Type is <strong>required</strong>
                        </mat-error>
                    </div>                    
            </div>
            <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
                <ngx-select class="display-full margin-bottom-20" 
                [items]="timezoneData" 
                formControlName="timezone"
                (select)="getSelectedOptionTimezone($event)"
                placeholder="Select Timezone">
            </ngx-select>
            <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
              <mat-error *ngIf="customerEdit.controls.timezone.hasError('required')">
                  Timezone is <strong>required</strong>
               </mat-error>
            </div>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-sm-12 col-md-6">
                <mat-checkbox class="btn-block" labelPosition="after" (change)="showTaxExempted($event)" formControlName="tax_exempted" [checked]="taxExemptedCheck">
                    Tax Exempted
                </mat-checkbox>
            </div>
        </div>
        
        <div class="row">
            <div class="form-group col-sm-12 col-md-6">
                <mat-checkbox class="btn-block margin-bottom-5" labelPosition="after" (change)="showRequireApproval($event)" formControlName="require_approval" [checked]="requireApprovalCheck">
                    Require Approval
                </mat-checkbox>
            </div>
        </div>
        <div class="row" [@popOverState]="checkApprovalAmount">
            <div class="form-group col-sm-12 col-md-4" >
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                   <input type="text" matInput placeholder="Amount" title="Amount" formControlName="approval_amount"  class="form-control input-lg custom-form-input" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event);validateNumbersWithDecimal($event,3)" maxlength="12">
                   <mat-error *ngIf="customerEdit.controls.approval_amount.hasError('pattern')">
                    Must be a number with upto 2 decimal places.
                  </mat-error>
                </mat-form-field>
            </div>  
            <p class="help-block col-sm-12"><strong>Note: </strong>If the Grand Total is exceeded by this amount, shop needs approval.</p>
        </div>   
        
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <mat-checkbox class="btn-block" labelPosition="after" (change)="isSubletWork($event)" [(ngModel)]="isSubletWorkCheck" [ngModelOptions]="{standalone: true}">
                    Sublet Work
                </mat-checkbox>
            </div>
        </div>
        <div class="row">
            <p class="help-block col-sm-12"><strong>Note: </strong>Use shop account as customer for sublet work</p>
        </div> 
    </div>

    <div class=" col-xs-12 form-block submit-block">
        <button type="submit" mat-raised-button class="car-btn btn-block text-uppercase  btn-lg font-bold fontcolor-white" color="accent" title="Submit" style="max-width: 300px;">Submit</button>
    </div>
</form>
</div>
</div>
</div>
</div>
</div>