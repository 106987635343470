import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ExpensesAddComponent } from '../expenses-add/expenses-add.component'
import { GlobalService } from 'src/environments/global.service';
import { globalConstants } from 'src/environments/globalConstants';
import {Subscription} from 'rxjs';
const EXPENSES_LIST = 'shop-expenseList-';
@Component({
  selector: 'app-expenses-list',
  templateUrl: './expenses-list.component.html',
  styleUrls: ['./expenses-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExpensesListComponent implements OnInit, OnDestroy {
  public options: any = {
    user_id:'',
    user_type:"User",
    search_keyword:"",
    search_by:"",
    is_export:"0",
    start:0,
    limit:10,
    duration:"",
    expense_date_range:"",
    category:""
  };
  public displayedColumns=  ['title', 'description', 'category', 'expense_date', 'amount', 'Actions'];
  public dataSource: MatTableDataSource<ExpensesData> = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  public currentUser: any;
  public record: any;
  public recordsTotal: any;
  public dRangePickerConfig: any = {  // date range picker config
    isDisabled: true
  }; 
  public dateRangeOptions: any = '';
  public searchPanelOpenStatus: boolean = false;
  public searchByList = [
    { id: "", text: "Search By" },
    { id: "1", text: "Title" },
    { id: "2", text: "Description" }
  ];
  public searchByDurationList = [
    { id: "", text: "Duration" },
    { id: "1", text: "Week" },
    { id: "2", text: "Month" },
    { id: "3", text: "Year" },
    { id: "4", text: "Date Range" },
  ];
  public categoriesList : any;
  public subscriptionDataForAPI: Subscription = new Subscription();
  constructor(
    public dialog: MatDialog,
    public globalService: GlobalService
  ) {}

  ngOnInit(): void {
    this.globalService.setMetaData("SHOPS","EXPENSELEDGER");
    this.currentUser = this.globalService.getCurrentUser();
    this.options.user_id = this.currentUser.id;
    this.globalService.ShowDateRange(this.dRangePickerConfig);
    this.reinitializeOptions();
    this.getCategoriesList();
    this.setDataTable();
  }

  reinitializeOptions() {
    this.options=this.globalService.localGetterSetter(this.options,this.options,EXPENSES_LIST);
    this.options.user_type= "User";
    this.options.user_id= this.currentUser.id;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  search(): void {
    this.options.start = 0;
    this.options.expense_date_range = this.dateRangeOptions;
    if(this.dateRangeOptions !== null){
      this.setDataTable();
    } else{
      this.globalService.snackbar('error',"Please select or enter a valid date range");
   }
  }

  checkFormEmpty(button) {
    if (this.options.search_keyword == "" && this.options.search_by == '' && this.options.duration == "" && this.options.category == ''
    ) {
      if (button) { button.color = "primary" }
      return false;
    } else {
      if (button) { button.color = "accent" }
      return true;
    }
  }

  getCategoriesList(){
    let payload ={
      user_id: this.currentUser.id,
      user_type: "User"
    }
    let categoryDDLPlaceholder=[{
      id:'',
      category_name:'Select Category'
    }];
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_EXPENSE_LEDGER_CATEGORIES,payload).subscribe((response:any) =>{
       if(response.result == 1){
           this.categoriesList = categoryDDLPlaceholder.concat(response.data);
       }
    })
}


  setDataTable() {
    this.record = [];
    var callApi = this.globalService.callAPI(globalConstants.API_GET_EXPENSE_LEDGER_LIST, this.options);
    this.subscriptionDataForAPI =  callApi.subscribe((data: any) => {
      if (data.data != undefined) {
        let moreRows = 'false';
        let firstList = 'false';
        this.globalService.localGetterSetter("totalRecords", data.recordsTotal, EXPENSES_LIST);
        if (data.more_rows == "true") {
          moreRows = "true";
        }
        this.globalService.localGetterSetter("more_rows", moreRows, EXPENSES_LIST);
        if (data.start >= 0 && data.start <= 5) {
          firstList = "true";
        }
        this.globalService.localGetterSetter("first_list", firstList, EXPENSES_LIST);
        this.recordsTotal = data.data.length;
        if (data.data.length == undefined) {
          this.dataSource.data = data.data;
          this.AddRecord(data.data)
          this.globalService.localGetterSetter("current-visible", '1', EXPENSES_LIST);
          this.dataSource = new MatTableDataSource(this.record);
          this.dataSource.sort = this.sort;
        } else {
          this.globalService.localGetterSetter("current-visible", this.recordsTotal, EXPENSES_LIST);
          for (var i = 0; i < data.data.length; i++) {
            this.AddRecord(data.data[i]);
          }
          this.dataSource = new MatTableDataSource(this.record);
          this.dataSource.sort = this.sort;
        }
        this.globalService.localGetterSetter(null, this.options, EXPENSES_LIST);
      } else {
  
        this.dataSource = new MatTableDataSource(this.record);
        this.globalService.localGetterSetter("totalRecords", '0', EXPENSES_LIST);
      }
    })
  }

  AddRecord(record) {
    var data = this.createRecord(record);
    this.record.push(data);
  }

  private createRecord(record) {
    return {
      id: record.exp_id,
      title: record.title,
      amount: record.amount,
      category: record.category,
      expense_date: record.expense_date,
      description:record.description,
      copy_expense:record.copy_expense
    };
  }

  
  handleAddEditExpense(row: any = null): void {
    let dialogRef = this.dialog.open(ExpensesAddComponent, {
      panelClass: ['car-dialog-form','add-expenses'],
      data: row
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result ==1){
          this.setDataTable();
        }
    });
  }

  handleDeleteExpenses(expenseData: any): void {
    this.globalService.confirmDialog('Delete', 'Are you sure you want to delete?').subscribe((res:any) => {
      if (res == 'Delete') {
             let payload ={
                 user_id: this.currentUser.id,
                 user_type:"User",
                 exp_id:expenseData.id   
             };
             this.subscriptionDataForAPI =   this.globalService.callAPI(globalConstants.API_DELETE_EXPENSE_LEDGER, payload).subscribe((response:any)=>{
                   if(response.result == '1'){
                        this.globalService.snackbar("success",response.message);
                        this.setDataTable();
                   }else{
                        this.globalService.snackbar('error',response.message);
                   }
             })
      }
    })
  }

  getTotalRecords() {
    return parseInt(localStorage.getItem(EXPENSES_LIST + 'totalRecords'))
  }

  paginate(event, type) {
    this.scrolling();
    if (type == "next") {
      this.options.start = this.options.start + this.options.limit;
    } else {
      this.options.start = this.options.start - this.options.limit;
    }
    this.setDataTable();
  }

  firstList() {
    return this.globalService.firstList(EXPENSES_LIST, this.options);
  }

  lastList() {
    return this.globalService.lastList(EXPENSES_LIST);
  }

  getUpperLimit(currentLastLimit, total) {
    if (currentLastLimit > total) {
      return total;
    } else {
      return currentLastLimit;
    }
  }

  scrolling() {
    this.globalService.setScrolling('.car-datatable', 1000, -20);
  }

  paginateByLimit(event) {
    this.globalService.setScrolling('body', 600, 0);
    this.options.limit = event.pageSize;
    this.options.start = 0;
    this.scrolling();
    this.setDataTable();
  }

  onChangeDays(event: any) {
    if (event == 4) {
      this.dRangePickerConfig.isDisabled = false;
      this.dateRangeOptions = null;
      this.globalService.ShowDateRange(this.dRangePickerConfig);
    } else {
      this.dRangePickerConfig.isDisabled = true;
      this.dateRangeOptions = '';
      this.globalService.ShowDateRange(this.dRangePickerConfig);
    }
  }

  onDateChange(event: any) {
    if (event == null) {
      this.dateRangeOptions = null;
    } else {
      var stringDateFormat = [
        event.start.toISOString(),
        event.end.toISOString()
      ]
      this.dateRangeOptions = stringDateFormat;
    }
  }

  clearSearch() {
    this.options.start = 0;
    this.options.search_keyword = "";
    this.options.search_by = '';
    this.options.category = "";
    this.options.is_export = '0';
    this.dateRangeOptions = '';
    this.options.expense_date_range = '';
    this.options.duration = '';
    this.dRangePickerConfig.isDisabled = true;
    this.globalService.ShowDateRange(this.dRangePickerConfig);
    this.setDataTable();
  }

  downloadExpenseLedgerCSV(){
    this.options.is_export = "1";
    var callApi = this.globalService.callAPI(globalConstants.API_GET_EXPENSE_LEDGER_LIST, this.options);
    this.subscriptionDataForAPI =  callApi.subscribe((reportData: any) => {
        this.options.is_export = '0';
        window.location.href = globalConstants.API_SHOP_DOWNLOAD_SERVICE_REQUEST_LIST + "?t=6&p=" + reportData.csv_url;
      })
  }

  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
  } 
}

export interface ExpensesData {
  id:string
  title: string;
  amount: string;
  category: string;
  expense_date: number;
  description:string;
  copy_expense:string;
}