import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { globalConstants } from '../../../environments/globalConstants';
import { GlobalService } from '../../../environments/global.service';
import { globalFunctions } from '../../../environments/globalFunctions';
import {Http, Headers, Response} from '@angular/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';

@Component({
  selector: 'app-customer-car-vin-scan-dialog',
  templateUrl: './customer-car-vin-scan-dialog.component.html',
  styleUrls: ['./customer-car-vin-scan-dialog.component.scss']
})
export class CustomerCarVinScanDialogComponent {
    public scanVinForm: FormGroup;
    public submitted: boolean = false;
    public currentVinDetails;
    public shopCustomerID;
    public milengthIssue;
    public customerCountryName;
    constructor( @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CustomerCarVinScanDialogComponent>, private formBuilder: FormBuilder, private globalService: GlobalService, private http: Http, private router: Router) {
        this.scanVinForm = this.formBuilder.group({
            vin_number: ['', [Validators.required, Validators.minLength(17)]],
        });
        this.globalService.diaLogObj(this.dialogRef);
        this.shopCustomerID = data.oprs;
    }

    ngOnInit() {

    }
    removespaces(obj){
      obj.target.value  = obj.target.value.replace(/ /g,'')
    }
    checkIfValid() {
        if (this.scanVinForm.valid ) {
            this.submitted = false;
        }
    }
    /**
     * @trackID <:CAR-1312> PartsTech VIN Scan
     * @updated 2019-12-31
     * @updatedBY Vikas Kumar
     * @param VIN Number
     * @return Vehicle Detail(data.data)
     */
    getVinScan() {
        Object.keys(this.scanVinForm.controls).map((controlName) => {
            this.scanVinForm.get(controlName).markAsTouched({onlySelf: true});
        });

        if (this.scanVinForm.valid) {
            let sendOprs = "customer_id="+this.shopCustomerID+"&vin="+this.scanVinForm.value.vin_number.replace(/ /g, '');
            this.globalService.callAPI(globalConstants.API_GET_VIN_DATA_URL, sendOprs).subscribe((data:any) => {
                var message = data.message;
                if(data.result == "1") {
                    this.currentVinDetails = data.data;
                    localStorage.setItem("CustomerVinValues", JSON.stringify(this.currentVinDetails));
                    localStorage.setItem("CustomerVinNumber", this.scanVinForm.value.vin_number);
                    this.dialogRef.close({currentVinDetails: this.currentVinDetails,status:1});
                    this.router.navigate(['/customer/add_cars'])
                } else {
                    if(data.code == "514" || data.code == "515"){
                        this.globalService.confirmDialog(311, "The VIN you trying is already owned by another user. Do you want to send him a request to transfer its ownership to you?").subscribe((data) => {
                            if (data != null) {
                                localStorage.setItem("CustomerVinNumber", this.scanVinForm.value.vin_number);
                                localStorage.setItem("CustomerVinExists", "1");
                                this.currentVinDetails = {vin_number: this.scanVinForm.value.vin_number};
                                this.dialogRef.close({currentVinDetails: this.currentVinDetails,status:0});
                                this.router.navigate(['/customer/add_cars']);
                            }
                        })
                    }else{
                        Object.keys(this.scanVinForm.controls).map((controlName) => {
                            this.scanVinForm.get(controlName).reset();
                        });
                        this.globalService.snackbar("error", message);
                    }
                }

            });

        } else{
            this.submitted = true;
        }
    }

    cancelVinScan() {
        Object.keys(this.scanVinForm.controls).map((controlName) => {
            this.scanVinForm.get(controlName).reset();
        });
        this.submitted = false;
    }

    checkVinValidity() {
        var vin_number = this.scanVinForm.value.vin_number;
        if(vin_number !="") {

            if(vin_number.length >= 17) {
                let rs = vin_number.substr(0, 17);
                this.scanVinForm.controls['vin_number'].setValue(rs);
                this.scanVinForm.controls.vin_number.setErrors(null);
            } else {
                this.scanVinForm.controls.vin_number.setErrors({"VinError": true});
            }
        }
    }

}
