<div class="container-fluid forgotPassword-panel">
    <div class="padding-top-25">
        <div class="login-panel padding-15" *ngIf="!displayMsg">
            <div id="resetPasswordContent" class="row bgcolor-lightgrey">
                <div class="login-panel">
                    <h1 class="text-center main-heading">RESET PASSWORD</h1>
                    <form [formGroup]="shop" novalidate (ngSubmit)="resetPassword()">
                        <!--email field-->
                        <mat-form-field class="car-input btn-block margin-bottom-5" floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                            <input matInput placeholder="Email Address*" title="Email" formControlName="email"  class="form-control input-lg custom-form-input mat-form-field-placeholder" required>
                            <mat-error class="validation-error" *ngIf="shop.controls.email.hasError('required')">
                                Email Address is <strong>required</strong>
                            </mat-error>
                            <mat-error class="validation-error" *ngIf="shop.controls.email.hasError('pattern')">
                                Please enter a valid email address
                            </mat-error>
                        </mat-form-field>
                        <!--submit button-->
                        <button type="submit" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white btn-black-expanded" color="primary"
                                title="Reset Password">SUBMIT</button>
                        <!-- go to login link-->
                        <a mat-raised-button color="accent" href="javascript:void(0);" title="Login" tabindex="5" class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white btn-accent-expanded" [routerLink]= "['/shops/']">LOGIN HERE</a>
                    </form>
                </div>


            </div>
        </div>

    </div>
    <div *ngIf="displayMsg" class="email-success"> 
        <p>Please check your mailbox.<br>
        A link has been sent to your email address to reset your password.</p>
    </div>
</div>
