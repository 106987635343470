import {Component, OnInit, ViewChild, Pipe, Inject} from '@angular/core';
import {AbstractControl, FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd,Params} from '@angular/router';
import {Http, Headers, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../../environments/globalFunctions"
import {globalConstants} from "../../../../environments/globalConstants"
import {GlobalService} from "../../../../environments/global.service"
import {lang} from "../../../../environments/lang-en"
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';
import {AppComponent } from "../../../app.component"

@Component({
  selector: 'app-shop-payment-success',
  templateUrl: './shop-payment-success.component.html',
  styleUrls: ['./shop-payment-success.component.scss']
})
export class ShopPaymentSuccessComponent implements OnInit {

    public subscriptionMessage;
    public serviceNote;
    public timeOut=5;
    public timeInit=1;
    constructor(public app:AppComponent,private activatedRoute: ActivatedRoute,private formBuilder: FormBuilder,public titleService: Title, private http: Http, private router: Router, private globalService: GlobalService) {
        this.subscriptionMessage = localStorage.getItem("shopPaySuccessMsg");
        var addNewKey = JSON.parse(localStorage.getItem("loggedInUser"));
        addNewKey.subscription_data.order_status = '0';
        this.globalService.setMetaData("SHOPS","PAYMENT_SUCESS")
        localStorage.setItem("loggedInUser", JSON.stringify(addNewKey));
        this.serviceNote = 'All the services will be added to your account within 1-2 hours. Once services will be assigned, you will get a notification.';
    }

    ngOnInit() {
        // this.countDown();
    }
    countDown(){
        setTimeout(()=>{
            if(this.timeInit==this.timeOut){
                this.goto();
            }else{
                this.timeInit+=1;
                this.countDown();
            }
        },1000);
    }
    goto(){
        globalFunctions.controlDashboardNavigation();
    }

}
