<div mat-dialog-title class="relative">
  Term and Condition
  <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
    <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam a nulla quis erat elementum consectetur. Nam venenatis interdum
    diam, ac ornare risus suscipit eu. Suspendisse vehicula, odio vitae rhoncus finibus, tortor nulla sagittis enim, non
    ullamcorper ex justo quis mi. Suspendisse potenti. Sed vel felis laoreet, pellentesque magna eu, fermentum ligula. Integer
    nibh nunc, dictum ac pharetra ac, commodo non libero. Aliquam tincidunt arcu et justo mattis, nec eleifend orci egestas.
  </p>
  <p>
    Pellentesque mollis ante non bibendum sodales. Donec orci metus, mollis at dui quis, varius maximus est. Suspendisse
    eget varius neque. Fusce pellentesque semper volutpat. Mauris ut tellus erat. Donec at sollicitudin sem. Quisque consequat
    sollicitudin volutpat. Interdum et malesuada fames ac ante ipsum primis in faucibus. Ut accumsan vehicula facilisis.
    Duis neque arcu, suscipit eget est in, feugiat pellentesque urna. Aenean porta eleifend neque, vel tincidunt sem. Vivamus
    egestas nec justo vitae lobortis. Cras quis iaculis turpis.
  </p>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
  <button mat-raised-button color="accent" mat-dialog-close class="font-bold">Close</button>
</mat-dialog-actions>