<!-- EDIT NEW PART -->
<div class="car-panel edit-new-part-panel" id="mainCompotents">

    <div class="panel panel-primary" id="editPartPanel">
        <div class="panel-heading padding-left-15">
            <h3 class="panel-title">Edit Part</h3>
            <button mat-mini-fab class="custom-btn-back" title="Back" (click)="globalService.goBack();">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
        <div class="panel-body bgcolor-lightgrey" >
            <!-- Add Customer page start -->
            <div id="editPartContent" class="row bgcolor-lightgrey form-content">
                <div class="container-fluid">
                    <form [formGroup]="editNewPart" (ngSubmit)="editPart()" class="form-inline col-xs-12 col-sm-12 col-md-12 col-lg-12" role="form" method="POST" enctype="multipart/form-data">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block">
                            <div class="row">
                                <div class="form-group col-sm-12 col-md-6" *ngIf="currentPartDetails">
                                    <label for="part_name">Part Name</label>
                                    <div class='relative'>
                                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                            <input matInput placeholder="Part Name*" title="Part Name" formControlName="part_name"  class="form-control input-lg custom-form-input"  [(ngModel)]="currentPartDetails.name" />
                                            <mat-error *ngIf="editNewPart.controls.part_name.hasError('required')">
                                                Part Name is required
                                            </mat-error>
                                        </mat-form-field>
    
                                    </div>
                                </div>
    
                                <div class="form-group col-sm-12 col-md-6" *ngIf="currentPartDetails">
                                    <label for="part_number">Part Number</label>
                                    <div class='relative'>
                                        <input class="car-input input-lg custom-form-input" matInput ngui-auto-complete (valueChanged)="partNumber($event)" [source]="observableSourcePN"
                                            [list-formatter]="myListFormatter" placeholder="Part Number" formControlName="part_number" (click)="setReturnFalse()"  id="new-part-no" class="form-control" [(ngModel)]="currentPartDetails.part_number" />
                                        <br/><br/>
                                        <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper" *ngIf="editNewPart.controls.part_number.hasError('required')">
                                            <mat-error>Part Number is required</mat-error>
                                        </div>
    
                                    </div>
                                </div>
                            </div>
    
                            <div class="row">
                                <div class="form-group col-sm-12 col-md-6" *ngIf="currentPartDetails">
                                    <label for="cost">Parts Cost</label>
                                    <div class='relative'>
                                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                            <input matInput placeholder="Parts Cost*" title="Parts Cost" formControlName="cost"  class="form-control input-lg custom-form-input" maxlength="15" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event);convertIntoDecimal($event,'cost',11)" [(ngModel)]="currentPartDetails.cost"/>
                                            <mat-error *ngIf="editNewPart.controls.cost.hasError('required')">
                                                Parts Cost is required
                                            </mat-error>
                                            <mat-error *ngIf="editNewPart.controls.cost.hasError('pattern')">
                                               Must be a number with upto 2 decimal places.
                                        </mat-error>
                                    </mat-form-field>
    
                                </div>
                            </div>
    
                            <div class="form-group col-sm-12 col-md-6" *ngIf="currentPartDetails">
                                <label for="price">Retail Price</label>
                                <div class='relative'>
                                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                        <input matInput placeholder="Retail Price*" title="Retail Price" formControlName="price" class="form-control input-lg custom-form-input" maxlength="15" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event);convertIntoDecimal($event,'price',11)" [(ngModel)]="currentPartDetails.price" />
                                        <mat-error *ngIf="editNewPart.controls.price.hasError('required')">
                                            Retail Cost is required
                                        </mat-error>
                                        <mat-error *ngIf="editNewPart.controls.price.hasError('pattern')">
                                            Must be a number with upto 2 decimal places.
                                    </mat-error>
                                </mat-form-field>
    
                            </div>
                        </div>
                    </div>
    
                    <div class="row">
                        <div class="form-group col-sm-12 col-md-6" *ngIf="currentPartDetails">
                            <label for="vendor">Vendor</label>
                            <div class='relative'>
                                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                    <input matInput placeholder="Vendor" title="Vendor" formControlName="vendor"  class="form-control input-lg custom-form-input" [(ngModel)]="currentPartDetails.vendor" />
                                    <!--                                        <mat-error *ngIf="addNewPart.controls.vendor.hasError('required')">
                                                                                This field cannot be empty
                                                                            </mat-error>-->
                                </mat-form-field>
    
                            </div>
                        </div>
    
                        <div class="form-group col-sm-12 col-md-6" *ngIf="currentPartDetails">
                            <label for="manufacturer">Manufacturer</label>
                            <div class='relative'>
                                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                    <input matInput placeholder="Manufacturer" title="Manufacturer" formControlName="manufacturer"  class="form-control input-lg custom-form-input" [(ngModel)]="currentPartDetails.manufacturer" />
                                    <!--                                            <mat-error *ngIf="addNewPart.controls.manufacturer.hasError('required')">
                                                                                This field cannot be empty
                                                                            </mat-error>-->
                                </mat-form-field>
    
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="isInventory">
                        <div class="form-group col-md-6 col-sm-12">
                            <label for="part_qty">Part Quantity</label>
                            <div class="relative details-input">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <input matInput maxlength="15" placeholder="Part Quantity" title="Part Quantity" formControlName="part_qty" class="input-lg custom-form-input"  [(ngModel)]="currentPartDetails.quantity" (keydown)="disableKey($event)"  (keypress)="onlyDecimalNumberKey($event);convertIntoDecimal($event,'part_qty',11)"  />
                                    <mat-error *ngIf="editNewPart.controls.part_qty.hasError('required')">
                                        Part Quantity is required
                                    </mat-error>
                                    <mat-error *ngIf="editNewPart.controls.part_qty.hasError('pattern')">
                                        Must be a number with upto 2 decimal places.
                                </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-group col-md-6 col-sm-12">
                            <label for="low_stock_qty">Low Stock Quantity</label>
                            <div class="relative details-input">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <input matInput maxlength="10" placeholder="{{lowStockQty}}" title="Low Stock Quantity" formControlName="low_stock_qty" class="input-lg custom-form-input"  [(ngModel)]="currentPartDetails.low_stock_value" (keydown)="disableKey($event)"  (keypress)="onlyDecimalNumberKey($event);convertIntoDecimal($event,'low_stock_qty',11)" />
                                    <mat-error *ngIf="editNewPart.controls.low_stock_qty.hasError('required')">
                                        Low Stock Quantity is required
                                    </mat-error>
                                    <mat-error *ngIf="editNewPart.controls.low_stock_qty.hasError('pattern')">
                                        Must be a number with upto 2 decimal places.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                            <div class="form-group col-sm-6  uc-ngx-select">
                                <label for="oem_part_number">Tags</label>
                                <div class="relative">
                                     <ngx-select [defaultValue]="preSelectTag" [autoClearSearch]="true" class="display-full margin-bottom-20 tags-select" formControlName="part_tags" [multiple]="true" [items]="allTags" optionTextField="tag_name" placeholder="Select Tags"></ngx-select>
                                </div>
                            </div>
                            <div class="form-group col-sm-6">
                                    <label for="oem_part_number" class="hidden-xs">&nbsp;</label>
                                 <div class="">
                                    <button type="button" mat-raised-button class="car-btn btn-block text-uppercase font-light fontcolor-white manage-tags-btn" id="manageTags" color="accent" title="Manage Tags" (click)="manageTag()">Manage Tags</button>
                                 </div>
                            </div>
                        </div>
                    <div class="row">
                        <div class="form-group col-sm-12 col-md-6" *ngIf="currentPartDetails">
                            <label for="oem_part_number">OEM Part Number</label>
                            <div class='relative'>
                                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                    <input matInput placeholder="OEM Part Number" title="OEM Part Number" formControlName="oem_part_number"  class="form-control input-lg custom-form-input" [(ngModel)]="currentPartDetails.oem_part_number" />
                                    <!--                                        <mat-error *ngIf="addNewPart.controls.oem_part_number.hasError('required')">
                                                                                This field cannot be empty
                                                                            </mat-error>-->
                                </mat-form-field>
    
                            </div>
                        </div>
                    </div>
    
                    <div class="row">
                        <div class="form-group col-sm-12" *ngIf="currentPartDetails">
                            <label for="description">Description</label>
                            <div class="relative details-input">
                                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                    <textarea matInput placeholder="Description" title="Description" formControlName="description" class="form-control input-lg custom-form-input" [(ngModel)]="currentPartDetails.description"></textarea>
                                    <!--                                        <mat-error *ngIf="addNewPart.controls.description.hasError('required')">
                                                                                This field cannot be empty
                                                                            </mat-error>-->
                                </mat-form-field>
    
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-12 col-md-6">
                            <label for="partPic">Parts Picture</label>
                            <div class="col-sm-12 file-upload-container" style="padding:0px;">
                                <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative">
                                    <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                                    Drag File Here
                                    <input type="file" id="partPic" name="part_pic" accept="image/*" (change)="getFile($event,'part_pic')" formControlname="part_picture"/>
                                    <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                                </mat-toolbar>
                                <input id="partPicImagedata" name="part_pic" value="" type="hidden" formControlName="part_picture">
                            </div>
                            <div class="col-sm-12" style="padding:0px;">
                               
                                <ul class="list-inline upload-image margin-bottom-0">
                                    <li *ngIf="partPicture!='' && partPicture!=null && partPicture!=undefined && currentPartPicture==''">
                                        <a href="javascript:void(0);" class="img-set thumbnail relative" (click)="openImageDialog(10);">
                                            <img class="show-image" src="{{partsPic}}{{partPicture}}" />
                                            <i class="fa fa-trash remove-img" (click)="deleteExistingPartPic()"></i>
                                        </a>
                                    </li>
                                    <li *ngIf="currentPartPicture!=''">
                                        <a href="javascript:void(0);" class="img-set thumbnail relative" (click)="openImageDialog(10);">
                                            <img class="show-image" src="{{currentPartPicture}}" />
                                            <i class="fa fa-trash remove-img" (click)="deletePartPic()"></i>
                                        </a>
                                    </li>
                                </ul>
                                <p class="help-block" style="margin:0px;">Formats supported : png, jpeg, jpg</p>
                                <p class="help-block" style="margin:0px;">Maximum size : 10MB</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block submit-block">
                            <button type="submit" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-light fontcolor-white" color="accent" title="Submit"  style="max-width: 300px;">
                                SUBMIT
                            </button>
                        </div>
                    </div>
                </div>
            </form>
                </div>
            </div>
        </div>
    </div>    
</div>
