import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { GlobalService } from 'src/environments/global.service';
import { globalConstants } from 'src/environments/globalConstants';
import { globalFunctions } from 'src/environments/globalFunctions';

@Component({
  selector: 'app-review-payment-config',
  templateUrl: './review-payment-config.component.html',
  styleUrls: ['./review-payment-config.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class ReviewPaymentConfigComponent implements OnInit {
  @Output() handleSubmitUrlAndNotes = new EventEmitter<any>();
  @Input() screenType :any;
  public pvtReviewSettingForm: FormGroup;
  public extPaymentSettingForm : FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    public globalService: GlobalService
  ) { }

  ngOnInit(): void {
    let currentUserInfo = JSON.parse(localStorage.getItem("loggedInUser"));
    if(currentUserInfo){
    this.pvtReviewSettingForm = this.formBuilder.group({
      review_notes:[''],
      review_notes_included:[''],
      review_url: ['',Validators.pattern(globalConstants.WEB_URL_REGEX)],
      review_url_included:[''],
      update_shop_profile:[0],
      user_type: currentUserInfo.user_type,
      user_id: currentUserInfo.id,
      shop_id: currentUserInfo.shop_id
    })

    this.extPaymentSettingForm = this.formBuilder.group({
      payment_notes:[''],
      payment_notes_included:[''],
      payment_url:['', Validators.pattern(globalConstants.WEB_URL_REGEX)],
      payment_url_included:[''],
      update_shop_profile:[0],
      user_type: currentUserInfo.user_type,
      user_id: currentUserInfo.id,
      shop_id: currentUserInfo.shop_id
    })

    if(this.screenType == 0){
         this.getPvtReviewSettingInfo(currentUserInfo);
    }else if(this.screenType == 1){
        this.getExtPaymentSettingInfo(currentUserInfo);
    }
   }
  }

getPvtReviewSettingInfo(currentUserInfo){
  let payload ={
    user_id: currentUserInfo.id,
    user_type:currentUserInfo.user_type,
    shop_id:currentUserInfo.shop_id,
  };
  this.globalService.callAPI(globalConstants.API_GET_PRIVATE_REVIEW_SETTING,payload).subscribe((res:any) => {
    if(res.result == 1){
        this.pvtReviewSettingForm.controls.review_notes.setValue(res.data.review_notes);
        this.pvtReviewSettingForm.controls.review_notes_included.setValue(res.data.review_notes_included);
        this.pvtReviewSettingForm.controls.review_url.setValue(res.data.review_url);
        this.pvtReviewSettingForm.controls.review_url_included.setValue(res.data.review_url_included);
    }
  })
}

getExtPaymentSettingInfo(currentUserInfo){
  let payload ={
    user_id: currentUserInfo.id,
    user_type:currentUserInfo.user_type,
    shop_id:currentUserInfo.shop_id,
  };
  this.globalService.callAPI(globalConstants.API_GET_EXTERNAL_PAYMENT_SETTING,payload).subscribe((res:any) => {
       if(res.result == 1){
        this.extPaymentSettingForm.controls.payment_notes.setValue(res.data.payment_notes);
        this.extPaymentSettingForm.controls.payment_notes_included.setValue(res.data.payment_notes_included);
        this.extPaymentSettingForm.controls.payment_url.setValue(res.data.payment_url);
        this.extPaymentSettingForm.controls.payment_url_included.setValue(res.data.payment_url_included);
       }
  })
}


handleIncludeReviewNotes(event:any){
    if (event == true) {
        this.pvtReviewSettingForm.controls.review_notes_included.setValue(1);
        this.pvtReviewSettingForm.get('review_notes').setValidators([Validators.required]);
        this.pvtReviewSettingForm.get('review_notes').updateValueAndValidity();
    } else {
        this.pvtReviewSettingForm.controls.review_notes_included.setValue(0);
        this.pvtReviewSettingForm.get('review_notes').clearValidators();
        this.pvtReviewSettingForm.get('review_notes').updateValueAndValidity();
    }
    
}

handleIncludeReviewUrl(event: any) {
  if (event) {
      this.pvtReviewSettingForm.controls.review_url_included.setValue(1);
      this.pvtReviewSettingForm.get('review_url').setValidators([Validators.required,Validators.pattern(globalConstants.WEB_URL_REGEX)]);
  } else {
      this.pvtReviewSettingForm.controls.review_url_included.setValue(0);
      this.pvtReviewSettingForm.get('review_url').setValidators([Validators.pattern(globalConstants.WEB_URL_REGEX)]);

  }
  this.pvtReviewSettingForm.get('review_url').updateValueAndValidity();
}

handleIncludePaymentNotes(event:any){
    if (event == true) {
        this.extPaymentSettingForm.controls.payment_notes_included.setValue(1);
        this.extPaymentSettingForm.get('payment_notes').setValidators([Validators.required]);
        this.extPaymentSettingForm.get('payment_notes').updateValueAndValidity();
    } else {
        this.extPaymentSettingForm.controls.payment_notes_included.setValue(0);
        this.extPaymentSettingForm.get('payment_notes').clearValidators();
        this.extPaymentSettingForm.get('payment_notes').updateValueAndValidity();
    }
}

handleIncludePaymentUrl(event: any) {
  if (event) {
      this.extPaymentSettingForm.controls.payment_url_included.setValue(1);
      this.extPaymentSettingForm.get('payment_url').setValidators([Validators.required,Validators.pattern(globalConstants.WEB_URL_REGEX)]);
  } else {
      this.extPaymentSettingForm.controls.payment_url_included.setValue(0);
      this.extPaymentSettingForm.get('payment_url').setValidators([Validators.pattern(globalConstants.WEB_URL_REGEX)]);
  }
  this.extPaymentSettingForm.get('payment_url').updateValueAndValidity();
}

validateNumbersNoDecimal(event) {
    return globalFunctions.validateNumbersNoDecimal(event);
  }

validateNumbersWithDecimal(event) {
    return globalFunctions.validateNumbersWithDecimal(event,2);
  }

handleUpdatePaymentsShopProfile(event:any){
    if (event == true) {
        this.extPaymentSettingForm.controls.update_shop_profile.setValue(1);
    } else {
        this.extPaymentSettingForm.controls.update_shop_profile.setValue(0);
    }
}

handleUpdateReviewsInShopProfile(event:any){
  if (event == true) {
      this.pvtReviewSettingForm.controls.update_shop_profile.setValue(1);
  } else {
      this.pvtReviewSettingForm.controls.update_shop_profile.setValue(0);
  }
}
  
  AddPvtReviewSetting(){
    if (this.pvtReviewSettingForm.invalid) {
      this.globalService.snackbar("error", globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
      return;
    }
      this.globalService.callAPI(globalConstants.API_UPDATE_PRIVATE_REVIEW_SETTINGS,this.pvtReviewSettingForm.value).subscribe((res:any) => {
          if(res.result == '1'){
            this.globalService.snackbar("success", res.message);
            let returnObj ={
              pvtReviewsSettingsObj:res.data,
              screenType:0
            }
            this.handleSubmitUrlAndNotes.emit(returnObj);
          }else{
            this.globalService.snackbar("error", res.message);
          }
      })
  }

  AddExtPaymentSetting(){
    if (this.extPaymentSettingForm.invalid) {
      this.globalService.snackbar("error", globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
      return;
    }
      this.globalService.callAPI(globalConstants.API_UPDATE_EXTERNAL_PAYMENT_SETTINGS,this.extPaymentSettingForm.value).subscribe((res:any) => {
        if(res.result == '1'){
          this.globalService.snackbar("success", res.message);
          let returnObj ={
            extPaymentSettingsObj:res.data,
            screenType:1
          }
          this.handleSubmitUrlAndNotes.emit(returnObj);
        }else{
          this.globalService.snackbar("error", res.message);
        }
      })
  }

  closeDialogBox(){
    let returnObj ={
      extPaymentSettingsObj:"",
      screenType:3
    }
    this.handleSubmitUrlAndNotes.emit(returnObj);
  }
}
