<mat-accordion class="quickAction-expansion">
  <mat-expansion-panel [expanded]="showOptions" class="relative" id="quickActionExpansion" (mouseenter)="toggleOptionsOnHover()">
    <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="40px" id="quickActionexpansionHead" (click)="toggleOptions()">
      <span class="margin-right-5"> </span><b>{{title}}</b>
    </mat-expansion-panel-header>
    <div class="row" *ngIf="serviceInfo">
      <div class=" col-xs-12">
        <ul class="quickaction list-inline padding-0 margin-0">
          <li>
            <span (click)="ticketAssignment()"  *ngIf="skipTicketAssign && isTicketAssign()" ><i class="fa fa-ticket text-green"></i>Ticket Assignment</span>
          </li>
          <li *ngIf="isServiceNote">
            <span (click)="srnoteList()" title="Open Notes"> 
              <i class="fa fa-regular fa-pen-to-square"></i>Open Notes
            </span>
          </li>
          <!-- <li *ngIf="serviceInfo.notify_to_customer == '0'">
            <span (click)="saveRecommendations(0)" title="Save & Stay">
              <i class="fa fa-save"></i>Save Recommendation
            </span>
          </li> -->
          <li>
            <span (click)="parent.saveAndStay(0,false)" title="Save & Stay">
              <!-- <i class="fa fa-save"></i>Save Recommendation -->
              <i class="fa fa-save"></i>Save
            </span>
          </li>
          <li>
            <span (click)="parent.saveAndClose(1, false)" title="Save & Close">
              <!-- <i class="fa fa-save"></i>Save Recommendation -->
              <i class="fa fa-save"></i>Close
            </span>
          </li>
          <li>
            <span (click)="parent.saveAndSend(2, false)" title="Send Updated Information{{ user.profile_type == '2' ? '' : ' to Customer' }}">
              <i class="fa fa-regular fa-envelope"></i>Send
            </span>
          </li>
          <li *ngIf="isAllowed('mark_service_complete','views')">
            <span
              *ngIf="(serviceInfo.request_status==2 || serviceInfo.request_status==3 || serviceInfo.request_status==7 || serviceInfo.request_status==12 )"
              (click)="parent.saveAndComplete(3, false)" title="Service Completion">
              <i class="fa fa-regular fa-square-check"></i>Complete
            </span>
          </li>
          <li>
              <span (click)="payment()" *ngIf="isPayment()" ><i class="fa fa fa-usd"></i>Payments</span>
          </li>
          <li *ngIf="isAddCoupon()">
            <span (click)="addCoupon()"><i class="fa fa-gift"></i>Create Coupon Code</span>
          </li>
          <li *ngIf="isVisible()">
            <span (click)="viewLogs()" ><i class="fa fa-eye"></i>View Activity Logs </span>
          </li>
          <li>
            <span (click)="openServiceHistory()">
              <!-- <i class="fa fa-history"></i> -->
              <img [src]="gc.SERVICE_HISTORY_ICON" style="width:15px" />
              View Service History
            </span>
          </li>
          <li>
            <span (click)="openSubletHistory()">
              <i class="fa fa-history"></i>
              View Sublet Jobs
            </span>
          </li>
          <li *ngIf="parent && parent.isRepairLinkAllowed">
            <span (click)="parent.openExternalUrl(1)">
              <i class="fa fa-link"></i>
              RepairLink
            </span>
          </li>
        </ul>

      </div>
    </div>
    <div class="row" *ngIf="viewServiceInfo">
      <div class=" col-xs-12">
        <ul class="quickaction list-inline padding-0 margin-0">
          <li>
            <span (click)="ticketAssignment()" *ngIf="skipTicketAssign && isTicketAssign()" ><i class="fa fa-ticket text-green"></i>Ticket Assignment</span>
          </li>
          <li *ngIf="isServiceNote">
            <span (click)="srnoteList()" title="Open Notes"><i class="fa fa-regular fa-pen-to-square"></i>Open Notes</span>
          </li>
          <li>
              <span (click)="payment()" *ngIf="isPayment()" ><i class="fa fa fa-usd"></i>Payments</span>
          </li>
          <li *ngIf="isAddCoupon()">
            <span (click)="addCoupon()" ><i class="fa fa-gift"></i>Create Coupon Code</span>
          </li>
          <li *ngIf="isVisible()">
            <span (click)="viewLogs()" ><i class="fa fa-eye"></i>View Activity Logs </span>
          </li>
          <li>
            <span (click)="openServiceHistory()">
              <img [src]="gc.SERVICE_HISTORY_ICON" style="width:15px" />
              View Service History
            </span>
          </li>       
          <li>
            <span (click)="openSubletHistory()">
              <i class="fa fa-history"></i>
              View Sublet Jobs
            </span>
          </li>
        </ul>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>