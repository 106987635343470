<div mat-dialog-title class="relative qq-dialog-title">
  <span class="title-span">Quick Quote View</span>
  <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
    <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="quick-quote-view">
  <div class="panel panel-primary ">
    <div class="panel-heading">
      <h3 class="panel-title">Quote Detail</h3>
       <button *ngIf="isAllowed('payment','views')" class="font-bold mat-raised-button payment-btn mat-success pull-right" (click)="payments()" title="Payments">
        <span class="hidden-xs" >Payments</span>
        <span class="hidden-lg hidden-sm hidden-md"><i class="fa fa-usd"></i></span>
       </button>
      <button class="font-bold mat-raised-button edit-quote-btn mat-accent pull-right" *ngIf="param.qq && recomendationData.status=='0' && editable" (click)="editJobs()" [class.edit-quote-btn-single]="!isAllowed('payment','views')"
        title="Edit Quote">
        <span class="hidden-xs" >
        Edit Quote
      </span>
      <span class="hidden-lg hidden-sm hidden-md">
        <i class="fa fa-pencil"></i>
      </span>
      </button>
      <button class="font-bold mat-raised-button print-quote-btn mat-accent pull-right" *ngIf="param.qq" (click)="generateQuote()" [class.print-quote-btn-single]="!isAllowed('payment','views')" [class.print-quote-btn-alone]="!(param.qq && recomendationData.status=='0' && editable)"  [class.print-quote-btn-payment]="(param.qq && recomendationData.status=='1') || !(editable)"
        title="Print Quote">
        <span class="hidden-xs" >
        Print Quote
      </span>
      <span class="hidden-lg hidden-sm hidden-md">
        <i class="fa fa-print"></i>
      </span>
      </button>
    </div>

    <div class="panel-body bgcolor-lightgrey">
      <div class="jumbotron padding-25 margin-bottom-0 car-view-str">
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Quote ID:</label></div>
              <div class="col-sm-6">{{recomendationData.id}}</div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
             <div class="row">
              <div class="col-sm-6"><label>Customer Name :</label></div>
              <div class="col-sm-6">{{recomendationData.first_name}} {{recomendationData.last_name}}</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
           
            <div class="row">
              <div class="col-sm-6"><label>Add Date:</label></div>
              <div class="col-sm-6">{{formatDate(recomendationData.add_date)}}</div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Vehicle :</label></div>
              <div class="col-sm-6">
               
                <span [innerHTML]="vehicleName ? vehicleName:'N/A'" style="word-break: break-all;" >

                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Created By :</label></div>
              <div class="col-sm-6">{{recomendationData.user_name}}</div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Customer Mobile No. :</label></div>
              <div class="col-sm-6"><a class="text-black" href="tel:{{recomendationData.mobile_phone}}">{{recomendationData.mobile_phone}}</a></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 margin-bottom-10">
            <div class="row">
              <div class="col-sm-3"><label>Notes :</label></div>
              <div class="col-sm-9">
                <span [innerHTML]="recomendationData.notes ? getNltoBr(recomendationData.notes):'-'"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <mat-accordion class="new-request-expension show-price margin-bottom-10 margin-top-10 display-full" displayMode="flat"
    hideToggle="false">
    <mat-expansion-panel [expanded]="i==0" hideToggle="true" class="new-request-expension relative"
      *ngFor="let job of allJobs;let i = index" id="qqvexpansion-{{i+1}}" style="border-radius: 15px 15px;">
      <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="qqvexpansionHead-{{i+1}}"
        [attr.data-state]="0">
        <span class="margin-right-5">{{ i+1 }}.</span><b>{{ job.name }}</b>
      </mat-expansion-panel-header>
      <ul class="service-list-items">
        <li *ngFor="let subChild of job.children;let j = index"><p>{{subChild.name}}</p>
          <!-- level 2-->
          <div *ngIf="(subChild.children && subChild.children.length==0)">
            <ng-container *ngTemplateOutlet="viewrecomtmp;context:{'jobs':subChild}">
            </ng-container>
          </div>
          <!-- level 2-->
          <!-- level 3-->
          <div *ngIf="subChild.children && subChild.children.length" class="padding-left-15" >
            <div *ngFor="let subChild3 of subChild.children;let k=index">
              <p>{{subChild3.name}}</p>
              <ng-container *ngTemplateOutlet="viewrecomtmp;context:{'jobs':subChild3}">
              </ng-container>
            </div>
          </div>
          <!-- level 3-->
        </li>
      </ul>
    </mat-expansion-panel>
  </mat-accordion>
</mat-dialog-content>

<div class="" *ngIf="recomendationData && showCostPrice">
  <div class="col-sm-12">
    <h4 class="text-right relative payment-info-wrap margin-0">
      <span class="payment-info">
        <strong>Total : </strong>$ <span> {{recomendationData.grand_total}}</span>
      </span>
       <span class="payment-info">
        <strong>Paid : </strong>$ <span> {{recomendationData.total_paid}}</span>
      </span>
    <i class="tax-note">
      Taxes and supplies are not included
    </i>
    </h4>
    
  </div>
</div>

<ng-template #viewrecomtmp let-jobs="jobs">
   <div class="row margin-bottom-0" *ngIf="jobs.id!='158'">
      <div class="row margin-bottom-0" *ngIf="selectedGenericJob && selectedGenericJob[jobs.id] && selectedGenericJob[jobs.id].length">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="col-sm-3 text-right control-label">Generic Job(s):</label>
            <div class="col-sm-9">            
                <div class="display-full row" *ngFor="let GJ of selectedGenericJob[jobs.id];let i=index">
                  <div class="col-md-5 col-lg-7 pname break-all">{{GJ.label}}</div>
                  <div class="col-md-7 col-lg-5">
                    <div class="row">
                      <div class="col-sm-5 pprice" *ngIf="showCostPrice">${{GJ.price}}</div>
                      <div class="col-sm-3 pquantity">{{GJ.quantity}} </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom-0" *ngIf="selectedParts && selectedParts[jobs.id] && selectedParts[jobs.id].length">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="col-sm-3 text-right control-label">Part(s):</label>
            <div class="col-sm-9">           
                <div class="display-full row" [class.text-orange]="part.partstech_cart_item==1" *ngFor="let part of selectedParts[jobs.id];let i=index">
                  <div class="col-md-5 col-lg-7 pname break-all">{{part.label}}</div>
                  <div class="col-md-7 col-lg-5">
                    <div class="row">
                      <div class="col-sm-5 pprice" *ngIf="showCostPrice"><span>${{part.price}}</span></div>
                      <div class="col-sm-3 pquantity">{{part.quantity}} </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom-0">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="col-sm-3 text-right control-label">Time:</label>
            <div class="col-sm-9">
              <div class="form-inline relative">
                <div class="complete-wrap mat-form-field">{{times[jobs.id] || '-'}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom-0">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="col-sm-3 text-right control-label">Labor:</label>
            <div class="col-sm-9">
              <div class="form-inline relative">
                <div class="complete-wrap mat-form-field"><span *ngIf="showCostPrice"> $ {{ labors[jobs.id] || '0.00'}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-bottom-0">
        <div class="col-sm-12">
          <div class="form-group margin-bottom-0">
            <label class="col-sm-3 text-right control-label margin-bottom-0">Notes:</label>
            <div class="col-sm-9" [innerHTML]="getNltoBr(notes[jobs.id])">
                
            </div>
          </div>
        </div>
      </div>
    </div>
</ng-template>
