<div mat-dialog-title class="relative">
  Subscription Plans
  <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
    <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="center-block" style="max-width: 600px;">
    <form class="form-horizontal form-control-normal" autocomplete="off" >
      <div class="display-full margin-bottom-5">
        <div class="col-sm-3">Plans</div>
        <div class="col-sm-9 ">
            <mat-form-field class="btn-block car-input" floatPlaceholder="never">
                <mat-select placeholder="Select Subscription Plans" class="form-control input-lg custom-form-input" (change)="changePlan($event)">
                    <mat-option>Select Subscription Plan</mat-option>
                    <mat-option *ngFor="let p of plans" [value]="p.id" >{{p.plan_features}}</mat-option>
                </mat-select>
            </mat-form-field>
          
        </div>
      </div>
      <div class="display-full margin-bottom-5" >
        <div class="col-sm-3">Price</div>
        <div class="col-sm-9">${{plan.amount}}</div>
        <div class="col-sm-3">Duration</div>
        <div class="col-sm-9">{{plan.duration}} Month(s)</div>
        <div class="col-sm-3">Description</div>
        <div class="col-sm-9">{{plan.subscription_desc}}</div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
  <button mat-raised-button color="accent" (click)="submitPlan()" class="font-bold">Activate Plan</button>
  <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
</mat-dialog-actions>