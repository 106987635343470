import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
import { Http } from '@angular/http';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';


export interface Condition{
  type:string;
  root?:boolean,
  removed?:boolean,
  aggregator?:string,
  operator?:string,
  value?:string,
  conditions?:Array<Condition>, 
  invalid?:boolean,
  ngModel?:any
}

@Component({
  selector: 'app-discount-rules',
  templateUrl: './discount-rules.component.html',
  styleUrls: ['./discount-rules.component.scss']
})
export class DiscountRulesComponent implements OnInit {

  @HostListener("click", ["$event"])
  public onClick(event: any): void
  {
    event.stopPropagation();
    // console.log('stopping propagation');
  }
  
  _submitted:boolean;
  
  matcher = new RuleStateMatcher();
  
  @Input('rule') rule: any;
  @Input('condition-types') conditionTypes: any;
  @Input() 
  set submitted(submitted:boolean){
    this._submitted = submitted;
    this.matcher.isSubmitted = submitted;
  }
  get submitted(){
    return this._submitted;
  }
  
  @Output('onValid') onValid = new EventEmitter<boolean>();
  @Output('onRuleRemoved') onRuleRemoved = new EventEmitter<boolean>();
  
  private id:number = 1;
  /**
   * Variables for discount rules
   * updated by - Shashank Thakur
   * updated on - 17/07-2020
   * CAR-1571
   */
  aggregators:Array<any> = [
    {
      id:'all',
      name: 'All'
    },
    {
      id:'any',
      name: 'Any'
    }
  ];
  
  operators:Array<any> = [
    {
      id:'>',
      name: 'Greater than'
    },
    {
      id:'>=',
      name: 'Greater than equal to'
    },
    {
      id:'<',
      name: 'Less than'
    },
    {
      id:'<=',
      name: 'Less than equal to'
    },
    {
      id:'=',
      name: 'Equals to'
    },
    // {
    //   id:'!=',
    //   name: 'not equal to'
    // }
  ];
  
  invalid:boolean;
  
  discountConditionRule:any = globalConstants.DISCOUNT_CONDITION_TYPE;

  constructor(public gs: GlobalService,public router: Router, public http: Http,public dialog: MatDialog) {
  }

  ngOnInit() {
    // console.log(this.seletedCar)
  }
  
  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
  }

  disableKey(event) {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey == true && (event.which == '67' || event.which == '86' || String.fromCharCode(event.which).toLowerCase() == 's')) {
      console.log('thou. shalt. not. PASTE!');
      event.preventDefault();
    }
  }
  
  /**
  * Functions for discount rules
  * updated by - Shashank Thakur
  * updated on - 17/07-2020
  * CAR-1571
  */
    
  conditionChange(condition:any){
    // console.log('condition change = ', condition);
    setTimeout(()=>{
      if(condition.type == this.discountConditionRule.value && !condition.root){
        condition.aggregator = 'all';
        condition.conditions = [];
        delete(condition.operator);
        delete(condition.value);
      }
      else{
        condition.operator = this.operators[0].id;
        delete(condition.aggregator);
        delete(condition.conditions);
      }
      // console.log('condition change new rules = ', this.rule);
    }, 500);
  }

  addCondition(rule:Condition){
    // console.log('adding condition ');
    // console.log('conditions = ', rule);
    if(!rule.conditions){
      rule.conditions = [];
    }
    let newCondition:Condition = {
      type: this.conditionTypes[1].value,
      operator: this.operators[0].id,
      value:'',
      ngModel: 'ruleValue_'+this.id++
    }
    rule.conditions.push(newCondition);
  }
  
  addCombineCondition(rule:Condition){
    // console.log('adding condition ');
    // console.log('conditions = ', rule);
    if(!rule.conditions){
      rule.conditions = [];
    }
    let newCondition:Condition = {
      type: this.discountConditionRule.value,
      aggregator:'all',
      conditions:[]
    }
    rule.conditions.push(newCondition);
  }

  removeCondition(rule:Condition){
    // console.log('removing condition ');
    // console.log('conditions = ', rule);
    rule.removed = true;
    if(rule.type == this.discountConditionRule.value){
      rule.conditions.forEach((condition)=>{
        this.removeCondition(condition);
      });
    }
    this.onRuleRemoved.emit(true);
    this.validateConditions(rule);
  }

  clearRulesJSON(rule:Condition){
    if(rule.conditions){
      rule.conditions.forEach((c, i)=>{
        if(c.removed){
          rule.conditions.splice(i,1);
        }
        if(c.type === this.discountConditionRule.value){
          this.clearRulesJSON(c);
        }
      });
    }
  }
  
  getRulesAndConditions(){
    this.validateConditions(this.rule);
    if(!this.invalid){
      this.clearRulesJSON(this.rule);
      return this.rule;
    }
    else{
      return '';
    }
  }
  
  getRulesAndConditionsStringified(){
    this.validateConditions(this.rule);
    if(!this.invalid){
      this.clearRulesJSON(this.rule);
      return JSON.stringify(this.rule);
    }
    else{
      return '';
    }
  }
  
  validateConditions(rule:Condition){
    if(rule.conditions){
      for(let i=0; i<rule.conditions.length; i++){
        let c:Condition = rule.conditions[i];
        // console.log('c = ', c);
        if(c.type !== this.discountConditionRule.value && c.value == '' && !c.removed){
          this.invalid = true;
          this.onValid.emit(false);
          // this.gs.snackbar('error', globalConstants.MESSAGE_RULES_VALIDATION);
          break;
        }
        else{
          // console.log('valid now');
          this.invalid = false;
          this.onValid.emit(true);
          this.validateConditions(c);
        }
      }
      // rule.conditions.every((c:Condition, i)=>{
      // });
    }
  }
  
  onValueUpdate(rule:Condition){
    // console.log('value updated = ', rule);
    if(rule.value == '' || !rule.value){
      rule.invalid = true;
      this.onValid.emit(false);
    }
    else{
      rule.invalid = false;
      this.onValid.emit(true);
    }
  }
  
  stopClick(ev){
    ev.stopPropagation();
    ev.preventDefault();
    // console.log('stopping 2');
  }
  
  reset(){
    this.rule = {
      type:globalConstants.DISCOUNT_CONDITION_TYPE.value,
      aggregator:"all",
      root:true,
      conditions:[]
    };
  }
  
  ngOnDestroy(){
    
  }

}

export class RuleStateMatcher implements ErrorStateMatcher {
  isSubmitted:boolean;
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    // console.log('control = ', control);
    // console.log('form = ', form);
    return !!(control && control.invalid && (control.dirty || control.touched || this.isSubmitted));
  }
}
