<div class="panel panel-primary margin-0">
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">Payment Status</h3>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <div class="payment-response sucess"  style="height: auto;">
            <div>
                <div class="payment-content" style="max-width: 100%;">
                    <span>
                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                    </span>
                    <h2 class="title">Success!</h2>
                    <h3 class="msg" [innerHTML]="subscriptionMessage"></h3>
                    <!-- <div class="msg"><strong>Note:</strong> {{serviceNote}}</div> -->
                </div>
                <div class="payment-content" >
                    <h5>Redirecting to Dashboard ...</h5>
                    <button mat-raised-button color="accent" type="button" (click)="goto()">Dashboard</button>
                </div>
            </div>
        </div>
    </div>
</div>