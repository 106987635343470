import { Component, OnInit,ChangeDetectorRef,OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
import { globalFunctions } from '../../../../environments/globalFunctions';
import {Http, Headers, Response, ResponseContentType} from '@angular/http';
import  saveAs  from 'file-saver';
import {Subscription} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ExpensesAddComponent } from './expenses-add/expenses-add.component'

export interface ReportData {
  total_no_sr: number
  rejected_no_sr:number
  sr_sales: number
  total_revenue: number
  part_sales: number
  labor_sales: number
  hour_presented: number
  hour_sold: number
  close_ratio: string
  effective_labor: string
  avg_sr_sales: string
  avg_sr_profit: string
  avg_sr_profit_margin: string
  gross_sales: string
  gross_profit: string
  supplies_charges: string
  taxable_amount: string
  total_discount: string
  parts: Parts
  genric_job: GenricJob
  labor: Labor
  payment_method: PaymentMethod
}

export interface Parts {
  cost: string
  price: string
  taxable: string
  non_taxable: string
}

export interface GenricJob {
  cost: string
  price: string
  taxable: string
  non_taxable: string
}

export interface Labor {
  cost: string
  price: string
  taxable: string
  non_taxable: string
}

export interface PaymentMethod {
  cash: string
  cheque: string
  credit_card: string
  other: string
}

@Component({
  selector: 'app-businessmetrics',
  templateUrl: './businessmetrics.component.html',
  styleUrls: ['./businessmetrics.component.scss']
})
export class BusinessmetricsComponent implements OnInit,OnDestroy {

  public excludedVehicleSideBar = false;
  public addExcludedVehicleSideBar = false;
  public searchFilterSideBar = false;
  public reportData: any = <ReportData>{};
  public options = {
    user_id: '',
    user_type: "User",
    start: 0,
    limit: 10,
    customer_id: '',
    employee_id: '',
    select_sales: '3',
    starting_date: '01/15/2019',
    ending_date: '01/15/2019'
  }
  searchInput: any;
  excludedVehicles = [];
  public subscriptionDataForAPI: Subscription = new Subscription();
  constructor(public globalService: GlobalService,public router: Router,public http:Http,public cdr: ChangeDetectorRef,  public dialog: MatDialog,) {
    this.options.user_id = JSON.parse(localStorage.getItem("loggedInUser")).id;   
    setTimeout(()=>{
      let isEnable=this.globalService.getAcl('sales_reports','views');
      if(!(isEnable)){
          this.router.navigate(['shops/action/forbidden']);
      }
    },globalConstants.RELOAD_WAIT);
  }

  ngOnInit() {
    this.globalService.setMetaData("SHOPS","BUSINESS_METRICS");
    this.getBusinessMetricsReport();
    this.getExcludedVehicles();
  }

  getExcludedVehicles(){
    let content = globalFunctions.stringifyFormData({user_id:this.options.user_id,user_type:this.options.user_type}, null);
    let callApi = this.globalService.callAPI(globalConstants.API_GET_EXCLUDED_VEHICLE_LIST, content);
    this.subscriptionDataForAPI =  callApi.subscribe((data:any) => {
      if('data' in data && Array.isArray(data.data)){
        this.excludedVehicles = data.data;
      }      
    });
  }

  excludedOut(event: any) {
    if (event.changed == 1) {     
      this.getBusinessMetricsReport();
    } else if (event.changed == 2) {
      this.addExcludedVehicleSideBar = true;
      this.excludedVehicleSideBar = false;
    } else {
      this.excludedVehicleSideBar = false;
    }
  }

  addexcludedOut(event: any) {
    this.addExcludedVehicleSideBar = false;
    if (event.changed) {
      this.excludedVehicles = event.selectedVehicles; 
      let vehicleIds        = this.excludedVehicles.map(itm => itm.Vehicle_id).join(',');
      let content           = globalFunctions.stringifyFormData({user_id:this.options.user_id,user_type:this.options.user_type,vehicles:vehicleIds}, null);
      let callApi           = this.globalService.callAPI(globalConstants.API_ADD_EXCLUDED_VEHICLE, content);
      this.subscriptionDataForAPI =callApi.subscribe((data:any) => { 
        if('result' in data && data.result=='1'){
          this.getBusinessMetricsReport();
          this.getExcludedVehicles();
        }        
      });
    }else{
      this.excludedVehicleSideBar = true;
    }
  }

  searchFilterOutput(event: any) {
    if(event.changes === 1){
      this.options.select_sales = event.select_sales.toString();
      if (this.options.select_sales === '5') {
        this.options.starting_date = event.starting_date;
        this.options.ending_date = event.ending_date;
      }
      this.searchFilterSideBar = false;
      this.getBusinessMetricsReport();
    }else if(event.changes === 3){
      this.getBusinessMetricsReport();
    }else if(event.changes === 4){
      this.options.select_sales = event.select_sales.toString();
      this.getBusinessMetricsReport();
    }else{
      this.searchFilterSideBar = false;
    }
  }

  calDiff(value1, value2) {
    return (parseFloat(value1) - parseFloat(value2)).toFixed(2);
  }

  totalSummary(sales, taxAmount, discount, charges) {
    return ((parseFloat(sales) + parseFloat(taxAmount) + parseFloat(charges)) - parseFloat(discount)).toFixed(2);
  }

  getBusinessMetricsReport() {
    const customerList = JSON.parse(localStorage.getItem("business-selected-customer"))|| [];
    if(customerList.length > 0 && Array.isArray(customerList)){
      this.options.customer_id =   customerList.map(itm => itm.id).join(','); 
    }else{
      this.options.customer_id = '';
    }
    const employeeList = JSON.parse(localStorage.getItem("business-selected-employee"))|| [];
    if(employeeList.length > 0 && Array.isArray(employeeList)){
      this.options.employee_id =   employeeList.map(itm => itm.id).join(','); 
    }else{
      this.options.employee_id = '';
    }
    var content = globalFunctions.stringifyFormData(this.options, null);
    var callApi = this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUESTS_BUSINESS_METRICS_REPORT, content);
    this.subscriptionDataForAPI = callApi.subscribe((data:any) => {      
      this.reportData = data.data;
    });
  }

  totalSaleDiscount(labor,gjob,part){
    return (parseFloat(labor) + parseFloat(gjob) + parseFloat(part)).toFixed(2);
  }

  totalPaymentMethod(cash,cheque,credit_card,other){
    return (parseFloat(cash) + parseFloat(cheque) + parseFloat(credit_card) + parseFloat(other)).toFixed(2);
  }
  

  exportInPDF(){
    let userId          = this.globalService.getCurrentUser()["id"];
    let url             = globalConstants.API_EXPORT_BUSINESS_METRICS_REPORT;
    let data            = Object.assign({},this.reportData);
    data['labor']       = JSON.stringify(data.labor);
    data['parts']       = JSON.stringify(data.parts);
    data['genric_job']  = JSON.stringify(data.genric_job);
    data['payment_method']  = JSON.stringify(data.payment_method);
    const customerList  = JSON.parse(localStorage.getItem("business-selected-customer"))|| [];
    const employeeList  = JSON.parse(localStorage.getItem("business-selected-employee"))|| [];
    let customer_ids    = "";
    if(customerList.length > 0 && Array.isArray(customerList)){
      customer_ids =   customerList.map(itm => itm.id).join(','); 
    }
    let employee_ids    = "";
    if(employeeList.length > 0 && Array.isArray(employeeList)){
      employee_ids =   employeeList.map(itm => itm.id).join(','); 
    }
    let content = globalFunctions.stringifyFormData( Object.assign(data,{user_id:this.options.user_id,user_type:this.options.user_type,invoke_download:this.globalService.isDeviceiOS()?1:0,customer_ids:customer_ids,employee_ids:employee_ids}), null);
    this.globalService.downloadFileWithPost(url,content,"service_request_business_metric_report");
  }

  /** open Add Notes */
  openAddExpenses() {
    let dialogRef = this.dialog.open(ExpensesAddComponent, {
      panelClass: ['car-dialog-form','add-expenses'],
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
  }  
}