<div class="panel panel-primary">
    <div class="panel-heading padding-left-15">
      <h3 class="panel-title">Notifications</h3>
      <button mat-mini-fab class="custom-btn-back" (click)="globalservice.goBack();">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <div class="panel-body bgcolor-lightgrey logs-detail">
      <div class="car-datatable uc-datatable mat-elevation-z1 employee-table1">
        <div class="table-responsive relative">
          <form class="form-inline signup-form" method="POST" enctype="multipart/form-data" autocomplete="off">
            <mat-table #table [dataSource]="dataSource" matSort class="uc-table-responsive datatable-loader-bg">
              <!-- Color Column -->
              <ng-container matColumnDef="msg">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Title </mat-header-cell>
                <mat-cell *matCellDef="let row" data-val="Title" [innerHTML]="row.msg"></mat-cell>
              </ng-container>
  
              <!-- Color Column -->
              <ng-container matColumnDef="add_date">
                <mat-header-cell *matHeaderCellDef > Notification Date </mat-header-cell>
                <mat-cell *matCellDef="let row" data-val="Notification Date"> {{row.date }} </mat-cell>
              </ng-container>
  
              <!-- Color Column -->
              <ng-container matColumnDef="Actions">
                <mat-header-cell *matHeaderCellDef data-role="button"> Actions </mat-header-cell>
                <mat-cell *matCellDef="let row" data-role="button">
                  <button *ngIf="row.payload_url!=''" mat-button class="list-action-btn" (click)="notifyView(row)" disableRipple>
                    <i class="fa fa-eye"></i>View </button>
                </mat-cell>
              </ng-container>
  
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;" [class.notification-unread]="row.read_status=='0'"></mat-row>
            </mat-table>
          </form>
  
          <div class="padding-15 text-center" [style.display]="getTotalRecords() < 1 ? '' : 'none'">
            No Records Available
          </div>
        </div>
        <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
          <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="options.limit"
            [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event)">
          </mat-paginator>
  
          <div class="mat-paginator table-paging">
            <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
            <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
              <mat-icon>navigate_before</mat-icon>
            </button>
            <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
              <mat-icon>navigate_next</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>