import { Component, OnInit, Inject, ViewEncapsulation, Optional } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { GlobalService } from '../../../../../environments/global.service'
import { IDatePickerConfig } from 'ng2-date-picker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-expenses-add',
  templateUrl: './expenses-add.component.html',
  styleUrls: ['./expenses-add.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class ExpensesAddComponent implements OnInit {

  public datePickerConfig: IDatePickerConfig = {
    format: "MM/DD/YYYY hh:mm A",
    min: this.gs.getFullFormateDate(new Date(), 'G','C'),
    drops:'down',
    disableKeypress:true,
    unSelectOnClick:false
  };

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private gs: GlobalService
  ) { }

  ngOnInit(): void {
  }

}
