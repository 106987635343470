<div mat-dialog-title class="relative">
  Recent Service Request(s)
  <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
    <mat-icon  class="notranslate" >close</mat-icon>
  </button>
</div>
<mat-dialog-content class="customer-detail-popup">
  <div class="table-responsive relative recent-sr-table">
  <table class="table table-striped bs-table  margin-bottom-10">
    <thead>
      <tr class="table-heading">
        <th class="header" scope="col" style="width:20%">Request Id</th>   
        <th class="header" scope="col" style="width:20%">Title</th> 
        <th class="header" scope="col" style="width:100px">Ticket Status</th>
        <th class="header" scope="col" style="width:100px">Amount Paid</th>
        <th class="header" scope="col" style="width:100px">Amount Balance</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let ticket of tickets">
        <td><a href="{{reqPath+ticket.id}}" target="_blank" class="hover-link" >{{ticket.request_id}}</a></td>  
        <td class="break-word">{{ ticket.title ? ticket.title : 'NA' }}</td>
        <td><mat-chip-list class="mat-badge"><mat-chip class="{{ getSRStatusColor(ticket) }}"  selected="true"><span>{{ statuses[ticket.status] }}</span></mat-chip></mat-chip-list></td>
        <td>$ {{ getPaidAmount(ticket) }}</td>
        <td>$ {{ getBalancAmount(ticket) }}</td>
      </tr>
    </tbody>
  </table>
   
  </div>
  
  
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
  <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
</mat-dialog-actions>