import { Component, OnInit, Inject } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { globalFunctions } from '../../../../environments/globalFunctions';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';

@Component({
    selector: 'app-registration-insurance-alert',
    templateUrl: './registration-insurance-alert.component.html',
    styleUrls: ['./registration-insurance-alert.component.scss']
})

export class RegistrationInsuranceAlertComponent implements OnInit {
    public currentCustomer;
    public currentCustomerName;
    public request;
    public currentCustomerVehicles: any = [];
    public vehicleStatus: any;
    public showModal = false;
    public customerSubscription;
    public popupType;
    public dayDiff;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private http: Http, public dialogRef: MatDialogRef<RegistrationInsuranceAlertComponent>, private formBuilder: FormBuilder, public globalService: GlobalService, private router: Router) {
        if (localStorage.getItem("loggedInCustomer")) {
            this.currentCustomer = JSON.parse(localStorage.getItem("loggedInCustomer"));
            if (JSON.parse(localStorage.getItem("customerSubsResponse")) != undefined || JSON.parse(localStorage.getItem("customerSubsResponse")) != null) {
                this.customerSubscription = JSON.parse(localStorage.getItem("customerSubsResponse")).notify_expiration_dates_web;
            }
            this.popupType = data.popup_type;
            this.dayDiff = data.day_diff;
            // console.log('Data', data);
            this.getCustomerVehicles();
        }
    }

    show(obj) {
        console.log(obj)
    }

    getCustomerVehicles() {
        var headers = new Headers();
        var content = "customer_id=" + this.currentCustomer.id + "&from_v2=1";
        headers.append('content-type', 'application/x-www-form-urlencoded');
        this.request = this.http.post(globalConstants.API_GET_CUSTOMER_VEHICLES_URL, content, { headers: headers }).subscribe((data) => {
            var response = JSON.parse(data["_body"]);
            if (response.result == "1") {
                if (response.data.length == undefined) {
                    this.currentCustomerVehicles[0] = response.data;
                } else {
                    this.currentCustomerVehicles = response.data;
                }
                for (var i in this.currentCustomerVehicles) {
                    this.currentCustomerVehicles[i].name = "";
                    if (this.currentCustomerVehicles[i].make) {
                        this.currentCustomerVehicles[i].name += this.currentCustomerVehicles[i].make;
                    }
                    if (this.currentCustomerVehicles[i].model) {
                        this.currentCustomerVehicles[i].name += (this.currentCustomerVehicles[i].name ? " " : "") + this.currentCustomerVehicles[i].model;
                    }
                }
            } else {
                this.currentCustomerVehicles = [];
                this.vehicleStatus = 1;
            }
            this.getAlertValues();
        });
    }

    updateExpiredDetailsOfVehicle(table_column, value, obj) {
        var content = "vehicle_id=" + obj.id + "&table_column=" + table_column + "&value=1"
        this.globalService.callAPI(globalConstants.API_UPDATE_CAR_NOTIFICATION_STATUS_URL, content).subscribe((response) => { });
    }

    getAlertValues() {
        var filteredDates = this.currentCustomerVehicles.filter(function (x) {
            return ((x.insurance_expiration_date != "" ||
                x.insurance_expiration_date != null ||
                x.insurance_expiration_date != "0000-00-00 00:00:00") ||
                (x.registration_expiration_date != "" || x.registration_expiration_date != null
                    || x.registration_expiration_date != "0000-00-00 00:00:00"))
        });
        //console.log("length", filteredDates.length);
        for (var i = 0; i < filteredDates.length; i++) {
            var insurance_expiration_date = filteredDates[i].insurance_expiration_date;
            var registration_expiration_date = filteredDates[i].registration_expiration_date;
            var currentDate = new Date();
            currentDate.setHours(0, 0, 0);
            var insuranceCheck30 = filteredDates[i].insurance_expiration_alter_30_web;
            var insuranceCheck7 = filteredDates[i].insurance_expiration_alert_7_web;
            var insuranceCheck0 = filteredDates[i].insurance_expiration_alert_today_web;

            /* Registration web check */
            var registrationCheck30 = filteredDates[i].registration_expiration_alter_30_web;
            var registrationCheck7 = filteredDates[i].registration_expiration_alert_7_web;
            var registrationCheck0 = filteredDates[i].registration_expiration_alert_today_web;

            var f = this.currentCustomerVehicles.find(function (x) { return x.id == filteredDates[i].id });
            f.registration_exipiring_type = [];
            f.insurance_exipiring_type = [];

            /* INSURANCE EXPIRATION DATES */

            /* Before 1 to 30 Days */
            if (new Date(insurance_expiration_date) > currentDate) {
                var getinsurance_expiration_date_diff = Math.abs(this.daysBetween(new Date(insurance_expiration_date), currentDate));
                if (getinsurance_expiration_date_diff >= 1 && getinsurance_expiration_date_diff < 31 && insuranceCheck30 == "0") {
                    this.showModal = true;
                    f.show = true
                    //f.insurance_expiring = false;
                    if (getinsurance_expiration_date_diff == 1) {
                        f.insdaysDiffmsg = 'insurance will expire in ' + getinsurance_expiration_date_diff + ' day';
                    } else {
                        f.insdaysDiffmsg = 'insurance will expire in ' + getinsurance_expiration_date_diff + ' days';
                    }
                    f.insurance_expiring = true;
                    f.insurance_exipiring_type.push(30)
                    //console.log("--INS ID 30 --", filteredDates[i].id, "Day Diff", getinsurance_expiration_date_diff);
                } else {
                    f.insurance_expiring = false;
                }
                if (f.insurance_expiring) {
                    this.updateExpiredDetailsOfVehicle("insurance_expiration_alter_30_web", 1, f)
                }
            }

            if (new Date(insurance_expiration_date) <= currentDate && insuranceCheck0 == "0") {
                var getinsurance_expiration_date_diff = Math.abs(this.daysBetween(currentDate, new Date(insurance_expiration_date)));
                //console.log("Ins Day Diff", getinsurance_expiration_date_diff)
                if (getinsurance_expiration_date_diff == 0) {
                    this.showModal = true;
                    f.show = true
                    f.insdaysDiffmsg = "insurance is expiring today";
                    f.insurance_expiring = true;
                    f.insurance_exipiring_type.push(0)
                    //console.log("--INS ID 0 --", filteredDates[i].id, "Day Diff", getinsurance_expiration_date_diff);
                } else {
                    f.insurance_expiring = false;
                }
                if (f.insurance_expiring) {
                    this.updateExpiredDetailsOfVehicle("insurance_expiration_alert_today_web", 1, f)
                }
            }

            if (currentDate > new Date(insurance_expiration_date) && insuranceCheck7 == "0") {
                var getinsurance_expiration_date_diff = Math.abs(this.daysBetween(currentDate, new Date(insurance_expiration_date)));
                //        console.log("INSSS", getinsurance_expiration_date_diff, filteredDates[i].id);
                if (getinsurance_expiration_date_diff >= 7) {
                    this.showModal = true;
                    f.show = true
                    f.insdaysDiffmsg = 'insurance has been expired'; //"7 days";
                    f.insurance_expiring = true;
                    f.insurance_exipiring_type.push(7)
                    //console.log("--INS ID 7 --", filteredDates[i].id, "Day Diff", getinsurance_expiration_date_diff);
                } else {
                    f.insurance_expiring = false;
                }
                if (f.insurance_expiring) {
                    this.updateExpiredDetailsOfVehicle("insurance_expiration_alert_7_web", 1, f)
                }
            }


            /* REGISTRATION EXPIRATION DATES */

            if (new Date(registration_expiration_date) > currentDate && registrationCheck30 == "0") {
                var getregistration_expiration_date_diff = Math.abs(this.daysBetween(new Date(registration_expiration_date), currentDate));
                if (getregistration_expiration_date_diff >= 1 && getregistration_expiration_date_diff < 31) {
                    this.showModal = true;
                    f.show = true
                    //f.insurance_expiring = false;
                    if (getregistration_expiration_date_diff == 1) {
                        f.regdaysDiffmsg = 'registration will expire in ' + getregistration_expiration_date_diff + ' day';
                    } else {
                        f.regdaysDiffmsg = 'registration will expire in ' + getregistration_expiration_date_diff + ' days';
                    }
                    f.registration_exipiring = true;
                    f.registration_exipiring_type.push(30)
                    //console.log("--Reg ID 30 --", filteredDates[i].id, "Day Diff", getregistration_expiration_date_diff);
                } else {
                    f.registration_exipiring = false;
                }
                if (f.registration_exipiring) {
                    this.updateExpiredDetailsOfVehicle("registration_expiration_alter_30_web", 1, f)
                }
            }


            //console.log("before",this.currentCustomerVehicles)
            if (new Date(registration_expiration_date) <= currentDate && registrationCheck0 == "0") {
                var getregistration_expiration_date_diff = Math.abs(this.daysBetween(currentDate, new Date(registration_expiration_date)));
                //console.log("Reg Day Diff", getregistration_expiration_date_diff)
                if (getregistration_expiration_date_diff == 0) {
                    this.showModal = true;
                    f.show = true
                    f.regdaysDiffmsg = "registration is expiring today";
                    f.registration_exipiring = true;
                    f.registration_exipiring_type.push(0)
                    //console.log("--REG ID 0 --", filteredDates[i].id, "Day Diff", getregistration_expiration_date_diff);
                } else {
                    f.registration_exipiring = false;
                }
                if (f.registration_exipiring) {
                    this.updateExpiredDetailsOfVehicle("registration_expiration_alert_today_web", 1, f)
                }
            }

            if (currentDate > new Date(registration_expiration_date) && registrationCheck7 == "0") {
                var getregistration_expiration_date_diff = Math.abs(this.daysBetween(currentDate, new Date(registration_expiration_date)));
                if (getregistration_expiration_date_diff >= 7) {
                    this.showModal = true;
                    f.show = true
                    f.regdaysDiffmsg = 'registration has been expired'; //"7 days";
                    f.registration_exipiring = true;
                    f.registration_exipiring_type.push(7)
                    //console.log("--REG ID 7 --", filteredDates[i].id, "Day Diff", getregistration_expiration_date_diff);
                } else {
                    f.registration_exipiring = false;
                }
                if (f.registration_exipiring) {
                    this.updateExpiredDetailsOfVehicle("registration_expiration_alert_7_web", 1, f)
                }
            }
        }
    }

    ngOnInit() {
        //this.getAlertValues();
    }

    // Date Difference
    daysBetween(date1, date2) {
        //Get 1 day in milliseconds
        var one_day = 1000 * 60 * 60 * 24;

        // Convert both dates to milliseconds
        var date1_ms = date1.getTime();
        var date2_ms = date2.getTime();

        // Calculate the difference in milliseconds
        var difference_ms = date2_ms - date1_ms;

        // Convert back to days and return
        return Math.round(difference_ms / one_day);
    }

    getCurrentRoute() {
        return this.router.url
    }

}
