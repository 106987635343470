<div class="row gridView-bottom-sheet">
    <hr class="divider">
    <div class="col-sm-12 search-section">
        <input matInput placeholder="search keyword" title="Search Keyword" formControlName="search_keyword"
                            class="form-control input-lg custom-form-input" />
        <div class="flex filter-section">
            <div class="">Search by Asignee</div>
            <div class="text-accent">Clear all</div>
        </div>                    
    </div>
    <div class="col-sm-12 col-md-12 col-xs-12 filter-item-section">

                <div class="filter-item">
                    <div class="filter-info">
                        <figure class="margin-right-10">
                            <img class="img-xs" src="../../../../assets/img/user-1.webp" alt="Person icon">
                        </figure>
                        <div class="">
                                <div class="text-bold margin-0">Pravesh panday</div>
                        </div>
                    </div>
                    <mat-checkbox class="checkbox" labelPosition="after">
                    </mat-checkbox>
                </div>
                <div class="filter-item">
                    <div class="filter-info">
                        <figure class="margin-right-10">
                            <img class="img-xs" src="../../../../assets/img/user-2.webp" alt="Person icon">
                        </figure>
                        <div class="">
                                <div class="text-bold margin-0">Shaikh Iqubal</div>
                        </div>
                    </div>
                    <mat-checkbox class="checkbox" labelPosition="after">
                    </mat-checkbox>
                </div>
    </div>
</div>
