<div class="panel panel-primary expenses-list-component" id="mainComponents">
    <div class="panel-heading padding-left-15 panel-with-flexbtns">
      <h3 class="panel-title">Expenses List</h3>
      <div class="flex-btns">
        <button mat-mini-fab class="custom-title-btn export-btn" title="Export Expenses"  (click)="downloadExpenseLedgerCSV()">
            <i class="fa fa-solid fa-file-export" aria-hidden="true"></i>
            <span class="hidden-xs"> Export</span>
        </button>
        <button mat-mini-fab class="custom-title-btn add-expenses-button" title="Add Expense" (click)="handleAddEditExpense()">
            <i class="fa fa-fw fa-plus"></i><span class="hidden-xs">Add</span>
        </button>
        <button mat-mini-fab class="custom-btn-back" title="Back" (click)="globalService.goBack();">
            <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <div class="display-full relative">
            <mat-expansion-panel hideToggle="false" class="search-filter search-employee relative mat-elevation-z1" [expanded]="searchPanelOpenStatus">
                <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px">
                  <strong><i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
                </mat-expansion-panel-header>
                <form id="advanceSearchForm" class="collapse in" aria-expanded="true">
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-sm-3">
                                <mat-form-field class="car-input btn-block" appearance="fill">
                                <mat-select class="form-control input-lg custom-form-input"
                                    [(ngModel)]="options.search_by"
                                    [ngModelOptions]="{standalone: true}"
                                    placeholder="Search By">
                                    <mat-option *ngFor="let option of searchByList" [value]="option.id">
                                      {{option.text}}
                                  </mat-option>
                                </mat-select>
                                </mat-form-field>
                            </div>
                    
                            <div class="col-sm-3">
                                <mat-form-field class="car-input btn-block" appearance="fill">
                                <input matInput placeholder="Search Keyword" title="Search Keyword"
                                    [(ngModel)]="options.search_keyword" [ngModelOptions]="{standalone: true}"
                                    class="form-control input-lg custom-form-input" />
                                </mat-form-field>
                            </div>
                    
                            <div class="col-sm-3">
                                <mat-form-field class="car-input btn-block" appearance="fill">
                                <mat-select class="form-control input-lg custom-form-input"
                                    [(ngModel)]="options.category"
                                    [ngModelOptions]="{standalone: true}"
                                    placeholder="Category">
                                    <mat-option *ngFor="let category of categoriesList" [value]="category.id">
                                        {{category.category_name}}
                                    </mat-option>
                                </mat-select>
                                </mat-form-field>
                            </div>
                    
                            <div class="col-sm-3">
                                <mat-form-field class="car-input btn-block" appearance="fill">
                                <mat-select class="form-control input-lg custom-form-input"
                                    [(ngModel)]="options.duration"
                                    [ngModelOptions]="{standalone: true}"
                                    (valueChange)="onChangeDays($event)"
                                    placeholder="Duration">
                                    <mat-option *ngFor="let duration of searchByDurationList" [value]="duration.id">
                                      {{duration.text}}
                                  </mat-option>
                                </mat-select>
                                </mat-form-field>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="col-sm-3 dateRangeStyle details-input">
                                <app-date-range-picker (SelectTime)="onDateChange($event)" class="date-time-section"></app-date-range-picker>
                        </div>
                        </div>
                    </div>
                  <mat-action-row class="search-filter-footer">
                    <button mat-raised-button color="accent" type="submit" title="Search" (click)="search()" >
                      Search
                    </button>
                  </mat-action-row>
                </form>
              </mat-expansion-panel>
              <button mat-button type="button" title="Clear Filter" class="clear-btn" #clearBtn [class.active-search]="checkFormEmpty(clearBtn)" (click)="clearSearch()">
                Clear Filter
              </button>
        </div>
      <div class="car-datatable uc-datatable mat-elevation-z1">
        <div class="table-responsive relative">
          <mat-table #table [dataSource]="dataSource" matSort class="uc-table-responsive service-list datatable-loader-bg">

            <!-- Define other columns -->
            <ng-container matColumnDef="title">
              <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto" class="title-header"> Title </mat-header-cell>
              <mat-cell *matCellDef="let row"  data-role="auto" data-val="Title" class="title-cell"> {{row.title}} </mat-cell>
              <mat-footer-cell *matFooterCellDef class="font-bold">Total</mat-footer-cell>
            </ng-container>
          
            <ng-container matColumnDef="description">
              <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto" class="description-header"> Description </mat-header-cell>
              <mat-cell *matCellDef="let row"  data-role="auto" data-val="Description" class="description-cell"> {{row.description || '-'}} </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>
          
            <ng-container matColumnDef="category">
              <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Category </mat-header-cell>
              <mat-cell *matCellDef="let row"  data-role="auto" data-val="Category"> {{row.category}} </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>
          
            <ng-container matColumnDef="expense_date">
              <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Expense Date </mat-header-cell>
              <mat-cell *matCellDef="let row"  data-role="auto" data-val="Expense Date"> {{row.expense_date | date:'MM/dd/yyyy'}} </mat-cell>
              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>
          
            <ng-container matColumnDef="amount">
              <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Amount </mat-header-cell>
              <mat-cell *matCellDef="let row"  data-role="auto" data-val="Amount"> {{row.amount | currency}} </mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="Actions">
              <mat-header-cell *matHeaderCellDef data-role="button"> Actions </mat-header-cell>
              <mat-cell *matCellDef="let row" data-role="button">
                <button mat-button class="list-action-btn" color="accent" [matMenuTriggerFor]="menu" disableRipple>
                  Action<mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #menu="matMenu" class="car-icon-menu disable-no-bg" [overlapTrigger]="false">
                  <button class="mat-menu-item" disableRipple type="button" (click)="handleAddEditExpense(row)">
                    <i class="fa fa-edit"></i>Edit
                  </button>
                  <button class="mat-menu-item" disableRipple type="button" (click)="handleDeleteExpenses(row)">
                    <i class="fa fa-trash"></i>Delete
                  </button>
                </mat-menu>
              </mat-cell>
              <mat-footer-cell *matFooterCellDef ></mat-footer-cell>
            </ng-container>
          

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="mat-row"></mat-row>
          </mat-table>
          
  
          <div class="padding-15 text-center" [style.display]="getTotalRecords() < 1 ? '' : 'none'">
            No Records Available
          </div>
        </div>
  


        <!-- Pagination -->
        <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
          <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0"
            [pageSize]="options.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event)">
          </mat-paginator>
          <div class="mat-paginator table-paging">
            <div class="mat-paginator-range-label margin-left-30 margin-right-30">
              {{options.start + 1}} - {{getUpperLimit(options.start + options.limit, getTotalRecords())}} of {{getTotalRecords()}}
            </div>
            <button mat-icon-button (click)="paginate($event, 'previous');" [disabled]="firstList()">
              <mat-icon>navigate_before</mat-icon>
            </button>
            <button mat-icon-button (click)="paginate($event, 'next');" [disabled]="lastList()">
              <mat-icon>navigate_next</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  