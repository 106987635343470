<form class="form-horizontal form-control-normal" autocomplete="off" [formGroup]="newQueForm">
    <div mat-dialog-title class="relative">
      Return Detail
      <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
        <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
      </button>
    </div>
    <mat-dialog-content class="customer-detail-popup">
      <div class="center-block" style="max-width: 600px;">
        <div class="display-full">
          <label class="col-sm-3 control-label">
            <strong>Date Time</strong>
          </label>
          <div class="col-sm-9 datepicker-section">
              <div class="vlio-datepicker relative">
                  <dp-date-picker mode="daytime" formControlName="add_date" [config]="datePickerConfig" (onChange)="changeEstimatedDeliveryDate($event)" theme="dp-material dp-main" class="btn-block" #dayPicker></dp-date-picker>
                  <button type="button" mat-icon-button (click)="dayPicker.api.open();">
                    <mat-icon>date_range</mat-icon>
                  </button>
                </div>
          </div>
        </div>
        <div class="display-full margin-bottom-5">
            <label class="col-sm-3 control-label">
              <strong>Description</strong>
            </label>
            <div class="col-sm-9 details-input">
              <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                <textarea matInput type="text" placeholder="Description" class="inputlg btn-block" formControlName="description" ></textarea>
              </mat-form-field>
            </div>
          </div>
        <div class="display-full margin-bottom-5">
            <label for="vehicleVinPic" class="col-sm-3 control-label">Document</label>
            <div class="col-sm-9 col-md-6 upload-image">
                <mat-toolbar class="mat-elevation-z2 margin-bottom-5 upload-btn relative">
                    <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                    Drag File Here
                    <input type="file" id="addvehicle_vin_pic" (change)="changeListner($event,'vehicle_vin_pic')" name="vehicle_vin_pic" accept="*/*"/>
                    <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                </mat-toolbar>
                <div class="margin-bottom-5">
                    <p class="help-block" style="margin:0px;">Formats supported : {{FORMAT_SUPPORTED_FOR_IMAGE}}, pdf</p>
                    <p class="help-block" style="margin:0px;">Maximum size : {{MAX_ALLOWED_IMAGE_DISPLAYED_TEXT}}</p>
                </div>
                <ul id="vin_photo-ul" class="list-inline margin-bottom-0 margin-top-5">
                    <li *ngFor="let doc of docList;let i=index">
                        <a href="javascript:void(0);" class="img-set thumbnail relative">
                            <span class="fa far fa-file-pdf fa-4x" *ngIf="doc.type==3"></span>
                            <span class="fa far fa-file-word fa-4x" *ngIf="doc.type==4"></span>
                            <img class="show-image" src="{{doc.src}}"  *ngIf="doc.type==1">
                          <i class="fa fa-trash remove-img" (click)="deleteImg(i)"></i>
                          <div class="image-loader" *ngIf="request_document_loader">
                            <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                          </div>
                        </a>
                  </li>
                </ul>
            </div>
        </div>
            
          
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="inquiry-action relative">
      <div class="flexBtns">
        <button mat-raised-button color="accent" (click)="submit()" class="font-bold">Submit</button>
        <button mat-raised-button mat-dialog-close class="font-bold border">Close</button>
      </div>
    </mat-dialog-actions>
  </form>