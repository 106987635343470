<form class="form-horizontal form-control-normal" autocomplete="off">
  <div mat-dialog-title class="relative">
    Add Existing Part to Collection
    <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
      <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
  </div>
  <mat-dialog-content class="ticket_dialog_content">
    <div class="center-block" style="max-width: 600px;">
      <div class="display-full margin-bottom-15">
        <div class="col-sm-12">
          <label class="col-sm-3 control-label">Parts Number:</label>
          <div class="col-sm-9">
            <div class="col-sm-9 padding-0 diagnostic-form">
              <input class="car-input " matInput ngui-auto-complete (valueChanged)="getSelectedOption($event)"
                [source]="observableParts" [list-formatter]="myListFormatter" value-property-name="label"
                placeholder="Enter Parts" (click)="setReturnFalse()" id="new-part-qty" class="form-control" />
            </div>
          </div>
        </div>
      </div>
      <div class="display-full margin-bottom-15">&nbsp;</div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="inquiry-action relative">
    <button type="button" (click)="submit(1)" class="font-bold mat-raised-button mat-accent" title="Submit & Add Another">Submit & Add Another</button>
    <button type="button" (click)="submit(2)" class="font-bold mat-raised-button mat-accent" title="Submit & Close">Submit & Close</button>
    <button type="button" mat-raised-button mat-dialog-close class="font-bold" title="Close">Close</button>
  </mat-dialog-actions>
</form>