import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from '../../../../environments/global.service';
import { IDatePickerConfig } from 'ng2-date-picker';
import { globalConstants } from '../../../../environments/globalConstants';

@Component({
  selector: 'app-returnpickup',
  templateUrl: './returnpickup.component.html',
  styleUrls: ['./returnpickup.component.scss']
})
export class ReturnpickupComponent implements OnInit {

  public newQueForm: FormGroup;
  public datePickerConfig: IDatePickerConfig = {
    format: "MM/DD/YYYY hh:mm A",
    max: this.gs.getFullFormateDate(new Date(),'G','C')
  };
  public part: any = {};
  user: any = null;
  public vehicle_vin_pic = '';
  public FORMAT_SUPPORTED_FOR_IMAGE = globalConstants.FORMAT_SUPPORTED_FOR_IMAGE;
  public MAX_ALLOWED_IMAGE_DISPLAYED_TEXT = globalConstants.MAX_ALLOWED_IMAGE_DISPLAYED_TEXT;
  public isMultipleFile=true;
  public request_document_loader: boolean = true
  public docList:any=[];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, public dialogRef: MatDialogRef<ReturnpickupComponent>, private gs: GlobalService) {
    this.user = this.gs.getCurrentUser();
  }

  ngOnInit() {
    if (!this.user) {
      this.dialogRef.close(2);
    }
    this.gs.diaLogObj(this.dialogRef);
    this.newQueForm = this.formBuilder.group({
      add_date: ['', Validators.required],
      description: [''],
      doc_file: [''],
      user_type: [this.user.user_type],
      user_id: [this.user.id]
    });
    this.isMultipleFile=this.gs.isMultifileAllowed()
  }
  changeEstimatedDeliveryDate($event) {
    // var value;
    // if((typeof $event !='undefined') && typeof $event._d=='undefined') {
    //   value = $event || $event._d;
    //   var c = new Date(value);
    //   c.setTime(c.getTime() + 30*60000);
    //   var f = new Date(c)
    //   var estimated_delivery_date = this.datePipe.transform(f, 'MM/dd/yyyy hh:mm a');
    //   this.serviceRequest.controls.estimated_delivery_date.setValue(estimated_delivery_date);
    //   this.serviceRequest.controls.estimatedDeliveryDate.setValue(estimated_delivery_date);
    // }
  }
  submit() {
    let name=[];
    this.docList.forEach(ele => {
      name.push(ele.name);
    });
    this.newQueForm.controls.doc_file.setValue(JSON.stringify(name));
    // console.log(this.newQueForm.value);
    this.dialogRef['ref']=this.newQueForm.value;
    this.dialogRef.close(1);
  }
  changeListner(event, rendering_tag_id) {
    var html = "";
    var base64image = "";
    let ele = <HTMLInputElement>document.getElementById('add' + rendering_tag_id);
    if (!this.isMultipleFile && this.docList.length >0){
      this.gs.snackbar("error", "Update your subscription to upload multiple files.");
      event.target.value = "";
      if (ele) {
        ele.value = '';
      }
      return false;
    }

    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      let cFiles = { src: '', type: 0, name: '' };
      let valid = true;
      valid = this.gs.validateFiles('doc', event.target.files);
      if (valid == false) {
        return false;
      }
      
      var reader = new FileReader();
      var base64value = "";
      let base64valuec;
      reader.onload = (e) => {
        base64valuec = e.target['result'];
        if (rendering_tag_id == 'vehicle_vin_pic') {
          cFiles.src = base64valuec;
        }
      }
      if(this.getFileIcons(file.name)==1){
        reader.readAsDataURL(event.target.files[0]);
      }
      let data = new FormData();
      let url = globalConstants.API_NEW_SERVICE_REQUEST_FILE;
      data.append('customer_id', this.user.id);
      data.append('doc', file);
      let FileType = this.gs.getFileIcon(file);
      cFiles.type = FileType;
      this.gs.formData(url, data).subscribe((response) => {
        let resData = response;
        if (resData.result == 1) {
          event.target.value = "";
          cFiles.name=resData.data.name;
          this.docList.push(cFiles);
        } else {
          this.gs.snackbar("error", resData.message);
        }
        if (ele) {
          ele.value = '';
        }
      })
    }
  }
  getFileIcons(str) {
    let ext = str.substr(str.lastIndexOf(".") + 1);
    let type = 0;
    if (ext != '') {
      type = this.gs.getFileIcon({ type: ext.toLowerCase() });
    }
    // console.log(str,ext,type);
    return type;
  }
  deleteImg(i){
    this.docList.splice(i, 1);
  }

}
