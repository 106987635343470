import { Component,OnDestroy} from '@angular/core';
import {  FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute  } from '@angular/router';
import { Http,Headers, Response} from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { globalFunctions } from "../../../environments/globalFunctions";
import { globalConstants } from "../../../environments/globalConstants";
import {GlobalService} from "../../../environments/global.service";
import { lang } from "../../../environments/lang-en";
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TextMaskModule } from 'angular2-text-mask';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoaderService } from '../../base/services/LoaderService';

@Component({
    selector: 'shop-addcomputercode',
    templateUrl: 'shopaddcomputercode.component.html',
    styleUrls: ['shopaddcomputercode.component.scss']
})
export class ShopaddcomputercodeComponent  implements OnDestroy {
    public currentCustomer;
    public currentCustomerName;
    public shopAddComputerCode;
    public subscriptionDataForAPI: Subscription = new Subscription();
    constructor(
        private activatedRoute:ActivatedRoute,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        public titleService:Title,
        private http: Http,
        private router: Router,
        public snackBar: MatSnackBar, 
        private globalService: GlobalService,
        private loaderService: LoaderService
        ){
        this.globalService.setMetaData("SHOPS","ADD_COMPUTER_CODES")

        if(localStorage.getItem("loggedInUser")) {
            this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
            this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
            this.shopAddComputerCode = this.formBuilder.group({
                trouble_code: ['', Validators.required],
                fault_location: ['', Validators.required],
                user_type: this.currentCustomer.user_type,
                user_id: this.currentCustomer.id,
            });
        } else {
            this.router.navigate(["/shops"]);
        }
    setTimeout(()=>{
        let isEnable=this.globalService.getAcl('computer_code','addNew');
        if(!(isEnable)){
            this.router.navigate(['shops/action/forbidden']);
        }
   },globalConstants.RELOAD_WAIT);
    }
    ngOnInit(){
        this.loaderService.hide();
    }

    

    addComputerCode() {
        if(this.shopAddComputerCode.valid) {
          var stringifiedFormData = globalFunctions.stringifyFormData(this.shopAddComputerCode.value,null);
          
          this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_ADD_COMPUTER_CODE_URL,stringifiedFormData).subscribe((data:any)=> {
                var message = data.message;
                if (data.result == "1") {
                    localStorage.setItem("search_by","");
                    localStorage.setItem("sort_by","");
                    localStorage.setItem("sort_type","");
                    localStorage.setItem("search_keyword","");
                    localStorage.setItem("start","0");
                    localStorage.setItem("limit","10");
                    this.router.navigate(['shops/list-computer-code']);
                    this.globalService.snackbar('success', message);
                } else {
                    this.globalService.snackbar('error', message);
                }
            });
        } else {
          this.mandatoryMsg();
        }
    }

    mandatoryMsg() {
        this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }

    ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
    } 
}
