<div class="panel panel-primary" id="mainCompotents">
  <div class="panel-heading padding-left-15">
    <h3 class="panel-title">PARTS Import Details</h3>
    <button mat-mini-fab class="custom-btn-back" (click)="gs.goBack();">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <div class="panel-body bgcolor-lightgrey" *ngIf="partDetail">
    <div class="display-full relative">
      <div class="table-responsive">
        <table class="table table-striped table-hover table-bordered ">
          <tbody>
            <tr>
              <td>
                <strong>Name</strong>
              </td>
              <td>{{partDetail.name}} </td>
            </tr>
            <tr>
              <td>
                <strong>Part Number</strong>
              </td>
              <td>{{partDetail.part_number}} </td>
            </tr>
            <tr>
              <td>
                <strong>OEM Number</strong>
              </td>
              <td>{{partDetail.oem_part_number}} </td>
            </tr>
            <tr>
              <td>
                <strong>Price</strong>
              </td>
              <td>{{partDetail.price}} </td>
            </tr>
            <tr>
              <td>
                <strong>Cost</strong>
              </td>
              <td>{{partDetail.cost}} </td>
            </tr>
            <tr>
              <td>
                <strong>Description</strong>
              </td>
              <td>{{partDetail.description}} </td>
            </tr>
            <tr>
              <td>
                <strong>Manufacturer</strong>
              </td>
              <td>{{partDetail.manufacturer}} </td>
            </tr>
            <tr>
              <td>
                <strong>Vendor</strong>
              </td>
              <td>{{partDetail.vendor}} </td>
            </tr>
            <tr>
              <td>
                <strong>Non Taxable</strong>
              </td>
              <td>{{partDetail.non_taxable}} </td>
            </tr>
            <tr>
              <td>
                <strong>Quantity</strong>
              </td>
              <td>{{partDetail.quantity}} </td>
            </tr>
            <tr>
              <td>
                <strong>Low Stock Value</strong>
              </td>
              <td>{{partDetail.low_stock_value}} </td>
            </tr>
            <tr>
              <td>
                <strong>Status </strong>
              </td>
              <td> {{getStatus(partDetail.upload_status)}}</td>
            </tr>
            <tr>
              <td><strong>Failure Reason</strong></td>
              <td>{{partDetail.failure_reason}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>