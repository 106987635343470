<div mat-dialog-title class="relative">
    Add Expenses
    <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
      <mat-icon class="close-icon-lg">close</mat-icon>
    </button>
</div>
<mat-dialog-content class="add-expenses-content">
    <div class="add-expenses-wrap">
        <form class=" add-expenses-normal" >
            <div class="row margin-bottom-5">
                <label class="col-sm-2 control-label"><strong>Title<span class="asterisk">*</span></strong></label>
                <div class="col-sm-10">
                  <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput type="text" placeholder="Title*" formControlName="title" class="form-control input-lg custom-form-input btn-block" maxlength="100" />
                  </mat-form-field>
                </div>
            </div>
           
            <div class="row margin-bottom-5 category-input">
                <label class="col-sm-2 control-label"><strong>Category<span class="asterisk">*</span></strong></label>
                <div class="col-sm-10 status-input">
                  <mat-form-field class="btn-block car-input status-select-input" floatPlaceholder="never" appearance="fill">
                    <mat-select class="form-control input-lg custom-form-input"  placeholder="Select Category*" formControlName="category" required>
                      <mat-option value="0">Electricity</mat-option>
                      <mat-option value="1">Employee Salary</mat-option>
                      <mat-option value="2">Infrastructure</mat-option>
                      <mat-option value="3">Vendors</mat-option>
                      <mat-option value="4">Others</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
            </div>
            <div class="row margin-bottom-5">
                <label class="col-sm-2 control-label"><strong>Amount<span class="asterisk">*</span></strong></label>
                <div class="col-sm-10">
                  <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput type="text" placeholder="Amount*" formControlName="amount" class="form-control input-lg custom-form-input btn-block" />
                  </mat-form-field>
                </div>
            </div>
            <div class="row margin-bottom-5 datepicker-section">
                <label class="col-sm-2 control-label"><strong>Date<span class="asterisk">*</span></strong></label>
                <div class="col-sm-10">
                  <div class="vlio-datepicker relative">
                      <dp-date-picker mode="daytime" placeholder="Choose Date*" formControlName="notify_date" [config]="datePickerConfig" theme="dp-material dp-main" class="btn-block" #dayPicker></dp-date-picker>
                      <button type="button" mat-icon-button (click)="dayPicker.api.open();">
                        <mat-icon>date_range</mat-icon>
                      </button>
                  </div>
                </div>
            </div>   
            <div class="row margin-bottom-5">
                <label class="col-sm-2 control-label"><strong>Description</strong></label>
                <div class="col-sm-10 details-input notes-input">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                      <textarea matInput placeholder="Description" rows="5" formControlName="description"></textarea>
                    </mat-form-field>
                </div>    
            </div> 
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
    <div class="flexBtns">
      <button mat-raised-button class="font-bold mat-raised-button" color="accent">Submit</button>
      <button mat-raised-button mat-dialog-close class="font-bold border">Close</button>
    </div>
  </mat-dialog-actions>