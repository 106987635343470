import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, ViewChildren, QueryList, AfterViewInit,OnDestroy} from '@angular/core';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
// import {MatPaginator, MatSort, MatDatepicker} from '@angular/material';
import {DataSource, SelectionModel} from '@angular/cdk/collections';
import {Http, Headers, Response} from '@angular/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {globalFunctions} from "../../../../environments/globalFunctions"
import {globalConstants} from "../../../../environments/globalConstants"
import {GlobalService} from "../../../../environments/global.service"
import {Observable} from 'rxjs/Observable';
import {Title} from '@angular/platform-browser';
import {trigger,state,style,animate,transition} from '@angular/animations';
// import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AppComponent} from '../../../app.component';
import {EmpreportdatatableviewComponent} from '../empreportdatatableview/empreportdatatableview.component';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-employeelogs',
  templateUrl: './employeelogs.component.html',
  styleUrls: ['./employeelogs.component.scss']
})
export class EmployeelogsComponent implements OnInit,AfterViewInit,OnDestroy {
    public employee: any;
    public empMainList = [];
    public currentCustomer;
    public total_req_count = 0;
    public parent_index :any = 0 ;
    public dateRange = [{
        id: '6',
        text: 'All'
    }, {
        id: '1',
        text: 'Today'
    }, {
        id: '2',
        text: 'Week'
    }, {
        id: '3',
        text: 'Month'
    }, {
        id: '4',
        text: 'Year'
    }, {
        id: '5',
        text: 'Range'
    }];
    public startDate;
    public endDate; 
    public selected = '2';
    today = new Date();
    minDate :any;
    maxDate = new Date(this.today);
    startDateModal: any = {
        start_date: new Date()
    };
    endDateModal: any = {
        end_date: new Date()
    };    
    public isReportGenerate=false;
    public isSrEnable = false;
    public emp_id = '';
    public selectedEmp = '';
    public isPrintDisabled:boolean = true;
    public subscriptionDataForAPI: Subscription = new Subscription();

    @ViewChildren(EmpreportdatatableviewComponent) empDataTables: QueryList<EmpreportdatatableviewComponent>;
    constructor(private location: Location, private app: AppComponent, public router: Router, private http: Http, public titleService: Title, public globalService: GlobalService) {
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
        setTimeout(()=>{
            let isErEnable = this.globalService.getAcl('employee_reports','views');
            if(!(isErEnable)){
                this.router.navigate(['shops/action/forbidden']);
            }
        },globalConstants.RELOAD_WAIT)
    }

    ngOnInit() {
        this.globalService.setMetaData("SHOPS", "EMPLOYEE_REPORT_LOGS") 
        this.startDate = this.formatDate(this.startDateModal.start_date);
        this.endDate = this.formatDate(this.endDateModal.end_date);
        this.loadEmployeeList();
        this.loadEmployeeReport();
    }

    ngAfterViewInit(){
        // console.log(this.empDataTables);
        // this.empDataTables.changes.subscribe((r) => { console.log('emp rows changed') });
    }

    loadEmployeeList(){
      this.subscriptionDataForAPI =  this.globalService.callAPI(globalConstants.API_GET_EMPLOYEES_DATA_URL,{}).subscribe((response:any)=>{
            if(response.result == 1){
                this.employee = response.data;
            }
        })
    }

    loadEmployeeReport(){
        let content = {
            user_id : this.currentCustomer.id,
            user_type: "User",
            date_criteria: this.selected,
            start_date: this.startDate,
            end_date: this.endDate,
            emp_id: this.emp_id
        };
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_EMPLOYEES_REPORTS_URL, content).subscribe((response:any) => {
            if(response.result == 1){
                this.empMainList = response.data;
                this.total_req_count = this.empMainList
                .map((item) => {
                    let currTotal = item.createdTickets ? parseInt(item.createdTickets) : 0;
                    currTotal += item.assignedTickets ? parseInt(item.assignedTickets) : 0;
                    return currTotal;
                }).reduce(( accumulator, currentValue ) => accumulator + currentValue);
            }else{
                this.total_req_count = 0;
                this.empMainList = [];
            }
            if(this.total_req_count > 0) this.isPrintDisabled = false;
        });
    }

    onPanelExpand(expIndex){
        // console.log('panel id ',expIndex);
        let empDataTableComponent = this.empDataTables.find((item,index,array) => { return index == expIndex});
        empDataTableComponent.setDataTable();
    }

    onPanelCollapse(expIndex){
        // console.log('collapse panel id ',expIndex);
    }

    updateDate(val) {
        let date = val;
        this.minDate = new Date(date.getTime() + 1);
    }
    
    submitDate() {
        this.startDate = this.formatDate(this.startDateModal.start_date);
        this.endDate = this.formatDate(this.endDateModal.end_date);
        this.loadEmployeeReport()
    }

    resetToDefault() {
        this.selected = '2';
        this.emp_id = '';
        this.selectedEmp = '';
        this.loadEmployeeReport();
    }

    public onChangeDate(event) {
        this.selected = event;
        if (event != '5') {
            this.loadEmployeeReport()
        }
    }
    public onChangeEmployee(event) { 
        this.emp_id = event;
        this.loadEmployeeReport()
    }
    setTouchViewValue() {
        return this.app.touchViewState;
    }

    formatDate(date) {
        if (!new Date(date) || date == null || date == "0000-00-00 00:00:00") {
            return "-"
        }
        return (new Date(date).getMonth() + 1) + "/" + new Date(date).getDate() + "/" + new Date(date).getFullYear()
    }

    scrolling() {
        this.globalService.setScrolling('.car-datatable', 1000, -20);
    }

    currentDate() {
        return (new Date().getMonth() + 1) + "/" + new Date().getDate() + "/" + new Date().getFullYear()
    }

    getEmpProductivity(employee){
        let hoursLogged = parseFloat(employee.total_hours_logged);
        let hoursBilled = parseFloat(employee.total_hours_billed);
        if(hoursLogged == 0) return 'N/A';
        return !isNaN(hoursBilled) && !isNaN(hoursLogged) ? Math.round((hoursBilled/hoursLogged)*100) : 0;
    }

    printDiv() {
        var divToPrint = document.getElementById('printarea');
        var htmlToPrint = '' +
            '<style type="text/css">' +
            'table th, table td {' +
                'border:1px solid #000;' +
                'padding;0.5em;' +
            '}' +
            '.main-content {' +
                'width:100%;' +
                'height:100%;' +
            '}' +
            '.panel-heading{'+
                'display:flex;' +
                'justify-content:space-between;' +
                'border-radius: 0px;'+
                'background: #3d3a49;'+
                'border-color: #3d3a49;'+
                'color: white;'+
                'margin: 5px;'+
                'padding: 5px 10px;'+
            '}'+
            '.card-default{'+
                'width: 25%;'+
                'background: #3d3a49;'+
                'color: white;'+
                'padding: 0;'+
                'border-radius: 2px;'+
            '}'+
            '.info-tile{'+
                'position: relative;'+
                'color: inherit;'+
                'border: 2px;'+
                'min-height: 87px;'+
                'padding: 15px;'+
                'border-radius: 5px;'+
                'margin: 10px 0;'+
                'font-size: 18px;'+
                'display: block;'+
            '}'+
            '</style>';
        htmlToPrint += divToPrint.outerHTML;
        let newWin = window.open("","","width=700,height=500");
        newWin.document.write("<h3 align='center'>Employee Reports</h3>");
        newWin.document.write("<div class='main-content'>");
        newWin.document.write(htmlToPrint);
        newWin.document.write("</div>");
        newWin.print();
        newWin.close();
    }

    ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
    }
}