import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { EditpopupComponent } from '../editpopup/editpopup.component';
import { ServiceHistoryComponent } from '../../../shop/shopservicerequest/service-history/service-history.component';
import { GlobalService } from "../../../../environments/global.service";
import { DetailTabComponent } from '../../shopservicerequest/detail-tab/detail-tab.component';
import { globalConstants } from '../../../../environments/globalConstants';
import { globalFunctions } from "../../../../environments/globalFunctions";
import { Subscription } from 'rxjs';
import {MatSort} from '@angular/material/sort'
const LS_SEARCH_PREFIX = 'last-sr-list-';

@Component({
  selector: 'app-last-service-vehicles',
  templateUrl: './last-service-vehicles.component.html',
  styleUrls: ['./last-service-vehicles.component.scss']
})
export class LastServiceVehiclesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  public searchPanelOpenStatus: boolean = true;
  public dataSource: MatTableDataSource<shopCarsData> = new MatTableDataSource();
  public options = {
    user_type: "User",
    user_id: "",
    search_keyword: "",
    search_by: '',
    lastServiceDays: "",
    vehiclesDateRange: '',
    isRejectJobs: false,
    is_export: 0,
    start: 0,
    limit: 10,
  };

  public searchKeyWordList = [
    { id: "", text: "Select By" },
    { id: "1", text: "Customer Name" },
    { id: "2", text: "Customer Email" },
    { id: "3", text: "Customer Mobile" },
    { id: "4", text: "Vehicle" },
    { id: "5", text: "Vin" },
    { id: "6", text: "License Plate" },
    { id: "7", text: "SR ID" },
  ];
  public searchByDaysList = [
    { id: "", text: "Last Service Date" },
    { id: "1", text: "Less than 180 days" },
    { id: "2", text: "More than 180 days" },
    { id: "3", text: "More than 365 days" },
    { id: "4", text: "More than 545 days" },
    { id: "5", text: "Date Range" },
  ];

  public dRangePickerConfig: any = { // date range picker config
    isDisabled: true
};  
  public displayedColumns = ['vehicle', 'VIN', 'licensePlate', 'customer', 'mobile', 'email', 'YTD', "YYYY", 'lastServiceDate', 'serviceTitle', 'Actions'];
  gc: any = globalConstants;
  public subscriptionDataForAPI: Subscription = new Subscription();

  public recode: any[];
  public currentCustomer: any;
  public recordsTotal: any;
  public dateRangeOptions: any = '';
  public previousYear: any;
  public aclPermissionsObj ={
    lastService : {
         permissionName : "view_all_vehicle",
         view : false
    },
    viewCustomerInfo : {
         permissionName : "view_customer_info",
         view : false
    }
  };
  public gAnauthObj :any ={
    message : "You don't have permissions to view this resource. Please contact administrator to get it enabled for your account."
  };
  constructor(
    public dialog: MatDialog,
    public globalService: GlobalService
  ) {
    this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
    this.options.user_id = this.currentCustomer.id;
    this.options.user_type = this.currentCustomer.user_type;
    this.globalService.setMetaData("SHOPS","LASTSERVICELIST");
  }

  ngOnInit(): void {
    this.aclPermissionsObj.lastService.view = this.isAllowed("view_all_vehicles",'views');
    this.aclPermissionsObj.viewCustomerInfo.view  = this.isAllowed('view_customer_info','views');
    var previousYear = new Date().getFullYear();
    this.previousYear =previousYear - 1;
    this.globalService.ShowDateRange(this.dRangePickerConfig);
    this.reinitializeOptions();
    // Retrieve values from local storage
    const searchKeyword = localStorage.getItem(LS_SEARCH_PREFIX + "search_keyword");
    const daysTillNextService = localStorage.getItem(LS_SEARCH_PREFIX + "days_till_next_service");

    if ( (searchKeyword  != '' && searchKeyword != null ) || (daysTillNextService != '' && daysTillNextService !=null)) {
      this.searchPanelOpenStatus = true;
    } else {
        this.searchPanelOpenStatus = false;
      }
      if(this.aclPermissionsObj.lastService.view){
          this.setDataTable();
      }
  }

  reinitializeOptions() {
    this.options=this.globalService.localGetterSetter(this.options,this.options,LS_SEARCH_PREFIX);
    this.options.user_type= "User";
    this.options.user_id= this.currentCustomer.id;
}


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  onDateChange(event: any) {
    if (event == null) {
      this.dateRangeOptions = null;
    } else {
      var stringDateFormat = [
        event.start.toISOString(),
        event.end.toISOString()
      ]
      this.dateRangeOptions = stringDateFormat;
    }
  }

  search(event) {
    this.options.start = 0;
    this.options.vehiclesDateRange = this.dateRangeOptions;
    if(this.dateRangeOptions !== null){
      this.setDataTable();
   } else{
      this.globalService.snackbar('error',"Please select or enter a valid date range");
   }
  }

  clearSearch() {
    this.options.start = 0;
    this.options.search_keyword = "";
    this.options.search_by = '';
    this.options.lastServiceDays = "";
    this.options.isRejectJobs = false;
    this.options.is_export = 0;
    this.dateRangeOptions = '';
    this.options.vehiclesDateRange = '';
    this.dRangePickerConfig.isDisabled = true;
    this.globalService.ShowDateRange(this.dRangePickerConfig);
    this.setDataTable();
  }

  setDataTable() {
    this.recode = [];
    var callApi = this.globalService.callAPI(globalConstants.API_GET_SHOP_VEHICLES, this.options);
    callApi.subscribe((data: any) => {
      if(data.result == '1'){
      if (data.data != undefined) {
        let moreRows = 'false';
        let firstList = 'false';
        this.globalService.localGetterSetter("totalRecords", data.recordsTotal, LS_SEARCH_PREFIX);
        if (data.more_rows == "true") {
          moreRows = "true";
        }
        this.globalService.localGetterSetter("more_rows", moreRows, LS_SEARCH_PREFIX);
        if (data.start >= 0 && data.start <= 5) {
          firstList = "true";
        }
        this.globalService.localGetterSetter("first_list", firstList, LS_SEARCH_PREFIX);
        this.recordsTotal = data.data.length;
        if (data.data.length == undefined) {
          this.dataSource.data = data.data
          this.AddRecord(data.data)
          this.globalService.localGetterSetter("current-visible", '1', LS_SEARCH_PREFIX);
          this.dataSource = new MatTableDataSource(this.recode);
          this.dataSource.sort = this.sort;
        } else {
          this.globalService.localGetterSetter("current-visible", this.recordsTotal, LS_SEARCH_PREFIX);
          for (var i = 0; i < data.data.length; i++) {
            this.AddRecord(data.data[i])
          }
          this.dataSource = new MatTableDataSource(this.recode);
          this.dataSource.sort = this.sort;

        }
        this.globalService.localGetterSetter(null, this.options, LS_SEARCH_PREFIX);
      } else {
        this.dataSource = new MatTableDataSource(this.recode);
        this.globalService.localGetterSetter("totalRecords", '0', LS_SEARCH_PREFIX);
      }
    }else{
       this.dataSource = new MatTableDataSource(this.recode);
       this.globalService.localGetterSetter("totalRecords", '0', LS_SEARCH_PREFIX);
       this.globalService.snackbar('error',data.message);
    }
    })
  }

  AddRecord(record) {
    var data = this.createRecord(record);
    this.recode.push(data);
  }

  private createRecord(record) {
    let DateFormat=this.globalService.DateFormat();
    return {
      requestId: record.request_id,
      carId: record.id,
      customerId: record.customer_id,
      vehicle: this.globalService.getGlobalVehicleName(record),
      VIN: record.vin || '-',
      licensePlate: record.license_plate || '-',
      customer: record.first_name + ' ' + record.middle_name + " " + record.last_name,
      mobile: record.mobile_phone,
      email: record.email,
      YTD: record.current_year_total,
      YYYY: record.last_year_total,
      lastServiceDate: this.globalService.getFullFormateDate(record.requested_delivery_date,DateFormat,'H'),
      lastServiceDays: record.requested_delivery_days_left,
      serviceTitle: record.title || "N/A",
    };
  }

  getTotalRecords() {
    let t = parseInt(localStorage.getItem(LS_SEARCH_PREFIX + 'totalRecords'));
    return (isNaN(t) ? 0 : t);
  }

  paginate(event, type) {
    this.scrolling();
    if (type == "next") {
      this.options.start = this.options.start + this.options.limit;
    } else {
      this.options.start = this.options.start - this.options.limit;
    }
    this.setDataTable();
  }

  scrolling() {
    this.globalService.setScrolling('.car-datatable', 1000, -20);
  }

  paginateByLimit(event) {
    this.globalService.setScrolling('body', 600, 0);
    this.options.limit = event.pageSize;
    this.options.start = 0;
    this.scrolling();
    this.setDataTable();
  }

  firstList() {
    return this.globalService.firstList(LS_SEARCH_PREFIX, this.options);
  }

  lastList() {
    return this.globalService.lastList(LS_SEARCH_PREFIX);
  }

  getUpperLimit(currentLastLimit, total) {
    if (currentLastLimit > total) {
      return total
    } else {
      return currentLastLimit
    }
  }

  checkFormEmpty(button) {
    if (this.options.search_keyword == "" && this.options.search_by == '' && this.options.lastServiceDays == "" && this.options.isRejectJobs == false
    ) {
      if (button) { button.color = "primary" }
      return false
    } else {
      if (button) { button.color = "accent" }
      return true;
    }
  }

  openViewCarPopUp(data, tabInfo) {
    let dialogRef = this.dialog.open(DetailTabComponent, {
      panelClass: 'car-dialog-form',
      width: "800px",
      data: { requestId: data.requestId, customer_id: data.customerId, tab: tabInfo, car_id: data.carId }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openEditCarPopUp(carId: any) {
    let dialogRef = this.dialog.open(EditpopupComponent, {
      panelClass: 'car-dialog-form',
      width: '100%',
      data: { customer_id: this.currentCustomer.id, car_id: carId }
    });
    dialogRef.afterClosed().subscribe((r: any) => {
    });
  }

  openServiceHistoryPopup(carId: any) {
    let info: any = {
      id: 'service-history-' + '37413',
      name: 'Service History View',
      module: 'Service Request'
    }
    let data: any = { isPopup: true, carId: carId, id: 'service-history-' + carId };
    this.globalService.openMinimizablePopup(ServiceHistoryComponent, info, data);
  }

  goToSR(SRID: any) {
    window.open('/shops/view-service-request/' + SRID, "_blank");
  }

  onChangeDays(event: any) {
    if (event == 5) {
      this.dRangePickerConfig.isDisabled = false;
      this.dateRangeOptions = null;
      this.globalService.ShowDateRange(this.dRangePickerConfig);
    } else {
      this.dRangePickerConfig.isDisabled = true;
      this.dateRangeOptions = '';
      this.globalService.ShowDateRange(this.dRangePickerConfig);
    }
  }

  downloadVehiclesCSV() {
    this.options.is_export = 1;
    var content = globalFunctions.stringifyFormData(this.options, null);
    var callApi = this.globalService.callAPI(globalConstants.API_GET_SHOP_VEHICLES, content);
    this.subscriptionDataForAPI = callApi.subscribe((data: any) => {
      var reportData = data;
      window.location.href = globalConstants.API_SHOP_DOWNLOAD_SERVICE_REQUEST_LIST + "?t=2&p=" + reportData.csv_url;
      this.options.is_export = 0;
    });
  }

  /** day or days accoding to day count */
  showDays(day,t){
    if(t){
      return (day ? day + ( day == 1 ? ' Day':' Days') :'Day');
    }else{
      return (day ? (day>999?'999+':day) + ( day == 1 ? ' Day':' Days') :'Day');
    }
  }

  isAllowed(moduleName, action){
    return this.globalService.getAcl(moduleName,action);
  }
}

export interface shopCarsData {
  requestId: any;
  customerId: any;
  carId: any;
  vehicle: any;
  VIN: any;
  licensePlate: any;
  customer: any;
  mobile: any;
  email: any;
  YTD: any;
  YYYY: any;
  serviceTitle: any;
  lastServiceDate: any;
  lastServiceDays:any;
}
