<div class="panel panel-primary" id="mainCompotents">
  <div class="panel-heading padding-left-15">
    <h3 class="panel-title">STOCK ORDERS LISTING</h3>
  </div>
  <div class="panel-body bgcolor-lightgrey">
        <div class="display-full relative" *ngIf="isSubscribed == true">
                <mat-expansion-panel hideToggle="false" class="search-filter search-employee relative" [expanded]="openCustomerSearch">
                    <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px">
                        <strong><i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
                    </mat-expansion-panel-header>
                    <form id="advanceSearchForm" class="collapse in" aria-expanded="true">
                        <div class="panel-body">    
                            <div class="col-sm-3">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <input  matInput placeholder="Search Keyword" title="Search Keyword" [(ngModel)]="options.search_keyword" [ngModelOptions]="{standalone: true}" class="form-control input-lg custom-form-input"/>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-3">
                                <mat-form-field class="car-input btn-block car-datePicker" floatPlaceholder="never" appearance="fill">
                                <input matInput class="form-control input-lg custom-form-input" [(ngModel)]="options.orderDate" [ngModelOptions]="{standalone: true}" placeholder="Order Date"
                                [matDatepicker]="picker2" (focus)="picker2.open()" />
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                            </div>
                            
                        </div>
                        <mat-action-row class="search-filter-footer">
                          <button mat-raised-button color="accent" type="submit" (click)="search($event)" title="Search">
                            Search
                          </button>
                        </mat-action-row>
                    </form>
                </mat-expansion-panel>
                <button mat-button title="Clear Filter" class="clear-btn" #clearBtn (click)="clearSearch()" [class.active-search]="checkFormEmpty(clearBtn)">
                    Clear Filter
                </button>
            </div>
    <div class="display-full relative margin-bottom-15 text-right text-right-btn" *ngIf="isAllowed('addNew') && isSubscribed">
        <button mat-raised-button class="mat-success" type="button" (click)="QueOrder()" title="Stock Order Queues">Stock Order Queues</button>
        <button mat-raised-button class="mat-success" type="button" (click)="AddNew()" title="Create Stock Order">Create Stock Order</button>
    </div>
    <div class="row bgcolor-lightgrey form-content padding-top-45" *ngIf="isSubscribed == false">
        <div class="container-fluid  padding-bottom-15" style="text-align: center;font-size: 22px; color:#a94442;">
            Upgrade your plan to add PartsTech Connection.
        </div>
        <div class="padding-bottom-45 col-xs-12" style="text-align: center;padding: 10px;">
            <button type="button" mat-raised-button class="mat-success font-bold mat-elevation-z1" (click)="gotoShopSubscription()" style="background-color: #f50057;">Upgrade Now</button>
        </div>
    </div>
    <div class="car-datatable uc-datatable mat-elevation-z1" *ngIf="isSubscribed == true">
        <div class="text-right hidden">
            <mat-form-field floatPlaceholder="never" >
                <input matInput #filter placeholder="Filter users">
            </mat-form-field>
        </div>
        <div class="table-responsive relative">
            <mat-table #table [dataSource]="dataSource" matSort class="service-list datatable-loader-bg uc-table-responsive">

                <!-- Color Column -->
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Order No. </mat-header-cell>
                    <mat-cell *matCellDef="let row"  data-role="auto" data-val="Order No."> {{row.id}} </mat-cell>
                </ng-container>

                <!-- Color Column -->
                <ng-container matColumnDef="add_date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Order Date </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-role="auto" data-val="Order Date"> {{row.add_date}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="que_name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Queue Name </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Queue Name"> {{(row.que_name) ? row.que_name : '-'}} </mat-cell>
                </ng-container>

                <!-- Color Column -->
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef data-role="button">Action</mat-header-cell>
                    <mat-cell *matCellDef="let row"  data-role="button">
                      <button  *ngIf="isAllowed('views')" mat-raised-button class="btn-view" color="accent" type="submit" (click)="ViewStockOrder(row.id)">
                        View
                      </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" class="mat-row"></mat-row>

            </mat-table>
            <div class="padding-15 text-center"
                 [style.display]="getTotalRecords() == 0 ? '' : 'none'">
                No Records Available.
            </div>
        </div>
        <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
             <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="options.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event)" >
            </mat-paginator>

            <div class="mat-paginator table-paging">
                <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
                <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                        <mat-icon>navigate_before</mat-icon>
                </button>
                <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                        <mat-icon>navigate_next</mat-icon>
                </button>
            </div>
        </div>
    </div>
  </div>
</div>
