<div class="car-panel" id="mainCompotents">
    <div class="panel panel-primary quickBooks-component">
        <div class="panel-heading padding-left-15">
            <h3 class="panel-title">
                Quick Books 
            </h3>
        </div>
        <div class="panel-body bgcolor-lightgrey">
            <div  class="container-fluid">
                <div class="form-content">
                    <div class="row padding-top-25 upgrade-section" *ngIf="isSubscribed == 0">
                        <div class="col-sm-12 padding-bottom-15 info-text">
                            Upgrade your plan to connect your Quickbooks Online account.
                        </div>
                        <div class="col-xs-12 btn-col">
                            <button type="button" mat-raised-button class="font-bold mat-elevation-z1" (click)="gotoShopSubscription()" color="accent">Upgrade Now</button>
                        </div>
                    </div>
                    <div class="row connection-control-section" *ngIf="isSubscribed == 1">
                        <div class="col-sm-12 connect-col" *ngIf="isQuickBookConnected">
                            <div class="row">
                                <div class="col-sm-12">
                                    <p>Your Quickbooks Online Account is already connected with CAR and invoices syncing is happening on daily basis.</p>
                                </div>
                                <div class="col-sm-12 margin-bottom-25">
                                    <p>Status: <span class="font-bold text-success">Connected</span></p>
                                    <p>Syncing: <span class="font-bold text-success">Daily basis</span></p>
                                </div>
                                <div class="col-sm-12 margin-bottom-5">
                                    <p>Disconnect Quickbooks Online - If you want you can disconnect your Quickbooks Online Account at any time. Click below "Disconnect" button to disconnect now.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 disconnect-col" *ngIf="!isQuickBookConnected">
                           <p>Please click below "<span class="font-bold">Connect Now</span>" button and follow the instruction to connect your Quickbooks Online account and start syncing your CAR invoices into Quickbooks.</p>
                        </div>
                        <div class="col-sm-2">
                            <button mat-raised-button color="accent" class="btn-block btn-lg" *ngIf="isQuickBookConnected" (click)="disconnectWithQuickBook()">Disconnect</button>
                            <button mat-raised-button class="connect-btn btn-block btn-lg" *ngIf="!isQuickBookConnected" (click)="connectWithQuickBook()">Connect Now</button>
                       </div>
                    </div>
                    <!-- <div class="row" *ngIf="isSubscribed == 1">
                     
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>



<!-- <button (click)="connect()">connect</button> -->
