import {Component, OnInit, ViewChild, OnDestroy, ViewEncapsulation} from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd, Params} from '@angular/router';
import {Http, Headers, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../../environments/globalFunctions"
import {globalConstants} from "../../../../environments/globalConstants"
import {lang} from "../../../../environments/lang-en"
import {Title, DomSanitizer} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';
import {GlobalService} from "../../../../environments/global.service";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {VideoShowComponent} from '../../../customer/my-vehicles/video-show/video-show.component';
import { ImagetooldialogComponent } from '../../../imagetooldialog/imagetooldialog.component';
import { EditpopupComponent } from '../editpopup/editpopup.component';
import {​ ServiceHistoryComponent }​ from '../../../shop/shopservicerequest/service-history/service-history.component';
import { VehicleInspectionReportComponent } from '../../shopservicerequest/vehicle-inspection-report/vehicle-inspection-report.component';
import { UspsVehicleInspectionReportComponent } from '../../shopservicerequest/usps-vehicle-inspection-report/usps-vehicle-inspection-report.component';

@Component({
    selector: 'app-shopcardetails',
    templateUrl: './shopcardetails.component.html',
    styleUrls: ['./shopcardetails.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ShopcardetailsComponent implements OnInit,OnDestroy {
    public carCustomerId;
    public vehicleId;
    public car;
    public currentCustomer;
    public imgPath = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_PICTURES + globalConstants.IMG_THUMB;
    public insurance = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_INSURANCE + globalConstants.IMG_THUMB;
    public registeredPicture = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_REGISTRATION_PIC + globalConstants.IMG_THUMB;
    public vinPicture = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_VIN_PIC + globalConstants.IMG_THUMB;
    public vehVideo = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_VIDEO;
    public VEHICLE_LABEL_PIC= globalConstants.S3_BUCKET_URL +globalConstants.VEHICLE_LABEL_PICTURES + globalConstants.IMG_THUMB
    public vehicleRegisteredPic = [];
    public vehicleInsuredPic = [];
    public vPic = [];
    public videoList = [];
    public vinPhoto = '';
    public productionDate;
    public countryName;
    public carDetails:any={}
    public customFields=[]
    public labelPhoto=[];
    gc:any = globalConstants;
    public subscriptionDataForAPI: Subscription = new Subscription();
    public vehicleInspReportData: any;
    public showingInpReport:boolean = false;
    public reportDownloadLink: string;
    constructor(private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, public titleService: Title, private http: Http, private router: Router, public globalService: GlobalService, public dialog: MatDialog,private _sanitizer:DomSanitizer) {
        this.activatedRoute.params.subscribe((params: Params) => {
            this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
            this.carCustomerId = localStorage.getItem("carDetailsCustomerID");
            this.vehicleId = localStorage.getItem("carDetailsVehicleID");
            this.countryName = localStorage.getItem("carCustomerCountryName");
        });
        this.initializeFormBuilder(null);
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_VEHICLES_URL, "customer_id=" + this.carCustomerId + "&vehicle_id=" + this.vehicleId).subscribe((data:any) => {
                let vData = data.data;
                this.carDetails=data.data;
                this.vehicleInspReportData = data.data.vir_info;
                this.showingInpReport = true;
                this.createVIRDownloadUrl();
                if(this.carDetails.custom_fields && this.carDetails.custom_fields.length){
                    this.customFields=this.carDetails.custom_fields
                }
                this.initializeFormBuilder(vData);
                this.vehicleRegisteredPic = vData.vehicle_registered_pic;
                this.vehicleInsuredPic = vData.vehicle_insured_pic;
                this.vPic = vData.vehicle_pic;
                this.videoList = vData.vehicle_video;
                this.vinPhoto = vData.vin_photo;
                if(typeof vData.vehicle_label_pic!='undefined' && vData.vehicle_label_pic.length){
                    this.labelPhoto=vData.vehicle_label_pic
                }
                if (vData.production_date != "0000-00-00 00:00:00" && vData.production_date != "" && vData.production_date != null) {
                    var splitDatesValues = vData.production_date.split("-");
                    this.productionDate = splitDatesValues[1] + "/" + splitDatesValues[0];
                } else {
                    // this.productionDate = 'N/A';
                }

            },(error)=>{});
        this.globalService.setMetaData("SHOPS","VIEW_CAR_DETAIL")
    }

    initializeFormBuilder(object) {
        if (object != null) {
            this.car = this.formBuilder.group({
                customer_id: [this.carCustomerId],
                old_customer_id: [''],
                vin: [object.vin],
                production_date: [object.add_date],
                year: [object.year, [Validators.required, Validators.maxLength(4)]],
                make: [object.make, Validators.required],
                model: [object.model, Validators.required],
                sub_model: [object.sub_model],
                mileage: [object.mileage],
                license_plate: [object.license_plate, Validators.required],
                color: [object.color, Validators.required],
                unit_number: [object.unit_number],
                driver: [object.driver],
                driver_phone_number: [object.driver_phone_number],
                distance_covered: [object.distance_covered],
                vehicle_registered_pic: [''],
                vehicle_insured_pic: [''],
                vehicle_pic: [''],
                vehicle_video: [''],
                registration_expiration_date: [this.globalService.getFullFormateDate(object.registration_expiration_date,'GD','H')],
                insurance_expiration_date: [this.globalService.getFullFormateDate(object.insurance_expiration_date,'GD','H')],
                inspection_expiration_date: [this.globalService.getFullFormateDate(object.inspection_expiration_date,'GD','H')],
                vin_photo: [''],
                state: [object.state, Validators.required],
                vehicle_id: [this.vehicleId]
            });
        } else {
            this.car = this.formBuilder.group({
                customer_id: [this.carCustomerId],
                old_customer_id: [''],
                vin: [],
                production_date: [],
                year: ['', [Validators.required, Validators.maxLength(4)]],
                make: ['', Validators.required],
                model: ['', Validators.required],
                sub_model: [''],
                mileage: [''],
                license_plate: ['', Validators.required],
                color: ['', Validators.required],
                unit_number: [''],
                driver: [''],
                driver_phone_number: [''],
                distance_covered: ['', [Validators.pattern("^[0-9]*$")]],
                vehicle_registered_pic: [''],
                vehicle_insured_pic: [''],
                vehicle_pic: [''],
                vehicle_video: [''],
                registration_expiration_date: [''],
                insurance_expiration_date: [''],
                inspection_expiration_date:[''],
                vin_photo: [''],
                state: ['', Validators.required],
                vehicle_id: [this.vehicleId]
            });
        }
    }
    ngOnInit() {
    }
    /*******video dialog******/
    videoShow(val): void {
        let dialogRef = this.dialog.open(VideoShowComponent, {
            panelClass: 'car-dialog-form',
            width: "800px",
            data: {name: val, url: globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_VIDEO}
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }
    /* image rotation dialog  */
    imageToolDialog(current,i,type,imgaar): void {
        let dialogRef = this.dialog.open(ImagetooldialogComponent, {
            panelClass: ['imageToolDialog'],
            width: "100%",
            data: {event: current,index:i,imgType:type,imgArray:imgaar,customerId:this.currentCustomer.id,carId:this.vehicleId}
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }
    
    editCar(customer_id, vehicle_id, country_name) {
        // localStorage.setItem("carDetailsCustomerID", customer_id);
        // localStorage.setItem("carDetailsVehicleID", vehicle_id);
        // localStorage.setItem("carCustomerCountryName", country_name);
        // this.router.navigate(['shops/edit-car']);
        if(this.carDetails.is_walkin_customer_vehicle == '1'){
            this.globalService.handleWalkinProfileEditDelete(this.carDetails.is_walkin_customer_vehicle);
            return false;
          }
        let dialogRef = this.dialog.open(EditpopupComponent, {
            panelClass: 'car-dialog-form',
            width: '100%',
            data: { customer_id: customer_id, car_id:vehicle_id }
          });
          dialogRef.afterClosed().subscribe((r: any) => {
            
          });
    }
    
    showCustomData() {
        let total = 0
        if (this.customFields && this.customFields.length) {
            total = this.customFields.length
        }
        let html = ''
        let td = ''
        for (let n in this.customFields) {
            let f = this.customFields[n]
            let i = parseInt(n)
            td += '<td width="25%"><label>' + f.label + '</label></td><td width="25%"><p class="pull-left">' + (f.value ? f.value : '-') + '</p></td>'
            i += 1;
            if (i && (i % 2) == 0) {
                html += '<tr>' + td + '</tr>'
                td = ''
            }
        }
        // console.log(html)
        return html;
    }
    getFieldVal(f){
        if(f.type=='textbox'){
            return (f.value ? f.value : '-')
        }else if(f.type=='checkbox'){
            return (f.value ? 'Yes' : 'No')
        }else{
            let d=(f.field_option ? f.field_option:'')
            return (d && d.value ? d.value: '-')
        }
    }
    
    openServiceHistoryPopup(){​
        // Material Popup Dialog
        
        // let dialogRef = this.dialog.open(ServiceHistoryComponent, {​
        //   panelClass: 'car-dialog-form',
        //   width:'95%',
        //   data: {​ isPopup: true, car_id:this.serviceDetail.car_id, service_data: this.serviceDetail }​
        // }​);
        // dialogRef.afterClosed().subscribe(result => {​
        //   console.log('history popup closed = ', result);
        // }​);
        let info:any = {
          id:'service-history-'+this.vehicleId,
          name: 'Service History View',
          module: 'Service Request'
        }
        let data:any = {​ isPopup: true, carId:this.vehicleId, serviceInfo: undefined, id: 'service-history-'+this.vehicleId };
        this.globalService.openMinimizablePopup(ServiceHistoryComponent, info, data);
    }
    
    ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
    } 

    createVIRDownloadUrl() {
        let inspReportDownloadUrl = '';
        let reportData;
        let qryStringUrl = "";
        if (this.vehicleInspReportData.vir_found == 1) {
          reportData = this.vehicleInspReportData.inspection_reports[0].acdelco_report_data;
          if (reportData) {
            inspReportDownloadUrl = globalConstants.API_DOWNLOAD_INSPECTION_REPORT_ACDELCO_URL;
            qryStringUrl += "?report_type=2";
            qryStringUrl += "&acdelco_report_id=" + reportData.id;
          } else {
            inspReportDownloadUrl = globalConstants.API_DOWNLOAD_INSPECTION_REPORT_USPS_URL;
            reportData = this.vehicleInspReportData.inspection_reports[0].usps_report_data;
            qryStringUrl += "?report_type=1";
            qryStringUrl += "&usps_report_id=" + reportData.id;
          }
          this.reportDownloadLink = inspReportDownloadUrl + qryStringUrl;
        } else {
          this.reportDownloadLink = 'javascript:void(0);';
        }
      }
    
    openVIR() {
        if (this.vehicleInspReportData.vir_found == 0) {
            this.globalService.snackbar('error', 'No report performed in this vehicle.');
        } else {
            if (this.carDetails.usps_vehicle == 1) {
                this.openUspsVIR();
            } else {
                this.openAcdelcoVIR();
            }
        }
    }

    openUspsVIR(){
        let dialogRef = this.dialog.open(UspsVehicleInspectionReportComponent, {
          panelClass: ['car-dialog-form', 'inscpection-dialog','usps-panel-disabled'],
          data: { service_request_id: this.vehicleInspReportData.request_id, report_data: this.vehicleInspReportData.inspection_reports[0].usps_report_data,disableFormInputControls:1,fullRequestId: this.vehicleInspReportData.car_request_id}
        });
        dialogRef.afterClosed().subscribe(result => {   
        });
    }

    openAcdelcoVIR(){
        let dialogRef = this.dialog.open(VehicleInspectionReportComponent, {
            panelClass: ['car-dialog-form', 'inscpection-dialog','acdelco-panel-disabled'],
            data: { service_request_id: this.vehicleInspReportData.request_id, report_data: this.vehicleInspReportData.inspection_reports[0].acdelco_report_data,disableFormInputControls:1,fullRequestId: this.vehicleInspReportData.car_request_id }
        });
          dialogRef.afterClosed().subscribe(result => {   
        });
    }

    handleDownloadVIR(){
    if(this.vehicleInspReportData.vir_found == 0){
        this.globalService.snackbar('error', 'No report performed in this vehicle.');
    }else{
       let  localFileName = 'CARInspectionReport.pdf';
       this.globalService.downloadAsset(this.reportDownloadLink,localFileName);
     }
    }

    handleVIRTitle(){
        if(this.vehicleInspReportData.vir_found == 0){
             return "No inspection report performed on this vehicle.";
        }else{
            return "Vehicle Inspection Report";
        }
    }

    handleVIRDownloadTitle() {
        if (this.vehicleInspReportData.vir_found == 0) {
          return "No inspection report performed on this vehicle.";
        } else {
          return "Download Vehicle Inspection Report";
        }
    }
}
