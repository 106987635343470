import { Component, OnInit, ContentChild, Input, Output, TemplateRef,  QueryList, ElementRef, EventEmitter } from '@angular/core';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
import { Observable } from 'rxjs';
import { Http } from '@angular/http';
import { Router } from '@angular/router';
import { QqpaymentComponent } from '../qqpayment/qqpayment.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-addjobs',
  templateUrl: './addjobs.component.html',
  styleUrls: ['./addjobs.component.scss']
})
export class AddjobsComponent implements OnInit {

  @Input() customer: any;
  @Input() seletedCar: any;
  @Input() qqdialogRef:any;
  @Input() editMode:any;
  @Output() isEditMode = new EventEmitter<any>();
  public user: any = null
  public newJobs: any = [];
  public showFillrecommendation = false
  public allJobs:any=[]
  showCostPrice=false
  public recomendationData:any={}
  public qq_notes:any=''
  @ContentChild("fillrecomtmp") fillrecomtmp: QueryList<any>;
  constructor(public gs: GlobalService,public router: Router, public http: Http,public dialog: MatDialog) {
    this.user = this.gs.getCurrentUser()
  }

  ngOnInit() {
    // console.log(this.seletedCar)
    this.showCostPrice=this.gs.getAcl('display_part_cost','views');
    if(this.editMode && typeof this.editMode.id !='undefined'){
      this.prepareFillRecommendation({id:this.editMode.id})
    }
  }
  findJob() {
    console.log(this.seletedCar)
  }
  observableSourceForx = (keyword: any): Observable<any[]> => {
    let url: string = globalConstants.API_search_service_requests_list + '?keyword=' + keyword;
    if (typeof keyword != 'undefined' && keyword.length >= 3) {
      let f = [];
     return this.gs.getAPI(url).map((res:any)=>{
        console.log("this is find job",res)
        let json = res;
          this.gs.authText(json);
          if (json && json != null && typeof json.data != 'undefined') {
            json.data.forEach(function (x) {
              f.push(x);
            });
          }
          return f;
      })
    } else {
      return Observable.of([]);
    }
  }
  /** add selected job */
  getSelectedOptionForx(opt) {
    let s1 = { id: 0, ln: '', child: [] };
    let s2 = { id: 0, ln: '', child: [] };
    let s3 = { id: 0, ln: '', type: '', value: '' };
    if (opt && typeof opt.level3Id != 'undefined' && opt.level3Id != '') {
      s3.id = opt.level3Id;
      s3.ln = opt.level3Name;
      s3.type = opt.input;
    }
    if (opt && typeof opt.level2Id != 'undefined') {
      s2.id = opt.level2Id;
      s2.ln = opt.level2Name;
    }
    if (opt && typeof opt.level1Id != 'undefined') {
      s1.id = opt.level1Id;
      s1.ln = opt.level1Name;
    }
    let isAddedl3 = this.alreadyAdded(s3, 0);
    let isGroupFound = (isAddedl3 ? false : this.alreadyAdded(s3, s2.id));
    let isAdded = this.alreadyAdded(s2, 0);
    if (isGroupFound && s3.type == 'radio') {
      this.gs.snackbar("error", 'Only one job can be selected from radio group ' + s1.ln + ' -> ' + s2.ln + '.');
    } else if (isAdded || isAddedl3) {
      this.gs.snackbar("error", 'Job already exists.');
    }
    if (isAdded || isAddedl3 || isGroupFound) {
      this.clearSearch();
      return false;
    }
    let isAlreadyAdded = false;
    let f = this.findNewJobs(this.newJobs, s1);
    if (f === false && s1.id != 0) {
      if (s3.id != 0) {
        s2.child.push(s3);
      }
      s1.child.push(s2);
      this.newJobs.push(s1);
    } else if (typeof this.newJobs[f] != 'undefined') {
      let f2 = this.findNewJobs(this.newJobs[f].child, s2);
      if (f2 === false && s2.id != 0) {
        if (s3.id != 0) {
          s2.child.push(s3);
        }
        this.newJobs[f].child.push(s2);
      } else if (s3.id != 0) {
        if (s3.type == 'radio' && this.newJobs[f].child[f2].child.length) {
          this.gs.snackbar("error", 'Only one job can be selected from radio group ' + s1.ln + ' -> ' + s2.ln + '.');
        } else {
          let f3 = this.findNewJobs(this.newJobs[f].child[f2].child, s3);
          if (f3 === false) {
            this.newJobs[f].child[f2].child.push(s3);
          } else {
            isAlreadyAdded = true;
          }
        }

      } else {
        isAlreadyAdded = true;
      }

      if (isAlreadyAdded) {
        this.gs.snackbar("error", 'This option already selected');
      }

    }
    this.clearSearch();
  }
  clearSearch() {
    let txt = <HTMLInputElement>document.getElementById('qqAddJobAattribute')
    txt.value = '';
  }
  /** find job already in service request */
  alreadyAdded(obj, rg) {
    return false
  }
  carAttribute() {
    return false;
  }
  autocompleListFormatter(opt) {
    return opt.value;
  }
  /** find data then return index */
  findNewJobs(data, opt) {
    let th = this;
    let I = null;
    let f = data.find((x, v) => {
      let jf = false;
      if (x.id == opt.id) {
        jf = true; I = v;
      }
      return jf;
    });
    return (typeof f == 'undefined' ? false : I);
  }
  /** submit new selected job */
  addNewJobs() {
    let data = {
      new_jobs: JSON.stringify(this.newJobs),
      user_type: this.user.user_type,
      user_id: this.user.id,
      customer_id: this.customer.id,
      vehicle_id: this.seletedCar.id,
      quote_id:(this.recomendationData ? this.recomendationData.id :''),
      qq_notes:this.qq_notes
    };
    let enableMode = {
      customer_id: this.customer.id,
      car_id: this.seletedCar.id,
      id:''
    };
    // console.log(data)
    let formdata = this.gs.formsData(data);
    this.gs.formData(globalConstants.API_QQ_CREATION, formdata).subscribe((r) => {
      if(r.result==1){
        this.newJobs = []
        this.gs.snackbar("success", r.message);
        this.gs.qqGrid(true) /** refresh Quick Quote List */
        this.prepareFillRecommendation(r.data)
        enableMode.id=r.data.id
        this.isEditMode.emit( {"data": enableMode} );
      }else{
        this.gs.snackbar("error", r.message);
      }
    }, (error) => { })
  }
  /** delete selected job */
  deleteNewJob(i, j, k?: any) {
    this.gs.confirmDialog("yes", "Are you sure?").subscribe((response) => {
      if (response != undefined && response.toString() == "yes") {
        if (typeof k == 'undefined') {
          this.newJobs[i].child.splice(j, 1);
        } else {
          this.newJobs[i].child[j].child.splice(k, 1);
          if (this.newJobs[i].child[j].child.length == 0) {
            this.newJobs[i].child.splice(j, 1);
          }
        }
        if (this.newJobs[i].child.length == 0) {
          this.newJobs.splice(i, 1);
        }
        // this.cdr.markForCheck();
      }
    });
  }
   /** add text to selected job
   *  if it is textarea */
  addNewJobComment(obj, i, j, k) {
    this.newJobs[i].child[j].child[k].value = obj.target.value;
  }
  /** get the all job list to  */
  prepareFillRecommendation(param){
    let data={quote_id:param.id,user_id: this.user.id};
    this.gs.callAPI(globalConstants.API_QQ_DETAIL, data).subscribe((r:any) => {
      if(r.result==1){
        this.showFillrecommendation=true;
        this.recomendationData=r.data;
        if(this.recomendationData.notes){
          this.qq_notes=this.recomendationData.notes;
        }
        this.allJobs=r.data.service_list;
      }else{
        this.gs.snackbar("error", r.message);
      }
    }, (error) => { })
  }
  gotoQQList(){
    this.qqdialogRef && this.qqdialogRef.close();
    this.router.navigateByUrl("/shops/quick-quotes");
  }
  allRecomendation(jsonData){
    let update_customer=0
    /** update to customer */
    if(jsonData.type==3){
      update_customer=1
    }else if(jsonData.type==4){
      this.payment()
      return true
    }
    let reqParams={
      quote_id:this.recomendationData.id,user_id: this.user.id,
      new_jobs:jsonData.recom,update_customer:update_customer,qq_notes:this.qq_notes
    }
    

    var data = new FormData();
    for(let i in reqParams){
        data.append(i, reqParams[i]);
    }
    this.gs.formData(globalConstants.API_QQ_SAVE_RECOMMENDATION, data).subscribe((r) => {
      if(r.result==1){
        this.gs.snackbar("success", r.message);
        this.gs.qqGrid(true) /** refresh Quick Quote List */
        if(jsonData.type==2){
          this.gotoQQList()
        }
      }else{
        this.gs.snackbar("error", r.message);
      }
    });
  }
  payment(){
    let row={id:this.recomendationData.id}
    let dialogRef: any = this.dialog.open(QqpaymentComponent, {
      panelClass: ['payment-dialog'],
      width: "100%",
      data: row
    });
    dialogRef.afterClosed().subscribe(r => {
      if (r == 1) {
        if(this.editMode && typeof this.editMode.id !='undefined'){
          this.showFillrecommendation=false
          this.prepareFillRecommendation({id:this.editMode.id})
        }
      }
    })
  }
  ngOnDestroy(){
    this.gs.qqGrid(false);
  }

}
