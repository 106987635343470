import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';



@Component({
  selector: 'shop-vehicle-service',
  templateUrl: './shop-vehicle-service.component.html',
  styleUrls: ['./shop-vehicle-service.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class ShopVehicleServiceComponent implements OnInit {

  public param:any={};
  public tickets:any = [];
  public reqPath=location.origin+'/shops/view-service-request/';
  public statuses = ['Appointment', 'Pending', 'Processing', 'Waiting for Approval', '', 'Service Completed', 'Completed', 'Approval Completed', 'Completed And Paid', 'Unread Rating', 'Non-CAR','','Work in progress'];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public gs: GlobalService,public dialogRef: MatDialogRef<ShopVehicleServiceComponent>) {
    this.param=data;
  }

  ngOnInit() {  
    this.tickets = this.param.tickets;
  }

  getBalancAmount(ticket:any){
    let balance =   ticket.grand_total > 0? (ticket.grand_total- ticket.paid_amount) : 0;
    return balance? balance.toFixed(2):0.00;

  }

  getPaidAmount(ticket:any){
    let paidAmount =   (ticket.paid_amount?ticket.paid_amount: 0.00);
    return paidAmount;

  }

  openLink(id:string){
    let url = this.reqPath+id;
    window.open(url,'_blank');
  }
 
  getSRStatusColor(ticket) {
    let status: any = ticket.is_appointment == '1' && (ticket.status == '1' || ticket.status == '2') ? 0 : ticket.status;
    if (status != -1) {
      var statusArr = ['badge-appointment', 'badge-pending', 'badge-inprogress', 'badge-approval-awaitted', "", 'badge-completed', 'badge-completed', 'badge-completed', 'badge-paid', "", "", "", 'badge-work-in-progress'];
      return statusArr[status];
    } else {
      return 'deleted-cell';
    }
  }
}
