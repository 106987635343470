import {Component, OnInit, ViewChild, Input, ViewEncapsulation} from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd,Params} from '@angular/router';
import {DpDatePickerModule} from 'ng2-date-picker';
import {Http, Headers, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../../environments/globalFunctions"
import {globalConstants} from "../../../../environments/globalConstants"
import {lang} from "../../../../environments/lang-en"
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';
// import {MatSnackBar} from '@angular/material';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {GlobalService} from "../../../../environments/global.service"
import {AppComponent} from "../../../app.component"
import { ImagetooldialogComponent } from '../../../imagetooldialog/imagetooldialog.component';
declare var jQuery:any;
declare var $ :any;
@Component({
  selector: 'app-view-diy-service-request-details',
  templateUrl: './view-diy-service-request-details.component.html',
  styleUrls: ['./view-diy-service-request-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewDiyServiceRequestDetailsComponent implements OnInit {
  public request;
  public requestContent;
  public content;
  public idFromUrl;
  public serviceRequestData: FormGroup;
  public serviceRequestsList = [];
  public requestData;
  public totalAcceptedCalculation = []
  public rejectedJobCalculation = [];
  public grandTotalAcceptedArray = [];
  public grandTotalRejectedArray = [];
  public nonTaxableValue = [];
  public UnTaxedAcceptedValue = 0;
  public totalAcceptedValue ="";
  public totalRejectedValue ="";
  public totalReturnedValue;
  public finalShowAbleAmount;
  public conservingStatus = [];
  public statusAcceptText:any;
  public statusRejectText:any;
  public recIds = []
  public ObjectJson = {}
  public globalConstants=globalConstants;
  public vehicleName="";
  constructor(public globalService: GlobalService, public dialog: MatDialog, private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder) {
    this.globalService.setMetaData("CUSTOMER","SERVICE_REQUEST_DETAIL")
  }

  ngOnInit() {
    this.initializeForm()
    this.setParams();
    var content = "customer_id=" + this.globalService.getCurrentCustomer()["id"] + "&service_request_id=" + this.idFromUrl;
    this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUEST_VIEW_URL, content)
    .subscribe((data:any) => {
      if (data.result == "1") {
        this.serviceRequestData.controls.add_date.setValue(data.data.add_date)
        this.serviceRequestData.controls.address1.setValue(data.data.address1)
        this.serviceRequestData.controls.address2.setValue(data.data.address2)
        this.serviceRequestData.controls.balance_amount.setValue(data.data.balance_amount)
        this.serviceRequestData.controls.business_name.setValue(data.data.business_name)
        this.serviceRequestData.controls.business_phone.setValue(data.data.business_phone)
        this.serviceRequestData.controls.car_id.setValue(data.data.car_id)
        this.serviceRequestData.controls.city.setValue(data.data.city)
        this.serviceRequestData.controls.color.setValue(data.data.color)
        this.serviceRequestData.controls.comments.setValue(data.data.comments)
        this.serviceRequestData.controls.company_name.setValue(data.data.company_name)
        this.serviceRequestData.controls.completion_date.setValue(data.data.completion_date)
        this.serviceRequestData.controls.confirmation_token.setValue(data.data.confirmation_token)
        this.serviceRequestData.controls.country.setValue(data.data.country)
        this.serviceRequestData.controls.customer_id.setValue(data.data.customer_id)
        this.serviceRequestData.controls.customer_type.setValue(data.data.customer_type)
        this.serviceRequestData.controls.disclaimer.setValue(data.data.disclaimer)
        this.serviceRequestData.controls.disclaimer_id.setValue(data.data.disclaimer_id)
        this.serviceRequestData.controls.email.setValue(data.data.email)
        this.serviceRequestData.controls.estimated_delivery_date.setValue(data.data.estimated_delivery_date)
        this.serviceRequestData.controls.feedback_code.setValue(data.data.feedback_code)
        this.serviceRequestData.controls.first_name.setValue(data.data.first_name)
        this.serviceRequestData.controls.grand_total.setValue(data.data.grand_total)
        this.serviceRequestData.controls.has_app_downloaded.setValue(data.data.has_app_downloaded)
        this.serviceRequestData.controls.home_phone.setValue(data.data.home_phone)
        this.serviceRequestData.controls.id.setValue(data.data.id)
        this.serviceRequestData.controls.inspection_reports.setValue(data.data.inspection_reports)
        this.serviceRequestData.controls.insurance_expiration_date.setValue(data.data.insurance_expiration_date)
        this.serviceRequestData.controls.is_DIY.setValue(data.data.is_DIY)
        this.serviceRequestData.controls.is_paid.setValue(data.data.is_paid)
        this.serviceRequestData.controls.is_supplies_charges_included.setValue(data.data.is_supplies_charges_included)
        this.serviceRequestData.controls.is_tax_included.setValue(data.data.is_tax_included)
        this.serviceRequestData.controls.is_usps_vehicle.setValue(data.data.is_usps_vehicle)
        this.serviceRequestData.controls.labor_amount.setValue(data.data.labor_amount)
        this.serviceRequestData.controls.labor_rate.setValue(data.data.labor_rate)
        this.serviceRequestData.controls.last_name.setValue(data.data.last_name)
        this.serviceRequestData.controls.license_plate.setValue(data.data.license_plate)
        this.serviceRequestData.controls.mail_sent.setValue(data.data.mail_sent)
        this.serviceRequestData.controls.make.setValue(data.data.make)
        this.serviceRequestData.controls.middle_name.setValue(data.data.middle_name)
        this.serviceRequestData.controls.mobile_phone.setValue(data.data.mobile_phone)
        this.serviceRequestData.controls.model.setValue(data.data.model)
        this.serviceRequestData.controls.modify_date.setValue(data.data.modify_date)
        this.serviceRequestData.controls.no_report_performed.setValue(data.data.no_report_performed)
        this.serviceRequestData.controls.non_taxable_amount.setValue(data.data.non_taxable_amount)
        this.serviceRequestData.controls.notes.setValue(data.data.notes)
        this.serviceRequestData.controls.notify_accept_reject_jobs_email.setValue(data.data.notify_accept_reject_jobs_email)
        this.serviceRequestData.controls.notify_accept_reject_jobs_push.setValue(data.data.notify_accept_reject_jobs_push)
        this.serviceRequestData.controls.notify_create_service_facility_car_email.setValue(data.data.notify_create_service_facility_car_email)
        this.serviceRequestData.controls.notify_create_service_facility_car_push.setValue(data.data.notify_create_service_facility_car_push)
        this.serviceRequestData.controls.notify_expiration_dates_email.setValue(data.data.notify_expiration_dates_email)
        this.serviceRequestData.controls.notify_expiration_dates_push.setValue(data.data.notify_expiration_dates_push)
        this.serviceRequestData.controls.notify_expiration_dates_web.setValue(data.data.notify_expiration_dates_web)
        this.serviceRequestData.controls.notify_feedback_email.setValue(data.data.notify_feedback_email)
        this.serviceRequestData.controls.notify_feedback_push.setValue(data.data.notify_feedback_push)
        this.serviceRequestData.controls.notify_make_changes_car_facility_service_request_email.setValue(data.data.notify_make_changes_car_facility_service_request_email)
        this.serviceRequestData.controls.notify_make_changes_car_facility_service_request_push.setValue(data.data.notify_make_changes_car_facility_service_request_push)
        this.serviceRequestData.controls.notify_send_updated_info_to_customer_email.setValue(data.data.notify_send_updated_info_to_customer_email)
        this.serviceRequestData.controls.notify_send_updated_info_to_customer_push.setValue(data.data.notify_send_updated_info_to_customer_push)
        this.serviceRequestData.controls.notify_service_completion_email.setValue(data.data.notify_service_completion_email)
        this.serviceRequestData.controls.notify_service_completion_push.setValue(data.data.notify_service_completion_push)
        this.serviceRequestData.controls.notify_to_admin.setValue(data.data.notify_to_admin)
        this.serviceRequestData.controls.notify_to_customer.setValue(data.data.notify_to_customer)
        this.serviceRequestData.controls.parts_amount.setValue(data.data.parts_amount)
        this.serviceRequestData.controls.password.setValue(data.data.password)
        this.serviceRequestData.controls.payment_date.setValue(data.data.payment_date)
        this.serviceRequestData.controls.payment_reminder_date.setValue(data.data.payment_reminder_date)
        this.serviceRequestData.controls.payment_reminder_status.setValue(data.data.payment_reminder_status)
        this.serviceRequestData.controls.present_mileage.setValue(data.data.present_mileage)
        this.serviceRequestData.controls.read_status.setValue(data.data.read_status)
        this.serviceRequestData.controls.recommendation_saved.setValue(data.data.recommendation_saved)
        this.serviceRequestData.controls.registration_expiration_date.setValue(data.data.registration_expiration_date)
        this.serviceRequestData.controls.request_documents.setValue(data.data.request_documents)
        this.serviceRequestData.controls.request_id.setValue(data.data.request_id)
        this.serviceRequestData.controls.request_images.setValue(data.data.request_images)
        this.serviceRequestData.controls.request_status.setValue(data.data.request_status)
        this.serviceRequestData.controls.requested_delivery_date.setValue(this.globalService.getFullFormateDate(data.data.requested_delivery_date,"G","B"))
        this.serviceRequestData.controls.reset_pwd_token.setValue(data.data.reset_pwd_token)
        this.serviceRequestData.controls.schedule_service_date.setValue(data.data.schedule_service_date)
        this.serviceRequestData.controls.service_facility.setValue(data.data.service_facility)
        this.serviceRequestData.controls.service_request_list.setValue(data.data.service_request_list)
        this.serviceRequestData.controls.shop_id.setValue(data.data.shop_id)
        this.serviceRequestData.controls.state.setValue(data.data.state)
        this.serviceRequestData.controls.status.setValue(data.data.status)
        this.serviceRequestData.controls.supplies_charges.setValue(data.data.supplies_charges)
        this.serviceRequestData.controls.suppliescharges.setValue(data.data.suppliescharges)
        this.serviceRequestData.controls.tax_amount.setValue(data.data.tax_amount)
        this.serviceRequestData.controls.tax_exempt_number.setValue(data.data.tax_exempt_number)
        this.serviceRequestData.controls.tax_exempted.setValue(data.data.tax_exempted)
        this.serviceRequestData.controls.tax_rate.setValue(data.data.tax_rate)
        this.serviceRequestData.controls.technician.setValue(data.data.technician)
        this.serviceRequestData.controls.title.setValue(data.data.title)
        this.serviceRequestData.controls.total_amount.setValue(data.data.total_amount)
        this.serviceRequestData.controls.total_paid.setValue(data.data.total_paid)
        this.serviceRequestData.controls.vin.setValue(data.data.vin)
        this.serviceRequestData.controls.year.setValue(data.data.year)
        this.serviceRequestData.controls.zip.setValue(data.data.zip);
        this.serviceRequestData.controls.service_facility_name.setValue(data.data.service_facility_name);
        // console.log(this.serviceRequestData)
        if (data.data.year =="") {
          this.vehicleName= "";
        } else {
          this.vehicleName= data.data.year ;
        }
        if(data.data.make != ""){
          this.vehicleName += (this.vehicleName ? "/" : "" ) + data.data.make;
        }
        if(data.data.model != ""){
          this.vehicleName += (this.vehicleName ? "/" : "" ) + data.data.model
        }
        this.vehicleName = (this.vehicleName ? this.vehicleName :"N/A");
        for(var i = 0;i<this.serviceRequestData.value.service_request_list.length;i++) {
          this.totalReturnedValue = this.getTotalPrice(this.serviceRequestData.value.service_request_list[i]);
          var returingObj = this.getAcceptAndRejectCalculationForPage(this.serviceRequestData.value.service_request_list[i],null)

          this.ObjectJson[this.serviceRequestData.value.service_request_list[i]["id"]]=
          (
            {
              //AcceptedValue:returingObj[0].toFixed(2),
              //RejectedValue:returingObj[1].toFixed(2)
            }
          )
        }
      } else {
        this.globalService.snackbar("error",  data.message);
      }
      console.log(this.ObjectJson)
      this.calculatefinalAmount (this.serviceRequestData.value.tax_rate,this.serviceRequestData.value.supplies_charges);
    });
  }
  setParams() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.idFromUrl = params["id"];
    });
  }
  ConvertToNumber(value) {
    return parseInt(value)
  }
  initializeForm(){
    this.serviceRequestData = this.formBuilder.group({
      add_date:[],
      address1:[],
      address2:[],
      balance_amount:[],
      business_name:[],
      business_phone:[],
      car_id:[],
      city:[],
      color:[],
      comments:[],
      company_name:[],
      completion_date:[],
      confirmation_token:[],
      country:[],
      customer_id:[],
      customer_type:[],
      disclaimer:[],
      disclaimer_id:[],
      email:[],
      estimated_delivery_date:[],
      feedback_code:[],
      first_name:[],
      grand_total:[],
      has_app_downloaded:[],
      home_phone:[],
      id:[],
      inspection_reports:[],
      insurance_expiration_date:[],
      is_DIY:[],
      is_paid:[],
      is_supplies_charges_included:[],
      is_tax_included:[],
      is_usps_vehicle:[],
      labor_amount:[],
      labor_rate:[],
      last_name:[],
      license_plate:[],
      mail_sent:[],
      make:[],
      middle_name:[],
      mobile_phone:[],
      model:[],
      modify_date:[],
      no_report_performed:[],
      non_taxable_amount:[],
      notes:[],
      notify_accept_reject_jobs_email:[],
      notify_accept_reject_jobs_push:[],
      notify_create_service_facility_car_email:[],
      notify_create_service_facility_car_push:[],
      notify_expiration_dates_email:[],
      notify_expiration_dates_push:[],
      notify_expiration_dates_web:[],
      notify_feedback_email:[],
      notify_feedback_push:[],
      notify_make_changes_car_facility_service_request_email:[],
      notify_make_changes_car_facility_service_request_push:[],
      notify_send_updated_info_to_customer_email:[],
      notify_send_updated_info_to_customer_push:[],
      notify_service_completion_email:[],
      notify_service_completion_push:[],
      notify_to_admin:[],
      notify_to_customer:[],
      parts_amount:[],
      password:[],
      payment_date:[],
      payment_reminder_date:[],
      payment_reminder_status:[],
      present_mileage:[],
      read_status:[],
      recommendation_saved:[],
      registration_expiration_date:[],
      request_documents:[],
      request_id:[],
      request_images:[],
      request_status:[],
      requested_delivery_date:[],
      reset_pwd_token:[],
      schedule_service_date:[],
      service_facility:[],
      service_request_list:[],
      shop_id:[],
      state:[],
      status:[],
      supplies_charges:[],
      suppliescharges:[],
      tax_amount:[],
      tax_exempt_number:[],
      tax_exempted:[],
      tax_rate:[],
      technician:[],
      title:[],
      total_amount:[],
      total_paid:[],
      vin:[],
      year:[],
      zip:[],
      service_facility_name:[]
    });
  }
  approveAll(){
    this.recIds = this.getRecommendationIds();
      // API_update_all_recommendation_statuses.php
    let content = "rec_ids=" + this.recIds + "&status=1";
      this.globalService.callAPI(globalConstants.API_UPDATE_ALL_RECOMMENDATION_STATUES, content).subscribe((data)=>{
        console.log("response",data)
      })
    }
  disapproveAll(){
    this.recIds = this.getRecommendationIds();
    let content = "rec_ids=" + this.recIds + "&status=2";
    this.globalService.callAPI(globalConstants.API_UPDATE_ALL_RECOMMENDATION_STATUES, content).subscribe((data)=>{
      console.log("response",data)
      })
  }
  getNltoBr(val){
    if(val && val.length>0){
      return val.toString().replace(/\r\n/g, "<br />").replace(/\n/g, "<br />");
    }
    return '-';
  }
  getRecommendationIds(){
    var recIds= []
    var service_request = this.serviceRequestData.value.service_request_list
    service_request.forEach(function(x) {
        // console.log(x.children);
        x.children.forEach(f => {
          if(f.children.length > 0) {
            f.children.forEach(function(w){
              console.log(w)
              console.log("3rd child recommendations",w.recommendations[0]);
              recIds.push(w.recommendations[0]["id"])
            })
          } else {
            console.log(f)
            console.log("recommendations= ",f.recommendations[0]);
            recIds.push(f.recommendations[0]["id"])
          }
        });
    })
    return recIds
  }
  getTotalPrice(object) {
    var totalPriceForGj = 0,totalPriceForParts = 0,rejectedJobCost = 0, nonTaxableAmount=0;
    var labourCost = 0;
    for(var i=0;i<object.children.length;i++) {
      if(object.children[i].children!=undefined && object.children[i].children.length > 0) {

        // do the level 2 jobs
        this.callToThirdLevelCalculations(object.children[i].children);

      } else {
        if(object.children[i].recommendations!=undefined && object.children[i].recommendations.length > 0) {

          for(var j=0;j<object.children[i].recommendations.length;j++) {
            labourCost = 0;
            var scopedNonTaxedValue = 0;
            labourCost = labourCost + parseFloat(object.children[i].recommendations[j].estimated_labor);

            // calculate price for parts
            for(var k=0;k<object.children[i].recommendations[j].parts.length;k++) {
              if(object.children[i].recommendations[j].parts.length > 0) {
                totalPriceForParts = parseFloat(totalPriceForParts.toString()) + (object.children[i].recommendations[j].parts[k].part_price * object.children[i].recommendations[j].parts[k].quantity);
                if(object.children[i].recommendations[j].parts[k].non_taxable == "1" && object.children[i].recommendations[j].approval_status !="2") {
                  scopedNonTaxedValue = scopedNonTaxedValue  + (object.children[i].recommendations[j].parts[k].part_price * object.children[i].recommendations[j].parts[k].quantity);

                }
              }
            }

            // calculate cost for generic jobs
            for(var k=0;k<object.children[i].recommendations[j].generic_jobs.length;k++) {
              totalPriceForGj = totalPriceForGj + (object.children[i].recommendations[j].generic_jobs[k].price * object.children[i].recommendations[j].generic_jobs[k].quantity);
              if(object.children[i].recommendations[j].generic_jobs[k].non_taxable == "1" && object.children[i].recommendations[j].approval_status !="2") {
                scopedNonTaxedValue = scopedNonTaxedValue  + (object.children[i].recommendations[j].generic_jobs[k].price * object.children[i].recommendations[j].generic_jobs[k].quantity);
              }
            }
            this.nonTaxableValue.push(scopedNonTaxedValue);
            // console.log("this.nonTaxableValue",  this.nonTaxableValue)
            var totalAmount = parseFloat(totalPriceForParts.toString()) + parseFloat(totalPriceForGj.toString()) + parseFloat(labourCost.toString());
            totalAmount = parseFloat(totalAmount.toString());
            if(object.children[i].recommendations[j].approval_status == "2") {
              this.rejectedJobCalculation.push(totalAmount);
              this.nonTaxableValue.splice(this.nonTaxableValue.length-1,1)
            } else {
              this.totalAcceptedCalculation.push(totalAmount);
            }
            //console.log("totalAmount",totalAmount)
            totalAmount = 0;
            totalPriceForParts = 0;
            totalPriceForGj = 0;
          }
          // console.log("accepted",)
        }
      }
    }

    return [this.totalAcceptedCalculation,this.rejectedJobCalculation]
  }
  calculatefinalAmount(tax_rate,supplies_charges) {

    var acceptedValue = this.addArray(this.totalAcceptedCalculation);

    var totalNonTaxedValue = this.addArray(this.nonTaxableValue);
    // console.log("final nontaxable value "+totalNonTaxedValue);
    var taxableValue = acceptedValue - totalNonTaxedValue;
    // console.log("taxableValue",taxableValue)
    var finalValue;
    var totalCalculatedAmount

    if(this.serviceRequestData.value.request_status == "6" || this.serviceRequestData.value.request_status == "8") {
      finalValue = this.serviceRequestData.value.total_amount
    } else if(this.serviceRequestData.value.request_status == "3" ||  this.serviceRequestData.value.request_status == "4" || this.serviceRequestData.value.request_status == "5" || this.serviceRequestData.value.request_status == "7" || this.serviceRequestData.value.request_status == "12" ){
      if(this.serviceRequestData.value.is_tax_included  == "1") {
        totalCalculatedAmount = acceptedValue + (taxableValue * (parseFloat(tax_rate)/100))
      } else {
        totalCalculatedAmount = acceptedValue;
      }

      if(this.serviceRequestData.value.is_supplies_charges_included == "1") {
        finalValue = totalCalculatedAmount + parseFloat(supplies_charges);
      } else  {
        finalValue = totalCalculatedAmount
      }
    } else {
      finalValue = this.serviceRequestData.value.total_amount
    }
    //
    // console.log("final totalCalculatedAmount value "+totalCalculatedAmount);
    // console.log("final value "+finalValue);
    if(finalValue!=null) {
      this.finalShowAbleAmount = parseFloat(finalValue).toFixed(2)
      // return finalValue.toFixed(2)
    }
  }
  callToThirdLevelCalculations(secondLevelChildren) {

    var totalPriceForGj = 0,totalPriceForParts = 0,rejectedJobCost = 0;
    var labourCost = 0;

    for(var i=0;i<secondLevelChildren.length;i++) {
      if(secondLevelChildren[i].recommendations.length > 0) {
        for(var j=0;j<secondLevelChildren[i].recommendations.length;j++) {
          var tobeDeducted = 0;
          var scopedNonTaxedValue = 0;
          totalPriceForGj = 0;
          totalPriceForParts = 0;
          rejectedJobCost = 0;

          for(var k=0;k<secondLevelChildren[i].recommendations[j].parts.length;k++) {
            totalPriceForParts = totalPriceForParts + (secondLevelChildren[i].recommendations[j].parts[k].part_price * secondLevelChildren[i].recommendations[j].parts[k].quantity);
            if(secondLevelChildren[i].recommendations[j].parts[k].non_taxable == "1" && secondLevelChildren[i].recommendations[j].approval_status !="2") {
              scopedNonTaxedValue = scopedNonTaxedValue + (secondLevelChildren[i].recommendations[j].parts[k].part_price * secondLevelChildren[i].recommendations[j].parts[k].quantity)
            }
          }

          for(var k=0;k<secondLevelChildren[i].recommendations[j].generic_jobs.length;k++) {
            totalPriceForGj = totalPriceForGj + (secondLevelChildren[i].recommendations[j].generic_jobs[k].price * secondLevelChildren[i].recommendations[j].generic_jobs[k].quantity);
            if(secondLevelChildren[i].recommendations[j].generic_jobs[k].non_taxable == "1" && secondLevelChildren[i].recommendations[j].approval_status !="2") {
              scopedNonTaxedValue = scopedNonTaxedValue + (secondLevelChildren[i].recommendations[j].generic_jobs[k].price * secondLevelChildren[i].recommendations[j].generic_jobs[k].quantity);
            }
          }

          this.nonTaxableValue.push(scopedNonTaxedValue);
          labourCost = labourCost + parseFloat(secondLevelChildren[i].recommendations[j].estimated_labor);

          var totalAmount = parseFloat(totalPriceForParts.toString()) + parseFloat(totalPriceForGj.toString()) + parseFloat(labourCost.toString()) - parseInt(tobeDeducted.toString());
          totalAmount = parseFloat(totalAmount.toString());

          if(secondLevelChildren[i].recommendations[j].approval_status == "2") {
            this.nonTaxableValue.splice(this.nonTaxableValue.length-1,1)
            this.rejectedJobCalculation.push(totalAmount)
          } else {
            this.totalAcceptedCalculation.push(totalAmount);
          }
          tobeDeducted = 0;
          totalAmount = 0;
          totalPriceForParts = 0;
          totalPriceForGj = 0;
          labourCost = 0
        }
      } else {
      }
    }
  }
  addArray(array) {
    var sum = 0;
    for(var i=0;i<array.length;i++) {
      sum = sum + array[i]
    }
    return sum
  }


  updateStatusVehicle(recId,recommendation,service_request, status,level1Id){
    let content = "rec_id=" + recId + "&status="+status+"&level1Id="+level1Id+"&recommendation="+JSON.stringify(recommendation)+"&service_request="+JSON.stringify(service_request);
    this.globalService.callAPI(globalConstants.API_UPDATE_RECOMMENDATION_STATUS_URL, content).subscribe((data:any)=>{
      // console.log("data", data);
      if(data.result == "1"){
        this.globalService.snackbar("success", data.message);
        console.log("new grand total=",data.grand_total)
         //this.ObjectJson[service_request.id]["AcceptedValue"] = data.accepted_total.toFixed(2)
         //this.ObjectJson[service_request.id]["RejectedValue"] = data.rejected_total.toFixed(2)
         this.finalShowAbleAmount = data.grand_total;
      }else{
        this.globalService.snackbar("error", data.message);
      }
    });
  }
  finalUpdateToNotifyAdmin(request_id){
    this.globalService.callAPI(globalConstants.API_UPDATE_NOTIFY_TO_ADMIN_URL,"request_id="+request_id).subscribe((response)=>{
      //console.log(response)
    })
  }
  calculateAmountForRecommendation(recommendation) {
    // console.log("recommendation",recommendation);
    var labourCharges = recommendation.estimated_labor;
    var scopedNonTaxedValue = 0,totalPriceForGj = 0,totalPriceForParts = 0;

    for(var k=0;k<recommendation.parts.length;k++) {
      // console.log("Parts",recommendation.parts[k]);
      totalPriceForParts = totalPriceForParts + (recommendation.generic_jobs[k].price *  recommendation.generic_jobs[k].quantity);
      if(recommendation.parts[k].non_taxable == "1" && recommendation.approval_status !="2") {
        scopedNonTaxedValue = scopedNonTaxedValue + (recommendation.parts[k].price * recommendation.parts[k].quantity);
      }
    }

    for(var k=0;k<recommendation.generic_jobs.length;k++) {
      // console.log("Generic Jobs",recommendation.generic_jobs[k]);
      totalPriceForGj = totalPriceForGj + (recommendation.generic_jobs[k].price * recommendation.generic_jobs[k].quantity);
      if(recommendation.generic_jobs[k].non_taxable == "1" && recommendation.approval_status !="2") {
        scopedNonTaxedValue = scopedNonTaxedValue + (recommendation.generic_jobs[k].price * recommendation.generic_jobs[k].quantity);
      }
    }

    var totalAmount = parseInt(totalPriceForGj.toString()) + parseInt(totalPriceForParts.toString()) + parseInt(labourCharges.toString());
    return totalAmount
  }
  // updateText($event, recommendation.id, recommendation,service_request)
  updateText(event, rec_id, recommendation,service_request){
    let textChange = <HTMLElement> document.getElementById(event.source.id).childNodes[0].childNodes[1];
    //console.log(this.ObjectJson[service_request.id]);

    if(event.value == 'accept'){

      if(event.source.checked){
        textChange.innerText = 'Accept';
        this.updateStatusVehicle(rec_id,recommendation,service_request,0,service_request.id);
      }else{
        event.value = 'Undo Accept';
        textChange.innerText = 'Undo Accept';
        this.updateStatusVehicle(rec_id,recommendation,service_request,1,service_request.id);
      }
    }else{
      if(event.source.checked){
        this.statusRejectText = 'Reject';
        textChange.innerText = 'Reject';
        this.updateStatusVehicle(rec_id,recommendation,service_request,0,service_request.id);
      }else{
        this.statusRejectText = 'Undo Reject';
        textChange.innerText = 'Undo Reject';
        this.updateStatusVehicle(rec_id,recommendation,service_request,2,service_request.id);
      }
    }
  }
  expansionOpen(ele) {
    var target= '#expansionHead-'+ ele;
    var eleState = $(target).data('state');
    $(".new-request-expension").find(".mat-expansion-panel-header").data("state",false);
    if(!eleState){
      setTimeout(() => {
         let element = document.getElementById('expansionHead-' + ele);
         var target= '#expansionHead-'+ ele;
         setTimeout(() => {
           var body = $("html, body");
           body.animate({
                  scrollTop: jQuery(target).offset().top - 90
            }, 500);
         }, 500);
         $(target).data('state',true);
      }, 200);
    }
    }

  getAcceptAndRejectCalculationForPage(serviceRequest,returningStatus){
//    var total = 0;
//    var rejected = 0;
//    var approved = 0;
//    var finalCalculation = 0;
//    serviceRequest.children.forEach(l2child => {
//
//      if(l2child.recommendations.length>0) {
//        total = 0;
//        var l2childRecommendation = l2child.recommendations[0];
//        var approval_status = l2childRecommendation.approval_status;
//
//        // parts calculation for l2
//
//        if(l2childRecommendation.parts.length > 0) {
//          l2childRecommendation.parts.forEach((part)=>{
//            total = total + (parseFloat(part.part_price) * parseFloat(part.quantity))
//          })
//        }
//
//        // GJ calculation for l2
//
//        if(l2childRecommendation.generic_jobs.length > 0) {
//          l2childRecommendation.generic_jobs.forEach(gj => {
//            total = total + (parseFloat(gj.price) * parseFloat(gj.quantity))
//          });
//        }
//        total = total + parseFloat(l2childRecommendation.estimated_labor);
//        finalCalculation = finalCalculation + total
//        if(approval_status == "2") {
//          rejected = rejected + total;
//        } else {
//          approved = approved + total;
//        }
//
//      }
//      else {
//        l2child.children.forEach(l3child=>{
//          total = 0;
//          var l3childRecommendation = l3child.recommendations[0];
//          var approval_status = l3childRecommendation.approval_status;
//
//          if(l3childRecommendation.parts.length > 0) {
//            l3childRecommendation.parts.forEach((part)=>{
//              total = total + (parseFloat(part.part_price) * parseFloat(part.quantity))
//            })
//          }
//          if(l3childRecommendation.generic_jobs.length > 0) {
//            l3childRecommendation.generic_jobs.forEach(gj => {
//              total = total + (parseFloat(gj.price) * parseFloat(gj.quantity))
//            });
//          }
//          total = total + parseFloat(l3childRecommendation.estimated_labor);
//
//          finalCalculation = finalCalculation + total
//          if(approval_status == "2") {
//            rejected = rejected + total;
//          } else {
//            approved = approved + total;
//          }
//        });
//      }
//    });
//
//    if(returningStatus == null ){
//      return [approved,rejected]
//    }
  }
   /* image rotation dialog  */
   imageToolDialog(current,i,type,imgaar): void {
    let dialogRef = this.dialog.open(ImagetooldialogComponent, {
        panelClass: ['imageToolDialog'],
        width: "100%",
        data: {event: current,index:i,imgType:type,imgArray:imgaar,customerId:this.globalService.getCurrentCustomer()["id"]}
    });

    dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
    });
}
getInvoicePdf(){
  return globalConstants.S3_BUCKET_URL+'service-invoices/'+ this.serviceRequestData.value.request_id+'.pdf';
}
}
