import { Component, Inject, ViewEncapsulation, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { AddLabelDialogComponent } from '../../shopservicerequest/add-label-dialog/add-label-dialog.component'

@Component({
  selector: 'app-add-labels-dialog',
  templateUrl: './add-labels-dialog.component.html',
  styleUrls: ['./add-labels-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddLabelsDialogComponent implements OnInit {
  public currentUser: any;
  public allLabels: any;
  public preSelectLabel: any = [];
  public serviceRequestId: any;
  public fullRequestId: any;
  public serviceReqData:any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalService: GlobalService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddLabelsDialogComponent>
  ) {
    this.serviceReqData = data.serviceReqInfo;
    this.serviceRequestId = this.serviceReqData.id;
    this.fullRequestId = this.serviceReqData.request_id;
  }

  ngOnInit(): void {
    this.currentUser = this.globalService.getCurrentUser();
    this.getSRLabelsData();
  }

  public getDefaultServiceRequestLabel() {
    this.serviceReqData.labels.forEach(element => {
      let labelId = element.label_id;
      this.preSelectLabel.push(labelId);
    });
  }
  getSRLabelsData() {
    let options = {
      user_id: this.currentUser.id,
      user_type: 'User'
    };
    this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUEST_LABELS_MASTER, options).subscribe((res: any) => {
      if (res.result == 1) {
        let customLabels = [
          {id: 'OTL', shop_id: '', label_name: 'OTL', add_date: '', modify_date: ''},
          {id: 'WAITING_FOR_PARTS', shop_id: '', label_name: 'Waiting For Parts', add_date: '', modify_date: ''}
        ];
        if(this.serviceReqData.status=='6' || this.serviceReqData.status=='8'){
          customLabels.push(
            {id: 'ACCOUNTANT_VERIFIED', shop_id: '', label_name: 'Accountant Verified', add_date: '', modify_date: ''}
          )
      }
        this.allLabels = customLabels.concat(res.data);
        this.allLabels = this.globalService.sortLabelsListInAlphabeticallyOrder(this.allLabels);
      }
      this.getDefaultServiceRequestLabel();
    })
  }

  selectedLabel(label: any) {
    if (label == 'OTL' || label == 'WAITING_FOR_PARTS' || label == 'ACCOUNTANT_VERIFIED') {
      this.updateCustomLabel(label,true);
    }else{
      let label_id: number = label;
      this.globalService.callAPI(globalConstants.API_UPDATE_SERVICE_REQUEST_LABELS, "request_id=" + this.serviceRequestId + "&user_id=" + this.globalService.getCurrentUser()["id"] + "&label_id=" + label_id + "&user_type=User").subscribe((response:any) => {
        if (response.result == 1) {
          this.serviceReqData.labels = response.data.labels;
        }else{
            this.globalService.snackbar("error",response.message);
        }
      })
    } 
  }

  closeDialog(){
    this.dialogRef.close({SRData:this.serviceReqData});
  }

  removeLabel(label: any) {
    if (label == 'OTL' || label == 'WAITING_FOR_PARTS' || label == 'ACCOUNTANT_VERIFIED') {
      this.updateCustomLabel(label,false);
    }else{
      let label_id: number = label;
      this.globalService.callAPI(globalConstants.API_DELETE_SERVICE_REQUEST_LABEL, "request_id=" + this.serviceRequestId + "&user_id=" + this.globalService.getCurrentUser()["id"] + "&label_id=" + label_id + "&user_type=User").subscribe((response:any) => {
        if (response.result == 1) {
          this.serviceReqData.labels = response.data.labels;
        }else{
          this.globalService.snackbar("error",response.message);
        }
      })
    }
  }

  updateCustomLabel(labelType,selectStatus:any){
      let obj ={
        checked: selectStatus
      };
      if (labelType == 'OTL') {
        this.updateOnTheLotStatus(obj);
      }else if(labelType == 'WAITING_FOR_PARTS'){
        this.updateWaitingForPartStatus(obj);
      }else if(labelType == 'ACCOUNTANT_VERIFIED'){
        this.updateAccountVerifyStatus(obj);
      }
  }

  updateOnTheLotStatus(value) {
    let checked: any = value.checked == true ? 1:0;
    this.globalService.callAPI(globalConstants.API_UPDATE_ON_THE_LOT_STATUS, "request_id=" + this.serviceRequestId + "&user_id=" + this.globalService.getCurrentUser()["id"] + "&on_the_lot=" + checked + "&user_type=User").subscribe((response: any) => {
      if (response.result == 1) {
        if (checked == 1) {
           this.serviceReqData.on_the_lot = "1"; 
        } else {
          this.removeCustomLabelInSelectLabelAndPreSelectLabel('OTL');
          this.serviceReqData.on_the_lot = "0";
        }
        this.globalService.snackbar("success", response.message);
      }
    })
  }

  updateWaitingForPartStatus(value){
    let checked: any = value.checked == true ? 1:0;
    let data={request_id:this.serviceRequestId,user_id:this.globalService.getCurrentUser()["id"],user_type:this.globalService.getCurrentUser()["user_type"],parts_awaited:checked}
    this.globalService.callAPI(globalConstants.API_UPDATE_PARTS_WAITING_STATUS,data).subscribe((res:any)=>{
      if(res.result==1){
        if(checked == 1){
          this.serviceReqData.parts_awaited = "1";
      }else{
        this.removeCustomLabelInSelectLabelAndPreSelectLabel('WAITING_FOR_PARTS');
        this.serviceReqData.parts_awaited = "0";
      } 
        this.globalService.snackbar("success", res.message);
      }else{
        this.globalService.snackbar("error", res.message);
      }
    },(error)=>{})
  }

  updateAccountVerifyStatus(value){
    let checked: any = value.checked == true ? 1:0;
    let data={request_id:this.serviceRequestId,user_id:this.globalService.getCurrentUser()["id"],user_type:this.globalService.getCurrentUser()["user_type"],account_verify:checked}
    this.globalService.callAPI(globalConstants.API_UPDATE_ACCOUNT_VERIFY_STATUS,data).subscribe((res:any)=>{
      if(res.result==1){
        if(checked == 1){ 
          this.serviceReqData.accountant_verification = "1";
      }else{
        this.removeCustomLabelInSelectLabelAndPreSelectLabel('ACCOUNTANT_VERIFIED');
        this.serviceReqData.accountant_verification = "0";
      }
        this.globalService.snackbar("success", res.message);
      }else{
        this.globalService.snackbar("error", res.message);
      }
    },(error)=>{})
  }

  removeCustomLabelInSelectLabelAndPreSelectLabel(labelType: any) {
    let i = this.preSelectLabel.findIndex(label => label == labelType);
    if(i >=0){
    this.preSelectLabel.splice(i, 1);
    }
    let idx = this.serviceReqData.labels.findIndex(label => label.label_id == labelType);
    if (idx >= 0) {
      this.serviceReqData.labels.splice(idx, 1);
    }
  }


  manageLabels() {
    let dialogRef = this.dialog.open(AddLabelDialogComponent, {
      panelClass: 'partstech-order-dialog',
      width: '800px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getSRLabelsData();
    });
  }
}
