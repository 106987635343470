import {Component, ViewChild, ChangeDetectorRef, HostListener, OnInit, ViewEncapsulation,ChangeDetectionStrategy, Sanitizer, SecurityContext, TemplateRef,Inject} from '@angular/core';
import {Router, ActivatedRoute, ParamMap, NavigationEnd, RoutesRecognized} from '@angular/router';
import {Title,DomSanitizer} from '@angular/platform-browser';
// import {MatSidenav, MatDialog} from '@angular/material';
import { MatSidenav} from '@angular/material/sidenav';
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
// import {NgProgress, NgProgressModule} from 'ngx-progressbar';
import {Location, LocationStrategy, PathLocationStrategy,} from '@angular/common';
import {​GlobalService, ModalInfo}​ from "../environments/global.service";
import {GoogleAdsComponent} from "../app/google-ads/google-ads.component"
import { DragulaService } from 'ng2-dragula';
import {globalConstants, GlobalVariable} from '../environments/globalConstants'
import {globalFunctions} from "../environments/globalFunctions"
import * as jQuery from 'jquery';
import { MessagingService } from './messaging.service';
import { Observable,Subject,Subscription,interval } from 'rxjs';
import { BsModalService, BsModalRef,ModalDirective } from 'ngx-bootstrap/modal';
import { QuickquoteComponent } from './shop/quickquote/quickquote.component';
// import { CustomLoader } from './customloader';
import {​ ServiceHistoryComponent }​ from '../app/shop/shopservicerequest/service-history/service-history.component';
import { QuickapptComponent } from './shop/quickappt/quickappt.component';
import { AddleaveComponent } from './shop/leave/addleave/addleave.component';
import { DOCUMENT } from '@angular/common';
import 'rxjs/add/operator/filter';
import { filter, catchError } from 'rxjs/operators';
import {LoaderService} from './base/services';
import {NgProgress, NgProgressComponent, NgProgressConfig} from 'ngx-progressbar';
import { CustomerLiveChatComponent } from './shop/customer-live-chat/customer-live-chat.component';
import { getMessaging, onMessage } from "firebase/messaging";
import { CCAuthorizationComponent } from './shop/cc-auth/cc-authorization/cc-authorization.component';
import {Socket} from 'ngx-socket-io';
import {environment} from 'src/environments/environment';
import {HelperService} from './helper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  @ViewChild(ModalDirective) vmodal: ModalDirective;
  public modalRef: BsModalRef;
  modalsListOb:Observable<Array<ModalInfo>>;
  modalsLength:number = 0; 
  modalsList:Array<ModalInfo> = [];
  modalsGroupedList:Array<{​module:string, items:Array<ModalInfo>}​>=[];
  title = 'CAR-V2';
  loginPage = false;
  registrationPage = false;
  currentUserName = "";
  currentCustomerName = "";
  currentAdminName = "";
  innerWidth: number;
  @ViewChild('loaderModal', { static: false,}) loaderModal?: ModalDirective;
  @ViewChild('sidenav, sidenav1,sidenav2') sidenav: MatSidenav;
  public navMode: any;
  public navState: boolean;
  public navHeaderClose: boolean;
  public menuState: boolean;
  public touchViewState: boolean;
  public dragStatusVal: boolean = false;
  public globalVariable;
  options: any = {
    removeOnSpill: false
  }
  public showCommonHeader=false;
  public shopAdsExpStatus: boolean = false;
  public shopAdsSubsStatus: boolean;
  public taxSettingStatus: boolean;
  public dragShow: boolean;
  public subscribedStatus: any;
  public dragOption: any;
  public menuForShop :any;
  public enbaleDragula :any;
  public dragulaModel = {start: function () {}};
  public sessionCheck = true;
  public isMenuLoaded = false;
  public showNoNLoggedInRoute=false;
  public mail_sent=false;
  public showShopView :any;
  public showCustomerView :any;
  public showAdminView :any;
  showOptions:boolean;
  public customerAdsDisplay=true;
  public allowedUrl = [
    "https://192.168.1.8:4200/",
    "https://beta.completeautoreports.com",
    "https://beta.completeautoreports.com/",
    "http://localhost:4200/",
    "http://52.27.231.255",
    "52.27.231.255",
    "http://52.27.231.255/",
    "52.27.231.255/",
    "https://app.completeautoreports.com",
    "https://app.completeautoreports.com/",
    "http://app.completeautoreports.com",
    "http://192.168.1.7:4200",
    "customer/login",
    "shops/register",
    "https://car-shop-776b5.web.app/",
    "car-shop-776b5.web.app/",
    "https://devshop.completeautoreports.com/",
    "https://devshop.completeautoreports.com",
    "devshop.completeautoreports.com",
    "https://shops.completeautoreports.com/",
    "https://shops.completeautoreports.com",
    "shops.completeautoreports.com",
  ];
  public roles:any=[];
  public currentUser:any={};
  public previousUrl:String = '';
  public notifications=[];
  public notifyCount=0;
  public allRead=false;
  public showLoader=true;
  public cmsContents :any;
  public eLearn:any =[];
  public iframeUrl:any='';
  public showMinButton=true;
  public enableELearning=false;
  public minidragTitle=''
  public minidragIcon='car2.jpg'
  public srnotelist=false
  public cprDate=(new Date()).getFullYear()
  public nonFleetmenu=false
  public aclLodded=false;
  private modalsIds:Array<any> = [];
  public currentUrl: string;
  gc:any = globalConstants;
  public disablePopup = false;
  public training_video_popup = 0;
  public training_video_icon = '0';
  // isIncludes:any
  loginUserInfo: any;
  public BAG: string = 'dragabbleOptions';
  public isLoading: Subject<boolean>;
  public globalENVStatus : any;
  public workFlowPermission : boolean = false;
  public activeModals;
  public globalBadgeCount:number = 0;
  public quickSRCreatePermission : boolean = false;
  public chatModalRef: MatDialogRef<CustomerLiveChatComponent>;
  public subscriptionDataForAPI: Subscription = new Subscription();
  public audio: HTMLAudioElement; 
  public shopLogo : any;
  public showLogo: boolean = false;
  constructor(
    public sanitiz:DomSanitizer,
    private modalService: BsModalService,
    private msgService: MessagingService,
    public url:LocationStrategy,
    public location: Location, 
    // public progressbar: NgProgress, 
    public route: ActivatedRoute, 
    public router: Router,
    public titleService: Title, 
    public cdr: ChangeDetectorRef, 
    public globalservice: GlobalService, 
    public dragulaService: DragulaService,
    public sanitizer : Sanitizer,
    public dialog: MatDialog, 
    private loaderService: LoaderService,
    public progress: NgProgress,
    private socket: Socket,
    private helperService:HelperService,
    
    @Inject(DOCUMENT) private document: Document
    )
   
    
     {
      this.globalVariable = GlobalVariable;
      jQuery("#hfc-frame").hide();
      this.enableELearning=globalConstants.e_learning;
      if (!this.isMenuLoaded) {
        this.getMenuForShops();
        this.isMenuLoaded = true;
      }
      if (location.path().split("/")[1] == "customer" && !this.isMenuLoaded) {
        this.getCutomerDetail();
        this.isMenuLoaded = true;
      }
      var tsnString = <HTMLElement>this.document.getElementById("main-loader");
      tsnString.style.opacity ='0';
      tsnString.style.zIndex = '0';
     
       this.loaderService.isLoading.subscribe((listener)=>{
        if(listener){
          this.loaderModal?.show()
        } else {
          this.loaderModal?.hide()   
        }
      })

        this.getCurrentCustomerSubscription();
        this.router.events.subscribe((event) => {
        var localData:any  = localStorage.getItem("loggedInUser");
        var localDataAdmin:any = localStorage.getItem("loggedInAdmin");
        var localDataCustomer :any= localStorage.getItem("loggedInCustomer");

        if (localDataCustomer != null && localDataCustomer != undefined && localDataCustomer != "") {
       
          this.currentCustomerName = JSON.parse(localDataCustomer).first_name;
        }

        if (localData != null && localData != undefined && localData != "") {
          this.currentUserName = JSON.parse(localData).first_name;
        }

        if (localDataAdmin != null && localDataAdmin != undefined && localDataAdmin != "") {
          this.currentAdminName = JSON.parse(localDataAdmin).username;
        }

        if (event instanceof NavigationEnd) {
          if (event.url == "/shops/register" || event.url == "/customer/register" || event.url == "/confirm-signup" || event.url == "/contact-us" || event.url == "/customer/forgot_password" || event.url == "/page-not-found") {
            this.navHeaderClose = true;
          }
          let vUrl=event.url.split('?');
          if(vUrl[0] == "/confirm-signup" || vUrl[0] == "/shops-confirm-signup" ||  vUrl[0].split('/')[1] == "shops-customer-approval"){
            this.navHeaderClose = true;
          }
          if(event.url.indexOf("customer/login") != -1 ){
            this.loginPage = true;
            this.navHeaderClose = true;
            this.showNoNLoggedInRoute = true;
          }
        }

        
        if (event instanceof NavigationEnd) {
          if (event.url == "/shops/forgot_password") {
            this.navHeaderClose = true;
          }
          this.currentUrl=event.url
        }

        if (event instanceof NavigationEnd) {
          if (event.url.toString().split("?")[0] == "/shops/reset-password") {
            this.navHeaderClose = true;
          }
        }

        if (event instanceof NavigationEnd) {
          if (event.url.toString().split("?")[0] == "/customer/reset-password") {
            this.navHeaderClose = true;
          }
        }
        /* END */

        if (event instanceof NavigationEnd) {
          if (event.url.toString().split("/")[1] == "shops") {
            this.getCurrentShopSubscription();
            this.dragStatusVal=false;
          }
          if (localStorage.getItem("loggedInUser") != null && localStorage.getItem("loggedInUser") != undefined && event.url.toString().split("/")[1] == "shops" && (event.url.toString().split("/")[2] && !['subscription'].includes(event.url.toString().split("/")[2]))) {
            let userDetails = JSON.parse(localStorage.getItem("loggedInUser"));
            var payload = "user_id=" + this.globalservice.getCurrentUser()["id"] + "&user_type=" + this.globalservice.getCurrentUser()["user_type"] + "&shop_id=" + this.globalservice.getCurrentUser()["shop_id"];
            this.subscriptionDataForAPI = this.globalservice.callAPI(globalConstants.API_GET_SHOP_DETAILS_ON_DASHBOARD, payload).subscribe((res: any) => {
              if (res.result == "1") {
                let shopDetails = res.data;
                this.globalservice.setShopDetails(res.data);
                if (!this.shopAdsExpStatus && userDetails.role_id == 1 && shopDetails && (shopDetails.eulaAgreement == 0 || shopDetails.cc_authorisation == 0)) {
                  this.ccAuthEULA();
                }
              }
            })
          } else {
            this.dialog.closeAll();
          }
        }
        

        if (event instanceof NavigationEnd) {
          
          var splitForResetPassword = event.url.toString().split('?')[0]
          
          if(splitForResetPassword == "/shops/reset-password") {
            localStorage.setItem("reset-password-data",JSON.stringify(this.route.snapshot.queryParams))
          }
         

          if(event.url == '/contact-us' || event.url == '/shops' || event.url == '/shops/reset_password' || splitForResetPassword == "/customer/reset-password" || splitForResetPassword == "/shops/reset-password" || event.url == '/shops/forgot_password' || event.url == '/customer' || event.url == '/admin' || event.url == '/customer/register' || event.url == '/customer/forgot_password' || event.url == '/page-not-found') {
            if(this.showNoNLoggedInRoute == false) { this.showNoNLoggedInRoute = true;
              this.router.navigateByUrl("/pages?next="+event.url,{skipLocationChange:true})
            }
          }
          if(event.url.includes('/shops/register')){
            this.showCommonHeader = true;
            this.showNoNLoggedInRoute = true;
          }
          let vUrl=event.url.split('?');
          if(vUrl[0] == "/confirm-signup"){
            this.showNoNLoggedInRoute = true;
            localStorage.setItem("signup-confirm-data",JSON.stringify(this.route.snapshot.queryParams))
            this.router.navigateByUrl("/pages?next="+vUrl[0],{skipLocationChange:true})
          }else if( vUrl[0] == "/shops-confirm-signup" ){
            this.showCommonHeader = true;
            this.showNoNLoggedInRoute = true;
            this.globalservice.getSetItems("shop-confirm-data",JSON.stringify(this.route.snapshot.queryParams))
          }else if( vUrl[0].split('/')[1] == "shops-customer-approval" ){
            this.showCommonHeader = true;
            this.showNoNLoggedInRoute = true;
            this.globalservice.getSetItems("shops-customer-approval",JSON.stringify(this.route.snapshot.queryParams))
          }
        }
      });

      if (this.isIncludes(this.allowedUrl,window.location.href)) {
         this.router.navigate(["/shops"])
      }

     this.dragulaService.createGroup(this.BAG, {
      moves: (el: any, container: any, handle: any) => {
        return this.dragStatusVal;
      },
      accepts: (el, target, source, sibling) => {
        return this.dragStatusVal;
      },
    });

      this.dragulaService.drop(this.BAG).subscribe((value:any) => {
        var parent:HTMLElement =  <HTMLElement>document.getElementById("items")
        var children:HTMLCollection =  parent!.children;
        var sendingObj:string = "";
        for (var i = 0; i < children.length -1; i++) {
          sendingObj = sendingObj + "menus_order[" + (i - 0) + "]=" + children[i]["id"] + "&"
         }
        var content = "shop_id=" + this.getCurrentLoggedInShop()["shop_id"]+"&user_id="+this.getCurrentLoggedInShop()["id"]+"&"+sendingObj;
        this.subscriptionDataForAPI = this.globalservice.callAPI(globalConstants.API_SAVE_MENU_ORDERING_URL, content).subscribe((response:any) => {
        },(error:any)=>{})
      });

      router.events.pipe(
      filter((event:any) => event instanceof NavigationEnd))
      .subscribe((e:any) => {
        if(e["urlAfterRedirects"] == "/page-not-found") {
          this.location.go(e["url"])
        }
      });
      
      window.addEventListener("storage", this.storageEventListener.bind(this));
      this.router.events.pipe(
      filter((e:any) => e instanceof RoutesRecognized))
      .subscribe((event: any) => {
        this.previousUrl = event.urlAfterRedirects;
      });
      
      this.modalsListOb = globalservice.modalsInfo;
      this.modalsListOb.subscribe((val:any)=>{​
         this.modalsList.length = 0;
         this.modalsGroupedList.length = 0;
        this.activeModals =val;
         this.modalsLength = Object.keys(val).length;
         for(let x in val){​
          this.modalsList.push(val[x]); 
         }​
         let allModules :any = [];
         this.modalsList.forEach((m)=>{​
           if(allModules.indexOf(m.module) == -1){​
            allModules.push(m.module);
           }​
         }​);
         allModules.forEach((mod:any)=>{​
          
         
          var itemInfo = this.modalsList.filter((m)=>{​
            return m.module == mod
           }​);
             let obj = {​
             module:mod,
            items: itemInfo
           }
           this.modalsGroupedList.push(obj);
         }​);
      }​);
  }

  async updateSocketId (token,socketId) {
    try {
      this.subscriptionDataForAPI =  this.globalservice.callAPI(`${environment.NODE_CHAT_SERVER_URL}/api/users/update_socket_id`,{
        token:token,
        socketId:socketId
      }).subscribe((response)=>{
        // this.globalservice.snackbar("success","Chat Session connected");

      })
    } catch(e){
      this.globalservice.snackbar("error","Something went wrong, please try after sometime");

    }
  }

  ngOnDestroy () {
    this.socket.disconnect(true);
    this.subscriptionDataForAPI.unsubscribe(); 
  }
  
  
  //update code at 3-04-2023​
  storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      this.localstorageChanged();
    }
  }
  show(str:any){
  }

  dragStatus(event:any) {
    if (event.checked) {
      this.dragStatusVal = true;
    } else {
      this.dragStatusVal = false;
    }
  }

  getMenuForShops(){
    var loginUserInfo:any = localStorage.getItem("loggedInUser");
    if (JSON.parse(loginUserInfo) != null) {
      let formData = {
        'shop_id':JSON.parse(loginUserInfo)["shop_id"],
        'user_id':JSON.parse(loginUserInfo)["id"]
      };
      this.getUserPermission();
      this.subscriptionDataForAPI = this.globalservice.callAPI(globalConstants.API_GET_MENU_FOR_SHOP,formData).subscribe((resp:any)=>{
       if(resp.result==1){
        let menuData = resp.data;
        menuData.sort(function (a:any, b:any) {
          var keyA = parseInt(a.priority);
          var keyB = parseInt(b.priority);
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1
          return 0
        });
        this.menuForShop = menuData;
        this.getShopDetails()
        interval(1000 * 60).subscribe((x) => {
           let user = this.globalservice.getCurrentUser();
           if (user && typeof user.id != 'undefined') {
             this.showLoader = false;
            //  this.customLoader.showLoader = false;
             let data = {opr:1, user_id: user.id, user_type: user.user_type };
             this.getNotify(data, true);
           } else {
             this.showLoader = true;
           }
         });
       }
      },(error:any)=>{});
    }
  }

  addNewItemToMenuItemResources(obj:any){
    var f = this.menuForShop.find(function(x:any){ return x.menu_name=="Vendors"});
    var alreadyPresent = f.vendors.find((x:any)=>{return parseInt(x.id) == parseInt(obj.id)});
    alreadyPresent = (alreadyPresent == undefined) ? false : true;
    if(f.vendors.length <5 && !alreadyPresent && obj.menu_status == "1") {
      f.vendors.push(obj)
    }
    f.vendors.sort(function(a:any,b:any){return (a.vendor_name > b.vendor_name ? 1 : -1)})
  }
  deleteVendor(obj:any) {
    var f = this.menuForShop.find(function(x:any){return x.menu_name=="Vendors"});
    var searchResource = f.vendors.find(function(x:any){ return parseInt(x.id) == parseInt(obj)});
    if(searchResource!=undefined) {
      f.vendors.splice(f.vendors.indexOf(searchResource),1);
    }
    f.vendors.sort(function(a:any,b:any){return (a.vendor_name > b.vendor_name ? 1 : -1)})
  }

  editVendor(obj:any) {
    var f = this.menuForShop.find(function(x:any){return x.menu_name=="Vendors"});
    var searchResource = f.vendors.find(function(x:any){ return parseInt(x.id) == parseInt(obj.vendor_id)});
    if(searchResource!=undefined) {
      searchResource.vendor_name = obj.vendor_name
      searchResource.link = obj.vendor_link
    }
    f.vendors.sort(function(a:any,b:any){return (a.vendor_name > b.vendor_name ? 1 : -1)})
  }

  /* Shop Resources */
  addNewItemToMenuItemShopResources(obj:any){
    var f = this.menuForShop.find(function(x:any){ return x.menu_name=="Resources"});
    var alreadyPresent = f.resources.find(function(x:any){ return x.id == obj.id })
    if(f.resources.length <5 && !alreadyPresent && obj.menu_status == "1") {
      f.resources.push(obj)
    }
    f.resources.sort(function(a:any,b:any){return (a.resource_name > b.resource_name ? 1 : -1)})
  }
  deleteResource(obj:any) {
    var f = this.menuForShop.find(function(x:any){return x.menu_name=="Resources"});
    var searchResource = f.resources.find(function(x:any){ return parseInt(x.id) == parseInt(obj)});
    if(searchResource!=undefined) {
      f.resources.splice(f.resources.indexOf(searchResource),1)
    }
    f.resources.sort(function(a:any,b:any){return (a.resource_name > b.resource_name ? 1 : -1)})
  }

  editResource(obj:any) {
    var f = this.menuForShop.find(function(x:any){return x.menu_name=="Resources"});
    var searchResource = f.resources.find(function(x:any){ return parseInt(x.id) == parseInt(obj.resource_id)});
    if(searchResource!=undefined) {
      searchResource.resource_name = obj.resource_name
      searchResource.resource_link = obj.resource_link
    }
    f.resources.sort(function(a:any,b:any){return (a.resource_name > b.resource_name ? 1 : -1)})
  }
  /* End Shop Resources */

  getCurrentLoggedInShop() {
    if (localStorage.getItem("loggedInUser") != null && localStorage.getItem("loggedInUser") != undefined) {
      var loginInfo:any = localStorage.getItem("loggedInUser")
      return JSON.parse(loginInfo)
    } else {
      return ""
    }
  }

  editProfile() {
    this.router.navigate(['shops/edit-shop']);
  }
  shopMenuActive(menuObj: any) {
    if(menuObj.route && menuObj.route.indexOf('/shops/cms') > -1){
      return (this.router.url == menuObj.route);  
    }
    if ((this.router.url == menuObj.route) && !menuObj.has_sub_menu) {
      return true;
    } else if (menuObj.has_sub_menu && this.isIncludes(menuObj.route,this.router.url) || this.isIncludes(menuObj.route,"/" + (this.router.url.split("/")[1] + "/" + this.router.url.split("/")[2]))) {
       return true;
    }
  }


  getCurrentShopSubscription() {
    this.loginUserInfo = localStorage.getItem("loggedInUser");
    if (localStorage.getItem("loggedInUser") != null && localStorage.getItem("loggedInUser") != undefined) {

      var isSubscriptionObjectPresent = JSON.parse(this.loginUserInfo)["subscription_data"].toString() != "" ? true : false

      var isSubscribed = JSON.parse(this.loginUserInfo).is_subscribed

      if(isSubscriptionObjectPresent) {
        var expiryDateInDb = JSON.parse(this.loginUserInfo)["subscription_data"].expiry_date
        var expirydateInTimestamp = new Date(expiryDateInDb).getTime()
        var isSubscriptionExpired = new Date().getTime() > expirydateInTimestamp ? true : false
        if(isSubscriptionExpired) {
          //  Your subscription is over. Please resubscribe to avail facilities.
          // this.shopAdsExpStatus = true;
            this.shopAdsExpStatus = true;
            this.shopAdsSubsStatus = false;
        } else {
          this.shopAdsExpStatus = false;
          this.shopAdsSubsStatus = false;
        }

      } else {
        this.shopAdsSubsStatus = true;
      }
    
      var isTaxSettingStatus = JSON.parse(this.loginUserInfo)["tax_setting_status"];
    
      if (isTaxSettingStatus != "1") {
        this.taxSettingStatus = true;
      } else {
        this.taxSettingStatus = false;
      }
    } else {
      this.shopAdsExpStatus = false;
      this.taxSettingStatus = false;
    }
    if (this.router.url == '/shops/subscription') {
      this.shopAdsSubsStatus = false;
      this.shopAdsExpStatus = false;
    }
  }

  getCurrentCustomerSubscription() {
    var customerRespons:any = localStorage.getItem("customerSubsResponse")
    if (localStorage.getItem("customerSubsResponse") != null && localStorage.getItem("customerSubsResponse") != undefined) {
      this.customerAdsDisplay = JSON.parse(customerRespons).subscribed;
      return this.customerAdsDisplay
      //return JSON.parse(localStorage.getItem("customerSubsResponse")).subscribed;
    }
  }
  
  getLoggedInUserDetail() {
    if (localStorage.getItem("loggedInUser") != null && localStorage.getItem("loggedInUser") != undefined) {
      return JSON.parse(this.loginUserInfo).first_name
    } else {
      return ""
    }
  }
  currentHostName() {
    return this.location.path;
  }
  gotoShopSubscription() {
    this.router.navigate(['/shops/subscription'])
  }

  ngOnInit() {
    this.globalENVStatus = globalConstants.GLOBAL_ENV_STATUS;
    this.router.events.subscribe(async(evt) => {
      
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.globalservice.setScrolling("body", 500, 0);
      window.scrollTo(0, 0);
      if (window.innerWidth > 991) {
        this.navState = true;
        if (this.navHeaderClose != true) {
          this.navHeaderClose = false;
        }
        this.menuState = false;
        this.navMode = 'side';
      } else {
        this.navMode = 'over';
        this.navState = false;
        this.navHeaderClose = true;
        this.menuState = true;
        this.dragShow = true;
      }
      this.getCurrentShopSubscription();
      this.touchView(null);
      if(await (localStorage.getItem("loggedInUser") != null && localStorage.getItem("loggedInUser") != undefined)) {
        this.loaderModal?.isShown === false && this.loaderModal?.show();
        this.initializeSocket();
      }else{
        this.socket.disconnect(true);
      }
      let url = "../assets/sounds/Notification.mp3";
      this.audio =new Audio(url);
      this.audio.volume = 0.5;
    });
    this.toggleOptionsOnHover()
  }

  initializeSocket(){
    this.getGlobalUnreadCount();
    this.socket.on('updateGlobalBadgeCount',(value)=>{
      if(value > 0 && value > this.globalBadgeCount ){ //&& modalState!=='active'
          this.audio.play();
          this.globalBadgeCount = value;
      } else {
          this.globalBadgeCount = value;
      }
    })

      this.socket.on('connect', async() => {
      setTimeout( async () => {
        this.helperService.updateSocketConnectionStatus(true);
        const userInfo = await this.globalservice.getCurrentUser();
          if(userInfo && this.socket.ioSocket.id!==null){
            const tokenInfo = userInfo.t;
            this.updateSocketId(tokenInfo,this.socket.ioSocket.id);
          }
        },5000);
      });
   
     this.socket.on('disconnect',()=>{
      this.helperService.updateSocketConnectionStatus(false);
    }) 
  }


  getGlobalUnreadCount =async () =>{
    const user = await this.globalservice.getCurrentUser()
    try {
      if(user){
        this.subscriptionDataForAPI =  this.globalservice.callAPI(globalConstants.CHAT_API_GET_UNREAD_CHAT_COUNT,{
          shopId:user.shop_id
        }).subscribe((response)=>{
          this.globalBadgeCount = parseInt(response.data.unread_count);
        })
      }
    } catch(e){
      this.globalservice.snackbar("error","Something went wrong");
    }
  }

/****resize and load***/
@HostListener('window:resize', ['$event'])
onResize(event:any) {
  const deviceWidth= window.innerWidth;
  this.menuToggle(deviceWidth);
  this.touchView(event);
}
@HostListener('window:load', ['$event'])
onLoad(event:any) {
  var scrollWidth = document.getElementById("scrollbar-hide");
  if (scrollWidth) {
    scrollWidth.style.width = "calc(100% + " + this.getScrollbarWidth() + ")";
  }
}
menuToggle(element:any) {
  if (element > 991) {
   
    this.navState = true;
    if (this.navHeaderClose != true) {
      this.navHeaderClose = false;
    }
    this.navMode = 'side';
    this.menuState = false;
    // this.dragShow = false;
  } else {
    this.navMode = 'over';
    this.navState = false;
    this.navHeaderClose = true;
    this.menuState = true;
    // this.dragShow = true;
  }
}

touchView(element:any) {
  // if (element != null) {
  //   if (element.path['0'].innerWidth > 767) {
  //     this.touchViewState = false;
  //   } else {
  //     this.touchViewState = true;
  //   }
  // } else {
  //   if (window.innerWidth > 767) {
  //     this.touchViewState = false;
  //   } else {
  //     this.touchViewState = true;
  //   }
  // }
}
/****sidenav update***/
sidenavToggle(state:any) {
  if (window.innerWidth > 991) {
    if (state.opened) {
      this.navHeaderClose = false;
    } else {
      this.navHeaderClose = true;
    }
  } else {
   let bodyDiv = <HTMLElement>document.querySelector('body');
   bodyDiv.classList.toggle("overflow-set")
  }
}
  menuClose(state: any, shopMenuData?: any) {
    if (typeof shopMenuData != 'undefined' && typeof shopMenuData.route != 'undefined' && shopMenuData.route == '/shops/list-service-requests/on-the-lot') {
      this.globalservice.getSetItems('on-the-lot', '1');
      this.router.navigate([shopMenuData.route]);
    } else if (typeof shopMenuData != 'undefined' && typeof shopMenuData.route != 'undefined' && shopMenuData.route == '/shops/live-chat') {
      this.openChatArea();
    } else {
      if(shopMenuData.route == '/shops/dashboard'){
         this.globalservice.controlDashboardNavigation();
      }else{
         this.router.navigate([shopMenuData.route]);
      }
    }

    if (window.innerWidth < 991) {
      if (state.opened) {
        this.sidenav.close();
        setTimeout(() => {
          let bodyDiv = <HTMLElement>document.querySelector('body');
          bodyDiv.classList.remove("overflow-set");
        }, 250);
      }
    }
  }
/****scrollbar width***/
getScrollbarWidth() {
  var outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.width = "100px";
  // outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

  document.body.appendChild(outer);

  var widthNoScroll = outer.offsetWidth;
  // force scrollbars
  outer.style.overflow = "scroll";

  // add innerdiv
  var inner = document.createElement("div");
  inner.style.width = "100%";
  outer.appendChild(inner);

  var widthWithScroll = inner.offsetWidth;

  // remove divs
  outer.parentNode!.removeChild(outer);
  let scrollbarWidth = widthNoScroll - widthWithScroll;
  return scrollbarWidth + "px";
}
isNoOneLoggedin() {
  return (localStorage.getItem("loggedInCustomer") == null && localStorage.getItem("loggedInAdmin") == null && localStorage.getItem("loggedInUser") == null) ? true : false
}
isAnyOneLoggedin() {
  return (localStorage.getItem("loggedInCustomer") != null || localStorage.getItem("loggedInAdmin") != null || localStorage.getItem("loggedInUser") != null) ? true : false
}
isLoginPage() {
  this.router.events
  .subscribe((event) => {
    if (event instanceof NavigationEnd) {
      if (event.url == "/customer" || event.url == "/shops" || event.url == "/admin" || event.urlAfterRedirects == "/404" || event.urlAfterRedirects == "/error") {
        this.loginPage = true;
      } else {
        this.loginPage = false;
      }
    }
  });
  //this.checkUserSession();
  return this.loginPage
}

isRegistrationPage() {
  this.router.events
  .subscribe((event) => {
    if (event instanceof NavigationEnd) {
      if (event.url == "/customer/register" || event.url == "/shops/register" ) {
        this.registrationPage = true;
      } else {
        this.registrationPage = false;
      }
    }
  });
  return this.registrationPage
}

isUserLoggedIn() {
  this.showShopView=true;
  return (localStorage.getItem("loggedInUser") == null) ? false : true
}
/* welcome dropdown-menu check open state*/
menuOpenState() {
  this.menuState = true;
}
menuCloseState() {
  this.menuState = false;
}

loadShopView(){
  let siteurl = this.router.url;
  if(this.isUserLoggedIn() && this.router.url.split('/')[1]=="shops" && siteurl!="/shops/forgot_password" && siteurl!="/shops/reset-password/:id1/:id2" && this.showCommonHeader) {
    this.showNoNLoggedInRoute = false;
    return true
  } else {
    return false
  }
}
logout(user_type:any) {
  this.globalservice.confirmDialog("logout", "Are you sure to logout?").subscribe((data:any) => {
    if (data != undefined && data.toString() == "logout") {
      this.socket.disconnect(true);
      this.globalservice.userLogout(user_type, true);
    }
  })
}
getlistServiceRequest() {
  localStorage.setItem("openFilter", "");
  localStorage.setItem("status", "");
  localStorage.setItem("shop-servicestatus", "");
  localStorage.setItem("search_keyword", "");
  localStorage.setItem("shop-servicesearch_keyword", "");
  localStorage.setItem("customer_id", "");
  localStorage.setItem("schedule_service_date", "")
  localStorage.setItem("requested_delivery_date", "")
  localStorage.setItem("estimated_delivery_date", "");
  this.router.navigate(['shops/list-service-requests']);
}
getServiceComplaints() {
  localStorage.setItem("complaint_status", "");
  localStorage.setItem("search_keyword", "");
  localStorage.setItem("openFilter", "");
  this.router.navigate(['shops/manage-service-complaints']);
}

getShopPartsList() {
  localStorage.setItem("search_by", "");
  localStorage.setItem("sort_by", "")
  localStorage.setItem("sort_type", "")
  localStorage.setItem("search_keyword", "");
  this.router.navigate(['shops/list-parts']);
}
getShopGenericJobList() {
  localStorage.setItem("search_by", "");
  localStorage.setItem("sort_by", "")
  localStorage.setItem("sort_type", "")
  localStorage.setItem("search_keyword", "");
  this.router.navigate(['shops/list-generic-jobs']);
}
getShopComputerCodesList() {
  localStorage.setItem("search_by", "");
  localStorage.setItem("sort_by", "")
  localStorage.setItem("sort_type", "")
  localStorage.setItem("search_keyword", "");
  this.router.navigate(['shops/list-computer-code']);
}
getShopGenericJobsList() {
  localStorage.setItem("search_by", "");
  localStorage.setItem("sort_by", "")
  localStorage.setItem("sort_type", "")
  localStorage.setItem("search_keyword", "");
  this.router.navigate(['shops/list-disclaimers']);
}


addCustomerCar() {
  localStorage.setItem("CustomerVinValues", "");
  localStorage.setItem("CustomerVinNumber", "");
  localStorage.setItem("CustomerVinExists", "");
  localStorage.setItem("CustomerlicenceNumber", "");
  localStorage.setItem("CustomerStateName", "");
  this.router.navigate(['customer/add_cars']);
}

checkUserSession() {
  // timeout check removed
  this.sessionCheck = true;
  let type=this.globalservice.getLoginType();
   if(this.isUserLoggedIn() && type=='shops'){
    jQuery('body').addClass(type);
  }else{
    jQuery('html').addClass('notranslate');
    jQuery("#hfc-frame").hide();
    jQuery('body').removeClass(type);
  }
}

localstorageChanged() {
  let type=this.globalservice.getLoginType();
   if((!this.isUserLoggedIn()) && type=='shops'){
    if(this.dragStatusVal){
      this.dragulaService.destroy("editor-bag");
      this.dragStatusVal = false;
    }
    this.isMenuLoaded = false;
    this.globalservice.setDefaultLang('');
    this.getCurrentShopSubscription();
  }

  var inURL = this.router.url.split("/")[1];
   if (inURL == "shops" && !this.isUserLoggedIn() && this.router.url != "/shops/forgot_password") {
    if(this.url.path().split("?")[0] != "/shops/reset-password" && this.url.path().split("?")[0] != "/shops/register") {
      this.globalservice.diaLogObj(null,null);
      this.router.navigateByUrl("/shops");
      // Added these lines to hide modal when token is expired/removed from localstorage
      if($('bs-modal-backdrop') && $('bs-modal-backdrop').length){
        $('bs-modal-backdrop').map((index)=>{
          $('bs-modal-backdrop')[index].style.display='none'
        })
      } 
      if($('modal-container') && $('modal-container').length){
        $('modal-container').map((index)=>{
          $('modal-container')[index].style.display='none'
        })
      }
    }
  } 
}

ngAfterContentInit(){
  if(this.isAnyOneLoggedin()) {
    this.showCommonHeader = true;
  }
  this.router.events
  .subscribe((event) => {
    if (event instanceof NavigationEnd) {
      this.checkUserSession();
      this.localstorageChanged();

    }
  });

  let observer = new MutationObserver(mutations => {
    let win:any=window;
   
    let canHide = win.LCAPIV && typeof win.LCAPIV.hideWidget === 'function' ? true : false;
    if (this.isUserLoggedIn()) {
      if(sessionStorage.getItem('translate')=='1'){
        jQuery('html').removeClass('notranslate')
      }
      // let cl=jQuery('#google_translate_element select option:selected').text()
      let shop=this.globalservice.getCurrentUser();
      
      if (shop.role_id == globalConstants.ROLE_ID_SHOP_OWNER || shop.role_id == globalConstants.ROLE_ID_MANAGER) {
        
      } else {
        if(canHide) win.LCAPIV && win.LCAPIV.hideWidget();
      }
    }else {
      if(canHide) win.LCAPIV && win.LCAPIV.hideWidget();
    }
    let type=this.globalservice.getLoginType();
    if(type != 'shops' ) {
      jQuery('html').addClass('notranslate');
      if(canHide) win.LCAPIV && win.LCAPIV.hideWidget();
    }
  });
  var config = { attributes: true, childList: true, characterData: true };
  observer.observe(document.body, config);
}

getUserPermission(){
  this.currentUser=this.globalservice.getCurrentUser();
  if(this.currentUser!=null){
    let data = {opr:1, user_id: this.currentUser.id, user_type: this.currentUser.user_type };
    this.subscriptionDataForAPI =  this.globalservice.callAPI(globalConstants.API_SHOP_USER_PERMISSIONS, data).subscribe((r:any) => {
      if (r.result == '1') {
        this.roles = r.data;
        this.globalservice.setAcl(this.roles);
        this.aclLodded=true
        this.getLearningData();
        let enableLang = this.currentUser.lang_enable;
        let currentLang = (this.currentUser.current_lang != null) ? this.currentUser.current_lang : "English" ;
        let select = document.getElementById('google_translate_element');
        if (enableLang == 1) {
          select!.style.visibility='visible';
        } else {
          select!.style.visibility = 'hidden';
        }
        if (currentLang != null && currentLang!='English') {
          this.globalservice.setDefaultLang(currentLang);
        } else {
          document.cookie = "googtrans=;path=/";
          sessionStorage.setItem('translate','0');
          jQuery('html').addClass('notranslate');
          this.globalservice.setDefaultLang(currentLang);
        }
        if (this.currentUser.original_lang == '1') {
          this.globalservice.setGLangCss('#goog-gt-tt{}');
        } else {
          this.globalservice.setGLangCss(this.globalservice.GLANGSTYLE);
        }
        this.getNotification(data);
        if (("Notification" in window)) { // To check if bower supports notification or not 
            const messaging = getMessaging();
            onMessage(messaging, (payload) => {
              this.msgService.showNotification(payload.notification, payload.data);
              this.msgService.currentMessage.next(payload);
              this.getNotification(data);
            })

        // old code start for web-notification(FCM)  
        //   this.msgService.messaging.onMessage((payload: any) => {
        //     this.msgService.showNotification(payload.notification, payload.data);
        //     this.msgService.currentMessage.next(payload);
        //     this.getNotification(data);
        //   });
        // old code end for web-notification(FCM)
        }
        this.checkWorkflowPermission();
        this.checkQuickSRCreatePermission();
      }
    },(error:any)=>{});
  }
 }

 checkSMSPermission(){
    if(this.currentUser.role_id == globalConstants.ROLE_ID_SHOP_OWNER){
       return true;
    }else{
       return this.globalservice.getAcl('live_chat','views');
    }
 }
 checkWorkflowPermission() {
    this.workFlowPermission = this.globalservice.getAcl('service_request','views');
  }

  openWorkFlowPage() {
    this.router.navigateByUrl("shops/boards/job-board");
  }

  checkQuickSRCreatePermission() {
    this.quickSRCreatePermission = this.globalservice.getAcl('service_request','addNew');
  }

  handleQuickSRCreation() {
    let payload = {
      user_id: this.currentUser.id,
      user_type: this.currentUser.user_type
    }
    this.subscriptionDataForAPI = this.globalservice.callAPI(globalConstants.API_CREATE_QUICK_SERVICE_REQUEST, payload).subscribe((response: any) => {
      if (response.result == '1') {
        localStorage.setItem("msgForSnackBar",response.message);
        this.router.navigateByUrl("/shops/service-inspection-report/" + response.data.request_id);
      } else {
        this.globalservice.snackbar("error", response.message);
      }
    })
  }

 isIncludes(r:any,u:any){
  if(r.indexOf(u)!=-1){
    return true;
  }
  return false;
 }
 getNotification(data?:any){
  // if (("Notification" in window)) {
  //  if(this.msgService.currentToken==''){
  //   this.msgService.getPermission()
  //   this.msgService.receiveMessage()
  //  }
   this.getNotify(data)
  // }else{
      // Observable.interval(1000 * 60).subscribe((x) => {
      //   let user = this.globalservice.getCurrentUser();
      //   if (user && typeof user.id!='undefined') {
      //     this.showLoader = false;
      //     this.getNotify(data, true);
      //   }else{
      //     this.showLoader = true;
      //   }
      // });   
  // }
 }


 // this function is called on row item click form notification bell icon in header
  showNotification(notify:any) {
    let urlData: any = null;
    let data = { opr: 2, oprid: notify.id, user_id: this.currentUser.id, user_type: this.currentUser.user_type };
    if (notify.notification_payload) {
      urlData = JSON.parse(notify.notification_payload);
    }
    if(notify.module_name=="part" && notify.module_action=="PartsInventory"){
      this.globalservice.localGetterSetter('handel',JSON.stringify(notify),'notification-');
    }
    if (notify.read_status==0) {
      this.subscriptionDataForAPI = this.globalservice.callAPI(globalConstants.API_NOTIFICATIONS, data).subscribe((r:any) => {
        data.opr = 1;
        this.gotoNotificatioinModule(urlData,data);
      }, (error:any) => { });
    }else{
      data.opr = 1;
      this.gotoNotificatioinModule(urlData,data);
    }
  }

// funcation to mark all notification "read" in notification bell icon
 markNotification(){
  let data={opr:3,user_id: this.currentUser.id, user_type: this.currentUser.user_type };
  this.subscriptionDataForAPI =  this.globalservice.callAPI(globalConstants.API_NOTIFICATIONS,data).subscribe((r:any)=>{
   data.opr=1;
   this.getNotification(data);
  },(error:any)=>{});
 }
  /**
   * @trackID <:CAR-1369> open same route with diffrent param
   * @updated 2020-02-13
   * @updatedBY Vikas Kumar
   * @param 
   * @return
   */
 gotoNotificatioinModule(urlData:any,data:any){
  if(urlData && typeof urlData.redirect_url !='undefined' && urlData.redirect_url != "" && urlData.redirect_url){
    this.globalservice.localGetterSetter('route',JSON.stringify(urlData),'notification-');
    this.router.navigate(['shops/notifyredirect']); 
  }
  this.getNotification(data);
 }

 ShowChat(){
  globalFunctions.supportChat()
 }

 appointmentCal(){
  this.router.navigate(['shops/service-calendar']); 
 }

 bookAppointment(){  
  if(this.globalservice.getAcl('service_request','addNew')){
    let dialogRef = this.dialog.open(QuickapptComponent, {
      panelClass: ['quickqouotedialog'],
      width: "1200px",
      data: {
        startTime:new Date(),
        endTime:new Date(),
        eventType:1
      }
    });
  }else{
    this.globalservice.snackbar("error", 'You do not have access rights for this resource.');
  }
 }

 AppplyForLeave(){
  if(this.globalservice.getAcl('apply_employee_leaves','addNew')){
    let dialogRef = this.dialog.open(AddleaveComponent, {
      panelClass: ['car-dialog-form'],
      width: "800px"
    });
  }else{
    this.globalservice.snackbar("error", 'You do not have access rights for this resource.');
  }
 }

 ccAuthEULA(){
  let dialogRef = this.dialog.open(CCAuthorizationComponent, {
    panelClass: ['quickqouotedialog', 'cc-authdialog'],
    disableClose: true,
    width: "100%"
  });
}

  ApptCalShow(){
    if (this.isUserLoggedIn()) {
      let shop=this.globalservice.getCurrentUser();
      if ([globalConstants.ROLE_ID_SHOP_OWNER,globalConstants.ROLE_ID_MANAGER,globalConstants.ROLE_ID_ADVISOR,globalConstants.ROLE_ID_TECHNICIAN].includes(Number(shop.role_id))) {
        return true;
      } else {
        return false;
      }
    }  
    return false;    
  } 

 happyFoxShow(){
  if (this.isUserLoggedIn()) {
    let shop=this.globalservice.getCurrentUser();
    if (shop.role_id == globalConstants.ROLE_ID_SHOP_OWNER || shop.role_id == globalConstants.ROLE_ID_MANAGER) {
      return true;
    } else {
      return false;
    }
  }  
  return false; 
 }

 getNotify(data?:any,setTrue?:any){
  let DateFormat=this.globalservice.DateFormat();
   data.user_id= this.currentUser.id;
   data.user_type= this.currentUser.user_type 
   this.subscriptionDataForAPI = this.globalservice.callAPI(globalConstants.API_NOTIFICATIONS,data).subscribe((r:any) => {
    if (r.result == '1') {
      let read_status = true;
      if (r.data.length) {
        for (var i in r.data) {
          r.data[i].add_date=this.globalservice.getFullFormateDate(r.data[i].add_date,DateFormat,'H')
          if (r.data[i].read_status == "0") {
            read_status = false;
          }
        }
      }
      this.allRead=read_status;
      this.notifications=r.data;
      this.notifyCount=r.count;
    }
    if(setTrue){
      this.showLoader=true;
    }
  },(error:any)=>{})
 }

  public reloadPage(){
    location.reload();
  }

  eLearnPortal(m:any){
    if(m.url && m.url !=''){
      this.iframeUrl = '';
      let th=this;
      setTimeout(()=>{
        th.iframeUrl = th.sanitiz.bypassSecurityTrustResourceUrl(m.url);
        th.vmodal.show();
        if(m && typeof m.title!='undefined'){
          th.minidragTitle=m.title
          if(m.img && typeof m.img!='undefined'){
            th.minidragIcon=m.img
          }else{
            th.minidragIcon='car2.jpg';
          }
        }else{
          th.minidragTitle=''
          th.minidragIcon='car2.jpg';
        }
        th.cdr.detectChanges();
      },0)
    }
  }

  elearn(type:any){
    if(type==1){
      this.showMinButton=true;
      this.vmodal.show();
    }else if(type==3){
      this.vmodal.hide();
      this.showMinButton=true;
      this.iframeUrl ='';
    }else{
      this.showMinButton=false;
      this.vmodal.hide();
    }
  }
  getLearningData() {
    let mover = (document.getElementById("movev"));
    function dragMouseDown(e:any) {
      e = e || window.event;
      e.preventDefault();
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      document.onmousemove = elementDrag;
    }

    function elementDrag(e:any) {
      e = e || window.event;
      e.preventDefault();
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      var w = (mover!.offsetLeft - pos1);
      var h = (mover!.offsetTop - pos2);
      var sw = window.innerWidth;
      var sh = window.innerHeight;
      if (h < 0) {
        h = 0;
      }
      if (w < 0) {
        w = 0;
      }
      if ((sw - w) < 151) {
        w = sw - 152;
      }
      if ((sh - h) < 30) {
        h = sh - 31;
      }
      mover!.style.top = h + "px";
      mover!.style.left = w + "px";
    }

    function closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    }

    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if (document.getElementById(mover!.id + "Elearning")) {
      var el = document.getElementById(mover!.id + "Elearning")
      el!.onmousedown = dragMouseDown;
    } else {
      mover!.onmousedown = dragMouseDown;
    }
    let data = { opr: 1, user_id: this.currentUser.id, user_type: this.currentUser.user_type };
    this.subscriptionDataForAPI = this.globalservice.callAPI(globalConstants.API_E_LEARNING, data).subscribe((r:any) => {
      if (r.result == '1') {
        this.eLearn = r.data;
      }
    }, (error:any) => { })
  }
  /** quick quote popup */
  quickQuote() {
    let dialogRef = this.dialog.open(QuickquoteComponent, {
      disableClose: true,
       panelClass: ['quickqouotedialog'],
       width: "100%",
    });
  }
  isQuickQuote(){
    let shopPer=this.globalservice.isSubscribe('quick_quote')
    let userPer=this.globalservice.getAcl('quick_quote','addNew');
    
    return (shopPer && userPer)
  }

  showInDevShop(){
    let envStatus = this.globalENVStatus;
    let navHeaderStatus = this.navHeaderClose;
    if(!envStatus && !navHeaderStatus){
         return  true;
    }
    else if(!envStatus && navHeaderStatus){ 
       return true;
    }
    else if (!envStatus){
     return true;
    }
    return false;
  }

  getCutomerDetail(){
    let custLogin=this.globalservice.getCurrentCustomer()
    this.nonFleetmenu=true;
    if(custLogin){
      var userOpr = {"customer_id": custLogin.id,"user_type": custLogin.user_type ,"opr":1};
      this.subscriptionDataForAPI =  this.globalservice.callAPI(globalConstants.API_GET_CUSTOMER_DETAILS, userOpr).subscribe((r:any) => {
        if(r && r.result==1){
          this.globalservice.setCustomerData(r.data);
          this.showCustMenu()
        }
      },(error:any)=>{ })
    }
  }
  showCustMenu(){
    let isFleet=this.globalservice.isFleetCustomer()
    this.nonFleetmenu=(!isFleet)
    this.cdr.detectChanges();
  }
  /**
   * @trackID <:CAR-1423> Master Data assined
   * @since 2020-02-19
   * @createdBY Vikas Kumar
   * @return
   */
// Handles clicks on the training logo icon
onTrainingLogoIconClick(){
  this.showOptions = true;
}

// Handles hover logic for the expansion panel
toggleOptionsOnHover() {
  const screenWidth = window.innerWidth; 
  if (screenWidth > 991) {
    this.showOptions = true;
  } else {
    this.showOptions = false;
  }
}

// Toggles the expansion panel's state
toggleOptions(){
  this.showOptions = !this.showOptions;
}
  getShopDetails(){
    var shopID = "user_id=" + this.globalservice.getCurrentUser()["id"] + "&user_type=" + this.globalservice.getCurrentUser()["user_type"] + "&shop_id=" + this.globalservice.getCurrentUser()["shop_id"];
    this.subscriptionDataForAPI =  this.globalservice.callAPI(globalConstants.API_GET_SHOP_DETAILS_ON_DASHBOARD, shopID).subscribe((res:any) => {
     if(res.result == "1"){
      this.training_video_popup = JSON.parse(this.loginUserInfo).training_video_popup;
      this.training_video_icon = JSON.parse(this.loginUserInfo).training_video_icon;
      if(document.getElementById("staticModal") && this.training_video_popup == 0 && (this.router.url == "/shops/dashboard" || this.router.url == "/shops/dashboard-two") ){
        this.disablePopup = false;
       var temp = document.getElementById("staticModal");
       temp!.click();
      }
      this.showLogo = this.shopLogo == 'undefined' ? false: true;
      this.shopLogo = res.data.replace_logo == 1? "assets/img/replacement_logos/"+res.data.replacement_logo:'assets/img/logo.png';
      this.globalservice.setShopData(res.data);
      this.globalservice.setShopDetails(res.data);
     }
    },(error:any)=>{ })
  }
  
  restorePopup(id:string){
    // old code for re-open the minimizable pop-up
    // let bsModalInfo:ModalInfo = this.globalservice.getBootstrapModalInfo(id);
    // const initialState = bsModalInfo.data;
    // console.log("this is initiState", initialState)
    // let bsModalRef = this.modalService.show(bsModalInfo.component, {initialState});
    // for(let x in initialState){
    //   bsModalRef.content[x] = initialState[x];
    // }
    //end
    if(id=="customer-live-chat") {
      this.openChatArea();
    } else {
      let info:any = {​​
        id:id,
      }
      this.globalservice.openMinimizablePopup('',info);
    }
  }
  
  closePopup(id:string){
    this.chatModalRef.close();
    this.globalservice.clearBootstrapModal(id);
  }
  
  initModalsData(){
    var minimiz:any = localStorage.getItem('minimizedWindows')
    this.modalsIds = JSON.parse(minimiz) || [];
    // need to store id keyword to component mapping to reset component.
    this.modalsIds.forEach((m)=>{
      let component:any;
      if(m.id.indexOf('service-history') >= 0){
        if(!this.globalservice.getBootstrapModalInfo(m.id)){
          component = ServiceHistoryComponent;
          let initialState = m.data;
          let bsModalRef = this.modalService.show(component, {initialState});
          this.globalservice.setBootstrapModalRef(initialState.id, bsModalRef);
           this.globalservice.setBootstrapModalInfo(initialState.id, {component: component, data: initialState, name: 'Service History View', id: initialState.id, module:'Service Request'});
        }
      }
    });
    // this.sendLatestModalsInfo();
  }
  
  openServiceHistoryAdvanced(){​​
    console.log('advanced mode');
    let info:any = {​​
      id:'service-history-advanced',
      name: 'Service History Advanced Search',
      module: 'Service Request'
    }​​
    let data:any = {​​​ isPopup: true, isAdvancedSearch: true, disableModeSwitch:true, carId:'', serviceInfo: null, id: 'service-history-advanced'}​​;
    this.globalservice.openMinimizablePopup(ServiceHistoryComponent, info, data);
  }

  openChatArea(){
    const alreadyExists:MatDialogRef<CustomerLiveChatComponent>=this.dialog.getDialogById('customer-live-chat');
    if(alreadyExists) {
      alreadyExists.removePanelClass('hide-modal');
      alreadyExists.componentInstance.addNoneClass=false;
      document.querySelector('html').classList.add('cdk-global-scrollblock');
      this.helperService.toggleLiveChatModal('active');
      this.globalservice.setBootstrapModalInfo("customer-live-chat",{
        component: CustomerLiveChatComponent,
        data: {},
        name: "Customer Live Chat",
        module: "Customer Live Chat",
        id: "customer-live-chat"
      });
    } else {
      this.helperService.toggleLiveChatModal('active');
      this.chatModalRef=this.dialog.open(CustomerLiveChatComponent,{
        panelClass: ['full-width-dialog'],
        restoreFocus: true,
        backdropClass: 'cdk-overlay-transparent-backdrop',
        hasBackdrop: false,
        id: 'customer-live-chat',
        width:'100vw',
        minWidth:'100%',
        data: {
          toggleModalState: (state) => {
            if(state==='minimise') {
              this.helperService.toggleLiveChatModal('minimised');
              this.chatModalRef.disableClose=true;
              this.chatModalRef.addPanelClass('hide-modal');
              this.chatModalRef.backdropClick();
              document.querySelector('html').classList.remove('cdk-global-scrollblock');
              this.globalservice.setBootstrapModalInfo("customer-live-chat",{
                component: CustomerLiveChatComponent,
                data: {},
                name: "Customer Live Chat",
                module: "Customer Live Chat",
                id: "customer-live-chat",
              })
            } else {
              this.helperService.toggleLiveChatModal('destroyed');
              this.chatModalRef.close();
              this.globalservice.clearBootstrapModal("customer-live-chat");
            }
          }
        }
      })
    }
  }

  closePopUpModel(id:number){
    if(this.disablePopup && id ===1){  // clicked on yes + checkbox checked
      var shopID = "user_id=" + this.globalservice.getCurrentUser()["id"] + "&user_type=" + this.globalservice.getCurrentUser()["user_type"] + "&training_update_type=1 & training_video_popup=" +(this.disablePopup?1:0) + "& training_video_icon=" +this.training_video_icon;
      this.subscriptionDataForAPI = this.globalservice.callAPI(globalConstants.API_UPDATE_TRAINING_VIDEO_SETTING, shopID).subscribe((res:any) => {
          let u = this.loginUserInfo;
          u.training_video_popup = this.disablePopup?1:0;
          localStorage.setItem('loggedInUser',JSON.stringify(u));
          this.loginUserInfo = u;
      },(error:any)=>{ })
    }
    if(document.getElementById("staticModalHide")){
      var temp =document.getElementById("staticModalHide");
      temp!.click();
    }
  }

  hideTutorial(type:any){
    if(type === 1){
      this.training_video_icon = (this.training_video_icon==='0')?'1':'0';
    }else{
      this.training_video_icon = '1';
    }
    var shopID = "user_id=" + this.globalservice.getCurrentUser()["id"] + "&user_type=" + this.globalservice.getCurrentUser()["user_type"] + "&training_update_type=2 & training_video_popup=" +this.training_video_popup + "& training_video_icon=" +this.training_video_icon;
    this.subscriptionDataForAPI =  this.globalservice.callAPI(globalConstants.API_UPDATE_TRAINING_VIDEO_SETTING, shopID).subscribe((res:any) => {
      if(this.training_video_icon === '1'){
        this.globalservice.snackbar("success", 'To reopen Training Videos, select your profile icon, then Click Training Video. If you’re looking for extra support, select Live Chat to find articles that relate to your task.','Close',true,8000);
      }
      this.toggleOptions();
    },(error:any)=>{ }) 
  }
  isSubMenuActive(shopmenu: any): boolean {
    // Check if any submenu route matches the current router URL
    return shopmenu.sub_menu.some((subMenu: any) => this.router.url === subMenu.route);
  }

  dashBoardNavigationControl(){
      this.globalservice.controlDashboardNavigation();
  }
}

