<div mat-dialog-title *ngIf="iframe==''" class="relative" >
    Video
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
        <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
</div>
<div *ngIf="vehVideo==''" class="relative  btn-close-right">
         <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
             <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
         </button>
</div>
<mat-dialog-content *ngIf="iframe==''">
    <p class="text-center" *ngIf="vehVideo!=''">
        <video controls style="width: 100%;">
            <source src="{{vehVideo}}mp4/{{vehicle_video}}.mp4" type="video/mp4">
            <source src="{{vehVideo}}mov/{{vehicle_video}}.mov" type="video/mov">
        </video>
    </p>
</mat-dialog-content>
<mat-dialog-content *ngIf="vehVideo==''">
    <div class="partstech-cred-banner" *ngIf="isPartsTechConfigured == 0">
        Using Demo Account
    </div>
    <div class="partstech-iframe-wrap">
        <iframe id="partstech-iframe" *ngIf="iframe!=''" [src]="iframe" width="100%" height="100%"></iframe>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative pt-dialog-action" id="partstech-iframe-close-btn">
    <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
</mat-dialog-actions>
