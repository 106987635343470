import {Component, OnInit, ViewChild, Pipe, Inject,OnDestroy} from '@angular/core';
import {AbstractControl, FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd,Params} from '@angular/router';
import {Http, Headers, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../environments/globalFunctions"
import {globalConstants} from "../../../environments/globalConstants"
import {GlobalService} from "../../../environments/global.service"
import {lang} from "../../../environments/lang-en"
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';
import {AppComponent } from "../../app.component"
@Component({
  selector: 'app-payment-response',
  templateUrl: './payment-response.component.html',
  styleUrls: ['./payment-response.component.scss']
})
export class PaymentResponseComponent implements OnInit,OnDestroy {
public formData:FormGroup;
public paymentSuccess;
public subscriptionMessage;
public loggedInShop = JSON.parse(localStorage.getItem("loggedInUser"));
public subscriptionDataForAPI: Subscription = new Subscription();

  constructor(public app: AppComponent,private activatedRoute: ActivatedRoute,private formBuilder: FormBuilder,public titleService: Title, private http: Http, private router: Router, private globalService: GlobalService) {
    console.log(this.activatedRoute.snapshot.queryParams)
    this.setFormBuilder(this.activatedRoute.snapshot.queryParams);
    this.sendRequest();
  }

  setFormBuilder(params) {
    this.formData = this.formBuilder.group({
      userId:[this.loggedInShop.id],
      shop_id:[params.shop_id],
      shopId:[this.loggedInShop.shop_id],
      first_name:[params.first_name],
      last_name:[params.last_name],
      email:[params.email],
      street_address:[params.street_address],
      city:[params.city],
      state:[params.state],
      country:[params.country],
      zip:[params.zip],
      fixed:[params.fixed],
      product_id:[params.product_id],
      merchant_order_id:[params.merchant_order_id],
      ip_country:[params.ip_country],
      product_description:[params.product_description],
      currency_code:[params.currency_code],
      pay_method:[params.pay_method],
      invoice_id:[params.invoice_id],
      order_number:[params.order_number],
      card_holder_name:[params.card_holder_name],
      merchant_product_id:[params.merchant_product_id],
      credit_card_processed:[params.credit_card_processed],
      total:[params.total],
      cart_tangible:[params.cart_tangible],
      quantity:[params.quantity],
      key:[params.key],
      userType:["User"],
      subscription_id:[localStorage.getItem("subscription_id")]
    })
  }
    sendRequest() {
      this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_SUBSCRIBE_SHOP,this.formData.value).subscribe((data:any)=>{
            // console.log(data);
            this.subscriptionMessage = data.message;
            localStorage.setItem("shopPaySuccessMsg", data.message)
            if(data.result == "1") {
                this.paymentSuccess = true;
                data["data"]["timeStamp"] = JSON.parse(localStorage.getItem("loggedInUser"))["timeStamp"]
                localStorage.setItem("loggedInUser",JSON.stringify(data["data"]));
                this.app.getCurrentShopSubscription();
                this.router.navigate(["shops/payment-success"]);
            } else {
                this.paymentSuccess = false;
                this.router.navigate(["shops/payment-fail"]);
            }
        })
    }

  ngOnInit() {
  }

  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
}
}
