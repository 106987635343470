<div class="panel panel-primary upgrade-subscription">
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">Subscription</h3>
    </div>
    <div class="panel-body bgcolor-lightgrey subscription-section">
        <div class="display-full padding-15 padding-bottom-0">
            <form [formGroup]="shopPayment" class="form-inline col-xs-12 col-sm-12 col-md-12 col-lg-12" role="form" method="POST" enctype="multipart/form-data" autocomplete="off">
                <mat-tab-group #tabNavbar indexToSelect="0" [selectedIndex]="0">

                    <mat-tab label="Subcription Plan">
                        <ng-template mat-tab-label>
                            <h4>Subcription Plans</h4>
                        </ng-template>
                        <div class="padding-15 grid-wrap">
                            <div class="row grid-row gutter-bottom">
                                <owl-carousel-o *ngIf="plansList && plansList.length" [options]="customOptions">
                                    <ng-container class="" *ngFor="let subscription of plansList;let i =index">
                                      <ng-template carouselSlide >
                                        <div class="col-xs-12">
                                            <mat-card   class="subscription-card" [ngClass]="{'basic-plan':subscription.plan_type_group == 1,  'silver-plan':subscription.plan_type_group == 2}">
                                                <mat-card-header class="relative text-center">
                                                    <div class="popular-badge hide"><span>Popular</span></div>
                                                    <mat-card-title class="margin-0 relative">
                                                        <b>$</b><span class="price">{{subscription.amount}}</span>
                                                        <span class="display-full per-year font-bold">Per {{subscription.plan_type == 1? 'Month':'Year'}}</span>
                                                        <span class="display-full text-center">
                                                        Yearly amount ${{subscription.yearly_amount}} (SAVE $ 0.00 )
                                                    </span>
                                                    
                                                    </mat-card-title>
                                                    <mat-card-subtitle class="font-bold" >{{subscription.subscription_name}}</mat-card-subtitle>
                                                </mat-card-header>
                                                <mat-card-content>
                                                    <mat-expansion-panel hideToggle="true" [expanded]="true" class="feature-panel">
                                                        <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px">
                                                            <span class="font-bold icon">Includes:</span>
                                                        </mat-expansion-panel-header>
                                                        <ul class="list-group list-group-flush text-center perkslist">
                                                            <li class="list-group-item" *ngFor="let feature of subscription.featuresIncluded.features_included">
                                                                <div class="row list-row">
                                                                    <div class="col-xs-10 col-sm-10"><i class="fa fa-check fa-fw text-success"></i>&nbsp;&nbsp;<span>{{feature.name}}</span></div>
                                                                    <div class="col-xs-2 col-sm-2 text-right car-red">
                                                                        <i class="fa fa-circle" [ngClass]="feature.included == 2? 'hide':''"></i>
                                                                        <img src="/assets/img/half-circle.png" [ngClass]="feature.included == 1? 'hide':''">
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </mat-expansion-panel>
                                                    <mat-card-actions class="text-center card-action-btn-section">
                                                        <label class="margin-bottom-15 margin-top-10">
                                                            
                                                                <mat-checkbox  (change)="handlePlanSwitch($event, subscription,i)" [checked]="subscription?.checked">Yearly subscription amount ${{subscription.yearly_amount}}</mat-checkbox>
                                                        </label>
                                                        <button mat-raised-button class="font-bold text-uppercase" (click)="getPaymentGateway(subscriptionPlans, subscription,i)">Subscribe Now</button>
                                                    </mat-card-actions> 
                                                    <mat-expansion-panel hideToggle="true" [expanded]="true" class="extras-panel">
                                                        <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px">
                                                            <span class="font-bold icon">Extras:</span>
                                                        </mat-expansion-panel-header>
                                                        <ul class="list-group list-group-flush text-center perkslist">
                                                            <li class="list-group-item" *ngFor="let extra of subscription.extrasInfo.extras">
                                                                <div class="row list-row">
                                                                    <div class="col-xs-9 col-md-10 ">
                                                                        <i class="fa fa-check fa-fw text-success" [ngClass]="extra.included == 2? 'hide':''"></i>
                                                                        <i class="fa fa-minus fa-fw car-red" [ngClass]="extra.included == 1? 'hide':''"></i>
                                                                        &nbsp;&nbsp;<span>{{extra.name}}</span>
                                                                    </div>
                                                                    <div class="col-xs-3 col-md-2 text-right price-col">{{extra.price}}</div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </mat-expansion-panel>

                                                </mat-card-content>
                                            </mat-card>
                                        </div>
                                      </ng-template>
                                    </ng-container>
                                </owl-carousel-o>
                                <!-- </div>    -->
                            </div>
                        </div>
                    </mat-tab>
                <mat-tab label="Fleet Plans" class="font-bold">
            <ng-template mat-tab-label>
                <h4>Fleet Plans</h4>
            </ng-template>
            <div class="padding-15" *ngIf="shopDetails && shopDetails['fleet_request_mail']==0">
                <div class="row margin-bottom-5">
                    <div class="col-sm-12">
                        <h3>Looking For a Fleet:</h3>
                        <p>If you are running a fleet, we have options to help you use CAR to manage your fleet better, faster and
                            more efficiently.</p>
                        <p>Reach out to us to learn more about those options by pressing below button to contact and CAR team will
                            contact you back.</p>
                    </div>
                </div>
                <div class="display-full margin-bottom-5">
                    <div class="row">
                        <label class="col-sm-3">Company Name</label>
                        <p class="col-sm-9 break-word">{{shopDetails["company_name"]}}</p>
                    </div>
                    <div class="row">
                        <label class="col-sm-3">Email</label>
                        <p class="col-sm-9">
                          <a class="text-red break-word" href="mailto:{{shopDetails['email_address']}}">{{shopDetails["email_address"]}}</a></p>
                    </div>
                    <div class="row">
                        <label class="col-sm-3">Owner Name</label>
                        <p class="col-sm-9 break-word">{{shopDetails["owner_name"]}}</p>
                    </div>
                    <div class="row">
                        <label class="col-sm-3">Phone Number</label>
                        <p class="col-sm-9">{{shopDetails["business_phone1"]}}</p>
                    </div>
                </div>
                <div class="row margin-bottom-5">
                    <div class="col-sm-12" *ngIf="shopDetails && shopDetails['fleet_request_mail']==0">
                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                            <textarea matInput type="text" title="Enter Your Message" maxlength="1000" placeholder="Enter Your Message(optional)"
                                [(ngModel)]="fleetComments" [ngModelOptions]="{standalone: true}"
                                class="formcontrol input-lg custom-form-input btn-block"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 margin-top-5" *ngIf="shopDetails && shopDetails['fleet_request_mail']==0">
                        <button type="button" (click)="fleetComment()"
                            class="font-bold mat-raised-button mat-accent">Submit</button>
                    </div>
                </div>
            </div>
            <div class="padding-15" style="height: auto;" *ngIf="shopDetails">
                <div class="row">
                    <div class="payment-content col-sm-12">
                        <div class="alert alert-success" *ngIf="shopDetails && shopDetails['fleet_request_mail']==1">
                            <p *ngIf="!profileType">Your request for Fleet has been submitted successfully. Someone from support team will contact you soon.</p>
                            <p>For any questions related to Fleet please contact our support team by clicking below button.</p>
                            <div class="margin-top-15 text-center"><button type="button" class="font-bold mat-raised-button mat-accent margin-top-10" (click)="showChat()">Contact Now</button></div>
                        </div>
                    </div>
                </div>
            </div>
                </mat-tab>
                </mat-tab-group>
            </form>

        </div>
    </div>
</div>
