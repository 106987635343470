import { Component, OnInit, ViewChild, ViewEncapsulation,OnDestroy} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap,NavigationEnd, Params  } from '@angular/router';
import { Http,Headers, Response} from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { globalFunctions } from "../../../../environments/globalFunctions";
import { globalConstants } from "../../../../environments/globalConstants";
import {GlobalService} from "../../../../environments/global.service";
import { lang } from "../../../../environments/lang-en";
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TextMaskModule } from 'angular2-text-mask';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar} from '@angular/material/snack-bar'
import { ImagetooldialogComponent } from '../../../imagetooldialog/imagetooldialog.component';
@Component({
  selector: 'app-shopviewservicecomplaint',
  templateUrl: './shopviewservicecomplaint.component.html',
  styleUrls: ['./shopviewservicecomplaint.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShopviewservicecomplaintComponent implements OnInit,OnDestroy {
    public serviceRequestId: number;
    public shopAddComment: FormGroup;
    public serviceInfo: any;
    public serviceInfoComments: any=[];
    public serviceRequestList: any;
    public showServiceDetail: boolean = false;
    public service_complaint_id;
    public servicecommentDetails;
    public serviceInfoStatus;
    public read_status;
    public serviceReadStatus='';
    myControl: FormControl = new FormControl();
    genericControl: FormControl = new FormControl();
    public complaint_id:any=null;
    //filteredOptions: Observable<string[]>;
    //genericFilterOptions: Observable<string[]>;
    partsOptions = [];
    genericOptions = [];
    public statuses = ['', 'Created', 'Resolved', 'Closed', 'Unread'];
    public subscriptionDataForAPI: Subscription = new Subscription();

    public complaintImgThumbUrl: string = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_COMPLAINT_PIC + globalConstants.IMG_THUMB;
    constructor(private activatedRoute:ActivatedRoute, public dialog: MatDialog,private formBuilder: FormBuilder,private route: ActivatedRoute,public titleService:Title,private http: Http,private router: Router,public snackBar: MatSnackBar, public globalService: GlobalService) {
        if(localStorage.getItem("loggedInUser")) {
            this.initialializeAddPartForm();
            this.getParams();
            if(this.complaint_id){
                this.service_complaint_id =this.complaint_id
            }else{
                this.service_complaint_id = localStorage.getItem("shopComplaintID");
            }
            this.serviceReadStatus = this.globalService.getSetItems("shopComplaintReadStatus");
            this.globalService.setMetaData("SHOPS","VIEW_SERVICE_COMPLAINT")
        } else {
            this.router.navigate(["/shops"]);
        }
        setTimeout(()=>{
        let isEnable=this.globalService.getAcl('complaints','views');
        if(!(isEnable)){
            this.router.navigate(['shops/action/forbidden']);
        }
    },globalConstants.RELOAD_WAIT)
    }

    ngOnInit() {
        this.initialializeAddPartForm();
        let content = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&service_complaint_id=" + this.service_complaint_id+"&read_status="+this.serviceReadStatus;
          this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_SERVICE_COMPLAINT_VIEW_URL, content).subscribe((response:any) => {
          if (response.result == 1) {
            // console.log("service", response.data);
              this.serviceInfo = response.data;
              this.serviceInfoStatus = response.data.status;
              this.shopAddComment.controls.add_comment_request_id.setValue(this.serviceInfo.request_id);
              this.shopAddComment.controls.service_complaint_customer_id.setValue(this.serviceInfo.user_id);
              if(this.serviceInfo.comments != null) {
                  if (this.serviceInfo.comments && this.serviceInfo.comments.length){
                      this.serviceInfoComments = this.serviceInfo.comments;
                  } else {
                      this.serviceInfoComments[0] = this.serviceInfo.comments;
                  }
              }
              let vehicleName="";
              if (this.serviceInfo.year) {
                vehicleName= this.serviceInfo.year ;
              }
              if(this.serviceInfo.make != ""){
                vehicleName+= (vehicleName ? "/" : "" ) + this.serviceInfo.make;
              }
              if(this.serviceInfo.model != ""){
                vehicleName+= (vehicleName ? "/" : "" ) + this.serviceInfo.model
              }
              this.serviceInfo.vehicleName=(vehicleName ? vehicleName :'N/A');
              //this.serviceInfoComments = this.serviceInfo.comments;
              this.serviceRequestList = response.data.service_request_list;
              this.showServiceDetail = true;
          } else {
              this.showServiceDetail = false;
              this.globalService.snackbar("error", response.message);
          }

      });
    }

    ngAfterViewInit(){

    }

    initialializeAddPartForm() {
        this.shopAddComment = this.formBuilder.group({
            service_complaint_id: this.service_complaint_id,
            service_complaint_customer_id: [''],
            user_type: this.globalService.getCurrentUser()["user_type"],
            user_id: this.globalService.getCurrentUser()["id"],
            complaint_comment: ['', Validators.required],
            complaint_resolver: [''],
            add_comment_request_id: [],
            is_comment: [true],
        })
    }

    addComment() {
        Object.keys(this.shopAddComment.controls).map((controlName) => {
        this.shopAddComment.get(controlName).markAsTouched({onlySelf: true});
        });
        if(this.shopAddComment.valid) {
            var stringifiedFormData = globalFunctions.stringifyFormData(this.shopAddComment.value,null);
            this.subscriptionDataForAPI =  this.globalService.callAPI(globalConstants.API_ADD_COMPLAINT_URL,stringifiedFormData).subscribe((data:any)=> {
                var message = data.message;
                if (data.result == "1") {
                    this.globalService.snackbar('success', message);
                    this.shopAddComment.controls.complaint_comment.reset();
                    this.ngOnInit();

                } else {
                    this.globalService.snackbar('error', message);
                }
              });
            //  this.router.navigate(["/shops"]);
        } else{
            this.mandatoryMsg();
        }
    }

    showTaxExempted(event) {
        if (event.checked) {
            this.shopAddComment.controls.complaint_resolver.setValue(true);
        } else {
            this.shopAddComment.controls.complaint_resolver.setValue(false);
        }
    }

    openDialog() {
        this.globalService.confirmDialog("cc_id", 'It\'s a demo popup please ignore this').subscribe((res) => {});
    }

    expansionOpen(ele) {
//        let element = document.getElementById('expansion-' + ele);
//        setTimeout(() => {
//            this.globalService.setScrolling(element, 700, -5);
//        }, 500);
    }

    filter(val: any): any[] {
            if(val.length!=undefined) {
            var filteredValue = this.partsOptions.filter(option => option.label.toLowerCase().indexOf(val.toLowerCase()) === 0);
            return filteredValue
            }
    }

    getStatus(statusId) {
        return this.statuses[statusId]
    }

    mandatoryMsg() {
        this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
     /* image rotation dialog  */
     imageToolDialog(current,i,type,imgaar): void {
        let dialogRef = this.dialog.open(ImagetooldialogComponent, {
            panelClass: ['imageToolDialog'],
            width: "100%",
            data: {event: current,index:i,imgType:type,imgArray:imgaar,customerId:this.globalService.getCurrentUser()["id"]}
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }
    getProperDate(value){
       return this.globalService.getFullFormateDate(value,'G','B');
    }
    getParams() {
        this.route.params.subscribe((params: Params) => {
          this.complaint_id = params["id"];
        });
    }

    ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
    } 
}
