import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalService } from '../../../../environments/global.service';
import { MatrixrangeComponent } from '../matrixrange/matrixrange.component';
import { MatDialog } from '@angular/material/dialog';
import { globalConstants } from '../../../../environments/globalConstants';
import { globalFunctions } from '../../../../environments/globalFunctions';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-partsmatrix',
  templateUrl: './partsmatrix.component.html',
  styleUrls: ['./partsmatrix.component.scss']
})
export class PartsmatrixComponent implements OnInit, OnDestroy {

  public user: any = null;
  public matrixForm: FormGroup;
  public priceRages = [];
  public adjMethod: any = { cost_limit: '0.00', margin_start: '0.00', margin_end: '0.00', margin_range: '0.00', adjustment_value: '0.00',above_cost_limit:'1' };
  public priceCostLimit: any = '0.00';
  public pricingMethod: any = {};
  public d = '';
  public enableSetting = false;
  public incrementValue = 0.01;
  public methodType=0;
  public price_set=0;
  public costMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: false,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 11,
    requireDecimal: false,
    allowNegative: false,
    allowLeadingZeroes: false
  });
  public marginMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: false,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 3,
    requireDecimal: false,
    allowNegative: false,
    allowLeadingZeroes: false
  });
  public aboveCostLimit:any=1;
  public subscriptionDataForAPI: Subscription = new Subscription();
  constructor(public router: Router, private ref: ChangeDetectorRef, public gs: GlobalService, private formBuilder: FormBuilder, public dialog: MatDialog) {
    this.user = this.gs.getCurrentUser();
    if (this.user) {
      this.gs.setMetaData("SHOPS", "PART_MATRIX_SETTING");

    } else {
      this.router.navigate(['shops']);
    }
    setTimeout(() => {
      let isEnable = this.gs.getAcl('parts_matrix', 'edits');
      if (!(isEnable)) {
        this.router.navigate(['shops/action/forbidden']);
      }
    }, globalConstants.RELOAD_WAIT);
  }

  ngOnInit() {
    this.initMatrixForm();
    let content = { user_id: this.user.id };
    this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_GET_PART_MATRIX, content).subscribe((res:any) => {
      if (res.result == '1') {
        this.matrixForm.controls.method_type.setValue(res.data.method);
        this.methodType=this.matrixForm.value.method_type;
        this.price_set=res.data.price_set;
        this.matrixForm.controls.price_set.setValue(res.data.price_set); 
        this.matrixForm.controls.part_matrix_status.setValue(res.data.status);
        if (res.data.status == '1') {
          this.enableSetting = true;
        }
        for (var i in this.adjMethod) {
          this.adjMethod[i] = (res.adj_method[i] ? res.adj_method[i]:'0.00');
        }
        if (res.pricing_method && typeof res.pricing_method.cost_limit != 'undefined') {
          this.priceCostLimit = res.pricing_method.cost_limit;
        }
        if (res.pricing_method && typeof res.pricing_method.above_cost_limit != 'undefined') {
          this.aboveCostLimit = res.pricing_method.above_cost_limit;
        }
        if (res.pricing_method && typeof res.pricing_method.cost_ranges != 'undefined') {
          this.priceRages = res.pricing_method.cost_ranges;
        }
        this.ref.detectChanges();
      }

    }, (error) => { })
  }

  initMatrixForm() {
    this.matrixForm = this.formBuilder.group({
      part_matrix_status: 0,
      method_type: [''],
      adj_method: [''],
      pricing_method: [''],
      price_set: [''],
      user_type: this.user.user_type,
      user_id: this.user.id
    })
  }

  onlyNumericKey(event) {
    return globalFunctions.onlyDecimalNumberKey(event);
  }

  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    this.d = event.srcElement.value;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  disableKey(event) {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey == true && (event.which == '67' || event.which == '86' || String.fromCharCode(event.which).toLowerCase() == 's')) {
      event.preventDefault();
    }
  }
  vkFloat(e, k) {

    if(k=='adjMethod'){
      for (var i in this.adjMethod) {
        let p=Number(this.adjMethod[i]);

        if(isNaN(p)){
          this.adjMethod[i] = '0.00';
        }
      }
      this.ref.detectChanges();
    }else{
      let output = Number(this[k]);
      let u=parseFloat(this[k]);
      let d=isNaN(Number(this.d));
      if (isNaN(output)) {
        if (d) {
          this[k] = (isNaN(u)?'0.00':u);
        } else {
          this[k] = (d?'0.00':this.d);
        }
      }
    }
  }
  matrixType(e, type) {
    this.matrixForm.controls.method_type.setValue(type);
    this.methodType=this.matrixForm.value.method_type;
  }

  priceType(e, type) {
    this.matrixForm.controls.price_set.setValue(type);
    this.price_set=this.matrixForm.value.price_set;
  }

  addRange() {
    let limit = parseFloat(this.priceCostLimit);

    if (limit <= 0 || isNaN(limit)) {
      this.gs.snackbar("error", 'Please enter part pricing method cost limit');
      return false;
    }
    let dialogRef = this.dialog.open(MatrixrangeComponent, {
      panelClass: ['car-dialog-form'],
      width: "500px",
      data: { editMode: false,incVal:this.incrementValue, limit: this.priceCostLimit, ranges: this.priceRages }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof dialogRef["range"] != 'undefined' && dialogRef["range"] && result == 1) {
        this.priceRages.push(dialogRef["range"]);
        this.ref.detectChanges();
      } else {

      }

    });
  }
  /** Calculate Adj.Method */
  calAdj() {
    let p: any = 0;
    let limit = parseFloat(this.adjMethod.cost_limit);
    let start=parseFloat(this.adjMethod.margin_start);
    let end=parseFloat(this.adjMethod.margin_end);
    let range = start - end;
    if(start >= 100){
      this.gs.snackbar("error", 'Margin start should be less than 100%.');
      return false;
    }else if(end >= start){
      this.gs.snackbar("error", 'Margin end should be less than margin start.');
      return false;
    }else if ((!isNaN(range)) && range >= 0) {
      this.adjMethod.margin_range = range.toFixed(10);
    } else {
      this.gs.snackbar("error", 'Margin start should be greater than margin end.');
      return false;
    }
    if (limit && (!isNaN(limit)) && limit > 0) {
      p = (((this.adjMethod.margin_range) / 100) / this.adjMethod.cost_limit).toFixed(10);
    } else {
      this.gs.snackbar("error", 'Please enter cost limit');
      return false;
    }
    this.adjMethod.adjustment_value = (p ? p : '0.00');
    return true;
  }

  submitMatrix(n) {
    
    if(this.enableSetting && !(this.methodType==1 || this.methodType==2)){
      this.gs.snackbar("error", 'Please choose one method');
      return false;
    }else if(this.enableSetting && this.methodType==1 && (!this.calAdj())){
      return false;
    }else if (this.enableSetting && this.methodType==2 && (!this.rangeValid())) {
      return false;
    }
    this.matrixForm.controls.adj_method.setValue(JSON.stringify(this.adjMethod));

    let priceMethods = {above_cost_limit:this.aboveCostLimit, cost_limit: this.priceCostLimit, cost_ranges: this.priceRages };

    this.matrixForm.controls.pricing_method.setValue(JSON.stringify(priceMethods));


    let partMatrixPrice = this.price_set; 
    this.matrixForm.controls.price_set.setValue(partMatrixPrice);


    let content = this.matrixForm.value;
    this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_EDIT_PART_MATRIX, content).subscribe((res:any) => {
      if (res.result == '1') {
        this.gs.snackbar("success", res.message);
      } else {
        this.gs.snackbar("error", res.message);
      }
    }, (error) => { });
  }

  rangeValid(type?:any) {
    let cost_ranges = this.priceRages;
    let limit = parseFloat(this.priceCostLimit);
    let lastLimit: any = 0;
    let startLimit: any = 0;

    if (limit <= 0 || isNaN(limit)) {
      this.gs.snackbar("error", 'Please enter part pricing method cost limit');
      return false;
    }
    if(type==1){
      return false;
    }
    if (cost_ranges.length) {
      lastLimit = cost_ranges[cost_ranges.length - 1].margin_end;
      startLimit = cost_ranges[0].margin_start;
      if (startLimit != this.incrementValue) {
        this.gs.snackbar("error", 'Part pricing method start should be ' + this.incrementValue + ' at cost range #1.');
        return false;
      } else if (limit != lastLimit) {
        this.gs.snackbar("error", 'Part pricing method end should be ' + limit + ' at cost range #' + cost_ranges.length + '.');
        return false;
      } else {
        let ps: any = 0, pe: any = 0;
        let valid = true;
        cost_ranges.forEach((r, i) => {
          if (!valid) {
            return ;
          }
          if (i == 0) {
            ps = r.margin_start;
            pe = r.margin_end;
          } else {
            let e: any= (parseFloat(pe) + this.incrementValue);
            if(!isNaN(e)){
              e=e.toFixed(2);
            }
            if (e == r.margin_start) {
              ps = r.margin_start;
              pe = r.margin_end;
            } else {
              this.gs.snackbar("error", 'Part Pricing Method - End range value in not valid in #' + i + '.');
              valid = false;
              return false;
            }
          }
        });
        if (!valid) {
          return false;
        }
      }
    } else {
      this.gs.snackbar("error", 'Please enter cost ranges in part pricing method');
      return false;
    }

    return true;
  }

  editRange(index) {
    let limit = parseFloat(this.priceCostLimit);

    if (limit <= 0 || isNaN(limit)) {
      this.gs.snackbar("error", 'Please enter part pricing method cost limit.');
      return false;
    }
    let dialogRef = this.dialog.open(MatrixrangeComponent, {
      panelClass: ['car-dialog-form'],
      width: "500px",
      data: { editMode: true, editIndex: index,incVal:this.incrementValue, limit: this.priceCostLimit, ranges: this.priceRages }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof dialogRef["range"] != 'undefined' && dialogRef["range"] && result == 1) {
        this.updateRange(dialogRef["range"], index);
        this.ref.detectChanges();
      }
    });
  }

  delRange(index) {
    this.gs.confirmDialog("yes", 'Are you sure?').subscribe((ress) => {
      let r: any = ress;
      if (r == 'yes') {
        this.priceRages.splice(index, 1);

        this.reArrangeCost();
        this.ref.detectChanges();
      }
    });
  }

  updateRange(range, i) {
    this.priceRages[i].margin_start = range.margin_start;
    this.priceRages[i].margin_end = range.margin_end;
    this.priceRages[i].markup = range.markup;
    this.reArrangeCost();
  }
  enbSetting(e) {
    if (e.checked) {
      this.matrixForm.controls.part_matrix_status.setValue('1');
    } else {
      this.matrixForm.controls.part_matrix_status.setValue('0');
    }
  }
  reArrangeCost() {

    let cost_ranges = this.priceRages;
    let ps: any = 0, pe: any = 0;
    let valid = true;
    cost_ranges.forEach((r, i) => {
      if (i == 0) {
        ps = r.margin_start;
        pe = r.margin_end;
      } else {
        let e: any = (parseFloat(pe) + this.incrementValue);
        if (!isNaN(e)) {
          e = e.toFixed(2);
        }
        if (e == r.margin_start) {
          ps = r.margin_start;
          pe = r.margin_end;
        } else {
          this.priceRages[i].margin_start = e;
          ps = r.margin_start;
          pe = r.margin_end;
          this.ref.detectChanges();
        }
      }
    });
    return valid;
  }
/** set above cost limit for Price Method */
  setAboveCostlimit(e,type){
    this.aboveCostLimit=type;
  }
/** set above cost limit for Adj. Method */
  adjAboveCostlimit(e,type){
    this.adjMethod.above_cost_limit=type;
  }

  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
} 
}
