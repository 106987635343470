import {Component, OnInit, ViewChild, Pipe, Inject} from '@angular/core';
import {AbstractControl, FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd,Params} from '@angular/router';
import {Http, Headers, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../../environments/globalFunctions"
import {globalConstants} from "../../../../environments/globalConstants"
import {GlobalService} from "../../../../environments/global.service"
import {lang} from "../../../../environments/lang-en"
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';
import {AppComponent } from "../../../app.component"

@Component({
  selector: 'app-payment-fail',
  templateUrl: './payment-fail.component.html',
  styleUrls: ['./payment-fail.component.scss']
})
export class PaymentFailComponent implements OnInit {

    public subscriptionMessage;
    constructor(public app:AppComponent,private activatedRoute: ActivatedRoute,private formBuilder: FormBuilder,public titleService: Title, private http: Http, private router: Router, private globalService: GlobalService) {
          this.subscriptionMessage = localStorage.getItem("paySuccessMsg");
    }

    ngOnInit() {
    }

}
