import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { globalConstants } from "../../../../environments/globalConstants";
import { GlobalService } from "../../../../environments/global.service";
import { LoaderService } from '../../../base/services/LoaderService';

@Component({
  selector: 'app-add-mobile-no',
  templateUrl: './add-mobile-no.component.html',
  styleUrls: ['./add-mobile-no.component.scss']
})
export class AddMobileNoComponent implements OnInit {

  public AddMobileNo: FormGroup;
  public currentUser: any;
  public shopId: number;
  public phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    public loaderService: LoaderService,
    public globalService: GlobalService) {

    if (localStorage.getItem("loggedInUser")) {
      this.currentUser = this.globalService.getCurrentUser();
      if (this.currentUser) {
        this.shopId = this.currentUser.shop_id;
      }
    } else {
      router.navigate(["/shops"]);
    }
    setTimeout(() => {
      let inSubscription: boolean = true;
      let isEnable = this.globalService.getAcl('sms_mobile_no_ignore', 'addNew');
      if (!(isEnable) || !(inSubscription)) {
        this.router.navigate(['shops/action/forbidden']);
      }
    }, globalConstants.RELOAD_WAIT);
  }

  ngOnInit() {
    this.loaderService.hide();
    this.initializeAddForm();
  }

  initializeAddForm() {
    this.AddMobileNo = this.formBuilder.group({
      name: ['Unknown', Validators.required],
      shop_id: [this.shopId, Validators.required],
      phone_number: ['', [Validators.required, Validators.minLength(10), this.globalService.mobileNumberValidation]],
      restore_phone_number: ['']
    });
  }

  onSubmit(isRestore = null) {
    if (this.AddMobileNo.valid) {
      let addNumberObj = this.AddMobileNo.value;
      addNumberObj.user_id = this.currentUser.id;
      addNumberObj.user_type = this.currentUser.user_type;
      if (isRestore == 1) {
        addNumberObj.restore_phone_number = '1';
      }
      var payload = this.globalService.formsData(addNumberObj);
      this.globalService.formData(globalConstants.API_SHOP_SMS_ADD_IGNORE_MOBILE_NO_URL, payload).subscribe((r) => {
        if (r.result == 1) {
          let message = r.message;
          this.router.navigate(['shops/mobile-no-ignore-list']);
          this.globalService.snackbar('success', message);
        } else {
          let message = r.message;
          if (r.code == 768) {
                this.globalService.snackbar('error', message);
          } else {
            this.globalService.snackbar('error', message);
          }
        }
      }, (error) => { })

    } else {
      this.globalService.validateAllFormFields(this.AddMobileNo);
    }
  }

}
