import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from '../../../../environments/global.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ViktableComponent, VikSource } from '../../../admin/viktable/viktable.component';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { globalConstants } from '../../../../environments/globalConstants';
const UPLOAD_LIST = "return-que-detail-";
@Component({
  selector: 'app-returnview',
  templateUrl: './returnview.component.html',
  styleUrls: ['./returnview.component.scss']
})
export class ReturnviewComponent extends ViktableComponent implements OnInit {

  public tablesData = this;
  displayedColumns = ['Name', 'partnumber', 'Quantity','in Stock','add_date'];
  public status = ['Pending', 'Move to local Inventory', 'Picked by Vendor', 'Return Processed'];
  dataSource: any;
  url = "";
  public options = {
    user_type: "User",
    user_id: "",
    search_keyword: "",
    item_id: "",
    start: 0,
    limit: 10
  }
  public user: any = {};
  public returnData:any={};
  public partsList:any=[];
  public returnQueId: any = '';
  public filePath=globalConstants.S3_BUCKET_URL+'Return-Queue-Docs/'+globalConstants.IMG_THUMB;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(public gs: GlobalService, public router: Router, private activatedRoute: ActivatedRoute) {
    super(globalConstants.API_GET_RETURN_QUEUE_DETAILS, gs);
    this.user = this.gs.getCurrentUser();
    if (this.user) {
      this.returnData = 'queDetail';
      this.sessionKey = UPLOAD_LIST;
      this.url = globalConstants.API_GET_RETURN_QUEUE_DETAILS;
      this.gs.setMetaData("SHOPS", "RETURN_QUEUE_DETAIL")
    } else {
      this.router.navigate(['shops']);
    }
   }

  ngOnInit() {
    this.getParam();
    this.initOptions();
  }

  ngAfterViewInit(){
    this.setDataTable();
  }
  getParam() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.returnQueId = params["id"];
    });
  }
  /** init options */
  initOptions() {
    this.options = this.gs.localGetterSetter(this.options, this.options, UPLOAD_LIST);
    this.options.item_id = this.returnQueId;
    this.options.user_type = this.user.user_type;
    this.options.user_id = this.user.id;
  }
  /** set data source of order que detail list */
  setDataTable() {
    this.dataSource = new VikSource(this.tablesData, this.sort, this.paginator);
    this.dataSource.getSortedData = this.sortedData;
  }
  /** create record, that show on order queue list */
  createRecordrecord(record) {
     let data= {
      part_number: record.part_number,
      part_id: record.part_id,
      name: record.name,
      quantity: record.quantity,
      request_number:record.request_number,
      statustxt: this.showStatus(record.status),
      status:record.status,
      modify_date:this.gs.getFullFormateDate(record.modify_date,'G','H'),
      add_date:this.gs.getFullFormateDate(record.add_date,'G','B')
    };
    this.resultData.push(data);
    return data;
  }
  /** sort order que list */
  sortedData(): any[] {
    let th: any = this;
    const data = th._exampleDatabase.data.slice();
    if (!th._sort.active || th._sort.direction == '') { return data; }
    return data.sort((a, b) => {
      let propertyA;
      let propertyB;
      switch (th._sort.active) {
        case 'Name': propertyA = a.name; propertyB = b.name; break;
        case 'Create At': propertyA = a.added_on; propertyB = b.added_on; break;
        default: return 0;
      }
      let valueA = isNaN(propertyA) ? propertyA.toUpperCase() : propertyA;
      let valueB = isNaN(propertyB) ? propertyB.toUpperCase() : propertyB;

      return (valueA < valueB ? -1 : 1) * (th._sort.direction == 'asc' ? 1 : -1);
    });
  }
  /** return que status */
  showStatus(s) {
    return (typeof this.status[s] == 'undefined' ? 'N/A' : this.status[s]);
  }

}
