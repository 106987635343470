<form class="form-horizontal form-control-normal job-approval-form" autocomplete="off" [formGroup]="jobApprovalForm">
  
  <div mat-dialog-title class="relative bg-modal">    
   <div class=" col-xs-12">
    <div class="row">
      <div class="col-xs-6 col-md-6 col-lg-6 job-approval">
      <h4 class="job-heading"> Job Approval <span class="sr-id hidden-xs"> - #{{data.fullRequestId}}</span></h4>
     </div>   
     <div class="col-xs-6 col-md-6 col-lg-6 btn-right hidden-xs">
      <button type="button" class="btn btn-modal" title="Accept All" (click)="acceptAll()" [ngClass]="disableAcceptAndRejectButton == true ? 'disableButton' : ''">Accept All</button>
      <button type="button" class="btn btn-modal" title="Reject All" (click)="rejectAll()" [ngClass]="disableAcceptAndRejectButton == true ? 'disableButton' : ''">Reject All</button>
      <button type="button" *ngIf="canUsePrevious()" title="Use Previous" (click)="applyPrevious()" class="btn btn-modal">Use Previous</button>
     </div>
     <div class="col-xs-6 col-md-6 col-lg-6 visible-xs padding-right-40">
     <div class="dropdown">
      <button class="dropbtn"  [ngClass]="disableAcceptAndRejectButton == true ? 'disableButton' : ''">Actions
        <i class="fa fa-angle-down drop-caret"></i>
      </button>
      <div class="dropdown-content">
        <a href="javascript:void(0)" title="Accept All" (click)="acceptAll()" [ngClass]="disableAcceptAndRejectButton == true ? 'disableButton' : ''">Accept All</a>
        <a href="javascript:void(0)" title="Reject All" (click)="rejectAll()" [ngClass]="disableAcceptAndRejectButton == true ? 'disableButton' : ''">Reject All</a>
        <a href="javascript:void(0)" title="Use Previous" *ngIf="canUsePrevious()" (click)="applyPrevious()">Use Previous</a>
      </div>
    </div>
    </div>
    </div>
   </div>
   <button mat-mini-fab (click)="closeDialog()" class="close-modal mat-elevation-z0 jobApproval-close">
    <mat-icon class="notranslate close-x" aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
  </div>
 <div class="modal-content-block">
    <mat-dialog-content>
    <div class="center-block">
      <mat-accordion #parentAccordion="matAccordion"
        class="job-expansion view-request show-price display-full" displayMode="flat" hideToggle="false"
        [multi]="true">
        <div class="srId-show visible-xs">Request Id - #{{data.fullRequestId}}</div>
        <mat-expansion-panel hideToggle="true" [disabled]="true"
          *ngFor="let recom of serviceList;let a=index" id="job-expansion-{{a+1}}">
          <mat-expansion-panel-header id="job-expansionHead-history-{{a+1}}"
            [attr.data-state]="a==0">
            <div class="row display-full hidden-xs">
              <div class="col-xs-12 col-lg-12 col-md-6"> 
                  <p class="job-detail">
                    {{ recom.category_name || 'N/A' }}
                  </p> 
              </div>
             <div class="row">
                <div class="col-xs-6 col-lg-6 col-md-3">                 
                  <p class="job-total">
                    Job Total : <b> $</b> {{ toFormattedDecimal(recom.job_total,2) || 'N/A' }}
                  </p>                 
              </div>
              <div class="col-xs-6 col-lg-6 col-md-3 text-right padding-0">
                <button title="{{ recomStates[recom.id].acp_text }}" (click)="toggleJobApproval(recom.id,recom.level1_id,'accept')" class="btn btn-success">{{ recomStates[recom.id].acp_text }}</button>
                <button title="{{ recomStates[recom.id].rej_text }}" (click)="toggleJobApproval(recom.id,recom.level1_id,'reject')" class="btn btn-danger">{{ recomStates[recom.id].rej_text }}</button>
              </div>
             </div>
            </div>
            <div class="row visible-xs">
              <div class="col-xs-12 col-md-12 margin-top-5 fs-12">
                <span class="help-block-light margin-0">
                  {{ recom.category_name || 'N/A' }}
                </span>
              </div>
              <div class="col-xs-4 col-md-6 margin-top-5 fs-12"> 
                <p class="no-padding">  <b> $</b> {{ toFormattedDecimal(recom.job_total,2) || 'N/A' }}  </p> 
              </div>
              <div class="col-xs-8 col-md-6 margin-top-5 fs-12 text-right"> 
                  <button title="{{ recomStates[recom.id].acp_text }}" (click)="toggleJobApproval(recom.id,recom.level1_id,'accept')" class="btn btn-success">{{ recomStates[recom.id].acp_text }}</button>
                  <button title="{{ recomStates[recom.id].rej_text }}" (click)="toggleJobApproval(recom.id,recom.level1_id,'reject')" class="btn btn-danger">{{ recomStates[recom.id].rej_text }}</button>
                </div>              
            </div>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
      </mat-accordion>
      <div *ngIf="disableAcceptAndRejectButton" class="noJobStyle">No Jobs Found</div>

    <h4 class="text-right sm"><strong> Sub Total : </strong>$ <span class="sub-total sm">{{toFormattedDecimal(subTotal,2 || 'N/A')}}</span></h4>
    <h4 class="text-right sm" *ngIf="supplyCharges > 0" ><strong> Supply Charges : </strong>$ <span class="discount sm">{{toFormattedDecimal(supplyCharges,2) || 'N/A' }}</span></h4>
    <h4 class="text-right sm"><strong> Discount : </strong>$ <span class="discount sm">{{toFormattedDecimal(discount,2) || 'N/A' }}</span></h4>
    <h4 class="text-right sm"><strong> Tax : ({{taxRate}}% : $<span class="tax sm">{{toFormattedDecimal(taxAmount,2) || 'N/A'}})</span></strong></h4>
    <h4 class="text-right sm"><strong> Grand Total : </strong> $<span class="grand-total sm">{{toFormattedDecimal(finalShowAbleAmount,2) || 'N/A'}}</span></h4>
    <p class="text-right xsfont">Note: Grand total includes supply charges</p>
    

    </div>
  </mat-dialog-content>
 </div>
  <mat-dialog-actions class="inquiry-action relative jobApproval-submit">
    <div class="flexBtns2">
      <button mat-raised-button title="Apply Changes" color="accent" (click)="applyChanges()" class="font-bold">Apply Changes</button>
      <button mat-raised-button title="Close" (click)="closeDialog()" class="font-bold close-button-bottom">Close</button>
    </div>
  </mat-dialog-actions>
</form>