<div id="signupContent" class="bgcolor-lightgrey form-content registration-component">
  <div class="container">
    <h1 class="text-center main-heading">ACCOUNT SIGNUP</h1>
    <form [formGroup]="shop" class="form-inline col-xs-12 signup-form" role="form" (ngSubmit)="signUp()">
      <div class="running-line-left hidden-xs"></div>
      <div class="row margin-0">
        
        <div class="col-xs-12 text-right form-block login-link">
          <h5>Already have an account?
            <a [routerLink]="['/shops']" class="text-link">Login Here</a>
          </h5>
        </div>
        <div class="col-sm-12 padding-sm-right-0">
          <div class="row flex-wrap">
            <div class="col-sm-12 col-md-6 name-email-wrap">
              <div class="row">
                <div class="col-xs-12 form-block">
                  <h2 class="fontcolor-blue sub-heading"><div class="heading-icon hidden-xs"><i class="fa fa-car fa-fw"></i></div>Shop Name</h2>
                  <div class="row">
                    <div class="form-group col-sm-12">
                      <div class='relative'>
                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                          <input matInput placeholder="Shop Name*" maxlength="200" title="Shop Name" formControlName="shop_name" class="form-control input-lg custom-form-input mat-form-field-placeholder"
                            (keyup)="checkIfValid()" />
                          <mat-error *ngIf="shop.controls.shop_name.hasError('required')">
                            Shop Name is <strong>required</strong>
                          </mat-error>
                        </mat-form-field>
          
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 form-block">
                  <h2 class="fontcolor-blue sub-heading">
                      <div class="heading-icon hidden-xs"><i class="fa fa-fw fa-lock"></i></div>LOGIN INFORMATION</h2>
                  <div class="row">
                    <div class="form-group col-sm-12">
                      <div class='relative'>
                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                          <input matInput placeholder="Email Address*" maxlength="200" title="Email" formControlName="email" class="form-control input-lg custom-form-input mat-form-field-placeholder"
                            (keyup)="checkIfValid()" />
                          <mat-error *ngIf="shop.controls.email.hasError('pattern')">
                            Please enter a valid Email address
                          </mat-error>
                          <mat-error *ngIf="shop.controls.email.hasError('required')">
                            Email Address is
                            <strong>required</strong>
                          </mat-error>
                        </mat-form-field>
          
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 border plan-detals-wrap">
              <app-subscription-plan-preview class="row"></app-subscription-plan-preview>
            </div>
          </div>
        </div>
        <div class="col-xs-12 form-block personal-info-container padding-sm-right-0">
          <h2 class="fontcolor-blue sub-heading"><div class="heading-icon hidden-xs"><i class="fa fa-fw fa-user"></i></div>PERSONAL INFORMATION</h2>
          <div class="row">
  
            <div class="form-group col-md-4 col-sm-12">
              <div class='relative'>
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="First Name*" title="First Name" maxlength="100" formControlName="first_name" class="form-control input-lg custom-form-input mat-form-field-placeholder"
                    (keyup)="checkIfValid()" />
                  <mat-error *ngIf="shop.controls.first_name.hasError('required')">
                    First Name is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="shop.controls.first_name.hasError('pattern')">
                   Must not contain any special characters.
                  </mat-error>
                </mat-form-field>
  
              </div>
            </div>
  
            <div class="form-group col-md-4 col-sm-12">
              <div class='relative'>
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Middle Name" maxlength="100" title="Middle Name" formControlName="middle_name" class="form-control input-lg custom-form-input mat-form-field-placeholder"
                  />
                  <mat-error *ngIf="shop.controls.middle_name.hasError('pattern')">
                    Must not contain any special characters.
                  </mat-error>
                </mat-form-field>
  
              </div>
            </div>
  
            <div class="form-group col-md-4 col-sm-12">
              <div class='relative'>
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Last Name*" title="Last Name" formControlName="last_name" maxlength="100" class="form-control input-lg custom-form-input mat-form-field-placeholder"
                    (keyup)="checkIfValid()" />
                  <mat-error *ngIf="shop.controls.last_name.hasError('required')">
                    Last Name is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="shop.controls.last_name.hasError('pattern')">
                    Must not contain any special characters.
                  </mat-error>
                </mat-form-field>
  
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 form-block contact-info-container padding-sm-right-0">
          <h2 class="fontcolor-blue sub-heading"><div class="heading-icon hidden-xs"><i class="fa fa-fw fa-map-marker"></i></div>CONTACT INFORMATION</h2>
          <div class="row">
            <div class="form-group col-sm-12 col-md-6">
              <div class='relative'>
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Address Line 1*" title="Address Line 1" maxlength="200" formControlName="address_one" class="form-control input-lg custom-form-input mat-form-field-placeholder"
                    (keyup)="checkIfValid()" />
                  <mat-error *ngIf="shop.controls.address_one.hasError('required')">
                    Address Line 1 is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
  
              </div>
            </div>
            <div class="form-group col-sm-12 col-md-6">
              <div class='relative'>
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Address Line 2" title="Address 2" maxlength="200" formControlName="address_two" class="form-control input-lg custom-form-input mat-form-field-placeholder"
                  />
                </mat-form-field>
  
              </div>
            </div>
          </div>
  
          <div class="row">
            <div class="form-group col-sm-12 col-md-6">
              <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                <input matInput 
                placeholder="{{zipLabel}}*" 
                title="{{zipLabel}}" 
                #zipcode 
                (input)="zipcode.value = zipcode.value.toUpperCase()"
                formControlName="zipcode" 
                class="form-control input-lg custom-form-input mat-form-field-placeholder"
                maxlength="15" 
                (keyup)="checkIfValid()" 
                (blur)="setCountryStateCityViaZipCode()"
                 />
                <mat-error *ngIf="shop.controls.zipcode.hasError('pattern')">
                  Please enter valid {{zipLabel.toLowerCase()}}
                </mat-error>
                <mat-error *ngIf="shop.controls.zipcode.hasError('required')">
                  {{zipLabel}} is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
  
            <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
              <ngx-select class="display-full margin-bottom-20" [items]="countries" [(ngModel)]="selectedCountry"
                [ngModelOptions]="{standalone: true}" (select)="handleCountryDropdownChange($event)" placeholder="Select Country">
              </ngx-select>
            </div>
          </div>
  
          <div class="row">
            <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
              <ngx-select class="display-full margin-bottom-20" [items]="states" [(ngModel)]="selectedState"
                [ngModelOptions]="{standalone: true}" (select)="handleStateDropdownChange($event)" [disabled]="disableStates"
                placeholder="Select State">
              </ngx-select>
              <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper" *ngIf="shop.controls.state.hasError('required') && shop.get('state').touched">
                <mat-error >
                  State is <strong>required</strong>
                </mat-error>
              </div>
            </div>
  
            <div class="form-group col-sm-12 col-md-6 uc-ngx-select mat-form-field-invalid">
              <ngx-select class="display-full margin-bottom-20" [items]="cities" [(ngModel)]="selectedCity"
                [ngModelOptions]="{standalone: true}" (select)="handleCityDropdownChange($event)" [disabled]="disableCities"
                placeholder="Select City">
              </ngx-select>
              <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper" *ngIf="shop.controls.city.hasError('required') && shop.get('city').touched">
                <mat-error >
                  City is <strong>required</strong>
                </mat-error>
              </div>
            </div>
          </div>
  
          <div class="row">
            <div class="form-group col-sm-12 col-md-6">
              <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                <input matInput (keyup)="phoneValidation('shop_business_phone1')" placeholder="Business Phone 1*" title="Business Phone 1"
                  formControlName="shop_business_phone1" class="form-control input-lg custom-form-input mat-form-field-placeholder" maxlength="15" (keyup)="checkIfValid()"
                  [textMask]="{mask: phoneMask}" />
                <mat-error *ngIf="shop.controls.shop_business_phone1.hasError('required')">
                  Business Phone 1 is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="shop.controls.shop_business_phone1.hasError('shop_business_phone1')">
                  Invalid Business Phone 1
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group col-sm-12 col-md-6">
              <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                <input matInput (keyup)="phoneValidation('shop_business_phone2')" placeholder="Business Phone 2" title="Business Phone 2"
                  formControlName="shop_business_phone2" class="form-control input-lg custom-form-input mat-form-field-placeholder" maxlength="15" [textMask]="{mask: phoneMask}"
                />
                <mat-error *ngIf="shop.controls.shop_business_phone2.hasError('shop_business_phone2')">
                  Invalid Business Phone 2  
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-12 col-md-6">
              <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                <input matInput (keyup)="phoneValidation('shop_cell_phone1')" placeholder="Cell Phone 1" title="Cell Phone 1" formControlName="shop_cell_phone1"
                  class="form-control input-lg custom-form-input mat-form-field-placeholder mat-form-field-placeholder" maxlength="15" [textMask]="{mask: phoneMask}" />
                <mat-error *ngIf="shop.controls.shop_cell_phone1.hasError('shop_cell_phone1')">
                  Invalid Cell Phone 1
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group col-sm-12 col-md-6">
              <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                <input matInput (keyup)="phoneValidation('shop_cell_phone2')" placeholder="Cell Phone 2" title="Cell Phone 2" formControlName="shop_cell_phone2"
                  class="form-control input-lg custom-form-input mat-form-field-placeholder" maxlength="15" [textMask]="{mask: phoneMask}" />
                <mat-error *ngIf="shop.controls.shop_cell_phone2.hasError('shop_cell_phone2')">
                  Invalid Cell Phone 2
                </mat-error>
              </mat-form-field>
            </div>
  
          </div>
          <div class="row">
            <div class="form-group col-sm-12 col-md-6">
              <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                <input matInput placeholder="Fax" (keyup)="phoneValidation('shop_fax')" title="Fax" formControlName="shop_fax" class="form-control input-lg custom-form-input mat-form-field-placeholder"
                  maxlength="15" [textMask]="{mask: faxMask}" />
                  <mat-error *ngIf="shop.controls.shop_fax.hasError('shop_fax')">
                    Invalid Fax number
                  </mat-error>
              </mat-form-field>
            </div>
            
          </div>
          <div class="row">
            <div class="form-group col-sm-12 col-md-6">
              <div class="col-sm-12" style="padding:0px;">
                <label for="partPic">Business Logo</label>
              </div>
              <div class="col-sm-12 file-upload-container upload-image" style="padding:0px;">
                <input id="businessLogoImagedata" name="business_logo_imagedata" value="" type="hidden" formControlName="business_logo_imagedata">
                <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative">
                  <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                  Drag File Here
                  <input type="file" id="businessLogo" name="business_logo" accept="image/*" (change)="getFile($event,'business_logo_imagedata')"
                    formControlname="business_logo_imagedata" />
                  <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                </mat-toolbar>
                <ul id="vehicle_pic-ul" class="list-inline margin-bottom-0" *ngIf="currentBusinesslogo!=''">
                  <li class="relative view-images" style="vertical-align: top;">
                    <a href="javascript:void(0);" class="thumbnail">
                      <img class="show-image" src="{{currentBusinesslogo}}" />
                      <i class="fa fa-trash remove-img" (click)="deleteBusinessLogo()"></i>
                    </a>
                  </li>
                </ul>
                <p class="help-block" style="margin:0px;">Formats supported : png, jpeg, jpg</p>
                <p class="help-block" style="margin:0px;">Maximum size : 10MB</p>
              </div>
  
            </div>
            <div class="form-group col-sm-12 col-md-6 col-xs-12">
              <re-captcha siteKey="{{site_key}}" (resolved)="handleReCaptchaResponse($event)" formControlName="g_recaptcha_response" ngDefaultControl></re-captcha>
              <mat-error class="validation-error" *ngIf="submitted && shop.controls.g_recaptcha_response.hasError('required')">
                reCaptcha is <strong>required</strong>
              </mat-error>
            </div>
          </div>
          
        </div>
        <div class="col-xs-12 col-sm-6 form-block submit-block padding-sm-right-0">
          <div class="heading-icon hidden-xs">
            <i class="fa fa-fw fa-check fontcolor-blue"></i>
          </div>
          <!-- <label class="checkbox-bigger"> <input type="checkbox" [(ngModel)]="termAndCond" [ngModelOptions]="{standalone: true}" (change)="checkIfValid()"  [ngClass]="{'checked-background': termAndCond}"> You agree to our <a href="javascript:;"  class="text-link" (click)="termAndCondition()"> Terms & Conditions</a>
          </label> -->
          <mat-checkbox  (change)="handleCheckTermsAndCondition($event.checked)"> You agree to our <a href="javascript:;"  class="text-link" (click)="termAndCondition()"> Terms & Conditions</a></mat-checkbox>
          <mat-error class="validation-error" *ngIf="shop.controls.terms_condition.hasError('required') && shop.get('terms_condition').touched">
            Please Accept the <strong>Terms & Conditions</strong> 
          </mat-error>
          <button type="submit" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent"
            title="Submit">SUBMIT</button>
        </div>
      </div>
     
    </form>
  </div>
</div>