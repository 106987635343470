import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termandcond',
  templateUrl: './termandcond.component.html'
})
export class TermandcondComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
