<div class="popup-sidebar-wrapper">
  <mat-drawer-container class="side-panel-wrappers ticket-assignment business-report" autosize end>
    <mat-drawer #excludedVehicesDrawer class="ticket-sidenav" mode="over" position="end">
      <!-- header section -->
      <h4><span>Add Vehicles To Excluded List</span></h4>
     <div class="border-box">
        <div class="btn-block header" >
            <ul class="padding-0" style="float: right;">
                <li>
                  <button type="button" class="btn skip-btn " (click)="closeSideBar(true)" [disabled]="!isAnySelected" title="Submit"><i class="fa fa-check" aria-hidden="true"></i></button>
                </li>
                <li>
                  <button type="button" class="btn skip-btn " (click)="clearVehileSelection()" title="Clear"><i class="fa fa-trash" aria-hidden="true"></i></button>
                </li>
                <li>
                  <button type="button" class="btn skip-btn " (click)="closeSideBar()" title="Cancel"><i class="fa fa-times" aria-hidden="true"></i></button>
                </li>
            </ul>
        </div>
          <!-- <div class="cross-btn">
            <button type="button" (click)="closeSideBar()">
              <div class="desktop-screen">x</div>
            </button>
          </div> -->
          <!-- content section -->
          <div class="assignment-block" >
            <form id="advanceSearchForm" [formGroup]="searchForm" (ngSubmit)="search()">
              <div class="row">
                <div class="col-md-4 col-sm-12 details-input">
                  <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput placeholder="License Plate" title="License Plate"
                    formControlName="license_plate"
                    class=" input-lg custom-form-input" />
                  </mat-form-field>
                </div>            
                <div class="col-md-4 col-sm-12 details-input">
                  <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput placeholder="VIN" title="VIN"
                    formControlName="vin"
                    class=" input-lg custom-form-input" />
                  </mat-form-field>
                </div>
                <div class="col-md-4 col-sm-12 details-input">
                  <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput placeholder="Customer Name" title="Customer Name"
                    formControlName="customer_name"
                    class=" input-lg custom-form-input" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row"> 
                <div class="col-md-4 col-sm-12 details-input">
                  <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput placeholder="Customer Email" title="Customer Email"
                    formControlName="customer_email"
                    class=" input-lg custom-form-input" />
                  </mat-form-field>
                </div>
                <div class="col-md-4 col-sm-12 details-input">
                  <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput placeholder="Customer Phone" title="Customer Phone"
                    formControlName="customer_mobile"
                    class=" input-lg custom-form-input" />
                  </mat-form-field>
                </div>
                <div class="col-md-4 col-sm-12 details-input">
                  <button mat-raised-button color="accent" type="submit">Search</button>
                  <!-- <button type="button" class="btn skip-btn">Clear</button>   -->
                </div>
              </div>
            </form>
            <div class="row margin-left-0 padding-top-10">
              <mat-chip-list class="mat-badge">
                <mat-chip *ngFor="let selected of selectedVehicle; let i = index" [selectable]="selectable">
                  {{selected.license_plate?selected.license_plate:(selected.vin?selected.vin:selected.Vehicle_id)}}
                  <mat-icon style="font-size: 20px;" (click)="removeSelected(i)">cancel</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </div>
            <div class="row">
              <div class="col-md-12 col-sm-12 bs-table">
                <table class="table table-striped add-vehicles padding-top-10 margin-bottom-0">
                    <thead>
                        <tr>
                            <th>Select</th>
                            <th>License Plate</th>
                            <th>VIN</th>
                            <th>State</th>
                            <th>Customer Name</th>
                            <th>Mobile</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="no-data-wrapper" *ngIf="allVehicleList.length === 0">
                          <td colspan="7" class="no-data-found text-center">No Records Available</td>
                         </tr>
                        <tr *ngFor="let vehicle of allVehicleList;let i = index">
                          <td><mat-checkbox [checked]="vehicle.status" (change)="updateSelection(i)"></mat-checkbox></td>
                          <td>{{vehicle.license_plate}}</td>
                          <td>{{vehicle.vin}}</td>
                          <td>{{vehicle.state}}</td>
                          <td>{{vehicle.customer_fullname}}</td>
                          <td style="white-space: nowrap;">{{vehicle.mobile_phone}}</td>
                        </tr>
                    </tbody>
                </table>
              </div>
            </div>
            <div class="display-full relative" [style.display]="recordsTotal < 1 ? 'none' : ''" style="border-top:1px solid #ccc;">
              <mat-paginator class="car-pagination" #paginator [length]="recordsTotal" [pageIndex]="0" [pageSize]="advancedOptions.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event)" >
             </mat-paginator>
    
             <div class="mat-paginator table-paging">
                 <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{advancedOptions.start+1}} - {{getUpperLimit(advancedOptions.start + advancedOptions.limit,recordsTotal)}} of {{recordsTotal}}</div>
                 <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                         <mat-icon>navigate_before</mat-icon>
                 </button>
                 <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                         <mat-icon>navigate_next</mat-icon>
                 </button>
             </div>
            </div>
          </div>
          <!-- Footer section -->
          <div class="footer">
            <div class="row">
              <div class="col-sm-2 col-xs-12"></div>
              <div class="col-sm-10 col-xs-12">
                <div class="btn-block" >
                  <ul>
                    <li>
                      <button type="button" class="btn skip-btn submit" (click)="closeSideBar(true)" [disabled]="!isAnySelected">Submit</button>
                    </li>
                    <li>
                      <button type="button" class="btn skip-btn clear" (click)="clearVehileSelection()">Clear</button>
                    </li>
                    <li>
                      <button type="button" class="btn skip-btn cancel" (click)="closeSideBar()">Cancel</button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
     </div>
    </mat-drawer>
  </mat-drawer-container>
</div>