<div class="panel panel-primary relative" id="mainCompotents">
    <div class="panel-heading padding-left-30">
        <h3 class="panel-title">PARTS LIST</h3>
        <button mat-mini-fab class="custom-btn-back list-smart-batch-button btn-right" title="Add New Part"  (click)="addNewPart();">
            <i class="fa fa-fw fa-plus margin-right-5"></i><span class="hidden-xs">ADD NEW PART</span>
        </button>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <div class="car-datatable mat-elevation-z1">
            <div class="table-responsive relative">
                <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg">

                    <!-- Color Column -->
                    <ng-container matColumnDef="Name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Name </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto"> {{row.name}} </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="Part Number">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Part Number </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto"> {{(row.part_number)}} </mat-cell>
                    </ng-container>

                    <!-- Color Colu`mn -->
                    <ng-container matColumnDef="Price">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="price"> Price </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="price">
                            <span *ngIf="showCostPrice">{{row.price}}</span>
                            <span *ngIf="!showCostPrice">-</span>
                        </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="Description">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Quantity </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto">
                            <strong [class.text-red]="row.is_low_stock">{{showWholeNumber(row.quantity)}}&nbsp;({{showWholeNumber(row.low_stock_value)}})</strong>
                            <button (click)="addStockOrder(row)" *ngIf="addTostockOrder && row.is_low_stock" class="btn-link text-red" title="Add to stock order"><i class="fa fa-cart-plus fa-lg" ></i></button>
                        </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="Picture">
                        <mat-header-cell *matHeaderCellDef  data-role="pic"> Picture </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="pic"> <img style="max-width:85px;max-height:75px;padding:5px 0;" *ngIf="row.picture !='' && row.picture !=null" src="{{partPicture}}{{row.picture}}" /> </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Added on">
                        <mat-header-cell *matHeaderCellDef mat-sort-header  data-role="date"> Added on </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="date"> {{row.added_on }} </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="Actions">
                        <mat-header-cell *matHeaderCellDef data-role="button"> Actions </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="button">
                            <button *ngIf="isAllowed('edits') || isAllowed('deletes')" mat-button class="list-action-btn" [matMenuTriggerFor]="menu" disableRipple >
                                Action<mat-icon>arrow_drop_down</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu" class="car-icon-menu" [overlapTrigger]="false">
                                <a class="mat-menu-item" *ngIf="isAllowed('edits') && row.delete_status=='0'" (click)="editPart(row.part_id)"><i class="fa fa-edit"></i>Edit</a>
                                <a class="mat-menu-item" *ngIf="isAllowed('edits') && row.delete_status=='1'" (click)="enablePart(row.part_id)"><i class="fa fa-edit"></i>Enable</a>
                                <a class="mat-menu-item" *ngIf="isAllowed('views') && row.delete_status=='0'" (click)="viewLog(row.part_id)"><i class="fa fa-eye"></i>Logs</a>
                            </mat-menu>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
                <div class="padding-15 text-center"
                     [style.display]="getTotalRecords() < 1 ? '' : 'none'">
                     No Records Available
            </div>
        </div>
        <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
            <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="options.limit" [pageSizeOptions]="[5, 10,25, 100]" (page)="paginateByLimit($event)" >
            </mat-paginator>

            <div class="mat-paginator table-paging">
                <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
                <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                        <mat-icon>navigate_before</mat-icon>
                </button>
                <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                        <mat-icon>navigate_next</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
</div>
