import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BottomSheetHelperComponent } from '../../../base/helpers/bottom-sheet-helper/bottom-sheet-helper.component';

import { MatBottomSheet } from '@angular/material/bottom-sheet';
@Component({
  selector: 'app-shopsrcolumnview',
  templateUrl: './shopsrcolumnview.component.html',
  styleUrls: ['./shopsrcolumnview.component.scss'],
 

})
export class ShopsrcolumnviewComponent implements OnInit {
  @ViewChild(TemplateRef) template: TemplateRef<any>;
  /* SEARCH BY STATUS ARRAY */
searchByStatus = [
    // { id: "", text: "Select Status"},
    { id: "11", text: "SR Workflow"},
    { id: "1", text: "My job Queue"},
    { id: "2", text: "On the lot"},
];

toppings = new FormControl('');
toppingList: string[] = ['Appointments','Pending','Processing','Completed'];

toppingsTemp = new FormControl('');
toppingListTemp: string[] = ['Process', 'Completed'];

toppingsQuickaction = new FormControl('');
toppingListQuickaction: string[] = ['Assign to me', 'Today', 'Week', 'Month', 'Date Range'];
  constructor(
    private bottomSheet: MatBottomSheet
  ) { }

  ngOnInit(): void {
  }

  todo = [
    '#CAR2023081255913',
    '#CAR2023081255914',
    '#CAR2023081255913',
    '#CAR2023081255913'
  ];

  done = [
    '#CAR2023081255913',
    '#CAR2023081255913',
    '#CAR2023081255913',
    '#CAR2023081255913',
  ];

  work = [
    '#CAR2023081255913',
    '#CAR2023081255913',
    '#CAR2023081255913',
    '#CAR2023081255913',
    '#CAR2023081255913',
    '#CAR2023081255913',
    '#CAR2023081255913',
    '#CAR2023081255913',
    '#CAR2023081255913',
    '#CAR2023081255913',
    '#CAR2023081255913',
    '#CAR2023081255913',
    
  ];

  git = [
    '#CAR2023081255913',
    '#CAR2023081255913',
    '#CAR2023081255913',
    '#CAR2023081255913',
  ];

  paid=[
    '#CAR2023081255913',
    '#CAR2023081255913',
    '#CAR2023081255913',
    '#CAR2023081255913',
  ];
  onthelot=[
    '#CAR2023081255913',
    '#CAR2023081255913',
    '#CAR2023081255913',
    '#CAR2023081255913',
  ];
  invoiced=[
    '#CAR2023081255913',
    '#CAR2023081255913',
    '#CAR2023081255913',
    '#CAR2023081255913',
  ];

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }

  openBottomSheet() {
    return this.bottomSheet.open(BottomSheetHelperComponent);
  }
}
