import { Component, OnInit, ViewChild,OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap,NavigationEnd, Params  } from '@angular/router';
import { Http,Headers, Response} from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { globalFunctions } from "../../../../environments/globalFunctions";
import { globalConstants } from "../../../../environments/globalConstants";
import {GlobalService} from "../../../../environments/global.service";
import { lang } from "../../../../environments/lang-en";
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TextMaskModule } from 'angular2-text-mask';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppComponent } from '../../../app.component';

@Component({
  selector: 'app-editshopresource',
  templateUrl: './editshopresource.component.html',
  styleUrls: ['./editshopresource.component.scss']
})
export class EditshopresourceComponent implements OnInit,OnDestroy {
    public currentCustomer;
    public currentCustomerName;
    public isCollapsed:boolean = true;
    public editResourceForm:FormGroup;
    public idFromUrl;
    public currentResourceDetails;
    public resourceName;
    public resourceLink;
    public leftMenuStatus:boolean;
    public subscriptionDataForAPI: Subscription = new Subscription();

    constructor(private app:AppComponent,private activatedRoute:ActivatedRoute,private formBuilder: FormBuilder,private route: ActivatedRoute,public titleService:Title,private http: Http,private router: Router,public snackBar: MatSnackBar, public globalService: GlobalService){
        if(localStorage.getItem("loggedInUser")) {
            this.setParams();
            this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
            this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
            this.editResourceForm = this.formBuilder.group({
                resource_name: ['', [Validators.required]],
                //resource_link: ['', [Validators.required, Validators.pattern("https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,}")]],
                resource_link: ['', [Validators.required,Validators.pattern(globalConstants.WEB_URL_REGEX)]],
                user_type: this.currentCustomer.user_type,
                user_id: this.currentCustomer.id,
                shop_id: this.currentCustomer.shop_id,
                resource_id: this.idFromUrl,
                left_menu_status: [''],
            })

        } else {
            this.router.navigate(["/shops"]);
        }
        setTimeout(()=>{
        let isEnable=this.globalService.getAcl('resources','edits');
        if(!(isEnable)){
            this.router.navigate(['shops/action/forbidden']);
        }
    },globalConstants.RELOAD_WAIT)
    this.globalService.setMetaData("SHOPS","EDIT_RESOURCE")
    }

    setParams(){
      this.activatedRoute.params.subscribe((params: Params) => {
          this.idFromUrl = params["resource_id"];
       });
    }

    ngOnInit(){
        this.setParams();

        var shopContent = "user_id="+this.currentCustomer.id+"&user_type="+this.currentCustomer.user_type+"&resource_id="+ this.idFromUrl;

        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_RESOURCE_LIST_URL,shopContent).subscribe((data:any) => {
            this.currentResourceDetails = data.data;
            this.resourceName = this.currentResourceDetails.resource_name;
            this.resourceLink = this.currentResourceDetails.resource_link;
            if (this.currentResourceDetails.menu_status == '1') {
                this.leftMenuStatus = true;
                this.editResourceForm.controls.left_menu_status.setValue(1);
            } else {
                this.leftMenuStatus = false;
                this.editResourceForm.controls.left_menu_status.setValue(0);
            }
        });
    }


    editResource() {
        if(this.editResourceForm.valid) {
            var stringifiedData =this.globalService.formsData(this.editResourceForm.value);
            this.globalService.formData(globalConstants.API_EDIT_RESOURCE_URL,stringifiedData).subscribe((data:any)=> {
                var message = data.message;
                if (data.result == "1") {
                    this.router.navigate(['shops/list-resources']);
                    this.globalService.snackbar('success', message);
                    this.app.editResource(this.editResourceForm.value);
                    if(data.data.menu_status == "1") {
                        this.app.addNewItemToMenuItemShopResources(data.data)
                    } else {
                        this.app.deleteResource(data.data.id)   
                    }
                } else {
                    this.globalService.snackbar("error", message);
                }
            });
        } else {
                this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
        }
        
    }
    
    checkLeftMenuStatus(event) {
        if (event.checked) {
            this.editResourceForm.controls.left_menu_status.setValue(1);
        } else {
            this.editResourceForm.controls.left_menu_status.setValue(0);
        }
    }

    ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
      }  
}
