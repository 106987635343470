import {Component, OnInit, ViewChild, ViewEncapsulation, OnDestroy} from '@angular/core';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
import {Http, Headers, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../../environments/globalFunctions"
import {globalConstants} from "../../../../environments/globalConstants"
import {GlobalService} from "../../../../environments/global.service"
import {lang} from "../../../../environments/lang-en"
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';

// import {MatPaginator, MatSort, MatDatepicker} from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {DataSource, SelectionModel} from '@angular/cdk/collections';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';


import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
const SHOP_GENERICJOB='shop-generic-job-';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-listgenericjobs',
  templateUrl: './listgenericjobs.component.html',
  styleUrls: ['./listgenericjobs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListgenericjobsComponent implements OnInit,OnDestroy {
    public currentCustomer;
    public currentCustomerName;
    public currentCustomerVehicles;
    public isCollapsed: boolean = true;
    public openShopGenericJobListSearch: boolean = true;
    public customer_id;
    public result: any;
    displayedColumns = ['name', 'price', 'description', 'added_on', 'Actions'];
    dataSource: MatTableDataSource<UserData> = new MatTableDataSource();
    public record :any =[];

    public more_rows;
    public options = {
        user_type: "User",
        user_id: "",
        search_by: "",
        sort_by: "",
        sort_type: "",
        search_keyword: "",
        start: 0,
        limit: 10,
    }


    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    public searchByArray = [];
    public sortByArray = [];
    public sortTypeArray = [];
    public showCostPrice=false;
    public subscriptionDataForAPI: Subscription = new Subscription();
    recordsTotal: any;

    constructor(public titleService: Title, public globalService: GlobalService, private route: ActivatedRoute, private http: Http, private router: Router) {
        this.globalService.setMetaData("SHOPS","LIST_GENERIC_JOB")
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
        this.options.user_id = this.currentCustomer.id;
        this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
        this.reinitializeOptions();
        this.openShopGenericJobListSearch = this.globalService.openSearch(SHOP_GENERICJOB);
        setTimeout(()=>{
            let isEnable=this.globalService.getAcl('generic_job','enable');
            this.showCostPrice=this.globalService.getAcl('display_part_cost','views');
            if(!(isEnable)){
                this.router.navigate(['shops/action/forbidden']);
            }
        },globalConstants.RELOAD_WAIT);
        
        /* SEARCH BY ARRAY */
        this.searchByArray = [
            { id: 1, text: "Job Name"},
            { id: 2, text: "Description"}
        ];
        /* SORT BY ARRAY */
        this.sortByArray = [
            { id: 'name', text: "Name"},
            { id: 'price', text: "Price"}
        ];
        
        /* SORT TYPE ARRAY */
        this.sortTypeArray = [
            { id: 'ASC', text: "Ascending"},
            { id: 'DESC', text: "Descending"}
        ]
    }

    ngOnInit() {
        //this.titleService.setTitle(lang.TITLE_ADMIN_SHOP_LIST);

        this.setDataTable();
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    formatDate(date) {
        if (!new Date(date) || date == null || date == "0000-00-00 00:00:00") { return "--" }
        return (new Date(date).getMonth()+1) + "/" + new Date(date).getDate() + "/" + new Date(date).getFullYear()
    }

    search(event) {
        this.setDataTable();
    }

    clearSearch() {
        this.globalService.localGetterSetter(null,this.options,SHOP_GENERICJOB);
        this.reinitializeOptions();
        this.setDataTable();
    }

    editJob(job_id) {
        this.globalService.localGetterSetter(this.options,null,SHOP_GENERICJOB);
        localStorage.setItem("shopJobID", job_id);
        this.router.navigate(['shops/edit-generic-job']);
    }

    deleteJob(job_id) {
        this.globalService.localGetterSetter(this.options,null,SHOP_GENERICJOB);
        var stringifiedFormData = "user_type=" + this.currentCustomer.user_type + "&user_id=" + this.currentCustomer.id + "&job_id=" + job_id;
        this.globalService.confirmDialog(job_id, 'Are you sure you want to delete this generic job?').subscribe((res) => {
            this.result = res;
            if (this.result != null) {
                this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_DELETE_GENERIC_JOB_URL, stringifiedFormData).subscribe((data:any) => {
                        var message = data.message;
                        if (data.result == "1") {
                            this.globalService.onDeleteCheck(SHOP_GENERICJOB,this.options)
                            this.reinitializeOptions()
                            this.setDataTable();
                            this.globalService.snackbar('success', message);
                        } else {
                            this.globalService.snackbar("error", message);
                        }
                    });
            }
        });
    }

    sideNavOpen() {

    }

    checkFormEmpty(button) {
        if (this.options.search_keyword == "" && this.options.search_by == "" && this.options.sort_by == "" && this.options.sort_type == "") {
            if (button) {button.color = "primary"}
            return false
        }
        else {
            if (button) {button.color = "accent"}
            return true;
        }
    }

    onResize(event) {
        event.target.innerWidth;
    }

    getCurrentRoute() {
        return this.router.url
    }

    scrolling(){
        this.globalService.setScrolling('.car-datatable', 1000, -20);
    }

    paginateByLimit(event){
        this.globalService.setScrolling('body', 600, 0);
        this.options.limit = event.pageSize;
        this.options.start = 0;
        this.scrolling();
        this.setDataTable();
    }

    reinitializeOptions() {
        this.globalService.localGetterSetter(this.options,this.options,SHOP_GENERICJOB);
        this.options.user_id= this.currentCustomer.id;
        this.options.user_type= this.currentCustomer.user_type;
    }

    getTotalRecords() {
        return parseInt(localStorage.getItem(SHOP_GENERICJOB+'totalRecords'))
    }

   paginate(event,type){
        this.scrolling();
        if(type=="next") {
          this.options.start = this.options.start + this.options.limit;

        } else {
          this.options.start = this.options.start - this.options.limit;

        }
        this.setDataTable();
    }

    firstList() {
        return this.globalService.firstList(SHOP_GENERICJOB,this.options);
    }

    lastList(){
        return this.globalService.lastList(SHOP_GENERICJOB);
    }

    getUpperLimit(currentLastLimit,total) {
        if(currentLastLimit > total) {
          return total
        } else {
          return currentLastLimit
        }
    }
    setDataTable(){
        this.record = [];
        // this.options = options;
        var content = globalFunctions.stringifyFormData(this.options, null);
        var callApi = this.globalService.callAPI(globalConstants.API_GET_GENERIC_JOBS_LIST_URL, content);
        callApi.subscribe((data:any) => {
            if (data.data != undefined) {
                let moreRows='false';
                let firstList='false';
                this.globalService.localGetterSetter("totalRecords",data.recordsTotal,SHOP_GENERICJOB);
                if(data.more_rows == "true") {
                    moreRows="true";
                }
                this.globalService.localGetterSetter("more_rows",moreRows,SHOP_GENERICJOB);
                if (data.start >= 0 && data.start <= 5) {
                    firstList="true";
                }
                this.globalService.localGetterSetter("first_list",firstList,SHOP_GENERICJOB);

                this.recordsTotal = data.data.length;

                if(data.data.length==undefined){
                    this.AddRecord(data.data);
                    this.dataSource = new MatTableDataSource(this.record)
                    this.dataSource.sort = this.sort;
                    this.globalService.localGetterSetter("current-visible",'1',SHOP_GENERICJOB);
                } else {
                    this.globalService.localGetterSetter("current-visible",this.recordsTotal,SHOP_GENERICJOB);
                    for(var i=0;i<data.data.length;i++){
                      this.AddRecord(data.data[i])
                    }
                    this.dataSource = new MatTableDataSource(this.record)
                    this.dataSource.sort = this.sort;
                }
                this.globalService.localGetterSetter(null,this.options,SHOP_GENERICJOB);
            }else {
                this.dataSource = new MatTableDataSource(this.record);
                this.globalService.localGetterSetter("totalRecords",'0',SHOP_GENERICJOB);
            }
        })
    }

    AddRecord(record){
        var data = this.createRecordrecord(record);
          this.record.push(data);
    }
    createRecordrecord(record) {
        return {
            name: record.name,
            price: parseFloat(record.price),
            description: record.description,
            added_on: this.globalService.getFullFormateDate(record.add_date,'GD','H'),
            job_id: record.id
        };
    }
    isAllowed(action){
        return this.globalService.getAcl('generic_job',action);
    }

    ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
    } 
}

export interface UserData {
    name: any,
    price: any,
    description: any,
    added_on: any,
    job_id: any
}

