import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { globalFunctions } from '../../../../environments/globalFunctions';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';

@Component({
  selector: 'app-qqpayment',
  templateUrl: './qqpayment.component.html',
  styleUrls: ['./qqpayment.component.scss']
})
export class QqpaymentComponent implements OnInit {

  public addPayment: FormGroup;
  public maxDate = new Date();
  public paymentMethod = [{ id: '1', text: 'Cash' }, { id: '2', text: 'Check' }, { id: '3', text: 'Credit Card' }, { id: '4', text: 'Other' }];
  public maxDates = (this.maxDate.getMonth() + 1) + '/' + this.maxDate.getDate() + '/' + this.maxDate.getFullYear();
  public paymentData = {};
  public dataSources = { data: [], total_paid: 0, balance: '', request_status: '1' };
  public checkMethod: boolean = false;
  public creaditCard: boolean = false;
  public amtModified: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<QqpaymentComponent>, private formBuilder: FormBuilder, private gs: GlobalService) { }

  ngOnInit() {
    this.addPayment = this.formBuilder.group({
      payment_method: ['1'],
      payment_amount: ['', Validators.required],
      cc_number: [''],
      paymentDate: [''],
      cheque_number: [''],
      bank_name: [''],
      payment_notes: [''],
      payment_qq_id: [this.data.id]
    });
    this.getPaymentDetail(this.data.id)
  }
  onlyNumericKey(event) {
    return globalFunctions.onlyDecimalNumberKey(event);
  }
  toNummber(string) {
    return parseInt(string)
  }
  getStyle(p) {
    if (p.delete_status == 1) {
      return 'text-decoration:line-through';
    }
    return '';
  }
  public d = '';
  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    this.d = event.srcElement.value;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }
  disableKey(event) {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey == true && (event.which == '67' || event.which == '86' || String.fromCharCode(event.which).toLowerCase() == 's')) {
      event.preventDefault();
    }
  }
  vkFloat(e, k) {
    let output = Number(this.addPayment.value[k]);
    if (isNaN(output)) {
      if (isNaN(Number(this.d))) {
        this.addPayment.controls[k].setValue(parseFloat(this.addPayment.value[k]));
      } else {
        this.addPayment.controls[k].setValue(this.d);
      }
    }
  }
  isAllowed(action) {
    return this.gs.getAcl('payment',action);
  }
  changeMethod(event?: any) {
    if (typeof event == 'string') {
      event = { value: event };
    }
    if (event.value == 2) {
      this.checkMethod = true;
      this.creaditCard = false;
      this.addPayment.controls.cc_number.setValue('');
    } else if (event.value == 3) {
      this.creaditCard = true;
      this.checkMethod = false;
      this.addPayment.controls.cheque_number.setValue('');
    } else {
      this.checkMethod = false;
      this.creaditCard = false;
      this.addPayment.controls.cheque_number.setValue('');
      this.addPayment.controls.cc_number.setValue('');
    }

  }
  addPayments() {

    let fcData = this.addPayment;
    for (let inner in this.addPayment.controls) {
      this.addPayment.get(inner).markAsTouched();
      this.addPayment.get(inner).updateValueAndValidity();
    }
    let th = this;
    if (this.addPayment.valid) {
      if (fcData.controls.paymentDate.value) {
        let d = new Date(fcData.controls.paymentDate.value);
        fcData.value.paymentDate = (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear();
      }
      let params = globalFunctions.stringifyFormData(fcData.value, this.gs.getCurrentUser()["user_type"]);
      this.gs.callAPI(globalConstants.API_QQ_ADD_PAYMENT, params).subscribe((res:any) => {
        if (res.result == 1) {
          this.gs.snackbar("success", res.message);
          this.addPayment.controls.payment_method.setValue('1');
          this.changeMethod({ value: 1 });
          this.addPayment.controls.payment_amount.setValue('');
          this.addPayment.controls.cc_number.setValue('');
          this.addPayment.controls.paymentDate.setValue('');
          this.addPayment.controls.cheque_number.setValue('');
          this.addPayment.controls.bank_name.setValue('');
          this.addPayment.controls.payment_notes.setValue('');
          th.addPayment.markAsUntouched();
          this.addPayment.controls.payment_amount.setErrors(null);
          this.getPaymentDetail(this.data.id)
          this.amtModified=true
        } else {
          this.gs.snackbar("error", res.message);
        }
      }, (error) => { })
    } else {
      this.gs.snackbar("error", 'Enter Payment Amount');
    }
  }
  getPaymentDetail(q_id) {
    let data = { qq_id: q_id };
    let params = globalFunctions.stringifyFormData(data, this.gs.getCurrentUser()["user_type"]);

    this.gs.callAPI(globalConstants.API_QQ_PAYMENT_LIST, params).subscribe((response:any) => {
      this.dataSources = response;
      if (typeof this.dataSources.data == 'undefined') {
        this.dataSources.data = [];
      };
      if (this.dataSources.balance == '-') {
        this.dataSources.balance = '0.00';
      }
      for (var i = 0; i < this.dataSources.data.length; i++) {
        this.dataSources.data[i].payment_date = this.gs.getFullFormateDate(this.dataSources.data[i].payment_date, 'GD', 'H');
      }
    }, (error) => { });
  }
  getPayMethod(id) {
    let payMethod = '';
    switch (id) {
      case '1': payMethod = 'Cash'; break;
      case '2': payMethod = 'Check'; break;
      case '3': payMethod = 'Credit Card'; break;
      case '4': payMethod = 'Other'; break;
      default: payMethod = 'not found'; break;
    }
    return payMethod;
  }
  deletePayment(p_id) {
    let data = { payment_id: p_id, quote_id: this.data.id };
    let conf = 'Are you sure you want to delete the payment entry?';
    let params = globalFunctions.stringifyFormData(data, this.gs.getCurrentUser()["user_type"]);
    this.gs.confirmDialog('delAmount', conf).subscribe((r) => {
      let res: any = r;
      if (res == 'delAmount') {
        this.gs.callAPI(globalConstants.API_DEL_QQ_PAYMENT, params).subscribe((response:any) => {
          if (response.result == 1) {
            this.gs.snackbar("success", response.message);
            this.getPaymentDetail(this.data.id);
            this.amtModified=true
          } else {
            this.gs.snackbar("error", response.message);
          }
        },(error)=>{ });
      }
    })
  }
  popupClose(){
    this.dialogRef.close((this.amtModified?1:0))
  }

}
