import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap,NavigationEnd, Params  } from '@angular/router';
import { Http,Headers, Response} from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { globalFunctions } from "../../../../environments/globalFunctions";
import { globalConstants } from "../../../../environments/globalConstants";
import {GlobalService} from "../../../../environments/global.service";
import { lang } from "../../../../environments/lang-en";
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TextMaskModule } from 'angular2-text-mask';

@Component({
  selector: 'app-addeditmailchimp',
  templateUrl: './addeditmailchimp.component.html',
  styleUrls: ['./addeditmailchimp.component.scss']
})
export class AddeditmailchimpComponent implements OnInit, OnDestroy  {
  public currentCustomer;
  public currentCustomerName;
  public currentCustomerVehicles;
  public isCollapsed:boolean = true;
  public idFromUrl;
  public showSubscriptionMessage = false;
  public mailchipConnection:FormGroup;
  public currentAPIDetails;
  public syncMailChimp;
  public submitted=false;
  public API_key;
  public customerListID;
  public isSubscribed;
  public mailchimpStatus;
  public subscriptionDataForAPI: Subscription = new Subscription();

    constructor(private activatedRoute:ActivatedRoute,private formBuilder: FormBuilder,private route: ActivatedRoute,public titleService:Title,private http: Http,private router: Router,private globalService: GlobalService) {
        this.globalService.setMetaData("SHOPS","MANAGE_MAILCHIMP")
        if(localStorage.getItem("loggedInUser")) {
            this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
            this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
            this.isSubscribed = this.currentCustomer.is_subscribed;
            /* Check for mailchimp status */
            if(this.isSubscribed == "true") { 
                 this.mailchimpStatus = this.currentCustomer.subscription_data.mailchimp;
            }
            
            this.mailchipConnection = this.formBuilder.group({
                api_key: ['', Validators.required],
                customerlist_id: ['', Validators.required],
                user_id: this.currentCustomer.id,
                user_type: this.currentCustomer.user_type
            })
            
        } else {
            this.router.navigate(["/shops"]);
        }
        setTimeout(()=>{
        let isEnable=this.globalService.getAcl('mailchimp','addNew');
        if(!(isEnable)){
            this.router.navigate(['shops/action/forbidden']);
        }
    },globalConstants.RELOAD_WAIT);
    }

    
    ngOnInit() {

        var shopContent = "user_id="+this.currentCustomer.id+"&user_type="+this.currentCustomer.user_type;
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_MAILCHIMP_CONNECTION_URL,shopContent).subscribe((data:any) => {
            if (data.result == "1") { 
                this.currentAPIDetails = data.data;
                this.API_key = this.currentAPIDetails.api_key;
                this.customerListID = this.currentAPIDetails.customerlist_id;
            } else { 
                this.API_key = "";
                this.customerListID = "";
            }
        });
    }
  
    checkIfValid() {
        if(this.mailchipConnection.valid) {
          this.submitted= false;
        }
    }
    
    editMailchimp() {
        if(this.mailchipConnection.valid) {
            var stringifiedFormData = globalFunctions.stringifyFormData(this.mailchipConnection.value,null);
            this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_ADD_MAILCHIMP_CONNECTION_URL,stringifiedFormData).subscribe((data:any)=> {
                var message = data.message;
                if(data.result == 1) {
                    this.globalService.snackbar("success", message);
                } else { 
                    this.globalService.snackbar("error", message);
                }
            });
        } else {
            this.submitted = true;
            this.mandatoryMsg();
        }
    }
    
    syncShopMailchimpCustomerList() {

        var shopContent = "user_id="+this.currentCustomer.id+"&user_type="+this.currentCustomer.user_type;
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_SYNC_MAILCHIMP_LIST_URL,shopContent).subscribe((data:any) => {
            this.syncMailChimp =data.data;
            var message = data.message;
            if(data.result == 1) {
                this.globalService.snackbar("success", message);
            } else { 
                this.globalService.snackbar("error", message);
            }
        });
    }
    
    mandatoryMsg() {
        this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
    
    gotoShopSubscription() {
        this.router.navigate(['/shops/subscription'])
    }
   
    ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
    } 
}
