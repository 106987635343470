import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
@Component({
    selector: 'app-snackbar-toaster',
    templateUrl: './snackbar-toaster.component.html',
    styleUrls: ['./snackbar-toaster.component.scss']
})
export class SnackbarToasterComponent {
    public type: any;
    public message: string;
    private action: any = {};
    public saveButton = false;
    public refreshButton = false;
    constructor(public snackbar: MatSnackBarRef<SnackbarToasterComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any) {
        this.action = data;
    }
    doAction() {
        if (this.action) {
            let comp = this.action.component;
            let method = this.action.method;
            let param = this.action.param;
            try {
                comp[method](param);
            } catch (e) {
                console.log(e);
            }
        }
    }

}
