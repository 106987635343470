import { Component, OnInit, Inject, ViewChild, ElementRef, NgModule, AfterViewChecked } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import {  MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import {IDatePickerConfig} from 'ng2-date-picker';
import {DatePipe} from '@angular/common';
import { UpdatesrFieldComponent } from '../updatesr-field/updatesr-field.component';
import {globalFunctions} from "../../../../environments/globalFunctions"
import * as moment from 'moment';
@Component({
  selector: 'app-service-calendar-modal',
  templateUrl: './assignemployee.component.html',
  styleUrls: ['./assignemployee.component.scss'],
  providers: [DatePipe]
})

export class AssignemployeeComponent implements OnInit,AfterViewChecked {
  // @ViewChild('dayPickerTwo') datepicker2: any ;

  public empList=[];
  public techList = [];
  public requestList=[];
  public userData;
  public assignForm;
  public itemId;
  public submitDisabled:boolean = true;
  public carDetail:any={};
  public labelPhoto=[];
  public vechicleWrap:boolean = false;
  public startDatetime;
  public endDateTime;
  public startMaxDate;
  public startMinDate;
  public neededBy;
  public bypassendDateClearence=false;
  public neededDate:boolean = false;
  public requestId;
  public estimatedTime=false;
  public est_hours:any = 0;
  public est_minutes:any = 30;
  public estimated_hours:any = globalConstants.SR_ESTIMATED_HOUR;
  public sDisable:boolean = true;
  public selectSR:any;
  public sTimeSlots:Array<any> = [];
  public eTimeSlots:Array<any> = [];
  public error:any={dError:false,tError:false};
  datePickerConfig: IDatePickerConfig = {
    drops: "down",
    format: "MM/DD/YYYY",
    min: this.datePipe.transform(new Date(), 'MM/dd/yyyy'),
    closeOnSelect:true,
    disableKeypress: true,
    opens:'right',
    unSelectOnClick:false
};


  constructor(public dialogRef: MatDialogRef<AssignemployeeComponent>,@Inject(MAT_DIALOG_DATA) public data: any,
  private datePipe: DatePipe,
  private formBuilder: FormBuilder,
  public dialog: MatDialog,
  public globalService: GlobalService) 
  { 
    this.userData = this.globalService.getCurrentUser();
    this.sTimeSlots =  globalConstants.TIME_SLOTS;
    this.eTimeSlots =  globalConstants.TIME_SLOTS;
  }
  initForm(){
    this.assignForm = this.formBuilder.group({
      emp_id            :[this.userData.id,[Validators.required]],
      request_id        : ['',[Validators.required]],
      user_id           :[this.userData.id],
      user_type         :[this.userData.user_type],
      event_start_date  :['',[Validators.required]],
      event_end_date    :['',[Validators.required]],
      event_type        :[this.data.eventType],
      assign_tomyself   :[1],
      event_start_time  :['',Validators.required],
      event_end_time    :['',Validators.required],
      overwrite_time    :[false],
      force_action      :[0]
    });
  }

  ngOnInit() {
    this.initForm();  
    this.sDisable   = this.data.fromSRPopup;    
    if(this.data.startTime){
      let startDate = this.datePipe.transform(moment(this.data.startTime).toISOString(), 'MM/dd/yyyy hh:mm a');
      this.assignForm.controls.event_start_date.setValue(this.datePipe.transform(startDate, 'MM/dd/yyyy'));
      this.setTime(moment(this.datePipe.transform(startDate,'hh:mm a'),'hh:mm A').format('HH:mm'),1);
      this.startDatetime = startDate;
      this.startMinDate  = startDate;
    }else if(this.data.requested_delivery_date){  
      let startDate = this.datePipe.transform(moment(this.data.requested_delivery_date).toISOString(), 'MM/dd/yyyy hh:mm a');
      let todayDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm a');
      if(startDate > todayDate){
        this.assignForm.controls.event_start_date.setValue(this.datePipe.transform(startDate, 'MM/dd/yyyy'));
        this.setTime(moment(this.datePipe.transform(startDate,'hh:mm a'),'hh:mm A').format('HH:mm'),1);
        this.startDatetime = startDate;
        this.startMinDate  = startDate;
      }else{
        this.assignForm.controls.event_start_date.setValue(this.datePipe.transform(todayDate, 'MM/dd/yyyy'));
        this.setTime(moment(this.datePipe.transform(todayDate,'hh:mm a'),'hh:mm A').format('HH:mm'),1);
        this.startDatetime = todayDate;
        this.startMinDate  = todayDate;
      }
   
    }
    
    if(this.data.endTime){
      let endDate = this.datePipe.transform(moment(this.data.endTime).toISOString(), 'MM/dd/yyyy hh:mm a');
      this.assignForm.controls.event_end_date.setValue(this.datePipe.transform(endDate, 'MM/dd/yyyy'));
      this.setTime(moment(this.datePipe.transform(endDate,'hh:mm a'),'hh:mm A').format('HH:mm'),2);
    }
   
    if(this.data.requestId != 'undefined' && this.data.requestId != "" && this.data.requestId != 'null'){
      this.itemId       = this.data.requestId;     
      this.assignForm.controls.request_id.setValue(this.data.requestId);
    }    

    let content='user_id='+this.userData.id+'&user_type='+this.userData.user_type;
    this.globalService.callAPI(globalConstants.API_QUICKAPPT_SERVICE_REQUESTS,content).subscribe((resp:any)=>{
      if(resp.result==1){
        if(typeof resp.data != 'undefined' && resp.data.length){
          for(var i in resp.data){
            resp.data[i].text = resp.data[i].title+' ('+resp.data[i].request_id+', '+resp.data[i].license_plate+', '+resp.data[i].last_name+')';
            this.requestList.push(resp.data[i]);
          }
        }
        
        if(this.data.requestId != 'undefined' && this.data.requestId != "" && this.data.requestId != 'null'){
          this.vechicleWrap = true;
          this.getVehicleDetail(this.data.requestId);
        }
      }
    }
    );
    
    //content = content+'&techassign=1';
    this.globalService.callAPI(globalConstants.API_GET_TECHNICIAN,content).subscribe((tech:any)=>{
      if(tech.result==1){
        if(typeof tech.data != 'undefined' && tech.data.length){
          for(var i in tech.data){
            tech.data[i].text=tech.data[i].first_name+' '+tech.data[i].last_name;
            this.techList.push(tech.data[i]);
          }
        }
        if(typeof tech.employes != 'undefined' && tech.employes.length){
          for(var i in tech.employes){
            tech.employes[i].text=tech.employes[i].first_name+' '+tech.employes[i].last_name;
            this.empList.push(tech.employes[i]);
          }
        }
      }
    });

    this.assignForm.get("assign_tomyself").valueChanges.subscribe(x => {
      if(x==1){      
        this.assignForm.controls.emp_id.setValue(this.userData.id);
      }else{
        this.assignForm.controls.emp_id.setValue('');
      }
    });

  }

 

  setAssignType(event){  
    this.assignForm.controls.assign_tomyself.setValue(event.target.value);
  }

  checkIfValid() {
    this.error.dError = false;
    this.error.tError = false;
    if(moment(new Date(this.assignForm.controls['event_start_date'].value)).format('MM/DD/YYYY') > moment(new Date(this.assignForm.controls['event_end_date'].value)).format('MM/DD/YYYY')){
      this.error.dError = true;
    }
  
    if(moment(new Date(this.assignForm.controls['event_start_date'].value)).format('MM/DD/YYYY')==moment(new Date(this.assignForm.controls['event_end_date'].value)).format('MM/DD/YYYY') && this.assignForm.controls['event_start_time'].value > this.assignForm.controls['event_end_time'].value){
      this.error.tError = true;
    }
    if (this.assignForm.valid && this.error.dError==false && this.error.tError==false) {
      this.submitDisabled = false;
    }else{
      this.submitDisabled = true;
    }  
  }

  getVehicleDetail(srReqId=null) {

    if(srReqId){
      this.selectSR = this.requestList.find(obj => { return obj.id === srReqId });
      if('requested_delivery_date' in this.selectSR){
        let startDate = this.datePipe.transform(moment(this.selectSR['requested_delivery_date']).toISOString(), 'MM/dd/yyyy hh:mm a');
        let todayDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm a');
        if(startDate > todayDate){
          this.assignForm.controls.event_start_date.setValue(this.datePipe.transform(startDate, 'MM/dd/yyyy'));
          this.setTime(moment(this.datePipe.transform(startDate,'hh:mm a'),'hh:mm A').format('HH:mm'),1);
          this.startDatetime = startDate;
          this.startMinDate  = startDate;
        }else{
          this.assignForm.controls.event_start_date.setValue(this.datePipe.transform(todayDate, 'MM/dd/yyyy'));
          this.setTime(moment(this.datePipe.transform(todayDate,'hh:mm a'),'hh:mm A').format('HH:mm'),1);
          this.startDatetime = todayDate;
          this.startMinDate  = todayDate;
        };
      }      
    }
   
    this.neededDate = false;
    this.vechicleWrap = false;
    let carOpt = "&request_id=" + this.itemId;
    this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_VEHICLES_URL, carOpt).subscribe((res:any) => {
      if (res.result == 1) {
        this.carDetail = res.data;
        this.vechicleWrap = true;
        if (this.carDetail.production_date != "0000-00-00 00:00:00" && this.carDetail.production_date != "" && this.carDetail.production_date != null) {
          var splitDatesValues = this.carDetail.production_date.split("-");
          this.carDetail.production_date = splitDatesValues[1] + "/" + splitDatesValues[0];
        } else {
          this.carDetail.production_date = '-';
        }
        if (this.carDetail.vehicle_label_pic) {
          this.labelPhoto = this.carDetail.vehicle_label_pic
        }
      }
    }, (error) => { });
    this.getServiceDetails();
  }

  getServiceDetails(){
    this.estimatedTime = false;
    let content1 ='user_id='+this.userData.id+'&user_type='+this.userData.user_type+"&request_id=" + this.itemId;
    this.globalService.callAPI(globalConstants.API_QUICKAPPT_SERVICE_REQUESTS,content1).subscribe((resp:any)=>{
      if(resp.result==1){
        if(typeof resp.data != 'undefined' && resp.data != ""){
          this.neededBy = moment(resp.data.estimated_delivery_date).toISOString();
          if(resp.data.est_hours!=null && resp.data.est_hours!='null'){
            this.estimated_hours  = resp.data.est_hours;
          }
          if(resp.data.hours!=null && resp.data.hours!='null'){
            this.est_hours        = resp.data.hours;
          }
          if(resp.data.minute!=null && resp.data.minute!='null'){
            this.est_minutes      = resp.data.minute;
          } 
          
          if(!this.startDatetime){
            let todayDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm a');
            this.assignForm.controls.event_start_date.setValue(this.datePipe.transform(todayDate, 'MM/dd/yyyy'));
            this.setTime(moment(this.datePipe.transform(todayDate,'hh:mm a'),'hh:mm A').format('HH:mm'),1);
            var dt = new Date(todayDate);
          }else{
            var dt = new Date(this.startDatetime);
          }
          if(resp.data.hours){
            dt.setHours(dt.getHours() + resp.data.hours);
          }
          if(resp.data.minute){
            dt.setMinutes(dt.getMinutes() + resp.data.minute);
          }
          if(resp.data.hours  || resp.data.minute){
            this.assignForm.controls.event_end_date.setValue(this.datePipe.transform(dt, 'MM/dd/yyyy'));
            this.setTime(moment(this.datePipe.transform(dt,'hh:mm a'),'hh:mm A').format('HH:mm'),2);
          }
        }
      }
    });
  }
  
  onlyNumericKey(event) {
    return globalFunctions.onlyDecimalNumberKey(event);
  }

  validateDateTime(e){
    //  if(this.bypassendDateClearence == true){
    //     this.assignForm.controls.event_end_date.setValue('');
    //  }else{
    //    this.bypassendDateClearence = true;
    //  }
  }

  startDateHandler(event){ 
    if(event){
      var startDate = this.datePipe.transform(event, 'MM/dd/yyyy');   
      var dt = new Date(this.datePipe.transform(event, 'MM/dd/yyyy hh:mm a'));
      let EndDate = this.datePipe.transform(dt, 'MM/dd/yyyy');
      if(typeof this.est_hours != 'undefined' && this.est_hours != 'null'){
        dt.setHours(dt.getHours() + this.est_hours);
      }
      if(typeof this.est_minutes != 'undefined' && this.est_minutes != 'null'){
        dt.setMinutes(dt.getMinutes() + this.est_minutes);
      }
      if(typeof this.est_hours != 'undefined' || typeof this.est_minutes != 'undefined'){        
        this.assignForm.controls.event_end_date.setValue(this.datePipe.transform(EndDate, 'MM/dd/yyyy'));       
        //this.setTime(moment(this.datePipe.transform(dt,'hh:mm a'),'hh:mm A').format('HH:mm'),2);
      }     
    }
  }


  endDateHandler(event){                                
   
  }

  updateNeededBy(){
    let dialogRef = this.dialog.open(UpdatesrFieldComponent, {
      panelClass: 'car-dialog-form',
      width: '700px',
      data: {request_id:this.itemId, needed_date:this.neededBy},
    });
    dialogRef.afterClosed().subscribe((r: any) => {
      this.getServiceDetails();
      this.neededDate = false;
    })
  }

  createTechAssignment(forcAction=null,conflictAction=null){
    if(this.assignForm.valid){
      var calStarttDate = this.datePipe.transform(this.assignForm.value.event_start_date, 'MMddyyyyHHmmss');
      var calEndDate = this.datePipe.transform(this.assignForm.value.event_end_date, 'MMddyyyyHHmmss');

      if(calStarttDate > calEndDate){
        this.globalService.snackbar("error", 'Invalid Date Range');
        return;
      }
      else if(this.neededBy && this.neededBy != '0000-00-00 00:00:00' && this.neededBy != 'null' ){
        let calStarttDate = this.datePipe.transform(this.assignForm.value.event_start_date, 'MMddyyyyHHmmss');
        let calEndDate = this.datePipe.transform(this.assignForm.value.event_end_date, 'MMddyyyyHHmmss');

        if(calEndDate > this.datePipe.transform(this.neededBy, 'MMddyyyyHHmmss') || calStarttDate > this.datePipe.transform(this.neededBy, 'MMddyyyyHHmmss')){
          this.neededDate = true;
          this.submitDisabled = false;
          return;
        }else{
          this.neededDate = false;
          this.submitDisabled = true;
        }
      }

      if(this.assignForm.value.assign_tomyself==1){
        this.assignForm.controls.emp_id.setValue(this.userData.id);
      }
      console.log('adfa',this.assignForm.value);
      const data= Object.assign({},this.assignForm.value);
      data['event_start_date'] = moment(data.event_start_date+" "+data.event_start_time).format('MM/DD/YYYY hh:mm A Z');
      data['event_end_date']   = moment(data.event_end_date+" "+data.event_end_time).format('MM/DD/YYYY hh:mm A Z'); 
      data['overwrite_time']   = data.overwrite_time?1:0;
      if(forcAction > 0){
        data['force_action']   = forcAction;
      }
      if(conflictAction > 0){
        data['adding_conflict']= conflictAction;
      }
      let formdata = this.globalService.formsData(data);
      this.globalService.formData(globalConstants.API_ASSIGN_SERVICE_REQUEST, formdata).subscribe((r:any) => {
        if(r.result==1){
          this.globalService.snackbar("success", r.message);
          this.dialogRef.close(true);
        }else{          
          if([730,731,732,734,740].includes(parseInt(r.code))){
            let code:any = r.code;
            this.globalService.confirmDialog('techAssign', r.message).subscribe((resp:any) => {
              let fStatus = {730:1,731:2,732:3,740:4,734:5};
              if(resp=='techAssign'){
                if([1,2].includes(fStatus[Number(code)])){
                  conflictAction = fStatus[Number(code)];
                }
                this.createTechAssignment(fStatus[Number(code)],conflictAction);
              }
            });
          }else{
            this.globalService.snackbar("error", r.message);
          } 
        }
      }, (error) => { })
    }else{
      this.submitDisabled = true;
      this.globalService.snackbar('error',globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
  }

  startTimeHandler(event){
  
    let tDate  = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
    let sTime  = moment(tDate+" "+event); 
    if(typeof this.est_hours != 'undefined' && this.est_hours != 'null'){
     sTime.add(this.est_hours,'hours');
    }
    if(typeof this.est_minutes != 'undefined' && this.est_minutes != 'null'){
      sTime.add(this.est_minutes,'minutes');  
    }
    if(typeof this.est_hours != 'undefined' || typeof this.est_minutes != 'undefined'){
      this.setTime(sTime.format('HH:mm'),2);
    }

  }

  setTime(hoursMinutes,setField=1){
    const time    = hoursMinutes.split(":");
    let  hour     = time[0];
    let  minutes  = "00";
    if(time[1] > 0 && time[1] <= 15){
      minutes = "15";
    }else if(time[1] > 15 && time[1] <= 30){
      minutes = "30";
    }else if(time[1] > 30 && time[1] <= 45){
      minutes = "45";
    }else if(time[1] > 45){
      hour = Number(hour)+1;
    }   

    hour    = hour.toString().padStart(2, "0");
    minutes = minutes.toString().padStart(2, "0");
    
    if(setField==1){
      this.assignForm.controls.event_start_time.setValue(hour+":"+minutes);
    }else{
      this.assignForm.controls.event_end_time.setValue(hour+":"+minutes);
    }
  }

  ngAfterViewChecked() {  
   this.checkIfValid();
  }  

  convertISODate(dateString){
    return moment(dateString).toISOString();
  }

}
