import { Component, OnInit, ViewChild, Pipe, Inject, ChangeDetectorRef, ViewEncapsulation, Optional, ContentChild, ElementRef } from '@angular/core';
import { AbstractControl, FormGroup, FormControl, FormBuilder, Validators, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import {Http, Headers, Response, ResponseContentType} from '@angular/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker'
import { globalFunctions } from "../../../../environments/globalFunctions";
import { globalConstants } from "../../../../environments/globalConstants";
import { GlobalService } from "../../../../environments/global.service";
// import { CustomLoader } from '../../../customloader';
import { DetailTabComponent } from '../detail-tab/detail-tab.component';
import { ImagedialogComponent } from '../../../assetsComponent/imagedialog/imagedialog.component';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { ImagetooldialogComponent } from '../../../imagetooldialog/imagetooldialog.component';
import { VideoShowComponent } from '../../../customer/my-vehicles/video-show/video-show.component';
// import { AppComponent } from '../../../app.component';
declare var jQuery:any;
declare var $ :any;

@Component({
  selector: 'app-sublet-history',
  templateUrl: './sublet-history.component.html',
  styleUrls: ['./sublet-history.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubletHistoryComponent implements OnInit {
  
  @ViewChild(ModalDirective) serviceHistoryModal: ModalDirective;
  @ViewChild('scrollLoader', {read: ElementRef}) victim: ElementRef;
  
  vehiclesListIO:IntersectionObserver;
  isCategoryView:boolean;
  isCategoryViewModel:boolean;
  categoryBtnText:string = 'Switch to Category View';
  isAdvancedSearch:boolean;
  isAdvancedSearchModel:boolean;
  disableModeSwitch:boolean;
  searchBtnText:string = 'Switch to Advanced Mode';
  serviceRequestId:any;
  customer_id:any;
  public serviceInfo:any;
  vehicle_pic:any;
  vin_photo:any;
  vehicleName:any;
  public carId:any;
  backupCarId:any;
  globalConstants=globalConstants;
  openSearch: boolean;
  openAdvancedSearch: boolean;
  openVehicleList: boolean;
  historyDetailsPanel: boolean;
  carMakeList;
  carModels;;
  years;
  vehiclesList:Array<any>;
  selectedCar:any;
  imgPath = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_PICTURES + globalConstants.IMG_THUMB;
  historyListing:any;
  vehicleListing:any;
  toStartDate:Date;
  options = {
    user_id: "",
    user_type: "User",
    status: '',
    search_by: "",
    search_keyword: "",
    start: 0,
    limit: 10,
    schedule_service_date: "",
    requested_delivery_date: "",
    estimated_delivery_date: "",
    customer_id: "",
    jobStatus:'',
    on_the_lot : '0',
    wait_for_part:'0',
    account_verify:'0',
    serv_notes_unread:'0',
    sort_column: '',
    sort_direction: '',
    from_date: '',
    to_date: '',
    searched: false
  };
  fromDateModel:any;
  toDateModel:any;
  searchByArray = [];
  currentCustomer:any;
  showMinButton:boolean;
  id:string;
  searchResults:Array<any> = [];
  public minidragIcon='car2.jpg';
  minidragTitle:string = 'Service history';
  vehicleIds: Array<string> = [];
  /**
   * service details / results
   */
  partsOptions = [];
  genericOptions = [];
  //  public disclaimer = changeDisclaimer | null
  public ObjectJson = {};
  public headingColorFlag={};
  public totalReturnedValue;
  public finalShowAbleAmount;
  public balance;
  public totalAcceptedCalculation = []
  public rejectedJobCalculation = [];
  public grandTotalAcceptedArray = [];
  public grandTotalRejectedArray = [];
  public nonTaxableValue = [];
  public UnTaxedAcceptedValue = 0;
  public totalAcceptedValue ="";
  public totalRejectedValue ="";
  public conservingStatus = [];
  public statusAcceptText:any;
  public statusRejectText:any;
  public dataSources = {data: []};
  public reportDownloadLink='';
  public reportDownloadType=0;
  public subTotal;
  public totalTax;
  public recIds = []
  public jobStatusObj = {
    l1Id:"",
    l2Id:"",
    l3Id:"",
    recommendationId:"",
    status:""
  };
  public applyChanges=false;
  public jobStatuses = [];
  public showPartsTechButton=false;
  public partstechData=[];
  public partstechOrder=[];
  public editMode='0';
  public access_timestamp='';
  public showDownload=false;
  public initFormValue=true;

  public currentUserID;
  public userType;
  public requestCustomerDetails;
  public requestCustomerID;
  public getRequireApproval;
  //public authorizeBy;
  //public contactPerson;
  public contactPhoneNumber;
  //public dateTimeOfCall;
  public otherMeans;
  public getApprovalAmount;
  public requestCreatedDate;
  public viewCustomerInfo=false;
  public isFillRecommendation=false;
  public isDisclaimers=false;
  public isAcceptRejectJob=false;
  public taskTrack=false;
  public userTaskTrack:any=0;
  public statuses = ['Appointment', 'Pending', 'Processing', 'Waiting for Approval', '', 'Service Completed', 'Completed', 'Approval Completed', 'Completed And Paid', 'Unread Rating', 'Non-CAR','','Work in progress'];
  public skipTicketAssign=false;
  public techinianStatusDone=false;

  public getRejetedJob=true;

  public requestApprovalDetails: any = [];
  public authorizeBy;
  public contactPerson;
  public contactPersonPhone;
  public dateTimeOfCall;
  public otherMeansOfContact;
  public dateTimeOfCallValue;

  public vinTimeStamp = new Date().getTime();
  public vehicleTimeStamp = new Date().getTime();
  public isSubscribed=true;
  public isEmployee=false;
  animationState = 'out';
  animationStateApprovalData = 'out';
  public isInventory=false;
  public recomLock:any={is_locked:0,locker_id:"",locker_name:""};
  public showCostPrice=false;
  public isLaborTax=false;
  public isServiceNote=false
  public serviceDetail:any=null;
  public qqDetail:any;
  public levelPriceObj:any={};
  public hidePrices=true;
  couponRedeemConditions:Array<boolean> = [];
  
  setFocus:boolean;
  
  constructor(private formBuilder: FormBuilder, private router: Router, public globalService: GlobalService, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, @Optional() public dialogRef: MatDialogRef<SubletHistoryComponent>,
  //  private customLoader:CustomLoader, 
   public dialog: MatDialog, private activatedRoute: ActivatedRoute, public bsModalRef:BsModalRef, public cdr: ChangeDetectorRef, private http:Http) {
    // if(data && data.isPopup){
    //   this.isPopup = true;
    //   this.carId = data.car_id;
    //   this.serviceInfo = data.service_data;
    // }
    this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
    /* SEARCH BY ARRAY */
    this.searchByArray = [
      { id: "1", text: "Request ID"},
      { id: "2", text: "Category Name/Job"},
      { id: "3", text: "Part Number"},
      { id: "4", text: "Rejected Job"},
    ];
    
    this.showCostPrice=this.globalService.getAcl('display_part_cost','views');
  }

  ngOnInit(){
    this.setParams();
    setTimeout(()=>{
      if(!this.backupCarId){
        this.backupCarId = this.carId;
      }
      this.isAdvancedSearchModel = this.isAdvancedSearch;
      if(this.isAdvancedSearch){
        this.searchBtnText = 'Switch to Normal Mode';
        this.openAdvancedSearch = true;
        this.openVehicleList = true;
      }
      else{
        this.searchBtnText = 'Switch to Advanced Mode';
        this.openAdvancedSearch = false;
        this.openVehicleList = false;
      }
      this.isCategoryViewModel = this.isCategoryView;
      if(this.options.from_date){
        this.fromDateModel = new Date(this.options.from_date);
      }
      if(this.options.to_date){
        this.toDateModel = new Date(this.options.to_date);
      }
      this.getCarMakes();
      if((this.isAdvancedSearch && this.vehiclesList && this.vehiclesList.length > 0 && this.selectedCar) || (!this.isAdvancedSearch && this.backupCarId)){
        this.initializePopupData();
      }
    }, 500);
  }
  
  initializePopupData(){
    this.getHistoryDetails();
  }
  
  setParams() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.carId = params["car_id"];
    });
  }
  
  getHistoryDetails(){
    let content = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&vehicle_id=" + this.backupCarId + "&is_sublet=1";
    this.globalService.callAPI(globalConstants.API_VEHICLE_OWNER_DETAILS_URL, content).subscribe((response:any) => {
      if(response.result == 1){
        this.serviceInfo = response.data;
      }
      else{
        this.serviceInfo = undefined;
      }
    });
    this.getHistoryListings();
  }
  
  getHistoryListings(isLoadMore?:boolean){
    let body = {
      user_id: this.globalService.getCurrentUser()["id"],
      user_type: this.globalService.getCurrentUser()["user_type"],
      vehicle_id: this.backupCarId || this.carId,
      vehicle_ids: this.vehicleIds,
      start: this.options.start,
      limit: this.options.limit,
      search_by:this.options.search_by,
      keyword: this.options.search_keyword,
      from_date: this.options.from_date,
      to_date: this.options.to_date,
      type: (this.isCategoryView)?'1':'2',
      is_sublet: '1'
    } 
    this.globalService.callAPI(globalConstants.API_VEHICLE_SERVICE_HISTORY_URL, body).subscribe((response:any) => {
      // console.log('history details listing = ', response);
      this.historyListing = response;
      if(!isLoadMore){
        if(response.result == 1){
          this.searchResults = response.data;
          if(this.isCategoryView){
            this.searchResults.forEach((sr)=>{
              sr.category_name = sr.level1_name + ' > ' + sr.level2_name + ((sr.level3_name) ? (' > '+sr.level3_name) : '');
            });
          }
          else{
            // Date View
            this.searchResults.forEach((sr)=>{
              sr.sub_list.data.forEach((job)=>{
                job.category_name = job.level1_name + ' > ' + job.level2_name + ((job.level3_name) ? (' > '+job.level3_name) : '');
              });
            });
          }
        }
        else{
          this.searchResults = [];
          this.globalService.snackbar('error', response.message);
        }
      }
      else{
        if(response.result == 1){
          let tmpResults = response.data;
          if(this.isCategoryView){
            tmpResults.forEach((sr)=>{
              sr.category_name = sr.level1_name + ' > ' + sr.level2_name + ((sr.level3_name) ? (' > '+sr.level3_name) : '');
            });
          }
          else{
            // Date View
            tmpResults.forEach((sr)=>{
              sr.sub_list.data.forEach((job)=>{
                job.category_name = job.level1_name + ' > ' + job.level2_name + ((job.level3_name) ? (' > '+job.level3_name) : '');
              });
            });
          }
          this.searchResults = this.searchResults.concat(tmpResults);
        }
        else{
          this.globalService.snackbar('error', response.message);
        }
      }
    });
  }
  
  detailTab(type) {
    let dialogRef = this.dialog.open(DetailTabComponent, {
      panelClass: 'car-dialog-form',
      width: '800px',
      data: { requestId: this.serviceRequestId, customer_id: this.customer_id, tab: type ,car_id : this.backupCarId }
    });
  }
  
  chkDate(date){
    if (date!="0000-00-00 00:00:00") {
      let currentDate:Date = new Date();
      if(new Date(date)<new Date(currentDate)) {
        return true
      } else {
        return false;
      }
    }
  }
  
  openImageDialog(catType){
    let imgfile;
    if(catType == 1){
      imgfile= this.vehicle_pic;
    }else{
      imgfile= this.vin_photo;
    }
    let dialogRef = this.dialog.open(ImagedialogComponent, {
      panelClass: ['image-dialog'],
      data: {img:imgfile, category:catType}
    });
    imgfile="";
  }
  
  copyText() {
    let text:any = this.serviceInfo.vin;
    this.globalService.txtCopy(text);
  }
  
  isAllowed(action,module?:any){
    return true;
  }
  
  search(event?:any, isAdvanced?:boolean) {
      if(this.options.search_by){
        // this.options.customer_id=''
        // localStorage.setItem("customer_id", '');
      }
      if (this.fromDateModel) {
        let from_date = this.dateFormatWithoutTime(this.fromDateModel);
        this.options.from_date = from_date;
      } else {
        this.options.from_date = "";
      }
      if (this.toDateModel) {
        let to_date = this.dateFormatWithoutTime(this.toDateModel);
        this.options.to_date = to_date;
      } else {
        this.options.to_date = "";
      }
      this.options.start = 0;
      this.options.searched = true;
      this.getHistoryListings();
      // this.setDataTable();
  }
  
  setToStartDate(ev:MatDatepickerInputEvent<Date>){
    // console.log('ev = ', ev);
    this.toStartDate = ev.value;
  }
  
  clearSearch() {
    
    localStorage.setItem("customer_id", '');
    localStorage.setItem("openFilter",'');
    this.options = {
      user_id: this.currentCustomer.id,
      user_type: this.currentCustomer.user_type,
      status: '',
      search_by: "",
      search_keyword: "",
      start: 0,
      limit: 10,
      schedule_service_date: "",
      requested_delivery_date: "",
      estimated_delivery_date: "",
      customer_id: "",
      jobStatus:'',
      on_the_lot : '0',
      wait_for_part:'0',
      account_verify:'0',
      serv_notes_unread:'0',
      sort_column: '',
      sort_direction: '', 
      from_date: '',
      to_date: '',
      searched: false
    };
    this.fromDateModel = '';
    this.toDateModel = '';
    this.getHistoryListings();
  }
  
  dateFormatWithoutTime(dateValue) {
    return ((new Date(dateValue).getMonth()+1) < 10 ? '0' : '')+(new Date(dateValue).getMonth()+1) + "/" + (new Date(dateValue).getDate() < 10 ? '0' : '')  + new Date(dateValue).getDate() + "/" + new Date(dateValue).getFullYear();
  }
  
  /***Check service request*****/
  isAnyOptionActive(button?:any, isAdvanced?:any) {
    if(!isAdvanced){
      if(this.options.from_date != '' ||  this.options.to_date != ''){
        return true;
      }
      if (this.options.search_by=="" && this.options.jobStatus=="" && this.options.search_keyword == "" && this.options.status == "" && this.options.estimated_delivery_date == "" && this.options.requested_delivery_date == "" && this.options.schedule_service_date == "" ) {
        // console.log("IF", this.options);
        if (button) {button.color = "primary"}
          return false;
      }
      else {
        // console.log("ELSE", this.options);
        if (button) {button.color = "accent"}
          return true;
      }
    }
  }
  
  toggleState(type){
    if(type==1){
      this.serviceHistoryModal.show();
    }else if(type==3){
      this.bsModalRef.hide();
      this.globalService.clearBootstrapModal(this.id);
    }else{
      this.globalService.updateBootstrapModalInfo(this.id, {options: this.options, isAdvancedSearch: this.isAdvancedSearch, openAdvancedSearch: this.openAdvancedSearch,openVehicleList: this.openVehicleList, isCategoryView: this.isCategoryView, selectedCar:this.selectedCar, vehiclesList:this.vehiclesList, backupCarId: this.backupCarId, serviceInfo: this.serviceInfo, searchResults: this.searchResults});
      this.bsModalRef.hide();
    }
  }
  
  isUserLoggedIn() {
    return (localStorage.getItem("loggedInUser") == null) ? false : true
  }
  
  expansionOpen(ele, idKeyword:string) {
    var target= '#expansionHead-'+idKeyword+'-'+ ele;
    var eleState = $(target).data('state');
    $(".modal-body.service-history .new-request-expansion-"+idKeyword).find(".mat-expansion-panel-header").data("state",false);
    if(!eleState && this.setFocus){
      setTimeout(() => {
         let element = document.getElementById('expansion-'+idKeyword + ele);
         var target= '#expansionHead-'+idKeyword+'-'+ ele;
         $(target).data('state',true);
      }, 200);
    }
  }
  
  expandAll(idKeyword:string, parent:any=''){
    $('.modal-body.service-history [id*=expansionHead-'+idKeyword+'-'+parent+']').not('.mat-expanded').each((i, target)=>{
      this.setFocus = false;
      $(target).click();
    });
  }
  
  collapseAll(idKeyword:string, parent:any=''){
    $('.modal-body.service-history [id*=expansionHead-'+idKeyword+'-'+parent+'].mat-expanded').each((i, target)=>{
      // let ele = $(target).attr('id').split('-')[2];
      this.setFocus = false;
      $(target).click();
    });
  }
  
  /** copy recommendation data from jobs */
  // copyJob(e,data,l1,jl3?:any){
  copyJob(e, data){
    e.preventDefault();
    let jobId='';
    let name='';
    // let v:any=data;
    let l1 = data.level1_name;
    let l2:any="",l3="";
    if(data.level3_name){
      // v=jl3;
      l2=data.level2_name;
      l3=data.level3_name;
      name=data.level2_name+' / '+data.level3_name;
      jobId=data.recommendations[0].service_request_list_id;
    }else{
      name=data.level2_name;
      l2=data.level2_name;
      jobId=data.recommendations[0].service_request_list_id;
    }
    let shopId:string;
    if(data.shop_id){
      shopId = data.shop_id;
    }
    let cbData={id:jobId,name:name,shopId:shopId,l1:l1,l2:l2,l3:l3,recomm:data.recommendations};
    this.globalService.copyJob('set',cbData);
  }
  
  isCopyEnable(obj){
    if(typeof obj.children =='undefined'){
      return true;
    }else if(typeof obj.children !='undefined' && obj.children.length==0){
      return true;
    }
    return false;
  }
  
  parseFloats(value){
    let val=parseFloat(value);
    if(isNaN(val)){
      return '0.00';
    }
    return val.toFixed(2);
  }
  
  stockInHand(part){
    let q=globalFunctions.hasDecimal(part.stock_quantity,true)+' remaining in stock';
    let ouq=globalFunctions.hasDecimal(part.quantity_overused,true);
    if(parseFloat(ouq)){
      q+=', '+ouq+' short';
    }
    return q;
  }
  
  getNltoBr(val){
    if(val && val.length>0){
      return val.toString().replace(/\r\n/g, "<br />").replace(/\n/g, "<br />");
    }
    return '-';
  }
  
  imageToolDialog(current,i,type,imgaar): void {
    let dialogRef = this.dialog.open(ImagetooldialogComponent, {
        panelClass: ['imageToolDialog'],
        width: "100%",
        data: {event: current,index:i,imgType:type,imgArray:imgaar,customerId:this.globalService.getCurrentUser()["id"]}
    });

    dialogRef.afterClosed().subscribe(result => {
        //console.log('The dialog was closed');
        if(type == 1){
          this.vehicleTimeStamp = new Date().getTime();
        }else if(type == 5){
          this.vinTimeStamp = new Date().getTime();
        }
    });
  }
  
  videoShow(val): void {
    let dialogRef = this.dialog.open(VideoShowComponent, {
        panelClass: 'car-dialog-form',
        width: "800px",
        data: {name: val, url: globalConstants.S3_BUCKET_URL + 'recommendation-videos/'}
    });
  
    dialogRef.afterClosed().subscribe(result => {
  
    });
  }
    
  toNumber(value){
    return parseInt(value)
  }
  
  ConvertToNumber(str){ return parseInt(str) }
  
  loadMoreSearches(){
    this.options.start += this.options.limit;
    this.getHistoryListings(true);
  }
  
  loadMoreRequests(root:any, categoryData:any){
    let body:any = {
      user_id: this.globalService.getCurrentUser()["id"],
      user_type: this.globalService.getCurrentUser()["user_type"],
      vehicle_id: this.backupCarId || this.carId,
      start: root.sub_list.start,
      limit: root.sub_list.limit,
      search_by:this.options.search_by,
      keyword: this.options.search_keyword,
      from_date: this.options.from_date,
      to_date: this.options.to_date,
      type: (this.isCategoryView)?'1':'2',
      is_sublet: '1'
    } 
    if(this.isCategoryView){
      body.level1_id = categoryData.l1;
      body.level2_id = categoryData.l2;
      body.level3_id = categoryData.l3;
    }
    else{
      body.request_id = categoryData.request_id;
    }
    this.globalService.callAPI(globalConstants.API_VEHICLE_SERVICE_HISTORY_SUB_LIST_URL, body).subscribe((response:any) => {
      root.sub_list.more_rows = response.more_rows;
      root.sub_list.start = response.start;
      if(response.result == 1){
        let tmpData:Array<any> = response.data;
        if(this.isCategoryView){
          tmpData.forEach((sr)=>{
            sr.category_name = sr.level1_name + ' > ' + sr.level2_name + ((sr.level3_name) ? (' > '+sr.level3_name) : '');
          });
        }
        else{
          // Date View
          tmpData.forEach((sr)=>{
            sr.category_name = sr.level1_name + ' > ' + sr.level2_name + ((sr.level3_name) ? (' > '+sr.level3_name) : '');
          });
        }
        root.sub_list.data = root.sub_list.data.concat(tmpData);
      }
      else{
        this.globalService.snackbar('error', response.message);
      }
    });
  }
  
  toggleResultsView(){
    this.isCategoryView = !this.isCategoryView;
    this.isCategoryViewModel = !this.isCategoryViewModel;
    if(this.isCategoryView){
      this.categoryBtnText = 'Switch to Date View';
    }
    else{
      this.categoryBtnText = 'Switch to Category View';
    }
    this.options.start = 0;
    this.getHistoryListings();
  }  
  
  getCarMakes() {
    this.globalService.callAPI(globalConstants.API_GET_CAR_MAKES, "opr=2").subscribe((data:any) => {
      if(data.result=="1"){
        this.carMakeList = data.data;
        /* FOR SEARCH BOX DROPDOWN */
        var makelist=[];
        for(var i in this.carMakeList){
            this.carMakeList[i].id=this.carMakeList[i].make;
            this.carMakeList[i].text=this.carMakeList[i].make;
            makelist.push(this.carMakeList[i]);
        }
        this.carMakeList=makelist;
      }
    });
  }
  
  changeCar(){
    this.selectedCar=null;
    this.backupCarId = '';
    if(this.vehiclesListIO && this.victim){
      this.vehiclesListIO.observe(this.victim.nativeElement);
    }
  }

  //Issue: Car-370 -> Clear Search when all vehicles are selected
  viewAllCar(){
    this.selectedCar=null;
    this.backupCarId = '';
    if(this.vehiclesListIO && this.victim){
      this.vehiclesListIO.observe(this.victim.nativeElement);
    }
    this.openSearch = false;
    this.clearSearch();
  }
  
  selectVehicle(car){
    this.selectedCar=car;
    this.backupCarId = car.id;
    this.clearSearch();
    this.initializePopupData();
    if(this.vehiclesListIO && this.victim){
      this.vehiclesListIO.unobserve(this.victim.nativeElement);
    }
  }
  
  getFormattedDate(date:string){
    return this.globalService.getFullFormateDate(date,'G','B')
  }
  
  formatVehicleName(v:Array<any>){
    v.forEach((obj: any) => {
      let n = obj.make;
      if (obj.year && obj.year!='0') {
        n = (n? n + ' / ':'') + obj.year
      }
      if (obj.model) {
        n = (n? n + ' / ':'') + obj.model
      }
      obj.car_name = (n ? n : 'N/A')
    })
    if(v.length==1){
      // this.selectedCar = (v[0]);
      this.selectVehicle((v[0]));
    }
  }
  
}
