import { Component, OnInit,ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
import { globalFunctions } from '../../../../environments/globalFunctions';
import {Http, Headers, Response, ResponseContentType} from '@angular/http';
import saveAs from 'file-saver';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-advancereport',
  templateUrl: './advancereport.component.html',
  styleUrls: ['./advancereport.component.scss']
})
export class AdvancereportComponent implements OnInit {

  public excludedVehicleSideBar = false;
  public addExcludedVehicleSideBar = false;
  public searchFilterSideBar = false;
  public reportData:{records:Array<any>,grand_total:any,grand_total_job:any} = {
    records: [],
    grand_total: {},
    grand_total_job: {}
  };
  public options = {
    user_id: '',
    user_type: "User",
    start: 0,
    limit: 10,
    customer_id: '',
    employee_id: '',
    select_sales: '3',
    starting_date: '01/15/2019',
    ending_date: '01/15/2019'
  }
  searchInput: any;
  excludedVehicles = [];
  public subscriptionDataForAPI: Subscription = new Subscription();

  constructor(public globalService: GlobalService,public router: Router,public http:Http,public cdr: ChangeDetectorRef) {
    this.options.user_id = JSON.parse(localStorage.getItem("loggedInUser")).id;   
    setTimeout(()=>{
      let isEnable=this.globalService.getAcl('sales_reports','views');
      if(!(isEnable)){
          this.router.navigate(['shops/action/forbidden']);
      }
    },globalConstants.RELOAD_WAIT);
  }

  ngOnInit() {
    this.globalService.setMetaData("SHOPS","ADVANCED_SALES_REPORT");
    this.getAdvanceReport();
    this.getExcludedVehicles();
  }

  getExcludedVehicles(){
    let content = globalFunctions.stringifyFormData({user_id:this.options.user_id,user_type:this.options.user_type}, null);
    let callApi = this.globalService.callAPI(globalConstants.API_GET_EXCLUDED_VEHICLE_LIST, content);
    
    this.subscriptionDataForAPI = callApi.subscribe((data:any) => {
      if('data' in data && Array.isArray(data.data)){
        this.excludedVehicles = data.data;
      }      
    });
  }

  excludedOut(event: any) {
    if (event.changed == 1) {     
      this.getAdvanceReport();
    } else if (event.changed == 2) {
      this.addExcludedVehicleSideBar = true;
      this.excludedVehicleSideBar = false;
    } else {
      this.excludedVehicleSideBar = false;
    }
  }

  addexcludedOut(event: any) {
    this.addExcludedVehicleSideBar = false;
    if (event.changed) {
      this.excludedVehicles = event.selectedVehicles; 
      let vehicleIds        = this.excludedVehicles.map(itm => itm.Vehicle_id).join(',');
      let content           = globalFunctions.stringifyFormData({user_id:this.options.user_id,user_type:this.options.user_type,vehicles:vehicleIds}, null);
      let callApi           = this.globalService.callAPI(globalConstants.API_ADD_EXCLUDED_VEHICLE, content);
      
      this.subscriptionDataForAPI = callApi.subscribe((data:any) => { 
        if('result' in data && data.result=='1'){
          this.getAdvanceReport();
          this.getExcludedVehicles();
        }        
      });
    }else{
      this.excludedVehicleSideBar = true;
    }
  }

  searchFilterOutput(event: any) {
    if(event.changes === 1){
      this.options.select_sales = event.select_sales.toString();
      if (this.options.select_sales === '5') {
        this.options.starting_date = event.starting_date;
        this.options.ending_date = event.ending_date;
      }
      this.searchFilterSideBar = false;
      this.getAdvanceReport();
    }else if(event.changes === 3){
      this.getAdvanceReport();
    }else if(event.changes === 4){
      this.options.select_sales = event.select_sales.toString();
      this.getAdvanceReport();
    }else{
      this.searchFilterSideBar = false;
    }
  }

  getAdvanceReport() {
    const customerList = JSON.parse(localStorage.getItem("advance-report-selected-customer"))|| [];
    if(customerList.length > 0 && Array.isArray(customerList)){
      this.options.customer_id =   customerList.map(itm => itm.id).join(','); 
    }else{
      this.options.customer_id = '';
    }
    const employeeList = JSON.parse(localStorage.getItem("advance-report-selected-employee"))|| [];
    if(employeeList.length > 0 && Array.isArray(employeeList)){
      this.options.employee_id =   employeeList.map(itm => itm.id).join(','); 
    }else{
      this.options.employee_id = '';
    }
    var content = globalFunctions.stringifyFormData(this.options, null);
    var callApi = this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUEST_ADVANCED_REPORT, content);
    this.subscriptionDataForAPI = callApi.subscribe((data:any) => {      
      this.reportData = data.data;
    });
  }

  getTotalByUnits(arr,key){
    return arr.reduce((accumulator, current) => accumulator + Number(current[key]), 0);
  }

  getTotalByField(arr,key){
      return arr.reduce((accumulator, current) => accumulator + Number(current[key]), 0).toFixed(2)
  }

  getAverage(arr,key){
    const total = arr.filter(i => Number(i[key]) != 0).length;
    const sum = arr.reduce((accumulator, current) => accumulator + Number(current[key]), 0);
    const final = (sum/total);
    if(isNaN(final)){
      return 0;
    }
    return final.toFixed(2);
  }

  totalNetSales(jobs){
    let net_sales = 0;
    jobs.forEach(e => {
      net_sales = net_sales + e.net_sales      
    });
    return (net_sales/jobs.length).toFixed(2);
  }

  totalGPM(jobs){
    let gpm = 0;
    let count = 0;
    jobs.forEach(e => {
      if(e.gpm != 0){
        gpm = gpm + e.gpm;
        count += 1;
      }
    });
    return (gpm/count).toFixed(2);
  }

  exportInPDF(){
    let url             = globalConstants.API_GET_SERVICE_REQUEST_ADVANCED_REPORT;
    let data            = Object.assign({},this.options);
    var content = globalFunctions.stringifyFormData(Object.assign(data,{export_pdf: 1,invoke_download:this.globalService.isDeviceiOS()?1:0}), null);
    this.globalService.downloadFileWithPost(url,content,"service_request_advanced_report");
  }

  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
}  
}
