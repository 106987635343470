import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog'
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
import { Router } from '@angular/router';
const SHOP_MOBILE_NO_IGNORE_LIST = "shop-mobile-no-ignore-list-";
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-mobile-no-ignore-list',
  templateUrl: './mobile-no-ignore-list.component.html',
  styleUrls: ['./mobile-no-ignore-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MobileNoIgnoreListComponent implements OnInit {

  public currentUser: any   = {};
  public openStateSearch    = false;
  public displayedColumns   = ['name', 'phone_number', 'add_date', 'Actions'];
  public tablesData         = this;
  public searchByArray:Array<any> = [{id:1,text:"Title"},{id:2,text:"Phone Number"}];
  public url = "";
  public options = {
    user_type: "User",
    user_id: "",
    shop_id: "",
    search_by: "",
    search_keyword: "",
    start: 0,
    limit: 10,
  }
  public isEditEnabled: boolean = true;
  public isDelEnabled: boolean = true;
  public isAddEnabled: boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public dataSource: MatTableDataSource<ignoreListData> = new MatTableDataSource();
  public record :any =[];
  public isReady: boolean = false;
  public recordsTotal: any;
  constructor(public gs: GlobalService, public router: Router, public dialog: MatDialog) {
    this.currentUser = this.gs.getCurrentUser();
    if (!this.currentUser) {
      this.router.navigate(["/shops"]);
    }
    setTimeout(() => {
      this.isEditEnabled = this.gs.getAcl('sms_mobile_no_ignore', 'edits');
      this.isDelEnabled  = this.gs.getAcl('sms_mobile_no_ignore', 'deletes');
      this.isAddEnabled  = this.gs.getAcl('sms_mobile_no_ignore', 'addNew');      
      let inSubscription: boolean = true;
      let isEnable = this.gs.getAcl('sms_mobile_no_ignore', 'views');
      if (!(isEnable) || !(inSubscription)) {
        this.router.navigate(['shops/action/forbidden']);
      }
    }, globalConstants.RELOAD_WAIT);

  }

  ngOnInit() {
    this.reinitializeOptions();
    if (this.handleFormEmpty(null)) {
      this.openStateSearch = true;
    } else {
      this.openStateSearch = false;
    }
    this.isReady = true;
    //this.gs.setMetaData("SHOPS", "SMS_TEMPLATE_LIST");  
    this.setDataTable();
  }

  addNew() {
    this.router.navigate(["/shops/add-ignore-mobile-no"]);
  }

  onEdit(row:any){
    let id = row.id;
    this.router.navigate(['/shops/edit-ignore-mobile-no/' + id]);
  }


  handleNumberDelete(row: any) {
    this.gs.confirmDialog("yes", "Are you sure you want to remove this number?").subscribe((r: any) => {
      if (r != undefined && r == "yes") {
        let data = { user_id: this.currentUser.id, user_type: this.currentUser.user_type, phone_number: row.phone_number };
        this.gs.callAPI(globalConstants.API_SHOP_SMS_DELETE_IGNORE_MOBILE_NO_URL, data).subscribe((r) => {
          let message = r.message;
          if (r.result == 1) {
            this.gs.snackbar('success', message);
            this.setDataTable();
          } else {
            this.gs.snackbar('error', message);
          }
        }, (error) => { });
      }
    })
  }


  handleSearch(event) {
    this.options.start = 0;
    this.setDataTable();
  }

  handleClearSearch() {
    this.openStateSearch = false;
    this.gs.localGetterSetter("", this.options, SHOP_MOBILE_NO_IGNORE_LIST);
    this.options.search_keyword = "";
    this.reinitializeOptions();
    this.setDataTable();
  }

  reinitializeOptions() {
    this.options = this.gs.localGetterSetter(this.options, this.options, SHOP_MOBILE_NO_IGNORE_LIST);
    this.options.user_type = this.currentUser.user_type;
    this.options.user_id = this.currentUser.id;
    this.options.shop_id = this.currentUser.shop_id;
    this.options.search_by  = '';
  }

  paginate(event, type) {
    this.scrolling();
    if (type == "next") {
      this.options.start = this.options.start + this.options.limit;

    } else {
      this.options.start = this.options.start - this.options.limit;

    }
    this.setDataTable();
  }

  scrolling(){
    this.gs.setScrolling('.car-datatable', 1000, -20);
  }

  getUpperLimit(currentLastLimit, total) {
    if (currentLastLimit > total) {
      return total
    } else {
      return currentLastLimit
    }
  }

  firstList() {
    return this.gs.firstList(SHOP_MOBILE_NO_IGNORE_LIST, this.options);
  }

  lastList() {
    return this.gs.lastList(SHOP_MOBILE_NO_IGNORE_LIST);
  }

  paginateByLimit(event){
    this.gs.setScrolling('body', 600, 0);
    this.options.limit = event.pageSize;
    this.options.start = 0;
    this.scrolling();
    this.setDataTable();
}
  handleFormEmpty(button) {
    if (this.options.search_keyword == "") {
      if (button) { button.color = "primary" }
      return false;
    } else {
      if (button) { button.color = "accent" }
      return true;
    }
  }

  getTotalRecords() {
    let totalRecords=parseInt(localStorage.getItem(SHOP_MOBILE_NO_IGNORE_LIST+'totalRecords'));
    return (isNaN(totalRecords) ? 0 : totalRecords );
}

  setDataTable() {
   this.record = [];
    var callApi = this.gs.callAPI(globalConstants.API_SHOP_SMS_IGNORE_MOBILE_NO_URL, this.options);
    callApi.subscribe((data:any) => {
      if (data.data != undefined) {
        let moreRows = 'false';
        let firstList = 'false';
        this.gs.localGetterSetter("totalRecords", data.recordsTotal, SHOP_MOBILE_NO_IGNORE_LIST);
        if (data.more_rows == "true") {
          moreRows = "true";
        }
        this.gs.localGetterSetter("more_rows", moreRows, SHOP_MOBILE_NO_IGNORE_LIST);
        if (data.start >= 0 && data.start <= 5) {
          firstList = "true";
        }
        this.gs.localGetterSetter("first_list", firstList, SHOP_MOBILE_NO_IGNORE_LIST);
        if (data.data.length != undefined) {
          this.recordsTotal = data.data.length;
          for (var i = 0; i < data.data.length; i++) {
            this.AddRecord(data.data[i]);
          }
          this.dataSource = new MatTableDataSource(this.record);
          this.dataSource.sort = this.sort;
          this.gs.localGetterSetter("current-visible", this.recordsTotal, SHOP_MOBILE_NO_IGNORE_LIST);
        } else {
          this.gs.localGetterSetter("current-visible", '1', SHOP_MOBILE_NO_IGNORE_LIST);
          this.recordsTotal = "1";
          this.AddRecord(data.data);
          this.dataSource = new MatTableDataSource(this.record);
          this.dataSource.sort = this.sort;
        }
        this.gs.localGetterSetter(null, this.options, SHOP_MOBILE_NO_IGNORE_LIST);
      } else {
        this.dataSource = new MatTableDataSource(this.record);
        this.gs.localGetterSetter("totalRecords", '0', SHOP_MOBILE_NO_IGNORE_LIST);
      }
      this.gs.getSetItems('userAlreadyExist', '');
    });
  }

  AddRecord(record) {
      this.record.push(this.createRecord(record));
  }

  public createRecord(record) {
    return {
      id: record.id,
      name:record.name,
      phone_number: record.phone_number,
      add_date: record.add_date
    };
  }
}

export interface ignoreListData {
      name:any,
      phone_number: any,
      add_date: any
}