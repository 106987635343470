<div class="panel panel-primary" id="mainCompotents">
  <div class="panel-heading padding-left-30">
    <h3 class="panel-title">Return Queue Detail</h3>
    <button mat-mini-fab class="custom-btn-back" (click)="gs.goBack();">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <!---->
  <div class="panel-body bgcolor-lightgrey">
    <div class="jumbotron inspection-report padding-25 car-view-str margin-bottom-15">
      <!-- <h3 class="margin-top-0">Return Queue Detail</h3> -->
      <!-- <hr> -->
      <div class="row">
        <div class="col-sm-12 margin-bottom-10" *ngIf="detailData.added_by_name">
          <div class="row">
            <div class="col-sm-3">
              <label>Added By : </label>
            </div>
            <div class="col-sm-9 break-word">
              <span>{{detailData.added_by_name}}</span>
            </div>
          </div>
        </div>
        <div class="col-sm-12 margin-bottom-10" *ngIf="detailData.add_date">
          <div class="row">
            <div class="col-sm-3">
              <label>Vendor Pickup Date : </label>
            </div>
            <div class="col-sm-9 break-word">
              <span>{{gs.getFullFormateDate(detailData.add_date,'G','H')}}</span>
            </div>
          </div>
        </div>
        <div class="col-sm-12 margin-bottom-10" *ngIf="detailData.description">
          <div class="row">
            <div class="col-sm-3">
              <label>Description : </label>
            </div>
            <div class="col-sm-9 break-word" [innerHTML]="detailData.description">
            </div>
          </div>
        </div>
        
        <div class="col-sm-12 " *ngIf="detailData.files">
          <div class="row">
            <div class="col-sm-3">
              <label>Files : </label>
            </div>
            <div class="col-sm-9 upload-image break-word">
              <ul id="vin_photo-ul" class="list-inline margin-bottom-0 margin-top-5">
                <li *ngFor="let doc of detailData.files;let i=index">
                  <a href="javascript:void(0);" class="img-set thumbnail relative">
                    <span class="fa far fa-file-pdf fa-4x" *ngIf="doc.type==3"></span>
                    <span class="fa far fa-file-word fa-4x" *ngIf="doc.type==4"></span>
                    <img class="show-image" src="{{filePath}}{{doc.name}}" *ngIf="doc.type==1">
                    <!-- <i class="fa fa-trash remove-img" (click)="deleteImg(i)"></i> -->
                    <!-- <div class="image-loader" *ngIf="request_document_loader">
                  <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                </div> -->
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-12 margin-bottom-10" *ngIf="detailData.modify_date">
          <div class="row">
            <div class="col-sm-3">
              <label>Modified On  : </label>
            </div>
            <div class="col-sm-9 break-word">
              <span>{{gs.getFullFormateDate(detailData.modify_date,'G','H')}}</span>
            </div>
          </div>
        </div>
        <div class="col-sm-12 margin-bottom-10" *ngIf="detailData.status">
            <div class="row">
              <div class="col-sm-3">
                <label>Status : </label>
              </div>
              <div class="col-sm-9 break-word">
                <span >{{showStatus(detailData.status)}}</span>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="panel-body bgcolor-lightgrey">

    <div class="display-full relative">
      <div class="car-datatable mat-elevation-z1">
        <div class="table-responsive relative">
          <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg">

            <ng-container matColumnDef="Name">
              <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Name </mat-header-cell>
              <mat-cell *matCellDef="let row" data-role="auto"> {{row.name}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Quantity">
              <mat-header-cell *matHeaderCellDef data-role="auto"> Quantity </mat-header-cell>
              <mat-cell *matCellDef="let row" data-role="auto"> {{row.quantity }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="in Stock">
              <mat-header-cell *matHeaderCellDef data-role="auto"> Request ID </mat-header-cell>
              <mat-cell *matCellDef="let row" data-role="auto"> {{row.request_number }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="add_date">
              <mat-header-cell *matHeaderCellDef data-role="auto"> Added on </mat-header-cell>
              <mat-cell *matCellDef="let row" data-role="auto"> {{row.add_date }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="partnumber">
              <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Part Number </mat-header-cell>
              <mat-cell *matCellDef="let row" data-role="auto"> {{row.part_number}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
          <div class="padding-15 text-center" [style.display]="getTotalRecords() < 1 ? '' : 'none'">
            No Records Available
          </div>
        </div>
        <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
          <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="options.limit"
            [pageSizeOptions]="[5, 10,25, 100]" (page)="paginateByLimit($event)">
          </mat-paginator>

          <div class="mat-paginator table-paging">
            <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
            <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
              <mat-icon>navigate_before</mat-icon>
            </button>
            <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
              <mat-icon>navigate_next</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>