<div mat-dialog-title class="relative">
  Profile Log Detail
  <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
    <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="table-responsive">
    <table class="table table-striped table-hover table-bordered " *ngIf="oldData && newData">

      <thead>
        <tr>
          <th>Name</th>
          <th>Old Value</th>
          <th>New value</th>
        </tr>
      </thead>

      <tbody>
        <tr [class.log-diff-detail-bg-red]="oldData.first_name!=newData.first_name">
          <td>First Name </td>
          <td>{{( oldData.first_name ? oldData.first_name : '-' )}}</td>
          <td>{{( newData.first_name ? newData.first_name : '-' )}}</td>
        </tr>
        <tr [class.log-diff-detail-bg-red]="oldData.middle_name!=newData.middle_name">
          <td>Middle Name </td>
          <td>{{( oldData.middle_name ? oldData.middle_name : '-' )}}</td>
          <td>{{( newData.middle_name ? newData.middle_name : '-' )}}</td>
        </tr>
        <tr [class.log-diff-detail-bg-red]="oldData.last_name!=newData.last_name">
          <td>Last Name </td>
          <td>{{( oldData.last_name ? oldData.last_name : '-' )}}</td>
          <td>{{( newData.last_name ? newData.last_name : '-' )}}</td>
        </tr>
        <tr [class.log-diff-detail-bg-red]="oldData.email!=newData.email">
          <td>Email </td>
          <td>{{( oldData.email ? oldData.email : '-' )}}</td>
          <td>{{( newData.email ? newData.email : '-' )}}</td>
        </tr>
        <tr [class.log-diff-detail-bg-red]="oldData.mobile_phone!=newData.mobile_phone">
          <td>Mobile Phone </td>
          <td>{{( oldData.mobile_phone ? oldData.mobile_phone : '-' )}}</td>
          <td>{{( newData.mobile_phone ? newData.mobile_phone : '-' )}}</td>
        </tr>
        <tr [class.log-diff-detail-bg-red]="oldData.home_phone!=newData.home_phone">
          <td>Home Phone </td>
          <td>{{( oldData.home_phone ? oldData.home_phone : '-' )}}</td>
          <td>{{( newData.home_phone ? newData.home_phone : '-' )}}</td>
        </tr>
        <tr [class.log-diff-detail-bg-red]="oldData.business_phone!=newData.business_phone">
          <td>Business Phone </td>
          <td>{{( oldData.business_phone ? oldData.business_phone : '-' )}}</td>
          <td>{{( newData.business_phone ? newData.business_phone : '-' )}}</td>
        </tr>
        <tr [class.log-diff-detail-bg-red]="oldData.address1!=newData.address1">
          <td>Address1 </td>
          <td>{{( oldData.address1 ? oldData.address1 : '-' )}}</td>
          <td>{{( newData.address1 ? newData.address1 : '-' )}}</td>
        </tr>
        <tr [class.log-diff-detail-bg-red]="oldData.address2!=newData.address2">
          <td>Address2 </td>
          <td>{{( oldData.address2 ? oldData.address2 : '-' )}}</td>
          <td>{{( newData.address2 ? newData.address2 : '-' )}}</td>
        </tr>
        <tr [class.log-diff-detail-bg-red]="oldData.zip!=newData.zip">
          <td>zip </td>
          <td>{{( oldData.zip ? oldData.zip : '-' )}}</td>
          <td>{{( newData.zip ? newData.zip : '-' )}}</td>
        </tr>
        <tr [class.log-diff-detail-bg-red]="oldData.country!=newData.country">
          <td>Country </td>
          <td>{{( oldData.country ? oldData.country : '-' )}}</td>
          <td>{{( newData.country ? newData.country : '-' )}}</td>
        </tr>
        <tr [class.log-diff-detail-bg-red]="oldData.state!=newData.state">
          <td>State </td>
          <td>{{( oldData.state ? oldData.state : '-' )}}</td>
          <td>{{( newData.state ? newData.state : '-' )}}</td>
        </tr>
        <tr [class.log-diff-detail-bg-red]="oldData.city!=newData.city">
          <td>City </td>
          <td>{{( oldData.city ? oldData.city : '-' )}}</td>
          <td>{{( newData.city ? newData.city : '-' )}}</td>
        </tr>
        <tr [class.log-diff-detail-bg-red]="oldData.request_origin!=newData.request_origin">
            <td>Request Origin </td>
            <td>{{( oldData.request_origin ? originType(oldData.request_origin) : '-' )}}</td>
            <td>{{( newData.request_origin ? originType(newData.request_origin) : '-' )}}</td>
        </tr>
      </tbody>
    </table>
  </div>

</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
  <div class="text-right">
    <button type="button" mat-raised-button mat-dialog-close class="font-bold" title="Close">Close</button>
  </div>
</mat-dialog-actions>