import { Component, OnInit, ChangeDetectorRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { globalFunctions } from '../../../environments/globalFunctions';
import { GlobalService } from '../../../environments/global.service';
import { globalConstants } from '../../../environments/globalConstants';
import { ImagecroppieComponent } from '../../imagecroppie/imagecroppie.component';
import { MatDialog } from '@angular/material/dialog';
import { Router,ActivatedRoute } from '@angular/router';
import {ReCaptchaComponent} from 'angular2-recaptcha';
import { AppComponent } from '../../app.component';
import { Subscription } from 'rxjs';
const EMAIL_REGEX = globalConstants.EMAIL_REGEX;
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegistrationComponent implements OnInit {
  public shop: FormGroup;
  public submitted = false;
  public currentBusinesslogo = '';
  public phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public faxMask = globalConstants.faxMask;
  public selectedState:any;
  public selectedCity: string;
  public selectedCountry:any= "USA";
  public countries: any = [];
  public states: any = [];
  public cities: any = [];
  public disableStates = true;
  public disableCities = true;
  public countriesArray = {};
  public termAndCond=false;
  public zipLabel = 'Zip Code';
  @ViewChild(ReCaptchaComponent) captcha: ReCaptchaComponent;
  public site_key = globalConstants.GOOGLE_RECAPTCHA_SITE_KEY;
  public subscriptionDataForAPI: Subscription = new Subscription();

  constructor(
    public app:AppComponent,
    private ref: ChangeDetectorRef,
    private router: Router, 
    private globalService: GlobalService, 
    private formBuilder: FormBuilder, 
    public dialog: MatDialog,
    public route: ActivatedRoute
  ) {
      this.route.queryParams.subscribe((params:any)=>{
          if((Object.keys(params).length === 0) == false){
             localStorage.setItem("wpi",params.wpi);
          }
      })
   }

  ngOnInit() {
    let user=this.globalService.getCurrentUser()
    if (user != undefined && user.user_type == "User") {
      this.app.showCommonHeader=true;
      this.app.showShopView = true;
      this.globalService.controlDashboardNavigation();
    }
    this.initShopFormbuilder();
    this.getCountries();
     /**
      * update Zip codee validation
     */
     this.shop.get("country").valueChanges.subscribe(country_name => {               
      if(country_name && Array.isArray(this.countries)){                 
           let zRegex = this.countries.find(res=>(res.country_id==country_name || res.country_name==country_name));
           if(zRegex && 'zipcode_regex' in zRegex){
               this.zipLabel = zRegex.zipcode_label;
               this.shop.controls.zipcode.setValidators([Validators.required, Validators.pattern(zRegex.zipcode_regex)]);
               this.shop.controls.zipcode.updateValueAndValidity();
           }          
       }                
   });
  
  }
  initShopFormbuilder() {
    this.shop = this.formBuilder.group({
      shop_name: ['', [Validators.required]],
      owner_name: [''],
      contact_name: [''],
      first_name: ['', [Validators.required, Validators.pattern(globalConstants.NAME_REGEX)]],
      middle_name: ['', [Validators.pattern(globalConstants.NAME_REGEX)]],
      last_name: ['', [Validators.required, Validators.pattern(globalConstants.NAME_REGEX)]],
      address_one: ['',[Validators.required]],
      address_two: [''],
      city: ['',Validators.required],
      state: ['',Validators.required],
      frm_state: [''],
      zipcode: ['', [Validators.required, Validators.pattern("^[0-9]+$")]],
      country: ['USA'],
      frm_country: ['USA' + "|" + 'USA'],
      shop_business_phone1: ['', [Validators.required]],
      shop_business_phone2: [''],
      shop_cell_phone1: [''],
      shop_cell_phone2: [''],
      shop_number: [''],
      email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
      web_address: [''],
      licenses: [''],
      add_date: [''],
      modify_date: [''],
      status: [''],
      tax_rate: [''],
      supplies_charges: [''],
      shop_fax: [''],
      business_logo_imagedata: [''],
      user_type: ['User'],
      terms_condition:['',Validators.required],
      // profile_type: ["1", [Validators.required]],
      g_recaptcha_response: ['',Validators.required]
    });
  }
  checkIfValid() {
    if (this.shop.valid) {
      this.submitted = false;
    }
  }
  signUp() {
    Object.keys(this.shop.controls).map((controlName) => {
      this.shop.get(controlName).markAsTouched({ onlySelf: true });
    });
    if (this.shop.valid) {
      this.submitted = false;
      let data = new FormData();
      for (let i in this.shop.value) {
        data.append(i, this.shop.value[i]);
      }

      const timings = [
        {"weekdayid":0,"start_time":"08:00","close_time":"17:00"},
        {"weekdayid":1,"start_time":"08:00","close_time":"17:00"},
        {"weekdayid":2,"start_time":"08:00","close_time":"17:00"},
        {"weekdayid":3,"start_time":"08:00","close_time":"17:00"},
        {"weekdayid":4,"start_time":"08:00","close_time":"17:00"},
        {"weekdayid":5,"start_time":"","close_time":""},
        {"weekdayid":6,"start_time":"","close_time":""}
      ];
      data.append('shop_timings', JSON.stringify(timings));
      this.globalService.formData(globalConstants.API_ADD_SHOP, data).subscribe((data:any) => {
        let message = data.message;
        if (data.result == 1) {
          this.router.navigate(["/shops"]);
          this.globalService.snackbar('success', message);
        } else {
          this.globalService.snackbar('error', message);
        }
      });
    } else {
      this.submitted = true;
      this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
  }
  onlyNumericKey(event) {
    return globalFunctions.onlyDecimalNumberKey(event);
  }
  phoneValidation(key) {
    this.globalService.preferedServicesPhoneValidity(this.shop, key);
    this.checkIfValid();
  }
  getCountries() {
    this.globalService.callAPI(globalConstants.API_GET_COUNTRIES_LIST, "opr=1").subscribe((data: any) => {
      if (data.result == "1") {
        this.countries = data.data;
        for (var i in this.countries) {
          this.countries[i].id = this.countries[i].country_id;
          this.countries[i].text = this.countries[i].country_name;
        }
        this.shop.controls.country.setValue('USA');
        this.getStates('USA');
      } else {
        this.countries = [];
      }
    });
  }

  getStates(countryID) {
    if (typeof(countryID) == 'undefined' || countryID == "") {
        this.disableStates = true;
        this.disableCities = true;
        this.cities=[];
    } else {
        this.disableCities = true;
        this.cities=[
            { id: "", text: "Select City" }
        ];
        let stateDDLPlaceholder = [
            { 
                id: "",
                state_id: "",
                state_name: "Select State",
                country_id: "",
                latitude: "",
                longitude: "",
                tax_rate: "",
                state_code: ""
             }
        ];
        this.states = [];
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_STATES_URL, "opr=1&country_id=" + countryID).subscribe((data:any) => {
            var responseData = data;
            this.disableStates = false;
            if (responseData.result == "1") {
                this.states  = stateDDLPlaceholder.concat(responseData.data);
                for(var i in this.states){
                    this.states[i].id=this.states[i].state_id;
                    this.states[i].text=this.states[i].state_name;  
               }
            } else {
                this.states = [];
                this.cities = [];
                this.disableStates = false;
                this.selectedState = [{ id: "", text: "No state found" }];
                this.disableCities = true;
            }
        });
    }
  }

 /* GET CITIES */
 getCities(stateId) {
  if (typeof(stateId) == 'undefined' || stateId == "") {
      this.cities = [];
      this.disableCities = true;
  } else {
     this.cities = [];
     let cityDDLPlaceholder=[{
      id: "",
      city_id: "",
      city_name: "Select City",
      state_id: "",
      country_id: "",
      latitude: "",
      longitude: ""
      }];
      this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_CITIES_URL, "opr=1&state_id=" + stateId).subscribe((data:any) => {
          var responseData = data;
          if (responseData.result == "1") {
              this.disableCities = false;
                  this.cities = cityDDLPlaceholder.concat(responseData.data);
                  for(var i in this.cities){
                      this.cities[i].id=this.cities[i].city_name;
                      this.cities[i].text=this.cities[i].city_name;          
                  }
          } else {
              this.cities = [{id: "", text: "No city found"}];
              this.disableCities = false;
          }
      })
  }
}

handleCountryDropdownChange(countryId) {
  this.getStates(countryId);
  let countryData = this.countries.find(countryInfo => countryId == countryInfo.id);
  this.shop.controls.country.setValue(countryData.country_name);
  this.shop.controls.state.setValue('');
  this.shop.controls.city.setValue('');
  this.selectedState = '';
  this.selectedCity = '';
}

  handleStateDropdownChange(stateId) {
    if (stateId !== '') {
      this.getCities(stateId);
      let stateData = this.states.find(stateInfo => stateId == stateInfo.id);
      this.shop.controls.state.setValue(stateData.state_name);
      this.shop.controls.city.setValue('');
      this.selectedCity = 'Select City';
    } else {
      this.shop.controls.state.setValue('');
      this.shop.controls.city.setValue('');
      this.selectedCity = 'Select City';
    }
  }

  handleCityDropdownChange(cityName) {
    if (cityName !== 'Select City') {
      this.shop.controls.city.setValue(cityName);
    } else {
      this.shop.controls.city.setValue('');
    }
  }
  
  getFile(evt, key) {
    let validImage  = this.globalService.validateFiles('image', evt.target.files);
    if (validImage) {
      this.imageCroppieDialog(evt.target.files[0]);
    }
  }
  _handleReaderLoaded(readerEvt) {
    let binaryString = readerEvt.target.result;

    this.currentBusinesslogo = binaryString;
    this.shop.controls.business_logo_imagedata.setValue(binaryString);

  }
  imageCroppieDialog(data): any {
    let dialogRef = this.dialog.open(ImagecroppieComponent, {
      panelClass: ['image-cropp-dialog','car-dialog-form'],
      width: '900px',
      data: { imgfile: data }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.data != null) {
        this.currentBusinesslogo = result.data;
        this.shop.controls.business_logo_imagedata.setValue(result.data);
        this.ref.detectChanges();
      }
      let ele = <HTMLInputElement>document.getElementById('businessLogo');
      ele.value = '';
    });
  }
  deleteBusinessLogo() {
    this.currentBusinesslogo = '';
    this.shop.controls.business_logo_imagedata.setValue('');
    this.shop.value.part_pic = '';
    let ele = <HTMLInputElement>document.getElementById('businessLogo');
    ele.value = '';
  }
  
  termAndCondition() {
    let url=globalConstants.S3_BUCKET_URL+ 'CAR-Contract/CAR_Base_Contract.pdf';
    let localFileName = 'CAR_Base_Contract.pdf';
    this.globalService.downloadAsset(url,localFileName);
  }
  validateCityState(){
    this.globalService.validateState(this.shop, ['state','city']);
    this.checkIfValid();
  }

  setCountryStateCityViaZipCode(){
    let payload = {
         user_type: "User",
         zip_code: this.shop.controls.zipcode.value
    }
    this.globalService.callAPI(globalConstants.API_GET_STATE_CITY_VIA_ZIPCODE,payload).subscribe((response:any)=>{
        if(response.result == 1){
            if(response.data.country_info.country_id !== 0 
              && response.data.state_info.state_id !== 0 
              &&  response.data.city_info.city_id !== 0){
                this.selectedCountry = response.data.country_info.country_code;
                this.shop.controls.country.setValue(response.data.country_info.country_name);
                this.getStates(response.data.country_info.country_code);
                this.selectedState = response.data.state_info.state_id;
                this.shop.controls.state.setValue(response.data.state_info.state_name);
                this.getCities(response.data.state_info.state_id)
                this.selectedCity = response.data.city_info.city_name;
                this.shop.controls.city.setValue(this.selectedCity);
            }
        }
  })
}

handleCheckTermsAndCondition(event){
    if(event == true){
    this.shop.controls.terms_condition.setValue(1);
    }else{
      this.shop.controls.terms_condition.setValue('');
    }
}

handleReCaptchaResponse(captchaResponse: string) {
  this.shop.controls.g_recaptcha_response.setValue(captchaResponse);
}

ngOnDestroy(){
  this.subscriptionDataForAPI.unsubscribe();
} 
}
