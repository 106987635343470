import { Component, OnInit,ViewChild } from '@angular/core';
import { GlobalService } from '../../environments/global.service';
import { globalConstants } from '../../environments/globalConstants';
import { DataSource } from '@angular/cdk/collections';
// import { MatPaginator, MatSort } from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject, Observable } from 'rxjs';
import { globalFunctions } from '../../environments/globalFunctions';
import { ScrollToService } from 'ng2-scroll-to-el';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { MatTableDataSource } from '@angular/material/table';
const SHOP_EMP_LIST = 'shop-notification-';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html'
})
export class NotificationComponent implements OnInit {
  public notifications = []
  public user;
  displayedColumns = ['msg', 'add_date', 'Actions'];
  dataSource: MatTableDataSource<NotifyModel> = new MatTableDataSource();
  public options = {
    user_type: "",
    user_id: "",
    search_keyword: "",
    search_by:'0',
    start: 0,
    limit: 10,
  };
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public record: any;
  public recordsTotal: string;
  constructor(public router: Router,public globalservice: GlobalService,private scrollService: ScrollToService,private app:AppComponent) { }

  ngOnInit() {
    this.globalservice.setMetaData("SHOPS","NOTIFICATIONS")
    this.user=this.globalservice.getCurrentUser();
    let data={user_id: this.user.id, user_type: this.user.user_type }
    this.setDataTable();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  setDataTable() {
    this.record= [];
    this.options.user_id = this.user.id;
    this.options.user_type = this.user.user_type;
    var content = globalFunctions.stringifyFormData(this.options, null);
    var callApi = this.globalservice.callAPI(globalConstants.API_NOTIFICATIONS_LIST, content);
    callApi.subscribe((data:any) => {
      if (data.data != undefined) {
        let moreRows = 'false';
        let firstList = 'false';
        this.globalservice.localGetterSetter("totalRecords", data.recordsTotal, SHOP_EMP_LIST);
        if (data.more_rows == "true") {
          moreRows = "true";
        }
        this.globalservice.localGetterSetter("more_rows", moreRows, SHOP_EMP_LIST);
        if (data.start >= 0 && data.start <= 5) {
          firstList = "true";
        }
        this.globalservice.localGetterSetter("first_list", firstList, SHOP_EMP_LIST);
        if (data.data.length != undefined) {
          this.recordsTotal = data.data.length;
          for (var i = 0; i < data.data.length; i++) {
            this.AddRecord(data.data[i])
          }
          this.dataSource = new MatTableDataSource(this.record)
          this.dataSource.sort = this.sort;
          this.globalservice.localGetterSetter("current-visible", this.recordsTotal, SHOP_EMP_LIST);
        } else {
          this.globalservice.localGetterSetter("current-visible", '1', SHOP_EMP_LIST)
          this.recordsTotal = "1";
          this.AddRecord(data.data)
          this.dataSource = new MatTableDataSource(this.record)
          this.dataSource.sort = this.sort;
        }
        this.globalservice.localGetterSetter(null, this.options, SHOP_EMP_LIST);
      } else {
        this.globalservice.localGetterSetter("totalRecords", '0', SHOP_EMP_LIST);
      }
      this.globalservice.getSetItems('userAlreadyExist', '');
    },(error)=>{});
  }

  AddRecord(record) {
    var data = this.createRecord(record);
    this.record.push(data);
}


private createRecord(record) {
  let j=JSON.parse(record.notification_payload);
  let DateFormat=this.globalservice.DateFormat();
  return {
    refcode: record.id,
    msg: record.msg,
    date: this.globalservice.getFullFormateDate(record.add_date,DateFormat,'H'),
    read_status: record.read_status,
    module_name: record.module_name,
    module_action: record.module_action,
    payload:record.notification_payload,
    payload_url : ( j.redirect_url ? j.redirect_url : "" )
  };
}

  getTotalRecords() {
    return parseInt(localStorage.getItem(SHOP_EMP_LIST + 'totalRecords'));
  }
  paginate(event, type) {
    // this.scrolling();
    if (type == "next") {
      this.options.start = this.options.start + this.options.limit;
    } else {
      this.options.start = this.options.start - this.options.limit;
    }
    this.setDataTable();
  }

  firstList() {
    return this.globalservice.firstList(SHOP_EMP_LIST, this.options);
  }

  lastList() {
    return this.globalservice.lastList(SHOP_EMP_LIST);
  }
  getUpperLimit(currentLastLimit, total) {
    if (currentLastLimit > total) {
      return total
    } else {
      return currentLastLimit
    }
  }
  scrolling() {
    this.scrollService.scrollTo('.car-datatable', 1000, -20);
  }
  notifyView(data){
    data.id=data.refcode;
    data.notification_payload=data.payload;
    this.app.showNotification(data);
  }
  paginateByLimit(event) {
    this.scrolling();
    this.options.limit = event.pageSize;
    this.options.start = 0;
    this.setDataTable();
  }
}
export interface NotifyModel {
  refcode: any;
  msg: any;
  read_status: boolean;
  date: any;
  module_name:any;
  payload:any;
  payload_url:any;
  module_action:any;
}
