import { Component, OnInit,Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
import { globalFunctions } from '../../../../environments/globalFunctions';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-dfs-edit',
  templateUrl: './dfs-edit.component.html',
  styleUrls: ['./dfs-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DfsEditComponent implements OnInit {
  public dfsData:any;
  public editDfsForm:FormGroup;
  public scheduleByDate : any ;
  public minDate = new Date();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DfsEditComponent>,
    private formBuilder: FormBuilder, 
    private globalService: GlobalService,
  ) { 
     this.dfsData = data;
  }

  ngOnInit(): void {
    this.editDfsForm = this.formBuilder.group({
      schedule_by_miles:['', [Validators.pattern(/^\d+(\.\d)?$/)]],
      schedule_by_date :[''],
    })
    this.scheduleByDate = new Date(this.dfsData.scheduleByDate);
    this.editDfsForm.controls.schedule_by_miles.setValue(Number(this.dfsData.scheduleByMiles));
  }

  validateNumbersWithDecimal(event) {
    return globalFunctions.validateNumbersWithDecimal(event,2);
  }
   /**
   * validates the form control's value on paste event.
   * ensure that validation messages are displayed if the pasted value is invalid.
   */
   validateFieldOnPaste(controlName: string) {
    const control = this.editDfsForm.get(controlName);
    control.markAsTouched();
  }

  handleUpdateReminder(){
    var currentLoginUser: any = JSON.parse(localStorage.getItem("loggedInUser"));
    let scheduleByDateAPI= moment(this.scheduleByDate).format('MM/DD/YYYY');
    let payload ={
      "user_id":currentLoginUser.id,
      "user_type":currentLoginUser.user_type,
      "schedule_by_miles":this.editDfsForm.value.schedule_by_miles,
      "schedule_by_date":scheduleByDateAPI,
      "dfs_id":this.dfsData.dfsId
    }
    this.globalService.callAPI(globalConstants.API_UPDATE_DFS_REMINDER, payload).subscribe((res:any)=>{
      if (res.result == "1") {
        this.globalService.snackbar("success", res.message);
        this.dialogRef.close({
          status:1
       });
      }else{
        this.globalService.snackbar("error", res.message);
      }
    });
  }
}
