<div class="row alert-row">
    <div class="col-xs-12 banner-alert-section">
      <mat-toolbar class="banner-alert-bar" >
          <div class="alertbanner-content">
              {{waringMsg}}
          </div>
          <div class="alert-btn-wrap">
            <button mat-raised-button class="font-bold mat-elevation-z0" color="accent" (click)="sendAddOnsRequest()">
              <span>Send Request Now</span>
            </button>
          </div>
        </mat-toolbar>
    </div>
  </div>