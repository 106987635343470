import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, Params } from '@angular/router';
import {NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {MatTab} from '@angular/material/tabs';
import {MatDialog} from '@angular/material/dialog';
import {Location, LocationStrategy, PathLocationStrategy, DatePipe} from '@angular/common';
import {globalConstants} from "../../../../environments/globalConstants";
import {GlobalService} from "../../../../environments/global.service";
import { UpdateworkhoursComponent } from '../updateworkhours/updateworkhours.component';
import {Subscription} from 'rxjs';

export class AppDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
   if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
     const str = value.split('/');
     const year = Number(str[2]);
     const month = Number(str[1]) - 1;
     const date = Number(str[0]);
     return new Date(year, month, date);
   }
   const timestamp = typeof value === 'number' ? value : Date.parse(value);
   return isNaN(timestamp) ? null : new Date(timestamp);
 }

  format(date: Date, displayFormat: Object): string {
      if (displayFormat == "input") {
          let day = date.getDate();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();
          return this._to2digit(month)+ '/' + this._to2digit(day)  + '/' + year;
      } else {
          return date.toDateString();
      }
  }

  private _to2digit(n: number) {
      return ('00' + n).slice(-2);
  }
}

export const APP_DATE_FORMATS =
{
  parse: {
      dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
  },
  display: {
      // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
      dateInput: 'input',
      monthYearLabel: { month: 'short', year: 'numeric', day: 'numeric' },
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'},
  }
}
@Component({
  selector: 'app-techreport',
  templateUrl: './techreport.component.html',
  styleUrls: ['./techreport.component.scss'],
  providers: [MatTab, DatePipe,
    {
        provide: DateAdapter, useClass: AppDateAdapter
    },
    {
        provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
 ]
})
export class TechreportComponent implements OnInit, OnDestroy {
  public empId;
  public currentUser;
  public reportData={total:0,underProcess:0,completed:0};
  public fillActionValue='';
  public fromDate='';
  public toDate='';
  public rangeSelected:any="1";
  public jobsData=[];
  public totalWages:any=0;
  public today=new Date();
  public isEditPermissions = false;
  public selectedSR = '';
  public searchByArray = [{ id: 1, text: "Req. Id"}];
  public search_by = 1;
  public search_keyword = '';
  public filterOption=[{id:0,text:'Please Select'},{id:1,text:'Today'},{id:2,text:'Week'},{id:3,text:'Date Range'}];
  public subscriptionDataForAPI: Subscription = new Subscription();

  constructor(public globalService: GlobalService, public router: Router, private route: ActivatedRoute, public dialog: MatDialog) { 
    this.currentUser=this.globalService.getCurrentUser();
    
    setTimeout(()=>{
      let isEnable=this.globalService.getAcl('employees','views');
      let isView=( this.currentUser.role_id != 4 ? true : false );
      if((!isEnable) || (!isView)){
        this.router.navigate(['shops/action/forbidden']);
      }
    },globalConstants.RELOAD_WAIT);
    this.globalService.setMetaData("SHOPS","EMPLOYEE_REPORT")
  }
  ngOnInit() {
    this.getParams();
    this.isEditPermission();
  }
  getParams() {
    this.rangeSelected = 1;
    this.route.params.subscribe((params: Params) => {
      this.empId = params["emp_id"];
    });
    this.route.queryParams.subscribe(params => {
      this.search_keyword = params["req_id"];
      if(this.search_keyword && this.search_keyword !== ''){
        this.rangeSelected = 0;
      }else{
        this.rangeSelected = 1;
      }
    });
    this.getFilter();
  }

  setTouchViewValue() {
    return true;
  }
  dateChange(event,type){
    if(type==1 && this.toDate != '' && this.toDate < this.fromDate){
      this.toDate= new Date().toISOString();
    }
  }
  changeRangeSelected(){
    if(this.rangeSelected==0 || this.rangeSelected==1 || this.rangeSelected==2){
      this.fillActionValue='';
    }else if(this.rangeSelected==3){
      this.fillActionValue='bwtDate';
    }    
  }

  getFilter(){
    let data="user_id="+this.currentUser.id+"&range_selected="+this.rangeSelected+"&emp_id="+this.empId+"&user_type="+this.currentUser.user_type;
    if(this.rangeSelected === 3 && (this.fromDate ==='' || this.toDate ==='')){
      this.globalService.snackbar('error', "Please select Start and End dates");
      return false;
    }
    if(this.fromDate!=''){
      data =data+'&start_date='+ this.dateFormat(this.fromDate);
    }
    if(this.toDate!=''){
      data =data+'&end_date='+ this.dateFormat(this.toDate);
    }
    if(this.search_keyword && this.search_keyword!=''){
      data =data+'&search_by='+ this.search_by+"&search_keyword="+this.search_keyword;
    }
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_EMPLOYEE_REPORT,data).subscribe((r:any)=>{
      if(r.result==1){
        this.jobsData=r.data;
        this.totalWages=r.totalWages;
        this.reportData.total=r.count.total_assigned;
        this.reportData.underProcess=r.count.under_process;
        this.reportData.completed=r.count.completed;
      }else{
        this.jobsData=[];
        this.totalWages=r.totalWages;
        this.reportData.total=0;
        this.reportData.underProcess=0;
        this.reportData.completed=0;
        this.globalService.snackbar('error', "No Data found");
      }
    })
  }

  clearFilter(){
    this.rangeSelected = 1;
    this.fillActionValue='';
    this.search_keyword = '';
    this.fromDate = '';
    this.toDate = '';
    this.getFilter();
  }

  dateFormat(dateValue) {
    return ((new Date(dateValue).getMonth()+1) < 10 ? '0' : '')+(new Date(dateValue).getMonth()+1) + "/" + (new Date(dateValue).getDate() < 10 ? '0' : '')  + new Date(dateValue).getDate() + "/" + new Date(dateValue).getFullYear();
 }
 viewRequest(requestId){
  this.router.navigate(['shops/view-service-request/',requestId]);
 }
 getTotal(){
  let t=0;
   if(this.jobsData.length){
    this.jobsData.forEach((d,i)=>{
      if(!isNaN(parseFloat(d.minutes_worked))){
        t = t + parseFloat(d.minutes_worked);
      }
    })
   }
   let txt:any='0'
   if( t > 60 ){
    txt=(t / 60)
    txt = parseInt(txt) +' Hrs '+ (t%60) +' Mins';
   }else{
     txt= t+' Mins';
   }
  return txt;
 }

  editHourWork(requestId){
    if(this.globalService.getAcl('adjust_hours_logged','edits')){
      this.selectedSR = requestId;
    } else {
      this.selectedSR = "";
    }
  }

  updateWorkHour(output: number){
    if(output === 1){
      this.getFilter();
    }
    this.selectedSR = '';
  }

  isEditPermission(){
    if(this.globalService.getAcl('adjust_hours_logged','edits')){
      this.isEditPermissions = true;
    }else{
      this.isEditPermissions = false;
    }
  }

  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
  }  
}
