<div class="panel panel-primary view-customer-details" id="mainCompotents">
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">View {{ currentCustomer.profile_type == '2' ? 'Contact' : 'Customer' }} Details</h3>
        <button mat-mini-fab class="custom-btn-back" title="Back" (click)="globalService.goBack();">
            <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <div class="row" *ngIf="editAllowed">
            <div class="col-md-6" *ngIf="emailActivationStatus=='0'"></div>
            <div class="col-md-4" *ngIf="emailActivationStatus!='0'"></div>
            <div class="col-md-4" *ngIf="mapping_status == '0'"></div>
            <div class="col-md-2 text-right"></div>
            <div class="col-md-2 col-xs-4  customer-details-link">
                <p>
                    <a href="javascript:void(0);" class="hover-link" (click)="viewVehicles(idFromUrl)" title="Vehicles"><i class="fa fa-list-alt fa-lg margin-right-5"></i>Vehicles</a>
                </p>
            </div>
            <div class="col-md-2 col-xs-4  customer-details-link" *ngIf="mapping_status != '0'">
                <p><a href="javascript:void(0);" class="hover-link" (click)="editContacts()" title="Contacts"><i class="fa fa-vcard fa-lg margin-right-5"></i> {{ currentCustomer.profile_type == '2' ? 'Other Contacts' : 'Contacts' }}</a>
                </p>
            </div>
            <div class="col-md-2 col-xs-4  customer-details-link" *ngIf="emailActivationStatus!='0' && mapping_status != '0'">
                <p><a href="javascript:void(0)" class="hover-link" (click)="editCustomer(idFromUrl)" title="Edit"><i class="fa fa-edit fa-lg margin-right-5"></i>Edit</a>
                </p>
            </div>
        </div>
        <div class="jumbotron padding-25 car-view-str customer-details" *ngIf="idFromUrl != 23608">
            <!-- Replaced div grid structure with table -->
            <h3 class="margin-top-0 font-24">{{ currentCustomer.profile_type == '2' ? 'Contact' : 'Customer' }} Information
                <mat-chip-list class="mat-badge" *ngIf="isSubletWorkCheck" style="float:right">
                    <mat-chip color="primary" selected="true">Sublet Account</mat-chip>
                </mat-chip-list>
            </h3>
            <hr>
            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-xs-6 col-sm-6"><label>Name</label></div>
                        <div class="col-xs-6 col-sm-6 break-word">{{customerName}}</div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-xs-6 col-sm-6"><label>Email</label></div>
                        <div class="col-xs-6 col-sm-6 break-word">
                            <a href="mailto:{{customerEmail}}" class="text-black">{{customerEmail}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-xs-6 col-sm-6"><label>Address</label></div>
                        <div class="col-xs-6 col-sm-6 break-word">{{customerAddress  || '-'}}</div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-xs-6 col-sm-6"><label>City</label></div>
                        <div class="col-xs-6 col-sm-6 break-word">{{customerCity}}</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-xs-6 col-sm-6"><label>State</label></div>
                        <div class="col-xs-6 col-sm-6">{{customerState}}</div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-xs-6 col-sm-6"><label>Country</label></div>
                        <div class="col-xs-6 col-sm-6 break-word">{{customerCountry}}</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-xs-6 col-sm-6"><label>Zip Code</label></div>
                        <div class="col-xs-6 col-sm-6">{{customerZip}}</div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-xs-6 col-sm-6"><label>Home Phone</label></div>
                        <div class="col-xs-6 col-sm-6 break-word"><a href="tel:{{homePhone}}" class="text-black">{{homePhone}}</a></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-xs-6 col-sm-6"><label>Business Phone</label></div>
                        <div class="col-xs-6 col-sm-6"><a href="tel:{{businessPhone}}" class="text-black">{{businessPhone}}</a></div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-xs-6 col-sm-6"><label>Mobile Phone</label></div>
                        <div class="col-xs-6 col-sm-6 break-word"><a href="tel:{{mobilePhone}}" class="text-black">{{mobilePhone}}</a></div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-xs-6 col-sm-6"><label>{{ currentCustomer.profile_type == '2' ? 'Contact' : 'Customer' }} Labor Rate Type</label></div>
                        <div class="col-xs-6 col-sm-6">{{customerType}}</div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-xs-6 col-sm-6"><label>Tax Exempted</label></div>
                        <div class="col-xs-6 col-sm-6 break-word">{{taxExempted}}</div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-xs-6 col-sm-6"><label>{{globalService.getBORDName()}}</label></div>
                        <div class="col-xs-6 col-sm-6 break-word">{{businessName}}</div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-xs-6 col-sm-6"><label></label></div>
                        <div class="col-xs-6 col-sm-6 break-word">

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="jumbotron padding-25 car-view-str customer-details" *ngIf="idFromUrl == 23608">
            <!-- Replaced div grid structure with table -->
            <h3 class="margin-top-0">{{ currentCustomer.profile_type == '2' ? 'Contact' : 'Customer' }} Information</h3>
            <hr>
            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>Name</label></div>
                        <div class="col-sm-6">{{customerName}}</div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>Email</label></div>
                        <div class="col-sm-6 break-word">
                            <a href="mailto:{{customerEmail}}" class="text-black">{{customerEmail}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>Address</label></div>
                        <div class="col-sm-6">*********</div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>City</label></div>
                        <div class="col-sm-6 break-word">*********</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>State</label></div>
                        <div class="col-sm-6">*********</div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>Country</label></div>
                        <div class="col-sm-6 break-word">*********</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>Zip Code</label></div>
                        <div class="col-sm-6">******</div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>Home Phone</label></div>
                        <div class="col-sm-6 break-word">***-***-****</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>Business Phone</label></div>
                        <div class="col-sm-6">***-***-****</div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>Mobile Phone</label></div>
                        <div class="col-sm-6 break-word">***-***-****</div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>{{ currentCustomer.profile_type == '2' ? 'Contact' : 'Customer' }} Type</label></div>
                        <div class="col-sm-6">**</div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>Tax Exempted</label></div>
                        <div class="col-sm-6 break-word">***</div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label>{{globalService.getBORDName()}}</label></div>
                        <div class="col-sm-6">*********</div>
                    </div>
                </div>
                <div class="col-sm-6 margin-bottom-10">
                    <div class="row">
                        <div class="col-sm-6"><label></label></div>
                        <div class="col-sm-6 break-word">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




