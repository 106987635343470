<div class="popup-sidebar-wrapper tech-assign-drawer">
  <mat-drawer-container class="side-panel-wrappers ticket-assignment" autosize end>
    <mat-drawer #ticketAssignDrawer class="ticket-sidenav" mode="over" position="end">
      <!-- header section -->
      <h4><span *ngIf="techStatus==false">Ticket Assignment</span></h4>
      <div class="border-box">
        <div class="btn-block header" *ngIf="techStatus==false">
          <ul class="padding-0 margin-0" style="float: right;">
            <li>
              <button type="button" class="btn" (click)="assignTotech()" title="Submit"><i class="fa fa-check"
                  aria-hidden="true"></i></button>
            </li>
            <li>
              <button type="button" class="btn" (click)="closeSideBar()" title="Skip"><i class="fa fa-fast-forward"
                  aria-hidden="true"></i></button>
            </li>
            <li>
              <button type="button" class="btn" (click)="closeSideBar()" title="Close"><i class="fa fa-times"
                  aria-hidden="true"></i></button>
            </li>
          </ul>
        </div>
  
        <!-- <div class="cross-btn">
          <button type="button" (click)="closeSideBar()">
            <div class="desktop-screen">x</div>
          </button>
        </div> -->
        <!-- content section -->
        <div class="assignment-block" *ngIf="techStatus==false">
          <form autocomplete="off" [formGroup]="tech">
  
            <div class="select-totech uc-ngx-select" *ngIf="sDisable">
              <ngx-select class="display-full margin-bottom-15" [items]="requestList" formControlName="request_id"
                (select)="getServiceDetails();" placeholder="Select Service Request"></ngx-select>
            </div>

            <div class="vehicle-detail" *ngIf="(selectSR | json) != undefined">
              <div class="row bg-color">
                <div class="col-sm-12 margin-bottom-5">
                  <strong>Service Request Information</strong>
                </div>
                <div class="col-sm-12">
                  <label>SR ID : </label>
                  {{selectSR.request_id}}
                </div>
                <div class="col-sm-12">
                  <label>Appointment Date : </label>
                  {{ convertISODate(selectSR.requested_delivery_date) | date:'M/d/yy h:mm a'}}
                </div>
              </div>
              <div class="break row"></div>
              <div class="row bg-color">
                <div class="col-sm-12 margin-bottom-5">
                  <strong>Vehicle Information</strong>
                </div>
                <div class="col-sm-12"
                  *ngIf="selectSR.year !=undefined && selectSR.make !=undefined &&  selectSR.model !=undefined">
                  <label>Year/Make/Model : </label>
                  {{globalService.getGlobalVehicleName(selectSR)||"N/A"}} &nbsp;&nbsp;
                  <ng-container *ngIf="selectSR.state">
                    <label>State : </label> {{selectSR.state}}
                  </ng-container>
                </div>
                <div class="col-sm-12">
                  <label>License Plate : </label>
                  {{selectSR.license_plate || "N/A"}}
                </div>
                <div class="col-sm-12" *ngIf="selectSR.vin">
                  <label>VIN : </label>
                  {{selectSR.vin || 'N/A'}}
                </div>
              </div>
              <div class="break row"></div>
              <div class="row bg-color">
                <div class="col-sm-12">
                  <label>Estimated Service Time : </label>
                  {{estimated_hours ? estimated_hours+' hour' : '-'}}
                </div>
              </div>
            </div>
  
            <div class="display-full">
              <div class="tech-assign assign-label">
                <strong class="mat-radio-label-content">Assign To : 
                   <label *ngIf="onlySelf == true">Yourself</label>
                </strong>
              </div>
              <ul class="label-font">
                <li
                  *ngIf="(onlySelf==false && fromConflict==false) || (fromConflict==true && userData && empId!=userData.id)">
                  <!-- <label class="material-radio text-left margin-bottom-5">
                    <div class="mat-radio-container1">
                      <input type="radio" [checked]="tech.controls['assign_tomyself'].value==1" value="1"
                        name="assign_tomyself" (change)="assignToMyself($event,1)" formControlName="assign_tomyself" />
                    </div>
                    <span class="radio-box-text">Yourself</span>
                  </label> -->
                  <mat-radio-button matInput [checked]="tech.controls['assign_tomyself'].value==1" value="1"  
                  (change)="assignToMyself($event,1)" >Yourself</mat-radio-button>
                 
                </li>
                <li *ngIf="userData.role_id!='4' && onlySelf==false">
                  <!-- <label class="material-radio text-left margin-bottom-5">
                    <div class="mat-radio-container1">
                      <input type="radio" [checked]="tech.controls['assign_tomyself'].value==2" value="2"
                        name="assign_tomyself" (change)="onAssignToTech($event,2)" formControlName="assign_tomyself" />
                    </div>
                    <span class="radio-box-text">Technician</span>
                  </label> -->
                  <mat-radio-button matInput [checked]="tech.controls['assign_tomyself'].value==2" value="2"  
                  (change)="assignToMyself($event,2)" >Technician</mat-radio-button>
                </li>
                <li *ngIf="userData.role_id!='4' && onlySelf==false">
                  <!-- <label class="material-radio text-left margin-bottom-5">
                    <div class="mat-radio-container1">
                      <input type="radio" [checked]="tech.controls['assign_tomyself'].value==3" name="assign_tomyself"
                        (change)="onAssignToTech($event,3)" formControlName="assign_tomyself" />
                    </div>
                    <span class="radio-box-text">Employee</span>
                  </label> -->
                  <mat-radio-button matInput [checked]="tech.controls['assign_tomyself'].value==3" value="3"  
                  (change)="assignToMyself($event,3)" >Employee</mat-radio-button>
                </li>
              </ul>
            </div>
  
            <div class="select-totech uc-ngx-select margin-top-15 tech-select relative"
              *ngIf="tech.controls['assign_tomyself'].value!=1 && userData.role_id!='4'">
              <!-- <ngx-select class="display-full margin-bottom-15" [noAutoComplete]="true"
                [items]="tech.controls['assign_tomyself'].value==2?shopTechnician:adviser" formControlName="emp_id"
                [placeholder]="tech.controls['assign_tomyself'].value==2?'Select technician':'Select Employee'"
                readonly="readonly" (select)="updateDisplayName()">
              </ngx-select> -->

              <mat-form-field appearance="fill" class="display-full" *ngIf="tech.controls['assign_tomyself'].value === 2">
                <mat-select formControlName="emp_id" placeholder="Select Technician">
                  <mat-option *ngFor="let technician of shopTechnician" [value]="technician.id">
                    {{technician.first_name}} {{technician.last_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field  appearance="fill" class="display-full"  *ngIf="tech.controls['assign_tomyself'].value === 3">
                <mat-select  formControlName="emp_id" placeholder="Select Employee">
                  <mat-option *ngFor="let advise of adviser" [value]="advise.id">
                    {{advise.first_name}} {{advise.last_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <div class="errors validation-error"
                *ngIf="tech.controls['emp_id'].invalid && (tech.controls['emp_id'].dirty || tech.controls['emp_id'].touched)">
                <mat-error [hidden]="!((tech.controls['emp_id'].dirty) &&  tech.controls['emp_id'].errors?.required)">
                  {{ tech.controls['assign_tomyself'].value==2?'Technician':'Employee' }} is <strong>required</strong>
                </mat-error>
              </div>
            </div>
  
            <div class="display-full">
              <div class="col-md-3 margin-top-10 date-block padding-left-0"><strong>Start Date :</strong></div>
              <div class="col-md-5 px-0">
                <div class="vlio-datepicker relative">
                  <dp-date-picker mode="day" theme="dp-material dp-main" class="btn-block" [config]="datePickerConfig"
                    placeholder="Select Start Date" autocomplete="off" formControlName="event_start_date"
                    (ngModelChange)="startDateHandler($event)" #dayPicker></dp-date-picker>
                  <button type="button" mat-icon-button (click)="dayPicker.api.open();">
                    <img src="assets/img/calendar.png">
                  </button>
                </div>
                <div class="errors"
                  *ngIf="tech.controls['event_start_date'].invalid && (tech.controls['event_start_date'].dirty || tech.controls['event_start_date'].touched)">
                  <mat-error
                    [hidden]="!((tech.controls['event_start_date'].dirty) &&  tech.controls['event_start_date'].errors?.required)">
                    Start Date is <strong>required</strong>
                  </mat-error>
                </div>
              </div>
              <div class="col-md-4 px-0">
                <ngx-select class="display-full margin-bottom-20 uc-ngx-select" [noAutoComplete]="true"
                  formControlName="event_start_time" placeholder="Start Time" [items]="sTimeSlots"
                  (select)="startTimeHandler($event)">
                </ngx-select>
                <div
                  *ngIf="tech.controls['event_start_time'].invalid && (tech.controls['event_start_time'].dirty || tech.controls['event_start_time'].touched)">
                  <mat-error
                    [hidden]="!((tech.controls['event_start_time'].dirty) &&  tech.controls['event_start_time'].errors?.required)">
                    Start Time is <strong>required</strong>
                  </mat-error>
                </div>
              </div>
            </div>
            <div class="display-full">
              <div class="col-md-3 margin-top-10 date-block padding-left-0"><strong>End Date :</strong></div>
              <div class="col-md-5 px-0">
                <div class="vlio-datepicker relative">
                  <dp-date-picker mode="day" [config]="datePickerConfig" theme="dp-material dp-main" class="btn-block"
                    placeholder="Select Start Date" autocomplete="off" (ngModelChange)="endDateHandler($event)"
                    formControlName="event_end_date" #dayPickerTwo>
                  </dp-date-picker>
                  <button type="button" mat-icon-button (click)="dayPickerTwo.api.open();">
                    <img src="assets/img/calendar.png">
                  </button>
                </div>
                <div
                  *ngIf="tech.controls['event_end_date'].invalid && (tech.controls['event_end_date'].dirty || tech.controls['event_end_date'].touched)">
                  <mat-error
                    [hidden]="!((tech.controls['event_end_date'].dirty) &&  tech.controls['event_end_date'].errors?.required)">
                    End Date is <strong>required</strong>
                  </mat-error>
                </div>
                <div class="f-error">
                  <mat-error *ngIf="error.dError">End Date can't before start date.</mat-error>
                </div>
              </div>
              <div class="col-md-4 px-0">
                <ngx-select class="display-full margin-bottom-20 uc-ngx-select" [noAutoComplete]="true"
                  formControlName="event_end_time" placeholder="End Time" [items]="eTimeSlots">
                </ngx-select>
                <div
                  *ngIf="tech.controls['event_end_time'].invalid && (tech.controls['event_end_time'].dirty || tech.controls['event_end_time'].touched)">
                  <mat-error
                    [hidden]="!((tech.controls['event_end_time'].dirty) &&  tech.controls['event_end_time'].errors?.required)">
                    End Time is <strong>required</strong>
                  </mat-error>
                </div>
                <div class="f-error">
                  <mat-error *ngIf="error.tError">End time can't before start time.</mat-error>
                </div>
              </div>
            </div>
  
            <div class="name-suggestion technicians margin-bottom-15"
              *ngIf="tech.controls['emp_id'].value!='' && tech.controls['request_id'].value!=''">
              <div class="row">
                <div class="col-sm-12 col-xs-12">
                  <label>Suggestion For {{displayName}} :</label>
                </div>
                <!-- <div class="col-sm-6 col-xs-4" style="text-align: right;">
                  <img src="assets/img/user-icon.svg">
                </div> -->
              </div>
              <div class="row" *ngFor="let slot of slots;let i = index">
                <div class="col-sm-8 col-xs-7">
                  <div class="time-box">
                    <span>{{ convertISODate(slot.slot_start_time) | date:'M/d/yy h:mm a' }} - {{
                      convertISODate(slot.slot_end_time) | date:'M/d/yy h:mm a' }}</span>
                  </div>
                </div>
                <div class="col-sm-4 col-xs-5">
                  <div class="select-btn">
                    <p><button type="button" class="btn list-action-btn mat-button" title="Select"
                        (click)="selectSlots(i)">Select</button></p>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="slots.length === 0 && noAvailbleSlotFound">
                <div class="col-sm-12">
                  <p class="text-red"><i class="fa fa-exclamation-triangle margin-right-15" aria-hidden="true"></i>{{
                    noAvailbleSlotFound }}</p>
                </div>
              </div>
            </div>
  
            <div class="display-full overwrite-time">
              <mat-checkbox formControlName="overwrite_time" class="">Do not overwrite the timing, while auto pushing the
                job queue</mat-checkbox>
              <mat-checkbox *ngIf="editShopForever" class="btn-block margin-bottom-10" id="requestid"
                labelPosition="after" (change)="skipForever($event)">Skip Forever </mat-checkbox>
            </div>
          </form>
        </div>
        <!-- Footer section -->
        <div class="footer">
          <div class="row">
            <div class="col-sm-4 col-xs-12"></div>
            <div class="col-sm-8 col-xs-12">
              <div class="btn-block" *ngIf="techStatus==false">
                <ul>
                  <li>
                    <button type="button" class="btn skip-btn submit" (click)="assignTotech()">Submit</button>
                  </li>
                  <li>
                    <button type="button" class="btn skip-btn clear" (click)="closeSideBar()">Skip</button>
                  </li>
                </ul>
              </div>
              <div class="btn-block" *ngIf="techStatus==true">
                <ul>
                  <li>
                    <button type="button" class="btn skip-btn cancel" (click)="closeSideBar()">Close</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-drawer>
  </mat-drawer-container>
</div>