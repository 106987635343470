import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { globalConstants } from '../../../../environments/globalConstants';
import { globalFunctions } from '../../../../environments/globalFunctions';
  /**
   * @trackID <:CAR-1414> Edit Customer
   * @created 2020-02-17
   * @createdBY Vikas Kumar
   * @return
   */
@Component({
  selector: 'app-editcustomerpopup',
  templateUrl: './editcustomerpopup.component.html',
  styleUrls: ['./editcustomerpopup.component.scss']
})
export class EditcustomerpopupComponent implements OnInit {

  public user:any=null
  public customer: FormGroup;
  public param:any={}
  public phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public getCustomerTypes;
  public timezoneData = [];
  public shopTimezone: string='';
  public defaultCustomerType = '';
  public shopDetails: any = null
  public selectedCountry;
  public selectedState;
  public selectedCity;
  public countries: any = [];
  public states: any = [];
  public cities: any = [];
  public zipLabel = 'Zip Code';
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public gs:GlobalService, public dialogRef: MatDialogRef<EditcustomerpopupComponent>, public dialog: MatDialog, private form: FormBuilder) { 
    this.gs.diaLogObj(this.dialogRef);
    this.user=this.gs.getCurrentUser()
    this.param=data
    let p=this.gs.getAcl('customer','edits');
    if(!p){
      this.dialogRef.close(0)
    }
    this.getCountries();
  }

  ngOnInit() {
    this.customer = this.form.group({
      email: ['', [Validators.required, Validators.pattern(globalConstants.EMAIL_REGEX)]],
      first_name: ['', [Validators.required]],
      middle_name: [''],
      last_name: ['', [Validators.required]],
      address_one: ['', Validators.required],
      city: [''],
      state: [''],
      frm_state: [''],
      country: [''],
      frm_country: [''],
      zipcode: ['', [Validators.required, Validators.pattern("^[0-9]+$")]],
      mobile_phone: ['', [Validators.required, Validators.minLength(10), this.gs.mobileNumberValidation]],
      timezone: ['',Validators.required],
      user_id: this.user.id,
      user_type: this.user.user_type,
      shop_id: this.user.shop_id,
      bypass_customer_mapping:1,
      customer_id:this.param.id,
    });  
    
   
    this.customer.get("country").valueChanges.subscribe(country_name => {                
      if(country_name && Array.isArray(this.countries)){                 
            let zRegex = this.countries.find(res=>(res.country_id==country_name || res.country_name==country_name));
            if(zRegex && 'zipcode_regex' in zRegex){
                this.zipLabel = zRegex.zipcode_label;
                this.customer.controls.zipcode.setValidators([Validators.required, Validators.pattern(zRegex.zipcode_regex)]);
                this.customer.controls.zipcode.updateValueAndValidity();
            }          
        }                
    });
    this.getCustomerData();
  }

   /** get customer detail */
   getCustomerData() {
    var customerInfo = "user_id=" + this.user.id + "&user_type=" + this.user.user_type + "&customer_id=" + this.param.id;
    this.gs.callAPI(globalConstants.API_GET_CUSTOMER_DETAILS, customerInfo).subscribe((r:any) => {
      if (r.result == 1) {
        var response = r.data;
        this.customer.controls.email.setValue(response.email);
        this.customer.controls.first_name.setValue(response.first_name);
        this.customer.controls.middle_name.setValue(response.middle_name);
        this.customer.controls.last_name.setValue(response.last_name);
        this.customer.controls.address_one.setValue(response.address1);
        this.customer.controls.city.setValue(response.city);
        this.customer.controls.state.setValue(response.state);
        this.customer.controls.country.setValue(response.country);
        this.customer.controls.zipcode.setValue(response.zip);
        this.customer.controls.mobile_phone.setValue(response.mobile_phone);
        this.customer.controls.timezone.setValue(response.time_zone);
        if(response.time_zone && response.time_zone!=''){
          this.shopTimezone = response.time_zone;
        }
        this.selectedCountry = response.country_id;
        this.getShopInfo()
      }
    }, (error) => { })
  }
  getShopInfo() {
    let param = "user_id=" + this.user.id + "&user_type=" + this.user.user_type + "&shop_id=" + this.user.shop_id;
    this.gs.callAPI(globalConstants.API_GET_SHOP_DETAILS_ON_DASHBOARD, param).subscribe((res:any) => {
      if (res.result == "1") {
        this.shopDetails = res.data;
        if(this.selectedCountry==''){
          this.selectedCountry = this.shopDetails.country_id;
        }
        if(this.shopTimezone==''){
          this.shopTimezone = this.shopDetails.time_zone;
        }    
        this.getStates(this.selectedCountry);   
        this.getTimezone(this.selectedCountry); 
      }
    }, (error) => { })

  }
  checkIfValid() {
    if (this.customer.valid) {
    }
  }
  /** get all timezone start */
  getTimezone(Id) {
    console.log('timezone cuntry',Id)
    let data = { country_id: Id, user_type: this.user.user_type, user_id: this.user.id };
    this.gs.callAPI(globalConstants.API_GET_TIMEZONE, data).subscribe((zones:any) => {
      if (zones.result == '1') {
        this.timezoneData = zones.data;
        // handling if selected country is equal to shop country than set new customer timezone to shop timezone
        if (this.selectedCountry) {
          if (Id === this.selectedCountry) {
            this.customer.controls.timezone.setValue(this.shopTimezone);
          } else {
            this.customer.controls.timezone.setValue('');
          }
        }
      } else {
        this.timezoneData = [];
        this.customer.controls.timezone.setValue('');
      }
    });
  }
  /** get all timezone end */
  getSelectedOptionTimezone(opt) {
    this.customer.controls.timezone.setValue(opt);
    this.checkIfValid()
  }

  handleCountryDropdownChange(countryId) {
    this.getStates(countryId)
    let countryData = this.countries.find(countryInfo => countryId == countryInfo.id);
    this.customer.controls.country.setValue(countryData.country_name);
    this.customer.controls.state.setValue('');
    this.customer.controls.city.setValue('');
    this.selectedState = '';
    this.selectedCity = '';
    this.getTimezone(countryId)
    this.selectedState = "";
    this.checkIfValid()
  }


  handleStateDropdownChange(stateId) {
    this.getCities(stateId);
    let stateData = this.states.find(stateInfo => stateId == stateInfo.id);
    this.customer.controls.state.setValue(stateData.state_name);
    this.customer.controls.city.setValue('');
    this.selectedCity = 'Select City';
}
  handleCityDropdownChange(cityName) {
    this.customer.controls.city.setValue(cityName);
    this.checkIfValid()
  }
  getCountries() {
    this.gs.callAPI(globalConstants.API_GET_COUNTRIES_LIST, "opr=1").subscribe((data:any) => {
      if (data.result == "1") {
      this.countries = data.data;
      for (var i in this.countries) {
        this.countries[i].id = this.countries[i].country_id;
        this.countries[i].text = this.countries[i].country_name;
      }
      let country = this.customer.value.country
      let shopCountry = this.countries.filter(list => list.country_name == country)
      if (shopCountry.length > 0) {
        this.selectedCountry = shopCountry[0].country_id;
      }
    }
    }, (error) => { });
  }

  getStates(country_id) {
    this.cities = [
      { id: "", text: "Select City" }
    ];
    this.states = []
    let stateDDLPlaceholder = [
      { 
          id: "",
          state_id: "",
          state_name: "Select State",
          country_id: "",
          latitude: "",
          longitude: "",
          tax_rate: "",
          state_code: ""
       }
  ];
    this.gs.callAPI(globalConstants.API_GET_STATES_URL, "opr=1&country_id=" + country_id).subscribe((responseData:any) => {
      if (responseData.result == "1") {
        this.states  = stateDDLPlaceholder.concat(responseData.data);
          for (var i in this.states) {
            this.states[i].id = this.states[i].state_id;
            this.states[i].text = this.states[i].state_name;
          }
        let state = this.customer.value.state;
        let shopState = this.shopDetails.state;
        let filterOn = '';
        if (shopState != state && state && state.length) {
          filterOn = state;
        } else if (shopState && shopState.length) {
          filterOn = shopState;
        }
        let shopStates = this.states.filter(state => state.state_name == filterOn)
        if (filterOn && shopStates.length > 0) {
          this.selectedState = shopStates[0].state_id;
          this.customer.controls.state.setValue(shopStates[0].state_name)
          this.getCities(shopStates[0].state_id);
        }
      }else{
         this.selectedState = [{ id: "", text: "No state found" }];
      }
    }, (error) => { })
  }
  getCities(opt) {
    this.cities = [];
    let cityDDLPlaceholder=[{
      id: "",
      city_id: "",
      city_name: "Select City",
      state_id: "",
      country_id: "",
      latitude: "",
      longitude: ""
      }];
    this.gs.callAPI(globalConstants.API_GET_CITIES_URL, "opr=1&state_id=" + opt).subscribe((responseData:any) => {
      if (responseData.result == "1") {
          this.cities = cityDDLPlaceholder.concat(responseData.data);
          for (var i in this.cities) {
            this.cities[i].id = this.cities[i].city_name;
            this.cities[i].text = this.cities[i].city_name;
          }
        if(this.selectedState==opt){
          let city = this.customer.value.city;
          let shopCity= this.shopDetails.city;
          let filterOn='';
          if(shopCity!=city && city && city.length){
            filterOn=city;
          }else if(shopCity && shopCity.length){
            filterOn=shopCity;
          }
          let cities = this.cities.filter(city => city.city_name ==filterOn )
          if (filterOn && cities.length > 0) {
            this.selectedCity = cities[0].city_name;
            this.customer.controls.city.setValue(cities[0].city_name);
          }
        }
      }else{
        this.selectedState = [{ id: "", text: "No city found" }];
      }
      this.checkIfValid()
    }, (error) => { })

  }
  submitCustomer() {
    this.gs.validateState(this.customer, ['city','state','country']);
    Object.keys(this.customer.controls).map((controlName) => {
      this.customer.get(controlName).markAsTouched({onlySelf: true});
    });
    if (this.customer.valid) {

      var data = new FormData();
      for (let i in this.customer.value) {
        data.append(i, this.customer.value[i]);
      }

      this.gs.formData(globalConstants.API_EDIT_CUSTOMER_URL, data).subscribe((data) => {
        if (data.result == 1) {
          this.gs.snackbar('success', data.message);
          this.dialogRef['email']=this.customer.value.email
          this.dialogRef['first_name']=this.customer.value.first_name
          this.dialogRef['last_name']=this.customer.value.last_name
          this.dialogRef['mobile_phone']=this.customer.value.mobile_phone
          this.dialogRef.close(1)
        } else {
          this.gs.snackbar('error', data.message);
        }
      }, (error) => { });
    }else{
      this.gs.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
  }
  
  onlyNumericKey(event) {
    return globalFunctions.onlyDecimalNumberKey(event);
  }

  setCountryStateCityViaZipCode(){
    let currentLoginUser = JSON.parse(localStorage.getItem("loggedInUser"));
    let payload = {
         user_id: currentLoginUser.id,
         user_type: "User",
         zip_code: this.customer.controls.zipcode.value
    }
    this.gs.callAPI(globalConstants.API_GET_STATE_CITY_VIA_ZIPCODE,payload).subscribe((response:any)=>{
        if(response.result == 1){
            if(response.data.country_info.country_id !== 0 
              && response.data.state_info.state_id !== 0 
              &&  response.data.city_info.city_id !== 0){
                this.selectedCountry = response.data.country_info.country_code;
                this.customer.controls.country.setValue(response.data.country_info.country_name);
                this.getStates(response.data.country_info.country_code);
                this.selectedState = response.data.state_info.state_id;
                this.customer.controls.state.setValue(response.data.state_info.state_name);
                this.getCities(response.data.state_info.state_id)
                this.selectedCity = response.data.city_info.city_name;
                this.customer.controls.city.setValue(this.selectedCity);
            }
        }
  })
  }
}
