import {Component, OnInit, ViewEncapsulation, Injectable} from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd, Params} from '@angular/router';
import {Http, Headers, Response, RequestOptions} from '@angular/http';
import {globalConstants} from "../../../../environments/globalConstants"
import {globalFunctions} from "../../../../environments/globalFunctions"
import {GlobalService} from "../../../../environments/global.service"
import {TextMaskModule} from 'angular2-text-mask';
import {Observable} from 'rxjs/Observable';
import {Title} from '@angular/platform-browser';
import {lang} from "../../../../environments/lang-en";
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/map';
import {AppComponent} from '../../../app.component';
import { LoaderService} from '../../../base/services/LoaderService';

@Component({
    selector: 'app-import-contacts',
    templateUrl: './import-contacts.component.html',
    styleUrls: ['./import-contacts.component.scss']
})
export class ImportContactsComponent implements OnInit {
    public currentCustomer;
    public currentCustomerName;
    public importContacts:FormGroup;
    public formData:FormData;
    public submitted: boolean = false;
    public rerrors=[];
    public seletedFileName='';
    constructor(
        public app:AppComponent,
        private formBuilder: FormBuilder, 
        public titleService: Title, 
        private router: Router, 
        public loaderService: LoaderService,
        private globalService: GlobalService) {
          this.formData = new FormData();
                if (localStorage.getItem("loggedInUser")) {
            this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
            this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
            this.importContacts = this.formBuilder.group({
                contacts_csv: ['',Validators.required],
                user_type:this.globalService.getCurrentUser()["user_type"],
                user_id:this.globalService.getCurrentUser()["id"],
            });

        } else {
            this.router.navigate(["/shops"]);
        }
    }

    ngOnInit() {
      this.loaderService.hide();
      this.globalService.setMetaData("SHOPS","IMPORT_CUSTOMERS");
      setTimeout(()=>{
      let isEnable=this.globalService.isAllowed(this.app,'import_contacts','addNew');
      if(!(isEnable )){
          this.router.navigate(['shops/action/forbidden']);
      }
    },globalConstants.RELOAD_WAIT)
    }
    getFile(event){
        let valid = false;
        if (event.target.files.length) {
            valid = this.globalService.validateFiles('csv', event.target.files);
        }
        if (valid) {
            this.importContacts.controls.contacts_csv.setValue(event.target.files[0]);
            this.seletedFileName = event.target.files[0].name;
        } else {
            this.importContacts.controls.contacts_csv.setValue('');
            this.seletedFileName = '';
            let ele = <HTMLInputElement>document.getElementById('contacts_csvfiles');
            ele.value = '';
            return false;
        }
    }
    importNewContact() {
        if (this.importContacts.valid) {
            this.formData.append('contacts_csv', this.importContacts.value.contacts_csv);
            this.formData.append('user_type', this.importContacts.value.user_type);
            this.formData.append('user_id', this.importContacts.value.user_id);
            this.globalService.formData(globalConstants.API_UPLOAD_CONTACTS_FILE_URL, this.formData).subscribe((data:any) => {
                var resultData = data;
                this.submitted = true;
                this.formData = new FormData();
                if (typeof resultData.data != 'undefined') {
                    this.rerrors = resultData.data;
                }
                if (resultData.result == 1) {
                    //    this.router.navigate(['shops/list-customers']);
                    this.globalService.snackbar('success', resultData.message);
                } else {
                    this.globalService.snackbar('error', resultData.message);
                }
            });
        } else {

            this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
        }
    }

    downloadSmapleCSV() {
        window.location.href = globalConstants.API_DOWNLOAD_SAMPLE_CONTACTS_FILE_URL+"?t=1";
    }

    expansionOpen(ele) {
      let element = document.getElementById('expansion-' + ele);
    //   console.log("element", element.offsetTop);
      setTimeout(() => {
        this.globalService.setScrolling(element, 700, 125);
      }, 500);
    }

    changeListner(event, rendering_tag_id) {
     var html = "";
     if (event.target.files && event.target.files[0]) {
         let file = event.target.files[0];
         if (file.type == "csv") {
             if (file.size < 1e+7) {
                 let data = new FormData();
                 let url=globalConstants.API_UPLOAD_CONTACTS_FILE_URL;
                 data.append(rendering_tag_id,file );
                 var headers = new Headers();
                 headers.set('Accept', 'application/json');
                 let options = new RequestOptions({ headers: headers });
              }
         }
     }
   }
}
