import { Component, OnInit,OnDestroy  } from '@angular/core';
import { GlobalService } from '../../../../environments/global.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { globalConstants } from '../../../../environments/globalConstants';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-partimportdetail',
  templateUrl: './partimportdetail.component.html',
  styleUrls: ['./partimportdetail.component.scss']
})
export class PartimportdetailComponent implements OnInit,OnDestroy {

  public user:any={};
  public uploadId:any='';
  public partDetail:any={};
  public status=['Pending','Uploaded for import','Processing','Finished'];
  public partStatus=['Queued','Successfully Imported','Failed to import'];
  public subscriptionDataForAPI: Subscription = new Subscription();

  constructor(private activatedRoute: ActivatedRoute,public gs: GlobalService, public router: Router) {
    this.user=this.gs.getCurrentUser();
    if (! this.user) {
      this.router.navigate(['shops']);
    }
   }

  ngOnInit() {
    this.getParams();
    this.getPartDetail();
  }
  /** get param id */
  getParams() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.uploadId = params["id"];
    });
  }
  /** get import part detail */
  getPartDetail(){
    let content={user_type:this.user.user_type,user_id:this.user.id,bulk_upload_item_id:this.uploadId}
    this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_PART_BULK_UPLOAD_PART_DETAIL,content).subscribe((res:any)=>{
      if(res.result=='1'){
        this.partDetail=res.data;
      }
    },(error)=>{});
  }
  /** return imported part status */
  getStatus(s){
    return (typeof this.partStatus[s] == 'undefined'?'N/A' : this.partStatus[s]);
  }

  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
  }  
}
