<div class="row bgcolor-lightgrey form-content margin-0  cc-edit-shop-profile">
    
    <form [formGroup]="shopProfileForm" (ngSubmit)="editShopProfile()"class="form-inline col-xs-12 col-sm-12 col-md-12 col-lg-12 padding-bottom-15 padding-top-15 edit-shop-profile-form">
      <mat-dialog-content class="profile-mat-content">
        <ul class="instruction-list">
          <li><span class="font-bold">Please complete "Tax Settings", It is mandatory to start creating service requests.</span></li>
          <li *ngIf="showSkipButton == 0"><span class="font-bold">Press "Skip", if you want to do it later. </span></li>
        </ul>
       <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block">
            <h2 class="fontcolor-blue sub-heading">TAXES SETTINGS</h2>
            <div class="row">
              <div class="form-group  col-sm-12 col-md-6" [class.hide]="isAllowed('edits','tax')==false">
                <div class='relative'>
                  <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput placeholder="Tax Rate" title="Tax Rate" formControlName="tax_rate" class="form-control input-lg custom-form-input" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event);convertIntoDecimal($event,'tax_rate',7,5)"  maxlength="10"/>
                    <mat-error *ngIf="shopProfileForm.controls.tax_rate.hasError('required')">
                      Tax Rate is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="shopProfileForm.value.tax_rate > 100">
                      Percentage should not exceed 100.
                    </mat-error>
                    <mat-error *ngIf="shopProfileForm.controls.tax_rate.hasError('pattern') && !(shopProfileForm.value.tax_rate > 100)">
                      Tax Rate must be a number with upto 4 decimal places
                    </mat-error>
                  </mat-form-field>
      
                </div>
                <div class="col-sm-12 padding-0">
                  <p class="help-block margin-0">Enter 0.00 if taxes are not applicable</p>
                </div>
              </div>
              <div class="form-group  col-sm-12 col-md-6" *ngIf="isAllowed('views','tax') && isAllowed('edits','tax')==false">
                <div class='relative'>
                   <label> Tax Rate </label> : {{ currentShopDetails.tax_rate }}
                </div>
              </div>
      
              <div class="form-group  col-sm-12 col-md-6" *ngIf="currentShopDetails && isAllowed('edits','tax')">
                <div class='relative'>
                  <mat-checkbox class="btn-block" labelPosition="after" (change)="handleDefaultTax($event)"  [checked]= "this.shopProfileForm.value.include_tax_default">
                    Include Taxes (Default Setting)
                  </mat-checkbox>
                </div>
                <div class="col-sm-12 padding-0" >
                  <p class="help-block margin-0">You can control tax inclusion on service tickets also.</p>
                </div>
              </div>
      
              <div class="form-group  col-sm-12 col-md-6" *ngIf="currentShopDetails && isAllowed('edits','tax')">
                <div class='relative'>
                  <mat-checkbox class="btn-block" labelPosition="after" (change)="handleLaborTaxExcluded($event)" [checked]= "this.shopProfileForm.value.labor_tax_excluded">
                    Exclude Labor Taxes (Default Setting)
                  </mat-checkbox>
                </div>
                <div class="col-sm-12 padding-0">
                  <p class="help-block margin-0">You can control labor tax inclusion on service tickets also.</p>
                </div>
              </div>
      
            </div>
            <h2 class="fontcolor-blue sub-heading">SUPPLY CHARGES SETTINGS</h2>
            <div class="row">
                  <div class="col-sm-12">
                    <p>Supply charges will be charges in:</p>
                  </div>  
                  <div class="col-sm-12">
                    <label class="material-radio">
                      <input type="radio" name="supply_charges_disp_type" [checked]="shopProfileForm.value.supply_charges_disp_type=='1'"  (click)="handleSupplyChargeType($event,1)"  value="1"  />
                      <span class="margin-right-10">Amount</span>
                    </label>
                    <label class="material-radio">
                      <input type="radio" name="supply_charges_disp_type" [checked]="shopProfileForm.value.supply_charges_disp_type=='2'" (click)="handleSupplyChargeType($event,2)" value="2"   />
                      <span>Percentage</span>
                    </label>
                  </div>
                <div class="form-group col-sm-12 col-md-3" [class.col-md-6]="shopProfileForm.controls['supply_charges_disp_type'].value != 2" [class.col-md-3]="shopProfileForm.controls['supply_charges_disp_type'].value == 2" [class.hide]="isAllowed('edits','add_supplies_invoice')==false">
                  <div class='relative'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                      <input matInput placeholder="Supply Charges" title="Supply Charges" formControlName="supply_charges"  class="form-control input-lg custom-form-input" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event);convertIntoDecimal($event,'supply_charges',7,3)" maxlength="10"/>
                      <mat-error *ngIf="shopProfileForm.controls.supply_charges.hasError('required')">
                        Supply Charges is <strong>required</strong>
                      </mat-error>
                      <mat-error *ngIf="shopProfileForm.value.supply_charges > 100 && shopProfileForm.controls['supply_charges_disp_type'].value == 2">
                        Percentage should not exceed 100.
                      </mat-error>
                      <mat-error *ngIf="shopProfileForm.controls.supply_charges.hasError('pattern') && shopProfileForm.controls['supply_charges_disp_type'].value == 2 && !(shopProfileForm.value.supply_charges > 100)">
                        Must be a number with upto 2 decimal places.
                     </mat-error>
                     <mat-error *ngIf="shopProfileForm.controls.supply_charges.hasError('pattern') && shopProfileForm.controls['supply_charges_disp_type'].value == 1">
                      Must be a number with upto 2 decimal places.
                     </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-12 padding-0" >
                    <p class="help-block margin-0">Enter 0.00 if supply charges are not applicable</p>
                  </div>
                </div>
                <div class="form-group  col-sm-12 col-md-3"  *ngIf="currentShopDetails" [class.hide]="isAllowed('edits','add_supplies_invoice')==false || shopProfileForm.controls['supply_charges_disp_type'].value != 2">
                  <div class='relative'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                      <input matInput placeholder="Supply Charges Limit" title="Supply Charges Limit"  formControlName="supply_charges_limit" class="form-control input-lg custom-form-input" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event);validateNumbersWithDecimal($event,3)" maxlength="10"/>
                      <mat-error *ngIf="shopProfileForm.controls.supply_charges_limit.hasError('pattern')">
                        Must be a number with upto 2 decimal places.
                     </mat-error>
                    </mat-form-field>
                    <div class="col-sm-12 padding-0">
                      <p class="help-block margin-0">Value should always be provided in absolute amount for supply charge limit</p>
                    </div>
                  </div>
                </div>
                <div class="form-group  col-sm-12 " [class.col-md-6]="shopProfileForm.controls['supply_charges_disp_type'].value != 2" [class.col-md-3]="shopProfileForm.controls['supply_charges_disp_type'].value == 2" *ngIf="isAllowed('views','add_supplies_invoice') && isAllowed('edits','add_supplies_invoice')==false ">
                  <div class='relative' >
                    <label>  Supply Charges </label>:<span>{{currentShopDetails.supplies_charges}}</span> 
                  </div>
                </div>
                <div class="form-group  col-sm-12 col-md-6" *ngIf="isAllowed('views','add_supplies_invoice') && isAllowed('edits','add_supplies_invoice')==false ">
                  <div class='relative' >
                    <label>  Supply Charges Limit </label>: <span>{{currentShopDetails.supply_charges_limit|| 'N/A'}}</span> 
                  </div>
                </div>
                <div class="form-group  col-sm-12 col-md-6" *ngIf="currentShopDetails " [class.hide]="isAllowed('edits','add_supplies_invoice')==false">
                  <div class='relative'>
                    <mat-checkbox class="btn-block" labelPosition="after" (change)="handleSupplyCharges($event)" [checked]="shopProfileForm.value.include_supply_charges">
                      Include Supply Charges (Default Setting)
                    </mat-checkbox>
                  </div>
                  <div class="col-sm-12 padding-0">
                    <p class="help-block margin-0">You can control supply charges inclusion on service tickets also.</p>
                  </div>
                </div>
            </div>
            <h2 class="fontcolor-blue sub-heading">CC FEES</h2>
            <div class="row">
            
                <div class="col-sm-12"><p>CC fees will be charges in:</p></div>
                <div class="col-sm-12">
                    <label class="material-radio">
                      <input type="radio" name="cc_fee_type" [checked]="shopProfileForm.value.cc_fee_type=='1'"  (click)="handleCcFeeChargeType($event,1)"  value="1"  />
                      <span class="margin-right-10">Amount</span>
                    </label>
                    <label class="material-radio">
                      <input type="radio" name="cc_fee_type" [checked]="shopProfileForm.value.cc_fee_type=='2'" (click)="handleCcFeeChargeType($event,2)" value="2"   />
                      <span>Percentage</span>
                    </label>
                  </div>
            <!-- </div> -->
            <div class="form-group col-md-6 col-sm-12">
                <div class='relative'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                        <input matInput placeholder="CC Fees" title="CC Fees" formControlName="cc_fee"  class="form-control input-lg custom-form-input"  (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event);convertIntoDecimal($event,'cc_fee',7,3)" maxlength="10"/>
                        <mat-error *ngIf="shopProfileForm.controls.cc_fee.hasError('required')">
                          CC Fee is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="shopProfileForm.value.cc_fee > 100 && shopProfileForm.controls['cc_fee_type'].value == 2">
                          Percentage should not exceed 100.
                        </mat-error>
                        <mat-error
                          *ngIf="shopProfileForm.controls.cc_fee.hasError('pattern') && shopProfileForm.controls['cc_fee_type'].value == 2 && !(shopProfileForm.value.cc_fee > 100)">
                          Must be a number with upto 2 decimal places.
                        </mat-error>
                        <mat-error *ngIf="shopProfileForm.controls.cc_fee.hasError('pattern') && shopProfileForm.controls['cc_fee_type'].value == 1">
                          Must be a number with upto 2 decimal places.
                        </mat-error>
                      </mat-form-field>
                </div>
                <div class="col-sm-12 padding-0">
                <p class="help-block margin-0">Enter 0.00 if CC Fees is not applicable</p>
                </div>
            </div>
            
            </div>
            <h2 class="fontcolor-blue sub-heading">TIMEZONE SETTINGS</h2>
            <div class="row">
                <div class="form-group col-sm-12 col-md-6 uc-ngx-select timezone-col">
                  <label>Timezone: </label>
                  <ngx-select class="display-full margin-bottom-20" 
                  [items]="timezoneData" 
                  [(ngModel)]="timezone" 
                  [ngModelOptions]="{standalone: true}" 
                  [defaultValue]=""
                  (select)="getSelectedOptionTimezone($event)"
                  placeholder="Select Timezone">
                 </ngx-select>
                
                  <div class="col-sm-12 padding-0">
                    <p class="help-block margin-0">Select a specific timezone from the list. Changing the timezone would change the date/time site wide.</p>
                  </div>
                </div>
            </div>
              <!-- End USER TIME Row -->
             
             <!-- End USER TIME Row -->
             <div class="row">
                <div class=" col-xs-12 form-block">
                    <h2 class="fontcolor-blue sub-heading">WORK SCHEDULE</h2>
                    <mat-error *ngIf="!shopProfileForm.controls.selectedItems.valid">
                        Please select at least one weekday.
                    </mat-error>

                    <div class="row work-schedule-wrapper" formArrayName="shop_timings"
                        *ngFor="let timing of shopProfileForm.get('shop_timings')['controls']; let i = index;">
                        <div class="col-sm-12 col-md-12" [formGroupName]="i">
                            <div class="row">
                                <div class="col-sm-2 col-xs-2">
                                    <div class="day-fields">
                                        <ng-container [ngSwitch]="i">
                                          <label *ngSwitchCase="0">Monday</label>
                                          <label *ngSwitchCase="1">Tuesday</label>
                                          <label *ngSwitchCase="2">Wednesday</label>
                                          <label *ngSwitchCase="3">Thursday</label>
                                          <label *ngSwitchCase="4">Friday</label>
                                          <label *ngSwitchCase="5">Saturday</label>
                                          <label *ngSwitchCase="6">Sunday</label>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-sm-1 col-xs-1">
                                    <div class="row">
                                        <div class="form-group weak-checkbox col-xs-12">
                                            <mat-checkbox class="btn-block padding-top-10" labelPosition="after"
                                                formControlName="weekdayid" (change)="updateDefaultTime(i)">
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-9 col-xs-12">
                                    <div class="row">
                                        <div class="form-group  col-xs-6 uc-ngx-select">
                                            <ngx-select class="display-full" [items]="timeSlots" formControlName="start_time"
                                                placeholder="Start Time" optionTextField="text">
                                            </ngx-select>
                                            <mat-error class="dp-error" *ngIf="timing.get('start_time').hasError('required')">
                                              Start Time <strong>required</strong>
                                            </mat-error>
                                            <mat-error class="dp-error" *ngIf="timing.errors">
                                                {{ timing.errors?.dates }}
                                            </mat-error>
                                        </div>
                                        <div class="form-group  col-xs-6 uc-ngx-select">
                                            <ngx-select class="display-full" [items]="timeSlots" formControlName="close_time"
                                                placeholder="End Time" optionTextField="text">
                                            </ngx-select>
                                            <mat-error  class="dp-error"  *ngIf="timing.get('close_time').hasError('required')">
                                                End Time <strong>required</strong>
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
              </div>
        </div>
       </div>
      </mat-dialog-content>  
      <mat-dialog-actions class="inquiry-action submit-section">
       <div class="submit-section">
          <button type="submit" mat-raised-button title="Save" class=" font-bold mat-accent save-btn text-uppercase">Save</button>
          <button type="button" mat-raised-button *ngIf="showSkipButton == 0" title="Skip" mat-accent class=" font-bold mat-accent text-success text-uppercase margin-left-15" (click)="skipShopProfileSetup()">Skip</button>
      </div>
    </mat-dialog-actions>
      </form>
    
</div>
