import { Component, Inject, ChangeDetectorRef ,ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { globalFunctions } from '../../../../environments/globalFunctions';
import {Http, Headers, Response} from '@angular/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';

@Component({
  selector: 'app-reject-transfer-ownership-pop-up',
  templateUrl: './reject-transfer-ownership-pop-up.component.html',
  styleUrls: ['./reject-transfer-ownership-pop-up.component.scss']
})
export class RejectTransferOwnershipPopUpComponent {

    public rejectReason: FormGroup;
    public submitted: boolean = false;
    public currentVinDetails;
    public shopCustomerID;
    public customerCountryName;

    constructor( @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<RejectTransferOwnershipPopUpComponent>, private formBuilder: FormBuilder, private globalService: GlobalService, private http: Http, private router: Router, public cdr:ChangeDetectorRef,) {
        this.rejectReason = this.formBuilder.group({
            deny_reason: ['', [Validators.required]],
            customer_id: [data.transferOprs.request_to],
            status: [3],
            id:[data.transferOprs.id],
            car_id:[data.transferOprs.car_id],
            new_customer_id:[data.transferOprs.request_from]
        });
        this.globalService.diaLogObj(this.dialogRef);
    }

    ngOnInit() {
        
    }
    
    checkIfValid() {
        if (this.rejectReason.valid) {
            this.submitted = false;
        }
    }
    
    rejectRequest() {
        Object.keys(this.rejectReason.controls).map((controlName) => {
            this.rejectReason.get(controlName).markAsTouched({onlySelf: true});
        });
        
        if (this.rejectReason.valid) {
            // console.log("form", this.rejectReason.value);
            this.globalService.callAPI(globalConstants.API_PROCESS_TOR_URL, this.rejectReason.value).subscribe((data:any) => {
                var message = data.message;
                // console.log("msg", message);
                if (data.result == "1") {
                    this.dialogRef.close();
                    //this.router.navigate(["/customer/list-transferred-ownership"]);
                    this.router.navigate(["/customer/list-transferred-ownership"]);
                    this.globalService.snackbar('success', message);
                    this.cdr.markForCheck();
                } else {
                    this.globalService.snackbar("error", message);
                }
            })
        } else{ 
            this.submitted = true;
        }
    }
    

}
