import {Component, OnInit,OnDestroy} from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
import {DpDatePickerModule} from 'ng2-date-picker';
import {Http, Headers, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../../environments/globalFunctions";
import {globalConstants} from "../../../../environments/globalConstants";
import {lang} from "../../../../environments/lang-en";
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';
import {DateAdapter} from '@angular/material/core';
import {GlobalService} from "../../../../environments/global.service";
import {AppComponent} from "../../../app.component";
import { ShopcarvinscanComponent } from '../shopcarvinscan/shopcarvinscan.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ShopcarLicensePlateComponent } from '../shopcarlicenseplate/shopcarlicenseplate.component';
import { EditpopupComponent } from '../editpopup/editpopup.component';
import {​ ServiceHistoryComponent }​ from '../../../shop/shopservicerequest/service-history/service-history.component';

@Component({
  selector: 'app-listcustomercars',
  templateUrl: './listcustomercars.component.html',
  styleUrls: ['./listcustomercars.component.scss']
})
export class ListcustomercarsComponent implements OnInit,OnDestroy {
  public currentCustomer;
  public currentCustomerName;
  public currentCustomerVehicles;
  public carMakeList;
  public isCollapsed: boolean = true;
  public request;
  public listCustomerID;
  public result: any;
  public carLength;
  public carCustomerDetails:any;
  public sendParams:any;
  public countryName;
  public imgPath = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_PICTURES + globalConstants.IMG_THUMB;
  public viewCustomerInfo=false;
  gc:any = globalConstants;
  public subscriptionDataForAPI: Subscription = new Subscription();

  constructor(public appComponent: AppComponent, private dateAdapter: DateAdapter<Date>, private formBuilder: FormBuilder, private route: ActivatedRoute, public titleService: Title, private http: Http, private router: Router, public globalService: GlobalService, public dialog: MatDialog) {
    if (localStorage.getItem("loggedInUser")) {
      this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
      this.listCustomerID = localStorage.getItem("listCustomerID");
      //this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
      this.getCustomerVehicles();
      this.getCustomerDetails();
    } else {
      this.router.navigate(["/shops"]);
    }
    this.globalService.setMetaData("SHOPS","LIST_CUSTOMER_CARS");
    localStorage.removeItem('licensePlateData');
  }

  getCustomerDetails() {
    
    var customerOprs = "user_id="+this.currentCustomer.id+"&user_type="+this.currentCustomer.user_type+"&customer_id="+ this.listCustomerID;
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_DETAILS,customerOprs).subscribe((data:any) => {
      this.carCustomerDetails = data.data;
      this.sendParams = this.carCustomerDetails.id+','+this.carCustomerDetails.country_id;
      this.countryName = this.carCustomerDetails.country;
    });
  }

  getCustomerVehicles() {

    var content = "customer_id=" + this.listCustomerID + "&from_v2=1&sorting=alpha";

    this.currentCustomerVehicles = [];
    this.request = this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_VEHICLES_URL, content).subscribe((data:any) => {
      var response = data;
      if(response.result == 1) {
        let cars=[];
        for(var i in response.data){
          let n=(response.data[i].make ? response.data[i].make +" " : "") + response.data[i].model;
          if(n){
            response.data[i].car_name=n;
          }else{
            response.data[i].car_name="N/A";
          }
          cars.push(response.data[i]);
        }
        this.currentCustomerVehicles = cars;//response.data
        //console.log("Vehicle", this.currentCustomerVehicles.vin)
      } else {
      this.carLength = 1;
    }

  });
}

addCar(id) {
  localStorage.setItem("shopCustomerID", id);
  localStorage.setItem("VinValues", "");
  localStorage.setItem("VinNumber", "");
  this.router.navigate(['shops/add-car']);
}

viewCar(customer_id, vehicle_id) {
  localStorage.setItem("carDetailsCustomerID", customer_id);
  localStorage.setItem("carDetailsVehicleID", vehicle_id);
  localStorage.setItem("carCustomerCountryName", this.countryName);
  this.router.navigate(['shops/view-car']);
}

editCar(customer_id, vehicle_id, isWalkinCustomerVehicle) {
  // localStorage.setItem("carDetailsCustomerID", customer_id);
  // localStorage.setItem("carDetailsVehicleID", vehicle_id);
  // localStorage.setItem("carCustomerCountryName", country_name);
  // this.router.navigate(['shops/edit-car']);
  if(isWalkinCustomerVehicle == '1'){
    this.globalService.handleWalkinProfileEditDelete(isWalkinCustomerVehicle);
    return false;
  }
    let dialogRef = this.dialog.open(EditpopupComponent, {
      panelClass: 'car-dialog-form',
      width: '100%',
      data: { customer_id: customer_id, car_id:vehicle_id }
    });
    dialogRef.afterClosed().subscribe((r: any) => {  
    });
}

viewCarHistory(customer_id, vehicle_id) {
  localStorage.setItem("carDetailsCustomerID", customer_id);
  localStorage.setItem("carDetailsVehicleID", vehicle_id);
  this.router.navigate(['shops/service-history']);
}

openServiceHistoryPopup(serviceInfo){​
  // Material Popup Dialog
  
  // let dialogRef = this.dialog.open(ServiceHistoryComponent, {​
  //   panelClass: 'car-dialog-form',
  //   width:'95%',
  //   data: {​ isPopup: true, car_id:this.serviceDetail.car_id, service_data: this.serviceDetail }​
  // }​);
  // dialogRef.afterClosed().subscribe(result => {​
  //   console.log('history popup closed = ', result);
  // }​);
  let info:any = {
    id:'service-history-'+serviceInfo.id,
    name: 'Service History View',
    module: 'Service Request'
  }
  let data:any = {​ isPopup: true, carId:serviceInfo.id, serviceInfo:serviceInfo, id: 'service-history-'+serviceInfo.id };
  this.globalService.openMinimizablePopup(ServiceHistoryComponent, info, data);
}

deleteCar(car_id, customer_id,isWalkinCustomerVehicle) {
  if(isWalkinCustomerVehicle == '1'){
    this.globalService.handleWalkinProfileEditDelete(isWalkinCustomerVehicle);
    return false;
  }
  this.globalService.confirmDialog(car_id, 'Are you sure you want to delete this car?').subscribe((res) => {
    this.result = res;
    if (this.result != null) {
      this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_DELETE_CUSTOMER_VEHICLE_URL, "customer_id=" + customer_id + "&vehicle_id=" + car_id).subscribe((data:any) => {
        var message = data.message;
        if (data.result == "1") {
          this.globalService.snackbar('success', message);
        } else {
          this.globalService.snackbar("error", message);
        }
        this.getCustomerVehicles();
      });
    }
  });
}

onInput(obj) {
  obj.targetElement.value = this.dateAdapter.toIso8601(new Date(obj.target.value));
}

transferOwnship(id) {
  setTimeout(() => this.router.navigate(['/customer/transfer-ownership/', id]));
}

makeNewRequest(carId,customer_id,vehicleVin) {
  // if(vehicleVin !="") {
    this.router.navigate(['shops/new-service-request/'+customer_id+'/'+carId]);
  // } else {
  //   this.globalService.confirmDialog('confirm' ,'Request can only be created for the vehicle with VIN number.');
  // }
}

//    initTransfer() {
//        this.globalService.callAPI(globalConstants.API_TRANSFER_OWNERSHIP_URL, "customer_id=" + this.currentCustomer.id + "&vin=" + this.car.value.vin).subscribe((data) => {
//            this.globalService.snackbar("success", data.message)
//        })
//    }

setTouchViewValue() {
  return this.appComponent.touchViewState;
}
ngOnInit() {
  this.viewCustomerInfo=this.globalService.isAllowed(this.appComponent,'view_customer_info','views');
  //console.log("F Name", this.carCustomerDetails["first_name"]
}

public isCountryValid(){
  if(this.carCustomerDetails && this.carCustomerDetails.country_id){
    return this.globalService.checkValidCountry(this.carCustomerDetails.country_id);
  }else{
    return false;
  }
}


/* VIN SCAN POPUP */
scanVINDialog(sendParams, oprs): void {
  let dialogRef = this.dialog.open(ShopcarvinscanComponent, {
    panelClass: ['car-dialog-form'],
    // position: {top: '50px'},
    width: "800px",
    data: {send_params: sendParams,oprs:oprs}
  });

  dialogRef.afterClosed().subscribe(result => {
  });
}
/* END */


public openLicensePlateDialog(sendParams, oprs): void {
  const dialogRef = this.dialog.open(ShopcarLicensePlateComponent, {
    panelClass: ['car-dialog-form','ticket-dialog'],
    // position: {top: '50px'},
    width: "800px",
    data: { send_params: sendParams, oprs: oprs }
  });

  dialogRef.afterClosed().subscribe(result => {
     
  });
}

downloadCSV(){
  var content = "user_id="+this.currentCustomer.id+"&user_type="+this.currentCustomer.user_type+"&customer_id=" + this.listCustomerID + "&from_v2=1";
  var callApi = this.globalService.callAPI(globalConstants.API_EXPORT_CUSTOMER_VEHICLE_REPORT_URL, content);
  this.subscriptionDataForAPI = callApi.subscribe((data:any)=>{
    var reportData=data;
    var customerName = this.carCustomerDetails.first_name+" "+this.carCustomerDetails.last_name;
    window.location.href = globalConstants.API_DOWNLOAD_CUSTOMER_VEHICLE_REPORT_URL+"?t=1&p="+reportData.csv_url+"&customer_name="+customerName;
   });
}

  getCustomerAddress(address) {
    let customerAddress = '';
    if (address.address1 !== '' && typeof (address.address1) !== 'undefined') {
      customerAddress += address.address1;
    }
    if (address.address2 !== '' && typeof (address.address2) !== 'undefined') {
      customerAddress += customerAddress !== '' ? ', ' + address.address2 : address.address2;
    }
    if (address.city !== '' && typeof (address.city) !== 'undefined') {
      customerAddress += customerAddress !== '' ? ', ' + address.city : address.city;
    }
    if (address.state !== '' && typeof (address.state) !== 'undefined') {
      customerAddress += customerAddress !== '' ? ', ' + address.state : address.state;
    }
    return customerAddress;
  }


ngOnDestroy(){
  this.subscriptionDataForAPI.unsubscribe();
}  

}
