<div class="panel-primary padding-bottom-15 padding-top-5 margin-top-15 no-shadow">
  <div class="panel-heading relative">
    <h3 class="panel-title">Created</h3>
  </div>
  <div class="panel-body bgcolor-lightgrey border border-top-0">
    <div class="car-datatable mat-elevation-z1">
      <div class="table-responsive relative">
        <mat-table #tableCreated [dataSource]="dataSourceCreated" matSort class="service-list datatable-loader-bg">

          <ng-container matColumnDef="request_id">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="id"> Req. Id </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="id"> <a class="clickable"
                (click)="viewServiceRequest(row.id,row)">{{row.request_id}}</a> </mat-cell>
          </ng-container>

          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Title </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.title || 'N/A'}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="customer_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.customer_name}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="vehicle_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Vehicle Name </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.vehicle_name}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.status}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Actions </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="action">
              <button type="button" mat-raised-button [disabled]="!isSrEnable"
                class="list-action-btn mat-button mat-accent" color="accent" title="submit" style="max-width: 300px;"
                (click)="viewServiceLogs(row.id,row.request_id)">
                View Logs
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displColsCreated"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displColsCreated;"
            class="mat-row {{getRowStatusColor(row.check_status)}}"></mat-row>
        </mat-table>
        <div class="padding-15 text-center" [style.display]="getTotalRecords(1) < 1 ? '' : 'none'">
          No Records Available
        </div>
      </div>
      <div class="display-full relative" [style.display]="getTotalRecords(1) < 1 ? 'none' : ''">
        <mat-paginator class="car-pagination" #paginatorCreated [length]="getTotalRecords(1)" [pageIndex]="0"
          [pageSize]="optionsCreated.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event,1)">
        </mat-paginator>

        <div class="mat-paginator table-paging">
          <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{optionsCreated.start+1}} -
            {{getUpperLimit(optionsCreated.start + optionsCreated.limit,getTotalRecords(1))}} of {{getTotalRecords(1)}}</div>
          <button mat-icon-button (click)="paginate($event,'previous',1);" [disabled]="firstList(1)">
            <mat-icon>navigate_before</mat-icon>
          </button>
          <button mat-icon-button (click)="paginate($event,'next',1);" [disabled]="lastList(1)">
            <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="panel-primary padding-bottom-15 no-shadow">
  <div class="panel-heading relative">
    <h3 class="panel-title">Assigned</h3>
  </div>
  <div class="panel-body bgcolor-lightgrey border border-top-0">
    <div class="car-datatable mat-elevation-z1">
      <div class="table-responsive relative">
        <mat-table #tableAssigned [dataSource]="dataSourceAssigned" matSort class="service-list datatable-loader-bg">
          
          <ng-container matColumnDef="request_id">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="id"> Req. Id </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="id"> <a class="clickable"
                (click)="viewServiceRequest(row.id,row)">{{row.request_id}} </a></mat-cell>
          </ng-container>

          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Title </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.title || 'N/A'}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="customer_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.customer_name}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="vehicle_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Vehicle Name </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.vehicle_name}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="hours_logged">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Hours Logged </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.hours_logged || 0.00}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="hours_billed">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Hours billed</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.hours_billed || 0.00}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.status}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Actions </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="action">
              <button type="button" mat-raised-button [disabled]="!isSrEnable"
                class="list-action-btn mat-button mat-accent" color="accent" title="submit" style="max-width: 300px;"
                (click)="viewServiceLogs(row.id,row.request_id,true)">
                View Logs
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displColsAssigned"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displColsAssigned;"
            class="mat-row {{getRowStatusColor(row.check_status)}}"></mat-row>
        </mat-table>
        <div class="padding-15 text-center" [style.display]="getTotalRecords(2) < 1 ? '' : 'none'">
          No Records Available
        </div>
      </div>
      <div class="display-full relative" [style.display]="getTotalRecords(2) < 1 ? 'none' : ''">
        <mat-paginator class="car-pagination" #paginatorAssigned [length]="getTotalRecords(2)" [pageIndex]="0"
          [pageSize]="optionsAssigned.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event,2)">
        </mat-paginator>

        <div class="mat-paginator table-paging">
          <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{optionsAssigned.start+1}} -
            {{getUpperLimit(optionsAssigned.start + optionsAssigned.limit,getTotalRecords(2))}} of
            {{getTotalRecords(2)}}</div>
          <button mat-icon-button (click)="paginate($event,'previous',2);" [disabled]="firstList(2)">
            <mat-icon>navigate_before</mat-icon>
          </button>
          <button mat-icon-button (click)="paginate($event,'next',2);" [disabled]="lastList(2)">
            <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>