<div class="panel panel-primary notranslate fill-recommendation" id="mainCompotents">
  <div class="panel-heading padding-left-15">
    <h3 class="panel-title">SERVICE REQUESTS DETAIL</h3>
    <button *ngIf="isServiceNote" mat-mini-fab class="custom-btn-back vin-btn hide" style="padding-left:5px;padding-right:5px;right:50px;width:80px;border-right:1px solid #fff" (click)="srnoteList()">
      <i class="fa fa-folder-open margin-right-5"></i>
       <span class="hidden-xs" >Notes</span>
    </button>
    <button mat-mini-fab class="custom-btn-back" title="Back" (click)="globalService.goBack();">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <div class="quick-action">
    <div class="quick-action-icon" (touchstart)="onQuickActionIconClick()"><i class="fa-solid fa-gear"></i></div>
    <app-quickaction [serviceInfo]="serviceInfo" [parent]="this" [isAddInPayment]="isAddInPayment" (onTicketAssignment)="onTicketAssignment($event)" (couponAdded)="onCouponAdded($event)" (paymentAdded)="OnPaymentAdded($event)"></app-quickaction>
   </div>
  <div class="panel-body bgcolor-lightgrey" *ngIf="showServiceDetail" [formGroup]="serviceRequest">
    <div class="jumbotron inspection-report car-view-str">
        <div class="row">
          <div class="col-sm-6 col-md-6">
            <h3 class="margin-top-0 font-24 title-recom">
              Service Recommendations
            </h3>
          </div>
          <div class="col-sm-6 text-right top-row-checkbox">
            <mat-checkbox (change)="updateOnTheLotStatus($event)" [checked]="serviceRequest.value.on_the_lot" #onTheLotCheckbox>On The Lot</mat-checkbox>
              &nbsp;&nbsp;
            <mat-checkbox (change)="updateWaitingForPartStatus($event)" [checked]="serviceRequest.value.parts_awaited" #waitingForPartsCheckbox>Waiting For Parts</mat-checkbox>
          </div>
         

          <div class="col-sm-12 text-right sr-action-btns hidden-xs">
            <div class="icon-block" *ngIf="taskTrack && (userTaskTrack==0 || userTaskTrack==2)"> 
                <a href="javascript:;" class="btn btn-text-wrap" [title]="userTaskTrack==0 ? 'Start' : 'Resume'" (click)="taskTracking(1)">
                  <span *ngIf="userTaskTrack==0"><i class="fa fa-solid fa-play start"></i></span>
                  <span *ngIf="userTaskTrack==2"><i class="fa fa-solid fa-play resume"></i></span>
                </a>
                
                <span>
                  <p *ngIf="userTaskTrack==0" class="help-word">Start</p>
                  <p *ngIf="userTaskTrack==2" class="help-word">Resume</p>
                </span>

            </div>
            <div  class="icon-block" *ngIf="userTaskTrack==-1 && serviceInfo.request_status!='6' && serviceInfo.request_status!='8'">
              <a href="javascript:;" class="btn btn-text-wrap" title="Assign Now" (click)="autoAssign()" >
                <span><i class="fa fa-solid fa-play"></i></span>
              </a>
              <p class="help-word">Start</p>
            </div>
            <div  class="icon-block" *ngIf="userTaskTrack==-1 && serviceInfo.request_status!='6' && serviceInfo.request_status!='8' ">
              <a href="javascript:;" class="btn btn-text-wrap" title="Next Available" (click)="slefTicketAssignment()" >
                <img src="/assets/img/play-plus-black.svg" >
              </a>
              <p class="help-word">Next Avail</p>
            </div>
            <div  class="icon-block separator" *ngIf="userTaskTrack==-1 && serviceInfo.request_status!='6' && serviceInfo.request_status!='8' "> 
               <span class="vertical-separator" >|</span>
            </div>
            <div class="icon-block" *ngIf="taskTrack && userTaskTrack=='1'">
               <a href="javascript:;" class="btn btn-text-wrap" title="Pause" (click)="taskTracking(2)"><i class="fa fa-solid fa-pause"></i></a>
               <p class="help-word">Pause</p>
            </div>
            <div class="icon-block" *ngIf="taskTrack && userTaskTrack!='0' && userTaskTrack!='3' && userTaskTrack!=-1">
               <button type="button" class="btn btn-text-wrap" title="Job Done" (click)="taskTracking(3)" 
              [disabled]="userTaskTrack=='2'"><i class="fa fa-regular fa-calendar-check"></i></button>
              <p class="help-word">Job Done</p>
            </div>
            <div class="icon-block" *ngIf="(taskTrack==false || taskTrack==true) && userTaskTrack=='3'">
             <a href="javascript:;" class="btn btn-text-wrap success-btn" title="Job Done" ><i class="fa fa-regular fa-calendar-check"></i></a>
             <p class="help-word">Job Done</p>
            </div>
            <div class="icon-block separator" *ngIf="taskTrack && (userTaskTrack==0 || userTaskTrack==1 || userTaskTrack==2 || userTaskTrack==3 || userTaskTrack!=-1)">
             <span class="vertical-separator">|</span>
            </div>
            <div class="icon-block">
              <a href="javascript:;" class="btn btn-text-wrap" title="View Request" (click)="handleOpenViewSRPage()"><i class="fa fa-eye"></i></a>
              <p class="help-word">View</p>
            </div>
            <div class="icon-block" *ngIf="isAllowed('edits','service_request') &&  (serviceInfo.request_status != 10)">
              <a href="javascript:;" class="btn btn-text-wrap" title="Edit Job(s)"   (click)="handleOpenEditSRPage()"><i class="fa fa-wrench"></i></a>
              <p class="help-word">Edit Job(s)</p>
            </div>
            <div class="icon-block" *ngIf="skipTicketAssign && isTicketAssign()">
              <a href="javascript:;" class="btn btn-text-wrap" title="Ticket Assignment" (click)="ticketAssignment()"><img src="../../../../assets/img/play-black.svg"></a>
              <p class="help-word">Assign</p>
            </div>
            <div class="icon-block separator">
              <span class="vertical-separator">|</span>
            </div>
            <div class="icon-block" *ngIf="isAllowed('views','complaints')">
              <a href="javascript:;" class="btn btn-text-wrap" (click)="messages()"  title="{{ currentUser.profile_type == '2' ? 'Contact' : 'Customer' }} Chat List" ><i class="fa fa-regular fa-envelope"></i></a>
              <p class="help-word">Chat</p>
            </div>
            <div class="icon-block" *ngIf="isServiceNote" >
              <a hre="javascript:;" class="btn btn-text-wrap" title="Open Notes" (click)="srnoteList()"><i class="fa fa-regular fa-note-sticky"></i></a>
              <p class="help-word">Notes</p>
            </div>
            <div class="icon-block">
              <a href="javascript:;" class="btn btn-text-wrap" (click)="openServiceHistoryPopup()" title="View Service History" >
              <img class="service-history-img" #historyImg src="../../../../assets/img/service-history.svg"  />
              </a>
              <p class="help-word">History</p>
            </div>

          </div>
          <div class="col-sm-12 dot-btn-section margin-top-10 text-right">
            <div class="icon-block" *ngIf="taskTrack && (userTaskTrack==0 || userTaskTrack==2)">
              <a href="javascript:;" class="btn btn-text-wrap" [title]="userTaskTrack==0 ? 'Start' : 'Resume'" (click)="taskTracking(1)">
                <span *ngIf="userTaskTrack==0"><i class="fa fa-solid fa-play start"></i></span>
                <span *ngIf="userTaskTrack==2"><i class="fa fa-solid fa-play resume"></i></span>
              </a>
              <span>
                <p *ngIf="userTaskTrack==0" class="help-word">Start</p>
                <p *ngIf="userTaskTrack==2" class="help-word">Resume</p>
              </span>
            </div>  
            <div  class="icon-block" *ngIf="userTaskTrack==-1 && serviceInfo.request_status!='6' && serviceInfo.request_status!='8'">
              <a href="javascript:;" class="btn btn-text-wrap" title="Assign Now" (click)="autoAssign()" >
                <span><i class="fa fa-solid fa-play"></i></span>
              </a>
              <p class="help-word">Start</p>
            </div>
            <div  class="icon-block" *ngIf="userTaskTrack==-1 && serviceInfo.request_status!='6' && serviceInfo.request_status!='8' ">
              <a href="javascript:;" class="btn btn-text-wrap" title="Next Available" (click)="slefTicketAssignment()" >
                <img src="/assets/img/play-plus-black.svg" >
              </a>
              <p class="help-word">Next Avail</p>
            </div>
            <div  class="icon-block separator" *ngIf="userTaskTrack==-1 && serviceInfo.request_status!='6' && serviceInfo.request_status!='8' "> 
               <span class="vertical-separator" >|</span>
            </div>
            <div class="icon-block" *ngIf="taskTrack && userTaskTrack=='1'">
              <a href="javascript:;" class="btn btn-text-wrap" title="Pause" (click)="taskTracking(2)"><i class="fa fa-solid fa-pause"></i></a>
              <p class="help-word">Pause</p>
            </div>
            <div class="icon-block" *ngIf="taskTrack && userTaskTrack!='0' && userTaskTrack!='3' && userTaskTrack!=-1">  
              <button type="button" class="btn btn-text-wrap" title="Job Done" (click)="taskTracking(3)" 
                [disabled]="userTaskTrack=='2'"><i class="fa fa-regular fa-calendar-check"></i></button>
              <p class="help-word">Job Done</p>  
            </div>
            <div class="icon-block" *ngIf="(taskTrack==false || taskTrack==true) && userTaskTrack=='3'"> 
                <a href="javascript:;" class="btn btn-text-wrap success-btn" title="Job Completed"><i class="fa fa-regular fa-calendar-check"></i></a>
                <p class="help-word">Completed</p>  
            </div>  
            <div class="icon-block separator" *ngIf="taskTrack && (userTaskTrack==0 || userTaskTrack==1 || userTaskTrack==2 || userTaskTrack==3 || userTaskTrack!=-1)">  
              <span class="vertical-separator">|</span>
            </div>
            <div class="icon-block">  
              <a href="javascript:;" class="btn btn-text-wrap" title="View Request" (click)="handleOpenViewSRPage()"><i class="fa fa-eye"></i></a>
              <p class="help-word">View SR</p>  
            </div>
            <div class="icon-block"  *ngIf="isAllowed('edits','service_request') &&  (serviceInfo.request_status != 10)">
              <a href="javascript:;" class="btn btn-text-wrap" title="Edit Job(s)"  (click)="handleOpenEditSRPage()"><i class="fa fa-wrench"></i></a>
              <p class="help-word">Edit Job(s)</p> 
            </div>
            <div class="icon-block" *ngIf="skipTicketAssign && isTicketAssign()">
              <a href="javascript:;" class="btn btn-text-wrap" title="Ticket Assignment" (click)="ticketAssignment()"><img src="../../../../assets/img/play-black.svg"></a>
              <p class="help-word">Assign</p>
            </div>
            <button class="mat-icon-button dot-btn" mat-icon-button [matMenuTriggerFor]="actionMenu" disableRipple>
              <mat-icon class="mat-icon material-icons text-red margin-left-5">more_vert</mat-icon>
            </button>

            <mat-menu #actionMenu="matMenu" class="car-icon-menu disable-no-bg" [overlapTrigger]="false" xPosition="before">
              <!-- <button class="mat-menu-item" disableRipple type="button"  title="Ticket Assignment" (click)="ticketAssignment()" *ngIf="skipTicketAssign && isTicketAssign()"><span><i class="fa fa-ticket text-green"></i> Ticket Assignment</span></button>    -->
              <button class="mat-menu-item" disableRipple type="button" (click)="messages()" title="{{ currentUser.profile_type == '2' ? 'Contact' : 'Customer' }} Chat List" ><span><i class="fa fa-regular fa-envelope"></i> {{ currentUser.profile_type == '2' ? 'Contact' : 'Customer' }} Chat List</span></button>  
              <button class="mat-menu-item" disableRipple type="button"  title="View Notes" *ngIf="isServiceNote" (click)="srnoteList()"><span><i class="fa fa-regular fa-note-sticky"></i> Open Notes</span></button>  
              <button class="mat-menu-item" disableRipple type="button"  (click)="openServiceHistoryPopup()" title="View Service History" ><span class="service-history-icon">
                
                <img #historyImg src="../../../../assets/img/service-history.svg"  /> Service History
               </span> </button>  
      
            </mat-menu>
          </div>
        </div>
      <hr class="margin-top-10">
      <div class="row-wrap">
        <div class="row" *ngIf="qqDetail">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6 col-md-6 col-xs-6"><label>Quote ID</label></div>
              <div class="col-sm-6 col-md-6 col-xs-6" (click)="quickQuoteView()"  class="tooltip-status quoteId" >#{{qqDetail.quote_id}} ({{globalService.getFullFormateDate(qqDetail.add_date,'G','H')}})</div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6 col-md-6 col-xs-6"><label>Created By</label></div>
              <div class="col-sm-6 col-md-6 col-xs-6">{{qqDetail.user_name}}</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="row margin-bottom-10">
              <div class="col-xs-6 col-md-6 col-sm-6">
                <label class="control-label">Request ID</label>
              </div>
              <div class="col-xs-6 col-md-6 col-sm-6 break-all">
                <p class="pull-left" id="serviceRequestId">{{serviceRequest.value.request_id}}</p>
              </div>
            </div>
            <div class="row margin-bottom-10">
              <div class="col-xs-6 col-md-6 col-sm-6"><label class="control-label" >Title</label><button (click)="editDailog()" class="btn btn-xs btn-link text-red ng-tns-c13-22" title="Edit" type="button"><i class="fa fa-pencil fa-lg"></i></button></div>
              <div class="col-xs-6 col-md-6 col-sm-6 break-all"><p id="title">{{serviceRequest.value.title || 'N/A'}}  </p></div>
            </div>
            <div class="row margin-bottom-10">
              <div class="col-xs-6 col-md-6 col-sm-6"><label class="control-label"  [ngClass]="serviceInfo.is_walkin_customer == '1' ? 'req-attention' : ''">{{ currentUser.profile_type == '2' ? 'Contact' : 'Customer' }} Name 
              </label>
              <button  (click)="detailTab(2)" class="btn btn-xs btn-link text-red" title="{{ currentUser.profile_type == '2' ? 'Contact' : 'Customer' }} Detail" type="button"><i class="fa fa-info-circle fa-lg"></i></button>
              <button (click)="handleUpdateCustomerOrVehicleInfo(1)" class="btn btn-xs btn-link text-red padding-left-0" title="Edit" type="button">
                  <i *ngIf="serviceInfo.is_walkin_customer == '1'" class="fa fa-refresh fa-lg fa-fade"></i>
                  <i *ngIf="serviceInfo.is_walkin_customer == '0'" class="fa fa-refresh fa-lg"></i>
              </button>
            </div>
              <div class="col-xs-6 col-md-6 col-sm-6 break-word"><p id="customerName">{{serviceRequest.value.first_name + " " +serviceRequest.value.last_name}}</p></div>
            </div>
            <div class="row margin-bottom-10">
              <div class="col-xs-6 col-md-6 col-sm-6"><label class="control-label">Mobile Number</label></div>
              <div class="col-xs-6 col-md-6 col-sm-6"><p id="mobileNumber">
                <a class="text-black" *ngIf="isAllowed('views','view_customer_info')" href="tel:{{serviceRequest.value.mobile_number}}">{{serviceRequest.value.mobile_number}}</a>
                <span *ngIf="isAllowed('views','view_customer_info')==false">-</span>
              </p>
              </div>
            </div>
            <div class="row margin-bottom-10">
              <div *ngIf="!openExternalUrl(0)" class="col-xs-6 col-md-6 col-sm-6"><label class="control-label" [ngClass]=
                "serviceInfo.is_walkin_customer_vehicle == '1' ? 'req-attention' : ''">Vehicle 
              </label>
              <button (click)="detailTab(1)" class="btn btn-xs btn-link text-red ng-tns-c13-22"  type="button">&nbsp;<i class="fa fa-info-circle fa-lg"></i></button>
              <button  (click)="handleUpdateCustomerOrVehicleInfo(2)" class="btn btn-xs btn-link text-red padding-left-0" title="Edit" type="button">
                <i *ngIf="serviceInfo.is_walkin_customer_vehicle == '1'" class="fa fa-refresh fa-lg fa-fade"></i>
                <i *ngIf="serviceInfo.is_walkin_customer_vehicle == '0'" class="fa fa-refresh fa-lg"></i>
              </button>
            </div>
              <div *ngIf="openExternalUrl(0)" class="col-xs-6 col-md-6 col-sm-6"><label class="control-label" [ngClass]="serviceInfo.is_walkin_customer_vehicle == '1' ? 'req-attention' : ''"><span class="tooltip-status"  (click)="openExternalUrl(1)">Vehicle </span>
              </label>
              <button class="tooltip-status btn btn-xs btn-link text-red" title="Vehicle Details" (click)="detailTab(1)">&nbsp;<i class="fa fa-info-circle fa-lg"></i></button>
              <button (click)="handleUpdateCustomerOrVehicleInfo(2)" class="btn btn-xs btn-link text-red padding-left-0" title="Edit" type="button">
                <i *ngIf="serviceInfo.is_walkin_customer_vehicle == '1'" class="fa fa-refresh fa-lg fa-fade"></i>
                <i *ngIf="serviceInfo.is_walkin_customer_vehicle == '0'" class="fa fa-refresh fa-lg"></i>
              </button>
              
            </div>
            
              <div class="col-xs-6 col-md-6 col-sm-6" style="word-break: break-word;">
                <!-- <p id="vehicleName" data-original-title="" title="">{{serviceRequest.value.vehicle}}</p> -->
                <span *ngIf="vehicle_pic ==null || vehicle_pic ==undefined || vehicle_pic.length == 0">
                  <span>
                    {{serviceRequest.value.vehicle ? serviceRequest.value.vehicle : 'N/A'}}
                  </span>
                </span>
                <div *ngIf="vehicle_pic !=null && vehicle_pic !=undefined && vehicle_pic.length != 0">
                <span class="tooltip-status" [tooltip]="myTooltip"  (click)="imageToolDialog($event,0,1,[{'vehicle_pic':vehicle_pic}]);">{{serviceRequest.value.vehicle ? serviceRequest.value.vehicle : '-'}}</span>
                <!-- <span class="tooltip-status"  (click)="imageToolDialog($event,0,1,[{'vehicle_pic':vehicle_pic}]);">{{serviceRequest.value.vehicle ? serviceRequest.value.vehicle : '-'}}</span> -->
                <tooltip-content #myTooltip class="image-tooltip" placement="bottom">
                    <img src="{{globalConstants.S3_BUCKET_URL}}{{globalConstants.VEHICLE_PICTURES}}{{globalConstants.IMG_ORIGINAL}}{{vehicle_pic}}?{{vehicleTimeStamp}}" class="img-responsive">
                </tooltip-content>
                </div>
                <p id="expMsgs" class="text-danger" style="font-size:11px;margin-top: 5px;margin-bottom: 0;" *ngIf=" chkDate(serviceRequest.value.insurance_expiration_date) || chkDate(serviceRequest.value.registration_expiration_date)">
                  <span *ngIf="chkDate(serviceRequest.value.insurance_expiration_date)">Vehicle Insurance Expired -{{serviceRequest.value.insurance_expiration_date }}<br></span>
                  <span *ngIf="chkDate(serviceRequest.value.registration_expiration_date)">Vehicle Registration Expired - {{serviceRequest.value.registration_expiration_date }}<br></span>
                </p>
              </div>
            </div>
            <div class="row margin-bottom-10">
              <div class="col-xs-6 col-md-6 col-sm-6"><label class="control-label">VIN Number</label><button (click)="copyText()" *ngIf="serviceRequest.value.vin_number" class="btn btn-xs btn-link text-red ng-tns-c13-22" title="Copy VIN" type="button"><i class="fa fa-copy fa-lg"></i></button></div>
              <div class="col-xs-6 col-md-6 col-sm-6">
                <!-- <p id="vinNumber" style="text-transform:uppercase; max-width:200px; word-wrap:break-word;">
                {{this.serviceRequest.value.vin_number.substr(0,8)}}<strong style="color:#eb1e4c">{{this.serviceRequest.value.vin_number.substr(9)}}</strong>
                </p> -->

                <span *ngIf="vin_photo ==null || vin_photo ==undefined || vin_photo.length == 0">
                    <span *ngIf="this.serviceRequest.value.vin_number">
                        {{this.serviceRequest.value.vin_number | slice:0:-8}}<span style="color:#eb1e4c">{{this.serviceRequest.value.vin_number.substr(this.serviceRequest.value.vin_number.length - 8)}}</span>
                      </span>
                    <span *ngIf="!serviceRequest.value.vin_number">
                      N/A
                    </span>
                  <!-- {{this.serviceRequest.value.vin_number ? this.serviceRequest.value.vin_number.substr(0, 8) : ''}}<span style="color:#eb1e4c">{{this.serviceRequest.value.vin_number ? this.serviceRequest.value.vin_number.substr(9) : ''}}</span> -->
                </span>
                <div *ngIf="vin_photo !=null && vin_photo !=undefined && vin_photo.length != 0">
                    <span class="text-uppercase tooltip-status" [tooltip]="myTooltip1" (click)="imageToolDialog($event,0,5,vin_photo);"  *ngIf="this.serviceRequest.value.vin_number!=null" style="display: inline-block;">
                      <span *ngIf="this.serviceRequest.value.vin_number">
                            {{this.serviceRequest.value.vin_number | slice:0:-8}}<span style="color:#eb1e4c">{{this.serviceRequest.value.vin_number.substr(this.serviceRequest.value.vin_number.length - 8)}}</span>
                          </span>

                    </span> 
                    <tooltip-content #myTooltip1 class="image-tooltip" placement="bottom">
                      <img src="{{globalConstants.S3_BUCKET_URL}}{{globalConstants.VEHICLE_VIN_PIC}}{{globalConstants.IMG_ORIGINAL}}{{vin_photo}}?{{vinTimeStamp}}" class="img-responsive">
                    </tooltip-content>
                </div>
              </div>
            </div>
            <div class="row margin-bottom-10">
              <div class="col-xs-6 col-md-6 col-sm-6"><label class="control-label">License Plate</label></div>
              <div class="col-xs-6 col-md-6 col-sm-6"><p id="licensePlate" class="break-word" style="text-transform:uppercase;">{{serviceRequest.value.license_plate || 'N/A'}}</p></div>
            </div>
            <div class="row status-section margin-bottom-10">
              <div class="col-xs-6 col-md-6 col-sm-6"><label class="control-label">Status</label></div>
              <div class="col-xs-6 col-md-6 col-sm-6 break-all">
                  <mat-chip-list class="mat-badge">
                      <mat-chip class="cursor-pointer {{ getStatusColor() }}"  selected="true">
                      <span (click)="showAllstatus()" *ngIf="currentUser.role_id!=4"> {{getStatus()}}</span>
                      <span *ngIf="currentUser.role_id==4"> {{getStatus()}}</span>
                      </mat-chip>
                  </mat-chip-list>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="row margin-bottom-10">
              <div class="col-xs-6 col-md-6 col-sm-6"><label class="control-label" style="text-align:left;">Present Mileage (Miles)<button [disabled]="serviceInfo.is_walkin_customer_vehicle == 1" (click)="editDailog()" class="btn btn-xs btn-link text-red ng-tns-c13-22" title="{{serviceInfo.is_walkin_customer_vehicle == '1' ? 'Mileage can\'t be modified for Walkin Vehcile profile. Please change vehicle against you want to track mileage.' : 'Edit'}}" type="button"><i class="fa fa-pencil fa-lg"></i></button></label></div>
              <div class="col-xs-6 col-md-6 col-sm-6 break-word"><p id="distanceCovered" >{{serviceRequest.value.mileage}} </p></div>
            </div>
            <div class="row margin-bottom-10">
              <div class="col-xs-6 col-md-6 col-sm-6"><label class="control-label">Estimated Service Time</label>
                <button (click)="editDailog()" class="btn btn-xs btn-link text-red ng-tns-c13-22" title="Edit" type="button"><i class="fa fa-pencil fa-lg"></i></button>
              </div>
              <div class="col-xs-6 col-md-6 col-sm-6"><p id="requestedDeliveryDate">{{serviceInfo.est_hours || 'N/A'}} &nbsp;&nbsp;
                <span  [tooltip]="'Estimated service time is ' + (serviceInfo.est_hours ? serviceInfo.est_hours + ' hours' : 'N/A')" tooltipPlacement="top" class="tooltip-status text-black fa fa-solid fa-circle-question hide"></span> 
              </p></div>
            </div>
            <div class="row margin-bottom-10">
              <div class="col-xs-6 col-md-6 col-sm-6"><label class="control-label" [ngClass]="serviceInfo.is_walkin_customer_vehicle == '1' ? 'req-attention' : ''" >Requested Date</label>
                <button (click)="editDailog()" class="btn btn-xs btn-link text-red ng-tns-c13-22" title="Edit" type="button"><i class="fa fa-pencil fa-lg"></i></button>
              </div>
              <div class="col-xs-6 col-md-6 col-sm-6"><p id="requestedDeliveryDate">{{serviceRequest.value.requested_date}} </p></div>
            </div>
            <div class="row margin-bottom-10 scheduledDate-section">
              <div class="col-xs-6 col-md-6 col-sm-6"><label class="control-label">Scheduled Date</label></div>
              <div class="col-xs-6 col-md-6 col-sm-6">
                <div class="vlio-datepicker relative">
                  <dp-date-picker mode="daytime" formControlName="scheduledServiceDate" [config]="datePickerConfig" (onChange)="changeEstimatedDeliveryDate($event)" theme="dp-material dp-main" class="btn-block" #dayPicker></dp-date-picker>
                  <button type="button" mat-icon-button (click)="dayPicker.api.open();">
                    <mat-icon>date_range</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="row neededby-section margin-bottom-10">
              <div class="col-xs-6 col-md-6 col-sm-6"><label class="control-label">Needed By</label></div>
              <div class="col-xs-6 col-md-6 col-sm-6">
                <div class="vlio-datepicker relative">
                  <dp-date-picker mode="daytime" formControlName="estimatedDeliveryDate" [config]="datePickerConfig"  theme="dp-material dp-main" class="btn-block" #dayPicker1></dp-date-picker>
                  <button type="button" mat-icon-button (click)="dayPicker1.api.open();">
                    <mat-icon>date_range</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="row report-section margin-bottom-10">
              <div class="col-xs-6 col-md-6 col-sm-6"><label class="control-label" style="text-align:left;">Vehicle Inspection Report</label></div>
              <div class="col-xs-6 col-md-6 col-sm-6">
                <button type="button" *ngIf="serviceInfo.is_usps_vehicle=='1'" mat-raised-button color="accent" class="font-bold" (click)="openInsceptionReport()">Vehicle Inspection Report</button>
                <button type="button" *ngIf="serviceInfo.is_usps_vehicle!='1'" mat-raised-button color="accent" class="font-bold" (click)="openVehicleInscpectionReport()">Vehicle Inspection Report</button>
                <p *ngIf="VehicleInspectionReport.length>0"><a (click)="invokeDownload(reportDownloadLink,'CARInspectionReport')" href="javascript:void(0)" class="usps-report-submitted usps-report-download text-success" style="position: relative; top: 5px;">
                  <i class="fa fa-download fa-lg"></i>&nbsp;Download</a></p><br>
                  <mat-checkbox class="margin-top-5" *ngIf="VehicleInspectionReport.length==0" [checked]="serviceRequest.value.no_report_performed" (change)="checkVehicleReportFlag($event)">No report Performed</mat-checkbox>
              </div>
            </div>
        </div>

        <div class="row margin-0">
          <div class="col-sm-12 margin-bottom-10">
            <div class="row notes-row">
              <div class="col-xs-12 col-sm-3 col-md-3"><label class="control-label">Notes<button (click)="editDailog()" class="btn btn-xs btn-link text-red ng-tns-c13-22" title="Edit" type="button"><i class="fa fa-pencil fa-lg"></i></button></label></div>
              <div class="col-xs-12 col-sm-9 col-md-9 break-all">
                <textarea [(ngModel)]="serviceInfo.notes" rows="5" maxlength="1000" [ngModelOptions]="{standalone: true}" (blur)="updateSRNotes()"></textarea>
               </div>
            </div>
          </div>
        </div>
        <div class="row margin-0">
          <div class="col-sm-12 margin-bottom-10">
            <div class="row">
              <div class="col-sm-3">
                <label class="control-label">Labels 
                  <button class="btn btn-xs btn-link text-red" title="Edit" type="button">
                    <i class="fa fa-pencil fa-lg" title="Manage Labels" (click)="manageLabels()"></i>
                  </button>
                  </label>
              </div>
              <div class="col-sm-4">
                <div class="form-group details-input uc-ngx-select margin-0">
                  <div class="relative" *ngIf="showLabelsDropdown">
                    <ngx-select [autoClearSearch]="true" class="display-full margin-bottom-5"
                    [defaultValue]="preSelectLabel"
                    [multiple]="true" 
                    [items]="allLabels"
                    (select)="selectedLabel($event)"
                    (remove)="removeLabel($event)"
                    optionTextField="label_name"
                    placeholder="Select Label">
                  </ngx-select>
                  </div>
              </div>
              </div>
          </div>
          </div>
        </div>
        <div class="row margin-0">
          <div class="col-sm-12  margin-bottom-10" >
            <div class="row">
              <div class="col-xs-12 col-sm-3 col-md-3"><label class="control-label">Walk Around Video</label></div>
              <div class="col-xs-12 col-sm-9 col-md-9">
                <div class="vlio-datepicker relative">
                  <div class="upload-image" id="upload-img">
                            <mat-toolbar class="mat-elevation-z2 margin-right-10 upload-btn upload-note videoUpload-section relative">
                              <div class="upload-icon"><mat-icon class="margin-right-10">cloud_upload</mat-icon></div>
                              <span class="drag-file-text">Drag File Here</span>
                              <input type="file" (change)="uploadImages($event)" />
                              <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                            </mat-toolbar>
                            <div class="note"> 
                              <p class="help-block" style="margin:0px;font-size:14px;">Format(s) supported : {{videoTypes}}</p>
                              <p class="help-block" style="margin:0px;font-size:14px;">Maximum size : {{videoMaxAllowedSize}}</p>
                            </div>  
                            <ul class="list-inline margin-bottom-0" >
                              <li class="video-upload "  *ngFor="let image of upload_v;let i=index" >
                                <a class="thumbnail margin-bottom-0 relative" href="javascript:void(0)"  (click)="videoShowMain(image);$event.stopPropagation();">
                                  <span class="relative video-bg">
                                    <mat-icon class="position-center play-video">play_circle_filled</mat-icon>
                                  </span>
                                  <i class="fa fa-trash remove-img" (click)="imageDelete(uploaded_video_id);$event.stopPropagation();"></i>
                                </a>
                              </li>
                            </ul>
                        </div>
                </div>
              </div>
            </div>
          </div>
        </div>




    <div class="row margin-bottom-15" *ngIf="(businessMetricReport | json) != '{}' && profitPermission && hidePrices"> 
      <div class="col-sm-12 ">
        <div class="alert alert-info uc-alert-info profit-alert" >             
          <p class="break-para">What are your overall service request metrics?</p>
          <button class="font-bold mat-raised-button mat-elevation-z0" style="margin-right:220px;width:auto;">
            SR (Profit) : $ {{ businessMetricReport.sr_profit }}
          </button>
          <button class="font-bold mat-raised-button mat-elevation-z0" style="width: auto;">
            SR (Profit Margin) : {{ businessMetricReport.sr_profit_margin }}%
          </button>
        </div>
      </div>
    </div>

    <div class="documents-accordion relative">
        <mat-accordion class="new-request-expension view-request margin-bottom-10 display-full" displayMode="flat" hideToggle="false" >
            <mat-expansion-panel hideToggle="true" id="expansion-rules" >
              <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="expansionHead-rules" >
                <span class="margin-right-5"> </span><b>Documents &amp; Images</b>
                <!-- <h4 class="document__title"  (click)="toggleShowDiv()" ><strong></strong>
                </h4> -->
              </mat-expansion-panel-header>
                <div class="row ">
                    <div class="col-md-6">
                    <label for="fileDocumentUpload" style="font-weight:bold;">Upload Document</label>
                    <mat-toolbar class="mat-elevation-z2 margin-bottom-15 margin-top-5 upload-btn relative" >
                        <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                        Drag File Here
                        <input type="file" multiple id="addrequest_document" name="request_document" (change)="uploadDocImg($event,'request_document')" />
                        <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                    </mat-toolbar>
                    <p style="margin:0px;font-size:14px;" class="help-block">Format(s) supported : pdf, doc, docx</p>
                    <p style="margin:0px;font-size:14px;" class="help-block">Maximum size : 10MB</p>
                    <p style="margin:0px;font-size:14px;" class="help-block bold-text">E.g. : Pdf reports from your scan tool or other relevant documents.</p>
                    <ul class="list-inline margin-left-0 upload-image">
                        <li *ngFor="let doc of requestDoc;let i=index" class="relative view-images">
                        <a (click)="invokeDownload(docPath+doc.document_name,'ServiceRequestDocument',doc.document_name)" href="javascript:void(0)" class="img-set thumbnail relative pdf-img">
                            <!-- <img class="show-image img-responsive" src="assets/img/adobe_pdf-logo.jpg" /> -->
                            <span class="fa far fa-file-pdf fa-4x" *ngIf="getFileIcons(doc.document_name)==3"></span>
                            <span class="fa far fa-file-word fa-4x" *ngIf="getFileIcons(doc.document_name)==4"></span>
                            <span (click)="stopBubbling($event)">
                            <i class="fa fa-trash remove-img" (click)="deleteImgDoc(i,doc.id,'document')"></i>
                            </span>
                            <div class="image-loader">
                            <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                            </div>
                        </a>
                        </li>
                    </ul>
            
                    </div>
                    <div class="col-md-6">
                    <label for="fileImageUpload" style="font-weight:bold;">Upload Image</label>
                    <mat-toolbar class="mat-elevation-z2 margin-bottom-15 margin-top-5 upload-btn relative" >
                        <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                        Drag File Here
                        <input type="file" multiple id="addrequest_image" name="request_image" (change)="uploadDocImg($event,'request_image')" />
                        <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                    </mat-toolbar>
                    <p style="margin:0px;font-size:14px;" class="help-block">Format(s) supported : {{imgTypes}}</p>
                    <p style="margin:0px;font-size:14px;" class="help-block">Maximum size : {{imgMaxAllowedSize}}</p>
                    <p style="margin:0px;font-size:14px;" class="help-block bold-text">E.g. : Picture of instrument cluster, 4 corners of car, vehicle damage, etc. </p>
                    <ul class="list-inline margin-left-0 upload-image">
                        <li class="img-set relative view-images" *ngFor="let img of requestImages;let i=index" style="border:none">
                          <a (click)="imageToolDialog($event,i,8,requestImages)" class="thumbnail" href="javascript:void(0);$event.stopPropagation();">
                            <img class="show-image img-responsive" src="{{requestImgPath}}{{img.image_name}}" />
                            <i class="fa fa-trash remove-img" (click)="deleteImgDoc(i,img.id,'image');$event.stopPropagation();"></i>
                        </a>
                        <div class="image-loader">
                            <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                        </div>
                        </li>
                    </ul>
            
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <!-- <div class="form-group upload_documents_section   mat-expansion-panel" id="documentsAndImagesContainer">
            <h4 class="col-md-12 document__title mat-expansion-panel-header"  (click)="toggleShowDiv()" ><strong>Documents &amp; Images</strong>
            </h4>
            <div class="col-sm-12 show-documents" [@slideInOut]="animationState">
            <div class="col-md-6">
              <label for="fileDocumentUpload" style="font-weight:bold;">Upload Document</label>
              <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative" >
                <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                Drag File Here
                <input type="file" multiple id="addrequest_document" name="request_document" (change)="uploadDocImg($event,'request_document')" />
                <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
              </mat-toolbar>
              <p style="margin:0px;font-size:14px;" class="help-block">Format(s) supported : pdf, doc, docx</p>
              <p style="margin:0px;font-size:14px;" class="help-block">Maximum size : 10MB</p>
              <p style="margin:0px;font-size:14px;" class="help-block bold-text">E.g. : Pdf reports from your scan tool or other relevant documents.</p>
              <ul class="list-inline margin-left-0 upload-image">
                <li *ngFor="let doc of requestDoc;let i=index" class="relative view-images">
                  <a (click)="invokeDownload(docPath+doc.document_name,'ServiceRequestDocument',doc.document_name)" href="javascript:void(0)" class="img-set thumbnail relative pdf-img">
                    <span class="fa far fa-file-pdf fa-4x" *ngIf="getFileIcons(doc.document_name)==3"></span>
                    <span class="fa far fa-file-word fa-4x" *ngIf="getFileIcons(doc.document_name)==4"></span>
                    <span (click)="stopBubbling($event)">
                      <i class="fa fa-trash remove-img" (click)="deleteImgDoc(i,doc.id,'document')"></i>
                    </span>
                    <div class="image-loader">
                      <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                    </div>
                  </a>
                </li>
              </ul>
        
            </div>
            <div class="col-md-6">
              <label for="fileImageUpload" style="font-weight:bold;">Upload Image</label>
              <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative" >
                <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                Drag File Here
                <input type="file" multiple id="addrequest_image" name="request_image" (change)="uploadDocImg($event,'request_image')" />
                <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
              </mat-toolbar>
              <p style="margin:0px;font-size:14px;" class="help-block">Format(s) supported : {{imgTypes}}</p>
              <p style="margin:0px;font-size:14px;" class="help-block">Maximum size : {{imgMaxAllowedSize}}</p>
              <p style="margin:0px;font-size:14px;" class="help-block bold-text">E.g. : Picture of instrument cluster, 4 corners of car, vehicle damage, etc. </p>
              <ul class="list-inline margin-left-0 upload-image">
                <li class="img-set relative view-images" *ngFor="let img of requestImages;let i=index" style="border:none">
                  <a (click)="imageToolDialog($event,i,8,requestImages)" class="thumbnail" href="javascript:void(0);">
                    <img class="show-image img-responsive" src="{{requestImgPath}}{{img.image_name}}" />
                    <i class="fa fa-trash remove-img" (click)="deleteImgDoc(i,img.id,'image')"></i>
                  </a>
                  <div class="image-loader">
                    <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                  </div>
                </li>
              </ul>
        
            </div>
            </div>
            </div> -->
    </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="alert alert-info uc-alert-info" *ngIf="serviceRequest.value.usps_vehicle == '1'">
                    <p>Perform Emissions Inspection. Click below button to perform one now?</p>
                    <button mat-raised-button class="font-bold mat-elevation-z0" color="accent" (click)="emissionInspection()">
                      <span>Emission Inspection</span>
                    </button>
              </div>
              <div class="alert alert-info uc-alert-info two-btn print-alert">
                <p>Estimate and Tech Sheet print can be taken after filling out recommendations.</p>
                <button mat-raised-button [ngClass]="isAllowed('views','invoices') && isAllowed('views','view_customer_info') ? 'print-tech-sheet-btn' : ''" class="font-bold mat-elevation-z0" color="accent"  (click)="printTechSheet()" [disabled]="serviceRequest.value.recommendation_saved != '1'">
                  <span>Print Tech Sheet</span>
                </button>
                <button mat-raised-button class="font-bold mat-elevation-z0" color="accent" (click)="printInvoice()" [disabled]="serviceRequest.value.recommendation_saved != '1'" *ngIf="isAllowed('views','invoices') && isAllowed('views','view_customer_info')">
                  <span>Print Estimate</span>
                </button>
              </div>
              <div class="alert alert-info uc-alert-info orderParts-alert" *ngIf="isAllowed('views','partstech_order') || isAllowed('views','nexpart_order')">
                <p>Place your order on PartsTech{{ isAllowed('views','nexpart_order') ? '/Nexpart' : ''}} now.</p>
                <button title="PartsTech Order Now" *ngIf="isAllowed('views','partstech_order')" (click)="getDataForOrderNow()" class="font-bold btn  mat-elevation-z0" color="accent"  [disabled]="serviceRequest.value.recommendation_saved != '1'" [ngStyle]="{'right': (isAllowed('views','nexpart_order') ? '195px' : '10px')}">
                  <span><img style="width:140px;" alt="PartsTech Order Now" src="assets/img/PartsTechLogo.png"  /></span>
                </button>
                <button title="Nexpart Order Now" *ngIf="isAllowed('views','nexpart_order')" (click)="getDataForOrderNowNexpart()" class="font-bold btn  mat-elevation-z0" color="accent"  [disabled]="serviceRequest.value.recommendation_saved != '1'">
                  <span><img style="height:20px;" alt="Nexpart Order Now" [src]="nexpartLogoURL" /></span>
                </button>
              </div>
            </div>
          </div>
      </div>
      <div class="row">
        <div class="documents-accordion relative addJob-section">
          <mat-accordion class="new-request-expension show-price uc-add-new-jobs margin-bottom-15 margin-top-10 display-full" hideToggle="true">
            <mat-expansion-panel hideToggle="false" class="new-request-expension relative fillRecomendation-addjob" id="addJobExpansion" (opened)="showWaring()">
              <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="addJobexpansionHead" >
                <span class="margin-right-5"> </span><b>Add Job</b>
              </mat-expansion-panel-header>
              <div class="col-sm-12 margin-bottom-10 padding-0 relative">
                <input style="border-radius:0px" ngui-auto-complete (valueChanged)="getSelectedOptionForx($event)"
                  (click)="carAttribute()" [source]="observableSourceForx" [list-formatter]="autocompleListFormatter"
                  value-property-name="value" placeholder="Search Attribute" id="searchAddJobAattribute" class="form-control" />
              </div>
              <div class="alert alert-warning col-sm-12">
                <p>Note: Make sure you have saved the recommendations changes before adding new jobs.</p>
              </div>
              <mat-accordion class="new-request-expension show-price margin-bottom-15 margin-top-10 display-full"
                hideToggle="true">
                <mat-expansion-panel [expanded]="i==0" hideToggle="true" class="new-request-expension relative"
                  *ngFor="let job of newJobs;let i = index" id="addExpansion-{{i+1}}">
                  <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="addNewJobExpansionHead-{{i+1}}">
                    <span class="margin-right-5">{{ i+1 }}.</span><b>{{ job.ln }}</b>
                  </mat-expansion-panel-header>
                  <ul class="service-list-items" *ngFor="let ch2nd of job.child;let j=index">
                      <li>
                        <span>{{ch2nd.ln}}</span>
                        <button type="button" *ngIf="ch2nd.child.length==0" class="btn btn-link text-red" (click)="deleteNewJob(i,j)"><span class="fa fa-trash"></span></button>
                        <ul class="service-list-items" *ngFor="let ch of ch2nd.child;let k=index">
                            <li>
                              <span>{{ch.ln}}</span>
                              <button type="button" class="btn btn-link text-red" (click)="deleteNewJob(i,j,k)"><span class="fa fa-trash"></span></button>
                              <mat-form-field class="car-input btn-block" floatPlaceholder="never" *ngIf="ch.type == 'textarea'">
                                  <textarea matInput placeholder="comment" title="comment" rows="4" maxlength="500" (keyup)="addNewJobComment($event,i,j,k)"></textarea>
                              </mat-form-field>
                            </li>
                        </ul>
                      </li>
                  </ul>
                </mat-expansion-panel>
              </mat-accordion>
              <button type="button" (click)="addNewJobs()" *ngIf="newJobs.length > 0 " class="btn btn-success pull-right">Submit Jobs</button>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div *ngIf="noJobsAddedMsg" class="noJobStyle">No Jobs Found</div>
        <div class="documents-accordion relative">
        <mat-accordion class="new-request-expension show-price margin-bottom-10 margin-top-10 display-full" displayMode="flat" hideToggle="false">
          <!-- job-category-container starts -->
          <div class="job-category-container" *ngFor="let servicerequest of serviceRequestList; let i = index">
              <mat-expansion-panel [expanded]="i==jobOpenIndex" hideToggle="true" class="new-request-expension relative"  id="expansion-{{i+1}}" >
              <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="expansionHead-{{i+1}}" (click)="expansionOpen(i+1);" [attr.data-state]="0"   [attr.data-level]="servicerequest.id">
                <div class="job-name text-ellipsis"><span class="margin-right-5">{{ i+1 }}.</span><b>{{ servicerequest.name }}</b></div>
                <div class="row hidden-xs subjob-category-wrapper-desktop">
                  <div class="col-sm-12" *ngFor="let jobCategories of servicerequest.subJobCategories; let sn = index">
                    <p class="subjobs"><span>{{ sn+1 }}.</span><span> {{jobCategories}}</span></p>
                  </div>
                </div>
              </mat-expansion-panel-header>
              <ul class="service-list-items">
              <li *ngFor="let servicerequestChild of servicerequest.children;let j = index"  [attr.data-level]="servicerequestChild.id" >
                <p>{{servicerequestChild.name}}</p>
                <button *ngIf="isPaste(servicerequestChild) " class="btn-copy-paste paste-btn margin-left-5 margin-bottom-10 margin-sm-bottom-0" title="Paste" (click)="pasteJobData(servicerequestChild,servicerequest,servicerequestChild.id)">
                    <i class="fa fa-clipboard"></i>
                </button>
                <button class="btn-copy-paste js-btn margin-bottom-10 margin-sm-bottom-0" title="Job Suggestions" *ngIf="servicerequestChild.children == undefined || servicerequestChild.children.length == 0"
                (click)="showJobMatchDialog(servicerequest,servicerequestChild,servicerequestChild.id)"><i class="fa fa-regular fa-lightbulb"></i></button>
                <!-- {{servicerequestChild.id}} -->

                <!-- No 3rd level child-->
                <div *ngIf="servicerequestChild.children == undefined || servicerequestChild.children.length == 0">
                  <div class="row margin-bottom-0">
                    <div class="col-sm-12 padding-lr-0-mob">

                      <!--Structure for Parts addition/ deletion for level 2 ---->
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Part(s): </label>
                        <div class="col-sm-9">
                          <div class="form-inline quick-service-section relative">
                              <input placeholder="Enter Part Name" matInput ngui-auto-complete (valueChanged) = "getSelectedOption($event,servicerequestChild,servicerequest)"
                              [source]="observableSource"
                              [list-formatter]="autoCompleListParts"
                              value-property-name="label"
                              (click)="setReturnFalse()"
                              id="new-part-{{servicerequestChild.id}}-ac" class="form-control"/>

                            <mat-form-field class="car-input margin-right-5" [class.hide]="!showCostPrice" floatPlaceholder="never" appearance="fill" style=" max-width: 96px; ">
                              <input type="text" placeholder="Price" aria-label="Price" id="new-part-{{servicerequestChild.id}}-price" class="form-control input-lg custom-form-input btn-block" matInput (keypress)="onlyNumericKey($event)"/>
                            </mat-form-field>

                            <mat-form-field class="car-input margin-right-5" floatPlaceholder="never" appearance="fill" style=" max-width: 100px; ">
                              <input type="text" placeholder="Quantity" aria-label="Quantity" id="new-part-{{servicerequestChild.id}}-qty" class="form-control input-lg custom-form-input btn-block" matInput maxlength="5" (keypress)="onlyNumericKey($event)" />
                            </mat-form-field>

                            <button type="button" class="btn btn-default addPart" *ngIf="showUpdateBtn.id!=servicerequestChild.id && !showUpdateBtn.show" (click)="addNewPart($event,servicerequestChild,servicerequestChild.recommendations[0],i)" id="new-part-{{servicerequestChild.id}}-add-btn">Add</button>
                            <button type="button" class="btn btn-default updatePart hidden"  (click)="UpdateNewPart($event,servicerequestChild,servicerequestChild.recommendations[0],i)" id="new-part-{{servicerequestChild.id}}-update-btn">Update</button>
                            <button type="button" class="btn btn-default updatePart hidden"  (click)="editNewPart($event,servicerequestChild,servicerequestChild.recommendations[0],i)" id="edit-part-{{servicerequestChild.id}}-edit-btn">Edit Part</button>

                            <button type="button" *ngIf="isAllowed('addNew','part')" class="btn btn-success addNewPart add-new-btn" id="new-part-{{servicerequestChild.id}}-add-new-btn" (click)="addNewPartOpen(servicerequestChild.recommendations[0],2,servicerequest.id,servicerequestChild.id,null)"><i class="fa fa-fw fa-plus"></i>Add New</button>
                            <button mat-button class="order-parts-btn" color="primary" *ngIf="showPartsTechButon || showNexpartButton" type="button" [matMenuTriggerFor]="partOrderingVendors" title="Search Parts" >
                              <i class="fa fa-shopping-cart"></i>
                              <span>&nbsp;Search Parts</span>
                            </button>
                            <mat-menu #partOrderingVendors="matMenu" class="part-ordering-sites" [overlapTrigger]="false" xPosition="before">
                              <button mat-menu-item *ngIf="showPartsTechButon" type="button" class="btn btn-gray" id="new-part-{{servicerequestChild.id}}-partech-new-btn" (click)="partech(servicerequest.id,servicerequestChild.id,null,servicerequestChild.recommendations[0],servicerequest,servicerequestChild,null)" ><img style="width:140px;" src="assets/img/PartsTechLogo.png" /></button>
                              <button mat-menu-item *ngIf="showNexpartButton && vendorsData && vendorsData.length == 1" title="Nexpart Vendors" disableRipple type="button" class="btn btn-gray" id="new-part-{{servicerequestChild.id}}-nexpart-new-btn" (click)="nexpart(servicerequest.id,servicerequestChild.id,null,servicerequestChild.recommendations[0])" >
                                <img style="width:auto; height: 20px;" [src]="nexpartLogoURL" />
                              </button>
                              <button mat-menu-item *ngIf="showNexpartButton && vendorsData && vendorsData.length > 1" [matMenuTriggerFor]="nexpartVendors" title="Nexpart Vendors" disableRipple type="button" class="btn btn-gray" id="new-part-{{servicerequestChild.id}}-nexpart-new-btn">
                                <img style="width:auto; height: 20px;" [src]="nexpartLogoURL" />
                              </button>
                            </mat-menu>
                            <mat-menu #nexpartVendors="matMenu" class="nexpart-vendors-list {{ (!vendorNameVisibility) ? 'only-img':'' }}" [overlapTrigger]="false" xPosition="before">
                              <mat-list *ngIf="vendorNameVisibility">
                                <mat-list-item *ngFor="let vendor of vendorsData" (click)="selectVendor(servicerequest.id,servicerequestChild.id,null,servicerequestChild.recommendations[0], vendor)">
                                  <img matListAvatar *ngIf="vendor.vendor_logo" src="{{logoBaseUrl}}{{vendor.vendor_logo}}" alt="Vendor Logo">
                                  <img matListAvatar *ngIf="!vendor.vendor_logo" src="{{defaultLogo}}" alt="Vendor Logo">
                                  <p matLine>
                                    <span>{{ vendor.label }}</span>
                                  </p>
                                </mat-list-item>
                              </mat-list>
                              <mat-list *ngIf="!vendorNameVisibility">
                                <mat-list-item *ngFor="let vendor of vendorsData" (click)="selectVendor(servicerequest.id,servicerequestChild.id,null,servicerequestChild.recommendations[0], vendor)">
                                  <img matListAvatar *ngIf="vendor.vendor_logo" src="{{logoBaseUrl}}{{vendor.vendor_logo}}" alt="Vendor Logo">
                                  <img matListAvatar *ngIf="!vendor.vendor_logo" src="{{defaultLogo}}" alt="Vendor Logo">
                                </mat-list-item>
                              </mat-list>
                            </mat-menu>
                            <button mat-button class="stock-order-btn" color="primary" title="Stock Order" *ngIf="showPartsTechButon" id="stockOrder{{servicerequestChild.recommendations[0].service_request_list_id}}" (click)="AddPartsInStockOrder(servicerequestChild.recommendations)">
                              <i class="fa fa-cart-plus"></i><span> Stock Order</span>
                            </button>
                          </div>
                          <div class="addedPartsItems col-xs-12  col-md-12">

                            <div *ngIf="viewingJSON[servicerequestChild.recommendations[0].id]!=undefined && viewingJSON[servicerequestChild.recommendations[0].id]['AP'].length > 0">
                              <div class="row">
                                <div class="col-sm-12 padding-0 margin-top-5"><strong>Part(s) Already Included: </strong></div>
                              </div>

                              <div class="partRow row selPart-1" *ngFor="let recommendation of servicerequestChild.recommendations">
                                <div class="display-full row" [class.part-item-active]="viewingJSON[recommendation.id]['UAP'][a].is_editable" [class.text-orange]="viewingJSON[recommendation.id]['AP'][a].partstech==1 || viewingJSON[recommendation.id]['AP'][a].partstech_order==1" [class.text-nexpart-green]="viewingJSON[recommendation.id]['AP'][a].nexpart==1 || viewingJSON[recommendation.id]['AP'][a].nexpart_order==1" *ngFor="let parts of viewingJSON[recommendation.id]['AP'];let a=index">
                                    <div class="col-md-5 col-lg-7 pname break-all" title="{{getPartToolTipTitle(viewingJSON[recommendation.id]['AP'][a])}}">{{viewingJSON[recommendation.id]['AP'][a].part_name}}</div>
                                    <div class="col-md-7 col-lg-5">
                                      <div class="row">
                                        <div class="col-sm-5 col-md-5 col-xs-5 pprice">
                                          <span [tooltip]="gnTipId(parts)" *ngIf="isAllowed('views','display_part_cost')">$ {{viewingJSON[recommendation.id]['AP'][a].part_price}}</span>
                                          
                                        </div>
                                        <div class="col-sm-3 col-md-3 col-xs-3 pquantity" [class.text-red]="isInventory && isStockEmpty(viewingJSON[recommendation.id]['AP'][a])"><span *ngIf="isInventory"><i title="{{ stockInHand( viewingJSON[recommendation.id]['AP'][a] ) }}" class="fa fa-info-circle" (click)="suggestion(a,servicerequestChild.recommendations[0],parts)"></i>&nbsp;</span>{{viewingJSON[recommendation.id]['AP'][a].part_qty}} </div>
                                        <div class="col-sm-4 col-md-4 col-xs-4 text-right padding-0 actions-srfr">
                                          <!-- # button  with icon -->
                                          <button [disabled] = "viewingJSON[recommendation.id]['UAP'][a].is_disabled || viewingJSON[recommendation.id]['UAP'][a].is_editable" (click)="addStockOrder(a,recommendation,'AP',parts)" *ngIf="addTostockOrder && isStockEmpty(viewingJSON[recommendation.id]['AP'][a])" class="btn btn-xs btn-link text-red" title="Add to stock order"><i class="fa fa-cart-plus fa-lg" ></i></button>
                                          <button *ngIf="!viewingJSON[recommendation.id]['UAP'][a].is_editable" [disabled] ="viewingJSON[recommendation.id]['UAP'][a].is_disabled" (click)="editPart(a,servicerequestChild,recommendation,'AP')" type="button" title="Edit" class="btn btn-xs btn-link text-red "><i class="fa fa-pencil fa-lg"></i></button>
                                          <button *ngIf="viewingJSON[recommendation.id]['UAP'][a].is_editable == true" (click)="CancelEditPart(a,servicerequestChild,recommendation,'AP')" type="button" title="Cancel" class="btn btn-xs btn-link text-red "><i class="fa fa-times fa-lg"></i></button>
                                          <button [disabled] = "viewingJSON[recommendation.id]['UAP'][a].is_disabled || viewingJSON[recommendation.id]['UAP'][a].is_editable" (click)="deleteParts(a,recommendation,'AP',parts,servicerequest,servicerequestChild,null)" type="button" title="Delete" class="btn btn-xs btn-link text-red"><i class="fa fa-trash fa-lg"></i></button>

                                        </div>
                                      </div>
                                    </div>

                                </div>
                              </div>
                            </div>

                            <div class="row" *ngIf="viewingJSON[servicerequestChild.recommendations[0]['id']]!=undefined && viewingJSON[servicerequestChild.recommendations[0]['id']]['NP'].length > 0">
                              <div class="col-sm-12 padding-0 margin-top-5"><strong>Part(s) Newly Added : </strong></div>
                            </div>

                            <div class="partRow row selPart-1" *ngFor="let recommendation of servicerequestChild.recommendations">
                              <div  *ngFor="let part of viewingJSON[recommendation.id]['NP'];let a=index" class="display-full row" [class.part-item-active]="viewingJSON[recommendation.id]['UNP'][a].is_editable"  [class.text-orange]="viewingJSON[recommendation.id]['NP'][a].partstech==1 || viewingJSON[recommendation.id]['NP'][a].partstech_order==1" [class.text-nexpart-green]="viewingJSON[recommendation.id]['NP'][a].nexpart==1 && viewingJSON[recommendation.id]['NP'][a].nexpart_order==0" style="padding-top:3px;">
                                <div class="col-md-5 col-lg-7 pname break-all" title="{{getPartToolTipTitle(viewingJSON[recommendation.id]['NP'][a])}}">{{viewingJSON[recommendation.id]['NP'][a].part_name}} </div>
                                <div class="col-md-7 col-lg-5">
                                  <div class="row">
                                    <div class="col-sm-5 col-md-5 col-xs-4 pprice">
                                      <span [tooltip]="gnTipId(part)" *ngIf="isAllowed('views','display_part_cost')">$ {{viewingJSON[recommendation.id]['NP'][a].part_price}}</span>
                                    </div>
                                    <div class="col-sm-3 col-md-3 col-xs-3 pquantity" [class.text-red]="isInventory && isStockEmpty(viewingJSON[recommendation.id]['NP'][a])"><span *ngIf="isInventory"><i title="{{ stockInHand( viewingJSON[recommendation.id]['NP'][a] ) }}" class="fa fa-info-circle" (click)="suggestion(a,servicerequestChild.recommendations[0],part)"></i>&nbsp;</span>{{viewingJSON[recommendation.id]['NP'][a].part_qty}} </div>
                                    <div class="col-sm-4 col-md-4 col-xs-5 text-right padding-0 actions-srfr">
                                      <!-- # button  with icon -->
                                      <button [disabled] = "viewingJSON[recommendation.id]['UNP'][a].is_disabled || viewingJSON[recommendation.id]['UNP'][a].is_editable" (click)="addStockOrder(a,recommendation,'NP',part)" *ngIf="addTostockOrder && isStockEmpty(viewingJSON[recommendation.id]['NP'][a])" class="btn btn-xs btn-link text-red" title="Add to stock order"><i class="fa fa-cart-plus fa-lg" ></i></button>
                                      <button *ngIf="!viewingJSON[recommendation.id]['UNP'][a].is_editable"  [disabled] ="viewingJSON[recommendation.id]['UNP'][a].is_disabled" (click)="editPart(a,servicerequestChild,recommendation,'NP')" type="button" title="Edit" class="btn btn-xs btn-link text-red "><i class="fa fa-pencil fa-lg"></i></button>
                                      <button *ngIf="viewingJSON[recommendation.id]['UNP'][a].is_editable" (click)="CancelEditPart(a,servicerequestChild,recommendation,'NP')" type="button" title="Cancel" class="btn btn-xs btn-link text-red "><i class="fa fa-times fa-lg"></i></button>
                                      <button [disabled] = "viewingJSON[recommendation.id]['UNP'][a].is_disabled || viewingJSON[recommendation.id]['UNP'][a].is_editable" (click)="deleteParts(a,recommendation,'NP',part,servicerequest,servicerequestChild,null)" type="button" title="Delete" class="btn btn-xs btn-link text-red"><i class="fa fa-trash fa-lg"></i></button>

                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Structure for GJ addition/ deletion for level 2 ---->
                  <div class="row margin-bottom-0">
                    <div class="col-sm-12 padding-lr-0-mob">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Generic Job(s):</label>
                        <div class="col-sm-9">
                          <div class="form-inline quick-service-section relative">

                            <input matInput ngui-auto-complete (valueChanged) = "getSelectedOptionForGj($event,servicerequestChild,servicerequest)"
                            [source]="observableSourceforGJ" [list-formatter]="myListFormatter"
                            value-property-name="label"
                            placeholder="Enter Generic Job"
                            (click)="setReturnFalse()"
                            id="new-gj-{{servicerequestChild.id}}-ac"
                            class="form-control"/>

                            <mat-form-field class="car-input quantity-input" floatPlaceholder="never" appearance="fill">
                              <input type="text" placeholder="Quantity" aria-label="Quantity" id="new-gj-{{servicerequestChild.id}}-qty"  class="form-control input-lg custom-form-input btn-block" matInput maxlength="5" (keypress)="onlyNumericKey($event)" />
                            </mat-form-field>
                            <button type="button" class="btn btn-default addGJobs" (click)="addNewGJ($event,servicerequestChild,servicerequestChild.recommendations[0],j)">Add</button>
                            <button type="button" *ngIf="isAllowed('addNew','generic_job')" class="btn btn-success addNewJob" (click)="addNewJob(servicerequestChild.recommendations[0],2,servicerequest.id,servicerequestChild.id,null)"><i class="fa fa-fw fa-plus"></i>Add New</button>
                          </div>

                          <div class="addedPartsItems addedJobs col-xs-12 col-md-12">

                            <div *ngIf="viewingJSON[servicerequestChild.recommendations[0].id]!=undefined && viewingJSON[servicerequestChild.recommendations[0].id]['AGJ'].length > 0">
                              <div class="row"><div class="col-sm-12 padding-0 margin-top-5"><strong>Job(s) Already Included: </strong></div></div>
                              <div class="partRow row selPart-1" *ngFor="let recommendation of servicerequestChild.recommendations">
                                <div class="display-full row" *ngFor="let job of viewingJSON[recommendation.id]['AGJ'];let a = index">
                                  <div class="col-md-7 col-lg-7" >{{viewingJSON[recommendation.id]['AGJ'][a].name}} </div>
                                  <div class="col-md-5 col-lg-5">
                                    <div class="row addedJob-details">
                                      <div class="col-sm-5 col-md-5 col-xs-5"><span *ngIf="showCostPrice">$ {{viewingJSON[recommendation.id]['AGJ'][a].price}}</span></div>
                                      <div class="col-sm-3 col-md-3 col-xs-3">{{viewingJSON[recommendation.id]['AGJ'][a].quantity}}</div>
                                      <div class="col-sm-4 col-md-4 col-xs-4 text-right padding-right-0">
                                        <button type="button" title="Delete" class="btn btn-xs btn-link text-red" (click)="removeGJ(a,recommendation,'AGJ')"><i class="fa fa-trash fa-lg"></i></button>
                                        <!-- <input type="button" class="btn btn-danger btn-sm remove" value="Remove" (click)="removeGJ(a,recommendation,'AGJ')"> -->
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" *ngIf="viewingJSON[servicerequestChild.recommendations[0]['id']]!=undefined && viewingJSON[servicerequestChild.recommendations[0]['id']]['NGJ'].length > 0">
                              <div class="col-sm-12 padding-0 margin-top-5"><strong>Job(s) Newly Added: </strong></div>
                            </div>
                            <div class="partRow row" *ngFor="let recommendation of servicerequestChild.recommendations">
                              <div class="display-full row" style="padding-top:3px;" *ngFor="let job of viewingJSON[recommendation.id]['NGJ'];let a=index">
                                <div class="col-md-5 col-lg-7 pname break-all">{{viewingJSON[recommendation.id]['NGJ'][a].name}}</div>
                                <div class="col-md-7 col-lg-5">
                                  <div class="row addedJob-details">
                                    <div class="col-sm-5 col-md-5 col-xs-4"><span *ngIf="showCostPrice">$ {{viewingJSON[recommendation.id]['NGJ'][a].price}}</span></div>
                                    <div class="col-sm-3 col-md-3 col-xs-3">{{viewingJSON[recommendation.id]['NGJ'][a].quantity}}</div>
                                    <div class="col-sm-4 col-md-4 col-xs-5 text-right padding-right-0">
                                      <button type="button" title="Delete" class="btn btn-xs btn-link text-red" (click)=" removeGJ(a,recommendation,'NGJ')"><i class="fa fa-trash fa-lg"></i></button>
                                      <!-- <input type="button" class="btn btn-danger btn-sm remove" value="Remove" (click)=" removeGJ(a,recommendation,'NGJ')"> -->
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!------------------>
                    <div class="row" *ngIf="currentUser.subscription_data.nexpart == 1">
                      <div class="col-sm-12 padding-lr-0-mob">
                        <div class="form-group">
                          <label *ngIf="serviceInfo.partstech_labor == 1" class="col-sm-3 col-lg-2 text-right hoursLbr control-label"
                          data-bs-toggle="tooltip" data-bs-placement="right" title="Nexpart: {{parseFloats(tempReports[servicerequest.id][servicerequestChild.id]['totalNextPartLaborHours'])}} Hrs, PartsTech: {{parseFloats(tempReports[servicerequest.id][servicerequestChild.id]['totalPartsTechLaborHours'])}} Hrs">Nexpart/PartsTech Labor (Hours): </label>
                          <label *ngIf="serviceInfo.partstech_labor == 0" class="col-sm-3 col-lg-2 text-right hoursLbr control-label"
                          data-bs-toggle="tooltip" data-bs-placement="right" title="Nexpart: {{parseFloats(tempReports[servicerequest.id][servicerequestChild.id]['totalNextPartLaborHours'])}} Hrs">Nexpart (Hours): </label>
                          <div class="col-sm-9">
                            <div class="form-inline">
                              <mat-form-field class="car-input text-inline data-input" floatPlaceholder="never" appearance="fill">
                                <input type="text" placeholder="Nexpart/PartsTech Labor (Hours)" class="form-control input-lg custom-form-input btn-block" matInput maxlength="5" (keypress)="onlyNumericKey($event)" value="{{parseFloats(tempReports[servicerequest.id][servicerequestChild.id]['nexpart_labor_time'])}}" (change)="addNexpartLabour($any($event.target).value,servicerequest,servicerequestChild,undefined)" (keypress)="onlyNumericKey($event)"/>
                              </mat-form-field>

                            <!--   <button (click)="copyText()" *ngIf="serviceRequest.value.vin_number" class="btn btn-xs btn-link text-red ng-tns-c13-22" title="Copy VIN" type="button"><i class="fa fa-copy fa-lg"></i></button> -->

                            <button type="button"  class="btn btn-xs btn-link text-black ng-tns-c13-22" (click)="copyNexpartLaborNotes(servicerequest,servicerequestChild,undefined)" title="Copy Nexpart labor description" ><i class="fa fa-copy fa-lg"></i></button>
                            <button type="button" *ngIf="serviceInfo.partstech_labor ==1" class="btn btn-xs btn-link text-red ng-tns-c13-22" (click)="copyPartsTechLaborNotes(servicerequest,servicerequestChild,undefined)" title="Copy PartsTech labor description" ><i class="fa fa-copy fa-lg"></i></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> 
                  <!------------------>
                  <div class="row">
                    <div class="col-sm-12 padding-lr-0-mob">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Labor/Hours:</label>
                        <div class="col-sm-9">
                          <div class="form-inline">
                            <mat-form-field class="car-input text-inline  data-input" floatPlaceholder="never" appearance="fill">
                              <input type="text" placeholder="Estimated Time (hours)" class="form-control input-lg custom-form-input btn-block" matInput maxlength="5" (keypress)="onlyNumericKey($event)" value="{{parseFloats(tempReports[servicerequest.id][servicerequestChild.id]['estimated_time'])}}" (change)="addTime($event,servicerequest,servicerequestChild,undefined)" (keypress)="onlyNumericKey($event)"/>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" [class.hide]="!showCostPrice">
                    <div class="col-sm-12 padding-lr-0-mob">
                      <div class="form-group lbr-cost-section">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Labor $:</label>
                        <div class="col-sm-5">
                          <div class="form-inline">
                            <mat-form-field class="car-input text-inline  data-input" floatPlaceholder="never" appearance="fill">
                              <input type="text" placeholder="Estimated Labor Charge" class="form-control input-lg custom-form-input btn-block" matInput (keypress)="onlyNumericKey($event)" value="{{parseFloats(tempReports[servicerequest.id][servicerequestChild.id]['estimated_labor'])}}" (change)="addLabor($event,servicerequest,servicerequestChild,undefined)" (keypress)="onlyNumericKey($event)"/>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="col-sm-4">
                          <div class="form-group uc-ngx-select">
                            <div class="relative">
                              <select (change)="selectLabourType($event,servicerequest,servicerequestChild,undefined)"
                                class="form-control input-lg custom-form-input btn-block" [(ngModel)]="tempReports[servicerequest.id][servicerequestChild.id]['labour_type']" [ngModelOptions]="{standalone: true}">
                                <option  disabled>Need to Change Labor Rate? Select Below:</option>
                                <option *ngFor="let rates of labourRatesType" [value]="rates.id">
                                  
                                  {{rates.type}} - ${{rates.labor_rate}}
                                </option>
                                <option *ngIf="tempReports[servicerequest.id][servicerequestChild.id]['select_costom_type']" [value]="0">
                                      Custom - {{tempReports[servicerequest.id][servicerequestChild.id]['old_labour_rate']}}
                                </option>
                              </select>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row margin-bottom-0">
                    <div class="col-sm-12 padding-lr-0-mob">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Computer Code(s):</label>
                        <div class="col-sm-9">
                          <div class="form-inline relative quick-service-section computer-code">

                            <input matInput ngui-auto-complete (valueChanged) = "getSelectedOptionForCC($event,servicerequestChild,servicerequest)"
                            [source]="observableSourceforCC" [list-formatter]="autocompleListFormatter"
                            value-property-name="label"
                            placeholder="Enter Computer Code"
                            (click)="setReturnFalse()"
                            id="new-cc-{{servicerequestChild.id}}-ac"
                            class="form-control"/>

                            <button type="button" class="btn btn-default addComputerCode"(click)="addNewComputerCodeFromList(servicerequestChild,servicerequestChild.recommendations[0],j)">Add</button>
                            <button type="button" *ngIf="isAllowed('addNew','computer_code')" class="btn btn-success addNewComputerCode" (click)="addNewComputerCode(servicerequestChild.recommendations[0],2,servicerequest.id,servicerequestChild.id,null)"><i class="fa fa-fw fa-plus"></i>Add New</button>
                          </div>
                          <div class="addedPartsItems col-xs-12  col-md-12">

                            <div class="row" *ngIf="viewingJSON[servicerequestChild.recommendations[0].id]['ACC'].length > 0">
                              <div class="col-sm-12 padding-0 margin-top-5"><strong>Code(s) Already Included: </strong></div>
                            </div>

                            <div class="partRow row selPart-1" *ngFor="let recommendation of servicerequestChild.recommendations">
                              <div class="display-full row" style="padding-top:3px;" *ngFor="let cc of viewingJSON[recommendation.id]['ACC'];let a =index">
                                <div class="col-xs-9 col-md-7 col-lg-7 pname break-all padding-right-0">
                                    {{ viewingJSON[recommendation.id]['ACC'][a].trouble_code}} {{ viewingJSON[recommendation.id]['ACC'][a].fault_location}}
                                </div>
                                <div class="col-xs-3 col-md-5 col-lg-5 text-right addedCode-details padding-right-0">
                                  <button type="button" title="Delete" class="btn btn-xs btn-link text-red" (click)=" removeCC(a,recommendation,'ACC')"><i class="fa fa-trash fa-lg"></i></button>
                                  <!-- <div class="row addedCode-details">
                                    <div class="col-sm-5"></div>
                                    <div class="col-sm-3"></div>
                                    <div class="col-sm-4 text-right">
                                        <button type="button" title="Delete" class="btn btn-xs btn-link text-red" (click)=" removeCC(a,recommendation,'ACC')"><i class="fa fa-trash fa-lg"></i></button>
                                    </div>
                                  </div> -->
                                </div>
                              </div>
                            </div>

                          </div>

                          <div class="addedPartsItems col-xs-12  col-md-12">
                            <div class="row" *ngIf="viewingJSON[servicerequestChild.recommendations[0]['id']]['NCC'].length > 0">
                              <div class="col-sm-12 padding-0 margin-top-5"><strong>Code(s) newly Add: </strong></div>
                            </div>
                            <div class="partRow row selPart-1" *ngFor="let recommendation of servicerequestChild.recommendations">
                              <div class="display-full row" style="padding-top:3px;" *ngFor="let cc of viewingJSON[recommendation.id]['NCC'];let a =index">
                                  <div class="col-xs-9 col-md-7 col-lg-7 pname break-all padding-right-0">
                                      {{viewingJSON[recommendation.id]['NCC'][a].trouble_code}} {{viewingJSON[recommendation.id]['NCC'][a].fault_location}}
                                  </div>
                                  <div class="col-xs-3 col-md-5 col-lg-5 text-right addedCode-details padding-right-0">
                                    <button type="button" title="Delete" class="btn btn-xs btn-link text-red" (click)=" removeCC(a,recommendation,'NCC')"><i class="fa fa-trash fa-lg"></i></button>
                                    <!-- <div class="row addedCode-details">
                                      <div class="col-sm-5"></div>
                                      <div class="col-sm-3"></div>
                                      <div class="col-sm-4 text-right">
                                          <button type="button" title="Delete" class="btn btn-xs btn-link text-red" (click)=" removeCC(a,recommendation,'NCC')"><i class="fa fa-trash fa-lg"></i></button>
                                      </div>
                                    </div> -->
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row margin-bottom-0">
                    <div class="col-sm-12 padding-lr-0-mob">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Description of Work:</label>
                        <div class="col-sm-9 details-input">
                          <mat-form-field class="car-input btn-block margin-bottom-10" floatPlaceholder="never" appearance="fill">
                            <textarea matInput placeholder="Description of Work" title="Description of Work" rows="5" (blur)="addRec($event,servicerequest,servicerequestChild,null)" value="{{tempReports[servicerequest.id][servicerequestChild.id]['recommendation']}}"></textarea>
                          </mat-form-field>
                          <mat-checkbox (change)="add_rec_check($event,servicerequest,servicerequestChild,undefined)" [checked]="set_checkbox_as_checked(tempReports[servicerequest.id][servicerequestChild.id]['rec_check'])">Print On Invoice</mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row margin-bottom-0">
                    <div class="col-sm-12 padding-lr-0-mob">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Notes:</label>
                        <div class="col-sm-9 details-input">
                          <mat-form-field class="car-input btn-block margin-bottom-10" floatPlaceholder="never" appearance="fill">
                            <textarea matInput placeholder="Notes" title="notes" rows="5" value="{{tempReports[servicerequest.id][servicerequestChild.id]['notes']}}" (blur)="addNotes($event,servicerequest,servicerequestChild,undefined)"></textarea>
                          </mat-form-field>
                          <mat-checkbox (change)="add_notes_check($event,servicerequest,servicerequestChild,undefined)" [checked]="set_checkbox_as_checked(tempReports[servicerequest.id][servicerequestChild.id]['notes_check']) ">Print On Invoice</mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12 padding-lr-0-mob">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Upload Image:</label>
                        <div class="col-sm-9">
                          <div class="upload-image" id="upload-img">
                            <mat-toolbar class="mat-elevation-z2 margin-right-10 upload-btn upload-note relative">
                              <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                              Drag File Here
                              <input type="file" multiple (change)="uploadPicture($event,servicerequest,servicerequestChild,null,servicerequestChild.recommendations[0])" />
                              <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                            </mat-toolbar>
                            <div class="note">
                              <p class="help-block" style="margin:0px;font-size:14px;">Format(s) supported : {{imgTypes}}</p>
                              <p class="help-block" style="margin:0px;font-size:14px;">Maximum size : {{imgMaxAllowedSize}}</p>
                            </div>
                            <ul class="list-inline margin-bottom-0">
                              <li class="relative view-images" *ngFor="let image of tempReports[servicerequest.id][servicerequestChild.id].uploaded_images;let i=index">
                                <a (click)="imageToolDialog($event,i,4,tempReports[servicerequest.id][servicerequestChild.id].uploaded_images)" class="thumbnail" href="javascript:void(0);$event.stopPropagation();">
                                  <img class="show-image" src="{{bucket_url}}recommendation-pictures/thumb/{{tempReports[servicerequest.id][servicerequestChild.id].uploaded_images[i].filename}}" />
                                  <i class="fa fa-trash remove-img" (click)="imgDelete(i,servicerequest.id,servicerequestChild.id,undefined,tempReports[servicerequest.id][servicerequestChild.id].uploaded_images[i].id,servicerequestChild.recommendations[0].request_id);$event.stopPropagation();"></i>
                                </a>
                                <div class="image-loader">
                                  <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 padding-lr-0-mob">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Upload Video:</label>
                        <div class="col-sm-9 file-upload-container">
                          <div class="upload-image" id="upload-img">
                            <mat-toolbar class="mat-elevation-z2 margin-right-10 upload-btn upload-note relative">
                              <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                              Drag File Here
                              <input type="file" (change)="uploadVideo($event,servicerequest,servicerequestChild,null,servicerequestChild.recommendations[0])" />
                              <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                            </mat-toolbar>
                            <div class="note">
                              <p class="help-block" style="margin:0px;font-size:14px;">Format(s) supported : {{videoTypes}}</p>
                              <p class="help-block" style="margin:0px;font-size:14px;">Maximum size : {{videoMaxAllowedSize}}</p>
                            </div>
                            <ul class="list-inline margin-bottom-0">
                              <li class="video-upload " *ngFor="let image of tempReports[servicerequest.id][servicerequestChild.id].uploaded_videos;let i=index">
                                <a class="thumbnail margin-bottom-0 relative" href="javascript:void(0)" (click)="videoShow(image.filename);$event.stopPropagation();">
                                  <span class="relative video-bg">
                                    <mat-icon class="position-center play-video">play_circle_filled</mat-icon>
                                  </span>
                                  <i class="fa fa-trash remove-img" (click)="videoDelete(i,servicerequest.id,servicerequestChild.id,null,image.id);$event.stopPropagation();"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="display-full text-right" *ngIf="servicerequestChild.recommendations[0].id && showCostPrice">
                      Job Total : <span *ngIf="levelPriceObj">$ {{levelPriceObj[servicerequestChild.recommendations[0].id]}}</span>
                  </div>
                  <div class="display-full text-right" *ngIf="servicerequestChild.recommendations[0].approval_status!=0">
                    <mat-button-toggle value="Accepted" class="toogle-btn accReg" [class.btn-success]="servicerequestChild.recommendations[0].approval_status=='1'" [class.btn-danger]="servicerequestChild.recommendations[0].approval_status=='2'" disabled>
                      {{( servicerequestChild.recommendations[0].approval_status=='1' ? 'Accepted' : 'Rejected' )}}
                    </mat-button-toggle>
                  </div>
                </div>
                <!--3rd level child-->
                <div *ngIf="servicerequestChild.recommendations.length == 0">
                  <div *ngFor="let servicerequest3levelChild of servicerequestChild.children;let k=index" [attr.data-level]="servicerequest3levelChild.id" >

                    <b><span><i class='fa fa-arrow-right'></i></span>&nbsp;{{servicerequest3levelChild.name}}</b>
                  <button *ngIf="isPaste(servicerequest3levelChild)" class="btn-copy-paste paste-btn margin-left-5" title="Paste" (click)="pasteJobData(servicerequestChild,servicerequest,servicerequest3levelChild.id)">
                      <i class="fa fa-clipboard"></i> 
                  </button>
                  <button class="btn-copy-paste js-btn" title="Job Suggestions" (click)="showJobMatchDialog(servicerequest,servicerequestChild,servicerequest3levelChild.id)"><i class="fa fa-regular fa-lightbulb"></i></button>
                    <div *ngIf="servicerequest3levelChild.input=='textarea'">
                      <p style="padding-left:58px;" [innerHtml]="getNltoBr(servicerequest3levelChild.value)"></p>
                    </div>
                    <div *ngIf="servicerequest3levelChild.id=='158'" class="row margin-bottom-0">
                        <span style="padding-left: 58px;">
                            <img src="{{globalConstants.S3_BUCKET_URL}}service-pictures/thumb/{{servicerequest3levelChild.value}} "/>
                          </span>
                    </div>
                    <div class="row margin-bottom-0" *ngIf="servicerequest3levelChild.id!='158'">
                      <div class="col-sm-12 padding-lr-0-mob">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Part(s):</label>
                          <div class="col-sm-9">
                            <div class="form-inline quick-service-section relative">
                              <input matInput ngui-auto-complete (valueChanged) = "getSelectedOption($event,servicerequest3levelChild,servicerequest)"  [source]="observableSource"
                              [list-formatter]="autoCompleListParts"
                              value-property-name="label"
                              placeholder="Enter Part Name"
                              (click)="setReturnFalse()"
                              id="new-part-{{servicerequest3levelChild.id}}-ac"

                              class="form-control"/>

                              <mat-form-field class="car-input" [class.hide]="!showCostPrice" floatPlaceholder="never" appearance="fill" style=" max-width: 96px; ">
                                <input type="text" placeholder="Price" aria-label="Price" id="new-part-{{servicerequest3levelChild.id}}-price" class="form-control input-lg custom-form-input btn-block" matInput (keypress)="onlyNumericKey($event)" />
                              </mat-form-field>

                              <mat-form-field class="car-input" floatPlaceholder="never" appearance="fill" style=" max-width: 100px; ">
                                <input type="text" placeholder="Quantity" aria-label="Quantity" id="new-part-{{servicerequest3levelChild.id}}-qty" class="form-control input-lg custom-form-input btn-block" matInput maxlength="5" (keypress)="onlyNumericKey($event)" />
                              </mat-form-field>

                              <button type="button" class="btn btn-default addPart" id="new-part-{{servicerequest3levelChild.id}}-add-btn" (click)="addNewPart($event,servicerequest3levelChild,servicerequest3levelChild.recommendations[0],j)">Add</button>
                              <button type="button" class="btn btn-default updatePart hidden" (click)="UpdateNewPart($event,servicerequest3levelChild,servicerequest3levelChild.recommendations[0],j)" id="new-part-{{servicerequest3levelChild.id}}-update-btn">Update</button>
                              <button type="button" class="btn btn-default updatePart hidden"  (click)="editNewPart($event,servicerequest3levelChild,servicerequest3levelChild.recommendations[0],i)" id="edit-part-{{servicerequest3levelChild.id}}-edit-btn">Edit Part</button>
                              <button type="button" *ngIf="isAllowed('addNew','part')" class="btn btn-success addNewPart add-new-btn" id="new-part-{{servicerequest3levelChild.id}}-add-new-btn"  (click)="addNewPartOpen(servicerequest3levelChild.recommendations[0],3,servicerequest.id,servicerequestChild.id,servicerequest3levelChild.id)"><i class="fa fa-fw fa-plus"></i>Add New</button>
                              
                              <button mat-button class="order-parts-btn" color="primary" *ngIf="showPartsTechButon || showNexpartButton" type="button" [matMenuTriggerFor]="partOrderingVendors" title="Search Parts" >
                                <i class="fa fa-shopping-cart"></i>
                                <span>&nbsp;Search Parts</span>
                              </button>
                              <mat-menu #partOrderingVendors="matMenu" class="part-ordering-sites" [overlapTrigger]="false" xPosition="before">
                                <button mat-menu-item *ngIf="showPartsTechButon" type="button" class="btn btn-gray" id="new-part-{{servicerequest3levelChild.id}}-partech-new-btn"  (click)="partech(servicerequest.id,servicerequestChild.id,servicerequest3levelChild.id,servicerequest3levelChild.recommendations[0],servicerequest,servicerequestChild,servicerequest3levelChild)"  ><img style="width:140px;" src="assets/img/PartsTechLogo.png" /></button>
                                <button mat-menu-item *ngIf="showNexpartButton && vendorsData && vendorsData.length == 1" title="Nexpart Vendors" disableRipple type="button" class="btn btn-gray" id="new-part-{{servicerequest3levelChild.id}}-nexpart-new-btn" (click)="nexpart(servicerequest.id,servicerequestChild.id,servicerequest3levelChild.id,servicerequest3levelChild.recommendations[0])" >
                                  <img style="width:auto; height: 20px;" [src]="nexpartLogoURL" />
                                </button>
                                <button mat-menu-item *ngIf="showNexpartButton && vendorsData && vendorsData.length > 1" [matMenuTriggerFor]="nexpartVendorsL3" title="Nexpart Vendors" disableRipple type="button" class="btn btn-gray" id="new-part-{{servicerequestChild.id}}-nexpart-new-btn">
                                  <img style="width:auto; height: 20px;" [src]="nexpartLogoURL" />
                                </button>
                              </mat-menu>
                              <mat-menu #nexpartVendorsL3="matMenu" class="nexpart-vendors-list {{ (!vendorNameVisibility) ? 'only-img':'' }}" [overlapTrigger]="false" xPosition="before">
                                <mat-list *ngIf="vendorNameVisibility">
                                  <mat-list-item *ngFor="let vendor of vendorsData" (click)="selectVendor(servicerequest.id,servicerequestChild.id,servicerequest3levelChild.id,servicerequest3levelChild.recommendations[0], vendor)">
                                    <img matListAvatar *ngIf="vendor.vendor_logo" src="{{logoBaseUrl}}{{vendor.vendor_logo}}" alt="Vendor Logo">
                                    <img matListAvatar *ngIf="!vendor.vendor_logo" src="{{defaultLogo}}" alt="Vendor Logo">
                                    <p matLine>
                                      <span>{{ vendor.label }}</span>
                                    </p>
                                  </mat-list-item>
                                </mat-list>
                                <mat-list *ngIf="!vendorNameVisibility">
                                  <mat-list-item *ngFor="let vendor of vendorsData" (click)="selectVendor(servicerequest.id,servicerequestChild.id,servicerequest3levelChild.id,servicerequest3levelChild.recommendations[0], vendor)">
                                    <img matListAvatar *ngIf="vendor.vendor_logo" src="{{logoBaseUrl}}{{vendor.vendor_logo}}" alt="Vendor Logo">
                                    <img matListAvatar *ngIf="!vendor.vendor_logo" src="{{defaultLogo}}" alt="Vendor Logo">
                                  </mat-list-item>
                                </mat-list>
                              </mat-menu>
                              <button mat-button class="stock-order-btn" color="primary" *ngIf="showPartsTechButon" id="stockOrder{{servicerequest3levelChild.recommendations[0].service_request_list_id}}"  title="Stock Order" (click)="AddPartsInStockOrder(servicerequest3levelChild.recommendations)">
                                <i class="fa fa-cart-plus"></i><span> Stock Order</span>
                              </button>
                            </div>
                            <div class="addedPartsItems col-xs-12 col-md-12">

                              <div *ngIf="viewingJSON[servicerequest3levelChild.recommendations[0].id]!=undefined && viewingJSON[servicerequest3levelChild.recommendations[0].id]['AP'].length > 0">
                                <div class="row">
                                  <div class="col-sm-12 padding-0 margin-top-5"><strong>Part(s) Already Included: </strong></div>
                                </div>

                                <div class="partRow row selPart-1" *ngFor="let recommendation of servicerequest3levelChild.recommendations">
                                  <!-- recommendation: {{recommendation.id}} -->
                                    <div class="display-full row" [class.part-item-active]="viewingJSON[recommendation.id]['UAP'][a].is_editable" [class.text-orange]="viewingJSON[recommendation.id]['AP'][a].partstech==1  || viewingJSON[recommendation.id]['AP'][a].partstech_order==1" [class.text-nexpart-green]="viewingJSON[recommendation.id]['AP'][a].nexpart==1 || viewingJSON[recommendation.id]['AP'][a].nexpart_order==1" *ngFor="let parts of viewingJSON[recommendation.id]['AP'];let a=index">
                                      <div class="col-md-5 col-lg-7 pname break-all" title="{{getPartToolTipTitle(viewingJSON[recommendation.id]['AP'][a])}}">{{viewingJSON[recommendation.id]['AP'][a].part_name}}</div>
                                      <div class="col-md-7 col-lg-5">
                                        <div class="row">
                                          <div class="col-sm-5 col-md-5 col-xs-5 pprice"><span [tooltip]="gnTipId(parts)"  *ngIf="isAllowed('views','display_part_cost')">$ {{viewingJSON[recommendation.id]['AP'][a].part_price}}</span></div>
                                          <div class="col-sm-3 col-md-3 col-xs-3 pquantity" [class.text-red]="isInventory && isStockEmpty(viewingJSON[recommendation.id]['AP'][a])"><span *ngIf="isInventory"><i title="{{ stockInHand( viewingJSON[recommendation.id]['AP'][a] ) }}" class="fa fa-info-circle" (click)="suggestion(a,servicerequest3levelChild.recommendations[0],parts)"></i>&nbsp;</span>{{viewingJSON[recommendation.id]['AP'][a].part_qty}} </div>
                                          <div class="col-sm-4 col-md-4 col-xs-4 text-right padding-0 actions-srfr">
                                            <!-- *ngIf="showUpdateBtn.id!=servicerequest3levelChild.id && !showUpdateBtn.show" -->
                                            <!-- # button  with icon -->
                                            <button [disabled] = "viewingJSON[recommendation.id]['UAP'][a].is_disabled || viewingJSON[recommendation.id]['UAP'][a].is_editable" (click)="addStockOrder(a,recommendation,'AP',parts)" *ngIf="addTostockOrder && isStockEmpty(viewingJSON[recommendation.id]['AP'][a])" class="btn btn-xs btn-link text-red" title="Add to stock order"><i class="fa fa-cart-plus fa-lg" ></i></button>
                                            <button [disabled] ="viewingJSON[recommendation.id]['UAP'][a].is_disabled" *ngIf="!viewingJSON[recommendation.id]['UAP'][a].is_editable" (click)="editPart(a,servicerequest3levelChild,recommendation,'AP')" type="button" title="Edit" class="btn btn-xs btn-link text-red "><i class="fa fa-pencil fa-lg"></i></button>
                                            <button *ngIf="viewingJSON[recommendation.id]['UAP'][a].is_editable" (click)="CancelEditPart(a,servicerequest3levelChild,recommendation,'AP')" type="button" title="Cancel" class="btn btn-xs btn-link text-red "><i class="fa fa-times fa-lg"></i></button>
                                            <button [disabled] = "viewingJSON[recommendation.id]['UAP'][a].is_disabled || viewingJSON[recommendation.id]['UAP'][a].is_editable" (click)="deleteParts(a,recommendation,'AP',parts,servicerequest,servicerequestChild,servicerequest3levelChild)" type="button" title="Delete" class="btn btn-xs btn-link text-red"><i class="fa fa-trash fa-lg"></i></button>

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                              </div>


                              <div class="row" *ngIf="viewingJSON[servicerequest3levelChild.recommendations[0]['id']]!=undefined && viewingJSON[servicerequest3levelChild.recommendations[0]['id']]['NP'].length > 0">
                                <div class="col-sm-12 padding-0 margin-top-5"><strong>Part(s) Newly Added: </strong></div>
                              </div>
                              <div class="partRow row selPart-1" *ngFor="let recommendation of servicerequest3levelChild.recommendations">
                                <div *ngFor="let part of viewingJSON[recommendation.id]['NP'];let a=index" class="display-full row" [class.part-item-active]="viewingJSON[recommendation.id]['UNP'][a].is_editable" [class.text-orange]="viewingJSON[recommendation.id]['NP'][a].partstech==1  || viewingJSON[recommendation.id]['NP'][a].partstech_order==1" [class.text-nexpart-green]="viewingJSON[recommendation.id]['NP'][a].nexpart==1 && viewingJSON[recommendation.id]['NP'][a].nexpart_order==0" style="padding-top:3px;" >
                                  <div class="col-md-5 col-lg-7 pname break-all" title="{{getPartToolTipTitle(viewingJSON[recommendation.id]['NP'][a])}}">{{viewingJSON[recommendation.id]['NP'][a].part_name}}</div>
                                  <div class="col-md-7 col-lg-5">
                                    <div class="row">
                                      <div class="col-sm-5 col-md-5 col-xs-4 pprice"><span [tooltip]="gnTipId(part)" *ngIf="isAllowed('views','display_part_cost')" >$ {{viewingJSON[recommendation.id]['NP'][a].part_price}}</span></div>
                                      <div class="col-sm-3 col-md-3 col-xs-3 pquantity" [class.text-red]="isInventory && isStockEmpty(viewingJSON[recommendation.id]['NP'][a])"><span *ngIf="isInventory"><i title="{{  stockInHand( viewingJSON[recommendation.id]['NP'][a] ) }}" class="fa fa-info-circle" (click)="suggestion(a,servicerequest3levelChild.recommendations[0],part)"></i>&nbsp;</span>{{viewingJSON[recommendation.id]['NP'][a].part_qty}}</div>
                                      <div class="col-sm-4 col-md-4 col-xs-5 text-right padding-0 actions-srfr">
                                        <!-- # button  with icon -->
                                        <button [disabled] = "viewingJSON[recommendation.id]['UNP'][a].is_disabled || viewingJSON[recommendation.id]['UNP'][a].is_editable" (click)="addStockOrder(a,recommendation,'NP',part)" *ngIf="addTostockOrder && isStockEmpty(viewingJSON[recommendation.id]['NP'][a])" class="btn btn-xs btn-link text-red" title="Add to stock order"><i class="fa fa-cart-plus fa-lg" ></i></button>
                                        <button  [disabled] = "viewingJSON[recommendation.id]['UNP'][a].is_disabled" *ngIf="!viewingJSON[recommendation.id]['UNP'][a].is_editable" (click)="editPart(a,servicerequest3levelChild,recommendation,'NP')" type="button" title="Edit" class="btn btn-xs btn-link text-red "><i class="fa fa-pencil fa-lg"></i></button>
                                        <button *ngIf="viewingJSON[recommendation.id]['UNP'][a].is_editable"  (click)="CancelEditPart(a,servicerequest3levelChild,recommendation,'NP')" type="button" title="Cancel" class="btn btn-xs btn-link text-red "><i class="fa fa-times fa-lg"></i></button>
                                        <button [disabled] = "viewingJSON[recommendation.id]['UNP'][a].is_disabled || viewingJSON[recommendation.id]['UNP'][a].is_editable" (click)="deleteParts(a,recommendation,'NP',part,servicerequest,servicerequestChild,servicerequest3levelChild)" type="button" title="Delete" class="btn btn-xs btn-link text-red"><i class="fa fa-trash fa-lg"></i></button>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row margin-bottom-0" *ngIf="servicerequest3levelChild.id!='158'">
                      <div class="col-sm-12 padding-lr-0-mob">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Generic Job(s):</label>
                          <div class="col-sm-9">
                            <div class="form-inline quick-service-section relative">

                              <input matInput ngui-auto-complete (valueChanged) = "getSelectedOptionForGj($event,servicerequest3levelChild,servicerequest)"  [source]="observableSourceforGJ"
                              [list-formatter]="myListFormatter"
                              value-property-name="label"
                              placeholder="Enter Generic Job"
                              (click)="setReturnFalse()"
                              id="new-gj-{{servicerequest3levelChild.id}}-ac"

                              class="form-control"/>
                              <mat-form-field class="car-input quantity-input" floatPlaceholder="never" appearance="fill">
                                <input type="text" placeholder="Quantity" aria-label="Quantity" id="new-gj-{{servicerequest3levelChild.id}}-qty" class="form-control input-lg custom-form-input btn-block" matInput maxlength="5" (keypress)="onlyNumericKey($event)" />
                              </mat-form-field>
                              <button type="button" class="btn btn-default addGJobs" (click)="addNewGJ($event,servicerequest3levelChild,servicerequest3levelChild.recommendations[0],j)">Add</button>
                              <button type="button" *ngIf="isAllowed('addNew','generic_job')" class="btn addGeneric-job btn-success" (click)="addNewJob(servicerequest3levelChild.recommendations[0],3,servicerequest.id,servicerequestChild.id,servicerequest3levelChild.id)"><i class="fa fa-fw fa-plus"></i>Add New</button>
                            </div>
                            <div class="addedPartsItems addedJobs col-xs-12  col-md-12">

                              <div *ngIf="viewingJSON[ servicerequest3levelChild.recommendations[0].id]!=undefined && viewingJSON[ servicerequest3levelChild.recommendations[0].id]['AGJ'].length > 0">
                                <div class="row">
                                  <div class="col-sm-12 padding-0 margin-top-5"><strong>Job(s) Already Included: </strong></div>
                                </div>
                                <div class="partRow row selPart-1" *ngFor="let recommendation of servicerequest3levelChild.recommendations">
                                  <!-- recommendation:{{recommendation.id}} -->
                                  <div class="display-full row" *ngFor="let job of viewingJSON[recommendation.id]['AGJ'];let a =index">
                                    <div class="col-md-5 col-lg-7 pname break-all">{{viewingJSON[recommendation.id]['AGJ'][a].name}} </div>
                                    <div class="col-md-7 col-lg-5">
                                      <div class="row addedJob-details">
                                        <div class="col-sm-5 col-md-5 col-xs-5 pprice"><span *ngIf="showCostPrice">$ {{viewingJSON[recommendation.id]['AGJ'][a].price}}</span></div>
                                        <div class="col-sm-3 col-md-3 col-xs-3 pquantity">{{viewingJSON[recommendation.id]['AGJ'][a].quantity}}</div>
                                        <div class="col-sm-4 col-md-4 col-xs-4 text-right padding-right-0">
                                          <button type="button" title="Delete" class="btn btn-xs btn-link text-red" (click)="removeGJ(a,recommendation,'AGJ')"><i class="fa fa-trash fa-lg"></i></button>
                                          <!-- <input type="button" class="btn btn-danger btn-sm remove" value="Remove" (click)="removeGJ(a,recommendation,'AGJ')"> -->
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row" *ngIf="viewingJSON[servicerequest3levelChild.recommendations[0]['id']]!=undefined && viewingJSON[servicerequest3levelChild.recommendations[0]['id']]['NGJ'].length > 0">
                                <div class="col-sm-12 padding-0 margin-top-5"><strong>Job(s) Newly Added: </strong></div>
                              </div>
                              <div class="partRow row selPart-1" *ngFor="let recommendation of servicerequest3levelChild.recommendations">
                                <div class="display-full row" style="padding-top:3px;" *ngFor="let job of viewingJSON[recommendation.id]['NGJ'];let a =index">
                                  <div class="col-md-5 col-lg-7 pname break-all">{{viewingJSON[recommendation.id]['NGJ'][a].name}} </div>
                                  <div class="col-md-7 col-lg-5">
                                    <div class="row addedJob-details">
                                      <div class="col-sm-5 col-md-5 col-xs-4 pprice"><span *ngIf="showCostPrice">$ {{viewingJSON[recommendation.id]['NGJ'][a].price}}</span></div>
                                      <div class="col-sm-3 col-md-3 col-xs-3 pquantity">{{viewingJSON[recommendation.id]['NGJ'][a].quantity}}</div>
                                      <div class="col-sm-4 col-md-4 col-xs-5 text-right padding-right-0">
                                          <button type="button" title="Delete" class="btn btn-xs btn-link text-red" (click)="removeGJ(a,recommendation,'NGJ')"><i class="fa fa-trash fa-lg"></i></button>
                                        <!-- <input type="button" class="btn btn-danger btn-sm remove" value="Remove" (click)="removeGJ(a,recommendation,'NGJ')"> -->
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                      <!------------------>
                  <div class="row" *ngIf="currentUser.subscription_data.nexpart == 1">
                    <div class="col-sm-12 padding-lr-0-mob">
                      <div class="form-group">
                        <label *ngIf="serviceInfo.partstech_labor ==1" class="col-sm-3 col-lg-2 hoursLbr text-right control-label"data-bs-placement="right" title="Nexpart: {{parseFloats(tempReports[servicerequest.id][servicerequestChild.id][servicerequest3levelChild.id]['totalNextPartLaborHours'])}} Hrs, PartsTech: {{parseFloats(tempReports[servicerequest.id][servicerequestChild.id][servicerequest3levelChild.id]['totalPartsTechLaborHours'])}} Hrs">Nexpart/PartsTech Labor (Hours):</label>
                        <label  *ngIf="serviceInfo.partstech_labor == 0" class="col-sm-3 col-lg-2 hoursLbr text-right control-label"data-bs-placement="right" title="Nexpart: {{parseFloats(tempReports[servicerequest.id][servicerequestChild.id][servicerequest3levelChild.id]['totalNextPartLaborHours'])}} Hrs">Nexpart (Hours):</label>
                        <div class="col-sm-9">
                          <div class="form-inline"> 
                            <mat-form-field class="car-input text-inline data-input" floatPlaceholder="never" appearance="fill">
                              <input type="text" placeholder="Nexpart/PartsTech Labor (Hours)" class="form-control input-lg custom-form-input btn-block" matInput maxlength="5" (keypress)="onlyNumericKey($event)" value="{{parseFloats(tempReports[servicerequest.id][servicerequestChild.id][servicerequest3levelChild.id].nexpart_labor_time)}}" (change)="addNexpartLabour($any($event.target).value,servicerequest,servicerequestChild,servicerequest3levelChild)" (load)="addNexpartLabour($event,servicerequest,servicerequestChild,servicerequest3levelChild)" (keypress)="onlyNumericKey($event)"/>
                            </mat-form-field>
                            
                            <button type="button"  class="btn btn-xs btn-link text-black ng-tns-c13-22" (click)="copyNexpartLaborNotes(servicerequest,servicerequestChild,servicerequest3levelChild)"  title="Copy Nexpart labor description"  ><i class="fa fa-copy fa-lg"></i></button>
                            <button type="button" *ngIf="serviceInfo.partstech_labor ==1" class="btn btn-xs btn-link text-red ng-tns-c13-22" (click)="copyPartsTechLaborNotes(servicerequest,servicerequestChild,servicerequest3levelChild)"  title="Copy PartsTech labor description"  ><i class="fa fa-copy fa-lg"></i></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                    <div class="row" *ngIf="servicerequest3levelChild.id!='158'">
                      <div class="col-sm-12 padding-lr-0-mob">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Labor/Hours:</label>
                          <div class="col-sm-9">
                            <div class="form-inline">
                              <mat-form-field class="car-input  data-input" floatPlaceholder="never" appearance="fill">
                                <input type="text" placeholder="Estimated Time (hours)" class="form-control input-lg custom-form-input btn-block" matInput maxlength="5" (keypress)="onlyNumericKey($event)" (change)="addTime($event,servicerequest,servicerequestChild,servicerequest3levelChild)" value="{{parseFloats(tempReports[servicerequest.id][servicerequestChild.id][servicerequest3levelChild.id]['estimated_time'])}}" (keypress)="onlyNumericKey($event)"/>
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="servicerequest3levelChild.id!='158'" [class.hide]="!showCostPrice">
                      <div class="col-sm-12 padding-lr-0-mob">
                        <div class="form-group lbr-cost-section">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Labor $:</label>
                          <div class="col-sm-5">
                            <div class="form-inline">
                              <mat-form-field class="car-input  data-input" floatPlaceholder="never" appearance="fill">
                                <input type="text" placeholder="Estimated Labor Charge" class="form-control input-lg custom-form-input btn-block" matInput (keypress)="onlyNumericKey($event)" (change)="addLabor($event,servicerequest,servicerequestChild,servicerequest3levelChild)" value="{{parseFloats(tempReports[servicerequest.id][servicerequestChild.id][servicerequest3levelChild.id]['estimated_labor'])}}" (keypress)="onlyNumericKey($event)"/>
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="form-group uc-ngx-select">
                              <div class="relative">
                                <select (change)="selectLabourType($event,servicerequest,servicerequestChild,servicerequest3levelChild)"
                                    class="form-control input-lg custom-form-input btn-block" [(ngModel)]="tempReports[servicerequest.id][servicerequestChild.id][servicerequest3levelChild.id]['labour_type']" [ngModelOptions]="{standalone: true}">
                                  <option  disabled>Need to Change Labor Rate? Select Below:</option>
                                    <option *ngFor="let rates of labourRatesType" [value]="rates.id">
                                    
                                    {{rates.type}} - ${{rates.labor_rate}}
                                  </option>
                                  <option *ngIf="tempReports[servicerequest.id][servicerequestChild.id][servicerequest3levelChild.id]['select_costom_type']" [value]="0">
                                        Custom - {{tempReports[servicerequest.id][servicerequestChild.id][servicerequest3levelChild.id]['old_labour_rate']}}
                                  </option>
                                </select>
                              </div>
                          </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="row margin-bottom-0" *ngIf="servicerequest3levelChild.id!='158'">
                      <div class="col-sm-12 padding-lr-0-mob">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Computer Code(s):</label>
                          <div class="col-sm-9">
                            <div class="form-inline quick-service-section relative computer-code">
                              <input matInput ngui-auto-complete (valueChanged) = "getSelectedOptionForCC($event,servicerequest3levelChild,servicerequest)"  [source]="observableSourceforCC"
                              [list-formatter]="autoCompleListParts"
                              value-property-name="label"
                              placeholder="Enter Computer Code"
                              (click)="setReturnFalse()"
                              id="new-cc-{{servicerequest3levelChild.id}}-ac"

                              class="form-control"/>
                              <!-- <mat-form-field class="car-input" floatPlaceholder="never">
                                <input type="text" placeholder="Computer Code" [formControl]="ComputerCodesControl" class="form-control input-lg custom-form-input btn-block" matInput [matAutocomplete]="computerCodesAuto" />
                                <mat-autocomplete #computerCodesAuto="matAutocomplete" [displayWith]="displayFnforCC.bind(this)">
                                  <mat-option *ngFor="let option of ComputerCodesFilterOptions | async" [value]="option" (onSelectionChange)="getSelectedOptionForCC($event,option,servicerequest3levelChild,servicerequest)">
                                    {{ option.fault_location }}
                                  </mat-option>
                                </mat-autocomplete>
                              </mat-form-field> -->
                              <button type="button" class="btn btn-default addComputerCode" (click)="addNewComputerCodeFromList(servicerequest3levelChild,servicerequest3levelChild.recommendations[0],j)">Add</button>
                              <button type="button" *ngIf="isAllowed('addNew','computer_code')" class="btn btn-success addNewComputerCode" (click)="addNewComputerCode(servicerequest3levelChild.recommendations[0],3,servicerequest.id,servicerequestChild.id,servicerequest3levelChild.id)"><i class="fa fa-fw fa-plus"></i>Add New</button>
                            </div>

                            <div class="addedPartsItems col-xs-12  col-md-12"  *ngIf="viewingJSON[servicerequest3levelChild.recommendations[0].id]['ACC'].length > 0">
                              <div class="row">
                                <div class="col-sm-12 padding-0 margin-top-5"><strong>Code(s) Already Included: </strong></div>
                              </div>

                              <div class="partRow row selPart-1" *ngFor="let recommendation of servicerequest3levelChild.recommendations">
                                <div class="display-full row" style="padding-top:3px;" *ngFor="let cc of viewingJSON[recommendation.id]['ACC'];let a =index">
                                    <div class="col-xs-9 col-xs-7 col-md-7 col-lg-7 pname break-all padding-right-0">
                                        {{ viewingJSON[recommendation.id]['ACC'][a].trouble_code}} {{ viewingJSON[recommendation.id]['ACC'][a].fault_location}}
                                    </div>
                                    <div class="col-xs-3 col-md-5 col-lg-5 text-right addedCode-details padding-right-0">
                                      <button type="button" title="Delete" class="btn btn-xs btn-link text-red" (click)=" removeCC(a,recommendation,'ACC')"><i class="fa fa-trash fa-lg"></i></button>
                                      <!-- <div class="row addedCode-details">
                                        <div class="col-sm-5"></div>
                                        <div class="col-sm-3"></div>
                                        <div class="col-sm-4 text-right">
                                          <button type="button" title="Delete" class="btn btn-xs btn-link text-red" (click)=" removeCC(a,recommendation,'ACC')"><i class="fa fa-trash fa-lg"></i></button>
                                        </div>
                                      </div> -->
                                    </div>
                                </div>
                              </div>
                            </div>

                            <div class="addedPartsItems col-xs-12  col-md-12" *ngIf="viewingJSON[servicerequest3levelChild.recommendations[0]['id']]['NCC'].length > 0">
                              <div class="row">
                                <div class="col-sm-12 padding-0 margin-top-5"><strong>Code(s) newly Add: </strong></div>
                              </div>
                              <div class="partRow row selPart-1" *ngFor="let recommendation of servicerequest3levelChild.recommendations">
                                <div class="display-full row" style="padding-top:3px;" *ngFor="let cc of viewingJSON[recommendation.id]['NCC'];let a =index">
                                    <div class="col-xs-9 col-xs-7 col-md-7 col-lg-7 pname break-all padding-right-0">
                                        {{viewingJSON[recommendation.id]['NCC'][a].trouble_code}} {{viewingJSON[recommendation.id]['NCC'][a].fault_location}}
                                    </div>
                                    <div class="col-xs-3 col-md-5 col-lg-5 text-right addedCode-details padding-right-0">
                                      <button type="button" title="Delete" class="btn btn-xs btn-link text-red" (click)=" removeCC(a,recommendation,'NCC')"><i class="fa fa-trash fa-lg"></i></button>
                                      <!-- <div class="row addedCode-details">
                                        <div class="col-sm-5"></div>
                                        <div class="col-sm-3"></div>
                                        <div class="col-sm-4 text-right">
                                            <button type="button" title="Delete" class="btn btn-xs btn-link text-red" (click)=" removeCC(a,recommendation,'NCC')"><i class="fa fa-trash fa-lg"></i></button>
                                        </div>
                                      </div> -->
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row margin-bottom-0" *ngIf="servicerequest3levelChild.id!='158'">
                      <div class="col-sm-12 padding-lr-0-mob">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Description of Work:</label>
                          <div class="col-sm-9 details-input">
                            <mat-form-field class="car-input btn-block margin-bottom-10 margin-top-5" floatPlaceholder="never" appearance="fill">
                              <textarea matInput placeholder="Description of Work" title="Description of Work" rows="5" value="{{tempReports[servicerequest.id][servicerequestChild.id][servicerequest3levelChild.id]['recommendation']}}" (blur)="addRec($event,servicerequest,servicerequestChild,servicerequest3levelChild)"></textarea>
                            </mat-form-field>
                            <mat-checkbox (change)="add_rec_check($event,servicerequest,servicerequestChild,servicerequest3levelChild)" [checked]="set_checkbox_as_checked(tempReports[servicerequest.id][servicerequestChild.id][servicerequest3levelChild.id]['rec_check'])">Print On Invoice</mat-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row margin-bottom-0" *ngIf="servicerequest3levelChild.id!='158'">
                      <div class="col-sm-12 padding-lr-0-mob">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Notes:</label>
                          <div class="col-sm-9 details-input">
                            <mat-form-field class="car-input btn-block margin-bottom-10" floatPlaceholder="never" appearance="fill">
                              <textarea matInput placeholder="Notes" title="notes" rows="5" value="{{tempReports[servicerequest.id][servicerequestChild.id][servicerequest3levelChild.id]['notes']}}" (blur)="addNotes($event,servicerequest,servicerequestChild,servicerequest3levelChild)"></textarea>
                            </mat-form-field>
                            <mat-checkbox (change)="add_notes_check($event,servicerequest,servicerequestChild,servicerequest3levelChild)" [checked]="set_checkbox_as_checked(tempReports[servicerequest.id][servicerequestChild.id][servicerequest3levelChild.id]['notes_check'])">Print On Invoice</mat-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row" *ngIf="servicerequest3levelChild.id!='158'">
                      <div class="col-sm-12 padding-lr-0-mob">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Upload Image:</label>
                          <div class="col-sm-9">
                            <div class="upload-image" id="upload-img">
                              <mat-toolbar class="mat-elevation-z2 margin-right-10 upload-btn upload-note relative">
                                <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                                Drag File Here
                                <input type="file" multiple (change)="uploadPicture($event,servicerequest,servicerequestChild,servicerequest3levelChild,servicerequest3levelChild.recommendations[0])" />
                                <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                              </mat-toolbar>
                              <div class="note">
                                <p class="help-block" style="margin:0px;font-size:14px;">Format(s) supported : {{imgTypes}}</p>
                                <p class="help-block" style="margin:0px;font-size:14px;">Maximum size : {{imgMaxAllowedSize}}</p>
                              </div>
                              <ul class="list-inline margin-bottom-0">
                                <li class="relative view-images" *ngFor="let image of tempReports[servicerequest.id][servicerequestChild.id][servicerequest3levelChild.id].uploaded_images;let i=index">
                                  <a (click)="imageToolDialog($event,i,4,tempReports[servicerequest.id][servicerequestChild.id][servicerequest3levelChild.id].uploaded_images)" class="thumbnail" href="javascript:void(0);$event.stopPropagation();">
                                    <img class="show-image"  src="{{bucket_url}}recommendation-pictures/thumb/{{tempReports[servicerequest.id][servicerequestChild.id][servicerequest3levelChild.id].uploaded_images[i].filename}}" />
                                    <i class="fa fa-trash remove-img"  (click)="imgDelete(i,servicerequest.id,servicerequestChild.id,servicerequest3levelChild.id,tempReports[servicerequest.id][servicerequestChild.id][servicerequest3levelChild.id].uploaded_images[i].id,servicerequest3levelChild.recommendations[0].request_id);$event.stopPropagation();"></i>
                                  </a>
                                  <div class="image-loader">
                                    <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="servicerequest3levelChild.id!='158'">
                      <div class="col-sm-12 padding-lr-0-mob">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Upload Video:</label>
                          <div class="col-sm-9 file-upload-container">
                            <div class="upload-image margin-bottom-5" id="upload-img">
                              <mat-toolbar class="mat-elevation-z2 margin-right-10 upload-btn upload-note relative">
                                <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                                Drag File Here
                                <input type="file" (change)="uploadVideo($event,servicerequest,servicerequestChild,servicerequest3levelChild,servicerequest3levelChild.recommendations[0])"/>
                                <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                              </mat-toolbar>
                              <div class="note">
                                <p class="help-block" style="margin:0px;font-size:14px;">Format(s) supported : {{videoTypes}}</p>
                                <p class="help-block" style="margin:0px;font-size:14px;">Maximum size : {{videoMaxAllowedSize}}</p>
                              </div>

                      <ul id="vehicle_video-ul" class="list-inline margin-bottom-0">
                        <li class="video-upload" *ngFor="let image of tempReports[servicerequest.id][servicerequestChild.id][servicerequest3levelChild.id].uploaded_videos;let i=index">
                          <a class="thumbnail margin-bottom-0 relative" href="javascript:void(0)" (click)="videoShow(image.filename);$event.stopPropagation();">
                            <span class="relative video-bg">
                              <mat-icon class="position-center play-video">play_circle_filled</mat-icon>

                          </span>
                          <i class="fa fa-trash remove-img" (click)="videoDelete(i,servicerequest.id,servicerequestChild.id,servicerequest3levelChild.id,image.id);$event.stopPropagation();"></i>
                        </a>

                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="display-full text-right" *ngIf="servicerequest3levelChild.recommendations[0].id && showCostPrice">
              Job Total : <span *ngIf="levelPriceObj">$ {{levelPriceObj[servicerequest3levelChild.recommendations[0].id]}}</span>
          </div>
          <div class="display-full text-right" *ngIf="servicerequest3levelChild.recommendations[0].approval_status!=0">
            <mat-button-toggle value="Accepted" class="toogle-btn accReg" [class.btn-success]="servicerequest3levelChild.recommendations[0].approval_status=='1'" [class.btn-danger]="servicerequest3levelChild.recommendations[0].approval_status=='2'" disabled>
              {{( servicerequest3levelChild.recommendations[0].approval_status=='1' ? 'Accepted' : 'Rejected' )}}
            </mat-button-toggle>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <div class="panel-price">
    <div class="panel-footer" *ngIf="showCostPrice">
      <h4 class="text-right" style="margin:0px;">
        <span>Total Price : </span><span class="total-job-price-1">$ {{calcObj[servicerequest.id]}}</span>
      </h4>
    </div>
  </div>
              </mat-expansion-panel>
              <div class="subjob-category-wrapper-mobile">
                <span class="tooltip-status" [tooltip]="myJobTooltip" class="visible-xs fa fa-info-circle"></span>
                <tooltip-content #myJobTooltip class="subjob-tooltip-container" placement="top">
                  <div class="row subjob-category-wrapper-mobile">
                    <div class="col-sm-12" *ngFor="let jobCategories of servicerequest.subJobCategories; let sn = index">
                      <p class="subjobs"><span>{{ sn+1 }}.</span><span> {{jobCategories}}</span></p>
                    </div>
                  </div>
                </tooltip-content>
              </div>
          </div>  
          <!-- job-category-container ends -->
        </mat-accordion>
        </div>
      </div>
<h4 class="text-right" *ngIf="showCostPrice"><strong>Sub Total : </strong>$ <span class="grand-total">{{subtotal.toFixed(2) }}</span></h4>

<span class="checkbox text-right" [class.hide]="!(isAllowed('views','add_supplies_invoice') && showCostPrice)" style="font-size:18px;font-weight:bold;" >
  <mat-checkbox (change)="AddSCToGrandTotal($event,1)" [checked] = "serviceRequest.value.is_supplies_charges_included">
    Supply Charges<span *ngIf="serviceInfo.supply_charges_disp_type == 2">({{serviceInfo.supply_charge_percentage}}%)</span> : $ {{parseFloats(supplyCharges)}}
  </mat-checkbox>
</span>

<!-- Coupon Redeem UI -->
<app-redeem-coupon #fillRecommendationCoupon *ngIf="serviceInfo && showCostPrice" [context]="this" [is-fill-recommendation]="true" (couponAdded)="onCouponAdded($event)"></app-redeem-coupon>

<span class="checkbox text-right" [class.hide]="!(isAllowed('views','add_tax_invoice') && showCostPrice)" style="font-size:18px;font-weight:bold;" id="taxRow" >
  <mat-checkbox (change)="AddTaxToGrandTotal($event,1)" [checked] = "serviceRequest.value.is_tax_included">Tax ({{serviceRequest.value.tax_rate}}% : $ {{totalTaxedValue}})</mat-checkbox>
</span>

<h4 class="text-right" *ngIf="showCostPrice"><strong>Grand Total : </strong>$ <span class="grand-total-with-tax">{{grandTotal }}</span></h4>

<ng-container *ngIf="showCostPrice && serviceInfo.total_paid != null && serviceInfo.total_paid > 0">
  <hr/>
  <h4 class="text-right" *ngIf="showCostPrice"><strong>Total SR Paid : </strong>$ <span id="totalPaid">{{parseFloats(serviceInfo.total_paid)}}</span></h4>
  <h4 class="text-right" *ngIf="showCostPrice"><strong>Total CC Fee Paid : </strong>$ <span id="totalPaid">{{parseFloats(serviceInfo.total_cc_fee)}}</span></h4>
  <h4 class="text-right" *ngIf="showCostPrice"><strong>Total Net Paid : </strong>$ <span id="totalPaid">{{parseFloats(totalNetPayed(serviceInfo.total_paid, serviceInfo.total_cc_fee))}}</span></h4>
  <h4 class="text-right" *ngIf="showCostPrice"><strong>Balance : </strong>$ <span id="balance">{{ parseFloats(balance) }}</span></h4>
</ng-container>
<!-- <ng-container  *ngIf="netPayable > 0 && balance > 0"> -->
<ng-container *ngIf="showCostPrice">
  <hr/>
  <span class="checkbox text-right" style="font-size:18px;font-weight:bold;" id="taxRow" >
    <mat-checkbox (change)="calculateCCFee($event)" [checked] = "serviceRequest.value.cc_fee_included == '1'">CC Fee <span *ngIf="serviceInfo.cc_fee_type == '2'">({{serviceInfo.cc_fee}}%)</span> : $ {{parseFloats(totalCCfeeValue)}}</mat-checkbox>
  </span>
  <h4 class="text-right"><strong>Total Payable: </strong>$ <span class="grand-total-with-tax">{{ parseFloats(netPayable) }}</span></h4>
</ng-container>
  
<span *ngIf="showPartsTechButon" class="checkbox col-sm-12" style="font-size:18px;font-weight:bold;" >
  <mat-checkbox class="checkbox-label-wrap" (change)="AddAutoOrder($event)" [checked] = "serviceRequest.value.is_auto_order==1">Auto Order on{{ currentUser.profile_type == '2' ? '' : ' Customer' }} Approval</mat-checkbox>
</span>
<div class="row text-right bgcolor-lightgrey">
  <div class=" col-xs-12 form-block submit-block">
    <!-- <button *ngIf="serviceRequest.value.notify_to_customer == '0'" type="button" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Save Recommendation"  style="max-width: 260px;" (click)="saveRecommendations(1, false)">Save Recommendation</button> -->
    <button type="button" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Save & Stay"  style="max-width: 150px;" (click)="saveAndStay(0, false)">Save</button>
    <button type="button" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Save & Close"  style="max-width: 150px;" (click)="saveAndClose(1, false)">Close</button>
    <button type="button" id="sendNotificationToCustomer" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Send Updated Information{{ currentUser.profile_type == '2' ? '' : ' to Customer' }}" style="max-width: 150px;" (click)="saveAndSend(2, false)">Send</button>
    <button type="button" color="accent" title="Service Completion" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" *ngIf="(serviceInfo.request_status==2 || serviceInfo.request_status==3 || serviceInfo.request_status==7 || serviceInfo.request_status==12 ) && isAllowed('views','mark_service_complete')" style="max-width: 150px;" (click)="saveAndComplete(3, false)" >Complete</button>
  </div>
</div>
<div class="alert alert-info relative margin-top-15" *ngIf="isAllowed('views','disclaimer')" style="padding:17px 15px;border-radius:5px;" id="disclaimer">
  {{serviceInfo.disclaimer}}
  <div class="btnChangeDisclaimerModalContainer" *ngIf="isAllowed('edits','disclaimer')">
      <button class="btn btn-danger btn-sm" id="btnChangeDisclaimerModal" style="position:absolute;right:0px;top:0px;padding:2px;"  (click)="disclaimerOpen()">
          <i class="fa fa-edit fa-fw fa-lg"></i>
      </button>
  </div>
</div>
</div>
</div>
<!--
Ticket Assignment SidePanel integration
-->
<app-tech-assignment [data]="techData" (closeTab)="techAssignmentOut($event)" *ngIf="(techData | json) != '{}'"></app-tech-assignment>
