import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GlobalService, ModalInfo } from '../../../environments/global.service';
import { PaymentDialogComponent } from '../shopservicerequest/payment-dialog/payment-dialog.component';
import { TechassignmentComponent } from '../shopservicerequest/techassignment/techassignment.component';
import { MatDialog } from '@angular/material/dialog';
import { AddDiscountComponent } from './../discounts/add-discount/add-discount.component';
import { globalConstants } from '../../../environments/globalConstants';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {​ ServiceHistoryComponent }​ from '../../shop/shopservicerequest/service-history/service-history.component';
import { SubletHistoryComponent } from '../shopservicerequest/sublet-history/sublet-history.component';
import { Router } from '@angular/router';
declare var jQuery:any;
declare var $ :any;
@Component({
  selector: 'app-quickaction',
  templateUrl: './quickaction.component.html',
  styleUrls: ['./quickaction.component.scss']
})
export class QuickactionComponent implements OnInit {

  @Input()  serviceInfo: any; //for fill recommendation
  @Input()  parent: any;
  @Input()  viewServiceInfo:any; //for view service request
  @Input()  isAddInPayment: boolean;
  @Output() couponAdded:EventEmitter<string> = new EventEmitter<string>();
  @Output() onTicketAssignment:EventEmitter<any> = new EventEmitter<any>();
  @Output() paymentAdded:EventEmitter<string> = new EventEmitter<string>();
  public title='Quick Action'
  public isServiceNote=false
  public isEmployee=false;
  public user:any=null
  public skipTicketAssign=false;
  showOptions:boolean = true;;
  bsModalRef: BsModalRef;
  gc:any = globalConstants;
  constructor(public gs:GlobalService,public router: Router, public dialog: MatDialog, private modalService: BsModalService) { }
 /**
   * @author Vikas Kumar
   * @trackID <:CAR-1528>
   * @created 20-04-2020
   * @see Function added get ticket setting
   */
  ngOnInit() {
    this.isServiceNote= this.gs.isSubscribe('service_notes');
    this.isEmployee=this.gs.isSubscribe('employee')
    this.user = this.gs.getCurrentUser();
    this.getTicketAssignSetting();
    this.toggleOptionsOnHover();
  }
  saveRecommendations(type){
    this.parent.saveRecommendations(type, false, false)
  }
  srnoteList(){
    this.gs.setServiceNoteOpen()
  }
  isAllowed(module,action?:any){
    if(typeof module!='undefined'){
        return this.gs.getAcl(module,action);
    }
    return false;
  }
  /**
   * @author Vikas Kumar
   * @trackID <:CAR-1528>
   * @created 20-04-2020
   * @see Function payment
   */
  payment() {
    let id = (this.viewServiceInfo ? this.viewServiceInfo.id : this.serviceInfo.id)
    let accessLimit = this.gs.getAcl('payment');
    let dialogRef = this.dialog.open(PaymentDialogComponent, {
      panelClass: ['payment-dialog'],
      data: { request_id: id, accessLimit: accessLimit, isAddInPayment: this.isAddInPayment }
    });
    dialogRef.afterClosed().subscribe(result => {
      // emit output event from here to notify parent about coupon added and ready to apply status. 
      if(result['paymentAdded']){
        this.paymentAdded.emit('true');
      }
      // if(result && result.addedCoupon.length > 0){
      //   this.couponAdded.emit(result.addedCoupon[0]);
      // }
    });
  }
 /**
   * @author Vikas Kumar
   * @trackID <:CAR-1528>
   * @created 20-04-2020
   * @see Function ticket assignment
   */
  ticketAssignment() {
    if (this.isEmployee == false) {
      return false;
    }
    let id = (this.viewServiceInfo ? this.viewServiceInfo.id : this.serviceInfo.id)
    /*let dialogRef = this.dialog.open(TechassignmentComponent, {
      panelClass: ['car-dialog-form', 'ticket-dialog'],
      data: { request_id: id,data:this.viewServiceInfo ? this.viewServiceInfo : this.serviceInfo }
    });*/
    this.onTicketAssignment.emit({ request_id: id,data:this.viewServiceInfo ? this.viewServiceInfo : this.serviceInfo });
  }
/**
   * @author Vikas Kumar
   * @trackID <:CAR-1528>
   * @created 20-04-2020
   * @see Function get assignment setting
   */
  getTicketAssignSetting() {
    let data = "user_id=" + this.user.id + "&user_type=" + this.user.user_type;
    this.gs.callAPI(globalConstants.API_SKIP_FOREVER, data).subscribe((skipTicket:any) => {
      if (skipTicket.result == "1") {
        if (typeof skipTicket.data.skip_forever != 'undefined' && skipTicket.data.skip_forever == '1') {
          this.skipTicketAssign = false;
        } else {
          this.skipTicketAssign = true;
        }
      } else {
        this.skipTicketAssign = true;
      }
    }, (error) => { })
  }
/**
 * @author Vikas Kumar
 * @trackID <:CAR-1528>
 * @created 20-04-2020
 * @see Function check permission
 */
  isVisible() {
    let row = (this.viewServiceInfo ? this.viewServiceInfo : this.serviceInfo)
    if (row.request_status == 6 || row.request_status == 8) {
      return false;
    }
    return (this.user.role_id != 4 ? true : false);
  }
/**
 * @author Vikas Kumar
 * @trackID <:CAR-1528>
 * @created 20-04-2020
 * @see Function check permission
 */
  isTicketAssign() {
    let row = (this.viewServiceInfo ? this.viewServiceInfo : this.serviceInfo)
    return (this.isVisible() && this.isEmployee && row.request_status != 10)
  }
/**
 * @author Vikas Kumar
 * @trackID <:CAR-1528>
 * @created 20-04-2020
 * @see Function check permission
 */
  isPayment() {
    let row = (this.viewServiceInfo ? this.viewServiceInfo : this.serviceInfo)
    return (this.gs.getAcl('payment', 'views') && (row.request_status != 10))
  }

  /**
   * @author Shashank Thakur
   * @trackID <:CAR-1571>
   * @created 29-07-2020
   * @see Function to open popup for add coupon
  */
  addCoupon() {
    let tmp = this.viewServiceInfo;
    if(!tmp){
      tmp = this.serviceInfo;
    }
    // this.toggleOptions(); //to close actions menu on action click
    let dialogRef = this.dialog.open(AddDiscountComponent, {
      panelClass: 'car-dialog-form',
      data: { isPopup: true, customerId:tmp.customer_id, showApplyOnClose:(tmp.request_status != 8) }
    });
    dialogRef.afterClosed().subscribe(result => {
      // emit output event from here to notify parent about coupon added and ready to apply status. 
      if(result && result.addedCoupon.length > 0){
        this.couponAdded.emit(result.addedCoupon[0]);
      }
    });
  }
  
  /**
   * @author Shashank Thakur
   * @trackID <:CAR-1571>
   * @created 29-07-2020
   * @see Function to check whether to show add coupon or not
  */
  isAddCoupon() {
    return this.gs.getAcl('discounts','addNew');
  }
  
  /**
   * @author Shashank Thakur
   * @trackID <:CAR-1571>
   * @created 29-07-2020
   * @see Function to toggle quick actions menu
  */
  toggleOptionsOnHover() {
    const screenWidth = window.innerWidth; 
    if (screenWidth > 991) {
      this.showOptions = true;
    } else {
      this.showOptions = false;
    }
  }
  toggleOptions() {
    this.showOptions = !this.showOptions;
  }
  /**
   * @author Shashank Thakur
   * @trackID <:CAR-#23>
   * @created 16-10-2020
   * @see Function to service history popup
  */
  openServiceHistory() {
    // Material Popup Dialog
    
    // let dialogRef = this.dialog.open(ServiceHistoryComponent, {​
    //   panelClass: 'car-dialog-form',
    //   width:'95%',
    //   data: {​ isPopup: true, car_id:this.serviceDetail.car_id, service_data: this.serviceDetail }​
    // }​);
    // dialogRef.afterClosed().subscribe(result => {​
    //   console.log('history popup closed = ', result);
    // }​);
    let serviceDetail = this.viewServiceInfo;
    if(!serviceDetail){
      serviceDetail = this.serviceInfo;
    }
    let isModalPresent:ModalInfo = this.gs.getBootstrapModalInfo('service-history-'+serviceDetail.car_id);
    let initialState:any;
    if(!isModalPresent){
      initialState = {​ isPopup: true, carId:serviceDetail.car_id, serviceInfo: serviceDetail, id: 'service-history-'+serviceDetail.car_id };
      this.bsModalRef = this.modalService.show(ServiceHistoryComponent, {initialState});
      this.gs.setBootstrapModalRef(initialState.id, this.bsModalRef);
      this.gs.setBootstrapModalInfo(initialState.id, {component: ServiceHistoryComponent, data: initialState, name: 'Service History View', id: initialState.id, module:'Service Request'});
    }
    else{
      initialState = isModalPresent.data;
      this.bsModalRef = this.modalService.show(isModalPresent.component, {initialState});
    }
    for(let x in initialState){
      this.bsModalRef.content[x] = initialState[x];
    }
  }

  viewLogs(){
    let id = (this.viewServiceInfo ? this.viewServiceInfo.id : this.serviceInfo.id)
    this.router.navigate(['shops/service-request/logs',id]);
  }

  openSubletHistory(){
    let serviceDetail = this.viewServiceInfo;
    if(!serviceDetail){
      serviceDetail = this.serviceInfo;
    }
    let isModalPresent:ModalInfo = this.gs.getBootstrapModalInfo('service-history-'+serviceDetail.car_id);
    let initialState:any;
    if(!isModalPresent){
      initialState = {​ carId:serviceDetail.car_id, serviceInfo: serviceDetail, id: 'service-history-'+serviceDetail.car_id };
      this.bsModalRef = this.modalService.show(SubletHistoryComponent, {initialState});
      this.gs.setBootstrapModalRef(initialState.id, this.bsModalRef);
      this.gs.setBootstrapModalInfo(initialState.id, {component: SubletHistoryComponent, data: initialState, name: 'Service History View', id: initialState.id, module:'Service Request'});
    }
    else{
      initialState = isModalPresent.data;
      this.bsModalRef = this.modalService.show(isModalPresent.component, {initialState});
    }
    for(let x in initialState){
      this.bsModalRef.content[x] = initialState[x];
    }
  }

}
