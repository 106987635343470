<ng-container *ngIf="!isCoupon || couponOpr == 1">
  <div mat-dialog-title class="relative">
      Click as per your choice.
    <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
      <mat-icon class="close-icon-lg">close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="row">
      <div class="col-sm-6 form-group">
        <button mat-raised-button (click)="saveAndStay()" class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white"
          title="Save & Stay (Saves the changes and keeps you on the same page.)" color="accent">
          Save & Stay
        </button>
      </div>
      <div class="col-sm-6 form-group">
        <button mat-raised-button (click)="saveAndClose()" class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white"
          title="Save & Close (Saves the changes and redirects you to the list of service requests page.)" color="accent">
          Save & Close
        </button>
      </div>
    </div>
  </mat-dialog-content>
</ng-container>

<ng-container *ngIf="isCoupon && couponOpr == 2">
  <div mat-dialog-title class="relative">
      Click as per your choice.
    <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
      <mat-icon class="close-icon-lg">close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="center-block" style="max-width: 600px;">
      <div class="display-full margin-bottom-5">
        <div>
          <div class="col-sm-12 text-center">
            <!-- <b>Are You Sure: </b>  -->
            Are you sure you want remove this coupon? <br><span class="milesout-popup"><i>This action will save all the changes made to the request before making changes in coupons!</i></span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 form-group">
        <button mat-raised-button (click)="saveAndStay()" class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white"
          title="Save & Stay (Saves the changes and keeps you on the same page.)" color="accent">
          Save & Stay
        </button>
      </div>
      <div class="col-sm-6 form-group">
        <button mat-raised-button (click)="saveAndClose()" class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white"
          title="Save & Close (Saves the changes and redirects you to the list of service requests page.)" color="accent">
          Save & Close
        </button>
      </div>
    </div>
  </mat-dialog-content>
</ng-container>
