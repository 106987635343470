import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';

@Component({
  selector: 'app-stockorderpopup',
  templateUrl: './stockorderpopup.component.html',
  styleUrls: ['./stockorderpopup.component.scss']
})
export class StockorderpopupComponent implements OnInit {

  public stockFrm: FormGroup;
  public newQueForm: FormGroup;
  public part: any = {};
  user: any = null;
  public requestId = '';
  public newAllowed = false;
  public request_id = '';
  public showDropDown = false;
  public optionOfExisting = true;
  public allQues = [];
  public recom: any = {};
  public enableNewQueue = false;
  public queDefaultVal:any ='';
  public lastMonthUsage='0';
  public isRecom=false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, public dialogRef: MatDialogRef<StockorderpopupComponent>, private gs: GlobalService) {
    this.user = this.gs.getCurrentUser();
    this.part = data.part;
    this.requestId = data.requestId;
    this.newAllowed = data.newAllowed;
    this.request_id = data.request_id;

    if(data.recom){
      this.isRecom=true;
      this.recom = data.recom;
    }
    
  }

  ngOnInit() {
    if (!this.user) {
      this.dialogRef.close(2);
    }
    this.gs.diaLogObj(this.dialogRef);
    this.stockFrm = this.formBuilder.group({
      part_id: [this.part.id],
      quantity: [this.part.part_qty, Validators.required],
      request_id: [''],
      request_num: [this.request_id],
      service_list_id: [''],
      que_id: [''],
      from_recommendation:[''],
      user_type: [this.user.user_type],
      user_id: [this.user.id]
    });
    this.newQueForm = this.formBuilder.group({
      que_name: ['', Validators.required],
      user_type: [this.user.user_type],
      user_id: [this.user.id]
    });
    this.getAllQues();
    setTimeout(()=>{
      let isEnable=this.gs.getAcl('stock_order','addNew');
      if(!(isEnable)){
          this.dialogRef.close(2);
      }
    },globalConstants.RELOAD_WAIT);
    this.getLastMonthUsage();
    if(this.isRecom){
      this.isExisting(0);
    }
  }
  /** get all Queue */
  getAllQues() {
    let data = { user_id: this.user.id,from_recommendation:(this.isRecom ? 1 : 0) };
    this.gs.callAPI(globalConstants.API_STOCK_ORDER_QUEUE_LIST, data).subscribe((res:any) => {
      if (res.result == 1) {
        this.allQues = res.data;
      }
    }, (error) => { });
  }
  /** check user selection existing queue or service request queue */
  isExisting(val) {
    if (val) {
      this.showDropDown = true;
      // this.stockFrm.controls.request_id.setValue('');
      // this.stockFrm.controls.service_list_id.setValue('');
    } else {
      this.showDropDown = false;
      this.stockFrm.controls.que_id.setValue('');
    }
    this.stockFrm.controls.request_id.setValue(this.requestId);
    this.stockFrm.controls.service_list_id.setValue(this.recom.service_request_list_id);
  }
  /** submit to add part in queue list */
  SubmitQue() {
    if (this.stockFrm.valid) {
      if(this.isRecom){
        this.stockFrm.controls.from_recommendation.setValue('1');
      }else{
        this.stockFrm.controls.from_recommendation.setValue('');
      }
      let data = this.stockFrm.value;
      this.gs.callAPI(globalConstants.API_ADD_PART_IN_STOCK_ORDER_QUEUE, data).subscribe((res:any) => {
        if (res.result == 1) {
          this.gs.snackbar("success", res.message);
          this.dialogRef.close(1);
        } else {
          this.gs.snackbar("error", res.message);
        }
      }, (error) => { });
    }
  }
  /** open add new Queue form */
  newQueue() {
    if(this.enableNewQueue){
      this.enableNewQueue = false;
    }else{
      this.enableNewQueue = true;
    }
  }
  /** submit new queue name to add in list */
  submitNewQue() {
    let url = globalConstants.API_ADD_STOCK_ORDER_QUEUE;
    let data = this.newQueForm.value;
    if (this.newQueForm.valid) {
      this.gs.callAPI(url, data).subscribe((res:any) => {
        if (res.result == 1) {
          this.enableNewQueue = false;
          this.getAllQues();
          this.stockFrm.controls.que_id.setValue(res.data.id);
          this.queDefaultVal=res.data.id;
          this.newQueForm.controls.que_name.setValue('');
          this.gs.snackbar("success", res.message);
        } else {
          this.gs.snackbar("error", res.message);
        }
      }, (error) => { });
    }
  }
  /** part last month usages */
  getLastMonthUsage(){
    let data= { user_id: this.user.id , part_id:this.part.id };
    this.gs.callAPI(globalConstants.API_PART_LAST_MONTH_USAGE, data).subscribe((res:any) => {
      if (res.result == 1) {
        this.lastMonthUsage=(res.data ? res.data.quantity : '');
      }
    }, (error) => { });
  }

}
