import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../environments/global.service';
import { Router } from '@angular/router';
  /**
   * @trackID <:CAR-1369> open same route with diffrent param
   * @since 2020-02-13
   * @createdBY Vikas Kumar
   * @param 
   * @return
   */
@Component({
  selector: 'app-vkroute',
  templateUrl: './vkroute.component.html',
  styleUrls: ['./vkroute.component.scss']
})
export class VkrouteComponent implements OnInit {

  public redirect:any={}
  constructor(public gs:GlobalService,public router: Router) { 
    let t=this.gs.localGetter('route','notification-');
    if(t){
      this.redirect=JSON.parse(t);
    }
  }

  ngOnInit() {
    if(this.redirect){
      if('request_id' in this.redirect &&  this.redirect.redirect_url=='shops/attention-service-requests'){
        this.router.navigate([this.redirect.redirect_url],{ queryParams: { request_id: this.redirect.request_id },replaceUrl: true }); 
      }else{
        this.router.navigate([this.redirect.redirect_url], { replaceUrl: true });
      }  
    }else{
      this.router.navigate(['shops/dashboard']);
    }
  }

}
