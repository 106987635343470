import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ServiceHistoryComponent } from '../shopservicerequest/service-history/service-history.component';
import { GlobalService } from 'src/environments/global.service';
import { DetailTabComponent } from '../shopservicerequest/detail-tab/detail-tab.component';
import { globalConstants } from 'src/environments/globalConstants';
import { globalFunctions } from 'src/environments/globalFunctions';
import {MatSort} from '@angular/material/sort';
import { AddinternalnoteComponent } from '../internalnote/addinternalnote/addinternalnote.component';
const LS_SEARCH_PREFIX = 'all-vehicle-list-';
@Component({
  selector: 'app-shop-vehicles-list',
  templateUrl: './shop-vehicles-list.component.html',
  styleUrls: ['./shop-vehicles-list.component.scss']
})
export class ShopVehiclesListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  public searchPanelOpenStatus: boolean = false;
  public dataSource: MatTableDataSource<shopCarsData> = new MatTableDataSource();
  public options = {
    user_type: "User",
    user_id: "",
    search_keyword: "",
    search_by: '',
    days_till_next_service: "",
    vehiclesDateRange: '',
    isRejectJobs:false,
    is_export: 0,
    start: 0,
    limit: 10,
  };

  public searchByList = [
    { id: "", text: "Search By" },
    { id: "1", text: "Customer Name" },
    { id: "2", text: "Customer Email" },
    { id: "3", text: "Customer Mobile" },
    { id: "4", text: "Vehicle" },
    { id: "5", text: "Vin" },
    { id: "6", text: "License Plate" },
    { id: "7", text: "SR ID" },
    { id: "8", text: "Unit Number" },
  ];
  public searchByNSDList = [
    { id: "", text: "Next Service Date" },
    { id: "1", text: "Less than 180 days" },
    { id: "2", text: "More than 180 days" },
    { id: "3", text: "More than 365 days" },
    { id: "4", text: "More than 545 days" },
    { id: "5", text: "Date Range" },
  ];

  public showDateRange: any = false;
  public displayedColumns = ['vehicleImage','vehicle','licensePlate','VIN', 'account','lastYearTotal','currentYearTotal','lastServiceDate','nextServiceDate','Actions'];
  public gc: any = globalConstants;
  public record: any[];
  public currentCustomer: any;
  public recordsTotal: any;
  public dateRangeOptions: any;
  public imgPath = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_PICTURES + globalConstants.IMG_THUMB;
  public previousYear: number;
  public currentYear: number;
  constructor(
    public dialog: MatDialog,
    public globalService: GlobalService,
    private router: Router
  ) {
    this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
    this.options.user_id = this.currentCustomer.id;
    this.options.user_type = this.currentCustomer.user_type;
    this.globalService.setMetaData("SHOPS","VEHICLESLIST");
   }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.previousYear =this.currentYear - 1;
    this.reinitializeOptions();
    if(localStorage.getItem(LS_SEARCH_PREFIX+"search_keyword")!="" ||localStorage.getItem(LS_SEARCH_PREFIX+"days_till_next_service")!="") {
      this.searchPanelOpenStatus = true;
  } else {
      this.searchPanelOpenStatus = false;
  }
    this.setDataTable();
  }

  reinitializeOptions() {
    this.options=this.globalService.localGetterSetter(this.options,this.options,LS_SEARCH_PREFIX);
    this.options.user_type= "User";
    this.options.user_id= this.currentCustomer.id;
}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  setDataTable() {
    this.record = [];
    var callApi = this.globalService.callAPI(globalConstants.API_GET_SHOP_VEHICLES_LIST, this.options);
    callApi.subscribe((data: any) => {
      if(data.result == '1'){
      if (data.data != undefined) {
        let moreRows = 'false';
        let firstList = 'false';
        this.globalService.localGetterSetter("totalRecords", data.recordsTotal, LS_SEARCH_PREFIX);
        if (data.more_rows == "true") {
          moreRows = "true";
        }
        this.globalService.localGetterSetter("more_rows", moreRows, LS_SEARCH_PREFIX);
        if (data.start >= 0 && data.start <= 5) {
          firstList = "true";
        }
        this.globalService.localGetterSetter("first_list", firstList, LS_SEARCH_PREFIX);
        this.recordsTotal = data.data.length;
        if (data.data.length == undefined) {
          this.dataSource.data = data.data;
          this.AddRecord(data.data);
          this.globalService.localGetterSetter("current-visible", '1', LS_SEARCH_PREFIX);
          this.dataSource = new MatTableDataSource(this.record);
          this.dataSource.sort = this.sort;
        } else {
          this.globalService.localGetterSetter("current-visible", this.recordsTotal, LS_SEARCH_PREFIX);
          for (var i = 0; i < data.data.length; i++) {
            this.AddRecord(data.data[i]);
          }
          this.dataSource = new MatTableDataSource(this.record);
          this.dataSource.sort = this.sort;
        }
        this.globalService.localGetterSetter(null, this.options, LS_SEARCH_PREFIX);
      } else {
        this.dataSource = new MatTableDataSource(this.record);
        this.globalService.localGetterSetter("totalRecords", '0', LS_SEARCH_PREFIX);
      }
    }else{
       this.dataSource = new MatTableDataSource(this.record);
       this.globalService.localGetterSetter("totalRecords", 0, LS_SEARCH_PREFIX);
       this.globalService.snackbar("error",data.message);
    }
    })
  }

  AddRecord(record) {
    var data = this.createRecord(record);
    this.record.push(data);
  }

  private createRecord(record) {
    return {
      srId: record.request_id,
      carId: record.car_id,
      customerId: record.customer_id,
      vehicle: this.globalService.getGlobalVehicleName(record)||"-",
      vehicleImage:record.vehicle_pic,
      VIN: record.vin || '-',
      licensePlate: record.license_plate || '-',
      customer: record.first_name + ' ' + record.middle_name + " " + record.last_name,
      lastServiceDate: this.globalService.getFullFormateDate(record.requested_delivery_date,'GD','H') || '-',
      lastServiceDays: record.days_since_last_service,
      nextServiceDate: this.globalService.getFullFormateDate(record.schedule_by_date,'GD','H') || '-',
      nextServiceDays: record.days_till_next_service,
      lastYearTotal: Number(record.last_year_total).toFixed(2),
      currentYearTotal: Number(record.current_year_total).toFixed(2)
    };
  }

  getTotalRecords() {
    let t = parseInt(localStorage.getItem(LS_SEARCH_PREFIX + 'totalRecords'));
    return (isNaN(t) ? 0 : t);
  }

  paginate(event, type) {
    this.scrolling();
    if (type == "next") {
      this.options.start = this.options.start + this.options.limit;
    } else {
      this.options.start = this.options.start - this.options.limit;
    }
    this.setDataTable();
  }

  scrolling() {
    this.globalService.setScrolling('.car-datatable', 1000, -20);
  }

  paginateByLimit(event) {
    this.globalService.setScrolling('body', 600, 0);
    this.options.limit = event.pageSize;
    this.options.start = 0;
    this.scrolling();
    this.setDataTable();
  }

  firstList() {
    return this.globalService.firstList(LS_SEARCH_PREFIX, this.options);
  }

  lastList() {
    return this.globalService.lastList(LS_SEARCH_PREFIX);
  }

  getUpperLimit(currentLastLimit, total) {
    if (currentLastLimit > total) {
      return total;
    } else {
      return currentLastLimit;
    }
  }

  showDays(day,t){
    if(t){
      return (day ? day + ( day == 1 ? ' Day':' Days') :'Day');
    }else{
      return (day ? (day>999?'999+':day) + ( day == 1 ? ' Day':' Days') :'Day');
    }
  }

  handleSearchVehiclesList(event) {
    this.options.start = 0;
    this.options.vehiclesDateRange = this.dateRangeOptions;
    this.setDataTable();
  }

  onDateChange(event: any) {
    var stringDateFormate = [
      event.start.toISOString(),
      event.end.toISOString()
    ]
    this.dateRangeOptions = stringDateFormate;
  }

  clearSearch() {
    this.options.start = 0;
    this.options.search_keyword = "";
    this.options.search_by = '';
    this.options.days_till_next_service = "";
    this.options.is_export = 0;
    this.options.isRejectJobs = false;
    this.showDateRange = false;
    this.globalService.ShowDateRange(this.showDateRange);
    this.setDataTable();
  }

  checkFormEmpty(button) {
    if (this.options.search_keyword == "" && this.options.search_by == '' && this.options.days_till_next_service == "" && this.options.isRejectJobs == false
    ) {
      if (button) { button.color = "primary" }
      return false;
    } else {
      if (button) { button.color = "accent" }
      return true;
    }
  }

  onChangeDays(event: any) {
    if (event == 5) {
      this.showDateRange = true;
      this.globalService.ShowDateRange(this.showDateRange);
    } else {
      this.showDateRange = false;
      this.globalService.ShowDateRange(this.showDateRange);
    }
  }

  handleOpenViewCarAndCustomerPopUp(data, tabInfo) {
    let dialogRef = this.dialog.open(DetailTabComponent, {
      panelClass: 'car-dialog-form',
      width: "800px",
      data: { requestId: data.requestId, customer_id: data.customerId, tab: tabInfo, car_id: data.carId }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openServiceHistoryPopup(carId: any) {
    let info: any = {
      id: 'service-history-advanced',
      name: 'Service History View',
      module: 'Service Request'
    };
    let data: any = { isPopup: true, carId: carId, id: 'service-history-' + carId };
    this.globalService.openMinimizablePopup(ServiceHistoryComponent, info, data);
  }


  handleDownloadVehiclesCSV() {
    this.options.is_export = 1;
    var content = globalFunctions.stringifyFormData(this.options, null);
    var callApi = this.globalService.callAPI(globalConstants.API_GET_SHOP_VEHICLES_LIST, content);
    callApi.subscribe((data: any) => {
      var reportData = data;
      window.location.href = globalConstants.API_SHOP_DOWNLOAD_SERVICE_REQUEST_LIST + "?t=4&p=" + reportData.csv_url;
      this.options.is_export = 0;
    });
  }

  handleMakeNewSR(carId,customerId) {
    this.globalService.localGetterSetter(this.options,null,LS_SEARCH_PREFIX);
    this.router.navigate(['shops/new-service-request/'+customerId+'/'+carId]);
  }

   /** open Add Notes */
   AddInternalNotes() {
    let dialogRef = this.dialog.open(AddinternalnoteComponent, {
      panelClass: 'car-dialog-form',
      width: "800px",
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.result==1){
        this.clearSearch();
      }
    });
  }
}

export interface shopCarsData {
  reqId: any;
  customerId: any;
  carId: any;
  VIN: any;
  vehicle:any;
  vehicleImage:any;
  licensePlate: any;
  customer: any;
  milage:any;
  milagePerYear:any;
  lastServiceDate: any;
  lastServiceDays:any;
  nextServiceDate:any;
  nextServiceDays:any;
  lastYearTotal:any;
  currentYearTotal:any;
}
