<form class="form-horizontal add-new-part-form" autocomplete="off" novalidate [formGroup]="newPart" (ngSubmit)="addNewPartSubmit(1);" >
    <div mat-dialog-title class="relative">
        Add New Part
        <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
            <mat-icon class="close-icon-lg">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <div class="display-full margin-bottom-5">
            
            <label for="partName" class="col-sm-3 control-label">
                <mat-checkbox  class="v-checkbox in-confirm" matTooltip="Keep Part Name" formControlName="keep_part_name"  mat-tooltip-panel-above class="tooltip-status"></mat-checkbox>
                <strong>Part Name</strong>
            </label>
            <div class="col-sm-9">
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput type="text" placeholder="Part Name" formControlName="part_name" maxlength="200"  class="form-control input-lg custom-form-input btn-block" required/>
                    <mat-error *ngIf="newPart.controls.part_name.hasError('required')">
                        Part name is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <!--<input type="text" class="form-control" id="partName" name="part_name" placeholder="Part Name*" data-validation="[NOTEMPTY]">-->
            </div>
        </div>
        <div class="display-full margin-bottom-5">
            <label for="partNumber" class="col-sm-3 control-label">
                <mat-checkbox  class="v-checkbox in-confirm" matTooltip="Keep Part Number" formControlName="keep_part_no"  mat-tooltip-panel-above class="tooltip-status"></mat-checkbox>
                <strong>Part Number</strong>
            </label>
            <div class="col-sm-9">
                <div class='relative auto-complete-full'>
                    <input matInput type="text" placeholder="Part Number" formControlName="part_number"  class="form-control input-lg custom-form-input btn-block" ngui-auto-complete (valueChanged)="partNumber($event)" [source]="observableSourcePN"
                        [list-formatter]="myListFormatter" (click)="setReturnFalse()"  required />
                    <br/><br/>
                    <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper" *ngIf="newPart.controls.part_number.hasError('required') && submitted" >
                        <mat-error>Part number is <strong>required</strong></mat-error>
                    </div>
                </div>                    
            </div>
        </div>
        <div class="display-full margin-bottom-5">
            <label for="cost" class="col-sm-3 control-label"><strong>Part Cost</strong></label>
            <div class="col-sm-9">
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput type="text" placeholder="Part Cost" formControlName="cost"   class="form-control input-lg custom-form-input btn-block"  maxlength="15" required (keypress)="onlyNumericKey($event);convertIntoDecimal($event,'cost',11)" />
                    <mat-error *ngIf="newPart.controls.cost.hasError('required')">
                        Part Cost is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="newPart.controls.cost.hasError('pattern')">
                        Must be a number with upto 2 decimal places.
                    </mat-error>
                </mat-form-field>
                <!--<input type="text" class="form-control" id="cost" name="cost" placeholder="Part Cost*" onkeydown="allowDigits(event);" data-validation="[NOTEMPTY]">-->
            </div>
        </div>
        <div class="display-full margin-bottom-5">
            <label for="price" class="col-sm-3 control-label"><strong>Retail Price</strong></label>
            <div class="col-sm-9">
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput type="text" placeholder="Retail Price" formControlName="price"  class="form-control input-lg custom-form-input btn-block"  maxlength="15" required (keypress)="onlyNumericKey($event);convertIntoDecimal($event,'price',11)" />
                    <mat-error *ngIf="newPart.controls.price.hasError('required')">
                       Retail Price is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="newPart.controls.price.hasError('pattern')">
                        Must be a number with upto 2 decimal places.
                    </mat-error>
                </mat-form-field>
                <!--<input type="text" class="form-control" id="price" name="price" placeholder="Part Price*" maxlength="11" onkeydown="allowDigits(event);" data-validation="[NOTEMPTY]">-->
            </div>
        </div>
        <div class="display-full margin-bottom-5">
            <label for="vendor" class="col-sm-3 control-label">
                <mat-checkbox  class="v-checkbox in-confirm" matTooltip="Keep Vendor" formControlName="keep_vendor" mat-tooltip-panel-above class="tooltip-status"></mat-checkbox>
                <strong>Vendor</strong>
            </label>
            <div class="col-sm-9">
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput type="text" placeholder="Vendor" formControlName="vendor"  class="form-control input-lg custom-form-input btn-block"  maxlength="200" />
                </mat-form-field>              
                <!--<input type="text" class="form-control" id="vendor" name="vendor" placeholder="Vendor" maxlength="255">-->
            </div>
        </div>
        <div class="display-full margin-bottom-5">
            <label for="manufacturer" class="col-sm-3 control-label">
                <mat-checkbox  class="v-checkbox in-confirm" matTooltip="Keep Manufacturer" formControlName="keep_manufacturer" mat-tooltip-panel-above class="tooltip-status"></mat-checkbox>
                <strong>Manufacturer</strong>
            </label>
            <div class="col-sm-9">
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput type="text" placeholder="Manufacturer" formControlName="manufacturer"   class="form-control input-lg custom-form-input btn-block"  maxlength="200" />
                </mat-form-field>             
                <!--<input type="text" class="form-control" id="manufacturer" name="manufacturer" placeholder="Manufacturer" maxlength="255">-->
            </div>
        </div>
        <div class="display-full margin-bottom-5" *ngIf="isInventory">
            <label for="part_qty" class="col-sm-3 control-label">Part Quantity</label>
            <div class="col-sm-9 details-input">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <input matInput maxlength="15" placeholder="Part Quantity" title="Part Quantity" formControlName="part_qty" class="input-lg custom-form-input" (keydown)="disableKey($event);" (keypress)="onlyNumericKey($event);convertIntoDecimal($event,'part_qty',11)"  required />
                    <mat-error *ngIf="newPart.controls.part_qty.hasError('required')">
                        Part Quantity is <strong>required</strong> 
                    </mat-error>
                    <mat-error *ngIf="newPart.controls.part_qty.hasError('pattern')">
                       Must be a number with upto 2 decimal places.
                </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="display-full margin-bottom-5" *ngIf="isInventory">
            <label for="low_stock_qty" class="col-sm-3 control-label">Low Stock Quantity</label>
            <div class="col-sm-9 details-input">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <input matInput maxlength="15" placeholder="{{lowStockQty}}" title="Low Stock Quantity" formControlName="low_stock_qty" class="input-lg custom-form-input" (keydown)="disableKey($event);" (keypress)="onlyNumericKey($event);convertIntoDecimal($event,'low_stock_qty',11)" required />
                    <mat-error *ngIf="newPart.controls.low_stock_qty.hasError('required')">
                        Low Stock Quantity is <strong>required</strong> 
                    </mat-error>
                    <mat-error *ngIf="newPart.controls.low_stock_qty.hasError('pattern')">
                       Must be a number with upto 2 decimal places.
                </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="display-full margin-bottom-5">
            <label for="oem_part_number" class="col-sm-3 control-label">
                <mat-checkbox  class="v-checkbox in-confirm" matTooltip="Keep OEM Part Number" formControlName="keep_oem_part_no"  mat-tooltip-panel-above class="tooltip-status"></mat-checkbox>
                <strong>OEM Part Number</strong>
            </label>
            <div class="col-sm-9">
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput type="text" placeholder="OEM Part Number" formControlName="oem_part_number" class="form-control input-lg custom-form-input btn-block"  maxlength="200" />
                </mat-form-field>
                <!--<input type="text" class="form-control" id="oem_part_number" name="oem_part_number" placeholder="OEM Part Number" maxlength="255">-->
            </div>
        </div>
        <div class="display-full margin-bottom-5">
                <label for="oem_part_number" class="col-sm-3 control-label">Tags</label>
                <div class="col-sm-9  uc-ngx-select">
                    <div class="col-sm-6 padding-0 details-input">
                         <ngx-select [autoClearSearch]="true" class="display-full margin-bottom-20 tags-select" formControlName="part_tags" [multiple]="true" [items]="allTags" optionTextField="tag_name" placeholder="Select Tags"></ngx-select>
                    </div>
                     <div class="col-sm-6">
                        <button type="button" mat-raised-button class="car-btn btn-block text-uppercase font-light fontcolor-white manage-tags-btn" id="manageTags" color="accent" title="Manage Tags" (click)="manageTag()">Manage Tags</button>
                     </div>
                </div>
        </div>
        <div class="display-full margin-bottom-5">
            <label for="description" class="col-sm-3 control-label"><strong>Description</strong></label>
            <div class="col-sm-9 details-input">
                <mat-form-field class="car-input btn-block notes-input" floatPlaceholder="never" appearance="fill">
                    <textarea matInput placeholder="Description" rows="5" formControlName="description" maxlength="1000"></textarea>
                </mat-form-field>
                <!--<textarea class="form-control" id="description" name="description" placeholder="Description" rows="5"></textarea>-->
            </div>
        </div>
        <div class="display-full margin-bottom-5">
            <label for="partPic" class="col-sm-3 control-label"><strong>Part Picture</strong></label>
            <div class="col-sm-9">
                <div class="">
                    <div class="col-sm-12 file-upload-container padding-0">
                        <div class="upload-image" id="upload-img">
                            <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative">
                                <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                                Drag File Here
                                <input type="file" (change)="uploadImage($event)"/>
                                <button mat-raised-button color="accent" class="pull-right">Upload</button>
                            </mat-toolbar>
                            <ul class="list-inline" >
                              <li class="relative view-images" *ngIf="newPart.controls.part_picture.value!=''">
                                  <a href="javascript:void(0);" class="thumbnail"><img class="show-image" src="{{newPart.controls.part_picture.value}}">
                                    <i class="fa fa-trash remove-img" (click)="deleteImg()"></i></a>
                              </li>
                            </ul>
                        </div>
                     </div>
                    <div class="col-sm-12 padding-0">
                        <p class="help-block margin-0">Formats supported : png, jpeg, jpg</p>
                        <p class="help-block margin-0">Maximum size : 10MB</p>
                        <div id="partPicView">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="display-full margin-bottom-5">
            <div class="col-sm-3">&nbsp;</div>
            <div class="col-sm-9">
                <mat-checkbox name="non_taxable" formControlName="non_taxable" (change)="toggleNonTaxable($event)">Non Taxable</mat-checkbox>
                <!--<input type="checkbox"  value="1">Non Taxable-->
                <p><strong>Note:</strong> If checked then this part price would not be included in tax calculations.</p>
            </div>
        </div>

        <div class="display-full margin-bottom-5">
            <div class="col-sm-3">&nbsp;</div>
            <div class="col-sm-9">
                <mat-checkbox name="add_to_job" (change)="toggleQuantityField($event)" [(ngModel)]="newPart.value.add_to_job" [ngModelOptions]="{standalone: true}">Add to job</mat-checkbox>
            </div>
        </div>

        <div class="display-full margin-bottom-5" *ngIf="newPart.value.add_to_job ">
            <label for="description" class="col-sm-3 control-label"><strong>Quantity</strong></label>
            <div class="col-sm-9">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                  <input matInput type="text" formControlName="quantity" class="form-control input-lg custom-form-input btn-block"  maxlength="15" (keydown)="disableKey($event);" (keypress)="onlyNumericKey($event)" (paste)="validateFieldOnPaste('quantity')"/>
               <mat-error *ngIf="newPart.controls.quantity.touched && newPart.controls.quantity.hasError('pattern')">Please enter only numbers</mat-error>
                </mat-form-field>
             </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="inquiry-action relative flexBtn3">
        <button type="button" mat-raised-button mat-dialog-close class="font-bold" click="close()">Close</button>
        <button type="button" mat-raised-button class="font-bold" color="accent" (click)="addNewPartSubmit(1)" >Submit and Close</button>
        <button type="button" mat-raised-button class="font-bold" color="accent" (click)="addNewPartSubmit(2)" >Submit and Add Another</button>
    </mat-dialog-actions>
</form>
