import {Component, OnInit, ViewChild,ChangeDetectorRef, ViewEncapsulation} from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Router, ActivatedRoute, ParamMap, NavigationEnd, Params} from '@angular/router';
import {Http, Headers, RequestOptions, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../environments/globalFunctions";
import {globalConstants} from "../../../environments/globalConstants";
import {GlobalService} from "../../../environments/global.service";
import {lang} from "../../../environments/lang-en";
import {Title} from '@angular/platform-browser';
import {Subscription, Observable} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';
import { ImagedialogComponent } from '../../assetsComponent/imagedialog/imagedialog.component';
import {AppComponent} from '../../app.component';
import { PartagsComponent } from '../shopparts/partags/partags.component';
// import { forEach } from '@angular/router/src/utils/collection';

@Component({
    selector: 'shop-editpart',
    templateUrl: 'shopeditpart.component.html',
    styleUrls: ['./shopeditpart.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ShopeditpartComponent {
    public currentCustomer;
    public currentCustomerName;
    public currentCustomerVehicles;
    public isCollapsed: boolean = true;
    public part_id;
    public showSubscriptionMessage = false;
    public base64value = "";
    public selectedKey = "";
    public states: any;
    public cities: any;
    public editNewPart: FormGroup;
    public currentPartDetails:any={};
    public nonTaxable;
    public submitted=false;
    public currentPartPicture='';
    public partPicture='';
    public partsPic=globalConstants.S3_BUCKET_URL+globalConstants.PART_PICTURE+globalConstants.IMG_THUMB;
    public lowStockQty="Low Stock Quantity";
    public isInventory=false;
    public allTags=[];
    public preSelectTag=[];

    constructor(private ref:ChangeDetectorRef,private activatedRoute:ActivatedRoute,private formBuilder: FormBuilder,private route: ActivatedRoute,public titleService:Title,private http: Http,private router: Router, public globalService: GlobalService,public dialog: MatDialog) {
        if(localStorage.getItem("loggedInUser")) {
            this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
            this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
            this.part_id = localStorage.getItem("shopPartID");
            this.editNewPart = this.formBuilder.group({
                part_name: ['', Validators.required],
                part_number: ['', Validators.required],
                cost: ['', [Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
                price: ['',[Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
                vendor: [''],
                manufacturer: [''],
                oem_part_number: [''],
                description: [''],
                non_taxable: [''],
                approval_status: [''],
                part_pic: [''],
                part_id: [''],
                user_type: this.currentCustomer.user_type,
                user_id: this.currentCustomer.id,
                shop_id: this.currentCustomer.shop_id,
                part_picture:[''],
                part_qty:['',Validators.pattern(/^-?\d+(?:\.\d+)?$/)],
                low_stock_qty:['',Validators.pattern(/^-?\d+(?:\.\d+)?$/)],
                part_tags:[]
            });
        } else {
            this.router.navigate(["/shops"]);
        }
        setTimeout(()=>{
        let isEnable=this.globalService.getAcl('part','edits');
        if(!(isEnable)){
            this.router.navigate(['shops/action/forbidden']);
        }  
     },globalConstants.RELOAD_WAIT)
     this.globalService.setMetaData("SHOPS","EDIT_PART")
    }

    onlyDecimalNumberKey(event) {
        let charCode = (event.which) ? event.which : event.keyCode;
        if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
            return false;
        return true;
    }

    disableKey(event) {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if (event.ctrlKey==true && (event.which == '67' || event.which == '86' ||  String.fromCharCode(event.which).toLowerCase() == 's')) {
            console.log('thou. shalt. not. PASTE!');
            event.preventDefault();
        }
    }


    ngOnInit() {

        var shopContent = "user_id="+this.currentCustomer.id+"&user_type="+this.currentCustomer.user_type+"&part_id="+ this.part_id;
        this.globalService.callAPI(globalConstants.API_GET_PARTS_LIST_URL,shopContent).subscribe((data:any) => {
            if(data.result==1){
            this.currentPartDetails = data.data;
            // console.log('Part Details', this.currentPartDetails);
            this.editNewPart.controls.part_id.setValue(this.part_id);
            this.editNewPart.controls.approval_status.setValue(this.currentPartDetails.admin_approval_status);
            if (this.currentPartDetails.non_taxable == 1) {
                this.nonTaxable = true;
                this.editNewPart.controls.non_taxable.setValue('1');
            } else {
                this.nonTaxable = false;
                this.editNewPart.controls.non_taxable.setValue('0');
            }
            let tags=this.currentPartDetails.part_tags;
           
            if(tags.length>0){
                tags.forEach((r,v)=>{
                    console.log(r);
                    this.preSelectTag.push(r.tag_id);
                })
            }
            this.partPicture = this.currentPartDetails.part_picture;
           }
        },(error)=>{});
        this.isInventory = this.globalService.isInventories();
        if (this.isInventory) {
            let content = { shop_id: this.currentCustomer.shop_id };
            this.globalService.callAPI(globalConstants.API_GET_SHOP_DEFAULT_LOW_STOCK_VALUE, content).subscribe((data:any) => {
                if (data.result == "1") {
                    this.lowStockQty = data.data.default_low_stock_value;
                }
            }, (error) => { });

            this.editNewPart.get('part_qty').setValidators([Validators.required,Validators.pattern(/^-?\d+(?:\.\d+)?$/)]);
            this.editNewPart.get('low_stock_qty').setValidators([Validators.required,Validators.pattern(/^-?\d+(?:\.\d+)?$/)]);
        }
        this.getAllTags();

    }

    sideNavOpen() {
        console.log("nav toggle");
    }
    onResize(event) {
        event.target.innerWidth;
    }

    getCurrentRoute() {
        return this.router.url
    }

    editPart() {
        if (this.editNewPart.valid) {
            var stringifiedFormData = globalFunctions.stringifyFormData(this.editNewPart.value, null);

            var data = new FormData();
            for (let i in this.editNewPart.value) {
                data.append(i, this.editNewPart.value[i]);
            }

            this.globalService.formData(globalConstants.API_EDIT_PART_URL,data).subscribe((data:any) => {
                    var message = data.message;
                    if (data.result == "1") {
                        this.router.navigate(['shops//list-parts']);
                        this.globalService.snackbar('success', message);
                    } else {
                        this.globalService.snackbar('error', message);
                    }
                });
        } else {
            this.submitted = true;
            this.mandatoryMsg();
        }
    }

    getFile(evt,key) {
        this.selectedKey = key;
        var validImage=this.globalService.validateFiles('image', evt.target.files);
        if (validImage) {
            var reader = new FileReader();
            reader.onload = this._handleReaderLoaded.bind(this);
            reader.readAsDataURL(evt.target.files[0]);
        }
    }

    _handleReaderLoaded(readerEvt) {
        // console.log(this.selectedKey);
        var binaryString = readerEvt.target.result;
        this.base64value = btoa(binaryString);
        this.currentPartPicture=binaryString;
        this.editNewPart.controls.part_picture.setValue(binaryString);
        this.ref.detectChanges();
    }

    showTaxExempted(event) {
        if (event.checked) {
            this.editNewPart.controls.non_taxable.setValue('1');
        } else {
            this.editNewPart.controls.non_taxable.setValue('0');
        }
    }

    mandatoryMsg() {
        this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }

    deletePartPic(){
        this.currentPartPicture='';
        this.editNewPart.controls.part_picture.setValue('');
        let ele=<HTMLInputElement>document.getElementById('partPic');
        ele.value='';
    }
    
    deleteExistingPartPic(){
        this.globalService.confirmDialog(1, 'Are you sure you want to delete this picture?').subscribe((res) => {
            if (res != null) {

                let content='user_id='+this.currentCustomer.id+'&part_id='+this.part_id+'&user_type='+this.currentCustomer.user_type;
                this.globalService.callAPI(globalConstants.API_DELETE_PART_PICTURE_URL,content).subscribe((data:any) => {
                    //this.partPicture='';
                    var message = data.message;
                    if (data.result == "1") {
                        this.partPicture='';
                        this.globalService.snackbar('success', message);
                    } else {
                        this.globalService.snackbar("error", message);
                    }
                })
            }
        });
    }
    
    openImageDialog(catType){
      let imgfile,base64;
      if(this.partPicture!='' && this.partPicture!=null && this.partPicture!=undefined && this.currentPartPicture==''){
        imgfile=this.partPicture;
      }
      else{
        imgfile = "";
        base64=this.currentPartPicture;
      }
      let dialogRef = this.dialog.open(ImagedialogComponent, {
          panelClass: ['image-dialog'],
          data: {img:imgfile, category:catType, base64Image:base64}

      });
      imgfile="";
      base64="";
    }
    manageTag(){
        let dialogRef = this.dialog.open(PartagsComponent, {
            panelClass: 'partstech-order-dialog',
            width: '800px',
            data: {}
        });
        dialogRef.afterClosed().subscribe(result => {
            this.getAllTags();
        });
    }
    getAllTags(){
        let data={user_id: this.currentCustomer.id};
        this.globalService.callAPI(globalConstants.API_LIST_PART_TAGS,data).subscribe((res:any)=>{
            if (res.result == "1") {
                this.allTags=res.data;
            }
        },(error)=>{})
    }

    partNumber(opt) {
        if (typeof opt == "object") {
            this.editNewPart.controls.part_number.setValue(opt.part_number);
        }else if(typeof opt!='undefined'){
            this.editNewPart.controls.part_number.setValue(opt);
        }
    }

    observableSourcePN = (keyword: any): Observable<any[]> => {
        let PartSearchKeyword = keyword.replace(/#/g, "|");
        let url: string = globalConstants.API_GET_PART_NUMBERS + "?user_id=" + this.globalService.getCurrentUser()["id"]+ "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&query=" + PartSearchKeyword;
        if (keyword) {
            let f = [];
            return this.globalService.getAPI(url).map((res:any)=>{
                    let json = res;
                    this.globalService.authText(json);
                    if (json != null) {
                        json.data.forEach(function (x) {
                            x.value = x.part_number;
                            f.push(x)
                        }
                        );
                    }
                    return f
                })
        } else {
            return Observable.of([]);
        }
    }

    setReturnFalse() {
        return false
    }

    myListFormatter(data: any): string {
        return data['part_number'];
    }
    convertIntoDecimal(event,frmControlName,beforeDecimalDigit){
        let fieldValue = this.editNewPart.get(frmControlName).value
        let result= this.globalService.processDecimalValue(fieldValue,beforeDecimalDigit);
        this.editNewPart.get(frmControlName).setValue(result);
        return globalFunctions.validateNumbersWithDecimal(event,3);
      }
}
