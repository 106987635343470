import { Component, OnInit, OnDestroy} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute  } from '@angular/router';
import { Http,Headers, Response} from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { globalFunctions } from "../../../environments/globalFunctions";
import { globalConstants } from "../../../environments/globalConstants";
import {GlobalService} from "../../../environments/global.service";
import { lang } from "../../../environments/lang-en";
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoaderService } from '../../base/services/LoaderService';

@Component({
    selector: 'shop-addgenericjob',
    templateUrl: 'shopaddgenericjob.component.html',
    styleUrls: ['shopaddgenericjob.component.scss']
})
export class ShopaddgenericjobComponent implements OnInit,OnDestroy  {
  public currentCustomer;
  public currentCustomerName;
  public currentCustomerVehicles;
  public isCollapsed:boolean = true;
  public idFromUrl;
  public showSubscriptionMessage = false;
  public shopAddJob:FormGroup;
  public currentJobDetails;
  public subscriptionDataForAPI: Subscription = new Subscription();

  constructor(
    private activatedRoute:ActivatedRoute,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public titleService:Title,
    private http: Http,
    private router: Router,
    public snackBar: MatSnackBar, 
    public globalService: GlobalService,
    private loaderService: LoaderService
    ){
    this.globalService.setMetaData("SHOPS","ADD_GENERIC_JOB")
      if(localStorage.getItem("loggedInUser")) {
          this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
          this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
          this.shopAddJob = this.formBuilder.group({
              job_name: ['', [Validators.required]],
              cost: ['', [Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
              price: ['',[Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
              description: [''],
              non_taxable: [''],
              user_type: this.currentCustomer.user_type,
              user_id: this.currentCustomer.id,
          })
      } else {
        this.router.navigate(["/shops"]);
      }
      setTimeout(()=>{
      let isEnable=this.globalService.getAcl('generic_job','addNew');
      if(!(isEnable)){
          this.router.navigate(['shops/action/forbidden']);
      }
    },globalConstants.RELOAD_WAIT);
  }

  ngOnInit(){
    this.loaderService.hide();
//      this.shopAddJob = this.formBuilder.group({
//        job_name: ['', Validators.required],
//        cost: ['', [Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
//        price: ['',[Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
//        description: ['']
//      });
   }
   onlyDecimalNumberKey(event) {
     let charCode = (event.which) ? event.which : event.keyCode;
     if (charCode != 46 && charCode > 31
         && (charCode < 48 || charCode > 57))
         return false;
     return true;

   }
   disableKey(event) {
     console.log('hi');
     let charCode = String.fromCharCode(event.which).toLowerCase();
     if (event.ctrlKey==true && (event.which == '67' || event.which == '86' ||  String.fromCharCode(event.which).toLowerCase() == 's')) {
                 console.log('thou. shalt. not. PASTE!');
                 event.preventDefault();
     }
   }
  

    addgenericjob() {
        if(this.shopAddJob.valid) {
          var stringifiedFormData = globalFunctions.stringifyFormData(this.shopAddJob.value,null);
          this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_ADD_GENERIC_JOB_URL,stringifiedFormData).subscribe((data:any)=> {
                var message = data.message;
                localStorage.setItem("search_by","");
                localStorage.setItem("sort_by","");
                localStorage.setItem("sort_type","");
                localStorage.setItem("search_keyword","");
                localStorage.setItem("start","0");
                localStorage.setItem("limit","10");
                if (data.result == "1") {
                    this.router.navigate(['shops//list-generic-jobs']);
                    this.globalService.snackbar('success', message);
                } else {
                    this.globalService.snackbar("error", message);
                }
            });
          //  this.router.navigate(["/shops"]);
        } else{
          this.mandatoryMsg();
        }
    }

    showTaxExempted(event) {
        if (event.checked) {
            this.shopAddJob.controls.non_taxable.setValue(1);
        } else {
            this.shopAddJob.controls.non_taxable.setValue(0);
        }
    }

    mandatoryMsg() {
        this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }

    convertIntoDecimal(event,frmControlName,beforeDecimalDigit){
      let fieldValue = this.shopAddJob.get(frmControlName).value
      let result= this.globalService.processDecimalValue(fieldValue,beforeDecimalDigit);
      this.shopAddJob.get(frmControlName).setValue(result);
      return globalFunctions.validateNumbersWithDecimal(event,3);
    }
    ngOnDestroy(){
      this.subscriptionDataForAPI.unsubscribe();
  }  
  
}
