<div id="mainCompotents">
    <div class="panel panel-primary margin-0 shopdashboard" id="dashboardQuickLinks">
        <div class="panel-heading padding-left-15 shop-dashboardOne-heading">
            <h3 class="panel-title">
                Shop Profile Detail
            </h3>
        </div>
    </div>
    <div class="panel panel-primary margin-top-20" *ngIf="isAllowed('views','shop_acc_info')">
        <div class="well well-lg address-detail-section">
            <a href="javascript:void(0);" *ngIf="isAllowed('edits','shop_acc_info')" (click)="editProfile()"
                class="btn-edit"><i class="fa fa-lg fa-edit fa-fw"></i>Edit</a>
            <address *ngIf="shopDetails!=undefined" class="notranslate">
                <h4 class="break-word"><strong>{{shopDetails["company_name"]}}</strong></h4>
                <span class="break-word">{{shopDetails["address1"]}}</span><span class="break-all"
                    *ngIf="shopDetails['address2']!=''">, {{shopDetails["address2"]}}</span>
                <br><span class="inline-display">{{shopDetails["city"]}}<span
                        *ngIf="shopDetails['state']">,</span></span>
                <span class="inline-display"> {{shopDetails["state"]}}<span *ngIf="shopDetails['zip']">,</span></span>
                <span class="inline-display"> {{shopDetails["zip"]}}</span>
                <br>{{shopDetails["country"]}}<br>
                <span title="Phone">P:</span>
                <a class="text-black" href="tel:{{shopDetails['business_phone1']}}"
                    [innerHTML]='shopDetails["business_phone1"]'></a> <span
                    *ngIf="shopDetails['business_phone2']">,&nbsp;</span>
                <a class="text-black" href="tel:{{shopDetails['business_phone2']}}"
                    [innerHTML]='shopDetails["business_phone2"]'></a><br>
                <div *ngIf='shopDetails["cell_phone1"]!=null && shopDetails["cell_phone2"] !=null'>
                    <span title="Mobile">M:</span>
                    <a class="text-black" href="tel:{{shopDetails['cell_phone1']}}"
                        [innerHTML]='shopDetails["cell_phone1"]'></a> <span
                        *ngIf="shopDetails['cell_phone2']">,&nbsp;</span> <a href="tel:{{shopDetails['cell_phone2']}}"
                        [innerHTML]='shopDetails["cell_phone2"]'></a>
                </div>
            </address>
            <address *ngIf="shopDetails != undefined" class="margin-bottom-0">
                <strong class="break-word">{{shopDetails["owner_name"]}}</strong><br><a
                    href="mailto:{{shopDetails['email_address']}}"
                    class="break-word">{{shopDetails["email_address"]}}</a>
                <br><a href="{{shopDetails['web_address']}}" class="break-word"
                    target="_blank">{{shopDetails["web_address"]}}</a>
                <h4 class="ad-title">Timezone : <span [innerHTML]="shopDetails['timezone']"></span></h4>
                <!-- <h4><span >Shop Timing :</span> <span [innerHTML]="openingTime"></span> </h4> -->
                <h4><span>Shop Timing </span> </h4>
                <mat-chip-list *ngIf="shopDetails.shop_timeline">
                    <ng-container *ngFor="let timeline of shopDetails.shop_timeline;let i=index">
                        <mat-chip *ngIf="timeline.is_checked==1">
                            <ng-container [ngSwitch]="timeline.weekday_id">
                                <label *ngSwitchCase="0">Monday</label>
                                <label *ngSwitchCase="1">Tuesday</label>
                                <label *ngSwitchCase="2">Wednesday</label>
                                <label *ngSwitchCase="3">Thursday</label>
                                <label *ngSwitchCase="4" class="margin-zero">Friday</label>
                                <label *ngSwitchCase="5">Saturday</label>
                                <label *ngSwitchCase="6">Sunday</label>
                            </ng-container>
                            &nbsp;{{ convertTime(timeline.open_time) }} - {{ convertTime(timeline.close_time) }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </address>
        </div>
    </div>

    <div class="panel panel-primary margin-top-20" *ngIf="roleID!='3' && roleID!='4'">
        <div class="well well-lg address-detail-section">
            <h4><strong>Current Plan Details</strong></h4>
            <div *ngIf="isSubscribed=='true'" class="subscription-alert-section">
                <p class="margin-0"><span>Plan Name : </span> <span [innerHTML]="subscriptionPlanName"></span><label
                        class="text-danger"
                        *ngIf="isPlanExpiredOrCancelled == 1 ||isPlanExpiredOrCancelled == 2 ">&nbsp;(Inactive)</label>
                </p>
                <p class="margin-0"><span>Plan Description : </span> <span [innerHTML]="subscriptionDesc"></span></p>
                <p class="margin-0"><span>Duration : </span> <span [innerHTML]="subscriptionDuration"></span>
                    <ng-container *ngIf="subscriptionDurationType!='days'"> <span *ngIf="subscriptionDuration=='1'">
                            Month</span>
                        <span *ngIf="subscriptionDuration!='1'"> Months</span></ng-container> <span
                        *ngIf="subscriptionDurationType=='days'"> Days</span>
                </p>
                <p><span>Expiry Date : </span> <span [innerHTML]="expiryDate"></span></p>
                <button mat-raised-button class="font-bold mat-elevation-z0" color="accent" style="margin-bottom: 5px"
                    (click)="gotoShopSubscription()">
                    <span *ngIf="isPlanExpiredOrCancelled == 0">Upgrade Subscription</span>
                    <span *ngIf="isPlanExpiredOrCancelled == 2 || isPlanExpiredOrCancelled == 1 ">Renew
                        Subscription</span>
                </button>
                <span *ngIf="isPlanExpiredOrCancelled == 0 && displayCCDetails">
                    <button mat-raised-button class="font-bold mat-elevation-z0" color="accent"
                        style="margin-bottom: 5px" (click)="cancelSubscription(shopID)">
                        <span>Cancel Subscription</span>
                    </button>
                </span>
            </div>
            <div *ngIf="displayCCDetails" class="cc-details-section">
                <h4 class="ad-title"><strong>CC Details</strong></h4>
                <p>CC Number : {{ccMaskedNumber}}</p>
                <p>
                    <button mat-raised-button class="font-bold mat-elevation-z0" color="accent"
                        style="margin-bottom: 5px" (click)="updateCCDetails(ccPaymentToken)">
                        <span>Update CC Details</span>
                    </button>
                </p>
                <p>
                    <strong>Note:</strong> If you want to update your existing CC info. Please click on above "Update CC
                    Details" button and fill all the mandatory fields and update. After Update, all the recurring and
                    new payment will be made using new CC. For your kind information that our system does not save any
                    sensitive/CC information.
                </p>
                <a href="https://www.braintreegateway.com/merchants/nhwv2y5vrvgmgh2y/verified" target="_blank">
                    <img class="img-responsive braintree-imgs"
                        src="https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-dark.png" />
                </a>
            </div>
            <div *ngIf="isSubscribed=='false'" class="subscription-alert-section margin-top-20">
                <!--            <p class="margin-0">You don't have any subscription yet.</p>-->
                <mat-toolbar class="subscription-alert-bar" (click)="gotoShopSubscription()">
                    <div class="subscription-content">
                        You don't have any subscription yet. So please
                    </div>
                    <div class="subscription-btn">
                        <button mat-raised-button class="font-bold mat-elevation-z0" color="accent">
                            <i class="fa fa-money margin-right-5" aria-hidden="true"></i>
                            <span>Subscribe Now</span>
                        </button>
                    </div>
                </mat-toolbar>
            </div>
        </div>
    </div>
</div>