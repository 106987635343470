import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';

@Component({
  selector: 'app-acquirevehicle',
  templateUrl: './acquirevehicle.component.html',
  styleUrls: ['./acquirevehicle.component.scss']
})
export class AcquirevehicleComponent implements OnInit {

  public info: FormGroup
  public param: any = null
  public user: any = null
  public shopDetails: any = null
  public states: any = []
  public cities: any = []
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AcquirevehicleComponent>, private frmbuild: FormBuilder, public gs: GlobalService) {
    this.param = data
    this.user = this.gs.getCurrentUser()
    this.gs.diaLogObj(this.dialogRef);
  }

  ngOnInit() {
    if (!(typeof this.param.qq != 'undefined' && this.param.customer_id != '' && this.param.qq && this.param.customer_id)) {
      this.dialogRef.close(0)
      this.gs.snackbar("error", 'Please select cutomer.');
      return false;
    }
    this.info = this.frmbuild.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      state: [''],
      city: [''],
      zip: ['', [Validators.required]],
      user_id: this.user.id,
      customer_id: this.param.customer_id,
      vin: this.param.vin,
      plate: this.param.plate,
      vehicle_state:this.param.state,
      opr: this.param.opr
    })
    this.getShopInfo()
  }
  getShopInfo() {
    let param = "user_id=" + this.user.id + "&user_type=" + this.user.user_type + "&shop_id=" + this.user.shop_id;
    this.gs.callAPI(globalConstants.API_GET_SHOP_DETAILS_ON_DASHBOARD, param).subscribe((res:any) => {
      if (res.result == "1") {
        this.shopDetails = res.data;
        this.getStates()
      }
    }, (error) => { })

  }
  getStates() {
    let param = "country_id=" + this.shopDetails.country_id + "&opr=1";
    this.gs.callAPI(globalConstants.API_GET_STATES_URL, param).subscribe((data:any) => {
      if (typeof data.data != "undefined") {
        this.states = data.data;
        var slist = [];
        for (var i in this.states) {
          this.states[i].id = this.states[i].state_id;
          this.states[i].text = this.states[i].state_name;
          slist.push(this.states[i]);
        }
        this.states = slist;
      }

    }, (error) => { })
  }
  /** get state name */
  getStateName(state_id) {
    let name = ''
    let found = this.states.filter((e) => { return e.id == state_id })
    if (found && found.length) {
      name = found[0].text
    }
    return name
  }
  /** get cities based on state */
  getCities(state) {

    this.info.controls.state.setValue(this.getStateName(state))
    this.info.controls.city.setValue('')
    this.cities = []
    let param = "state_id=" + state + "&opr=1";
    this.gs.callAPI(globalConstants.API_GET_CITIES_URL, param).subscribe((data:any) => {
      if (typeof data.data != "undefined") {
        this.cities = data.data;
        var slist = [];
        for (var i in this.cities) {
          this.cities[i].id = this.cities[i].city_name;
          this.cities[i].text = this.cities[i].city_name;
          slist.push(this.cities[i]);
        }
        this.cities = slist;
      }

    }, (error) => { })
  }

  selectedCity(city) {
    this.info.controls.city.setValue(city)
  }
  verifyInfo() {

    if (this.isValid()) {
      let param = this.info.value
      this.gs.callAPI(globalConstants.API_QQ_TRANSFER_VEHICLE, param).subscribe((r: any) => {
        if (r.result == 1) {
          this.gs.snackbar("success", r.message);
          this.dialogRef.close(1)
        } else {
          this.gs.snackbar("error", r.message);
        }
      }, (error) => { })
    } else {
      this.gs.snackbar("error", globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
  }
  isValid() {
    this.gs.validateState(this.info, ['state', 'city'])
    return this.info.valid
  }


}
