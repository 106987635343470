<div class="panel panel-primary self-checkin-component" id="mainCompotents">
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">Self CHECK-IN</h3>
    </div>

    <div class="panel-body bgcolor-lightgrey form-content self-checkin-body">
        <div class="container-fluid">
            <div class="selfCheckin-step-wrapper">

                <div class="row identification-step" *ngIf="currentStep === 1">
                    <div class="col-sm-12">
                        <p>We are delighted to welcome you at <b>{{shopDetail.company_name}}</b> ! We understand that
                            your
                            time is valuable, and we have optimised our check-in procedure for maximum
                            convenience.</p>
                    </div>
                    <div class="col-sm-12">
                        <div class="row">
                            <div class="col-xs-12 margin-bottom-10 registration-alert-section">
                                <mat-toolbar class="registration-alert-bar mat-toolbar-single-row">
                                    <div class="registration-content">
                                        First time here with us at <b><em
                                                class="notranslate">{{shopDetail.company_name}}</em></b> ?
                                    </div>
                                    <div class="registration-btn">
                                        <button mat-raised-button class=" font-bold mat-elevation-z0" color="accent"
                                            (click)="goToNextStep(4)">
                                            <span>Register Now</span>
                                        </button>
                                    </div>
                                </mat-toolbar>
                            </div>
                        </div>
                    </div>
                    <form class="form-inline col-xs-12 padding-bottom-15 user-identification-form"
                        [formGroup]="customerSearchForm" (submit)="searchCustomer(2)">
                        <div class="col-sm-12">
                            <label>Existing client, please proceed below:</label>
                        </div>
                        <div class="col-sm-12 form-block">
                            <div class="row">
                                <div class="form-group  col-sm-6">
                                    <label for="">Mobile Number</label>
                                    <div class='relative'>
                                        <mat-form-field class="btn-block car-input" floatPlaceholder="never"
                                            appearance="fill">
                                            <input matInput placeholder="Enter mobile number" title="Mobile Number"
                                                formControlName="mobileNumber" [textMask]="{mask: phoneMask}"
                                                class="form-control input-lg custom-form-input" />
                                            <mat-error
                                                *ngIf="customerSearchForm.get('mobileNumber').invalid && (customerSearchForm.get('mobileNumber').touched || customerSearchForm.get('mobileNumber').dirty)">
                                                Invalid mobile number.
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <p class="or-separator">OR</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group  col-sm-6">
                                    <label for="">Email Address</label>
                                    <div class='relative'>
                                        <mat-form-field class="btn-block car-input" floatPlaceholder="never"
                                            appearance="fill">
                                            <input matInput placeholder="Enter email address" title="Email Address"
                                                formControlName="emailAddress"
                                                class="form-control input-lg custom-form-input" />
                                            <mat-error
                                                *ngIf="customerSearchForm.controls.emailAddress.hasError('pattern')">
                                                Please enter a valid email address
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="common-error-message" *ngIf="showCommonErrorMessage == 1">
                                Please fill your phone number or email address.
                            </div>

                            <div class="row submit-section">

                                <div class="col-xs-12 col-sm-3 submit-block text-left">

                                    <button type="submit" mat-raised-button
                                        class="car-btn btn-block btn-lg font-bold fontcolor-white mx-width-300" color="accent"
                                        title="Submit" >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
                <div class="row vehicle-selection-step" *ngIf="currentStep === 2">
                    <div class="col-sm-12">
                        <div class="panel panel-primary account-details-section">
                            <div class="panel-heading">
                                <h3 class="panel-title">Account Details</h3>
                            </div>
                            <div class="panel-body bgcolor-lightgrey">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <p>Please verify your name and phone number before proceeding further.</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-5 col-sm-2 font-bold">Name:</div>
                                    <div class="col-xs-7 col-sm-10 font-bold" *ngIf="customerInfo.userExitStatus == 0">
                                        {{customerInfo.first_name}} {{customerInfo.last_name}}</div>
                                    <div class="col-xs-7 col-sm-10 font-bold" *ngIf="customerInfo.userExitStatus == 1">
                                        {{customerInfo.first_name_masked}} {{customerInfo.last_name}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-5 col-sm-2 font-bold">Phone Number:</div>
                                    <div class="col-xs-7 col-sm-10 font-bold" *ngIf="customerInfo.userExitStatus == 0">
                                        {{customerInfo.mobile_phone}}</div>
                                    <div class="col-xs-7 col-sm-10 font-bold" *ngIf="customerInfo.userExitStatus == 1">
                                        {{customerInfo.mobile_phone_masked}}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 margin-top-10 checkbox-section">
                        <mat-checkbox class="mat-error" (change)="confirmCustomer()">I confirm that above details are
                            correct and belong to my account.</mat-checkbox>
                    </div>
                    <div class="col-sm-12 margin-top-20" *ngIf="showVehiclesSection">
                        <!-- <div class="col-sm-12 margin-top-20" > -->
                        <div class="panel panel-primary vehicle-selection-section">
                            <div class="panel-heading">
                                <h3 class="panel-title">Select A Vehicle</h3>
                            </div>
                            <div class="panel-body bgcolor-lightgrey">
                                <div class="row" *ngIf="customerVehicles.length > 0">
                                    <div class="col-sm-12" *ngIf="showVehicleList == true">
                                        <p>Please select the vehicle which you have brought with you. Click on "Add Vehicle" if you don't see that vehicle in below list.</p>
                                    </div>
                                </div>
                                <div class="row" *ngIf="showVehicleList == true">
                                    <div class="col-sm-12">
                                        <div class="list-wrap" *ngIf="customerVehicles.length>0">
                                            <div class="list-group"
                                                *ngFor="let vehicle of customerVehicles; let i = index"
                                                [class.hide]="selectedVehicle && selectedVehicle.id!=vehicle.id">
                                                <div class="list-group-item relative">
                                                    <div class="row list-row">
                                                        <div class="col-xs-5 col-sm-2 image-section">
                                                            <img class="img-responsive" src="{{imgPath}}default-car.png"
                                                                *ngIf="vehicle.vehicle_pic == ''">
                                                            <img class="img-responsive"
                                                                src="{{imgPath}}{{vehicle.vehicle_pic}}"
                                                                *ngIf="vehicle.vehicle_pic != ''">
                                                        </div>
                                                        <div class="col-xs-7 col-sm-9">
                                                            <h2 class="car-name">
                                                                {{ getVehicleName(vehicle) || 'N/A' }} &nbsp;&nbsp;
                                                               <mat-chip class="anotherOwner-badge" selected="true" *ngIf="vehicle.customer_id != customerInfo.id">Temp</mat-chip>
                                                               <mat-chip color="primary" selected="true"
                                                                *ngIf="vehicle.is_walkin_customer_vehicle == '1'">Walkin</mat-chip>
                                                            </h2>
                                                            <!-- <h4 *ngIf="vehicle.ownerStatus !== 'undefined' && vehicle.ownerStatus">
                                                                 Owned By - {{vehicle.ownerName || 'N/A'}}
                                                            </h4> -->
                                                        </div>
                                                        <div class="col-xs-7 col-sm-2 select-btn">
                                                            <button *ngIf="selectedVehicle == null" mat-raised-button
                                                                class="car-btn btn-block btn-lg font-bold" type="button"
                                                                title="Select" color="accent"
                                                                (click)="selectVehicles(vehicle)">
                                                                Select
                                                            </button>
                                                            <button 
                                                                *ngIf="selectedVehicle && selectedVehicle.id ==vehicle.id"
                                                                mat-raised-button
                                                                class="car-btn btn-block btn-lg font-bold selected" type="button"
                                                                title="Select" color="accent"
                                                                (click)="unSelectVehicles(vehicle)">
                                                                Unselect
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="selectedVehicle" class="margin-top-15">
                                                    <p *ngIf="vehicle.ownerStatus !== 'undefined' && vehicle.ownerStatus" class="mat-error">This vehicle is owned by another customer registered in CAR. We will send vehicle transfer ownership request to current owner.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="noVehicles" *ngIf="customerVehicles.length == 0">
                                            No vehicle Found
                                        </div>
                                    </div>
                                </div>
                                <div class="row addVehicle-text-row" *ngIf="showVehicleList && selectedVehicle == null">
                                    <div class="col-sm-8 col-lg-10 font-bold">Do you want to add a new vehicle? click
                                        here</div>
                                    <div class="col-sm-4 col-lg-2">
                                        <button type="submit" mat-raised-button
                                            class="car-btn btn-block btn-lg font-bold fontcolor-white mx-width-300" color="accent"
                                            title="Add vehicle" 
                                            (click)="showAddVehiclesSection()">
                                            Add Vehicle
                                        </button>
                                    </div>
                                </div>
                                <div class="row add-vehicle-row">
                                    <div class="col-sm-12 margin-top-20" *ngIf="showAddVehicleSection">

                                        <div class="row">
                                            <form [formGroup]="addVehicleForm"
                                                class="form-inline col-xs-12 padding-bottom-15 add-vehicle-form">
                                                <div class="row margin-0">
                                                    <div class="col-sm-12">
                                                        <p>Please enter license plate or Make, Year and Model.</p>
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 form-block">
                                                    <div class="row">
                                                        <div class="form-group col-sm-6">
                                                            <label for="">License Plate</label>
                                                            <div class='relative'>
                                                                <mat-form-field class="btn-block car-input"
                                                                    floatPlaceholder="never" appearance="fill">
                                                                    <input matInput
                                                                        placeholder="Enter License Plate Number"
                                                                        title="First Name"
                                                                        formControlName="license_plate"
                                                                        (blur)="checkLicense()" (keyup.enter)="checkLicense()"
                                                                        class="form-control input-lg custom-form-input"
                                                                        maxlength="15" />
                                                                        <mat-error *ngIf="addVehicleForm.controls.license_plate.hasError('pattern')">
                                                                            Invalid license plate number
                                                                        </mat-error>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-sm-6">
                                                            <label for="">State</label>
                                                            <div class='relative uc-ngx-select'>
                                                                <ngx-select class="display-full margin-bottom-20"
                                                                    [defaultValue]="defaultState"
                                                                    formControlName="state" [items]="states"
                                                                    (select)="handleSelectedState($event)" 
                                                                    placeholder="Select State">
                                                                </ngx-select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-6 text-center">
                                                            <p class="font-bold margin-0">OR</p>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group  col-sm-6 uc-ngx-select">
                                                            <label for="">Make</label>
                                                            <ngx-select class="display-full margin-bottom-20"
                                                                formControlName="make" [items]="vehicleMakes"
                                                                (select)="handleSelectedMakes($event)"
                                                                placeholder="Select Make"  *ngIf="!showMakeTxtFld">
                                                            </ngx-select>
                                                            <mat-form-field class="btn-block car-input" floatPlaceholder="never" *ngIf="showMakeTxtFld" appearance="fill">
                                                                <input matInput placeholder="Make" title="Make" formControlName="make"  class="form-control input-lg custom-form-input" maxlength="200"/>
                                                            </mat-form-field>
                                                            <a href="javascript:void(0);" class="others-link make-link" (click)="useExitingMake(true)" *ngIf="!showMakeTxtFld">Another Make</a>
                                                            <a href="javascript:void(0);" class="others-link make-link" (click)="useExitingMake(false)" *ngIf="showMakeTxtFld">Existing Make</a>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group  col-sm-6 uc-ngx-select">
                                                            <label for="">Year</label>
                                                            <ngx-select class="display-full margin-bottom-20"
                                                                formControlName="year" [items]="vehicleYears"
                                                                (select)="handleSelectedYear($event)" 
                                                                [ngClass] = "disableYear ? 'disabledDropDown':''"
                                                                placeholder="Select Year" *ngIf="!showYearTxtFld">
                                                            </ngx-select>
    
                                                            <mat-form-field class="btn-block car-input" floatPlaceholder="never"  appearance="fill" *ngIf="showYearTxtFld">
                                                                <input matInput placeholder="Year" title="Year" formControlName="year" class="form-control input-lg custom-form-input" maxlength="4"/>
                                                                <mat-error *ngIf="addVehicleForm.controls.year.hasError('pattern') && addVehicleForm.get('year').touched">Please enter a valid year. </mat-error>
                                                            </mat-form-field>    
                                                            <div *ngIf="showYearAndModel">
                                                                <a href="javascript:void(0);" class="others-link make-link" (click)="useExitingYear(true)" *ngIf="!showYearTxtFld">Another year</a>
                                                                <a href="javascript:void(0);" class="others-link make-link" (click)="useExitingYear(false)" *ngIf="showYearTxtFld">Existing year</a>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group  col-sm-6 uc-ngx-select">
                                                            <label for="">Model</label>
                                                            <ngx-select class="display-full margin-bottom-20"
                                                            formControlName="model" [items]="vehicleModels"
                                                                (select)="getSelectedOptionModels($event)" 
                                                                [ngClass] = "disableModel ? 'disabledDropDown':''"
                                                                 placeholder="Select Model" *ngIf="!showModelTxtFld">
                                                            </ngx-select>
                                                            <mat-form-field class="btn-block car-input" floatPlaceholder="never"  appearance="fill" *ngIf="showModelTxtFld">
                                                                <input matInput placeholder="Year" title="Year" formControlName="model" class="form-control input-lg custom-form-input" maxlength="200"/>
                                                            </mat-form-field> 
                                                            <div *ngIf="showModel">
                                                            <a href="javascript:void(0);" class="others-link make-link" (click)="useExitingModel(true)" *ngIf="!showModelTxtFld">Another Model</a>
                                                            <a href="javascript:void(0);" class="others-link make-link" (click)="useExitingModel(false)" *ngIf="showModelTxtFld">Existing Model</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row" *ngIf="showCommonErrorMessage == 3">
                                                        <div class="col-sm-12">
                                                               <p class="mat-error">Please enter license plate or Make, Year and Model.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 data-fields">
                                                    <div class="row submit-timer-row">
                                                        <div class="col-xs-12 col-sm-5 submit-block">
                                                         <div class="row">
                                                            <div class="col-xs-12 col-sm-6">
                                                                    <button type="button" mat-raised-button
                                                                    class="car-btn btn-block btn-lg font-bold fontcolor-white mx-width-300"
                                                                    color="accent" title="Add Vehicle"  (click)="AddNewVehicles()">
                                                                    Add Vehicle
                                                                </button>
                                                            </div>
                                                            <div class="col-xs-12 col-sm-6">
                                                                <button type="button" mat-raised-button 
                                                                class="car-btn reset-btn btn-block btn-lg font-bold fontcolor-white mx-width-300"
                                                                color="accent" title="Reset"   (click)="reSetAddVehicleForm()">
                                                                Reset
                                                            </button>
                                                        </div>
                                                            </div>
                                                            
                                                        </div>
                                                        <div
                                                            class="col-xs-12 col-sm-2 margin-top-15 text-center timer-block">
                                                            <div class="timer">
                                                                <i class="fa far fa-clock" aria-hidden="true"></i>
                                                                <span> {{countDownTimer | timeFormat}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-5 submit-block text-right">
                                                            <button type="button" mat-raised-button
                                                                class="car-btn btn-block btn-lg font-bold fontcolor-white bg-black mx-width-300"
                                                                title="Cancel" 
                                                                (click)="showAddVehiclesSection()">
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 margin-top-20" *ngIf="selectedVehicle !== null">
                        <div class="panel panel-primary add-jobs-section">
                            <div class="panel-heading">
                                <h3 class="panel-title">Select Jobs</h3>
                            </div>
                            <div class="panel-body bgcolor-lightgrey">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <p>Please select the job category.</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-sm-12 job-expansion-section"
                                        *ngFor="let levelOneJob of serviceRequestJobCategories;let k = index">
                                        <div class="job-expansion-wrap"
                                            *ngFor="let level2ndJob of levelOneJob.children;let i = index"
                                            id="expansion-{{i+1}}">
                                            <mat-checkbox labelPosition="before" class="btn-block"
                                                (change)="handleJobSelection($event, i+1);makeSRJson($event,levelOneJob,level2ndJob,null,i+1)">{{level2ndJob.name}}
                                            </mat-checkbox>
                                            <div class="expansion-subchild" *ngIf="level2ndJob.children"
                                                id="{{ 'second-level-child-' + level2ndJob.id }}">
                                                <div *ngFor="let level3rdJob of level2ndJob.children; let j=index">
                                                    <label *ngIf="level3rdJob.input == 'radio'"
                                                        class="padding-right-10 material-radio" labelPosition="before">
                                                        <input type="radio" value="1"
                                                            (change)="makeSRJson($event,levelOneJob,level2ndJob,level3rdJob,i+1)" />
                                                        <span>{{level3rdJob.name}}</span>
                                                    </label>
                                                    <!-- <mat-checkbox class="btn-block" labelPosition="before" *ngIf="level3rdJob.input == 'checkbox'" (change)="makeSRJson($event,levelOneJob,level2ndJob,level3rdJob,i+1)">
                                                    {{level3rdJob.name}}
                                                  </mat-checkbox> -->
                                                    <mat-form-field class="car-input btn-block" floatPlaceholder="never"
                                                        *ngIf="level3rdJob.input == 'textarea'">
                                                        <textarea matInput id="{{levelOneJob.id}}-{{level2ndJob.id}}"
                                                            rows="4" maxlength="500"
                                                            (blur)="makeSRJson($event,levelOneJob,level2ndJob,level3rdJob,i+1)"></textarea>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row submit-timer-row vehicle-selection-submit margin-0" *ngIf="!showAddVehicleSection">
                        <div class="col-xs-12 col-sm-3 submit-block startover-block">
                            <button type="submit" mat-raised-button
                                class="car-btn btn-block btn-lg font-bold fontcolor-white" color="accent" title="Startover"
                                (click)="goToWelcomeScreen()">
                                Startover
                            </button>
                        </div>
                        <div class="col-xs-12 col-sm-6 margin-top-15 text-center timer-block">
                            <div class="timer">
                                <i class="fa far fa-clock" aria-hidden="true"></i>
                                <span> {{countDownTimer | timeFormat}}</span>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-3 submit-block  confirm-btn-col">
                            <button type="submit" mat-raised-button
                                class="car-btn btn-block btn-lg font-bold fontcolor-white btn-success" title="Confirm Checkin"
                                (click)="createSrRequest()" [disabled]="!jobSelectedStatus">
                                Confirm Checkin
                            </button>
                        </div>
                    </div>

                </div>
                <div class="row vehicleSelection-success-step" *ngIf="currentStep === 3">
                    <div class="col-sm-12 success-col">
                        <div class="alert alert-success text-center">
                            <p>Congratulation, Your vehicle checkin process has been completed successfully.<br> Your
                                service request ID is <b>#{{srInformation.request_id}}</b></p>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="row submit-timer-row">

                            <div class="col-xs-12 col-sm-3 submit-block text-center">
                                <button type="submit" mat-raised-button
                                    class="car-btn btn-block btn-lg font-bold fontcolor-white bg-black mx-width-300" title="Submit"
                                     (click)="goToWelcomeScreen()">
                                    Goto Home
                                </button>
                                <div class="timer">
                                    <i class="fa far fa-clock" aria-hidden="true"></i>
                                    <span> {{countDownTimer | timeFormat}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row new-registration-step" *ngIf="currentStep === 4">
                    <form class="form-inline col-xs-12 padding-bottom-15 registration-form">
                        <div class="col-sm-12">
                            <p>The phone number or email address you have provided is not registered with us.
                                No worries, we need minimum required details to create your account and
                                ‘proceed with check-in.
                            </p>
                            <p>Please fill below form to create your account in CAR.</p>

                        </div>
                        <div class="col-xs-12 form-block">
                            <form [formGroup]="customerAddForm" (submit)="addCustomer(2)">
                                <div class="row">
                                    <div class="form-group  col-sm-12 col-md-6">
                                        <label for="">First Name</label>
                                        <div class='relative'>
                                            <mat-form-field class="btn-block car-input" floatPlaceholder="never"
                                                appearance="fill">
                                                <input matInput placeholder="Enter first name" title="First Name"
                                                    formControlName="firstName"
                                                    class="form-control input-lg custom-form-input" />
                                                <mat-error
                                                    *ngIf="customerAddForm.controls.firstName.hasError('required')">
                                                    First Name is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="form-group  col-sm-12 col-md-6">
                                        <label for="">Last Name</label>
                                        <div class='relative'>
                                            <mat-form-field class="btn-block car-input" floatPlaceholder="never"
                                                appearance="fill">
                                                <input matInput placeholder="Enter last name" title="Last Name"
                                                    formControlName="lastName"
                                                    class="form-control input-lg custom-form-input" />
                                                <mat-error
                                                    *ngIf="customerAddForm.controls.lastName.hasError('required')">
                                                    Last Name is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group  col-sm-12 col-md-6">
                                        <label for="">Mobile Number</label>
                                        <div class='relative'>
                                            <mat-form-field class="btn-block car-input" floatPlaceholder="never"
                                                appearance="fill">
                                                <input matInput placeholder="Enter mobile number" title="Mobile Number"
                                                    formControlName="mobileNumber" [textMask]="{mask: phoneMask}"
                                                    class="form-control input-lg custom-form-input" (keyup)="validateMobileNumbersMatch()"
                                                    (blur)="checkMobilePhone()" />
                                                <mat-error
                                                    *ngIf="customerAddForm.controls.mobileNumber.hasError('pattern')">
                                                    Invalid mobile number.
                                                </mat-error>
                                                <mat-error
                                                    *ngIf="customerAddForm.controls.mobileNumber.hasError('required')">
                                                    Mobile number is <strong>required</strong>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="form-group  col-sm-12 col-md-6">
                                        <label for="">Confirm Mobile Number</label>
                                        <div class='relative'>
                                            <mat-form-field class="btn-block car-input" floatPlaceholder="never"
                                                appearance="fill">
                                                <input matInput placeholder="Confirm mobile number"
                                                    title="Confirm mobile number" formControlName="confirmMobileNumber"
                                                    [textMask]="{mask: phoneMask}"
                                                    class="form-control input-lg custom-form-input" (keyup)="validateMobileNumbersMatch()"/>
                                                <mat-error
                                                    *ngIf="customerAddForm.controls.confirmMobileNumber.hasError('pattern')">
                                                    Invalid mobile number.
                                                </mat-error>
                                                <mat-error
                                                    *ngIf="customerAddForm.controls.confirmMobileNumber.hasError('required')">
                                                    Confirm mobile number is <strong>required</strong>
                                                </mat-error>
                                                <mat-error *ngIf="customerAddForm.controls.confirmMobileNumber.hasError('mismatch')">
                                                    Mobile number and Confirm mobile number is not same
                                                </mat-error>
                                            </mat-form-field>
                                            <!-- <span *ngIf="showCommonErrorMessage == 2">
                                                Mobile number and Confirm mobile number is not same
                                            </span> -->
                                        </div>
                                    </div>

                                    <div class="form-group  col-sm-12 col-md-6">
                                        <label for="">Email Address</label>
                                        <div class='relative'>
                                            <mat-form-field class="btn-block car-input" floatPlaceholder="never"
                                                appearance="fill">
                                                <input matInput placeholder="Enter email address" title="Email id"
                                                    formControlName="emailAddress"
                                                    class="form-control input-lg custom-form-input" (blur)="checkEmailAddress()"  />
                                                <mat-error
                                                    *ngIf="customerAddForm.controls.emailAddress.hasError('pattern')">
                                                    Enter valid email id.
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="row submit-timer-row">
                                    <div class="col-xs-12 col-sm-3 submit-block startover-block">
                                        <button type="button" mat-raised-button
                                            class="car-btn btn-block btn-lg font-bold fontcolor-white" color="accent"
                                            title="Startover" (click)="goToWelcomeScreen()">
                                            Startover
                                        </button>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 margin-top-15 text-center timer-block">
                                        <div class="timer">
                                            <i class="fa far fa-clock" aria-hidden="true"></i>
                                            <span> {{countDownTimer | timeFormat}}</span>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-3 submit-block">
                                        <button type="submit" mat-raised-button
                                            class="car-btn btn-block btn-lg font-bold fontcolor-white btn-success"
                                            title="Submit">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>