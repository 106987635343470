/**
 * @trackID <:CAR-1412> Open Component as/in Popup
 * @updated 18-03-2020
 * @updatedBy Shashank Thakur
 * @return 
 */

import {Component, OnInit, ChangeDetectorRef , Inject, Optional, OnDestroy } from '@angular/core';
import {AbstractControl, FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
import {Http, Headers, Response, RequestOptions} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../../environments/globalFunctions"
import {globalConstants} from "../../../../environments/globalConstants"
import {GlobalService} from "../../../../environments/global.service"
import {lang} from "../../../../environments/lang-en"
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';
import {trigger,state,style,animate,transition} from '@angular/animations';
import {Observable} from 'rxjs/Observable';
import {startWith} from 'rxjs/operators/startWith';
import {map} from 'rxjs/operators/map';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
const EMAIL_REGEX = globalConstants.EMAIL_REGEX;
import {MatDialog} from '@angular/material/dialog';
import { NgxSelectModule, INgxSelectOptions } from 'ngx-select-ex';
import { SearchcustomerComponent } from '../searchcustomer/searchcustomer.component';
import { from } from 'rxjs/observable/from';
import { SearchothercustomerComponent } from '../searchothercustomer/searchothercustomer.component';
import { HelperService } from 'src/app/helper.service';


@Component({
  selector: 'app-addnewcustomer',
  templateUrl: './addnewcustomer.component.html',
  styleUrls: ['./addnewcustomer.component.scss'],
  animations: [
    trigger('popOverState', [
      state('show', style({
        opacity:1,
        transform:'translateY(0px)',
        'z-index':'0',
        display:'block'
      })),
      state('hide',   style({
        opacity:0,
        height:'0px',
        'z-index':'-1',
        display:'none'
      })),
      transition('show => hide', animate('0.2s 100ms ease-out')),
      transition('hide => show', animate('0.2s 100ms ease-out'))
    ])
  ]
})

export class AddnewcustomerComponent implements OnInit,OnDestroy {
    public isCollapsed:boolean = true;
    public addNewCustomer:FormGroup;
    public currentUserDetails:any;
    public shopState;
    public selectedCountry = "USA";
    public selectedState;
    public selectedCity;
    public countries: any = [];
    public states:any = [];
    public cities:any = [];
    public base64value="";
    public selectedKey="";
    public phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    public taxIncluded:boolean;
    public supplyChargesIncluded:boolean;
    public currentUserInfo;
    public getCustomerTypes;
    public getEmailCheck;
    public getMobileCheck;
    public checkUserEmail: boolean = false;
    public checkUserMobile: boolean = false;
    public hide;
    public show = false;
    public getRandomCustomerEmail;
    public customerStateName;
    public requireApprovalCheck: boolean = true;
    public requireApprovalAmount: boolean = true;
    public isSubletWorkCheck: boolean = false;
    public disableStates;
    public disableCities;
    public customerTypesOptions={};
    public modulePermission=false;
    public timezoneData=[];
    public shopTimezone:string;
    public defaultCustomerType = '';
    public shopCountry = 'USA';
    public shopCountryId = '';
    public zipLabel = 'Zip Code';
    public subscriptionDataForAPI: Subscription = new Subscription();
    public isAutoGeneratedEmail: boolean;
    public isFromZipCode: boolean = false;
    public shopDetails: any;
    constructor(
        public cdr: ChangeDetectorRef,
        private formBuilder: FormBuilder, 
        private route: ActivatedRoute, 
        public titleService: Title, 
        private http: Http, 
        private router: Router, 
        public globalService: GlobalService, 
        @Optional() @Inject(MAT_DIALOG_DATA) public popupData: any,
        public dialog: MatDialog, @Optional() private addCustomerPopup:MatDialogRef<AddnewcustomerComponent>,
        public helperService : HelperService
        ) { }
    
    initShopFormbuilder() {
        this.addNewCustomer = this.formBuilder.group(
            {
                email: ['', [Validators.pattern(EMAIL_REGEX)]],
                //password: ['', [Validators.required, Validators.minLength(6)]],
                //confirm_password: ['', Validators.required],
                first_name: ['', [Validators.required,Validators.pattern(globalConstants.NAME_REGEX)]],
                middle_name: ['',Validators.pattern(globalConstants.NAME_REGEX)],
                last_name: ['', [Validators.required,Validators.pattern(globalConstants.NAME_REGEX)]],
                address_one: ['',[Validators.required]],
                address_two: [''],
                country: [''],
                frm_country: [''],
                state: ['',Validators.required],
                frm_state: [''],
                city: ['',Validators.required],
                zipcode: ['',[Validators.pattern("^[0-9]+$")]],
                home_phone: [''],
                business_phone: [''],
                mobile_phone: ['', [Validators.required, Validators.minLength(10), this.globalService.mobileNumberValidation]],
                business_name: ['',[Validators.pattern(globalConstants.NAME_REGEX)]],
                customer_type: [''],
                tax_exempted: [''],
                user_type:this.globalService.getCurrentUser()["user_type"],
                user_id:this.globalService.getCurrentUser()["id"],
                require_approval: [''],
                approval_amount: ['',Validators.pattern(/^-?\d+(?:\.\d+)?$/)],
                timezone:[''],
                is_sublet: ['0'],
                address_is_required:[false]
            }//, {validator: this.confirmPasswordValidator}
        );


        this.addNewCustomer.get("address_is_required").valueChanges.subscribe(value => {  
            let country = this.addNewCustomer.value.country;
            let zRegex = this.countries.find(res => (res.country_id == country || res.country_name == country));
            if(value){

                this.addNewCustomer.controls.address_one.setValidators([]);
                this.addNewCustomer.controls.zipcode.setValidators([Validators.pattern(zRegex.zipcode_regex)]);
                this.addNewCustomer.controls.state.setValidators([]);
                this.addNewCustomer.controls.city.setValidators([]);
            }else{
                this.addNewCustomer.controls.address_one.setValidators([Validators.required]);  
                this.addNewCustomer.controls.zipcode.setValidators([Validators.required,Validators.pattern(zRegex.zipcode_regex)]);
                this.addNewCustomer.controls.state.setValidators([Validators.required]); 
                this.addNewCustomer.controls.city.setValidators([Validators.required]);                
            }  
            this.addNewCustomer.controls.address_one.updateValueAndValidity(); 
            this.addNewCustomer.controls.zipcode.updateValueAndValidity();  
            this.addNewCustomer.controls.state.updateValueAndValidity(); 
            this.addNewCustomer.controls.city.updateValueAndValidity();              
        });  
    } 
    
    checkEmail(){
        let email=this.addNewCustomer.controls.email;
        let errorEmail=false;

        if( this.addNewCustomer.value.email =='' || email.hasError('pattern')){
            errorEmail=true;
        }else{
        var opr = "email=" + this.addNewCustomer.value.email.replaceAll('+','%2B');
        this.subscriptionDataForAPI =  (!errorEmail) &&
         this.globalService.callAPI(globalConstants.API_CHECK_CUSTOMER_INFORMATION_URL, opr).subscribe((data:any) => {
          this.getEmailCheck = data;
            if(this.getEmailCheck.result == "1"){
                this.checkUserEmail = true;
                this.addNewCustomer.get('email').setErrors({"emailAlreadyExit": true});
                if(!this.isAutoGeneratedEmail){
                this.openDialog(this.getEmailCheck,'email');
                }
            }else{
                this.checkUserEmail = false;
                if(data.code == 528){
                    this.globalService.confirmDialog("confirm", data.message).subscribe((res) => {
                        if (res != null) {
                            this.addNewCustomer.patchValue({
                                email: ''
                            });
                        }
                    });                  
                }
            }
        })
    }
    }

    checkMobilePhone(){
        let mobile=this.addNewCustomer.controls.mobile_phone;
        let errorMobile=false;
        if(mobile.hasError('required') || mobile.hasError('pattern')){
            errorMobile=true;
        }
        var opr = "mobile_phone=" + this.addNewCustomer.value.mobile_phone;
        this.subscriptionDataForAPI =  (!errorMobile) && this.globalService.callAPI(globalConstants.API_CHECK_CUSTOMER_INFORMATION_URL, opr).subscribe((data:any) => {
          this.getMobileCheck = data;
            if(this.getMobileCheck.result == "1"){
                this.checkUserMobile = true;
                this.getMobileCheck.mobile_phone = this.addNewCustomer.value.mobile_phone;
                this.addNewCustomer.get('mobile_phone').setErrors({"contactAlreadyExit": true});
                this.openDialog(this.getMobileCheck,'mobile');
            }else{
                this.checkUserMobile = false;
                if(data.code == 528){
                    this.globalService.confirmDialog("confirm", data.message).subscribe((res) => {
                        if (res != null) {
                            this.addNewCustomer.patchValue({
                                mobile_phone: ''
                            });
                        }
                    });
                }
            }
        })
    }

    customerInformationPage(getMobileCheck,medium) {
        localStorage.setItem("customer_id",getMobileCheck.customer_id);
        if(this.popupData && this.popupData.isPopup){
            this.addCustomerPopup.close();
        }
        if(medium === 'email'){
            this.checkEmail();            
        }else if(medium === 'mobile'){
            this.checkMobilePhone();
        }
    }
      
    /* END FOR V2 SELECT */
    
    getSelectedOptionCustomerType(opt) {
        this.addNewCustomer.controls.customer_type.setValue(opt);
    }
    
    /**
     * @author Shashank Thakur
     * @trackID <:CAR-1412>
     * @created 18-03-2020
     * @see Function to save customer by sending form data to API. Closes the popup after submission if 
     * it is opened in popup. This is done as there is a redirection code that takes to customer 
     * listing page after form submission.
     */

    addCustomer(opr=1) {
        this.customerTypeValidation();
        if(this.addNewCustomer.valid) {
            /* BLANK COUNTRY CHECK */
//            if(this.addNewCustomer.value.frm_country == undefined || this.addNewCustomer.value.frm_country == 'undefined') {
//                this.addNewCustomer.controls.country.setValue('');
//            } else {
//                this.addNewCustomer.controls.country.setValue(this.customerCountryName);
//            }

            /* BLANK STATE CHECK */
//            if(this.customerStateName == undefined || this.customerStateName == 'undefined' || this.addNewCustomer.controls.frm_state == null) {
//                this.addNewCustomer.controls.state.setValue('');
//            } else {
//                this.addNewCustomer.controls.state.setValue(this.customerStateName);
//            }
            /*if(this.newCountryName){
                this.addNewCustomer.controls.country.setValue(this.newCountryName);            
            }
            */
            if (!this.addNewCustomer.value.timezone|| typeof this.addNewCustomer.value.timezone == 'undefined' || this.addNewCustomer.value.timezone == 'undefined') {
               this.addNewCustomer.patchValue({timezone : globalConstants.DEFAULT_TIME_ZONE});
            }

            if(this.addNewCustomer.value.require_approval == true) {
                this.addNewCustomer.controls.require_approval.setValue(1);
            } else {
                this.addNewCustomer.controls.require_approval.setValue(0);
            }

            var data = new FormData();

            for (let i in this.addNewCustomer.value) {
                data.append(i, this.addNewCustomer.value[i]);
            }
            this.globalService.formData(globalConstants.API_SIGNUP_URL,data).subscribe((data:any)=> {
                  var resultData = data;
                  if(resultData.result == '1'){
                    if(this.popupData && this.popupData.isPopup){
                        this.addCustomerPopup.close();
                    }
                    if(opr == 1){
                        this.globalService.localGetterSetter(null,{"search_keyword":"","start":"0","limit":"10"},'shop-customer-');
                        this.router.navigate(['shops/list-customers']);
                        this.globalService.snackbar('success', resultData.message);
                    }else{
                        this.gotoAddVehicle([resultData.data.id, this.selectedCountry, resultData.data.state].join(','));
                    }
                  } else {
                    this.globalService.snackbar('error', resultData.message);
                  }
              });
        } else {
            Object.keys(this.addNewCustomer.controls).forEach(field => {
                const control = this.addNewCustomer.get(field);
                control.markAsTouched({ onlySelf: true });
            });
            this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
        }
    }

    onlyNumericKey(event) {
        return globalFunctions.onlyDecimalNumberKey(event);
    }

    confirmPasswordValidator(c: AbstractControl) {
        if (c.get('password').value !== c.get('confirm_password').value) {
            c.get('confirm_password').setErrors({"matched": true})
            return {matched: true};
        } else {
            c.get('confirm_password').valid;
        }
    }
    ngOnInit() {
        this.shopDetails = JSON.parse(localStorage.getItem('shop-details'));
        if (this.shopDetails) {
            this.shopCountry = this.shopDetails.country;
            this.shopState = this.shopDetails.state;
            this.shopTimezone = this.shopDetails.time_zone;
            this.shopCountryId = this.shopDetails.country_id;
        }
        this.globalService.setMetaData("SHOPS", "ADD_NEW_CUSTOMER");
        let currentUserInfo = JSON.parse(localStorage.getItem("loggedInUser"));
        if (currentUserInfo) {
            this.currentUserInfo = currentUserInfo
            //this.requireApprovalCheck = true;
            this.initShopFormbuilder();
            /* GET CUSTOMER TYPES */
            var userInfo = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&shop_id=" + this.globalService.getCurrentUser()["shop_id"];
            this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_TYPES, userInfo).subscribe((data: any) => {
                this.getCustomerTypes = data.data;
                var customerTypesList = [];
                for (var i in this.getCustomerTypes) {
                    this.getCustomerTypes[i].id = this.getCustomerTypes[i].id;
                    this.getCustomerTypes[i].text = this.getCustomerTypes[i].type;
                    customerTypesList.push(this.getCustomerTypes[i]);
                    this.customerTypesOptions[this.getCustomerTypes[i].id] = this.getCustomerTypes[i].id;
                }
                this.getCustomerTypes = customerTypesList;
                this.defaultCustomerType = this.getCustomerTypes[0].id;
                //set default customer type to Standard
                this.addNewCustomer.controls.customer_type.setValue(this.defaultCustomerType);
            })

            /* GET COUNTRIES */
            this.getCountries();
            /**
             * update Zip codee validation
            */
            this.addNewCustomer.get("country").valueChanges.subscribe(country_name => {
                if (country_name && Array.isArray(this.countries)) {
                    let zRegex = this.countries.find(res => (res.country_id == country_name || res.country_name == country_name));
                    if (zRegex && 'zipcode_regex' in zRegex) {
                        this.zipLabel = zRegex.zipcode_label;
                        if (!this.addNewCustomer.get("address_is_required").value) {
                            this.addNewCustomer.controls.zipcode.setValidators([Validators.required, Validators.pattern(zRegex.zipcode_regex)]);
                        } else {
                            this.addNewCustomer.controls.zipcode.setValidators([Validators.pattern(zRegex.zipcode_regex)]);
                        }
                        this.addNewCustomer.controls.zipcode.updateValueAndValidity();
                    }
                }
            });
        } else {
            this.router.navigate(["/shops/login"]);
        }
        this.getTimezone(this.shopCountryId);
    }

   
    openDialog(getEmailCheck, searchBy) {
        if(getEmailCheck.mapping_status == '1'){
            const dialogRef = this.dialog.open(SearchcustomerComponent, {
                panelClass: ['car-dialog-form'],
                data: {
                    customerDetails:getEmailCheck,
                }
            });
        }else{
            const dialogRef = this.dialog.open(SearchothercustomerComponent, {
                panelClass: ['car-dialog-form'],
                data: {
                    customerDetails:getEmailCheck,
                    searchBy: searchBy
                }
            });
        }
        // dialogRef.afterClosed().subscribe(result => {
        //   console.log(`Dialog result: ${result}`);
        // });
    }

    /* Home Phone Validity Check */
    checkHomePhoneValidity() {
        Window["errorObj"] = this.addNewCustomer
        let home_phone = this.addNewCustomer.value.home_phone;
         if(home_phone !="") {
            let phoneResult = this.addNewCustomer.value.home_phone.replace(/\D+/g, "").length >= 10 ? false : {minLength: true};
            if(phoneResult == false) {
                this.addNewCustomer.controls.home_phone.setErrors(null);
            } else {
                this.addNewCustomer.controls.home_phone.setErrors({"phoneError": true});
            }
        } else {
            this.addNewCustomer.controls.home_phone.setErrors(null);
        }
    }

    /* Business Phone Validity Check */
    checkBusinessPhoneValidity() {
         Window["errorObj"] = this.addNewCustomer;
        let home_phone = this.addNewCustomer.value.business_phone;
         if(home_phone !="") {
            let phoneResult = this.addNewCustomer.value.business_phone.replace(/\D+/g, "").length >= 10 ? false : {minLength: true};
            if(phoneResult == false) {
                this.addNewCustomer.controls.business_phone.setErrors(null);
            } else {
                this.addNewCustomer.controls.business_phone.setErrors({"phoneError": true});
            }
        } else {
            this.addNewCustomer.controls.business_phone.setErrors(null);
        }
    }

    showTaxExempted(event) {
        if (event.checked) {
            this.addNewCustomer.controls.tax_exempted.setValue(1);
        } else {
            this.addNewCustomer.controls.tax_exempted.setValue(0);
        }
    }
    onlyDecimalNumberKey(event) {
        let charCode = (event.which) ? event.which : event.keyCode;
        if (charCode != 46 && charCode > 31
            && (charCode < 48 || charCode > 57))
            return false;
        return true;
    }

    disableKey(event) {

        let charCode = String.fromCharCode(event.which).toLowerCase();
        if (event.ctrlKey == true && (event.which == '67' || event.which == '86' || String.fromCharCode(event.which).toLowerCase() == 's')) {
            event.preventDefault();
        }
    }

    /*GET Countries */
    getCountries(){
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_COUNTRIES_LIST,"opr=1").subscribe((data:any) => {
            if (data.result == "1") {
            this.countries = data.data;
            for(var i in this.countries){
                this.countries[i].id=this.countries[i].country_id;
                this.countries[i].text=this.countries[i].country_name;                                        
            }
            // set default country and state of customer according to shop country and state
            const shopCountry = this.countries.filter(country => country.country_name == this.shopCountry);
            if(shopCountry.length > 0) {
                this.selectedCountry = shopCountry[0].country_id;
                this.shopCountryId = shopCountry[0].country_id;
                this.addNewCustomer.controls.country.setValue(this.shopCountry);
                this.addNewCustomer.controls.zipcode.setValue(this.shopDetails.zip);
                // this.addNewCustomer.controls.frm_country.setValue(this.shopCountry);
                this.getTimezone(this.shopCountryId);
                this.getStates(shopCountry[0].country_id);
            } else {
                this.addNewCustomer.controls.country.setValue('');
                // this.addNewCustomer.controls.frm_country.setValue('');
            }
            let noAddressStatus = JSON.parse(localStorage.getItem("shop-details")).no_customer_address== 1? true:false;
            this.addNewCustomer.controls.address_is_required.setValue(noAddressStatus);
            /* END FOR V2 SELECT */
        }else{
            this.countries = [];  
        }
        })
    }

    /* GET STATES */
    getStates(countryId) {
        if (typeof(countryId) == undefined || countryId == "") {
            /*this.addNewCustomer.controls.frm_state.disable();
            this.addNewCustomer.controls.city.disable();
            this.addNewCustomer.controls.frm_state.reset();
            this.addNewCustomer.controls.city.reset();*/
            this.disableStates = true;
            this.disableCities = true;
            this.cities = [
                { id: "", text: "Select City" }
            ];
        } else {
            /*this.addNewCustomer.controls.frm_state.enable();
            this.addNewCustomer.controls.city.disable();
            this.addNewCustomer.controls.frm_state.setValue('');
            this.addNewCustomer.controls.state.setValue('');*/
            this.states = [];
            let stateDDLPlaceholder = [
                { 
                    id: "",
                    state_id: "",
                    state_name: "Select State",
                    country_id: "",
                    latitude: "",
                    longitude: "",
                    tax_rate: "",
                    state_code: ""
                 }
            ];
            this.disableCities = true;
            this.cities = [
                { id: "", text: "Select City" }
            ];
            this.subscriptionDataForAPI =  this.globalService.callAPI(globalConstants.API_GET_STATES_URL, "opr=1&country_id=" + countryId)
                .subscribe((data: any) => {
                    var responseData = data;
                    if (responseData.result == "1") {
                        this.disableStates = false;
                        this.states  = stateDDLPlaceholder.concat(responseData.data);
                        for (var i in this.states) {
                            this.states[i].id = this.states[i].state_id;
                            this.states[i].text = this.states[i].state_name;
                        }
                        if (!this.isFromZipCode) { //auto fill shop state in state dropdown
                            const shopState = this.states.filter(state => state.state_name == this.shopState);
                            if (shopState.length > 0) {
                                this.selectedState = shopState[0].state_id;
                                this.addNewCustomer.controls.state.setValue(this.shopState);
                                this.getCities(shopState[0].state_id);
                            } else {
                                this.addNewCustomer.controls.state.setValue('');
                            }
                        }
                    } else {
                        this.states = [];
                        this.cities = [];
                        this.disableStates = false;
                        this.selectedState = [{ id: "", text: "No state found" }];
                        this.disableCities = true;
                    }
                    this.cdr.markForCheck();
                });
        }
    }
    
    /* GET CITIES */
    getCities(stateId) {
        if (typeof(stateId) == undefined || stateId == "") {
            this.cities = [
                { id: "", text: "Select City" }
            ];
            this.disableCities = true;
        } else {
            this.cities=[];
            let cityDDLPlaceholder=[{
                id: "",
                city_id: "",
                city_name: "Select City",
                state_id: "",
                country_id: "",
                latitude: "",
                longitude: ""
                }];
            this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_CITIES_URL, "opr=1&state_id=" + stateId)
                .subscribe(data => {
                var responseData = data;
                if (responseData.result == "1") {
                    this.disableCities = false;
                    this.cities = cityDDLPlaceholder.concat(responseData.data);
                        for(var i in this.cities){
                            this.cities[i].id=this.cities[i].city_name;
                            this.cities[i].text=this.cities[i].city_name;          
                        }
                        if(!this.isFromZipCode){
                            let shopCity = this.shopDetails.city;
                            const city = this.cities.filter(city => city.city_name ==  shopCity);
                            if (city.length > 0) {
                                this.selectedCity = city[0].city_name;
                                this.addNewCustomer.controls.city.setValue(shopCity);
                            } else {
                                this.addNewCustomer.controls.city.setValue('');
                            }
                          }
                } else {
                    this.cities = [{id: "", text: "No city found"}];
                    this.disableCities = false;
                }
                 this.cdr.markForCheck();
            })
        }
    }

        /* FOR V2 SELECT */
    handleCountryDropdownChange(countryId) {
        this.getStates(countryId);
        let countryData = this.countries.find(countryInfo => countryId == countryInfo.id);
        this.addNewCustomer.controls.country.setValue(countryData.country_name);
        this.addNewCustomer.controls.state.setValue('');
        this.addNewCustomer.controls.city.setValue('');
        this.selectedState = '';
        this.selectedCity = '';
        this.getTimezone(countryId);
        this.addNewCustomer.get('timezone').setValidators([Validators.required]);
        this.addNewCustomer.get('timezone').updateValueAndValidity();
    }

    handleStateDropdownChange(stateId) {
        if (stateId !== '') {
            this.getCities(stateId);
            let stateData = this.states.find(stateInfo => stateId == stateInfo.id);
            this.addNewCustomer.controls.state.setValue(stateData.state_name);
            this.addNewCustomer.controls.city.setValue('');
            this.selectedCity = 'Select City';
        } else {
            this.addNewCustomer.controls.state.setValue('');
            this.addNewCustomer.controls.city.setValue('');
            this.selectedCity = 'Select City';
        }
    }

    handleCityDropdownChange(cityName) {
        if (cityName !== 'Select City') {
            this.addNewCustomer.controls.city.setValue(cityName);
        } else {
            this.addNewCustomer.controls.city.setValue('');
        }
    }
        

    /* animation */
    get showBusinessName() {
        return this.show ? 'show' : 'hide'
    }
    businessNameToggle() {
      this.show = !this.show;
    }
    
    showRequireApproval(event) {
        if (event.checked) {
            this.requireApprovalAmount = true;
            this.requireApprovalCheck = true;
            this.addNewCustomer.controls.require_approval.setValue(1);
        } else {
            this.requireApprovalAmount = false;
            this.requireApprovalCheck = false;
            this.addNewCustomer.controls.require_approval.setValue(0);
        }
    }

    isSubletWork(event) {
        if (event.checked) {
            this.isSubletWorkCheck = true;
            this.addNewCustomer.controls.is_sublet.setValue(1);
        } else {
            this.isSubletWorkCheck = false;
            this.addNewCustomer.controls.is_sublet.setValue(0);
        }
    }
    
    /* Animation */
    get checkApprovalAmount() {
        return this.requireApprovalAmount ? 'show' : 'hide'
    }
    
    /* Customer Type Validation */
    customerTypeValidation() {
        let cType = this.addNewCustomer.value.customer_type;
        if(cType !="") {
            let cTypeValue = this.addNewCustomer.value.customer_type.length >= 1 ? false : {required: true};
            if(cTypeValue == false) {
                this.addNewCustomer.controls.customer_type.setErrors(null);
            } else {
                this.addNewCustomer.controls.customer_type.setErrors({"required": true});
            }
        } else {
            this.addNewCustomer.controls.customer_type.setErrors({"required": true});
        }
    }
    /** customer timezone start */
    getSelectedOptionTimezone(event){
        if(event !== ''){
        this.addNewCustomer.controls.timezone.setValue(event);
        }else{
            this.addNewCustomer.controls.timezone.setValue(''); 
        }
    }
    /** customer timezone end */
    /** get all timezone start */
    getTimezone(Id){
        console.log("this is id, shop country",Id,this.shopCountry,this.shopTimezone,this.shopCountryId)
        let timeZoneDDLPlaceHolder =[{
            text: "Select Time Zone", 
            id: ""
        }]
        let data={country_id:Id ,user_type: this.currentUserInfo.user_type,user_id: this.currentUserInfo.id};
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_TIMEZONE,data).subscribe((zones:any)=>{
            if(zones.result=='1'){
                this.timezoneData=timeZoneDDLPlaceHolder.concat(zones.data);
                // handling if selected country is equal to shop country than set new customer timezone to shop timezone
                if (this.shopCountry) {
                    if (Id === this.shopCountryId) {
                        this.addNewCustomer.controls.timezone.setValue(this.shopTimezone);
                    }else{
                        this.addNewCustomer.controls.timezone.setValue('');
                    }
                }
            }else{
                this.timezoneData=[];
                this.addNewCustomer.controls.timezone.setValue('');
            }
        });      
    }
    /** get all timezone end */
    
    gotoAddVehicle(customerId){
        localStorage.setItem("shopCustomerID", customerId);
        localStorage.setItem("VinValues", "");
        localStorage.setItem("VinNumber", "");
        this.router.navigate(['shops/add-car']);
    }



    setCountryStateCityViaZipCode(){
        let currentLoginUser = JSON.parse(localStorage.getItem("loggedInUser"));
        let payload = {
             user_id: currentLoginUser.id,
             user_type: "User",
             zip_code: this.addNewCustomer.controls.zipcode.value
        }
        this.subscriptionDataForAPI =  this.globalService.callAPI(globalConstants.API_GET_STATE_CITY_VIA_ZIPCODE,payload).subscribe((response:any)=>{
            if(response.result == 1){
                if(response.data.country_info.country_id !== 0 
                    && response.data.state_info.state_id !== 0 
                    &&  response.data.city_info.city_id !== 0 ){
                    this.isFromZipCode = true;
                    this.selectedCountry = response.data.country_info.country_code;
                    let oldNewCustomerCountry = this.addNewCustomer.value.country;
                    this.addNewCustomer.controls.country.setValue(response.data.country_info.country_name);
                    this.getStates(response.data.country_info.country_code);
                    this.selectedState  = response.data.state_info.state_id;
                    this.addNewCustomer.controls.state.setValue(response.data.state_info.state_name);
                    this.getCities(response.data.state_info.state_id);
                    this.selectedCity = response.data.city_info.city_name;
                    this.addNewCustomer.controls.city.setValue(response.data.city_info.city_name);
                    // this.addNewCustomer.controls.timezone.setValue('');
                    if(oldNewCustomerCountry !== this.selectedCountry){
                        this.addNewCustomer.controls.timezone.setValue('');
                         this.getTimezone(response.data.country_info.country_code);
                         this.addNewCustomer.get('timezone').setValidators([Validators.required]);
                         this.addNewCustomer.get('timezone').updateValueAndValidity();
                    }
                }
            }
      })
      }

    validateNumbersWithDecimal(event,limit) {
        return globalFunctions.validateNumbersWithDecimal(event,limit);
    }
    ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
    }  
}