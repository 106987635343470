<div class="panel panel-primary">
  <div class="panel-heading padding-left-30">
    <h3 class="panel-title">Service Request Detail</h3>
    <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <div class="panel-body bgcolor-lightgrey">
    <div class="jumbotron padding-25 car-view-str">
      <div class="row">
        <div class="col-sm-6 col-md-6">
          <h3 class="margin-0">Service Request Information</h3>
        </div>
        <div class="col-sm-6 col-md-6">
          <a class="btn btn-success pull-right " id="downloadInvoice" *ngIf="serviceRequestData.value.request_status=='6' || serviceRequestData.value.request_status=='8'" name="downloadInvoice" target="_blank" href="{{getInvoicePdf()}}" title="">
            <i class="fa fa-download"></i>  Download Invoice
          </a>
        </div>
      </div>
      <hr>

      <form id="viewServiceRequestForm" class="form-horizontal" role="form">
        <!-- Replaced div grid structure with table -->
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Request ID:</label></div>
              <div class="col-sm-6">{{this.serviceRequestData.value.request_id}}</div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Shop Name :</label></div>
              <div class="col-sm-6 break-word">
                {{this.serviceRequestData.value.service_facility_name || this.serviceRequestData.value.company_name}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Vehicle:</label></div>
              <div class="col-sm-6">
                {{vehicleName}}

              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>License Plate :</label></div>
              <div class="col-sm-6 break-word text-uppercase">
                {{this.serviceRequestData.value.license_plate}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Title :</label></div>
              <div class="col-sm-6">
                {{this.serviceRequestData.value.title}}
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>VIN Number :</label></div>
              <div class="col-sm-6 break-word text-uppercase" *ngIf="this.serviceRequestData.value.vin">
                {{this.serviceRequestData.value.vin | slice:0:-8}}<span style="color:#eb1e4c">{{this.serviceRequestData.value.vin.substr(this.serviceRequestData.value.vin.length - 8)}}</span>
              </div>
              <div class="col-sm-6 break-word text-uppercase" *ngIf="this.serviceRequestData.value.vin==undefined || this.serviceRequestData.value.vin==''"> - </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Customer Name :</label></div>
              <div class="col-sm-6">
                {{this.serviceRequestData.value.first_name}} {{this.serviceRequestData.value.last_name}}
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Requested Date :</label></div>
              <div class="col-sm-6 break-word">
                {{this.serviceRequestData.value.requested_delivery_date}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Mobile Number :</label></div>
              <div class="col-sm-6">
                <a class="text-black" href="tel:{{this.serviceRequestData.value.mobile_phone}}">{{this.serviceRequestData.value.mobile_phone}}</a>
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Scheduled Date :</label></div>
              <div class="col-sm-6">
                {{this.serviceRequestData.value.schedule_service_date}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Present Mileage (Miles) :</label></div>
              <div class="col-sm-6">
                {{this.serviceRequestData.value.present_mileage}}
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Estimated Date :</label></div>
              <div class="col-sm-6">
                {{this.serviceRequestData.value.estimatedDeliveryDate}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 margin-bottom-10">
            <div class="row">
              <div class="col-sm-3"><label>Notes :</label></div>
              <div class="col-sm-9" [innerHtml]="getNltoBr(serviceRequestData.value.notes)">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 margin-bottom-10">
            <div class="row">
              <div class="col-sm-3"><label>Download Report :</label></div>
              <div class="col-sm-9">
                <span id="downloadReportUPSP" class="hidden">
                  <a href="javascript:void(0);">(USPS-PMI-PS-Form)<i class="fa fa-download fa-fw "></i></a>
                </span>
                <span id="downloadReportACDELCO" class="hidden">
                  <a href="javascript:void(0);">(CAR Inspection Report)<i class="fa fa-download fa-fw "></i></a>
                </span>
                <span id="noReportPerformed" class="">
                  No Report Performed
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 notifyToAdminContainer">
            <div class="alert alert-info disabled" *ngIf="serviceRequestData.value.request_status == 3">
              <!-- {{serviceRequestData.value.request_status}} -->
              Approve the recommendation(s) that need to be done to the service request and reject those which are not needed. Then click the below button to apply the changes and continue further.
              <br>
              <div class="btn btn-success notify-to-admin" (click)="finalUpdateToNotifyAdmin(serviceRequestData.value.request_id)">
                Apply Changes
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" *ngIf="serviceRequestData.value.request_documents!=null || serviceRequestData.value.request_images!=null">
            <h4><strong>Documents &amp; Images</strong></h4>
            <div class="row">
              <div class="col-sm-6">
                <div class="row" *ngIf="serviceRequestData.value.request_documents!=null">
                  <div class="col-sm-12 margin-bottom-5"><label>Document(s)</label></div>
                  <div class="col-sm-12 text-danger">
                    <div id="serviceRequestDocumentsView">
                      <ul class="list-inline margin-bottom-0">
                        <li class="relative view-images" *ngFor="let document of serviceRequestData.value.request_documents">
                      <a style="display:inline-block;margin:0px 5px 5px 0px;position:relative;" target="_blank" href="{{globalConstants.S3_BUCKET_URL}}Service-Request-Documents/{{document.document_name}}" class="thumbnail">
                        <img style="width:80px;height:75px;" src="assets/img/adobe_pdf-logo.jpg">
                      </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="serviceRequestData.value.request_documents.length == 0 || serviceRequestData.value.request_documents==null">
                    <div class="col-sm-12 margin-bottom-5"><label> </label></div>
                    <div class="col-sm-12 text-danger"> No documents uploaded.</div>
                </div>
              </div>
              <div class="col-sm-6" >
                <div class="row" *ngIf="serviceRequestData.value.request_images!=null">
                  <div class="col-sm-12 margin-bottom-5"><label>Image(s)</label></div>
                  <div class="col-sm-12" >

                    <div id="serviceRequestPicturesView">
                        <ul class="list-inline margin-bottom-0" >
                            <li  class="relative view-images" *ngFor="let image of serviceRequestData.value.request_images;let i=index;">
                        <a href="javascript:void(0);" (click)="imageToolDialog($event,i,8,serviceRequestData.value.request_images)" class="thumbnail">
                        <img style="width:100px;height:75px;" src="{{globalConstants.S3_BUCKET_URL}}Service-Request-Pictures/thumb/{{image.image_name}}">
                      </a>
                            </li>
                        </ul>
                  </div>

                  </div>
                </div>
                <div class="row" *ngIf="serviceRequestData.value.request_images.length == 0 || serviceRequestData.value.request_images==null">
                    <div class="col-sm-12 margin-bottom-5"><label> </label></div>
                    <div class="col-sm-12 text-danger"> No images uploaded.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="documents-accordion relative">
    <mat-accordion class="new-request-expension view-request margin-bottom-10 display-full" displayMode="flat" hideToggle="false">
      <mat-expansion-panel [expanded]="a==0" hideToggle="true"  *ngFor="let service_request of serviceRequestData.value.service_request_list;let a=index" id="expansion-{{a+1}}" >
        <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="expansionHead-{{a+1}}" (click)="expansionOpen(a+1);" [attr.data-state]="a==0">
          {{a+1}}. {{ service_request.name }}
        </mat-expansion-panel-header>
        <ul class="service-list-items">
          <li class="topPad" *ngFor="let servicerequestChild of service_request.children;let i = index">
            <p>{{i+1}}) {{servicerequestChild.name}}</p>
            <ul class="service-list-sub-items no-style" >
              <!-- <li></li> -->
              <li class="topPad" *ngFor="let secondChild of servicerequestChild.children;let j = index">
                {{j+1}}. {{secondChild.name}}
                <p class="service-list-sub-items" *ngIf="secondChild.input == 'textarea'">
                  <span style="padding-left: 58px;" class="display-full" [innerHtml]="getNltoBr(secondChild.value)">
                  </span>
                </p>
                <p class="service-list-sub-items" *ngIf="secondChild.input == 'imageupload'">
                  <span style="padding-left: 58px;">
                    <img src="{{globalConstants.S3_BUCKET_URL}}service-pictures/thumb/{{secondChild.value}} "/>
                  </span>
                </p>
                <!-- <div class="row" *ngIf="serviceRequestData.value.notify_to_customer == '1'">
                 </div> -->
               <!-- <li class="topPad">Comments<p>bb</p></li>  -->
              </li>
            </ul>
          </li>
        </ul>
        <div class="panel-price" *ngIf="ConvertToNumber(serviceRequestData.value.request_status) >=3">

        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
    <div class="final-calculation">
      <!-- <p ><b>Supply Charges</b> : ${{serviceRequestData.value.supplies_charges}}</p> -->

      <p><b>Total Paid</b> : ${{serviceRequestData.value.total_paid == null ? "0.00" : serviceRequestData.value.total_paid}}</p>
    </div>
  </div>
  <div class="notifyToAdminContainer" *ngIf="serviceRequestData.value.request_status == 3">
    <div class="btn-lg alert-info disabled">Approve the recommendation(s) that need to be done to the service request and reject those which are not needed. Then click the below button to apply the changes and continue further.<br><div class="btn btn-success notify-to-admin" (click)="finalUpdateToNotifyAdmin(serviceRequestData.value.request_id)">Apply Changes</div></div>
  </div>
  <div style="position:relative;" *ngIf="serviceRequestData.value.request_status == 3">
    <p class="alert-info" style="padding:17px 15px;border-radius:5px;border:1px solid #9acfea;" id="disclaimer">{{serviceRequestData.value.disclaimer}}</p>
  </div>
</div>
