import { Component, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { globalFunctions } from '../../../../environments/globalFunctions';
import {Http, Headers, Response} from '@angular/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';

@Component({
  selector: 'app-add-mileage-dialog',
  templateUrl: './add-mileage-dialog.component.html',
  styleUrls: ['./add-mileage-dialog.component.scss']
})
export class AddMileageDialogComponent {
    public addMileageForm: FormGroup;
    public request_id;
    public distance_covered;
    public addMileageResponse;
    public actionType;
    public currentUser;
    constructor( @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AddMileageDialogComponent>, private formBuilder: FormBuilder, private globalService: GlobalService, private http: Http, private router: Router, public cdr: ChangeDetectorRef) {
        this.request_id = data.request_id;
        this.distance_covered = data.distance_covered !='' ? data.distance_covered : '0';
        this.actionType = data.action_type; /* if 1 then uder process if then fill recomendation */
        this.currentUser = JSON.parse(localStorage.getItem("loggedInUser"));
        this.addMileageForm = this.formBuilder.group({
            present_mileage: ['', [Validators.required,Validators.pattern(/^\d+(\.\d)?$/)]],
            request_id: [this.request_id],
            user_id:[this.currentUser.id]
        });
        this.globalService.diaLogObj(this.dialogRef);
    }

    ngOnInit() {
    }
    
    onlyNumericKey(event) {
      return globalFunctions.onlyDecimalNumberKey(event);
    }
    
    
    addMileage() {
        Object.keys(this.addMileageForm.controls).map((controlName) => {
            this.addMileageForm.get(controlName).markAsTouched({onlySelf: true});
        });
        
        if (this.addMileageForm.valid) {
            this.addMileageForm.controls.request_id.setValue(this.request_id);
            var stringifiedFormData = globalFunctions.stringifyFormData(this.addMileageForm.value, null);
            this.globalService.callAPI(globalConstants.API_UPDATE_PRESENT_MILEAGE_URL, stringifiedFormData).subscribe((data:any) => {
                var message = data.message;
                if(data.result == "1") {
                    this.addMileageResponse = data.data;
                    this.dialogRef['addMileage']=true;
                    this.dialogRef['actionType']=this.actionType;
                    this.dialogRef.close(1);
                    this.globalService.snackbar("success", message);
                    this.cdr.markForCheck();
                    // if(this.actionType == 2) { 
                    //     this.router.navigate(["/shops/service-inspection-report/" + this.request_id]);
                    // }
                } else {
                    Object.keys(this.addMileageForm.controls).map((controlName) => {
                        this.addMileageForm.get(controlName).reset();
                    });
                    this.globalService.snackbar("error", message);
                }
            });
            
        } 
    }
}
