import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { globalFunctions } from '../../../../environments/globalFunctions';

@Component({
  selector: 'app-qqsupport',
  templateUrl: './qqsupport.component.html',
  styleUrls: ['./qqsupport.component.scss']
})
export class QqsupportComponent implements OnInit {

  public param: any = null
  public user: any = null
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<QqsupportComponent>, public gs: GlobalService) {
    this.param = data
    this.user = this.gs.getCurrentUser()
    this.gs.diaLogObj(this.dialogRef);
  }

  ngOnInit() {
  }
  /** open live chat window */
  supportRequest(){
    globalFunctions.supportChat()
  }

}
