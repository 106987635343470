<div class="popup-sidebar-wrapper">
  <mat-sidenav-container class="side-panel-wrappers ticket-assignment" autosize end>
      <mat-sidenav #drawer opened mode="side" class="ticket-sidenav" mode="over" position="end">
          <h4>Adjust Working Hour</h4>
         <div class="border-box">
          <div class="btn-block header">
            <ul class="padding-0 margin-0" style="float: right;">
                <li>
                    <button type="button" class="btn skip-btn" (click)="updateUserAdjustHour()" title="Update"><i class="fa fa-check" aria-hidden="true"></i> </button>
                </li>
                <li>
                    <button type="button" class="btn skip-btn" (click)="closeSideBar()" title="Close"><i class="fa fa-times" aria-hidden="true"></i></button>
                </li>
            </ul>
        </div>
        <!-- <div class="cross-btn">
            <button type="button" (click)="closeSideBar()">
                <div class="desktop-screen"> -->
                      <!-- <img src="assets/img/sidebar-icon.png"> -->
                    <!-- x
                </div>
            </button>
        </div> -->
          <div class="assignment-block updatehours">
              <div class="vehicle-detail">
                  <div class="row">
                      <div class="col-sm-12 margin-bottom-5">
                          <strong>Service Request Information</strong>
                      </div>
                      <div class="col-sm-12">
                          <label>Hours Worked : </label>
                          <span *ngIf="prevHour != 0 || prevMin != 0">
                            <span *ngIf="prevHour != 0">{{prevHour}} {{(prevHour==0 || prevHour==1)?'Hr':'Hrs'}}</span>
                            <span *ngIf="prevMin != 0"> {{prevMin}} {{(prevMin==0 || prevMin==1)?'Min':'Mins'}}</span>
                          </span>
                      </div>
                      <div class="col-sm-12">
                          <label>Adjustment Type : </label>
                          {{requestdata.adjustment_type == 1?'Add':requestdata.adjustment_type == 2?'Subtract':'NA'}}
                      </div>
                      <div class="col-sm-12">
                        <label>Adjusted Hours : </label>
                        <span *ngIf="workHour == 0 && workMin == 0">NA</span>
                        <span *ngIf="workHour != 0 || workMin != 0">
                          <span *ngIf="workHour != 0">{{workHour}} {{(workHour==0 || workHour==1)?'Hr':'Hrs'}}</span>
                          <span *ngIf="workMin != 0"> {{workMin}} {{(workMin==0 || workMin==1)?'Min':'Mins'}}</span>
                        </span>
                    </div>
                  </div>
                </div>
                <form class="form-horizontal form-control-normal" autocomplete="off" [formGroup]="timeForm" novalidate>
                  <div class="display-full margin-top-15">
                    <div class="col-md-6 margin-top-10 date-block padding-left-0"><strong>Adjustment Type :</strong></div>
                    <div class="col-md-6 uc-ngx-select">
                      <ngx-select class="display-full" [items]="actionSlots" placeholder="Adjustment Type" optionTextField="text" formControlName="adjustment_type" (select)="updateValue()">
                      </ngx-select>
                      <mat-error *ngIf="submitted && timeForm.controls.adjustment_type.hasError('required')">
                        Select Adjustment Type
                      </mat-error> 
                    </div>
                  </div>
                  <div class="display-full margin-top-15">
                    <div class="col-md-6 margin-top-10 date-block padding-left-0"><strong>Adjusted Hours :</strong></div>
                    <div class="col-md-6 uc-ngx-select">
                      <ngx-select class="display-full" [items]="hourSlots" placeholder="Hours" optionTextField="text" formControlName="updatedHours" [disabled]="timeForm.controls.adjustment_type.value === '2' && prevHour === 0">
                      </ngx-select>
                      <mat-error *ngIf="submitted && timeForm.controls.updatedHours.hasError('required')">
                        Select Hours
                      </mat-error>
                    </div>
                  </div>
                  <div class="display-full margin-top-15">
                    <div class="col-md-6 margin-top-10 date-block padding-left-0"><strong>Adjusted Mins :</strong></div>
                    <div class="col-md-6 uc-ngx-select">
                      <ngx-select class="display-full" [items]="minSlots" placeholder="Minutes" optionTextField="text" formControlName="updatedMins">
                      </ngx-select>
                      <mat-error *ngIf="submitted && timeForm.controls.updatedMins.hasError('required')">
                        Select Minutes
                      </mat-error>
                    </div>
                  </div>
                  <div class="display-full margin-top-15">
                    <div class="col-md-6 margin-top-10 date-block padding-left-0"><strong>Comment :</strong></div>
                  </div>
                  <div class="display-full margin-top-5">
                    <div class="col-md-12 details-input">
                      <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                        <textarea matInput class="form-control input-lg"  formControlName="comment" placeholder="Add Comment" style="height: 100px;"></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </form>
          </div>
          <div class="footer">
              <div class="row">
                  <div class="col-sm-4 col-xs-12"></div>
                  <div class="col-sm-8 col-xs-12">
                      <div class="btn-block">
                          <ul>
                              <li>
                                  <button type="button" class="btn list-action-btn mat-button" (click)="updateUserAdjustHour()">Update</button>
                              </li>
                              <li>
                                <button type="button" class="btn cancle" (click)="closeSideBar()">Cancel</button>
                            </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
         </div>
      </mat-sidenav>
  </mat-sidenav-container>
</div>
