import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
import { ViktableComponent, VikSource } from '../../../admin/viktable/viktable.component';
import { QqviewComponent } from '../qqview/qqview.component';
import { MatTableDataSource } from '@angular/material/table';
const QQ_PREVIOUS_LIST = "shop-prequickquote-";

@Component({
  selector: 'app-qqprevious',
  templateUrl: './qqprevious.component.html',
  styleUrls: ['./qqprevious.component.scss']
})
export class QqpreviousComponent extends ViktableComponent implements OnInit {

  @Input() customer: any;
  @Input() seletedCar: any;
  @Input() editMode:any;
  public user:any=null
  dataSource: any;
  public tablesData = this;
  public checkFormEmpty=false;
  public searchEnabled : boolean = false;
  public displayedColumns=['ID','Jobs','Total','Created By','Creation Date','Actions']
  public editId:any=null
  public showCostPrice=false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(public gs: GlobalService, public router: Router,public dialog: MatDialog) {
    super(globalConstants.API_previous_QQ, gs);
    this.user=this.gs.getCurrentUser()
    if (this.user) {
      this.sessionKey = QQ_PREVIOUS_LIST;
      this.url = globalConstants.API_previous_QQ;
    } else {
      this.router.navigate(["/shops"]);
    }
   }

   ngOnInit() {
    this.gs.setMetaData("SHOPS", "QUICK_QUOTE_LIST");
    if(this.editMode && typeof this.editMode.id !='undefined'){
      this.exceptId=this.editMode.id
    }
    this.showCostPrice=this.gs.getAcl('display_part_cost','views');
    this.reInitOptions()
   
   }

   ngAfterViewInit() {
    setTimeout(() => {
      this.setDataTable();
    },0)
  
  }
   reInitOptions(){
    this.options.user_type = this.user.user_type;
    this.options.user_id = this.user.id;
    this.options.customer_id=(this.customer ? this.customer.id : "");
    this.options.vehicle_id=this.seletedCar.id
    this.options.limit=25
    this.options.quote_id=(this.exceptId ? this.exceptId :'')
   }
   setDataTable() {
    console.log("this is table",this.tablesData)
    this.dataSource = new VikSource(this.tablesData, this.sort, this.paginator);
  }
  createRecordrecord(record) {
      return {
        id: record.id,
        jobs: this.getLevelName(record.service_jobs),
        total: record.grand_total,
        name: record.user_name,
        added_at: this.convertDate(record.add_date),
        modified_at: this.convertDate(record.modify_date)
      };
  }
  getLevelName(jobs){
    let name=''
    if(typeof jobs !='undefined' && jobs.length){
      jobs.forEach((e) => {
        if(name){
          name=name+', '+e.name
        }else{
          name=e.name
        }
      });
    }
    return name
  }
  public convertDate(date){
    return this.gs.getFullFormateDate(date,'G','H');
  }

  openPrevious(row){
    row.qq=false
    let dialogRef: any = this.dialog.open(QqviewComponent, {
      panelClass: ['quickqouotedialog', 'quickqouotview'],
      width: "100%",
      data: row
    });
  }

}

export interface quickReviousData {
  id: any;
  jobs: any;
  total:any;
  name:any;
  added_at: any;
}
