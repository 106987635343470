<div mat-dialog-title class="relative">
  Matrix Range
  <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
    <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>
<form class="form-horizontal form-control-normal" autocomplete="off" [formGroup]="ranges">
  <mat-dialog-content class="customer-detail-popup">
    <div class="center-block" style="max-width: 600px;">
      <div class="display-full margin-bottom-5">
        <label class="col-sm-3 control-label">
          <strong> Start</strong>
        </label>
        <div class="col-sm-9">
          <div class="relative">
            <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
              <input matInput type="text" [textMask]="{mask: rangeMask}" placeholder="Start" formControlName="margin_start"
                 (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)"
                class="form-control input-lg custom-form-input btn-block" required readonly />
              <mat-error *ngIf="ranges.controls.margin_start.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="display-full margin-bottom-5">
        <label class="col-sm-3 control-label">
          <strong> End<span class="asterisk">*</span></strong>
        </label>
        <div class="col-sm-9">
          <div class="relative">
            <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
              <input matInput type="text" [textMask]="{mask: rangeMask}" placeholder="End" formControlName="margin_end"
                (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)"
                (keyup)="vkFloat($event)" class="form-control input-lg custom-form-input btn-block" required />
              <mat-error *ngIf="ranges.controls.margin_end.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="display-full margin-bottom-5">
        <label class="col-sm-3 control-label">
          <strong> Markup<span class="asterisk">*</span></strong>
        </label>
        <div class="col-sm-9">
          <div class="relative">
            <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
              <input matInput type="text" [textMask]="{mask: markupMask}" placeholder="Markup" formControlName="markup"
                (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)" (keyup)="vkFloat($event)"
                class="form-control input-lg custom-form-input btn-block" required />
              <mat-error *ngIf="ranges.controls.markup.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="inquiry-action relative">
    <div class="flexBtns">
      <button mat-raised-button color="accent" (click)="submit()" class="font-bold">Submit</button>
      <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
    </div>
  </mat-dialog-actions>
</form>