<div mat-dialog-title class="relative m-0 appointment-title">
  <span *ngIf="isAppointment">Appointment</span>
  <span *ngIf="!isAppointment">Tech Assignment</span>
  <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
    <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="customer-detail-popup m-0">

  <mat-tab-group #tabNavbar indexToSelect="1" [selectedIndex]="(param.tab-1)">

    <!-- Appintment Deatils -->
    <mat-tab label="Appointment Details" class="font-bold" *ngIf="isAppointment">
      <ng-template mat-tab-label>
        <strong>Appointment Details</strong>
      </ng-template>
      <div class="padding-15">
        <div class="row margin-bottom-10">
          <div class="col-sm-5">
            <label>Request ID</label>
          </div>
          <div class="col-sm-7">
            <!-- <span class="text-uppercase tooltip-status" [tooltip]="myTooltip1" 
            (click)="NavigateTo();" tooltipPlacement="left"
              *ngIf="param.dialogMetaData.service_request_id" style="display: inline-block;"> -->
              <span class="text-uppercase tooltip-status" 
            (click)="NavigateTo();" tooltipPlacement="left"
              *ngIf="param.dialogMetaData.service_request_id" style="display: inline-block;">
              <span *ngIf="param.dialogMetaData.service_request_id">
                <span style="color:#eb1e4c">{{param.dialogMetaData.service_request_id}}</span>
              </span>
              </span>
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-5">
            <label>Title &nbsp;<button type="button" class="tooltip-status text-red fa fa-pencil" *ngIf="(param.dialogMetaData.request_status != 10)  && isAllowed('service_request','edits')" [disabled]="(param.dialogMetaData.request_status >= 6)" (click)="SrUpdate('title')" style="float: right;" title="Edit title"></button></label>
          </div>
          <div class="col-sm-7" [innerHTML]="param.dialogMetaData.service_request_title ? param.dialogMetaData.service_request_title : '-' "></div>
        </div>
        <div class="row margin-bottom-10">
          <div class="col-sm-5">
            <label>Appt. Date &nbsp;<button type="button" class="tooltip-status text-red fa fa-pencil"  *ngIf="(param.dialogMetaData.request_status == 11 || param.dialogMetaData.request_status == 1) && isAllowed('service_request','edits')" [disabled]="(param.dialogMetaData.request_status >= 6)"  (click)="SrUpdate('appt_date')" style="float: right;" title="Edit Requested date"></button></label>
          </div>
          <div class="col-sm-7" [innerHTML]="param.dialogMetaData.requested_delivery_date ? param.dialogMetaData.requested_delivery_date : '-' "></div>
        </div>
        <div class="row margin-bottom-10">
          <div class="col-sm-5">
            <label>Scheduled Date &nbsp;<button type="button" class="tooltip-status text-red fa fa-pencil" *ngIf="(param.dialogMetaData.request_status != 10) && isAllowed('service_request','edits')" [disabled]="(param.dialogMetaData.request_status >= 6)" (click)="SrUpdate('scheduled_date')" style="float: right;" title="Edit Scheduled date"></button></label>
          </div>
          <div class="col-sm-7" [innerHTML]="param.dialogMetaData.schedule_service_date ? param.dialogMetaData.schedule_service_date : '-' "></div>
        </div>
        <div class="row margin-bottom-10">
          <div class="col-sm-5">
            <label>Est. Service Time &nbsp;<button type="button" class="tooltip-status text-red fa fa-pencil" *ngIf="(param.dialogMetaData.request_status != 10) && isAllowed('service_request','edits')" [disabled]="(param.dialogMetaData.request_status >= 6)"  (click)="SrUpdate('est_hours')" style="float: right;" title="Edit Est. Service Time"></button></label>
          </div>
          <div class="col-sm-7" [innerHTML]="param.dialogMetaData.est_hours ? param.dialogMetaData.est_hours+' hour' : '-' "></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-5">
            <label>Status </label>
          </div>
          <div class="col-sm-7">
            <mat-chip-list class="mat-badge">
              <mat-chip class="cursor-pointer mat-chip-selected {{ getStatusColor() }}" selected="true">               
               <span> {{getStatus()}}</span>
              </mat-chip>
          </mat-chip-list>
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-5">
            <label>Labels &nbsp;<button type="button" class="tooltip-status text-red fa fa-pencil" *ngIf="(param.dialogMetaData.request_status != 10)" [disabled]="(param.dialogMetaData.request_status >= 6)"  (click)="manageLabels()" style="float: right;" title="Manage Labels"></button></label>
          </div>
          <div class="col-sm-7">
            <ngx-select [autoClearSearch]="true" class="label-box display-full margin-bottom-20"
            [defaultValue]="preSelectLabel"
            [multiple]="true" 
            [items]="allLabels"
            (select)="selectedLabel($event)"
            (remove)="removeLabel($event)"
            optionTextField="label_name"
            [disabled]="(param.dialogMetaData.request_status >= 6)"
            placeholder="Select Label">
          </ngx-select>
          </div>
        </div>
        <!-- Ticket Assignment -->
        <div class="row margin-bottom-10" *ngIf="isVisible(param.dialogMetaData.request_status) && skipTicketAssign && isEmployee && (param.dialogMetaData.request_status != 10)">
          <div class="col-sm-12">
            <!-- <p><label><i class="fa fa-ticket text-red"></i>&nbsp;&nbsp;<a style="cursor: pointer;" class="pencel-btn" (click)="techAssignment(param.dialogMetaData)">Ticket Assignment</a></label></p> -->
            <p><label><i class="fa fa-user text-red"></i>&nbsp;&nbsp;<a style="cursor: pointer;" class="pencel-btn" (click)="viewAssignee(param.dialogMetaData.request_id)">View Assignee</a></label></p>
          </div>
        </div>
        <!-- View Assignment -->
        <!-- <div class="row margin-bottom-10" *ngIf="isVisible(param.dialogMetaData.request_status) && skipTicketAssign && isEmployee && (param.dialogMetaData.request_status != 10) && menuEnable(param.dialogMetaData.is_assign)">
          <div class="col-sm-12">
            <label><a style="cursor: pointer;" (click)="viewAssignee(row.id)">View Assignee &nbsp;&nbsp;<i class="fa fa-ticket"></i></a></label>
            </div>
        </div> -->
      </div>

      <!-- <hr style="margin-top: 0;"> -->
      <div class="padding-15" style="padding-top: 0;" *ngIf="logs?.length > 0">
        <!-- repeat user logs start -->
        <div class="row"><div class="col-md-6"><label>SR Logs</label></div></div>
        <div class="display-full user logwrap" *ngFor="let log of logs">
          <div class="user-content">
            <em>
              <span [innerHTML]="log.remarks"></span> <span *ngIf="log.assignee_first_name!=''"> - <small>{{log.assignee_first_name}} {{log.assignee_last_name}} <i>({{getRoles(log.assignee_designation)}})</i></small></span>
              <span>on <strong>{{formatDate(log.add_date)}}</strong></span><span *ngIf="log.user_type!='Customer'">&nbsp;<strong>({{log.first_name}} {{log.last_name}}</strong>)</span> 
            </em>
          </div>
        </div>
        <!-- repeat user logs end -->
      </div>
    </mat-tab>

    <!-- Tech Assignment -->
    <mat-tab label="Tech Assigne Details" class="font-bold" *ngIf="!isAppointment">
      <ng-template mat-tab-label>
        <strong>Tech Assignment Details</strong>
      </ng-template>
      <div class="padding-15">
        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Request ID</label>
          </div>
          <div class="col-sm-6">
            <!-- <span class="text-uppercase tooltip-status" [tooltip]="myTooltip1" 
            (click)="NavigateTo();" tooltipPlacement="left"
              *ngIf="param.dialogMetaData.service_request_id" style="display: inline-block;"> -->
              <span class="text-uppercase tooltip-status" 
              (click)="NavigateTo();" tooltipPlacement="left"
                *ngIf="param.dialogMetaData.service_request_id" style="display: inline-block;">
              <span *ngIf="param.dialogMetaData.service_request_id">
                <span style="color:#eb1e4c">{{param.dialogMetaData.service_request_id}}</span>
              </span>
              </span>
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
          <label>Assigned employee</label>
          </div>
          <div class="col-md-6">
            {{param.dialogMetaData.emp_fname + ' ' + param.dialogMetaData.emp_lname}}
          </div>
          
          <!-- &nbsp;&nbsp;&nbsp;<a href="javascript:;" style="color:#eb1e4c" (click)="viewAssignee(param.dialogMetaData.req_id)"><i class="fa fa-ticket"></i>View Assignee</a> -->
          <!-- <div class="col-md-6">
            <div *ngFor="let tech of techinianStatus;let i=index" class="emp-tag">
              {{tech.first_name}} {{tech.last_name}}
            </div>
          </div> -->
          
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Title &nbsp;<span class="tooltip-status text-red fa fa-pencil"  *ngIf="(param.dialogMetaData.request_status != 10)  && isAllowed('service_request','edits')" (click)="SrUpdate('title')" style="float: right;" title="Edit title"></span></label>
          </div>
          <div class="col-sm-6" [innerHTML]="param.dialogMetaData.service_request_title ? param.dialogMetaData.service_request_title : '-' "></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Start Date &nbsp;<span class="tooltip-status text-red fa fa-pencil" *ngIf="(param.dialogMetaData.request_status != 10)  && isAllowed('service_request','edits') && param.dialogMetaData.completion_status == '0'"  (click)="adjustTiming()" style="float: right;" title="Edit start date"></span></label>
          </div>
          <div class="col-sm-6" [innerHTML]="param.dialogMetaData.start_date ? param.dialogMetaData.start_date : '-' "></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>End Date &nbsp;<span class="tooltip-status text-red fa fa-pencil" *ngIf="(param.dialogMetaData.request_status != 10)  && isAllowed('service_request','edits') && param.dialogMetaData.completion_status == '0'"  (click)="adjustTiming()" style="float: right;" title="Edit end date"></span></label>
          </div>
          <div class="col-sm-6" [innerHTML]="param.dialogMetaData.end_date ? param.dialogMetaData.end_date : '-' "></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Status</label>
          </div>
          <div class="col-sm-6">
            <mat-chip-list class="mat-badge">
              <mat-chip [color]="param.dialogMetaData.completion_status == '3' ? 'success' : param.dialogMetaData.completion_status == '1' ? 'accent' : 'primary'" selected="true">              
                <span > {{ techStatus[param.dialogMetaData.completion_status] }}</span>
              </mat-chip>
          </mat-chip-list>
          </div>
        </div>

      </div>

      <!-- <hr style="margin-top: 0;"> -->
      <div class="padding-15" style="padding-top: 0;" *ngIf="logs?.length > 0">
        <!-- repeat user logs start -->
        <div class="row"><div class="col-md-6"><label>SR Logs</label></div></div>
        <div class="display-full user logwrap" *ngFor="let log of logs">
          <div class="user-content">
            <em>
              <span [innerHTML]="log.remarks"></span> <span *ngIf="log.assignee_first_name!=''"> - <small>{{log.assignee_first_name}} {{log.assignee_last_name}} <i>({{getRoles(log.assignee_designation)}})</i></small></span>
              <span>on <strong>{{formatDate(log.add_date)}}</strong></span><span *ngIf="log.user_type!='Customer'">&nbsp;<strong>({{log.first_name}} {{log.last_name}}</strong>)</span> 
            </em>
          </div>
        </div>
        <!-- repeat user logs end -->
      </div>

    </mat-tab>
    
    <mat-tab label="Vehicle Details" class="font-bold">
      <ng-template mat-tab-label>
        <strong>Vehicle Details</strong>
      </ng-template>
      <div class="padding-15">
        <div class="row" *ngIf="carDetail && carDetail.customer_id == customer_id">
          <span class="tooltip-status text-red fa fa-pencil" style="float: right;" title="Edit Vehicle" (click)="carEdit()"></span>
        </div>
        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Make</label>
          </div>
          <div class="col-sm-6" [innerHTML]="carDetail.make ? carDetail.make : '-' "></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Year</label>
          </div>
          <div class="col-sm-6" [innerHTML]="carDetail.year ? carDetail.year : '-' "></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Model</label>
          </div>
          <div class="col-sm-6" [innerHTML]="carDetail.model ? carDetail.model : '-' "></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Engine Size</label>
          </div>
          <div class="col-sm-6" [innerHTML]="(carDetail.sub_model ? carDetail.sub_model : '-')"></div>
        </div>


        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Vin</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail.vin) ? carDetail.vin : '-')"></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Production Date</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail.production_date) ? carDetail.production_date : '-')"></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Mileage</label>
          </div>
          <div class="col-sm-6" [innerHTML]="(carDetail.distance_covered ? carDetail.distance_covered : '0')"></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>License Plate</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail.license_plate) ? carDetail.license_plate : '-')"></div>
        </div>


        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>State</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail.state) ? carDetail.state : '-')"></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Color</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail.color) ? carDetail.color : '-')"></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Unit Number</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail.unit_number) ? carDetail.unit_number : '-')"></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Driver</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail.driver) ? carDetail.driver : '-')"></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Driver Phone Number</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail.driver_phone_number) ? carDetail.driver_phone_number : '-')">
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Insurance Expiration Date</label>
          </div>
          <div class="col-sm-6" [innerHTML]="(gs.getFullFormateDate(carDetail.insurance_expiration_date,'GD','H'))">
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Registration Expiration Date</label>
          </div>
          <div class="col-sm-6" [innerHTML]="(gs.getFullFormateDate(carDetail.registration_expiration_date,'GD','H'))">
          </div>
        </div>
        <div class="row margin-bottom-10" *ngIf="(gs.getFullFormateDate(carDetail.inspection_expiration_date,'GD','H'))!='-'">
          <div class="col-sm-6">
            <label>Inspection Expiration Date</label>
          </div>
          <div class="col-sm-6" [innerHTML]="(gs.getFullFormateDate(carDetail.inspection_expiration_date,'GD','H'))">
          </div>
        </div>
        <div class="row margin-bottom-10" *ngIf="carDetail && carDetail.transmission">
          <div class="col-sm-6">
            <label>Transmission</label>
          </div>
          <div class="col-sm-6"
            [innerHTML]="((carDetail && carDetail.transmission ) ? (carDetail.transmission ) : '-')"></div>
        </div>
        <div class="row margin-bottom-10" *ngIf="carDetail && carDetail.brake">
          <div class="col-sm-6">
            <label>Brake</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail && carDetail.brake ) ? (carDetail.brake ) : '-')"></div>
        </div>
        <div class="row margin-bottom-10" *ngIf="carDetail && carDetail.driveline">
          <div class="col-sm-6">
            <label>Driveline</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail && carDetail.driveline ) ? (carDetail.driveline ) : '-')">
          </div>
        </div>
        <div class="row margin-bottom-10" *ngIf="carDetail && carDetail.gvwr">
          <div class="col-sm-6">
            <label>GVWR</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail && carDetail.gvwr ) ? (carDetail.gvwr ) : '-')"></div>
        </div>
        <div class="row margin-bottom-10" *ngIf="carDetail && carDetail.wheel_base">
          <div class="col-sm-6">
            <label>Wheel Base</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail && carDetail.wheel_base ) ? (carDetail.wheel_base ) : '-')">
          </div>
        </div>
        <div class="row margin-bottom-10" *ngIf="carDetail && carDetail.ttire_size">
          <div class="col-sm-6">
            <label>Tire Size</label>
          </div>
          <div class="col-sm-6" [innerHTML]="((carDetail && carDetail.tire_size ) ? (carDetail.tire_size ) : '-')">
          </div>
        </div>
        <div class="row margin-bottom-10" *ngIf="carDetail && carDetail.wheel_lock_location">
          <div class="col-sm-6">
            <label>Wheel Lock Location</label>
          </div>
          <div class="col-sm-6"
            [innerHTML]="((carDetail && carDetail.wheel_lock_location ) ? (carDetail.wheel_lock_location ) : '-')">
          </div>
        </div>
        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>California Emissions</label>
          </div>
          <div class="col-sm-6">
            <p class="pull-left" *ngIf="carDetail.california_emissions!=1"
              [innerHTML]="( carDetail.california_emissions==1 ? 'Yes' : 'No')"></p>
            <ul id="vehicle_picul" class="list-inline margin-bottom-0" *ngIf="carDetail.california_emissions==1">
              <li class="relative view-images">
                <a href="javascript:void(0);" class="thumbnail">
                  <img class="show-image" title="California Emissions Standards"
                    src="assets/img/california-emissions.PNG" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row margin-bottom-10" *ngFor="let f of carDetail.custom_fields" [class.hide]="(f.value ? false : true )">
          <div class="col-sm-6">
            <label>{{f.label}}</label>
          </div>
          <div class="col-sm-6" [innerHTML]="getFieldVal(f)"></div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Vehicle Registration Photo</label>
          </div>
          <div class="col-sm-6">
            <div class="pull-left" id="vehicleRegPicView">
              <ul id="vehicle_pic-ul" class="list-inline margin-bottom-0">
                <li class="relative view-images" *ngFor="let item of carDetail.vehicle_registered_pic let i = index">
                  <span class="thumbnail" (click)="imageToolDialog($event,i,3,carDetail.vehicle_registered_pic)">
                    <img class="show-image" src="{{registeredPicture}}{{item.vehicle_registered_pic}}" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Vehicle Insurance Card Photo</label>
          </div>
          <div class="col-sm-6">
            <div class="pull-left" id="vehicleInsPicView">
              <ul id="vehicle_insured_pic-ul" class="list-inline margin-bottom-0">
                <li class="relative view-images" *ngFor="let item of carDetail.vehicle_insured_pic let i = index">
                  <span class="thumbnail" (click)="imageToolDialog($event,i,2,carDetail.vehicle_insured_pic)">
                    <img class="show-image" src="{{insurance}}{{item.vehicle_insured_pic}}">
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Vehicle Photo(s)</label>
          </div>
          <div class="col-sm-6">
            <div class="pull-left" id="vehiclePicView">
              <ul id="vehicle_pic-ul" class="list-inline margin-bottom-0">
                <li class="relative view-images" *ngFor="let item of carDetail.vehicle_pic let i = index">
                  <span class="thumbnail" (click)="imageToolDialog($event,i,1,carDetail.vehicle_pic)">
                    <img class="show-image" src="{{imgPath}}{{item.vehicle_pic}}" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Vehicle Video(s)</label>
          </div>
          <div class="col-sm-6">
            <div class="pull-left" id="vehicleVidView">
              <ul id="vehicle_video-ul" class="list-inline margin-bottom-0">
                <li *ngFor="let video of carDetail.vehicle_video" class="video-upload relative">
                  <span class="thumbnail margin-bottom-0" (click)="videoShow(video.vehicle_video)">
                    <span class="relative video-bg">
                      <mat-icon class="position-center play-video">play_circle_filled</mat-icon>
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Vehicle Vin Photo</label>
          </div>
          <div class="col-sm-6">
            <div class="pull-left" id="vehicleVinPicView">
              <ul id="vin_photo-ul" class="list-inline margin-bottom-0">
                <li class="relative view-images" *ngIf="carDetail.vin_photo!=''">
                  <span class="thumbnail" (click)="imageToolDialog($event,0,5,carDetail.vin_photo)">
                    <img class="show-image" src="{{vinPicture}}{{carDetail.vin_photo}}">
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Other Label Photo</label>
          </div>
          <div class="col-sm-6">
            <div class="pull-left" id="vehicleLabelPicView">
              <ul id="vehicle_pic-ul" class="list-inline margin-bottom-0">
                <li class="relative view-images" *ngFor="let item of labelPhoto; let i = index">
                  <span class="thumbnail" (click)="imageToolDialog($event,i,13,labelPhoto)">
                    <img class="show-image" src="{{VEHICLE_LABEL_PIC}}{{item.label_pic}}" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="{{ user.profile_type == '2' ? 'Contact' : 'Customer' }} Details">
      <ng-template mat-tab-label>
        <strong>{{ user.profile_type == '2' ? 'Contact' : 'Customer' }} Details</strong>
      </ng-template>
      <div class="padding-15">
        <div class="row" *ngIf="customerDetail.mapping_status == '1'">
          <span class="tooltip-status text-red fa fa-pencil" style="float: right;" title="Edit Customer" (click)="customerEdit()"></span>
        </div>
        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Name</label>
          </div>
          <div class="col-sm-6">{{customerDetail.first_name}} {{customerDetail.middle_name}}
            {{customerDetail.last_name}}</div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Email</label>
          </div>
          <div class="col-sm-6 break-word">
            <a href="mailto:{{customerDetail.email}}" class="text-black">{{customerDetail.email}}</a>
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Address</label>
          </div>
          <div class="col-sm-6">{{customerDetail.address1}}</div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>City</label>
          </div>
          <div class="col-sm-6 break-word">{{customerDetail.city}}</div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>State</label>
          </div>
          <div class="col-sm-6">{{customerDetail.state}}</div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Country</label>
          </div>
          <div class="col-sm-6 break-word">{{customerDetail.country}}</div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Zip Code</label>
          </div>
          <div class="col-sm-6">{{customerDetail.zip}}</div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Home Phone</label>
          </div>
          <div class="col-sm-6 break-word">
            <a href="tel:{{customerDetail.home_phone}}" class="text-black">{{customerDetail.home_phone}}</a>
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Business Phone</label>
          </div>
          <div class="col-sm-6">
            <a href="tel:{{customerDetail.business_phone}}" class="text-black">{{customerDetail.business_phone}}</a>
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Mobile Phone</label>
          </div>
          <div class="col-sm-6 break-word">
            <a href="tel:{{customerDetail.mobile_phone}}" class="text-black">{{customerDetail.mobile_phone}}</a>
          </div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>{{ user.profile_type == '2' ? 'Contact' : 'Customer' }} Type</label>
          </div>
          <div class="col-sm-6">{{customerDetail.customer_type_name}}</div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>Tax Exempted</label>
          </div>
          <div class="col-sm-6 break-word">{{customerDetail.tax_exempted}}</div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label>{{gs.getBORDName()}}</label>
          </div>
          <div class="col-sm-6">{{customerDetail.business_name}}</div>
        </div>

        <div class="row margin-bottom-10">
          <div class="col-sm-6">
            <label></label>
          </div>
          <div class="col-sm-6 break-word">

          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="{{ user.profile_type == '2' ? 'Other Contacts' : 'Contacts' }} Details" class="font-bold">
      <ng-template mat-tab-label>
        <strong>{{ user.profile_type == '2' ? 'Other Contacts' : 'Contacts' }} Details</strong>
      </ng-template>
      <div class="padding-15">
        <!-- <div class="row" style="text-align: right;">
          <span class="tooltip-status text-red fa fa-pencil" title="Edit Contacts" (click)="ContactEdit()"></span>
        </div> -->
        <div class="row margin-bottom-10" *ngFor="let cont of contacts">
          <div class="col-sm-3">
            <label>{{cont.contact_name}}</label>
          </div>
          <div class="col-sm-5">
            <a href="{{cont.contact_email}}">{{cont.contact_email}}</a>
          </div>
          <div class="col-sm-4 break-word">
            <a href="tel:{{cont.contact_number}}" class="text-black">{{cont.contact_number}}</a>
          </div>
    
        </div>
        <div class="row margin-bottom-10" *ngIf="contacts.length==0">
          <div class="col-sm-6">
            <label>No Contacts Found.</label>
          </div>
        </div>
      </div>
    </mat-tab>

  </mat-tab-group>

</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
  <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
</mat-dialog-actions>
