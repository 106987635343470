import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { globalConstants } from '../../../../environments/globalConstants';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MAT_DATE_FORMATS, DateAdapter, NativeDateAdapter } from '@angular/material/core';
import { GlobalService } from '../../../../environments/global.service';
import { globalFunctions } from '../../../../environments/globalFunctions';
import { ImagetooldialogComponent } from '../../../imagetooldialog/imagetooldialog.component';
import { DatePipe } from '@angular/common';

export class AppDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
   if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
     const str = value.split('/');
     const year = Number(str[2]);
     const month = Number(str[1]) - 1;
     const date = Number(str[0]);
     return new Date(year, month, date);
   }
   const timestamp = typeof value === 'number' ? value : Date.parse(value);
   return isNaN(timestamp) ? null : new Date(timestamp);
 }

  format(date: Date, displayFormat: Object): string {
      if (displayFormat == "input") {
          let day = date.getDate();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();
          return this._to2digit(month)+ '/' + this._to2digit(day)  + '/' + year;
      } else {
          return date.toDateString();
      }
  }

  private _to2digit(n: number) {
      return ('00' + n).slice(-2);
  }
}

export const APP_DATE_FORMATS =
{
  parse: {
      dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
  },
  display: {
      // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
      dateInput: 'input',
      monthYearLabel: { month: 'short', year: 'numeric', day: 'numeric' },
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'},
  }
}

@Component({
  selector: 'app-emissions-inspection',
  templateUrl: './emissions-inspection.component.html',
  styleUrls: ['./emissions-inspection.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    DatePipe,
    {
        provide: DateAdapter, useClass: AppDateAdapter
    },
    {
        provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
]
})
export class EmissionsInspectionComponent implements OnInit {
  public report: FormGroup;
  public dataSources=[];
  public request_id;
  public carImage='';
  public maxDate = new Date();
  public picPath=globalConstants.S3_BUCKET_URL+globalConstants.EMISSION_INSPECTION_PIC+globalConstants.IMG_THUMB;
  public emissionMaxAllowedSize=globalConstants.MAX_ALLOWED_IMAGE_DISPLAYED_TEXT;
  public emissionImgTypes=globalConstants.FORMAT_SUPPORTED_FOR_IMAGE;
  public reqPath=location.origin+'/shops/view-service-request/';
  public status=true
  constructor(public dialogRef: MatDialogRef<EmissionsInspectionComponent>,@Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private globalService: GlobalService, public dialog: MatDialog) {
    this.request_id=data.service_request_id;
    if(typeof data.status!='undefined' && (data.status==6 || data.status==8)){
      this.status=false
    }
    this.globalService.diaLogObj(this.dialogRef);
  }
  ngOnInit() {
    this.report = this.formBuilder.group({
      performDate: [new Date()],
      vehicle_pic: [""],
      emission_request_id: [this.request_id],
      user_type:["User"]
    });
    this.getPaymentDetail();
  }
  getPaymentDetail() {
    let data = { request_id: this.request_id };
    let params = globalFunctions.stringifyFormData(data, this.globalService.getCurrentUser()["user_type"]);

    this.globalService.callAPI(globalConstants.API_GET_EMISSION_INSPECTION_LIST_URL, params).subscribe((response:any) => {
      if (typeof response.data != 'undefined' && response.data.length) {
        this.dataSources = response.data;
      };
    });
  }
  addInspection(){
    this.report.value.performDate=this.formatDate(this.report.value.performDate);
    let url=globalConstants.API_SAVE_EMISSION_INSPECTION_URL;
    let fData=new FormData();
    for(let i in this.report.value){
      fData.append(i,this.report.value[i]);
    }

    let th=this;
    this.globalService.formData(url, fData).subscribe(function(response:any){
      if(response.result==0){
        th.globalService.snackbar('error', response.message);
      }else{
        th.globalService.snackbar('success', response.message);
        th.getPaymentDetail();
        th.resetForm();
      }

    });
  }

  imageToolDialog(current,i,type,imgaar): void {
    let dialogRef = this.dialog.open(ImagetooldialogComponent, {
        panelClass: ['imageToolDialog'],
        width: "100%",
        data: {event: current,index:i,imgType:type,imgArray:imgaar,customerId:this.globalService.getCurrentUser()["id"]}
    });
  }

  formatDate(date) {
    if (!new Date(date) || date == null || date == "0000-00-00 00:00:00") { return "--" }
    return (new Date(date).getMonth()+1) + "/" + new Date(date).getDate() + "/" + new Date(date).getFullYear()
  }
  getFiles(object){
    var files = object.target.files;
    var file = files[0];
    let valid=this.globalService.validImage(file,'addvehicle_pic');
    let validSize=this.globalService.checkImageSize(file,'addvehicle_pic');
    if(valid && validSize){
      this.report.value.vehicle_pic=file;

      if (files && file) {
        var reader = new FileReader();
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(file);
      }
    }

  }
  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.carImage = binaryString;
  }
  resetForm(){
    this.report.controls.performDate.setValue('');
    this.deletePartPic();
  }
  deletePartPic(){
    let ele=<HTMLInputElement>document.getElementById('addvehicle_pic');
    ele.value='';
    this.carImage='';
    this.report.controls.vehicle_pic.setValue('');
  }
  openInNewTab(id){
    let url=this.reqPath+id;
    window.open(url,'_blank');
  }

}
