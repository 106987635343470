<div mat-dialog-title class="relative m-0 appointment-title">
  <span>Edit Customer Details</span>
  <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
    <mat-icon class="notranslate close-icon-lg" aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>

<mat-dialog-content class="customer-detail-popup edit-customer-details m-0">
  <form  class="form-inline col-xs-12 padding-bottom-15" role="form" [formGroup]="customerEdit" novalidate (ngSubmit)="editCustomerData()">
    <div class=" col-xs-12 form-block">
        <div class="fontcolor-blue sub-heading">
            <div class="row flex-row">
                <div class="col-xs-7 col-sm-9 col-md-10">
                    <h4 class="margin-0">LOGIN INFORMATION</h4>
                </div>
                <div class="col-xs-5 col-sm-3 col-md-2 text-right contact-btn">
                    <button mat-raised-button type="button" class=" mat-success" (click)="editContacts()">
                            <i class="fa fa-vcard fa-lg"></i> <span class="hidden-xs">&nbsp;</span>
                            <span class="hidden-xs">{{ currentCustomer.profile_type == '2' ? 'Other Contacts' : 'Contacts' }}</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-12 col-md-4">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill"  hideRequiredMarker="false">
                    <input  matInput placeholder="Email Address" formControlName="email" maxlength="200" class="form-control input-lg custom-form-input" required  />
                    <mat-error *ngIf="customerEdit.controls.email.hasError('pattern')">
                        Please enter a valid Email Address
                    </mat-error>
                    <mat-error *ngIf="customerEdit.controls.email.hasError('required')">
                        Email Address is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper" *ngIf="checkUserEmail">
                    <div class="ng-tns-c12-29 ng-trigger ng-trigger-transitionMessages" style="opacity: 1; transform: translateY(0%);">
                        <p class="mat-error ng-tns-c11-28" role="alert">
                            Email Address is <strong>already exist. </strong> <a href="javascript:void(0)" [routerLink]="['/shops/dashboard']" target="_blank">Click Here.</a>
                        </p>
                    </div>
                </div>
                <!--<input type="text" class="form-control input-lg custom-form-input" id="email" name="email" placeholder="Email*" value="nitin.teche@gmail.com" disabled="">-->
            </div>
            
        </div>
        <p class="help-block"><strong>Note: </strong>If you are making any change to email, customer will receive an email verification link on this new email. Customer will not be able to access his/her account until they verify the email. Further modifications to customer profile would not be possible until he/she verifies the new email address.</p>
    </div>
    <div class=" col-xs-12 form-block">
        <h4 class="fontcolor-blue sub-heading">PERSONAL INFORMATION</h4>
        <div class="row">
            <div class="form-group col-sm-12 margin-bottom-5">
                <mat-checkbox [checked]="checkedBox" [value]="" (click)="businessNameToggle()">Add {{globalService.getBORDName()}}</mat-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-12 col-md-4">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill" hideRequiredMarker="false">
                    <input matInput placeholder="First Name" title="First Name" formControlName="first_name" maxlength="100"  class="form-control input-lg custom-form-input" required>
                    <mat-error *ngIf="customerEdit.controls.first_name.hasError('required')">
                        First Name is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="customerEdit.controls.first_name.hasError('pattern')">
                        Must not contain any special characters.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group col-sm-12 col-md-4">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <input  matInput placeholder="Middle Name" title="Middle Name" formControlName="middle_name" maxlength="100"  class="form-control input-lg custom-form-input">
                    <mat-error *ngIf="customerEdit.controls.middle_name.hasError('pattern')">
                        Must not contain any special characters.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group col-sm-12 col-md-4">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill" hideRequiredMarker="false">
                    <input  matInput placeholder="Last Name" title="Last Name" formControlName="last_name" maxlength="100"  class="form-control input-lg custom-form-input" required >
                    <mat-error *ngIf="customerEdit.controls.last_name.hasError('required')">
                        Last Name is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="customerEdit.controls.last_name.hasError('pattern')">
                        Must not contain any special characters.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group col-sm-12 col-md-4" [@popOverState]="showBusinessName">
                 <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <input  matInput placeholder="{{globalService.getBORDName()}}" title="{{globalService.getBORDName()}}" formControlName="business_name"  class="form-control input-lg custom-form-input" maxlength="200">
                    <mat-error *ngIf="customerEdit.controls.business_name.hasError('pattern')">
                        Must not contain any special characters.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class=" col-xs-12 form-block">
        <h4 class="fontcolor-blue sub-heading">CONTACT INFORMATION</h4>
        <div class="row">
            <div class="form-group col-sm-12 col-md-6">
              <mat-checkbox class="btn-block margin-top-10" labelPosition="after" formControlName="address_is_required">
                No Address
              </mat-checkbox>
            </div>
        </div>       
        <div class="row">
            <div class="form-group col-sm-12 col-md-6">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <input  matInput placeholder="Address Line 1*" title="Address 1" formControlName="address_one" maxlength="200"  class="form-control input-lg custom-form-input">
                    <mat-error *ngIf="customerEdit.controls.address_one.hasError('required')">
                        Address Line 1 is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group col-sm-12 col-md-6">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <input  matInput placeholder="Address Line 2" title="Address 2" formControlName="address_two" maxlength="200" class="form-control input-lg custom-form-input">
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-sm-12 col-md-6">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <input  matInput placeholder="{{zipLabel}}" title="{{zipLabel}}" 
                     #zipcode (input)="zipcode.value = zipcode.value.toUpperCase()"
                      formControlName="zipcode" class="form-control input-lg custom-form-input"
                       maxlength="15"  
                       (blur)="setCountryStateCityViaZipCode()"
                       />
                    <mat-error *ngIf="customerEdit.controls.zipcode.hasError('required')">
                        {{zipLabel}} is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="customerEdit.controls.zipcode.hasError('pattern')">
                        Please enter valid {{zipLabel.toLowerCase()}}
                    </mat-error>
                    <mat-error *ngIf="customerEdit.controls.zipcode.hasError('maxlength')">
                        Invalid {{zipLabel.toLowerCase()}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
                <ngx-select class="display-full margin-bottom-20" 
                [items]="countries" 
                [(ngModel)]="selectedCountry" 
                [ngModelOptions]="{standalone: true}"
                (select)="handleCountryDropdownChange($event)"
                placeholder="Select Country">
                </ngx-select>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
                <ngx-select class="display-full margin-bottom-20" 
                [items]="states" 
                [(ngModel)]="selectedState" 
                [ngModelOptions]="{standalone: true}"
                [disabled]="disableStates" 
                (select)="handleStateDropdownChange($event)"
                placeholder="Select State">
                </ngx-select>
                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                    <mat-error *ngIf="customerEdit.controls.state.hasError('required')">
                        State is <strong>required</strong>
                    </mat-error> 
                  </div>
            </div>
            <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
                <ngx-select class="display-full margin-bottom-20" 
                [items]="cities" 
                [(ngModel)]="selectedCity" 
                [ngModelOptions]="{standalone: true}"
                [disabled]="disableCities" 
                (select)="handleCityDropdownChange($event)"
                placeholder="Select City">
                </ngx-select>
                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                    <mat-error *ngIf="customerEdit.controls.city.hasError('required')">
                        City is <strong>required</strong>
                    </mat-error>
                </div>
            </div>
        </div>
<div class="row">
<div class="form-group col-sm-12 col-md-4">
    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
        <input  matInput placeholder="Home Phone" title="Home Phone" formControlName="home_phone"  class="form-control input-lg custom-form-input"  maxlength="15" [textMask]="{mask: phoneMask}" (keyup)="checkHomePhoneValidity()" />
        <mat-error *ngIf="customerEdit.controls.home_phone.hasError('phoneError')">
            Invalid Home Phone
        </mat-error>
    </mat-form-field>
</div>
<div class="form-group col-sm-12 col-md-4">
    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
        <input  matInput placeholder="Business Phone" title="Business Phone" formControlName="business_phone"  class="form-control input-lg custom-form-input"  maxlength="15" [textMask]="{mask: phoneMask}" (keyup)="checkBusinessPhoneValidity()" />
        <mat-error *ngIf="customerEdit.controls.business_phone.hasError('phoneError')">
            Invalid Business Phone
        </mat-error>
    </mat-form-field>
</div>
<div class="form-group col-sm-12 col-md-4">
    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
        <input  matInput placeholder="Mobile Phone*" title="Mobile Phone" formControlName="mobile_phone"  class="form-control input-lg custom-form-input" required maxlength="15" [textMask]="{mask: phoneMask}"  />
        <mat-error *ngIf="customerEdit.controls.mobile_phone.hasError('required')">
            Mobile Phone is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="customerEdit.controls.mobile_phone.hasError('minLength') && !customerEdit.controls.mobile_phone.hasError('required')">
            Invalid Mobile Phone
        </mat-error>
    </mat-form-field>
</div>
</div>

<div class="row">
<div class="form-group col-sm-12 col-md-6 uc-ngx-select">
    <ngx-select class="display-full margin-bottom-20" 
        formControlname="customer_type"
        [(ngModel)]="defaultCustomerType"
        [items]="getCustomerTypes" 
        (select)="getSelectedOptionCustomerType($event)"
        [ngModelOptions]="{standalone: true}"
        placeholder="Select Customer Type">
    </ngx-select>
    <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
        <mat-error *ngIf="customerEdit.controls.customer_type.hasError('required')">
            Customer Type is <strong>required</strong>
        </mat-error>
    </div>                    
</div>
<div class="form-group col-sm-12 col-md-6 uc-ngx-select">
    <ngx-select class="display-full margin-bottom-20" [items]="timezoneData" formControlName="timezone"
        (select)="getSelectedOptionTimezone($event)" placeholder="Select Timezone">
    </ngx-select>
    <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
        <mat-error *ngIf="customerEdit.controls.timezone.hasError('required')">
            Timezone is <strong>required</strong>
        </mat-error>
    </div>
</div>
</div>

<div class="row">
<div class="form-group col-sm-12 col-md-6">
<mat-checkbox class="btn-block" labelPosition="after" (change)="showTaxExempted($event)" formControlName="tax_exempted" [checked]="taxExemptedCheck">
    Tax Exempted
</mat-checkbox>
</div>
</div>

<div class="row">
<div class="form-group col-sm-12 col-md-6">
<mat-checkbox class="btn-block" labelPosition="after" (change)="showRequireApproval($event)" formControlName="require_approval" [checked]="requireApprovalCheck">
    Require Approval
</mat-checkbox>
</div>
</div>
<div class="row">
<div class="form-group col-sm-12 col-md-4" [@popOverState]="checkApprovalAmount">
<mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
   <input type="text" matInput placeholder="Amount" title="Amount" formControlName="approval_amount"  class="form-control input-lg custom-form-input" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event);validateNumbersWithDecimal($event,3)" maxlength="12">
   <mat-error *ngIf="customerEdit.controls.approval_amount.hasError('pattern')">
    Must be a number with 2 decimal places.
  </mat-error>
</mat-form-field>
</div>  
<p class="help-block col-sm-12"><strong>Note: </strong>If the Grand Total is exceeded by this amount, shop needs approval.</p>
</div>   
        

</div>

<div class=" col-xs-12 form-block submit-block">
<button type="submit" mat-raised-button class="car-btn btn-block text-uppercase  btn-lg font-bold fontcolor-white" color="accent" title="Submit" style="max-width: 300px;">Submit</button>
</div>
</form>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
  <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
</mat-dialog-actions>