<ng-container *ngIf="partShop == gc.PART_SHOPS.PARTSTECH">
  <div mat-dialog-title class="relative partstech-container">
    <img src="assets/img/PartsTechLogo.png" class="partstech-img">
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
      <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
  </div>
  <mat-dialog-content *ngIf="enable">
    <p *ngIf="showOrder==true" class="alert alert-warning uc-alert-info btn-small">
      <small>Below are the order(s) associated with this request.</small>
      <button mat-raised-button class="mat-success font-bold" *ngIf="viewCartIfany && isAllowed('views')" (click)="showCarts()">View Cart</button>
    </p>
    <div *ngIf="showOrder==true">
      <fieldset *ngFor="let order of dataSources;let i=index;">
        <div class="row order-detail">
          <div class="col-sm-5">
            <strong>Order No : </strong>
            <p>{{order.order_number}}</p>
          </div>
          <div class="col-sm-4">
            <strong>Order Date:</strong>
            <p>{{order.date}}</p>
          </div>
          <div class="col-sm-3 text-right">
            <button mat-raised-button *ngIf="isAllowed('deletes')" btn-sm color="accent" (click)="cancelOrder(order.order_number,i)" class="font-bold">Cancel Order</button>
          </div>
        </div>
        <div *ngFor="let dist of order.distributor">
          <div colspan="3" class="partstech-order-title">
            <h4>
              <strong>Distributor : </strong>
              <span>{{dist.distributor_name}}</span>
            </h4>
            <h5>
              <strong>Location : </strong>
              <span>{{dist.distributor_location_name}}</span>
            </h5>
          </div>
          <div class="table-responsive relative ">
            <table class="table table-striped  margin-bottom-10 ">
              <thead>
                <tr class="table-heading">
                  <th style="width:40%">Part Name</th>
                  <th >Part Number</th>
                  <th style="width:100px">Price</th>
                  <th style="width:100px">Qty</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let part of dist.parts">
                  <td>{{part.name}}</td>
                  <td>{{part.part_number}}</td>
                  <td>${{part.part_price}}</td>
                  <td>{{part.part_quantity}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </fieldset>
    </div>
    <div *ngIf="orderNow==true" class="row alert alert-warning uc-alert-info btn-small mobile-view-css">
      <p>Note: Save the changes you made to the service request to get the latest changes here. The parts you are not selecting will be discarded as PartsTech part and will be treated as inventory part.</p>
      <button mat-raised-button *ngIf="isAllowed('views')" class="mat-success font-bold" (click)="getOrders()">View Order(s)</button>
    </div>
    <p *ngIf="orderPlaced==true" class="alert alert-success"> <strong>Your order has been placed successfully.</strong></p>
    <div class="row" *ngIf="showOrder==false">
      <fieldset *ngFor="let dist of dataSources">
        <!-- <legend *ngIf="showOrder==false">{{dist.distributor_name}}:</legend> -->
        <div colspan="4" class="partstech-order-title">
          <h4>
            <strong>Distributor : </strong>
            <span>{{dist.distributor_name}}</span>
          </h4>
          <h5>
            <strong>Location : </strong>
            <span>{{dist.distributor_location_name}}</span>
          </h5>
        </div>
        <div class="relative partstech-table" [ngClass]="{'completed-sr': orderNow == false}">
          <table class="table-striped bs-table">
            <thead>
              <tr class="table-heading">
                <th *ngIf="orderNow==true"></th>
                <th *ngIf="orderNow==false">Name</th>
                <th  *ngIf="orderNow==true">Part Name</th>
                <th >Part Number</th>
                <th >Price</th>
                <th >Qty</th>
                <th *ngIf="orderNow==false">Availability</th>
              </tr>
            </thead>
              <tr *ngFor="let part of dist.parts">
                <td  *ngIf="orderNow==true">
                  <mat-checkbox name="item[]" class="btn-block" value="{{part.cart_item_id}}" labelPosition="before" (change)="selectedItem($event,part.cart_item_id)">
                  </mat-checkbox>
                </td>
                <td >{{part.name}}</td>
                <td >{{part.number}}</td>
                <td >${{part.part_price}}</td>
                <td >{{part.part_quantity}}</td>
                <td *ngIf="orderNow==false">
                  <span *ngIf="part.availability=='1'">Yes</span>
                  <span *ngIf="part.availability!='1'">No</span>
                </td>
              </tr>
          </table>
        </div>
      </fieldset>
    </div>
    <div class="table-responsive relative border-0">
      <table class="table table-striped table-bordered  margin-bottom-0 " *ngIf="dataSources.length==0">
        <tbody>
          <tr>
            <td colspan="3" *ngIf="orderNow" class="norecords-row">No new PartsTech parts exist to be ordered</td>
            <td colspan="3" *ngIf="viewCartIfany" class="norecords-row">No orders found for this request </td>
            <td colspan="3" *ngIf="itemDiscarded" class="norecords-row">PartsTech parts have been discarded in CAR and will be still a part of invoice</td>
          </tr>
        </tbody>
      </table>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="inquiry-action relative partstech-flexbtns" *ngIf="orderNow==false && enable">
    <button mat-raised-button *ngIf="(showOrder==false) && isAllowed('addNew') " [disabled]="dataSources.length == 0" color="accent" (click)="checkAvailability()"
      class="font-bold">Place Order</button>
    <button mat-raised-button *ngIf="(showOrder==false) && isAllowed('deletes')" [disabled]="dataSources.length == 0" color="accent" (click)="discardAvailability()"
      class="font-bold">Discard Order</button>
    <!-- <button mat-raised-button *ngIf="(showOrder==true) && (dataSources.length > 0)" color="accent" (click)="cancelOrder()" class="font-bold">Cancel Order</button> -->
    <button mat-raised-button mat-dialog-close class="font-bold close-btn hidden-xs">Close</button>
  </mat-dialog-actions>
  <mat-dialog-actions class="inquiry-action relative partstech-flexbtns" *ngIf="orderNow==true && enable">
    <button mat-raised-button *ngIf="isAllowed('addNew')" [disabled]="dataSources.length == 0" color="accent" (click)="placeorderNow()" class="font-bold">Place Order</button>
    <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
  </mat-dialog-actions>
</ng-container>

<ng-container *ngIf="partShop == gc.PART_SHOPS.NEXPART">
  <div mat-dialog-title class="relative">
    <img [src]="nexpartLogoURL" style="height:25px;">
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
      <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
  </div>
  <mat-dialog-content *ngIf="enableNexpart">
    <p *ngIf="showOrder==true" class="alert alert-warning uc-alert-info btn-small">
      <small>Below are the order(s) associated with this request.</small>
      <!-- <button mat-raised-button class="mat-success font-bold" *ngIf="viewCartIfany && isAllowed('views')" (click)="showCarts()">View Cart</button> -->
    </p>
    <div *ngIf="showOrder==true">
      <fieldset *ngFor="let order of dataSources;let i=index;">
        <div class="row order-detail">
          <div class="col-sm-4">
            <strong>Order ID : </strong>
            <p>{{order.id}}</p>
          </div>
          <div class="col-sm-4">
            <strong>Order Date:</strong>
            <p>{{getFormattedDate(order.add_date)}}</p>
          </div>
          <div class="col-sm-4">
            <strong>Order Total:</strong>
            <p>${{order.order_total}}</p>
          </div>
          <!-- <div class="col-sm-3 text-right">
            <button mat-raised-button *ngIf="isAllowed('deletes')" btn-sm color="accent" (click)="cancelOrder(order.order_number,i)" class="font-bold">Cancel Order</button>
          </div> -->
        </div>
        <div class="table-responsive relative ">
          <div *ngFor="let dist of order.distributors">
            <div class="row sub-order-detail">
              <div class="col-sm-3">
                <strong>Branch : </strong>
                <p>{{dist.distributor_name}}</p>
              </div>
              <div class="col-sm-3">
                <strong>Order Number:</strong>
                <p>{{dist.order_number}}</p>
              </div>
            </div>
            <table class="table table-striped  margin-bottom-10 ">
              <thead>
                <tr class="table-heading">
                  <th style="width:40%">Part Name</th>
                  <th >Part Number</th>
                  <th style="width:100px">Price</th>
                  <th style="width:100px">Cost</th>
                  <th style="width:100px">Qty</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of dist.items">
                  <td>{{item.name}}</td>
                  <td>{{item.part_number}}</td>
                  <td>${{item.part_price}}</td>
                  <td>${{item.part_cost}}</td>
                  <td>{{item.part_quantity}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <div *ngFor="let item of order.items">
          <div colspan="3" class="partstech-order-title">
            <h4>
              <strong>Name : </strong>
              <span>{{item.name}}</span>
            </h4>
            <h5>
              <strong>Location : </strong>
              <span>{{dist.distributor_location_name}}</span>
            </h5>
          </div>
        </div> -->
      </fieldset>
    </div>
    <div class="table-responsive relative ">
      <table class="table table-striped table-bordered  margin-bottom-0 " *ngIf="dataSources.length==0">
        <tbody>
          <tr>
            <!-- <td colspan="3" *ngIf="orderNow" class="norecords-row">
              No new Nexpart parts exist to be ordered
            </td> -->
            <td colspan="3" class="norecords-row">No orders found for this request </td>
            <!-- <td colspan="3" *ngIf="itemDiscarded" class="norecords-row">
              Nexpart parts have been discarded in CAR and will be still a part of invoice
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="inquiry-action relative" *ngIf="orderNow==false && enable">
    <button mat-raised-button *ngIf="(showOrder==false) && isAllowed('addNew') " [disabled]="dataSources.length == 0" color="accent" (click)="checkAvailability()"
      class="font-bold">Place Order</button>
    <button mat-raised-button *ngIf="(showOrder==false) && isAllowed('deletes')" [disabled]="dataSources.length == 0" color="accent" (click)="discardAvailability()"
      class="font-bold">Discard Order</button>
    <!-- <button mat-raised-button *ngIf="(showOrder==true) && (dataSources.length > 0)" color="accent" (click)="cancelOrder()" class="font-bold">Cancel Order</button> -->
    <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
  </mat-dialog-actions>
  <mat-dialog-actions class="inquiry-action relative" *ngIf="orderNow==true && enable">
    <button mat-raised-button *ngIf="isAllowed('addNew')" [disabled]="dataSources.length == 0" color="accent" (click)="placeorderNow()" class="font-bold">Place Order</button>
    <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
  </mat-dialog-actions>
</ng-container>
