<div class="popup-sidebar-wrapper">
  <mat-drawer-container class="side-panel-wrappers ticket-assignment business-report" autosize end>
    <mat-drawer #searchCustomerDrawer class="ticket-sidenav" mode="over" position="end">
      <!-- header section -->
      <h4><span>Customer Search</span></h4>
     <div class="border-box">
      <div class="btn-block header" >
        <ul class="padding-0 margin-0" style="float: right;">
            <li>
                <button type="button" class="btn skip-btn" (click)="closeSideBar(true)" title="Submit" [disabled]="!isAnySelected"><i class="fa fa-check" aria-hidden="true"></i> </button>
            </li>
            <li>
                <button type="button" class="btn skip-btn" (click)="clearVehileSelection()" title="Clear"><i class="fa fa-trash" aria-hidden="true"></i></button>
            </li>
            <li>
                <button type="button" class="btn skip-btn" (click)="closeSideBar()" title="Cancel"><i class="fa fa-times" aria-hidden="true"></i></button>
            </li>
        </ul>
    </div>
      <!-- <div class="cross-btn">
        <button type="button" (click)="closeSideBar()">
          <div class="desktop-screen">x</div>
        </button>
      </div> -->
      <!-- content section -->
      <div class="assignment-block" >
        <form id="advanceSearchForm" [formGroup]="searchForm" (ngSubmit)="search()">
          <div class="row"> 
            <div class="col-sm-4">
              <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <mat-select class="form-control input-lg custom-form-input"
                      options="searchByArray"
                      formControlName="search_by"
                      placeholder="Search By">
                      <mat-option  *ngFor="let opt of searchByArray" [value]="opt.id">{{opt.text}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <mat-error *ngIf="submitted && searchForm.controls.search_by.value === '0'">
                Search By is <strong>required</strong>
              </mat-error>
            </div>
            <div class="col-sm-4">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                    <input  matInput placeholder="Search Keyword" title="Search Keyword" formControlName="search_keyword" class="form-control input-lg custom-form-input"/>
                </mat-form-field>
                <mat-error *ngIf="submitted && searchForm.controls.search_keyword.hasError('required')">
                  Search Keyword is <strong>required</strong>
                </mat-error>
            </div>
            <div class="col-md-4 col-sm-12">
              <button mat-raised-button color="accent" type="submit">Search</button>
            </div>
          </div>
        </form>
        <div class="row margin-left-0 margin-top-10">
          <mat-chip-list class="mat-badge">
            <mat-chip *ngFor="let selected of selectedCustomer; let i = index" [selectable]="selectable">
              {{selected.customer_fullname}}
              <mat-icon style="font-size: 20px;" (click)="removeSelected(i)">cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </div>
        <div class="row">
          <div class="col-md-12 col-sm-12 bs-table">
            <table class="table table-striped add-customer padding-top-10 margin-bottom-0">
                <thead>
                    <tr>
                        <th>Select</th>
                        <th>Customer Name</th>
                        <th>Email</th>
                        <th>State</th>
                        <th>City</th>
                        <th>Mobile</th>
                    </tr>
                </thead>
                <tbody>
                  <tr class="no-data-wrapper" *ngIf="allCustomerList.length === 0">                      
                    <td colspan="6" class="no-data-found text-center">No Records Available</td>
                  </tr>
                  <tr *ngFor="let customer of allCustomerList;let i = index">
                    <td><mat-checkbox [checked]="customer.status" (change)="updateSelection(i)"></mat-checkbox></td>
                    <td>{{customer.customer_fullname}}</td>
                    <td style="white-space: nowrap;">{{customer.email}}</td>
                    <td>{{customer.state}}</td>
                    <td>{{customer.city}}</td>
                    <td style="white-space: nowrap;">{{customer.mobile_phone}}</td>
                  </tr>
                </tbody>
            </table>
          </div>
        </div>
        <div class="display-full relative" [style.display]="recordsTotal < 1 ? 'none' : ''" style="border-top:1px solid #ccc;">
          <mat-paginator class="car-pagination" #paginator [length]="recordsTotal" [pageIndex]="0" [pageSize]="advancedOptions.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event)" >
         </mat-paginator>

         <div class="mat-paginator table-paging">
             <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{advancedOptions.start+1}} - {{getUpperLimit(advancedOptions.start + advancedOptions.limit,recordsTotal)}} of {{recordsTotal}}</div>
             <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                     <mat-icon>navigate_before</mat-icon>
             </button>
             <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                     <mat-icon>navigate_next</mat-icon>
             </button>
         </div>
        </div>
      </div>
      <!-- Footer section -->
      <div class="footer">
        <div class="row">
          <div class="col-sm-2 col-xs-12"></div>
          <div class="col-sm-10 col-xs-12">
            <div class="btn-block" >
              <ul>
                <li>
                  <button type="button" class="btn skip-btn submit" (click)="closeSideBar(true)" [disabled]="!isAnySelected">Submit</button>
                </li>
                <li>
                  <button type="button" class="btn skip-btn clear" (click)="clearVehileSelection()">Clear</button>
                </li>
                <li>
                  <button type="button" class="btn skip-btn cancel" (click)="closeSideBar()">Cancel</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
     </div>
    </mat-drawer>
  </mat-drawer-container>
</div>