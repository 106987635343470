<div mat-dialog-title class="relative">
  Profile Logs
  <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
    <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
    <div class="display-full relative">
    
      <mat-expansion-panel hideToggle="false" class="search-filter relative">
        <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px">
          <strong><i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
        </mat-expansion-panel-header>
        <form id="advanceSearchForm" class="collapse in" aria-expanded="true">
    
          <div class="panel-body">
    
            <div class="col-sm-3">
              <mat-form-field class="btn-block car-input" floatPlaceholder="never">
                <mat-select placeholder="Search By" class="form-control input-lg custom-form-input"
                  [(ngModel)]="options.search_by" [ngModelOptions]="{standalone: true}">
                  <mat-option *ngFor="let opt of selectlists" [value]="opt.id">
                    {{opt.text}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-sm-3" *ngIf="options.search_by=='1'">
              <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                  <input  matInput placeholder="Search Keyword" title="Search Keyword" [(ngModel)]="options.search_keyword" [ngModelOptions]="{standalone: true}" class="form-control input-lg custom-form-input"/>
              </mat-form-field>
            </div>
    
            <div class="col-sm-3">
              <mat-form-field class="car-input btn-block car-datePicker" floatPlaceholder="never">
                <input (dateChange)="dateChange($event,1)" matInput class="form-control input-lg custom-form-input"
                  [(ngModel)]="fromDate" [max]="today" [ngModelOptions]="{standalone: true}" placeholder="Log From Date"
                  [matDatepicker]="filerPicker1" onkeydown="return false;">
                <mat-datepicker-toggle matSuffix [for]="filerPicker1"></mat-datepicker-toggle>
                <mat-datepicker #filerPicker1 [touchUi]="setTouchViewValue()"></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <mat-form-field class="car-input btn-block car-datePicker" floatPlaceholder="never">
                <input (change)="dateChange($event,2)" matInput class="form-control input-lg custom-form-input"
                  [(ngModel)]="toDate" [min]="fromDate" [max]="today" [ngModelOptions]="{standalone: true}"
                  placeholder="Log to Date" [matDatepicker]="filerPicker2" onkeydown="return false;">
                <mat-datepicker-toggle matSuffix [for]="filerPicker2"></mat-datepicker-toggle>
                <mat-datepicker #filerPicker2 [touchUi]="setTouchViewValue()"></mat-datepicker>
              </mat-form-field>
            </div>
    
          </div>
          <mat-action-row class="search-filter-footer">
            <button mat-raised-button color="accent" type="submit" (click)="search($event)">
              Search
            </button>
          </mat-action-row>
        </form>
      </mat-expansion-panel>
      <button mat-button class="clear-btn" #clearBtn (click)="clearSearch()"
        [class.active-search]="checkFormEmpty(clearBtn)">
        Clear Filter
      </button>
    </div>
    <div class="car-datatable uc-datatable  mat-elevation-z1">
      <div class="table-responsive relative">
        <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg uc-table-responsive">

          <ng-container matColumnDef="Name">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Shop Name</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="Shop Name"> {{row.name}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="modifier_type">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Accessed By</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="Accessed By"> {{row.modifier_type}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Modified By">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Modified By</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="Modified By"> {{row.modify_by}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Modification Date">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Modification Date</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="Modification Date"> {{row.modify_date}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Actions">
            <mat-header-cell *matHeaderCellDef data-role="button"> Action </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="button">
                <button mat-button *ngIf="row.new_record_value" class="list-action-btn" (click)="openDiffView(row)" disableRipple >View</button>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <div class="padding-15 text-center" [style.display]="getTotalRecords() < 1 ? '' : 'none'">No Records Available
        </div>
      </div>
      <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
        <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0"
          [pageSize]="options.limit" [pageSizeOptions]="[5, 10,25, 100]" (page)="paginateByLimit($event)">
        </mat-paginator>

        <div class="mat-paginator table-paging ">
          <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} -
            {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
          <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
            <mat-icon>navigate_before</mat-icon>
          </button>
          <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
            <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </div>
    </div>
  
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
  <div class="text-right">
    <button type="button" mat-raised-button mat-dialog-close class="font-bold" title="Close">Close</button>
  </div>
</mat-dialog-actions>
