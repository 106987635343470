import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { GlobalService } from '../../../environments/global.service';
import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { globalConstants } from '../../../environments/globalConstants';
import { ViktableComponent,VikSource } from '../viktable/viktable.component';
import { Router } from '@angular/router';

const SHOP_CUSTOMER_PULL_LOGS_LIST = "admin-customer-pull-logs-";
@Component({
  selector: 'app-customerpulllogs',
  templateUrl: './customerpulllogs.component.html',
  styleUrls: ['./customerpulllogs.component.scss']
})

export class CustomerpulllogsComponent extends ViktableComponent implements OnInit {
  public currentAdmin: any = {};
  public openStateSearch = false;
  displayedColumns = ['Shop Name',  'Employee Name',  'Customer Name', 'Shop Email',  'Shop Phone', 'Event Date'];
  public tablesData=this;
  dataSource: any;
  url="";
  public options = {
    user_type: "Super Admin",
    user_id: "",
    search_keyword:  "",
    start: 0 ,
    limit: 10 ,
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(public gs: GlobalService,public router: Router) {
    super(globalConstants.API_MASTER_GET_CUSTOMER_PULLS_URL, gs);
    this.currentAdmin = this.gs.getAdmin();
    if (this.currentAdmin) {
      this.sessionKey = SHOP_CUSTOMER_PULL_LOGS_LIST;
      this.url = globalConstants.API_MASTER_GET_CUSTOMER_PULLS_URL;
    } else {
      this.router.navigate(["/admin"]);
    }    
  }

  ngOnInit() {
    this.reinitializeOptions();
    if(this.checkFormEmpty(null)){
      this.openStateSearch = true;
    }else{
      this.openStateSearch = false;
    }
    this.isReady = true;
    this.gs.setMetaData("ADMIN", "SHOP_CUSTOMER_PULL_LOGS_LIST")
    this.setDataTable()
  }

  search(event) {
    this.options.start = 0;
    this.setDataTable();
  }
  
  clearSearch() {
    this.openStateSearch = false;
    this.gs.localGetterSetter("", this.options, SHOP_CUSTOMER_PULL_LOGS_LIST);
    this.options.search_keyword= "";
    this.reinitializeOptions();
    this.setDataTable();
  }

  reinitializeOptions() {
    this.options = this.gs.localGetterSetter(this.options, this.options, SHOP_CUSTOMER_PULL_LOGS_LIST);
    this.options.user_type = this.currentAdmin.user_type;
    this.options.user_id = this.currentAdmin.id;
  }

  createRecordrecord(record) {
    return {
      name: record.company_name,
      id: record.id,
      email_address: record.email_address,
      business_phone: record.business_phone1,
      customer_name: record.cfirstname+' '+record.cmiddlename+' '+record.clastname,
      employee_name: record.sfirstname+' '+record.smiddlename+' '+record.slastname,
      event_date: this.gs.getFullFormateDate(record.add_date,'G',null)
    };
  }

  checkFormEmpty(button) {
    if (this.options.search_keyword == "") {
        if (button) {button.color = "primary"}
        return false
    }else {
        if (button) {button.color = "accent"}
        return true;
    }
  }

  setDataTable() {
    this.dataSource = new VikSource(this.tablesData, this.sort, this.paginator);
    this.dataSource.getSortedData=this.sortedData;
  }

  sortedData(): any[] {
   let th:any=this;
   const data = th._exampleDatabase.data.slice();
    if (!th._sort.active || th._sort.direction == '') { return data; }

    return data.sort((a, b) => {
      let propertyA;
      let propertyB;
      switch (th._sort.active) {
        case 'Shop Name': propertyA = a.name; propertyB = b.name; break;
        case 'Shop Email': propertyA = a.email_address; propertyB = b.email_address; break;
        case 'Shop Phone': propertyA = a.business_phone; propertyB = b.business_phone; break;
        case 'Employee Name': propertyA = a.employee_name; propertyB = b.employee_name; break;
        case 'Customer Name': propertyA = a.customer_name; propertyB = b.customer_name; break;
        case 'Login Date': propertyA = a.add_date; propertyB = b.add_date; break;
        default: return 0;
      }
      let valueA = isNaN(propertyA) ? propertyA.toUpperCase() : propertyA;
      let valueB = isNaN(propertyB) ? propertyB.toUpperCase() : propertyB;

      return (valueA < valueB ? -1 : 1) * (th._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
