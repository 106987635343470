import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from '../../../../environments/global.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { globalConstants } from '../../../../environments/globalConstants';
import { ViktableComponent, VikSource } from '../../../admin/viktable/viktable.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { QuoteviewComponent } from '../quoteview/quoteview.component';

const QUOTE_LIST = "customer-quote-";

@Component({
  selector: 'app-quotelist',
  templateUrl: './quotelist.component.html',
  styleUrls: ['./quotelist.component.scss']
})
export class QuotelistComponent extends ViktableComponent implements OnInit {
  public customer:any=null
  dataSource: any;
  public tablesData = this;
  public checkFormEmpty=false;
  public searchEnabled : boolean = false;
  public displayedColumns=['id','vehicle','jobs','total','added','Actions']
  public searchByStatus = [];
  public searchByArray = []
  public options:any = {
    customer_id: "",
    user_type: "customer",
    status: '',
    convertedToSR:false,
    search_by: "",
    keyword: "",
    start: 0,
    limit: 10
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(public gs: GlobalService, public router: Router,public dialog: MatDialog) {
    super(globalConstants.API_CUSTOMER_QUOTE_LIST, gs);
    this.customer=this.gs.getCurrentCustomer()
    if (this.customer) {
      this.sessionKey = QUOTE_LIST;
      this.url = globalConstants.API_CUSTOMER_QUOTE_LIST;
    } else {
      this.router.navigate(["/customer"]);
    }
    this.searchByArray = [
      { id: "1", text: "Licence Plate" },
      { id: "2", text: "Quick Quote ID" },
      { id: "3", text: "Vehicle" },
      { id: "4", text: "Vin" }
    ];
    
  }

  ngOnInit() {
    setTimeout(() => {
      let isFleetCustomer = this.gs.isFleetCustomer()
      if (isFleetCustomer) {
          this.router.navigate(["/customer/dashboard"]);
      }
  }, globalConstants.RELOAD_WAIT);
    this.gs.setMetaData("CUSTOMER", "QUICK_QUOTE_LIST");
    this.reInitOptions()
    this.setDataTable();
   }
   reInitOptions(){
    this.options.user_type = this.customer.user_type;
    this.options.customer_id = this.customer.id;
    this.options.status = '0';
   }

   setDataTable() {
     if(this.options.convertedToSR){
      this.options.status = '1';
     }else{
      this.options.status = '0';
     }
    this.dataSource = new VikSource(this.tablesData, this.sort, this.paginator);
    this.dataSource.getSortedData = this.sortedData;
  }
  createRecordrecord(record) {
    return {
      id: record.id,
      name: this.formatVehicle(record),
      added_at : this.convertDate(record.add_date),
      modified_at : this.convertDate(record.modify_date),
      car_id:record.car_id,
      customer_id:record.customer_id,
      status:record.status,
      jobs:this.getLevelName(record.service_jobs),
      total:record.grand_total
    };
  }
  
  formatVehicle(record){
    let name='';
    if(record.make){
      name=record.make
    }
    if(record.year && record.year!='0'){
      name=name+' '+record.year
    }
    if(record.model){
      name=name+' '+record.model
    }
    return name
  }

  sortedData(): any[] {
    let th: any = this;
    const data = th._exampleDatabase.data.slice();
    if (!th._sort.active || th._sort.direction == '') { return data; }
    return data.sort((a, b) => {
      let propertyA;
      let propertyB;
      switch (th._sort.active) {
        case 'Name': propertyA = a.part_number; propertyB = b.part_number; break;
        default: return 0;
      }
      let valueA = isNaN(propertyA) ? propertyA.toUpperCase() : propertyA;
      let valueB = isNaN(propertyB) ? propertyB.toUpperCase() : propertyB;

      return (valueA < valueB ? -1 : 1) * (th._sort.direction == 'asc' ? 1 : -1);
    });
  }

  public convertDate(date){
    return this.gs.getFullFormateDate(date,'G','H');
  }

  firstList() {
    return this.gs.firstList(this.sessionKey, this.options);
  }

  lastList() {
    return this.gs.lastList(this.sessionKey);
  }

  getLevelName(jobs){
    let name=''
    if(typeof jobs !='undefined' && jobs.length){
      jobs.forEach((e) => {
        if(name){
          name=name+', '+e.name
        }else{
          name=e.name
        }
      });
    }
    return name
  }

  isAnyOptionActive(button?: any) {
    if (this.options.search_by == "" && this.options.convertedToSR==false && this.options.keyword == "") {
      return false;
    }else {
      return true;
    }
  }

  search() {
    this.options.start = 0;
    this.setDataTable();
  }

  clearSearch() {
    this.options.user_type = this.customer.user_type;
    this.options.customer_id = this.customer.id;
    this.options.status = '';
    this.options.convertedToSR=false
    this.options.keyword = '';
    this.options.search_by = '';
    this.options.start = 0;
    this.setDataTable();
  }

  statusChange($e){
    // console.log($e)
  }
  
  view(row){
    row.qq=true
    let dialogRef: any = this.dialog.open(QuoteviewComponent, {
      panelClass: ['quickqouotview', 'quickqouotedialog'],
      width: "100%",
      data: row
    });
    // this.router.navigate(['customer/quick-quote/'+row.id]);
  }

}
