<div id="mainCompotents">
  <div class="main-content">
    <div class="shopaddnewpart">
      <div class="panel panel-primary">
        <div class="panel-heading padding-left-15">
          <h3 class="panel-title">{{heading}} Smart Batch</h3>
          <button mat-mini-fab class="custom-btn-back" (click)="gs.goBack();" *ngIf="partId">
            <mat-icon>arrow_back</mat-icon>
          </button>
        </div>
        <div class="panel-body bgcolor-lightgrey">
          <!-- Add New Smart Batch start -->
          <div id="addNewPart" class="row bgcolor-lightgrey form-content">
            <div class="container-fluid">
              <form [formGroup]="addPartNumber" novalidate (ngSubmit)="AddPart(1)" id="addPartForm"
                class="form-inline col-sm-12" role="form" method="POST" enctype="multipart/form-data">
                <div class="col-xs-12 form-block">
                  <div class="row">
                    <div class="form-group col-md-6 col-sm-12">
                      <label for="group_name">Part Type</label>
                      <div class="relative">
                        <mat-form-field class="btn-block car-input" floatPlaceholder="never">
                          <input matInput placeholder="Part Type" title="Part Type" formControlName="group_name" value=""
                            class=" input-lg custom-form-input" (keydown)="checkIfValid()" required />
                          <mat-error *ngIf="addPartNumber.controls.group_name.hasError('required')">
                            This field cannot be empty
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-6 col-sm-12">
                      <label for="part_number">Smart Batch Number</label>
                      <div class="relative">
                        <mat-form-field class="btn-block car-input" floatPlaceholder="never">
                          <input matInput placeholder="Smart Batch Number" title="Smart Batch Number" formControlName="part_number"
                            value="" class=" input-lg custom-form-input" (keydown)="checkIfValid()" required />
                          <mat-error *ngIf="addPartNumber.controls.part_number.hasError('required')">
                            This field cannot be empty
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 form-block submit-block">
                  <button type="button" mat-raised-button
                    class="car-btn btn-block text-uppercase  btn-lg font-light fontcolor-white" id="saveClose" style="max-width: 300px;"
                    color="accent" title="Submit and Close" [disabled]="submitted" (click)="AddPart(1)">Submit and Close</button>
                  <button *ngIf="!partId" type="button" mat-raised-button
                    class="car-btn btn-block text-uppercase  btn-lg font-light fontcolor-white" id="saveAdd" style="max-width: 300px;"
                    color="accent" title="Submit and Add New Part" [disabled]="submitted" (click)="AddPart(2)">Submit and Add New Part</button>
                </div>
              </form>
            </div>
          </div>
          <!-- Add New Smart Batch end -->
        </div>
        <div *ngIf="showPartList">
          <div>
            &nbsp;
          </div>
          <div>
            &nbsp;
          </div>
          <div>
            &nbsp;
          </div>
          <app-shopSmartBatchPartsComponent></app-shopSmartBatchPartsComponent>
        </div>
      </div>
    </div>
  </div>
</div>