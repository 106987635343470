import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, Params } from '@angular/router';
import { globalConstants } from "../../../../environments/globalConstants";
import { GlobalService } from "../../../../environments/global.service";
import { MatDialog} from '@angular/material/dialog';
import { UsercontactsComponent } from '../../../usercontacts/usercontacts.component';
import { ImagetooldialogComponent } from '../../../imagetooldialog/imagetooldialog.component';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-viewemp',
  templateUrl: './viewemp.component.html',
  styleUrls: ['./viewemp.component.scss']
})
export class ViewempComponent implements OnInit, OnDestroy {
 public currentUserInfo;
 public empId='';
 public userInfo='';
 public empData:any={};
 public photo = '';
 public docs = [];
 public licenseDoc = [];
 public docImgPath = globalConstants.S3_BUCKET_URL + globalConstants.EMP_DOCUMENT + globalConstants.IMG_THUMB;
 public docPath = globalConstants.S3_BUCKET_URL + globalConstants.EMP_DOCUMENT;
 public subscriptionDataForAPI: Subscription = new Subscription();

  constructor(  private router: Router, private route: ActivatedRoute,public gs: GlobalService, public dialog: MatDialog) {
    let user = this.gs.getCurrentUser();
    if (user) {
      this.currentUserInfo = user;
    } else {
      this.router.navigate(["/shops/login"]);
    }
    setTimeout(()=>{
      let isEnable=this.gs.getAcl('employees','views');
      if(!isEnable){
        this.router.navigate(['shops/action/forbidden']);
      }
    },globalConstants.RELOAD_WAIT);
    this.gs.setMetaData("SHOPS","VIEW_EMPLOYEE_DETAIL")
   }

  ngOnInit() {
    this.getParams();
    this.userInfo = "user_id=" + this.currentUserInfo["id"] + "&user_type=" + this.currentUserInfo["user_type"] + "&shop_id=" + this.currentUserInfo["shop_id"];
    let content=this.userInfo += '&emp_id=' + this.empId;
    this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_GET_SHOP_USER,content).subscribe((res:any)=>{
      if(res.result=="1"){
        this.empData=res.data;
        this.photo=res.data.emp_photo;
        this.licenseDoc=res.data.emp_license;
        this.docs=res.data.emp_docs;
      }
    });
  }
  getParams() {
    this.route.params.subscribe((params: Params) => {
      this.empId = params["emp_id"];
    });
  }
  editEmp(){
    this.router.navigate(['shops/employee/edit/'+this.empId]);
  }
  editContacts() {
    let dialogRef = this.dialog.open(UsercontactsComponent, {
      panelClass: 'car-dialog-form',
      width: "800px",
      data: { emp_id:this.empId,user_id: this.currentUserInfo.id, user_type: this.currentUserInfo.user_type,url:globalConstants.API_SHOP_EMP_CONTACTS }
    });
    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  /* image rotation dialog  */
  imageToolDialog(current,i,type,imgaar): void {
    let dialogRef = this.dialog.open(ImagetooldialogComponent, {
        panelClass: ['imageToolDialog'],
        width: "100%",
        data: {event: current,index:i,imgType:type,imgArray:imgaar,customerId:this.gs.getCurrentUser()["id"]}
    });

    dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
    });
  }

  invokeDownload(url,type,fileName=''){
    let localFileName = 'DownloadedFile',localNameExt,localName;
    if(type != ''){
      localName = fileName.split('.');
      localNameExt = localName[localName.length-1];
      localFileName = type+'.'+localNameExt;
    }
    //console.log(localFileName);
    this.gs.downloadAsset(url,localFileName);
  }
  isAllowed(action){
    return this.gs.getAcl('employees',action);
  }

  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
  }
}
