<div class="panel panel-primary service-request-grid" id="mainCompotents">
    <div class="panel-heading padding-left-15">
        <div class="row">
            <div class="col-xs-8">
                <h3 class="panel-title srGrid-title">SERVICE REQUESTS GRID</h3>
            </div>
            <div class="col-xs-4 header-btns">
                <div class="" style="text-align: right;">
                    <!-- <div class="col-sm-3 uc-ngx-select change-workflow-dropdowm">
                        <ngx-select class="display-full hidden-xs"
                                    [items]="searchByStatus"
                                    [noAutoComplete]="true"
                                    placeholder="Change workflow">
                        </ngx-select>
                    </div> -->
                    <button [matMenuTriggerFor]="menu" class="settingsIcon text-white"><i class="fa fa-cog"></i></button>
                    <mat-menu #menu="matMenu" class="mat-dropdown-menu" [overlapTrigger]="false" xPosition="before">
                        <button mat-menu-item >SR Workflow</button>
                        <button mat-menu-item>My job Queue</button>                   
                        <button mat-menu-item>On the lot</button>                        
                    </mat-menu>
                    <button class="settingsIcon text-white fa fa-filter" title="Filters" type="button"></button>
                    <button class="settingsIcon text-white fa fa-calendar" title="Filters" type="button"></button>
                </div>
            </div>
        </div>

    </div>
    <div class="panel-body bgcolor-lightgrey">
        <div class="sr-operations">
            <div class="flex">
                <div class="search-section flex">
                    <input matInput placeholder="search keyword" title="Search Keyword" formControlName="search_keyword"
                            class="form-control input-lg custom-form-input" />
                            <button class="search-btn"><i class="fa fa-search"></i></button>
                </div>
                <ul class="assignee-section flex padding-5">
                    <li class="assignee selected">
                        <img src="../../../../assets/img/user-1.webp" alt="Person icon">
                    </li>
                    <li class="assignee">
                        <img src="../../../../assets/img/user-2.webp" alt="Person icon">
                    </li>
                </ul>
                <div class="quickAction-dropdown margin-right-10">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <mat-select [formControl]="toppingsQuickaction" class="form-control input-lg custom-form-input" placeholder="Quick actions" multiple>
                        <mat-select-trigger>
                            {{toppingsQuickaction.value?.[0] || ''}}
                            <span *ngIf="(toppingsQuickaction.value?.length || 0) > 1" class="example-additional-selection">
                            (+{{(toppingsQuickaction.value?.length || 0) - 1}} {{toppingsQuickaction.value?.length === 2 ? 'other' : 'others'}})
                            </span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let topping of toppingListQuickaction" [value]="topping">{{topping}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <button mat-raised-button class="margin-right-10 submit-btn mat-accent" type="submit">Submit</button>
                <button mat-raised-button class="clear-btn margin-right-10" type="reset">Clear</button>
                <button mat-raised-button class="more-btn" type="more">More</button>
            </div>
            <div class="grid-list-view-icon">
                <div class="padding-0">
                     <ul class="list-inline flex">
                         <li class="list-inline-item">
                             <div class="action-icon-color">
                                 <i class="fa fa-list" aria-hidden="true"></i>
                             </div>
                         </li>
                         <li class="list-inline-item">
                             <div class="action-icon-color">
                                 <i class="fa fa-th" aria-hidden="true"></i>
                             </div>
                         </li>
                     </ul>
                </div>
             </div>

        </div>
        <div class="filter-items">
                <div class="status-dropdown padding-right-10">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <mat-select [formControl]="toppings" class="form-control input-lg custom-form-input" placeholder="Select status" multiple>
                        <mat-select-trigger>
                            {{toppings.value?.[0] || ''}}
                            <span *ngIf="(toppings.value?.length || 0) > 1" class="example-additional-selection">
                            (+{{(toppings.value?.length || 0) - 1}} {{toppings.value?.length === 2 ? 'other' : 'others'}})
                            </span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="jobStatus-dropdowm padding-right-10">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                        <mat-select [formControl]="toppingsTemp" class="form-control input-lg custom-form-input" placeholder="Job status" multiple>
                        <mat-select-trigger>
                            {{toppingsTemp.value?.[0] || ''}}
                            <span *ngIf="(toppingsTemp.value?.length || 0) > 1" class="example-additional-selection">
                            (+{{(toppingsTemp.value?.length || 0) - 1}} {{toppingsTemp.value?.length === 2 ? 'other' : 'others'}})
                            </span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let topping of toppingListTemp" [value]="topping">{{topping}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
        </div>
        <div class="srList-menu">
            <ul class="srMenu-lsit">
                <li class="list-item selected" (click)="openBottomSheet()">Assignee <span>+2</span>&nbsp;<i class="fa fa-angle-down"></i> </li>
                <li class="list-item selected">Change workflow <i class="fa fa-angle-down"></i></li>
                <li class="list-item">Select Status <i class="fa fa-angle-down"></i></li>
                <li class="list-item">Job Status <i class="fa fa-angle-down"></i></li>
                <li class="list-item">Active request <i class="fa fa-angle-down"></i></li>
            </ul>
        </div>
        <div class="jobBoard-wraper">
            <div class="sr-header">
                <ul class="hearer-list">
                    <li class="header-item">
                        <div class="item-child"><h3>All Open (Appt/Pending)</h3></div>
                        <div class="item-child toggle-section"><mat-slide-toggle class="mat-slide-toggle padding-right-10"
                                labelPosition="before"></mat-slide-toggle>
                            <div class="padding-right-10"><i class="fa fa-file-text"></i><span> 0</span></div>
                        </div>
                    </li>
                    <li class="header-item">
                        <div class="item-child"><h3>In Progress / Inspection</h3></div>
                        <div class="item-child"><div class="padding-right-10"><i class="fa fa-file-text"></i><span> 0</span></div></div>

                    </li>
                    <li class="header-item">
                        <div class="item-child"><h3>Approval Awaited</h3></div>
                        <div class="item-child"><div class="padding-right-10"><i class="fa fa-file-text"></i><span> 0</span></div></div>

                    </li>
                    <li class="header-item">
                        <div class="item-child"><h3>Work In Progress</h3></div>
                        <div class="item-child"><div class="padding-right-10"><i class="fa fa-file-text"></i><span> 0</span></div></div>

                    </li>
                    <li class="header-item">
                        <div class="item-child"><h3>On The Lot</h3></div>
                        <div class="item-child"><div class="padding-right-10"><i class="fa fa-file-text"></i><span> 0</span></div></div>

                    </li>
                    <li class="header-item">
                        <div class="item-child"><h3>Invoiced</h3></div>
                        <div class="item-child"><div class="padding-right-10"><i class="fa fa-file-text"></i><span> 0</span></div></div>

                    </li>
                    <li class="header-item">
                        <div class="item-child"><h3>Paid</h3></div>
                        <div class="item-child"><div class="padding-right-10"><i class="fa fa-file-text"></i><span> 0</span></div></div>

                    </li>
                </ul>
            </div>
            <div class="row srGrid-row">
                <div class="srGrid-container col-md-2">
                    <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="todo"
                        [cdkDropListConnectedTo]="[doneList, workList, gitList, paidList, onthelotList, invoicedtList]"
                        class="srGrid-list" (cdkDropListDropped)="drop($event)">
                        <div class="srGrid-box" *ngFor="let item of todo" cdkDrag>
                            <!-- {{ item }} -->
                            <app-service-card></app-service-card>
                        </div>
                    </div>
                </div>

                <div class="srGrid-container col-md-2">
                    <div cdkDropList #doneList="cdkDropList" [cdkDropListData]="done"
                        [cdkDropListConnectedTo]="[todoList, workList, gitList, paidList, onthelotList, doneList, invoicedtList]"
                        class="srGrid-list" (cdkDropListDropped)="drop($event)">
                        <div class="srGrid-box" *ngFor="let item of done" cdkDrag>
                            <!-- {{ item }} -->
                            <app-service-card></app-service-card>
                        </div>
                    </div>
                </div>

                <div class="srGrid-container col-md-2">
                    <div cdkDropList #workList="cdkDropList" [cdkDropListData]="work"
                        [cdkDropListConnectedTo]="[todoList, doneList, gitList, paidList, onthelotList, workList, invoicedtList]"
                        class="srGrid-list" (cdkDropListDropped)="drop($event)">
                        <div class="srGrid-box" *ngFor="let item of work" cdkDrag>
                            <!-- {{ item }} -->
                            <app-service-card></app-service-card>
                        </div>
                    </div>
                </div>

                <div class="srGrid-container col-md-2">
                    <div cdkDropList #gitList="cdkDropList" [cdkDropListData]="git"
                        [cdkDropListConnectedTo]="[todoList, doneList, workList, paidList, onthelotList, gitList, invoicedtList]"
                        class="srGrid-list" (cdkDropListDropped)="drop($event)">
                        <div class="srGrid-box" *ngFor="let item of git" cdkDrag>
                            <!-- {{ item }} -->
                            <app-service-card></app-service-card>   
                        </div>
                    </div>
                </div>
                <div class="srGrid-container col-md-2">
                    <div cdkDropList #onthelotList="cdkDropList" [cdkDropListData]="onthelot"
                        [cdkDropListConnectedTo]="[todoList, doneList, workList, paidList, onthelotList, gitList, invoicedtList]"
                        class="srGrid-list" (cdkDropListDropped)="drop($event)">
                        <div class="srGrid-box" *ngFor="let item of onthelot" cdkDrag>
                            <!-- {{ item }} -->
                            <app-service-card></app-service-card>
                        </div>
                    </div>
                </div>

                <div class="srGrid-container col-md-2">
                    <div cdkDropList #invoicedtList="cdkDropList" [cdkDropListData]="invoiced"
                        [cdkDropListConnectedTo]="[todoList, doneList, workList, paidList, onthelotList, gitList, invoicedtList]"
                        class="srGrid-list" (cdkDropListDropped)="drop($event)">
                        <div class="srGrid-box" *ngFor="let item of invoiced" cdkDrag>
                            <!-- {{ item }} -->
                            <app-service-card></app-service-card>
                        </div>
                    </div>
                </div>

                <div class="srGrid-container col-md-2">
                    <div cdkDropList #paidList="cdkDropList" [cdkDropListData]="paid"
                        [cdkDropListConnectedTo]="[todoList, doneList, workList, paidList, onthelotList, gitList, invoicedtList]"
                        class="srGrid-list" (cdkDropListDropped)="drop($event)">
                        <div class="srGrid-box" *ngFor="let item of paid" cdkDrag>
                            <!-- {{ item }} -->
                            <app-service-card></app-service-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    </div>
</div>