import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { globalConstants } from "../../../../environments/globalConstants";
import {GlobalService} from "../../../../environments/global.service";
import { globalFunctions } from 'src/environments/globalFunctions';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-parts-bulk-operations-dialog',
  templateUrl: './parts-bulk-operations-dialog.component.html',
  styleUrls: ['./parts-bulk-operations-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PartsBulkOperationsDialogComponent implements OnInit {
  public updatePartInfoForm : FormGroup;
  public currentCustomer :any ;
  public partsIds:any;
  public bulkOpr :any;
  public disPlayErrMsg : boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public globalService : GlobalService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PartsBulkOperationsDialogComponent>
  ) { 
     this.partsIds = data.partIds;
     this.bulkOpr = data.bulkOpr;
  }


  ngOnInit(): void {
    this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
    this.createUpdatePartInfoForm();
  }

  createUpdatePartInfoForm(){
    this.updatePartInfoForm = this.formBuilder.group({
      bulk_cost: ['', [Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
      bulk_price: ['', [Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
      bulk_quantity:['',Validators.pattern(/^-?\d+(?:\.\d+)?$/)],
      bulk_low_stock_value:['',Validators.pattern(/^-?\d+(?:\.\d+)?$/)],
      user_type: this.currentCustomer.user_type,
      user_id: this.currentCustomer.id,
      part_ids : this.partsIds,
      bulk_opr : this.bulkOpr
  });
  }

  handleUpdateBulkParts() {
    if (this.updatePartInfoForm.valid) {
      if (this.updatePartInfoForm.value.bulk_cost !== '' || this.updatePartInfoForm.value.bulk_price !== '' || this.updatePartInfoForm.value.bulk_quantity !== '' || this.updatePartInfoForm.value.bulk_low_stock_value !== '') {
        this.disPlayErrMsg = false;
        this.globalService.callAPI(globalConstants.API_BULK_OPERATIONS_PARTS_LIST, this.updatePartInfoForm.value).subscribe((res: any) => {
          if (res.result == '1') {
            this.globalService.snackbar('success', res.message);
            this.dialogRef.close(1);
          } else {
            this.globalService.snackbar('error', res.message);
          }
        })
      } else {
        this.disPlayErrMsg = true;
      }
    }
  }

onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
        && (charCode < 48 || charCode > 57))
        return false;
    return true;
}

convertIntoDecimal(event,frmControlName,beforeDecimalDigit){
  let fieldValue = this.updatePartInfoForm.get(frmControlName).value
  let result= this.globalService.processDecimalValue(fieldValue,beforeDecimalDigit);
  this.updatePartInfoForm.get(frmControlName).setValue(result);
  return globalFunctions.validateNumbersWithDecimal(event,3);
}

disableKey(event) {
  if (event.ctrlKey == true && (event.which == '67' || event.which == '86' || String.fromCharCode(event.which).toLowerCase() == 's')) {
      event.preventDefault();
  }
}


}
