import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-common-dialog-big',
  templateUrl: './common-dialog-big.component.html',
  styleUrls: ['./common-dialog-big.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommonDialogBigComponent implements OnInit {
  public dialogData : any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CommonDialogBigComponent>
  ) { 
     this.dialogData = data;
  }

  ngOnInit(): void {  
  }

  handleShopProfileCompletion(){
    this.dialogRef.close({
      status:1
   });
  }

  handleReviewAndPaymentConfigSubmit(updatedReviewAndPaymentSettingObj:any){
    this.dialogRef.close({
      status:true,
      reviewAndPaymentSettingObj:updatedReviewAndPaymentSettingObj
   });
  }
}
