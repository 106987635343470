import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { globalFunctions } from '../../../../environments/globalFunctions';
import * as moment from 'moment';
@Component({
  selector: 'edit-servicerequest-fields',
  templateUrl: './edit-servicerequest-fields.component.html',
  styleUrls: ['./edit-servicerequest-fields.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditServiceRequestFieldsComponent implements OnInit {
  public requestedDate:any ='';
  public param:any={};  
  public serviceEdit: FormGroup;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder,public gs: GlobalService,public dialogRef: MatDialogRef<EditServiceRequestFieldsComponent>) {
    this.param=data;
  }

  ngOnInit() {  
    this.serviceEdit = this.fb.group({
      request_car_id: [this.param.serviceInfo.car_id],
      request_id: [this.param.serviceInfo.id, [Validators.required]],
      request_title: [this.param.serviceInfo.title],
      distance_covered: [{value:this.param.serviceInfo.present_mileage,disabled: true}, [Validators.required,Validators.pattern(/^[0-9.]+$/)]],
      request_notes: [this.param.serviceInfo.notes],
      requested_delivery_date : [this.param.serviceInfo.requested_delivery_date],
      est_service_time : [this.param.serviceInfo.est_hours],
      is_walkin_customer_vehicle:[this.param.serviceInfo.is_walkin_customer_vehicle],
      is_walkin_customer:[this.param.serviceInfo.is_walkin_customer]
    });
    this.requestedDate = this.param.serviceInfo.requested_delivery_date;
    if(this.param.serviceInfo.is_walkin_customer_vehicle == '0'){
      this.serviceEdit.controls.distance_covered.enable();
    }
  }

  handleSRRequestDate(event:any){
       let convertFormateForAPI = moment(event.value).format('MM/DD/YYYY hh:mm A Z');
       this.serviceEdit.controls.requested_delivery_date .setValue(convertFormateForAPI);
  } 

  editData(){
    if (this.serviceEdit.valid) {
      let formdata=new FormData();
      for(var i in this.serviceEdit.value){
          formdata.append(i,this.serviceEdit.value[i]);
      }
      this.gs.formData(globalConstants.API_EDIT_SERVICE_FIELDS,formdata).subscribe((res) => {
        if (res.result == 1 ) { 
            this.dialogRef['response'] = res.data;               
            this.dialogRef.close();
        }else{
          this.gs.snackbar('error', res.message);
        }
      },(error)=>{});
    }
  }

  onlyNumericKey(event) {
    return globalFunctions.onlyDecimalNumberKey(event);
  }
}
