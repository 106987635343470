import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';


import { Router } from '@angular/router';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';

@Component({
  selector: 'app-searchcar',
  templateUrl: './searchcar.component.html',
  styleUrls: ['./searchcar.component.scss']
})
export class SearchcarComponent implements OnInit {

  public currentUserInfo;
  status;
  customerVehcileInfo;
  shopCustomerDetails;
  currentCustomer;
  searchBy;  
  constructor(public dialogRef: MatDialogRef<SearchcarComponent>,@Inject(MAT_DIALOG_DATA) public data: any, public globalService: GlobalService, private router: Router, private formBuilder: FormBuilder) {
    this.status = data.status;
    this.searchBy = data.searchBy;
    this.customerVehcileInfo = data.customerVehcileInfo;
    this.shopCustomerDetails = data!.shopCustomerDetails;
    this.currentCustomer = data.currentCustomer;
    if(localStorage.getItem("loggedInUser")) {
      var localData:any = localStorage.getItem("loggedInUser")
      this.currentUserInfo = JSON.parse(localData);
    }
  }

  ngOnInit() { } 

  createSR(){
    this.router.navigate(['shops/new-service-request/'+this.customerVehcileInfo.vehicle_info.customer_id+'/'+this.customerVehcileInfo.vehicle_info.vehicle_id]);
    this.dialogRef.close({status:'jump'});
  }

  createSubletSR(){
    this.router.navigate(['shops/new-service-request/'+this.shopCustomerDetails.id+'/'+this.customerVehcileInfo.vehicle_info.vehicle_id]);
    this.dialogRef.close({status:'jump'});
  }

  createSRForAnotherUser(){
    this.router.navigate(['shops/new-service-request/'+this.shopCustomerDetails.id+'/'+this.customerVehcileInfo.vehicle_info.vehicle_id]);
    this.dialogRef.close({status:'jump'});
  }

  transferOwnerShip(){
    this.globalService.confirmDialog("Transfer Ownership", "Request "+ this.customerVehcileInfo.vehicle_info.customer_name +" to transfer vehicle Ownership to "+this.shopCustomerDetails.first_name + " " + this.shopCustomerDetails.last_name+"?").subscribe((res:any) => {
      if (res != null) {
        var opr = 'owner_name='+ this.shopCustomerDetails.first_name + " " + this.shopCustomerDetails.last_name+" &owner_email="+ this.shopCustomerDetails.email.replaceAll('+','%2B') +" &customer_id="+this.customerVehcileInfo.vehicle_info.customer_id+" &vehicle_id="+this.customerVehcileInfo.vehicle_info.vehicle_id;
        this.globalService.callAPI(globalConstants.API_TRANSFER_OWNERSHIP_URL,opr).subscribe((data:any) => {
            var response = data;
            if (response.result == "1") {
                this.globalService.snackbar("success", data.message);
             var click = <HTMLElement>document.getElementById("close")
             click.click();
                this.dialogRef.close({status:'jump'});        
            } else {
                this.globalService.snackbar("error", data.message);
            }
        });
      }
    });
  }
}
