import { Component, Inject, ChangeDetectorRef, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { globalFunctions } from '../../../../environments/globalFunctions';
import {Http, Headers, Response} from '@angular/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
const EMAIL_REGEX = globalConstants.EMAIL_REGEX;

@Component({
  selector: 'app-editcustomerdialog',
  templateUrl: './editcustomerdialog.component.html',
  styleUrls: ['./editcustomerdialog.component.scss']
})
export class EditcustomerdialogComponent implements OnInit {
    public customerEdit: FormGroup;
    public submitted: boolean = false;
    public customerID;
    public diaplayEmail;
    public displayMobileNumber;
    public checkUserEmail: boolean = false;
    public getEmailCheck;
    public phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    public customerDetail:any={}; 
    public reqOrigin=globalFunctions.requestOrigin()
    constructor( @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditcustomerdialogComponent>, private formBuilder: FormBuilder, private globalService: GlobalService, private http: Http, private router: Router, public cdr: ChangeDetectorRef) {
        this.customerID = data.customer_id;
        this.diaplayEmail = data.email;
        this.displayMobileNumber = data.mobile_phone;
        this.globalService.diaLogObj(this.dialogRef);
        this.customerDetail=data.customer;
        this.initializeForBuilders();
    }

    ngOnInit() {
    }
     
    initializeForBuilders() {
        this.customerEdit = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
            mobile_phone: ['', [Validators.required]],
            customer_id:this.customerID,
            email_activation_status: [this.customerDetail.email_activation_status, [Validators.required]],
            email_activation:this.customerDetail.email_activation_status,
            activation_status:[this.customerDetail.status, [Validators.required]],
            request_origin:['', [Validators.required]],
            user_type:["Super Admin"],
        });
    }
    
    onlyNumericKey(event) {
      return globalFunctions.onlyDecimalNumberKey(event);
    }
    
    checkIfValid() {
        if (this.customerEdit.valid) {
            this.submitted = false;
        }
    }
    
    checkEmail(){
        let email=this.customerEdit.controls.email;
        let errorEmail=false;
        if(email.hasError('required') || email.hasError('pattern')){
            errorEmail=true;
        }
        var opr = "email=" + this.customerEdit.value.email + "&mobile_phone=" + this.customerEdit.value.mobile_phone;
        (!errorEmail) && this.globalService.callAPI(globalConstants.API_CHECK_CUSTOMER_INFORMATION_URL, opr).subscribe((data) => {
          this.getEmailCheck = data;
            if(this.getEmailCheck.result == "1"){
                this.checkUserEmail = true;
            }else{
                this.checkUserEmail = false;
            }
        })
    }
    
    phoneValidation(key){
       this.globalService.preferedServicesPhoneValidity(this.customerEdit,key);
       this.checkIfValid();
    }
    
    editCustomerData() {
        // console.log("Post", this.customerEdit.value)
        if(this.customerEdit.valid) {
            var data = new FormData();
            for(let i in this.customerEdit.value){
               data.append(i, this.customerEdit.value[i]);
            }
            
            this.globalService.formData(globalConstants.API_EDIT_CUSTOMER_URL, data).subscribe((data) => {
                if(data.code == "510"){
                    this.dialogRef.close(); 
                    this.globalService.snackbar('success', data.message);
                    this.router.navigate(["/admin/list-customers"])
                }else{

                    this.customerEdit.controls.email.setValue(this.diaplayEmail);
                    this.customerEdit.controls.mobile_phone.setValue(this.displayMobileNumber);
                    this.globalService.snackbar('error', data.message);
                }
            });
            
        } else {
            this.submitted = true;
        }
    }

}
