<form (ngSubmit)="addNewVehicle()" [formGroup]="newVehicle" method="POST" enctype="multipart/form-data"
    autocomplete="off" class="appointment-add-vehicle">
    <div mat-dialog-title class="relative">
        Add Vehicle
        <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
            <mat-icon class="notranslate close-icon-lg" aria-label="Example icon-button with a heart icon">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="add-vehicle-content">

        <div class="form-group col-sm-12">
            <label for="make" class="col-sm-3">Make</label>
            <div class='relative uc-ngx-select col-sm-9' *ngIf="!newMake">
                <ngx-select class="display-full margin-bottom-20" formControlname="sel_make" [items]="carMakeList"
                    (select)="getOptionMakes($event)" placeholder="Select Make*">
                </ngx-select>
                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                    <mat-error *ngIf="newVehicle.controls.sel_make.hasError('required')">
                        This field cannot be empty
                    </mat-error>
                </div>
                <a href="javascript:void(0);" color="accent" class="others-link make-link"
                    (click)="anotherMake(true)">Another Make</a>
            </div>
            <div class='relative col-sm-9' *ngIf="newMake">
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill" *ngIf="newMake">
                    <input matInput placeholder="Make*" title="Make" formControlName="make"
                        class="form-control input-lg custom-form-input" maxlength="200"/>
                    <mat-error *ngIf="newVehicle.controls.make.hasError('required')">
                        This field cannot be empty
                    </mat-error>
                </mat-form-field>
                <a href="javascript:void(0);" class="others-link make-link" (click)="anotherMake(false)">Existing Make</a>
            </div>

        </div>
        <div class="form-group col-sm-12">
            <label for="year" class="col-sm-3">Year</label>
            <div class='relative uc-ngx-select col-sm-9' *ngIf="!newYear">
                <ngx-select class="display-full margin-bottom-20" 
                [(ngModel)]="selectedYear"  
                [ngModelOptions]="{standalone: true}"            
                [items]="yearsList"
                (select)="getOptionYears($event)" 
                [disabled]="disabledYear" 
                placeholder="Select Year*">
                </ngx-select>
                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                    <mat-error *ngIf="newVehicle.controls.sel_year.hasError('required')">
                        This field cannot be empty
                    </mat-error>
                </div>
                <a href="javascript:void(0);" class="others-link year-link" (click)="anotherYear(true)">Another Year</a>
            </div>

            <div class='relative col-sm-9' *ngIf="newYear">
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill" *ngIf="newYear">
                    <input matInput placeholder="Year*" title="Year" formControlName="year" [disabled]="disabledYear"
                        class="form-control input-lg custom-form-input" maxlength="4"
                        (keypress)="onlyNumericKey($event)" />
                    <mat-error *ngIf="newVehicle.controls.year.hasError('required')">
                        This field cannot be empty
                    </mat-error>
                    <mat-error *ngIf="newVehicle.controls.year.hasError('pattern') ">
                        Invalid year
                    </mat-error>
                    <mat-error *ngIf="newVehicle.controls.year.hasError('minLength') ">
                        Invalid year
                    </mat-error>
                </mat-form-field>
                <a href="javascript:void(0);" class="others-link year-link" (click)="anotherYear(false)">Existing Year</a>
            </div>

        </div>
        <div class="form-group col-sm-12">
            <label for="model" class="col-sm-3">Model</label>
            <div class='relative uc-ngx-select col-sm-9' *ngIf="!newModel">
                <ngx-select class="display-full margin-bottom-20" 
                [(ngModel)]="selectedModel"
                [ngModelOptions]="{standalone: true}" 
                [items]="modelList"
                (select)="getOptionModels($event)" 
                [disabled]="disabledModel" placeholder="Select Model*">
                </ngx-select>
                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                    <mat-error *ngIf="newVehicle.controls.sel_model.hasError('required')">
                        This field cannot be empty
                    </mat-error>
                </div>
                <a href="javascript:void(0);" class="others-link model-link" (click)="anotherModel(true)">Another Model</a>
            </div>
            <div class='relative col-sm-9' *ngIf="newModel">
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput placeholder="Model*" [disabled]="disabledModel" title="Model" formControlName="model"
                        class="form-control input-lg custom-form-input" maxlength="200"/>
                    <mat-error *ngIf="newVehicle.controls.model.hasError('required')">
                        This field cannot be empty
                    </mat-error>
                </mat-form-field>
                <a href="javascript:void(0);" class="others-link model-link" (click)="anotherModel(false)">Existing Model</a>
            </div>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions class="inquiry-action relative">
        <div class="text-right flexBtns">
            <button type="submit" class="font-bold mat-elevation-z0 mat-raised-button mat-accent margin-right-10">Submit</button>
            <button type="button" mat-raised-button mat-dialog-close class="font-bold">Close</button>
        </div>
    </mat-dialog-actions>
</form>