import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-unauthorised',
  templateUrl: './global-unauthorised.component.html',
  styleUrls: ['./global-unauthorised.component.scss']
})
export class GlobalUnauthorisedComponent implements OnInit {
  @Input() gAnauthObj;
  constructor() { }

  ngOnInit(): void {
  }

}
