<!-- List Labour Rates -->
<div class="car-panel manage-labor-rates" id="mainCompotents">
    <div class="panel panel-primary" id="addCustomerPanel">
        <div class="panel-heading padding-left-15">
            <h3 class="panel-title">
                Manage Labor Rates
            </h3>
            <button mat-mini-fab class="custom-btn-back" title="Back" (click)="globalService.goBack();">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
        <div class="panel-body bgcolor-lightgrey">
            <div id="signupContent" class="bgcolor-lightgrey">
                <div id="genericJobsList" class="table-responsive" style="min-height:250px;padding-bottom:180px;">
                    <p class="text-right">
                        <a class="hover-link" title="Add New Customer Type" *ngIf="isAllowed('addNew')" href="javascript:void(0)" (click)="openInsceptionReport()"><i class="fa fa-plus la-lg margin-right-5"></i>Add New Customer Type</a>
                    </p>
                    <table class="table table-striped table-hover table-condensed sortable">
    
                        <thead>
                            <tr>
                                <th>Customer Type</th>
                                <th class="lbr-rate-th">Labor Rate (per hour)</th>
                                <th class="actions-th">Actions</th>
                            </tr>
                        </thead>
    
                        <tbody id="genericJobsListView" *ngFor="let getCustomerType of getCustomerTypes">
                               <tr class="active">
                                <td class="customer-type-col">
                                    <input type="text" class="form-control txt-tax-value" [value]='getCustomerType.type' [ngModelOptions]="{standalone: true}"  [(ngModel)] = "getCustomerType.type" maxlength="100">
                                </td>
                                <td>
                                    <input type="text" class="form-control txt-tax-value" maxlength='14' (keyup)="vkFloat($event,getCustomerType.type)" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event,this)" [value]='getCustomerType.labor_rate' [ngModelOptions]="{standalone: true}" [(ngModel)] = "getCustomerType.labor_rate">
                                </td>
                                <td>
                                    <button *ngIf="isAllowed('edits')" mat-raised-button type="button" color="warn" class="action-update-btn" (click)="editCustomerTypes(getCustomerType,getCustomerType.id,getCustomerType.labor_rate,getCustomerType.type)">Update</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

  
</div>