import { Component, OnInit,Output, Input,EventEmitter } from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { GlobalService } from '../../../../environments/global.service';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
  public color: ThemePalette = 'accent';
  public isDisble = false;
  public startDate;
  public endDate;
  public isChanged = false;
  @Input() inputValueObj;
  @Output() SelectTime = new EventEmitter<any>();
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  constructor(
     public globalService: GlobalService
  ) { }

  ngOnInit(): void {
     if( typeof(this.inputValueObj) !== 'undefined'){
        this.range.controls.start.setValue(this.inputValueObj.startDate);
        this.range.controls.end.setValue(this.inputValueObj.endDate);
     }
    this.globalService.isDateRange.subscribe((r)=>{
      if(r == false){
         this.range.reset();
         this.isDisble = r;
      }else{
        this.isDisble = r;
      }
    }) 
  }

  checkChange(){
    //  console.log("this is change check",this.range.value);
     this.SelectTime.emit(this.range.value);
  }
}
