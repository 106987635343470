<form (ngSubmit)="verifyInfo()" [formGroup]="info" method="POST" enctype="multipart/form-data" autocomplete="off">
  <div mat-dialog-title class="relative">
    Vehicle Transfer
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
      <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="form-group col-sm-12 col-lg-6">
      <label for="first_name">First Name</label>
      <mat-form-field class="btn-block car-input" floatPlaceholder="never">
        <input matInput placeholder="First Name*" autocomplete="first_name" title="First Name"
          formControlName="first_name" class="form-control input-lg custom-form-input" />
        <mat-error *ngIf="info.controls.first_name.hasError('required')">
          This field cannot be empty
        </mat-error>
      </mat-form-field>
    </div>
    <div class="form-group col-sm-12 col-lg-6">
      <label for="last_name">Last Name</label>
      <mat-form-field class="btn-block car-input" floatPlaceholder="never">
        <input matInput placeholder="Last Name*" autocomplete="last_name" title="Last Name" formControlName="last_name"
          class="form-control input-lg custom-form-input" />
        <mat-error *ngIf="info.controls.last_name.hasError('required')">
          This field cannot be empty
        </mat-error>
      </mat-form-field>
    </div>
    <div class="form-group col-sm-12 col-lg-6 uc-ngx-select">
      <label for="State">State</label>
      <ngx-select class="display-full margin-bottom-20" [items]="states" (select)="getCities($event)" placeholder="Select State *">
      </ngx-select>
      <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
        <mat-error *ngIf="info.controls.state.hasError('required')">
          This field cannot be empty
        </mat-error>
      </div>
    </div>
    <div class="form-group col-sm-12 col-lg-6 uc-ngx-select">
      <label for="City">City</label>
      <ngx-select class="display-full margin-bottom-20" [items]="cities" (select)="selectedCity($event)" placeholder="Select City *">
      </ngx-select>
      <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
        <mat-error *ngIf="info.controls.city.hasError('required')">
          This field cannot be empty
        </mat-error>
      </div>
      
    </div>
    
    <div class="form-group col-sm-12 col-lg-6">
      <label for="zip">Zip</label>
      <mat-form-field class="btn-block car-input" floatPlaceholder="never">
        <input matInput placeholder="Zip*" title="Zip" formControlName="zip"
          class="form-control input-lg custom-form-input" />
        <mat-error *ngIf="info.controls.zip.hasError('required')">
          This field cannot be empty
        </mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="inquiry-action relative">
    <div class="text-right">
      <button type="submit" class="font-bold mat-elevation-z0 mat-raised-button mat-accent">Submit</button>
      <button type="button" mat-raised-button mat-dialog-close class="font-bold">Close</button>
    </div>
  </mat-dialog-actions>
</form>