import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { globalFunctions } from '../../../../environments/globalFunctions';
import {Http, Headers, RequestOptions, Response} from '@angular/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';

@Component({
  selector: 'app-add-complaint-dialog',
  templateUrl: './add-complaint-dialog.component.html',
  styleUrls: ['./add-complaint-dialog.component.scss']
})
export class AddComplaintDialogComponent implements OnInit {
    public addComplaintForm: FormGroup;
    public submitted = false;
    public currentCustomer;
    public base64value = "";
    public selectedKey = "";
    public currentComplaintPicture='';
    versionMismatch:boolean;
    constructor( @Inject(MAT_DIALOG_DATA) public data: any,private http: Http, public dialogRef: MatDialogRef<AddComplaintDialogComponent>, private formBuilder: FormBuilder, public globalService: GlobalService, private router: Router) {
        this.versionMismatch = data.version_mismatch || false;
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInCustomer"));
        this.initializeForm();
        this.globalService.diaLogObj(this.dialogRef);
    }

    ngOnInit() {
        if(this.versionMismatch){
            let title:string = `#${this.data.service_request_id} - Customer Requested for updated information`
            this.addComplaintForm.controls['add_complaint_title'].setValue(title);
            this.addComplaintForm.controls['add_complaint_title'].updateValueAndValidity();
            let infoRequestControl = new FormControl();
            this.addComplaintForm.addControl('is_info_request', infoRequestControl);
            this.addComplaintForm.get('is_info_request').setValue(1);
            this.addComplaintForm.get('is_info_request').updateValueAndValidity();
        }
    }
    
    initializeForm(){
        this.addComplaintForm = this.formBuilder.group({
            add_complaint_title: ['', Validators.required],
            add_complaint_description: ['', Validators.required],
            complaint_pic: [''],
            add_complaint_request_id: [this.data.request_id],
            from_device: ['1'],
            customer_id: [this.currentCustomer.id]
        });
    }
    
    addComplaint() { 
        // console.log('form data = ', this.addComplaintForm.value);
        if (this.addComplaintForm.valid) {
            var stringifiedFormData = globalFunctions.stringifyFormData(this.addComplaintForm.value, null);
            

            var data = new FormData();
            for(let i in this.addComplaintForm.value){
              data.append(i, this.addComplaintForm.value[i]);
            }
            this.globalService.formData(globalConstants.API_ADD_COMPLAINT_URL, data ).subscribe( data => {
                    var response = data;
                    if (response.result == 1) {
                        this.dialogRef.close();
                        if(this.versionMismatch){
                            this.router.navigate(["/customer/list-service-requests"]);
                        }
                        else{
                            this.router.navigate(["/customer/list-complaints"]);
                        }
                        this.globalService.snackbar("success", data.message);
                    } else {
                        this.globalService.snackbar("error", data.message);
                    }
                });
        } else {
            this.submitted = false;
        }
    }
    
    getFile(evt, key) {
        this.selectedKey = key;
        var files = evt.target.files;
        var file = files[0];
        if (files && file) {
            var reader = new FileReader();
            reader.onload = this._handleReaderLoaded.bind(this);
            reader.readAsDataURL(file);
        }
    }

    _handleReaderLoaded(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.base64value = (binaryString);
        this.currentComplaintPicture=binaryString;
        if (this.selectedKey == "complaint_pic") {
            this.addComplaintForm.controls.complaint_pic.setValue(this.base64value);
        }
    }
    
    deleteComplaintPic(){
        this.currentComplaintPicture='';
        this.addComplaintForm.controls.complaint_pic.setValue('');
        let ele=<HTMLInputElement>document.getElementById('complaintPic');
        ele.value='';
    }

}
