import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { globalConstants } from '../../../../environments/globalConstants';

@Component({
  selector: 'app-partags',
  templateUrl: './partags.component.html',
  styleUrls: ['./partags.component.scss']
})
export class PartagsComponent implements OnInit {

  public tagForm: FormGroup;
  public allTags = [];
  user: any = null;
  public isEdit = false;
  public isAdd = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, public gs: GlobalService, public dialogRef: MatDialogRef<PartagsComponent>) {
    this.user = this.gs.getCurrentUser();
  }

  ngOnInit() {

    if (!this.user) {
      this.dialogRef.close(2);
    }
    this.gs.diaLogObj(this.dialogRef);
    this.tagForm = this.formBuilder.group({
      tag_id: [],
      tag_name: ['', Validators.required],
      user_type: [this.user.user_type],
      user_id: [this.user.id]
    });
    this.getAllTags();
  }
  getAllTags() {
    let data = { user_id: this.user.id };
    this.gs.callAPI(globalConstants.API_LIST_PART_TAGS, data).subscribe((res:any) => {
      if (res.result == "1") {
        this.allTags = res.data;
      }
    }, (error) => { })
  }
  addTag(opt) {
    this.isEdit = false;
    if (opt == 1) {
      this.isAdd = true;
    } else {
      this.submit(globalConstants.API_ADD_PART_TAGS);
    }
  }
  editTag(tag) {
    this.isEdit = true;
    this.isAdd = false;
    this.tagForm.controls.tag_id.setValue(tag.id);
    this.tagForm.controls.tag_name.setValue(tag.tag_name);
  }
  submitEdit() {
    this.submit(globalConstants.API_EDIT_PART_TAGS);
  }
  submit(url) {
    if (this.tagForm.valid) {
      this.gs.callAPI(url, this.tagForm.value).subscribe((res:any) => {
        if (res.result == 1) {
          this.gs.snackbar("success", res.message);
          this.isEdit = false;
          this.isAdd = false;
          this.getAllTags();
          this.tagForm.controls.tag_id.reset();
          this.tagForm.controls.tag_name.reset();
          // Object.keys(this.tagForm.controls).map((controlName) => {
          //   this.tagForm.get(controlName).reset();
          // });
        } else {
          this.gs.snackbar("error", res.message);
        }
      }, (error) => { })
    }
  }
  cancelAction() {
    this.isEdit = false;
    this.isAdd = false;
  }

}
