<div mat-dialog-title class="relative edit-sr-field-title">
  Edit Service Request
  <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
    <mat-icon  class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="customer-detail-popup">
    <div class="container-fluid">
        <form  class="form-inline col-xs-12 padding-bottom-15 edit-sr-fields" role="form" [formGroup]="serviceEdit" novalidate (ngSubmit)="editData()">
          <div class="row">
              <div class="col-sm-5">Request Title:</div>
              <div class="form-group col-sm-7">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input  matInput placeholder="Request Title" title="Request Title" class="form-control input-lg custom-form-input" maxlength="255" formControlName="request_title"/>
                </mat-form-field>
                <!-- <p class="mat-error ng-tns-c11-28" *ngIf="serviceEdit.controls.request_title.hasError('required')">
                    Request Title is <strong>required</strong>
                </p> -->
              </div>
          </div>
  
          <div class="row">
              <div class="col-sm-5">Request Notes:</div>
              <div class="form-group col-sm-7 details-input">
                <mat-form-field class="car-input btn-block notes-input" floatPlaceholder="never" appearance="fill">
                  <textarea matInput placeholder="Request Notes" title="Request Notes" rows="5" maxlength="1000" formControlName="request_notes"></textarea>
                </mat-form-field>
                <p class="mat-error ng-tns-c11-28" *ngIf="serviceEdit.controls.request_notes.hasError('maxlength')">Only 1000 character Allowed</p>
              </div>
          </div>
          
          <div class="row">
                <div class="col-sm-5">Present Mileage (Miles):</div>
                <div class="form-group col-sm-7">
                  <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill" >
                    <input matInput placeholder="{{param.serviceInfo.present_mileage}}" title="Present Mileage" maxlength="10" class="form-control input-lg custom-form-input"  formControlName="distance_covered" (keypress)="convertIntoDecimal($event,'distance_covered',8,2)" id="mileage"
                    [ngClass]="this.param.serviceInfo.is_walkin_customer_vehicle == '1' ? 'disabled':''"
                    />
                  </mat-form-field>
                  <p class="mat-error ng-tns-c11-28" *ngIf="serviceEdit.controls.distance_covered.hasError('required')">
                      Present Mileage is <strong>required</strong>
                  </p>
                  <p class="mat-error ng-tns-c11-28" *ngIf="serviceEdit.controls.distance_covered.hasError('pattern')">
                    Please enter only numbers or upto one decimal place
                  </p>
                  <p class="mat-error ng-tns-c11-28" *ngIf="this.param.serviceInfo.is_walkin_customer_vehicle == '1'">
                    Mileage can't be modified for "Walkin Vehcile" profile. Please change vehicle against you want to track mileage.
                  </p>
                </div>
          </div>
           <div class="row">
            <div class="col-sm-5">Requested Date:</div>
            <div class="form-group col-sm-7 date-time-col">
              <app-date-and-time-picker *ngIf="requestedDate" [importTime]="requestedDate" class="date-time-section" (SelectTime)="handleSRRequestDate($event)"></app-date-and-time-picker>
            </div>
           </div>

           <div class="row">
            <div class="col-sm-5">Estimated Service Time:</div>
            <div class="form-group col-sm-7">
              <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                 <input matInput placeholder="Estimate service time" class="form-control input-lg custom-form-input" maxlength="6" formControlName="est_service_time" (keypress)="convertIntoDecimal($event,'est_service_time',4,3)"/>
              </mat-form-field>
              <p class="mat-error ng-tns-c11-28" *ngIf="serviceEdit.controls.est_service_time.hasError('pattern')">
                Please enter only numbers or upto two decimal place
              </p>
            </div>
           </div>
        </form>
      </div>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
  <div class="flexBtns2">
    <button mat-raised-button mat-accent class="font-bold mat-accent" (click)="editData()">Update</button>
    <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
  </div>
</mat-dialog-actions>