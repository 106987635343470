<!-- Coupon Redeem UI -->
<ng-container *ngIf="!isViewMode">
  <div [ngClass]="{'coupon-redeem-container text-right':true, 'mb-5':(showCodeApplyForm), 'mb-10':(!showCodeApplyForm), 'customer':(for == 'Customer')}">
    <ng-container *ngIf="((for == 'User') || ((for == 'Customer') && (serviceData && serviceData.request_status >= 3)))">
      <label>
        Discount : <span class="discount-value">$ {{ discountAmount | number:'1.2-2' }}</span>
      </label>
      <div class="help-block note text-red">
        Note : 
        <span>Discount applied above is subject to change based on the changes made to the service request.</span>
      </div>
    </ng-container>
    <span class="apply-coupon-text text-red" (click)="toggleCouponCodeApply()">
      Apply Coupon Code 
      <i class="fa fa-fw fa-caret-down fa-lg"></i>
    </span>
    <!-- <div class="redeem-form" *ngIf="showCodeApplyForm"> -->
    <!-- <div [ngClass]="{'redeem-form':true, 'hide':!showCodeApplyForm}"> -->
    <div [ngClass]="{'redeem-form':true}" [@applyCoupon]="{'value': (showCodeApplyForm ? 'in' : 'out'), params:{mt:(showCodeApplyForm ? '10' : '0')}}">
      <form [formGroup]="applyCodeForm" class="applyCodeForm">
        <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
          <input class="input-sm form-control custom-form-input" matInput formControlName="coupon_code" type="text" placeholder="Coupon Code" required>
          <mat-error>
            This field cannot be empty
          </mat-error>
        </mat-form-field>
        <button class="btn btn-sm car-btn btn-red mat-elevation-z0 mat-raised-button mat-accent" type="button" (click)="submitCouponCode()" [disabled]="applyCodeForm.controls['coupon_code'].errors">Apply</button>
        <button class="create-quick-coupon btn btn-sm car-btn btn-red mat-elevation-z0 mat-raised-button mat-accent" type="button" (click)="createQuickCoupon()" title="Create Quick Coupon">
          <i class="fa fa-fw fa-plus fa-lg"></i>
        </button>
      </form>
      <ng-container *ngIf="(appliedCoupons && appliedCoupons.length > 0) || (notAppliedCoupons && notAppliedCoupons.length > 0)">
        <div [ngClass]="{'applied-coupons':true}">
          <ng-container *ngIf="(appliedCoupons && appliedCoupons.length > 0)">
            <ul>
              <ng-container *ngFor="let coupon of appliedCoupons">
                <li [@addRemoveCoupon]="coupon.state">
                  <ng-container [ngTemplateOutlet]="couponInfo" [ngTemplateOutletContext]="{coupon:coupon}" ></ng-container>
                  <span *ngIf="(for == 'User') || (coupon.added_by_type == for)"> 
                    <button type="button" class="p-0 op-btn btn btn-xs text-red" (click)="removeCoupon(coupon)" title="Remove coupon"> 
                      <i class="fa fa-fw fa-times fa-lg"></i>
                    </button>
                  </span>
                </li>
              </ng-container>
            </ul>
          </ng-container>
          <ng-container *ngIf="(notAppliedCoupons && notAppliedCoupons.length > 0)">
            <h5>Inapplicable Coupon(s)</h5>
            <ul>
              <ng-container *ngFor="let coupon of notAppliedCoupons">
                <li [@addRemoveCoupon]="coupon.state">
                  <ng-container [ngTemplateOutlet]="couponInfo" [ngTemplateOutletContext]="{coupon:coupon}" ></ng-container>
                  <span *ngIf="(for == 'User') || (coupon.added_by_type == for)"> 
                    <button type="button" class="p-0 op-btn btn btn-xs text-red" (click)="removeCoupon(coupon)" title="Remove coupon"> 
                      <i class="fa fa-fw fa-times fa-lg"></i>
                    </button>
                  </span>
                </li>
              </ng-container>
            </ul>
          </ng-container>
        </div>
        <div class="help-block note text-red">
          Note : 
          <span>Coupon code(s) applied above are subject to change based on the changes made to the service request.</span>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isViewMode">
  <ng-container *ngIf="for != 'Customer'">
    <div [ngClass]="{'coupon-redeem-container text-right margin-adjust':true}">
      <label style="font-size:18px;">
        Discount : <span class="discount-value">$ {{ discountAmount | number:'1.2-2' }}</span>
        <p class="help-block details" *ngIf="discountAmount > 0" (click)="toggleCouponCodeApply()">Click to see details</p>
      </label>
      <div [ngClass]="{'redeem-form':true}" [@applyCoupon]="{'value': (showCodeApplyForm ? 'in' : 'out'), params:{mt:'0'}}">
        <ng-container *ngIf="(appliedCoupons && appliedCoupons.length > 0) || (notAppliedCoupons && notAppliedCoupons.length > 0)">
          <div [ngClass]="{'applied-coupons':true}">
            <ng-container *ngIf="(appliedCoupons && appliedCoupons.length > 0)">
              <ul>
                <ng-container *ngFor="let coupon of appliedCoupons">
                  <li [@addRemoveCoupon]="coupon.state">
                    <ng-container [ngTemplateOutlet]="couponInfo" [ngTemplateOutletContext]="{coupon:coupon}" ></ng-container>
                  </li>
                </ng-container>
              </ul>
            </ng-container>
            <ng-container *ngIf="(notAppliedCoupons && notAppliedCoupons.length > 0)">
              <h5>Inapplicable Coupons</h5>
              <ul>
                <ng-container *ngFor="let coupon of notAppliedCoupons">
                  <li [@addRemoveCoupon]="coupon.state">
                    <ng-container [ngTemplateOutlet]="couponInfo" [ngTemplateOutletContext]="{coupon:coupon}" ></ng-container>
                  </li>
                </ng-container>
              </ul>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="(for == 'Customer' && discountAmount > 0)">
    <div [ngClass]="{'coupon-redeem-container text-right customer':true}">
      <label>
        Discount : <span class="discount-value">$ {{ discountAmount | number:'1.2-2' }}</span>
        <p class="help-block details" (click)="toggleCouponCodeApply()">Click to see details</p>
      </label>
      <div [ngClass]="{'redeem-form':true}" [@applyCoupon]="{'value': (showCodeApplyForm ? 'in' : 'out'), params:{mt:'0'}}">
        <ng-container *ngIf="(appliedCoupons && appliedCoupons.length > 0) || (notAppliedCoupons && notAppliedCoupons.length > 0)">
          <div [ngClass]="{'applied-coupons':true}">
            <ng-container *ngIf="(appliedCoupons && appliedCoupons.length > 0)">
              <ul>
                <ng-container *ngFor="let coupon of appliedCoupons">
                  <li [@addRemoveCoupon]="coupon.state">
                    <ng-container [ngTemplateOutlet]="couponInfo" [ngTemplateOutletContext]="{coupon:coupon}" ></ng-container>
                  </li>
                </ng-container>
              </ul>
            </ng-container>
            <ng-container *ngIf="(notAppliedCoupons && notAppliedCoupons.length > 0)">
              <h5>Inapplicable Coupons</h5>
              <ul>
                <ng-container *ngFor="let coupon of notAppliedCoupons">
                  <li [@addRemoveCoupon]="coupon.state">
                    <ng-container [ngTemplateOutlet]="couponInfo" [ngTemplateOutletContext]="{coupon:coupon}" ></ng-container>
                  </li>
                </ng-container>
              </ul>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #couponInfo let-coupon="coupon">
  <!-- Code - {{coupon.discount_code}} : $ {{ coupon.discount_amount | number:'1.2-2' }} -->
  {{coupon.discount_code}}
</ng-template>