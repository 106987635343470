import {Component, OnInit, Inject ,ViewChild , ElementRef, OnDestroy } from '@angular/core';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {globalConstants} from "../../../environments/globalConstants"
import {globalFunctions} from "../../../environments/globalFunctions"
import {GlobalService} from "../../../environments/global.service"

import {Observable} from 'rxjs/Observable';
import {Title} from '@angular/platform-browser';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/map';
import {IDatePickerConfig} from 'ng2-date-picker';
import {trigger, state, style, animate, transition} from '@angular/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import 'rxjs/add/observable/of';
import { ShopVehicleServiceComponent } from '../shopservicerequest/shop-vehicle-service/shop-vehicle-service.component';
declare var jQuery:any;
declare var $ :any;
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import { CommonDialogBigComponent } from '../common-dialog-big/common-dialog-big.component';
import { AppComponent } from 'src/app/app.component';

@Component({
    selector: 'app-new-service-request',
    templateUrl: './new-service-request.component.html',
    styleUrls: ['./new-service-request.component.scss'],
    animations: [
        trigger('popOverState', [
            state('show', style({
                opacity: 1,
                transform: 'translateX(0px)',
                'z-index': '0'
            })),
            state('hide', style({
                opacity: 0,
                transform: 'translateX(-69px)',
                'z-index': '-1'
            })),
            transition('show => hide', animate('100ms ease-out')),
            transition('hide => show', animate('0.2s 100ms ease-out'))
        ])
    ],
    providers: [DatePipe]
})

export class NewServiceRequestComponent implements OnInit, OnDestroy {


    @ViewChild('request_title') request_title: ElementRef;
    @ViewChild('distance_covered') distance_covered: ElementRef;

    public newRequest: FormGroup;
    public isDIY = true;
    public customerId;
    public showDropdown;
    public serviceVehicles=[];
    public serviceRequestList;
    public autoOptions = []
    public defaultServiceFacility = {name: "", id: "" , sr_title_required: ""};
    public defaultServiceFacilities = [];
    public filterOptionsSet: Observable<string[]>;
    public currentShop;
    public rejectedJob;
    public rejectedJobList;
    public rejectedJobMessage;
    public checklist = {};
    public selectedCarid;
    public mileageValue="";
    public request_image = [];
    public mileage="";
    public request_image_loader: boolean = false;
    public request_document = [];
    public request_document_loader: boolean = false;
    datePickerConfig: IDatePickerConfig = {
        drops: "down",
        format: "MM/DD/YYYY hh:mm A",
        //min: this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm a'),
        closeOnSelect:true,
        disableKeypress:true,
        unSelectOnClick:false
    };
    public jobImages='';
    public insuranceExpirationDate;
    public registrationExpirationDate;
    public insuranceExpired;
    public registrationExpired;
    public displayInsuranceDate;
    public displayRegistrationDate;
    public imgTypes=globalConstants.FORMAT_SUPPORTED_FOR_IMAGE;
    public imgSize=globalConstants.MAX_ALLOWED_IMAGE_DISPLAYED_TEXT;
    myControl: FormControl = new FormControl();
    public isMultipleFile=true;
    public waitingEnabled = false; 

    public currentUser:any=null;
    public today = new Date();
    public mask = {
        guide: true,
        showMask : false,
        mask: [/\d/, /\d/, '/', /\d/, /\d/, '/',/\d/, /\d/,/\d/, /\d/]
    };
    date = '';
    public calDate;
    time = '';
    dateValid = true;
    submited = false;
    public sTimeSlots: Array<any> = [];
    public subscriptionDataForAPI: Subscription = new Subscription();
    public selectedDateAndTime : any;
    constructor(
        public globalService: GlobalService, 
        public titleService: Title, 
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder, 
        private route: ActivatedRoute, 
        public router: Router, 
        public dialog: MatDialog, 
        private datePipe: DatePipe, 
        private matDialog: MatDialog,
        public app: AppComponent,
         ) {
        this.setParams();
        this.setCurrentShop();
        this.makeFormBuilder();
        this.getServiceRequests();
        this.getCustomerVehicles();
        this.defaultServiceFacility.name = ('shop_name' in this.currentShop)?this.currentShop.shop_name: this.currentShop.first_name + " " + this.currentShop.last_name;
        this.defaultServiceFacility.id = this.currentShop.id;
        this.defaultServiceFacility.sr_title_required = this.currentShop.sr_title_required;
        let cDate=this.globalService.getFullFormateDate(null,'G','C');
        this.newRequest.controls.request_date.setValue(cDate);
        this.globalService.setMetaData("SHOPS","NEW_REQUEST_CREATION");
        this.isMultipleFile=this.globalService.isMultifileAllowed()
    }
    setParams() {
        this.activatedRoute.params.subscribe((params: Params) => {
            this.customerId = params["customer_id"];
            this.selectedCarid = params['car_id'];
        });
        setTimeout(()=>{
            let isEnable=this.globalService.getAcl('service_request','addNew');
            if(!isEnable){
              this.router.navigate(['shops/action/forbidden']);
            }
        },globalConstants.RELOAD_WAIT);
    }

    checkForDuplicateOpenTicket(carId){ 
        if(carId){
            let customerOprs = "car_id="+carId+"&user_type=User&shop_id="+this.currentShop.shop_id;
           this.subscriptionDataForAPI =  this.globalService.callAPI(globalConstants.API_GET_SHOP_VEHICLE_SERVICE,customerOprs).subscribe((res:any) => {
                if (res.result == 1 && res.data.length > 0) {                
                    let dialogRef = this.dialog.open(ShopVehicleServiceComponent, {
                        panelClass: ['car-dialog-form','recent-sr-modal'],
                        // width: '800px',
                        data: {tickets:res.data} 
                    });
                }else if(res.result == 0){
                    this.globalService.snackbar('error', res.message);
                    if(res.code == 297){
                        let popUpDisplayType = "yes"; // defualt display type - yes/no
                        let note = "Taxes and supplies charges are required in order to create new request. Do you want to complete profile settings now?";
                        this.globalService.confirmDialog(popUpDisplayType, note).subscribe((res: any) => {
                            if (typeof (res) !== 'undefined') {
                                let dialogRef = this.dialog.open(CommonDialogBigComponent, {
                                            panelClass: ['car-dialog-form','common-dialog','newSr-profile-setup'],
                                            data: {opr:1, dialogTitle:"Shop Profile Setup"} 
                                        }); 
                                        dialogRef.afterClosed().subscribe(result => {
                                            if (result.status == 1) {
                                                this.app.getCurrentShopSubscription();
                                            }
                                        })
                            } 
                        }) 
                    
                    }
                }
            },(error)=>{

            });            
        }        
    }

    setCurrentShop() {
        this.currentShop = JSON.parse(localStorage.getItem("loggedInUser"))
    }
    onlyNumericKey(event) {
      return globalFunctions.onlyDecimalNumberKey(event);
    }
    getServiceRequests() {
        this.subscriptionDataForAPI =  this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUESTS_LIST_URL, "customer_id=" + this.customerId)
            .subscribe((data:any) => {
                this.serviceRequestList = data.data
            })
    }
    /**
     * @trackID <:CAR-1322> Add Unit Number in Vehicle Name
     * @updated 2020-01-06
     * @updatedBY Vikas Kumar
     * @param
     * @return
     */
    getCustomerVehicles() {
        let opr = "customer_id=" + this.customerId + "&from_v2=1";
        if(this.selectedCarid && this.selectedCarid != ''){
            opr = opr + "&vehicle_id=" + this.selectedCarid;
        }
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_VEHICLES_URL, opr)
            .subscribe((data:any) => {
                this.serviceVehicles = [];
                if (data.result == "1") {
                    if (data.data.length != undefined) {
                         let allVehicle = data.data;//this.serviceVehicles;
                        if (allVehicle.length > 1) {

                            for (let i in allVehicle) {
                                
                                    allVehicle[i].name="";
                                    if (allVehicle[i].year) {
                                        allVehicle[i].name = allVehicle[i].year + ' ';
                                    }
                                    if(allVehicle[i].make){
                                        allVehicle[i].name += allVehicle[i].make + ' ';
                                    }
                                    if(allVehicle[i].model){
                                        allVehicle[i].name +=  allVehicle[i].model + ' ';
                                    }
                                    if (allVehicle[i].license_plate != '') {
                                        allVehicle[i].name += ' (' + allVehicle[i].license_plate + ')';
                                    }
                                    if(!allVehicle[i].name){
                                        allVehicle[i].name="N/A";
                                    }
                                    if(allVehicle[i].unit_number){
                                        allVehicle[i].name +=' '+allVehicle[i].unit_number
                                    }
                                    this.serviceVehicles.push(allVehicle[i]);
                                    
                            }
                        }else if(allVehicle.length == 1){
                            allVehicle[0].name="";
                            if (allVehicle[0].year) {
                                allVehicle[0].name = allVehicle[0].year + ' ';
                            }
                            if(allVehicle[0].make){
                                allVehicle[0].name += allVehicle[0].make + ' ';
                            }
                            if(allVehicle[0].model){
                                allVehicle[0].name +=  allVehicle[0].model + ' ';
                            }
                            if (allVehicle[0].license_plate != '') {
                                allVehicle[0].name += ' ' + allVehicle[0].license_plate;
                            }
                            if(!allVehicle[0].name){
                                allVehicle[0].name="N/A";
                            }
                            if(allVehicle[0].unit_number){
                                allVehicle[0].name +=' '+allVehicle[0].unit_number
                            }
                            this.serviceVehicles.push(allVehicle[0]);
                            this.newRequest.patchValue({request_car_id : this.serviceVehicles[0].id});
                        }
                    } else {

                        data.data.name =data.data.year+' '+data.data.make+' '+data.data.model
                        if(data.data.license_plate != ''){
                            data.data.name +=' '+data.data.license_plate;
                        }
                        if(data.data.unit_number != ''){
                            data.data.name +=' '+data.data.unit_number
                        }
                        this.serviceVehicles[0] = data.data;
                        this.newRequest.patchValue({request_car_id : this.serviceVehicles[0].id});
                    }

                } else {


                }
                if(this.serviceVehicles.length==0){
                    this.matDialog.open(addCarDialog, {
                        panelClass: ['dialog-padding-0'],
                        disableClose: true,
                        data: {cusId: this.customerId}
                    });
                }



                this.selectedVehicle();
            });
    }
    hasCheckBoxChild(m2, object) {
        return m2.children.find(function (x) {return x.input == "checkbox"}) != undefined ? true : false
    }
    hasTextAreaChild(m2, object) {
        var hasTextareaInChild = m2.children.find(function (x) {return x.input == "textarea"})
        if (hasTextareaInChild != undefined) {
            return true;
        } else {
            return false;
        }
    }
    hasTextRadioChild(m2, object) {
        var hasRadioInChild = m2.children.find(function (x) {return x.input == "radio"})
        if (hasRadioInChild != undefined) {
            return true;
        } else {
            return false;
        }
    }
    hasCheckboxChild(m2,object) {
      var hasRadioInChild = m2.children.find(function (x) {return x.input == "checkbox"})
      // if (hasRadioInChild != undefined) {
          return hasRadioInChild != undefined ? true : false;
      // } else {
          // return false;
      // }
    }
    makeJSON(object, m1, m2, m3, pid) {
        var obj = {};
        if (object.checked || object.value == 1 || object.type == "keyup" || object.type == "change" || object.type == "blur") {
            if (m2.children && this.hasTextAreaChild(m2, object) && object.target == undefined) {
                this.checklist["chk[" + m1.id + "][" + m2.id + "]"] = true
                this.newRequest.addControl("chk[" + m1.id + "][" + m2.id + "]", new FormControl("chk[" + m1.id + "][" + m2.id + "]"));
                this.newRequest.controls["chk[" + m1.id + "][" + m2.id + "]"].setValue("");
                let f= <HTMLButtonElement> document.getElementById(m1.id+"-"+m2.id);
                f.value=""
            }
            if (m2.children && this.hasTextRadioChild(m2, object) && m3 == undefined) {
                this.checklist["chk[" + m1.id + "][" + m2.id + "]"] = true
                this.newRequest.addControl("chk[" + m1.id + "][" + m2.id + "]", new FormControl("chk[" + m1.id + "][" + m2.id + "]"));
                this.newRequest.controls["chk[" + m1.id + "][" + m2.id + "]"].setValue(true)
            }
            if (m2.children && this.hasCheckboxChild(m2, object) && m3 == undefined) {
                this.checklist["chk[" + m1.id + "][" + m2.id + "]"] = true
                this.newRequest.addControl("chk[" + m1.id + "][" + m2.id + "]", new FormControl("chk[" + m1.id + "][" + m2.id + "]"));
                this.newRequest.controls["chk[" + m1.id + "][" + m2.id + "]"].setValue(true)
            }
            else if (m2.children) {
                // control will be here if m3 is present
                if (m3 != undefined && m3.input == "checkbox") {
                    this.checklist["chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]"] = true
                    this.newRequest.addControl("chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]", new FormControl("chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]"))
                    this.newRequest.controls["chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]"].setValue(true)

                } else if (m3 != undefined && m3.input == "radio") {
                    this.checklist["chk[" + m1.id + "][" + m2.id + "]"] = m3.id;
                    this.newRequest.addControl("chk[" + m1.id + "][" + m2.id + "]", new FormControl());
                    this.newRequest.controls["chk[" + m1.id + "][" + m2.id + "]"].setValue(m3.id)

                } else if (m3 != undefined && m3.input == "textarea") {
                    this.checklist["chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]"] = object.target.value
                    this.newRequest.addControl("chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]", new FormControl("chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]"));
                    this.newRequest.controls["chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]"].setValue(object.target.value)
                    delete this.checklist["chk[" + m1.id + "][" + m2.id + "]"]
                    this.newRequest.removeControl("chk[" + m1.id + "][" + m2.id + "]");

                } else if (m3 != undefined && m3.input == "imageupload") {
                    this.checklist["chk[" + m1.id + "][" + m2.id + "]"+"["+ m3.id +"]"] = 'on';
                    this.newRequest.addControl("chk[" + m1.id + "][" + m2.id + "]"+"["+ m3.id +"]", new FormControl("chk[" + m1.id + "][" + m2.id + "]"+"["+ m3.id +"]"));
                    this.newRequest.controls["chk[" + m1.id + "][" + m2.id + "]"+"["+ m3.id +"]"].setValue('on');
                    this.checklist['chk_' + m1.id + "_" + m2.id + "_" + m3.id] = object.target.files[0]
                    this.newRequest.addControl('chk_' + m1.id + "_" + m2.id + "_" + m3.id, new FormControl('chk_' + m1.id + "_" + m2.id + "_" + m3.id));
                    this.newRequest.controls['chk_' + m1.id + "_" + m2.id + "_" + m3.id].setValue(object.target.files[0]);
                    this.changeListner(object,'other_lights');
                }
            } else {
                if (m2.input == "checkbox") { // always true condition , second child will always be checkbox
                    obj = {["chk[" + m1.id + "][" + m2.id + "]"]: true}
                    this.checklist["chk[" + m1.id + "][" + m2.id + "]"] = true;
                    this.newRequest.addControl("chk[" + m1.id + "][" + m2.id + "]", new FormControl("chk[" + m1.id + "][" + m2.id + "]"));
                    this.newRequest.controls["chk[" + m1.id + "][" + m2.id + "]"].setValue(true)
                }
            }
        } else {
            let rejectedEle = <HTMLButtonElement> document.getElementById('rejected-' + m1.id + '-' + m2.id);
            if (rejectedEle) {
                rejectedEle.disabled = false;
                this.deleteRejectedJob(m1, m2, m3);
            }
            if (m2.children) {
                // control will be here if m3 is present
                if (m3 != undefined && m3.input == "checkbox") {
                    delete this.checklist["chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]"]
                    this.newRequest.removeControl("chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]")
                } else if (m3 != undefined && m3.input == "radio") {
                    delete this.checklist["chk[" + m1.id + "][" + m2.id + "]"]
                    this.newRequest.removeControl("chk[" + m1.id + "][" + m2.id + "]")
                } else {
                    for (let i in m2.children) {
                        let m3 = m2.children[i];
                        if (m3 != undefined && m3.input == "checkbox") {
                            delete this.checklist["chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]"]
                            this.newRequest.removeControl("chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]")
                        } else if (m3 != undefined && m3.input == "radio") {
                            delete this.checklist["chk[" + m1.id + "][" + m2.id + "]"]
                            this.newRequest.removeControl("chk[" + m1.id + "][" + m2.id + "]")
                        } else if (m3 != undefined && m3.input == "imageupload") {
                            delete this.checklist["chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]"]
                            this.newRequest.removeControl("chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]");
                            delete this.checklist['chk_' + m1.id + "_" + m2.id + "_" + m3.id];
                            this.newRequest.removeControl('chk_' + m1.id + "_" + m2.id + "_" + m3.id);
                            this.deleteJobImg(m1,m2,m3);
                        } else if (m3 != undefined && m3.input == "textarea") {
                            delete this.checklist["chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]"]
                            this.newRequest.removeControl("chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]");
                        }
                    }
                }
            } else {
                if (m2.input == "checkbox") { // always true condition , second child will always be checkbox
                    delete this.checklist["chk[" + m1.id + "][" + m2.id + "]"]
                    this.newRequest.removeControl("chk[" + m1.id + "][" + m2.id + "]");
                }
            }
            if(m2.children && !object.checked) {
              delete this.checklist["chk[" + m1.id + "][" + m2.id + "]"]
              this.newRequest.removeControl("chk[" + m1.id + "][" + m2.id + "]");
            }
        }
        if (m3) {
            let l2 = null;
            let l3CId = null;
            if ((typeof object.srcElement == 'undefined') && (typeof object.source !='undefined')) {
                l2 = object.source._elementRef.nativeElement.parentElement.parentElement.previousElementSibling.id;
                l3CId = object.source._elementRef.nativeElement.parentElement.parentElement.id;
            } else {
                let target:any='';
               if(typeof object.srcElement =='undefined'){
                   target=object.originalTarget;
               }else{
                   target=object.srcElement;
               }
               if (target.nodeName != 'TEXTAREA') {
                   if (m3.input != "imageupload") {
                       l2 = target.parentElement.parentElement.parentElement.previousElementSibling.id;
                       l3CId = target.parentElement.parentElement.parentElement.id;
                   }
               }
            }

            let container = document.getElementById(l3CId);
            let unChkParent = false;
            let box = (container ? container.querySelectorAll('input[type="checkbox"]') : []);
            if (box.length != 0) {
                box = container.querySelectorAll('input[type="checkbox"]:checked');
                unChkParent = (box.length == 0 ? true : unChkParent);
            }
            if (unChkParent) {
                let l2c = document.getElementById(l2);
                let l2Cbox = l2c.querySelectorAll('input[type="checkbox"]:checked');
                if (l2Cbox.length) {
                    document.getElementById(l2Cbox[0].id).click();
                }
            }
        }
        this.checkChild(pid, null);
    }
    isAnyDefaultFacility() {
        return this.defaultServiceFacility.id == "" ? false : true
    }
    getDefaultServiceFacility() {
        var headers = new Headers();
        headers.append('content-type', 'application/x-www-form-urlencoded');

        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_SERVICE_FACILITY_LIST_FOR_SERVICE_REQUEST_URL, "customer_id=" + this.customerId, ).subscribe((data:any) => {
            var parsedResponse = data.data
            this.defaultServiceFacilities = parsedResponse;
            this.autoOptions = parsedResponse;
            this.filterOptionsSet = this.myControl.valueChanges
                .startWith(null)
                .map(val => val ? this.filter(val) : this.autoOptions.slice());
            for (var i = 0; i < parsedResponse.length; i++) {
                if (parsedResponse[i]["is_default"] == "1") {
                    this.defaultServiceFacility.name = parsedResponse[i]["name"]
                    this.defaultServiceFacility.id = parsedResponse[i]["id"]
                }
            }
            if (this.defaultServiceFacility.name == "") {
                this.defaultServiceFacility.name = "DIY";
            }
        });

      
    }

    filter(val: string): string[] {
        return this.autoOptions.filter(option =>
            option.name.toLowerCase() === 0);
    }

    public transformInnerHtmlData(html: any) {
        return html.changingThisBreaksApplicationSecurity; 
    } 

    makeFormBuilder() {
        this.newRequest = this.formBuilder.group({
            user_id: [this.currentShop.id],
            customer_id: [this.customerId],
            customer_email: [],
            request_car_id: [this.selectedCarid],
            distance_covered: ['',[Validators.pattern(/^\d+(\.\d)?$/)]],
            service_facility_id: [''],
            request_title: [''],
            request_notes: [''],
            request_date: [''],
            shop_name: [''],
            shop_email: [''],
            shop_address: [''],
            shop_city: [''],
            shop_state: [''],
            shop_zip: [''],
            shop_phone: [''],
            shop_url: [''],
            user_type: ['User'],
            requestCreationType: ['1'],
            step: ['1'],
            request_document: [''],
            request_image: [''],
            request_other_light: [''],
            from_v2: [1],
            on_the_lot :[0],
            force_submit:[0]
        });
    }

    /**
   * updateOnTheLotStatus
   */
  public updateOnTheLotStatus(value) {
        let checked : number = 0;
        if(value.checked === true) {
            checked = 1;
        }
        this.newRequest.patchValue({
            on_the_lot : checked
        });
    }

    displayFn(val) {
        if (val != undefined) {
            return val.name;
        } else {
            return "Enter Shop name";
        }
    }

    toggleTextField(event, defaultServiceFacility) {
        if (event.value == 1) {
            this.isDIY = true
        }
        if (event.value == 2) {
            this.newRequest.controls.service_facility_id.setValue(defaultServiceFacility.shop_id);
            this.isDIY = false
        }
        if (event.value == 3) {
            this.showDropdown = true;
        } else {
            this.showDropdown = false;
        }
    }

    expansionOpen(ele) {
        setTimeout(() => {
            var target= '#expansion-'+ ele;
            setTimeout(() => {
              var body = $("html, body");
              body.animate({
                     scrollTop: jQuery(target).offset().top - 90
               }, 500);
            }, 500);
            $(target).data('state',true);
         }, 200);
    }

    sendNewRequest(opr=1) {
        this.newRequest.addControl("sfacility", new FormControl("sfacility"));
        this.newRequest.controls.sfacility.setValue(this.currentShop.shop_id);
        let img = [];
        if (this.request_image.length > 0) {
            for (var i in this.request_image) {
                img.push(this.request_image[i].name);
            }
        }
        this.newRequest.controls.request_image.setValue(JSON.stringify(img));
        let doc = [];
        if (this.request_document.length > 0) {
            for (var i in this.request_document) {
                doc.push(this.request_document[i].name);
            }
        }
        this.newRequest.controls.request_document.setValue(JSON.stringify(doc));
        if(!this.dateValid || (this.date != '' && this.time == '')){
            this.globalService.snackbar("error", 'Service Schedule Date is invalid');
            return;
        }
        if(this.newRequest.value.request_date == 'undefined' || (typeof this.newRequest.value.request_date == 'undefined')){
            this.newRequest.value.request_date="";
        }
        this.makeRequest(globalConstants.API_CREATE_NEW_SERVICE_URL,opr)
    }

    makeRequest(url,opr) {
      let data = new FormData();
      for(var i in this.newRequest.value){
          if((this.newRequest.value[i]==null) || (typeof this.newRequest.value[i]=='undefined') ){
            data.append(i, '');
          }else{
              if(i=='request_date'){
                data.append(i, moment(this.selectedDateAndTime).format('MM/DD/YYYY hh:mm A Z'));
              }else{
                data.append(i, this.newRequest.value[i]);
              }             
          }
      }
      this.globalService.formData(url,data).subscribe((response:any)=>{
        var data       = response;
        var message    = data.message
        let statusCode = data.statusCode;
           if (data.result != "1") {
                if(statusCode){
                    this.globalService.confirmDialog('unpaidBalance', message,400).subscribe((r) => {
                        let res: any = r;
                        if (res == 'unpaidBalance') {
                            this.waitingEnabled = true;
                            this.newRequest.controls.force_submit.setValue(statusCode);
                            setTimeout(() => {
                                this.sendNewRequest(opr);
                                this.waitingEnabled = false;
                            }, 500);
                        }
                    });
               }else{
                   if(data.code == 259 ){
                    this.distance_covered.nativeElement.focus(); 
                   } 
                   if(data.code == 598 ){
                    this.request_title.nativeElement.focus(); 
                   }
                   this.globalService.snackbar("error", message);
               }
           } else {
               this.globalService.snackbar("success", message);
               if(opr == 1){
                    let listOtion= {user_id: "",user_type: "User",status: '',search_keyword: "",totalRecords:0,start: 0,limit: 10,schedule_service_date: "",requested_delivery_date: "",estimated_delivery_date: "",customer_id: ""};
                    this.globalService.localGetterSetter(null,listOtion,'shop-service');
                    localStorage.setItem("shop-servicecustomer_id", '');
                    localStorage.setItem("openFilter",'');
                    this.router.navigate(['shops/list-service-requests']);
               }else{
                    let reqId = data.data;
                    this.redirectToFillRecomPage(reqId,"/shops/service-inspection-report/" + reqId);
               }
           }
      })
        // this.globalService.callAPI(url, this.newRequest.value).subscribe((data) => {
        //     var message = data.message
        //     if (data.result != "1") {
        //         this.globalService.snackbar("error", message);
        //     } else {
        //         this.globalService.snackbar("success", message);
        //         this.router.navigate(['shops/list-service-requests']);
        //     }
        // });
    }

    showOptions( event, parentEleId) {
        let parentEle = document.getElementById('expansion-' + parentEleId);
        let ele = null;
        if (event.checked == true) {
            parentEle.classList.contains("checked") ? console.log("checked set") : parentEle.classList.add("checked");
            if (event.source._elementRef.nativeElement.nextElementSibling != undefined) {
                ele = event.source._elementRef.nativeElement.nextElementSibling.id;
                document.getElementById(ele).classList.add("active");
            } else {
            }
        } else if (event.checked == false) {
            if (event.source._elementRef.nativeElement.nextElementSibling != undefined) {
                ele = event.source._elementRef.nativeElement.nextElementSibling.id;
                document.getElementById(ele).classList.remove("active");
            }
            var totalChildren = event.source._elementRef.nativeElement.parentElement.parentElement.children.length;
           
            var totalUnchecked = 0;
            for (var i = 0; i < totalChildren; i++) {
                if (event.source._elementRef.nativeElement.parentElement.parentElement.children[i].childNodes["0"].children["0"].childNodes["0"].autofocus == false) {
                    totalUnchecked++;
                }
            }
            if (totalChildren == totalUnchecked) {
                // parentEle.classList.remove("checked")
            }
        }
        this.checkChild(parentEleId, ele);
    }
    checkChild(pId, ele) {

        let parentEle = document.getElementById('expansion-' + pId);
        if (ele) {
            let container = document.getElementById(ele)
            let box = container.querySelectorAll('input[type="checkbox"]:checked');
            let unChkChild = false;
            let bType = 'checkbox';
            if (box.length) {
                unChkChild = true;
            } else {
                box = container.querySelectorAll('input[type="radio"]:checked');
                bType = 'radio';
                unChkChild = (box.length ? true : unChkChild);
            }
            if (unChkChild) {
                for (var i in box) {
                    if (typeof box[i] == 'object') {
                        if (bType == 'checkbox') {
                            document.getElementById(box[i].id).click();
                        } else {
                            let radioBox = <HTMLInputElement> box[i];
                            radioBox.checked = false;
                        }
                    }
                }
            }
        }
        if (parentEle != null && parentEle != null) {
            var checkBoxesSelected = parentEle.querySelectorAll('input[type="checkbox"]:checked');
            if (checkBoxesSelected && checkBoxesSelected.length) {
                parentEle.classList.add("checked");
            } else {

                checkBoxesSelected = parentEle.querySelectorAll('input[type="radio"]:checked');
                if (checkBoxesSelected && checkBoxesSelected.length) {
                    parentEle.classList.add("checked");
                } else {
                    parentEle.classList.remove("checked");
                }
            }
        }
    }
    /**
     * @trackID <:CAR-1321> Focus remove
     * @updated 2020-01-07
     * @updatedBY Vikas Kumar
     * @param
     * @return
     */
    selectedVehicle() {
        let carId = this.newRequest.value.request_car_id;             
        this.subscriptionDataForAPI = carId && this.globalService.callAPI(globalConstants.API_GET_REJECTED_SERVICE_JOBS_URL, "customer_id=" + this.customerId + "&car_id=" + carId+"&user_type=User&user_id="+this.currentShop.id).subscribe((data:any) => {

            if (data.result == "1") {
                this.rejectedJob = data;
                if (this.rejectedJob.code == 500) {
                    this.rejectedJobMessage = false;
                    this.rejectedJobList = this.rejectedJob.data;
                } else if (this.rejectedJob.code == 150) {
                    this.rejectedJobList = false;
                    this.rejectedJobMessage = this.rejectedJob.message;
                }
            }else if (('code' in data) && data.code==150 ){
                this.rejectedJobList    = false;
                this.rejectedJobMessage = "No previous Rejected jobs.";
            }
        });

        this.checkForDuplicateOpenTicket(carId);

        if (carId && this.serviceVehicles) {
            for (let i in this.serviceVehicles) {
                if (this.serviceVehicles[i].id == carId) {
                    /* Registration/Insurance expiration notice */
                this.insuranceExpirationDate = this.serviceVehicles[i]["insurance_expiration_date"];
                this.registrationExpirationDate = this.serviceVehicles[i]["registration_expiration_date"];

                if(this.insuranceExpirationDate!="" && this.insuranceExpirationDate!=null && this.insuranceExpirationDate!="0000-00-00 00:00:00" && this.insuranceExpirationDate!="Invalid Date") {
                     if(this.globalService.isOldDate(this.insuranceExpirationDate)) {
                        this.insuranceExpired = true;
                        //this.displayInsuranceDate = (new Date(this.insuranceExpirationDate).getMonth()+1) + "/" + new Date(this.insuranceExpirationDate).getDate() + "/" + new Date(this.insuranceExpirationDate).getFullYear();
                        this.displayInsuranceDate =  this.globalService.getFullFormateDate(this.insuranceExpirationDate,"GD","")
                    } else {
                        this.insuranceExpired = false;
                    }
                } else {
                    this.insuranceExpired = false;
                }

                if(this.registrationExpirationDate!="" && this.registrationExpirationDate!=null && this.registrationExpirationDate!="0000-00-00 00:00:00" && this.registrationExpirationDate!="Invalid Date") {
                     if(this.globalService.isOldDate(this.registrationExpirationDate)) {
                        this.registrationExpired = true;
                        this.displayRegistrationDate =  this.globalService.getFullFormateDate(this.registrationExpirationDate,"GD","")
                    } else {
                        this.registrationExpired = false;
                    }
                } else {
                    this.registrationExpired = false;
                }

                /* END */

                // this.newRequest.controls.distance_covered.setValue(this.serviceVehicles[i].distance_covered);
                this.mileage = this.serviceVehicles[i].distance_covered == "" || this.serviceVehicles[i].distance_covered == undefined ? "0.00" : this.serviceVehicles[i].distance_covered
                // var mileage = <HTMLInputElement> document.querySelector('#mileage')
                // mileage.value="";
                // mileage.click();
                break;
                }
            }
        }
    }
    ngOnInit() {
    this.sTimeSlots  = globalConstants.TIME_SLOTS;
    this.currentUser = this.globalService.getCurrentUser();
    }
    changeListner(event, rendering_tag_id) {
        this.globalService.imageLoader();
        var html = "";
        let ele = <HTMLInputElement> document.getElementById('add' + rendering_tag_id);

        let multi = true;
        if (!this.isMultipleFile) {
            multi = false;
        }
        if (event.target.files && event.target.files[0]) { 
          var filesAmount = event.target.files.length;
          for (let i = 0; i < filesAmount; i++) {
        let cFiles={src:'',type:0,name:''};
          var reader = new FileReader(); 
          let file = event.target.files[i];

            let valid=true;
            if (rendering_tag_id == 'request_document') {
                valid=this.globalService.validateFiles('docpdf',event.target.files);
            }else{
                valid=this.globalService.validateFiles('image',event.target.files);
            }
            if(valid==false){
                if (ele) {
                    ele.value = '';
                }
                // event.target.value = "";
                return false;
            }
            // let file = event.target.files[0];
            if (valid) {
                if (file.size) {
                    let data = new FormData();
                    let url = globalConstants.API_NEW_SERVICE_REQUEST_FILE;
                    data.append('customer_id', this.customerId);
                    data.append(rendering_tag_id, file);
                    let FileType=this.globalService.getFileIcon(file);
                    cFiles.type=FileType;
                    if (rendering_tag_id == 'request_document') {
                        if (!multi && this.request_document.length != 0) {
                            this.globalService.snackbar("error", "Update your subscription to upload multiple files.");
                            if (ele) {
                                ele.value = '';
                            }
                            return false;
                        }
                         data.append('new_service_file', 'doc');
                            this.request_document_loader = true;
                       
                    } else if (rendering_tag_id == 'request_image') {
                        if (!multi && this.request_image.length != 0) {
                            this.globalService.snackbar("error", "Update your subscription to upload multiple files.");
                            if (ele) {
                                ele.value = '';
                            }
                            return false;
                        }
                           this.request_image_loader = true;
                            let reader = new FileReader();
                            reader.onload = (e) => {
                                cFiles.src = <string>e.target['result'];

                            }
                            reader.readAsDataURL(event.target.files[i]); 

                    }else if (rendering_tag_id == 'other_lights'){
                     
                        let read = new FileReader();
                        read.onload = (e:any) => {
                            this.jobImages = e.target['result'];

                        }
                        read.readAsDataURL(event.target.files[i]);
                      
                    } 
                    this.globalService.formData(url, data).subscribe((response:any) => {
                        let resData = response;
                        if (resData.result == 1) { 
                            this.checkFileUploadLimit(cFiles,resData,rendering_tag_id);
                            
                            // event.target.value = "";
                        } else {
                            if (ele) {
                                ele.value = '';
                            }
                            this.globalService.snackbar("error", resData.message);
                        }
                    })
                } else {
                    if (ele) {
                        ele.value = '';
                    }
                    this.globalService.snackbar("error", 'Please upload a valid file.');
                }
            } else {
                if (ele) {
                    ele.value = '';
                }
                this.globalService.snackbar("error", 'Please upload a valid file.');
            }
            // event.target.value = "";
        }
        }
    }
    deleteImg(type,index?:any) {
        let ele = <HTMLInputElement> document.getElementById('add' + type);
        if (ele) {
            ele.value = '';
        }

        let base64valuec = '';
        if (type == "request_image") {
            this.request_image.splice(index,1);
            this.newRequest.controls.request_image.setValue(base64valuec);
        } else if (type == 'request_document') {
            this.request_document.splice(index,1);
            this.newRequest.controls.request_document.setValue(base64valuec);
        }

    }
    addRejectedJobs(index) {

        var key = {};
        var opt = {level1Id:'',level2Id:'',level3Id:''};      
        let l2id = this.rejectedJobList[index]["level1Job"] + '-' + this.rejectedJobList[index]["level2Job"];
        opt.level1Id=this.rejectedJobList[index]["level1Job"];
        opt.level2Id=this.rejectedJobList[index]["level2Job"];
        if (this.rejectedJobList[index]["job_level"] == "3") {

            this.newRequest.addControl("rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][" + (this.rejectedJobList[index]["level3Job"]) + "][labor_price]", new FormControl("[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][labor_price]"));
            this.newRequest.controls["rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][" + (this.rejectedJobList[index]["level3Job"]) + "][labor_price]"].setValue(this.rejectedJobList[index]["estimated_labor"])


            this.newRequest.addControl("rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][" + (this.rejectedJobList[index]["level3Job"]) + "][nexpart_labor_time]", new FormControl("[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][nexpart_labor_time]")); 
            this.newRequest.controls["rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][" + (this.rejectedJobList[index]["level3Job"]) + "][nexpart_labor_time]"].setValue(this.rejectedJobList[index]["nexpart_labor_time"])

            this.newRequest.addControl("rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][" + (this.rejectedJobList[index]["level3Job"]) + "][labor_time]", new FormControl("[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][labor_time]"));
            this.newRequest.controls["rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][" + (this.rejectedJobList[index]["level3Job"]) + "][labor_time]"].setValue(this.rejectedJobList[index]["estimated_time"])

            // add notes text and recommandation test for Rejected Jobs
            this.newRequest.addControl("rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][" + (this.rejectedJobList[index]["level3Job"]) + "][notes]", new FormControl("[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][notes]"));
            this.newRequest.controls["rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][" + (this.rejectedJobList[index]["level3Job"]) + "][notes]"].setValue(this.rejectedJobList[index]["notes"])
            this.newRequest.addControl("rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][" + (this.rejectedJobList[index]["level3Job"]) + "][recommendations]", new FormControl("[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][recommendations]"));
            this.newRequest.controls["rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][" + (this.rejectedJobList[index]["level3Job"]) + "][recommendations]"].setValue(this.rejectedJobList[index]["recommendation"])

            let l3id = this.rejectedJobList[index]["level3Job"];
            let l2Box = <HTMLInputElement> document.getElementById('request-check-' + l2id + '-input');
            let eleRadio = document.getElementById('requestRadio-' + l2id + '-' + l3id + '-input');
            let eleTxt:any = document.getElementById(l2id);
            let eleCheckbox;
            opt.level3Id=this.rejectedJobList[index]["level3Job"];
            if (l2Box.checked == false) {
                if(eleTxt){
                    jQuery('#request-check-' + l2id).trigger('click');
                    let value=(typeof this.rejectedJobList[index].service_request_input !='undefined' ? this.rejectedJobList[index].service_request_input : '' );
                    let th=this;
                    setTimeout(function(){
                        jQuery(eleTxt).val(value);
                        jQuery('#'+l2id).trigger('click');
                        th.newRequest.addControl("chk[" + opt.level1Id + "][" + opt.level2Id + "][" + opt.level3Id + "]", new FormControl("chk[" + opt.level1Id + "][" + opt.level2Id + "][" + opt.level3Id + "]"));
                        th.newRequest.controls["chk[" + opt.level1Id + "][" + opt.level2Id + "][" + opt.level3Id + "]"].setValue(value);
                        th.newRequest.removeControl("chk[" + opt.level1Id + "][" + opt.level2Id + "]");
                    },200);
                }else{
                    l2Box.click();
                }
            }
            if (eleRadio) {
                eleRadio.click();
            } else {
                eleCheckbox = document.getElementById('requestid-' + l2id + '-' + l3id + '-input');
                if (eleCheckbox) {
                    eleCheckbox.click();
                }
            }
            var replaceQuotesWithPipes = "";
            var l3parts = new Array();
              if (this.rejectedJobList[index]["Parts"] != undefined) {
                for (var p = 0; p < this.rejectedJobList[index]["Parts"].length; p++) {
                    var stringifyWholeObject = JSON.stringify(this.rejectedJobList[index]["Parts"][p])
                    replaceQuotesWithPipes = stringifyWholeObject.replace(/\"/g, '|');
                    l3parts.push(replaceQuotesWithPipes);
                }
            }
            this.newRequest.addControl("rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][" + (this.rejectedJobList[index]["level3Job"]) + "][parts]", new FormControl("[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][" + (this.rejectedJobList[index]["level3Job"]) + "][parts]"));

            this.newRequest.controls["rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][" + (this.rejectedJobList[index]["level3Job"]) + "][parts]"].setValue("["+l3parts+"]")

            var l3GJ = new Array();
            if (this.rejectedJobList[index]["GenericJobs"] != undefined) {
                for (var g = 0; g < this.rejectedJobList[index]["GenericJobs"].length; g++) {
                    var stringifyWholeObject = JSON.stringify(this.rejectedJobList[index]["GenericJobs"][g])
                    replaceQuotesWithPipes = stringifyWholeObject.replace(/\"/g, '|');
                    l3GJ.push(replaceQuotesWithPipes)
                }
            }
            this.newRequest.addControl("rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][" + (this.rejectedJobList[index]["level3Job"]) + "][genericJobs]", new FormControl("[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][" + (this.rejectedJobList[index]["level3Job"]) + "][genericJobs]"));
            this.newRequest.controls["rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][" + (this.rejectedJobList[index]["level3Job"]) + "][genericJobs]"].setValue("["+l3GJ+"]")

        } else if (this.rejectedJobList[index]["job_level"] == "2") {
            var arr = new Array();
            document.getElementById('request-check-' + l2id + '-input').click();
            this.newRequest.addControl("rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][labor_price]", new FormControl("[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][labor_price]"));
            this.newRequest.controls["rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][labor_price]"].setValue(this.rejectedJobList[index]["estimated_labor"]);

            
            
            this.newRequest.addControl("rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][nexpart_labor_time]", new FormControl("[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][nexpart_labor_time]"));
            this.newRequest.controls["rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][nexpart_labor_time]"].setValue(this.rejectedJobList[index]["nexpart_labor_time"])
             

            this.newRequest.addControl("rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][labor_time]", new FormControl("[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][labor_time]"));
            this.newRequest.controls["rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][labor_time]"].setValue(this.rejectedJobList[index]["estimated_time"])
            
            // add notes text and recommandation test for Rejected Jobs
            this.newRequest.addControl("rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][notes]", new FormControl("[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][notes]"));
            this.newRequest.controls["rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][notes]"].setValue(this.rejectedJobList[index]["notes"])
            this.newRequest.addControl("rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][recommendations]", new FormControl("[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][recommendations]"));
            this.newRequest.controls["rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][recommendations]"].setValue(this.rejectedJobList[index]["recommendation"])
            
            
            var replaceQuotesWithPipes = "";
            var s = new Array();
            if (this.rejectedJobList[index]["Parts"] != undefined) {
                for (var p = 0; p < this.rejectedJobList[index]["Parts"].length; p++) {
                    var stringifyWholeObject = JSON.stringify(this.rejectedJobList[index]["Parts"][p])
                    replaceQuotesWithPipes = stringifyWholeObject.replace(/\"/g, '|');
                    s.push(replaceQuotesWithPipes)
                }
            }
            this.newRequest.addControl("rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][parts]", new FormControl("[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][parts]"));
            this.newRequest.controls["rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][parts]"].setValue("["+s+"]")
            var s1 = new Array();
            if (this.rejectedJobList[index]["GenericJobs"] != undefined) {
                for (var g = 0; g < this.rejectedJobList[index]["GenericJobs"].length; g++) {
                    var stringifyWholeObject = JSON.stringify(this.rejectedJobList[index]["GenericJobs"][g])
                    replaceQuotesWithPipes = stringifyWholeObject.replace(/\"/g, '|');
                    s1.push(replaceQuotesWithPipes)
                }
            }
            this.newRequest.addControl("rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][genericJobs]", new FormControl("[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][genericJobs]"));
            this.newRequest.controls["rej[" + (this.rejectedJobList[index]["level1Job"]) + "][" + (this.rejectedJobList[index]["level2Job"]) + "][genericJobs]"].setValue("["+s1+"]")
        }
        this.getSelectedOptionForx(opt);
        let ele = <HTMLButtonElement> document.getElementById('rejected-' + l2id);
        ele.disabled = true;
    }


    deleteRejectedJob(m1, m2, m3) {
        if (m3) {
            this.newRequest.removeControl("rej[" + m1.id + "][" + m2.id + "][" + m3.id + "][labor_price]")
            this.newRequest.removeControl("rej[" + m1.id + "][" + m2.id + "][" + m3.id + "][nexpart_labor_time]")
            this.newRequest.removeControl("rej[" + m1.id + "][" + m2.id + "][" + m3.id + "][labor_time]")
            this.newRequest.removeControl("rej[" + m1.id + "][" + m2.id + "][" + m3.id + "][parts]")
            this.newRequest.removeControl("rej[" + m1.id + "][" + m2.id + "][" + m3.id + "][genericJobs]")
        } else {
            this.newRequest.removeControl("rej[" + m1.id + "][" + m2.id + "][labor_price]")
            this.newRequest.removeControl("rej[" + m1.id + "][" + m2.id + "][nexpart_labor_time]")
            this.newRequest.removeControl("rej[" + m1.id + "][" + m2.id + "][labor_time]")
            this.newRequest.removeControl("rej[" + m1.id + "][" + m2.id + "][parts]")
            this.newRequest.removeControl("rej[" + m1.id + "][" + m2.id + "][genericJobs]")
        }
    }
    deleteJobImg(m1,m2,m3){
        let f= <HTMLButtonElement> document.getElementById(m3.id+'jobs');
        f.value="";
        this.newRequest.controls.request_other_light.setValue('');
        this.jobImages='';
        delete this.checklist["chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]"]
        this.newRequest.removeControl("chk[" + m1.id + "][" + m2.id + "][" + m3.id + "]");
        delete this.checklist['chk_' + m1.id + "_" + m2.id + "_" + m3.id];
        this.newRequest.removeControl('chk_' + m1.id + "_" + m2.id + "_" + m3.id);
      }
    /* DATE FORMAT FUNCTION */
    formatDateToString(date){
       // 01, 02, 03, ... 29, 30, 31
       var dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
       // 01, 02, 03, ... 10, 11, 12
       var MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
       // 1970, 1971, ... 2015, 2016, ...
       var yyyy = date.getFullYear();

       // create the format you want
       return (MM + "/" + dd + "/" + yyyy);
    }

    setTime(hoursMinutes){
    const time    = hoursMinutes.split(":");
    let  hour     = time[0];
    let  minutes  = "00";
    if(time[1] > 0 && time[1] <= 15){
        minutes = "15";
    }else if(time[1] > 15 && time[1] <= 30){
        minutes = "30";
    }else if(time[1] > 30 && time[1] <= 45){
        minutes = "45";
    }else if(time[1] > 45){
        hour = Number(hour)+1;
    }   
    
    hour    = hour.toString().padStart(2, "0");
    minutes = minutes.toString().padStart(2, "0");
    return hour+":"+minutes;
    }

    dateChange(event){
        let date = new Date(event.target.value);
        this.date = this.formatDateToString(date);
        this.dateValid = true;
        this.updateDateTime();
    }

    updateDate(event){
        let date = new Date(event.target.value);
        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 1);
        if(this.globalService.isValidDate(date) && (this.isDIY || currentDate <= date)){
            this.date = this.formatDateToString(date);
            this.calDate = date;
            this.dateValid = true;
            this.updateDateTime();
        }else{
            this.submited = true;
            this.dateValid = false;
        }
    }

    startTimeHandler(event) {
        this.time = event;
        // let currentTime = this.setTime(new Date().getHours()+':'+new Date().getMinutes());
        // if(new Date().toDateString() == new Date(this.date).toDateString() && this.time <= currentTime){
        //     this.time = currentTime;
        // }
        this.updateDateTime();
    }

    updateDateTime(){
        let time = '';
        if(this.date == '' || this.time == ''){
            return;
        }
        if(parseInt(this.time.split(':')[0]) >= 12){
            time = this.time.replace(this.time.split(':')[0],(parseInt(this.time.split(':')[0])-12).toString()) + ' PM';
        }else{
            if(this.time.split(':')[0] == '00'){
                time = this.time.replace('00','12') + ' AM';
            }else{
                time = this.time + ' AM';
            }    
        }
        this.newRequest.patchValue({
            request_date: this.date + ' ' + time
        });
    }

    /* CHECK DATES */
    chkDate(date){
        if (date!="0000-00-00 00:00:00") {
            var currentDate = new Date();
            if(new Date(date)< new Date(currentDate)) {
                return true
            } else {
                return false;
            }

        }
    }
    carAttribute(){
        return false;
    }
    observableSourceForx = (keyword: any): Observable<any[]> => {
        let url: string = globalConstants.API_search_service_requests_list+'?keyword='+keyword;
        if (typeof keyword !='undefined' && keyword.length >= 3) {
        let f=[];
        return this.globalService.getAPI(url).map(res => {
                  let json = res;
                  if(json && json != null && typeof json.data != 'undefined'){
                    json.data.forEach(function(x){
                      f.push(x);
                    });
                  }
                  return f;
                })
             } else {
          return Observable.of([]);
        }
      }
      getSelectedOptionForx(opt){

        let l1=opt.level1Id;
        let l2=opt.level2Id;
        let l3=opt.level3Id;
        for(var i in this.serviceRequestList){
            if(this.serviceRequestList[i].id==l1){
                var target= '#expansion-'+ (parseInt(i)+1);
                $(`${target}`).children()[0].click();
                this.expansionOpen((parseInt(i)+1));
                if(typeof this.serviceRequestList[i].children != 'undefined'){
                    let l1child=this.serviceRequestList[i].children;
                    for(var j in l1child){
                        if(l1child[j].id==l2){
                            if(typeof l1child[j].children != 'undefined'){
                                let l2child=l1child[j].children;
                                for(var k in l2child){
                                    if(l2child[k].id==l3){
                                        this.serviceRequestList[i].is_searchable=2;
                                        this.serviceRequestList[i].children[j].is_searchable=2;
                                        this.serviceRequestList[i].children[j].children[k].is_searchable=2;
                                        let ele=<HTMLInputElement>document.getElementById('request-check-'+l1+'-'+l2+'-input');
                                        let container = document.getElementById('second-level-child-'+l2);
                                        let eleL3=<HTMLInputElement>document.getElementById('requestid-'+l1+'-'+l2+'-'+l3+'-input');
                                        let eleR3=<HTMLInputElement>document.getElementById('requestRadio-'+l1+'-'+l2+'-'+l3+'-input');
                                        let box = container.querySelectorAll('input[type="checkbox"]:checked');
                                        if(eleR3){
                                            box = container.querySelectorAll('input[type="radio"]:checked');
                                        }
                                        if(ele && box.length==0){
                                            if(!jQuery(ele).is(':checked')){
                                                ele.click();
                                            }
                                        }
                                        eleL3 = (eleL3 || eleR3);
                                        this.searchableJobSelect(l1,l2,l3,ele,eleL3,target);
                                        break ;
                                    }
                                }
                            } else{
                                
                                this.serviceRequestList[i].is_searchable=2;
                                this.serviceRequestList[i].children[j].is_searchable=2;
                                let ele2=<HTMLInputElement>document.getElementById('request-check-'+l1+'-'+l2+'-input');
                                this.searchableJobSelect(l1,l2,l3,ele2,'',target);
                                this.checkChild(l1, null);
                                break ;
                            }
                        }
                    }
                }
            }
        }
      }
      autocompleListFormatter(opt){
        return opt.value;
      }
      searchableJobSelect(l1, l2, l3, ele2, ele3, target) {
          let t = false;
          if (l3 == "") {
              t = jQuery('#request-check-' + l1 + '-' + l2 + '-input').is(':checked');
              if (ele2 && t == false) {
                  ele2.click();
              }
          } else {
              let eleL3 = <HTMLInputElement>document.getElementById('requestid-' + l1 + '-' + l2 + '-' + l3 + '-input');
              let rId = (eleL3 ? 'requestid-' : 'requestRadio-');
              t = jQuery('#' + rId + l1 + '-' + l2 + '-' + l3 + '-input').is(':checked');
              if (ele3 && t == false) {
                  ele3.click();
              }
          }
          setTimeout(() => {
              var body = $("html, body");
              body.animate({
                  scrollTop: jQuery(target).offset().top - 90
              }, 500);
          }, 300);
          let txt = <HTMLInputElement>document.getElementById('searchCarAattribute')
          txt.value = '';
          if (!jQuery(target).hasClass('mat-expanded')) {
            jQuery(target).click();
          }
      }
    checkFileUploadLimit(cFiles, resData, rendering_tag_id) {
        let multi=true;
        if(! this.isMultipleFile){
            multi=false;
        }   
        if (rendering_tag_id == 'request_document') {
            cFiles.name = resData.data.name;
            if(!multi){
                this.request_document=[]; 
            }
            this.request_document.push(cFiles);
            this.newRequest.controls.request_document.setValue(resData.data.name);
            this.request_document_loader = false;
        } else if (rendering_tag_id == 'request_image') {
            this.request_image_loader = false;
            cFiles.name = resData.data.name;
            if(!multi){
                this.request_image=[];
            }
            this.request_image.push(cFiles);
            this.newRequest.controls.request_image.setValue(resData.data.name);

        } else if (rendering_tag_id == 'other_lights') {
            this.newRequest.controls.request_other_light.setValue(resData.data.name);
        } 
    }

    redirectToFillRecomPage(reqId, url) {
        if (!this.globalService.isUserReportOn()) {
            this.router.navigate([url]);
        } else {
            this.router.navigate([url]);
           //this.autoAssign(reqId, url);
        }
        
    }

    autoAssign(rId, url) {
        let data = { assign_totech: 1, emp_id: this.currentShop.id, request_id: rId, user_id: this.currentShop.id, user_type: this.currentShop.user_type };
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_ASSIGN_SERVICE_REQUEST, data).subscribe((tech:any) => {
            if (tech.result == 1) {
                this.router.navigate([url]);
            } else if(tech.code=='400'){
                this.router.navigate([url]);
            }
        }, (error) => { })
    }

    isAllowed(action,module?:any){
        if(typeof module!='undefined'){
            return this.globalService.getAcl(module,action);
        }
        return false;
    }

    gotoAddCar() {
        let currentUser = JSON.parse(localStorage.getItem("loggedInUser"));
        var customerOprs = "user_id="+currentUser.id+"&user_type="+currentUser.user_type+"&customer_id="+ this.customerId;
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_DETAILS,customerOprs).subscribe((data:any) => {
            if(data.result =="1"){
                let customerDetails = data.data;
                let params = this.customerId+','+customerDetails.country_id;
                localStorage.setItem("shopCustomerID", params);
                this.router.navigate(['shops/add-car']);
            }
         },(error)=>{});
    }

    onDateChange(event:any){
        this.selectedDateAndTime = event.value;
    }

    convertIntoDecimal(event,frmControlName,beforeDecimalDigit,digitAfterDecimal){
        let fieldValue = this.newRequest.get(frmControlName).value;
        let result= this.globalService.processDecimalValue(fieldValue,beforeDecimalDigit);
        this.newRequest.get(frmControlName).setValue(result);
        return globalFunctions.validateNumbersWithDecimal(event,digitAfterDecimal);
    }

    validateFieldOnPaste(controlName: string) {
        const control = this.newRequest.get(controlName);
        control.markAsTouched();
    }
    ngOnDestroy(){
        if (this.subscriptionDataForAPI) {
            this.subscriptionDataForAPI.unsubscribe();
          }
    }
}

@Component({
    templateUrl: 'add-car-dialog.html',
    styles: [`
    .subscription-box {
        height: auto;
    }
    .subscription-box .alert-danger{
        padding:0;
        background:transparent;
    }
    .mat-dialog-container{
        padding:0 !important;
    }
    .subscription-content{
        width: 500px;
        min-height: 227px;
        background: rgba(245, 0, 87, 0.12);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 25px 40px 25px;
        font-size: 17px;
    }
    .subscription-content img{
        display:  block;
         width: 113px;
         border-radius:  50%;
         margin-bottom: 18px;
         margin-top: 50px;
    }
    .subscription-box .btn{
        margin:20px 0;
    }
    @media only screen and (max-width: 768px) {
        .subscription-content{
            width: auto;
        }
    }
    `]
})
export class addCarDialog {
    public customer_id;
    public customer_country;
    public sendParams;

    public currentCustomer;
    public currentUserID;
    public userType;
    public carCustomerDetails;

    constructor(public dialogRef: MatDialogRef<addCarDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private router: Router, private globalService: GlobalService) {
        this.customer_id = data.cusId;
        this.customer_country = localStorage.getItem("customer_country");
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
        this.currentUserID = this.currentCustomer.id;
        this.userType = this.currentCustomer.user_type;
        //this.sendParams = this.customer_id + ',' + this.customer_country;
        this.globalService.diaLogObj(this.dialogRef);
        var customerOprs = "user_id="+this.currentUserID+"&user_type="+this.userType+"&customer_id="+ this.customer_id;
        this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_DETAILS,customerOprs).subscribe((data:any) => {
            if(data.result =="1"){
                this.carCustomerDetails = data.data;
                this.sendParams = this.customer_id+','+this.carCustomerDetails.country_id;
            }
         },(error)=>{});
    }
    addCar() {
        localStorage.setItem("shopCustomerID", this.sendParams);
        this.router.navigate(['shops/add-car']);
        this.dialogRef.close();
    }
}
