import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { GlobalService } from '../../../../environments/global.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { globalConstants } from '../../../../environments/globalConstants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ViktableComponent, VikSource } from '../../../admin/viktable/viktable.component';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { globalFunctions } from '../../../../environments/globalFunctions';
import { SmartBatchAssignComponent } from '../smart-batch-assign/smart-batch-assign.component';
import { StockorderpopupComponent } from '../stockorderpopup/stockorderpopup.component';
import { EditpartPopupComponent } from '../../shopeditpart/editpart-popup/editpart-popup.component';
const UPLOAD_LIST = "part-upload-detail-";
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-add-smart-batch',
  templateUrl: './add-smart-batch.component.html',
  styleUrls: ['./add-smart-batch.component.scss'],

})
export class AddSmartBatchComponent extends ViktableComponent implements OnInit,OnDestroy  {

  public user: any = null;
  public addBatches: FormGroup;
  public batchId;
  public heading: string = "ADD NEW";
  public showPartList: boolean = false;
  displayedColumns = ['Name', 'part_number', 'Price', 'Quantity', 'Actions'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: any;
  public tablesData = this;
  public options = {
    user_type: "User",
    user_id: "",
    search_by: "2",
    search_keyword: "",
    batch_id: "",
    start: 0,
    limit: 10
  }
  url = "";
  public addTostockOrder = false;
  public subscriptionDataForAPI: Subscription = new Subscription();
  constructor(public router: Router, public gs: GlobalService, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, public dialog: MatDialog) {
    super(globalConstants.API_GET_SMART_BATCH_PART_LIST, gs);
    this.returnData = 'uploadDetail';
    this.user = this.gs.getCurrentUser();
    if (this.user) {
      this.sessionKey = UPLOAD_LIST;
      this.url = globalConstants.API_GET_SMART_BATCH_PART_LIST;
      this.gs.setMetaData("SHOPS", "ADD_SMART_BATCH");
    } else {
      this.router.navigate(['shops']);
    }
    this.getParams();
    if (this.batchId) {

      this.heading = "EDIT";
      this.gs.setMetaData("SHOPS", "EDIT_SMART_BATCH");
    }
  }

  ngOnInit() {
    this.initAddForm();
    this.addTostockOrder = this.gs.getAcl('stock_order', 'addNew');
    if (this.batchId) {
      this.getBatchDetail();
      this.reinitOptions();
    }
  }

  ngAfterViewInit(){
    this.setDataTable();
  }
  initAddForm() {
    this.addBatches = this.formBuilder.group({
      group_name: ['', Validators.required],
      batch_number: ['', Validators.required],
      user_type: this.user.user_type,
      user_id: this.user.id
    })
  }
  mandatoryMsg() {
    this.gs.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
  }


  AddBatch(opr) {
    Object.keys(this.addBatches.controls).map((controlName) => {
      this.addBatches.get(controlName).markAsTouched({ onlySelf: true });
    });

    if (this.addBatches.valid) {
      this.addBatches.controls.user_type.setValue(this.user.user_type);
      this.addBatches.controls.user_id.setValue(this.user.id);

      var data = new FormData();
      for (let i in this.addBatches.value) {
        data.append(i, this.addBatches.value[i]);
      }
      let url = globalConstants.API_ADD_SMART_BATCHES;
      if (this.batchId) {
        url = globalConstants.API_EDIT_SMART_BATCHES;
        data.append('batch_id', this.batchId);
      }
      this.gs.formData(url, data).subscribe(data => {
        var message = data.message;
        if (data.result == "1") {
          this.gs.snackbar('success', message);
          if (opr == 1) {
            this.router.navigate(["/shops/list-smart-batch"]);
            
          } else if (opr == 2) {
            this.router.navigate(["shops/edit-smart-batch/"+data.smart_id]);
          }
        } else {
          this.gs.snackbar('error', message);
        }
      }, (error) => { });
    } else {
      this.mandatoryMsg();
    }
  }

  getBatchDetail() {
    var shopContent = "user_id=" + this.user.id + "&user_type=" + this.user.user_type + "&batch_id=" + this.batchId;
    this.subscriptionDataForAPI =  this.gs.callAPI(globalConstants.API_SHOP_SMART_BATCH_LIST, shopContent).subscribe((data:any) => {
      if (data && data.result == '1') {
        this.addBatches.patchValue(data.data);
        this.options.search_keyword = data.data.batch_number;
        this.showPartList = true;
      }
    });
  }
  isAllowed(action, module?: any) {
    return this.gs.getAcl(module, action);
  }
  /** init options */
  reinitOptions() {
    this.options = this.gs.localGetterSetter(this.options, this.options, UPLOAD_LIST);
    this.options.user_type = this.user.user_type;
    this.options.user_id = this.user.id;
    this.options.batch_id = this.batchId;
  }
  /** set data source of smart batch part list */
  setDataTable() {
    this.dataSource = new VikSource(this.tablesData, this.sort, this.paginator);
    this.dataSource.getSortedData = this.sortedData;
  }
  /** create record, that show on smart batch part list */
  createRecordrecord(record) {
    return {
      id: record.id,
      part_id: record.id,
      name: record.name,
      price: record.price,
      quantity: record.quantity,
      picture: record.part_picture,
      part_number: record.part_number,
      delete_status: record.delete_status,
      low_stock_value: record.low_stock_value,
      added_on: this.gs.getFullFormateDate(record.add_date, 'GD', 'H'),
      is_low_stock: this.isLowStock(record.low_stock_value, record.quantity)
    };
  }
  /** sort smart batch part list */
  sortedData(): any[] {
    let th: any = this;
    const data = th._exampleDatabase.data.slice();
    if (!th._sort.active || th._sort.direction == '') { return data; }
    return data.sort((a, b) => {
      let propertyA;
      let propertyB;
      switch (th._sort.active) {
        case 'Name': propertyA = a.name; propertyB = b.name; break;
        case 'Create At': propertyA = a.added_on; propertyB = b.added_on; break;
        default: return 0;
      }
      let valueA = isNaN(propertyA) ? propertyA.toUpperCase() : propertyA;
      let valueB = isNaN(propertyB) ? propertyB.toUpperCase() : propertyB;

      return (valueA < valueB ? -1 : 1) * (th._sort.direction == 'asc' ? 1 : -1);
    });
  }

  /** get param id */
  getParams() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.batchId = params["id"];
    });
  }
  isLowStock(low_stock, quantity) {
    let lq = parseFloat(low_stock);
    let q = parseFloat(quantity);
    return (q <= lq ? true : false);
  }
  AssignBatch() {
    let dialogRef = this.dialog.open(SmartBatchAssignComponent, {
      panelClass: ['car-dialog-form', 'ticket-dialog'],
      width: '800px',
      data: { id: this.batchId, ref: this }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != 'undefined' && typeof result.part_id != 'undefined') {

      }
    });
  }
  showWholeNumber(val) {
    return globalFunctions.hasDecimal(val, true);
  }
  addStockOrder(row) {
    row.id = row.part_id;
    let dialogRef = this.dialog.open(StockorderpopupComponent, {
      panelClass: ['car-dialog-form', 'ticket-dialog'],
      width: '800px',
      data: { newAllowed: true, recom: null, request_id: "", requestId: '', part: row }
    });
  }

  editPart(part_id) {
    let dialogRef = this.dialog.open(EditpartPopupComponent, {
      panelClass: 'car-dialog-form',
      data: { part_id: part_id }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.reinitOptions();
      this.setDataTable();
    });
  }

  viewLog(id) {
    this.router.navigate(['shops/part/logs', id]);
  }
  removeBatch(row) {
    this.gs.confirmDialog('Yes', 'Are you sure you want to remove this part from batch?').subscribe((res) => {
      let result: any = res;
      if (result == 'Yes') {
        let content = { "user_id": this.user.id, "user_type": this.user.user_type, "part_id": row.id, "batch_id": this.batchId };
        this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_DELETE_PART_FROM_SMARTBATCH_ASSIGNMENT, content).subscribe((res:any) => {
          var message = res.message;
          if (res.result == 1) {
            this.gs.snackbar('success', message);
            this.setDataTable();
          } else {
            this.gs.snackbar('error', message);
          }
        }, (error) => { })
      }
    })
  }

  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
} 

}
