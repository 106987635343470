import { Component,  OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
import {TechassignmentComponent} from '../techassignment/techassignment.component';
import { NavigationEnd, Router } from '@angular/router';
import { EditpopupComponent } from '../../shopcar/editpopup/editpopup.component';
import { ServiceFieldUpdateComponent } from './service-field-update/service-field-update.component';
import { ServiceCalendarComponent } from '../service-calendar/service-calendar.component';
import { CustomerEditModalComponent } from '../../../customer/customer-edit-modal/customer-edit-modal.component';
import {IDatePickerConfig} from 'ng2-date-picker';
import {UsercontactsComponent} from '../../../usercontacts/usercontacts.component';
import { AssignemployeeComponent } from '../../quickappt/assignemployee/assignemployee.component';
import { AddLabelDialogComponent } from '../add-label-dialog/add-label-dialog.component';
import * as moment from 'moment';
import { AdjustTechScheduleComponent } from '../attention-service-requests/adjust-tech-schedule/adjust-tech-schedule.component';
import { ImagetooldialogComponent } from '../../../imagetooldialog/imagetooldialog.component';
// import {VideoShowComponent} from '../video-show/video-show.component';


@Component({
  selector: 'app-service-calendar-modal',
  templateUrl: './service-calendar-modal.component.html',
  styleUrls: ['./service-calendar-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe]
})



export class ServiceCalendarModalComponent implements OnInit {

  public param:any={};
  public carDetail:any={};
  public customerDetail:any={};
  public delivery_date:any;
  public EstServicetime;
  public start_date:any;
  public end_date:any;
  public isAppointment=false;
  public serviceRequestId;
  public customer_id;
  public vehicle_id;
  public user:any;
  public contacts:any=[];
  public labelPhoto=[];
  public serviceInfo: FormGroup;
  public status;
  public isEmployee=false;
  public currentCustomer;
  public currentUserID;
  public userType;
  public techinianStatus=[];
  public logs = [];
  public skipTicketAssign=false;
  public isReportGenerate=false;
  public roles=globalConstants.ROLES;
  public allLabels = [];
  public preSelectLabel=[];
  public modulesName='service_request';
  public imgPath = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_PICTURES + globalConstants.IMG_THUMB;
  public insurance = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_INSURANCE + globalConstants.IMG_THUMB;
  public registeredPicture = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_REGISTRATION_PIC + globalConstants.IMG_THUMB;
  public vinPicture = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_VIN_PIC + globalConstants.IMG_THUMB;
  public vehVideo = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_VIDEO;
  public VEHICLE_LABEL_PIC= globalConstants.S3_BUCKET_URL +globalConstants.VEHICLE_LABEL_PICTURES + globalConstants.IMG_THUMB;
  public is_assign = 0;

  public statuses = ['Appointment', 'Pending', 'Processing', 'Waiting for Approval', '', 'Service Completed', 'Completed', 'Approval Completed', 'Completed And Paid', 'Unread Rating', 'Non-CAR','','Work In Progress'];
  public techStatus = ['Yet to start','Working','Pause','Done'];
  datePickerConfig: IDatePickerConfig = {
    drops: "down",
    format: "MM/DD/YYYY hh:mm A",
    min: this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm a'),
    closeOnSelect:true,
    disableKeypress:true,
    unSelectOnClick:false
};
  constructor(public gs: GlobalService,
    public dialogRef: MatDialogRef<ServiceCalendarModalComponent>,@Inject(MAT_DIALOG_DATA)
    public data: any,
    public router: Router,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    ) 
    {
    if(data.dialogMetaData.event_type == 1){this.isAppointment = true;}
      this.param = data,
      this.serviceRequestId = data.dialogMetaData.request_id;
      this.customer_id = data.dialogMetaData.customer_id;
      this.vehicle_id = data.dialogMetaData.car_id;
      this.status = data.dialogMetaData.status;
      this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
      this.currentUserID = this.currentCustomer.id;
      this.userType = this.currentCustomer.user_type;
      // this.EstServicetime = data.dialogMetaData.est_hours;

      if(data.dialogMetaData.est_hours != 'undefined' || data.dialogMetaData.est_hours != '' || data.dialogMetaData.est_hours != null){
        this.EstServicetime = data.dialogMetaData.est_hours;
      }else{
        this.EstServicetime = '-';
      }

      console.log('dialogMetaData',this.param.dialogMetaData);

      if(this.param.dialogMetaData.requested_delivery_date){
        this.param.dialogMetaData.requested_delivery_date = this.datePipe.transform(moment(this.param.dialogMetaData.requested_delivery_date).toISOString(), 'MM/dd/yyyy hh:mm a')
      }

      if(this.param.dialogMetaData.schedule_service_date){
       this.param.dialogMetaData.schedule_service_date = this.datePipe.transform(moment(this.param.dialogMetaData.schedule_service_date).toISOString(), 'MM/dd/yyyy hh:mm a')
      }

      if(this.param.dialogMetaData.start_date){
       this.param.dialogMetaData.start_date = this.datePipe.transform( moment(this.param.dialogMetaData.start_date).toISOString(), 'MM/dd/yyyy hh:mm a')
      }

      if(this.param.dialogMetaData.end_date){
        this.param.dialogMetaData.end_date = this.datePipe.transform( moment(this.param.dialogMetaData.end_date).toISOString(), 'MM/dd/yyyy hh:mm a')
      }
      
      if(this.param.dialogMetaData.is_assign){
        this.is_assign = this.param.dialogMetaData.is_assign;
      }
      

      this.isReportGenerate=this.gs.isUserReportOn();

      router.events.subscribe((val) => {
        this.dialogRef.close(); 
      });
  }

  ngOnInit() {
    
    this.gs.diaLogObj(this.dialogRef);
    this.user = this.gs.getCurrentUser();
    this.isEmployee=this.gs.isSubscribe('employee');
    let customerOprs = "user_id="+this.user.id+"&user_type="+this.user.user_type+"&customer_id="+ this.customer_id;
    this.getCustomerDeatils();
    this.getVehicleDetail()
    this.getContactDetails();
    this.getTicketAssignSetting();
    this.getAllLabels();
    this.getDefaultServiceRequestLabel();
    //this.getLog();
    // let content='user_id='+this.user.id+'&user_type='+this.user.user_type;
    // let data=content+'&request_id='+this.serviceRequestId;
    // this.gs.callAPI(globalConstants.API_SERVICE_REQUEST_TRACKERS,data).subscribe((r)=>{
    //   if(r.result==1){
    //     this.techinianStatus=r.data;

    //     console.log(this.techinianStatus);
    //   }
    // })
  }

  getCustomerDeatils(){
    let customerOprs = "user_id="+this.user.id+"&user_type="+this.user.user_type+"&customer_id="+ this.customer_id;
    this.gs.callAPI(globalConstants.API_GET_CUSTOMER_DETAILS,customerOprs).subscribe((res:any) => {
      if (res.result == 1) {
        this.customerDetail=this.getBlankToHypen(res.data);
      }
    },(error)=>{})
    this.getContactDetails();
  }
  
  getContactDetails(){
    let customerOprs = "user_id="+this.user.id+"&user_type="+this.user.user_type+"&customer_id="+ this.customer_id;
    this.gs.callAPI(globalConstants.API_CONTACTS_ADD_LIST,customerOprs).subscribe((res:any)=>{
      if (res.result == 1) {
        this.contacts=res.data;
      }
    },(error:any)=>{})
  }

  /*getLog() {
    let data='user_id='+this.user.id+'&user_type='+this.user.user_type+'&request_id='+this.serviceRequestId;
    this.gs.callAPI(globalConstants.API_SERVICE_REQUEST_LOGS, data).subscribe((r) => {
      if (r.result == 1) {
        this.logs = r.data;
      }else{
        this.logs = [];
      }
    });
  }*/

  isVisible(status){
    if(status == 6 || status == 8){
        return false;
    }
    return ( this.currentCustomer.role_id != 4 ? true : false );
  }

  menuEnable(isAssign){
    if (!this.isReportGenerate) {
       return true; 
    }else if(this.isReportGenerate && isAssign==1){
       return true; 
    }else{
       return false; 
    }
}

  getTicketAssignSetting(){
    let data="user_id="+this.currentCustomer.id+"&user_type="+this.currentCustomer.user_type;
    this.gs.callAPI(globalConstants.API_SKIP_FOREVER,data).subscribe((skipTicket:any)=>{
        if(skipTicket.result=="1"){
            if(typeof skipTicket.data.skip_forever !='undefined' && skipTicket.data.skip_forever=='1'){
                this.skipTicketAssign=false;
            }else{
                this.skipTicketAssign=true;
            }
        }else{
            this.skipTicketAssign=true;
        }
    })
  }

  techAssignment(calEvent){
    let startTime = null;
    let endTime = null;
    let evtType = 2;
    let requestId = this.param.dialogMetaData.req_id;
    let requested_delivery_date =  this.param.dialogMetaData.requested_delivery_date;
    let dialogRef = this.dialog.open(AssignemployeeComponent, {
      panelClass: ['quickapptdialog'],
      width: "100%",
      data: {
        requestId:requestId,
        startTime:startTime,
        endTime:endTime,
        eventType:evtType,
        fromSRPopup:false,
        requested_delivery_date:requested_delivery_date
      }
    });
  }

  isProfilePic(src) {
    if (src == null || src == '') {
      return false;
    } else {
      return true;
    }
  }
  isProfilePlaceholder(src:any) {
    if (src == null || src == '') {
      return true;
    } else {
      return false;
    }
  }
  formatDate(data:any){
    return this.gs.getFullFormateDate(data,'G','H');
  }
  getRoles(id){
    return (typeof this.roles[id] != 'undefined' ? this.roles[id] :'');
   }

  getBlankToHypen(data:any){
    let obj=data;
    for(let i in obj){
      if(i=='middle_name' || i=='first_name' || i=='last_name' ){
        obj[i]=obj[i];
      }else if(i=='tax_exempted'){
        obj[i]=(obj[i]=='0' ? 'No':'Yes');
      }else if(typeof obj[i]=='string' && obj[i].length==0){
        obj[i]='-';
      }
    }
    return obj;
  }

  
  // get default service request label
  getDefaultServiceRequestLabel(){
    let preselectTag = this.preSelectLabel;
    this.gs.callAPI(globalConstants.API_GET_SERVICE_REQUEST_LABELS, "request_id="+this.serviceRequestId+"&user_id="+this.gs.getCurrentUser()["id"]+"&user_type=User").subscribe(function(response:any){
      if(response.result == "1"){
        let labels = response.data;
        if(Array.isArray(labels) && labels.length>0){
          console.log(labels);
          labels.forEach((r,v)=>{
              preselectTag.push(r.label_id);
          })
        }
      }
      })
    }

  selectedLabel(e){
    let label_id : number = e;
    this.gs.callAPI(globalConstants.API_UPDATE_SERVICE_REQUEST_LABELS,"request_id="+this.serviceRequestId+"&user_id="+this.gs.getCurrentUser()["id"]+"&label_id="+label_id+ "&user_type=User").subscribe(function(response:any){
      console.log(response.result);
      if(response.result == "1"){
      }
    })
  }

  removeLabel(e){
    this.preSelectLabel = [];
    let label_id : number = e;
    this.gs.callAPI(globalConstants.API_DELETE_SERVICE_REQUEST_LABEL,"request_id="+this.serviceRequestId+"&user_id="+this.gs.getCurrentUser()["id"]+"&label_id="+label_id+ "&user_type=User").subscribe(function(response:any){
      console.log(response.result);
      if(response.result == "1"){
        if(response.count == "0"){
          this.preSelectLabel = [];
        }
      }
    })
  }
  
   // Add By Tafzeel //
   getAllLabels(){
    let content = "user_id="+this.gs.getCurrentUser()["id"];
    this.gs.callAPI(globalConstants.API_GET_SERVICE_REQUEST_LABELS_MASTER, content).subscribe((res:any)=> {
      if(res.result == 1){
        this.allLabels = res.data;
      }
    },(error:any)=>{});
  }

  manageLabels(){
    let dialogRef = this.dialog.open(AddLabelDialogComponent, {
        panelClass: 'partstech-order-dialog',
        width: '800px',
        data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
        this.getAllLabels();
    });
}

  getVehicleDetail() {
    let carOpt = "&vehicle_id=" + this.vehicle_id + "&vehicle_info=1&customer_id=" + this.customer_id;
    // console.log(carOpt);
    this.gs.callAPI(globalConstants.API_GET_CUSTOMER_VEHICLES_URL, carOpt).subscribe((res:any) => {
      if (res.result == 1) {
        // console.log(res.data);
        this.carDetail = res.data;
        if (this.carDetail.production_date != "0000-00-00 00:00:00" && this.carDetail.production_date != "" && this.carDetail.production_date != null) {
          var splitDatesValues = this.carDetail.production_date.split("-");
          this.carDetail.production_date = splitDatesValues[1] + "/" + splitDatesValues[0];
        } else {
          this.carDetail.production_date = '-';
        }
        if (this.carDetail.vehicle_label_pic) {
          this.labelPhoto = this.carDetail.vehicle_label_pic
        }
      }
    }, (error) => { });
  }

  getStatus() {
    return this.param.dialogMetaData.is_appointment== '1' && (this.param.dialogMetaData.request_status=='1' || this.param.dialogMetaData.request_status=='2') ? 'Appt.': this.statuses[this.param.dialogMetaData.request_status] ;
  }

  /* STATUS COLOR */
  getStatusColor() {      
    let status:any = this.param.dialogMetaData.is_appointment== '1' && (this.param.dialogMetaData.request_status=='1' || this.param.dialogMetaData.request_status=='2') ? 0: this.param.dialogMetaData.request_status ;
    if(status != -1){
        var statusArr = ['customer-appt', 'warning-cell', 'info-cell', 'awaited-cell', "",  'success-cell', 'success-completed', 'success-cell', 'success-paid', "", "", "", 'work-progress-cell'];
        return statusArr[status];
    }
    else{
        return 'deleted-cell';
    }
  }

   getFieldVal(f){
    if(f.type=='textbox'){
        return (f.value ? f.value : '-')
    }else if(f.type=='checkbox'){
        return (f.value ? 'Yes' : 'No')
    }else{
        let d=(f.field_option ? f.field_option:'')
        return (d && d.value ? d.value: '-')
    }
}
viewAssignee(id){
  if(this.isEmployee==false){
      return false;
  }
  let dialogRef = this.dialog.open(TechassignmentComponent, {
      panelClass: ['car-dialog-form'],
      width: "900px",
      data: {request_id:id,techStatus:true}
  });
  dialogRef.afterClosed().subscribe((r: any) => {
    
  });
}

  showAllstatus() {
    if(this.isEmployee==false){
      return false;
    }
     let dialogRef = this.dialog.open(TechassignmentComponent, {
       panelClass: ['car-dialog-form'],
       width: "700px",
       data: {request_id:this.serviceRequestId,techStatus:true}
     });
   }

   carEdit() {
    if(this.carDetail.is_walkin_customer_vehicle == '1'){
      this.gs.handleWalkinProfileEditDelete(this.carDetail.is_walkin_customer_vehicle);
      return false;
    }
    let dialogRef = this.dialog.open(EditpopupComponent, {
      panelClass: 'car-dialog-form',
      width: '1000px',
      data: { customer_id: this.customer_id, car_id: this.vehicle_id }
    });
    dialogRef.afterClosed().subscribe((r: any) => {
      if(r){
        this.dialogRef.close();
      }else{
        this.getVehicleDetail()
      }
    });
  }

  customerEdit(){
    if(this.customerDetail.is_walkin_customer == '1'){
      this.gs.handleWalkinProfileEditDelete(this.customerDetail.is_walkin_customer);
      return false;
    }
    let dialogRef = this.dialog.open(CustomerEditModalComponent, {
      panelClass: 'car-dialog-form',
      width: '1000px',
      data: { customer_id: this.customer_id }
    });
    dialogRef.afterClosed().subscribe((r: any) => {
      this.getCustomerDeatils();
    });
  }

  SrUpdate(event ){
    let srData = this.param;
    let dialogRef = this.dialog.open(ServiceFieldUpdateComponent, {
      panelClass: 'car-dialog-form',
      width: '700px',
      data: {form_event: this.isAppointment, event_type: event, sr_data:srData},
    });
    dialogRef.afterClosed().subscribe((r: any) => {      
      this.refresh();
    });
    dialogRef.afterClosed().subscribe((r: any) => {
      //this.getLog();
    });
  }

  ContactEdit(){
    let dialogRef = this.dialog.open(UsercontactsComponent, {
        panelClass: 'car-dialog-form',
        width: "800px",
        data: {user_id:this.gs.getCurrentUser()["id"],user_type:this.gs.getCurrentUser()["user_type"],customer_id:this.customer_id}
      });
      dialogRef.afterClosed().subscribe(result => {
        this.getContactDetails();
      });
  }

   NavigateTo(){
    let srId = this.param.dialogMetaData.req_id;
    if (this.isReportGenerate && this.is_assign==0) {
      window.open('shops/view-service-request/'+srId, '_blank');
    }else{
      window.open('shops/view-service-request/'+srId, '_blank');
      this.closeDialog();
    }

    
   }

  closeDialog() {
    this.dialogRef.close();
  }

  isAllowed(module,action){
    return this.gs.getAcl(module,action);
  }

  refresh(){
    let contant = "user_id="+this.user.id+"&user_type="+this.user.user_type+"&request_id="+ this.param.dialogMetaData.request_id+"&event_type="+this.param.dialogMetaData.event_type+"&usr_sr_id="+this.param.dialogMetaData.usr_sr_id;
    this.gs.callAPI(globalConstants.API_GET_SERVICE_REQUEST,contant).subscribe((res:any) => {
      if (res.result == 1) {        
       this.param.dialogMetaData.service_request_title = res.data.service_request_title;
       this.param.dialogMetaData.est_hours = res.data.est_hours;
       if(res.data.requested_delivery_date){
        this.param.dialogMetaData.requested_delivery_date = this.datePipe.transform(res.data.requested_delivery_date, 'MM/dd/yyyy hh:mm a');
       }

       if(res.data.schedule_service_date){
        this.param.dialogMetaData.schedule_service_date = this.datePipe.transform(res.data.schedule_service_date, 'MM/dd/yyyy hh:mm a');
       }

       if(res.data.start_date){
        this.param.dialogMetaData.start_date = this.datePipe.transform(res.data.start_date, 'MM/dd/yyyy hh:mm a');
       }

       if(res.data.end_date){
        this.param.dialogMetaData.end_date = this.datePipe.transform(res.data.end_date, 'MM/dd/yyyy hh:mm a');
       }       
      }
    })
  }


  adjustTiming() {
    let dialogRef = this.dialog.open(AdjustTechScheduleComponent, {
      panelClass: ['car-dialog-form'],
      width: "650px",
      data: { request_id: this.param.dialogMetaData.request_id,data:this.param.dialogMetaData,user_sr_id:this.param.dialogMetaData.usr_sr_id,emp_id:this.param.dialogMetaData.emp_id,is_edit:true}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof dialogRef["adjustTime"] != 'undefined' && dialogRef["adjustTime"] == true) {
        this.refresh();
      }
    });
  }

  
     /* image rotation dialog  */
     imageToolDialog(current,i,type,imgaar): void {
      // let dialogRef = this.dialog.open(ImagetooldialogComponent, {
      //     panelClass: ['imageToolDialog'],
      //     width: "100%",
      //     data: {event: current,index:i,imgType:type,imgArray:imgaar,customerId:this.currentCustomer.id,carId:this.idFromUrl}
      // });

      // dialogRef.afterClosed().subscribe(result => {
      //     console.log('The dialog was closed');
      // });
  }


     /*******video dialog******/
     videoShow(val): void {
      // let dialogRef = this.dialog.open(VideoShowComponent, {
      //     panelClass: 'car-dialog-form',
      //     width: "600px",
      //     data: {name: val, url: globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_VIDEO}
      // });

      // dialogRef.afterClosed().subscribe(result => {
      //     console.log('The dialog was closed');
      // });
  }
}
