<ng-container *ngTemplateOutlet="languageRules;context:{'rule':rule}"></ng-container>
<ng-template #rules let-rule="rule">
  <div class="row" *ngIf="!rule.removed">
    <div class="form-group col-md-4 col-sm-6">
      <label>Type</label>
    </div>
    <ng-container *ngIf="rule.type == discountConditionRule.value">
      <div class="form-group col-md-4 col-sm-12">
        <mat-select class="form-control input-lg custom-form-input" [(value)]="rule.type" placeholder="Condition" (change)="conditionChange(rule)">
          <mat-option *ngFor="let type of conditionTypes" [value]="type.value">{{type.name}}</mat-option>
        </mat-select>
      </div>
      <div class="form-group col-md-2 col-sm-12">
        <button type="button" mat-raised-button class="car-btn text-uppercase btn-sm font-bold fontcolor-white small" color="accent" (click)="addCondition(rule)"> 
          <i class="fa fa-fw fa-plus"></i>
        </button>
      </div>
      <div class="form-group col-md-2 col-sm-12">
        <button type="button" mat-raised-button *ngIf="!rule.root" class="car-btn text-uppercase btn-sm font-bold fontcolor-white small" color="accent" (click)="removeCondition(rule)"> 
          <i class="fa fa-fw fa-times"></i>
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="rule.type !== discountConditionRule.value">
      <div class="form-group col-md-6 col-sm-12">
        <mat-select class="form-control input-lg custom-form-input" [(value)]="rule.type" placeholder="Condition" (change)="conditionChange(rule)">
          <mat-option *ngFor="let type of conditionTypes" [value]="type.value">{{type.name}}</mat-option>
        </mat-select>
      </div>
      <div class="form-group col-md-2 col-sm-12">
        <button type="button" mat-raised-button *ngIf="!rule.root" class="car-btn text-uppercase btn-sm font-bold fontcolor-white small" color="accent" (click)="removeCondition(rule)"> 
          <i class="fa fa-fw fa-times"></i>
        </button>
      </div>
    </ng-container>
  </div>
  <div class="row" *ngIf="(rule.type == discountConditionRule.value) && (!rule.removed)">
    <div class="form-group col-md-4 col-sm-6">
      <label>Aggregator</label>
    </div>
    <div class="form-group col-md-8 col-sm-12">
      <mat-select class="form-control input-lg custom-form-input" [(value)]="rule.aggregator" placeholder="Aggregator">
        <mat-option *ngFor="let aggregator of aggregators" [value]="aggregator.id">{{aggregator.name}}</mat-option>
      </mat-select>
    </div>
  </div>
  <div class="row" *ngIf="(rule.type !== discountConditionRule.value) && (!rule.removed)">
    <div class="form-group col-md-4 col-sm-6">
      <label>Operator</label>
    </div>
    <div class="form-group col-md-8 col-sm-12">
      <mat-select class="form-control input-lg custom-form-input" [(value)]="rule.operator" placeholder="Operator">
        <mat-option *ngFor="let operator of operators" [value]="operator.id">{{operator.name}}</mat-option>
      </mat-select>
    </div>
  </div>
  <div class="row" *ngIf="(rule.type !== discountConditionRule.value) && (!rule.removed)">
    <div class="form-group col-md-4 col-sm-6">
      <label>Value</label>
    </div>
    <div class="form-group col-md-8 col-sm-12">
      <mat-form-field class="car-input btn-block" floatPlaceholder="never">
        <input #ruleValue="ngModel" matInput placeholder="Value" title="Value" [(ngModel)]="rule.value" [ngModelOptions]="{standalone: true}" class="input-lg custom-form-input" required (keydown)="disableKey($event);" (keypress)="onlyDecimalNumberKey($event)" />
        <mat-error *ngIf="(rule.value == '') && (ruleValue.dirty || ruleValue.touched)">
          This field cannot be empty
        </mat-error>
        <!-- <mat-error *ngIf="shopAddDiscount.controls.type_value.invalid  && shopAddDiscount.value.type_value!='' && isNotNumber(shopAddDiscount.value.type_value)">
          Type value is required (must be a number with 2 decimal places).
        </mat-error>
        <mat-error *ngIf="shopAddDiscount.controls.type_value.invalid  && shopAddDiscount.value.type_value!='' && shopAddDiscount.value.type_value < 1">
          Type value is should be greater than 0.
        </mat-error>
        <mat-error *ngIf="shopAddDiscount.controls.type_value.invalid  && shopAddDiscount.value.type_value!='' && shopAddDiscount.value.type_value > 100 && discount_type == 2">
          Percentage Value should not exceed 100.
        </mat-error> -->
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="rule.conditions && rule.conditions.length > 0" class="child-conditions">
    <ng-template ngFor let-condition [ngForOf]="rule.conditions">
      <ng-template [ngTemplateOutlet]="rules" [ngTemplateOutletContext]="{rule:condition}"></ng-template>
    </ng-template>
  </div>
</ng-template>

<ng-template #languageRules let-rule="rule">
  <div class="discount-rules-container">
    <div class="row if-row" *ngIf="(rule.type === discountConditionRule.value) && (!rule.removed)">
      <div class="form-group col-md-12 col-sm-12">
        <label>
          If 
          <mat-select class="all-any form-control input-lg custom-form-input" [(value)]="rule.aggregator" placeholder="Aggregator" (click)="stopClick($event)">
            <mat-option *ngFor="let aggregator of aggregators" [value]="aggregator.id">{{aggregator.name}}</mat-option>
          </mat-select>
          of these conditions are <b>TRUE</b> : 
          <span> 
            <button type="button" *ngIf="!rule.root" class="p-0 op-btn btn btn-xs text-red" (click)="removeCondition(rule)" title="Remove rule"> 
              <i class="fa fa-fw fa-trash fa-lg"></i>
            </button>
          </span>
        </label>
      </div>
    </div>
    <div class="row" *ngIf="(rule.type !== discountConditionRule.value) && (!rule.removed)">
      <ng-container>
        <div class="form-group col-md-12 col-sm-12">
          <mat-select class="clause form-control input-lg custom-form-input" [(value)]="rule.type" placeholder="Condition" (change)="conditionChange(rule)">
            <mat-option *ngFor="let type of conditionTypes" [value]="type.value">{{type.name}}</mat-option>
          </mat-select>
          
          <mat-select class="operator form-control input-lg custom-form-input" [(value)]="rule.operator" placeholder="Operator">
            <mat-option *ngFor="let operator of operators" [value]="operator.id">{{operator.name}}</mat-option>
          </mat-select>
          
          <mat-form-field [ngClass]="{'clause-value car-input btn-block': true}" floatPlaceholder="never" appearance="fill">
            <input #ruleValue="ngModel" matInput placeholder="Value" title="Value" [(ngModel)]="rule.value" [ngModelOptions]="{standalone: true}" (input)="onValueUpdate(rule)" [ngClass]="{'input-sm custom-form-input':true}" required (keydown)="disableKey($event);" (keypress)="onlyDecimalNumberKey($event)" [errorStateMatcher]="matcher" />
            <!-- <input #ruleValue="ngModel" matInput placeholder="Value" title="Value" [(ngModel)]="rule.value" [ngModelOptions]="{standalone: true}" (input)="onValueUpdate(rule)" [ngClass]="{'input-sm custom-form-input':true}" required (keydown)="disableKey($event);" (keypress)="onlyDecimalNumberKey($event)" /> -->
            <mat-error>
              This field cannot be empty
            </mat-error>
          </mat-form-field>
          <button type="button" *ngIf="!rule.root" class="op-btn btn btn-xs text-red delete-btn" (click)="removeCondition(rule)" title="Remove rule"> 
            <i class="fa fa-fw fa-trash fa-lg"></i>
          </button>
        </div>
      </ng-container>
    </div>
    <div *ngIf="rule.conditions && rule.conditions.length > 0" class="child-conditions">
      <ng-template ngFor let-condition [ngForOf]="rule.conditions">
        <ng-template [ngTemplateOutlet]="languageRules" [ngTemplateOutletContext]="{rule:condition}"></ng-template>
      </ng-template>
      <!-- <span class="op-btns" *ngIf="(rule.type === discountConditionRule.value) && (!rule.removed)">
        <button type="button" class="p-0 op-btn btn btn-xs text-success" (click)="addCondition(rule)" title="Add rule"> 
          <i class="fa fa-fw fa-plus fa-lg"></i>
        </button>
      </span> -->
    </div>
    <span class="op-btns" *ngIf="(rule.type === discountConditionRule.value) && (!rule.removed)">
      <button type="button" class="p-0 op-btn btn btn-xs text-success" (click)="addCondition(rule)" title="Add rule"> 
        <i class="fa fa-fw fa-plus fa-lg"></i>
      </button>
    </span>
  </div>
</ng-template>