import { Component,Inject, ElementRef,OnInit,AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { globalConstants } from '../../environments/globalConstants';
import { GlobalService } from '../../environments/global.service';
import { globalFunctions } from '../../environments/globalFunctions';
import {Http, Headers,RequestOptions, Response} from '@angular/http';

@Component({
  selector: 'app-partstech',
  templateUrl: './partstech.component.html',
  styleUrls: ['./partstech.component.scss']
})
export class PartstechComponent {
public request_id;
public user_id;
public userType;
public availability;
public dataSources=[];
public showOrder=false;
public orderNow=false;
public selectedItems=[];
public orderPlaced=false;
public viewCartIfany=false;
public cartData:any=[];
public itemDiscarded=false;
public enable=false;
public enableNexpart=false;
partShop:string = globalConstants.PART_SHOPS.PARTSTECH;
gc:any = globalConstants;
nexpartLogoURL:string = globalConstants.NEXPART_OFFICIAL_LOGO;
  constructor(private el:ElementRef,@Inject(MAT_DIALOG_DATA) public data: any,private http: Http, public dialogRef: MatDialogRef<PartstechComponent>,private globalService: GlobalService) {
    this.partShop = data.partShop || globalConstants.PART_SHOPS.PARTSTECH;
    this.dataSources=data.dataSources;
    this.showOrder=(typeof data.showOrder =='undefined'?false:true);
    this.request_id=data.request_id;
    this.user_id=data.user_id;
    this.userType=data.userType;
    this.orderNow=(typeof data.orderNow=='undefined'?false:data.orderNow);
    this.enable=this.globalService.getAcl('partstech_order','enable');
    this.enableNexpart=this.globalService.getAcl('nexpart_order','enable');
    this.globalService.diaLogObj(this.dialogRef);
  }
  ngOnInit() {
  }
  checkAvailability(){
    let content = "user_id=" + this.user_id + "&user_type=" + this.userType + "&request_id=" + this.request_id;
    var conf = "Are you sure to continue?";
    this.globalService.confirmDialog('checkOrder', conf).subscribe((r) => {
    let res: any = r;
    if (res == 'checkOrder') {
    this.globalService.callAPI(globalConstants.API_PARTS_AVAILABILITY_URL, content).subscribe((response:any) => {

      if(response.result=='1'){
        this.availability=response.data;
        this.placeOrder();
        
      }else{
        this.globalService.snackbar("error", response.message);
      }
    });
  }
})
  }
  placeOrder(){
    let content = "user_id=" + this.user_id + "&user_type=" + this.userType + "&request_id=" + this.request_id;
    this.globalService.callAPI(globalConstants.API_PARTS_PLACE_ORDER_URL, content).subscribe((response:any) => {
      if(response.result=='1'){
        this.dataSources=[];
        this.orderPlaced=true;
        this.availability=response.data;
        this.dialogRef['placeOrder']=true;
        this.globalService.snackbar("success", response.message);
        this.globalService.reload();
      }else{
        this.globalService.snackbar("error", response.message);
      }
    });
  }
  
  discardAvailability(){
    let content = "user_id=" + this.user_id + "&user_type=" + this.userType + "&request_id=" + this.request_id;
    var conf = "Are you sure to continue?";
    this.globalService.confirmDialog('discard', conf).subscribe((r) => {
    let res: any = r;
    if (res == 'discard') {
     this.globalService.callAPI(globalConstants.API_PARTSTECH_DISCARD_CART_URL, content).subscribe((response:any) => {
      if(response.result=='1'){
        this.dataSources=[];
        this.dialogRef['discard']=true;
        this.itemDiscarded=true;
      }else{
        this.globalService.snackbar("error", response.message);
      }
     });
    }else{
      return false;
    }
    });
  }

  cancelOrder(order,index){
    let content = "user_id=" + this.user_id + "&user_type=" + this.userType + "&request_id=" + this.request_id+"&order_number="+order;
    var conf = "Are you sure to continue?";
    this.globalService.confirmDialog('cancelOrder', conf).subscribe((r) => {
    let res: any = r;
    if (res == 'cancelOrder') {
    this.globalService.callAPI(globalConstants.API_PARTSTECH_CANCEL_ORDER_URL, content).subscribe((response:any) => {
      if(response.result=='1'){
        this.dataSources.splice(index,1);
        this.dialogRef['cancelOrder']=true;
        this.globalService.snackbar("success", response.message);
        this.globalService.reload();
      }else{
        this.globalService.snackbar("error", response.message);
      }
    });
  }else{
    return false;
  }
  });
  }
  selectedItem(event,item){
    if(event.checked){
      this.selectedItems.push(item);
    }else{
      let i=this.selectedItems.indexOf(item);
      this.selectedItems.splice(i,1);
    }
  }
  placeorderNow(){
    if(this.selectedItems.length == 0){
      this.globalService.snackbar("error", 'Please select parts');
      return false;
    }
    let content={user_id:this.user_id,user_type:this.userType,request_id:this.request_id,items:this.selectedItems};
    var conf = "Are you sure to continue?";
    this.globalService.confirmDialog('yes', conf).subscribe((r) => {
    let res: any = r;
    if (res == 'yes') {
    this.globalService.callAPI(globalConstants.API_PARTSTECH_ORDER_NOW_URL, content).subscribe((response:any) => {
      if(response.result=='1'){
        this.dataSources=[];
        this.dialogRef['orderPlaced']=true;
        this.orderPlaced=true;
        this.globalService.snackbar("success", response.message);
        this.globalService.reload();
      }else{
        this.globalService.snackbar("error", response.message);
      }
    });
  }
})
  }
  getOrders(){
    let content={user_id:this.user_id,user_type:this.userType,request_id:this.request_id};
    this.cartData=this.dataSources;
    this.globalService.callAPI(globalConstants.API_GET_PARTSTECH_ORDER_URL, content).subscribe((response:any) => {
      if(response.result=='1'){
        this.dataSources=response.data;

      }else{
        this.dataSources=[];
      }
      this.showOrder=true;
      this.orderNow=false;
      this.viewCartIfany=true;
      this.orderPlaced=false;
    });
  }
  showCarts(){
    this.dataSources=this.cartData;
    this.viewCartIfany=false;
    this.showOrder=false;
    this.orderNow=true;
  }
  isAllowed(action){
    return this.globalService.getAcl('partstech_order',action);
  }
  
  getFormattedDate(date:string){
    return this.globalService.getFullFormateDate(date,'G','B')
  }
}
