import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpParams
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { globalConstants } from '../../../environments/globalConstants'

@Injectable()
export class JwtInspectorInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    var inURL = window.location.href.split('/');
    let jwtConfig: any = '';
    if (typeof inURL[3] != 'undefined' && (inURL[3] == 'shops' ||  inURL[3] == '') ) {
      jwtConfig = localStorage.getItem(globalConstants.SHOP_JWT_KEY);
    }

    if (jwtConfig) {
      jwtConfig = JSON.parse(jwtConfig);
      var newReq = request.clone({
        params: (request.params ? request.params : new HttpParams())
          .set('jwt', jwtConfig.jwt) /*.... add new params here .....*/
          .set('token', jwtConfig.token)
          .set('type', jwtConfig.type)
          .set('module', jwtConfig.module)
      });

    } else {
      var newReq = request.clone({
        params: (request.params ? request.params : new HttpParams())
      });
    }
    return next.handle(newReq);
  }
}
