import {Component, OnInit, ViewChild,OnDestroy} from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd, Params} from '@angular/router';
import {Http, Headers, RequestOptions, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../../environments/globalFunctions"
import {globalConstants} from "../../../../environments/globalConstants"
import {GlobalService} from "../../../../environments/global.service"
import {lang} from "../../../../environments/lang-en"
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';
// import {AppComponent} from '../../../app.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {VideoShowComponent} from '../../../customer/my-vehicles/video-show/video-show.component';
import {IDatePickerConfig} from 'ng2-date-picker';
import {DatePipe} from '@angular/common';
// import {NgProgress} from 'ngx-progressbar';
import {NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
export class AppDateAdapter extends NativeDateAdapter {
   parse(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

   format(date: Date, displayFormat: Object): string {
       if (displayFormat == "input") {
           let day = date.getDate();
           let month = date.getMonth() + 1;
           let year = date.getFullYear();
           return this._to2digit(month)+ '/' + this._to2digit(day)  + '/' + year;
       } else {
           return date.toDateString();
       }
   }

   private _to2digit(n: number) {
       return ('00' + n).slice(-2);
   }
}

export const APP_DATE_FORMATS =
{
   parse: {
       dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
   },
   display: {
       // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
       dateInput: 'input',
       monthYearLabel: { month: 'short', year: 'numeric', day: 'numeric' },
       dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
       monthYearA11yLabel: {year: 'numeric', month: 'long'},
   }
}


import { ImagetooldialogComponent } from '../../../imagetooldialog/imagetooldialog.component';
import { ExtrafieldsComponent } from '../extrafields/extrafields.component';
import { ExtrafieldListComponent } from '../extrafield-list/extrafield-list.component';
@Component({
    selector: 'app-shopeditcar',
    templateUrl: './shopeditcar.component.html',
    styleUrls: ['./shopeditcar.component.scss'],
    providers: [
        DatePipe,
       {
           provide: DateAdapter, useClass: AppDateAdapter
       },
       {
           provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
       }
   ]
})
export class ShopeditcarComponent implements OnInit,OnDestroy {
    public car: FormGroup;
    public currentCustomer;
    public carCustomerId;
    public vehicleId;
    public idFromUrl;
    public vehicleDetails;
    public carMakeList;
    public carModels;;
    public years;
    public states;
    public selectedMake;
    public selectedYear;
    public selectedModel;
    public addNewColor = false;
    public phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];//['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    //public dateMask = [/[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, '/', /[1-9]/, /[1-9]/, '/', /[1-9]/, /[1-9]/];
    public dateMask = [/[1-9]/, /[1-9]/, '/', /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/];
    public userDetails;
    public userCountryID;
    public carState;
    public imgPath = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_PICTURES + globalConstants.IMG_THUMB;
    public insurance = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_INSURANCE + globalConstants.IMG_THUMB;
    public registeredPicture = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_REGISTRATION_PIC + globalConstants.IMG_THUMB;
    public vinPicture = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_VIN_PIC + globalConstants.IMG_THUMB;
    public vehVideo = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_VIDEO;
    public VEHICLE_LABEL_PIC= globalConstants.S3_BUCKET_URL +globalConstants.VEHICLE_LABEL_PICTURES + globalConstants.IMG_THUMB
    public vPic = [];
    public vehicleInsuredPic = [];
    public vehicleRegisteredPic = [];
    public vehicleVinPic = [];
    public vinPhoto = '';
    public loaderHide = [];
    public vinPhotoSet = '';
    public vinPhotoSetLoader = false;
    public videoList = [];
    public submitted: boolean = false;
    public newVIN = ""
    public vinError: boolean = false;
    public sendRequest = false;
    
    public EnterNewMakeButton = true;
    public EnterNewYearButton = true;
    public EnterNewModelButton = true;
    public UseExistingMakeButton = false;
    public UseExistingYearButton = false;
    public UseExistingModelButton = false;
    public EnterNewMake = false;
    public EnterNewYear = false;
    public EnterNewModel = false;
    public UseExisitngMake = true;
    public UseExistingYear = true;
    public UseExisitngModel = true;
    public showSubscriptionMessage = false;
    public disabledYear;
    public disabledModel;
    
    public carCountryName;
    public addVinConfirmation: any;
    public countries: any = [];
    public customerCountryID;
    datePickerConfig: IDatePickerConfig = {
        format: "MM/YYYY",
        closeOnSelect:true
    };
    public uspsVehicle;
    public engineSize;
    public FORMAT_SUPPORTED_FOR_IMAGE = globalConstants.FORMAT_SUPPORTED_FOR_IMAGE;
    public FORMAT_SUPPORTED_FOR_VIDEO = globalConstants.FORMAT_SUPPORTED_FOR_VIDEO;
    public MAX_ALLOWED_IMAGE_SIZE = globalConstants.MAX_ALLOWED_IMAGE_SIZE;
    public MAX_ALLOWED_IMAGE_DISPLAYED_TEXT = globalConstants.MAX_ALLOWED_IMAGE_DISPLAYED_TEXT;
    public MAX_ALLOWED_VIDEO_SIZE = globalConstants.MAX_ALLOWED_VIDEO_SIZE;
    public MAX_ALLOWED_VIDEO_DISPLAYED_TEXT = globalConstants.MAX_ALLOWED_VIDEO_DISPLAYED_TEXT;
    
    public vehicleColor;
    public colorsArray=[''];
    public carColors = [
        {
            id : 'White',
            text: "White/White Pearl",
        },
        {
            id : 'Black',
            text: "Black",
        },
        {
            id : 'Silver',
            text: "Silver",
        },
        {
            id : 'Red',
            text: "Red",
        },
        {
            id : 'Gray',
            text: "Gray",
        },
        {
            id : 'Blue',
            text: "Blue",
        },
        {
            id : 'Brown',
            text: "Beige/Brown",
        },
        {
            id : 'Green',
            text: "Green",
        },
        {
            id : 'Yellow',
            text: "Yellow/Gold",
        }
    ];
    public disableYears;
    public disableModels;
    public yearsValue;
    public makesValue;
    public modelsValue;
    public validCar=false;
    public wheelLock=[]
    public antWheellocklocation=true
    public customFieldList=[]
    public preCustomFields=[]
    public otherLabelsTemp=[]
    public subscriptionDataForAPI: Subscription = new Subscription();

    constructor(private dateAdapter: DateAdapter<Date>, private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, public titleService: Title, private http: Http, private router: Router, public globalService: GlobalService, public dialog: MatDialog, private datePipe: DatePipe
        // public progressbar: NgProgress
        ) {
        //this.titleService.setTitle(lang.TITLE_CUSTOMER_VIEW_CAR);
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_COUNTRIES_LIST, "opr=1").subscribe((data:any) => {
            this.countries = data.data
        })
        this.disabledModel = true;
        this.disabledYear = true;
        this.globalService.setMetaData("SHOPS","EDIT_CAR_DETAIL")
    }
    setTouchViewValue() {
        // return this.app.touchViewState;
        return false;
    }

    ngOnInit() {
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
        this.carCustomerId = localStorage.getItem("carDetailsCustomerID");
        this.vehicleId = localStorage.getItem("carDetailsVehicleID");
        this.carCountryName = localStorage.getItem("carCustomerCountryName");
        this.wheelLock=globalFunctions.wheelLock()
        this.initializeFormBuilder();
        
        this.setParams();
 
        if(this.carCountryName == "" || this.carCountryName == null || this.carCountryName == undefined) {
              this.car.controls.State.disable();
        }
        
        this.activatedRoute.data.subscribe(data => {
            this.subscriptionDataForAPI =this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_VEHICLES_URL, "customer_id=" + this.carCustomerId + "&vehicle_id=" + this.vehicleId).subscribe((data:any) => {
                    if (data.result == 1) {
                        this.vehicleDetails = data.data;
                        // Registration Date
                        let registrationDateFromDB = this.vehicleDetails.registration_expiration_date;
                        if (registrationDateFromDB != '0000-00-00 00:00:00' && registrationDateFromDB != "") {
                            registrationDateFromDB=this.globalService.getFullFormateDate(registrationDateFromDB,'L','B');
                            this.car.controls.registration_expiration_date_frm.setValue(new Date(registrationDateFromDB));
                            this.car.controls.registration_expiration_date.setValue(this.vehicleDetails.registration_expiration_date);
                        }
                        // Insurance date
                        let insuranceDateFromDB = this.vehicleDetails.insurance_expiration_date;
                        if (insuranceDateFromDB != '0000-00-00 00:00:00' && insuranceDateFromDB != "") {
                            insuranceDateFromDB=this.globalService.getFullFormateDate(insuranceDateFromDB,'L','B');
                            this.car.controls.insurance_expiration_date_frm.setValue(new Date(insuranceDateFromDB));
                            this.car.controls.insurance_expiration_date.setValue(this.vehicleDetails.insurance_expiration_date);
                        }
                        let inspecDateFromDB = this.vehicleDetails.inspection_expiration_date;
                        if (inspecDateFromDB != '0000-00-00 00:00:00' && inspecDateFromDB != "") {
                            inspecDateFromDB=this.globalService.getFullFormateDate(inspecDateFromDB,'L','B');
                            this.car.controls.inspection_expiration_date_frm.setValue(new Date(inspecDateFromDB));
                            this.car.controls.inspection_expiration_date.setValue(this.vehicleDetails.inspection_expiration_date);
                        }

                        this.car.controls.driver.setValue(this.vehicleDetails.driver)
                        this.car.controls.State.setValue(this.vehicleDetails.state)
                        if(this.vehicleDetails.production_date){
                            this.car.controls.production_date.setValue(this.datePipe.transform(this.vehicleDetails.production_date, 'MM/yyyy'));
                        }
                        this.carModels = [];
                        this.years = [];
                        //this.carModels[0] = {"model": this.selectedModel};
                        //this.years[0] = {"year": this.vehicleDetails.year}
                        this.vinPhoto = this.vehicleDetails.vin_photo;
                        this.selectedMake = this.vehicleDetails.make;
                        this.car.value.make = this.vehicleDetails.make;
                        this.car.controls.make.setValue(this.vehicleDetails.make);
                        this.car.controls.sel_make.setValue(this.vehicleDetails.make);
                        //this.car.controls['make'].setValue(this.vehicleDetails.make);
                        this.getYears(this.vehicleDetails.make);
                        this.selectedYear = this.vehicleDetails.year;
                        this.car.controls.year.setValue(this.vehicleDetails.year);
                        this.car.controls.sel_year.setValue(this.vehicleDetails.year);
                        //this.car.value.year = this.vehicleDetails.year;
                        this.getModelList(this.selectedYear);

                        this.selectedModel = this.vehicleDetails.model;
                        this.car.controls.model.setValue(this.vehicleDetails.model);
                        this.car.controls.sel_model.setValue(this.vehicleDetails.model);
                        
                        this.car.controls.color.setValue(this.vehicleDetails.color);
                        this.carState = this.vehicleDetails.state;
                        this.vehicleColor = this.vehicleDetails.color;
                        //this.carState++;
                         
                        //this.carModels[0] = {"model": this.selectedModel};
                        //console.log("year", this.vehicleDetails.state, this.car.value.state);
                        let vpics = [];
                        this.loaderHide['vehiclePic'] = [];
                        if (this.vehicleDetails.vehicle_pic.length) {
                            for (var i in this.vehicleDetails.vehicle_pic) {
                                this.vehicleDetails.vehicle_pic[i].loader = false;
                                this.vehicleDetails.vehicle_pic[i].vehiclePic = '';
                                vpics.push(this.vehicleDetails.vehicle_pic[i]);
                            }
                        }

                        if (this.vehicleDetails.vehicle_insured_pic.length) {
                            for (var i in this.vehicleDetails.vehicle_insured_pic) {
                                this.vehicleDetails.vehicle_insured_pic[i].loader = false;
                                this.vehicleDetails.vehicle_insured_pic[i].vehicleInsured_pic = '';
                                this.vehicleInsuredPic.push(this.vehicleDetails.vehicle_insured_pic[i]);
                            }
                        }
                        if (this.vehicleDetails.vehicle_registered_pic.length) {
                            for (var i in this.vehicleDetails.vehicle_registered_pic) {
                                this.vehicleDetails.vehicle_registered_pic[i].loader = false;
                                this.vehicleDetails.vehicle_registered_pic[i].vehicleRegistered_pic = '';
                                this.vehicleRegisteredPic.push(this.vehicleDetails.vehicle_registered_pic[i]);
                            }
                        }
                        if (this.vehicleDetails.vehicle_video.length) {
                            for (var i in this.vehicleDetails.vehicle_video) {
                                this.vehicleDetails.vehicle_video[i].loader = false;
                                this.vehicleDetails.vehicle_video[i].vehicleVideo = '';
                                this.videoList.push(this.vehicleDetails.vehicle_video[i]);
                            }
                        }
                        if (this.vehicleDetails.vehicle_label_pic.length > 0) {
                            for (var i in this.vehicleDetails.vehicle_label_pic) {
                                this.vehicleDetails.vehicle_label_pic[i].loader = false;
                                this.vehicleDetails.vehicle_label_pic[i].other_labels_temp = '';
                                this.otherLabelsTemp.push(this.vehicleDetails.vehicle_label_pic[i]);
                            }
                        }
                        if (this.vehicleDetails.california_emissions == 1) {
                            this.car.controls.california_emmissions.setValue(1);
                        }
                        this.car.controls.transmission.setValue(this.vehicleDetails.transmission);
                        this.car.controls.brake.setValue(this.vehicleDetails.brake);
                        this.car.controls.driveline.setValue(this.vehicleDetails.driveline);
                        this.car.controls.gvwr.setValue(this.vehicleDetails.gvwr);
                        this.car.controls.wheel_base.setValue(this.vehicleDetails.wheel_base);
                        this.car.controls.tire_size.setValue(this.vehicleDetails.tire_size);
                        this.car.controls.wheel_lock_location.setValue(this.vehicleDetails.wheel_lock_location);
                        this.checkExistingLoclLocation()
                        this.vPic = vpics;
                        this.uspsVehicle = this.vehicleDetails.usps_vehicle;
                        this.customerCountryID = this.vehicleDetails.country_id;
                        this.engineSize = (this.vehicleDetails.sub_model!= 'undefined') ? this.vehicleDetails.sub_model : '';
                        var statesOpr = "country_id=" + this.customerCountryID + "&opr=1";
                        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_STATES_URL, statesOpr).subscribe((data:any) => {
                            this.states = data.data;
                            var slist=[];
                            for(var i in this.states){
                                this.states[i].id=this.states[i].state_name;
                                this.states[i].text=this.states[i].state_name;
                                slist.push(this.states[i]);
                                //this.statesArray[this.states[i].state_id]=(this.states[i].state_name);
                            }
                            this.states=slist;
                        });
                        
                        var colorList=[];
                        for(var i in this.carColors){
                            this.carColors[i].id=this.carColors[i].id;
                            this.carColors[i].text=this.carColors[i].text;
                            colorList.push(this.carColors[i]);
                            this.colorsArray[this.carColors[i].id]=(this.carColors[i].text);
                        }
                        this.carColors=colorList;
                        let custFields=[]
                        if(this.vehicleDetails && typeof this.vehicleDetails.custom_fields!='undefined' && this.vehicleDetails.custom_fields.length){
                            // this.customFieldList=this.vehicleDetails.custom_fields
                            custFields=this.vehicleDetails.custom_fields
                        }
                        this.getCustomField(custFields)

                    }
                    this.checkExistingColor();
                    this.getCarMakes();
                    //this.checkExistingMakeYearModel();
                });
                // setTimeout(() => {
                //     this.checkExistingMakeYearModel();
                // }, 1200);
        });
    }
    
    checkExistingMakeYearModel() {
        this.enterNewMake();
        for (var i = 0; i < this.carMakeList.length; i++) {
            if (this.carMakeList[i].make == this.vehicleDetails.make) {
                this.useExitingMake();
                this.enterNewYear();
                for (var i = 0; i < this.years.length; i++) {
                    if (this.years[i].year == this.vehicleDetails.year) {
                        this.useExitingYear();
                        this.enterNewModel();
                        for (var i = 0; i < this.carModels.length; i++) {
                            var modelStr = this.carModels[i].model;
                            if (this.carModels[i].trim != '') {
                                modelStr = modelStr + ' ' + this.carModels[i].trim;
                            }
                            if (this.carModels[i].body_style != '') {
                                modelStr = modelStr + ' ' + this.carModels[i].body_style;
                            }
                            if (modelStr == this.vehicleDetails.model) {
                                this.useExitingModel();
                                return;
                            }
                        }
                        return;
                    }
                }
            }
        }
    }
    
    checkExistingColor() {
         for (var i = 0; i < this.carColors.length; i++) {
            if (this.carColors[i].id == this.vehicleColor) {
                this.addNewColor = false;
                return;
            } else { 
                this.addNewColor = true;
            }
        }
    }
    
    /* CHANGE MAKE */
    getSelectedOptionMakes(opt){
        this.disabledModel = true;
        this.makesValue = opt;
        this.car.controls.sel_make.setValue(opt);
        this.car.controls.make.setValue(opt);
        this.car.controls.sel_year.setValue('');
        this.car.controls.year.setValue('');
        this.car.controls.production_date.setValue('')
        this.getYears(opt);
        // this.globalService.validateState(this.car, ['make']);
        this.checkIfValid();
    }
    
    /* CHANGE YEARS */
    getSelectedOptionYears(opt){
        this.yearsValue = opt;
        this.car.controls.sel_year.setValue(opt);
        this.car.controls.year.setValue(opt);
        this.car.controls.sel_model.setValue('');
        this.car.controls.model.setValue('');
        this.getModelList(opt);
        let makeDate = "01"+'/'+opt;
        this.car.controls.production_date.setValue(makeDate)
        // this.globalService.validateState(this.car, ['year']);
        this.checkVinValidity();
        this.checkIfValid();
    }
    
    /* CHANGE MODELS */
    getSelectedOptionModels(opt){
        this.modelsValue = opt;
        this.car.controls.sel_model.setValue(opt);
        this.car.controls.model.setValue(opt);
        // this.globalService.validateState(this.car, ['model']);
        this.checkIfValid();
    }
    
    /* STATES LIST IN SEARCH BOX DROP DOWN */
    getSelectedColor(opt) {
         if(opt!="") {
            this.car.controls.select_color.setValue(opt);
            this.car.controls.color.setValue(opt);
            // this.globalService.validateState(this.car, ['color']);
            this.checkIfValid();
        }
    }
    
    /* STATES LIST IN SEARCH BOX DROP DOWN */
    getSelectedOptionState(opt) {
        this.car.controls.State.setValue(opt);
        // this.globalService.validateState(this.car, ['State']);
        this.checkIfValid();
    }
    
    stateValidation() {
        // this.globalService.validateState(this.car, ['State', 'color']);
        this.checkIfValid();
    }
    
    autoFillProductionDate() {
        if (this.car.value.year != "") {
            let makeDate = "01" + '/' + this.car.value.year;
            this.car.controls.production_date.setValue(makeDate)
        }
    }

    onInput(obj) {
        obj.targetElement.value = this.dateAdapter.toIso8601(new Date(obj.target.value));
    }

    onlyNumericKey(event) {
        return globalFunctions.onlyDecimalNumberKey(event);
    }

    getCarMakes() {
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_CAR_MAKES, "opr=1").subscribe((data:any) => {
          if(data.result=="1"){
            this.carMakeList = data.data;
            /* FOR SEARCH BOX DROPDOWN */
            var makelist=[];
            for(var i in this.carMakeList){
                this.carMakeList[i].id=this.carMakeList[i].make;
                this.carMakeList[i].text=this.carMakeList[i].make;
                makelist.push(this.carMakeList[i]);
                //this.statesArray[this.states[i].state_id]=(this.states[i].state_name);
            }
            this.carMakeList=makelist;
          }
          this.checkExistingMakeYearModel();
        });
    }

    changeDateFormat(dateValue) {
        return new Date(dateValue).getFullYear() + '-' + (new Date(dateValue).getMonth() + 1) + '-' + new Date(dateValue).getDate();
    }

    editCar() {
        this.stateValidation();

        if (this.car.valid) {
            this.car.value.vehicle_id = this.vehicleId;
            if (this.car.value.registration_expiration_date_frm != '') {
                let regDate= this.changeDateFormat(this.car.value.registration_expiration_date_frm);
                this.car.controls.registration_expiration_date.setValue(regDate)
            } else {
                this.car.controls.registration_expiration_date.setValue('')
            }

            if (this.car.value.insurance_expiration_date_frm != '') {
                let insDate = this.changeDateFormat(this.car.value.insurance_expiration_date_frm);
                this.car.controls.insurance_expiration_date.setValue(insDate)
            } else {
                this.car.controls.insurance_expiration_date.setValue('')
            }
            if (this.car.value.inspection_expiration_date_frm != '') {                
                let insDate= this.changeDateFormat(this.car.value.inspection_expiration_date_frm);
                this.car.controls.inspection_expiration_date.setValue(insDate)
            } else {
                this.car.controls.inspection_expiration_date.setValue('')
            }
            let custFields=[];
            if(this.customFieldList.length){
                for(let n in this.customFieldList){
                    let cf=this.customFieldList[n]
                    let nid=(typeof cf.vehicle_profile_field_id!='undefined' ? cf.vehicle_profile_field_id:false);
                    let t:any={id:(nid ? nid : cf.id ) , value:cf.value}
                    custFields.push(t)
                }
                this.car.controls.custom_fields.setValue(JSON.stringify(custFields))
            }

            var content = globalFunctions.stringifyFormData(this.car.value, null)

            if (this.car.value.vin != "" || this.validCar) {
                this.submitCar(content);
            } else {
                this.globalService.confirmDialog(1, 'You have not entered the VIN Number. You will not be able to create a service request without it. Do you still want to continue without entering it?').subscribe((res) => {
                    this.addVinConfirmation = res;
                    if (this.addVinConfirmation == 1) {
                        this.submitCar(content);
                    }
                });
            }
        } else {
            this.submitted = true;
            /*if (this.car.controls.vin.hasError && this.car.value.vin != "") {
                this.globalService.snackbar('error', "Vin number issued after 1980 should be of 16 digits max");
            } else { */
            this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
            //}
        }
    }

    checkIfValid() {
        let x=['sel_make','sel_year', 'sel_model','license_plate','State','color'];
        this.validCar=false;
        let lp=this.globalService.isFleet()
        if(this.EnterNewMake || this.EnterNewYear || this.EnterNewModel){
            this.validCar=true;
        }
        for (const key in this.car.controls) {
            if(x.indexOf(key) != -1 ){
                this.car.get(key).clearValidators();
                this.car.get(key).setErrors(null);
                this.car.get(key).updateValueAndValidity({ onlySelf: false, emitEvent: false});
            }
        }
        if(this.validCar){
            this.car.get('select_color').setValidators(null);
            this.car.get('color').setValidators(null);
            this.car.get('license_plate').setValidators(null);
            this.car.get('State').setValidators(null);
        }else{
            this.car.get('color').setValidators([Validators.required]);
            this.car.get('select_color').setValidators([Validators.required]);
            this.car.get('license_plate').setValidators([Validators.required]);
            this.car.get('State').setValidators([Validators.required]);
        }
        if(this.EnterNewMake){
            this.car.get('sel_make').setValidators(null);            
        }else{
            (!this.validCar) && this.car.get('sel_make').setValidators([Validators.required]);            
        }
        if(this.EnterNewYear){
            this.car.get('sel_year').setValidators(null);
        }else{
            (!this.validCar) && this.car.get('sel_year').setValidators([Validators.required])
        }
        if(this.EnterNewModel){
            this.car.get('sel_model').setValidators(null);
        }else{
            (!this.validCar) && this.car.get('sel_model').setValidators([Validators.required])
        }
        if(lp){
          this.car.get('license_plate').setValidators(null);
        }
        for (const key in this.car.controls) {
            if(x.indexOf(key) != -1){
                this.car.get(key).updateValueAndValidity({ onlySelf: false, emitEvent: false});
            }   
        }
        this.car.controls.is_make.setValue(( this.EnterNewMake || this.validCar ? "0":"1"));
        this.car.controls.is_year.setValue(( this.EnterNewYear || this.validCar ? "0":"1"));
        this.car.controls.is_model.setValue(( this.EnterNewModel || this.validCar ? "0":"1"));
        this.car.controls.is_color.setValue(( this.EnterNewMake || this.validCar ? "0":"1"));
        this.car.controls.is_license.setValue(( this.EnterNewMake || this.validCar ? "0":"1"));
        
        if(this.car.value.production_date == "" || typeof this.car.value.production_date== "undefined"){
          this.car.controls.production_date.setValue("");
        }
        if (this.car.valid) {
            this.submitted = false;
        }
    }

    checkVinValidity() {
        Window["errorObj"] = this.car
        var year_selected = this.car.value.year;
        var vin_number = this.car.value.vin;
        if (vin_number != "") {
            if (year_selected > 1981 && vin_number.length < 17 && !this.EnterNewYear) {
                this.car.controls.vin.setErrors({"YearError": true});
            } else {
                this.car.controls.vin.setErrors(null);
            }
        }
        /*if (year_selected > 1980 && vin_number.length > 16 && !this.EnterNewYear) {
            this.car.controls.vin.setErrors({"YearError": true});
        } else {
            this.car.controls.vin.setErrors(null);
        }*/
    }

    setParams() {
        this.activatedRoute.params.subscribe((params: Params) => {
            this.idFromUrl = params["v_id"];
        });
    }

    /* Home Phone Validity Check */
    checkHomePhoneValidity() {
        Window["errorObj"] = this.car
        let home_phone = this.car.value.driver_phone_number;
        if (home_phone != "") {
            let phoneResult = this.car.value.driver_phone_number.replace(/\D+/g, "").length >= 10 ? false : {minLength: true};

            if (phoneResult == false) {
                this.car.controls.driver_phone_number.setErrors(null);
                this.submitted = false;
            } else {
                this.car.controls.driver_phone_number.setErrors({"phoneError": true});
            }
        } else {
            this.car.controls.driver_phone_number.setErrors(null);
            this.submitted = false;
        }
    }

    initializeFormBuilder() {
        this.car = this.formBuilder.group({
            vin: [''],
            production_date: [''],
            year: [''],
            make: [''],
            model: [''],
            sel_make: [''],
            sel_year: [''],
            sel_model: [''],
            sub_model: [''],
            mileage: [''],
            license_plate: [''],
            color: [''],
            select_color: [''],
            unit_number: [''],
            driver: [''],
            driver_phone_number: [''],
            distance_covered: ['', [Validators.pattern("^[0-9]*$")]],
            vehicle_registered_pic: [''],
            vehicle_insured_pic: [''],
            vehicle_pic: [''],
            vehicle_video: [''],
            registration_expiration_date_frm: [''],
            registration_expiration_date: [''],
            insurance_expiration_date_frm: [''],
            insurance_expiration_date: [''],
            vin_photo: [''],
            State: [''],
            vehicle_id: [this.vehicleId],
            /*user_type: [this.currentCustomer.user_type],
            user_id: [this.currentCustomer.id],*/
            customer_id: [this.carCustomerId],
            old_customer_id: [''],
            is_make:[1],
            is_year:[1],
            is_model:[1],
            is_color:[1],
            is_license :[1],
            inspection_expiration_date_frm:[''],
            inspection_expiration_date:[''],
            california_emmissions:[''],
            transmission:[''],
            brake:[''],
            driveline:[''],
            gvwr:[''],
            wheel_base:[''],
            tire_size:[''],
            wheel_lock_location:[''],
            custom_fields:[''],
            other_labels_temp:['']
        });
    }

    getYears(event) {
        //this.car.controls.model.disable();
        this.carModels = [];
        if (event == undefined || event == "") {
            // this.car.controls.year.disable();
            this.years = [];
            this.carModels = [];
            this.disabledYear = true;
            this.disabledModel = true;
        } else {
            //this.car.controls.year.enable();
            this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_YEAR_LIST, "opr=1&make=" + event).subscribe((data:any) => {
                if (data.result == "1") {
                    this.disabledYear = false;
                    var yearsList=[];
                    if (data.data.length == undefined) {
                        //this.years = [];
                        this.years[0] = data.data;
                        /* FOR SEARCH BOX DROPDOWN SELECT */
                        this.years.id=this.years.year;
                        this.years.text=this.years.year;          
                        yearsList.push(this.years);
                        //this.statesArray[this.states.state_id]=this.states.state_name;
                        this.years=yearsList;
                        /* END FOR V2 SELECT */
                    } else {
                        this.years = data.data;
                        /* FOR SEARCH BOX DROPDOWN SELECT */
                        for(var i in this.years){
                            this.years[i].id=this.years[i].year;
                            this.years[i].text=this.years[i].year;
                            yearsList.push(this.years[i]);
                            //this.statesArray[this.years[i].state_id]=(this.years[i].state_name);
                        }
                        this.years=yearsList;
                        /* END FOR V2 SELECT */
                    }
                } else {
                    //this.car.controls.year.disable();
                    this.disabledYear = true;
                }
            });
        }
    }

    getModelList(event) {
        if (event == undefined) {
            //this.car.controls.model.disable();
            this.carModels = [];
            this.disabledModel = true;
        } else {
            this.car.controls.model.enable();
            this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_MODELS_LIST_URL, "opr=1&make=" + this.selectedMake + "&year=" + event).subscribe((data:any) => {
                if (data.result == "1") {
                    this.disabledModel = false;
                    var modelsList=[];
                    if (data.data.length != undefined) {
                        this.carModels = data.data;
                        /* FOR SEARCH BOX DROPDOWN SELECT */
                        for(var i in this.carModels){
                            this.carModels[i].id=this.carModels[i].model+(this.carModels[i].trim ? ' '+this.carModels[i].trim : '')+(this.carModels[i].body_style ? ' '+this.carModels[i].body_style : '')
                            this.carModels[i].text=this.carModels[i].model+(this.carModels[i].trim ? ' '+this.carModels[i].trim : '')+(this.carModels[i].body_style ? ' '+this.carModels[i].body_style : '')
                            modelsList.push(this.carModels[i]);
                            //this.statesArray[this.years[i].state_id]=(this.years[i].state_name);
                        }
                        this.carModels=modelsList;
                        /* END FOR V2 SELECT */
                    } else {
                        this.carModels[0] = data.data;
                        /* FOR SEARCH BOX DROPDOWN SELECT */
                        this.carModels.id=this.carModels.model+(this.carModels.trim ? ' '+this.carModels.trim : '')+(this.carModels.body_style ? ' '+this.carModels.body_style : '')
                        this.carModels.text=this.carModels.model+(this.carModels.trim ? ' '+this.carModels.trim : '')+(this.carModels.body_style ? ' '+this.carModels.body_style : '')          
                        modelsList.push(this.carModels);
                        //this.statesArray[this.states.state_id]=this.states.state_name;
                        this.carModels=modelsList;
                        /* END FOR V2 SELECT */
                    }
                } else {
                    //                    this.globalService.snackbar("error", data.message)
                }
            });
        }
    }
    enableAddNewColor() {
        this.addNewColor = true;
    }
    disableAddNewColor() {
        this.addNewColor = false;
    }
    changeListner(event, rendering_tag_id) {
        var html = "";
        var base64image = "";
        if (event.target.files && event.target.files[0]) {
            let file = event.target.files[0];
            let uploading_key = rendering_tag_id.split("-")[0];
            let ele=<HTMLInputElement>document.getElementById(uploading_key);
            //if (file.type == "image/png" || file.type == "image/jpeg" || file.type == 'video/mp4' || file.type == "video/quicktime") {
                if (uploading_key == "vehicle_video" ? file.size < this.MAX_ALLOWED_VIDEO_SIZE : file.size < this.MAX_ALLOWED_IMAGE_SIZE) {
                    var img:any = document.createElement("img");
                    img.className = "show-image";
                    var reader = new FileReader();
                    var base64value = "";
                    let base64valuec;
                    reader.onload = (e) => {

                        img.src = e.target['result'];
                        img.className = "show-image";
                        base64valuec = e.target['result'];

                          /*if (uploading_key == "vehicle_video") {
                            if (file.type == 'video/mp4' || file.type == "video/quicktime") {
                                return true;
                            } else { 
                                this.globalService.snackbar("error", "Please upload only mp4 video.");
                                //let ele=<HTMLInputElement>document.getElementById(rendering_tag_id);
                                //ele.value='';
                                return false;
                            }
                        } else {
                            if(file.type == "image/png" || file.type == "image/jpeg" || file.type == 'image/jpg') {
                                return true;
                            } else { 
                                this.globalService.snackbar("error", "Please upload only png jpeg & jpg format of image.");
                                //let ele=<HTMLInputElement>document.getElementById(rendering_tag_id);
                                //ele.value='';
                                return false;
                            }
                        }*/
                        
                        /*
                        if (file.type == 'video/mp4' || file.type == "video/quicktime") {
                            if (uploading_key != "vehicle_video") {
                                this.globalService.snackbar("error", "Please upload only mp4 video.");
                                return false;
                            }
                        } */
                        
                        let pic = {id: 0, loader: false, vehiclePic: '', vehicleInsured_pic: '', vehicleRegistered_pic: '',other_labels_temp:'',label_pic:''};
                        pic.loader = true
                        if (uploading_key == "vehicle_pic") {
                            if (file.type == "image/png" || file.type == "image/jpeg" || file.type == 'image/jpg') {
                                pic.vehiclePic = base64valuec;
                                this.vPic.push(pic);
                                this.uploadImage(globalConstants.API_UPLOAD_VEHICLE_PICTURE_URL, uploading_key, base64valuec);
                            } else { 
                                this.globalService.snackbar("error", "Please upload only png, jpg & jpeg format of image.");
                                pic.vehiclePic = "";
                                ele.value='';
                            }
                        }
                        else if (uploading_key == "vehicle_registered_pic") {
                            if (file.type == "image/png" || file.type == "image/jpeg" || file.type == 'image/jpg') {
                                pic.vehicleRegistered_pic = base64valuec;
                                this.vehicleRegisteredPic.push(pic);
                                this.uploadImage(globalConstants.API_UPLOAD_VEHICLE_REG_PICTURE_URL, uploading_key, base64valuec);
                            } else { 
                                this.globalService.snackbar("error", "Please upload only png, jpg & jpeg format of image.");
                                pic.vehicleRegistered_pic = "";
                                ele.value='';
                            }
                        }
                        else if (uploading_key == "vehicle_insured_pic") {
                            if (file.type == "image/png" || file.type == "image/jpeg" || file.type == 'image/jpg') {
                                pic.vehicleInsured_pic = base64valuec;
                                this.uploadImage(globalConstants.API_UPLOAD_VEHICLE_INS_PICTURE_URL, uploading_key, base64valuec);
                                this.vehicleInsuredPic.push(pic);
                            } else { 
                                this.globalService.snackbar("error", "Please upload only png, jpg & jpeg format of image.");
                                pic.vehicleInsured_pic = "";
                                ele.value='';
                            }
                        }
                        else if (uploading_key == "vehicle_video") {
                            if (file.type == 'video/mp4' || file.type == "video/quicktime") {
                                this.videoList.push(pic);
                                this.uploadImage(globalConstants.API_UPLOAD_VEHICLE_VIDEO_URL, uploading_key, file);
                            } else {
                                this.globalService.snackbar("error", "Please upload only mp4 & quicktime format of video.");
                                ele.value='';
                                return false;
                            }
                        }
                        else if (uploading_key == "vin_photo") {
                            if (file.type == "image/png" || file.type == "image/jpeg" || file.type == 'image/jpg') {
                                this.vinPhotoSetLoader = true;
                                if (this.vinPhoto == '') {
                                    this.vinPhoto = 'new Image';
                                }
                                this.vinPhotoSet = base64valuec;
                                this.uploadImage(globalConstants.API_UPLOAD_VEHICLE_VIN_PICTURE_URL, uploading_key, base64valuec);
                            } else { 
                                this.globalService.snackbar("error", "Please upload only png, jpg & jpeg format of image.");
                                this.vinPhotoSet = "";
                                ele.value='';
                            }
                        }else if (uploading_key == "other_labels_temp") {
                            if (file.type == "image/png" || file.type == "image/jpeg" || file.type == 'image/jpg') {
                                pic.other_labels_temp = base64valuec;
                                this.otherLabelsTemp.push(pic);
                                this.uploadImage(globalConstants.API_ADD_VEHICLE_LABEl_PICTURE, uploading_key, base64valuec);
                            } else {
                                ele.value='';
                                pic.other_labels_temp = "";
                                this.globalService.snackbar("error", "Please upload only png, jpg & jpeg format of image.");
                            }
                        }
                        event.target.value = "";
                    }

                    reader.readAsDataURL(event.target.files[0]);
                } else {
                    ele.value='';
                    if(uploading_key == "vehicle_video"){
                        this.globalService.snackbar("error", "Video should be less then "+this.MAX_ALLOWED_VIDEO_DISPLAYED_TEXT+".");
                    }else{
                        this.globalService.snackbar("error", "Image should be less then "+this.MAX_ALLOWED_IMAGE_DISPLAYED_TEXT+".");
                    }
                }
            /*} else {
                this.globalService.snackbar("error", "Please upload only png & jpeg format of image");
            }*/
        }
    }
    uploadImage(url, key, base64value) {
        // console.log("console");
        this.globalService.imageLoader();
        if (key == "vin_photo") {
            key = "vehicle_vin_pic"
        }else if(key=='other_labels_temp'){
            key='label_pic'
        }

        var data = new FormData();
        data.append('customer_id', this.carCustomerId);
        data.append('vehicle_id', this.vehicleId);
        data.append(key, base64value);

        this.globalService.formData(url, data).subscribe((response:any) => {
            let resData = response;
            if (key == "vehicle_pic") {
                this.vPic[this.vPic.length - 1].loader = false;
                this.vPic[this.vPic.length - 1].id = resData.data.id;
                this.vPic[this.vPic.length - 1].vehiclePic='';
                this.vPic[this.vPic.length - 1].vehicle_pic = resData.data.vehicle_pic;
            } else if (key == "vehicle_registered_pic") {
                this.vehicleRegisteredPic[this.vehicleRegisteredPic.length - 1].id = resData.data.id;
                this.vehicleRegisteredPic[this.vehicleRegisteredPic.length - 1].loader = false;
                this.vehicleRegisteredPic[this.vehicleRegisteredPic.length - 1].vehicleRegistered_pic='';
                this.vehicleRegisteredPic[this.vehicleRegisteredPic.length - 1].vehicle_registered_pic=resData.data.vehicle_registered_pic;
            } else if (key == "vehicle_insured_pic") {
                this.vehicleInsuredPic[this.vehicleInsuredPic.length - 1].id = resData.data.id;
                this.vehicleInsuredPic[this.vehicleInsuredPic.length - 1].loader = false;
                this.vehicleInsuredPic[this.vehicleInsuredPic.length - 1].vehicle_insured_pic=resData.data.vehicle_insured_pic;
                this.vehicleInsuredPic[this.vehicleInsuredPic.length - 1].vehicleInsured_pic='';
            } else if (key == 'vehicle_vin_pic') {
                this.vinPhotoSetLoader = false;
                this.vinPhotoSet='';
                this.vinPhoto=resData.data.vehicle_vin_pic;
            } else if (key == "vehicle_video") {
                this.videoList[this.videoList.length - 1].id = resData.data.id;
                this.videoList[this.videoList.length - 1].vehicle_video = resData.data.vehicle_video;
                this.videoList[this.videoList.length - 1].loader = false;
            } else if (key == "label_pic") {
                this.otherLabelsTemp[this.otherLabelsTemp.length - 1].id = resData.data.id;
                this.otherLabelsTemp[this.otherLabelsTemp.length - 1].label_pic=resData.data.label_pic;
                this.otherLabelsTemp[this.otherLabelsTemp.length - 1].other_labels_temp='';
                this.otherLabelsTemp[this.otherLabelsTemp.length - 1].loader = false;
            }
        })
    }
    /** delete image and video */
    imgDelete(picId, types, index) {
        this.globalService.imageLoader();
       
        let data = 'vehicle_id=' + this.vehicleId;

        let url = '';

        
        if (types == 'vehicle_pic') {
          this.vPic[index].loader = true;
            this.globalService.confirmDialog(picId ,'Are you sure you want to delete this image?').subscribe((res) => {
                if (res != null) {
                    url = globalConstants.API_DELETE_VEHICLE_PICTURE_URL;
                    data = data + '&vehicle_picture_id=' + picId;
                    if (url != '') {
                        this.subscriptionDataForAPI = this.globalService.callAPI(url, data).subscribe((response:any) => {
                          this.vPic[index].loader = false;
                            let result = response;
                            if (types == 'vehicle_pic' && result.result == 1) {
                                this.deleteImage(this.vPic, picId);
                                this.globalService.snackbar("success", result.message);
                            }
                        })
                    }
                }
            });
        } else if (types == "vehicle_registered_pic") {
            this.globalService.confirmDialog(picId ,'Are you sure you want to delete this image?').subscribe((res) => {
               this.vehicleRegisteredPic[index].loader = true;
                if (res != null) {
                    url = globalConstants.API_DELETE_VEHICLE_REG_PICTURE_URL;
                    data = data + '&vehicle_registered_picture_id=' + picId;
                    if (url != '') {
                        this.subscriptionDataForAPI = this.globalService.callAPI(url, data).subscribe((response:any) => {
                           this.vehicleRegisteredPic[index].loader = false;
                            let result = response;
                            if (types == 'vehicle_registered_pic' && result.result == 1) {
                                this.deleteImage(this.vehicleRegisteredPic, picId);
                                this.globalService.snackbar("success", result.message);
                            }
                        })
                    }
                }
            });
        } else if (types == "vehicle_insured_pic") {
            this.globalService.confirmDialog(picId ,'Are you sure you want to delete this image?').subscribe((res) => {
              this.vehicleInsuredPic[index].loader = true;
                if (res != null) {
                    url = globalConstants.API_DELETE_VEHICLE_INS_PICTURE_URL;
                    data = data + '&vehicle_insured_picture_id=' + picId;
                    if (url != '') {
                        this.subscriptionDataForAPI = this.globalService.callAPI(url, data).subscribe((response:any) => {
                            let result = response;
                          this.vehicleInsuredPic[index].loader = false;
                            if (types == 'vehicle_insured_pic' && result.result == 1) {
                                this.deleteImage(this.vehicleInsuredPic, picId);
                                this.globalService.snackbar("success", result.message);
                            }
                        })
                    }
                }
            });
         } else if (types == "vinPhoto") {
            this.globalService.confirmDialog(picId ,'Are you sure you want to delete this image?').subscribe((res) => {
              this.vinPhotoSetLoader = true;
                if (res != null) {
                    url = globalConstants.API_DELETE_VEHICLE_VIN_PICTURE_URL;
                    data = data + '&customer_id=' + this.carCustomerId;
                    if (url != '') {
                        this.subscriptionDataForAPI = this.globalService.callAPI(url, data).subscribe((response:any) => {
                          this.vinPhotoSetLoader = false;
                            let result = response;
                            if (types == 'vinPhoto' && result.result == 1) {
                                this.vinPhoto = ''; this.vinPhotoSet = '';
                                this.globalService.snackbar("success", result.message);
                            }
                        })
                    }
                }
            });
        } else if (types == "vehicle_video") {
            this.globalService.confirmDialog(picId ,'Are you sure you want to delete this video?').subscribe((res:any) => {
              this.videoList[index].loader = true;
                if (res != null) {
                    url = globalConstants.API_DELETE_VEHICLE_VIDEO_URL;
                    data = data + '&vehicle_video_id=' + picId;
                    if (url != '') {
                        this.subscriptionDataForAPI = this.globalService.callAPI(url, data).subscribe((response:any) => {
                          let result = response;
                          this.videoList[index].loader = false;
                            if (types == 'vehicle_video' && result.result == 1) {
                                this.deleteImage(this.videoList, picId);
                                this.globalService.snackbar("success", result.message);
                            }
                        })
                    }
                }
            });
        }else if(types == "other_labels_temp"){
            this.globalService.confirmDialog(picId, 'Are you sure you want to delete this image?').subscribe((res:any) => {
                this.otherLabelsTemp[index].loader = true;
                if (res != null) {
                    url = globalConstants.API_DELETE_VEHICLE_LABEl_PICTURE;
                    data = data + '&label_picture_id=' + picId;
                    if (url != '') {
                        this.subscriptionDataForAPI =  this.globalService.callAPI(url, data ).subscribe((response:any) => {
                            this.otherLabelsTemp[index].loader = false;
                            let result = response;
                            if (types == 'other_labels_temp' && result.result == 1) {
                                this.deleteImage(this.otherLabelsTemp, picId);
                                this.globalService.snackbar("success", result.message);
                            }
                        })
                    }
                }
            });
        }
    }

    /***delete data from array */
    deleteImage(vPic, picId) {
        if (vPic.length) {
            for (let i in vPic) {
                if (vPic[i].id == picId) {
                    vPic.splice(parseInt(i), 1);
                }
            }
        }
    }

    /*******video dialog******/
    videoShow(val): void {
        let dialogRef = this.dialog.open(VideoShowComponent, {
            panelClass: 'car-dialog-form',
            width: "800px",
            data: {name: val,url:globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_VIDEO}
        });

        dialogRef.afterClosed().subscribe(result => {

        });
    }

    // Make
    enterNewMake() {
        this.EnterNewMake = true;
        this.EnterNewYear = true;
        this.EnterNewModel = true;

        if (this.EnterNewMakeButton == true) {
            this.EnterNewMakeButton = false;//show Existing Make
            this.UseExistingMakeButton = true;//Hide Another
            this.EnterNewYearButton = false;//Hide Existing Year
            this.UseExistingYearButton = false;//Hide Another
            this.EnterNewModelButton = false;//Hide Existing Model
            this.UseExistingModelButton = false;//Hide Another
        } else {
            this.EnterNewMakeButton = true;//hide Existing Make
            this.UseExistingMakeButton = false;//show Another
            this.EnterNewYearButton = true;//show Existing Year
            this.UseExistingYearButton = false;//Hide Another
            this.EnterNewModelButton = true;//show Existing Model
            this.UseExistingModelButton = false;//Hide Another
        }

        if (this.disabledYear == false || this.disabledModel == false) {
            this.disabledYear = true;
            this.disabledModel = true;
        } else {
            this.disabledYear = false;
            this.disabledModel = false;
        }
        this.checkIfValid();
    }
    useExitingMake() {
        if(this.makesValue!="") {
            this.disabledYear = false;
        }
        if(this.modelsValue!="") {
            this.disabledModel = false;
        }
        this.EnterNewMake = false;
        this.EnterNewYear = false;
        this.EnterNewModel = false;
        this.EnterNewMakeButton = true;//hide Existing Make
        this.UseExistingMakeButton = false;//show Another
        this.EnterNewYearButton = true;//show Existing Year
        this.UseExistingYearButton = false;//Hide Another
        this.EnterNewModelButton = true;//show Existing Model
        this.UseExistingModelButton = false;//Hide Another
        //this.car.controls.year.reset();
        this.checkIfValid();
    }
    enterNewYear() {
        this.EnterNewYear = true;
        this.EnterNewModel = true
        this.EnterNewYearButton = false;//show Existing Year
        this.UseExistingYearButton = true;//Hide Another
        this.EnterNewModelButton = false;//show Existing Model
        this.UseExistingModelButton = false;//Hide Another
        this.checkIfValid();
    }
    useExitingYear() {
        this.EnterNewYear = false;
        this.EnterNewModel = false
        this.EnterNewYearButton = true;//show Existing Year
        this.UseExistingYearButton = false;//Hide Another
        this.EnterNewModelButton = true;//show Existing Model
        this.UseExistingModelButton = false;//Hide Another
        //this.car.controls.model.reset();
        this.checkIfValid();
    }
    enterNewModel() {
        this.EnterNewModel = true
        this.EnterNewModelButton = false;
        this.UseExistingModelButton = true;//Hide Another
        this.checkIfValid();
    }
    useExitingModel() {
        this.EnterNewModel = false
        this.UseExistingModelButton = false;
        this.EnterNewModelButton = true;
        //this.car.controls.model.reset();
        this.checkIfValid();
    }

    /* Get Country ID from Name */
    /*getCountryIDByName(id) {
        var name = ""
        for (var i = 0; i < this.countries.length; i++) {
            if (id == this.countries[i]["country_name"]) {
                name = this.countries[i]["country_id"];
                break;
            }
        }

        return name;
    } */
     /* image rotation dialog  */
     imageToolDialog(current,i,type,imgaar): void {
        let dialogRef = this.dialog.open(ImagetooldialogComponent, {
            panelClass: ['imageToolDialog'],
            width: "100%",
            data: {event: current,index:i,imgType:type,imgArray:imgaar,customerId:this.currentCustomer.id,carId:this.idFromUrl}
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }
    goTo(obj,picker){
        if(typeof obj =='undefined'){
            obj=picker.inputElementValue;
        }
        if(obj!=''){
            picker.api.moveCalendarTo(obj);
        }
    }
    submitCar(content){
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_EDIT_VEHICLE_URL, content).subscribe((data:any) => {
            var message = data.message;
            if (data.result == "1") {
                this.router.navigate(['shops/list-cars']);
                this.globalService.snackbar('success', message);
            } else {
                this.globalService.snackbar('error', message);
            }
        });
    }
    getLockLocation(opt) {
        this.checkIfValid();
    }
    californiaEmmissions(t) {
        if (t.checked) {
            this.car.controls.california_emmissions.setValue(1);
        } else {
            this.car.controls.california_emmissions.setValue(0);
        }
        this.checkIfValid();
    }
    checkExistingLoclLocation() {
        for (var i = 0; i < this.wheelLock.length; i++) {
            if (this.wheelLock[i].id == this.vehicleDetails.wheel_lock_location) {
                this.antWheellocklocation = true;
                return;
            } else {
                this.antWheellocklocation = false;
            }
        }
    }
    isCustFieldAllowed(){
        return this.globalService.getAcl('custom_vehicle_fields','addNew')
    }
    /** add custom fields */
    addMoreFields(){
        let dialogRef = this.dialog.open(ExtrafieldsComponent, {
            panelClass: ['car-dialog-form','extrafields-dialog-form'],
            width: "800px",
            data: {}
        });
        dialogRef.afterClosed().subscribe((r:any) => {
            if(r && typeof r.id!='undefined'){
                r.value=""
                this.customFieldList.push(r)
            }
        })
    }
    getCustomField(f){
        let data={user_type: this.currentCustomer.user_type,user_id: this.currentCustomer.id}
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_CUSTOM_VEHICLE_FIELD,data).subscribe((r:any)=>{
            if(r.result==1){
                this.customFieldList=r.data
                this.assigneValueToCustmFields(f)
            }
        },(error)=>{ })
    }
    assigneValueToCustmFields(data) {
        if (data && typeof data != 'undefined' && data.length) {
            this.customFieldList.forEach((e) => {
                for (let i in data) {
                    if(e.id==data[i].vehicle_profile_field_id){
                        e.value=data[i].value
                        break;
                    }
                }
                
            })
        }
    }
    chooseCustmField(v,i){
        this.customFieldList[i].value=v
    }
    fieldList() {
        let dialogRef = this.dialog.open(ExtrafieldListComponent, {
            panelClass: ['car-dialog-form', 'extrafields-dialog-form'],
            width: "800px",
            data: {}
        });
        dialogRef.afterClosed().subscribe((r: any) => {
            let custFields = []
            if (this.vehicleDetails && typeof this.vehicleDetails.custom_fields != 'undefined' && this.vehicleDetails.custom_fields.length) {
                custFields = this.vehicleDetails.custom_fields
            }
            this.getCustomField(custFields)
        })
    }

    ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
    }  
    
}
