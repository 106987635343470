<div class="panel panel-primary emp-permission" id="mainCompotents">
    <div class="panel-heading padding-left-15">
      <h3 class="panel-title">Manage Employee Permissions</h3>
     <button mat-mini-fab class="custom-btn-back" (click)="gs.goBack();">
            <mat-icon>arrow_back</mat-icon>
    </button>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <form (ngSubmit)="savePermission()" (keydown.enter)="$event.preventDefault()">
            <!-- <mat-accordion class="new-request-expension margin-bottom-10 margin-top-10 display-full" displayMode="flat" hideToggle="false"> -->
            <!-- <mat-expansion-panel  [expanded]="i==0" hideToggle="true" class="new-request-expension role-expansion-panel" *ngFor="let role of roles; let i = index" id="expansion-{{i+1}}"> -->
                <!-- <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="expansionHead-{{i+1}}" (click)="gs.expansionOpen(i+1);" data-state="false">
                    <span class="margin-right-5">{{role.name}}</span>
                </mat-expansion-panel-header> -->
        <div class="emp-permission-wrap" *ngFor="let role of roles; let i = index" id="expansion-{{i+1}}">
                <div class="second-row table-responsive " >
                    <table class="table permission-tabel table-striped">
                        <thead>
                            <tr class="permission-btns header-part" >
                                <td>
                                    <div class="manager-name">
                                        <span class="employee-span">{{role.name}}</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="checkbox-data-block">
                                    </div>
                                </td>
                                <td>
                                    <div class="checkbox-data-block">
                                        <button class="tooltip-status text-white fa fa-eye" title="View All" type="button"></button>
                                    </div>
                                </td>
                                <td>
                                    <div class="checkbox-data-block">
                                        <button class="tooltip-status text-white fa fa-plus-circle" title="Add All" type="button"></button>
                                    </div>
                                </td>
                                <td>
                                    <div class="checkbox-data-block">
                                        <button class="tooltip-status text-white fa fa-pencil" title="Edit All" type="button"></button>
                                    </div>
                                </td>
                                <td>
                                    <div class="checkbox-data-block">
                                        <button class="tooltip-status text-white fa fa-trash" title="Delete All" type="button"></button>
                                    </div>
                                </td>
                            </tr>
                            <tr class="permission-btns search-part">
                                <th>
                                    <div class="manager-name">
                                        <div class="input-group">
                                            <input class="form-control border-end-0 border search-input" type="search" placeholder="Search" id="example-search-input" [(ngModel)]="search" [ngModelOptions]="{standalone: true}" (keyup.enter)="searchPermissions(i)" (input)="clearPermissions(i)">
                                            <span class="input-group-append">
                                                <button class="btn btn-outline-secondary bg-white border-start-0 border-bottom-0 border ms-n5" type="button" (click)="searchPermissions(i)">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div class="all-checkbox-block black-box">
                                    </div>
                                </th>
                                <th>
                                    <div class="all-checkbox-block">
                                        <mat-checkbox  [checked]="role.all.view" name="view-all-check" labelPosition="after" (change)="checkAllAction(role.id, i, 'view', role.all.view)" [disabled]="role.is_admin">
                                        </mat-checkbox>
                                    </div>
                                </th>
                                <th>
                                    <div class="all-checkbox-block">
                                        <mat-checkbox  [checked]="role.all.add" name="add-all-check" labelPosition="after" (change)="checkAllAction(role.id, i, 'add', role.all.add)" [disabled]="role.is_admin">
                                        </mat-checkbox>
                                    </div>
                                </th>
                                <th>
                                    <div class="all-checkbox-block">
                                        <mat-checkbox  [checked]="role.all.edit" name="edit-all-check" labelPosition="after" (change)="checkAllAction(role.id, i, 'edit', role.all.edit)" [disabled]="role.is_admin">
                                        </mat-checkbox>
                                    </div>
                                </th>
                                <th>
                                    <div class="all-checkbox-block">
                                        <mat-checkbox  [checked]="role.all.delete" name="delete-all-check" labelPosition="after" (change)="checkAllAction(role.id, i, 'delete', role.all.delete)" [disabled]="role.is_admin">
                                        </mat-checkbox>
                                    </div> 
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let item of role.items; let j = index">
                                <tr *ngIf="item.show">
                                    <td >
                                        <div class="manager-name">
                                            <p>{{item.name}}</p>
                                        </div>
                                    </td>
                                    <td >
                                        <div class="checkbox-data-block permission-btns black-box visible-xs">
                                            <span [tooltip]="item.help_text" tooltipPlacement="top" class="tooltip-status text-black fa fa-info-circle"></span>
                                        </div>
                                        <div class="row hidden-xs">
                                            <div class="col-sm-12"><p class="help-block margin-0"> {{item.help_text}}</p></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="checkbox-data-block">
                                                <mat-checkbox *ngIf="item.is_view==1" value="item.permissions.view" labelPosition="after" [checked]="item.permissions.view" (change)="checkPermission(item.role_id, i, 'view', item.permissions.view , item.permission_id, j)" [disabled]="role.is_admin">
                                                </mat-checkbox>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="checkbox-data-block">
                                            <mat-checkbox *ngIf="item.is_addNew==1" value="item.permissions.add" labelPosition="after" [checked]="item.permissions.add" (change)="checkPermission(item.role_id, i, 'add', item.permissions.add , item.permission_id, j)" [disabled]="role.is_admin">
                                            </mat-checkbox>
                                        </div>
                                    </td>
                                    <td>
                                    <div class="checkbox-data-block">
                                            <mat-checkbox *ngIf="item.is_edit==1" value="item.permissions.edit" labelPosition="after" [checked]="item.permissions.edit" (change)="checkPermission(item.role_id, i, 'edit', item.permissions.edit , item.permission_id, j)" [disabled]="role.is_admin">
                                            </mat-checkbox>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="checkbox-data-block">
                                            <mat-checkbox *ngIf="item.is_delete==1" value="item.permissions.delete" labelPosition="after" [checked]="item.permissions.delete" (change)="checkPermission(item.role_id, i, 'delete', item.permissions.delete , item.permission_id, j)" [disabled]="role.is_admin">
                                            </mat-checkbox>
                                    </div>
                                    </td>
                                </tr>
                            </ng-container>
                            <tr class="no-result-row" *ngIf="showNoResult == true">
                                <td colspan="6" class="text-center no-results-message">
                                    <p class="mat-error">No Results Found</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            <!-- </mat-expansion-panel>
            </mat-accordion> -->
        </div>
            <div class="display-full text-right margin-top-10 btn-section">
                <button  type="submit"  mat-raised-button class="" color="accent" mat-raised-button title="Submit">
                    Submit
                </button>
            </div>
        </form>
    </div>
</div>