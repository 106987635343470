import {Component,OnInit,Output,Input,EventEmitter} from '@angular/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {Observable,observable, Subscription} from 'rxjs';
import {HelperService} from 'src/app/helper.service';
import {IConversation,ICustomerChat,ISRHistory,ISRHistoryInfo,IVehicleHistory} from 'src/app/interfaces';
import {GlobalService} from 'src/environments/global.service';
import {globalConstants} from 'src/environments/globalConstants';

@Component({
  selector: 'app-chat-info-area',
  templateUrl: './chat-info-area.component.html',
  styleUrls: ['./chat-info-area.component.scss']
})
export class ChatInfoAreaComponent implements OnInit {
  panelOpenState=false;
  @Input() onMobileScreen;
  @Output() backToChatArea=new EventEmitter<any>();
  @Input() conversation=new Observable();

  public conversationInfo: IConversation|null=null;
  public vehicleHistory: IVehicleHistory[]=[];
  public showMoreVehicle: boolean=false;
  public showMoreCompletedSR: boolean=false;
  public showMoreActiveSR: boolean=false;
  public imgPath = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_PICTURES + globalConstants.IMG_THUMB;

  public SRHistory: ISRHistory={
    active: [],
    completed: []
  }
  public paginationInfo={
    vehicle: -1,
    activesSr: -1,
    completedSr: -1
  }
  public conversationDetail: IConversation|null=null
  public statuses=['Appointment','Pending','Processing','Approval Awaited','','','Completed','Approval Sent','Completed And Paid','Created','Created','','Work on Progress'];

  public activeSRs: Array<ISRHistory>=[];
  public completedSRs: Array<ISRHistory>=[];
  public connectionActive: boolean=true;
  public conversationSubscription: Subscription = new Subscription();

  constructor(public globalService: GlobalService,public helperService: HelperService) {}

  ngOnInit(): void {
    this.conversationSubscription = this.conversation.subscribe((conversation: IConversation) => {
      this.conversationDetail=conversation;
      this.conversationInfo=conversation;
      this.paginationInfo={
        vehicle: 0,
        activesSr: 0,
        completedSr: 0
      }
      this.getVehicleHistory();
      this.getActiveSRHistory();
      this.getCompletedSRHistory();
      this.vehicleHistory=[];
      this.SRHistory.active=[];
      this.SRHistory.completed=[];
      this.helperService.getSocketConnectionStatus.subscribe((status) => {
        this.connectionActive=status;
      })
    })
  }

  getVehicleHistory=async () => {
    if(this.connectionActive) {
      let loggedInShopUser=await JSON.parse(localStorage.getItem('loggedInUser'));
      const customerId=this.conversationInfo.customer_id;
      this.conversationSubscription = this.globalService.callAPI(globalConstants.CHAT_API_GET_RECENT_VEHICLES_URL,{
        customerId,
        shopId: loggedInShopUser.shop_id,
        page: this.paginationInfo.vehicle
      }).subscribe((response) => {
        this.vehicleHistory=[...this.vehicleHistory,...response.data.current_data];
        this.showMoreVehicle=response.data.more_rows;
      });
    }

  }

  getActiveSRHistory=async () => {
    if(this.connectionActive) {
      let loggedInShopUser=await JSON.parse(localStorage.getItem('loggedInUser'));
      const customerId=this.conversationInfo.customer_id;
      this.conversationSubscription = this.globalService.callAPI(globalConstants.CHAT_API_GET_SR_URL,{
        customerId,
        shopId: loggedInShopUser.shop_id,
        status: 'active',
        page: this.paginationInfo.activesSr,
        limit: 2
      }).subscribe((response) => {
        this.SRHistory.active=[...this.SRHistory.active,...response.data.data];
        this.showMoreActiveSR=response.data.more_rows;
      });
    }
  }

  getCompletedSRHistory=async () => {
    if(this.connectionActive) {
      let loggedInShopUser=await JSON.parse(localStorage.getItem('loggedInUser'));
      const customerId=this.conversationInfo.customer_id;
      this.conversationSubscription = this.globalService.callAPI(globalConstants.CHAT_API_GET_SR_URL,{
        customerId,
        shopId: loggedInShopUser.shop_id,
        status: 'completed',
        page: this.paginationInfo.completedSr,
        limit: 2
      }).subscribe((response) => {
        this.SRHistory.completed=[...this.SRHistory.completed,...response.data.data];
        this.showMoreCompletedSR=response.data.more_rows;
      })
    }
  }

  onBackToChatArea() {
    this.backToChatArea.emit();
  }

  paginateVehicle() {
    this.paginationInfo={
      ...this.paginationInfo,
      vehicle: this.paginationInfo.vehicle+1
    };
    this.getVehicleHistory();
  }

  paginateActiveSr() {
    this.paginationInfo={
      ...this.paginationInfo,
      activesSr: this.paginationInfo.activesSr+1
    };
    this.getActiveSRHistory();
  }
  paginateCompletedSr() {
    if(this.connectionActive) {
      this.paginationInfo={
        ...this.paginationInfo,
        completedSr: this.paginationInfo.completedSr+1
      };
      this.getCompletedSRHistory();
    }
  }

  getStatusColor(serviceReqData) {
    let status: any=serviceReqData.is_appointment=='1'&&(serviceReqData.status=='1'||serviceReqData.status=='2')? 0:serviceReqData.status;
    if(status!=-1) {
      var statusArr=['customer-appt','warning-cell','info-cell','awaited-cell',"",'success-cell','success-completed','success-cell','success-paid',"","","",'work-progress-cell'];
      return statusArr[status];
    }
    else {
      return 'deleted-cell';
    }
  }

  navigateToSRView =(SRId:number) =>{
    window.open(`/shops/view-service-request/${SRId}`,'_blank');
  }

  getCustomerInitials(conversation: IConversation|ICustomerChat) {
    const initials=conversation.first_name[0].toUpperCase()+conversation.last_name[0].toUpperCase();
    return initials.toString();
  }
  ngOnDestroy() {
    this.conversationSubscription.unsubscribe()
  }
}
