import {Component, ViewChild, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {Http, Headers, Response} from '@angular/http';
import {globalFunctions} from "../../../../environments/globalFunctions";
import {globalConstants} from "../../../../environments/globalConstants";
import {GlobalService} from "../../../../environments/global.service";
import {Title} from '@angular/platform-browser';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import {AppComponent} from '../../../app.component';
import { MatTableDataSource } from '@angular/material/table';
const SHOP_INQUIRIES='shop-inquiries-';
@Component({
  selector: 'app-listshopinquiries',
  templateUrl: './listshopinquiries.component.html',
  styleUrls: ['./listshopinquiries.component.scss']
})

export class ListshopinquiriesComponent implements OnInit {
  displayedColumns = ['inquiry_id', 'title','customer_name', 'contact_number', 'customer_email', 'inquiry_date', 'status', 'actions'];
  dataSource: MatTableDataSource<InquireData> = new MatTableDataSource();
  public currentCustomer;
  public schedule_service_option
  public requested_delivery_option
  public estimated_delivery_option
  public openSearch;
  public more_rows;
  public options = {
    user_id: "",
    user_type: "User",
    inquiry_status: '',
    search_keyword: "",
    start: 0 ,
    limit: 10 ,
  }
  public statuses = ['Created', 'Open', 'Resolved', 'Unread'];
  public gService;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public searchByStatus = [];
  public recordsTotal: any;
  public record: any;

  constructor(private location:Location,private app: AppComponent, public router:Router,private http:Http, public titleService: Title,public globalservice:GlobalService){
    this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
    this.options.user_id = this.currentCustomer.id;
    this.gService=this.globalservice;
    this.reinitializeOptions();
    if(localStorage.getItem("openFilter") == "1") {
            this.openSearch = true;
    } else {
        this.openSearch = false;
    }
    /* SEARCH BY ARRAY */
    this.searchByStatus = [
        { id: "1", text: "Open"},
        { id: "2", text: "Resolved"},
        { id: "3", text: "Unread"}
    ];
  }
  getStatus(statusId) {
    return this.statuses[statusId]
  }
  setTouchViewValue(){
      return this.app.touchViewState;
  }
  formatDate(date) {
    if (!new Date(date) || date == null || date == "0000-00-00 00:00:00") { return "-" }
    return (new Date(date).getMonth()+1) + "/" + new Date(date).getDate() + "/" + new Date(date).getFullYear()
  }
  ngOnInit() {
    setTimeout(()=>{
    let isEnable=this.globalservice.getAcl('inquiries','views');
    if(!(isEnable)){
        this.router.navigate(['shops/action/forbidden']);
    }
  },globalConstants.RELOAD_WAIT)
    this.globalservice.setMetaData("SHOPS","LIST_SHOP_INQURIES")
    //this.titleService.setTitle(lang.TITLE_CUSTOMER_LIST_REQUEST);
    this.setDataTable();
    // this.clearSearch();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  
  search(event) {
    this.options.start = 0;
    this.setDataTable();
  }
  setDataTable(){
    this.record = [];
    var content = globalFunctions.stringifyFormData(this.options, null);
    var callApi = this.globalservice.callAPI(globalConstants.API_GET_INQUIRIES_URL, content);
    callApi.subscribe((data:any)=>{
      if(data.data!=undefined) {
        let moreRows='false';
        let firstList='false';
        this.globalservice.localGetterSetter("totalRecords",data.recordsTotal,SHOP_INQUIRIES);
        if(data.more_rows == "true") {
            moreRows="true";
        }
        this.globalservice.localGetterSetter("more_rows",moreRows,SHOP_INQUIRIES);

        if(data.start >= 0 && data.start <= 5) {
          firstList="true";
        }
        this.globalservice.localGetterSetter("first_list",firstList,SHOP_INQUIRIES);

        this.recordsTotal = data.data.length;

        if(data.data.length==undefined){
          this.AddRecord(data.data)
          this.dataSource = new MatTableDataSource(this.record)
          this.dataSource.sort = this.sort;
        } else {
          for(var i=0;i<data.data.length;i++){
            this.AddRecord(data.data[i])
          }
          this.dataSource = new MatTableDataSource(this.record)
          this.dataSource.sort = this.sort;
        }
        this.globalservice.localGetterSetter(null,this.options,SHOP_INQUIRIES);
      }else {
        this.dataSource = new MatTableDataSource(this.record)
          this.globalservice.localGetterSetter("totalRecords",'0',SHOP_INQUIRIES);
      }
    })
  }

  AddRecord(record) {
    var data = this.createRecord(record);
    this.record.push(data);
}

private createRecord(record) {
  return {
      inquiry_id: record.id,
      title: record.title,
      customer_name: record.first_name + " " + record.last_name,
      first_name: record.first_name,
      middle_name: record.middle_name,
      last_name: record.last_name,
      contact_number: record.mobile_phone ,
      customer_email: record.email,
      inquiry_date: this.globalservice.getFullFormateDate(record.add_date,'G','H'),
      status: this.statuses[record.status],
      check_status: record.status,
      read_status: record.read_status,
  };
}
  clearSearch() {
    this.gService.localGetterSetter(null,this.options,SHOP_INQUIRIES);
    this.openSearch = false;
    localStorage.setItem("openFilter",'');
    this.reinitializeOptions();
    this.setDataTable();
  }
  viewPart(getId){
    this.gService.localGetterSetter(this.options,null,SHOP_INQUIRIES);
      this.router.navigate(['/customer/view-request/', getId]);
  }
  scrolling(){
      this.globalservice.setScrolling('.car-datatable', 1000, -20);
}
  paginateByLimit(event){
    this.globalservice.setScrolling('body', 600, 0);
    this.options.limit = event.pageSize;
    this.options.start = 0;
    this.scrolling();
    // this.clearSearch();
    this.setDataTable();
  }
  reinitializeOptions (){
    this.gService.localGetterSetter(this.options,this.options,SHOP_INQUIRIES);
    this.options.user_id= this.currentCustomer.id;
    this.options.user_type= "User";
  }
  getTotalRecords(){
    return parseInt(localStorage.getItem(SHOP_INQUIRIES+'totalRecords'))
  }
  paginate(event,type){
    this.scrolling();
    if(type=="next") {
      this.options.start = this.options.start + this.options.limit;
    } else {
      this.options.start = this.options.start - this.options.limit;
    }
    this.setDataTable()
  }
  firstList() {
    return this.globalservice.firstList(SHOP_INQUIRIES,this.options);
  }
  lastList(){
    return this.globalservice.lastList(SHOP_INQUIRIES);
  }
  getUpperLimit(currentLastLimit,total) {
    if(currentLastLimit > total) {
      return total
    } else {
      return currentLastLimit
    }
  }

  viewInquiryDetails(row) {
    this.gService.localGetterSetter(this.options,null,SHOP_INQUIRIES);
    localStorage.setItem("shopInquiryID",row.inquiry_id)
    this.gService.getSetItems("shopInquiryReadStatus",row.read_status)
    this.router.navigate(['shops/view-shop-customer-inquiry']);
   }

   /***Check service request*****/
  isAnyOptionActive(button) {
      if (this.options.search_keyword == "" && this.options.inquiry_status == "") {
          if (button) {button.color = "primary"}
          return false
      }
      else {
          if (button) {button.color = "accent"}
          return true;
      }
  }
}

export interface InquireData {
    inquiry_id: string,
    title: string;
    customer_name: string;
    contact_number: string;
    customer_email: string;
    inquiry_date: string;
    status: string;
    first_name: string;
    last_name: string;
    middle_name: string;
    check_status: any;
    read_status: any;
}

