import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { GlobalService } from '../../../../environments/global.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
  public color: ThemePalette = 'accent';
  public isDisabled = false;
  public startDate;
  public endDate;
  public isChanged = false;
  public isInvalidDate = false;
  @Input() inputValueObj;
  @Output() SelectTime = new EventEmitter<any>();
  public subscriptionDataForAPI: Subscription = new Subscription();
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  constructor(public globalService: GlobalService) { }

  ngOnInit(): void {
    if (typeof(this.inputValueObj) !== 'undefined') {
      this.range.controls.start.setValue(this.inputValueObj.startDate);
      this.range.controls.end.setValue(this.inputValueObj.endDate);
    }

    this.subscriptionDataForAPI = this.globalService.isDateRange.subscribe((response) => {
       this.isDisabled = response.isDisabled;
       this.range.reset();
       this.clearErrors();
   });
  }

  handleDateRangeChange(): void {
    // Clear any existing error messages before re-validating
    this.clearErrors();
    this.isInvalidDate = false;
    // Validation for start date
    if (this.range.value.start == null || isNaN(Date.parse(this.range.value.start))) {
     this.isInvalidDate = true;
    }
    
    // Validation for end date
    if (this.range.value.end == null || isNaN(Date.parse(this.range.value.end))) {
      this.isInvalidDate = true;
    }

    //Validation for checking end date grater then start date 
    if (!this.isInvalidDate) {
      if ((this.range.value.start <= this.range.value.end)) {
        this.isInvalidDate = false;
      } else {
        this.isInvalidDate = true;
      }
  }

    if(this.isInvalidDate){
      this.SelectTime.emit(null);
    }else{
      this.SelectTime.emit(this.range.value);
    }
  }

  // Helper function to clear error messages
  clearErrors(): void {
    this.isInvalidDate = false;
  }

  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
  }  
}
