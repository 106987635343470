import { Component, OnInit, Inject } from '@angular/core';
import { globalConstants } from '../../../../environments/globalConstants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { globalFunctions } from '../../../../environments/globalFunctions';
import { IDatePickerConfig } from 'ng2-date-picker';
import { DatePipe } from '@angular/common';
import { INgxSelectOptions } from 'ngx-select-ex';
import * as moment from 'moment';
// const CustomSelectOptions: INgxSelectOptions = {
//   optionValueField: 'id',
//   optionTextField: 'text'
// };

@Component({
  selector: 'app-qqto-servicerequest',
  templateUrl: './qqto-servicerequest.component.html',
  styleUrls: ['./qqto-servicerequest.component.scss'],
  providers: [DatePipe]
})
export class QqtoServicerequestComponent implements OnInit {

  public user: any = null
  public param: any = {}
  public addservice: FormGroup;
  public customer: FormGroup;
  public vehicle: FormGroup;
  public datePickerConfig: IDatePickerConfig = {
    drops: "down",
    format: "MM/DD/YYYY hh:mm A",
    min: this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm a'),
    closeOnSelect: true,
    disableKeypress:true,
    unSelectOnClick:false
  };
  public fillCustomerData = true
  public shopDetails: any = null
  public countries;
  public states: any = [];
  public cities: any = [];

  public currentCustomerCountry;
  public currentCustomerState;
  public preferedCustomerState;
  public cityBlank: any = ''
  public phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public getCustomerTypes;
  public timezoneData = [];
  public shopTimezone: string='';
  public defaultCustomerType = '';
  public fillVehicleData=true
  public vehicleMileage:any='';
  public addNewColor=false
  public vehicleColor:any=''
  public carColors:any=[]
  public vehicleDetails:any={}
  public recomendationData:any={}
  public showCustForm:any=false
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public gs: GlobalService, public dialogRef: MatDialogRef<QqtoServicerequestComponent>, private form: FormBuilder, private datePipe: DatePipe) {
    this.gs.diaLogObj(this.dialogRef);
    this.user = this.gs.getCurrentUser()
    this.param = data
    this.carColors=globalFunctions.carColors()
  }

  ngOnInit() {
    let shopPer = this.gs.isSubscribe('quick_quote')

    if (!(shopPer)) {
      this.dialogRef.close()
    }
    this.getQQDetail(this.param)
    if(typeof this.param.vehicleData !='undefined' && typeof this.param.vehicleData.isValid !='undefined'){
      this.fillVehicleData=(this.param.vehicleData.isValid==2 ? true : false);
      this.vehicleMileage=this.param.vehicleData.mileage;
    }
    this.addservice = this.form.group({
      distance_covered: [this.vehicleMileage, [Validators.required]],
      request_date: [''],
      request_title: [''],
      request_notes: [''],
      qq_id: this.param.id,
      user_id: this.user.id,
    })
    this.vehicle = this.form.group({
      distance_covered: [this.vehicleMileage, [Validators.required, Validators.pattern("^[0-9]*$")]],
      vin: [''],
      production_date: [''],
      year: [''],
      make: [''],
      model: [''],
      sel_make: [''],
      sel_year: [''],
      sel_model: [''],
      sub_model: [''],
      license_plate: [''],
      state: [''],
      color: [''],
      select_color: [''],
      unit_number: [''],
      driver: [''],
      driver_phone_number: [''],
      mileage: [''],
      vehicle_registered_pic: [''],
      vehicle_insured_pic: [''],
      vehicle_pic: [''],
      vehicle_video: [''],
      registration_expiration_date_frm: [''],
      registration_expiration_date: [''],
      insurance_expiration_date_frm: [''],
      insurance_expiration_date: [''],
      vin_photo: [''],
      vehicle_id: this.param.car_id,
      customer_id: this.param.customer_id,
      qq_id: this.param.id,
      user_id: this.user.id,
    })
    let cDate = this.gs.getFullFormateDate(null, 'G', 'C');
    this.addservice.controls.request_date.setValue(cDate);
    this.customer = this.form.group({
      first_name: ['', [Validators.required]],
      middle_name: [''],
      last_name: ['', [Validators.required]],
      address_one: ['', Validators.required],
      city: [''],
      state: [''],
      frm_state: [''],
      country: [''],
      frm_country: [''],
      zipcode: ['', [Validators.required, Validators.pattern("^[0-9]+$")]],
      mobile_phone: ['', [Validators.required, Validators.minLength(10), this.gs.mobileNumberValidation]],
      customer_type: ['', Validators.required],
      timezone: ['',Validators.required],
      qq_id: this.param.id,
      user_id: this.user.id,
      customer_id:this.param.customer_id,
    })
    if (this.param && typeof this.param.fillcust_data != 'undefined' && this.param.fillcust_data) {
      this.showCustForm=true
    }
      this.getCustomerData()
    // }else if(this.fillVehicleData){
      this.getVehicleDetail()
    // }
  }
  onlyNumericKey(event) {
    return globalFunctions.onlyDecimalNumberKey(event);
  }
  disableKey(event) {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey == true && (event.which == '67' || event.which == '86' || String.fromCharCode(event.which).toLowerCase() == 's')) {
      event.preventDefault();
    }
  }
  /** convert quick quote to service request */
  createSR() {
    let data = this.addservice.value
    this.gs.validateState(this.addservice, ['request_date']);
    data['request_date']  = moment(data['request_date']).format('MM/DD/YYYY hh:mm A Z')
    if (this.addservice.valid) {
      this.gs.formData(globalConstants.API_QQ_TO_SERVICE_REQUEST, this.gs.formsData(data)).subscribe((r) => {
        if (r.result == 1) {
          this.gs.snackbar('success', r.message);
          this.dialogRef["request_id"]=r.data
          this.dialogRef.close(1)
        } else if (r.code == "524") {
          this.fillCustomerData = true
          this.gs.snackbar('error', r.message);
        } else {
          this.gs.snackbar('error', r.message);
        }
      }, (error) => { })
    } else {
      this.gs.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
  }
  /** get customer detail */
  getCustomerData() {
    var customerInfo = "user_id=" + this.user.id + "&user_type=" + this.user.user_type + "&customer_id=" + this.param.customer_id;
    this.gs.callAPI(globalConstants.API_GET_CUSTOMER_DETAILS, customerInfo).subscribe((r:any) => {
      if (r.result == 1) {
        var response = r.data;
        this.customer.controls.first_name.setValue(response.first_name);
        this.customer.controls.middle_name.setValue(response.middle_name);
        this.customer.controls.last_name.setValue(response.last_name);
        this.customer.controls.address_one.setValue(response.address1);
        this.customer.controls.city.setValue(response.city);
        this.customer.controls.state.setValue(response.state);
        this.customer.controls.country.setValue(response.country);
        this.customer.controls.zipcode.setValue(response.zip);
        this.customer.controls.customer_type.setValue(response.customer_type);
        this.customer.controls.mobile_phone.setValue(response.mobile_phone);
        this.customer.controls.timezone.setValue(response.time_zone);
        if(response.time_zone && response.time_zone!=''){
          this.shopTimezone = response.time_zone
        }
        this.fillCustomerData = true
        // this.getCountries()
        this.getShopInfo()
        this.getCustomerType()
      }
    }, (error) => { })
  }
  getShopInfo() {
    let param = "user_id=" + this.user.id + "&user_type=" + this.user.user_type + "&shop_id=" + this.user.shop_id;
    this.gs.callAPI(globalConstants.API_GET_SHOP_DETAILS_ON_DASHBOARD, param).subscribe((res:any) => {
      if (res.result == "1") {
        this.shopDetails = res.data;
        this.currentCustomerCountry = this.shopDetails.country_id
        if(this.shopTimezone==''){
          this.shopTimezone = this.shopDetails.time_zone
        }
        this.getCountries()
        // this.getStates(this.shopDetails.country_id)
        this.getTimezone(this.currentCustomerCountry)
      }
    }, (error) => { })

  }
  getCountries() {
    this.gs.callAPI(globalConstants.API_GET_COUNTRIES_LIST, "opr=1").subscribe((data:any) => {
      this.countries = data.data;
      /* FOR V2 SELECT */
      var clist = [];
      for (var i in this.countries) {
        this.countries[i].id = this.countries[i].country_id;
        this.countries[i].text = this.countries[i].country_name;
        clist.push(this.countries[i]);
      }
      this.countries = clist;
      let country = this.customer.value.country
      let shopCountry = clist.filter(list => list.country_name == country)
      // console.log(shopState)
      if (shopCountry.length > 0) {
        this.currentCustomerCountry = shopCountry[0].country_id;
      }
      this.getStates(this.currentCustomerCountry)
      /* END FOR V2 SELECT */
    }, (error) => { });
  }

  getSelectCountry(country_id) {
    // console.log(country_id)
    this.customer.controls.country.setValue(country_id);
    this.customer.controls.state.setValue('');
    this.customer.controls.city.setValue('');
    this.cities = []
    this.getStates(country_id)
    this.getTimezone(country_id)
    this.currentCustomerState = "";
  }

  getStates(country_id) {
    this.states = []
    this.gs.callAPI(globalConstants.API_GET_STATES_URL, "opr=1&country_id=" + country_id).subscribe((r:any) => {
      if (r.result == "1") {
        if (r.data && typeof r.data.length != 'undefined' && r.data.length) {
          this.states = r.data
          for (var i in this.states) {
            this.states[i].id = this.states[i].state_id;
            this.states[i].text = this.states[i].state_name;
          }
        } else {
          this.states[0] = r.data
          this.states[0].id = this.states[0].state_id;
          this.states[0].text = this.states[0].state_name;
        }
        let state = this.customer.value.state
        let shopstate = this.shopDetails.state
        let filterOn = ''
        if (shopstate != state && state && state.length) {
          filterOn = state
        } else if (shopstate && shopstate.length) {
          filterOn = shopstate
        }
        let shopStates = this.states.filter(state => state.state_name == filterOn)
        // console.log(shopState)
        if (filterOn && shopStates.length > 0) {
          this.currentCustomerState = shopStates[0].state_id;
          this.customer.controls.state.setValue(shopStates[0].state_name)
          this.getCities(shopStates[0].state_id);
        }
      }
    }, (error) => { })
  }

  getCities(opt,type?:any) {
    let shopState = this.states.filter(state => state.state_id == opt)
    if (shopState.length > 0) {
      this.currentCustomerState = shopState[0].state_id;
      this.customer.controls.state.setValue(shopState[0].state_name)
    }
    this.cities = []
    if(type=='reset'){
      this.customer.controls.city.setValue('');
    }
    this.gs.callAPI(globalConstants.API_GET_CITIES_URL, "opr=1&state_id=" + opt).subscribe((r:any) => {
      if (r.result == "1") {
        if (r.data && typeof r.data.length != 'undefined' && r.data.length) {
          this.cities = r.data
          for (var i in this.cities) {
            this.cities[i].id = this.cities[i].city_id;
            this.cities[i].text = this.cities[i].city_name;
          }
        } else {
          this.cities[0] = r.data
          this.cities[0].id = this.cities[0].city_id;
          this.cities[0].text = this.cities[0].city_name;
        }
        if(this.currentCustomerState==opt){
          let city = this.customer.value.city
          let shopCity= this.shopDetails.city
          let filterOn=''
          if(shopCity!=city && city && city.length){
            filterOn=city
          }else if(shopCity && shopCity.length){
            filterOn=shopCity
          }
          let shopcities = this.cities.filter(city => city.city_name ==filterOn )
          if (filterOn && shopcities.length > 0) {
            this.cityBlank = shopcities[0].city_id;
            this.customer.controls.city.setValue(shopcities[0].city_name);
          }
        }
      }
    }, (error) => { })

  }

  getSelectedOptionCity(opt) {
    let shopCity = this.cities.filter(city => city.city_id == opt)
    if (shopCity.length > 0) {
      this.cityBlank = shopCity[0].city_id;
      this.customer.controls.city.setValue(shopCity[0].city_name)
    }else{
      this.customer.controls.city.setValue(opt);
    }
  }
  /* GET CUSTOMER TYPES */
  getCustomerType() {
    var userInfo = "user_id=" + this.user.id + "&user_type=" + this.user.user_type + "&shop_id=" + this.user.shop_id;
    this.gs.callAPI(globalConstants.API_GET_CUSTOMER_TYPES, userInfo).subscribe((data:any) => {
      this.getCustomerTypes = data.data;
      var customerTypesList = [];
      for (var i in this.getCustomerTypes) {
        this.getCustomerTypes[i].id = this.getCustomerTypes[i].id;
        this.getCustomerTypes[i].text = this.getCustomerTypes[i].type;
        customerTypesList.push(this.getCustomerTypes[i]);
      }
      this.getCustomerTypes = customerTypesList;
      this.defaultCustomerType = this.getCustomerTypes[0].id
      //set default customer type to Standard
      this.customer.controls.customer_type.setValue(this.getCustomerTypes[0].id);
    })
  }
  /** get all timezone start */
  getTimezone(Id) {
    let data = { country_id: Id, user_type: this.user.user_type, user_id: this.user.id };
    this.gs.callAPI(globalConstants.API_GET_TIMEZONE, data).subscribe((zones:any) => {
      if (zones.result == '1') {
        this.timezoneData = zones.data;
        // handling if selected country is equal to shop country than set new customer timezone to shop timezone
        if (this.currentCustomerCountry) {
          if (Id === this.currentCustomerCountry) {
            this.customer.controls.timezone.setValue(this.shopTimezone);
          } else {
            this.customer.controls.timezone.setValue('');
          }
        }
      } else {
        this.timezoneData = [];
        this.customer.controls.timezone.setValue('');
      }
    });
  }
  /** get all timezone end */
  getSelectedOptionCustomerType(opt) {
    this.customer.controls.customer_type.setValue(opt);
  }
  getSelectedOptionTimezone(opt) {
    this.customer.controls.timezone.setValue(opt);
  }
  submitCustomer() {
    this.gs.validateState(this.customer, ['city','state','country']);
    Object.keys(this.customer.controls).map((controlName) => {
      this.customer.get(controlName).markAsTouched({onlySelf: true});
    });
    if (this.customer.valid) {
      var data = new FormData();
      for (let i in this.customer.value) {
        data.append(i, this.customer.value[i]);
      }

      this.gs.formData(globalConstants.API_EDIT_CUSTOMER_URL, data).subscribe((data) => {
        if (data.result == 1) {
          // this.gs.snackbar('success', data.message);
          this.fillCustomerData = false
          if(typeof this.param.vehicleData !='undefined' && typeof this.param.vehicleData.isValid !='undefined'){
            // this.fillVehicleData=(this.param.vehicleData.isValid==2 ? true : false);
            // if(this.fillVehicleData){
            //   this.getVehicleDetail()
            // }
            this.submitCar()
          }
        } else {
          this.gs.snackbar('error', data.message);
        }
      }, (error) => { });
    }else{
      this.gs.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
    // console.log(this.customer.value)
  }
 
  carValid(){
    this.checkBeforeSubmit()
  }
  getVehicleDetail(){
    let data={"customer_id" :this.param.customer_id, "vehicle_id" : this.param.car_id}
    this.gs.callAPI(globalConstants.API_GET_CUSTOMER_VEHICLES_URL, data).subscribe((data:any) => {
      if (data.result == 1) {
        this.vehicleDetails = data.data;
        let registrationDateFromDB = this.vehicleDetails.registration_expiration_date;
        if (registrationDateFromDB != '0000-00-00 00:00:00' && registrationDateFromDB != "") {
          registrationDateFromDB = this.gs.getFullFormateDate(registrationDateFromDB, 'L', 'B');
          this.vehicle.controls.registration_expiration_date_frm.setValue(new Date(registrationDateFromDB));
          this.vehicle.controls.registration_expiration_date.setValue(this.vehicleDetails.registration_expiration_date);
        }

        let insuranceDateFromDB = this.vehicleDetails.insurance_expiration_date;
        if (insuranceDateFromDB != '0000-00-00 00:00:00' && insuranceDateFromDB != "") {
          insuranceDateFromDB = this.gs.getFullFormateDate(insuranceDateFromDB, 'L', 'B');
          this.vehicle.controls.insurance_expiration_date_frm.setValue(new Date(insuranceDateFromDB));
          this.vehicle.controls.insurance_expiration_date.setValue(this.vehicleDetails.insurance_expiration_date);
        }

        this.vehicle.controls.driver.setValue(this.vehicleDetails.driver)
        if (this.vehicleDetails.production_date) {
          this.vehicle.controls.production_date.setValue(this.datePipe.transform(this.vehicleDetails.production_date, 'MM/yyyy'));
        }
        this.vehicle.controls.distance_covered.setValue(this.vehicleDetails.distance_covered);
        this.vehicle.controls.make.setValue(this.vehicleDetails.make);
        this.vehicle.controls.sel_make.setValue(this.vehicleDetails.make);
        this.vehicle.controls.year.setValue(this.vehicleDetails.year);
        this.vehicle.controls.sel_year.setValue(this.vehicleDetails.year);
        this.vehicle.controls.model.setValue(this.vehicleDetails.model);
        this.vehicle.controls.sel_model.setValue(this.vehicleDetails.model);
        this.vehicle.controls.mileage.setValue(this.vehicleDetails.mileage);
        this.vehicle.controls.license_plate.setValue(this.vehicleDetails.license_plate);
        this.vehicle.controls.vin.setValue(this.vehicleDetails.vin);
        this.vehicle.controls.state.setValue(this.vehicleDetails.state);
        this.vehicle.controls.color.setValue(this.vehicleDetails.color);
        this.vehicleColor = this.vehicleDetails.color;
        this.getCustomerCarState()
        this.checkExistingColor()
      }
    },(error)=>{ })
  }
  getCustomerCarState() {
    var statesOpr = "country_id=" + this.vehicleDetails.country_id + "&opr=1";
    this.gs.callAPI(globalConstants.API_GET_STATES_URL, statesOpr).subscribe((data:any) => {
      this.states = data.data;
      var slist = [];
      for (var i in this.states) {
        this.states[i].id = this.states[i].state_name;
        this.states[i].text = this.states[i].state_name;
        slist.push(this.states[i]);
      }
      this.states = slist;
    });
  }
  getSelectedOptionState(opt) {
    this.vehicle.controls.state.setValue(opt);
    this.carValid();
  }
  checkExistingColor() {
    for (var i = 0; i < this.carColors.length; i++) {
      if (this.carColors[i].id == this.vehicleColor) {
        this.addNewColor = false;
        return;
      } else {
        //this.addNewColor = true;
      }
    }
  }
  getSelectedColor(opt){
    this.vehicleDetails.color=opt
    this.vehicle.controls.select_color.setValue(opt);
    this.vehicle.controls.color.setValue(opt);
    this.carValid();
  }
  submitCar() {
    this.checkBeforeSubmit()
    if (this.vehicle.valid) {
      let content = this.vehicle.value
      this.gs.callAPI(globalConstants.API_EDIT_VEHICLE_URL, content).subscribe((r:any) => {
        if (r.result == "1") {
          // this.gs.snackbar('success', r.message);
          //this.vehicleMileage=this.vehicle.value.distance_covered
          // this.addservice.controls.distance_covered.setValue(this.vehicleMileage);
          // this.fillVehicleData=false
          this.createSR()
        } else {
          this.gs.snackbar('error', r.message);
        }
      }, (error) => {  });
    }else{
      this.gs.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
  }
  checkBeforeSubmit() {
    let validCar = false;
    let lp = this.gs.isFleet()
    if (this.vehicleDetails.is_other_make == 1) {
      validCar = true;
    }
    if (validCar) {
      this.vehicle.get('vin').setValidators(null);
      this.vehicle.get('color').setValidators(null);
      this.vehicle.get('license_plate').setValidators(null);
      this.vehicle.get('state').setValidators(null);
    } else {
      this.vehicle.get('color').setValidators([Validators.required]);
      this.vehicle.get('vin').setValidators([Validators.required]);
      this.vehicle.get('license_plate').setValidators([Validators.required]);
      this.vehicle.get('state').setValidators([Validators.required]);
    }
    for (const key in this.vehicle.controls) {
        this.vehicle.get(key).updateValueAndValidity({ onlySelf: false, emitEvent: false});
    }
  }
  submitForms(){
    if(this.showCustForm && this.fillCustomerData){
      this.submitCustomer()      
    }else if(this.fillVehicleData){
      this.submitCar()
    }else{
      this.createSR()
    }
  }
  /** get quick quote detail  */
  getQQDetail(param){
    let data={quote_id:param.id,user_id: this.user.id}
    this.gs.callAPI(globalConstants.API_QQ_DETAIL, data).subscribe((r:any) => {
      if(r.result==1){
        this.recomendationData=r.data
        if(this.recomendationData.notes){
          this.addservice.controls.request_notes.setValue(this.recomendationData.notes)
        }
      }else{
        this.gs.snackbar("error", r.message);
      }
    }, (error) => { })
  }

}
