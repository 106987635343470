import { Component, OnInit, ViewChild, Output, Input, EventEmitter, AfterViewInit } from '@angular/core';
import { GlobalService } from '../../../../environments/global.service';
import { MatDrawer } from '@angular/material/sidenav';
import { globalFunctions } from '../../../../environments/globalFunctions';
import { globalConstants } from '../../../../environments/globalConstants';
@Component({
  selector: 'app-excluded-vehicles',
  templateUrl: './excluded-vehicles.component.html',
  styleUrls: ['./excluded-vehicles.component.scss'],
})

export class ExcludedVehiclesComponent implements OnInit, AfterViewInit {
  @ViewChild('excludedVehicesDrawer') excludedVehicesDrawer: MatDrawer;
  @Input()  excludedVehicles;
  @Output() closeTab = new EventEmitter<any>();
  public options = {
    user_id: '',
    user_type: "User"
  }
  constructor(public globalService: GlobalService) {
    this.options.user_id = JSON.parse(localStorage.getItem("loggedInUser")).id;
  }

  ngOnInit() {  

  }

  ngAfterViewInit() {
    this.excludedVehicesDrawer.toggle();
  }

  removeVehicle(index:number){
    this.globalService.confirmDialog('delTech', "Are you sure?").subscribe((r) => {
      if (r != null) {
        let content = globalFunctions.stringifyFormData({user_id:this.options.user_id,user_type:this.options.user_type,id:this.excludedVehicles[index].id}, null);
        let callApi = this.globalService.callAPI(globalConstants.API_DELETE_EXCLUDED_VEHICLE, content);
        callApi.subscribe((data:any) => {
          if('result' in data && data.result=='1'){
            this.excludedVehicles.splice(index, 1);
            const output = {
              'changed': 1,
              'selectedVehicles': this.excludedVehicles
            }
            setTimeout(() => {
              this.closeTab.emit(output);
            }, 1000);
          }else {
            this.globalService.snackbar("error", data.message);
          };
        });     
      }
    });
  }

  closeSideBar(isChanged: number = 0) {
    const output = {
      'changed': isChanged,
      'selectedVehicles': this.excludedVehicles
    }
    if(isChanged !== 2){
      this.excludedVehicesDrawer.toggle();
      setTimeout(() => {
        this.closeTab.emit(output);
      }, 1000);
    }else{
      this.closeTab.emit(output);
    }
  }
}


