import { Component, OnInit, ViewChild} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap,NavigationEnd, Params  } from '@angular/router';
import { Http,Headers, Response} from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { globalFunctions } from "../../../../environments/globalFunctions";
import { globalConstants } from "../../../../environments/globalConstants";
import {GlobalService} from "../../../../environments/global.service";
import { lang } from "../../../../environments/lang-en";
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TextMaskModule } from 'angular2-text-mask';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppComponent } from "../../../app.component";
import { LoaderService } from '../../../base/services/LoaderService';

@Component({
  selector: 'app-addshopresource',
  templateUrl: './addshopresource.component.html',
  styleUrls: ['./addshopresource.component.scss']
})
export class AddshopresourceComponent implements OnInit {
    public currentCustomer;
    public currentCustomerName;
    public isCollapsed:boolean = true;
    public addResourceForm:FormGroup;

    constructor(
        public app:AppComponent,
        private activatedRoute:ActivatedRoute,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        public titleService:Title,
        private http: Http,
        private router: Router,
        public snackBar: MatSnackBar, 
        public globalService: GlobalService,
        private loaderService: LoaderService
        ){
        if(localStorage.getItem("loggedInUser")) {
            this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
            this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
            this.addResourceForm = this.formBuilder.group({
                resource_name: ['', [Validators.required]],
                //resource_link: ['', [Validators.required, Validators.pattern(/^(ftp|http|https):\/\/[^ "]+$/)]],
                resource_link: ['', [Validators.required,Validators.pattern(globalConstants.WEB_URL_REGEX)]],
                user_type: this.currentCustomer.user_type,
                user_id: this.currentCustomer.id,
                shop_id: this.currentCustomer.shop_id,
                left_menu_status: [''],
            })
        } else {
            this.router.navigate(["/shops"]);
        }
        setTimeout(()=>{
        let isEnable=this.globalService.getAcl('resources','addNew');
        if(!(isEnable)){
            this.router.navigate(['shops/action/forbidden']);
        }
    },globalConstants.RELOAD_WAIT)
    this.globalService.setMetaData("SHOPS","ADD_NEW_RESOURCE")
    }

    ngOnInit() {
        this.loaderService.hide();
    }
    
    addResource() {
        if(this.addResourceForm.valid) {
            var stringifiedData =this.globalService.formsData(this.addResourceForm.value);
            this.globalService.formData(globalConstants.API_ADD_RESOURCE_URL,stringifiedData).subscribe((data:any)=> {
                var message = data.message;
                if (data.result == "1") {
                    this.router.navigate(['shops/list-resources']);
                    this.app.addNewItemToMenuItemShopResources(data.data);
                    this.globalService.snackbar('success', message);
                } else {
                    this.globalService.snackbar("error", message);
                }
            });
        } else {
            this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
        }
    }
    
    checkLeftMenuStatus(event) {
        if (event.checked) {
            this.addResourceForm.controls.left_menu_status.setValue(1);
        } else {
            this.addResourceForm.controls.left_menu_status.setValue(0);
        }
    }

}
