<form class="form-horizontal change-disclaimer-form" autocomplete="off">
    <div mat-dialog-title class="relative">
        Change Disclaimer
        <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
            <mat-icon class="close-icon-lg">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="change-disclaimer-content">
        <div class="table-responsive relative padding-bottom-10 change-disclaimer-table">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th class="title-column">Title</th>
                        <th class="description-column">Description</th>
                        <th class="actions-column text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let payment of dataSources.data; let i=index ">
                        <td class="title-column">{{payment.title}}</td>
                        <td class="description-column" title="{{payment.description}}"><span class="text-ellipsis">{{getLimitedText(payment.description)}}</span></td>
                        <td class="actions-column text-center apply-btn"><button class="btn " [class.btn-success]="disclaimer_id==payment.id" (click)="applyChange(i, payment.id)" >Apply</button></td>
                </tr>
                <tr *ngIf="dataSources.data.length==0">
                    <td colspan="3" class="norecords-row">No Records Found.</td>
                </tr>
                </tbody>
            </table>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="inquiry-action relative change-disclaimer-action">
        <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
    </mat-dialog-actions>
</form>
