<div mat-dialog-title class="relative" >
    <span [ngClass]="preAuthorizationPopUp == true ? 'hidden':''" *ngIf="jobStatus==false">How approval is received? </span>
    <span [ngClass]="preAuthorizationPopUp == true ? 'hidden':''" *ngIf="jobStatus==true">Identify Decision Maker</span>
    <span [ngClass]="preAuthorizationPopUp == true ? '':'hidden'">Prior authorization details</span>
    <span class="sr-id-subHeading hidden-xs">#{{fullRequestId}}</span>
    <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0 ra-close">
        <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button> 
</div>
<!-- BODY -->
<mat-dialog-content class="require-approval">
    <span class="visible-xs srId-show">Request Id - #{{fullRequestId}}</span>
    <div [ngClass]="preAuthorizationPopUp == true ? 'hidden':''">
    <mat-radio-group class="example-radio-group" [(ngModel)]="approval_val" [ngModelOptions]="{standalone: true}">
        <mat-radio-button (change)="changeForm($event)" class="example-radio-button margin-right-20" *ngFor=" let list of approvalList " [value]=" list.id ">
            {{ list.name }}
        </mat-radio-button>
    </mat-radio-group>
    
    <!-- FORM ONE -->
    <div class="margin-top-15" *ngIf="approval_val == 1">
        <form class="form-horizontal form-control-normal" autocomplete="off" [formGroup]="addApprovalOneForm" (ngSubmit)="addApproval(approval_val)">
            <div class="col-xs-12 form-block">                
                <div class="form-group ">
                    
                    <div class="col-sm-12 col-md-4">
                        <label for="part_name">Who was the person?</label>
                        <div class='relative'>
                            <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                <input matInput placeholder="Name" title="Name" class="form-control input-lg custom-form-input" formControlName="full_name" [matAutocomplete]="auto" (keyup)="checkIfValid(approval_val)"/>
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)= "setOptionAsContact($event,approval_val)" [displayWith]="displayFn.bind(this)">
                                  <mat-option *ngFor="let option of filterOptionsSet | async" [value]="option">
                                    {{ option.contact_name }}
                                  </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="addApprovalOneForm.controls.full_name.hasError('required')">
                                    Name is <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-md-4">
                        <label for="part_name">Phone number?</label>
                        <div class='relative'>
                            <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill" hideRequiredMarker="false">
                                <input matInput (keyup)="phoneValidation('phone_number',approval_val)" placeholder="Phone Number" title="Phone Number" formControlName="phone_number" class="form-control input-lg custom-form-input" (keyup)="checkIfValid(approval_val)" maxlength="15" [textMask]="{mask: phoneMask}" [(ngModel)]="mobileNumber">
                                <!--<mat-error *ngIf="addApprovalOneForm.controls.phone_number.hasError('required')">
                                    Phone number is <strong>required</strong>
                                </mat-error>-->
                                <mat-error *ngIf="addApprovalOneForm.controls.phone_number.hasError('phone_number')">
                                    Invalid phone number
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-md-4">
                        <label for="part_name">Date/Time</label>
                        <div class="vlio-datepicker relative">
                            <dp-date-picker data-position="center" mode="daytime" [config]="datePickerConfig" theme="dp-material dp-main" formControlName="date_time_of_call" class="btn-block" (keyup)="checkIfValid(approval_val);" #dayPicker></dp-date-picker>
                            <button type="button" mat-icon-button (click)="dayPicker.api.open();">
                              <mat-icon>date_range</mat-icon>
                            </button>
                        </div>
                        <mat-error *ngIf="addApprovalOneForm.controls.date_time_of_call.hasError('required')">
                            Date/Time is <strong>required</strong>
                        </mat-error>
                    </div>
                    
                </div>
            </div>
            <div class="display-full margin-bottom-5">
                <div class="col-sm-12 text-right idm-actionBtns">
                    <button type="button" mat-raised-button class="font-bold" color="accent" title="USE PRIOR AUTHORIZATION" *ngIf="preAuthorization==true" (click)="goForPriorAuthorization()">
                        USE PRIOR AUTHORIZATION
                    </button>
                    <button type="submit" mat-raised-button  class="font-bold" color="accent" title="SAVE">
                        SAVE
                    </button>
                    <button mat-raised-button mat-dialog-close class="font-bold" color="accent" title="BYPASS" *ngIf="allowBypassBtn=='1'&&jobStatus==true" (click)="bypassApproval();">BYPASS</button>
                    <button mat-raised-button mat-dialog-close class="font-bold" title="CLOSE">CLOSE</button>
                </div>
            </div>
        </form>
    </div>
    
    <!-- FORM TWO -->
    <div class="margin-top-15" *ngIf="approval_val == 2">
        <form class="form-horizontal form-control-normal" autocomplete="off" [formGroup]="addApprovalTwoForm" (ngSubmit)="addApproval(approval_val)">
            <div class="col-xs-12 form-block">                
                <div class="form-group  ">
                    <div class="col-sm-12 col-md-4">
                        <label for="part_name">Who did you speak to?</label>
                        <div class='relative'>
                            <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                <input matInput placeholder="Name" title="Name" class="form-control input-lg custom-form-input" formControlName="full_name" [matAutocomplete]="auto"  style=" height: 35px;" (keyup)="checkIfValid(approval_val)"/>
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)= "setOptionAsContact($event,approval_val)" [displayWith]="displayFn.bind(this)">
                                  <mat-option *ngFor="let option of filterOptionsSet | async" [value]="option">
                                    {{ option.contact_name }}
                                  </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="addApprovalTwoForm.controls.full_name.hasError('required')">
                                    Name is <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-md-4">
                        <label for="part_name">Phone number?</label>
                        <div class='relative'>
                            <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill" hideRequiredMarker="false">
                                <input matInput (keyup)="phoneValidation('phone_number',approval_val)" placeholder="Phone Number" title="Phone Number" formControlName="phone_number" class="form-control input-lg custom-form-input" (keyup)="checkIfValid(approval_val)" maxlength="15" [textMask]="{mask: phoneMask}" [(ngModel)]="mobileNumber">
                                <mat-error *ngIf="addApprovalTwoForm.controls.phone_number.hasError('required')">
                                    Phone number is <strong>required</strong>
                                </mat-error>
                                <mat-error *ngIf="addApprovalTwoForm.controls.phone_number.hasError('phone_number')">
                                    Invalid phone number
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-md-4">
                        <label for="part_name">Date/Time of call</label>
                        <div class="vlio-datepicker relative">
                            <dp-date-picker data-position="center" mode="daytime" [config]="datePickerConfig" theme="dp-material dp-main" formControlName="date_time_of_call" class="btn-block" (keyup)="checkIfValid(approval_val)" #dayPicker></dp-date-picker>
                            <button type="button" mat-icon-button (click)="dayPicker.api.open();">
                              <mat-icon>date_range</mat-icon>
                            </button>
                        </div>
                        <mat-error *ngIf="addApprovalTwoForm.controls.date_time_of_call.hasError('required')">
                            Date Time of call is <strong>required</strong>
                        </mat-error>
                    </div>
                </div>
            </div>
            <div class="display-full margin-bottom-5">
                <div class="col-sm-12 text-right idm-actionBtns">
                    <button type="submit" mat-raised-button  color="accent" title="SAVE">
                        SAVE
                    </button>
                    <button mat-raised-button mat-dialog-close class="font-bold" color="accent" title="BYPASS" *ngIf="allowBypassBtn=='1'" (click)="bypassApproval();">BYPASS</button>
                    <button mat-raised-button mat-dialog-close class="font-bold" title="CLOSE">CLOSE</button>
                </div>
            </div>
        </form>
    </div>
    
    <!-- FORM THREE -->
    <div class="margin-top-15" *ngIf="approval_val == 3">
        <form class="form-horizontal form-control-normal" autocomplete="off" [formGroup]="addApprovalThreeForm" (ngSubmit)="addApproval(approval_val)">
            <div class="col-xs-12 form-block">                
                <div class="form-group ">
                    <div class="col-sm-12 col-md-4">
                        <label for="part_name">Who did you speak to?</label>
                        <div class='relative'>
                            <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                <input matInput placeholder="Name" title="Name" class="form-control input-lg custom-form-input" formControlName="full_name" [matAutocomplete]="auto"  style=" height: 35px;" (keyup)="checkIfValid(approval_val)"/>
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)= "setOptionAsContact($event,approval_val)" [displayWith]="displayFn.bind(this)">
                                  <mat-option *ngFor="let option of filterOptionsSet | async" [value]="option">
                                    {{ option.contact_name }}
                                  </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="addApprovalThreeForm.controls.full_name.hasError('required')">
                                    Name is <strong>required</strong>
                                </mat-error>
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <label for="part_name">Phone number?</label>
                        <div class='relative'>
                            <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill" hideRequiredMarker="false">
                                <input matInput (keyup)="phoneValidation('phone_number',approval_val)" placeholder="Phone Number" title="Phone Number" formControlName="phone_number" class="form-control input-lg custom-form-input" (keyup)="checkIfValid(approval_val)" maxlength="15" [(ngModel)]="mobileNumber">
                                <mat-error *ngIf="addApprovalThreeForm.controls.phone_number.hasError('required')">
                                    Phone number is <strong>required</strong>
                                </mat-error>
                                <mat-error *ngIf="addApprovalThreeForm.controls.phone_number.hasError('phone_number')">
                                    Invalid phone number
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-4">
                        <label for="part_name">Date/Time of TEXT</label>
                        <div class="vlio-datepicker relative">
                            <dp-date-picker data-position="center" mode="daytime" [config]="datePickerConfig" theme="dp-material dp-main" formControlName="date_time_of_call" class="btn-block" (keyup)="checkIfValid(approval_val);" #dayPicker></dp-date-picker>
                            <button type="button" mat-icon-button (click)="dayPicker.api.open();">
                              <mat-icon>date_range</mat-icon>
                            </button>
                        </div>
                        <mat-error *ngIf="addApprovalThreeForm.controls.date_time_of_call.hasError('required')">
                            Date Time of call is <strong>required</strong>
                        </mat-error>
                    </div>
                </div>
            </div>
            <div class="display-full margin-bottom-5">
                <div class="col-sm-12 text-right idm-actionBtns">
                    <button type="submit" mat-raised-button  color="accent" title="SAVE">
                        SAVE
                    </button>
                    <button mat-raised-button mat-dialog-close class="font-bold" color="accent" title="BYPASS" *ngIf="allowBypassBtn=='1'" (click)="bypassApproval();">BYPASS</button>
                    <button mat-raised-button mat-dialog-close class="font-bold" title="CLOSE">CLOSE</button>
                </div>
            </div>
        </form>
    </div>
    
    <!-- FORM FOUR -->
    <div class="margin-top-15" *ngIf="approval_val == 4">
        <form class="form-horizontal form-control-normal" autocomplete="off" [formGroup]="addApprovalFourForm" (ngSubmit)="addApproval(approval_val)">
            <div class="col-xs-12 form-block"> 
                <div class="form-group">
                    
                    <div class="col-sm-12 col-md-4">
                        <label for="part_name">Who did you speak to?</label>
                        <div class='relative'>
                            <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                <input matInput placeholder="Name" title="Name" class="form-control input-lg custom-form-input" formControlName="full_name" [matAutocomplete]="auto"  style=" height: 35px;" (keyup)="checkIfValid(approval_val)"/>
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)= "setOptionAsContact($event,approval_val)" [displayWith]="displayFn.bind(this)">
                                  <mat-option *ngFor="let option of filterOptionsSet | async" [value]="option">
                                    {{ option.contact_name }}
                                  </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="addApprovalThreeForm.controls.full_name.hasError('required')">
                                    Name is <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-md-4 datepicker-section">
                        <label for="part_name">Date/Time of call</label>
                        <div class="vlio-datepicker relative">
                            <dp-date-picker data-position="center" mode="daytime" [config]="datePickerConfig" theme="dp-material dp-main" formControlName="date_time_of_call" class="btn-block" (keyup)="checkIfValid(approval_val);" #dayPicker></dp-date-picker>
                            <button type="button" mat-icon-button (click)="dayPicker.api.open();">
                              <mat-icon>date_range</mat-icon>
                            </button>
                        </div>
                        <mat-error *ngIf="addApprovalThreeForm.controls.date_time_of_call.hasError('required')">
                            Date Time of call is <strong>required</strong>
                        </mat-error>
                    </div>
                    
                    <div class="col-sm-12 col-md-12">
                        <label for="part_name">Details/Description</label>
                        <div class='relative details-input'>
                            <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                <textarea matInput placeholder="Details/Description" title="Details/Description" formControlName="other" rows="5" (keyup)="checkIfValid(approval_val);" #notes maxlength="1000"></textarea>
                                <mat-hint align="end">{{notes.value?.length || 0}}/1000 Characters Max</mat-hint>
                                <mat-error *ngIf="addApprovalFourForm.controls.other.hasError('required')">
                                    This field can not be empty
                                </mat-error>
                            </mat-form-field>
                            <!--<mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill" hideRequiredMarker="false">
                                <input matInput placeholder="Other" title="Other" formControlName="other" class="form-control input-lg custom-form-input" (keyup)="checkIfValid(approval_val);">
                                <mat-error *ngIf="addApprovalFourForm.controls.other.hasError('required')">
                                    This field can not be empty
                                </mat-error>
                            </mat-form-field>-->
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="display-full margin-bottom-5">
                <div class="col-sm-12 text-right idm-actionBtns">
                    <button type="submit" mat-raised-button  color="accent" title="SAVE">
                        SAVE
                    </button>
                    <button mat-raised-button mat-dialog-close class="font-bold" color="accent" title="BYPASS" *ngIf="allowBypassBtn=='1'" (click)="bypassApproval();">BYPASS</button>
                    <button mat-raised-button mat-dialog-close class="font-bold" title="CLOSE">CLOSE</button>
                </div>
            </div>
        </form>
    </div>
    </div>
    <div [ngClass]="preAuthorizationPopUp == true ? '':'hidden'">
        <div class="row">
            <div class="col-sm-12 col-md-12 margin-bottom-10">
                <label class="col-sm-3">Authorize By</label>
                <div class="col-sm-9">{{authorizeBy || '-' }}</div>
            </div>
            <div class="col-sm-12 col-md-12 margin-bottom-10">
                <label class="col-sm-3">Contact Person</label>
                <div class="col-sm-9">{{contactPerson || '-' }}</div>
            </div> 
            <div class="col-sm-12 col-md-12 margin-bottom-10">
                <label class="col-sm-3">Phone number</label>
                <div class="col-sm-9">{{contactPersonPhone || '-' }}</div>
            </div>
            <div class="col-sm-12 col-md-12 margin-bottom-10" *ngIf="otherMeansOfContact==''">
                 <label class="col-sm-3">Date/Time</label>
                <div class="col-sm-9">{{dateTimeOfCall}}</div>                
            </div>
            <div class="col-sm-12 col-md-12 margin-bottom-10" *ngIf="otherMeansOfContact!=''">
                 <label class="col-sm-3">Details/Description</label>
                <div class="col-sm-9">{{otherMeansOfContact || '-' }}</div>                
            </div> 
        </div>
        <div class="display-full margin-bottom-5">
            <div class="col-sm-12 text-right idm-actionBtns">
                <button type="button" mat-raised-button  class="font-bold" color="accent" title="CONTINUE" (click)="submitPriorAuthorization()">CONTINUE</button>
                <button type="button" mat-raised-button class="font-bold" title="CANCEL" (click)="goCancel()">CANCEL</button>
            </div>
        </div>
    </div>
</mat-dialog-content>

<!-- END BODY -->
