import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { Http } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { globalConstants } from '../../../../environments/globalConstants';

@Component({
  selector: 'app-admin-smart-batch-assign',
  templateUrl: './admin-smart-batch-assign.component.html',
  styleUrls: ['./admin-smart-batch-assign.component.scss']
})
export class AdminSmartBatchAssignComponent implements OnInit {

  public batches:any=null;
  public user:any=null;
  public part:any=null;
  public showCostPrice=false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AdminSmartBatchAssignComponent>, private gs: GlobalService,public http:Http) {
    this.batches = data;
  }

  ngOnInit() {
    this.user = this.gs.getAdmin();
     this.gs.diaLogObj(this.dialogRef);
  }
  getSelectedOption(opt) {
    if (typeof opt == "object") {
      this.part = {
        part_id: opt.id,
        batch_id:this.batches.id,
        user_id: this.user.id,
        user_type: this.user.user_type
      };
    }
  }
  observableParts = (keyword: any): Observable<any[]> => {
    let url: string = globalConstants.API_MASTER_PART_LIST_SMART_BATCH_AUTOCOMPLETE + "?user_id=" + this.user["id"] + "&query=" + keyword;
    if (keyword) {
      let f = [];
      return this.http.get(url)
        .map(res => {
          let json = res.json();
          let show=this.showCostPrice;
          this.gs.authText(json);
          if (json != null) {
            json.forEach(function (x) {
              x.value = x.name;
              x.name = x.name;
              x.name+=(show?' '+x.price:'');
              x.quantity = 1;
              f.push(x)
            }
            );
          }
          return f
        })
    } else {
      return Observable.of([]);
    }
  }
  myListFormatter(data: any): string {
    return data['name'];
  }
  setReturnFalse() {
    return false
  }
  submit(type){
    if((!this.part) || (typeof this.part.part_id=='undefined')){
      return false;
    }
    this.gs.callAPI(globalConstants.API_MASTER_ASSIGN_PART_TO_SMART_BATCH_NUMBER,this.part).subscribe((res:any)=>{
      var message = res.message;
      if(res.result==1){
        this.gs.snackbar('success', message);
        (<HTMLInputElement>document.getElementById("new-part-qty")).value = "";
        this.part = {};
        this.batches.ref.setDataTable();
        if(type==2){
          this.dialogRef.close(0);
        }
      } else {
        this.gs.snackbar('error', message);
      }
    },(error)=>{})
  }

}
