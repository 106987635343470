import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort} from '@angular/material/sort';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { ViktableComponent, VikSource } from '../../../admin/viktable/viktable.component';
import { ExtrafieldsComponent } from '../extrafields/extrafields.component';
import { globalFunctions } from '../../../../environments/globalFunctions';

const EXTRA_FIELD = "shop-extrafield-";

@Component({
  selector: 'app-extrafield-list',
  templateUrl: './extrafield-list.component.html',
  styleUrls: ['./extrafield-list.component.scss']
})
export class ExtrafieldListComponent extends ViktableComponent implements OnInit {

  public user: any = null
  dataSource: any;
  public tablesData = this;
  public checkFormEmpty = false;
  public searchEnabled: boolean = false;
  public displayedColumns = ['ID', 'Name', 'Type', 'is_mandatory', 'Actions']
  public fieldType = globalFunctions.customFieldType()

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(public gs: GlobalService, public dialogRef: MatDialogRef<ExtrafieldListComponent>, public dialog: MatDialog) {
    super(globalConstants.API_GET_CUSTOM_FIELD_LIST, gs);
    this.gs.diaLogObj(this.dialogRef);
    this.user = this.gs.getCurrentUser()
    if (this.user) {
      this.sessionKey = EXTRA_FIELD;
      this.url = globalConstants.API_GET_CUSTOM_FIELD_LIST;
    } else {
      this.dialogRef.close();
    }
  }


  ngOnInit() {
    this.reInitOptions()
    
  }

  ngAfterViewInit(){
    this.setDataTable();
  }

  reInitOptions() {
    this.options.user_type = this.user.user_type;
    this.options.user_id = this.user.id;
  }
  setDataTable() {
    this.dataSource = new VikSource(this.tablesData, this.sort, this.paginator);
    this.dataSource.getSortedData = this.sortedData;
  }
  createRecordrecord(r) {
    return {
      id: r.id,
      type: this.fType(r.type),
      name: r.label,
      is_mandatory: r.is_mandatory,
      added_at: this.convertDate(r.add_date)
    };
  }
  sortedData(): any[] {
    let th: any = this;
    const data = th._exampleDatabase.data.slice();
    if (!th._sort.active || th._sort.direction == '') { return data; }
    return data.sort((a, b) => {
      let propertyA;
      let propertyB;
      switch (th._sort.active) {
        case 'ID': propertyA = parseInt(a.id); propertyB =parseInt(b.id); break;
        case 'Name': propertyA = a.name; propertyB = b.name; break;
        case 'Type': propertyA = a.type; propertyB = b.type; break;
        case 'is_mandatory': propertyA = a.is_mandatory; propertyB = b.is_mandatory; break;
        case 'added': propertyA=new Date(a.added_at);propertyB=new Date(b.added_at);break;
        default: return 0;
      }
      if(propertyA=='Invalid Date' || propertyB=='Invalid Date'){
        let date=-1;
        if(propertyB=='Invalid Date'){
            date=1;
        }
        return (date * (th._sort.direction == 'asc' ? 1 : -1));
      }
      
      let valueA = isNaN(propertyA) && propertyA ? propertyA.toUpperCase() : propertyA;
      let valueB = isNaN(propertyB) && propertyB ? propertyB.toUpperCase() : propertyB;

      return (valueA < valueB ? -1 : 1) * (th._sort.direction == 'asc' ? 1 : -1);
    });
  }

  public convertDate(date) {
    return this.gs.getFullFormateDate(date, 'G', 'H');
  }
  /** add custom fields */
  addMoreFields() {
    let dialogRef = this.dialog.open(ExtrafieldsComponent, {
      panelClass: ['car-dialog-form', 'extrafields-dialog-form'],
      width: "800px",
      data: {}
    });
    dialogRef.afterClosed().subscribe((r: any) => {
      this.setDataTable();
    })
  }
  editField(row) {
    let dialogRef = this.dialog.open(ExtrafieldsComponent, {
      panelClass: ['car-dialog-form', 'extrafields-dialog-form'],
      width: "800px",
      data: row
    });
    dialogRef.afterClosed().subscribe((r: any) => {
      this.setDataTable();
    })
  }
  delField(row) {
    this.gs.confirmDialog('yes', 'Are you sure?').subscribe((r: any) => {
      if (r == 'yes') {
        let data = { field_id: row.id, user_id: this.user.id, user_type: this.user.user_type }
        this.gs.callAPI(globalConstants.API_DELETE_CUSTOM_FIELD, data).subscribe((r:any) => {
          if (r.result == 1) {
            this.gs.snackbar('success', r.message);
            this.setDataTable();
          } else {
            this.gs.snackbar('error', r.message);
          }
        }, (error) => { })
      }
    });
  }
  fType(f){
    let m=this.fieldType.filter((r)=> r.id==f)
    if(m && m.length==1){
      f= m[0].text
    }
    return f
  }

}
