import { Component, OnInit, ViewChild, Inject,OnDestroy} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap,NavigationEnd, Params  } from '@angular/router';
import { Http,Headers, Response} from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { globalFunctions } from "../../../../environments/globalFunctions";
import { globalConstants } from "../../../../environments/globalConstants";
import {GlobalService} from "../../../../environments/global.service";
import { lang } from "../../../../environments/lang-en";
import {NewCustomerTypeComponent} from "../new-customer-type/new-customer-type.component";
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TextMaskModule } from 'angular2-text-mask';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-addeditlabourrates',
  templateUrl: './addeditlabourrates.component.html',
  styleUrls: ['./addeditlabourrates.component.scss']
})
export class AddeditlabourratesComponent implements OnInit,OnDestroy {
  public currentCustomer;
  public currentCustomerName;
  public currentCustomerVehicles;
  public isCollapsed:boolean = true;
  public idFromUrl;
  public showSubscriptionMessage = false;
  public base64value="";
  public selectedKey="";
  public states:any;
  public cities:any;
  public editComputer:FormGroup;
  public getCustomerTypes;
  public customerType;
  public labourRate;
  public subscriptionDataForAPI: Subscription = new Subscription();

  constructor(private activatedRoute:ActivatedRoute,private formBuilder: FormBuilder,private route: ActivatedRoute,public titleService:Title,private http: Http,private router: Router,public globalService: GlobalService, private dialog: MatDialog) {
    this.globalService.setMetaData("SHOPS","MANAGE_LABOUR_RATES")
    if(localStorage.getItem("loggedInUser")) {
        var loginUser:any = JSON.parse(localStorage.getItem("loggedInUser"));
        this.currentCustomer = loginUser;
        this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;

        this.editComputer = this.formBuilder.group({
            labor_rate: ['', Validators.required],
            customer_type_name: ['', Validators.required],
            customer_type_id: [''],
            user_type: this.currentCustomer.user_type,
            user_id: this.currentCustomer.id,
            shop_id: this.currentCustomer.shop_id,
            approval_status: [''],
            added_by: ['']
        })

    } else {
      this.router.navigate(["/shops"]);
    }
    setTimeout(()=> {
    let isEnable=this.globalService.getAcl('labor_rates','enable');
    if(!(isEnable)){
        this.router.navigate(['shops/action/forbidden']);
    }
},globalConstants.RELOAD_WAIT)
  }

    ngAfterViewInit(){
        
        var oprListShops = "user_id=" + this.currentCustomer.id + "&user_type=" + this.currentCustomer.user_type+ "&shop_id=" + this.currentCustomer.id;
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_TYPES, oprListShops).subscribe((data:any) => {
            this.getCustomerTypes = data.data;
        })
    }

    sideNavOpen(){
      
    }
    onResize(event) {
      event.target.innerWidth;
    }
    ngOnInit() {
      //this.titleService.setTitle(lang.TITLE_ADMIN_SIGN_IN);
    }
    getCurrentRoute() {
      return this.router.url
    }

    editCustomerTypes(object, id, labourRate, customer_type_name) {
        //if(this.editComputer.valid) {
          var stringifiedFormData = "user_type="+ this.currentCustomer.user_type +"&user_id=" + this.currentCustomer.id + "&shop_id=" + this.currentCustomer.id + "&customer_type_id=" + id + "&labor_rate=" + labourRate + "&customer_type_name=" + customer_type_name;
          this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_UPDATE_LABOR_RATE_URL,stringifiedFormData).subscribe((data:any)=> {
                var message = data.message;
                if(data.result == 1) {
                    this.globalService.snackbar("success", message);
                } else {
                    this.globalService.snackbar("error", message);
                }
            });
        //}
    }

    openInsceptionReport(): void {
        let dialogRef = this.dialog.open(NewCustomerTypeComponent, {
            panelClass: 'inscpection-dialog',
            data: {customerType: this.customerType, labourRate: this.labourRate}
        });

        dialogRef.afterClosed().subscribe(result => {
            this.ngAfterViewInit();
        });
    }
    public d='';
    onlyDecimalNumberKey(event,obj) {
      let charCode = (event.which) ? event.which : event.keyCode;
      this.d=event.srcElement.value;
      if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
          return false;
      return true;

  }

  disableKey(event) {
      let charCode = String.fromCharCode(event.which).toLowerCase();
      if (event.ctrlKey == true && (event.which == '67' || event.which == '86' || String.fromCharCode(event.which).toLowerCase() == 's')) {
          event.preventDefault();
      }
  }
  vkFloat(e, k) {
    for (let i in this.getCustomerTypes) {
        if (this.getCustomerTypes[i].type == k) {
            let output = Number(this.getCustomerTypes[i].labor_rate);
            if (isNaN(output)) {
                if (isNaN(Number(this.d))) {
                    this.getCustomerTypes[i].labor_rate = parseFloat(this.getCustomerTypes[i].labor_rate);
                } else {
                    this.getCustomerTypes[i].labor_rate = this.d;
                }
            }
        }
    }
 }
 isAllowed(action){
    return this.globalService.getAcl('labor_rates',action);
 }

 ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
}
 
}
