import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { globalFunctions } from '../../../../environments/globalFunctions';
import {Http, Headers, Response} from '@angular/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
import { SearchcarComponent } from '../searchcar/searchcar.component';
import { QQSearchcarComponent } from '../../quickquote/qq-searchcar/qq-searchcar.component';


@Component({
  selector: 'app-shopcarvinscan',
  templateUrl: './shopcarvinscan.component.html',
  styleUrls: ['./shopcarvinscan.component.scss']
})

export class ShopcarvinscanComponent {
    public scanVinForm: FormGroup;
    public currentVinDetails;
    public shopCustomerID:any=null;
    public customerCountryName;
    public currentCustomer;
    public qq=false
    public quickapt= false;
    shopCustomerDetails: any;
    constructor( @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ShopcarvinscanComponent>, public dialog: MatDialog, private formBuilder: FormBuilder, private globalService: GlobalService, private http: Http, private router: Router) {
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
        this.scanVinForm = this.formBuilder.group({
            vin_number: ['', [Validators.required, Validators.minLength(17),Validators.pattern(globalConstants.VIN_AND_LICENSE_PLATE_REGEX)]],
        });
        if(typeof data.quickapt!='undefined' && data.quickapt){
            this.quickapt=true
        }
        if(typeof data.qq!='undefined' && data.qq){
            this.qq=true
            this.shopCustomerID=data.customer_id
        }else if(data.send_params) {
            localStorage.setItem("shopCustomerID", data.send_params);
            var splitParamsValues = data.send_params.split(",");
            this.shopCustomerID = splitParamsValues[0];
            this.customerCountryName = splitParamsValues[1];
        }else if(data.customer_id){
            this.shopCustomerID=data.customer_id;
        }
        this.globalService.diaLogObj(this.dialogRef);
    }

    ngOnInit() {
        if(this.shopCustomerID && typeof this.shopCustomerID != 'undefined'){
            this.getShopCustomerDetails();   
        }            
    }
    
    /**
     * @trackID <:CAR-1312> PartsTech VIN Scan
     * @updated 2019-12-31
     * @updatedBY Vikas Kumar
     * @param VIN Number
     * @return Vehicle Detail(data.data)
     */
    getVinScan() {
        Object.keys(this.scanVinForm.controls).map((controlName) => {
            this.scanVinForm.get(controlName).markAsTouched({onlySelf: true});
        });
        
        if (this.scanVinForm.valid) {

            let sendOprs = (this.shopCustomerID ? "customer_id="+this.shopCustomerID:"")+"&vin="+this.scanVinForm.value.vin_number.replace(/ /g, '')+"&user_id="+this.currentCustomer.id;
            if(this.qq){
                sendOprs = sendOprs+"&qq_opr=1"
            }
            this.globalService.callAPI(globalConstants.API_GET_VIN_DATA_URL, sendOprs).subscribe((data:any) => {
                var message = data.message;
                if(data.result == "1") {
                    if(this.qq){
                        this.dialogRef['vin']=this.scanVinForm.value.vin_number
                        if(data.data && typeof data.data!='undefined'){
                            this.dialogRef['car']=data.data
                        }else{
                            this.dialogRef['car']=[]
                        }
                        this.dialogRef['customer']=""
                        this.dialogRef['vehicles']=""
                        if(typeof data.customer!='undefined' && data.customer){
                            this.dialogRef['customer']=data.customer
                        }
                        if(typeof data.vehicles!='undefined' && data.vehicles){
                            this.dialogRef['vehicles']=data.vehicles
                        }
                        this.dialogRef.close(1);
                    }else{
                        this.currentVinDetails = data.data;
                        localStorage.setItem("VinValues", JSON.stringify(this.currentVinDetails));
                        localStorage.setItem("VinNumber", this.scanVinForm.value.vin_number);
                        this.dialogRef.close();
                        this.router.navigate(['/shops/add-car'])
                    }
                } else {
                    this.dialogRef['vin']=this.scanVinForm.value.vin_number
                    if(data.code==515){
                        if(this.quickapt){
                            let vehicleInfo = { vehicle_info: data.vehicles }
                            this.searchCarDialog(vehicleInfo,'vin');
                        }else{
                            let vehicleInfo = { vehicle_info: data.vehicles }
                            //this.askForAcquiring(message)
                            this.qqSearchCarDialog(vehicleInfo,'vin');
                        }
                    }else{
                        this.globalService.snackbar("error", message);
                        Object.keys(this.scanVinForm.controls).map((controlName) => {
                            this.scanVinForm.get(controlName).reset();
                        });
                    }
                }
                
            });
            
        } 
    }

    getShopCustomerDetails() {
        var opr = "user_type=" + this.currentCustomer.user_type + "&user_id="+this.currentCustomer.id+"&customer_id=" + this.shopCustomerID;
        this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_DETAILS, opr).subscribe((data:any) => {
            this.shopCustomerDetails = data.data;
        });
    } 

    checkVIN(){
        Object.keys(this.scanVinForm.controls).map((controlName) => {
            this.scanVinForm.get(controlName).markAsTouched({onlySelf: true});
        });        
        if (this.scanVinForm.valid) {
            let vin = this.scanVinForm.value.vin_number.replace(/ /g, '');
            let opr = "user_type=" + this.currentCustomer.user_type + "&user_id="+this.currentCustomer.id+"&vin="+vin +"&customer_id=" + this.shopCustomerID;
            this.globalService.callAPI(globalConstants.API_CHECK_VEHICLE_INFO, opr).subscribe((data:any) => {
                let message = data.message;
                if(data.result == "1"){
                    if('vehicle_info' in data.data){                      
                        this.searchCarDialog(data.data,'vin');      
                    }else{
                        this.currentVinDetails = data.data;
                        localStorage.setItem("VinValues", JSON.stringify(this.currentVinDetails));
                        localStorage.setItem("VinNumber", this.scanVinForm.value.vin_number);
                        this.dialogRef.close();
                        this.router.navigate(['/shops/add-car'])
                    }                          
                }else{
                    this.dialogRef['vin']=this.scanVinForm.value.vin_number
                    if(data.code == 528){
                        this.globalService.confirmDialog("confirm", data.message).subscribe((res:any) => {
                            if (res != null) {
                                this.scanVinForm.patchValue({ vin_number: '' });
                            }
                        });
                    }else if(data.code==515){
                        this.askForAcquiring(message)
                    }else{
                        this.globalService.snackbar("error", message);
                        Object.keys(this.scanVinForm.controls).map((controlName) => {
                            this.scanVinForm.get(controlName).reset();
                        });
                    }
                }
            });
        }else{

        }
    }

    searchCarDialog(customerVehcileInfo, searchBy){
        let status = 1; 
        if(this.shopCustomerID == customerVehcileInfo.vehicle_info.customer_id){
            status = 1;
        }else if(this.shopCustomerID != customerVehcileInfo.vehicle_info.customer_id){
            status = 2;
        }
        if(this.shopCustomerDetails.is_sublet == '1'){
            status = 3;
        }
        const dialogRef = this.dialog.open(SearchcarComponent, {
            panelClass: ['car-dialog-form'],
            data: {
                status:status,
                customerVehcileInfo: customerVehcileInfo,
                shopCustomerDetails: this.shopCustomerDetails,
                currentCustomer: this.currentCustomer,
                searchBy: searchBy,
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if(result.status === 'jump'){
                this.dialogRef.close(3);
            }
        });
    }
    
    cancelVinScan() {
        Object.keys(this.scanVinForm.controls).map((controlName) => {
            this.scanVinForm.get(controlName).reset();
        });
    }
    
    checkVinValidity() {
        var vin_number = this.scanVinForm.value.vin_number;
        if(vin_number !="") {
            if(vin_number.length >= 17) {
                let rs = vin_number.substr(0, 17);
                this.scanVinForm.controls['vin_number'].setValue(rs);
            } else {
                this.scanVinForm.controls.vin_number.setErrors({"VinError": true});
            }
        }
    }
    /**
     * getting confirmation from user 
     * to go for vehicle transfer 
     * or Scan new VIN
    */
    askForAcquiring(msg){
        this.globalService.confirmDialog("yes", msg+" If you want to expedite the ownership transfer and can verify customer details, press YES?").subscribe((r:any) => {
            if (r != undefined && r == "yes") {
                this.dialogRef["acquire"]=true;
                this.dialogRef.close(2);
            }else{
                this.dialogRef["acquire"]=false;
            }
        })
    }

    qqSearchCarDialog(customerVehcileInfo, searchBy){
        let status = 1; 
        if(this.shopCustomerID == customerVehcileInfo.vehicle_info.customer_id){
            status = 1;
        }else if(this.shopCustomerID != customerVehcileInfo.vehicle_info.customer_id){
            status = 2;
        }
      
        const dialogRef = this.dialog.open(QQSearchcarComponent, {
            panelClass: ['car-dialog-form'],
            data: {
                status:status,
                customerVehcileInfo: customerVehcileInfo,
                shopCustomerDetails: this.shopCustomerDetails,
                currentCustomer: this.currentCustomer,
                searchBy: searchBy,
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.dialogRef["acquire"]=false;
            if(result && 'data' in result){
                console.log('as111dfa',result)
                this.dialogRef['data'] = result.data;
                this.dialogRef["acquire"]=true;
            } 
            this.dialogRef.close(2);            
        });
    }

}
