import {Component, OnInit, ViewChild, Pipe, Inject, ElementRef, ViewEncapsulation,OnDestroy} from '@angular/core';
import {AbstractControl, FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
import {Http, Headers, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../../environments/globalFunctions";
import {globalConstants} from "../../../../environments/globalConstants";
import {GlobalService} from "../../../../environments/global.service";
import {lang} from "../../../../environments/lang-en";
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';

// import {MatPaginator, MatSort, MatDatepicker} from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {DataSource, SelectionModel} from '@angular/cdk/collections';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {ScrollToService} from 'ng2-scroll-to-el';
import { MatTableDataSource } from '@angular/material/table';

import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';

@Component({
  selector: 'app-shopcustomerinformation',
  templateUrl: './shopcustomerinformation.component.html',
  styleUrls: ['./shopcustomerinformation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShopcustomerinformationComponent implements OnInit, OnDestroy {
    public submitted = false;
    public currentCustomer;
    public currentCustomerName;
    public currentCustomerVehicles;
    public isCollapsed: boolean = true;
    public currentShopDetails;
    public listShops;
    public listParts = [];
    public UnapprovedPartsList = [];
    public result: any;
    public idFromUrl;
    public listCar: FormGroup;
    displayedColumns = ['Name', 'Email', 'Address', 'City', 'State', 'Country', 'Mobile Number', 'Actions'];
    dataSource: MatTableDataSource<customerInformationData> = new MatTableDataSource();

    public more_rows;
    public options = {
        user_type: "User",
        user_id: "",
        customer_id: localStorage.getItem("customer_id") || "",
        start: parseInt(localStorage.getItem("start")) || 0 ,
        limit: parseInt(localStorage.getItem("limit")) || 10 ,
    }

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    public subscriptionDataForAPI: Subscription = new Subscription();
    public record :any =[];
    public recordsTotal :any;
    constructor(private formBuilder: FormBuilder, public titleService: Title, public globalService: GlobalService, private route: ActivatedRoute, private http: Http, private router: Router, private scrollService: ScrollToService) {
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
        this.options.user_id = this.currentCustomer.id;
        this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;

        this.listCar = this.formBuilder.group({
            customer_id: [''],
        });
    }

    newServiceRequestForCustomer(customer_id) {
      console.log(customer_id)
      this.router.navigate(['shops/new-service-request/'+customer_id]);
    }
    ngOnInit() {
        //this.titleService.setTitle(lang.TITLE_ADMIN_SHOP_LIST);
        this.setDataTable()
        this.registerAndCheckCustomerPulls();
    }

    registerAndCheckCustomerPulls(){
        let params = {
            user_id:this.currentCustomer.id || "",
            customer_id:localStorage.getItem("customer_id") || ""
        };
        var content = globalFunctions.stringifyFormData(params, null);
        var callApi = this.globalService.callAPI(globalConstants.API_CHECK_AND_REGISTER_CUSTOMER_PULLS_URL, content);
        this.subscriptionDataForAPI = callApi.subscribe((res:any) => {
            // console.log('customer pull registered.')
            if(res.logged_out && res.logged_out == 1){
                this.globalService.snackbar("error", res.message);
                setTimeout(() => this.globalService.userLogout("user",res.message),5000);
            }
        });
    }

    onResize(event) {
        event.target.innerWidth;
    }

    viewAllServiceRequests() {
        this.router.navigate(['shops/list-service-requests']);
    }

    getLoggedInUserDetail() {
      return JSON.parse(localStorage.getItem("loggedInUser")).first_name
    }
    getCurrentRoute() {
        return this.router.url
    }

    scrolling() {
        this.scrollService.scrollTo('.car-datatable', 1000, -20);
    }

    paginateByLimit(event) {
        this.scrolling();
        this.options.limit = event.pageSize;
        this.options.start = 0;
        localStorage.setItem("first_list","true");
        this.setDataTable()
    }

    reinitializeOptions() {
        this.options = {
            user_type: "User",
            user_id: this.options.user_id,
            customer_id: localStorage.getItem("customer_id") || "",
            start: parseInt(localStorage.getItem("start")) || 0 ,
            limit: parseInt(localStorage.getItem("limit")) || 10 ,
        }
    }

    getTotalRecords(){
        return parseInt(localStorage.getItem('totalRecords'))
    }

    paginate(event,type){
      this.scrolling();
      if(type=="next") {
        this.options.start = this.options.start + this.options.limit;
        this.setDataTable()
    } else {
        this.options.start = this.options.start - this.options.limit;
        this.setDataTable()
    }
    }

    firstList() {
        if (localStorage.getItem("first_list") == "true" || this.options.limit >= parseInt(localStorage.getItem("totalRecords")))
            return true;
        else
            return false;
    }

    lastList() {
        if (localStorage.getItem("more_rows") == "true") {
            return !true
        } else {
            return !false
        }
    }

    getUpperLimit(currentLastLimit, total) {
        if (currentLastLimit > total) {
            return total
        } else {
            return currentLastLimit
        }
    }

    setDataTable(){
        this.record = [];
        var content = globalFunctions.stringifyFormData(this.options, null);
        var callApi = this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_DETAILS, content);
        callApi.subscribe((data:any) => {
            if (data.data != undefined) {

                localStorage.setItem("totalRecords", data["recordsTotal"])
                if (data.more_rows == "true") {
                    localStorage.setItem("more_rows", "true");
                } else {
                    localStorage.setItem("more_rows", "false");
                }

                if (data.start >= 0 && data.start <= 10) {
                    localStorage.setItem("first_list", "true");
                } else {
                    localStorage.setItem("first_list", "false");
                }

                if (data.data.length != undefined) {
                    this.recordsTotal = data.data.length;
                    for (var i = 0; i < data.data.length; i++) {
                        this.AddRecord(data.data[i])
                    }
                    this.dataSource = new MatTableDataSource(this.record)
                    this.dataSource.sort = this.sort;
                } else {
                    this.recordsTotal = "1";
                    this.AddRecord(data.data);
                    this.dataSource = new MatTableDataSource(this.record)
                    this.dataSource.sort = this.sort;
                }
                localStorage.setItem("search_keyword","");
                localStorage.setItem("start","0");
                localStorage.setItem("limit","10");
            } else {
              localStorage.setItem("totalRecords", "0")
            }
        })
    }


    /** its user add the record via using create r */
   AddRecord(record){
    var data = this.createRecord(record);
      this.record.push(data);
   }

   private createRecord(record) {
    return {
        name: record.first_name + " " + record.last_name,
        email: record.email,
        address: record.address1,
        city: record.city,
        state: record.state,
        country: record.country,
        mobile: record.mobile_phone,
        status: record.status,
        customer_id: record.id,
        id:record.id
    };
}

    ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
    }
}

export interface customerInformationData {
    name: any;
    email: any;
    address: any;
    city: any;
    state: any;
    country: any;
    mobile: any;
    status: any;
    customer_id: any;
    id:any
}
