import { Component, OnInit, ElementRef, AfterViewInit, ViewChild, ViewEncapsulation,OnDestroy } from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import Calendar from 'tui-calendar'; /* ES6 */
import { GlobalService } from "../../../../environments/global.service";
import {MatDrawer} from '@angular/material/sidenav';
import {MatDialog, } from '@angular/material/dialog';
import { QuickapptComponent } from "../../quickappt/quickappt.component";
import { AssignemployeeComponent } from "../../quickappt/assignemployee/assignemployee.component";
import { globalConstants } from "../../../../environments/globalConstants";
import { Router } from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {DatePipe} from '@angular/common';
import { ServiceCalendarModalComponent } from '../service-calendar-modal/service-calendar-modal.component';
import { AddinternalnoteComponent } from '../../../shop/internalnote/addinternalnote/addinternalnote.component'
import { ViewnoteComponent } from '../../../shop/internalnote/viewnote/viewnote.component';
import {TechassignmentComponent} from '../techassignment/techassignment.component';
import { ServiceFieldUpdateComponent } from '../service-calendar-modal/service-field-update/service-field-update.component';
import { AddleaveComponent } from '../../leave/addleave/addleave.component';
import * as moment from 'moment';
import { ImagetooldialogComponent } from '../../../imagetooldialog/imagetooldialog.component';
import {Subscription} from 'rxjs';


interface CalenderView {
  value: string;
  name: string;
}

@Component({
  selector: 'app-service-calendar',
  templateUrl: './service-calendar.component.html',
  styleUrls: ['./service-calendar.component.scss'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None,
})

export class ServiceCalendarComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('serviceCalendar') serviceCalDiv: ElementRef;
  @ViewChild('drawer') drawer: MatDrawer;
  public newRequest: FormGroup;
  public formName;
  public serviceCalender;
  public counter = 0;
  public userData:any;
  public searchByStatus = [];
  public getEmployee = [];
  public getCustomer = [];
  public allLabels=[];
  public dayname=[];
  public CalenderViewList:any;
  public activeDayIsOpen: boolean = false;
  public currentDate = this.datePipe.transform(new Date(), 'MMddyyyyHHmmss');
  public CalendarMonth;
  public rangeStart;
  public rangeEnd;
  public serviceReqStatus = ['Appointments','Pending','Processing','Approval Awaited','','','Completed','Approval Completed','Completed And Paid','Unread Ratings','','Appointments','Work In Progress'];
  public techStatus = ['Yet to start','Working','Pause','Done'];
  public MONTHLY_CUSTOM_THEME = {
    // month header 'dayname'
    'month.dayname.height': '42px',
    'month.dayname.borderLeft': 'none',
    'month.dayname.paddingLeft': '8px',
    'month.dayname.paddingRight': '0',
    'month.dayname.fontSize': '13px',
    'month.dayname.backgroundColor': 'inherit',
    'month.dayname.fontWeight': 'normal',
    'month.dayname.textAlign': 'left',

    // month day grid cell 'day'
    'month.holidayExceptThisMonth.color': '#f3acac',
    'month.dayExceptThisMonth.color': '#bbb',
    'month.weekend.backgroundColor': '#fafafa',
    'month.day.fontSize': '16px',

    // month schedule style
    

    // month more view
    'month.moreView.boxShadow': 'none',
    'month.moreView.paddingBottom': '0',
    'month.moreView.border': '1px solid #9a935a',
    'month.moreView.backgroundColor': '#ffffff',
    'month.moreViewTitle.height': '28px',
    'month.moreViewTitle.marginBottom': '0',
    'month.moreViewTitle.backgroundColor': '#f4f4f4',
    'month.moreViewTitle.borderBottom': '1px solid #ddd',
    'month.moreViewTitle.padding': '0 10px',
    'month.moreViewList.padding': '10px'
  };
  public calenderData:any=[
    {id: "day", name: "day"},
    {id: "week", name: "Week"},
    {id: "month", name: "Month"}
  ];
  public empSelected="";
  public custSelected="";
  public calenderSelectedEvent:string = "month";
  public skipTicketAssign=false;
  public isEmployee=false;
  public allTicketsView = false;
  public sidePanelContent:any = {};
  public technicianList   = [];
  public techloading:boolean;
  isCompletedIncluded:boolean;
  myControl: FormControl = new FormControl();
  customerControl: FormControl = new FormControl();
  filterOptionsSet: Observable<any[]>;
  filterCustomerSet: Observable<any[]>;
  public imgPath    = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_PICTURES + globalConstants.IMG_THUMB;
  public docImgPath = globalConstants.S3_BUCKET_URL + globalConstants.EMP_LEAVE_DOCUMENT + globalConstants.IMG_THUMB;
  public docPath    = globalConstants.S3_BUCKET_URL + globalConstants.EMP_LEAVE_DOCUMENT;
  public options = {
    user_id: "",
    user_type: "User",
    status: '',
    search_by: "",
    search_keyword: "",
    jobStatus:'',
    employee:'',
    service_label:'',
  }
  public techData:Object = {};
  public subscriptionDataForAPI: Subscription = new Subscription();

  constructor(
    public gs:GlobalService,
    public dialog: MatDialog,
    public router: Router,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    
    ) {
      
      this.newRequest = this.formBuilder.group({
        employee_id: [''],
        customer_id: ['']
      });

    /* SEARCH BY STATUS ARRAY */
    this.searchByStatus = [
      { id: "", text: "Select options"},
      { id: "1", text: "Appointments"},
      { id: "2", text: "Assigned Tech."},
      { id: "3", text: "Internal Notes"},
      { id: "4", text: "Holidays"},
      { id: "5", text: "Employee Leaves"}
  ];
  }
  ngOnInit(): void {
    this.userData = this.gs.getCurrentUser();
    this.getEmployees();
    this.getCustomers();
    this.getAllLabels();
    this.getTicketAssignSetting();
    this.isEmployee     = this.gs.isSubscribe('employee');
    this.allTicketsView = this.gs.getAcl('all_ticket_view','views');
  }
  ngAfterViewInit() {
    this.calendarInit();
    this.getCalendarDateRange();
  }


  getCalendarDateRange(){
    this.rangeStart = this.datePipe.transform(this.serviceCalender.getDateRangeStart(), 'MM/dd/yyyy hh:mm a');
    this.rangeEnd = this.datePipe.transform(this.serviceCalender.getDateRangeEnd(), 'MM/dd/yyyy hh:mm a');

    let startDateRange = this.datePipe.transform(this.serviceCalender.getDateRangeStart(), 'dd MMM yy');
    let endDateRange = this.datePipe.transform(this.serviceCalender.getDateRangeEnd(), 'dd MMM yy');
    this.CalendarMonth = startDateRange+' ~ '+endDateRange;
  }

  getColor(status,is_appointment = 0){
  let bgcolor = '#f50057';
  if(status==1 || status == 2 && is_appointment == 1){
    bgcolor = '#fd82ad5e';
  }else if(status == 1){
    bgcolor = '#faf2cc';
  }else if(status == 2){
    bgcolor = '#c4e3f3';
  }else if(status == 3){
    bgcolor = '#e1e114';
  }else if(status == 5){
    bgcolor = '#d0e9c6';
  }else if(status == 6){
    bgcolor = '#b4eab4'; 
  }else if(status == 7){
    bgcolor = '#ada'; 
  }else if(status == 8){
    bgcolor = '#52bab3';
  }else if(status = 12){
    bgcolor = '#b4eab4'; 
  }

  return bgcolor;
  }
  
  calendarInit(){
    this.serviceCalender = new Calendar(this.serviceCalDiv.nativeElement, {
      defaultView: 'month',      
      theme: this.MONTHLY_CUSTOM_THEME,
      taskView: false,
      useCreationPopup: false,
      useDetailPopup: false,
      template: {         
        allday: (schedule) => {          
          let scheduleTileHtml = '';
          let tTitle           = '';
          if(schedule.raw.event_type == 1){
            tTitle            = schedule.raw.service_request_title+" || "+this.serviceReqStatus[(schedule.raw.is_appointment== '1' &&  (schedule.raw.request_status=='1' || schedule.raw.request_status=='2')) ? 0 : Number(schedule.raw.request_status)]+" || "+schedule.raw.license_plate;
            scheduleTileHtml  = '<div class="wrap-tile"  title="'+tTitle+'">';
            if(schedule.raw.created_by_type == 'Customer'){
              scheduleTileHtml += '<i class="fa fa-user"></i> ';
            }
            scheduleTileHtml += schedule.title;
            scheduleTileHtml += '</div>';
           
          }else if(schedule.raw.event_type == 2){
            tTitle            = schedule.raw.service_request_title+" || "+this.techStatus[Number(schedule.raw.completion_status)]+" || "+schedule.raw.license_plate+" || "+schedule.raw.emp_fname+" "+schedule.raw.emp_lname+" || "+this.convertMinutes(schedule.raw.allocated_time)+" | "+this.convertMinutes(schedule.raw.consumed_time);
            scheduleTileHtml  = '<div class="wrap-tile"  title="'+tTitle+'">'
            if(Number(schedule.raw.completion_status)==1 || Number(schedule.raw.completion_status)==2){
              scheduleTileHtml += '<i class="fa far fa-clock"></i> ';
            }
            scheduleTileHtml += schedule.raw.service_request_id; 
            scheduleTileHtml += '<span style="margin-left:4px;display:inline-block;width:22px; height:22px;text-align:center;background:#ffffff;border-radius:50%;color:#000;font-weight:700;">';            
            scheduleTileHtml += this.getInitials(schedule.raw.emp_fname+" "+schedule.raw.emp_lname);            
            scheduleTileHtml += '</span>';
            scheduleTileHtml += '</div>'; 
          }else if(schedule.raw.event_type == 5){
            tTitle           = schedule.raw.emp_fname+" "+schedule.raw.emp_lname+" | "+schedule.title;
            scheduleTileHtml = '<div class="wrap-tile"  title="'+tTitle+'">'
            scheduleTileHtml += schedule.title;
            scheduleTileHtml += '<span style="margin-left:4px;display:inline-block;width:22px; height:22px;text-align:center;background:#ffffff;border-radius:50%;color:#000;font-weight:700;">';            
            scheduleTileHtml += this.getInitials(schedule.raw.emp_fname+" "+schedule.raw.emp_lname);            
            scheduleTileHtml += '</span>';
            scheduleTileHtml += '</div>';
          }else{
            tTitle           = schedule.title;
            scheduleTileHtml = '<div class="wrap-tile"  title="'+tTitle+'">'
            scheduleTileHtml += schedule.title;
            scheduleTileHtml += '</div>';
          }
          return scheduleTileHtml;
        },
        time: (schedule) => {          
          let scheduleTileHtml = '';
          let tTitle           = '';
          if(schedule.raw.event_type == 1){
            tTitle            = schedule.raw.service_request_title+" || "+this.serviceReqStatus[(schedule.raw.is_appointment== '1' &&  (schedule.raw.request_status=='1' || schedule.raw.request_status=='2')) ? 0 : Number(schedule.raw.request_status)]+" || "+schedule.raw.license_plate;
            scheduleTileHtml  = '<div class="wrap-tile"  title="'+tTitle+'">';
            if(schedule.raw.created_by_type == 'Customer'){
              scheduleTileHtml += '<i class="fa fa-user"></i> ';
            }
            scheduleTileHtml += schedule.title;
            scheduleTileHtml += '</div>';
            
          }else if(schedule.raw.event_type == 2){
            tTitle            = schedule.raw.service_request_title+" || "+this.techStatus[Number(schedule.raw.completion_status)]+" || "+schedule.raw.license_plate+" || "+schedule.raw.emp_fname+" "+schedule.raw.emp_lname+" || "+this.convertMinutes(schedule.raw.allocated_time)+" | "+this.convertMinutes(schedule.raw.consumed_time);
            scheduleTileHtml  = '<div class="wrap-tile"  title="'+tTitle+'">'
            if(Number(schedule.raw.completion_status)==1 || Number(schedule.raw.completion_status)==2){
              scheduleTileHtml += '<i class="fa far fa-clock"></i> ';
            }
            scheduleTileHtml += schedule.raw.service_request_id; 
            scheduleTileHtml += '<span style="margin-left:4px;display:inline-block;width:22px; height:22px;text-align:center;background:#ffffff;border-radius:50%;color:#000;font-weight:700;">';            
            scheduleTileHtml += this.getInitials(schedule.raw.emp_fname+" "+schedule.raw.emp_lname);
            scheduleTileHtml += '</span>';
           
            scheduleTileHtml += '</div>'; 
          }else if(schedule.raw.event_type == 5){
            tTitle           = schedule.raw.emp_fname+" "+schedule.raw.emp_lname+" | "+schedule.title;
            scheduleTileHtml = '<div class="wrap-tile"  title="'+tTitle+'">'
            scheduleTileHtml += schedule.title;
            scheduleTileHtml += '<span style="margin-left:4px;display:inline-block;width:22px; height:22px;text-align:center;background:#ffffff;border-radius:50%;color:#000;font-weight:700;">';            
            scheduleTileHtml += this.getInitials(schedule.raw.emp_fname+" "+schedule.raw.emp_lname);            
            scheduleTileHtml += '</span>';
            scheduleTileHtml += '</div>';
          }else{
            tTitle           = schedule.title;
            scheduleTileHtml = '<div class="wrap-tile"  title="'+tTitle+'">'
            scheduleTileHtml += schedule.title;
            scheduleTileHtml += '</div>';
          }
          return scheduleTileHtml;
        }
      }
    });
    // console.log(this.serviceCalender);
  
   this.loadCalendarEvents();
    this.serviceCalender.on('beforeCreateSchedule', (event) => {
      /*let calStarttDate = this.datePipe.transform(event.start, 'MMddyyyyHHmmss');
      let calEndDate = this.datePipe.transform(event.end, 'MMddyyyyHHmmss');
      // console.log(calStarttDate, calEndDate, this.currentDate);
      if(calEndDate < this.currentDate || calStarttDate < this.currentDate){
        event.guide.clearGuideElement();
        return;
      }*/

      if(new Date(event.end._date) < new Date() && new Date(event.start._date) < new Date()){
        event.guide.clearGuideElement();
        return;
      }

      event.guide.clearGuideElement();
      let evtDetails = {
        startTimeTz : event.start,
        endTimeTz : event.end,
        startTime : event.start.toDate(),
        endTime : event.end.toDate(),
        isAllDay : event.isAllDay,
        guide : event.guide,
        evtType : null
      }
      let dialogRef = this.gs.confirmDialog("select_quick_appt","Please select a type of event to proceed", 600).subscribe((response)=>{
        if(response!=undefined) {      
          if(response.toString() == "customer_appt" ){
            evtDetails.evtType = 1;
            if(this.gs.getAcl('service_request','addNew')){
              this.quickCustomerAppt(evtDetails);
            }else{
              this.gs.snackbar("error", 'You do not have access rights for this resource.');
            }
          }else if(response.toString() == "tech_assign"){
            evtDetails.evtType = 2;
            if(this.isVisible() && this.skipTicketAssign && this.isEmployee){
              this.techAssignment(evtDetails);
            }else{
              this.gs.snackbar("error", 'You do not have access rights for this resource.');
            }
          }else if(response.toString() == "leave"){
            if(this.gs.getAcl('apply_employee_leaves','addNew')){
              let dialogRef = this.dialog.open(AddleaveComponent, {
                panelClass: ['car-dialog-form'],
                width: "800px",
                data:{ startDate:moment(evtDetails.startTime).toISOString(),endDate:moment(evtDetails.endTime).toISOString()}
              });
            }else{
              this.gs.snackbar("error", 'You do not have access rights for this resource.');
            }
          }else{
            evtDetails.evtType = 3;
            this.internalNotes(evtDetails)
          }
          
        }else{
         // this.loadCalendarEvents();
        }
       
      })
    });

    this.serviceCalender.on('beforeUpdateSchedule', (event) => {
   
      var schedule = event.schedule;
      var changes = event.changes;     

      if(!this.gs.isValidDate(event.end._date) && !this.gs.isValidDate(event.start._date)){        
        return false;
      }

      if(this.calenderSelectedEvent == 'month'){
        let sTime = moment(schedule.raw.start_date);
        let eTime = moment(schedule.raw.end_date);
        let sDateTime = moment(new Date(event.end._date)).set({hour:sTime.get('hour'),minute:sTime.get('minute'),second:sTime.get('second')});
        let eDateTime = moment(new Date(event.start._date)).set({hour:eTime.get('hour'),minute:eTime.get('minute'),second:eTime.get('second')});
        if(eDateTime < moment() || sDateTime < moment()){
          this.serviceCalender.setOptions({disableClick: true}, true);
          return;
        }       
      }else if(new Date(event.end._date) < new Date() || new Date(event.start._date) < new Date()){
        this.serviceCalender.setOptions({disableClick: true}, true);
        return;
      }

      if(schedule.raw.event_type==1 && (!changes.start || !changes.end)){
        return false;
      }
    
      let startDate; let endDate; let internalNoteId = schedule.raw.internal_note_id;
      let usr_sr_id  = schedule.raw.usr_sr_id;
      internalNoteId = (schedule.raw.internal_note_id !== null && schedule.raw.internal_note_id!== undefined) ? schedule.raw.internal_note_id : null;
      startDate = (this.gs.isValidDate(changes.start)) ? this.datePipe.transform(changes.start, 'MM/dd/yyyy hh:mm a') : event.start._date;
      endDate   = (this.gs.isValidDate(changes.end)) ? this.datePipe.transform(changes.end, 'MM/dd/yyyy hh:mm a') : event.end._date;
      if(schedule.raw.event_type==2 && !changes.start &&  !startDate){
        startDate = schedule.raw.start_date;
      }

      let fdata = new FormData();
      fdata.append('user_id',this.userData.id);
      fdata.append('user_type',this.userData.user_type);
      fdata.append('event_start_date',moment(startDate).format('MM/DD/YYYY hh:mm A Z'));
      fdata.append('event_end_date',moment(endDate).format('MM/DD/YYYY hh:mm A Z'));
      fdata.append('event_type',schedule.raw.event_type);
      fdata.append('request_id',schedule.raw.req_id);
      if(internalNoteId){
        fdata.append('internal_note_id',internalNoteId);
      }
      if(usr_sr_id){
        fdata.append('usr_sr_id',usr_sr_id);
      } 
      fdata.append('calendar_view',this.calenderSelectedEvent);
      
      /*'user_id='+this.userData.id+'&user_type='+this.userData.user_type+'&event_start_date='
      +moment(startDate).format('MM/DD/YYYY hh:mm A Z')+'&event_end_date='+moment(endDate).format('MM/DD/YYYY hh:mm A Z')+'&event_type='+schedule.raw.event_type+'&request_id='+schedule.raw.req_id+'&internal_note_id='+internalNoteId+'&usr_sr_id='+usr_sr_id+"&calendar_view="+this.calenderSelectedEvent;*/

      this.gs.formData(globalConstants.API_QUICKAPPT_UPDATE, fdata).subscribe((r) => {
        if(r.result == 1){
          this.loadCalendarEvents();
          this.gs.snackbar("success", r.message);
        }else{
          if([730,731,732,734,740].includes(parseInt(r.code))){
            let code:any = r.code;
            this.gs.confirmDialog('techAssign', r.message).subscribe((resp:any) => {
              let fStatus = {730:1,731:2,732:3,740:4,734:5};
              if(resp=='techAssign'){
                console.log('techAssign',(fStatus[Number(code)]));
                this.ticketAssign(fdata,fStatus[Number(code)]);
              }
            });
          }else{
            this.gs.snackbar("error", r.message);
          }
          return false;
        }
      })
    });

    this.serviceCalender.on('clickSchedule', (event) => {
      if(event.schedule.raw.event_type == 3){
        this.viewNote(event.schedule.raw.internal_note_id, event.schedule.raw.read_status)
      }else if(event.schedule.raw.event_type==1 || event.schedule.raw.event_type==2){
        //this.viewSchedule(event.schedule.raw);
        Object.assign(this.sidePanelContent, event.schedule.raw);
        if(this.sidePanelContent.requested_delivery_date){
          this.sidePanelContent.requested_delivery_date = moment(this.sidePanelContent.requested_delivery_date).toISOString();
        }
        if(this.sidePanelContent.schedule_service_date){
          this.sidePanelContent.schedule_service_date = moment(this.sidePanelContent.schedule_service_date).toISOString();
        }        
        this.getAssignedTechinican();
        this.drawer.toggle();
      }else if(event.schedule.raw.event_type==5){
        Object.assign(this.sidePanelContent, event.schedule.raw);
        this.drawer.toggle();
      }
    });
    
  }

  ticketAssign(fdata,fAction = null){
    if(!('force_action' in fdata.keys()) && fAction){
      fdata.append('force_action',fAction);
    }else{
      fdata.set('force_action',fAction);
    }
    this.gs.formData(globalConstants.API_QUICKAPPT_UPDATE, fdata).subscribe((tech) => {
      if(tech.result == 1){
        this.loadCalendarEvents();
        this.gs.snackbar("success", tech.message);
      }else{
        if([730,731,732,734,740].includes(parseInt(tech.code))){
          let code:any = tech.code;
          this.gs.confirmDialog('techAssign', tech.message).subscribe((resp:any) => {
            let fStatus = {730:1,731:2,732:3,740:4,734:5};
            if(resp=='techAssign'){
              console.log('techAssign',(fStatus[Number(code)]));
              this.ticketAssign(fdata,fStatus[Number(code)]);
            }
          });
        }else{
          this.gs.snackbar("error", tech.message);
        }
      }
    })

  }

  calenderReminder(calEvent, result){
    let startDate = (this.datePipe.transform(calEvent.startTime, 'MM/dd/yyyy hh:mm a'));
    let endDate   = (this.datePipe.transform(calEvent.endTime, 'MM/dd/yyyy hh:mm a'));
    /*let content   ='user_id='+this.userData.id+'&user_type='+this.userData.user_type+'&event_start_date='
    +startDate+'&event_end_date='+endDate+'&event_type='+calEvent.evtType
    +'&request_title='+result.data.title+'&note_description='+result.data.description+'&note_id='+result.data.id;   */ 
    let fdata = new FormData();
    fdata.append('user_id',this.userData.id);
    fdata.append('user_type',this.userData.user_type);
    fdata.append('event_start_date',startDate);
    fdata.append('event_end_date',endDate);
    fdata.append('event_type',calEvent.evtType);
    fdata.append('request_title',result.data.title);
    fdata.append('note_description',result.data.description);
    fdata.append('note_id',result.data.id);
    this.gs.formData(globalConstants.API_QUICKAPPT_CREATION, fdata).subscribe((r) => {
      if(r.result==1){
        this.loadCalendarEvents();
      }else{
        this.gs.snackbar("error", 'something wrong');
      }
    }, (error) => { })
  }

  /** note mark as read */
  viewNote(internal_note_id, read_statusatus){
    if(read_statusatus==1){
      this.redirectToView(internal_note_id)
    }else{
      let data = { user_id: this.userData.id,user_type: this.userData.user_type,note_id:internal_note_id,status:1};
      let url=globalConstants.API_ADD_INTERNAL_NOTE_COMMENT;
      this.subscriptionDataForAPI = this.gs.callAPI(url, data).subscribe((r:any) => {
        if (r.result == 1) {
          this.redirectToView(internal_note_id)
        }else if(r.code=499){
          this.redirectToView(internal_note_id)
        }
      }, (error) => { })
    }
  }

  /** redirect to view internal note detail */
  redirectToView(internal_note_id){ 
    this.gs.localGetterSetter('internal-note', internal_note_id,'');
    let dialogRef = this.dialog.open(ViewnoteComponent, {
        panelClass: 'dialog-view-note',
        data: this
    });
  }

  /** open Add Notes */
  internalNotes(eventData) {
    let dialogRef = this.dialog.open(AddinternalnoteComponent, {
      panelClass: 'car-dialog-form',
      width: "800px",
      data: {
        eventType:eventData.evtType,
        startTime:eventData.startTime,
        endTime:eventData.endTime,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.code==647){
        this.clearSearch();
      }
    });
  }

  
  loadCalendarEvents(){
    
    this.ClearCalender();
    this.getCalendarDateRange();
    /*let content='user_id='+this.userData.id+'&user_type='+this.userData.user_type+'&sr_status='+this.options.status
    +'&employee_id='+this.newRequest.value.employee_id
    +'&customer_id='+this.newRequest.value.customer_id+'&label_id='+this.options.service_label
    +'&start_date='+this.rangeStart+'&end_date='+this.rangeEnd;
    if(this.isCompletedIncluded){
      content +="&isCompletedIncluded=1";
    }
    this.gs.callAPI(globalConstants.API_GET_CALENDAR_EVENTS,content).subscribe((resp)=>{*/
    let fdata = new FormData();
    fdata.append('user_id',this.userData.id);
    fdata.append('user_type',this.userData.user_type);
    fdata.append('sr_status',this.options.status);
    fdata.append('employee_id',this.newRequest.value.employee_id);
    fdata.append('customer_id',this.newRequest.value.customer_id);
    fdata.append('label_id',this.options.service_label);
    fdata.append('start_date',moment(this.rangeStart).format('MM/DD/YYYY hh:mm A Z'));
    fdata.append('end_date',moment(this.rangeEnd).format('MM/DD/YYYY hh:mm A Z'));
    if(this.isCompletedIncluded){
      fdata.append('isCompletedIncluded','1');
    }
    this.gs.formData(globalConstants.API_GET_CALENDAR_EVENTS,fdata).subscribe((resp)=>{
      if(resp.result==1){
        if(typeof resp.data != 'undefined' && Array.isArray(resp.data) && resp.data.length){
          for(var i in resp.data){
            this.createCalendarSchedule({
              id: resp.data[i].id,
              calendarId: resp.data[i].assignee_id,
              title: resp.data[i].title,
              startTime: resp.data[i].start_date,
              endTime: resp.data[i].end_date,
              eventData: resp.data[i],
              eventType: resp.data[i].event_type,
              requestStatus: resp.data[i].request_status,
              is_appointment:resp.data[i].is_appointment,
              completion_status:resp.data[i].completion_status              
            });
          }
        }
      }
    });
  }

  viewSchedule(calEvent){
    console.log('sss',calEvent);
    let dialogRef = this.dialog.open(ServiceCalendarModalComponent, {
      panelClass: ['car-dialog-form'],
      width:"800px",
      data: {
       dialogMetaData:calEvent
      }
    });
    dialogRef.afterClosed().subscribe((res)=> {
      this.loadCalendarEvents();
      this.getAllLabels();
      this.getAssignedTechinican();
    })
  }

  quickCustomerAppt(calEvent) {
    let d = new Date(calEvent.startTime);
    let dialogRef = this.dialog.open(QuickapptComponent, {
      panelClass: ['quickqouotedialog'],
      width: "1200px",
      data: {
        startTime:moment(calEvent.startTime).toISOString(),
        endTime:moment(calEvent.endTime).toISOString(),
        eventType:calEvent.evtType
      }
    });
    dialogRef.afterClosed().subscribe((res)=>{
      console.log('quickCustomerAppt',res);
      if(res){
        this.loadCalendarEvents();
      }
    });
  }

  /*techAssignment(calEvent){
    let dialogRef = this.dialog.open(AssignemployeeComponent, {
      panelClass: ['quickapptdialog'],
      width: "100%",
      data: {
        startTime:moment(calEvent.startTime).toISOString(),
        endTime:moment(calEvent.endTime).toISOString(),
        eventType:calEvent.evtType,
        fromSRPopup:true
      }
    });
    dialogRef.afterClosed().subscribe((res)=>{
      if(res){
        this.loadCalendarEvents();
        this.getAssignedTechinican();
      }     
    });
  }*/

  techAssignment(calEvent:any ={}){
    if(this.isEmployee==false){
        return false;
    }
    this.techData= {fromSRPopup: true,start_time:moment(calEvent.startTime).toISOString(),end_time:moment(calEvent.endTime).toISOString()};
  }

  techAssignmentOut($event:any){
    if($event==true){
      setTimeout(() => {
        this.getAssignedTechinican();
        this.loadCalendarEvents();
      },500);
    }
    this.techData = {};
  }

  createCalendarSchedule(evtDetails){ 
    if(evtDetails.eventType == 1){
      if('request_id' in evtDetails.eventData){
        evtDetails.id = evtDetails.eventData.request_id;
      }     
      var color = this.getColor(evtDetails.requestStatus,('is_appointment' in evtDetails)? evtDetails.is_appointment:0);
      var bgcolor = color;
      var brcolor = color;
    }else if(evtDetails.eventType == 2){
      if('usr_sr_id' in evtDetails.eventData){
        evtDetails.id = evtDetails.eventData.usr_sr_id;
      }   
      var bgcolor = '#b1a3e8a6';
      let brcolor = '#b1a3e8a6';
    }else if(evtDetails.eventType == 3){
      if('internal_note_id' in evtDetails.eventData){
        evtDetails.id = evtDetails.eventData.internal_note_id;
      }
      var bgcolor = '#92929e';
      var brcolor = '#92929e';
    }else if(evtDetails.eventType == 4){
      var bgcolor = '#faaa69';
      var brcolor = '#faaa69';
    }else if(evtDetails.eventType == 5){
      var bgcolor = '#a7edfd';
      var brcolor = '#a7edfd';      
    }else if(evtDetails.eventType == 6){
      if('holiday_id' in evtDetails.eventData){
        evtDetails.id = evtDetails.eventData.holiday_id;
      }
      var bgcolor = '#faaa69';
      var brcolor = '#faaa69';
    }else if(evtDetails.eventType == 5){
      if('app_id' in evtDetails.eventData){
        evtDetails.id = evtDetails.eventData.app_id;
      }
      var bgcolor = '#a7edfd';
      var brcolor = '#a7edfd';      
    }else if(evtDetails.eventType == 6){
      if('app_id' in evtDetails.eventData){
        evtDetails.id = evtDetails.eventData.app_id;
      }
      var bgcolor = '#f1a19fab';
      var brcolor = '#f1a19fab';      
    }else{
      evtDetails.id = Math.floor(Math.random() * 100);
      var color = this.getColor(evtDetails.requestStatus);
      var bgcolor = color;
      var brcolor = color;
    }
    

    let schedule = {
      id          : evtDetails.id,
      calendarId  : 1,
      title       : evtDetails.title,
      category    : this.calenderSelectedEvent == 'month'?'allday':'time',
      dueDateClass: (this.calenderSelectedEvent == 'month')?'disable-drag':(((evtDetails.eventType) != 2)?'disable-drag':''),
      start       : moment(evtDetails.startTime).toISOString(),
      end         : moment(evtDetails.endTime).toISOString(),
      raw         : evtDetails.eventData || {},
      color       : '#000',
      bgColor     : bgcolor,
      borderColor : brcolor,
      isAllDay    : ('is_allday' in evtDetails.eventData &&  evtDetails.eventData.is_allday =='1')?true:(([4,6].includes(evtDetails.eventType)?true:((evtDetails.eventType==5 && 'leave_duration' in evtDetails.eventData &&  evtDetails.eventData.leave_duration =='f')?true:false))),
      dragBgColor : 'transparent',
      isReadOnly  : ((evtDetails.eventType) == 4 || (evtDetails.eventType) == 5 || (evtDetails.eventType) == 6) ? true :(((evtDetails.eventType) == 1 && (evtDetails.requestStatus)!=11 && (evtDetails.requestStatus)!=1)? true :(((evtDetails.eventType) == 2 && (evtDetails.completion_status)!=0)? true:false))
    };

    this.serviceCalender.createSchedules([schedule]);
  }
  
  filter(val: string, val_type): string[] {
    // console.log(val)
    if (val.length != undefined) {
        if(val_type == 'customer'){
          var filteredValue = this.getCustomer.filter(option => option.name.toLowerCase().indexOf(val.toLowerCase()) === 0);
        }else if(val_type == 'employee'){
          var filteredValue = this.getEmployee.filter(option => option.first_name.toLowerCase().indexOf(val.toLowerCase()) === 0);
        }
        return filteredValue
    }
  }

  empDisplayFn(val) {
    if (val != undefined && val != null && val != '') {
      return val.first_name + ' ' + val.last_name; 
    }
  }

  custDisplayFn(val) {
    if (val != undefined && val != null) {
      return val.name; 
    }
  }

  SetOptionAsEmployeeId(event){
    this.newRequest.controls.employee_id.setValue(event.option.value["id"]);
  }
  SetOptionAsCustomerId(event){
    this.newRequest.controls.customer_id.setValue(event.option.value["id"]);
  }

  getEmployees(){
    let content='user_id='+this.userData.id+'&user_type='+this.userData.user_type;
    let val_type = 'employee';
    this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_GET_TECHNICIAN,content).subscribe((data:any)=>{
          this.getEmployee = data.allemployes;
          this.filterOptionsSet = this.myControl.valueChanges
              .startWith(null)
              .map(val => val ? this.filter(val,val_type ) : this.getEmployee && this.getEmployee.slice());
         
    });
  }

  getCustomers(){
    let content='user_id='+this.userData.id+'&user_type=User&limit_type=all';
    let val_type = 'customer';
    this.subscriptionDataForAPI =  this.gs.callAPI(globalConstants.API_GET_SHOP_CUSTOMERS_DATA_URL,content).subscribe((data:any)=>{
      var parsedResponse = data;
            this.getCustomer = parsedResponse.data;
            this.filterCustomerSet = this.customerControl.valueChanges
                .startWith(null)
                .map(val => val ? this.filter(val, val_type) : this.getCustomer.slice());
      });
  }

    // Add By Tafzeel //
    getAllLabels(){
      let content = "user_id="+this.userData.id;
      this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_GET_SERVICE_REQUEST_LABELS_MASTER, content).subscribe((res:any)=> {
        if(res.result == 1){
          this.allLabels = res.data;
        }
      },(error)=>{});
    }

  calenderSelectedView(event){
    this.getCalendarDateRange();
    if(event == 'day'){
      this.serviceCalender.changeView('day', true);
      this.calenderSelectedEvent = 'day';
      
    }else if(event == 'week'){
      this.serviceCalender.changeView('week', true);
      this.calenderSelectedEvent = 'week';
      
    }else{
      this.serviceCalender.changeView('month', true);
      this.calenderSelectedEvent = 'month';
    }
    this.loadCalendarEvents();
  }

  CalendarNextPrevEvent($praam){
    this.getCalendarDateRange();
    
    if($praam == "prev"){
      this.serviceCalender.prev();
    }else if($praam == "today"){
      this.serviceCalender.today();
    }else{
      this.serviceCalender.next();
    }
    this.loadCalendarEvents();
    
  }

  resetAutocomplete(type){
    if(type == 'employee'){
      this.newRequest.controls.employee_id.setValue("");
      this.empSelected="";
    }else{
      this.newRequest.controls.customer_id.setValue("");
      this.custSelected="";
    }
  }
  
  clearSearch() {
    this.options.status="";
    this.newRequest.value.employee_id="";
    this.newRequest.value.customer_id="";
    this.options.service_label = "",
    this.resetAutocomplete('employee');
    this.resetAutocomplete('customer');
    this.loadCalendarEvents();
}

  ClearCalender(){
    this.serviceCalender.clear();
    this.serviceCalender.render();
  }

  listView(){
    this.router.navigate(['shops/list-service-requests']);
  }

  getTicketAssignSetting(){
    let data="user_id="+this.userData.id+"&user_type="+this.userData.user_type;
    this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_SKIP_FOREVER,data).subscribe((skipTicket:any)=>{
        if(skipTicket.result=="1"){
            if(typeof skipTicket.data.skip_forever !='undefined' && skipTicket.data.skip_forever=='1'){
                this.skipTicketAssign=false;
            }else{
                this.skipTicketAssign=true;
            }
        }else{
            this.skipTicketAssign=true;
        }
    })
  }

  isVisible(){
    return ( this.userData.role_id != 4 ? true : false );
  }
  
  toggleCompletedRequests(ev:any){
    this.isCompletedIncluded = !this.isCompletedIncluded;
    this.loadCalendarEvents();
  }

  getInitials(fullName:string){    
    const allNames = fullName.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
      if(index === 0 || index === allNames.length - 1){
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
    return initials;
  }

  convertMinutes(num){
    if(num){
      let d = Math.floor(num/1440); // 60*24
      let h = Math.floor((num-(d*1440))/60);
      let m = Math.round(num%60); 
      let tTime = ""; 
      if(d>0) tTime += d + "D ";
      if(h>0) tTime += h + "H ";
      if(m>0) tTime += m+"M";
      return tTime;     
    }else{
      return "-";
    }  
  }

  viewAssignee(id){
    let dialogRef = this.dialog.open(TechassignmentComponent, {
        panelClass: ['car-dialog-form'],
        width: "700px",
        data: { request_id: id, techStatus: true }
    });
    dialogRef.afterClosed().subscribe((res)=>{
        this.getAssignedTechinican();
    });
  }

  NavigateTo(srId){    
    window.open('shops/view-service-request/'+srId, '_blank');
  }

  getAssignedTechinican(){
    this.techloading    = true;   
    let content='user_id='+this.userData.id+'&user_type='+this.userData.user_type;
    if(this.techStatus){
      let data=content+'&request_id='+this.sidePanelContent.request_id;
      this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_SERVICE_REQUEST_TRACKERS,data).subscribe((r:any)=>{
        if(r.result==1){
          this.techloading    = false;
          this.technicianList = r.data;
        }else{
          this.techloading    = false;
          this.technicianList = [];  
        }
      })
    }
  }

  newTechAssignment(){
    /*let dialogRef = this.dialog.open(AssignemployeeComponent, {
      panelClass: ['quickapptdialog'],
      width: "100%",
      data: {
        requestId:this.sidePanelContent.request_id,
        startTime:null,
        endTime:null,
        eventType:2,
        fromSRPopup:false,
        requested_delivery_date:moment(this.sidePanelContent.requested_delivery_date).toISOString()
      }
    });

    dialogRef.afterClosed().subscribe((res)=>{
      if(res){
        this.getAssignedTechinican();
        this.loadCalendarEvents(); 
      }
    });*/
    if(this.isEmployee==false){
      return false;
    }
    this.techData= {request_id: this.sidePanelContent.request_id};
  }

  isAllowed(module,action){
    return this.gs.getAcl(module,action);
  }

  SrUpdate(event ){
    let dialogRef = this.dialog.open(ServiceFieldUpdateComponent, {
      panelClass: 'car-dialog-form',
      width: '700px',
      data: {form_event: this.sidePanelContent.event_type==1?true:false, event_type: event, sr_data:{dialogMetaData:this.sidePanelContent}},
    });
    dialogRef.afterClosed().subscribe((response: any) => {
      if(response==true){
        let contant = "user_id="+this.userData.id+"&user_type="+this.userData.user_type+"&request_id="+this.sidePanelContent.request_id+"&event_type="+this.sidePanelContent.event_type+"&usr_sr_id="+this.sidePanelContent.usr_sr_id;
        this.gs.callAPI(globalConstants.API_GET_SERVICE_REQUEST,contant).subscribe((res:any) => {
          if (res.result == 1) {   
            if(event=='appt_date') {
              this.sidePanelContent.requested_delivery_date = moment(res.data.requested_delivery_date).toISOString();
              this.loadCalendarEvents(); 
            }           
          }
        })
      }    
    });
  }

  convertISODate(dateString){
    return moment(dateString).toISOString();
  }

  removeLeave(){
    this.gs.confirmDialog('Yes', 'Are you sure you want to delete?').subscribe((result:any) => {     
      if (result == 'Yes') {
        let contant = "user_id="+this.userData.id+"&user_type="+this.userData.user_type+"&emp_id="+this.sidePanelContent.emp_id+"&id="+this.sidePanelContent.app_id;
        this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_DELETE_LEAVE,contant).subscribe((res:any) => {
            if (res.result == 1) { 
              this.drawer.toggle();
              this.loadCalendarEvents(); 
            }
        });
      }
    });
  }

  /* image rotation dialog  */
  imageToolDialog(current,i,type,imgaar): void {
    let dialogRef = this.dialog.open(ImagetooldialogComponent, {
        panelClass: ['imageToolDialog'],
        width: "100%",
        data: {event: current,index:i,imgType:type,imgArray:imgaar,customerId:this.gs.getCurrentUser()["id"]}
    });

    dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
    });
  }

  invokeDownload(url,type,fileName=''){
    let localFileName = 'DownloadedFile',localNameExt,localName;
    if(type != ''){
      localName = fileName.split('.');
      localNameExt = localName[localName.length-1];
      localFileName = type+'.'+localNameExt;
    }
    //console.log(localFileName);
    this.gs.downloadAsset(url,localFileName);
  }
  
  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
  }  

}
