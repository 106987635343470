import { Component, OnInit, ViewChild, Pipe, Inject,  Optional, OnDestroy  } from '@angular/core';
import { AbstractControl, FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { globalFunctions } from "../../../../environments/globalFunctions";
import { globalConstants } from "../../../../environments/globalConstants";
import { GlobalService } from "../../../../environments/global.service";
import { DiscountRulesComponent, Condition } from '../discount-rules/discount-rules.component';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-edit-discount',
  templateUrl: './edit-discount.component.html',
  styleUrls: ['./edit-discount.component.scss']
})
export class EditDiscountComponent implements OnInit, OnDestroy {

  @ViewChild('editDiscountRules') editDiscountRules:DiscountRulesComponent;
  
  public shopEditDiscount: FormGroup;
  public submitted = false;
  public rulesSubmitted = false;
  public currentUser;
  public discountId;
  public discount_type;
  public discount_multiple_usage;
  public minEndDate = new Date();
  public discountDetail:any;
  public discountStatus:any;
  public statusList:any = [
    {id:'1',text:'Active'},
    {id:'0',text:'Inactive'}
  ];
  
  conditionTypes:Array<any>;
  
  rule:Condition = {
    type:globalConstants.DISCOUNT_CONDITION_TYPE.value,
    aggregator:"all",
    root:true,
    conditions:[]
  }
  
  customerData:Array<any>

  /**
   * Accordion UI variables
   */
  rulesAccordion:boolean = true;
  codeDetailsAccordion:boolean = true;
  actionAccordion:boolean = true;
  
  /**
   * Popup ui variables
   */
  isPopup:boolean;
  public subscriptionDataForAPI: Subscription = new Subscription();
  constructor(private formBuilder: FormBuilder, private router: Router, private activatedRoute: ActivatedRoute, public globalService: GlobalService, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, @Optional() public dialogRef: MatDialogRef<EditDiscountComponent>) {
    if(data && data.isPopup){
      this.isPopup = true;
    }
    if (localStorage.getItem("loggedInUser")) {
      this.currentUser = JSON.parse(localStorage.getItem("loggedInUser"));
    } else {
      router.navigate(["/shops"])
    }
    setTimeout(()=>{
      let inSubscription:boolean=true;
      // inSubscription = this.globalService.isSubscribe(globalConstants.SUBSCRIPTION_DISCOUNTS_FEATURE);
      let isEnable=this.globalService.getAcl('discounts','edits');
      if(!(isEnable) || !(inSubscription)){
        this.router.navigate(['shops/action/forbidden']);
      }
     },globalConstants.RELOAD_WAIT);
  }

  ngOnInit() {
    this.globalService.setMetaData("SHOPS", "EDIT_DISCOUNT");
    this.getParams();
    this.initialializeEditDiscountForm();
    this.initializeFormDetails();
  }

  getParams() {
    if(this.data && this.data.isPopup && this.data.couponId){
      this.discountId = this.data.couponId;
    }
    else{
      this.activatedRoute.params.subscribe((params: Params) => {
        this.discountId = params["id"];
      });
    }
  }

  initialializeEditDiscountForm() {
    this.shopEditDiscount = this.formBuilder.group({
      discount_name: ['', Validators.required],
      discount_code: [{ value: '', disabled: true }, Validators.required],
      startDateObj: ['', Validators.required],
      endDateObj: ['', Validators.required],
      type: [{ value: '', disabled: true }, Validators.required],
      type_value: ['', [Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/), Validators.min(1)]],
      max_redemption_limit:['',Validators.min(1)],
      multiple_usage: [{ value: '', disabled: true }, Validators.required],
      usage_limit: ['',Validators.min(1)],
      usage_per_customer_limit: ['',Validators.min(1)],
      status: [''],
      no_end_date: [false],
      is_combinational:['no'],
      customers:['']
    });
    this.shopEditDiscount.controls['no_end_date'].valueChanges.subscribe((updatedVal)=>{
      
      if(updatedVal){
        this.shopEditDiscount.controls['endDateObj'].clearValidators();
        this.shopEditDiscount.controls['endDateObj'].updateValueAndValidity();
      }
      else{
        this.shopEditDiscount.controls['endDateObj'].setValidators([Validators.required]);
        this.shopEditDiscount.controls['endDateObj'].updateValueAndValidity();
      }
    });
  }

  initializeFormDetails(){
    let data = { user_id: this.currentUser.id, user_type: this.currentUser.user_type, discount_id: this.discountId };
    this.getDiscountMasterData();
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_SHOP_DISCOUNT_URL, data).subscribe((r:any) => {
      if (r.result == 1) {
        let th = this;
        this.discountDetail = r.data;
        this.getCustomersList();
        if(this.discountDetail.shop_id != this.currentUser.shop_id){
          this.router.navigate(['shops/discounts']);
        }
        setTimeout(()=>{
          this.shopEditDiscount.get('discount_name').setValue(this.discountDetail.name);
          this.shopEditDiscount.get('discount_code').setValue(this.discountDetail.code);
          let ds=this.globalService.getFullFormateDate(this.discountDetail.start_date, 'G', 'H');
          let de=this.globalService.getFullFormateDate(this.discountDetail.end_date, 'G', 'H');
          if(ds!='-') this.shopEditDiscount.get('startDateObj').setValue(new Date(ds));
          this.minEndDate = this.shopEditDiscount.get('startDateObj').value;
          if(de!='-') this.shopEditDiscount.get('endDateObj').setValue(new Date(de));
          this.discount_type = this.discountDetail.type;
          this.shopEditDiscount.get('type').setValue(this.discount_type);
          let typeVal = this.discount_type == 1 ? this.discountDetail.abs_value : this.discountDetail.percent_value;
          this.shopEditDiscount.get('type_value').setValue(typeVal);
          this.shopEditDiscount.get('max_redemption_limit').setValue(this.discountDetail.max_redemption_limit ? this.discountDetail.max_redemption_limit : '');
          this.discount_multiple_usage = this.discountDetail.multiple_usage;
          this.shopEditDiscount.get('multiple_usage').setValue(this.discount_multiple_usage);
          this.discountStatus = this.discountDetail.status;
          this.shopEditDiscount.get('usage_limit').setValue(this.discountDetail.usage_limit ? this.discountDetail.usage_limit : '');
          this.shopEditDiscount.get('usage_per_customer_limit').setValue(this.discountDetail.usage_per_customer_limit ? this.discountDetail.usage_per_customer_limit : '');
          
          this.shopEditDiscount.get('no_end_date').setValue((this.discountDetail.no_end_date == 1 ? true:false));
          this.shopEditDiscount.get('is_combinational').setValue(this.discountDetail.is_combinational);
          
          this.toggleTypeValidation();
          this.toggleUsageValidation();
          if(this.discountDetail.rules){
            this.rule = {...(JSON.parse(this.discountDetail.rules))};
            this.rule.removed = false;
          }
        }, 300);
      }else{
        let message = r.message;
        this.router.navigate(['shops/discounts']);
        this.globalService.snackbar('error', message);
      }
    }, (error) => { })
  }

  handleStartDateChange(){
    this.minEndDate = this.shopEditDiscount.get('startDateObj').value;
    this.shopEditDiscount.get('endDateObj').setValue('');
  }

  dateFormatWithoutTime(dateValue) {
    return new Date(dateValue).getFullYear() + "-" + ((new Date(dateValue).getMonth()+1) < 10 ? '0' : '')+(new Date(dateValue).getMonth()+1)+'-'+(new Date(dateValue).getDate() < 10 ? '0' : '')  + new Date(dateValue).getDate();
  }

  toggleTypeValidation(){
    this.shopEditDiscount.get('type').setValue(this.discount_type);
    this.shopEditDiscount.get('type').updateValueAndValidity();
    if(this.discount_type == 1){
      this.shopEditDiscount.get('type_value').setValidators([Validators.required,Validators.min(1)]);
      this.shopEditDiscount.get('type_value').updateValueAndValidity();
      this.shopEditDiscount.get('max_redemption_limit').clearValidators();
      this.shopEditDiscount.get('max_redemption_limit').updateValueAndValidity();
    }else{
      this.shopEditDiscount.get('type_value').setValidators([Validators.required,Validators.min(1),Validators.max(100)]);
      this.shopEditDiscount.get('type_value').updateValueAndValidity();
      this.shopEditDiscount.get('max_redemption_limit').setValidators([Validators.required,Validators.min(1)]);
      this.shopEditDiscount.get('max_redemption_limit').updateValueAndValidity();
    }
  }

  isNotNumber(num){
    return isNaN(num);
  }

  toggleUsageValidation() {
    if (this.discount_multiple_usage == 2) {
      this.shopEditDiscount.get('usage_limit').setValidators([Validators.required,Validators.min(1)]);
      this.shopEditDiscount.get('usage_limit').updateValueAndValidity();
      this.shopEditDiscount.get('usage_per_customer_limit').setValidators([Validators.required,Validators.min(1)]);
      this.shopEditDiscount.get('usage_per_customer_limit').updateValueAndValidity();
    } else {
      this.shopEditDiscount.get('usage_limit').clearValidators();
      this.shopEditDiscount.get('usage_limit').updateValueAndValidity();
      this.shopEditDiscount.get('usage_per_customer_limit').clearValidators();
      this.shopEditDiscount.get('usage_per_customer_limit').updateValueAndValidity();
    }
    this.shopEditDiscount.get('multiple_usage').setValue(this.discount_multiple_usage);
    this.shopEditDiscount.get('multiple_usage').updateValueAndValidity();
  }

  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
  }

  onlyNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) return false;
    return true;
  }

  disableKey(event) {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey == true && (event.which == '67' || event.which == '86' || String.fromCharCode(event.which).toLowerCase() == 's')) {
      event.preventDefault();
    }
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.id == o2.id;
  }

  checkIfValid() {
    setTimeout(() => {
      if (this.shopEditDiscount.valid) {
        this.submitted = false;
      }
    }, 0);
  }

  editDiscountCoupon(opr) {
    this.shopEditDiscount.get('type').setValue(this.discount_type);
    this.shopEditDiscount.get('multiple_usage').setValue(this.discount_multiple_usage);
    this.shopEditDiscount.get('status').setValue(this.discountStatus);

    this.shopEditDiscount.addControl('start_date',new FormControl(''));
    this.shopEditDiscount.addControl('end_date',new FormControl(''));
    let startDateObj = this.shopEditDiscount.get('startDateObj').value;
    let endDateObj = this.shopEditDiscount.get('endDateObj').value;
    this.shopEditDiscount.get('start_date').setValue(startDateObj ? this.dateFormatWithoutTime(startDateObj) : '');
    this.shopEditDiscount.get('end_date').setValue(endDateObj ? this.dateFormatWithoutTime(endDateObj) : '');

    Object.keys(this.shopEditDiscount.controls).map((controlName) => {
      this.shopEditDiscount.get(controlName).markAsTouched({ onlySelf: true });
    });
    // let discountRules = this.addDiscountRules.getRulesAndConditions();
    let discountRules = this.editDiscountRules.getRulesAndConditionsStringified();
    // this.clearRulesJSON(this.rule);
    
    if(this.shopEditDiscount.valid && discountRules) {
        var data = new FormData();
        data.append('discount_id',this.discountId);
        data.append('user_type',this.currentUser.user_type);
        data.append('user_id',this.currentUser.id);
        data.append('discount_rules', discountRules);
        if(this.shopEditDiscount.controls['customers'].value){
          data.append('customers', this.shopEditDiscount.controls['customers'].value.join(','));
        }
        else{
          data.append('customers', '');
        }
        data.append('no_end_date', (this.shopEditDiscount.controls['no_end_date'].value ? '1' : '0'));
        let exceptions = ['startDateObj','endDateObj', 'customers', 'no_end_date'];
        for(let i in this.shopEditDiscount.value){
          if(exceptions.indexOf(i) == -1) data.append(i, this.shopEditDiscount.value[i]);
        }
        this.globalService.formData(globalConstants.API_EDIT_SHOP_DISCOUNT_URL,data).subscribe((data:any)=> {
            const message = data.message;
            if (data.result == "1") {
              if(!this.isPopup){
                if (opr == 1) {
                  this.router.navigate(["/shops/discounts"]);
                  this.globalService.snackbar('success', message);
                } else if (opr == 2) {
                  this.globalService.setScrolling("body", 500, 0);
                  this.globalService.snackbar('success', message);
                }
              }
              else{
                if (opr == 1) {
                  this.dialogRef.close();
                  this.globalService.snackbar('success', message);
                } else if (opr == 2) {
                  this.globalService.setScrolling("body", 500, 0);
                  this.globalService.snackbar('success', message);
                }
              }
            } else {
                this.globalService.snackbar('error', message);
            }
        });
    } else {
        this.submitted = true;
        this.mandatoryMsg();
    }
  }

  mandatoryMsg() {
    this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
  }

  getDiscountMasterData(){
    let body:any = {
      user_type:this.currentUser.user_type,
      user_id:this.currentUser.id
    }
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_DISCOUNT_RULE_OPTIONS_URL, body)
    .subscribe((response:any)=>{
      
      if(response.result == 1){
        this.conditionTypes = response.data;
        this.conditionTypes.push(globalConstants.DISCOUNT_CONDITION_TYPE);
        this.conditionTypes.sort((a,b)=>{
          return a.name > b.name ? 1 : -1;
        });
      }
      else{
        this.globalService.snackbar('error', response.message);
      }
    })
  }
  
  getCustomersList(){
    let body:any = {
      user_type:this.currentUser.user_type,
      user_id:this.currentUser.id
    }
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_SHOP_CUSTOMERS_DATA_URL, body)
    .subscribe((response:any)=>{
      
      if(response.result == 1){
        this.customerData = response.data;
        this.customerData.forEach((customer)=>{
          customer.label = customer.name+'('+customer.email+')';
        });
        if(this.discountDetail.customers && this.discountDetail.customers.length > 0){
          this.shopEditDiscount.get('customers').setValue(this.discountDetail.customers);
        }
      }
    });
  }
  
  expandAccordion(accordion:boolean){
    accordion = !accordion;
  }
  
  areRulesValid(ev){
   
    this.rulesSubmitted = !ev;
  }
  
  updateSubmitted(ev){
   
    this.submitted = false;
  }
  
  getSelectedCustomers(ev:any){
   
  }
  
  customerRemoved(ev){
   
  }
  
  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
  } 
}
