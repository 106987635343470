import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort} from '@angular/material/sort';
import {  MatDialog } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import {Subscription} from 'rxjs';
import { globalConstants } from '../../../../environments/globalConstants';
import { CustomdatatableComponent,CustomdatatableSource } from '../../../customdatatable/customdatatable.component';
import { Router } from '@angular/router';
import { AddNexpartVendorComponent } from "../add-nexpart-vendor/add-nexpart-vendor.component";
import { EditNexpartVendorComponent } from "../edit-nexpart-vendor/edit-nexpart-vendor.component";
import { MatTableDataSource } from '@angular/material/table';
import { NexpartLaborNotesSettingComponent} from '../nexpart-labor-notes-setting/nexpart-labor-notes-setting.component';
const SHOP_NEXPART_VENDORS_LIST = "nexpart-vendors-";
@Component({
  selector: 'app-list-vendors',
  templateUrl: './list-vendors.component.html',
  styleUrls: ['./list-vendors.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListVendorsComponent extends CustomdatatableComponent implements OnInit, OnDestroy {

  public currentUser: any = {};
  public openStateSearch = false;
  public displayedColumns = ['name', 'username', 'vendor_logo', 'add_date', 'Actions'];
  public tablesData=this;
  public url="";
  public options = {
    user_type: "User",
    user_id: "",
    search_by: "",
    search_keyword:  "",
    start: 0 ,
    limit: 10 ,
  }
  public searchByArray=[{id:1,text:'Name'},{id:2,text:'Code'}];
  public isEditEnabled:boolean;
  public isDelEnabled:boolean;
  public isAddEnabled:boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  logoBaseUrl:string = globalConstants.S3_BUCKET_URL+globalConstants.NEXPART_LOGO;
  defaultLogo:string = globalConstants.NEXLINK_DEFAULT_VENDOR_LOGO;
  vendorNameVisibility:boolean = true;
  public subscriptionDataForAPI: Subscription = new Subscription();
  dataSource: MatTableDataSource<vendorsListData> = new MatTableDataSource();
  public record :any =[]; 


  constructor(public gs: GlobalService,public router: Router, public dialog: MatDialog) {
    super(globalConstants.API_GET_NEXLINK_VENDORS_URL, gs);
    this.currentUser = this.gs.getCurrentUser();
    if (this.currentUser) {
      this.sessionKey = SHOP_NEXPART_VENDORS_LIST;
      this.url = globalConstants.API_GET_NEXLINK_VENDORS_URL;
    } else {
      this.router.navigate(["/shops"]);
    }
    setTimeout(()=>{
     this.isEditEnabled=this.gs.getAcl('nexpart_creds','edits');
     this.isDelEnabled=this.gs.getAcl('nexpart_creds','deletes');
     this.isAddEnabled=this.gs.getAcl('nexpart_creds','addNew');
     let inSubscription:boolean=true;
      // inSubscription = this.gs.isSubscribe(globalConstants.SUBSCRIPTION_DISCOUNTS_FEATURE);
     let isEnable=this.gs.getAcl('nexpart_creds','views');
      if(!(isEnable) || !(inSubscription)){
        this.router.navigate(['shops/action/forbidden']);
      }
    },globalConstants.RELOAD_WAIT);
  }

  ngOnInit() {
    this.initializeData();
    this.getVendorNameSetting();
  }
  
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  initializeData(){
    this.reinitializeOptions();
    if(this.checkFormEmpty(null)){
      this.openStateSearch = true;
    }else{
      this.openStateSearch = false;
    }
    this.isReady = true;
    this.gs.setMetaData("SHOPS", "NEXPART_VENDORS_LIST")
    this.setDataTable()
  }
  
  getVendorNameSetting(){
    this.vendorNameVisibility = (this.gs.getShopDetails('nexlink_vendor_name_display') == 1) ? true : false;
  }
  
  onVendorNameToggle(ev:any){
    let body = {
      nexlink_vendor_name_display: ((!this.vendorNameVisibility) ? 1 : 0),
      user_id: this.currentUser.id,
      user_type: this.currentUser.user_type
    }
    this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_NEXLINK_VENDOR_NAME_DISPLAY_URL, body).subscribe(data => {
      let resultData:any = data;
      if (resultData.result == 1) {
        this.vendorNameVisibility = !this.vendorNameVisibility;
        let tmpShopDetails = this.gs.getShopDetails();
        tmpShopDetails['nexlink_vendor_name_display'] = body.nexlink_vendor_name_display;
        this.gs.setShopDetails(tmpShopDetails);
      } else {
        this.gs.snackbar('error', resultData.message);
      }
    });
  }

  addNexpartVendor(){
    // this.router.navigate(["/shops/nexpart/add-vendor"]);
    let dialogRef = this.dialog.open(AddNexpartVendorComponent, {
      panelClass: 'car-dialog-form',
      width:'700px',
      data: { isPopup: true }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('add coupon closed = ', result);
      if(result == 1){
        this.initializeData();
      }
    });
  }

  editDiscount(row){
    // this.router.navigate(["/shops/nexpart/edit-vendor/"+row.id]);
    let dialogRef = this.dialog.open(EditNexpartVendorComponent, {
      panelClass: 'car-dialog-form',
      width:'700px',
      data: { isPopup: true, vendorId:row.id }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('edit coupon closed = ', result);
      if(result == 1){
        this.initializeData();
      }
    });
  }

  deleteDiscount(row){
    this.gs.confirmDialog("yes", "Are you sure you want to remove this vendor?").subscribe((r:any) => {
      if (r != undefined && r == "yes") {
        let data = { user_id: this.currentUser.id, user_type: this.currentUser.user_type, vendor_id: row.id };
        this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_REMOVE_NEXLINK_VENDOR_URL, data).subscribe((r:any) => {
          let message = r.message;
          if (r.result == 1) {
            this.gs.snackbar('success', message);
            this.setDataTable();
          }else{
            this.gs.snackbar('error', message);
          }
        }, (error) => { });
      }
    })
  }

  search(event) {
    this.options.start = 0;
    this.setDataTable();
  }
  
  clearSearch() {
    this.openStateSearch = false;
    this.gs.localGetterSetter("", this.options, SHOP_NEXPART_VENDORS_LIST);
    this.options.search_keyword= "";
    this.reinitializeOptions();
    this.setDataTable();
  }

  reinitializeOptions() {
    this.options = this.gs.localGetterSetter(this.options, this.options, SHOP_NEXPART_VENDORS_LIST);
    this.options.user_type = this.currentUser.user_type;
    this.options.user_id = this.currentUser.id;
  }

  dateFormatWithoutTime(dateValue) {
    return ((new Date(dateValue).getMonth()+1) < 10 ? '0' : '')+(new Date(dateValue).getMonth()+1)+'/'+(new Date(dateValue).getDate() < 10 ? '0' : '')  + new Date(dateValue).getDate()+'/'+(new Date(dateValue).getFullYear());
  }

  createRecordrecord(record) {
    return {
      name: record.name,
      username: record.username,
      vendor_logo: record.vendor_logo,
      id: record.id,
      add_date: this.gs.getFullFormateDate(record.add_date,'G',null)
    };
  }

  checkFormEmpty(button) {
    if (this.options.search_keyword == "") {
        if (button) {button.color = "primary"}
        return false
    }else {
        if (button) {button.color = "accent"}
        return true;
    }
  }

 setDataTable() {
    // this.dataSource = new CustomdatatableSource(this.tablesData, this.sort, this.paginator);
    // this.dataSource.getSortedData=this.sortedData;
    this.record = [];
    var content = this.options;
    this.isGetData=false
    this.callApi = this.gs.callAPI(this.url, content);
    this.callApi.subscribe((data) => {
      this.apiCaller.next(data);
      this.isGetData=true;
      let responseData = data.data
      if (responseData != undefined) {
        let moreRows = 'false';
        let firstList = 'false';
        this.gs.localGetterSetter("totalRecords", data.recordsTotal, this.sessionKey);
        if (data.more_rows == "true") {
          moreRows = "true";
        }
        this.gs.localGetterSetter("more_rows", moreRows, this.sessionKey);
        if (data.start >= 0 && data.start <= 5) {
          firstList = "true";
        }
        this.gs.localGetterSetter("first_list", firstList, this.sessionKey);
        if (responseData.length != undefined) {
          this.recordsTotal = responseData.length;
          for (var i = 0; i < responseData.length; i++) {
            this.AddRecord(responseData[i])
          }
          this.dataSource = new MatTableDataSource(this.record)
          this.dataSource.sort = this.sort;
        } else {
          this.recordsTotal = "1";
          this.AddRecord(responseData)
          this.dataSource = new MatTableDataSource(this.record)
          this.dataSource.sort = this.sort;
        }
        this.gs.localGetterSetter(null, this.options, this.sessionKey);
      } else {
        this.dataSource = new MatTableDataSource(this.record)
        this.gs.localGetterSetter("totalRecords", '0', this.sessionKey);
      }
    },(error)=>{
      this.gs.localGetterSetter("totalRecords", '0', this.sessionKey);
    })
  }

  AddRecord(record){
    var data = this.createRecordrecord(record);
      this.record.push(data);
  }

  // sortedData(): any[] {
  //  let th:any=this;
  //  const data = th._exampleDatabase.data.slice();
  //   if (!th._sort.active || th._sort.direction == '') { return data; }

  //   return data.sort((a, b) => {
  //     let propertyA;
  //     let propertyB;
  //     switch (th._sort.active) {
  //       case 'Name': propertyA = a.name; propertyB = b.name; break;
  //       case 'Username': propertyA = a.username; propertyB = b.username; break;
  //       case 'Added On': propertyA = a.add_date; propertyB = b.add_date; break;
  //       default: return 0;
  //     }
  //     let valueA = isNaN(propertyA) ? propertyA.toUpperCase() : propertyA;
  //     let valueB = isNaN(propertyB) ? propertyB.toUpperCase() : propertyB;

  //     return (valueA < valueB ? -1 : 1) * (th._sort.direction == 'asc' ? 1 : -1);
  //   });
  // }


  openNexpartLaborSettingsDialog(){
     this.dialog.open(NexpartLaborNotesSettingComponent, {
      panelClass: 'car-dialog-form',
      width:'700px',
    });
  }

  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
  } 
}

export interface vendorsListData {
  name: any;
  username: any;
  added_by:any;
  vendor_logo: any;
  add_date: any;
  id:any;
}