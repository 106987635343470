import {Component, OnInit ,Inject} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {globalConstants} from "../../../environments/globalConstants";
import {GlobalService} from "../../../environments/global.service";
import {Subscription} from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-update-credit-card',
  templateUrl: './update-credit-card.component.html',
  styleUrls: ['./update-credit-card.component.scss']
})
export class UpdateCreditCardComponent implements OnInit {
  public ccForm: FormGroup;
  public currentCustomer:any;
  public currentCustomerName:any;
  public first_name:any;
  public last_name:any;
  public email:any;
  public address1:any;
  public city:any;
  public state:any;
  public zip:any;
  public country:any;
  public cell_phone1:any;
  public user_type:any;
  public user_id:any;
  public planName:any;
  public planAmount:any;
  public shop_id:any;
  public paymentSuccess:any;
  public subscriptionMessage:any;
  
  public isSubscribeld:any;
  public displayForm:any;
  public cardHolderName:any;
  public CardType:any;
  public ccMaskedNumber:any;
  public ccExpiryDay:any;
  public ccInfoResult:any;
  public defaultCheck:any;
  public displayNewForm:any;
  public paymentToken:any;
  public btCustomerID:any;
  public plan_id:any;
  public mailChimp:any;
  public subscriptionID:any;
  public subscriptionType:any;
  public btSubscriptionID:any;
  public subscriptionDataForAPI: Subscription = new Subscription();
  constructor(
       private router: Router,
       public dialogRef: MatDialogRef<UpdateCreditCardComponent>,
       @Inject(MAT_DIALOG_DATA) public data: any, 
       private formBuilder: FormBuilder, 
       private globalService: GlobalService
       ) {
      this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
      this.currentCustomerName = this.currentCustomer.first_name+" "+this.currentCustomer.last_name;
      /* USER LOGIN DETAILS */
      this.first_name     = this.currentCustomer.first_name;
      this.last_name      = this.currentCustomer.last_name;
      this.email          = this.currentCustomer.email;
      this.address1       = this.currentCustomer.address1;
      this.city           = this.currentCustomer.city;
      this.state          = this.currentCustomer.state;
      this.country        = this.currentCustomer.country;
      this.zip            = this.currentCustomer.zip;
      this.cell_phone1    = this.currentCustomer.cell_phone1;
      this.user_type      = this.currentCustomer.user_type;
      this.user_id        = this.currentCustomer.id;
      this.shop_id        = this.currentCustomer.shop_id;
      this.isSubscribeld  = this.currentCustomer.is_subscribed
      this.globalService.diaLogObj(this.dialogRef);
      if(this.isSubscribeld == 'true'){

          this.defaultCheck = true;
          let resquestdata = "user_id=" + this.globalService.getCurrentUser().id+ "&shop_id=" + this.globalService.getCurrentUser().shop_id+ "&user_type=" + this.globalService.getCurrentUser().user_type;
          this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_BT_CC_INFO_URL, resquestdata).subscribe((data:any) => {
              if (data.result == '1') {
                  this.ccInfoResult = data.data;
                  this.cardHolderName = this.ccInfoResult.cardholderName;
                  this.CardType       = this.ccInfoResult.cardType;
                  this.ccMaskedNumber = this.ccInfoResult.maskedNumber;
                  this.ccExpiryDay    = this.ccInfoResult.expirationDate;
                  this.paymentToken   = this.ccInfoResult.payment_token;
                  this.btCustomerID   = this.ccInfoResult.bt_customer_id;
                  this.btSubscriptionID = this.currentCustomer.subscription_data.bt_subscription_id
                  this.formInitWithNewCC();
              } 
          });
      } else {
          //this.displayForm = true;
          //this.defaultCheck = false;
          this.formInitWithNewCC();
      }
       //this.formInitWithNewCC();
      /* END */
      //this.planName = data.plan_name;
      //this.planAmount = parseFloat(data.plan_amount).toFixed(2);
  }
  
  formInitWithNewCC(){
      this.ccForm = this.formBuilder.group({
          cc_name: ['', [Validators.required,Validators.pattern(globalConstants.NAME_REGEX)]],
          cc_number: ['',[Validators.required, Validators.pattern(globalConstants.DIGIT_ONLY_REGEX)]],
          cvv: ['', [Validators.required, Validators.pattern(globalConstants.DIGIT_ONLY_REGEX)]],
          exp_month: ['', [Validators.required,Validators.pattern(globalConstants.DIGIT_ONLY_REGEX)]],
          exp_year: ['', [Validators.required, Validators.pattern(globalConstants.DIGIT_ONLY_REGEX)]],
          first_name: [this.first_name],
          last_name: [this.last_name],
          email: [this.email],
          address1: [this.address1],
          city: [this.city],
          state: [this.state],
          country: [this.country],
          zip: [this.zip],
          cell_phone1: [this.cell_phone1],
          user_type: [this.user_type],
          user_id: [this.user_id],
          shop_id: [this.shop_id],
          payment_token: [this.paymentToken],
          bt_customer_id: [this.btCustomerID],
      });
  }
  
  onlyDecimalNumberKey(event) {
      let charCode = (event.which) ? event.which : event.keyCode;
      if (charCode < 48 || charCode > 57)
          return false;
      return true;
  }

  disableKey(event) {
      let charCode = String.fromCharCode(event.which).toLowerCase();
      if (event.ctrlKey==true && (event.which == '67' || event.which == '86' ||  String.fromCharCode(event.which).toLowerCase() == 's')) {
          
          event.preventDefault();
      }
  }
  handleCardTypeImg() {
      let cardEle: any = <HTMLElement>document.getElementById('cardIcon');
      var cardImage = this.globalService.getCardTypeImage(this.ccForm.value.cc_number);
      cardEle.innerHTML = cardImage;
  }
  
  ngOnInit() {
      this.formInitWithNewCC();
  }
  /* Pay Now From CC Pop Up FIRST TIME PAYMENT */
  
  updateCCInfo(){ 
      if (this.ccForm.valid) {
          let data = new FormData();
          for (let i in this.ccForm.value) {
              data.append(i, this.ccForm.value[i]);
          }
          this.globalService.formData(globalConstants.API_UPDATE_SHOP_BT_CC,data).subscribe((data:any)=> {
              //localStorage.setItem("shopPaySuccessMsg", data.message);
              if (data.result == "1") {
                  this.paymentSuccess = true;
                  this.globalService.snackbar('success', data.message);
                  return this.dialogRef.close({data: data.masked_cc});
              } else {
                  this.paymentSuccess = false;
                  this.globalService.snackbar('error', data.message);
              }
          });
      } 
  } 
  
  close(){
       return this.dialogRef.close({ data: 3 });
  }

  validateFieldOnPaste(controlName: string) {
      const control = this.ccForm.get(controlName);
      control.markAsTouched();
  }

  ngOnDestroy(){
      
      this.subscriptionDataForAPI.unsubscribe();
  }

}
