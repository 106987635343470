import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { globalFunctions } from '../../../../environments/globalFunctions';
import {Http, Headers, Response, ResponseContentType} from '@angular/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import  saveAs  from 'file-saver';

@Component({
    selector: 'app-payment-dialog',
    templateUrl: './payment-dialog.component.html',
    styleUrls: ['./payment-dialog.component.scss']
})
export class PaymentDialogComponent implements OnInit{
    public d='';
    public addPayment: FormGroup;
    public addReminder: FormGroup;
    public checkMethod: boolean = false;
    public creaditCard: boolean = false;
    public newReminderShow: boolean = false;
    public paymentId: string;
    public paymentData = {};
    // public dataSources = { data: [],total_paid:0 ,balance:'',request_status:'1' };
    public dataSources = { data: [],total_paid:0,total_cc_fee:0 ,balance:'',request_status:'1', discounts:{total_discounted_amount: '0.00', discounts_list:{applicable:[], inapplicable:[]}}};
    public maxDate = new Date();
    public listShop;
    public maxDates=(this.maxDate.getMonth()+1)+'/'+this.maxDate.getDate()+'/'+this.maxDate.getFullYear();
    public accessLimit={};
    public isAddInPayment;
    public isCCFeeIncluded = false;
    public isMarkAsPaid=false;
    public paymentMethod=[{id:'1',text:'Cash'},{id:'2',text:'Check'},{id:'3',text:'Credit Card'},{id:'4',text:'Other'}];
//    serializedDate = new FormControl((new Date()).toISOString());
    public options = {
        request_id: "",
        user_type: "User",
        status: localStorage.getItem("status") || '',
        start: parseInt(localStorage.getItem("start")) || 0,
        limit: parseInt(localStorage.getItem("limit")) || 10,
        schedule_service_date: localStorage.getItem("schedule_service_date") || "",
        requested_delivery_date: localStorage.getItem("requested_delivery_date") || "",
        estimated_delivery_date: localStorage.getItem("estimated_delivery_date") || "",
    }
    public onTheLot;
    public requestStatus;
    public serviceDetail;
    couponRedeemConditions:Array<boolean> = [];
    public netPayable:any = 0 ;
    public ccFee = 0;
    paymentAdded = false;
    public fullRequestId:any;
    constructor( @Inject(MAT_DIALOG_DATA) public data: any,private http: Http, public dialogRef: MatDialogRef<PaymentDialogComponent>, private formBuilder: FormBuilder, private globalService: GlobalService) {
        this.addPayment = this.formBuilder.group({
            payment_method: ['1'],
            payment_amount: ['', Validators.required],
            cc_number: [''],
            paymentDate: [''],
            cheque_number: [''],
            bank_name: [''],
            payment_notes: [''],
            payment_request_id: [data.request_id],
            cc_fee: [''],
            cc_fee_type: [''],
            cc_fee_percentage: [''],         
        });
        this.addReminder = this.formBuilder.group({
            payment_reminder_date: ['', Validators.required],
            payment_req_id: [data.request_id]
        });
        this.listShop=(typeof data.obj!='undefined'?data.obj:null);
        this.options.request_id=data.request_id;
        // this.getPaymentDetail(data.request_id);
        this.accessLimit=data.accessLimit;
        this.isAddInPayment= data.isAddInPayment;
        this.isMarkAsPaid=this.globalService.getAcl('mark_as_paid','addNew');
        this.globalService.diaLogObj(this.dialogRef);
        this.fullRequestId = data.fullRequestId;
    }

    ngOnInit(){
        this.setCouponRedeemConditions();
        if(this.options.request_id) {
            this.getPaymentDetail(this.options.request_id);
            // this.getServiceDetail();
        }
        this.paymentAmountChange('payment_amount');
    }

    changeMethod(event?:any) {
        if(typeof event=='string'){
            event={value:event};
        }
        if (event.value == 2) {
            this.checkMethod = true;
            this.creaditCard = false;
            this.addPayment.controls.cc_number.setValue('');
        } else if (event.value == 3) {
            this.creaditCard = true;
            this.checkMethod = false;
            this.addPayment.controls.cheque_number.setValue('');
            this.addPayment.controls.cc_fee_type.setValue(this.serviceDetail.cc_fee_type);
            if(this.addPayment.controls.payment_amount.value == ''){
                this.calculateTotalAmount(this.dataSources.balance.replace(/,/g,''));
            }else{
                this.calculateTotalAmount(this.addPayment.controls.payment_amount.value);
            }
        } else {
            this.checkMethod = false;
            this.creaditCard = false;
            this.addPayment.controls.cheque_number.setValue('');
            this.addPayment.controls.cc_number.setValue('');
        }

    }
    onlyNumericKey(event) {
        return globalFunctions.onlyDecimalNumberKey(event);
    }
    getPaymentDetail(r_id) {
        let data = { request_id: r_id };
        let params = globalFunctions.stringifyFormData(data, this.globalService.getCurrentUser()["user_type"]);

        this.globalService.callAPI(globalConstants.API_GET_PAYMENT_LIST_URL, params).subscribe((response:any) => {
            this.dataSources = response;
            if (typeof this.dataSources.data == 'undefined') {
                this.dataSources.data = [];
            };
            if(this.dataSources.balance=='-'){
                this.dataSources.balance='0.00';
            }
            for(var i=0;i<this.dataSources.data.length;i++){
              this.dataSources.data[i].payment_date = this.globalService.getFullFormateDate(this.dataSources.data[i].payment_date,'GD','H');
            }
            // To update for new response.
            // this.dataSources.total_discount = this.dataSources.discounts.total_discounted_amount;
            // this.dataSources.balance = Number(parseFloat(this.dataSources.balance) - parseFloat(this.dataSources.total_discount)).toFixed(2);
            this.dataSources.discounts.discounts_list.applicable = this.dataSources.discounts.discounts_list.applicable || response.discounts.discounts_list;
            this.dataSources.discounts.discounts_list.inapplicable = this.dataSources.discounts.discounts_list.inapplicable || response.discounts.discounts_list;
            this.getServiceDetail();
        });
    }
    getPayMethod(id) {
        let payMethod = '';
        switch (id) {
            case '1': payMethod = 'Cash'; break;
            case '2': payMethod = 'Check'; break;
            case '3': payMethod = 'Credit Card'; break;
            case '4': payMethod = 'Other'; break;
            default: payMethod = 'not found'; break;
        }
        return payMethod;
    }
    toNummber(string){
      return parseInt(string)
    }
    addPayments() {
        let fcData = this.addPayment;
        for (let inner in this.addPayment.controls) {
            this.addPayment.get(inner).markAsTouched();
            this.addPayment.get(inner).updateValueAndValidity();
        }
        let th=this;
        if (fcData.valid) {
            if(fcData.controls.payment_method.value != 3){
                fcData.controls.cc_fee.setValue(0);
            }
            if(fcData.controls.paymentDate.value){
             let d = new Date(fcData.controls.paymentDate.value);
             fcData.value.paymentDate = (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear();
            }
            let params = globalFunctions.stringifyFormData(fcData.value, this.globalService.getCurrentUser()["user_type"]);
            this.globalService.callAPI(globalConstants.API_ADD_PAYMENT_URL, params).subscribe((response:any) => {
                if (response.result == 1) {
                    this.paymentAdded = true;
                    this.globalService.snackbar("success", response.message);
                    this.dialogRef['addAmount']=true;
                    this.addPayment.controls.payment_method.setValue('1');
                    this.changeMethod({value:1});
                    this.addPayment.controls.payment_amount.setValue('');
                    this.addPayment.controls.cc_number.setValue('');
                    this.addPayment.controls.paymentDate.setValue('');
                    this.addPayment.controls.cheque_number.setValue('');
                    this.addPayment.controls.bank_name.setValue('');
                    this.addPayment.controls.payment_notes.setValue('');
                    if(this.serviceDetail.cc_fee_type == "1"){
                        this.addPayment.controls.cc_fee.setValue(this.serviceDetail.cc_fee);
                        this.addPayment.controls.cc_fee_percentage.setValue(0);
                    }else if(this.serviceDetail.cc_fee_type == "2"){
                        this.addPayment.controls.cc_fee.setValue(0);
                        this.addPayment.controls.cc_fee_percentage.setValue(this.serviceDetail.cc_fee);
                    }
                    th.addPayment.markAsUntouched();
                    this.addPayment.controls.payment_amount.setErrors(null);
                    // Object.keys(th.addPayment.controls).forEach((name) => {
                    //     let control = th.addPayment.controls[name];

                    //   });
                    // this.addPayment.controls.payment_request_id.setValue(this.options.request_id);
                    this.getPaymentDetail(fcData.value.payment_request_id);
                } else {
                    if(this.serviceDetail.cc_fee_type == "1"){ //cc fee type amount is fixed 
                        this.addPayment.controls.cc_fee.setValue(this.serviceDetail.cc_fee);
                        this.addPayment.controls.cc_fee_percentage.setValue(0);
                    }else if(this.serviceDetail.cc_fee_type == "2"){ //cc fee type is percentage
                        this.addPayment.controls.cc_fee.setValue(this.addPayment.controls.cc_fee.value);
                        this.addPayment.controls.cc_fee_percentage.setValue(this.serviceDetail.cc_fee);
                    }
                    this.addPayment.controls.paymentDate.setValue('');
                    this.globalService.snackbar("error", response.message);
                }
            });
        } else {
            this.globalService.snackbar("error", 'Enter Payment Amount');
        }
    }

    closePopup(){
        this.dialogRef.close({ paymentAdded : this.paymentAdded });
    }
 /**
   * @author Vikas Kumar
   * @trackID <:CAR-1528>
   * @updated 20-04-2020
   */
    markAsPaid(r_id) {
        let data = { user_id: this.globalService.getCurrentUser()['id'], request_id: r_id };
        let conf='Are you sure you want to mark this request as paid?';
        let params = globalFunctions.stringifyFormData(data, this.globalService.getCurrentUser()["user_type"]);
        this.globalService.confirmDialog('markAsPaid', conf).subscribe((r) => {
            let res:any=r;
          if(res=='markAsPaid'){
        // if (confirm('Are you sure you want to mark this request as paid?')) {
            this.globalService.callAPI(globalConstants.API_UPDATE_SERVICE_REQUEST_STATUS_AS_PAID_URL, params).subscribe((response:any) => {
                if (response.result == 1) {
                    this.paymentAdded = true;
                    this.globalService.snackbar("success", response.message);
                    this.getPaymentDetail(r_id);
                    if(this.listShop){
                        this.listShop.setDataTable();
                    }
                } else {
                    this.globalService.snackbar("error", response.message);
                }
            });
        }
        })
    }
 /**
   * @author Vikas Kumar
   * @trackID <:CAR-1528>
   * @updated 20-04-2020
   */
    deletePayment(r_id, p_id) {
        let data = { data_pay_id: p_id, data_req_id: r_id };
        let conf='Are you sure you want to delete the payment entry?';
        let params = globalFunctions.stringifyFormData(data, this.globalService.getCurrentUser()["user_type"]);
        this.globalService.confirmDialog('markAsPaid', conf).subscribe((r) => {
            let res:any=r;
            if(res=='markAsPaid'){
        // if (confirm('Are you sure you want to delete the payment entry?')) {
            this.globalService.callAPI(globalConstants.API_DELETE_PAYMENT_URL, params).subscribe((response:any) => {
                if (response.result == 1) {
                    this.paymentAdded = true;
                    this.globalService.snackbar("success", response.message);
                    this.getPaymentDetail(r_id);
                    if(this.listShop){
                        this.listShop.setDataTable();
                    }
                } else {
                    this.globalService.snackbar("error", response.message);
                }
            });
        }
      })
    }
    showReminderForm() {
        if (this.newReminderShow) {
            this.newReminderShow = false;
        } else {
            this.newReminderShow = true;
        }
    }
    newReminders(dataSource) {
        let show = false;
        let balance = parseFloat(dataSource.balance);
        if ((!isNaN(balance))) {
            show = true;
        }
        return !show;
    }
    setReminder() {
        let fcData = this.addReminder;
        if (fcData.valid) {
            let d = new Date(fcData.controls.payment_reminder_date.value);
            fcData.value.payment_reminder_date = (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear();
            let params = globalFunctions.stringifyFormData(fcData.value, this.globalService.getCurrentUser()["user_type"]);
            this.globalService.callAPI(globalConstants.API_SAVE_PAYMENT_REMINDER_URL, params).subscribe((response:any) => {
                if (response.result == 1) {
                    this.paymentAdded = true;
                    this.globalService.snackbar("success", response.message);
                    this.getPaymentDetail(fcData.value.payment_req_id);
                } else {
                    this.globalService.snackbar("error", response.message);
                }
            });
        } else {
            this.globalService.snackbar("error", 'Enter Reminder Date');
        }
    }
    getStyle(p){
        if(p.delete_status==1){
            return 'text-decoration:line-through';
        }
        return '';
    }
    onlyDecimalNumberKey(event) { //for disable a-z key word 
        let charCode = (event.which) ? event.which : event.keyCode;
      this.d=event.srcElement.value;
      if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
          return false;
      return true;
  }

  disableKey(event) { //for disable copy and past 
      let charCode = String.fromCharCode(event.which).toLowerCase();
      if (event.ctrlKey == true && (event.which == '67' || event.which == '86' || String.fromCharCode(event.which).toLowerCase() == 's')) {
          event.preventDefault();
      }
  }
  vkFloat(k) {
      let output = Number(this.addPayment.value[k]);
      if (isNaN(output)) {
          if (isNaN(Number(this.d))) {
              this.addPayment.controls[k].setValue(parseFloat(this.addPayment.value[k]));
          } else {
              this.addPayment.controls[k].setValue(this.d);
          }
      }
  }
  floatUpdate(val){ 
    return parseFloat(val).toFixed(2)
  }

    calculateTotalAmount(amount) {
        let zeroVariable:any = 0;
        if (this.addPayment.controls.cc_fee_type.value == "2") { //cc fee in percentage 
            let ccFee = parseFloat(amount) * (parseFloat(this.addPayment.controls.cc_fee_percentage.value) / 100);
            if (isNaN(ccFee)) {
                this.ccFee = 0;
            }else{
                this.ccFee = ccFee;
            }
            this.addPayment.controls.cc_fee.setValue(this.ccFee.toFixed(2));
        }
        if (!this.isCCFeeIncluded) {
            this.ccFee = 0;
            this.addPayment.controls.cc_fee.setValue(0);
        }
        if (amount == '') {
            this.netPayable = zeroVariable.toFixed(2);
        } else {
            this.netPayable = (parseFloat(amount) + parseFloat(this.addPayment.controls.cc_fee.value)).toFixed(2);
        }
    }

  paymentAmountChange(k): void {
    this.vkFloat(k);
    var val = this.addPayment.controls.payment_amount.value;
        if(this.addPayment.controls.payment_method.value === "3"){ //when select the credit card 
            if(val != '' && val != null){
                this.calculateTotalAmount(val);
            }else{
                this.calculateTotalAmount(0);
            }
        }
  }

 
 paymentWithOrWithoutCCfee(event:any,fieldType:any):void{
       let val = parseFloat(event.target.value);
       if(this.addPayment.controls.payment_method.value === "3"){ //when select the credit card
        if(val != null){
            this.updatePaymentAmount(val,fieldType);
        }else{
            this.updatePaymentAmount(0,fieldType);
        }
    }
 }

    updatePaymentAmount(amount, fieldType) {
        let zeroVariable:any = 0;
        if (isNaN(amount)) {
            if (this.addPayment.controls.cc_fee_type.value == "2") {   //cc fee in percentage  
                 this.ccFee =0;
                 this.addPayment.controls.cc_fee.setValue(0);
                 if(fieldType == 'payment_amount'){
                    this.netPayable = zeroVariable.toFixed(2);
                 } else {
                    this.addPayment.controls.payment_amount.setValue(zeroVariable.toFixed(2));
                 }
            } else {
                if(fieldType == 'payment_amount'){
                    this.netPayable = zeroVariable.toFixed(2);
                }
            } 
        } else {
            if (fieldType == 'payment_amount') {
                this.calculateTotalAmount(amount);
            } else {
                let ccFeeValue = this.addPayment.controls.cc_fee.value;
                if (this.addPayment.controls.cc_fee_type.value == "2") { //ccfee in percentage
                    let totalAmount = ((parseFloat(amount) * 100) / (100 + parseFloat(this.addPayment.controls.cc_fee_percentage.value))).toFixed(2);
                    let ccFee = parseFloat(amount) - parseFloat(totalAmount);
                    this.addPayment.controls.cc_fee.setValue(ccFee.toFixed(2));
                    this.ccFee = ccFee;
                    this.addPayment.controls.payment_amount.setValue(totalAmount);
                } else {
                    if (amount == 0) {
                        this.addPayment.controls.payment_amount.setValue(zeroVariable.toFixed(2));
                        this.netPayable = zeroVariable.toFixed(2);
                    }
                    if(amount<ccFeeValue){
                         this.addPayment.controls.payment_amount.setValue(zeroVariable.toFixed(2));
                    }
                    else if (amount > ccFeeValue) {
                        let totalAmount: any = (parseFloat(amount.toFixed(2)) - parseFloat(this.addPayment.controls.cc_fee.value)).toFixed(2);
                        this.addPayment.controls.payment_amount.setValue(totalAmount);
                    }
                }
            }
            if (!this.isCCFeeIncluded) {
                this.ccFee = 0;
                this.addPayment.controls.cc_fee.setValue(0);
            }
        }
    }

 paymentOnfocuesOut(event,fieldType){
    let amountWithCcfee:any = parseFloat(event.target.value).toFixed(2);
    let zeroVariable:any = 0;
    if(fieldType == 'payment_with_ccFee'){
    if (this.addPayment.controls.cc_fee_type.value == "2"){ //cc fee in percentage
        if(isNaN(amountWithCcfee)){
            this.addPayment.controls.payment_amount.setValue(zeroVariable.toFixed(2));
            this.addPayment.controls.cc_fee.setValue(zeroVariable.toFixed(2));
            this.ccFee = zeroVariable.toFixed(2);
            this.netPayable = zeroVariable.toFixed(2);
        } 
    }else{
         var ccFeeValue:any = parseFloat(this.addPayment.controls.cc_fee.value).toFixed(2);
         if(isNaN(amountWithCcfee)){
            this.netPayable = parseFloat(this.addPayment.controls.cc_fee.value).toFixed(2);
            this.addPayment.controls.payment_amount.setValue(zeroVariable.toFixed(2));
         }       
         if(Number(ccFeeValue) > Number(amountWithCcfee)){
             this.netPayable = parseFloat(this.addPayment.controls.cc_fee.value).toFixed(2);
             this.addPayment.controls.payment_amount.setValue(zeroVariable.toFixed(2));
         }
    }
   }else{
       if(!isNaN(amountWithCcfee)){
           this.addPayment.controls.payment_amount.setValue(Number(amountWithCcfee).toFixed(2));
       }
       if(isNaN(amountWithCcfee)){
          this.addPayment.controls.payment_amount.setValue(Number(zeroVariable).toFixed(2));
       }
   }
}

  isAllowed(action){
    return this.accessLimit[action]==1?true:false;
  }

   /**
   * updateOnTheLotStatus
   */
  public updateOnTheLotStatus(value) {
    let checked : number = 0;
    if(value.checked === true) {
      checked = 1;
    }
    let self = this;
    this.globalService.callAPI(globalConstants.API_UPDATE_ON_THE_LOT_STATUS,"request_id="+this.options.request_id+"&user_id="+this.globalService.getCurrentUser()["id"]+"&on_the_lot="+checked+ "&user_type=User").subscribe(function(response:any){
        this.paymentAdded = true;
      self.globalService.snackbar("success", response.message);
    })
  }

  /**
   * getServiceDetail
   */
  public getServiceDetail() {
    let content = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&service_request_id=" + this.options.request_id;
    this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUEST_VIEW_URL, content).subscribe((response:any) => {
        if (response.result == 1) {
           this.onTheLot = response.data.on_the_lot == "0" ? false : true;
           this.serviceDetail = response.data;
           this.requestStatus = response.data.request_status;
           this.addPayment.controls.cc_fee_type.setValue(this.serviceDetail.cc_fee_type);
           if(this.serviceDetail.cc_fee_type == "1"){
                this.addPayment.controls.cc_fee.setValue(this.serviceDetail.cc_fee);
                this.addPayment.controls.cc_fee_percentage.setValue(0);
            }else if(this.serviceDetail.cc_fee_type == "2"){
                this.addPayment.controls.cc_fee.setValue(0);
                this.addPayment.controls.cc_fee_percentage.setValue(this.serviceDetail.cc_fee);
            }
            this.ccFee = this.addPayment.controls.cc_fee.value;
            if(this.isAddInPayment && this.dataSources && this.dataSources.balance){
                this.addPayment.controls.payment_amount.setValue(parseFloat(this.dataSources.balance.replace(/,/g,'')).toFixed(2));
                this.addPayment.controls.payment_method.setValue('3');
                this.isCCFeeIncluded = true;
                this.changeMethod({value:3});
            }
            if (this.isAddInPayment === undefined || this.isAddInPayment === null){
                if(this.serviceDetail.cc_fee_included == '1'  && this.dataSources && this.dataSources.balance){
                    this.addPayment.controls.payment_amount.setValue(parseFloat(this.dataSources.balance.replace(/,/g,'')).toFixed(2));
                    this.addPayment.controls.payment_method.setValue('3');
                    this.isCCFeeIncluded = true;
                    this.changeMethod({value:3});
                }
            }
        }
    });
  }

  /**
   * Function to download invoice
   */
  getInvoicePdf(){
    let data={
      "service_request_id":this.serviceDetail.id,
      "user_type":"User",
      "invoke_download":1,
      "user_id":this.globalService.getCurrentUser()["id"],
      "is_tax_included":this.serviceDetail.is_tax_included,
      "tax_rate":this.serviceDetail.tax_rate,
      "total":this.serviceDetail.grand_total
    };
    let url = globalConstants.API_GENERATE_INVOICE_URL;
    let skipFirst:boolean = true;
    for(let d in data){
      //console.log(d,data[d]);
      if(skipFirst == true){
        url += "?"+d+"="+data[d];
        skipFirst = false;
      }else{
        url += "&"+d+"="+data[d];
      }
    }
    let serviceRequestId = this.serviceDetail.request_id;
    this.globalService.downloadFileWithGet(url,serviceRequestId);
  }
    /**
     * Function to set all the conditions for initializing redeem coupon component.
     */
    setCouponRedeemConditions(){
        this.couponRedeemConditions.push(this.globalService.isSubscribe(globalConstants.SUBSCRIPTION_DISCOUNTS_FEATURE));
    }

    getTotal(paymentAmount,ccFee){
          let totalAmount:any = parseFloat(paymentAmount)+ parseFloat(ccFee);
          return parseFloat(totalAmount).toFixed(2);
    }
}
