import { Component, OnInit, Inject, ViewEncapsulation, Optional, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { GlobalService } from '../../../../../environments/global.service'
import { IDatePickerConfig } from 'ng2-date-picker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import { globalConstants } from 'src/environments/globalConstants';
import { globalFunctions } from 'src/environments/globalFunctions';
import * as moment from 'moment';

@Component({
  selector: 'app-expenses-add',
  templateUrl: './expenses-add.component.html',
  styleUrls: ['./expenses-add.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class ExpensesAddComponent implements OnInit, OnDestroy {
  public addExpenseForm : FormGroup;
  public currentUser : any;
  public categoriesList : any;
  public subscriptionDataForAPI: Subscription = new Subscription();
  public datePickerConfig: IDatePickerConfig = {
    format: "MM/DD/YYYY",
    max: moment().add(1, 'years').format('MM/DD/YYYY'),
    drops:'down',
    unSelectOnClick:false
  };
  public editExpenseData :any = null;
  public apiUrl: any = globalConstants.API_ADD_EXPENSE_LEDGER;
  public errorMsgExpenseDate = "";
  
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
     private formBuilder: FormBuilder, 
     private globalService: GlobalService,
     public dialogRef: MatDialogRef<ExpensesAddComponent>
  ) { 
      this.editExpenseData = data;
  }

  ngOnInit(): void {
        this.currentUser = this.globalService.getCurrentUser();
        this.getCategoriesList();
        this.createAddExpenseForm();
        if(this.editExpenseData !== null){
            this.setValueInExpenseForm();
        } 
  }

  createAddExpenseForm(){
      this.addExpenseForm = this.formBuilder.group({
           title:['',[Validators.required]],
           category:['',[Validators.required]],
           expense_date:['',[Validators.required]],
           amount:['',[Validators.required,Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
           description :[''],
           copy_expense : [false],
           user_id: this.currentUser.id,
           user_type: 'User'
      });
  }

  setValueInExpenseForm(){
      this.addExpenseForm.controls.title.setValue(this.editExpenseData.title);
      this.addExpenseForm.controls.category.setValue(this.editExpenseData.category);
      this.addExpenseForm.controls.expense_date.setValue(moment(new Date(this.editExpenseData.expense_date)).format('MM/DD/YYYY'));
      this.addExpenseForm.controls.description.setValue(this.editExpenseData.description);
      this.addExpenseForm.controls.amount.setValue(this.editExpenseData.amount);
      this.addExpenseForm.controls.copy_expense.setValue(this.editExpenseData.copy_expense == 1 ? true : false);
      this.apiUrl = globalConstants.API_EDIT_EXPENSE_LEDGER;
  }

  getCategoriesList(){
      let payload ={
        user_id: this.currentUser.id,
        user_type: "User"
      };
      let categoryDDLPlaceholder=[{
        id:'',
        category_name:'Select Category'
      }];
      this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_EXPENSE_LEDGER_CATEGORIES,payload).subscribe((response:any) =>{
         if(response.result == 1){
              this.categoriesList = categoryDDLPlaceholder.concat(response.data);
         }
      })
  }

  handleAddExpense(){
       if(this.addExpenseForm.valid){
           let payload = this.addExpenseForm.value;
           if(payload.copy_expense){
            payload.copy_expense = 1;
            }else{
                payload.copy_expense = 0;
            }
          if(this.editExpenseData !== null){
               payload.exp_id = this.editExpenseData.id
          }
          this.subscriptionDataForAPI =  this.globalService.callAPI(this.apiUrl,payload).subscribe((response:any)=>{
                 if(response.result == '1'){
                     this.globalService.snackbar('success', response.message);
                     this.dialogRef.close(1);
                 }else{
                  this.globalService.snackbar('error', response.message);
                 }
           })
       }else{
          this.addExpenseForm.controls.expense_date.markAsTouched();
          this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
       }
  }


  handleExpenseDate(obj, picker) {
    if (typeof obj == 'undefined') {
      obj = picker.inputElementValue;
    }
    if (obj != '') {
      let currentFutureDate = moment(this.datePickerConfig.max);
      let expenseDate = moment(obj);
      if(expenseDate.isBefore(currentFutureDate)){
          this.errorMsgExpenseDate = "";
      }else{
        this.errorMsgExpenseDate = "Future date can be up to next 12 months.";
      }
      picker.api.moveCalendarTo(obj);
    }
  }

  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
        && (charCode < 48 || charCode > 57))
        return false;
    return true;
}

disableKey(event) {
    if (event.ctrlKey == true && (event.which == '67' || event.which == '86' || String.fromCharCode(event.which).toLowerCase() == 's')) {
        event.preventDefault();
    }
}

convertIntoDecimal(event,frmControlName,beforeDecimalDigit) {
  let fieldValue = this.addExpenseForm.get(frmControlName).value;
  let result= this.globalService.processDecimalValue(fieldValue,beforeDecimalDigit);
  this.addExpenseForm.get(frmControlName).setValue(result);
  return globalFunctions.validateNumbersWithDecimal(event,3);
}

ngOnDestroy(){
  this.subscriptionDataForAPI.unsubscribe();
} 

}
