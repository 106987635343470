<div mat-dialog-title class="relative">
  Convert to Service Request
  <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
    <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="convert-serviceRequest">
  <mat-accordion class="new-request-expension show-price margin-bottom-10 margin-top-10 display-full" multi="true" displayMode="flat"
    hideToggle="false">
    <mat-expansion-panel [class.hide]="!showCustForm" [expanded]="true" hideToggle="true" class="new-request-expension relative service-request-panel" id="qqtovexpansion1" >
      <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="qqtovexpansionHead1">
        <span class="margin-right-5"></span><b>Customer Detail</b>
      </mat-expansion-panel-header>
        <ul class="service-list-items no-padding">
          <li>
        <form class="form-horizontal form-control-normal" autocomplete="off" [formGroup]="customer">
          <div class="center-block">
            <div class="display-full margin-bottom-5">
              <!-- customer detail form -->
              <div class="col-sm-6 col-md-6 margin-bottom-20">
                <label>First Name:
                  <span>*</span>
                </label>
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill" hideRequiredMarker="false">
                  <input matInput placeholder="First Name" title="First Name" formControlName="first_name"
                    class="form-control input-lg custom-form-input" >
                  <mat-error *ngIf="customer.controls.first_name.hasError('required')">
                    First Name is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="customer.controls.first_name.hasError('pattern')">
                    Invalid first name
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-6 col-md-6 margin-bottom-20">
                <label>Last Name:
                  <span>*</span>
                </label>
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill" hideRequiredMarker="false">
                  <input matInput placeholder="Last Name" title="Last Name" formControlName="last_name" class="form-control input-lg custom-form-input">
                  <mat-error *ngIf="customer.controls.last_name.hasError('required')">Last Name is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="customer.controls.last_name.hasError('pattern')">
                    Invalid last name
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-6 col-md-6 margin-bottom-20">
                <label>Address:
                  <span>*</span>
                </label>
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Address 1" title="Address 1" formControlName="address_one" class="form-control input-lg custom-form-input">
                  <mat-error *ngIf="customer.controls.address_one.hasError('required')">Address is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-6 col-md-6 uc-ngx-select">
                <label>Country:
                  <span>*</span>
                </label>
                <ngx-select class="display-full margin-bottom-20" [items]="countries"
                  [(ngModel)]="currentCustomerCountry" [ngModelOptions]="{standalone: true}"
                  (select)="getSelectCountry($event)" placeholder="Select Country">
                </ngx-select>
              </div>
              <div class="col-sm-6 col-md-6 uc-ngx-select">
                <label>State:
                  <span>*</span>
                </label>
                <ngx-select class="display-full margin-bottom-20" [items]="states" [(ngModel)]="currentCustomerState"
                  [ngModelOptions]="{standalone: true}" (select)="getCities($event,'reset')"
                  [disabled]="states.length==0" placeholder="Select State">
                </ngx-select>
                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                  <mat-error *ngIf="customer.controls.state.hasError('required')">
                    State is <strong>required</strong>
                  </mat-error>
                </div>
              </div>

              <div class="col-sm-6 col-md-6 uc-ngx-select">
                <label>City:
                  <span>*</span>
                </label>
                <ngx-select class="display-full margin-bottom-20" [items]="cities" [(ngModel)]="cityBlank"
                  [ngModelOptions]="{standalone: true}" (select)="getSelectedOptionCity($event)"
                  [disabled]="cities.length==0" placeholder="Select City">
                </ngx-select>
                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                  <mat-error *ngIf="customer.controls.city.hasError('required')">
                    City is <strong>required</strong>
                  </mat-error>
                </div>
              </div>

              <div class="col-sm-6 col-md-6 margin-bottom-20">
                <label>Zip Code:
                  <span>*</span>
                </label>
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Zip Code" title="Zip Code" formControlName="zipcode"
                    class="form-control input-lg custom-form-input" maxlength="5" 
                    (keypress)="onlyNumericKey($event)" />
                  <mat-error *ngIf="customer.controls.zipcode.hasError('required')">
                    Zip Code is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="customer.controls.zipcode.hasError('pattern')">
                    Please enter only numeric value
                  </mat-error>
                  <mat-error *ngIf="customer.controls.zipcode.hasError('maxlength')">
                    Invalid zipcode
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-6 col-md-6 margin-bottom-20">
                <label>Mobile Number:
                  <span>*</span>
                </label>
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Mobile Number" title="Mobile Number" formControlName="mobile_phone"
                    class="form-control input-lg custom-form-input" maxlength="15" [textMask]="{mask: phoneMask}"
                     />
                  <mat-error *ngIf="customer.controls.mobile_phone.hasError('required')">
                    Mobile number is <strong>required</strong>.
                  </mat-error>
                  <mat-error
                    *ngIf="customer.controls.mobile_phone.hasError('minLength') && !customer.controls.mobile_phone.hasError('required')">
                    Invalid mobile number.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-6 col-md-6 uc-ngx-select">
                <label>Customer Type:
                  <span>*</span>
                </label>
                <ngx-select class="display-full margin-bottom-20" formControlname="customer_type"
                  [items]="getCustomerTypes" (select)="getSelectedOptionCustomerType($event)"
                  [(ngModel)]="defaultCustomerType" [ngModelOptions]="{standalone: true}"
                  placeholder="Select Customer Type">
                </ngx-select>
                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                  <mat-error *ngIf="customer.controls.customer_type.hasError('required')">
                    Customer Type is <strong>required</strong>
                  </mat-error>
                </div>
              </div>
              <div class="col-sm-6 col-md-6 uc-ngx-select">
                <label>Timezone:
                  <span>*</span>
                </label>
                <ngx-select class="display-full" [items]="timezoneData" formControlName="timezone"
                  (select)="getSelectedOptionTimezone($event)" placeholder="Select Timezone">
                </ngx-select>
                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                  <mat-error *ngIf="customer.controls.timezone.hasError('required')">
                    Timezone is <strong>required</strong>
                  </mat-error>
                </div>
              </div>
            </div>
          </div>
        </form>
      </li>
    </ul>
      
    </mat-expansion-panel>
    <mat-expansion-panel [class.hide]="!fillVehicleData" [expanded]="true" hideToggle="true" class="new-request-expension relative service-request-panel" id="qqtovexpansion2">
      <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="qqtovexpansionHead2">
        <span class="margin-right-5"></span><b>Vehicle Detail</b>
      </mat-expansion-panel-header>
        <ul class="service-list-items">
            <li>
        <form class="form-horizontal form-control-normal qq-serviceRequestForm" autocomplete="off" [formGroup]="vehicle">
          <div class="center-block">
            <div class="display-full margin-bottom-5">
              <!-- vehicle detail form -->
              <div class="row">
                <div class="col-sm-6 col-md-6 margin-bottom-20">
                  <label>Mileage In:
                    <span>*</span>
                  </label>
                  <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill" hideRequiredMarker="false">
                    <input matInput placeholder="Mileage In" title="Mileage In" formControlName="distance_covered"
                      (keydown)="disableKey($event)" (keypress)="onlyNumericKey($event)"
                      class="form-control input-lg custom-form-input" (keyup)="carValid()">
                    <mat-error *ngIf="vehicle.controls.distance_covered.hasError('required')">
                      Mileage In is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-6 col-md-3 margin-bottom-20">
                  <label>License Plate:
                    <span>*</span>
                  </label>
                  <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill" hideRequiredMarker="false">
                    <input matInput placeholder="License Plate" title="License Plate" formControlName="license_plate"
                      class="form-control input-lg custom-form-input" (keyup)="carValid()">
                    <mat-error *ngIf="vehicle.controls.license_plate.hasError('required')">
                      License Plate is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-6 col-md-3 uc-ngx-select" *ngIf="vehicleDetails">
                  <label for="">State:
                    <span>*</span>
                  </label>
                  <ngx-select class="display-full margin-bottom-20" formControlname="state"
                    [(ngModel)]="vehicleDetails.state" [ngModelOptions]="{standalone: true}" [items]="states"
                    (select)="getSelectedOptionState($event)" placeholder="Select State">
                  </ngx-select>
                  <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                    <mat-error *ngIf="vehicle.controls.state.hasError('required')">
                      State is <strong>required</strong>
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-md-6 margin-bottom-20">
                  <label>Vin:
                    <span>*</span>
                  </label>
                  <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill" hideRequiredMarker="false">
                    <input matInput placeholder="Vin" title="Vin" formControlName="vin" maxlength="17"
                      class="form-control input-lg custom-form-input" (keyup)="carValid()">
                    <mat-error *ngIf="vehicle.controls.vin.hasError('required')">
                      Vin is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6" *ngIf="vehicleDetails">
                  <label for="color">Color:
                    <span>*</span>
                  </label>
                  <div class='relative uc-ngx-select'>
                    <ngx-select class="display-full color-select" formControlname="color" [(ngModel)]="vehicleColor"
                      [ngModelOptions]="{standalone: true}" *ngIf="!addNewColor" [items]="carColors" (select)="getSelectedColor($event)"
                      placeholder="Select Color">
                    </ngx-select>
                    <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper color-error-wrap" *ngIf="!addNewColor">
                      <mat-error *ngIf="vehicle.controls.color.hasError('required')">
                        Color is <strong>required</strong>
                      </mat-error>
                    </div>
                    <mat-form-field class="btn-block car-input mat-form-field-type-mat-select" floatPlaceholder="never" appearance="fill"
                      *ngIf="addNewColor">
                      <input matInput type="text" placeholder="Choose color"
                        class="form-control input-lg custom-form-input" formControlName="color"
                        [value]="vehicleDetails.color">
                        <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper color-error-wrap">
                         <mat-error *ngIf="vehicle.controls.color.hasError('required')" >
                        Color is <strong>required</strong>
                      </mat-error>
                    </div>
                   
                    </mat-form-field>
                    <a href="javascript:void(0);" class="others-link" *ngIf="!addNewColor"
                      (click)="addNewColor=true">Other Color</a>
                      <a href="javascript:void(0);" class="others-link make-link" *ngIf="addNewColor"
                      (click)="addNewColor=false">Use Existing Color</a>
                  </div>
                  <div class='relative' *ngIf="addNewColor">
                    
                    
                  </div>
                </div>
              </div>
            </div>
          </div>

        </form>
        </li>
        </ul>
      
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="true" hideToggle="true" class="new-request-expension relative service-request-panel" id="qqtovexpansion3" style="border-radius: 15px 15px;">
      <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="qqtovexpansionHead3">
        <span class="margin-right-5"></span><b>Service Request Detail</b>
      </mat-expansion-panel-header>
      <ul class="service-list-items no-padding">
          <li> <form class="form-horizontal form-control-normal" autocomplete="off" [formGroup]="addservice">
        <div class="center-block">
          <div class="display-full margin-bottom-5">
            <div class="row">
              <div class="col-sm-12">
                <label class="col-sm-3">
                  <strong>Service Schedule Date:             
                  </strong>
                </label>
                <div class="col-sm-9">
                  <div class="vlio-datepicker relative margin-bottom-25">
                    <dp-date-picker mode="daytime" title="Service Schedule Date" placeholder="Service Schedule Date"
                      [config]="datePickerConfig" theme="dp-material dp-main" class="btn-block"
                      formControlName="request_date" #dayPicker></dp-date-picker>
                    <button type="button" mat-icon-button (click)="dayPicker.api.open();">
                      <mat-icon>date_range</mat-icon>
                    </button>
                  </div>
                  <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                    <mat-error *ngIf="addservice.controls.request_date.hasError('required')">
                      This field cannot be empty
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 margin-bottom-25">
                <label class="col-sm-3">
                  <strong>Mileage In:
                    <span>*</span>
                  </strong>
                </label>
                <div class="col-sm-9">
                  <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput type="text" maxlength='14' title="Mileage In"
                      placeholder="Mileage In({{vehicleMileage}})" (keydown)="disableKey($event)" 
                      (keypress)="onlyNumericKey($event)" class="form-control input-lg custom-form-input btn-block"
                      formControlName="distance_covered" />
                    <mat-error *ngIf="addservice.controls.distance_covered.hasError('required')">
                      This field cannot be empty
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm-12 margin-bottom-25">
                <label class="col-sm-3">
                  <strong>Request Title:
                  </strong>
                </label>
                <div class="col-sm-9">
                  <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput type="text"  placeholder="Request Title" title="Request Title"
                      class="form-control input-lg custom-form-input btn-block" formControlName="request_title" />
                    <mat-error *ngIf="addservice.controls.request_title.hasError('required')">
                      This field cannot be empty
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm-12">
                <label class="col-sm-3">
                  <strong>Request Notes:
                  </strong>
                </label>
                <div class="col-sm-9 text-area-note">
                  <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <textarea matInput placeholder="Request Notes"  title="Request Notes" rows="5" maxlength="1000"
                      formControlName="request_notes"></textarea>
                    <mat-error *ngIf="addservice.controls.request_notes.hasError('required')">
                      This field cannot be empty
                    </mat-error>
                    <mat-error *ngIf="addservice.controls.request_notes.hasError('maxlength')">
                      Only 1000 character Allowed
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

            </div>
          </div>
        </div>

      </form>
      </li>
      </ul>
    </mat-expansion-panel>
  </mat-accordion>

</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative margin-bottom-0">
    <div class="text-right flexBtns2">
      <button mat-raised-button color="accent" (click)="submitForms()" class="font-bold">Submit</button>
      <button mat-raised-button color="accent"  (click)="submitCar()" class="font-bold hide">Submit</button>
      <button mat-raised-button color="accent" (click)="submitCustomer()" class="font-bold hide">Submit</button>
      <button mat-raised-button color="accent"  (click)="createSR()" class="font-bold hide">Submit</button>
      <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
    </div>
</mat-dialog-actions>
