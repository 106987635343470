<div class="col-xs-12  subscription-alert-section padding-left-0 padding-right-0" *ngIf="showPermissionBanner" style="float: none;">
  <mat-toolbar class="subscription-alert-bar" >
      <div class="subscription-content">
          Browser notifications are disabled. Enable them so you don't miss new updates. 
      </div>
      <div class="subscription-btn">
        <button mat-raised-button class="font-bold mat-elevation-z0" color="accent" (click)="askPermissionForNotifications()">
          <span>Enable Notifications</span>
        </button>
      </div>
    </mat-toolbar>
    <p></p>
</div>
<div id="dashboardQuickLinks" class="panel panel-primary margin-0 dashboard-new-component" >
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">
            Shop Dashboard
        </h3>
           <div  class="dashboard-toggle-section">
            <i class="fa-solid fa-arrows-rotate " title="Refresh Dashboard" (click)=" refreshDashboard()"></i>&nbsp;&nbsp;
            <span class="" (click)="handleDashboardSwitch()">
              <mat-slide-toggle  [checked]="true" class="shop-drag-opt"></mat-slide-toggle>
              <span class="hidden-xs " title="Switch Dashboard"> Switch Dashboard</span>
            </span>
            
          </div>
      </div>
    <div class="panel-body">
        <div class="container-fluid ">
            <div class="row flex-sm border-bottom frist-row blocks-wrapper no-gutters">
                <div class=" col-sm-6 col-lg-4 month-sm-col flex-sm border-bottom-xs flex-view column-block " *ngIf="isAllowed('views','service_request') || isAllowed('enable','overdue_reports')">
                 
                    <div class="row border-row">
                      <div class="row">
                        <h4>This Month</h4>
                        <div class="col-sm-12 month-sr-block" *ngIf="isAllowed('views','service_request')" (click)="navigateToSRListWithoutFilter()" title="Total Service Requests">
                            <span>Total Service Requests</span> <span class="font-bold">{{shopStatistics?.totalTickets_count}}</span>
                        </div>
                        <div class="col-sm-12 month-sr-block total-revenue-section" title="Total Revenue" *ngIf="isAllowed('views','sales_reports')" (click)="navigateToPage('shops/reports-stats-servicerequests')">
                         <span class="label-text">Total Revenue</span> <span class="font-bold"><i class="fa fa-solid fa-dollar-sign" ></i> {{salesRevenue?.total_revenue | number: '1.0-2'}} <label [ngClass]="{'text-green': reportData?.sales_after_discount_totals.profit_percentage >= 0, 'text-danger': reportData?.sales_after_discount_totals.profit_percentage < 0}">({{reportData?.sales_after_discount_totals.profit_percentage}}%)</label></span>
                        </div>
                        <div class="col-sm-12 month-sr-block" title="Completed (Not Paid)" *ngIf="isAllowed('views','service_request')" (click)="navigateToSRListWithFilter('6')">
                          <span> Not Paid</span><span class="font-bold">{{shopStatistics?.totalUnpaidTickets_count}}</span>
                        </div>
                        <div class="col-sm-12 month-sr-block" title="Overdue 30 days" *ngIf="isAllowed('enable','overdue_reports')" (click)="navigateToPage('shops/reports-stats-overdue')">
                           <span>Overdue <strong>30</strong></span> <span class="font-bold">{{shopStatistics?.overdue_30}}</span>
                        </div>
                        <div class="col-sm-12 month-sr-block" title="Overdue 90 days" *ngIf="isAllowed('enable','overdue_reports')" (click)="navigateToPage('shops/reports-stats-overdue')">
                            <span> Overdue <strong>90</strong></span> <span class="font-bold">{{shopStatistics?.overdue_90}}</span>
                        </div>
                       
  
                    </div> 
                    <div class="row">
                      <h4>Parts Inventory</h4>
                      <div class="col-sm-12 month-sr-block" title="Low Stock" *ngIf="isAllowed('views','sales_reports')" (click)="navigateToPartList()">
                        <span>Low Stock </span> <span class="font-bold">{{ shopStatistics?.partInventoryStats_Data.low_stock }}</span>
                      </div>
                      <div class="col-sm-12 month-sr-block" title="Current Month" *ngIf="isAllowed('views','sales_reports')" (click)="navigateToInventoryList('1')">
                        <span>Current Month </span> <span class="font-bold">{{ shopStatistics?.partInventoryStats_Data.current_month.total_quantity }}</span>
                      </div>
                      <div class="col-sm-12 month-sr-block" title="Last Month" *ngIf="isAllowed('views','sales_reports')" (click)="navigateToInventoryList('2')">
                        <span>Last Month </span> <span class="font-bold">{{ shopStatistics?.partInventoryStats_Data.last_month.total_quantity }}</span>
                      </div>
                    </div>
                  </div>  
                </div>
                <div class=" col-sm-6 col-lg-4 flex-sm border-bottom-xs column-block quick-links-block">
                    <div class="row quick-links-row flex border-row">
                        <div class="col-xs-4 col-sm-6 dashboard-top-menu quick-links flex-sm" id="quickActionsContent">
                                <div class="row text-center dashboard-circle-links padding-6">
                                   
                                    <!-- <div class="col-sm-12" *ngIf="isAllowed('addNew','service_request')"> -->
                                    <div class="col-sm-12">
                                        <a href="javascript:void(0);" class="dashboard-circle" title="Create Appointment" >
                                            <span class="inner-circle" [ngClass]="isAllowed('addNew','service_request') == true ? '' : 'disabled'" (click)="handleBookAppointment()"><i class="fa-regular fa-calendar-plus"></i></span>
                                            <span class="btn-block font-bold" >Create Appt.</span>
                                        </a>
                                    </div>
                                    <div class="col-sm-12" >
                                        <a href="javascript:void(0);" class="dashboard-circle" title="Quick Service Request" (click)="handleQuickSRCreation()">
                                            <span class="inner-circle"  [ngClass]="isAllowed('addNew','service_request') == true ? '' : 'disabled'"><i class="fa fa-plus-circle"></i></span>
                                            <span class="btn-block font-bold" >Quick Service</span>
                                        </a>
                                    </div>
                                    <div class="col-sm-12" >
                                        <a href="javascript:void(0);"  class="dashboard-circle" title="Job Board" (click)="navigateToJobBoard()">
                                            <span class="inner-circle img-flex-sm" [ngClass]="isAllowed('views','service_request') == true ? '' : 'disabled'">
                                              <img src="/assets/img/workflow-black.svg" class="width-40">
                                            </span>
                                            <span class="btn-block font-bold" >Job Board</span>
                                        </a>
                                    </div>
                                </div>
                        </div>
                        <div class="col-xs-8 col-sm-6 month-sm-col flex-sm flex-view padding-0">
                            <div class="row ">
                                <div class="col-sm-12 month-sr-block" title="Calendar" (click)="navigateToPage('shops/service-calendar')">
                                 <span><i class="fa fa-regular fa-calendar-days"></i> Calendar</span>
                                </div>
                                <div class="col-sm-12 month-sr-block" title="Service Requests" *ngIf="isAllowed('views','service_request')" (click)="navigateToSRListWithoutFilter()">
                                  <span><i class="fa fa-file-text fa-fw"></i> SR LIST</span> <span class="font-bold"></span>
                                 </div>
                                <div class="col-sm-12 month-sr-block" title="Appointments" *ngIf="isAllowed('views','service_request')" (click)="navigateToSRListWithFilter('11')">
                                    <span><i class="fa-regular fa-calendar-plus"></i> Appts.</span><span class="font-bold">{{shopStatistics?.totalAppointments}}</span>
                                </div>
                                <div class="col-sm-12 month-sr-block" title="Pending" *ngIf="isAllowed('views','service_request')" (click)="navigateToSRListWithFilter('1')">
                                    <span><i class="fa fa-solid fa-spinner"></i> Pending</span><span class="font-bold">{{shopStatistics?.totalPendTickets_count}}</span>
                                </div>
                                <div class="col-sm-12 month-sr-block" title="Work In Progress" *ngIf="isAllowed('views','service_request')" (click)="getWorkInProgress()">
                                    <span><i class="fa fa-solid fa-bars-progress"></i> WIP</span><span class="font-bold">{{shopStatistics?.workInprogress}}</span>
                                </div>
                                <div class="col-sm-12 month-sr-block text-center" title="Approval Awaited" *ngIf="isAllowed('views','service_request')" (click)="navigateToSRListWithFilter('3')">
                                    <span><i class="fa fa-solid fa-hourglass-start"></i> AWA</span> <span class="font-bold">{{shopStatistics?.approvalAwait}}</span>
                                </div>
                                <div class="col-sm-12 month-sr-block text-center" title="Approval Completed" *ngIf="isAllowed('views','service_request')" (click)="navigateToSRListWithFilter('7')">
                                    <span><i class="fa fa-solid fa-person-circle-check"></i> AUTH </span><span class="font-bold">{{shopStatistics?.approvalCmpl}}</span>
                                </div>
                                <div class="col-sm-12 month-sr-block" title="Completed And Paid" *ngIf="isAllowed('views','service_request')" (click)="navigateToSRListWithFilter('8')">
                                    <span><i class="fa fa-solid fa-bars-progress"></i> Completed</span><span class="font-bold">{{shopStatistics?.totalCompletedTickets_count}}</span>
                                </div>
                             </div>
                        </div>
                    </div>
                </div>
                <div class=" col-sm-6 col-lg-4 month-sm-col flex-view flex-sm column-block" *ngIf="isAllowed('views','view_service_history') || isAllowed('views','live_chat') || isAllowed('views','service_request') || isAllowed('views','attention_sr')">
                    <div class="row border-row">
                        <h4>Quick Service Actions</h4>
                        <div class="col-sm-12 month-sr-block sr-history-block" title="Advanced Search History" *ngIf="isAllowed('views','view_service_history')" (click)="handleAdvanceServiceHistory()">
                            <span><img class="svg-icon" src="../../../../assets/img/service-history.svg"> Advanced Search history</span><span class="font-bold"></span>
                        </div>
                        <div class="col-sm-12 month-sr-block" title="Live SMS" *ngIf="isAllowed('views','live_chat')" (click)="openChat()">
                            <span><i class="fa fa-comments fa-fw fa-solid"></i> SMS</span><span class="font-bold"></span>
                        </div>
                        <div class="col-sm-12 month-sr-block" title="On The Lot" *ngIf="isAllowed('views','service_request')" (click)="handleOnTheLotOption()">
                            <span><i class="fa fa-fw fa-list"></i> On The Lot</span><span class="font-bold">{{shopStatistics?.totalOnTheLot}}</span>
                        </div>
                        <div class="col-sm-12 month-sr-block" title="Service Request Attention" *ngIf="isAllowed('views','attention_sr')" (click)="navigateToPage('shops/attention-service-requests')">
                            <span><i class="fa fa-fw fa-list"></i> SR Attention</span><span class="font-bold"></span>
                        </div>
                        <div class="col-sm-12 month-sr-block" title="Unassigned" *ngIf="isAllowed('views','all_ticket_view')" (click)="navigationToSRListWithUnassignFilter()">
                            <span><i class="fa fa-fw fa-list"></i> UNASSIGNED</span><span class="font-bold">{{shopStatistics?.totalUnassigned}}</span>
                        </div>
                     </div>
                </div>
                <div class=" col-sm-6 col-lg-4 month-sm-col border-bottom-xs flex-view column-block" *ngIf="isAllowed('views','sales_reports')">
                  <div class="border-row report-block">
                        <h4 title="Advanced Report" class="cursor-pointer adv-report-title" (click)="navigateToPage('shops/reports-stats-servicerequests/advance-report')">Advanced Report</h4>
                    <div class="row" *ngIf="isAllowed('views','sales_reports')">
                        <h4 class="cursor-pointer" title="Business Metrics" (click)="navigateToPage('shops/reports-stats-servicerequests/business-metrics')">Business Metrics</h4>
                        <div class="col-sm-12 month-sr-block cursor-pointer" title="Hours Sold" *ngIf="isAllowed('views','sales_reports')" (click)="navigateToPage('shops/reports-stats-servicerequests/business-metrics')">
                          <span>HOURS SOLD</span> <span class="font-bold">{{reportData?.hour_sold}}</span>
                        </div>
                        <div class="col-sm-12 month-sr-block cursor-pointer"  title="Hours Presented" *ngIf="isAllowed('views','sales_reports')" (click)="navigateToPage('shops/reports-stats-servicerequests/business-metrics')">
                          <span>HOURS PRESENTED</span> <span class="font-bold">{{reportData?.hour_presented}}</span>
                        </div>
                        <div class="col-sm-12 month-sr-block cursor-pointer" title="Average Service Request Sales" *ngIf="isAllowed('views','sales_reports')" (click)="navigateToPage('shops/reports-stats-servicerequests/business-metrics')">
                        <span> AVG SR SALES</span> <span class="font-bold"><i class="fa fa-solid fa-dollar-sign"></i> {{reportData?.avg_sr_sales}}</span>
                        </div>
                        <div class="col-sm-12 month-sr-block cursor-pointer" title="Average Service Request Profit" *ngIf="isAllowed('views','sales_reports')" (click)="navigateToPage('shops/reports-stats-servicerequests/business-metrics')">
                            AVG SR Profit <span class="font-bold"> <i class="fa fa-solid fa-dollar-sign"></i> {{reportData?.avg_sr_profit }}</span>
                        </div>
                    </div>
                  </div>
                </div>
                <div class=" col-sm-6 col-lg-4 easy-checkin-col border-bottom-xs   column-block">
                  <div class="border-row">
                        <form [formGroup]="easyCheckInForm" (ngSubmit)="handleEasyCheckIn()" novalidate class="row form-inline easy-checkin-form" *ngIf="isAllowed('addNew','check_in')">
                            <!-- <div class="form-group heading-title"> -->
                                <h4 class="fontcolor-blue col-sm-12">Easy Check In <span class="font-xs">(Existing Customers)</span></h4>
                            <!-- </div> -->
                            <div class="col-xs-12">
                                <div class="uc-ngx-select car-input">
                                    <ngx-select class="margin-bottom-20" *ngIf="isAllowed('views','view_customer_info')" [items]="easyCheckInShopList"  formControlName="search_by"
                                    placeholder="Select" optionTextField="name">
                                    </ngx-select>

                                    <ngx-select class="margin-bottom-20" *ngIf="!isAllowed('views','view_customer_info')" [items]="easyCheckInNoCustomerViewList"  formControlName="search_by"
                                    placeholder="Select" optionTextField="name" >
                                    </ngx-select>
                                    <mat-error class="validation-error" *ngIf="easyCheckInForm.controls.search_by.hasError('required')">
                                       Select field is <strong>required</strong>
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-xs-12">
                                <mat-form-field class="car-input search-keyword-field" floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                                    <input matInput placeholder="Search Keyword" title="Search Keyword" formControlName="search_keyword"  class="form-control input-lg custom-form-input" />
                                    <mat-error *ngIf="easyCheckInForm.controls.search_keyword.hasError('required')">
                                      Search Keyword is <strong>required</strong>
                                  </mat-error>
                                  </mat-form-field>
                                    <button type="submit" mat-raised-button id="btnEasyChkinForm" class="car-btn text-uppercase btn-lg search-btn font-bold fontcolor-white" color="accent" title="Submit">
                                        <i class="fa fa-search"></i>
                                    </button>
                                
                            </div>
                        </form>
                    <!-- </div> -->
                        <div class="row"> 
                            <div class="col-xs-6 col-sm-6">
                                <a href="javascript:void(0);" class="dashboard-circle" title="Add New Customer" (click)="handleAddNewCustomer()">
                                    <span class="inner-circle" [ngClass]="isAllowed('addNew','customer') == true ? '' : 'disabled'"><i class="fa fa-plus"></i></span>
                                    <span class="btn-block font-bold" >New Customer</span>
                                </a>
                            </div>
                            <div class="col-xs-6 col-sm-6">
                                <a href="javascript:void(0);" class="dashboard-circle" title="Notifications" (click)="navigateToPage('shops/notification')">
                                    <span class="inner-circle"><i class="fa fa-regular fa-bell"></i></span>
                                    <span class="btn-block font-bold" >Notifications</span>
                                </a>
                            </div>
                            <div class="col-xs-6 col-sm-6">
                                    <a href="javascript:void(0);" class="dashboard-circle" title="Video Guide" (click)="handleGuideDrawer('video')">
                                        <span class="inner-circle"><i class="fa fa-solid fa-video"></i></span>
                                        <span class="btn-block font-bold" >Video Guide</span>
                                    </a>
                            </div>
                            <div class="col-xs-6 col-sm-6">
                                <a href="javascript:void(0);" class="dashboard-circle" title="Help Guide" (click)="handleGuideDrawer('help')">
                                    <span class="inner-circle"><i class="fa fa-book fa-lg"></i></span>
                                    <span class="btn-block font-bold" >Help Guide</span>
                                </a>
                            </div>
                        </div>
  
                  </div>
                    
                </div>
                <div class=" col-sm-6 col-lg-4 month-sm-col flex-view  column-block" *ngIf="isAllowed('views','shop_all_vehicles') || isAllowed('views','view_all_vehicles') || isAllowed('views','dfs_reminders') || isAllowed('views','service_request')">
                  <div class="border-row vehicle-block">
                    <div class="row" *ngIf="isAllowed('views','shop_all_vehicles') || isAllowed('views','view_all_vehicles')">
                        <h4>Vehicles List</h4>
                        <div class="col-sm-12 month-sr-block" title="Shop Vehicles List" *ngIf="isAllowed('views','shop_all_vehicles')" (click)="navigateToPage('shops/shop-vehicles-list')">
                       <span> <i class="fa fa-file-text fa-fw"></i> Shop Vehicles List</span><span class="font-bold">{{shopStatistics?.shop_vehicles_count}}</span>
                        </div>
                        <div class="col-sm-12 month-sr-block" title="Last Service" *ngIf="isAllowed('views','view_all_vehicles')" (click)="navigateToPage('shops/last-service')">
                            <span><i class="fa fa-fw fa-list"></i> Last Service</span><span class="font-bold">{{shopStatistics?.last_service_count}}</span>
                        </div>
                    </div>
                    <div class="row" *ngIf="isAllowed('views','dfs_reminders')">
                        <h4 class="">Due for service list</h4>
                        <div class="col-sm-12 month-sr-block" title="Next Week" (click)="navigateToDFSList(7)">
                          <span><i class="fa fa-solid fa-calendar"></i> Next Week</span><span class="font-bold">{{shopStatistics?.dfs_next_week_count}}</span>
                        </div>
                        <div class="col-sm-12 month-sr-block" title="Next Month" (click)="navigateToDFSList(30)">
                         <span> <i class="fa fa-solid fa-calendar"></i> NEXT Month</span><span class="font-bold">{{shopStatistics?.dfs_next_month_count}}</span>
                        </div>
                    </div>
                    <div class="row" *ngIf="isAllowed('views','service_request')">
                        <h4>REG, INS, INSP EXP<br><span class="font-xs">Registration, Insurance, Inspection</span></h4>
                        <div class="col-sm-12 month-sr-block" title="This Month" (click)="navigateToInspectionExpList(3)">
                          <span><i class="fa-solid fa-calendar"></i> This Month</span><span class="font-bold">{{shopStatistics?.reg_ins_insp_this_month_count}}</span>
                        </div>
                        <div class="col-sm-12 month-sr-block" title="Next Month" (click)="navigateToInspectionExpList(4)">
                          <span><i class="fa-solid fa-calendar"></i> NEXT Month</span><span class="font-bold">{{shopStatistics?.reg_ins_insp_next_month_count}}</span>
                        </div>
                    </div>
                  </div>  
                </div>
                <div class="col-sm-6 flex-sm table-col border-bottom-xs column-block appts-table" *ngIf="isAllowed('views','service_request')">
                  <div class="border-row">
                    <h4>
                      <span>APPOINTMENTS:</span>
                      <span class="absolute-btns-section">
                        <span class="btn" title="Create Appointment" (click)="handleBookAppointment()"><i class="fa fa fa-solid fa-plus"  ></i></span>
                      </span>
                    </h4>
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Request Id</th>
                          <th>Account</th>
                         <th>YMM</th>
                         <th>Timer</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let appointment of shopStatistics?.totalAppointments_data">
                          <td>
                            <span><i class="fa fa-solid fa-hashtag show-xs"></i><a class="hover-link cursor-pointer" (click)="openServiceRequestViewPage(appointment.id)"> {{appointment?.display_request_id}}</a></span>
                          </td>
                          <td>
                            <span><i class="fa fa-solid fa-user show-xs"></i> {{appointment?.first_name}} {{appointment?.last_name}}<br class="hidden-xs"></span>
                            <span class="font-xs"><i class="fa fa-solid fa-phone"></i> <a href="tel:{{appointment?.mobile_phone}}" class="hover-link cursor-pointer"> {{appointment?.mobile_phone}}</a></span>
                          </td>
                          <td>
                            <span><i class="fa fa-solid fa-car show-xs"></i> {{globalService.getGlobalVehicleName(appointment)}}</span>
                          </td>
                          <td>
                            <span><i class="fa fa-regular fa-clock show-xs"></i> {{appointment?.est_hours}} hrs</span>
                          </td>
                        </tr>
                        <tr class="no-result-row" *ngIf="shopStatistics?.totalAppointments_data.length == 0">
                          <td colspan="6" class="no-results-message">
                            <p class="mat-error text-center">No Results Found</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-sm-6 flex-sm table-col border-bottom-xs column-block" *ngIf="isAllowed('views','service_request')">
                  <div class="border-row">
                    <h4>UNASSIGNED:</h4>
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Request Id</th>
                          <th>Title</th>
                          <th>YMM</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let unassignSR of shopStatistics?.totalUnassigned_Data">
                          <td>
                            <span><i class="fa fa-solid fa-hashtag show-xs"></i> <a class="hover-link cursor-pointer" (click)="openServiceRequestViewPage(unassignSR.id)"> {{unassignSR?.display_request_id}}</a></span>
                          </td>
                          <td>
                            <span><i class="fa fa-solid fa-tools show-xs"></i> {{unassignSR?.title || '-'}}</span>
                          </td>
                          <td>
                            <span><i class="fa fa-solid fa-car show-xs"></i> {{globalService.getGlobalVehicleName(unassignSR) || '-'}}</span>
                          </td>
                        </tr>
                        <tr class="no-result-row " *ngIf="shopStatistics?.totalUnassigned_Data?.length == 0">
                          <td colspan="6" class="no-results-message">
                            <p class="mat-error text-center">No Results Found</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-sm-6 flex-sm table-col column-block" *ngIf="isAllowed('views','service_request')">
                  <div class="border-row">
                    <h4>ASSIGNED TO ME:</h4>
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Request Id</th>
                          <th>Title</th>
                          <th>YMM</th>
                          <th>Timer (Allocated/Working)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr  *ngFor="let assignedSR of shopStatistics?.totalAssignedToMe_Data">
                          <td>
                            <span><i class="fa fa-solid fa-hashtag show-xs"></i> <a class="hover-link cursor-pointer"(click)="openServiceRequestViewPage(assignedSR.id)"> {{assignedSR?.display_request_id}}</a></span>
                          </td>
                          <td>
                            <span><i class="fa fa-solid fa-tools show-xs"></i> {{assignedSR?.title || '-'}}</span>
                          </td>
                          <td>
                            <span><i class="fa fa-solid fa-car show-xs"></i> {{globalService.getGlobalVehicleName(assignedSR) || '-'}}</span>
                          </td>
                          <td>
                            <span><i class="fa fa-clock show-xs"></i><span class="show-xs"> &nbsp;</span>
                              <img [src]="gc.TIMER_ICON" class="width-20" *ngIf="assignedSR?.work_status == 1">
                              {{ globalService.convertToMinutes(assignedSR.allocated_time) }}/
                               {{ assignedSR.totalHour }}</span>
                          </td>
                        </tr>
                        <tr class="no-result-row " *ngIf="shopStatistics?.totalAssignedToMe_Data?.length == 0">
                          <td colspan="6" class="no-results-message">
                            <p class="mat-error text-center">No Results Found</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
               
                <div class="col-sm-6 flex-sm table-col column-block tech-column" *ngIf="isAllowed('views','service_request')">
                  <div class="border-row">
                    <h4>TECHS:</h4>
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Tech Name</th>
                          <th>Request Id</th>
                          <th>Timer (Allocated/Working)</th>
                          <th>View</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let techAssignSR of shopStatistics?.tech_Data">
                          <td class="tech-name-col">
                            <span><i class="fa fa-solid fa-user show-xs"></i> {{techAssignSR.first_name}} {{techAssignSR.last_name}}</span>
                            <span class="span-btn cursor-pointer"><i class="fa fa-fw fa-list" (click)="navigateToSRList(techAssignSR?.user_id)"></i>&nbsp;&nbsp; 
                              <img class="width-20" src="/assets/img/workflow-black.svg" title="Job Board"  (click)="navigateToJobBoard(techAssignSR?.user_id)">
                            </span>
                          </td>
                          <td>
                            <span><i class="fa fa-solid fa-hashtag show-xs"></i> <a class="hover-link cursor-pointer" (click)="openServiceRequestViewPage(techAssignSR.id)"> {{techAssignSR?.display_request_id}}</a></span>
                            <br>
                            <span class="font-xs"><i class="fa fa-solid fa-car"></i> {{globalService.getGlobalVehicleName(techAssignSR) || '-'}}</span>
                            <br>
                            <div class="blocks ar-block font-xs" *ngIf="techAssignSR.accepted_rejected_jobs.accepted > 0 || techAssignSR.accepted_rejected_jobs.rejected > 0">
                              <span><i class="fa fa-wrench"></i></span>&nbsp; <span class="font-bold text-success no"> {{techAssignSR.accepted_rejected_jobs.accepted}}</span>&nbsp; <span class="separator">|</span><span class="font-bold text-danger no"> &nbsp;{{techAssignSR.accepted_rejected_jobs.rejected}}</span>
                            </div>
                          </td>
                          <td>
                            <span><i class="fa fa-clock show-xs"></i><span class="show-xs"> &nbsp;</span> 
                              <img [src]="gc.TIMER_ICON" class="width-20" *ngIf="techAssignSR?.work_status == 1">
                             {{ globalService.convertToMinutes(techAssignSR.allocated_time) }}/
                              {{ techAssignSR.totalHour }}</span>
                          </td>
                          <td class="hidden-xs view-col">
                            <span class="hidden-xs">
                              <i class="fa fa-fw fa-list cursor-pointer" (click)="navigateToSRList(techAssignSR?.user_id)" title="Service Request List"></i>&nbsp; 
                              <img class="width-20 cursor-pointer" src="/assets/img/workflow-black.svg" title="Job Board"  (click)="navigateToJobBoard(techAssignSR?.user_id)">
                            </span>
                          </td>
                        </tr>
                        <tr class="no-result-row " *ngIf="shopStatistics?.tech_Data?.length == 0">
                          <td colspan="6" class="no-results-message">
                            <p class="mat-error text-center">No Results Found</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-sm-6 flex-sm table-col border-bottom-xs   column-block hide" *ngIf="isAllowed('views','service_request')">
                  <div class="border-row">
                    <h4>Overtimer:</h4>
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Request Id</th>
                          <th>Title</th>
                          <th>YMM</th>
                          <th>Timer A/W</th>
                        </tr>
                      </thead>
                      <tbody >
                        <tr>
                          <td>
                            <span><i class="fa fa-solid fa-hashtag show-xs"></i> <a class="hover-link cursor-pointer"> CAR2024100458766</a></span>
                          </td>
                          <td>
                            <span><i class="fa fa-solid fa-tools show-xs"></i> Brake Issue</span>
                          </td>
                          <td>
                            <span><i class="fa fa-solid fa-car show-xs"></i> 1973/AC/428</span>
                          </td>
                          <td>
                            <span><i class="fa fa-clock show-xs"></i> 1.5 hrs / 2.40 hrs</span>
                          </td>
                        </tr>
                        <tr class="no-result-row hide">
                          <td colspan="4" class="no-results-message">
                            <p class="mat-error text-center">No Results Found</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-sm-6 flex-sm table-col border-bottom-xs column-block notes-table">
                  <div class="border-row">
                    <h4>
                      <span>Notes:</span>
                      <span class="absolute-btns-section">
                        <span class="btn" title="Add Internal Note" (click)="openAddInternalNotes()"><i class="fa fa-solid fa-plus"></i></span>
                        <span class="btn" (click)="navigateToPage('shops/internalnote/list')" title="View All Internal Notes"><i class="fa fa-solid fa-list"></i></span>
                      </span>
                    </h4> 
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Note Id</th>
                          <th>Title</th>
                          <th>Status</th>
                          <th>View</th>
                        </tr>
                      </thead>
                      <tbody >
                        <tr *ngFor="let note of internalNotesData">
                          <td>
                              <span><i class="fa fa-solid fa-hashtag show-xs"></i> {{note.id}}</span>
                            <span class="btn visible-xs cursor-pointer" (click)="handleViewInternalNotes(note)" aria-label="View Note">
                              <i class="fa fa-eye"></i>
                            </span>
                          </td>
                          <td> <span><i class="fa fa-solid fa-tools show-xs"></i> {{note.title}}</span></td>
                          <td >
                            <span class="flex">
                              <i class="fa fa-solid fa-square-poll-horizontal show-xs"></i> 
                              <mat-chip-list class="mat-badge" >
                                <mat-chip class="mat-chip" color="accent" selected="true"> {{getStatus(note.status)}}</mat-chip>
                              </mat-chip-list>
                             
                            </span>
                          </td>
                          <td class="hidden-xs">
                            <i class="fa fa-eye cursor-pointer" (click)="handleViewInternalNotes(note)" title="View Note" aria-label="View Note"></i>
                           
                          </td>
                        </tr>
                        <tr class="no-result-row " *ngIf="internalNotesData?.length == 0">
                          <td colspan="6" class="no-results-message">
                              <p class="mat-error text-center">No Results Found</p>
                          </td>
                       </tr>
                      </tbody>
                     
                    </table>
                  </div>
                </div>
               
                
            </div>
        </div>
        
    </div>
    
  </div>

  <app-common-sidepanel-drawer [guideType]="guideType"></app-common-sidepanel-drawer>
  