import { Component, OnInit, ChangeDetectorRef, Inject,OnDestroy  } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IDatePickerConfig } from 'ng2-date-picker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import {Subscription} from 'rxjs'; 

@Component({
  selector: 'app-editnote',
  templateUrl: './editnote.component.html',
  styleUrls: ['./editnote.component.scss']
})
export class EditnoteComponent implements OnInit,OnDestroy  {

  public notes: FormGroup;
  public noteId: any = null;
  public noteImgDocs: any = [];
  public user: any = {};
  public employes = [];
  public isMultipleFile = true;
  public param: any = null;
  public FORMAT_SUPPORTED = globalConstants.FORMAT_SUPPORTED_FOR_IMAGE + ', pdf, ' + globalConstants.FORMAT_FOR_DOC;
  public noteDetail: any = null;
  public assignees: any = [];
  public noteDocPath = globalConstants.INTERNAL_NOTE_DOC;
  public imgPath = globalConstants.S3_BUCKET_URL;
  public minDate = new Date();
  public minDates=(this.minDate.getMonth()+1)+'/'+this.minDate.getDate()+'/'+this.minDate.getFullYear();
  public isEmployee=false;
  public datePickerConfig: IDatePickerConfig = {
    format: "MM/DD/YYYY hh:mm A",
    min: this.gs.getFullFormateDate(new Date(), 'G','C'),
    drops:'down',
    disableKeypress:true,
    unSelectOnClick:false
  };
  public subscriptionDataForAPI: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<EditnoteComponent>,public router: Router, private activatedRoute: ActivatedRoute, private ref: ChangeDetectorRef, private formBuilder: FormBuilder, public gs: GlobalService) {
    this.user = this.gs.getCurrentUser();
    this.gs.diaLogObj(this.dialogRef);
    if (this.user) {
      this.gs.setMetaData("SHOPS", "EDIT_INTERNAL_NOTE");
    } else {
      this.router.navigate(['shops']);
    }
    this.isEmployee=this.gs.isSubscribe('employee');
  }

  ngOnInit() {
    this.getParams();
    this.isMultipleFile = this.gs.isMultifileAllowed()
    this.notes = this.formBuilder.group({
      note_id: this.noteId,
      title: ['', Validators.required],
      description: ['', Validators.required],
      notify_date:[''],
      status: ['0'],
      assignees: [],
      doc_files: [],
      user_id: this.user.id,
      user_type: this.user.user_type
    });
    this.getDetail()
  }
  getParams() {
    if(typeof this.data.id !='undefined'){
      this.noteId = this.data.id 
    }else{
      this.activatedRoute.params.subscribe((params: Params) => {
        this.noteId = params["id"];
      });
    }
  }
  selectedEmps(obj) {
    if (this.notes.value.status == 1) {
      this.gs.validateState(this.notes, ['assignees']);
    }
  }

  /** get notes detail */
  getDetail() {
    let data = { user_id: this.user.id, user_type: this.user.user_type, note_id: this.noteId };
    this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_GET_INTERNAL_NOTE_COMMENT, data).subscribe((r:any) => {
      if (r.result == 1) {
        let th = this;
        this.noteDetail = r.data;
        if(this.noteDetail.added_by != this.user.id){
          this.router.navigate(['shops/internalnote/list'])
        }
        this.notes.controls.title.setValue(this.noteDetail.title);
        this.notes.controls.description.setValue(this.noteDetail.description);
        this.notes.controls.status.setValue(this.noteDetail.status);
        let d=this.gs.getFullFormateDate(this.noteDetail.notify_date, 'G', 'H')
        if(d!='-'){
          // this.minDate=new Date(this.noteDetail.notify_date)
          this.notes.controls.notify_date.setValue(d)
        }
        if (this.noteDetail.assignees) {
          this.noteDetail.assignees.forEach((r, i) => {
            th.assignees.push(r.user_id);
          });
          this.ref.detectChanges();
          this.ref.markForCheck();
        }
        if(this.isEmployee){
          this.getEmployee()
        }
      }
    }, (error) => { })
  }
  getSelected(e) {
    let i = this.assignees.indexOf(e);
    if (i != -1) {
      this.assignees.splice(i, 1);
    }
  }
  getEmployee() {
    let data = { user_id: this.user.id, user_type: this.user.user_type, opr: 1,pr:1 };
    this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_GET_TECHNICIAN, data).subscribe((r:any) => {
      if (r.result == 1) {
        let th = this;
        this.employes = [];
        r.data.forEach((r) => {
          let a = { id: r.id, text: r.first_name + ' ' + r.middle_name + ' ' + r.last_name };
          th.employes.push(a);
        });
        this.ref.markForCheck();
        this.ref.detectChanges();
      }
    }, (error) => { })

  }
  editNotes(type) {

    let fcData = this.notes;
    for (let inner in this.notes.controls) {
      this.notes.get(inner).markAsTouched();
      this.notes.get(inner).updateValueAndValidity();
    }
    if (this.notes.value.status == 1) {
      this.gs.validateState(this.notes, ['assignees']);
    }
    let th = this;
    if (fcData.valid) {
      let fileNames = [];
      this.noteImgDocs.forEach(ele => {
        fileNames.push(ele.name);
      });
      let file = JSON.stringify(fileNames);
      this.notes.controls.doc_files.setValue(file);
      // if(fcData.controls.notify_date.value){
      //   let d = new Date(fcData.controls.notify_date.value);
      //   fcData.value.notify_date = (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear();
      // }
    
      let data = Object.assign({},this.notes.value);
      data['notify_date'] = moment(data['notify_date']).format('MM/DD/YYYY hh:mm A Z');
      this.gs.formData(globalConstants.API_EDIT_INTERNAL_NOTE,  this.gs.formsData(data)).subscribe((r) => {
        if (r.result == 1) {
          this.gs.snackbar("success", r.message);
          // this.router.navigate(['shops/internalnote/list'])
          this.dialogRef.close(type);
        } else {
          this.gs.snackbar("error", r.message);
        }
      }, (error) => { })
    } else {
      this.gs.snackbar("error", globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
  }
  getFileIcons(name) {
    return this.gs.getFileIcon(name)
  }
  deleteImgDoc(i, type,obj?:any) {
    if (type == 1) {
      this.noteImgDocs.splice(i, 1);
    } else if (type == 2) {
      let conf = "Are you sure you want to continue?";
      let data={ user_id: this.user.id, user_type: this.user.user_type, doc_id: obj.id }
      this.gs.confirmDialog('deleteDoc', conf).subscribe((r) => {
        let res: any = r;
        if (res == 'deleteDoc') {
          this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_DELETE_INTERNAL_NOTE_DOCUMENT, data).subscribe((res:any) => {
            if (res.result == '1') {
              this.noteDetail.docs.splice(i, 1);
              this.gs.snackbar("success", res.message);
            } else {
              this.gs.snackbar("error", res.message);
            }
          });
        }
      });
    }
  }
  changeListner(event) {
    let valid = false;
    let ele = <HTMLInputElement>document.getElementById('addInternalNotesDoc');
    if (!this.isMultipleFile && this.noteImgDocs.length > 0) {
      this.gs.snackbar("error", "Update your subscription to upload multiple files.");
      event.target.value = "";
      if (ele) {
        ele.value = '';
      }
      return false;
    }
    let cFiles = { src: '', type: 0, name: '' };
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      valid = this.gs.validateFiles('doc', event.target.files);
      if (valid) {
        var reader = new FileReader();
        let base64valuec;
        reader.onload = (e) => {
          base64valuec = e.target['result'];
          cFiles.src = base64valuec;
        }
        let FileType = this.gs.getFileIcon(file);
        cFiles.type = FileType;
        if (FileType == 1) {
          reader.readAsDataURL(event.target.files[0]);
        }
        let data = new FormData();
        let url = globalConstants.API_NEW_SERVICE_REQUEST_FILE;
        data.append('user_id', this.user.id);
        data.append('user_type', this.user.user_type);
        data.append('doc', file);

        this.gs.formData(url, data).subscribe((response) => {
          let resData = response;
          if (resData.result == 1) {
            event.target.value = "";
            cFiles.name = resData.data.name;
            this.noteImgDocs.push(cFiles);
          } else {
            this.gs.snackbar("error", resData.message);
          }
          if (ele) {
            ele.value = '';
          }
        });
      } else if (ele) {
        ele.value = '';
      }
    }
  }
  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
  } 
   
}
