<div mat-dialog-title class="relative" >
    Enter Present Mileage
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
        <mat-icon class="notranslate close-icon-lg">close</mat-icon>
    </button>
</div>
<!-- BODY -->
<mat-dialog-content>
    <form class="form-horizontal form-control-normal presentMileage-form" autocomplete="off" [formGroup]="addMileageForm">
        <div class="display-full margin-bottom-5">
            <div class="col-sm-12">
                <label>Present Mileage (Miles)</label>
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                    <input matInput type="text" placeholder="{{distance_covered}}" title="{{distance_covered}}" formControlName="present_mileage" class="form-control input-lg custom-form-input btn-block"  (keypress)="onlyNumericKey($event)"/>
                    <mat-error *ngIf="addMileageForm.controls.present_mileage.hasError('required')">
                        This field cannot be empty.
                    </mat-error>
                    <mat-error *ngIf="addMileageForm.controls.present_mileage.hasError('pattern')">
                        Please enter only numbers or upto two decimal place
                    </mat-error>
                </mat-form-field>
            </div>
        </div>


      <div class="display-full margin-bottom-5">
          <div class="col-sm-12 text-right flexBtns">
            <button type="button" mat-raised-button  color="accent" title="Save" (click)="addMileage()">
                SAVE
            </button>
            <button mat-raised-button mat-dialog-close class="font-bold" title="Close">Close</button>
          </div>
      </div>

    </form>
</mat-dialog-content>

<!-- END BODY -->