import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { globalConstants } from "../../../../environments/globalConstants";
import { GlobalService } from "../../../../environments/global.service";

@Component({
  selector: 'app-edit-template',
  templateUrl: './edit-template.component.html',
  styleUrls: ['./edit-template.component.scss']
})
export class EditTemplateComponent implements OnInit {

  public shopEditTemplate: FormGroup;
  public currentUser;
  public tempId: number;
  public templateDetail: any = {};
  public variables: Array<any> = [];

  constructor(private formBuilder: FormBuilder, private router: Router, private activatedRoute: ActivatedRoute, public globalService: GlobalService) {

    if (localStorage.getItem("loggedInUser")) {
      this.currentUser = JSON.parse(localStorage.getItem("loggedInUser"));
    } else {
      router.navigate(["/shops"])
    }
    setTimeout(() => {
      let inSubscription: boolean = true;
      let isEnable = this.globalService.getAcl('sms_template', 'edits');
      if (!(isEnable) || !(inSubscription)) {
        this.router.navigate(['shops/action/forbidden']);
      }
    }, globalConstants.RELOAD_WAIT);
  }

  ngOnInit() {
    //this.globalService.setMetaData("SHOPS", "EDIT_DISCOUNT");
    this.getParams();
    this.initialializeEditDiscountForm();
    this.initializeFormDetails();
  }

  getParams() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.tempId = params["id"];
    });
  }

  initialializeEditDiscountForm() {
    this.shopEditTemplate = this.formBuilder.group({
      temp_id: ['', Validators.required],
      title: ['', Validators.required],
      template: ['', Validators.required]
    });
  }

  initializeFormDetails() {
    let data = { user_id: this.currentUser.id, user_type: this.currentUser.user_type, temp_id: this.tempId };
    this.globalService.callAPI(globalConstants.API_SHOP_SMS_TEMPLATES_DETAIL_URL, data).subscribe((r:any) => {
      if (r.result == 1) {
        this.templateDetail = r.data;
        this.shopEditTemplate.controls.temp_id.setValue(this.templateDetail.id);
        this.shopEditTemplate.controls.title.setValue(this.templateDetail.title);
        this.shopEditTemplate.controls.template.setValue(this.templateDetail.template);
        if (this.templateDetail && 'description' in this.templateDetail) {
          let regex = /(?:^|\s)(?:#)([a-zA-Z\d]+)/gm;
          let match;
          while ((match = regex.exec(this.templateDetail.description))) {
            this.variables.push(match[1]);
          } 
        }
      } else {
        let message = r.message;
        this.router.navigate(['shops/sms-templates']);
        this.globalService.snackbar('error', message);
      }
    }, (error) => { })
  }

  onSubmit() {
    if (this.shopEditTemplate.valid) {
      let data = new FormData();
      data.append('user_type', this.currentUser.user_type);
      data.append('user_id', this.currentUser.id);
      data.append('temp_id', this.shopEditTemplate.value.temp_id);
      data.append('template', this.shopEditTemplate.value.template);
      console.log('asdf', data);
      this.globalService.formData(globalConstants.API_SHOP_SMS_TEMPLATES_EDIT_URL, data).subscribe((r:any) => {
        if (r.result == 1) {
          let message = r.message;
          this.router.navigate(['shops/sms-templates']);
          this.globalService.snackbar('success', message);
        } else {
          let message = r.message;
          this.globalService.snackbar('error', message);
        }
      }, (error) => { })

    } else {
      this.globalService.validateAllFormFields(this.shopEditTemplate);
    }
  }

}
