import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { globalFunctions } from '../../../../environments/globalFunctions';
import * as moment from 'moment';
import { CommonDialogBigComponent } from '../../common-dialog-big/common-dialog-big.component';

@Component({
  selector: 'app-milesout',
  templateUrl: './milesout.component.html',
  styleUrls: ['./milesout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MilesoutComponent implements OnInit {
  public milesOut;
  public miles:any={};
  public milesForm:FormGroup;
  public confTxt ='<center><b>Are You Sure: </b></center> <center>The technician(s) work is completed on this service request? <br><span class="milesout-popup"><i>This action stops all timers immediately!</i></span> </center>';
  public note = '<center><b>Completing This Action:</b></center> <center>Mark this service request as complete and generates the invoice.</center>';
  public carDetail:any={};
  public viewingJSON=[];
  public genericJobsDropDownModel={};
  public showDiagnosticCheckbox=false;
  public showCostPrice=false;
  public showExistingReminderSection: boolean = false;
  public showExistingReminderBanner : boolean = false;
  public scheduleByDate : any ;
  public minDate = new Date();
  public isShopOwner :boolean = false;
  public gc = globalConstants;
  public permissionObj : any ={
       dfsPermission:false,
       extPaymentPermission:false,
       pvtReviewPermission:false
  };
  public scheduleByMilesTemp: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder, 
    public dialogRef: MatDialogRef<MilesoutComponent>, 
    private gs: GlobalService,
    public dialog:MatDialog
  ) {
    this.miles = data;
  }

  ngOnInit() {
    let user = this.gs.getCurrentUser();
    if (!user) {
      this.dialogRef.close(2);
    }
    if(user.role_id == globalConstants.ROLE_ID_SHOP_OWNER){
        this.isShopOwner = true;
    }
    this.gs.diaLogObj(this.dialogRef);
    this.milesForm = this.formBuilder.group({
      miles_out: [''],
      request_id: [this.miles.requestId],
      labor_charge:[''],
      labor_time:[''],
      is_diagnostic:[0],
      generic_jobs:[''],
      schedule_by_miles:['', [Validators.pattern(/^\d+(\.\d)?$/)]],
      schedule_by_date :[''],
      include_review_url :[''],
      dont_set_reminder:[0],
      set_new_reminder :[1],
      review_url_chk: [0],
      payment_url_chk :[0],
      review_notes:this.miles.pvtReviewsSettingsObj.reviewNotes,
      review_notes_included:this.miles.pvtReviewsSettingsObj.reviewNotesIncluded,
      review_url:this.miles.pvtReviewsSettingsObj.reviewUrl,
      review_url_included:this.miles.pvtReviewsSettingsObj.reviewUrlIncluded,
      payment_notes:this.miles.extPaymentSettingsObj.paymentNotes,
      payment_url:this.miles.extPaymentSettingsObj.paymentUrl,
      payment_notes_included:this.miles.extPaymentSettingsObj.paymentNotesIncluded,
      payment_url_included:this.miles.extPaymentSettingsObj.paymentNotesIncluded
    });
    var reOpenSR :boolean = false;
    if(this.miles.existingReminderObj.existingReminder == false){
      this.showExistingReminderSection = false;
      this.showExistingReminderBanner = false;
    }else{
      if(this.miles.existingReminderObj.existingReminder.request_id == this.miles.requestId){
        this.showExistingReminderBanner = false;
        this.showExistingReminderSection = false;
        reOpenSR = true;
      }else{ //existing reminder condition
        this.showExistingReminderBanner = true;
        this.showExistingReminderSection = true;
        this.milesForm.controls.dont_set_reminder.setValue(0);
        this.milesForm.controls.set_new_reminder.setValue(0);
      }
    }

    if (this.miles.extPaymentSettingsObj.paymentUrlIncluded == '0') {
      this.handleDoNotSetPaymentURl(false);
    } else {
      this.handleDoNotSetPaymentURl(true);
    }

    if (this.miles.pvtReviewsSettingsObj.reviewUrlIncluded == '0') {
      this.handleDoNotSetReviewURl(false);
    } else {
      this.handleDoNotSetReviewURl(true);
    }
    this.permissionObj.dfsPermission = this.gs.getAcl('dfs_reminders','addNew');
    this.permissionObj.extPaymentPermission = this.gs.getAcl('external_payment','views');
    this.permissionObj.pvtReviewPermission =  this.gs.getAcl('private_review','views');
    let carOpt = "&vehicle_id="+this.miles.car_id+"&vehicle_info=1&customer_id="+ this.miles.customer_id;
    this.gs.callAPI(globalConstants.API_GET_CUSTOMER_VEHICLES_URL,carOpt).subscribe((res:any) => {
      if (res.result == 1) {
        this.carDetail = res.data;
        var currentDate = new Date();
        if (reOpenSR) {
          this.milesForm.controls.schedule_by_miles.setValue(Number(this.miles.existingReminderObj.existingReminder.schedule_by_miles));
          this.scheduleByMilesTemp =  Number(this.carDetail.distance_covered) + Number(this.miles.existingReminderObj.standardMilage);
          this.scheduleByDate = new Date(this.miles.existingReminderObj.existingReminder.schedule_by_date)
        } else {
          this.milesForm.controls.schedule_by_miles.setValue(Number(this.carDetail.distance_covered) + Number(this.miles.existingReminderObj.standardMilage));
          this.scheduleByMilesTemp =  Number(this.carDetail.distance_covered) + Number(this.miles.existingReminderObj.standardMilage);
          currentDate.setDate(currentDate.getDate() + Number(this.miles.existingReminderObj.standardDays));
          this.scheduleByDate = currentDate;
        }
        if (this.carDetail.distance_covered == "") {
          this.carDetail.distance_covered = "N/A";
        }
      } else {
        this.carDetail.distance_covered = "N/A";
      }
    },(error)=>{});
    this.identifyJobs();
    this.showCostPrice=this.gs.getAcl('display_part_cost','views');
  }
  submit() {
    if ( this.milesForm.valid ){
          this.submitService(false);
      // remove this code when perform code cleanup task
      // if(typeof this.miles.returnData && this.miles.returnData){
      //   this.miles.recomData.append('miles_out',this.milesForm.value.miles_out);
      //   this.gs.formData(globalConstants.API_SAVE_RECOMMENDATION_URL, this.miles.recomData).subscribe((response) => {
      //     if (response.result == "1") {
      //       this.gs.snackbar("success", response.message);
      //       this.dialogRef.close(1);
      //     } else {
      //       this.gs.snackbar("error", response.message);
      //     }
      //   })
      // }else{
      //   this.submitService(false);
      // }
      //end

    }
  }
  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  disableKey(event) {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey == true && (event.which == '67' || event.which == '86' || String.fromCharCode(event.which).toLowerCase() == 's')) {
      event.preventDefault();
    }
  }
  submitService(override?: any) {
    let option=this.miles.option;
    var url = globalConstants.API_GENERATE_INVOICE_URL;
    let userId = this.gs.getCurrentUser()["id"];
    let data = {
      "service_request_id": this.miles.requestId,
      "user_type": "User",
      "user_id": userId,
      "is_tax_included": option.isTax,
      "tax_rate": option.tax,
      "total": option.total,
      "miles_out":this.milesForm.value.miles_out
    };
    let skipFirst: boolean = true;
    for (let d in data) {
      if (skipFirst == true) {
        url += "?" + d + "=" + data[d];
        skipFirst = false;
      } else {
        url += "&" + d + "=" + data[d];
      }
    }
    /** avoid the reverification to override the technician task */
    // if (override == true) {
      url += "&force_override=1";
    // }
    
    let jobIDs=[];
    this.milesForm.controls.generic_jobs.setValue('');
    this.viewingJSON.forEach((e,v)=>{
      jobIDs.push(e.id)
    })
    let scheduleByDateAPI= moment(this.scheduleByDate).format('MM/DD/YYYY');
    this.milesForm.controls.schedule_by_date.setValue(scheduleByDateAPI);
    this.milesForm.controls.generic_jobs.setValue(JSON.stringify(jobIDs));
    let reminderMiles;
    let reminderScheduleByDate;
    if(this.milesForm.value.set_new_reminder == 1){
      reminderMiles=  this.milesForm.value.schedule_by_miles;
      reminderScheduleByDate = this.milesForm.value.schedule_by_date;
    }else{
      reminderMiles =  this.miles.existingReminderObj.existingReminder.schedule_by_miles;
      reminderScheduleByDate = moment(this.miles.existingReminderObj.existingReminder.schedule_by_date).format('MM/DD/YYYY');
    }
    let formData={
      vehicle_eval:"",
      is_diagnostic:this.milesForm.value.is_diagnostic,
      schedule_by_miles:this.permissionObj.dfsPermission?reminderMiles:this.scheduleByMilesTemp,
      schedule_by_date :reminderScheduleByDate,
      include_review_url :this.milesForm.value.include_review_url,
      dont_set_reminder:this.milesForm.value.dont_set_reminder,
      set_new_reminder :this.milesForm.value.set_new_reminder,
      review_url_chk :(this.miles.permissionObj.privateReview == '0' || (this.miles.isWalkinCustomer == '1' || this.miles.isWalkinVehicle == '1'))?'0':this.milesForm.value.review_url_chk,
      review_notes:this.milesForm.value.review_notes,
      review_url:this.milesForm.value.review_url,
      review_notes_included:this.milesForm.value.review_notes_included,
      review_url_included:this.milesForm.value.review_url_included,
      payment_url_chk :(this.miles.permissionObj.externalPayment == '0'|| (this.miles.isWalkinCustomer == '1' || this.miles.isWalkinVehicle == '1'))?'0': this.milesForm.value.payment_url_chk, 
      payment_notes:this.milesForm.value.payment_notes,
      payment_url:this.milesForm.value.payment_url,
      payment_notes_included:this.milesForm.value.payment_notes_included,
      payment_url_included:this.milesForm.value.payment_url_included,
    };
    formData.vehicle_eval = JSON.stringify(this.milesForm.value);
    if (formData.review_url_chk == 0 && formData.payment_url_chk == 0) {
      this.generateInvoiceAPICall(url, formData);
    } else {
      this.validateReviewPaymentUrl(url,formData);
    }
  }

  generateInvoiceAPICall(url,formData){
    this.gs.callAPI(url,formData).subscribe((resp:any) => {
      if (resp.result == "1") {
        this.gs.snackbar("success", resp.message);
        this.dialogRef["miles_out"]=this.milesForm.value.miles_out;
        this.dialogRef.close(1);
      } else {
        if (resp.code == "393") {
          this.handelConflict();
        } else {
          this.gs.snackbar("error", resp.message);
        }
      }
    },(error)=>{});
  }

  validateReviewPaymentUrl(url,formData){
    if((formData.review_url_chk == 1 && formData.review_url == '')){
      this.gs.snackbar("error", "Review URL value is missing. Please click the pencil icon to enter the URL to be included in the invoice email.");
    }else if((formData.payment_url_chk == 1 && formData.payment_url == '')){
      this.gs.snackbar("error", "Payment URL value is missing. Please click the pencil icon to enter the URL to be included in the invoice email.");
    }else{
      this.generateInvoiceAPICall(url, formData);
    }
  }

  handelConflict() {
    this.gs.confirmDialog("yes", this.note).subscribe((res) => {
      let r: any = res;
      if (r == 'yes') {
        this.submitService(true);
      }
    });
  }
  identifyJobs() {
    let requestList = this.miles.list;
    let rejected = [];
    if(typeof requestList=='undefined'){
      return false;
    }
    for (let i in requestList) {
      let isAnyAccepted = false;
      let isAnyRejected = false;
      let isAnyRemain = false;
      let servicerequestChild = requestList[i].children;
      for (let j in servicerequestChild) {
        let recommendation = servicerequestChild[j].recommendations;
        for (let k in recommendation) {
          if (recommendation[k].approval_status == 1) {
            isAnyAccepted = true;
          } else if (recommendation[k].approval_status == 2) {
            isAnyRejected = true;
          } else {
            isAnyRemain = true;
          }
        }
        let level3 = servicerequestChild[j].children;
        for (let k in level3) {
          let recomand3 = level3[k].recommendations;
          for (let m in recomand3) {
            if (recomand3[m].approval_status == 1) {
              isAnyAccepted = true;
            } else if (recomand3[m].approval_status == 2) {
              isAnyRejected = true;
            } else {
              isAnyRemain = true;
            }
          }
        }
      }
      if (isAnyRejected) {
        rejected.push(i)
      }
    }
    if (rejected.length > 0) {
      this.showDiagnosticCheckbox = true;
    }

    if(requestList.length == 0){
       this.showDiagnosticCheckbox = true;
    }
  }
  diagnosticForm($e) {
    let checked: any = 0;
    if ($e.checked === true) {
      checked = 1;
    }
    this.milesForm.controls.is_diagnostic.setValue(checked);
  }

  getSelectedOptionForGj(opt) {
    if (typeof opt == "object") {
      (<HTMLInputElement>document.getElementById("new-gj-qty")).value = opt.quantity;
      this.genericJobsDropDownModel = {
        id: opt.id,
        name: opt.name,
        price: opt.price,
        quantity: opt.quantity,
        non_taxable: opt.non_taxable
      };
    }
  }
  observableSourceforGJ = (keyword: any): Observable<any[]> => {
    let url: string = globalConstants.API_GET_GENERIC_JOBS_URL + "?from_v2=1&user_id=" + this.gs.getCurrentUser()["id"] + "&query=" + keyword;
    if (keyword || 1) {
      let f = [];
      return this.gs.getAPI(url)
        .map(res => {
          let json = res;
          let show=this.showCostPrice;
          this.gs.authText(json);
          if (json != null) {
            json.forEach(function (x) {
              x.value = x.label;
              x.name = x.label;
              x.label+=(show?' '+x.price:'');
              x.quantity = 1;
              f.push(x)
            }
            );
          }
          return f
        })
    } else {
      return Observable.of([]);
    }
  }
  addNewGJ(event) {
    var updatedQty = (<HTMLInputElement>document.getElementById("new-gj-qty")).value;
    if (updatedQty != "" && updatedQty != undefined && parseFloat(updatedQty) > 0 && isNaN(Number(updatedQty)) != true) {
      if (updatedQty.indexOf('.') != -1) {
        updatedQty = parseFloat(updatedQty).toString();
        if (updatedQty.indexOf('.') != -1) {
          updatedQty = updatedQty.substr(0, updatedQty.indexOf('.')) + updatedQty.substr(updatedQty.indexOf('.'), 4);
        }
      }
      this.genericJobsDropDownModel["quantity"] = updatedQty;

      var isAlreadyPresentInNGJ = this.viewingJSON.find((x) => {
        return x.id == this.genericJobsDropDownModel["id"]
      });

      if (isAlreadyPresentInNGJ != undefined) {
        this.gs.snackbar('error', " Generic Job already Exists");
      } else {
        this.viewingJSON.push(this.genericJobsDropDownModel);
        this.genericJobsDropDownModel = {};
      }
      (<HTMLInputElement>document.getElementById("new-gj-ac")).value = "";
      (<HTMLInputElement>document.getElementById("new-gj-qty")).value = "";

    } else {
      if (this.genericJobsDropDownModel == undefined || this.genericJobsDropDownModel == "") {
        this.gs.snackbar('error', "Please Enter Generic Job");
      }
      else if (parseFloat(updatedQty) <= 0 || updatedQty == "") {
        this.gs.snackbar('error', "Please Enter Generic Job");
      }
      if (isNaN(Number(updatedQty)) == true) {
        this.gs.snackbar('error', "Invalid Quantity");
      }
    }
  }
  onlyNumericKey(event) {
    return globalFunctions.onlyDecimalNumberKey(event);
  }
  setReturnFalse() {
    return false
  }
  removeGJ(index) {
    delete (this.viewingJSON[index])
    this.viewingJSON.splice(index, 1);
  }
  addLabor(event) {
    let labor = event.target.value;
    if (parseInt(this.miles.labor_rate) > 0) {
      if ((isNaN(parseFloat(event.target.value)) == true) || (event.target.value == '')) {
        event.target.value = 0;
      }
      let price = (parseFloat(this.milesForm.value.labor_charge) / parseFloat(this.miles.labor_rate)).toFixed(2);
      if(isNaN(parseFloat(price))){
        this.milesForm.controls.labor_time.setValue('0.00');
      }else{
        this.milesForm.controls.labor_time.setValue(price);
      }
     
    }
  }
  addTime(event) {
    if (parseInt(this.miles.labor_rate) > 0) {
      if ((isNaN(parseFloat(event.target.value)) == true) || (event.target.value == '')) {
        event.target.value = 0;
      }
      let price = (parseFloat(this.milesForm.value.labor_time) * parseFloat(this.miles.labor_rate)).toFixed(2);
      if(isNaN(parseFloat(price))){
        this.milesForm.controls.labor_charge.setValue('0.00');
      }else{
        this.milesForm.controls.labor_charge.setValue(price);
      }
    }
  }
  myListFormatter(data: any): string {
    return data['label'];
  }

  handleDoNotSetReminder(event){
       if(event == true){
          this.milesForm.controls.dont_set_reminder.setValue(1);
        }else{
          this.milesForm.controls.dont_set_reminder.setValue(0);
        }
  }

  handleToSwitchRemindersScreen(screenType){
       if(screenType == 1){
        this.showExistingReminderSection = true;
        this.milesForm.controls.dont_set_reminder.setValue(0);
        this.milesForm.controls.set_new_reminder.setValue(0);
       }else{
        this.showExistingReminderSection =  false;
        this.milesForm.controls.dont_set_reminder.setValue(0);
        this.milesForm.controls.set_new_reminder.setValue(1);
       }
  }

  convertDateFormat(standardDate){
    return moment(standardDate).format('MM/DD/YYYY');
  }

  validateNumbersWithDecimal(event) {
    return globalFunctions.validateNumbersWithDecimal(event,2);
  }

  openReviewAndPaymentDialog(screenType){
    let dialogTitle;
    if(screenType == 0){
      dialogTitle ="Private Review Settings";
    }else{
      dialogTitle ="External Payment Settings";
    }
    let dialogRef = this.dialog.open(CommonDialogBigComponent, {
      panelClass: ['car-dialog-form', 'common-dialog'],
      data: { 
        opr: 2,
        dialogTitle: dialogTitle,
        screenType : screenType,
       }
    });
    //screen type 0 means = review url and notes screen
    //screen type 1 means = payment url and notes screen
    dialogRef.afterClosed().subscribe(result => {
      if (result.status == true) {
          if(result.reviewAndPaymentSettingObj.screenType == 0){
            this.milesForm.controls.review_notes.setValue(result.reviewAndPaymentSettingObj.pvtReviewsSettingsObj.review_notes);
            this.milesForm.controls.review_notes_included.setValue(result.reviewAndPaymentSettingObj.pvtReviewsSettingsObj.review_notes_included);
            this.milesForm.controls.review_url.setValue(result.reviewAndPaymentSettingObj.pvtReviewsSettingsObj.review_url);
            this.milesForm.controls.review_url_included.setValue(result.reviewAndPaymentSettingObj.pvtReviewsSettingsObj.review_url_included);
            if (result.reviewAndPaymentSettingObj.pvtReviewsSettingsObj.review_url_included == '0') {
              this.handleDoNotSetReviewURl(false);
            } else {
              this.handleDoNotSetReviewURl(true);
            }
          }else if(result.reviewAndPaymentSettingObj.screenType == 1){
            this.milesForm.controls.payment_notes.setValue(result.reviewAndPaymentSettingObj.extPaymentSettingsObj.payment_notes);
            this.milesForm.controls.payment_notes_included.setValue(result.reviewAndPaymentSettingObj.extPaymentSettingsObj.payment_notes_included);
            this.milesForm.controls.payment_url.setValue(result.reviewAndPaymentSettingObj.extPaymentSettingsObj.payment_url);
            this.milesForm.controls.payment_url_included.setValue(result.reviewAndPaymentSettingObj.extPaymentSettingsObj.payment_url_included);
            if (result.reviewAndPaymentSettingObj.extPaymentSettingsObj.payment_url_included == '0') {
              this.handleDoNotSetPaymentURl(false);
            } else {
              this.handleDoNotSetPaymentURl(true);
            }
          }
      }
    })
  }

  handleDoNotSetPaymentURl(event){
    if(event == true){
       this.milesForm.controls.payment_url_chk.setValue(1);
     }else{
       this.milesForm.controls.payment_url_chk.setValue(0);
     }
  }

  handleDoNotSetReviewURl(event){
    if(event == true){
       this.milesForm.controls.review_url_chk.setValue(1);
     }else{
       this.milesForm.controls.review_url_chk.setValue(0);
     }
  }
   /**
   * validates the form control's value on paste event.
   * ensure that validation messages are displayed if the pasted value is invalid.
   */
   validateFieldOnPaste(controlName: string) {
    const control = this.milesForm.get(controlName);

    control.markAsTouched();
  }
}
