<div mat-dialog-title class="relative" >
    Edit Customer
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
        <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
</div>
<!-- BODY -->
<form class="form-horizontal form-control-normal" autocomplete="off" [formGroup]="customerEdit" (ngSubmit)="editCustomerData()">
<mat-dialog-content>
    
        <div class="display-full margin-bottom-5">
            <div class="col-sm-12">
                <label>Email</label>
                <mat-form-field class="btn-block car-input" floatPlaceholder="never">
                    <input matInput type="text" placeholder="Email" title="Email" formControlName="email" class="form-control input-lg custom-form-input btn-block" (keyup)="checkIfValid();" [(ngModel)]="diaplayEmail"/>
                    <mat-error *ngIf="customerEdit.controls.email.hasError('pattern')">
                        Please enter a valid email.
                    </mat-error>
                    <mat-error *ngIf="customerEdit.controls.email.hasError('required')">
                        Email is <strong>required</strong>.
                    </mat-error>
                </mat-form-field>
                
            </div>
        </div>
        
        <div class="display-full margin-bottom-5">
            <div class="col-sm-12">
                <label>Mobile Phone</label>
                <mat-form-field class="btn-block car-input" floatPlaceholder="never">
                    <input matInput (keyup)="phoneValidation('mobile_phone')" type="text" placeholder="Mobile Number" title="Mobile Number" formControlName="mobile_phone" class="form-control input-lg custom-form-input btn-block" (keyup)="checkIfValid();" [textMask]="{mask: phoneMask}" maxlength="15" [(ngModel)]="displayMobileNumber" />
                    <mat-error *ngIf="customerEdit.controls.mobile_phone.hasError('required')">
                        Mobile number is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="customerEdit.controls.mobile_phone.hasError('mobile_phone')">
                        Invalid mobile number
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="display-full margin-bottom-5">
       
            <div class="col-sm-6">
                <label>Account Activation</label>
                <mat-form-field class="btn-block car-input" floatPlaceholder="never">
                    <mat-select placeholder="Status" (change)="checkIfValid();" class="form-control input-lg custom-form-input" formControlName="activation_status">
                        <mat-option value="">--Status--</mat-option>
                        <mat-option value="0">Deactivate</mat-option>
                        <mat-option value="1">Activate</mat-option>
                    </mat-select>
                    <mat-error *ngIf="customerEdit.controls.activation_status.hasError('required')">
                            Account Activation is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                    <label>Email Verification</label>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" >
                        <mat-select placeholder="Status" (change)="checkIfValid();" class="form-control input-lg custom-form-input" formControlName="email_activation_status">
                            <mat-option value="">--Status--</mat-option>
                            <mat-option value="0" *ngIf="diaplayEmail!=customerDetail.email || customerEdit.value.email_activation==0">Send Verification Link</mat-option>
                            <mat-option value="1" *ngIf="diaplayEmail!=customerDetail.email || customerEdit.value.email_activation==0">Verify E-mail</mat-option>
                            <mat-option value="1" *ngIf="diaplayEmail == customerDetail.email && customerEdit.value.email_activation==1">Verified</mat-option>
                        </mat-select>  
                        <mat-error *ngIf="customerEdit.controls.email_activation_status.hasError('required')">
                                Email Verification is <strong>required</strong>
                        </mat-error>                    
                    </mat-form-field>
            </div>
            <div class="col-sm-6">
                <label>Request Origin</label>
                <mat-form-field class="btn-block car-input" floatPlaceholder="never" >
                    <mat-select placeholder="Request Origin" (change)="checkIfValid();" class="form-control input-lg custom-form-input" formControlName="request_origin">
                        <mat-option value="">--Select Origin--</mat-option>
                        <mat-option *ngFor="let opt of reqOrigin" value="{{opt.value}}">{{opt.label}}</mat-option>
                    </mat-select>  
                    <mat-error *ngIf="customerEdit.controls.request_origin.hasError('required')">
                        Request Origin is <strong>required</strong>
                    </mat-error>                    
                </mat-form-field>
            </div>
        </div>
      
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
    <button type="submit" mat-raised-button color="accent" title="Save" [disabled]="submitted"> SAVE</button>
    <button type="button" mat-raised-button mat-dialog-close class="font-bold" title="Close">Close</button>
</mat-dialog-actions>
</form>

<!-- END BODY -->