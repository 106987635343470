import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-job-paste-confirmation',
  templateUrl: './job-paste-confirmation.component.html',
  styleUrls: ['./job-paste-confirmation.component.scss']
})
export class JobPasteConfirmationComponent implements OnInit {
  public jobData: any
  public JobOneNameActive: any;
  public jobTwoName: any;
  public selectedJobData: any;
  public showErrMsg: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<JobPasteConfirmationComponent>,
  ) {
    this.jobData = data;
  }

  ngOnInit(): void {
    let jobName = "";
    if (this.jobData.targetJob.recom.children !== undefined && this.jobData.targetJob.recom.children.length > 0) {
      jobName = this.jobData.targetJob.recom.children[0].name;
    }
    this.JobOneNameActive = {
      level1JobName: this.jobData.jobName.level1JobName,
      level2JobName: this.jobData.jobName.level2JobName,
      level3JobName: this.jobData.jobName.level3JobName,
    }
    this.jobTwoName = this.jobData.pasteJob;
  }

  handleSelectJob(jobType: any) {
    if (jobType == "targetJob") {
      this.selectedJobData = this.jobData.targetJob.recom;
    } else {
      this.selectedJobData = this.jobData.pasteJob;
    }
  }

  submittedJob() {
    if (this.selectedJobData) {
      this.dialogRef["selectedJobs"] = this.selectedJobData;
      this.dialogRef.close(1);
    } else {
      this.showErrMsg = true;
    }
  }
}
