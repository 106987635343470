import { Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap,NavigationEnd, Params  } from '@angular/router';
import { Http,Headers, Response} from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { globalFunctions } from "../../../../environments/globalFunctions";
import { globalConstants } from "../../../../environments/globalConstants";
import {GlobalService} from "../../../../environments/global.service";
import { lang } from "../../../../environments/lang-en";
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TextMaskModule } from 'angular2-text-mask';
// import { MatSnackBar,MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ImagetooldialogComponent } from '../../../imagetooldialog/imagetooldialog.component';
import { CustomdatatableComponent, CustomdatatableSource } from '../../../customdatatable/customdatatable.component';
import { MatTableDataSource } from '@angular/material/table';

const VIEW_STOCK_ORDER_LIST = "shop-view-stock-orders-";
@Component({
  selector: 'app-view-stock-orders',
  templateUrl: './view-stock-orders.component.html',
  styleUrls: ['./view-stock-orders.component.scss']
})
export class ViewStockOrdersComponent extends CustomdatatableComponent implements OnInit {
  public stockOrderDetails;
  public idFromUrl;
  public order_items = [];
  displayedColumns = ['name','partstech_part_number','part_quantity','part_price','list_price','part_manufacturer','distributor_name'];
  public tablesData = this;
  public currentUser: any = {};
  url = "";
  public checkFormEmpty=false;
  public searchEnabled : boolean = false;
  public openViewStockOrderSearch = false;
    /* SEARCH BY ARRAY */
  searchByArray = [
      { id: 1, text: "Name"},
      { id: 2, text: "Number"},
      { id: 3, text: "Qty"},
      { id: 4, text: "Cost"},
      { id: 5, text: "Price"},
      { id: 6, text: "Manufacturer"},
      { id: 7, text: "Distributor Name"}
  ];
  /* SORT BY ARRAY */
  sortByArray = [
      { id: 'part_name', text: "Name"},
      { id: 'partstech_part_number', text: "Number"},
      { id: 'part_quantity', text: "Qty"},
      { id: 'part_price', text: "Cost"},
      { id: 'list_price', text: "Price"},
      { id: 'part_manufacturer', text: "Manufacturer"},
      { id: 'distributor_name', text: "Distributor Name"}
  ];
  
  /* SORT TYPE ARRAY */
  sortTypeArray = [
      { id: 'ASC', text: "Ascending"},
      { id: 'DESC', text: "Descending"}
  ]
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<StockData> = new MatTableDataSource();
  public record :any =[];
  public recordsTotal: any;
  constructor(private activatedRoute:ActivatedRoute,private formBuilder: FormBuilder,public titleService:Title,private http: Http,private router: Router,public globalService: GlobalService) {
    super(globalConstants.API_GET_STOCK_ORDER_DETAILS, globalService);
    this.currentUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (this.currentUser) {
      this.sessionKey = VIEW_STOCK_ORDER_LIST;
      this.url = globalConstants.API_GET_STOCK_ORDER_DETAILS;
    } else {
      this.router.navigate(["/shops"]);
    }
    this.stockOrderDetails = this.formBuilder.group({
      id:[""],
      order_id:[""],
      session_id:[""],
      add_date:[""],
      order_total:[""],
      modify_date:[""],
      sub_total:[""],
      tax_total:[""],
      delievery_total:[""],
      que_name:['']
     })
    this.setParams();
  }
  ngOnInit() {
    this.globalService.setMetaData("SHOPS","STOCK_ORDER_DETAILS");
    this.reinitializeOptions();
      if(this.checkFormEmptys()){
        this.openViewStockOrderSearch = true;
      }else{
        this.openViewStockOrderSearch = false;
      }
      this.isReady = true;
      this.setDataTable();
  }
  reinitializeOptions() {
      this.options.search_keyword = '';
      this.options.search_by = '';
      this.options = this.globalService.localGetterSetter(this.options, this.options, VIEW_STOCK_ORDER_LIST);
      this.options.user_type = this.currentUser.user_type;
      this.options.user_id = this.currentUser.id;
      this.options.stock_order_id = this.idFromUrl;
      if(this.options.search_by == null || this.options.search_by == "null"){
        this.options.search_by = '';
      }else{
        if(this.options.search_by != ''){
          this.options.search_by = Number(this.options.search_by);
        }
      }
    }
  
    checkFormEmptys() {
      if ((this.options.search_keyword == "" || this.options.search_keyword == null) && (this.options.search_by == "" || this.options.search_by == null || this.options.search_by == "null")) {
        this.checkFormEmpty=false;
        return false
      }else {
        this.checkFormEmpty=true;
        return true;
      }
    }

    ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
    }

    setDataTable() {
      // this.dataSource = new CustomdatatableSource(this.tablesData, this.sort, this.paginator);
      // this.checkFormEmptys();
      // this.dataSource.getSortedData = this.sortedData;
      var content = this.options;
      this.detailData={};
      this.resultData=[];
      this.isGetData=false
      this.callApi = this.gs.callAPI(this.url, content);
      this.callApi.subscribe((data) => {
        this.apiCaller.next(data);
        
        this.isGetData=true;
        if(this.returnData && data[this.returnData]){
          this.detailData=data[this.returnData];
        }
        let responseData = (this.sessionKey == VIEW_STOCK_ORDER_LIST) ? data.data.order_items : data.data;
        if (responseData != undefined) {
          let moreRows = 'false';
          let firstList = 'false';
          this.gs.localGetterSetter("totalRecords", data.recordsTotal, this.sessionKey);
          if (data.more_rows == "true") {
            moreRows = "true";
          }
          this.gs.localGetterSetter("more_rows", moreRows, this.sessionKey);
          if (data.start >= 0 && data.start <= 5) {
            firstList = "true";
          }
          this.gs.localGetterSetter("first_list", firstList, this.sessionKey);
          if (responseData.length != undefined) {
            this.recordsTotal = responseData.length;
            for (var i = 0; i < responseData.length; i++) {
              this.AddRecord(responseData[i])
            }
            this.dataSource = new MatTableDataSource(this.record)
            this.dataSource.sort = this.sort;
          } else {
            this.recordsTotal = "1";
            this.AddRecord(responseData);
            this.dataSource = new MatTableDataSource(this.record)
            this.dataSource.sort = this.sort;
          }
          this.gs.localGetterSetter(null, this.options, this.sessionKey);
        } else {
          this.dataSource = new MatTableDataSource(this.record)
          this.gs.localGetterSetter("totalRecords", '0', this.sessionKey);
        }
      },(error)=>{
        this.gs.localGetterSetter("totalRecords", '0', this.sessionKey);
      })
    }
  
    AddRecord(record){
      var data = this.createRecordrecord(record);
        this.record.push(data);
    }

  createRecordrecord(record) {
      return {
        id: record.id,
        name: record.part_name,
        partstech_part_number: record.partstech_part_number,
        part_quantity: record.part_quantity,
        part_price: record.part_price,
        list_price: record.list_price,
        part_manufacturer: record.part_manufacturer,
        distributor_name: record.distributor_name,
        modify_date : this.convertDate(record.modify_date)
      };
    }
  
    public convertDate(date){
      return this.globalService.getFullFormateDate(date,'G','H');
    }

    public clearSearch(){
      this.globalService.localGetterSetter(null,this.options,this.sessionKey);
      this.options.search_keyword = "" ;
      this.options.search_by = '';
      this.setDataTable();
      this.searchEnabled = false;
    }
    setParams(){
      this.activatedRoute.params.subscribe((params: Params) => {
        this.idFromUrl = params["id"];
        this.initFormGroup()
      });
    }
  initFormGroup() {
    let content = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&stock_order_id=" + this.idFromUrl;
      this.apiCaller.subscribe((response) => {
        if(Object.entries(response).length > 0){
          if(response.result == "1") {
            this.stockOrderDetails.controls.id.setValue(response.data.order.id)
            this.stockOrderDetails.controls.order_id.setValue(response.data.order.order_id)
            this.stockOrderDetails.controls.add_date.setValue(this.globalService.getFullFormateDate(response.data.order.add_date,"G","B"))
            this.stockOrderDetails.controls.modify_date.setValue(response.data.order.modify_date)
  
            this.stockOrderDetails.controls.order_total.setValue(response.data.order.order_total)
  
            this.stockOrderDetails.controls.sub_total.setValue(response.data.order.sub_total)
  
            this.stockOrderDetails.controls.tax_total.setValue(response.data.order.tax_total)
  
            this.stockOrderDetails.controls.delievery_total.setValue(response.data.order.delievery_total)
            this.stockOrderDetails.controls.que_name.setValue(response.data.order.que_name)
            this.order_items = response.data.order_items;
            // console.log(response.data.order_items)
          } else {
            this.globalService.snackbar("error",response.message);
          }
        }
      });
  }
}

export interface StockData {
  name: any;
  partstech_part_number: any;
  part_quantity:any;
  part_price: any;
  list_price:any;
  vehicle: any;
  part_manufacturer: any;
  distributor_name:any;
}