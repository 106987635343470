<div class="panel panel-primary margin-0" id="mainCompotents">
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">
          Overdue Balances Report
        </h3>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <div class="total-count-section">
            <div class="row">
                <div class="col-sm-3 link">
                  <mat-card class="card-default">
                      <div class="infoTile"  >
                          <strong [innerHTML]="timeScale1"></strong>
                          <p>0-30 days-Completed Invoices having payment due.</p>
                          <strong [innerHTML]="'$ '+decimalTwoDigit(timeScale1Balance)">$ {{decimalTwoDigit(timeScale1Balance)}}</strong>
                      </div>
                  </mat-card>
                </div>
                <div class="col-sm-3 link">
                  <mat-card class="card-default">
                      <div class="infoTile"  >
                          <strong [innerHTML]="timeScale2"></strong>
                          <p>31-60 days-Completed Invoices having payment due.</p>
                          <strong [innerHTML]="'$ '+decimalTwoDigit(timeScale2Balance)">$ {{decimalTwoDigit(timeScale2Balance)}}</strong>
                      </div>
                  </mat-card>
                </div>
                <div class="col-sm-3 link">
                    <mat-card class="card-default">
                        <div class="infoTile"  >
                            <strong [innerHTML]="timeScale3"></strong>
                            <p>61-90 days-Completed Invoices having payment due.</p>
                            <strong [innerHTML]="'$ '+decimalTwoDigit(timeScale3Balance)">$ {{decimalTwoDigit(timeScale3Balance)}}</strong>
                        </div>
                    </mat-card>
                </div>
                <div class="col-sm-3 link">
                  <mat-card class="card-default">
                      <div class="infoTile"  >
                          <strong [innerHTML]="timeScale4"></strong>
                          <p>90+ days-Completed Invoices having payment due.</p>
                          <strong [innerHTML]="'$ '+decimalTwoDigit(timeScale4Balance)">$ {{decimalTwoDigit(timeScale4Balance)}}</strong>
                      </div>
                  </mat-card>
                </div>
            </div>
        </div>
    </div>

    <div class="panel panel-primary">
      <div class="panel-heading relative">
        <h3 class="panel-title">Service Requests List</h3>
        <span *ngIf="isActive">
          <button type="button" name="btncsv" class="btn btn-success btn-download-csv"  title="Download CSV File" (click)="downloadCSV()">Download CSV File</button>
          </span>
          <span *ngIf="!isActive">
            <button type="button" name="btncsv" class="btn btn-success btn-download-csv disabled"  title="Download CSV File">Download CSV File</button>
          </span>
      </div>
      
      <div class="panel-body bgcolor-lightgrey">
        <div class="car-datatable mat-elevation-z1">
          <div class="text-right hidden">
            <mat-form-field floatPlaceholder="never" >
              <input matInput #filter placeholder="Filter users">
            </mat-form-field>
          </div>
          <div class="table-responsive relative">
            <mat-table #table [dataSource]="dataSource" matSort class="service-list datatable-loader-bg">

              <!-- Color Column -->
              <ng-container matColumnDef="request_id">
                <mat-header-cell *matHeaderCellDef mat-sort-header  data-role="id"> Req Id </mat-header-cell>
                <mat-cell *matCellDef="let row" style="color:#f50057;" data-role="id"> {{row.request_id}} </mat-cell>
              </ng-container>

              <!-- Color Column -->
              <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef mat-sort-header data-role="title"> Title </mat-header-cell>
                <mat-cell *matCellDef="let row" data-role="title"> {{row.title ? row.title : 'N/A'}} </mat-cell>
              </ng-container>

              <!-- Color Column -->
              <ng-container matColumnDef="customer_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header data-role="name"> Customer  Name </mat-header-cell>
                <mat-cell *matCellDef="let row" data-role="name"> {{row.customer_name}} </mat-cell>
              </ng-container>

              <!-- Color Column -->
              <ng-container matColumnDef="mobile_phone">
                <mat-header-cell *matHeaderCellDef mat-sort-header data-role="phone"> Mobile </mat-header-cell>
                <mat-cell *matCellDef="let row" data-role="phone">
                  <a class="text-black" href="tel:{{row.mobile_phone}}" *ngIf="row.mobile_phone"> {{row.mobile_phone}}</a>
                  <span *ngIf="!row.mobile_phone">N/A</span>
                </mat-cell>
              </ng-container>

              <!-- Color Column -->
              <ng-container matColumnDef="date_completed">
                <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Date Completed </mat-header-cell>
                <mat-cell *matCellDef="let row" data-role="auto">{{row.date_completed}} </mat-cell>
              </ng-container>

              <!-- Color Column -->
              <ng-container matColumnDef="total_invoice">
                <mat-header-cell *matHeaderCellDef mat-sort-header data-role="price"> Total Invoice </mat-header-cell>
                <mat-cell *matCellDef="let row" data-role="price">$ {{decimalTwoDigit(row.total_invoice)}} </mat-cell>
              </ng-container>

              <!-- Color Column -->
              <ng-container matColumnDef="balance_owed">
                <mat-header-cell *matHeaderCellDef mat-sort-header data-role="price"> Balance Owed </mat-header-cell>
                <mat-cell *matCellDef="let row"  data-role="price">$ {{decimalTwoDigit(row.balance_owed)}}</mat-cell>
              </ng-container>
              <!-- Color Column -->
              <ng-container matColumnDef="last_payment_date">
                <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Last Payment Date </mat-header-cell>
                <mat-cell *matCellDef="let row" data-role="auto">{{row.last_payment_date}} </mat-cell>
              </ng-container>

              <!-- Color Column -->
              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef  data-role="button"> Actions </mat-header-cell>
                <mat-cell  *matCellDef="let row" data-role="button">
                  <button *ngIf="isAllowed('views','payment') || isAllowed('views','service_request')" mat-button class="list-action-btn" color="accent" [matMenuTriggerFor]="menu" disableRipple >
                    Action<mat-icon>arrow_drop_down</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" class="car-icon-menu disable-no-bg" [overlapTrigger]="false">
                    <button mat-menu-item *ngIf="isAllowed('views','service_request')" (click)="viewServiceRequest(row.id)"> <mat-icon>remove_red_eye</mat-icon>View</button>
                    <button mat-menu-item *ngIf="isAllowed('views','payment')" (click)="paymentDialog(row.id)"> <i class="fa fa-usd"></i>Payments</button>
                  </mat-menu>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;" class="mat-row {{getRowStatusColor(row.status)}}"></mat-row>
            </mat-table>
            <div class="padding-15 text-center"
            [style.display]="getTotalRecords() < 1 ? '' : 'none'">
            No Records Available
          </div>
        </div>
        <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
          <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event)" >
          </mat-paginator>

                <div class="mat-paginator table-paging">
                    <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
                    <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                            <mat-icon>navigate_before</mat-icon>
                    </button>
                    <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                            <mat-icon>navigate_next</mat-icon>
                    </button>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>
