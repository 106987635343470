import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import {Http, Headers, Response} from '@angular/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
import { globalFunctions } from '../../../environments/globalFunctions';
import { GlobalService } from '../../../environments/global.service';
import { globalConstants } from '../../../environments/globalConstants';

@Component({
  selector: 'app-customer-car-licenseplate',
  templateUrl: './customer-car-licenseplate.component.html',
  styleUrls: ['./customer-car-licenseplate.component.scss']
})

export class CustomercarLicensePlateComponent {
    public licensePlateForm: FormGroup;
    public submitted: boolean = false;
    public licensePlateData;
    public customerCountryName;
    public currentCustomer;
    public shopState;
    public states: any = [];
    public defaultState;
    public statesArray=[''];
    constructor( @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<CustomercarLicensePlateComponent>,
        private formBuilder: FormBuilder,
        private globalService: GlobalService,
        private http: Http,
        private router: Router
    ) {
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInCustomer"));
        this.licensePlateForm = this.formBuilder.group({
            plate_number: ['', [Validators.required]],
            state: ['', [Validators.required]],
            stateName: ['',Validators.required]
        });
        this.globalService.diaLogObj(this.dialogRef);
    }

    ngOnInit() {
        this.getStates();
    }
    
    checkIfValid() {
        if (this.licensePlateForm.valid) {
            this.submitted = false;
        }
    }
    
    getLicensePlate() {
        Object.keys(this.licensePlateForm.controls).map((controlName) => {
            this.licensePlateForm.get(controlName).markAsTouched({onlySelf: true});
        });
        
        if (this.licensePlateForm.valid) {
            let sendOprs = "customer_id="+this.currentCustomer.id+"&plate="+this.licensePlateForm.value.plate_number.replace(/^\s+|\s+$/gm, '')+"&state="+this.licensePlateForm.value.state+"&state_name="+this.licensePlateForm.value.stateName+"&qq_opr=1";
            this.globalService.callAPI(globalConstants.API_GET_LICENSE_PLATE_DATA_URL, sendOprs).subscribe((data:any) => {
                var message = data.message;
                if(data.result == "1") {
                    this.licensePlateData = data.data;
                    localStorage.setItem('customerlicensePlateData', JSON.stringify(this.licensePlateData));
                    this.dialogRef.close(this.licensePlateData);
                    this.router.navigate(['/customer/add_cars']);
                } else {
                    if(data.code == "516" || data.code == "517"){
                        this.globalService.confirmDialog(311, "The Licence Plate trying is already owned by another user. Do you want to send him a request to transfer its ownership to you?").subscribe((d) => {
                            if (d != null) {
                                let vin = data.vehicles.vin;
                                localStorage.setItem("CustomerVinNumber", vin);
                                localStorage.setItem("CustomerlicenceNumber", this.licensePlateForm.value.plate_number);
                                localStorage.setItem("CustomerStateName", this.licensePlateForm.value.stateName);
                                localStorage.setItem("CustomerVinExists", "2");
                                this.licensePlateData = {vin_number: vin, license_plate: this.licensePlateForm.value.plate_number, stateName: this.licensePlateForm.value.stateName};
                                this.dialogRef.close({currentVinDetails: this.licensePlateData, status:0});
                                this.router.navigate(['/customer/add_cars']);
                            }
                        })
                    }else{
                        this.globalService.snackbar("error", message);
                    }
                }
                
            });
            
        } else{ 
            this.submitted = true;
        }
    }
    
    cancelLicensePlateModel() {
        Object.keys(this.licensePlateForm.controls).map((controlName) => {
            this.licensePlateForm.get(controlName).reset();
        });
        this.submitted = false;
    }

    getStates(){
        var statesOpr = "customer_id=" + this.currentCustomer.id + "&opr=1";
            this.globalService.callAPI(globalConstants.API_GET_STATES_BY_CUSTOMER_ID, statesOpr).subscribe((data:any) => {
            this.states = data.data;
            var slist=[];
            for(var i in this.states){
                this.states[i].id=this.states[i].state_code;
                this.states[i].text=this.states[i].state_name;
                slist.push(this.states[i]);
                this.statesArray[this.states[i].state_id]=(this.states[i].state_name);
            }
            this.states=slist;
            const carState = this.states.filter(state => state.state_name ==  localStorage.getItem('customerState'));
            if(carState.length > 0) {
                this.defaultState = carState[0].state_code;
                this.licensePlateForm.patchValue({state : this.defaultState});
                this.licensePlateForm.patchValue({stateName : carState[0].state_name});
            } else {
                this.defaultState = '';
                this.licensePlateForm.controls.state.setValue('');
                this.licensePlateForm.controls.stateName.setValue('');
            }
        })
    }

     /* STATES LIST IN SEARCH BOX DROP DOWN */
     public getSelectedOptionState(opt) {
        const carState = this.states.filter(state => state.state_code ==  opt);
        if(carState.length > 0) {
            this.defaultState = carState[0].state_code;
            this.licensePlateForm.patchValue({state : this.defaultState});
            this.licensePlateForm.patchValue({stateName : carState[0].state_name});
        } else {
            this.licensePlateForm.controls.state.setValue('');
            this.licensePlateForm.controls.stateName.setValue('');
        }
        this.checkIfValid();
    }

}
