<div id="mainCompotents">
    <div class="main-content" id="dashboard-content">
        <div class="shopaddnewpart">
            <div class="panel panel-primary" id="shopaddnewpartPanel">
                <div class="panel-heading padding-left-15">
                    <h3 class="panel-title">Add New Part</h3>
                </div>
                <div class="panel-body bgcolor-lightgrey">
                    <!-- Add New Part page start -->
                    <div id="addNewPart" class="row  form-content">
                        <div class="container-fluid">
                            <form [formGroup]="shopAddPart" novalidate (ngSubmit)="AddPart(1)" id="addPartForm" class="form-inline col-sm-12" role="form" method="POST"
                                enctype="multipart/form-data">
                                <div class="col-xs-12 form-block add-new-part">
                                    <div class="row">
                                        <div class="form-group col-md-6 col-sm-12">
                                            <label for="part_name">Part Name</label>
                                            <mat-checkbox  class="v-checkbox in-confirm" formControlName="keep_part_name" matTooltip="Keep Part Name" tooltipPlacement="top" class="tooltip-status"></mat-checkbox>
                                            <div class="relative">
                                                <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                                    <input matInput placeholder="Part Name" title="Part Name" formControlName="part_name" maxlength="200"  class=" input-lg custom-form-input"  required />
                                                    <mat-error *ngIf="shopAddPart.controls.part_name.hasError('required')">
                                                        Part Name is required
                                                </mat-error>
                                            </mat-form-field>
    
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6 col-sm-12 part-number">
                                        <label for="part_number">Part Number</label>
                                        <mat-checkbox  class="v-checkbox in-confirm" matTooltip="Keep Part Number" tooltipPlacement="top" formControlName="keep_part_no" class="tooltip-status"></mat-checkbox>
                                        <div class="relative">
                                                <input class="car-input input-lg custom-form-input" maxlength="200" matInput ngui-auto-complete (valueChanged)="partNumber($event)" [source]="observableSourcePN"
                                                [list-formatter]="myListFormatter" placeholder="Part Number" formControlName="part_number" (click)="setReturnFalse()"  id="new-part-no" class="form-control" />
                                            <br/><br/>
                                            <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper" *ngIf="shopAddPart.controls.part_number.hasError('required')">
                                                <mat-error>Part Number is required</mat-error>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6 col-sm-12">
                                        <label for="part_number">Part Cost</label>
                                        <div class="relative">
                                            <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                                <input matInput placeholder="Part Cost" title="Part Cost" formControlName="cost" value="" class=" input-lg custom-form-input" maxlength="10" (keydown)="disableKey($event);" (keypress)="onlyDecimalNumberKey($event);convertIntoDecimal($event,'cost',7)" required />
                                            <mat-error *ngIf="shopAddPart.controls.cost.hasError('required') ">
                                                Part Cost is required
                                            </mat-error>
                                            <mat-error *ngIf="shopAddPart.controls.cost.hasError('pattern')">
                                               Must be a number with upto 2 decimal places.
                                            </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-group col-md-6 col-sm-12">
                                <label for="part_number">Retail Price</label>
                                <div class="relative">
                                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                        <input matInput placeholder="Retail Price" title="Retail Price" formControlName="price" value="" class=" input-lg custom-form-input"  maxlength="10" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event);convertIntoDecimal($event,'price',7)" 	required/>
                                        <mat-error *ngIf="shopAddPart.controls.price.hasError('required')">
                                            Retail price is required
                                        </mat-error>
                                        <mat-error *ngIf="shopAddPart.controls.price.hasError('pattern')">
                                            Must be a number with upto 2 decimal places.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6 col-sm-12">
                            <label for="vendor">Vendor  </label>
                            <mat-checkbox  class="v-checkbox in-confirm" formControlName="keep_vendor" matTooltip="Keep Vendor" mat-tooltip-panel-above class="tooltip-status"></mat-checkbox>
                            <div class="relative">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <input matInput placeholder="Vendor" title="Vendor" formControlName="vendor" maxlength="200" class=" input-lg custom-form-input"
                                           />
                                </mat-form-field>                          
                            </div>
                        </div>
                        <div class="form-group col-md-6 col-sm-12">
                            <label for="vendor">Manufacturer  </label>
                            <mat-checkbox  class="v-checkbox in-confirm" formControlName="keep_manufacturer" matTooltip="Keep Manufacturer" mat-tooltip-panel-above class="tooltip-status"></mat-checkbox>
                            <div class="relative">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <input matInput placeholder="Manufacturer" title="Manufacturer" formControlName="manufacturer" maxlength="200" class=" input-lg custom-form-input"
                                           />
                                </mat-form-field>                         
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="isInventory">
                        <div class="form-group col-md-6 col-sm-12">
                            <label for="part_qty">Part Quantity</label>
                            <div class="relative">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <input matInput maxlength="7" placeholder="Part Quantity" title="Part Quantity" formControlName="part_qty" class="input-lg custom-form-input" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event);convertIntoDecimal($event,'part_qty',4)" required />
                                    <mat-error *ngIf="shopAddPart.controls.part_qty.hasError('required')">
                                        Part Quantity is required
                                    </mat-error>
                                    <mat-error *ngIf="shopAddPart.controls.part_qty.hasError('pattern')">
                                        Must be a number with upto 2 decimal places.
                                </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-group col-md-6 col-sm-12">
                            <label for="low_stock_qty">Low Stock Quantity</label>
                            <div class="relative">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <input matInput maxlength="7" placeholder="{{lowStockQty}}" title="Low Stock Quantity" formControlName="low_stock_qty" class="input-lg custom-form-input" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event);convertIntoDecimal($event,'low_stock_qty',4)" required />
                                    <mat-error *ngIf="shopAddPart.controls.low_stock_qty.hasError('required')">
                                        Low Stock Quantity is required
                                    </mat-error>
                                    <mat-error *ngIf="shopAddPart.controls.low_stock_qty.hasError('pattern')">
                                        Must be a number with upto 2 decimal places.
                                </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-12">
                            <label for="oem_part_number">OEM Part Number</label>
                            <mat-checkbox  class="v-checkbox in-confirm" formControlName="keep_oem_part_no" matTooltip="Keep OEM Part Number"  class="tooltip-status"></mat-checkbox>
                            <div class="relative">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <input matInput placeholder="OEM Part Number" title="OEM Part Number" formControlName="oem_part_number" maxlength="200"  class=" input-lg custom-form-input"
                                           />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row" >
                            <div class="form-group col-sm-6  uc-ngx-select">
                                <label for="oem_part_number">Tags</label>
                                <div class="relative">
                                     <ngx-select [autoClearSearch]="true" class="display-full margin-bottom-20 tags-select" formControlName="part_tags" [multiple]="true" [items]="allTags" (select)="selectedTags($event)" (remove)="selectedTags($event)" optionTextField="tag_name" placeholder="Select Tags"></ngx-select>
                                </div>
                            </div>
                            <div class="form-group col-sm-6">
                                    <label for="oem_part_number" class="hidden-xs">&nbsp;</label>
                                 <div class="">
                                    <button type="button" mat-raised-button class="car-btn btn-block text-uppercase font-light fontcolor-white manage-tags-btn" id="manageTags" color="accent" title="Manage Tags" (click)="manageTag()">Manage Tags</button>
                                 </div>
                            </div>
                        </div>
                    <div class="row">
                        <div class="form-group col-sm-12">
                            <label for="description">Description</label>
                            <div class="relative">
                                <mat-form-field class="car-input btn-block notes-input" floatPlaceholder="never" appearance="fill">
                                    <textarea matInput  placeholder="Description" title="Description" minHeight="200"  formControlName="description" maxlength="1000"  class=" input-lg custom-form-input">
                                    </textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-group col-sm-12">
                            <div class="col-sm-12" style="padding:0px;">
                                <label for="partPic">Part Picture</label>
                            </div>
    
                            <div class="col-sm-12 file-upload-container" style="padding:0px;">
                                <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative">
                                    <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                                    Drag File Here
                                    <input type="file" id="partPic" name="part_pic" accept="image/*" (change)="getFile($event,'part_pic')" formControlname="part_picture"/>
                                    <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                                </mat-toolbar>
                                <input id="partPicImagedata" name="part_pic" value="" type="hidden" formControlName="part_picture">
                            </div>
                            <div class="col-sm-12" style="padding:0px;">
                                <ul class="list-inline upload-image margin-bottom-0">
                                    <li *ngIf="currentPartPicture!=''">
                                        <a href="javascript:void(0);" class="img-set thumbnail relative" (click)="openImageDialog(10);">
                                            <img class="show-image" src="{{currentPartPicture}}" />
                                            <i class="fa fa-trash remove-img" (click)="deletePartPic()"></i>
                                        </a>
                                    </li>
                                </ul>                            
                                <p class="help-block" style="margin:0px;">Formats supported : png, jpeg, jpg</p>
                                <p class="help-block" style="margin:0px;">Maximum size : 10MB</p>
                            </div>
                        </div>
    
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-12">
                            <mat-checkbox class="btn-block" labelPosition="after" (change)="showTaxExempted($event)" formControlName="non_taxable" >
                                Non Taxable
                            </mat-checkbox>
                            <p class="help-block"><strong>Note: </strong>If checked then this part price would not be included in tax calculations.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 form-block submit-block">
                            <button type="button" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Submit & Close" style="max-width: 300px;"  (click)="setButtonStatusAndSubmit(1)">
                                SUBMIT &amp; CLOSE
                            </button>
                            <button type="button" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Submit & Add Another" style="max-width: 300px;"  (click)="setButtonStatusAndSubmit(2)">
                                SUBMIT &amp; ADD ANOTHER
                            </button>
                        </div>
                    </div>
                </div>
               
            </form>
        </div>
    </div>
    <!-- Add new part end -->
    </div>
    </div>
    </div>
    </div>
    </div>
    