<div class="panel panel-primary" id="mainCompotents">
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">SERVICE MESSAGE DETAIL</h3>
        <button mat-mini-fab class="custom-btn-back" title="Back" (click)="globalService.goBack();">
            <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
    <div class="panel-body bgcolor-lightgrey" *ngIf="showServiceDetail">
        <div class="jumbotron inspection-report padding-25 car-view-str margin-bottom-15">
            <h3 class="margin-top-0">Service Message Information</h3>
            <hr>
            <div class="row">
                <div class="col-sm-6 margin-bottom-5">
                    <div class="row">
                        <div class="col-sm-6">
                            <label class="control-label">ID:</label>
                        </div>
                        <div class="col-sm-6">
                            <p class="pull-left">{{serviceInfo.request_id}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6  margin-bottom-5">
                    <div class="row">
                        <div class="col-sm-6"><label class="control-label">Request ID:</label></div>
                        <div class="col-sm-6">
                            <p>
                                <a class="hover-link text-black" title="View Service Request" href="javascript:void(0)" [routerLink]="['/shops/view-service-request/'+serviceInfo.request_id]">{{serviceInfo.ticket_no}}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6  margin-bottom-5">
                    <div class="row">
                        <div class="col-sm-6"><label class="control-label">Vehicle:</label></div>
                        <div class="col-sm-6"><p data-original-title="" title="">{{serviceInfo.vehicleName}}</p></div>
                    </div>
                </div>
                <div class="col-sm-6  margin-bottom-5">
                    <div class="row">
                        <div class="col-sm-6"><label class="control-label">Customer Name :</label></div>
                        <div class="col-sm-6"><p style="text-transform:uppercase;">{{serviceInfo.first_name}} {{serviceInfo.last_name}}</p></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6  margin-bottom-5">
                    <div class="row">
                        <div class="col-sm-6"><label class="control-label"> Date</label></div>
                        <div class="col-sm-6"><p class="form-control-static" id="title">{{getProperDate(serviceInfo.add_date)}}</p></div>
                    </div>
                </div>
                <div class="col-sm-6  margin-bottom-5">
                    <div class="row">
                        <div class="col-sm-6"><label class="control-label">Mobile Number :</label></div>
                        <div class="col-sm-6"><p style="text-transform:uppercase; max-width:200px; word-wrap:break-word;">
                          <a class="text-black" href="tel:{{serviceInfo.mobile_phone}}">{{serviceInfo.mobile_phone}}</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6  margin-bottom-5">
                    <div class="row">
                        <div class="col-sm-6"><label class="control-label">Status :</label></div>
                        <div class="col-sm-6">
                            <mat-chip-list class="mat-badge">
                                <mat-chip [color]="getStatus(serviceInfo.status) == 'Closed' ? 'warn' : getStatus(serviceInfo.status) == 'Created' ? 'accent' : 'primary'" selected="true">{{getStatus(serviceInfo.status)}}</mat-chip>
                            </mat-chip-list>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6  margin-bottom-5">
                    <div class="row">

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6  margin-bottom-5">
                    <div class="row">
                        <div class="col-sm-6"><label class="control-label">Title :</label></div>
                        <div class="col-sm-6"><p>{{serviceInfo.title}}</p></div>
                    </div>
                </div>
                <div class="col-sm-6  margin-bottom-5">
                    <div class="row">

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12  margin-bottom-5">
                    <div class="row">
                        <div class="col-sm-3"><label class="control-label" style="text-align:left;">Description :</label></div>
                        <div class="col-sm-9"><p>{{serviceInfo.description}}</p></div>
                    </div>
                </div>
                <div class="col-sm-6  margin-bottom-5">
                    <div class="row">
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="serviceInfo.complaint_pic">
                <div class="col-sm-6  margin-bottom-5">
                    <div class="row">
                        <div class="col-sm-6"><label class="control-label" style="text-align:left;">Vehicle Picture :</label></div>
                        <div class="col-sm-6">
                        <div class="view-images">
                            <a href="javascript:void(0);" (click)="imageToolDialog($event,0,7,serviceInfo);$event.stopPropagation();" class="thumbnail">
                                <img src="{{complaintImgThumbUrl}}{{serviceInfo.complaint_pic}}">
                            </a>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6  margin-bottom-5">
                    <div class="row">

                    </div>
                </div>
            </div>
        </div>
        <div class="panel-heading expansion-pretend mat-elevation-z2 padding-0 margin-bottom-10">
            <div class="panel-title padding-10">COMMENTS SECTION</div>
            <span *ngIf="serviceInfoComments.length==0" class="display-full padding-15">No Comment Available</span>
        </div>
        <div class="display-full" *ngIf="serviceInfoComments.length > 0">
            <mat-accordion class="new-request-expension display-full" displayMode="flat" hideToggle="false" *ngFor="let serviceInfoComment of serviceInfoComments;">
                           <mat-expansion-panel hideToggle="false" expanded="true" class="break-word">
                    <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px">
                        <ul class="list-inline display-full margin-bottom-0">
                            <li>Added By: {{serviceInfoComment.first_name}}</li>
                            <li class="pull-right">Date: {{getProperDate(serviceInfoComment.add_date)}}</li>
                        </ul>
                    </mat-expansion-panel-header>
                    <span [innerHTML]="serviceInfoComment.description"></span>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <form [formGroup]="shopAddComment" (ngSubmit)="addComment()" novalidate id="addPartForm" class="form-inline" role="form" method="POST" *ngIf="serviceInfoStatus != 3">
            <div class="row">
                <div class="form-group col-sm-12">
                    <label for="description">Add Comment</label>
                    <div class="relative details-input">
                        <mat-form-field class="car-input btn-block notes-input" floatPlaceholder="never" appearance="fill">
                            <textarea matInput  placeholder="Write Your Comment Here" title="Write Your Comment Here" rows="5" maxlength="1000" minHeight="200"  formControlName="complaint_comment"  class=" input-lg custom-form-input"></textarea>
                            <mat-error *ngIf="shopAddComment.controls.complaint_comment.hasError('required')">
                                Please add your <strong>Comment</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-12">
                    <mat-checkbox class="btn-block" labelPosition="after" (change)="showTaxExempted($event)" formControlName="complaint_resolver" >
                        Mark Resolved
                    </mat-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="display-full margin-top-5">
                    <div class="col-sm-6 col-lg-4">
                        <button type="button" mat-raised-button class="car-btn btn-block text-uppercase  btn-lg font-light fontcolor-white" id="saveClose" color="accent" title="Add Comment"  (click)="addComment()">ADD COMMENT</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
