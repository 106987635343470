import {Component, OnInit, ElementRef, ViewEncapsulation, OnDestroy } from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd, Params} from '@angular/router';
import {globalConstants as GC} from '../../../environments/globalConstants';
import {GlobalService as GS} from '../../../environments/global.service';
import {globalFunctions} from '../../../environments/globalFunctions';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-shop-customer-feedback',
  templateUrl: './shop-customer-feedback.component.html',
  styleUrls: ['./shop-customer-feedback.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShopCustomerFeedbackComponent implements OnInit, OnDestroy {
    public feedbackQuestion: any;
    public requestId: string;
    private feedbackCode: string;
    public questionInfo: any = {};
    private currentCustomer: any = {};
    private customerId: string;
    private userType: string;
    public subscriptionDataForAPI: Subscription = new Subscription();

    constructor(public gs: GS, private formBuilder: FormBuilder, private element: ElementRef, private activatedRoute: ActivatedRoute) {
        this.currentCustomer = JSON.parse(localStorage.getItem('loggedInUser'));
        this.customerId = this.currentCustomer['id'];
        this.userType = 'User';
    }

    ngOnInit() {
        this.gs.setMetaData("SHOPS","RATINGS")
        this.getParams();
        let params: string = '';
        params = 'user_id=' + this.customerId + '&user_type=' + this.userType + '&request_id=' + this.requestId + '&read_status=0';
        this.subscriptionDataForAPI = this.gs.callAPI(GC.API_GET_FEEDBACK_QUESTIONS_URL, params).subscribe((data:any) => {
            if (data.result == 1) {
                this.feedbackQuestion = data.data;
                // console.log(this.feedbackQuestion);
                for (let i = 0; i < this.feedbackQuestion.length; i++) {
                    let obj: any = {};
                    this.questionInfo[this.feedbackQuestion[i].id] = {};
                    if (i === 0) {
                        // console.log(this.feedbackQuestion[0].comment);
                        this.questionInfo.feedback_comment = this.feedbackQuestion[i].comment;
                    }
                    obj.title = this.feedbackQuestion[i].rating;
                    this.questionInfo[this.feedbackQuestion[i].id] = obj;
                }
                // console.log(this.questionInfo);
            }else {
                this.gs.snackbar('error', data.message);
            }
        });
    }
    getParams() {
        this.activatedRoute.params.subscribe((params: Params) => {
            this.requestId = (params['request_id']);
        });
    }

    ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
      }
}
