<div class="display-full relative ">
  <mat-expansion-panel hideToggle="false" class="search-filter search-employee relative" [expanded]="false">
    <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="40px">
      <strong>Customers</strong>
    </mat-expansion-panel-header>
    <ng-container id="advanceSearchForm" class="collapse in" aria-expanded="true">
      <div class="customer-list bs-table">
        <table class="table table-striped ">
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr class="no-data-wrapper" *ngIf="customerList.length === 0">
              <td colspan="4" class="no-data-found  text-center">No Records Available</td>
            </tr>
            <tr *ngFor="let customer of customerList;let i = index">
              <td>{{customer.customer_fullname}}</td>
              <td style="white-space: nowrap;">{{customer.email}}</td>
              <td style="white-space: nowrap;">{{customer.mobile_phone}}</td>
              <td class="btn-icon-block">
                <button class="btns btn-danger btn-icons" title="Delete" type="button" (click)="removeCustomer(i)">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </mat-expansion-panel>
  <button mat-raised-button color="accent" class="add-btn" type="submit" title="Add" (click)="sendOutput(2)"><i
    class="fa fa-plus fa-lg margin-right-5"></i></button>
</div>