import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GlobalService } from '../../../../environments/global.service';
import { Observable } from 'rxjs';
import { globalConstants } from '../../../../environments/globalConstants';
import { Http } from '@angular/http';
import { globalFunctions } from '../../../../environments/globalFunctions';
import { VideoShowComponent } from '../../../customer/my-vehicles/video-show/video-show.component';
import { MatDialog } from '@angular/material/dialog';
import { addNewParts } from '../../shopservicerequest/fill-recommendation/fill-recommendation.component';
import { EditpartPopupComponent } from '../../shopeditpart/editpart-popup/editpart-popup.component';
declare var jQuery:any;
declare var $ :any;
@Component({
  selector: 'app-qq-recomendation',
  templateUrl: './qq-recomendation.component.html',
  styleUrls: ['./qq-recomendation.component.scss']
})
export class QqRecomendationComponent implements OnInit {

  @Input() customer: any;
  @Input() seletedCar: any;
  @Input() allJobs: any;
  @Input() recomendations:any;
  @Output() onSave = new EventEmitter<any>();
  public user: any = null
  public selectedParts: any = {}
  public currentPart: any = {}
  public currentGenericJob: any = {}
  public selectedGenericJob: any = {}
  public times:any={}
  public labors:any={}
  public notes:any={}
  public showCostPrice=false;
  public laborRate:any=0
  public grand_total:any=0
  public total_paid:any=0
  public partMatrixStatus=false;
  public showPartsTechButon=false;
  public isSubscribed=true;
  public partechSession={};
  public partstechLoadCount=1;
  public currentEdit={}
  public currentEditIndex={}
  constructor(public gs: GlobalService, public http: Http, public dialog: MatDialog) {
    this.user = this.gs.getCurrentUser()
  }
  /**
   * @trackID <:CAR-1339>Partstech Permission Check
   * @updated 2020-01-24
   * @updatedBY Vikas Kumar
   * @param
   * @return
   */
  ngOnInit() {
    this.showCostPrice=this.gs.getAcl('display_part_cost','views');  
    this.laborRate=(this.recomendations ? this.recomendations.labor_rate :0);
    this.petchAllRecommendation();
    this.isSubscribed=this.gs.isPartstechAllowed();
    if (this.recomendations) {
      this.partMatrixStatus=(this.recomendations.part_matrix_status==1 ? true : false);
      this.total_paid = (this.recomendations.total_paid!=undefined ? this.recomendations.total_paid : 0.00);
    }
    let partsTechOrder=this.gs.getAcl('partstech_order','addNew');
    if(this.recomendations.is_partstech_user=="1" && partsTechOrder && this.isSubscribed){
      this.showPartsTechButon=true;
    }
    
  }
  setReturnFalse() {
    return false;
  }
  observableSource = (keyword: any): Observable<any[]> => {
    let url: string = globalConstants.API_GET_PARTS_DATA_URL + "?user_id=" + this.user.id + "&query=" + keyword;
    if(this.showCostPrice){
      url+='&get_cost=1';
    }
    if (keyword && keyword.length>=3) {
      let f = [];
      //by ankur
      return this.gs.getAPI(url).map((res:any)=>{
          let json = res;
          this.gs.authText(json);
          if (json != null) {
            json.forEach(function (x) {
              x.value = x.label;
              x.quantity = 1
              x.inventory_price = x.price
              f.push(x)
            }
            );
          }
          return f
        })
    } else {
      return Observable.of([]);
    }
  }

  onlyNumericKey(event) {
    return globalFunctions.onlyDecimalNumberKey(event);
  }
  autoCompleListParts(data: any) {
    return data['label'];
  }
  /** selected new part from suggestion list */
  getSelectedOption(opt, job) {
    if (typeof opt == "object") {
      let p = (opt.part_matrix_status ? opt.part_matrix_price : opt.price);
      (<HTMLInputElement>document.getElementById("new-part-" + job.id + "-price")).value = p;
      (<HTMLInputElement>document.getElementById("new-part-" + job.id + "-qty")).value = opt.quantity;
      if (typeof this.currentPart[job.id] == 'undefined') {
        this.currentPart[job.id] = {}
      }
      this.currentPart[job.id] = opt
    }
  }
  /** add new part */
  addNewPart($e, job) {
    let found:any=null;
    let updatedQty = (<HTMLInputElement>document.getElementById("new-part-" + job.id + "-qty")).value;
    let updatedPrice = (<HTMLInputElement>document.getElementById("new-part-" + job.id + "-price")).value;
    updatedPrice = parseFloat(updatedPrice).toFixed(2);
    if (typeof this.selectedParts[job.id] == 'undefined') {
      this.selectedParts[job.id] = []
    }
    if (typeof this.currentPart[job.id] != 'undefined' && this.currentPart[job.id].id) {
      
      let prtId=this.currentPart[job.id].id
      found=this.selectedParts[job.id].filter((r)=>{ return r.id==prtId })
      if(found && found.length){
        this.gs.snackbar('error', "Already added");
      }else{
        this.currentPart[job.id].price = updatedPrice
        this.currentPart[job.id].quantity = updatedQty
        this.selectedParts[job.id].push(this.currentPart[job.id])
        this.calTotal()
      }
      
    }else{
      this.gs.snackbar('error', "Please Enter Part");
    }
    (<HTMLInputElement>document.getElementById("new-part-" + job.id + "-ac")).value = "";
    (<HTMLInputElement>document.getElementById("new-part-" + job.id + "-qty")).value = "";
    (<HTMLInputElement>document.getElementById("new-part-" + job.id + "-price")).value = "";
    this.currentPart[job.id] = {}
  }
  gnTipId(part){
    return  (typeof part.inventory_price!='undefined' ?' Retail:'+part.inventory_price:'') +(typeof part.part_matrix_price!='undefined' && this.partMatrixStatus ?' Matrix:'+part.part_matrix_price:'')+(typeof part.partstech_price!='undefined' && part.partstech_cart_item==1?' PartsTech:'+part.partstech_price:'')
   }
  observableGenericJob = (keyword: any): Observable<any[]> => {
    let url: string = globalConstants.API_GET_GENERIC_JOBS_URL + "?from_v2=1&user_id=" + this.user.id + "&query=" + keyword;
    if (keyword || 1) {
      let f = [];
      return this.gs.getAPI(url)
        .map(res => {
          let json = res;
          let show=this.showCostPrice;
          this.gs.authText(json);
          if (json != null) {
            json.forEach(function (x) {
              x.value = x.label;
              x.name = x.label;
              x.label+=(show?' '+x.price:'');
              x.quantity = 1;
              // x.non_taxable = 1;
              f.push(x)
            }
            );
          }
          return f
        })
    } else {
      return Observable.of([]);
    }
  }
  getSelectedGenericJob(opt, job) {
    if (typeof opt == "object") {
      (<HTMLInputElement>document.getElementById("new-gj-" + job.id + "-qty")).value = opt.quantity;
      if (typeof this.currentGenericJob[job.id] == 'undefined') {
        this.currentGenericJob[job.id] = {}
      }
      this.currentGenericJob[job.id] = opt
    }
  }

  addNewGJ(job) {
    let found:any=null;
    let updatedQty = (<HTMLInputElement>document.getElementById("new-gj-" + job.id + "-qty")).value;
    if (typeof this.selectedGenericJob[job.id] == 'undefined') {
      this.selectedGenericJob[job.id] = []
    }
    if (typeof this.currentGenericJob[job.id] != 'undefined' && this.currentGenericJob[job.id].id) {
      let gjobId=this.currentGenericJob[job.id].id
      found=this.selectedGenericJob[job.id].filter((r)=>{ return r.id==gjobId })
      if(found && found.length){
        this.gs.snackbar('error', "Already added");
      }else{
        this.currentGenericJob[job.id].quantity = updatedQty
        this.selectedGenericJob[job.id].push(this.currentGenericJob[job.id])
        this.calTotal()
      }
    }else{
      this.gs.snackbar('error', "Please Enter Generic Job");
    }
    (<HTMLInputElement>document.getElementById("new-gj-" + job.id + "-ac")).value = "";
    (<HTMLInputElement>document.getElementById("new-gj-" + job.id + "-qty")).value = "";
    this.currentGenericJob[job.id] = {}
  }
  /**
   * @trackID <:CAR-1354>
   * @updated 2020-02-07
   * @updatedBY Vikas Kumar
   * @param
   * @return
   */
  timeChange(job){
    let time=parseFloat(this.times[job.id])
    let t=isNaN(time);
    this.labors[job.id]=(t ? 0 :(time*parseFloat(this.laborRate)).toFixed(2));
    this.calTotal()
  }
  /**
   * @trackID <:CAR-1354>
   * @updated 2020-02-07
   * @updatedBY Vikas Kumar
   * @param
   * @return
   */
  laborChange(job){
    let lo=parseFloat(this.labors[job.id])
    let l=isNaN(lo);
    let lr=parseFloat(this.laborRate);
    this.times[job.id]=(l ? 0 : (lo/lr).toFixed(2));
    this.calTotal()
  }
  saveToParent() {
    let GJ=this.selectedGenericJob
    let part=this.selectedParts
    let time=this.times
    let labor=this.labors
    let note=this.notes
    let levels:any={}
    for(let i in GJ){
      if(typeof levels[i]=='undefined'){
        levels[i]=this.setDefaultStruct()
      }
      levels[i].genericJob=GJ[i]
    }
    for(let i in part){
      if(typeof levels[i]=='undefined'){
        levels[i]=this.setDefaultStruct()
      }
      levels[i].parts=part[i]
    }
    for(let i in time){
      if(typeof levels[i]=='undefined'){
        levels[i]=this.setDefaultStruct()
      }
      levels[i].time=time[i]
    }
    for(let i in labor){
      if(typeof levels[i]=='undefined'){
        levels[i]=this.setDefaultStruct()
      }
      levels[i].labor=labor[i]
    }
    for(let i in note){
      if(typeof levels[i]=='undefined'){
        levels[i]=this.setDefaultStruct()
      }
      levels[i].note=note[i]
    }
    return levels;
  }
  setDefaultStruct(){
    return {"genericJob":[],"parts":[],"time":0,"labor":0,"note":""}
  }
  saveRecommendation(type){
    let data=this.saveToParent()
    this.onSave.emit( {"recom": JSON.stringify(data),"type":type} );
  }
  petchAllRecommendation(){
    let data=this.allJobs
    data.forEach((r)=>{
       if(r.children && r.children.length){
        for(let i in r.children){
           if(typeof r.children[i].recommendation!='undefined' && r.children[i].recommendation && r.children[i].recommendation.id){
            this.seperateJob(r.children[i].recommendation)
           }else if(typeof r.children[i].children!='undefined' && r.children[i].children.length){
            this.findChild(r.children[i].children)
           }
        }
       }else if(typeof r.recommendation!='undefined' && r.recommendation && r.recommendation.id){
        this.seperateJob(r.recommendation)
       }
    })
    this.calTotal()
  }
  findChild(job){
    job.forEach((r)=>{
       if(r.children && r.children.length){
        for(let i in r.children){
           if(typeof r.children[i].recommendation!='undefined' && r.children[i].recommendation && r.children[i].recommendation.id){
            this.seperateJob(r.children[i].recommendation)
           }else if(typeof r.children[i].children!='undefined' && r.children[i].children.length){
            this.findChild(r.children[i].children)
           }
        }
       }else if(typeof r.recommendation!='undefined' && r.recommendation && r.recommendation.id){
        this.seperateJob(r.recommendation)
       }
    })
  }

  seperateJob(job){
    // console.log(job)
    let priceShow=this.showCostPrice
    if(job.parts.length){
      job.parts.forEach((e)=>{
          e.label=e.part_number+(priceShow? ' ($'+e.cost+') ':' ')+e.name
          e.price=e.part_price
      })
    }

    if(job.generic_jobs.length){
      job.generic_jobs.forEach((e)=>{
          e.label=e.name
      })
    }
    if (typeof this.selectedParts[job.service_list_id] == 'undefined') {
      this.selectedParts[job.service_list_id] = []
      this.selectedParts[job.service_list_id] = job.parts
    }
    if (typeof this.selectedGenericJob[job.service_list_id] == 'undefined') {
      this.selectedGenericJob[job.service_list_id] = []
      this.selectedGenericJob[job.service_list_id] = job.generic_jobs
    }
    if (typeof this.labors[job.service_list_id] == 'undefined') {
      this.labors[job.service_list_id] = ''
      this.labors[job.service_list_id] = job.estimated_labor
    }
    if (typeof this.times[job.service_list_id] == 'undefined') {
      this.times[job.service_list_id] = ''
      this.times[job.service_list_id] = job.estimated_time
    }
    if (typeof this.notes[job.service_list_id] == 'undefined') {
      this.notes[job.service_list_id] = ''
      this.notes[job.service_list_id] = job.notes
    }
  }
  deleteParts(i,id){
    if(typeof this.selectedParts[id]!='undefined'){
      this.selectedParts[id].splice(i,1)
    }
    this.calTotal()
  }
  removeGJ(i,id){
    if(typeof this.selectedGenericJob[id]!='undefined'){
      this.selectedGenericJob[id].splice(i,1)
    }
    this.calTotal()
  }
  /**
   * @trackID <:CAR-1338> calculate grand total
   * @updated 2020-01-24
   * @updatedBY Vikas Kumar
   * @param
   * @return
   */
  calTotal(){
    let grandTotal=0
    let GJ=this.selectedGenericJob
    let part=this.selectedParts
    let labor=this.labors
    for(let i in GJ){
      if(GJ[i].length){
        for(let j in GJ[i]){
          let p=parseFloat(GJ[i][j].price);
          if(p && (!isNaN(p))){
            p=parseFloat(GJ[i][j].quantity)*p
            grandTotal+=p
          }
        }
      }
    }
    for(let i in part){
      if(part[i].length){
        for(let j in part[i]){
          let p=parseFloat(part[i][j].price);
          if(p && (!isNaN(p))){
            p=parseFloat(part[i][j].quantity)*p
            grandTotal+=p
          }
        }
      }
    }
    
    for (let i in labor) {
      let p = parseFloat(labor[i]);
      if (p && (!isNaN(p))) {
        grandTotal += p
      }
    }
    
    this.grand_total=grandTotal
    let t=( grandTotal ? this.grand_total.toFixed(2):"0.00")
    this.grand_total=(grandTotal ? t : "0.00")
    // console.log('grand_total',this.grand_total,grandTotal)
  }
  /** get confirmation from system user */
  sendToCustomer(type){
    this.gs.confirmDialog("yes","Are you sure ?").subscribe((r:any)=>{
      if(r!=undefined && r.toString() == "yes") {
        this.saveRecommendation(type)
      }
    })
  }
  
  isAllowed(module,action) {
    return this.gs.getAcl(module,action);
  }
  /**
   * @trackID <:CAR-1339> PartsTech Part
   * @since 2020-01-23
   * @addedby Vikas Kumar
   * @param diffrent level of jobs 
   * @return
   */
  partech(jobs,parent,grandParent){
    let l1=grandParent.id,l2,l3;
    if(parent){
      l3=jobs.id
      l2=parent.id
    }else{
      l2=jobs.id
    }
    let indx=jobs.id;
    let keyWords=(<HTMLInputElement>document.getElementById("new-part-" +indx+ "-ac")).value;
    let dialogRef;
    this.partechSession[indx]={};
    this.partechSession[indx].vin=this.seletedCar.vin;
    this.partechSession[indx].quote_id= this.recomendations.id;
    this.partechSession[indx].level1_id = l1;
    this.partechSession[indx].level2_id = l2;
    this.partechSession[indx].level3_id = (l3==null?'':l3);
    this.partechSession[indx].keyword=keyWords;
    this.partechSession[indx].user_id = this.gs.getCurrentUser()["id"];
    this.partechSession[indx].user_type = this.gs.getCurrentUser()["user_type"];
    this.gs.callAPI(globalConstants.API_QQ_GET_PARTSTECH, this.partechSession[indx]).subscribe((response:any) => {
      if (response.result == 1) {
        this.partechSession[indx].redirect_url =response.data.redirect_url;
        this.partechSession[indx].session_id =response.data.session_id;
        dialogRef = this.dialog.open(VideoShowComponent, {
          panelClass: 'partechDialog',
          width: "100%",
          data: {'iframe':this.partechSession[indx].redirect_url,'ref':this.partechSession[indx],isPartsTechConfigured:this.recomendations.is_partstech_configured}
        });
        dialogRef.afterClosed().subscribe(result => {
          dialogRef['ref'].get_cost=1;
          let th=this;
          setTimeout(()=>{
            th.getPartstechData(dialogRef,indx);
          },1000);
        });
      }
    },(error)=>{});
  }
  /**
   * @trackID <:CAR-1339> PartsTech dealy dataload
   * @since 2020-01-23
   * @addedby Vikas Kumar
   * @param refrence of current job , job id 
   * @return
   */
  getPartstechData(dialogRef,index) {
    this.gs.callAPI(globalConstants.API_QQ_GET_PARTSTECH_CART, dialogRef['ref']).subscribe((response:any) => {
      
      if (response.is_cart_modified == "1") {
        this.partMatrixStatus=(response.part_matrix_status==1 ? true : false);
        this.addUpdatePartech(dialogRef['ref'], response.data,index);
      } else if (response.result == '0' && response.code == "449") {
        this.gs.snackbar("error", response.message);
        let th = this;
        if (this.partstechLoadCount <= 3) {
          setTimeout(() => {
            th.partstechLoadCount += 1;
            th.getPartstechData(dialogRef,index);
          }, 1000)
        }else{
          this.partstechLoadCount=1;
        }
      }else if (response.result == '0' ){
        this.gs.snackbar("error", response.message);
      }
    },(error)=>{});
  }
  /**
   * @trackID <:CAR-1339> PartsTech loaded part add in job
   * @since 2020-01-23
   * @updated 2020-01-29
   * @addedby Vikas Kumar
   * @param refrence, partstech part of current job , job id 
   * @return
   */
  addUpdatePartech(parts, data,index){
    if(data && data.length){
      if (typeof this.selectedParts[index] == 'undefined') {
        this.selectedParts[index] = []
      }
      data.forEach(ele => {
        let ind=-1;
        let f=this.selectedParts[index].filter((e,i)=>{
          ind=i
          if(typeof e.part_id!='undefined' && ele.part_id==e.part_id) {
            return true;
          }else if(ele.part_id==e.id){
            return true;
          }
        })
        if(!(f && f.length)){
          ele.partstech_cart_item=1
          this.selectedParts[index].push(ele)
        }else if(ind!=-1){
          ele.partstech_cart_item=1
          this.selectedParts[index][ind].price=ele.price
        }
      });
      this.calTotal()
    }
  }
  /**
   * @trackID <:CAR-1338> Edit part
   * @since 2020-01-23
   * @addedby Vikas Kumar
   * @param index,current part , job id 
   * @return
   */
  editPart(i,part,id){
    this.currentEdit[id]=part
    this.currentEditIndex[id]=i;
    this.showhide(part,id,true)   
  }
  /**
   * @trackID <:CAR-1338> Cancel Edit part
   * @since 2020-01-23
   * @addedby Vikas Kumar
   * @param index,current part , job id 
   * @return
   */
  cancelEditPart(i,part,id){
    this.showhide(part,id,false)
    this.currentEdit[id]={}
    this.currentEditIndex[id]='';
  }
  /**
   * @trackID <:CAR-1338> Show/Hide Button according to Edit/Cancel part
   * @since 2020-01-23
   * @updated 2020-01-29 
   * @addedby Vikas Kumar
   * @param current part , job id ,type
   * @return
   */
  showhide(part,id,type){
    var ElementOfQty   =  $("#new-part-" + id + "-qty");
    var ElementOfPrice =  $("#new-part-" + id + "-price");
    var ElementOfAc    =  $("#new-part-" + id + "-ac");
    var EleDel         = $('.delqq-' + id +'-part')
    var ElePartstech   = $('#new-part-' + id +'-partech-new-btn')
    var EleUpdatePart  = $('#update-part-' + id +'-btn')
    var EleAddPart     = $('#new-part-' + id +'-add-btn')
    var EleAddNewPart  = $('#new-part-' + id +'-add-new-btn')
    var EleCancel      = $('#cancel-' + id +'-part-'+(part.part_id ? part.part_id:part.id))
    var EleEditCurnt   = $('#edit-' + id +'-part-'+(part.part_id ? part.part_id:part.id))
    var EleEdit        = $('.editqq-' + id +'-part')
    var EleUpdateEdit  = $('#edit-part-' + id +'-btn')
        EleCancel.toggle()
        EleUpdatePart.toggle()
        EleEditCurnt.toggle()
        ElePartstech.toggle()
        EleAddPart.toggle()
        EleAddNewPart.toggle()
        EleUpdateEdit.toggle()
        if(type){
          ElementOfPrice.val(part.price)
          ElementOfQty.val(part.quantity)
          ElementOfAc.val(part.label).attr("disabled","disabled")
          EleDel.attr("disabled","disabled")
          ElePartstech.attr("disabled","disabled")
          EleAddPart.attr("disabled","disabled")
          EleEdit.attr("disabled","disabled")
        }else{
          ElementOfAc.val('').removeAttr("disabled")
          EleAddPart.removeAttr("disabled")
          ElePartstech.removeAttr("disabled")
          EleEdit.removeAttr("disabled")
          EleDel.removeAttr("disabled").prop("disabled", false)
          ElementOfPrice.val('')
          ElementOfQty.val('')
        }
  }
  /**
   * @trackID <:CAR-1338> Update part
   * @since 2020-01-24
   * @addedby Vikas Kumar
   * @param current job
   * @return
   */
  updatePart($e,job){

    let idx=this.currentEditIndex[job.id]
    if(typeof this.selectedParts[job.id][idx] !='undefined'){
      var ElementOfQty   =  $("#new-part-" + job.id + "-qty").val();
      var ElementOfPrice =  $("#new-part-" + job.id + "-price").val();
      this.selectedParts[job.id][idx].price=parseFloat(ElementOfPrice).toFixed(2)
      this.selectedParts[job.id][idx].quantity=ElementOfQty
      this.cancelEditPart('',this.selectedParts[job.id][idx],job.id)
      this.calTotal()
    }
  }
  /**
   * @trackID <:CAR-1337> Add New part
   * @since 2020-01-24
   * @addedby Vikas Kumar
   * @updatedby Nitin Raj
   * @updatedon 2020-06-15
   * @param diffrent level of job
   * @return
   */
  addNewPartDialog(jobs, parent, grandparent) {
    let dialogRef:any = this.dialog.open(addNewParts, {
      panelClass: 'car-dialog-form',
      data: { newParts: [] },
    });
    let priceShow=this.showCostPrice
    dialogRef.afterClosed().subscribe(result => {
      for(var i=0;i<dialogRef.newParts.length;i++) {
        var newPartData = dialogRef.newParts[i];
        newPartData.label=newPartData.part_number+(priceShow? ' ($'+newPartData.cost+') ':' ')+newPartData.name;
        newPartData.inventory_price = newPartData.price;
        if (typeof this.selectedParts[jobs.id] == 'undefined') {
          this.selectedParts[jobs.id] = []
        }
        this.selectedParts[jobs.id].push(newPartData)
      }
      this.calTotal()
    })
  }
  /**
   * @trackID <:CAR-1338> Update Selected part
   * @since 2020-01-24
   * @updated 2020-01-29
   * @addedby Vikas Kumar
   * @param current job
   * @return
   */
  updateEditPart($event,jobs){
    let part=this.currentEdit[jobs.id]
    let idx=this.currentEditIndex[jobs.id]
    let dialogRef = this.dialog.open(EditpartPopupComponent, {
      panelClass: 'car-dialog-form',
      data: { part_id: ((typeof part.part_id !='undefined') ? part.part_id : part.id ),request_id: '',quote_id:this.recomendations.id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(typeof result != 'undefined' && typeof result.part_id != 'undefined' ){
        let label=result.part_number+(this.showCostPrice? ' ($'+result.cost+') ':' ')+result.part_name
        this.selectedParts[jobs.id][idx].label=label
      }
    });
  }
  getInfoTxt(){
    return 'Inventory will not be managed in QQ. It will be managed when QQ gets converted to SR.'
  }
   /**
   * @author Vikas Kumar
   * @trackID <:CAR-1540>
   * @created 28-04-2020
   */
  myListFormatter(data: any): string {
    return data['label'];
  }
}
