<form class="form-horizontal form-control-normal" autocomplete="off" [formGroup]="newQueForm" *ngIf="enableNewQueue">
    <div mat-dialog-title class="relative">
      Add Queue
      <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
        <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
      </button>
    </div>
    <mat-dialog-content class="customer-detail-popup">
      <div class="center-block" style="max-width: 600px;">
        <div class="display-full margin-bottom-5">
          <label class="col-sm-3 control-label">
            <strong>Queue Name</strong>
          </label>
          <div class="col-sm-9">
            <mat-form-field class="btn-block col-sm-6 car-input" floatPlaceholder="never" appearance="fill">
              <input matInput type="text" placeholder="Queue" class="form-control input-lg custom-form-input btn-block"
                formControlName="que_name" maxlength="200" required/>
              <mat-error *ngIf="newQueForm.controls.que_name.hasError('required')">
                This field cannot be empty
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="inquiry-action relative">
      <button mat-raised-button color="accent" (click)="submitNewQue()" class="font-bold">Submit</button>
      <button mat-raised-button (click)="newQueue()" class="font-bold">Close</button>
    </mat-dialog-actions>
  </form>
<form class="form-horizontal form-control-normal add-stock-order" autocomplete="off" [formGroup]="stockFrm" *ngIf="enableNewQueue==false">
  <div mat-dialog-title class="relative">
    Add to stock order
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
      <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
  </div>
  <mat-dialog-content class="customer-detail-popup">
    <div class="center-block" style="max-width: 600px;">

      <div class="display-full margin-bottom-5">
        <label class="col-sm-3 control-label">
          <strong>Quantity</strong>
        </label>
        <div class="col-sm-9 quantity-section">
          <mat-form-field class="btn-block col-sm-6 car-input" floatPlaceholder="never" appearance="fill">
            <input matInput type="text" placeholder="Quantity {{part.part_qty}}" class="form-control input-lg custom-form-input btn-block"
              formControlName="quantity" maxlength="10" title="Quantity" required/>
            <mat-error *ngIf="stockFrm.controls.quantity.hasError('required')">
              This field cannot be empty
            </mat-error>
          </mat-form-field>
          <div class="col-sm-12 margin-bottom-10">
           <p class="help-block" style="margin:0px;">You have used {{lastMonthUsage}} unit(s) in the last month</p>
          </div>
        </div>
      </div>
      <div class="display-full margin-bottom-5" *ngIf="newAllowed==false">
        <label class="col-sm-3 control-label">Queue</label>
        <div class="col-sm-9">
          <label class="col-sm-6 control-label">
            <input type="radio" [checked]="isRecom" name="stockqueue" (click)="isExisting(0)" />{{request_id}}</label>
          <label class="col-sm-6 control-label">
            <input type="radio" name="stockqueue" (click)="isExisting(1)" />Existing Queue</label>
        </div>
      </div>
      <div class="display-full margin-bottom-5" *ngIf="showDropDown || newAllowed">
          <div class="col-sm-3 hidden-xs">&nbsp;</div>
        <div class="col-sm-9 queue-section">
            <div class="select-totech col-sm-8 uc-ngx-select">
                <ngx-select class="display-full margin-bottom-20" 
                   [items]="allQues" 
                   [defaultValue]="queDefaultVal"
                   formControlName="que_id" 
                   optionTextField="name"
                   [disabled]="allQues.length <= 0 "
                   placeholder="Select Queue">
               </ngx-select>
           </div>
          <div class="col-sm-4"><button type="button" *ngIf="newAllowed" class="font-bold mat-raised-button mat-accent create-new-btn" (click)="newQueue()">Create New</button></div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="inquiry-action relative">
    <div class="flexBtns2">
      <button mat-raised-button color="accent" (click)="SubmitQue()" class="font-bold">Submit</button>
      <button mat-raised-button mat-dialog-close class="font-bold border">Close</button>
    </div>
  </mat-dialog-actions>
</form>