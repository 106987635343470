import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from '../../../../environments/global.service';
import { globalFunctions } from '../../../../environments/globalFunctions';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
@Component({
  selector: 'app-matrixrange',
  templateUrl: './matrixrange.component.html',
  styleUrls: ['./matrixrange.component.scss']
})
export class MatrixrangeComponent implements OnInit {

  user: any = null;
  public d = '';
  public ranges: FormGroup;
  public params: any = {};
  public start: any = '0.01';
  public endPrice: any = 0;
  public incrementValue: any = 0;
  public rangeMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: false,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 11,
    requireDecimal: false,
    allowNegative: false,
    allowLeadingZeroes: false
  });
  public markupMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: false,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 3,
    requireDecimal: false,
    allowNegative: false,
    allowLeadingZeroes: false
  });
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, public dialogRef: MatDialogRef<MatrixrangeComponent>, private gs: GlobalService) {
    this.user = this.gs.getCurrentUser();
    this.params = data;
    this.start = data.incVal;
    this.incrementValue = data.incVal;
  }

  ngOnInit() {
    if (!this.user) {
      this.dialogRef.close(2);
    }
    this.gs.diaLogObj(this.dialogRef);
    this.ranges = this.formBuilder.group({
      margin_start: ['', Validators.required],
      margin_end: [this.params.limit, Validators.required],
      markup: ['0.00', Validators.required]
    });
    if (this.params.ranges.length) {
      this.start = this.params.ranges[this.params.ranges.length - 1].margin_end;
      this.start = (parseFloat(this.start) + this.incrementValue).toFixed(2);
    }
    this.ranges.controls.margin_start.setValue(this.start);
    this.endPrice = parseFloat(this.params.limit);
    this.editMode()
  }

  onlyNumericKey(event) {
    return globalFunctions.onlyDecimalNumberKey(event);
  }

  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    this.d = event.srcElement.value;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  disableKey(event) {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey == true && (event.which == '67' || event.which == '86' || String.fromCharCode(event.which).toLowerCase() == 's')) {
      event.preventDefault();
    }
  }

  submit() {
    let limit = parseFloat(this.params.limit);
    let start = parseFloat(this.ranges.value.margin_start);
    let end = parseFloat(this.ranges.value.margin_end);
    let markUp=parseFloat(this.ranges.value.markup);
    if (this.ranges.valid) {
      if (isNaN(start) || isNaN(end)) {
        this.gs.snackbar("error", "Part Pricing Method - Start or End range must be valid and numeric.");
        return false;
      } else if (end < start) {
        this.gs.snackbar("error", 'Part Pricing Method - End should be greater than Start range.');
        return false;
      } else if (end > limit || start > limit) {
        this.gs.snackbar("error", "Part Pricing Method - Start and End Range should not exceed the Cost limit( " + limit + " ).'");
      } else if(isNaN(markUp)){
        this.gs.snackbar("error", "Part Pricing Method - Markup must be valid and numeric.");
        return false;
      }else {
        this.dialogRef['range'] = this.ranges.value;
        this.dialogRef.close(1);
      }
    }

  }
  editMode() {
    if (this.params.editMode && this.params.editIndex >= 0) {
      let start = this.params.ranges[this.params.editIndex].margin_start;
      let end = this.params.ranges[this.params.editIndex].margin_end;
      let markup = this.params.ranges[this.params.editIndex].markup;
      this.ranges.controls.margin_start.setValue(start);
      this.ranges.controls.margin_end.setValue(end);
      this.ranges.controls.markup.setValue(markup);
    }
  }
  vkFloat(e) {
    let output = Number(this.ranges.value.margin_end);
    if (isNaN(output)) {
      if (isNaN(Number(this.d))) {
        this.ranges.value.margin_end = parseFloat(this.ranges.value.margin_end);
      } else {
        this.ranges.value.margin_end = (isNaN(Number(this.d)) ? '0' : this.d);
      }
    }
  }

}
