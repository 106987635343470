import { Component, OnInit, ViewChild } from '@angular/core';
import { globalConstants } from '../../../../environments/globalConstants';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { GlobalService } from '../../../../environments/global.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Http } from '@angular/http';
import { globalFunctions } from '../../../../environments/globalFunctions';
import { StockorderpopupComponent } from '../../shopparts/stockorderpopup/stockorderpopup.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { ViktableComponent, VikSource } from '../../../admin/viktable/viktable.component';

const SMART_BATCH_LIST = "shop-smart-batch-";

@Component({
  selector: 'app-shop-smart-batch-list',
  templateUrl: './shop-smart-batch-list.component.html',
  styleUrls: ['./shop-smart-batch-list.component.scss']
})
export class ShopSmartBatchListComponent extends ViktableComponent implements OnInit {

    public currentUser: any = {};
    public openSmartBatchSearch = false;
    public Content_List = [];
    displayedColumns = ['Part Type', 'Smart Batch Number' , 'Actions'];
    public tablesData = this;
    dataSource: any;
    url = "";
    public checkFormEmpty=false;
    public searchEnabled : boolean = false;
     /* SEARCH BY ARRAY */
    searchByArray = [
        { id: 1, text: "Part Type"},
        { id: 2, text: "Smart Batch Number"}
    ];
    /* SORT BY ARRAY */
    sortByArray = [
        { id: 'type', text: "Part Type"},
        { id: 'batch_number', text: "Smart Batch Number"}
    ];
    
    /* SORT TYPE ARRAY */
    sortTypeArray = [
        { id: 'ASC', text: "Ascending"},
        { id: 'DESC', text: "Descending"}
    ]
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    constructor(public gs: GlobalService, public router: Router) {
      super(globalConstants.API_SHOP_SMART_BATCH_LIST, gs);
      this.currentUser = JSON.parse(localStorage.getItem("loggedInUser"));
      if (this.currentUser) {
        this.sessionKey = SMART_BATCH_LIST;
        this.url = globalConstants.API_SHOP_SMART_BATCH_LIST;
      } else {
        this.router.navigate(["/shops"]);
      }
    }
  
    ngOnInit() {
      this.reinitializeOptions();
      if(this.checkFormEmptys()){
        this.openSmartBatchSearch = true;
      }else{
        this.openSmartBatchSearch = false;
      }
      this.isReady = true;
      this.gs.setMetaData("SHOPS", "SMART_BATCH_LIST");
    }

    ngAfterViewInit(){
      this.setDataTable();
     }

    search(event) {
      this.options.start = 0;
      this.setDataTable();
      this.searchEnabled = true;
    }
  

    reinitializeOptions() {
      this.options.search_keyword = '';
      this.options.search_by = '';
      this.options = this.gs.localGetterSetter(this.options, this.options, SMART_BATCH_LIST);
      this.options.user_type = this.currentUser.user_type;
      this.options.user_id = this.currentUser.id;
      if(this.options.search_by == null || this.options.search_by == "null"){
        this.options.search_by = '';
      }else{
        if(this.options.search_by != ''){
          this.options.search_by = Number(this.options.search_by);
        }
      }
    }
  
    checkFormEmptys() {
      if ((this.options.search_keyword == "" || this.options.search_keyword == null) && (this.options.search_by == "" || this.options.search_by == null || this.options.search_by == "null")) {
        this.checkFormEmpty=false;
        return false
      }else {
        this.checkFormEmpty=true;
        return true;
      }
    }
    setDataTable() {
      this.dataSource = new VikSource(this.tablesData, this.sort, this.paginator);
      this.checkFormEmptys();
      this.dataSource.getSortedData = this.sortedData;
    }
   
    editContent(obj) {
      this.gs.localGetterSetter(this.options, this.options, SMART_BATCH_LIST);
      this.router.navigate(['/admin/cms/edit/'+obj.id]);
    }
   
  
    createRecordrecord(record) {
      return {
        id: record.id,
        name: record.group_name,
        part_number: record.batch_number,
        modified_at : this.convertDate(record.modified_at)
      };
    }
    sortedData(): any[] {
      let th: any = this;
      const data = th._exampleDatabase.data.slice();
      if (!th._sort.active || th._sort.direction == '') { return data; }
      return data.sort((a, b) => {
        let propertyA;
        let propertyB;
        switch (th._sort.active) {
          case 'Part Type': propertyA = a.name; propertyB = b.name; break;
          case 'Smart Batch Number': propertyA = a.part_number; propertyB = b.part_number; break;
          case 'ModeficationDate': propertyA = a.modified_at; propertyB = b.modified_at; break;
          default: return 0;
        }
        let valueA = isNaN(propertyA) ? propertyA.toUpperCase() : propertyA;
        let valueB = isNaN(propertyB) ? propertyB.toUpperCase() : propertyB;
  
        return (valueA < valueB ? -1 : 1) * (th._sort.direction == 'asc' ? 1 : -1);
      });
    }

    public edit(id){
      this.gs.localGetterSetter(this.options,null,this.sessionKey);
      this.router.navigate(['/shops/edit-smart-batch/'+id]);
    }

    public convertDate(date){
      return this.gs.getFullFormateDate(date,'G','H');
    }

    public clearSearch(){
      this.gs.localGetterSetter(null,this.options,this.sessionKey);
      this.options.search_keyword = "" ;
      this.options.search_by = '';
      this.setDataTable();
      this.searchEnabled = false;
    }

    public goToPartsList(){
      if(this.searchEnabled){
        this.gs.localGetterSetter(this.options,null,this.sessionKey);
      }
      this.router.navigate(['/shops/list-parts']);
    }

    public addNewPart(partNumber : any){
      this.gs.localGetterSetter(this.options,null,this.sessionKey);
      this.gs.getSetItems('auto_part_number',partNumber);
      this.router.navigate(['/shops/add-part']);
    }
}
