import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
    public id: any;
    public message: string;
    public labelNo: string;
    public labelYes: string;
    public labelRm: string;
    data:any;
    serviceDetail:any;
    markAsComplete:boolean = false;
    downloadInvoice:boolean = false;
    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {
        this.labelNo = 'No';
        this.labelYes = 'Yes';
        this.labelRm = 'Rm';
    }
    
    ngOnInit() {
        if(this.data && this.id == 'open_pay'){
            // console.log('data = ', this.data);
            // this.serviceDetail = this.data.serviceDetail.value;
        }
    }
    
   selectNo(){
    this.dialogRef.close('No');
   }
}
