<form class="form-horizontal add-new-job-form" autocomplete="off" novalidate [formGroup]="newGenericJob">
  <div mat-dialog-title class="relative">
    Add New Job
    <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
      <mat-icon class="close-icon-lg">close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="display-full margin-bottom-5">
      <label for="partName" class="col-sm-3 control-label"><strong>Job Name</strong></label>
      <div class="col-sm-9">
        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
          <input matInput type="text" placeholder="Job Name" formControlName="job_name" class="form-control input-lg custom-form-input btn-block" required/>
          <mat-error *ngIf="newGenericJob.controls.job_name.hasError('required')">
            Job name is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="display-full margin-bottom-5">
      <label for="cost" class="col-sm-3 control-label"><strong>Job Cost</strong></label>
      <div class="col-sm-9">
        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
          <input matInput type="text" placeholder="Job Cost" formControlName="cost" class="form-control input-lg custom-form-input btn-block" maxlength="11" required (keypress)="onlyNumericKey($event);convertIntoDecimal($event,'cost',11)" />
          <mat-error *ngIf="newGenericJob.controls.cost.hasError('required')">
            Job Cost is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="newGenericJob.controls.cost.hasError('pattern')">
           Must be a number with upto 2 decimal places.
         </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="display-full margin-bottom-5">
      <label for="partNumber" class="col-sm-3 control-label"><strong>Retail Price</strong></label>
      <div class="col-sm-9">
        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
          <input matInput type="text" placeholder="Retail Price" formControlName="price" class="form-control input-lg custom-form-input btn-block" required (keypress)="onlyNumericKey($event);convertIntoDecimal($event,'price',11)"/>
          <mat-error *ngIf="newGenericJob.controls.price.hasError('required')">
            Retail Price is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="newGenericJob.controls.price.hasError('pattern')">
           Must be a number with upto 2 decimal places.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
   

    <div class="display-full margin-bottom-5">
      <label for="description" class="col-sm-3 control-label"><strong>Description</strong></label>
      <div class="col-sm-9 details-input notes-input">
        <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
          <textarea matInput placeholder="Description" formControlName="description" rows="5"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="display-full margin-bottom-5">
      <div class="col-sm-3">&nbsp;</div>
      <div class="col-sm-9">
        <mat-checkbox name="non_taxable" formControlName="non_taxable" (change)="toggleNonTaxable($event)">Non Taxable</mat-checkbox>
        <p><strong>Note:</strong>If checked then this generic job price would not be included in tax calculations.</p>
      </div>
    </div>
    <div class="display-full margin-bottom-5">
      <div class="col-sm-3">&nbsp;</div>
      <div class="col-sm-9">
        <mat-checkbox name="add_to_job" (change)="toggleQuantityField($event)" [(ngModel)]="newGenericJob.value.add_to_job" [ngModelOptions]="{standalone: true}">Add to job</mat-checkbox>
      </div>
    </div>

    <div class="display-full margin-bottom-5" *ngIf="newGenericJob.value.add_to_job ">
      <label for="description" class="col-sm-3 control-label"><strong>Quantity</strong></label>
      <div class="col-sm-9">
        <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
          <input matInput type="text" formControlName="quantity" class="form-control input-lg custom-form-input btn-block"  maxlength="255" />
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <!-- <mat-dialog-actions class="inquiry-action relative">
    <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
    <button mat-raised-button class="font-bold" (click)="addNewGenericJobSubmit(1)" color="accent">Submit</button>
  </mat-dialog-actions> -->
  <mat-dialog-actions class="inquiry-action relative flexBtn3">
      <button type="button" mat-raised-button mat-dialog-close class="font-bold" click="close()">Close</button>
      <button type="button" mat-raised-button class="font-bold" color="accent" (click)="addNewGenericJobSubmit(1)">Submit and Close</button>
      <button type="button" mat-raised-button class="font-bold" color="accent" (click)="addNewGenericJobSubmit(2)">Submit and Add Another</button>
  </mat-dialog-actions>
</form>
