<div class="panel panel-primary margin-0" id="mainCompotents">
  <div class="panel-heading padding-left-15">
    <h3 class="panel-title">
      Employee Logs
    </h3>
    <button mat-mini-fab class="custom-btn-back print-button" [disabled]="isPrintDisabled" title="Print" (click)="printDiv();" >
      <i class="fa fa-fw fa-print margin-right-5"></i><span class="hidden-xs">PRINT</span>
    </button>
  </div>
  <div class="panel-body bgcolor-lightgrey">
    <div class="total-count-section">
      <div class="row">
        <div class="col-sm-3 col-lg-2  uc-ngx-select">
          <ngx-select class="display-full margin-bottom-20" [items]="dateRange" [(ngModel)]="selected" placeholder=""
            [noAutoComplete]="true" (select)="onChangeDate($event)"></ngx-select>
        </div>
        <div class="col-sm-3 col-lg-2  uc-ngx-select">
          <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
            <mat-select class="form-control input-lg custom-form-input" options="searchByArray" placeholder="Employee"
              (valueChange)="onChangeEmployee($event)" [(ngModel)]="selectedEmp">
              <mat-option value="">All</mat-option>
              <mat-option *ngFor="let opt of employee" [value]="opt.id">{{opt.emp_name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="selected=='5'">
          <div class="col-sm-3 col-lg-2"> 
            <mat-form-field class="car-input btn-block car-datePicker" floatPlaceholder="never" appearance="fill">
              <input matInput class="form-control input-lg custom-form-input" [(ngModel)]="startDateModal.start_date"
                name="start_date" [max]="maxDate" [matDatepicker]="startDatePicker" placeholder="Choose a date" (dateChange)="updateDate($event.value)">
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker ></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-sm-3 col-lg-2">
            <mat-form-field class="car-input btn-block car-datePicker" floatPlaceholder="never" appearance="fill">
              <input matInput class="form-control input-lg custom-form-input" [(ngModel)]="endDateModal.end_date"
                [min]="minDate" [max]="maxDate" name="end_date" [matDatepicker]="endDatePicker"
                placeholder="Choose a date">
              <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-sm-2 form-block">
            <button type="button" mat-raised-button
              class="car-btn btn-block text-uppercase btn-lg font-light fontcolor-white" color="accent" title="submit"
              style="max-width: 300px; padding-top: 1px; padding-bottom: 0px;" (click)="submitDate()">
              SUBMIT
            </button>
          </div>
        </div>
        <div class="col-sm-2 form-block pull-right">
          <button type="button" mat-raised-button
            class="car-btn btn-block text-uppercase btn-lg font-light fontcolor-black reset-btn" title="reset"
            style="float: right;max-width: 300px; padding-top: 1px; padding-bottom: 0px;" (click)="resetToDefault()">
            CLEAR
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3 link">
          <mat-card class="card-default">
            <div class="infoTile">
              <strong [innerHTML]="total_req_count"></strong>
              <p>TOTAL SERVICE REQUESTS</p>
            </div>
          </mat-card>
        </div>
      </div>
    </div>

    <mat-accordion class="new-request-expansion-history view-request show-price display-full" displayMode="flat"
      hideToggle="false" [multi]="true">
      <mat-expansion-panel hideToggle="true" *ngFor="let employee of empMainList;let a=index"
        id="expansion-history-{{parent_index}}-{{a+1}}" #expPanel>
        <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px"
          (click)="expPanel.expanded?onPanelExpand(a):onPanelCollapse(a)"
          id="expansionHead-history-{{parent_index}}-{{a+1}}" [attr.data-state]="a==0">
          <!-- <ng-container *ngIf="isCategoryView"> -->
          <div class="row display-full hidden-xs">
            <div class="col-sm-3 col-lg-3 col-md-3 text-left">
              <span>
                Name: {{ employee.name || '' }}
              </span>
            </div>
            <div class="col-sm-3 col-lg-3 col-md-3 text-center">
              <span>
                Hours Logged: {{ employee.total_hours_logged || '0.0' }}
              </span>
            </div>
            <div class="col-sm-3 col-lg-3 col-md-3 text-center">
              <span class="hidden-xs">
                Hours Billed: {{ employee.total_hours_billed || '0.0' }}
              </span>
            </div>
            <div class="col-sm-3 col-lg-3 col-md-3 text-right padding-0">
              <span>
                Productivity: {{getEmpProductivity(employee) || 0}} %
              </span>
            </div>
          </div>
          <div class="row visible-xs">
            <div class="col-sm-6 col-xs-12">
              <span class="col-xs-6 margin-0 pull-left">
                Name: {{ employee.name || '' }}
              </span>
              <span class="col-xs-6 margin-0 pull-right">
                Hours Logged: {{ employee.total_hours_logged || '0.0' }}
              </span>
            </div>
            <div class="col-sm-6 col-xs-12">
              <span class="col-xs-6 margin-0 pull-left">
                Hours Billed: {{ employee.total_hours_billed || '0.0' }}
              </span>
              <span class="col-xs-6 margin-0 pull-right">
                Productivity: {{getEmpProductivity(employee) || 0}} %
              </span>
            </div>
          </div>
        </mat-expansion-panel-header>
        <app-empreportdatatableview [empId]="employee.id" [startDate]="startDate" [endDate]="endDate" [dateCriteria]="selected"></app-empreportdatatableview>
      </mat-expansion-panel>
      <div style="visibility: hidden">
        <div id="printarea">
          <div class="card-default">
            <div class="info-Tile">
              <strong>{{total_req_count}}</strong>
              <p>TOTAL SERVICE REQUESTS</p>
            </div>
          </div> 
          <div class="panel-heading" *ngFor="let emp of empMainList;let a = index">
            <div>Name : {{emp.name || ''}}</div>
            <div>Hours Logged : {{emp.total_hours_logged || '0.0'}}</div>
            <div>Hours Billed : {{emp.total_hours_billed || '0.0'}}</div>
            <div>Productivity: {{getEmpProductivity(emp) || 0}} %</div>
          </div>
        </div>
      </div>
    </mat-accordion>
