<div class="popup-sidebar-wrapper">
  <mat-drawer-container class="side-panel-wrappers ticket-assignment business-report" autosize end>
    <mat-drawer #excludedVehicesDrawer class="ticket-sidenav" mode="over" position="end">
      <!-- header section -->
      <h4><span>Vehicles Excluded From Report</span></h4>
      <div class="border-box">
        <div class="btn-block header" >
          <ul class="padding-0 margin-0" style="float: right;">
              <li>
                  <button type="button" class="btn skip-btn" (click)="closeSideBar()" title="Close"><i class="fa fa-times" aria-hidden="true"></i></button>
              </li>
          </ul>
      </div>
        <!-- <div class="cross-btn">
          <button type="button" (click)="closeSideBar()">
            <div class="desktop-screen">x</div>
          </button>
        </div> -->
        <!-- content section -->
          <div class="assignment-block" >
              <div class="row bs-table">
                  <p class="text-right margin-right-10 marign-top-10">
                      <button mat-raised-button color="accent" type="submit" (click)="closeSideBar(2)"><i class="fa fa-plus fa-lg margin-right-5"></i> Add</button>
                  </p>
                  <div class="col-md-12 col-sm-12 vehicle-excluded">
                      <table class="table table-striped ">
                          <thead>
                              <tr>
                                  <th>License Plate</th>
                                  <th>State</th>
                                  <th>Customer Name</th>
                                  <th>Mobile</th>
                                  <th>Action</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr class="no-data-wrapper" *ngIf="excludedVehicles.length === 0"> 
                                  <td colspan="5" class="no-data-found text-center">No Records Available</td>
                              </tr>
                              <tr *ngFor="let vehicle of excludedVehicles;let i = index">
                                  <td>{{vehicle.license_plate}}</td>
                                  <td>{{vehicle.state}}</td>
                                  <td>{{vehicle.customer_fullname}}</td>
                                  <td>{{vehicle.mobile_phone}}</td>
                                  <td class="btn-icon-block">
                                      <button class="btns btn-danger btn-icons" title="Delete" type="button" (click)="removeVehicle(i)">
                                      <i class="fa fa-trash"></i>
                                      </button>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
        </div>
        <!-- Footer section -->
        <div class="footer">
          <div class="row">
            <div class="col-sm-4 col-xs-12"></div>
            <div class="col-sm-8 col-xs-12">
              <div class="btn-block" >
                <ul style="float: right;">
                  <li>
                    <button type="button" class="btn skip-btn cancel" (click)="closeSideBar()">Close</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-drawer>
  </mat-drawer-container>
</div>