<div mat-dialog-title class="relative">
    Add Service Note
    <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
      <mat-icon class="close-icon-lg">close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="center-block">
      <form class="form-horizontal form-control-normal add-service-note" autocomplete="off" (onSubmit)="addNotes()" [formGroup]="notes">
        <div class="display-full margin-bottom-5">
          <label class="col-sm-3 control-label"><strong>Title<span class="asterisk">*</span></strong></label>
          <div class="col-sm-9">
            <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
              <input matInput type="text" placeholder="Title" formControlName="title" maxlength="200" class="form-control input-lg custom-form-input btn-block" />
              <mat-error *ngIf="notes.controls.title.hasError('required') ">
                This field cannot be empty
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="display-full margin-bottom-5">
          <label class="col-sm-3 control-label"><strong>Description<span class="asterisk">*</span></strong></label>
          <div class="col-sm-9 details-input">
            <mat-form-field class="car-input btn-block notes-input" floatPlaceholder="never" appearance="fill">
              <textarea matInput placeholder="Description" rows="5" maxlength="1000" formControlName="description"></textarea>
              <mat-error *ngIf="notes.controls.description.hasError('required') ">
                This field cannot be empty
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="display-full datepicker-section">
          <label class="col-sm-3 control-label"><strong>Notification Date</strong></label>
          <div class="col-sm-9">
            <div class="vlio-datepicker relative">
                <dp-date-picker mode="daytime" placeholder="Choose Date i.e {{minDates}}" formControlName="notify_date" [config]="datePickerConfig" theme="dp-material dp-main" class="btn-block" #dayPicker></dp-date-picker>
                <button type="button" mat-icon-button (click)="dayPicker.api.open();">
                  <mat-icon>date_range</mat-icon>
                </button>
            </div>
          </div>
        </div>
        <div class="display-full margin-bottom-5" *ngIf="isEmployee">
          <label class="col-sm-3 control-label"><strong>Status</strong></label>
          <div class="col-sm-9 status-section">
            <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
              <mat-select class="form-control input-lg custom-form-input"  placeholder="Select status" formControlName="status" (change)="selectedEmps($event)">
                <mat-option value="0">Select status</mat-option>
                <mat-option value="1">Assigned</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="display-full margin-bottom-5 uc-ngx-select" *ngIf="isEmployee">
          <label class="col-sm-3 control-label"><strong>Assign to</strong></label>
          <div class="col-sm-9 details-input relative">
            <ngx-select (remove)="getSelected($event)" [autoClearSearch]="true" class="display-full margin-bottom-20" formControlName="assignees" [multiple]="true" [items]="employes" placeholder="Select Employee"></ngx-select>
            <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
              <mat-error *ngIf="notes.controls.assignees.hasError('required')">
                  This field cannot be empty
              </mat-error>
            </div>
          </div>
        </div>
        <div class="display-full margin-bottom-5" >
            <label class="col-sm-3 control-label"><strong>Documents &amp; Images</strong></label>
            <div class="col-sm-9">
              <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative" >
                <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                Drag File Here
                <input type="file" id="addInternalNotesDoc" name="addInternalNotesDoc" (change)="changeListner($event)" />
                <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
              </mat-toolbar>
              <p class="help-block">Format(s) supported : {{FORMAT_SUPPORTED}}</p>
              <p class="help-block">Maximum size : 10MB</p>
              <ul class="list-inline margin-left-0 upload-image">
                <li *ngFor="let doc of noteImgDocs;let i=index" class="relative view-images">
                  <a href="javascript:void(0);" class="img-set thumbnail relative pdf-img">
                    <img class="show-image" src="{{doc.src}}"  *ngIf="doc.type==1">
                    <span class="fa far fa-file-pdf fa-4x" *ngIf="doc.type==3"></span>
                    <span class="fa far fa-file-word fa-4x" *ngIf="doc.type==4"></span>
                    <div class="image-loader">
                      <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                    </div>
                    <i class="fa fa-trash remove-img" (click)="deleteImgDoc(i)"></i>
                  </a>
                </li>
              </ul>
              <ul class="list-inline margin-left-0 upload-image" *ngIf="noteDetail && noteDetail.docs.length">
                  <li *ngFor="let doc of noteDetail.docs;let i=index" class="relative view-images">
                    <a href="javascript:void(0);" class="img-set thumbnail relative pdf-img">
                        <img class="show-image" src="{{imgPath}}{{noteDocPath}}thumb/{{doc.filename}}" *ngIf="doc.doc_type==1">
                        <span class="fa far fa-file-pdf fa-4x" *ngIf="doc.doc_type==3"></span>
                        <span class="fa far fa-file-word fa-4x" *ngIf="doc.doc_type==4"></span>
                      <div class="image-loader">
                        <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                      </div>
                      <i class="fa fa-trash remove-img" (click)="deleteImgDoc(i)"></i>
                    </a>
                  </li>
                </ul>
            </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="inquiry-action relative">
    <div class="flexBtns">
      <button mat-raised-button (click)="addNotes()" [disabled]="isSubmitBtnDisabled" class="font-bold mat-raised-button" color="accent">Submit</button>
      <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
    </div>
  </mat-dialog-actions>
  