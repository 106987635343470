import { Component, OnInit , ViewChild,Inject,ElementRef,AfterViewInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { globalConstants } from '../../../environments/globalConstants';
import { GlobalService } from '../../../environments/global.service';
import { globalFunctions } from '../../../environments/globalFunctions';
import {Http, Headers,RequestOptions, Response} from '@angular/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Router} from '@angular/router';
@Component({
  selector: 'app-imagedialog',
  templateUrl: './imagedialog.component.html',
  styleUrls: ['./imagedialog.component.scss']
})
export class ImagedialogComponent{
  public imagepath;
  public catType;
  constructor( private el:ElementRef,@Inject(MAT_DIALOG_DATA) public data: any, public router: Router,private http: Http, public dialogRef: MatDialogRef<ImagedialogComponent>, private formBuilder: FormBuilder, private globalService: GlobalService) {
    this.globalService.diaLogObj(this.dialogRef);
    if(data.category == 1){
      this.imagepath=globalConstants.S3_BUCKET_URL+globalConstants.VEHICLE_PICTURES+globalConstants.IMG_ORIGINAL+data.img;
    }else if(data.category == 5){
      this.imagepath=globalConstants.S3_BUCKET_URL+globalConstants.VEHICLE_VIN_PIC+globalConstants.IMG_ORIGINAL+data.img;
    }else if(data.category == 10){
      if(data.img !=""){
        this.imagepath=globalConstants.S3_BUCKET_URL+globalConstants.PART_PICTURE+globalConstants.IMG_ORIGINAL+data.img;
      }else{
        this.imagepath = data.base64Image;
      }
    }else if(data.category == 11){
      if(data.img !=""){
        this.imagepath=globalConstants.S3_BUCKET_URL+globalConstants.MASTER_PART_PICTURE+globalConstants.IMG_ORIGINAL+data.img;
      }else{
        this.imagepath = data.base64Image;
      }
    } else if (data.category == 12) {
      this.imagepath = globalConstants.S3_BUCKET_URL+globalConstants.INTERNAL_NOTE_DOC+globalConstants.IMG_ORIGINAL+ data.img + '?' + new Date().getTime();
    } else if (data.category == 13) {
      this.imagepath = globalConstants.S3_BUCKET_URL+globalConstants.SERVICE_NOTE_DOC+globalConstants.IMG_ORIGINAL+ data.img + '?' + new Date().getTime();
    } else if (data.category == 14) {
      this.imagepath = globalConstants.S3_BUCKET_URL+globalConstants.SERVICE_NOTE_COMMENT_DOC+globalConstants.IMG_ORIGINAL+ data.img + '?' + new Date().getTime();
    } else if (data.category == 15) {
      this.imagepath = globalConstants.S3_BUCKET_URL+globalConstants.INTERNAL_NOTE_COMMENT_DOC+globalConstants.IMG_ORIGINAL+ data.img + '?' + new Date().getTime();
    }else{
      this.dialogRef.close();
    }
  }



}
