import { Component, OnInit } from '@angular/core';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { trigger, state, style, animate, transition, group } from '@angular/animations';
import { Router } from '@angular/router';
import { ImagetooldialogComponent } from '../../../imagetooldialog/imagetooldialog.component';
import { VideoShowComponent } from '../../../customer/my-vehicles/video-show/video-show.component';
import { MatDialog } from '@angular/material/dialog';
import { LoaderService } from '../../../base/services/LoaderService';
import { globalFunctions } from '../../../../environments/globalFunctions';
@Component({
  selector: 'app-logview',
  templateUrl: './logview.component.html',
  styleUrls: ['./logview.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ 'max-height': '1500px', overflow: 'hidden' })),
      state('out', style({ 'max-height': '0px', overflow: 'hidden' })),
      transition('in => out', [group([
        animate('700ms ease-in-out', style({ 'max-height': '0px', overflow: 'hidden' })),
      ])]),
      transition('out => in', [group([
        animate('700ms ease-in-out', style({ 'max-height': '1500px', overflow: 'hidden' })),
      ])]),
    ])
  ]
})
export class LogviewComponent implements OnInit {

  public userData:any;
  public vehicleName :any ="";
  public content: any= '';
  public logDetail: any = {};
  public animationState = 'out';
  // animationStateApprovalData = 'out';
  public ObjectJson:any = {};
  public showCostPrice = false;
  public currentCustomer;
  public statuses = ['Appointment', 'Pending', 'Processing', 'Waiting for Approval', '', 'Service Completed', 'Completed', 'Approval Completed', 'Completed And Paid', 'Unread Rating', 'Non-CAR', '', 'Work in progress'];
  public bucket_url = globalConstants.S3_BUCKET_URL+globalConstants.DATA_ARCHIVE;
  public requestImgPath = globalConstants.S3_BUCKET_URL + globalConstants.SERVICE_REQUEST_PICTURES + globalConstants.IMG_THUMB;
  public docPath = globalConstants.S3_BUCKET_URL + globalConstants.SERVICE_REQUEST_DOC;
  public vin_photo:any='';
  public reportDownloadType:any='';
  public vehicle_pic:any='';
  public isInventory=false;
  reportDownloadLink: string;
  constructor(
    public gs: GlobalService, 
    public router: Router, 
    public dialog: MatDialog,
    private loaderService: LoaderService
    ) {
    this.userData = this.gs.getCurrentUser();
    this.content = 'user_id=' + this.userData.id + '&user_type=' + this.userData.user_type;
    this.currentCustomer = this.userData;
    setTimeout(() => {
      let isEnable = this.gs.getAcl('service_request', 'views');
      this.showCostPrice = this.gs.getAcl('display_part_cost', 'views');
      if (!isEnable) {
        this.router.navigate(['shops/action/forbidden']);
      }
    }, globalConstants.RELOAD_WAIT)
    this.gs.setMetaData("SHOPS", "SERVICE_REQUEST_LOGS")
  }

  ngOnInit() {
   this.loaderService.hide();
   var downloadUSPSReport_URL = globalConstants.API_DOWNLOAD_INSPECTION_REPORT_USPS_URL;
   this.reportDownloadLink = downloadUSPSReport_URL;
   let data = this.gs.getSetItems('logView');
    var res = data;//.replace(new RegExp("\\\\", "g"), "");
    let list = this.gs.isJSON(res, null);
    if (list) {
      this.logDetail = list;
      if (list.year =="") {
        this.vehicleName= "";
      } else {
        this.vehicleName= list.year ;
      }
      if(list.make != ""){
        this.vehicleName+= (this.vehicleName ? "/" : "" ) + list.make;
      }
      if(list.model != ""){
        this.vehicleName+= (this.vehicleName ? "/" : "" ) + list.model
      }
    }else{
      this.router.navigate(['shops/list-service-requests']);
    }

  }
  ConvertToNumber(str) { return parseInt(str) }
  getNltoBr(val) {
    if (val && val.length > 0) {
      return val.toString().replace(/\r\n/g, "<br />").replace(/\n/g, "<br />");
    }
    return '-';
  }
  parseFloats(value) {
    let val = parseFloat(value);
    if (isNaN(val)) {
      return '0.00';
    }
    return val.toFixed(2);
  }
  expansionOpen(ele) {
    this.gs.expansionOpen(ele);
  }
  isAllowed(action, module?: any) {
    if (typeof module != 'undefined') {
      return this.gs.getAcl(module, action);
    }
    return false;
  }
  getStatus() {
    return this.logDetail.is_appointment == '1' && (this.logDetail.request_status == '1' || this.logDetail.request_status == '2') ? 'Appt.' : this.statuses[this.logDetail.request_status];
  }
  toggleShowDiv() {
    this.animationState = this.animationState === 'out' ? 'in' : 'out';
  }
  getFileIcons(str) {
    let ext = str.substr(str.lastIndexOf(".") + 1);
    let type = 0;
    if (ext != '') {
      type = this.gs.getFileIcon({ type: ext.toLowerCase() });
    }
    return type;
  }
  imageToolDialog(current, i, type, imgaar): void {
    let dialogRef = this.dialog.open(ImagetooldialogComponent, {
      panelClass: ['imageToolDialog'],
      width: "100%",
      data: { ArchUrl:true,event: current, index: i, imgType: type, imgArray: imgaar, customerId: this.gs.getCurrentUser()["id"] }
    });

  }
  videoShow(val): void {
    let dialogRef = this.dialog.open(VideoShowComponent, {
      panelClass: 'car-dialog-form',
      width: "800px",
      data: { name: val, url: globalConstants.S3_BUCKET_URL+globalConstants.DATA_ARCHIVE + globalConstants.RECOMMEDATION_VIDEO }
    });
  }
  invokeDownload(url,type,fileName=''){
    let localFileName = 'DownloadedFile',localNameExt,localName;
    if(type == 'ServiceRequestDocument'){
      localName = fileName.split('.');
      localNameExt = localName[localName.length-1];
      localFileName = 'ServiceRequestDocument.'+localNameExt;
    }else if(type == 'CARInspectionReport'){
      localFileName = 'CARInspectionReport.pdf';
    }
    //console.log(localFileName);
    this.gs.downloadAsset(url,localFileName);
  }
  formatDate(data){
    return this.gs.getFullFormateDate(data,'G','0000-00-00 00:00:00');
  }

  stockInHand(part){
    let q=globalFunctions.hasDecimal(part.stock_quantity,true)+' remaining in stock';
    let ouq=globalFunctions.hasDecimal(part.quantity_overused,true);
    let count=globalFunctions.hasDecimal(part.suggestion_parts_count,true);
    if(parseFloat(ouq)){
      q+=', '+ouq+' short';
    }
    if(parseFloat(count) && count){
      q+=', '+count+' replacements available';
    }else{
      q+=', No Replacements Available';
    }
    return q;
  }
}
