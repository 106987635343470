<div class="panel panel-primary">
  <div class="panel-heading padding-left-30">
    <h3 class="panel-title">Notifications Handel</h3>
    <button mat-mini-fab class="custom-btn-back" (click)="gs.goBack();">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <div class="panel-body bgcolor-lightgrey logs-detail">
    <div class="car-datatable mat-elevation-z1 employee-table">
      <div class="table-responsive relative">
      </div>
    </div>
  </div>
</div>