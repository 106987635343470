<div id="mainCompotents">
  <div class="panel panel-primary" id="dashboardQuickLinks">
    <div class="panel-heading padding-left-30">
      <h3 class="panel-title">IMPORT PARTS</h3>
      <button mat-mini-fab class="custom-btn-back" (click)="gs.goBack();">
          <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
    <div class="panel-body bgcolor-lightgrey padding-0" style="border:none;">
      <div class="container-fluid">
        <p class="text-right padding-top-5">
          <a class="hover-link hover-link-icon mat-menu-item" (click)="downloadSmapleCSV()" style="display: inline-block;" disableRipple="true">
            <i class="fa fa-download fa-2x" aria-hidden="true"></i>
            <span>Sample CSV File</span>
          </a>
          <a class="hover-link hover-link-icon mat-menu-item" (click)="exportCSV()" style="display: inline-block;" disableRipple="true">
            <i class="fa far fa-share-from-square fa-2x" aria-hidden="true"></i>
            <span>Export Parts as CSV File</span>
          </a>
        </p>
        <form enctype="multipart/form-data" class="form-inline col-xs-12 padding-bottom-15" role="form" [formGroup]="importParts"
          novalidate (ngSubmit)="importNewParts()">
          <div class="col-xs-12 padding-bottom-15">
            <h2 class="sub-heading margin-bottom-15 padding-bottom-15">UPLOAD PARTS FILE</h2>
            <div class="upload-image" id="upload-img">
              <div class="upload-image" id="upload-img">
                <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative">
                  <i class="fa fa-cloud-upload fa-2x" aria-hidden="true"></i>  Drag File Here
                  <input type="file" id="parts_csvfiles" name="parts_csv" (change)="getFile($event)" />
                  <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                </mat-toolbar>
                <span>{{seletedFileName}}</span>
              </div>
            </div>
            <div class="display-full">
              <p class="help-block" style="margin:0px;">Formats supported : csv</p>
              <p class="help-block" style="margin:0px;">Maximum size : 10MB</p>
            </div>
            <button type="submit" mat-raised-button color="accent" class="pull-left col-sm-lg">Upload</button>
          </div>
        </form>
<div class="row">
  <div class="col-sm-12">
      <strong>This function allows you to easily upload parts to create NEW Part(s).<br/>
      How to use this function:</strong>
      <ol type="1">
        <li>Please get "Sample CSV file" File Format which has predefined columns.</li>
        <li>Update the file with your parts information and note the followings:
          <ul>
            <li>DO NOT change the order of the columns or delete any columns.</li>
            <li>This function create new parts as well as update existing parts with existing Part ID (read only).</li>
            <li>Please make sure the REQUIRED fields are filled up (Marked with *) . For e.g. Part Name, Part Number, Cost, Price,
              Non Taxable, Quantity, Low Stock Value etc.</li>
            <li>For Non Taxable, mention No if part is taxable, Yes if part is non taxable.</li>
            <li>Double check your Excel file for accuracy. Upload your updated excel file by choosing the file and click on Upload
              button. Go to Bulk Upload Listing to see the status of the product.</li>
          </ul>
        </li>
        <li>Export the parts list using "Export Parts as CSV file", make the changes like part name, quantity, low stock quantity
          and save the file. Upload your updated excel file by choosing the file and click on Upload button. Go to Bulk Upload
          Listing to see the status of the product.</li>
      </ol>
  </div>
</div>
      </div>
      <div *ngIf="submitted && rerrors.length > 0" class="container-fluid" style="min-height: 250px; padding-bottom: 180px;">
        <div class="panel panel-danger">
          <div class="panel-heading">
            <h3 class="panel-title" style="text-transform: uppercase;">Parts ignored in import</h3>
          </div>
          <div class="panel-body table-responsive">
            <table class="table table-striped table-hover table-condensed">
              <thead>
                <tr>
                  <th>Part ID(read olny)</th>
                  <th>Part Name</th>
                  <th>Part number</th>
                  <th>Cost</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Low Stock Value</th>
                  <th>Failure Reason</th>
                </tr>
              </thead>
              <tbody >
                <tr *ngFor="let data of rerrors">
                  <td>{{data.part_id}}</td>
                  <td>{{data.part_name}}</td>
                  <td>{{data.part_number}}</td>
                  <td>{{data.cost}}</td>
                  <td>{{data.price}}</td>
                  <td>{{showNum(data.quantity)}}</td>
                  <td>{{showNum(data.low_stock_value)}}</td>
                  <td>{{data.failure_reason}}</td>
                </tr>
                <tr class="active" *ngIf="rerrors.length==0">
                  <td colspan="9" class="norecords-row">No records.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>