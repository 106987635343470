import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable,throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalService } from '../../../environments/global.service'

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(
    public globalService : GlobalService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error=>{
       if (error.status === 500) {
               this.globalService.snackbar('error',"Something went wrong. Please try later.");
            }
            else if (error.status === 400) {
              this.globalService.snackbar('error',error.msg);
            }
            else if (error.status === 409) {
              this.globalService.snackbar('error',error.msg);
            }
            else if (error.status === 406) {
              this.globalService.snackbar('error',error.msg);
            }
      return throwError(error);
    }))
  }
}
