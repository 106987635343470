import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {Router} from '@angular/router';
import { GlobalService } from '../../../environments/global.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public router: Router,
    public globalService : GlobalService
  ){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    var tokenData = localStorage.getItem("jwt");
    if(tokenData){
       return true
    }else{
         this.globalService.snackbar('error','You are unauthorized user , Please login again');
         localStorage.clear();
         this.router.navigate(["/shops"]);
    }
    return true;
  }
  
}
