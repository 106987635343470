import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewEncapsulation} from '@angular/core';
import { AbstractControl, FormGroup, FormControl, FormBuilder, Validators,FormArray, ValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap, NavigationEnd, Params } from '@angular/router';
import { Http, Headers, Response } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
// import { MatPaginator, MatSort, MatDatepicker } from '@angular/material';
import { globalFunctions } from "../../../../environments/globalFunctions"
import { globalConstants } from "../../../../environments/globalConstants"
import { GlobalService } from "../../../../environments/global.service"
import { lang } from "../../../../environments/lang-en"
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TextMaskModule } from 'angular2-text-mask';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsercontactsComponent } from '../../../usercontacts/usercontacts.component';
import { ImagetooldialogComponent } from '../../../imagetooldialog/imagetooldialog.component';
import {IDatePickerConfig} from 'ng2-date-picker';
import { DatePipe } from '@angular/common';
const EMAIL_REGEX = globalConstants.EMAIL_REGEX;
import * as moment from 'moment';



@Component({
  selector: 'app-editemployee',
  templateUrl: './editemployee.component.html',
  styleUrls: ['./editemployee.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe]
})
export class EditemployeeComponent implements OnInit, OnDestroy {
  public editEmployee: FormGroup;
  public currentUserDetails: any;
  public selectedCountry;
  public selectedState:any ;
  public selectedCity: any ; 
  public states: any = [];
  public cities: any = [];
  public base64value = "";
  public selectedKey = "";
  public countries: any = [];
  public phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public taxIncluded: boolean;
  public supplyChargesIncluded: boolean;
  public submitted: boolean = false;
  public currentUserInfo;
  public getUserTypes = [];
  public userInfo = '';
  public getEmailCheck;
  public imgTypes = globalConstants.FORMAT_SUPPORTED_FOR_IMAGE;
  public imgSize = globalConstants.MAX_ALLOWED_IMAGE_DISPLAYED_TEXT;
  public docType = globalConstants.FORMAT_FOR_DOC + ', pdf, ' + this.imgTypes;
  public emgContacts: any = [];
  public photo:any = '';
  public docs = [];
  public licenseDoc = [];
  public docImgPath = globalConstants.S3_BUCKET_URL + globalConstants.EMP_DOCUMENT + globalConstants.IMG_THUMB;
  public docPath = globalConstants.S3_BUCKET_URL + globalConstants.EMP_DOCUMENT;
  public today = new Date();
  public maxDate = new Date(this.today);
  public empId = '';
  public disableStates;
  public disableCities;
  public timeSlots:any = []; 
  public shopTimeLines = [];
  public hourSlots =  globalConstants.HOUR_SLOTS;
  public minSlots  =  globalConstants.MINUTES_SLOTS;
  public zipLabel = 'Zip Code';
  public addressPlaceHolder = 'Address 1 *'; //CAR-262 adding dynamic placeholder
  public addressCheckbox = true;
  public subscriptionDataForAPI: Subscription = new Subscription();
  public gc = globalConstants;
  public employeeRole : any;
  public isFromZipCode: boolean = false;
  public datePickerConfig: IDatePickerConfig = {
    format: 'MM/DD/YYYY',
    closeOnSelect: true,
    max:moment(new Date()).format("MM/DD/YYYY")
  };
  public isFutureDate :boolean = false;
  public employeeEmail: any ;
  constructor(
    private ref: ChangeDetectorRef, 
    private formBuilder: FormBuilder, 
    private route: ActivatedRoute, 
    public titleService: Title, 
    private router: Router, 
    public globalService: GlobalService, 
    public dialog: MatDialog,
    public datePipe: DatePipe
  ) {
    this.timeSlots = globalConstants.TIME_SLOTS;
    this.globalService.setMetaData("SHOPS", "EDIT_EMPLOYEE");
    let user = this.globalService.getCurrentUser();
    if (user) {
      this.currentUserInfo = user;
    } else {
      this.router.navigate(["/shops/login"]);
    }
    this.getParams();
    setTimeout(()=>{
    let isEnable=this.globalService.getAcl('employees','edits');

    if(this.empId==this.currentUserInfo.id && this.currentUserInfo.role_id!=1){
      isEnable=false;
    }
    if(!(isEnable)){     
        this.router.navigate(['shops/action/forbidden']);
    }
  },globalConstants.RELOAD_WAIT);
    this.initForm();
    
  }

  checkEmail() {

    let email = this.editEmployee.controls.email;
    if(email.value !== this.employeeEmail){
    let errorEmail = false;
    if (email.hasError('required') || email.hasError('pattern')) {
      errorEmail = true;
    }
    var opr = this.userInfo + "&email=" + email.value;
    this.subscriptionDataForAPI = (!errorEmail) && this.globalService.callAPI(globalConstants.API_CHECK_USER_INFORMATION_URL, opr).subscribe((data) => {
      this.getEmailCheck = data;

      if (this.getEmailCheck.result == "1") {
        this.editEmployee.get('email').setErrors({"alreadyExit": true});
      } else {
      }
    },(error)=>{})
    }
  }

  customerInformationPage(email) {
    this.globalService.getSetItems('userAlreadyExist', email);
    this.router.navigate(['shops/employee/list']);
  }

  beforeUpdate() {
    if (this.editEmployee.controls.dob.value) {
      let d = new Date(this.editEmployee.controls.dob.value);
      let fDate = (d.getMonth() + 1)+ '/' + d.getDate() + '/' + d.getFullYear();
      this.editEmployee.controls.date_of_birth.setValue(fDate);
    }
    // this.editEmployee.controls.state.setValue(this.getStateNameById(this.editEmployee.value.state));

    // this.editEmployee.controls.country.setValue(this.getCountryNameById(this.editEmployee.value.country));
  }
  updateEmployee() {    
    if (this.editEmployee.valid && !this.isFutureDate) {
      this.beforeUpdate();
      //var stringifiedFormData = globalFunctions.stringifyFormData(this.editEmployee.value, null);      
      var data = new FormData();
      for(let i in this.editEmployee.value){
        if(i=='weekday_timings' && Array.isArray(this.editEmployee.value[i])){
          let wTimings = this.editEmployee.value[i];
          wTimings.map((data,index)=>{
              if(!data['weekdayid']){
                data['start_time'] = "";
                data['close_time'] = "";
              }
              return data['weekdayid'] = index; 
          });
          data.append(i, JSON.stringify(wTimings));
        }else{
          data.append(i, this.editEmployee.value[i]);
        }        
      }
      let maxOverTime = Number((Number(this.editEmployee.controls.max_hour.value)*60)+Number(this.editEmployee.controls.max_min.value));
      data.append('max_overtime',maxOverTime.toString());
      this.globalService.formData(globalConstants.API_EDIT_SHOP_USER, data).subscribe((resultData:any) => {
        if (resultData.result == 1) {
          this.router.navigate(['shops/employee/list']);
          this.globalService.snackbar('success', resultData.message);
        } else {
          this.globalService.snackbar('error', resultData.message);
        }
      });
    } else {
      this.editEmployee.get('dob').markAsTouched();
      this.submitted = true;
      this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }

  }
  
  stateValidation() {
    this.globalService.validateState(this.editEmployee, ['country','state','city']);
    this.checkIfValid();
  }
  onlyNumericKey(event) {
    return globalFunctions.onlyDecimalNumberKey(event);
  }

  confirmPasswordValidator(c: AbstractControl) {
    if (c.get('password').value !== c.get('confirm_password').value) {
      c.get('confirm_password').setErrors({ "matched": true })
      return { matched: true };
    } else {
      c.get('confirm_password').valid;
    }
  }
  ngOnInit() {
    this.getCountries();
    this.userInfo = "user_id=" + this.currentUserInfo["id"] + "&user_type=" + this.currentUserInfo["user_type"] + "&shop_id=" + this.currentUserInfo["shop_id"];
    let employeeTypeDDPlaceHolder =[{
      id:'',
      name:'Select Type'
    }];
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_USER_TYPES, this.userInfo).subscribe((data:any) => {
      this.getUserTypes = employeeTypeDDPlaceHolder.concat(data.data);
    },(error)=>{});
      this.userInfo += '&emp_id=' + this.empId;
      this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_SHOP_USER, this.userInfo).subscribe((data:any) => {
            if(data.data.role_id==3){
              if(!this.isManageAdvisory()){
                this.router.navigate(['shops/action/forbidden']);
              };
            }else if(data.data.role_id==4){
              if(!this.isManageTechnician()){
                this.router.navigate(['shops/action/forbidden']);
              };
            }    
            this.employeeEmail= data.data.email;                
            this.initForm(data.data);
    });
    
    this.editEmployee.get("email").valueChanges.subscribe(email => {
      let emailRxg =  globalConstants.EMAIL_REGEX; 
      if(email == '' || emailRxg.test(email)){
      }
  })
  }

  getParams() {
    this.route.params.subscribe((params: Params) => {
      this.empId = params["emp_id"];
    });
  }
  checkIfValid() {
    if (this.editEmployee.valid) {
      this.submitted = false;
    }
  }


  enableReport(event) {
    if (event.checked) {
      this.editEmployee.controls.enable_report.setValue(1);
    } else {
      this.editEmployee.controls.enable_report.setValue(0);
    }
  }

  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  disableKey(event) {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey == true && (event.which == '67' || event.which == '86' || String.fromCharCode(event.which).toLowerCase() == 's')) {
      console.log('thou. shalt. not. PASTE!');
      event.preventDefault();
    }
  }

  
  getCountries(){
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_COUNTRIES_LIST, "opr=1").subscribe((data:any) => {
      this.countries = data.data;
      for(var i in this.countries){
        this.countries[i].id=this.countries[i].country_id;
        this.countries[i].text=this.countries[i].country_name;
      }
      this.updateZipCodeVailation();
    })
  }

  updateZipCodeVailation(){
    this.editEmployee.get("country").valueChanges.subscribe(country_name => {                
      if(country_name && Array.isArray(this.countries)){                 
         let zRegex = this.countries.find(res=>(res.country_id==country_name || res.country_name==country_name));
         if(zRegex && 'zipcode_regex' in zRegex){
             this.zipLabel = zRegex.zipcode_label;
             this.editEmployee.controls.zipcode.setValidators([Validators.required, Validators.pattern(zRegex.zipcode_regex)]);
             this.editEmployee.controls.zipcode.updateValueAndValidity();
         }          
       }                
   });
  }

  handleCountryDropdownChange(countryId){
    this.getStates(countryId);
    let countryData = this.countries.find(countryInfo => countryId == countryInfo.id);
    this.editEmployee.controls.country.setValue(countryData.country_name);
    this.editEmployee.controls.state.setValue('');
    this.editEmployee.controls.city.setValue('');
    this.selectedState = '';
    this.selectedCity = '';
  }
  handleStateDropdownChange(stateId) {
    if (stateId !== '') {
      this.getCities(stateId);
      let stateData = this.states.find(stateInfo => stateId == stateInfo.id);
      this.editEmployee.controls.state.setValue(stateData.state_name);
      this.editEmployee.controls.city.setValue('');
      this.selectedCity = 'Select City';
    } else {
      this.editEmployee.controls.state.setValue('');
      this.editEmployee.controls.city.setValue('');
      this.selectedCity = 'Select City';
    }
  }
  handleCityDropdownChange(cityName) {
    if (cityName !== 'Select City') {
      this.editEmployee.controls.city.setValue(cityName);
    } else {
      this.editEmployee.controls.city.setValue('');
    }
  }

  /**
   *
   * @param event Get State by Country Value
   */
  getStates(countryID) {
    if (typeof(countryID) == 'undefined' || countryID == "") {
        this.disableStates = true;
        this.disableCities = true;
        this.cities=[];
    } else {
        this.disableCities = true;
        this.cities=[
            { id: "", text: "Select City" }
        ];
        let stateDDLPlaceholder = [
            { 
                id: "",
                state_id: "",
                state_name: "Select State",
                country_id: "",
                latitude: "",
                longitude: "",
                tax_rate: "",
                state_code: ""
             }
        ];
        this.states = [];
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_STATES_URL, "opr=1&country_id=" + countryID).subscribe((data:any) => {
            var responseData = data;
            this.disableStates = false;
            if (responseData.result == "1") {
                this.states  = stateDDLPlaceholder.concat(responseData.data);
                for(var i in this.states){
                    this.states[i].id=this.states[i].state_id;
                    this.states[i].text=this.states[i].state_name;  
                 }
                 document.getElementById('stateId').click();
            } else {
                this.states = [];
                this.cities = [];
                this.disableStates = false;
                this.selectedState = [{ id: "", text: "No state found" }];
                this.disableCities = true;
            }
        });
    }
  }

  /**
   * Get Cities by State Id
   * @param event
   */
  getCities(stateId) {
    if (typeof(stateId) == 'undefined' || stateId == "") {
        this.cities = [];
        this.disableCities = true;
    } else {
       this.cities = [];
       let cityDDLPlaceholder=[{
        id: "",
        city_id: "",
        city_name: "Select City",
        state_id: "",
        country_id: "",
        latitude: "",
        longitude: ""
        }];
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_CITIES_URL, "opr=1&state_id=" + stateId).subscribe((data:any) => {
            var responseData = data;
            if (responseData.result == "1") {
                this.disableCities = false;
                    this.cities = cityDDLPlaceholder.concat(responseData.data);
                    for(var i in this.cities){
                        this.cities[i].id=this.cities[i].city_name;
                        this.cities[i].text=this.cities[i].city_name;          
                    }
                    document.getElementById('cityId').click();
            } else {
                this.cities = [{id: "", text: "No city found"}];
                this.disableCities = false;
            }
        })
    }
}

  /**
   * GET COUNTRY ID BY NAME
   * @param id
   */
  getCountryIDByName(id) {
    var name = ""

    for (var i = 0; i < this.countries.length; i++) {
      if (id == this.countries[i]["country_name"]) {
        name = this.countries[i]["country_id"];
        break;
      }
    }

    return name;
  }

  /**
   * GET COUNTRY ID BY NAME
   * @param id
   */
  getStateIdByName(id) {
    var name = "";

    for (var i = 0; i < this.states.length; i++) {
      if (id == this.states[i]["state_name"]) {
        name = this.states[i]["state_id"];
      }
    }

    return name;
  }

  /**
   * GET STATE NAME BY ID
   * @param id
   */
  getStateNameById(id) {
    var name = ""
    for (var i = 0; i < this.states.length; i++) {
      if (id == this.states[i]["state_id"]) {
        name = this.states[i]["state_name"];
        break;
      }
    }
    return name;
  }

  /**
   * GET COUNTRY NAME BY ID
   * @param id
   */
  getCountryNameById(id) {
    var name = ""
    for (var i = 0; i < this.countries.length; i++) {
      if (id == this.countries[i]["country_id"]) {
        name = this.countries[i]["country_name"];
        break;
      }
    }
    return name;
  }
  initForm(data?: any) {
    if (typeof data != 'undefined') {
      this.editEmployee.controls.emp_id.setValue(this.empId);
      this.editEmployee.controls.email.setValue(data.email);
      this.editEmployee.controls.first_name.setValue(data.first_name);
      this.editEmployee.controls.middle_name.setValue(data.middle_name);
      this.editEmployee.controls.last_name.setValue(data.last_name);
      this.editEmployee.controls.address_one.setValue(data.address1);
      this.editEmployee.controls.address_two.setValue(data.address2);
      this.editEmployee.controls.employee_type.setValue(data.role_id);
      this.editEmployee.controls.mobile_phone.setValue(data.cell_phone1);
      this.editEmployee.controls.country.setValue(data.country_id);
      this.editEmployee.controls.state.setValue(data.state);
      this.editEmployee.controls.city.setValue(data.city);
      this.editEmployee.controls.zipcode.setValue(data.zip);
      this.editEmployee.controls.hours_week.setValue(data.hours_week);
      this.editEmployee.controls.hours_wages.setValue(data.hours_wages);
      this.editEmployee.controls.week_wages.setValue(data.week_wages);
      this.employeeRole = data.role_name;
      this.editEmployee.controls.job_type.setValue(data.job_type==""?0:data.job_type);
      this.selfCheckinValidation(Number(data.role_id))
      if(data.is_report_generation==1){
        this.editEmployee.controls.enable_report.setValue(1);
      }else{
        this.editEmployee.controls.enable_report.setValue(0);
      } 
      if(data.hours_week && data.hours_wages){
        let val:any=(parseFloat(data.hours_wages)*parseFloat(data.hours_week));
        if(!isNaN(val)){
          this.editEmployee.controls.week_wages.setValue(val.toFixed(2));
        }
      }  

      if(data.userTimeLine && Array.isArray(data.userTimeLine)){
        let uTime = {};        
        data.userTimeLine.map((res,index)=>{ 
          if(res.is_checked==1){       
            uTime = {weekdayid:true,start_time:moment(res.start_time, ["hh:mm:ss"]).format("HH:mm"),close_time:moment(res.end_time, ["hh:mm:ss"]).format("HH:mm")}; 
            this.timings.controls[index].patchValue(uTime);
          }  
        });
        this.getShopTimeLine(true);
      }else{
        this.getShopTimeLine(false);
      }  
      
      if(Number(data.max_overtime) > 0){
        let hours   = Math.floor(Number(data.max_overtime) / 60);          
        let minutes = Number(data.max_overtime) % 60;
        this.editEmployee.controls.max_hour.setValue(hours.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}));
        this.editEmployee.controls.max_min.setValue(minutes.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}));
      }

      this.editEmployee.controls.dob.setValue(this.datePipe.transform(data.dob, 'MM/dd/yyyy'));
      this.editEmployee.controls.date_of_birth.setValue(data.dob);
      if (data.emp_photo != '') {
        this.photo = data.emp_photo;
      }
      this.docs = data.emp_docs;
      this.licenseDoc = data.emp_license;
      if(data.country_id!=''){
        this.selectedCountry = data.country_id;
      }else{
        this.selectedCountry = 'USA';
      }
      this.getStates(data.country_id);
      if(data.state_id!=''){
        this.selectedState =data.state_id;
        this.getCities(data.state_id);
        this.selectedCity = data.city;
      }
      /* If Address 1 is present then update placeholder and validation */
      if(data.address1){
        this.editEmployee.get('address_one').setValidators([Validators.required]); //Set Required Validator
        this.editEmployee.get('address_one').updateValueAndValidity();
        this.addressPlaceHolder  = 'Address 1 *';
        this.addressCheckbox = true;
      }else{
          this.editEmployee.get('address_one').clearValidators(); // Clear All Validators
          this.editEmployee.get('address_one').updateValueAndValidity();
          this.addressPlaceHolder  = 'Address 1';
          this.addressCheckbox = false;
      }
    } else {
      

      this.editEmployee = this.formBuilder.group({
        emp_id: [''],
        email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
        first_name: ['', [Validators.required, Validators.pattern(globalConstants.NAME_REGEX)]],
        middle_name: ['', [Validators.pattern(globalConstants.NAME_REGEX)]],
        last_name: ['', [Validators.required, Validators.pattern(globalConstants.NAME_REGEX)]],
        address_one: ['', Validators.required],
        address_two: [''],
        country: [''],
        state: ['',Validators.required],
        city: ['',Validators.required],
        zipcode: ['', [Validators.required, Validators.pattern("^[0-9]+$")]],
        mobile_phone: ['', [Validators.required, Validators.minLength(10), this.globalService.mobileNumberValidation]],
        dob: ['', [Validators.required]],
        date_of_birth: [''],
        employee_type: ['', [Validators.required]],
        enable_report: [''],
        hours_week: ['', Validators.required],
        hours_wages: ['', Validators.required],
        week_wages: ['', Validators.required],
        emp_photo: [''],
        driving_license: [''],
        emp_documents: [''],
        user_type: this.currentUserInfo["user_type"],
        user_id: this.currentUserInfo["id"],
        job_type:['0'],
        weekday_timings:this.formBuilder.array([]),
        selectedItems: [false,Validators.required],
        max_hour:['00'],
        max_min:['00']
      });
       
      this.timings.valueChanges.subscribe((v) => {
         this.editEmployee.controls.selectedItems.setValue(this.mapItems(v));
      });
            
      for(let i of [0,1,2,3,4,5,6]){
        this.timings.push(this.formBuilder.group({
          weekdayid  : [false],
          start_time : [''],
          close_time : [''],
          is_disable : [false]
        },{validator: this.dateLessThan('start_time', 'close_time')}));
        this.timings.controls[i].get('weekdayid').valueChanges.subscribe(res=>{
          if(res){
            this.timings.controls[i].get('start_time').setValidators([Validators.required]);
            this.timings.controls[i].get('close_time').setValidators([Validators.required]);
          }else{
            this.timings.controls[i].get('start_time').setValue("");
            this.timings.controls[i].get('close_time').setValue("");
            this.timings.controls[i].get('start_time').clearValidators();
            this.timings.controls[i].get('close_time').clearValidators();
          }
          this.timings.controls[i].get('start_time').updateValueAndValidity();
          this.timings.controls[i].get('close_time').updateValueAndValidity();
        });
      }
    }
  }


  mapItems(items) {
    let selectedItems = items.filter((item) => item.weekdayid).map((item) => item.id);
    return selectedItems.length ? selectedItems : null;
  }

  get timings(): FormArray {
		return this.editEmployee.get('weekday_timings') as FormArray;
	}

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): {[key: string]: any} => {
      let f = group.controls[from];
      let t = group.controls[to];
      if (f.value &&  t.value && (f.value > t.value || f.value == t.value)) {
        return {
          dates: "Start Time should be less than End Time"
        };
      }
      return {};
    }      
  }

  async getShopTimeLine(defaultTimeSet){
    this.userInfo = "user_id=" + this.currentUserInfo["id"] + "&user_type=" + this.currentUserInfo["user_type"] + "&shop_id=" + this.currentUserInfo["shop_id"];
    this.subscriptionDataForAPI =  await this.globalService.callAPI(globalConstants.API_GET_SHOP_TIMELINE_URL, this.userInfo).subscribe((data:any) => {
     this.shopTimeLines = data.data;
     if(Array.isArray(this.shopTimeLines) && this.shopTimeLines.length){
        this.shopTimeLines.map((data, index) =>{       
          if(data.is_checked==0){
            this.timings.controls[data.weekday_id].get('weekdayid').setValue(false);
            this.timings.controls[data.weekday_id].get('is_disable').setValue(true);
            this.timings.controls[data.weekday_id].get('weekdayid').updateValueAndValidity();
            this.timings.controls[data.weekday_id].get('start_time').setValue('');
            this.timings.controls[data.weekday_id].get('start_time').updateValueAndValidity();
            this.timings.controls[data.weekday_id].get('start_time').disable();
            this.timings.controls[data.weekday_id].get('close_time').setValue('');
            this.timings.controls[data.weekday_id].get('close_time').updateValueAndValidity();
            this.timings.controls[data.weekday_id].get('close_time').disable();
          }else if(defaultTimeSet==false){
            this.timings.controls[data.weekday_id].get('weekdayid').setValue(true);
            this.timings.controls[data.weekday_id].get('start_time').setValue(moment(data.open_time, ["hh:mm:ss"]).format("HH:mm"));
            this.timings.controls[data.weekday_id].get('close_time').setValue(moment(data.close_time, ["hh:mm:ss"]).format("HH:mm"));
          }
        });   
      }
    });
  }



  editContacts() {
    let dialogRef = this.dialog.open(UsercontactsComponent, {
      panelClass: 'car-dialog-form',
      width: "800px",
      data: { emp_id:this.empId,user_id: this.currentUserInfo.id, user_type: this.currentUserInfo.user_type,url:globalConstants.API_SHOP_EMP_CONTACTS }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.emgContacts = dialogRef['data'];
    });
  }
  uploadPicture(event, type, docType?: any) {
    let valid = this.globalService.validateFiles(type, event.target.files);
    if (valid == false) {
      this.removeFileValues();
      return false;
    }
    let data = new FormData();
    let url = globalConstants.API_SHOP_EMP_DOCS;
    let file = event.target.files[0];
    let fileType = this.globalService.getFileIcon(file);
    let reader = new FileReader();
    let docTypes = ['profile', 'document', 'license'];
    data.append('fileType', fileType.toString());
    data.append('docType', docTypes[docType]);
    data.append(type, file);
    data.append('emp_id', this.empId);
    data.append('user_id', this.currentUserInfo.id);
    data.append('user_type', this.currentUserInfo.user_type);
    this.globalService.formData(url, data).subscribe((resData:any) => {
      if (resData.result == 1) {
        if (type == 'image') {
          this.photo = resData.data;
        } else if (type == 'doc' && docType == 1) {
          resData.data.type = fileType;
          this.docs.push(resData.data);
        } else if (type == 'doc' && docType == 2) {
          resData.data.type = fileType;
          this.licenseDoc.push(resData.data);
        }
        this.globalService.snackbar('success', resData.message);
        this.ref.detectChanges();
      } else {
        this.globalService.snackbar("error", resData.message);
      }
    });
  }
  removeFileValues() {
    let file: any = document.querySelectorAll('input[type="file"]');
    if (typeof file.length != 'undefined') {
      file.length && file.forEach(function (x) {
        x.value = '';
      })
    }
  }
  delete(type, index?: any, docType?: any) {
    let val: any = [];
    if (type == 'image') {
      val = this.photo;
      this.photo = '';
    } else if (type == 'doc' && docType == 1) {
      val = this.docs.splice(index, 1);
    } else if (type == 'doc' && docType == 2) {
      val = this.licenseDoc.splice(index, 1);
    }
    let docId = 0;
    if (typeof val[0] == 'undefined') {
      docId = val.id;
    } else {
      docId = val[0].id;
    }
    let content = this.userInfo + '&delete=1&doc_id=' + docId +'&emp_id=' + this.empId;;
    this.removeFileValues();
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_SHOP_EMP_DOCS, content).subscribe((res:any) => {
      if (res.result == 1) {
        this.globalService.snackbar('success', res.message);
      } else {
        this.globalService.snackbar('error', res.message);
      }
    });
  }
  vkFloat(key){
    let data=this.editEmployee.value[key];
    let output = Number(data);
    if (isNaN(output)) {
      data=data.split('.');
      let val=(typeof data[0]!='undefined'?data[0]:'0.00');
      this.editEmployee.get(key).setValue(val);
    }else{ 
    }
    let hWeek=this.editEmployee.controls.hours_week.value;
    if(hWeek==''){
      this.editEmployee.controls.hours_wages.setValue('');
      this.editEmployee.controls.week_wages.setValue('');
    }
    let hWage=this.editEmployee.controls.hours_wages.value;
    let wWage=this.editEmployee.controls.week_wages.value;
     /*if(hWeek!='' && wWage!='' && (hWeek>0) && (key=='week_wages'||key=='hours_week')){
     let val:any=(parseFloat(wWage)/parseFloat(hWeek));
      if(!isNaN(val)){
        val=val.toFixed(2);
      }
      this.editEmployee.controls.hours_wages.setValue(val)
    }else*/
    if(hWeek!='' && hWage!='' && (key=='hours_wages'||key=='hours_week')){
      let val:any=(parseFloat(hWage)*parseFloat(hWeek));
      if(!isNaN(val)){
        val=val.toFixed(2);
      }
      this.editEmployee.controls.week_wages.setValue(val)
    }
  }
  isManageAdvisory(){
    return this.globalService.getAcl('manage_advisors','edits');
  }
  isManageTechnician(){
    return this.globalService.getAcl('manage_technicians','edits');
  }
  /* image rotation dialog  */
  imageToolDialog(current,i,type,imgaar): void {
    let dialogRef = this.dialog.open(ImagetooldialogComponent, {
        panelClass: ['imageToolDialog'],
        width: "100%",
        data: {event: current,index:i,imgType:type,imgArray:imgaar,customerId:this.globalService.getCurrentUser()["id"]}
    });

    dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
    });
  }

  invokeDownload(url,type,fileName=''){
    let localFileName = 'DownloadedFile',localNameExt,localName;
    if(type != ''){
      localName = fileName.split('.');
      localNameExt = localName[localName.length-1];
      localFileName = type+'.'+localNameExt;
    }
    //console.log(localFileName);
    this.globalService.downloadAsset(url,localFileName);
  }

  applyToAll(index){    
    for(let i of [0,1,2,3,4,5,6]){
      if(i!=index && this.timings.controls[i].get('weekdayid').value==true){
        this.timings.controls[i].get('start_time').setValue(this.timings.controls[index].get('start_time').value);
        this.timings.controls[i].get('close_time').setValue(this.timings.controls[index].get('close_time').value);
        this.timings.controls[i].get('start_time').updateValueAndValidity();
        this.timings.controls[i].get('close_time').updateValueAndValidity();
      }
    } 
  }

  /* Address Frield Validation */
  changeValidation(obj){
    if(obj.checked){
        this.editEmployee.get('address_one').setValidators([Validators.required]); //Set Required Validator
        this.editEmployee.get('address_one').updateValueAndValidity();
        this.addressPlaceHolder  = 'Address 1 *';
    }else{
        this.editEmployee.get('address_one').clearValidators(); // Clear All Validators
        this.editEmployee.get('address_one').updateValueAndValidity();
        this.addressPlaceHolder  = 'Address 1';
    }
  }

  setCountryStateCityViaZipCode(){
    let currentLoginUser = JSON.parse(localStorage.getItem("loggedInUser"));
    let payload = {
         user_id: currentLoginUser.id,
         user_type: "User",
         zip_code: this.editEmployee.controls.zipcode.value
    }
    this.subscriptionDataForAPI =  this.globalService.callAPI(globalConstants.API_GET_STATE_CITY_VIA_ZIPCODE,payload).subscribe((response:any)=>{
        if(response.result == 1){
            if(response.data.country_info.country_id !== 0 
              && response.data.state_info.state_id !== 0 
              &&  response.data.city_info.city_id !== 0 ){
                this.selectedCountry = response.data.country_info.country_code;
                this.editEmployee.controls.country.setValue(response.data.country_info.country_name);
                this.getStates(response.data.country_info.country_code);
                this.selectedState = response.data.state_info.state_id;
                this.editEmployee.controls.state.setValue(response.data.state_info.state_name);
                this.getCities(response.data.state_info.state_id);
                this.selectedCity = response.data.city_info.city_name;
                this.editEmployee.controls.city.setValue(response.data.city_info.city_name);
            }
        }
    })
  }

  selfCheckinValidation(roleId:any){
        if(roleId == 5){
          this.editEmployee.controls.mobile_phone.setValidators([Validators.minLength(10)]);
           this.editEmployee.controls.mobile_phone.updateValueAndValidity();
           this.checkMobileValid();
        }else{
          this.editEmployee.controls.mobile_phone.setValidators([Validators.required,Validators.minLength(10)]);
          this.editEmployee.controls.mobile_phone.updateValueAndValidity(); 
          this.checkMobileValid(); 
      }
  }

  checkMobileValid(){
    if(this.editEmployee.value.mobile_phone.replace(/\D+/g, "").length !== 10){
      this.editEmployee.controls.mobile_phone.setErrors({minLength:true})
    }
   }

      /* END */
      handleDOB(obj, picker) {
        this.isFutureDate = this.globalService.validateFutureDate(this.editEmployee.value.dob);
        if (typeof obj == 'undefined') {
          obj = picker.inputElementValue;
        }
        if (obj != '') {
          picker.api.moveCalendarTo(obj);
        }
      }
  ngOnDestroy(){
    if( this.subscriptionDataForAPI){
      this.subscriptionDataForAPI.unsubscribe();
    }
  }  

}
