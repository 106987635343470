<div class="popup-sidebar-wrapper">
    <mat-drawer-container class="side-panel-wrappers ticket-assignment business-report" autosize end>
        <mat-drawer #searchFilterDrawer class="ticket-sidenav" mode="over" position="end">
            <!-- header section -->
            <div class="main-heading-block">
                <h4><span>Search</span></h4>
            </div>
            <div class="border-box">
                <div class="btn-block header" >
                    <ul class="padding-0 margin-0" style="float: right;">
                        <li>
                            <button type="button" class="btn skip-btn" (click)="closeSideBar(1)" title="Submit"><i class="fa fa-check" aria-hidden="true"></i> </button>
                        </li>
                        <li>
                            <button type="button" class="btn skip-btn" (click)="clearSearch()" title="Clear"><i class="fa fa-trash" aria-hidden="true"></i></button>
                        </li>
                        <li>
                            <button type="button" class="btn skip-btn" (click)="closeSideBar()" title="Close"><i class="fa fa-times" aria-hidden="true"></i></button>
                        </li>
                    </ul>
                </div>
                <!-- <div class="cross-btn">
                    <button type="button" (click)="closeSideBar()">
                    <div class="desktop-screen">x</div>
                    </button>
                </div> -->
                <!-- content section -->
                <div class="assignment-block search-filters">
                    <form id="advanceSearchForm" class="collapse in" aria-expanded="true" [formGroup]="searchForm">
                       <div class="row padding-top-10">
                            <div class="col-sm-2 col-xs-3 filters" *ngFor="let filter of filterOption">
                                <mat-checkbox [checked]="filter.check" (change)="changeRangeSelected(filter.id)">{{filter.text}}</mat-checkbox>
                            </div>
                       </div>
                       <div class="row padding-top-10" style="padding-left: 15px;margin: 0px;">
                            <mat-error *ngIf="submited && searchForm.controls.rangeSelected.value === 0" style="margin-top:0px">
                                Please check atleast one option from above 
                            </mat-error>
                       </div>
                        <div class="row padding-top-15">
                            <div *ngIf="fillActionValue=='bwtDate'">
                                <div class="col-sm-6">
                                    <mat-form-field class="car-input btn-block car-datePicker"  floatPlaceholder="never" appearance="fill">
                                        <input (input)="dateChange($event,1)" matInput [textMask]="mask" class="form-control input-lg custom-form-input date-picker" placeholder="From Date(MM/DD/YYYY)" [(ngModel)]="fromDate" [ngModelOptions]="{standalone: true}">
                                        <input type="hidden" [max]="today" [matDatepicker]="filerPicker1" (dateChange)="updateChange($event,1)" [(ngModel)]="fromCal" [ngModelOptions]="{standalone: true}">
                                        <mat-datepicker-toggle matSuffix [for]="filerPicker1" ></mat-datepicker-toggle>
                                        <mat-datepicker #filerPicker1 [touchUi]="setTouchViewValue()"></mat-datepicker>
                                    </mat-form-field>
                                    <mat-error *ngIf="submited && fromDate == ''">
                                        From Date is <strong>required</strong>
                                    </mat-error>
                                    <mat-error *ngIf="submited && fromDateValid && fromDate != ''">
                                        From Date is <strong>Invalid</strong>. Please use <strong>MM/DD/YYYY</strong> format
                                    </mat-error>
                                </div>
                                <div class="col-sm-6">
                                    <mat-form-field class="car-input btn-block car-datePicker"  floatPlaceholder="never" appearance="fill">
                                        <input (input)="dateChange($event,2)" matInput [textMask]="mask" class="form-control input-lg custom-form-input date-picker" placeholder="To Date(MM/DD/YYYY)" [(ngModel)]="toDate" [ngModelOptions]="{standalone: true}">
                                        <input type="hidden" [min]="makeDate(fromDate)" [max]="today" [matDatepicker]="filerPicker2" (dateChange)="updateChange($event,2)" [(ngModel)]="toCal" [ngModelOptions]="{standalone: true}">
                                        <mat-datepicker-toggle matSuffix [for]="filerPicker2" ></mat-datepicker-toggle>
                                        <mat-datepicker #filerPicker2 [touchUi]="setTouchViewValue()"></mat-datepicker>
                                    </mat-form-field>
                                    <mat-error *ngIf="submited && toDate == ''">
                                        To Date is <strong>required</strong>
                                    </mat-error>
                                    <mat-error *ngIf="submited && toDateValid && toDate != ''">
                                        To Date is <strong>Invalid</strong>. Please use <strong>MM/DD/YYYY</strong> format
                                    </mat-error>                               
                                </div>
                            </div>
                        </div>
                    </form>
                    <app-customer-list [type]="type" [customerList]="customerList" (closeTab)="CustomerOutput($event)" *ngIf="type !== 'salesReport'"></app-customer-list>
                    <app-employee-list [type]="type" [employeeList]="employeeList" (closeTab)="EmployeeOutput($event)" *ngIf="type !== 'salesReport'"></app-employee-list>
                </div>
                <!-- Footer section -->
                <div class="footer search-filter-footer">
                    <div class="row">
                        <div class="col-sm-2 col-xs-12"></div>
                        <div class="col-sm-10 col-xs-12">
                            <div class="btn-block" >
                                <ul class="padding-0 margin-0" style="float: right;">
                                    <li>
                                        <button type="button" class="btn skip-btn submit" (click)="closeSideBar(1)">Submit</button>
                                    </li>
                                    <li>
                                        <button type="button" class="btn skip-btn clear" (click)="clearSearch()">Clear</button>
                                    </li>
                                    <li>
                                        <button type="button" class="btn skip-btn cancel" (click)="closeSideBar()">Close</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-drawer>
    </mat-drawer-container>
</div>
<app-search-advance-customer [type]="type" (closeTab)="searchCustomerOutput($event)" *ngIf="addCustomerSearchSideBar"></app-search-advance-customer>
<app-search-advance-employee [type]="type" (closeTab)="searchEmployeeOutput($event)" *ngIf="addEmployeeSearchSideBar"></app-search-advance-employee>