<div mat-dialog-content class="text-center">
    <p class="margin-bottom-0" [innerHTML]="message"></p>
    <ng-container [ngSwitch]="id">
        <ng-container *ngSwitchCase="'notifyAdmin'">
            <div class="m-15">
                <mat-checkbox  class="v-checkbox in-confirm" [ngModelOptions]="{standalone: true}" [(ngModel)]="markAsComplete" >Service Completion</mat-checkbox>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'open_pay'">
            <!-- <div class="m-15" *ngIf="data && data.serviceDetail && data.serviceDetail.value.request_status == 6 || data.serviceDetail.value.request_status == 8"> -->
            <div class="m-15">
                <mat-checkbox  class="v-checkbox in-confirm" [ngModelOptions]="{standalone: true}" [(ngModel)]="downloadInvoice" >Download Invoice</mat-checkbox>
                <!-- <button mat-raised-button class="font-bold mat-success" color="success" title="Download Invoice" [mat-dialog-close]="{id:id, downloadInvoice: true}">
                    <mat-icon style="font-size: 18px; margin-top:3px;">cloud_download</mat-icon>
                    Download Invoice
                </button> -->
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'bulkOprConsent'">
            <div class="m-15">
                <mat-checkbox  class="v-checkbox in-confirm" [ngModelOptions]="{standalone: true}" [(ngModel)]="bulkOprConsent" (change)="handleBulkOprConsent()">Yes, I agree</mat-checkbox>
                <mat-error *ngIf="displayErrMsg" class="validation-error"> This field is required.</mat-error>
            </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
        </ng-container>
    </ng-container>
</div>
<div class="service-future-date" mat-dialog-actions *ngIf="id != 'confirm'">
    <ng-container [ngSwitch]="id">
        <ng-container *ngSwitchCase="'select_quick_appt'">
            <button class="mat-button light-blue" (click)="dialogRef.close('tech_assign')">{{labelNo}}</button>
            <button class="mat-button light-pink" (click)="dialogRef.close('customer_appt')">{{labelYes}}</button>
            <button class="mat-button cgray" (click)="dialogRef.close('reminder')">{{labelRm}}</button>
            <button class="mat-button dark-blue" (click)="dialogRef.close('leave')">Add Leave</button>
        </ng-container>
        <ng-container *ngSwitchCase="'taskTrackConfirm'">           
            <button class="mat-button cgray" (click)="dialogRef.close('2')">{{labelYes}}</button>
            <button class="mat-button" (click)="dialogRef.close('3')">{{labelRm}}</button>
            <button class="mat-button" style="background-color: #2e2b37;" (click)="dialogRef.close()">{{labelNo}}</button>
        </ng-container>
        <ng-container *ngSwitchCase="'taskTrackConfirmStop'">           
            <button class="mat-button cgray" *ngIf="data && data.status!='2'" (click)="dialogRef.close('2')">{{labelYes}}</button>
            <button class="mat-button" style="background-color: #f50057;" *ngIf="data && data.status!='3'" (click)="dialogRef.close('3')">{{labelRm}}</button>
            <button class="mat-button" style="background-color: #2e2b37;" (click)="dialogRef.close()">{{labelNo}}</button>
        </ng-container>
        <ng-container *ngSwitchCase="'ask_vehicle_ins'"> 
            <button class="mat-button" (click)="dialogRef.close('skip')">{{labelNo}}</button>
            <button class="mat-button" [mat-dialog-close]="'yes'">{{labelYes}}</button>
            <button class="mat-button" (click)="dialogRef.close('close')" style="background-color: #2e2b37;">{{'Close'}}</button>
        </ng-container>
        <ng-container *ngSwitchCase="'notifyAdmin'">
            <button class="mat-button" (click)="dialogRef.close()">{{labelNo}}</button>
            <button class="mat-button" [mat-dialog-close]="{id:id, markAsComplete: markAsComplete}">{{labelYes}}</button>
        </ng-container>
        <ng-container *ngSwitchCase="'bulkOprConsent'">
            <button class="mat-button" (click)="dialogRef.close()">{{labelNo}}</button>
            <button class="mat-button" (click)="processPartsBulkOperation()" >{{labelYes}}</button>
        </ng-container>
        <ng-container *ngSwitchCase="'open_pay'">
            <button class="mat-button" (click)="dialogRef.close({downloadInvoice: downloadInvoice})">{{labelNo}}</button>
            <button class="mat-button" [mat-dialog-close]="{id:id, downloadInvoice: downloadInvoice}">{{labelYes}}</button>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <button class="mat-button" (click)="dialogRef.close()">{{labelNo}}</button>
            <button class="mat-button" [mat-dialog-close]="id">{{labelYes}}</button>
        </ng-container>
    </ng-container>
</div>
<div mat-dialog-actions *ngIf="id == 'confirm'">
    <button class="mat-button" mat-dialog-close style='background: #f50057'>Ok</button>
</div>