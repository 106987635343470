<div mat-dialog-title class="relative limit-width">
  <span>Needed By</span>
  <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
    <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>
<form class="form-horizontal form-control-normal" (ngSubmit)="editSRdetails()" autocomplete="off" [formGroup]="SRForm">
  <mat-dialog-content class="ticket_dialog_content">
      <div class="display-full margin-bottom-5">
        <div class="col-md-6">
          <label> Needed By: </label>
          <div class="vlio-datepicker relative">
            <dp-date-picker mode="daytime" [config]="datePickerConfig" theme="dp-material dp-main" class="btn-block"
            (onChange)="checkIfValid();" formControlName="event_needed_date" #dayPicker></dp-date-picker>
            <button type="button" mat-icon-button (click)="dayPicker.api.open();">
              <mat-icon>date_range</mat-icon>
            </button>
          </div>
        </div>
      </div>
  </mat-dialog-content>
  <mat-dialog-actions class="inquiry-action relative">
    <button type="submit" mat-raised-button color="accent" title="Save" [disabled]="submitDisabled"> Save and Continue</button>
    <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
  </mat-dialog-actions>
</form>