import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-subscription-plans-dialog',
  templateUrl: './subscription-plans-dialog.component.html',
  styleUrls: ['./subscription-plans-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubscriptionPlansDialogComponent implements OnInit {
  public subscriptionPlanList: any = [];
  public selectedPlan: any = null;
  public plansList: any =  [];

  // Define Owl Carousel options
  public customOptions: OwlOptions = {
    loop: false,
    margin: 10,
    nav: true,
    startPosition: 1,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      }
    }
  };
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SubscriptionPlansDialogComponent>,
  ) {
    this.subscriptionPlanList = data;
    if (this.subscriptionPlanList.length > 0) {
      for (let i = 0; i < this.subscriptionPlanList.length; i++) {
        this.subscriptionPlanList[i].checked = false;
        this.subscriptionPlanList[i].featuresIncluded = JSON.parse(this.subscriptionPlanList[i].features_included);
        this.subscriptionPlanList[i].extrasInfo = JSON.parse(this.subscriptionPlanList[i].extras);
      }

      var sourcePlanList: any = JSON.parse(JSON.stringify(this.subscriptionPlanList));
      var valuePlanInfo :any = sourcePlanList.filter(function (plan) {
        let planInfo = (plan.plan_type == 1 && plan.plan_type_group == 1);
        return planInfo;
      });
      var standardPlanInfo :any = sourcePlanList.filter(function (plan) {
        let planInfo = (plan.plan_type == 1 && plan.plan_type_group == 2);
        return planInfo;
      });
      this.plansList.push(valuePlanInfo[0]);
      this.plansList.push(standardPlanInfo[0]);
    }
  }

  ngOnInit(): void {
  }

  handleSubscriptionSelection(subscription) {
    this.selectedPlan = subscription;
    this.dialogRef.close({
      status: 1,
      selectedSubscription: this.selectedPlan
    });
  }

  handlePlanSwitch(event, sub, index) {
    var sourcePlanList :any = JSON.parse(JSON.stringify(this.subscriptionPlanList));
    if (index == 0) {
      var planType = event.checked ? 2 : 1;
      var valuePlanInfo: any = sourcePlanList.filter(function (plan) {
        let planInfo = (plan.plan_type == planType && plan.plan_type_group == 1);
        return planInfo;
      });
      this.updatePlanDetails(index, valuePlanInfo[0]);
    } else {
      var planType = event.checked ? 2 : 1;
      var standardPlanInfo: any = sourcePlanList.filter(function (plan) {
        let planInfo = (plan.plan_type == planType && plan.plan_type_group == 2);
        return planInfo;
      });
      this.updatePlanDetails(index, standardPlanInfo[0]);
    }
  }

  updatePlanDetails(index,subscriptionData){
      Object.assign(this.plansList[index],subscriptionData); 
   }
}
