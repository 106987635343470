import {Component, ElementRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {Router, ActivatedRoute, ParamMap, NavigationEnd, Params} from '@angular/router';
import {Location, LocationStrategy, PathLocationStrategy, DatePipe} from '@angular/common';
import {DataSource, SelectionModel} from '@angular/cdk/collections';
// import {MatPaginator, MatSort, MatDatepicker, NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS, MatTab} from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatTab } from '@angular/material/tabs';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {globalFunctions} from "../../../../environments/globalFunctions"
import {globalConstants} from "../../../../environments/globalConstants"
import {GlobalService} from "../../../../environments/global.service"
import {Http, Headers, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Title} from '@angular/platform-browser';
import {lang} from "../../../../environments/lang-en"
import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import {AppComponent} from '../../../app.component';
const HISTORY_LIST = "service-history-list-";

export class AppDateAdapter extends NativeDateAdapter {
    parse(value: any): Date | null {
        if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
            const str = value.split('/');
            const year = Number(str[2]);
            const month = Number(str[1]) - 1;
            const date = Number(str[0]);
            return new Date(year, month, date);
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }

    format(date: Date, displayFormat: Object): string {
        if (displayFormat == "input") {
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            return this._to2digit(month)+ '/' + this._to2digit(day)  + '/' + year;
        } else {
            return date.toDateString();
        }
    }

    private _to2digit(n: number) {
        return ('00' + n).slice(-2);
    }
}

export const APP_DATE_FORMATS =
{
    parse: {
        dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
    },
    display: {
        // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
        dateInput: 'input',
        monthYearLabel: { month: 'short', year: 'numeric', day: 'numeric' },
        dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
        monthYearA11yLabel: {year: 'numeric', month: 'long'},
    }
}

@Component({
    selector: 'app-list-service-request-history',
    templateUrl: './list-service-request-history.component.html',
    styleUrls: ['./list-service-request-history.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [MatTab, DatePipe,
        {
            provide: DateAdapter, useClass: AppDateAdapter
        },
        {
            provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
        }
    ]
})
export class ListServiceRequestHistoryComponent {
    displayedColumns = ['request_id','title', 'vehicle', 'appointment_date', 'completion_date', 'car', 'status' ,'actions'];
    dataSource: ExampleDataSource | null;
    public currentCustomer;
    public schedule_service_option='';
    public requested_delivery_option='';
    public estimated_delivery_option='';
    public more_rows;
    public carValue;
    public openHistorySearch: boolean = false;

    public options = {
        customer_id: "",
        car_id: "",
        status: "",
        search_keyword: "",
        start: 0,
        limit: 10,
        schedule_service_date: "",
        requested_delivery_date: "",
        estimated_delivery_date: ""
    }
    //public statuses = ['Created', 'Pending', 'Processing', 'Waiting for Approval', '', '', 'Completed', 'Approval Sent', 'Completed And Paid', 'Created', 'Created'];
    public statuses = ['Appointment', 'Pending', 'Processing', 'Approval Awaited', '', '', 'Completed', 'Approval Sent', 'Completed And Paid', 'Created', 'Created'];
    //exampleDatabase = new ExampleDatabase(this.http,this.options);

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    constructor(private router: Router, private activatedRoute: ActivatedRoute, private app: AppComponent, private http:Http, public titleService: Title, public globalService: GlobalService){
        if (localStorage.getItem("loggedInCustomer")) {
            this.currentCustomer = JSON.parse(localStorage.getItem("loggedInCustomer"));
            this.options.customer_id = this.currentCustomer.id;
            this.reinitializeOptions();
            this.activatedRoute.params.subscribe((params: Params) => {
                this.carValue = params["car_id"];
            });
            this.options.car_id = this.carValue;
            if (this.options.search_keyword == "" && this.options.schedule_service_date == "" && this.options.requested_delivery_date == "" && this.options.estimated_delivery_date == "") {
                this.openHistorySearch = false;
            }
            else {
               this.openHistorySearch = true;
            }

        } else {
            this.router.navigate(["/customer"]);
        }
        this.globalService.setMetaData("CUSTOMER","SERVICE_REQUESTS_HISTORY")
    }

    setTouchViewValue(){
        return this.app.touchViewState;
    }
    ConvertToNumber(str){ return parseInt(str)}
    getStatus(statusId) {
      return this.statuses[statusId]
    }

    formatDate(date) {
      if (!new Date(date) || date == null || date == "0000-00-00 00:00:00") { return "--" }
      return (new Date(date).getMonth()+1) + "/" + new Date(date).getDate() + "/" + new Date(date).getFullYear()
    }

    ngOnInit() {
        //this.titleService.setTitle(lang.TITLE_CUSTOMER_LIST_REQUEST);
        this.reinitializeOptions();
        this.setDataTable();
        //this.dataSource = new ExampleDataSource(new ExampleDatabase(this.http,this.options),this.sort, this.paginator);
   }

    /***Check service request*****/
    isAnyOptionActive(button) {       
        if(this.schedule_service_option!='' || this.requested_delivery_option!='' ||  this.estimated_delivery_option!=''){
            return true;
        }
        if (this.options.search_keyword == "" && this.options.schedule_service_date == "" && this.options.requested_delivery_date == "" && this.options.estimated_delivery_date == "") {
            if (button) 
            // {button.color = "primary"}
            return false;
        }
        else {
            if (button) 
            // {button.color = "accent"}
            return true;
        }
    }

    dateFormatWithoutTime(dateValue) {
       return ((new Date(dateValue).getMonth()+1) < 10 ? '0' : '')+(new Date(dateValue).getMonth()+1) + "/" + (new Date(dateValue).getDate() < 10 ? '0' : '')  + new Date(dateValue).getDate() + "/" + new Date(dateValue).getFullYear();
    }
    /***end Check service request*****/
    search(event) {
        if (this.schedule_service_option!="" && this.schedule_service_option != null) {
            //let schedule_date =  new Date(this.schedule_service_option).getMonth()+1 + "/" + new Date(this.schedule_service_option).getDate() + "/" + new Date(this.schedule_service_option).getFullYear();
            let schedule_date =  this.dateFormatWithoutTime(this.schedule_service_option);
            this.options.schedule_service_date = schedule_date;
        }else{
            this.options.schedule_service_date = "";
        }
        if (this.requested_delivery_option!="" && this.requested_delivery_option != null) {
            //let request_delivary = (new Date(this.requested_delivery_option).getMonth()+1) + "/" + new Date(this.requested_delivery_option).getDate() + "/" + new Date(this.requested_delivery_option).getFullYear();
            let request_delivary = this.dateFormatWithoutTime(this.requested_delivery_option);
            this.options.requested_delivery_date = request_delivary;
        }else{
            this.options.requested_delivery_date = "";
        }
        if (this.estimated_delivery_option!="" && this.estimated_delivery_option != null) {
            //let estimated_delivary = (new Date(this.estimated_delivery_option).getMonth()+1) + "/" + new Date(this.estimated_delivery_option).getDate() + "/" + new Date(this.estimated_delivery_option).getFullYear();
            let estimated_delivary = this.dateFormatWithoutTime(this.estimated_delivery_option);
            this.options.estimated_delivery_date = estimated_delivary;
        }else{
            this.options.estimated_delivery_date = "";
        }
        this.setDataTable();
        //this.dataSource = new ExampleDataSource( new ExampleDatabase(this.http,this.options),this.sort, this.paginator);
    }

    scrolling() {
        this.globalService.setScrolling('.car-datatable', 1000, -20);
    }

    paginateByLimit(event) {
        this.globalService.setScrolling('body', 600, 0);
        this.options.limit = event.pageSize;
        this.options.start = 0;
        this.scrolling();
        this.setDataTable();
    }

    clearSearch() {
        this.openHistorySearch = false;
        this.schedule_service_option='';
        this.requested_delivery_option='';
        this.estimated_delivery_option='';
        this.globalService.localGetterSetter(null,this.options,HISTORY_LIST);
        this.reinitializeOptions();
        this.setDataTable();
    }

    reinitializeOptions() {
        this.options = this.globalService.localGetterSetter(this.options, this.options, HISTORY_LIST);
        this.options.customer_id = this.currentCustomer.id;
        this.options.car_id = this.carValue;
    }

    getTotalRecords() {
        return parseInt(localStorage.getItem(HISTORY_LIST + 'totalRecords'));
    }

    paginate(event, type) {
        this.scrolling();
        if (type == "next") {
            this.options.start = this.options.start + this.options.limit;

        } else {
            this.options.start = this.options.start - this.options.limit;
        }
        this.setDataTable();
    }


    firstList() {
        return this.globalService.firstList(HISTORY_LIST, this.options);
    }

    lastList() {
        return this.globalService.lastList(HISTORY_LIST);
    }

    getUpperLimit(currentLastLimit, total) {
        if (currentLastLimit > total) {
            return total
        } else {
            return currentLastLimit
        }
    }

    viewHistoryDetail(historyId){
        this.globalService.localGetterSetter(this.options, null, HISTORY_LIST);
        this.router.navigate(["/customer/service-history-view", historyId]);
    }

    setDataTable() {
        this.dataSource = new ExampleDataSource(new ExampleDatabase(this.http, this.options, this.globalService, this.statuses), this.sort, this.paginator);
    }
    /* STATUS COLOR */
    getRowStatusColor(status) {
        var statusArr = ['active-cell', 'warning-cell', 'info-cell', 'success-cell', 'info-cell', 'success-cell', 'success-completed', 'success-cell', 'success-paid', '', '', '', 'work-progress-cell'];
        return statusArr[status];
    }
    getFormatDate(value){
        return this.globalService.getFullFormateDate(value,'G','H');
    }
}

export interface UserData {
    color: string;
    vehicle: string;
    estimated_delivery_date: string;
    requested_delivery_date:string;
    appointment_date: string;
    request_id: string;
    status: string;
    title: string;
    car: string;
    serviceId: string;
}

/** An example database that the data source uses to retrieve data for the table. */
export class ExampleDatabase {
    public total=100;
    public options = {
        customer_id: "",
        car_id: "",
        status: "",
        search_keyword: "",
        start: 0,
        limit: 10,
        schedule_service_date: "",
        requested_delivery_date: "",
        estimated_delivery_date: ""
    }

    dataChange: BehaviorSubject<UserData[]> = new BehaviorSubject<UserData[]>([]);
    public recordsTotal = "";
    get data(): UserData[] { return this.dataChange.value; }
    getTotalRecords() { return this.recordsTotal }
    constructor(public http: Http, options, private globalService: GlobalService, public statuses) {
        this.options = options;
        var content = globalFunctions.stringifyFormData(this.options, null);
        var callApi = this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUESTS_HISTROY_URL, content);
        callApi.subscribe((data:any)=>{
            if (data.data != undefined) {
                let moreRows = 'false';
                let firstList = 'false';
                this.globalService.localGetterSetter("totalRecords", data.recordsTotal, HISTORY_LIST);
                if (data.more_rows == "true") {
                    moreRows = "true";
                }
                this.globalService.localGetterSetter("more_rows", moreRows, HISTORY_LIST);
                if (data.start >= 0 && data.start <= 5) {
                    firstList = "true";
                }
                this.globalService.localGetterSetter("first_list", firstList, HISTORY_LIST);

                if (data.data.length != undefined) {
                    this.recordsTotal = data.data.length;
                    for (var i = 0; i < data.data.length; i++) {
                        this.AddRecord(data.data[i])
                    }
                } else {
                    this.recordsTotal = "1";
                    this.AddRecord(data.data)
                }
            } else {
                this.globalService.localGetterSetter("totalRecords", '0', HISTORY_LIST);
            }
        })
    }

    AddRecord(record) {
      const copiedData = this.data.slice();
      copiedData.push(this.createRecordrecord(record));
      this.dataChange.next(copiedData);
    }

    private createRecordrecord(record) {
        return {
            color: record.color,
            vehicle: (record.make ? record.make + " ":"") + record.model,
            estimated_delivery_date: this.globalService.getFullFormateDate(record.estimated_delivery_date,'G','H'),
            appointment_date: this.globalService.getFullFormateDate(record.add_date,'G','H'),
            serviceId: record.id,
            requested_delivery_date:this.globalService.getFullFormateDate(record.requested_delivery_date,'G','H'),
            request_id: record.request_id,
            status: this.statuses[record.status],
            title: record.title,
            car: (record.status == '9' || record.status == '10') ? 'No' : 'Yes',
            check_status: record.status
        };
    }
}

export class ExampleDataSource extends DataSource<any> {
    constructor(private _exampleDatabase: ExampleDatabase, private _sort: MatSort, private _paginator: MatPaginator) {
        super();
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<UserData[]> {
        const displayDataChanges = [
            this._exampleDatabase.dataChange,
            this._paginator.page,
            this._sort.sortChange,
        ];

        return Observable.merge(...displayDataChanges).map(() => {
            let data = this._exampleDatabase.data.slice();

            // sort if needed
            if (this._sort.active && this._sort.direction !== '') {
              const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
              return this.getSortedData().splice(startIndex, this._paginator.pageSize);;
            }
            const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            return data.splice(startIndex, this._paginator.pageSize);
        });
    }

    getSortedData(): UserData[] {
        const data = this._exampleDatabase.data.slice();
        if (!this._sort.active || this._sort.direction == '') { return data; }

        return data.sort((a, b) => {
            let propertyA;
            let propertyB;

            switch (this._sort.active) {
                case 'request_id': propertyA=a.request_id; propertyB =  b.request_id; break;
                case 'title': propertyA=a.title; propertyB =  b.title; break;
                case 'vehicle': propertyA=a.vehicle; propertyB =  b.vehicle; break;
                case 'appointment_date': propertyA=new Date(a.appointment_date); propertyB =  new Date(b.appointment_date); break;
                case 'completion_date': propertyA=new Date(a.estimated_delivery_date); propertyB =  new Date(b.estimated_delivery_date); break;
                case 'car': propertyA=a.car; propertyB =  b.car; break;
                case 'status': propertyA=a.status; propertyB =  b.status; break;
                default: return 0;
            }

            let valueA = isNaN(propertyA) ? propertyA.toUpperCase(): propertyA;
            let valueB = isNaN(propertyB) ? propertyB.toUpperCase(): propertyB;

            return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
        });
    }

    disconnect() {}
}
