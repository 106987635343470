import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';

@Component({
  selector: 'app-qq-searchcar',
  templateUrl: './qq-searchcar.component.html',
  styleUrls: ['./qq-searchcar.component.scss']
})
export class QQSearchcarComponent implements OnInit {

  public currentUserInfo;
  status;
  customerVehcileInfo;
  shopCustomerDetails;
  currentCustomer;
  searchBy;  
  constructor(public dialogRef: MatDialogRef<QQSearchcarComponent>,@Inject(MAT_DIALOG_DATA) public data: any, public globalService: GlobalService) {
    this.status = data.status;
    this.searchBy = data.searchBy;
    this.customerVehcileInfo = data.customerVehcileInfo;
    this.shopCustomerDetails = data.shopCustomerDetails;
    this.currentCustomer = data.currentCustomer;
    if(localStorage.getItem("loggedInUser")) {
      var loginUser :any = localStorage.getItem("loggedInUser")
      this.currentUserInfo = JSON.parse(loginUser);
    }
  }

  ngOnInit() { } 

  createSR(){
    let opr = "user_type=" + this.currentCustomer.user_type + "&user_id="+this.currentCustomer.id+"&vehicle_id="+this.customerVehcileInfo.vehicle_info.vehicle_id +"&customer_id=" + this.customerVehcileInfo.vehicle_info.customer_id;
    this.globalService.callAPI(globalConstants.API_TEMP_VEHICLE_MAPPING, opr).subscribe((data:any) => {    
      if(data && 'result' in data && data.result == 1){       
        this.dialogRef.close({data:{customer:data.customer,vehicle:data.vehicle}});
      }else if('message' in data && data.message){
        this.globalService.snackbar("error", data.message);
      }
    });

  }

  createSRForAnotherUser(){ 
    let opr = "user_type=" + this.currentCustomer.user_type + "&user_id="+this.currentCustomer.id+"&vehicle_id="+this.customerVehcileInfo.vehicle_info.vehicle_id +"&customer_id=" + this.shopCustomerDetails.id;
    this.globalService.callAPI(globalConstants.API_TEMP_VEHICLE_MAPPING, opr).subscribe((data:any) => {      
      if(data && 'result' in data && data.result == 1){     
        this.dialogRef.close({data:{customer:data.customer,vehicle:data.vehicle}});
      }else if('message' in data && data.message){
        this.globalService.snackbar("error", data.message);
      }
    });
  }

  
}
