<div mat-dialog-title class="relative">
    <h4 class="margin-top-0">Customer Already Exists ?</h4>
    <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
        <mat-icon class="close-icon-lg">close</mat-icon>
      </button>
</div>
<mat-dialog-content class="overflow-data exist-customer-popup">
    <div class="center-block" style="max-width: 600px;">
        <form class="form-horizontal form-control-normal">
            <div class="customer-search">
                <div class="row margin-bottom-20 display-full">
                    <div class="col-xs-4">
                        <div class="field-name">
                            Name
                        </div>
                    </div>
                    <div class="col-xs-8">
                        <div class="field-name" *ngIf="customerDetails">
                            {{customerDetails.first_name}} {{customerDetails.last_name}}
                        </div>
                    </div>
                </div>
                <div class="row margin-bottom-20 display-full">
                    <div class="col-xs-4">
                        <div class="field-name">
                            Email address
                        </div>
                    </div>
                    <div class="col-xs-8">
                        <div class="field-name email-address" *ngIf="customerDetails">
                            <a class="text-black" href="mailto:{{customerDetails.email}}">
                                {{customerDetails.email}}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="row margin-bottom-20 display-full">
                    <div class="col-xs-4">
                        <div class="field-name">
                            Address
                        </div>
                    </div>
                    <div class="col-xs-8">
                        <div class="field-name" *ngIf="customerDetails">
                            <span>{{customerDetails.address1}}</span>
                            <span class="inline-display" *ngIf="customerDetails.city">{{customerDetails.city}},</span>
                            <span class="inline-display"> {{customerDetails.state}}</span>
                            <span>{{customerDetails.zip}}</span>
                        </div>
                    </div>
                </div> 
                <div class="row margin-bottom-20 display-full">
                    <div class="col-xs-4">
                        <div class="field-name">
                            Mobile
                        </div>
                    </div>
                    <div class="col-xs-8">
                        <div class="field-name" *ngIf="customerDetails">
                            <a class="text-black" href="tel:{{customerDetails.mobile_phone}}">
                                {{customerDetails.mobile_phone}}                            </a>
                        </div>
                    </div>
                </div>  
                <!-- <div class="row row display-full">
                    <div class="col-sm-12 col-xs-12 small">
                        <div class="row">
                            <div class="col-xs-2">
                                <button type="button" [routerLink]="['/shops/view-customer/',customerDetails.customer_id]" mat-dialog-close class="close-modal btn fontcolor-white mat-raised-button mat-accent">View</button>
                            </div>
                            <div class="col-xs-4">
                                <button type="button" [routerLink]="['/shops/new-service-request',customerDetails.customer_id]" mat-dialog-close  class="close-modal btn fontcolor-white mat-raised-button mat-accent">Create Service Request</button>
                            </div>
                            <div class="col-xs-3">
                                <button type="button" mat-dialog-close  class="close-modal btn fontcolor-white mat-raised-button mat-accent" (click)="viewVehicle()">View Vehicles</button>
                            </div>
                            <div class="col-xs-3">
                                <button type="button" mat-dialog-close class="close-modal btn blue-btn">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
    <div class=" display-full">
        <div class="button-section">
            <ul class="padding-0 margin-0">
                <li class="margin-left-0">
                    <button type="button" [routerLink]="['/shops/view-customer/',customerDetails.customer_id]" [mat-dialog-close]="true" class="close-modal btn fontcolor-white mat-raised-button mat-accent">View</button>
                </li>
                <li *ngIf="customerDetails && customerDetails.is_sublet == '0'">
                    <button type="button" [routerLink]="['/shops/new-service-request',customerDetails.customer_id]" [mat-dialog-close]="true" class="close-modal btn fontcolor-white mat-raised-button mat-accent"><span class="hidden-xs">Create Service Request</span><span class="visible-xs">Create SR</span></button>
                </li>
                <li *ngIf="customerDetails && customerDetails.is_sublet == '1'">
                    <button type="button" [routerLink]="['/shops/new-service-request',customerDetails.customer_id]" [mat-dialog-close]="true" class="close-modal btn fontcolor-white mat-raised-button mat-accent">Create Sublet Service Request</button>
                </li>
                <li>
                    <button type="button" [mat-dialog-close]="true" class="close-modal btn fontcolor-white mat-raised-button mat-accent" (click)="viewVehicle()">View Vehicles</button>
                </li>
                <li>
                    <button type="button" mat-raised-button mat-dialog-close class="close-modal font-bold btn blue-btn">Cancel</button>
                </li>
            </ul>
        </div>
    </div>
</mat-dialog-actions>

