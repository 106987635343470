import { Component, OnInit } from '@angular/core';
import { globalConstants } from '../../../environments/globalConstants';
import { GlobalService } from '../../../environments/global.service';
import { Router } from '@angular/router';
import { globalFunctions } from 'src/environments/globalFunctions';
@Component({
  selector: 'app-mailverification',
  templateUrl: './mailverification.component.html',
})
export class MailverificationComponent implements OnInit {
  public message='';
  public thanksMsg :any=null;
  public alreayLogin=false;
  constructor(  private router: Router,private globalService: GlobalService) { }

  ngOnInit() {
    var params = JSON.parse(this.globalService.getSetItems("shop-confirm-data"))
    this.globalService.callAPI(globalConstants.API_CONFIRM_SHOP_SIGNUP,params).subscribe((data:any)=>{
      if(data.result=="1"){
        this.thanksMsg=true;
      }else{
        this.thanksMsg=false;
      }
      this.message=data.message;
    });
    if(this.globalService.getCurrentUser()){
      this.alreayLogin=true;
    }
  }
  login(){
    if(this.alreayLogin){
        globalFunctions.controlDashboardNavigation();
    }else{
      this.router.navigate(["/shops"]);
    }
  }

}
