<div mat-dialog-title class="relative" *ngIf=" getCurrentRoute() =='/customer/dashboard'">
    Registration/Insurance Expiration
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
        <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
</div>
<!-- BODY -->
<mat-dialog-content>
    <div class="center-block" style="padding: 0px 0 10px;">
<!--        <ul *ngIf="popupType==true" class="list-inline">
            <li *ngIf="dayDiff!=undefined">{{dayDiff}}.</li>
        </ul>-->
        <ul class="list-inline-none">
            <li *ngIf="popupType==true">
                <p *ngIf="dayDiff!=undefined"> {{dayDiff}}.</p>
            </li>
            <li *ngFor="let details of currentCustomerVehicles" >
                <p *ngIf="(details.registration_exipiring_type.indexOf(0) != -1) || (details.registration_exipiring_type.indexOf(30) != -1) || (details.registration_exipiring_type.indexOf(7) != -1)">
                     Your vehicle<span *ngIf="details.name==''">'s</span> <strong *ngIf="details.name !=''">{{details.name}}'s</strong> {{details.regdaysDiffmsg}}.
                </p>
                <p *ngIf="(details.insurance_exipiring_type.indexOf(0) != -1) || (details.insurance_exipiring_type.indexOf(30) != -1) || (details.insurance_exipiring_type.indexOf(7) != -1)">
                     Your vehicle<span *ngIf="details.name==''">'s</span> <strong *ngIf="details.name !=''">{{details.name}}'s</strong> {{details.insdaysDiffmsg}}.
                </p>
            </li>

        </ul>
    </div>
</mat-dialog-content>

<!-- END BODY -->
<mat-dialog-actions class="inquiry-action relative">
    <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
</mat-dialog-actions>
