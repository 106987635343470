import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GlobalService } from '../../environments/global.service';
import { globalConstants } from '../../environments/globalConstants';
import { Http } from '@angular/http';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {trigger, state, style, animate, transition, group, sequence} from '@angular/animations';
import { FillRecommendationComponent } from '../shop/shopservicerequest/fill-recommendation/fill-recommendation.component';
import { ViewServicerequestComponent } from '../shop/shopservicerequest/view-servicerequest/view-servicerequest.component';
import { AddDiscountComponent } from './../shop/discounts/add-discount/add-discount.component';
// import { ViewRequestComponent } from '../customer/service-request/view-request/view-request.component';

export interface Condition{
  type:string;
  root?:boolean,
  removed?:boolean,
  aggregator?:string,
  operator?:string,
  value?:string,
  conditions?:Array<Condition>, 
  invalid?:boolean,
  ngModel?:any
}

@Component({
  selector: 'app-redeem-coupon',
  templateUrl: './redeem-coupon.component.html',
  styleUrls: ['./redeem-coupon.component.scss'], 
  animations:[
    trigger('applyCoupon',[
      state('in', style({ 'height': '*', overflow: 'hidden', opacity:'1', 'margin-top':'{{ mt }}px' }), {params: {mt:'10'}}),
      state('out', style({ 'height': '0px', overflow: 'hidden', opacity:'0', 'margin-top':'{{ mt }}px' }), {params: {mt:'0'}}),
      transition('in => out', [group([
        animate('300ms ease-in-out', style({ 'height': '0px', overflow: 'hidden', opacity:'0', 'margin-top':'{{ mt }}px'})),
      ])]),
      transition('out => in', [group([
        animate('300ms ease-in-out', style({ 'height': '*', overflow: 'hidden', opacity:'1', 'margin-top':'{{ mt }}px'})),
      ])]),
    ]),
    trigger('addRemoveCoupon',[
      // transition(':enter', [group([
      //   animate('300ms ease-in-out', style({ 'height': '*', opacity:'1'})),
      // ])]),
      // transition(':leave', [group([
      //   animate('300ms ease-in-out', style({ 'height': '0px', opacity:'0'})),
      // ])])
      transition('* => void', [
        style({ height: '*', opacity: '1', transform: 'translateX(0)'}),
        sequence([
          animate(".25s ease", style({ height: '*', opacity: '.2', transform: 'translateX(20px)'})),
          animate(".1s ease", style({ height: '0', opacity: 0, transform: 'translateX(20px)'}))
        ])
      ]),
      transition('void => active', [
        style({ height: '0', opacity: '0', transform: 'translateX(20px)'}),
        sequence([
          animate(".1s ease", style({ height: '*', opacity: '.2', transform: 'translateX(20px)'})),
          animate(".35s ease", style({ height: '*', opacity: 1, transform: 'translateX(0)'}))
        ])
      ])
    ])
  ]
})
export class RedeemCouponComponent implements OnInit {

  @HostListener("click", ["$event"])
  public onClick(event: any): void
  {
    event.stopPropagation();
    // console.log('stopping propagation');
  }
  
  @Input('for') for:string = 'User';
  @Input('context') context:ViewServicerequestComponent | FillRecommendationComponent;
  @Input('is-fill-recommendation') isFillRecommendation:boolean;
  @Input('is-view-mode') isViewMode:boolean;
  @Input('confirm-removal') confirmRemoval:boolean = true;
  @Output() couponAdded:EventEmitter<string> = new EventEmitter<string>();
  
  userInfo:any;
  // Coupon redeem variables
  showCodeApplyForm:boolean;
  applyCodeForm:FormGroup;
  couponApplied:boolean;
  appliedCoupons:Array<any> = [];
  notAppliedCoupons:Array<any> = [];
  discountAmount:number;
  discountInfo:any;
  serviceData:any;

  constructor(public gs: GlobalService,public router: Router, public http: Http,public dialog: MatDialog, private formBuilder:FormBuilder) {
    
  }

  ngOnInit() {
    // console.log('init redeem component');
    if(this.for == 'User'){
      setTimeout(()=>{
        let inSubscription:boolean=true;
        // inSubscription = this.globalService.isSubscribe(globalConstants.SUBSCRIPTION_DISCOUNTS_FEATURE);
        // this is render the component in readonly mode to hide use the view permission.
        let isEnable=this.gs.getAcl('apply_discounts','edits');
        // check for view permission if the requirement is to hide the component all together
        if(!(isEnable) || !(inSubscription)){
          this.isViewMode = true;
          // this.router.navigate(['shops/action/forbidden']);
        }
        // console.log('view mode as per permissions = ', isEnable);
      },globalConstants.RELOAD_WAIT);
    }
    this.discountAmount = 0;
    this.applyCodeForm = this.formBuilder.group({
      coupon_code:['', Validators.required]
    });
    if(this.for == 'User'){
      this.userInfo = this.gs.getCurrentUser();
      // console.log('user info = ', this.userInfo);
      if(this.isFillRecommendation){
        this.discountInfo = this.context.serviceInfo.discounts;
        this.serviceData = this.context.serviceInfo;
      }
      else{
        this.discountInfo = this.context.serviceDetail.discounts;
        this.serviceData = this.context.serviceDetail;
      }
    }
    else{
      this.userInfo = this.gs.getCurrentCustomer();
      // refer to customer response
      this.discountInfo = this.context.serviceDetail.discounts;
      this.serviceData = this.context.serviceDetail;
    }
    // console.log('discount info = ', this.discountInfo);
    this.setCouponsInfo();
  }
  
  private addActiveState(arr:Array<any>){
    arr.forEach((coupon)=>{
      coupon.state = 'active';
    });
  }
  
  /**
   * Function to show and hide the apply coupon code form
   */
  toggleCouponCodeApply(){
    // console.log('showing form');
    this.showCodeApplyForm = !this.showCodeApplyForm;
  }
  
  /**
   * Function to handle submission of Coupon Code.
   */
  submitCouponCode(){
    // console.log('apply code form = ', this.applyCodeForm);
    let body:any = {
      user_type:this.userInfo.user_type,
      request_id:this.context.serviceRequestId,
      coupon_code:this.applyCodeForm.value.coupon_code
    }
    if(this.for == 'User'){
      body.user_id = this.userInfo.id;
    }
    else{
      body.customer_id = this.userInfo.id;
    }
    // console.log('body = ', body);
    if(this.isFillRecommendation){
      this.context.addCouponControls(body.coupon_code, 1);
      this.context.postSaveAndStay(1,true);
    }
    else{
      this.addCouponAPI(body);
    }
  }
  
  addCouponAPI(body:any){
    this.gs.callAPI(globalConstants.API_APPLY_SHOP_DISCOUNT_URL, body)
    .subscribe((response:any)=>{
      // console.log('discount applied = ', response);
      if(response.result == 1){
        // this.discountAmount = response.data.discounted_amount; // will be in api response.
        // let addedAmount = 0;
        // if(this.discountAmount > 0){
        //   addedAmount = response.data.total_discounted_amount - this.discountAmount;
        // }
        // else{
        //   addedAmount = response.data.total_discounted_amount;
        // }
        this.discountAmount = response.data.total_discounted_amount; // will be in api response.
        this.couponApplied = true;
        this.appliedCoupons = response.data.discounts_list.applicable;
        this.notAppliedCoupons = response.data.discounts_list.inapplicable;
        this.addActiveState(this.appliedCoupons);
        this.addActiveState(this.notAppliedCoupons);
        this.reset();
        if(this.isFillRecommendation){
          // this.context.makeCalculations();
          this.context.applyDiscount(this.discountAmount);
        }
        else{
          this.context.applyDiscount(this.discountAmount);
        }
        this.gs.snackbar('success', response.message);
      }
      else{
        if(response.code == 590  && response.data.discounts_list){
          // console.log('for inapplicable coupons detected');
          // this.discountAmount = response.data.total_discounted_amount; // will be in api response.
          this.couponApplied = true;
          this.appliedCoupons = response.data.discounts_list.applicable;
          this.notAppliedCoupons = response.data.discounts_list.inapplicable;
          this.addActiveState(this.appliedCoupons);
          this.addActiveState(this.notAppliedCoupons);
          this.reset();
        }
        this.gs.snackbar('error', response.message);
      }
    });
  }
  
  /**
   * Function to remove applied coupon and update calculation accordingly.
   */
  removeCoupon(coupon){
    // console.log('removed coupon = ', coupon);
    let continueRemoveCoupon = ()=>{
      let body:any = {
        user_type:this.userInfo.user_type,
        request_id:this.context.serviceRequestId,
        discount_id:coupon.discount_id
      }
      if(this.for == 'User'){
        body.user_id = this.userInfo.id;
      }
      else{
        body.customer_id = this.userInfo.id;
      }
      if(this.isFillRecommendation){
        this.context.addCouponControls(body.discount_id, 2);
        this.context.postSaveAndStay(1,true);
      }
      else{
        this.gs.callAPI(globalConstants.API_REMOVE_SHOP_DISCOUNT_URL, body)
        .subscribe((response:any)=>{
          // console.log('discount removed = ', response);
          if(response.result == 1){
            // let idx = this.appliedCoupons.findIndex((coup)=>{
            //   return coup.discount_id == coupon.discount_id;
            // });
            // this.appliedCoupons.splice(idx,1);
            // let removedAmount = this.discountAmount - response.data.total_discounted_amount;
            this.discountAmount = response.data.total_discounted_amount; // will be in api response.
            this.couponApplied = false; 
            this.appliedCoupons = response.data.discounts_list.applicable;
            this.notAppliedCoupons = response.data.discounts_list.inapplicable;
            this.addActiveState(this.appliedCoupons);
            this.addActiveState(this.notAppliedCoupons);
            if(this.isFillRecommendation){
              // this.context.makeCalculations();
              // this.context.removeDiscount(removedAmount);
              this.context.applyDiscount(this.discountAmount);
            }
            else{
              // this.context.removeDiscount(removedAmount);
              this.context.applyDiscount(this.discountAmount);
            }
            this.gs.snackbar('success', response.message);
          }
          else{
            this.gs.snackbar('error', response.message);
          }
        });
      }
    };
    if(!this.confirmRemoval){
      continueRemoveCoupon();
    }
    else{
      let conf = 'Are you sure you want to remove this coupon code?';
      this.gs.confirmDialog('removeCouponCode', conf)
      .subscribe((r) => {
        let res: any = r;
        if (res == 'removeCouponCode') {
          continueRemoveCoupon();
        }
        else{
          if(this.isFillRecommendation){
            this.context.removeCouponControls();
          }
        }
      });
    }
  }
  
  reset(){
    this.applyCodeForm.reset();
    this.showCodeApplyForm = false;
  }
  
  reInitialize(newDiscountInfo:any){
    this.discountAmount = 0;
    this.discountInfo = newDiscountInfo;
    // console.log('discount info = ', this.discountInfo);
    this.setCouponsInfo();
  }
  
  private setCouponsInfo(){
    if(this.discountInfo && this.discountInfo.total_discounted_amount >= 0){
      // assign applicable coupons to appliedCoupons
      // assign not applicable coupons to notAppliedCoupons
      this.appliedCoupons = this.discountInfo.discounts_list.applicable;
      this.notAppliedCoupons = this.discountInfo.discounts_list.inapplicable;
      this.addActiveState(this.appliedCoupons);
      this.addActiveState(this.notAppliedCoupons);
      this.discountAmount = this.discountInfo.total_discounted_amount;
      this.context.applyDiscount(this.discountAmount);
    }
  }
  
  /**
   * @author Shashank Thakur
   * @trackID <:CAR-1571>
   * @created 29-07-2020
   * @see Function to open popup for add coupon
  */
  createQuickCoupon() {
      
    // this.toggleOptions(); //to close actions menu on action click
    let dialogRef = this.dialog.open(AddDiscountComponent, {
      panelClass: 'car-dialog-form',
      data: { isPopup: true, isQuickMode:true, couponCode: this.applyCodeForm.get('coupon_code').value || '', customerId:this.serviceData.customer_id, showApplyOnClose:(this.serviceData.request_status != 8) }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('add coupon closed = ', result);
      // emit output event from here to notify parent about coupon added and ready to apply status. 
      if(result && result.addedCoupon.length > 0){
        this.couponAdded.emit(result.addedCoupon[0]);
      }
    });
  }
  
  ngOnDestroy(){
    
  }

}
