import { Component, OnInit, ViewChild } from '@angular/core';
import { ViktableComponent, VikSource } from '../../../admin/viktable/viktable.component';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
const UPLOAD_LIST = "queue-order-";
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-queorder',
  templateUrl: './queorder.component.html',
  styleUrls: ['./queorder.component.scss']
})
export class QueorderComponent extends ViktableComponent implements OnInit {

  public tablesData = this;
  displayedColumns = ['name', 'status','added_on', 'Actions'];
  public status=['Pending','Processing','Finished'];
  url = "";
  public options = {
    user_type: "User",
    user_id: "",
    search_keyword: "",
    search_status:'',
    start: 0,
    limit: 10
  }
  public user:any={};
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<StockQueOrderData> = new MatTableDataSource();
  public record :any =[];
  constructor(public gs: GlobalService, public router: Router) {
    super(globalConstants.API_STOCK_ORDER_QUEUE_LIST, gs);
    this.user=this.gs.getCurrentUser();
    if (this.user) {
      this.sessionKey = UPLOAD_LIST;
      this.url = globalConstants.API_STOCK_ORDER_QUEUE_LIST;
      this.gs.setMetaData("SHOPS", "QUEUE_ORDER_LIST")
    } else {
      this.router.navigate(['shops']);
    }
   }

  ngOnInit() {
    setTimeout(()=>{
      let isEnable=this.gs.getAcl('stock_order','addNew');
      if(!(isEnable)){
          this.router.navigate(['shops/action/forbidden']);
      }
    },globalConstants.RELOAD_WAIT);
    this.reinitializeOptions();
    this.setDataTable();
  }
   /** init options */
   reinitializeOptions() {
    this.options = this.gs.localGetterSetter(this.options, this.options, UPLOAD_LIST);
    this.options.user_type = this.user.user_type;
    this.options.user_id = this.user.id;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  /** set data source of order que list */
  setDataTable() {
    this.record = [];
    // this.dataSource = new VikSource(this.tablesData, this.sort, this.paginator);
    // this.dataSource.getSortedData = this.sortedData;
    var content = this.options;
    this.detailData={};
    this.resultData=[];
    
    this.isGetData=false
    var callApi = this.gs.callAPI(this.url, content);
    callApi.subscribe((data:any) => {
     
      this.isGetData=true
      if(this.returnData && data[this.returnData]){
        this.detailData=data[this.returnData];
      }
      if (data.data != undefined) {
        let moreRows = 'false';
        let firstList = 'false';
        this.gs.localGetterSetter("totalRecords", data.recordsTotal, this.sessionKey);
        if (data.more_rows == "true") {
          moreRows = "true";
        }
        this.gs.localGetterSetter("more_rows", moreRows, this.sessionKey);
        if (data.start >= 0 && data.start <= 5) {
          firstList = "true";
        }
        this.gs.localGetterSetter("first_list", firstList, this.sessionKey);
        if (data.data.length != undefined) { 
          this.recordsTotal = data.data.length;
          for (var i = 0; i < data.data.length; i++) {
            this.AddRecord(data.data[i])
          }
          this.dataSource = new MatTableDataSource(this.record)
          this.dataSource.sort = this.sort;
        } else {
          this.recordsTotal = "1";
          this.AddRecord(data.data)
        }
        this.gs.localGetterSetter(null, this.options, this.sessionKey);
      } else {
        this.dataSource = new MatTableDataSource(this.record);
        this.gs.localGetterSetter("totalRecords", '0', this.sessionKey);
      }
    },(error)=>{
      this.dataSource = new MatTableDataSource(this.record);
      this.gs.localGetterSetter("totalRecords", '0', this.sessionKey);
    })
  }

  AddRecord(record){
    var data = this.createRecordrecord(record);
      this.record.push(data);
  }
  
  /** create record, that show on order queue list */
  createRecordrecord(record) {
    return {
      id: record.id,
      name: record.name,
      status : this.showStatus(record.status),
      added_on: this.gs.getFullFormateDate(record.add_date,"G","B")
    };
  }
  /** sort order que list */
  // sortedData(): any[] {
  //   let th: any = this;
  //   const data = th._exampleDatabase.data.slice();
  //   if (!th._sort.active || th._sort.direction == '') { return data; }
  //   return data.sort((a, b) => {
  //     let propertyA;
  //     let propertyB;
  //     switch (th._sort.active) {
  //       case 'Name': propertyA = a.name; propertyB = b.name; break;
  //       case 'Create At': propertyA = a.added_on; propertyB = b.added_on; break;
  //       default: return 0;
  //     }
  //     let valueA = isNaN(propertyA) ? propertyA.toUpperCase() : propertyA;
  //     let valueB = isNaN(propertyB) ? propertyB.toUpperCase() : propertyB;

  //     return (valueA < valueB ? -1 : 1) * (th._sort.direction == 'asc' ? 1 : -1);
  //   });
  // }
  /** return order queue status */
  showStatus(s){
    return (typeof this.status[s] == 'undefined'?'N/A' : this.status[s]);
  }
  /** navigate to order que detail page */
  viewQueDetail(row){
    this.gs.localGetterSetter(this.options,null, UPLOAD_LIST);
    this.router.navigate(['shops/queue/detail/'+row.id]);
  }
  checkFormEmpty(button) {
    if (this.options.search_keyword == "" && this.options.search_status == "") {
      if (button) { button.color = "primary" }
      return false
    }else {
      if (button) { button.color = "accent" }
      return true;
    }
  }
  search() {
    this.options.start = 0;
    this.setDataTable();
  }
  clearSearch() {
    this.gs.localGetterSetter(null, this.options, UPLOAD_LIST);
    this.reinitializeOptions();
    this.setDataTable();
  }

}

export interface StockQueOrderData {
  name: string;
  status: string;
  added_on:string;
}
