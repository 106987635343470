import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation,OnDestroy} from '@angular/core';

import { ChartReadyEvent } from 'ng2-google-charts';
import { ChartErrorEvent } from 'ng2-google-charts';
import { ChartSelectEvent } from 'ng2-google-charts';
import { ChartMouseOverEvent, ChartMouseOutEvent,GoogleChartInterface,GoogleChartType } from 'ng2-google-charts';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatPaginator} from '@angular/material/paginator';
import {DataSource, SelectionModel} from '@angular/cdk/collections';
import {Http, Headers, Response} from '@angular/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {globalFunctions} from "../../../../environments/globalFunctions"
import {globalConstants} from "../../../../environments/globalConstants"
import {GlobalService} from "../../../../environments/global.service"

import {Observable} from 'rxjs/Observable';
import {Title} from '@angular/platform-browser';

import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';

import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AppComponent} from '../../../app.component';
import {Subscription} from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';

const SERVICE_LIST='shop-salesreportComponent';
@Component({
  selector: 'app-salesreport',
  templateUrl: './salesreport.component.html',
  styleUrls: ['./salesreport.component.scss']
})
export class SalesreportComponent implements OnInit,OnDestroy {
  @ViewChild('cchart') cchart;
  selectedSales = 'barchart';
  selectedService = 'piechart';
  selectedPart = 'barchart';
  private chartData = {};
  public salesData:GoogleChartInterface;
  public reportData:any;
  public ServiceRequestData:GoogleChartInterface;
  public PartData:GoogleChartInterface;
  public selectEvent: ChartSelectEvent;
  displayedColumns = ['request_id', 'title', 'customer_name', 'vehicle', 'appointment_date', 'service_date', 'estimated_delivery_date', 'status'];
  dataSource: MatTableDataSource<serviceRequestListData> = new MatTableDataSource();
  public currentCustomer;
  public schedule_service_option
  public requested_delivery_option
  public estimated_delivery_option
  public more_rows;
  public request_id:string;
  public options = {
      user_id: "",
      user_type: "User",
      start: 0,
      limit: 10,
      select_sales:'3',
      select_basis:1,
      starting_date:'01/15/2019',
      ending_date:'01/15/2019'
  }
  //public statuses = ['', 'Pending', 'Processing', 'Approval', '', '', 'Completed', 'Approval Completed', 'Completed And Paid', 'Unread feedback', '', 'New'];
  public statuses = ['Appointment', 'Pending', 'Processing', 'Waiting for Approval', '', 'Service Completed', 'Completed', 'Approval Completed', 'Completed And Paid', "", "Non-CAR", "", "Work In Progress"];
  public chatType=[{id:'barchart',text:'Bar Chart'},{id:'piechart',text:'Pie Chart'}];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public gSerice=null;
  public openSearch: boolean = false;
  public isActive: boolean = true;
  public cashbasis=1;
  public total_sales;
  public total_revenue;
  public total_tax;
  public total_labor;
  public avg_sales_day;
  public avg_sales_week;
  public avg_sales_month;
  public total_tickets_count;
  public avg_sales_notes;
  public Start_date;
  public End_date;
  public report_csv_url;
  selected = '3';
  public partSalesByQty:GoogleChartInterface;
  public partSalesByPercentProfit:GoogleChartInterface;
  public partSalesByAmountProfit:GoogleChartInterface;
  public searchFilterSideBar = false;
  searchInput: any;
  public showChart: boolean= false;
  public chartType: string ="BarChart"
  public subscriptionDataForAPI: Subscription = new Subscription();
  recordsTotal: any;
  record: any[];
  constructor(private location: Location, private app: AppComponent, public router: Router, private http: Http, public titleService: Title, public globalService: GlobalService, public globalservice: GlobalService, public dialog: MatDialog) {
      this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
      // this.searchInput = {
      //   type: 'salesReport',
      //   customerList: []
      // }
      this.options.user_id = this.currentCustomer.id;
      this.gSerice=globalservice;
      
      if(localStorage.getItem("openFilter") == "1") {
          this.openSearch = true;
      } else {
          this.openSearch = false;
      }
      setTimeout(()=>{
      let isEnable=this.globalService.getAcl('sales_reports','views');
      if(!(isEnable)){
          this.router.navigate(['shops/action/forbidden']);
      }
    },globalConstants.RELOAD_WAIT)
  }

 ngOnInit() {
   this.globalService.setMetaData("SHOPS","SALES_REPORT")
  //  this.initChart();
   //this.titleService.setTitle(lang.TITLE_CUSTOMER_LIST_REQUEST);
   this.reinitializeOptions();
   //this.titleService.setTitle(lang.TITLE_CUSTOMER_LIST_REQUEST);
   this.schedule_service_option = ""
   this.requested_delivery_option = ""
   this.estimated_delivery_option = ""
   this.salesSortReport();
  //  this.partReports()
}

ngAfterViewInit(){
  this.setDataTable();
}

 searchFilterOutput(event:any){
    if(event.changes === 1){
      this.searchFilterSideBar = false;
      this.selected = event.select_sales.toString();
      if(this.selected === '5'){
        this.Start_date = event.starting_date;
        this.End_date = event.ending_date;
      }
      this.showChart = false;
      this.salesSortReport();
      this.setDataTable(); 
    }else if(event.changes === 0){
      this.searchFilterSideBar = false;
    }else if(event.changes === 4){
      this.selected = event.select_sales.toString();
      this.showChart = false;
      this.salesSortReport();
      this.setDataTable(); 
    }
  }

// API_EXPORT_SALES_REPORT_SERVICELIST_URL
downloadCSV(){

  this.options.select_basis=this.cashbasis;
  this.options.select_sales=this.selected;
  this.options.starting_date=this.Start_date;
  this.options.ending_date=this.End_date;
  var content = globalFunctions.stringifyFormData(this.options, null);
  var callApi = this.globalService.callAPI(globalConstants.API_EXPORT_SALES_REPORT_SERVICELIST_URL, content);
  this.subscriptionDataForAPI =  callApi.subscribe((data:any)=>{
   var reportData= data;
    window.location.href = globalConstants.API_DOWNLOAD_REPORTS_STATS_FILE_URL+"?t=1&p="+reportData.csv_url;
    // this.report_csv_url=reportdata.csv_url;
  });
}
switchChange(event) {
    if (event.checked) {
      this.cashbasis=1;
        console.log('true');
    } else {
          this.cashbasis=0;
        console.log('false');
    }
    this.salesSortReport();
    // this.partReports()
    this.setDataTable();
}
 public initChart(salesData){
     this.salesData=  {
      chartType: GoogleChartType.BarChart,
      dataTable: [
         ['Type', salesData.shop_name],
         ['Sales', salesData.total_sales],
         ['Revenue', salesData.total_revenue],
         ['Taxes',  salesData.total_tax],
         ['Labor', salesData.total_labor]
      ],
      formatters: [
            {
                columns: [1],
                type: 'NumberFormat',
                options: {
                fractionDigits:2
                }
            }
        ],
      options: {
        title: 'Sales',
        width: '100%',
        height: 350,
        animation:{
          duration: 1000,
          easing: 'out',
          startup: true
        },
        hAxis: {
            title: 'Amount (in Dollars)',
            titleTextStyle: {color: '#000'}
        }
      }
    };
    this.ServiceRequestData=  {
      chartType: GoogleChartType.BarChart,
      dataTable:[
         ['Type', salesData.shop_name],
         ['New', parseFloat( salesData.ticket_status.new_tickets)],
         ['Pending', parseFloat(salesData.ticket_status.pending_tickets)],
         ['Under Process', parseFloat(salesData.ticket_status.processing_tickets)],
         ['Approval Awaited/Completed', parseFloat(salesData.ticket_status.approval_tickets)],
         ['Work In Progress', parseFloat(salesData.ticket_status.wip_tickets)],
         ['Completed', parseFloat(salesData.ticket_status.completed_notpaid_tickets)],
         ['Completed & Paid', parseFloat(salesData.ticket_status.completed_paid_tickets)]
      ] ,
      options: {
        title: 'Service Request',
        width: '100%',
        height: 350,
        animation:{
          duration: 1000,
          easing: 'out',
          startup: true
        },
        hAxis: {
            title: 'Amount (in Dollars)',
            titleTextStyle: {color: '#000'}
        }
      }
    };
    this.showChart =true;
    let tp=(salesData.parts && typeof salesData.parts.gross_parts !='undefined' ?salesData.parts.gross_parts.total_price:0)
    let tc=(salesData.parts && typeof salesData.parts.gross_parts !='undefined' ?salesData.parts.gross_parts.total_cost:0)
    let tpp=(salesData.parts && typeof salesData.parts.gross_parts !='undefined' ?salesData.parts.gross_parts.total_profit:0)
    this.PartData =  {
      chartType: GoogleChartType.ColumnChart,
      dataTable:[
         ['Type', salesData.shop_name],
         ['Gross Parts Sales', tp],
         ['Gross Parts Cost', tc],
         ['Gross Profit Parts',  tpp]
      ],
      formatters: [
        {
            columns: [1],
            type: 'NumberFormat',
            options: {
            fractionDigits:2
            }
        }
    ],
      options: {
        title: "Part's Sales & Profit",
        width: '100%',
        height: 350,
        bar: {groupWidth: "35%"},
        animation:{
          duration: 1000,
          easing: 'out',
          startup: true
        },
        vAxis: {
            title: 'Amount (in Dollars)',
            titleTextStyle: {color: '#000'}
        }
      }
    };
    this.showChart =true;
    this.partReports(salesData)
  }

 public changeSalesChartType():void {
    if(this.salesData.chartType == 'BarChart'){
      this.salesData.chartType = 'PieChart';
    }
    else{
      this.salesData.chartType = 'BarChart';
      
    }
    if(this.ServiceRequestData.chartType == 'BarChart')
      this.ServiceRequestData.chartType = 'PieChart';
    else
      this.ServiceRequestData.chartType = 'BarChart';

      this.salesData.component.draw();
      this.ServiceRequestData.component.draw();
  }
 public changeServiceRequestChartType():void {
    // this.ServiceRequestData = Object.create(this.ServiceRequestData);
    // if(this.ServiceRequestData.chartType == 'BarChart')
    //   this.ServiceRequestData.chartType = 'PieChart';
    // else
    //   this.ServiceRequestData.chartType = 'BarChart';
  }
 public changePartChartType():void {
    if(this.PartData.chartType == 'ColumnChart'){
      this.partSalesByQty.chartType = 'PieChart';
      this.partSalesByPercentProfit.chartType = 'PieChart';
      this.partSalesByAmountProfit.chartType = 'PieChart';
      this.PartData.chartType = 'PieChart';
    }else{
      this.partSalesByQty.chartType = 'ColumnChart';
      this.partSalesByPercentProfit.chartType = 'ColumnChart';
      this.partSalesByAmountProfit.chartType = 'ColumnChart';
      this.PartData.chartType = 'ColumnChart';
    }
    this.partSalesByQty.component.draw();
    this.partSalesByPercentProfit.component.draw();
    this.partSalesByAmountProfit.component.draw();
    this.PartData.component.draw();
  }

  public ready(event: ChartReadyEvent) {
    console.log(event.message);
  }

  public error(event: ChartErrorEvent) {
    console.error(event);
  }

  public select(event: ChartSelectEvent) {
    this.selectEvent = event;
  }

  public mouseOver(event: ChartMouseOverEvent) {
    console.log('bb: ' + JSON.stringify(event.boundingBox));
    console.log('pos: ' + JSON.stringify(event.position));
    console.log('type: ' + JSON.stringify(event.columnType));
    console.log('label: ' + JSON.stringify(event.columnLabel));
    console.log('value: ' + JSON.stringify(event.value));
  }

  public mouseOut(event: ChartMouseOutEvent) {
    console.log('bb: ' + JSON.stringify(event.boundingBox));
    console.log('pos: ' + JSON.stringify(event.position));
    console.log('type: ' + JSON.stringify(event.columnType));
    console.log('label: ' + JSON.stringify(event.columnLabel));
    console.log('value: ' + JSON.stringify(event.value));
  }


  /* data table*/
  getStatus(statusId) {
      return this.statuses[statusId]
  }
  setTouchViewValue() {
      return this.app.touchViewState;
  }

  formatDate(date) {
      if (!new Date(date) || date == null || date == "0000-00-00 00:00:00") {return "-"}
      return (new Date(date).getMonth() + 1) + "/" + new Date(date).getDate() + "/" + new Date(date).getFullYear()
  }

  scrolling() {
      this.globalservice.setScrolling('.car-datatable', 1000, -20);
  }


  paginateByLimit(event){
      this.globalservice.setScrolling('body', 600, 0);
      this.options.limit = event.pageSize;
      this.options.start = 0;
      this.scrolling();
      this.setDataTable();
  }

  reinitializeOptions() {
      this.gSerice.localGetterSetter(this.options,this.options,SERVICE_LIST);
      this.options.user_id= this.currentCustomer.id;
      this.options.user_type= "User";
      this.options.select_sales='3';
      this.options.select_basis=1;
      this.options.starting_date=this.currentDate();
      this.options.ending_date=this.currentDate();
  }
  salesSortReport(){
    this.options.select_basis=this.cashbasis;
    this.options.select_sales=this.selected;
    this.options.starting_date=this.Start_date;
    this.options.ending_date=this.End_date;
    var content = globalFunctions.stringifyFormData(this.options, null);
    this.globalService.callAPI(globalConstants.API_SHOP_SALES_REPORT_URL, content).subscribe((data:any)=>{
      var message = data.message;
      if (data.code == "339") {
          this.globalService.snackbar('error', message);
          this.isActive=false;
      }
      else{
        this.isActive=true;
      }
      var salesData= data.data;
        this.total_sales=salesData.total_sales;
        this.total_revenue=salesData.total_revenue;
        this.total_tax=salesData.total_tax;
        this.total_labor=salesData.total_labor;
        this.avg_sales_day=salesData.average_sales_day;
        this.avg_sales_week=salesData.average_sales_week;
        this.avg_sales_month=salesData.average_sales_month;
        this.avg_sales_notes=salesData.average_sales_notes;
        this.total_tickets_count=salesData.total_tickets_count;
        this.initChart(salesData);

    });
  }
  decimalTwoDigit(x) {
    return isNaN(x) ? '0.00' : Number.parseFloat(x).toFixed(2);
  }
  getTotalRecords() {
      return parseInt(localStorage.getItem(SERVICE_LIST+'totalRecords'))
  }
  currentDate(){
    return (new Date().getMonth() + 1) + "/" + new Date().getDate() + "/" + new Date().getFullYear()
  }
  paginate(event,type){
      this.scrolling();
      if(type=="next") {
        this.options.start = this.options.start + this.options.limit;

      } else {
        this.options.start = this.options.start - this.options.limit;

      }
      this.setDataTable();
  }

  firstList() {
      return this.globalservice.firstList(SERVICE_LIST,this.options);
  }
  lastList() {
      return this.globalservice.lastList(SERVICE_LIST);
  }
  getUpperLimit(currentLastLimit, total) {
      if (currentLastLimit > total) {
          return total
      } else {
          return currentLastLimit
      }
  }

    /***Check service request*****/
    setDataTable(){
      this.record = [];
      var content = globalFunctions.stringifyFormData(this.options, null);

      var callApi = this.globalService.callAPI(globalConstants.API_GET_SERVEICE_REQUEST_FOR_SALES_REPORT_URL, content);
      this.subscriptionDataForAPI =  callApi.subscribe((data:any) => {
          if (data.data != undefined) {
              let moreRows='false';
              let firstList='false';
              this.globalService.localGetterSetter("totalRecords",data.recordsTotal,SERVICE_LIST);
              if(data.more_rows == "true") {
                  moreRows="true";
              }
              this.globalService.localGetterSetter("more_rows",moreRows,SERVICE_LIST);
              if (data.start >= 0 && data.start <= 5) {
                  firstList="true";
              }
              this.globalService.localGetterSetter("first_list",firstList,SERVICE_LIST);

              this.recordsTotal = data.data.length;

              if (data.data.length == undefined) {
                  this.AddRecord(data.data);
                  this.dataSource = new MatTableDataSource(this.record)
                  this.dataSource.sort = this.sort;
              } else {
                  for (var i = 0; i < data.data.length; i++) {
                      this.AddRecord(data.data[i]);
                  }
                  this.dataSource = new MatTableDataSource(this.record)
                  this.dataSource.sort = this.sort;
              }
              this.globalService.localGetterSetter(null,this.options,SERVICE_LIST);
          } else {
            this.dataSource = new MatTableDataSource(this.record)
              this.globalService.localGetterSetter("totalRecords",'0',SERVICE_LIST);
          }
      })
    }

    AddRecord(record) {
      var data = this.createRecord(record);
      this.record.push(data);
  }

  private createRecord(record) {
    return {
          vehicle: record.make + " " + record.model,
          customer_name: record.first_name + " " + record.last_name,
          estimated_delivery_date: this.globalService.getFullFormateDate(record.estimated_delivery_date,'G','H'),
          service_date: this.globalService.getFullFormateDate(record.schedule_service_date,'G','H'),
          appointment_date: this.globalService.getFullFormateDate(record.add_date,'G','H'),
          request_id: record.request_id,
          status: this.statuses[record.status],
          title: record.title,
          //      car: (record.status == '9' || record.status == '10') ? 'No' : 'Yes',
          id: record.id,
          customer_id: record.customer_id,
          car_id: record.car_id,
          feedback_read_status: record.feedback_read_status,
          check_status: record.status
    };
}


    /* STATUS COLOR */
    getRowStatusColor(status) {
        var statusArr = ['active-cell', 'warning-cell', 'info-cell', 'success-cell', 'info-cell', 'success-cell', 'success-completed', 'success-cell', 'success-paid', "", "", "", "work-progress-cell"];
        return statusArr[status];
    }
  partReports(partsaleData) {

    let partSale: any = partsaleData.parts
    let qtylist = [];

    qtylist.push(['Type', partsaleData.shop_name])
    if (partSale.top_parts_by_quantity.length) {
      partSale.top_parts_by_quantity.forEach(e => {
        qtylist.push([e.name, parseFloat(e.quantity)])
      });
    } else {
      qtylist.push(['Parts data not found', 0])
    }

    this.partSalesByQty = {
      // chartType: 'ColumnChart',
      chartType: GoogleChartType.ColumnChart,
      dataTable: qtylist,
      formatters: [
        {
          columns: [1],
          type: 'NumberFormat',
          options: {
            fractionDigits: 2
          }
        }
      ],
      options: {
        title: 'Top Parts Sold By Quantity',
        width: '100%',
        height: 350,
        bar: { groupWidth: "35%" },
        animation: {
          duration: 1000,
          easing: 'out',
          startup: true
        },
        vAxis: {
          title: 'Parts Quantity',
          titleTextStyle: { color: '#000' }
        }
      }
    };
    let qtyPerlist = [];

    qtyPerlist.push(['Type', partsaleData.shop_name])
    if (partSale.top_parts_by_percentage_profit.length) {
      partSale.top_parts_by_percentage_profit.forEach(e => {
        qtyPerlist.push([e.name, parseFloat(e.profitpercentage)])
      });
    } else {
      qtyPerlist.push(['Parts data not found', 0])
    }
    this.partSalesByPercentProfit = {
      // chartType: 'ColumnChart',
      chartType: GoogleChartType.ColumnChart,
      dataTable: qtyPerlist,
      formatters: [
        {
          columns: [1],
          type: 'NumberFormat',
          options: {
            fractionDigits: 2
          }
        }
      ],
      options: {
        title: 'Top Parts Sold % Profit',
        width: '100%',
        height: 350,
        bar: { groupWidth: "35%" },
        animation: {
          duration: 1000,
          easing: 'out',
          startup: true
        },
        vAxis: {
          title: 'Parts Profit',
          titleTextStyle: { color: '#000' }
        }
      }
    };
    let qtyAmtlist = [];

    qtyAmtlist.push(['Type', partsaleData.shop_name])
    if (partSale.top_parts_by_amount_profit.length) {
      partSale.top_parts_by_amount_profit.forEach(e => {
        qtyAmtlist.push([e.name, parseFloat(e.profit)])
      });
    } else {
      qtyAmtlist.push(['Parts data not found', 0])
    }
    this.partSalesByAmountProfit = {
      // chartType: 'ColumnChart',
      chartType: GoogleChartType.ColumnChart,
      dataTable: qtyAmtlist,
      formatters: [
        {
          columns: [1],
          type: 'NumberFormat',
          options: {
            fractionDigits: 2
          }
        }
      ],
      options: {
        title: 'Top Parts Sold $ Profit',
        width: '100%',
        height: 350,
        bar: { groupWidth: "35%" },
        animation: {
          duration: 1000,
          easing: 'out',
          startup: true
        },
        vAxis: {
          title: 'Parts Amount',
          titleTextStyle: { color: '#000' }
        }
      }
    };
    this.showChart =true;
  }
  downloadPartCSV() {

    this.options.select_basis = this.cashbasis;
    this.options.select_sales = this.selected;
    this.options.starting_date = this.Start_date;
    this.options.ending_date = this.End_date;
    var content = globalFunctions.stringifyFormData(this.options, null);
    var callApi = this.globalService.callAPI(globalConstants.API_EXPORT_PART_SALE_REPORT, content);
    this.subscriptionDataForAPI = callApi.subscribe((data:any) => {
      var reportData = data;
      window.location.href = globalConstants.API_DOWNLOAD_REPORTS_STATS_FILE_URL + "?t=1&p=" + reportData.csv_url;
      // this.report_csv_url=reportdata.csv_url;
    });
  }

  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
 }  
}

export interface serviceRequestListData {
  vehicle: string;
  customer_name: string;
  estimated_delivery_date: string;
  appointment_date: string;
  service_date: string;
  request_id: string;
  status: string;
  title: string;
  id: string,
  customer_id:string,
  feedback_read_status: string;
  check_status: any;
}

