<div class="col-sm-12 subscription-plans-details">
    <div class="row margin-bottom-15 ">
        <div class="col-sm-12 font-bold">Plan Details</div>
    </div>
    <div class="row margin-bottom-15 plan-name-row">
        <div class="col-xs-3 font-bold">Plan:</div>
        <div class="col-xs-9"><span>{{selectedPlanDetails?.subscription_name}}</span><span>&nbsp;( {{selectedPlanDetails?.plan_type ==1? 'Monthly':'Yearly'}} )</span></div>
    </div>
    <div class="row margin-bottom-15">
        <div class="col-xs-3 font-bold">Price:</div>
        <div class="col-xs-9"><span class="trial-badge">14 Days Free Trial</span></div>
    </div>
    <div class="row margin-bottom-15">
        <div class="col-xs-3 font-bold">Includes:</div>
        <div class="col-xs-9">{{selectedPlanDetails?.subscription_desc}}</div>
    </div>
    <div class="row submit-btn text-center change-plan-btn">
        <div class="col-xs-12 col-sm-7">
            <button type="button" mat-raised-button class="font-bold submit-btn btn-block mat-raised-button mat-accent text-uppercase" title="Change Plan" (click)="openSubscriptionPlansDialog()">Change Plan</button>
        </div>
    </div>
</div>