<div class="panel panel-primary" id="mainCompotents">
    <div class="panel-heading padding-left-15">
      <h3 class="panel-title">Return Queue LIST</h3>
      <button mat-mini-fab class="custom-btn-back" (click)="gs.goBack();">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <div class="panel-body bgcolor-lightgrey">
          <div class="display-full relative">
            <mat-expansion-panel hideToggle="false" class="search-filter search-employee relative">
                <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px">
                    <strong><i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
                </mat-expansion-panel-header>
                <form id="advanceSearchForm" class="collapse in" aria-expanded="true">
                    <div class="panel-body">
                        <div class="col-sm-3 uc-ngx-select">
                            
                            <ngx-select class="display-full margin-bottom-20" 
                                [items]="searchByArray" 
                                [noAutoComplete]="true"
                                [(ngModel)]="options.search_type" 
                                [ngModelOptions]="{standalone: true}"
                                placeholder="Search By">
                            </ngx-select>
                        </div>

                        <div class="col-sm-3"  *ngIf="options.search_type!='5' && options.search_type!='4'">
                            <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                <input  matInput placeholder="Search Keyword" title="Search Keyword" [(ngModel)]="options.search_keyword" [ngModelOptions]="{standalone: true}" class="form-control input-lg custom-form-input"/>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-3" *ngIf="options.search_type=='5'">
                            <mat-form-field class="car-input btn-block car-datePicker" floatPlaceholder="never" appearance="fill">
                            <input matInput class="form-control input-lg custom-form-input" [(ngModel)]="options.picByVendor" [ngModelOptions]="{standalone: true}" placeholder="Date (Picked By Vendor)"
                            [matDatepicker]="picker2" (focus)="picker2.open()" />
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-3"  *ngIf="options.search_type=='4'">
                            <mat-select placeholder="Choose Status" class="form-control custom-form-input" [(ngModel)]="options.item_status" [ngModelOptions]="{standalone: true}">
                                <mat-option *ngFor="let op of status;let i=index" value="{{i}}">{{op}}</mat-option>
                            </mat-select>
                        </div>
                        
                    </div>
                    <mat-action-row class="search-filter-footer">
                      <button mat-raised-button color="accent" type="submit" (click)="search($event)">
                        Search
                      </button>
                    </mat-action-row>
                </form>
            </mat-expansion-panel>
            <button mat-button  class="clear-btn" #clearBtn (click)="clearSearch()" [class.active-search]="checkFormEmpty(clearBtn)">
                Clear Filter
            </button>
        </div>
        <div class="display-full relative">
            <div class="col-sm-3 padding-left-0 margin-bottom-10">
                    <mat-select placeholder="Choose" [disabled]="selectedRow.length==0" class="form-control custom-form-input" [(ngModel)]="applyToAllrow">
                            <mat-option value="0">--Choose--</mat-option>
                            <mat-option value="1">Move to Local Inventory</mat-option>
                            <mat-option value="2">Picked By Vendor</mat-option>
                            <mat-option value="3">Return Processed</mat-option>
                            <mat-option value="4">Delete Entry</mat-option>
                    </mat-select>
            </div>
            <div class="col-sm-3 padding-left-0 margin-bottom-10">
                <button type="button" [disabled]="selectedRow.length==0" mat-raised-button color="accent" class="font-bold" (click)="applyToAll($event);">Apply</button>
            </div>
      </div>
      <div class="display-full relative">
          <div class="car-datatable uc-datatable mat-elevation-z1">
              <div class="table-responsive relative">
                  <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg uc-table-responsive">
  
                      <ng-container matColumnDef="checkbox">
                          <mat-header-cell *matHeaderCellDef >
                            <mat-checkbox class="btn-block" [(ngModel)]="selectAll" id="checkAll" labelPosition="before" (change)="checkedAll($event)">
                            </mat-checkbox>
                          </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-role="auto" [attr.data-val]="getAttrVal(row.status)" [class.cell-empty] = "(row.status==1 || row.status==3)" > 
                           <mat-checkbox class="btn-block" [(ngModel)]="row.checked" *ngIf="(row.status!=0) ? ((row.status==2) ? true : false ) : true  " value="{{row.id}}" id="requestid-{{row.id}}" labelPosition="before" (change)="checked($event,row)"></mat-checkbox>
                          </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="Name">
                          <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Part Name </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-role="auto" data-val="Part Name"> {{row.name}} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="partnumber">
                          <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Part Number </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-role="auto" data-val="Part Number"> {{row.part_number}} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="RequestID">
                          <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Request ID </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-role="auto" data-val="Request ID"> {{row.request_number}} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="AddedBy">
                          <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Added By </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-role="auto" data-val="Added By"> {{row.added_by_name}} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="Qty">
                          <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Quantity </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-role="auto" data-val="Quantity"> {{row.quantity}} </mat-cell>
                      </ng-container>
  
                      <ng-container matColumnDef="Status">
                          <mat-header-cell *matHeaderCellDef data-role="auto"> Status </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-role="auto" data-val="Status"> {{row.statustxt }} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="vendor_pickup_date">
                            <mat-header-cell *matHeaderCellDef data-role="auto"> Vendor Pickup Date </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="auto" data-val="Vendor Pickup Date"> {{row.vendor_pickup_date }} </mat-cell>
                      </ng-container>
                      
                      <ng-container matColumnDef="Create At">
                          <mat-header-cell *matHeaderCellDef mat-sort-header  data-role="auto"> Add Date </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-role="auto" data-val="Add Date"> {{row.add_date }} </mat-cell>
                      </ng-container>
  
                      <ng-container matColumnDef="Actions">
                          <mat-header-cell *matHeaderCellDef data-role="button"> Actions </mat-header-cell>
                          <mat-cell *matCellDef="let row" data-role="button">
                              <button mat-button class="list-action-btn" [disabled]="selectedRow.length > 0 " [matMenuTriggerFor]="menu" disableRipple >
                                  Action<mat-icon>arrow_drop_down</mat-icon>
                              </button>
                              <mat-menu #menu="matMenu" class="car-icon-menu disable-no-bg" [overlapTrigger]="false">
                                  <button type="button" disableRipple class="mat-menu-item" *ngIf="row.status==1 || row.status==2 || row.status==3" (click)="returnView(row)"><i class="fa fa-eye fa-lg"></i>View</button>  
                                  <button type="button" disableRipple class="mat-menu-item" *ngIf="row.status==0" (click)="moveTolocal(row)"><i class="fa fa-cart-plus fa-lg"></i>Move to Local Inventory</button>
                                  <button type="button" disableRipple class="mat-menu-item" *ngIf="row.status==0" (click)="pickedUp(row)"><i class="fa fa-cart-arrow-down fa-lg"></i>Picked By Vendor</button>
                                  <button type="button" disableRipple class="mat-menu-item" *ngIf="(row.status!=0) ? ((row.status==2) ? true : false) : false  " (click)="proceessQue(row)"><i class="fa fa-undo fa-lg"></i>Return Processed</button>
                                  <button type="button" disableRipple class="mat-menu-item" *ngIf="row.status==0" (click)="deleteQue(row)"><i class="fa fa-trash fa-lg"></i>Delete Entry</button>
                              </mat-menu>
                          </mat-cell>
                      </ng-container>
  
                      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  </mat-table>
                  <div class="padding-15 text-center" [style.display]="getTotalRecords() < 1 ? '' : 'none'">
                       No Records Available
              </div>
          </div>
          <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
              <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="options.limit" [pageSizeOptions]="[5, 10,25, 100]" (page)="paginateByLimit($event)" >
              </mat-paginator>
  
              <div class="mat-paginator table-paging">
                  <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
                  <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                          <mat-icon>navigate_before</mat-icon>
                  </button>
                  <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                          <mat-icon>navigate_next</mat-icon>
                  </button>
              </div>
          </div>
      </div>
      </div>
    </div>
  </div>