<button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
    <mat-icon>close</mat-icon>
  </button>
<mat-dialog-content class="ticket_dialog_content text-center">
    <div class="center-block search-employee" style="max-width: 600px;">
      <div class="row">
        <div class="col-sm-12 margin-bottom-15">
          {{param.msg}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <button type="button" class="font-bold new-customer-btn mat-elevation-z0 mat-raised-button mat-accent"
            (click)="supportRequest()" title="Open Support Request">Open Support Request</button>
        </div>
      </div>
    </div>
</mat-dialog-content>