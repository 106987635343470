<div mat-dialog-title class="relative">
  Payments
<button mat-mini-fab (click)="popupClose()" class="close-modal notranslate mat-elevation-z0">
<mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
</button>
</div>
<mat-dialog-content>
<div class="center-block" style="max-width: 600px;" *ngIf="isAllowed('addNew')">
<form class="form-horizontal form-control-normal" autocomplete="off" [formGroup]="addPayment">
  <div class="display-full margin-bottom-5">
    <label class="col-sm-3 control-label"><strong>Payment Method</strong></label>
    <div class="col-sm-9 uc-ngx-select">
        <ngx-select class="display-full margin-bottom-20" [items]="paymentMethod" formControlName="payment_method"
        placeholder="Select Payment Method" [noAutoComplete]="true" (select)="changeMethod($event)">
      </ngx-select>
    </div>
  </div>
  <div class="display-full margin-bottom-5">
    <label class="col-sm-3 control-label"><strong>Payment Amount</strong></label>
    <div class="col-sm-9">
      <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
        <input matInput type="text" maxlength='14' id="paymentAmountPopup" placeholder="{{dataSources.balance}}" (keyup)="vkFloat($event,'payment_amount')" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)"  formControlName="payment_amount" class="form-control input-lg custom-form-input btn-block"
        required/>
        <mat-error *ngIf="addPayment.controls.payment_amount.hasError('required')">
          Amount is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="display-full margin-bottom-5">
    <label class="col-sm-3 control-label"><strong>Payment Date</strong></label>
    <div class="col-sm-9">
      <mat-form-field class="car-input btn-block car-datePicker" floatPlaceholder="never" appearance="fill">
        <input matInput readonly class="form-control input-lg custom-form-input" formControlName="paymentDate" placeholder="{{maxDates}}" [max]="maxDate"
        [matDatepicker]="picker1" (focus)="picker1.open()" />
        <mat-datepicker-toggle matSuffix class="payment-date" [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker readonly #picker1></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="display-full margin-bottom-5" *ngIf="checkMethod">
    <label class="col-sm-3 control-label"><strong>Check Number</strong></label>
    <div class="col-sm-9">
      <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
        <input matInput type="text" placeholder="Check Number" formControlName="cheque_number" class="form-control input-lg custom-form-input btn-block"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="display-full margin-bottom-5" *ngIf="checkMethod">
    <label class="col-sm-3 control-label"><strong>Bank Name</strong></label>
    <div class="col-sm-9">
      <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
        <input matInput type="text" placeholder="Bank Name" formControlName="bank_name" class="form-control input-lg custom-form-input btn-block"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="display-full margin-bottom-5" *ngIf="creaditCard">
    <label class="col-sm-3 control-label"><strong>CC Last 4 Digits</strong></label>
    <div class="col-sm-9">
      <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
        <input matInput type="text" (keyup)="vkFloat($event,'cc_number')" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)" maxlength='4' placeholder="Last 4 Digits Only" formControlName="cc_number" class="form-control input-lg custom-form-input btn-block"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="display-full margin-bottom-5">
    <label class="col-sm-3 control-label"><strong>Notes</strong></label>
    <div class="col-sm-9 details-input">
      <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
        <textarea matInput placeholder="Notes" formControlName="payment_notes" rows="3" #notes maxlength="250"></textarea>
        <mat-hint align="end">{{notes.value?.length || 0}}/250 Characters Max</mat-hint>
      </mat-form-field>
    </div>
  </div>
  <div class="display-full margin-bottom-5">
    <label class="col-sm-3 control-label"><strong>&nbsp;</strong></label>
    <div class="col-sm-9">
      <button mat-raised-button (click)="addPayments()" class="font-bold" color="accent">Add Payment</button>
    </div>
  </div>
</form>
</div>
<div class="display-full margin-bottom-30" *ngIf="isAllowed('views')">
<h4 class="margin-bottom-30">Previous Payments for this Quick Quote:</h4>
<div class="table-responsive relative">
  <table class="table table-striped table-hover table-condensed sortable" id="paymentsList">
    <thead>
      <tr>
        <th class="">#</th>
        <th class="">Payment Method</th>
        <th class="">Date</th>
        <th class="">Amount</th>
        <th class="">CC</th>
        <th class="">Check No.</th>
        <th class="">Bank Name</th>
        <th width="15%" class="">Notes</th>
        <th class="sorttable_nosort" *ngIf="dataSources.request_status!='8' && isAllowed('deletes')">Actions</th>
      </tr>
    </thead>
    <tbody id="paymentsListView">
      <tr *ngFor="let payment of dataSources.data" [class.deletedPay]="payment.delete_status=='1'">
        <td class="">{{payment.payment_id}}</td>
        <td class="">{{getPayMethod(payment.payment_method)}}</td>
        <td class="">{{payment.payment_date}}</td>
        <td class="">{{payment.payment_amount}}</td>
        <td class="">{{payment.credit_card_number}}</td>
        <td class="">{{payment.cheque_number}}</td>
        <td class="">{{payment.bank_name}}</td>
        <td width="15%" class="">{{payment.notes}}</td>
        <td class="" *ngIf="isAllowed('deletes')">
          <input (click)="deletePayment(payment.payment_id)" [disabled]="payment.delete_status=='1'" type="button" value="Delete" class="btn btn-xs btn-red-theme deletePayment">
        </td>
      </tr>
      <tr *ngIf="dataSources.data.length==0">
        <td colspan="9" class="norecords-row">No Records Found.</td>
      </tr>
    </tbody>
    <tfoot></tfoot>
  </table>
</div>
<div class="row mrg-top-10">
  <div class="col-sm-4 col-xs-5 pad-top-6">Total Paid:</div>
  <div class="col-sm-8 col-xs-5 pad-top-6" id="payTotalPaid">${{dataSources.total_paid}}</div>
</div>
<div class="row mrg-top-10 hide">
  <div class="col-sm-4 col-xs-5 pad-top-6">Payment Balance:</div>
  <div class="col-sm-8 col-xs-5 pad-top-6" id="payBalance">${{dataSources.balance}}</div>
</div>

</div>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
<button mat-raised-button (click)="popupClose()" class="font-bold">Close</button>
</mat-dialog-actions>
