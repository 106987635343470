<div class="container accout-authorization">
    <div class="row bgcolor-lightgrey" id="contactus-thanks-msg"  >    
        <div class="col-xs-12 col-sm-12 thanks-msg text-center">
            <div class="form-group row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <section class="text-center" id="uc-page-error">
                        <div class="page-error-inner" *ngIf="isLinkUsed == 0">
                            <h1 class="uc-main-title">Account authorization</h1>
                            <p class="discription">
                                Your Security is important to us.
                                <br>
                                If you are aware of this activity and want to authorize to access your account, Select <b>Allow</b>.
                                <br>
                                If you do not want to allow access, select <b>Go Back</b>
                            </p>
                            <button mat-button class="list-action-btn" color="accent"  (click)="allow()">Allow</button>
                            <button class="list-action-btn back-btn" (click)="back()">Back</button>
                        </div>
                        <div class="page-error-inner" *ngIf="isLinkUsed == 1">
                            <h1 class="uc-main-title">Oops</h1>
                            <p class="discription">
                                It seems like your link is either expired or already been used.
                            </p>
                            <button class="list-action-btn back-btn" (click)="back()">Back</button>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>  
</div>