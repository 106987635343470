import { Component, OnInit, OnDestroy} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap,NavigationEnd, Params  } from '@angular/router';
import { Http,Headers, Response} from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { globalFunctions } from "../../../environments/globalFunctions";
import { globalConstants } from "../../../environments/globalConstants";
import {GlobalService} from "../../../environments/global.service";
import { lang } from "../../../environments/lang-en";
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TextMaskModule } from 'angular2-text-mask';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'shop-editgenericjob',
    templateUrl: 'shopeditgenericjob.component.html',
    styleUrls: ['shopeditgenericjob.component.scss']
})
export class ShopeditgenericjobComponent implements OnInit,OnDestroy {
  public currentCustomer;
  public currentCustomerName;
  public currentCustomerVehicles;
  public isCollapsed:boolean = true;
  public idFromUrl;
  public showSubscriptionMessage = false;
  public editJenericJob:FormGroup;
  public currentJobDetails;
  public job_id;
  public subscriptionDataForAPI: Subscription = new Subscription();

  constructor(private activatedRoute:ActivatedRoute,private formBuilder: FormBuilder,private route: ActivatedRoute,public titleService:Title,private http: Http,private router: Router,public snackBar: MatSnackBar, public globalService: GlobalService) {

    if(localStorage.getItem("loggedInUser")) {
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
        this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
        this.job_id = localStorage.getItem("shopJobID");
        this.editJenericJob = this.formBuilder.group({
            job_name: ['', [Validators.required]],
            cost: ['', [Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
            price: ['',[Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
            description: [''],
            user_type: this.currentCustomer.user_type,
            user_id: this.currentCustomer.id,
            status: [''],
            job_id: ['']
        })
        // End

    } else {
      this.router.navigate(["/shops"]);
    }
    setTimeout(()=>{
     let isEnable=this.globalService.getAcl('generic_job','edits');
     if(!(isEnable)){
        this.router.navigate(['shops/action/forbidden']);
     }
    },globalConstants.RELOAD_WAIT)
    this.globalService.setMetaData("SHOPS","EDIT_GENERIC_JOB");
  }

    setParams(){
      this.activatedRoute.params.subscribe((params: Params) => {
          this.idFromUrl = params["job_id"];
       });
    }

    ngAfterViewInit(){
        this.setParams();
        
        var shopContent = "user_id="+this.currentCustomer.id+"&user_type="+this.currentCustomer.user_type+"&job_id="+ this.job_id;
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_GENERIC_JOBS_LIST_URL,shopContent).subscribe((data:any) => {
            this.currentJobDetails = data.data;
          // this.editJenericJob.controls.job_id.setValue(this.currentJobDetails.id);
          //  this.editJenericJob.controls.approval_status.setValue(this.currentJobDetails.admin_approval_status);
        });
    }

    sideNavOpen(){
      console.log("nav toggle");
    }
    onResize(event) {
      event.target.innerWidth;
    }
    ngOnInit() {
      //this.titleService.setTitle(lang.TITLE_ADMIN_SIGN_IN);
    }
    getCurrentRoute() {
      return this.router.url
    }

    onlyDecimalNumberKey(event) {
      let charCode = (event.which) ? event.which : event.keyCode;
      if (charCode != 46 && charCode > 31
          && (charCode < 48 || charCode > 57))
          return false;
      return true;

    }
    disableKey(event) {
      let charCode = String.fromCharCode(event.which).toLowerCase();
      if (event.ctrlKey==true && (event.which == '67' || event.which == '86' ||  String.fromCharCode(event.which).toLowerCase() == 's')) {
                  console.log('thou. shalt. not. PASTE!');
                  event.preventDefault();
      }
    }
    
    editgenericjob() {
        if(this.editJenericJob.valid) {
            this.editJenericJob.value.job_id = this.job_id;
            this.editJenericJob.value.status = this.currentJobDetails.status;
            var stringifiedFormData = globalFunctions.stringifyFormData(this.editJenericJob.value,null);
            this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_EDIT_GENERIC_JOB_URL,stringifiedFormData).subscribe((data:any)=> {
                var message = data.message;
                if (data.result == "1") {
                  this.router.navigate(['shops//list-generic-jobs']);
                  this.globalService.snackbar('success', message);
                } else { 
                    this.globalService.snackbar('error', message);
                }
            });
        } else {
            this.mandatoryMsg();
        }
    }
    
    mandatoryMsg() {
        this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }

    validateNumbersWithDecimal(event) {
      return globalFunctions.validateNumbersWithDecimal(event,3);
    }

    convertIntoDecimal(event,frmControlName,beforeDecimalDigit){
      let fieldValue = this.editJenericJob.get(frmControlName).value
      let result= this.globalService.processDecimalValue(fieldValue,beforeDecimalDigit);
      this.editJenericJob.get(frmControlName).setValue(result);
      return globalFunctions.validateNumbersWithDecimal(event,3);
    }

    ngOnDestroy(){
      this.subscriptionDataForAPI.unsubscribe();
  }  
}
