import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-date-and-time-picker',
  templateUrl: './date-and-time-picker.component.html',
  styleUrls: ['./date-and-time-picker.component.scss']
})
export class DateAndTimePickerComponent implements OnInit {
  public color: ThemePalette = 'accent';
  @Input() importTime;
  @Output() SelectTime = new EventEmitter<any>();
  public dateAndTimeForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder
  ) {
    
   }

  ngOnInit(): void {
    this.dateAndTimeForm = this.formBuilder.group({
      dateAndTimeField: [new Date(this.importTime)]
    })
  }

  onDateChange(type: string, event: any) {
    this.SelectTime.emit(event);
  }


}
