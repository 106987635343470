import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { globalConstants } from "../../../environments/globalConstants";
import { GlobalService } from "../../../environments/global.service";
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AppComponent } from '../../app.component'
import * as moment from 'moment';
import { UpdateCreditCardComponent } from '../update-credit-card/update-credit-card.component';

@Component({
  selector: 'app-shop-profile-detail',
  templateUrl: './shop-profile-detail.component.html',
  styleUrls: ['./shop-profile-detail.component.scss']
})
export class ShopProfileDetailComponent implements OnInit {
  public shopDetails: any = {};
  public idFromUrl: any;
  public user_type: any;
  public currentCustomer: any;
  public isSubscribed: any;
  public subscriptionAmount: any;
  public expiryDate: any;
  public subscriptionPlanName: any;
  public planExpired: any;
  public subscriptionDesc: any;
  public subscriptionDuration: any;
  public subscriptionDurationType: any;
  public quick_quote: any;
  public shopID: any;
  public ccInfoResult: any;
  public ccMaskedNumber: any;
  public ccPaymentToken: any;
  public displayCCDetails: any;
  public roleID: any;
  public openingTime = '- to -';
  public closeingTime = '-';
  public profileType: any = 0;
  public subscriptionDataForAPI: Subscription = new Subscription();
  public isPlanExpiredOrCancelled: any = 0;

  constructor(
    public app: AppComponent,
    public titleService: Title,
    private router: Router,
    private globalService: GlobalService,
    public dialog: MatDialog,
  ) {
    this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
    this.globalService.setMetaData("SHOPS","SHOPPROFILE");
    if (this.currentCustomer) {
      this.shopID = this.currentCustomer.shop_id;
      this.isSubscribed = this.currentCustomer.is_subscribed;
      this.roleID = this.currentCustomer.role_id;

      if (this.isSubscribed) {
        this.subscriptionAmount = this.currentCustomer.subscription_data.amount;
        this.expiryDate = this.currentCustomer.subscription_data.expiry_date;

        if (this.expiryDate != "0000-00-00 00:00:00") {
          let currentDate = new Date();
          if (new Date(currentDate) > new Date(this.expiryDate)) {
            this.isPlanExpiredOrCancelled = 2;
            this.expiryDate = this.globalService.getFullFormateDate(this.currentCustomer.subscription_data.expiry_date, "GD", "")
          } else {
            this.isPlanExpiredOrCancelled = 0;
            this.expiryDate = this.globalService.getFullFormateDate(this.currentCustomer.subscription_data.expiry_date, "GD", "")
          }
        }
        if (this.currentCustomer.subscription_data.order_status == '1') {
          this.isPlanExpiredOrCancelled = 1;
        }

        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_SHOP_SUBSCRIPTION_TYPES_URL, "opr=1&user_id=" + this.globalService.getCurrentUser().id).subscribe((data: any) => {
          if (data.result == '1') {
            if (data.data.length > 0) {
              this.subscriptionPlanName = data.data[0].subscription_name;
              this.subscriptionDesc = data.data[0].subscription_desc;
              this.subscriptionDuration = data.data[0].duration;
              this.quick_quote = data.data[0].quick_quote;
              this.subscriptionDurationType = data.data[0].duration_type;
            }
          }
        })
        /* GET CC DETAILS */
        const resquestdata = "user_id=" + this.globalService.getCurrentUser().id + "&shop_id=" + this.globalService.getCurrentUser().shop_id + "&user_type=" + this.globalService.getCurrentUser().user_type;
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_BT_CC_INFO_URL, resquestdata).subscribe((data: any) => {
          if (data.result == '1') {
            if (data.data != null) {
              this.ccInfoResult = data.data;
              this.displayCCDetails = true;
              this.ccMaskedNumber = this.ccInfoResult.maskedNumber;
              this.ccPaymentToken = this.ccInfoResult.payment_token;
            } else {
              this.displayCCDetails = false;
            }
          } else {
            this.displayCCDetails = false;
          }
        });
      }
    } else {
      this.router.navigate(["shops"]);
    }
  }

  ngOnInit() {
    const shopID = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&shop_id=" + this.globalService.getCurrentUser()["shop_id"];
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_SHOP_DETAILS_ON_DASHBOARD, shopID).subscribe((res: any) => {
      if (res.result == "1") {
        this.shopDetails = res.data;
        this.globalService.setShopDetails(this.shopDetails);
        if (typeof this.shopDetails.shop_timing != 'undefined') {
          let shopTiming = this.shopDetails.shop_timing;
          if (shopTiming) {
            let v = shopTiming.open_time.split(':');
            let defaultOpenTime = (parseInt(v[0]) * 60) + parseInt(v[1]);
            let close = shopTiming.close_time.split(':');
            let defaultCloseTime = (parseInt(close[0]) * 60) + parseInt(close[1]);
            this.shopTiming([defaultOpenTime, defaultCloseTime]);
          }
        }
      }
    }, (error) => { });
  }

    /* REDIRECT TO SUBSCRIPTION PAGE */
    gotoShopSubscription() {
      this.router.navigate(['/shops/subscription']);
    }
  
    /* CANCEL SUBSCRIPTION */
    cancelSubscription(shop_id) {
  
      this.globalService.confirmDialog(shop_id, 'Do you really want to cancel your subscription?').subscribe((res) => {
        if (res != null) {
          var stringifiedFormData = "user_type=" + this.currentCustomer.user_type + "&user_id=" + this.currentCustomer.id + "&shop_id=" + shop_id;
          this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_CANCEL_BT_SHOP_SUBSCRIPTION_URL, stringifiedFormData).subscribe(
            (data: any) => {
              var message = data.message;
              if (data.result == "1") {
                this.isPlanExpiredOrCancelled = 2;
                var addNewKey = JSON.parse(localStorage.getItem("loggedInUser"));
                addNewKey.subscription_data.order_status = '1';
                localStorage.setItem("loggedInUser", JSON.stringify(addNewKey));
                this.globalService.snackbar('success', message);
              } else {
                this.globalService.snackbar("error", message);
                this.isPlanExpiredOrCancelled = 0;
              }
            });
        }
      });
    }
  
    /* UPDATE CC DETAILS */
    updateCCDetails(paymentToken) {
      let dialogRef;
      dialogRef = this.dialog.open(UpdateCreditCardComponent, {
        disableClose: true,
        panelClass: 'car-dialog-form'
  
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result.data != "" && result.data != "3") {
          this.ccMaskedNumber = result.data;
        }
      });
    }

  editProfile() {
    this.router.navigate(['shops/edit-shop']);
  }
  isAllowed(action, module?: any) {
    if (typeof module != 'undefined') {
      return this.globalService.isAllowed(this.app, module, action);
    }
    return false;
  }
  /** Shop open close time  */
  shopTiming(event) {
    let h: any = Math.floor(event[0] / 60);
    let m: any = Math.floor(event[0] % 60);
    if ((m).toString().length <= 1) {
      m = '0' + m;
    }
    if ((h).toString().length <= 1) {
      h = '0' + h;
    }
    let AMPM = ' AM';
    if (h >= 12) {
      h = (h - 12 ? h - 12 : h);
      AMPM = ' PM';
    }
    if ((h).toString().length <= 1) {
      h = '0' + h;
    }
    this.openingTime = h + ':' + m + AMPM;

    let h1: any = Math.floor(event[1] / 60);
    let m1: any = Math.floor(event[1] % 60);
    if ((m1).toString().length <= 1) {
      m1 = '0' + m1;
    }
    if ((h1).toString().length <= 1) {
      h1 = '0' + h1;
    }
    let AMPM1 = ' AM';
    if (h1 >= 12) {
      h1 = (h1 - 12 ? h1 - 12 : h1);
      AMPM1 = ' PM';
    }
    if ((h1).toString().length <= 1) {
      h1 = '0' + h1;
    }
    this.closeingTime = h1 + ':' + m1 + AMPM1;
    this.openingTime = this.openingTime + ' to ' + this.closeingTime;
  }

  convertTime(timeSlot) {
    return moment(timeSlot, ["hh:mm:ss"]).format("hh:mm A");
  }
  ngOnDestroy() {
    this.subscriptionDataForAPI.unsubscribe();
  }
}

