<div class="container-fluid">
    <div class="padding-top-25">
        <div class="login-panel padding-15">
            <div id="resetPasswordContent" class="row bgcolor-lightgrey">
                <div class="login-panel">
                    <h1 class="text-center main-heading">RESET PASSWORD</h1>
                    <form [formGroup]="change_pass" novalidate (ngSubmit)="resetPassword()">
                        <div class="col-xs-12 form-block">

                            <div class="row">
                                <div class="form-group">
                                    <label for="new_password">New Password</label>
                                    <div class='relative'>
                                        <mat-form-field class="car-input btn-block input-suffix" floatPlaceholder="never" appearance="fill">
                                            <input type="password" matInput placeholder="New Password"  formControlName="password"  class="form-control input-lg custom-form-input" [type]="hide ? 'text' : 'password'">
                                            <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                            <mat-error *ngIf="change_pass.controls.password.hasError('required')">
                                                Password is <strong>required</strong>
                                            </mat-error>
                                            <mat-error *ngIf="change_pass.controls.password.hasError('minlength')">
                                                The password must be minimum 6 characters
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group">
                                    <label for="new_password">Confirm Password</label>
                                    <div class='relative'>
                                        <mat-form-field class="car-input btn-block input-suffix" floatPlaceholder="never" appearance="fill">
                                            <input type="password" matInput placeholder="Confirm Password"  formControlName="confirm_password" class="form-control input-lg custom-form-input" [type]="hide1 ? 'text' : 'password'" >
                                            <mat-icon matSuffix (click)="hide1 = !hide1">{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                            <mat-error *ngIf="change_pass.controls.confirm_password.hasError('required')">
                                                Password is <strong>required</strong>
                                            </mat-error>
                                            <mat-error *ngIf="change_pass.controls.confirm_password.hasError('minLength')">
                                                The password must be minimum 6 characters
                                            </mat-error>
                                            <mat-error *ngIf="change_pass.controls.confirm_password.hasError('matched')">
                                                New and confirm password should be same
                                            </mat-error>
                                        </mat-form-field>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-block">
                                    <button type="submit" mat-raised-button class="car-btn btn-block text-uppercase  btn-lg font-bold fontcolor-white" color="accent" title="Submit">Submit</button>
                                </div>
                            </div>
                    </div>


                    </form>
                </div>


            </div>
        </div>

    </div>
</div>
