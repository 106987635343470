<div mat-dialog-title class="relative clipboard-dialog-title">
  Clipboard
  <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0 pull-right">
      <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="clipboard-content">
  <div class="centerblock clipboard-modal">
    <div class="display-fullmargin-bottom-5">
      <div class="padding-15 text-center" [class.hide]="(jobs.length > 0)">No Records Available </div>
      <mat-accordion class="new-request-expension showprice margin-bottom10 margin-top10 displayfull"
        displayMode="flat" hideToggle="false">
        <mat-expansion-panel hideToggle="true"  class="new-request-expension relative show-copy-paste"
          *ngFor="let j of jobs;let i = index" id="expansion-{{i+1}}">
          <mat-expansion-panel-header  id="expansionHead-{{i+1}}">
            <div class="job-content-box">
              <span class="margin-right-5 jobName">{{ i+1 }}.&nbsp;<b>{{ j.name }} </b></span>
              <span class="margin-left-2">
                  {{getVehicleName(j.vehicleInfo)}}
                </span>
            </div>
            <div class="button-section">
            <span type="button" class="btn-copy-paste copy" (click)="pasteData(j)">
              <i class="fa fa-clipboard"></i><span class="fs-13-mob">Paste</span>
            </span>
            <span type="button" class="btn-copy-paste" (click)="removeData($event,i)">
              <i class="fa fa-trash"></i><span class="fs-13-mob">Remove</span>
            </span>
            </div>
          </mat-expansion-panel-header>
          <div class="service-list-items fs-13-mob">
            <div *ngFor="let r of j.recomm">
              <div class="col-sm-12 padding-0" *ngIf="r.parts.length > 0">
                <div class="form-group">
                  <label class="col-xs-3 col-sm-3 text-right control-label">Parts:</label>
                  <div class="col-xs-9 col-sm-9 text-justify break-all">
                    <div *ngIf="r.parts.length > 0" class="text-justify break-all">
                      <div *ngFor="let p of r.parts">{{p.name}} <span class="qty">({{p.quantity}})</span></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 padding-0" *ngIf="r.generic_jobs.length > 0">
                <div class="form-group">
                  <label class="col-xs-3 col-sm-3 text-right control-label">Generic Jobs:</label>
                  <div class="col-xs-9 col-sm-9 text-justify break-all">
                    <div  *ngIf="r.generic_jobs.length > 0" class="text-justify break-all">
                      <div *ngFor="let g of r.generic_jobs">{{g.name}} <span class="qty">({{g.quantity}})</span></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 padding-0" *ngIf="r.computer_codes.length > 0">
                <div class="form-group">
                  <label class="col-xs-3 col-sm-3 text-right control-label">Computer codes:</label>
                  <div class="col-xs-9 col-sm-9 text-justify break-all">
                    <div *ngIf="r.computer_codes.length > 0" class="text-justify break-all">
                      <div *ngFor="let computerCode of r.computer_codes">{{computerCode.trouble_code}} {{computerCode.fault_location}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 padding-0">
                <div class="form-group">
                  <label class="col-xs-3 col-sm-3 text-right control-label">Nexpart Labor:</label>
                  <div class="col-xs-9 col-sm-9 text-justify break-all">
                    <div>{{r.nexpart_labor_time}} hour(s) </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 padding-0">
                <div class="form-group">
                  <label class="col-xs-3 col-sm-3 text-right control-label">Time:</label>
                  <div class="col-xs-9 col-sm-9 text-justify break-all">
                    <div>{{r.estimated_time}} hour(s) </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 padding-0">
                <div class="form-group">
                  <label class="col-xs-3 col-sm-3 text-right control-label">Labor:</label>
                  <div class="col-xs-9 col-sm-9 text-justify break-all">
                    <div>$ {{r.estimated_labor}}</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 padding-0" *ngIf="r.recommendation !== ''">
                <div class="form-group">
                  <label class="col-sm-3 text-right control-label">Description of Work:</label>
                  <div class="col-sm-9 text-justify break-all">
                      <p class="margin-0" [innerHtml]="convertNltoBr(r.recommendation)"></p>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 padding-0" *ngIf="r.notes !== ''">
                <div class="form-group">
                  <label class="col-sm-3 text-right control-label">Note:</label>
                  <div class="col-sm-9 text-justify break-all">
                    <p class="margin-0" [innerHtml]="convertNltoBr(r.notes)"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
  <button mat-raised-button color="accent" [disabled]="jobs.length == 0" (click)="clearClipboard()" class="font-bold pull-right">Clear</button>
  <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
</mat-dialog-actions>
