import { Component, OnInit, ViewChild, ChangeDetectorRef,OnDestroy } from '@angular/core';
import { ViktableComponent, VikSource } from '../../../admin/viktable/viktable.component';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
import { ReturnpickupComponent } from '../returnpickup/returnpickup.component';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
const UPLOAD_LIST = "return-que-";
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-returnquelist',
  templateUrl: './returnquelist.component.html',
  styleUrls: ['./returnquelist.component.scss']
})
export class ReturnquelistComponent extends ViktableComponent implements OnInit,OnDestroy  {

  public tablesData = this;
  displayedColumns = ['checkbox', 'Name', 'partnumber', 'Qty', 'RequestID', 'Create At', 'AddedBy', 'Status','vendor_pickup_date', 'Actions'];
  public status = ['Pending', 'Move to local Inventory', 'Picked by Vendor', 'Return Processed'];
  dataSource: any;
  url = "";
  public options:any = {
    user_type: "User",
    user_id: "",
    search_type:0,
    picByVendor:'',
    search_keyword: "",
    item_status:'',
    start: 0,
    limit: 10
  }
  public user: any = {};
  public selectedRow = [];
  public applyToAllrow: any = '0';
  public selectAll = false;
  public partTempid:any='';
  public searchByArray = [{ id: 0, text: "Search By"},{ id: 2, text: "Part Name"},{ id: 1, text: "Part Number"}, { id: 3, text: "Request ID"},{ id: 4, text: "Status"},{ id: 5, text: "Date"}];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public subscriptionDataForAPI: Subscription = new Subscription();

  constructor(public gs: GlobalService, public router: Router, public dialog: MatDialog,public cdr: ChangeDetectorRef) {
    super(globalConstants.API_PARTS_RETURN_QUEUE, gs);
    this.user = this.gs.getCurrentUser();
    if (this.user) {
      this.sessionKey = UPLOAD_LIST;
      this.url = globalConstants.API_PARTS_RETURN_QUEUE;
      this.gs.setMetaData("SHOPS", "RETURN_QUEUE_LIST")
    } else {
      this.router.navigate(['shops']);
    }
  }

  ngOnInit() {
    setTimeout(() => {
      let isEnable = this.gs.getAcl('return_queue', 'addNew');
      if (!(isEnable)) {
        this.router.navigate(['shops/action/forbidden']);
      }
    }, globalConstants.RELOAD_WAIT);
    this.reInit();
    // this.setDataTable();
  }
  /** init options */
  reInit() {
    this.options = this.gs.localGetterSetter(this.options, this.options, UPLOAD_LIST);
    this.options.user_type = this.user.user_type;
    this.options.user_id = this.user.id;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setDataTable();
    },0)
  
  }
  /** set data source of return que list */
  setDataTable() {
    this.selectedRow = [];
    this.applyToAllrow = '0';
    this.selectAll = false;
    this.resultData=[];
    this.dataSource = new VikSource(this.tablesData, this.sort, this.paginator);
    this.dataSource.getSortedData = this.sortedData;
  }
  /** create record, that show on return queue list */
  createRecordrecord(record) {
    let data = {
      id: record.id,
      name: record.name,
      quantity: record.quantity,
      request_number: record.request_number,
      statustxt: this.showStatus(record.status),
      status: record.status,
      part_number: record.part_number,
      add_date: this.gs.getFullFormateDate(record.add_date, "G", "B"),
      added_by_name: record.added_by_name,
      checked: false,
      vendor_pickup_date:this.gs.getFullFormateDate(record.vendor_pickup_date, "G", "H")
    };
    this.resultData.push(data);
    return data;
  }
  /** sort return que list */
  sortedData(): any[] {
    let th: any = this;
    const data = th._exampleDatabase.data.slice();
    if (!th._sort.active || th._sort.direction == '') { return data; }
    return data.sort((a, b) => {
      let propertyA;
      let propertyB;
      switch (th._sort.active) {
        case 'Name': propertyA = a.name; propertyB = b.name; break;
        case 'Create At': propertyA = a.added_on; propertyB = b.added_on; break;
        default: return 0;
      }
      let valueA = isNaN(propertyA) ? propertyA.toUpperCase() : propertyA;
      let valueB = isNaN(propertyB) ? propertyB.toUpperCase() : propertyB;

      return (valueA < valueB ? -1 : 1) * (th._sort.direction == 'asc' ? 1 : -1);
    });
  }
  /** return return queue status */
  showStatus(s) {
    return (typeof this.status[s] == 'undefined' ? 'N/A' : this.status[s]);
  }
  /** part move to local inventory */
  moveTolocal(row) {
    this.selectedRow=[];
    this.selectedRow.push(row.id);
    this.partTempid=row.id;
    this.applyToAllrow = '1';
    this.selectAll = false;
    this.applyToAll({});
  }
  /** part picked by Vendor */
  pickedUp(row) {
    this.selectedRow=[];
    this.selectedRow.push(row.id);
    this.partTempid=row.id;
    this.applyToAllrow = '2';
    this.selectAll = false;
    this.getPickupDetail();
  }
  proceessQue(row) {
    this.selectedRow=[];
    this.selectedRow.push(row.id);
    this.partTempid=row.id;
    this.applyToAllrow = '3';
    this.selectAll = false;
    this.applyToAll({});
  }
  deleteQue(row) {
    this.selectedRow=[];
    this.selectedRow.push(row.id);
    this.partTempid=row.id;
    this.applyToAllrow = '4';
    this.applyToAll({});
    this.selectAll = false;
  }
  /** get slected row */
  checked(e, r) {
    // console.log(this.selectedRow);
    if (e.checked) {
      this.selectedRow.push(r.id);
    } else {
      let i = this.selectedRow.indexOf(r.id);
      this.selectedRow.splice(i, 1);
      this.selectAll = false;
      
    }
    // console.log(this.selectedRow);
  }
  /** select all row */
  checkedAll(e) {
    this.selectedRow=[];
    // this.dataChange = new BehaviorSubject<any[]>([]);
    if (e.checked) {
      this.resultData.forEach(ele => {
        let chkBox = ((ele.status != 0) ? ((ele.status == 2) ? true : false) : true);
        if (chkBox) {
          ele.checked = true;
          // this.AddRecord(ele);
          this.selectedRow.push(ele.id);
        }
      });
    } else {

      this.resultData.forEach(ele => {
        ele.checked = false;
        // this.AddRecord(ele);
      });
    }
    // console.log(this.selectedRow,'nono')
  }
  /** action apply to all row */
  applyToAll(e) {
    if (this.applyToAllrow == 2) {
      this.getPickupDetail();
    } else {
      this.gs.confirmDialog("sure", "Are you sure?").subscribe((response) => {
        if (response != undefined && response.toString() == "sure" ) {
          this.applyAction(false);
        }else{
          if(this.partTempid){
            let i = this.selectedRow.indexOf(this.partTempid);
            this.selectedRow.splice(i, 1);
            this.partTempid='';
            this.applyToAllrow = '0';
          }
          this.cdr.detectChanges();
        }
      });
    }
  }
  /** fill vendor detail using popup */
  getPickupDetail() {
    let data = {add_date:'',description:'',doc_file:'', user_id: this.user.id, que_items: JSON.stringify(this.selectedRow), opr: 1, status: this.applyToAllrow };
    let dialogRef = this.dialog.open(ReturnpickupComponent, {
      panelClass: ['car-dialog-form','pickup-form-dialog'],
      width: '800px',
      data: {}
    });
    
    dialogRef.afterClosed().subscribe(result => {
      this.cdr.markForCheck();
      if (result == 1) {
        // console.log(dialogRef['ref']);
        data.add_date=dialogRef['ref'].add_date;
        data.description=dialogRef['ref'].description;
        data.doc_file=dialogRef['ref'].doc_file;
        this.applyAction(true,data);
      }else{

        if(this.partTempid){
          let i = this.selectedRow.indexOf(this.partTempid);
          this.selectedRow.splice(i, 1);
          this.partTempid='';
          this.applyToAllrow = '0';
        }
        this.cdr.detectChanges();
      }
      // console.log(this.selectedRow);
    });
  }
  /** apply action according to selected option */
  applyAction(cond,content?:any){
    // console.log(this.selectedRow);
    let data = { user_id: this.user.id, que_items: JSON.stringify(this.selectedRow), opr: 1, status: this.applyToAllrow };
    if (this.applyToAllrow == 4) {
      data.opr = 2;
    }
    if(cond){
      data=content;
    }
    this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_UPDATE_PARTS_RETURN_QUEUE, data).subscribe((r:any) => {
      if (r.result == 1) {
        this.gs.snackbar("success", r.message);
        this.setDataTable();
      } else {
        this.gs.snackbar("error", r.message);
      }
    }, (error) => {
      this.gs.snackbar("error", 'Something went wrong, please try later or check internet connection.');
      this.setDataTable();
    });
  }
  /** show return process detail */
  returnView(row){
    this.router.navigate(['shops/return/view/'+row.id]);
  }
  checkFormEmpty(button) {
    if (this.options.search_keyword == "" && this.options.search_type==0) {
        if (button) {button.color = "primary"}
        return false
    }
    else {
        if (button) {button.color = "accent"}
        return true;
    }
}
clearSearch() {
  this.gs.localGetterSetter(null,this.options,UPLOAD_LIST);
  this.reInit();
  this.options.search_type=0;
  this.options.item_status="";
  this.options.picByVendor = "";
  this.options.search_keyword = "";
  this.setDataTable();
}
search(event) {
  this.options.start = 0;
  if(this.options.search_type==5){
    let d = new Date(this.options.picByVendor);
    let m=(d.getMonth() + 1).toString();
    let md=(d.getDate()).toString();
    this.options.search_keyword = d.getFullYear()+'-' +('00'+m).slice(m.length) + '-' + ('00'+md).slice(md.length);
  }
  this.setDataTable();
}

getAttrVal(status){
  if(status == 0 || status == 2){
    return 'Select';
  }else{
    return '';
  }
}

ngOnDestroy(){
  this.subscriptionDataForAPI.unsubscribe();
}  

}
