
<div mat-dialog-title class="relative">
    <h4 class="margin-top-0">Customer Already Exists ?</h4>
    <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
        <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
      </button>
</div>
<mat-dialog-content class="overflow-data">
    <div class="center-block" style="max-width: 600px;">
        <form class="form-horizontal form-control-normal">
            <div class="customer-search">
                <div class="row margin-bottom-20 display-full">
                    <div class="col-xs-4">
                        <div class="field-name">
                            Name
                        </div>
                    </div>
                    <div class="col-xs-8">
                        <div class="field-name" *ngIf="customerDetails">
                            {{customerDetails.first_name}} {{customerDetails.last_name}}
                        </div>
                    </div>
                </div>
                <div class="row margin-bottom-20 display-full">
                    <div class="col-xs-4">
                        <div class="field-name">
                            Email address
                        </div>
                    </div>
                    <div class="col-xs-8">
                        <div class="field-name email-address" *ngIf="customerDetails">
                            <a class="text-black" href="mailto:{{customerDetails.email}}">
                                {{customerDetails.email}}
                            </a>
                            <!-- <button type="button" mat-dialog-close class="close-modal" (click)="sendMail()">
                                <i class="fa fa-envelope"></i>
                            </button> -->
                        </div>
                    </div>
                </div>
                <div class="row margin-bottom-20 display-full">
                    <div class="col-xs-4">
                        <div class="field-name">
                            Mobile
                        </div>
                    </div>
                    <form role="form" [formGroup]="newCustomer" novalidate>
                        <div class="col-xs-8" *ngIf="!isValidated">
                            <div class="field-name" *ngIf="customerDetails">
                                ***-***-****
                            </div>
                            <div class="field-name margin-bottom-15">
                                <span>Enter Mobile Number</span>
                                <input  matInput placeholder="Mobile Number" title="Mobile Number" formControlName="mobile_phone"  class="form-control input-lg custom-form-input" required maxlength="15" [textMask]="{mask: phoneMask}">
                                <mat-error *ngIf="submitted && newCustomer.controls.mobile_phone.hasError('required')">
                                    Mobile Number is <strong>required</strong>
                                </mat-error>
                                <mat-error *ngIf="submitted && newCustomer.controls.mobile_phone.hasError('minLength') && !newCustomer.controls.mobile_phone.hasError('required')">
                                    Invalid Mobile Number
                                </mat-error>    
                            </div>
                            <button type="button" class="car-btn fontcolor-white mat-raised-button mat-accent" (click)="validate()">Validate</button>
                        </div>    
                    </form>
                    <div class="col-xs-8" *ngIf="isValidated">
                        <div class="field-name number-show" *ngIf="validatePhone" >
                            <div class="field-name" *ngIf="customerDetails">
                                <a class="text-black" href="tel:{{customerDetails.mobile_phone}}">
                                    {{customerDetails.mobile_phone}}&nbsp;&nbsp;&nbsp;                           </a>
                            </div>
                            <span style="color:#4B96FF">Verified</span>
                        </div>
                        <div class="field-name number-show" *ngIf="!validatePhone" >
                            ***-***-****&nbsp;&nbsp;&nbsp;
                            <span style="color:red">Not Verified</span>
                        </div>
                    </div>
                </div>
                <div class="row margin-bottom-20 display-full" *ngIf="alternate_phone !== '' && !validatePhone">
                    <div class="col-xs-4">
                        <div class="field-name">
                        </div>
                    </div>
                    <div class="col-xs-8">
                        <div class="field-name">
                            <div class="field-name number-show" *ngIf="customerDetails">
                                <a class="text-black" href="tel:{{alternate_phone}}">
                                    {{alternate_phone}}&nbsp;&nbsp;&nbsp;                            
                                </a>
                            </div>
                            <span style="color:#4B96FF; margin-right:5px;">Saved as Alternate Contact</span>
                            <span class="tooltip-status text-red fa fa-pencil" title="Edit" (click)="updateAlterNumber()"></span>
                        </div>
                    </div>
                </div>
                <!-- <div class="row row display-full" *ngIf="!isValidated">
                    <div class="col-sm-4 col-xs-4 d-none">
                        <div class="field-name"> </div>
                    </div>
                    <div class="col-sm-8 col-xs-8 small">
                        <div class="row">
                            <div class="col-xs-6">
                                <button type="button" class="btn blue-btn" (click)="skipValidation()">Skip</button>
                            </div>
                            <div class="col-xs-6">
                                <button type="button" mat-dialog-close class="close-modal btn blue-btn">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="row row display-full" *ngIf="isValidated">
                    <div class="col-sm-12 col-xs-12 small">
                        <div class="row">
                            <div class="col-xs-2">
                                <button type="button" [routerLink]="['/shops/view-customer/',customerId]" mat-dialog-close class="close-modal btn fontcolor-white mat-raised-button mat-accent">View</button>
                            </div>
                            <div class="col-xs-4">
                                <button type="button" [routerLink]="['/shops/new-service-request',customerId]" mat-dialog-close  class="close-modal btn fontcolor-white mat-raised-button mat-accent">Create Service Request</button>
                            </div>
                            <div class="col-xs-3">
                                <button type="button" mat-dialog-close  class="close-modal btn fontcolor-white mat-raised-button mat-accent" (click)="viewVehicle()">View Vehicles</button>
                            </div>
                            <div class="col-xs-3">
                                <button type="button" mat-dialog-close class="close-modal btn blue-btn">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
    <div class=" display-full" *ngIf="!isValidated">
        <div class=" button-section">
            <ul class="padding-0 margin-0">
                <li class="margin-left-0">
                    <button type="button" class="btn blue-btn" (click)="skipValidation()">Skip</button>
                </li>
                <li>
                    <button type="button" mat-raised-button mat-dialog-close class="close-modal font-bold">Cancel</button>
                </li>
            </ul>
        </div>
    </div>
    <div class="display-full" *ngIf="isValidated && customerDetails">
        <div class="button-section">
            <ul class="padding-0 margin-0">
                <li class="margin-left-0">
                    <button type="button" [routerLink]="['/shops/view-customer/',customerDetails.customer_id]" [mat-dialog-close]="true" class="close-modal btn fontcolor-white mat-raised-button mat-accent">View</button>
                </li>
                <li *ngIf="customerDetails && customerDetails.is_sublet == '0'">
                    <button type="button" [routerLink]="['/shops/new-service-request',customerDetails.customer_id]" [mat-dialog-close]="true" class="close-modal btn fontcolor-white mat-raised-button mat-accent">
                        <span class="hidden-xs">Create Service Request</span>
                        <span class="visible-xs">Create SR</span>
                    </button>
                </li>
                <li *ngIf="customerDetails && customerDetails.is_sublet == '1'">
                    <button type="button" [routerLink]="['/shops/new-service-request',customerDetails.customer_id]" [mat-dialog-close]="true" class="close-modal btn fontcolor-white mat-raised-button mat-accent">
                        <span class="hidden-xs">Create Sublet Service Request</span>
                        <span class="visible-xs">Create Sublet SR</span>
                    </button>
                </li>
                <li>
                    <button type="button" [mat-dialog-close]="true"  class="close-modal btn fontcolor-white mat-raised-button mat-accent" (click)="viewVehicle()">View Vehicles</button>
                </li>
                <li>
                    <button type="button" mat-raised-button mat-dialog-close class="close-modal font-bold btn blue-btn">Cancel</button>
                </li>
            </ul>
        </div>
    </div>
</mat-dialog-actions>