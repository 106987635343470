<div class="panel panel-primary edit-employee" id="mainCompotents">
  <div class="panel-heading padding-left-15">
    <h3 class="panel-title">Edit Employee</h3>
    <button mat-mini-fab class="custom-btn-back" title="Back" (click)="globalService.goBack();">
        <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <div class="panel-body bgcolor-lightgrey padding-0 no-border">

    <!-- Login page start -->
    <div class="bgcolor-lightgrey form-content">
      <div class="container-fluid">
        <form class="form-inline col-xs-12 padding-bottom-15 edit-employee-form" role="form" [formGroup]="editEmployee" novalidate (ngSubmit)="updateEmployee()">
          <div class=" col-xs-12 form-block">
            <h2 class="fontcolor-blue sub-heading">LOGIN INFORMATION</h2>
            <div class="row">
              <div class="form-group col-sm-12 col-md-6 uc-ngx-select" *ngIf="employeeRole !== 'Shop Owner'">
                <ngx-select class="display-full margin-bottom-20" [items]="getUserTypes" formControlName="employee_type"
                  placeholder="Select Employee Type *" [noAutoComplete]="true" optionTextField="name" (select)="checkIfValid();selfCheckinValidation($event)">
                </ngx-select>  
                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                  <mat-error *ngIf="editEmployee.controls.employee_type.hasError('required')">
                    Employee Type is
                    <strong>required</strong>
                  </mat-error>
                </div>
              </div>
              <div class="form-group col-sm-12 col-md-6">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                  <input matInput placeholder="Email Address*" formControlName="email" maxlength="200" class="form-control input-lg custom-form-input" (keyup)="checkIfValid()"
                    (blur)="checkEmail()" />
                  <mat-error *ngIf="editEmployee.controls.email.hasError('pattern')">
                    Please enter a valid Email Address
                  </mat-error>
                  <mat-error *ngIf="editEmployee.controls.email.hasError('required')">
                    Email Address is
                    <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="editEmployee.controls.email.hasError('alreadyExit')">
                    Email address <strong>already exists. </strong>
                    <a href="javascript:void(0)" *ngIf="getEmailCheck.is_same" (click)="customerInformationPage(getEmailCheck.email)">Click Here.</a>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class=" col-xs-12 form-block">
            <h2 class="fontcolor-blue sub-heading">PERSONAL INFORMATION</h2>
            <div class="row">
              <div class="form-group col-sm-12 col-md-4">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                  <input matInput placeholder="First Name *" title="First Name" maxlength="200" formControlName="first_name" class="form-control input-lg custom-form-input"
                    (keyup)="checkIfValid()">
                  <mat-error *ngIf="editEmployee.controls.first_name.hasError('required')">
                    First Name is
                    <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="editEmployee.controls.first_name.hasError('pattern')">
                    Must not contain any special characters.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-group col-sm-12 col-md-4">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Middle Name" title="Middle Name" formControlName="middle_name" maxlength="200" class="form-control input-lg custom-form-input">
                  <mat-error *ngIf="editEmployee.controls.middle_name.hasError('pattern')">
                    Must not contain any special characters.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-group col-sm-12 col-md-4">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" hideRequiredMarker="false" appearance="fill">
                  <input matInput placeholder="Last Name *" title="Last Name" formControlName="last_name" maxlength="200" class="form-control input-lg custom-form-input"
                    (keyup)="checkIfValid()">
                  <mat-error *ngIf="editEmployee.controls.last_name.hasError('required')">
                    Last Name is
                    <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="editEmployee.controls.last_name.hasError('pattern')">
                    Must not contain any special characters.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class=" col-xs-12 form-block">
            <h2 class="fontcolor-blue sub-heading">CONTACT INFORMATION</h2>
            <div class="row">
              <div class="form-group col-sm-12 col-md-6 margin-top-0">
                <mat-checkbox class="btn-block margin-top-10" labelPosition="after" (change)="changeValidation($event)" [checked]="addressCheckbox">
                  Address Line 1 Required
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-12 col-md-6">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill"> 
                  <input matInput [placeholder]="addressPlaceHolder" title="Address Line 1" formControlName="address_one" maxlength="200" class="form-control input-lg custom-form-input"
                    (keyup)="checkIfValid()">
                  <mat-error *ngIf="editEmployee.controls.address_one.hasError('required')">
                    Address Line 1 is
                    <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-group col-sm-12 col-md-6">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Address 2" title="Address Line 2" formControlName="address_two" maxlength="200" class="form-control input-lg custom-form-input">
                </mat-form-field>
              </div>
            </div>

            <div class="row">
               <div class="form-group col-sm-12 col-md-6">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="{{zipLabel}}*" title="{{zipLabel}}"  #zipcode (input)="zipcode.value = zipcode.value.toUpperCase()" formControlName="zipcode" class="form-control input-lg custom-form-input"
                    maxlength="15" (keyup)="checkIfValid()" (blur)="setCountryStateCityViaZipCode()"/>
                  <mat-error *ngIf="editEmployee.controls.zipcode.hasError('required')">
                    {{zipLabel}} is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="editEmployee.controls.zipcode.hasError('pattern')">
                    Please enter valid {{zipLabel.toLowerCase()}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
                <ngx-select class="display-full margin-bottom-20" 
                [items]="countries" 
                [(ngModel)]="selectedCountry" 
                [ngModelOptions]="{standalone: true}"
                (select)="handleCountryDropdownChange($event)"
                  placeholder="Select Country">
                </ngx-select>
                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                  <mat-error *ngIf="editEmployee.controls.country.hasError('required')">
                    Country is
                    <strong>required</strong>
                  </mat-error>

                </div>
               </div>
              
            </div>

            <div class="row">
              <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
                <ngx-select class="display-full margin-bottom-20" id="stateId"
                [items]="states" 
                [(ngModel)]="selectedState" 
                [ngModelOptions]="{standalone: true}"
                [disabled]="disableStates" 
                (select)="handleStateDropdownChange($event)"
                placeholder="Select State">
                </ngx-select>
                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                  <mat-error *ngIf="editEmployee.controls.state.hasError('required')">
                    State is
                    <strong>required</strong>
                  </mat-error>
                </div>
              </div>
              <div class="form-group col-sm-12 col-md-6 uc-ngx-select">
                <ngx-select class="display-full margin-bottom-20" id="cityId"
                [items]="cities" 
                [(ngModel)]="selectedCity" 
                [ngModelOptions]="{standalone: true}"
                [disabled]="disableCities" 
                (select)="handleCityDropdownChange($event)"
                placeholder="Select City">
                </ngx-select>
                <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
                  <mat-error *ngIf="editEmployee.controls.city.hasError('required')">
                    City is
                    <strong>required</strong>
                  </mat-error>
                </div>
              </div>              
            </div>

            <div class="row">
              <div class="form-group col-sm-12 col-md-6">
                <div class="row">
                  <div class="col-sm-6">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                      <input matInput placeholder="Mobile Phone *" title="Mobile Phone" formControlName="mobile_phone" class="form-control input-lg custom-form-input"
                        maxlength="15" [textMask]="{mask: phoneMask}" (keyup)="checkIfValid();checkMobileValid()" />
                      <mat-error *ngIf="editEmployee.controls.mobile_phone.hasError('required')">
                        Mobile Phone is
                        <strong>required</strong>
                      </mat-error>
                      <mat-error *ngIf="editEmployee.controls.mobile_phone.hasError('minLength') && !editEmployee.controls.mobile_phone.hasError('required')">
                        Invalid Mobile Phone
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-6 margin-bottom-15">
                    <button mat-raised-button type="button" (click)="editContacts()" class="display-full btn-height-40 margin-sm-bottom-15 emergency-contacts-btn" color="accent">Emergency Contacts</button>
                  </div>
                </div>

              </div>
              <div class="form-group col-sm-12 col-md-6 dob-field-group">
                <div class="vlio-datepicker relative">
                  <dp-date-picker matInput mode="day" placeholder='Date of Birth * (MM/DD/YYYY)' (open)="handleDOB($event,dayPicker)"
                    (onChange)="handleDOB($event,dayPicker)" theme="dp-material dp-main" class="btn-block"   [config]="datePickerConfig"
                    formControlName="dob" #dayPicker></dp-date-picker>
                  <button type="button" class="calendar-icon" mat-icon-button (click)="dayPicker.api.open();">
                    <mat-icon class="mat-icon">date_range</mat-icon>
                  </button>
                </div>
                <div class="dp-error mat-error"
                  *ngIf="editEmployee.controls.dob.hasError('required') && editEmployee.get('dob').touched ">
                  Date of Birth is
                  <strong>required</strong>
                </div>
                <div class="dp-error mat-error"
                  *ngIf="!(editEmployee.controls.dob.valid) && (editEmployee.value.dob!='') && (editEmployee.value.dob!=undefined)">
                  <p>Invalid Date of Birth</p>
                </div>
                <div class="dp-error"
                  *ngIf="isFutureDate && (editEmployee.controls.dob.valid)">
                  <p>Date of Birth in the future is not allowed.</p>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="empId!=currentUserInfo.id || currentUserInfo.role_id == gc.ROLE_ID_SHOP_OWNER">
              <div class="form-group col-sm-12 col-md-6 weekly-hours">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input matInput placeholder="Hours a Week *" title="Hours a Week" formControlName="hours_week" maxlength="6" class="form-control input-lg custom-form-input"
                    (keyup)="vkFloat('hours_week');checkIfValid()" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)"/>
                  <mat-error *ngIf="editEmployee.controls.hours_week.hasError('required')">
                    Hours a Week is
                    <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              
              <div class="form-group1 col-sm-12 col-md-6">
                <mat-checkbox class="btn-block padding-bottom-15 padding-top-15" labelPosition="after" (change)="enableReport($event)" formControlName="enable_report">
                  Enable Report Generation
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-12 col-md-6">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                      <input matInput placeholder="Hourly Wages *" title="Hours Wages" formControlName="hours_wages" maxlength="7" class="form-control input-lg custom-form-input"
                        (keyup)="vkFloat('hours_wages');checkIfValid()" (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)"/>
                      <mat-error *ngIf="editEmployee.controls.hours_wages.hasError('required')">
                        Hourly Wages is
                        <strong>required</strong>
                      </mat-error>
                    </mat-form-field>
                  </div>
              <div class="form-group col-sm-12 col-md-6">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                  <input matInput readonly placeholder="Weekly Wages *" title="Week Wages" formControlName="week_wages" class="form-control input-lg custom-form-input disabled"/>
                  <mat-error *ngIf="editEmployee.controls.week_wages.hasError('required')">
                    Weekly Wages is
                    <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-group col-sm-12 col-md-6">                
              </div>
            </div>
          </div>

          <!-- Start USER TIMELINE Row -->           
          <div class=" col-xs-12 form-block">
              <h2 class="fontcolor-blue sub-heading">WORK SCHEDULE</h2>
              <mat-error *ngIf="!editEmployee.controls.selectedItems.valid">
                  Please select at least one weekday.
              </mat-error>
              <mat-radio-group formControlName="job_type" aria-label="Select an option">
                  <mat-radio-button [checked]="editEmployee.controls.job_type.value==0" value="0">Full Time</mat-radio-button>
                  <mat-radio-button [checked]="editEmployee.controls.job_type.value==1" value="1">Part Time</mat-radio-button>
              </mat-radio-group>               
              
              <div class="row work-schedule-wrapper" formArrayName="weekday_timings" *ngFor="let timing of editEmployee.get('weekday_timings')['controls']; let i = index;">
                  <div class="col-sm-12 col-md-12" [formGroupName]="i">
                      <div class="row">
                          <div class="col-sm-2 col-xs-2">
                              <div class="day-fields">
                                  <ng-container [ngSwitch]="i">
                                      <label *ngSwitchCase="0">Monday</label>
                                      <label *ngSwitchCase="1">Tuesday</label>
                                      <label *ngSwitchCase="2">Wednesday</label>
                                      <label *ngSwitchCase="3">Thursday</label>
                                      <label *ngSwitchCase="4">Friday</label>
                                      <label *ngSwitchCase="5">Saturday</label>
                                      <label *ngSwitchCase="6">Sunday</label>
                                  </ng-container>  
                              </div>
                          </div>
                          <div class="col-sm-1 col-xs-1">
                              <div class="row">
                                  <div class="form-group weak-checkbox col-xs-12">
                                      <mat-checkbox class="btn-block padding-top-10"  labelPosition="after" [class.chk_disable]="timing.get('is_disable').value"  formControlName="weekdayid">
                                      </mat-checkbox>
                                  </div>
                              </div>
                          </div>
                          <div class="col-sm-9 col-xs-12">
                              <div class="row">
                                  <div class="form-group col-sm-5  col-xs-4 uc-ngx-select">
                                      <ngx-select class="display-full"  [items]="timeSlots" formControlName="start_time" placeholder="Start Time" optionTextField="text" >
                                      </ngx-select>                           
                                          <mat-error class="dp-error" *ngIf="timing.get('start_time').hasError('required')">
                                          Start Time <strong>required</strong>
                                          </mat-error>
                                          <mat-error class="dp-error" *ngIf="timing.errors">
                                          {{ timing.errors?.dates  }}
                                          </mat-error>
                                  </div>
                                  <div class="form-group col-sm-5  col-xs-4 uc-ngx-select">
                                      <ngx-select class="display-full" [items]="timeSlots" formControlName="close_time"  placeholder="End Time" optionTextField="text" >
                                      </ngx-select>                                         
                                          <mat-error class="dp-error" *ngIf="timing.get('close_time').hasError('required')">
                                          End Time <strong>required</strong>
                                          </mat-error>                            
                                  </div>
                                  <div class="form-group  col-xs-2">
                                    <button type="button" [disabled]="timing.get('weekdayid').value==false || timing.get('start_time').value=='' || timing.get('close_time').value==''" (click)="applyToAll(i)" mat-raised-button color="accent" class="pull-left">Apply to All</button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>               
              
          </div>
          <!-- End USER TIME Row -->

          <!-- USER OVERTIME Row -->              
            <div class="col-xs-12 form-block padding-bottom-20 margin-top-10 overtime-section">
              <h2 class="fontcolor-blue sub-heading">OVERTIME SETTING</h2>                
                <div class="row">
                  <div class="col-sm-4 col-xs-6 uc-ngx-select">
                    <ngx-select class="display-full" [items]="hourSlots" formControlName="max_hour"  placeholder="Hours" optionTextField="text" >
                    </ngx-select> 
                  </div>
                  <div class="col-sm-4 col-xs-6 uc-ngx-select">
                    <ngx-select class="display-full" [items]="minSlots" formControlName="max_min"  placeholder="Minutes" optionTextField="text" >
                    </ngx-select> 
                  </div>
              </div>
            </div>
          <!-- USER OVERTIME Row -->  

          <div class="col-xs-12 form-block">
            <h2 class="fontcolor-blue sub-heading">PHOTO / DOCUMENTS / DRIVING LICENSE</h2>
            <div class="row">
              <div class="form-group document-section col-sm-12 col-md-6 padding-bottom-20">
                <!-- Start Employee Upload photo -->
                <div class="col-sm-12 padding-0">
                  <label for="partPic">Upload Photo</label>
                </div>
                <div class="col-sm-12 file-upload-container padding-0">
                  <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative">
                    <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                    Drag File Here
                    <input type="file" id="empPhoto" (change)="uploadPicture($event,'image',0)" name="emp_pic" accept="image/*" formControlname="emp_photo" />
                    <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                  </mat-toolbar>
                  <input id="empPhotoImagedata" name="emp_pic" value="" type="hidden" formControlName="emp_photo">
                </div>
                <div class="col-sm-12 padding-0">
                    <p class="help-block margin-0">Formats supported : {{imgTypes}}</p>
                    <p class="help-block margin-0">Maximum size : {{imgSize}}</p>
                  <ul class="list-inline upload-image margin-bottom-0">
                    <li *ngIf="photo!=''">
                      <a href="javascript:void(0);" (click)="imageToolDialog($event,0,11,[photo]);$event.stopPropagation();" class="img-set thumbnail relative">
                        <img class="show-image" src="{{docImgPath}}{{photo.src}}" />
                        <i class="fa fa-trash remove-img" (click)="delete('image');$event.stopPropagation();"></i>
                      </a>
                    </li>
                  </ul>

                </div>
                <!-- end Employee Upload photo -->
                <!-- start Employee Driving License -->
                <div class="col-sm-12 padding-0 margin-top-20">
                  <label for="partPic">Driving License</label>
                </div>
                <div class="col-sm-12 file-upload-container padding-0">
                  <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative">
                    <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                    Drag File Here
                    <input type="file" id="drivingLicense" (change)="uploadPicture($event,'doc',2)" name="emp_driving_license" />
                    <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                  </mat-toolbar>
                  <input id="drivingLicensedata" name="emp_driving_license" value="" type="hidden" formControlName="driving_license">
                </div>
                <div class="col-sm-12 padding-0">
                    <p class="help-block margin-0">Formats supported : {{docType}}</p>
                    <p class="help-block margin-0">Maximum size : {{imgSize}}</p>
                  <ul class="list-inline upload-image margin-bottom-0">
                      <li *ngFor="let license of licenseDoc;let i=index">
                          <a href="javascript:void(0);" *ngIf="license.type==1" (click)="imageToolDialog($event,0,11,[licenseDoc[i]]);$event.stopPropagation();" class="img-set thumbnail relative">
                            <img class="show-image" *ngIf="license.type==1" src="{{docImgPath}}{{license.src}}" />
                            <i class="fa fa-trash remove-img" (click)="delete('doc',i,2);$event.stopPropagation();"></i>
                          </a>
                          <a href="javascript:void(0);" *ngIf="license.type==3 || license.type==4" (click)="invokeDownload(docPath+license.src,'EmployeeDocument',license.src);$event.stopPropagation();" class="img-set thumbnail relative">
                              <span class="fa far fa-file-pdf fa-4x" *ngIf="license.type==3"></span>
                              <span class="fa far fa-file-word fa-4x" *ngIf="license.type==4"></span>
                              <i class="fa fa-trash remove-img" (click)="delete('doc',i,2);$event.stopPropagation();"></i>
                          </a>
                      </li>
                  </ul>

                </div>
                <!-- start Employee Driving License -->
              </div>
              <!-- start Employee Driving documents -->
              <div class="form-group col-sm-12 col-md-6 padding-bottom-20 documents-upload">
                <div class="col-sm-12 padding-0">
                  <label for="partPic">Documents</label>
                </div>
                <div class="col-sm-12 file-upload-container padding-0">
                  <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative">
                    <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                    Drag File Here
                    <input type="file" id="empDocuments" name="emp_Documents" (change)="uploadPicture($event,'doc',1)" />
                    <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                  </mat-toolbar>
                  <input id="empDocuments" name="emp_Documents" value="" type="hidden" formControlName="emp_documents">
                </div>
                <div class="col-sm-12 padding-0">
                    <p class="help-block margin-0">Formats supported : {{docType}}</p>
                    <p class="help-block margin-0">Maximum size : {{imgSize}}</p>
                  <ul class="list-inline upload-image margin-bottom-0">
                      <li *ngFor="let doc of docs;let i=index;">
                          <a href="javascript:void(0);" *ngIf="doc.type==1" (click)="imageToolDialog($event,0,11,[docs[i]]);$event.stopPropagation();" class="img-set thumbnail relative">
                            <img class="show-image" src="{{docImgPath}}{{doc.src}}" />
                            <i class="fa fa-trash remove-img" (click)="delete('doc',i,1);$event.stopPropagation();"></i>
                          </a>
                          <a href="javascript:void(0);" *ngIf="doc.type==3 || doc.type==4" (click)="invokeDownload(docPath+doc.src,'EmployeeDocument',doc.src);$event.stopPropagation();" class="img-set thumbnail relative">
                              <span class="fa far fa-file-pdf fa-4x" *ngIf="doc.type==3"></span>
                              <span class="fa far fa-file-word fa-4x" *ngIf="doc.type==4"></span>
                              <i class="fa fa-trash remove-img" (click)="delete('doc',i,1);$event.stopPropagation();"></i>
                          </a>
                        </li>
                  </ul>

                </div>
              </div>
              <!-- end Employee Driving documents -->
            </div>
          </div>
         
          <div class=" col-xs-12 form-block submit-block">
            <div class="row">
              <div class="col-sm-6 col-lg-4">
                <button type="submit" mat-raised-button class="car-btn btn-block text-uppercase  btn-lg font-light fontcolor-white" id="saveClose"
                  color="accent" title="Submit" >SUBMIT</button>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
