import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { GlobalService } from '../../../environments/global.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { globalConstants } from '../../../environments/globalConstants';
import { globalFunctions } from '../../../environments/globalFunctions';
import { UsercontactsComponent } from '../../usercontacts/usercontacts.component';
const EMAIL_REGEX = globalConstants.EMAIL_REGEX;
import {Router, ActivatedRoute, ParamMap, NavigationEnd, Params} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Http, Headers, Response, RequestOptions} from '@angular/http';
import {trigger,state,style,animate,transition} from '@angular/animations';

@Component({
  selector: 'app-customer-edit-modal',
  templateUrl: './customer-edit-modal.component.html',
  styleUrls: ['./customer-edit-modal.component.scss'],
  animations: [
    trigger('popOverState', [
      state('show', style({
        opacity:1,
        height:'50px',
        transform:'translateY(0px)',
        'z-index':'0'
      })),
      state('hide',   style({
        opacity:0,
        height:'0px',
        transform:'translateY(-69px)',
        'z-index':'-1'
      })),
      transition('show => hide', animate('100ms ease-out')),
      transition('hide => show', animate('0.2s 100ms ease-out'))
    ])
  ]
})
export class CustomerEditModalComponent implements OnInit {

    public customerEdit: FormGroup;
    public customerServiceCenter: FormGroup;
    public customerRoadsideAssistance: FormGroup;
    public customerInsurance: FormGroup;
    public customerWarranty: FormGroup;
    public currentCustomer;
    public selectedCountry:any;
    public selectedState:any;
    public selectedCity:any;
    public countries:any = [];
    public states: any = [];
    public cities: any = [];
    public checkedBox: boolean = false;
    public phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    public currentUserInfo:any;
    public getCustomerTypes:any;
    public getEmailCheck:any;
    public checkUserEmail: boolean = false;
    public idFromUrl:any;
    public show = false;
    public taxExemptedCheck:boolean;
    public requireApprovalCheck:boolean;
    public requireApprovalAmount : boolean = false;    
    public disableStates:any;
    public disableCities:any;
    public customerTypesOptions:any;
    public defaultCustomerType:any;
    public modulePermission:any=0;
    public timezoneData=[];
    public param:any;
    public zipLabel = 'Zip Code';

   constructor(

        private activatedRoute:ActivatedRoute,private formBuilder: FormBuilder,private route: ActivatedRoute,
        public titleService:Title,private http: Http,private router: Router, public globalService: GlobalService,
        public dialogRef:MatDialogRef<CustomerEditModalComponent>,@Inject(MAT_DIALOG_DATA)
        public data: any,
        public dialog: MatDialog) {
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
        this.getCountries();
        this.param = data;
        var userInfo = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&shop_id=" + this.globalService.getCurrentUser()["shop_id"];
        this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_TYPES, userInfo).subscribe((data:any) => {
            this.getCustomerTypes = data.data;
            var customerTypesList=[];
            for(var i in this.getCustomerTypes){
                this.getCustomerTypes[i].id=this.getCustomerTypes[i].id;
                this.getCustomerTypes[i].text=this.getCustomerTypes[i].type;                                        
                customerTypesList.push(this.getCustomerTypes[i]);
                //this.customerTypesOptions[this.getCustomerTypes[i].id]=this.getCustomerTypes[i].id;
            }
            this.getCustomerTypes = customerTypesList;
        });
    }

  ngOnInit() {
    this.initializeForBuilders();
    this.globalService.setMetaData("SHOPS","EDIT_CUSTOMERS")
    this.getCurrentCustomerDetails();
  }

  setParams(){
    this.activatedRoute.params.subscribe((params: Params) => {
      this.idFromUrl = params["customer_id"];
    });
}

  initializeForBuilders() {
    // this.setParams();
    this.customerEdit = this.formBuilder.group({
        email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
        first_name: ['', [Validators.required,Validators.pattern(globalConstants.NAME_REGEX)]],
        middle_name: ['',Validators.pattern(globalConstants.NAME_REGEX)],
        last_name: ['', [Validators.required,Validators.pattern(globalConstants.NAME_REGEX)]],
        address_one: [''],
        address_two: [''],
        city: ['',Validators.required],
        state: ['',Validators.required],
        frm_state: [''],
        country: [''],
        frm_country: [''],
        zipcode: ['',[Validators.pattern("^[0-9]+$")]],
        home_phone: [''],
        business_phone: [''],
        mobile_phone: ['', [Validators.required, Validators.minLength(10), this.globalService.mobileNumberValidation]],
        business_name: ['',Validators.pattern(globalConstants.NAME_REGEX)],
        customer_type: [''],
        tax_exempted: [''],
        user_type:this.globalService.getCurrentUser()["user_type"],
        user_id:this.globalService.getCurrentUser()["id"],
        customer_id:[''],
        shop_id:this.globalService.getCurrentUser()["shop_id"],
        business_name_present: [false],
        require_approval: [''],
        approval_amount: ['',Validators.pattern(/^-?\d+(?:\.\d+)?$/)],
        timezone:[''],
        address_is_required:[false]
    });

    this.customerEdit!.get("country").valueChanges.subscribe(country_name => {                
        if(country_name && Array.isArray(this.countries)){                 
             let zRegex = this.countries.find(res=>(res.country_id==country_name || res.country_name==country_name));
             if(zRegex && 'zipcode_regex' in zRegex){
                 this.zipLabel = zRegex.zipcode_label;
                 if(!this.customerEdit.get("address_is_required").value){
                    this.customerEdit.controls.zipcode.setValidators([Validators.required,Validators.pattern(zRegex.zipcode_regex)]);
                }else{
                    this.customerEdit.controls.zipcode.setValidators([Validators.pattern(zRegex.zipcode_regex)]);
                }
                 this.customerEdit.controls.zipcode.updateValueAndValidity();
             }          
         }                
     });

     this.customerEdit.get("address_is_required").valueChanges.subscribe(value => {
        let country = this.customerEdit.value.country;
        let zRegex = this.countries.find(res => (res.country_id == country || res.country_name == country));          
        if(value){                 
            this.customerEdit.controls.address_one.setValidators([]);
            this.customerEdit.controls.zipcode.setValidators([Validators.pattern(zRegex.zipcode_regex)]);
            this.customerEdit.controls.state.setValidators([]);
            this.customerEdit.controls.city.setValidators([]);   
        }else{
            this.customerEdit.controls.address_one.setValidators([Validators.required]);  
            this.customerEdit.controls.zipcode.setValidators([Validators.required,Validators.pattern(zRegex.zipcode_regex)]);
            this.customerEdit.controls.state.setValidators([Validators.required]); 
            this.customerEdit.controls.city.setValidators([Validators.required]);
        }  
        this.customerEdit.controls.address_one.updateValueAndValidity(); 
        this.customerEdit.controls.zipcode.updateValueAndValidity(); 
        this.customerEdit.controls.state.updateValueAndValidity(); 
        this.customerEdit.controls.city.updateValueAndValidity();                 
    });
   // this.getStates(this.currentCustomer.state);
}

    /* Customer Type Validation */
    customerTypeValidation() {
      let cType = this.customerEdit.value.customer_type;
      if(cType !="") {
          let cTypeValue = this.customerEdit.value.customer_type.length >= 1 ? false : {required: true};
          if(cTypeValue == false) {
              this.customerEdit.controls.customer_type.setErrors(null);
          } else {
              this.customerEdit.controls.customer_type.setErrors({"required": true});
          }
      } else {
          this.customerEdit.controls.customer_type.setErrors({"required": true});
      }
  }

editCustomerData() {
  this.customerTypeValidation();
  if(this.customerEdit.valid) {

      /* BLANK COUNTRY CHECK */
      /*if(this.customerEdit.value.frm_country == undefined || this.customerEdit.value.frm_country == 'undefined') {
          this.customerEdit.controls.country.setValue('');
          this.customerEdit.controls.state.setValue('');
          this.customerEdit.controls.city.setValue('');
      } else {
          this.customerEdit.controls.country.setValue(this.customerCountryName);
      }

      if(this.customerStateName == undefined || this.customerStateName == '' || this.customerEdit.value.frm_state == "" || this.customerEdit.value.frm_country == undefined) {
          this.customerEdit.controls.state.setValue('');
          this.customerEdit.controls.city.setValue('');
      } else {
          this.customerEdit.controls.state.setValue(this.customerStateName);
      }

      if(this.customerEdit.value.frm_country == undefined || this.customerEdit.value.city == 'undefined' || this.customerEdit.value.city == undefined || this.customerEdit.value.city == "null" || this.customerEdit.value.city == "" || this.customerEdit.controls.city == null) {
          this.customerEdit.controls.city.setValue('');
      }*/
      /* END */


      /*var content = globalFunctions.stringifyFormData(this.customerEdit.value, null)
      headers.append('content-type', 'application/x-www-form-urlencoded');*/
      /*if(this.newCountryName && this.newCountryName != undefined && this.newCountryName!=''){
          this.customerEdit.controls.country.setValue(this.newCountryName);   
           console.log("1 IF");      
      } else {
          this.customerEdit.controls.country.setValue(this.selectedCountry);
          this.customerEdit.controls.state.setValue(this.selectedState);
          this.customerEdit.controls.city.setValue(this.selectedCity);
          console.log("1 ELSE");  
      }
      
      if(this.newStateName && this.newStateName != undefined && this.newStateName!=''){
          this.customerEdit.controls.state.setValue(this.newStateName);  
           console.log("2 IF");        
      }  else {
         this.customerEdit.controls.state.setValue(this.selectedState);
         this.customerEdit.controls.city.setValue(this.selectedCity);
          console.log("2 ELSE");  
      }
      
      if(this.newCityName && this.newCityName!=undefined && this.newCityName!='' && (this.newCityName!=null && this.newCityName!='null')){
          this.customerEdit.controls.city.setValue(this.newCityName);   
           console.log("3 IF");           
      }  else {
          this.customerEdit.controls.city.setValue(this.selectedCity);
           console.log("3 ELSE");  
      } */ 
          /*
      if(this.newCountryName && this.newCountryName!=undefined && this.newCountryName!=''){
          this.customerEdit.controls.country.setValue(this.newCountryName);   
          this.customerEdit.controls.state.setValue('');
          this.customerEdit.controls.city.setValue('');         
      } else {
          if(this.selectedCountry!='') {
              this.customerEdit.controls.country.setValue(this.selectedCountry);
              this.customerEdit.controls.state.setValue('');
              this.customerEdit.controls.city.setValue('');
          } else {
              this.customerEdit.controls.country.setValue('');
              this.customerEdit.controls.state.setValue('');
              this.customerEdit.controls.city.setValue('');
          }
      }
      
      if(this.newStateName && this.newStateName!=undefined && this.newStateName!=''){
          this.customerEdit.controls.state.setValue(this.newStateName);  
          this.customerEdit.controls.city.setValue('');          
      }  else {
          if(this.selectedState!='' && this.newCountryName !='') {
              this.customerEdit.controls.state.setValue(this.selectedState);
              this.customerEdit.controls.city.setValue('');
          } else {
              this.customerEdit.controls.state.setValue('');
              this.customerEdit.controls.city.setValue('');
          }
      }
      if(this.newCityName && this.newCityName!=undefined && this.newCityName!='' && (this.newCityName!=null || this.newCityName!='null')){
          this.customerEdit.controls.city.setValue(this.newCityName);            
      }  else {
          if(this.selectedCity!='' && this.newStateName!='') {
              this.customerEdit.controls.city.setValue(this.selectedCity);
          } else {
              this.customerEdit.controls.city.setValue('');
          }
      }
*/

//            console.log(this.customerEdit.value);
//            return false;

      if (!this.customerEdit.value.timezone || this.customerEdit.value.timezone == null || typeof (this.customerEdit.value.timezone) == 'undefined') {
          this.customerEdit.patchValue({ timezone: globalConstants.DEFAULT_TIME_ZONE });
      }
      var data = new FormData();
      for(let i in this.customerEdit.value){
         data.append(i, this.customerEdit.value[i]);
      }

      //var content = globalFunctions.stringifyFormData(this.customerEdit.value, null);
      this.globalService.formData(globalConstants.API_EDIT_CUSTOMER_URL, data).subscribe((data) => {
        if(data.code == "510"){
          this.globalService.snackbar('success', data.message);
          this.closeDialog();
        }else{
          this.globalService.snackbar('error', data.message);
        }
      });
  } else {
    this.mandatoryMsg();
  }
}

mandatoryMsg() {
  this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
}
onlyNumericKey(event:any) {
  return globalFunctions.onlyDecimalNumberKey(event);
}
showTaxExempted(event:any) {
  if (event.checked) {
      this.customerEdit.controls.tax_exempted.setValue(1);
  } else {
      this.customerEdit.controls.tax_exempted.setValue(0);
  }
}

closeDialog() {
    this.dialogRef.close();
  }

getCurrentCustomerDetails() {
  // this.setParams();
  var response = "";
  
  var customerInfo = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&customer_id=" + this.param.customer_id;
  
  this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_DETAILS, customerInfo).subscribe((data:any) => {
  var response = data.data;

  this.customerEdit.controls.email.setValue(response.email);
  this.customerEdit.controls.first_name.setValue(response.first_name);
  this.customerEdit.controls.middle_name.setValue(response.middle_name);
  this.customerEdit.controls.last_name.setValue(response.last_name);
  this.customerEdit.controls.business_name.setValue(response.business_name);
  this.customerEdit.controls.address_one.setValue(response.address1);
  this.customerEdit.controls.address_two.setValue(response.address2);
  this.customerEdit.controls.city.setValue(response.city);
  this.customerEdit.controls.state.setValue(response.state);
  this.customerEdit.controls.country.setValue(response.country);
  //this.customerEdit.controls.state.setValue(response.state);
  this.customerEdit.controls.zipcode.setValue(response.zip);
  //this.customerEdit.controls.country.setValue(response.country);
  this.customerEdit.controls.home_phone.setValue(response.home_phone);
  this.customerEdit.controls.business_phone.setValue(response.business_phone);
  this.customerEdit.controls.business_name_present.setValue(response.business_name_present);
  this.customerEdit.controls.mobile_phone.setValue(response.mobile_phone);
  //this.customerEdit.controls.tax_exempted.setValue(response.tax_exempted);
  this.customerEdit.controls.customer_type.setValue(response.customer_type);
  this.defaultCustomerType = response.customer_type;
  this.customerEdit.controls.approval_amount.setValue(response.approval_amount);
  this.customerEdit.controls.customer_id.setValue(this.param.customer_id);
  let noAddressStatus = JSON.parse(localStorage.getItem("shop-details")).no_customer_address== 1? true:false;
  this.customerEdit.controls.address_is_required.setValue(noAddressStatus);
  if (response.tax_exempted == 1) {
      this.taxExemptedCheck = true;
      this.customerEdit.controls.tax_exempted.setValue(1);
  } else {
      this.taxExemptedCheck = false;
      this.customerEdit.controls.tax_exempted.setValue(0);
  }
  
  if (response.require_approval == '1') {
      this.requireApprovalCheck = true;
      this.requireApprovalAmount = true;
      this.customerEdit.controls.require_approval.setValue(1);
  } else {
      this.requireApprovalCheck = false;
      this.requireApprovalAmount = false;
      this.customerEdit.controls.require_approval.setValue(0);
  }

  this.customerEdit.controls.frm_country.setValue(response.country_id);
  this.getStates(response.country_id);
  this.customerEdit.controls.timezone.setValue(response.time_zone);
  this.getTimezone(response.country_id);
  /* SET STATE ID TO GET CITY NAME */
  this.customerEdit.controls.frm_state.setValue(response.state_id);

  this.getCities(response.state_id);
  this.selectedCountry = response.country_id;
  this.selectedState = response.state_id;
  this.selectedCity = response.city;
      /* END */

  /*var event = {
    value:response.country
  }
  this.getStates(event);
  this.cities[0] = {city_name:response.city}*/

  if(response.business_name != null && response.business_name != " " && response.business_name != ""){
    this.checkedBox = true;
    this.show = true;
  } else {
    this.checkedBox = false;
    this.show = false;
  }
  
})
}

getCountries(){
    this.globalService.callAPI(globalConstants.API_GET_COUNTRIES_LIST, "opr=1").subscribe((data:any) => {
      this.countries = data.data;
      for(var i in this.countries){
        this.countries[i].id=this.countries[i].country_id;
        this.countries[i].text=this.countries[i].country_name;
      }
    })
  }
  getStates(countryID) {
    if (typeof(countryID) == 'undefined' || countryID == "") {
        this.disableStates = true;
        this.disableCities = true;
        this.cities=[];
    } else {
        this.disableCities = true;
        this.cities=[
            { id: "", text: "Select City" }
        ];
        let stateDDLPlaceholder = [
            { 
                id: "",
                state_id: "",
                state_name: "Select State",
                country_id: "",
                latitude: "",
                longitude: "",
                tax_rate: "",
                state_code: ""
             }
        ];
        this.states = [];
        this.globalService.callAPI(globalConstants.API_GET_STATES_URL, "opr=1&country_id=" + countryID).subscribe((data:any) => {
            var responseData = data;
            this.disableStates = false;
            if (responseData.result == "1") {
                this.states  = stateDDLPlaceholder.concat(responseData.data);
                for(var i in this.states){
                    this.states[i].id=this.states[i].state_id;
                    this.states[i].text=this.states[i].state_name;  
               }
            } else {
                this.states = [];
                this.cities = [];
                this.disableStates = false;
                this.selectedState = [{ id: "", text: "No state found" }];
                this.disableCities = true;
            }
        });
    }
}
  /* GET CITIES */
  getCities(stateId) {
    if (typeof(stateId) == 'undefined' || stateId == "") {
        this.cities = [];
        this.disableCities = true;
    } else {
       this.cities = [];
       let cityDDLPlaceholder=[{
        id: "",
        city_id: "",
        city_name: "Select City",
        state_id: "",
        country_id: "",
        latitude: "",
        longitude: ""
        }];
       this.globalService.callAPI(globalConstants.API_GET_CITIES_URL, "opr=1&state_id=" + stateId).subscribe((data:any) => {
            var responseData = data;
            if (responseData.result == "1") {
                this.disableCities = false;
                    this.cities = cityDDLPlaceholder.concat(responseData.data);
                    for(var i in this.cities){
                        this.cities[i].id=this.cities[i].city_name;
                        this.cities[i].text=this.cities[i].city_name;          
                    }
            } else {
                this.cities = [{id: "", text: "No city found"}];
                this.disableCities = false;
            }
        })
    }
}

   /* FOR COUNTRY/STATES/CITY SELECT */
   handleCountryDropdownChange(countryId){
    this.getStates(countryId);
    let countryData = this.countries.find(countryInfo => countryId == countryInfo.id);
    this.customerEdit.controls.country.setValue(countryData.country_name);
    this.customerEdit.controls.state.setValue('');
    this.customerEdit.controls.city.setValue('');
    this.customerEdit.controls.timezone.setValue('');
    this.selectedState = '';
    this.selectedCity = '';
    this.getTimezone(countryId);
    this.customerEdit.get('timezone').setValidators([Validators.required]);
    this.customerEdit.get('timezone').updateValueAndValidity();
  }
    handleStateDropdownChange(stateId) {
        if (stateId !== '') {
            this.getCities(stateId);
            let stateData = this.states.find(stateInfo => stateId == stateInfo.id);
            this.customerEdit.controls.state.setValue(stateData.state_name);
            this.customerEdit.controls.city.setValue('');
            this.selectedCity = 'Select City';
        } else {
            this.customerEdit.controls.state.setValue("");
            this.customerEdit.controls.city.setValue('');
            this.selectedCity = 'Select City';
        }
    }
    handleCityDropdownChange(cityName) {
        if (cityName !== 'Select City') {
            this.customerEdit.controls.city.setValue(cityName);
        } else {
            this.customerEdit.controls.city.setValue('');
        }
    }

/* Home Phone Validity Check */
checkHomePhoneValidity() {
    Window["errorObj"] = this.customerEdit
    let home_phone = this.customerEdit.value.home_phone;
     if(home_phone !="") {
        let phoneResult = this.customerEdit.value.home_phone.replace(/\D+/g, "").length >= 10 ? false : {minLength: true};
        if(phoneResult == false) {
            this.customerEdit.controls.home_phone.setErrors(null);
        } else {
            this.customerEdit.controls.home_phone.setErrors({"phoneError": true});
        }
    } else {
        this.customerEdit.controls.home_phone.setErrors(null);
    }
}

/* Business Phone Validity Check */
checkBusinessPhoneValidity() {
     Window["errorObj"] = this.customerEdit;
    let home_phone = this.customerEdit.value.business_phone;
     if(home_phone !="") {
        let phoneResult = this.customerEdit.value.business_phone.replace(/\D+/g, "").length >= 10 ? false : {minLength: true};
        if(phoneResult == false) {
            this.customerEdit.controls.business_phone.setErrors(null);
        } else {
            this.customerEdit.controls.business_phone.setErrors({"phoneError": true});
        }
    } else {
        this.customerEdit.controls.business_phone.setErrors(null);
    }
}



   /** customer timezone start */
    getSelectedOptionTimezone(event) {
        if (event !== '') {
            this.customerEdit.controls.timezone.setValue(event);
        } else {
            this.customerEdit.controls.timezone.setValue('');
        }
    }
    /** customer timezone end */
    /** get all timezone start */
    getTimezone(Id){ 
        let timeZoneDDLPlaceHolder =[{
            text: "Select Time Zone", 
            id: ""
        }];
        let data={country_id:Id ,user_type: this.globalService.getCurrentUser()["user_type"],user_id: this.globalService.getCurrentUser()["id"]};
        this.globalService.callAPI(globalConstants.API_GET_TIMEZONE,data).subscribe((zones:any)=>{
            if(zones.result=='1'){
                this.timezoneData=timeZoneDDLPlaceHolder.concat(zones.data);
            }else{
                this.timezoneData=[];
            }
        });
    }
    /** get all timezone end */

    showRequireApproval(event) {
      if (event.checked) {
          this.requireApprovalAmount = true;
          this.customerEdit.controls.require_approval.setValue(1);
      } else {
          this.requireApprovalAmount = false;
          this.customerEdit.controls.require_approval.setValue(0);
      }
  }
  
  /* animation */
  get checkApprovalAmount() {
      return this.requireApprovalAmount ? 'show' : 'hide'
  }
  
  onlyDecimalNumberKey(event) {
      let charCode = (event.which) ? event.which : event.keyCode;
      if (charCode != 46 && charCode > 31
          && (charCode < 48 || charCode > 57))
          return false;
      return true;
  }

  disableKey(event) {
      let charCode = String.fromCharCode(event.which).toLowerCase();
      if (event.ctrlKey==true && (event.which == '67' || event.which == '86' ||  String.fromCharCode(event.which).toLowerCase() == 's')) {
          console.log('thou. shalt. not. PASTE!');
          event.preventDefault();
      }
  }

  businessNameToggle() {
    if(this.show){
        this.show = false;
        this.customerEdit.controls.business_name.setValue("");
    }else{
        this.show = true;
    }
}


/* FOR CUSTOMER TYPES */
getSelectedOptionCustomerType(opt) {
    this.customerEdit.controls.customer_type.setValue(opt);
}

editContacts(){
    let dialogRef = this.dialog.open(UsercontactsComponent, {
        panelClass: 'car-dialog-form',
        width: "800px",
        data: {user_id:this.globalService.getCurrentUser()["id"],user_type:this.globalService.getCurrentUser()["user_type"],customer_id:this.param.customer_id}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
}
    /* animation */
    
  get showBusinessName() {
      return this.show ? 'show' : 'hide'
  }

  setCountryStateCityViaZipCode(){
    let payload = {
         user_id: this.currentCustomer.id,
         user_type: "User",
         zip_code: this.customerEdit.controls.zipcode.value
    }
    this.globalService.callAPI(globalConstants.API_GET_STATE_CITY_VIA_ZIPCODE,payload).subscribe((response:any)=>{
        if(response.result == 1){
            if(response.data.country_info.country_id !== 0 
              && response.data.state_info.state_id !== 0 
              &&  response.data.city_info.city_id !== 0){
                this.selectedCountry = response.data.country_info.country_code;
                let oldNewCustomerCountry = this.customerEdit.value.country;
                this.customerEdit.controls.country.setValue(response.data.country_info.country_name);
                this.getStates(response.data.country_info.country_code);
                this.selectedState = response.data.state_info.state_id;
                this.customerEdit.controls.state.setValue(response.data.state_info.state_name);
                this.getCities(response.data.state_info.state_id);
                this.selectedCity = response.data.city_info.city_name;
                this.customerEdit.controls.city.setValue(this.selectedCity);
                if(oldNewCustomerCountry !== this.selectedCountry){
                    this.customerEdit.controls.timezone.setValue('');
                    this.getTimezone(response.data.country_info.country_code);
                    this.customerEdit.get('timezone').setValidators([Validators.required]);
                    this.customerEdit.get('timezone').updateValueAndValidity();
                }
            }
        }
  })
}

validateNumbersWithDecimal(event,limit) {
    return globalFunctions.validateNumbersWithDecimal(event,limit);
}

}
