import { Component, OnInit, ViewChild, Pipe, Inject, ChangeDetectorRef, ViewEncapsulation, Optional } from '@angular/core';
import { AbstractControl, FormGroup, FormControl, FormBuilder, Validators, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { globalFunctions } from "../../../../environments/globalFunctions";
import { globalConstants } from "../../../../environments/globalConstants";
import { GlobalService } from "../../../../environments/global.service";
import { INgxSelectOption } from 'ngx-select-ex';
import { map, switchMap } from 'rxjs/operators';
import { Observable, pipe } from 'rxjs';
// import { CustomLoader } from '../../../customloader';
import { ImagetooldialogComponent } from '../../../imagetooldialog/imagetooldialog.component';

@Component({
  selector: 'app-add-nexpart-vendor',
  templateUrl: './add-nexpart-vendor.component.html',
  styleUrls: ['./add-nexpart-vendor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddNexpartVendorComponent implements OnInit {

  public isPopup:boolean;
  public currentCustomer:any;
  public currentCustomerName:string;
  public addVendorForm:FormGroup;
  public passwordVisibility:boolean;
  public logoImage:string;
  public activeSubmitAndStay:boolean = false;
  public FORMAT_SUPPORTED_FOR_IMAGE = globalConstants.FORMAT_SUPPORTED_FOR_IMAGE;
  public MAX_ALLOWED_IMAGE_SIZE = globalConstants.MAX_ALLOWED_IMAGE_SIZE;
  public MAX_ALLOWED_IMAGE_DISPLAYED_TEXT = globalConstants.MAX_ALLOWED_IMAGE_DISPLAYED_TEXT;
  
  constructor(private formBuilder: FormBuilder, private router: Router, public globalService: GlobalService, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, @Optional() public dialogRef: MatDialogRef<AddNexpartVendorComponent>,
  //  private customLoader:CustomLoader, 
   public dialog: MatDialog) {
    if(data && data.isPopup){
      this.isPopup = true;
    }
    if (localStorage.getItem("loggedInUser")) {
      this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
      this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
    } else {
      router.navigate(["/shops"])
    }
    setTimeout(()=>{
      let inSubscription:boolean=true;
      // inSubscription = this.globalService.isSubscribe(globalConstants.SUBSCRIPTION_DISCOUNTS_FEATURE);
      let isEnable=this.globalService.getAcl('nexpart_creds','addNew');
      if(!(isEnable) || !(inSubscription)){
        this.router.navigate(['shops/action/forbidden']);
      }
     },globalConstants.RELOAD_WAIT);
  }

  ngOnInit() {
    this.globalService.setMetaData("SHOPS", "ADD_NEXPART_VENDOR");
    this.initializeAddDiscountForm();
  }
  
  initializeAddDiscountForm() {
    this.addVendorForm = this.formBuilder.group({
      name: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', [Validators.required]],
      logo: ['']
    });
  }

  isNotNumber(num){
    return isNaN(num);
  }

  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
  }

  onlyNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) return false;
    return true;
  }

  disableKey(event) {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey == true && (event.which == '67' || event.which == '86' || String.fromCharCode(event.which).toLowerCase() == 's')) {
      console.log('thou. shalt. not. PASTE!');
      event.preventDefault();
    }
  }

  addVendor(opr) {
    Object.keys(this.addVendorForm.controls).map((controlName) => {
      this.addVendorForm.get(controlName).markAsTouched({ onlySelf: true });
    });
    
    if(this.addVendorForm.valid) {
      var data = new FormData();
      let formData = this.addVendorForm.value; 
      data.append('user_type',this.currentCustomer.user_type);
      data.append('user_id',this.currentCustomer.id);
      for(let x in formData){
        data.append(x, formData[x]);
      }
      // data.append('username', formData.username);
      // data.append('password', formData.password);
      console.log('final form data = ', data);
      this.globalService.formData(globalConstants.API_ADD_NEXLINK_VENDOR_URL,data).subscribe((data:any)=> {
          var message = data.message;
          if (data.result == "1") {
            if(opr == 1){
              if(this.isPopup){
                this.dialogRef.close(1);
              }
              else{
                this.router.navigate(["/shops/nexpart/vendors-list"]);
              }
            }
            else{
              this.addVendorForm.reset();
              this.deleteImg('logo');
              this.activeSubmitAndStay = true;
            }
            this.globalService.snackbar('success', message);
          } else {
            this.globalService.snackbar('error', message);
          }
      });
    } else {
      this.mandatoryMsg();
    }
  }

  mandatoryMsg() {
    this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
  }
  
  expandAccordion(accordion:boolean){
    accordion = !accordion;
  }
  
  
  getSelectedCustomers(ev:any){
    console.log('selected customers = ', ev);
    // console.log('selected customers add = ', this.addVendorForm.controls['customers'].value);
    // this.filteredCustomerData = this.customerData.slice(0,100).filter((customer)=>{
    //   return this.addVendorForm.get('customers').value.indexOf(customer.id) == -1;
    // });
  }
  
  changeListener(event, rendering_tag_id) {
    let ele = <HTMLInputElement>document.getElementById('add' + rendering_tag_id);
    if (event.target.files && event.target.files[0]) {
      let cFiles = { src: <ArrayBuffer | string>'', type: 0, name: '' };
      let file = event.target.files[0];
      let valid=true;
      valid=this.globalService.validateFiles('image',event.target.files);
      if(valid==false){
        return false;
      }
      if (valid) {
        if (file.size) {
          let data = new FormData();
          let url = globalConstants.API_NEW_SERVICE_REQUEST_FILE;
          data.append('user_id', this.currentCustomer.id);
          data.append('user_type', this.currentCustomer.user_type);
          data.append('image', file);
          let FileType = this.globalService.getFileIcon(file);
          cFiles.type = FileType;
          let reader = new FileReader();
          reader.onload = (e) => {
            cFiles.src = e.target['result'];
          }
          reader.readAsDataURL(event?.target?.files[0]);

          this.globalService.formData(url, data).subscribe((response:any) => {
            let resData = response;
            if (resData.result == 1) {
              this.checkFileUploadLimit(cFiles,resData,rendering_tag_id);
              event.target.value = "";
            } else {
              if (ele) {
                  ele.value = '';
              }
              this.globalService.snackbar("error", resData.message);
            }
          })
        } else {
          if (ele) {
              ele.value = '';
          }
          this.globalService.snackbar("error", 'Please upload a valid file.');
        }
      } else {
        if (ele) {
            ele.value = '';
        }
        this.globalService.snackbar("error", 'Please upload a valid file.');
      }
    }
  }
  
  deleteImg(type){
    let ele=<HTMLInputElement>document.getElementById('add'+type);
    if(ele){
      ele.value='';
    }
    this.logoImage = '';
    this.addVendorForm.controls.logo.setValue('');
  }
  
  checkFileUploadLimit(cFiles,resData,rendering_tag_id){
    // let multi=false;
    cFiles.name = resData.data.name;
    // if(!multi){
    //   this.vehicle_pics=[];    
    // }
    // this.vehicle_pics.push(cFiles);
    this.logoImage = cFiles.src;
    this.addVendorForm.controls.logo.setValue(cFiles.name);
  } 

  handleClose(){
      if(this.activeSubmitAndStay){
        this.dialogRef.close(1);
      }else{
        this.dialogRef.close();
      }
  }
}
