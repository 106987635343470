import { Component, OnInit ,Input,EventEmitter,Output, ViewEncapsulation } from '@angular/core';
import { GlobalService } from 'src/environments/global.service';
import { globalConstants } from 'src/environments/globalConstants';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { SearchcarComponent } from '../shopcar/searchcar/searchcar.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ShopVehicleServiceComponent } from '../shopservicerequest/shop-vehicle-service/shop-vehicle-service.component';
import { CommonDialogBigComponent } from '../common-dialog-big/common-dialog-big.component';

@Component({
  selector: 'app-customer-vehicle-section',
  templateUrl: './customer-vehicle-section.component.html',
  styleUrls: ['./customer-vehicle-section.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomerVehicleSectionComponent implements OnInit {
  @Input() importVehicleList:any = [];
  @Input() importCustomerData:any;
  @Input() importVehicleId: any;
  @Input() importLicensePlate:any;
  @Input() viaSearchVehicles:any;
  @Output() SelectedVehicleInfo = new EventEmitter<any>();
  @Output() SearchVehicleObj = new EventEmitter<any>()
  public selectedVehicle:any=null;
  public imgPath = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_PICTURES + globalConstants.IMG_THUMB;
  public showAddVehicleSection :any = false;
  public addVehicleForm: FormGroup;
  public vehicleSearchForm : FormGroup;
  public shopDetail: any;
  public loginUserDetails: any;
  public states: any;
  public shopState: any;
  public defaultState: any;
  public vehicleMakes: any;
  public vehicleYears: any = [];
  public vehicleModels: any = [];
  public selectedJobs: any = [];
  public srInformation: any;
  public showMakeTxtFld : boolean =  false;
  public showYearTxtFld : boolean = false;
  public showModelTxtFld : boolean = false;
  public showYearAndModel :boolean = true;
  public showModel : boolean = true;
  public disableYear :any = true;
  public disableModel : any = true;
  public showCommonErrorMessage :any =0;
  public searchByItem=[{id:'0',text:'Search By'},{id:'1',text:'License Plate'},{id:'2',text: 'VIN'}, {id:'3',text:'Vehicle'}];
  public payload = {
    user_type: "",
    user_id: "",
    search_keyword: "",
    search_by:'0',
    customer_id:''
  };
  constructor(
    public globalService:GlobalService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CustomerVehicleSectionComponent>, 
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loginUserDetails = JSON.parse(localStorage.getItem("loggedInUser"));
    var payload = "user_id=" + this.loginUserDetails.shop_id + "&user_type=" + this.loginUserDetails.user_type + "&shop_id=" + this.loginUserDetails.shop_id;
      this.globalService.callAPI(globalConstants.API_SHOP_DETAILS, payload).subscribe((data: any) => {
      this.shopDetail = data.data;
      this.buildAddVehicleForm();
      this.getCountries();
      this.getVehicleMakes();
      if(typeof(this.importLicensePlate) == 'string' && this.importLicensePlate !== ''){
         var vehicles = this.importVehicleList.find(vehicle =>vehicle.license_plate == this.importLicensePlate.toUpperCase());
          this.handleVehicleSection(vehicles);
      }
      })
      this.handleUnSelectVehicle();
      this.vehicleSearchForm = this.formBuilder.group({
        search_by:['0'],
        search_keyword:['',Validators.required]
      })
  }

  buildAddVehicleForm() {
    this.addVehicleForm = this.formBuilder.group({
      license_plate: ['',Validators.pattern(globalConstants.VIN_AND_LICENSE_PLATE_REGEX)],
      state: [''],
      make: [''],
      year: ['',Validators.pattern('^[12][0-9]{3}$')],
      model: [''],
      customer_id: [''],
      production_date: [''],
      sub_model: [''],
      mileage: [''],
      color: [''],
      user_id: [this.shopDetail.id],
      user_type: ['User'],
      vin: ['']
    });
  }

  handleVehicleSection(car){
    this.selectedVehicle = null;
    setTimeout(() => {
      this.selectedVehicle = car;
      this.getVehiclesPreviousSRs(car.id);
      this.SelectedVehicleInfo.emit(this.selectedVehicle);
    }, 500);    
  }

  getVehicleName(vehicleInfo: any) {
    return this.globalService.getGlobalVehicleName(vehicleInfo);
  }

  handleUnSelectVehicle(){
    this.selectedVehicle=null;
    this.showAddVehicleSection = false;
    this.SelectedVehicleInfo.emit(null);
  }

  showAddVehiclesSection(){
      this.showAddVehicleSection =  !this.showAddVehicleSection;
      this.SelectedVehicleInfo.emit(null);
  }

  getCountries(){
    let countryId =  this.shopDetail.country_id;
    this.getStates(countryId);
  }

  getStates(countryId: any) {
    var payload = "country_id=" + countryId + "&opr=1";
    this.globalService.callAPI(globalConstants.API_GET_STATES_URL, payload).subscribe((data: any) => {
      this.states = globalConstants.stateDDLPlaceholder.concat(data.data);
      for (var i in this.states) {
        this.states[i].id = this.states[i].state_id;
        this.states[i].text = this.states[i].state_name;
      }
      this.shopState = this.loginUserDetails.state;
      this.setDefaultState();
    })
  }

  setDefaultState() {
    var stateName: any = "";
    stateName = (this.importCustomerData[0].state == "" || typeof (this.importCustomerData[0].state) == 'undefined') ? this.shopDetail.state : this.importCustomerData[0].state;
    if (this.shopState && stateName == '') {
      stateName = this.shopState;
    }
    if (stateName) {
      const carState = this.states.filter(state => state.state_name == stateName);
      if (carState.length > 0) {
        this.defaultState = carState[0].state_id;
        this.addVehicleForm.controls.state.setValue(carState[0].state_name);
      } else {
        this.defaultState = '';
        this.addVehicleForm.controls.state.setValue('');
      }
    }
  }

  getVehicleMakes() {
    this.globalService.callAPI(globalConstants.API_GET_CAR_MAKES, "opr=1").subscribe((data: any) => {
      this.vehicleMakes = data.data;
      var makeList = [
        {
           id:'',
           text: "Select Make"
        }
      ];
      for (var i in this.vehicleMakes) {
        this.vehicleMakes[i].id = this.vehicleMakes[i].make;
        this.vehicleMakes[i].text = this.vehicleMakes[i].make;
        makeList.push(this.vehicleMakes[i]);
      }
      this.vehicleMakes = makeList;
    })
  }

  handleSelectedMakes(opt: any) {
    if(opt !== '' ){
    this.getVehicleYears(opt);
    this.disableYear = false;
    this.disableModel = true;
    this.addVehicleForm.controls.make.setValue(opt);
    this.addVehicleForm.controls.year.setValue('');
    this.addVehicleForm.controls.model.setValue('');
    }else{
      this.disableYear = true;
      this.disableModel = true;
      this.addVehicleForm.controls.year.setValue('');
      this.addVehicleForm.controls.model.setValue('');

    }
  }

  getVehicleYears(opt) {
    this.globalService.callAPI(globalConstants.API_GET_YEAR_LIST, "opr=1&make=" + opt).subscribe((data: any) => {
      if (data.result == "1") {
        this.addVehicleForm.controls.year.enable();
        var yearsList = [
          {
            id:'',
            text: "Select Year"
         }
        ];
        if (data.data.length != undefined) {
          this.vehicleYears = [];
          this.vehicleYears = data.data;
          for (var i in this.vehicleYears) {
            this.vehicleYears[i].id = this.vehicleYears[i].year;
            this.vehicleYears[i].text = this.vehicleYears[i].year;
            yearsList.push(this.vehicleYears[i]);
          }
          this.vehicleYears = yearsList;
        } else {
          this.vehicleYears = [];
          this.vehicleYears[0] = data.data;
          this.vehicleYears.id = this.vehicleYears.year;
          this.vehicleYears.text = this.vehicleYears.year;
          yearsList.push(this.vehicleYears);
          this.vehicleYears = yearsList;
        }
      } else {
        this.addVehicleForm.controls.year.disable();
      }
    })
  }

  handleSelectedYear(opt: any) {
    if(opt !== ''){
       this.addVehicleForm.controls.model.setValue('');
       this.addVehicleForm.controls.year.setValue(opt);
       this.getVehicleModels(opt);
    }else{
      this.disableModel = true;
      this.addVehicleForm.controls.model.setValue('');
    }
  }

  getVehicleModels(event) {
    if (event == undefined || event == "") {
      this.vehicleModels = [];
      this.addVehicleForm.controls.model.reset();
      this.disableModel = false;
    } else {
      this.addVehicleForm.controls.model.reset();
      this.disableModel = false;
      this.globalService.callAPI(globalConstants.API_GET_MODELS_LIST_URL, "opr=1&make=" + this.addVehicleForm.value.make + "&year=" + event).subscribe((data: any) => {
        if (data.result == "1") {
          var modelsList = [
            {
              id:'',
              text: "Select Model"
           }
          ];
          if (data.data.length == undefined) {
            this.vehicleModels = [];
            this.vehicleModels[0] = data.data;
            this.vehicleModels.id = this.vehicleModels.model + (this.vehicleModels.trim ? ' ' + this.vehicleModels.trim : '') + (this.vehicleModels.body_style ? ' ' + this.vehicleModels.body_style : '')
            this.vehicleModels.text = this.vehicleModels.model + (this.vehicleModels.trim ? ' ' + this.vehicleModels.trim : '') + (this.vehicleModels.body_style ? ' ' + this.vehicleModels.body_style : '')
            modelsList.push(this.vehicleModels);
            this.vehicleModels = modelsList;
          } else {
            this.vehicleModels = [];
            this.vehicleModels = data.data;
            for (var i in this.vehicleModels) {
              this.vehicleModels[i].id = this.vehicleModels[i].model + (this.vehicleModels[i].trim ? ' ' + this.vehicleModels[i].trim : '') + (this.vehicleModels[i].body_style ? ' ' + this.vehicleModels[i].body_style : '')
              this.vehicleModels[i].text = this.vehicleModels[i].model + (this.vehicleModels[i].trim ? ' ' + this.vehicleModels[i].trim : '') + (this.vehicleModels[i].body_style ? ' ' + this.vehicleModels[i].body_style : '')
              modelsList.push(this.vehicleModels[i]);
            }
            this.vehicleModels = modelsList;
          }
        } else {
          this.addVehicleForm.controls.model.disable();
        }
      });
    }
  }
  getSelectedOptionModels(opt: any) {
    this.addVehicleForm.controls.model.setValue(opt);
  }

  useExitingMake(status) {
    this.showMakeTxtFld  = status;
    this.showYearTxtFld = status;
    this.showModelTxtFld = status;
    this.showYearAndModel = !this.showYearAndModel;
    this.showModel   = !this.showModel;
    this.disableYear = true;
  }

  useExitingYear(status){
     this.showYearTxtFld = status;
     this.showModelTxtFld = status;
     this.showModel   = !this.showModel;
  }

  useExitingModel(status){
      this.showModelTxtFld = status;
  }


  AddNewVehicles() {
    setTimeout(() => {
      this.addVehicleForm.controls.customer_id.setValue(this.importCustomerData[0].id);
      if ((this.addVehicleForm.value.license_plate && this.addVehicleForm.value.state !== '') || (this.addVehicleForm.value.make && this.addVehicleForm.value.year && this.addVehicleForm.value.model && this.addVehicleForm.valid)) {
        var formData = new FormData();
        for (var i in this.addVehicleForm.value) {
          if (this.addVehicleForm.value[i] == null) {
            formData.append(i, '');
          } else {
            formData.append(i, this.addVehicleForm.value[i]);
          }
        }
        this.globalService.formData(globalConstants.API_SELF_CHECKIN_ADD_VEHICLE, formData).subscribe(data => {
          var message = data.message;
          if (data.result == "1") {
            this.globalService.snackbar('success', message);
            this.importVehicleList.push(data.data);
            this.handleVehicleSection(data.data);
            this.showAddVehicleSection = false;
            this.reSetAddVehicleForm();
          } else {
            if (data.code == "311" || data.code == "310") {
              this.checkLicense(true);
            } else if (data.code == "312" || data.code == "313") {
              this.checkLicense();
            }
          }
        })
      } else {
        this.showCommonErrorMessage = 3;
      }
    }, 1000);
  }

  checkLicense(VINFindState = false) {
    let license_plate = this.addVehicleForm.controls.license_plate.value;
    if (license_plate && this.addVehicleForm.controls.license_plate.valid) {
      let stateName = this.addVehicleForm.controls.state.value;
      let payload = "user_type=" + this.loginUserDetails.user_type + "&user_id=" + this.loginUserDetails.shop_id  + "&customer_id=" + this.importCustomerData[0].id;
      let carState = this.states.filter(state => state.state_name == stateName);
      if (carState.length > 0) {
        payload = payload + "&state_name=" + carState[0].state_name + "&state=" + carState[0].state_code + "&partstech_check=1";
      } else {
        payload = payload + "&state=&partstech_check=1";
      }
      if(VINFindState){
        payload = payload+ "&vin=" + this.addVehicleForm.controls.vin.value;
      }else{
        payload = payload+ "&license_plate=" + license_plate
      }
      this.globalService.callAPI(globalConstants.API_CHECK_VEHICLE_INFO, payload).subscribe((data: any) => {
        if (data.result == "1") {
          let vehicleInfo = data.data;
          if ('code' in data && data.code == '500' && 'vehicle_info' in data.data) {
            if (vehicleInfo.vehicle_info.customer_id == this.importCustomerData[0].id) {
              let info= this.checkVehicleExit(vehicleInfo.vehicle_info);
              if(info.status == true){
                this.handleVehicleSection(info.vehicleInfo);
                this.showAddVehicleSection = false;
                this.reSetAddVehicleForm()
              }else{
                vehicleInfo.vehicle_info.id = vehicleInfo.vehicle_info.vehicle_id;
                vehicleInfo.vehicle_info.vehicle_pic = '';
                this.importVehicleList.push(vehicleInfo.vehicle_info);
                this.handleVehicleSection(vehicleInfo.vehicle_info);
                this.showAddVehicleSection = false;
                this.reSetAddVehicleForm();
              }
            } else {
              let info= this.checkVehicleExit(vehicleInfo.vehicle_info);
              if(info.status == true){
                this.handleVehicleSection(info.vehicleInfo);
                this.showAddVehicleSection = false;
                this.reSetAddVehicleForm()
              }else{
                this.handleSRCreationViaSearch(vehicleInfo,'licence');
                // let obj = {
                //   vehicle_pic: "",
                //   id: vehicleInfo.vehicle_info.vehicle_id,
                //   make: typeof (vehicleInfo.vehicle_info.make) !== 'undefined' ? vehicleInfo.vehicle_info.make : '',
                //   year: typeof (vehicleInfo.vehicle_info.year) !== 'undefined' ? vehicleInfo.vehicle_info.year : '',
                //   model: typeof (vehicleInfo.vehicle_info.model) !== 'undefined' ? vehicleInfo.vehicle_info.model : '',
                //   ownerName: vehicleInfo.vehicle_info.customer_name,
                //   ownerStatus: true,
                //   license_plate: vehicleInfo.vehicle_info.license_plate
                // }
                // this.importVehicleList.push(obj);
                // this.handleVehicleSection(obj);
                // this.showAddVehicleSection = false;
                // this.reSetAddVehicleForm()
              }
            }
          } else {
            this.fillLicensePlateData(vehicleInfo);
          }
        } else {
          this.globalService.snackbar("error", data.message);
        }
      });
    }
  }

  checkVehicleExit(vehicleInfo) {
    var status = false;
    for (var i in this.importVehicleList) {
      if (vehicleInfo.license_plate == this.importVehicleList[i].license_plate) {
        return {
          status: true,
          vehicleInfo: this.importVehicleList[i]
        }
      } else {
        status = true;
      }
    }
    return {
      status : false
    }
  }

  fillLicensePlateData(vehicleInfo) {
    this.showMakeTxtFld  = true;
    this.showYearTxtFld = true;
    this.showModelTxtFld = true;
    this.showYearAndModel = false;
    this.showModel = false;
    Object.entries(vehicleInfo).forEach(([key, val]) => {
      if (key == 'license_plate') {
        this.addVehicleForm.controls.license_plate.setValue(val);
      }
      else if (key == 'make') {
        this.addVehicleForm.controls.make.setValue(val);
      }
      else if (key == 'model') {
        this.addVehicleForm.controls.model.setValue(val);
      } else if (key == 'vin') {
        this.addVehicleForm.controls.vin.setValue(val);
      } else if (key == 'year') {
        this.addVehicleForm.controls.year.setValue(val);
      } else if (key == 'engine_size') {
        this.addVehicleForm.controls.sub_model.setValue(val);
      } 
    });
    this.disableYear = true;
    this.disableModel = true;
    if (vehicleInfo.year != "" && typeof vehicleInfo.year != 'undefined') {
      let carProductionDate = "01/" + vehicleInfo.year;
      this.addVehicleForm.controls.production_date.setValue(carProductionDate);
    } else {
      this.addVehicleForm.controls.production_date.setValue('');
    }
  }

  reSetAddVehicleForm(){
    this.addVehicleForm.reset();
    this.showMakeTxtFld  = false;
    this.showYearTxtFld = false;
    this.showModelTxtFld = false;
    this.showYearAndModel = true;
    this.showModel = true;
    this.disableModel = true;
    this.disableYear = true;
    this.addVehicleForm.controls.user_type.setValue('User');
    this.addVehicleForm.controls.user_id.setValue(this.shopDetail.id);
    this.addVehicleForm.controls.make.setValue('');
    this.addVehicleForm.controls.year.setValue('');
    this.addVehicleForm.controls.model.setValue('');
    this.showCommonErrorMessage = 0;
    this.defaultState = '';
    this.setDefaultState();
  }

  handleSelectedState(opt) {
    if(opt !==''){
    const carState = this.states.filter(state => state.state_id == opt);
    this.addVehicleForm.controls.state.setValue(carState[0].state_name);
    this.checkLicense();
    }else{
      this.addVehicleForm.controls.state.setValue('');
    }
  }

  handleSRCreationViaSearch(customerVehicleInfo, searchBy){
    let status = 1; 
    if(this.importCustomerData[0].id == customerVehicleInfo.vehicle_info.customer_id){
        status = 1;
    }else if(this.importCustomerData[0].id != customerVehicleInfo.vehicle_info.customer_id){
        status = 2;
    }
    if(this.shopDetail && customerVehicleInfo.is_sublet == '1'){
      status = 3;
  }
  const dialogRef = this.dialog.open(SearchcarComponent, {
      panelClass: ['car-dialog-form'],
      data: {
          status:status,
          customerVehcileInfo: customerVehicleInfo,
          shopCustomerDetails: this.importCustomerData[0],
          currentCustomer    : this.loginUserDetails,
          searchBy: searchBy,
      }
  });
  dialogRef.afterClosed().subscribe(result => {
    if(result){
      this.dialogRef.close(true);
    }         
  });  
  }

  getVehiclesPreviousSRs(vehicleId){ 
        let payload = "car_id="+vehicleId+"&user_type=User&shop_id="+this.shopDetail.id;
        this.globalService.callAPI(globalConstants.API_GET_SHOP_VEHICLE_SERVICE,payload).subscribe((res:any) => {
            if (res.result == 1 && res.data.length > 0) {                
                 this.dialog.open(ShopVehicleServiceComponent, {
                    panelClass: ['car-dialog-form','recent-sr-modal'],
                    data: {tickets:res.data} 
                });
            }else if(res.result == 0){
                this.globalService.snackbar('error', res.message);
                if(res.code == 297){
                  let popUpDisplayType = "yes"; // defualt display type - yes/no
                  let note = "Taxes and supplies charges are required in order to Add a new Service Request. Do you want to complete profile settings now?";
                  this.globalService.confirmDialog(popUpDisplayType, note).subscribe((res: any) => {
                      if (typeof (res) !== 'undefined') {
                          let dialogRef = this.dialog.open(CommonDialogBigComponent, {
                                      panelClass: ['car-dialog-form','common-dialog','newSr-profile-setup'],
                                      data: {opr:1, dialogTitle:"Shop Profile Setup"} 
                                  }); 
                                  dialogRef.afterClosed().subscribe(result => {
                                      if (result.status == 1) {
                                          
                                      }
                                  })
                      } 
                  }) 
              
              }
            }
        },(error)=>{

        });                   
}

onlyDecimalNumberKey(event) {
  let charCode = (event.which) ? event.which : event.keyCode;
  if (charCode != 46 && charCode > 31
    && (charCode < 48 || charCode > 57))
    return false;
    return true;
}

    /**
   * validates the form control's value on paste event.
   * ensure that validation messages are displayed if the pasted value is invalid.
   */
    validateFieldOnPaste(controlName: string) {
      const control = this.addVehicleForm.get(controlName);
      control.markAsTouched();
    }

  handleVehicleSearch() {
    if (this.vehicleSearchForm.valid) {
      this.payload.search_by = this.vehicleSearchForm.value.search_by;
      this.payload.search_keyword = this.vehicleSearchForm.value.search_keyword;
      this.payload.customer_id = this.importCustomerData[0].id;
      this.SearchVehicleObj.emit(this.payload);
    }
  }

  handleClearVehicleSearch() {
    if (this.payload.search_keyword !== '') {
      this.payload.search_by = '0';
      this.payload.search_keyword = '';
      this.vehicleSearchForm.controls.search_keyword.setValue('');
      this.vehicleSearchForm.controls.search_by.setValue('0');
      this.payload.customer_id = this.importCustomerData[0].id;
      this.SearchVehicleObj.emit(this.payload);
    }
  }
    ngOnChanges() {
        if(this.viaSearchVehicles){
           this.handleUnSelectVehicle();
        }
    }
}
