<div id="mainCompotents">
  <div class="main-content">
    <div class="shopaddnewpart">
      <div class="panel panel-primary">
        <div class="panel-heading padding-left-15">
          <h3 class="panel-title">Part Matrix Setting</h3>
        </div>
        <div class="panel-body bgcolor-lightgrey">
          <!-- Part Matrix start -->
          <div id="addNewPart" class="row  form-content part-matrix">
            <div class="container-fluid">
              <form [formGroup]="matrixForm" novalidate (ngSubmit)="submitMatrix(1)" id="addPartForm"
                class="form-inline col-sm-12 padding-bottom-10 padding-top-5" role="form" method="POST" enctype="multipart/form-data">
                <div class="row">
                  <mat-slide-toggle class="mat-accent part-matrix-toggle" (change)="enbSetting($event)" [(ngModel)]="enableSetting"
                    [ngModelOptions]="{standalone: true}" [checked]="enableSetting" labelPosition="before">
                    Part Matrix
                  </mat-slide-toggle>
                  <div class="col-md-12 col-sm-12 margin-bottom-15">
                   <div class="help-block">Matrix pricing is a system that uses multipliers to determine the selling price of a part used in a repair order. Here you can enable one matrix option at a time.</div>
                  </div>
                </div>
                <div class="row" *ngIf="enableSetting">
                  <div class="col-sm-12">
                    <label class="material-radio">
                      <input type="radio" name="matrixType" [disabled]="!enableSetting"
                        [checked]="matrixForm.value.method_type=='1'" value="1" (change)="matrixType($event,1)" />
                      <span>Adjustment Method (Adj)</span>
                    </label>
                    <div class="alertalert-info help-block">This matrix works on margins profits. Margin Start could be the max. margin profit and Margin End could be the min. profit you want to setup. Based on cost limit, margin start and margin end matrix would calculate the margin range and would adjust the retail price in repair orders.</div>
                  </div>
                  <div class="col-md-8 col-sm-12 col-md-offset-2 padding-sm-0" *ngIf="methodType==1">
                    <div class="display-full padding-bottom-15">
                      <div class="col-sm-6 col-lg-5">
                        <label>Matrix < Retail Defaults to</label>
                      </div>
                      <div class="col-sm-6 col-lg-7">
                        <label class="material-radio">
                          <input type="radio" name="price_set" [checked]="matrixForm.value.price_set=='1'"  (click)="priceType($event,1)"  value="1"  />
                          <span class="margin-right-10">Matrix Price</span>
                        </label>
                        <label class="material-radio">
                          <input type="radio" name="price_set" [checked]="matrixForm.value.price_set=='2'" (click)="priceType($event,2)" value="2"   />
                          <span>Retail Price</span>
                        </label>
                      </div>
                    </div>
                    <div class="display-full">
                      <div class="col-md-3">
                        <label>Cost Limit</label>
                      </div>
                      <div class="relative col-md-9 ">
                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                          <input matInput placeholder="Cost Limit" title="Cost Limit"
                            class="form-control input-lg custom-form-input" [(ngModel)]="adjMethod.cost_limit"
                            (keydown)="disableKey($event)" (keyup)="vkFloat($event,'adjMethod')" [textMask]="{mask: costMask}"
                            (keypress)="onlyDecimalNumberKey($event)" (change)="calAdj()" [disabled]="!enableSetting"
                            [ngModelOptions]="{standalone: true}" />

                        </mat-form-field>
                      </div>
                    </div>

                    <div class="display-full">
                      <div class="col-md-3">
                        <label>Margin Start(%)</label>
                      </div>
                      <div class="relative col-md-9 ">
                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                          <input matInput placeholder="Margin Start" title="Margin Start" [textMask]="{mask: marginMask}"
                            class="form-control input-lg custom-form-input" [(ngModel)]="adjMethod.margin_start"
                            (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)" (change)="calAdj()"
                            [disabled]="!enableSetting" [ngModelOptions]="{standalone: true}" />
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="display-full">
                      <div class="col-md-3">
                        <label>Margin End(%)</label>
                      </div>
                      <div class="relative col-md-9 ">
                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                          <input matInput placeholder="Margin End" title="Margin End" [textMask]="{mask: marginMask}"
                            class="form-control input-lg custom-form-input" [(ngModel)]="adjMethod.margin_end"
                            (keydown)="disableKey($event)" (keypress)="onlyDecimalNumberKey($event)" (change)="calAdj()"
                            [disabled]="!enableSetting" [ngModelOptions]="{standalone: true}" />
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="display-full margin-sm-bottom-10">
                      <div class="col-md-3">
                        <label>Margin Range(%)</label>
                      </div>
                      <div class="relative col-md-9 ">
                        <span>{{adjMethod.margin_range}}%</span>
                      </div>
                    </div>
                    <div class="display-full margin-sm-bottom-10">
                      <div class="col-md-3">
                        <label>Adj.</label>
                      </div>
                      <div class="relative col-md-9 ">
                        <span>{{adjMethod.adjustment_value}}</span>
                      </div>
                    </div>
                    <div class="display-full">
                      <div class="col-sm-3">
                        <label>Above Cost Limit</label>
                      </div>
                      <div class="col-sm-9">
                        <label class="material-radio">
                          <input type="radio" name="adjMatrixLimitType" [checked]="adjMethod.above_cost_limit=='1'"  value="1" (change)="adjAboveCostlimit($event,1)" />
                          <span class="margin-right-10">Part Retail Price</span>
                        </label>
                        <label class="material-radio">
                          <input type="radio" name="adjMatrixLimitType" [checked]="adjMethod.above_cost_limit=='2'"  value="2" (change)="adjAboveCostlimit($event,2)" />
                          <span>Apply Adj. Margin</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <hr *ngIf="enableSetting" />
                <div class="row" *ngIf="enableSetting">
                  <div class="display-full pricing-method">
                    <div class=" col-sm-12">
                      <label class="material-radio">
                        <input type="radio" [disabled]="!enableSetting" name="matrixType"
                          [checked]="matrixForm.value.method_type=='2'" value="2" (change)="matrixType($event,2)" />
                        <span>Part Pricing Method</span>
                      </label>
                      <div class="alertalert-info help-block">This matrix works on part pricing range. It takes a range of part's retail price and a profit multiplier to calculate the part price for repair order. Part Pricing Method is a gradual method for growing profits over time it typically works on a sliding scale; the lower the cost of a part, the higher the multiplier based on a margin calculation. As the cost of the parts rise, the markup drops.</div>
                    </div>
                    <div class="col-sm-12 text-right add-range-btn" *ngIf="methodType==2">
                      <button type="button" title="Add Range" [disabled]="!enableSetting" class="mat-raised-button mat-accent" (click)="addRange()">Add Range</button>
                    </div>
                  </div>
                  <div class="margin-top-10 col-md-8 col-sm-12 col-md-offset-2 padding-sm-0" *ngIf="methodType==2">
                    <div class="display-full padding-bottom-15">
                      <div class="col-sm-3 col-lg-5">
                        <label>Matrix < Retail Defaults to</label>
                      </div>
                      <div class="col-sm-9 col-lg-7">
                        <label class="material-radio">
                          <input type="radio" name="price_set" [checked]="matrixForm.value.price_set=='1'"  (click)="priceType($event,1)"  value="1"  />
                          <span class="margin-right-10">Matrix Price</span>
                        </label>
                        <label class="material-radio">
                          <input type="radio" name="price_set" [checked]="matrixForm.value.price_set=='2'" (click)="priceType($event,2)" value="2"   />
                          <span>Retail Price</span>
                        </label>
                      </div>
                    </div>
                    <div class="display-full">
                      <div class="col-md-3">
                        <label>Cost Limit</label>
                      </div>
                      <div class="relative col-md-9 ">
                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                          <input matInput placeholder="Cost Limit" title="Cost Limit" [textMask]="{mask: costMask}"
                            class="form-control input-lg custom-form-input" [(ngModel)]="priceCostLimit"
                            (keydown)="disableKey($event)" (keyup)="vkFloat($event,'priceCostLimit')"
                            (keypress)="onlyDecimalNumberKey($event)" (change)="rangeValid(1)"
                            [disabled]="!enableSetting" [ngModelOptions]="{standalone: true}" />
                        </mat-form-field>
                      </div>
                    </div>
                    
                    <div class="col-md-12 col-sm-12 pricing-method">
                   
                        <div class="table-responsive relative ">
                          <table class="table table-striped margin-bottom-10 ">
                            <thead class="table-heading">
                              <tr>
                                <th>Cost Ranges</th>
                                <th>Start</th>
                                <th>End</th>
                                <th>Markup</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let r of priceRages;let i=index">
                                <td>{{(1+i)}}</td>
                                <td>{{r.margin_start}}</td>
                                <td>{{r.margin_end}}</td>
                                <td>{{r.markup}}%</td>
                                <td>
                                  <button [disabled]="!enableSetting" class="btn btn-xs btn-link text-red" (click)="delRange(i)" title="Delete Ranges" type="button"><i class="fa fa-trash fa-lg"></i></button>
                                  <button [disabled]="!enableSetting" class="btn btn-xs btn-link text-red" (click)="editRange(i)" title="Edit Ranges" type="button"><i class="fa fa-pencil fa-lg"></i></button>
                                </td>
                              </tr>
                              <tr *ngIf="priceRages.length==0" class="tbl-center">
                                  <td colspan="5">No Records Available</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                    </div>

                    <div class="display-full">
                      <div class="col-sm-3">
                        <label>Above Cost Limit</label>
                      </div>
                      <div class="col-sm-9">
                        <label class="material-radio">
                          <input type="radio" name="priceMatrixLimitType" [checked]="aboveCostLimit=='1'" value="1" (change)="setAboveCostlimit($event,1)" />
                          <span class="margin-right-10">Part Retail Price</span>
                        </label>
                        <label class="material-radio">
                          <input type="radio" name="priceMatrixLimitType" [checked]="aboveCostLimit=='2'"  value="2" (change)="setAboveCostlimit($event,2)" />
                          <span>Last Markup Applied</span>
                        </label>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="row form-block submit-block">
                    <div class="col-sm-4 col-lg-3 btn-col">
                      <button type="button" mat-raised-button
                        class="car-btn btn-block text-uppercase  btn-lg font-light fontcolor-white" id="saveClose"
                        color="accent" title="Submit"  (click)="submitMatrix(1)">Submit</button>
                    </div>
                </div>
              </form>
            </div>
          </div>
          <!-- Part Matrix end -->
        </div>
      </div>
    </div>
  </div>
</div>