import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router, Params, ActivatedRoute} from '@angular/router';
import { DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {AppComponent} from "../../app.component";
import { globalConstants } from '../../../environments/globalConstants';
import { GlobalService } from '../../../environments/global.service';
import { TitleService } from '../../Titleservice.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-wak-details',
  templateUrl: './wak-details.component.html',
  styleUrls: ['./wak-details.component.scss']
})
export class WAKdetailsComponent implements OnInit,OnDestroy {
   
    public WAKContent :any= {title:'&nbsp;', wak_content:''};
    public contentId;
    public subscriptionDataForAPI: Subscription = new Subscription();

    constructor(public app:AppComponent,public titleService: TitleService,private router: Router, public globalService: GlobalService, private _sanitizer: DomSanitizer, public activatedRoute: ActivatedRoute) {
        if((this.router.url.indexOf('/shops/cms') > -1) || (this.router.url.indexOf('/shops/wak') > -1)){
            if(!localStorage.getItem('loggedInUser') ){
                this.router.navigate(["/shops"]);
            }
        }else{
            if(!localStorage.getItem('loggedInCustomer') ){
                this.router.navigate(["/customer"]);
            }
        }
        this.activatedRoute.params.subscribe((params: Params) => {
            this.contentId = params["id"];
            this.getWak();
        });
    }

    ngOnInit() {
    }

    public getWak(){
         if((this.router.url.indexOf('/shops/cms') > -1) || (this.router.url.indexOf('/shops/wak') > -1)){
            this.globalService.setMetaData("SHOPS","WAK");
            this.getWAKContent(1);
        }else{
            this.globalService.setMetaData("CUSTOMER","WAK");
            this.getWAKContent(2);
        }
    }
    
    getWAKContent(type){
        let shopId;
        if(localStorage.getItem('loggedInUser')){
            let shop = JSON.parse(localStorage.getItem('loggedInUser'));
            shopId = shop.shop_id;
        }
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_WAK_CONTENT, { type: type, shop_id: shopId,cms_id : this.contentId}).subscribe((data:any) => {
            if (data.result == "1") {
              this.WAKContent = data.data[0];
              this.titleService.setTitle("CAR :: " +this.WAKContent.title); //setting title dynamically
            }else{
                this.globalService.snackbar("error", data.message);
                if((this.router.url == ('/shops/cms/'+this.contentId)) || (this.router.url == ('/shops/wak/'+this.contentId))){
                    this.router.navigate(['shops/dashboard']);
                }else{
                    this.router.navigate(['customer/dashboard']);
                }
            }
          }, (error) => { });
    }

    public htmlProperty() : SafeHtml {
        if(this.WAKContent && this.WAKContent.wak_content){
            return this._sanitizer.bypassSecurityTrustHtml(this.WAKContent.wak_content);
        }
     }
   
     ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
    }  
}
