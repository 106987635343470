<div class="panel panel-primary relative" id="mainCompotents">
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">PARTS LIST</h3>
        <button mat-mini-fab class="custom-btn-back list-smart-batch-button" title="Smart Batch Numbers List"  (click)="goToSmartBatchList();" >
            <i class="fa fa-fw fa-list margin-right-5"></i><span class="hidden-xs">SMART BATCH NUMBERS LIST</span>
        </button>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <div class="display-full relative" >
            <mat-expansion-panel hideToggle="false" class="search-filter search-employee relative" [expanded]="openShopPartsListSearch">
                <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px">
                    <strong><i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
                </mat-expansion-panel-header>
                <form id="advanceSearchForm" class="collapse in" aria-expanded="true">
                    <div class="panel-body">
                        <div class="col-sm-3 uc-ngx-select">
                            
                            <ngx-select class="display-full margin-bottom-20" 
                                [items]="searchByArray" 
                                [noAutoComplete]="true"
                                [(ngModel)]="options.search_by" 
                                [ngModelOptions]="{standalone: true}"
                                placeholder="Search By">
                            </ngx-select>
                        </div>

                        <div class="col-sm-3">
                            <mat-form-field class="car-input btn-block" loatPlaceholder="never" appearance="fill">
                                <input  matInput placeholder="Search Keyword" title="Search Keyword" [(ngModel)]="options.search_keyword" [ngModelOptions]="{standalone: true}" class="form-control input-lg custom-form-input"/>
                            </mat-form-field>
                        </div>
                        
                        <div class="col-sm-3 uc-ngx-select">
                            
                            <ngx-select class="display-full margin-bottom-20" 
                                [items]="sortByArray" 
                                [noAutoComplete]="true"
                                [(ngModel)]="options.sort_by" 
                                [ngModelOptions]="{standalone: true}"
                                placeholder="Sort By">
                            </ngx-select>
                        </div>

                        <div class="col-sm-3 uc-ngx-select">
                            
                             <ngx-select class="display-full margin-bottom-20" 
                                [items]="sortTypeArray" 
                                [noAutoComplete]="true"
                                [(ngModel)]="options.sort_type" 
                                [ngModelOptions]="{standalone: true}"
                                placeholder="Sort Type">
                            </ngx-select>
                        </div>
                        <div class="col-sm-3 uc-ngx-select">
                            <ngx-select [autoClearSearch]="true" class="display-full margin-bottom-20 details-input" [(ngModel)]="options.part_tag" 
                            [ngModelOptions]="{standalone: true}" [multiple]="true" [items]="allTags" optionTextField="tag_name" placeholder="Select Tags"></ngx-select>
                       </div>
                        
                    </div>
                    <mat-action-row class="search-filter-footer">
                      <button mat-raised-button color="accent" title="Search" type="submit" (click)="search($event)">
                        Search
                      </button>
                    </mat-action-row>
                </form>
            </mat-expansion-panel>
            <button mat-button title="Clear Filter" class="clear-btn" #clearBtn (click)="clearSearch()" [class.active-search]="checkFormEmpty(clearBtn)">
                Clear Filter
            </button>
        </div>

        <div class="car-datatable uc-datatable mat-elevation-z1">
            <div class="table-responsive relative">
                <mat-table  [dataSource]="dataSource" matSort class="datatable-loader-bg uc-table-responsive">

                    <!-- Color Column -->
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Name </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Name"> {{row.name}} </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="part_number">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Part Number </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Part Number"> {{row.part_number}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="manufacturer">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Manufacturer </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Manufacturer"> {{(row.manufacturer)}} </mat-cell>
                    </ng-container>

                    <!-- Color Colu`mn -->
                    <ng-container matColumnDef="matrix_price">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="price"> Price ($) </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="price" data-val="Price">
                            <span *ngIf="showCostPrice">
                                <span *ngIf="row.matrix_status==1"  tooltipPlacement="top">
                                    {{ row.matrix_price | number:'1.2-2' }}
                                    <span>({{row.cost}})</span>
                                </span>
                                <span *ngIf="row.matrix_status==0"  tooltipPlacement="top">
                                    {{row.cost}}
                                </span>
                                <!-- <span *ngIf="row.matrix_status==1">({{row.matrix_price}})</span> -->
                            </span>
                            <span *ngIf="!showCostPrice">-</span>
                        </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="quantity">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Quantity </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Quantity">
                            <strong [class.text-red]="row.is_low_stock">{{showWholeNumber(row.quantity)}}&nbsp;({{showWholeNumber(row.low_stock_value)}})</strong>
                            <button (click)="addStockOrder(row)" *ngIf="addTostockOrder && row.is_low_stock" class="btn-link text-red" title="Add to stock order"><i class="fa fa-cart-plus fa-lg" ></i></button>
                        </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="Picture">
                        <mat-header-cell *matHeaderCellDef  data-role="pic"> Picture </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="pic" data-val="Picture"> <img style="max-width:85px;max-height:75px;padding:5px 0;" *ngIf="row.picture !='' && row.picture !=null" src="{{partPicture}}{{row.picture}}" /> </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="added_on">
                        <mat-header-cell *matHeaderCellDef mat-sort-header  data-role="date"> Added on </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="date" data-val="Added on"> {{row.added_on }} </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="Actions">
                        <mat-header-cell *matHeaderCellDef data-role="button"> Actions </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="button">
                            <button *ngIf="isAllowed('edits') || isAllowed('deletes')" mat-button class="list-action-btn" [matMenuTriggerFor]="menu" disableRipple >
                                Action<mat-icon>arrow_drop_down</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu" class="car-icon-menu" [overlapTrigger]="false">
                                <a class="mat-menu-item" *ngIf="isAllowed('edits') && row.delete_status=='0'" (click)="editPart(row.part_id)"><i class="fa fa-edit"></i>Edit</a>
                                <a class="mat-menu-item" *ngIf="(isAllowed('deletes') && row.delete_status=='0') " (click)="deletePart(row.part_id)"><i class="fa fa-trash"></i>Delete</a>
                                <a class="mat-menu-item" *ngIf="isAllowed('edits') && row.delete_status=='1'" (click)="enablePart(row.part_id)"><i class="fa fa-edit"></i>Enable</a>
                                <a class="mat-menu-item" *ngIf="isAllowed('views') && row.delete_status=='0'" (click)="viewLog(row.part_id)"><i class="fa fa-eye"></i>Logs</a>
                            </mat-menu>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
                <div class="padding-15 text-center"
                     [style.display]="getTotalRecords() < 1 ? '' : 'none'">
                     No Records Available
            </div>
        </div>
        <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
            <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="options.limit" [pageSizeOptions]="[5, 10,25, 100]" (page)="paginateByLimit($event)" >
            </mat-paginator>

            <div class="mat-paginator table-paging">
                <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
                <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                        <mat-icon>navigate_before</mat-icon>
                </button>
                <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                        <mat-icon>navigate_next</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
</div>
