import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/environments/global.service';
import { globalConstants } from 'src/environments/globalConstants';
@Component({
  selector: 'app-alert-warning',
  templateUrl: './alert-warning.component.html',
  styleUrls: ['./alert-warning.component.scss']
})
export class AlertWarningComponent implements OnInit {
  @Input() addonPayloadValue :any;
  public waringMsg :any ;
  constructor(
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    if(this.addonPayloadValue == globalConstants.ADD_ONS_OBJ.privateReview){
       this.waringMsg ="Private Review is not included in your current subscription plan. Contact administrator to get it enabled."
    }else if(this.addonPayloadValue == globalConstants.ADD_ONS_OBJ.externalPayment){
      this.waringMsg ="External Payment  is not included in your current subscription plan. Contact administrator to get it enabled."
    }else if(this.addonPayloadValue == globalConstants.ADD_ONS_OBJ.dfsReminders){
      this.waringMsg ="Due For Service is not included in your current subscription plan. Contact administrator to get it enabled."
   }else if(this.addonPayloadValue == globalConstants.ADD_ONS_OBJ.twoWaySMS){
      this.waringMsg ="Contact CAR support to get Live Chat feature enabled for your account."
   }else if(this.addonPayloadValue == globalConstants.ADD_ONS_OBJ.oneWaySMS){
    this.waringMsg ="SMS Feature is not included in your current subscription plan. Contact administrator to get it enabled."
 }
 }


  sendAddOnsRequest(){
    if(localStorage.getItem("loggedInUser")) {
      let currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
    let payload = {
        "user_id":currentCustomer.id,
        "user_type":currentCustomer.user_type,
        "comment":'',
        "add_on": this.addonPayloadValue
    };
     this.globalService.callAPI(globalConstants.API_ADD_ON_REQUEST_MAIL,payload).subscribe((res:any) => {
          if(res.result == 1){
                this.globalService.snackbar('success', res.message);
          }else{
            this.globalService.snackbar('error', res.message);
          }
    })
    return false;
}
}

}
