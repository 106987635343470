import { Component,  OnInit, Inject, ViewEncapsulation, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import {IDatePickerConfig} from 'ng2-date-picker';
import {  MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { GlobalService } from '../../../../../environments/global.service';
import { globalFunctions } from '../../../../../environments/globalFunctions';
import { globalConstants } from '../../../../../environments/globalConstants';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-service-field-update',
  templateUrl: './service-field-update.component.html',
  styleUrls: ['./service-field-update.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe]
})
export class ServiceFieldUpdateComponent implements OnInit {

  public isRequestAppointment=false;
  public param:any;
  public SRForm: FormGroup;
  public formType;
  public request_title;
  public appt_date;
  public schduled_date;
  public start_date;
  public end_date;
  public est_hours;
  public submit=false;
  public eventType:number;
  public submitDisabled:boolean = false;
  public startDate;
  public endDate;

  public datePickerConfig: IDatePickerConfig = {
    drops: "down",
    format: "MM/DD/YYYY hh:mm A",
    min: this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm a'),
    closeOnSelect:true,
    disableKeypress:true,
    unSelectOnClick:false
  };

  public dateSPickerConfig: IDatePickerConfig = {
    drops: "down",
    format: "MM/DD/YYYY hh:mm A",
    min: this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm a'),
    closeOnSelect:true,
    disableKeypress:true,
    unSelectOnClick:false
  };

  myControl: FormControl = new FormControl();

  constructor(public gs: GlobalService,
    public dialogRef: MatDialogRef<ServiceFieldUpdateComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
    private formBuilder: FormBuilder,
    public router: Router,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private form: FormBuilder
    ){
       this.param = data;
       this.request_title = data.sr_data.dialogMetaData.service_request_title;
       this.appt_date     = moment(data.sr_data.dialogMetaData.requested_delivery_date).toISOString();
       if(this.appt_date){
        this.dateSPickerConfig = {
          drops: "down",
          format: "MM/DD/YYYY hh:mm A",
          min: this.datePipe.transform(new Date(this.appt_date), 'MM/dd/yyyy hh:mm a'),
          closeOnSelect:true
        };
       }
       if(data.sr_data.dialogMetaData.schedule_service_date)
       this.schduled_date = moment(data.sr_data.dialogMetaData.schedule_service_date).toISOString();
       if(data.sr_data.dialogMetaData.start_date)       
       this.start_date    = moment(data.sr_data.dialogMetaData.start_date).toISOString();
       if(data.sr_data.dialogMetaData.end_date)
       this.end_date      = moment(data.sr_data.dialogMetaData.end_date).toISOString();;
       this.est_hours     = data.sr_data.dialogMetaData.est_hours;
       this.eventType     = data.sr_data.dialogMetaData.event_type;
       this.isRequestAppointment = this.param.form_event;
       this.formType = this.param.event_type;
     }

  ngOnInit() {
    this.SRForm = this.form.group({
      event_request_title: [''],
      event_appt_date:[''],
      event_scheduled_date:[''],
      event_start_date:[''],
      event_end_date:[''],
      event_est_hours:[''],
    });

    let apptDate      = this.datePipe.transform(this.appt_date, 'MM/dd/yyyy hh:mm a');
    let scheduledDate = this.datePipe.transform(this.schduled_date, 'MM/dd/yyyy hh:mm a');
    let startDate     = this.datePipe.transform(this.start_date, 'MM/dd/yyyy hh:mm a');
    let endDate       = this.datePipe.transform(this.end_date, 'MM/dd/yyyy hh:mm a');

    this.startDate = this.datePipe.transform(startDate, 'MMddyyyyHHmmss');
    this.endDate = this.datePipe.transform(endDate, 'MMddyyyyHHmmss');

    if(this.param.event_type == 'title'){
      this.SRForm.controls.event_request_title.setValue(this.request_title);
    } else if(this.param.event_type == 'appt_date' && apptDate !== null){
      this.SRForm.controls.event_appt_date.setValue(apptDate);
    }else if(this.param.event_type == 'scheduled_date' && scheduledDate !== null){
      this.SRForm.controls.event_scheduled_date.setValue(scheduledDate);
    }else if(this.param.event_type == 'start_date' && startDate != null){
      this.SRForm.controls.event_start_date.setValue(startDate);
    }else if(this.param.event_type == 'end_date' && endDate !== null){
      this.SRForm.controls.event_end_date.setValue(endDate);
    }else if(this.param.event_type == 'est_hours' && this.est_hours !== null){
      this.SRForm.controls.event_est_hours.setValue(this.est_hours);
    }
  }

  checkIfValid() {
    if (this.SRForm.valid) {
        this.submitDisabled = false;
    }
  }

  onlyNumericKey(event) {
    return globalFunctions.onlyDecimalNumberKey(event);
  }

  editSRdetails(){

    // if(this.SRForm.value.event_start_date != ''){
    //   let startDate = this.datePipe.transform(this.SRForm.value.event_start_date, 'MMddyyyyHHmmss');
    //   let endDate = this.datePipe.transform(this.end_date, 'MMddyyyyHHmmss');

    //   if(startDate == endDate || startDate > endDate){
    //     this.gs.snackbar("error", 'Invalid Date Range');
    //     return;
    //   }
    // }

   if(this.SRForm.valid) {

      let startDate = this.datePipe.transform(this.SRForm.value.event_start_date, 'MMddyyyyHHmmss');
      let endDate = this.datePipe.transform(this.SRForm.value.event_end_date, 'MMddyyyyHHmmss');

      if(startDate){
        if(startDate > this.endDate){
          this.gs.snackbar("error", 'Start date should be less than End date');
          return;  
        }
      }
      if(endDate){
        if(endDate < this.startDate){
          this.gs.snackbar("error", 'End date should be greater than End date');
          return;  
        }
      }

      this.submit=true;
      var data = new FormData();
      data.append('user_id', this.gs.getCurrentUser()["id"]);
      data.append('user_type', this.gs.getCurrentUser()["user_type"]);
      if(this.SRForm.value.event_request_title){
        data.append('request_title', this.SRForm.value.event_request_title);
      }      
      if(this.SRForm.value.event_est_hours){
        data.append('est_hours', this.SRForm.value.event_est_hours);
      }     
      if(this.SRForm.value.event_appt_date){
        data.append('appt_date', moment(this.SRForm.value.event_appt_date).format('MM/DD/YYYY hh:mm A Z') );
      }    
      if(this.SRForm.value.event_scheduled_date){
        data.append('scheduled_date', moment(this.SRForm.value.event_scheduled_date).format('MM/DD/YYYY hh:mm A Z'));
      }     
      if(this.SRForm.value.event_start_date){
        data.append('start_date', moment(this.SRForm.value.event_start_date).format('MM/DD/YYYY hh:mm A Z') );
      }  
      if(this.SRForm.value.event_end_date){
        data.append('end_date', moment(this.SRForm.value.event_end_date).format('MM/DD/YYYY hh:mm A Z'));
      }
      if(this.param.sr_data.dialogMetaData.request_id){
        data.append('request_id', this.param.sr_data.dialogMetaData.request_id);
      }   
      if(this.param.sr_data.dialogMetaData.usr_sr_id){
        data.append('usr_sr_id', this.param.sr_data.dialogMetaData.usr_sr_id);
      }      
      data.append('type', this.eventType.toString());


      /*var data = "user_id=" + this.gs.getCurrentUser()["id"] + "&user_type="
      + this.gs.getCurrentUser()["user_type"] + "&request_title=" + this.SRForm.value.event_request_title+"&est_hours="
      + this.SRForm.value.event_est_hours
      + "&appt_date=" + moment(this.SRForm.value.event_appt_date).format('MM/DD/YYYY hh:mm A Z') 
      + "&scheduled_date=" + moment(this.SRForm.value.event_scheduled_date).format('MM/DD/YYYY hh:mm A Z')
      + "&start_date=" + moment(this.SRForm.value.event_start_date).format('MM/DD/YYYY hh:mm A Z') 
      + "&end_date=" + moment(this.SRForm.value.event_end_date).format('MM/DD/YYYY hh:mm A Z') 
      + "&request_id=" + this.param.sr_data.dialogMetaData.request_id 
      + "&usr_sr_id=" + this.param.sr_data.dialogMetaData.usr_sr_id 
      + "&type=" + this.eventType;*/

      //console.log(data);
      this.gs.formData(globalConstants.API_UPDATE_CALANDER_REQUEST_FIELD,data).subscribe((res:any) => {
        if (res.result == 1 ) { 
            this.dialogRef['response'] = res.data;
            this.gs.snackbar("success", res.message);          
            this.dialogRef.close(true);
        }else{
          this.gs.snackbar('error', res.message);
          this.dialogRef.close();
        }
      },(error:any)=>{});
   }
   
  }

}
