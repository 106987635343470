import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
import { IDatePickerConfig } from 'ng2-date-picker';

@Component({
  selector: 'app-add-srnote',
  templateUrl: './add-srnote.component.html',
  styleUrls: ['./add-srnote.component.scss']
})
export class AddSrnoteComponent implements OnInit {
  public notes: FormGroup;
  public noteImgDocs: any = [];
  public user: any = {};
  public employes = [];
  public isMultipleFile = true;
  public param:any=null;
  public FORMAT_SUPPORTED = globalConstants.FORMAT_SUPPORTED_FOR_IMAGE + ', pdf, ' + globalConstants.FORMAT_FOR_DOC;
  public noteDetail:any=null;
  public assignees:any=[];
  public noteDocPath=globalConstants.INTERNAL_NOTE_DOC;
  public imgPath=globalConstants.S3_BUCKET_URL;
  public minDate = new Date();
  public minDates=(this.minDate.getMonth()+1)+'/'+this.minDate.getDate()+'/'+this.minDate.getFullYear();
  public isEmployee=false;
  public datePickerConfig: IDatePickerConfig = {
    format: "MM/DD/YYYY hh:mm A",
    min: this.gs.getFullFormateDate(new Date(), 'G','C'),
    drops:'down',
    disableKeypress:true,
    unSelectOnClick:false
  };
  public isSubmitBtnDisabled = false;
  constructor(private ref:ChangeDetectorRef, public dialogRef: MatDialogRef<AddSrnoteComponent>,@Inject(MAT_DIALOG_DATA) public data: any,private formBuilder: FormBuilder, public gs: GlobalService) { 
  this.gs.diaLogObj(this.dialogRef);
  this.user = this.gs.getCurrentUser();
  this.param=this.data;
}

ngOnInit(){
  this.isMultipleFile = this.gs.isMultifileAllowed()
  this.notes = this.formBuilder.group({
    id:'',
    request_id:this.param.request_id,
    title: ['', Validators.required],
    description: ['',Validators.required],
    notify_date:[''],
    status: ['0'],
    assignees: [''],
    doc_files: [''],
    user_id: this.user.id,
    user_type: this.user.user_type
  });
  
  this.isEmployee=this.gs.isSubscribe('employee');
  if(this.isEmployee){
    this.getEmployee()
  }
  
}

addNotes() {
  let fcData = this.notes;
  for (let inner in this.notes.controls) {
    this.notes.get(inner).markAsTouched();
    this.notes.get(inner).updateValueAndValidity();
  }
  if (this.notes.value.status == 1) {
    this.gs.validateState(this.notes, ['assignees']);
  }
  let th = this;
  if (fcData.valid) {
    let fileNames = [];
    this.noteImgDocs.forEach(ele => {
      fileNames.push(ele.name);
    });
    let file = JSON.stringify(fileNames);
    this.notes.controls.doc_files.setValue(file);
    let data = this.gs.formsData(this.notes.value);
    this.isSubmitBtnDisabled = true;
    this.gs.formData(globalConstants.API_ADD_SERVICE_NOTE, data).subscribe((r) => {
      this.isSubmitBtnDisabled = false;
      if (r.result == 1) {
        this.dialogRef.close(1);
        this.gs.snackbar("success", r.message);
      } else {
        this.gs.snackbar("error", r.message);
      }
    }, (error) => { })
  } else {
    this.gs.snackbar("error", globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
  }
}
getFileIcons(name) {
  return this.gs.getFileIcon(name)
}
deleteImgDoc(i) {
  this.noteImgDocs.splice(i, 1);
}
changeListner(event) {
  let valid = false;
  let ele = <HTMLInputElement>document.getElementById('addInternalNotesDoc');
  if (!this.isMultipleFile && this.noteImgDocs.length > 0) {
    this.gs.snackbar("error", "Update your subscription to upload multiple files.");
    event.target.value = "";
    if (ele) {
      ele.value = '';
    }
    return false;
  }
  let cFiles = { src: '', type: 0, name: '' };
  if (event.target.files && event.target.files[0]) {
    let file = event.target.files[0];
    valid = this.gs.validateFiles('doc', event.target.files);
    if (valid) {
      var reader = new FileReader();
      let base64valuec;
      reader.onload = (e) => {
        base64valuec = e.target['result'];
        cFiles.src = base64valuec;
      }
      let FileType = this.gs.getFileIcon(file);
      cFiles.type = FileType;
      if (FileType == 1) {
        reader.readAsDataURL(event.target.files[0]);
      }
      let data = new FormData();
      let url = globalConstants.API_NEW_SERVICE_REQUEST_FILE;
      data.append('user_id', this.user.id);
      data.append('user_type', this.user.user_type);
      data.append('doc', file);

      this.gs.formData(url, data).subscribe((response) => {
        let resData = response;
        if (resData.result == 1) {
          event.target.value = "";
          cFiles.name = resData.data.name;
          this.noteImgDocs.push(cFiles);
        } else {
          this.gs.snackbar("error", resData.message);
        }
        if (ele) {
          ele.value = '';
        }
      });
    } else if (ele) {
      ele.value = '';
    }
  }
}
getEmployee() {
  let data = { user_id: this.user.id, user_type: this.user.user_type, opr: 1,pr:1 };
  this.gs.callAPI(globalConstants.API_GET_TECHNICIAN, data).subscribe((r:any) => {
    if (r.result == 1) {
      let th=this;
      this.employes = [];
      r.data.forEach((r)=>{
        let a={id: r.id, text: r.first_name+' ' +r.middle_name+' '+r.last_name };
        th.employes.push(a);
      });
      this.ref.markForCheck();

    }
  }, (error) => { })

}
selectedEmps(obj) {
  if (this.notes.value.status == 1) {
    this.gs.validateState(this.notes, ['assignees']);
  }
}

getSelected(e){
  let i=this.assignees.indexOf(e);
  if(i!=-1){
    this.assignees.splice(i,1);
  }
}
}
