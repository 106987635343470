<div class="customer-contacts-section">
    <div class="row margin-0">
        <div class="col-sm-12 padding-0">
            <form class="search-form">
                <div class="input-group">
                   
                    <input type="text" id="searchForm" [(ngModel)]="keyword" [ngModelOptions]="{standalone: true}"
                    placeholder="Search here..." class="form-control-search" (keyup.enter)="searchWithQuery()">
                    <div class="clear-icon" *ngIf="keyword.length > 0">
                        <i class="fa fa-times" (click)="HandleClearSearch()"></i>
                    </div>
                    <div class="input-group-text" (click)="searchWithQuery()">
                        <i class="fa fa-search"></i>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <mat-tab-group class="customer-contacts" animationDuration="0ms" (selectedTabChange)="tabChange($event)">
        <mat-tab label="Chats" class="tab-label">
            <div class="row">
                <div class="col-sm-12">
                    <p class="recent-chatText">Recent Chats</p>
                </div>
            </div>
            <div class="row recent-chats-section" *ngIf="conversations!=null">
                <div class="col-sm-12 col-md-12 col-xs-12 chat-item" *ngFor="let conversation of conversations">
                    <a class="flex" (click)="getCustomerInfoAndUpdateView(conversation.customer_id,conversation)">
                        <figure class="margin-right-10">
                            <div class="img-xs">
                                <p>{{getCustomerInitials(conversation)}}</p>
                            </div>
                        </figure>
                        <div class="contact-chatDetails-section flex">
                            <div class="contact-chatDetails">
                                <p class="text-bold margin-0">{{conversation.first_name}} {{conversation.last_name}}</p>
                                <p class="margin-0 meesage-desc">{{conversation.last_msg}}</p>
                            </div>
                            <div class="chat-endDetails">
                                <p class="margin-0 text-right">
                                    {{getTimeInString(conversation.converted_modify_date)}}
                                </p>
                                <div class="rounded-pill" *ngIf="getUnreadCount(conversation)" >
                                    {{ getUnreadCount(conversation) }}</div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-xs-12 col-md-12 col-sm-12 margin-top-5"><button class="btn btn-gray btn-block load-more-btn no-outline" [class.hidden]="!showLoadMoreConversationsButton" (click)="paginateConversations()">Load More...</button></div>

                <div class="col-xs-12 col-md-12 col-sm-12 no-result-found" *ngIf="!conversations.length">
                    <p>No Results Found</p>
                </div>

            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-xs-12"></div>
            </div>
        </mat-tab>
        <!-- 
        <mat-tab label="Calls" class="tab-label">
            <div class="row">
                <div class="col-sm-12">
                    <p class="recent-callText">Recent Calls</p>
                </div>
            </div>
            <div class="row call-section">
                <div class="col-sm-12 col-md-12 col-xs-12 chat-item">
                    <a class="flex">
                        <figure class="margin-right-10 margin-top-10">
                            <div class="img-wrap"><img src="assets/images/faces/face4.jpg" alt="user" class="img-xs"></div>
                            <div class="status"></div>
                        </figure>
                        <div class="call-Details-section flex">
                            <div class="call-Details">
                                <p class="text-bold margin-0">John mike</p>
                                <p class="margin-0">Today, 11:15 AM</p>
                            </div>
                            <div class="call-endDetails">
                                <a class="margin-right-15"><i class="fa fa-phone" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </a>
                </div>
            </div> 
            <div class="row call-section">
                <div class="col-sm-12 col-md-12 col-xs-12 chat-item">
                    <a class="flex">
                        <figure class="margin-right-10 margin-top-10">
                            <div class="img-wrap"><img src="assets/images/faces/face1.jpg" alt="user" class="img-xs"></div>
                            <div class="status online"></div>
                        </figure>
                        <div class="call-Details-section flex">
                            <div class="call-Details">
                                <p class="text-bold margin-0">Ammy Burton</p>
                                <p class="margin-0">Yesteray, 16:05 PM</p>
                            </div>
                            <div class="call-endDetails">
                                <a class="margin-right-15"><i class="fa fa-video-camera" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </a>
                </div>
            </div>  
        </mat-tab> -->

        <mat-tab label="Contacts" class="tab-label">
            <div class="row">
                <div class="col-sm-12">
                    <p class="contacts-Text">Contacts</p>
                </div>
            </div>
            <div class="row contact-section">
                <div class="col-sm-12 col-md-12 col-xs-12 chat-item" *ngFor="let customer of customerList">
                    <a class="flex"  (click)="updateSelectedContactInfo(customer)">
                        <figure class="margin-right-10">
                            <!-- <div class="img-wrap"><img src="assets/images/faces/face1.jpg" alt="user" class="img-xs">
                            </div> -->
                            <!-- <div class="status"></div> -->
                            <div class="img-xs">
                                <p>{{getCustomerInitials(customer)}}</p>
                            </div>
                        </figure>
                        <div class="contact-Details-section flex">
                            <div class="contact-Details">
                                <p class="text-bold margin-0">
                                    {{customer.first_name}} {{customer.last_name}}</p>
                                <p class="margin-0">{{customer.mobile_phone}}</p>
                            </div>
                            <div class="contact-endDetails">
                                <a><i class="fa fa-envelope" aria-hidden="true"></i></a>
                                <!-- <a class="margin-right-15"><i class="fa fa-phone" aria-hidden="true"></i></a> -->
                                <!-- <a><i class="fa fa-video-camera" aria-hidden="true"></i></a> -->
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-xs-12 col-md-12 col-sm-12 margin-top-5"><button class="btn btn-gray btn-block load-more-btn no-outline" [class.hidden]="!showLoadMoreCustomerButton"  (click)="paginateCustomer()">Load More...</button></div>
                
                <div class="col-xs-12 col-md-12 col-sm-12 no-result-found" *ngIf="!customerList.length">
                    <p>No Results Found</p>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>