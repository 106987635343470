<form (ngSubmit)="addNewField()" [formGroup]="customForm" method="POST" enctype="multipart/form-data"
  autocomplete="off">
  <div mat-dialog-title class="relative">
    {{ ( isEdit ? 'Edit' : 'Add New' )}} Field
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
      <mat-icon class="notranslate close-icon-lg" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="form-group col-sm-12 col-md-12">
      <label for="label">Label</label>
      <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
        <input matInput placeholder="Label*" autocomplete="Label" title="Label"
          formControlName="label" class="form-control input-lg custom-form-input" maxlength="200"/>
        <mat-error *ngIf="customForm.controls.label.hasError('required')">
          This field cannot be empty
        </mat-error>
      </mat-form-field>
    </div>
    
    
    <div class="form-group col-sm-12 col-md-12 uc-ngx-select">
      <label for="type">Field Type</label>
        <ngx-select class="display-full margin-bottom-20" [defaultValue]="fieldDefaultType" formControlname="type" [items]="fieldType" (select)="getOptionType($event)"
          placeholder="Select Type">
        </ngx-select>
        <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
          <mat-error *ngIf="customForm.controls.type.hasError('required')">
            This field cannot be empty
          </mat-error>
        </div>
    </div>
    <div class="form-group col-sm-12 col-md-12" *ngIf="customForm.value.type!='textbox' && customForm.value.type!='checkbox'">
      <h2 class="fontcolor-blue sub-heading more-fields-heading">                        
        Field Options                       
      <button type="button" mat-raised-button color="accent" class="font-bold pull-right"(click)="addOption()">Add Options</button>
      </h2>
    </div>
    <div class="form-group col-sm-12 col-md-12" *ngIf="customForm.value.type!='textbox' && customForm.value.type!='checkbox'">
      <div *ngFor="let opt of fieldOptions;let i=index">
          <mat-form-field class="car-input col-xs-10 col-sm-10 col-md-10 padding-0" floatPlaceholder="never" appearance="fill">
             <input matInput placeholder="Option-{{i+1}}" title="{{opt.value}}" [(ngModel)]="opt.value" [ngModelOptions]="{standalone: true}" class="form-control input-lg custom-form-input" maxlength="200" />
          </mat-form-field>
          <div class="action-btn-wrap col-xs-2 col-sm-2 col-md-2 padding-0">
              <span class="fa fa-trash text-red" (click)="removeOpt(i)" title="Delete" ></span>
          </div>
      </div>
    </div>
    <div class="form-group  col-sm-12 col-md-12">
      <label>Is Mandatory?</label>
      <div>
          <mat-radio-group class="example-radio-group" [value]="isMadatory" > 
          <mat-radio-button class="example-radio-button" (change)="isMadatChange($event)" value="1">Yes   </mat-radio-button>
          <mat-radio-button class="example-radio-button" (change)="isMadatChange($event)" value="0">No   </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="inquiry-action relative">
    <div class="text-right flexBtns">
      <button mat-raised-button color="accent" type="submit" class="font-bold">Submit</button>
      <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
    </div>
</mat-dialog-actions>
</form>