import { Component, OnInit, ChangeDetectorRef, Optional, OnDestroy} from '@angular/core';
import { GlobalService } from '../../../../environments/global.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { globalConstants } from '../../../../environments/globalConstants';
import { ImagetooldialogComponent } from '../../../imagetooldialog/imagetooldialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ImagedialogComponent } from '../../../assetsComponent/imagedialog/imagedialog.component';
import { EditSrnoteComponent } from '../edit-srnote/edit-srnote.component';
import {Subscription} from 'rxjs'; 
declare var jQuery:any;
declare var $ :any;

@Component({
  selector: 'app-view-srnote',
  templateUrl: './view-srnote.component.html',
  styleUrls: ['./view-srnote.component.scss']
})
export class ViewSrnoteComponent implements OnInit,OnDestroy {

  
  public noteId: any = null;
  public user:any={};
  public comment: FormGroup;
  public commentImgDocs=[];
  public isMultipleFile = true;
  public noteDetail:any=null;
  status=['Created','Assigned','Resolved'];
  public FORMAT_SUPPORTED = globalConstants.FORMAT_SUPPORTED_FOR_IMAGE + ', pdf, ' + globalConstants.FORMAT_FOR_DOC;
  public noteDocPath=globalConstants.SERVICE_NOTE_DOC;
  public commentDocPath=globalConstants.SERVICE_NOTE_COMMENT_DOC;
  public imgPath=globalConstants.S3_BUCKET_URL;
  public isInternalNotes=false;
  public preCommentImgDocs:any=[];
  public commentStatus=['-','Read','Work In Progress','Resolved','Deleted'];
  public isEmployee=false;
  public preCallOut=false;
  public preCommtStatus:any='0';
  public reRender=false;
  public expandAll=false;
  public firstExpand=true;
  public popupOrview=false;
  public assigneWorkStatus:any=[]
  public isSubmitBtnDisabled = false;
  public subscriptionDataForAPI: Subscription = new Subscription();

  constructor(public cdr: ChangeDetectorRef,@Optional() public dialogRef: MatDialogRef<ViewSrnoteComponent>, private formBuilder: FormBuilder,public gs: GlobalService, public router: Router, private activatedRoute: ActivatedRoute, public dialog: MatDialog) {

    this.user = this.gs.getCurrentUser();
    this.isInternalNotes = this.gs.isSubscribe('service_notes');
    if (this.user) {
      // this.gs.setMetaData("SHOPS", "INTERNAL_NOTES_DETAIL");
    } else {
      this.router.navigate(['shops']);
    }
    this.isEmployee=this.gs.isSubscribe('employee');

  }

  ngOnInit() {

    this.isMultipleFile = this.gs.isMultifileAllowed();
    this.getParams();
    this.initComment();
    this.getDetails();
  }
  getParams() {
    let id=this.gs.localGetter('service-note','')
    if(id && id!=''){
      this.noteId = id
      this.popupOrview=true
      localStorage.removeItem('service-note')
    }else{
      this.activatedRoute.params.subscribe((params: Params) => {
        this.noteId = params["id"];
      });
    }
  }
  getDetails(){
    let data = { user_id: this.user.id, user_type: this.user.user_type, note_id: this.noteId };
    this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_GET_SERVICE_NOTE, data).subscribe((r:any) => {
      if (r.result == 1) {
        this.noteDetail=r.data;
        this.checkPreStatus()
      }else{
        this.gs.snackbar("error", r.message);
      }
    }, (error) => { })
  }
  /** init comment form */
  initComment(){
    this.comment = this.formBuilder.group({
      comment: ['',Validators.required],
      status: [''],
      call_out: [0],
      note_comment_id:[''],
      note_id:this.noteId,
      doc_files: [''],
      user_id: this.user.id,
      user_type: this.user.user_type
    });
  }
  reInit(){
    this.resetComment()
    this.commentImgDocs=[];
    this.assigneWorkStatus=[];
    this.getDetails();
  }
  addComment() {

    let fcData = this.comment;
    for (let inner in this.comment.controls) {
      this.comment.get(inner).markAsTouched();
      this.comment.get(inner).updateValueAndValidity();
    }

    let th = this;
    if (fcData.valid) {
      let fileNames = [];
      this.commentImgDocs.forEach(ele => {
        fileNames.push(ele.name);
      });
      let file = JSON.stringify(fileNames);
      this.comment.controls.doc_files.setValue(file);
      if(this.preCommtStatus==3){
        this.comment.controls.status.setValue(3);
      }else if(this.preCommtStatus==2){
        if(this.comment.value.status == 3){
          this.comment.controls.status.setValue(3);
        }else{
          this.comment.controls.status.setValue(2);
        }
      }
      let data = this.gs.formsData(this.comment.value);
      let url=globalConstants.API_ADD_COMMENT_SERVICE_NOTE;
      if(this.comment.value.note_comment_id!=''){
        url=globalConstants.API_EDIT_COMMENT_SERVICE_NOTE;
      }else{
        this.reRender=true;
      }
      this.isSubmitBtnDisabled = true;
      this.gs.formData(url, data).subscribe((r) => {
        this.isSubmitBtnDisabled = false;
        if (r.result == 1) {
          this.reInit();
          this.gs.snackbar("success", r.message);
        } else {
          this.gs.snackbar("error", r.message);
        }
      }, (error) => { })
    } else {
      this.gs.snackbar("error", globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
  }
  getFileIcons(name) {
    return this.gs.getFileIcon(name)
  }
  deleteImgDoc(i:any, type:any,obj?:any) {
    if (type == 1) {
      this.commentImgDocs.splice(i, 1)
    } else if (type == 2) {
      let conf = "Are you sure you want to continue?";
      let data={ user_id: this.user.id, user_type: this.user.user_type, comment_doc_id: obj.id }
      this.gs.confirmDialog('deleteDoc', conf).subscribe((r) => {
        let res: any = r;
        if (res == 'deleteDoc') {
          this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_DETELE_SERVICE_NOTE_COMMENT_DOC, data).subscribe((res:any) => {
            if (res.result == '1') {
              this.preCommentImgDocs.splice(i, 1)
              this.gs.snackbar("success", res.message)
            } else {
              this.gs.snackbar("error", res.message)
            }
          });
        }
      });
    }
  }
  changeListner(event) {
    let valid = false;
    let ele = <HTMLInputElement>document.getElementById('addInternalNotesDoc');
    if (!this.isMultipleFile && this.commentImgDocs.length > 0) {
      this.gs.snackbar("error", "Update your subscription to upload multiple files.");
      event.target.value = "";
      if (ele) {
        ele.value = '';
      }
      return false;
    }
    let cFiles = { src: '', type: 0, name: '' };
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      valid = this.gs.validateFiles('doc', event.target.files);
      if (valid) {
        var reader = new FileReader();
        let base64valuec;
        reader.onload = (e) => {
          base64valuec = e.target['result'];
          cFiles.src = base64valuec;
        }
        let FileType = this.gs.getFileIcon(file);
        cFiles.type = FileType;
        if (FileType == 1) {
          reader.readAsDataURL(event.target.files[0]);
        }
        let data = new FormData();
        let url = globalConstants.API_NEW_SERVICE_REQUEST_FILE;
        data.append('user_id', this.user.id);
        data.append('user_type', this.user.user_type);
        data.append('doc', file);

        this.gs.formData(url, data).subscribe((response) => {
          let resData = response;
          if (resData.result == 1) {
            event.target.value = "";
            cFiles.name = resData.data.name;
            this.commentImgDocs.push(cFiles);
          } else {
            this.gs.snackbar("error", resData.message);
          }
          if (ele) {
            ele.value = '';
          }
        });
      } else if (ele) {
        ele.value = '';
      }
    }
  }
  /** get status */
  getStatus(i){
    return (typeof this.status[i]!='undefined' ? this.status[i]:'-')
  }
  getFormatedDate(rec,format){
    return this.gs.getFullFormateDate(rec, format, 'H')
  }
  getNltoBr(val){
    if(val && val.length>0){
      return val.toString().replace(/\r\n/g, "<br />").replace(/\n/g, "<br />");
    }
    return '-';
  }
  editComment(row) {
    this.comment.controls.comment.setValue(row.comment)
    this.comment.controls.status.setValue(row.status)
    this.comment.controls.note_comment_id.setValue(row.id)
    this.comment.controls.call_out.setValue(row.call_out)
    this.preCommentImgDocs = row.docs;
    this.goto('#commentForm');
    jQuery('#commentForm textarea').focus()
  }
  resetComment(){
    this.comment.controls.comment.reset()
    this.comment.controls.status.reset()
    this.comment.controls.doc_files.reset()
    this.comment.controls.note_comment_id.reset()
    this.comment.controls.note_comment_id.setValue('')
    this.comment.controls.call_out.setValue(0)
    for (let inner in this.comment.controls) {
      this.comment.get(inner).markAsUntouched()
      this.comment.get(inner).setErrors(null);
    }
    this.preCommentImgDocs=[]
    this.cdr.markForCheck();
  }
  /** get comment status */
  getCommStatus(t){
    return (typeof this.commentStatus[t]!="undefined" ? this.commentStatus[t]: '-');
  }
  /** set comment status */
  statusChange($e,type){
    if($e.checked){
      this.comment.controls.status.setValue(type);
    }else if(this.comment.value.note_comment_id){
      this.comment.controls.status.setValue(2);
    }else{
      this.comment.controls.status.setValue(0);
    }
  }
  callOutChange($e,type){
    if($e.checked){
      this.comment.controls.call_out.setValue(type);
    }else{
      this.comment.controls.call_out.setValue(0);
    }
  }
  /** checking previous action/status choosen by user */
  checkPreStatus(){
    let data=this.noteDetail;
    let th=this;
    this.assigneWorkStatus=[]
    if(data.comments.length > 0){
      th.preCommtStatus=0
      data.comments.forEach((c)=>{
        if(c.user_id==th.user.id){
          if(c.call_out==1){
            th.preCallOut=true;
          }
          if(c.status==2 && th.preCommtStatus < 2){
            th.preCommtStatus=2;
          }
          if(c.status==3 && th.preCommtStatus < 3){
            th.preCommtStatus=3;
          }
        }
        if(c.status!=1){
          let f=th.assigneWorkStatus.filter((s)=>{
            if(c.user_id==s.id){
              return true;
            }
            return false;
          })
          if(typeof f!='undefined' && f && f.length==0){
            th.assigneWorkStatus.push({id:c.user_id,status:c.status})
          }
        }
      })
    }
    this.slideToFirst();
  }
  goto(target){
    var body = $("html, body");
    body.animate({
      scrollTop: jQuery(target).offset().top - 90
    }, 200);
  }
  slideToFirst(){
    if(this.reRender){
      let th=this
      setTimeout(()=>{
        th.goto('#commentHead0');
        jQuery('.mat-raised-button:nth-child(2)').focus()
        this.firstExpand=true;
        th.reRender=false;
      },1000)
    }
  }
  /** accordion expand / collapse */
  expand(t){
    this.firstExpand=false;
    if(t){
      this.expandAll=false;
    }else{
      this.expandAll=true;
    }
  }
  /** remove assigne */
  removeAssigne(u, i) {
    // let data={,}
    let conf = "Are you sure you want to continue?";
    let data = { user_id: this.user.id, user_type: this.user.user_type, note_id: this.noteId ,assignee_id:u.user_id }
    this.gs.confirmDialog('removeUser', conf).subscribe((r) => {
      let res: any = r;
      if (res == 'removeUser') {
        this.subscriptionDataForAPI =  this.gs.callAPI(globalConstants.API_DETELE_SERVICE_NOTE_ASSIGNE, data).subscribe((r:any) => {
          if (r.result == 1) {
            this.reInit();
            this.gs.snackbar("success", r.message)
          } else {
            this.gs.snackbar("error", r.message)
          }
        }, (error) => { })
      }
    })
  }
  setAssigneColor(u){
    let status:any=0;
    this.assigneWorkStatus.filter((s)=>{
      if(u.user_id==s.id){
        status=s.status
        return true;
      }
      return false;
    })
    return status
  }
  isCommentEdit(com){
    let status=parseInt(com.status);
    if(status<=1){
      return false
    }else if(status >1 && status!=4 && this.user.id==com.user_id){
      return true
    }
    return false
  }
  /** only creator remove user */
  canRemove(u){
    let note=this.noteDetail
    if(this.user.id!=note.added_by){
      return false
    }else if(u.user_id!=this.user.id){
      return true
    }
    return false
  }
  /* view image dialog and download docs */
  imageToolDialog(i, doc, isComment=false): void {
    let img = [],imageCategory = 13,docBaseBath = this.imgPath + this.noteDocPath + 'doc/' + doc.filename;
    if(isComment){
      imageCategory = 14;
      docBaseBath = this.imgPath + this.commentDocPath + 'doc/' + doc.filename;
    }
    if (doc.doc_type == 1) {
      img.push(doc.filename)
      this.dialog.open(ImagedialogComponent, { panelClass: ['image-dialog'], data: { img: doc.filename, category: imageCategory } });
    } else if (doc.doc_type == 3 || doc.doc_type == 4) {
      this.gs.downloadAsset(docBaseBath, doc.filename);
    }
  }
  editNote(){
    this.dialogRef.close(0);
    let popup=this.dialog.open(EditSrnoteComponent, {
      panelClass: 'car-dialog-form',
      data: this.noteDetail
    });
    popup.afterClosed().subscribe(result => {
      if(result==2){
       this.redirectToView()
     }
   });
  }
  /** redirect to view service note detail */
  redirectToView(){
    this.gs.localGetterSetter('service-note', this.noteDetail.id,'');
    this.dialog.open(ViewSrnoteComponent, {
        panelClass: 'dialog-view-note',
    });
  }
//   isAllowed(action,module?:any){
//     if(typeof module!='undefined'){
//         return this.gs.getAcl(module,action);
//     }
//     return false;
//     // this.isAllowed('edits','service_notes')
//  }

ngOnDestroy(){
  this.subscriptionDataForAPI.unsubscribe();
}
}
