<!--
  @author Shashank Thakur
  @trackID CAR-1419, CAR-1496
  @updated 20-03-2020
-->
<div class="panel panel-primary service-request-list" id="mainCompotents">
    <div class="panel-heading padding-left-15 panel-with-flexbtns">
        <h3 class="panel-title">SERVICE REQUESTS LIST</h3>
        <div class="flex-btns">
            <button mat-mini-fab class="custom-title-btn export-btn" title="Export"  (click)="downloadSRListCSV()">
                <i class="fa fa-solid fa-file-export" aria-hidden="true"></i> <span class="hidden-xs"> Export</span> 
            </button>
            <button mat-mini-fab *ngIf="isShop" class="custom-title-btn appt-calendar" title="Appointment Calendar" (click)="newCalenderView()">
                <i class="fa fa-calendar fa-lg visible-xs"></i> <span class="hidden-xs"> Appt. Calendar</span>
            </button>
        </div>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <div class="display-full relative">
            <mat-expansion-panel hideToggle="false" [expanded]="openSearch" class="search-filter search-employee relative mat-elevation-z1 margin-bottom">
                <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px">
                    <strong><i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
                </mat-expansion-panel-header>
                <form id="advanceSearchForm" class="collapse in" aria-expanded="true">
                    <div class="panel-body padding-top-5 padding-bottom-5">
                        <div class="row">
                            <div class="col-sm-3">
                            <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                <mat-select class="form-control input-lg custom-form-input"
                                    options="searchByArray"
                                    [(ngModel)]="options.search_by"
                                    [ngModelOptions]="{standalone: true}"
                                    placeholder="Search By">
                                    <mat-option  *ngFor="let opt of searchByArray" [value]="opt.id">{{opt.text}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            </div>
                            <div class="col-sm-3">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <input  matInput placeholder="Search Keyword" title="Search Keyword" [(ngModel)]="options.search_keyword" [ngModelOptions]="{standalone: true}" class="form-control input-lg custom-form-input"/>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-3 details-input">
                                <mat-form-field class="car-input btn-block car-datePicker" floatPlaceholder="never" appearance="fill">
                                    <input matInput class="form-control input-lg custom-form-input" [(ngModel)]="schedule_service_option" [ngModelOptions]="{standalone: true}"  placeholder="Schedule Date" [matDatepicker]="filerPicker" onkeydown="return false;">
                                        <mat-datepicker-toggle matSuffix [for]="filerPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #filerPicker [touchUi]="setTouchViewValue()"></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-3 details-input">
                                <app-date-range-picker  class="date-time-section srList-date-range" (SelectTime)="onDateChange($event)"></app-date-range-picker>
                            </div>
                            <div class="col-sm-3 details-input">
                                <mat-form-field class="car-input btn-block car-datePicker"  floatPlaceholder="never" appearance="fill">
                                    <input matInput class="form-control input-lg custom-form-input"  [(ngModel)]="estimated_delivery_option" [ngModelOptions]="{standalone: true}" placeholder="Needed By" [matDatepicker]="filerPicker2" [ngModelOptions]="{standalone: true}" onkeydown="return false;">
                                        <mat-datepicker-toggle matSuffix [for]="filerPicker2" ></mat-datepicker-toggle>
                                    <mat-datepicker #filerPicker2 [touchUi]="setTouchViewValue()"></mat-datepicker>
                                </mat-form-field>
                            </div>

                            <div class="col-sm-3 uc-ngx-select">
                                <ngx-select class="display-full margin-bottom-20"
                                    [items]="searchByStatus"
                                    [noAutoComplete]="true"
                                    [(ngModel)]="options.status"
                                    [ngModelOptions]="{standalone: true}"
                                    placeholder="Select Status">
                                </ngx-select>
                            </div>
                            <div class="col-sm-3 uc-ngx-select" >
                                <ngx-select class="display-full margin-bottom-20"
                                    [items]="jobFilter"
                                    [noAutoComplete]="true"
                                    [(ngModel)]="options.jobStatus"
                                    optionTextField="name"
                                    [ngModelOptions]="{standalone: true}"
                                    placeholder="Jobs Status">
                                </ngx-select>
                            </div>
                            <div class="col-sm-3 uc-ngx-select" >
                                <mat-select class="form-control input-lg custom-form-input display-full margin-bottom-20" placeholder="Select Employee"
                                    [(ngModel)]="options.employee_id" [ngModelOptions]="{standalone: true}" (valueChange)="handleTechnician($event)">
                                    <mat-option *ngFor="let data of techniciansList;index as i" [value]="data.id">{{data.first_name}} {{data.last_name}}</mat-option>
                                </mat-select>
                            </div>
                            <div class="col-sm-3" >
                                <mat-checkbox class="v-checkbox" [ngModelOptions]="{standalone: true}" [indeterminate]="onTheLot===null" (change)="updateOnTheLotStatus($event)" [(ngModel)]="onTheLot" >On The Lot <span  class="fa fa-question-circle" [tooltip]="myTooltip"></span>
                                    <tooltip-content #myTooltip class="image-tooltip show-inline-info">
                                        <span> <i class="fa fa-minus-square"></i> Not On The Lot</span>&nbsp;<span><i class="fa fa-check-square"></i> On The Lot</span>
                                    </tooltip-content>
                                </mat-checkbox>
                            </div>
                            <div class="col-sm-3">
                                <mat-checkbox class="v-checkbox" [ngModelOptions]="{standalone: true}" [indeterminate]="waitForPart===null" (change)="waitingForPart($event)" [(ngModel)]="waitForPart" >Waiting For Parts <span class="fa fa-question-circle" [tooltip]="myTooltip1"></span>
                                    <tooltip-content #myTooltip1 class="image-tooltip show-inline-info">
                                        <span><i class="fa fa-minus-square"></i> Not Waiting For Parts</span>&nbsp;<span><i class="fa fa-check-square"></i> Waiting For Parts</span>
                                    </tooltip-content>
                                </mat-checkbox>    
                            </div>
                            <div class="col-sm-3" *ngIf="isAllowed('edits','account_verification')">
                                <mat-checkbox class="v-checkbox" [ngModelOptions]="{standalone: true}" [indeterminate]="accountVerifyDeterminate===null" (change)="accountVerify()" [(ngModel)]="accountVerifyDeterminate">Accountant Verified <span class="fa fa-question-circle" [tooltip]="myTooltip2"></span></mat-checkbox>
                                    <tooltip-content #myTooltip2 class="image-tooltip show-inline-info">
                                        <span> <i class="fa fa-minus-square"></i> Verification Pending</span>&nbsp;<span><i class="fa fa-check-square"></i> Verification Completed</span>
                                    </tooltip-content>
                            </div>
                            <div class="col-sm-3" *ngIf="isServiceNote && options.search_by=='10'">
                                <mat-checkbox class="v-checkbox" (change)="serviceNoteUnreadStatus($event)" [checked]="options.serv_notes_unread=='1'">Unread Notes
                                    <span class="fa fa-question-circle" [tooltip]="myTooltip3"></span><tooltip-content #myTooltip3 class="image-tooltip show-inline-info">Unread Service Requests Notes</tooltip-content>
                                </mat-checkbox>                            
                            </div>
                            <div class="col-sm-3" >
                                <mat-checkbox class="v-checkbox" (change)="handleUnassignedSRStatus($event)" [checked]="options.unassigned_sr=='1'">Unassigned SR
                                    <span class="fa fa-question-circle" [tooltip]="myTooltip4"></span><tooltip-content #myTooltip4 class="image-tooltip show-inline-info">Unassigned Service Requests </tooltip-content>
                                </mat-checkbox>                            
                            </div>
                        </div>
                    </div>
                    <mat-action-row class="search-filter-footer">
                      <button mat-raised-button color="accent" type="submit" title="Search" (click)="search($event)">
                        Search
                      </button>
                    </mat-action-row>
                </form>
            </mat-expansion-panel>
            <button mat-button type="button" #clearBtn class="clear-btn" title="Clear Filter" [class.active-search]="isAnyOptionActive(clearBtn)" (click)="clearSearch()">
              Clear Filter
            </button>
        </div>
        <div class="display-full relative" *ngIf="isServiceNote && options.search_by=='10'">
           <span class="help-block text-red mb-0">
            Note: Please contact shop administrator, if service request(s) are not visible.
           </span>
        </div>
        <div class="color-info top flex">
            <button class="toggle-btn margin-bottom-10" (click)="toggleLegends()"><span>SR Legends</span> &nbsp;<i [class.fa-arrow-right]="isLegendHidden" [class.fa-times]="!isLegendHidden" class="fa fa-solid"></i></button>
            <ul class="sr-legends-list" [class.show]="!isLegendHidden">
                <li style="background-color:#fd82ad5e;" (click)="handleLegends('11')" title="Appointments">Appointments</li>
                <li style="background-color:#faf2cc;" (click)="handleLegends('1')" title="Pending">Pending</li>
                <li style="background-color:#c4e3f3;" (click)="handleLegends('2')" title="Processing">Processing</li>
                <li style="background-color:#e1e114;" (click)="handleLegends('3')" title="Approval Awaited">Approval Awaited</li>
                <li style="background-color:#d0e9c6;" (click)="handleLegends('7')" title="Approval Completed">Approval Completed</li>
                <li style="background-color:#b4eab4;" (click)="handleLegends('12')" title="Work In Progress">Work In Progress</li>
                <li style="background-color:#ada;" (click)="handleLegends('6')" title="Completed">Completed</li>
                <li style="background-color:#52bab3;" (click)="handleLegends('8')" title="Completed And Paid">Completed And Paid</li>
                <li style="background-color:#a9a9a9;" (click)="handleLegends('-1')" title="Deleted">Deleted</li>
                <li style="background-color:#f5f5f5;border:1px solid;padding-bottom:4px;" (click)="handleLegends('9')" title="Unread">Unread</li>
            </ul>
        </div>
        <div class="help-text-wrap relative">
           <div [ngClass]="{'help-block mt-0':true, 'text-red': !isCompletedIncluded, 'text-green':isCompletedIncluded}" (click)="toggleCompletedRequests($event)">
                <span class="click-note">Note:</span>
                <span *ngIf="!isCompletedIncluded" class="click-note">This list EXCLUDES Completed service requests.</span>
                <span *ngIf="isCompletedIncluded" class="click-note">This list now INCLUDES Completed Service Requests.</span>
            </div>
            <div class="padding-0 column-view-icon">
                <div class="action-icon-color click-note" (mouseenter)="onHover(true)" (mouseleave)="onHover(false)">
                    <img class="workflow-icon width-22" [matMenuTriggerFor]="workflowMenu" [src]="isWorkflowIconHovered ? '../assets/img/workflow-accent.svg' : '../assets/img/workflow-black.svg'" title="Job Board">
                    <mat-menu #workflowMenu="matMenu" class="mat-dropdown-menu board-menu">
                        <button mat-menu-item (click)="navigateToJobBoard('/shops/boards/job-board')">Job Board</button>
                        <button mat-menu-item (click)="navigateToJobBoard('/shops/boards/my-workload')">My Job Queue</button>
                        <button mat-menu-item (click)="navigateToJobBoard('/shops/boards/on-the-lot')">On The Lot</button>
                    </mat-menu>
                  </div>

                <i class="fa fa-fw fa-gears pull-right gear-icon" (click)="customCol($event)" [matMenuTriggerFor]="menucc" title="Show/Hide Columns">&nbsp;</i>
                <mat-menu #menucc="matMenu" (close)="customColumnsMenuIsClosed()" class="msgnotify uc-column-setting" [overlapTrigger]="false" xPosition="before">
                    <ul class="custom-column" >
                        <li *ngFor="let cc of additionalColumn" (click)="$event.stopPropagation()">
                            <mat-checkbox class="v-checkbox" (change)="addColumn($event,cc)" [checked]="cc.default">{{cc.label}}
                            </mat-checkbox>
                        </li>
                    </ul>
                </mat-menu>
            </div>
            
        </div>
        <div class="car-datatable uc-datatable mat-elevation-z1">
            <div class="text-right hidden">
                <mat-form-field floatPlaceholder="never" appearance="fill">
                    <input matInput #filter placeholder="Filter users">
                </mat-form-field>
            </div>
            <div class="example-sidenav-content">
                <button type="button" class="hide" mat-button (click)="drawer.toggle()">
                    Toggle sidenav
                </button>
            </div>
            <div class="popup-sidebar-wrapper">
                <mat-drawer-container class="side-panel-wrappers ticket-assignment" autosize end>
                    <mat-drawer #drawer class="ticket-sidenav" mode="over" position="end">
                        <h4>Ticket Assignment</h4>
                        <div class="cross-btn">
                            <button type="button" (click)="drawer.toggle()">
                                <div class="desktop-screen">
                                     <!-- <img src="assets/img/sidebar-icon.png"> -->
                                    x
                                </div>
                            </button>
                        </div>
                        <div class="assignment-block">
                            <div class="vehicle-detail">
                                <div class="row bg-color">
                                    <div class="col-sm-12 margin-bottom-5">
                                        <strong>Service Request Information</strong>
                                    </div>
                                    <div class="col-sm-12">
                                        <label>SR ID:</label> 
                                        CAR2022020522925
                                    </div>
                                    <div class="col-sm-12">
                                        <label>Appointment Date:</label>
                                        Feb 7 2022 05:25:00PM
                                    </div>
                                </div>
                                <div class="break row"></div>
                                <div class="row bg-color">
                                    <div class="col-sm-12 margin-bottom-5">
                                        <strong>Vehicle Information</strong>
                                    </div>
                                    <div class="col-sm-12">
                                        <label>Year/Make/Model:</label>
                                        2016/Fiat/500X Base SUV
                                    </div>
                                    <div class="col-sm-12">
                                        <label>License Plate:</label> 
                                        2016/Fiat/500X Base SUV 
                                    </div>
                                    <div class="col-sm-12">
                                        <label>VIN: </label>
                                        FYFYFYGG2344544X
                                    </div>
                                </div>
                                <div class="break row"></div>
                                <div class="row bg-color">
                                    <div class="col-sm-12">
                                        <strong>Estimated Service Time:</strong> 
                                        0.5 hour
                                    </div>
                                </div>
                              </div>
                            <div class="select-totech uc-ngx-select margin-top-15">
                                <ngx-select class="display-full margin-bottom-15"
                                    placeholder="Select Service Request">
                                </ngx-select>
                            </div>
                            <div class="display-full ">
                                <div class="assign-label">
                                    <strong class="mat-radio-label-content f-14">Assign To:</strong>
                                </div>
                                <ul class="label-font">
                                    <li>
                                        <label class="material-radio text-left margin-bottom-5">
                                            <div class="mat-radio-container1">
                                                <input type="radio" value="1"  name="assign_tomyself"/>
                                            </div>
                                            <span class="radio-box-text">Yourself</span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="material-radio text-left margin-bottom-5">
                                            <div class="mat-radio-container1">
                                                <input type="radio" value="2" name="assign_tomyself"/>
                                            </div>
                                            <span class="radio-box-text">Technician</span>
                                        </label>
                                    </li>
                                    <li>    
                                        <label class="material-radio text-left margin-bottom-5">
                                            <div class="mat-radio-container1">
                                                <input type="radio" value="3" name="assign_tomyself"/>
                                            </div>
                                            <span class="radio-box-text">Employee</span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div class="select-totech uc-ngx-select ">
                                <ngx-select class="display-full margin-bottom-15" 
                                    placeholder="Select Technician or Employee">
                                </ngx-select>
                            </div>
                            <div class="display-full">
                                <div class="col-md-3 date-block padding-left-0"><strong>Start Date:</strong></div>
                                <div class="col-md-5 px-0">
                                    <div class="vlio-datepicker relative">
                                        <dp-date-picker mode="day"  theme="dp-material dp-main" class="btn-block"
                                            placeholder="Select Start Date" autocomplete="off"
                                            #dayPicker></dp-date-picker>
                                        <button type="button" mat-icon-button>
                                            <!-- <mat-icon>date_range</mat-icon> -->
                                            <img src="assets/img/calendar.png">
                                        </button>
                                    </div>
                                </div>
                                <div class="col-md-4 date-select-box px-0">
                                    <ngx-select class="display-full margin-bottom-20"  placeholder="Start Time"
                                    [items]="sTimeSlots" [noAutoComplete]="true">
                                   </ngx-select>
                                </div>
                            </div>
                            <div class="display-full ">
                                <div class="col-md-3 date-block padding-left-0"><strong>End Date:</strong></div>
                                <div class="col-md-5 px-0">
                                    <div class="vlio-datepicker relative">
                                        <dp-date-picker mode="day"  theme="dp-material dp-main" class="btn-block"
                                            placeholder="Select End Date" autocomplete="off"
                                            #dayPicker></dp-date-picker>
                                        <button type="button" mat-icon-button>
                                            <!-- <mat-icon>date_range</mat-icon> -->
                                            <img src="assets/img/calendar.png">
                                        </button>
                                    </div>
                                </div>
                                <div class="col-md-4 date-select-box px-0">
                                    <ngx-select class="display-full margin-bottom-10"  placeholder="End Time"
                                    [items]="eTimeSlots" [noAutoComplete]="true">
                                   </ngx-select>
                                </div>
                            </div>
                            <div class="name-suggestion margin-bottom-15">
                                <div class="row">
                                    <div class="col-sm-6 col-xs-8">
                                        <label class="f-14">Suggestion For Name:</label>
                                    </div>
                                    <div class="col-sm-6 col-xs-4" style="text-align: right;">
                                        <img src="assets/img/user-icon.svg">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-8 col-xs-7">
                                        <div class="time-box">
                                            <span>Slot- 1 Aug 2022 10:00 AM - 01:00 PM</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-xs-5">
                                        <div class="select-btn">
                                            <p><button type="button" class="btn list-action-btn mat-button" title="Select">Select</button></p>
                                        </div>  
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-8 col-xs-7">
                                        <div class="time-box">
                                            <span>Slot- 1 Aug 2022 10:00 AM - 01:00 PM</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-xs-5">
                                        <div class="select-btn">
                                            <p><button type="button" class="btn list-action-btn mat-button" title="Select">Select</button></p>
                                        </div>  
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-8 col-xs-7">
                                        <div class="time-box">
                                            <span>Slot- 1 Aug 2022 10:00 AM - 01:00 PM</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-xs-5">
                                        <div class="select-btn">
                                            <p><button type="button" class="btn list-action-btn mat-button" title="Select">Select</button></p>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <p class="text-red"><i class="fa fa-exclamation-triangle margin-right-5" aria-hidden="true"></i>Technician is fully occupied till next 3/5 days</p>
                                </div>
                            </div>
                            <div class="display-full overwrite-time">              
                                <mat-checkbox>Do not overwrite the timing, while auto pushing the job queue</mat-checkbox>               
                            </div>
                        </div>
                        <div class="footer">
                            <div class="row">
                                <div class="col-sm-4 col-xs-12"></div>
                                <div class="col-sm-8 col-xs-12">
                                    <div class="btn-block">
                                        <ul>
                                            <li>
                                                <button type="button" class="btn list-action-btn mat-button">Submit</button>
                                            </li>
                                            <li>
                                                <button type="button" class="btn skip-btn">Skip</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-drawer>
                </mat-drawer-container>
            </div>
            <div class="table-responsive relative"  [class.uc-col-auto]="displayedColumns.length < '6'">
                <mat-table #table [dataSource]="dataSource" matSort (matSortChange)="changeSortParams($event)" class="uc-table-responsive service-list datatable-loader-bg">

                    <!-- Color Column -->
                    <ng-container matColumnDef="request_id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header  data-role="id"> Req. Id </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="id" data-val="Req. Id" style="max-width: 160px;">
                            {{row.request_id}}
                            <ng-container *ngIf="row.emp_work_timers && row.emp_work_timers.length > 0">
                                <span *ngFor="let empTimer of row.emp_work_timers;let i = index;">
                                    <img [src]="gc.TIMER_ICON" [tooltip]="myTooltip" tooltipPlacement="right" style="width:20px;">
                                    <tooltip-content #myTooltip placement="right" class="">
                                        <div>{{empTimer.emp_name}}</div>
                                        <cd-timer class="workTimer" format="intelli" [startTime]="empTimer.seconds_worked"></cd-timer>
                                    </tooltip-content>
                                </span>
                            </ng-container>
                            <span *ngIf="row.work_in_progress == '1'"><img [src]="gc.TIMER_ICON" [tooltip]="myTooltip" tooltipPlacement="right" style="width:20px;"></span>
                            <tooltip-content #myTooltip placement="right" class="">
                                <cd-timer class="workTimer" format="intelli" [startTime]="row.seconds_worked"></cd-timer>
                            </tooltip-content>
                        </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="title">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto" > Title </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto"  data-val="Title"> {{row.title ? row.title: "N/A"}} 
                           <br> <span *ngIf="row.selfCheckInStatus == '1'" class="selfCheckin-badge">Self Checkin</span>
                        </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="customer_name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Account </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Account"><button class="btn btn-xs btn-link text-red ng-tns-c13-22 hover-button" *ngIf="isAllowed('views','view_customer_info')" (click)="detailTab(row,2)"  title="Vehicle Details" type="button"><i class="fa fa-info-circle fa-lg"></i></button> {{( row.business_name ? row.business_name : row.customer_name ) }}  </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="business_name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Account </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Account">
                            <button class="btn btn-xs btn-link text-red ng-tns-c13-22 hover-button" *ngIf="isAllowed('views','view_customer_info')" (click)="detailTab(row,2)"  title="Vehicle Details" type="button"><i class="fa fa-info-circle fa-lg"></i></button> {{( row.business_name ? row.business_name : row.customer_name ) }} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="unit_number">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Unit Number </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Unit Number"> {{row.unit_number}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="on_the_lot">
                        <mat-header-cell *matHeaderCellDef mat-sort-header class="header-text text-long"  data-role="auto"> On The Lot </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="On The Lot"> 
                            <mat-checkbox class="v-checkbox"  (change)="setOnTheLotStatus($event,row)" [checked]="row.on_the_lot" ></mat-checkbox>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="license_plate">
                        <mat-header-cell *matHeaderCellDef mat-sort-header class="header-text"  data-role="auto"> License Plate </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="License Plate"> 
                            {{ row.license_plate || 'N/A'}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="vin">
                        <mat-header-cell *matHeaderCellDef mat-sort-header  data-role="auto"> VIN </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="VIN"> 
                            {{ row.vin || 'N/A'}}
                        </mat-cell>
                    </ng-container>
                    <!-- Color Column -->
                    <ng-container matColumnDef="vehicle">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Vehicle </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Vehicle">
                            <button class="btn btn-xs btn-link text-red ng-tns-c13-22 hover-button"  (click)="detailTab(row,1)"  title="Vehicle Details" type="button"><i class="fa fa-info-circle fa-lg"></i></button>
                             {{(row.vehicle ? row.vehicle :"N/A")}} </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="appointment_date">
                        <mat-header-cell *matHeaderCellDef mat-sort-header class="header-text" data-role="date"> Requested Date </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="date" data-val="App. Date">{{row.requested_delivery_date}}</mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="service_date">
                        <mat-header-cell *matHeaderCellDef mat-sort-header class="header-text" data-role="date"> Schedule Date </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="date" data-val="Service Date">{{row.service_date}} </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="estimated_delivery_date">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="date"> Needed By </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="date" data-val="Est. Delivery Date">{{row.estimated_delivery_date}} </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="status"> Status </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="status" data-val="Status">
                            <span *ngIf="row.check_action_status != -1">
                                {{row.appt== '1' && (row.check_action_status=='1' || row.check_action_status=='2') ? 'Appt.' : row.status}} 
                            </span>
                            <span *ngIf="row.check_action_status == -1">
                                Deleted
                            </span>
                        </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef  data-role="button"> Actions </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="button">
                            <button mat-button class="list-action-btn" color="accent" [matMenuTriggerFor]="menu" disableRipple >
                                Action<mat-icon>arrow_drop_down</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu" class="car-icon-menu disable-no-bg" [overlapTrigger]="false">
                                <!--<button type="button" disabled="true" [disabled]="!(row.status == 1 || row.status == 10)" mat-menu-item (click)="editRequest(row.id,row.customer_id)"> <mat-icon>mode_edit</mat-icon>Edit</button>-->
                                <button class="mat-menu-item" disableRipple type="button" *ngIf="row.work_in_progress == 1" (click)="pauseEmpTimer(row.id)" title="Pause Timer"><i class="fa fa-pause"></i>Pause Timer</button>
                                <button class="mat-menu-item" disableRipple type="button" *ngIf="isAllowed('edits') && (row.check_action_status != 10)" [disabled]="(row.check_action_status >= 6)" (click)="editRequest(row.id,row.customer_id)" title="Edit Request"> <mat-icon>mode_edit</mat-icon>Edit</button>
                                <button class="mat-menu-item" disableRipple type="button" *ngIf="isAllowed('views')" (click)="viewServiceRequest(row.id,row)" title="View Request"> <mat-icon>remove_red_eye</mat-icon>View</button>
                                <!--<button type="button" disabled="true" [disabled]="(row.status == 2 || row.status == 10 || row.status == 6 ||row.status == 8)" mat-menu-item (click)="underProcess(row.id)"> <i class="fa farfa-square-check"></i>Under Process</button>-->
                                <button class="mat-menu-item" disableRipple type="button" *ngIf="isAllowed('addNew','mark_request_under_pr')" [disabled]="(row.check_action_status != '1' )" (click)="underProcess(row.id,row.present_mileage,row.distance_covered,row)" title="Under Process"> <i class="fa fa-regular fa-square-check"></i>Under Process</button>
                                <button class="mat-menu-item" disableRipple type="button" *ngIf="isAllowed('addNew','fill_recommendation') && (row.check_action_status != 10)" [disabled]="(row.check_action_status >= 6)" (click)="fillRecommendation(row.id,row.present_mileage,row.distance_covered,row)" title="Fill Recommendation"> <i class="fa fa-gears"></i>Fill Recommendation</button>
                                <button class="mat-menu-item" disableRipple type="button"  *ngIf="isAllowed('views','view_service_history') && (row.check_action_status != 10)" (click)="openServiceHistoryPopup(row)" title="View History"> 
                                    <!-- <mat-icon>history</mat-icon> -->
                                    <img [src]="gc.SERVICE_HISTORY_ICON" style="width:16px; margin-right:5px;" />
                                    View History
                                </button>
                               <!--  <span *ngIf="isAllowed('addNew','mark_as_paid') && (row.check_action_status != 10)">
                                <button class="mat-menu-item" disableRipple type="button" *ngIf="row.check_action_status == 6 && menuEnable(row)" (click)="markAsPaid(row.id)"> <i class="fa fa-check-circle"></i>Mark As Paid</button>
                                </span> -->
                                <span *ngIf="isAllowed('views','invoices') && (row.check_action_status != 10)">
                                <button class="mat-menu-item" disableRipple type="button" *ngIf="row.check_action_status == 6 || row.check_action_status == 8" (click)="generateInvoice(row.id,row.grand_total,row.tax_rate,row.is_tax_included,row.request_id)" title="Print Invoice"> <i class="fa fa-print"></i>Print Invoice</button>
                                </span>

                                <!--<button type="button" disabled="true" [disabled]="(row.status == 1 || row.status == 10)" mat-menu-item (click)="paymentDialog(row.id)"> <i class="fa fa-usd"></i> Payments</button>-->
                                <button class="mat-menu-item" disableRipple *ngIf="isAllowed('views','payment') && (row.check_action_status != 10)" type="button" (click)="paymentDialog(row)" title="Payments"> <i class="fa fa-usd"></i> Payments</button>
                                <span *ngIf="isAllowed('views','feedback')">
                                <button class="mat-menu-item" disableRipple *ngIf="(row.feedback_read_status == 0 || row.feedback_read_status == 1)  && (row.check_action_status != 10)" type="button" (click)="showFeedbackView(row.id)" title="Ratings"> <i class="fa fa-commenting fa-fw"></i> Ratings</button>
                                </span>
                                <button class="mat-menu-item" disableRipple type="button" *ngIf="isVisible(row) && skipTicketAssign && isEmployee && (row.check_action_status != 10) && menuEnable(row)" (click)="ticketAssignment(row.id,row)" title="Ticket Assignment"> <i class="fa fa-ticket"></i>Ticket Assignment </button>
                                <button class="mat-menu-item" disableRipple type="button" *ngIf="isViewAssignee(row) && skipTicketAssign && isEmployee && (row.check_action_status != 10) && menuEnable(row)" (click)="viewAssignee(row.id)" title="View Assignee"> <i class="fa fa-ticket"></i>View Assignee </button>
                                <button class="mat-menu-item" disableRipple type="button" *ngIf="isViewAssignee(row) && (row.check_action_status != 10) && menuEnable(row)" (click)="viewLogs(row.id)" title="View Activity Logs"> <i class="fa fa-eye"></i>View Activity Logs </button>
                                <button class="mat-menu-item" disableRipple type="button" *ngIf="isServiceNote" (click)="srnoteList(row)" title="View Notes"> <i class="fa fa-solid fa-pen-to-square"></i>View Notes </button>
                                <button class="mat-menu-item" disableRipple type="button" *ngIf="isAllowed('views','complaints')" (click)="addMessage(row)" title="Add {{ currentCustomer.profile_type == '2' ? 'Contact' : 'Customer' }} Chat"> <i class="fa fa-plus"></i>Add {{ currentCustomer.profile_type == '2' ? 'Contact' : 'Customer' }} Chat </button>
                                <!--
                                    @author Shashank Thakur
                                    @trackID CAR-1492, CAR-1420
                                    @updated 19-03-2020
                                -->
                                <button class="mat-menu-item" disableRipple type="button" *ngIf="isAllowed('edits', 'reopen_service_ticket') && (row.check_action_status == 6 || row.check_action_status == 8)" [disabled]="false" (click)="reOpen(row)" title="Reopen Request"> <i class="fa fa-refresh"></i>Reopen</button>
                                <button class="mat-menu-item" disableRipple type="button" *ngIf="isAllowed('deletes', 'service_request')" [disabled]="false" (click)="softDelete(row)" title="Delete Request"> <i class="fa fa-trash"></i>Delete</button>
                            </mat-menu>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" class="mat-row {{getRowStatusColor(row.read_status== '0' ? 0 : (row.appt== '1' && (row.check_action_status=='1' || row.check_action_status=='2')) ? 4 : row.check_action_status)}}"></mat-row>
                </mat-table>
                <div class="padding-15 text-center" [style.display]="getTotalRecords() < 1 ? '' : 'none'"> No Records Available </div>
        </div>
        <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
             <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="options.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event)" >
            </mat-paginator>

            <div class="mat-paginator table-paging">
                <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
                <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                        <mat-icon>navigate_before</mat-icon>
                </button>
                <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                        <mat-icon>navigate_next</mat-icon>
                </button>
            </div>
        </div>
        </div>
        <div class="grid-view margin-top-15 hide">
            <div class="desktop-view-1">
                <div class="row">
                    <div class="bg-color col-md-4">
                        <div class="main-box">
                            <div class="row">
                                <div class="col-md-10 col-xs-10">
                                    <h4 class="px-3 f-16 f-weight">All Open <span class="f-10 f-weight">(Appointment/Pending)</span></h4>
                                </div>
                                <div class="col-md-2 col-xs-2">
                                    <div class="image-block">
                                        <img src="assets/img/listshop/notify.png">
                                    </div>
                                    <div class="notify-number">
                                        2
                                    </div>
                                </div>
                            </div>
                            <div class="box-wrapper">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <div class="car-image">
                                            <img src="assets/img/car-body.png" width="40px">
                                        </div>
                                    </div>
                                    <div class="col-xs-7">
                                        <a href="#" class="text-red f-weight f-14">CAR2022042738939</a>
                                    </div>
                                    <div class="col-xs-2">
                                        <button class="mat-icon-button"  mat-icon-button="" style="margin-top: -10px;">
                                            <span class="mat-button-wrapper">
                                                <mat-icon class="mat-icon material-icons text-red">more_vert</mat-icon>
                                            </span>
                                            <div class="mat-button-ripple mat-ripple mat-button-ripple-round" matripple=""></div>
                                            <div class="mat-button-focus-overlay"></div>
                                        </button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-xs-6">
                                        <div class="margin-bottom-5 margin-top-5">
                                            <span class="" title="Cust. Name"><i class="fa fa-calendar"></i></span>
                                            <span class="f-10">08/08/2022  10:30 AM</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-xs-6">
                                        <div class="margin-bottom-5 margin-top-5">
                                            <span class="" title="Cust. Name"><i class="fa fa-clock"></i></span>
                                            <span class="f-10">01 Hr 30 Mins</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-xs-12">
                                        <div class="margin-bottom-5 margin-top-5">
                                            <span class="" title="Cust. Name"><i class="fa fa-car"></i></span>
                                            <span class="f-10">Make/Year/Model</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-xs-12">
                                        <div class="margin-bottom-5 margin-top-5">
                                            <span class="" title="Cust. Name"><i class="fa fa-user"></i></span>
                                            <span class="f-10">Customer Name - Mobile</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-xs-6">
                                        <div class="notification-text">
                                            <div class="t1-class light-pink-color">
                                               <span class="text-icon">T1</span>
                                            </div>
                                            <div class="t2-class pink-color">
                                                <span class="text-icon">T2</span>
                                                <div class="check-icon">
                                                    <i class="fa fa-check" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div class="t3-class dark-pink-color">
                                                <span class="text-icon">T3</span>
                                                <div class="check-icon">
                                                    <i class="fa far fa-clock" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-xs-6 text-right">
                                        <div class="msg-box">
                                            <a href="#"><img src="assets/img/listshop/msg.png"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   <!-- #2 column -->
                   <div class="bg-color col-md-4">
                        <div class="main-box">
                            <div class="row">
                                <div class="col-md-10 col-xs-10">
                                    <h4 class="px-3 f-16 f-weight">All Open <span class="f-10 f-weight">(Appointment/Pending)</span></h4>
                                </div>
                                <div class="col-md-2 col-xs-2">
                                    <div class="image-block">
                                        <img src="assets/img/listshop/notify.png">
                                    </div>
                                    <div class="notify-number">
                                        2
                                    </div>
                                </div>
                            </div>
                            <div class="box-wrapper">
                                <div class="row">
                                    <div class="col-sm-3 col-xs-3">
                                        <div class="car-image">
                                            <img src="assets/img/car-body.png" width="40px">
                                        </div>
                                    </div>
                                    <div class="col-xs-7">
                                        <a href="#" class="text-red f-weight f-14">CAR2022042738939</a>
                                    </div>
                                    <div class="col-xs-2">
                                        <button class="mat-icon-button"  mat-icon-button="" style="margin-top: -10px;">
                                            <span class="mat-button-wrapper">
                                                <mat-icon class="mat-icon material-icons text-red">more_vert</mat-icon>
                                            </span>
                                            <div class="mat-button-ripple mat-ripple mat-button-ripple-round" matripple=""></div>
                                            <div class="mat-button-focus-overlay"></div>
                                        </button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-xs-6">
                                        <div class="margin-bottom-5 margin-top-5">
                                            <span class="" title="Cust. Name"><i class="fa fa-calendar"></i></span>
                                            <span class="f-10">08/08/2022  10:30 AM</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-xs-6">
                                        <div class="margin-bottom-5 margin-top-5">
                                            <span class="" title="Cust. Name"><i class="fa fa-clock"></i></span>
                                            <span class="f-10">01 Hr 30 Mins</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-xs-12">
                                        <div class="margin-bottom-5 margin-top-5">
                                            <span class="" title="Cust. Name"><i class="fa fa-car"></i></span>
                                            <span class="f-10">Make/Year/Model</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-xs-12">
                                        <div class="margin-bottom-5 margin-top-5">
                                            <span class="" title="Cust. Name"><i class="fa fa-user"></i></span>
                                            <span class="f-10">Customer Name - Mobile</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-xs-6">
                                        <div class="notification-text">
                                            <div class="t1-class light-pink-color">
                                            <span class="text-icon">T1</span>
                                            </div>
                                            <div class="t2-class pink-color">
                                                <span class="text-icon">T2</span>
                                                <div class="check-icon">
                                                    <i class="fa fa-check" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div class="t3-class dark-pink-color">
                                                <span class="text-icon">T3</span>
                                                <div class="check-icon">
                                                    <i class="fa far fa-clock" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-xs-6 text-right">
                                        <div class="msg-box">
                                            <a href="#"><img src="assets/img/listshop/msg.png"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- #3 column -->
                    <div class="bg-color col-md-4">
                        <div class="main-box">
                            <div class="row">
                                <div class="col-md-10 col-xs-10">
                                    <h4 class="px-3 f-16 f-weight">All Open <span class="f-10 f-weight">(Appointment/Pending)</span></h4>
                                </div>
                                <div class="col-md-2 col-xs-2">
                                    <div class="image-block">
                                        <img src="assets/img/listshop/notify.png">
                                    </div>
                                    <div class="notify-number">
                                        2
                                    </div>
                                </div>
                            </div>
                            <div class="box-wrapper">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <div class="car-image">
                                            <img src="assets/img/car-body.png" width="40px">
                                        </div>
                                    </div>
                                    <div class="col-xs-7">
                                        <a href="#" class="text-red f-weight f-14">CAR2022042738939</a>
                                    </div>
                                    <div class="col-xs-2">
                                        <button class="mat-icon-button"  mat-icon-button="" style="margin-top: -10px;">
                                            <span class="mat-button-wrapper">
                                                <mat-icon class="mat-icon material-icons text-red">more_vert</mat-icon>
                                            </span>
                                            <div class="mat-button-ripple mat-ripple mat-button-ripple-round" matripple=""></div>
                                            <div class="mat-button-focus-overlay"></div>
                                        </button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-xs-6">
                                        <div class="margin-bottom-5 margin-top-5">
                                            <span class="" title="Cust. Name"><i class="fa fa-calendar"></i></span>
                                            <span class="f-10">08/08/2022  10:30 AM</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-xs-6">
                                        <div class="margin-bottom-5 margin-top-5">
                                            <span class="" title="Cust. Name"><i class="fa fa-clock"></i></span>
                                            <span class="f-10">01 Hr 30 Mins</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-xs-12">
                                        <div class="margin-bottom-5 margin-top-5">
                                            <span class="" title="Cust. Name"><i class="fa fa-car"></i></span>
                                            <span class="f-10">Make/Year/Model</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-xs-12">
                                        <div class="margin-bottom-5 margin-top-5">
                                            <span class="" title="Cust. Name"><i class="fa fa-user"></i></span>
                                            <span class="f-10">Customer Name - Mobile</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-xs-6">
                                        <div class="notification-text">
                                            <div class="t1-class light-pink-color">
                                               <span class="text-icon">T1</span>
                                            </div>
                                            <div class="t2-class pink-color">
                                                <span class="text-icon">T2</span>
                                                <div class="check-icon">
                                                    <i class="fa fa-check" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div class="t3-class dark-pink-color">
                                                <span class="text-icon">T3</span>
                                                <div class="check-icon">
                                                    <i class="fa far fa-clock" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-xs-6 text-right">
                                        <div class="msg-box">
                                            <a href="#"><img src="assets/img/listshop/msg.png"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--
Ticket Assignment SidePanel integration
-->
<app-tech-assignment [data]="techData" (closeTab)="techAssignmentOut($event)" *ngIf="(techData | json) != '{}'"></app-tech-assignment>

<!-- color status -->
<div class="color-info">
    <ul class="sr-legends-list">
        <li style="background-color:#fd82ad5e;" (click)="handleLegends('11')" title="Appointments">Appointments</li>
        <li style="background-color:#faf2cc;" (click)="handleLegends('1')" title="Pending">Pending</li>
        <li style="background-color:#c4e3f3;" (click)="handleLegends('2')" title="Processing">Processing</li>
        <li style="background-color:#e1e114;" (click)="handleLegends('3')" title="Approval Awaited">Approval Awaited</li>
        <li style="background-color:#d0e9c6;" (click)="handleLegends('7')" title="Approval Completed">Approval Completed</li>
        <li style="background-color:#b4eab4;" (click)="handleLegends('12')" title="Work In Progress">Work In Progress</li>
        <li style="background-color:#ada;" (click)="handleLegends('6')" title="Completed">Completed</li>
        <li style="background-color:#52bab3;" (click)="handleLegends('8')" title="Completed And Paid">Completed And Paid</li>
        <li style="background-color:#a9a9a9;" (click)="handleLegends('-1')" title="Deleted">Deleted</li>
        <li style="background-color:#f5f5f5;border:1px solid;padding-bottom:4px;" (click)="handleLegends('9')" title="Unread">Unread</li>
    </ul>    
</div>