import {Component, OnInit, ViewChild, Pipe, Inject} from '@angular/core';
import {AbstractControl, FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd,Params} from '@angular/router';
import {Http, Headers, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../environments/globalFunctions"
import {globalConstants} from "../../../environments/globalConstants"
import {GlobalService} from "../../../environments/global.service"
import {lang} from "../../../environments/lang-en"
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';
import {AppComponent } from "../../app.component"

@Component({
  selector: 'app-customer-payment-response',
  templateUrl: './customer-payment-response.component.html',
  styleUrls: ['./customer-payment-response.component.scss']
})
export class CustomerPaymentResponseComponent implements OnInit {
  public formData:FormGroup;
  public paymentSuccess;
  public loggedInCustomer = JSON.parse(localStorage.getItem("loggedInCustomer"))
  public subscriptionMessage;
  constructor(public app:AppComponent,private activatedRoute: ActivatedRoute,private formBuilder: FormBuilder,public titleService: Title, private http: Http, private router: Router, private globalService: GlobalService) {
    // console.log(this.activatedRoute.snapshot.queryParams)
    this.setFormBuilder(this.activatedRoute.snapshot.queryParams);
    this.sendRequest();
  }

  setFormBuilder(params) {
    this.formData = this.formBuilder.group({
      customer_id:[this.loggedInCustomer.id],
      first_name:[params.first_name],
      last_name:[params.last_name],
      email:[params.email],
      street_address:[params.street_address],
      city:[params.city],
      state:[params.state],
      country:[params.country],
      zip:[params.zip],
      fixed:[params.fixed],
      product_id:[params.product_id],
      merchant_order_id:[params.merchant_order_id],
      ip_country:[params.ip_country],
      product_description:[params.product_description],
      currency_code:[params.currency_code],
      pay_method:[params.pay_method],
      invoice_id:[params.invoice_id],
      order_number:[params.order_number],
      card_holder_name:[params.card_holder_name],
      merchant_product_id:[params.merchant_product_id],
      credit_card_processed:[params.credit_card_processed],
      total:[params.total],
      cart_tangible:[params.cart_tangible],
      quantity:[params.quantity],
      key:[params.key],
      subscription_id:[params.subscription_id]
    })
  }
   sendRequest() {
    this.globalService.callAPI(globalConstants.API_SUBSCRIBE_CUSTOMER,this.formData.value).subscribe((data:any)=>{
      // console.log(data);
        this.subscriptionMessage = data.message;
        localStorage.setItem("paySuccessMsg", data.message)
        if(data.result == "1") {
            this.paymentSuccess = true;
            var exitingTimeStamp = JSON.parse(localStorage.getItem("loggedInCustomer")).timeStamp;
            data.data["timeStamp"] = exitingTimeStamp;
            localStorage.setItem("customerSubsResponse",JSON.stringify(data.data));
            this.app.getCurrentCustomerSubscription();
            this.router.navigate(["customer/payment-success"]);
        } else {
            this.paymentSuccess = false;
            this.router.navigate(["customer/payment-fail"]);
        }
    })
  }
  
  ngOnInit() {
  }
  
}
