import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { globalConstants } from "../../../../environments/globalConstants";
import { GlobalService } from "../../../../environments/global.service";

@Component({
  selector: 'app-edit-mobile-no',
  templateUrl: './edit-mobile-no.component.html',
  styleUrls: ['./edit-mobile-no.component.scss']
})
export class EditMobileNoComponent implements OnInit {

  public EditMobileNo : FormGroup;
  public currentUser  : any;
  public shopId       : number;
  public id           : number;
  public phoneMask    = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(private formBuilder: FormBuilder, private router: Router,private route: ActivatedRoute, private activatedRoute: ActivatedRoute, public globalService: GlobalService) {

    if (localStorage.getItem("loggedInUser")) {
      this.currentUser = this.globalService.getCurrentUser();
      if(this.currentUser){
        this.shopId = this.currentUser.shop_id;
      }
    } else {
      router.navigate(["/shops"]);
    }
    setTimeout(() => {
      let inSubscription: boolean = true;
      let isEnable = this.globalService.getAcl('sms_mobile_no_ignore', 'edits');
      if (!(isEnable) || !(inSubscription)) {
        this.router.navigate(['shops/action/forbidden']);
      }
    }, globalConstants.RELOAD_WAIT);
    this.getParams();
  }

  ngOnInit() {
    this.initializeEditForm();
  }

  getDetail(){
    let getNumberObj = this.EditMobileNo.value;
    getNumberObj.user_id = this.currentUser.id;
    getNumberObj.user_type = this.currentUser.user_type;
    var payload = this.globalService.formsData(getNumberObj);
    this.globalService.formData(globalConstants.API_SHOP_SMS_GET_IGNORE_MOBILE_NO_URL, payload).subscribe((r) => {
      if (r.result == 1) {
        let message = r.message;
        this.EditMobileNo.patchValue(r.data);
      } else {
        let message = r.message;
        this.globalService.snackbar('error', message);      }
    }, (error) => { })
  }

  getParams() {
    this.route.params.subscribe((params: Params) => {
      this.id = params["id"];
    });
  }

  initializeEditForm() {
    this.EditMobileNo = this.formBuilder.group({
      id                  : [this.id],
      name                : ['Unknown', Validators.required],
      shop_id             : [this.shopId, Validators.required],
      phone_number        : ['', [Validators.required, Validators.minLength(10), this.globalService.mobileNumberValidation]],
      restore_phone_number: ['']
    });
    this.getDetail();
  }

  onSubmit(isRestore=null) {
    if (this.EditMobileNo.valid) {
      let editNumberObj = this.EditMobileNo.value;
      editNumberObj.user_id = this.currentUser.id;
      editNumberObj.user_type = this.currentUser.user_type;
      if (isRestore == 1) {
        editNumberObj.restore_phone_number = '1';
      }
      var payload = this.globalService.formsData(editNumberObj);
      this.globalService.formData(globalConstants.API_SHOP_SMS_EDIT_IGNORE_MOBILE_NO_URL, payload).subscribe((r) => {
        if (r.result == 1) {
          let message = r.message;
          this.router.navigate(['shops/mobile-no-ignore-list']);
          this.globalService.snackbar('success', message);
        } else {
          let message = r.message;
          if(r.code ==768){
            this.globalService.confirmDialog("yes", message).subscribe((r:any) => {
                  if (r != undefined && r == "yes") {
                    this.onSubmit(1);
                  }else{
                    this.globalService.snackbar('error', message);
                  }
                }, (error) => { });
              }else{
                this.globalService.snackbar('error', message);
              }
        }
      }, (error) => { })

    } else {
      this.globalService.validateAllFormFields(this.EditMobileNo);
    }
  }

}
