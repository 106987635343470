import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/base/services';
import { GlobalService } from 'src/environments/global.service';
import { globalConstants } from 'src/environments/globalConstants';

@Component({
  selector: 'app-qbo-connect',
  templateUrl: './qbo-connect.component.html',
  styleUrls: ['./qbo-connect.component.scss']
})
export class QboConnectComponent implements OnInit {
  public isSubscribed: any = false;
  public shopDetails: any;
  public childWin: any;
  public loginUserDetails: any
  public isQuickBookConnected: any;
  constructor(
    private router: Router,
    public globalService: GlobalService,
    public loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.loaderService.hide();
    this.shopDetails = JSON.parse(localStorage.getItem("shop-details"));
    this.loginUserDetails = JSON.parse(localStorage.getItem("loggedInUser"));
    if (this.shopDetails) {
      this.isSubscribed = this.shopDetails.quickbooks_online;
    }
    if (this.isSubscribed == '1') {
      this.checkQBConnectionStatus();
    }
  }

  checkQBConnectionStatus() {
    let payload = {
      user_id: this.loginUserDetails.id,
      user_type: "User"
    };
    this.globalService.callAPI(globalConstants.API_GET_SHOP_QBO_DETAILS, payload).subscribe((res: any) => {
      if (res.result == 1) {
        this.isQuickBookConnected = true;
      } else {
        this.isQuickBookConnected = false;
      }
    })
  }

  gotoShopSubscription() {
    this.router.navigate(['/shops/subscription']);
  }

  connectWithQuickBook() {
    var widthScreen = screen.width - 200;
    var heightScreen = screen.height - 170;
    var quickBookConnectUrl = globalConstants.API_GET_QBO_CONNECT + "?shop_id=" + this.shopDetails.id;
    this.childWin = window.open(quickBookConnectUrl, 'Quickbooks Online Connect', "toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width= " + widthScreen + " ,height=" + heightScreen + ",top=50,left=100");
    var interval = setInterval(function (childWinObj) {
      try {
        if (childWinObj.childWin.closed) {
          childWinObj.checkQBConnectionStatus();
          clearInterval(interval);
        }
      }
      catch (e) {
      }
    }, 500, this);
  }

  disconnectWithQuickBook() {
    let confirmationMsg = "Are you sure you want to disconnect Quickbooks Online account?"
    this.globalService.confirmDialog("yes", confirmationMsg).subscribe((res: any) => {
      if (res == 'yes') {
        let payload = {
          user_id: this.loginUserDetails.id,
          user_type: "User"
        };
        this.globalService.callAPI(globalConstants.API_DISCOUNECT_SHOP_QBO, payload).subscribe((res: any) => {
          if (res.result == 1) {
            this.isQuickBookConnected = false;
          } else {
            this.isQuickBookConnected = true;
          }
        })
      }
    })
  }
}


