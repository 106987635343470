<!--add disclaimer start-->
<div class="car-panel">
    <div class="panel panel-primary" id="addDisclaimerPanel">
        <div class="panel-heading padding-left-15">
            <h3 class="panel-title">
              Add New Disclaimer
            </h3>
        </div>
    </div>

    <div class="panel-body bgcolor-lightgrey add-disclaimer">
        <!-- Add disclaimer page start -->
        <div id="adddisclaimerContent" class="row bgcolor-lightgrey form-content">
            <div class="container-fluid">
                <form [formGroup]="shopAddDisclaimer" (ngSubmit)="adddisclaimer()" class="form-inline col-xs-12 col-sm-12 col-md-12 col-lg-12" role="form" method="POST" enctype="multipart/form-data" autocomplete="off">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block">
                      <div class="row">
                          <div class="form-group col-sm-12 col-md-6" >
                              <label for="job_name">Name</label>
                              <div class='relative'>
                                  <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                      <input matInput placeholder="Title*" title="Title" maxlength="200" formControlName="title"  class="form-control input-lg custom-form-input"/>
                                      <mat-error *ngIf="shopAddDisclaimer.controls.title.hasError('required')">
                                          This field cannot be empty
                                      </mat-error>
                                  </mat-form-field>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="form-group details-input col-sm-12">
                              <label for="description">Description</label>
                              <div class='relative'>
                                  <mat-form-field class="btn-block car-input notes-input" floatPlaceholder="never" appearance="fill">
                                      <textarea matInput placeholder="Description*" rows="5" maxlength="1000" title="Description" formControlName="description"   rows="5"  class="input-lg custom-form-input"></textarea>
                                      <mat-error *ngIf="shopAddDisclaimer.controls.description.hasError('required')">
                                          This field cannot be empty
                                      </mat-error>
                                  </mat-form-field>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="form-group col-sm-12">
                              <div class='relative'>
                                  <mat-checkbox class="btn-block" labelPosition="after"  formControlName="is_default" (change)="showTaxExempted($event)">
                                      Set as Default Disclaimer.
                                  </mat-checkbox>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block submit-block">
                              <button type="submit" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-light fontcolor-white" color="accent" title="Submit" style="max-width: 300px;">
                                  SUBMIT
                              </button>
                          </div>
                      </div>
                  </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!--add disclaimer end -->
