<div mat-dialog-title class="relative">
    {{dialogData.dialogTitle}}
    <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
      <mat-icon class="close-icon-lg" aria-label="">close</mat-icon>
    </button>
  </div>
  <!-- <mat-dialog-content> -->
    <div *ngIf="dialogData.opr == 1">
        <app-complete-profile-setup class="newSr-profile-completion" (handleSkipShopProfileSetup)="handleShopProfileCompletion()" [showSkipButton]="1"></app-complete-profile-setup>
    </div>
    <div *ngIf="dialogData.opr == 2">
        <app-review-payment-config (handleSubmitUrlAndNotes)="handleReviewAndPaymentConfigSubmit($event)" [screenType]="dialogData.screenType"></app-review-payment-config>
    </div>
<!-- </mat-dialog-content> -->