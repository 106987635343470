<div class="sr-card-addlabel">
<div mat-dialog-title class="relative addlabel-title">
    <h4 class="margin-0">Add Label to SR</h4>
    <div class="btn-wrap">
        <button (click)="manageLabels()" class="edit-btn"><span class="hidden-xs">Manage Labels</span> <span class="edit-icon"><i class="fa fa-edit visible-xs"></i></span></button>
         <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0" title="Close" (click)="closeDialog()">
            <mat-icon class="notranslate">close</mat-icon>
         </button>
    </div>  
</div>

<mat-dialog-content>
    <ngx-select 
        [autoClearSearch]="true" class="display-full" 
        [defaultValue]="preSelectLabel"
        [multiple]="true" 
        [items]="allLabels"
        (select)="selectedLabel($event)"
        (remove)="removeLabel($event)"
        optionTextField="label_name"
        placeholder="Select Label"
        class="details-input">
    </ngx-select>
    <div class="display-full margin-top-20 margin-bottom-5">
        <div class="text-wrap">
            <div class="margin-bottom-10">You are updating labels on <span class="sr-id">#{{fullRequestId}}</span></div>
            <p class="help-text">Select the labels applicable to the SR. All selected labels will be visible on the Job Board. 
                You can remove the already added labels directly from the board.</p>
        </div>
    </div>
    <div class="display-full margin-top-10 margin-bottom-5">
        <div class="col-sm-12 close-btn">
          <button mat-raised-button mat-dialog-close class="font-bold" title="Close" (click)="closeDialog()">Close</button>
        </div>
    </div>
</mat-dialog-content>
</div>