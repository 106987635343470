<div class="panel panel-primary">
    <div class="panel-heading padding-left-30">
        <h3 class="panel-title">SERVICE REQUESTS HISTORY</h3>
        <button mat-mini-fab class="custom-btn-back" title="Back" (click)="globalservice.goBack();">
            <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <div class="car-datatable mat-elevation-z1">
            <div class="text-right hidden">
                <mat-form-field floatPlaceholder="never" >
                    <input matInput #filter placeholder="Filter users">
                </mat-form-field>
            </div>
            <div class="table-responsive relative">
                <mat-table #table [dataSource]="dataSource" matSort class="service-list datatable-loader-bg">

                    <!-- Color Column -->
                    <ng-container matColumnDef="request_id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header  data-role="id"> Request Id </mat-header-cell>
                        <mat-cell *matCellDef="let row" style="color:#f50057;"data-role="id"> {{row.request_id}} </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="title">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="title"> Title </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="title"> {{(row.title ? row.title : '-')}} </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="customer_name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="name"> Customer Name </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="name"> {{row.customer_name}} </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="vehicle">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="vehicle"> Vehicle </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="vehicle"> {{(row.vehicle ? row.vehicle : '-')}} </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="appointment_date">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> App. Date </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto">{{row.requested_delivery_date}} </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="service_date">
                      <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Service Date </mat-header-cell>
                      <mat-cell *matCellDef="let row" data-role="auto"> {{row.service_date}} </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="estimated_delivery_date">
                      <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Est. Delivery Date </mat-header-cell>
                      <mat-cell *matCellDef="let row" data-role="auto"> {{row.estimated_delivery_date}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="car">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="car"> Car </mat-header-cell>
                        <mat-cell *matCellDef="let row"  data-role="car"> {{row.car}} </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Status </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto"> {{getStatus(row.status)}}</mat-cell>
                    </ng-container>

            <!-- Color Column -->
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef  data-role="button"> Actions </mat-header-cell>
                <mat-cell *matCellDef="let row" data-role="button">
                    <button mat-button class="list-action-btn" color="accent" [matMenuTriggerFor]="menu" disableRipple >
                      Action<mat-icon>arrow_drop_down</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" class="car-icon-menu disable-no-bg" [overlapTrigger]="false">
                      <button mat-menu-item (click)="viewHistoryDetail(row.id)"> <mat-icon>remove_red_eye</mat-icon>View</button>
                    </mat-menu>
                </mat-cell>
            </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" class="mat-row {{getRowStatusColor(row.status)}}"></mat-row>
            </mat-table>
                <div class="padding-15 text-center"
                     [style.display]="getTotalRecords() < 1 ? '' : 'none'">
                     No Records Available
                </div>
            </div>
            <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
                 <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event)" >
                </mat-paginator>

                <div class="mat-paginator table-paging">
                    <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
                    <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                            <mat-icon>navigate_before</mat-icon>
                    </button>
                    <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                            <mat-icon>navigate_next</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
