import { Component, OnInit, Inject } from '@angular/core';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Http } from '@angular/http';
@Component({
  selector: 'app-admin-editpart-popup',
  templateUrl: './admin-editpart-popup.component.html',
  styleUrls: ['./admin-editpart-popup.component.scss']
})
export class AdminEditpartPopupComponent implements OnInit {
  public admin;
  public editNewPart: FormGroup;
  public part_id = 0;
  public currentPartDetails:any={};
  public nonTaxable;
  public submitted = false;
  public currentPartPicture = '';
  public partPicture = '';
  public partsPic = globalConstants.S3_BUCKET_URL + globalConstants.PART_PICTURE + globalConstants.IMG_THUMB;
  public lowStockQty="Low Stock Quantity";
  public isInventory=false;
  public allTags=[];
  public preSelectTag=[];
  constructor( @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AdminEditpartPopupComponent>, private formBuilder: FormBuilder, private globalService: GlobalService,public dialog: MatDialog, private http : Http) {
    this.part_id = data.part_id;
    this.globalService.diaLogObj(this.dialogRef);
  }

  ngOnInit() {
    this.admin = this.globalService.getAdmin();
    this.initEditForm();
    this.getPartsList();
  }

  public initEditForm(){
    this.editNewPart = this.formBuilder.group({
      part_name: ['', Validators.required],
      part_number: ['', Validators.required],
      cost: ['', [Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
      price: ['', [Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
      vendor: [''],
      manufacturer: [''],
      oem_part_number: [''],
      description: [''],
      part_pic: [''],
      part_id: [''],
      user_type: this.admin.user_type,
      user_id: this.admin.id,
      part_picture: [''],
    });
  }

  public getPartsList(){
    var partContent = "user_id=" + this.admin.id + "&user_type=" + this.admin.user_type + "&part_id=" + this.part_id;
    this.globalService.callAPI(globalConstants.API_MASTER_GET_PARTS_LIST_URL, partContent).subscribe((data:any) => {
     if(data.result=="1"){
      this.currentPartDetails = data.data;
      this.currentPartDetails.part_name = data.data.name;
      this.currentPartDetails.part_id = data.data.id;
      this.editNewPart.patchValue(this.currentPartDetails);
      this.partPicture = this.currentPartDetails.part_picture;
     }
    }, (error) => { });
  }

  editPart() {
    if (this.editNewPart.valid) {
      var data = new FormData();
      if(this.editNewPart.value.part_picture == null){
        this.editNewPart.patchValue({part_picture:''});
      }
      for (let i in this.editNewPart.value) {
        data.append(i, this.editNewPart.value[i]);
      }
      this.globalService.formData(globalConstants.API_MASTER_EDIT_PART_URL, data).subscribe(data => {
        var message = data.message;
        if (data.result == "1") {
          this.globalService.snackbar('success', message);
          this.dialogRef.close(this.editNewPart.value);
        } else {
          this.globalService.snackbar('error', message);
        }
      }, (error) => { });
    } else {
      this.submitted = true;
      this.globalService.snackbar('error', "Please fill all the mandatory fields.");
    }
  }

  checkIfValid() {
    if (this.editNewPart.valid) {
      this.submitted = false;
    }
  }

  getFile(evt, key) {
    var files = evt.target.files;
    var file = files[0];
    var valid = this.globalService.validImage(file, 'partPic');
    if (valid && files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsDataURL(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.currentPartPicture = binaryString;
    this.editNewPart.controls.part_picture.setValue(binaryString);
  }

  deletePartPic() {
    this.currentPartPicture = '';
    this.editNewPart.controls.part_picture.setValue('');
    let ele = <HTMLInputElement>document.getElementById('partPic');
    ele.value = '';
  }

  deleteExistingPartPic(){
    this.globalService.confirmDialog(1, 'Are you sure you want to delete this picture?').subscribe((res) => {
        if (res != null) {
            let content='user_id='+this.admin.id+'&part_id='+this.part_id+'&user_type='+this.admin.user_type;
            this.globalService.callAPI(globalConstants.API_DELETE_PART_PICTURE_URL,content).subscribe((data:any) => {
                var message = data.message;
                if (data.result == "1") {
                    this.partPicture='';
                    this.globalService.snackbar('success', message);
                    this.editNewPart.patchValue({part_picture:''});
                } else {
                    this.globalService.snackbar("error", message);
                }
            }, (error) => { })
        }
    });
  }

  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
        && (charCode < 48 || charCode > 57))
        return false;
    return true;
  }

  disableKey(event) {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey==true && (event.which == '67' || event.which == '86' ||  String.fromCharCode(event.which).toLowerCase() == 's')) {
        console.log('thou. shalt. not. PASTE!');
        event.preventDefault();
    }
  }

  partNumber(opt) {
    if (typeof opt == "object") {
        this.editNewPart.controls.part_number.setValue(opt.part_number);
    }else if(typeof opt!='undefined'){
        this.editNewPart.controls.part_number.setValue(opt);
    }
    this.checkIfValid();
  }
  
  observableSourcePN = (keyword: any): Observable<any[]> => {
    let url: string = globalConstants.API_GET_PART_NUMBERS + "?user_id=" + this.globalService.getCurrentUser()['id']+ "&user_type=" + this.globalService.getCurrentUser()['user_type'] + "&query=" + keyword;
    if (keyword) {
        let f = [];
        return this.http.get(url)
            .map(res => {
                let json = res.json();
                this.globalService.authText(json);
                if (json != null) {
                    json.data.forEach(function (x) {
                        x.value = x.part_number;
                        f.push(x)
                    }
                    );
                }
                return f
            })
    } else {
        return Observable.of([]);
    }
  }
  
  setReturnFalse() {
    return false
  }
  
  myListFormatter(data: any): string {
    return data['part_number'];
  }
}
