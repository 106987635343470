import { Component, OnInit, OnDestroy } from '@angular/core';
import { GlobalService } from '../../../../environments/global.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { globalConstants } from '../../../../environments/globalConstants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-partnumber',
  templateUrl: './partnumber.component.html',
  styleUrls: ['./partnumber.component.scss']
})
export class PartnumberComponent implements OnInit , OnDestroy{

  public user: any = null;
  public submitted = false;
  public addPartNumber: FormGroup;
  public partId;
  public heading : string = "ADD NEW";
  public showPartList : boolean = false;
  constructor(public router: Router, public gs: GlobalService, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute) {
    this.user = this.gs.getCurrentUser();
    if (this.user) {
      this.gs.setMetaData("SHOPS", "PART_NUMBER")
    } else {
      this.router.navigate(['shops']);
    }
    this.activatedRoute.params.subscribe((params: Params) => {
      this.partId = params["id"];
    });
    if(this.partId){
      this.heading = "EDIT";
      this.getPartDetail();
    }
    setTimeout(() => {
      let isEnable = this.gs.getAcl('part', 'addNew');
      if (!(isEnable)) {
        this.router.navigate(['shops/action/forbidden']);
      }
    }, globalConstants.RELOAD_WAIT);
  }

  ngOnInit() {
    this.initAddForm();
    this.gs.getSetItems('auto_part_number','');
  }
  initAddForm() {
    this.addPartNumber = this.formBuilder.group({
      group_name: ['', Validators.required],
      part_number: ['', Validators.required],
      user_type: this.user.user_type,
      user_id: this.user.id
    })
  }
  AddPart(opr) {
    Object.keys(this.addPartNumber.controls).map((controlName) => {
      this.addPartNumber.get(controlName).markAsTouched({ onlySelf: true });
    });

    if (this.addPartNumber.valid) {

      this.addPartNumber.controls.user_type.setValue(this.user.user_type);
      this.addPartNumber.controls.user_id.setValue(this.user.id);

      var data = new FormData();
      for (let i in this.addPartNumber.value) {
        data.append(i, this.addPartNumber.value[i]);
      }
      let url = globalConstants.API_ADD_PART_NUMBER;
      if(this.partId){
        url = globalConstants.API_SHOP_SMART_BATCH_EDIT;
        data.append('batch_id', this.partId);
      }
      this.gs.formData(url, data).subscribe(data => {
        var message = data.message;
        if (data.result == "1") {
          if (opr == 1) {
            this.router.navigate(["/shops/list-smart-batch"]);
            this.gs.snackbar('success', message);
          } else if (opr == 2) {
            this.gs.getSetItems('auto_part_number',this.addPartNumber.value.part_number);
            this.router.navigate(["/shops/add-part"]);
            this.gs.snackbar('success', message);
          }
        } else {
          this.gs.snackbar('error', message);
        }
      },(error)=>{});
    } else {
      this.submitted = true;
      this.mandatoryMsg();
    }
  }

  mandatoryMsg() {
    this.gs.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
  }
  checkIfValid() {
    if (this.addPartNumber.valid) {
      this.submitted = false;
    }
  }

  public getPartDetail(){
    var shopContent = "user_id=" + this.user.id + "&user_type=" + this.user.user_type + "&batch_id=" + this.partId;
    this.gs.callAPI(globalConstants.API_SHOP_SMART_BATCH_LIST, shopContent).subscribe((data:any) => {
      if(data && data.result == '1'){
        this.addPartNumber.patchValue(data.data);
        sessionStorage.setItem('shop-search_keyword', data.data.part_number);
        this.showPartList = true;
      }
    });
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('shop-search_keyword');
  }


}
