import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap,NavigationEnd, Params  } from '@angular/router';
import { Http,Headers, Response} from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { globalFunctions } from "../../../../environments/globalFunctions";
import { globalConstants } from "../../../../environments/globalConstants";
import {GlobalService} from "../../../../environments/global.service";
import { lang } from "../../../../environments/lang-en";
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TextMaskModule } from 'angular2-text-mask';
import { Observable } from 'rxjs/Observable';
// import { element } from 'protractor';
import {VideoShowComponent} from '../../../customer/my-vehicles/video-show/video-show.component';
import {MatDialog} from '@angular/material/dialog';
import { LoaderService } from 'src/app/base/services';

@Component({
  selector: 'app-stock-order',
  templateUrl: './stock-order.component.html',
  styleUrls: ['./stock-order.component.scss']
})
export class StockOrderComponent implements OnInit, OnDestroy {
    public currentUser;
    public searchSelectPart;
    public searchSelectPartLabel;
    public searchSelectPartQuantity:any =0;
    public selectedParts;
    public submitted: boolean = false;
    public stockOrderId:any = 0;
    public isSubscribed=true;
    public orderQueId: any = '';
    public subscriptionDataForAPI: Subscription = new Subscription();

    constructor(private route: ActivatedRoute,public titleService:Title,private http: Http,private router: Router,public globalService: GlobalService, public dialog: MatDialog,public loaderService:LoaderService) {
        if(localStorage.getItem("loggedInUser")) {
            this.currentUser = JSON.parse(localStorage.getItem("loggedInUser"));
            this.searchSelectPart = {
              id:'',
              label:'',
              quantity:''
            };
            this.searchSelectPartLabel = '';
            this.searchSelectPartQuantity = '';
            this.selectedParts = [];
        } else {
            this.router.navigate(["/shops"]);
        }
        setTimeout(()=>{
        let isEnable=this.globalService.getAcl('stock_order','addNew');
        if(!(isEnable)){
            this.router.navigate(['shops/action/forbidden']);
        }
      },globalConstants.RELOAD_WAIT);
      this.isSubscribed=this.globalService.isPartstechAllowed();
      this.globalService.setMetaData("SHOPS","STOCK_ORDER_PARTSTECH")
    }

    ngOnInit() {
      
      this.getParam();
      if(this.orderQueId){
        let data={que_id:this.orderQueId,user_id:this.globalService.getCurrentUser()["id"]};
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_STOCK_ORDER_QUEUE_DATA,data).subscribe((res:any)=>{
          if(res.result==1){
            this.selectedParts=res.data;
          }
        },(error)=>{});
      }else{
          this.loaderService.hide();
      }
    }

    fillPartstechCartItems(){
      let postData = new FormData();
      postData.append('user_id',this.globalService.getCurrentUser()["id"]);
      this.globalService.formData(globalConstants.API_GET_PARTSTECH_ALL_CARTS_FOR_SHOP_URL,postData).subscribe((res:any)=>{
        if(res.result == '1'){
          res.data.forEach(element => {
            let temp = {
              id:element.id,
              label:element.label,
              quantity:element.quantity
            }
            this.selectedParts.push(temp);
          });
        }
      });
    }

    observableSource = (keyword: any): Observable<any[]> => {
      let url: string = globalConstants.API_GET_PARTSTECH_PART_DATA_URL+"?get_cost=1&user_id="+this.globalService.getCurrentUser()["id"]+"&query="+keyword;
      if (keyword) {
      let f= [];
          //by ankur
         return this.globalService.getAPI(url).map((res:any)=>{
            let json = res;
            if(json!=null){
              json.forEach(function(x){
                x.value = x.label;
                x.quantity = 1
                f.push(x)
              });
            }
            return f;
          });
      } else {
        return Observable.of([]);
      }
    }

    getSelectedOption(opt) {
      // console.log(opt);
      if(typeof opt == "object") {
        this.searchSelectPart = opt;
        this.searchSelectPartQuantity = opt.quantity;
      }
    }

    addNewPart(){
      // console.log(this.searchSelectPart);
      if(this.searchSelectPartLabel == '') {
        this.globalService.snackbar('error','Please select a part first.');
      }else if(this.searchSelectPart.id == ''){
        this.globalService.snackbar('error','Please select a valid part.');
      }else if(this.searchSelectPartQuantity == ''){
        this.globalService.snackbar('error','Please enter quantity.');
      }else if(isNaN(this.searchSelectPartQuantity) || this.searchSelectPartQuantity <= 0){
        this.globalService.snackbar('error','Please enter a valid quantity.');
      }else{
        let partAlreadyAdded = false;
        this.selectedParts.forEach(element => {
          if(element.id == this.searchSelectPart.id){
            partAlreadyAdded = true;
          }
        });
        if(!partAlreadyAdded){
          this.searchSelectPart.quantity = parseInt(this.searchSelectPartQuantity);
          this.selectedParts.push(this.searchSelectPart);
        }else{
          this.globalService.snackbar('error','Part has been already added.');
        }
        this.searchSelectPart = {
          id:'',
          label:'',
          quantity:''
        };
        this.searchSelectPartLabel = '';
        this.searchSelectPartQuantity = '';
      }
    }

    removePart(partId){
      // console.log('partId',partId);
      // console.log(this.selectedParts);
      let partIndex = -1;
      for (let i = 0; i < this.selectedParts.length; i++) {
        const element = this.selectedParts[i];
        if(element.id == partId){
          partIndex = i;
          break;
        }
      }
      // console.log('partIndex',partIndex);
      if(partIndex != -1){
        this.selectedParts.splice(partIndex,1);
      }
    }

    callStockOrderAPI(){
      if(this.selectedParts.length > 0){
        let formData = new FormData();
        let dialogRef;
        formData.append('user_id',this.currentUser.id);
        formData.append('user_type',this.currentUser.user_type);
        this.selectedParts.forEach(element => {
          formData.append('parts['+element.id+']',element.quantity);
        });
        if(this.orderQueId){
          formData.append('que_id',this.orderQueId);
        }
        this.globalService.formData(globalConstants.API_GET_STOCK_ORDER_URL,formData)
        .subscribe((response)=>{
          if (response.result == 1) {
            this.stockOrderId = response.data.stock_order_id;
            let redirectUrl = response.data.redirect_url;
            let sessionId = response.data.session_id;
            dialogRef = this.dialog.open(VideoShowComponent, {
              panelClass: 'partechDialog',
              width: "100%",
              data: {'iframe':redirectUrl,'ref':sessionId}
            });
            dialogRef.afterClosed().subscribe(result => {

              let formData = new FormData();
              let dialogRef;
              formData.append('user_id',this.currentUser.id);
              formData.append('user_type',this.currentUser.user_type);
              formData.append('stock_order_id',this.stockOrderId);
              this.globalService.formData(globalConstants.API_GET_STOCK_ORDER_STATUS_URL,formData)
              .subscribe((response) => {
                if(response.result == '1'){
                  if(response.order_completed == '1'){
                    this.globalService.snackbar('success',response.message);
                    this.router.navigateByUrl("/shops/list-stock-orders");
                  }
                }
              });
            });
          }
        });
      }else{
        this.submitted = true;
      }
      return false;
    }
    /**
     * check input qty float & blank
     * @param part
     * @param i
     */
    checkQty(part,i){
      if((part.quantity=="") || (isNaN(part.quantity) || (parseInt(part.quantity) <= 0))){
        this.globalService.snackbar('error','Please enter a valid quantity.');
        this.submitted = true;
        this.selectedParts[i].quantity='';
      }else{
        this.selectedParts[i].quantity=parseInt(part.quantity);
        this.submitted = false;
      }
    }
    /**
     * Type only Number  (Number Validation)
     * @param event
     */
    onlyNumericKey(event) {
      return globalFunctions.onlyDecimalNumberKey(event);
    }
    gotoShopSubscription() {
      this.router.navigate(['/shops/subscription'])
    }
    getParam() {
      this.route.params.subscribe((params: Params) => {
        this.orderQueId = params["id"];
      });
    }

    increment(value: string) {
      if(value === 'Plus') {
          this.searchSelectPartQuantity = ++this.searchSelectPartQuantity;
        }else if (value === 'Minus' && this.searchSelectPartQuantity > 1){
          this.searchSelectPartQuantity= --this.searchSelectPartQuantity;
        }else{
          this.searchSelectPartQuantity = 0;
        }
    }

    ngOnDestroy(){
      this.subscriptionDataForAPI.unsubscribe();
    }
}
