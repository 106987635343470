import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { GlobalService } from '../../../../environments/global.service';
import { Router } from '@angular/router';
import { globalConstants } from '../../../../environments/globalConstants';
import { globalFunctions } from '../../../../environments/globalFunctions';
import {Http, Headers, Response, ResponseContentType} from '@angular/http';
import  saveAs  from 'file-saver';

@Component({
  selector: 'app-importexport',
  templateUrl: './importexport.component.html',
  styleUrls: ['./importexport.component.scss']
})
export class ImportexportComponent implements OnInit {

  public submitted: boolean = false;
  public importParts: FormGroup;
  public formData: FormData;
  public rerrors = [];
  public seletedFileName = '';
  public user: any = {};
  constructor(private formBuild: FormBuilder,private http:Http, public gs: GlobalService, private router: Router) {
    this.user = this.gs.getCurrentUser();
    if (!this.user) {
      this.router.navigate(["/shops"]);
    }
  }

  ngOnInit() {
    this.gs.setMetaData("SHOPS","IMPORT_PARTS");
    this.formData = new FormData();
    this.importParts = this.formBuild.group({
      parts_csv: ['', Validators.required],
      user_type: this.user["user_type"],
      user_id: this.user["id"],
    });
    setTimeout(()=>{
      let isEnable=this.gs.getAcl('parts_import_export','views');
      if(!(isEnable )){
          this.router.navigate(['shops/action/forbidden']);
      }
    },globalConstants.RELOAD_WAIT)
  }
  getFile(event) {
    let valid = true;
    if (event.target.files.length) {
      valid = this.gs.validateFiles('csv', event.target.files);
    }
    if (valid) {
      this.importParts.controls.parts_csv.setValue(event.target.files[0]);

      this.seletedFileName = event.target.files[0].name;
    } else {
      this.importParts.controls.parts_csv.setValue('');
      this.seletedFileName = '';
      let ele = <HTMLInputElement>document.getElementById('parts_csvfiles');
      ele.value = '';
      return false;
    }
  }
  importNewParts() {
    if (this.importParts.valid) {
      this.formData.append('parts_csv', this.importParts.value.parts_csv);
      this.formData.append('user_type', this.importParts.value.user_type);
      this.formData.append('user_id', this.importParts.value.user_id);

      let fdata=this.gs.formsData(this.importParts.value);
      this.gs.formData(globalConstants.API_IMPORT_SHOP_PARTS_LIST, fdata).subscribe(data => {
        var resultData = data;
        this.submitted = true;
        this.formData = new FormData();
        if (typeof resultData.data != 'undefined') {
          this.rerrors = resultData.data;
        }
        if (resultData.result == 1) {
          this.gs.snackbar('success', resultData.message);
        } else {
          this.gs.snackbar('error', resultData.message);
        }
      },(error)=>{});
    } else {
      this.gs.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
  }
  exportCSV(){
    let data={user_id:this.importParts.value.user_id ,user_type: this.importParts.value.user_type}
    let url=globalConstants.API_EXPORT_SHOP_PARTS_LIST;
    let content=globalFunctions.stringifyFormData(data, null);
    this.gs.downloadFileWithPost(url,content,"part_export_");
  }
  downloadSmapleCSV() {

    window.location.href = globalConstants.API_DOWNLOAD_SAMPLE_CONTACTS_FILE_URL+"?t=2";
  }
  showNum(val){
    return globalFunctions.hasDecimal(val,true);
  }

}
