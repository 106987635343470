import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
declare var $;
declare var jQuery;
@Component({
  selector: 'app-partsuggestion',
  templateUrl: './partsuggestion.component.html',
  styleUrls: ['./partsuggestion.component.scss']
})
export class PartsuggestionComponent implements OnInit {

  public user:any=null;
  public param:any={};
  public parts=[];
  public title:any='';
  public matrixStatus=0;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<PartsuggestionComponent>, private gs: GlobalService) {
    this.param = data;
  }

  ngOnInit() {
    this.user = this.gs.getCurrentUser();
    if (!this.user) {
      this.dialogRef.close(2);
    }
    if(this.param && typeof this.param.for!='undefined' && typeof this.param.for.part_number!='undefined'){
      this.title=this.param.for.part_number;
    }
    this.gs.diaLogObj(this.dialogRef);
    this.getSuggestionList();
  }

  getSuggestionList(){
    let content = "user_id="+this.user.id+"&user_type="+ this.user.user_type+"&part_id="+this.param.for.id;
    this.gs.callAPI(globalConstants.API_PART_SUGGESTION_FROM_BATCH,content).subscribe((res:any) => {
      if (res.result == 1) {
        this.parts=res.data;
        this.matrixStatus=res.data.part_matrix_status;
      }else{
        
      }
    },(error)=>{});
  }
  submit(){
    if(jQuery('input[name="part"]:checked').length){
      let i=jQuery('input[name="part"]:checked').val();
      let part=this.parts[i];
      part['part_matrix_status']=this.matrixStatus;
      this.dialogRef["suggested"]=part;
      this.dialogRef.close(1);
    }
   
  }

}
