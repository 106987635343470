<div class="panel panel-primary" id="mainCompotents">
  <div class="panel-heading padding-left-15">
    <h3 class="panel-title">EASY CHECK IN</h3>
  </div>
  <div class="panel-body bgcolor-lightgrey">
    <div class="display-full relative">

      <mat-expansion-panel hideToggle="false" class="search-filter search-employee relative" [expanded]="openSearch">
        <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px">
          <strong><i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
        </mat-expansion-panel-header>
        <form id="advanceSearchForm" class="collapse in" aria-expanded="true">
          <div class="panel-body">

            <div class="col-sm-3 uc-ngx-select">
                <ngx-select class="display-full margin-bottom-20" [items]="selectlists" [(ngModel)]="options.search_by" [ngModelOptions]="{standalone: true}"
                placeholder="Search By" [noAutoComplete]="true" optionTextField="name" (select)="onSelectListChange($event)">
              </ngx-select>
            </div>
            <div class="col-sm-3 details-input" *ngIf="(!licensePlate) && (options.search_by!='5')">
              <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill" [class.input-error]="search_keyword_valid">
                <input  matInput placeholder="Search Keyword" title="Search Keyword" [(ngModel)]="options.search_keyword" [ngModelOptions]="{standalone: true}" class="custom-form-input"/>
              </mat-form-field>
            </div>
            <div class="col-sm-3 details-input" *ngIf="(licensePlate) || (options.search_by=='5')">
              <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill" [class.input-error]="search_keyword_valid">
                <input  matInput placeholder="Last Name" title="Last Name" [(ngModel)]="options.search_keyword" [ngModelOptions]="{standalone: true}" class="custom-form-input"/>
              </mat-form-field>
            </div>
            <div class="col-sm-3 details-input" *ngIf="(licensePlate) || (options.search_by=='5')">
              <mat-form-field class="car-input " floatPlaceholder="never" appearance="fill" hideRequiredMarker="false" [class.input-error]="search_keyword2_valid">
                <input matInput placeholder="License Plate" title="License Plate" [(ngModel)]="options.search_keyword2" [ngModelOptions]="{standalone: true}" class="custom-form-input" >
              </mat-form-field>
            </div>
          </div>
          <mat-action-row class="search-filter-footer">
            <button mat-raised-button color="accent" type="submit" (click)="search($event)">
              Search
            </button>
          </mat-action-row>
        </form>
      </mat-expansion-panel>
      <!--
        @author Shashank Thakur
        @trackID CAR-1412
        @updated 18-03-2020
      -->
      <button type="button" *ngIf="isAllowed('addNew','customer')" class="add-customer-btn" mat-raised-button color="accent" type="submit" (click)="openAddNewCustomerPopup($event)">Add New Customer</button>
    </div>

    <div class="car-datatable uc-datatable mat-elevation-z1">
      <div class="table-responsive relative">
        <!-- DISPLAY RESULTS FOR LICENCE PLATE -->
        <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg uc-table-responsive">
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="name"> Customer Name </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="name" data-val="Customer Name">
              <i class="fa fa-info-circle pointer text-red" (click)="handleOpenViewCarAndCustomerPopUp(row,2)"></i>
              {{row.name ? row.name : "-"}} 
              </mat-cell>
          </ng-container>
          <!-- Color Column -->
          <ng-container matColumnDef="mobile" *ngIf="showCarDetail()">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="phone"> Phone Number </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="phone" data-val="Phone Number"> {{row.mobile ? row.mobile : "-"}} </mat-cell>
          </ng-container>

          <!-- Color Column -->
          <ng-container matColumnDef="car_make" *ngIf="showCarDetail()">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Maker </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="Maker"> {{row.car_make? row.car_make : "-"}} </mat-cell>
          </ng-container>

          <!-- Color Colu`mn -->
          <ng-container matColumnDef="car_model" *ngIf="showCarDetail()">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Model </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="Model">{{row.car_model ? row.car_model : "-"}}</mat-cell>
          </ng-container>

          <!-- Color Column -->
          <ng-container matColumnDef="vehicle" *ngIf="showCarDetail()">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="Vehicle">Vehicle </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="Vehicle" data-val="Vehicle">
              <i class="fa fa-info-circle pointer text-red" (click)="handleOpenViewCarAndCustomerPopUp(row,1)"></i>
              {{ row.vehicle ? row.vehicle : "-" }}
            </mat-cell>
          </ng-container>

          <!-- Color Column -->
          <ng-container matColumnDef="car_color" *ngIf="showCarDetail()">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="color"> Color </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="color" data-val="Color"> {{row.car_color ? row.car_color : "-"}} </mat-cell>
          </ng-container>
 
          <ng-container matColumnDef="unit_number" *ngIf="showCarDetail()">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="Unit-Number"> Unit Number </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="Unit-Number" data-val="Unit Number">{{ row.unit_number ? row.unit_number : "-" }}</mat-cell> 
          </ng-container>


          <ng-container matColumnDef="car_license_plate" *ngIf="showCarDetail()">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> License Plate </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto"  data-val="License Plate"> {{row.car_license_plate ? row.car_license_plate : "-"}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="vin" *ngIf="showCarDetail()">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Vin Number </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto"  data-val="Vin Number"> {{row.vin ? row.vin : "-"}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="state" *ngIf="showCarDetail()">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> State </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="State"> {{row.state ? row.state : "-"}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="email" *ngIf="!showCarDetail()">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Email </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" class="link" data-val="Email"><a href="mailto:{{row.email}}">{{row.email}}</a> </mat-cell>
          </ng-container>

          <ng-container matColumnDef="address" *ngIf="!showCarDetail()">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Address </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="Address">{{row.address ? row.address : "-"}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="city" *ngIf="!showCarDetail()">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> City </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="City">{{ row.city ? row.city : "-" }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="state" *ngIf="!showCarDetail()">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> State </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="State"> {{row.state ? row.state : "-"}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="country" *ngIf="!showCarDetail()">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Country </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="Country"> {{row.country ? row.country : '-'}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="mobile" *ngIf="!showCarDetail()">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="phone"> Mobile No. </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="phone" class="link" data-val="Mobile No."> <a class="text-black" href="tel:{{row.mobile}}">{{row.mobile}}</a> </mat-cell>
          </ng-container>

          <!-- Color Column -->
          <ng-container matColumnDef="Actions" *ngIf="showCarDetail()">
            <mat-header-cell *matHeaderCellDef  data-role="button"> Actions </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="button">
              <button mat-button class="list-action-btn" [matMenuTriggerFor]="menu" disableRipple >
                Action<mat-icon>arrow_drop_down</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="car-icon-menu" [overlapTrigger]="false">
                <!-- <button class="mat-menu-item" (click)="viewCarHistory(row.customer_id,row.car_id)"><i class="fa fa-list"></i>View History </button> -->
                <button class="mat-menu-item" (click)="openServiceHistoryPopup(row)">
                  <!-- <i class="fa fa-list"></i> -->
                  <img [src]="gc.SERVICE_HISTORY_ICON" style="width:16px; margin-right:5px;" />
                  View History 
                </button>
                <button class="mat-menu-item" (click)="viewCar(row.customer_id,row.car_id)"><i class="fa fa-file-text"></i>View</button>
                <!-- <button mat-menu-item [disabled]="disableEdit" (click)="editCar(row.customer_id,row.car_id,row.country)"><i class="fa fa-edit" ></i>Edit</button> -->
                <button class="mat-menu-item" (click)="deleteCar(row.car_id,row.customer_id)"><i class="fa fa-trash"></i>Delete</button>
                <button class="mat-menu-item" *ngIf="isAllowed('addNew','service_request')" (click)="carNewRequest(row.car_id,row.customer_id,row.vin)"><i class="fa fa-plus"></i>New Request</button>
              </mat-menu>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Actions" *ngIf="!showCarDetail()">
            <mat-header-cell *matHeaderCellDef  data-role="button"> Actions </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="button">
              <button mat-button class="list-action-btn" [matMenuTriggerFor]="menu" disableRipple >
                Action<mat-icon>arrow_drop_down</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="car-icon-menu" [overlapTrigger]="false">
                <!-- <a mat-menu-item [disabled]="disableEdit" (click) = "editCustomer(row.shop_id)"><i class="fa fa-edit"></i>Edit</a> -->
                <a class="mat-menu-item" (click) = "addCar(row.shop_id, row.country_id)"><i class="fa fa-car"></i>Add Car</a>
                <a class="mat-menu-item" (click) = "listCars(row.shop_id)"><i class="fa fa-list-alt"></i>View Cars</a>
                <a class="mat-menu-item" *ngIf="isAllowed('addNew','service_request')" (click) = "makeNewRequest(row.shop_id)"><i class="fa fa-wrench"></i>New Service Request</a>
                <a class="mat-menu-item" *ngIf="isAllowed('views','service_request')" (click) = "viewAllServiceRequests(row.name,row.customer_id)"><i class="fa fa-list-alt"></i> View All Requests</a>
              </mat-menu>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <!-- DISPLAY RESULTS FOR OTHERS -->

        <div class="padding-15 text-center"
        [style.display]="getTotalRecords() < 1 ? '' : 'none'">
        No Records Available
      </div>
    </div>
    <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
      <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event)" >
      </mat-paginator>

      <div class="mat-paginator table-paging">
        <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
        <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
          <mat-icon>navigate_before</mat-icon>
        </button>
        <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
          <mat-icon>navigate_next</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
</div>
