<div class="container">
<div class="row bgcolor-lightgrey" id="contactus-thanks-msg" *ngIf="thanksMsg" >    
    <div class="col-xs-12 col-sm-12 thanks-msg text-center">
      <h2  class="fontcolor-blue main-heading margin-bottom-25">Thank you</h2>     
      <h3>
        {{message}}
      </h3>
      <a class="btn uc-btn-default"  [routerLink]="['/customer']" style="background: #f50057; color: #fff;min-width: 130px;">Login Here</a>
    </div>
  </div>  
</div>