import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
@Injectable()
export class TitleService {
    private count=0;
    private title='CAR :: Complete Auto Reports';
    constructor() {
        this.init();
    }
    setTitle(title:any) {
        this.title=title;
        document.title=title;
    }
    init(){
        let th=this;
        let observer = new MutationObserver(mutations => {
            mutations.length && mutations.forEach(function (mutation) {
                if (mutation.type == 'childList' && mutation.target.nodeName=='TITLE') {
                    if(th.title!=window.document.title){
                        document.title=th.title;
                    }
                }
            })          
        });
        var config = { subtree: true,attributes: true, childList: true, characterData: true };
        observer.observe(document.head, config);
    }
}
