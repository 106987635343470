import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../environments/global.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-notificationhandel',
  templateUrl: './notificationhandel.component.html'
})
export class NotificationhandelComponent implements OnInit {

  notify: any = {};
  defultUrl = "shops/dashboard";
  constructor(public gs: GlobalService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    let urlData: any = null;
    let data = this.gs.localGetter('handel', 'notification-');
    if (data) {
      this.notify = JSON.parse(data);
    } else {
      this.notify = null;
    }

    if (this.notify && this.notify.notification_payload) {
      urlData = JSON.parse(this.notify.notification_payload);
    }

    if (this.notify && this.notify.module_name == "part" && this.notify.module_action == "PartsInventory") {
      this.gs.localGetterSetter('search_by', '5', 'shop-part-');
      this.gs.localGetterSetter('search_date', urlData.notify, 'shop-part-');
      // urlData.redirect_url="shops/list-parts/"+urlData.notify;
      urlData.redirect_url="shops/list-parts";
    }
    if (urlData && typeof urlData.redirect_url != 'undefined' && urlData.redirect_url != "" && urlData.redirect_url) {
      this.router.navigateByUrl(urlData.redirect_url);
      this.gs.localGetterSetter('handel', '', 'notification-');
    } else {
      this.router.navigateByUrl(this.defultUrl);
    }
  }

}
