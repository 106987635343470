import { Component, OnInit, ViewChild } from '@angular/core';
import { ViktableComponent, VikSource } from '../../../admin/viktable/viktable.component';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
const UPLOAD_LIST = "part-upload-";
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-partuploadlist',
  templateUrl: './partuploadlist.component.html',
  styleUrls: ['./partuploadlist.component.scss']
})
export class PartuploadlistComponent extends ViktableComponent implements OnInit {

  displayedColumns = ['name', 'status','uploaded_by','date_formate', 'Actions'];
  public status=['Pending','Uploaded for import','Processing','Finished'];
  public tablesData = this;
  // dataSource: any;
  url = "";
  public options = {
    user_type: "User",
    user_id: "",
    search_keyword: "",
    start: 0,
    limit: 10
  }
  public user:any={};
  public partFile=globalConstants.S3_BUCKET_URL+'Parts-Spreadsheet-Temp/';
  public isInventory=false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<partUploadData> = new MatTableDataSource();
  public record :any =[];
  public recordsTotal: any =[];
  constructor(public gs: GlobalService, public router: Router) {
    super(globalConstants.API_PART_BULK_UPLOAD_LIST, gs);
    this.user=this.gs.getCurrentUser();
    if (this.user) {
      this.sessionKey = UPLOAD_LIST;
      this.url = globalConstants.API_PART_BULK_UPLOAD_LIST;
      this.gs.setMetaData("SHOPS", "IMPORT_PARTS_LIST")
    } else {
      this.router.navigate(['shops']);
    }
   }

  ngOnInit() {
    this.reinitializeOptions();
    this.isInventory = this.gs.isInventories();
    if (!this.isInventory) {
      this.router.navigate(['shops/action/forbidden']);
    }
    this.setDataTable();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  /** init options */
  reinitializeOptions() {
    this.options = this.gs.localGetterSetter(this.options, this.options, UPLOAD_LIST);
    this.options.user_type = this.user.user_type;
    this.options.user_id = this.user.id;
  }
  /** set data source of import list */
  setDataTable() {
    var content = this.options;
    this.detailData={};
    this.resultData=[];
    this.record=[];
    this.isGetData=false
    var callApi = this.gs.callAPI(this.url, content);
    callApi.subscribe((data:any) => {
      this.isGetData=true;
      if(this.returnData && data[this.returnData]){
        this.detailData=data[this.returnData];
      }
      if (data.data != undefined) {
        let moreRows = 'false';
        let firstList = 'false';
        this.gs.localGetterSetter("totalRecords", data.recordsTotal, this.sessionKey);
        if (data.more_rows == "true") {
          moreRows = "true";
        }
        this.gs.localGetterSetter("more_rows", moreRows, this.sessionKey);
        if (data.start >= 0 && data.start <= 5) {
          firstList = "true";
        }
        this.gs.localGetterSetter("first_list", firstList, this.sessionKey);
        if (data.data.length != undefined) { 
          this.recordsTotal = data.data.length;
          for (var i = 0; i < data.data.length; i++) {
            this.AddRecord(data.data[i]);
          }
          this.dataSource = new MatTableDataSource(this.record);
          this.dataSource.sort = this.sort;
        } else {
          this.recordsTotal = "1";
          this.AddRecord(data.data);
        }
        this.gs.localGetterSetter(null, this.options, this.sessionKey);
      } else {
        this.dataSource = new MatTableDataSource(this.record);
        this.gs.localGetterSetter("totalRecords", '0', this.sessionKey);
      }
    },(error)=>{
      this.dataSource = new MatTableDataSource(this.record);
      this.gs.localGetterSetter("totalRecords", '0', this.sessionKey);
    })
  }

  AddRecord(record){
    var data = this.createRecordrecord(record);
      this.record.push(data);
  }
  /** create record, that show on import list */
  createRecordrecord(record) {
    return {
      id: record.id,
      name: record.file_name,
      status : this.showStatus(record.status),
      date_formate:this.gs.getFullFormateDate(record.add_date,'G','H'),
      added_on: record.add_date,
      csv_url : record.csv_url,
      uploaded_by :record.user_name
    };
  }
  /** sort import list */
  sortedData(): any[] {
    let th: any = this;
    const data = th._exampleDatabase.data.slice();
    if (!th._sort.active || th._sort.direction == '') { return data; }
    return data.sort((a, b) => {
      let propertyA;
      let propertyB;
      switch (th._sort.active) {
        case 'Name': propertyA = a.name; propertyB = b.name; break;
        case 'Create At': propertyA = a.added_on; propertyB = b.added_on; break;
        default: return 0;
      }
      let valueA = isNaN(propertyA) ? propertyA.toUpperCase() : propertyA;
      let valueB = isNaN(propertyB) ? propertyB.toUpperCase() : propertyB;

      return (valueA < valueB ? -1 : 1) * (th._sort.direction == 'asc' ? 1 : -1);
    });
  }
  /** return import file status */
  showStatus(s){
    return (typeof this.status[s] == 'undefined'?'N/A' : this.status[s]);
  }
  /** navigate to import detail page */
  viewImport(row){
    this.gs.localGetterSetter(this.options,null, UPLOAD_LIST);
    this.router.navigate(['shops/parts/import-detail/'+row.id]);
  }
 /** goto part upload file */
 bulkUpload(){
  this.router.navigate(['shops/parts/import-export']);
 }
 /** download uploaded csv */
 downLoadCsv(name){
   let url=this.partFile+name;
   this.gs.downloadAsset(url,name);
 }
}

export interface partUploadData {
  id:any,
  uploaded_by:any,
  status:any,
  date_formate:any
}