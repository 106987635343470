<div class="panel panel-primary margin-0" id="mainCompotents">
  <div class="panel-heading padding-left-15 slaes-wrapper">
     <div class="row">
          <div class="col-xs-8">
              <h3 class="panel-title">Sales Report</h3>
          </div>
          <div class="col-xs-4">
              <div class="" style="text-align: right;">
                  <button [matMenuTriggerFor]="menu" class="settingsIcon text-white"><i class="fa fa-cog"></i></button>
                  <mat-menu #menu="matMenu" class="mat-dropdown-menu business-metrics" [overlapTrigger]="false" xPosition="before">
                      <button mat-menu-item routerLink="advance-report">Advanced Report</button>
                      <button mat-menu-item routerLink="business-metrics">Business Metrics</button>                
                      <button mat-menu-item>Sales Report</button>
                  </mat-menu>
                  <button class="settingsIcon text-white fa fa-filter" title="Filters" type="button" (click)="searchFilterSideBar = true">
                  </button>
              </div>
          </div>
     </div>
  </div>
  <div class="panel-body bgcolor-lightgrey">
      <div class="total-count-section">
          <div class="row">
            <div class="col-sm-3 col-xs-7 uc-ngx-select" style="float:right; text-align:right;">
              Cash Basis: <mat-slide-toggle class="switch-green" [checked]="true" (change)="switchChange($event)"></mat-slide-toggle>
            </div>
          </div>
          <div class="row">
              <div class="col-sm-3 link">
                <mat-card class="card-default">
                    <div class="infoTile"  >
                        <strong [innerHTML]="total_tickets_count"></strong>
                        <p>TOTAL TICKETS</p>
                    </div>
                </mat-card>
              </div>
              <div class="col-sm-3 link">
                <mat-card class="card-default">
                    <div class="infoTile" >
                        <strong [innerHTML]="'$ '+decimalTwoDigit(total_sales)"></strong>
                        <p>TOTAL SALES</p>
                    </div>
                </mat-card>
              </div>
              <div class="col-sm-3 link">
                  <mat-card class="card-default">
                      <div class="infoTile"  >
                          <strong [innerHTML]="'$ '+decimalTwoDigit(total_revenue)"></strong>
                          <p class="text-red">TOTAL REVENUE COLLECTED*</p>
                      </div>
                  </mat-card>
              </div>
              <div class="col-sm-3 link">
                <mat-card class="card-default">
                    <div class="infoTile">
                        <strong [innerHTML]="'$ '+decimalTwoDigit(total_tax)"></strong>
                        <p class="text-red">TOTAL TAX COLLECTED**</p>
                    </div>
                </mat-card>
              </div>
              <div class="col-sm-3 link">
                <mat-card class="card-default">
                    <div class="infoTile"  >
                        <strong [innerHTML]="'$ '+decimalTwoDigit(total_labor)"></strong>
                        <p>TOTAL LABOR COLLECTED</p>
                    </div>
                </mat-card>
              </div>
              <div class="col-sm-3 link">
                <mat-card class="card-default">
                    <div class="infoTile" >
                        <strong [innerHTML]="'$ '+decimalTwoDigit(avg_sales_day)"></strong>
                        <p>AVERAGE SALE IN A DAY</p>
                    </div>
                </mat-card>
              </div>
              <div class="col-sm-3 link">
                  <mat-card class="card-default">
                      <div class="infoTile"  >
                          <strong [innerHTML]="'$ '+decimalTwoDigit(avg_sales_week)"></strong>
                          <p>AVERAGE SALE IN A WEEK</p>
                      </div>
                  </mat-card>
              </div>
              <div class="col-sm-3 link">
                <mat-card class="card-default">
                    <div class="infoTile"  >
                        <strong [innerHTML]="'$ '+decimalTwoDigit(avg_sales_month)"></strong>
                        <p>AVERAGE SALE IN A MONTH</p>
                    </div>
                </mat-card>
              </div>
          </div>
          <div class="row">
            <div class="col-sm-12 averageSalesNote help-block" *ngIf="(selected == '3' && avg_sales_notes !=null) || (selected == '4' && avg_sales_notes !=null)">
              <b>Note : </b>{{avg_sales_notes}}
            </div>
            <div class="col-sm-12 help-block">
              <span class="text-red">* Tax Excluded.</span><br>
              <span class="text-red">
                ** Total tax collected refers to calculated tax per payment made within the date criteria as it refers to each CAR invoice.
                Customers, parts, generic jobs, or invoices that are not taxed will be accurately reflected here.
                However, the accuracy of the data is dependent on the accuracy of information input.
                CAR assumes NO LIABILITY for misrepresenting taxes through invoices. \
                It is the responsibility of the facility to consult with an accountant to review their respective tax laws.
              </span>
            </div>
          </div>
      </div>
  </div>
  <div class="panel panel-primary sales-report-wrapper margin-15 no-shadow">
    <div class="panel-heading relative service-heading">
      <h3 class="panel-title">Sales / Service Requests</h3>
      <div class="green-select uc-ngx-select">
        <form>
            <ngx-select class="display-full margin-bottom-20 bgcolor-white" [items]="chatType" (select)="changeSalesChartType()"
            placeholder="" [noAutoComplete]="true" [(ngModel)]="selectedSales" [ngModelOptions]="{standalone: true}">
          </ngx-select>
        </form>
      </div>
    </div>
    <div class="panel-body bgcolor-lightgrey border border-top-0">
      <div class="chart"  *ngIf="showChart">
          <div class="col-sm-6">
        <google-chart [data]="salesData"></google-chart>
      </div>
        <div class="col-sm-6">
        <google-chart [data]="ServiceRequestData"></google-chart>
      </div>
      </div>
    </div>
  </div>
  <div class="panel panel-primary sales-report-wrapper margin-15 hide">
    <div class="panel-heading relative">
      <h3 class="panel-title">Service Requests</h3>
      <div class="green-select uc-ngx-select">
        <form>
            <ngx-select class="display-full margin-bottom-20 bgcolor-white" [items]="chatType" (select)="changeServiceRequestChartType()"
            placeholder="" [noAutoComplete]="true" [(ngModel)]="selectedService" [ngModelOptions]="{standalone: true}">
          </ngx-select>
        </form>
      </div>
    </div>
    <div class="panel-body bgcolor-lightgrey border border-top-0">
      <div class="chart">
        
      </div>
    </div>
  </div>
  <div class="panel panel-primary sales-report-wrapper margin-15 no-shadow">
    <div class="panel-heading relative">
      <h3 class="panel-title">Parts</h3>
      <button type="button" name="btncsv" class="btn hide btn-success btn-download-csv"  title="Download CSV File" (click)="downloadPartCSV()">Download CSV File</button>
      <div class="green-select uc-ngx-select">
        <form>
            <ngx-select class="display-full margin-bottom-20 bgcolor-white" [items]="chatType" (select)="changePartChartType()"
            placeholder="" [noAutoComplete]="true" [(ngModel)]="selectedPart" [ngModelOptions]="{standalone: true}">
          </ngx-select>
        </form>
      </div>
    </div>
    <div class="panel-body bgcolor-lightgrey border border-top-0">
      <div class="chart" *ngIf="showChart">
        <div class="col-sm-6">
          <google-chart [data]="PartData"></google-chart>
        </div>
        <div class="col-sm-6">
          <google-chart [data]="partSalesByQty"></google-chart>
        </div>
        <div class="col-sm-6">
          <google-chart [data]="partSalesByPercentProfit"></google-chart>
        </div>
        <div class="col-sm-6">
          <google-chart [data]="partSalesByAmountProfit"></google-chart>
        </div>
      </div>
    </div>
  </div>
  <div class="panel panel-primary sales-report-wrapper margin-15 no-shadow">
  <div class="panel-heading relative">
      <h3 class="panel-title">SERVICE REQUESTS LIST</h3>
      <span *ngIf="isActive">
      <button type="button" name="btncsv" class="btn btn-success btn-download-csv"  title="Download CSV File" (click)="downloadCSV()"><mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color visible-xs" aria-hidden="true" data-mat-icon-type="font">cloud_download</mat-icon><span class="hidden-xs">Download CSV File</span></button>
      </span>
      <span *ngIf="!isActive">
        <button type="button" name="btncsv" class="btn btn-success btn-download-csv disabled"  title="Download CSV File">Download CSV File</button>
      </span>
  </div>
  <div class="panel-body bgcolor-lightgrey border border-top-0">
      <div class="car-datatable mat-elevation-z1">
          <div class="table-responsive relative">
              <mat-table #table [dataSource]="dataSource" matSort class="service-list datatable-loader-bg">

                  <!-- Color Column -->
                  <ng-container matColumnDef="request_id">
                      <mat-header-cell *matHeaderCellDef mat-sort-header  data-role="id"> Req. Id </mat-header-cell>
                      <mat-cell *matCellDef="let row" data-role="id"> {{row.request_id}} </mat-cell>
                  </ng-container>

                  <!-- Color Column -->
                  <ng-container matColumnDef="title">
                      <mat-header-cell *matHeaderCellDef mat-sort-header > Title </mat-header-cell>
                      <mat-cell *matCellDef="let row" > {{row.title ? row.title : 'N/A'}} </mat-cell>
                  </ng-container>

                  <!-- Color Column -->
                  <ng-container matColumnDef="customer_name">
                      <mat-header-cell *matHeaderCellDef mat-sort-header > Customer Name </mat-header-cell>
                      <mat-cell *matCellDef="let row" > {{row.customer_name}} </mat-cell>
                  </ng-container>

                  <!-- Color Column -->
                  <ng-container matColumnDef="vehicle">
                      <mat-header-cell *matHeaderCellDef mat-sort-header > Vehicle </mat-header-cell>
                      <mat-cell *matCellDef="let row" > {{row.vehicle}} </mat-cell>
                  </ng-container>

                  <!-- Color Column -->
                  <ng-container matColumnDef="appointment_date">
                      <mat-header-cell *matHeaderCellDef mat-sort-header > App. Date </mat-header-cell>
                      <mat-cell *matCellDef="let row" >{{row.appointment_date}} </mat-cell>
                  </ng-container>

                  <!-- Color Column -->
                  <ng-container matColumnDef="service_date">
                      <mat-header-cell *matHeaderCellDef mat-sort-header > Service Date </mat-header-cell>
                      <mat-cell *matCellDef="let row" >{{row.service_date}} </mat-cell>
                  </ng-container>

                  <!-- Color Column -->
                  <ng-container matColumnDef="estimated_delivery_date">
                      <mat-header-cell *matHeaderCellDef mat-sort-header > Est. Delivery Date </mat-header-cell>
                      <mat-cell *matCellDef="let row"  >{{row.estimated_delivery_date}} </mat-cell>
                  </ng-container>

                  <!-- Color Column -->
                  <ng-container matColumnDef="status">
                      <mat-header-cell *matHeaderCellDef mat-sort-header data-role="status"> Status </mat-header-cell>
                      <mat-cell *matCellDef="let row" data-role="status"> {{row.status}}</mat-cell>
                  </ng-container>

                  <!-- Color Column -->

                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;" class="mat-row {{getRowStatusColor(row.check_status)}}"></mat-row>
              </mat-table>
              <div class="padding-15 text-center"
                   [style.display]="getTotalRecords() < 1 ? '' : 'none'">
                   No Records Available
          </div>
      </div>
      <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
           <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="options.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event)" >
          </mat-paginator>

          <div class="mat-paginator table-paging">
              <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
              <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                      <mat-icon>navigate_before</mat-icon>
              </button>
              <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                      <mat-icon>navigate_next</mat-icon>
              </button>
          </div>
      </div>
  </div>
</div>
</div>
<app-search-filter [data]="options" [type]="'salesReport'" (closeTab)="searchFilterOutput($event)" *ngIf="searchFilterSideBar"></app-search-filter>