
<!-- Popup view for this component -->
<ng-container>
  <div bsModal class="component-popup">
    <div class="modal-header">
      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-6">
          <h4 class="modal-title pull-left margin-10 margin-left-15">Sublet Job History</h4>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6">
          <button type="button" class="close" aria-label="Close" (click)="toggleState(3)">
            <span aria-hidden="true">&times;</span>
          </button>
          <button type="button" class="close" (click)="toggleState(2)">
            <span aria-hidden="true">&minus;</span>
          </button>
        </div>
      </div>
    </div>
    <div class="modal-body service-history">
      <ng-container *ngTemplateOutlet="historyViewDetails"></ng-container>
    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Close</button>
    </div> -->
  </div>
  
  <!-- Material modal popup code -->
  <!-- <div mat-dialog-title class="relative">
    Create Coupon Code
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
      <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="center-block">
      <div id="addNewDiscount" class="row bgcolor-lightgrey form-content">
        <div class="container-fluid">
          <ng-container *ngTemplateOutlet="historyViewDetails"></ng-container>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="inquiry-action relative">
    <div class="row sticky-section">
      <div class="btn-wrap col-sm-2 col-lg-4 col-md-4 col-xs-12 text-right">
        <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
      </div>
      <div class="btn-wrap col-sm-10 col-lg-8 col-xs-12 col-md-8 text-left">
      </div>
    </div>
  </mat-dialog-actions> -->
</ng-container>

<ng-template #historyViewDetails>  
  <mat-expansion-panel hideToggle="false" [expanded]="historyDetailsPanel" class="vehicle-owner-details relative mat-elevation-z1"  *ngIf="(isAdvancedSearch && vehiclesList && vehiclesList.length > 0 && selectedCar) || (!isAdvancedSearch && backupCarId)">
    <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px">
      <strong>&nbsp;Vehicle/Owner Details</strong>
    </mat-expansion-panel-header>
    <div class="row margin-5">
      <div class="col-md-12 padding-left-5 padding-right-5">
        <form id="viewServiceRequestForm" class="form-horizontal" role="form" *ngIf="serviceInfo">
          <!-- Replaced div grid structure with table -->
          <div class="row display-full">
            <div class="col-sm-6 margin-bottom-10">
              <div class="row">
                <div class="col-sm-6 col-xs-6">
                  <label>Vehicle :&nbsp;&nbsp;
                  </label>
                </div>
                <div class="col-sm-6 col-xs-6">
                  {{this.serviceInfo.year}}/{{this.serviceInfo.make}}/{{this.serviceInfo.model}}
                  <span *ngIf="vehicle_pic ==null || vehicle_pic ==undefined || vehicle_pic.length == 0">
                    <span *ngIf="this.serviceInfo.year !=undefined && this.serviceInfo.make !=undefined && this.serviceInfo.model !=undefined">
                      {{vehicleName}}
                    </span>
                  </span>
                  <div *ngIf="vehicle_pic !=null && vehicle_pic !=undefined && vehicle_pic.length != 0">
                  <span class="tooltip-status" [tooltip]="myTooltip" tooltipPlacement="bottom" (click)="openImageDialog(1);">{{this.serviceInfo.year}}/{{this.serviceInfo.make}}/{{this.serviceInfo.model}}</span>
                  <tooltip-content #myTooltip class="image-tooltip">
                      <img src="{{globalConstants.S3_BUCKET_URL}}{{globalConstants.VEHICLE_PICTURES}}{{globalConstants.IMG_ORIGINAL}}{{vehicle_pic}}" class="img-responsive">
                  </tooltip-content>
                  </div>
                  <!-- <p id="expMsgs" class="text-danger" style="font-size:11px;margin-top: 5px;margin-bottom: 0;">
                    <span *ngIf="chkDate(serviceInfo.insurance_expiration_date)">Vehicle Insurance Expired -{{serviceInfo.insurance_expiration_date}}<br></span>
                    <span *ngIf="chkDate(serviceInfo.registration_expiration_date)">Vehicle Registration Expired - {{serviceInfo.registration_expiration_date}}<br></span>
                  </p> -->
                </div>
              </div>
            </div>
            <div class="col-sm-6 margin-bottom-10">
              <div class="row">
                <div class="col-sm-6 col-xs-6"><label>License Plate :</label></div>
                <div class="col-sm-6 col-xs-6 break-word text-uppercase">
                  {{this.serviceInfo.license_plate || 'N/A'}}
                </div>
              </div>
            </div>
          </div>
          <div class="row display-full">
            <div class="col-sm-6 margin-bottom-10">
              <div class="row">
                <div class="col-sm-6 col-xs-6">
                  <label>Customer Name :&nbsp;&nbsp;
                    <!-- <span class="tooltip-status" (click)="detailTab(2)">
                      <i class="fa fa-info-circle"></i>
                    </span> -->
                  </label>
                </div>
                <div class="col-sm-6 col-xs-6">
                  {{this.serviceInfo.first_name}} {{this.serviceInfo.last_name}}
                </div>
              </div>
            </div>
            <div class="col-sm-6 margin-bottom-10">
              <div class="row">
                <div class="col-sm-6 col-xs-6"><label>VIN Number :&nbsp;&nbsp;<span class="tooltip-status" (click)="copyText()"><i class="fa fa-copy"></i></span></label></div>
                 <!-- *ngIf="this.serviceInfo.vin!=undefined" -->
                <div class="col-sm-6 col-xs-6 break-word text-uppercase" *ngIf="this.serviceInfo.vin">
                  {{this.serviceInfo.vin.substr(0,8)}}<strong style="color:#eb1e4c">{{this.serviceInfo.vin.substr(9)}}</strong>
                </div>
                <div class="col-sm-6 break-word text-uppercase" *ngIf="!this.serviceInfo.vin">
                  "--"
                </div>
              </div>
            </div>
          </div>
          <div class="row display-full">
            <div class="col-sm-6 margin-bottom-10">
              <div class="row">
                <div class="col-sm-6 col-xs-6"><label>Mobile Number :</label></div>
                <div class="col-sm-6 col-xs-6">
                  <a class="text-black" *ngIf="isAllowed('views','view_customer_info')" href="tel:{{this.serviceInfo.mobile_phone}}">{{this.serviceInfo.mobile_phone}}</a>
                  <span *ngIf="isAllowed('views','view_customer_info')==false">-</span>
                </div>
              </div>
            </div>
            <div class="col-sm-6 margin-bottom-10">
            </div>
          </div>
        </form>
      </div>
    </div>
  </mat-expansion-panel>

  <!-- Issue: Car-370 -> showing Service Requests without vehicle id Line Changes 392-->
  <ng-container *ngIf="(!isAdvancedSearch && backupCarId) || (isAdvancedSearch)">
    <!-- Normal Search -->
    <div class="display-full relative">
      <mat-expansion-panel hideToggle="false" [expanded]="openSearch" class="search-filter search-employee relative mat-elevation-z1">
          <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px">
            <strong><i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
          </mat-expansion-panel-header>
          <form id="advanceSearchForm" class="collapse in" aria-expanded="true" (ngSubmit)="search($event)">
              <div class="panel-body">
                <div class="row flex-row">
                  <div class="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                  <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                    <mat-select class="form-control input-lg custom-form-input"
                      options="searchByArray"
                      [(ngModel)]="options.search_by"
                      [ngModelOptions]="{standalone: true}"
                      placeholder="Search By">
                      <mat-option  *ngFor="let opt of searchByArray" [value]="opt.id">{{opt.text}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  </div>
                  <div class="col-xs-12 col-sm-4 col-md-3 col-lg-3" [class.hide]="(options.search_by == '4')">
                    <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                      <input  matInput placeholder="Search Keyword" title="Search Keyword" [(ngModel)]="options.search_keyword" [ngModelOptions]="{standalone: true}" class="form-control input-lg custom-form-input" />
                    </mat-form-field>
                  </div>
                  <div class="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                    <mat-form-field class="car-input btn-block car-datePicker"  floatPlaceholder="never">
                      <input matInput class="form-control input-lg custom-form-input"  [(ngModel)]="fromDateModel" placeholder="From Date" [matDatepicker]="fromPicker" [ngModelOptions]="{standalone: true}"  onkeydown="return false;" (dateChange)="setToStartDate($event)">
                      <mat-datepicker-toggle matSuffix [for]="fromPicker" ></mat-datepicker-toggle>
                      <mat-datepicker #fromPicker></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                    <mat-form-field class="car-input btn-block car-datePicker"  floatPlaceholder="never">
                      <input matInput class="form-control input-lg custom-form-input"  [(ngModel)]="toDateModel" placeholder="To Date" [matDatepicker]="toPicker" [ngModelOptions]="{standalone: true}" [min]="toStartDate" onkeydown="return false;">
                      <mat-datepicker-toggle matSuffix [for]="toPicker" ></mat-datepicker-toggle>
                      <mat-datepicker #toPicker></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <mat-action-row class="search-filter-footer">
                <button mat-raised-button color="accent" type="submit" title="Search">
                  Search
                </button>
              </mat-action-row>
          </form>
      </mat-expansion-panel>
      <button mat-button type="button" title="Clear Filter" #clearBtn class="clear-btn" [class.active-search]="isAnyOptionActive(clearBtn)" (click)="clearSearch()">
        Clear Filter
      </button>
    </div>
    
    <div class="row flex-row hidden-xs" *ngIf="searchResults && searchResults.length > 0">
      <div class="col-md-6 col-sm-6">
        <p class="text-left expand-collapse">
          <span class="expand margin-right-10" (click)="expandAll('history')">
            <i class="fa fa-expand"></i> &nbsp; Expand All
          </span>
          <span class="collapse" (click)="collapseAll('history')">
            <i class="fa fa-compress"></i> &nbsp; Collapse All
          </span>
        </p>
      </div>
      <div class="col-md-6 col-sm-6">
        <div class="view-switcher pull-right" title="{{categoryBtnText}}">  
          <mat-slide-toggle class="switch-green" color="success" [checked]="isCategoryViewModel" (change)="toggleResultsView()">
            <span>Category View</span>
          </mat-slide-toggle>
        </div>
      </div>
    </div>
    
    <div class="row flex-row visible-xs" *ngIf="searchResults && searchResults.length > 0">
      <div class="col-sm-12 col-xs-12">
        <div class="row">
          <div class="col-xs-6">
            <span class="text-left expand-collapse">
              <span class="expand margin-right-10" (click)="expandAll('history')">
                <i class="fa fa-expand"></i>
                <span class="hidden-xs">&nbsp; Expand All</span>
              </span>
            </span>
            <span class="text-right expand-collapse">
              <span class="collapse" (click)="collapseAll('history')">
                <i class="fa fa-compress"></i>
                <span class="hidden-xs"> &nbsp; Collapse All</span>
              </span>
            </span>
          </div>
          <div class="col-xs-6">
            <div class="view-switcher pull-right display-full" title="{{categoryBtnText}}">  
              <mat-slide-toggle class="switch-green" color="success" [checked]="isCategoryViewModel" (change)="toggleResultsView()">
                <span>Category View</span>
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-xs-12">
      </div>
    </div>
    
    <!-- Results Section -->
    <div class="display-full relative results-section" *ngIf="searchResults && searchResults.length > 0">
      <mat-accordion #parentAccordion="matAccordion" class="new-request-expansion-history view-request show-price display-full" displayMode="flat" hideToggle="false" [multi]="true" >
        <mat-expansion-panel [expanded]="a==0" hideToggle="true"  *ngFor="let service_request of searchResults;let a=index" id="expansion-history-{{a+1}}">
          <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="expansionHead-history-{{a+1}}" (click)="setFocus=true;expansionOpen(a+1, 'history');" [attr.data-state]="a==0" >
            <ng-container *ngIf="isCategoryView">
              {{ service_request.category_name }}
            </ng-container>
            <ng-container *ngIf="!isCategoryView">
              <div class="row display-full hidden-xs" *ngIf="showCostPrice">
                <div class="col-sm-3 col-lg-3 col-md-3 text-left">
                  <span>
                    #{{service_request.car_request_id}}
                  </span>
                </div>
                <div class="col-sm-3 col-lg-3 col-md-3 text-center" *ngIf="service_request">
                  <span>
                    Shop Name: {{service_request.shop_name}}
                  </span>
                </div>
                <!-- <div class="col-sm-3 col-lg-3 col-md-3 text-center">
                  <span>
                    Mileage: {{ service_request.present_mileage || 'N/A' }}
                  </span>
                </div> -->
                <div class="col-sm-3 col-lg-3 col-md-3 text-center">
                  <span>
                    Total: $ {{ (service_request.grand_total | number:'1.2-2') || '0.0' }}
                  </span>
                </div>
                <div class="col-sm-3 col-lg-3 col-md-3 text-right padding-0">
                  <span class="hidden-xs">
                    Date: {{ getFormattedDate(service_request.schedule_service_date) || 'N/A' }}
                  </span>
                </div>
              </div>
              <div class="row display-full hidden-xs" *ngIf="!showCostPrice">
                <div class="col-sm-4 col-lg-4 col-md-4 text-left">
                  <span>
                    #{{service_request.car_request_id}}
                  </span>
                </div>
                <div class="col-sm-4 col-lg-4 col-md-4 text-center" *ngIf="service_request">
                  <span>
                    Shop Name: {{service_request.shop_name}}
                  </span>
                </div>
                <!-- <div class="col-sm-3 col-lg-3 col-md-3 text-center">
                  <span>
                    Mileage: {{ service_request.present_mileage || 'N/A' }}
                  </span>
                </div> -->
                <div class="col-sm-4 col-lg-4 col-md-4 text-right padding-0">
                  <span class="hidden-xs">
                    Date: {{ getFormattedDate(service_request.schedule_service_date) || 'N/A' }}
                  </span>
                </div>
              </div>
              <div class="row visible-xs">
                <div class="col-sm-6 col-xs-12">
                  <span class="pull-left">
                    #{{service_request.car_request_id}}
                  </span>
                  <span class="pull-left margin-top-5 total-text" [class.hide]="!showCostPrice">
                    Total: $ {{ (service_request.grand_total | number:'1.2-2') || '0.0' }}
                  </span>
                </div>
                <div class="col-sm-6 col-xs-12 margin-top-5 fs-12">
                  <span class="help-block-light margin-0 pull-left">
                    Date: {{ getFormattedDate(service_request.schedule_service_date) || 'N/A' }}
                  </span>
                  <span class="help-block-light margin-0 pull-right" *ngIf="service_request">
                    Shop Name: {{service_request.shop_name}}
                    <!-- Miles: {{ service_request.present_mileage || '0' }} -->
                  </span>
                </div>
              </div>
            </ng-container>
          </mat-expansion-panel-header>
          
          <div class="relative requests-section margin-top-10">
            <!-- Nested Expand/Collapse -->
            <div class="row hidden-xs w-100">
              <div class="col-md-6 col-sm-6">
                <p class="text-left expand-collapse">
                  <span class="expand margin-right-10" (click)="expandAll('nested-history',(a+1))">
                    <i class="fa fa-expand"></i> &nbsp; Expand All
                  </span>
                  <span class="collapse" (click)="collapseAll('nested-history',(a+1))">
                    <i class="fa fa-compress"></i> &nbsp; Collapse All
                  </span>
                </p>
              </div>
              <div class="col-md-6 col-sm-6">
              </div>
            </div>
            
            <div class="row visible-xs w-100 margin-bottom-10">
              <div class="col-sm-12 col-xs-12">
                <div class="row">
                  <div class="col-xs-6">
                    <span class="text-left expand-collapse">
                      <span class="expand margin-right-10" (click)="expandAll('nested-history',(a+1))">
                        <i class="fa fa-expand"></i>
                        <span class="hidden-xs">&nbsp; Expand All</span>
                      </span>
                    </span>
                    <span class="text-right expand-collapse">
                      <span class="collapse" (click)="collapseAll('nested-history',(a+1))">
                        <i class="fa fa-compress"></i>
                        <span class="hidden-xs"> &nbsp; Collapse All</span>
                      </span>
                    </span>
                  </div>
                  <div class="col-xs-6">
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-xs-12">
              </div>
            </div>
            
            <ng-container *ngIf="!isCategoryView">
              <ng-container [ngTemplateOutlet]="subItemsList" [ngTemplateOutletContext]="{list: service_request.sub_list, recommendation_saved: service_request.recommendation_saved, parent_index: (a+1)}"></ng-container>
            </ng-container>
            <ng-container *ngIf="isCategoryView">
              <ng-container [ngTemplateOutlet]="subItemsList" [ngTemplateOutletContext]="{list: service_request.sub_list, recommendation_saved: undefined, parent_index: (a+1)}"></ng-container>
            </ng-container>
            <p class="text-right load-more" *ngIf="service_request && service_request.sub_list && (service_request.sub_list.more_rows == 'true')">
              <!-- <span (click)="loadMoreRequests(service_request)">Load More...</span> -->
              <ng-container *ngIf="!isCategoryView">
                <button class="btn btn-gray btn-block" (click)="loadMoreRequests(service_request, {request_id: service_request.request_id})">Load More...</button>
              </ng-container>
              <ng-container *ngIf="isCategoryView">
                <button class="btn btn-gray btn-block" (click)="loadMoreRequests(service_request, {l1:service_request.level1_id, l2:service_request.level2_id, l3:service_request.level3_id})">Load More...</button>
              </ng-container>
            </p>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <p class="text-center load-more" *ngIf="historyListing && (historyListing.more_rows == 'true')">
        <!-- <span (click)="loadMoreSearches()">Load More...</span> -->
        <button class="btn btn-gray btn-block" (click)="loadMoreSearches()">Load More...</button>
      </p>
    </div>
    <div class="display-full relative results-section" *ngIf="searchResults && searchResults.length == 0">
      <p class="text-center margin-top-10"> No Records Available </p>
    </div>
  </ng-container>
  
  <ng-template #subItemsList let-list="list" let-recommendation_saved="recommendation_saved" let-parent_index = "parent_index">
    <mat-accordion class="new-request-expansion-nested-history view-request show-price display-full" displayMode="flat" hideToggle="false" [multi]="true" >
      <mat-expansion-panel [expanded]="a==0" hideToggle="true" *ngFor="let request of list.data;let a=index" id="expansion-nested-history-{{parent_index}}-{{a+1}}" [class.mat-bg-red]="(!isCategoryView && request.recommendations[0].approval_status == 2) || (isCategoryView && request.recommendations[0].approval_status == 2)">
        <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="expansionHead-nested-history-{{parent_index}}-{{a+1}}" (click)="setFocus=true;expansionOpen(a+1, 'nested-history-'+parent_index);" [attr.data-state]="a==0" >
          <ng-container *ngIf="!isCategoryView">
            <div class="row display-full hidden-xs">
              <div class="col-sm-6 col-lg-6 col-md-6 text-left">
                <span>
                  {{ request.category_name }}
                </span>
              </div>
              <div class="col-sm-6 col-lg-6 col-md-6 text-right">
                <span [class.hide]="!showCostPrice">
                  Total: $ {{ (request.recommendations[0].recom_total | number:'1.2-2') || '0.0' }}
                </span>
              </div>
            </div>
            <div class="row visible-xs">
              <div class="col-sm-12 col-xs-12">
                <span class="pull-left">
                  {{ request.category_name }}
                </span>
                <span class="pull-left margin-top-5 total-text" [class.hide]="!showCostPrice">
                  Total: $ {{ (request.recommendations[0].recom_total | number:'1.2-2') || '0.0' }}
                </span>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="isCategoryView">
            <div class="row display-full hidden-xs" *ngIf="showCostPrice">
              <div class="col-sm-3 col-lg-3 col-md-3 text-left">
                <span>
                  #{{request.car_request_id}}
                </span>
              </div>
              <div class="col-sm-3 col-lg-3 col-md-3 text-center" *ngIf="request">
                <span>
                  Shop Name: {{request.shop_name}}
                </span>
              </div>
              <!-- <div class="col-sm-3 col-lg-3 col-md-3 text-center">
                <span>
                  Mileage: {{ service_request.present_mileage || 'N/A' }}
                </span>
              </div> -->
              <div class="col-sm-3 col-lg-3 col-md-3 text-center">
                <span>
                  Total: $ {{ (request.recommendations[0].recom_total | number:'1.2-2') || '0.0' }}
                </span>
              </div>
              <div class="col-sm-3 col-lg-3 col-md-3 text-right padding-0">
                <span class="hidden-xs">
                  Date: {{ getFormattedDate(request.schedule_service_date) || 'N/A' }}
                </span>
              </div>
            </div>
            <div class="row display-full hidden-xs" *ngIf="!showCostPrice">
              <div class="col-sm-4 col-lg-4 col-md-4 text-left">
                <span>
                  #{{request.car_request_id}}
                </span>
              </div>
              <div class="col-sm-4 col-lg-4 col-md-4 text-center" *ngIf="request">
                <span>
                  Shop Name: {{request.shop_name}}
                </span>
              </div>
              <!-- <div class="col-sm-3 col-lg-3 col-md-3 text-center">
                <span>
                  Mileage: {{ service_request.present_mileage || 'N/A' }}
                </span>
              </div> -->
              <div class="col-sm-4 col-lg-4 col-md-4 text-right padding-0">
                <span class="hidden-xs">
                  Date: {{ getFormattedDate(request.schedule_service_date) || 'N/A' }}
                </span>
              </div>
            </div>
            <div class="row visible-xs">
              <div class="col-sm-6 col-xs-12">
                <span class="pull-left">
                  #{{request.car_request_id}}
                </span>
                <span class="pull-left margin-top-5 total-text" [class.hide]="!showCostPrice">
                  Total: $ {{ (request.recommendations[0].recom_total | number:'1.2-2') || '0.0' }}
                </span>
              </div>
              <div class="col-sm-6 col-xs-12 margin-top-5" *ngIf="request">
                <span [ngClass]="{'help-block-light fs-12' : (isCategoryView && request.recommendations[0].approval_status == 2), 'help-block': ((isCategoryView && request.recommendations[0].approval_status != 2)), 'margin-0 pull-left': true}" >
                  Date: {{ getFormattedDate(request.schedule_service_date) || 'N/A' }}
                </span>
                <span [ngClass]="{'help-block-light fs-12' : (isCategoryView && request.recommendations[0].approval_status == 2), 'help-block': ((isCategoryView && request.recommendations[0].approval_status != 2)), 'margin-0 pull-right': true}">
                  Shop Name: {{request.shop_name}}
                  <!-- Miles: {{ request.present_mileage || '0' }} -->
                </span>
              </div>
            </div>
          </ng-container>
          <!-- <span class="mat-expansion-indicator" style="color:#000000;"></span> -->
        </mat-expansion-panel-header>
        <!-- <div class="row margin-0 visible-xs">
          <div class="col-md-12">
            <span>
              Date: {{ getFormattedDate(service_request.schedule_service_date) || 'N/A' }} 
              Mileage: {{ request.present_mileage || 'N/A' }}
            </span>
          </div>
        </div> -->
        <ul class="service-list-items margin-bottom-0" *ngIf="request.recommendations && request.recommendations.length > 0">
          <li class="topPad">
            <ul class="service-list-sub-items no-style padding-left-0" >
              <ng-container *ngIf="!isCategoryView">
                <li  *ngIf="recommendation_saved == '1'">
                <!-- <li> -->
                  <div class="row margin-0">
                    <div *ngFor="let recommendation of request.recommendations;let j = index">
                      <div class="row margin-0">
                        <div class="col-sm-8 col-md-8 col-lg-10 padding-0">
                          <div class="row margin-0">
                            <!-- Parts -->
                            <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.parts.length > 0">
                              <div class="row form-group">
                                <label class="col-sm-3 col-lg-2 col-xs-3 text-left control-label text-bold">Part(s):</label>
                                <div class="col-sm-9 col-lg-10 col-xs-9 text-justify">
                                  <div class="addedPartsItems">
                                    <div class="partRow row hidden-xs" [class.text-orange]="part.partstech_cart_item==1 || part.partstech_order_item==1" style="padding-top:3px;" *ngFor="let part of recommendation.parts;let k = index">
                                      <div class="col-sm-8 pname break-all">
                                        <span *ngIf="part.part_number!=''">{{part.part_number}} </span>
                                        <span *ngIf="showCostPrice && hidePrices">($ {{part.cost}}) -</span>
                                        {{part.name}}
                                        <span *ngIf="part.description!=''"> - {{part.description}}</span>
                                      </div>
                                      <div class="col-sm-2 pprice "><span *ngIf="showCostPrice">$ {{ parseFloats(part.part_price)}}</span></div>
                                      <div class="col-sm-2 pquantity" [class.text-red]="isInventory && part.out_of_stock=='1'">
                                        <i *ngIf="isInventory" class="fa fa-info-circle" title="{{ stockInHand(part) }} "></i> {{part.quantity}}
                                        <!-- <button type="button" class="btn btn-xs btn-link text-red" (click)="addStockOrder(recommendation,part)"><i *ngIf="isInventory && part.out_of_stock=='1'" class="fa fa-cart-plus fa-lg" title="Add to stock order"></i></button> -->
                                      </div>
                                    </div>
                                    <div class="partRow row visible-xs" [class.text-orange]="part.partstech_cart_item==1 || part.partstech_order_item==1" style="padding-top:3px;" *ngFor="let part of recommendation.parts;let k = index">
                                      <div class="col-xs-12 pname break-all">
                                        <span *ngIf="part.part_number!=''">{{part.part_number}} </span>
                                        <span *ngIf="showCostPrice && hidePrices">($ {{part.cost}}) -</span>
                                        {{part.name}}
                                        <span *ngIf="part.description!=''"> - {{part.description}}</span>
                                      </div>
                                      <div class="col-xs-6 pprice "><span *ngIf="showCostPrice">$ {{ parseFloats(part.part_price)}}</span></div>
                                      <div class="col-xs-6 pquantity" [class.text-red]="isInventory && part.out_of_stock=='1'">
                                        <i *ngIf="isInventory" class="fa fa-info-circle" title="{{ stockInHand(part) }} "></i> {{part.quantity}}
                                        <!-- <button type="button" class="btn btn-xs btn-link text-red" (click)="addStockOrder(recommendation,part)"><i *ngIf="isInventory && part.out_of_stock=='1'" class="fa fa-cart-plus fa-lg" title="Add to stock order"></i></button> -->
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-2">
                          <div class="row margin-0 hidden-xs text-right">
                            <span class="btn-copy-paste devices hidden-xs pull-right" *ngIf="recommendation_saved == '1' && isCopyEnable(request)" (click)="copyJob($event,request)">
                              <i class="fa fa-copy"></i> Copy Job
                            </span>
                          </div>
                        </div>
                      </div>
                      <!-- For each section of data -->
                      <div class="row margin-0 margin-top-10">
                        <div class="col-sm-8 col-md-8 col-lg-10 padding-0">
                          <div class="row margin-0">
                            <!-- Estimated Labor -->
                            <div class="col-sm-12 margin-bottom-15" *ngIf="showCostPrice">
                              <div class="row form-group">
                                <label class="col-sm-3 col-xs-3 col-lg-2 text-left control-label text-bold">Estimated Labor:</label>
                                <div class="col-sm-9 col-lg-10 col-xs-9 text-justify break-all">
                                  <div class="addedComputerCodes">$ {{ parseFloats(recommendation.estimated_labor)}} ({{recommendation.estimated_time}} hour(s))</div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 margin-bottom-15" *ngIf="!showCostPrice">
                              <div class="row form-group">
                                <label class="col-sm-3 col-xs-3 col-lg-2 text-left control-label text-bold">Estimated Time:</label>
                                <div class="col-sm-9 col-lg-10 col-xs-9 text-justify break-all">
                                  <div class="addedComputerCodes">{{ recommendation.estimated_time}} hour(s)</div>
                                </div>
                              </div>
                            </div>
                            
                            <!-- Job Total -->
                            <!-- <div class="display-full col-sm-12 text-right hidden-xs">
                              Job Total : <span *ngIf="levelPriceObj">$ {{recommendation.recom_total || '0.00'}}</span>
                            </div> -->
                            
                            <!-- Generic Jobs -->
                            <!-- <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.generic_jobs.length > 0">
                              <div class="row form-group">
                                <label class="col-sm-3 col-lg-2 text-right control-label">Generic Job(s):</label>
                                <div class="col-sm-9 col-lg-10 text-justify">
                                  <div class="addedGenericJobs">
                                    <div class="partRow row" style="padding-top:3px;" *ngFor="let gjob of recommendation.generic_jobs;let l = index">
                                      <div class="col-sm-8 pname">{{gjob.name}}</div>
                                      <div class="col-sm-2 pprice"><span *ngIf="showCostPrice">$ {{ parseFloats(gjob.price) }}</span></div>
                                      <div class="col-sm-2 ppqty">{{gjob.quantity}}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> -->
                            
                            <!-- Computer Codes -->
                            <!-- <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.computer_codes.length > 0">
                              <div class="row form-group">
                                <label class="col-sm-3 col-lg-2 text-right control-label">Computer Codes(s):</label>
                                <div class="col-sm-9 col-lg-10 text-justify">
                                  <div class="addedGenericJobs">
                                    <div class="partRow row" style="padding-top:3px;" *ngFor="let cjob of recommendation.computer_codes;let l = index">
                                      <div class="col-sm-12 pname break-all">{{cjob.trouble_code}} {{cjob.fault_location}}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> -->
                            
                            <!-- Estimated Time -->
                            <!-- <div class="col-sm-12 margin-bottom-15">
                              <div class="row form-group">
                                <label class="col-sm-3 col-lg-2 text-right control-label">Estimated Time:</label>
                                <div class="col-sm-9 col-lg-10 text-justify break-all">
                                  <div class="addedComputerCodes">{{recommendation.estimated_time}} hour(s) </div>
                                </div>
                              </div>
                            </div> -->
                            
                            <!-- Recommendation -->
                            <!-- <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.service_request_list_id!=700">
                              <div class="row form-group">
                                <label class="col-sm-3 col-lg-2 text-right control-label">Recommendation:</label>
                                <div class="col-sm-9 col-lg-10 text-justify break-all" >
                                  <p [innerHtml]="getNltoBr(recommendation.recommendation)"></p>
                                </div>
                              </div>
                            </div> -->
                            
                            <!-- Notes -->
                            <!-- <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.service_request_list_id!=700">
                              <div class="row form-group">
                                <label class="col-sm-3 col-lg-2 text-right control-label">Notes:</label>
                                <div class="col-sm-9 col-lg-10 text-justify break-all">
                                  <p [innerHtml]="getNltoBr(recommendation.notes)"></p>
                                </div>
                              </div>
                            </div> -->
                            
                            <!-- Uploaded Images -->
                            <!-- <div class="col-sm-12 margin-bottom-15 already-uploaded-images" *ngIf="recommendation.service_request_list_id!=700">
                              <div class="row form-group">
                                <label class="col-sm-3 col-lg-2 text-right control-label">Uploaded Image(s):</label>
                                <div class="col-sm-9 col-lg-10 text-justify">
                                  <ul class="list-inline margin-bottom-0" *ngIf="recommendation.uploaded_images.length > 0">
                                    <li  class="relative view-images" *ngFor="let image of recommendation.uploaded_images;let i=index;">
                                      <a (click)="imageToolDialog($event,i,4,recommendation.uploaded_images)" class="thumbnail" href="javascript:void(0);">
                                        <img class="show-image" src="{{globalConstants.S3_BUCKET_URL}}recommendation-pictures/thumb/{{image.filename}}">
                                      </a>
                                    </li>
                                  </ul>
                                  <span class="text-danger" *ngIf="recommendation.uploaded_images.length == 0">
                                    <span>No images uploaded.</span>
                                  </span>
                                </div>
                              </div>
                            </div> -->
                            
                            <!-- Uploaded Videos -->
                            <!-- <div class="col-sm-12 margin-bottom-15 already-uploaded-videos" *ngIf="recommendation.service_request_list_id!=700">
                              <div class="row form-group">
                                <label class="col-sm-3 col-lg-2 text-right control-label">Uploaded Video(s):</label>
                                  <div class="col-sm-9 col-lg-10 text-justify" *ngIf="recommendation.uploaded_videos.length > 0">
                                    <ul id="vehicle_video-ul" class="list-inline margin-bottom-0">
                                      <li class="video-upload relative" *ngFor="let video of recommendation.uploaded_videos;let i=index">
                                        <a class="thumbnail margin-bottom-0" href="javascript:void(0)" (click)="videoShow(video.filename)">
                                          <span class="relative video-bg">
                                            <mat-icon class="position-center play-video">play_circle_filled</mat-icon>
                                          </span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                <div class="col-sm-9 col-lg-10 text-justify" *ngIf="recommendation.uploaded_videos.length == 0">
                                  <span class="text-danger">No videos uploaded.
                                    <span></span>
                                  </span>
                                </div>
                              </div>
                            </div> -->
                          </div>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-2">
                          <div class="row margin-0 hidden-xs text-right text-bold" [class.hide]="!showCostPrice">
                            Job Total : <span *ngIf="levelPriceObj">$ {{(recommendation.recom_total | number:'1.2-2') || '0.00'}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row margin-0 visible-xs fs-12" *ngIf="showCostPrice">
                        <div class="col-xs-6 text-center padding-10 jt text-bold">
                          Total : <span *ngIf="levelPriceObj">$ {{(recommendation.recom_total | number:'1.2-2') || '0.00'}}</span>
                        </div>
                        <div class="col-xs-6 padding-0">
                          <span class="btn-copy-paste devices btn-block" *ngIf="recommendation_saved == '1' && isCopyEnable(request)" (click)="copyJob($event,request)">
                            <i class="fa fa-copy"></i> Copy Job
                          </span>
                        </div>
                      </div>
                      <div class="row margin-0 visible-xs fs-12" *ngIf="!showCostPrice">
                        <div class="col-xs-12 padding-0">
                          <span class="btn-copy-paste devices btn-block" *ngIf="recommendation_saved == '1' && isCopyEnable(request)" (click)="copyJob($event,request)">
                            <i class="fa fa-copy"></i> Copy Job
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ng-container>
              <ng-container *ngIf="isCategoryView">
                <li  *ngIf="request.recommendation_saved == '1'">
                  <div class="row margin-0">
                    <div *ngFor="let recommendation of request.recommendations;let j = index">
                      <div class="row margin-0">
                        <div class="col-sm-8 col-md-8 col-lg-10 padding-0">
                          <div class="row margin-0">
                            <!-- Parts -->
                            <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.parts.length > 0">
                              <div class="row form-group">
                                <label class="col-sm-3 col-lg-2 col-xs-3 text-left control-label text-bold">Part(s):</label>
                                <div class="col-sm-9 col-lg-10 col-xs-9 text-justify">
                                  <div class="addedPartsItems">
                                    <div class="partRow row hidden-xs" [class.text-orange]="part.partstech_cart_item==1 || part.partstech_order_item==1" style="padding-top:3px;" *ngFor="let part of recommendation.parts;let k = index">
                                      <div class="col-sm-8 pname break-all">
                                        <span *ngIf="part.part_number!=''">{{part.part_number}} </span>
                                        <span *ngIf="showCostPrice && hidePrices">($ {{part.cost}}) -</span>
                                        {{part.name}}
                                        <span *ngIf="part.description!=''"> - {{part.description}}</span>
                                      </div>
                                      <div class="col-sm-2 pprice "><span *ngIf="showCostPrice">$ {{ parseFloats(part.part_price)}}</span></div>
                                      <div class="col-sm-2 pquantity" [class.text-red]="isInventory && part.out_of_stock=='1'">
                                        <i *ngIf="isInventory" class="fa fa-info-circle" title="{{ stockInHand(part) }} "></i> {{part.quantity}}
                                        <!-- <button type="button" class="btn btn-xs btn-link text-red" (click)="addStockOrder(recommendation,part)"><i *ngIf="isInventory && part.out_of_stock=='1'" class="fa fa-cart-plus fa-lg" title="Add to stock order"></i></button> -->
                                      </div>
                                    </div>
                                    <div class="partRow row visible-xs" [class.text-orange]="part.partstech_cart_item==1 || part.partstech_order_item==1" style="padding-top:3px;" *ngFor="let part of recommendation.parts;let k = index">
                                      <div class="col-xs-12 pname break-all">
                                        <span *ngIf="part.part_number!=''">{{part.part_number}} </span>
                                        <span *ngIf="showCostPrice && hidePrices">($ {{part.cost}}) -</span>
                                        {{part.name}}
                                        <span *ngIf="part.description!=''"> - {{part.description}}</span>
                                      </div>
                                      <div class="col-xs-6 pprice "><span *ngIf="showCostPrice">$ {{ parseFloats(part.part_price)}}</span></div>
                                      <div class="col-xs-6 pquantity" [class.text-red]="isInventory && part.out_of_stock=='1'">
                                        <i *ngIf="isInventory" class="fa fa-info-circle" title="{{ stockInHand(part) }} "></i> {{part.quantity}}
                                        <!-- <button type="button" class="btn btn-xs btn-link text-red" (click)="addStockOrder(recommendation,part)"><i *ngIf="isInventory && part.out_of_stock=='1'" class="fa fa-cart-plus fa-lg" title="Add to stock order"></i></button> -->
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-2">
                          <div class="row margin-0 hidden-xs text-right">
                            <span class="btn-copy-paste devices hidden-xs pull-right" *ngIf="request.recommendation_saved == '1' && isCopyEnable(request)" (click)="copyJob($event,request)">
                              <i class="fa fa-copy"></i> Copy Job
                            </span>
                          </div>
                        </div>
                      </div>
                      <!-- For each section -->
                      <div class="row margin-0 margin-top-10">
                        <div class="col-sm-8 col-md-8 col-lg-10 padding-0">
                          <div class="row margin-0">
                            <!-- Estimated Labor -->
                            <div class="col-sm-12 margin-bottom-15" *ngIf="showCostPrice">
                              <div class="row form-group">
                                <label class="col-sm-3 col-xs-3 col-lg-2 text-left control-label text-bold">Estimated Labor:</label>
                                <div class="col-sm-9 col-lg-10 col-xs-9 text-justify break-all">
                                  <div class="addedComputerCodes">$ {{ parseFloats(recommendation.estimated_labor)}} ({{recommendation.estimated_time}} hour(s))</div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 margin-bottom-15" *ngIf="!showCostPrice">
                              <div class="row form-group">
                                <label class="col-sm-3 col-xs-3 col-lg-2 text-left control-label text-bold">Estimated Time:</label>
                                <div class="col-sm-9 col-lg-10 col-xs-9 text-justify break-all">
                                  <div class="addedComputerCodes">{{ recommendation.estimated_time}} hour(s)</div>
                                </div>
                              </div>
                            </div>
                            
                            <!-- Job Total -->
                            <!-- <div class="display-full col-sm-12 text-right hidden-xs">
                              Job Total : <span *ngIf="levelPriceObj">$ {{recommendation.recom_total || '0.00'}}</span>
                            </div> -->
                            
                            <!-- Generic Jobs -->
                            <!-- <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.generic_jobs.length > 0">
                              <div class="row form-group">
                                <label class="col-sm-3 col-lg-2 text-right control-label">Generic Job(s):</label>
                                <div class="col-sm-9 col-lg-10 text-justify">
                                  <div class="addedGenericJobs">
                                    <div class="partRow row" style="padding-top:3px;" *ngFor="let gjob of recommendation.generic_jobs;let l = index">
                                      <div class="col-sm-8 pname">{{gjob.name}}</div>
                                      <div class="col-sm-2 pprice"><span *ngIf="showCostPrice">$ {{ parseFloats(gjob.price) }}</span></div>
                                      <div class="col-sm-2 ppqty">{{gjob.quantity}}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> -->
                            
                            <!-- Computer Codes -->
                            <!-- <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.computer_codes.length > 0">
                              <div class="row form-group">
                                <label class="col-sm-3 col-lg-2 text-right control-label">Computer Codes(s):</label>
                                <div class="col-sm-9 col-lg-10 text-justify">
                                  <div class="addedGenericJobs">
                                    <div class="partRow row" style="padding-top:3px;" *ngFor="let cjob of recommendation.computer_codes;let l = index">
                                      <div class="col-sm-12 pname break-all">{{cjob.trouble_code}} {{cjob.fault_location}}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> -->
                            
                            <!-- Estimated Time -->
                            <!-- <div class="col-sm-12 margin-bottom-15">
                              <div class="row form-group">
                                <label class="col-sm-3 col-lg-2 text-right control-label">Estimated Time:</label>
                                <div class="col-sm-9 col-lg-10 text-justify break-all">
                                  <div class="addedComputerCodes">{{recommendation.estimated_time}} hour(s) </div>
                                </div>
                              </div>
                            </div> -->
                            
                            <!-- Recommendation -->
                            <!-- <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.service_request_list_id!=700">
                              <div class="row form-group">
                                <label class="col-sm-3 col-lg-2 text-right control-label">Recommendation:</label>
                                <div class="col-sm-9 col-lg-10 text-justify break-all" >
                                  <p [innerHtml]="getNltoBr(recommendation.recommendation)"></p>
                                </div>
                              </div>
                            </div> -->
                            
                            <!-- Notes -->
                            <!-- <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.service_request_list_id!=700">
                              <div class="row form-group">
                                <label class="col-sm-3 col-lg-2 text-right control-label">Notes:</label>
                                <div class="col-sm-9 col-lg-10 text-justify break-all">
                                  <p [innerHtml]="getNltoBr(recommendation.notes)"></p>
                                </div>
                              </div>
                            </div> -->
                            
                            <!-- Uploaded Images -->
                            <!-- <div class="col-sm-12 margin-bottom-15 already-uploaded-images" *ngIf="recommendation.service_request_list_id!=700">
                              <div class="row form-group">
                                <label class="col-sm-3 col-lg-2 text-right control-label">Uploaded Image(s):</label>
                                <div class="col-sm-9 col-lg-10 text-justify">
                                  <ul class="list-inline margin-bottom-0" *ngIf="recommendation.uploaded_images.length > 0">
                                    <li  class="relative view-images" *ngFor="let image of recommendation.uploaded_images;let i=index;">
                                      <a (click)="imageToolDialog($event,i,4,recommendation.uploaded_images)" class="thumbnail" href="javascript:void(0);">
                                        <img class="show-image" src="{{globalConstants.S3_BUCKET_URL}}recommendation-pictures/thumb/{{image.filename}}">
                                      </a>
                                    </li>
                                  </ul>
                                  <span class="text-danger" *ngIf="recommendation.uploaded_images.length == 0">
                                    <span>No images uploaded.</span>
                                  </span>
                                </div>
                              </div>
                            </div> -->
                            
                            <!-- Uploaded Videos -->
                            <!-- <div class="col-sm-12 margin-bottom-15 already-uploaded-videos" *ngIf="recommendation.service_request_list_id!=700">
                              <div class="row form-group">
                                <label class="col-sm-3 col-lg-2 text-right control-label">Uploaded Video(s):</label>
                                  <div class="col-sm-9 col-lg-10 text-justify" *ngIf="recommendation.uploaded_videos.length > 0">
                                    <ul id="vehicle_video-ul" class="list-inline margin-bottom-0">
                                      <li class="video-upload relative" *ngFor="let video of recommendation.uploaded_videos;let i=index">
                                        <a class="thumbnail margin-bottom-0" href="javascript:void(0)" (click)="videoShow(video.filename)">
                                          <span class="relative video-bg">
                                            <mat-icon class="position-center play-video">play_circle_filled</mat-icon>
                                          </span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                <div class="col-sm-9 col-lg-10 text-justify" *ngIf="recommendation.uploaded_videos.length == 0">
                                  <span class="text-danger">No videos uploaded.
                                    <span></span>
                                  </span>
                                </div>
                              </div>
                            </div> -->
                          </div>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-2">
                          <div class="row margin-0 hidden-xs text-right text-bold" [class.hide]="!showCostPrice">
                            Job Total : <span *ngIf="levelPriceObj">$ {{(recommendation.recom_total | number:'1.2-2') || '0.00'}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row margin-0 visible-xs fs-12" *ngIf="showCostPrice">
                        <div class="col-xs-6 text-center padding-10 jt text-bold">
                          Total : <span *ngIf="levelPriceObj">$ {{(recommendation.recom_total | number:'1.2-2') || '0.00'}}</span>
                        </div>
                        <div class="col-xs-6 padding-0">
                          <span class="btn-copy-paste devices btn-block" *ngIf="request.recommendation_saved == '1' && isCopyEnable(request)" (click)="copyJob($event,request)">
                            <i class="fa fa-copy"></i> Copy Job
                          </span>
                        </div>
                      </div>
                      <div class="row margin-0 visible-xs fs-12" *ngIf="!showCostPrice">
                        <div class="col-xs-12 padding-0">
                          <span class="btn-copy-paste devices btn-block" *ngIf="request.recommendation_saved == '1' && isCopyEnable(request)" (click)="copyJob($event,request)">
                            <i class="fa fa-copy"></i> Copy Job
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ng-container>
            </ul>
          </li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-template>
</ng-template>