import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';

@Component({
  selector: 'app-returnque',
  templateUrl: './returnque.component.html',
  styleUrls: ['./returnque.component.scss']
})
export class ReturnqueComponent implements OnInit {

  public note = 'Are you sure, you want to delete this part?';
  public part: any = {};
  public showTxt = false;
  public addToreturnQue=true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ReturnqueComponent>, private gs: GlobalService) {
    this.part = data.part;
  }

  ngOnInit() {

    let isEnable = this.gs.getAcl('return_queue', 'addNew');
    if (!(isEnable)) {
      this.addToreturnQue=false;
      // this.dialogRef.close(0);
    }
    this.gs.diaLogObj(this.dialogRef);
    if ((this.part.partstech_order == '1') || (this.part.partstech == '1')) {
      this.showTxt = true;
    }
  }
  returnQ() {
    this.dialogRef.close(1);
  }
  stayStock() {
    this.dialogRef.close(2);
  }

}
