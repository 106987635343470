<div mat-dialog-title class="relative" id="mainCompotents">
    Action Required
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
        <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="center-block" style="padding: 20px 0 10px;">
        <div>
            <div class="col-sm-12">
                <p class="text-red">{{ refreshInfo.message }}</p>
            </div>
        </div>
        <div>
            <div class="col-sm-12">
                <h5>Refreshing in {{ timeOut-timeInit }} seconds...</h5>
            </div>
        </div>
        <div class="display-full margin-bottom-5">
            <div class="col-sm-12 text-center">
                <button mat-raised-button type="button" (click)="goto()" class="font-bold" color="accent" title="Submit">Refresh Now</button>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative" style=" justify-content: flex-end; ">
    <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
</mat-dialog-actions>
