import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
import { CustomdatatableComponent, CustomdatatableSource } from '../../../customdatatable/customdatatable.component';
import { Router } from '@angular/router';
const SHOP_SMS_TEMPLATE_LIST = "shop-templates-";
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-list-templates',
  templateUrl: './list-templates.component.html',
  styleUrls: ['./list-templates.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListTemplatesComponent extends CustomdatatableComponent implements OnInit {

  public currentUser: any = {};
  public openStateSearch = false;
  public displayedColumns = ['title', 'slug', 'template'];
  public tablesData = this;
  public url = "";
  public options = {
    user_type: "User",
    user_id: "",
    search_by: "",
    search_keyword: "",
    start: 0,
    limit: 10,
  }
  public searchByArray = [{ id: 1, text: 'Name' }, { id: 2, text: 'Code' }];
  public isEditEnabled: boolean;
  public isDelEnabled: boolean;
  public isAddEnabled: boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<smsTemplateListData> = new MatTableDataSource();
  public record :any =[];

  constructor(public gs: GlobalService, public router: Router, public dialog: MatDialog) {
    super(globalConstants.API_LIST_SHOP_SMS_TEMPLATES_URL, gs);
    this.currentUser = this.gs.getCurrentUser();
    if (this.currentUser) {
      this.sessionKey = SHOP_SMS_TEMPLATE_LIST;
      this.url = globalConstants.API_LIST_SHOP_SMS_TEMPLATES_URL;
    } else {
      this.router.navigate(["/shops"]);
    }
    setTimeout(() => {
      this.isEditEnabled = this.gs.getAcl('sms_template', 'edits');
      let inSubscription: boolean = true;
      let isEnable = this.gs.getAcl('sms_template', 'views');
      if (!(isEnable) || !(inSubscription)) {
        this.router.navigate(['shops/action/forbidden']);
      }
    }, globalConstants.RELOAD_WAIT);
   
  }

  ngOnInit() {
    this.reinitializeOptions();
    if (this.checkFormEmpty(null)) {
      this.openStateSearch = true;
    } else {
      this.openStateSearch = false;
    }
    this.isReady = true;  
    //this.gs.setMetaData("SHOPS", "SMS_TEMPLATE_LIST");  
    this.setDataTable()
  } 

  editDiscount(row:any) {
    this.router.navigate(["/shops/sms-templates/edit/" + row.id]);
  }

  search(event:any) {
    this.options.start = 0;
    this.setDataTable();
  }

  clearSearch() {
    this.openStateSearch = false;
    this.gs.localGetterSetter("", this.options, SHOP_SMS_TEMPLATE_LIST);
    this.options.search_keyword = "";
    this.reinitializeOptions();
    this.setDataTable();
  }

  reinitializeOptions() {
    this.options = this.gs.localGetterSetter(this.options, this.options, SHOP_SMS_TEMPLATE_LIST);
    this.options.user_type = this.currentUser.user_type;
    this.options.user_id = this.currentUser.id;
  }

  dateFormatWithoutTime(dateValue:any) {
    return ((new Date(dateValue).getMonth() + 1) < 10 ? '0' : '') + (new Date(dateValue).getMonth() + 1) + '/' + (new Date(dateValue).getDate() < 10 ? '0' : '') + new Date(dateValue).getDate() + '/' + (new Date(dateValue).getFullYear());
  }

  createRecordrecord(record:any) {
    return {
      title: record.title,
      slug: record.slug,
      id: record.id,     
      template:  record.template,
      status: record.status == 1 ? 'Active' : 'Inactive',
      created_at: this.gs.getFullFormateDate(record.created_at, 'G', null)
    };
  }

  checkFormEmpty(button:any) {
    if (this.options.search_keyword == "") {
      if (button) { button.color = "primary" }
      return false
    } else {
      if (button) { button.color = "accent" }
      return true;
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

 setDataTable() {
    // this.dataSource = new CustomdatatableSource(this.tablesData, this.sort, this.paginator);
    // this.dataSource.getSortedData=this.sortedData;
    this.record = [];
    var content = this.options;
    this.isGetData=false
    this.callApi = this.gs.callAPI(this.url, content);
    this.callApi.subscribe((data) => {
      this.apiCaller.next(data);
      this.isGetData=true;
      let responseData = data.data
      if (responseData != undefined) {
        let moreRows = 'false';
        let firstList = 'false';
        this.gs.localGetterSetter("totalRecords", data.recordsTotal, this.sessionKey);
        if (data.more_rows == "true") {
          moreRows = "true";
        }
        this.gs.localGetterSetter("more_rows", moreRows, this.sessionKey);
        if (data.start >= 0 && data.start <= 5) {
          firstList = "true";
        }
        this.gs.localGetterSetter("first_list", firstList, this.sessionKey);
        if (responseData.length != undefined) {
          this.recordsTotal = responseData.length;
          for (var i = 0; i < responseData.length; i++) {
            this.AddRecord(responseData[i])
          }
          this.dataSource = new MatTableDataSource(this.record)
          this.dataSource.sort = this.sort;
        } else {
          this.recordsTotal = "1";
          this.AddRecord(responseData)
          this.dataSource = new MatTableDataSource(this.record)
          this.dataSource.sort = this.sort;
        }
        this.gs.localGetterSetter(null, this.options, this.sessionKey);
      } else {
        this.dataSource = new MatTableDataSource(this.record)
        this.gs.localGetterSetter("totalRecords", '0', this.sessionKey);
      }
    },(error)=>{
      this.gs.localGetterSetter("totalRecords", '0', this.sessionKey);
    })
  }

  AddRecord(record){
    var data = this.createRecordrecord(record);
      this.record.push(data);
  }

  // sortedData(): any[] {
  //   let th: any = this;
  //   const data = th._exampleDatabase.data.slice();
  //   if (!th._sort.active || th._sort.direction == '') { return data; }

  //   return data.sort((a:any, b:any) => {
  //     let propertyA;
  //     let propertyB;
  //     switch (th._sort.active) {
  //       case 'Title': propertyA = a.title; propertyB = b.title; break;
  //       case 'Slug': propertyA = a.slug; propertyB = b.slug; break;
  //       case 'Template': propertyA = a.template; propertyB = b.template; break;
  //       case 'Added On': propertyA = a.created_at; propertyB = b.created_at; break;
  //       default: return 0;
  //     }
  //     let valueA = isNaN(propertyA) ? propertyA.toUpperCase() : propertyA;
  //     let valueB = isNaN(propertyB) ? propertyB.toUpperCase() : propertyB;

  //     return (valueA < valueB ? -1 : 1) * (th._sort.direction == 'asc' ? 1 : -1);
  //   });
  // }

}

export interface smsTemplateListData {
  title: any;
  slug: any;
  id:any;
  template: any;
  status: any;
}