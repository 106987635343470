<div class="panel panel-primary">
  <div class="panel-heading padding-left-30">
    <h3 class="panel-title">Payment Status</h3>
  </div>
  <div class="panel-body bgcolor-lightgrey">
    <p *ngIf="paymentSuccess">    {{subscriptionMessage}}</p>
    <p *ngIf="!paymentSuccess">    {{subscriptionMessage}}</p>

  </div>
  </div>
