<!-- LIST SUBSCRIPTION LOGS --->
<div class="car-panel">
    <div class="panel panel-primary" id="addCustomerPanel">
        <div class="panel-heading padding-left-30">
            <h3 class="panel-title">Shop Subscription Logs</h3>
            <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
    </div>

    <div class="panel-body bgcolor-lightgrey">
        <div style="padding:0px 5px;">
            <strong>Shop: {{getCompanyName}}</strong>
            <span *ngIf="logDataLength>0"><button type="button" mat-raised-button color="warn" class="font-bold mat-elevation-z1" style="float:right;" (click)="extendSubscription()"><i class="fa fa-plus"></i>&nbsp;Extend Shop's Subscription</button></span>
        </div>
        <hr>
        <div id="signupContent" class="bgcolor-lightgrey">
            <div id="genericJobsList" class="table-responsive" style="min-height:250px;padding-bottom:180px;">
                <table class="table table-striped table-hover table-condensed sortable">
                    <thead>
                        <tr>
                            <th>Plan Name</th>
                            <th>Duration</th>
                            <th>Plan Price</th>
                            <th>Subscription Add Date</th>
                            <th>Subscription Expiry Date</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="logDataLength>0">
                        <tr *ngFor="let getSubscriptionLog of getSubscriptionLogs" class="{{getStatus(getSubscriptionLog.status)}}">
                            <!--<td> {{convertStaticNameToDynamic(getSubscriptionLog.subscription_type)}}</td>-->
                            <td> {{getSubscriptionLog.subscription_name}}</td>
                            <td>{{getDuration(getSubscriptionLog.duration)}}</td>
                            <td>${{getSubscriptionLog.amount}}</td>
                            <td>{{getSubscriptionLog.add_date}} </td>
                            <td>{{getSubscriptionLog.expiry_date}} </td>
                        </tr>
                    </tbody>

                    <tbody *ngIf="logDataLength==0">
                        <tr>
                            <td colspan='5' align='center'> No records available!</td>
                        </tr>
                    </tbody>

                </table>
            </div>
        </div>
    </div>
</div>
