import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDatePickerConfig } from 'ng2-date-picker';
import { GlobalService } from '../../../../../environments/global.service';
import * as moment from 'moment';
import { globalConstants } from '../../../../../environments/globalConstants';
@Component({
  selector: 'app-adjust-tech-schedule',
  templateUrl: './adjust-tech-schedule.component.html',
  styleUrls: ['./adjust-tech-schedule.component.scss'],
  providers: [DatePipe]
})
export class AdjustTechScheduleComponent implements OnInit {
  public tech: FormGroup;
  public sTimeSlots: Array<any> = globalConstants.TIME_SLOTS;
  public eTimeSlots: Array<any> = globalConstants.TIME_SLOTS;
  public error: any = { dError: false, tError: false };
  public selectSR: any;
  public est_hours: any = 0;
  public est_minutes: any = 30;
  public estimated_hours: any = globalConstants.SR_ESTIMATED_HOUR;
  public userData: any = {};
  public dialogData: any = {};
  public isEdit: boolean;
  datePickerConfig: IDatePickerConfig = {
    drops: 'down',
    opens: 'right',
    min: this.datePipe.transform(new Date(), 'MM/dd/yyyy'),
    format: "MM/DD/YYYY",
    closeOnSelect: true,
    disableKeypress: true,
    unSelectOnClick: false
  };
  constructor(public dialogRef: MatDialogRef<AdjustTechScheduleComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, public globalService: GlobalService, private datePipe: DatePipe) {
    this.dialogData = data;
    if ('data' in data) {
      this.selectSR = data.data;
    }
    if ('is_edit' in data && data.is_edit) {
      this.isEdit = true;
    }
  }

  ngOnInit() {
    this.userData = this.globalService.getCurrentUser();
    this.tech = this.formBuilder.group({
      emp_id: [this.dialogData.emp_id, [Validators.required]],
      request_id: [this.dialogData.request_id],
      user_id: [this.userData.id],
      user_type: [this.userData.user_type],
      event_start_date: ['', [Validators.required]],
      event_end_date: ['', [Validators.required]],
      event_start_time: ['', Validators.required],
      event_end_time: ['', Validators.required],
      user_sr_id: [this.dialogData.user_sr_id, Validators.required]
    }, { validator: this.checkDates });

    if ('request_id' in this.dialogData) {
      this.getServiceDetails();
    }


  }

  checkDates(group: FormGroup) {
    if(group.controls.event_start_date.value==group.controls.event_end_date.value && group.controls.event_end_time.value < group.controls.event_start_time.value) {
      return { notValid:true }
    }
    return null;
  }

  startDateHandler(event:any) {
    if (event) {
      var startDate = this.datePipe.transform(event, 'MM/dd/yyyy');
      var dt = new Date(this.datePipe.transform(event, 'MM/dd/yyyy hh:mm a'));
      if (typeof this.est_hours != 'undefined' && this.est_hours != 'null') {
        dt.setHours(dt.getHours() + this.est_hours);
      }
      if (typeof this.est_minutes != 'undefined' && this.est_minutes != null) {
        dt.setMinutes(dt.getMinutes() + this.est_minutes);
      }
      if ((typeof this.est_hours != 'undefined' || typeof this.est_minutes != 'undefined')) {
        let EndDate = this.datePipe.transform(dt, 'MM/dd/yyyy');
        this.tech.controls.event_end_date.setValue(EndDate);
        this.setTime(moment(this.datePipe.transform(dt, 'hh:mm a'), 'hh:mm A').format('HH:mm'), 2);
      }
    }
  }

  startTimeHandler(event:any) {
    let tDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
    let sTime = moment(tDate + " " + event);
    if (typeof this.est_hours != 'undefined' && this.est_hours != 'null') {
      sTime.add(this.est_hours, 'hours');
    }
    if (typeof this.est_minutes != 'undefined' && this.est_minutes != 'null') {
      sTime.add(this.est_minutes, 'minutes');
    }
    if (typeof this.est_hours != 'undefined' || typeof this.est_minutes != 'undefined') {
      this.setTime(sTime.format('HH:mm'), 2);
    }

  }

  getServiceDetails() {
    // let content1 = 'user_id=' + this.userData.id + '&user_type=' + this.userData.user_type + "&request_id=" + this.tech?.get('request_id').value;
    let content1 = 'user_id=' + this.userData.id + '&user_type=' + this.userData.user_type + "&request_id=" + this.tech.get('request_id')?.value

    this.globalService.callAPI(globalConstants.API_QUICKAPPT_SERVICE_REQUESTS, content1).subscribe((resp:any) => {
      if (resp.result == 1) {
        if (typeof resp.data != 'undefined' && resp.data != "") {
          if (resp.data.est_hours != null && resp.data.est_hours != 'null') {
            this.estimated_hours = resp.data.est_hours;
          }
          if (resp.data.hours != null && resp.data.hours != 'null') {
            this.est_hours = resp.data.hours;
          }
          if (resp.data.minute != null && resp.data.minute != 'null') {
            this.est_minutes = resp.data.minute;
          }

          let todayDate:any = this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm a');
          let dt = new Date(todayDate);
          if ('data' in this.dialogData && this.datePipe!.transform(moment(this.dialogData.data.start_date).toISOString(), 'MM/dd/yyyy hh:mm a') > todayDate) {
            let startDate = this.datePipe.transform(moment(this.dialogData.data.start_date).toISOString(), 'MM/dd/yyyy hh:mm a');
            let endDate = this.datePipe.transform(moment(this.dialogData.data.end_date).toISOString(), 'MM/dd/yyyy hh:mm a');          
            this.tech.controls.event_start_date.setValue(this.datePipe.transform(startDate, 'MM/dd/yyyy'));
            this.tech.controls.event_end_date.setValue(this.datePipe.transform(endDate, 'MM/dd/yyyy'));           
            this.setTime(moment(this.datePipe.transform(startDate, 'hh:mm a'), 'hh:mm A').format('HH:mm'), 1);
            this.setTime(moment(this.datePipe.transform(endDate, 'hh:mm a'), 'hh:mm A').format('HH:mm'), 2);
          } else {
            if ('requested_delivery_date' in resp.data) {
              let startDate:any = this.datePipe.transform(moment(resp.data.requested_delivery_date).toISOString(), 'MM/dd/yyyy hh:mm a');
              if (startDate > todayDate) {
                this.tech.controls.event_start_date.setValue(this.datePipe.transform(startDate, 'MM/dd/yyyy'));
                this.tech.controls.event_end_date.setValue(this.datePipe.transform(startDate, 'MM/dd/yyyy'));
                this.setTime(moment(this.datePipe.transform(startDate, 'hh:mm a'), 'hh:mm A').format('HH:mm'), 1);
                dt = new Date(startDate);
              } else {
                this.tech.controls.event_start_date.setValue(this.datePipe.transform(todayDate, 'MM/dd/yyyy'));
                this.tech.controls.event_end_date.setValue(this.datePipe.transform(todayDate, 'MM/dd/yyyy'));
                this.setTime(moment(this.datePipe.transform(todayDate, 'hh:mm a'), 'hh:mm A').format('HH:mm'), 1);
              }
            } else {
              this.tech.controls.event_start_date.setValue(this.datePipe.transform(todayDate, 'MM/dd/yyyy'));
              this.tech.controls.event_end_date.setValue(this.datePipe.transform(todayDate, 'MM/dd/yyyy'));
              this.setTime(moment(this.datePipe.transform(todayDate, 'hh:mm a'), 'hh:mm A').format('HH:mm'), 1);
            }
            if (resp.data.hours) {
              dt.setHours(dt.getHours() + resp.data.hours);
            }
            if (resp.data.minute) {
              dt.setMinutes(dt.getMinutes() + resp.data.minute);
            }
            if (resp.data.hours || resp.data.minute) {
              this.tech.controls.event_end_date.setValue(this.datePipe.transform(dt, 'MM/dd/yyyy'));
              this.setTime(moment(this.datePipe.transform(dt, 'hh:mm a'), 'hh:mm A').format('HH:mm'), 2);
            }
          }
        }
      }
    });
  }

  setTime(hoursMinutes:any, setField = 1) {
    const time = hoursMinutes.split(":");
    let hour = time[0];
    let minutes = "00";
    if (time[1] > 0 && time[1] <= 15) {
      minutes = "15";
    } else if (time[1] > 15 && time[1] <= 30) {
      minutes = "30";
    } else if (time[1] > 30 && time[1] <= 45) {
      minutes = "45";
    } else if (time[1] > 45) {
      hour = Number(hour) + 1;
    }

    hour = hour.toString().padStart(2, "0");
    minutes = minutes.toString().padStart(2, "0");

    if (setField == 1) {
      this.tech.controls.event_start_time.setValue(hour + ":" + minutes);
    } else {
      this.tech.controls.event_end_time.setValue(hour + ":" + minutes);
    }
  }


  convertMinutes(num:any) {
    if (num) {
      let d = Math.floor(num / 1440); // 60*24
      let h = Math.floor((num - (d * 1440)) / 60);
      let m = Math.round(num % 60);
      let tTime = "";
      if (d > 0) tTime += d + "D ";
      if (h > 0) tTime += h + "H ";
      if (m > 0) tTime += m + "M";
      return tTime;
    } else {
      return "-";
    }
  }

  convertISODate(dateString:any) {
    return moment(dateString).toISOString();
  }

  onSubmit(forcAction:any = null, conflictAction:any = null) {
    this.error.dError = false;
    this.error.tError = false;
    
    if(moment(new Date(this.tech.controls['event_start_date'].value)).format('MM/DD/YYYY') < moment(new Date(this.tech.controls['event_end_date'].value)).format('MM/DD/YYYY')){
      this.error.dError = true;
    }

    if(moment(new Date(this.tech.controls['event_start_date'].value)).format('MM/DD/YYYY')==moment(new Date(this.tech.controls['event_end_date'].value)).format('MM/DD/YYYY') && this.tech.controls['event_start_time'].value > this.tech.controls['event_end_time'].value){
      this.error.tError = true;
    }

    if (this.tech.valid && this.error.dError == false && this.error.tError == false) {

      let data = Object.assign({}, this.tech.value);
      data['event_start_date'] = moment(data.event_start_date + " " + data.event_start_time).format('MM/DD/YYYY hh:mm A Z');
      data['event_end_date'] = moment(data.event_end_date + " " + data.event_end_time).format('MM/DD/YYYY hh:mm A Z');
      if (forcAction > 0) {
        data['force_action'] = forcAction;
      }
      if (conflictAction > 0) {
        data['adding_conflict'] = conflictAction;
      }
      this.globalService.formData(globalConstants.API_ADJUST_TECH_SCHEDULE, this.globalService.formsData(data)).subscribe((tech:any) => {
        if (tech.result == 1) {
          this.globalService.snackbar("success", tech.message);
          this.dialogRef["adjustTime"] = true;
          this.dialogRef.close(1);
        } else {
          if ([730, 731, 732, 734, 740].includes(parseInt(tech.code))) {
            let code: any = tech.code;
            this.globalService.confirmDialog('techAssign', tech.message).subscribe((resp: any) => {
              let fStatus:any = { 730: 1, 731: 2, 732: 3, 740: 4, 734: 5 };
              if ([1, 2].includes(fStatus[Number(code)])) {
                conflictAction = fStatus[Number(code)];
              }
              if (resp == 'techAssign') {
                this.onSubmit(fStatus[Number(code)], conflictAction);
              }
            });
          } else {
            this.globalService.snackbar("error", tech.message);
          }
        }
      });
    } else {
      this.globalService.validateAllFormFields(this.tech);
    }

  }

}
