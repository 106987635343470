<div class="panel panel-primary"  id="mainCompotents">
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">SERVICE REQUESTS LIST</h3>
        <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
            <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <div class="display-full relative">
            <mat-expansion-panel hideToggle="false" class="search-filter relative" [expanded]="openHistorySearch">
                <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px">
                    <strong><i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
                </mat-expansion-panel-header>
                <form id="advanceSearchForm" class="collapse in" aria-expanded="true">
                    <div class="panel-body">
                        <div class="col-sm-3">
                            <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                                <input  matInput placeholder="Search Keyword" title="Search Keyword" [(ngModel)]="options.search_keyword" [ngModelOptions]="{standalone: true}" class="form-control input-lg custom-form-input"/>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-3">
                            <mat-form-field class="car-input btn-block car-datePicker" floatPlaceholder="never">
                                <input matInput class="form-control input-lg custom-form-input" placeholder="Schedule Service Date" [matDatepicker]="filerPicker" [(ngModel)]="schedule_service_option" [ngModelOptions]="{standalone: true}" onkeydown="return false;">
                                <mat-datepicker-toggle matSuffix [for]="filerPicker"></mat-datepicker-toggle>
                                <mat-datepicker #filerPicker [touchUi]="setTouchViewValue()"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-3">
                            <mat-form-field class="car-input btn-block car-datePicker"  floatPlaceholder="never">
                                <input matInput class="form-control input-lg custom-form-input" placeholder="Requested Delivery Date" [matDatepicker]="filerPicker1" [(ngModel)]="requested_delivery_option" [ngModelOptions]="{standalone: true}" onkeydown="return false;">
                                <mat-datepicker-toggle matSuffix [for]="filerPicker1" ></mat-datepicker-toggle>
                                <mat-datepicker #filerPicker1 [touchUi]="setTouchViewValue()"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-3">
                            <mat-form-field class="car-input btn-block car-datePicker"  floatPlaceholder="never">
                                <input matInput class="form-control input-lg custom-form-input" placeholder="Estimated Delivery Date" [matDatepicker]="filerPicker2" [(ngModel)]="estimated_delivery_option" [ngModelOptions]="{standalone: true}" onkeydown="return false;">
                                <mat-datepicker-toggle matSuffix [for]="filerPicker2" ></mat-datepicker-toggle>
                                <mat-datepicker #filerPicker2 [touchUi]="setTouchViewValue()"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-3">
                            <mat-form-field class="btn-block car-input" floatPlaceholder="never">
                                <mat-select placeholder="Select Status" class="form-control input-lg custom-form-input" [(ngModel)]="options.status" [ngModelOptions]="{standalone: true}">
                                    <mat-option value ="">Select Status</mat-option>
                                    <mat-option value="0">Appointments</mat-option>
                                    <mat-option value="1">Pending</mat-option>
                                    <mat-option value="2">Processing</mat-option>
                                    <mat-option value="3">Approval Awaited</mat-option>
                                    <mat-option value="7">Approved</mat-option>
                                    <mat-option value="6">Completed</mat-option>
                                    <mat-option value="12">Work in progress</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <!-- <div class="col-sm-3">
                            <button mat-raised-button class="filter-apply" type="submit" (click)="search($event)">
                                <i class="fa fa-search hide"></i><strong>Apply Filter</strong>
                            </button>
                        </div> -->
                    </div>
                    <mat-action-row class="search-filter-footer">
                      <button mat-raised-button color="accent" type="submit" (click)="search($event)">
                        Search
                      </button>
                    </mat-action-row>
                </form>

            </mat-expansion-panel>
            <button mat-button #clearBtn class="clear-btn" [class.active-search]="isAnyOptionActive(clearBtn)" (click)="clearSearch()">Clear Filter</button>
        </div>

        <div class="car-datatable uc-datatable mat-elevation-z1">
            <div class="text-right hidden">
                <mat-form-field floatPlaceholder="never" >
                    <input matInput #filter placeholder="Filter users">
                </mat-form-field>
            </div>
            <div class="table-responsive">
                <mat-table #table [dataSource]="dataSource" matSort class="service-list uc-table-responsive">


                    <ng-container matColumnDef="request_id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="id"> Request Id </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="id" data-val="Request Id"> {{row.request_id}} </mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="title">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="title"> Title </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="title" data-val="Title"> {{row.title ? row.title : "-"}} </mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="vehicle">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="vehicle"> Vehicle </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="vehicle" data-val="Vehicle"> {{row.vehicle ? row.vehicle : 'N/A'}} </mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="appointment_date">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="date"> Appointment Date </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="date" data-val="Appointment Date">{{row.requested_delivery_date}} </mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="completion_date">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="date"> Completion Date </mat-header-cell>
                        <mat-cell *matCellDef="let row"  data-role="date" data-val="Completion Date"> {{row.estimated_delivery_date}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="car">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="car"> CAR </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="car" data-val="CAR"> {{row.car}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="status"> Status </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="status" data-val="Status"> {{row.status}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef data-role="button"> Actions </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="button">
                            <button mat-button class="list-action-btn" [matMenuTriggerFor]="menu" disableRipple>
                                Action<mat-icon>arrow_drop_down</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu" class="car-icon-menu" [overlapTrigger]="false">
                                <a mat-menu-item (click)="viewHistoryDetail(row.serviceId)"><i class="fa fa-eye fa-fw"></i>View</a>
                            </mat-menu> </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" class="mat-row {{getRowStatusColor(row.check_status)}}"></mat-row>
                </mat-table>
                <div class="padding-15 text-center"
                    [style.display]="getTotalRecords() == 0 ? '' : 'none'">
                    No Records Available.
                </div>
            </div>
            <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
                <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="options.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event)" >
                </mat-paginator>

                <div class="mat-paginator table-paging">
                    <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
                    <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                            <mat-icon>navigate_before</mat-icon>
                    </button>
                    <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                            <mat-icon>navigate_next</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
