import {Component, OnInit, ViewChild, Input} from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd, Params} from '@angular/router';
import {Http, Headers, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../../environments/globalFunctions"
import {globalConstants} from "../../../../environments/globalConstants"
import {GlobalService} from "../../../../environments/global.service"
import {lang} from "../../../../environments/lang-en"
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';
import {trigger, state, style, animate, transition} from '@angular/animations';
import {AppComponent} from "../../../app.component"
const EMAIL_REGEX = globalConstants.EMAIL_REGEX;
import {DatePipe} from '@angular/common';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatTab } from '@angular/material/tabs';

import {UsercontactsComponent} from '../../../usercontacts/usercontacts.component';
import { AnyNsRecord } from 'dns';

export class AppDateAdapter extends NativeDateAdapter {
   parse(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

   format(date: Date, displayFormat: Object): string {
       if (displayFormat == "input") {
           let day = date.getDate();
           let month = date.getMonth() + 1;
           let year = date.getFullYear();
           return this._to2digit(month)+ '/' + this._to2digit(day)  + '/' + year;
       } else {
           return date.toDateString();
       }
   }

   private _to2digit(n: number) {
       return ('00' + n).slice(-2);
   }
}

export const APP_DATE_FORMATS =
  {
     parse: {
         dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
     },
     display: {
         // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
         dateInput: 'input',
         monthYearLabel: { month: 'short', year: 'numeric', day: 'numeric' },
         dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
         monthYearA11yLabel: {year: 'numeric', month: 'long'},
     }
  }

@Component({
    selector: 'app-edit-customer',
    templateUrl: './edit-customer.component.html',
    styleUrls: ['./edit-customer.component.scss'],
    animations: [
        trigger('popOverState', [
            state('show', style({
                opacity: 1,
                height: '69px',
                transform: 'translateY(0px)',
                'z-index': '0'
            })),
            state('hide', style({
                opacity: 0,
                height: '0px',
                transform: 'translateY(-69px)',
                'z-index': '-1'
            })),
            transition('show => hide', animate('100ms ease-out')),
            transition('hide => show', animate('0.2s 100ms ease-out'))
        ])
    ],

    providers: [MatTab, DatePipe,
       {
           provide: DateAdapter, useClass: AppDateAdapter
       },
       {
           provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
       }
    ]
})
export class EditCustomerComponent implements OnInit {
    public customerEdit: FormGroup;
    public customerServiceCenter: FormGroup;
    public customerRoadsideAssistance: FormGroup;
    public customerInsurance: FormGroup;
    public customerWarranty: FormGroup;
    public currentCustomer;
    public countries: any = [];
    public states: any = [];
    public cities: any = [];
    public preferedCities: any = [];
    public preferedStates: any = [];
    public defaultCountry;
    public defaultState;
    public selectedTab;
    public setFormGroupName = 'customerEdit';
    show = false;
    public checkedBox: boolean = false;
    public phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    public submitted: boolean = false;

    public currentCustomerCountry;
    public currentCustomerState;
    public preferedCustomerState;

    public splitCountryValues;
    public customerCountryID;
    public customerCountryName;
    public splitStateValues;
    public customerStateID;
    public customerStateName;
    public splitPreferedStateValues;
    public preferedCustomerStateID = "";
    public preferedCustomerStateName ="";
    selectedIndex:number=0;
    public requireApprovalCheck:boolean;
    public requireApprovalAmount : boolean = false;
    
    public vcountries={};
    public vstates=[''];
    public vcities=[''];
    public disableStates;
    public disableCities;
    public stateBlank;
    public cityBlank;
    public newCountryName='';
    public newStateName;
    public newCityName;
    public selectedCountry;
    public selectedState;
    public selectedCity;
    
    public prefCountries={};
    public prefStates=[''];
    public prefCities=[''];
    public disablePrefStates;
    public disablePrefCities;
    public preferedCustomerStateValue;
    public preferedCustomerCityValue;
    public timezone='';
    public timezoneData=[];
    public zipLabel  = 'Zip Code';
    public sZipLabel = 'Zip Code';
    
    constructor(private activatedRoute: ActivatedRoute, private appComponent: AppComponent, private formBuilder: FormBuilder, private route: ActivatedRoute, public titleService: Title, private http: Http, private router: Router, private globalService: GlobalService, private datePipe: DatePipe, private dateAdapter: DateAdapter<Date>, public dialog: MatDialog) {
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInCustomer"));
        this.globalService.callAPI(globalConstants.API_GET_COUNTRIES_LIST, "opr=1").subscribe((data:any) => {
            this.countries = data.data;
            /* FOR V2 SELECT */
            var clist=[];
            for(var i in this.countries){
                this.countries[i].id=this.countries[i].country_id;
                this.countries[i].text=this.countries[i].country_name;                                        
                clist.push(this.countries[i]);
                this.vcountries[this.countries[i].id]=this.countries[i].country_name;
            }
            this.countries=clist;
            /* END FOR V2 SELECT */
        });
        this.globalService.setMetaData("CUSTOMER","EDIT_PROFILE")
        this.initializeForBuilders();
        //this.titleService.setTitle(lang.TITLE_CUSTOMER_ACCOUNT);

        /* SET TABS IF COME FROM DASHBOARD */

        this.activatedRoute.params.subscribe((params: Params) => {
            // console.log(params)
           if(params.type != null && params.type !='') {
             let valIndex=params.type;
             this.selectedIndexChange(valIndex)
           }  else {
            this.selectedIndexChange(0)
           }
        });
        /* END */
    }

    ngOnInit() {
       this.getCurrentCustomerDetails();
       //this.get_customer_service_center_detail(0);
       this.get_customer_roadside_assistance_details(0);
       this.get_customer_insurance_company_details(0);
       this.get_customer_warranty_company_details(0);
    }

    ngAfterViewInit(){}

    initializeForBuilders() {
        this.customerEdit = this.formBuilder.group({
            email: [{value: this.currentCustomer.email, disabled: true}, [Validators.required, Validators.pattern(globalConstants.EMAIL_REGEX)]],
            customer_id: [this.currentCustomer.id],
            first_name: ['', Validators.required],
            middle_name: [''],
            last_name: ['', Validators.required],
            business_name: [''],
            address_one: [''],
            address_two: [''],
            city: [''],
            state: [''],
            frm_state: [''],
            zipcode: [''],
            country: ['USA'],
            frm_country: [''],
            home_phone: [''],
            business_phone: [''],
            business_name_present: [false],
            mobile_phone: ['', [Validators.required, Validators.minLength(10), this.globalService.mobileNumberValidation]],
            require_approval: [''],
            approval_amount: [''],
            timezone: [''],
        });
        //this.getStates(this.currentCustomer.state,this.customerEdit);
        this.customerServiceCenter = this.formBuilder.group({
            email: ['', [Validators.pattern(EMAIL_REGEX)]],
            customer_id: [this.currentCustomer.id],
            contact_name: [''],
            business_name: [''],
            address_one: [''],
            address_two: [''],
            country: ['USA'],
            city: [''],
            state: [''],
            pre_state: [''],
            zipcode: [''],
            mobile_phone: [''],
            website_url: [''],
        });
        this.customerRoadsideAssistance = this.formBuilder.group({
            business_name: [''],
            customer_id: [this.currentCustomer.id],
            contact_name: [''],
            mobile_phone: [''],
            notes: [''],
        });
        this.customerInsurance = this.formBuilder.group({
            business_name: [''],
            customer_id: [this.currentCustomer.id],
            contact_name: [''],
            local_office_phone: ['',],
            emergency_phone: ['',],
            notes: [''],
        });
        this.customerWarranty = this.formBuilder.group({
            business_name: [''],
            customer_id: [this.currentCustomer.id],
            contact_name: [''],
            mobile_phone: [''],
            policy_number: [''],
            expiration_date: [''],
            expiration_date_frm: [''],
            notes: [''],
        });

         /**
         * update Zip codee validation
         */
          this.customerEdit.get("country").valueChanges.subscribe(country_name => {  
           if(country_name && Array.isArray(this.countries)){                 
                let zRegex = this.countries.find(res=>(res.country_id==country_name || res.country_name==country_name));
                if(zRegex && 'zipcode_regex' in zRegex){
                    this.zipLabel = zRegex.zipcode_label;
                    this.customerEdit.controls.zipcode.setValidators([Validators.required, Validators.pattern(zRegex.zipcode_regex)]);
                    this.customerEdit.controls.zipcode.updateValueAndValidity();
                }          
            }                
        });

        this.customerServiceCenter.get("country").valueChanges.subscribe(country_id => {  
            if(country_id){              
                let zRegex = this.countries.find(res=>res.country_id==country_id);
                if(zRegex && 'zipcode_regex' in zRegex){
                    this.sZipLabel = zRegex.zipcode_label;
                    this.customerServiceCenter.controls.zipcode.setValidators([Validators.required, Validators.pattern(zRegex.zipcode_regex)]);
                    this.customerServiceCenter.controls.zipcode.updateValueAndValidity();
                }          
            }                
        });

    }
    
    /* EDIT PROFILE FUNCTIONS FOR COUNTRY, STATE, CITY */
    getSelectedOptionCountry(opt) {
        this.disableStates = true;
        this.getStates({value:opt+'|'+this.vcountries[opt]})
        //this.newCountryName = this.vcountries[opt];
        this.customerEdit.controls.country.setValue(this.vcountries[opt]);
        this.customerEdit.controls.state.setValue('');
        this.customerEdit.controls.city.setValue('');
        this.getTimezone(opt);
    }
    
    getSelectedOptionState(opt) {
        this.disableCities = true;
        this.getCities({value:opt+'|'+this.vstates[opt]})
        this.newStateName = this.vstates[opt];
        this.customerEdit.controls.state.setValue(this.vstates[opt]);
        this.customerEdit.controls.city.setValue('');
        this.cityBlank = '';
    }
    
    getSelectedOptionCity(opt) {
        this.newCityName = opt;
        this.customerEdit.controls.city.setValue(opt);
    }
    /* END */
    
    /* EDIT PROFILE FUNCTIONS FOR COUNTRY, STATE, CITY */
    getSelectedOptionPrefCountry(opt) {
        console.log("opt1", opt, this.vcountries[opt]);
        this.getPreferedStates({value:opt})
        //this.newCountryName = this.vcountries[opt];
        this.customerServiceCenter.controls.country.setValue(opt);
        this.customerServiceCenter.controls.state.setValue('');
        this.customerServiceCenter.controls.city.setValue('');
    }
    
    getSelectedOptionPrefState(opt,stateID) {
        console.log("opt2", opt, this.prefStates[opt]);
        this.getPreferedCities({value:opt+'|'+this.prefStates[opt]})
        //this.newStateName = this.prefStates[opt];
        this.customerServiceCenter.controls.state.setValue(this.prefStates[opt]);
        this.customerServiceCenter.controls.city.setValue('');
     }
    
    getSelectedOptionPrefCity(opt,cityName) {
        console.log("opt3", opt);
        //this.newCityName = opt;
        this.customerServiceCenter.controls.city.setValue(opt);
    }
    /* END */
    
    editCustomerData(whereToGo) {
        Object.keys(this.customerEdit.controls).map((controlName) => {
            this.customerEdit.get(controlName).markAsTouched({onlySelf: true});
        });            
        if (this.customerEdit.valid) {
            /* 
            if(this.customerEdit.value.frm_country == undefined || this.customerEdit.value.frm_country == 'undefined') {
                this.customerEdit.controls.country.setValue('');
                this.customerEdit.controls.state.setValue('');
                this.customerEdit.controls.city.setValue('');
            } else {
                this.customerEdit.controls.country.setValue(this.customerCountryName);
            }

            if(this.customerStateName == undefined || this.customerStateName == 'undefined' || this.customerEdit.controls.frm_state == null || this.customerEdit.value.frm_state == "") {
                this.customerEdit.controls.state.setValue('');
                this.customerEdit.controls.city.setValue('');
            } else {
                this.customerEdit.controls.state.setValue(this.customerStateName);
            }

            if(this.customerEdit.value.city == 'undefined' || this.customerEdit.value.city == undefined || this.customerEdit.value.city == "null" || this.customerEdit.value.city == "" || this.customerEdit.controls.city == null) {
                this.customerEdit.controls.city.setValue('');
            } */
            if(!this.customerEdit.value.timezone || this.customerEdit.value.timezone == null){
                this.customerEdit.patchValue({timezone : ''});
            }
            var content = globalFunctions.stringifyFormData(this.customerEdit.value, null);
            this.globalService.callAPI(globalConstants.API_EDIT_CUSTOMER_PROFILE_URL, content).subscribe((data:any) => {
                    if (data.code == "506") {
                        this.globalService.snackbar('success', data.message);
                        if(data.data.state){ //handle customer state name for license plate default state
                            localStorage.setItem('customerState', data.data.state)
                        }else{
                            localStorage.removeItem('customerState');
                        }
                        if (whereToGo == 1) {
                            this.gotoDashboard();
                        }
                    } else {
                        this.globalService.snackbar('error', data.message);
                    }
                });

        } else {
            this.submitted = true;
            this.globalService.snackbar('error', 'Please fill all the mandatory fields');
        }
    }

    onlyNumericKey(event) {
        return globalFunctions.onlyDecimalNumberKey(event);
    }

    selectedIndexChange(val :number ){
        this.selectedIndex=val;
        let indexi=val;
        if (indexi == 0) {
            setTimeout(() => {this.getCurrentCustomerDetails()}, 100)
        } else if (indexi == 1) {
            setTimeout(() => {this.get_customer_service_center_detail(0)}, 100)
        } else if (indexi == 2) {
            setTimeout(() => {this.get_customer_roadside_assistance_details(0)}, 100)
        } else if (indexi == 3) {
            setTimeout(() => {this.get_customer_insurance_company_details(0)}, 100)
        } else if (indexi == 4) {
            setTimeout(() => {this.get_customer_warranty_company_details(0)}, 100)
        }
    }
//    getPreferredServiceCenter($event: any) {
//        //console.log($event.index);
//        this.selectedTab = $event.index;
//        //this.setFormGroupName = $event.index;
//        if ($event.index == 0) {
//            setTimeout(() => {this.getCurrentCustomerDetails()}, 100)
//        } else if ($event.index == 1) {
//            setTimeout(() => {this.get_customer_service_center_detail(0)}, 100)
//        } else if ($event.index == 2) {
//            setTimeout(() => {this.get_customer_roadside_assistance_details(0)}, 100)
//        } else if ($event.index == 3) {
//            setTimeout(() => {this.get_customer_insurance_company_details(0)}, 100)
//        } else if ($event.index == 4) {
//            setTimeout(() => {this.get_customer_warranty_company_details(0)}, 100)
//        }
//    }

    getCurrentCustomerDetails() {
        var content = "customer_id=" + this.currentCustomer.id;
        this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_DETAILS, content).subscribe((data:any) => {
            var response = data.data;

            this.customerEdit.controls.first_name.setValue(response.first_name);
            this.customerEdit.controls.middle_name.setValue(response.middle_name);
            this.customerEdit.controls.last_name.setValue(response.last_name);
            this.customerEdit.controls.business_name.setValue(response.business_name);
            this.customerEdit.controls.address_one.setValue(response.address1);
            this.customerEdit.controls.address_two.setValue(response.address2);
            //this.customerEdit.controls.city.setValue(response.city);
            //this.customerEdit.controls.state.setValue(response.state);
            //this.customerEdit.controls.country.setValue(response.country);
            this.customerEdit.controls.zipcode.setValue(response.zip);
            this.customerEdit.controls.home_phone.setValue(response.home_phone);
            this.customerEdit.controls.business_phone.setValue(response.business_phone);
            this.customerEdit.controls.business_name_present.setValue(response.business_name_present);
            this.customerEdit.controls.mobile_phone.setValue(response.mobile_phone);
            this.customerEdit.controls.approval_amount.setValue(response.approval_amount);
            this.customerEdit.controls.timezone.setValue(response.time_zone);
            if (response.require_approval == '1') {
                this.requireApprovalCheck = true;
                this.requireApprovalAmount = true;
                this.customerEdit.controls.require_approval.setValue(1);
            } else {
                this.requireApprovalCheck = false;
                this.requireApprovalAmount = false;
                this.customerEdit.controls.require_approval.setValue(0);
            }
            /* SET COUNTRY ID TO GET STATE */
            if(response.country != "") {
                this.currentCustomerCountry = response.country_id+"|"+response.country;
                //this.currentCustomerCountry = response.country_id;
                this.selectedCountry = response.country_id;
            } else {
                this.currentCustomerCountry = "USA"+"|"+"USA";
                this.selectedCountry = "USA";
            }

            this.customerEdit.controls.country.setValue(response.country);

            var event = {
                value: this.currentCustomerCountry
            }

            this.getStates(event);
            this.getTimezone(this.selectedCountry);
            /* SET STATE ID TO GET CITY NAME */

            if(response.state != "") {
                this.currentCustomerState = response.state_id+"|"+response.state;
                //this.currentCustomerState = response.state_id;
                this.selectedState = response.state_id;
            } else {
                this.currentCustomerState = '';
                this.selectedState = '';
            }

            let cityName = {
                value: this.currentCustomerState
            }

            this.getCities(cityName,response.city);
            this.customerEdit.controls.state.setValue(response.state);
            
            if(response.city != "") {
                this.selectedCity = response.city;
            } else {
                this.selectedCity = '';
            }
            this.customerEdit.controls.city.setValue(response.city);
            /* END */

            if (response.business_name != null && response.business_name != " " && response.business_name != "") {
                this.checkedBox = true;
                this.show = true;
            } else {
                this.checkedBox = false;
                this.show = false;
            }
        })
    }

    get_customer_service_center_detail(whereToGo) {
        var content = "customer_id=" + this.currentCustomer.id;
        var response ;
        this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_PREF_SERVICE_CENTER_DETAILS_URL, content).subscribe((data:any) => {
                response = data.data;
                this.customerServiceCenter.controls.email.setValue(response["service_center_email"]);
                this.customerServiceCenter.controls.contact_name.setValue(response["service_center_contact_name"]);
                this.customerServiceCenter.controls.business_name.setValue(response["service_center_business_name"]);
                this.customerServiceCenter.controls.address_one.setValue(response["service_center_address1"]);
                this.customerServiceCenter.controls.address_two.setValue(response["service_center_address2"]);
                this.customerServiceCenter.controls.country.setValue(response["service_center_country"]);
                this.customerServiceCenter.controls.city.setValue(response["service_center_city"])
                
                this.preferedCustomerCityValue = response["service_center_city"];
                //this.customerServiceCenter.controls.state.setValue(response["service_center_state"]);
                this.customerServiceCenter.controls.zipcode.setValue(response["service_center_zip"]);
                this.customerServiceCenter.controls.mobile_phone.setValue(response["service_center_phone"]);
                this.customerServiceCenter.controls.website_url.setValue(response["service_center_website_url"]);
                /* COUNTRY */
                if (response["service_center_country"] != "") {
                    this.defaultCountry = response["service_center_country"];
                } else {
                    this.defaultCountry = 'USA';
                }

                var event = {
                    value: this.defaultCountry
                }

                this.getPreferedStates(event,response["service_center_state_id"]);

                /* SET STATE ID TO GET CITY NAME */
                if (response["service_center_state"] != "") {
                    this.preferedCustomerState = response["service_center_state_id"]+"|"+response["service_center_state"];
                    this.preferedCustomerStateValue = response["service_center_state_id"];
                } else {
                    this.preferedCustomerState = '';
                    this.preferedCustomerStateValue = '';
                }
                
                this.customerServiceCenter.controls.state.setValue(response["service_center_state"]);

                let cityName = {
                    value: this.preferedCustomerState
                }

                this.getPreferedCities(cityName, response["service_center_city"]);
                 /* END */
            });
    }

    get_customer_roadside_assistance_details(whereToGo) {

        var response = "";

        var content = "customer_id=" + this.currentCustomer.id;
        this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_ROADSIDE_ASSISTANCE_DETAILS_URL, content).subscribe((data:any) => {
                response = data.data;
                this.customerRoadsideAssistance.controls.business_name.setValue(response["roadside_assistance_business_name"])
                this.customerRoadsideAssistance.controls.contact_name.setValue(response["roadside_assistance_contact_name"])
                this.customerRoadsideAssistance.controls.mobile_phone.setValue(response["roadside_assistance_phone"])
                this.customerRoadsideAssistance.controls.notes.setValue(response["roadside_assistance_notes"])
            })
    }

    get_customer_insurance_company_details(whereToGo) {
        var response = "";
        var content = "customer_id=" + this.currentCustomer.id;
        this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_INSURANCE_COMPANY_DETAILS_URL, content).subscribe((data:any) => {
            response = data.data;
            this.customerInsurance.controls.business_name.setValue(response["insurance_company_business_name"])
            this.customerInsurance.controls.contact_name.setValue(response["insurance_company_contact_name"])
            this.customerInsurance.controls.local_office_phone.setValue(response["insurance_company_local_phone"])
            this.customerInsurance.controls.emergency_phone.setValue(response["insurance_company_emergency_phone"])
            this.customerInsurance.controls.notes.setValue(response["insurance_company_notes"])
        })
    }

    get_customer_warranty_company_details(whereToGo) {
        var response = "";

        var content = "customer_id=" + this.currentCustomer.id;
        this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_WARRANTY_COMPANY_DETAILS_URL, content).subscribe((data:any) => {
                response = data.data;
                this.customerWarranty.controls.business_name.setValue(response["warranty_company_business_name"])
                this.customerWarranty.controls.contact_name.setValue(response["warranty_company_contact_name"])
                this.customerWarranty.controls.mobile_phone.setValue(response["warranty_company_phone"])
                this.customerWarranty.controls.policy_number.setValue(response["warranty_company_policy_number"])
                if(response["warranty_company_expiration_date"]!="") {
                    let fDate=this.globalService.getFullFormateDate(response["warranty_company_expiration_date"],'L','B');
                    this.customerWarranty.controls.expiration_date_frm.setValue(new Date(fDate));
                }

                this.customerWarranty.controls.notes.setValue(response["warranty_company_notes"])
                //console.log(response)
            })
    }

    /* GET STATES */
    getStates(event) {
        //console.log("event1", event, "value1", event.value);
        if (event.value == undefined || event.value == "") {
            /*this.customerEdit.controls.frm_state.disable();
            this.customerEdit.controls.city.disable();
            this.customerEdit.controls.frm_state.reset();
            this.customerEdit.controls.city.reset();*/
            
            this.disableStates = true;
            this.disableCities = true;
            this.vstates=[];
            this.vcities=[];
        } else {
            /*this.customerEdit.controls.frm_state.enable();
            this.customerEdit.controls.city.disable();
            this.customerEdit.controls.frm_state.setValue('');
            this.customerEdit.controls.state.setValue('');*/
            /*this.states = [];
            this.cities = [];*/
            
            this.disableCities = true;
            this.vstates=[];
            this.vcities=[];
              /* Split country values */
            this.splitCountryValues  = event.value.split("|");
            this.customerCountryID   = this.splitCountryValues[0];
            this.customerCountryName = this.splitCountryValues[1];
            /* END */
            this.globalService.callAPI(globalConstants.API_GET_STATES_URL, "opr=1&country_id=" + this.customerCountryID).subscribe((data:any) => {
                var responseData = data;
                if (responseData.result == "1") {
                    this.disableStates = false;
                    var slist=[];
                    if (responseData.data.length == undefined) {
                        console.log("YE1");
                        this.states[0]  = responseData.data;
                        /* FOR V2 SELECT */
                        this.states[0].id  =this.states[0].state_id;
                        this.states[0].text=this.states[0].state_name;          
                        slist.push(this.states[0]);
                        this.vstates[this.states[0].state_id]=this.states[0].state_name;
                        this.states=slist;
                        /* END FOR V2 SELECT */
                    } else {
                        this.states = responseData.data;
                        for(var i in this.states){
                            this.states[i].id=this.states[i].state_id;
                            this.states[i].text=this.states[i].state_name;
                            slist.push(this.states[i]);
                            this.vstates[this.states[i].state_id]=(this.states[i].state_name);
                        }
                        this.states=slist;
                        /* END FOR V2 SELECT */
                    }
                } else {
                    //
                    this.states = [];
                    this.cities = [];
                    //this.customerEdit.controls.city.disable();
                    
                    /* FOR V2 SELECT */
                    this.vstates=[];
                    this.vcities=[];
                    this.disableStates = false;
                    this.disableCities = true;
                    /* END FOR V2 SELECT */
                }
            });
        }
    }

    /* GET CITIES */
    getCities(event,cityValue?:any) {
        if (event.value == undefined || event.value == "") {
            //this.customerEdit.controls.city.disable();
            //this.cities = [];
            this.disableCities = true;
            this.vcities=[];
        } else {
            this.splitStateValues  = event.value.split("|");
            this.customerStateID   = this.splitStateValues[0];
            if(this.customerStateID != "") {
                this.customerStateName = this.splitStateValues[1];
            } else {
                this.customerStateName = '';
            }

              //this.customerEdit.controls.city.enable();
            this.vcities=[];
            this.globalService.callAPI(globalConstants.API_GET_CITIES_URL, "opr=1&state_id=" + this.customerStateID).subscribe((data:any) => {
                var responseData = data;
                if (responseData.result == "1") {
                    this.disableCities = false;
                    var clist=[];
                    if (responseData.data.length == undefined || responseData.data.length == 'undefined') {
                        this.cities[0] = responseData.data;
                        /* FOR V2 SELECT */
                        this.cities[0].id=this.cities[0].city_name;
                        this.cities[0].text=this.cities[0].city_name;          
                        clist.push(this.cities[0]);
                        this.vcities=clist;
                        /* END FOR V2 SELECT */
                    } else {
                        this.cities = responseData.data;
                        /* FOR V2 SELECT */
                        for(var i in this.cities){
                            this.cities[i].id=this.cities[i].city_name;
                            this.cities[i].text=this.cities[i].city_name;          
                            clist.push(this.cities[i]);
                        }
                        this.vcities=clist;
                        /* END FOR V2 SELECT */
                    }
                } else {
                    //this.cities = [];
                    //this.customerEdit.controls.city.disable();
                    /* FOR V2 SELECT */
                    this.disableCities = false;
                    this.vcities=[];
                    /* END FOR V2 SELECT */
                }
                if(typeof cityValue!='undefined' && cityValue!='' )
                this.selectedCity = cityValue;
            })
        }
    }

    /* GET PREFERE CUSTOMERS COUNTRY/STATES/CITY */

    getPreferedStates(event,stateRefId?:any) {
        console.log("EVENT", event);
        console.log("EVENT VALUE", event.value);
        if (event.value == undefined || event.value == "") {
            /*this.customerServiceCenter.controls.pre_state.disable();
            this.customerServiceCenter.controls.city.disable();
            this.customerServiceCenter.controls.pre_state.reset();
            this.customerServiceCenter.controls.city.reset();*/
            
            this.preferedStates = [];
            this.preferedCities = [];
            
            this.disablePrefStates = true;
            this.disablePrefCities = true;
            this.prefStates=[];
            this.prefCities=[];
        } else {
            /*this.customerServiceCenter.controls.pre_state.enable();
            this.customerServiceCenter.controls.city.disable();
            this.customerServiceCenter.controls.pre_state.setValue('');
            this.customerServiceCenter.controls.state.setValue('');*/
            
            this.preferedStates = [];
            this.preferedCities = [];
            
            this.disablePrefCities = true;
            this.prefStates=[];
            this.prefCities=[];
            this.globalService.callAPI(globalConstants.API_GET_STATES_URL, "opr=1&country_id=" + event.value).subscribe((data:any) => {
                var responseData = data;
                if (responseData.result == "1") {
                    this.disablePrefStates = false;
                    var slist=[];
                    if (responseData.data.length == undefined) {
                        this.preferedStates[0] = responseData.data;
                        /* FOR V2 SELECT */
                        this.preferedStates.id=this.preferedStates.state_id;
                        this.preferedStates.text=this.preferedStates.state_name;          
                        slist.push(this.preferedStates);
                        this.prefStates[this.preferedStates.state_id]=this.preferedStates.state_name;
                        this.preferedStates=slist;
                        /* END FOR V2 SELECT */
                    } else {
                        this.preferedStates = responseData.data;
                        /* FOR V2 SELECT */
                        for(var i in this.preferedStates){
                            this.preferedStates[i].id=this.preferedStates[i].state_id;
                            this.preferedStates[i].text=this.preferedStates[i].state_name;
                            slist.push(this.preferedStates[i]);
                            this.prefStates[this.preferedStates[i].state_id]=(this.preferedStates[i].state_name);
                        }
                        this.preferedStates=slist;
                        /* END FOR V2 SELECT */
                    }
                } else {
                    this.preferedStates = [];
                    this.preferedCities = [];
                    //this.customerEdit.controls.city.disable();
                    /* FOR V2 SELECT */
                    this.prefStates=[];
                    this.disablePrefStates = false;
                    this.prefCities=[];
                    this.disablePrefCities = true;
                    /* END FOR V2 SELECT */
                }
                if(typeof stateRefId!='undefined' && stateRefId!='' )
                 this.preferedCustomerStateValue = stateRefId;
            });
        }
    }

    getPreferedCities(event,cityValue?:any) {
        if (event.value == undefined || event.value == "") {
            /*this.customerServiceCenter.controls.city.disable();
            this.customerServiceCenter.controls.city.reset();*/
            this.disablePrefCities = true;
            this.prefCities=[];
        } else {
            /* Split States values */
            // console.log("State", this.customerServiceCenter.value.pre_state)
            this.splitPreferedStateValues  = event.value.split("|");
            this.preferedCustomerStateID   = this.splitPreferedStateValues[0];
            if(this.preferedCustomerStateID != "") {
                this.preferedCustomerStateName = this.splitPreferedStateValues[1];
            } else {
                this.preferedCustomerStateName = '';
            }

            /* END */
            //this.customerServiceCenter.controls.city.enable();
            this.prefCities=[];
            this.globalService.callAPI(globalConstants.API_GET_CITIES_URL, "opr=1&state_id=" + this.preferedCustomerStateID).subscribe((data:any) => {
                var responseData = data;
                if (responseData.result == "1") {
                    this.disablePrefCities = false;
                    var clist=[];
                    if (responseData.data.length == undefined || responseData.data.length == 'undefined') {
                        this.preferedCities[0] = responseData.data;
                        /* FOR V2 SELECT */
                        this.preferedCities[0].id=this.preferedCities[0].city_name;
                        this.preferedCities[0].text=this.preferedCities[0].city_name;          
                        clist.push(this.preferedCities[0]);
                        this.prefCities=clist;
                        /* END FOR V2 SELECT */
                    } else {
                        this.preferedCities = responseData.data;
                        /* FOR V2 SELECT */
                        for(var i in this.preferedCities){
                            this.preferedCities[i].id=this.preferedCities[i].city_name;
                            this.preferedCities[i].text=this.preferedCities[i].city_name;          
                            clist.push(this.preferedCities[i]);
                        }
                        this.prefCities=clist;
                        /* END FOR V2 SELECT */
                    }
                } else {
                    this.preferedCities = [];
                    //this.customerEdit.controls.city.disable();
                    /* FOR V2 SELECT */
                    this.disablePrefCities = false;
                    this.vcities=[];
                    /* END FOR V2 SELECT */
                }
                if(typeof cityValue!='undefined' && cityValue!='' )
                this.preferedCustomerCityValue = cityValue;
            })
        }
    }

    /* END */
    update_customer_service_center_detail(whereToGo) {
        if (this.customerServiceCenter.valid) {
            if (this.customerServiceCenter.value.email == undefined || this.customerServiceCenter.value.email == 'undefined') {
                this.customerServiceCenter.controls.email.setValue('');
            }
            if (this.customerServiceCenter.value.website_url == undefined || this.customerServiceCenter.value.website_url == 'undefined') {
                this.customerServiceCenter.controls.website_url.setValue('');
            }

            /*if (this.customerServiceCenter.value.country == undefined || this.customerServiceCenter.value.country == 'undefined') {
                this.customerServiceCenter.controls.country.setValue('');
                this.customerEdit.controls.state.setValue('');
                this.customerEdit.controls.city.setValue('');
            }

            if (this.customerServiceCenter.value.pre_state == undefined || this.customerServiceCenter.value.pre_state == 'undefined' || this.customerServiceCenter.controls.pre_state == null || this.customerServiceCenter.value.pre_state == "") {
                this.customerServiceCenter.controls.pre_state.setValue('');
                this.customerServiceCenter.controls.state.setValue('');
                this.customerServiceCenter.controls.city.setValue('');
            } else {
                this.customerServiceCenter.controls.state.setValue(this.preferedCustomerStateName);
            }*/

            /*if(this.customerServiceCenter.value.city == 'undefined' || this.customerServiceCenter.value.city == undefined || this.customerServiceCenter.value.city == "null" || this.customerEdit.value.city == "" || this.customerEdit.controls.city == null) {
                this.customerServiceCenter.controls.city.setValue('');
            }*/


            var content = globalFunctions.stringifyFormData(this.customerServiceCenter.value, null)
            this.globalService.callAPI(globalConstants.API_EDIT_CUSTOMER_PREF_SERVICE_CENTER_PROFILE_URL, content ).subscribe((data:any) => {
                var resultData = data;
                if (resultData.result == 1) {
                    if (whereToGo == 1) {
                        this.gotoDashboard();
                    }
                    this.globalService.snackbar('success', resultData.message);
                } else {
                    this.globalService.snackbar('error', resultData.message);
                }
            })
        } else {
            this.globalService.snackbar('error', 'Please fill all the mandatory fields');
        }
    }

    update_customer_roadside_assistance_details(whereToGo) {
        if (this.customerRoadsideAssistance.valid) {

            var content = globalFunctions.stringifyFormData(this.customerRoadsideAssistance.value, null)
            this.globalService.callAPI(globalConstants.API_EDIT_CUSTOMER_ROADSIDE_ASSISTANCE_PROFILE_URL, content ).subscribe((data:any) => {
                var resultData = data;
                if (resultData.result == 1) {
                    if (whereToGo == 1) {
                        this.gotoDashboard();
                    }
                    this.globalService.snackbar('success', resultData.message);
                } else {
                    this.globalService.snackbar('error', resultData.message);
                }
            })
        } else {
            this.submitted = true;
            //this.globalService.snackbar('error', 'Please fill all the mandatory fields');
        }
    }

    update_customer_insurance_company_details(whereToGo) {
        if (this.customerInsurance.valid) {

            var content = globalFunctions.stringifyFormData(this.customerInsurance.value, null)
            this.globalService.callAPI(globalConstants.API_EDIT_CUSTOMER_INSURANCE_COMPANY_PROFILE_URL, content ).subscribe((data:any) => {
                var resultData = data;
                if (resultData.result == 1) {
                    if (whereToGo == 1) {
                        this.gotoDashboard();
                    }
                    this.globalService.snackbar('success', resultData.message);
                } else {
                    this.globalService.snackbar('error', resultData.message);
                }
            })
        } else {
            this.submitted = true;
        }
    }

    onInput(obj) {
        obj.targetElement.value = this.dateAdapter.toIso8601(new Date(obj.target.value));
    }

    update_customer_warranty_company_details(whereToGo) {
        //console.log("Values", this.customerWarranty.value.expiration_date_frm)
        if (this.customerWarranty.valid) {

            if (this.customerWarranty.value.expiration_date_frm == null || this.customerWarranty.value.expiration_date_frm == '' || this.customerWarranty.value.expiration_date_frm == undefined) {
                this.customerWarranty.controls.expiration_date.setValue('');
            } else {
                this.customerWarranty.controls.expiration_date.setValue(this.datePipe.transform(new Date(this.customerWarranty.value.expiration_date_frm), 'MM/dd/yyyy'))
            }


            var content = globalFunctions.stringifyFormData(this.customerWarranty.value, null)
            this.globalService.callAPI(globalConstants.API_EDIT_CUSTOMER_WARRANTY_COMPANY_PROFILE_URL, content).subscribe((data:any) => {
                var resultData = data;
                if (resultData.result == 1) {
                    if (whereToGo == 1) {
                        this.gotoDashboard();
                    }
                    this.globalService.snackbar('success', resultData.message);
                } else {
                    this.globalService.snackbar('error', resultData.message);
                }

                /*this.showSuccessMessage();
                if (whereToGo == 1) {
                    this.gotoDashboard();
                }*/
            })
        } else {
            this.submitted = true;
        }
    }

    gotoDashboard() {
        this.router.navigate(['/customer/dashboard'])
    }
    showSuccessMessage() {
        this.globalService.snackbar('success', 'Data Successfully Updated');
    }
    get showBusinessName() {
        return this.show ? 'show' : 'hide'
    }
    businessNameToggle() {
        this.show = !this.show;
    }
    setTouchViewValue() {
        return this.appComponent.touchViewState;
    }
    checkIfValid() {
        Object.keys(this.customerEdit.controls).map((controlName) => {
            this.customerEdit.get(controlName).markAsTouched({onlySelf: true});
        });
        if (this.customerEdit.valid) {
            //console.log("valid");
            this.submitted = false;
        }
    }

    /* PHONE NUMBER FORMAT VALIDATIONS IF NOT REQUIRED */

    /* Home Phone Validity Check */
    checkHomePhoneValidity() {
        Window["errorObj"] = this.customerEdit
        let home_phone = this.customerEdit.value.home_phone;
        if (home_phone != "") {
            let phoneResult = this.customerEdit.value.home_phone.replace(/\D+/g, "").length >= 10 ? false : {minLength: true};
            if (phoneResult == false) {
                this.customerEdit.controls.home_phone.setErrors(null);
                this.submitted = false;
            } else {
                this.customerEdit.controls.home_phone.setErrors({"phoneError": true});
            }
        } else {
            this.customerEdit.controls.home_phone.setErrors(null);
            this.submitted = false;
        }
    }

    /* Business Phone Validity Check */
    checkBusinessPhoneValidity() {
        Window["errorObj"] = this.customerEdit;
        let home_phone = this.customerEdit.value.business_phone;
        if (home_phone != "") {
            let phoneResult = this.customerEdit.value.business_phone.replace(/\D+/g, "").length >= 10 ? false : {minLength: true};
            if (phoneResult == false) {
                this.customerEdit.controls.business_phone.setErrors(null);
                this.submitted = false;
            } else {
                this.customerEdit.controls.business_phone.setErrors({"phoneError": true});
            }
        } else {
            this.customerEdit.controls.business_phone.setErrors(null);
            this.submitted = false;
        }
    }

    /* Prefered Service Phone Validity Check */
    preferedServicesPhoneValidity() {
        Window["errorObj"] = this.customerServiceCenter;
        let home_phone = this.customerServiceCenter.value.mobile_phone;
        if (home_phone != "") {
            let phoneResult = this.customerServiceCenter.value.mobile_phone.replace(/\D+/g, "").length >= 10 ? false : {minLength: true};
            if (phoneResult == false) {
                this.customerServiceCenter.controls.mobile_phone.setErrors(null);
                this.submitted = false;
            } else {
                this.customerServiceCenter.controls.mobile_phone.setErrors({"phoneError": true});
            }
        } else {
            this.customerServiceCenter.controls.mobile_phone.setErrors(null);
            this.submitted = false;
        }
    }

    /* Road Side Assistance Phone Validity Check */
    roadSideAssistancePhoneValidity() {
        Window["errorObj"] = this.customerRoadsideAssistance;
        let home_phone = this.customerRoadsideAssistance.value.mobile_phone;
        if (home_phone != "") {
            let phoneResult = this.customerRoadsideAssistance.value.mobile_phone.replace(/\D+/g, "").length >= 10 ? false : {minLength: true};
            if (phoneResult == false) {
                this.customerRoadsideAssistance.controls.mobile_phone.setErrors(null);
                this.submitted = false;
            } else {
                this.customerRoadsideAssistance.controls.mobile_phone.setErrors({"phoneError": true});
            }
        } else {
            this.customerServiceCenter.controls.mobile_phone.setErrors(null);
            this.submitted = false;
        }
    }

    /* Insurance Company Phone Validity Check */
    localOfficePhoneValidity() {
        let home_phone = this.customerInsurance.value.local_office_phone;
        if (home_phone != "") {
            let phoneResult = this.customerInsurance.value.local_office_phone.replace(/\D+/g, "").length >= 10 ? false : {minLength: true};
            if (phoneResult == false) {
                this.customerInsurance.controls.local_office_phone.setErrors(null);
                this.submitted = false;
            } else {
                this.customerInsurance.controls.local_office_phone.setErrors({"phoneError": true});
            }
        } else {
            this.customerInsurance.controls.local_office_phone.setErrors(null);
            this.submitted = false;
        }
    }

    /* Emergency Phone number validity check */
    emergencyPhoneValidity() {
        let home_phone = this.customerInsurance.value.emergency_phone;
        if (home_phone != "") {
            let phoneResult = this.customerInsurance.value.emergency_phone.replace(/\D+/g, "").length >= 10 ? false : {minLength: true};
            if (phoneResult == false) {
                this.customerInsurance.controls.emergency_phone.setErrors(null);
                this.submitted = false;
            } else {
                this.customerInsurance.controls.emergency_phone.setErrors({"phoneError": true});
            }
        } else {
            this.customerInsurance.controls.emergency_phone.setErrors(null);
            this.submitted = false;
        }
    }

    /* Emergency Phone number validity check */
    warrantyPhoneValidity() {
        let home_phone = this.customerWarranty.value.mobile_phone;
        if (home_phone != "") {
            let phoneResult = this.customerWarranty.value.mobile_phone.replace(/\D+/g, "").length >= 10 ? false : {minLength: true};
            if (phoneResult == false) {
                this.customerWarranty.controls.mobile_phone.setErrors(null);
                this.submitted = false;
            } else {
                this.customerWarranty.controls.mobile_phone.setErrors({"phoneError": true});
            }
        } else {
            this.customerWarranty.controls.mobile_phone.setErrors(null);
            this.submitted = false;
        }
    }

    /* ************************** */
    phoneValidation(control,key){
        this.globalService.preferedServicesPhoneValidity(control,key);
        this.checkIfValid();
    }
    contacts(){
        let dialogRef = this.dialog.open(UsercontactsComponent, {
            panelClass: 'car-dialog-form',
            width: "800px",
            data: {user_id:'',customer_id:this.currentCustomer.id,user_type:this.currentCustomer.user_type}
          });
        
          dialogRef.afterClosed().subscribe(result => {
        
          });
    }
    
    showRequireApproval(event) {
        if (event.checked) {
            this.requireApprovalAmount = true;
            this.customerEdit.controls.require_approval.setValue(1);
        } else {
            this.requireApprovalAmount = false;
            this.customerEdit.controls.require_approval.setValue(0);
        }
    }
    
    /* animation */
    get checkApprovalAmount() {
        return this.requireApprovalAmount ? 'show' : 'hide'
    }
    
    onlyDecimalNumberKey(event) {
        let charCode = (event.which) ? event.which : event.keyCode;
        if (charCode != 46 && charCode > 31
            && (charCode < 48 || charCode > 57))
            return false;
        return true;
    }

    disableKey(event) {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if (event.ctrlKey==true && (event.which == '67' || event.which == '86' ||  String.fromCharCode(event.which).toLowerCase() == 's')) {
            console.log('thou. shalt. not. PASTE!');
            event.preventDefault();
        }
    }
    /** customer timezone start */
    getSelectedOptionTimezone(event){
        this.customerEdit.controls.timezone.setValue(event);
    }
    /** customer timezone end */
    /** get all timezone start */
    getTimezone(Id){
        
        let data={country_id:Id ,user_type: this.currentCustomer.user_type,user_id: this.currentCustomer.id};
        this.globalService.callAPI(globalConstants.API_GET_TIMEZONE,data).subscribe((zones:any)=>{
            if(zones.result=='1'){
                this.timezoneData=zones.data;
            }else{
                this.timezoneData=[];
            }
        });
    }
    /** get all timezone end */
}
