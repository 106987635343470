import { Component, OnInit, ViewChild } from '@angular/core';
import { GoogleChartInterface, GoogleChartType } from 'ng2-google-charts';
import { GlobalService } from 'src/environments/global.service';
import { MatTableDataSource } from '@angular/material/table';
import { globalConstants } from 'src/environments/globalConstants';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { globalFunctions } from 'src/environments/globalFunctions';
import { Router } from '@angular/router';
const INVENTORY_LIST = 'shop-inventoryComponent';

@Component({
  selector: 'app-inventory-stats',
  templateUrl: './inventory-stats.component.html',
  styleUrls: ['./inventory-stats.component.scss']
})
export class InventoryStatsComponent implements OnInit {
  public inventoryGraph: GoogleChartInterface;
  public options: any = {
    user_type: "User",
    user_id: "",
    search_by: '',
    sort_by: "",
    sort_type: "",
    search_keyword: "",
    search_date: "",
    part_tag: "",
    start: 0,
    limit: 10,
  };
  public displayedColumns = ['name', 'part_number', 'manufacturer', 'matrix_price', 'quantity', 'Picture', 'added_on'];
  public dataSource: MatTableDataSource<InventoryData> = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  public currentUser: any;
  public record: any;
  public recordsTotal: any;
  public showCostPrice = false;
  public partPicture = globalConstants.S3_BUCKET_URL + globalConstants.PART_PICTURE + globalConstants.IMG_THUMB;
  constructor(
    private globalService: GlobalService,
    public router: Router
  ) { 
    setTimeout(()=>{
      let isEnable=this.globalService.getAcl('sales_reports','views');
      if(!(isEnable)){
          this.router.navigate(['shops/action/forbidden']);
      }
    },globalConstants.RELOAD_WAIT)
  }

  ngOnInit(): void {
    this.showCostPrice = this.globalService.getAcl('display_part_cost', 'views');
    this.currentUser = JSON.parse(localStorage.getItem("loggedInUser"));
    this.options.user_id = this.currentUser.id;
    this.getInventoryReportData();
    this.setDataTable();
  }

  getInventoryReportData() {
    let inventoryList = [
      ['Type', 'Part Sales'],
      [
        'Engine Control Compu Engine Control Module',
        10
      ],
      [
        'CLAMP,CHARGE AIR BYPASS VALVE TUBE CONNECTOR',
        20
      ],
      [
        '(OE) COOLER,CHARGE AIR',
        5
      ],
      [
        '(SUN) PS PRESSURE LIN',
        15
      ],

    ]
    this.inventoryGraph = {
      chartType: GoogleChartType.ColumnChart,
      dataTable: inventoryList,
      formatters: [
        {
          columns: [1],
          type: 'NumberFormat',
          options: {
            fractionDigits: 2
          }
        }
      ],
      options: {
        title: 'Top Parts Sold',
        width: '100%',
        height: 350,
        bar: { groupWidth: "35%" },
        animation: {
          duration: 1000,
          easing: 'out',
          startup: true
        },
        vAxis: {
          title: 'Parts Quantity',
          titleTextStyle: { color: '#000' }
        }
      }
    };
  }

  getTotalRecords() {
    return parseInt(localStorage.getItem(INVENTORY_LIST + 'totalRecords'))
  }

  currentDate() {
    return (new Date().getMonth() + 1) + "/" + new Date().getDate() + "/" + new Date().getFullYear()
  }

  paginate(event, type) {
    this.scrolling();
    if (type == "next") {
      this.options.start = this.options.start + this.options.limit;
    } else {
      this.options.start = this.options.start - this.options.limit;
    }
    this.setDataTable();
  }

  firstList() {
    return this.globalService.firstList(INVENTORY_LIST, this.options);
  }

  lastList() {
    return this.globalService.lastList(INVENTORY_LIST);
  }

  getUpperLimit(currentLastLimit, total) {
    if (currentLastLimit > total) {
      return total;
    } else {
      return currentLastLimit;
    }
  }

  setDataTable() {
    this.record = [];
    var callApi = this.globalService.callAPI(globalConstants.API_GET_PARTS_LIST_URL, this.options);
    callApi.subscribe((data: any) => {
      if (data.data != undefined) {
        let moreRows = 'false';
        let firstList = 'false';
        this.globalService.localGetterSetter("totalRecords", data.recordsTotal, INVENTORY_LIST);
        if (data.more_rows == "true") {
          moreRows = "true";
        }
        this.globalService.localGetterSetter("more_rows", moreRows, INVENTORY_LIST);
        if (data.start >= 0 && data.start <= 5) {
          firstList = "true";
        }
        this.globalService.localGetterSetter("first_list", firstList, INVENTORY_LIST);
        this.recordsTotal = data.data.length;
        if (data.data.length == undefined) {
          this.dataSource.data = data.data;
          this.AddRecord(data.data)
          this.globalService.localGetterSetter("current-visible", '1', INVENTORY_LIST);
          this.dataSource = new MatTableDataSource(this.record);
          this.dataSource.sort = this.sort;
        } else {
          this.globalService.localGetterSetter("current-visible", this.recordsTotal, INVENTORY_LIST);
          for (var i = 0; i < data.data.length; i++) {
            this.AddRecord(data.data[i]);
          }
          this.dataSource = new MatTableDataSource(this.record);
          this.dataSource.sort = this.sort;
        }
        this.globalService.localGetterSetter(null, this.options, INVENTORY_LIST);
      } else {
        this.dataSource = new MatTableDataSource(this.record);
        this.globalService.localGetterSetter("totalRecords", '0', INVENTORY_LIST);
      }
    })
  }

  AddRecord(record) {
    var data = this.createRecord(record);
    this.record.push(data);
  }

  private createRecord(record) {
    return {
      name: record.name,
      part_number: (record.part_number) ? (record.part_number) : '-',
      manufacturer: (record.manufacturer) ? (record.manufacturer) : '-',
      price: parseFloat(record.price),
      cost: record.cost,
      description: record.description,
      picture: record.part_picture,
      added_on: this.globalService.getFullFormateDate(record.add_date, 'GD', 'H'),
      part_id: record.id,
      delete_status: record.delete_status,
      quantity: record.quantity,
      low_stock_value: record.low_stock_value,
      is_low_stock: this.isLowStock(record.low_stock_value, record.quantity),
      matrix_price: record.part_matrix_price,
      matrix_status: record.part_matrix_status
    };
  }

  scrolling() {
    this.globalService.setScrolling('.car-datatable', 1000, -20);
  }

  paginateByLimit(event) {
    this.globalService.setScrolling('body', 600, 0);
    this.options.limit = event.pageSize;
    this.options.start = 0;
    this.scrolling();
    this.setDataTable();
  }

  isLowStock(val, quantity) {
    let lowStockQty = parseFloat(val);
    let qty = parseFloat(quantity);
    return (qty <= lowStockQty ? true : false);
  }

  showWholeNumber(val) {
    return globalFunctions.hasDecimal(val, true);
  }
}

export interface InventoryData {
  name: string;
  part_number: string;
  manufacturer: string;
  price: number;
  cost: number;
  description: string;
  picture: string;
  added_on: string;
  part_id: number;
  delete_status: number;
  quantity: number;
  low_stock_value: number;
  is_low_stock: number;
  matrix_price: number;
  matrix_status: number;
}
