import { Component, OnInit, OnDestroy } from '@angular/core';
import { globalConstants } from "../../../../environments/globalConstants";
import { GlobalService } from "../../../../environments/global.service";
import { Router, ActivatedRoute, ParamMap, NavigationEnd, Params } from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-partlogdetail',
  templateUrl: './partlogdetail.component.html',
  styleUrls: ['./partlogdetail.component.scss']
})
export class PartlogdetailComponent implements OnInit, OnDestroy {
  public partLogId = 0;
  public currentUser;
  public logDetail: any;
  public logDetailOld: any = {};
  public logDetailNew: any = {};
  public subscriptionDataForAPI: Subscription = new Subscription();

  constructor(private activatedRoute: ActivatedRoute, public globalService: GlobalService) {
    this.currentUser = this.globalService.getCurrentUser();
    this.globalService.setMetaData("SHOPS","PART_LOG_DETAILS")
  }

  ngOnInit() {
    this.getParams();
    let data = 'user_id=' + this.currentUser.id + '&user_type=' + this.currentUser.user_type + '&part_log_id=' + this.partLogId;
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_PART_LOGS, data).subscribe((logs:any) => {
      if (logs.data != undefined) {
        this.logDetail = logs.data;
        if (logs.data.old_record_value) {
          this.logDetailOld = JSON.parse(logs.data.old_record_value);
        }
        if (logs.data.new_record_value) {
          this.logDetailNew = JSON.parse(logs.data.new_record_value);
        }
      }
    })
  }
  getParams() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.partLogId = params["id"];
    });
  }

  
  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
  }  

}
