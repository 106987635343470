import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog,MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
import { JobPasteConfirmationComponent } from './job-paste-confirmation/job-paste-confirmation.component';

@Component({
  selector: 'app-recommpaste',
  templateUrl: './recommpaste.component.html',
  styleUrls: ['./recommpaste.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RecommpasteComponent implements OnInit {

  public user: any = null;
  public jobs: any;
  public param: any = null;
  public currentCustomer;
  public panelOpenState = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RecommpasteComponent>,
    private gs: GlobalService,
    public dialog: MatDialog
    ) {
    this.param = data;
    this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
  }

  ngOnInit() {
    this.user = this.gs.getCurrentUser();
    if (!this.user) {
      this.dialogRef.close(2);
    }
    this.gs.diaLogObj(this.dialogRef);
    this.jobs = this.gs.copyJob('get');
    this.updateGenericJobPrice();
  }
  
  updateGenericJobPrice(){
    this.jobs.forEach(job => {
      job.recomm.forEach(recomm => {
        recomm.generic_jobs.forEach(e => {
          var shopContent = "user_id="+this.currentCustomer.id+"&user_type="+this.currentCustomer.user_type+"&job_id="+ e.generic_job_id;
          this.gs.callAPI(globalConstants.API_GET_GENERIC_JOBS_LIST_URL,shopContent).subscribe((data:any) => {
            e.price = data.data.price;
            e.cost = data.data.cost;
          });
        });
      });
    });
  }

  pasteData(jobs) {
    if (typeof (this.param.recom.children) !== 'undefined' && this.param.recom.children.length > 0) {
      //process level 3 job
      if (jobs.id == this.param.currentjobId) { 
        this.directJobPaste(jobs);
      } else {
        this.openPasteJobConfirmationPopUp(jobs);
      }
    } else {
      //process level 2 job
      if (jobs.id == this.param.currentjobId) {
        this.directJobPaste(jobs);
      } else {
        this.openPasteJobConfirmationPopUp(jobs);
      }
    }
  }
  openPasteJobConfirmationPopUp(jobs){
        let dialogRef = this.dialog.open(JobPasteConfirmationComponent, {
        panelClass: ['car-dialog-form'],
        width: '800px',
        data: { targetJob: this.param, pasteJob: jobs, jobName: this.param.jobNameObj }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == 1 && typeof dialogRef["selectedJobs"] != 'undefined') {
          let selectedPasteJob = dialogRef["selectedJobs"];
          if (selectedPasteJob.id == this.param.recom.id) {
            this.directJobPaste(jobs);
          } else {
            this.dialogRef["jobs"] = { job: jobs, isSameJobSelected: false };
            this.dialogRef.close(1);
          }
        }
      })
  }

  clearClipboard() {
    let msg="Are you sure, you want to clear clipboard?";
    this.gs.confirmDialog("yes",msg).subscribe((res)=>{
      let r:any=res;
      if(r=='yes'){
        this.gs.copyJob('reset');
        this.dialogRef.close(0);
      }
    });
  }

  removeData($e,index){
    $e.preventDefault();
    $e.stopPropagation();
    let msg="Are you sure?";
    this.gs.confirmDialog("yes",msg).subscribe((res)=>{
      let r:any=res;
      if(r=='yes'){
        this.gs.copyJob('resetjob',index);
        this.jobs.splice(index,1);
      }
    });
  }
  /** update part price cost */
  updateLatest(res,jobs){
    let partId=[]
    if(jobs.recomm && jobs.recomm.length){
      for(let i in jobs.recomm){
        if(jobs.recomm[i].parts && jobs.recomm[i].parts.length){
          for(let p in jobs.recomm[i].parts){
            this.updateItem(jobs.recomm[i].parts[p],res)
          }
        }
      }
    }
    this.dialogRef["jobs"]={job:jobs,isSameJobSelected:true};
    this.dialogRef.close(1);
  }

  updateItem(part,res){

    for(let el in res){
      if(res[el].id==part.part_id){
        part.cost=res[el].cost
        part.inventory_price=res[el].price
        part.low_stock_value=res[el].low_stock_value
        part.non_taxable=res[el].non_taxable
        part.part_cost=res[el].cost
        part.part_matrix_price=res[el].part_matrix_price
        part.part_price=res[el].price
        part.stock_quantity=res[el].stock_quantity,
        part.part_matrix_status=res[el].part_matrix_status
        break;
      }
    }
  }
  getVehicleName(vehicleInfo:any){
     return this.gs.getGlobalVehicleName(vehicleInfo);
  }

  convertNltoBr(text) {
    return this.gs.globalNltoBr(text);
  }

  directJobPaste(jobs){
    this.dialogRef["jobs"]=jobs;
    let partId=[]
    let shopId:string;
    if(jobs.shopId && jobs.shopId != ''){
      shopId = jobs.shopId;
    }
    if(jobs.recomm && jobs.recomm.length){
      for(let i in jobs.recomm){
        if(jobs.recomm[i].parts && jobs.recomm[i].parts.length){
          for(let p in jobs.recomm[i].parts){
            partId.push(jobs.recomm[i].parts[p].part_id);
          }
        }
      }  
    }
    if(partId.length){ 
      let data={user_id:this.user.id,shop_id:shopId,parts:JSON.stringify(partId)}
      this.gs.callAPI(globalConstants.API_GET_JOB_DATA,data).subscribe((r:any)=>{
        if(r && r.length){
          this.updateLatest(r,jobs);
        }
      },(error)=>{ })
    }else{ 
      this.dialogRef["jobs"]={job:jobs,isSameJobSelected:true};
      this.dialogRef.close(1);
    }
 
  }
}
