<div class="panel panel-primary" id="mainCompotents">
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">Edit SMS Template</h3>
        <button mat-mini-fab class="custom-btn-back" title="Back" (click)="globalService.goBack();">
            <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
    <div class="panel-body bgcolor-lightgrey padding-0" style="border:none;">
        <!-- Login page start -->
        <div class="bgcolor-lightgrey form-content edit-template">
            <div class="container-fluid">
                <form class="form-inline col-xs-12 padding-bottom-15" role="form" [formGroup]="shopEditTemplate"
                    novalidate (ngSubmit)="onSubmit()">
                    <div class=" col-xs-12 form-block">
                        <div class="row">
                            <div class="form-group col-sm-12 col-md-6">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill"
                                    hideRequiredMarker="false">
                                    <input matInput placeholder="Title" title="Title" formControlName="title"
                                        class="form-control input-lg custom-form-input">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-12 col-md-6 details-input">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill"
                                    hideRequiredMarker="false">
                                    <textarea formControlName="template" matInput placeholder="SMS Template"
                                        title="SMS Template" rows="4"></textarea>
                                    <mat-error *ngIf="shopEditTemplate.controls.template.hasError('required')">
                                        Template is<strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                                <p class="variable-names" *ngIf="variables.length"><b>Variable : </b>{{ variables.join(', ') }}</p>
                            </div>
                        </div>
                    </div>

                    <div class=" col-xs-12 form-block submit-block">
                        <div class="row">
                            <div class="col-sm-6 col-lg-4">
                                <button type="submit" mat-raised-button
                                    class="car-btn btn-block text-uppercase  btn-lg font-light fontcolor-white"
                                    color="accent" title="Submit">SUBMIT</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>