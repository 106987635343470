// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// local values

export const environment = {
    production: false,//false - show icons in Dev
    BASE_URL: 'https://devapi.completeautoreports.com/',
    S3_BUCKET_URL: 'https://s3.us-east-1.amazonaws.com/car-stage/', // dev s3 S3_BUCKET_URL
    DOMAIN_URL: 'https://devshop.completeautoreports.com',
    BUILD_VERSION: '24.8',
    NEXPART_OFFICIAL_LOGO: 'https://www.nexpartuat.com/images2/login/nexpart_logo_outline.svg',
    NODE_CHAT_SERVER_URL:"https://devchat.completeautoreports.com",
    SOCKET_SERVER_URL:"wss://devchat.completeautoreports.com",
    firebaseConfigInfo: {
        apiKey: 'AIzaSyBYbBL8JNOHryLfac4mBj-yhTHalE86jFU',
        authDomain: 'car-v2.firebaseapp.com',
        databaseURL: 'https://car-v2.firebaseio.com',
        projectId: 'car-v2',
        storageBucket: 'car-v2.appspot.com',
        messagingSenderId: '762114350792',
        appId: '1:762114350792:web:3659375f72676a95',
        vapidKey: 'BDNuUiw1yqD_6AKPoc753GekuKx0tuuGvEFLKMtNJHu1jlZ_k63rthmzj3Fat0ZVj-_Bs5N01rlPG-LufBe_ZzQ',
    },
};