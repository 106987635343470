import { Component, Inject, ElementRef, OnInit,  AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { globalConstants } from '../../environments/globalConstants';
import { GlobalService } from '../../environments/global.service';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { TextMaskModule } from 'angular2-text-mask';
const EMAIL_REGEX = globalConstants.EMAIL_REGEX;

@Component({
  selector: 'app-usercontacts',
  templateUrl: './usercontacts.component.html',
  styleUrls: ['./usercontacts.component.scss']
})
export class UsercontactsComponent implements OnInit {
  public userType = '';
  public user_id = '';
  public dataSources: any = [];
  public contacts: FormGroup;
  public editMode = false;
  public phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public emailMask = [EMAIL_REGEX];
  public formSubmit = true;
  public sendCustomerCheck:boolean=false;
  public callApi=true;
  public contactId=0;
  public URL='';
  constructor( @Inject(MAT_DIALOG_DATA) public data: any, private http: Http, public dialogRef: MatDialogRef<UsercontactsComponent>, private formBuilder: FormBuilder, private globalService: GlobalService) {
    // console.log(data); 
    this.contacts = this.formBuilder.group({
      contact_id: [''],
      name: ['', [Validators.required,Validators.pattern("^[0-9,a-z,A-Z,.,_,\-,',`, ]+$")]],
      number: ['', Validators.required],
      email: ['', [Validators.pattern(EMAIL_REGEX)]],
      send_to_contact: [''],
      user_type: [data.user_type],
      user_id: [data.user_id],
      customer_id: [data.customer_id],
      emp_id: [data.emp_id]
    });
    this.callApi=(typeof data.callAPI!='undefined'?data.callAPI:true);
    this.dataSources=(typeof data.contacts!='undefined'?data.contacts:[]);
    this.URL=(typeof data.url!='undefined'?data.url:globalConstants.API_CONTACTS_ADD_LIST);
    this.globalService.diaLogObj(this.dialogRef);
    if(data.reSetList){
        this.dataSources = [];
    }
  }

  ngOnInit() {
    let content = this.contacts.value;
    this.dialogRef['data']=this.dataSources;
    this.callApi && this.globalService.callAPI(this.URL, content).subscribe((response:any) => {
      if (response.result == '1') {
        this.dataSources = response.data;
      }
    });
  }

  addContacts() {
    let fcData = this.contacts;
    for (let inner in this.contacts.controls) {
        this.contacts.get(inner).markAsTouched();
        this.contacts.get(inner).updateValueAndValidity();
    }
    let th = this;
    if (fcData.valid && this.formSubmit) {
      let data = fcData.value;
      if (this.editMode) {
        data.dataType = 'editContact';
      } else {
        data.dataType = 'AddContact';
      }
      if(this.callApi ===false){
         this.localContact(data);
         return false;
      }
      this.globalService.callAPI(this.URL, data).subscribe((response:any) => {
        if (response.result == '1') {
          this.dataSources = response.data;
          this.resetForm();
          this.globalService.snackbar("success", response.message);
        } else {
          this.globalService.snackbar("error", response.message);
        }
      });
    } else {
      if(this.formSubmit==false){
        this.globalService.snackbar("error", 'Enter contact number');
      }else{
        this.globalService.snackbar("error", 'Enter contact detail');
      }
    }
  }
  deleteContact(id) {
    let fcData = this.contacts;
    let data = fcData.value;
    data.dataType = 'deleteContact';
    data.contact_id = id;
    let conf = 'Are you sure you want to delete the contact?';
    this.globalService.confirmDialog('markAsPaid', conf).subscribe((r) => {
      let res: any = r;
      if (res == 'markAsPaid') {
        if(this.callApi ===false){
          this.localContact(data,id);
          return false;
        }
        this.globalService.callAPI(this.URL, data).subscribe((response:any) => {
          if (response.result == '1') {
            this.dataSources = response.data;
            this.resetForm();
            this.globalService.snackbar("success", response.message);
          } else {
            this.globalService.snackbar("error", response.message);
          }
        });
      }
    });
  }
  resetForm() {
    this.contacts.controls.contact_id.setValue('');
    this.contacts.controls.name.setValue('');
    this.contacts.controls.number.setValue('');
    this.contacts.controls.email.setValue('');
    this.contacts.controls.send_to_contact.setValue(0);
    this.sendCustomerCheck = false;
    this.contacts.markAsUntouched();
    this.editMode = false;
    this.contacts.controls.name.setErrors(null);
    this.contacts.controls.number.setErrors(null);
  }
  updateContact(data) {
    let x:any= document.getElementById('customer_name');
    x.focus();

    this.editMode = true;
    this.contacts.controls.contact_id.setValue(data.id);
    this.contacts.controls.name.setValue(data.contact_name);
    this.contacts.controls.number.setValue(data.contact_number);
    this.contacts.controls.email.setValue(data.contact_email);
    this.contacts.controls.send_to_contact.setValue(data.send_to_contact);

    if (data.is_notification == 1) {
      this.sendCustomerCheck = true;
      this.contacts.controls.send_to_contact.setValue(1);
    } else {
      this.sendCustomerCheck = false;
      this.contacts.controls.send_to_contact.setValue(0);
    }

    this.checkPhoneValidity();
  }
  checkPhoneValidity() {
    Window["errorObj"] = this.contacts
    let home_phone = this.contacts.value.number;
    if (home_phone != "") {
      let phoneResult = this.contacts.value.number.replace(/\D+/g, "").length >= 10 ? false : { minLength: true };
      if (phoneResult == false) {
        this.contacts.controls.number.setErrors(null);
        this.formSubmit=true;
      } else {
        this.contacts.controls.number.setErrors({ "phoneError": true });
        this.formSubmit = false;
      }
    } else {
      // If the phone number is empty, set the required error
       this.contacts.controls.number.setErrors({ required: true });
       this.contacts.controls.number.markAsTouched();
       this.formSubmit = false;
      
    }
  }
  localContact(data,id?:any){
    if(data.dataType=='deleteContact'){
      for(var i in this.dataSources){
        if(this.dataSources[i].id==id){
          this.dataSources.splice(i,1);
        }
      }
    }else if(data.dataType=='AddContact'){
      this.contactId+=1;
      let tempData ;
      if(this.contacts.value.send_to_contact == 1){
         tempData={id:this.contactId,contact_number:data.number,contact_email:data.email,contact_name:data.name,is_notification:'1'};
      }else{
        tempData={id:this.contactId,contact_number:data.number,contact_email:data.email,contact_name:data.name,is_notification:'0'};
      }
      let result = this.dataSources.filter((info)=> info.contact_number == data.number);
      if(result.length == 0){
        this.dataSources.push(tempData);
        this.resetForm();
      }else{
         this.globalService.snackbar('error',"Contact number already exists.")
      }
    }else if(data.dataType=='editContact'){
      for(var i in this.dataSources){
        if(data.contact_id==this.dataSources[i].id){
          this.dataSources[i].contact_number=data.number;
          this.dataSources[i].contact_name=data.name;
          this.dataSources[i].contact_email=data.email;
          this.dataSources[i].send_to_contact=data.is_notification;
        }
      }
      this.resetForm();
    }
    this.dialogRef['data']=this.dataSources;
    return false;
  }

  showSendToContact(event) {
    if (event.checked) {
        if(this.contacts.controls.email.value == ""){
          let x:any= document.getElementById('customer_email');
          x.focus();
          this.contacts.controls.send_to_contact.setValue(0);
        }
        this.contacts.controls.send_to_contact.setValue(1);
    } else {
        this.contacts.controls.send_to_contact.setValue(0);
    }
  }

}
