import { Component, OnInit, OnDestroy, ViewEncapsulation} from '@angular/core';
import {FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router} from '@angular/router';
import { globalConstants } from "../../../../environments/globalConstants";
import {GlobalService} from "../../../../environments/global.service";
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-addeditpartstech',
  templateUrl: './addeditpartstech.component.html',
  styleUrls: ['./addeditpartstech.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AddeditpartstechComponent implements OnInit,OnDestroy  {
    public isCollapsed:boolean = true;
    public showSubscriptionMessage = false;
    public partstechform:FormGroup;
    public currentCustomer;
    public currentCustomerName;
    public isSubscribed=true;
    public subscriptionDataForAPI: Subscription = new Subscription();
    public chkDisplayLaborTextInDescription :any = false; 
    public chkLaborTitleInDescription :any = false;  
    public chkLaborNotesInDescription :any = false; 
    public chkLaborNotesPrefixInDescription : any = false;
    public chkDisplayLaborTextInTooltip :any = false;      
    public chkLaborTitleInTooltip :any = false;     
    public chkLaborNotesInTooltip :any = false;  
    public showErrMsgForChkDisplayLaborText :any = false;   
    public showErrMsgForChkDisplayLaborTooltip :any = false; 
    public isPTLaborSettingsValidated :any = true;  
    public partstech: boolean = false;
    public partstechLabor: boolean = false;
    public partstechLaborSuccess: boolean =false;
    constructor(
        private formBuilder: FormBuilder,
        public titleService:Title,
        private router: Router,
        private globalService: GlobalService) {
        if(localStorage.getItem("loggedInUser")) {
            this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
            this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;

            this.partstechform = this.formBuilder.group({
                partner_id: ['', Validators.required],
                partner_key: ['', Validators.required],
                user_id: this.currentCustomer.id,
                user_type: this.currentCustomer.user_type

            });
        } else {
            this.router.navigate(["/shops"]);
        }
        this.isSubscribed=this.globalService.isPartstechAllowed();
        setTimeout(()=>{
        let isEnable=this.globalService.getAcl('partstech_credentials','addNew');
        if(!(isEnable)){
            this.router.navigate(['shops/action/forbidden']);
        }
    },globalConstants.RELOAD_WAIT);
    this.globalService.setMetaData("SHOPS","MANAGE_CONN_PARTSTECH")
    }

    ngOnInit() {
        if(!this.isSubscribed){
            return false;
        }

        var shopContent = "user_id="+this.currentCustomer.id+"&user_type="+this.currentCustomer.user_type;
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_PARTS_TECHKEY_CONNECTION_URL,shopContent).subscribe((data:any) => {
            let res=data;
            if (res.result == "1") { 
                this.partstechform.controls.partner_id.setValue(res.data.partner_id);
                this.partstechform.controls.partner_key.setValue(res.data.partner_key);
                this.chkDisplayLaborTextInDescription = res.data.chk_pt_labor_job_desc=="1"?true:false; 
                this.chkLaborTitleInDescription = res.data.chk_pt_labor_job_desc_title=="1"?true:false;  
                this.chkLaborNotesInDescription = res.data.chk_pt_labor_job_desc_notes=="1"?true:false; 
                this.chkLaborNotesPrefixInDescription = res.data.chk_pt_labor_job_desc_notes_prefix == "1" ? true : false; 
                this.chkDisplayLaborTextInTooltip  = res.data.chk_pt_labor_job_tooltip=="1"?true:false;      
                this.chkLaborTitleInTooltip  = res.data.chk_pt_labor_job_tooltip_title=="1"?true:false;     
                this.chkLaborNotesInTooltip = res.data.chk_pt_labor_job_tooltip_notes=="1"?true:false;
                if(res.data.partstech_labor == "1"){
                      this.partstechLabor = false;
                }else{
                    let ptLaborReq = (typeof(sessionStorage.getItem('pt_labor_req')) == 'string') ? false : true;
                    this.partstechLabor = ptLaborReq;
                }
               
            } else { 
                this.globalService.snackbar('error',res.message);
                if(typeof(res.data) !== 'undefined'){
                   let isPartechkey= Object.keys(res.data).includes('partner_key');
                   if(!isPartechkey){
                        this.partstech = true;
                   }
                    if (res.data.partstech_labor == "1") {
                        this.partstechLabor = false;
                    } else {
                        let ptLaborReq = (typeof (sessionStorage.getItem('pt_labor_req')) == 'string') ? false : true;
                        this.partstechLabor = ptLaborReq;
                    }
                }  
            }
        });
        
       

    }
    addPartstechCredentials() {
        this.isPTLaborSettingsValidated = true;
        if(this.chkDisplayLaborTextInDescription){
            if( !this.chkLaborTitleInDescription  && !this.chkLaborNotesInDescription){
                 this.showErrMsgForChkDisplayLaborText = true;
                 this.isPTLaborSettingsValidated = false;
            }
        }

        if (this.chkDisplayLaborTextInTooltip) {
            if (!this.chkLaborTitleInTooltip && !this.chkLaborNotesInTooltip) {
                this.showErrMsgForChkDisplayLaborTooltip = true;
                this.isPTLaborSettingsValidated = false;
            } 
        }

        this.submitPartsTechForm();
    }

    submitPartsTechForm(){
                if(this.isPTLaborSettingsValidated && this.partstechform.valid){
                    let partsTechObj = this.partstechform.value;
                    partsTechObj.chk_pt_labor_job_desc = this.chkDisplayLaborTextInDescription ? 1 : 0;
                    partsTechObj.chk_pt_labor_job_desc_title = this.chkLaborTitleInDescription ? 1 : 0;
                    partsTechObj.chk_pt_labor_job_desc_notes = this.chkLaborNotesInDescription ? 1 : 0;
                    partsTechObj.chk_pt_labor_job_desc_notes_prefix = this.chkLaborNotesPrefixInDescription ? 1 : 0;
                    partsTechObj.chk_pt_labor_job_tooltip = this.chkDisplayLaborTextInTooltip ? 1 : 0;
                    partsTechObj.chk_pt_labor_job_tooltip_title = this.chkLaborTitleInTooltip ? 1 : 0;
                    partsTechObj.chk_pt_labor_job_tooltip_notes = this.chkLaborNotesInTooltip ? 1 : 0;
                    var shopContent = this.globalService.formsData(partsTechObj);
                    this.globalService.formData(globalConstants.API_PARTS_TECHKEY_CONNECTION_URL,shopContent).subscribe((data:any) => {
                        let res=data;
                        if (res.result == "1") { 
                            this.partstechform.controls.partner_id.setValue(res.data.partner_id);
                            this.partstechform.controls.partner_key.setValue(res.data.partner_key);
                            this.globalService.snackbar('success', res.message);
                            this.showErrMsgForChkDisplayLaborText  = false;   
                            this.showErrMsgForChkDisplayLaborTooltip = false; 
                               let isPartechkey = Object.keys(res.data).includes('partner_key');
                               if (!isPartechkey) {
                                 this.partstech = true;
                               }else{
                                 this.partstech = false;
                               }
                               this.partstechLabor =
                                 res.data.partstech_labor == '0' ? true : false;

                        } else { 
                            this.globalService.snackbar('error', res.message);
                        }
                    });
        }
    }
    
   
    mandatoryMsg() {
        this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
    gotoShopSubscription() {
        this.router.navigate(['/shops/subscription'])
    }

    handleLaborDescAndTooltip(chkboxType: any) {
        if (chkboxType == 'laborTextDesc') {
            if (!this.chkDisplayLaborTextInDescription) {
                this.chkLaborNotesInDescription = false;
                this.chkLaborTitleInDescription = false;
                this.chkLaborNotesPrefixInDescription = false;
            }
        } else if (chkboxType == 'laborTextTooltip') {
            if (!this.chkDisplayLaborTextInTooltip) {
                this.chkLaborTitleInTooltip = false;
                this.chkLaborNotesInTooltip = false;
            }
        }
    }

    handleLaborTitleAndNotes(chkBoxType:any){
          if(chkBoxType == 'chkNoteAndTitleInDesc'){
            this.showErrMsgForChkDisplayLaborText = false;
            if(!this.chkLaborNotesInDescription && !this.chkLaborTitleInDescription && !this.chkLaborNotesPrefixInDescription){
                 this.chkDisplayLaborTextInDescription = false;
            }else{
              this.chkDisplayLaborTextInDescription = true;
            }
          }else if(chkBoxType == 'chkNoteAndTitleInTooltip'){
            this.showErrMsgForChkDisplayLaborTooltip = false;
            if(!this.chkLaborTitleInTooltip && !this.chkLaborNotesInTooltip){
                 this.chkDisplayLaborTextInTooltip = false;
            }else{
                this.chkDisplayLaborTextInTooltip = true;
            }
          }
    }
    sendPartsTechLaborRequest(){
        let payload = {
            "user_id":this.currentCustomer.id,
            "user_type":this.currentCustomer.user_type,
            "comment":'',
            "add_on":'2'
        }
        this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_ADD_ON_REQUEST_MAIL,payload).subscribe((res:any) => {
              if(res.result == 1){
                    this.partstechLabor = false;
                    sessionStorage.setItem('pt_labor_req', '1');
                    this.globalService.snackbar('success', res.message);

              }else{
                this.globalService.snackbar('error', res.message);
              }
        })
    }
 
    ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
      } 
}

