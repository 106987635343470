import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/environments/global.service';
import { globalConstants } from 'src/environments/globalConstants';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-full-profile-access',
  templateUrl: './full-profile-access.component.html',
  styleUrls: ['./full-profile-access.component.scss']
})
export class FullProfileAccessComponent implements OnInit {
  public customerData: any;
  public showVerificationOptions: any = 0;
  public phoneMask = globalConstants.phoneMask;
  public currentUserInfo: any;
  public mobileNumberForm: FormGroup;
  public verifiedStatus: any = 0;
  public verifiedMobileNo: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FullProfileAccessComponent>,
    private globalService: GlobalService,
    private formBuilder: FormBuilder
  ) {
    this.customerData = data.customerInfo;
  }

  ngOnInit(): void {
    this.currentUserInfo = JSON.parse(localStorage.getItem("loggedInUser"));
    this.mobileNumberForm = this.formBuilder.group({
      mobile_phone: ['', [Validators.required, Validators.minLength(10), this.globalService.mobileNumberValidation]],
    })
  }

  showVerificationOption(selectedOption: any) {
    if (selectedOption == 'phoneNumber') {
      this.showVerificationOptions = 1;
      this.mobileNumberForm.controls.mobile_phone.setValue("");
      this.verifiedStatus = 0;
    } else {
      this.showVerificationOptions = 2;
    }
  }



  validateUser(verificationType: any) {
    let payLoadObj: any = {
      user_id: this.currentUserInfo.id,
      user_type: this.currentUserInfo.user_type,
      customer_id: this.customerData.customer_id
    };
    if (verificationType == 'mobileNo') {
      if (this.mobileNumberForm.valid) {
        payLoadObj.mobile_number = this.mobileNumberForm.controls.mobile_phone.value;
        this.globalService.callAPI(globalConstants.API_UPDATE_ALTERNATE_NUMBER, payLoadObj).subscribe((res: any) => {
          if (res.result == 1) {
            if (res.data.verify_status.toUpperCase() == 'VERIFIED') {
              this.verifiedMobileNo = this.mobileNumberForm.controls.mobile_phone.value;
              this.verifiedStatus = 1;
            } else {
              this.verifiedStatus = 3;
            }
          }
        })
      }
    } else {
      this.globalService.callAPI(globalConstants.API_RESEND_PROFILE_ACCESS_EMAIL, payLoadObj).subscribe((res: any) => {
        if (res.result == 1) {
          this.verifiedStatus = 2;
        } else {
          this.globalService.snackbar("error", res.message);
        }
      })
    }
  }

  closeConfirmationPopUp() {
    if (this.verifiedStatus == 2 || this.verifiedStatus == 1) {
      this.dialogRef.close(true);
    } else {
      this.dialogRef.close(false);
    }
  }
}
