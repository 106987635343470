import { Component, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { GlobalService } from 'src/environments/global.service';
import { Subscription } from 'rxjs';
import { globalConstants } from 'src/environments/globalConstants';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonSidepanelDrawerComponent } from '../common-sidepanel-drawer/common-sidepanel-drawer.component';
import { MatDrawer } from '@angular/material/sidenav';
import { ViewnoteComponent } from '../internalnote/viewnote/viewnote.component';
import { MessagingService } from 'src/app/messaging.service';
import { globalFunctions } from 'src/environments/globalFunctions';
import { AddinternalnoteComponent } from '../internalnote/addinternalnote/addinternalnote.component';
import { QuickapptComponent } from '../quickappt/quickappt.component';

@Component({
  selector: 'app-shop-dashboard-two',
  templateUrl: './shop-dashboard-two.component.html',
  styleUrls: ['./shop-dashboard-two.component.scss']
})
export class ShopDashboardTwoComponent implements OnInit {
  @ViewChild(CommonSidepanelDrawerComponent) accordionDrawer: CommonSidepanelDrawerComponent;
  @ViewChild('drawer', { static: false }) drawer: MatDrawer;
  public currentUser: any;
  public subscriptionDataForAPI: Subscription = new Subscription();
  public easyCheckInNoCustomerViewList=[
    {id: 2,name: "License Plate"},
    {id:7,name: "Vin"}
  ];
  public profileType :any ;
  public easyCheckInForm :any = FormGroup ;
  public easyCheckInShopList: any = [
    {
      id: 1,
      name: "Last Name",
    },
    {
      id: 2,
      name: "License Plate",
    },
    {
      id: 3,
      name: "Phone Number(Last 4 digits)",
    },
    {
      id: 4,
      name: "Business Name",
    },
    {
      id: 6,
      name: "Unit Number",
    },
    {
      id: 7,
      name: "Vin",
    }
  ]
  public easyCheckInFleetList: any = [
    {
      id: 2,
      name: "License Plate",
    },
    {
      id: 4,
      name: "Department Name",
    },
    {
      id: 6,
      name: "Unit Number",
    },
    {
      id: 7,
      name: "Vin",
    }
  ]
  public guideType: string;
  public internalNotesData : any;
  public showPermissionBanner : boolean = false;
  public shopStatistics :any;
  public reportData: any;
  public salesRevenue: any;
  public gc = globalConstants
  constructor(
    public app: AppComponent,
    private router: Router, 
    public globalService: GlobalService, 
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private msgService: MessagingService,
  ) { 
    this.globalService.setMetaData("SHOPS","NEWDASHBOARD");
  }

  ngOnInit(): void {
    this.currentUser = this.globalService.getCurrentUser();
    this.getDashboardStatistics();
    this.getBusinessMetricsReport();
    this.getTotalRevenue();
    this.easyCheckInForm = this.formBuilder.group({
      user_type: "User",
      user_id: this.currentUser.id,
      start: 0,
      limit: 10,
      search_keyword: ['', Validators.required],
      search_by: [''],
      search_keyword2: ['']
    });
    if (this.currentUser && typeof this.currentUser.profile_type != 'undefined') {
      this.profileType = this.currentUser.profile_type;
    }
    if (this.profileType == 2) {
      this.easyCheckInShopList = this.easyCheckInFleetList;
    }
    

    let internalNotesPayload:any = {
      user_type: "User",
      user_id: this.currentUser.id,
      search_by: "",
      keyword: "",
      datef:'',
      datet:'',
      from:'',
      to:'',
      status:'',
      start: 0,
      limit: 5
    }
    // API_get_internal_notes.php
    this.globalService.callAPI(globalConstants.API_GET_INTERNAL_NOTE, internalNotesPayload).subscribe((response:any) => {
      if(response.result == 1){
        this.internalNotesData = response.data;
      }else{
        this.internalNotesData = [];
      }
    })
    this.checkDeviceAndPermission();
  }

  //check the view permission of particular section or tile 
  isAllowed(action,module?:any){
    if(typeof module!='undefined'){
        return this.globalService.isAllowed(this.app,module,action);
    }
    return false;
  }

  //open the SR list page without using any filter
 navigateToSRListWithoutFilter(){
    localStorage.setItem("shop-servicestatus", "");
    localStorage.setItem("openFilter", "0");
    this.router.navigate(['shops/list-service-requests']);
 }

 // open the SR list page with some filter
 navigateToSRListWithFilter(filterPrams){
  localStorage.setItem("shop-servicestatus", filterPrams);
  localStorage.setItem("openFilter", "1");
  this.router.navigate(['shops/list-service-requests']);
 }

 //navigate to anther page
 navigateToPage(navigateUrl){
  this.router.navigate([navigateUrl]);
 }
 //open book Appointment pop-up 
 handleBookAppointment(){  
    if(this.globalService.getAcl('service_request','addNew')){
      let dialogRef = this.dialog.open(QuickapptComponent, {
        panelClass: ['quickqouotedialog'],
        width: "1200px",
        data: {
          startTime:new Date(),
          endTime:new Date(),
          eventType:1
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result !== undefined){
          this.refreshDashboard();
        }
      });
    }else{
      this.globalService.snackbar("error", 'You do not have access rights for this resource.');
    }
 }

 //making QSR 
 handleQuickSRCreation(){
    this.app.handleQuickSRCreation();
 }

 //open the job board
 navigateToJobBoard(empId:any = null){
   if(this.globalService.getAcl('service_request','views')){
     if(empId !== null){
      this.globalService.getSetItems('jobBoardFilterEmpId', empId);
     }
     this.router.navigateByUrl("shops/boards/job-board");
   }else{
    this.globalService.snackbar("error", 'You do not have access rights for this resource.');
   }
}

 handleAdvanceServiceHistory(){
     this.app.openServiceHistoryAdvanced();
  }

  openChat(){
      this.app.openChatArea();
  }

  //open the on the lot  in SR list 
  handleOnTheLotOption(){
    this.globalService.getSetItems('on-the-lot', '1');
    this.globalService.getSetItems('isCompletedIncluded','1');
    this.router.navigate(["shops/list-service-requests/on-the-lot"]);
  } 

 //easy check the user and vehicle info
  handleEasyCheckIn(){
    this.globalService.validateState(this.easyCheckInForm, ['search_by']);
    if (this.easyCheckInForm.valid) {
        localStorage.setItem("easyCheckinData", JSON.stringify(this.easyCheckInForm.value));
        localStorage.setItem("openFilter", "1");
        this.router.navigate(['shops/easy-check-in']);
    } else {
        this.globalService.snackbar('error',globalConstants.FORM_SUBMISSION_VALIDATION_WARNING)
    }
  }

  //open add new customer page 
  handleAddNewCustomer(){
      if(this.globalService.getAcl('customer','addNew')){
        this.router.navigateByUrl("shops/add-customer");
      }else{
        this.globalService.snackbar("error", 'You do not have access rights for this resource.');  
      }
  }

  //open DFS list with week and month filter
  navigateToDFSList(days){
    const currentDate = new Date();
    const nextSevenDate = new Date();
    nextSevenDate.setDate(currentDate.getDate() + days);
    let options = {
      days_till_next_service: "5",
      vehiclesDateRange: [currentDate.toISOString(),nextSevenDate.toISOString()]
    };
    this.globalService.localGetterSetter(options,null,'dfs-list-');
    this.router.navigate(["shops/due-for-service"]);
  }

  //open inspection list with 30 days and 60 day 
  navigateToInspectionExpList(searchType){
    let options = {
      search_by: searchType,
    };
    this.globalService.localGetterSetter(options,null,'shop-inspectlist-');
    this.router.navigate(["shops/inspection-list"]);
  }

  // Method to open the drawer when "Video Guide" is clicked
  handleGuideDrawer(type: string): void {
    if (this.accordionDrawer) {
      this.guideType = type;
      this.accordionDrawer.openDrawer();
    }
  }

  handleViewInternalNotes(row){
    if(row.read_status==1){
      this.openInternalNotesDetails(row)
    }else{
      let data = { user_id: this.currentUser.id,user_type: this.currentUser.user_type,note_id:row.id,status:1};
      let url=globalConstants.API_ADD_INTERNAL_NOTE_COMMENT;
      this.subscriptionDataForAPI = this.globalService.callAPI(url, data).subscribe((r:any) => {
        if (r.result == 1) {
          this.openInternalNotesDetails(row)
        }else if(r.code=499){
          this.openInternalNotesDetails(row)
        }
      }, (error) => { })
    }
  }
  /** redirect to view internal note detail */
  openInternalNotesDetails(row){
    this.globalService.localGetterSetter('internal-note', row.id,'');
    let dialogRef = this.dialog.open(ViewnoteComponent, {
        panelClass: 'dialog-view-note'
    });
  }

  checkDeviceAndPermission(){
    let isIPhoneDevice = this.msgService.checkDevice();
     if(isIPhoneDevice){
     }else{
       let permissionStatus = this.msgService.checkNotificationPermission();
       if(permissionStatus == 'granted' ){
           this.msgService.getFCMToken();
       }else if(permissionStatus == 'default'){
           this.showPermissionBanner = true;
       } else if(permissionStatus == 'denied'){
            this.showPermissionBanner = false;
       }
     }
}

askPermissionForNotifications(){
      this.msgService.getPermission();
      this.showPermissionBanner = false;
}

  // handling dashboard reference when switching between dashboard
  handleDashboardSwitch(){
      let payload ={
        dashboard_preference : 0,
        user_type:this.currentUser.user_type,
        user_id:this.currentUser.id
      }
      this.globalService.callAPI(globalConstants.API_SET_DASHBOARD_PREFERENCE, payload).subscribe((response:any) => {
        if(response.result == 1){
          let user=JSON.parse(localStorage.getItem('loggedInUser'));
          user.dashboard_preference = response.data.dashboard_preference;
          localStorage.setItem('loggedInUser',JSON.stringify(user));
          this.app.getMenuForShops();
          this.router.navigate(['shops/dashboard']);
        }
      })
 }

  /** get dashboard statistics */
  getDashboardStatistics() {
    const content = "user_id=" + this.currentUser.id + "&user_type=" + this.currentUser.user_type + "&shop_id=" + this.currentUser.shop_id;
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_DASHBOARD_STATISTICS_TWO, content).subscribe((res:any) => {
        if (res.result == 1) { 
            this.shopStatistics = res.data;
            this.globalService.verifyBuildVersion('shop', res.data.web_app_version_shops);
        }
    }, (error) => { });
  }

 openServiceRequestViewPage(SRID: any) {
  window.open('/shops/view-service-request/' + SRID, "_blank");
 }


  // get status of Internal Notes /
  getStatus(i){
    let status =['Created','Assigned','Resolved','Unread'];
    return (typeof status[i]!='undefined' ? status[i]:'-')
  }
  // Get the data for Advanced Report & Business Metrices
  getBusinessMetricsReport(){
    let  BusinessMetricsPayload = {
      user_id: this.currentUser.id,
      user_type: "User",
      start: 0,
      limit: 10,
      customer_id: '',
      employee_id: '',
      select_sales: '3',
      starting_date: '01/15/2019',
      ending_date: '01/15/2019'
    }
    var content = globalFunctions.stringifyFormData(BusinessMetricsPayload, null);
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUESTS_BUSINESS_METRICS_REPORT, content).subscribe((data:any) => {      
      this.reportData = data.data;
    });
   }

   getWorkInProgress() {
    localStorage.setItem("shop-servicejobStatus", "process");
    localStorage.setItem("openFilter", "1");
    this.router.navigate(['shops/list-service-requests']);
  }
  getTotalRevenue(){
    let revenuePayload ={
      user_id: this.currentUser.id,
      user_type: this.currentUser.user_type,
      start: 0,
      limit: 10,
      select_sales: 3,
      select_basis: 1,
      starting_date:"" ,
      ending_date:"" ,
    }
    var content = globalFunctions.stringifyFormData(revenuePayload, null);
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_REPORTS_AND_STATISTICS_URL, content).subscribe((response: any) => {
        this.salesRevenue = response.data;
    });
  }
  refreshDashboard(){
    this.ngOnInit();
  }

  navigationToSRListWithUnassignFilter(){
    this.globalService.getSetItems('unassigned_sr', '1');
    localStorage.setItem("openFilter", "1");
    this.router.navigate(['shops/list-service-requests']);
   }

   /** open Add Notes */
   openAddInternalNotes() {
    let dialogRef = this.dialog.open(AddinternalnoteComponent, {
      panelClass: 'car-dialog-form',
      width: "800px",
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.result==1){
        this.refreshDashboard()
      }
    });
  }

  navigateToSRList(empId) {
    localStorage.setItem("shop-serviceemployee_id", empId);
    localStorage.setItem("openFilter", "1");
    this.router.navigate(['shops/list-service-requests']);
  }

  navigateToPartList(){
      this.globalService.localGetterSetter("search_by","5",'shop-part-');
      this.router.navigate(['shops/list-parts']);
  }

  navigateToInventoryList(searchByType){
    let options = {
      search_by: searchByType,
    };
    this.globalService.localGetterSetter(options,null,'shop-inventoryStatsList-');
     this.router.navigate(['shops/inventory-stats']);
  }
}
