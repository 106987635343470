<div mat-dialog-title class="relative">
  Contacts
  <button mat-mini-fab mat-dialog-close class="close-modal notranslate mat-elevation-z0">
    <mat-icon class="close-icon-lg">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="contact-popup-content"> 
  <div class="center-block" style="max-width: 600px;">
    <form class="form-horizontal form-control-normal" autocomplete="off" [formGroup]="contacts">
      <div class="row display-full margin-bottom-5">
        <label class="col-sm-4 control-label"><strong>Contact Name<span class="asterisk">*</span></strong></label>
        <div class="col-sm-8">
          <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
            <input matInput type="text" id="customer_name" placeholder="Name" formControlName="name" class="form-control input-lg custom-form-input btn-block" maxlength="200" required/>
            <mat-error *ngIf="contacts.controls.name.hasError('required')">Name is <strong>required</strong>. </mat-error>
            <mat-error *ngIf="contacts.controls.name.hasError('pattern')">Must not contain any special characters.</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row display-full margin-bottom-5" >
        <label class="col-sm-4 control-label"><strong>Contact Number<span class="asterisk">*</span></strong></label>
        <div class="col-sm-8">
          <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
            <input matInput type="text" [textMask]="{mask: phoneMask}" (keyup)="checkPhoneValidity()" placeholder="Contact Number" formControlName="number" class="form-control input-lg custom-form-input btn-block" required />
            <mat-error *ngIf="contacts.controls.number.hasError('required')">Contact number is <strong>required</strong>. </mat-error>
            <mat-error *ngIf="contacts.controls.number.hasError('phoneError')">Invalid contact number. </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row display-full margin-bottom-5" >
        <label class="col-sm-4 control-label"><strong>Contact Email</strong></label>
        <div class="col-sm-8">
          <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
            <input matInput type="email" id="customer_email" placeholder="Contact Email" formControlName="email" class="form-control input-lg custom-form-input btn-block" maxlength="200" />
            <mat-error *ngIf="contacts.controls.email.hasError('pattern')">Invalid email address.</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row display-full margin-bottom-5">
      <label class="col-sm-4 control-label hidden-xs"><strong></strong></label>
        <div class='col-sm-8'>
            <mat-checkbox class="btn-block" labelPosition="after" (change)="showSendToContact($event)" formControlName="send_to_contact" [checked]="sendCustomerCheck">
              <span class="info-text">Send customer communication to this contact</span>
            </mat-checkbox>
        </div>
      </div>

      <div class="row display-full margin-bottom-5">
        <div class="col-sm-8 col-sm-offset-4">
          <button mat-raised-button (click)="addContacts()" class="font-bold add-contact-btn" color="accent"><span *ngIf="editMode==true">Update</span><span *ngIf="editMode==false">Add</span></button>
        </div>
      </div>
    </form>
  </div>
  <div class="display-full margin-bottom-30">
    <h4 class="margin-bottom-30">Contacts List:</h4>
    <div class="table-responsive relative">
      <table class="table table-striped table-hover table-condensed sortable" id="contactsList">
        <thead>
          <tr>
            <th width="50px" class="">#</th>
            <th class="">Contact Name</th>
            <th width="120px" >Contact No.</th>
            <th width="120px" >Email Address</th>
            <th width="120px" >Notification</th>
            <th width="70px" class="sorttable_nosort" >Actions</th>
          </tr>
        </thead>
        <tbody id="paymentsListView">
          <tr *ngFor="let contact of dataSources;let i=index" >
            <td width="50px" >{{(i+1)}}</td>
            <td >{{contact.contact_name}}</td>
            <td width="120px">
              <a class="text-black" href="tel:{{contact.contact_number}}" *ngIf="contact.contact_number !=''">{{contact.contact_number}}</a>
            </td>
            <td width="120px">
              <a class="text-black" href="mailto:{{contact.contact_email}}" >{{contact.contact_email || '-'}}</a>
            </td>
            <td width="120px">
              <span *ngIf="contact.contact_email !=''">
                <span *ngIf="contact.is_notification == '1'">Enabled</span>
                <span *ngIf="contact.is_notification == '0'">Disabled</span>
                <span *ngIf="contact.is_notification == ''">-</span>
              </span>
              <span *ngIf="contact.contact_email ==''">
                <span>-</span>
              </span>
            </td>
            <td width="70px"  >
              <button (click)="updateContact(contact)" type="button" title="Edit" class="btn btn-xs btn-link text-red "><i class="fa fa-pencil fa-lg"></i></button>
              <button (click)="deleteContact(contact.id)" type="button" title="Delete" class="btn btn-xs btn-link text-red"><i class="fa fa-trash fa-lg"></i></button>
            </td>
          </tr>
          <tr *ngIf="dataSources.length==0">
            <td colspan="4" class="norecords-row">No Records Found.</td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
  <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
</mat-dialog-actions>
