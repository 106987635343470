<ng-container>
    <div bsModal class="component-popup job-suggestion-container">
      <div class="modal-header">
        <div class="row header-row">
          <div class="col-sm-6 col-md-6 col-lg-6">
            <h4 class="modal-title pull-left margin-10 margin-left-15">Job Match Suggestion</h4>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6">
            <button type="button" class="close" aria-label="Close" (click)="toggleState(3)">
              <span aria-hidden="true">&times;</span>
            </button>
            <!-- <button type="button" class="close" (click)="toggleState(2)">
              <span aria-hidden="true">&minus;</span>
            </button> -->
          </div>
        </div>
      </div>
      <div class="modal-body">
        <ng-container *ngTemplateOutlet="jobMatchDetails"></ng-container>
      </div>
    </div>
  </ng-container>

  <ng-template #jobMatchDetails>
         <div class="row margin-0 info-section">
            <div class="col-sm-12">
              <div class="row margin-0">
                <div class="col-xs-3  col-sm-2 font-bold padding-left-0"><span>Vehicle</span></div>
                <div class="col-xs-9  col-sm-10 text-left"><span>{{SRData.vehicleName}}</span></div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row row margin-left-0 margin-right-0 margin-top-10">
                <div class="col-xs-3 col-sm-2 font-bold padding-left-0"><span>Job</span></div>
                <div class="col-xs-9 col-sm-10 text-left"><span>{{SRData.jobName.level1JobName}}</span><span>&nbsp;->&nbsp;</span><span>{{SRData.jobName.level2JobName}}</span><span *ngIf="SRData.jobName.level3JobName !== null">&nbsp;->&nbsp;</span><span>{{SRData.jobName.level3JobName}}</span></div>
              </div>
            </div>
         </div>
         <div class="row margin-0 button-section" *ngIf="allJobSuggestion && allJobSuggestion.length > 0">
          <div class="col-md-6 col-sm-6">
            <p class="text-left expand-collapse">
              <span class="expand margin-right-10" (click)="expandAll('suggestion')">
                <i class="fa fa-expand"></i> <span class="hidden-xs">&nbsp; Expand All</span>
              </span>
              <span class="collapse" (click)="collapseAll('suggestion')">
                <i class="fa fa-compress"></i> <span class="hidden-xs">&nbsp; Collapse All</span>
              </span>
            </p>
          </div>
         </div>
         <div class="row margin-0">
          <div class="col-sm-12 expansion-section" *ngIf="allJobSuggestion && allJobSuggestion.length > 0">
            <mat-accordion displayMode="flat" hideToggle="false" [multi]="true">
              <mat-expansion-panel [expanded]="areAllAccordionsOpened || (a==0 && isFirstAccordionOpened)" *ngFor="let jobSuggestion of allJobSuggestion;let a=index"  id="expansion-history-{{a+1}}" (click)="scrollToView(a+1)">
                <mat-expansion-panel-header id="expansionHead-history-{{a+1}}" [attr.data-state]="a==0">
                  <mat-panel-title>
                    <div class="row margin-0 jobDestails-section">
                      <div class="col-sm-1 padding-0 icon-item">
                        <img src="../../../../assets/img/dart-arrow-in-the-middle-white.svg" class="padding-right-5" *ngIf="jobSuggestion.year !== this.SRData.vehicleInfo.year || jobSuggestion.make !==this.SRData.vehicleInfo.make || jobSuggestion.model !== this.SRData.vehicleInfo.model">
                        <i class="fa fa-fade"><img src="../../../../assets/img/dart-arrow-in-the-middle-accent.svg" class="padding-right-5" *ngIf="jobSuggestion.year == this.SRData.vehicleInfo.year && jobSuggestion.make ==this.SRData.vehicleInfo.make && jobSuggestion.model == this.SRData.vehicleInfo.model"></i>
                      </div> 
                      <div class="col-sm-11 job-info">
                        <div class="row job-info-row">
                           <div class="col-sm-4 col-lg-2">
                            <a [routerLink]="['shops/view-service-request/'+jobSuggestion.id]" target="_blank"><span>{{jobSuggestion.request_id}}</span></a>
                           </div>
                           <div class="col-sm-5 col-lg-8">
                            <span *ngIf="jobSuggestion.year !== this.SRData.vehicleInfo.year || jobSuggestion.make !==this.SRData.vehicleInfo.make || jobSuggestion.model !== this.SRData.vehicleInfo.model">
                              <label>{{getVehicleName(jobSuggestion)}}</label>
                            </span>
                           </div>
                           <div class="col-sm-3 job-date-time"><span>{{getFormattedDate(jobSuggestion.requested_delivery_date)}}</span></div>
                        </div>
                      </div>
                    </div>
                  </mat-panel-title>
                  <!-- <mat-panel-description>
                    <div class="row margin-0">
                      <div class="col-sm-12 text-right"><span>{{getFormattedDate(jobSuggestion.schedule_service_date)}}</span></div>
                    </div>
                  </mat-panel-description> -->
                </mat-expansion-panel-header>
                <div class="row margin-0 padding-top-20">
                  <div class="col-sm-12 text-right copy-paste-section hidden-xs">
                    <button class="margin-right-10" title="Copy" (click)="copyAndPasteSuggestion($event,jobSuggestion,'copy')"><i class="fa fa-copy"></i></button>
                    <button title="Paste" (click)="copyAndPasteSuggestion($event,jobSuggestion,'paste')"><i class="fa fa-paste"></i></button>
                  </div>
                  <div class="row margin-0 ">
                    <div class="col-sm-12 padding-0 fs-13-mob">
                      <div class="row margin-0" *ngIf="jobSuggestion.recommendations[0].parts.length>0">
                        <label class="col-xs-3 col-sm-2 col-lg-2 text-right"><span>Parts:</span></label>
                        <div class="col-xs-9 col-sm-10 col-lg-10 partsJob">
                          <div class="job-wrap" *ngFor="let part of jobSuggestion.recommendations[0].parts">
                            <div class="row margin-0" [class.text-orange]="part.partstech_cart_item==1 || part.partstech_order_item==1">
                              <div class="col-xs-12 col-sm-8 pname break-all">
                                <span *ngIf="part.part_number!=''">{{part.part_number}} </span>
                                <span *ngIf="showCostPrice">($ {{part.cost}}) -</span>
                                {{part.name}}
                                <span *ngIf="part.description!=''"> - {{part.description}}</span>
                              </div>
                              <div class="col-xs-6 col-sm-2 pprice text-center"><span *ngIf="showCostPrice">$ {{ parseFloats(part.part_price)}}</span></div>
                                <div class="col-xs-6 col-sm-2 pquantity">
                                  <i class="fa fa-info-circle"></i> {{part.quantity}}
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row margin-0 padding-top-10" *ngIf="jobSuggestion.recommendations[0].generic_jobs.length>0">
                        <label class="col-xs-3 col-sm-2 col-lg-2 text-right"><span>Generic jobs:</span></label>
                        <div class="col-xs-9 col-sm-10 col-lg-10 genericJob">
                          <div class="job-wrap">
                          <div class="row margin-0"  *ngFor="let gjob of jobSuggestion.recommendations[0].generic_jobs;let l = index">
                            <div class="col-xs-12 col-sm-8 break-all">{{gjob.name}}</div>
                            <div class="col-xs-6 col-sm-2 text-center"><span *ngIf="showCostPrice"> $ {{ parseFloats(gjob.price)}}</span></div>
                            <div class="col-xs-6 col-sm-2 ">{{gjob.quantity}}</div>
                          </div>
                        </div>
                        </div>
                      </div>

                      <div class="row margin-0 padding-top-10" *ngIf="jobSuggestion.recommendations[0].computer_codes.length>0">
                        <label class="col-xs-3 col-sm-2 col-lg-2 text-right"><span>Computer Code:</span></label>
                        <div class="col-xs-9 col-sm-10 col-lg-10 genericJob">
                          <div class="job-wrap">
                          <div class="row margin-0"  *ngFor="let computerCode of jobSuggestion.recommendations[0].computer_codes;let l = index">
                            <div class="col-xs-12 col-sm-8 break-all">{{computerCode.trouble_code}} {{computerCode.fault_location}}</div>
                          </div>
                        </div>
                        </div>
                      </div>
                      <div class="row margin-0 padding-top-10">
                        <label class="col-xs-3 col-sm-2 col-lg-2 text-right"><span>NXT/PT Labor:</span></label>
                        <div class="col-xs-9 col-sm-10 col-lg-10">
                          <div class="job-wrap">
                            <div class="row margin-0">
                              <div class="col-sm-12"><span>{{parseFloats(jobSuggestion.recommendations[0].nexpart_labor_time)}} hrs</span></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row margin-0 padding-top-10">
                        <label class="col-xs-3 col-sm-2 col-lg-2 text-right"><span class="hidden-xs">Estimated Labor:</span><span class="visible-xs">Est. Labor:</span></label>
                        <div class="col-xs-9 col-sm-10 col-lg-10">
                          <div class="job-wrap">
                            <div class="row margin-0">
                              <div class="col-sm-12"><span>$ {{parseFloats(jobSuggestion.recommendations[0].estimated_labor)}} ({{parseFloats(jobSuggestion.recommendations[0].estimated_time)}} hour(s))</span></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row margin-0 padding-top-10" *ngIf="jobSuggestion.recommendations[0].recommendation !== ''">
                        <label class="col-sm-2 col-lg-2 text-right"><span>Description of Work:</span></label>
                        <div class="col-sm-10 col-lg-10">
                          <div class="job-wrap">
                            <div class="row margin-0">
                              <div class="col-sm-12">
                               <p class="margin-0" [innerHtml]="convertNltoBr(jobSuggestion.recommendations[0].recommendation)"></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row margin-0 padding-top-10" *ngIf="jobSuggestion.recommendations[0].notes !==''">
                        <label class="col-sm-2 col-lg-2 text-right"><span>Notes:</span></label>
                        <div class="col-sm-10 col-lg-10">
                          <div class="job-wrap">
                            <div class="row margin-0">
                              <div class="col-sm-12">
                                <p class="margin-0" [innerHtml]="convertNltoBr(jobSuggestion.recommendations[0].notes)"></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class=" row visible-xs copy-paste-mob">
                        <div class="col-xs-6 copy-btn" (click)="copyAndPasteSuggestion($event,jobSuggestion,'copy')">
                          <span class=""><i class="fa fa-copy"></i>&nbsp; Copy Job</span>
                        </div>
                        <div class="col-xs-6 paste-btn" (click)="copyAndPasteSuggestion($event,jobSuggestion,'paste')">
                          <span class=""><i class="fa fa-paste"></i>&nbsp; Paste Job</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            
          </div>
          <div class="display-full expansion-section" *ngIf="allJobSuggestion && allJobSuggestion.length == 0">
            <p class="text-center margin-top-10"> No Suggestion Available </p>
          </div>
         </div>
  </ng-template>