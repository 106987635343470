import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PartagsComponent } from '../../shopparts/partags/partags.component';
import { Observable } from 'rxjs';
import { Http } from '@angular/http';
import { globalFunctions } from 'src/environments/globalFunctions';
@Component({
  selector: 'app-editpart-popup',
  templateUrl: './editpart-popup.component.html',
  styleUrls: ['./editpart-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditpartPopupComponent implements OnInit {
  public currentUser;
  public editNewPart: FormGroup;
  public part_id = 0;
  public currentPartDetails:any={};
  public nonTaxable;
  public submitted = false;
  public currentPartPicture = '';
  public partPicture = '';
  public partsPic = globalConstants.S3_BUCKET_URL + globalConstants.PART_PICTURE + globalConstants.IMG_THUMB;
  public lowStockQty="Low Stock Quantity";
  public isInventory=false;
  public allTags=[];
  public preSelectTag=[];
  public request_id:any=''
  public quote_id:any=''
  public isEnable = false;
  constructor( @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditpartPopupComponent>, private formBuilder: FormBuilder, private globalService: GlobalService,public dialog: MatDialog, private http: Http) {
    this.part_id = data.part_id;
    this.request_id = data.request_id;
    this.quote_id = ( typeof data.quote_id!='undefined' ?  data.quote_id :'')
    this.isEnable = this.globalService.getAcl('part', 'edits');
    if(this.isEnable){
      this.globalService.diaLogObj(this.dialogRef);
    }
  }
  /**
   * @trackID <:CAR-1338> Edit part added in quote job
   * @updated 2020-01-29
   * @updatedby Vikas Kumar
   * @param
   * @return
   */
  ngOnInit() {

    this.isEnable = this.globalService.getAcl('part', 'edits');
    if(!this.isEnable){
      this.globalService.snackbar('error', "You don't have sufficient permission");
      this.dialogRef.close(0);
      return false;
    }

    this.currentUser = this.globalService.getCurrentUser();
    this.editNewPart = this.formBuilder.group({
      part_name: ['', Validators.required],
      part_number: ['', Validators.required],
      cost: ['', [Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
      price: ['', [Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/)]],
      vendor: [''],
      manufacturer: [''],
      oem_part_number: [''],
      description: [''],
      non_taxable: [''],
      approval_status: [''],
      part_pic: [''],
      part_id: [''],
      user_type: this.currentUser.user_type,
      user_id: this.currentUser.id,
      shop_id: this.currentUser.shop_id,
      part_picture: [''],
      part_qty:['',Validators.pattern(/^-?\d+(?:\.\d+)?$/)],
      low_stock_qty:['',Validators.pattern(/^-?\d+(?:\.\d+)?$/)],
      part_tags:[],
      request_id:this.request_id,
      part_matrix_price:[''],
      quote_id:this.quote_id
    });
    var shopContent = "user_id=" + this.currentUser.id + "&user_type=" + this.currentUser.user_type + "&part_id=" + this.part_id;
    this.globalService.callAPI(globalConstants.API_GET_PARTS_LIST_URL, shopContent).subscribe((data:any) => {
     if(data.result=="1"){
      this.currentPartDetails = data.data;
      // console.log('Part Details', this.currentPartDetails);
      this.editNewPart.controls.part_id.setValue(this.part_id);
      this.editNewPart.controls.approval_status.setValue(this.currentPartDetails.admin_approval_status);
      if (this.currentPartDetails.non_taxable == 1) {
        this.nonTaxable = true;
        this.editNewPart.controls.non_taxable.setValue('1');
      } else {
        this.nonTaxable = false;
        this.editNewPart.controls.non_taxable.setValue('0');
      }
      let tags=this.currentPartDetails.part_tags;
            if(tags.length>0){
                tags.forEach((r,v)=>{
                    this.preSelectTag.push(r.tag_id);
                })
            }
      this.partPicture = this.currentPartDetails.part_picture;
     }
    }, (error) => { });
    this.isInventory = this.globalService.isInventories();
    if (this.isInventory) {
      let content = { shop_id: this.editNewPart.value.shop_id };
      this.globalService.callAPI(globalConstants.API_GET_SHOP_DEFAULT_LOW_STOCK_VALUE, content).subscribe((data:any) => {
        if (data.result == "1") {
          this.lowStockQty = data.data.default_low_stock_value;
        }
      }, (error) => { });

      this.editNewPart.get('part_qty').setValidators([Validators.required,Validators.pattern(/^-?\d+(?:\.\d+)?$/)]);
      this.editNewPart.get('low_stock_qty').setValidators([Validators.required,Validators.pattern(/^-?\d+(?:\.\d+)?$/)]);
    }
    this.getAllTags();

  }
  editPart() {
    if (this.editNewPart.valid) {
      var data = new FormData();
      for (let i in this.editNewPart.value) {
        data.append(i, this.editNewPart.value[i]);
      }
      this.globalService.formData(globalConstants.API_EDIT_PART_URL, data).subscribe((data:any) => {
        var message = data.message;
        if (data.result == "1") {
          this.editNewPart.controls.part_matrix_price.setValue(data.data.part_matrix_price)
          this.globalService.snackbar('success', message);
          this.dialogRef.close(this.editNewPart.value);
        } else {
          this.globalService.snackbar('error', message);
        }
      }, (error) => { });
    } else {
      this.submitted = true;
      this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
  }
  checkIfValid() {
    if (this.editNewPart.valid) {
      this.submitted = false;
    }
  }
  getFile(evt, key) {
    var valid = this.globalService.validateFiles('image', evt.target.files);
    if (valid) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsDataURL(evt.target.files[0]);
    }
  }
  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.currentPartPicture = binaryString;
    this.editNewPart.controls.part_picture.setValue(binaryString);
  }
  deletePartPic() {
    this.currentPartPicture = '';
    this.editNewPart.controls.part_picture.setValue('');
    let ele = <HTMLInputElement>document.getElementById('partPic');
    ele.value = '';
  }
  deleteExistingPartPic(){
    this.globalService.confirmDialog(1, 'Are you sure you want to delete this picture?').subscribe((res) => {
        if (res != null) {
            let content='user_id='+this.currentUser.id+'&part_id='+this.part_id+'&user_type='+this.currentUser.user_type;
            this.globalService.callAPI(globalConstants.API_DELETE_PART_PICTURE_URL,content).subscribe((data:any) => {
                var message = data.message;
                if (data.result == "1") {
                    this.partPicture='';
                    this.globalService.snackbar('success', message);
                } else {
                    this.globalService.snackbar("error", message);
                }
            }, (error) => { })
        }
    });
}
onlyDecimalNumberKey(event) {
  let charCode = (event.which) ? event.which : event.keyCode;
  if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
  return true;
}

disableKey(event) {
  let charCode = String.fromCharCode(event.which).toLowerCase();
  if (event.ctrlKey==true && (event.which == '67' || event.which == '86' ||  String.fromCharCode(event.which).toLowerCase() == 's')) {
      console.log('thou. shalt. not. PASTE!');
      event.preventDefault();
  }
}

manageTag(){
  let dialogRef = this.dialog.open(PartagsComponent, {
      panelClass: 'partstech-order-dialog',
      width: '800px',
      data: {}
  });
  dialogRef.afterClosed().subscribe(result => {
      this.getAllTags();
  });
}
getAllTags(){
  let data={user_id: this.globalService.getCurrentUser()["id"]};
  this.globalService.callAPI(globalConstants.API_LIST_PART_TAGS,data).subscribe((res:any)=>{
      if (res.result == "1") {
          this.allTags=res.data;
      }
  },(error)=>{})
}

partNumber(opt) {
  if (typeof opt == "object") {
      this.editNewPart.controls.part_number.setValue(opt.part_number);
  }else if(typeof opt!='undefined'){
      this.editNewPart.controls.part_number.setValue(opt);
  }
  this.checkIfValid();
}

observableSourcePN = (keyword: any): Observable<any[]> => {
  let PartSearchKeyword = keyword.replace(/#/g, "|");
  let url: string = globalConstants.API_GET_PART_NUMBERS + "?user_id=" + this.globalService.getCurrentUser()['id']+ "&user_type=" + this.globalService.getCurrentUser()['user_type'] + "&query=" + PartSearchKeyword;
  if (keyword) {
      let f = [];
       return this.globalService.getAPI(url).map((res:any)=>{
              let json = res;
              this.globalService.authText(json);
              if (json != null) {
                  json.data.forEach(function (x) {
                      x.value = x.part_number;
                      f.push(x)
                  }
                  );
              }
              return f
          })
  } else {
      return Observable.of([]);
  }
}

setReturnFalse() {
  return false
}

myListFormatter(data: any): string {
  return data['part_number'];
}

convertIntoDecimal(event,frmControlName,beforeDecimalDigit){
  let fieldValue = this.editNewPart.get(frmControlName).value
  let result= this.globalService.processDecimalValue(fieldValue,beforeDecimalDigit);
  this.editNewPart.get(frmControlName).setValue(result);
  return globalFunctions.validateNumbersWithDecimal(event,3);
}
}
