<form class="form-horizontal form-control-normal partTag-form" autocomplete="off" [formGroup]="tagForm">
  <div mat-dialog-title class="relative">
    Tags
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
      <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
  </div>
  <mat-dialog-content class="customer-detail-popup">
    <div class="center-block" style="max-width: 600px;" *ngIf="isEdit || isAdd">
      <div class="display-full margin-bottom-5">
        <label class="col-sm-3 control-label">
          <strong>Tag Name</strong>
        </label>
        <div class="col-sm-9">
          <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
            <input matInput type="text" placeholder="Tag Name" maxlength="200" class="form-control input-lg custom-form-input btn-block" formControlName="tag_name"
              required/>
            <mat-error *ngIf="tagForm.controls.tag_name.hasError('required')">
              This field cannot be empty
            </mat-error>
          </mat-form-field>
        </div>
      </div>

    </div>
    <div class="col-sm-12" *ngIf="isEdit==false && isAdd==false">
      <fieldset *ngIf="allTags.length > 0">
        <div class="table-responsive relative ">
          <table class="table table-striped  margin-bottom-10 " >
            <thead class="table-heading">
              <tr>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let tag of allTags">
                <td>{{tag.tag_name}}</td>
                <td>
                  <a href="javascript:;" (click)="editTag(tag)">
                    <i class="fa fa-pencil"></i>
                  </a>
                </td>
              </tr>
           </tbody>
          </table>
        </div>
        </fieldset>
        <div class="table-responsive relative " *ngIf="allTags.length==0">
          <table class="table table-striped table-bordered  margin-bottom-0 " >
            <tbody>
              <tr>
                <td colspan="2" class="norecords-row">No record found</td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="inquiry-action relative">
    <div class="flexBtns">
      <button mat-raised-button color="accent" *ngIf="isAdd==false && isEdit==false" (click)="addTag(1)" class="font-bold">Add Tag</button>
      <button mat-raised-button color="accent" *ngIf="isAdd" (click)="addTag(2)" class="font-bold">Submit</button>
      <button mat-raised-button color="accent" *ngIf="isEdit" (click)="submitEdit()" class="font-bold">Update</button>

      <button mat-raised-button color="accent" *ngIf="isEdit || isAdd" (click)="cancelAction()" class="font-bold">Cancel</button>
      <button mat-raised-button mat-dialog-close *ngIf="isAdd==false && isEdit==false" class="font-bold">Close</button>
    </div>  
  </mat-dialog-actions>
</form>