import {Component, OnInit, ElementRef, ViewEncapsulation} from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd, Params} from '@angular/router';
import {globalConstants as GC} from '../../../environments/globalConstants';
import {GlobalService as GS} from '../../../environments/global.service';
import {globalFunctions} from '../../../environments/globalFunctions';

@Component({
  selector: 'app-customer-feedback',
  templateUrl: './customer-feedback.component.html',
  styleUrls: ['./customer-feedback.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomerFeedbackComponent implements OnInit {
    public customerFeedBack: FormGroup;
    public feedbackQuestion: any;
    public feedbackQuestionV: any;
    public requestId: string;
    public shopId: string;
    public feedbackCode: string;
    public questionInfo: any = {};
    public customerId: string;
    public feedbackStatus;
    public pageViewStatus;
    
    public shopParamID;
    public shopParamRequestID;
    public shopFeedbackCode;
    
    constructor(private gs: GS, private formBuilder: FormBuilder, private element: ElementRef, private activatedRoute: ActivatedRoute, public router:Router) {
        this.customerId     = this.gs.getCurrentCustomer()['id'];
        this.gs.setMetaData("CUSTOMER","FEEDBACK")
        this.getParams();
        if(Object.keys(this.activatedRoute.queryParams["_value"]).length > 0) { 
            this.requestId      = this.shopParamRequestID;
            this.shopId         = this.shopParamID;
            this.feedbackCode   = this.shopFeedbackCode;
            localStorage.setItem("getFeedbackRequestID", "");
            localStorage.setItem("getFeedbackShopID", "");
            localStorage.setItem("getFeedbackCode", "");
        } else { 
            this.requestId      = localStorage.getItem("getFeedbackRequestID");
            this.shopId         = localStorage.getItem("getFeedbackShopID");
            this.feedbackCode   = localStorage.getItem("getFeedbackCode");
        }
        this.initialializeAddFeedbackForm()
        let params: string = '';
        params = 'customer_id=' + this.customerId + '&request_id=' + this.requestId;
        this.gs.callAPI(GC.API_GET_FEEDBACK_QUESTIONS_URL, params).subscribe((data:any) => {
            if (data.result == 1) {
                if (data.feedback_status == 1) { 
                    this.pageViewStatus = true;
                    this.feedbackQuestion = data.data;
                    for (let i = 0; i < this.feedbackQuestion.length; i++) {
                        let obj: any = {};
                        this.questionInfo[this.feedbackQuestion[i].id] = {};
                        if (i === 0) {
                            this.questionInfo.feedback_comment = this.feedbackQuestion[i].comment;
                        }
                        obj.title = this.feedbackQuestion[i].rating;
                        this.questionInfo[this.feedbackQuestion[i].id] = obj;
                    }
                } else if(data.feedback_status == 0) {
                    this.pageViewStatus = false;
                    this.gs.callAPI(GC.API_GET_FEEDBACK_QUESTIONS_URL, 'customer_id=' + this.gs.getCurrentCustomer().id).subscribe((data:any)=>{
                        if(data.result == 1){
                            this.feedbackQuestion = data.data;
                            for (let i = 0; i < this.feedbackQuestion.length; i++) {
                                let obj: any = {};
                                this.questionInfo[this.feedbackQuestion[i].id] = {};
                                obj.title = '0';
                                obj.ratingValue = '0';
                                this.questionInfo[this.feedbackQuestion[i].id] = obj;
                            }
                        }else {
                            this.gs.snackbar('error', data.message);
                        }
                    });
                }
            } else {
                this.gs.snackbar('error', data.message);
            }
        });
    }
    
    initialializeAddFeedbackForm() { 
        this.getParams();
        this.customerFeedBack = this.formBuilder.group({
            feedback_comment: ['', Validators.required],
            request_id: [this.requestId || ''],
            shop_id: [this.shopId || ''],
            feedback_code: [this.feedbackCode || ''],
            customer_id: [this.customerId]
        });
    }
    ngOnInit() {
        this.initialializeAddFeedbackForm();
//        this.getParams();
//        this.customerFeedBack = this.formBuilder.group({
//            feedback_comment: ['', Validators.required],
//            request_id: [this.requestId || ''],
//            shop_id: [this.shopId || ''],
//            feedback_code: [this.feedbackCode || ''],
//            customer_id: [this.customerId]
//        });
//        this.gs.callAPI(GC.API_GET_FEEDBACK_QUESTIONS_URL, 'customer_id=' + this.gs.getCurrentCustomer().id).subscribe((data)=>{
//            if(data.result == 1){
//                this.feedbackQuestion = data.data;
//                // console.log(this.feedbackQuestion);
//                for (let i = 0; i < this.feedbackQuestion.length; i++) {
//                    let obj: any = {};
//                    this.questionInfo[this.feedbackQuestion[i].id] = {};
//                    obj.title = '0';
//                    obj.ratingValue = '0';
//                    this.questionInfo[this.feedbackQuestion[i].id] = obj;
//                }
//                // console.log(this.questionInfo);
//            }else {
//                this.gs.snackbar('error', data.message);
//            }
//        });
    }
    
    getParams() {
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            // console.log(params);
            this.shopParamRequestID = atob(params['r'] || 'MA==');
            this.shopParamID = atob(params['s'] || 'MA==');
            this.shopFeedbackCode = atob(params['c'] || 'MA==');
        });
    }
    
    updateTitle(qid, ev) {
        // console.log(ev);
        this.questionInfo[qid].title = (+ev) + 1;
    }
    
    // btnHover() {
    //     console.log('update');
    // }
    
    saveRating(qid, ev) {
        // console.log(ev);
        this.questionInfo[qid].ratingValue = ev;
    }
    
    saveFeedback() {
        if (this.customerFeedBack.valid) {
            let rating = {};
            for (let x in this.questionInfo) {
                if (x) {
                    rating[x] = this.questionInfo[x].ratingValue;
                    this.customerFeedBack.addControl('rating[' + x + ']', new FormControl('rating[' + x + ']'));
                    this.customerFeedBack.controls['rating[' + x + ']'].setValue(rating[x])
                    // console.log(this.customerFeedBack)
                }
            }
            this.gs.callAPI(GC.API_SAVE_CUSTOMER_FEEDBACK_URL, this.customerFeedBack.value).subscribe(
            data => {
                let dat: any = data;
                if (dat.result == "1") {
                    this.router.navigate(["/customer/list-service-requests"]);
                    this.gs.snackbar("success", dat.message);
                } else {
                    this.gs.snackbar("error", dat.message);
                }
            });
        }
    }
    
}