<div class="panel panel-primary" id="mainCompotents">
  <div class="panel-heading padding-left-15">
    <h3 class="panel-title">PARTS IMPORT LIST</h3>
    <button mat-mini-fab class="custom-btn-back" title="New Bulk Upload" *ngIf="isInventory" style="padding-left:0px;padding-right:5px;width:150px;border-right:1px solid #fff" (click)="bulkUpload()">
        <i class="fa fa-plus fa-lg margin-right-5"></i>New Bulk Upload</button>
  </div>
  <div class="panel-body bgcolor-lightgrey">
        <div class="display-full relative">
                <div class="col-sm-12">
                    <strong>Status of your uploaded excel file will be shown in File Upload Listing:</strong>
                    <ul>
                        <li>Pending - Waiting records to be imported to temporary table</li>
                        <li>Uploaded for Import - Records import to temporary table is in progress</li>
                        <li>Processing - Records import to parts table is in progress</li>
                        <li>Finished - Finish importing records to temporary table</li>
                    </ul>
                </div>
        </div>
    <div class="display-full relative">
        <div class="car-datatable uc-datatable mat-elevation-z1">
            <div class="table-responsive relative">
                <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg uc-table-responsive">

                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">File Name </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="File Name"><a href="javascript:;" (click)="downLoadCsv(row.name)"> {{row.name}}</a> </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="uploaded_by">
                            <mat-header-cell *matHeaderCellDef data-role="auto"> Uploaded By </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="auto" data-val="Uploaded By"> {{row.uploaded_by }} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef data-role="auto"> Status </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Status"> {{row.status }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="date_formate">
                        <mat-header-cell *matHeaderCellDef mat-sort-header  data-role="auto"> Added on </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Added on"> {{row.date_formate }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Actions">
                        <mat-header-cell *matHeaderCellDef data-role="button"> Actions </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="button" data-val="Actions">
                          <a href="javascript:;" (click)="viewImport(row)" title="View"> <i class="fa fa-eye"></i></a>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
                <div class="padding-15 text-center" [style.display]="getTotalRecords() < 1 ? '' : 'none'">
                     No Records Available
            </div>
        </div>
        <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
            <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="options.limit" [pageSizeOptions]="[5, 10,25, 100]" (page)="paginateByLimit($event)" >
            </mat-paginator>

            <div class="mat-paginator table-paging">
                <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
                <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                        <mat-icon>navigate_before</mat-icon>
                </button>
                <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                        <mat-icon>navigate_next</mat-icon>
                </button>
            </div>
        </div>
    </div>
    </div>
  </div>
</div>