import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Http, Headers, Response} from '@angular/http';
import {globalConstants} from "../../../../environments/globalConstants";
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalService } from '../../../../environments/global.service';
@Component({
    selector: 'app-video-show',
    templateUrl: './video-show.component.html',
    styleUrls: ['./video-show.component.scss']
})
export class VideoShowComponent {

    public vehVideo = '';
    public vehicle_video = '';
    public iframe:any='';
    public isPartsTechConfigured :any;
    constructor( @Inject(MAT_DIALOG_DATA) public data: any,public sanitizer:DomSanitizer, public gs: GlobalService, private http: Http, public dialogRef: MatDialogRef<VideoShowComponent>) {
        this.gs.diaLogObj(this.dialogRef);
        if(typeof data.name=='undefined'){
            this.iframe = this.sanitizer.bypassSecurityTrustResourceUrl(data.iframe);
            this.dialogRef['ref']=data.ref;
            this.isPartsTechConfigured = data.isPartsTechConfigured
        }else{
            this.vehicle_video = data.name;
            this.vehVideo = data.url;
        }
    }

    ngOnInit() {
    }
    close(){
        return this.dialogRef['ref'];
    }
}
