import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubscriptionPlansDialogComponent } from '../subscription-plans-dialog/subscription-plans-dialog.component';
import { GlobalService } from 'src/environments/global.service';
import { globalConstants } from 'src/environments/globalConstants';

@Component({
  selector: 'app-subscription-plan-preview',
  templateUrl: './subscription-plan-preview.component.html',
  styleUrls: ['./subscription-plan-preview.component.scss']
})
export class SubscriptionPlanPreviewComponent implements OnInit {
  public selectedPlanDetails: any;
  public subscriptionPlansList: any;
  constructor(
    public dialog: MatDialog,
    public globalService: GlobalService
  ) { }

  ngOnInit(): void {
    let planSummary = this.globalService.decodedWpiInfo();
    if(planSummary !== null){
      this.getAllSubscriptionPlans(planSummary.planId);
    }else{
      this.getAllSubscriptionPlans();
    }
  }

  getAllSubscriptionPlans(planId:any = null) {
    let payload = {
      user_type: "User",
      opr: 1
    }
    this.globalService.callAPI(globalConstants.API_GET_SHOPS_SUBSCRIPTION_PLANS, payload).subscribe((response: any) => {
      if (response.result == 1) {
        this.subscriptionPlansList = response.data;
        if (planId !== null) {
          this.selectedPlanDetails = this.subscriptionPlansList.find((plan) => plan.bt_plan_id == planId);
        } else {
          this.selectedPlanDetails = this.subscriptionPlansList.find((plan) => plan.is_popular == 1);
          this.globalService.encodedWpiInfo(this.selectedPlanDetails);
        }
      }
    })
  }

  openSubscriptionPlansDialog() {
    let dialogRef = this.dialog.open(SubscriptionPlansDialogComponent, {
      panelClass: ['car-dialog-form','subscription-plan-dialog'],
      data: this.subscriptionPlansList
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result.status == 1){
         this.selectedPlanDetails = result.selectedSubscription;
        this.globalService.encodedWpiInfo(this.selectedPlanDetails);
        }
    })
}

}
