<div class="car-panel labor-partstech-panel" id="mainCompotents">
    <div class="panel panel-primary">
        <div class="panel-heading padding-left-15">
            <h3 class="panel-title">
                Partstech
            </h3>
        </div>
        <div class="panel-body bgcolor-lightgrey partsTech">
            <div  class="row bgcolor-lightgrey form-content">
                <div class="container-fluid partstech-container-fluid">
                    <div class="row bgcolor-lightgrey form-content padding-top-45" *ngIf="isSubscribed == false">
                        <div class="container-fluid  padding-bottom-15 fluid-text">
                            Upgrade your plan to add PartsTech Connection.
                        </div>
                        <div class="padding-bottom-45 col-xs-12 text-center padding-10">
                            <button type="button" mat-raised-button class="mat-success font-bold mat-elevation-z1 upgrade-btn" (click)="gotoShopSubscription()">Upgrade Now</button>
                        </div>
                    </div>
                    <div class="row margin-0">
                        <div class="col-xs-12 subscription-alert-section margin-bottom-20" *ngIf="partstech || partstechLabor">
                            <mat-toolbar class="subscription-alert-bar"  >
                              <div class="row" *ngIf="partstech">
                                <div class="col-sm-12">
                                    <div class="subscription-content">
                                        <p class="partstech-help-text">You are using <b>PartsTech Demo Account</b> credentials, Please enter your PartsTech Account API credentials to start ordering parts with in CAR.</p>
                                        <p class="partstech-help-text">Don't have PartsTech Account. <a href="https://app.partstech.com/sign-up/create-account" target="_blank" rel="noopener noreferrer" class="click-here-anchor"><b>Click Here</b></a> to create your account now.</p>  
                                     </div>
                                </div>
                              </div>
                              <div class="row" *ngIf="partstechLabor">
                                <div class="col-sm-12 partstechLabor-alert-col">
                                    <div class="subscription-content">
                                        PartsTech Labor Estimates By Motor is not included in your current subscription Plan. Contact adminstrator to get it enabled.
                                    </div>
                                    <div class="subscription-btn">
                                        <button mat-raised-button class="font-bold mat-elevation-z0 send-btn" color="accent" (click)="sendPartsTechLaborRequest()">
                                          <span>Send Request Now</span>
                                        </button>
                                      </div>
                                </div>
                              </div>
                            </mat-toolbar>
                          </div>
                    </div>
                    <form [formGroup]="partstechform" *ngIf="isSubscribed" (ngSubmit)="addPartstechCredentials()" class="form-inline col-xs-12 padding-bottom-15">
                        <div class="col-xs-12 form-block partstech-block">
                            <div class="row">
                                <div class="form-group  col-sm-12 col-md-6">
                                    <label for="partner_id">Partstech User ID</label>
                                    <div class='relative'>
                                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                            <input matInput placeholder="Partstech User ID*" title="Partstech User ID" formControlName="partner_id"  class="form-control input-lg custom-form-input"/>
                                            <mat-error *ngIf="partstechform.controls.partner_id.hasError('required')">
                                                This field cannot be empty
                                            </mat-error>
                                        </mat-form-field>
    
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="form-group  col-sm-12 col-md-6">
                                    <label for="partner_key">Partstech API Key</label>
                                    <div class='relative'>
                                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                            <input matInput placeholder="Partstech API Key*" title="Partstech API Key (32 Character Key found in PartsTech Account Page)" formControlName="partner_key"  class="form-control input-lg custom-form-input" />
                                            <mat-error *ngIf="partstechform.controls.partner_key.hasError('required')">
                                                This field cannot be empty
                                            </mat-error>
                                        </mat-form-field>
                                        <p class="help-block" ><strong>Note : </strong>Partstech API Key - 32 Character Key found in PartsTech Account Page.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group  col-sm-12 col-md-6">
                                    <label>PartsTech Labor Settings</label>
                                     <div>
                                        <mat-checkbox [(ngModel)]="chkDisplayLaborTextInDescription"  [ngModelOptions]="{standalone: true}" (change)="handleLaborDescAndTooltip('laborTextDesc')">Pull labor notes into "Description of Work" in job recommendation</mat-checkbox>
                                     </div>
                                     <div class="space-notes-display">
                                         <span>
                                             <mat-checkbox [(ngModel)]="chkLaborTitleInDescription" [ngModelOptions]="{standalone: true}" (change)="handleLaborTitleAndNotes('chkNoteAndTitleInDesc')">Display Title</mat-checkbox><br>
                                             <mat-checkbox [(ngModel)]="chkLaborNotesInDescription" [ngModelOptions]="{standalone: true}" (change)="handleLaborTitleAndNotes('chkNoteAndTitleInDesc')">Display Notes</mat-checkbox><br>
                                             <mat-checkbox [(ngModel)]="chkLaborNotesPrefixInDescription" [ngModelOptions]="{standalone: true}" (change)="handleLaborTitleAndNotes('chkNoteAndTitleInDesc')">Include "[PT]:" Prefix</mat-checkbox>
                                             <mat-error *ngIf="showErrMsgForChkDisplayLaborText">Please select at least one option from Display Title or Display Notes.</mat-error>
                                        </span>
                                     </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group  col-sm-12 col-md-6 mt-1">
                                     <div>
                                        <mat-checkbox [(ngModel)]="chkDisplayLaborTextInTooltip" [ngModelOptions]="{standalone: true}" (change)="handleLaborDescAndTooltip('laborTextTooltip')">Display labor notes on hover over parts name </mat-checkbox>
                                     </div>
                                     <div class="space-notes-display">
                                         <span>
                                             <mat-checkbox [(ngModel)]="chkLaborTitleInTooltip" [ngModelOptions]="{standalone: true}" (change)="handleLaborTitleAndNotes('chkNoteAndTitleInTooltip')">Display Title</mat-checkbox><br>
                                             <mat-checkbox [(ngModel)]="chkLaborNotesInTooltip" [ngModelOptions]="{standalone: true}" (change)="handleLaborTitleAndNotes('chkNoteAndTitleInTooltip')">Display Notes</mat-checkbox>
                                             <mat-error *ngIf="showErrMsgForChkDisplayLaborTooltip"> Please select at least one option</mat-error>
                                        </span>
                                     </div>
                                </div>
                            </div>
    
                            <div class="row">
                                <div class="col-xs-12 form-block submit-block">
                                    <button type="submit" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Submit">
                                        SUBMIT
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

   
</div>
