<div mat-dialog-title class="relative">
     Subscription Plans
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
      <mat-icon  class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
  </div>
  <mat-dialog-content class="subscription-dialog-content">
    <div class="row grid-row gutter-bottom padding-sm-bottom-15">
      <owl-carousel-o [options]="customOptions">
        <ng-container *ngFor="let subscription of plansList;let i =index">
          <ng-template carouselSlide>
            <div class="col-xs-12">
              <mat-card class="subscription-card"
                [ngClass]="{'basic-plan':subscription.plan_type_group == 1,  'silver-plan':subscription.plan_type_group == 2}">
    
                <mat-card-header class="relative text-center">
                  <!-- <div class="mat-card-header-text  relative"> -->
                  <div class="popular-badge hide"><span>Popular</span></div>
                  <mat-card-title class="margin-0 relative">
                    <b>$</b><span class="price">{{subscription.amount}}</span>
                    <span class="display-full per-year font-bold">Per {{subscription.plan_type == 1? 'Month':'Year'}}</span>
                    <span class="display-full text-center">
                      Yearly amount ${{subscription.yearly_amount}} (SAVE $ 0.00 )
                    </span>
    
                  </mat-card-title>
                  <mat-card-subtitle class="font-bold">{{subscription.subscription_name}}</mat-card-subtitle>
                  <!-- </div> -->
                </mat-card-header>
                <mat-card-content>
                  <mat-expansion-panel hideToggle="true" [expanded]="true" class="feature-panel">
                    <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px">
                      <span class="font-bold icon">Includes:</span>
                    </mat-expansion-panel-header>
                    <ul class="list-group list-group-flush text-center perkslist">
                      <li class="list-group-item" *ngFor="let feature of subscription.featuresIncluded.features_included">
                        <div class="row list-row">
                          <div class="col-xs-10 col-sm-10"><i
                              class="fa fa-check fa-fw text-success"></i>&nbsp;&nbsp;<span>{{feature.name}}</span></div>
                          <div class="col-xs-2 col-sm-2 text-right car-red">
                            <i class="fa fa-circle" [ngClass]="feature.included == 2? 'hide':''"></i>
                            <img src="/assets/img/half-circle.png" [ngClass]="feature.included == 1? 'hide':''">
                          </div>
                        </div>
                      </li>
    
    
                    </ul>
    
                  </mat-expansion-panel>
                  <mat-card-actions class="text-center card-action-btn-section">
                    <label class="margin-bottom-15 margin-top-10">
    
                      <mat-checkbox (change)="handlePlanSwitch($event, subscription,i)"
                        [checked]="subscription?.checked">Yearly subscription amount
                        ${{subscription.yearly_amount}}</mat-checkbox>
                    </label>
                    <button mat-raised-button class="font-bold text-uppercase"
                      (click)="handleSubscriptionSelection(subscription)">Start Subscription</button>
                  </mat-card-actions>
                  <mat-expansion-panel hideToggle="true" [expanded]="true" class="extras-panel">
                    <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px">
                      <span class="font-bold icon">Extras:</span>
                    </mat-expansion-panel-header>
                    <ul class="list-group list-group-flush text-center perkslist">
                      <li class="list-group-item" *ngFor="let extra of subscription.extrasInfo.extras">
                        <div class="row list-row">
                          <div class="col-xs-9 col-md-10 ">
                            <i class="fa fa-check fa-fw text-success" [ngClass]="extra.included == 2? 'hide':''"></i>
                            <i class="fa fa-minus fa-fw car-red" [ngClass]="extra.included == 1? 'hide':''"></i>
                            &nbsp;&nbsp;<span>{{extra.name}}</span>
                          </div>
                          <div class="col-xs-3 col-md-2 text-right price-col">{{extra.price}}</div>
                        </div>
                      </li>
                    </ul>
                  </mat-expansion-panel>
    
                </mat-card-content>
              </mat-card>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="inquiry-action relative subscription-actions hide">
    <div class="flexBtns2">
      <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
    </div>
  </mat-dialog-actions>

