import {Component, OnInit} from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Http, Headers, Response, RequestOptions} from '@angular/http';
import {globalFunctions} from "../../../environments/globalFunctions";
import {globalConstants} from "../../../environments/globalConstants";
import {GlobalService} from "../../../environments/global.service";
import {lang} from "../../../environments/lang-en";
import {Title} from '@angular/platform-browser';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
const EMAIL_REGEX = globalConstants.EMAIL_REGEX;

@Component({
    selector: 'app-forgotpassword',
    templateUrl: './shopforgotpassword.component.html',
    styleUrls: ['./shopforgotpassword.component.scss']
})

export class ShopForgotPasswordComponent implements OnInit {
    public shop: FormGroup;
    public displayMsg: boolean = false;
    constructor(private route: ActivatedRoute, public formBuilder: FormBuilder, private http: Http, private router: Router, public globalService: GlobalService, public titleService: Title) {
        this.shop = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]]
        });
    }

    ngOnInit() {
        //this.titleService.setTitle(lang.TITLE_CUSTOMER_RESET_PASSWORD)
    }

    resetPassword() {
        if (this.shop.valid) {
            //var content = 'email=' + this.shop.value.email;
            var headers = new Headers();

            var data = new FormData();
            for(let i in this.shop.value){
                data.append(i, this.shop.value[i]);
            }

            headers.set('Accept', 'application/json');
            let options = new RequestOptions({ headers: headers });
            this.globalService.formData(globalConstants.API_SHOP_RESET_PASSWORD_URL, data).subscribe((response:any) => {
                if (response.result == '1') {
                    this.globalService.snackbar("success", response.message);
                    this.displayMsg = true;
                    setTimeout(() => this.router.navigate(['/shops']), 5000);
                } else {
                    this.globalService.snackbar("error", response.message);
                    this.displayMsg = false;
                }
            });
        }
    }
}
