<div mat-dialog-title class="relative m-0 appointment-title">
    <span>Ticket Assignment</span>
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
        <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
</div>

<form
    class="form-horizontal form-control-normal"
    (ngSubmit)="createTechAssignment()"
    autocomplete="off"
    [formGroup]="assignForm">
    <mat-dialog-content class="ticket_dialog_content overflow-data">
        <div class="center-block search-employee" style="max-width: 600px;">

            <div class="select-totech uc-ngx-select" *ngIf="sDisable">
                <ngx-select
                    class="display-full margin-bottom-25"
                    [items]="requestList"
                    formControlName="request_id"
                    (select)="checkIfValid();getVehicleDetail($event);"
                    placeholder="Select Service Request"
                    [(ngModel)]="itemId"></ngx-select>
            </div>

            <div class="vehicle-detail" *ngIf="vechicleWrap">
                <div class="row" *ngIf="(selectSR | json) != ({} | json)">
                    <div class="col-sm-12">
                        <h4>Service Request Information</h4>
                    </div>
                    <div class="col-sm-12"><label>SR ID : </label> {{ selectSR.request_id}}</div>
                    <div class="col-sm-12"><label>Appointment Date : {{ convertISODate(selectSR.requested_delivery_date) | date:'M/d/yy h:mm a'}} </label> </div>
                </div>
                <div class="break row"></div>
                <div class="row">
                    <div class="col-sm-12">
                        <h4>Vehicle Information</h4>
                    </div>
                    <div class="col-sm-12" *ngIf="carDetail.year !=undefined && carDetail.make !=undefined &&
           carDetail.model !=undefined"><label>Year/Make/Model : </label> {{carDetail.year+'/'+carDetail.make+'/'+carDetail.model}} &nbsp;&nbsp; <ng-container *ngIf="carDetail.state"><label>State : </label> {{carDetail.state}}</ng-container> </div>
                    <div class="col-sm-12"><label>License Plate : </label> {{carDetail.license_plate}}</div>
                    <div class="col-sm-12" *ngIf="carDetail.vin"><label>VIN : </label> {{carDetail.vin}}</div>
                </div>
                <div class="break row"></div>
                <div class="row">
                    <div class="padd"><label>Estimated Service Time : </label> {{estimated_hours ? estimated_hours+' hour' : '-'}}</div>
                </div>
            </div>        

            <div class="display-full margin-bottom-25">
                <label class="material-radio text-left">
                    <div class="mat-radio-container1">
                        <input
                            type="radio"
                            [checked]="assignForm.controls['assign_tomyself'].value==1"
                            value="1"
                            name="assign_tomyself"
                            (change)="setAssignType($event)"
                            formControlName="assign_tomyself"/>
                        <strong class="mat-radio-label-content">Assign to yourself</strong>
                    </div>
                </label>
                <label class="material-radio text-left" *ngIf="userData.role_id!='4'">
                    <div class="mat-radio-container1">
                        <input
                            type="radio"
                            [checked]="assignForm.controls['assign_tomyself'].value==2"
                            value="2"
                            name="assign_tomyself"
                            (change)="setAssignType($event)"
                            formControlName="assign_tomyself"/>
                        <strong class="mat-radio-label-content">Assign to technician</strong>
                    </div>
                </label>
                <label class="material-radio text-left" *ngIf="userData.role_id!='4'">
                    <div class="mat-radio-container1">
                        <input
                            type="radio"
                            [checked]="assignForm.controls['assign_tomyself'].value==3"
                            value="3"
                            name="assign_tomyself"
                            (change)="setAssignType($event)"
                            formControlName="assign_tomyself"/>
                        <strong class="mat-radio-label-content">Assign to employee</strong>
                    </div>
                </label>
            </div>

            <div
                class="select-totech uc-ngx-select"
                *ngIf="assignForm.controls['assign_tomyself'].value!=1 && userData.role_id!='4'">
                <ngx-select
                    class="display-full margin-bottom-20"
                    [items]="assignForm.controls['assign_tomyself'].value==2?techList:empList"
                    formControlName="emp_id"
                    (select)="checkIfValid();"
                    [noAutoComplete]="true"
                    [placeholder]="assignForm.controls['assign_tomyself'].value==2?'Select technician':'Select Employee'"></ngx-select>
            </div>

            <div class="display-full margin-bottom-5">
                <div class="col-md-3">Start Date:</div>
                <div class="col-md-5">
                    <div class="vlio-datepicker relative">
                        <dp-date-picker
                            mode="day"
                            [config]="datePickerConfig"
                            theme="dp-material dp-main"
                            class="btn-block"
                            (onChange)="checkIfValid();"
                            placeholder="Select Start date"
                            formControlName="event_start_date"
                            (ngModelChange)="startDateHandler($event)"
                            #dayPicker></dp-date-picker>
                        <button type="button" mat-icon-button (click)="dayPicker.api.open();">
                            <mat-icon>date_range</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="col-md-4">
                    <ngx-select class="display-full margin-bottom-20" formControlName="event_start_time" placeholder="Start Time"
                     [items]="sTimeSlots" (select)="startTimeHandler($event)" [noAutoComplete]="true">
                    </ngx-select>
                    <div
                      *ngIf="assignForm.controls['event_start_time'].invalid && (assignForm.controls['event_start_time'].dirty || assignForm.controls['event_start_time'].touched)">
                      <mat-error
                        [hidden]="!((assignForm.controls['event_start_time'].dirty) &&  assignForm.controls['event_start_time'].errors?.required)">
                        Start Time is <strong>required</strong>
                      </mat-error>
          
                    </div>
          
                  </div>
            </div>

            <div class="display-full margin-bottom-5">
                <div class="col-md-3">End Date:</div>
                <div class="col-md-5">
                    <div class="vlio-datepicker relative">
                        <dp-date-picker
                            mode="day"
                            [config]="datePickerConfig"
                            theme="dp-material dp-main"
                            class="btn-block"                         
                            placeholder="Select End date"
                            formControlName="event_end_date"
                            (ngModelChange)="endDateHandler($event)"
                            #dayPickerTwo></dp-date-picker>
                        <button type="button" mat-icon-button (click)="dayPickerTwo.api.open();">
                            <mat-icon>date_range</mat-icon>
                        </button>
                    </div>
                    <div class="f-error" >
                    <mat-error *ngIf="error.dError">End Date can't before start date.</mat-error>
                    </div>
                </div>
                <div class="col-md-4">
                    <ngx-select class="display-full margin-bottom-20"  formControlName="event_end_time" placeholder="End Time"
                      [items]="eTimeSlots" [noAutoComplete]="true">
                    </ngx-select>
                    <div
                      *ngIf="assignForm.controls['event_end_time'].invalid && (assignForm.controls['event_end_time'].dirty || assignForm.controls['event_end_time'].touched)">
                      <mat-error
                        [hidden]="!((assignForm.controls['event_end_time'].dirty) &&  assignForm.controls['event_end_time'].errors?.required)">
                        End Time is <strong>required</strong>
                      </mat-error>
                    </div>
                    <div class="f-error" >
                    <mat-error *ngIf="error.tError">End time can't before start time.</mat-error>
                    </div>
                  </div>
            </div>
            <div class="display-full overwrite-time">              
                <mat-checkbox formControlName="overwrite_time">Do not overwrite the timing, while auto pushing the job queue</mat-checkbox>               
            </div>

            <p class="xsfont" *ngIf="neededDate==true">Warning: Tech Assign Start/End date
                is breaching Needed By date, Please change assign date, or click here to update
                the Needed date</p>
            <button
                mat-raised-button
                btn-info
                class="font-bold"
                (click)="updateNeededBy()"
                *ngIf="neededDate==true">Uppdate Needed date
                <i class="fa fa-pencil" style="height: 18px; width: 20px;"></i>
            </button>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="inquiry-action relative">
        <button
            type="submit"
            mat-raised-button
            color="accent"
            title="Save"
            [disabled]="submitDisabled">
            SAVE</button>
        <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
    </mat-dialog-actions>
</form>
