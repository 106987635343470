import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GlobalService } from "../../../environments/global.service";
import { globalConstants } from "../../../environments/globalConstants";
import { globalFunctions } from "../../../environments/globalFunctions";
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { style } from '@angular/animations';
declare var jQuery:any;
declare var $ :any;
@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss']
})
export class PermissionComponent implements OnInit , AfterViewChecked {
  public allAll = false;
  view_all_check = false;
  public formPermission: FormGroup;
  public admin: any;
  public roles: Roles[];
  public rolesList = [];
  public selectedRole = 0;
  public search: string;
  public loginType='';
  public showNoResult: boolean = false;
  constructor(private formBuilder: FormBuilder, private globalService: GlobalService, private router: Router) {
    this.globalService.setMetaData("SHOPS","MANAGE_ROLES_PERMISSIONS")
  }
  ngAfterViewChecked() {
    // let elements: any = document.getElementsByClassName('role-list-all');
    // if(elements.length){
    //    jQuery('.role-list-all').css({'padding-right': this.getScrollbarWidth()});
    // }

  }
  getScrollbarWidth() {
    var outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.overflow = "scrollbar"; // needed for WinJS apps

    document.body.appendChild(outer);

    var widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";

    // add innerdiv
    var inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);

    var widthWithScroll = inner.offsetWidth;

    // remove divs
    outer.parentNode.removeChild(outer);
    let scrollbarWidth = widthNoScroll - widthWithScroll;
    return 15 + scrollbarWidth + "px";
  }
  ngOnInit() {
    this.loginType=this.globalService.getLoginType();
    if(this.loginType=='admin'){
      this.admin = this.globalService.getAdmin();
    }else if(this.loginType=='shops'){
      this.admin = this.globalService.getCurrentUser();
      setTimeout(()=>{
      let isEnable=this.globalService.getAcl('shop_roles_permission','addNew');
      if(!(isEnable)){
          this.router.navigate(['shops/action/forbidden']);
      }
    },globalConstants.RELOAD_WAIT);
    }
    this.getPermission();
  }
  /**
   * on Change Check All Checkbox checked  by (View, Add , Edit ,Delete )
   * @param roleId
   * @param roleIndex
   * @param actionType
   * @param actionTypeStatus
   */
  checkAllAction(roleId, roleIndex, actionType, actionTypeStatus) {
    this.roles[roleIndex].items.forEach(elements => {
      switch (actionType) {
        case 'view':
          elements.permissions.view = (actionTypeStatus == true) ? false : true;
          break;
        case 'add':
          elements.permissions.add = (actionTypeStatus == true) ? false : true;
          break;
        case 'edit':
          elements.permissions.edit = (actionTypeStatus == true) ? false : true;
          break;
        case 'delete':
          elements.permissions.delete = (actionTypeStatus == true) ? false : true;
          break;
      }
    });
    this.validateCheckAll(roleId, roleIndex, actionType, actionTypeStatus, '', '', '1');
  }
  /**
   * To validate permission checkboxes upon changing Permissions checkboxes (View, Add,Edit Delete)
   * @param roleId
   * @param roleIndex
   * @param actionType
   * @param actionTypeStatus
   * @param permissionId
   * @param permissionIndex
   */
  checkPermission(roleId, roleIndex, actionType, actionTypeStatus, permissionId, permissionIndex) {
    switch (actionType) {
      case 'view':
        this.roles[roleIndex].items[permissionIndex].permissions.view = (this.roles[roleIndex].items[permissionIndex].permissions.view) ? false : true;
        this.validateCheckAll(roleId, roleIndex, actionType, this.roles[roleIndex].items[permissionIndex].permissions.view, permissionId, permissionIndex, '2')
        break;
      case 'add':
        this.roles[roleIndex].items[permissionIndex].permissions.add = (this.roles[roleIndex].items[permissionIndex].permissions.add) ? false : true;
        this.validateCheckAll(roleId, roleIndex, actionType, this.roles[roleIndex].items[permissionIndex].permissions.add, permissionId, permissionIndex, '2')
        break;
      case 'edit':
        this.roles[roleIndex].items[permissionIndex].permissions.edit = (this.roles[roleIndex].items[permissionIndex].permissions.edit) ? false : true;
        this.validateCheckAll(roleId, roleIndex, actionType, this.roles[roleIndex].items[permissionIndex].permissions.edit, permissionId, permissionIndex, '2')
        break;
      case 'delete':
        this.roles[roleIndex].items[permissionIndex].permissions.delete = (this.roles[roleIndex].items[permissionIndex].permissions.delete) ? false : true;
        this.validateCheckAll(roleId, roleIndex, actionType, this.roles[roleIndex].items[permissionIndex].permissions.delete, permissionId, permissionIndex, '2')
        break;
    }
  }
  /**
   * To validate 'ALL' checkboxes upon changing 'ALL' and upon changing Permissions checkboxes (View, Add,Edit Delete)
   * @param roleId
   * @param roleIndex
   * @param actionType
   * @param actionTypeStatus
   * @param permissionId
   * @param permissionIndex
   * @param isAllClicked
   */
  validateCheckAll(roleId, roleIndex, actionType, actionTypeStatus, permissionId, permissionIndex, isAllClicked) {
  
    if (isAllClicked == 1) {
      var checkedVal = (actionTypeStatus == true) ? false : true;
      this.checkAll(roleIndex, actionType, checkedVal);
    } else {

      switch (actionType) {
        case 'view':
          var filterResult = this.roles[roleIndex].items.filter(function (x) { return x.permissions.view == false });
          break;
        case 'add':
          var filterResult = this.roles[roleIndex].items.filter(function (x) { return x.permissions.add == false });
          break;
        case 'edit':
          var filterResult = this.roles[roleIndex].items.filter(function (x) { return x.permissions.edit == false });
          break;
        case 'delete':
          var filterResult = this.roles[roleIndex].items.filter(function (x) { return x.permissions.delete == false });
          break;
      }

     
      if (filterResult.length > 0) {
        this.checkAll(roleIndex, actionType, false);
      } else {
        this.checkAll(roleIndex, actionType, true);
      }
    }
  }
  /**
   * To check/uncheck 'ALL' checkboxes
   * @param roleIndex
   * @param actionType
   * @param checkedValue
   */
  checkAll(roleIndex, actionType, checkedValue) {
    switch (actionType) {
      case 'view':
        this.roles[roleIndex].all.view = checkedValue;
        break;
      case 'add':
        this.roles[roleIndex].all.add = checkedValue;
        break;
      case 'edit':
        this.roles[roleIndex].all.edit = checkedValue;
        break;
      case 'delete':
        this.roles[roleIndex].all.delete = checkedValue;
        break;
    }
  }
  getPermission() {
    let data = { user_id: this.admin.id, user_type: this.admin.user_type };
    this.globalService.callAPI(globalConstants.API_ROLE_PERMISSION_URL, data).subscribe((r:any) => {
      if (r.result == '1') {
        this.roles = r.data;
        this.roles.forEach((e,index)=>{
          if(e.id !== '5'){
            this.rolesList.push({id:index,text:e.name});
            e.items.forEach(element => {
              element.show = true;
            });
          }
        })
      }
    });
  }
  savePermission() {
    let conent= {user_id:this.admin.id,user_type:this.admin.user_type,permissions:JSON.stringify(this.roles)};
    let formData=new FormData();
    for(var i in conent){
      formData.append(i,conent[i]);
    }
    this.globalService.formData(globalConstants.API_ROLE_PERMISSION_SAVE_URL, formData).subscribe((r) => {
      if (r.result == '1') {
        this.globalService.snackbar('success',r.message);
      }
    });
  }
  applyToAll(role){
    let conent= {user_id:this.admin.id,user_type:this.admin.user_type,permissions:JSON.stringify(role)};
    let formData=new FormData();
    for(var i in conent){
      formData.append(i,conent[i]);
    }
    this.globalService.formData(globalConstants.API_ROLE_PERMISSION_APPLY_TO_ALL, formData).subscribe((r) => {
      if (r.result == '1') {
        this.globalService.snackbar('success',r.message);
      }
    });
  }

  searchPermissions(roleIndex: number){
    let hasVisibleItems = false;
    if(this.search != ''){
      this.roles[roleIndex].items.forEach(e=>{
        if(e.name.toLowerCase().includes(this.search.toLowerCase())){
          e.show = true;
          hasVisibleItems = true;
        }else{
          e.show = false;
        }
      });
    }else{
      this.roles[roleIndex].items.forEach(e=>{
        e.show = true;
        hasVisibleItems = true;
      });
    }
    this.showNoResult = !hasVisibleItems;
  }

  clearPermissions(roleIndex: number){
    if(this.search == ''){
      this.roles[roleIndex].items.forEach(e=>{
        e.show = true;
        this.showNoResult = false;
      });
    }
  }

  onChangeRole($event){
    this.search = '';
    this.clearPermissions(this.selectedRole);
  }
}
export class Roles {
  id: String;
  name: String;
  is_admin: boolean;
  all: any;
  items: any;
}

