import { Component, OnInit, ViewChild, Pipe, Inject, ChangeDetectorRef, ViewEncapsulation, Optional, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup, FormControl, FormBuilder, Validators, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { globalFunctions } from "../../../../environments/globalFunctions";
import { globalConstants } from "../../../../environments/globalConstants";
import { GlobalService } from "../../../../environments/global.service";
import { DiscountRulesComponent, Condition } from '../discount-rules/discount-rules.component';
import { INgxSelectOption } from 'ngx-select-ex';
import { map, switchMap } from 'rxjs/operators';
import { Observable, pipe,Subscription } from 'rxjs';
// import { CustomLoader } from '../../../customloader';

@Component({
  selector: 'app-add-discount',
  templateUrl: './add-discount.component.html',
  styleUrls: ['./add-discount.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddDiscountComponent implements OnInit, OnDestroy {

  @ViewChild('addDiscountRules') addDiscountRules:DiscountRulesComponent;
  
  public shopAddDiscount: FormGroup;
  public submitted = false;
  public rulesSubmitted = false;
  public isCodeUnique = true;
  public currentCustomer;
  public currentCustomerName;
  public discount_type;
  public discount_multiple_usage;
  public minStartDate = new Date();
  public minEndDate = new Date();
  public discountStatus:any = '1';
  public statusList:any = [
    {id:'1',text:'Active'},
    {id:'0',text:'Inactive'}
  ];
   
  conditionTypes:Array<any>;
  
  rule:Condition = {
    type:globalConstants.DISCOUNT_CONDITION_TYPE.value,
    aggregator:"all",
    root:true,
    conditions:[]
  }
  
  customerData:Array<any>;
  filteredCustomerData:Array<any>;
  selectedCustomer:any;
  
  /**
   * Accordion UI variables
   */
  rulesAccordion:boolean = true;
  codeDetailsAccordion:boolean = true;
  actionAccordion:boolean = true;
  
  /**
   * Popup ui variables
   */
  isPopup:boolean;
  addedCoupons:Array<any> = [];
  
  showApplyOnClose:boolean = true;
  
  /**
   * Quick Mode variables
   */
  isQuickMode:boolean;
  filledCouponCode:string;
  
  public subscriptionDataForAPI: Subscription = new Subscription();
  constructor(private formBuilder: FormBuilder, private router: Router, public globalService: GlobalService, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, @Optional() public dialogRef: MatDialogRef<AddDiscountComponent>,
  //  private customLoader:CustomLoader
   ) {
    if(data && data.isPopup){
      this.isPopup = true;
      this.showApplyOnClose = data.showApplyOnClose;
      if(data.isQuickMode){
        this.isQuickMode = true;
        this.filledCouponCode = data.couponCode;
      }
    }
    if (localStorage.getItem("loggedInUser")) {
      this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
      this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
    } else {
      router.navigate(["/shops"])
    }
    setTimeout(()=>{
      let inSubscription:boolean=true;
      // inSubscription = this.globalService.isSubscribe(globalConstants.SUBSCRIPTION_DISCOUNTS_FEATURE);
      let isEnable=this.globalService.getAcl('discounts','addNew');
      if(!(isEnable) || !(inSubscription)){
        this.router.navigate(['shops/action/forbidden']);
      }
      else{
        this.getDiscountMasterData();
      }
     },globalConstants.RELOAD_WAIT);
  }

  initialializeAddDiscountForm() {
    this.shopAddDiscount = this.formBuilder.group({
      discount_name: ['', Validators.required],
      discount_code: ['', [Validators.required], this.validateCouponCode()],
      startDateObj: ['', Validators.required],
      endDateObj: ['', Validators.required],
      type: ['1', Validators.required],
      type_value: ['', [Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/), Validators.min(1)]],
      // max_redemption_limit:['', Validators.min(1)],
      max_redemption_limit:[''],
      multiple_usage: ['1', Validators.required],
      usage_limit: ['', Validators.min(1)],
      usage_per_customer_limit: ['', Validators.min(1)],
      status: [''],
      no_end_date: [false],
      is_combinational:['0'],
      customers:[''],
      to_apply:[false]
    });
    this.shopAddDiscount.controls['no_end_date'].valueChanges.subscribe((updatedVal)=>{
      // console.log('no end date updated val = ', updatedVal);
      if(updatedVal){
        this.shopAddDiscount.controls['endDateObj'].clearValidators();
        this.shopAddDiscount.controls['endDateObj'].updateValueAndValidity();
      }
      else{
        this.shopAddDiscount.controls['endDateObj'].setValidators([Validators.required]);
        this.shopAddDiscount.controls['endDateObj'].updateValueAndValidity();
      }
    });
    this.shopAddDiscount.get('usage_limit').setValue(1);
    this.shopAddDiscount.get('usage_per_customer_limit').setValue(1);
    setTimeout(()=>{
      // for default value 1 of type
      this.shopAddDiscount.get('type_value').setValidators([Validators.required,Validators.min(1)]);
      this.shopAddDiscount.get('type_value').updateValueAndValidity();
      this.shopAddDiscount.get('max_redemption_limit').clearValidators();
      this.shopAddDiscount.get('max_redemption_limit').updateValueAndValidity();
      // for default value 1 of usage_type
      this.shopAddDiscount.get('usage_limit').clearValidators();
      this.shopAddDiscount.get('usage_limit').updateValueAndValidity();
      this.shopAddDiscount.get('usage_per_customer_limit').clearValidators();
      this.shopAddDiscount.get('usage_per_customer_limit').updateValueAndValidity();
      
      if(this.isQuickMode){
        this.setQuickModeOptions();
      }
    },300);
  }

  handleStartDateChange(){
    this.minEndDate = this.shopAddDiscount.get('startDateObj').value;
    this.shopAddDiscount.get('endDateObj').setValue('');
  }

  dateFormatWithoutTime(dateValue) {
    return new Date(dateValue).getFullYear() + "-" + ((new Date(dateValue).getMonth()+1) < 10 ? '0' : '')+(new Date(dateValue).getMonth()+1)+'-'+(new Date(dateValue).getDate() < 10 ? '0' : '')  + new Date(dateValue).getDate();
  }

  toggleTypeValidation(){
    // this.shopAddDiscount.get('type').setValue(this.discount_type);
    // this.shopAddDiscount.get('type').updateValueAndValidity();
    if(this.shopAddDiscount.get('type').value == 1){
      this.shopAddDiscount.get('type_value').setValidators([Validators.required,Validators.min(1)]);
      this.shopAddDiscount.get('type_value').updateValueAndValidity();
      this.shopAddDiscount.get('max_redemption_limit').clearValidators();
      this.shopAddDiscount.get('max_redemption_limit').updateValueAndValidity();
    }else{
      this.shopAddDiscount.get('type_value').setValidators([Validators.required,Validators.min(1),Validators.max(100)]);
      this.shopAddDiscount.get('type_value').updateValueAndValidity();
      this.shopAddDiscount.get('max_redemption_limit').setValidators([Validators.required,Validators.min(1)]);
      this.shopAddDiscount.get('max_redemption_limit').updateValueAndValidity();
    }
  }

  isNotNumber(num){
    return isNaN(num);
  }

  toggleUsageValidation() {
    if (this.shopAddDiscount.get('multiple_usage').value == 2) {
      this.shopAddDiscount.get('usage_limit').setValidators([Validators.required,Validators.min(1)]);
      this.shopAddDiscount.get('usage_limit').updateValueAndValidity();
      this.shopAddDiscount.get('usage_per_customer_limit').setValidators([Validators.required,Validators.min(1)]);
      this.shopAddDiscount.get('usage_per_customer_limit').updateValueAndValidity();
    } else {
      this.shopAddDiscount.get('usage_limit').clearValidators();
      this.shopAddDiscount.get('usage_limit').updateValueAndValidity();
      this.shopAddDiscount.get('usage_per_customer_limit').clearValidators();
      this.shopAddDiscount.get('usage_per_customer_limit').updateValueAndValidity();
    }
    // this.shopAddDiscount.get('multiple_usage').setValue(this.discount_multiple_usage);
    // this.shopAddDiscount.get('multiple_usage').updateValueAndValidity();
  }

  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
  }

  onlyNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) return false;
    return true;
  }

  disableKey(event) {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey == true && (event.which == '67' || event.which == '86' || String.fromCharCode(event.which).toLowerCase() == 's')) {
      console.log('thou. shalt. not. PASTE!');
      event.preventDefault();
    }
  }

  checkIfValid() {
    setTimeout(() => {
      if (this.shopAddDiscount.valid) {
        this.submitted = false;
      }
    }, 0);
  }

  ngOnInit() {
    this.globalService.setMetaData("SHOPS", "ADD_DISCOUNT");
    this.initialializeAddDiscountForm();
  }

  addDiscountCoupon(opr) {
    // console.log('form = ', this.shopAddDiscount);
    this.shopAddDiscount.controls['discount_code'].clearAsyncValidators();
    // this.shopAddDiscount.get('type').setValue(this.discount_type);
    // this.shopAddDiscount.get('multiple_usage').setValue(this.discount_multiple_usage);
    this.shopAddDiscount.get('status').setValue(this.discountStatus);

    this.shopAddDiscount.addControl('start_date',new FormControl(''));
    this.shopAddDiscount.addControl('end_date',new FormControl(''));
    let startDateObj = this.shopAddDiscount.get('startDateObj').value;
    let endDateObj = this.shopAddDiscount.get('endDateObj').value;
    this.shopAddDiscount.get('start_date').setValue(startDateObj ? this.dateFormatWithoutTime(startDateObj) : '');
    this.shopAddDiscount.get('end_date').setValue(endDateObj ? this.dateFormatWithoutTime(endDateObj) : '');

    Object.keys(this.shopAddDiscount.controls).map((controlName) => {
      this.shopAddDiscount.get(controlName).markAsTouched({ onlySelf: true });
    });
    // let discountRules = this.addDiscountRules.getRulesAndConditions();
    let discountRules = this.addDiscountRules.getRulesAndConditionsStringified();
    if(this.isQuickMode){
      this.shopAddDiscount.get('discount_name').setValue(this.shopAddDiscount.get('discount_code').value);
      this.shopAddDiscount.get('discount_name').updateValueAndValidity();
    }
    // this.clearRulesJSON(this.rule);
    // console.log('rule = ', discountRules);
    
    if(this.shopAddDiscount.valid && discountRules) {
        var data = new FormData();
        let formData = this.shopAddDiscount.value; 
        let toApply = formData.to_apply;
        data.append('user_type',this.currentCustomer.user_type);
        data.append('user_id',this.currentCustomer.id);
        data.append('discount_rules', discountRules);
        if(this.shopAddDiscount.controls['customers'].value){
          data.append('customers', this.shopAddDiscount.controls['customers'].value.join(','));
        }
        else{
          data.append('customers', '');
        }
        data.append('no_end_date', (this.shopAddDiscount.controls['no_end_date'].value ? '1' : '0'));
        console.log('final form data = ', data);
        let exceptions = ['startDateObj','endDateObj', 'customers', 'no_end_date'];
        for(let i in this.shopAddDiscount.value){
          if(exceptions.indexOf(i) == -1) data.append(i, this.shopAddDiscount.value[i]);
        }
        this.globalService.formData(globalConstants.API_ADD_SHOP_DISCOUNT_URL,data).subscribe((data:any)=> {
            var message = data.message;
            if (data.result == "1") {
                if(!this.isPopup){
                  if (opr == 1) {
                    this.router.navigate(["/shops/discounts"]);
                    this.globalService.snackbar('success', message);
                  } else if (opr == 2) {
                    this.globalService.setScrolling("body", 500, 0);
                    this.globalService.snackbar('success', message);
                    this.addDiscountRules.reset();
                    Object.keys(this.shopAddDiscount.controls).map((controlName) => {
                      this.shopAddDiscount.get(controlName).reset();
                    });
                    this.shopAddDiscount.controls['type'].reset('1');
                    this.shopAddDiscount.controls['multiple_usage'].reset('1');
                    this.shopAddDiscount.controls['is_combinational'].reset('0');
                  }
                }
                else{
                  if (opr == 1) {
                    if(toApply){
                      this.addedCoupons[0] = formData.discount_code;
                    }
                    this.dialogRef.close({ addedCoupon : this.addedCoupons });
                    this.globalService.snackbar('success', message);
                  } else if (opr == 2) {
                    this.globalService.snackbar('success', message);
                    this.addDiscountRules.reset();
                    Object.keys(this.shopAddDiscount.controls).map((controlName) => {
                      this.shopAddDiscount.get(controlName).reset();
                    });
                    this.shopAddDiscount.controls['type'].reset('1');
                    this.shopAddDiscount.controls['multiple_usage'].reset('1');
                    this.shopAddDiscount.controls['is_combinational'].reset('0');
                  }
                }
            } else {
                this.globalService.snackbar('error', message);
            }
        });
    } else {
        this.submitted = true;
        this.rulesSubmitted = true;
        this.mandatoryMsg();
        this.shopAddDiscount.controls['discount_code'].asyncValidator = this.validateCouponCode()
    }
  }

  mandatoryMsg() {
    this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
  }

  getDiscountMasterData(){
    let body:any = {
      user_type:this.currentCustomer.user_type,
      user_id:this.currentCustomer.id
    }
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_DISCOUNT_RULE_OPTIONS_URL, body)
    .subscribe((response:any)=>{
      // console.log('rule options = ', response);
      this.getCustomersList();
      if(response.result == 1){
        this.conditionTypes = response.data;
        this.conditionTypes.push(globalConstants.DISCOUNT_CONDITION_TYPE);
        this.conditionTypes.sort((a,b)=>{
          return a.name > b.name ? 1 : -1;
        });
      }
      else{
        this.globalService.snackbar('error', response.message);
      }
    });
  }
  
  getCustomersList(){
    let body:any = {
      user_type:this.currentCustomer.user_type,
      user_id:this.currentCustomer.id
    }
    this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_SHOP_CUSTOMERS_DATA_URL, body)
    .subscribe((response:any)=>{
      // console.log('rule options = ', response);
      if(response.result == 1){
        this.customerData = response.data;
        this.customerData.forEach((customer)=>{
          customer.label = customer.name+' ('+customer.email+')';
        });
        this.filteredCustomerData = this.customerData.slice(0,100);
        if(this.isPopup && this.data.customerId){
          this.selectedCustomer = this.customerData.find((customer)=>{
            return customer.id == this.data.customerId;
          });
          this.shopAddDiscount.get('customers').setValue([this.data.customerId]);
          this.shopAddDiscount.get('customers').disable({onlySelf:true});
        }
      }
    });
  }
  
  filterCustomer(ev:string){
    console.log('typed = ',ev);
    if(ev){
      this.filteredCustomerData = this.customerData.filter((customer)=>{
        return customer.label.indexOf(ev) > 0;
      });
    }
    else{
      this.filteredCustomerData = this.customerData.slice(0,100).filter((customer)=>{
        return this.shopAddDiscount.get('customers').value.indexOf(customer.id) == -1;
      });
    }
  }
  
  expandAccordion(accordion:boolean){
    accordion = !accordion;
  }
  
  areRulesValid(ev){
    // console.log('on validate = ', ev);
    this.rulesSubmitted = !ev;
  }
  
  updateSubmitted(ev){
    // console.log('on rules removed = ', ev);
    this.submitted = false;
  }
  
  getSelectedCustomers(ev:any){
    console.log('selected customers = ', ev);
    // console.log('selected customers add = ', this.shopAddDiscount.controls['customers'].value);
    // this.filteredCustomerData = this.customerData.slice(0,100).filter((customer)=>{
    //   return this.shopAddDiscount.get('customers').value.indexOf(customer.id) == -1;
    // });
  }
  
  customerRemoved(ev){
    console.log('customer removed = ', ev);
    // console.log('selected customers remove = ', this.shopAddDiscount.controls['customers'].value);
  }
  
  validateCouponCode():AsyncValidatorFn {
    return (control: FormControl):Observable<{ [key: string]: any } | null | Observable<ValidationErrors> | null > =>{
      // console.log('control = ', control);
      return this.checkCouponCode(control.value)
      // .debounceTime(1000)
      .pipe(
        map((res:any)=>{
          console.log('res = ', res);
          if(res.result == 1){
            this.submitted = false;
            this.rulesSubmitted = false;
            this.isCodeUnique = true;
          }
          else{
            this.submitted = true;
            this.rulesSubmitted = true;
            this.isCodeUnique = false;
          }
          // to trigger validation message
          document.getElementById('discount_code').blur();
          return (res.result == 0) ? {couponExists:true} : null;
        })
      );
      // return pipe(
      //   debounceTime(time),
      //   switchMap(()=> this.checkCouponCode(control.value)),
      //   map((res:any) => { 
      //     return (res.result == 0) ? {couponExists:true} : {couponExists:false};
      //   })
      // )
    }
  }
  
  private checkCouponCode(code:string){
    let body:any = {
      user_id: this.currentCustomer.id,
      user_type: this.currentCustomer.user_type,
      coupon_code: code
    }
    return Observable
    .timer(1000)
    .pipe(
      switchMap(()=>{
        // this.customLoader.showLoader = false;
        return this.globalService.callAPI(globalConstants.API_VALIDATE_COUPON_CODE_URL, body)
      })
    )
  }
  
  toggleViewMode(){
    this.isQuickMode = !this.isQuickMode;
    this.initAddCouponForm();
    if(this.isQuickMode){
      this.setQuickModeOptions();
    }
    else{
      this.resetQuickModeOptions();
    }
  }
  
  initAddCouponForm(){
    this.shopAddDiscount.get('usage_limit').setValue(1);
    this.shopAddDiscount.get('usage_per_customer_limit').setValue(1);
    setTimeout(()=>{
      // for default value 1 of type
      this.shopAddDiscount.get('type_value').setValidators([Validators.required,Validators.min(1)]);
      this.shopAddDiscount.get('type_value').updateValueAndValidity();
      this.shopAddDiscount.get('max_redemption_limit').clearValidators();
      this.shopAddDiscount.get('max_redemption_limit').updateValueAndValidity();
      // for default value 1 of usage_type
      this.shopAddDiscount.get('usage_limit').clearValidators();
      this.shopAddDiscount.get('usage_limit').updateValueAndValidity();
      this.shopAddDiscount.get('usage_per_customer_limit').clearValidators();
      this.shopAddDiscount.get('usage_per_customer_limit').updateValueAndValidity();
    },100);
  }
  
  setQuickModeOptions(){
    setTimeout(()=>{
      this.shopAddDiscount.get('discount_name').setValue(this.filledCouponCode);
      this.shopAddDiscount.get('discount_name').updateValueAndValidity();
      this.shopAddDiscount.get('discount_code').setValue(this.filledCouponCode);
      this.shopAddDiscount.get('discount_code').updateValueAndValidity();
      this.shopAddDiscount.get('startDateObj').setValue(new Date());
      this.shopAddDiscount.get('startDateObj').updateValueAndValidity();
      this.shopAddDiscount.get('no_end_date').setValue(true);
      this.shopAddDiscount.get('no_end_date').updateValueAndValidity();
      this.shopAddDiscount.get('multiple_usage').setValue('1');
      this.shopAddDiscount.get('multiple_usage').updateValueAndValidity();
      this.shopAddDiscount.get('status').setValue('1');
      this.shopAddDiscount.get('status').updateValueAndValidity();
      this.shopAddDiscount.get('to_apply').setValue(true);
      this.shopAddDiscount.get('to_apply').updateValueAndValidity();
      this.shopAddDiscount.get('is_combinational').setValue('1');
      this.shopAddDiscount.get('is_combinational').updateValueAndValidity();
    },200);
  }
  
  resetQuickModeOptions(){
    setTimeout(()=>{
      this.shopAddDiscount.get('discount_name').reset('');
      this.shopAddDiscount.get('discount_name').updateValueAndValidity();
      this.shopAddDiscount.get('discount_code').reset('');
      this.shopAddDiscount.get('discount_code').updateValueAndValidity();
      this.shopAddDiscount.get('startDateObj').reset('');
      this.shopAddDiscount.get('startDateObj').updateValueAndValidity();
      // this.shopAddDiscount.get('no_end_date').setValue(true);
      // this.shopAddDiscount.get('no_end_date').updateValueAndValidity();
      // this.shopAddDiscount.get('status').setValue('1');
      // this.shopAddDiscount.get('status').updateValueAndValidity();
      // this.shopAddDiscount.get('to_apply').setValue(true);
      // this.shopAddDiscount.get('to_apply').updateValueAndValidity();
      this.shopAddDiscount.get('is_combinational').setValue('0');
      this.shopAddDiscount.get('is_combinational').updateValueAndValidity();
    },200);
  }

  ngOnDestroy(){
    this.subscriptionDataForAPI.unsubscribe();
  } 
}
