<div class="panel panel-primary">
  <div class="panel-heading padding-left-30">
    <h3 class="panel-title">Service Request Detail</h3>
    <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <div class="panel-body bgcolor-lightgrey">
    <div class="jumbotron padding-25 car-view-str">
      <div class="row">
        <div class="col-sm-6 col-md-6">
          <h3 class="margin-0">Service Request Information</h3>
        </div>
        <div class="col-sm-6 col-md-6">
          <a class="btn btn-success pull-right " id="downloadInvoice" *ngIf="serviceRequestData.value.request_status=='6' || serviceRequestData.value.request_status=='8'" name="downloadInvoice" target="_blank" (click)="getInvoicePdf()" title="">
            <i class="fa fa-download"></i>  Download Invoice
          </a>
        </div>
      </div>
      <hr>

      <form id="viewServiceRequestForm" class="form-horizontal" role="form">
        <!-- Replaced div grid structure with table -->
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Request ID:</label></div>
              <div class="col-sm-6">{{this.serviceRequestData.value.request_id}}</div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Shop Name :</label></div>
              <div class="col-sm-6 break-word">
                {{this.serviceRequestData.value.service_facility_name || this.serviceRequestData.value.company_name}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Vehicle:</label></div>
              <div class="col-sm-6">
                {{vehicleName}}
                <p id="expMsgs" class="text-danger" style="font-size:11px;margin-top: 5px;margin-bottom: 0;" *ngIf=" chkDate(serviceRequestData.value.insurance_expiration_date) || chkDate(serviceRequestData.value.registration_expiration_date)">
                  <span *ngIf="chkDate(serviceRequestData.value.insurance_expiration_date)">Vehicle Insurance Expired -{{serviceRequestData.value.insurance_expiration_date }}<br></span>
                  <span *ngIf="chkDate(serviceRequestData.value.registration_expiration_date)">Vehicle Registration Expired - {{serviceRequestData.value.registration_expiration_date}}<br></span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>License Plate :</label></div>
              <div class="col-sm-6 break-word text-uppercase">
                {{this.serviceRequestData.value.license_plate}}<span *ngIf="this.serviceRequestData.value.license_plate==''">-</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Title :</label></div>
              <div class="col-sm-6">
                {{this.serviceRequestData.value.title}} <span *ngIf="this.serviceRequestData.value.title==''">-</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>VIN Number :</label></div>
              <div class="col-sm-6 break-word text-uppercase" *ngIf="this.serviceRequestData.value.vin">
                  {{this.serviceRequestData.value.vin | slice:0:-8}}<span style="color:#eb1e4c">{{this.serviceRequestData.value.vin.substr(this.serviceRequestData.value.vin.length - 8)}}</span>
              </div>
              <div class="col-sm-6 break-word text-uppercase" *ngIf="this.serviceRequestData.value.vin==undefined || this.serviceRequestData.value.vin==''">-</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Customer Name :</label></div>
              <div class="col-sm-6">
                {{this.serviceRequestData.value.first_name}} {{this.serviceRequestData.value.middle_name}} {{this.serviceRequestData.value.last_name}}
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Requested Date :</label></div>
              <div class="col-sm-6 break-word">
                {{this.serviceRequestData.value.requested_delivery_date}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Mobile Number :</label></div>
              <div class="col-sm-6">
                <a class="text-black" href="tel:{{this.serviceRequestData.value.mobile_phone}}">{{this.serviceRequestData.value.mobile_phone}}</a>
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Scheduled Date :</label></div>
              <div class="col-sm-6">
                {{this.serviceRequestData.value.schedule_service_date}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Present Mileage (Miles) :</label></div>
              <div class="col-sm-6">
                {{this.serviceRequestData.value.present_mileage}}
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Estimated Date :</label></div>
              <div class="col-sm-6">
                {{this.serviceRequestData.value.estimated_delivery_date }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 margin-bottom-10">
            <div class="row">
              <div class="col-sm-3"><label>Notes :</label></div>
              <div class="col-sm-9" [innerHtml]="getNltoBr(serviceRequestData.value.notes)">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 margin-bottom-10">
            <div class="row">
              <div class="col-sm-3"><label>Download Report :</label></div>
              <div class="col-sm-9">
                <span id="downloadReportUPSP" *ngIf="(serviceRequestData.value.notify_to_customer == '1' || serviceRequestData.value.notify_to_admin == '1') && reportDownloadType==1" >
                  <a href="javascript:;" (click)="invokeDownload(reportDownloadLink,'CARInspectionReport','')">(USPS-PMI-PS-Form)<i class="fa fa-download fa-fw "></i></a>
                </span>
                <span id="downloadReportACDELCO" *ngIf="(serviceRequestData.value.notify_to_customer == '1' || serviceRequestData.value.notify_to_admin == '1') && reportDownloadType==2">
                  <a href="javascript:;" (click)="invokeDownload(reportDownloadLink,'CARInspectionReport','')">(CAR Inspection Report)<i class="fa fa-download fa-fw "></i></a>
                </span>
                <span id="noReportPerformed" *ngIf="reportDownloadType==0">
                  No Report Performed
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 notifyToAdminContainer">
            <div class="alert alert-info disabled" *ngIf="serviceRequestData.value.request_status == 3 && hideAllAction==false">
              <!-- {{serviceRequestData.value.request_status}} -->
              Approve the recommendation(s) that need to be done to the service request and reject those which are not needed. Then click the below button to apply the changes and continue further.
              <br>
              <div class="btn btn-success notify-to-admin" (click)="finalUpdateToNotifyAdmin(serviceRequestData.value.request_id)">
                Apply Changes
              </div>
              <br>
              <div class="text-red notify-to-admin info-requested" *ngIf="serviceDetail.info_update_requested == 1">
                Note : {{ updatedInfoRequestedMessage }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" *ngIf="serviceRequestData.value.request_documents!=null || serviceRequestData.value.request_images!=null">
            <h4><strong>Documents &amp; Images</strong></h4>
            <div class="row">
              <div class="col-sm-6" *ngIf="serviceRequestData.value.request_documents!=null">
                <div class="row" *ngIf="serviceRequestData.value.request_documents.length > 0">
                  <div class="col-sm-12 margin-bottom-5"><label>Document(s)</label></div>
                  <div class="col-sm-12 text-danger">
                    <div id="serviceRequestDocumentsView">
                      <a *ngFor="let doc of serviceRequestData.value.request_documents" style="display:inline-block;margin:0px 5px 5px 0px;position:relative;" href="javascript:void(0)" (click)="invokeDownload(globalConstants.S3_BUCKET_URL+'Service-Request-Documents/'+doc.document_name,'ServiceRequestDocument',doc.document_name)" class="thumbnail">
                        <!-- <img style="width:80px;height:75px;" src="assets/img/adobe_pdf-logo.jpg"> -->
                        <span class="fa fa-file-pdf-o fa-4x" *ngIf="getFileIcons(doc.document_name)==3"></span>
                        <span class="fa fa-file-word-o fa-4x" *ngIf="getFileIcons(doc.document_name)==4"></span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="serviceRequestData.value.request_documents.length == 0">
                    <div class="col-sm-12 margin-bottom-5"><label> </label></div>
                    <div class="col-sm-12 text-danger"> No documents uploaded.</div>
                </div>
              </div>
              <div class="col-sm-6" *ngIf="serviceRequestData.value.request_images!=null">
                <div class="row" *ngIf="serviceRequestData.value.request_images.length > 0">
                  <div class="col-sm-12 margin-bottom-5"><label>Image(s)</label></div>
                  <div class="col-sm-12" *ngIf="serviceRequestData.value.request_images!=null">
                      <ul class="list-inline margin-bottom-0" >
                          <li  class="relative view-images" *ngFor="let image of serviceRequestData.value.request_images;let i=index;">
                              <a (click)="imageToolDialog($event,i,8,serviceRequestData.value.request_images)" class="thumbnail" href="javascript:void(0);">
                                  <img style="width:100px;height:75px;" src="{{globalConstants.S3_BUCKET_URL}}Service-Request-Pictures/thumb/{{image.image_name}}">
                              </a>
                          </li>
                      </ul>

                  </div>

                </div>
                <div class="row" *ngIf="serviceRequestData.value.request_images.length == 0">
                    <div class="col-sm-12 margin-bottom-5"><label> </label></div>
                    <div class="col-sm-12 text-danger"> No images uploaded.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="display-full margin-bottom-15" *ngIf="serviceRequestData.value.request_status == '3' && hideAllAction==false">
      <button type="button" mat-raised-button class="mat-success font-bold mat-elevation-z1" (click)="approveAll()">Accept all</button>
      <button type="button" mat-raised-button color="warn" class="font-bold mat-elevation-z1" (click)="disapproveAll()">Reject all</button>
    </div>
    <div class="documents-accordion relative">
        <mat-accordion class="new-request-expension mat-expanded view-request margin-bottom-10 display-full mat-accordion" displayMode="flat" >
            <mat-expansion-panel [expanded]="a==0" hideToggle="true" *ngFor="let service_request of serviceRequestData.value.service_request_list;let a=index" id="expansion-{{a+1}}" >
              <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="expansionHead-{{a+1}}" (click)="expansionOpen(a+1);" [attr.data-state]="a==0">
                {{a+1}}. {{ service_request.name }}
              </mat-expansion-panel-header>
              <ul class="service-list-items">
                <li  *ngFor="let servicerequestChild of service_request.children;let i = index">
                  <p>{{i+1}}) {{servicerequestChild.name}}</p>
                  <ul class="service-list-sub-items no-style" >
                      <!-- *ngIf="serviceRequestData.value.notify_to_customer == '1' || serviceRequestData.value.notify_to_admin == '1' " -->
                    <li >
                      <div class="row">
                        <div *ngFor="let recommendation of servicerequestChild.recommendations;let j = index">
                          <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.parts.length > 0 && (serviceRequestData.value.notify_to_customer == '1' || serviceRequestData.value.notify_to_admin == '1')">
                            <div class="form-group">
                              <label class="col-sm-3 col-lg-2 text-right control-label">Part(s):</label>
                              <div class="col-sm-9 col-lg-10 text-justify">
                                <div class="addedPartsItems">
                                  <div class="partRow row" style="padding-top:3px;" *ngFor="let part of recommendation.parts;let k = index">
                                    <div class="col-sm-8 pname"><span *ngIf="part.oem_part_number!=''">{{part.oem_part_number}} - </span>{{part.name}}<span *ngIf="part.description!=''"> - {{part.description}}</span></div>
                                    <div class="col-sm-2 pprice">$ {{parseFloats(part.part_price)}}</div>
                                    <div class="col-sm-2 pquantity">{{part.quantity}}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.generic_jobs.length > 0 && (serviceRequestData.value.notify_to_customer == '1' || serviceRequestData.value.notify_to_admin == '1')">
                            <div class="form-group">
                              <label class="col-sm-3 col-lg-2 text-right control-label">Generic Job(s):</label>
                              <div class="col-sm-9 col-lg-10 text-justify">
                                <div class="addedGenericJobs">
                                  <div class="partRow row" style="padding-top:3px;" *ngFor="let gjob of recommendation.generic_jobs;let l = index">
                                    <div class="col-sm-8 pname">{{gjob.name}}</div>
                                    <div class="col-sm-2 pprice " >$ {{parseFloats(gjob.price) }}</div>
                                    <div class="col-sm-2 ppqty">{{gjob.quantity}}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.computer_codes.length > 0 && (serviceRequestData.value.notify_to_customer == '1' || serviceRequestData.value.notify_to_admin == '1')">
                              <div class="form-group">
                                <label class="col-sm-3 col-lg-2 text-right control-label">Computer Codes(s):</label>
                                <div class="col-sm-9 col-lg-10 text-justify">
                                  <div class="addedGenericJobs">
                                    <div class="partRow row" style="padding-top:3px;" *ngFor="let cjob of recommendation.computer_codes;let l = index">
                                      <div class="col-sm-12 pname">{{cjob.trouble_code}} {{cjob.fault_location}}</div>
                                     </div>
                                  </div>
                                </div>
                              </div>
                            </div>
      
                          <div class="col-sm-12 margin-bottom-15" *ngIf="(serviceRequestData.value.notify_to_customer == '1' || serviceRequestData.value.notify_to_admin == '1')  &&  recommendation.nexpart_labor_time >0">
                            <div class="form-group">
                              <label class="col-sm-3 col-lg-2 text-right control-label">Nexpart labor time:</label>
                              <div class="col-sm-9 col-lg-10 text-justify">
                                <div class="addedComputerCodes">{{parseFloats(recommendation.nexpart_labor_time)}} hour(s)</div>
                              </div>
                            </div>
                          </div>
      
                          <div class="col-sm-12 margin-bottom-15" *ngIf="(serviceRequestData.value.notify_to_customer == '1' || serviceRequestData.value.notify_to_admin == '1')">
                            <div class="form-group">
                              <label class="col-sm-3 col-lg-2 text-right control-label">Estimated Labor:</label>
                              <div class="col-sm-9 col-lg-10 text-justify">
                                <div class="addedComputerCodes">$ {{parseFloats(recommendation.estimated_labor)}}</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 margin-bottom-15" *ngIf="(serviceRequestData.value.notify_to_customer == '1' || serviceRequestData.value.notify_to_admin == '1')">
                            <div class="form-group">
                              <label class="col-sm-3 col-lg-2 text-right control-label">Estimated Time:</label>
                              <div class="col-sm-9 col-lg-10 text-justify">
                                <div class="addedComputerCodes">{{recommendation.estimated_time}} hour(s) </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.recommendation!='' && (serviceRequestData.value.notify_to_customer == '1' || serviceRequestData.value.notify_to_admin == '1')">
                            <div class="form-group">
                              <label class="col-sm-3 col-lg-2 text-right control-label">Description of Work:</label>
                              <div class="col-sm-9 col-lg-10 text-justify break-all">
                                <p [innerHtml]="getNltoBr(recommendation.recommendation)"></p>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.notes!='' && (serviceRequestData.value.notify_to_customer == '1' || serviceRequestData.value.notify_to_admin == '1')">
                            <div class="form-group">
                              <label class="col-sm-3 col-lg-2 text-right control-label">Notes:</label>
                              <div class="col-sm-9 col-lg-10 text-justify break-all">
                                <p [innerHtml]="getNltoBr(recommendation.notes)"></p>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 margin-bottom-15 already-uploaded-images" *ngIf="recommendation.uploaded_images.length > 0">
                            <div class="form-group">
                              <label class="col-sm-3 col-lg-2 text-right control-label"><b>Uploaded Image(s):</b></label>
                              <div class="col-sm-9 col-lg-10 text-justify" >
                                <ul class="list-inline margin-bottom-0" *ngIf="recommendation.uploaded_images.length > 0">
                                    <li  class="relative view-images" *ngFor="let image of recommendation.uploaded_images;let i=index;">
                                        <a (click)="imageToolDialog($event,i,4,recommendation.uploaded_images)" class="thumbnail" href="javascript:void(0);">
                                          <img class="show-image" src="{{globalConstants.S3_BUCKET_URL}}recommendation-pictures/thumb/{{image.filename}}">
                                        </a>
                                    </li>
                                </ul>
                              </div>
                              <div class="col-sm-9 col-lg-10 text-justify" *ngIf="recommendation.uploaded_images.length == 0">
                                <span class="text-danger">No images uploaded. </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 margin-bottom-15 already-uploaded-videos" *ngIf="recommendation.uploaded_videos.length > 0">
                            <div class="form-group">
                              <label class="col-sm-3 col-lg-2 text-right control-label"><b>Uploaded Video(s):</b></label>
                              <div class="col-sm-9 col-lg-10 text-justify" *ngIf="recommendation.uploaded_videos.length > 0">
                                <ul id="vehicle_video-ul" class="list-inline margin-bottom-0">
                                  <li class="video-upload relative" *ngFor="let video of recommendation.uploaded_videos;let i=index">
                                    <a class="thumbnail margin-bottom-0" href="javascript:void(0)" (click)="videoShow(video.filename)">
                                      <span class="relative video-bg">
                                        <mat-icon class="position-center play-video">play_circle_filled</mat-icon>
                                        <div class="image-loader" *ngIf="video.loader">
                                          <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                                        </div>
                                      </span>
                                    </a>
                                    <!-- <i class="fa fa-trash remove-img" (click)="imgDelete(video.id,'vehicle_video',i)"></i> -->
                                  </li>
                                </ul>
                              </div>
                              <div class="col-sm-9 col-lg-10 text-justify" *ngIf="recommendation.uploaded_videos.length == 0">
                                <span class="text-danger">No videos uploaded.
                                  <span></span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-xs-12" *ngIf="recommendation.service_request_list_id!=700">
                            <div class="display-full text-right" *ngIf="serviceRequestData.value.request_status=='3' && hideAllAction==false">
                              <mat-button-toggle value="accept" (change)="updateText($event, recommendation.id, recommendation,service_request,1)" class="toogle-btn btn-success" [checked]="recommendation.approval_status == '0' || recommendation.approval_status == '2'">
                                {{recommendation.approval_status == 0 ? 'Accept' : recommendation.approval_status == '1' ? 'Undo Accept' : recommendation.approval_status == '2' ? 'Accept': statusAcceptText}}
                              </mat-button-toggle>
                              <mat-button-toggle value="reject" (change)="updateText($event, recommendation.id, recommendation,service_request,2)" class="toogle-btn btn-danger" [checked]="recommendation.approval_status == '0' || recommendation.approval_status == '1'">
                                {{recommendation.approval_status == 0 ? 'Reject' : recommendation.approval_status == '1' ? 'Reject' : recommendation.approval_status == '2' ? 'Undo Reject': statusRejectText}}
                              </mat-button-toggle>
                            </div>
      
                            <div class="display-full text-right"  *ngIf="serviceRequestData.value.request_status != '3' && hideAllAction==false">
                              <mat-button-toggle value="Accepted" class="toogle-btn btn-success" *ngIf="recommendation.approval_status=='1'">Accepted</mat-button-toggle>
                              <mat-button-toggle value="Rejected" class="toogle-btn btn-danger" *ngIf="recommendation.approval_status=='2'">Rejected</mat-button-toggle>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
      
                    <li  *ngFor="let secondChild of servicerequestChild.children;let j = index">
      
                      <p>{{secondChild.name}}</p>
      
                      <p class="service-list-sub-items" *ngIf="secondChild.input == 'textarea'">
                        <span style="padding-left: 58px;" class="display-full" [innerHtml]="getNltoBr(secondChild.value)">
                        </span>
                      </p>
                      <p class="service-list-sub-items" *ngIf="secondChild.input == 'imageupload'">
                        <span style="padding-left: 58px;">
                          <img src="{{globalConstants.S3_BUCKET_URL}}service-pictures/thumb/{{secondChild.value}} "/>
                        </span>
                      </p>
                      <!-- *ngIf="serviceRequestData.value.notify_to_customer == '1' || serviceRequestData.value.notify_to_admin == '1'" -->
                      <div class="row" >
                          <div *ngFor="let recommendation of secondChild.recommendations;let j = index">
                          <div *ngIf="recommendation.service_request_list_id!='158'">
      
                            <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.parts.length > 0  && (serviceRequestData.value.notify_to_customer == '1' || serviceRequestData.value.notify_to_admin == '1')">
                              <div class="form-group">
                                <label class="col-sm-3 col-lg-2 text-right control-label">Part(s):</label>
                                <div class="col-sm-9 col-lg-10 text-justify">
                                  <div class="addedPartsItems">
                                    <div class="partRow row" style="padding-top:3px;" *ngFor="let part of recommendation.parts;let k = index">
                                      <div class="col-sm-8 pname"><span *ngIf="part.oem_part_number!=''">{{part.oem_part_number}} - </span>{{part.name}}<span *ngIf="part.description!=''"> - {{part.description}}</span></div>
                                      <div class="col-sm-2 pprice " data-price="120.00" data-qty="1" data-approval-status="1">$ {{parseFloats(part.part_price)}}</div>
                                      <div class="col-sm-2 pquantity">{{part.quantity}}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
      
                            <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.generic_jobs.length > 0 && (serviceRequestData.value.notify_to_customer == '1' || serviceRequestData.value.notify_to_admin == '1')">
                              <div class="form-group">
                                <label class="col-sm-3 col-lg-2 text-right control-label">Generic Job(s):</label>
                                <div class="col-sm-9 col-lg-10 text-justify">
                                  <div class="addedGenericJobs">
                                    <div class="partRow row" style="padding-top:3px;" *ngFor="let gjob of recommendation.generic_jobs;let l = index">
                                      <div class="col-sm-8 pname">{{gjob.name}}</div>
                                      <div class="col-sm-2 pprice " >$ {{parseFloats(gjob.price)}}</div>
                                      <div class="col-sm-2 ppqty">{{gjob.quantity}}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
      
                            <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.computer_codes.length > 0 && (serviceRequestData.value.notify_to_customer == '1' || serviceRequestData.value.notify_to_admin == '1')">
                                <div class="form-group">
                                  <label class="col-sm-3 col-lg-2 text-right control-label">Computer Codes(s):</label>
                                  <div class="col-sm-9 col-lg-10 text-justify">
                                    <div class="addedGenericJobs">
                                      <div class="partRow row" style="padding-top:3px;" *ngFor="let cjob of recommendation.computer_codes;let l = index">
                                        <div class="col-sm-12 pname">{{cjob.trouble_code}} {{cjob.fault_location}}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
      
      
                          <div class="col-sm-12 margin-bottom-15" *ngIf="(serviceRequestData.value.notify_to_customer == '1' || serviceRequestData.value.notify_to_admin == '1')  &&  recommendation.nexpart_labor_time >0">
                            <div class="form-group">
                              <label class="col-sm-3 col-lg-2 text-right control-label">Nexpart labor time:</label>
                              <div class="col-sm-9 col-lg-10 text-justify">
                                <div class="addedComputerCodes">{{parseFloats(recommendation.nexpart_labor_time)}} hour(s)</div>
                              </div>
                            </div>
                          </div>
      
                            <div class="col-sm-12 margin-bottom-15" *ngIf="(serviceRequestData.value.notify_to_customer == '1' || serviceRequestData.value.notify_to_admin == '1')">
                              <div class="form-group">
                                <label class="col-sm-3 col-lg-2 text-right control-label">Estimated Labor:</label>
                                <div class="col-sm-9 col-lg-10 text-justify">
                                  <div class="addedComputerCodes">$ {{parseFloats(recommendation.estimated_labor)}}</div>
                                </div>
                              </div>
                            </div>
      
                            <div class="col-sm-12 margin-bottom-15" *ngIf="(serviceRequestData.value.notify_to_customer == '1' || serviceRequestData.value.notify_to_admin == '1')">
                              <div class="form-group">
                                <label class="col-sm-3 col-lg-2 text-right control-label">Estimated Time:</label>
                                <div class="col-sm-9 col-lg-10 text-justify">
                                  <div class="addedComputerCodes">{{recommendation.estimated_time}} hour(s) </div>
                                </div>
                              </div>
                            </div>
      
                            <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.recommendation!='' && (serviceRequestData.value.notify_to_customer == '1' || serviceRequestData.value.notify_to_admin == '1')">
                              <div class="form-group">
                                <label class="col-sm-3 col-lg-2 text-right control-label">Description of Work:</label>
                                <div class="col-sm-9 col-lg-10 text-justify break-all">
                                  <p [innerHtml]="getNltoBr(recommendation.recommendation)"></p>
                                </div>
                              </div>
                            </div>
      
                            <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.notes!='' && (serviceRequestData.value.notify_to_customer == '1' || serviceRequestData.value.notify_to_admin == '1')">
                              <div class="form-group">
                                <label class="col-sm-3 col-lg-2 text-right control-label">Notes:</label>
                                <div class="col-sm-9 col-lg-10 text-justify break-all">
                                  <p [innerHtml]="getNltoBr(recommendation.notes)"></p>
                                </div>
                              </div>
                            </div>
      
                            <div class="col-sm-12 margin-bottom-15 already-uploaded-images" *ngIf="recommendation.uploaded_images.length > 0">
                              <div class="form-group">
                                <label class="col-sm-3 col-lg-2 text-right control-label"><b>Uploaded Image(s):</b></label>
      
                                <div class="col-sm-9 col-lg-10 text-justify" >
      
                                  <ul class="list-inline margin-bottom-0" *ngIf="recommendation.uploaded_images.length > 0">
                                      <li  class="relative view-images" *ngFor="let image of recommendation.uploaded_images;let i=index;">
                                          <a (click)="imageToolDialog($event,i,4,recommendation.uploaded_images)" class="thumbnail" href="javascript:void(0);">
                                            <img class="show-image" src="{{globalConstants.S3_BUCKET_URL}}recommendation-pictures/thumb/{{image.filename}}">
                                          </a>
                                      </li>
                                  </ul>
                                </div>
      
                                <div class="col-sm-9 col-lg-10 text-justify" *ngIf="recommendation.uploaded_images.length == 0">
                                  <span class="text-danger">No images uploaded.
      
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 margin-bottom-15 already-uploaded-videos" *ngIf="recommendation.uploaded_videos.length > 0">
                              <div class="form-group">
                                <label class="col-sm-3 col-lg-2 text-right control-label"><b>Uploaded Video(s):</b></label>
                                <div class="col-sm-9 col-lg-10 text-justify" *ngIf="recommendation.uploaded_videos.length > 0">
                                  <ul id="vehicle_video-ul" class="list-inline margin-bottom-0">
                                    <li class="video-upload relative" *ngFor="let video of recommendation.uploaded_videos;let i=index">
                                      <a class="thumbnail margin-bottom-0" href="javascript:void(0)" (click)="videoShow(video.filename)">
                                        <span class="relative video-bg">
                                          <mat-icon class="position-center play-video">play_circle_filled</mat-icon>
                                          <div class="image-loader" *ngIf="video.loader">
                                            <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                                          </div>
                                        </span>
                                      </a>
      
                                    </li>
                                  </ul>
                                </div>
      
                                <div class="col-sm-9 col-lg-10 text-justify" *ngIf="recommendation.uploaded_videos.length == 0">
                                  <span class="text-danger">No videos uploaded.
                                    <span></span>
                                  </span>
                                </div>
                              </div>
                            </div>
      
                            <div class="col-xs-12">
                              <div class="display-full text-right" *ngIf="serviceRequestData.value.request_status=='3' && hideAllAction==false">
                                <mat-button-toggle value="accept" (change)="updateText($event, recommendation.id, recommendation,service_request,1)" class="toogle-btn btn-success" [checked]="recommendation.approval_status == 0 || recommendation.approval_status == 2">
                                  {{recommendation.approval_status == 0 ? 'Accept' : recommendation.approval_status == 1 ? 'Undo Accept' : recommendation.approval_status == 2 ? 'Accept': statusAcceptText}}
                                </mat-button-toggle>
                                <mat-button-toggle value="reject" (change)="updateText($event, recommendation.id, recommendation,service_request,2)" class="toogle-btn btn-danger" [checked]="recommendation.approval_status == 0 || recommendation.approval_status == 1">
                                  {{recommendation.approval_status == 0 ? 'Reject' : recommendation.approval_status == 1 ? 'Reject' : recommendation.approval_status == 2 ? 'Undo Reject': statusRejectText}}
                                </mat-button-toggle>
                            </div>
      
                             <div class="display-full text-right" *ngIf="serviceRequestData.value.request_status != '3' && hideAllAction==false">
                              <mat-button-toggle value="Accepted" class="toogle-btn btn-success" *ngIf="recommendation.approval_status==1">Accepted</mat-button-toggle>
                              <mat-button-toggle value="Rejected" class="toogle-btn btn-danger" *ngIf="recommendation.approval_status==2" >Rejected</mat-button-toggle>
                             </div>
      
                            </div>
                          </div>
                      </div>
                    </div>
      
                  </li>
                </ul>
              </li>
            </ul>
            <div class="panel-price" *ngIf="ConvertToNumber(serviceRequestData.value.request_status) >=3 && ConvertToNumber(serviceRequestData.value.request_status) < 9">
              <div class="panel-footer rejectedJobs col-sm-6" role="tab">
                <h4 class="text-right" style="margin:0;"><span>Rejected Job(s) Cost&nbsp;:&nbsp;$ </span>
                  <span class="total-job-price-63">
                    {{ this.ObjectJson[service_request.id]["RejectedValue"] }}
                  </span>
                </h4>
              </div>
              <div class="panel-footer acceptedJobs col-sm-6" role="tab">
                <h4 class="text-right" style="margin:0;"><span>Total Price&nbsp;:&nbsp;$ </span>
                  <span class="total-job-price-63">
                    {{ this.ObjectJson[service_request.id]["AcceptedValue"] }}
                  </span>
                </h4>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
    </div>
  <!-- <div class="disclaimer-and-totals-container notranslate">
    <h4 class="text-right" *ngIf="serviceRequestData.value.total_amount"><strong>Sub Total : </strong>$<span class="grand-total"> {{parseFloats(serviceRequestData.value.total_amount)}}</span></h4>
    
    <label class="checkbox text-right" style="font-size:18px;" id="supplyChargesRow" *ngIf="serviceRequestData.value.is_supplies_charges_included==1">
      Supply Charges : <span class="supply-charges-value">$ {{parseFloats(serviceRequestData.value.supplies_charges)}}</span>
    </label>
    
    <app-redeem-coupon #viewRequestCoupon *ngIf="serviceDetail" [is-view-mode]="serviceDetail.request_status == 8" [context]="this"></app-redeem-coupon>
    
    <label class="checkbox text-right" [class.hide]="!(isAllowed('views','add_tax_invoice') && showCostPrice)" style="font-size:18px;" id="taxRow" onclick="return false;" *ngIf="serviceInfo.value.is_tax_included  == '1'">
      Tax (<span class="tax-rate-value">{{serviceInfo.value.tax_rate}}% : $ {{totalTax}}</span>)
    </label>
    <h4 class="text-right" *ngIf="showCostPrice"><strong>Grand Total : </strong>$ <span class="grand-total-with-tax">{{ finalShowAbleAmount }}</span></h4>
    <h4 class="text-right" *ngIf="showCostPrice"><strong>Total Paid : </strong>$ <span id="totalPaid">{{serviceInfo.value.total_paid == null ? "0.00" : serviceInfo.value.total_paid}}</span></h4>
    <h4 class="text-right" *ngIf="showCostPrice"><strong>Balance : </strong>$<span id="balance"> {{ balance }}</span></h4>
    <div>


    </div>
  </div> -->
  <div class="final-calculation">
    <!-- Sub Total Calculation -->
    <p *ngIf="serviceRequestData.value.notify_to_customer == '1' || serviceRequestData.value.notify_to_admin == '1'"><b>Sub Total</b> : $ {{parseFloats(serviceRequestData.value.total_amount)}}</p>
    
    <p *ngIf="serviceRequestData.value.is_supplies_charges_included==1"><b>Supply Charges</b> : $ {{parseFloats(serviceRequestData.value.supplies_charges)}}</p>
    
    <!-- Updated UI for discount module -->
    <!-- <app-redeem-coupon #viewRequestCoupon *ngIf="serviceDetail" [for]="'Customer'" [context]="this" [is-view-mode]="true"></app-redeem-coupon> -->
    <!-- <app-redeem-coupon #viewRequestCoupon *ngIf="serviceDetail"  [context]="this" [is-view-mode]="true"></app-redeem-coupon> -->
    
    <!-- List of coupons applied -->
    
    <!-- Tax Calculation -->
    <p *ngIf="(serviceRequestData.value.notify_to_customer == '1' || serviceRequestData.value.notify_to_admin == '1') && (serviceRequestData.value.is_tax_included == 1)"><b>Tax</b>({{parseFloats(serviceRequestData.value.tax_rate)}}% : $ {{parseFloats(serviceRequestData.value.tax_amount)}})</p>
    
    <p *ngIf="serviceRequestData.value.notify_to_customer == '1' || serviceRequestData.value.notify_to_admin == '1' "><b>Grand Total</b> : $ {{ finalShowAbleAmount }}</p>
    
    <ng-container *ngIf="serviceRequestData && serviceRequestData.value.total_paid != null && serviceRequestData.value.total_paid > 0">
      <hr/>
      <p ><strong>Total SR Paid : </strong>$ <span id="totalPaid">{{parseFloats(serviceRequestData.value.total_paid)}}</span></p>
      <p ><strong>Total CC Fee Paid : </strong>$ <span id="totalPaid">{{parseFloats(serviceRequestData.value.total_cc_fee)}}</span></p>
      <p class="text-right"><strong>Total Net Paid : </strong>$ <span id="totalPaid">{{parseFloats(totalNetPayed(serviceRequestData.value.total_paid, serviceRequestData.value.total_cc_fee))}}</span></p>
      <!-- Balance Calculation -->
      <p *ngIf="balance != null"><b>Balance</b> : $ {{parseFloats(balance)}}</p>
    </ng-container>
    
    <ng-container *ngIf="netPayable > 0 && balance > 0">
      <hr/>
      <p class="text-right" *ngIf="totalCCfeeValue > 0"><strong>CC Fee <span *ngIf="serviceRequestData && serviceRequestData.value.cc_fee_type == '2'">({{serviceRequestData.value.cc_fee_percentage}}%)</span></strong><span class="grand-total-with-tax">: $ {{parseFloats(totalCCfeeValue)}}</span></p>
      <p class="text-right" *ngIf="netPayable > 0"><strong>Total Payable : </strong>$ <span class="grand-total-with-tax">{{ parseFloats(netPayable) }}</span></p>
    </ng-container>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="notifyToAdminContainer" *ngIf="serviceRequestData.value.request_status == 3  && hideAllAction==false">
        <div class="btn-lg alert alert-info disabled margin-bottom-10">Approve the recommendation(s) that need to be done to the service request and reject those which are not needed. Then click the below button to apply the changes and continue further.<br><div class="btn btn-success notify-to-admin" (click)="finalUpdateToNotifyAdmin(serviceRequestData.value.request_id)">Apply Changes</div>
          <br>
          <div class="text-red notify-to-admin info-requested" *ngIf="serviceDetail.info_update_requested == 1">
            Note : {{ updatedInfoRequestedMessage }}
          </div>
        </div>
      </div>

      <div style="position:relative;" *ngIf="serviceRequestData.value.request_status == 3  && hideAllAction==false">
        <p class="alert alert-info" style="padding:17px 15px;border-radius:5px;" id="disclaimer">{{serviceRequestData.value.disclaimer}}</p>
      </div>
    </div>
  </div>
</div>
</div>
