import { Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import { ViktableComponent, VikSource } from '../../../admin/viktable/viktable.component';
import { Router } from '@angular/router';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { QqviewComponent } from '../qqview/qqview.component';
import { QuickquoteComponent } from '../quickquote.component';
import { QqtoServicerequestComponent } from '../qqto-servicerequest/qqto-servicerequest.component';
import { QqpaymentComponent } from '../qqpayment/qqpayment.component';
import  saveAs  from 'file-saver';
import {globalFunctions} from "../../../../environments/globalFunctions";
import {Http, Headers, Response, ResponseContentType} from '@angular/http';
import {Subscription} from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
const QUICK_QUOTE_LIST = "shop-quickquote-";


@Component({
  selector: 'app-qqlist',
  templateUrl: './qqlist.component.html',
  styleUrls: ['./qqlist.component.scss']
})
export class QqlistComponent extends ViktableComponent implements OnInit,OnDestroy {

  public user:any=null
  public tablesData = this;
  public checkFormEmpty=false;
  public searchEnabled : boolean = false;
  public displayedColumns=['id','name','vehicle','jobs','total','created_by','added_at','Actions']
  public searchByStatus = [];
  public searchByArray = []
  public options:any = {
    user_id: "",
    user_type: "User",
    status: '0',
    convertedToSR:false,
    search_by: "",
    keyword: "",
    start: 0,
    limit: 10
  }
  public showCostPrice=false;
  public editable=true
  public refreshList:any=null
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public subscriptionDataForAPI: Subscription = new Subscription();
  dataSource: MatTableDataSource<UserData> = new MatTableDataSource();
  public record :any =[];
  public showBuzColumn = false;
  constructor(public gs: GlobalService, public router: Router,public dialog: MatDialog,public http: Http) {
    super(globalConstants.API_QQ_LIST, gs);
    this.user=this.gs.getCurrentUser()
    if (this.user) {
      this.sessionKey = QUICK_QUOTE_LIST;
      this.url = globalConstants.API_QQ_LIST;
    } else {
      this.router.navigate(["/shops"]);
    }

    this.searchByStatus = [
      { id: "", text: "Select Status" },
      { id: "0", text: "Draft" },
      { id: "1", text: "Converted to Service Request" }
    ];
    this.searchByArray = [
      { id: "1", text: "Customer Email" },
      { id: "2", text: "Customer Name" },
      { id: "3", text: "Licence Plate" },
      { id: "4", text: "Quick Quote ID" },
      { id: "5", text: "Vehicle" },
      { id: "6", text: "Vin" }
    ];
    if(!this.gs.isFleet()){
      this.showBuzColumn = true;
      this.displayedColumns=['id','name','vehicle','jobs','total','created_by','added_at','Actions']
    }
  }

  ngOnInit() {
    setTimeout(() => {
      let shopPer = this.gs.isSubscribe('quick_quote')
      let userPer = this.gs.getAcl('quick_quote', 'views');
      this.editable = this.gs.getAcl('quick_quote', 'edits');
      this.showCostPrice=this.gs.getAcl('display_part_cost','views');
      if ((!userPer)) {
        this.router.navigate(['shops/action/forbidden']);
      }
    }, globalConstants.RELOAD_WAIT);
    this.gs.setMetaData("SHOPS", "QUICK_QUOTE_LIST");
    this.reInitOptions()
    this.refreshList=this.gs.UpdateQQ.subscribe((r)=>{
      if(r){
        this.setDataTable();
      }
    })
    this.setDataTable();
   }
   reInitOptions(){
    let sr:any=this.gs.getSetItems('convertedToSR',null,QUICK_QUOTE_LIST)
    if(sr==='2'){
      this.options.convertedToSR=true
      this.gs.getSetItems('convertedToSR','1',QUICK_QUOTE_LIST)
    }
    this.options.user_type = this.user.user_type;
    this.options.user_id = this.user.id;
    this.options.status = '0';
   }

    /** define the paginator in after init the page  */
   ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
   setDataTable() {
    this.record = [];
     if(this.options.convertedToSR){
      this.options.status = '1';
     }else{
      this.options.status = '0';
     }
    if(this.isGetData==true){
      // console.log("this is tables data",this.tablesData)
      // this.dataSource = new VikSource(this.tablesData, this.sort, this.paginator);
      // this.dataSource.getSortedData = this.sortedData;
      var content = this.options;
      this.detailData={};
      this.resultData=[];
      
      this.isGetData=false
      var callApi = this.gs.callAPI(this.url, content);
      callApi.subscribe((data:any) => {
       
        this.isGetData=true
        if(this.returnData && data[this.returnData]){
          this.detailData=data[this.returnData];
        }
        if (data.data != undefined) {
          let moreRows = 'false';
          let firstList = 'false';
          this.gs.localGetterSetter("totalRecords", data.recordsTotal, this.sessionKey);
          if (data.more_rows == "true") {
            moreRows = "true";
          }
          this.gs.localGetterSetter("more_rows", moreRows, this.sessionKey);
          if (data.start >= 0 && data.start <= 5) {
            firstList = "true";
          }
          this.gs.localGetterSetter("first_list", firstList, this.sessionKey);
          if (data.data.length != undefined) { 
            this.recordsTotal = data.data.length;
            for (var i = 0; i < data.data.length; i++) {
              this.AddRecord(data.data[i])
            }
            this.dataSource = new MatTableDataSource(this.record)
            this.dataSource.sort = this.sort;
          } else {
            this.recordsTotal = "1";
            this.AddRecord(data.data)
            this.dataSource = new MatTableDataSource(this.record)
            this.dataSource.sort = this.sort;
          }
          this.gs.localGetterSetter(null, this.options, this.sessionKey);
        } else {
          this.dataSource = new MatTableDataSource(this.record);
          this.gs.localGetterSetter("totalRecords", '0', this.sessionKey);
        }
      },(error)=>{
        this.dataSource = new MatTableDataSource(this.record);
        this.gs.localGetterSetter("totalRecords", '0', this.sessionKey);
      })
    }
  }

/** its user add the record via using create r */
AddRecord(record){
  var data = this.createRecord(record);
    this.record.push(data);
}
  createRecord(record) {
    return {
      id: record.id,
      name: this.getNameORB(record),
      vehicle: this.gs.getGlobalVehicleName(record)||"-",
      added_at : this.convertDate(record.add_date),
      modified_at : this.convertDate(record.modify_date),
      car_id:record.car_id,
      customer_id:record.customer_id,
      status:record.status,
      jobs:this.getLevelName(record.service_jobs),
      total:record.grand_total,
      created_by:record.user_name
    };
  }
  formatVehicle(record){
    let name='';
    if(record.make){
      name=record.make
    }
    if(record.year){
      name=name+' '+record.year
    }
    if(record.model){
      name=name+' '+record.model
    }
    return (name ? name : 'N/A')
  }

  public convertDate(date){
    return this.gs.getFullFormateDate(date,'G','H');
  }
  
  /**redirect to edit quote */
  edit(row){
    let dialogRef = this.dialog.open(QuickquoteComponent, {
      disableClose: true,
       panelClass: ['quickqouotedialog'],
       width: "100%",
       data: row
    });
  }
  view(row){
    row.qq=true
    let dialogRef: any = this.dialog.open(QqviewComponent, {
      panelClass: ['quickqouotedialog', 'quickqouotview'],
      width: "100%",
      data: row
    });
    dialogRef.afterClosed().subscribe(r => {
      if(r==2){
        this.edit(row)
      }
    })
  }
  convertosr(row){
    let data={"customer_validation":1,"user_id":this.user.id,"vehicle_id":row.car_id,"qq_id":row.id,"customer_id":row.customer_id}
    this.subscriptionDataForAPI = this.gs.callAPI(globalConstants.API_QQ_TO_SERVICE_REQUEST, data).subscribe((r:any) => {
      if (r.result == 1) {
        row.vehicleData=r.vehicle
        this.fillMandetoryField(row)
      } else if (r.code == "524") {
        row.fillcust_data=true
        row.vehicleData=r.vehicle
        this.fillMandetoryField(row)
      }
    }, (error) => { })
    
  }
  fillMandetoryField(row){
    let dialogRef: any = this.dialog.open(QqtoServicerequestComponent, {
      panelClass: ['quickqouotedialog'],
      width: "100%",
      data: row
    });
    dialogRef.afterClosed().subscribe((r)=>{
      if(r==1){
        if(typeof dialogRef.request_id!='undefined'){
          this.router.navigate(["/shops/view-service-request/"+dialogRef.request_id]);
        }else{
          this.setDataTable()
        }
      }
    })
  }
  firstList() {
    return this.gs.firstList(this.sessionKey, this.options);
  }
  lastList() {
    return this.gs.lastList(this.sessionKey);
  }
  getLevelName(jobs){
    let name=''
    if(typeof jobs !='undefined' && jobs.length){
      jobs.forEach((e) => {
        if(name){
          name=name+', '+e.name
        }else{
          name=e.name
        }
      });
    }
    return name
  }
  isAnyOptionActive(button?: any) {
    if (this.options.search_by == "" && this.options.convertedToSR==false && this.options.keyword == "") {
      return false;
    }else {
      return true;
    }
  }
  search() {
    this.options.start = 0;
    this.setDataTable();
  }
  clearSearch() {
    this.options.user_type = this.user.user_type;
    this.options.user_id = this.user.id;
    this.options.status = '';
    this.options.convertedToSR=false
    this.options.keyword = '';
    this.options.search_by = '';
    this.options.start = 0;
    this.setDataTable();
  }
  statusChange($e){
    // console.log($e)
  }
  openPayment(row){
    let dialogRef: any = this.dialog.open(QqpaymentComponent, {
      panelClass: ['payment-dialog'],
      width: "100%",
      data: row
    });
    
  }
  isAllowed(module,action) {
    return this.gs.getAcl(module,action);
  }
  ngOnDestroy(){
    this.refreshList && this.refreshList.unsubscribe();
    this.subscriptionDataForAPI.unsubscribe();
  }
  generateQuote(quote){
    var conf = "Are you sure you want to print this quote ?";
    this.gs.confirmDialog('GenerateQuote', conf).subscribe((r) => {
        let res:any=r;
    if(res=='GenerateQuote'){
        var url=globalConstants.API_QQ_PRINT_ESTIMATE_URL;
        let userId=this.gs.getCurrentUser()["id"];
        let params = new URLSearchParams();
        params.set('quote_id', quote.id);
        params.set('user_type', "User");
        params.set('user_id', userId);       
        url += '?'+params.toString();
        let FileName = 'Quote-'+quote.id+'.pdf';
        this.gs.downloadAsset(url,FileName);          
      } else {
        return false;
      }
    });
  }
  getNameORB(r){
    let n=(r.first_name+' '+r.last_name)
    return (r.business_name?r.business_name:n)
  }
  exportQuickQuotesCSV(){
    let data = this.options;
    let url=globalConstants.API_EXPORT_QUICK_QUOTE_LIST;
    let content=globalFunctions.stringifyFormData(data, null);
    this.gs.downloadFileWithPost(url,content,"quick_quotes_export_");
  }
}

export interface UserData {
  total: any;
  jobs: any;
  manufacturer:any;
  created_by: any;
  name:any;
  vehicle: any;
  added_at: any;
}