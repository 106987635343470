import {Component, OnInit, OnDestroy } from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd, Params} from '@angular/router';
import {Http, Headers, Response} from '@angular/http';
import {globalConstants} from "../../../../environments/globalConstants"
import {GlobalService} from "../../../../environments/global.service"
import {lang} from "../../../../environments/lang-en"
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {AppComponent} from "../../../app.component";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {UsercontactsComponent} from '../../../usercontacts/usercontacts.component';

@Component({
  selector: 'app-customerapprovaldetails',
  templateUrl: './customerapprovaldetails.component.html',
  styleUrls: ['./customerapprovaldetails.component.scss']
})
export class CustomerapprovaldetailsComponent implements OnInit, OnDestroy {
    public currentCustomer;
    public idFromUrl;
    public getCurrentCustomerDetails;
    public customerName;
    public customerAddress;
    public customerEmail;
    public customerCity;
    public customerState;
    public customerCountry;
    public customerZip;
    public homePhone;
    public businessPhone;
    public mobilePhone;
    public customerType;
    public taxExempted;
    public businessName;
    public emailActivationStatus;
    public modulesName='customer';
    public modulePermission:any=0;
    public editAllowed=false;
    public subscriptionDataForAPI: Subscription = new Subscription(); 

    constructor(public app:AppComponent,private activatedRoute:ActivatedRoute,private formBuilder: FormBuilder,private route: ActivatedRoute,public titleService:Title,private http: Http,private router: Router, public globalService: GlobalService, public dialog: MatDialog) {
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
        this.setParams();
        setTimeout(()=>{
            this.modulePermission=this.globalService.isAllowed(this.app,this.modulesName,'views');
            this.editAllowed=this.globalService.isAllowed(this.app,this.modulesName,'edits');
            if(! this.modulePermission){
                this.router.navigate(['shops/action/forbidden']);
            }
        },globalConstants.RELOAD_WAIT)
        var userInfo = "user_id=" + this.currentCustomer.id + "&user_type=" + this.currentCustomer.user_type + "&customer_id=" + this.idFromUrl;

       this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_DETAILS, userInfo).subscribe((data:any) => {
            if(data.result == "1"){
                this.getCurrentCustomerDetails = data.data
                this.customerName = this.getCurrentCustomerDetails.first_name+(this.getCurrentCustomerDetails.middle_name!="" ? ' '+this.getCurrentCustomerDetails.middle_name : '')+' '+this.getCurrentCustomerDetails.last_name;
                this.customerEmail = this.getCurrentCustomerDetails.email;
                this.customerAddress = this.getCurrentCustomerDetails.address1+(this.getCurrentCustomerDetails.address2!="" ? ', '+this.getCurrentCustomerDetails.address2 : '');
                this.customerCity = this.getCurrentCustomerDetails.city != '' ? this.getCurrentCustomerDetails.city : '-';
                this.customerState = this.getCurrentCustomerDetails.state !='' ? this.getCurrentCustomerDetails.state : '-';
                this.customerCountry = this.getCurrentCustomerDetails.country !='' ? this.getCurrentCustomerDetails.country : '-';
                this.customerZip = this.getCurrentCustomerDetails.zip !='' ? this.getCurrentCustomerDetails.zip : '-';
                this.homePhone = this.getCurrentCustomerDetails.home_phone !='' ? this.getCurrentCustomerDetails.home_phone : '-';
                this.businessPhone = this.getCurrentCustomerDetails.business_phone !='' ? this.getCurrentCustomerDetails.business_phone : '-';
                this.mobilePhone = this.getCurrentCustomerDetails.mobile_phone !='' ? this.getCurrentCustomerDetails.mobile_phone : '-';;
                this.customerType = this.getCurrentCustomerDetails.customer_type_name !='' ? this.getCurrentCustomerDetails.customer_type_name :'-';
                this.taxExempted = this.getCurrentCustomerDetails.tax_exempted == '0' ? 'No' : 'Yes';
                this.businessName = this.getCurrentCustomerDetails.business_name !='' ? this.getCurrentCustomerDetails.business_name : '-';
                this.emailActivationStatus = this.getCurrentCustomerDetails.email_activation_status;
            } else {
                this.globalService.snackbar('error', data.message);
            }
        })
    }

    ngOnInit() {
        this.globalService.setMetaData("SHOPS","VIEW_CUSTOMERS")
    }
    
    setParams(){
        this.activatedRoute.params.subscribe((params: Params) => {
          this.idFromUrl = params["customer_id"];
        });
    }
    
    editCustomer(id) {
        this.router.navigate(['shops/edit-customer/', id]);
    }
    
    editContacts(){
        let dialogRef = this.dialog.open(UsercontactsComponent, {
            panelClass: 'car-dialog-form',
            width: "800px",
            data: {user_id:this.globalService.getCurrentUser()["id"],user_type:this.globalService.getCurrentUser()["user_type"],customer_id:this.idFromUrl}
          });

          dialogRef.afterClosed().subscribe(result => {

          });
    }
    viewVehicles(id){
        localStorage.setItem("listCustomerID", id);
        this.globalService.localGetterSetter({user_type: "User",user_id: this.currentCustomer.id,search_keyword: "",start: 0,limit: 10,},null,'shop-customer-');
        this.router.navigate(['shops/list-cars']);
    }

    ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
      }  
}
