import {Component, OnInit, ViewChild,OnDestroy} from '@angular/core';
import {AbstractControl, FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
import {Http, Headers, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../../environments/globalFunctions";
import {globalConstants} from "../../../../environments/globalConstants";
import {GlobalService} from "../../../../environments/global.service";
import {lang} from "../../../../environments/lang-en";
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DataSource, SelectionModel} from '@angular/cdk/collections';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {ScrollToService} from 'ng2-scroll-to-el';
import { AppComponent } from "../../../app.component"
import { MatTableDataSource } from '@angular/material/table';

import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
const SHOP_RESOURCES_LIST='shop-resources-';

@Component({
  selector: 'app-listshopresources',
  templateUrl: './listshopresources.component.html',
  styleUrls: ['./listshopresources.component.scss']
})
export class ListshopresourcesComponent implements OnInit, OnDestroy  {
    public submitted = false;
    public currentCustomer;
    public currentCustomerName;
    public currentCustomerVehicles;
    public isCollapsed: boolean = true;
    public openShopCustomersSearch: boolean = true;
    public currentShopDetails;
    public listShops;
    public listParts = [];
    public UnapprovedPartsList = [];
    public result: any;
    public idFromUrl;
    public listCar: FormGroup;
    displayedColumns = ['name', 'link', 'added_on', 'Actions'];
    dataSource: MatTableDataSource<sourcesList> = new MatTableDataSource();

    public more_rows;
    public options = {
        user_type: "User",
        user_id: "",
        search_keyword: "",
        start: 0 ,
        limit: 10 ,
    }

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    public record :any =[];
    public subscriptionDataForAPI: Subscription = new Subscription();
    recordsTotal: any;

    constructor(public app:AppComponent,private formBuilder: FormBuilder, public titleService: Title, private globalService: GlobalService, private route: ActivatedRoute, private http: Http, private router: Router, private scrollService: ScrollToService) {
        this.globalService.setMetaData("SHOPS","RESOURCES_LIST")

        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
        this.options.user_id = this.currentCustomer.id;
        this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
        this.reinitializeOptions();
        if(localStorage.getItem(SHOP_RESOURCES_LIST+"search_keyword")!="") {
            this.openShopCustomersSearch = true;
        } else {
            this.openShopCustomersSearch = false;
        }
        this.listCar = this.formBuilder.group({
            customer_id: [''],
        });
        setTimeout(()=>{
        let isEnable=this.globalService.getAcl('resources','enable');
        if(!(isEnable)){
            this.router.navigate(['shops/action/forbidden']);
        }
    },globalConstants.RELOAD_WAIT)
    }

    ngOnInit() {
        // //this.titleService.setTitle(lang.TITLE_ADMIN_SHOP_LIST);
       
    }

    ngAfterViewInit() {
        setTimeout(() => {
          this.setDataTable();
        },0)
    }  
      
    search(event) {
        this.options.start = 0;
        this.setDataTable();
    }

    clearSearch() {
        this.openShopCustomersSearch=false;
        this.globalService.localGetterSetter(null,this.options,SHOP_RESOURCES_LIST);
        this.reinitializeOptions();
        this.setDataTable();
    }

    checkFormEmpty(button) {
        if (this.options.search_keyword == "") {
            if (button) {button.color = "primary"}
            return false
        }
        else {
            if (button) {button.color = "accent"}
            return true;
        }
    }

    onResize(event) {
        event.target.innerWidth;
    }

    /* Edit Vendor Function */

    editResource(id) {
        this.globalService.localGetterSetter(this.options,null,SHOP_RESOURCES_LIST);
        this.router.navigate(['shops/edit-resource/', id]);
    }

    /* DELETE VENDOR */

    deleteResource(resource_id) {
        this.globalService.localGetterSetter(this.options,null,SHOP_RESOURCES_LIST);
        var stringifiedFormData = "user_type=" + this.currentCustomer.user_type + "&user_id=" + this.currentCustomer.id + "&resource_id=" + resource_id;
        this.globalService.confirmDialog(resource_id, 'Are you sure you want to delete this resource?').subscribe((res) => {
            this.result = res;
            if (this.result != null) {
                this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_DELETE_RESOURCE_URL, stringifiedFormData).subscribe((data:any) => {
                        var message = data.message;
                        if (data.result == "1") {
                            this.globalService.onDeleteCheck(SHOP_RESOURCES_LIST,this.options);
                            this.app.deleteResource(resource_id)
                            this.reinitializeOptions()
                            this.setDataTable();
                            this.globalService.snackbar('success', message);
                        } else {
                            this.globalService.snackbar("error", message);
                        }
                });
            }
        });
    }

    getCurrentRoute() {
        return this.router.url
    }

    scrolling() {
        this.scrollService.scrollTo('.car-datatable', 1000, -20);
    }

    paginateByLimit(event) {
        this.scrolling();
        this.options.limit = event.pageSize;
        this.options.start = 0;
        this.setDataTable();
    }

    reinitializeOptions() {
        this.options=this.globalService.localGetterSetter(this.options,this.options,SHOP_RESOURCES_LIST);
        this.options.start = 0;
        this.options.user_type= "User";
        this.options.user_id= this.currentCustomer.id;
    }

    getTotalRecords(){
        return parseInt(localStorage.getItem(SHOP_RESOURCES_LIST+'totalRecords'));
    }

    paginate(event,type){
      this.scrolling();
      if(type=="next") {
        this.options.start = this.options.start + this.options.limit;
      } else {
        this.options.start = this.options.start - this.options.limit;
      }
      this.setDataTable();
    }

    firstList() {
        return this.globalService.firstList(SHOP_RESOURCES_LIST,this.options);
    }

    lastList() {
        return this.globalService.lastList(SHOP_RESOURCES_LIST);
    }

    getUpperLimit(currentLastLimit, total) {
        if (currentLastLimit > total) {
            return total
        } else {
            return currentLastLimit
        }
    }
    setDataTable(){
        this.record = [];
        var content = globalFunctions.stringifyFormData(this.options, null);
        var callApi = this.globalService.callAPI(globalConstants.API_GET_RESOURCE_LIST_URL, content);
        callApi.subscribe((data:any) => {
            if (data.data != undefined) {
                let moreRows='false';
                let firstList='false';
                this.globalService.localGetterSetter("totalRecords",data.recordsTotal,SHOP_RESOURCES_LIST);
                if(data.more_rows == "true") {
                    moreRows="true";
                }
                this.globalService.localGetterSetter("more_rows",moreRows,SHOP_RESOURCES_LIST);
                if (data.start >= 0 && data.start <= 5) {
                    firstList="true";
                }
                this.globalService.localGetterSetter("first_list",firstList,SHOP_RESOURCES_LIST);

                if (data.data.length != undefined) {
                    this.recordsTotal = data.data.length;
                    for (var i = 0; i < data.data.length; i++) {
                        this.AddRecord(data.data[i]);
                    }
                    this.dataSource = new MatTableDataSource(this.record)
                    this.dataSource.sort = this.sort;
                    this.globalService.localGetterSetter("current-visible",this.recordsTotal,SHOP_RESOURCES_LIST);
                } else {
                    this.globalService.localGetterSetter("current-visible",'1',SHOP_RESOURCES_LIST);
                    this.recordsTotal = "1";
                    this.AddRecord(data.data);
                    this.dataSource = new MatTableDataSource(this.record);
                    this.dataSource.sort = this.sort;
                }
                this.globalService.localGetterSetter(null,this.options,SHOP_RESOURCES_LIST);
            } else {
                this.dataSource = new MatTableDataSource(this.record);
                this.globalService.localGetterSetter("totalRecords",'0',SHOP_RESOURCES_LIST);
            }
        })
    }

    /** its user add the record via using create r */
    AddRecord(record){
      var data = this.createRecord(record);
      this.record.push(data);
   }

   private createRecord(record) {
    return {
        resource_name: record.resource_name,
        resource_link: record.resource_link,
        add_date: this.globalService.getFullFormateDate(record.add_date,"GD","H"),
        modify_date: this.globalService.getFullFormateDate(record.modify_date,"GD","H"),
        id:record.id,
        shop_id: record.shop_id,
        left_menu_status: record.menu_status,
    };
}
    isAllowed(action){
        return this.globalService.getAcl('resources',action);
    }

    ngOnDestroy(){
        this.subscriptionDataForAPI.unsubscribe();
      }  
}

export interface sourcesList {
    resource_name: any;
    resource_link: any;
    add_date: any;
    modify_date: any;
    id: any;
    shop_id: any;
    left_menu_status:any;
}
