<div class="panel panel-primary" id="mainCompotents">
  <div class="panel-heading padding-left-30">
    <h3 class="panel-title">Order Queue LIST</h3>
    <button mat-mini-fab class="custom-btn-back" (click)="gs.goBack();">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <div class="panel-body bgcolor-lightgrey">
        <div class="display-full relative">
                <mat-expansion-panel hideToggle="false" class="search-filter search-employee relative">
                    <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px">
                        <strong><i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
                    </mat-expansion-panel-header>
                    <form id="advanceSearchForm" class="collapse in" aria-expanded="true">
                        <div class="panel-body">
                            
                            <div class="col-sm-3" >
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <input  matInput placeholder="Search Keyword" title="Search Keyword" [(ngModel)]="options.search_keyword" [ngModelOptions]="{standalone: true}" class="form-control input-lg custom-form-input"/>
                                </mat-form-field>
                            </div>
                            
                            <div class="col-sm-3" >
                                <mat-select placeholder="Choose Status" class="form-control custom-form-input" [(ngModel)]="options.search_status" [ngModelOptions]="{standalone: true}">
                                    <mat-option *ngFor="let op of status;let i=index" value="{{i}}">{{op}}</mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <mat-action-row class="search-filter-footer">
                          <button mat-raised-button color="accent" type="submit" title="Search" (click)="search()">
                            Search
                          </button>
                        </mat-action-row>
                    </form>
                </mat-expansion-panel>
                <button mat-button  class="clear-btn" title="Clear Filter" #clearBtn (click)="clearSearch()" [class.active-search]="checkFormEmpty(clearBtn)">
                    Clear Filter
                </button>
            </div>
    <div class="display-full relative">
        <div class="car-datatable uc-datatable mat-elevation-z1">
            <div class="table-responsive relative">
                <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg uc-table-responsive">

                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Name </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Name"> {{row.name}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef data-role="auto"> Status </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Status"> {{row.status }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="added_on">
                        <mat-header-cell *matHeaderCellDef mat-sort-header  data-role="auto"> Added on </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="auto" data-val="Added on"> {{row.added_on }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Actions">
                        <mat-header-cell *matHeaderCellDef data-role="button"> Actions </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="button">
                          <button  (click)="viewQueDetail(row)" title="View" class="btn-view mat-raised-button mat-accent" type="button">View</button>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
                <div class="padding-15 text-center" [style.display]="getTotalRecords() < 1 ? '' : 'none'">
                     No Records Available
            </div>
        </div>
        <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
            <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0" [pageSize]="options.limit" [pageSizeOptions]="[5, 10,25, 100]" (page)="paginateByLimit($event)" >
            </mat-paginator>

            <div class="mat-paginator table-paging">
                <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} - {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
                <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                        <mat-icon>navigate_before</mat-icon>
                </button>
                <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                        <mat-icon>navigate_next</mat-icon>
                </button>
            </div>
        </div>
    </div>
    </div>
  </div>
</div>