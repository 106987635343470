import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import * as moment from 'moment-timezone';
import { globalConstants } from 'src/environments/globalConstants';
window.moment = moment;

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  public getSocketConnectionStatus=new BehaviorSubject(false);
  public modalViewStatus = new BehaviorSubject<string>('');
  constructor() {}

  updateSocketConnectionStatus=(status: boolean) => {
    this.getSocketConnectionStatus.next(status);
  }

  isAutoGeneratedEmail(emailId) {
    if (emailId.includes("completeautoreports.com") && emailId.includes("useraccount")) {
      return true;
    } else {
      return false;
    }
  }

  convertTimeInString = (shopDetails, time) => {
    let shopTimeZone = (shopDetails.time_zone == '' || typeof (shopDetails.time_zone) == 'undefined' || shopDetails.time_zone == null) ? globalConstants.DEFAULT_TIME_ZONE : shopDetails.time_zone;
    var a = moment().tz(shopTimeZone);
    var b = moment(time).tz(shopTimeZone);
    const days = a.diff(b, 'days');
    if (days > 7) {
      return moment(time).tz(shopTimeZone).format('MM/DD/YYYY');
    } else {
      return moment(time).fromNow();
    }
  }

  toggleLiveChatModal = (modalState: string) => {
    this.modalViewStatus.next(modalState);
  };
  
}
