import {Component, OnInit, ViewChild, Pipe, Inject, ElementRef, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
import {Http, Headers, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../../environments/globalFunctions";
import {globalConstants} from "../../../../environments/globalConstants";
import {GlobalService} from "../../../../environments/global.service";
import {lang} from "../../../../environments/lang-en";
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';

// import {MatPaginator, MatSort, MatDatepicker} from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {DataSource, SelectionModel} from '@angular/cdk/collections';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';


import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';

@Component({
  selector: 'app-listshopssubscribedcustomers',
  templateUrl: './listshopssubscribedcustomers.component.html',
  styleUrls: ['./listshopssubscribedcustomers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListshopssubscribedcustomersComponent implements OnInit {
    public currentCustomer;
    public currentCustomerName;
    public currentShopID;
    public currentCustomerVehicles;
    public isCollapsed: boolean = true;
    public currentShopDetails;
    public listShops;
    public listParts = [];
    public UnapprovedPartsList = [];
    public result: any;
    displayedColumns = ['name', 'email', 'subscription_type', 'duration', 'amount', 'subscribed_on', 'expiry_on'];
    dataSource: ExampleDataSource | null;
    public more_rows;
    public options = {
        user_type: "Super Admin",
        user_id: "",
        shop_id: "" ,
        part_status: 0,
        start: parseInt(localStorage.getItem("start")) || 0 ,
        limit: parseInt(localStorage.getItem("limit")) || 10 ,
    }

    // exampleDatabase = new ExampleDatabase(this.http, this.options);
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(public titleService: Title, private globalService: GlobalService, private route: ActivatedRoute, private http: Http, private router: Router) {
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInAdmin"));
        this.currentShopID = JSON.parse(localStorage.getItem("currentShopID"));
        this.options.user_id = this.currentCustomer.id;
        this.options.shop_id = this.currentShopID;
        this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
    }

    ngOnInit() {
        //this.titleService.setTitle(lang.TITLE_ADMIN_SHOP_LIST);
        this.reinitializeOptions();
        this.dataSource = new ExampleDataSource(new ExampleDatabase(this.http, this.options, this.globalService), this.sort, this.paginator);
    }

    getCurrentRoute() {
        return this.router.url
    }

    scrolling(){
        this.globalService.setScrolling('.car-datatable', 1000, -20);
    }

    paginateByLimit(event){
        this.globalService.setScrolling('body', 600, 0);
        this.options.limit = event.pageSize;
        this.options.start = 0;
        this.scrolling();
        this.dataSource = new ExampleDataSource( new ExampleDatabase(this.http,this.options, this.globalService),this.sort, this.paginator);
    }

    reinitializeOptions() {
        this.options = {
            user_type: this.currentCustomer.user_type,
            user_id: this.currentCustomer.id,
            shop_id: this.options.shop_id,
            part_status: 0,
            start: parseInt(localStorage.getItem("start")) || 0 ,
            limit: parseInt(localStorage.getItem("limit")) || 10 ,
        }
    }

    getTotalRecords() {
        return parseInt(localStorage.getItem('totalRecords'))
    }

    formatDate(date) {
        if (!new Date(date) || date == null || date == "0000-00-00 00:00:00") { return "--" }
        return (new Date(date).getMonth()+1) + "/" + new Date(date).getDate() + "/" + new Date(date).getFullYear()
    }

    paginate(event,type){
        this.scrolling();
        if(type=="next") {
          this.options.start = this.options.start + this.options.limit;
          this.dataSource = new ExampleDataSource(new ExampleDatabase(this.http,this.options, this.globalService),this.sort, this.paginator);
        } else {
          this.options.start = this.options.start - this.options.limit;
          this.dataSource = new ExampleDataSource(new ExampleDatabase(this.http,this.options, this.globalService),this.sort, this.paginator);
        }
    }

    firstList() {
        if (localStorage.getItem("first_list") == "true" || this.options.limit >= parseInt(localStorage.getItem("totalRecords")) || this.options.start == 0)
            return true;
        else
            return false;
    }

    lastList() {
        if (localStorage.getItem("more_rows") == "true") {
            return !true
        } else {
            return !false
        }
    }

    getUpperLimit(currentLastLimit, total) {
        if (currentLastLimit > total) {
            return total
        } else {
            return currentLastLimit
        }
    }

}

export interface UserData {
    name: any;
    email: any;
    subscription_type: any;
    duration: any;
    amount: any;
    subscribed_on: any;
    expiry_on: any;
    shop_id: any;
}

/** An example database that the data source uses to retrieve data for the table. */
export class ExampleDatabase {
    public total = 100;
    public options = {
        user_type: "Super Admin",
        user_id: "",
        shop_id: "",
        part_status: 0,
        start: 0,
        limit: 10,
    }

    dataChange: BehaviorSubject<UserData[]> = new BehaviorSubject<UserData[]>([]);
    public recordsTotal = "";
    get data(): UserData[] {return this.dataChange.value;}
    getTotalRecords() {return this.recordsTotal}

    constructor(public http: Http, options, private globalService: GlobalService) {
        this.options = options;
        var content = globalFunctions.stringifyFormData(this.options, null);
        var callApi = this.globalService.callAPI(globalConstants.API_GET_SUBSCRIBED_CUSTOMERS_LIST_URL, content);
        callApi.subscribe((data:any) => {
             if(data.data!=undefined) {
                  localStorage.setItem("totalRecords",data.recordsTotal);

                  if(data.more_rows == "true") {
                    localStorage.setItem("more_rows","true");
                  } else {
                    localStorage.setItem("more_rows","false");
                  }

                  if(data.start >= 0 && data.start <= 10) {
                    localStorage.setItem("first_list","true");
                  } else {
                    localStorage.setItem("first_list","false");
                  }

                  this.recordsTotal = data.data.length;

                  if(data.data.length==undefined){
                    this.AddRecord(data.data)
                  } else {
                    for(var i=0;i<data.data.length;i++){
                      this.AddRecord(data.data[i])
                    }
                  }
            } else {
              localStorage.setItem("totalRecords", "0")
            }
        })
    }

    AddRecord(record) {
        const copiedData = this.data.slice();
        copiedData.push(this.createRecordrecord(record));
        this.dataChange.next(copiedData);
    }

    private createRecordrecord(record) {
        return {
            name: record.first_name + " " + record.last_name,
            email: record.email,
            subscription_type:record.subscription_type,
            duration: record.duration,
            amount: record.amount,
            subscribed_on: this.globalService.getFullFormateDate(record.subscription_date,'GD','H'),
            expiry_on: this.globalService.getFullFormateDate(record.expiry_date,'GD','H'),
            shop_id: record.id,
        };
    }

}

export class ExampleDataSource extends DataSource<any> {
    constructor(private _exampleDatabase: ExampleDatabase, private _sort: MatSort, private _paginator: MatPaginator) {
        super();
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<UserData[]> {
        const displayDataChanges = [
            this._exampleDatabase.dataChange,
            this._paginator.page,
            this._sort.sortChange,
        ];

        return Observable.merge(...displayDataChanges).map(() => {
            let data = this._exampleDatabase.data.slice();

            // sort if needed
            if (this._sort.active && this._sort.direction !== '') {
                const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
                return this.getSortedData().splice(startIndex, this._paginator.pageSize);;
            }
            const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            return data.splice(startIndex, this._paginator.pageSize);
        });
    }

    getSortedData(): UserData[] {
        const data = this._exampleDatabase.data.slice();
        if (!this._sort.active || this._sort.direction == '') {return data;}

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            let valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            let valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
        });
    }

    disconnect() {}

}
