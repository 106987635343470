import { Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Http,Headers, Response} from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { globalFunctions } from "../../../environments/globalFunctions";
import { globalConstants } from "../../../environments/globalConstants";
import {GlobalService} from "../../../environments/global.service";
import { lang } from "../../../environments/lang-en";
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TextMaskModule } from 'angular2-text-mask';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    moduleId: module.id,
    selector: 'shop-editcomputercode',
    templateUrl: 'shopeditcomputercode.component.html',
    styleUrls: ['shopeditcomputercode.component.scss']
})
export class ShopeditcomputercodeComponent implements OnDestroy {
  public currentCustomer;
  public currentCustomerName;
  public isCollapsed:boolean = true;
  public idFromUrl;
  public showSubscriptionMessage = false;
  public shopEditComputerCode:FormGroup;
  public currentComputerDetails;
  public cc_id;
  public subscriptionDataForAPI: Subscription = new Subscription();
  
  constructor(private activatedRoute:ActivatedRoute,private formBuilder: FormBuilder,private route: ActivatedRoute,public titleService:Title,private http: Http,private router: Router,public snackBar: MatSnackBar, public globalService: GlobalService) {
  //console.log(router.url)

    if(localStorage.getItem("loggedInUser")) {
            this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
            this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
            this.cc_id = localStorage.getItem("shopCcID");

            this.shopEditComputerCode = this.formBuilder.group({
                trouble_code: ['', Validators.required],
                fault_location: [''],
                user_type: this.currentCustomer.user_type,
                user_id: this.currentCustomer.id,
                cc_id: this.cc_id,
                shop_id: this.currentCustomer.shop_id
            })
        } else {
          this.router.navigate(["/shops"]);
        }
        setTimeout(()=>{
        let isEnable=this.globalService.getAcl('computer_code','edits');
        if(!(isEnable)){
            this.router.navigate(['shops/action/forbidden']);
        }
    },globalConstants.RELOAD_WAIT)
    this.globalService.setMetaData("SHOPS","EDIT_COMPUTER_CODES")
    }


  ngAfterViewInit(){
      
      var shopContent = "user_id="+this.currentCustomer.id+"&user_type="+this.currentCustomer.user_type+"&cc_id="+ this.cc_id;
      this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_GET_COMPUTER_CODES_LIST_URL,shopContent).subscribe((data:any) => {
          this.currentComputerDetails = data.data;
          //this.shopEditComputerCode.controls.cc_id.setValue(this.currentComputerDetails.id);
      });
  }

  ngOnInit() {
    //this.titleService.setTitle(lang.TITLE_ADMIN_SIGN_IN);
  }


    editComputerCode() {
        if(this.shopEditComputerCode.valid) {
          var stringifiedFormData = globalFunctions.stringifyFormData(this.shopEditComputerCode.value,null);
          this.subscriptionDataForAPI = this.globalService.callAPI(globalConstants.API_EDIT_COMPUTER_CODE_URL,stringifiedFormData).subscribe((data:any)=> {
              var message = data.message;
              if (data.result == "1") {
                  this.router.navigate(['shops//list-computer-code']);
                  this.globalService.snackbar('success', message);
              } else { 
                  this.globalService.snackbar('error', message);
              }
            });
        } else{
          this.mandatoryMsg();
        }
    }
  
    mandatoryMsg() {
        this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }

    ngOnDestroy(){
      this.subscriptionDataForAPI.unsubscribe();
  }  
}
