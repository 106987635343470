<div mat-dialog-title class="relative" >
    Change Customer Password
   <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
        <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
</div>
<!-- BODY -->
<mat-dialog-content>
    <form class="form-horizontal form-control-normal" autocomplete="off" [formGroup]="checkForPwdForm" (ngSubmit)="validatePassword()">
        <div class="display-full margin-bottom-5">
            <div class="col-sm-12">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never"  hideRequiredMarker="false">
                    <input  matInput placeholder="New Password" formControlName="password"  class="form-control input-lg custom-form-input" (keyup)="checkIfValid()"  type="password"/>
                    <mat-error *ngIf="checkForPwdForm.controls.password.hasError('required')">
                        New Password is required
                    </mat-error>
                    <mat-error *ngIf="checkForPwdForm.controls.password.hasError('minlength')">
                        The new password must be minimum 6 characters
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="display-full margin-bottom-5">
            <div class="col-sm-12">
                <mat-form-field class="car-input btn-block" floatPlaceholder="never"  hideRequiredMarker="false">
                    <input  matInput placeholder="Confirm Password" formControlName="confirm_password"  class="form-control input-lg custom-form-input" (keyup)="checkIfValid()"  type="password"/>
                    <mat-error *ngIf="checkForPwdForm.controls.confirm_password.hasError('required')">
                        Confirm Password is required
                    </mat-error>
                    <mat-error *ngIf="checkForPwdForm.controls.confirm_password.hasError('minlength')">
                        The Confirm Password must be minimum 6 characters
                    </mat-error>
                    <mat-error *ngIf="checkForPwdForm.controls.confirm_password.hasError('mismatch')">
                        New Password and Confirm Password should be same.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="display-full margin-bottom-5">
          <div class="col-sm-12 text-right">
            <button type="submit" mat-raised-button  color="accent" title="Submit" [disabled]="submitted">
                SUBMIT
            </button>
            <button type="button" mat-raised-button class="font-bold" title="Cancel" (click)="cancelAuthentication()">CANCEL</button>
          </div>
      </div>
    </form>
</mat-dialog-content>

