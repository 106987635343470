import { Component, OnInit } from '@angular/core'; 
import { Router } from '@angular/router';
@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
})
export class CallbackComponent implements OnInit {
  constructor(  ) { }

  ngOnInit() {
  }
//   login(){
//     if(this.alreayLogin){
//       window.location.href=window.location.origin+"/shops/dashboard";
//     }else{
//       this.router.navigate(["/shops"]);
//     }
//   }

}
