import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { GlobalService } from 'src/environments/global.service';
import { globalConstants } from 'src/environments/globalConstants';
import * as moment from 'moment';
import { globalFunctions } from 'src/environments/globalFunctions';

@Component({
  selector: 'app-complete-profile-setup',
  templateUrl: './complete-profile-setup.component.html',
  styleUrls: ['./complete-profile-setup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompleteProfileSetupComponent implements OnInit {
  @Output() handleSkipShopProfileSetup = new EventEmitter<any>();
  @Input() showSkipButton :any = 0;
  public shopProfileForm: FormGroup;
  public currentShopDetails: any = {};
  public taxIncluded: boolean;
  public supplyChargesIncluded: boolean;
  public timezone = '';
  public timezoneData = [];
  public timeSlots: any = [];
  public currentLoginUserInfo: any;
  constructor(
    private formBuilder: FormBuilder,
    public globalService: GlobalService
  ) { }


  ngOnInit(): void {
    this.timeSlots = globalConstants.TIME_SLOTS;
    if (typeof (localStorage.getItem("loggedInUser")) !== 'undefined') {
      this.currentLoginUserInfo = this.globalService.getCurrentUser();
      this.shopProfileForm = this.formBuilder.group({ 
        user_type: this.currentLoginUserInfo.user_type, 
        user_id: this.currentLoginUserInfo.id, 
        shop_id: this.currentLoginUserInfo.shop_id,  
        labor_tax_excluded: [''], 
        tax_rate: ['', [Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/),Validators.min(0),Validators.max(100)]], 
        include_tax_default: [''], 
        tax_setting_status: [this.currentLoginUserInfo.tax_setting_status],
        supply_charges_disp_type: [''], 
        supply_charges: ['', [Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/)]], 
        supply_charges_limit: ['', [Validators.pattern(/^-?\d+(?:\.\d+)?$/)]], 
        include_supply_charges: [''], 
        cc_fee: ['', [Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/)]], 
        cc_fee_type: ['1'], 
        shop_timings: this.formBuilder.array([]),
        selectedItems: [false],
        open_time: [''], 
        close_time: [''], 
        timezone: [''],
      });

      this.timings.valueChanges.subscribe((v) => {
        this.shopProfileForm.controls.selectedItems.setValue(this.mapItems(v));
      });

      for (let i of [0, 1, 2, 3, 4, 5, 6]) {
        this.timings.push(this.formBuilder.group({
          weekdayid: [false],
          start_time: [''],
          close_time: ['']
        }, { validator: this.timeLessThan('start_time', 'close_time') }));
        this.timings.controls[i].get('weekdayid').valueChanges.subscribe(res => {
          if (res) {
            this.timings.controls[i].get('start_time').setValidators([Validators.required]);
            this.timings.controls[i].get('close_time').setValidators([Validators.required]);
          } else {
            this.timings.controls[i].get('start_time').setValue("");
            this.timings.controls[i].get('close_time').setValue("");
            this.timings.controls[i].get('start_time').clearValidators();
            this.timings.controls[i].get('close_time').clearValidators();
          }
          this.timings.controls[i].get('start_time').updateValueAndValidity();
          this.timings.controls[i].get('close_time').updateValueAndValidity();
        });
      }
      this.getShopProfileDetails();
    }
  }

  mapItems(items) {
    let selectedItems = items.filter((item) => item.weekdayid).map((item) => item.id);
    return selectedItems.length ? selectedItems : null;
  }
  get timings(): FormArray {
    return this.shopProfileForm.get('shop_timings') as FormArray;
  }

  timeLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let formTime = group.controls[from];
      let toTime = group.controls[to];
      if (formTime.value && toTime.value && (formTime.value > toTime.value || formTime.value == toTime.value)) {
        return {
          dates: "Start Time should be less than End Time"
        };
      }
      return {};
    }
  }

  getShopProfileDetails() {
    const shopContent = "user_id=" + this.currentLoginUserInfo.id + "&user_type=" + this.currentLoginUserInfo.user_type + "&shop_id=" + this.currentLoginUserInfo.shop_id;
    this.globalService.callAPI(globalConstants.API_SHOP_DETAILS, shopContent).subscribe((data: any) => {
      if (data.result == "1") {
        this.currentShopDetails = data.data;
        this.timezone = this.currentShopDetails.time_zone;
        this.getTimezone(this.currentShopDetails.country_id);
        this.shopProfileForm.controls.timezone.setValue(this.timezone);
        let obj = Object.assign({}, this.shopProfileForm.getRawValue(), this.currentShopDetails);
        this.shopProfileForm.patchValue(obj);
        this.shopProfileForm.controls.supply_charges.setValue(this.currentShopDetails.supplies_charges);
        if (this.currentShopDetails.is_taxes_included == 1) {
          this.taxIncluded = true;
          this.shopProfileForm.controls.include_tax_default.setValue(1);
        } else {
          this.taxIncluded = false;
          this.shopProfileForm.controls.include_tax_default.setValue(0);
        }
        if (this.currentShopDetails.is_labor_tax_excluded == 1) {
          this.shopProfileForm.controls.labor_tax_excluded.setValue('1');
        } else {
          this.shopProfileForm.controls.labor_tax_excluded.setValue('0');
        }

        if (this.currentShopDetails.is_supplies_charges_included == 1) {
          this.supplyChargesIncluded = true;
          this.shopProfileForm.controls.include_supply_charges.setValue(1);
        } else {
          this.supplyChargesIncluded = false;
          this.shopProfileForm.controls.include_supply_charges.setValue(0);
        }
        this.shopProfileForm.controls.supply_charges_disp_type.setValue(this.currentShopDetails.supply_charges_disp_type);
        if (this.currentShopDetails.supply_charges_disp_type == 2) {
          this.shopProfileForm.controls.supply_charges_limit.setValue(this.currentShopDetails.supply_charges_limit);
          this.shopProfileForm.get('supply_charges').setValidators([
            Validators.required,
            Validators.min(0),
            Validators.max(100),
            Validators.pattern(/^-?\d+(?:\.\d+)?$/)
          ]);
          this.shopProfileForm.get('supply_charges').updateValueAndValidity();
        }
        this.shopProfileForm.controls.cc_fee_type.setValue(this.currentShopDetails.cc_fee_type);
        if (this.currentShopDetails.cc_fee_type == 2) {
          this.shopProfileForm.get('cc_fee').setValidators([
            Validators.required,
            Validators.min(0),
            Validators.max(100),
            Validators.pattern(/^-?\d+(?:\.\d+)?$/)
          ]);
          this.shopProfileForm.get('cc_fee').updateValueAndValidity();
        }

        if (this.currentShopDetails.shopTimeLine && Array.isArray(this.currentShopDetails.shopTimeLine)) {
          let shopWorkTime = {};
          this.currentShopDetails.shopTimeLine.map((res, index) => {
            if (res.is_checked == 1) {
              shopWorkTime = { weekdayid: true, start_time: moment(res.open_time, ["hh:mm:ss"]).format("HH:mm"), close_time: moment(res.close_time, ["hh:mm:ss"]).format("HH:mm") };
              this.timings.controls[index].patchValue(shopWorkTime);
            }
          });
        }
      }
    })
  }

  editShopProfile() {
    if (this.shopProfileForm.valid) {
      if (!this.shopProfileForm.value.timezone || this.shopProfileForm.value.timezone == null) {
        this.shopProfileForm.patchValue({ timezone: globalConstants.DEFAULT_TIME_ZONE });
      }
      if (!this.shopProfileForm.value.timeslot_suggestions_lookup_days || this.shopProfileForm.value.timeslot_suggestions_lookup_days == null) {
        this.shopProfileForm.patchValue({ timeslot_suggestions_lookup_days: '' });
      }
      var data = new FormData();
      for (let i in this.shopProfileForm.value) {
        if (i == 'shop_timings' && Array.isArray(this.shopProfileForm.value[i])) {
          let showWorkTime = this.shopProfileForm.value[i];
          showWorkTime.map((data, index) => {
            if (!data['weekdayid']) {
              data['start_time'] = "";
              data['close_time'] = "";
            }
            return data['weekdayid'] = index;
          });
          data.append(i, JSON.stringify(showWorkTime));
        } else {
          data.append(i, this.shopProfileForm.value[i]);
        }
      }
      this.globalService.formData(globalConstants.API_EDIT_SHOP_PROFILE_COMPETION, data).subscribe((response: any) => {
        if (response.result == "1") {
          this.currentLoginUserInfo = this.globalService.getCurrentUser();
          this.currentLoginUserInfo.tax_setting_status = "1";
          localStorage.setItem("loggedInUser", JSON.stringify(this.currentLoginUserInfo));
          this.globalService.snackbar('success', response.message);
          this.skipShopProfileSetup();
        } else {
          this.globalService.snackbar('error', response.message);
        }
      });
    } else {
      this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
  }

  updateDefaultTime(i: number) {
    if (this.timings.controls[i].get('weekdayid').value) {
      this.timings.controls[i].get('start_time').setValue("");
      this.timings.controls[i].get('close_time').setValue("");
    }
  }

  getTimezone(Id) {
    let data = { country_id: Id, user_type: this.globalService.getCurrentUser()["user_type"], user_id: this.globalService.getCurrentUser()["id"] };
    this.globalService.callAPI(globalConstants.API_GET_TIMEZONE, data).subscribe((zones: any) => {
      if (zones.result == '1') {
        this.timezoneData = globalConstants.timeZoneDDLPlaceHolder.concat(zones.data);
      } else {
        this.timezoneData = [];
      }
      this.shopProfileForm.controls.timezone.setValue(this.timezone);
    });
  }

  handleDefaultTax(event) {
    if (event.checked) {
      this.shopProfileForm.controls.include_tax_default.setValue('1');
    } else {
      this.shopProfileForm.controls.include_tax_default.setValue('0');
    }
  }

  handleLaborTaxExcluded(event) {
    if (event.checked) {
      this.shopProfileForm.controls.labor_tax_excluded.setValue('1');
    } else {
      this.shopProfileForm.controls.labor_tax_excluded.setValue('0');
    }
  }


  handleSupplyCharges(event) {
    if (event.checked) {
      this.shopProfileForm.controls.include_supply_charges.setValue('1');
    } else {
      this.shopProfileForm.controls.include_supply_charges.setValue('0');
    }
  }

  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;

  }

  disableKey(event) {
    if (event.ctrlKey == true && (event.which == '67' || event.which == '86' || String.fromCharCode(event.which).toLowerCase() == 's')) {
      event.preventDefault();
    }
  }

  handleSupplyChargeType(e, type) {
    this.shopProfileForm.controls.supply_charges_disp_type.setValue(type);
    if (type == 1) {
      this.shopProfileForm.get('supply_charges').setValidators([Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/)]);
      this.shopProfileForm.get('supply_charges_limit').clearValidators();
      this.shopProfileForm.get('supply_charges').updateValueAndValidity();
      this.shopProfileForm.get('supply_charges_limit').updateValueAndValidity();
    } else {
      this.shopProfileForm.get('supply_charges').setValidators([
        Validators.required,
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(/^-?\d+(?:\.\d+)?$/)
      ]);
      this.shopProfileForm.get('supply_charges_limit').setValidators(Validators.pattern(/^-?\d+(?:\.\d+)?$/));
      this.shopProfileForm.get('supply_charges_limit').updateValueAndValidity();
      this.shopProfileForm.get('supply_charges').updateValueAndValidity();
    }
  }

  handleCcFeeChargeType(e, type) {
    this.shopProfileForm.controls.cc_fee_type.setValue(type);
    if (type == 1) {
      this.shopProfileForm.get('cc_fee').setValidators([Validators.required, Validators.pattern(/^-?\d+(?:\.\d+)?$/)]);
      this.shopProfileForm.get('cc_fee').updateValueAndValidity();
    } else {
      this.shopProfileForm.get('cc_fee').setValidators([
        Validators.required,
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(/^-?\d+(?:\.\d+)?$/)
      ]);
      this.shopProfileForm.get('cc_fee').updateValueAndValidity();
    }
  }

  isAllowed(action, module?: any) {
    if (typeof module != 'undefined') {
      return this.globalService.getAcl(module, action);
    }
    return false;
  }

  getSelectedOptionTimezone(event) {
    if(event !== ''){
      this.shopProfileForm.controls.timezone.setValue(event);
    }else{
      this.shopProfileForm.controls.timezone.setValue(event);
    }
  }

  skipShopProfileSetup() {
    this.handleSkipShopProfileSetup.emit();
  }

  convertIntoDecimal(event,frmControlName,beforeDecimalDigit,digitAfterDecimal){
    let fieldValue = this.shopProfileForm.get(frmControlName).value
    let result= this.globalService.processDecimalValue(fieldValue,beforeDecimalDigit);
    this.shopProfileForm.get(frmControlName).setValue(result);
    return globalFunctions.validateNumbersWithDecimal(event,digitAfterDecimal);
  }

  validateNumbersWithDecimal(event,limit) {
    return globalFunctions.validateNumbersWithDecimal(event,limit);
  }
}
