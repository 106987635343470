import { globalConstants } from 'src/environments/globalConstants';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { IChat, IConversation, ICustomerChat } from 'src/app/interfaces';
import { GlobalService } from 'src/environments/global.service';
import { Observable,Subscription} from 'rxjs';
import { HelperService } from 'src/app/helper.service';
import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-customer-contacts',
  templateUrl: './customer-contacts.component.html',
  styleUrls: ['./customer-contacts.component.scss'],
})
export class CustomerContactsComponent implements OnInit {
  @Input() onMobileScreen;
  @Output() SelectContact = new EventEmitter<any>();
  @Output() newSelectContact = new EventEmitter<any>();
  @Input() refreshConversation = new Observable();
  @Input() updateBadgeInChatView = new Observable();
  public customerList: ICustomerChat[] = [];
  public conversations: IConversation[] = [];
  public activeConversation: IConversation;
  public conversationSubscription: Subscription = new Subscription();
  public unReadChatsCountMapping: { unread_count: number; chat_id: number }[] =
    [];
  public chatUnreadMapping: {
    unread_count: number;
    chat_id: number;
  }[] = [];
  public keyword: string = '';
  public shopDetails;
  public connectionActive: boolean = false;
  public contact_pagination = {
    page: 0,
    limit: 100
  };

  public conversationsPagination = {
    page: 0,
    limit: 100
  };

  public showLoadMoreCustomerButton: boolean = false;
  public showLoadMoreConversationsButton: boolean = false;

  constructor(
    public globalService: GlobalService,
    public helperService: HelperService,
    private socket: Socket
  ) {}

  ngOnInit(): void {
    if(this.conversationSubscription) {
      this.conversationSubscription.unsubscribe()
    }
    this.conversationSubscription = this.refreshConversation.subscribe(() => {
      this.getRecentChats();
    });
    this.socket.on('updateLocalBadgeCount',(updatedValue) => {
      this.unReadChatsCountMapping = updatedValue;
    });
    this.conversationSubscription =
    this.helperService.getSocketConnectionStatus.subscribe((status) => {
      this.connectionActive = status;
      if (status) {
        this.getRecentChats();
        this.getCustomers();
      } else {
        // when socket is disconnected then clear the customer list else on re-connect it will re-push the info
        this.customerList = [];
      }
    });
    this.conversationSubscription = this.updateBadgeInChatView.subscribe((conversation_id) => {
      const selected = this.unReadChatsCountMapping.find(
        (map) => map.chat_id.toString() === conversation_id.toString()
      );
      if (selected) {
        selected.unread_count = 0;
      }
    });
  }

  getCustomers = async () => {
    if (this.connectionActive) {
      const currentUser = await this.globalService.getCurrentUser();
      this.conversationSubscription =  this.globalService
        .callAPI(globalConstants.CHAT_API_GET_CUSTOMERS_URL, {
          query: this.keyword,
          shopId: currentUser.shop_id,
          page: this.contact_pagination.page,
          limit: this.contact_pagination.limit,
        })
        .subscribe((response) => {
          this.customerList = [
            ...this.customerList,
            ...response.data.customers,
          ];
          this.showLoadMoreCustomerButton = response.data.loadMore;
        });
      this.shopDetails = await this.globalService.getShopDetails();
    }
  };

  paginateCustomer = () => {
    this.contact_pagination = {
      ...this.contact_pagination,
      limit: this.contact_pagination.limit,
      page: this.contact_pagination.page + 1,
    };
    this.getCustomers();
  };

  getRecentChats = async () => {
    if (this.connectionActive) {
      const currentUser = await this.globalService.getCurrentUser();
      this.conversationSubscription = this.globalService.callAPI(globalConstants.CHAT_API_GET_CONVERSATION_URL, {
          query: this.keyword,
          shopId: currentUser.shop_id,
          limit: this.conversationsPagination.limit,
          page: this.conversationsPagination.page,
        })
        .subscribe((response) => {
          this.showLoadMoreConversationsButton = response.data.loadMore;
          if (this.conversationsPagination.page > 0) {
            this.conversations = [
              ...this.conversations,
              ...response.data.conversationInfo,
            ];
          } else {
            this.conversations = [...response.data.conversationInfo];
          }
          if (response.data.unreadChatsInfo) {
            this.unReadChatsCountMapping = response.data.unreadChatsInfo;
          }
        });
    }
  };

  paginateConversations = () => {
    this.conversationsPagination = {
      ...this.conversationsPagination,
      limit: this.conversationsPagination.limit,
      page: this.conversationsPagination.page + 1,
    };
    this.getRecentChats();
  };

  getCustomerInfoAndUpdateView = (
    customerId: string,
    selectedConversation: IConversation
  ) => {
    if (this.connectionActive) {
      const selectedCustomer = this.conversations.find(
        (conversation) => conversation.customer_id === customerId
      );
      this.conversationSubscription.unsubscribe();
      this.newSelectContact.emit(selectedCustomer);
      this.SelectContact.emit();
      this.activeConversation = selectedConversation;
    }
  };

  updateSelectedContactInfo = (customerInfo: ICustomerChat) => {
    if (this.connectionActive) {
      customerInfo && this.newSelectContact.emit(customerInfo);
    }
  };

  searchWithQuery = () => {
    this.resetList();
    this.getCustomers();
    this.getRecentChats();
  };

  HandleClearSearch(){
    this.keyword = '';
    this.searchWithQuery();
  }

  resetList = () => {
    this.customerList = [];
    this.conversations = [];
    this.contact_pagination.page = 0;
    this.conversationsPagination.page = 0;
    this.showLoadMoreCustomerButton = false;
    this.showLoadMoreConversationsButton = false;
  };

  tabChange = (e) => {};

  getTimeInString = (time: moment.MomentInput) => {
    return this.helperService.convertTimeInString(this.shopDetails,time);
  }

  getCustomerInitials(conversation: IConversation | ICustomerChat) {
    const initials =
      conversation.first_name[0].toUpperCase() +
      conversation.last_name[0].toUpperCase();
    return initials.toString();
  }

  humanizeTime = (unreadLength: number) => {
    return unreadLength > 9 ? '9+' : unreadLength;
  };

  getUnreadCount = (conversation: IConversation) => {
    if (conversation) {
      const getInfo: any = this.unReadChatsCountMapping.find(
        (unreadInfo) =>
          unreadInfo.chat_id.toString() == conversation.conversation_id
      );
      if (getInfo) {
        return getInfo.unread_count > 9 ? '9+' : getInfo.unread_count;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  ngOnDestroy() {
    this.conversationSubscription.unsubscribe();
  }
}
