import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
import { CustomerEditModalComponent } from '../../../customer/customer-edit-modal/customer-edit-modal.component';
import {VideoShowComponent} from '../../../customer/my-vehicles/video-show/video-show.component';
import { ImagetooldialogComponent } from '../../../imagetooldialog/imagetooldialog.component';
import { EditpopupComponent } from '../../shopcar/editpopup/editpopup.component';
import { VehicleInspectionReportComponent } from '../vehicle-inspection-report/vehicle-inspection-report.component';
import { UspsVehicleInspectionReportComponent } from '../usps-vehicle-inspection-report/usps-vehicle-inspection-report.component';

@Component({
  selector: 'app-detail-tab',
  templateUrl: './detail-tab.component.html',
  styleUrls: ['./detail-tab.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class DetailTabComponent implements OnInit {

  public param:any={};
  public carDetail:any={};
  public customerDetail:any={};
  public imgPath = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_PICTURES + globalConstants.IMG_THUMB;
  public insurance = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_INSURANCE + globalConstants.IMG_THUMB;
  public registeredPicture = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_REGISTRATION_PIC + globalConstants.IMG_THUMB;
  public vinPicture = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_VIN_PIC + globalConstants.IMG_THUMB;
  public vehVideo = globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_VIDEO;
  public VEHICLE_LABEL_PIC= globalConstants.S3_BUCKET_URL +globalConstants.VEHICLE_LABEL_PICTURES + globalConstants.IMG_THUMB;
  public contacts:any=[];
  public labelPhoto=[]
  public user;
  public isChanged = false;
  public vehicleInspReportData: any;
  public reportDownloadLink: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog ,public gs: GlobalService,public dialogRef: MatDialogRef<DetailTabComponent>) {
    this.param=data;
  }

  ngOnInit() {
    this.gs.diaLogObj(this.dialogRef);
    this.user = this.gs.getCurrentUser();
    this.getCustomerDeatils();
    this.getVehicleDetail();   
    this.getContactDetails();
  }
  show(type){
    this.param.tab=type;
  }
  getBlankToHypen(data){
    let obj=data;
    for(let i in obj){
      if(i=='middle_name' || i=='first_name' || i=='last_name' ){
        obj[i]=obj[i];
      }else if(i=='tax_exempted'){
        obj[i]=(obj[i]=='0' ? 'No':'Yes');
      }else if(typeof obj[i]=='string' && obj[i].length==0){
        obj[i]='-';
      }
    }
    return obj;
  }

  /*******video dialog******/
  videoShow(val): void {
  let dialogRef = this.dialog.open(VideoShowComponent, {
      panelClass: 'car-dialog-form',
      width: "800px",
      data: {name: val, url: globalConstants.S3_BUCKET_URL + globalConstants.VEHICLE_VIDEO}
  });

  dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
  });
  }
  /* image rotation dialog  */
  imageToolDialog(current,i,type,imgaar): void {
    let user = this.gs.getCurrentUser();
  let dialogRef = this.dialog.open(ImagetooldialogComponent, {
      panelClass: ['imageToolDialog'],
      width: "100%",
      data: {event: current,index:i,imgType:type,imgArray:imgaar,customerId:user.id,carId:this.param.car_id}
  });

  dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
  });
  }
  getFieldVal(f){
    if(f.type=='textbox'){
        return (f.value ? f.value : '-')
    }else if(f.type=='checkbox'){
        return (f.value ? 'Yes' : 'No')
    }else{
        let d=(f.field_option ? f.field_option:'')
        return (d && d.value ? d.value: '-')
    }
}
  getVehicleDetail() {
    let carOpt = "&vehicle_id=" + this.param.car_id + "&vehicle_info=1&customer_id=" + this.param.customer_id;
    this.gs.callAPI(globalConstants.API_GET_CUSTOMER_VEHICLES_URL, carOpt).subscribe((res:any) => {
      if (res.result == 1) {
        this.carDetail = res.data;
        this.vehicleInspReportData = res.data.vir_info;
        this.createVIRDownloadUrl();
        if (this.carDetail.production_date != "0000-00-00 00:00:00" && this.carDetail.production_date != "" && this.carDetail.production_date != null) {
          var splitDatesValues = this.carDetail.production_date.split("-");
          this.carDetail.production_date = splitDatesValues[1] + "/" + splitDatesValues[0];
        } else {
          this.carDetail.production_date = '-';
        }
        if (this.carDetail.vehicle_label_pic) {
          this.labelPhoto = this.carDetail.vehicle_label_pic
        }
      }
    }, (error) => { });
  }
  carEdit() {
    if(this.carDetail.is_walkin_customer_vehicle == '1'){
      this.gs.handleWalkinProfileEditDelete(this.carDetail.is_walkin_customer_vehicle);
      return false;
    }
    let dialogRef = this.dialog.open(EditpopupComponent, {
      panelClass: 'car-dialog-form',
      width: '100%',
      data: { customer_id: this.param.customer_id, car_id: this.param.car_id }
    });
    dialogRef.afterClosed().subscribe((r: any) => {
      if(r){
        this.dialogRef.close();
      }else{
        this.getVehicleDetail();
        this.isChanged = true;
      }      
    });
  }

  customerEdit(){
    if(this.customerDetail.is_walkin_customer == '1'){
      this.gs.handleWalkinProfileEditDelete(this.customerDetail.is_walkin_customer);
      return false;
    }
    let dialogRef = this.dialog.open(CustomerEditModalComponent, {
      panelClass: 'car-dialog-form',
      width: '1000px',
      data: { customer_id: this.param.customer_id }
    });
    dialogRef.afterClosed().subscribe((r: any) => {
      this.getCustomerDeatils();
      this.isChanged = true;
    });
  }

  getCustomerDeatils(){
    let customerOprs = "user_id="+this.user.id+"&user_type="+this.user.user_type+"&customer_id="+ this.param.customer_id;
    this.gs.callAPI(globalConstants.API_GET_CUSTOMER_DETAILS,customerOprs).subscribe((res:any) => {
      if (res.result == 1) {
        this.customerDetail=this.getBlankToHypen(res.data);
      }
    },(error)=>{})
    this.getContactDetails();
  }

  getContactDetails(){
    let customerOprs = "user_id="+this.user.id+"&user_type="+this.user.user_type+"&customer_id="+ this.param.customer_id;
    this.gs.callAPI(globalConstants.API_CONTACTS_ADD_LIST,customerOprs).subscribe((res:any)=>{
      if (res.result == 1) {
        this.contacts=res.data;
      }
    },(error)=>{})
  }

  createVIRDownloadUrl() {
    let inspReportDownloadUrl = '';
    let reportData;
    let qryStringUrl = "";
    if (this.vehicleInspReportData.vir_found == 1) {
      reportData = this.vehicleInspReportData.inspection_reports[0].acdelco_report_data;
      if (reportData) {
        inspReportDownloadUrl = globalConstants.API_DOWNLOAD_INSPECTION_REPORT_ACDELCO_URL;
        qryStringUrl += "?report_type=2";
        qryStringUrl += "&acdelco_report_id=" + reportData.id;
      } else {
        inspReportDownloadUrl = globalConstants.API_DOWNLOAD_INSPECTION_REPORT_USPS_URL;
        reportData = this.vehicleInspReportData.inspection_reports[0].usps_report_data;
        qryStringUrl += "?report_type=1";
        qryStringUrl += "&usps_report_id=" + reportData.id;
      }
      this.reportDownloadLink = inspReportDownloadUrl + qryStringUrl;
    } else {
      this.reportDownloadLink = 'javascript:void(0);';
    }
  }

openVIR() {
    if (this.vehicleInspReportData.vir_found == 0) {
        this.gs.snackbar('error', 'No report performed in this vehicle.');
    } else {
        if (this.carDetail.usps_vehicle == 1) {
            this.openUspsVIR();
        } else {
            this.openAcdelcoVIR();
        }
    }
}

openUspsVIR(){
    let dialogRef = this.dialog.open(UspsVehicleInspectionReportComponent, {
      panelClass: ['car-dialog-form', 'inscpection-dialog','usps-panel-disabled'],
      data: { service_request_id: this.vehicleInspReportData.request_id, report_data: this.vehicleInspReportData.inspection_reports[0].usps_report_data,disableFormInputControls:1,fullRequestId: this.vehicleInspReportData.car_request_id}
    });
    dialogRef.afterClosed().subscribe(result => {   
    });
}

openAcdelcoVIR(){
    let dialogRef = this.dialog.open(VehicleInspectionReportComponent, {
        panelClass: ['car-dialog-form', 'inscpection-dialog','acdelco-panel-disabled'],
        data: { service_request_id: this.vehicleInspReportData.request_id, report_data: this.vehicleInspReportData.inspection_reports[0].acdelco_report_data,disableFormInputControls:1,fullRequestId: this.vehicleInspReportData.car_request_id }
    });
      dialogRef.afterClosed().subscribe(result => {   
    });
}

handleDownloadVIR(){
  if(this.vehicleInspReportData.vir_found == 0){
      this.gs.snackbar('error', 'No report performed in this vehicle.');
  }else{
     let  localFileName = 'CARInspectionReport.pdf';
     this.gs.downloadAsset(this.reportDownloadLink,localFileName);
   }
  }

handleVIRTitle(){
    if(this.vehicleInspReportData.vir_found == 0){
         return "No inspection report performed on this vehicle.";
    }else{
        return "Vehicle Inspection Report";
    }
}

  handleVIRDownloadTitle() {
    if (this.vehicleInspReportData.vir_found == 0) {
      return "No inspection report performed on this vehicle.";
    } else {
      return "Download Vehicle Inspection Report";
    }
}
}
