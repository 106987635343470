<div id="mainCompotents"><div class="panel panel-primary" id="dashboardQuickLinks">
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">IMPORT CONTACTS</h3>
    </div>
    <div class="panel-body bgcolor-lightgrey padding-0" style="border:none;">
        <div class="container-fluid sample-download-btn">
            <p class="text-right padding-top-5">
                <a class="btn btn-success btn-download-csv" (click)="downloadSmapleCSV()" style="display: inline-block;" disableRipple>
                    <span>Download Sample CSV File</span>
                </a>
                
            </p>
            <form enctype="multipart/form-data" class="form-inline col-xs-12 padding-bottom-15" role="form" [formGroup]="importContacts" novalidate (ngSubmit)="importNewContact()">
                  <div class="col-xs-12 padding-bottom-15">
                    <h2 class="sub-heading margin-bottom-15 padding-bottom-15">UPLOAD CONTACTS FILE</h2>
                    <div class="upload-image" id="upload-img">
                        <div class="upload-image" id="upload-img">
                            <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative" >
                                <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                                Drag File Here
                                <input type="file" id="contacts_csvfiles" name="contacts_csv" formControlname="contacts_csv" (change)="getFile($event)" />
                                <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
                            </mat-toolbar>
                           <span >{{seletedFileName}}</span>
                        </div>
                        
                    </div>

                    <div class="display-full">
                        <p class="help-block" style="margin:0px;">Formats supported : csv</p>
                        <p class="help-block" style="margin:0px;">Maximum size : 10MB</p>
                    </div>
                    <button type="submit" mat-raised-button color="accent" class="pull-left col-sm-lg" title="Upload">Upload</button>
                    
                </div>
            </form>
    
        </div>
        <div id="rejectedCustomersList" *ngIf="submitted" class="container-fluid" style="min-height: 250px; padding-bottom: 180px;">
                <div class="panel panel-danger">
                    <div class="panel-heading">
                        <h3 class="panel-title" style="text-transform: uppercase;">Contacts ignored in import</h3>
                    </div>
                    <div class="panel-body table-responsive">
                        <table class="table table-striped table-hover table-condensed">
                                <thead>
                                        <tr>
                                                <!--<th>S.No.</th>-->
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Email</th>
                                                <th>Address</th>
                                                <th>City</th>
                                                <th>State</th>
                                                <th>Country</th>
                                                <th>Mobile Number</th>
                                                <th>Failure Reason</th>
                                        </tr>
                                </thead>
                                <tbody id="rejectedCustomersListView">
                                    <tr *ngFor="let data of rerrors">
                                        <td>{{data.first_name}}</td>
                                        <td>{{data.last_name}}</td>
                                        <td>{{data.email}}</td>
                                        <td>{{data.address_one}} {{data.address_two}} </td>
                                        <td>{{data.city}}</td>
                                        <td>{{data.state}}</td>
                                        <td>{{data.country}}</td>
                                        <td>{{data.mobile_phone}}</td>
                                        <td>{{data.failure_reason}}</td>
                                    </tr>
                                    <tr class="active" *ngIf="rerrors.length==0">
                                        <td colspan="9" class="norecords-row">No records.</td>
                                    </tr>
                                </tbody>
                        </table>
                    </div>
                </div>
</div>
    </div>
</div>
</div>