<div mat-dialog-title class="relative">
  Edit Part
  <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
    <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="center-block" style="max-width: 600px;">
    <form class="form-horizontal form-control-normal" [formGroup]="editNewPart">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block">
        <div class="row">
          <div class="margin-bottom-15 col-sm-12 col-md-12 padding-0" *ngIf="currentPartDetails">
            <label for="part_name">Part Name</label>
            <div class='relative'>
              <mat-form-field class="btn-block car-input" floatPlaceholder="never">
                <input matInput placeholder="Part Name*" title="Part Name" formControlName="part_name"
                  class="form-control input-lg custom-form-input" (keydown)="checkIfValid()" />
                <mat-error *ngIf="editNewPart.controls.part_name.hasError('required')">
                  This field cannot be empty
                </mat-error>
              </mat-form-field>

            </div>
          </div>

          <div class="margin-bottom-15 col-sm-12 col-md-12 padding-0" *ngIf="currentPartDetails">
            <label for="part_number">Part Number</label>
            <div class='relative auto-complete-full'>
              <input matInput ngui-auto-complete (valueChanged)="partNumber($event)" [source]="observableSourcePN"
                [list-formatter]="myListFormatter" (click)="setReturnFalse()" placeholder="Part Number*"
                title="Part Number" formControlName="part_number" class="form-control input-lg custom-form-input"
                (keydown)="checkIfValid()" [(ngModel)]="currentPartDetails.part_number" />
              <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper"
                *ngIf="editNewPart.controls.part_number.hasError('required') && submitted">
                <mat-error>Part number is <strong>required</strong></mat-error>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="margin-bottom-15 col-sm-12 col-md-12 padding-0" *ngIf="currentPartDetails">
            <label for="cost">Part Cost</label>
            <div class='relative'>
              <mat-form-field class="btn-block car-input" floatPlaceholder="never">
                <input matInput placeholder="Part Cost*" title="Parts Cost" formControlName="cost"
                  class="form-control input-lg custom-form-input" maxlength="15"
                  (keydown)="disableKey($event);checkIfValid()" (keypress)="onlyDecimalNumberKey($event)" />
                <mat-error *ngIf="editNewPart.controls.cost.hasError('required')">
                  This field cannot be empty
                </mat-error>
                <mat-error *ngIf="editNewPart.controls.cost.invalid  && editNewPart.value.cost!=''">
                  Part Cost is required (must be a number with 2 decimal places).
                </mat-error>
              </mat-form-field>

            </div>
          </div>

          <div class="margin-bottom-15 col-sm-12 col-md-12 padding-0" *ngIf="currentPartDetails">
            <label for="price">Retail Price</label>
            <div class='relative'>
              <mat-form-field class="btn-block car-input" floatPlaceholder="never">
                <input matInput placeholder="Retail Price*" title="Retail Price" formControlName="price"
                  class="form-control input-lg custom-form-input" maxlength="15"
                  (keydown)="disableKey($event);checkIfValid()" (keypress)="onlyDecimalNumberKey($event)" />
                <mat-error *ngIf="editNewPart.controls.price.hasError('required')">
                  This field cannot be empty
                </mat-error>
                <mat-error *ngIf="editNewPart.controls.price.invalid  && editNewPart.value.price!=''">
                  Retail Cost is required (must be a number with 2 decimal places).
                </mat-error>
              </mat-form-field>

            </div>
          </div>
        </div>

        <div class="row">
          <div class="margin-bottom-15 col-sm-12 col-md-12 padding-0" *ngIf="currentPartDetails">
            <label for="vendor">Vendor</label>
            <div class='relative'>
              <mat-form-field class="btn-block car-input" floatPlaceholder="never">
                <input matInput placeholder="Vendor" title="Vendor" formControlName="vendor"
                  class="form-control input-lg custom-form-input" />
              </mat-form-field>
            </div>
          </div>

          <div class="margin-bottom-15 col-sm-12 col-md-12 padding-0" *ngIf="currentPartDetails">
            <label for="manufacturer">Manufacturer</label>
            <div class='relative'>
              <mat-form-field class="btn-block car-input" floatPlaceholder="never">
                <input matInput placeholder="Manufacturer" title="Manufacturer" formControlName="manufacturer"
                  class="form-control input-lg custom-form-input" />
              </mat-form-field>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="margin-bottom-15 col-sm-12 col-md-12 padding-0" *ngIf="currentPartDetails">
            <label for="oem_part_number">OEM Part Number</label>
            <div class='relative'>
              <mat-form-field class="btn-block car-input" floatPlaceholder="never">
                <input matInput placeholder="OEM Part Number" title="OEM Part Number" formControlName="oem_part_number"
                  class="form-control input-lg custom-form-input" />
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="margin-bottom-15 col-sm-12 padding-0" *ngIf="currentPartDetails">
            <label for="description">Description</label>
            <div class='relative'>
              <mat-form-field class="btn-block car-input" floatPlaceholder="never">
                <textarea matInput placeholder="Description" title="Description"
                  formControlName="description"></textarea>
              </mat-form-field>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-12 col-md-12">
            <label for="partPic">Part Picture</label>
            <div class="col-sm-12 file-upload-container" style="padding:0px;">
              <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative">
                <mat-icon class="margin-right-10">cloud_upload</mat-icon>
                Drag File Here
                <input type="file" id="partPic" name="part_pic" accept="image/*" (change)="getFile($event,'part_pic')"
                  formControlname="part_picture" />
                <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
              </mat-toolbar>
              <input id="partPicImagedata" name="part_pic" value="" type="hidden" formControlName="part_picture">
            </div>
            <div class="col-sm-12" style="padding:0px;">
              <ul class="list-inline upload-image margin-bottom-0">
                <li *ngIf="partPicture!='' && partPicture!=null && partPicture!=undefined && currentPartPicture==''">
                  <a href="javascript:void(0);" class="img-set thumbnail relative">
                    <img class="show-image" src="{{partsPic}}{{partPicture}}" />
                    <i class="fa fa-trash remove-img" (click)="deleteExistingPartPic()"></i>
                  </a>
                </li>
                <li *ngIf="currentPartPicture!=''">
                  <a href="javascript:void(0);" class="img-set thumbnail relative">
                    <img class="show-image" src="{{currentPartPicture}}" />
                    <i class="fa fa-trash remove-img" (click)="deletePartPic()"></i>
                  </a>
                </li>
              </ul>
              <p class="help-block" style="margin:0px;">Formats supported : png, jpeg, jpg</p>
              <p class="help-block" style="margin:0px;">Maximum size : 10MB</p>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="inquiry-action relative">
  <button mat-raised-button mat-dialog-close class="font-bold" title="Close">Close</button>
  <button mat-raised-button class="font-bold" (click)="editPart()" color="accent" title="Save Change">Save Change</button>
</mat-dialog-actions>