<div mat-dialog-title class="relative" >
    Reject Vehicle Transfer Request
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
        <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
</div>
<!-- BODY -->
<mat-dialog-content>
    <form class="form-horizontal form-control-normal" autocomplete="off" [formGroup]="rejectReason" (ngSubmit)="rejectRequest()">
        <div class="display-full margin-bottom-5">
            <div class="col-sm-12">
                <label for="title">Please enter reason for denial:</label>
                <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                    <textarea matInput placeholder="Rejection Reason" title="Rejection Reason" rows="5" formControlName="deny_reason" #notes (keyup)="checkIfValid()" maxlength="200"></textarea>
                    <mat-hint align="end">{{notes.value?.length || 0}}/200 Characters Max</mat-hint>
                    <mat-error *ngIf="rejectReason.controls.deny_reason.hasError('required')">
                        This field cannot be empty
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
      
        <div class="display-full margin-bottom-5">
          <div class="col-sm-12 text-right">
            <button type="button" mat-raised-button  color="accent" title="Ok" [disabled]="submitted" (click)="rejectRequest()">
                OK
            </button>
            <button mat-raised-button mat-dialog-close class="font-bold" title="Close">Close</button>
          </div>
      </div>
    </form>
</mat-dialog-content>
