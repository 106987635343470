import { Component, OnInit, AfterViewInit, EventEmitter, Output, Input } from '@angular/core';
import { GlobalService } from '../../../../environments/global.service';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss'],
})

export class EmployeeListComponent implements OnInit, AfterViewInit {
  
  @Output() closeTab = new EventEmitter<any>();
  @Input() employeeList;
  @Input() type;
  constructor(public globalService: GlobalService) {
  }
  
  ngAfterViewInit(): void {
  }

  ngOnInit() {
  }

  removeEmployee(index:number){
    // this.globalService.confirmDialog('delTech', "Are you sure?").subscribe((r) => {
    //   if (r != null) {
        this.employeeList.splice(index, 1);
        if(this.type === 'advance-report'){
          localStorage.setItem("advance-report-selected-employee",JSON.stringify(this.employeeList));
        }else if(this.type === 'business-metrics'){
          localStorage.setItem("business-selected-employee",JSON.stringify(this.employeeList));
        }
        this.sendOutput(3);
    //   }
    // });
  }

  sendOutput(isChanged: number = 0) {
    this.closeTab.emit(isChanged);
  }
}


