<div class="panel panel-primary">
    <div class="panel-heading padding-left-30">
        <h3 class="panel-title">GIVE YOUR RATINGS</h3>
    </div>
    <div class="panel-body bgcolor-lightgrey" *ngIf="!pageViewStatus">
        
        <div class="col-xs-12" autocomplete="off">
            <form [formGroup]="customerFeedBack" (ngSubmit)="saveFeedback()" id="customerFeedBack" class="form-inline col-xs-12" role="form" method="POST" enctype="multipart/form-data">
                <div class="col-xs-12 form-block padding-top-25 padding-bottom-25 rating-form">
                    <div class="row" *ngFor="let question of feedbackQuestion">
                        <div class="form-group col-lg-6">
                            <label>{{question.question}}</label>
                            <bar-rating rate="rating-{{question.id}}" (hover)="updateTitle(question.id, $event)" [max]="5" [theme]="'stars'" title="{{questionInfo[question.id].title}}" (rateChange)="saveRating(question.id, $event)"></bar-rating>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-6">
                            <label for="feedbackComment">Add a comment :</label>
                            <mat-form-field class="car-input btn-block" floatPlaceholder="never">
                                <textarea matInput placeholder="Add a comment" name="feedback_comment" formControlName="feedback_comment" rows="3"  #notes maxlength="500"></textarea>
                                <mat-hint align="end">{{notes.value?.length || 0}}/500 Characters Max</mat-hint>
                                <mat-error *ngIf="customerFeedBack.controls.feedback_comment.hasError('required')">
                                    This field is <strong>required.</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 form-block submit-block">
                        <button type="submit" mat-raised-button class="car-btn btn-lg font-bold btn-block" color="accent" title="Thanks For Your Feedback" style=" max-width: 300px; ">SUBMIT</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
        <div class="panel-body bgcolor-lightgrey" *ngIf="pageViewStatus">
        <div class="col-xs-12" autocomplete="off">
            <div id="customerFeedBack" class="form-inline col-xs-12">
                <div class="col-xs-12 form-block padding-top-25 padding-bottom-25 rating-form">
                    <div class="row" *ngFor="let question of feedbackQuestion">
                        <div class="form-group col-lg-6">
                            <label>{{question.question}}</label>
                            <bar-rating [(rate)]="question.rating" [readOnly]="true" [max]="5" [theme]="'stars'" title="{{questionInfo[question.id].title}}"></bar-rating>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-6">
                            <label for="feedbackComment">Comment :</label>
                            <p>{{questionInfo.feedback_comment}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
    </div>
</div>
