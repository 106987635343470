import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from '../../../../environments/global.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { globalConstants } from '../../../../environments/globalConstants';

@Component({
  selector: 'app-add-label-dialog',
  templateUrl: './add-label-dialog.component.html',
  styleUrls: ['./add-label-dialog.component.scss']
})

export class AddLabelDialogComponent implements OnInit {

  public labelForm: FormGroup;
  public allLabels = [];
  user: any = null;
  users: any = null;

  public isEdit = false;
  public isAdd = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, public gs: GlobalService, public dialogRef: MatDialogRef<AddLabelDialogComponent>) {
    this.user = this.gs.getCurrentUser();
  }
  ngOnInit() {
    if (!this.user) {
      this.dialogRef.close(2);
    }
    this.gs.diaLogObj(this.dialogRef);
    this.labelForm = this.formBuilder.group({
      label_id: [],
      label_name: ['', Validators.required],
      user_type: [this.user.user_type],
      user_id: [this.user.id]
    });
    this.getAllLabels();
  }

   // Add By Tafzeel //
   getAllLabels(){
    let data = {user_id : this.user.id}
    this.gs.callAPI(globalConstants.API_GET_SERVICE_REQUEST_LABELS_MASTER, data).subscribe((res:any)=> {
      if(res.result == 1){
        let customLabels = [
          {id: 'OTL', shop_id: '', label_name: 'OTL', add_date: '', modify_date: ''},
          {id: 'WAITING_FOR_PARTS', shop_id: '', label_name: 'Waiting For Parts', add_date: '', modify_date: ''},
          {id: 'ACCOUNTANT_VERIFIED', shop_id: '', label_name: 'Accountant Verified', add_date: '', modify_date: ''}
        ];
        this.allLabels = customLabels.concat(res.data);
        this.allLabels = this.gs.sortLabelsListInAlphabeticallyOrder(this.allLabels);
      }
    },(error)=>{});
  }

  addLabel(opt) {
    this.isEdit = false;
    if(opt == 1){
      this.isAdd = true;
    }else{
      this.submit(globalConstants.API_ADD_SERVICE_REQUEST_LABELS)
    }
  }

  submit(url) {
    if (this.labelForm.valid) {
      this.gs.callAPI(url, this.labelForm.value).subscribe((res:any) => {
        if (res.result == 1) {
          this.gs.snackbar("success", res.message);
          this.isEdit = false;
          this.isAdd = false;
          this.getAllLabels();
          this.labelForm.controls.label_id.reset();
          this.labelForm.controls.label_name.reset();
          // Object.keys(this.tagForm.controls).map((controlName) => {
          //   this.tagForm.get(controlName).reset();
          // });
        } else {
          this.gs.snackbar("error", res.message);
        }
      }, (error) => { })
    }
  }

  editLabel(label) {
    this.isEdit = true;
    this.isAdd = false;
    this.labelForm.controls.label_id.setValue(label.id);
    this.labelForm.controls.label_name.setValue(label.label_name);
  }
  submitEdit() {
    this.submit(globalConstants.API_EDIT_SERVICE_REQUEST_LABELS);
  }

  cancelAction() {
    this.isEdit = false;
    this.isAdd = false;
  }

}
