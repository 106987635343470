import { Component, OnInit, ViewEncapsulation, Inject,AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { globalFunctions } from '../../../../environments/globalFunctions';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
@Component({
    selector: 'vehicle-inspection-report',
    templateUrl: 'vehicle-inspection-report.html',
})
export class VehicleInspectionReport implements AfterViewInit{
    public checked = true;
    public allGreen = false;
    public allYellow = false;
    public allRed = false;
    public serviceRequestId;
    public report: FormGroup;
    public wearPatternLeft = [];
    public reportData;
    public twoBox = ['wear_pattern_damage_right', 'tires_alignment', 'tires_balance', 'wear_pattern_damage_left', 'tires_rotation'];
    constructor(public dialogRef: MatDialogRef<VehicleInspectionReport>, @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private globalService: GlobalService) {
        this.serviceRequestId = data.service_request_id;
        this.reportData = data.report_data;
        this.globalService.diaLogObj(this.dialogRef);
        this.initForm();
        this.setReportDaata();

    }
    ngAfterViewInit(){
        this.globalService.removeAutoFocus();
    }
    setReportDaata() {
        if (this.reportData) {
            for (const i in this.reportData) {
                try {
                    if (this.twoBox.indexOf(i) != -1) {
                        this.setPatternData(i, null);
                    }else if (this.reportData[i] == 0){
                        this.report.controls[i].setValue('');
                    }else{
                        this.report.controls[i].setValue(this.reportData[i]);
                    }

                } catch (e) {}
            }
        }

    }
    onNoClick(): void {
        this.dialogRef.close();
    }
    isChecked(type) {
        if (type == 'all_green') {
            return this.allGreen;
        } else if (type == 'all_yellow') {
            return this.allYellow;
        } else if (type == 'all_red') {
            return this.allRed;
        }
        return false;
    }
    checkAll(event, type) {
        let value="0"
        if (type == 'all_green') {
            value = "1";
            this.allGreen = true;
            this.allYellow = false;
            this.allRed = false;
        } else if (type == 'all_yellow') {
            value = "2"; this.allYellow = true;
            this.allGreen = false;
            this.allRed = false;
        } else if (type == 'all_red') {
            value = "3"; this.allRed = true;
            this.allGreen = false;
            this.allYellow = false;
        }
        if (event.checked == false) {
            value = "0";
            this.allGreen = false;
            this.allYellow = false;
            this.allRed = false;
        }
        this.report.controls.interior_ac.setValue(value);
        this.report.controls.interior_lighting.setValue(value);
        this.report.controls.interior_heating.setValue(value);
        this.report.controls.exterior_headlights.setValue(value);
        this.report.controls.exterior_brakelights.setValue(value);
        this.report.controls.exterior_turnsignals.setValue(value);
        this.report.controls.wiperblades_windshield_condition.setValue(value);
        this.report.controls.wiperblades_rear.setValue(value);
        this.report.controls.wiperblades_rf.setValue(value);
        this.report.controls.wiperblades_lf.setValue(value);
        this.report.controls.battery_charge.setValue(value);
        this.report.controls.battery_condition.setValue(value);
        this.report.controls.battery_cables.setValue(value);
        this.report.controls.tires_pressure.setValue(value);
        this.report.controls.fluids_engine_oil.setValue(value);
        this.report.controls.fluids_brake_fluid_reservoir.setValue(value);
        this.report.controls.fluids_transmission.setValue(value);
        this.report.controls.fluids_coolant_recovery.setValue(value);
        this.report.controls.fluids_power_steering.setValue(value);
        this.report.controls.fluids_windshield_washer.setValue(value);
        this.report.controls.brakes_noise.setValue(value);
        this.report.controls.brakes_excessive_dust.setValue(value);
        this.report.controls.brakes_front_linings.setValue(value);
        this.report.controls.brakes_hoses.setValue(value);
        this.report.controls.brakes_steel_line_condition.setValue(value);
        this.report.controls.brakes_rear_linings.setValue(value);
        this.report.controls.brakes_parking.setValue(value);
        this.report.controls.visible_leaks_fuel_system.setValue(value);
        this.report.controls.visible_leaks_engine_transmission.setValue(value);
        this.report.controls.visible_leaks_engine_cooling_system.setValue(value);
        this.report.controls.visible_leaks_shocks_struts.setValue(value);
        this.report.controls.visual_condition_belts.setValue(value);
        this.report.controls.visual_condition_hoses.setValue(value);
        this.report.controls.visual_condition_air_filters.setValue(value);
        this.report.controls.visual_condition_steering.setValue(value);
        this.report.controls.visual_condition_cvdrive.setValue(value);
        this.report.controls.visual_condition_exhaust.setValue(value);
    }
    initForm() {
        this.report = this.formBuilder.group({
            service_request_id: [this.serviceRequestId],
            inspectionReportType: [2],
            interior_ac: [''],
            interior_lighting: [''],
            interior_heating: [''],
            exterior_headlights: [''],
            exterior_brakelights: [''],
            exterior_turnsignals: [''],
            wiperblades_windshield_condition: [''],
            wiperblades_rear: [''],
            wiperblades_rf: [''],
            wiperblades_lf: [''],
            battery_charge: [''],
            battery_condition: [''],
            battery_cables: [''],
            tires_pressure: [''],
            fluids_engine_oil: [''],
            fluids_brake_fluid_reservoir: [''],
            fluids_transmission: [''],
            fluids_coolant_recovery: [''],
            fluids_power_steering: [''],
            fluids_windshield_washer: [''],
            brakes_noise: [''],
            brakes_excessive_dust: [''],
            brakes_front_linings: [''],
            brakes_hoses: [''],
            brakes_steel_line_condition: [''],
            brakes_rear_linings: [''],
            brakes_parking: [''],
            visible_leaks_fuel_system: [''],
            visible_leaks_engine_transmission: [''],
            visible_leaks_engine_cooling_system: [''],
            visible_leaks_shocks_struts: [''],
            visual_condition_belts: [''],
            visual_condition_hoses: [''],
            visual_condition_air_filters: [''],
            visual_condition_steering: [''],
            visual_condition_cvdrive: [''],
            visual_condition_exhaust: [''],
            wiperblades_cracks: [''],
            wiperblades_chips: [''],
            wear_pattern_damage_left: [''],
            wear_pattern_damage_left1: [''],
            wear_pattern_damage_left2: [''],
            wear_pattern_damage_right: [''],
            wear_pattern_damage_right1: [''],
            wear_pattern_damage_right2: [''],
            tires_balance: [''],
            tires_balance1: [''],
            tires_balance2: [''],
            tires_alignment: [''],
            tires_alignment1: [''],
            tires_alignment2: [''],
            tires_lf_psi_at: [''],
            tires_lf_psi_setto: [''],
            tires_lr_psi_at: [''],
            tires_lr_psi_setto: [''],
            tires_rf_psi_at: [''],
            tires_rf_psi_setto: [''],
            tires_rr_psi_at: [''],
            tires_rr_psi_setto: [''],
            tires_rotation: [''],
            tires_rotation1: [''],
            tires_rotation2: ['']



        });
    }
    addInspectionReport() {
        for (const i in this.report.value) {
            if (this.twoBox.indexOf(i) != -1) {
                this.getPatternData(i);
            }else if (this.report.value[i] == true){
                this.report.controls[i].setValue(1);
            }else if (this.report.value[i] == false){
                this.report.controls[i].setValue('');
            }
        }
    }

    saveReport() {

        const url = globalConstants.API_FILL_INSPECTION_REPORT_ACDELCO;
        this.addInspectionReport();
        let data = globalFunctions.stringifyFormData(this.report.value, this.globalService.getCurrentUser()['user_type']);
        data = data + '&user_id=' + this.globalService.getCurrentUser()['id'];
        this.globalService.callAPI(url, data).subscribe((result:any) => {
            if (result.result == '1') {
                this.dialogRef.close('success');
                this.globalService.snackbar('success', result.message);
            } else {
                this.globalService.snackbar('error', result.message);
            }
        });

        // inspectionReportType:1 //uspc
    }
    getPatternData(i) {
        if ((this.report.value[i + '1'] == 1 && this.report.value[i + '2'] == 2) || (this.report.value[i + '1'] == true && this.report.value[i + '2'] == true)) {
            this.report.controls[i].setValue(3);
        } else if ((this.report.value[i + '1'] == 1) || (this.report.value[i + '1'] == true)) {
            this.report.controls[i].setValue(1);
        } else if ((this.report.value[i + '2'] == 2) || (this.report.value[i + '2'] == true)) {
            this.report.controls[i].setValue(2);
        }else{
            this.report.controls[i].setValue('');
        }
    }
    setPatternData(i, value) {
        if (this.reportData[i] == 3) {
            this.report.controls[i + '1'].setValue(1);
            this.report.controls[i + '2'].setValue(2);
        } else if (this.reportData[i] == 1) {
            this.report.controls[i + '1'].setValue(1);
        } else if (this.reportData[i] == 2) {
            this.report.controls[i + '2'].setValue(2);
        }else if (this.reportData[i] == 0){
            this.report.controls[i].setValue('');
        }

    }
}
