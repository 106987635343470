<!--
  @author Shashank Thakur
  @trackID CAR-1419, CAR-1496
  @updated 20-03-2020
-->
<div class="panel panel-primary" id="mainCompotents">
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">ATTENTION SERVICE REQUEST LIST</h3>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <div class="display-full relative">
            <mat-expansion-panel hideToggle="false" [expanded]="openSearch"
                class="search-filter search-employee relative mat-elevation-z1">
                <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px">
                    <strong><i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
                </mat-expansion-panel-header>
                <form id="advanceSearchForm" class="collapse in" aria-expanded="true">
                    <div class="panel-body">
                        <div class="col-sm-12">
                            <div class="col-sm-3">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <mat-select class="form-control input-lg custom-form-input" options="searchByArray"
                                        [(ngModel)]="options.search_by" [ngModelOptions]="{standalone: true}"
                                        placeholder="Search By">
                                        <mat-option *ngFor="let opt of searchByArray" [value]="opt.id">{{opt.text}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-sm-3">
                                <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                    <input matInput placeholder="Search Keyword" title="Search Keyword"
                                        [(ngModel)]="options.search_keyword" [ngModelOptions]="{standalone: true}"
                                        class="form-control input-lg custom-form-input" />
                                </mat-form-field>
                            </div>

                            <div class="col-sm-3">
                                <mat-form-field class="car-input" floatPlaceholder="never" appearance="fill" style="width: 100%;">
                                    <input placeholder="Search Employee" matInput title="Select Employee"
                                        class="form-control input-lg custom-form-input" [(ngModel)]="empSelected"
                                        [formControl]="myControl" [matAutocomplete]="auto" style=" height: 40px;" />
                                    <mat-autocomplete #auto="matAutocomplete"
                                        (optionSelected)="SetOptionAsEmployeeId($event)"
                                        [displayWith]="empDisplayFn.bind(this)">
                                        <mat-option class="employee-dropdown" *ngFor="let option of filterOptionsSet | async" [value]="option">
                                            {{ option.first_name }}
                                            {{ option.last_name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <span>
                                    <button type="button" class="search_btn" mat-button *ngIf="options.emp_id" matSuffix
                                        mat-icon-button aria-label="Clear" (click)="options.emp_id='';empSelected=''">
                                        <mat-icon id="matico">close</mat-icon>
                                    </button>
                                </span>
                            </div>
                            <div class="col-sm-3 col-lg-3 uc-ngx-select">
                                <ngx-select class="display-full margin-bottom-20" [items]="filterOption"
                                    [defaultValue]="[]" [noAutoComplete]="true" placeholder="Please Select"
                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="options.search_days">
                                </ngx-select>
                            </div>
                            <ng-container *ngIf="options.search_days=='3'">
                                <div class="col-sm-3 col-lg-3 calendar-icon">
                                    <mat-form-field class="car-input btn-block car-datePicker" floatPlaceholder="never" appearance="fill">
                                        <input (dateChange)="options.from_date =$event.target.value" matInput
                                            class="form-control input-lg custom-form-input"
                                            [(ngModel)]="options.from_date" 
                                            [ngModelOptions]="{standalone: true}" placeholder="From Date"
                                            [matDatepicker]="filerPicker1" onkeydown="return false;">
                                        <mat-datepicker-toggle matSuffix [for]="filerPicker1"></mat-datepicker-toggle>
                                        <mat-datepicker #filerPicker1 [touchUi]="true"></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-3 col-lg-3 calendar-icon">
                                    <mat-form-field class="car-input btn-block car-datePicker" floatPlaceholder="never" appearance="fill">
                                        <input matInput class="form-control input-lg custom-form-input"
                                            [(ngModel)]="options.to_date" [min]="options.from_date"
                                            [ngModelOptions]="{standalone: true}" placeholder="To Date"
                                            [matDatepicker]="filerPicker2" onkeydown="return false;">
                                        <mat-datepicker-toggle matSuffix [for]="filerPicker2"></mat-datepicker-toggle>
                                        <mat-datepicker #filerPicker2 [touchUi]="true"></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </ng-container>

                        </div>
                    </div>
                    <mat-action-row class="search-filter-footer">
                        <button mat-raised-button color="accent" type="submit" title="Search" (click)="search()">
                            Search
                        </button>
                    </mat-action-row>
                </form>
            </mat-expansion-panel>
            <button mat-button type="button" title="Clear Filter" #clearBtn class="clear-btn" (click)="clearSearch()">
                Clear Filter
            </button>
        </div>

        <div class="car-datatable uc-datatable mat-elevation-z1">
            <div class="text-right hidden">
                <mat-form-field floatPlaceholder="never" appearance="fill">
                    <input matInput #filter placeholder="Filter users">
                </mat-form-field>
            </div>

            <div class="table-responsive relative">
                <mat-table #table [dataSource]="dataSource" matSort (matSortChange)="changeSortParams($event)"
                    class="uc-table-responsive service-list datatable-loader-bg">

                    <!-- Color Column -->
                    <ng-container matColumnDef="request_id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="request_id"> Req. Id
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="request_id" data-val="Req. Id"
                            style="max-width: 160px;">
                            {{row.request_id}}
                        </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="name"> Cust. Name
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="name" data-val="Title"> {{row.name ? row.name:
                            "N/A"}}
                        </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="username">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="username"> Employee Name
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="username" data-val="Title"> {{row.username ?
                            row.username:
                            "N/A"}}
                        </mat-cell>
                    </ng-container>

                    <!-- Color Column -->


                    <ng-container matColumnDef="start_date">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="start_date"> Start Date
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="start_date" data-val="Start Date">
                            {{ convertISODate(row.start_date) | date:'M/d/yy h:mm a' }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="end_date">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="end_date"> End Date
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="end_date" data-val="End Date">
                            {{ convertISODate(row.end_date) | date:'M/d/yy h:mm a' }}
                        </mat-cell>
                    </ng-container>
                    <!-- Color Column -->
                    <ng-container matColumnDef="conflict_reason">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="conflict_reason"> Conflict Reason
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="conflict_reason" data-val="Conflict Reason">
                            <span title="{{(row.reason_description ?
                                row.reason_description :'N/A')}}">{{(row.reason_title ?
                                row.reason_title :"N/A")}} </span>
                        </mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="requested_delivery_date">
                        <mat-header-cell *matHeaderCellDef mat-sort-header data-role="requested_delivery_date">
                            Requested Date
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="requested_delivery_date" data-val="App. Date">
                            {{ convertISODate(row.requested_delivery_date) | date:'M/d/yy h:mm a' }}</mat-cell>
                    </ng-container>

                    <!-- Color Column -->
                    <ng-container matColumnDef="Actions">
                        <mat-header-cell *matHeaderCellDef data-role="button"> Actions </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-role="button">
                            <button mat-button class="list-action-btn" [matMenuTriggerFor]="menu" disableRipple>
                                Action
                                <mat-icon>arrow_drop_down</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu" class="car-icon-menu" [overlapTrigger]="false">
                                <a class="mat-menu-item" (click)="asssignetoOther(row)"><i class="fa fa-edit"></i>Assign
                                    to Other</a>
                                <a class="mat-menu-item" (click)="adjustTiming(row)"><i class="fa fa-edit"></i>Adjust
                                    Schedule</a>
                                <a class="mat-menu-item" (click)="removeAssigne(row)"><i class="fa fa-trash"></i>Delete
                                    Assignee</a>
                                <a class="mat-menu-item"
                                    *ngIf="row.conflict_reason =='halfday_leave' || row.conflict_reason =='fullday_leave'"
                                    (click)="deleteLeave(row)"><i class="fa fa-trash"></i>Delete Leave</a>
                                <a class="mat-menu-item" (click)="deleteConflict(row)"><i class="fa fa-trash"></i>Delete</a>
                            </mat-menu>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>

                <div class="padding-15 text-center" [style.display]="getTotalRecords() < 1 ? '' : 'none'"> No Records
                    Available </div>
            </div>

            <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
                <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0"
                    [pageSize]="options.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="paginateByLimit($event)">
                </mat-paginator>

                <div class="mat-paginator table-paging">
                    <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} -
                        {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}
                    </div>
                    <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                        <mat-icon>navigate_before</mat-icon>
                    </button>
                    <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                        <mat-icon>navigate_next</mat-icon>
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>
<!--
Ticket Assignment SidePanel integration
-->
<app-tech-assignment [data]="techData" (closeTab)="techAssignmentOut($event)" *ngIf="(techData | json) != '{}'"></app-tech-assignment>