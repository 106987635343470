<div class="panel panel-primary employee-details-view" id="mainCompotents">
  <div class="panel-heading padding-left-15">
    <h3 class="panel-title">View Employee Detail</h3>
    <button mat-mini-fab class="custom-btn-back" (click)="gs.goBack();">
        <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <div class="panel-body bgcolor-lightgrey">
    <div class="row">

      <div class="col-md-4 text-right"></div>
      <div class="col-md-4 text-right"></div>
      <div class="col-md-2 text-right pull-right" *ngIf="empId != currentUserInfo.id && isAllowed('edits')">
        <p><a href="javascript:void(0)" class="hover-link" (click)="editEmp()"><i class="fa fa-edit fa-lg margin-right-5"></i>Edit</a>
        </p>
      </div>
      <div class="col-md-2 text-right pull-right">
        <p><a href="javascript:void(0);" class="hover-link" (click)="editContacts()"><i class="fa fa-vcard fa-lg margin-right-5"></i> Contacts</a>
        </p>
      </div>
      
    </div>
    <div class="jumbotron padding-25 car-view-str">
      <!-- Replaced div grid structure with table -->
      <div class="row">
        <div class="col-sm-8">
          <h3 class="margin-top-0 font-24">Employee Information</h3>
        </div>
        <div class="col-sm-4">
          <!-- <div class="text-right" *ngIf="photo!=''">
            <img src="{{docImgPath}}{{empData.emp_photo.src}}" />
          </div> -->
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-sm-6 margin-bottom-10">
          <div class="row">
            <div class="col-xs-6 col-sm-6"><label>Name</label></div>
            <div class="col-xs-6 col-sm-6 break-word">{{empData.first_name}} {{empData.middle_name}} {{empData.last_name}}</div>
          </div>
        </div>
        <div class="col-sm-6 margin-bottom-10">
          <div class="row">
            <div class="col-xs-6 col-sm-6"><label>Email</label></div>
            <div class="col-xs-6 col-sm-6 break-word">
              <a href="mailto:{{empData.email}}" class="text-black">{{empData.email}}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 margin-bottom-10">
          <div class="row">
            <div class="col-xs-6 col-sm-6"><label>Address</label></div>
            <div class="col-xs-6 col-sm-6 break-word"> {{ (empData.address1 || empData.address2) ? (empData.address1 + ' ' + empData.address2) : '-' }}</div>
          </div>
        </div>
        <div class="col-sm-6 margin-bottom-10">
          <div class="row">
            <div class="col-xs-6 col-sm-6"><label>City</label></div>
            <div class="col-xs-6 col-sm-6 break-word">{{empData.city || '-'}}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 margin-bottom-10">
          <div class="row">
            <div class="col-xs-6 col-sm-6"><label>State</label></div>
            <div class="col-xs-6 col-sm-6">{{ empData.state || '-' }}</div>
          </div>
        </div>
        <div class="col-sm-6 margin-bottom-10">
          <div class="row">
            <div class="col-xs-6 col-sm-6"><label>Country</label></div>
            <div class="col-xs-6 col-sm-6 break-word">{{empData.country}}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 margin-bottom-10">
          <div class="row">
            <div class="col-xs-6 col-sm-6"><label>Zip Code</label></div>
            <div class="col-xs-6 col-sm-6">{{empData.zip}}</div>
          </div>
        </div>
        <div class="col-sm-6 margin-bottom-10">
          <div class="row">
            <div class="col-xs-6 col-sm-6"><label>Mobile Phone</label></div>
            <div class="col-xs-6 col-sm-6 break-word"><a href="tel:{{empData.cell_phone1}}" class="text-black">{{empData.cell_phone1 || '-'}}</a></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 margin-bottom-10">
          <div class="row">
            <div class="col-xs-6 col-sm-6"><label>Employee Type</label></div>
            <div class="col-xs-6 col-sm-6">{{empData.role_name}}</div>
          </div>
        </div>
        <div class="col-sm-6 margin-bottom-10">
          <div class="row">
            <div class="col-xs-6 col-sm-6"><label>Date of Birth</label></div>
            <div class="col-xs-6 col-sm-6 break-word">{{empData.dob || '-'}}</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 margin-bottom-10">
          <div class="row">
            <div class="col-xs-6 col-sm-6"><label>Hours wages</label></div>
            <div class="col-xs-6 col-sm-6">{{empData.hours_wages}}</div>
          </div>
        </div>
        <div class="col-sm-6 margin-bottom-10">
          <div class="row">
            <div class="col-xs-6 col-sm-6"><label>Hours week</label></div>
            <div class="col-xs-6 col-sm-6 break-word">{{empData.hours_week}} </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 margin-bottom-10">
          <div class="row">
            <div class="col-xs-6 col-sm-6"><label>Week wages</label></div>
            <div class="col-xs-6 col-sm-6">{{empData.week_wages}}</div>
          </div>
        </div>
        <div class="col-sm-6 margin-bottom-10">
          <div class="row">
            <div class="col-xs-6 col-sm-6"><label>Report Generation</label></div>
            <div class="col-xs-6 col-sm-6">
              <span *ngIf="empData.is_report_generation=='1'">Yes</span>
              <span *ngIf="empData.is_report_generation=='0'">No</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 margin-bottom-10">
          <div class="row">
            <div class="col-sm-6"><label>Photo</label></div>
            <div class="col-sm-6">
              <ul class="list-inline upload-image margin-bottom-0" *ngIf="photo!=''">
                <li>
                  <a href="javascript:void(0);" (click)="imageToolDialog($event,0,11,[empData.emp_photo])" class="img-set thumbnail relative">
                        <img class="show-image" src="{{docImgPath}}{{empData.emp_photo.src}}" />
                    </a>
                </li>
              </ul>
              <p *ngIf="photo === ''" class="pull-left">-</p>
            </div>
          </div>
        </div>
        <div class="col-sm-6 margin-bottom-10">
          <div class="row">
            <div class="col-sm-6"><label>Driving License</label></div>
            <div class="col-sm-6">
              <ul class="list-inline upload-image margin-bottom-0">
                <li *ngFor="let license of licenseDoc;let i=index">
                  <a href="javascript:void(0);" *ngIf="license.type==1" (click)="imageToolDialog($event,0,11,[licenseDoc[i]]);$event.stopPropagation();" class="img-set thumbnail relative">
                    <img class="show-image" *ngIf="license.type==1" src="{{docImgPath}}{{license.src}}" />
                  </a>
                  <a href="javascript:void(0);" *ngIf="license.type==3 || license.type==4" (click)="invokeDownload(docPath+license.src,'EmployeeDocument',license.src);$event.stopPropagation();" class="img-set thumbnail relative">
                      <span class="fa far fa-file-pdf fa-4x" *ngIf="license.type==3"></span>
                      <span class="fa far fa-file-word fa-4x" *ngIf="license.type==4"></span>
                  </a>
                </li>
              </ul>
              <p *ngIf="licenseDoc && licenseDoc.length == 0" class="pull-left">-</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 margin-bottom-10">
          <div class="row">
            <div class="col-sm-6"><label>Documents</label></div>
            <div class="col-sm-6">
              <ul class="list-inline upload-image margin-bottom-0">
                <li *ngFor="let doc of docs;let i=index;">
                  <a href="javascript:void(0);" *ngIf="doc.type==1" (click)="imageToolDialog($event,0,11,[docs[i]]);$event.stopPropagation();" class="img-set thumbnail relative">
                    <img class="show-image" src="{{docImgPath}}{{doc.src}}" />
                  </a>
                  <a href="javascript:void(0);" *ngIf="doc.type==3 || doc.type==4" (click)="invokeDownload(docPath+doc.src,'EmployeeDocument',doc.src);$event.stopPropagation();" class="img-set thumbnail relative">
                      <span class="fa far fa-file-pdf fa-4x" *ngIf="doc.type==3"></span>
                      <span class="fa far fa-file-word fa-4x" *ngIf="doc.type==4"></span>
                  </a>
                </li>
              </ul>
              <p *ngIf="docs && docs.length == 0" class="pull-left">-</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>