
export const PREFIX ="CAR::";
export const lang = {
  CUSTOMER:{
    LOGIN:{
      METADATA:{
        TITLE:PREFIX+"Login",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    SIGNUP:{
      METADATA:{
        TITLE:PREFIX+"Sign Up",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    DASHBOARD:{
      METADATA:{
        TITLE:PREFIX+"Dashboard",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    EDIT_PROFILE:{
      METADATA:{
        TITLE:PREFIX+"Edit Profile Details",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    LIST_SERVICE_REQUEST:{
      METADATA:{
        TITLE:PREFIX+" Facility Service Requests List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    LIST_CARS:{
      METADATA:{
        TITLE:PREFIX+" My Vehicles",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"List CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    ADD_CARS:{
      METADATA:{
        TITLE:PREFIX+" Add New Car",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    VIEW_CAR:{
      METADATA:{
        TITLE:PREFIX+" Car Details",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    VIEW_REQUEST:{
      METADATA:{
        TITLE:PREFIX+" Service Request Detail",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    NEW_REQUEST:{
      METADATA:{
        TITLE:PREFIX+" Request Creation",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    VIEW_DIY_REQUEST:{
      METADATA:{
        TITLE:PREFIX+"DIY Requests",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    VIEW_SERVICE_COMPLAINT:{
      METADATA:{
        TITLE:PREFIX+" Service Message Detail",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    NEW_SERVICE_FACILITY:{
      METADATA:{
        TITLE:PREFIX+" Add Service Facility",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    EDIT_SERVICE_FACILITY:{
      METADATA:{
        TITLE:PREFIX+" Edit Service Facility",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    VIEW_SERVICE_FACILITY:{
      METADATA:{
        TITLE:PREFIX+" My Service Facilities",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    ADD_INQUIRY:{
      METADATA:{
        TITLE:PREFIX+" Start Live Chat",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    VIEW_ALL_INQURIES:{
      METADATA:{
        TITLE:PREFIX+" Live Chat List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    FORGOT_PASSWORD:{
      METADATA:{
        TITLE:PREFIX+"Forgot Password",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    SUBSCRIPTION:{
      METADATA:{
        TITLE:PREFIX+"Subscription",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    CONFIRM_SIGNUP:{
      METADATA:{
        TITLE:PREFIX+"Confirm Signup",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    TRANSFERRED_VEHICLES:{
      METADATA:{
        TITLE:PREFIX+" Transferred Vehicles",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    TRANSFERRED_OWNERSHIP:{
      METADATA:{
        TITLE:PREFIX+" Transfer Ownership Requests",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    EDIT_CAR:{
      METADATA:{
        TITLE:PREFIX+" Edit Car",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    SERVICE_REQUESTS_HISTORY:{
      METADATA:{
        TITLE:PREFIX+" Service Requests List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    SERVICE_REQUEST_DETAIL:{
      METADATA:{
        TITLE:PREFIX+" Service Request Detail",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    TRANSFER_OWNERSHIP:{
      METADATA:{
        TITLE:PREFIX+" Transfer Ownership",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    DIY_SERVICE_REQUESTS_LIST:{
      METADATA:{
        TITLE:PREFIX+" DIY Service Requests List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    EDIT_SERVICE_REQUEST:{
      METADATA:{
        TITLE:PREFIX+" Edit Request",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    SERVICE_COMPLAINT_LIST:{
      METADATA:{
        TITLE:PREFIX+" Service Messages List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    VIEW_INQURY_DETAIL:{
      METADATA:{
        TITLE:PREFIX+" Live Chat Detail",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    FEEDBACK:{
      METADATA:{
        TITLE:PREFIX+" Give Your Ratings",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    WAK:{
      METADATA:{
        TITLE:PREFIX+" WAK",
          AUTHOR:"CAR",
          DESCRIPTION:"Complete Auto Reports, CAR in short",
          KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    QUICK_QUOTE_LIST:{
      METADATA:{
        TITLE:PREFIX+" Quick Quote List",
          AUTHOR:"CAR",
          DESCRIPTION:"Complete Auto Reports, CAR in short",
          KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    }
  },
  SHOPS:{
    LOGIN:{
      METADATA:{
        TITLE:PREFIX+" Shop Login",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    DASHBOARD:{
      METADATA:{
        TITLE:PREFIX+" Shop Dashboard",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    EDIT_PROFILE:{
      METADATA:{
        TITLE:PREFIX+" Edit Shop",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    ADD_NEW_CUSTOMER:{
      METADATA:{
        TITLE:PREFIX+" Add New Customer",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    VIEW_CUSTOMERS:{
      METADATA:{
        TITLE:PREFIX+" View Customer Details",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    IMPORT_CUSTOMERS:{
      METADATA:{
        TITLE:PREFIX+" Import Contacts",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    LIST_SERVICE_REQUEST:{
      METADATA:{
        TITLE:PREFIX+"Service Requests List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    MANAGE_COMPLAINT:{
      METADATA:{
        TITLE:PREFIX+"Service Messages List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    NEW_PART:{
      METADATA:{
        TITLE:PREFIX+" Add New Part",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    LIST_PARTS:{
      METADATA:{
        TITLE:PREFIX+" Parts List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    ADD_GENERIC_JOB:{
      METADATA:{
        TITLE:PREFIX+" Add New Job",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    LIST_GENERIC_JOB:{
      METADATA:{
        TITLE:PREFIX+" Generic Jobs List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    SALES_REPORT:{
      METADATA:{
        TITLE:PREFIX+" Sales Report",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    ADVANCED_SALES_REPORT:{
      METADATA:{
        TITLE:PREFIX+" Advanced Report",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    BUSINESS_METRICS:{
      METADATA:{
        TITLE:PREFIX+" Business Metrics",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    EMPLOYEE_REPORT_LOGS:{
      METADATA:{
        TITLE:PREFIX+" Employee Report",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    OVERDUE_REPORT:{
      METADATA:{
        TITLE:PREFIX+" Overdue Balances Report",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    ADD_COMPUTER_CODES:{
      METADATA:{
        TITLE:PREFIX+" Add New Computer Code",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    LIST_COMPUTER_CODES:{
      METADATA:{
        TITLE:PREFIX+" Computer Code List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    }
    ,
    ADD_DISCLAIMER:{
      METADATA:{
        TITLE:PREFIX+" Add New Disclaimer",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    LIST_DISCLAIMER:{
      METADATA:{
        TITLE:PREFIX+" Disclaimers List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    MANAGE_LABOUR_RATES:{
      METADATA:{
        TITLE:PREFIX+"Manage Labor Rates",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    MANAGE_MAILCHIMP:{
      METADATA:{
        TITLE:PREFIX+"Manage Connections-Mailchimp",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    LIST_SHOP_INQURIES:{
      METADATA:{
        TITLE:PREFIX+"Live Chat List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    ADD_NEW_EMPLOYEE:{
      METADATA:{
        TITLE:PREFIX+" Add New Employee",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    EDIT_EMPLOYEE:{
      METADATA:{
        TITLE:PREFIX+" Edit Employee",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    VIEW_SERVICE_COMPLAINT:{
      METADATA:{
        TITLE:PREFIX+"Service Message Detail",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    INQUIRY_DETAIL:{
      METADATA:{
        TITLE:PREFIX+"Live Chat Detail",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    LIST_CUSTOMERS:{
      METADATA:{
        TITLE:PREFIX+" Customers List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    EDIT_CUSTOMERS:{
      METADATA:{
        TITLE:PREFIX+" Edit Customer Details",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    SERVICE_REQUEST_DETAIL:{
      METADATA:{
        TITLE:PREFIX+" Service Request Detail",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    EDIT_SERVICE_REQUEST:{
      METADATA:{
        TITLE:PREFIX+" Edit Service Request",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    EDIT_MY_PROFILE:{
      METADATA:{
        TITLE:PREFIX+" Edit Profile",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    LIST_CUSTOMER_CARS:{
      METADATA:{
        TITLE:PREFIX+" List Customer Cars",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"List CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    ADD_NEW_CAR:{
      METADATA:{
        TITLE:PREFIX+" Add New Car",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    NEW_REQUEST_CREATION:{
      METADATA:{
        TITLE:PREFIX+" Request Creation",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    CALENDER_VIEW:{
      METADATA:{
        TITLE:PREFIX+" Calendar View",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    EASY_CHECK_IN:{
      METADATA:{
        TITLE:PREFIX+" Easy Check In",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    SERVICE_REQUEST_LOGS:{
      METADATA:{
        TITLE:PREFIX+" Service Request Logs",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    VIEW_CAR_DETAIL:{
      METADATA:{
        TITLE:PREFIX+" Car Details",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    EDIT_CAR_DETAIL:{
      METADATA:{
        TITLE:PREFIX+" Edit Car",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    SERVICE_REQUESTS_HISTORY:{
      METADATA:{
        TITLE:PREFIX+" Service Requests History",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    SUBSCRIPTION:{
      METADATA:{
        TITLE:PREFIX+" Subscription",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    EDIT_PART:{
      METADATA:{
        TITLE:PREFIX+" Edit Part",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    PARTS_LOGS:{
      METADATA:{
        TITLE:PREFIX+" Parts Logs",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    PART_LOG_DETAILS:{
      METADATA:{
        TITLE:PREFIX+" Part Log Details",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    STOCK_ORDERES_LISTING:{
      METADATA:{
        TITLE:PREFIX+" Stock Orders Listing",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    STOCK_ORDER_DETAILS:{
      METADATA:{
        TITLE:PREFIX+" Stock Order Details",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    STOCK_ORDER_PARTSTECH:{
      METADATA:{
        TITLE:PREFIX+" Stock Order From Partstech",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    EDIT_DISCLAIMER:{
      METADATA:{
        TITLE:PREFIX+" Edit Disclaimer",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    EDIT_GENERIC_JOB:{
      METADATA:{
        TITLE:PREFIX+" Edit Generic Job",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    MANAGE_CONN_PARTSTECH:{
      METADATA:{
        TITLE:PREFIX+" Partstech",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    EDIT_COMPUTER_CODES:{
      METADATA:{
        TITLE:PREFIX+" Edit Computer Code",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    ADD_VENDOR:{
      METADATA:{
        TITLE:PREFIX+" Add New Vendor",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    EDIT_VENDOR:{
      METADATA:{
        TITLE:PREFIX+" Edit Vendor",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    VENDORS_LIST:{
      METADATA:{
        TITLE:PREFIX+" Vendors List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    ADD_NEW_RESOURCE:{
      METADATA:{
        TITLE:PREFIX+" Add New Resource",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    EDIT_RESOURCE:{
      METADATA:{
        TITLE:PREFIX+" Edit Resource",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    RESOURCES_LIST:{
      METADATA:{
        TITLE:PREFIX+" Resources List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    EMPLOYEE_LIST:{
      METADATA:{
        TITLE:PREFIX+" Employee List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    VIEW_EMPLOYEE_DETAIL:{
      METADATA:{
        TITLE:PREFIX+" View Employee Detail",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    EMPLOYEE_REPORT:{
      METADATA:{
        TITLE:PREFIX+" Report",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    MANAGE_EMPLOYEE_PERMISSIONS:{
      METADATA:{
        TITLE:PREFIX+" Manage Employee Permission",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    MANAGE_ROLES_PERMISSIONS:{
      METADATA:{
        TITLE:PREFIX+" Manage Roles & Permissions",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    RATINGS:{
      METADATA:{
        TITLE:PREFIX+" Ratings for Service Request",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    NOTIFICATIONS:{
      METADATA:{
        TITLE:PREFIX+" Notifications",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    PAYMENT_SUCESS:{
      METADATA:{
        TITLE:PREFIX+" Payment Status",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    IMPORT_PARTS:{
      METADATA:{
        TITLE:PREFIX+" Import Parts",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    IMPORT_PARTS_LIST:{
      METADATA:{
        TITLE:PREFIX+" Parts Import List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    IMPORT_PARTS_DETAILS:{
      METADATA:{
        TITLE:PREFIX+" Parts Import Details",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    QUEUE_ORDER_LIST:{
      METADATA:{
        TITLE:PREFIX+" Order Queue List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    QUEUE_ORDER_DETAIL:{
      METADATA:{
        TITLE:PREFIX+" Order Queue Details",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    RETURN_QUEUE_DETAIL:{
      METADATA:{
        TITLE:PREFIX+" Return Queue Details",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    RETURN_QUEUE_LIST:{
      METADATA:{
        TITLE:PREFIX+" Return Queue List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    PART_NUMBER:{
      METADATA:{
        TITLE:PREFIX+" Add New Smart Batch",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    WAK:{
      METADATA:{
        TITLE:PREFIX+" WAK",
          AUTHOR:"CAR",
          DESCRIPTION:"Complete Auto Reports, CAR in short",
          KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    SMART_BATCH_LIST:{
      METADATA:{
        TITLE:PREFIX+" Smart Batch List",
          AUTHOR:"CAR",
          DESCRIPTION:"Complete Auto Reports, CAR in short",
          KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    ADD_SMART_BATCH:{
      METADATA:{
        TITLE:PREFIX+" Add New Smart Batch",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    EDIT_SMART_BATCH:{
      METADATA:{
        TITLE:PREFIX+" Edit Smart Batch",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    PART_MATRIX_SETTING:{
      METADATA:{
        TITLE:PREFIX+" Part Matrix Setting",
          AUTHOR:"CAR",
          DESCRIPTION:"Complete Auto Reports, CAR in short",
          KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    INTERNAL_NOTES_LIST:{
      METADATA:{
        TITLE:PREFIX+" Internal Note List",
          AUTHOR:"CAR",
          DESCRIPTION:"Complete Auto Reports, CAR in short",
          KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    INTERNAL_NOTES_DETAIL:{
      METADATA:{
        TITLE:PREFIX+" Internal Note Detail",
          AUTHOR:"CAR",
          DESCRIPTION:"Complete Auto Reports, CAR in short",
          KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    EDIT_INTERNAL_NOTE:{
      METADATA:{
        TITLE:PREFIX+" Edit Internal Note",
          AUTHOR:"CAR",
          DESCRIPTION:"Complete Auto Reports, CAR in short",
          KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    QUICK_QUOTE_LIST:{
      METADATA:{
        TITLE:PREFIX+" Quick Quote List",
          AUTHOR:"CAR",
          DESCRIPTION:"Complete Auto Reports, CAR in short",
          KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    INSPECT_LIST:{
      METADATA:{
        TITLE:PREFIX+" Inspection List",
          AUTHOR:"CAR",
          DESCRIPTION:"Complete Auto Reports, CAR in short",
          KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    SHOP_DISCOUNTS_LIST:{
      METADATA:{
        TITLE:PREFIX+" Discounts List",
          AUTHOR:"CAR",
          DESCRIPTION:"Complete Auto Reports, CAR in short",
          KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    ADD_DISCOUNT:{
      METADATA:{
        TITLE:PREFIX+" Add Discount",
          AUTHOR:"CAR",
          DESCRIPTION:"Complete Auto Reports, CAR in short",
          KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    EDIT_DISCOUNT:{
      METADATA:{
        TITLE:PREFIX+" Edit Discount",
          AUTHOR:"CAR",
          DESCRIPTION:"Complete Auto Reports, CAR in short",
          KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    NEXPART_VENDORS_LIST:{
      METADATA:{
        TITLE:PREFIX+" Nexpart Vendors List",
          AUTHOR:"CAR",
          DESCRIPTION:"Complete Auto Reports, CAR in short",
          KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    ADD_NEXPART_VENDOR:{
      METADATA:{
        TITLE:PREFIX+" Add Nexpart Vendor",
          AUTHOR:"CAR",
          DESCRIPTION:"Complete Auto Reports, CAR in short",
          KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    EDIT_NEXPART_VENDOR:{
      METADATA:{
        TITLE:PREFIX+" Edit Nexpart Vendor",
          AUTHOR:"CAR",
          DESCRIPTION:"Complete Auto Reports, CAR in short",
          KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    SMS_TEMPLATE_LIST:{
      METADATA:{
        TITLE:PREFIX+"  SMS Templates",
          AUTHOR:"CAR",
          DESCRIPTION:"Complete Auto Reports, CAR in short",
          KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    NEWDASHBOARD:{
      METADATA:{
        TITLE:PREFIX+" Dashboard",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    VEHICLESLIST:{
      METADATA:{
        TITLE:PREFIX+" Shop Vehicles List",
        AUTHOR:"CAR",
        DESCRIPTION:"Showing all vehicles list in shops",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    LASTSERVICELIST:{
      METADATA:{
        TITLE:PREFIX+" Last Service List",
        AUTHOR:"CAR",
        DESCRIPTION:"Showing all vehicles list in shops which are serviced",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    DUEFORERVICELIST:{
      METADATA:{
        TITLE:PREFIX+" Due For Service List",
        AUTHOR:"CAR",
        DESCRIPTION:"Showing all vehicles list in shops which service is due",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    SHOPPROFILE:{
      METADATA:{
        TITLE:PREFIX+" Shop Profile",
        AUTHOR:"CAR",
        DESCRIPTION:"Showing shop related information",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    JOBBOARD:{
      METADATA:{
        TITLE:PREFIX+" Job Board",
        AUTHOR:"CAR",
        DESCRIPTION:"Showing all job in one place and its easy to change status",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    ONTHELOTJOBBOARD:{
      METADATA:{
        TITLE:PREFIX+" Job Board - On The Lot",
        AUTHOR:"CAR",
        DESCRIPTION:"Showing all on the lot job in one place and its easy to change status",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    MYWORKLOADJOBBOARD:{
      METADATA:{
        TITLE:PREFIX+" Job Board - My Job Queue",
        AUTHOR:"CAR",
        DESCRIPTION:"Showing all assigned job in one place and its easy to change status",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    SELFCHECKIN:{
      METADATA:{
        TITLE:PREFIX+" Self Check-in",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
  },
  ADMIN:{
    LOGIN:{
      METADATA:{
        TITLE:PREFIX+" Admin",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    DASHBOARD:{
      METADATA:{
        TITLE:PREFIX+" Dashboard",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },ADD_NEW_SHOP:{
      METADATA:{
        TITLE:PREFIX+" Add New Shop",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },SHOPS_LIST:{
      METADATA:{
        TITLE:PREFIX+" Shops List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },ADD_NEW_PART:{
      METADATA:{
        TITLE:PREFIX+" Add New Part",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },ADD_NEW_JOB:{
      METADATA:{
        TITLE:PREFIX+" Add New Job",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },CHANGE_PASSWORD:{
      METADATA:{
        TITLE:PREFIX+" Change Password",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    SUBSCRIBE_CUSTOMER_LIST:{
      METADATA:{
        TITLE:PREFIX+" Subscribed Customers List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },CHANGE_SHOP_PASSWORD:{
      METADATA:{
        TITLE:PREFIX+" Change Shop's Password",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    SHOP_SUBSCRIPTION_LOG:{
      METADATA:{
        TITLE:PREFIX+" Shop Subscription Logs",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },EDIT_SHOP:{
      METADATA:{
        TITLE:PREFIX+" Edit Shop",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    REOPEN_SHOP_TICKET:{
      METADATA:{
        TITLE:PREFIX+" Reopen Ticket",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },PART_LIST:{
      METADATA:{
        TITLE:PREFIX+" Parts List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    EDIT_PART:{
      METADATA:{
        TITLE:PREFIX+" Edit Part",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },GENERIC_JOB_LIST:{
      METADATA:{
        TITLE:PREFIX+" Generic Jobs List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },EDIT_GENERIC_JOB:{
      METADATA:{
        TITLE:PREFIX+" Edit Generic Job",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },ADD_NEW_DISCLAIMER:{
      METADATA:{
        TITLE:PREFIX+" Add New Disclaimer",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },DISCLAIMERS_LIST:{
      METADATA:{
        TITLE:PREFIX+" Disclaimers List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },EDIT_DISCLAIMER:{
      METADATA:{
        TITLE:PREFIX+" Edit Disclaimer",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },MAILCHIMP_CONNECTIONS:{
      METADATA:{
        TITLE:PREFIX+" Manage Connections-Mailchimp",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },PARTSTECH_CONNECTIONS:{
      METADATA:{
        TITLE:PREFIX+" Partstech",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },MANAGE_LABOR_RATES:{
      METADATA:{
        TITLE:PREFIX+" Manage Labor Rates",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },ADD_COMPUTER_CODES:{
      METADATA:{
        TITLE:PREFIX+" Add New Computer Code",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },COMPUTER_CODE_LIST:{
      METADATA:{
        TITLE:PREFIX+" Computer Code List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },EDIT_COMPUTER_CODE:{
      METADATA:{
        TITLE:PREFIX+" Edit Computer Code",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },EDIT_CUSTOMER:{
      METADATA:{
        TITLE:PREFIX+" View Customer Details",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },CUSTOMERS_LIST:{
      METADATA:{
        TITLE:PREFIX+" Customers List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    STATE_LIST:{
      METADATA:{
        TITLE:PREFIX+" States List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    EDIT_STATE:{
      METADATA:{
        TITLE:PREFIX+" Edit State",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    ADD_STATE:{
      METADATA:{
        TITLE:PREFIX+" Add State",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    CITY_LIST:{
      METADATA:{
        TITLE:PREFIX+" Cities List",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    EDIT_CITY:{
      METADATA:{
        TITLE:PREFIX+" Edit City",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    ADD_CITY:{
      METADATA:{
        TITLE:PREFIX+" Add City",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    ADD_NEW_PAGE:{
      METADATA:{
        TITLE:PREFIX+" Add New Page",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    EDIT_PAGE:{
      METADATA:{
        TITLE:PREFIX+" Edit Page",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    CONTENT_LIST:{
      METADATA:{
        TITLE:PREFIX+" CMS Pages List",
          AUTHOR:"CAR",
          DESCRIPTION:"Complete Auto Reports, CAR in short",
          KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    PART_NUMBER:{
      METADATA:{
        TITLE:PREFIX+" Add New Smart Batch",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    EDIT_SMART_PART_NUMBER:{
      METADATA:{
        TITLE:PREFIX+" Edit Smart Batch",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    SMART_BATCH_LIST:{
      METADATA:{
        TITLE:PREFIX+" Smart Batch List",
          AUTHOR:"CAR",
          DESCRIPTION:"Complete Auto Reports, CAR in short",
          KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    ASSIGN_SHOPS:{
      METADATA:{
        TITLE:PREFIX+" Shops Assignment",
          AUTHOR:"CAR",
          DESCRIPTION:"Complete Auto Reports, CAR in short",
          KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    ADD_PARTNER:{
      METADATA:{
        TITLE:PREFIX+" Add New Partner",
          AUTHOR:"CAR",
          DESCRIPTION:"Complete Auto Reports, CAR in short",
          KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    EDIT_PARTNER:{
      METADATA:{
        TITLE:PREFIX+" Edit Partner",
          AUTHOR:"CAR",
          DESCRIPTION:"Complete Auto Reports, CAR in short",
          KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    PARTNERS_LIST:{
      METADATA:{
        TITLE:PREFIX+" Partners List",
          AUTHOR:"CAR",
          DESCRIPTION:"Complete Auto Reports, CAR in short",
          KEYWORDS:"Add CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports,"
      }
    },
    DELETE_SHOP_TICKET:{
      METADATA:{
        TITLE:PREFIX+" Delete Ticket",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    SHOP_LOGIN_LOGS_LIST:{
      METADATA:{
        TITLE:PREFIX+" Shop login logs",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    CUSTOMER_LOGIN_LOGS_LIST:{
      METADATA:{
        TITLE:PREFIX+" Customer login logs",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    SHOP_CUSTOMER_PULL_LOGS_LIST:{
      METADATA:{
        TITLE:PREFIX+" Customer pull logs",
        AUTHOR:"CAR",
        DESCRIPTION:"Complete Auto Reports, CAR in short",
        KEYWORDS:"CAR,complete auto reports, view vehicles, add vehicles,my account,emission reports,inspection reports"
      }
    },
    

  },
}
// this.globalService.setMetaData("SHOPS","DASHBOARD")
