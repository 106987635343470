import { Component, OnInit, Inject, ChangeDetectorRef, Optional, ViewEncapsulation } from '@angular/core';
import { GlobalService } from '../../../../environments/global.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { globalConstants } from '../../../../environments/globalConstants';
import { IDatePickerConfig } from 'ng2-date-picker';
import {DatePipe} from '@angular/common';
import * as moment from 'moment';
@Component({
  selector: 'app-addinternalnote',
  templateUrl: './addinternalnote.component.html',
  styleUrls: ['./addinternalnote.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe]
})
export class AddinternalnoteComponent implements OnInit {

  public notes: FormGroup;
  public noteImgDocs: any = [];
  public user: any = {};
  public employes = [];
  public isMultipleFile = true;
  public param:any=null;
  public FORMAT_SUPPORTED = globalConstants.FORMAT_SUPPORTED_FOR_IMAGE + ', pdf, ' + globalConstants.FORMAT_FOR_DOC;
  public noteDetail:any=null;
  public assignees:any=[];
  public noteDocPath=globalConstants.INTERNAL_NOTE_DOC;
  public imgPath=globalConstants.S3_BUCKET_URL;
  public minDate = new Date();
  public minDates=(this.minDate.getMonth()+1)+'/'+this.minDate.getDate()+'/'+this.minDate.getFullYear();
  public isEmployee=false;
  public datePickerConfig: IDatePickerConfig = {
    format: "MM/DD/YYYY hh:mm A",
    min: this.gs.getFullFormateDate(new Date(), 'G','C'),
    drops:'down',
    disableKeypress:true,
    unSelectOnClick:false
  };
  public isSubmitBtnDisabled = false;
  constructor(private ref:ChangeDetectorRef, private datePipe: DatePipe,
    public dialogRef: MatDialogRef<AddinternalnoteComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private gs: GlobalService) {
    this.gs.diaLogObj(this.dialogRef);
    this.user = this.gs.getCurrentUser();
    this.param=this.data;
  }

  ngOnInit() {
    this.isMultipleFile = this.gs.isMultifileAllowed()
    this.notes = this.formBuilder.group({
      id:'',
      title: ['', Validators.required],
      description: ['',Validators.required],
      notify_date:[''],
      status: ['0'],
      assignees: [''],
      doc_files: [''],
      user_id: this.user.id,
      user_type: this.user.user_type,
      event_type:['0']
    });
    
    this.isEmployee=this.gs.isSubscribe('employee');
    if(this.isEmployee){
      this.getEmployee()
    }
    console.log(this.data);
    
    let startDate = this.datePipe.transform(this.data.startTime, 'MM/dd/yyyy hh:mm a');
    let endDate = this.datePipe.transform(this.data.endTime, 'MM/dd/yyyy hh:mm a');
    this.notes.controls.notify_date.setValue(startDate);
    this.notes.controls.event_type.setValue(this.data.eventType);
    //this.notes.controls.event_end_date.setValue(endDate);
  }

  addNotes() {

    let fcData = this.notes;
    for (let inner in this.notes.controls) {
      this.notes.get(inner).markAsTouched();
      this.notes.get(inner).updateValueAndValidity();
    }
    if (this.notes.value.status == 1) {
      this.gs.validateState(this.notes, ['assignees']);
    }
    let th = this;
    if (fcData.valid) {
      let fileNames = [];
      this.noteImgDocs.forEach(ele => {
        fileNames.push(ele.name);
      });
      let file = JSON.stringify(fileNames);
      this.notes.controls.doc_files.setValue(file);
      let data = Object.assign({},this.notes.value);
      data['notify_date'] = moment(data['notify_date']).format('MM/DD/YYYY hh:mm A Z');
      this.isSubmitBtnDisabled = true;
      this.gs.formData(globalConstants.API_ADD_INTERNAL_NOTE, this.gs.formsData(data)).subscribe((r) => {
        this.isSubmitBtnDisabled = false;
        if (r.result == 1) {
          this.dialogRef.close(r);
          this.gs.snackbar("success", r.message);
        } else {
          this.gs.snackbar("error", r.message);
        }
      }, (error) => { })
    } else {
      this.gs.snackbar("error", globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }
  }
  getFileIcons(name) {
    return this.gs.getFileIcon(name)
  }
  deleteImgDoc(i) {
    this.noteImgDocs.splice(i, 1);
  }
  changeListner(event) {
    let valid = false;
    let ele = <HTMLInputElement>document.getElementById('addInternalNotesDoc');
    if (!this.isMultipleFile && this.noteImgDocs.length > 0) {
      this.gs.snackbar("error", "Update your subscription to upload multiple files.");
      event.target.value = "";
      if (ele) {
        ele.value = '';
      }
      return false;
    }
    let cFiles = { src: '', type: 0, name: '' };
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      valid = this.gs.validateFiles('doc', event.target.files);
      if (valid) {
        var reader = new FileReader();
        let base64valuec;
        reader.onload = (e) => {
          base64valuec = e.target['result'];
          cFiles.src = base64valuec;
        }
        let FileType = this.gs.getFileIcon(file);
        cFiles.type = FileType;
        if (FileType == 1) {
          reader.readAsDataURL(event.target.files[0]);
        }
        let data = new FormData();
        let url = globalConstants.API_NEW_SERVICE_REQUEST_FILE;
        data.append('user_id', this.user.id);
        data.append('user_type', this.user.user_type);
        data.append('doc', file);

        this.gs.formData(url, data).subscribe((response) => {
          let resData = response;
          if (resData.result == 1) {
            event.target.value = "";
            cFiles.name = resData.data.name;
            this.noteImgDocs.push(cFiles);
          } else {
            this.gs.snackbar("error", resData.message);
          }
          if (ele) {
            ele.value = '';
          }
        });
      } else if (ele) {
        ele.value = '';
      }
    }
  }
  getEmployee() {
    let data = { user_id: this.user.id, user_type: this.user.user_type, opr: 1,pr:1 };
    this.gs.callAPI(globalConstants.API_GET_TECHNICIAN, data).subscribe((r:any) => {
      if (r.result == 1) {
        let th=this;
        this.employes = [];
        r.data.forEach((r)=>{
          let a={id: r.id, text: r.first_name+' ' +r.middle_name+' '+r.last_name };
          th.employes.push(a);
        });
        this.ref.markForCheck();

      }
    }, (error) => { })

  }
  selectedEmps(obj) {
    if (this.notes.value.status == 1) {
      this.gs.validateState(this.notes, ['assignees']);
    }
  }
  
  getSelected(e){
    let i=this.assignees.indexOf(e);
    if(i!=-1){
      this.assignees.splice(i,1);
    }
  }

}
