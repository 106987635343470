import { Component, OnInit, Inject } from '@angular/core';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-quoteview',
  templateUrl: './quoteview.component.html',
  styleUrls: ['./quoteview.component.scss']
})
export class QuoteviewComponent implements OnInit {

  public customer:any=null
  public param:any={}
  public recomendationData:any={}
  public allJobs:any=[]
  public selectedParts: any = {}
  public selectedGenericJob: any = {}
  public times:any={}
  public labors:any={}
  public notes:any={}
  public vehicleName :any ="";
  public editable=true
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public gs:GlobalService, public dialogRef: MatDialogRef<QuoteviewComponent>) { 
    this.gs.diaLogObj(this.dialogRef);
    this.customer=this.gs.getCurrentCustomer()
    this.param=data
  }

  ngOnInit() {
    // console.log(this.param)
    this.getQQDetail(this.param)
    
  }
  /** get the all job list to  */
  getQQDetail(param){
    let data={quote_id:param.id,customer_id: this.customer.id}
    this.gs.callAPI(globalConstants.API_CUSTOMER_QUICK_DETAIL, data).subscribe((r:any) => {
      if(r.result==1){
        this.recomendationData=r.data
        if (this.recomendationData.year =="") {
        this.vehicleName= "";
      } else {
        this.vehicleName=this.recomendationData.year ;
      }
      if(this.recomendationData.make != ""){
        this.vehicleName+= (this.vehicleName ? "/" : "" ) + this.recomendationData.make;
      }
      if(this.recomendationData.model != ""){
        this.vehicleName+= (this.vehicleName ? "/" : "" ) + this.recomendationData.model
    }
        this.allJobs=r.data.service_list
        this.petchAllRecommendation()
      }else{
        this.gs.snackbar("error", r.message);
      }
    }, (error) => { })
  }
  petchAllRecommendation(){
    let data=this.allJobs
    data.forEach((r)=>{
       if(r.children && r.children.length){
        for(let i in r.children){
           if(typeof r.children[i].recommendation!='undefined' && r.children[i].recommendation && r.children[i].recommendation.id){
            this.seperateJob(r.children[i].recommendation)
           }else if(typeof r.children[i].children!='undefined' && r.children[i].children.length){
            this.findChild(r.children[i].children)
           }
        }
       }else if(typeof r.recommendation!='undefined' && r.recommendation && r.recommendation.id){
        this.seperateJob(r.recommendation)
       }
    })
  }
  findChild(job){
    job.forEach((r)=>{
       if(r.children && r.children.length){
        for(let i in r.children){
           if(typeof r.children[i].recommendation!='undefined' && r.children[i].recommendation && r.children[i].recommendation.id){
            this.seperateJob(r.children[i].recommendation)
           }else if(typeof r.children[i].children!='undefined' && r.children[i].children.length){
            this.findChild(r.children[i].children)
           }
        }
       }else if(typeof r.recommendation!='undefined' && r.recommendation && r.recommendation.id){
        this.seperateJob(r.recommendation)
       }
    })
  }

  seperateJob(job){
    // console.log(job)
    let priceShow=false;
    if(job.parts.length){
      job.parts.forEach((e)=>{
          e.label=e.part_number+(priceShow? ' ($'+e.cost+') ':' ')+e.name
          e.price=e.part_price
      })
    }

    if(job.generic_jobs.length){
      job.generic_jobs.forEach((e)=>{
          e.label=e.name
      })
    }
    if (typeof this.selectedParts[job.service_list_id] == 'undefined') {
      this.selectedParts[job.service_list_id] = []
      this.selectedParts[job.service_list_id] = job.parts
    }
    if (typeof this.selectedGenericJob[job.service_list_id] == 'undefined') {
      this.selectedGenericJob[job.service_list_id] = []
      this.selectedGenericJob[job.service_list_id] = job.generic_jobs
    }
    if (typeof this.labors[job.service_list_id] == 'undefined') {
      this.labors[job.service_list_id] = ''
      this.labors[job.service_list_id] = job.estimated_labor
    }
    if (typeof this.times[job.service_list_id] == 'undefined') {
      this.times[job.service_list_id] = ''
      this.times[job.service_list_id] = job.estimated_time
    }
    if (typeof this.notes[job.service_list_id] == 'undefined') {
      this.notes[job.service_list_id] = ''
      this.notes[job.service_list_id] = job.notes
    }
  }
  
  formatDate(addDate){
    return this.gs.getFullFormateDate(addDate,'G','B')
  }

  getNltoBr(val){
    if(val && val.length>0){
      return val.toString().replace(/\r\n/g, "<br />").replace(/\n/g, "<br />");
    }
    return '-';
  }

}
