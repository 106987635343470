<div class="row bgcolor-lightgrey form-content reviewPayment-config-component margin-sm-0">
  <form [formGroup]="pvtReviewSettingForm" (ngSubmit)="AddPvtReviewSetting()" id="addShopForm"
    class="form-inline col-xs-12 col-sm-12 col-md-12 col-lg-12 padding-bottom-15 pvt-review-form" role="form"
    *ngIf="screenType == 0">
    <mat-dialog-content>
     <!--  -->
      <div class="dialog-inner-content">
        <div class="row">
          <div class="com-sm-12 col-md-6">
            <p>Review settings for 'Due For Service' reminders notification.</p>
          </div>
        </div>
        <div class="row review-settings-row">
          <div class="form-group  col-sm-12 col-md-6">
            <label>Review Notes</label>
            <div class='relative details-input review-input'>
              <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                <textarea matInput placeholder="Enter Review Notes" formControlName="review_notes" maxlength="500"></textarea>
                <mat-error *ngIf="pvtReviewSettingForm.controls.review_notes.hasError('required')">
                  Review notes is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
      
          <div class="form-group  col-sm-12 col-md-6">
            <div class='relative'>
              <mat-checkbox class="btn-block" labelPosition="after" (change)="handleIncludeReviewNotes($event.checked)"
                [checked]="pvtReviewSettingForm.value.review_notes_included=='1'">
                Include in invoice email
              </mat-checkbox>
            </div>
            <div class="col-sm-12 padding-0">
              <p class="help-block margin-0">If checked off, review notes will be included in invoice email notification.</p>
            </div>
          </div>
        </div>
        <div class="row review-url-row margin-sm-top-15">
          <div class="form-group  col-sm-12 col-md-6">
            <label>Review Url</label>
            <div class='relative review-url-input'>
              <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                <input matInput placeholder="Enter Review Url (Example: https://example.com)" formControlName="review_url"
                  class="form-control input-lg custom-form-input" maxlength="200"/>
                <mat-error *ngIf="pvtReviewSettingForm.controls.review_url.hasError('required')">
                  Review url is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="pvtReviewSettingForm.controls.review_url.hasError('pattern')">
                  Please enter a valid URL
                </mat-error>
              </mat-form-field>
            </div>
          </div>
      
          <div class="form-group  col-sm-12 col-md-6">
            <div class='relative'>
              <mat-checkbox class="btn-block" labelPosition="after" (change)="handleIncludeReviewUrl($event.checked)"
                [checked]="pvtReviewSettingForm.value.review_url_included=='1'">
                Include in invoice email
              </mat-checkbox>
            </div>
            <div class="col-sm-12 padding-0">
              <p class="help-block margin-0">If checked off, review notes and url will be included in invoice email
                notification.</p>
            </div>
          </div>
        </div>
        <div class="row margin-sm-top-15">
          <div class="col-sm-12">
            <mat-checkbox class="btn-block" labelPosition="after" (change)="handleUpdateReviewsInShopProfile($event.checked)">
              Update settings in shop profile
            </mat-checkbox>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="inquiry-action">
      <div class="flexBtns2">
        <button type="submit" mat-raised-button title="Save" class="font-bold text-uppercase"
          color="accent">Save</button>
        <button type="button" mat-raised-button title="Save" class="font-bold text-uppercase"
          (click)="closeDialogBox()">close</button>
      </div>
    </mat-dialog-actions>
  </form>

  <form [formGroup]="extPaymentSettingForm" (ngSubmit)="AddExtPaymentSetting()" id="addShopForm"
    class="form-inline col-xs-12 col-sm-12 col-md-12 col-lg-12 padding-bottom-15 extrnal-payment-form" role="form"
    *ngIf="screenType == 1">
    <mat-dialog-content>
      <div class="dialog-inner-content">
        <div class="row">
          <div class="com-sm-12 col-md-6">
            <p>Review settings for 'Due For Service' reminders notification.</p>
          </div>
        </div>
        <div class="row payment-settings-row">
          <div class="form-group  col-sm-12 col-md-6">
            <label>Payment Notes</label>
            <div class='relative details-input payment-input'>
              <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                <textarea matInput placeholder="Enter Payment Notes" formControlName="payment_notes" maxlength="500"></textarea>
                <mat-error *ngIf="extPaymentSettingForm.controls.payment_notes.hasError('required')">
                  Payment notes is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="form-group  col-sm-12 col-md-6">
            <div class='relative'>
              <mat-checkbox class="btn-block" labelPosition="after" (change)="handleIncludePaymentNotes($event.checked)"
                [checked]="extPaymentSettingForm.value.payment_notes_included=='1'">
                Include in invoice email
              </mat-checkbox>
            </div>
            <div class="col-sm-12 padding-0">
              <p class="help-block margin-0">If checked off, payment notes will be included in invoice email notification.</p>
            </div>
          </div>
        </div>
        <div class="row payment-url-row margin-sm-top-15">
          <div class="form-group  col-sm-12 col-md-6">
            <label>Payment Url</label>
            <div class='relative payment-url-input'>
              <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                <input matInput placeholder="Enter Payment Url (Example: https://example.com)"
                  formControlName="payment_url" class="form-control input-lg custom-form-input" maxlength="200"/>
                <mat-error *ngIf="extPaymentSettingForm.controls.payment_url.hasError('required')">
                  Payment url is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="extPaymentSettingForm.controls.payment_url.hasError('pattern')">
                  Please enter a valid URL
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="form-group  col-sm-12 col-md-6">
            <div class='relative'>
              <mat-checkbox class="btn-block" labelPosition="after" (change)="handleIncludePaymentUrl($event.checked)"
                [checked]="extPaymentSettingForm.value.payment_url_included=='1'">
                Include in invoice email
              </mat-checkbox>
            </div>
            <div class="col-sm-12 padding-0">
              <p class="help-block margin-0">If checked off, payment notes and url will be included in invoice email
                notification.</p>
            </div>
          </div>
        </div>
        <div class="row margin-sm-top-15">
          <div class="col-sm-12">
            <mat-checkbox class="btn-block" labelPosition="after"
              (change)="handleUpdatePaymentsShopProfile($event.checked)">
              Update settings in shop profile
            </mat-checkbox>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="inquiry-action">
      <div class="flexBtns2">
        <button type="submit" mat-raised-button title="Save" class="font-bold text-uppercase"
          color="accent">Save</button>
        <button type="button" mat-raised-button title="Save" class="font-bold text-uppercase"
          (click)="closeDialogBox()">close</button>

      </div>
    </mat-dialog-actions>
  </form>
</div>
