<div class="panel panel-primary margin-0 inventory-stats-component" id="mainCompotents">
    <div class="panel-heading padding-left-15 slaes-wrapper">
        <div class="row">
            <div class="col-xs-8">
                <h3 class="panel-title">Inventory Stats</h3>
            </div>
            
        </div>
    </div>
    <div class="panel panel-primary sales-report-wrapper margin-15 no-shadow">
        <!-- <div class="panel-heading relative service-heading">
            <h3 class="panel-title">Inventory Graph</h3>
        </div> -->
        <div class="display-full relative">
            <mat-expansion-panel hideToggle="false" class="search-filter relative" [expanded]="searchPanelOpenStatus">
                <mat-expansion-panel-header collapsedHeight="53px" expandedHeight="53px">
                    <strong><i class="fa fa-search fa-fw"></i>&nbsp;Search Filters</strong>
                </mat-expansion-panel-header>
                <form id="advanceSearchForm" class="collapse in" aria-expanded="true">
                    <div class="panel-body">
                        <div class="row nr-col-width">
                            <div class="col-sm-3">
                                <mat-form-field class="car-input btn-block" title="Search By" floatPlaceholder="never" appearance="fill">
                                    <mat-select class="form-control input-lg custom-form-input"
                                        options="searchByList" [(ngModel)]="options.search_by"
                                        [ngModelOptions]="{standalone: true}" placeholder="Search By" (valueChange)="onOptionChange($event)">
                                        <mat-option *ngFor="let opt of searchByList"
                                            [value]="opt.id">{{opt.text}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            
                            <div class="col-sm-3 dateRangeStyle details-input">
                                <app-date-range-picker class="date-time-section"
                                    (SelectTime)="onDateChange($event)"></app-date-range-picker>
                            </div>
                            <div class="col-sm-3 uc-ngx-select">
                                <ngx-select [autoClearSearch]="true" class="display-full margin-bottom-20 details-input" [(ngModel)]="options.part_tags" 
                                [ngModelOptions]="{standalone: true}" [multiple]="true" [items]="allTags" optionTextField="tag_name" placeholder="Select Tags"></ngx-select>
                            </div>
                        </div>
                       
                    </div>
                    <mat-action-row class="search-filter-footer">
                        <button mat-raised-button color="accent" type="submit" title="Search" (click)="handleSearchInventoryStats($event)">
                            Search
                        </button>
                    </mat-action-row>
                </form>
            </mat-expansion-panel>
            <button mat-button class="clear-btn" title="Clear Filter" #clearBtn [class.active-search]="checkFormEmpty(clearBtn)"
                    (click)="clearSearch()">
                    Clear Filter
            </button>
        </div>
        <div class="panel-body bgcolor-lightgrey border border-top-0">
            <div class="chart" *ngIf="showChart">
                <div class="col-sm-12">
                    <google-chart [data]="inventoryGraph"></google-chart>
                </div>
            </div>
        </div>
    </div>
    <div class="panel panel-primary sales-report-wrapper margin-15 no-shadow parts-inventry-panel">
        <div class="panel-heading relative parts-inventry-heading">
            <h3 class="panel-title">Part Inventory LIST</h3>
            <span>
                <button type="button" name="btncsv" class="btn btn-success btn-download-csv" (click)="downloadInventoryStatsCSV()"
                    title="Download CSV File"><mat-icon role="img"
                        class="mat-icon notranslate material-icons mat-icon-no-color visible-xs" aria-hidden="true"
                        data-mat-icon-type="font">cloud_download</mat-icon><span class="hidden-xs">Download CSV
                        File</span></button>
            </span>
        </div>
        <div class="panel-body bgcolor-lightgrey border border-top-0">
            <div class="car-datatable mat-elevation-z1">
                <div class="table-responsive relative">
                    <mat-table [dataSource]="dataSource" matSort class="datatable-loader-bg uc-table-responsive">
                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Name </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="auto" data-val="Name"> {{row.name}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="part_number">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Part Number
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="auto" data-val="Part Number"> {{row.part_number}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="manufacturer">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto"> Manufacturer
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="auto" data-val="Manufacturer">
                                {{(row.manufacturer)}} </mat-cell>
                        </ng-container>

                        <!-- Color Colu`mn -->
                        <ng-container matColumnDef="price">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="price" title="Total Retail Price">Total ($)
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="price" data-val="Total Price ($)">
                                        {{row.price}}
                            </mat-cell>
                        </ng-container>

                        <!-- Color Column -->
                        <ng-container matColumnDef="quantity">
                            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto" title="Total Quantity Sold">Quantity
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="auto" data-val="Total Quantity">
                                {{showWholeNumber(row.quantity)}}
                            </mat-cell>
                        </ng-container>

                        <!-- Color Column -->
                        <ng-container matColumnDef="Picture">
                            <mat-header-cell *matHeaderCellDef data-role="pic"> Picture </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-role="pic" data-val="Picture"> <img
                                    class="part-pictures"
                                    *ngIf="row.picture !='' && row.picture !=null"
                                    src="{{partPicture}}{{row.picture}}" /> </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row class="mat-row" *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                    <div class="padding-15 text-center" [style.display]="getTotalRecords() < 1 ? '' : 'none'">
                        No Records Available
                    </div>
                </div>
                <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
                    <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0"
                        [pageSize]="options.limit" [pageSizeOptions]="[5, 10,25, 100]" (page)="paginateByLimit($event)">
                    </mat-paginator>

                    <div class="mat-paginator table-paging">
                        <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} -
                            {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}
                        </div>
                        <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
                            <mat-icon>navigate_before</mat-icon>
                        </button>
                        <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
                            <mat-icon>navigate_next</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 legends-section">
                <strong>Legends:</strong>
                <ul >
                    <li>Total <span class="visible-xs">Price</span> ($): Total Retail Price</li>
                    <li><span class="visible-xs">Total</span> Quantity: Total Quantity Sold</li>
                </ul>
            </div>  
        </div>
       
    </div>
          
</div>