import { Component,  OnInit, Inject, ViewEncapsulation, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import {IDatePickerConfig} from 'ng2-date-picker';
// import { MatPaginator, MatSort, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import {  MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { GlobalService } from '../../../../environments/global.service';
import { globalFunctions } from '../../../../environments/globalFunctions';
import { globalConstants } from '../../../../environments/globalConstants';
import { TechassignmentComponent } from '../../shopservicerequest/techassignment/techassignment.component';
import { Router } from '@angular/router';
import { EditpopupComponent } from '../../shopcar/editpopup/editpopup.component';
// import { type } from 'os';
import * as moment from 'moment';
@Component({
  selector: 'app-updatesr-field',
  templateUrl: './updatesr-field.component.html',
  styleUrls: ['./updatesr-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe],
  
})
  
export class UpdatesrFieldComponent implements OnInit {

  public SRForm: FormGroup;
  public needed_date;
  public submit=false;
  public eventType:number;
  public submitDisabled:boolean = false;
  public datePickerConfig: IDatePickerConfig = {
    drops: "down",
    format: "MM/DD/YYYY hh:mm A",
    min: this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm a'),
    closeOnSelect:true,
    disableKeypress:true,
    unSelectOnClick:false
  };

  myControl: FormControl = new FormControl();

  constructor(public gs: GlobalService,
    public dialogRef: MatDialogRef<UpdatesrFieldComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
    private formBuilder: FormBuilder,
    public router: Router,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private form: FormBuilder) { 
    this.needed_date = data.needed_date;
    }

  ngOnInit() {
    this.SRForm = this.form.group({
      event_needed_date:[''],
    });

    let Neededdate = this.datePipe.transform(this.data.needed_date, 'MM/dd/yyyy hh:mm a');
    this.SRForm.controls.event_needed_date.setValue(Neededdate);
  }

  checkIfValid() {
    if (this.SRForm.valid) {
        this.submitDisabled = false;
    }
  }

  editSRdetails(){

    //console.log(this.SRForm.value.event_needed_date);   
    if(this.SRForm.valid) {
       this.submit = true;
       var data    = new FormData();
       data.append('user_id', this.gs.getCurrentUser()["id"]);
       data.append('user_type', this.gs.getCurrentUser()["user_type"]);
       if(this.SRForm.value.event_needed_date){
        data.append('needed_date', moment(this.SRForm.value.event_needed_date).format('MM/DD/YYYY hh:mm A Z'));
       } 
       if(this.data.request_id){
        data.append('request_id', this.data.request_id);
       }  
       /*var data = "user_id=" + this.gs.getCurrentUser()["id"] + "&user_type="
       + this.gs.getCurrentUser()["user_type"]+"&needed_date="+moment(this.SRForm.value.event_needed_date).format('MM/DD/YYYY hh:mm A Z')+"&request_id=" + this.data.request_id */
       this.gs.formData(globalConstants.API_UPDATE_CALANDER_REQUEST_FIELD,data).subscribe((res:any) => {
         if (res.result == 1 ) { 
             this.dialogRef['response'] = res.data;
             this.gs.snackbar("success", res.message);          
             this.dialogRef.close();
         }else{
           this.gs.snackbar('error', res.message);
           this.dialogRef.close();
         }
       },(error)=>{});
    }
   }

}
