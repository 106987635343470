<div mat-dialog-title class="relative">
    <h3 mat-dialog-title>Add Customer Type</h3>
    <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
        <mat-icon class="notranslate" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
</div>
<mat-dialog-content class="add-customer-type">
    <form class="form-inline col-xs-12 col-sm-12 col-md-12 col-lg-12" role="form" autocomplete="off" [formGroup]="newCustomerType">
        <div class="alert alert-danger alert-dismissible" role="alert" id="addCustomerTypeFormErrorMsg" style="display: none;">
            <button type="button" class="close"><span aria-hidden="true">×</span></button>
        </div>

        <div class="row">
            <div class="form-group  details-input col-sm-12">
                <label for="title">Customer Type<span class="asterisk">*</span></label>
                <div class='relative'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                        <input matInput placeholder="Customer Type" title="Customer Type"  class="custom-form-input" formControlName="add_customer_type_name" required maxlength="100"/>
                        <mat-error *ngIf="newCustomerType.controls.add_customer_type_name.hasError('required')">
                            Customer Type is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="newCustomerType.controls.add_customer_type_name.hasError('pattern')">
                            Please enter a valid customer type
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="form-group details-input col-sm-12">
                <label for="title">Labor Rate (per hour)<span class="asterisk">*</span></label>
                <div class='relative'>
                    <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                        <input matInput placeholder="Labor Rate (per hour)" title="Labor Rate (per hour)" class="custom-form-input" formControlName="add_labor_rate" required (keypress)="onlyNumeric($event);validateNumbersWithDecimal($event,'add_labor_rate',7,3)" maxlength="10"/>
                               <mat-error *ngIf="newCustomerType.controls.add_labor_rate.hasError('required')">
                                  Labor hours is <strong>required</strong>
                               </mat-error>
                               <mat-error *ngIf="newCustomerType.controls.add_labor_rate.hasError('pattern')">
                                    Must be a number with upto 2 decimal
                                </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block submit-block">
                <button type="button" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Save and Add Another" (click)="saveNewCustomerType(1)">Save and Add Another</button>
                <button type="button" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Save and Exit" (click)="saveNewCustomerType(2)">Save and Exit</button>
            </div>
        </div>
    </form>
</mat-dialog-content>
