import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationEnd } from '@angular/router';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { IDatePickerConfig } from 'ng2-date-picker';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AddSrnoteComponent } from '../add-srnote/add-srnote.component';
import { EditSrnoteComponent } from '../edit-srnote/edit-srnote.component';
import { ViewSrnoteComponent } from '../view-srnote/view-srnote.component';
const UPLOAD_LIST = "srinternal-note-";
declare var jQuery:any;
declare var $ :any;
@Component({
  selector: 'app-srnotelist',
  templateUrl: './srnotelist.component.html',
  styleUrls: ['./srnotelist.component.scss']
})
export class SrnotelistComponent implements OnInit {
  
  public user: any={};
  public owlOptions: any = {
    items: 4, dots: false,
    nav: true,
    navText: [
      "<i class='fa fa-chevron-left fa-lg'></i>",
      "<i class='fa fa-chevron-right fa-lg'></i>"
    ],
    responsive: {
      0: {
        items:1
      },
      480: {
        items:1
      },
      768: {
        items:3
      },
      992: {
        items:4
      },
      1200: {
        items:5
      }
    }
  };
  public options:any = {
    user_type: "User",
    user_id: "",
    request_id:""    
  }
  url = "";

  status=['Created','Assigned','Resolved','Unread'];
  public noteId: any = null;
  public isInternalNotes=false;
  public isOpen:any = null;
  public param:any=null;
  public FORMAT_SUPPORTED = globalConstants.FORMAT_SUPPORTED_FOR_IMAGE + ', pdf, ' + globalConstants.FORMAT_FOR_DOC;
  public noteDetail:any=null;
  public minDate = new Date();
  public minDates=(this.minDate.getMonth()+1)+'/'+this.minDate.getDate()+'/'+this.minDate.getFullYear();
  public isEmployee=false;
  public datePickerConfig: IDatePickerConfig = {
    format: "MM/DD/YYYY hh:mm A",
    min: this.gs.getFullFormateDate(new Date(), 'G','C'),
    drops:'down',
    unSelectOnClick:false
  };
  isShowAddNote=false
  public allList=[]
  public isSlideOpen=true
  public disableSlide=false
  public requestID=''
  public showList=false
  public autoOpen=false
  constructor( public ref: ChangeDetectorRef,public router: Router,public gs: GlobalService, public dialog: MatDialog, private formBuilder: FormBuilder) {
    
    this.user = this.gs.getCurrentUser();
    this.isInternalNotes= this.gs.isSubscribe('service_notes');
    if (this.user) {
      this.url =globalConstants.API_SERVICE_NOTE_LIST
    } else {
      this.router.navigate(['shops']);
    }
  }

  ngOnInit() {

    this.reInit();
    this.noteId=this.gs.SRNoteListId.subscribe((r)=>{
      this.options.request_id=r
      this.showList=false;
      if(r){
        this.autoOpen=true
        this.getAllData();
      }
      this.ref.detectChanges();
    })
    this.isOpen=this.gs.SRNoteListOpen.subscribe((r)=>{
      this.showList=false
      this.ref.detectChanges();
      this.listView()
    })
  }
  
  /** open Add Notes */
  internalNotes() {
    this.listView(0)
    let dialogRef = this.dialog.open(AddSrnoteComponent, {
      panelClass: 'car-dialog-form',
      data: {request_id:this.options.request_id}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result==1){
        this.clearSearch();
      }
      this.disableSlide=false
      this.ref.detectChanges();
    });
  }
  /** get status */
  getStatus(i){
    return (typeof this.status[i]!='undefined' ? this.status[i]:'-')
  }
  /** init options */
  reInit() {
    this.options = this.gs.localGetterSetter(this.options, this.options, UPLOAD_LIST);
    this.options.user_type = this.user.user_type;
    this.options.user_id = this.user.id;
    this.options.request_id=this.gs.SRNoteListId.value;
  }
  
  /** create record, that show list */
  createRecordrecord(rec) {
    let obj= {
      id: rec.id,
      name: rec.title,
      status:rec.status,
      added_on: this.gs.getFullFormateDate(rec.add_date, 'GD', 'H'),
      read_status:rec.read_status,
      added_by:rec.added_by,
      description:rec.description
    };
    this.allList.push(obj);
  }
  /** get all notes */
  clearSearch() {
    this.gs.localGetterSetter(null,this.options,UPLOAD_LIST);
    this.reInit();
    this.getAllData();
  }
  /** note mark as read and open detail view */
  viewNote(row){

    if(row.read_status==1){
      this.redirectToView(row)
    }else{
      let data = { user_id: this.user.id,user_type: this.user.user_type,note_id:row.id,status:1};
      let url=globalConstants.API_ADD_COMMENT_SERVICE_NOTE;
      this.gs.callAPI(url, data).subscribe((r:any) => {
        if (r.result == 1) {
          this.redirectToView(row)
        }else if(r.code=499){
          this.redirectToView(row)
        }
      }, (error) => { })
    }
  }
  /** redirect to view service note detail */
  redirectToView(row,open?:any){
    (open!=true) && this.listView(0)
    this.gs.localGetterSetter('service-note', row.id,'');
    let dialogRef = this.dialog.open(ViewSrnoteComponent, {
        panelClass: 'dialog-view-note',
    });
    dialogRef.afterClosed().subscribe(()=>{
        this.disableSlide=false
        this.ref.detectChanges();
    })
  }
   /** open edit service note detail */
  edit(row){
    this.listView(0)
    let dialogRef = this.dialog.open(EditSrnoteComponent, {
      panelClass: 'car-dialog-form',
      data: row
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result==2){
        this.redirectToView(row,true)
      }
      this.getAllData()
      this.disableSlide=false
      this.ref.detectChanges();
    });
  }
 /** get all service request notes */  
  getAllData() {
    this.allList=[]
    var content = this.options;
    var callApi = this.gs.callAPI(this.url, content);
    callApi.subscribe((data:any) => {
      this.allList=[]
      this.requestID=data.request_id
      if (data.data != undefined) {
        
        if (data.data.length != undefined) {
          
          for (var i = 0; i < data.data.length; i++) {
            this.createRecordrecord(data.data[i])
          }
        } else {
          this.createRecordrecord(data.data)
        }
      }
      let curl=this.router.url.split('/')
        if(this.autoOpen && curl && (curl[2]=='list-service-requests' || curl[2]=='boards')){    
          this.autoOpen=false
          this.isSlideOpen=false
          this.listView()
      }
    },(error)=>{})
  }
  listView(v?:any,hide?:any){
    if(!this.isSlideOpen){
      $('#notepanellist').slideDown("slow");
      this.isSlideOpen=!this.isSlideOpen
    }else{
      let th=this
      $('#notepanellist').slideUp("slow",()=>{
        if(hide){
          th.showList=true
          th.ref.detectChanges();
        }
      });
      this.isSlideOpen=!this.isSlideOpen
    }
    if(v==0){
      this.disableSlide=true
    }else{
      this.disableSlide=false
    }
  }
  /** limit text length */
  limitxt(txt,l){
    if(txt && txt.length>l){
      return txt.substr(0, l)+'...';
    }
    return txt;
  }
  ngOnDestroy(){
    this.noteId.unsubscribe()
    this.isOpen.unsubscribe()
    this.isSlideOpen=true
    this.disableSlide=false
    this.showList=false
  }
/** Close service notes list */
  closeList(){
    this.listView({},true)
  }
  


}
