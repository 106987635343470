 <!-- Select Jobs Start -->
 <mat-accordion class="new-request-expension show-price  uc-add-new-jobs margin-bottom-15  display-full" hideToggle="true">
    <mat-expansion-panel [expanded]="!showFillrecommendation" hideToggle="false" class="new-request-expension relative" [class.select-job-panel]="newJobs.length==0" id="addJobExpansion">
      <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="addJobexpansionHead" >
        <span class=""> </span><b>SELECT JOBS </b>
      </mat-expansion-panel-header>
      <div class="col-sm-12 margin-bottom-10 padding-0 relative">
        <input style="border-radius:0px" ngui-auto-complete (valueChanged)="getSelectedOptionForx($event)"
          (click)="carAttribute()" [source]="observableSourceForx" [list-formatter]="autocompleListFormatter"
          value-property-name="value" placeholder="Search Categories" title="Search Categories" id="qqAddJobAattribute" class="form-control" />
      </div>
      <div class="alert alert-warning col-sm-12">
        <p>Note: Make sure you have selected the correct vehicle.</p>
        <p>Type minimum 3 characters to see the job category suggestions.</p>
      </div>
      <mat-accordion class="new-request-expension show-price margin-top-10 display-full" [class.margin-bottom-15]="newJobs.length" hideToggle="true">
        <mat-expansion-panel [expanded]="i==0" hideToggle="true" class="new-request-expension relative"
          *ngFor="let job of newJobs;let i = index" id="addExpansion-{{i+1}}">
          <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="addNewJobExpansionHead-{{i+1}}">
            <span class="margin-right-5">{{ i+1 }}.</span><b>{{ job.ln }}</b>
          </mat-expansion-panel-header>
          <ul class="service-list-items" *ngFor="let ch2nd of job.child;let j=index">
              <li>
                <span class="service-list-item">{{ch2nd.ln}}</span>
                <button type="button" *ngIf="ch2nd.child.length==0" class="btn btn-link text-red" (click)="deleteNewJob(i,j)"><span class="fa fa-trash"></span></button>
                <ul class="service-list-items" *ngFor="let ch of ch2nd.child;let k=index">
                    <li>
                      <span class="service-list-item" >{{ch.ln}}</span>
                      <button type="button" class="btn btn-link text-red" (click)="deleteNewJob(i,j,k)"><span class="fa fa-trash"></span></button>
                      <mat-form-field class="car-input btn-block notes-input" floatPlaceholder="never" *ngIf="ch.type == 'textarea'">
                          <textarea matInput placeholder="comments" title="comment" rows="4" (keyup)="addNewJobComment($event,i,j,k)"></textarea>
                      </mat-form-field>
                    </li>
                </ul>
              </li>
          </ul>
        </mat-expansion-panel>
      </mat-accordion>
      <div class="btn-wrap">
        <button type="button" (click)="addNewJobs()" *ngIf="newJobs.length > 0 && editMode " class="font-bold submit-quote-btn mat-raised-button mat-accent" title="Submit & Create Quote">
          <span *ngIf="!editMode">Submit & Create Quote</span><span *ngIf="editMode">Add Jobs</span>
        </button>
      </div>
      
    </mat-expansion-panel>
  </mat-accordion> 
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group">
        <label class="col-sm-13 col-lg-12 control-label padding-0">Notes:</label>
        <div class="col-sm-12 padding-0 details-input notes-input">
          <mat-form-field class="car-input btn-block note-input" floatPlaceholder="never" appearance="fill">
            <textarea matInput placeholder="Notes" [(ngModel)]="qq_notes" title="Notes" maxlength="1000" rows="5"></textarea>
          </mat-form-field>
          <p class="taxnote text-red">Note: Customer can see anything you write here</p>
        </div>
      </div>
    </div>
  </div>
  <div class="btn-wrap">
    <button type="button" *ngIf="!editMode" (click)="addNewJobs()" class="font-bold submit-quote-btn mat-raised-button mat-accent" title="Submit & Create Quote">
      <span>Submit & Create Quote</span>
    </button>
  </div>
  <!-- Select Jobs End -->
  <!-- Fill-recommendation Jobs Start -->
   <app-qq-recomendation (onSave)="allRecomendation($event)" *ngIf="showFillrecommendation" [customer]="customer" [seletedCar]="seletedCar" [recomendations]="recomendationData" [allJobs]="allJobs"></app-qq-recomendation>
  <!-- Fill-recommendation Jobs End -->
  