<div class="panel panel-primary notranslate" id="mainCompotents">
  <div class="panel-heading padding-left-30">
      <h3 class="panel-title">Calendar View</h3>
      <button mat-mini-fab class="custom-title-btn" (click)="listView()">
        List View
    </button>   
  </div>
  <div class="panel-body bgcolor-lightgrey">
    <div class="row">
    <div class="col-sm-3">
      <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
          <mat-select placeholder="Select Status" (change)="getCalenderData()" class="form-control input-lg custom-form-input" [(ngModel)]="optionsStatus" [ngModelOptions]="{standalone: true}">
              <mat-option value ="">Select Status</mat-option>
              <mat-option value="11">Appointments</mat-option>
              <mat-option value="1">Pending</mat-option>
              <mat-option value="2">Processing</mat-option>
              <mat-option value="3">Approval Awaited</mat-option>
              <mat-option value="7">Approval Completed</mat-option>
              <mat-option value="12">Work in progress</mat-option>
              <mat-option value="6">Completed</mat-option>
              <mat-option value="8">Completed And Paid</mat-option>
              <mat-option value="9">Unread Ratings</mat-option>
              <mat-option value="feedback">Ratings</mat-option>

          </mat-select>
      </mat-form-field>
  </div>
    </div>
<div class="row text-center calendar-view">
    <div class="col-sm-4">
      <div class="btn-group" style="float: left;">
        <div
          class="btn btn-primary"
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="activeDayIsOpen = false" (click)="previousMonth($event)">
          Previous
        </div>
        <div
          class="btn btn-outline-secondary"
          mwlCalendarToday
          [(viewDate)]="viewDate"  (click)="previousMonth($event)">
          Today </div>
        <button
          class="btn btn-primary"
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="activeDayIsOpen = false" [disabled]="nextBtnDisabled" (click)="previousMonth($event)">
          Next
        </button>
      </div>
    </div>
    <div class="col-sm-4">
      <h3 class="month-heading">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
    </div>
    <div class="col-sm-4">
      <div class="btn-group" style="float: right;">
        <div
          class="btn btn-primary"
          (click)="changeView('month')"
          [class.active]="view === 'month'">
          Month
        </div>
        <div
          class="btn btn-primary"
          (click)="changeView('week')"
          [class.active]="view === 'week'">
          Week
        </div>
        <div
          class="btn btn-primary"
          (click)="changeView('day')"
          [class.active]="view === 'day'">
          Day
        </div>
      </div>
    </div>
  </div>
  <br>
  <ng-template
  #openDayEventsTemplate
  let-events="events"
  let-eventClicked="eventClicked">
  <button class="btn btn-danger  pull-right" (click)="closeDayEvent()"> <i class="fa fa-close" ></i></button>
  <div *ngFor="let event of events"
    [ngClass]="event?.cssClass"  >
    <span class="cal-event" [style.backgroundColor]="event.color.primary">   </span>
    <mwl-calendar-event-title
      [event]="event"
      [customTemplate]="eventTitleTemplate"
      view="month"
      (mwlClick)="eventClicked.emit({event: event})">
    </mwl-calendar-event-title>
    <mwl-calendar-event-actions [event]="event"></mwl-calendar-event-actions>
  </div>
</ng-template>
  <div class="calendar-container" [ngSwitch]="view">
    <mwl-calendar-month-view
      *ngSwitchCase="'month'"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [activeDayIsOpen]="activeDayIsOpen"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      [openDayEventsTemplate]="openDayEventsTemplate"
      (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
      *ngSwitchCase="'week'"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      *ngSwitchCase="'day'"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-day-view>
  </div>
  </div>
</div>
