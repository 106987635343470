<div class="panel panel-primary" >
    <div class="panel-heading padding-left-15">
        <h3 class="panel-title">
            Change Password
        </h3>
    </div>
    <div class="panel-body bgcolor-lightgrey car-body">
        <!-- Add Customer page start -->
        <div  class="row bgcolor-lightgrey form-content">
            <div class="container-fluid">
                <form [formGroup]="change_pass" (ngSubmit)="ChangePassword()" id="changePass" class="form-inline col-xs-12 col-sm-12 col-md-12 col-lg-12 change-password-form" role="form" method="POST" enctype="multipart/form-data">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block">
                        <div class="row">
                            <div class="form-group col-sm-12 col-md-6">
                                <div class='relative'>
                                    <mat-form-field class="car-input btn-block" floatPlaceholder="never" appearance="fill">
                                        <input type="password" matInput title="Current Password" placeholder="Current Password"  formControlName="old_password" class="form-control input-lg custom-form-input">
                                        <mat-error *ngIf="change_pass.controls.old_password.hasError('required')">
                                            Password is <strong>required</strong>
                                        </mat-error>
                                        <mat-error *ngIf="change_pass.controls.old_password.hasError('minlength')">
                                            Password must be at least 6 characters long.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-sm-12 col-md-6">
                                <div class='relative'>
                                    <mat-form-field class="car-input btn-block input-suffix" floatPlaceholder="never" appearance="fill">
                                        <input type="password" matInput title="New Password" placeholder="New Password"  formControlName="new_password"  class="form-control input-lg custom-form-input" [type]="hide ? 'text' : 'password'">
                                        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        <mat-error *ngIf="change_pass.controls.new_password.hasError('required')">
                                            New password is <strong>required</strong>
                                        </mat-error>
                                        <mat-error *ngIf="change_pass.controls.new_password.hasError('minlength')">
                                            New password must be at least 6 characters long.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-sm-12 col-md-6">
                                <div class='relative'>
                                    <mat-form-field class="car-input btn-block input-suffix" floatPlaceholder="never" appearance="fill">
                                        <input type="password" matInput title="Confirm Password" placeholder="Confirm Password"  formControlName="confirm_password" class="form-control input-lg custom-form-input" [type]="hide1 ? 'text' : 'password'" >
                                        <mat-icon matSuffix (click)="hide1 = !hide1">{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        <mat-error *ngIf="change_pass.controls.confirm_password.hasError('required')">
                                            Confirm password is <strong>required</strong>
                                        </mat-error>
                                        <mat-error *ngIf="change_pass.controls.confirm_password.hasError('minLength')">
                                            Confirm password must be at least 6 characters long.
                                        </mat-error>
                                        <mat-error *ngIf="change_pass.controls.confirm_password.hasError('matched')">
                                            New and confirm password should be same.
                                        </mat-error>
                                    </mat-form-field>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block submit-block">
                                <button type="submit" mat-raised-button class="car-btn btn-block text-uppercase  btn-lg font-bold fontcolor-white" color="accent" title="Submit" style="max-width: 300px;">Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

</div>
