<div mat-dialog-title class="relative">{{popUpTitle}}<button mat-mini-fab mat-dialog-close
        class="close-modal mat-elevation-z0">
        <mat-icon class="notranslate">close</mat-icon>
    </button></div><mat-dialog-content>
    <div class="center-block" style="max-width: 600px;">
        <form [formGroup]="newPassword" class="form-horizontal form-control-normal" id="auth_form"
            (ngSubmit)="authorize()">
            <p [innerHTML]="note"></p>
            <div class="display-full margin-bottom-5">
                <label class="col-sm-3 control-label"><strong>Password</strong></label>
                <div class="col-sm-9"><mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                        <input matInput type="password" placeholder="Password" formControlName="password"
                            class="form-control input-lg custom-form-input btn-block" required /></mat-form-field>
                </div>
            </div>
        </form>    
    </div>
</mat-dialog-content>
<div class="row">
    <div class="col-sm-12 text-right margin-bottom-5 text-right flexBtns">
        <button mat-raised-button type="submit" form="auth_form" class="font-bold margin-right-10" color="accent">Submit</button>
        <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
    </div>
</div>