import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, ParamMap, NavigationEnd,Params} from '@angular/router';
import {AbstractControl, FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {globalFunctions} from "../../../environments/globalFunctions";
import {globalConstants} from "../../../environments/globalConstants";
import {GlobalService} from "../../../environments/global.service";
import {Title,Meta} from '@angular/platform-browser';

@Component({
  selector: 'app-confirmsignup',
  templateUrl: './confirmsignup.component.html',
  styleUrls: ['./confirmsignup.component.scss']
})
export class ConfirmsignupComponent implements OnInit {
  public formData:FormGroup;
  public message='';
  public thanksMsg=false;
  constructor(private activatedRoute: ActivatedRoute, private globalService: GlobalService, public title: Title) { }

  ngOnInit() {
    this.globalService.setMetaData("CUSTOMER","CONFIRM_SIGNUP")
    var params = JSON.parse(localStorage.getItem("signup-confirm-data"))
    this.globalService.callAPI(globalConstants.API_CONFIRM_SIGNUP_URL,params).subscribe((data:any)=>{
      this.thanksMsg=true;
      this.message=data.message;
    })
  }
// getSearchParameters() {
//     var prmstr = window.location.search.substr(1);
//     return prmstr != null && prmstr != "" ? this.transformToAssocArray(prmstr) : {};
// }
//
// transformToAssocArray( prmstr ) {
//   var params = {};
//   var prmarr = prmstr.split("&");
//   for ( var i = 0; i < prmarr.length; i++) {
//       var tmparr = prmarr[i].split("=");
//       params[tmparr[0]] = tmparr[1];
//   }
//   return params;
// }

}
