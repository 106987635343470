import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { GlobalService } from "../../../../environments/global.service";
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { globalConstants } from 'src/environments/globalConstants';
import { globalFunctions } from 'src/environments/globalFunctions';
@Component({
  selector: 'app-job-match-dialog',
  templateUrl: './job-match-dialog.component.html',
  styleUrls: ['./job-match-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class JobMatchDialogComponent implements OnInit {
  @ViewChild(ModalDirective) JobMatchDialogModal: ModalDirective;
  public id: any;
  public SRData:any;
  public vehicleName: any;
  public JobName: any;
  public currentUserInfo:any;
  public allJobSuggestion:any = [];
  public showCostPrice=false;
  public areAllAccordionsOpened : boolean = false;
  public isFirstAccordionOpened:boolean = true;
  constructor(
    private globalService: GlobalService,
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
      this.currentUserInfo = this.globalService.getCurrentUser();
      this.showCostPrice=this.globalService.getAcl('display_part_cost','views');
      this.getAllJobSuggestion();
  }

  getAllJobSuggestion(){
       let payloadObj ={
        vehicle_id:this.SRData.vehicleID,
        job_ids:this.SRData.jobIds,
        user_type:this.currentUserInfo.user_type,
        user_id:this.currentUserInfo.id,
        year: this.SRData.vehicleInfo.year,
        make: this.SRData.vehicleInfo.make,
        model: this.SRData.vehicleInfo.model,
        request_id: this.SRData.serviceReqId
       };
       this.globalService.callAPI(globalConstants.API_JOB_MATCH_SUGGESTION,payloadObj).subscribe((res:any)=>{
            if(res.result == '1'){
                  this.allJobSuggestion = res.data;
            }else{
                 this.globalService.snackbar("error",res.message);
            }
       })
  }

  parseFloats(value){
    let val=parseFloat(value);
    if(isNaN(val)){
      return '0.00';
    }
    return val.toFixed(2);
  }

  expandAll(idKeyword:string, parent:any=''){
    if(!parent) {
      this.areAllAccordionsOpened = true;
    }
  }
  
  collapseAll(idKeyword:string, parent:any=''){
    if(!parent) {
      this.areAllAccordionsOpened = false;
      this.isFirstAccordionOpened = false; 
    }
  }

  getFormattedDate(date:string){
    return this.globalService.getFullFormateDate(date,'G','B');
  }

  copyAndPasteSuggestion(e,data,typeOfAction){
    let jobId=this.SRData.jobIds;
    let name= (this.SRData.jobName.level3JobName == null)? this.SRData.jobName.level1JobName+" / "+this.SRData.jobName.level2JobName:this.SRData.jobName.level1JobName+' / '+this.SRData.jobName.level2JobName+' / '+ this.SRData.jobName.level3JobName;
    let l1= this.SRData.jobName.level1JobName;
    let l2= this.SRData.jobName.level2JobName;
    let l3= (this.SRData.jobName.level3JobName == null)?"":this.SRData.jobName.level3JobName;
    let vehicleInfo = {
      year: data.year,
      make: data.make,
      model: data.model,
      sub_model: data.sub_model
    };
    let cbData={id:jobId,name:name,l1:l1,l2:l2,l3:l3,recomm:data.recommendations,vehicleInfo};
    if(typeOfAction == 'copy'){
         this.globalService.copyJob('set',cbData);
    }else{
         this.updateGenericJobPrice(cbData);
         this.checkUpdatedInfoOfPart(cbData);
    }
  }

  updateGenericJobPrice(job){
      job.recomm.forEach(recomm => {
        recomm.generic_jobs.forEach(e => {
          var shopContent = "user_id="+this.currentUserInfo.id+"&user_type="+this.currentUserInfo.user_type+"&job_id="+ e.generic_job_id;
          this.globalService.callAPI(globalConstants.API_GET_GENERIC_JOBS_LIST_URL,shopContent).subscribe((data:any) => {
            e.price = data.data.price;
            e.cost = data.data.cost;
          });
        });
      });
  }

  checkUpdatedInfoOfPart(jobs) {
    let partId=[];
    let shopId:string;
    if(jobs.shopId && jobs.shopId != ''){
      shopId = jobs.shopId;
    }
    if(jobs.recomm && jobs.recomm.length){
      for(let i in jobs.recomm){
        if(jobs.recomm[i].parts && jobs.recomm[i].parts.length){
          for(let p in jobs.recomm[i].parts){
            partId.push(jobs.recomm[i].parts[p].part_id);
          }
        }
      }  
    }
    if(partId.length){ 
      let data={user_id:this.currentUserInfo.id,shop_id:shopId,parts:JSON.stringify(partId)};
      this.globalService.callAPI(globalConstants.API_GET_JOB_DATA,data).subscribe((r:any)=>{
        if(r && r.length){
          this.updateLatestInfoInParts(r,jobs);
        }
      },(error)=>{ })
    }else{ 
        this.pasteJobSuggestion(jobs);
    }
  }

    /** update part price cost */
    updateLatestInfoInParts(res,jobs){
      if(jobs.recomm && jobs.recomm.length){
        for(let i in jobs.recomm){
          if(jobs.recomm[i].parts && jobs.recomm[i].parts.length){
            for(let p in jobs.recomm[i].parts){
              this.updateParts(jobs.recomm[i].parts[p],res);
            }
          }
        }
      }
      this.pasteJobSuggestion(jobs);
    }
  
    updateParts(part,res){
      for(let el in res){
        if(res[el].id==part.part_id){
          part.cost=res[el].cost;
          part.inventory_price=res[el].price;
          part.low_stock_value=res[el].low_stock_value;
          part.non_taxable=res[el].non_taxable;
          part.part_cost=res[el].cost;
          part.part_matrix_price=res[el].part_matrix_price;
          part.part_price=res[el].price;
          part.stock_quantity=res[el].stock_quantity;
          part.part_matrix_status=res[el].part_matrix_status;
          break;
        }
      }
    }
  pasteJobSuggestion(jobSuggestion:any){
       let directPasteObj ={
            jobData:this.SRData.jobData,
            suggestion:jobSuggestion
       };
       this.globalService.passPasteObj(directPasteObj);
       this.toggleState(3);
  }

  scrollToView(index:number){
    setTimeout(() => {
      document.getElementById(`expansion-history-${index}`).scrollIntoView({
        block:'start',
        inline:'start',
        behavior: 'smooth'
      })
    }, (200));
  }

  getVehicleName(vehicleInfo:any){
    return this.globalService.getGlobalVehicleName(vehicleInfo);
  }
  toggleState(type) {
    if (type == 3) {
      this.bsModalRef.hide();
      this.globalService.clearBootstrapModal(this.id);
    } else {
      this.globalService.updateBootstrapModalInfo(this.id, {});
      this.bsModalRef.hide();
    }
  }
  convertNltoBr(text){
    return this.globalService.globalNltoBr(text);
  }
}
