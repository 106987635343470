<div class="car-panel">

  <div class="panel panel-primary">
    <div class="panel-heading padding-left-30">
      <h3 class="panel-title">
        Stock Order From Partstech
      </h3>
      <button mat-mini-fab class="custom-btn-back" title="Back" (click)="globalService.goBack();">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>

  <div class="panel-body bgcolor-lightgrey">
    <div class="row bgcolor-lightgrey form-content">
      <div class="container-fluid">
          <div class="row bgcolor-lightgrey form-content padding-top-45" *ngIf="isSubscribed == false">
              <div class="container-fluid  padding-bottom-15" style="text-align: center;font-size: 22px; color:#a94442;">
                  Upgrade your plan to add a Parstech Connection.
              </div>
              <div class="padding-bottom-45 col-xs-12" style="text-align: center;padding: 10px;">
                  <button type="button" mat-raised-button class="mat-success font-bold mat-elevation-z1" (click)="gotoShopSubscription()" style="background-color: #f50057;">Upgrade Now</button>
              </div>
          </div>
        <div class="col-xs-12 form-block">
          <div class="row margin-bottom-0 stock-order">
            <div class="col-sm-12">
              <div class="alert alert-info">
                Note : You can search the parts added from PartsTech in your shop inventory. Click on
                <strong>Place Order</strong> button to see the PartsTech Stock Order screen where you can change the final quantity
                and place the order.
              </div>
              <!-- add  stock order -->
              <div class="form-inline relative">
                <h5>Search Part Name</h5>
                <div class="row margin-0">
                  <div class="col-md-8 col-sm-8 col-xs-12 " data-price="12.00" data-qty="1">
                    <input type="text" placeholder="Enter Part Name" matInput ngui-auto-complete [source]="observableSource" (valueChanged)="getSelectedOption($event)" value-property-name="label"
                       [(ngModel)]="searchSelectPartLabel" id="partSearchBox" class="form-control"
                    />
                  </div>
                  <div class="col-md-3 col-sm-3 col-xs-12  pquantity">
                        <button type="button" class="qty-btn" (click)="increment('Plus')"  >+</button>
                        <mat-form-field class="car-input" floatPlaceholder="never" appearance="fill">
                        <input type="text" placeholder="Quantity" [(ngModel)]="searchSelectPartQuantity" id="quantityBox" aria-label="Quantity" class="form-control input-lg custom-form-input btn-block"
                            matInput maxlength="5" (keypress)="onlyNumericKey($event)" />
                        </mat-form-field>
                        <button type="button" class="qty-btn" (click)="increment('Minus')">-</button>
                    </div>
                  <div class="col-md-1 col-sm-2 col-xs-12 add-btn">
                    <button type="button" (click)="addNewPart()" class="btn btn-default addPart">Add</button>
                  </div>
                </div>
                <!-- # End  stock order -->

                <!-- Start Show stock order list -->
                <div *ngIf="selectedParts.length > 0" class="order-parts-wrapper">
                  <div class="col-sm-12 padding-0 margin-top-5 margin-bottom-15">
                    <strong class="text-size">Part(s) to be ordered: </strong>
                  </div>
                  <div class="row margin-0" *ngFor="let parts of selectedParts;let a=index">
                    <div class="col-md-9 col-sm-8 col-xs-12 pname break-all">
                      {{parts.label}}
                    </div>
                    <div class="col-md-2 col-sm-2 col-xs-7  ep-quantity">
                      <mat-form-field class="car-input " floatPlaceholder="never" appearance="fill">
                        <input type="text" placeholder="Quantity" [(ngModel)]="parts.quantity" (change)="checkQty(parts,a)" aria-label="Quantity" class="form-control input-lg custom-form-input btn-block"
                          matInput maxlength="5" (keypress)="onlyNumericKey($event)" />
                      </mat-form-field>
                    </div>
                    <div class="col-md-1 col-sm-2 col-xs-4">
                      <button type="button" (click)="removePart(parts.id)" class="btn btn-danger btn-xs remove">Remove</button>
                    </div>
                  </div>
                </div>
                <!-- # End stock order list -->
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-6 col-lg-4 form-block submit-block">
              <button (click)="callStockOrderAPI()" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white"
                color="accent" title="Place Order" style="max-width: 300px;" [disabled]="selectedParts.length==0 || submitted">
                Place Order
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
