<button *ngIf="popupOrview" mat-mini-fab mat-dialog-close class="close-modal btn-internalnote-model mat-elevation-z0">
  <mat-icon  class="notranslate" >close</mat-icon>
</button>
<div class="panel panel-primary view-sr-notes">
  <div class="panel-heading padding-left-15">
    <h3 class="panel-title">Service Notes Detail</h3>
    <button mat-mini-fab class="custom-btn-back" (click)="gs.goBack();">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <div class="panel-body bgcolor-lightgrey serviceNote-view">
    <div class="jumbotron padding-25 car-view-str">
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <h3 class="margin-0 pull-left">Service Notes Information <i *ngIf="noteDetail && noteDetail.request_no">(#{{noteDetail.request_no}})</i></h3>
          <button type="button" class="font-bold mat-raised-button mat-accent pull-right edit-button" (click)="editNote()" *ngIf="popupOrview && noteDetail && user.id==noteDetail.added_by">Edit</button>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-sm-6 margin-bottom-10">
          <div class="row">
            <div class="col-xs-6 col-sm-6">
              <label class="control-label">Title:</label>
            </div>
            <div class="col-xs-6 col-sm-6 break-word">
              <p class="pull-left" *ngIf="noteDetail && noteDetail.title">{{noteDetail.title}}</p>
            </div>
          </div>
        </div>
        <div class="col-sm-6 margin-bottom-10">
          <div class="row">
            <div class="col-xs-6 col-sm-6">
              <label class="control-label">Notification Date:</label>
            </div>
            <div class="col-xs-6 col-sm-6 break-word">
              <p class="pull-left" *ngIf="noteDetail && noteDetail.add_date">{{getFormatedDate(noteDetail.notify_date,'G')}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6  margin-bottom-10">
          <div class="row">
            <div class="col-xs-6 col-sm-6"><label class="control-label">Creator:</label></div>
            <div class="col-xs-6 col-sm-6 text-justify break-word">
              <p *ngIf="noteDetail">{{noteDetail.first_name}} {{noteDetail.middle_name}} {{noteDetail.last_name}}</p>
            </div>
          </div>
        </div>
        <div class="col-sm-6  margin-bottom-10">
          <div class="row">
            <div class="col-xs-6 col-sm-6"><label class="control-label">Status:</label></div>
            <div class="col-xs-6 col-sm-6 break-word">
              <mat-chip-list class="mat-badge" *ngIf="noteDetail && noteDetail.status">
                <mat-chip color="accent" selected="true">
                  <span> {{getStatus(noteDetail.status)}}</span>
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="isEmployee && noteDetail && noteDetail.assignees.length">
        <div class="col-sm-12 margin-bottom-10">
          <div class="row">
            <div class="col-xs-6 col-sm-3"><label class="control-label">Assignees:</label></div>
            <div class="col-xs-6 col-sm-9 break-word">
              <ul class="padding-0 assigne-list">
                <li class="badge badge-pill" [class.progress-color]="setAssigneColor(u)==2" [class.resolved-color]="setAssigneColor(u)==3" [class.padding-9]="!canRemove(u)" *ngFor="let u of noteDetail.assignees;let i=index">{{u.first_name}} {{u.last_name}}
                  <button type="button" (click)="removeAssigne(u,i)" *ngIf="canRemove(u)" mat-icon-button color="secondary">
                    <i class="fa fa fa-times-circle fa-lg"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12  margin-bottom-10">
          <div class="row">
            <div class="col-sm-3"><label class="control-label">Description:</label></div>
            <div class="col-sm-9 text-justify break-all">
              <p *ngIf="noteDetail && noteDetail.description" [innerHTML]="getNltoBr(noteDetail.description)"></p>
            </div>
          </div>
        </div>
      </div>
     
      <div class="row" *ngIf="noteDetail && noteDetail.docs.length">
        <div class="col-sm-12 margin-bottom-10">
          <div class="row">
            <div class="col-sm-3"><label class="control-label">Document:</label></div>
            <div class="col-sm-9">
              <ul class="list-inline margin-left-0 upload-image">
                <li *ngFor="let doc of noteDetail.docs;let i=index" class="relative view-images">
                  <a href="javascript:void(0);" class="img-set thumbnail relative pdf-img" (click)="imageToolDialog(i,doc)">
                    <img class="show-image" src="{{imgPath}}{{noteDocPath}}thumb/{{doc.filename}}" *ngIf="doc.doc_type==1">
                    <span class="fa far fa-file-pdf fa-4x" *ngIf="doc.doc_type==3"></span>
                    <span class="fa far fa-file-word fa-4x" *ngIf="doc.doc_type==4"></span>
                    <i class="fa fa-trash remove-img hide" (click)="deleteImgDoc(i,doc.doc_type)"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button  class="expandButton" type="button" *ngIf="noteDetail && noteDetail.comments.length > 0" mat-raised-button color="accent" (click)="expand(0)">Expand All</button>
    <button class="collapseButton" type="button" *ngIf="noteDetail && noteDetail.comments.length > 0" mat-raised-button color="primary" (click)="expand(1)">Collapse All</button>
    <div class="panel-heading expansion-pretend mat-elevation-z2 padding-0 margin-bottom-10 margin-top-15">
      <div class="panel-title padding-10">COMMENTS SECTION</div>
      <span *ngIf="noteDetail && noteDetail.comments.length==0" class="display-full padding-15">No Comment Available</span>
    </div>
    <div class="display-full" *ngIf="noteDetail && noteDetail.comments.length > 0">
      <mat-accordion [multi]="true" class="new-request-expension display-full" displayMode="flat" hideToggle="false">
        <mat-expansion-panel hideToggle="false"[expanded]="(firstExpand && i==0 ? true : false ) || expandAll" class="break-word" *ngFor="let com of noteDetail.comments;let i=index">
          <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" id="commentHead{{i}}">
            <ul class="list-inline display-full margin-bottom-0">
              <li>Added By: {{com.first_name}} {{com.last_name}}</li>
              <li class="pull-right show-date">Date: {{getFormatedDate(com.add_date,'G')}}</li>
            </ul>
          </mat-expansion-panel-header>
          <div class=" text-justify break-all" *ngIf="com.status!=1" [innerHTML]="getNltoBr(com.comment)"></div>
          <div class=" text-justify break-all" *ngIf="com.status==1" >{{com.first_name}} {{com.last_name}} has viewed the note.</div>
          <div class="row margin-top-10">
            <div class="col-xs-12">
              <ul *ngIf="com && com.docs.length" class="list-inline margin-left-0 upload-image">
                <li *ngFor="let doc of com.docs;let i=index" class="relative view-images">
                  <a href="javascript:void(0);" class="img-set thumbnail relative pdf-img" (click)="imageToolDialog(i,doc,true);$event.stopPropagation();">
                    <img class="show-image" src="{{imgPath}}{{commentDocPath}}thumb/{{doc.filename}}" *ngIf="doc.doc_type==1">
                    <span class="fa far fa-file-pdf fa-4x" *ngIf="doc.doc_type==3"></span>
                    <span class="fa far fa-file-word fa-4x" *ngIf="doc.doc_type==4"></span>
                    <i class="fa fa-trash remove-img hide" (click)="deleteImgDoc(i,2,doc);$event.stopPropagation();"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="uc-mat-chips">
                <mat-chip-list class="mat-badge" *ngIf="com.call_out==1">
                  <mat-chip selected class="callout-color">
                    <span> Call-Out</span>
                  </mat-chip>
                </mat-chip-list>
                <mat-chip-list class="mat-badge">
                  <mat-chip selected [class.progress-color]="com.status==2" [class.resolved-color]="com.status==3">
                    <span> {{getCommStatus(com.status)}}</span>
                  </mat-chip>
                </mat-chip-list>
                <button type="button" *ngIf="isCommentEdit(com)" class="btn-note-edit font-bold mat-button mat-accent" (click)="editComment(com)"><i class="fa fa-edit"></i> Edit</button>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <form class="form-horizontal form-control-normal" id="commentForm" autocomplete="off" (ngSubmit)="addComment()" [formGroup]="comment">
      <div class="row margin-top-15">
        <div class="col-sm-12 details-input">
          <mat-form-field class="car-input btn-block notes-input" floatPlaceholder="never" appearance="fill">
            <textarea matInput placeholder="Add Comment" title="Add Comment" rows="5" maxlength="1000" formControlName="comment" rows="5"></textarea>
            <mat-error *ngIf="comment.controls.comment.hasError('required') ">
              This field cannot be empty
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row checkbox-row" *ngIf="comment.value.note_comment_id == '' || comment.value.note_comment_id == 'undefined'">
          <div class="col-md-12 padding-bottom-10" >
              <div class="form-group col-sm-6 col-md-4 col-lg-3">
                  <mat-checkbox [disabled]="preCommtStatus==2 || preCommtStatus==3" [checked]="comment.value.status==2 || comment.value.status==3 || preCommtStatus==2 || preCommtStatus==3" (change)="statusChange($event,2)" value="2">Work In Progress</mat-checkbox>
              </div>
              <div class="form-group col-sm-6 col-md-4 col-lg-3">
                  <mat-checkbox [disabled]="preCommtStatus==3" [checked]="comment.value.status==3 || preCommtStatus==3" (change)="statusChange($event,3)" value="3">Resolved</mat-checkbox>
              </div>
          </div>
      </div>
      <div class="row checkbox-row" *ngIf="isEmployee && noteDetail && user.id!=noteDetail.added_by && (comment.value.note_comment_id == '' || comment.value.note_comment_id == 'undefined')">
        <div class="col-md-12 padding-bottom-10" >
            <div class="form-group col-sm-6 col-md-4 col-lg-3">
                <mat-checkbox [disabled]="preCallOut" [checked]="comment.value.call_out==1 || preCallOut" (change)="callOutChange($event,1)" value="1">Call-out</mat-checkbox>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label for="fileDocumentUpload" style="font-weight:bold;">Upload Document/Image</label>
          <mat-toolbar class="mat-elevation-z2 margin-bottom-15 upload-btn relative">
            <mat-icon class="margin-right-10">cloud_upload</mat-icon>
            Drag File Here
            <input type="file" id="addInternalNotesDoc" name="addInternalNotesDoc" (change)="changeListner($event)" />
            <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
          </mat-toolbar>
          <p style="margin:0px;font-size:14px;" class="help-block">Format(s) supported : {{FORMAT_SUPPORTED}}</p>
          <p style="margin:0px;font-size:14px;" class="help-block">Maximum size : 10MB</p>
          <ul class="list-inline margin-left-0 upload-image">
            <li *ngFor="let doc of commentImgDocs;let i=index" class="relative view-images">
              <a href="javascript:void(0);" class="img-set thumbnail relative pdf-img">
                <img class="show-image show-border" src="{{doc.src}}" *ngIf="doc.type==1">
                <span class="fa far fa-file-pdf fa-4x" *ngIf="doc.type==3"></span>
                <span class="fa far fa-file-word fa-4x" *ngIf="doc.type==4"></span>
                <div class="image-loader">
                  <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30">
                  </mat-progress-spinner>
                </div>
                <i class="fa fa-trash remove-img" (click)="deleteImgDoc(i,1)"></i>
              </a>
            </li>
          </ul>
          <ul *ngIf="preCommentImgDocs.length" class="list-inline margin-left-0 upload-image">
            <li *ngFor="let doc of preCommentImgDocs;let i=index" class="relative view-images">
              <a href="javascript:void(0);" class="img-set thumbnail relative pdf-img">
                    <img class="show-image" src="{{imgPath}}{{commentDocPath}}thumb/{{doc.filename}}" *ngIf="doc.doc_type==1">
                    <span class="fa far fa-file-pdf fa-4x" *ngIf="doc.doc_type==3"></span>
                    <span class="fa far fa-file-word fa-4x" *ngIf="doc.doc_type==4"></span>
                    <i class="fa fa-trash remove-img " (click)="deleteImgDoc(i,2,doc)"></i>
                  </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row margin-top-5">
        <div class="col-md-12 submit-section">
          <button type="submit" [disabled]="isSubmitBtnDisabled" class="font-bold mat-raised-button mat-accent"><span class="mat-button-wrapper">Submit</span></button>
          <button type="button" *ngIf="comment.value.note_comment_id!=''" class="font-bold mat-raised-button mat-accent" (click)="resetComment()"><span class="mat-button-wrapper">Reset</span></button>
        </div>
      </div>
    </form>
  </div>
</div>
