<div mat-dialog-title class="relative">
  <span>
    Custom Fields List<button type="button" mat-raised-button class="car-btn more-fields-btn font-bold fontcolor-white" color="accent" (click)="addMoreFields()" title="Add New"><span class="icon">
    <i class="fa fa-plus" aria-hidden="true"></i>
  </span></button>
  </span>
  <button mat-mini-fab mat-dialog-close class="close-modal mat-elevation-z0">
    <mat-icon class="notranslate close-icon-lg">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
<div class="panelpanel-primary">
  
  <div class="panel-bodybgcolor-lightgrey">
    <div class="car-datatable uc-datatable  mat-elevation-z1">
      <div class="table-responsive relative">
        <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg uc-table-responsive">

          <ng-container matColumnDef="ID">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="id">ID</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="id" data-val="ID"> {{row.id || '-'}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Name">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Name</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="Name"> {{row.name || '-'}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Type">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Type</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="Type">  {{row.type}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="is_mandatory">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Mandatory</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="Mandatory">  {{(row.is_mandatory==1?'Yes':'No')}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Actions">
            <mat-header-cell *matHeaderCellDef data-role="button"> Actions </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="button">
              <button mat-button class="list-action-btn" [matMenuTriggerFor]="menu" disableRipple>
                Action<mat-icon>arrow_drop_down</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="car-icon-menu" xPosition="before" [overlapTrigger]="false">
                <a class="mat-menu-item" (click)="editField(row)"><i class="fa fa-pencil"></i>Edit</a>
                <a class="mat-menu-item" (click)="delField(row)"><i class="fa fa-trash"></i>Delete</a>
              </mat-menu>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <div class="padding-15 text-center" [style.display]="getTotalRecords() < 1 ? '' : 'none'">No Records Available
        </div>
      </div>
      <div class="display-full relative" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
        <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0"
          [pageSize]="options.limit" [pageSizeOptions]="[5, 10,25, 100]" (page)="paginateByLimit($event)">
        </mat-paginator>

        <div class="mat-paginator table-paging">
          <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} -
            {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
          <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
            <mat-icon>navigate_before</mat-icon>
          </button>
          <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
            <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</mat-dialog-content>
