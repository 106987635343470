import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ViktableComponent, VikSource } from '../../viktable/viktable.component';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { GlobalService } from '../../../../environments/global.service';
import { globalConstants } from '../../../../environments/globalConstants';
import { ProfilelogdiffComponent } from '../profilelogdiff/profilelogdiff.component';
const PROFILE_LOG = "cust-profile-log-";
  /**
   * @trackID <:CAR-1416> Edit Customer Profile Change Log List
   * @created 2020-02-18
   * @createdBY Vikas Kumar
   * @return
   */
@Component({
  selector: 'app-profilelog',
  templateUrl: './profilelog.component.html',
  styleUrls: ['./profilelog.component.scss']
})
export class ProfilelogComponent extends ViktableComponent implements OnInit {

  public currentAdmin: any = {};
  public openStateSearch = false;
  public countries=[];
  displayedColumns = ['modifier_type','Name','Modified By','Modification Date',  'Actions'];
  public tablesData=this;
  dataSource: any;
  url="";
  public options = {
    user_type: "Super Admin",
    user_id: "",
    search_by: "",
    fromDate: "",
    toDate: "",
    customer_id: "",
    search_keyword:  "",
    start: 0 ,
    limit: 10 ,
  }
  public fromDate = '';
  public toDate = '';
  public today = new Date();
  public param:any={}
  public selectlists=[]

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(@Inject(MAT_DIALOG_DATA) public cust: any,public gs: GlobalService, public dialog: MatDialog) {
    super(globalConstants.API_MASTER_CUSTOMER_PROFILE_LOGS, gs);
    this.currentAdmin = this.gs.getAdmin();
    this.param=cust
    if (this.currentAdmin) {
      this.sessionKey = PROFILE_LOG;
      this.url = globalConstants.API_MASTER_CUSTOMER_PROFILE_LOGS;
    } else {

    }    
  }

  ngOnInit() {
    this.selectlists=[
      {id:'',text:' Search By '},
      {id:1,text:'Shop'},
      {id:2,text:'Customer'},
      {id:3,text:'CAR Admin'}
    ];
    this.reinitOptions();
    this.setDataTable()
  }
  createRecordrecord(r) {
    return {
      name: r.shop_name,
      modify_by: r.first_name+' '+r.last_name,
      modify_date:this.gs.getFullFormateDate(r.add_date,'G','H'),
      modifier_type:this.returnUserType(r.user_type),
      new_record_value: r.new_record_value,
      old_record_value: r.old_record_value,
      id: r.id,
    };
  }
  reinitOptions() {
    this.options = this.gs.localGetterSetter(this.options, this.options, PROFILE_LOG);
    this.options.user_type = this.currentAdmin.user_type;
    this.options.user_id = this.currentAdmin.id;
    this.options.customer_id=this.param.customer_id
  }
  setDataTable() {
    if (this.fromDate != '') {
      this.options.fromDate = this.dateFormat(this.fromDate);
    }
    if (this.toDate != '') {
      this.options.toDate = this.dateFormat(this.toDate);
    }
    this.dataSource = new VikSource(this.tablesData, this.sort, this.paginator);
    this.dataSource.getSortedData=this.sortedData;
  }
  sortedData(): any[] {
    let th:any=this;
    const data = th._exampleDatabase.data.slice();
     if (!th._sort.active || th._sort.direction == '') { return data; }
 
     return data.sort((a, b) => {
       let propertyA;
       let propertyB;
       switch (th._sort.active) {
         case 'Name': propertyA = a.name; propertyB = b.name; break;
         case 'modifier_type': propertyA = a.modifier_type; propertyB = b.modifier_type; break;
         case 'Modification Date': propertyA =new Date(a.modify_date); propertyB =new Date(b.modify_date); break;
         case 'Modified By': propertyA = a.modify_by; propertyB = b.modify_by; break;
         default: return 0;
       }
        if(propertyA=='Invalid Date' || propertyB=='Invalid Date'){
          let date=-1;
          if(propertyB=='Invalid Date'){
              date=1;
          }
          return (date * (th._sort.direction == 'asc' ? 1 : -1));
        }
       let valueA = isNaN(propertyA) ? propertyA.toUpperCase() : propertyA;
       let valueB = isNaN(propertyB) ? propertyB.toUpperCase() : propertyB;
 
       return (valueA < valueB ? -1 : 1) * (th._sort.direction == 'asc' ? 1 : -1);
     });
   }
   openDiffView(row){
    let dialogRef = this.dialog.open(ProfilelogdiffComponent, {
      panelClass: ['car-dialog-form'],
      width: "800px",
      data: row
    });
   }
  setTouchViewValue() {
    return true;
  }
  search(event) {
    this.options.start = 0;
    this.setDataTable();
  }
  clearSearch() {
    this.gs.localGetterSetter(null, this.options, PROFILE_LOG);
    this.fromDate = ''
    this.options.fromDate = '';
    this.toDate = ''
    this.options.toDate = ''
    this.reinitOptions();
    this.setDataTable();
  }
  checkFormEmpty(button) {
    if (this.options.search_keyword == "") {
      if (button) { button.color = "primary" }
      return false
    }
    else {
      if (button) { button.color = "accent" }
      return true;
    }
  }
  dateChange(event, type) {
    if (type == 1) {
      this.toDate = this.fromDate;
    }
  }
  dateFormat(dateValue) {
    return ((new Date(dateValue).getMonth() + 1) < 10 ? '0' : '') + (new Date(dateValue).getMonth() + 1) + "/" + (new Date(dateValue).getDate() < 10 ? '0' : '') + new Date(dateValue).getDate() + "/" + new Date(dateValue).getFullYear();
  }

  returnUserType(type){
    if(type=='User'){
      return 'Shop'
    }else if(type=='Customer'){
      return 'Customer'
    }else if(type=='Super Admin'){
      return 'CAR Admin'
    }
  }


}
