<div mat-dialog-title class="relative">
  <div class="button-row text-center">
    <button mat-icon-button class="left" (click)="viewPreviousImage();">
      <i class="fa fa-chevron-left fa-lg"></i>
    </button>
    <button mat-icon-button class="right" (click)="viewNextImage();">
      <i class="fa fa-chevron-right fa-lg"></i>
    </button>
    <button mat-icon-button (click)="imageRotation('left')">
      <i class="fa fa-rotate-left fa-lg"></i>
    </button>
    <button mat-icon-button (click)="imageRotation('right')">
        <i class="fa fa-rotate-right fa-lg"></i>
      </button>
    <button mat-icon-button (click)="saveRotatedImage()" *ngIf="archURL==''">
      <i class="fa fa-save fa-lg"></i>
    </button>
    <button mat-icon-button (click)="downloadImage()">
      <i class="fa fa-download fa-lg"></i>
    </button>
    <button mat-icon-button mat-dialog-close class="close-modal">
      <i class="fa fa-close fa-lg"></i>
    </button>
  </div>
</div>
<mat-dialog-content>
  <div id="viewLargeImageModal">
    <div class="image-dialog">
      <div class="image-content" style="margin:0 auto;line-height: 0;padding-top: 60px;">
        <div class="image-body" style="display: inline-block;padding:0px;line-height: 0;">
          <canvas id="imageCanvas" #imageCanvas style="display: none;"></canvas>
          <img class="img-responsive" id="imageShow" crossorigin="Anonymous"/>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
