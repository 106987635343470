<div class="panel panel-primary" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
  <div class="panel-heading">
    <h3 class="panel-title">Previous QUOTES</h3>
  </div>
  <div class="panel-body bgcolor-lightgrey">
    <div class="car-datatable uc-datatable  mat-elevation-z1">
      <div class="table-responsive relative">
        <mat-table #table [dataSource]="dataSource" matSort class="datatable-loader-bg uc-table-responsive">

          <ng-container matColumnDef="ID">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="id">ID</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="id" data-val="ID"> {{row.id || '-'}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Jobs">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Jobs</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="Jobs"> {{row.jobs || '-'}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Total">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Total</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="Total">  <span *ngIf="!showCostPrice">{{'-'}}</span><span *ngIf="showCostPrice">${{row.total || '0.00'}}</span> </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Created By">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="auto">Created By</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="auto" data-val="Created By"> {{row.name || '-'}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Creation Date">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-role="date">Creation Date</mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="date" data-val="Creation Date"> {{row.added_at || '-'}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Actions">
            <mat-header-cell *matHeaderCellDef data-role="button"> Actions </mat-header-cell>
            <mat-cell *matCellDef="let row" data-role="button">
              <button mat-button class="list-action-btn" [matMenuTriggerFor]="menu" disableRipple>
                Action<mat-icon>arrow_drop_down</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="car-icon-menu" xPosition="before" [overlapTrigger]="false">
                <a class="mat-menu-item" (click)="openPrevious(row)"><i class="fa fa-eye"></i>View</a>
              </mat-menu>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <div class="padding-15 text-center" [style.display]="getTotalRecords() < 1 ? '' : 'none'">No Records Available
        </div>
      </div>
      <div class="display-full relative hide" [style.display]="getTotalRecords() < 1 ? 'none' : ''">
        <mat-paginator class="car-pagination" #paginator [length]="getTotalRecords()" [pageIndex]="0"
          [pageSize]="options.limit" [pageSizeOptions]="[5, 10,25, 100]" (page)="paginateByLimit($event)">
        </mat-paginator>

        <div class="mat-paginator table-paging">
          <div class="mat-paginator-range-label margin-left-30 margin-right-30">{{options.start+1}} -
            {{getUpperLimit(options.start + options.limit,getTotalRecords())}} of {{getTotalRecords()}}</div>
          <button mat-icon-button (click)="paginate($event,'previous');" [disabled]="firstList()">
            <mat-icon>navigate_before</mat-icon>
          </button>
          <button mat-icon-button (click)="paginate($event,'next');" [disabled]="lastList()">
            <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
