import { Component, Inject, Optional } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { GlobalService } from '../../environments/global.service';
import { DataSource } from '@angular/cdk/collections';
// import { MatSort, MatPaginator } from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs/Observable';

const VIEW_STOCK_ORDER_LIST = "shop-view-stock-orders-";
@Component({
  selector: 'app-customdatatable',
  templateUrl: './customdatatable.component.html'
})

export class CustomdatatableComponent {
  public total = 100;
  public options: any = {
    'start':0,
    'limit':10
  };
  public detailData:any={};
  public returnData:any=null;
  public resultData:any=[];

  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public apiCaller: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public recordsTotal: any = 0;
  public sessionKey = "";
  public isReady: any = false;
  public hasToPaginate: any = true;
  public exceptId:any = null
  get data(): any[] { return this.dataChange.value; }
  public isGetData:any=true
  public callApi:any = false;
  constructor(@Inject("") public url: string, public gs: GlobalService) {
    if (this.isReady) {
      this.getAllData()
    }
  }
  getAllData() {
    if(!this.hasToPaginate){
      delete this.options.start;
      delete this.options.limit;
    }
    var content = this.options;
    this.detailData={};
    this.resultData=[];
    this.dataChange = new BehaviorSubject<any[]>([]);
    this.isGetData=false
    this.callApi = this.gs.callAPI(this.url, content);
    this.callApi.subscribe((data) => {
      this.apiCaller.next(data);
      this.isGetData=true;
      if(this.returnData && data[this.returnData]){
        this.detailData=data[this.returnData];
      }
      let responseData = (this.sessionKey == VIEW_STOCK_ORDER_LIST) ? data.data.order_items : data.data;
      if (responseData != undefined) {
        let moreRows = 'false';
        let firstList = 'false';
        this.gs.localGetterSetter("totalRecords", data.recordsTotal, this.sessionKey);
        if (data.more_rows == "true") {
          moreRows = "true";
        }
        this.gs.localGetterSetter("more_rows", moreRows, this.sessionKey);
        if (data.start >= 0 && data.start <= 5) {
          firstList = "true";
        }
        this.gs.localGetterSetter("first_list", firstList, this.sessionKey);
        if (responseData.length != undefined) {
          this.recordsTotal = responseData.length;
          for (var i = 0; i < responseData.length; i++) {
            this.AddRecord(responseData[i])
          }
        } else {
          this.recordsTotal = "1";
          this.AddRecord(responseData)
        }
        this.gs.localGetterSetter(null, this.options, this.sessionKey);
      } else {
        this.gs.localGetterSetter("totalRecords", '0', this.sessionKey);
      }
    },(error)=>{
      this.gs.localGetterSetter("totalRecords", '0', this.sessionKey);
    })
  }

  AddRecord(record) {
    if (this.exceptId && this.exceptId == record.id) {
      this.manageTotal()
    }else{
      const copiedData = this.data.slice();
      copiedData.push(this.createRecordrecord(record));
      this.dataChange.next(copiedData);
    }
  }

  public createRecordrecord(record) {
    return {
      id: record.id,
    };
  }
  scrolling() {
    this.gs.setScrolling('.car-datatable', 1000, -20);
  }
  getUpperLimit(currentLastLimit, total) {
    if (currentLastLimit > total) {
      return total
    } else {
      return currentLastLimit
    }
  }
  public firstList() {
    return this.gs.firstList(this.sessionKey, this.options);
  }
  public lastList() {
    return this.gs.lastList(this.sessionKey);
  }
  paginateByLimit(event) {
    this.gs.setScrolling('body', 600, 0);
    this.options.limit = event.pageSize;
    this.options.start = 0;
    this.scrolling();
    this.setDataTable();
  }
  paginate(event, type) {
    this.scrolling();
    if (type == "next") {
      this.options.start = this.options.start + this.options.limit;
    } else {
      this.options.start = this.options.start - this.options.limit;
    }
    this.setDataTable()
  }
  public setDataTable() { }
  public getTotalRecords() {
    let t=parseInt(localStorage.getItem(this.sessionKey + 'totalRecords'));
    return (isNaN(t) ? 0 : t );
  }
  getDetails(){
    return this.detailData;
  }
  manageTotal(){
    let total=parseInt(this.recordsTotal)-1;
    if(total>=0){
      this.recordsTotal=total
      this.gs.localGetterSetter("totalRecords",total,this.sessionKey);
    }

  }

}

export class CustomdatatableSource extends DataSource<any> {
  constructor(private _exampleDatabase, private _sort: MatSort, private _paginator: MatPaginator) {
    super();
    this._exampleDatabase.getAllData();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._exampleDatabase.dataChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return Observable.merge(...displayDataChanges).map(() => {
      let data = this._exampleDatabase.data.slice();
      // sort if needed
      if (this._sort.active && this._sort.direction !== '') {
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return this.getSortedData().splice(startIndex, this._paginator.pageSize);;
      }
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      return data.splice(startIndex, this._paginator.pageSize);
    });
  }

  public getSortedData(): any[] {
    const data = this._exampleDatabase.data.slice();
    if (!this._sort.active || this._sort.direction == '') { return data; }

    return data.sort((a, b) => {
      let propertyA;
      let propertyB;
      switch (this._sort.active) {
        case 'Name': propertyA = a.name; propertyB = b.name; break;
        case 'Status': propertyA = a.status; propertyB = b.status; break;
        default: return 0;
      }
      let valueA = isNaN(propertyA) ? propertyA.toUpperCase() : propertyA;
      let valueB = isNaN(propertyB) ? propertyB.toUpperCase() : propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
  disconnect() { }

}
