import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators,AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { IDatePickerConfig } from 'ng2-date-picker';
import * as moment from 'moment';

import {DatePipe} from '@angular/common';
import { Router } from '@angular/router';
@Component({
  templateUrl: './techassignment.component.html',
  styleUrls: ['./techassignment.component.scss'],
  providers: [DatePipe]
})

export class TechassignmentComponent implements OnInit {
  public tech: FormGroup;
  public shopTechnician=[];
  public userData;
  public techinianStatus=[];
  public techStatus=false;
  public editMode=false;
  public editShopForever=false;
  public adviser=[];
  public est_hours:any = 0;
  public est_minutes:any = 30;
  public estimated_hours:any = globalConstants.SR_ESTIMATED_HOUR;
  public selectSR:any;
  public onlySelf:boolean = false;
  public sTimeSlots:Array<any> = [];
  public eTimeSlots:Array<any> = [];
  public error:any={dError:false,tError:false};
  public fromConflict:boolean;
  public empId:any  = 0;
  public techId:any = 0;
  public request = '';
  datePickerConfig: IDatePickerConfig = {
    drops: 'down',
    opens: 'right',
    min: this.datePipe.transform(new Date(), 'MM/dd/yyyy'),
    format: "MM/DD/YYYY",
    closeOnSelect:true,
    disableKeypress: true,
    unSelectOnClick:false
  };
  constructor( public dialogRef: MatDialogRef<TechassignmentComponent>,@Inject(MAT_DIALOG_DATA) public data: any,private formBuilder: FormBuilder, public globalService: GlobalService,private datePipe: DatePipe,public router: Router) { 
 
    if('data' in data ){
      this.selectSR = data.data;     
    }
    if('onlySelf' in data){
      this.onlySelf = true;
    }

    this.sTimeSlots =  globalConstants.TIME_SLOTS;
    this.eTimeSlots =  globalConstants.TIME_SLOTS;

    this.userData=this.globalService.getCurrentUser();
    this.tech = this.formBuilder.group({
      assign_tomyself       :[2],
      emp_id                :['',[Validators.required]],
      adviser               :[''],
      skip_forever          :[''],
      request_id            : [data.request_id],
      user_id               :[this.userData.id],
      user_type             :[this.userData.user_type],
      edit_mode             :[''],
      event_start_date      :['',[Validators.required]],
      event_end_date        :['',[Validators.required]],
      event_start_time      :['',Validators.required],
      event_end_time        :['',Validators.required],
      overwrite_time        :[false],
      force_action          :[0]
    },{validator: this.checkDates});
    this.techStatus     =(typeof  data.techStatus!='undefined'?data.techStatus:false);
    this.editMode       =(typeof  data.editMode!='undefined'?data.editMode:false);
    this.fromConflict   =(typeof  data.fromConflict!='undefined'?data.fromConflict:false);
    this.empId          =(typeof  data.empId!='undefined'?data.empId:0);
    this.techId         =(typeof  data.techId!='undefined'?data.techId:0);
 
    if((this.techStatus==false || this.onlySelf==true) && this.fromConflict==false){
      this.tech.controls.assign_tomyself.setValue(1);
      this.tech.controls.emp_id.setValue(this.userData.id);
    }else{
      this.tech.controls.assign_tomyself.setValue(2);
    }
    
    this.editShopForever=this.globalService.getAcl('shop_acc_info','edits');
    this.globalService.diaLogObj(this.dialogRef);
    if('request_id' in data){
      this.getServiceDetails();
    }

    this.tech.get("assign_tomyself").valueChanges.subscribe(x => {
      if(x==1){      
        this.tech.controls.emp_id.setValue(this.userData.id);
      }else{
        this.tech.controls.emp_id.setValue('');
      }
    });  
  }

  checkDates(group: FormGroup) {
    if(group.controls.event_start_date.value==group.controls.event_end_date.value && group.controls.event_end_time.value < group.controls.event_start_time.value) {
      return { notValid:true }
    }
    return null;
  }
/**
   * @trackID <:CAR-1515> show manager/adviser in employee list
   * @updated 2020-04-10
   * @updatedBY Vikas Kumar
   * @param 
   * @return
   */
  ngOnInit() {
    let content='user_id='+this.userData.id+'&user_type='+this.userData.user_type;
    if(this.techStatus){
      let data=content+'&request_id='+this.tech.value.request_id;
      this.globalService.callAPI(globalConstants.API_SERVICE_REQUEST_TRACKERS,data).subscribe((r:any)=>{
        if(r.result==1){
          this.techinianStatus=r.data;
        }
      })
    }else{
    this.globalService.callAPI(globalConstants.API_GET_TECHNICIAN,content).subscribe((tech:any)=>{
      if(tech.result==1){
          if(tech.data.length){
            for(var i in tech.data){
              tech.data[i].text=tech.data[i].first_name+' '+tech.data[i].last_name;
              if(this.fromConflict == true && this.empId!=tech.data[i].id){
                this.shopTechnician.push(tech.data[i]);
              }else if(this.fromConflict == false){
                this.shopTechnician.push(tech.data[i]);
              }             
            }
          }
          if(typeof tech.employes != 'undefined' && tech.employes.length){
            for(var i in tech.employes){
              tech.employes[i].text=tech.employes[i].first_name+' '+tech.employes[i].last_name;
              if(this.fromConflict == true && this.empId!=tech.data[i].id){
                this.adviser.push(tech.employes[i]);
              }else if(this.fromConflict == false){
                this.adviser.push(tech.employes[i]);
              }
            }
          }
      }
    });
  }
    
  }
  assignTotech(forcAction=null,conflictAction=null){
    this.error.dError = false;
    this.error.tError = false;

    if(moment(new Date(this.tech.controls['event_start_date'].value)).format('MM/DD/YYYY') > moment(new Date(this.tech.controls['event_end_date'].value)).format('MM/DD/YYYY')){
      this.error.dError = true;
    }

    if(moment(new Date(this.tech.controls['event_start_date'].value)).format('MM/DD/YYYY')==moment(new Date(this.tech.controls['event_end_date'].value)).format('MM/DD/YYYY') && this.tech.controls['event_start_time'].value > this.tech.controls['event_end_time'].value){
      this.error.tError = true;
    }
    console.log('ssqq',this.tech.valid);
    console.log('dError', this.error.dError);
    console.log('tError', this.error.tError);
    if(this.tech.valid && this.error.dError==false && this.error.tError==false){

      if(this.tech.value.assign_tomyself==1){
        this.tech.controls.emp_id.setValue(this.userData.id);
      }

      if(this.editMode){
        this.tech.controls.edit_mode.setValue(1);
      }
      console.log('adfa',this.tech.value)
      const data = Object.assign({},this.tech.value);
      data['event_start_date'] = moment(data.event_start_date+" "+data.event_start_time).format('MM/DD/YYYY hh:mm A Z');
      data['event_end_date']   = moment(data.event_end_date+" "+data.event_end_time).format('MM/DD/YYYY hh:mm A Z');
      data['overwrite_time']   = data.overwrite_time?1:0;
      if(forcAction > 0){
        data['force_action']   = forcAction;
      }
      if(this.fromConflict == true && this.techId!=0){
        data['assign_to_other']  = 1;
        data['current_assigne']  = this.techId; 
      }
      if(conflictAction > 0){
        data['adding_conflict']= conflictAction;
      }
      console.log('data',(data));
      this.globalService.formData(globalConstants.API_ASSIGN_SERVICE_REQUEST,this.globalService.formsData(data)).subscribe((tech)=>{
        if(tech.result==1){
          this.globalService.snackbar("success", tech.message);
          this.dialogRef["techAssign"]=true;
          this.dialogRef.close(1);
        }else{         
          if([730,731,732,734,740].includes(parseInt(tech.code))){
            let code:any = tech.code;
            this.globalService.confirmDialog('techAssign', tech.message).subscribe((resp:any) => {
              let fStatus = {730:1,731:2,732:3,740:4,734:5};
              if(resp=='techAssign'){
                if([1,2].includes(fStatus[Number(code)])){
                  conflictAction = fStatus[Number(code)];
                }  
                this.assignTotech(fStatus[Number(code)],conflictAction);
              }
            });
          }else{
            this.globalService.snackbar("error", tech.message);
          }          
        }
      });
    }else{
      console.log('ss',this.tech);
     
      this.globalService.validateAllFormFields(this.tech);
    }
    
  }
  
  skipForever(event) {
    if (event.checked == true) {
        this.tech.controls.skip_forever.setValue(1);
    } else {
        this.tech.controls.skip_forever.setValue(0);
    }
}

  onAssignToTech(event,val){
    this.tech.controls.assign_tomyself.setValue(val);
  }
  assignToMyself(event,val){
    this.tech.controls.assign_tomyself.setValue(val);
  }
  getStatus(option){
    let status=['Pending','Processing','Processing','Completed'];
    return status[option];
  }

  deleteAssignee(tech,index){
    let content='user_id='+this.userData.id+'&user_type='+this.userData.user_type;
    var conf = "Are you sure?";
    let data=content+'&request_id='+this.tech.value.request_id+"&tech_id="+tech.id;
    this.globalService.confirmDialog('delTech', conf).subscribe((r) => {
      let res:any=r;
      if(res=='delTech'){
        this.globalService.callAPI(globalConstants.API_DELETE_SERVICE_REQUEST_ASSIGNEE,data).subscribe((r:any)=>{
          if(r.result==1){
            this.globalService.snackbar("success", r.message);
            if(typeof this.techinianStatus[index]!='undefined'){
              this.techinianStatus.splice(index,1);
            }
          }else{
            this.globalService.snackbar("error", r.message);
          }
        })
      }
    });
  }

  stopTimer(tech,index){
    let content='user_id='+this.userData.id+'&user_type='+this.userData.user_type;
    let conf = "Are you sure want to Pause/Complete the running timer?"; 
    if(tech.work_status=='2'){
      conf = "Are you sure want to Complete the running timer?"; 
    }
    this.globalService.confirmDialog('taskTrackConfirmStop', conf,300,{status:tech.work_status}).subscribe((r:any) => {    
      console.log('ss',r);
      if(r=='2' || r=='3' ){   
       let data=content+'&request_id='+this.tech.value.request_id+"&tech_id="+tech.user_id+'&tracker=' + btoa(r);
       this.globalService.callAPI(globalConstants.API_TRACK_SERVICE_REQUEST,data).subscribe((r:any)=>{
          if(r.result==1){
            this.globalService.snackbar("success", r.message);
            let tdata=content+'&request_id='+this.tech.value.request_id;
            this.globalService.callAPI(globalConstants.API_SERVICE_REQUEST_TRACKERS,tdata).subscribe((r:any)=>{
              if(r.result==1){
                this.techinianStatus=r.data;
              }
            });
          }else{
            this.globalService.snackbar("error", r.message);
          }
        })
      }
    });
  }

  
  startDateHandler(event){
    if(event){    
      var startDate = this.datePipe.transform(event, 'MM/dd/yyyy');
      var dt = new Date(this.datePipe.transform(event, 'MM/dd/yyyy hh:mm a'));
      if(typeof this.est_hours != 'undefined' && this.est_hours != 'null'){
        dt.setHours(dt.getHours() + this.est_hours);      
      }
      if(typeof this.est_minutes != 'undefined' && this.est_minutes != null){
        dt.setMinutes(dt.getMinutes() + this.est_minutes);
      }
      if((typeof this.est_hours != 'undefined' || typeof this.est_minutes != 'undefined')){
        let EndDate = this.datePipe.transform(dt, 'MM/dd/yyyy');
        this.tech.controls.event_end_date.setValue(EndDate);
        //this.setTime(moment(this.datePipe.transform(dt,'hh:mm a'),'hh:mm A').format('HH:mm'),2);
      }
    }
  }

  endDateHandler(event){

  }

  startTimeHandler(event){
    let tDate  = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
    let sTime  = moment(tDate+" "+event); 
    if(typeof this.est_hours != 'undefined' && this.est_hours != 'null'){
     sTime.add(this.est_hours,'hours');
    }
    if(typeof this.est_minutes != 'undefined' && this.est_minutes != 'null'){
      sTime.add(this.est_minutes,'minutes');  
    }
    if(typeof this.est_hours != 'undefined' || typeof this.est_minutes != 'undefined'){
      this.setTime(sTime.format('HH:mm'),2);
    }

  }

  getServiceDetails(){
   
    let content1 ='user_id='+this.userData.id+'&user_type='+this.userData.user_type+"&request_id=" + this.tech.get('request_id').value;
    this.globalService.callAPI(globalConstants.API_QUICKAPPT_SERVICE_REQUESTS,content1).subscribe((resp:any)=>{
      if(resp.result==1){
        if(typeof resp.data != 'undefined' && resp.data != ""){
          if(resp.data.est_hours!=null && resp.data.est_hours!='null'){
            this.estimated_hours  = resp.data.est_hours;
          }
          if(resp.data.hours!=null && resp.data.hours!='null'){
            this.est_hours        = resp.data.hours;
          }
          if(resp.data.minute!=null && resp.data.minute!='null'){
            this.est_minutes      = resp.data.minute;
          } 

          let todayDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm a');
          let dt = new Date(todayDate); 
          if('requested_delivery_date' in resp.data){
            let startDate = this.datePipe.transform(moment(resp.data.requested_delivery_date).toISOString(), 'MM/dd/yyyy hh:mm a');     
            if(startDate > todayDate){
              this.tech.controls.event_start_date.setValue(this.datePipe.transform(startDate, 'MM/dd/yyyy'));
              this.tech.controls.event_end_date.setValue(this.datePipe.transform(startDate, 'MM/dd/yyyy'));
              this.setTime(moment(this.datePipe.transform(startDate,'hh:mm a'),'hh:mm A').format('HH:mm'),1);
              dt = new Date(startDate);
            }else{
              this.tech.controls.event_start_date.setValue(this.datePipe.transform(todayDate, 'MM/dd/yyyy'));
              this.tech.controls.event_end_date.setValue(this.datePipe.transform(todayDate, 'MM/dd/yyyy'));
              this.setTime(moment(this.datePipe.transform(todayDate,'hh:mm a'),'hh:mm A').format('HH:mm'),1);
            }         
          }else{         
            this.tech.controls.event_start_date.setValue(this.datePipe.transform(todayDate, 'MM/dd/yyyy'));
            this.tech.controls.event_end_date.setValue(this.datePipe.transform(todayDate, 'MM/dd/yyyy'));
            this.setTime(moment(this.datePipe.transform(todayDate,'hh:mm a'),'hh:mm A').format('HH:mm'),1);
          }
          if(resp.data.hours){
            dt.setHours(dt.getHours() + resp.data.hours);
          }
          if(resp.data.minute){
            dt.setMinutes(dt.getMinutes() + resp.data.minute);
          }
          if(resp.data.hours  || resp.data.minute){
            this.tech.controls.event_end_date.setValue(this.datePipe.transform(dt, 'MM/dd/yyyy'));
            this.setTime(moment(this.datePipe.transform(dt,'hh:mm a'),'hh:mm A').format('HH:mm'),2);            
          }
         
        }
      }
    });
  }

  setTime(hoursMinutes,setField=1){
    const time    = hoursMinutes.split(":");
    let  hour     = time[0];
    let  minutes  = "00";
    if(time[1] > 0 && time[1] <= 15){
      minutes = "15";
    }else if(time[1] > 15 && time[1] <= 30){
      minutes = "30";
    }else if(time[1] > 30 && time[1] <= 45){
      minutes = "45";
    }else if(time[1] > 45){
      hour = Number(hour)+1;
    }

    hour    = hour.toString().padStart(2, "0");
    minutes = minutes.toString().padStart(2, "0");
        
    if(setField==1){
      this.tech.controls.event_start_time.setValue(hour+":"+minutes);
    }else{
      this.tech.controls.event_end_time.setValue(hour+":"+minutes);
    }
  }

  
  convertMinutes(num){
    if(num){
      let d = Math.floor(num/1440); // 60*24
      let h = Math.floor((num-(d*1440))/60);
      let m = Math.round(num%60); 
      let tTime = ""; 
      if(d>0) tTime += d + "D ";
      if(h>0) tTime += h + "H ";
      if(m>0) tTime += m+"M";
      return tTime;     
    }else{
      return "-";
    }  
  }

  convertISODate(dateString){
    return moment(dateString).toISOString();
  }

  checkPermission(moduleName,action){
    return this.globalService.getAcl(moduleName,action);
  }

  employeeReport(empId: any,request: string){
    this.router.navigate(['shops/employee/report/'+empId],{ queryParams: { req_id: request}});
  }
}

