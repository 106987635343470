import { Component, OnInit, ViewChild} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap,NavigationEnd, Params  } from '@angular/router';
import { Http,Headers, Response} from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { globalFunctions } from "../../../environments/globalFunctions";
import { globalConstants } from "../../../environments/globalConstants";
import {GlobalService} from "../../../environments/global.service";
import { lang } from "../../../environments/lang-en";
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TextMaskModule } from 'angular2-text-mask';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoaderService } from '../../base/services/LoaderService';

@Component({
    selector: 'shop-adddisclaimer',
    templateUrl: 'shopadddisclaimer.component.html',
    styleUrls: ['shopadddisclaimer.component.scss']
})
export class ShopadddisclaimerComponent implements OnInit{
    public currentCustomer;
    public currentCustomerName;
    public shopAddDisclaimer;
    constructor(
        private activatedRoute:ActivatedRoute,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        public titleService:Title,
        private http: Http,
        private router: Router,
        public snackBar: MatSnackBar, 
        public globalService: GlobalService,
        private loaderService: LoaderService
        ){
        this.globalService.setMetaData("SHOPS","ADD_DISCLAIMER")
        if(localStorage.getItem("loggedInUser")) {
            this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
            this.currentCustomerName = this.currentCustomer.first_name + this.currentCustomer.last_Name;
              this.shopAddDisclaimer = this.formBuilder.group({
                title: ['', Validators.required],
                description: ['', Validators.required],
                is_default: [''],
                user_type: this.currentCustomer.user_type,
                user_id: this.currentCustomer.id,
              });
            // End

        } else {
          this.router.navigate(["/shops"]);
        }
        setTimeout(()=>{
        let isEnable=this.globalService.getAcl('disclaimer','addNew');
        if(!(isEnable)){
            this.router.navigate(['shops/action/forbidden']);
        }
    },globalConstants.RELOAD_WAIT);
    }
    ngOnInit(){
       this.loaderService.hide();
    }


    adddisclaimer() {
        if(this.shopAddDisclaimer.valid) {
            var stringifiedFormData = this.globalService.formsData(this.shopAddDisclaimer.value);
            
            this.globalService.formData(globalConstants.API_ADD_DISCLAIMER_URL,stringifiedFormData).subscribe((data:any)=> {
                var message = data.message;
                localStorage.setItem("search_by","");
                localStorage.setItem("sort_by","");
                localStorage.setItem("sort_type","");
                localStorage.setItem("search_keyword","");
                localStorage.setItem("start","0");
                localStorage.setItem("limit","10");
                if (data.result == "1") {
                      this.router.navigate(['shops//list-disclaimers']);
                      this.globalService.snackbar('success', message);
                } else { 
                      this.globalService.snackbar('error', message);
                }
            });
        } else{
            this.mandatoryMsg();
        }
    }
    
    showTaxExempted(event) {
        if (event.checked) {
            this.shopAddDisclaimer.controls.is_default.setValue(1);
        } else {
            this.shopAddDisclaimer.controls.is_default.setValue(0);
        }
    }
    
    mandatoryMsg() {
        this.globalService.snackbar('error', globalConstants.FORM_SUBMISSION_VALIDATION_WARNING);
    }  
}
