<div class="display-full" id="mainContainer">
    <ng-progress *ngIf="showLoader" class="event-prevent full-page-loader" [speed]="1000" [trickleSpeed]="1000" [color]="'#f50057'" [thick]="false" [ease]="'cubic-bezier(0.755, 0.05, 0.855, 0.06)'"></ng-progress>
    <app-srnotelist *ngIf="globalservice.SRNoteListId.value"></app-srnotelist>
    
    <!-- Header -->
    <div class="mobile-view-header">
      <nav id="navbar" class="navbar-fixed-top" [class.full-nav]="navHeaderClose || router.url == '/error' || router.url == '/page-not-found' || router.url.split('/')[1] == 'pages' || (router.url.includes('/shops/register'))== true" *ngIf="!isLoginPage() && showCommonHeader" role="navigation">
          <div class="logo" >
              <div id="carLogo">
                  <a href="javascript:void(0)" (click)="dashBoardNavigationControl()" *ngIf="isUserLoggedIn() && router.url.split('/')[1] == 'shops'">
                    <img class="img-responsive center-block shop-logo margin-top-10" [src]="shopLogo" alt="Logo Image">
                  </a>
          
                  <a href="javascript:void(0)" [routerLink]="['/shops']" *ngIf="!isNoOneLoggedin() && router.url == '/'">
                    <img class="img-responsive center-block margin-top-10" src="assets/img/logo.png" alt="Logo Image">
                  </a>
          
                  <a href="javascript:void(0)" [routerLink]="['/shops']" *ngIf="router.url == '/shops/forgot_password' || router.url == '/pages/sforgot_password'">
                    <img class="img-responsive center-block margin-top-10" src="assets/img/logo.png"  alt="Logo Image">
                  </a>
                  
          
                <a href="javascript:void(0)" [routerLink]="['/shops']" *ngIf="router.url.toString() == '/page-not-found' || router.url.toString() == '/error'">
                  <img class="img-responsive center-block margin-top-10" src="assets/img/logo.png" alt="Logo Image">
                </a>
          
                <a href="javascript:void(0)" [routerLink]="['/shops']" *ngIf="router.url == '/customer/register' || router.url == '/pages/cregister'">
                  <img class="img-responsive center-block margin-top-10" src="assets/img/logo.png" alt="Logo Image">
                </a>
               
          
              <a href="javascript:void(0)" [routerLink]="['/shops']" *ngIf="router.url == '/pages/creset-password'">
                <img class="img-responsive center-block margin-top-10" src="assets/img/logo.png"  alt="Logo Image">
              </a>
              <a href="javascript:void(0)" [routerLink]="['/shops']" *ngIf="router.url == '/pages/sreset-password'">
                <img class="img-responsive center-block margin-right-10" src="assets/img/logo.png"  alt="Logo Image">
              </a>
              <a href="javascript:void(0)" [routerLink]="['/customer']" *ngIf="router.url ==  '/pcontact'">
                <img class="img-responsive center-block margin-right-10" src="assets/img/logo.png"  alt="Logo Image">
              </a>
              <a href="javascript:void(0)" [routerLink]="['/confirm-signup']" *ngIf="router.url =='/confirm-signup'">
                <img class="img-responsive center-block margin-right-10" src="assets/img/logo.png"  alt="Logo Image">
              </a>
              <a href="javascript:void(0)" [routerLink]="['/shops']" *ngIf="(router.url.includes('/shops/register') == true) || (router.url) == '/shops/register'">
                <img class="img-responsive center-block margin-right-10" src="assets/img/logo.png"  alt="Logo Image">
              </a>
              <a href="javascript:void(0)" [routerLink]="['/shops']" *ngIf="(router.url =='/shops-confirm-signup') || (router.url.indexOf('/shops-confirm-signup')!=-1)">
                <img class="img-responsive center-block margin-right-10" src="assets/img/logo.png"  alt="Logo Image">
              </a>
              <a href="javascript:void(0)" [routerLink]="['/shops']" *ngIf="(router.url =='/shops-customer-approval') || (router.url.indexOf('/shops-customer-approval')!=-1)">
                <img class="img-responsive center-block margin-right-10" src="assets/img/logo.png"  alt="Logo Image">
              </a>
              </div>
          </div>
          <div class="navbar-main">
          <div class="navbar-collapse" id="navbarHeader">
              <div class="nav navbar-nav navbar-right  nav-shop" *ngIf="isUserLoggedIn() && router.url.split('/')[1] == 'shops'">
                  <button id="logoutComponent" class="hide" (click)="logout('user')"></button>
                  <button class="relative" mat-icon-button title="Appointment Calendar" (click)="appointmentCal()" disableRipple>
                      <i class="fa-regular fa-calendar-days fa-xl"></i>
                    </button>
                  <button mat-icon-button class="notranslate" [matMenuTriggerFor]="menuNotify" title="Notifications" disableRipple>
                    <i class="fa fa-bell fa-xl"></i><span class="badge notify-badge" [innerHTML]="notifyCount"></span>
                  </button>
                 
                  <mat-menu #menuNotify="matMenu" class="msgnotify" [overlapTrigger]="false" xPosition="before">
                    <ul class="notification" >
                      <li *ngFor="let notify of notifications" (click)="showNotification(notify)" [class.notification-unread]="notify.read_status=='0'">
                        <span class="notification-icon"> <i class="fa fa-file-text"></i>  </span>
                        <p [innerHTML]="notify.msg"></p>
                        <span [innerHTML]="notify.add_date">            </span>
                      </li>
                      <li class="no-update" *ngIf="notifications.length==0">  <small>No New Updates</small>
                      </li>
                    </ul>
                    <div class="notification-btn">
                      <button mat-raised-button color="accent" [routerLink]="['shops/notification']">View All</button>
                      <button mat-raised-button color="accent" [disabled]="(notifications.length==0 || allRead)" class="pull-right" (click)="markNotification()">Mark all as read</button>
                    </div>
                  </mat-menu>
                  <mat-menu #menuElearn="matMenu" class="msgnotify mse-learning" [overlapTrigger]="false" xPosition="before">
                    <ul class="notification e-learning" *ngIf="eLearn.length > 0">
                      <li *ngFor="let e of eLearn" (click)="eLearnPortal(e)">
                        <p [class.notification-group]="e.items.length > 0">{{e.title}}</p>
                        <ul *ngIf="e.items.length > 0" class="list-inline">
                          <li *ngFor="let m of e.items" (click)="eLearnPortal(m)"><p>{{m.title}}</p></li>
                        </ul>
                      </li>
                    </ul>
                  </mat-menu>
                  <mat-menu #minimizedWindows="matMenu" class="msgnotify mse-learning" [overlapTrigger]="false" xPosition="before">
                    <ul class="notification e-learning minimized" *ngIf="modalsLength > 0">
                      <li *ngFor="let mg of modalsGroupedList">
                        <p [class.notification-group]="mg.items.length > 0">
                          {{mg.module}} Popups
                        </p>
                        <ul *ngIf="mg.items.length > 0" class="list-inline">
                          <li *ngFor="let m of mg.items">
                            <div class="row">
                              <div class="col-md-2 col-sm-2 col-xs-2">
                                <p class="text-right icon">
                                  <img [src]="gc.SERVICE_HISTORY_ICON" />
                                </p>
                              </div>
                              <div class="col-md-7 col-sm-7 col-xs-7">
                                <p class="text-left" (click)="restorePopup(m.id)">
                                  {{ m.name }}
                                  <span class="help-block text-uppercase">{{ m.data?.serviceInfo?.license_plate || 'N/A' }}</span>
                                </p>
                              </div>
                              <div class="col-md-3 col-sm-3 col-xs-3 padding-0">
                                <p class="text-right">
                                  <span class="action-icons"> 
                                    <i class="fa fa-window-restore" (click)="restorePopup(m.id)"></i> 
                                    <i class="fa fa-times" (click)="closePopup(m.id)"></i>
                                  </span>
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </mat-menu>
                  <button class="hidden-xs relative user-mat-menu" mat-raised-button [matMenuTriggerFor]="menu" (onMenuOpen)="menuOpenState()" (onMenuClose)="menuCloseState()" [class.dropdown-menu-open]="menuState" title="Welcome {{ currentUserName }}">
                    <span class="margin-right-10">Welcome <em class="notranslate">{{ currentUserName }}</em></span>        
                    <mat-icon class="position-vertical-center">arrow_drop_down</mat-icon>
                  </button>
                  <button mat-icon-button [matMenuTriggerFor]="menu" class="visible-xs pull-right">
                    <mat-icon>person_pin</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" [overlapTrigger]="false" class="mat-dropdown-menu" xPosition="before">
                    <button class="mat-menu-item"  (click)="dashBoardNavigationControl()">
                      <mat-icon>palette</mat-icon>
                      <span>Dashboard</span>
                    </button>
                    <button class="mat-menu-item" color="accent" [routerLink]="['/shops/edit-profile']">
                      <mat-icon>person</mat-icon>
                      <span>Edit Profile</span>
                    </button>               
                      <button class="mat-menu-item" color="accent" [routerLink]="['/shops/change-password']">
                        <mat-icon>security</mat-icon>
                        <span>Change Password</span>
                      </button>
                      <button class="mat-menu-item" color="accent" title="Click to Hide / Show" (click)="hideTutorial(1)">
                        <i class="fa fa-eye" aria-hidden="true" style="margin-right:5px;"></i>
                        <span>Training Video</span>
                      </button>
                      <button class="mat-menu-item" (click)="logout('user')">
                        <mat-icon>lock</mat-icon>
                        <span>Logout</span>
                      </button>
                    </mat-menu>
                  <button mat-icon-button [matMenuTriggerFor]="AllMenu" class="visible-xs pull-right">
                      <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #AllMenu="matMenu" class="mat-dropdown-menu" xPosition="before">
                          <button mat-menu-item *ngIf="happyFoxShow()" (click)="ShowChat()" disableRipple>
                              <i class="fa fa-comment fa-xl"></i>
                          <span>Live Support</span>
                          </button>
                          <!-- <button mat-menu-item class="notranslate hidden-xs" (click)="AppplyForLeave()" disableRipple>
                            <img class="width-22" src="../assets/img/timerOff-icon-white.svg">
                          <span>Add Leave</span>
                          </button> -->
                          <button mat-menu-item class="notranslate hidden-xs" (click)="bookAppointment()" disableRipple>
                              <i class="fa-regular fa-calendar-plus font-19 "></i>
                          <span>Book Appointment</span>
                          </button>
                          <button mat-menu-item class="notranslate hidden-xs" *ngIf="enableELearning && eLearn.length > 0" [matMenuTriggerFor]="menuElearn" title="Help Guide" disableRipple>
                              <i class="fa fa-book fa-lg"></i>
                          <span>Help Guide</span>
                          </button>
                          <button mat-menu-item class="notranslate hidden-xs" disableRipple *ngIf="isQuickQuote()" class="notranslate" (click)="quickQuote()">
                              <i class="fa fa-brands fa-quora fa-xl"></i>
                          <span>Quick Quote</span>
                          </button>
                          <button mat-menu-item *ngIf="!navHeaderClose" class="notranslate" disableRipple title="Service History Advanced Search" (click)="openServiceHistoryAdvanced()">
                              <img class="width-22" src="../../../../assets/img/service-history-white.svg">
                          <span>Service History</span>
                          </button>
                      </mat-menu>
              </div>
              <!-- right side Admin dropdown menu  -->
          </div>
          <!-- /.navbar-collapse -->
          </div>
      </nav>
      
  </div>
  <nav id="navbar" class="navbar-fixed-top desktop-view" [class.full-nav]="navHeaderClose || router.url == '/error' || router.url == '/page-not-found' || router.url.split('/')[1] == 'pages' || (router.url.includes('/shops/register'))== true" *ngIf="!isLoginPage() && showCommonHeader" role="navigation">
        <div class="logo" >
          <div id="carLogo">
            <a class="shop-logo-anchor" href="javascript:void(0)" (click)="dashBoardNavigationControl()" *ngIf="isUserLoggedIn() && router.url.split('/')[1] == 'shops'">
              <img class="img-responsive center-block shop-logo margin-top-10" *ngIf="showLogo" [src]="shopLogo"  alt="Logo Image">
              <img *ngIf="shopLogo != 'assets/img/logo.png' && showLogo == true " class="shop-powered" src="../assets/img/replacement_logos/powered_by_car_white.svg">
            </a>
            <a href="javascript:void(0)" [routerLink]="['/shops']" *ngIf="!isNoOneLoggedin() && router.url == '/'">
              <img class="img-responsive center-block margin-top-10" src="assets/img/logo.png" alt="Logo Image">
            </a>
  
            <a href="javascript:void(0)" [routerLink]="['/shops']" *ngIf="router.url == '/shops/forgot_password' || router.url == '/pages/sforgot_password'">
              <img class="img-responsive center-block margin-top-10" src="assets/img/logo.png"  alt="Logo Image">
            </a>
            <a href="javascript:void(0)" [routerLink]="['/shops']" *ngIf="router.url.toString() == '/page-not-found' || router.url.toString() == '/error'">
              <img class="img-responsive center-block margin-top-10" src="assets/img/logo.png"  alt="Logo Image">
            </a>
            <!-- extra remove it -->
            <a href="javascript:void(0)" [routerLink]="['/shops']" *ngIf="router.url == '/customer/register' || router.url == '/pages/cregister'">
              <img class="img-responsive center-block margin-top-10" src="assets/img/logo.png" alt="Logo Image">
            </a>
      
            <a href="javascript:void(0)" [routerLink]="['/shops']" *ngIf="router.url == '/pages/creset-password'">
              <img class="img-responsive center-block margin-top-10" src="assets/img/logo.png"  alt="Logo Image">
            </a>
            <a href="javascript:void(0)" [routerLink]="['/shops']" *ngIf="router.url == '/pages/sreset-password'">
              <img class="img-responsive center-block margin-top-10" src="assets/img/logo.png"  alt="Logo Image">
            </a>
            <a href="javascript:void(0)" [routerLink]="['/shops']" *ngIf="router.url ==  '/pcontact'">
              <img class="img-responsive center-block margin-top-10" src="assets/img/logo.png"  alt="Logo Image">
            </a>
            <a href="javascript:void(0)" [routerLink]="['/confirm-signup']" *ngIf="router.url =='/confirm-signup'">
              <img class="img-responsive center-block margin-top-10" src="assets/img/logo.png"  alt="Logo Image">
            </a>
            <a href="javascript:void(0)" [routerLink]="['/shops']" *ngIf="(router.url.includes('/shops/register') == true) || (router.url) == '/shops/register'">
              <img class="img-responsive center-block margin-top-10" src="assets/img/logo.png"  alt="Logo Image">
            </a>
            <a href="javascript:void(0)" [routerLink]="['/shops']" *ngIf="(router.url =='/shops-confirm-signup') || (router.url.indexOf('/shops-confirm-signup')!=-1)">
              <img class="img-responsive center-block margin-top-10" src="assets/img/logo.png"  alt="Logo Image">
            </a>
            <a href="javascript:void(0)" [routerLink]="['/shops']" *ngIf="(router.url =='/shops-customer-approval') || (router.url.indexOf('/shops-customer-approval')!=-1)">
              <img class="img-responsive center-block margin-top-10" src="assets/img/logo.png"  alt="Logo Image">
            </a>
          </div>
        </div>
  <div class="navbar-main">
    <div class="navbar-collapse" id="navbarHeader">
      <!-- right side self-checkin dropdown menu -->
      <div class="nav navbar-nav navbar-right" *ngIf="isUserLoggedIn() && router.url.split('/')[1] == 'shops' && router.url.split('/')[2] == 'self-checkin'">
        <button id="logoutComponent" class="hide" (click)="logout('admin')"></button>
        <button class="hidden-xs relative user-mat-menu" mat-raised-button [matMenuTriggerFor]="menu" (onMenuOpen)="menuOpenState()" (onMenuClose)="menuCloseState()" [class.dropdown-menu-open]="menuState" title="Welcome {{ currentAdminName }}">
          <span class="margin-right-10">Welcome <em class="notranslate">{{ currentUserName }}</em></span>
          <mat-icon class="position-vertical-center">arrow_drop_down</mat-icon>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="menu" class="visible-xs pull-right">
          <mat-icon>person_pin</mat-icon>
        </button>
        <mat-menu #menu="matMenu" [overlapTrigger]="false" class="mat-dropdown-menu" xPosition="before">
          <button mat-menu-item (click)="logout('user')">
            <mat-icon>lock</mat-icon>
            <span>Logout</span>
          </button>
        </mat-menu>
      </div>

      <!-- right side shops dropdown menu  -->
      <div class="nav navbar-nav navbar-right  nav-shop" *ngIf="isUserLoggedIn() && router.url.split('/')[1] == 'shops' && router.url.split('/')[2] !== 'self-checkin'">
        <button id="logoutComponent" class="hide" (click)="logout('user')"></button>
      
        <button type="button" id="staticModal" class="modal-btn-hide" (click)="staticModal.show()"></button>
        <button type="button" id="staticModalHide" class="modal-btn-hide" (click)="staticModal.hide()"></button>

        <button *ngIf="checkSMSPermission()" class="notranslate hidden-xs" mat-icon-button disableRipple title="Live SMS" (click)="openChatArea()">
          <img class="width-25" [src]="navHeaderClose ? '../assets/img/sms-icon-white.svg' : '../assets/img/sms-icon-black.svg'">
          <span *ngIf="globalBadgeCount > 0" class="badge notify-badge" [innerHTML]="globalBadgeCount"></span>
        </button>
        
        <button *ngIf="workFlowPermission" class="notranslate hidden-xs workflow-icon" mat-icon-button disableRipple title="Job Board" (click)="openWorkFlowPage()">
          <img class="width-22" [src]="navHeaderClose ? '../assets/img/workflow-white.svg' : '../assets/img/workflow-black.svg'">
        </button>
        <button *ngIf="!navHeaderClose" class="notranslate hidden-xs" mat-icon-button disableRipple title="Service History Advanced Search" (click)="openServiceHistoryAdvanced()" >
           <img class="width-20"  [src]="gc.SERVICE_HISTORY_ICON"  />
         </button>
        <button *ngIf="navHeaderClose hidden-xs" class="notranslate hidden-xs" mat-icon-button disableRipple title="Service History Advanced Search" (click)="openServiceHistoryAdvanced()" >
           <img class="width-20"  [src]="gc.SERVICE_HISTORY_ICON_HOVER"  />
         </button>
        <button mat-icon-button class="notranslate hidden-xs" *ngIf="modalsLength > 0" [matMenuTriggerFor]="minimizedWindows" title="Minimized Popups" disableRipple>
          <i class="fa fa-desktop fa-xl"></i>
          <span class="badge notify-badge" [innerHTML]="modalsLength"></span>
        </button>
        <button mat-icon-button  class="relative notranslate hidden-xs" disableRipple title="Quick Quote" *ngIf="isQuickQuote()" (click)="quickQuote()"><i class="fa fa-brands fa-quora fa-xl"></i></button>
        <button mat-icon-button class="notranslate hidden-xs" *ngIf="enableELearning && eLearn.length > 0" [matMenuTriggerFor]="menuElearn" title="Help Guide" disableRipple>
          <i class="fa fa-book fa-lg"></i>
        </button>
        <button class="relative" mat-icon-button title="Appointment Calendar" (click)="appointmentCal()" disableRipple>
          <i class="fa fa-regular fa-calendar-days font-19"></i>
        </button>
        <button class="relative hidden-xs" mat-icon-button title="Book Appointment" (click)="bookAppointment()" disableRipple>
          <i class="fa-regular fa-calendar-plus font-19 "></i>
        </button>
        <button *ngIf="quickSRCreatePermission" class="notranslate hidden-xs quickSR-plus-icon" mat-icon-button disableRipple title="Quick Service Request" (click)="handleQuickSRCreation()">
          <i class="fa fa-plus-circle text-white fa-xl" aria-hidden="true" *ngIf="navHeaderClose"></i>
          <i class="fa fa-plus-circle font-19" aria-hidden="true" *ngIf="!navHeaderClose"></i>
        </button>
        <button class="notranslate hidden-xs relative" mat-icon-button title="Apply Leave" (click)="AppplyForLeave()" disableRipple>
          <img class="width-21" [src]="navHeaderClose ? '../assets/img/timerOff-icon-white.svg' : '../assets/img/timerOff-icon-black.svg'">
      </button>

        <button class="relative hidden-xs" *ngIf="happyFoxShow()" mat-icon-button title="Live Support" (click)="ShowChat()" disableRipple><i class="fa fa-regular fa-comment fa-xl"></i></button>
        <button mat-icon-button class="notranslate" [matMenuTriggerFor]="menuNotify" title="Notifications" disableRipple>
          <i class="fa fa-regular fa-bell fa-xl"></i><span class="badge notify-badge" [innerHTML]="notifyCount"></span>
        </button>
        <mat-menu #menuNotify="matMenu" class="msgnotify" [overlapTrigger]="false" xPosition="before">
          <ul class="notification" >
            <li *ngFor="let notify of notifications" (click)="showNotification(notify)" [class.notification-unread]="notify.read_status=='0'">
              <span class="notification-icon"> <i class="fa fa-file-text"></i>  </span>
              <p [innerHTML]="notify.msg"></p>
              <span [innerHTML]="notify.add_date">            </span>
            </li>
            <li class="no-update" *ngIf="notifications.length==0">  <small>No New Updates</small>
            </li>
          </ul>
          <div class="notification-btn">
            <button mat-raised-button color="accent" title="View All" [routerLink]="['shops/notification']">View All</button>
            <button mat-raised-button color="accent" title="Mark all as read" [disabled]="(notifications.length==0 || allRead)" class="pull-right" (click)="markNotification()">Mark all as read</button>
          </div>
        </mat-menu>
         <button mat-icon-button [matMenuTriggerFor]="AllMenu" class="visible-xs">
            <mat-icon id="more_shops">more_vert</mat-icon>
          </button>
          <mat-menu
            #AllMenu="matMenu"
            [overlapTrigger]="false"
            class="mat-dropdown-menu"
            xPosition="before"
          >
          <button  class="mat-menu-item" title="Service History Advanced Search" (click)="openServiceHistoryAdvanced()" >
            <img class="width-22"  [src]="gc.SERVICE_HISTORY_ICON_HOVER"  /><span> Service History</span>
           </button>
            <button class="mat-menu-item" *ngIf="workFlowPermission" (click)="openWorkFlowPage()">
              <img class="workflow-icon-mob" src="../assets/img/workflow-white.svg">
              <span> Workflow</span>
            </button>
            <button
              class="mat-menu-item"
              *ngIf="happyFoxShow()"
              (click)="ShowChat()"
              disableRipple
              title=""
            >
              <i class="width-22 fa fa-regular fa-comment fa-xl"></i>
              <span> Live Support</span>
            </button>
            <button *ngIf="checkSMSPermission()"  class="mat-menu-item" disableRipple (click)="openChatArea()" title="Live SMS" >
              <img class="width-22 smsIcon-mob" src="../assets/img/sms-icon-white.svg">
             
              <span> Live SMS </span>
             </button>
            <button
              class="mat-menu-item"
              (click)="AppplyForLeave()"
              disableRipple
              title="Add Leave"
            >
            <img class="width-22 timerOff-mob" src="../assets/img/timerOff-icon-white.svg">
              <span> Add Leave</span>

            </button>
            <button
              class="mat-menu-item"
              (click)="bookAppointment()"
              disableRipple
              title="Book Appointment"
            >
              <i class="width-22 fa fa-regular fa-calendar-plus font-19 "></i>
              <span> Book Appointment</span>

            </button>
            <button class="mat-menu-item" *ngIf="quickSRCreatePermission" (click)="handleQuickSRCreation()">
              <i class="width-22 fa fa-plus-circle font-19" aria-hidden="true"></i>
              <span> Make Quick SR </span>
            </button>
            <button
              class="mat-menu-item"
              *ngIf="enableELearning && eLearn.length > 0"
              [matMenuTriggerFor]="menuElearn"
              title="Help Guide"
              disableRipple
            >
              <i class="width-22 fa fa-book fa-lg"></i>
              <span> Help Guide</span>

            </button>
            <button
              class="mat-menu-item"
              disableRipple
              *ngIf="isQuickQuote()"
              (click)="quickQuote()"
              title="Quick Quote"
            >

              <i class="width-22 fa fa-brands fa-quora fa-xl"></i>
              <span> Quick Quote</span>

            </button>
            <button
              class="mat-menu-item"
              *ngIf="!navHeaderClose"
              class="notranslate"
              disableRipple
              title="Service History Advanced Search"
              (click)="openServiceHistoryAdvanced()"
            >
              <img
                class="width-22"
                src="../../../../assets/img/service-history-white.svg"
              />
              <span>Service History</span>
            </button>
          </mat-menu>
        <mat-menu #menuElearn="matMenu" class="msgnotify mse-learning" [overlapTrigger]="false" xPosition="before">
          <ul class="notification e-learning" *ngIf="eLearn.length > 0">
            <li *ngFor="let e of eLearn" (click)="eLearnPortal(e)">
              <p [class.notification-group]="e.items.length > 0">{{e.title}}</p>
              <ul *ngIf="e.items.length > 0" class="list-inline">
                <li *ngFor="let m of e.items" (click)="eLearnPortal(m)"><p>{{m.title}}</p></li>
              </ul>
            </li>
          </ul>
        </mat-menu>
        <mat-menu #minimizedWindows="matMenu" class="msgnotify mse-learning" [overlapTrigger]="false" xPosition="before">
          <ul class="notification e-learning minimized minimized-window" *ngIf="modalsLength > 0">
            <li *ngFor="let mg of modalsGroupedList">
              <p [class.notification-group]="mg.items.length > 0">
                {{mg.module}} Popups
              </p>
              <ul *ngIf="mg.items.length > 0" class="list-inline">
                <li *ngFor="let m of mg.items">
                  <div class="row margin-0">
                    <div class="col-md-2 col-sm-2 col-xs-2">
                      <p class="text-right icon" *ngIf="m.module == 'Service Request'">
                        <img [src]="gc.SERVICE_HISTORY_ICON" />
                      </p>
                      <p class="text-right icon" *ngIf="m.module == 'Customer Live Chat'">
                        <img class="width-22" src="../assets/img/sms-icon-black.svg">
                      </p>
                    </div>
                    <div class="col-md-7 col-sm-7 col-xs-7 cursor-pointer" (click)="restorePopup(m.id)">
                      <p class="text-left">
                        {{ m.name }}
                        <span class="help-text text-uppercase">{{ m.data?.serviceInfo?.license_plate || 'N/A' }}</span>
                      </p>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-3">
                      <p class="text-right">
                        <span class="action-icons cursor-pointer">
                          <i class="fa fa-window-restore" (click)="restorePopup(m.id)"></i>
                          <i class="fa fa-times" (click)="closePopup(m.id)"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        
        </mat-menu>
        <button class="hidden-xs relative user-mat-menu" mat-raised-button [matMenuTriggerFor]="menu" (onMenuOpen)="menuOpenState()" (onMenuClose)="menuCloseState()" [class.dropdown-menu-open]="menuState" title="Welcome {{ currentUserName }}">
          <span class="margin-right-10 text-ellipsis">Welcome <em class="notranslate">{{ currentUserName }}</em></span>        
          <mat-icon class="position-vertical-center">arrow_drop_down</mat-icon>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="menu" class="visible-xs pull-right">
          <mat-icon>person_pin</mat-icon>
        </button>
        <mat-menu #menu="matMenu" [overlapTrigger]="false" class="mat-dropdown-menu" xPosition="before">
          <button class="mat-menu-item"  (click)="dashBoardNavigationControl()">
            <mat-icon>palette</mat-icon>
            <span>Dashboard</span>
          </button>
          <button class="mat-menu-item" color="accent" [routerLink]="['/shops/edit-profile']">
            <mat-icon>person</mat-icon>
            <span>Edit Profile</span>
          </button>
          <button class="mat-menu-item" color="accent" [routerLink]="['/shops/change-password']">
            <mat-icon>security</mat-icon>
            <span>Change Password</span>
          </button>
          <button class="mat-menu-item" color="accent" title="Click to Hide / Show" (click)="hideTutorial(1)">
            <mat-icon>visibility</mat-icon>
            <span>Training Video</span>
          </button>
          <button class="mat-menu-item" (click)="logout('user')">
            <mat-icon>lock</mat-icon>
            <span>Logout</span>
          </button>
        </mat-menu>
      </div>  
    </div>
    <!-- /.navbar-collapse -->
  </div>
  </nav>
  
  <div *ngIf="loadShopView() && showShopView == true && aclLodded" class="main-content shop-navbar-head" id="dashboard-content" [class.sidenav-hidden]="navHeaderClose">
    <mat-sidenav-container class="btn-block left-nav-container car-sidenav-main" animationFinished="true" (backdropClick)="sidenavToggle(sidenav2)" >
      <!-- Training Video Code Start-->
     <div class="training-logos quick-action" *ngIf="training_video_icon === '0' && currentUser.role_id !== '5'">
      <div class="quick-action-icon" (touchstart)="onTrainingLogoIconClick()"><img src="/assets/img/vedio-icon-1.svg"></div>
      <mat-accordion class="">
          <mat-expansion-panel [expanded]="showOptions" class="relative" id="quickActionExpansion" (mouseenter)="toggleOptionsOnHover()">
              <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="40px" id="quickActionexpansionHead" (click)="toggleOptions()">
                  <span class="margin-right-5"> </span><b>Training Videos</b>
              </mat-expansion-panel-header>
              <div class="row">
                      <div class=" col-xs-12">
                          <ul class="quickaction list-inline padding-0 margin-0">
                              <li> 
                                  <span><a href="https://youtu.be/ZdqjoF351J0" target="_blank">Overview Walkthrough Video</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/service-calendar') > -1">
                                  <span><a href="https://youtu.be/47CsVwXTcz4" target="_blank">Calendar Overview</a></span>
                              </li>
                              <li  *ngIf="currentUrl && currentUrl.indexOf('/shops/list-service-requests') > -1">
                                  <span><a href="https://youtu.be/iRI4sK1KMZ8" target="_blank">Closing Tickets and Payments</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/discounts') > -1">
                                  <span><a href="https://youtu.be/3R0WdQ666sc" target="_blank">Coupons </a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/dashboard') > -1">
                                  <span><a href="https://youtu.be/tsPXsw36Rrk " target="_blank">Dashboard Video</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/list-disclaimers') > -1">
                                  <span><a href="https://youtu.be/4YMMWVQNSwQ" target="_blank">Disclaimers</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/edit-service-request') > -1">
                                  <span><a href="https://youtu.be/Ej9wXULvWgY" target="_blank">Edit – Remove Job Category</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/employee/add') > -1">
                                  <span><a href="https://youtu.be/E1ngsfV25U8" target="_blank">Add New Employee Log In</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/list-computer-code') > -1">
                                  <span><a href="https://youtu.be/yPGSuw2yI4A" target="_blank">Fault codes</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/service-inspection-report') > -1">
                                  <span><a href="https://youtu.be/24aWG5T9l64" target="_blank">Fill Recommendation</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/new-generic-job') > -1">
                                  <span><a href="https://youtu.be/0xXGV87Voh4" target="_blank">Generic Jobs New and List</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/upload-contacts') > -1">
                                  <span><a href="https://youtu.be/hDBlGCmQjtU" target="_blank">Import Customer List</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/inspection-list') > -1">
                                  <span><a href="https://youtu.be/wsUPhfbvnzI" target="_blank">Vehicle Inspection Expiration List</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/internalnote/list') > -1">
                                  <span><a href="https://youtu.be/nXeAgd4ZXq4" target="_blank">Internal Notes</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/manage-labor-rates') > -1">
                                  <span><a href="https://youtu.be/LmrTzbsEV4M" target="_blank">Labor Rate</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/connections') > -1">
                                  <span><a href="https://youtu.be/iqMVkLKh0CY" target="_blank">MailChimp</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/manage-service-complaints') > -1">
                                  <span><a href="https://youtu.be/oS960cfD2-Y" target="_blank">Customer and Shop Messages and Live Chat</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/edit-profile') > -1">
                                  <span><a href="https://youtu.be/TN6DiTQS4oE" target="_blank">My Account Profile and Company profile</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/add-customer') > -1">
                                  <span><a href="https://youtu.be/v-AfBkGgEtM" target="_blank">Adding New Customer</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/add-part') > -1">
                                  <span><a href="https://youtu.be/150X55j_348" target="_blank">New Part Inventory</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/nexpart/vendors-list') > -1">
                                  <span><a href="https://youtu.be/auWGqNxPX-E" target="_blank">Nexpart Parts Vendor Integration</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/list-service-requests') > -1">
                                  <span><a href="https://youtu.be/VaIU5Sv0Afs" target="_blank">On The Lot</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/list-parts') > -1">
                                  <span><a href="https://youtu.be/lK0KoBFvJ5s" target="_blank">Parts List</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/part-matrix') > -1">
                                  <span><a href="https://youtu.be/7pEiOPu2dnU" target="_blank">Parts Matrix</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/parts-tech') > -1">
                                  <span><a href="https://youtu.be/lqeMAMNYEUg" target="_blank">Partstech Integration</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/return/queue') > -1">
                                  <span><a href="https://youtu.be/eKDbZxoUnZA" target="_blank">Return Queue</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/reports-stats-servicerequests') > -1">
                                  <span><a href="https://youtu.be/BEl25yxER7Q" target="_blank">Sales Over Due Reports and Employee logs</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/add-smart-batch') > -1">
                                  <span><a href="https://youtu.be/Jupe1TYBrgo" target="_blank">Smart Batch</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/stock-order') > -1">
                                  <span><a href="https://youtu.be/MHnYUf-vpUw" target="_blank">Stock Order</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/subscription') > -1">
                                  <span><a href="https://youtu.be/sP3ay2mAmoE" target="_blank">Upgrade Account</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/add-vendor') > -1" >
                                  <span><a href="https://youtu.be/MHAh2v2h17U" target="_blank">Vendor and Resources</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/list-customers') > -1" >
                                  <span><a href="https://youtu.be/ynaK5XazmSg" target="_blank">View/Search for all Customers</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/list-service-requests') > -1">
                                  <span><a href="https://youtu.be/-5edQcziGp4" target="_blank">View Service Request List</a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/list-service-requests') > -1">
                                  <span><a href="https://youtu.be/U0Lb3G33py8" target="_blank">Approval Stages: Waiting For Approval - Approval Acquired - Work in Progress </a></span>
                              </li>
                              <li *ngIf="currentUrl && currentUrl.indexOf('/shops/parts/import-list') > -1">
                                  <span><a href="https://youtu.be/_7f_llrglug" target="_blank">Bulk Upload List</a></span>
                              </li>
                              <li>
                                <span><a href="https://youtu.be/jochqxBmq3E" target="_blank">Book Appointment/Invoice</a></span>
                            </li>
                              <li>
                                <span><a (click)="hideTutorial(0)">Hide</a></span>
                              </li>
                          </ul>
              
                      </div>
              </div>
          </mat-expansion-panel>
      </mat-accordion>
     </div>
      <!-- Training Video Code End-->
      <mat-sidenav #sidenav2 [mode]="navMode" [opened]="navState" fixedInViewport="true" fixedTopGap="85" >
      <!-- <mat-sidenav #sidenav2 [mode]="{{navMode}}" [opened]="navState" fixedInViewport="true" fixedTopGap="85" > -->
        
        <div id="sidebar" class="col-xs-12 padding-0 sidebar-offcanvas">
          <div class="scrollbar-hide" id="scrollbar-hide">
            <div class="list-group bgcolor-grad panel" id="sidemenuAccordian" style="border-bottom: none;">
              <div *ngIf="currentUser.role_id !== '5'">
                <mat-slide-toggle class="mat-slide-toggle padding-10 shop-drag-opt" title="Enable Drag" labelPosition="before" (change)="dragStatus($event)" *ngIf="!navHeaderClose || dragShow"></mat-slide-toggle>
              </div>
              <ul id="sortableSidebar" data-shop-id="1" style="padding: 0; list-style: none;" class="ui-sortable">
                <mat-accordion class="car-expasion-panel display-full" id="items"  [dragula]='BAG'>            
                    <div class="menu-item" *ngFor="let shopmenu of menuForShop"  id="{{shopmenu.menu_id}}" [class.active]="shopMenuActive(shopmenu) || isSubMenuActive(shopmenu)">
                      <i *ngIf="!shopmenu.has_sub_menu" class="small fa {{shopmenu.menu_icon}} fa-fw" [routerLink]="[shopmenu.route]"></i>
                      <i *ngIf="shopmenu.has_sub_menu" class="small fa {{shopmenu.menu_icon}} fa-fw" ></i>
                      <mat-expansion-panel *ngIf="!shopmenu.has_sub_menu" [hideToggle]="shopmenu.has_sub_menu == '0'" [expanded]="shopMenuActive(shopmenu)" (click)="menuClose(sidenav2,shopmenu)">
                        <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" >
                          <i class="fa fa-ellipsis-v sortIcons" *ngIf="dragStatusVal"></i>
                          <i class="fa fa-fw {{shopmenu.menu_icon}}"></i>
                          <a class="sidebar-submenu-link btn-block" *ngIf="shopmenu.route !== '/shops/live-chat'" [routerLink]="[shopmenu.route]" (click)="menuClose(sidenav2,shopmenu)">{{shopmenu.menu_name}}</a>
                          <a class="sidebar-submenu-link btn-block" *ngIf="shopmenu.route == '/shops/live-chat'"  (click)="menuClose(sidenav2,shopmenu)">{{shopmenu.menu_name}}</a>
                        </mat-expansion-panel-header>
                      </mat-expansion-panel>
                      <mat-expansion-panel *ngIf="shopmenu.has_sub_menu" [hideToggle]="shopmenu.has_sub_menu == '0'" [expanded]="shopMenuActive(shopmenu)">
                        <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px" class="menu-item-expansion" [class.active]="isSubMenuActive(shopmenu)">
                          <i class="fa fa-ellipsis-v sortIcons" *ngIf="dragStatusVal"></i>
                          <i class="fa fa-fw {{shopmenu.menu_icon}}"></i>
                          <a class="sidebar-submenu-link btn-block" href="javascript:void(0);" >{{shopmenu.menu_name}}</a>
                        </mat-expansion-panel-header>
                      
                        <ul class="sidebar-submenu" *ngIf="shopmenu.has_sub_menu" [class.active]="true">
                          <ng-template ngFor let-subMenu [ngForOf]="shopmenu.sub_menu" let-subMenuIndex="index">
                              <li>
                                <a href="javascript:void(0)" class="sidebar-submenu-link customer" [routerLink]="[subMenu.route]" (click)="menuClose(sidenav2,subMenu);" [class.active]="router.url == subMenu.route"><i class="fa fa-fw {{subMenu.menu_icon}}"></i> {{subMenu.menu_name}}</a>
                              </li>
                            <ng-container *ngIf="subMenuIndex == 0 && shopmenu.vendors != undefined && shopmenu.vendors.length > 0">
                              <li *ngFor="let vendor of shopmenu.vendors">
                                <a href="{{vendor.link}}" target="_blank" class="sidebar-submenu-link" [class.active]="router.url == subMenu.route"><i class="fa fa-vcard"></i> {{vendor.vendor_name}}</a>
                              </li>
                            </ng-container>
                            <ng-container *ngIf="subMenuIndex == 0 && shopmenu.resources != undefined && shopmenu.resources.length > 0">
                                <li *ngFor="let resource of shopmenu.resources">
                                  <a href="{{resource.resource_link}}" target="_blank" class="sidebar-submenu-link" [class.active]="router.url == subMenu.route"><i class="fa fa-vcard"></i> {{resource.resource_name}}</a>
                                </li>
                              </ng-container>
                          </ng-template>
                        </ul>
                        </mat-expansion-panel>
                      </div>
  
                    <div class="menu-item level-0 mat-social-icon" data-icon="&#xf1e1;">
                      <mat-expansion-panel hideToggle="false">
                        <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px">
  
                          <a alt="Facebook" class="fontcolor-white footer-social-link" href="https://www.facebook.com/CARCompleteAutoReports"
                          target="_blank">
                          <i class="fa fa-fw fa-brands fa-facebook-f  fa-xl"></i>
                        </a>
  
                        <a alt="Google Plus" class="fontcolor-white footer-social-link" href="https://www.google.com/maps/place/Complete+Auto+Reports/@40.6268607,-74.268806,21z/data=!4m5!3m4!1s0x89c3b3a627b87d99:0x65176413b80837e5!8m2!3d40.6268838!4d-74.2686887"
                        target="_blank">
                        <i class="fa fa-fw fa-brands fa-google-plus-g fa-xl"></i>
                      </a>
  
                      <a alt="Twitter" class="fontcolor-white footer-social-link" href="https://twitter.com/CARAutoReports" target="_blank">
                        <i class="fa-brands fa-twitter fa-xl"></i>
                      </a>
  
                    </mat-expansion-panel-header>
                  </mat-expansion-panel>
                </div>
              </mat-accordion>
            </ul>
          </div>
  
        </div>
      </div>
    </mat-sidenav>
    <!-- Remove ads bar in shop -->
    <div class="display-full">
      <div class="col-xs-12 padding-top-15 subscription-alert-section" *ngIf="shopAdsExpStatus">
        <mat-toolbar class="subscription-alert-bar" (click)="gotoShopSubscription()">
          <div class="subscription-content">
            Your subscription is over. Please resubscribe to avail facilities.
          </div>
          <div class="subscription-btn">
            <button mat-raised-button class="font-bold mat-elevation-z0" color="accent">
              <i class="fa fa-money margin-right-5" aria-hidden="true"></i>
              <span>Subscribe Now</span>
            </button>
          </div>
        </mat-toolbar>
      </div>
      <div class="col-xs-12 padding-top-15 subscription-alert-section" *ngIf="shopAdsSubsStatus">
        <mat-toolbar class="subscription-alert-bar" (click)="gotoShopSubscription()">
          <div class="subscription-content">
            Subscribe now to start accepting service request from customers.
          </div>
          <div class="subscription-btn">
            <button mat-raised-button class=" font-bold mat-elevation-z0" color="accent" >
              <i class="fa fa-money margin-right-5" aria-hidden="true"></i>
              <span>Subscribe Now</span>
            </button>
          </div>
        </mat-toolbar>
      </div>
      <div class="col-xs-12 padding-top-15 subscription-alert-section" *ngIf="taxSettingStatus">
        <mat-toolbar class="subscription-alert-bar" (click)="editProfile()">
          <div class="subscription-content">
            Taxes and Supply Charges settings are pending. Click here to set now.
          </div>
          <div class="subscription-btn">
            <button mat-raised-button class="font-bold mat-elevation-z0" color="accent" >
              <i class="fa fa-money margin-right-5" aria-hidden="true"></i>
              <span>Setup Now</span>
            </button>
          </div>
        </mat-toolbar>
      </div>
      <div class="col-xs-12 padding-top-20 padding-bottom-20 main-wrapper">
        <div class="desktop-toggle-btn">
          <a href="javascript:void(0);" (click)="sidenav.toggle();sidenavToggle(sidenav)" class="offcanvas-toggle-button-large dashboard-toggle-btn">
              <img src="assets/img/side-close.svg">
          </a>
      </div>
      <div class="mobile-toggle-btn">
          <a href="javascript:void(0);" (click)="sidenav.toggle();sidenavToggle(sidenav)" class="offcanvas-toggle-button-large dashboard-toggle-btn">
              <i class="fa fa-bars"></i>
          </a>
      </div>
        <div class="relative" *ngIf="!showNoNLoggedInRoute && router.url.split('/')[1] == 'shops'">
          <router-outlet></router-outlet>
          <!--Start of HappyFox Live Chat Script-->
        </div>
      </div>
    </div>
  
    <div class="display-full car-footer">
      <div class="copyright-section">
        <p class="text-center">Copyright © {{cprDate}} Complete Auto Reports. All Rights Reserved</p>
      </div>
      <table width="135" border="0" cellpadding="2" cellspacing="0" class="footer-table" title="Click to Verify - This site chose Symantec SSL for secure e-commerce and confidential communications.">
        <tbody>
          <tr>
            <td width="135" align="center" valign="top">
              <script type="text/javascript" src="https://seal.websecurity.norton.com/getseal?host_name=www.completeautoreports.com&amp;size=L&amp;use_flash=NO&amp;use_transparent=YES&amp;lang=en"></script><img name="seal" src="https://seal.websecurity.norton.com/getseal?at=0&amp;sealid=0&amp;dn=www.completeautoreports.com&amp;lang=en&amp;tpt=transparent" oncontextmenu="return false;" border="0" usemap="#sealmap_large" alt="">
              <map name="sealmap_large" id="sealmap_large">
                <area alt="Click to Verify - This site has chosen an SSL Certificate to improve Web site security" title="" href="javascript:vrsn_splash()" shape="rect" coords="0,0,130,65" tabindex="-1" class="no-outline">
                <area alt="Click to Verify - This site has chosen an SSL Certificate to improve Web site security" title="" href="javascript:vrsn_splash()" shape="rect" coords="0,65,71,88" tabindex="-1" class="no-outline">
                <area alt="" title="" href="javascript:symcBuySSL()" shape="rect" coords="71,65,130,88" class="no-outline">
              </map>
              <br>
              <a href="http://www.symantec.com/ssl-certificates" target="_blank" class="ssl-link">ABOUT SSL CERTIFICATES</a>
            </td>
          </tr>
        </tbody>
      </table>
  
    </div>
  </mat-sidenav-container>
  </div>
  
  
  <div *ngIf="showNoNLoggedInRoute == true || router.url == '/page-not-found' || router.url == '/error'">
    <router-outlet></router-outlet>
  </div>
</div>
  <!-- show website refresh button  -->
  <span class="refresh-icon" *ngIf="globalVariable.showRefresh">
    <i class="fa fa-refresh" (click)="reloadPage()" aria-hidden="true"></i>
    <span class="reload-message">
      <p>
        We've tightened some nuts 'n bolts behind the scenes so we can make CAR better. Please click <a class="refresh-website-snackbar-reload"
          (click)="reloadPage()"><i class="fa fa-refresh" aria-hidden="true"></i></a> to get the latest updates.
      </p>
      <span class="down-arrow" >
        <i class="fa fa-caret-down" aria-hidden="true"></i>
      </span>
    </span>
  </span>
  <!-- E Learning -->
  <div class="help-mini-icon " id="movev" [class.hide]="showMinButton" *ngIf="isUserLoggedIn()">
    <img src="assets/img/{{minidragIcon}}" id="movevElearning" ontouchmove="movesLearing(event)" style="width:70px;" title="{{minidragTitle}}">
   <span (click)="elearn(1)" title="Restore {{minidragTitle}}" class="btn-maximize">
      <svg version="1.1" id="Layer_1" width="25" x="0px" y="0px"
         viewBox="0 0 512 512"  xml:space="preserve">
      <g>
        <g>
          <path fill="#ffffff" d="M503.172,0H8.828C3.953,0,0,3.953,0,8.828v273.655v220.69C0,508.047,3.953,512,8.828,512h220.69h273.655
            c4.875,0,8.828-3.953,8.828-8.828V8.828C512,3.953,508.047,0,503.172,0z M220.69,494.345H17.655V291.31H220.69V494.345z
             M494.345,494.345h-256V282.483c0-4.875-3.953-8.828-8.828-8.828H17.655v-256h476.69V494.345z"/>
        </g>
      </g>
      <g>
        <g>
          <path fill="#ffffff" d="M432.552,79.448H308.966c-4.875,0-8.828,3.953-8.828,8.828c0,4.875,3.953,8.828,8.828,8.828H411.24L261.171,247.172
            l12.484,12.484l150.069-150.069v102.275c0,4.875,3.953,8.828,8.828,8.828c4.875,0,8.828-3.953,8.828-8.828V88.276
            C441.379,83.401,437.427,79.448,432.552,79.448z"/>
        </g>
      </g>
      </svg>
    </span>
    <span (click)="elearn(3)" title="Close">
      <svg version="1.1" id="Capa_1" width="25" x="0px" y="0px"
         viewBox="0 0 60 60" xml:space="preserve">
      <g>
        <path fill="#ffffff" d="M0,0v60h60V0H0z M58,58H2V2h56V58z"/>
        <polygon fill="#ffffff" points="16.009,45.405 30.151,31.263 44.293,45.405 45.707,43.991 31.565,29.849 45.707,15.707 44.293,14.293
          30.151,28.435 16.009,14.293 14.595,15.707 28.737,29.849 14.595,43.991 	"/>
      </g>
  
      </svg>
    </span>
  </div>
  <!-- End E-learning -->
  <div class="modal fade modal-hide" bsModal #modal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="dialog-events-name">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" aria-label="Close" (click)="elearn(3)">
            <span aria-hidden="true">&times;</span>
          </button>
          <button type="button" class="close" (click)="elearn(2)">
            <span aria-hidden="true">&minus;</span>
          </button>
        </div>
        <div class="modal-body">
          <iframe id="partstech-iframe" *ngIf="iframeUrl!=''" [src]="iframeUrl" frameborder="0" width="100%" height="100%" style="background-color: white;"></iframe>
        </div>
      </div>
    </div>
  </div>
  
  <!-- Training video modal popup -->
  <div class="modal video-modal-popup fade modal-hide" bsModal #staticModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="dialog-events-name">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="close" aria-label="Close" (click)="closePopUpModel(0)">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
              <h3 class="margin-bottom-0 margin-top-0"><b>Welcome to CAR-Complete Auto Reports</b></h3>
              <p class="margin-bottom-0 margin-top-20"> Would you like to go watch a short Video Tutorial on how to use the software? </p>
              <p>(Video tutorial and interactive guides can be found on your <b>right</b> should you need to reference them or 
                  <b>click on the icon</b>)</p>
              <div class="video-btns">
                  <div class="row">
                      <div class="col-xs-6">
                          <a class="mat-raised-button mat-accent" href="https://youtu.be/ZdqjoF351J0" target="_blank" (click)="closePopUpModel(1)">Yes</a>
                      </div>
                      <div class="col-xs-6">
                          <button type="button" class="mat-raised-button mat-accent" (click)="closePopUpModel(0)">Maybe Later</button>
                      </div>
                  </div>  
              </div>
              <div class="margin-top-20">
                  <p>To permanently disable this popup on start, check this checkbox. 
                      &nbsp;<mat-checkbox  class="v-checkbox in-confirm" [(ngModel)]="disablePopup"></mat-checkbox>
                  </p>
              </div>
          </div>
        </div>
      </div>
  </div>

  <div bsModal #loaderModal="bs-modal" class="modal fade custom-ajax-spinner" [config]="{backdrop: 'static', keyboard: false}"
   data-backdrop="static" data-keyboard="false" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-body">
           <img src="assets/img/loader.gif" class="spinner-loader" width="150px" height="150px"/>
        </div>
      </div>
    </div>
  </div>