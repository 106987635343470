<div class="panel panel-primary permissions-component" id="mainCompotents">
    <div class="panel-heading padding-left-15">
      <h3 class="panel-title">Manage Roles & Permissions</h3>
    </div>
    <div class="panel-body bgcolor-lightgrey">
        <form (ngSubmit)="savePermission()" (keydown.enter)="$event.preventDefault()" *ngIf="roles && roles.length > 0" class="permission-form">
                    <div class="second-row table-responsive " >
                        <table class="table permission-tabel table-striped">
                            <thead>
                                <tr class="permission-btns header-part">
                                    <td>
                                        <ngx-select class="uc-ngx-select role-drop-down text-white"
                                            [items]="rolesList"
                                            [(ngModel)]="selectedRole" 
                                            (select)="onChangeRole($event)"
                                            placeholder=""
                                            [noAutoComplete]="true"
                                            [ngModelOptions]="{standalone: true}">
                                        </ngx-select>
                                    </td>
                                    <td>
                                        <div class="checkbox-data-block">
                                        </div>
                                    </td>
                                    <td>
                                        <div class="checkbox-data-block">
                                            <button class="tooltip-status text-white fa fa-eye" title="View All" type="button"></button>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="checkbox-data-block">
                                            <button class="tooltip-status text-white fa fa-plus-circle" title="Add All" type="button"></button>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="checkbox-data-block">
                                            <button class="tooltip-status text-white fa fa-pencil" title="Edit All" type="button"></button>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="checkbox-data-block">
                                            <button class="tooltip-status text-white fa fa-trash" title="Delete All" type="button"></button>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="permission-btns search-part">
                                    <th>
                                        <div class="manager-name">
                                            <div class="input-group">
                                                <input class="form-control border-end-0 border search-input" type="search" placeholder="Search" id="example-search-input" [(ngModel)]="search" [ngModelOptions]="{standalone: true}" (keyup.enter)="searchPermissions(selectedRole)" (input)="clearPermissions(selectedRole)">
                                                <span class="input-group-append">
                                                    <button class="btn btn-outline-secondary bg-white border-start-0 border-bottom-0 border ms-n5" type="button" (click)="searchPermissions(selectedRole)">
                                                        <i class="fa fa-search"></i>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="all-checkbox-block black-box">
                                        </div>
                                    </th>
                                    <th>
                                        <div class="all-checkbox-block">
                                            <mat-checkbox  [checked]="roles[selectedRole].all.view" name="view-all-check" labelPosition="after" (change)="checkAllAction(roles[selectedRole].id, selectedRole, 'view', roles[selectedRole].all.view)" [disabled]="roles[selectedRole].is_admin">
                                            </mat-checkbox>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="all-checkbox-block">
                                            <mat-checkbox  [checked]="roles[selectedRole].all.add" name="add-all-check" labelPosition="after" (change)="checkAllAction(roles[selectedRole].id, selectedRole, 'add', roles[selectedRole].all.add)" [disabled]="roles[selectedRole].is_admin">
                                            </mat-checkbox>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="all-checkbox-block">
                                            <mat-checkbox  [checked]="roles[selectedRole].all.edit" name="edit-all-check" labelPosition="after" (change)="checkAllAction(roles[selectedRole].id, selectedRole, 'edit', roles[selectedRole].all.edit)" [disabled]="roles[selectedRole].is_admin">
                                            </mat-checkbox>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="all-checkbox-block">
                                            <mat-checkbox  [checked]="roles[selectedRole].all.delete" name="delete-all-check" labelPosition="after" (change)="checkAllAction(roles[selectedRole].id, selectedRole, 'delete', roles[selectedRole].all.delete)" [disabled]="roles[selectedRole].is_admin">
                                            </mat-checkbox>
                                        </div> 
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let item of roles[selectedRole].items; let j = index">
                                    <tr *ngIf="item.show">
                                        <td >
                                            <div class="manager-name">
                                                <p>{{item.name}}</p>
                                            </div>
                                        </td>
                                        <td >
                                            <div class="checkbox-data-block permission-btns black-box visible-xs">
                                                <span [tooltip]="item.help_text" tooltipPlacement="top" class="tooltip-status text-black fa fa-info-circle"></span> 
                                            </div>
                                            <div class="row hidden-xs">
                                                <div class="col-sm-12"><p class="help-block margin-0"> {{item.help_text}}</p></div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="checkbox-data-block">
                                                    <mat-checkbox *ngIf="item.is_view==1" value="item.permissions.view" labelPosition="after" [checked]="item.permissions.view" (change)="checkPermission(item.role_id, selectedRole, 'view', item.permissions.view , item.permission_id, j)" [disabled]="roles[selectedRole].is_admin">
                                                    </mat-checkbox>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="checkbox-data-block">
                                                <mat-checkbox *ngIf="item.is_addNew==1" value="item.permissions.add" labelPosition="after" [checked]="item.permissions.add" (change)="checkPermission(item.role_id, selectedRole, 'add', item.permissions.add , item.permission_id, j)" [disabled]="roles[selectedRole].is_admin">
                                                </mat-checkbox>
                                            </div>
                                        </td>
                                        <td>
                                        <div class="checkbox-data-block">
                                                <mat-checkbox *ngIf="item.is_edit==1" value="item.permissions.edit" labelPosition="after" [checked]="item.permissions.edit" (change)="checkPermission(item.role_id, selectedRole, 'edit', item.permissions.edit , item.permission_id, j)" [disabled]="roles[selectedRole].is_admin">
                                                </mat-checkbox>
                                        </div>
                                        </td>
                                        <td>
                                        <div class="checkbox-data-block">
                                                <mat-checkbox *ngIf="item.is_delete==1" value="item.permissions.delete" labelPosition="after" [checked]="item.permissions.delete" (change)="checkPermission(item.role_id, selectedRole, 'delete', item.permissions.delete , item.permission_id, j)" [disabled]="roles[selectedRole].is_admin">
                                                </mat-checkbox>
                                        </div>
                                        </td>
                                    </tr>
                                </ng-container>
                                <tr class="no-result-row" *ngIf="showNoResult == true">
                                    <td colspan="6" class="text-center no-results-message">
                                        <p class="mat-error">No Results Found</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- <div class="role__list">
                        <div class="role__list--scroll">
                            <div class="row" *ngFor="let item of role.items; let j = index">
                                <div class="col-sm-7">
                                    <p>{{item.name}}</p>
                                </div>
                                <div class="col-sm-5">
                                    <div class="checkbox-group">
                                        <table>
                                            <tr>
                                                <td>
                                                    <mat-checkbox *ngIf="item.is_view==1" value="item.permissions.view" labelPosition="after" [checked]="item.permissions.view" (change)="checkPermission(item.role_id, i, 'view', item.permissions.view , item.permission_id, j)" [disabled]="role.is_admin">
                                                    </mat-checkbox>
                                                </td>
                                                <td>
                                                    <mat-checkbox *ngIf="item.is_addNew==1" value="item.permissions.add" labelPosition="after" [checked]="item.permissions.add" (change)="checkPermission(item.role_id, i, 'add', item.permissions.add , item.permission_id, j)" [disabled]="role.is_admin">
                                                    </mat-checkbox>
                                                </td>
                                                <td>
                                                    <mat-checkbox *ngIf="item.is_edit==1" value="item.permissions.edit" labelPosition="after" [checked]="item.permissions.edit" (change)="checkPermission(item.role_id, i, 'edit', item.permissions.edit , item.permission_id, j)" [disabled]="role.is_admin">
                                                    </mat-checkbox>
                                                </td>
                                                <td>
                                                    <mat-checkbox *ngIf="item.is_delete==1" value="item.permissions.delete" labelPosition="after" [checked]="item.permissions.delete" (change)="checkPermission(item.role_id, i, 'delete', item.permissions.delete , item.permission_id, j)" [disabled]="role.is_admin">
                                                    </mat-checkbox>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
            <div class="row btn-section margin-top-10">
                <div class="col-sm-6">
                    
                </div>
                <div class="col-sm-6 text-right">
                    <button type="button"  mat-raised-button class="margin-right-10 applyall" color="accent" *ngIf="loginType=='shops'" (click)="applyToAll(roles[selectedRole])" title="Apply to all">Apply To All</button>
                    <button  type="submit" class="" color="accent" mat-raised-button  title="Submit">
                        Submit
                    </button>
                </div>
            </div>
           
        </form>
    </div>
  </div>