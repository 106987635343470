<div class="car-panel" id="mainCompotents">
    <div class="panel panel-primary" >
        <div class="panel-heading padding-left-15">
            <h3 class="panel-title">
                Edit Computer Code
            </h3>
            <button mat-mini-fab class="custom-btn-back" title="Back" (click)="globalService.goBack();">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
        <div class="panel-body bgcolor-lightgrey" >
            <!-- edit computer code Customer page start -->
            <div class="row bgcolor-lightgrey form-content edit-computer-code">
                <div class="container-fluid">
                    <form [formGroup]="shopEditComputerCode" (ngSubmit)="editComputerCode()" class="form-inline col-xs-12 col-sm-12 col-md-12 col-lg-12" role="form" method="POST" enctype="multipart/form-data" autocomplete="off">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block">
                            <div class="row">
                                <div class="form-group  col-sm-12 col-md-6" *ngIf="currentComputerDetails">
                                    <label for="title">Trouble Code</label>
                                    <div class='relative'>
                                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                            <input matInput placeholder="Trouble Code*" maxlength="200" title="Trouble Code" formControlName="trouble_code"  class="form-control input-lg custom-form-input" [(ngModel)]="currentComputerDetails.trouble_code" />
                                            <mat-error *ngIf="shopEditComputerCode.controls.trouble_code.hasError('required')">
                                                This field cannot be empty
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
    
                            </div>
    
                             <div class="row">
                                <div class="form-group col-sm-12" *ngIf="currentComputerDetails">
                                    <label for="description">Fault Location</label>
                                    <div class="relative details-input">
                                        <mat-form-field class="btn-block car-input notes-input" floatPlaceholder="never" appearance="fill">
                                            <textarea matInput placeholder="Fault Location" rows="5" maxlength="1000" title="Fault Location" formControlName="fault_location" [(ngModel)]="currentComputerDetails.fault_location" rows="5" ></textarea>
                                            <mat-error *ngIf="shopEditComputerCode.controls.fault_location.hasError('required')">
                                                This field cannot be empty
                                            </mat-error>
                                        </mat-form-field>
    
                                    </div>
                                </div>
                            </div>
    
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block submit-block">
                                    <button type="submit" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent" title="Submit" style="max-width: 300px;">
                                        SUBMIT
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
   
</div>
