import { Component, Inject, ChangeDetectorRef, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { globalConstants } from '../../../../environments/globalConstants';
import { GlobalService } from '../../../../environments/global.service';
import { globalFunctions } from '../../../../environments/globalFunctions';
import {Http, Headers, Response} from '@angular/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
import {IDatePickerConfig} from 'ng2-date-picker';
import {DatePipe} from '@angular/common';
import {Observable} from 'rxjs/Observable';

@Component({
    selector: 'app-require-approval-dialog',
    templateUrl: './require-approval-dialog.component.html',
    styleUrls: ['./require-approval-dialog.component.scss'],
    providers: [DatePipe]
})
export class RequireApprovalDialogComponent implements OnInit {
    public phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    public addApprovalOneForm: FormGroup;
    public addApprovalTwoForm: FormGroup;
    public addApprovalThreeForm: FormGroup;
    public addApprovalFourForm: FormGroup;
    public request_id;
    public distance_covered;
    public addMileageResponse;
    public actionType;
    public accessTimeStamp;
    public formName;
    public APINAME;
    public getContacts;
    public setFullName;
    
    public currentCustomer;
    public currentUserID;
    public userType;
    public getCustomerDetails;
    public mobileNumber;
    
    myControl: FormControl = new FormControl();
    autoOptions = []
    filterOptionsSet: Observable<any[]>;
    
    datePickerConfig: IDatePickerConfig = {
        drops: "down",
        format: "MM/DD/YYYY hh:mm A",
        min: localStorage.getItem("requestCreatedDate"),//this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm a'),
        max: this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm a'),
        closeOnSelect:true,
        unSelectOnClick:false
    };
    approval_val:any = 1;

    approvalList = [
        {id:1, name:'In person?'},
        {id:2, name:'Over the phone?'},
        {id:3, name:'Text?'},
        {id:4, name:'Other?'},
    ];
    public jobStatus;
    public currentShopDetails;
    public allowBypassBtn;
    public customerID;
    public preAuthorization = false;
    public preAuthorizationPopUp = false;
    public authorizeBy;
    public contactPerson;
    public contactPersonPhone;
    public dateTimeOfCall;
    public otherMeansOfContact;
    public dateTimeOfCallValue;
    public fullRequestId:any;
    constructor( @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<RequireApprovalDialogComponent>, private formBuilder: FormBuilder, private globalService: GlobalService, private http: Http, private router: Router, public cdr: ChangeDetectorRef, private datePipe: DatePipe) {
        this.request_id      = data.service_request_id;
        this.accessTimeStamp = data.access_timestamp;
        this.currentCustomer = JSON.parse(localStorage.getItem("loggedInUser"));
        this.currentUserID   = this.currentCustomer.id;
        this.userType        = this.currentCustomer.user_type;
        this.jobStatus       = data.jobStatus;
        this.customerID      = data.customer_id;    
        this.fullRequestId   =data.fullRequestId;   
        this.globalService.diaLogObj(this.dialogRef);
        /* GET CONTACT LIST */
        let content='user_type=User'+"&user_id=" + data.user_id+"&customer_id=" + data.customer_id;
        this.globalService.callAPI(globalConstants.API_CONTACTS_ADD_LIST, content).subscribe((response:any) => {
            if (response.result == '1') {
                this.getContacts = response.data;
                this.autoOptions = this.getContacts;
                this.filterOptionsSet = this.myControl.valueChanges
                .startWith(null)
                .map(val => val ? this.filter(val) : this.autoOptions.slice());
            }
        });

        /* GET CUSTOMER DETAILS */
        var customerOprs = "user_id="+this.currentUserID+"&user_type="+this.userType+"&customer_id="+ data.customer_id;
        this.globalService.callAPI(globalConstants.API_GET_CUSTOMER_DETAILS, customerOprs).subscribe((response:any) => {
            if (response.result == '1') {
                this.getCustomerDetails = response.data;
                this.mobileNumber = this.getCustomerDetails.mobile_phone;
            }
        });
        /* END */
        
        /* GET SHOP DETAILS */
        var shopContent = "user_id=" + this.globalService.getCurrentUser()["id"] + "&user_type=" + this.globalService.getCurrentUser()["user_type"] + "&shop_id=" + this.globalService.getCurrentUser()["shop_id"];
        this.globalService.callAPI(globalConstants.API_SHOP_DETAILS, shopContent).subscribe((data:any) => {
            this.currentShopDetails = data.data;
            this.allowBypassBtn = this.currentShopDetails.allow_bypass;
        });
        /* END */
        
        /* GET IF APPROVAL IS DONE EARLIER */
        var requestOprs = "user_id="+this.currentUserID + "&user_type="+this.userType + "&request_id=" + this.request_id + "&shop_id=" + this.globalService.getCurrentUser()["shop_id"];
        this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUEST_APPROVAL, requestOprs).subscribe((response:any) => {
            if (response.result == '1') {
                this.getCustomerDetails = response.data
                this.preAuthorization   = true;
                this.authorizeBy        = this.getCustomerDetails.authorize_by;
                this.contactPerson      = this.getCustomerDetails.contact_person;
                this.contactPersonPhone = this.getCustomerDetails.contact_person_phone_number;
                let DateFormat          = this.globalService.DateFormat();
                this.dateTimeOfCall     = this.globalService.getFullFormateDate(this.getCustomerDetails.date_time_of_call,DateFormat,'H');
                this.dateTimeOfCallValue= this.getCustomerDetails.date_time_of_call;
                this.otherMeansOfContact= this.getCustomerDetails.other_means_of_contacts;
            } else {
                this.preAuthorization   = false;
            }
        });
        /* END */
        
        this.addApprovalOneForm = this.formBuilder.group({
            authorize_by: ['In person'],
            full_name: ['', [Validators.required]],
            contact_name: [''],
            request_id: [this.request_id],
            phone_number: [''],
            date_time_of_call: [this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm a'),[Validators.required]],
            access_timestamp: [this.accessTimeStamp],
            shop_id:[this.globalService.getCurrentUser()["shop_id"]],
            customer_id:[this.customerID],
        });
        
        this.addApprovalTwoForm = this.formBuilder.group({
            authorize_by: ['Over the phone'],
            full_name: ['', [Validators.required]],
            contact_name: [''],
            request_id: [this.request_id],
            phone_number: ['',[Validators.required]],
            date_time_of_call: [this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm a'),[Validators.required]],
            access_timestamp: [this.accessTimeStamp],
            shop_id:[this.globalService.getCurrentUser()["shop_id"]],
            customer_id:[this.customerID],
        });
        
        this.addApprovalFourForm = this.formBuilder.group({
            authorize_by: ['Other'],
            other: ['', [Validators.required]],
            full_name: ['', [Validators.required]],
            contact_name: [''],
            date_time_of_call: [this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm a'),[Validators.required]],
            request_id: [this.request_id],
            access_timestamp: [this.accessTimeStamp],
            shop_id:[this.globalService.getCurrentUser()["shop_id"]],
            customer_id:[this.customerID],
        });
        
        this.addApprovalThreeForm = this.formBuilder.group({
            authorize_by: ['Text'],
            full_name: ['', [Validators.required]],
            contact_name: [''],
            request_id: [this.request_id],
            phone_number: ['',[Validators.required]],
            date_time_of_call: [this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm a'),[Validators.required]],
            access_timestamp: [this.accessTimeStamp],
            shop_id:[this.globalService.getCurrentUser()["shop_id"]],
            customer_id:[this.customerID],
        });
    }
    
    displayFn(val) {
        if (val != undefined && val != null) {
            return val.contact_name;
        }
    }
    
    filter(val: string): string[] {
        if (val.length != undefined) {
            var filteredValue = this.autoOptions.filter(option => option.contact_name.toLowerCase().indexOf(val.toLowerCase()) === 0);
            return filteredValue
        }
    }
    
    setOptionAsContact(event, approval_val) {
        this.setFullName = event.option.value.contact_name;
        if(approval_val == 1) {
            this.addApprovalOneForm.controls.phone_number.setValue(event.option.value.contact_number);
        } else if(approval_val == 2) {
            this.addApprovalTwoForm.controls.phone_number.setValue(event.option.value.contact_number);
         } else if(approval_val == 3) {
            this.addApprovalThreeForm.controls.phone_number.setValue(event.option.value.contact_number);
        } 
    }
    
    ngOnInit() {
        this.formName = this.addApprovalOneForm;
    }
    
    onlyNumericKey(event) {
      return globalFunctions.onlyDecimalNumberKey(event);
    }

    checkIfValid(approval_val) {
        if(approval_val == 1) {
            this.formName = this.addApprovalOneForm;
        } else if(approval_val == 2) {
            this.formName = this.addApprovalTwoForm;
        } else if(approval_val == 3) {
            this.formName = this.addApprovalThreeForm;
        } else if(approval_val == 4) {
            this.formName = this.addApprovalFourForm;
        }
        if (this.formName.valid) {
        } 
    }
    
    addApproval(approval_val) {
        let data;
        if(approval_val == 1) {
            if(typeof this.addApprovalOneForm.value.full_name == 'object') {
                this.addApprovalOneForm.controls.contact_name.setValue(this.setFullName);
            } else if(typeof this.addApprovalOneForm.value.full_name == 'string') { 
                this.addApprovalOneForm.controls.contact_name.setValue(this.addApprovalOneForm.value.full_name);
            }
            data = this.addApprovalOneForm.value;
        } else if(approval_val == 2) {
            if(typeof this.addApprovalTwoForm.value.full_name == 'object') {
                this.addApprovalTwoForm.controls.contact_name.setValue(this.setFullName);
            } else if(typeof this.addApprovalTwoForm.value.full_name == 'string') { 
                this.addApprovalTwoForm.controls.contact_name.setValue(this.addApprovalTwoForm.value.full_name);
            }
            data = this.addApprovalTwoForm.value;
        } else if(approval_val == 3) {
            if(typeof this.addApprovalThreeForm.value.full_name == 'object') {
            this.addApprovalThreeForm.controls.contact_name.setValue(this.setFullName);
            } else if(typeof this.addApprovalThreeForm.value.full_name == 'string') { 
                this.addApprovalThreeForm.controls.contact_name.setValue(this.addApprovalThreeForm.value.full_name);
            }
            data = this.addApprovalThreeForm.value;
        } else if(approval_val == 4) {
            if(typeof this.addApprovalFourForm.value.full_name == 'object') {
                this.addApprovalFourForm.controls.contact_name.setValue(this.setFullName);
            } else if(typeof this.addApprovalFourForm.value.full_name == 'string') { 
                this.addApprovalFourForm.controls.contact_name.setValue(this.addApprovalFourForm.value.full_name);
            }
            data = this.addApprovalFourForm.value;
        }
  
        if (this.formName.valid) {
            if(this.data.skip_reassign) data.skip_reassign = this.data.skip_reassign;
            var stringifiedFormData = globalFunctions.stringifyFormData(data, null);
            //this.globalService.callAPI(globalConstants.API_UPDATE_NOTIFY_TO_ADMIN_URL, stringifiedFormData).subscribe((response) => {
            this.globalService.callAPI(globalConstants.API_ADD_SERVICE_REQUEST_APPROVAL, stringifiedFormData).subscribe((response:any) => {
                var message = response.message;
                if(response.result=='1'){
                    this.dialogRef.close({opr:'save',access_timestamp:response.access_timestamp});
                    // this.router.navigate(['shops/list-service-requests']);
                    this.globalService.snackbar("success", message);
                }else{
                    Object.keys(this.formName.controls).map((controlName) => {
                        this.formName.get(controlName).reset();
                    });
                    this.globalService.snackbar("error", message);
                }
            });
        } 
    }
    
    changeForm(obj){
        let val=obj.value;
        if(val == 1) {
            this.formName = this.addApprovalOneForm;
        } else if(val == 2) {
            this.formName = this.addApprovalTwoForm;
        } else if(val == 3) {
            this.formName = this.addApprovalThreeForm;
        } else if(val == 4) {
            this.formName = this.addApprovalFourForm;
        }
    }
    
    phoneValidation(key,val){
        if(val == 1) {
            this.globalService.preferedServicesPhoneValidity(this.addApprovalOneForm,key);
            this.checkIfValid(val);
        } else if(val == 2) {
            this.globalService.preferedServicesPhoneValidity(this.addApprovalTwoForm,key);
            this.checkIfValid(val);
        } else if(val == 3) {
            this.globalService.preferedServicesPhoneValidity(this.addApprovalThreeForm,key);
            this.checkIfValid(val);
        } 
    }
    
    bypassApproval(){
        let content='access_timestamp=' + this.accessTimeStamp+"&request_id=" + this.request_id;
        this.globalService.callAPI(globalConstants.API_UPDATE_NOTIFY_TO_ADMIN_URL,content ).subscribe((response:any) => {
            if (response.result == "1") {
                this.dialogRef.close({opr:'bypass',access_timestamp:response.access_timestamp});
                // this.router.navigate(['shops/list-service-requests']);
                this.globalService.snackbar("success", response.message);
            } else {
                this.globalService.snackbar("error", response.message);
            }
        });
    }
    
    goForPriorAuthorization(){
        this.preAuthorizationPopUp = true;
    }
    
    submitPriorAuthorization(){
        var currentDate = new Date("Y-m-d H:i:s");
        let content="authorize_by=" + this.authorizeBy + "&contact_name=" + this.contactPerson + "&phone_number=" + this.contactPersonPhone + "&date_time_of_call=" + this.dateTimeOfCallValue + "&other=" + this.otherMeansOfContact + "&access_timestamp=" + currentDate + "&request_id=" + this.request_id +"&shop_id=" + this.globalService.getCurrentUser()["shop_id"] + "&customer_id=" + this.customerID + "&prior_auth=1" + "&add_mode=1";
        console.log(content);
        this.globalService.callAPI(globalConstants.API_ADD_SERVICE_REQUEST_APPROVAL, content).subscribe((response:any) => {
            var message = response.message;
            if(response.result=='1'){
                this.dialogRef.close({opr:'priorAuth',access_timestamp:response.access_timestamp});
                // this.router.navigate(['shops/list-service-requests']);
                this.globalService.snackbar("success", message);
            }else{
                Object.keys(this.formName.controls).map((controlName) => {
                    this.formName.get(controlName).reset();
                });
                this.globalService.snackbar("error", message);
            }
        });
    }
    
    goCancel(){
        this.preAuthorizationPopUp = false;
    }
}



