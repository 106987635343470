<div class="panel panel-primary">
  <div class="panel-heading padding-left-30">
    <h3 class="panel-title">Service Request Detail</h3>
    <button mat-mini-fab class="custom-btn-back" (click)="globalService.goBack();">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <div class="panel-body bgcolor-lightgrey">
    <div class="jumbotron padding-25 car-view-str">
      <div class="row">
        <div class="col-sm-6 col-md-6">
          <h3 class="margin-0">Service Request Information</h3>
        </div>
        <div class="col-sm-6 col-md-6">
          <a class="btn btn-success pull-right " id="downloadInvoice" *ngIf="serviceInfo.value.request_status=='6' || serviceInfo.value.request_status=='8'" name="downloadInvoice" target="_blank" href="{{getInvoicePdf()}}" title="">
            <i class="fa fa-download"></i>  Download Invoice
          </a>
        </div>
      </div>
      <hr>

      <form id="viewServiceRequestForm" class="form-horizontal" role="form">
        <!-- Replaced div grid structure with table -->
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Request ID:</label></div>
              <div class="col-sm-6">{{this.serviceInfo.value.request_id}}</div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Shop Name :</label></div>
              <div class="col-sm-6 break-word">
                {{this.serviceInfo.value.service_facility_name || this.serviceInfo.value.company_name}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Vehicle:</label></div>
              <div class="col-sm-6">
                {{vehicleName}}
                <p id="expMsgs" class="text-danger" style="font-size:11px;margin-top: 5px;margin-bottom: 0;">
                  <!-- <span *ngIf="chkDate(serviceInfo.value.insurance_expiration_date)">Vehicle Insurance Expired -{{serviceInfo.value.insurance_expiration_date | date:'MM/dd/yyyy'}}<br></span> -->
                  <!-- <span *ngIf="chkDate(serviceInfo.value.registration_expiration_date)">Vehicle Registration Expired - {{serviceInfo.value.registration_expiration_date | date:'MM/dd/yyyy'}}<br></span> -->
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>License Plate :</label></div>
              <div class="col-sm-6 break-word text-uppercase">
                {{this.serviceInfo.value.license_plate}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Title :</label></div>
              <div class="col-sm-6">
                {{this.serviceInfo.value.title}}
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>VIN Number :</label></div>
              <div class="col-sm-6 break-word text-uppercase" *ngIf="this.serviceInfo.value.vin!=undefined">
                {{this.serviceInfo.value.vin | slice:0:-8}}<span style="color:#eb1e4c">{{this.serviceInfo.value.vin.substr(this.serviceInfo.value.vin.length - 8)}}</span>
              </div>
              <div class="col-sm-6 break-word text-uppercase" *ngIf="this.serviceInfo.value.vin==undefined">
                "--"
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Customer Name :</label></div>
              <div class="col-sm-6">
                {{this.serviceInfo.value.first_name}} {{this.serviceInfo.value.last_name}}
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Requested Date :</label></div>
              <div class="col-sm-6 break-word">
                {{this.serviceInfo.value.requested_delivery_date}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Mobile Number :</label></div>
              <div class="col-sm-6">
                <a class="text-black" href="tel:{{this.serviceInfo.value.mobile_phone}}">{{this.serviceInfo.value.mobile_phone}}</a>
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Scheduled Date :</label></div>
              <div class="col-sm-6">
                {{this.serviceInfo.value.schedule_service_date}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Present Mileage (Miles) :</label></div>
              <div class="col-sm-6">
                {{this.serviceInfo.value.present_mileage}}
              </div>
            </div>
          </div>
          <div class="col-sm-6 margin-bottom-10">
            <div class="row">
              <div class="col-sm-6"><label>Estimated Date :</label></div>
              <div class="col-sm-6" *ngIf="getDateVerify( this.serviceInfo.value.estimated_delivery_date)">
                {{this.serviceInfo.value.estimated_delivery_date}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 margin-bottom-10">
            <div class="row">
              <div class="col-sm-3"><label>Notes :</label></div>
              <div class="col-sm-9" [innerHtml]="getNltoBr(serviceInfo.value.notes)">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 margin-bottom-10">
            <div class="row">
              <div class="col-sm-3"><label>Download Report :</label></div>
              <div class="col-sm-9">
                <span id="downloadReportUPSP" *ngIf="reportDownloadType==1" >
                  <a href="{{reportDownloadLink}}">(USPS-PMI-PS-Form)<i class="fa fa-download fa-fw "></i></a>
                </span>
                <span id="downloadReportACDELCO" *ngIf="reportDownloadType==2">
                  <a href="{{reportDownloadLink}}">(CAR Inspection Report)<i class="fa fa-download fa-fw "></i></a>
                </span>
                <span id="noReportPerformed" *ngIf="reportDownloadType==0">
                  No Report Performed
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" *ngIf="(serviceInfo.value.request_documents!=null && serviceInfo.value.request_documents.length > 0) || (serviceInfo.value.request_documents!=null && serviceInfo.value.request_images.length > 0)">
            <h4><strong>Documents &amp; Images</strong></h4>
            <div class="row">
              <div class="col-sm-6" *ngIf="serviceInfo.value.request_documents!=null">
                <div class="row">
                  <div class="col-sm-12 margin-bottom-5"><label>Document(s)</label></div>
                  <div class="col-sm-12 text-danger">
                    <div id="serviceRequestDocumentsView">
                      <a *ngFor="let doc of serviceInfo.value.request_documents" style="display:inline-block;margin:0px 5px 5px 0px;position:relative;" href="javascript:void(0)" (click)="invokeDownload(globalConstants.S3_BUCKET_URL+'Service-Request-Documents/'+doc.document_name,'ServiceRequestDocument',doc.document_name)" class="thumbnail">
                        <!-- <img style="width:80px;height:75px;" src="assets/img/adobe_pdf-logo.jpg"> -->
                        <span class="fa fa-file-pdf-o fa-4x" *ngIf="getFileIcons(doc.document_name)==3"></span>
                        <span class="fa fa-file-word-o fa-4x" *ngIf="getFileIcons(doc.document_name)==4"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-6" *ngIf="serviceInfo.value.request_documents.length == 0 && serviceInfo.value.request_documents==null">
                <div class="text-danger">
                  No documents uploaded.
                </div>
              </div>

              <div class="col-sm-6" *ngIf="serviceInfo.value.request_images!=null">
                <div class="row">
                  <div class="col-sm-12 margin-bottom-5"><label>Image(s)</label></div>
                  <div class="col-sm-12" *ngIf="serviceInfo.value.request_images!=null">
                    <ul class="list-inline margin-bottom-0" >
                      <li  class="relative view-images" *ngFor="let image of serviceInfo.value.request_images;let i=index;">
                          <a (click)="imageToolDialog($event,i,8,serviceInfo.value.request_images)" class="thumbnail" href="javascript:void(0);">
                              <img style="width:100px;height:75px;" src="{{globalConstants.S3_BUCKET_URL}}Service-Request-Pictures/thumb/{{image.image_name}}">
                          </a>
                      </li>
                  </ul>
                    <!-- <div id="serviceRequestPicturesView" *ngFor="let image of serviceInfo.value.request_images">
                      <a href="javascript:void(0);" onclick="viewLargeImageModal('0');" style="display:inline-block;margin:0px 5px 5px 0px;position:relative;" class="thumbnail">
                        <img style="width:100px;height:75px;" src="{{globalConstants.root_url}}Service-Request-Pictures/thumb/{{image.image_name}}">
                      </a>
                    </div> -->

                  </div>
                </div>
              </div>
              <div class="text-danger" *ngIf="serviceInfo.value.request_images==null">
                No images uploaded.
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>


    <mat-accordion class="new-request-expension view-request  margin-bottom-10 display-full" displayMode="flat" hideToggle="false">
      <mat-expansion-panel hideToggle="true" [expanded]="a==0"  *ngFor="let service_request of serviceInfo.value.service_request_list;let a=index" id="expansion-{{a+1}}" (click)="expansionOpen(a+1);"  [attr.data-state]="a==0">
        <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px">
          {{a+1}}. {{ service_request.name }}
        </mat-expansion-panel-header>
        <ul class="service-list-items" *ngIf="serviceInfo.value.request_status!='9'">
          <li  *ngFor="let servicerequestChild of service_request.children;let i = index">
            <p>{{i+1}}) {{servicerequestChild.name}}</p>
            <ul class="service-list-sub-items no-style" >
              <li  *ngIf="serviceInfo.value.notify_to_customer == '1' || serviceInfo.value.notify_to_admin == '1' ">
                <div class="row">
                  <div *ngFor="let recommendation of servicerequestChild.recommendations;let j = index">

                    <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.parts.length > 0">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Part(s):</label>
                        <div class="col-sm-9 col-lg-10 text-justify">
                          <div class="addedPartsItems">
                            <div class="partRow row" style="padding-top:3px;" *ngFor="let part of recommendation.parts;let k = index">
                              <div class="col-sm-8 pname"><span *ngIf="part.oem_part_number!=''">{{part.oem_part_number}} - </span>{{part.name}}<span *ngIf="part.description!=''"> - {{part.description}}</span></div>
                              <div class="col-sm-2 pprice " >$ {{parseFloats(part.part_price)}}</div>
                              <div class="col-sm-2 pquantity">{{part.quantity}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.generic_jobs.length > 0">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Generic Job(s):</label>
                        <div class="col-sm-9 col-lg-10 text-justify">
                          <div class="addedGenericJobs">
                            <div class="partRow row" style="padding-top:3px;" *ngFor="let gjob of recommendation.generic_jobs;let l = index">
                              <div class="col-sm-8 pname">{{gjob.name}}</div>
                              <div class="col-sm-2 pprice " >$ {{parseFloats(gjob.price)}}</div>
                              <div class="col-sm-2 ppqty">{{gjob.quantity}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.computer_codes.length > 0">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Computer Codes(s):</label>
                          <div class="col-sm-9 col-lg-10 text-justify">
                            <div class="addedGenericJobs">
                              <div class="partRow row" style="padding-top:3px;" *ngFor="let cjob of recommendation.computer_codes;let l = index">
                                <div class="col-sm-12 pname">{{cjob.trouble_code}} {{cjob.fault_location}}</div>
                               </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    <div class="col-sm-12 margin-bottom-15">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Estimated Labor:</label>
                        <div class="col-sm-9 col-lg-10 text-justify break-all">
                          <div class="addedComputerCodes">$ {{parseFloats(recommendation.estimated_labor)}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 margin-bottom-15">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Estimated Time:</label>
                        <div class="col-sm-9 col-lg-10 text-justify break-all">
                          <div class="addedComputerCodes">{{recommendation.estimated_time}} hour(s) </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.recommendation!=''">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Description of Work:</label>
                        <div class="col-sm-9 col-lg-10  text-justify break-all">
                          <p>{{recommendation.recommendation}}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.notes!=''">
                      <div class="form-group">
                        <label class="col-sm-3 col-lg-2 text-right control-label">Notes:</label>
                        <div class="col-sm-9 col-lg-10  text-justify break-all">
                          <p>{{recommendation.notes}}<span *ngIf="recommendation.notes==''">-</span></p>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-12 margin-bottom-15 already-uploaded-images" *ngIf="recommendation.uploaded_images.length > 0">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label"><b>Uploaded Image(s):</b></label>
                          <div class="col-sm-9 col-lg-10  text-justify" >
                            <ul class="list-inline margin-bottom-0" *ngIf="recommendation.uploaded_images.length > 0">
                                <li  class="relative view-images" *ngFor="let image of recommendation.uploaded_images;let i=index;">
                                    <a (click)="imageToolDialog($event,i,4,recommendation.uploaded_images)" class="thumbnail" href="javascript:void(0);">
                                      <img class="show-image" src="{{globalConstants.S3_BUCKET_URL}}recommendation-pictures/thumb/{{image.filename}}">
                                    </a>
                                </li>
                            </ul>
                          </div>
                          <div class="col-sm-9 col-lg-10 text-justify" *ngIf="recommendation.uploaded_images.length == 0">
                            <span class="text-danger">No images uploaded. </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 margin-bottom-15 already-uploaded-videos" *ngIf="recommendation.uploaded_videos.length > 0">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label"><b>Uploaded Video(s):</b></label>
                          <div class="col-sm-9 col-lg-10  text-justify" *ngIf="recommendation.uploaded_videos.length > 0">
                            <ul id="vehicle_video-ul" class="list-inline margin-bottom-0">
                              <li class="video-upload relative" *ngFor="let video of recommendation.uploaded_videos;let i=index">
                                <a class="thumbnail margin-bottom-0" href="javascript:void(0)" (click)="videoShow(video.filename)">
                                  <span class="relative video-bg">
                                    <mat-icon class="position-center play-video">play_circle_filled</mat-icon>
                                    <div class="image-loader" *ngIf="video.loader">
                                      <mat-progress-spinner color="accent" mode="indeterminate" strokeWidth="2" diameter="30"></mat-progress-spinner>
                                    </div>
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div class="col-sm-9 col-lg-10  text-justify" *ngIf="recommendation.uploaded_videos.length == 0">
                            <span class="text-danger">No videos uploaded.
                              <span></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    <div class="col-xs-12" *ngIf="recommendation.service_request_list_id!=700">


                      <div class="display-full text-right" *ngIf="serviceInfo.value.request_status!='3'">
                        <mat-button-toggle value="Accepted" class="toogle-btn btn-success" *ngIf="recommendation.approval_status=='1'" >Accepted</mat-button-toggle>
                        <mat-button-toggle value="Rejected" class="toogle-btn btn-danger" *ngIf="recommendation.approval_status=='2'" >Rejected</mat-button-toggle>
                      </div>
                    </div>

                  </div>
                </div>
              </li>
              <li  *ngFor="let secondChild of servicerequestChild.children;let j = index">
                <p>{{secondChild.name}}</p>
                <p class="service-list-sub-items" *ngIf="secondChild.input == 'imageupload'">
                  <span style="padding-left: 58px;">
                    <img src="{{globalConstants.S3_BUCKET_URL}}service-pictures/thumb/{{secondChild.value}} "/>
                  </span>
                </p>

                <p class="service-list-sub-items" *ngIf="secondChild.input == 'textarea'">
                  <span style="padding-left: 58px;word-wrap: break-word" class="display-full" [innerHtml]="getNltoBr(secondChild.value)">
                  </span>
                </p>
                <div class="row" *ngIf="serviceInfo.value.notify_to_customer == '1' || serviceInfo.value.notify_to_admin == '1' ">

                  <div *ngFor="let recommendation of secondChild.recommendations;let j = index">
                    <div  *ngIf="recommendation.service_request_list_id!='158'">
                      <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.parts.length > 0">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Part(s):</label>
                          <div class="col-sm-9 col-lg-10 text-justify">
                            <div class="addedPartsItems">
                              <div class="partRow row" style="padding-top:3px;" *ngFor="let part of recommendation.parts;let k = index">
                                <div class="col-sm-8 pname"><span *ngIf="part.oem_part_number!=''">{{part.oem_part_number}} - </span>{{part.name}}<span *ngIf="part.description!=''"> - {{part.description}}</span></div>
                                <div class="col-sm-2 pprice " >$ {{parseFloats(part.part_price)}}</div>
                                <div class="col-sm-2 pquantity">{{part.quantity}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.generic_jobs.length > 0">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Generic Job(s):</label>
                          <div class="col-sm-9 col-lg-10  text-justify">
                            <div class="addedGenericJobs">
                              <div class="partRow row" style="padding-top:3px;" *ngFor="let gjob of recommendation.generic_jobs;let l = index">
                                <div class="col-sm-8 pname">{{gjob.name}}</div>
                                <div class="col-sm-2 pprice " >$ {{parseFloats(gjob.price)}}</div>
                                <div class="col-sm-2 ppqty">{{gjob.quantity}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.computer_codes.length > 0">
                          <div class="form-group">
                            <label class="col-sm-3 col-lg-2 text-right control-label">Computer Codes(s):</label>
                            <div class="col-sm-9 col-lg-10  text-justify">
                              <div class="addedGenericJobs">
                                <div class="partRow row" style="padding-top:3px;" *ngFor="let cjob of recommendation.computer_codes;let l = index">
                                  <div class="col-sm-12 pname">{{cjob.trouble_code}} {{cjob.fault_location}}</div>
                                 </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      <div class="col-sm-12 margin-bottom-15">
                        <div class="form-group">
                          <label class="col-sm-3  col-lg-2 text-right control-label">Estimated Labor:</label>
                          <div class="col-sm-9 col-lg-10  text-justify break-all">
                            <div class="addedComputerCodes">$ {{parseFloats(recommendation.estimated_labor)}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 margin-bottom-15">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Estimated Time:</label>
                          <div class="col-sm-9 col-lg-10 text-justify break-all">
                            <div class="addedComputerCodes">{{recommendation.estimated_time}} hour(s) </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.recommendation!=''">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Description of Work:</label>
                          <div class="col-sm-9 col-lg-10  text-justify break-all">
                            <p>{{recommendation.recommendation}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 margin-bottom-15" *ngIf="recommendation.notes!=''">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Notes:</label>
                          <div class="col-sm-9 col-lg-10  text-justify break-all">
                            <p>{{recommendation.notes}}<span *ngIf="recommendation.notes==''">-</span></p>
                          </div>
                        </div>
                      </div>

                      <!-- Recommendation Images and Videos STARTS-->
                      <div class="col-sm-12 margin-bottom-15 already-uploaded-images" *ngIf="recommendation.uploaded_images.length > 0">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Uploaded Image(s):</label>
                          <div class="col-sm-9 col-lg-10 text-justify " *ngIf="recommendation.uploaded_images.length > 0">
                          <ul class="list-inline margin-bottom-0" >
                                <li  class="relative view-images" *ngFor="let image of recommendation.uploaded_images;let i=index;">
                                    <a (click)="imageToolDialog($event,i,4,recommendation.uploaded_images)" class="thumbnail" href="javascript:void(0);">
                                      <img class="show-image" src="{{recoImagePath}}{{image.filename}}">
                                    </a>
                                </li>
                            </ul>
                          </div>
                          <div class="col-sm-9 col-lg-10 text-justify"  *ngIf="recommendation.uploaded_images.length == 0">
                            <span class="text-danger">No images uploaded.
                              <span></span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 margin-bottom-15 already-uploaded-videos" *ngIf="recommendation.uploaded_videos.length > 0">
                        <div class="form-group">
                          <label class="col-sm-3 col-lg-2 text-right control-label">Uploaded Video(s):</label>
                          <div class="col-sm-9 col-lg-10 text-justify" *ngIf="recommendation.uploaded_videos.length > 0">
                              <ul id="vehicle_video-ul" class="list-inline margin-bottom-0">
                                <li class="video-upload relative" *ngFor="let video of recommendation.uploaded_videos;let i=index">
                                  <a class="thumbnail margin-bottom-0" href="javascript:void(0)" (click)="videoShow(video.filename)">
                                    <span class="relative video-bg">
                                      <mat-icon class="position-center play-video">play_circle_filled</mat-icon>
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          <div class="col-sm-9 col-lg-10 text-justify" *ngIf="recommendation.uploaded_videos.length == 0">
                            <span class="text-danger">No videos uploaded.
                              <span></span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <!-- Recommendation Images and Videos ENDS-->

                      <div class="col-xs-12" *ngIf="serviceInfo.value.request_status != '3'">

                        <div class="display-full text-right" >
                          <mat-button-toggle value="Accepted" class="toogle-btn btn-success" *ngIf="recommendation.approval_status==1" >Accepted</mat-button-toggle>
                          <mat-button-toggle value="Rejected" class="toogle-btn btn-danger" *ngIf="recommendation.approval_status==2" >Rejected</mat-button-toggle>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </li>
            </ul>
          </li>
        </ul>

      </mat-expansion-panel>
    </mat-accordion>
    <div class="final-calculation">
      <!-- <p ><b>Supply Charges</b> : ${{serviceInfo.value.supplies_charges}}</p> -->
      <h4 class="text-right" *ngIf="serviceInfo.value.notify_to_customer == '1' || serviceInfo.value.notify_to_admin == '1' "><b>Grand Total (Incl. of all taxes)</b> : $ {{ parseFloats(serviceInfo.value.grand_total) }}</h4>
      <h4 class="text-right"><b>Total Paid</b> : $ {{serviceInfo.value.total_paid == null ? "0.00" : serviceInfo.value.total_paid}}</h4>
    </div>
  </div>

</div>
