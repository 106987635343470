<div class="row chat-content" *ngIf="isOneWaySMSEnabled || isTwoWaySMSEnabled">
    <div class="col-sm-12 chat-header">
        <div class="header-wrap" *ngIf="conversationInfo">
            <div class="back-section">
                <div class="back-icon" *ngIf="onMobileScreen"><mat-icon
                        (click)="handleBackFormChatArea()">arrow_back</mat-icon></div>
                <div class="contactInfo-section" (click)="handleSelectContactInfo()">
                    <figure class="margin-right-10">
                        <!-- <div class="img-wrap"><img src="assets/images/faces/face1.jpg" alt="user" class="img-xs" />
                        </div> -->
                        <!-- <div class="status online"></div> -->
                        <div class="img-xs">
                            <p>{{getCustomerInitials()}}</p>
                        </div>
                    </figure>
                    <div class="contact-chatDetails-section flex">
                        <div class="contact-chatDetails">
                            <p class="text-bold margin-0">{{conversationInfo.first_name}} {{conversationInfo.last_name}}
                            </p>
                            <p class="margin-0"><a class="text-black cursor-pointer" href="tel:{{conversationInfo.mobile_phone}}" (click)="onMobileNumberClick($event)" [innerHTML]="conversationInfo.mobile_phone"></a></p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="header-icons">
                <a class="margin-right-15"><i class="fa fa-video-camera" aria-hidden="true"></i></a>
                <a class="margin-right-15"><i class="fa fa-phone" aria-hidden="true"></i></a>
                <a><i class="fa fa-user-plus" aria-hidden="true"></i></a>
            </div> -->
        </div>
    </div>
    <div class="server-errorBar" *ngIf="!socketConnectionStatus">
        <p>Chat Server not available</p>
    </div>
    <div class="col-sm-12 chat-body" *ngIf="conversationInfo" [hidden]="!conversationInfo" id="chat-area-view" >
        <ul class="messages chat-body-messgaes" id="chat-area-messages" [ngClass]="{
            'alert-configure-xs': !isIPhone && displayErrorMsg == 2,
            'alert-text-xs':!isIPhone && displayErrorMsg == 4,
            'alert-xs':!isIPhone && displayErrorMsg == 1,
            'unsubscribe-text-xs':!isIPhone && displayErrorMsg == 3,
            'unsubscribe-text-xs-iphone': isIPhone && displayErrorMsg == 3,
            'alert-configure-xs-iphone': isIPhone &&  displayErrorMsg == 2,
            'alert-text-xs-iphone': isIPhone && displayErrorMsg == 4,
            'alert-xs-iphone': isIPhone && displayErrorMsg == 1,
            'iphone-alert-xs': isIPhone && displayErrorMsg == 0
          }">
            <li *ngIf="showMoreButton"><button class="btn btn-gray btn-block margin-bottom-15 border no-outline"
                    (click)="paginate('2')">Load
                    More...</button></li>
            <li class="message-item"
                [class.friend]="chat.msg_from.toString() === conversationInfo.customer_id.toString()"
                [class.me]="chat.msg_from.toString() !== conversationInfo.customer_id.toString()"
                *ngFor="let chat of chats; trackBy:identify" id="chat_message_{{chat.id}}">

                <div class="img-xs" *ngIf="chat.msg_from.toString() === conversationInfo.customer_id.toString()">
                    <p>{{getCustomerInitials()}}</p>
                </div>


                <!-- Shop -->
                <div class="img-xs" *ngIf="chat.msg_from.toString() !== conversationInfo.customer_id.toString() && chat.msg_via!=='system'">
                    <p>{{getUserInitials(chat)}}</p>
                </div>
                <div class="img-xs system-icon" title="This is a system generated message" *ngIf="chat.msg_from.toString() !== conversationInfo.customer_id.toString() && chat.msg_via==='system'">
                    <i class="fa-solid fa-robot"></i>
                </div>

                <div class="content" id="chat_message_{{chat.id}}">
                    <div class="message">
                        <div class="bubble">
                            <p>{{chat.message}}</p>
                        </div>
                        <span class="posted_message">{{getTimeInString(chat.converted_time)}}</span>
                        <!-- this is conditional -->
                        <span class="text-bold" *ngIf="chat.msg_via.toLowerCase() === 'sms'">&nbsp; &#x2022; &nbsp;via
                            SMS</span>
                        <span class="text-bold" *ngIf="chat.msg_via.toLowerCase() === 'system'">&nbsp; &#x2022; &nbsp;via
                            CAR SYSTEM</span>
                    </div>
                </div>
            </li>
            <!-- <li class="unread-indicator"><span>Unread messages</span></li> -->
        </ul>
    </div>

    <div class="col-sm-12 chat-footer" [ngClass]="{
        'hide': !conversationInfo,
        'alert-xs': !isTwoWaySMSEnabled
      }">
        <div class="message-send-section" *ngIf="displayErrorMsg == 0 && conversationInfo">
            <div class="input-text-scroll margin-right-10">
                <textarea id="chat-input" [disabled]="!socketConnectionStatus" placeholder="Type a message"
                    [(ngModel)]="previousChatRequestQuery.query" (keyup.enter)="sendMessage()"
                    (keydown.enter)="onKeydown($event)" [ngModelOptions]="{standalone: true}"></textarea>
            </div>
            <div class="sendBtn-section">
                <button class="rounded-circle" [disabled]="!socketConnectionStatus">
                    <i class="fa fa-paper-plane" aria-hidden="true" (click)="sendMessage()"></i>
                </button>
            </div>
        </div>
        <div class="input-text-scroll margin-right-10" *ngIf="displayErrorMsg == 3">
            <h3 class="text-center info-text">This contact has been unsubscribed from messages. </h3>
        </div>
        <div class="input-text-scroll margin-right-10" *ngIf="displayErrorMsg == 4">
            <h3 class="text-center info-text">This contact is added in SMS ignore list. Please remove it from ignore list in order to send messages.</h3>
        </div>
        <div class="input-text-scroll" *ngIf="displayErrorMsg == 1">
            <app-alert-warning [addonPayloadValue]="gc.ADD_ONS_OBJ.twoWaySMS"></app-alert-warning>
        </div>
        <div class="input-text-scroll margin-right-10" *ngIf="displayErrorMsg == 2">
            <h3 class="text-center info-text">Two-way SMS is not enabled for this account. If you would like to enable, please reach out to CAR Support -<a href="tel:1 (833) 227-7778" class="underline-xs hover-link">1 (833) 227-7778.</a></h3>
        </div>
    </div>
    <div class="col-md-12 no-conversation-col" *ngIf="!conversationInfo">
        <i class="fa-solid fa-message"></i>&nbsp;
        <h2> No Conversation Selected</h2>
    </div>

</div>
<div class="row chat-content alert-xs" *ngIf="!isOneWaySMSEnabled && !isTwoWaySMSEnabled">
    <div class="col-sm-12">
        <app-alert-warning [addonPayloadValue]="gc.ADD_ONS_OBJ.oneWaySMS"></app-alert-warning>
    </div>
</div>