import {Component, OnInit, ViewChild, Input, ViewEncapsulation, ChangeDetectorRef, Inject} from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, ParamMap, NavigationEnd,Params} from '@angular/router';
import {DpDatePickerModule} from 'ng2-date-picker';
import {Http, Headers, Response, ResponseContentType} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
import {globalFunctions} from "../../../../environments/globalFunctions"
import {globalConstants} from "../../../../environments/globalConstants"
import {lang} from "../../../../environments/lang-en"
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TextMaskModule} from 'angular2-text-mask';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DateAdapter} from '@angular/material/core';
import {GlobalService} from "../../../../environments/global.service"
import {AppComponent} from "../../../app.component"
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ImagetooldialogComponent } from '../../../imagetooldialog/imagetooldialog.component';
import {VideoShowComponent} from '../../my-vehicles/video-show/video-show.component';
import  saveAs  from 'file-saver';
import { RedeemCouponComponent } from '../../../redeem-coupon/redeem-coupon.component';
import { AddComplaintDialogComponent } from '../add-complaint-dialog/add-complaint-dialog.component';

declare var jQuery:any;
declare var $ :any;
@Component({
  selector: 'app-view-request',
  templateUrl: './view-request.component.html',
  styleUrls: ['./view-request.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewRequestComponent implements OnInit {
  
  @ViewChild('viewRequestCoupon') viewRequestCoupon: RedeemCouponComponent;
  
  public request;
  public requestContent;
  public content;
  public idFromUrl;
  public serviceRequestData: FormGroup;
  public serviceRequestsList = [];
  public requestData;
  public totalAcceptedCalculation = []
  public rejectedJobCalculation = [];
  public grandTotalAcceptedArray = [];
  public grandTotalRejectedArray = [];
  public nonTaxableValue = [];
  public UnTaxedAcceptedValue = 0;
  public totalAcceptedValue ="";
  public totalRejectedValue ="";
  public totalReturnedValue;
  public finalShowAbleAmount;
  public conservingStatus = [];
  public statusAcceptText:any;
  public statusRejectText:any;
  public recIds = []
  public ObjectJson = {}
  public image;
  public document;
  public reportDownloadLink='';
  public reportDownloadType=0;
  public globalConstants = globalConstants;
  public applyChanges=false;
  public access_timestamp='';
  public currentUserId=0;
  public hideAllAction=false;
  public vehicleName = "";
  public isLaborTax = false;
  public balance = 0;
  public totalCCfeeValue = 0;
  public netPayable = 0; 
  
  serviceDetail:any;
  serviceRequestId:any;
  updatedInfoRequestedMessage:string = globalConstants.MESSAGE_INFORMATION_REQUESTED;
  constructor(public globalService: GlobalService,public cdr: ChangeDetectorRef, public router: Router,public dialog: MatDialog, private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, public http: Http) {
    this.globalService.setMetaData("CUSTOMER","VIEW_REQUEST")

  }
  show(obj){
    // console.log(obj)
  }
  ngOnInit() {
    this.initializePage();
  }
  
  resetCalculationVariables(){
    this.finalShowAbleAmount = 0;
    this.totalAcceptedCalculation = [];
    this.rejectedJobCalculation = [];
    this.grandTotalAcceptedArray = [];
    this.grandTotalRejectedArray = [];
    this.nonTaxableValue = [];
    this.UnTaxedAcceptedValue = 0;
  }
  
  initializePage(){
    this.resetCalculationVariables();
    this.initializeForm();
    this.setParams();
    this.currentUserId=this.globalService.getCurrentCustomer()["id"];
    var content = "customer_id=" + this.globalService.getCurrentCustomer()["id"] + "&service_request_id=" + this.idFromUrl;
    this.globalService.callAPI(globalConstants.API_GET_SERVICE_REQUEST_VIEW_URL, content)
    .subscribe((data:any) => {
      // console.log(data)
      if (data.result == "1") {
        this.serviceDetail = data.data;
        if(data.data.request_status=='3' && data.data.customer_id!=this.currentUserId){
          this.hideAllAction=true;
        }
        if(data.data.is_labor_tax_excluded=="1"){
          this.isLaborTax=true;
        }
        
        this.access_timestamp=data.data.access_timestamp;
        this.serviceRequestData.controls.add_date.setValue(this.globalService.getFullFormateDate(data.data.add_date,'G','H'))
        this.serviceRequestData.controls.address1.setValue(data.data.address1)
        this.serviceRequestData.controls.address2.setValue(data.data.address2)
        this.serviceRequestData.controls.balance_amount.setValue(data.data.balance_amount)
        this.serviceRequestData.controls.business_name.setValue(data.data.business_name)
        this.serviceRequestData.controls.business_phone.setValue(data.data.business_phone)
        this.serviceRequestData.controls.car_id.setValue(data.data.car_id)
        this.serviceRequestData.controls.city.setValue(data.data.city)
        this.serviceRequestData.controls.color.setValue(data.data.color)
        this.serviceRequestData.controls.comments.setValue(data.data.comments)
        this.serviceRequestData.controls.company_name.setValue(data.data.company_name)
        this.serviceRequestData.controls.completion_date.setValue(data.data.completion_date)
        this.serviceRequestData.controls.confirmation_token.setValue(data.data.confirmation_token)
        this.serviceRequestData.controls.country.setValue(data.data.country)
        this.serviceRequestData.controls.customer_id.setValue(data.data.customer_id)
        this.serviceRequestData.controls.customer_type.setValue(data.data.customer_type)
        this.serviceRequestData.controls.disclaimer.setValue(data.data.disclaimer)
        this.serviceRequestData.controls.disclaimer_id.setValue(data.data.disclaimer_id)
        this.serviceRequestData.controls.email.setValue(data.data.email)
        this.serviceRequestData.controls.estimated_delivery_date.setValue(this.globalService.getFullFormateDate(data.data.estimated_delivery_date,'G','H'))
        this.serviceRequestData.controls.feedback_code.setValue(data.data.feedback_code)
        this.serviceRequestData.controls.first_name.setValue(data.data.first_name)
        this.serviceRequestData.controls.grand_total.setValue(data.data.grand_total)
        this.serviceRequestData.controls.has_app_downloaded.setValue(data.data.has_app_downloaded)
        this.serviceRequestData.controls.home_phone.setValue(data.data.home_phone)
        this.serviceRequestData.controls.id.setValue(data.data.id)
        this.serviceRequestData.controls.inspection_reports.setValue(data.data.inspection_reports)
        this.serviceRequestData.controls.insurance_expiration_date.setValue(this.globalService.getFullFormateDate(data.data.insurance_expiration_date,"GD","B"))
        this.serviceRequestData.controls.is_DIY.setValue(data.data.is_DIY)
        this.serviceRequestData.controls.is_paid.setValue(data.data.is_paid)
        this.serviceRequestData.controls.is_supplies_charges_included.setValue(data.data.is_supplies_charges_included)
        this.serviceRequestData.controls.is_tax_included.setValue(data.data.is_tax_included)
        this.serviceRequestData.controls.is_usps_vehicle.setValue(data.data.is_usps_vehicle)
        this.serviceRequestData.controls.labor_amount.setValue(data.data.labor_amount)
        this.serviceRequestData.controls.labor_rate.setValue(data.data.labor_rate)
        this.serviceRequestData.controls.last_name.setValue(data.data.last_name)
        this.serviceRequestData.controls.license_plate.setValue(data.data.license_plate)
        this.serviceRequestData.controls.mail_sent.setValue(data.data.mail_sent)
        this.serviceRequestData.controls.make.setValue(data.data.make)
        this.serviceRequestData.controls.middle_name.setValue(data.data.middle_name)
        this.serviceRequestData.controls.mobile_phone.setValue(data.data.mobile_phone)
        this.serviceRequestData.controls.model.setValue(data.data.model)
        this.serviceRequestData.controls.modify_date.setValue(data.data.modify_date)
        this.serviceRequestData.controls.no_report_performed.setValue(data.data.no_report_performed)
        this.serviceRequestData.controls.non_taxable_amount.setValue(data.data.non_taxable_amount)
        this.serviceRequestData.controls.notes.setValue(data.data.notes)
        this.serviceRequestData.controls.notify_accept_reject_jobs_email.setValue(data.data.notify_accept_reject_jobs_email)
        this.serviceRequestData.controls.notify_accept_reject_jobs_push.setValue(data.data.notify_accept_reject_jobs_push)
        this.serviceRequestData.controls.notify_create_service_facility_car_email.setValue(data.data.notify_create_service_facility_car_email)
        this.serviceRequestData.controls.notify_create_service_facility_car_push.setValue(data.data.notify_create_service_facility_car_push)
        this.serviceRequestData.controls.notify_expiration_dates_email.setValue(data.data.notify_expiration_dates_email)
        this.serviceRequestData.controls.notify_expiration_dates_push.setValue(data.data.notify_expiration_dates_push)
        this.serviceRequestData.controls.notify_expiration_dates_web.setValue(data.data.notify_expiration_dates_web)
        this.serviceRequestData.controls.notify_feedback_email.setValue(data.data.notify_feedback_email)
        this.serviceRequestData.controls.notify_feedback_push.setValue(data.data.notify_feedback_push)
        this.serviceRequestData.controls.notify_make_changes_car_facility_service_request_email.setValue(data.data.notify_make_changes_car_facility_service_request_email)
        this.serviceRequestData.controls.notify_make_changes_car_facility_service_request_push.setValue(data.data.notify_make_changes_car_facility_service_request_push)
        this.serviceRequestData.controls.notify_send_updated_info_to_customer_email.setValue(data.data.notify_send_updated_info_to_customer_email)
        this.serviceRequestData.controls.notify_send_updated_info_to_customer_push.setValue(data.data.notify_send_updated_info_to_customer_push)
        this.serviceRequestData.controls.notify_service_completion_email.setValue(data.data.notify_service_completion_email)
        this.serviceRequestData.controls.notify_service_completion_push.setValue(data.data.notify_service_completion_push)
        this.serviceRequestData.controls.notify_to_admin.setValue(data.data.notify_to_admin)
        this.serviceRequestData.controls.notify_to_customer.setValue(data.data.notify_to_customer)
        this.serviceRequestData.controls.parts_amount.setValue(data.data.parts_amount)
        this.serviceRequestData.controls.password.setValue(data.data.password)
        this.serviceRequestData.controls.payment_date.setValue(data.data.payment_date)
        this.serviceRequestData.controls.payment_reminder_date.setValue(data.data.payment_reminder_date)
        this.serviceRequestData.controls.payment_reminder_status.setValue(data.data.payment_reminder_status)
        this.serviceRequestData.controls.present_mileage.setValue(data.data.present_mileage)
        this.serviceRequestData.controls.read_status.setValue(data.data.read_status)
        this.serviceRequestData.controls.recommendation_saved.setValue(data.data.recommendation_saved)


        this.serviceRequestData.controls.registration_expiration_date.setValue(this.globalService.getFullFormateDate(data.data.registration_expiration_date,"GD","B"))



        if(data.data.request_documents.length > 0) {
          this.serviceRequestData.controls.request_documents.setValue(data.data.request_documents)
        } else {
          this.serviceRequestData.controls.request_documents.setValue(null)
        }
        this.serviceRequestData.controls.request_id.setValue(data.data.request_id)
        if(data.data.request_images.length > 0) {
          this.serviceRequestData.controls.request_images.setValue(data.data.request_images)
        } else {
          this.serviceRequestData.controls.request_images.setValue(null)
        }
        this.serviceRequestData.controls.request_status.setValue(parseInt(data.data.request_status))
        this.serviceRequestData.controls.requested_delivery_date.setValue(this.globalService.getFullFormateDate(data.data.requested_delivery_date,'G','H'));
        this.serviceRequestData.controls.reset_pwd_token.setValue(data.data.reset_pwd_token)
        this.serviceRequestData.controls.schedule_service_date.setValue(this.globalService.getFullFormateDate(data.data.schedule_service_date,'G','H'));
        this.serviceRequestData.controls.service_facility.setValue(data.data.service_facility)
        this.serviceRequestData.controls.service_request_list.setValue(data.data.service_request_list)
        this.serviceRequestData.controls.shop_id.setValue(data.data.shop_id)
        this.serviceRequestData.controls.state.setValue(data.data.state)
        this.serviceRequestData.controls.status.setValue(data.data.status)
        this.serviceRequestData.controls.supplies_charges.setValue(data.data.supplies_charges)
        this.serviceRequestData.controls.suppliescharges.setValue(data.data.suppliescharges)
        this.serviceRequestData.controls.tax_amount.setValue(data.data.tax_amount)
        this.serviceRequestData.controls.tax_exempt_number.setValue(data.data.tax_exempt_number)
        this.serviceRequestData.controls.tax_exempted.setValue(data.data.tax_exempted)
        this.serviceRequestData.controls.tax_rate.setValue(data.data.tax_rate)
        this.serviceRequestData.controls.technician.setValue(data.data.technician)
        this.serviceRequestData.controls.title.setValue(data.data.title)
        this.serviceRequestData.controls.total_amount.setValue(data.data.total_amount)
        this.serviceRequestData.controls.total_paid.setValue(data.data.total_paid)
        this.serviceRequestData.controls.vin.setValue(data.data.vin)
        this.serviceRequestData.controls.year.setValue(data.data.year)
        this.serviceRequestData.controls.zip.setValue(data.data.zip);
        this.serviceRequestData.controls.service_facility_name.setValue(data.data.service_facility_name);
        this.serviceRequestData.controls.cc_fee_type.setValue(data.data.cc_fee_type);
        this.serviceRequestData.controls.cc_fee_included.setValue(data.data.cc_fee_included);
        this.serviceRequestData.controls.total_cc_fee.setValue(data.data.total_cc_fee);
        if(data.data.cc_fee_type == '2'){
          this.serviceRequestData.controls.cc_fee.setValue(0);
          this.serviceRequestData.controls.cc_fee_percentage.setValue(data.data.cc_fee);
        }else{
          this.serviceRequestData.controls.cc_fee.setValue(data.data.cc_fee);
          this.serviceRequestData.controls.cc_fee_percentage.setValue(0);
        }
        this.calculateCCFee();
        this.downloadUspsReport();
        if(data.data.service_request_list){
          this.updateLevelPrice()
          this.updateAcceptReject(0,0,false);
        }
        if (data.data.year =="") {
          this.vehicleName= "";
        } else {
          this.vehicleName= data.data.year ;
        }
        if(data.data.make != ""){
          this.vehicleName += (this.vehicleName ? "/" : "" ) + data.data.make;
        }
        if(data.data.model != ""){
          this.vehicleName += (this.vehicleName ? "/" : "" ) + data.data.model
        }
        this.vehicleName = (this.vehicleName ? this.vehicleName :"N/A");
      } else {
        this.globalService.snackbar("error",  data.message);
      }
      // console.log('Accepted/Reject Value',this.ObjectJson);
      if(data.data.service_request_list){
        this.calculatefinalAmount (this.serviceRequestData.value.tax_rate,this.serviceRequestData.value.supplies_charges);
      }
    });
    // console.log(this.serviceRequestData);
    // this.image = this.serviceRequestData.value.request_images[0];
    // this.document = this.serviceRequestData.value.request_documents[0]
  }
  
  setParams() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.serviceRequestId = params["id"];
      this.idFromUrl = params["id"];
    });
  }
  ConvertToNumber(value) {
    return parseInt(value)
  }
  chkDate(date){
    return this.globalService.isOldDate(date) ? true : false
  }
  getNltoBr(val){
    if(val && val.length>0){
      return val.toString().replace(/\r\n/g, "<br />").replace(/\n/g, "<br />");
    }
    return '-';
  }
  initializeForm(){
    this.serviceRequestData = this.formBuilder.group({
      add_date:[],
      address1:[],
      address2:[],
      balance_amount:[],
      business_name:[],
      business_phone:[],
      car_id:[],
      city:[],
      color:[],
      comments:[],
      company_name:[],
      completion_date:[],
      confirmation_token:[],
      country:[],
      customer_id:[],
      customer_type:[],
      disclaimer:[],
      disclaimer_id:[],
      email:[],
      estimated_delivery_date:[],
      feedback_code:[],
      first_name:[],
      grand_total:[],
      has_app_downloaded:[],
      home_phone:[],
      id:[],
      inspection_reports:[],
      insurance_expiration_date:[],
      is_DIY:[],
      is_paid:[],
      is_supplies_charges_included:[],
      is_tax_included:[],
      is_usps_vehicle:[],
      labor_amount:[],
      labor_rate:[],
      last_name:[],
      license_plate:[],
      mail_sent:[],
      make:[],
      middle_name:[],
      mobile_phone:[],
      model:[],
      modify_date:[],
      no_report_performed:[],
      non_taxable_amount:[],
      notes:[],
      notify_accept_reject_jobs_email:[],
      notify_accept_reject_jobs_push:[],
      notify_create_service_facility_car_email:[],
      notify_create_service_facility_car_push:[],
      notify_expiration_dates_email:[],
      notify_expiration_dates_push:[],
      notify_expiration_dates_web:[],
      notify_feedback_email:[],
      notify_feedback_push:[],
      notify_make_changes_car_facility_service_request_email:[],
      notify_make_changes_car_facility_service_request_push:[],
      notify_send_updated_info_to_customer_email:[],
      notify_send_updated_info_to_customer_push:[],
      notify_service_completion_email:[],
      notify_service_completion_push:[],
      notify_to_admin:[],
      notify_to_customer:[],
      parts_amount:[],
      password:[],
      payment_date:[],
      payment_reminder_date:[],
      payment_reminder_status:[],
      present_mileage:[],
      read_status:[],
      recommendation_saved:[],
      registration_expiration_date:[],
      request_documents:[],
      request_id:[],
      request_images:[],
      request_status:[],
      requested_delivery_date:[],
      reset_pwd_token:[],
      schedule_service_date:[],
      service_facility:[],
      service_request_list:[],
      shop_id:[],
      state:[],
      status:[],
      supplies_charges:[],
      suppliescharges:[],
      tax_amount:[],
      tax_exempt_number:[],
      tax_exempted:[],
      tax_rate:[],
      technician:[],
      title:[],
      total_amount:[],
      total_paid:[],
      vin:[],
      year:[],
      zip:[],
      service_facility_name:[],
      cc_fee: [0],
      cc_fee_included: [0],
      cc_fee_percentage: [0],
      cc_fee_type: [0],
      total_cc_fee: [0]
    });
  }
  approveAll(){
    this.recIds = this.getRecommendationIds();
      // API_update_all_recommendation_statuses.php
    let content = 'access_timestamp='+this.access_timestamp+"&rec_ids=" + this.recIds + "&status=1&from_v2=1&request_id="+this.serviceRequestId;
      this.globalService.callAPI(globalConstants.API_UPDATE_ALL_RECOMMENDATION_STATUES, content).subscribe((data:any)=>{
        // console.log("response",data);
        if(data.result==1){
          this.access_timestamp=data.access_timestamp;
          this.updateAcceptReject(0,1,true);
          this.updateLevelPrice();
          this.applyChanges=true;
          //((this.subTotal * this.serviceInfo.value.tax_rate)/100).toFixed(2)
          this.serviceRequestData.controls['tax_amount'].setValue(data.tax_total);
          this.serviceRequestData.controls['total_amount'].setValue(data.sub_total);
          this.serviceRequestData.controls['supplies_charges'].setValue(data.supplies_charges);
          this.finalShowAbleAmount = this.parseFloats(data.grand_total);
          this.calculateCCFee();
          this.applyDiscount(this.serviceDetail.discounts.total_discounted_amount);
          this.finalUpdateToNotifyAdmin(this.serviceRequestId);
          this.globalService.snackbar("success", data.message);
        }else{
          if(data.code == 352){
            this.refreshDialog(data.message);
          }
          else{
            this.globalService.snackbar("error", data.message);
          }
        }         
      })
    }
  disapproveAll(){
    this.recIds = this.getRecommendationIds();
    let content = 'access_timestamp='+this.access_timestamp+"&rec_ids=" + this.recIds + "&status=2&from_v2=1&request_id="+this.serviceRequestId;
    this.globalService.callAPI(globalConstants.API_UPDATE_ALL_RECOMMENDATION_STATUES, content).subscribe((data:any)=>{
      if(data.result==1){
        this.access_timestamp=data.access_timestamp;
        this.updateAcceptReject(0,2,true);
        this.updateLevelPrice();
        this.applyChanges=true;
        //((this.subTotal * this.serviceInfo.value.tax_rate)/100).toFixed(2)
        this.serviceRequestData.controls['tax_amount'].setValue(data.tax_total);
        this.serviceRequestData.controls['total_amount'].setValue(data.sub_total);
        this.serviceRequestData.controls['supplies_charges'].setValue(data.supplies_charges);
        this.finalShowAbleAmount = this.parseFloats(data.grand_total);
        this.calculateCCFee();
        this.applyDiscount(this.serviceDetail.discounts.total_discounted_amount);
        this.finalUpdateToNotifyAdmin(this.serviceRequestId);
        this.globalService.snackbar("success", data.message);
      }else{
        if(data.code == 352){
          this.refreshDialog(data.message);
        }
        else{
          this.globalService.snackbar("error", data.message);
        }
      }
    })
  }
  getRecommendationIds(){
    var recIds= []
    var service_request = this.serviceRequestData.value.service_request_list
    service_request.forEach(function(x) {

        x.children.forEach(f => {
          if(typeof f.children!='undefined' && f.children.length > 0) {
            f.children.forEach(function(w){

              recIds.push(w.recommendations[0]["id"])
            })
          } else {

            recIds.push(f.recommendations[0]["id"])
          }
        });
    })
    return recIds
  }
  getTotalPrice(object) {
    var totalPriceForGj = 0,totalPriceForParts = 0,rejectedJobCost = 0, nonTaxableAmount=0;
    var labourCost = 0;
    for(var i=0;i<object.children.length;i++) {
      if(object.children[i].children!=undefined && object.children[i].children.length > 0) {

        // do the level 2 jobs
        this.callToThirdLevelCalculations(object.children[i].children);

      } else {
        if(object.children[i].recommendations!=undefined && object.children[i].recommendations.length > 0) {

          for(var j=0;j<object.children[i].recommendations.length;j++) {
            labourCost = 0;
            var scopedNonTaxedValue = 0;
            labourCost = labourCost + parseFloat(object.children[i].recommendations[j].estimated_labor);

            // calculate price for parts
            for(var k=0;k<object.children[i].recommendations[j].parts.length;k++) {
              if(object.children[i].recommendations[j].parts.length > 0) {
                totalPriceForParts = parseFloat(totalPriceForParts.toString()) + (object.children[i].recommendations[j].parts[k].part_price * object.children[i].recommendations[j].parts[k].quantity);
                if(object.children[i].recommendations[j].parts[k].non_taxable == "1" && object.children[i].recommendations[j].approval_status !="2") {
                  scopedNonTaxedValue = scopedNonTaxedValue  + (object.children[i].recommendations[j].parts[k].part_price * object.children[i].recommendations[j].parts[k].quantity);

                }
              }
            }

            // calculate cost for generic jobs
            for(var k=0;k<object.children[i].recommendations[j].generic_jobs.length;k++) {
              totalPriceForGj = totalPriceForGj + (object.children[i].recommendations[j].generic_jobs[k].price * object.children[i].recommendations[j].generic_jobs[k].quantity);
              if(object.children[i].recommendations[j].generic_jobs[k].non_taxable == "1" && object.children[i].recommendations[j].approval_status !="2") {
                scopedNonTaxedValue = scopedNonTaxedValue  + (object.children[i].recommendations[j].generic_jobs[k].price * object.children[i].recommendations[j].generic_jobs[k].quantity);
              }
            }

            this.nonTaxableValue.push(scopedNonTaxedValue);
            // console.log("this.nonTaxableValue",  this.nonTaxableValue)
            var totalAmount = parseFloat(totalPriceForParts.toString()) + parseFloat(totalPriceForGj.toString());
            if(this.isLaborTax){
              this.nonTaxableValue.push(labourCost);
            }
            totalAmount=totalAmount+parseFloat(labourCost.toString());
            totalAmount = parseFloat(totalAmount.toString());
            if(object.children[i].recommendations[j].approval_status == "2") {
              this.rejectedJobCalculation.push(totalAmount);
              this.nonTaxableValue.splice(this.nonTaxableValue.length-1,1)
            } else {
              this.totalAcceptedCalculation.push(totalAmount);
            }
            // console.log("totalAmount",totalAmount)
            totalAmount = 0;
            totalPriceForParts = 0;
            totalPriceForGj = 0;
          }
          // console.log("accepted",)
        }
      }
    }

    return [this.totalAcceptedCalculation,this.rejectedJobCalculation]
  }
  calculatefinalAmount(tax_rate,supplies_charges) {

    var acceptedValue = this.addArray(this.totalAcceptedCalculation);   
    var totalNonTaxedValue = this.addArray(this.nonTaxableValue);
    var taxableValue = acceptedValue - totalNonTaxedValue;
    // console.log("taxableValue",taxableValue)
    var finalValue;
    var totalCalculatedAmount

    if(this.serviceRequestData.value.request_status == "6" || this.serviceRequestData.value.request_status == "8") {
      finalValue = this.serviceRequestData.value.total_amount;     
    } else if(this.serviceRequestData.value.request_status == "3" ||  this.serviceRequestData.value.request_status == "4" || this.serviceRequestData.value.request_status == "5" || this.serviceRequestData.value.request_status == "7" || this.serviceRequestData.value.request_status == "12" ){
      if(this.serviceRequestData.value.is_tax_included  == "1") {
        totalCalculatedAmount = acceptedValue + (taxableValue * (parseFloat(tax_rate)/100))
      } else {
        totalCalculatedAmount = acceptedValue;
      }
      if(this.serviceRequestData.value.is_supplies_charges_included == "1") {
        let isJobRejected=this.isAllRejected();
        finalValue = totalCalculatedAmount + (isJobRejected ? parseFloat(supplies_charges):0);
      } else  {
        finalValue = totalCalculatedAmount;
      }
    } else {
      finalValue = this.serviceRequestData.value.total_amount;
    }
    
    //
    // console.log("final totalCalculatedAmount value "+totalCalculatedAmount);
    // console.log("final value "+finalValue);
    if(finalValue!=null) {
      this.finalShowAbleAmount = this.parseFloats(finalValue);
      this.calculateCCFee();
      // return finalValue.toFixed(2)
    }
  }
  callToThirdLevelCalculations(secondLevelChildren) {

    var totalPriceForGj = 0,totalPriceForParts = 0,rejectedJobCost = 0;
    var labourCost = 0;

    for(var i=0;i<secondLevelChildren.length;i++) {
      if(secondLevelChildren[i].recommendations.length > 0) {
        for(var j=0;j<secondLevelChildren[i].recommendations.length;j++) {
          var tobeDeducted = 0;
          var scopedNonTaxedValue = 0;
          totalPriceForGj = 0;
          totalPriceForParts = 0;
          rejectedJobCost = 0;

          for(var k=0;k<secondLevelChildren[i].recommendations[j].parts.length;k++) {
            totalPriceForParts = totalPriceForParts + (secondLevelChildren[i].recommendations[j].parts[k].part_price * secondLevelChildren[i].recommendations[j].parts[k].quantity);
            if(secondLevelChildren[i].recommendations[j].parts[k].non_taxable == "1" && secondLevelChildren[i].recommendations[j].approval_status !="2") {
              scopedNonTaxedValue = scopedNonTaxedValue + (secondLevelChildren[i].recommendations[j].parts[k].part_price * secondLevelChildren[i].recommendations[j].parts[k].quantity)
            }
          }

          for(var k=0;k<secondLevelChildren[i].recommendations[j].generic_jobs.length;k++) {
            totalPriceForGj = totalPriceForGj + (secondLevelChildren[i].recommendations[j].generic_jobs[k].price * secondLevelChildren[i].recommendations[j].generic_jobs[k].quantity);
            if(secondLevelChildren[i].recommendations[j].generic_jobs[k].non_taxable == "1" && secondLevelChildren[i].recommendations[j].approval_status !="2") {
              scopedNonTaxedValue = scopedNonTaxedValue + (secondLevelChildren[i].recommendations[j].generic_jobs[k].price * secondLevelChildren[i].recommendations[j].generic_jobs[k].quantity);
            }
          }

          this.nonTaxableValue.push(scopedNonTaxedValue);
          labourCost = labourCost + parseFloat(secondLevelChildren[i].recommendations[j].estimated_labor);

          var totalAmount = parseFloat(totalPriceForParts.toString()) + parseFloat(totalPriceForGj.toString()) - parseInt(tobeDeducted.toString());
          if(this.isLaborTax){
            this.nonTaxableValue.push(labourCost);
          }
          totalAmount=totalAmount+parseFloat(labourCost.toString());
          totalAmount = parseFloat(totalAmount.toString());
          if(secondLevelChildren[i].recommendations[j].approval_status == "2") {
            this.nonTaxableValue.splice(this.nonTaxableValue.length-1,1)
            this.rejectedJobCalculation.push(totalAmount)
          } else {
            this.totalAcceptedCalculation.push(totalAmount);
          }
          tobeDeducted = 0;
          totalAmount = 0;
          totalPriceForParts = 0;
          totalPriceForGj = 0;
          labourCost = 0
        }
      } else {
      }
    }
  }
  addArray(array) {
    var sum = 0.00;
    for(var i=0;i<array.length;i++) {
      sum = sum + parseFloat(array[i])
    }
    return sum
  }
  calculateCCFee(){    
    if(this.serviceRequestData.value.total_paid != null && this.serviceRequestData.value.total_paid != "0"){
      this.balance  = parseFloat(this.finalShowAbleAmount) - parseFloat(this.serviceRequestData.value.total_paid);
    }else{
      this.balance  = parseFloat(this.finalShowAbleAmount);
    }
    if(this.serviceRequestData.value.cc_fee_type == '2'){
      this.totalCCfeeValue  = ((this.balance)  * parseFloat(this.serviceRequestData.value.cc_fee_percentage))/100;
    }else{
      this.totalCCfeeValue  = parseFloat(this.serviceRequestData.value.cc_fee);
    }
    console.log(this.totalCCfeeValue);
    if(this.serviceRequestData.value.cc_fee_included == '1'){    
      this.netPayable  = this.balance + this.totalCCfeeValue;
    }else{
      this.netPayable  = this.balance;
      this.totalCCfeeValue = 0;
    }
  }
  totalNetPayed(amount, ccFee){
    return parseFloat(amount) + parseFloat(ccFee);
  }
  updateStatusVehicle(recId,recommendation,service_request, status,level1Id,type){
    // console.log(service_request)
    let content = 'access_timestamp='+this.access_timestamp+"&rec_id=" + recId + "&status="+status+"&level1Id="+level1Id + "&request_id="+this.serviceRequestId;
    this.globalService.callAPI(globalConstants.API_UPDATE_RECOMMENDATION_STATUS_URL, content).subscribe((data:any)=>{
      // console.log("data", data);
      if(data.result == "1"){
        this.access_timestamp=data.access_timestamp;
        this.globalService.snackbar("success", data.message);
        //  console.log("new grand total=",data.grand_total)
         this.ObjectJson[service_request.id]["AcceptedValue"] = data.accepted_total.toFixed(2)
         this.ObjectJson[service_request.id]["RejectedValue"] = data.rejected_total.toFixed(2)
         this.serviceRequestData.controls['tax_amount'].setValue(data.tax_total);
         this.serviceRequestData.controls['total_amount'].setValue(data.sub_total);
         this.serviceRequestData.controls['supplies_charges'].setValue(data.supplies_charges);
         this.finalShowAbleAmount = this.parseFloats(data.grand_total);
         this.calculateCCFee();       
         this.updateAcceptReject(recId,type,false,false);
         this.applyDiscount(this.serviceDetail.discounts.total_discounted_amount);
         setTimeout(() => {
          if(this.applyChanges){
            this.finalUpdateToNotifyAdmin(this.serviceRequestId);
          }
         }, 1500);
         this.cdr.markForCheck();
      }else{
        if(data.code == 352){
          this.refreshDialog(data.message);
        }
        else{
          this.globalService.snackbar("error", data.message);
        }
      }
    });
  }
  finalUpdateToNotifyAdmin(request_id) {
    let conf = 'Are you sure you want to submit your approval to the shop?';
    request_id=this.idFromUrl;
    if (this.applyChanges) {
      this.globalService.confirmDialog('notifyAdminCustomer', conf).subscribe((r) => {
        let res: any = r;
        if (res == 'notifyAdminCustomer') {
          let content='access_timestamp='+this.access_timestamp+"&request_id=" + request_id;
          this.globalService.callAPI(globalConstants.API_UPDATE_NOTIFY_TO_ADMIN_URL,content ).subscribe((response:any) => {
            if (response.result == "1") {
              this.globalService.snackbar("success", response.message);
              // For Code 518 - to handle invalid coupon codes
              // if(response.code == 518  && response.data.discounts_list){
              //   // console.log('inavlid coupons detected');
              //   this.viewRequestCoupon.reInitialize(response.data);
              //   this.viewRequestCoupon.reset();
              //   this.router.navigate(['customer/list-service-requests']);
              // }
              this.router.navigate(['customer/list-service-requests']);
            } else {
              // For Code 587 - Case of service request version mismatch. To handle if there was some change on shop end and customer is yet not notified about then refresh the data.
              if(response.code == 587){
                if(this.serviceDetail.info_update_requested == 0){
                  // refresh the data or open the popup with error message asking to send a message to shop requesting the latest version of the request data.
                  // this.initializePage();
                  this.sendMessageDialog(this.serviceRequestData.value.id);
                }
              }
              else if(response.code == 352){
                this.refreshDialog(response.message);
              }
              else{
                this.globalService.snackbar("error", response.message);
              }
            }
          });
        }
      });
    } else {
      this.globalService.snackbar("error", 'Please provide your approval for every job you want otherwise you can reject them.');
    }
  }
  calculateAmountForRecommendation(recommendation) {
    // console.log("recommendation",recommendation);
    var labourCharges = recommendation.estimated_labor;
    var scopedNonTaxedValue = 0,totalPriceForGj = 0,totalPriceForParts = 0;

    for(var k=0;k<recommendation.parts.length;k++) {
      // console.log("Parts",recommendation.parts[k]);
      totalPriceForParts = totalPriceForParts + (recommendation.generic_jobs[k].price *  recommendation.generic_jobs[k].quantity);
      if(recommendation.parts[k].non_taxable == "1" && recommendation.approval_status !="2") {
        scopedNonTaxedValue = scopedNonTaxedValue + (recommendation.parts[k].price * recommendation.parts[k].quantity);
      }
    }

    for(var k=0;k<recommendation.generic_jobs.length;k++) {
      // console.log("Generic Jobs",recommendation.generic_jobs[k]);
      totalPriceForGj = totalPriceForGj + (recommendation.generic_jobs[k].price * recommendation.generic_jobs[k].quantity);
      if(recommendation.generic_jobs[k].non_taxable == "1" && recommendation.approval_status !="2") {
        scopedNonTaxedValue = scopedNonTaxedValue + (recommendation.generic_jobs[k].price * recommendation.generic_jobs[k].quantity);
      }
    }

    var totalAmount = parseInt(totalPriceForGj.toString()) + parseInt(totalPriceForParts.toString()) + parseInt(labourCharges.toString());
    return totalAmount
  }
  updateText(event, rec_id, recommendation,service_request,type){
    let textChange = <HTMLElement> document.getElementById(event.source.id).childNodes[0].childNodes[1];
    // console.log(rec_id,service_request,recommendation);
    if(event.value == 'accept'){

      if(event.source.checked){
        // textChange.innerText = 'Accept';
        this.updateStatusVehicle(rec_id,recommendation,service_request,0,service_request.id,type);
      }else{
        // event.value = 'Undo Accept';
        // textChange.innerText = 'Undo Accept';
        this.updateStatusVehicle(rec_id,recommendation,service_request,1,service_request.id,type);
      }
    }else{
      if(event.source.checked){
        // this.statusRejectText = 'Reject';
        // textChange.innerText = 'Reject';
        this.updateStatusVehicle(rec_id,recommendation,service_request,0,service_request.id,type);
      }else{
        // this.statusRejectText = 'Undo Reject';
        // textChange.innerText = 'Undo Reject';
        this.updateStatusVehicle(rec_id,recommendation,service_request,2,service_request.id,type);
      }
    }
    
  }
  expansionOpen(ele) {

      var target= '#expansionHead-'+ ele;
      var eleState = $(target).data('state');
      $(".new-request-expension").find(".mat-expansion-panel-header").data("state",false);
      if(!eleState){
        setTimeout(() => {
           let element = document.getElementById('expansionHead-' + ele);
           var target= '#expansionHead-'+ ele;
           setTimeout(() => {
             var body = $("html, body");
             body.animate({
                    scrollTop: jQuery(target).offset().top - 90
              }, 500);
           }, 500);
           $(target).data('state',true);
        }, 200);
      }
  }
  getAcceptAndRejectCalculationForPage(serviceRequest,returningStatus){
    var total = 0;
    var rejected = 0;
    var approved = 0;
    var finalCalculation = 0;
    serviceRequest.children.forEach(l2child => {

      if(l2child.recommendations.length>0) {
        total = 0;
        var l2childRecommendation = l2child.recommendations[0];
        var approval_status = l2childRecommendation.approval_status;

        // parts calculation for l2

        if(l2childRecommendation.parts.length > 0) {
          l2childRecommendation.parts.forEach((part)=>{
            total = total + (parseFloat(part.part_price) * parseFloat(part.quantity))
          })
        }

        // GJ calculation for l2

        if(l2childRecommendation.generic_jobs.length > 0) {
          l2childRecommendation.generic_jobs.forEach(gj => {
            total = total + (parseFloat(gj.price) * parseFloat(gj.quantity))
          });
        }
        total = total + parseFloat(l2childRecommendation.estimated_labor);
        finalCalculation = finalCalculation + total
        if(approval_status == "2") {
          rejected = rejected + total;
        } else {
          approved = approved + total;
        }
        // this.ObjectJson.push({[serviceRequest.id]:{[l2child.id]:{"total":total,"status":approval_status}}})

      }
      else {
        l2child.children.forEach(l3child=>{
          // console.log("l3.id",l3child.id)
          total = 0;
          var l3childRecommendation = l3child.recommendations[0];
          if(l3childRecommendation!=undefined) {
            var approval_status = l3childRecommendation.approval_status;
          if(l3childRecommendation.parts.length > 0) {
            l3childRecommendation.parts.forEach((part)=>{
              total = total + (parseFloat(part.part_price) * parseFloat(part.quantity))
            })
          }
          if(l3childRecommendation.generic_jobs.length > 0) {
            l3childRecommendation.generic_jobs.forEach(gj => {
              total = total + (parseFloat(gj.price) * parseFloat(gj.quantity))
            });
          }
          total = total + parseFloat(l3childRecommendation.estimated_labor);
        }
          finalCalculation = finalCalculation + total
          if(approval_status == "2") {
            rejected = rejected + total;
          } else {
            approved = approved + total;
          }
        });
      }
    });

    if(returningStatus == null ){
      return [approved,rejected]
    }
    // return returningStatus == "accept" ? approved : rejected;
  }
  downloadUspsReport() {
    let downloadUSPSReport_URL = '';
    let reportData;
    let qryStringUrl = "";
    let data=this.serviceRequestData.value.inspection_reports;
    if (data.length) {
      reportData = data[0].acdelco_report_data;
      if (reportData) {
        downloadUSPSReport_URL = globalConstants.API_DOWNLOAD_INSPECTION_REPORT_ACDELCO_URL;
        qryStringUrl += "?report_type=2";
             qryStringUrl += "&acdelco_report_id=" + reportData.id;
             this.reportDownloadType=2;
      } else {
        downloadUSPSReport_URL = globalConstants.API_DOWNLOAD_INSPECTION_REPORT_USPS_URL;
        reportData = data[0].usps_report_data;
        qryStringUrl += "?report_type=1";
             qryStringUrl += "&usps_report_id=" + reportData.id;
             this.reportDownloadType=1;
      }

      this.reportDownloadLink = downloadUSPSReport_URL + qryStringUrl;
    } else {
      this.reportDownloadLink = '';
    }

  }
  getInvoicePdf(){
    //return globalConstants.S3_BUCKET_URL+'service-invoices/'+ this.serviceRequestData.value.request_id+'.pdf';
    let url = globalConstants.S3_BUCKET_URL+'service-invoices/'+ this.serviceRequestData.value.request_id+'.pdf';
    if(this.globalService.isDeviceiOS()){
      var fileName = this.serviceRequestData.value.request_id+'.pdf'; 
      var element = document.createElement('a');
      element.setAttribute('href', url);
      element.setAttribute('download', fileName);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }else{

      var headers = new Headers();
      headers.append('content-type', 'application/x-www-form-urlencoded');
      this.http.get(url,{headers:headers,responseType:ResponseContentType.Blob})
      .map(function(res){
        let resContentType = res.headers.get('content-type');
        // console.log(resContentType);
        if(resContentType == 'application/pdf'){
          return {
            'contentType' : resContentType,
            'response' : new Blob([res.blob()],{ type: "application/pdf" })
          };
        }else{
          return {
            'contentType' : resContentType,
            'response' : res.text()
          };
        }
      })
      .subscribe((resp) =>{
        var fileName = this.serviceRequestData.value.request_id+'.pdf'; 
        if(resp.contentType == 'application/pdf'){        
            saveAs(resp.response,fileName);
        }
      },
      (err) =>{
        let errorMsg = "Cannot download Invoice.";
        this.globalService.snackbar('error',errorMsg);
      });
    }    
    
  }
   /* image rotation dialog  */
   imageToolDialog(current,i,type,imgaar): void {
    let dialogRef = this.dialog.open(ImagetooldialogComponent, {
        panelClass: ['imageToolDialog'],
        width: "100%",
        data: {event: current,index:i,imgType:type,imgArray:imgaar,customerId:this.globalService.getCurrentCustomer()["id"]}
    });

    dialogRef.afterClosed().subscribe(result => {
        // console.log('The dialog was closed');
    });
 }

 /*******video dialog******/
 videoShow(val): void {
  let dialogRef = this.dialog.open(VideoShowComponent, {
      panelClass: 'car-dialog-form',
      width: "800px",
      data: {name: val, url: globalConstants.S3_BUCKET_URL + 'recommendation-videos/'}
  });

  dialogRef.afterClosed().subscribe(result => {

  });
}
updateLevelPrice(){
     if(this.serviceRequestData.value.request_status!="9" && this.serviceRequestData.value.request_status!="10" )  {
  for(var i = 0;i<this.serviceRequestData.value.service_request_list.length;i++) {
    this.totalReturnedValue = this.getTotalPrice(this.serviceRequestData.value.service_request_list[i]);
    var returingObj = this.getAcceptAndRejectCalculationForPage(this.serviceRequestData.value.service_request_list[i],null)
    this.ObjectJson[this.serviceRequestData.value.service_request_list[i]["id"]]=
    (
      {
        AcceptedValue:returingObj[0].toFixed(2),
        RejectedValue:returingObj[1].toFixed(2)
      }
    )
  }
     }
}
 updateAcceptReject(rId, type, setAll,runCalc = true) {
   let serviceRequest = this.serviceRequestData.value.service_request_list;
   this.applyChanges=true;
   for (let i in serviceRequest) {
     for (let j in serviceRequest[i].children) {
       if ((typeof serviceRequest[i].children[j].children !='undefined') && (serviceRequest[i].children[j].children.length > 0)) {
         for (let k in serviceRequest[i].children[j].children) {
           for (let l in serviceRequest[i].children[j].children[k].recommendations) {
             if (setAll) {
               this.serviceRequestData.value.service_request_list[i].children[j].children[k].recommendations[l].approval_status = type;
             } else if (serviceRequest[i].children[j].children[k].recommendations[l].id == rId) {
               if (serviceRequest[i].children[j].children[k].recommendations[l].approval_status == type) {
                 type = 0;
               }
               this.serviceRequestData.value.service_request_list[i].children[j].children[k].recommendations[l].approval_status = type;
             }
            if(serviceRequest[i].children[j].children[k].recommendations[l].service_request_list_id!='158'){
             if(this.serviceRequestData.value.service_request_list[i].children[j].children[k].recommendations[l].approval_status==0){
              this.applyChanges=false;
             }
            }
           }
         }
       } else {
         for (let k in serviceRequest[i].children[j].recommendations) {
           if (setAll) {
             this.serviceRequestData.value.service_request_list[i].children[j].recommendations[k].approval_status = type;
           } else if (serviceRequest[i].children[j].recommendations[k].id == rId) {
             if (serviceRequest[i].children[j].recommendations[k].approval_status == type) {
               type = 0;
             };
             this.serviceRequestData.value.service_request_list[i].children[j].recommendations[k].approval_status = type;
           }
           if(this.serviceRequestData.value.service_request_list[i].children[j].recommendations[k].approval_status ==0){
            this.applyChanges=false;
           }
         }
       }
     }
   }
   if(runCalc){
   this.calculatefinalAmount (this.serviceRequestData.value.tax_rate,this.serviceRequestData.value.supplies_charges);
   }
 }
 parseFloats(value){
   var val=parseFloat(value);
   if(isNaN(val)){
     return '0.00';
   }
   return val.toFixed(2);
 }
 getFileIcons(str){
  let ext=str.substr(str.lastIndexOf(".")+1);
  let type=0;
  if(ext!=''){
    type= this.globalService.getFileIcon({type:ext.toLowerCase()});
  }
  return type;
 }
 invokeDownload(url,type,fileName=''){
  let localFileName = 'DownloadedFile',localNameExt,localName;
  if(type == 'ServiceRequestDocument'){
    localName = fileName.split('.');
    localNameExt = localName[localName.length-1];
    localFileName = 'ServiceRequestDocument.'+localNameExt;
  }else if(type == 'CARInspectionReport'){
    localFileName = 'CARInspectionReport.pdf';
  }
  // console.log(localFileName);
  this.globalService.downloadAsset(url,localFileName);
}
isAllRejected(){
  let requestList=this.serviceRequestData.value.service_request_list;
  let isAnyRejected=false;
  let isAnyRemain=false;
  for(let i in requestList){
      let servicerequestChild=requestList[i].children;
      for(let j in servicerequestChild){
          let recommendation=servicerequestChild[j].recommendations;
          for(let k in recommendation){
              if(recommendation[k].approval_status==2){
                  isAnyRejected=true;
              }else{
                  isAnyRemain=true;
                  break;
              }
          }
          if(isAnyRemain){
            break;
          }
          let level3=servicerequestChild[j].children;
        for (let k in level3) {
          let recomand3 = level3[k].recommendations;
          for (let m in recomand3) {
            if (recomand3[m].service_request_list_id == '158') {
              
            } else if (recomand3[m].approval_status == 2) {
              isAnyRejected = true;
            } else {
              isAnyRemain = true;
              break;
            }
          }
        }
          if(isAnyRemain){
            break;
          }
      }
      if(isAnyRemain){
        break;
      }
    }
    return isAnyRemain;
  }
  
  /**
   * Function to apply discount to grand total and balance.
   * @param discount - The discount amount to be deducted.
   * @createdBy Shashank Thakur
   * @trackID <:CAR-1571>
   * @created 10-08-2020
   */
  applyDiscount(discount:number){
    this.serviceDetail.discounts.total_discounted_amount = discount;
    let tmpSubTotal = parseFloat(this.serviceRequestData.value.total_amount);
    // let tmpSubTotal = 0.0;
    let tmpSC = ((this.serviceRequestData.value.is_supplies_charges_included == '1') ? parseFloat(this.serviceRequestData.value.supplies_charges) : 0.0);
    let tmpTax = ((this.serviceRequestData.value.is_tax_included == '1') ? parseFloat(this.serviceRequestData.value.tax_amount) : 0.0);
    // let tmpTax = ((this.serviceRequestData.value.is_tax_included == '1') ? parseFloat('0.0') : 0.0);
    // console.log('tmp st = ', tmpSubTotal);
    // console.log('tmp sc = ', tmpSC);
    // console.log('tmp tax = ', tmpTax);
    let totalBeforeDiscount = tmpSubTotal + tmpSC + tmpTax;
    // console.log('discount = ', discount);
    // console.log('total before discount = ', totalBeforeDiscount);
    if(totalBeforeDiscount > 0){
      this.finalShowAbleAmount = this.parseFloats(totalBeforeDiscount - discount);
      if(parseFloat(this.finalShowAbleAmount) < 0){
        this.finalShowAbleAmount = this.parseFloats('0');
      }
      this.calculateCCFee();
      
      // this.balance = this.parseFloats(parseFloat(this.finalShowAbleAmount) - parseFloat(this.serviceInfo.value.total_paid));
      // if(parseFloat(this.balance) < 0){
      //   this.balance = this.parseFloats('0');
      // }
    }
  }
  
  /**
   * Function to remove a specific amount from totals. 
   * To be used if above function doesn't work as expected
   * @param discount - amount to be subtracted from totals.
   * @createdBy Shashank Thakur
   * @trackID <:CAR-1571>
   * @created 10-08-2020
   */
  removeDiscount(discount:number){
    // this.subTotal = this.parseFloats(parseFloat(this.subTotal) + discount);
    this.finalShowAbleAmount = this.parseFloats(parseFloat(this.finalShowAbleAmount) + discount);
    this.calculateCCFee();
    // this.balance = this.parseFloats(parseFloat(this.balance) + discount);
  }
  
  sendMessageDialog(id): void {
    let dialogRef = this.dialog.open(AddComplaintDialogComponent, {
        panelClass: ['car-dialog-form'],
        // position: {top: '50px'},
        // width: "800px",
        data: {request_id: id, version_mismatch: true, service_request_id:this.serviceRequestData.value.request_id}
    });

    dialogRef.afterClosed().subscribe(result => {
        // console.log('The dialog was closed');
    });
  }
  
  /* Refresh Dialog  */
  refreshDialog(message:string): void {
    let dialogRef = this.dialog.open(RefreshDialogComponent, {
      panelClass: ['car-dialog-form'],  
      width:'400px',
      data: {message: message, parent: this}
    });

    dialogRef.afterClosed().subscribe(result => {
        // console.log('The dialog was closed');
    });
  }
  /* END */
  
  // Functions added to match the function in fill recommendation to avoid errors.
  addCouponControls(code:string, opr:number){}
  removeCouponControls(){}
  saveRecommendations(type:any, coupon?:boolean){}
  
}

@Component({ 
  templateUrl: 'refresh-popup.html',
  styles: [`
      .mat-dialog-content {
        display: block;
        margin: 0 -24px;
        padding: 0 24px;
        max-height: auto;
        overflow: auto;
        backface-visibility: hidden;
      }
  `]
})
export class RefreshDialogComponent {
  public refreshInfo: any;
  public timeOut=5; // Time in seconds to refresh the view automatically.
  public timeInit=1;
  public timerHandle:any;
  constructor( @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<RefreshDialogComponent>, private globalService: GlobalService, public dialog: MatDialog) {
    this.refreshInfo = data;
    this.globalService.diaLogObj(this.dialogRef);
    this.dialogRef.beforeClosed().subscribe((resp:any)=>{
      // console.log('before close = ', resp);
      clearTimeout(this.timerHandle);
    });
  }
  
  ngOnInit() {
    this.countDown();
  }
  
  countDown() {
    this.timerHandle = setTimeout(() => {
      if (this.timeInit == this.timeOut) {
        this.goto();
      } else {
        this.timeInit += 1;
        this.countDown();
      }
    }, 1000);
  }
  
  goto() {
    this.dialogRef.close();
    this.refreshInfo.parent.initializePage();
    // window.location.href=window.location.origin+"/customer/dashboard";
  }

}
