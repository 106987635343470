<ng-container *ngIf="!isPopup">
  <div id="mainCompotents">
    <div class="main-content" id="dashboard-content">
      <div class="shopaddnewvendor">
        <div class="panel panel-primary" id="shopaddnewvendorPanel">
          <div class="panel-heading padding-left-15">
            <h3 class="panel-title">Edit Vendor</h3>
            <button mat-mini-fab class="custom-btn-back" title="Back" (click)="globalService.goBack();">
              <mat-icon>arrow_back</mat-icon>
          </button>
          </div>
          <div class="panel-body bgcolor-lightgrey">
            <!-- Add New discount page start -->
            <div id="addNewVendor" class="row bgcolor-lightgrey form-content">
              <div class="container-fluid">
                <ng-container *ngTemplateOutlet="editVendorFormTemplate"></ng-container>
              </div>
            </div>
            <!-- Add new discount end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- Popup view for this component -->
<ng-container *ngIf="isPopup">
  <div mat-dialog-title class="relative">
    Edit Vendor
    <button mat-mini-fab  class="close-modal mat-elevation-z0" (click)="handleClose()">
      <mat-icon class="notranslate close-icon-lg" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="center-block" style="max-width: 800px;">
      <div id="addNewVendor" class="row bgcolor-lightgrey form-content">
        <div class="container-fluid padding-0">
          <ng-container *ngTemplateOutlet="editVendorFormTemplate"></ng-container>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="inquiry-action relative">
    <div class="row sticky-section hidden-xs hidden-sm margin-0">
      <div class="btn-wrap col-lg-3 col-md-3 text-right">
        <button mat-raised-button  class="font-bold" (click)="handleClose()">Close</button>
      </div>
      <div class="btn-wrap col-lg-9 col-md-9 text-left">
        <button type="button" mat-raised-button class="font-bold" color="accent" title="Submit & Close"  (click)="editVendor(1)">
          Submit &amp; Close
        </button>
        <button type="button" mat-raised-button class="font-bold" color="accent" title="Submit & Stay"  (click)="editVendor(2)">
          Submit &amp; Stay
        </button>
      </div>
    </div>
    <div class="row sticky-section visible-xs visible-sm margin-0">
      <div class="btn-wrap col-sm-12 col-xs-12 text-right">
        <button mat-raised-button mat-dialog-close class="font-bold">Close</button>
      </div>
      <div class="btn-wrap col-sm-12 col-xs-12 text-left">
        <button type="button" mat-raised-button class="font-bold" color="accent" title="Submit & Close"  (click)="editVendor(1)">
          Submit &amp; Close
        </button>
        <button type="button" mat-raised-button class="font-bold" color="accent" title="Submit & Stay"  (click)="editVendor(2)">
          Submit &amp; Stay
        </button>
      </div>
    </div>
  </mat-dialog-actions>
</ng-container>

<ng-template #editVendorFormTemplate>
  <form [formGroup]="editVendorForm" novalidate id="addDiscountForm" class="form-inline col-sm-12" role="form" method="POST" enctype="multipart/form-data" (ngSubmit)="editVendor(1)">
    <div class="col-xs-12 form-block">
      <div class="row">
        <div class="form-group col-sm-6 col-md-6 col-xs-12">
          <label for="name">Nexpart Name</label>
          <div class='relative'>
            <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
              <input matInput placeholder="Nexpart Name*" title="Nexpart Name" formControlName="name"  class="form-control input-lg custom-form-input" maxlength="200"  required/>
              <mat-error *ngIf="editVendorForm.controls.name.hasError('required')">
                This field cannot be empty
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        
        <div class="form-group col-sm-6 col-md-6 col-xs-12">
          <label for="username">Nexpart Username</label>
          <div class='relative'>
            <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
              <input matInput placeholder="Nexpart Username*" title="Nexpart Username" formControlName="username"  maxlength="200" class="form-control input-lg custom-form-input"/>
              <mat-error *ngIf="editVendorForm.controls.username.hasError('required')">
                This field cannot be empty
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      
      <div class="row">
        <div class="form-group  col-sm-6 col-md-6 col-xs-12">
          <label for="password">Nexpart Password</label>
          <div class='relative'>
            <mat-form-field class="btn-block car-input input-suffix" floatPlaceholder="never" appearance="fill">
              <input matInput placeholder="Nexpart Password*" title="Nexpart Password" formControlName="password"  class="form-control input-lg custom-form-input" maxlength="300"  [type]="passwordVisibility ? 'text' : 'password'" required />
              <mat-icon matSuffix (click)="passwordVisibility = !passwordVisibility">{{passwordVisibility ? 'visibility_off' : 'visibility'}}</mat-icon>
              <mat-error *ngIf="editVendorForm.controls.password.hasError('required')">
                This field cannot be empty
              </mat-error>
            </mat-form-field>
            <!-- <p class="help-block" ><strong>Note : </strong>Nexpart API Key - 32 Character Key found in Nexpart Account Page.</p> -->
          </div>
        </div>
        
        <div class="form-group col-sm-6 col-md-6 upload-image">
          <label for="vehicleVinPic">Logo</label>
          <mat-toolbar class="mat-elevation-z2 margin-bottom-5 upload-btn relative">
            <mat-icon class="margin-right-10">cloud_upload</mat-icon>
            Drag File Here
            <input type="file" id="addlogo" (change)="changeListener($event,'logo')" name="logo" accept="image/*"/>
            <button type="button" mat-raised-button color="accent" class="pull-right">Browse</button>
          </mat-toolbar>
          <div class="margin-bottom-5">
            <p class="help-block" style="margin:0px;">Formats supported : {{FORMAT_SUPPORTED_FOR_IMAGE}}</p>
            <p class="help-block" style="margin:0px;">Maximum size : {{MAX_ALLOWED_IMAGE_DISPLAYED_TEXT}}</p>
          </div>
          <ul id="vin_photo-ul" class="list-inline margin-bottom-0 margin-top-5" *ngIf="editVendorForm.controls.logo.value && !isLogoUploaded">
            <li class="relative view-images">
              <a href="javascript:void(0);" class="thumbnail"><img class="show-image" src="{{logoBaseUrl}}{{editVendorForm.controls.logo.value}}"><i class="fa fa-trash remove-img" (click)="deleteImg('logo')"></i></a>
            </li>
          </ul>
          <ul id="vin_photo-ul" class="list-inline margin-bottom-0 margin-top-5" *ngIf="logoImage && isLogoUploaded">
            <li class="relative view-images">
              <a href="javascript:void(0);" class="thumbnail"><img class="show-image" src="{{logoImage}}"><i class="fa fa-trash remove-img" (click)="deleteImg('logo')"></i></a>
            </li>
          </ul>
          <!-- <input type="file" formControlname="vehicle_vin_pic" (change)="getFile($event,'vehicle_vin_pic')"> -->
        </div>
      </div>

      <div class="row" *ngIf="!isPopup">
        <div class="col-xs-12 form-block submit-block">
          <button type="submit" mat-raised-button
            class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent"
            title="Submit & Close" style="max-width: 300px;">
            SUBMIT &amp; CLOSE
          </button>
          <button type="button" mat-raised-button
            class="car-btn btn-block text-uppercase btn-lg font-bold fontcolor-white" color="accent"
            title="Submit & Stay" style="max-width: 300px;" 
            (click)="editVendor(2)">
            SUBMIT &amp; STAY
          </button>
        </div>
      </div>
    </div>
  </form>
</ng-template>