<div class="car-panel" id="mainCompotents">
    <div class="panel panel-primary" id="addGenericJobPanel">
        <div class="panel-heading padding-left-15">
            <h3 class="panel-title">
                Add New Resource
            </h3>
        </div>
        <div class="panel-body bgcolor-lightgrey">
            <!-- Add Customer page start -->
            <div id="addGenericJobContent" class="row bgcolor-lightgrey form-content add-resource">
                <div class="container-fluid">
                    <form [formGroup]="addResourceForm" (ngSubmit)="addResource()" class="form-inline col-xs-12 col-sm-12 col-md-12 col-lg-12" role="form" method="POST" enctype="multipart/form-data" autocomplete="off">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block">
    
                            <div class="row">
                                <div class="form-group col-sm-12 col-md-6" >
                                    <label for="vendor_name">Resource Name*</label>
                                    <div class='relative'>
                                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                            <input matInput placeholder="Resource Name" title="Resource Name" formControlName="resource_name"  class="form-control input-lg custom-form-input" maxlength="100"/>
                                            <mat-error *ngIf="addResourceForm.controls.resource_name.hasError('required')">
                                                Resource Name is required
                                            </mat-error>      
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
    
                            <div class="row">
                                <div class="form-group col-sm-12 col-md-6"  >
                                    <label for="price">Resource Link*</label>
                                    <div class='relative'>
                                        <mat-form-field class="btn-block car-input" floatPlaceholder="never" appearance="fill">
                                            <input matInput placeholder="Resource Link" title="Resource Link" formControlName="resource_link" class="form-control input-lg custom-form-input" maxlength="100" />
                                            <mat-error *ngIf="addResourceForm.controls.resource_link.hasError('required')">
                                                Resource Link is required
                                            </mat-error>
                                           <mat-error *ngIf="addResourceForm.controls.resource_link.hasError('pattern')">
                                               Please enter a valid link.
                                            </mat-error>
                                        </mat-form-field>
                                        <span class="help-block" style="margin:0px;">Ex: http://www.websitename.com</span>
                                    </div>
                                </div>
    
                            </div>
    
                            <div class="row">
                                <div class="form-group col-sm-12 col-md-12">
                                     <mat-checkbox class="btn-block checkbox-label-wrap" labelPosition="after" (change)="checkLeftMenuStatus($event)" formControlName="left_menu_status" >
                                        Check if you want to display this resource in left menu.
                                    </mat-checkbox>
                                    <p class="help-block"><strong>Note:</strong> You can display only 5 Resources under <strong>Resources</strong> tab in left menu.</p>
                                </div>
                            </div>
    
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-block submit-block">
                                    <button type="submit" mat-raised-button class="car-btn btn-block text-uppercase btn-lg font-light fontcolor-white" color="accent" title="Submit" style="max-width: 300px;">
                                        SUBMIT
                                    </button>
                                </div>
                            </div>
    
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    
</div>
